export interface CursorPagination {
  count: number;
  currentPageString: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nextCursor?: string;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  loadNextPage: () => void;
  loadPreviousPage: () => void;
  updateRowsPerPage: (rowsPerPage: number) => void;
}

export enum CellValidationType {
  email = 'email',
  phone = 'phone',
  number = 'number',
}

export type EditableTableCellComponent<TData> = (props: {
  columnId: string;
  row: TData;
  value: unknown;
  onUpdate: (value: unknown, isValid?: boolean) => void;
  onError: () => void;
  onValidate?: (validateType: CellValidationType, value: unknown) => boolean;
}) => JSX.Element;

export type EditableTableCellComponentProps<T> = {
  value: unknown;
  row: T;
  columnId: string;
  onUpdate: (value: unknown, isValid?: boolean) => void;
  onError: () => void;
  onValidate?: (validateType: CellValidationType, value: unknown) => boolean;
};

declare module '@tanstack/react-table' {
  interface TableMeta<TData> {
    editableComponents?: Record<string, EditableTableCellComponent<TData>>;
    updateData?: (rowIndex: number, columnId: string, value: unknown) => void;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends unknown, TValue extends unknown> {
    dataAccessor: string;
  }
}

export type Position = null | { row: number; column: number };

interface TableActionOption<T> {
  label: string;
  onClick: (rows: T[]) => void;
  disabled?: boolean;
  icon?: string;
  permission?: boolean;
  danger?: boolean;
}

export interface TableActionGroup<T> {
  label: string;
  options: TableActionOption<T>[];
}

export interface TableActionButton<T> {
  label: string;
  groups: TableActionGroup<T>[];
  alwaysEnabled?: boolean;
}

export interface TableHeaderFilter {
  label?: string | React.ReactNode;
  accessibilityLabel?: string;
  loading?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  icon?: string;
  testID?: string;
}
