import { useHistory, useLocation } from 'react-router-dom';

import {
  TWHeading1,
  TWIcon,
  TWButtonIconFlat,
  TWFlexContainer,
  TWSpacingContainer,
} from '@tw/components';
import { FormAssignmentNode } from '@tw/generated';
import { getTranslation } from '@tw/i18n';
import { LocationWithState } from './Forms.definitions';

export const FormHeading = ({ assignment }: { assignment: FormAssignmentNode }) => {
  const history = useHistory();
  const { label } = assignment;
  const location = useLocation<LocationWithState>();

  const handleOnClick = () => {
    if (location?.state?.fromAssignedToMe) {
      history.push('/forms/myForms/assignedToMe', {
        fromAssignedToMe: true,
      });
    } else if (location?.state?.fromPerpetual) {
      history.push('/forms/myForms/perpetualForms', { fromPerpetual: true });
    } else {
      history.goBack();
    }
  };
  return (
    <>
      <TWFlexContainer row align="center">
        <TWButtonIconFlat
          data-testid="FormHeading:BackButton"
          type="borderless"
          onClick={handleOnClick}
          accessibilityLabel={getTranslation('goBack')}
        >
          <TWIcon type="material-arrow_back" />
        </TWButtonIconFlat>
        <TWSpacingContainer twMarginRight={1} />
        <TWHeading1>{label}</TWHeading1>
      </TWFlexContainer>
    </>
  );
};
