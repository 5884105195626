export default {
  // All date/time format strings use MomentJS format
  dateAndTime: {
    // These are used for outputting/formatting dates
    veryShortDate: 'DD-MMM-YY',
    shorterDate: 'D-MMM-YYYY',
    shortDate: 'D [de] MMM [de] YYYY',
    noDayLongDate: 'D [de] MMMM [de] YYYY',
    longDate: 'dddd, D [de] MMMM [de] YYYY',
    shortDayDate: 'ddd, D [de] MMM',
    dayDate: 'ddd, D [de] MMMM [de] YYYY',
  },
  messagingDateSeparatorFormat: {
    lastDay: '[Ayer]',
    sameDay: '[Hoy]',
    nextDay: '[Mañana]',
    lastWeek: '[El] dddd [pasado]',
    nextWeek: 'dddd',
    sameElse: 'L',
  },
  messageDetailsDateFormat: {
    international: 'D [de] MMM [de] YYYY, hh:mm zz',
    default: 'D [de] MMM [de] YYYY, h:mma zz',
  },
};
