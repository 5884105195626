import _ from 'lodash';
import { pythonApiBackend } from '@tw/backends';
import academicServiceStoreConfig from '../academicServiceStoreConfig';
import travelLettersModel from './travelLettersModel';
import travelLettersQueries from './travelLettersQueries';

const travelLetterOperations = {
  async fetchCoursesForStudentList(filters, { orgPersonId, teamId, configContainer }) {
    const occurrenceBetween = `btw:[(${filters.filterStartTime}),(${filters.filterEndTime})]`;

    const responseData = await Promise.all(
      // Our graphql setup doesn't support OR statements. Consequently we need
      // to make two queries to get the courses that either start or end within
      // the date range provided. These are de-duped in during normalization.
      _.map(['Start', 'End'], (startEndString) => {
        const body = {
          query: travelLettersQueries.travelLettersCoursesQuery,
          variables: { ...filters, [`occurrenceBetween${startEndString}`]: occurrenceBetween },
        };

        return pythonApiBackend.doFetchGraphQL(
          {
            debugLabel: 'fetchCoursesForStudentList',
            includeAuthenticationHeader: true,
            personId: orgPersonId,
            teamId,
          },
          {
            body,
          },
          configContainer,
        );
      }),
    );

    return travelLettersModel.normalizeMultipleFetchCourses(responseData);
  },

  async sendTravelLetterEmail(body, { orgPersonId, teamId, configContainer }) {
    const response = await pythonApiBackend.doFetch(
      {
        ctx: academicServiceStoreConfig.operationContext,
        model: `travel_letters/send`,
        debugLabel: 'sendTravelLetterEmail',
        includeAuthenticationHeader: true,
        personId: orgPersonId,
        teamId,
      },
      {
        method: 'POST',
        body,
      },
      configContainer,
    );

    return response;
  },
};

export default travelLetterOperations;
