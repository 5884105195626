import { Student } from './student';

export enum SessionAttendanceStatuses {
  /* eslint-disable camelcase */
  attended = 'attended',
  no_fault_absence = 'no_fault_absence',
  student_canceled = 'student_canceled',
  student_no_show = 'student_no_show',
  partial_session = 'partial_session',
  pending = 'pending',
  tutor_canceled = 'tutor_canceled',
  completed = 'completed',
  other_canceled = 'other_canceled',
  /* eslint-enable camelcase */
}

type SessionAttendanceStatus = keyof typeof SessionAttendanceStatuses;

export interface SessionAttendance {
  id: string;
  status: SessionAttendanceStatus;
  meta: {
    notes?: string;
    prepared?: boolean;
    attendanceStartDateTime?: string;
    attendanceEndDateTime?: string;
  };
  student: Student;
  studentId: number;
  // cursor may be added by graphqlUtils.convertEdgesToArray, but generally should not be used
  cursor?: string;
}

export interface RawSessionAttendance extends Omit<SessionAttendance, 'meta'> {
  meta: {
    notes?: string;
    prepared?: boolean;
    attendance_start_date_time?: string; // eslint-disable-line camelcase
    attendance_end_date_time?: string; // eslint-disable-line camelcase
  };
}
