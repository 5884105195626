import { Dispatch, SetStateAction } from 'react';
import { FieldTemplateProps } from 'react-jsonschema-form';

import { TWTextDefault, TWTextSmall } from '@tw/components/presentational/typography';
import { CustomChipSizeType, theme } from '@tw/components/utils';
import { FormAssignmentCompletionRoleStatusGraphene, PageInfo } from '@tw/generated';
import { PersonNode } from '@tw/types';

import { RulesMatches } from './QuickFormBuilder/components/DrawerTabs/RulesTab/RulesTab.definitions';

export const MAX_LENGTH = 70;

export const datePickerDefaultFormat = 'MM/DD/YYYY';
export const datePickerAltFormat = 'DD/MM/YYYY';

export const customChipSize: CustomChipSizeType = {
  iconSize: theme.iconSize.medium,
  avatarFontSize: theme.baseUnit * 2,
  TextComponent: TWTextDefault,
  SubTextComponent: TWTextSmall,
  textPaddingLeft: 1,
};

export const FORM_ASSIGNMENT_TYPES = {
  onetime: 'onetime',
  perpetual: 'perpetual',
  scheduled: 'scheduled',
};

export enum QuestionType {
  TextArea = 'textarea',
  Numeric = 'numeric',
  Range = 'range',
  Checkboxes = 'checkboxes',
  Dropdown = 'dropdown',
  Date = 'date',
  DateRange = 'daterange',
  Radio = 'radio',
  Image = 'image',
  Pdf = 'pdf',
}

const questionTypeValues = Object.values<string>(QuestionType);

export const isQuestionType = (item: unknown): item is QuestionType =>
  typeof item === 'string' && questionTypeValues.includes(item);

export enum SchemaType {
  String = 'string',
  Integer = 'integer',
  Array = 'array',
}

export interface MyFormsFilters {
  pk?: number | string;
  search?: string;
  status?: FormAssignmentCompletionRoleStatusGraphene[];
  tab?: string;
  after?: string;
  first?: number;
  templateId?: string;
  folderId?: string;
  createdBy?: string;
  assignerIds?: number[];
  dueDateStatus?: string;
}

export interface MyFormsListProps {
  canFetchData?: boolean;
  containerId?: string;
  defaultFilters?: object;
  filters: MyFormsFilters;
  onPageChange?: (arg0: string) => void;
  onRowClick?: (arg0: object) => void;
  onFilterChange?: (arg0: object) => void;
  refetchAssignedForms?: boolean;
  setRefetchAssignedForms?: Dispatch<SetStateAction<boolean>>;
  setCursorHistoryData?: (cursorHistoryData: (string | null)[]) => void;
}

export type PropertiesModelKey = FieldTemplateProps['schema'] & {
  title: string;
  enum?: string[];
  flaggedOptions?: string[];
  minimum?: number;
  maximum?: number | undefined;
  description?: string;
  items?: {
    enum: string[];
  };
  src?: string | ArrayBuffer | null;
  pdfLength?: number;
  fileSize?: number;
  fileName?: string;
  snapshot?: string;
  uniqueItems?: boolean;
  dateFormat?: string;
  decimalPlaces?: number | null;
  // keep value as a string but treat as possible numeric
  asNumeric?: boolean;
  rules?: Rule[];
  ruleDependent?: { key: string; value: boolean }[];
};

export type PropertiesModel = {
  [key: string]: PropertiesModelKey;
};

export interface SchemaModel {
  description: string;
  title: string;
  type: FieldTemplateProps['schema']['type'];
  properties: PropertiesModel;
  required?: string[];
  hasFlaggedOptions?: boolean;
  hasConditionalLogic?: boolean;
}

export interface UiSchemaModel {
  [key: string]:
    | {
        [key: string]:
          | string
          | string[]
          | {
              minLabel: string | number;
              maxLabel: string | number;
            };
      }
    | string[];
}

export interface TemplateStorageProps {
  schema: SchemaModel;
  uiSchema: UiSchemaModel;
  isProcessed?: boolean;
}

export interface PreviewTemplate {
  id: string;
  label: string;
  template: TemplateStorageProps;
}

export interface FormAssigner {
  label?: string;
  value?: string;
}

export interface FormsPageInfo extends PageInfo {
  totalCount?: number;
}

export interface Conflict {
  label: string;
  key: string;
  action: string;
  questions?: string[];
}

export interface Rule {
  ifAnswer?: RulesMatches;
  option?: string;
  action?: string;
  fields?: { label: string; key: string }[];
  conflicts?: Conflict[];
  key?: string;
  index?: number;
}

export interface CompletionRoleProps {
  roleName: string;
  assignmentRole: {
    id: string;
  };
  isExternalRole: boolean;
  externalEmail: string;
  externalName: string;
  person: PersonNode;
  id: string;
}

export interface LocationParams {
  hash: string;
  key: string;
  pathname: string;
  search: string;
}

type Route = {
  [key: string]: {
    pathname: string;
    state: {
      [key: string]: boolean;
    };
  };
};

export interface LocationWithState extends LocationParams {
  fromPerpetual: boolean;
  fromAssignedToMe: boolean;
  fromDashboard: boolean;
  fromMyTasks: boolean;
  [key: string]: unknown;
}

export const routeMap: Route = {
  fromAssignedToMe: {
    pathname: '/forms/myForms/assignedToMe',
    state: { fromAssignedToMe: true },
  },
  fromDashboard: {
    pathname: '/home/overview',
    state: { fromDashboard: true },
  },
  fromMyTasks: {
    pathname: '/tasks',
    state: { fromMyTasks: true },
  },
  fromPerpetual: {
    pathname: '/forms/myForms/perpetualForms',
    state: { fromPerpetual: true },
  },
};

export type FieldWidgetType =
  | QuestionType
  | string
  | string[]
  | {
      minLabel: string | number;
      maxLabel: string | number;
    };
