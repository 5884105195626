/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalsyncCalendarUpdateStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type CalsyncCalendarUpdateStatusMutation = {
  calsyncCalendarUpdateStatus: {
    calsyncCalendar?: {
      id: string;
      relinkUrl?: string | null;
      status?: string | null;
      thirdPartyName?: string | null;
    } | null;
  };
};

export const CalsyncCalendarUpdateStatusDocument = gql`
  mutation CalsyncCalendarUpdateStatus($id: ID!) {
    calsyncCalendarUpdateStatus(id: $id) {
      calsyncCalendar {
        id
        relinkUrl
        status
        thirdPartyName
      }
    }
  }
`;
export type CalsyncCalendarUpdateStatusMutationFn = Apollo.MutationFunction<
  CalsyncCalendarUpdateStatusMutation,
  CalsyncCalendarUpdateStatusMutationVariables
>;

/**
 * __useCalsyncCalendarUpdateStatusMutation__
 *
 * To run a mutation, you first call `useCalsyncCalendarUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalsyncCalendarUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calsyncCalendarUpdateStatusMutation, { data, loading, error }] = useCalsyncCalendarUpdateStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalsyncCalendarUpdateStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CalsyncCalendarUpdateStatusMutation,
    CalsyncCalendarUpdateStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CalsyncCalendarUpdateStatusMutation,
    CalsyncCalendarUpdateStatusMutationVariables
  >(CalsyncCalendarUpdateStatusDocument, options);
}
export type CalsyncCalendarUpdateStatusMutationHookResult = ReturnType<
  typeof useCalsyncCalendarUpdateStatusMutation
>;
export type CalsyncCalendarUpdateStatusMutationResult =
  Apollo.MutationResult<CalsyncCalendarUpdateStatusMutation>;
export type CalsyncCalendarUpdateStatusMutationOptions = Apollo.BaseMutationOptions<
  CalsyncCalendarUpdateStatusMutation,
  CalsyncCalendarUpdateStatusMutationVariables
>;
