import { GraphQLPaginatedResponse } from '../graphQL';

import { StudyHall } from './studyHall';

export interface StudyHallActionNote {
  note: string;
  personId: number;
  timestamp: string;
  person: {
    fullNameNormalOrder: string;
    initials: string;
    pictureUrl: string;
  };
}

interface StudyHallActionNotes {
  checkinNote?: StudyHallActionNote;
  checkoutNote?: StudyHallActionNote;
  editNotes?: StudyHallActionNote[];
}

export interface StudyHallAction {
  id: string;
  studyHallActionId: string;
  studyHallId: number;
  studyHallPeriodId: number;
  accumulatedMinutes: number;
  autoCheckedOut: boolean;
  checkinDateTime: string;
  checkoutDateTime?: string;
  notes: StudyHallActionNotes;
  studyHall: StudyHall;
  personId: number;
  createdDate: string;
  updatedDate: string;
  createdBy: number;
  updatedBy: number;
  createdDateTime: String;
  updatedDateTime: String;
}

export interface StudyHallActionsPaginatedResponse {
  studyHallActions: GraphQLPaginatedResponse<StudyHallAction>;
}

export interface StudyHallActionsPaginatedData
  extends Omit<GraphQLPaginatedResponse<StudyHallAction>, 'edges'> {
  studyHallActions: StudyHallAction[];
}

// not an exhaustive list, these are the sort options that we are most likely to use
export enum StudyHallActionSort {
  checkinDateTimeAsc = 'checkin_date_time_asc',
  checkinDateTimeDesc = 'checkin_date_time_desc',
  checkoutDateTimeAsc = 'checkout_date_time_asc',
  checkoutDateTimeDesc = 'checkout_date_time_desc',
  autoCheckedOutAsc = 'auto_checked_out_asc',
  autoCheckedOutDesc = 'auto_checked_out_desc',
  createdDateAsc = 'created_date_asc',
  createdDateDesc = 'created_date_desc',
  updatedDateAsc = 'updated_date_asc',
  updatedDateDesc = 'updated_date_desc',
}

export interface StudyHallActionsQueryVariables {
  studyHallActionId?: string;
  studyHallId?: string;
  studyHallPeriodId?: string;
  checkinDateTime?: string;
  checkoutDateTime?: string;
  autoCheckedOut?: boolean;
  personId?: number;
  createdDateTime?: string;
  updatedDateTime?: string;
  sort?: StudyHallActionSort[];
}
