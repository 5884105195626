import { useEffect, useState } from 'react';
import type { CalendarDate } from '@internationalized/date';

import { DateRangePicker } from '@teamworksdev/react';
import { getTranslation } from '@tw/i18n';
import { datePickerDefaultFormat } from '@tw/modules/Forms/Forms.definitions';
import { DateWidgetSchema } from '@tw/modules/Forms/QuickForm/QuickForm.definitions';

import { Date, DateContainer } from './TWFormBuilderField/TWFormBuilderField.styles';

type DateRange = {
  start: CalendarDate;
  end: CalendarDate;
};

interface TWQuickFormDateProps {
  schema: DateWidgetSchema;
  onChange: (value: string) => void;
  value: string;
  formContext: { previewTemplate: boolean };
}

const TWQuickFormDateRange = ({ schema, onChange, value, formContext }: TWQuickFormDateProps) => {
  const { dateFormat } = schema;
  const { previewTemplate } = formContext;
  const [date, setDate] = useState<DateRange | null>(null);

  const handleOnChange = (event: DateRange | null) => {
    if (!event) {
      setDate(null);
      onChange('');
    } else {
      const { start, end } = event;
      const startDay = start.day.toString().padStart(2, '0');
      const startMonth = start.month.toString().padStart(2, '0');
      const startYear = start.year.toString();
      const endDay = end.day.toString().padStart(2, '0');
      const endMonth = end.month.toString().padStart(2, '0');
      const endYear = end.year.toString();
      const combined =
        dateFormat === datePickerDefaultFormat
          ? `${startMonth}/${startDay}/${startYear}-${endMonth}/${endDay}/${endYear}`
          : `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`;
      setDate(event);
      onChange(combined);
    }
  };

  useEffect(() => {
    if (!value) setDate(null);
  }, [value]);

  return (
    <DateContainer isRange previewTemplate={previewTemplate}>
      <Date>{`${getTranslation('startDate')} ${getTranslation('to')} ${getTranslation(
        'endDate',
      )}`}</Date>
      <DateRangePicker
        value={date}
        onChange={(event) => handleOnChange(event)}
        closeOnSelect
        allowUserInput
        isDisabled={previewTemplate}
        dateFormat={{ month: 'numeric', day: 'numeric', year: 'numeric' }}
        locale={dateFormat === datePickerDefaultFormat ? 'en-US' : 'en-GB'}
      />
    </DateContainer>
  );
};

export default TWQuickFormDateRange;
