/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllAppointmentsForConflictsQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type AllAppointmentsForConflictsQuery = {
  allAppointments?: {
    edges: Array<{
      node?: {
        appointmentId: string;
        isAllDay: boolean;
        isBookedSlot?: string | null;
        location: string;
        startTimeZone?: string | null;
        title?: string | null;
        appointmentType?: { appointmentTypeId?: number | null } | null;
        attendees?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | {
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{ selectionCode?: string | null } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        occurrences?: Array<{
          appointmentId?: number | null;
          appointmentOccurrenceId?: number | null;
          endDateTime?: any | null;
          startDateTime?: any | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const AllAppointmentsForConflictsDocument = gql`
  query AllAppointmentsForConflicts(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $selections: [String]!
  ) {
    allAppointments(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      selections: $selections
    ) {
      edges {
        node {
          appointmentId
          appointmentType {
            appointmentTypeId
          }
          attendees {
            label
            selectionCode
            ... on SelectionGroup {
              people {
                selectionCode
              }
            }
          }
          isAllDay
          isBookedSlot
          location
          occurrences {
            appointmentId
            appointmentOccurrenceId
            endDateTime
            startDateTime
          }
          startTimeZone
          title
        }
      }
    }
  }
`;

/**
 * __useAllAppointmentsForConflictsQuery__
 *
 * To run a query within a React component, call `useAllAppointmentsForConflictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppointmentsForConflictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppointmentsForConflictsQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useAllAppointmentsForConflictsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllAppointmentsForConflictsQuery,
    AllAppointmentsForConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllAppointmentsForConflictsQuery,
    AllAppointmentsForConflictsQueryVariables
  >(AllAppointmentsForConflictsDocument, options);
}
export function useAllAppointmentsForConflictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAppointmentsForConflictsQuery,
    AllAppointmentsForConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllAppointmentsForConflictsQuery,
    AllAppointmentsForConflictsQueryVariables
  >(AllAppointmentsForConflictsDocument, options);
}
export type AllAppointmentsForConflictsQueryHookResult = ReturnType<
  typeof useAllAppointmentsForConflictsQuery
>;
export type AllAppointmentsForConflictsLazyQueryHookResult = ReturnType<
  typeof useAllAppointmentsForConflictsLazyQuery
>;
export type AllAppointmentsForConflictsQueryResult = Apollo.QueryResult<
  AllAppointmentsForConflictsQuery,
  AllAppointmentsForConflictsQueryVariables
>;
