import styled from '@emotion/styled';

import TWIcon from '@tw/components/components.web/TWIcon/TWIcon';
import {
  TWFlexContainer,
  TWHeading3,
  TWPurifiedHtml,
  TWSpacingContainer,
  TWTextDefault,
} from '@tw/components/presentational';
import { getTranslation } from '@tw/i18n';

interface EmptyStateProps {
  testId?: string;
  icon?: string;
  headerText?: string;
  descriptionText?: string;
  bottomComponent?: JSX.Element;
}
const EmptyState = ({
  testId,
  icon = 'material-info',
  headerText = getTranslation('noData'),
  descriptionText = getTranslation('noDataDescription'),
  bottomComponent,
}: EmptyStateProps) => (
  <EmptyStateContainer align="center">
    <EmptyStateIconBackground data-testid={`${testId}:Icon`}>
      <InfoIcon type={icon} />
    </EmptyStateIconBackground>
    <TWSpacingContainer twMarginTop={1} data-testid={`${testId}:NoData`}>
      <TWHeading3>{headerText}</TWHeading3>
    </TWSpacingContainer>
    <EmptyStateTextContainer twPaddingTop={0.5} data-testid={`${testId}:NoDataDescription`}>
      <EmptyStateText>
        <TWPurifiedHtml textToRender={descriptionText} />
      </EmptyStateText>
    </EmptyStateTextContainer>
    {bottomComponent && (
      <TWSpacingContainer twMarginTop={2} data-testid={`${testId}:NoDataBottom`}>
        {bottomComponent}
      </TWSpacingContainer>
    )}
  </EmptyStateContainer>
);

const EmptyStateContainer = styled(TWFlexContainer)({
  width: '20%',
});

const EmptyStateTextContainer = styled(TWFlexContainer)({
  minWidth: 0,
  textAlign: 'center',
});

const EmptyStateText = styled(TWTextDefault)({
  whiteSpace: 'break-spaces',
});

const InfoIcon = styled(TWIcon)(({ theme }) => ({
  color: theme.colors.icon,
}));

const EmptyStateIconBackground = styled(TWFlexContainer)(({ theme }) => ({
  backgroundColor: theme.colors.highlightBold,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  height: 35,
  width: 35,
}));

export default EmptyState;
