import { Col, Row } from 'antd';
import { Children, PropsWithChildren } from 'react';

import { ActionBarWrapper } from './TWDrawerActionBar.styles';

const TWDrawerActionBar = ({ children }: PropsWithChildren<{}>) => (
  <ActionBarWrapper>
    <Row gutter={8} align="middle" className="action-bar">
      {Children.map(children, (childComponent) => (
        <Col className="drawer-action">{childComponent}</Col>
      ))}
    </Row>
  </ActionBarWrapper>
);

export default TWDrawerActionBar;
