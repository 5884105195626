/* eslint-disable react/jsx-props-no-spreading */
import { useSlate } from 'slate-react';
import { getTranslation } from '@tw/i18n';
import { BlockButton } from './BlockButton';
import { MarkButton } from './MarkButton';
import { LinkButton } from './LinkButton';
import { Dropdown } from './Dropdown';
import { ColorPicker } from './ColorPicker';
import { ToolbarGroup } from '../RichTextEditor.definitions';
import { Group, ToolbarWrapper } from '../RichTextEditor.styles';
import { HOTKEY_LABELS } from '../utils/hotkeys';
import { fontFamilyMap, sizeMap } from '../utils/slate';

const Toolbar = () => {
  const editor = useSlate();
  const toolbarGroups: ToolbarGroup[] = [
    [
      {
        id: 1,
        format: 'fontFamily',
        type: 'dropdown',
        options: [
          {
            text: getTranslation('textEditor.sansSerif'),
            value: 'sans',
            fontFamily: fontFamilyMap.sans,
          },
          {
            text: getTranslation('textEditor.serif'),
            value: 'serif',
            fontFamily: fontFamilyMap.serif,
          },
          {
            text: getTranslation('textEditor.monospace'),
            value: 'monospace',
            fontFamily: fontFamilyMap.monospace,
          },
        ],
      },
      {
        id: 2,
        format: 'fontSize',
        type: 'dropdown',
        options: [
          { text: getTranslation('textEditor.headingOne'), value: 'huge', fontSize: '2em' },
          {
            text: getTranslation('textEditor.headingTwo'),
            value: 'medium',
            fontSize: '1.6875em',
          },
          { text: getTranslation('textEditor.normal'), value: 'normal', fontSize: sizeMap.normal },
          { text: getTranslation('textEditor.small'), value: 'small', fontSize: sizeMap.small },
        ],
      },
    ],
    [
      {
        id: 3,
        accessibilityLabel: `${getTranslation('textEditor.bold')} ${HOTKEY_LABELS.bold}`,
        format: 'bold',
        type: 'mark',
      },
      {
        id: 4,
        accessibilityLabel: `${getTranslation('textEditor.italics')} ${HOTKEY_LABELS.italic}`,
        format: 'italic',
        type: 'mark',
      },
      {
        id: 5,
        accessibilityLabel: `${getTranslation('textEditor.underline')} ${HOTKEY_LABELS.underline}`,
        format: 'underline',
        type: 'mark',
      },
      {
        id: 6,
        accessibilityLabel: getTranslation('textEditor.strikethrough'),
        format: 'strikethrough',
        type: 'mark',
      },
    ],
    [
      {
        id: 7,
        accessibilityLabel: getTranslation('textEditor.textColor'),
        format: 'color',
        type: 'color-picker',
      },
      {
        id: 8,
        accessibilityLabel: getTranslation('textEditor.highlightColor'),
        format: 'backgroundColor',
        type: 'color-picker',
      },
    ],
    [
      {
        id: 9,
        accessibilityLabel: getTranslation('textEditor.superscript'),
        format: 'superscript',
        type: 'mark',
      },
      {
        id: 10,
        accessibilityLabel: getTranslation('textEditor.subscript'),
        format: 'subscript',
        type: 'mark',
      },
    ],
    [
      {
        id: 11,
        accessibilityLabel: getTranslation('textEditor.numberedList'),
        format: 'orderedList',
        type: 'block',
      },
      {
        id: 12,
        accessibilityLabel: getTranslation('textEditor.bulletedList'),
        format: 'unorderedList',
        type: 'block',
      },
    ],
    [
      {
        id: 13,
        accessibilityLabel: getTranslation('textEditor.alignLeft'),
        format: 'alignLeft',
        type: 'block',
      },
      {
        id: 14,
        accessibilityLabel: getTranslation('textEditor.alignCenter'),
        format: 'alignCenter',
        type: 'block',
      },
      {
        id: 15,
        accessibilityLabel: getTranslation('textEditor.alignRight'),
        format: 'alignRight',
        type: 'block',
      },
    ],
    [
      {
        id: 16,
        accessibilityLabel: getTranslation('link'),
        format: 'link',
        type: 'link',
      },
      {
        id: 17,
        accessibilityLabel: getTranslation('textEditor.clearFormat'),
        format: 'clear',
        type: 'block',
      },
    ],
  ];

  return (
    <ToolbarWrapper>
      {toolbarGroups.map((group, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Group key={index}>
          {group.map((element) => {
            switch (element.type) {
              case 'block':
                return <BlockButton key={element.id} editor={editor} {...element} />;
              case 'mark':
                return <MarkButton key={element.id} editor={editor} {...element} />;
              case 'dropdown':
                return <Dropdown key={element.id} editor={editor} {...element} />;
              case 'link':
                return <LinkButton key={element.id} editor={editor} {...element} />;

              case 'color-picker':
                return (
                  <ColorPicker
                    key={element.id}
                    accessibilityLabel={element.accessibilityLabel}
                    format={element.format}
                    editor={editor}
                  />
                );

              default:
                return null;
            }
          })}
        </Group>
      ))}
    </ToolbarWrapper>
  );
};

export default Toolbar;
