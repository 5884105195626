import styled from '@emotion/styled';

export const DropdownWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const Icon = styled.span({
  fontSize: 20,
});
