import { datadogLogs } from '@datadog/browser-logs';
import { StreamChat } from 'stream-chat';

type StreamAPIKey =
  | 'sendMessage'
  | 'connect'
  | 'getOrCreateChannel'
  | 'queryChannels'
  | 'markRead'
  | 'sendReaction'
  | 'deleteReaction'
  | 'search'
  | 'getReactions'
  | 'showChannel'
  | 'hideChannel'
  | 'updateChannel'
  | 'updateChannelPartial'
  | 'stopWatchingChannel'
  | 'updateMessage'
  | 'updateMessagePartial'
  | 'runMessageAction'
  | 'getReactions'
  | 'uploadImage'
  | 'uploadFile'
  | 'deleteMessage'
  | 'sync'
  | 'getApp'
  | 'unknown';

type StreamAPIRequestIntercept = NonNullable<
  Parameters<StreamChat['axiosInstance']['interceptors']['request']['use']>[0]
>;
type StreamAPIRequestConfig = Parameters<StreamAPIRequestIntercept>[0];

type MessageData = {
  id: string;
  text: string;
  selectionCodes?: string[];
};

type StreamDataObject = object & {
  message?: MessageData;
};

const isStreamDataType = (logdata: unknown): logdata is StreamDataObject =>
  !Array.isArray(logdata) && logdata !== null && typeof logdata === 'object';

/**
 * Get the Stream API key and relevant logdata from a request to log to Datadog
 */
export const getStreamRequestInfo = (
  req: StreamAPIRequestConfig,
): [key: StreamAPIKey, logdata?: object] | null => {
  const reqData = req.data ?? {};
  const method = req.method?.toUpperCase() ?? '';
  const { pathname } = new URL(req.url ?? '');
  const parts = pathname.split('/').slice(1);
  const base = parts[0];
  const tail = parts[parts.length - 1];

  if (tail === 'event') {
    // bail, typing event
    return null;
  }

  // get logdata
  let logData: object | undefined;
  let parsed;
  if (typeof reqData === 'string') {
    try {
      parsed = JSON.parse(reqData);
    } catch (er) {
      console.error('Error parsing Stream API data', reqData);
    }
  }
  const initialData = parsed ?? reqData;

  if (isStreamDataType(initialData)) {
    const { message, ...obj } = initialData;

    if (message) {
      // sanitize PII logdata, no text
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, text, ...msg } = message;
      logData = { messageId: id, ...msg, ...obj };
    } else {
      logData = initialData;
    }
  }

  const channelData = {
    ...logData,
    channelType: parts[1],
    channelId: parts.length === 4 ? parts[2] : undefined,
  };

  const messageData = {
    ...logData,
    messageId: parts[1],
    messageType: 'messaging',
  };

  switch (`${base}:${tail}:${method}`) {
    case 'app:app:GET':
      // GET /app
      return ['getApp', logData];

    case 'sync:sync:POST':
      // POST /sync
      return ['sync', logData];

    case 'search:search:GET':
    case 'search:search:POST':
      // GET /search
      return ['search', logData];

    // CHANNELS
    case 'channels:query:POST':
      // POST /channels/{type}/{id}/query
      // POST /channels/{type}/query
      return ['getOrCreateChannel', channelData];

    case 'channels:channels:POST':
      // POST /channels
      return ['queryChannels', logData];

    case 'channels:show:POST':
      // POST /channels/{type}/{id}/show
      return ['showChannel', channelData];

    case 'channels:hide:POST':
      // POST /channels/{type}/{id}/hide
      return ['hideChannel', channelData];

    case 'channels:message:POST':
      // POST /channels/{type}/{id}/message
      return ['sendMessage', { ...channelData, messageType: 'messaging' }];

    case 'channels:read:POST':
      // POST /channels/{type}/{id}/read
      return ['markRead', channelData];

    case 'channels:image:POST':
      // POST /channels/{type}/{id}/image
      return ['uploadImage', channelData];

    case 'channels:file:POST':
      // POST /channels/{type}/{id}/file
      return ['uploadFile', channelData];

    // MESSAGES
    case 'messages:reaction:POST':
      // POST /messages/{id}/reaction
      return ['sendReaction', messageData];

    case 'messages:reactions:POST':
      // GET /messages/{id}/reactions
      return ['getReactions', messageData];

    case 'messages:action:POST':
      // POST /messages/{id}/action
      return ['runMessageAction', messageData];

    default:
  }

  // Base with dynamic tails and method.
  switch (`${base}:${method}`) {
    // --- CHANNELS ---

    case 'channels:PATCH':
      // PATCH /channels/{type}/{id}
      return [
        'updateChannelPartial',
        {
          ...channelData,
          channelId: parts[2],
        },
      ];

    case 'channels:POST':
      // POST /channels/{type}/{id}
      return ['updateChannel', { ...channelData, channelId: parts[2] }];

    // MESSAGES
    case 'messages:POST':
      // POST /messages/{id}
      return ['updateMessage', messageData];

    case 'messages:PUT':
      // PUT /messages/{id}
      return ['updateMessagePartial', messageData];

    case 'messages:DELETE':
      if (!parts?.includes('reaction')) {
        // DELETE /messages/{id}
        return ['deleteMessage', messageData];
      }
      break;

    default:
  }

  if (base === 'messages' && parts?.includes('reaction') && method === 'DELETE') {
    // DELETE /messages/{id}/reaction/{type}
    return ['deleteReaction', { ...messageData, reaction: parts[3] }];
  }

  return ['unknown'];
};

type StreamApiLogContext = {
  userId?: string;
  [key: string]: unknown;
};

export const logStreamRequest = (key: StreamAPIKey, logdata?: StreamApiLogContext) =>
  datadogLogs.logger.info('stream.api.request', {
    streamAPI: key,
    ...logdata,
    time: Date.now(),
  });
