import { TWInputSelect, TWInvisibleLabel } from '@tw/components';
import { useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';

interface TeamFilterProps {
  value?: string;
  onChange: (value: string | null) => void;
  loading?: boolean;
  includesCustomGroups?: boolean;
  includesAllTeams?: boolean;
  getPopupContainer?: (triggerEl: HTMLElement) => HTMLElement;
  onDropdownVisibleChange?: () => void;
  disabled: boolean;
  viewCanModifyUsersTeamOnly?: boolean;
}

const TeamFilter = ({
  loading,
  value,
  onChange,
  includesCustomGroups = true,
  includesAllTeams = true,
  getPopupContainer = (triggerEl) => triggerEl.closest('.ant-modal-content') ?? document.body,
  viewCanModifyUsersTeamOnly,
  ...restOfProps
}: TeamFilterProps) => {
  const viewer = useViewer();

  const teamsList =
    viewer.availableTeams?.reduce<{ value: string; label: string }[]>((acc, team) => {
      if (!viewCanModifyUsersTeamOnly || team.canAddToTeam) {
        acc.push({ label: team.label, value: team.id });
      }
      return acc;
    }, []) ?? [];

  if (viewer.person.isOrgLevelUser) {
    // Don't add this option to the custom groups creation modal
    if (includesCustomGroups) {
      // passing null as a value to the antd select will give us an empty string in the change handler
      teamsList.unshift({ label: getTranslation('profiles.filters.acrossTeams'), value: 'null' });
    }

    if (includesAllTeams) {
      teamsList.unshift({
        label: getTranslation('allTeams'),
        value: viewer.currentOrg.allTeamsTeamId,
      });
    }
  }

  const handleChange = (newTeam: string) => {
    // we need to convert the across teams value back to null for the parent component
    onChange(newTeam === 'null' ? null : newTeam);
  };

  return (
    <>
      <TWInputSelect
        filterOption
        optionFilterProp="title"
        getPopupContainer={getPopupContainer}
        id="UserSelectionTeamFilter"
        testID="UserSelection:teamSelectInput"
        placeholder={getTranslation('teams', 1)}
        itemList={teamsList}
        minWidth="155px"
        loading={loading}
        onChange={handleChange}
        // the parent component sends null as a value for across teams, so we make it match here
        value={value === null ? 'null' : value}
        {...restOfProps}
      />
      <TWInvisibleLabel>
        <label htmlFor="UserSelectionTeamFilter">{getTranslation('teams', 1)}</label>
      </TWInvisibleLabel>
    </>
  );
};

export default TeamFilter;
