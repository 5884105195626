import { ConfigContainerType, UpdateProfessorSurveyAssignmentSchema } from '@tw/types';
import { pythonApiBackend } from '@tw/backends';

import academicServiceStoreConfig from '../academicServiceStoreConfig';

const professorSurveyAssignmentOperations = {
  updateProfessorSurveyAssignment(
    professorSurveyAssignmentId: string | number,
    updatedValues: UpdateProfessorSurveyAssignmentSchema,
    configContainer: ConfigContainerType,
  ) {
    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: `professor_surveys/professor_survey_assignments/${professorSurveyAssignmentId}::form`,
        debugLabel: 'updateProfessorSurveyAssignments',
        includeAuthenticationHeader: false,
      },
      {
        // fetch body
        method: 'POST',
        body: updatedValues,
      },
      configContainer,
    );
  },
};

export default professorSurveyAssignmentOperations;
