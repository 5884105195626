import _ from 'lodash';
import { Buffer } from 'buffer';

// These utilities are copied from https://github.com/graphql/graphql-relay-js
const PREFIX = 'arrayconnection:';
const base64 = (i: string) => Buffer.from(i, 'utf8').toString('base64');
const unbase64 = (i: string) => Buffer.from(i, 'base64').toString('utf8');
const cursorToOffset = (cursor: string) => parseInt(unbase64(cursor).substring(PREFIX.length), 10);
const offsetToCursor = (offset: number) => base64(PREFIX + offset);
const getOffsetWithDefault = (cursor: string, defaultOffset: number) => {
  if (typeof cursor !== 'string') {
    return defaultOffset;
  }
  const offset = cursorToOffset(cursor);
  return Number.isNaN(offset) ? defaultOffset : offset;
};

// Takes a type name and an ID specific to that type name, and returns a "global ID" that is unique among all types.
const toGlobalId = (type: string, id: string | number): string => base64([type, id].join(':'));

const toGlobalIdList = (pkList: (string | number)[], type: string) =>
  _.map(pkList, (pk) => toGlobalId(type, String(pk)));

// Takes the "global ID" created by toGlobalID, and returns the ID
const fromGlobalId = (globalId: string): string => {
  const unbasedGlobalId = unbase64(globalId);
  const delimiterPos = unbasedGlobalId.indexOf(':');
  return unbasedGlobalId.substring(delimiterPos + 1);
};

const connectionFromArraySlice = (arraySlice, args, meta) => {
  const { after, before, first, last } = args;
  const {
    sliceStart,
    arrayLength,
    nodeAlias = 'node',
    connectionType = 'Connection',
    edgeType = 'Edge',
  } = meta;
  const sliceEnd = sliceStart + arraySlice.length;
  const beforeOffset = getOffsetWithDefault(before, arrayLength);
  const afterOffset = getOffsetWithDefault(after, -1);

  let startOffset = Math.max(sliceStart - 1, afterOffset, -1) + 1;
  let endOffset = Math.min(sliceEnd, beforeOffset, arrayLength);
  if (typeof first === 'number') {
    if (first < 0) {
      throw new Error('Argument "first" must be a non-negative integer');
    }

    endOffset = Math.min(endOffset, startOffset + first);
  }
  if (typeof last === 'number') {
    if (last < 0) {
      throw new Error('Argument "last" must be a non-negative integer');
    }

    startOffset = Math.max(startOffset, endOffset - last);
  }
  // If supplied slice is too large, trim it down before mapping over it.
  const slice = arraySlice.slice(
    Math.max(startOffset - sliceStart, 0),
    arraySlice.length - (sliceEnd - endOffset),
  );

  const edges = slice.map((value, index) => ({
    __typename: edgeType,
    cursor: offsetToCursor(startOffset + index),
    [nodeAlias]: value,
  }));

  const firstEdge = edges[0];
  const lastEdge = edges[edges.length - 1];
  const lowerBound = after ? afterOffset + 1 : 0;
  const upperBound = before ? beforeOffset : arrayLength;

  return {
    __typename: connectionType,
    edges,
    pageInfo: {
      __typename: 'PageInfo',
      startCursor: firstEdge ? firstEdge.cursor : null,
      endCursor: lastEdge ? lastEdge.cursor : null,
      hasPreviousPage: typeof last === 'number' ? startOffset > lowerBound : false,
      hasNextPage: typeof first === 'number' ? endOffset < upperBound : false,
    },
    totalCount: arrayLength,
  };
};

const connectionFromArray = (data, args, meta) =>
  connectionFromArraySlice(data, args, {
    ...meta,
    sliceStart: 0,
    arrayLength: data.length,
  });

export default {
  connectionFromArray,
  connectionFromArraySlice,
  fromGlobalId,
  toGlobalId,
  toGlobalIdList,
};
