import { useCallback } from 'react';
import { useQuery, useLazyQuery, QueryHookOptions, LazyQueryHookOptions } from '@apollo/client';
import _ from 'lodash';

import {
  CourseSectionQueryFilters,
  CourseSectionRawQueryFilters,
  CourseSectionsRawPaginatedData,
} from '@tw/types';
import { courseSectionModel } from '@tw/services/academicService';

import { FetchCourseSections } from './FetchCourseSections.query';

export const useCourseSections = (
  variables?: CourseSectionQueryFilters,
  additionalArgs?: QueryHookOptions<CourseSectionsRawPaginatedData, CourseSectionRawQueryFilters>,
) => {
  const { data, ...rest } = useQuery<CourseSectionsRawPaginatedData, CourseSectionRawQueryFilters>(
    FetchCourseSections,
    {
      variables: courseSectionModel.normalizeVariables(variables || {}),
      skip: _.isEmpty(variables?.termId),
      ...additionalArgs,
    },
  );

  return {
    data: courseSectionModel.normalizeRawPaginatedCourseSectionData(data),
    ...rest,
  };
};

export const useCourseSectionsLazy = (
  variables?: CourseSectionQueryFilters,
  additionalArgs?: LazyQueryHookOptions<
    CourseSectionsRawPaginatedData,
    CourseSectionRawQueryFilters
  >,
) => {
  const [fetchCourseSectionsRaw, { data, ...rest }] = useLazyQuery<
    CourseSectionsRawPaginatedData,
    CourseSectionRawQueryFilters
  >(FetchCourseSections, {
    variables: courseSectionModel.normalizeVariables(variables || {}),
    ...additionalArgs,
  });

  const fetchCourseSections = useCallback(
    (
      fetchVariables?: CourseSectionQueryFilters,
      fetchArgs?: LazyQueryHookOptions<CourseSectionsRawPaginatedData, CourseSectionQueryFilters>,
    ) =>
      fetchCourseSectionsRaw({
        ...fetchArgs,
        variables: courseSectionModel.normalizeVariables(fetchVariables || {}),
      }),
    [fetchCourseSectionsRaw],
  );

  return {
    fetchCourseSections,
    data: courseSectionModel.normalizeRawPaginatedCourseSectionData(data),
    ...rest,
  };
};
