import styled from '@emotion/styled';
import { Avatar } from 'antd';

export default styled(Avatar)<{ size?: number }>(({ size, theme }) => ({
  background: theme.colors.iconBackground,
  '&.ant-avatar': {
    color: theme.colors.icon,
    display: 'block',
    fontWeight: theme.fontWeight.regular,
    'svg, span.material-icons, span.material-icons-outlined': {
      width: size,
      height: size,
      lineHeight: size !== undefined ? `${size}px` : undefined,
      fontSize: size !== undefined ? Math.ceil(size * 0.6) : undefined,
      verticalAlign: 'middle',
      marginBottom: '4px',
    },
  },
}));
