import styled from '@emotion/styled';
import { sharedSpacingStyles, SpacingComponentProps } from '../../../utils';

interface ContainerProps {
  isOutlined: boolean;
  isSelected: boolean;
  selectable: boolean;
  onClick: () => void;
}

export const Container = styled.div<SpacingComponentProps & ContainerProps>(
  ({ theme, isOutlined, isSelected, selectable }) => [
    sharedSpacingStyles,
    {
      display: 'flex',
      flex: 1,
      minWidth: 0,
      alignItems: 'center',
      justifyContent: 'space-between',
      userSelect: 'none',
      cursor: selectable ? 'pointer' : 'inherit',
      outline: isOutlined ? `1px solid ${theme.colors.border}` : 'inherit',
      transition: theme.cssValues.transition,
      ':focus': {
        outline: 0,
        border: 'none',
        background: theme.colors.border,
      },
      ':hover': {
        backgroundColor: selectable ? theme.colors.highlightBold : null,
      },
    },
    isSelected && {
      borderRight: `3px solid ${theme.colors.link}`,
      backgroundColor: theme.colors.highlightBold,
    },
  ],
);

Container.defaultProps = {
  twPadding: [0.5, 1],
};
