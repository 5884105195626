const $twListItem = {
  backgroundColor: '$colorHue.white',
  backgroundColorDark: '$colorHue.grayExtremelyLight',
  borderColor: '$colorHue.grayMid',
  borderWidth: '$borderWidth.small',
  iconColor: '$colorHue.grayBlue',
  contentHeight: '$twListItem.totalHeight - $twListItem._doubleVerticalPadding',
  totalHeight: 50, // this is contentHeight + doubleVerticalPadding
  horizontalPadding: '$whitespaceOld.large',
  verticalPadding: '$whitespaceOld.medium',
  paddingLeft: '$whitespaceOld.large',

  // We can only do super-simple two-operand operations with EStyleSheet,
  // so we need this as a temporary var to do the math for contentHeight.
  // @FIXME @BUG: There's currently an issue where if an expression *doesn't* start with a '$' then it won't
  // be evaluated properly, resulting in NaN.
  _doubleVerticalPadding: '$twListItem.verticalPadding * 2',
};

const $twListItemInverse = {
  ...$twListItem,
  backgroundColor: '$colorHue.grayDark',
  borderColor: '$colorHue.grayVeryDark',
  paddingLeft: '$whitespaceOld.large',
};

const $twListItemUserSelection = {
  ...$twListItem,
  paddingLeft: 0,
};

const $twListItemConversationUnread = {
  fontWeight: 'bold',
  backgroundColor: '#F1F9DA',
};

export { $twListItem, $twListItemInverse, $twListItemUserSelection, $twListItemConversationUnread };
