/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PersonsWhoCanViewBulletinQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  bulletinId: Types.Scalars['Int'];
  customSort?: Types.InputMaybe<Types.Scalars['String']>;
  searchPerson?: Types.InputMaybe<Types.Scalars['String']>;
  bulletinClickStatus?: Types.InputMaybe<Types.BulletinClickStatusEnum>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type PersonsWhoCanViewBulletinQuery = {
  personsWhoCanViewBulletin?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        pk?: number | null;
        firstName?: string | null;
        lastName?: string | null;
        pictureUrl?: string | null;
        hasClicked?: boolean | null;
        lastClickedDate?: any | null;
      } | null;
    } | null>;
  } | null;
};

export const PersonsWhoCanViewBulletinDocument = gql`
  query PersonsWhoCanViewBulletin(
    $first: Int
    $bulletinId: Int!
    $customSort: String
    $searchPerson: String
    $bulletinClickStatus: BulletinClickStatusEnum
    $before: String
    $after: String
  ) {
    personsWhoCanViewBulletin(
      first: $first
      bulletinId: $bulletinId
      customSort: $customSort
      searchPerson: $searchPerson
      bulletinClickStatus: $bulletinClickStatus
      before: $before
      after: $after
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          pk
          firstName
          lastName
          pictureUrl
          hasClicked
          lastClickedDate
        }
      }
    }
  }
`;

/**
 * __usePersonsWhoCanViewBulletinQuery__
 *
 * To run a query within a React component, call `usePersonsWhoCanViewBulletinQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonsWhoCanViewBulletinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsWhoCanViewBulletinQuery({
 *   variables: {
 *      first: // value for 'first'
 *      bulletinId: // value for 'bulletinId'
 *      customSort: // value for 'customSort'
 *      searchPerson: // value for 'searchPerson'
 *      bulletinClickStatus: // value for 'bulletinClickStatus'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePersonsWhoCanViewBulletinQuery(
  baseOptions: Apollo.QueryHookOptions<
    PersonsWhoCanViewBulletinQuery,
    PersonsWhoCanViewBulletinQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonsWhoCanViewBulletinQuery, PersonsWhoCanViewBulletinQueryVariables>(
    PersonsWhoCanViewBulletinDocument,
    options,
  );
}
export function usePersonsWhoCanViewBulletinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonsWhoCanViewBulletinQuery,
    PersonsWhoCanViewBulletinQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PersonsWhoCanViewBulletinQuery,
    PersonsWhoCanViewBulletinQueryVariables
  >(PersonsWhoCanViewBulletinDocument, options);
}
export type PersonsWhoCanViewBulletinQueryHookResult = ReturnType<
  typeof usePersonsWhoCanViewBulletinQuery
>;
export type PersonsWhoCanViewBulletinLazyQueryHookResult = ReturnType<
  typeof usePersonsWhoCanViewBulletinLazyQuery
>;
export type PersonsWhoCanViewBulletinQueryResult = Apollo.QueryResult<
  PersonsWhoCanViewBulletinQuery,
  PersonsWhoCanViewBulletinQueryVariables
>;
