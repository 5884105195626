import { StudyHallActionsPaginatedData, StudyHallActionsPaginatedResponse } from '@tw/types';
import { graphqlUtils } from '@tw/util';

export const transformRawStudyHallActionData = (
  input: StudyHallActionsPaginatedResponse,
): StudyHallActionsPaginatedData => {
  const { studyHallActions } = input;
  const { edges, ...rest } = studyHallActions;

  return {
    studyHallActions: graphqlUtils.convertEdgesToArray(edges),
    ...rest,
  };
};
