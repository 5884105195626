import styled from '@emotion/styled';
import _ from 'lodash';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect/TWInputSelect';

type TWInputSelectBorderlessProps = Omit<TWInputSelectProps, 'bordered'>;

const StyledSelect = styled(TWInputSelect)(({ theme, value }) => ({
  '&.ant-select': {
    '&:not(.ant-select-open)': {
      '.ant-select-selection-item': {
        fontWeight: theme.fontWeight.semibold,
      },
    },
    '&:not(.ant-select-open):not(.ant-select-focused)': {
      '.ant-select-arrow, .ant-select-clear': {
        color: !_.isEmpty(value) ? theme.colors.linkIcon : undefined,
      },
      '.ant-select-selection-item': {
        color: theme.colors.text,
      },
    },
    '.ant-select-selector .ant-select-selection-search input.ant-select-selection-search-input': {
      outline: 'none !important',
    },
    '.ant-select-selection-placeholder': {
      color: theme.colors.secondary,
    },
  },
}));

const TWInputSelectBorderless: React.FC<TWInputSelectBorderlessProps> = (props) => (
  <StyledSelect {...props} bordered={false} />
);

export default TWInputSelectBorderless;
