import _ from 'lodash';
import moment from 'moment';

import { makeConstantsObject } from '../constants';

// Services don't have many of their own constants, because anything model-related that they
// care about is probably also useful/noteworthy/interesting to another layer in the app --
// so this is pretty sparse. Sorry! :)

const INITIAL_PAGINATION_CURSOR = '';

/**
 * For consistency, all services should provide two selectors that our storage system will use:
 * whatever the selectors return will be stored offline, then restored the next time the app
 * is launched.
 *
 * One selector returns user-independent data ("general") while the other stores user-specific
 * data ("user") -- but most services don't need to store general data (and some don't persist
 * anything) so this no-op is provided *for readability only*, to make it clear what the
 * intent is
 */
const storageSelectorDoesNotPersistAnything = () => null;

const minTimeBetweenStorageBackups = 2000;
const maxTimeBetweenStorageBackups = 6000;

/**
 * Each activityNotice indicates a specific action on a specific target.
 * The backend provides several different fields to describe the target and its properties,
 * which activityNoticeModel massages into one of these values.
 */
const ACTIVITY_NOTICE_TARGETS = makeConstantsObject(
  [
    'UNKNOWN',
    // Real target types that align with backend entries:
    'ANNOUNCEMENT', // verbed an announcement
    'APPOINTMENT_OCCURRENCE', // verbed an occurrence of an appointment
    'APPOINTMENT_SERIES', // verbed an appointment
    'FILE_SHARED', // verbed a file
    'FILE_SHARED_MULTIPLE', // verbed 123 files
    'FILE_TRAVEL', // ?
    'FILE_TRAVEL_MULTIPLE', // ?
    'FORM_ASSIGNABLE', // made a form available available to you (perpetual)
    'FORM_COMPLETABLE', // assigned a form to you
    'NOTE', // a note created/updated and made visible to you
    'VIDEO', // verbed a video
    // UI-created target types:
    'GAP',
    // @TODO: 'BIRTHDAY',
  ],
  {
    // The backend's "objectType" values usually map cleanly to the UI constants above. They are:
    Appointment: 'APPOINTMENT_SERIES',
    Announcement: 'ANNOUNCEMENT',
    AppointmentException: 'APPOINTMENT_OCCURRENCE',
    Note: 'NOTE',
    SubmittableFormAssignment: 'FORM_ASSIGNABLE',
    // Note: a "completion" record indicates it _should be_ completed: it's the bucket where the Answer records go.
    SubmittableFormAssignmentCompletion: 'FORM_COMPLETABLE',
    MultipleFile: 'FILE_SHARED_MULTIPLE',
    SharedFile: 'FILE_SHARED',
    TravelFile: 'FILE_TRAVEL',
    Video: 'VIDEO',

    // And, if all else fails, the backend's "activityType" value can be used as a fallback
    // (These are left as-is, instead of being uppercased, because there's generally not a clean overlap
    //  between how the backend breaks things down and how we do)
    announcement: 'ANNOUNCEMENT',
    multipleFile: 'FILE_SHARED_MULTIPLE',
    video: 'VIDEO',
    // Skipped: 'appointment' could be APPOINTMENT_SERIES or APPOINTMENT_OCCURRENCE
    // Skipped: 'file' could be FILE_SHARED or FILE_SHARED_MULTIPLE
    // Skipped: 'form' could be FORM_ASSIGNABLE or FORM_COMPLETABLE
    // Skipped: 'travelFile' could be FILE_TRAVEL or FILE_TRAVEL_MULTIPLE
  },
);

const ACTIVITY_NOTICE_ACTIONS = makeConstantsObject(
  ['UNKNOWN', 'CREATE', 'UPDATE', 'DELETE', 'CUSTOM'],
  {
    // We use CRUD language in the UI, even though the backend doesn't
    ADD: 'CREATE',
    // 'cancel' does not exist in any data newer than 2013, but we treat it the same as delete
    CANCEL: 'DELETE',
  },
);

const ACTIVITY_NOTICE_TARGET_VALUES = _.values(ACTIVITY_NOTICE_TARGETS);
const ACTIVITY_NOTICE_ACTION_VALUES = _.values(ACTIVITY_NOTICE_ACTIONS);

const WEBLINK_SPECIAL_MOBILE_CODES = makeConstantsObject(['SAFETY'], {
  // LiveSafe is the 'special' mobile weblink that's used for the "Safety and Security" link on the Other tab:
  // it's sort of like an international emergency/911 system.
  LIVESAFE: 'SAFETY',
});

// these are ISO 8601 date constants formatted in UTC for use in
// fake backend responses to simulate dateTime responses from python backend
const dateTimeNowUTC = moment(new Date()).utc().format();
const dateTimeYesterdayUTC = moment(new Date()).subtract(1, 'days').utc().format();
const dateTimeTomorrowUTC = moment(new Date()).add(1, 'days').utc().format();
const dateTimeLastYearUTC = moment(new Date()).subtract(1, 'years').utc().format();

// The backend does not update calendar events synchronously, so we need an extra delay after CUD on events.
// This value is just a guess, but it seems to work well in the end-to-end tests.
const delayAfterModifyingCalendarEvent = 1500;

// list of possible types for trip itinerary event
const travelEventTypes = [
  {
    label: 'General',
    value: 'General',
  },
  {
    label: 'Hotel',
    value: 'Hotel',
  },
  {
    label: 'Ground',
    value: 'Ground',
  },
  {
    label: 'Flight',
    value: 'Flight',
  },
  {
    label: 'Meeting',
    value: 'Meeting',
  },
  {
    label: 'Competition',
    value: 'Competition',
  },
  {
    label: 'Meal',
    value: 'Meal',
  },
];

const tripCompetitionLocationTypes = [
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Neutral',
    value: 'neutral',
  },
  {
    label: 'Away',
    value: 'away',
  },
];

export {
  ACTIVITY_NOTICE_ACTIONS,
  ACTIVITY_NOTICE_ACTION_VALUES,
  ACTIVITY_NOTICE_TARGETS,
  ACTIVITY_NOTICE_TARGET_VALUES,
  WEBLINK_SPECIAL_MOBILE_CODES,
  INITIAL_PAGINATION_CURSOR,
  storageSelectorDoesNotPersistAnything,
  minTimeBetweenStorageBackups,
  maxTimeBetweenStorageBackups,
  dateTimeNowUTC,
  dateTimeYesterdayUTC,
  dateTimeTomorrowUTC,
  dateTimeLastYearUTC,
  delayAfterModifyingCalendarEvent,
  travelEventTypes,
  tripCompetitionLocationTypes,
};
