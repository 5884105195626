import { useState } from 'react';
import { Col, Form, Input, Row } from 'antd';

import { getTranslation } from '@tw/i18n';
import { TWForm, TWMessage, TWFormItem, TWTextDefault, TWSpacingContainer } from '@tw/components';
import { composeFormRules } from '@tw/util';
import { useRequestPasswordResetMutation } from '@tw/generated';
import { Button } from '@teamworksdev/react';

import { AuthTemplate, ButtonContainer, FooterLink } from '../@components';

type FormValues = {
  username: string;
};

export default function ForgotPassword() {
  const [submitComplete, setSubmitComplete] = useState(false);
  const [requestPasswordReset, { loading }] = useRequestPasswordResetMutation({
    onCompleted: ({ requestPasswordReset: { ok } }) => {
      if (ok) {
        setSubmitComplete(true);
      }
    },
    onError: (error) => TWMessage.errorHandler(error),
  });

  const [form] = Form.useForm();

  const onFormFinish = (values: FormValues) => {
    requestPasswordReset({ variables: { username: values.username } });
  };

  const ForgotPasswordLinks = (
    <FooterLink
      links={[
        { linkText: getTranslation('authentication.forgotUsername'), urlPath: '/forgotUsername' },
      ]}
      contactSupport
    />
  );

  return !submitComplete ? (
    <AuthTemplate
      heading={getTranslation('authentication.forgotPassword')}
      helperText={
        <TWTextDefault>{getTranslation('authentication.forgotPasswordHelper')}</TWTextDefault>
      }
      footerLinks={ForgotPasswordLinks}
    >
      <Row>
        <Col span={24}>
          <TWSpacingContainer twMargin={[3, 0]}>
            <TWForm form={form} name="ForgotPasswordForm" size="large" onFinish={onFormFinish}>
              <TWFormItem
                name="username"
                label={getTranslation('username')}
                rules={composeFormRules('required')}
              >
                <Input
                  type="username"
                  autoFocus
                  placeholder={getTranslation('authentication.enterUsername')}
                  data-testid="Authentication:LoginPage:username"
                  aria-label={getTranslation('username')}
                />
              </TWFormItem>
            </TWForm>
          </TWSpacingContainer>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ButtonContainer>
            <Button
              data-testid="Authentication:LoginPage:loginActionButton"
              style={{ maxWidth: '100%' }}
              isLoading={loading}
              onClick={form.submit}
            >
              {getTranslation('resetPassword')}
            </Button>
          </ButtonContainer>
        </Col>
      </Row>
    </AuthTemplate>
  ) : (
    <AuthTemplate
      heading={getTranslation('authentication.resetEmailSent')}
      helperText={
        <>
          <TWSpacingContainer twMarginBottom={2}>
            {getTranslation('authentication.passwordResetSentHelper')}
          </TWSpacingContainer>
          <TWSpacingContainer twMarginBottom={2}>
            {getTranslation('authentication.accountRecoveryHelpText')}
            <ul style={{ lineHeight: 2 }}>
              <li>{getTranslation('authentication.accountRecoveryHelpTextSSO')}</li>
              <li>{getTranslation('authentication.accountRecoveryHelpTextPassword')}</li>
            </ul>
          </TWSpacingContainer>
        </>
      }
      footerLinks={
        <FooterLink backLink={getTranslation('navigation.backToSignInOptions')} contactSupport />
      }
    />
  );
}
