import _ from 'lodash';

export const hasSelectIgnore = (item: any): item is { selectIgnore: boolean } =>
  !_.isNil(item?.selectIgnore);

export const hasSelectIsActive = (item: any): item is { isActive: boolean } =>
  !_.isNil(item?.isActive);

export const hasSelectionPrimaryKey = (
  item: any,
  selectionPrimaryKey: string,
): item is { [selectionPrimaryKey: string]: React.ReactText } => _.has(item, selectionPrimaryKey);
