import styled from '@emotion/styled';
import { Radio } from 'antd';

interface TWQuickFormRadioButtonProps {
  value: string;
  options: {
    enumOptions?: {
      label: string;
      value: string;
    }[];
  };
  schema: {
    title?: string;
    isEmptyQuestion?: boolean;
  };
  readonly: boolean;
  disabled: boolean;
  onChange: (value: string) => void;
}

const TWQuickFormRadioButton = (props: TWQuickFormRadioButtonProps) => {
  const {
    options: { enumOptions },
    value,
    disabled,
    readonly,
    onChange,
    schema,
  } = props;

  // HACK: don't show empty options that we generate to make jsonschema validation happy with
  // empty radio questions (used as section headers)
  if (schema?.isEmptyQuestion) {
    return null;
  }

  return (
    <Radio.Group
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    >
      {enumOptions?.map((option) => (
        <Container key={option.value}>
          <Radio value={option.value} disabled={disabled || readonly}>
            {option.label}
          </Radio>
        </Container>
      ))}
    </Radio.Group>
  );
};

const Container = styled.span(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row',
  margin: `${theme.baseUnit}px`,
}));

export default TWQuickFormRadioButton;
