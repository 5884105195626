/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateApptTypeMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.AppointmentTypeInput>;
}>;

export type CreateApptTypeMutation = {
  appointmentTypeCreate: { appointmentType?: { pk?: string | null } | null };
};

export const CreateApptTypeDocument = gql`
  mutation CreateApptType($input: AppointmentTypeInput) {
    appointmentTypeCreate(input: $input) {
      appointmentType {
        pk
      }
    }
  }
`;
export type CreateApptTypeMutationFn = Apollo.MutationFunction<
  CreateApptTypeMutation,
  CreateApptTypeMutationVariables
>;

/**
 * __useCreateApptTypeMutation__
 *
 * To run a mutation, you first call `useCreateApptTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApptTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApptTypeMutation, { data, loading, error }] = useCreateApptTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApptTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApptTypeMutation, CreateApptTypeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApptTypeMutation, CreateApptTypeMutationVariables>(
    CreateApptTypeDocument,
    options,
  );
}
export type CreateApptTypeMutationHookResult = ReturnType<typeof useCreateApptTypeMutation>;
export type CreateApptTypeMutationResult = Apollo.MutationResult<CreateApptTypeMutation>;
export type CreateApptTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateApptTypeMutation,
  CreateApptTypeMutationVariables
>;
