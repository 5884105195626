import _ from 'lodash';

import { DATA_STATUS, FETCH_STATUS } from '@tw/constants';
import { BackendOperationType } from './operation.definitions';

// A backendOperation is an object that tracks the status of some data that we fetch from
// somewhere or send to somewhere, generally via ajax. Each backendOperation represents
// a single 'dataset' (which, today, always means a single ajax request -- although that's
// not a fixed constraint), and they're stored in Redux under operation-specific keys.
//
// "read" and "write" operations are *slightly* different, but they work the same way.
//
// The backendOperation tracks information about:
//  - Our last attempt to fetch or write the data
//  - Whether we have data from a prior successful attempt (for reads only)
//  - The action and params and such that were used to request the data
//
// This file includes standalone functions that transform backendOperations when we
// begin, resolve, or reject requests. In general, only reducers should use these functions.

/**
 * backendOperations were originally always treated as proper immutable objects, but for background refreshes
 * it doesn't make sense to create a new object unless the status is actually different somehow, because
 * the new object will cause any screens that select the backendOperation to rerender.
 *
 * So this is an experiment in having the backendOperation object be *semi*-immutable: it will only yield
 * a new object instance if there's a noteworthy change in status.
 *
 * @type {boolean}
 */
const alwaysImmutable = false;

/**
 * This is the default look of brand new, never-touched backendOperation.
 */
const initialReadOperation = {
  // Note that actionType is NOT included here: it MUST be provided!
  fetchStatus: FETCH_STATUS.NULL,
  dataStatus: DATA_STATUS.ABSENT,
  message: null,
  lastFetchStatusTime: 0,
  lastFetchFailed: false,
  lastDataStatusTime: 0,
} as Partial<BackendOperationType>;

const readOperationFieldsToPullFromParent = [
  'fetchStatus',
  'dataStatus',
  'lastFetchStatusTime',
  'lastDataStatusTime',
];

const initialWriteOperation = {
  // Note that actionType is NOT included here: it MUST be provided!
  fetchStatus: FETCH_STATUS.NULL,
  message: null,
  lastFetchStatusTime: 0,
} as Partial<BackendOperationType>;

// BUT TWFetchData needs an actionType, so we'll export a helper that gives you that.
// This is really just syntactic sugar, but requiring people to use this ensures that
// we always have actionType.
// Note that we'll pull in any status (dataStatus, fetchStatus, etc) from the parent
// operation (which *should* be fetchAllItemsForOrg) to seed the initial status.
const initialReadOperationForAction = (
  actionType: string,
  fieldsToAdd: Object = {},
  parentOperation = null,
): BackendOperationType => ({
  ...initialReadOperation,
  ...(parentOperation ? _.pick(parentOperation, readOperationFieldsToPullFromParent) : {}),
  ...fieldsToAdd,
  actionType,
});

const initialWriteOperationForAction = (
  actionType: string,
  fieldsToAdd: Partial<BackendOperationType> = {},
): BackendOperationType => ({
  ...initialWriteOperation,
  ...fieldsToAdd,
  actionType,
});

/**
 * These are intended for use in reducers, when a __BEGIN action comes in:
 * For read operations, we'll retain any prior dataStatus, but everything else gets reset.
 * Write operations are always a new, blank backendOperation.
 *
 * Note that you'll always want to specify `actionType` in fieldsToAdd, along with any IDs.
 */
const beginReadOperation = (
  previousOperation = initialReadOperation,
  fieldsToAdd: Object = {},
): BackendOperationType => {
  const fieldsForNewOperation = {
    // We re-initialize the entire operation state (except actionType and dataStatus) on __BEGIN.
    // (resolve/reject *don't* re-initialize: they carry through any IDs or other fields from before)
    actionType: previousOperation.actionType,
    fetchStatus: FETCH_STATUS.PENDING,
    dataStatus: previousOperation.dataStatus,
    message: null,
    lastFetchStatusTime: Date.now(),
    lastDataStatusTime: previousOperation.lastDataStatusTime,
    ...fieldsToAdd,
  };
  if (alwaysImmutable || previousOperation.dataStatus !== DATA_STATUS.PRESENT) {
    return fieldsForNewOperation;
  }
  Object.assign(previousOperation, fieldsForNewOperation);

  return previousOperation as BackendOperationType;
};
// Note that the 'write' works the same way as 'read', but instead of calling the base
// operation "previousOperation" we call it "initialOperation" since its role is different.
const beginWriteOperation = (
  initialOperation = initialWriteOperation,
  fieldsToAdd: Object = {},
): BackendOperationType => ({
  actionType: initialOperation.actionType,
  fetchStatus: FETCH_STATUS.PENDING,
  message: null,
  lastFetchStatusTime: Date.now(),
  ...fieldsToAdd,
});

/**
 * These are intended for use in reducers, when a __RESOLVE action comes in:
 * we'll mark that we have data, and will overwrite any prior data status.
 */
const resolveReadOperation = (
  previousOperation = initialReadOperation,
  fieldsToAdd: Object = {},
): BackendOperationType => {
  const currentTime = Date.now();
  const fieldsToUpdate = {
    fetchStatus: FETCH_STATUS.SUCCESSFUL,
    dataStatus: DATA_STATUS.PRESENT,
    lastFetchStatusTime: currentTime,
    lastFetchFailed: false,
    lastDataStatusTime: currentTime,
    ...fieldsToAdd,
  };
  if (
    alwaysImmutable ||
    previousOperation.lastFetchFailed ||
    previousOperation.dataStatus !== DATA_STATUS.PRESENT
  ) {
    return {
      ...previousOperation,
      ...fieldsToUpdate,
    };
  }
  // Else: we already had data, and we weren't asked to be immutable, so this
  // isn't a noteworthy change, so mutate in place. (Eep)
  Object.assign(previousOperation, fieldsToUpdate);
  return previousOperation as BackendOperationType;
};

const resolveWriteOperation = (
  previousOperation = initialReadOperation,
  fieldsToAdd: Object = {},
): BackendOperationType => {
  const currentTime = Date.now();
  return {
    ...previousOperation,
    fetchStatus: FETCH_STATUS.SUCCESSFUL,
    lastFetchStatusTime: currentTime,
    ...fieldsToAdd,
  };
};

/**
 * These are intended for use in reducers, when a __REJECT action comes in:
 * we'll mark that things failed, but won't alter any prior data status (if it's a read).
 *
 * Note that you'll almost always want to specify `message` in fieldsToAdd.
 */
const rejectReadOperation = (
  previousOperation = initialReadOperation,
  fieldsToAdd: Object = {},
): BackendOperationType => ({
  ...previousOperation,
  fetchStatus: FETCH_STATUS.FAILED,
  lastFetchStatusTime: Date.now(),
  lastFetchFailed: true,
  ...fieldsToAdd,
});

export default {
  initialReadOperationForAction,
  initialWriteOperationForAction,
  beginReadOperation,
  beginWriteOperation,
  resolveReadOperation,
  resolveWriteOperation,
  rejectReadOperation,
};
