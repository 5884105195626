/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/destructuring-assignment */
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import BaseInput from 'react-jsonschema-form/lib/components/widgets/BaseInput';
import { getTranslation } from '@tw/i18n';
import { noopFn } from '@tw/constants';
import { PropertiesModelKey } from '../../../modules/Forms/Forms.definitions';
import { InvisibleLabel, OptionError, ErrorIcon } from './TWFormInputDeprecated.styles';

interface ErrorItem {
  index: number;
  created: Date;
}

interface TWFormInputDeprecatedProps {
  value?: string;
  label?: string;
  className?: string;
  style?: object;
  fieldSchema?: PropertiesModelKey;
  placeholder?: string;
  index?: number;
  onBlur?: (currentValue: string, index: number) => void;
  onFocus?: Function;
  onKeyDown?: (
    event: { keyCode: number; preventDefault: () => void; target: { value: string } },
    index: number,
  ) => void;
}

const TWFormInputDeprecated = ({
  value = '',
  label = '',
  className = '',
  fieldSchema,
  style = {},
  placeholder = '',
  index = 0,
  onBlur,
  onFocus,
  onKeyDown,
}: TWFormInputDeprecatedProps) => {
  const theme = useTheme();
  const [currentValue, setCurrentValue] = useState<string>(value);
  const [errors, setErrors] = useState<ErrorItem[]>([]);
  const enums = fieldSchema?.enum || fieldSchema?.items?.enum;
  const lowercaseEnums = enums?.map((item) => item.toLowerCase());

  useEffect(() => {
    if (
      enums &&
      lowercaseEnums &&
      lowercaseEnums.includes(currentValue?.toLowerCase().trim()) &&
      currentValue?.toLowerCase().trim() !== value?.toLowerCase().trim() &&
      onBlur
    ) {
      setErrors([...errors, { index, created: new Date() }]);
      setCurrentValue(enums[index]);
      onBlur(enums[index], index);
    }

    errors.map((error) => {
      const timeout = new Date(error.created).getTime() - Date.now();
      return setTimeout(
        () => setErrors((prev) => prev.filter((a) => !Object.is(a, error))),
        timeout + 6000,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBlur, onKeyDown]);

  const handleOnBlur = () => {
    if (_.isFunction(onBlur) && !lowercaseEnums?.includes(currentValue?.toLowerCase().trim())) {
      onBlur(currentValue, index);
    } else if (_.isFunction(onBlur) && enums) {
      onBlur(enums[index], index);
    }
  };

  const handleOnKeyDown = (event: {
    keyCode: number;
    preventDefault: () => void;
    target: { value: string };
  }) => {
    if (
      _.isFunction(onKeyDown) &&
      event.keyCode === 13 &&
      !lowercaseEnums?.includes(currentValue?.toLowerCase().trim())
    ) {
      onKeyDown(event, index);
    } else if (_.isFunction(onKeyDown) && event.keyCode === 13 && enums) {
      const keyboardEvent = {
        keyCode: 13,
        preventDefault: noopFn,
        target: { value: enums[index] },
      };
      onKeyDown(keyboardEvent, index);
    }
  };

  const id = _.uniqueId('input');

  return (
    <label className="input-wrapper" htmlFor={id}>
      <div className="input-label">{label}</div>
      <InvisibleLabel>{value}</InvisibleLabel>
      <BaseInput
        id={id}
        style={style}
        schema={{ type: 'string' }}
        className={className}
        value={currentValue}
        autoComplete="off"
        placeholder={placeholder}
        onBlur={handleOnBlur}
        onFocus={onFocus}
        onKeyDown={handleOnKeyDown}
        onChange={setCurrentValue}
        options={{}}
      />
      {errors.length > 0 && errors[0].index === index && (
        <OptionError>
          <ErrorIcon type="material-error" color={theme.colors.negativeIcon} />
          {getTranslation('forms.formBuilder.duplicateError')}
        </OptionError>
      )}
    </label>
  );
};

export default TWFormInputDeprecated;
