const mockDataDemoBackendConfig = {
  mocking: {
    artificialAjaxDelay: 250, // in ms

    // 'useFakeBackends' overrides ALL the service-specific flags.
    useFakeBackends: true,
  },
};

export default mockDataDemoBackendConfig;
