/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalsyncAccountDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type CalsyncAccountDeleteMutation = {
  calsyncAccountDelete: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    calsyncAccount?: {
      id: string;
      calsyncAccountId: string;
      providerId: string;
      createdBy?: number | null;
      personId: number;
      status?: string | null;
      calsyncCalendars?: Array<{
        id: string;
        providerId: string;
        createdBy?: number | null;
        personId: number;
        thirdPartyName?: string | null;
        status?: string | null;
      } | null> | null;
    } | null;
  };
};

export const CalsyncAccountDeleteDocument = gql`
  mutation CalsyncAccountDelete($id: ID!) {
    calsyncAccountDelete(id: $id) {
      userErrors {
        field
        message
      }
      calsyncAccount {
        id
        calsyncAccountId
        providerId
        createdBy
        personId
        status
        calsyncCalendars {
          id
          providerId
          createdBy
          personId
          thirdPartyName
          status
        }
      }
    }
  }
`;
export type CalsyncAccountDeleteMutationFn = Apollo.MutationFunction<
  CalsyncAccountDeleteMutation,
  CalsyncAccountDeleteMutationVariables
>;

/**
 * __useCalsyncAccountDeleteMutation__
 *
 * To run a mutation, you first call `useCalsyncAccountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalsyncAccountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calsyncAccountDeleteMutation, { data, loading, error }] = useCalsyncAccountDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalsyncAccountDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CalsyncAccountDeleteMutation,
    CalsyncAccountDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CalsyncAccountDeleteMutation, CalsyncAccountDeleteMutationVariables>(
    CalsyncAccountDeleteDocument,
    options,
  );
}
export type CalsyncAccountDeleteMutationHookResult = ReturnType<
  typeof useCalsyncAccountDeleteMutation
>;
export type CalsyncAccountDeleteMutationResult =
  Apollo.MutationResult<CalsyncAccountDeleteMutation>;
export type CalsyncAccountDeleteMutationOptions = Apollo.BaseMutationOptions<
  CalsyncAccountDeleteMutation,
  CalsyncAccountDeleteMutationVariables
>;
