const globalDefaultFormatConfig = {
  // All date/time format strings use MomentJS format
  dateAndTime: {
    whenEmpty: '', // whenEmpty is only useful for debugging
    whenInvalid: '---',
    // These are used for inputting/parsing dates
    backendDate: 'YYYY-MM-DD',
    backendDateTime: 'YYYY-MM-DDTHH:mm:ss',

    // These are used for outputting/formatting dates
    // these are overridden by locale layers but NOT 12 v 24 hour preference
    veryShortDate: 'M/D/YY',
    shorterDate: 'MMM/D/YYYY',
    shortDate: 'MMM D, YYYY',
    noDayLongDate: 'MMMM D, YYYY',
    longDate: 'dddd, MMMM D, YYYY',
    shortDayDate: 'ddd, MMM D',
    dayDate: 'ddd, MMM D, YYYY',
    // these are times, and are changed based on 12 v 24 hour preference, but NOT locale layers
    shortTime: 'h:mm a',
    longTime: 'h:mm:ss a',
  },
  messagingDateSeparatorFormat: {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[last] dddd',
    nextWeek: 'dddd',
    sameElse: 'L',
  },
  messageDetailsDateFormat: {
    international: 'MMM DD, YYYY, hh:mm zz',
    default: 'MMM DD, YYYY, h:mma zz',
  },

  // @TODO: currency, number, phone
};

export default globalDefaultFormatConfig;
