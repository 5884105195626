import ct from 'countries-and-timezones';
import _ from 'lodash';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect';

interface TWInputSelectCountryProps extends Omit<TWInputSelectProps, 'onChange'> {
  isDisabled?: boolean;
  populateOnMount?: boolean;
  popupContainerId?: string;
  placeholder?: string;
  testID?: string;
  skip?: boolean;
  onChange?: (value?: string) => void;
  accessibilityLabel?: string;
}

const TWInputSelectCountry = ({
  popupContainerId = '',
  onBlur,
  onChange,
  placeholder,
  isDisabled = false,
  testID,
  allowClear = true,
  accessibilityLabel,
  ...allUnrecognizedProps
}: TWInputSelectCountryProps) => {
  const countryOptionsList = _.map(ct.getAllCountries(), (value, key) => ({
    label: value.name,
    value: key,
  }));

  return (
    <TWInputSelect
      accessibilityLabel={accessibilityLabel}
      onBlur={onBlur}
      allowClear={allowClear}
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      disabled={isDisabled}
      itemList={countryOptionsList}
      onChange={(country) => onChange?.(country)}
      popupContainerId={popupContainerId}
      testID={testID}
      {...allUnrecognizedProps}
    />
  );
};

export default TWInputSelectCountry;
