/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulletinSendReminderMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.BulletinSendReminderInput>;
}>;

export type BulletinSendReminderMutation = {
  bulletinSendReminder: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const BulletinSendReminderDocument = gql`
  mutation BulletinSendReminder($input: BulletinSendReminderInput) {
    bulletinSendReminder(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type BulletinSendReminderMutationFn = Apollo.MutationFunction<
  BulletinSendReminderMutation,
  BulletinSendReminderMutationVariables
>;

/**
 * __useBulletinSendReminderMutation__
 *
 * To run a mutation, you first call `useBulletinSendReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulletinSendReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulletinSendReminderMutation, { data, loading, error }] = useBulletinSendReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulletinSendReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulletinSendReminderMutation,
    BulletinSendReminderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulletinSendReminderMutation, BulletinSendReminderMutationVariables>(
    BulletinSendReminderDocument,
    options,
  );
}
export type BulletinSendReminderMutationHookResult = ReturnType<
  typeof useBulletinSendReminderMutation
>;
export type BulletinSendReminderMutationResult =
  Apollo.MutationResult<BulletinSendReminderMutation>;
export type BulletinSendReminderMutationOptions = Apollo.BaseMutationOptions<
  BulletinSendReminderMutation,
  BulletinSendReminderMutationVariables
>;
