/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupsLandingQueryVariables = Types.Exact<{
  signupId?: Types.InputMaybe<Types.Scalars['UUID']>;
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  title?: Types.InputMaybe<Types.Scalars['String']>;
  creator?: Types.InputMaybe<Types.Scalars['ID']>;
  appointmentTypeId?: Types.InputMaybe<Types.Scalars['ID']>;
  mandatory?: Types.InputMaybe<Types.Scalars['Boolean']>;
  mySignupsOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type SignupsLandingQuery = {
  signups?: {
    totalCount?: number | null;
    pageInfo: {
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      node?: {
        signupId: string;
        title: string;
        canEdit?: boolean | null;
        isPrivate?: boolean | null;
        location?: string | null;
        mandatory?: boolean | null;
        timezone?: string | null;
        firstStartDatetime?: any | null;
        lastEndDatetime?: any | null;
        totalReservations?: number | null;
        totalCapacity?: number | null;
        recurrence?: string | null;
        slotDuration?: string | null;
        slotCapacity?: number | null;
        creator?: { displayName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export const SignupsLandingDocument = gql`
  query SignupsLanding(
    $signupId: UUID
    $startDate: DateTime
    $endDate: DateTime
    $title: String
    $creator: ID
    $appointmentTypeId: ID
    $mandatory: Boolean
    $mySignupsOnly: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    signups(
      signupId: $signupId
      startDatetime: $startDate
      endDatetime: $endDate
      title: $title
      creator: $creator
      appointmentTypeId: $appointmentTypeId
      mandatory: $mandatory
      mySignupsOnly: $mySignupsOnly
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          signupId
          title
          canEdit
          isPrivate
          location
          mandatory
          timezone
          firstStartDatetime
          lastEndDatetime
          totalReservations
          totalCapacity
          recurrence
          slotDuration
          slotCapacity
          creator {
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useSignupsLandingQuery__
 *
 * To run a query within a React component, call `useSignupsLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupsLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupsLandingQuery({
 *   variables: {
 *      signupId: // value for 'signupId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      title: // value for 'title'
 *      creator: // value for 'creator'
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *      mandatory: // value for 'mandatory'
 *      mySignupsOnly: // value for 'mySignupsOnly'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useSignupsLandingQuery(
  baseOptions?: Apollo.QueryHookOptions<SignupsLandingQuery, SignupsLandingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignupsLandingQuery, SignupsLandingQueryVariables>(
    SignupsLandingDocument,
    options,
  );
}
export function useSignupsLandingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SignupsLandingQuery, SignupsLandingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignupsLandingQuery, SignupsLandingQueryVariables>(
    SignupsLandingDocument,
    options,
  );
}
export type SignupsLandingQueryHookResult = ReturnType<typeof useSignupsLandingQuery>;
export type SignupsLandingLazyQueryHookResult = ReturnType<typeof useSignupsLandingLazyQuery>;
export type SignupsLandingQueryResult = Apollo.QueryResult<
  SignupsLandingQuery,
  SignupsLandingQueryVariables
>;
