import { ModalFuncProps } from 'antd/lib/modal';
import { ModalStaticFunctions } from 'antd/lib/modal/confirm';
import { ReactNode } from 'react';

import { i18nLocaleTranslator as translator } from '@tw/i18n';

import {
  IconNeutralColor,
  IconSuccessColor,
  ModalContentStyles,
  ModalTitleStyles,
  SvgBackgroundNeutral,
  SvgBackgroundSuccess,
  cancelButtonStyles,
  warningButtonStyles,
} from './modalRenderFunctions.styles';

const getType = (
  type?: 'primary' | 'attention' | ModalFuncProps['type'],
): ModalFuncProps['type'] | undefined => {
  // legacy AntD support
  switch (type) {
    case 'primary':
      return 'confirm';
    case 'attention':
      return 'warning';
    default:
      return type;
  }
};

export interface ModalProps extends Omit<ModalFuncProps, 'type'> {
  containerId?: string;
  icon?: ReactNode;
  iconName?: string;
  okButtonTestID?: string;
  cancelButtonTestID?: string;
  className?: string;
  testID?: string;
  title?: string;
  type?: 'primary' | 'attention' | ModalFuncProps['type'];
  content?: string | JSX.Element;
  cancelText?: string;
  width?: number | string;
}

export type ModalFunc = (
  modal: Pick<ModalStaticFunctions, 'confirm'>,
  config: ModalProps,
) => {
  destroy: () => void;
  update: (newConfig: ModalFuncProps) => void;
};

const confirmModal: ModalFunc = (modal, config) => {
  const { type, ...properConfig } = config;
  const {
    cancelButtonTestID,
    containerId,
    iconName,
    okButtonTestID,
    content,
    title,
    okButtonProps,
    cancelButtonProps,
  } = config;
  let { icon } = config;
  let hasIcon = false;

  if (iconName) {
    hasIcon = true;
    icon = (
      <SvgBackgroundNeutral className="anticon">
        <IconNeutralColor height={55} width={55} type={iconName} />
      </SvgBackgroundNeutral>
    );
  }

  const container = containerId ? document.getElementById(containerId) : undefined;

  return modal.confirm({
    ...properConfig,
    type: getType(type),
    getContainer: container ?? undefined,
    okButtonProps: {
      ...okButtonProps,
      ...(okButtonTestID && { 'data-testid': okButtonTestID }),
    },
    content: <ModalContentStyles hasIcon={hasIcon}>{content}</ModalContentStyles>,
    title: <ModalTitleStyles>{title}</ModalTitleStyles>,
    cancelButtonProps: {
      ...cancelButtonProps,
      ...(cancelButtonTestID && { 'data-testid': cancelButtonTestID }),
      style: { ...cancelButtonProps?.style, ...cancelButtonStyles },
      type: 'link',
    },
    icon,
  });
};

const successConfirmModal: ModalFunc = (modal, config) => {
  const {
    containerId,
    iconName,
    okButtonTestID,
    content,
    title,
    okButtonProps,
    type = 'success',
    ...properConfig
  } = config;
  let { icon } = config;
  let hasIcon = false;

  if (iconName) {
    hasIcon = true;
    icon = (
      <SvgBackgroundSuccess className="anticon">
        <IconSuccessColor height={55} width={55} type={iconName} />
      </SvgBackgroundSuccess>
    );
  }

  const container = containerId ? document.getElementById(containerId) : undefined;

  return modal.confirm({
    type: getType(type),
    getContainer: container ?? undefined,
    ...properConfig,
    okButtonProps: {
      ...okButtonProps,
      ...(okButtonTestID && { 'data-testid': okButtonTestID }),
    },
    content: <ModalContentStyles hasIcon={hasIcon}>{content}</ModalContentStyles>,
    title: <ModalTitleStyles>{title}</ModalTitleStyles>,
    icon,
  });
};

const warningConfirmModal: ModalFunc = (modal, config) => {
  const {
    containerId,
    iconName,
    okButtonTestID = `${containerId ?? ''}-WarningConfirmButton`,
    cancelButtonTestID,
    cancelButtonProps = {},
    content,
    testID = 'ConfirmationModal',
    title = translator.t('areYouSure'),
    type = 'attention',
    ...properConfig
  } = config;
  let { icon } = config;
  let hasIcon = false;

  if (iconName) {
    hasIcon = true;
    icon = (
      <SvgBackgroundNeutral className="anticon">
        <IconNeutralColor height={55} width={55} type={iconName} />
      </SvgBackgroundNeutral>
    );
  }

  const container = containerId ? document.getElementById(containerId) : undefined;

  return modal.confirm({
    ...properConfig,
    type: getType(type),
    getContainer: container ?? undefined,
    okButtonProps: {
      ...config.okButtonProps,
      ...(okButtonTestID ? { 'data-testid': okButtonTestID } : {}),
      style: type === 'primary' ? {} : warningButtonStyles,
    },
    content: (
      <ModalContentStyles hasIcon={hasIcon} data-testid={`${testID}:Content`}>
        {content}
      </ModalContentStyles>
    ),
    title: <ModalTitleStyles data-testid={`${testID}:Title`}>{title}</ModalTitleStyles>,
    cancelButtonProps: {
      ...config.cancelButtonProps,
      ...(cancelButtonTestID ? { 'data-testid': cancelButtonTestID } : {}),
      style: { ...cancelButtonStyles, ...cancelButtonProps.style },
      type: 'link',
    },
    icon,
  });
};

export { confirmModal, successConfirmModal, warningConfirmModal };
