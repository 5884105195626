import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { getTranslation } from '@tw/i18n';

import { TWIcon } from '@tw/components';
import { TWButtonIconFlat } from '../../../presentational/buttons';

interface TWDrawerButtonCalendarProps {
  personId: number;
  testID: string;
}

export const TWDrawerButtonCalendar = ({ personId, testID }: TWDrawerButtonCalendarProps) => {
  const history = useHistory();

  const query = { studentPersonIds: personId };
  return (
    <TWButtonIconFlat
      accessibilityLabel={getTranslation('calendar.viewCalendar')}
      label={getTranslation('calendar.viewCalendar')}
      title={getTranslation('calendar.viewCalendar')}
      data-testid={testID}
      onClick={() => history.push(`/calendar/view?${queryString.stringify(query)}`)}
    >
      <TWIcon type="material-calendar_month" />
    </TWButtonIconFlat>
  );
};
