import _ from 'lodash';
import faker from 'faker/locale/en';

import { relayUtils } from '@tw/util';

import mockUtils from './utils';

export const graphqlDefs = `
type PeopleConnection {
  pageInfo: PageInfo!
  edges: [PeopleEdge!]!
  totalCount: Int!
}

type PeopleFiltersInput {
  userType: ID!
  athleteStatus: ID!
}

extend type Query {
  people(
    before: String
    after: String
    first: Int
    last: Int
    filters: PeopleFiltersInput
  ): PeopleConnection!
}`;

export const generatePerson = (index: number) => {
  const fullName = faker.name.findName();
  const displayName = fullName;
  const preferredName = fullName.split(' ')[0];
  const initials = fullName
    .split(' ')
    .map((part: object) => _.head(part))
    .join('');
  const pictureUrl = mockUtils.sometimesNull(faker.image.avatar, 0.35);

  return {
    __typename: 'PersonNode',
    id: `${index}`,
    key: `Person${index}`,
    fullName,
    displayName,
    preferredName,
    initials,
    pictureUrl,
    pictureDownloadUrl: pictureUrl,
    title: mockUtils.sometimesNull(faker.name.title),
    emailAddress: faker.internet.email(),
    mobilePhone: mockUtils.sometimesNull(faker.phone.phoneNumber),
    lastLoginDate: mockUtils.sometimesNull(faker.date.recent.bind(Date.prototype.toISOString)),
    loginCount: mockUtils.sometimesNull(faker.random.number),
    usesExternalAuthentication: faker.random.boolean,
    hasLoginAccess: faker.random.boolean,
    isOrgLevelUser: false,
    hasUserAccount: faker.random.boolean,
    activeMemberships: [
      {
        id: `${index}`,
        team: { id: 'VGVhbU5vZGU6MTI0OQ==', label: 'The team' },
      },
    ],
    teamMembers: {
      edges: [
        {
          node: {
            id: `${index}`,
            teamId: 1234,
            active: true,
            team: { id: '1234', label: 'The team' },
          },
        },
      ],
    },
    active: true,
  };
};

const numberOfPeople = 1337;
export const personNodes = _.times(numberOfPeople, generatePerson);

export const resolvers = {
  people: (obj: object, args = {}) =>
    relayUtils.connectionFromArraySlice(personNodes, args, {
      sliceStart: 0,
      arrayLength: numberOfPeople,
      nodeAlias: 'person',
      connectionType: 'PeopleConnection',
      edgeType: 'PeopleEdge',
    }),
};
