import { reduxConfigUtils } from '@tw/util';

import mockDataDemoBackendConfig from './mockDataDemoBackendConfig';
import baseDefault from './baseDefault';
import backendConfigStoreConfig from './backendConfigStoreConfig';
import { APPLY_DEBUG_OVERRIDE, CLEAR_DEBUG_OVERRIDE } from './backendConfigActions';

// Note: Everything is flat here (instead of nesting/grouping the attributes and values
// separately) because that makes the store manipulations easier -- we'd need 3-4 spread
// operators for each one instead of 1-2.
const initialState = {
  // In priority order, from lowest to highest
  layerNames: backendConfigStoreConfig.layerNames,

  // Available attributes:
  //    isEnabled     {bool}
  //    @TODO: For future growth, if/when we need it:
  //      * something to enable/disable debug logging for the layer,
  //      * tags to allow complex queries from outside (to answer questions like
  //        "did this value come from a layer tagged as 'override'?", for debugging only)
  attributes_baseDefault: { isEnabled: true },
  attributes_mockDataDemo: { isEnabled: false },
  attributes_currentUser: { isEnabled: true },
  attributes_debugOverride: { isEnabled: true },

  values_baseDefault: baseDefault,
  values_mockDataDemo: mockDataDemoBackendConfig,
  values_currentUser: {},
  values_debugOverride: {},
};

reduxConfigUtils.warnIfInitialStateLooksIncomplete(initialState);

export default function backendConfigReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * This *merges in* a set of debugOverride values
     */
    case APPLY_DEBUG_OVERRIDE:
      return {
        ...state,
        attributes_debugOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_debugOverride,
          { isEnabled: true },
        ),
        values_debugOverride: reduxConfigUtils.mergeConfigLayerValues(
          state.values_debugOverride,
          action.debugOverrideValues,
        ),
      };

    /**
     * This wipes (but does not disable) any debugOverride values
     */
    case CLEAR_DEBUG_OVERRIDE:
      return {
        ...state,
        values_debugOverride: {},
      };

    default:
      return state;
  }
}
