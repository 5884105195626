/* eslint-disable react/jsx-props-no-spreading */
/**
 * SortableDragTable is a wrapper component for existing tables, to add draggable sort functionality.
 * The child function will return components to feed to the table and a locally sorted data source.
 * @example
 * Here's an example implementation:
 * ```
 * const columns = [
 *   SortableDragTableColumn, // <-- drag handle
 *   ...normalColumns
 * ];
 *
 * // Wrapper version
 * return (
 *   <SortableDragTableWrapper dataSource={dataSource} rowKey="id" onSort={onSort} sortKey="sortOrder" dragSource="demoTable1">
 *       {({ components, sortedDataSource }) => (
 *           <Table
 *               dataSource={sortedDataSource}
 *               columns={columns}
 *               pagination={false}
 *               size="small"
 *               rowKey="id"
 *               components={components}
 *            />
 *       )}
 *   </SortableDragTableWrapper>
 * )
 *
 * // HOC version
 * return (
 *   <SortableDragTable
 *      dataSource={dataSource}
 *      rowKey="id"
 *      sortKey="sortOrder"
 *      onSort={onSort}>
 *      columns={columns}
 *      pagination={false}
 *      size="small"
 *      dragSource="demoTable2"
 *   />
 * )
 * ```
 */
import { useMemo, useState, useRef, useEffect } from 'react';
import { Table } from 'antd';
import {
  SortableDragTableProps,
  ColumnType,
  SortableDragTableWrapperProps,
  DataType,
  SortableDragKeys,
  OnRowType,
  NumberKeys,
  SortBy,
  SortScanAccumulator,
  StringKeys,
  LocalDataState,
} from './SortableDragTable.definitions';
import { Hamburger } from './SortableDragTable.styles';
import { SortableDragTableRowGenerator } from './@components';

export const SortableDragTableColumn: ColumnType<{}> = {
  title: 'Sort',
  dataIndex: 'sort',
  // let's the component know that these column cells (TDs) are draggable
  key: SortableDragKeys.dragHandle,
  className: 'drag-visible',
  render: () => <Hamburger type="material-menu" />,
};

// Profiles -> Custom View: different drag icon
export const SortableDragTableProfileColumn: ColumnType<{}> = {
  ...SortableDragTableColumn,
  render: () => <Hamburger type="material-drag_indicator" />,
};

export const sortData = <T extends DataType>(
  data: readonly T[],
  sortBy: SortBy | keyof typeof SortBy = SortBy.asc,
  sortKey?: NumberKeys<T>,
): T[] => {
  if (data.length && sortKey && sortKey in data[0]) {
    const defaultVal = sortBy === SortBy.asc ? Number.MAX_VALUE : Number.MIN_VALUE;
    const isNumber = (val: unknown): val is number => typeof val === 'number' && !Number.isNaN(val);

    const sortVal = (obj: T) => {
      if (sortKey in obj) {
        const value = obj[sortKey];
        return isNumber(value) ? value : defaultVal;
      }
      return defaultVal;
    };
    return [...data].sort((a, b) => {
      if (sortBy === SortBy.asc) {
        return sortVal(a) - sortVal(b);
      }
      return sortVal(b) - sortVal(a);
    });
  }
  return [...data];
};

export const itemId = <T extends DataType>(obj: T, rowKey: StringKeys<T>): string | undefined => {
  if (rowKey in obj) {
    const value = obj[rowKey];
    return typeof value === 'string' ? value : undefined;
  }
  return undefined;
};

export const itemSortValue = <T extends DataType>(
  obj: T,
  sortKey: NumberKeys<T>,
): number | undefined => {
  if (sortKey in obj) {
    const value = obj[sortKey];
    return typeof value === 'number' && !Number.isNaN(value) ? value : undefined;
  }
  return undefined;
};

export const determineSortingChange = <T extends DataType>(
  newOrder: readonly T[],
  oldOrder: readonly T[],
  rowKey: StringKeys<T>,
  sortKey: NumberKeys<T>,
  sortBy: SortBy | keyof typeof SortBy,
): T[] => {
  // start with the first/top one in the old list (assuming they were already sorted)
  const firstSortValue = itemSortValue(oldOrder[0], sortKey);

  // let's determine a change list of the attributes that need new sort values
  const { changed } = newOrder.reduce<SortScanAccumulator<T>>(
    ({ nextSortOrder, changed: changeItems }, item, index) => {
      const id = itemId(item, rowKey);
      // make sure you are at least zero-based or just use the next value in the sequence
      const newSortOrder =
        sortBy === SortBy.asc ? Math.max(nextSortOrder, index) : Math.min(nextSortOrder, index);
      const oldSortOrderItem = oldOrder.find((oldItem) => {
        const oldId = itemId(oldItem, rowKey);
        return id !== undefined && oldId === id;
      });
      const oldSortOrder = oldSortOrderItem ? oldSortOrderItem[sortKey] : undefined;

      // if the sort order is different, then we need to update it
      if (oldSortOrder === undefined || newSortOrder !== oldSortOrder) {
        changeItems.push({
          ...item,
          [sortKey]: newSortOrder,
        });
      }
      // ensure that the order increments/decrements by at least 1
      return {
        nextSortOrder: newSortOrder + (sortBy === SortBy.asc ? 1 : -1),
        changed: changeItems,
      };
    },
    { nextSortOrder: firstSortValue !== undefined ? firstSortValue : 0, changed: [] },
  );

  return changed;
};

export const moveRowInState = <T extends DataType>(
  previousState: LocalDataState<T>,
  dragIndex: number,
  dropIndex: number,
  rowKey: StringKeys<T>,
  sortBy: SortBy | keyof typeof SortBy,
  sortKey?: NumberKeys<T>,
): LocalDataState<T> | undefined => {
  if (dragIndex !== dropIndex) {
    const newOrder = previousState.dataSource ? [...previousState.dataSource] : [];
    const oldOrder = previousState.dataSource ? [...previousState.dataSource] : [];
    const elementToMove = newOrder[dragIndex];

    // Use drag/hover indices to splice list
    newOrder.splice(dragIndex, 1);
    newOrder.splice(dropIndex, 0, elementToMove);

    // determine change if sortKey is defined
    const changed = sortKey
      ? determineSortingChange<T>(newOrder, oldOrder, rowKey, sortKey, sortBy)
      : undefined;
    return {
      dataSource: sortData(
        changed && changed.length
          ? newOrder.map((item) => {
              // reset sortKey values to the new order
              const changedItem = changed.find((cItem) => cItem[rowKey] === item[rowKey]);
              const sortKeyOverride = sortKey
                ? {
                    [sortKey]: changedItem ? changedItem[sortKey] : item[sortKey],
                  }
                : undefined;
              return {
                ...item,
                ...sortKeyOverride,
              };
            })
          : newOrder,
        sortBy,
        sortKey,
      ),
      changedList: changed,
    };
  }
  return undefined;
};

// Wrapper Component
export const SortableDragTableWrapper = <RecordType extends DataType>({
  children,
  dataSource: parentDataSource,
  onSort,
  dragSource,
  dropSource = dragSource,
  dragPadding,
  rowKey,
  sortKey,
  sortBy = SortBy.asc,
}: SortableDragTableWrapperProps<RecordType>) => {
  const [{ dataSource, changedList, movedItem }, setDataSource] = useState<
    LocalDataState<RecordType>
  >({
    dataSource: parentDataSource ? sortData(parentDataSource, sortBy, sortKey) : undefined,
    changedList: undefined,
    movedItem: undefined,
  });
  const hasSorted = useRef<boolean>(false);
  const previousOrder = useRef<RecordType[] | undefined>(dataSource || undefined);
  const firstRender = useRef(true);

  useEffect(() => {
    const newOrder = parentDataSource ? sortData(parentDataSource, sortBy, sortKey) : undefined;
    if (!firstRender.current && !hasSorted.current) {
      // update on prop change after first render
      setDataSource((prev) => ({
        ...prev,
        dataSource: newOrder,
      }));
    }
    previousOrder.current = newOrder;
    firstRender.current = false;
  }, [parentDataSource, sortKey, sortBy]);

  useEffect(() => {
    if (hasSorted.current && dataSource) {
      hasSorted.current = false;
      const prevOrder = previousOrder.current;
      previousOrder.current = [...dataSource];
      if (onSort) {
        onSort(
          dataSource,
          prevOrder,
          changedList && changedList.length ? changedList : undefined,
          movedItem,
        );
      }
    }
  }, [dataSource, onSort, changedList, movedItem]);

  const moveRow = (dragIndex: number, dropIndex: number) => {
    if (dragIndex !== dropIndex) {
      // updated state, then we'll let the parent know in the useEffect
      hasSorted.current = true;
      setDataSource((prev) => {
        const change = moveRowInState(prev, dragIndex, dropIndex, rowKey, sortBy, sortKey);
        return change ? { ...change, movedItem: change?.dataSource?.[dropIndex] } : prev;
      });
    }
  };

  const onRow: OnRowType<RecordType> = (data: RecordType, index?: number) => ({
    index,
    moveRow,
  });

  const components = useMemo(
    () => ({
      body: {
        row: SortableDragTableRowGenerator(dragSource, dropSource, dragPadding),
      },
    }),
    [dragSource, dropSource, dragPadding],
  );

  return (
    <>
      {children({
        components,
        sortedDataSource: dataSource,
        onRow,
      })}
    </>
  );
};

// Higher-Order Component
const SortableDragTable = <RecordType extends DataType>({
  dataSource: parentDataSource,
  onSort,
  rowKey,
  sortEnabled = true,
  components: parentComponents,
  dragSource,
  dropSource = dragSource,
  dragPadding,
  onRow: parentOnRow,
  sortKey,
  sortBy,
  sortFailed,
  ...tableProps
}: SortableDragTableProps<RecordType>) => {
  const onRowOverride =
    (childOnRow: OnRowType<RecordType>) => (data: RecordType, index?: number) => {
      const childAttr = childOnRow(data, index);
      const parentAttr = parentOnRow ? parentOnRow(data, index) : undefined;
      return {
        ...childAttr,
        ...parentAttr,
      };
    };

  return sortEnabled ? (
    <SortableDragTableWrapper
      dataSource={parentDataSource}
      onSort={onSort}
      dragSource={dragSource}
      dropSource={dropSource}
      dragPadding={dragPadding}
      rowKey={rowKey}
      sortKey={sortKey}
      sortBy={sortBy}
    >
      {({ components, sortedDataSource, onRow }) => (
        <Table
          dataSource={sortFailed ? parentDataSource : sortedDataSource}
          components={{
            ...components,
            ...parentComponents,
          }}
          rowKey={rowKey.toString()}
          onRow={onRowOverride(onRow)}
          {...tableProps}
        />
      )}
    </SortableDragTableWrapper>
  ) : (
    <Table
      dataSource={parentDataSource}
      components={parentComponents}
      rowKey={rowKey.toString()}
      onRow={parentOnRow}
      {...tableProps}
    />
  );
};

export default SortableDragTable;
