import _ from 'lodash';

interface Record {
  courseSectionSchedules: {
    dayAbbreviation: string;
  }[];
}

// this should just take a Course Section and should be renamed when all places it is used have been updated
const renderDayAbbreviations = (_value: unknown, record: Record) => {
  const { courseSectionSchedules } = record;
  if (_.isEmpty(courseSectionSchedules)) {
    return '-';
  }

  return _.uniqBy(courseSectionSchedules, 'dayAbbreviation')
    .map(({ dayAbbreviation }) => dayAbbreviation)
    .join('');
};

export default renderDayAbbreviations;
