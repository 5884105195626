import { useState, RefObject, useRef, useEffect, useLayoutEffect } from 'react';

import { Dimension, ElementResizeObserverOptions } from './useResizeObserver.definitions';

export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  onResize?: (entry: DOMRectReadOnly) => void,
) => {
  const [dimensions, setDimensions] = useState<Dimension>({
    width: 0,
    height: 0,
  });

  const prevDimensions = useRef<Dimension>({
    width: 0,
    height: 0,
  });

  const observerInstanceRef = useRef<ResizeObserver | null>(null);

  // Store the onResize callback in a ref to ensure inline functions don't get recreated each time
  const onResizeRef = useRef<ElementResizeObserverOptions['onResize'] | undefined>(undefined);
  onResizeRef.current = onResize;

  // track mounted state to ensure we don't attempt to set state on an unmounted component
  const hasUnmounted = useRef(false);
  useEffect(
    () => () => {
      hasUnmounted.current = true;
    },
    [],
  );

  useLayoutEffect(() => {
    if (observerInstanceRef.current) {
      return null;
    }

    // The function called whenever an observed resize occurs.
    const resizeCallback = ([entry]: readonly ResizeObserverEntry[]) => {
      let width;
      let height;

      if (entry.borderBoxSize) {
        width = Math.round(
          Array.isArray(entry.borderBoxSize)
            ? entry.borderBoxSize[0].inlineSize
            : entry.borderBoxSize.inlineSize,
        );

        height = Math.round(
          Array.isArray(entry.borderBoxSize)
            ? entry.borderBoxSize[0].blockSize
            : entry.borderBoxSize.blockSize,
        );
      } else {
        width = Math.round(entry.contentRect.width);
        height = Math.round(entry.contentRect.height);
      }

      if (prevDimensions.current.height !== height || prevDimensions.current.width !== width) {
        const newDimensions = { width, height };
        if (onResizeRef.current) {
          onResizeRef.current(newDimensions);
        } else {
          prevDimensions.current.height = height;
          prevDimensions.current.width = width;

          if (!hasUnmounted.current) {
            setDimensions(newDimensions);
          }
        }
      }
    };

    observerInstanceRef.current = new ResizeObserver(resizeCallback);

    if (ref.current) {
      observerInstanceRef.current.observe(ref.current, {
        box: 'border-box',
      });
    }

    return () => {
      observerInstanceRef.current?.disconnect();
      observerInstanceRef.current = null;
    };
  }, [ref]);

  return {
    ...dimensions,
  };
};
