/**
 * This file provides static values about how the themeConfig interacts with and exists
 * within the Redux store.
 */

const storeKey = 'themeConfig';

export default {
  /**
   * Every config (i.e., every set of actions, reducers, and selectors) lives in its own
   * little corner of the store. This is where the stuff for themeConfig is nested.
   *
   * @type {string}
   */
  storeKey,
  rootSelector: (store) => store[storeKey],

  /**
   * The values which make up the themeConfig config (conveniently named 'themeConfig') are built
   * up by merging four 'layers' of values together into a single source of truth.
   *
   * Each layer corresponds roughly to a different data source -- either local or remote --
   * and you should basically never need to change the layer definitions.
   *
   * The layers are listed in priority order, from lowest to highest.
   * The naming convention is to always start with a 'globalDefault', and always
   * end with a 'debugOverride'
   *
   * DANGER: If you DO make any changes here, you MUST make corresponding updates to the
   * themeConfigReducers! (And probably add some themeConfigActions as well.)
   *
   * @type {string}[]
   */
  layerNames: ['baseDefault', 'platformScheme', 'darkScheme', 'customerOverride', 'debugOverride'],
};
