import { Divider } from 'antd';
import { MouseEventHandler, ReactNode, useEffect, useState } from 'react';

import { noopFn } from '@tw/constants';
import { useCoursesLazy, useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { Course } from '@tw/types';

import { TWButtonIconFlat, TWFlexContainer } from '../../../presentational';
import TWIcon from '../../TWIcon';
import TWInputSelect from '../TWInputSelect';

import { CourseCreateModal } from './CourseCreateModal';
import { TWInputSelectCourseProps } from './TWInputSelectCourse.definitions';
import { AddCourseButton } from './TWInputSelectCourse.styles';

export const TWInputSelectCourse = (props: TWInputSelectCourseProps) => {
  const {
    populateOnMount = false,
    filters = {},
    popupContainerId = '',
    placeholder = getTranslation('courses', 2),
    onBlur,
    onChange = noopFn,
    onUpdate = noopFn,
    isDisabled = false,
    isMultiSelect,
    showAdd = false,
    showEdit = false,
    skip = false,
    testID,
    value,
    ...allUnrecognizedProps
  } = props;

  const {
    currentTeam: { teamId: currentTeamId },
    permissions: { isAcademicSuperuser, teamsCanManageAcademics },
  } = useViewer();

  const canManageAcademics =
    isAcademicSuperuser || teamsCanManageAcademics.includes(Number(currentTeamId));

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [courseToUpdate, setCourseToUpdate] = useState<Course | null>(null);

  const safeFilters = { sort: 'label_xlt_asc', ...filters };

  const { fetchCourses, data: courseList, loading, refetch } = useCoursesLazy(safeFilters);

  useEffect(() => {
    if (populateOnMount && !skip) {
      fetchCourses();
    }
  }, [populateOnMount, fetchCourses, skip]);

  const keyExtractor = (course: Course) => course?.label;

  const labelExtractor = (course: Course) => course?.label;

  const valueExtractor = (course: Course) => course && Number(course.courseId);

  const onFocus = () => fetchCourses();

  const onModalSubmit = (id: string) => {
    refetch?.();
    onChange(id);
    onUpdate();
    setIsModalVisible(false);
    setCourseToUpdate(null);
  };

  const onModalClose = () => {
    setIsModalVisible(false);
    setCourseToUpdate(null);
  };

  const onAddClick: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    setIsModalVisible(true);
  };

  return (
    <TWFlexContainer row>
      <TWInputSelect
        onFocus={onFocus}
        onBlur={onBlur}
        allowClear
        filterOption
        optionFilterProp="title"
        placeholder={placeholder}
        keyExtractor={keyExtractor}
        labelExtractor={labelExtractor}
        valueExtractor={valueExtractor}
        disabled={isDisabled}
        isMultiSelect={isMultiSelect}
        itemList={courseList}
        loading={loading}
        onChange={(v) => {
          onChange(v?.toString());
        }}
        value={value}
        popupContainerId={popupContainerId}
        testID={testID}
        dropdownRender={(menu: ReactNode) => (
          <>
            {menu}
            {showAdd && !loading && canManageAcademics && (
              <>
                <Divider style={{ margin: '2px' }} />
                <TWFlexContainer>
                  <AddCourseButton
                    aria-label={getTranslation('coursesTab.courseCreationModal.newCourse')}
                    onClick={onAddClick}
                  >
                    {getTranslation('coursesTab.courseCreationModal.newCourse')}
                  </AddCourseButton>
                </TWFlexContainer>
              </>
            )}
          </>
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...allUnrecognizedProps}
      />
      {showEdit && !isMultiSelect && canManageAcademics && (
        <TWButtonIconFlat
          onClick={() => {
            setCourseToUpdate(courseList.find((course) => value === course.id) ?? null);
            setIsModalVisible(true);
          }}
          disabled={!value}
        >
          <TWIcon type="tw-pencil" />
        </TWButtonIconFlat>
      )}
      {(showAdd || showEdit) && canManageAcademics && (
        <CourseCreateModal
          containerId={popupContainerId}
          course={courseToUpdate ?? undefined}
          open={isModalVisible}
          onSubmit={onModalSubmit}
          onClose={onModalClose}
          onUpdate={onUpdate}
        />
      )}
    </TWFlexContainer>
  );
};
