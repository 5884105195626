import { useCallback, useState } from 'react';
import { useMedia } from 'react-use';

import { TWHeading3, TWTextDefault, TWFlexContainer, TWSpacingContainer } from '@tw/components';
import { TWLogoBlack } from '@tw/assets';
import { getTranslation } from '@tw/i18n';
import {
  useExternalVideoQuery,
  useExternalVideoIncrementPlayCountMutation,
  useExternalUserFileVideoActionCreateMutation,
} from '@tw/generated';

import VideoPlayerModal from '../VideoPlayerModal';
import {
  AspectRatio,
  PlayButton,
  ResponsiveContainer,
  SmallLogo,
  VideoContainer,
} from './VideosExternalPlay.styles';
import { getWistiaThumbnailUrl } from '../util/wistia';

interface VideosExternalPlayProps {
  uuid: string;
}

const VideosExternalPlay = ({ uuid }: VideosExternalPlayProps) => {
  const containerId = 'external-video-play';
  const isNarrow = useMedia('(max-width: 768px)');
  const [isVideoPlayVisible, setIsVideoPlayVisible] = useState(false);

  const { data: video } = useExternalVideoQuery({
    variables: {
      uuid,
    },
  });
  const [incrementPlayCount] = useExternalVideoIncrementPlayCountMutation();
  const [logUserAction] = useExternalUserFileVideoActionCreateMutation();

  const onVideoPlay = useCallback(() => {
    incrementPlayCount({
      variables: {
        input: {
          uuid,
        },
      },
    });
    logUserAction({
      variables: {
        input: {
          uuid,
          actionType: 'play',
          platform: 'Web',
        },
      },
    });
  }, [uuid, incrementPlayCount, logUserAction]);

  const openVideoPlayModal = () => {
    setIsVideoPlayVisible(true);
  };

  const closeVideoPlayModal = () => {
    setIsVideoPlayVisible(false);
  };

  const thumbAspectRatio = 5 / 3;
  const thumbWidth = 480;
  const thumbHeight = thumbWidth / thumbAspectRatio;

  const { wistiaThumbnailUrl, wistiaOriginalFileUrl } = video?.externalVideo ?? {};

  const thumbnailUrl = getWistiaThumbnailUrl({
    width: thumbWidth,
    height: thumbHeight,
    wistiaThumbnailUrl,
    wistiaOriginalFileUrl,
  });

  return (
    <>
      {video?.externalVideo && (
        <ResponsiveContainer mobile={isNarrow} id={containerId}>
          <VideoPlayerModal
            video={video.externalVideo}
            isVisible={isVideoPlayVisible}
            onVideoPlay={onVideoPlay}
            closeVideoPlayModal={closeVideoPlayModal}
          />
          <VideoContainer>
            <AspectRatio width={thumbWidth} ratio={thumbAspectRatio}>
              <img src={thumbnailUrl} alt={getTranslation('videos.externalLinks.videoThumbnail')} />
              <div
                // an accessible button without the HTML button tag
                onClick={() => openVideoPlayModal()}
                onKeyDown={() => openVideoPlayModal()}
                role="button"
                tabIndex={0}
                aria-label={getTranslation('videos.externalLinks.videoThumbnail')}
              >
                <PlayButton type="material-play_arrow" />
              </div>
            </AspectRatio>
            <TWFlexContainer>
              <TWHeading3>{video.externalVideo?.title}</TWHeading3>
              <TWTextDefault twColor="secondary">{video.externalVideo?.description}</TWTextDefault>
            </TWFlexContainer>
          </VideoContainer>
          <TWFlexContainer twMarginTop={2}>
            <SmallLogo src={TWLogoBlack} alt="Teamworks logo" />
            <TWSpacingContainer twMarginBottom={1} />
            <TWTextDefault twColor="secondary">
              {getTranslation('videos.externalLinks.sharedViaTW')}{' '}
              <a href="https://www.teamworks.com/">
                {getTranslation('videos.externalLinks.learnMore')}
              </a>
            </TWTextDefault>
          </TWFlexContainer>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default VideosExternalPlay;
