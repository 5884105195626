/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBulletinsSettingsMutationVariables = Types.Exact<{
  input: Types.BulletinsSettingsInput;
}>;

export type CreateBulletinsSettingsMutation = {
  bulletinsSettingsCreate: {
    bulletinsSettings?: {
      birthdaysAnnouncementCodes?: Array<string | null> | null;
      birthdaysVisibilityCodes?: Array<string | null> | null;
      isBirthdaysEnabled: boolean;
      bulletinsSettingsId: string;
      label?: string | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const CreateBulletinsSettingsDocument = gql`
  mutation createBulletinsSettings($input: BulletinsSettingsInput!) {
    bulletinsSettingsCreate(input: $input) {
      bulletinsSettings {
        birthdaysAnnouncementCodes
        birthdaysVisibilityCodes
        isBirthdaysEnabled
        bulletinsSettingsId
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CreateBulletinsSettingsMutationFn = Apollo.MutationFunction<
  CreateBulletinsSettingsMutation,
  CreateBulletinsSettingsMutationVariables
>;

/**
 * __useCreateBulletinsSettingsMutation__
 *
 * To run a mutation, you first call `useCreateBulletinsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulletinsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulletinsSettingsMutation, { data, loading, error }] = useCreateBulletinsSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBulletinsSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBulletinsSettingsMutation,
    CreateBulletinsSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBulletinsSettingsMutation,
    CreateBulletinsSettingsMutationVariables
  >(CreateBulletinsSettingsDocument, options);
}
export type CreateBulletinsSettingsMutationHookResult = ReturnType<
  typeof useCreateBulletinsSettingsMutation
>;
export type CreateBulletinsSettingsMutationResult =
  Apollo.MutationResult<CreateBulletinsSettingsMutation>;
export type CreateBulletinsSettingsMutationOptions = Apollo.BaseMutationOptions<
  CreateBulletinsSettingsMutation,
  CreateBulletinsSettingsMutationVariables
>;
