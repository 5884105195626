import { i18nLocaleTranslator as translator } from '@tw/i18n';
import _ from 'lodash';

const reminderUnits = ['minutes', 'hours', 'days', 'weeks'] as const;

export type ReminderUnits = (typeof reminderUnits)[number];

export type ReminderType = 'sms' | 'email';

export const reminderTypes = [
  { value: 'sms', label: translator.t('components.reminderTypes.sms') },
  { value: 'email', label: translator.t('email') },
];

export const offsetTypes = _.map(reminderUnits, (unit) => ({
  value: unit,
  label: translator.t(`components.offsetType.${unit}`),
}));

export interface ReminderDataModel {
  type: ReminderType;
  time: number;
}

export interface FormReminder {
  time: number;
  unit: ReminderUnits;
  type: ReminderType;
}

export interface TWInputCustomReminderProps {
  isDisabled?: boolean;
  maxReminders?: number;
  onChange?: (newValue: ReminderDataModel[]) => void;
  popupContainerId?: string;
  testID?: string;
  value?: FormReminder[];
}
