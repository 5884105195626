import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useFlags } from '@tw/hooks';

import RichTextEditor from '../RichTextEditor';
import { EditorWrapper } from './TWInputRichTextEditor.styles';

Quill.register(Quill.import('attributors/attribute/direction'), true);
Quill.register(Quill.import('attributors/class/align'), true);
Quill.register(Quill.import('attributors/class/background'), true);
Quill.register(Quill.import('attributors/class/color'), true);
Quill.register(Quill.import('attributors/class/direction'), true);
Quill.register(Quill.import('attributors/class/font'), true);
Quill.register(Quill.import('attributors/class/size'), true);
Quill.register(Quill.import('attributors/style/align'), true);
Quill.register(Quill.import('attributors/style/background'), true);
Quill.register(Quill.import('attributors/style/color'), true);
Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/font'), true);
Quill.register(Quill.import('attributors/style/size'), true);

// use div instead of p as a block element
// it avoids to unwanted new line if html is used for example as email template
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  ['link'],
  [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['blockquote', { script: 'sub' }, { script: 'super' }],
  ['clean'],
];

const TWInputRichTextEditor: React.FC<{
  onChange?: (value: string) => void;
  value?: string;
}> = ({ onChange, value }) => {
  const { wcagSlateRichTextEditor } = useFlags();

  if (wcagSlateRichTextEditor) {
    return <RichTextEditor value={value} onChange={onChange} />;
  }

  return (
    <EditorWrapper data-text-editor="wrapper">
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={{ toolbar: toolbarOptions }}
        bounds="[data-text-editor='wrapper']"
      />
    </EditorWrapper>
  );
};

export { TWInputRichTextEditor };
