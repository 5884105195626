/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentAttachmentDeleteMutationVariables = Types.Exact<{
  fileIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type AppointmentAttachmentDeleteMutation = {
  appointmentAttachmentBulkDelete: { fileAttachment?: { id: string } | null };
};

export const AppointmentAttachmentDeleteDocument = gql`
  mutation AppointmentAttachmentDelete($fileIds: [ID!]!) {
    appointmentAttachmentBulkDelete(fileIds: $fileIds) {
      fileAttachment {
        id
      }
    }
  }
`;
export type AppointmentAttachmentDeleteMutationFn = Apollo.MutationFunction<
  AppointmentAttachmentDeleteMutation,
  AppointmentAttachmentDeleteMutationVariables
>;

/**
 * __useAppointmentAttachmentDeleteMutation__
 *
 * To run a mutation, you first call `useAppointmentAttachmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentAttachmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentAttachmentDeleteMutation, { data, loading, error }] = useAppointmentAttachmentDeleteMutation({
 *   variables: {
 *      fileIds: // value for 'fileIds'
 *   },
 * });
 */
export function useAppointmentAttachmentDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppointmentAttachmentDeleteMutation,
    AppointmentAttachmentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AppointmentAttachmentDeleteMutation,
    AppointmentAttachmentDeleteMutationVariables
  >(AppointmentAttachmentDeleteDocument, options);
}
export type AppointmentAttachmentDeleteMutationHookResult = ReturnType<
  typeof useAppointmentAttachmentDeleteMutation
>;
export type AppointmentAttachmentDeleteMutationResult =
  Apollo.MutationResult<AppointmentAttachmentDeleteMutation>;
export type AppointmentAttachmentDeleteMutationOptions = Apollo.BaseMutationOptions<
  AppointmentAttachmentDeleteMutation,
  AppointmentAttachmentDeleteMutationVariables
>;
