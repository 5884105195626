import styled from '@emotion/styled';
import { sharedSpacingStyles, SpacingComponentProps } from '../../../../../utils';

const Container = styled.div<{ inputWidth: number | string }>(({ inputWidth = 265 }) => ({
  width: inputWidth,
}));

const SelectionsContainer = styled.div<SpacingComponentProps>(() => [sharedSpacingStyles]);
SelectionsContainer.defaultProps = {
  twMarginTop: 1,
};

const SelectionListWrapper = styled.div<{ selectionsMaxHeight: string | number }>(
  ({ selectionsMaxHeight = 'auto' }) => ({
    maxHeight: selectionsMaxHeight,
    overflow: 'hidden',
    overflowY: 'auto',
  }),
);

export { Container, SelectionsContainer, SelectionListWrapper };
