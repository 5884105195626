import { Tooltip } from 'antd';
import { CompactPicker } from 'react-color';
import { TWDropdown } from '@tw/components';
import { Icon } from '../components';
import { activeMark, addMarkData } from '../utils/slate';
import { ButtonProps } from '../RichTextEditor.definitions';
import { ColorButton } from '../RichTextEditor.styles';

export const ColorPicker = ({ accessibilityLabel, format, editor }: ButtonProps) => {
  const handleColorChange = (color: { hex: string }) => {
    const value = color.hex;
    addMarkData(editor, { format, value });
  };
  return (
    <TWDropdown
      menu={{
        items: [
          {
            key: 'colorPicker',
            label: <CompactPicker onChange={handleColorChange} />,
          },
        ],
      }}
    >
      <Tooltip title={accessibilityLabel}>
        <ColorButton
          aria-label={accessibilityLabel}
          type="button"
          color={activeMark(editor, format) || 'black'}
        >
          <Icon icon={format} />
        </ColorButton>
      </Tooltip>
    </TWDropdown>
  );
};
