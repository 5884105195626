import _ from 'lodash';

import { graphqlUtils } from '@tw/util';
import {
  GraphQLPaginatedResponse,
  PaginatedTutorData,
  RawTutor,
  RawTutorFilters,
  Tutor,
  TutorFilters,
} from '@tw/types';

const transformTutorFilters = ({
  tutorId,
  isWhiteListed = true,
  ...rest
}: TutorFilters): RawTutorFilters => ({
  ...rest,
  tutorId: !_.isEmpty(tutorId)
    ? graphqlUtils.getQueryFilterFromWhiteListSelection({ isWhiteListed, selectionKeys: tutorId })
    : undefined,
});

const transformRawTutor = (rawTutorData: RawTutor): Tutor => {
  if (!rawTutorData) {
    return undefined;
  }

  return {
    ...rawTutorData,
    courses: rawTutorData.courses
      ? graphqlUtils.convertEdgesToArray(rawTutorData.courses.edges)
      : undefined,
    subjects: rawTutorData.subjects
      ? graphqlUtils.convertEdgesToArray(rawTutorData.subjects.edges)
      : undefined,
  };
};

const normalizeTutorRawPaginatedData = (
  rawTutorData: GraphQLPaginatedResponse<RawTutor>,
): PaginatedTutorData => {
  if (!rawTutorData) {
    return rawTutorData;
  }

  const { edges, pageInfo, totalCount } = rawTutorData;
  const tutors = graphqlUtils.convertEdgesToArray<RawTutor>(edges);

  const safeTutors = _.map(tutors, transformRawTutor);

  return { tutors: safeTutors, pageInfo, totalCount };
};

export default {
  transformRawTutor,
  transformTutorFilters,
  normalizeTutorRawPaginatedData,
};
