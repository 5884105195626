import styled from '@emotion/styled';

import { theme } from '@tw/components/utils';

export const iconWithContentStyle = {
  column: {
    display: 'flex',
    overflow: 'visible',
  },
};

export const AttributeGridWrapper = styled.div({
  '.grid-item': {
    paddingBottom: theme.fontUnit * 2,
  },
  '.attribute-value': {
    fontSize: theme.fontUnit * 6,
  },
});
