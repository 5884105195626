import { castArray } from 'lodash';
import { ReactText, useCallback, useEffect, useState } from 'react';

import { useAllTerms } from '@tw/hooks';
import { Term } from '@tw/types';

import TWInputSelect from '../TWInputSelect';
import { TWInputSelectTermProps } from './TWInputSelectTerm.definitions';
import { selectDefaultTerm } from './TWInputSelectTerm.utils';

export const TWInputSelectTerm = (props: TWInputSelectTermProps) => {
  const {
    allowEmpty = false,
    defaultValue = [],
    onChange,
    placeholder = 'Term',
    popupContainerId = '',
    useGlobalId = false,
    value,
    ...allUnrecognizedProps
  } = props;

  const { loading, error, data: terms } = useAllTerms();

  // In order to guarantee that this selector pre-populates with a value,
  // we have to make the child selector a controlled component.
  // TWInputSelectTerm can then be used as a controlled component or as an uncontrolled component.
  const [selectedValue, setSelectedValue] = useState<ReactText[]>(defaultValue);

  const handleChange = useCallback(
    (newValue: ReactText | ReactText[]) => {
      const safeValue = castArray(newValue);
      setSelectedValue(safeValue);
      onChange(safeValue);
    },
    [onChange],
  );

  useEffect(() => {
    if (!allowEmpty && !defaultValue?.length && terms && terms.length > 0) {
      handleChange(selectDefaultTerm(terms ?? [], useGlobalId));
    }

    // handleChange isn't included in this dependency array to prevent infinite renders in antd Forms.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terms, defaultValue?.length, useGlobalId, allowEmpty]);

  const keyExtractor = (term: Term) => (useGlobalId ? term.id : term.termId);

  const labelExtractor = (term: Term) => term.label;

  const valueExtractor = (term: Term) => (useGlobalId ? term.id : Number(term.termId));

  if (loading || error || !terms || !terms.length) return null;

  return (
    <TWInputSelect
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      loading={loading}
      itemList={terms ?? []}
      keyExtractor={keyExtractor}
      labelExtractor={labelExtractor}
      valueExtractor={valueExtractor}
      defaultValue={defaultValue?.[0]}
      onChange={handleChange}
      popupContainerId={popupContainerId}
      minWidth="240px"
      value={value || selectedValue}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allUnrecognizedProps}
    />
  );
};
