import min from 'lodash/min';

const getPositionStart = (pageIndex: number, rowsPerPage: number) => pageIndex * rowsPerPage + 1;
const getPositionEnd = (pageIndex: number, rowsPerPage: number) => rowsPerPage * (pageIndex + 1);

const getRangeOfRowsOnPage = (pageIndex: number, rowsPerPage: number, totalCount: number) => {
  const positionStart = getPositionStart(pageIndex, rowsPerPage);
  const positionEnd = min([getPositionEnd(pageIndex, rowsPerPage), totalCount]);

  return `${positionStart}-${positionEnd}`;
};

export default {
  getRangeOfRowsOnPage,
};
