/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveSignupMutationVariables = Types.Exact<{
  signupId?: Types.InputMaybe<Types.Scalars['String']>;
  input?: Types.InputMaybe<Types.SignupInput>;
  sendAlerts?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type SaveSignupMutation = {
  saveSignup: {
    canEdit?: boolean | null;
    appointmentTypeIcon?: string | null;
    signupId: string;
    recurrence?: string | null;
    location?: string | null;
    mandatory?: boolean | null;
    numReservations?: number | null;
    slotDuration?: string | null;
    slotInterval?: string | null;
    firstStartTime?: any | null;
    lastEndTime?: any | null;
    title: string;
    slotCapacity?: number | null;
    appointmentType?: { appointmentTypeId?: number | null; color?: string | null } | null;
    creator?: {
      name?: string | null;
      title?: string | null;
      emailAddress?: string | null;
      pictureImage?: string | null;
    } | null;
  };
};

export const SaveSignupDocument = gql`
  mutation SaveSignup($signupId: String, $input: SignupInput, $sendAlerts: String) {
    saveSignup(signupId: $signupId, input: $input, sendAlerts: $sendAlerts) {
      appointmentType {
        appointmentTypeId
        color
      }
      canEdit
      creator {
        name
        title
        emailAddress
        pictureImage
      }
      appointmentTypeIcon
      signupId
      recurrence
      location
      mandatory
      numReservations
      slotDuration
      slotInterval
      recurrence
      firstStartTime
      lastEndTime
      title
      slotCapacity
    }
  }
`;
export type SaveSignupMutationFn = Apollo.MutationFunction<
  SaveSignupMutation,
  SaveSignupMutationVariables
>;

/**
 * __useSaveSignupMutation__
 *
 * To run a mutation, you first call `useSaveSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSignupMutation, { data, loading, error }] = useSaveSignupMutation({
 *   variables: {
 *      signupId: // value for 'signupId'
 *      input: // value for 'input'
 *      sendAlerts: // value for 'sendAlerts'
 *   },
 * });
 */
export function useSaveSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveSignupMutation, SaveSignupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveSignupMutation, SaveSignupMutationVariables>(
    SaveSignupDocument,
    options,
  );
}
export type SaveSignupMutationHookResult = ReturnType<typeof useSaveSignupMutation>;
export type SaveSignupMutationResult = Apollo.MutationResult<SaveSignupMutation>;
export type SaveSignupMutationOptions = Apollo.BaseMutationOptions<
  SaveSignupMutation,
  SaveSignupMutationVariables
>;
