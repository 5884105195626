import styled from '@emotion/styled';

export const Wrapper = styled.div<{ justifyContent: string; isEditMode?: boolean }>(
  ({ justifyContent, theme }) => ({
    borderBottom: `1px solid ${theme.colors.border}`,
    borderRadius: '1rem 1rem 0 0',
    columnGap: theme.baseUnit,
    display: 'flex',
    flexDirection: 'row',
    justifyContent,
    overflowY: 'auto',
    padding: '1rem 0.75rem',
    width: '100%',
  }),
);

export const Actions = styled.div(({ theme }) => ({
  columnGap: theme.baseUnit * 2,
  display: 'flex',
  flexDirection: 'row',
}));

export const Filters = styled.div`
  column-gap: 16px;
  display: flex;
  flex-direction: row;
`;

export const RightActionsDivider = styled.div(({ theme }) => ({
  width: '8px',
  marginLeft: '8px',
  borderLeft: `2px solid ${theme.colors.lightIcon}`,
}));
