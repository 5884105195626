import { TWFlexContainer, TWButton, TWModal } from '@tw/components';
import { getTranslation } from '@tw/i18n';
import { DocusignFormInstructionItem } from './DocuSign.styles';

export const InstructionModal = ({
  containerId,
  isVisible,
  handleClose,
}: {
  containerId: string;
  isVisible: boolean;
  handleClose: () => void;
}) => (
  <TWModal
    containerId={containerId}
    title={getTranslation('forms.docusignFormCompletion.instructions.modalTitle')}
    open={isVisible}
    onCancel={() => handleClose()}
    footer={
      <TWFlexContainer align="flex-end">
        <TWButton onClick={() => handleClose()}>{getTranslation('close')}</TWButton>
      </TWFlexContainer>
    }
  >
    <DocusignFormInstructionItem id="helpItems">
      <li>
        <strong>{getTranslation('review')}</strong>
        <ul>
          <li>{getTranslation('forms.docusignFormCompletion.instructions.reviewFormDetailsM')}</li>
        </ul>
        <br />
      </li>
      <li>
        <strong>{getTranslation('complete')}</strong>
        <ul>
          <li>{getTranslation('forms.docusignFormCompletion.instructions.mandatoryFieldsM')}</li>
        </ul>
        <br />
      </li>
      <li>
        <strong>{getTranslation('forms.docusignFormCompletion.instructions.signFormT')}</strong>
        <ul>
          <li>{getTranslation('forms.docusignFormCompletion.instructions.signFormM')}</li>
        </ul>
        <br />
      </li>
      <li>
        <strong>{getTranslation('submit')}</strong>
        <ul>
          <li>{getTranslation('forms.docusignFormCompletion.instructions.submitFormM')}</li>
        </ul>
        <br />
      </li>
      <li style={{ listStyleType: 'none' }}>
        <strong>{getTranslation('forms.docusignFormCompletion.instructions.addlAssisT')}</strong>
        <ul>
          <li>
            {getTranslation('forms.docusignFormCompletion.instructions.addlAssisM')}
            <a href="mailto:support@teamworksapp.com">support@teamworksapp.com</a>
          </li>
        </ul>
      </li>
    </DocusignFormInstructionItem>
  </TWModal>
);
