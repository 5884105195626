import styled from '@emotion/styled';
import { Badge } from 'antd';
import {
  Button,
  ToggleSwitch,
  Tooltip,
  TooltipProvider,
  TooltipPortal,
  TooltipContent,
  TooltipTrigger,
} from '@teamworksdev/react';
import { useState } from 'react';

import { LDFlagsKey, useFlags } from '@tw/hooks';

import { css, useTheme } from '@emotion/react';
import { TWIcon } from '@tw/components';
import { useFeatureFlags } from './featureFlags.context';

type Position = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

interface FfControllerProps {
  flags: LDFlagsKey[];
  position?: Position;
}

export const FfController = ({ flags, position }: FfControllerProps) => {
  const { colors } = useTheme();
  const [showFlags, setShowFlags] = useState(false);

  const fflags = useFlags();
  const { localFlags, setLocalFlag } = useFeatureFlags();

  if (process.env.NODE_ENV !== 'development') return null;

  return (
    <TooltipProvider>
      <FfToggleContainer position={position}>
        <FfButton onClick={() => setShowFlags((prev) => !prev)}>
          <TWIcon type="material-flag" />
          <TWIcon type={showFlags ? 'material-expand_less' : 'material-expand_more'} />
        </FfButton>

        <ListContainer visible={showFlags} flagCount={flags.length}>
          <ul>
            {flags.map((flag) => (
              <li key={flag}>
                <Tooltip>
                  <Badge
                    dot={!(flag in localFlags)}
                    offset={[0, 0]}
                    color={fflags[flag] ? colors.positive : colors.negative}
                  >
                    <TooltipTrigger asChild>
                      <Row>
                        <LinkButton
                          variant="link"
                          size="sm"
                          className={flag in localFlags ? 'localOverride' : undefined}
                          onClick={() => {
                            setLocalFlag(flag, flag in localFlags ? undefined : fflags[flag]);
                          }}
                          title="Toggle Override"
                        >
                          {flag}
                        </LinkButton>
                        {flag in localFlags && (
                          <FfToggle
                            size="sm"
                            checked={fflags[flag]}
                            disabled={!(flag in localFlags)}
                            onCheckedChange={(value) => setLocalFlag(flag, value)}
                          />
                        )}
                      </Row>
                    </TooltipTrigger>
                  </Badge>
                  <TooltipPortal>
                    <TooltipContent side="left">{`'${flag}' is ${fflags[flag] ? 'ON' : 'OFF'} ${
                      flag in localFlags ? 'locally' : 'in LD'
                    }`}</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </li>
            ))}
          </ul>
        </ListContainer>
      </FfToggleContainer>
    </TooltipProvider>
  );
};

const FfButton = styled(Button)`
  background-color: #000;
  color: #fff;
  padding: 3px;
`;

const FfToggleContainer = styled.div<{ position?: Position }>(({ position }) => {
  const [vertical, horizontal] = (position ?? 'bottom-right').split('-');

  return css`
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: ${horizontal === 'left' ? 'flex-start' : 'flex-end'};

    ${vertical}: 200px;
    ${horizontal}: 1rem;
  `;
});

const ListContainer = styled.div<{ visible: boolean; flagCount: number }>(
  ({ theme, visible, flagCount }) => css`
    max-height: ${visible ? `${flagCount * 32 + 100}px` : '0'};
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    margin-top: 0.5rem;
    min-width: 10rem;
    display: ${visible ? 'block' : 'none'};

    ul {
      background-color: ${theme.colors.background};
      border-radius: 5px;
      border: 1px inset ${theme.colors.border};
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0.7rem;
      margin: 0;
      align-items: flex-end;

      li {
        display: flex;
        justify-content: space-between;

        span {
          font-weight: ${theme.fontWeight.bold};
          margin-right: 0.5rem;
        }
      }
    }
  `,
);

const FfToggle = styled(ToggleSwitch)(
  ({ theme }) => css`
    padding: 0;

    &[type='button'][data-state='unchecked']:not(:disabled) {
      background-color: ${theme.colors.negative};
    }

    &[type='button'][data-state='checked']:not(:disabled) {
      background-color: ${theme.colors.positive};
    }
  `,
);

const LinkButton = styled(Button)(
  ({ theme }) => css`
    background-color: unset;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &.tw-text-black {
      color: ${theme.colors.black60};

      &.localOverride {
        color: ${theme.colors.focus};
      }
    }
  `,
);

const Row = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
);
