import { useTheme } from '@emotion/react';
import { AutoComplete } from 'antd';

import { TWFlexContainer } from '@tw/components';

import { SearchIcon } from './TWInputAutoCompleteSearch.styles';

interface TWInputAutoCompleteSearchProps {
  accessibilityLabel?: string;
  allowClear?: boolean;
  autoFocus?: boolean;
  width?: string | number;
  id?: string;
  key?: string;
  options?: { label: JSX.Element | string; value: string }[];
  dropdownHeader?: { label: JSX.Element | string; value: string };
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  testID?: string;
  onClear?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const { Option } = AutoComplete;

const TWInputAutoCompleteSearch = (props: TWInputAutoCompleteSearchProps) => {
  const { colors } = useTheme();
  const {
    accessibilityLabel,
    allowClear,
    autoFocus,
    width = '200px',
    id,
    key,
    options,
    dropdownHeader,
    defaultValue,
    value,
    onBlur,
    onChange,
    onClear,
    onFocus,
    placeholder,
    testID,
  } = props;

  const optionsList = options?.map((option) => (
    <Option key={option.value} value={option.value}>
      {option.label}
    </Option>
  ));

  const firstRow = options && options?.length > 0 && dropdownHeader && (
    <Option
      style={{ cursor: 'default' }}
      disabled
      key={dropdownHeader.value}
      value={dropdownHeader.value}
    >
      {dropdownHeader.label}
    </Option>
  );

  return (
    <AutoComplete
      aria-label={accessibilityLabel}
      allowClear={allowClear}
      autoFocus={autoFocus}
      style={{ width }}
      id={id}
      key={key}
      defaultValue={defaultValue}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onClear={onClear}
      onFocus={onFocus}
      placeholder={
        <TWFlexContainer row align="center">
          <SearchIcon
            type="material-search"
            color={colors.icon}
            width={16}
            height={16}
            aria-hidden="true"
          />
          {placeholder}
        </TWFlexContainer>
      }
      data-testid={testID}
      className="tw-input-search"
    >
      {firstRow}
      {optionsList}
    </AutoComplete>
  );
};

export default TWInputAutoCompleteSearch;
