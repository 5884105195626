import { PropsWithChildren } from 'react';
import { useWindowSize } from 'react-use';

import { TWLogoBlack } from '@tw/assets';
import { TWSpacingContainer } from '@tw/components';
import { getTranslation } from '@tw/i18n';
import { Heading } from '@teamworksdev/react';

import { Container, ColumnContainer, FormContainer, TWLogoImage } from './StyledComponents';

interface AuthTemplateProps extends PropsWithChildren {
  heading?: string;
  helperText?: React.ReactNode;
  footerLinks?: React.ReactNode;
}

export const AuthTemplate = ({ heading, helperText, footerLinks, children }: AuthTemplateProps) => {
  const { width } = useWindowSize();
  const largeDisplay = width > 1000;
  const formWidth = largeDisplay ? '55%' : '85%';

  return (
    <Container>
      <ColumnContainer span={largeDisplay ? 12 : 24}>
        <FormContainer style={{ width: formWidth }}>
          <TWSpacingContainer twMarginBottom={6}>
            <TWLogoImage src={TWLogoBlack} alt="Teamworks logo" />
          </TWSpacingContainer>
          {heading && <Heading size="h1">{heading}</Heading>}
          {helperText && <TWSpacingContainer>{helperText}</TWSpacingContainer>}
          {children}
          <TWSpacingContainer twMarginTop={10}>{footerLinks}</TWSpacingContainer>
        </FormContainer>
      </ColumnContainer>
      {largeDisplay && (
        <ColumnContainer span={12} style={{ padding: '12px' }}>
          <div id="marketing-panel">
            <video className="videoInsert" autoPlay muted loop>
              <source
                src="https://cdn.armssoftware.com/web/_Web-Hero_Background.mp4"
                type="video/mp4"
              />
              {getTranslation('authentication.notSupportedBrowser')}
            </video>
          </div>
        </ColumnContainer>
      )}
    </Container>
  );
};
