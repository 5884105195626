import { gql } from '@apollo/client';

export const SubjectUpdate = gql`
  mutation SubjectUpdate($id: ID!, $input: SubjectUpdateInput!) {
    subjectUpdate(id: $id, input: $input) {
      subject {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
