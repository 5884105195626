import styled from '@emotion/styled';
import { Form } from 'antd';

const TWFormItemContainer = styled(Form.Item)(({ theme }) => ({
  display: 'block',
  label: {
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontUnit * 4,
    lineHeight: '24px',
  },
}));

export { TWFormItemContainer };
