import { PropsWithChildren, useEffect, useState } from 'react';
import session from '@tw/services/session';
import { useHistory } from 'react-router-dom';

const Authenticated = ({ children }: PropsWithChildren<{}>) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useHistory();

  const authenticate = async () => {
    if (session.isValid()) {
      setIsAuthenticated(true);
    } else {
      const refreshTokenIsValid = session.isRefreshTokenValid();
      // access token expired, refresh token may still be valid
      if (refreshTokenIsValid && session.isWithinInactivityTimeout()) {
        session
          .renewToken()
          .then(() => {
            setIsAuthenticated(true);
            history.push('/home/overview');
          })
          .catch(() => {
            session.clear();
            session.loginWithOkta();
          });
        return;
      }

      try {
        await session.logoutWithoutRedirect();
      } catch {
        // do nothing
      }

      session.loginWithOkta();
    }
  };

  useEffect(() => {
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  if (!isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};

export default Authenticated;
