import * as React from 'react';
import { useEffectOnce } from 'react-use';

import { useStudentsLazy } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { Student, StudentQueryVariables, StudentSortEnum } from '@tw/types';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect';

interface TWInputSelectStudentProps extends TWInputSelectProps {
  filters?: StudentQueryVariables;
  populateOnMount?: boolean;
}

const defaultFilters = {
  isDeleted: false,
  sort: [StudentSortEnum.last_name_asc, StudentSortEnum.preferred_name_asc],
};

export const TWInputSelectStudent: React.FC<TWInputSelectStudentProps> = (props) => {
  const {
    filters = {},
    placeholder = getTranslation('students', 2),
    populateOnMount = false,
    ...allUnrecognizedProps
  } = props;

  const { loading, data, fetchStudents } = useStudentsLazy({ ...defaultFilters, ...filters });
  const { students } = data || {};

  useEffectOnce(() => {
    if (populateOnMount) {
      // if the parent component has an initialValue, we need to perform a fetch when this component mounts
      // otherwise this will display the selector's value rather than its label
      fetchStudents();
    }
  });

  const keyExtractor = (student: Student) => student?.id;

  const labelExtractor = (student: Student) => student?.fullName;

  const valueExtractor = (student: Student) => student?.id;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <TWInputSelect
      onFocus={() => fetchStudents()}
      allowClear
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      keyExtractor={keyExtractor}
      labelExtractor={labelExtractor}
      valueExtractor={valueExtractor}
      itemList={students}
      loading={loading}
      minWidth="200px"
      {...allUnrecognizedProps}
    />
  );
};
