import { useState, useEffect, useRef } from 'react';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import { Result, Col, Row } from 'antd';
import { useEvent } from 'react-use';
import { Redirect, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Button, Heading } from '@teamworksdev/react';
import { MdInfo, MdListAlt } from '@teamworksdev/icons';
import { getTranslation } from '@tw/i18n';
import styled from '@emotion/styled';

import {
  TWHeading3,
  TWFlexContainer,
  TWSpacingContainer,
  TWTextDefault,
  TWMessage,
} from '@tw/components';
import { dayjs } from '@tw/util';
import { FormAssignmentCompletionRoleNode } from 'twGenerated';

import {
  INTERNAL_FETCH_DOCUSIGN_RECIPIENT_URL,
  EXTERNAL_FETCH_DOCUSIGN_RECIPIENT_URL,
} from './graphql';
import { InstructionModal } from './InstructionModal';
import { LocationWithState, routeMap } from '../Forms.definitions';

import {
  InfoAlert,
  DocusignContainer,
  DocusignIframe,
  DocusignInfoRow,
  DocusignPlaceHolder,
  DocusignPlaceHolderContainer,
  DocusignPlaceHolderBlockLarge,
  DocusignPlaceHolderBlockSmall,
  DocusignSpacingContainer,
  DocusignFlexContainer,
} from './DocuSign.styles';

type NoticeType = 'info' | 'success' | 'error' | 'warning' | 'loading';

const EMBED_IFRAME_ID = 'embediframe';

const CompletionWorkflowList = ({
  completionData,
}: {
  completionData: FormAssignmentCompletionRoleNode;
}) =>
  completionData.workflow?.map((step: FormAssignmentCompletionRoleNode, index: number) => {
    const assignee = step.person ? step.person.displayName : step.externalName;

    return (
      <Row key={step.id} gutter={16}>
        <Col flex="250px">
          <TWTextDefault>{`${index + 1}: ${
            assignee || getTranslation('forms.workflowStatus.toBeAssigned')
          }`}</TWTextDefault>
        </Col>
        <Col>
          <TWTextDefault>
            {step.status === 'queued' ? 'Waiting' : capitalize(step.status)}
          </TWTextDefault>
          {step.status !== 'queued' && (
            <TWTextDefault> on {moment(step.updatedDate).format('MMM D')}</TWTextDefault>
          )}
        </Col>
      </Row>
    );
  });

const DocuSignResult = ({
  completionRole,
  internal,
}: {
  completionRole: FormAssignmentCompletionRoleNode;
  internal: boolean;
}) => {
  const location = useLocation<LocationWithState>();
  const { lastCallbackEvent, completion, isExternalRole, externalLinkUrl } = completionRole;
  const formIsCompleted = !!completion?.isCompleted;

  let status: NoticeType = 'success';
  let title: string | null = null;
  let message: string | null | JSX.Element = null;

  switch (lastCallbackEvent) {
    case 'OnSigningComplete':
    case 'signing_complete':
      title = formIsCompleted
        ? getTranslation('forms.docusignFormCompletion.docusignStatus.formCompletedTa')
        : getTranslation('forms.docusignFormCompletion.docusignStatus.formCompletedTb');

      if (isExternalRole) {
        if (formIsCompleted) {
          message = getTranslation('forms.docusignFormCompletion.docusignStatus.formCompletedMa');
        } else {
          message = getTranslation('forms.docusignFormCompletion.docusignStatus.formCompletedMb');
        }

        if (externalLinkUrl) {
          message = (
            <>
              <br />
              {` ${getTranslation('forms.docusignFormCompletion.docusignStatus.formCompletedMc')}`}
              <br />
              <a href={externalLinkUrl}>{externalLinkUrl}</a>
            </>
          );
        }
      }
      break;
    case 'OnCancel':
    case 'cancel':
      title = getTranslation('forms.docusignFormCompletion.docusignStatus.formSavedT');
      message = getTranslation('forms.docusignFormCompletion.docusignStatus.formSavedMa');
      if (isExternalRole) {
        if (completion?.isLinkCompletion) {
          message = ` ${getTranslation('forms.docusignFormCompletion.docusignStatus.formSavedMb')}`;
        } else {
          message = ` ${getTranslation('forms.docusignFormCompletion.docusignStatus.formSavedMc')}`;
        }
      }
      break;
    case 'OnViewingComplete':
    case 'viewing_complete':
      title = getTranslation('forms.docusignFormCompletion.docusignStatus.formViewedT');
      message = getTranslation('forms.docusignFormCompletion.docusignStatus.formViewedM');
      break;
    case 'OnDecline':
    case 'decline':
      title = getTranslation('forms.docusignFormCompletion.docusignStatus.formDeclinedT');
      message = getTranslation('forms.docusignFormCompletion.docusignStatus.formDeclinedM');
      break;
    case 'OnSessionTimeout':
    case 'session_timeout':
      status = 'error';
      title = getTranslation('forms.docusignFormCompletion.docusignStatus.formSessionTimedOutT');
      if (isExternalRole) {
        message = getTranslation(
          'forms.docusignFormCompletion.docusignStatus.formSessionTimedOutMa',
        );
      } else {
        message = getTranslation(
          'forms.docusignFormCompletion.docusignStatus.formSessionTimedOutMb',
        );
      }
      break;
    case 'OnTTLExpired':
    case 'ttl_expired':
      status = 'error';
      title = getTranslation('forms.docusignFormCompletion.docusignStatus.formSessionExpiredT');
      if (isExternalRole) {
        message = getTranslation(
          'forms.docusignFormCompletion.docusignStatus.formSessionExpiredMa',
        );
      } else {
        message = getTranslation(
          'forms.docusignFormCompletion.docusignStatus.formSessionExpiredMb',
        );
      }
      break;
    case 'OnException':
    case 'exception':
      status = 'error';
      title = getTranslation('forms.docusignFormCompletion.docusignStatus.formErrorT');
      message = getTranslation('forms.docusignFormCompletion.docusignStatus.formErrorMa');
      break;
    default:
      status = 'error';
      message = getTranslation('forms.docusignFormCompletion.docusignStatus.formErrorMb');
  }

  useEffect(
    () => {
      if (internal) {
        TWMessage[`${status}`]({ content: title, duration: 5, type: status });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internal],
  );
  const returnRoutes = () => {
    const matchingRoute = Object.keys(routeMap).find((key) => location?.state?.[key]);
    const { pathname, state } = matchingRoute ? routeMap[matchingRoute] : routeMap.fromAssignedToMe;

    return <Redirect to={{ pathname, state }} />;
  };
  return internal ? returnRoutes() : <Result status={status} title={title} extra={message} />;
};

export const DocuSignForm = ({
  completionRole,
  internal = false,
  uuid = '',
}: {
  completionRole: FormAssignmentCompletionRoleNode;
  internal?: boolean;
  uuid?: string;
}) => {
  const location = useLocation<LocationWithState>();
  const matchingRoute = Object.keys(routeMap).find((key) => location?.state?.[key]);
  const { pathname, state } = matchingRoute ? routeMap[matchingRoute] : routeMap.fromAssignedToMe;
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [submittedFormCompletion, setSubmittedFormCompletion] =
    useState<FormAssignmentCompletionRoleNode | null>(null);
  const [recipientTokenUrl, setRecipientTokenUrl] = useState<string>('');
  const pollAmount = useRef(500);
  const completionData = submittedFormCompletion || completionRole;

  let fetchUrl: DocumentNode;
  let variables: object;

  if (uuid) {
    fetchUrl = EXTERNAL_FETCH_DOCUSIGN_RECIPIENT_URL;
    variables = { uuid };
  } else {
    fetchUrl = INTERNAL_FETCH_DOCUSIGN_RECIPIENT_URL;
    variables = { id: completionData.id };
  }

  // The following request actually gets the URL. The recipientTokenUrl should already be building at this point
  const { startPolling, stopPolling } = useQuery(fetchUrl, {
    pollInterval: pollAmount.current,
    notifyOnNetworkStatusChange: true,
    variables,
    onCompleted: (docusignData) => {
      try {
        stopPolling();
        const url = uuid
          ? docusignData?.externalCompletionRole?.retrieveAsyncRecipientTokenUrl
          : docusignData?.completionRole?.retrieveAsyncRecipientTokenUrl;

        if (pollAmount.current > 15000) {
          throw new Error('Polling timed out.');
        } else if (url) {
          setRecipientTokenUrl(url);
        } else {
          pollAmount.current += 1000;
          startPolling(pollAmount.current);
        }
      } catch (error) {
        TWMessage.errorHandler(error);
      }
    },
    onError: (error) => {
      TWMessage.errorHandler(error);
      stopPolling();
    },
  });

  const {
    completion: { assignment },
  } = completionRole;
  const {
    assignedBy: { displayName },
    assignerEmail,
  } = assignment;
  // After the DocuSign iframe completes submitting the form
  // it's callback url ( -> ExternalFormCallback component ) will
  // send a window message w/ the updated form completion.
  const onMessage = (event: Event) => {
    const formCallback = event?.data?.formCallback;
    if (formCallback) {
      // history.replace(window.location.pathname, formCallback);
      setSubmittedFormCompletion(formCallback.completionRole);
    }
  };

  const containerId = 'docusignComplete';

  useEvent('message', onMessage, window);

  const getBreadcrumbText = () => {
    let breadcrumbText;
    switch (true) {
      case location?.state?.fromDashboard:
        breadcrumbText = getTranslation('navigation.tabs.dashboard');
        break;
      case location?.state?.fromPerpetual:
        breadcrumbText = getTranslation('forms.perpetualForms.title');
        break;
      case location?.state?.fromMyTasks:
        breadcrumbText = getTranslation('tasks.myTasks.myTasks');
        break;
      default:
        breadcrumbText = getTranslation('forms.assignedToMe.title');
        break;
    }
    return breadcrumbText;
  };

  const renderDocusignPortion = () => {
    if (recipientTokenUrl) {
      return (
        <DocusignContainer>
          <DocusignIframe
            title={getTranslation('forms.formats.docusign')}
            src={recipientTokenUrl}
            id={EMBED_IFRAME_ID}
            name={EMBED_IFRAME_ID}
            frameBorder="no"
          />
        </DocusignContainer>
      );
    }
    return (
      <DocusignPlaceHolderContainer>
        <InfoAlert
          showIcon
          message={
            <>
              <TWTextDefault twFontWeight="bold">{getTranslation('loading')}</TWTextDefault>
              {': '}
              <TWTextDefault>{getTranslation('forms.docusignLoadingAlert')}</TWTextDefault>
            </>
          }
          type="info"
        />
        <DocusignPlaceHolder>
          <DocusignPlaceHolderBlockLarge />
          <DocusignPlaceHolderBlockSmall />
          <DocusignPlaceHolderBlockSmall />
          <DocusignPlaceHolderBlockSmall />
          <DocusignPlaceHolderBlockSmall />
          <DocusignPlaceHolderBlockSmall />
          <DocusignPlaceHolderBlockSmall />
          <DocusignPlaceHolderBlockSmall />
        </DocusignPlaceHolder>
      </DocusignPlaceHolderContainer>
    );
  };

  return (
    <DocusignFlexContainer id={containerId}>
      <DocusignSpacingContainer>
        <TWFlexContainer>
          {internal && (
            <Breadcrumbs>
              <BreadcrumbItem>
                <BreadcrumbLink to="/forms">
                  <MdListAlt />
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink
                  to={{
                    pathname,
                    state,
                  }}
                >
                  {getBreadcrumbText()}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumbs>
          )}
          <TWFlexContainer row justify="space-between" align="center">
            <Header>
              <Heading>{assignment.label}</Heading>
            </Header>
            {!submittedFormCompletion && (
              <Button variant="secondary" onClick={() => setShowInstructionModal(true)}>
                <TWFlexContainer twPaddingRight={1}>
                  <MdInfo size={23} />
                </TWFlexContainer>
                {getTranslation('forms.docusignFormCompletion.instructions.buttonLabel')}
              </Button>
            )}
          </TWFlexContainer>
        </TWFlexContainer>
      </DocusignSpacingContainer>

      <TWFlexContainer row twPadding={3}>
        <DocusignInfoRow data-testid="Docusign:LeftInfoRow">
          <TWSpacingContainer twMarginBottom={2}>
            <TWHeading3 twColor="secondary" data-testid="Docusign:LeftInfoRow:Notes">
              {getTranslation('notes', 2)}
            </TWHeading3>
            <TWTextDefault>
              {/* TODO: Do we need to handle line breaks in notes here? */}
              {assignment.notes ||
                getTranslation('forms.docusignFormCompletion.instructions.noSpecialNotes')}
            </TWTextDefault>
          </TWSpacingContainer>
          <div data-testid="Docusign:LeftInfoRow:Assigner">
            <TWTextDefault twColor="secondary" twFontWeight="bold">
              {getTranslation('forms.assigner', 1)}
            </TWTextDefault>
          </div>
          <div>
            <TWTextDefault>
              {`${displayName} (`}
              <a href={`mailto:${assignerEmail}`}>{assignerEmail}</a>)
            </TWTextDefault>
          </div>
          <TWSpacingContainer twMarginBottom={2} />
          <div data-testid="Docusign:LeftInfoRow:Date">
            <TWTextDefault twColor="secondary" twFontWeight="bold">
              {assignment?.dueDate
                ? getTranslation('forms.dueDate')
                : getTranslation('forms.assignedDate')}
            </TWTextDefault>
          </div>
          <div>
            <TWTextDefault>
              {assignment?.dueDate
                ? dayjs(assignment.dueDate).format('MMMM D, YYYY')
                : dayjs(assignment.assignedDateTime).format('MMM D, YYYY')}
            </TWTextDefault>
          </div>
        </DocusignInfoRow>

        <DocusignInfoRow data-testid="Docusign:RightInfoRow">
          <TWHeading3 twColor="secondary" data-testid="Docusign:RightInfoRow:FormStatus">
            {getTranslation('forms.docusignFormCompletion.formStatus')}
          </TWHeading3>
          <CompletionWorkflowList completionData={completionData} />
        </DocusignInfoRow>
      </TWFlexContainer>
      {recipientTokenUrl && (
        <InfoAlert
          showIcon
          message={
            <>
              <TWTextDefault twFontWeight="bold">
                {getTranslation('forms.docusignFormCompletion.importantNote')}
              </TWTextDefault>
              <TWTextDefault>
                {internal
                  ? getTranslation('forms.docusignFormCompletion.internalInfoMsg')
                  : getTranslation('forms.docusignFormCompletion.externalInfoMsg')}
              </TWTextDefault>
            </>
          }
          type="info"
        />
      )}
      {completionData.reassignmentNotes && (
        <TWSpacingContainer
          twPaddingLeft={3}
          twPaddingBottom={3}
          customStyles={{ borderBottom: '1px solid gray' }}
        >
          <TWFlexContainer twMarginBottom={2}>
            <TWHeading3 twColor="secondary">
              {getTranslation('forms.docusignFormCompletion.reassignmentNotesHeader')}
            </TWHeading3>
            <TWTextDefault>{completionData.reassignmentNotes}</TWTextDefault>
          </TWFlexContainer>
        </TWSpacingContainer>
      )}
      {submittedFormCompletion ? (
        <DocuSignResult completionRole={submittedFormCompletion} internal={internal} />
      ) : (
        renderDocusignPortion()
      )}
      <InstructionModal
        containerId={containerId}
        handleClose={() => setShowInstructionModal(false)}
        isVisible={showInstructionModal}
      />
    </DocusignFlexContainer>
  );
};

const Header = styled.div({
  h2: {
    margin: '0',
  },
});
