/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideosFolderCreateMutationVariables = Types.Exact<{
  folderName: Types.Scalars['String'];
  folderType: Types.FolderTypeGraphene;
  teamId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type VideosFolderCreateMutation = {
  folderCreate: {
    folder?: {
      id: string;
      orgId: number;
      teamId?: number | null;
      folderId: string;
      folderName?: string | null;
      folderType?: string | null;
      sortOrder?: number | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const VideosFolderCreateDocument = gql`
  mutation VideosFolderCreate(
    $folderName: String!
    $folderType: FolderTypeGraphene!
    $teamId: Int
  ) {
    folderCreate(input: { folderName: $folderName, folderType: $folderType, teamId: $teamId }) {
      folder {
        id
        orgId
        teamId
        folderId
        folderName
        folderType
        sortOrder
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type VideosFolderCreateMutationFn = Apollo.MutationFunction<
  VideosFolderCreateMutation,
  VideosFolderCreateMutationVariables
>;

/**
 * __useVideosFolderCreateMutation__
 *
 * To run a mutation, you first call `useVideosFolderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideosFolderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videosFolderCreateMutation, { data, loading, error }] = useVideosFolderCreateMutation({
 *   variables: {
 *      folderName: // value for 'folderName'
 *      folderType: // value for 'folderType'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useVideosFolderCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideosFolderCreateMutation,
    VideosFolderCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VideosFolderCreateMutation, VideosFolderCreateMutationVariables>(
    VideosFolderCreateDocument,
    options,
  );
}
export type VideosFolderCreateMutationHookResult = ReturnType<typeof useVideosFolderCreateMutation>;
export type VideosFolderCreateMutationResult = Apollo.MutationResult<VideosFolderCreateMutation>;
export type VideosFolderCreateMutationOptions = Apollo.BaseMutationOptions<
  VideosFolderCreateMutation,
  VideosFolderCreateMutationVariables
>;
