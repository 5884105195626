import { Tooltip } from 'antd';
import { MouseEvent } from 'react';
import { Button, Icon } from '../components';
import { isMarkActive, toggleMark } from '../utils/slate';
import { ButtonProps } from '../RichTextEditor.definitions';

export const MarkButton = ({ editor, accessibilityLabel, format }: ButtonProps) => (
  <Tooltip title={accessibilityLabel}>
    <Button
      accessibilityLabel={accessibilityLabel}
      active={isMarkActive(editor, format)}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon icon={format} title={accessibilityLabel} />
    </Button>
  </Tooltip>
);
