import { Tooltip } from 'antd';
import { ReactNode, forwardRef } from 'react';

import { DOMUtils } from '@tw/util';

import { PopoverChildProps } from '@tw/types/antd';
import { TWIcon } from '../../../components.web';
import { SizeScalingType } from '../../../utils';
import TWChip from '../../TWChip';
import { defaultListItemSizeScaling, IconInterface } from '../utils';
import {
  Container,
  IconRow,
  LeftContentContainer,
  RightContentContainer,
} from './TWListItemAvatar.styles';

type TWListItemAvatarProps = {
  label: string | JSX.Element;
  testID?: string;
  labelStyles?: object;
  labelColor?: string;
  subtext?: string | JSX.Element;
  subtextTitle?: string;
  subtextStyles?: object;
  avatarText?: string;
  avatarImage?: string;
  avatarIcon?: ReactNode;
  size?: SizeScalingType;
  rightIcons?: IconInterface[];
  isHighlighted?: boolean;
  selectable?: boolean;
  isSelected?: boolean;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
  showAvatar?: boolean;
  padding?: string;
  className?: string;
} & PopoverChildProps;

const TWListItemAvatar = forwardRef<HTMLDivElement, TWListItemAvatarProps>(
  (
    {
      avatarIcon,
      avatarImage,
      avatarText,
      className,
      subtext,
      subtextStyles,
      subtextTitle,
      size,
      testID,
      label,
      labelStyles,
      labelColor,
      rightIcons,
      isSelected,
      selectable,
      isHighlighted,
      leftNode = null,
      rightNode = null,
      showAvatar = true,
      padding,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onFocus,
    },
    ref,
  ) => {
    const { padding: sizedPadding, rightIconSize } = defaultListItemSizeScaling[size ?? 'default'];
    const twPadding = padding ?? sizedPadding;

    return (
      <Container
        className={className}
        data-testid={testID}
        id={testID}
        isHighlighted={!!isHighlighted}
        selectable={selectable}
        ref={ref}
        twPadding={twPadding}
        role="button"
        onClick={onClick}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <LeftContentContainer>
          {leftNode}
          <TWChip
            size={size}
            labelStyles={labelStyles}
            labelColor={labelColor}
            label={label}
            subtext={subtext}
            subtextStyles={subtextStyles}
            subtextTitle={subtextTitle}
            showAvatar={showAvatar}
            avatarIcon={avatarIcon}
            avatarImage={avatarImage}
            avatarText={avatarText}
            isSelected={isSelected}
          />
        </LeftContentContainer>
        <RightContentContainer>
          {rightNode}
          <IconRow>
            {rightIcons?.map(({ type, tooltip, iconOnClick, style }: IconInterface) => (
              <Tooltip
                key={type}
                placement="bottom"
                title={tooltip}
                getPopupContainer={() => DOMUtils.getContainer(testID)}
              >
                <TWIcon
                  testID={`${testID}:${type}`}
                  type={type}
                  onClick={iconOnClick}
                  width={rightIconSize}
                  height={rightIconSize}
                  style={{ ...style, cursor: iconOnClick ? 'pointer' : 'default' }}
                />
              </Tooltip>
            ))}
          </IconRow>
        </RightContentContainer>
      </Container>
    );
  },
);

TWListItemAvatar.defaultProps = {
  size: 'default',
  avatarText: '',
  subtext: '',
  isHighlighted: false,
  isSelected: false,
};

export default TWListItemAvatar;
