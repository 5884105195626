import { useQuery } from '@apollo/client';
import { Tooltip } from 'antd';
import { DocumentNode } from 'graphql';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useLocalStorage } from 'react-use';

import {
  TWButtonIconFlat,
  TWFlexContainer,
  TWIcon,
  TWLink,
  TWLoadingSpinner,
  TWMessage,
} from '@tw/components';
import { downloadableFileModalDateFormat, noopFn } from '@tw/constants';
import { useDateTimeFormat } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';

import { DownloadableFileModalRef, ReportFile } from './DownloadableFileModal.definitions';
import {
  DownloadLink,
  ErrorBold,
  ErrorContainer,
  FileItem,
  FileItemBody,
  FileItemBodyContent,
  FileItemContainer,
  FileItemTimestamp,
  FileItemTitleText,
  FileStatusCard,
  StatusText,
} from './DownloadableFileModal.styles';

interface DownloadableFileModalProps {
  pollEndpoint: DocumentNode;
  pollLength?: number;
  title: string;
  bodyVisibleLocalStorageKey: string;
  visible?: boolean;
  onClose?: () => void;
  // Disabling because we could have any number of report types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resultParser: (arg0: any) => ReportFile[];
  ref?: DownloadableFileModalRef;
}

const DownloadableFileModal = forwardRef(
  (
    {
      pollEndpoint,
      pollLength = 5000,
      title,
      bodyVisibleLocalStorageKey,
      visible = false,
      resultParser,
      onClose = noopFn,
    }: DownloadableFileModalProps,
    ref,
  ) => {
    const [modalVisible, setModalVisible] = useState(visible);
    const [bodyVisible, setBodyVisible] = useLocalStorage(
      `dl_file_modal_body_${bodyVisibleLocalStorageKey}`,
      true,
    );
    const [fileList, setFileList] = useState<ReportFile[]>([]);
    const [pollingFailed, setPollingFailed] = useState(false);

    const { dateTimeFormatWithSeconds } = useDateTimeFormat({
      inputDateFormat: downloadableFileModalDateFormat,
    });

    const { startPolling, stopPolling } = useQuery(pollEndpoint, {
      pollInterval: pollLength,
      notifyOnNetworkStatusChange: true,
      onCompleted: (pollData) => {
        const formattedResults = resultParser(pollData).filter((file) => file.status !== 'expired');
        setFileList(formattedResults);
        // If a file is 'ready' or 'generating' show the modal
        if (
          formattedResults.find(
            (file) =>
              file.status === 'ready' || file.status === 'generating' || file.status === 'error',
          )
        ) {
          setModalVisible(true);
        }
        // If there are no more files generating, stop polling
        if (!formattedResults.find((file) => file.status === 'generating')) {
          stopPolling();
        }
      },
      onError: (error) => {
        TWMessage.errorHandler(error);
        stopPolling();
        setPollingFailed(true);
      },
    });

    const setModalVisibility = (visibleVal: boolean) => {
      setModalVisible(visibleVal);
    };

    const toggleBody = () => {
      setBodyVisible(!bodyVisible);
    };

    const handleOnClose = () => {
      setModalVisible(false);
      stopPolling();
      onClose();
    };

    const downloadInNewTab = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      link: string | null | undefined,
    ) => {
      event.preventDefault();
      window.open(link || undefined);
    };

    useImperativeHandle(ref, () => ({
      startPoll: () => {
        startPolling(pollLength);
        setPollingFailed(false);
      },
      setModalVisibility: (visibleVal: boolean) => {
        setModalVisibility(visibleVal);
        setBodyVisible(true);
      },
      addFileToList: (file: ReportFile) => {
        setFileList([file, ...fileList]);
      },
    }));

    const renderItemAvatar = (status: string) => {
      switch (status) {
        case 'generating':
          return (
            <TWFlexContainer>
              {pollingFailed ? (
                <TWIcon type="material-do_not_disturb_alt" color="gray" />
              ) : (
                <TWLoadingSpinner size="small" />
              )}
            </TWFlexContainer>
          );
        case 'ready':
          return (
            <TWFlexContainer>
              <TWIcon
                data-testid="ReportStatus:EventReport:Success"
                type="tw-circled-check"
                color="green"
              />
            </TWFlexContainer>
          );
        case 'error':
          return (
            <TWFlexContainer>
              <TWIcon type="material-cancel" color="red" />
            </TWFlexContainer>
          );
        default:
          return <TWFlexContainer />;
      }
    };

    const renderBody = (file: ReportFile) => {
      if (file.status === 'ready') {
        return (
          <>
            <StatusText>{getTranslation('reportStatus.reportReady')}</StatusText>
            <DownloadLink data-testid="ReportStatus:EventReport:Download">
              <TWLink
                href={file?.url || ''}
                text={getTranslation('downloads', 1)}
                onClick={(event) => downloadInNewTab(event, file.url)}
              />
            </DownloadLink>
          </>
        );
      }
      if (file.status === 'generating' && pollingFailed) {
        return <StatusText>{getTranslation('reportStatus.reportRequestError')}</StatusText>;
      }
      if (file.status === 'generating') {
        return <StatusText>{getTranslation('reportStatus.reportGenerating')}</StatusText>;
      }
      if (file.status === 'error') {
        return (
          <ErrorContainer>
            <ErrorBold>{getTranslation('error')}:</ErrorBold>
            <span>{file?.errorMessage || ''}</span>
          </ErrorContainer>
        );
      }
      return <StatusText>{file.status}</StatusText>;
    };

    return (
      <>
        {modalVisible && (
          <FileStatusCard
            data-testid="ReportStatus"
            title={
              <TWFlexContainer row>
                <StatusText data-testid="ReportStatus:Title">{title}</StatusText>
                <TWButtonIconFlat type="borderless" onClick={() => handleOnClose()}>
                  <TWIcon type="material-close" />
                </TWButtonIconFlat>
                <TWButtonIconFlat type="borderless" onClick={() => toggleBody()}>
                  {bodyVisible ? (
                    <TWIcon type="material-expand_more" />
                  ) : (
                    <TWIcon type="material-expand_less" />
                  )}
                </TWButtonIconFlat>
              </TWFlexContainer>
            }
          >
            {bodyVisible && (
              <FileItemContainer>
                {fileList.map((file) => (
                  <FileItem data-testid="ReportStatus:EventReport" key={file.downloadableFileId}>
                    {renderItemAvatar(file.status)}
                    <FileItemBody>
                      <Tooltip title={file.title} placement="bottomLeft">
                        <FileItemTitleText
                          data-testid="ReportStatus:EventReport:DateRange"
                          twColor="secondary"
                          twFontWeight="semibold"
                        >
                          {file.title}
                        </FileItemTitleText>
                      </Tooltip>
                      {file.createdDate && (
                        <FileItemTimestamp data-testid="ReportStatus:EventReport:ReportCreationTime">
                          {moment(file.createdDate).format(dateTimeFormatWithSeconds)}
                        </FileItemTimestamp>
                      )}
                      <FileItemBodyContent data-testid="ReportStatus:EventReport:StatusText">
                        {renderBody(file)}
                      </FileItemBodyContent>
                    </FileItemBody>
                  </FileItem>
                ))}
              </FileItemContainer>
            )}
          </FileStatusCard>
        )}
      </>
    );
  },
);

export default DownloadableFileModal;
