export enum SlotType {
  any = 'any',
}

export interface TutorSessionMeta {
  courseId: number;
  termId: number;
  tutorPersonId: number;
  tutorSessionRequestId?: number;
  location?: string;
}

export interface RawTutorMeta extends Omit<TutorSessionMeta, 'tutorSessionRequestId'> {
  tutorSessionRequestId?: string | number;
}

export interface AppointmentMeta {
  slotDuration?: number;
  slotParentAppointmentId?: number;
  slotType: keyof typeof SlotType | undefined;
  tutorSession?: TutorSessionMeta;
}

export interface RawAppointmentMeta extends Omit<AppointmentMeta, 'tutorSession'> {
  tutorSession?: RawTutorMeta;
}
