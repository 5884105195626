import GET_ELIGIBILITY_YEARS from './getEligibilityYears.graphql';

export default [
  {
    request: {
      query: GET_ELIGIBILITY_YEARS,
      variables: {
        includeIsCollegiate: false,
      },
    },
    result: {
      data: {
        eligibilityYears: [
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjE=',
            label: 'Freshman',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjg=',
            label: 'RS-Freshman',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjI=',
            label: 'Sophomore',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjk=',
            label: 'RS-Sophomore',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjM=',
            label: 'Junior',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjEw',
            label: 'RS-Junior',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjQ=',
            label: 'Senior',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjEx',
            label: 'RS-Senior',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjEy',
            label: 'Graduate Student',
            __typename: 'EligibilityYear',
          },
        ],
      },
    },
  },
  {
    request: {
      query: GET_ELIGIBILITY_YEARS,
      variables: {
        includeIsCollegiate: true,
      },
    },
    result: {
      data: {
        eligibilityYears: [
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjIy',
            label: '5th Grade',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjE4',
            label: '6th Grade',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjE=',
            label: 'Freshman',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjE=',
            label: 'Freshman',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjg=',
            label: 'RS-Freshman',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjI=',
            label: 'Sophomore',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjk=',
            label: 'RS-Sophomore',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjM=',
            label: 'Junior',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjEw',
            label: 'RS-Junior',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjQ=',
            label: 'Senior',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjEx',
            label: 'RS-Senior',
            __typename: 'EligibilityYear',
          },
          {
            id: 'RWxpZ2liaWxpdHlZZWFyOjEy',
            label: 'Graduate Student',
            __typename: 'EligibilityYear',
          },
        ],
      },
    },
  },
];
