/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideoIncrementDownloadCountMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type VideoIncrementDownloadCountMutation = {
  videoIncrementDownloadCount: {
    video?: { id: string; videoId: string; downloadCount?: number | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const VideoIncrementDownloadCountDocument = gql`
  mutation VideoIncrementDownloadCount($id: ID) {
    videoIncrementDownloadCount(id: $id) {
      video {
        id
        videoId
        downloadCount
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type VideoIncrementDownloadCountMutationFn = Apollo.MutationFunction<
  VideoIncrementDownloadCountMutation,
  VideoIncrementDownloadCountMutationVariables
>;

/**
 * __useVideoIncrementDownloadCountMutation__
 *
 * To run a mutation, you first call `useVideoIncrementDownloadCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoIncrementDownloadCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoIncrementDownloadCountMutation, { data, loading, error }] = useVideoIncrementDownloadCountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoIncrementDownloadCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideoIncrementDownloadCountMutation,
    VideoIncrementDownloadCountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VideoIncrementDownloadCountMutation,
    VideoIncrementDownloadCountMutationVariables
  >(VideoIncrementDownloadCountDocument, options);
}
export type VideoIncrementDownloadCountMutationHookResult = ReturnType<
  typeof useVideoIncrementDownloadCountMutation
>;
export type VideoIncrementDownloadCountMutationResult =
  Apollo.MutationResult<VideoIncrementDownloadCountMutation>;
export type VideoIncrementDownloadCountMutationOptions = Apollo.BaseMutationOptions<
  VideoIncrementDownloadCountMutation,
  VideoIncrementDownloadCountMutationVariables
>;
