import { deliveryTypeFilterOptions } from '@tw/constants';
import { getTranslation } from '@tw/i18n';

import TWInputSelect from '../TWInputSelect';

interface TWInputSelectDeliveryTypeProps {
  popupContainerId: string;
  placeholder?: string;
  testID: string;
  defaultValue?: string | string[];
  onChange: (value?: string | string[]) => void;
}

const TWInputSelectDeliveryType = ({
  popupContainerId,
  placeholder = 'All Deliveries',
  testID,
  defaultValue,
  onChange,
}: TWInputSelectDeliveryTypeProps) => (
  <TWInputSelect
    id="MessagingTable:DeliveryType"
    accessibilityLabel={getTranslation('messaging.history.messageType')}
    allowClear
    filterOption
    isMultiSelect
    optionFilterProp="title"
    placeholder={placeholder}
    defaultValue={defaultValue}
    loading={false}
    onChange={(value: string) => onChange(value)}
    popupContainerId={popupContainerId}
    testID={testID}
    minWidth="200px"
  >
    <TWInputSelect.Option
      data-testid="MessagingTable:DeliveryType:TextOption"
      value={deliveryTypeFilterOptions.TEXT}
      title={getTranslation('text')}
    >
      {getTranslation('text')}
    </TWInputSelect.Option>
    <TWInputSelect.Option
      data-testid="MessagingTable:DeliveryType:EmailOption"
      value={deliveryTypeFilterOptions.EMAIL}
      title={getTranslation('email')}
    >
      {getTranslation('email')}
    </TWInputSelect.Option>
  </TWInputSelect>
);

export default TWInputSelectDeliveryType;
