import { BearerToken, CurrentUser } from '@tw/services/session';
import { LocalStorageKeys } from './localStorage.definitions';

const storage = {
  broadcast(messageType: string, messageBody: string) {
    window.localStorage.setItem(messageType, messageBody);
    window.localStorage.removeItem(messageType);
  },

  clear() {
    const teamworksTokens = Object.values(LocalStorageKeys).map((key) => `${key}`);

    Object.keys(window.localStorage).forEach((key) => {
      if (teamworksTokens.includes(key)) {
        window.localStorage.removeItem(key);
      }
    });
  },

  subscribe(fn: (event: StorageEvent) => void) {
    window.addEventListener('storage', fn);
  },

  unsubscribe(fn: (event: StorageEvent) => void) {
    window.removeEventListener('storage', fn);
  },

  setBearerToken(token: BearerToken) {
    window.localStorage.setItem(LocalStorageKeys.TOKEN, JSON.stringify(token));
  },

  getBearerToken(): BearerToken | null {
    const item = window.localStorage.getItem(LocalStorageKeys.TOKEN);
    if (item) {
      return JSON.parse(item);
    }

    return null;
  },

  getAccessToken() {
    const bearerToken = this.getBearerToken();
    return bearerToken ? bearerToken.accessToken : null;
  },

  getRefreshToken() {
    const bearerToken = this.getBearerToken();
    return bearerToken ? bearerToken.refreshToken : null;
  },

  setLastActive(expiry: number) {
    window.localStorage.setItem(LocalStorageKeys.LAST_ACTIVE, `${expiry}`);
  },

  getLastActive() {
    const time = Number(window.localStorage.getItem(LocalStorageKeys.LAST_ACTIVE));
    return time || 0;
  },

  setSessionInactivityTimeout(time: number) {
    window.localStorage.setItem(LocalStorageKeys.SESSION_INACTIVITY_TIMEOUT, `${time}`);
  },

  getSessionInactivityTimeout() {
    const time = Number(window.localStorage.getItem(LocalStorageKeys.SESSION_INACTIVITY_TIMEOUT));
    return time || 0;
  },

  setCurrentUser(currentUser: CurrentUser) {
    window.localStorage.setItem(LocalStorageKeys.CURRENT_USER, JSON.stringify(currentUser));
  },

  getCurrentUser(): CurrentUser | null {
    const item = window.localStorage.getItem(LocalStorageKeys.CURRENT_USER);
    if (item) {
      return JSON.parse(item);
    }

    return null;
  },

  removeCurrentUser() {
    window.localStorage.removeItem(LocalStorageKeys.CURRENT_USER);
  },
};

export default storage;
