/**
 * This file provides static values about how the formatConfig interacts with and exists
 * within the Redux store.
 */

const storeKey = 'formatConfig';

export default {
  storeKey,
  rootSelector: (store) => store[storeKey],

  layerNames: [
    'baseDefault',
    'devicePreference',
    'internationalTime',
    'dateFormatsForLocale_es',
    'teamPreference',
    'debugOverride',
  ],
};
