/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserFileActionCreateMutationVariables = Types.Exact<{
  input: Types.UserFileVideoActionInput;
}>;

export type UserFileActionCreateMutation = {
  userFileVideoActionCreate: { userFileVideoAction?: { userFileVideoActionId: string } | null };
};

export const UserFileActionCreateDocument = gql`
  mutation UserFileActionCreate($input: UserFileVideoActionInput!) {
    userFileVideoActionCreate(input: $input) {
      userFileVideoAction {
        userFileVideoActionId
      }
    }
  }
`;
export type UserFileActionCreateMutationFn = Apollo.MutationFunction<
  UserFileActionCreateMutation,
  UserFileActionCreateMutationVariables
>;

/**
 * __useUserFileActionCreateMutation__
 *
 * To run a mutation, you first call `useUserFileActionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFileActionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFileActionCreateMutation, { data, loading, error }] = useUserFileActionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserFileActionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserFileActionCreateMutation,
    UserFileActionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserFileActionCreateMutation, UserFileActionCreateMutationVariables>(
    UserFileActionCreateDocument,
    options,
  );
}
export type UserFileActionCreateMutationHookResult = ReturnType<
  typeof useUserFileActionCreateMutation
>;
export type UserFileActionCreateMutationResult =
  Apollo.MutationResult<UserFileActionCreateMutation>;
export type UserFileActionCreateMutationOptions = Apollo.BaseMutationOptions<
  UserFileActionCreateMutation,
  UserFileActionCreateMutationVariables
>;
