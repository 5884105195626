import { GraphQLPageInfo, GraphQLPaginatedResponse } from '../graphQL';

import { Advisor } from './advisor';

// Intentionally minimal, expand as needed
export interface Student {
  id: string; // relay id
  studentId: string;
  personId: number;
  teamIds: number[];
  pictureDownloadUrl?: string;
  preferredName: string;
  lastName: string;
  fullName: string;
  cumulativeGpa: string;
  eligibilityYear: string;
  eligibilityYears: string[];
  isAtRisk: boolean;
  academicStatus?: string;
  person?: {
    id: string;
    firstName: string;
    teamLabels: string;
    emailAddress: string;
    phoneCell?: string;
    phoneHome?: string;
    phoneOffice?: string;
    schoolIdentifier: string;
  };
  // cursor may be added by graphqlUtils.convertEdgesToArray, but generally should not be used
  cursor?: string;
  isDeleted: boolean;
  studyHallDetails: {
    isCurrentlyCheckedIn: boolean;
    weeklyAccumulatedMinutes: number;
    weeklyHoursRequired: number;
  };
  metaData?: {
    college?: string;
    major?: string;
    minor?: string;
  };
  advisors?: GraphQLPaginatedResponse<Advisor>;
}

export interface RawStudent extends Omit<Student, 'advisors'> {
  advisors: GraphQLPaginatedResponse<Advisor>;
}

export enum StudentEligibilityStatuses {
  AT_RISK = 'AT_RISK',
  ON_TRACK = 'ON_TRACK',
}

export type StudentEligibilityStatus = keyof typeof StudentEligibilityStatuses;

export enum StudentSortEnum {
  /* eslint-disable camelcase */
  ac2_student_id_asc = 'ac2_student_id_asc',
  ac2_student_id_desc = 'ac2_student_id_desc',
  eligibility_year_asc = 'eligibility_year_asc',
  eligibility_year_desc = 'eligibility_year_desc',
  preferred_name_asc = 'preferred_name_asc',
  preferred_name_desc = 'preferred_name_desc',
  last_name_asc = 'last_name_asc',
  last_name_desc = 'last_name_desc',
  created_date_asc = 'created_date_asc',
  created_date_desc = 'created_date_desc',
  updated_date_asc = 'updated_date_asc',
  updated_date_desc = 'updated_date_desc',
  created_by_asc = 'created_by_asc',
  created_by_desc = 'created_by_desc',
  updated_by_asc = 'updated_by_asc',
  updated_by_desc = 'updated_by_desc',
  /* eslint-enable camelcase */
}

export interface StudentPaginatedRawData {
  students: GraphQLPaginatedResponse<Student>;
}

export interface StudentPaginatedData {
  students: Student[];
  pageInfo: GraphQLPageInfo;
  totalCount: number;
}

export interface StudentQueryVariables {
  advisorIds?: number[];
  after?: string;
  first?: number;
  isAtRisk?: boolean;
  isDeleted?: boolean;
  searchStudents?: string;
  sort?: StudentSortEnum[];
  studentId?: string | number;
  teamIds?: number[];
  termIds?: number[];
}

export interface StudentRawQueryVariables {
  advisorIds?: number[];
  after?: string;
  first?: number;
  isAtRisk?: string;
  isDeleted?: string;
  searchStudents?: string;
  sort?: StudentSortEnum[];
  studentId?: string;
  teamIds?: string;
  termIds?: number[];
}
