import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import TWIcon from '../TWIcon';

export const TWIconContentRow = styled(Row)({
  flexWrap: 'nowrap',
});
export const TWIconContentIcon = styled(TWIcon)(({ theme }) => ({
  fontSize: theme.fontUnit + 10,
}));
export const TWIconContentColumn = styled(Col)({
  overflow: 'hidden',
});
