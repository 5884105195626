import * as React from 'react';
import { Empty } from 'antd';

interface TWEmptyProps {
  testID?: string;
  label?: string;
}

const TWEmpty: React.FC<TWEmptyProps> = ({ testID, label }) => (
  <Empty
    description={label || undefined}
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    data-testid={testID || 'TWEmpty'}
  />
);

export default TWEmpty;
