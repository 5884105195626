import { useTheme } from '@emotion/react';
import { TWIcon } from '@tw/components';
import { getTranslation } from '@tw/i18n';
import { Input, InputProps } from 'antd';

interface TWInputSearchProps extends InputProps {
  testID?: string;
  width?: string | number;
  placeholder?: string;
  defaultValue?: string;
  charBuffer?: boolean;
  accessibilityLabel?: string;
  iconPrefix?: boolean;
}

const TWInputSearch = ({
  testID = '',
  width = 200,
  height = 32,
  onChange,
  charBuffer = false,
  accessibilityLabel = getTranslation('search'),
  iconPrefix = false,
  ...allUnrecognizedProps
}: TWInputSearchProps) => {
  const { colors } = useTheme();
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // specs say we shouldn't search if the user hasn't typed in 2 or more chars
    // but we should also clear the search if the user has nothing entered
    if (charBuffer) {
      if (event.target.value.length !== 1) {
        onChange?.(event);
      }
    } else {
      onChange?.(event);
    }
  };

  const searchIcon = (
    <TWIcon
      testID={`${testID}:SearchIcon`}
      type="material-search"
      color={colors.icon}
      width={16}
      height={16}
      aria-hidden="true"
    />
  );
  return (
    <Input
      aria-label={accessibilityLabel}
      data-testid={testID}
      style={{ width, height }}
      onChange={handleOnChange}
      autoComplete="chrome-off"
      className="tw-input-search"
      suffix={!iconPrefix && searchIcon}
      prefix={iconPrefix && searchIcon}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allUnrecognizedProps}
    />
  );
};

export default TWInputSearch;
