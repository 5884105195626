/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RevokeTicketingEventPersonMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type RevokeTicketingEventPersonMutation = {
  ticketingEventPersonRevoke: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const RevokeTicketingEventPersonDocument = gql`
  mutation RevokeTicketingEventPerson($id: ID) {
    ticketingEventPersonRevoke(id: $id) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type RevokeTicketingEventPersonMutationFn = Apollo.MutationFunction<
  RevokeTicketingEventPersonMutation,
  RevokeTicketingEventPersonMutationVariables
>;

/**
 * __useRevokeTicketingEventPersonMutation__
 *
 * To run a mutation, you first call `useRevokeTicketingEventPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeTicketingEventPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeTicketingEventPersonMutation, { data, loading, error }] = useRevokeTicketingEventPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeTicketingEventPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeTicketingEventPersonMutation,
    RevokeTicketingEventPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeTicketingEventPersonMutation,
    RevokeTicketingEventPersonMutationVariables
  >(RevokeTicketingEventPersonDocument, options);
}
export type RevokeTicketingEventPersonMutationHookResult = ReturnType<
  typeof useRevokeTicketingEventPersonMutation
>;
export type RevokeTicketingEventPersonMutationResult =
  Apollo.MutationResult<RevokeTicketingEventPersonMutation>;
export type RevokeTicketingEventPersonMutationOptions = Apollo.BaseMutationOptions<
  RevokeTicketingEventPersonMutation,
  RevokeTicketingEventPersonMutationVariables
>;
