/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTicketingEventMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  input: Types.TicketingEventInput;
}>;

export type UpdateTicketingEventMutation = {
  ticketingEventUpdate: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const UpdateTicketingEventDocument = gql`
  mutation UpdateTicketingEvent($id: ID, $input: TicketingEventInput!) {
    ticketingEventUpdate(id: $id, input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateTicketingEventMutationFn = Apollo.MutationFunction<
  UpdateTicketingEventMutation,
  UpdateTicketingEventMutationVariables
>;

/**
 * __useUpdateTicketingEventMutation__
 *
 * To run a mutation, you first call `useUpdateTicketingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketingEventMutation, { data, loading, error }] = useUpdateTicketingEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTicketingEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTicketingEventMutation,
    UpdateTicketingEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTicketingEventMutation, UpdateTicketingEventMutationVariables>(
    UpdateTicketingEventDocument,
    options,
  );
}
export type UpdateTicketingEventMutationHookResult = ReturnType<
  typeof useUpdateTicketingEventMutation
>;
export type UpdateTicketingEventMutationResult =
  Apollo.MutationResult<UpdateTicketingEventMutation>;
export type UpdateTicketingEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateTicketingEventMutation,
  UpdateTicketingEventMutationVariables
>;
