import { TWFormItemContainer } from './TWFormItem.styles';
import { TWFormItemProps } from './TWFormItem.definitions';

export const TWFormItem = ({
  colon = false,
  children,
  testID = '',
  ...restOfProps
}: TWFormItemProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TWFormItemContainer colon={colon} data-testid={testID} {...restOfProps}>
    {children}
  </TWFormItemContainer>
);
