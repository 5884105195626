import styled from '@emotion/styled';

import { theme } from '@tw/components/utils';

export const AttributeStackedWrapper = styled.div({
  '.attribute-label': {
    color: theme.colors.secondary,
  },
  '.attribute-value': {
    fontSize: theme.fontUnit * 6,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
