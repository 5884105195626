import { InputNumber, InputNumberProps } from 'antd';
import { FocusEvent } from 'react';
import styled from '@emotion/styled';
import { NumericInputWidgetSchema } from '@tw/modules/Forms/QuickForm/QuickForm.definitions';
import { WidgetProps } from 'react-jsonschema-form';

const getPlaceholder = (decimalPlaces?: number | null) => {
  const count = decimalPlaces ?? 2;
  if (count > 0) {
    return `0.${new Array(count).fill(0).join('')}`;
  }
  return '0';
};

type NumericInputProps = Omit<
  InputNumberProps<string>,
  | 'defaultValue'
  | 'stringMode'
  | 'formatter'
  | 'parser'
  | 'step'
  | 'precision'
  | 'onInput'
  | 'onBlur'
  | 'onFocus'
> &
  Pick<Partial<WidgetProps>, 'defaultValue' | 'onBlur' | 'onFocus'>;

/**
 * Barebones numeric input component that is configured by decimalPlaces.
 */
export const NumericInput = ({
  defaultValue,
  decimalPlaces,
  onFocus,
  onBlur,
  id,
  type = 'number',
  placeholder,
  onChange,
  ...props
}: NumericInputProps & NumericInputWidgetSchema) => {
  const restrictDecimals = typeof decimalPlaces === 'number';
  const decimals = Math.max(0, decimalPlaces ?? 0);
  // If decimals are greater than zero, the step will be 1/(10^decimals).
  const step = decimals > 0 ? 1 / 10 ** decimals : 1;
  const realDefaultValue: string | number | undefined = Array.isArray(defaultValue)
    ? defaultValue.filter<string>((v): v is string => typeof v === 'string')?.[0]
    : (defaultValue as string | number | undefined);

  return (
    <NumericInputBase
      id={id}
      defaultValue={realDefaultValue !== undefined ? `${realDefaultValue}` : undefined}
      step={restrictDecimals ? step : undefined}
      stringMode
      type={type}
      // only allow numbers, decimal, and negative sign
      parser={(val?: string) => val?.replace(/[^\d\-.]+/g, '') ?? ''}
      precision={restrictDecimals ? decimals : undefined}
      onFocus={(e: FocusEvent<HTMLInputElement>) => {
        onFocus?.(id ?? '', e.currentTarget.value);
      }}
      onBlur={(e: FocusEvent<HTMLInputElement>) => {
        onBlur?.(id ?? '', e.currentTarget.value);
      }}
      placeholder={placeholder || getPlaceholder(decimalPlaces)}
      onChange={(value: string | null) => {
        let realValue = value;
        if (restrictDecimals && value) {
          if (decimalPlaces) {
            // set decimal places
            realValue = `${parseFloat(value).toFixed(decimalPlaces)}`;
          } else {
            // no decimals, make integer
            realValue = `${parseInt(value, 10)}`;
          }
        }
        onChange?.(realValue ?? '');
      }}
      {...props}
    />
  );
};

export interface QuickFormNumericInputProps extends NumericInputProps {
  schema: NumericInputWidgetSchema;
  formContext: { previewTemplate: boolean };
}

/**
 * Quick form numeric input component that supports the quick form schema and context.
 */
export const QuickFormNumericInput = ({
  schema = {},
  formContext,
  ...props
}: QuickFormNumericInputProps) => {
  const { decimalPlaces } = schema;
  return <NumericInput decimalPlaces={decimalPlaces} {...props} />;
};

const NumericInputBase = styled(InputNumber<string>)(({ theme }) => ({
  width: '100%',
  maxWidth: 402,
  borderRadius: '6px',

  '.ant-input-number-input': {
    height: `${theme.baseUnit * 5.5}px`,
    paddingLeft: `${theme.baseUnit * 2}px`,
    paddingRight: `${theme.baseUnit * 2}px`,
  },
}));

export default QuickFormNumericInput;
