import { CustomStyles } from '../../presentational/definitions';
import { ThemeProp } from '../styled';
import {
  themedMargin,
  themedPadding,
  ThemeMarginProps,
  ThemePaddingProps,
} from './themedFunctions';

export type SpacingComponentProps = ThemeMarginProps & ThemePaddingProps & CustomStyles;

export const sharedSpacingStyles = ({ customStyles }: ThemeProp & SpacingComponentProps) => [
  themedMargin,
  themedPadding,
  customStyles,
];
