const IS_MAC =
  typeof window !== 'undefined' && /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

export const HOTKEY_LABELS = {
  bold: IS_MAC ? '⌘+B' : 'Ctrl+B',
  italic: IS_MAC ? '⌘+I' : 'Ctrl+I',
  underline: IS_MAC ? '⌘+U' : 'Ctrl+U',
};
