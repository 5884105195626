/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentAttachmentBulkCreateMutationVariables = Types.Exact<{
  files:
    | Array<Types.InputMaybe<Types.FileAttachmentFileData>>
    | Types.InputMaybe<Types.FileAttachmentFileData>;
  parentId: Types.Scalars['ID'];
}>;

export type AppointmentAttachmentBulkCreateMutation = {
  appointmentAttachmentBulkCreate: { fileAttachment?: Array<{ id: string } | null> | null };
};

export const AppointmentAttachmentBulkCreateDocument = gql`
  mutation AppointmentAttachmentBulkCreate($files: [FileAttachmentFileData]!, $parentId: ID!) {
    appointmentAttachmentBulkCreate(files: $files, parentId: $parentId) {
      fileAttachment {
        id
      }
    }
  }
`;
export type AppointmentAttachmentBulkCreateMutationFn = Apollo.MutationFunction<
  AppointmentAttachmentBulkCreateMutation,
  AppointmentAttachmentBulkCreateMutationVariables
>;

/**
 * __useAppointmentAttachmentBulkCreateMutation__
 *
 * To run a mutation, you first call `useAppointmentAttachmentBulkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentAttachmentBulkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentAttachmentBulkCreateMutation, { data, loading, error }] = useAppointmentAttachmentBulkCreateMutation({
 *   variables: {
 *      files: // value for 'files'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useAppointmentAttachmentBulkCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppointmentAttachmentBulkCreateMutation,
    AppointmentAttachmentBulkCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AppointmentAttachmentBulkCreateMutation,
    AppointmentAttachmentBulkCreateMutationVariables
  >(AppointmentAttachmentBulkCreateDocument, options);
}
export type AppointmentAttachmentBulkCreateMutationHookResult = ReturnType<
  typeof useAppointmentAttachmentBulkCreateMutation
>;
export type AppointmentAttachmentBulkCreateMutationResult =
  Apollo.MutationResult<AppointmentAttachmentBulkCreateMutation>;
export type AppointmentAttachmentBulkCreateMutationOptions = Apollo.BaseMutationOptions<
  AppointmentAttachmentBulkCreateMutation,
  AppointmentAttachmentBulkCreateMutationVariables
>;
