import { GraphQLPageInfo, GraphQLPaginatedResponse } from '../graphQL';

export interface Professor {
  id: string; // relay id
  professorId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  emailAddress: string;
  phoneNumber?: string;
  officeLocation?: string;
}

export enum ProfessorSortEnum {
  /* eslint-disable camelcase */
  ac2_professor_id_asc = 'ac2_professor_id_asc',
  ac2_professor_id_desc = 'ac2_professor_id_desc',
  customer_key_asc = 'customer_key_asc',
  customer_key_desc = 'customer_key_desc',
  email_address_asc = 'email_address_asc',
  email_address_desc = 'email_address_desc',
  first_name_asc = 'first_name_asc',
  first_name_desc = 'first_name_desc',
  last_name_asc = 'last_name_asc',
  last_name_desc = 'last_name_desc',
  office_location_asc = 'office_location_asc',
  office_location_desc = 'office_location_desc',
  phone_number_asc = 'phone_number_asc',
  phone_number_desc = 'phone_number_desc',
  created_date_asc = 'created_date_asc',
  created_date_desc = 'created_date_desc',
  updated_date_asc = 'updated_date_asc',
  updated_date_desc = 'updated_date_desc',
  created_by_asc = 'created_by_asc',
  created_by_desc = 'created_by_desc',
  updated_by_asc = 'updated_by_asc',
  updated_by_desc = 'updated_by_desc',
  /* eslint-enable camelcase */
}

export interface ProfessorPaginatedRawData {
  professors: GraphQLPaginatedResponse<Professor>;
}

export interface ProfessorPaginatedData {
  professors: Professor[];
  pageInfo: GraphQLPageInfo;
  totalCount: number;
}

export interface ProfessorQueryVariables {
  professorId?: string[] | number[];
  isActive?: boolean;
  sort?: ProfessorSortEnum[];
}

export interface ProfessorRawQueryVariables {
  professorId?: string;
  isActive?: string;
  sort?: ProfessorSortEnum[];
}

export interface ProfessorCreateInput {
  input: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    officeLocation?: string;
    phoneNumber?: string;
  };
}

export interface ProfessorCreateData {
  professorCreate: {
    aC2Professor: {
      id: string;
    };
    userErrors: { field: string[]; message: string }[];
  };
}

export interface ProfessorUpdateInput {
  id: string;
  input: {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    officeLocation?: string;
    phoneNumber?: string;
  };
}

export interface ProfessorUpdateData {
  professorUpdate: {
    aC2Professor: {
      id: string;
    };
    userErrors: { field: string[]; message: string }[];
  };
}
