import _ from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import { TWDataTable } from '@tw/components';
import { usePrevious } from '@tw/hooks';
import { useTranslator } from '@tw/i18n';

import {
  KeyedObject,
  TWDataTableInfiniteScrollProps,
} from './TWDataTableInfiniteScroll.definitions';
import { EmptyTableText, TableFooterWrapper } from './TWDataTableInfiniteScroll.styles';

const TWDataTableInfiniteScroll = (
  {
    children,
    filters,
    onRowClick,
    onPageChange,
    queryFn,
    emptyText,
    showAmountRemaining,
    infiniteScrollProps,
    forceLoadingState,
    ...restOfProps
  }: TWDataTableInfiniteScrollProps,
  ref: React.Ref<unknown>,
) => {
  const translator = useTranslator();

  const [itemList, setItemList] = useState<KeyedObject[]>([]);

  const { data, totalCount, refetch, pageInfo, loading } = queryFn(filters);
  const hasNextPage = pageInfo?.hasNextPage;
  const previousData = usePrevious(data);
  const previousCount = usePrevious(totalCount);

  useImperativeHandle(ref, () => ({
    refetch,
  }));

  useEffect(() => {
    if (!loading) {
      // On filter change (any non-pagination filter, that is), the withPagination HOC sets "after" to null, which we can
      // use to clear the item list and make sure the results we are using are only for the current set of filters.
      const toPrepend = filters.after ? itemList : [];
      setItemList(_.uniqBy([...toPrepend, ...data], 'key'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, totalCount]);

  useEffect(() => {
    if (
      previousCount === totalCount &&
      !_.isEmpty(previousData) &&
      !_.isEqual(previousData, data)
    ) {
      // If the data itself changed, it likely means we updated something. We must find what changed, and update it...
      // We can't just spread it in, because it can break the sort order.
      const safeItemList = [...itemList];
      _.forEach(data, (value: { key: string }) => {
        const replaceIdx = _.findIndex(safeItemList, { key: value.key });

        if (replaceIdx !== -1) {
          safeItemList[replaceIdx] = value;
        } else {
          safeItemList.push(value);
        }
      });
      setItemList(safeItemList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleLoadMore = () => {
    if (hasNextPage && pageInfo?.endCursor) {
      onPageChange(pageInfo?.endCursor);
    }
  };

  const itemsRemaining = (totalCount && totalCount - _.size(itemList)) ?? 0;

  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    loading,
    hasNextPage: hasNextPage ?? false,
    onLoadMore: handleLoadMore,
    ...infiniteScrollProps,
  });

  return (
    <div ref={infiniteRef}>
      <TWDataTable
        dataSource={itemList}
        locale={{
          emptyText: loading ? <></> : <EmptyTableText>{emptyText}</EmptyTableText>,
        }}
        onRowClick={onRowClick}
        loading={forceLoadingState || loading}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restOfProps}
      >
        {children}
      </TWDataTable>
      {showAmountRemaining && hasNextPage && (
        <TableFooterWrapper>
          {translator.t('plusMore', { number: itemsRemaining })}
        </TableFooterWrapper>
      )}
    </div>
  );
};

export default forwardRef(TWDataTableInfiniteScroll);
