/* eslint-disable jsx-a11y/label-has-for */
import { CSSProperties } from 'react';

import { noopFn } from '@tw/constants';

import type { TWInputCheckboxColorProps } from './TWInputCheckboxColor.definitions';

/*
  This file is currently a component stub. It is only used in the web CheckboxFilter module currently, and will need to
  be fleshed out before being implemented app-wide (see ticket TW-13605)
*/

const createStyle = (color: CSSProperties['color']): Record<string, CSSProperties> => ({
  div: {
    backgroundColor: 'transparent',
    position: 'relative',
    marginLeft: '3px', // to prevent highlight from cutting off
    width: '16px',
    minWidth: '16px',
    height: '16px',
    borderRadius: '3px',
    border: '2px solid',
    borderColor: color,
  },

  label: {
    fontWeight: 'normal',
    cursor: 'pointer',
    marginLeft: '8px',
    marginBottom: 'inherit',
    // The below styling is specifically for long labels
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  svg: {
    position: 'absolute',
  },

  wrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: '100%',
  },
});

const TWInputCheckboxColor = ({
  id,
  labelPassed = true,
  onClick = noopFn,
  color = '#6C7182',
  isChecked,
  label = '',
  testID = '',
}: TWInputCheckboxColorProps) => {
  const styles = createStyle(color);
  styles.div.backgroundColor = isChecked ? color : 'transparent';

  const passLabel = () => {
    if (labelPassed) {
      return (
        <label
          className="custom-checkbox-label"
          htmlFor={id}
          style={styles.label}
          title={typeof label === 'string' ? label : ''} // Provides hover for all labels since there is a fixed width and could cut off long labels
        >
          {label}
        </label>
      );
    }
    return <div className="custom-checkbox-label" style={styles.label} />;
  };

  return (
    <div
      role="checkbox"
      aria-checked={isChecked}
      id={id}
      tabIndex={0}
      data-testid={`${testID}${isChecked ? ':Checked' : ':Unchecked'}`}
      className="custom-checkbox-wrapper"
      onClick={onClick}
      onKeyPress={onClick}
      style={styles.wrapper}
    >
      <div className="custom-checkbox" style={styles.div}>
        {isChecked && (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="custom-checkbox-icon"
            style={styles.svg}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5665 1.17596C11.0216 1.48885 11.1369 2.11142 10.824
                2.56653L5.32404 10.5665C5.14672 10.8245 4.85886 10.9845
                4.5462 10.9989C4.23354 11.0134 3.93215 10.8806 3.73177
                10.6402L1.23177 7.64019C0.878209 7.21591 0.935533 6.58534
                1.35981 6.23178C1.78409 5.87822 2.41465 5.93554 2.76822
                6.35982L4.42349 8.34615L9.17595 1.43347C9.48884 0.978367
                10.1114 0.863075 10.5665 1.17596Z"
              fill="#fff"
            />
          </svg>
        )}
      </div>
      {passLabel()}
    </div>
  );
};

export default TWInputCheckboxColor;
