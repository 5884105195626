import { ComponentType } from 'react';

export type SizeScalingType = 'small' | 'default' | 'large' | 'subfolder';

export enum AvatarSizeScalingEnum {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
}

export enum ListItemSizeScalingEnum {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
}

export enum ChipSizeScalingEnum {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
  XLARGE = 'xlarge',
}
export interface CustomChipSizeType {
  iconSize: number;
  avatarFontSize: number;
  TextComponent: ComponentType;
  SubTextComponent: ComponentType;
  textPaddingLeft: number;
}

export type ChipSizeScalingType = SizeScalingType | 'xlarge';
