const remoteImageToDataURL = (url: string, callback: (arg0: string | ArrayBuffer) => void) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

export default {
  remoteImageToDataURL,
};
