import { ApolloClient, ApolloLink, Context, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import localStorage from '@tw/services/localStorage';
import session from '@tw/services/session';
import { backendUtils } from '@tw/util';

import { resolvers, typeDefs } from './clientMocks';

const ignoredMessages = ['401 Unauthorized: Signature has expired'];

const GRAPHQL_ENDPOINT = `${backendUtils.API_BASE_URL}/api/v1/graphql`;

export const configureApollo = () => {
  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (!graphQLErrors.some(({ message }) => ignoredMessages.includes(message))) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          datadogRum.addError(new Error(`GraphQL operation ${operation.operationName} failed`), {
            query: operation.query.loc?.source.body,
            variables: operation.variables,
            message,
            locations,
            path,
          });
        });
      } else {
        session.logout();
      }

      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const requestLink = new ApolloLink((operation, forward) => {
    const debugLabel = operation.operationName ? `?operation=${operation.operationName}` : '';

    operation.setContext(({ headers: customHeaders }: Context) => {
      // Add necessary request headers
      const headers: Record<string, string> = {
        'User-Agent': 'TW-Web',
        ...customHeaders,
      };

      if (session.isValid()) {
        const currentUser = localStorage.getCurrentUser();
        const accessToken = localStorage.getAccessToken();

        headers.Authorization = `Bearer ${accessToken}`;

        if (currentUser?.personId && currentUser?.teamId) {
          headers['X-TW-PersonId'] = currentUser.personId.toString();
          headers['X-TW-TeamId'] =
            customHeaders && 'X-TW-TeamId' in customHeaders
              ? customHeaders['X-TW-TeamId'].toString()
              : currentUser.teamId.toString();
        }
      }

      return {
        uri: `${GRAPHQL_ENDPOINT}${debugLabel}`,
        credentials: 'include',
        headers,
      };
    });

    return forward(operation);
  });

  const reCaptchaLink = setContext((_operation, previousContext) => {
    const { headers, reCaptchaToken } = previousContext;
    if (!reCaptchaToken) {
      return previousContext;
    }
    return {
      ...previousContext,
      headers: {
        ...headers,
        'x-recaptcha-token': reCaptchaToken,
      },
    };
  });

  const uploadLink = createUploadLink({ print: (ast, originalPrint) => `${originalPrint(ast)}\n` });

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Viewer: {
          merge: true,
        },
      },
    }),
    defaultOptions: {
      query: { fetchPolicy: 'no-cache' },
      mutate: { fetchPolicy: 'no-cache' },
      watchQuery: { fetchPolicy: 'no-cache' },
    },
    link: ApolloLink.from([
      errorLink,
      requestLink,
      reCaptchaLink,
      uploadLink, // Terminating, needs to be last in array
    ]),
    resolvers,
    typeDefs,
  });
};
