import momentDurationFormatSetup from 'moment-duration-format';
import * as moment from 'moment-timezone';
import { fullCalendarViewNames } from '@tw/constants';
import { CalendarSelectableInterface } from '@tw/modules/Calendar/Calendar.definitions';

// Required for localization. Remove this and package once
// moment.locale() is set up at a global level
momentDurationFormatSetup(moment);

const convertTimezonedMomentToDate = (time: moment.Moment) => {
  const timeMoment = moment.default(time);
  return new Date(
    Date.UTC(
      timeMoment.year(),
      timeMoment.month(),
      timeMoment.date(),
      timeMoment.hour(),
      timeMoment.minute(),
      timeMoment.second(),
    ),
  );
};

const durationIsWithinRange = (
  rangeStart: string,
  rangeEnd: string,
  durationStart: string | Date,
  durationEnd: string | Date,
) =>
  moment.default(durationStart).isSameOrBefore(moment.default(rangeEnd)) &&
  moment.default(durationEnd).isSameOrAfter(moment.default(rangeStart));

// this expects view types set by fullCalendar
const getUpdatedDateMoment = (date: string, calendarViewType: string, diff: number) => {
  switch (calendarViewType) {
    case fullCalendarViewNames.day:
      return moment.default(date).add(diff, 'd');
    case fullCalendarViewNames.week:
      return moment.default(date).add(diff, 'w');
    case fullCalendarViewNames.month:
      return moment.default(date).add(diff, 'M');
    case fullCalendarViewNames.list:
      return moment.default(date).add(diff, 'w');
    default:
      return moment.default(date);
  }
};

// sort the available selections in alphabetical order by last name (break ties with first name)
export const sortUserList = (
  currentUserSelectionCode: string,
  selections: CalendarSelectableInterface[],
): CalendarSelectableInterface[] =>
  selections.sort((a, b) => {
    // Put the current user at the beginning of the list
    if (currentUserSelectionCode === a.selectionCode) {
      return -1;
    }
    if (currentUserSelectionCode === b.selectionCode) {
      return 1;
    }

    // Compare by sortString if it exists
    if (a.sortString && b.sortString) {
      return a.sortString.localeCompare(b.sortString, 'en', {
        sensitivity: 'base',
      });
    }

    // Compare by label if it exists
    if (a.label && b.label) {
      return a.label.localeCompare(b.label, 'en', {
        sensitivity: 'base',
      });
    }

    return 0;
  });

export default {
  convertTimezonedMomentToDate,
  durationIsWithinRange,
  getUpdatedDateMoment,
  sortUserList,
};
