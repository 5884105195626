import { noop } from 'lodash';
import { memo, useCallback, useEffect } from 'react';

import { KEY_CODES } from '@tw/constants';
import { useDoubleClick, useEventListener } from '@tw/hooks';

import { TWListItemAvatar } from '../../../../../presentational';
import { Container } from './TreeLeaf.styles';

interface TreeLeafProps {
  testID: string;
  label: string;
  onDoubleClick?: () => void;
  onClick?: () => void;
  avatarImage?: string;
  avatarText?: string;
  avatarIcon?: Node;
  isHighlighted?: boolean;
  isStaged?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
}

const TreeLeaf = ({
  onDoubleClick = noop,
  onClick = noop,
  isHighlighted = false,
  isStaged = false,
  isSelected = false,
  isDisabled = false,
  ...restOfProps
}: TreeLeafProps) => {
  const [setClickableElemRef, clickableElemRef] = useDoubleClick(
    onDoubleClick,
    onClick,
    isDisabled,
  );

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isHighlighted) {
        if (event.code === KEY_CODES.ENTER) {
          onDoubleClick();
        }
      }
    },
    [isHighlighted],
  );

  useEffect(() => {
    if (clickableElemRef) {
      clickableElemRef.focus();
    }
  }, [clickableElemRef]);

  useEventListener('keydown', handleOnKeyDown);

  return (
    <Container isDisabled={isDisabled} isStaged={isStaged}>
      <TWListItemAvatar
        selectable={!isDisabled}
        size="small"
        ref={setClickableElemRef}
        isHighlighted={isHighlighted}
        rightIcons={
          isSelected
            ? [
                {
                  type: 'material-done',
                },
              ]
            : []
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restOfProps}
      />
    </Container>
  );
};

TreeLeaf.defaultProps = {
  avatarImage: '',
  avatarText: '',
};

export default memo(
  TreeLeaf,
  (prevProps, nextProps) =>
    prevProps.isHighlighted === nextProps.isHighlighted &&
    prevProps.isStaged === nextProps.isStaged &&
    prevProps.isSelected === nextProps.isSelected,
);
