import styled from '@emotion/styled';

export const MenuWrapper = styled.div(({ theme }) => ({
  '.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected': {
    backgroundColor: theme.colors.menuItemHighlight,
  },
  // these next two lines ensure our side menus fit the height of the screen
  // (208px is the height of the navbar)
  minHeight: 'calc(100vh - 161px)',
  height: '100%',
}));

export const LinkText = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const IconWrapper = styled.div(({ theme }) => ({
  marginRight: theme.baseUnit * 0.5,
  fontSize: theme.fontUnit * 6,
  width: '1em',
  verticalAlign: 'middle',
  display: 'inline-flex',
}));
