/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulletinsSettingsDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type BulletinsSettingsDeleteMutation = {
  bulletinsSettingsDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export const BulletinsSettingsDeleteDocument = gql`
  mutation BulletinsSettingsDelete($id: ID!) {
    bulletinsSettingsDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type BulletinsSettingsDeleteMutationFn = Apollo.MutationFunction<
  BulletinsSettingsDeleteMutation,
  BulletinsSettingsDeleteMutationVariables
>;

/**
 * __useBulletinsSettingsDeleteMutation__
 *
 * To run a mutation, you first call `useBulletinsSettingsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulletinsSettingsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulletinsSettingsDeleteMutation, { data, loading, error }] = useBulletinsSettingsDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBulletinsSettingsDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulletinsSettingsDeleteMutation,
    BulletinsSettingsDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulletinsSettingsDeleteMutation,
    BulletinsSettingsDeleteMutationVariables
  >(BulletinsSettingsDeleteDocument, options);
}
export type BulletinsSettingsDeleteMutationHookResult = ReturnType<
  typeof useBulletinsSettingsDeleteMutation
>;
export type BulletinsSettingsDeleteMutationResult =
  Apollo.MutationResult<BulletinsSettingsDeleteMutation>;
export type BulletinsSettingsDeleteMutationOptions = Apollo.BaseMutationOptions<
  BulletinsSettingsDeleteMutation,
  BulletinsSettingsDeleteMutationVariables
>;
