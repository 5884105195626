import styled from '@emotion/styled';
import { TWFlexContainer } from '@tw/components/presentational';
import { PropsWithChildren } from 'react';

const TWDataTableFilterLabel = ({
  label,
  htmlFor,
  testId,
  children,
}: PropsWithChildren<{ label: string; htmlFor?: string; testId?: string }>) => (
  <TWFlexContainer data-testid={`${testId}:Label`}>
    <Label htmlFor={htmlFor}>{label}</Label>
    {children}
  </TWFlexContainer>
);

const Label = styled.label(({ theme }) => ({
  fontSize: 14,
  color: theme.colors.text,
  marginBottom: 4,
}));

export default TWDataTableFilterLabel;
