import styled from '@emotion/styled';
import { sharedSpacingStyles, SpacingComponentProps } from '../../../utils';
import TWErrorAlertDeprecated from '../../../presentational/TWErrorAlertDeprecated';
import TWFlexContainer from '../../../presentational/layout/TWFlexContainer';

const Container = styled.div<SpacingComponentProps>(({ theme }) => [
  sharedSpacingStyles,
  {
    height: '100%',
    maxHeight: 462,
    display: 'flex',
    columnGap: theme.baseUnit,
    alignItems: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: theme.colors.background,
    '> div:first-of-type, > div:last-of-type': {
      width: 373,
    },
  },
]);

const SearchAndTeamFilters = styled(TWFlexContainer)(({ theme }) => ({
  '&& .ant-input-affix-wrapper, &&& .ant-select-selector': {
    borderColor: `${theme.colors.border} !important`,
  },
  '&& .ant-input-affix-wrapper-focused': {
    boxShadow: '0 0 0 2px rgba(108, 113, 130, 0.2)', // Use default ant shadow to avoid red blur
  },
}));

const TreeContainer = styled.div(({ theme }) => ({
  minHeight: 326,
  position: 'relative',
  borderRadius: '3px',
  borderColor: theme.colors.border,
  backgroundColor: theme.colors.white,
  overflow: 'auto',
  border: `${theme.borderWidth.medium}px solid ${theme.colors.border}`,
  flex: 1,
  '.ant-empty': {
    margin: '100px 0px',
  },
}));

const SelectedContainer = styled.div<SpacingComponentProps>(({ theme }) => [
  sharedSpacingStyles,
  {
    border: `${theme.borderWidth.medium}px solid ${theme.colors.border}`,
    borderRadius: '3px',
    backgroundColor: theme.colors.white,
    overflow: 'auto',
  },
]);

const StyledTWErrorAlertDeprecated = styled(TWErrorAlertDeprecated)(({ theme }) => ({
  marginBottom: theme.baseUnit * 2,
}));

export {
  Container,
  TreeContainer,
  SelectedContainer,
  StyledTWErrorAlertDeprecated,
  SearchAndTeamFilters,
};
