import styled from '@emotion/styled';

export const TabWithLeftDivider = styled.div(({ theme }) => ({
  borderLeft: `${theme.borderWidth.small}px solid ${theme.colors.buttonBorder}`,
  padding: theme.baseUnit * 2,
}));

export const TabTitle = styled.div(({ theme }) => ({
  padding: theme.baseUnit * 2,
}));

export const TabsContainer = styled.div(({ theme }) => ({
  '.ant-tabs-bar': {
    borderBottom: `${theme.borderWidth.small}px solid ${theme.colors.buttonBorder}`,
    marginBottom: 0,
    padding: theme.baseUnit * 2,
    paddingBottom: 0,
  },
  '.ant-tabs-nav': {
    marginBottom: 0,
  },
  '.ant-tabs-ink-bar': {
    backgroundColor: theme.colors.focus,
  },
  '.ant-tabs-tab': {
    backgroundColor: 'transparent',
    color: theme.colors.secondary,
    cursor: 'pointer',
    marginRight: 0,
  },
  '.ant-tabs-tab-active': {
    backgroundColor: 'transparent',
    color: theme.colors.text,
    fontWeight: theme.fontWeight.semibold,
  },
  '.tab': {
    display: 'flex',
  },
  '.tab-content': {
    flex: 1,
  },
}));

export const TabContent = styled.div(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.colors.background,
  paddingTop: theme.baseUnit * 2,
}));
