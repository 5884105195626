/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTicketingBlockGuestQueryVariables = Types.Exact<{
  sortAttributes?: Types.InputMaybe<
    | Array<
        Types.InputMaybe<
          | Array<Types.InputMaybe<Types.Scalars['String']>>
          | Types.InputMaybe<Types.Scalars['String']>
        >
      >
    | Types.InputMaybe<
        Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>
      >
  >;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type FetchTicketingBlockGuestQuery = {
  ticketingBlockedGuests?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        orgId: number;
        id: string;
        createdDateTime?: string | null;
        notes?: string | null;
        blockedOn?: any | null;
        attributes?: Array<{
          attributeId?: string | null;
          attributeValue?: string | null;
        } | null> | null;
        blockedBy?: { personId: string; name?: string | null; createdDate?: any | null } | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchTicketingBlockGuestDocument = gql`
  query FetchTicketingBlockGuest(
    $sortAttributes: [[String]]
    $search: String
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    ticketingBlockedGuests(
      sortAttributes: $sortAttributes
      search: $search
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          orgId
          id
          attributes {
            attributeId
            attributeValue
          }
          createdDateTime
          notes
          blockedOn
          blockedBy {
            personId
            name
            createdDate
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTicketingBlockGuestQuery__
 *
 * To run a query within a React component, call `useFetchTicketingBlockGuestQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTicketingBlockGuestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTicketingBlockGuestQuery({
 *   variables: {
 *      sortAttributes: // value for 'sortAttributes'
 *      search: // value for 'search'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useFetchTicketingBlockGuestQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTicketingBlockGuestQuery,
    FetchTicketingBlockGuestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTicketingBlockGuestQuery, FetchTicketingBlockGuestQueryVariables>(
    FetchTicketingBlockGuestDocument,
    options,
  );
}
export function useFetchTicketingBlockGuestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTicketingBlockGuestQuery,
    FetchTicketingBlockGuestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTicketingBlockGuestQuery, FetchTicketingBlockGuestQueryVariables>(
    FetchTicketingBlockGuestDocument,
    options,
  );
}
export type FetchTicketingBlockGuestQueryHookResult = ReturnType<
  typeof useFetchTicketingBlockGuestQuery
>;
export type FetchTicketingBlockGuestLazyQueryHookResult = ReturnType<
  typeof useFetchTicketingBlockGuestLazyQuery
>;
export type FetchTicketingBlockGuestQueryResult = Apollo.QueryResult<
  FetchTicketingBlockGuestQuery,
  FetchTicketingBlockGuestQueryVariables
>;
