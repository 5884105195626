import { Row } from 'antd';
import _ from 'lodash';
import * as React from 'react';
import { TWAvatar } from '../../../presentational';
import {
  TWDrawerHeaderProfileAvatar,
  TWDrawerHeaderProfileContainer,
  TWDrawerHeaderProfileTitle,
} from './TWDrawerHeaderProfile.styles';

interface TWDrawerHeaderProfileProps {
  avatarSrc: string;
  fullName: string;
  title: string | JSX.Element;
  initials?: string;
}

const TWDrawerHeaderProfile: React.FC<TWDrawerHeaderProfileProps> = (props) => {
  const { fullName, title, initials, avatarSrc = null } = props;

  const fullNameParts = _.split(fullName, ' ');
  const { firstInitial, secondInitial } = {
    firstInitial: fullNameParts[0][0],
    secondInitial: fullNameParts[fullNameParts.length - 1][0],
  };

  return (
    <TWDrawerHeaderProfileContainer>
      <TWDrawerHeaderProfileAvatar justify="center" data-testid="TutorDetailDrawer:TutorPicture">
        <TWAvatar size={96} icon={initials || `${firstInitial}${secondInitial}`} src={avatarSrc} />
      </TWDrawerHeaderProfileAvatar>
      <Row justify="center" data-testid="DetailDrawer:FullName">
        <h4>{fullName}</h4>
      </Row>
      <Row justify="center">
        <TWDrawerHeaderProfileTitle>{title}</TWDrawerHeaderProfileTitle>
      </Row>
    </TWDrawerHeaderProfileContainer>
  );
};

export default TWDrawerHeaderProfile;
