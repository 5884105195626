import _ from 'lodash';

import { reduxConfigUtils } from '@tw/util';

import baseDefault from './baseDefault';
import i18nConfigStoreConfig from './i18nConfigStoreConfig';
import {
  APPLY_DEBUG_OVERRIDE,
  CLEAR_DEBUG_OVERRIDE,
  APPLY_DEVICE_CONFIG,
} from './i18nConfigActions';

// Note: Everything is flat here (instead of nesting/grouping the attributes and values
// separately) because that makes the store manipulations easier -- we'd need 3-4 spread
// operators for each one instead of 1-2.
const initialState = {
  // In priority order, from lowest to highest
  layerNames: i18nConfigStoreConfig.layerNames,

  // Available attributes:
  //    isEnabled     {bool}
  //    @TODO: For future growth, if/when we need it:
  //      * something to enable/disable debug logging for the layer,
  //      * tags to allow complex queries from outside (to answer questions like
  //        "did this value come from a layer tagged as 'override'?", for debugging only)
  attributes_baseDefault: { isEnabled: true },
  attributes_devicePreference: { isEnabled: false },
  attributes_debugOverride: { isEnabled: false },

  values_baseDefault: baseDefault,
  values_devicePreference: {},
  values_debugOverride: {},
};

reduxConfigUtils.warnIfInitialStateLooksIncomplete(initialState);

export default function deviceConfigReducer(state = initialState, action) {
  switch (action.type) {
    case APPLY_DEVICE_CONFIG: {
      const { i18nConfigValues } = action;

      // If we have the language as a supported language, we can set it to the active language, and move forward.
      if (_.has(i18nConfigStoreConfig.supportedLanguages, i18nConfigValues.deviceLanguage)) {
        i18nConfigValues.activeLanguage = i18nConfigValues.deviceLanguage;
      }

      return {
        ...state,
        attributes_devicePreference: {
          isEnabled: true,
        },
        values_devicePreference: reduxConfigUtils.mergeConfigLayerValues(
          state.values_devicePreference,
          i18nConfigValues,
        ),
      };
    }
    /**
     * This *merges in* a set of debugOverride values
     */
    case APPLY_DEBUG_OVERRIDE:
      return {
        ...state,
        attributes_debugOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_debugOverride,
          {
            isEnabled: true,
          },
        ),
        values_debugOverride: reduxConfigUtils.mergeConfigLayerValues(
          state.values_debugOverride,
          action.debugOverrideValues,
        ),
      };

    /**
     * This wipes (but does not disable) any debugOverride values
     */
    case CLEAR_DEBUG_OVERRIDE:
      return {
        ...state,
        attributes_debugOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_debugOverride,
          {
            isEnabled: false,
          },
        ),
        values_debugOverride: {},
      };

    default:
      return state;
  }
}
