import _ from 'lodash';
import {
  SessionRequestCreateValues,
  SessionRequestCreateRawValues,
  SessionRequestStatuses,
} from '@tw/types';

/* eslint-disable camelcase */
const convertDataForTutorSessionRequest = ({
  courseId,
  studentIds,
  orgId,
  termId,
  ...rest
}: SessionRequestCreateValues): SessionRequestCreateRawValues => ({
  course_id: courseId,
  org_id: orgId,
  status: SessionRequestStatuses.new,
  student_ids: studentIds,
  term_id: _.first(termId), // Handle case where for whatever reason multiple terms were selected.
  ...rest,
});
/* eslint-enable camelcase */

export default {
  convertDataForTutorSessionRequest,
};
