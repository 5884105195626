import styled from '@emotion/styled';
import TWCard from '../../TWCard';
import { TWTextDefault, TWTextSmall } from '../../typography';

export const FileStatusCard = styled(TWCard)(({ theme }) => ({
  bottom: theme.baseUnit * 6,
  boxShadow: theme.shadows.overlay,
  position: 'fixed',
  right: '48px',
  width: '375px',
  zIndex: 3,

  '.ant-card-head': {
    backgroundColor: theme.colors.iconBackground,
  },
  '.ant-card-head-title': {
    padding: `${theme.baseUnit}px 0`,
  },
  '.ant-card-body': {
    padding: 0,
  },
  'h5 > div': {
    float: 'right',
  },
}));

export const FileItem = styled.div(({ theme }) => ({
  display: 'flex',
  padding: '10px 10px',
  borderBottom: `${theme.borderWidth.small}px solid ${theme.colors.border}`,
  margin: '0px 10px',
}));

export const FileItemTitleText = styled(TWTextDefault)({
  width: '290px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const FileItemBody = styled.div(({ theme }) => ({
  display: 'flex',
  flex: '1 1 100%',
  flexDirection: 'column',
  paddingLeft: 3 * (theme.baseUnit / 2),
}));

export const FileItemContainer = styled.div(() => ({
  maxHeight: '240px',
  overflowY: 'auto',
}));

export const FileItemTimestamp = styled.div(({ theme }) => ({
  margin: 0,
  color: theme.colors.secondary,
  fontSize: theme.fontUnit * 3,
}));

export const FileItemBodyContent = styled(TWTextSmall)(({ theme }) => ({
  color: theme.colors.secondary,
  display: 'flex',
}));

export const StatusText = styled.span(() => ({
  flex: '1',
  margin: 'auto 0',
}));

export const DownloadLink = styled.span(() => ({
  width: '64px',
}));

export const ErrorContainer = styled.div(({ theme }) => ({
  color: theme.colors.negative,
}));

export const ErrorBold = styled.span(() => ({
  fontWeight: 'bold',
  marginRight: '5px',
}));
