import { useFlags, useLogout, useSwitchTeams, useViewer } from '@tw/hooks';
import { useHistory } from 'react-router-dom';
import session from '@tw/services/session';
import { datadogRum } from '@datadog/browser-rum';
import usePrimaryLinks from './usePrimaryLinks';
import useZendesk from './useZendesk';

export default function usePrimaryNav() {
  const { sharedNavAppSwitcher, salesforceLiveChatWidget } = useFlags();

  const history = useHistory();
  const viewer = useViewer();
  const links = usePrimaryLinks(viewer);
  const { openZendeskPanel } = useZendesk();

  const [logout] = useLogout();
  const [switchTeams] = useSwitchTeams();

  const apps = viewer?.personInfo?.webAccess?.reduce<Record<string, string>>((acc, app) => {
    const targetApp = viewer?.sharedNavbar?.targetUrls?.find((t) => t?.app === app);
    if (app && targetApp && targetApp.url) {
      acc[app] = targetApp.url;
    }
    return acc;
  }, {});

  const teams = viewer?.availableTeams.map((team) => ({
    id: team.id,
    name: team.label,
    avatar:
      viewer.sharedNavbar?.teamLogos?.find((t) => t.teamId === team.teamId)?.imageUrl ??
      team.logoUrl ??
      undefined,
    organization: viewer?.currentOrg?.label,
  }));

  const colors = {
    top: viewer?.sharedNavbar?.topColor ?? '#000000',
    bottom: viewer?.sharedNavbar?.bottomColor ?? '#31178C',
  };

  const onChatWithSupport = () => {
    if (salesforceLiveChatWidget) {
      const button = document.getElementsByClassName('helpButtonEnabled')?.[0] as HTMLButtonElement;
      button?.click();
    } else {
      openZendeskPanel();
    }
  };

  const onLogout = () => {
    logout();
  };

  const onAppChange = async (app: string, url: string) => {
    datadogRum.addAction('switch', { app, url });
    const token = await session.getAxleToken();
    window.location.href = `${url}?token=${token}`;
  };

  const onOrgChange = viewer?.hasMultipleAccounts
    ? () => {
        history.push('/chooseAccount');
      }
    : undefined;

  const onTeamChange = (selectedTeam: string) => {
    switchTeams({ variables: { team: selectedTeam } });
  };

  return {
    apps,
    hasAppSwitcher: sharedNavAppSwitcher,
    links,
    colors,
    team: {
      id: viewer?.currentTeam?.id,
      avatar:
        viewer?.sharedNavbar?.imageUrl ??
        viewer?.currentTeam?.logoUrl ??
        viewer?.currentOrg?.logoUrl ??
        'https://s3.amazonaws.com/assets-teamworksapp/image/logos/tw_logo_wg_43px.png',
      name: viewer?.currentTeam?.label,
      organization: viewer?.currentOrg?.label,
    },
    teams,
    user: {
      avatar: viewer?.person?.pictureUrl,
      name: viewer?.person.displayName,
      title: viewer?.currentMembership?.userTypes?.[0]?.name,
    },
    onAppChange,
    onChatWithSupport,
    onLogout,
    onOrgChange,
    onTeamChange,
  };
}
