import { PersonNode } from '../selectables';

export interface DiscretionaryPeriodISO {
  startDate: string;
  endDate: string;
}

export interface TmpTeamSegmentInput {
  tmpTeamId?: number;
  tmpSegmentType?: string;
  inSeason?: boolean;
  label?: string;
  status?: string;
  isFinalized?: boolean;
  weekStart?: number;
  segmentStartDate?: string;
  segmentEndDate?: string;
  firstCaraDate?: string;
  acclimatizationPeriodStartDate?: string;
  acclimatizationPeriodEndDate?: string;
  nineWeekPeriodStartDate?: string;
  nineWeekPeriodEndDate?: string;
  postSeasonStartDate?: string;
  postSeasonEndDate?: string;
  postSeasonDaysoffStartDate?: string;
  postSeasonDaysoffEndDate?: string;
  summerDiscretionaryPeriods?: DiscretionaryPeriodISO[];
  springDiscretionaryPeriods?: DiscretionaryPeriodISO[];
  orientationDate?: string;
  metaData: {
    daysOff: string;
    selectedNonCountableDays: string[];
  };
}

export interface TMPAthleteConflictInfo {
  personId: number;
  firstName: string;
  lastName: string;
  selectionCode: Partial<PersonNode>[];
  numAppts: number;
}

export interface TMPAdditionalConflictInfo {
  academicYearStartDate: string;
  athletes: TMPAthleteConflictInfo[];
}

export interface TMPWeeklyConflictInfo {
  tmpTeamSegmentId: number;
  weekLabel: string;
  startDate: string;
  endDate: string;
  athletes: TMPAthleteConflictInfo[];
}

interface TMPConflictInfo {
  additionalConflicts: TMPAdditionalConflictInfo[];
  weeklyConflicts: TMPWeeklyConflictInfo[];
}

export enum TMPSeasonType {
  inSeason = 'in-season',
  outOfSeason = 'out-of-season',
}

export enum TMPSegmentType {
  default = 'default',
  fbDefault = 'fb-default',
  fbSpring = 'fb-spring',
  fbSummer = 'fb-summer',
}

export interface TMPTeamSegmentConflict {
  tmpTeamSegmentId: string;
  tmpTeamId: number;
  id;
  status: string;
  seasonType: TMPSeasonType;
  label: string;
  orientationDate: string;
  tmpSegmentType: TMPSegmentType;
  weekStart: number;
  segmentOrder: number;
  isChampionship: boolean;
  firstCompetitionDate: string;
  firstCaraDate: string;
  postSeasonStartDate: string;
  postSeasonEndDate: string;
  postSeasonDaysoffEndDate: string;
  postSeasonDaysoffStartDate: string;
  nineWeekPeriodStartDate: string;
  nineWeekPeriodEndDate: string;
  segmentStartDate: string;
  segmentEndDate: string;
  acclimatizationPeriodStartDate: string;
  acclimatizationPeriodEndDate: string;
  summerDiscretionaryPeriods?: DiscretionaryPeriodISO[];
  springDiscretionaryPeriods?: DiscretionaryPeriodISO[];
  allTmpConflicts: TMPConflictInfo[];
  metaData?: {
    daysOff: string;
    selectedNonCountableDays: string[];
  };
}

export interface TMPTeamsSegment {
  tmpTeamSegmentId: string;
  id;
  tmpTeamId: number;
  status?: string | null;
  seasonType: string;
  tmpSegmentType?: string | null;
  label: string;
  orientationDate?: string | null;
  weekStart?: number | null;
  segmentOrder?: number | null;
  isChampionship?: boolean | null;
  firstCompetitionDate?: string | null;
  firstCaraDate?: string | null;
  dayCount?: number | null;
  postSeasonStartDate?: string | null;
  postSeasonEndDate?: string | null;
  postSeasonDaysoffEndDate?: string | null;
  postSeasonDaysoffStartDate?: string | null;
  segmentStartDate?: string | null;
  segmentEndDate?: string | null;
  acclimatizationPeriodStartDate?: string | null;
  acclimatizationPeriodEndDate?: string | null;
  summerDiscretionaryPeriods?: Array<{
    startDate?: string | null;
    endDate?: string | null;
  } | null> | null;
  springDiscretionaryPeriods?: Array<{
    startDate?: string | null;
    endDate?: string | null;
  } | null> | null;
}
