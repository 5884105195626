import * as React from 'react';
import _ from 'lodash';
import { Input, InputNumber } from 'antd';

import { useGrades } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { composeFormRules, stringUtils } from '@tw/util';
import { ProfessorSurveyReport, Student } from '@tw/types';

import {
  columnRenderFunctions,
  TWDataTableColumn,
  TWFormItem,
  TWInputSelect,
  TWInputSelectGrade,
} from '@tw/components';

import { studentFeedbackFormName } from '../StudentFeedbackDrawer/StudentFeedbackDrawer.definitions';

import { ReportTable, FormItemHiddenLabel } from './StudentReportTable.styles';

const { TextArea } = Input;

const generateFieldName = (fieldName: string, index: number) =>
  `professor_survey_reports[${index}].${fieldName}`;

interface StudentReportTableProps {
  containerId: string;
  professorSurveyReports: ProfessorSurveyReport[];
}

const StudentReportTable: React.FC<StudentReportTableProps> = (props) => {
  const { containerId, professorSurveyReports } = props;

  const { grades, loading } = useGrades();

  const getStudentFullName = (student: Student): string => {
    const hasNickname = student?.person.firstName !== student.preferredName;

    return hasNickname
      ? `${student?.person.firstName} (${student.preferredName}) ${student.lastName}`
      : student.fullName;
  };

  const renderStudentName = (student: Student) => {
    const fullName = getStudentFullName(student);

    return columnRenderFunctions.renderProfileItem(
      {
        fullName,
        // using firstName/lastName instead of student.fullName to guarantee we only generate initials from their legal name
        initials: stringUtils.getInitialsFromFullName(
          `${student?.person.firstName} ${student.lastName}`,
        ),
        picture: student.pictureDownloadUrl,
      },
      student?.person.teamLabels || '',
    );
  };

  const renderAbsence = (_value, report: ProfessorSurveyReport, index: number) => {
    const name = generateFieldName('absence_count', index);

    const student: Student = _.get(report, 'enrollment.studentTerm.student');
    const fullName = getStudentFullName(student);

    return (
      <FormItemHiddenLabel
        name={name}
        initialValue={report.absenceCount}
        key={`absence_count_${index}`}
        testID="StudentFeedbackForm:AbsenceCount"
        htmlFor={`${studentFeedbackFormName}_${name}`}
        label={`Absence Count for ${fullName}`}
      >
        <InputNumber min={0} />
      </FormItemHiddenLabel>
    );
  };

  const renderGrade = (_value, report: ProfessorSurveyReport, index: number) => {
    if (!loading) {
      const name = generateFieldName('grade', index);

      const initialGradeId = report.gradeId ? String(report.gradeId) : null;

      const initialGrade = initialGradeId
        ? _.find(grades, (grade) => grade.gradeId === initialGradeId)
        : null;

      const initialGradeValue = initialGrade?.label.toLocaleUpperCase();

      const student: Student = _.get(report, 'enrollment.studentTerm.student');
      const fullName = getStudentFullName(student);

      return (
        <FormItemHiddenLabel
          initialValue={initialGradeValue}
          name={name}
          htmlFor={`rc_select_${index * 2}`}
          label={`Grade for ${fullName}`}
        >
          <TWInputSelectGrade
            populateOnMount
            popupContainerId={containerId}
            placeholder=""
            testID="StudentFeedbackForm:Grade"
            listHeight={240}
          />
        </FormItemHiddenLabel>
      );
    }
    return null;
  };

  const renderIsAtRisk = (_value, report: ProfessorSurveyReport, index: number) => {
    const name = generateFieldName('is_at_risk', index);
    const initialRisk = _.isNil(report.isAtRisk) ? null : String(report.isAtRisk);

    const student: Student = _.get(report, 'enrollment.studentTerm.student');
    const fullName = getStudentFullName(student);

    const filterOption = (inputValue, option) =>
      option.title.toLowerCase().includes(inputValue.toLowerCase());

    return (
      <FormItemHiddenLabel
        initialValue={initialRisk}
        rules={composeFormRules('required')}
        name={name}
        htmlFor={`rc_select_${index * 2 + 1}`}
        label={`Is ${fullName} at risk of failing?`}
      >
        <TWInputSelect
          itemList={[
            {
              label: getTranslation('yes'),
              value: 'true',
              key: `is_at_risk_${index}`,
            },
            {
              label: getTranslation('no'),
              value: 'false',
              key: `no_risk_${index}`,
            },
          ]}
          popupContainerId={containerId}
          testID={`StudentFeedbackForm:AtRisk_${index}`}
          filterOption={filterOption}
        />
      </FormItemHiddenLabel>
    );
  };

  const renderComment = (_value, report: ProfessorSurveyReport, index: number) => {
    const name = generateFieldName('comment', index);

    const student: Student = _.get(report, 'enrollment.studentTerm.student');
    const fullName = getStudentFullName(student);

    return (
      <FormItemHiddenLabel
        name={name}
        initialValue={report.comment}
        htmlFor={`${studentFeedbackFormName}_${name}`}
        label={`Comment about ${fullName}`}
      >
        <TextArea />
      </FormItemHiddenLabel>
    );
  };

  const sortedReports = _.sortBy(professorSurveyReports, [
    'enrollment.studentTerm.student.lastName',
    'enrollment.studentTerm.student.person.firstName',
    'enrollment.studentTerm.student.preferredName',
    // include this fallback to ensure the order is deterministic even if two students have exactly the same name.
    'professorSurveyReportId',
  ]);

  return (
    <>
      {_.map(sortedReports, (report: ProfessorSurveyReport, index) => (
        <TWFormItem
          hidden
          key={`row_id_${index}`}
          name={generateFieldName('professor_survey_report_id', index)}
          initialValue={report?.professorSurveyReportId}
        />
      ))}

      <ReportTable
        dataSource={sortedReports}
        rowKey="professorSurveyReportId"
        testID="ProfessorSurvey:StudentFeedbackForm"
      >
        <TWDataTableColumn
          title={getTranslation('students', 2)}
          dataIndex={['enrollment', 'studentTerm', 'student']}
          render={renderStudentName}
        />
        <TWDataTableColumn
          title={getTranslation('instructorSurvey.studentFeedbackForm.unexcusedAbsence')}
          width="180px"
          render={renderAbsence}
        />
        <TWDataTableColumn
          title={getTranslation('coursesTab.surveys.currentGrade')}
          width="180px"
          render={renderGrade}
        />
        <TWDataTableColumn
          title={getTranslation('instructorSurvey.studentFeedbackForm.riskOfFail')}
          width="180px"
          render={renderIsAtRisk}
        />
        <TWDataTableColumn title={getTranslation('comments', 2)} render={renderComment} />
      </ReportTable>
    </>
  );
};

export default StudentReportTable;
