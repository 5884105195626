import { Form, Input } from 'antd';
import _ from 'lodash';
import { useEffect } from 'react';

import { useCourseCreate, useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { useCourseUpdate } from '@tw/modules/Academics2/hooks';
import { Subject } from '@tw/types';

import { TWButton, TWFlexContainer, TWForm, TWFormItem } from '../../../../presentational';
import { TWMessage } from '../../../TWMessage';
import { TWModal } from '../../../TWModal';
import { TWInputSelectSubject } from '../../TWInputSelectSubject';

import { CourseCreateFormValues, CourseCreateModalProps } from './CourseCreateModal.definitions';

export const CourseCreateModal = (props: CourseCreateModalProps) => {
  const { course, onSubmit, onClose, onUpdate, testID, ...rest } = props;

  const { personId, orgId } = useViewer();

  const [form] = Form.useForm<CourseCreateFormValues>();

  const handleComplete = (
    fetchedCourse: { id: string },
    userErrors: { field: string[]; message: string }[],
  ) => {
    if (userErrors.length > 0) {
      TWMessage.error(
        userErrors[0].message.includes(
          'violates unique constraint "course_uniq_subject_and_customer_key_per_org"',
        )
          ? getTranslation('coursesTab.courseCreationModal.alreadyExists')
          : userErrors[0].message,
      );
    } else {
      form.resetFields();
      onSubmit(fetchedCourse.id);
    }
  };

  const [createCourse, { loading: createLoading }] = useCourseCreate({
    onCompleted: ({ courseCreate: { course: fetchedCourse, userErrors } }) =>
      handleComplete(fetchedCourse, userErrors),
    onError: (error) => TWMessage.handleErrorWithContext({ error, context: { personId, orgId } }),
  });
  const [updateCourse, { loading: updateLoading }] = useCourseUpdate({
    onCompleted: ({ courseUpdate: { course: fetchedCourse, userErrors } }) =>
      handleComplete(fetchedCourse, userErrors),
    onError: (error) =>
      TWMessage.handleErrorWithContext({
        error,
        context: { personId, orgId, courseId: course?.id },
      }),
  });

  const loading = createLoading || updateLoading;

  useEffect(() => {
    const { id, subject, description, label } = course || {};
    form.setFieldsValue({ id, subjectId: subject?.id, name: description, code: label });
  }, [course, form]);

  const onOk = async () => {
    try {
      await form.validateFields();

      const { id = '', subjectId, code: label, name: description } = form.getFieldsValue();

      if (!course) {
        await createCourse({ variables: { input: { description, label, subjectId } } });
      } else {
        await updateCourse({ variables: { id, input: { description, label } } });
      }
    } catch (e) {
      const errorFields = _.get(e, 'errorFields');
      // if error fields is not empty, a validation error has occurred.
      // We don't need to handle this, but we should throw any other errors
      if (_.isEmpty(errorFields)) {
        throw e;
      }
    }
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <TWForm form={form} name="create-course-form" requiredMark>
      <TWModal
        confirmLoading={loading}
        onCancel={onCancel}
        onOk={onOk}
        title={
          course
            ? getTranslation('coursesTab.courseCreationModal.updateCourse')
            : getTranslation('coursesTab.courseCreationModal.newCourse')
        }
        footer={
          <TWFlexContainer row justify="space-between" align="center">
            <TWFlexContainer row twMarginLeft={1}>
              <TWButton
                accessibilityLabel={getTranslation('cancel')}
                onClick={onCancel}
                type="default"
                testID={`${testID}:Cancel`}
              >
                {getTranslation('cancel')}
              </TWButton>
              <TWButton
                accessibilityLabel={getTranslation('submit')}
                loading={loading}
                onClick={onOk}
                style={{ marginLeft: '8px' }}
                type="primary"
                testID={`${testID}:Submit`}
              >
                {getTranslation('submit')}
              </TWButton>
            </TWFlexContainer>
          </TWFlexContainer>
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {course && (
          <TWFormItem hidden name="id" required>
            <Input />
          </TWFormItem>
        )}
        <div id={`${testID}-subject-container`}>
          <TWFormItem
            name="subjectId"
            label={getTranslation('subjects', 1)}
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: getTranslation('coursesTab.courseCreationModal.subjectRequired'),
              },
            ]}
          >
            <TWInputSelectSubject
              disabled={!!course}
              keyExtractor={(subject: Subject) => subject.id}
              valueExtractor={(subject: Subject) => subject.id}
              onUpdate={onUpdate}
              populateOnMount
              popupContainerId={`${testID}-subject-container`}
              showAdd
              showEdit
              testID={`${testID}:Subject`}
            />
          </TWFormItem>
        </div>
        <TWFlexContainer row>
          <TWFormItem
            name="name"
            label={getTranslation('name')}
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: getTranslation('nameRequired'),
              },
            ]}
          >
            <Input placeholder={getTranslation('name')} data-testid={`${testID}:Name`} />
          </TWFormItem>
          <TWFormItem
            name="code"
            label={getTranslation('coursesTab.courseCreationModal.courseCode')}
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: getTranslation('coursesTab.courseCreationModal.codeRequired'),
              },
            ]}
            style={{ marginLeft: '16px' }}
          >
            <Input
              placeholder={getTranslation('coursesTab.courseCreationModal.courseCodeExample')}
              data-testid={`${testID}:Code`}
            />
          </TWFormItem>
        </TWFlexContainer>
      </TWModal>
    </TWForm>
  );
};
