/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TutoringAppointmentRangesQueryVariables = Types.Exact<{
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
  datetimeRangeList:
    | Array<Types.InputMaybe<Types.TutoringAppointmentRangeNode>>
    | Types.InputMaybe<Types.TutoringAppointmentRangeNode>;
}>;

export type TutoringAppointmentRangesQuery = {
  tutoringAppointmentRanges?: {
    edges: Array<{
      node?: {
        appointmentId: string;
        occurrences?: Array<{
          appointmentOccurrenceId?: number | null;
          appointmentId?: number | null;
          startDateTime?: any | null;
          endDateTime?: any | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const TutoringAppointmentRangesDocument = gql`
  query TutoringAppointmentRanges(
    $selections: [String]!
    $datetimeRangeList: [TutoringAppointmentRangeNode]!
  ) {
    tutoringAppointmentRanges(selections: $selections, datetimeRangeList: $datetimeRangeList) {
      edges {
        node {
          appointmentId
          occurrences {
            appointmentOccurrenceId
            appointmentId
            startDateTime
            endDateTime
          }
        }
      }
    }
  }
`;

/**
 * __useTutoringAppointmentRangesQuery__
 *
 * To run a query within a React component, call `useTutoringAppointmentRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTutoringAppointmentRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTutoringAppointmentRangesQuery({
 *   variables: {
 *      selections: // value for 'selections'
 *      datetimeRangeList: // value for 'datetimeRangeList'
 *   },
 * });
 */
export function useTutoringAppointmentRangesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TutoringAppointmentRangesQuery,
    TutoringAppointmentRangesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TutoringAppointmentRangesQuery, TutoringAppointmentRangesQueryVariables>(
    TutoringAppointmentRangesDocument,
    options,
  );
}
export function useTutoringAppointmentRangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TutoringAppointmentRangesQuery,
    TutoringAppointmentRangesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TutoringAppointmentRangesQuery,
    TutoringAppointmentRangesQueryVariables
  >(TutoringAppointmentRangesDocument, options);
}
export type TutoringAppointmentRangesQueryHookResult = ReturnType<
  typeof useTutoringAppointmentRangesQuery
>;
export type TutoringAppointmentRangesLazyQueryHookResult = ReturnType<
  typeof useTutoringAppointmentRangesLazyQuery
>;
export type TutoringAppointmentRangesQueryResult = Apollo.QueryResult<
  TutoringAppointmentRangesQuery,
  TutoringAppointmentRangesQueryVariables
>;
