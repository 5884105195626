import styled from '@emotion/styled';
import { useState } from 'react';
import * as React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import { NetworkStatus } from '@apollo/client';

import { CourseSection, Course, ProfessorSurveyAssignment } from '@tw/types';
import { useProfessorSurveyAssignmentsExternal } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';

import { TWDataTable, TWDataTableColumn, TWPill, TWLoadingMask, TWMessage } from '@tw/components';

import SurveyInfo from './parts/SurveySummary';
import AllSurveyCompleted from './parts/AllSurveyCompleted';
import { StudentFeedbackDrawer } from '../StudentFeedbackDrawer';

const AssignmentTable = styled(TWDataTable)(({ theme }) => ({
  '.ant-table-thead': {
    '.ant-table-cell': {
      color: theme.colors.text,
    },
  },
}));

interface StudentFeedbackListProps {
  containerId: string;
  uuid?: string;
}

export const StudentFeedbackList: React.FC<StudentFeedbackListProps> = ({ containerId, uuid }) => {
  const safeUuid = uuid || new URL(window.location.href).pathname.replace('/ext/', '');

  const [isProfessorReportVisible, setIsProfessorReportVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<ProfessorSurveyAssignment>(null);

  const { data, loading, refetch, networkStatus } = useProfessorSurveyAssignmentsExternal({
    variables: { uuid: safeUuid },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const { professorSurveyAssignments } = data || {};

  const surveyStatuses = {
    pending: {
      type: 'primary',
      label: getTranslation('instructorSurvey.studentFeedback.start'),
      pillType: 'default',
      pillLabel: getTranslation('instructorSurvey.studentFeedback.pending'),
    },
    in_progress: {
      type: 'default',
      label: getTranslation('continue'),
      pillType: 'success',
      pillLabel: getTranslation('inProgress'),
    },
    completed: {
      type: null,
      label: null,
      pillType: 'primary',
      pillLabel: getTranslation('completed'),
    },
  };

  const onClose = () => {
    setIsProfessorReportVisible(false);
    setSelectedFeedback(null);
  };

  const onSubmit = (status = 'completed') => {
    const action = status === 'completed' ? 'submitted' : 'saved';
    TWMessage.success(`Student Athlete feedback ${action} successfully.`);

    refetch();
    onClose();
  };

  const handleSurveyAction = (assignment: ProfessorSurveyAssignment) => {
    setIsProfessorReportVisible(true);
    setSelectedFeedback(assignment);
  };

  const renderCourse = (courseSection: CourseSection) =>
    `${courseSection?.course.label}-${courseSection?.shortCode}`;

  const renderDescription = (course: Course) => `${course?.description}`;

  const renderStudentCount = (assignment: ProfessorSurveyAssignment) =>
    assignment.professorSurveyReports.length;
  const renderSurveyStatus = (status: string) => {
    const pill = _.get(surveyStatuses, status);

    if (!pill) {
      return null;
    }

    return <TWPill type={pill.pillType} text={pill.pillLabel} />;
  };

  const renderAction = (assignment: ProfessorSurveyAssignment) => {
    const button = _.get(surveyStatuses, assignment.status);

    if (!button || !button.type) {
      return null;
    }

    const courseDescription = `${renderCourse(assignment.courseSection)} ${renderDescription(
      assignment.courseSection.course,
    )}`;

    const studentCount = assignment.courseSection.enrollments.totalCount;

    let accessibilityLabel = `report for ${studentCount} students in ${courseDescription}`;
    if (button.label === getTranslation('instructorSurvey.studentFeedback.start')) {
      accessibilityLabel = `Start ${accessibilityLabel}`;
    } else if (button.label === getTranslation('continue')) {
      accessibilityLabel = `Continue ${accessibilityLabel}`;
    }

    return (
      <Button
        type={button.type}
        size="small"
        onClick={() => handleSurveyAction(assignment)}
        aria-label={accessibilityLabel}
      >
        {button.label}
      </Button>
    );
  };

  if (loading || networkStatus === NetworkStatus.refetch) {
    return <TWLoadingMask />;
  }

  const hasSurvey = !_.isEmpty(professorSurveyAssignments);

  const surveysCompleted =
    !hasSurvey ||
    _.every(professorSurveyAssignments, (assignment) => assignment.status === 'completed');

  if (surveysCompleted) {
    return <AllSurveyCompleted />;
  }

  const courseSection = hasSurvey ? professorSurveyAssignments[0]?.courseSection : null;

  return (
    <div>
      <SurveyInfo description={courseSection?.term.label} professor={courseSection?.professor} />
      <AssignmentTable
        dataSource={professorSurveyAssignments}
        headerLabel={getTranslation('instructorSurvey.studentFeedback.title')}
        containerId={containerId}
        loading={loading}
        rowKey="professorSurveyAssignmentId"
      >
        <TWDataTableColumn
          title={getTranslation('courses', 2)}
          dataIndex="courseSection"
          render={renderCourse}
        />
        <TWDataTableColumn
          title={getTranslation('title')}
          dataIndex={['courseSection', 'course']}
          render={renderDescription}
        />
        <TWDataTableColumn title={getTranslation('students', 2)} render={renderStudentCount} />
        <TWDataTableColumn
          title={getTranslation('instructorSurvey.studentFeedback.status')}
          dataIndex="status"
          render={renderSurveyStatus}
        />
        <TWDataTableColumn
          title={getTranslation('actions', 2)}
          key="actions"
          render={renderAction}
        />
      </AssignmentTable>
      {selectedFeedback && (
        <StudentFeedbackDrawer
          containerId={containerId}
          uuid={safeUuid}
          open={isProfessorReportVisible}
          selectedSurvey={selectedFeedback}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
    </div>
  );
};
