/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchDashboardCalendarEventsQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type FetchDashboardCalendarEventsQuery = {
  allAppointments?: {
    edges: Array<{
      node?: {
        appointmentId: string;
        startTimeZone?: string | null;
        endTimeZone?: string | null;
        orgId: number;
        title?: string | null;
        location: string;
        notes?: string | null;
        isClass?: boolean | null;
        isBookedSlot?: string | null;
        isExternal?: boolean | null;
        isPrivate?: boolean | null;
        isMandatory?: boolean | null;
        isRecurring?: boolean | null;
        rrule?: string | null;
        createdDate?: any | null;
        complianceType?: string | null;
        countableMinutes?: number | null;
        meta?: any | null;
        firstOccurrenceStartDateTime?: any | null;
        firstOccurrenceEndDateTime?: any | null;
        allDay: boolean;
        userMetadata?: { canEdit?: boolean | null; canViewDetails?: boolean | null } | null;
        creator?: { preferredName?: string | null; lastName?: string | null } | null;
        appointmentType?: {
          appointmentTypeId?: number | null;
          color: string;
          label: string;
        } | null;
        occurrences?: Array<{
          appointmentId?: number | null;
          appointmentOccurrenceId?: number | null;
          endDateTime?: any | null;
          startDateTime?: any | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchDashboardCalendarEventsDocument = gql`
  query FetchDashboardCalendarEvents(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $selections: [String]!
  ) {
    allAppointments(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      selections: $selections
    ) {
      edges {
        node {
          appointmentId
          startTimeZone
          endTimeZone
          firstOccurrenceStartDateTime: startDate
          firstOccurrenceEndDateTime: endDate
          orgId
          title
          location
          notes
          allDay: isAllDay
          isClass
          isBookedSlot
          isExternal
          isPrivate
          isMandatory
          isRecurring
          rrule
          userMetadata {
            canEdit
            canViewDetails
          }
          creator {
            preferredName
            lastName
          }
          createdDate
          complianceType
          countableMinutes
          appointmentType {
            appointmentTypeId
            color
            label
          }
          occurrences {
            appointmentId
            appointmentOccurrenceId
            endDateTime
            startDateTime
          }
          meta
        }
      }
    }
  }
`;

/**
 * __useFetchDashboardCalendarEventsQuery__
 *
 * To run a query within a React component, call `useFetchDashboardCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardCalendarEventsQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useFetchDashboardCalendarEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchDashboardCalendarEventsQuery,
    FetchDashboardCalendarEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchDashboardCalendarEventsQuery,
    FetchDashboardCalendarEventsQueryVariables
  >(FetchDashboardCalendarEventsDocument, options);
}
export function useFetchDashboardCalendarEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDashboardCalendarEventsQuery,
    FetchDashboardCalendarEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchDashboardCalendarEventsQuery,
    FetchDashboardCalendarEventsQueryVariables
  >(FetchDashboardCalendarEventsDocument, options);
}
export type FetchDashboardCalendarEventsQueryHookResult = ReturnType<
  typeof useFetchDashboardCalendarEventsQuery
>;
export type FetchDashboardCalendarEventsLazyQueryHookResult = ReturnType<
  typeof useFetchDashboardCalendarEventsLazyQuery
>;
export type FetchDashboardCalendarEventsQueryResult = Apollo.QueryResult<
  FetchDashboardCalendarEventsQuery,
  FetchDashboardCalendarEventsQueryVariables
>;
