import * as React from 'react';
import { useTranslator } from '@tw/i18n';
import { TWFlexContainer, TWTextDefault, TWMessage, TWIcon } from '@tw/components';

interface CopiableSnippetProps {
  text: string;
  successText?: string;
}

const TWCopiableSnippet: React.FC<CopiableSnippetProps> = (props) => {
  const translator = useTranslator();
  const { text, successText } = props;

  return (
    <TWFlexContainer
      row
      onClick={() => {
        navigator.clipboard.writeText(text);
        TWMessage.success(successText || translator.t('copiedToClipboard'));
      }}
      style={{ cursor: 'pointer' }}
    >
      <TWTextDefault twColor="text" twMarginBottom={3} twMarginRight={1}>
        {text}
      </TWTextDefault>
      <TWIcon width={20} height={20} type="material-file_copy" color="secondary" />
    </TWFlexContainer>
  );
};

export default TWCopiableSnippet;
