import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import {
  SelectionRestrictingPermission,
  UserSelectionOptionsQuery,
  UserSelectionQuery,
  useUserSelectionOptionsQuery,
  useUserSelectionQuery,
} from '@tw/generated';
import { useEffect, useState } from 'react';

export interface UserSelectionQueryFilters {
  excludeAthletes?: boolean;
  excludeSelf?: boolean;
  includeContacts?: boolean;
  includeCustomGroups?: boolean;
  includeEveryoneGroups?: boolean;
  onlyPersonTypes?: string[];
  selectionRestrictingPermission?: SelectionRestrictingPermission;
  allowedPeople?: number[];
}
type UseUserSelectionOptions = UserSelectionQueryFilters & {
  teamFilter: string;
  userTypesOnly?: boolean;
  withTeamMembership: boolean;
};

interface UseUserSelectionProps {
  options: UseUserSelectionOptions;
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
  skip?: boolean;
  isNewUserSelect?: boolean;
}

/**
 * This hook contains logic with construction of all user selection
 * Because some organizations has multiple users, types and custom groups
 * which causes timeouts when we are fetching all data in one query
 * we decided to split query to multiple queries according to types
 */
export const useUserSelection = ({
  options,
  fetchPolicy,
  skip = false,
  isNewUserSelect = false,
}: UseUserSelectionProps) => {
  const { teamFilter, userTypesOnly = false, withTeamMembership, ...queryFilters } = options;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [data, setData] = useState<UserSelectionOptionsQuery | undefined>(undefined);
  const [newUserSelectData, setNewUserSelectData] = useState<UserSelectionQuery | undefined>(
    undefined,
  );

  const query = isNewUserSelect ? useUserSelectionQuery : useUserSelectionOptionsQuery;

  const generateVariables = (selectionOptions: string[]) => {
    const { includeCustomGroups, ...otherQueryFilters } = queryFilters;
    const filters = {
      ...otherQueryFilters,
      selectedTeam: teamFilter,
    };
    return {
      filters: {
        ...filters,
        selectionOptions,
      },
      withTeamMembership: !userTypesOnly && withTeamMembership,
      withPeople: !userTypesOnly,
    };
  };

  const {
    loading: loadingTypes,
    error: errorTypes,
    data: dataTypes,
  } = query({
    variables: generateVariables(['persontype', 'everyone', 'pos', 'year', 'status', 'contacts']),
    fetchPolicy: fetchPolicy ?? 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip,
  });

  const {
    loading: loadingCustom,
    error: errorCustom,
    data: dataCustom,
  } = query({
    variables: generateVariables(['custom']),
    fetchPolicy: fetchPolicy ?? 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip: skip || options?.includeCustomGroups === false,
  });

  useEffect(() => {
    setLoading(loadingTypes || loadingCustom);
  }, [loadingTypes, loadingCustom]);

  useEffect(() => {
    if (!loading && (errorTypes || errorCustom)) {
      setError(errorTypes ?? errorCustom);
    }
  }, [loading, errorTypes, errorCustom]);

  useEffect(() => {
    if (!loading && (dataTypes || dataCustom)) {
      if (isNewUserSelect) {
        setNewUserSelectData({
          groups: [...(dataTypes?.groups ?? []), ...(dataCustom?.groups ?? [])],
        });
      } else {
        setData({
          groups: [...(dataTypes?.groups ?? []), ...(dataCustom?.groups ?? [])],
        });
      }
    }
  }, [loading, dataTypes, dataCustom, isNewUserSelect]);

  if (isNewUserSelect) {
    return {
      loading,
      error,
      data: newUserSelectData,
    };
  }

  return {
    loading,
    error,
    data,
  };
};
