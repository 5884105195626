/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideosFolderDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  moveOrDeleteContents: Types.Scalars['String'];
  moveToFolderId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type VideosFolderDeleteMutation = {
  folderDelete: {
    folder?: { folderId: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export const VideosFolderDeleteDocument = gql`
  mutation VideosFolderDelete($id: ID!, $moveOrDeleteContents: String!, $moveToFolderId: Int) {
    folderDelete(
      id: $id
      input: { moveOrDeleteContents: $moveOrDeleteContents, moveToFolderId: $moveToFolderId }
    ) {
      folder {
        folderId
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type VideosFolderDeleteMutationFn = Apollo.MutationFunction<
  VideosFolderDeleteMutation,
  VideosFolderDeleteMutationVariables
>;

/**
 * __useVideosFolderDeleteMutation__
 *
 * To run a mutation, you first call `useVideosFolderDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideosFolderDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videosFolderDeleteMutation, { data, loading, error }] = useVideosFolderDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      moveOrDeleteContents: // value for 'moveOrDeleteContents'
 *      moveToFolderId: // value for 'moveToFolderId'
 *   },
 * });
 */
export function useVideosFolderDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideosFolderDeleteMutation,
    VideosFolderDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VideosFolderDeleteMutation, VideosFolderDeleteMutationVariables>(
    VideosFolderDeleteDocument,
    options,
  );
}
export type VideosFolderDeleteMutationHookResult = ReturnType<typeof useVideosFolderDeleteMutation>;
export type VideosFolderDeleteMutationResult = Apollo.MutationResult<VideosFolderDeleteMutation>;
export type VideosFolderDeleteMutationOptions = Apollo.BaseMutationOptions<
  VideosFolderDeleteMutation,
  VideosFolderDeleteMutationVariables
>;
