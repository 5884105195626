import {
  GraphQLPaginatedResponse,
  ProfessorSurveyReport,
  PaginatedProfessorSurveyReportData,
} from '@tw/types';
import { graphqlUtils } from '@tw/util';

export const transformRawPaginatedProfessorSurveyReportData = (
  rawData: GraphQLPaginatedResponse<ProfessorSurveyReport>,
): PaginatedProfessorSurveyReportData => {
  if (!rawData) {
    return undefined;
  }

  const { edges, ...rest } = rawData;
  return {
    professorSurveyReports: graphqlUtils.convertEdgesToArray<ProfessorSurveyReport>(edges),
    ...rest,
  };
};
