/* 
  Allows us to use our AntD confirmation modals from a global context, instead of needing to place the contextHolder somewhere in our module.
  useGlobalModal hook gives us confirmGlobalModal, successConfirmGlobalModal, and warningConfirmGlobalModal which we can call from other hooks and not have to worry about rendering to the DOM.
  Each returns a promise which resolves true or false, depending on the user's selection of 'ok' or 'cancel'.
*/
import { ModalStaticFunctions } from 'antd/lib/modal/confirm';
import useModal from 'antd/lib/modal/useModal';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { confirmModal, successConfirmModal, warningConfirmModal } from '@tw/components';
import { ModalFunc, ModalProps } from '../modalRenderFunctions/modalRenderFunctions';

type GlobalModalFunc = (config: ModalProps) => Promise<boolean>;
type GlobalModalContextType = {
  modal: Omit<ModalStaticFunctions, 'warn'>;
  confirmGlobalModal: GlobalModalFunc;
  successConfirmGlobalModal: GlobalModalFunc;
  warningConfirmGlobalModal: GlobalModalFunc;
};
const GlobalModalContext = createContext<GlobalModalContextType | null>(null);

// Decorate config to resolve promise
const decorateConfig = (
  config: ModalProps,
  resolve: (value: boolean | PromiseLike<boolean>) => void,
): ModalProps => ({
  ...config,
  okButtonProps: {
    ...config?.okButtonProps,
    onClick: (e) => {
      config?.okButtonProps?.onClick?.(e);
      resolve(true);
    },
  },
  onCancel: (arg) => {
    config?.onCancel?.(arg);
    resolve(false);
  },
});

export const GlobalModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [modal, contextHolder] = useModal();

  const context = useMemo<GlobalModalContextType>(() => {
    // Decorate modal render function to return a promise waiting for the user's choice
    const decorateModal = (confirmRenderFunction: ModalFunc, initialConfig: ModalProps) =>
      // Return promise that resolves with user's selection
      new Promise<boolean>((resolve) => {
        // TODO replace this destroyRef with a destroyOnClose prop with later versions of AntD. This sucks having to do this.
        let destroyRef: (() => void) | undefined;
        const { destroy } = confirmRenderFunction(
          modal,
          decorateConfig(
            {
              ...initialConfig,
              okButtonProps: {
                ...initialConfig?.okButtonProps,
                onClick: (e) => {
                  initialConfig?.okButtonProps?.onClick?.(e);
                  destroyRef?.();
                },
              },
              onCancel: (args) => {
                initialConfig?.onCancel?.(args);
                destroyRef?.();
              },
            },
            resolve,
          ),
        );
        destroyRef = destroy;
      });

    return {
      modal,
      confirmGlobalModal: (config) => decorateModal(confirmModal, config),
      successConfirmGlobalModal: (config) => decorateModal(successConfirmModal, config),
      warningConfirmGlobalModal: (config) => decorateModal(warningConfirmModal, config),
    };
  }, [modal]);

  return (
    <GlobalModalContext.Provider value={context}>
      {children}
      {contextHolder}
    </GlobalModalContext.Provider>
  );
};

export const useGlobalModal = () => {
  const ctx = useContext(GlobalModalContext);
  if (!ctx) {
    throw Error('useGlobalModal() must be used inside of a GlobalModalProvider');
  }
  return ctx;
};
