/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideoUpdateMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  input?: Types.InputMaybe<Types.VideoInput>;
}>;

export type VideoUpdateMutation = {
  videoUpdate: {
    video?: {
      videoId: string;
      wistiaVideoUuid?: string | null;
      title: string;
      description?: string | null;
      folderId?: number | null;
      isPinned: boolean;
      playCount?: number | null;
      downloadCount?: number | null;
      hasActiveShareLink?: boolean | null;
      wistiaThumbnailUrl?: string | null;
      wistiaOriginalFileUrl?: string | null;
      updatedDate?: any | null;
      canDownload?: boolean | null;
      creator?: {
        createdBy?: number | null;
        pictureDownloadUrl?: string | null;
        preferredName?: string | null;
        lastName?: string | null;
      } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const VideoUpdateDocument = gql`
  mutation VideoUpdate($id: ID, $input: VideoInput) {
    videoUpdate(id: $id, input: $input) {
      video {
        videoId
        wistiaVideoUuid
        title
        description
        folderId
        isPinned
        playCount
        downloadCount
        hasActiveShareLink
        wistiaThumbnailUrl
        wistiaOriginalFileUrl
        updatedDate
        canDownload
        creator {
          createdBy
          pictureDownloadUrl
          preferredName
          lastName
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type VideoUpdateMutationFn = Apollo.MutationFunction<
  VideoUpdateMutation,
  VideoUpdateMutationVariables
>;

/**
 * __useVideoUpdateMutation__
 *
 * To run a mutation, you first call `useVideoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoUpdateMutation, { data, loading, error }] = useVideoUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVideoUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<VideoUpdateMutation, VideoUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VideoUpdateMutation, VideoUpdateMutationVariables>(
    VideoUpdateDocument,
    options,
  );
}
export type VideoUpdateMutationHookResult = ReturnType<typeof useVideoUpdateMutation>;
export type VideoUpdateMutationResult = Apollo.MutationResult<VideoUpdateMutation>;
export type VideoUpdateMutationOptions = Apollo.BaseMutationOptions<
  VideoUpdateMutation,
  VideoUpdateMutationVariables
>;
