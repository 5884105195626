import { GraphQLPaginatedResponse, GraphQLPageInfo } from '../graphQL';
import { Course } from './course';
import { CourseSectionSchedule } from './courseSectionSchedule';
import { Enrollment } from './enrollment';
import { Professor } from './professor';
import { Term } from './term';

export interface CourseSection {
  id: string; // relay global id
  courseSectionId: string;
  courseSectionSchedules: CourseSectionSchedule[];
  course: Course;
  courseId: number;
  endDate: string;
  enrollments: {
    edges: Enrollment[];
    pageInfo: GraphQLPageInfo;
    totalCount: number;
  };
  professor: Professor;
  shortCode: string;
  startDate: string;
  term: Term;
}

export interface CourseSectionReportFilters {
  courseId?: number[];
  courseSectionSchedulesDay?: string[];
  searchCourseSections?: string;
  studentIds?: number[];
  teamIds?: number[];
  termId?: number[];
}

export interface CourseSectionRawReportFilters {
  courseIds?: string;
  courseSectionSchedulesDay?: string;
  studentIds: string; // stringified number[]
  termIds?: string;
  teamIds?: string;
}

export interface RawCourseSection
  extends Omit<CourseSection, 'courseSectionSchedules' | 'enrollments'> {
  courseSectionSchedules: GraphQLPaginatedResponse<CourseSectionSchedule>;
  enrollments: GraphQLPaginatedResponse<Enrollment>;
}

export interface CourseSectionsRawPaginatedData {
  courseSections: GraphQLPaginatedResponse<RawCourseSection>;
}

export interface CourseSectionsPaginatedData
  extends Omit<GraphQLPaginatedResponse<CourseSection>, 'edges'> {
  courseSections: CourseSection[];
}

export enum CourseSectionSortEnum {
  /* eslint-disable camelcase */
  ac2_course_section_id_asc = 'ac2_course_section_id_asc',
  ac2_course_section_id_desc = 'ac2_course_section_id_desc',
  customer_key_asc = 'customer_key_asc',
  customer_key_desc = 'customer_key_desc',
  course_id_asc = 'course_id_asc',
  course_id_desc = 'course_id_desc',
  is_deleted_asc = 'is_deleted_asc',
  is_deleted_desc = 'is_deleted_desc',
  is_online_asc = 'is_online_asc',
  is_online_desc = 'is_online_desc',
  professor_id_asc = 'professor_id_asc',
  professor_id_desc = 'professor_id_desc',
  short_code_asc = 'short_code_asc',
  short_code_desc = 'short_code_desc',
  term_id_asc = 'term_id_asc',
  term_id_desc = 'term_id_desc',
  start_date_asc = 'start_date_asc',
  start_date_desc = 'start_date_desc',
  end_date_asc = 'end_date_asc',
  end_date_desc = 'end_date_desc',
  org_id_asc = 'org_id_asc',
  org_id_desc = 'org_id_desc',
  created_date_asc = 'created_date_asc ',
  created_date_desc = 'created_date_desc',
  updated_date_asc = 'updated_date_asc',
  updated_date_desc = 'updated_date_desc',
  created_by_asc = 'created_by_asc',
  created_by_desc = 'created_by_desc',
  updated_by_asc = 'updated_by_asc',
  updated_by_desc = 'updated_by_desc',
  checksum_asc = 'checksum_asc',
  checksum_desc = 'checksum_desc',
  is_primary_asc = 'is_primary_asc',
  is_primary_desc = 'is_primary_desc',
  bulk_load_src_asc = 'bulk_load_src_asc',
  bulk_load_src_desc = 'bulk_load_src_desc',
  /* eslint-enable camelcase */
}

export interface CourseSectionQueryFilters {
  after?: string;
  before?: string;
  enableNameSort?: boolean;
  endDate?: string;
  first?: number;
  last?: number;
  courseId?: number[];
  courseSectionId?: number[];
  courseSectionSchedulesDay?: string[];
  courseSubjectId?: number[];
  searchCourseSections?: string;
  startDate?: string;
  studentIds?: number[];
  teamIds?: number[];
  termId?: number[];
  sort?: CourseSectionSortEnum[];
}

export interface CourseSectionRawQueryFilters
  extends Omit<
    CourseSectionQueryFilters,
    'courseId' | 'courseSectionId' | 'courseSectionSchedulesDay' | 'courseSubjectId' | 'termId'
  > {
  courseId?: string | number;
  courseSectionId?: string | number;
  courseSectionSchedulesDay?: string;
  courseSubjectId?: string | number;
  termId?: string | number;
}

export interface CourseSectionScheduleCreateSubInput {
  day: string;
  startTime?: string;
  endTime?: string;
  location?: string;
}

export interface EnrollmentCreateSubInput {
  creditsAttempted?: number;
  studentId: string;
}

export interface CourseSectionCreateInput {
  input: {
    courseId: string;
    isOnline?: boolean;
    professorId?: string;
    shortCode: string;
    termId: string;
    startDate: string;
    endDate: string;
    courseSectionSchedules: CourseSectionScheduleCreateSubInput[];
    enrollments?: EnrollmentCreateSubInput[];
  };
}

export interface CourseSectionCreateData {
  courseSectionCreate: {
    courseSection: {
      id: string;
    };
    userErrors: { field: string[]; message: string }[];
  };
}

export interface CourseSectionUpdateInput {
  id: string;
  input: {
    isOnline?: boolean;
    professorId?: string;
    termId?: string;
    startDate?: string;
    endDate?: string;
    courseSectionSchedules?: CourseSectionScheduleCreateSubInput[];
    addEnrollments?: string[];
    deleteEnrollments?: string[];
  };
}

export interface CourseSectionUpdateData {
  courseSectionUpdate: {
    courseSection: {
      id: string;
    };
    userErrors: { field: string[]; message: string }[];
  };
}
