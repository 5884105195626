import { TWAlertDeprecated, TWFlexContainer } from '@tw/components';
import styled from '@emotion/styled';

export const DocusignContainer = styled.div(() => ({
  position: 'relative',
}));

export const DocusignPlaceHolderContainer = styled.div(({ theme }) => ({
  position: 'relative',
  background: theme.colors.disabledBackground,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'top',
  alignItems: 'center',
  padding: '16px 16px 0 16px',
}));

export const DocusignPlaceHolder = styled.div<{ previewTemplate?: boolean }>(
  ({ theme, previewTemplate }) => ({
    position: 'relative',
    background: theme.colors.white,
    width: '100%',
    maxWidth: '700px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'top',
    padding: '48px 35px 35px 35px',
    marginTop: previewTemplate ? '' : '40px',
    borderRadius: previewTemplate ? '8px 8px 8px 8px' : '8px 8px 0 0',
    border: '1px solid rgba(66, 81, 130, 0.35)',
  }),
);

export const DocusignPlaceHolderBlockLarge = styled.div(({ theme }) => ({
  width: '100%',
  height: '67px',
  background: theme.colors.disabledBackground,
  borderRadius: '6px',
  margin: '12px 0',
}));

export const DocusignPlaceHolderBlockSmall = styled.div(({ theme }) => ({
  width: '100%',
  height: '30px',
  background: theme.colors.disabledBackground,
  borderRadius: '6px',
  margin: '12px 0',
}));

export const DocusignIframe = styled.iframe(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100vh',
}));

export const InfoAlert = styled(TWAlertDeprecated)(({ theme }) => ({
  backgroundColor: theme.colors.infoBackground,
  borderColor: theme.colors.infoBorder,
  marginLeft: `${theme.baseUnit * 3}px`,
  marginBottom: `${theme.baseUnit * 3}px`,
  width: 'fit-content',
  margin: 'auto',
}));
export const DocusignInfoRow = styled.section(() => ({
  flex: 1,
}));

export const DocusignSpacingContainer = styled.div(() => ({
  padding: '24px',
  borderBottom: '1px solid gray',
}));

export const DocusignFormInstructionItem = styled.ol(() => ({
  'li ul': {
    paddingLeft: '0',
  },
  'li ul li': {
    listStyle: 'none',
  },
}));

export const DocusignFlexContainer = styled(TWFlexContainer)(() => ({
  minHeight: '90vh',
}));
