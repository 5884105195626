/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.AppointmentDeleteInput;
}>;

export type AppointmentDeleteMutation = {
  appointmentDelete: {
    appointment?: { appointmentId: string } | null;
    userErrors: Array<{ message: string; field: Array<string>; code?: string | null }>;
  };
};

export const AppointmentDeleteDocument = gql`
  mutation AppointmentDelete($id: ID!, $input: AppointmentDeleteInput!) {
    appointmentDelete(id: $id, input: $input) {
      appointment {
        appointmentId
      }
      userErrors {
        message
        field
        code
      }
    }
  }
`;
export type AppointmentDeleteMutationFn = Apollo.MutationFunction<
  AppointmentDeleteMutation,
  AppointmentDeleteMutationVariables
>;

/**
 * __useAppointmentDeleteMutation__
 *
 * To run a mutation, you first call `useAppointmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentDeleteMutation, { data, loading, error }] = useAppointmentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppointmentDeleteMutation,
    AppointmentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AppointmentDeleteMutation, AppointmentDeleteMutationVariables>(
    AppointmentDeleteDocument,
    options,
  );
}
export type AppointmentDeleteMutationHookResult = ReturnType<typeof useAppointmentDeleteMutation>;
export type AppointmentDeleteMutationResult = Apollo.MutationResult<AppointmentDeleteMutation>;
export type AppointmentDeleteMutationOptions = Apollo.BaseMutationOptions<
  AppointmentDeleteMutation,
  AppointmentDeleteMutationVariables
>;
