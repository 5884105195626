import { Form, Input } from 'antd';
import { useEffect } from 'react';

import { useSubjectCreate, useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { useSubjectUpdate } from '@tw/modules/Academics2/hooks';
import { Subject, SubjectCreateInput } from '@tw/types';

import { TWButton, TWFlexContainer, TWForm, TWFormItem } from '../../../../presentational';
import { TWMessage } from '../../../TWMessage';
import { TWModal, TWModalProps } from '../../../TWModal';

interface SubjectCreateModalProps extends Omit<TWModalProps, 'children'> {
  onSubmit: (id: string) => void;
  onClose: () => void;
  subject?: Subject;
}

interface SubjectCreateFormValues {
  id?: string;
  name: string;
  code: string;
}

const normalizeFormValues = (values: SubjectCreateFormValues): SubjectCreateInput => ({
  input: {
    description: values.name,
    shortCode: values.code,
  },
});

export const SubjectCreateModal = ({
  onSubmit,
  onClose,
  subject,
  testID,
  ...rest
}: SubjectCreateModalProps) => {
  const { personId, orgId } = useViewer();

  const [form] = Form.useForm<SubjectCreateFormValues>();

  const handleComplete = (
    fetchedSubject: { id: string },
    userErrors: {
      field: string[];
      message: string;
    }[],
  ) => {
    if (userErrors.length > 0) {
      TWMessage.error(
        userErrors[0].message.includes(
          'value violates unique constraint "uix_ac2_subject_org_id_customer_key"',
        )
          ? getTranslation('coursesTab.subjectCreationModal.duplicateCodeError')
          : userErrors[0].message,
      );
    } else {
      form.resetFields();
      onSubmit(fetchedSubject.id);
    }
  };

  const [createSubject, { loading: createLoading }] = useSubjectCreate({
    onCompleted: ({ subjectCreate: { subject: fetchedSubject, userErrors } }) =>
      handleComplete(fetchedSubject, userErrors),
    onError: (error) => TWMessage.handleErrorWithContext({ error, context: { personId, orgId } }),
  });
  const [updateSubject, { loading: updateLoading }] = useSubjectUpdate({
    onCompleted: ({ subjectUpdate: { subject: fetchedSubject, userErrors } }) =>
      handleComplete(fetchedSubject, userErrors),
    onError: (error) =>
      TWMessage.handleErrorWithContext({
        error,
        context: { personId, orgId, subjectId: subject?.id },
      }),
  });

  const loading = createLoading || updateLoading;

  useEffect(() => {
    const { label, description, id } = subject || {};
    form.setFieldsValue({ code: label, name: description, id });
  }, [subject, form]);

  const onOk = async () => {
    await form.validateFields();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...formValues }: SubjectCreateFormValues = form.getFieldsValue();

    if (!subject) {
      await createSubject({ variables: normalizeFormValues(formValues) });
    } else {
      await updateSubject({
        variables: {
          id: subject.id,
          ...normalizeFormValues(formValues),
        },
      });
    }
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <TWForm form={form} name="create-subject-form" requiredMark>
      <TWModal
        onCancel={onCancel}
        title={
          subject
            ? getTranslation('coursesTab.subjectCreationModal.updateSubject')
            : getTranslation('coursesTab.subjectCreationModal.newSubject')
        }
        footer={
          <TWFlexContainer row twMarginLeft={1} justify="flex-end">
            <TWButton
              accessibilityLabel={getTranslation('cancel')}
              onClick={onCancel}
              type="default"
            >
              {getTranslation('cancel')}
            </TWButton>
            <TWButton
              accessibilityLabel={getTranslation('submit')}
              loading={loading}
              onClick={onOk}
              style={{ marginLeft: '8px' }}
              type="primary"
            >
              {getTranslation('submit')}
            </TWButton>
          </TWFlexContainer>
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {subject && (
          <TWFormItem hidden name="id" required>
            <Input />
          </TWFormItem>
        )}
        <TWFlexContainer row>
          <TWFormItem
            name="name"
            label={getTranslation('name')}
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: getTranslation('nameRequired'),
              },
            ]}
          >
            <Input placeholder={getTranslation('name')} data-testid={`${testID}:Name`} />
          </TWFormItem>
          <TWFormItem
            name="code"
            label={getTranslation('code')}
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: getTranslation('coursesTab.subjectCreationModal.codeRequired'),
              },
            ]}
            style={{ marginLeft: '16px' }}
          >
            <Input placeholder={getTranslation('code')} data-testid={`${testID}:SubjectCode`} />
          </TWFormItem>
        </TWFlexContainer>
      </TWModal>
    </TWForm>
  );
};
