import styled from '@emotion/styled';
import { MouseEventHandler } from 'react';

import { sharedSpacingStyles, SpacingComponentProps } from '../../../utils';

interface ContainerProps {
  isHighlighted?: boolean;
  selectable?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const LeftContentContainer = styled.div({
  display: 'flex',
  minWidth: 0,
  flex: 1,
});

const RightContentContainer = styled.div({
  display: 'flex',
});

const Container = styled.div<SpacingComponentProps & ContainerProps>(
  ({ theme, isHighlighted, selectable }) => [
    sharedSpacingStyles,
    {
      display: 'flex',
      flex: 1,
      minWidth: 0,
      alignItems: 'center',
      justifyContent: 'space-between',
      userSelect: 'none',
      cursor: selectable ? 'pointer' : 'default',
      outline: isHighlighted ? `1px solid ${theme.colors.border}` : 'inherit',
      transition: theme.cssValues.transition,
      ':focus': {
        outline: 0,
        border: 'none',
        background: theme.colors.border,
      },
      ':hover': {
        backgroundColor: theme.colors.hoverBackground,
      },
    },
  ],
);

Container.defaultProps = {
  twPadding: [0.5, 1],
};

const IconRow = styled.div(({ theme }) => ({
  display: 'flex',
  '*:not(:last-child)': {
    marginRight: theme.baseUnit,
  },
  svg: {
    display: 'flex',
  },
}));

export { IconRow, Container, LeftContentContainer, RightContentContainer };
