import { KeyboardEvent, MouseEvent, ReactElement, ReactNode } from 'react';

import { TWDataTableProps } from '../TWDataTable/TWDataTable.definitions';

export interface SelectionQuery<T> {
  isWhiteListed: boolean;
  selectionKeys: (string | number)[];
  records: T[];
  totalCount: number;
}

export interface RollbackGenericSelectionQuery<T = any> {
  [x: string]: SelectionQuery<T>;
}

interface BaseBulkAction {
  onClick: (
    selectionQueryConfig: RollbackGenericSelectionQuery,
    event?: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
  ) => void;
}

interface BulkAction extends BaseBulkAction {
  iconName: string;
  text: string;
  loading?: boolean;
  disabled?: boolean;
}

interface CustomBulkAction extends BaseBulkAction {
  customControl: ReactElement;
  ignoreSelections?: boolean;
}

interface PaginatedFilters {
  first?: number | null;
  after?: string | null;
}

export interface TWDataTableRollbackProps<T extends object> extends TWDataTableProps<T> {
  containerId: string;
  filters: PaginatedFilters;
  loading?: boolean;
  hasNextPage?: boolean;
  headerLabel: string;
  nextCursor?: string;
  onFilterChange: (filterUpdate: PaginatedFilters) => void;
  onPageChange: (cursor: string | null | undefined) => void;
  totalCount?: number;
  testID: string;
  selectionPrimaryKey: keyof T;
  bulkActions?: (BulkAction | CustomBulkAction)[];
  bulkActionsPositionRight?: boolean;
  defaultSelections?: (string | number)[];
  resetSelectionsOnFilterChange?: boolean;
  renderHeaderExtra?: () => ReactNode;
  onSelectionChanged?: (selectionQuery: RollbackGenericSelectionQuery<T>) => void;
  rowsPerPageOptions?: number[];
  bulkActionsForCurrentPage?: boolean;
  clearSelection?: boolean;
  bulkActionModal?: boolean;
  bulkActionDropdown?: boolean;
  bulkActionDropdownTitle?: string;
}

export interface RollbackSelectionState<T> {
  included: boolean;
  keys: (string | number)[];
  records: T[];
}

export const PAGINATION_FILTERS = ['after', 'before', 'first', 'last'];
