const $twMessaging = {
  // Note that, for now, our bubbles and other peoples' bubbles have the same size and layout,
  // and differ only in color.
  bubbleBorderRadius: 8,
  bubbleMaxWidth: '70%',
  bubbleMinWidth: '10%',
  bubblePaddingHorizontal: 10,
  bubblePaddingVertical: 6,
  messageTextFontSize: 17,
  messageTextLineHeight: 21,
  // The gutters get applied around every message
  gutterHorizontal: 12,
  gutterVertical: 4,

  // Messaging gets its own unique background color
  screenBackground: '$colorHue.grayExtremelyLight',
  bubbleColorOutbound: '$colorHue.blueLight', // Do we have this tealish-blue color anywhere else?
  bubbleColorInbound: '$colorHue.grayPale',

  messageTextColorOutbound: '$colorHue.white',
  messageTextColorInbound: '$colorHue.grayDark',
  messageTextColorSystem: '$colorHue.grayBlue',

  // Note that we only show the sender info for the other person's messages.
  // Also, these font sizes are all over the place. :-( We should get Nick to reduce the
  // number of variations in the designs.
  senderAvatarMargin: '$twMessaging.gutterHorizontal * 0.5', // this goes between the bubble and the avatar
  senderNameColor: '$colorHue.grayBlue',
  senderNameFontSize: 13,
  senderNameLineHeight: 18,
  timestampColor: '$colorHue.graySilver',
  timestampFontSize: 12,
  timestampLineHeight: 20,
};

export { $twMessaging };
