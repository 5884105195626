/* 
  TWTextOverflowTooltip:

  - Performs the same function as CSS text-overflow: ellipsis; word-wrap: nowrap; overflow: hidden
  - But it also adds an antd Tooltip if the text needs truncated
  - It requires a font-size to do that calculation

*/

import { Tooltip } from 'antd';
import { memo, useRef } from 'react';

import { TWInvisibleLabel } from '@tw/components';
import { avgCharWidthToFontSize } from '@tw/constants';
import { useResizeObserver } from '@tw/hooks';

interface TWTextOverflowTooltipProps {
  className?: string;
  text: string;
  fontSize: number;
}

const TWTextOverflowTooltip = memo(({ className, text, fontSize }: TWTextOverflowTooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver(ref);

  const letterWidth = avgCharWidthToFontSize[fontSize.toString()] || avgCharWidthToFontSize.default;
  const dotsWidth = 3 * letterWidth;

  const visibleCharacterCount =
    letterWidth * text.length < width ? text.length : Math.floor((width - dotsWidth) / letterWidth);

  const isTruncated = visibleCharacterCount !== text.length;

  return (
    <div ref={ref} className={className}>
      {isTruncated ? (
        <Tooltip title={text} placement="top">
          <span>
            {text.substring(0, visibleCharacterCount)}
            <TWInvisibleLabel>
              {text.substring(text.length - (text.length - visibleCharacterCount))}
            </TWInvisibleLabel>
            ...
          </span>
        </Tooltip>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
});

export default TWTextOverflowTooltip;
