const $twMessaging = {
  // Messaging gets its own unique background color
  screenBackground: '$colorHue.grayExtremelyLight',
  bubbleColorOutbound: '$colorHue.blueLight', // Do we have this tealish-blue color anywhere else?
  bubbleColorInbound: '$colorHue.grayPale',

  messageTextColorOutbound: '$colorHue.white',
  messageTextColorInbound: '$colorHue.grayDark',
  messageTextColorSystem: '$colorHue.grayBlue',

  senderNameColor: '$colorHue.grayBlue',
  timestampColor: '$colorHue.graySilver',
};

export { $twMessaging };
