/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentSelectionsQueryVariables = Types.Exact<{
  appointmentIds:
    | Array<Types.InputMaybe<Types.Scalars['Int']>>
    | Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type AppointmentSelectionsQuery = {
  appointmentSelections?: {
    edges: Array<{
      node?: {
        appointmentId: string;
        title?: string | null;
        location: string;
        selections?: Array<{
          code?: string | null;
          label?: string | null;
          persons?: Array<{
            id: string;
            pictureUrl?: string | null;
            preferredName?: string | null;
            lastName?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const AppointmentSelectionsDocument = gql`
  query AppointmentSelections($appointmentIds: [Int]!) {
    appointmentSelections(appointmentIds: $appointmentIds) {
      edges {
        node {
          appointmentId
          title
          location
          selections {
            code
            label
            persons {
              id
              pictureUrl
              preferredName
              lastName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAppointmentSelectionsQuery__
 *
 * To run a query within a React component, call `useAppointmentSelectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentSelectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentSelectionsQuery({
 *   variables: {
 *      appointmentIds: // value for 'appointmentIds'
 *   },
 * });
 */
export function useAppointmentSelectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentSelectionsQuery,
    AppointmentSelectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentSelectionsQuery, AppointmentSelectionsQueryVariables>(
    AppointmentSelectionsDocument,
    options,
  );
}
export function useAppointmentSelectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentSelectionsQuery,
    AppointmentSelectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentSelectionsQuery, AppointmentSelectionsQueryVariables>(
    AppointmentSelectionsDocument,
    options,
  );
}
export type AppointmentSelectionsQueryHookResult = ReturnType<typeof useAppointmentSelectionsQuery>;
export type AppointmentSelectionsLazyQueryHookResult = ReturnType<
  typeof useAppointmentSelectionsLazyQuery
>;
export type AppointmentSelectionsQueryResult = Apollo.QueryResult<
  AppointmentSelectionsQuery,
  AppointmentSelectionsQueryVariables
>;
