import { ValidFormat } from '../RichTextEditor.definitions';
import { ToolbarIcon } from '../RichTextEditor.styles';

const iconList = {
  arrowDropDown: 'arrow_drop_down',
  backgroundColor: 'format_color_fill',
  bold: 'format_bold',
  color: 'format_color_text',
  italic: 'format_italic',
  strikethrough: 'format_strikethrough',
  underline: 'format_underlined',
  superscript: 'superscript',
  subscript: 'subscript',
  alignLeft: 'format_align_left',
  alignCenter: 'format_align_center',
  alignRight: 'format_align_right',
  orderedList: 'format_list_numbered',
  unorderedList: 'format_list_bulleted',
  link: 'insert_link',
  unlink: 'link_off',
  clear: 'format_clear',
};

type IconProps = {
  icon: ValidFormat | 'arrowDropDown' | 'unlink';
  title?: string;
};

const Icon = ({ icon, title }: IconProps) => (
  <ToolbarIcon aria-hidden="true" title={title} className="material-icons-outlined">
    {iconList[icon]}
  </ToolbarIcon>
);

export default Icon;
