import { GraphQLPageInfo, GraphQLPaginatedResponse } from '../graphQL';
import {
  PaginatedProfessorSurveyAssignmentData,
  ProfessorSurveyAssignmentStatus,
  RawProfessorSurveyAssignment,
} from './professorSurveyAssignment';

export interface ProfessorSurvey {
  id: string;
  professorSurveyId: string;
  cursor?: string;
  label: string;
  dueDateTime: string;
  isClosed: boolean;
  isExpired: boolean;
  countStudents: number;
  countCompletedAssignments: number;
  countNotCompletedAssignments: number;
  description: string;
  professorSurveyAssignments?: PaginatedProfessorSurveyAssignmentData;
  createdDate: string;
  createdDateTime: string;
}

export interface RawProfessorSurvey {
  id: string;
  professorSurveyId: string;
  label: string;
  dueDateTime: string;
  isClosed: string;
  isDeleted: string;
  isExpired: string;
  hasOnlyCompletedAssignments: string;
  countStudents: string;
  countCompletedAssignments: string;
  countNotCompletedAssignments: string;
  description: string;
  professorSurveyAssignments?: GraphQLPaginatedResponse<RawProfessorSurveyAssignment>;
  createdBy: number;
  createdDate: string;
  createdDateTime: string;
}

export interface ProfessorSurveyPaginatedRawData {
  professorSurveys: GraphQLPaginatedResponse<RawProfessorSurvey>;
}

export interface ProfessorSurveyPaginatedData {
  professorSurveys: ProfessorSurvey[];
  pageInfo: GraphQLPageInfo;
  totalCount: number;
}

export enum ProfessorSurveySortEnum {
  /* eslint-disable camelcase */
  ac2_professor_survey_id_asc = 'ac2_professor_survey_id_asc',
  ac2_professor_survey_id_desc = 'ac2_professor_survey_id_desc',
  due_date_time_asc = 'due_date_time_asc',
  due_date_time_desc = 'due_date_time_desc',
  created_date_asc = 'created_date_asc',
  created_date_desc = 'created_date_desc',
  updated_date_asc = 'updated_date_asc',
  updated_date_desc = 'updated_date_desc',
  /* eslint-enable camelcase */
}

export interface ProfessorSurveyQueryVariables {
  professorSurveyId?: number[];
  isClosed?: boolean;
  isDeleted?: boolean;
  searchProfessorSurveys?: string;
  sort?: ProfessorSurveySortEnum[];
  termIds?: number[];
  after?: string;
  first?: number;
}

export interface ProfessorSurveyRawQueryVariables {
  professorSurveyId?: string;
  isClosed?: string;
  isDeleted?: string;
  searchProfessorSurveys?: string;
  sort?: ProfessorSurveySortEnum[];
  termIds?: number[];
  after?: string;
  first?: number;
}

export interface ProfessorSurveyCreateData {
  professorSurveyCreate: {
    professorSurvey: {
      id: string;
    };
    userErrors: { field: string; message: string }[];
  };
}

export interface ProfessorSurveyCreateInput {
  input: {
    courseSectionIds: string[];
    description: string;
    dueDateTime: string;
    label: string;
    messageBody?: string;
    studentIds: string[];
    termId: string;
  };
}

export interface ProfessorSurveyExportFilters {
  professorSurveyId: string;
  advisorIds?: number[];
  courseIds?: number[];
  eligibilityYearIds?: string[];
  isAtRisk?: boolean;
  searchSurveyResults?: string;
  status?: ProfessorSurveyAssignmentStatus[];
  teamIds?: number[];
}
