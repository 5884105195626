import _ from 'lodash';
import { ReactNode, forwardRef, useEffect, useState } from 'react';

import { SelectionItemInterface } from '@tw/components/utils';
import { useViewer } from '@tw/hooks';

import { TWIcon, TWListItemAvatar } from '@tw/components';
import { GroupType, SelectionRestrictingPermission } from '@tw/generated';

import { formatUtils } from '@tw/util';
import { useUserSelection } from '@tw/hooks/useUserSelection/useUserSelection';
import { SelectInputBase } from '../../../@components';
import TWUserSelectionFullSingleModal from '../../TWUserSelectionFullSingleModal';
import useSingleSelect from '../../useSingleSelect';

interface TWUserSelectionInputSingleProps {
  accessibilityLabel?: string;
  disabled?: boolean;
  testID: string;
  onChange?: (selection: SelectionItemInterface) => void;
  defaultGroupTypeFilter?: GroupType;
  placeholder?: string;
  queryFilters?: {
    onlyPersonTypes?: string[];
    selectedTeam?: string;
    selectionRestrictingPermission?: SelectionRestrictingPermission;
  };
  disabledSelections?: string[];
  individualsOnly?: boolean;
  useGroups?: boolean;
  modalOnly?: boolean;
  isModalOpen?: boolean;
  width?: number;
  value?: SelectionItemInterface; // for form controlled input
  withTeamMembership?: boolean;
  canSwitchTeams?: boolean;
}

const TWUserSelectionInputSingle = forwardRef<HTMLDivElement, TWUserSelectionInputSingleProps>(
  (
    {
      accessibilityLabel,
      disabled = false,
      onChange,
      testID,
      defaultGroupTypeFilter = GroupType.Type,
      placeholder = '',
      queryFilters = {},
      individualsOnly = false,
      useGroups = false,
      modalOnly = false,
      isModalOpen = false,
      disabledSelections = [],
      width,
      value = null,
      withTeamMembership = false,
      canSwitchTeams = false,
    },
    ref,
  ) => {
    const viewer = useViewer();
    const [selectionTreeVisible, setSelectionTreeVisible] = useState(false);
    const [internalIsModalOpen, setInternalIsModalOpen] = useState(isModalOpen);
    const [teamFilter, setTeamFilter] = useState(
      queryFilters.selectedTeam ?? viewer.currentTeam.id,
    );

    const propsValue = Array.isArray(value) ? value[0] : value;

    useEffect(() => {
      setInternalIsModalOpen(isModalOpen);
    }, [isModalOpen]);

    const { loading, error, data } = useUserSelection({
      options: {
        ...queryFilters,
        teamFilter,
        withTeamMembership,
        userTypesOnly: false,
      },
      fetchPolicy: 'cache-and-network',
    });

    const singleSelectMangementProps = useSingleSelect({
      defaultGroupTypeFilter,
      onChange,
      initialValue: propsValue,
    });

    const { selectedItem, handleOnItemClick } = singleSelectMangementProps;

    const handleOnChange = (selectionItem: SelectionItemInterface): void => {
      handleOnItemClick(selectionItem);
      setSelectionTreeVisible(false);
    };

    const handleModalClose = (): void => {
      setInternalIsModalOpen(false);
    };

    const handleSubmit = (selectionItem: SelectionItemInterface): void => {
      handleOnChange(selectionItem);
      handleModalClose();
    };

    const handleMoreButtonClick = (): void => {
      setInternalIsModalOpen(true);
      setSelectionTreeVisible(false);
    };

    const renderSelectedItem = (): ReactNode => (
      <TWListItemAvatar
        avatarIcon={<TWIcon type={_.has(selectedItem, 'people') ? 'tw-group' : 'tw-user'} />}
        avatarImage={selectedItem.pictureUrl}
        avatarText={selectedItem.initials}
        onClick={(): void => setSelectionTreeVisible(!selectionTreeVisible)}
        testID={`UserSelection:SelectedItem-${selectedItem.selectionCode}`}
        label={formatUtils.getSelectionGroupLabel(
          selectedItem.groupType as GroupType,
          selectedItem.label,
        )}
        size="small"
        rightIcons={[
          {
            testID: `UserSelection:RemoveSelectionButton-${selectedItem.selectionCode}`,
            type: 'material-close',
            iconOnClick: (): void => handleOnChange(null),
          },
        ]}
      />
    );

    return (
      <div id="TWUserSelectionInputSingle" ref={ref}>
        {!modalOnly && (
          <SelectInputBase
            accessibilityLabel={accessibilityLabel}
            disabled={disabled}
            testID={testID}
            placeholder={placeholder}
            isModalOpen={internalIsModalOpen}
            setSelectionTreeVisible={setSelectionTreeVisible}
            selectionTreeVisible={selectionTreeVisible}
            handleMoreButtonClick={handleMoreButtonClick}
            resetAfterSelection
            data={data}
            loading={loading}
            error={error}
            itemOnSelect={handleOnChange}
            innerInputRender={renderSelectedItem}
            disabledSelections={disabledSelections}
            selectionItemsByCode={
              selectedItem
                ? {
                    [selectedItem.selectionCode]: selectedItem,
                  }
                : []
            }
            width={width}
            individualsOnly={individualsOnly}
            useGroups={useGroups}
            {...singleSelectMangementProps}
          />
        )}
        {internalIsModalOpen && (
          <TWUserSelectionFullSingleModal
            containerId="TWUserSelectionInputSingle"
            visible={internalIsModalOpen}
            disabledSelections={disabledSelections}
            testID={`UserSelectionSelectMulti:dialog:${testID}`}
            onOk={handleSubmit}
            onCancel={handleModalClose}
            setTeamFilter={(teamId: string): void => setTeamFilter(teamId)}
            teamFilter={teamFilter}
            defaultGroupTypeFilter={GroupType.Type}
            canSwitchTeams={canSwitchTeams}
            data={data}
            error={error}
            loading={loading}
            selectedItem={selectedItem}
            individualsOnly={individualsOnly}
          />
        )}
      </div>
    );
  },
);

export { TWUserSelectionInputSingle };
