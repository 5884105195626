import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import noop from 'lodash/noop';
import { Moment } from 'moment';
import { useRef } from 'react';

import { DOMUtils } from '@tw/util';

interface Props {
  containerId: string;
  ariaLabel?: string;
  bordered?: boolean;
  testID?: string;
  value?: Moment;
}

const TWInputDatePicker = ({
  ariaLabel,
  bordered,
  containerId,
  testID,
  value,
  onChange = noop,
  ...rest
}: Props & DatePickerProps) => {
  const inputElement = useRef(null);
  const dateValue = value && value.isValid() ? value : undefined;

  // this allows us to update the value even if the user doesn't click on the "ok" button in the dropdown
  const onSelect = (e) => {
    onChange(e, e.format());
  };

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen && bordered) {
      inputElement.current?.blur();
    }
  };

  return (
    <DatePicker
      aria-label={ariaLabel}
      bordered={bordered}
      data-testid={testID}
      getPopupContainer={(e) => DOMUtils.getParentContainer(e, containerId)}
      ref={inputElement}
      value={dateValue}
      onChange={onChange}
      onOpenChange={onOpenChange}
      onSelect={onSelect}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default TWInputDatePicker;
