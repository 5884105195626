import styled from '@emotion/styled';
import { sharedSpacingStyles, SpacingComponentProps } from '@tw/components/utils';

interface FlexComponentProps {
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  alignSelf?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  columnGap?: number;
  flex?: number | string;
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  hidden?: boolean;
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  row?: boolean;
  rowGap?: number;
  role?: string;
  ariaLabel?: string;
  disabled?: boolean;
}

const TWFlexContainer = styled.div<SpacingComponentProps & FlexComponentProps>(
  ({
    align = 'stretch',
    alignSelf = null,
    columnGap = null,
    flex = null,
    flexWrap = 'nowrap',
    hidden = false,
    justify = 'flex-start',
    row = false,
    rowGap = null,
    role = null,
    ariaLabel = null,
  }) => [
    sharedSpacingStyles,
    {
      alignItems: align,
      alignSelf,
      columnGap,
      display: hidden ? 'none' : 'flex',
      flex,
      flexDirection: row ? 'row' : 'column',
      flexWrap,
      justifyContent: justify,
      rowGap,
      role,
      ariaLabel,
    },
  ],
);

export default TWFlexContainer;
