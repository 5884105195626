import { useState, ChangeEvent } from 'react';
import _ from 'lodash';
import { getTranslation } from '@tw/i18n';
import { Form, Input, Radio } from 'antd';
import { composeFormRules } from '@tw/util';
import { useMutation } from '@apollo/client';

import {
  TWButton,
  TWFlexContainer,
  TWForm,
  TWFormItem,
  TWHeading3,
  TWLoadingMask,
  TWMessage,
  TWSpacingContainer,
  TWTextDefault,
  TWUserSelectionInputSingle,
} from '@tw/components';
import { UseViewerType } from '@tw/hooks';
import {
  FormAssignmentCompletionRoleNode,
  FormAssignmentNode,
  FormAssignmentRoleNode,
} from '@tw/generated';

import { FlexContainer } from '../../Forms.styles';
import { FormHeading } from '../../FormHeading';
import { ASSIGN_NEXT_COMPLETER } from '../graphql';

type AssignNextUserFormProps = {
  completionRole: FormAssignmentCompletionRoleNode;
  uuid?: string;
  external?: boolean;
  viewer?: UseViewerType;
};

const AssignNextUserForm = ({
  completionRole,
  uuid,
  external,
  viewer,
}: AssignNextUserFormProps) => {
  const [form] = Form.useForm();
  const { validateFields } = form;

  const [assignNextUser, { loading }] = useMutation(ASSIGN_NEXT_COMPLETER, {
    onCompleted: () => {
      // TODO: This mutation should have been on the FormAssignmentCompletionNode
      // Since its on the form assignment, we aren't returning the updated form completions
      // So we'll redirect for now, in order to refetch the updated completions.
      if (viewer && !external) {
        const url = `/forms/formComplete/${completionRole.id}`;
        window.location.href = url;
      } else {
        window.location.reload();
      }
    },
    onError: (error) => TWMessage.errorHandler(error),
  });

  const { completionRolesAssignedByCompleter } = completionRole;

  const [completerTypeChanged, setCompleterTypeChanged] = useState(false);

  const handleSubmit = async () => {
    try {
      const values = await validateFields();
      // Loop over values creating an array of completion role update objects
      const input = completionRolesAssignedByCompleter?.map((cRole) => {
        const roleId = cRole?.assignmentRole?.id;

        const assigneeInput: {
          completionRole: string | undefined;
          externalAssignee: { name: string | null; email: string | null } | null;
          internalAssignee: string | null;
        } = {
          completionRole: cRole?.id,
          externalAssignee: null,
          internalAssignee: null,
        };

        // Get form values (some will be undefined);
        const completerType = _.get(values, `${roleId}.completerType`);
        const name = _.get(values, `${roleId}.name`);
        const email = _.get(values, `${roleId}.email`);
        const internalAssignee = _.get(values, `${roleId}.internalAssignee`);

        // Check if there is a completer type field, indicating user had a choice btwn external/internal
        if (completerType) {
          // Get which assignee type is selected and the inputs values
          if (completerType === 'external') {
            assigneeInput.externalAssignee = { name, email };
          } else {
            assigneeInput.internalAssignee = internalAssignee?.selectionCode;
          }
          return assigneeInput;
        }

        // Otherwise check if was just internal assignee input
        if (internalAssignee) {
          assigneeInput.internalAssignee = internalAssignee?.selectionCode;
          // ..Or just external
        } else {
          assigneeInput.externalAssignee = { name, email };
        }
        return assigneeInput;
      });

      const variables = {
        input: {
          roleChoices: input,
        },
      };
      if (uuid) {
        _.set(variables.input, 'externalLinkUuid', uuid);
      }

      assignNextUser({ variables });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  // Renders the form Input for selecting an external user (name and email)
  const renderExternalUserInput = (completerIsExternal: boolean, roleId: string) => (
    <>
      <TWFormItem
        style={{ width: 200 }}
        name={`${roleId}.name`}
        label={getTranslation('name')}
        rules={completerIsExternal && composeFormRules('required')}
      >
        <Input
          autoFocus
          disabled={!completerIsExternal}
          placeholder={getTranslation('name')}
          aria-label={getTranslation('name')}
        />
      </TWFormItem>
      <TWFormItem
        style={{ width: 200 }}
        name={`${roleId}.email`}
        label={getTranslation('email')}
        rules={completerIsExternal && composeFormRules('required', 'email')}
      >
        <Input
          placeholder={getTranslation('email')}
          disabled={!completerIsExternal}
          aria-label={getTranslation('email')}
        />
      </TWFormItem>
    </>
  );

  // Renders the form input for selecting an internal TW user
  const renderInternalUserInput = (completerIsExternal: boolean, role: FormAssignmentRoleNode) => (
    <TWFormItem
      name={`${role.id}.internalAssignee`}
      rules={!completerIsExternal && composeFormRules('required')}
    >
      <TWUserSelectionInputSingle
        individualsOnly
        canSwitchTeams
        disabled={completerIsExternal}
        placeholder={getTranslation('selectUsers', 1)}
        queryFilters={{
          onlyPersonTypes: role.chooserRestrictedToPersonTypes as Array<string>,
        }}
        testID="AssignNextUserForm:UserSelectionInput"
      />
    </TWFormItem>
  );

  // Renders the form input for one role's form
  // Can have Input for choosing external email, internal user, or both
  const renderFormInput = (role: FormAssignmentRoleNode) => {
    const roleId = role.id;
    const fieldName = `${roleId}.completerType`;
    const completerIsExternal = [undefined, 'external'].includes(form.getFieldValue(fieldName));

    // If user is allowed to select either external or interal user, render both options
    if (role.chooserRestrictedToPersonTypes && role.chooserAllowExternal) {
      return (
        <TWFormItem
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            // This is a hack since re render is not triggered when form item is changed
            setCompleterTypeChanged(!completerTypeChanged);
            return form.setFieldsValue({ fieldName: e.target.value });
          }}
          initialValue="external"
          name={fieldName}
          rules={composeFormRules('required')}
        >
          <Radio.Group style={{ display: 'flex', flexDirection: 'column' }}>
            <Radio value="external">
              <TWTextDefault>
                {getTranslation('forms.docusignFormCompletion.assignNextUser.enterInfoExternal')}
              </TWTextDefault>
              <TWFlexContainer twMarginLeft={4} twMarginTop={1}>
                {renderExternalUserInput(completerIsExternal, roleId)}
              </TWFlexContainer>
            </Radio>
            <Radio value="internal" disabled={!!external}>
              <TWTextDefault>
                {getTranslation('forms.docusignFormCompletion.assignNextUser.enterInfoInternal')}
              </TWTextDefault>
              <TWFlexContainer twMarginLeft={4} twMarginTop={2}>
                {renderInternalUserInput(completerIsExternal, role)}
              </TWFlexContainer>
            </Radio>
          </Radio.Group>
        </TWFormItem>
      );
    }
    // Otherwise just render external (name/email) form
    if (role.chooserAllowExternal) {
      return (
        <>
          <TWTextDefault>
            {getTranslation('forms.docusignFormCompletion.assignNextUser.enterInfoExternal')}
          </TWTextDefault>
          <TWFlexContainer twMarginTop={1}>{renderExternalUserInput(true, roleId)}</TWFlexContainer>
        </>
      );
    }
    // Or internal selection..
    return (
      <>
        <TWTextDefault>
          {getTranslation('forms.docusignFormCompletion.assignNextUser.enterInfoInternal')}
        </TWTextDefault>
        <TWFlexContainer twMarginTop={2}>{renderInternalUserInput(false, role)}</TWFlexContainer>
      </>
    );
  };

  // Renders all the forms for choosing next selection
  // There can be more than one, ex role needs to assign role 2 and 3
  const renderForms = () => (
    <TWForm name="assignNextUserForm" form={form}>
      {_.map(completionRole.completionRolesAssignedByCompleter, (cRole) => (
        <TWFlexContainer key={cRole?.id} twMarginBottom={3}>
          <TWHeading3>
            {`${cRole?.assignmentRole?.roleName} ${getTranslation(
              'forms.docusignFormCompletion.assignNextUser.completer',
            )}`}
          </TWHeading3>
          {cRole?.assignmentRole && renderFormInput(cRole.assignmentRole)}
        </TWFlexContainer>
      ))}
    </TWForm>
  );

  return (
    <FlexContainer>
      <TWFlexContainer id="docusignAssignNextUser">
        {loading && <TWLoadingMask />}
        <TWSpacingContainer
          twPadding={3}
          customStyles={{ borderBottom: '1px solid rgba(66, 81, 130, 0.2)' }}
        >
          <TWFlexContainer row justify="space-between">
            <FormHeading
              assignment={completionRole?.completion?.assignment as FormAssignmentNode}
            />
          </TWFlexContainer>
        </TWSpacingContainer>
        <TWFlexContainer twMargin={2}>
          <TWTextDefault>
            {getTranslation('forms.docusignFormCompletion.assignNextUser.chooseNextUser')}
          </TWTextDefault>
          <TWSpacingContainer twMarginBottom={2} />
          {renderForms()}
          <TWSpacingContainer twMarginBottom={2} />
          <TWButton type="primary" onClick={handleSubmit}>
            {getTranslation('continue')}
          </TWButton>
        </TWFlexContainer>
      </TWFlexContainer>
    </FlexContainer>
  );
};

export default AssignNextUserForm;
