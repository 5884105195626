import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { RawProfessorSurveyAssignment, GraphQLPaginatedResponse } from '@tw/types';
import { professorSurveyAssignmentModel } from '@tw/services/academicService';
import { FetchProfessorSurveyAssignmentsExternal } from './FetchProfessorSurveyAssignmentsExternal.query';

interface RawExternalProfessorSurveyData {
  externalProfessorSurveyAssignments: GraphQLPaginatedResponse<RawProfessorSurveyAssignment>;
}

interface ExternalProfessorSurveyVariables {
  uuid: string;
}

export const useProfessorSurveyAssignmentsExternal = (
  options?: QueryHookOptions<RawExternalProfessorSurveyData, ExternalProfessorSurveyVariables>,
) => {
  const { data, ...rest } = useQuery<
    RawExternalProfessorSurveyData,
    ExternalProfessorSurveyVariables
  >(FetchProfessorSurveyAssignmentsExternal, options);

  const assignmentData = useMemo(
    () =>
      data?.externalProfessorSurveyAssignments
        ? professorSurveyAssignmentModel.transformRawPaginatedProfessorSurveyAssignmentData(
            data.externalProfessorSurveyAssignments,
          )
        : undefined,
    [data],
  );

  return {
    ...rest,
    data: assignmentData,
  };
};
