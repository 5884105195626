import { ReactNode } from 'react';

import { AttributeStackedWrapper } from './TWAttributeStacked.styles';

interface TWAttributeStackedProps {
  label: string;
  value: ReactNode;
  testID?: string;
}

const TWAttributeStacked = ({ label, value, testID = '' }: TWAttributeStackedProps) => (
  <AttributeStackedWrapper>
    <div data-testid={`${testID}Item:Label`} className="attribute-label">
      {label}
    </div>
    <div data-testid={`${testID}Item:Value`} className="attribute-value">
      {value}
    </div>
  </AttributeStackedWrapper>
);

export default TWAttributeStacked;
