import { reduxConfigUtils } from '@tw/util';

import baseDefault from './baseDefault';
import uiSettingsConfigStoreConfig from './uiSettingsConfigStoreConfig';
import {
  APPLY_USER_STATE,
  CLEAR_USER_STATE,
  APPLY_DEBUG_OVERRIDE,
  CLEAR_DEBUG_OVERRIDE,
} from './uiSettingsConfigActions';

// Note: Everything is flat here (instead of nesting/grouping the attributes and values
// separately) because that makes the store manipulations easier -- we'd need 3-4 spread
// operators for each one instead of 1-2.
const initialState = {
  // In priority order, from lowest to highest
  layerNames: uiSettingsConfigStoreConfig.layerNames,

  // Available attributes:
  //    isEnabled     {bool}
  //    @TODO: For future growth, if/when we need it:
  //      * something to enable/disable debug logging for the layer,
  //      * tags to allow complex queries from outside (to answer questions like
  //        "did this value come from a layer tagged as 'override'?", for debugging only)
  attributes_baseDefault: { isEnabled: true },
  attributes_userState: { isEnabled: false },
  attributes_debugOverride: { isEnabled: false },

  values_baseDefault: baseDefault,
  values_userState: {},
  values_debugOverride: {},
};

reduxConfigUtils.warnIfInitialStateLooksIncomplete(initialState);

export default function uiSettingsConfigReducer(state = initialState, action) {
  switch (action.type) {
    case APPLY_USER_STATE: {
      return {
        ...state,
        values_userState: reduxConfigUtils.mergeConfigLayerValues(
          state.values_userState,
          action.userStateValues,
        ),
        attributes_userState: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_userState,
          {
            isEnabled: true,
          },
        ),
      };
    }

    /**
     * This *merges in* a set of debugOverride values
     */
    case APPLY_DEBUG_OVERRIDE: {
      return {
        ...state,
        attributes_debugOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_debugOverride,
          {
            isEnabled: true,
          },
        ),
        values_debugOverride: reduxConfigUtils.mergeConfigLayerValues(
          state.values_debugOverride,
          action.debugOverrideValues,
        ),
      };
    }

    /**
     * This wipes (but does not disable) any user-specific values
     */
    case CLEAR_USER_STATE: {
      return {
        ...state,
        attributes_userState: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_userState,
          {
            isEnabled: false,
          },
        ),
        values_userState: {},
      };
    }

    /**
     * This wipes (but does not disable) any debugOverride values
     */
    case CLEAR_DEBUG_OVERRIDE: {
      return {
        ...state,
        attributes_debugOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_debugOverride,
          {
            isEnabled: false,
          },
        ),
        values_debugOverride: {},
      };
    }

    default:
      return state;
  }
}
