/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketingEventAllocationsExportQueryVariables = Types.Exact<{
  searchPersons?: Types.InputMaybe<Types.Scalars['String']>;
  teams?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>
  >;
  personTypes?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>
  >;
  ticketingEvent: Types.Scalars['ID'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sort?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.TicketingEventPersonSortEnum>>
    | Types.InputMaybe<Types.TicketingEventPersonSortEnum>
  >;
}>;

export type TicketingEventAllocationsExportQuery = {
  ticketingEventPersons?: {
    edges: Array<{
      node?: {
        notes?: string | null;
        userDetails?: string | null;
        defaultAllocatedAmount?: number | null;
        transferredInAmount?: number | null;
        transferredOutAmount?: number | null;
        reservedAmount?: number | null;
        person?: { displayName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export const TicketingEventAllocationsExportDocument = gql`
  query TicketingEventAllocationsExport(
    $searchPersons: String
    $teams: [Int]
    $personTypes: [ID]
    $ticketingEvent: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [TicketingEventPersonSortEnum]
  ) {
    ticketingEventPersons(
      searchPersons: $searchPersons
      teams: $teams
      personTypes: $personTypes
      ticketingEvent: $ticketingEvent
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
    ) {
      edges {
        node {
          notes
          person {
            displayName
          }
          userDetails
          defaultAllocatedAmount
          transferredInAmount
          transferredOutAmount
          reservedAmount
        }
      }
    }
  }
`;

/**
 * __useTicketingEventAllocationsExportQuery__
 *
 * To run a query within a React component, call `useTicketingEventAllocationsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketingEventAllocationsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketingEventAllocationsExportQuery({
 *   variables: {
 *      searchPersons: // value for 'searchPersons'
 *      teams: // value for 'teams'
 *      personTypes: // value for 'personTypes'
 *      ticketingEvent: // value for 'ticketingEvent'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTicketingEventAllocationsExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    TicketingEventAllocationsExportQuery,
    TicketingEventAllocationsExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TicketingEventAllocationsExportQuery,
    TicketingEventAllocationsExportQueryVariables
  >(TicketingEventAllocationsExportDocument, options);
}
export function useTicketingEventAllocationsExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TicketingEventAllocationsExportQuery,
    TicketingEventAllocationsExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TicketingEventAllocationsExportQuery,
    TicketingEventAllocationsExportQueryVariables
  >(TicketingEventAllocationsExportDocument, options);
}
export type TicketingEventAllocationsExportQueryHookResult = ReturnType<
  typeof useTicketingEventAllocationsExportQuery
>;
export type TicketingEventAllocationsExportLazyQueryHookResult = ReturnType<
  typeof useTicketingEventAllocationsExportLazyQuery
>;
export type TicketingEventAllocationsExportQueryResult = Apollo.QueryResult<
  TicketingEventAllocationsExportQuery,
  TicketingEventAllocationsExportQueryVariables
>;
