import styled from '@emotion/styled';
import { ListItemSizeScalingEnum } from '../../../utils';
import { inputSizeScaling } from '../utils';

interface RadioGroupContainerProps {
  vertical?: boolean;
  inputType: 'radio' | 'button';
  size: ListItemSizeScalingEnum;
  customStyles?: any;
}

const RadioGroupContainer = styled.div<RadioGroupContainerProps>(
  ({ theme, vertical = false, size, inputType, customStyles }) => {
    const { height } = inputSizeScaling[size];

    const radioInputStyles = {
      '.ant-radio-group': {
        display: 'flex',
        flexDirection: 'column',
      },
      '.ant-radio-group label': {
        marginBottom: theme.baseUnit,
      },
      span: {
        fontSize: '14px',
        color: theme.colors.secondary,
        fontWeight: theme.fontWeight.regular,
      },
    };

    const buttonInputStyles = {
      '.ant-radio-group': {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
      },
      '.ant-radio-button-wrapper': {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        textAlign: 'center',
        'span:last-child': {
          width: '100%',
        },
      },
    };

    const inputTypeStyles =
      vertical && inputType === 'radio' ? radioInputStyles : buttonInputStyles;

    return {
      display: 'flex',
      alignItems: 'center',
      height: inputType === 'radio' && !vertical ? height : 'inherit',
      ...inputTypeStyles,
      ...customStyles,
    };
  },
);

export { RadioGroupContainer };
