import { QueryHookOptions, useQuery } from '@apollo/client';

import { GraphQLPaginatedResponse, ProfessorSurveyAssignmentStatus } from '@tw/types';

import { PROFESSOR_SURVEY_ASSIGNMENTS_BY_STUDENT_TERM_QUERY } from '../../@graphql';

export interface EnrollmentWithProfessorSurveyAssignments {
  id: string;
  professorSurveyReports: GraphQLPaginatedResponse<{
    id: string;
    absenceCount: number;
    comment: string;
    isAtRisk: boolean;
    grade: {
      id: string;
      label: string;
    };
    professorSurveyAssignment: {
      id: string;
      professorSurveyId: number;
      status: ProfessorSurveyAssignmentStatus;
    };
  }>;
  courseSection: {
    id: string;
    courseId: number;
    shortCode: string;
    course: {
      id: string;
      label: string;
      description: string;
    };
    professor: {
      id: string;
      fullName: string;
    };
  };
}

export interface StudentTermWithProfessorSurveyAssignments {
  id: string;
  student: {
    id: string;
    eligibilityYears: string[];
    fullName: string;
    person: {
      id: string;
      teamLabels: string;
    };
  };
  enrollments: GraphQLPaginatedResponse<EnrollmentWithProfessorSurveyAssignments>;
}

interface ProfessorSurveyAssignmentsByStudentTermData {
  studentTerms: GraphQLPaginatedResponse<StudentTermWithProfessorSurveyAssignments>;
}

interface ProfessorSurveyAssignmentsByStudentTermVariables {
  professorSurveyId: string;
  advisorIds?: number[];
  courseIds?: number[];
  eligibilityYearIds?: number[];
  isAtRisk?: boolean;
  searchStudents?: string;
  professorSurveyAssignmentStatus?: string[];
  teamIds?: number[];
  after: string;
  first: number;
}

export const useProfessorSurveyAssignmentsByStudentTerm = (
  options: QueryHookOptions<
    ProfessorSurveyAssignmentsByStudentTermData,
    ProfessorSurveyAssignmentsByStudentTermVariables
  >,
) => useQuery(PROFESSOR_SURVEY_ASSIGNMENTS_BY_STUDENT_TERM_QUERY, options);
