import * as React from 'react';
import { getTranslation } from '@tw/i18n';
import { StudentStatus } from './TWStudentStatus.styles';

interface TWStudentStatusProps {
  isAtRisk: boolean;
}

const TWStudentStatus: React.FC<TWStudentStatusProps> = ({ isAtRisk }) => {
  const statusKey = isAtRisk ? 'atRisk' : 'onTrack';
  return (
    <StudentStatus statusKey={statusKey}>{getTranslation(`components.${statusKey}`)}</StudentStatus>
  );
};

export default TWStudentStatus;
