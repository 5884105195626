/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBulletinInfoQueryVariables = Types.Exact<{
  bulletinId?: Types.InputMaybe<Types.Scalars['TWFilter_ID']>;
}>;

export type GetBulletinInfoQuery = {
  allBulletins?: {
    edges: Array<{
      bulletin?: {
        bulletinId: string;
        bulletinType: Types.BulletinType;
        label: string;
        externalUrl?: string | null;
        isPinned: boolean;
        createdDate?: any | null;
        updatedDate?: any | null;
        content: string;
        expirationDateTime?: any | null;
        scheduledDatetime?: any | null;
        messageId?: number | null;
        streamMessageId?: number | null;
        headerImageFull?: string | null;
        headerImageThumb?: string | null;
        linkPreviewHash?: string | null;
        meta?: any | null;
        buttonText?: string | null;
        personIdsNotClicked?: Array<number | null> | null;
        totalClicks?: number | null;
        personIdsWhoClicked?: Array<number | null> | null;
        lastClickedDate?: any | null;
        key?: string | null;
        visibilityCodes?: Array<string | null> | null;
        creator?: {
          pk?: string | null;
          initials?: string | null;
          fullName?: string | null;
          picture?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export const GetBulletinInfoDocument = gql`
  query GetBulletinInfo($bulletinId: TWFilter_ID) {
    allBulletins(bulletinId: $bulletinId) {
      edges {
        bulletin: node {
          key: pk
          bulletinId
          bulletinType
          label
          externalUrl
          isPinned
          createdDate
          updatedDate
          creator {
            pk
            fullName: fullNameNormalOrder
            initials
            picture: pictureUrl
          }
          content
          expirationDateTime
          scheduledDatetime
          messageId
          streamMessageId
          headerImageFull
          headerImageThumb
          linkPreviewHash
          meta
          buttonText
          personIdsNotClicked
          totalClicks
          personIdsWhoClicked
          lastClickedDate
          visibilityCodes: visibility
        }
      }
    }
  }
`;

/**
 * __useGetBulletinInfoQuery__
 *
 * To run a query within a React component, call `useGetBulletinInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulletinInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulletinInfoQuery({
 *   variables: {
 *      bulletinId: // value for 'bulletinId'
 *   },
 * });
 */
export function useGetBulletinInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBulletinInfoQuery, GetBulletinInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBulletinInfoQuery, GetBulletinInfoQueryVariables>(
    GetBulletinInfoDocument,
    options,
  );
}
export function useGetBulletinInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBulletinInfoQuery, GetBulletinInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBulletinInfoQuery, GetBulletinInfoQueryVariables>(
    GetBulletinInfoDocument,
    options,
  );
}
export type GetBulletinInfoQueryHookResult = ReturnType<typeof useGetBulletinInfoQuery>;
export type GetBulletinInfoLazyQueryHookResult = ReturnType<typeof useGetBulletinInfoLazyQuery>;
export type GetBulletinInfoQueryResult = Apollo.QueryResult<
  GetBulletinInfoQuery,
  GetBulletinInfoQueryVariables
>;
