/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideoCreateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.VideoInput>;
}>;

export type VideoCreateMutation = {
  videoCreate: {
    video?: {
      videoId: string;
      wistiaVideoUuid?: string | null;
      title: string;
      description?: string | null;
      folderId?: number | null;
      isPinned: boolean;
      playCount?: number | null;
      downloadCount?: number | null;
      hasActiveShareLink?: boolean | null;
      wistiaThumbnailUrl?: string | null;
      wistiaOriginalFileUrl?: string | null;
      updatedDate?: any | null;
      canDownload?: boolean | null;
      creator?: {
        createdBy?: number | null;
        pictureDownloadUrl?: string | null;
        preferredName?: string | null;
        lastName?: string | null;
      } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const VideoCreateDocument = gql`
  mutation VideoCreate($input: VideoInput) {
    videoCreate(input: $input) {
      video {
        videoId
        wistiaVideoUuid
        title
        description
        folderId
        isPinned
        playCount
        downloadCount
        hasActiveShareLink
        wistiaThumbnailUrl
        wistiaOriginalFileUrl
        updatedDate
        canDownload
        creator {
          createdBy
          pictureDownloadUrl
          preferredName
          lastName
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type VideoCreateMutationFn = Apollo.MutationFunction<
  VideoCreateMutation,
  VideoCreateMutationVariables
>;

/**
 * __useVideoCreateMutation__
 *
 * To run a mutation, you first call `useVideoCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoCreateMutation, { data, loading, error }] = useVideoCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVideoCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<VideoCreateMutation, VideoCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VideoCreateMutation, VideoCreateMutationVariables>(
    VideoCreateDocument,
    options,
  );
}
export type VideoCreateMutationHookResult = ReturnType<typeof useVideoCreateMutation>;
export type VideoCreateMutationResult = Apollo.MutationResult<VideoCreateMutation>;
export type VideoCreateMutationOptions = Apollo.BaseMutationOptions<
  VideoCreateMutation,
  VideoCreateMutationVariables
>;
