import { useQuery } from '@apollo/client';
import { Result } from 'antd';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { TWLoadingMask, TWTextDefault } from '@tw/components';
import { useTranslator } from '@tw/hooks';
import ProfessorSurveyReport from '@tw/modules/Academics2/ProfessorSurveyReport';
import { ExternalFormCompletion } from '@tw/modules/Forms/FormCompletion';
import VideosExternalPlay from '@tw/modules/Videos/VideosExternalPlay';
import { UserRegistration } from '../UserRegistration';
import { EXTERNAL_ACCESS_PAGE } from './@graphql';

const ErrorPage = ({ error }) => {
  const translator = useTranslator();
  const errorCode = _.isString(error)
    ? error
    : error?.graphQLErrors?.[0]?.extensions?.code ?? 'ExternalLinkNotFound';

  return (
    <Result
      status="error"
      title={translator.t(`errorCodes.${errorCode}`)}
      extra={
        <TWTextDefault>
          If you need help, please{' '}
          <a href="https://www.teamworks.com/support/" target="_blank" rel="noreferrer">
            contact Teamworks support.
          </a>
        </TWTextDefault>
      }
    />
  );
};

export default function ExternalAccess() {
  const history = useHistory();
  const location = useLocation();

  const url = new URL(window.location.href);

  const uuid = url.pathname.replace(/\/ext2/.test(url.pathname) ? '/ext2/' : '/ext/', '');
  const { data, loading, error } = useQuery(EXTERNAL_ACCESS_PAGE, { variables: { uuid } });
  const warning = _.first(data?.externalAccessLink?.warnings);
  const action = data?.externalAccessLink?.node?.action;

  // Clear location state immediately after rendering feedback message
  useEffectOnce(() => {
    if (location.state) {
      history.replace(window.location.pathname);
    }
  });

  if (loading) {
    return <TWLoadingMask />;
  }

  const Component =
    {
      ACADEMICS_PROFESSOR_SURVEY_FORM_RW: ProfessorSurveyReport,
      NEW_USER: UserRegistration,
      RESET_PASSWORD: null,
      FORM_ASSIGNMENT_COMPLETE: ExternalFormCompletion,
      VIDEO_EXTERNAL_PLAY: VideosExternalPlay,
    }[action] ?? ErrorPage;

  return <Component uuid={uuid} error={error || warning} />;
}
