import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { MutableRefObject, ReactNode, useMemo, useState } from 'react';

import { TWSpacingContainer } from '@tw/components';
import { SelectionItemInterface, SelectionItemsByCode } from '@tw/components/utils';
import { UserSelectionOptionsQuery, GroupType } from '@tw/generated';
import { PersonNode } from '@tw/types';
import { functionUtils, formatUtils } from '@tw/util';

import { TWIcon } from '../../../../../components.web';
import { TWEmpty, TWListItemParent, TWLoadingMask } from '../../../../../presentational';
import TreeLeaf from '../TreeLeaf';
import useKeyboardNavigation from './useKeyboardNavigation';

const branchLabel = (branchItem: SelectionItemInterface) =>
  formatUtils.getSelectionGroupLabel(branchItem.groupType as GroupType, branchItem.label);

const userSelectionFilterBySearchQuery =
  functionUtils.memoizeFilterWithSearchTermFn<SelectionItemInterface>(
    (listToSearch, searchQuery: string) =>
      listToSearch.filter((selectionItem) =>
        functionUtils.searchTermStartsWordInString(branchLabel(selectionItem), searchQuery),
      ),
  );

interface TreeSelectProps {
  groups: UserSelectionOptionsQuery['groups'];
  loading?: boolean;
  selectionItemsByCode?: SelectionItemsByCode;
  alwaysSelectedCodes?: string[];
  hideAlwaysSelected?: boolean;
  containerEl: MutableRefObject<HTMLDivElement | null>;
  searchFilter?: string;
  groupTypeFilter?: string;
  resetAfterSelection?: boolean;
  itemOnSelect?: (selectableItem: SelectionItemInterface) => void;
  individualsOnly?: boolean;
  disabledSelections?: string[];
  userTypesOnly?: boolean;
  userTypeGroupsOnly?: boolean;
  visible?: boolean;
  useGroups?: boolean;
  hideDisabled?: boolean;
}

const TreeSelect = ({
  groups,
  loading,
  searchFilter,
  groupTypeFilter,
  resetAfterSelection,
  itemOnSelect,
  selectionItemsByCode = {},
  alwaysSelectedCodes,
  hideAlwaysSelected,
  containerEl,
  visible,
  individualsOnly,
  disabledSelections,
  hideDisabled = false,
  userTypesOnly,
  userTypeGroupsOnly,
  useGroups = false,
}: TreeSelectProps) => {
  const [highlightedElRef, setHighlightedElRef] = useState(null);

  // move custom groups to the end of list
  const sortedGroups = sortBy(groups, ({ groupType }) => (groupType !== 'CUSTOM' ? 0 : 1));
  const firstCustomGroup = useMemo(
    () => sortedGroups?.find(({ groupType }) => groupType === 'CUSTOM'),
    [sortedGroups],
  );

  const setHighlightedElRefCb = (ref, highlighted): void => {
    if (highlighted) {
      setHighlightedElRef(ref);
    }
  };

  const container = containerEl?.current ?? null;

  const { cursorPosition, filteredSelectionItems, handleExpandBranch, expandedBranches } =
    useKeyboardNavigation({
      visible,
      groups,
      TreeEl: container,
      highlightedElRef,
      itemOnSelect,
      selections: selectionItemsByCode,
      searchFilter,
      groupTypeFilter,
      resetAfterSelection,
    });

  const renderLeaf = (selectableItem: SelectionItemInterface): ReactNode => {
    const isAlwaysSelected = alwaysSelectedCodes?.includes(selectableItem.selectionCode);

    // Don't render always selected items if hideAlwaysSelected is true
    if (isAlwaysSelected && hideAlwaysSelected) {
      return null;
    }

    const isItemSelected =
      isAlwaysSelected || has(selectionItemsByCode, selectableItem.selectionCode);
    const isGroup = has(selectableItem, 'groupType');
    const isDisabled =
      isGroup && 'people' in selectableItem
        ? selectableItem?.people.every((person) =>
            disabledSelections?.includes(person.selectionCode),
          )
        : disabledSelections?.includes(selectableItem.selectionCode);

    // Don't render disabled items if hideDisabled is true
    if (isDisabled && hideDisabled) {
      return null;
    }
    const isHighlighted =
      filteredSelectionItems[cursorPosition] &&
      filteredSelectionItems[cursorPosition].clientId === selectableItem.selectionCode;

    if (individualsOnly && isGroup) {
      return null;
    }

    let label = branchLabel(selectableItem);
    if (selectableItem.groupType === GroupType.Everyone) {
      label = selectableItem.pluralLabel;
    }

    const peopleCount = selectableItem?.people?.length;
    if (isGroup && peopleCount) {
      label += ` (${peopleCount})`;
    }

    return (
      <div
        key={selectableItem.selectionCode}
        ref={(ref): void => setHighlightedElRefCb(ref, isHighlighted)}
      >
        <TreeLeaf
          isDisabled={isAlwaysSelected || isDisabled}
          isSelected={isItemSelected}
          isHighlighted={isHighlighted}
          label={label}
          testID={`UserSelection:Leaf-${selectableItem.selectionCode}`}
          avatarIcon={<TWIcon type={selectableItem.groupType ? 'tw-group' : 'tw-user'} />}
          avatarText={selectableItem.initials}
          avatarImage={selectableItem.pictureUrl}
          onClick={() => {
            if (!isAlwaysSelected) itemOnSelect?.(selectableItem);
          }}
        />
      </div>
    );
  };

  const renderBranch = (branchItem: SelectionItemInterface): ReactNode => {
    const branchId = `${branchItem.selectionCode}-${branchItem.label}`;
    const isExpanded = expandedBranches?.includes(branchId);
    const isHighlighted =
      filteredSelectionItems[cursorPosition] &&
      filteredSelectionItems[cursorPosition].clientId === branchId;

    const isGroup = 'people' in branchItem;
    let branchPeople: PersonNode[] = [];

    if (isGroup) {
      branchPeople = branchItem.people;
    }

    let branchSelectableItems = [branchItem];
    if (!userTypesOnly) {
      branchSelectableItems = [branchItem, ...branchPeople];
    }

    // Only show user type contact groups when contacts filter is selected
    if (groupTypeFilter !== GroupType.Contacts && branchItem.groupType === GroupType.TypeContacts) {
      return null;
    }

    if (
      branchItem.groupType === GroupType.Everyone ||
      branchItem.groupType === GroupType.TypeContacts
    ) {
      return renderLeaf(branchItem);
    }

    // For the select inputs we don't want to show every possible selection
    // group, only the most common - i.e. user type groups
    // if useGroups is "true", show custom groups
    if (
      userTypeGroupsOnly &&
      !(
        branchItem.groupType === GroupType.Type ||
        (useGroups && branchItem.groupType === GroupType.Custom)
      )
    ) {
      return null;
    }

    // ATHLETIC_YEAR branches are displayed under the ACADEMIC_YEAR group filter
    const showItemsByFilter =
      branchItem.groupType === groupTypeFilter ||
      (groupTypeFilter === GroupType.AcademicYear &&
        branchItem.groupType === GroupType.AthleticYear);
    // filter groups based on groupType
    if (groupTypeFilter && !searchFilter && !showItemsByFilter) {
      return null;
    }

    // filter selectionItems by searchFilter
    if (searchFilter) {
      branchSelectableItems = userSelectionFilterBySearchQuery(
        branchSelectableItems,
        searchFilter,
      ).filter((selection) => {
        const isAlwaysSelected = alwaysSelectedCodes?.includes(selection.selectionCode);
        return !(isAlwaysSelected && hideAlwaysSelected);
      });
    }

    if (!branchSelectableItems.length) {
      return null;
    }

    return (
      <div
        className={`${
          isEqual(branchItem.label, firstCustomGroup?.label) ? 'custom-group-divider' : ''
        }`}
        key={branchId}
        ref={(ref): void => setHighlightedElRefCb(ref, isHighlighted)}
      >
        {!userTypesOnly ? (
          <TWListItemParent
            isHighlighted={isHighlighted}
            onClick={() => handleExpandBranch(branchItem)}
            isExpanded={isExpanded}
            testID={`UserSelection:Branch-${branchId}`}
            label={branchLabel(branchItem)}
            disableExpanded={!!searchFilter}
          >
            {(isExpanded || searchFilter) && (
              <TWSpacingContainer twPaddingLeft={3}>
                {branchSelectableItems?.map(renderLeaf)}
              </TWSpacingContainer>
            )}
          </TWListItemParent>
        ) : (
          <TWSpacingContainer>{branchSelectableItems?.map(renderLeaf)}</TWSpacingContainer>
        )}
      </div>
    );
  };

  if (loading) {
    return <TWLoadingMask />;
  }

  const renderedBranches = sortedGroups
    ?.filter(({ people }) => !isEmpty(people) || userTypesOnly)
    ?.map(renderBranch);

  if (renderedBranches?.find((branch) => branch != null)) {
    return <>{renderedBranches}</>;
  }

  return <TWEmpty label="No users were found" testID="UserSelection:Empty" />;
};

TreeSelect.defaultProps = {
  groups: [],
  searchFilter: '',
  resetAfterSelection: true,
  itemOnSelect: () => null,
  visible: false,
};

export default TreeSelect;
