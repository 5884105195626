import { TWInputRadioGroup } from '@tw/components';
import { ListItemSizeScalingEnum } from '@tw/components/utils';
import { GroupType } from '@tw/generated';
import { getTranslation } from '@tw/i18n';

interface GroupFilterProps {
  groupTypes: Set<GroupType>;
  defaultValue: GroupType;
  onChange: ({ target: { value } }: { target: { value?: GroupType } }) => void;
  disabledOptions?: string[];
}

const GroupFilter = ({ onChange, defaultValue, groupTypes }: GroupFilterProps) => {
  const options = [
    {
      testID: 'UserSelection:groupTypeRadioGroup-TYPE',
      value: GroupType.Type,
      label: getTranslation('types', 1),
    },
    {
      testID: 'UserSelection:groupTypeRadioGroup-CUSTOM',
      value: GroupType.Custom,
      label: getTranslation('userSelection.group'),
    },
    {
      testID: 'UserSelection:groupTypeRadioGroup-CGAT',
      value: GroupType.Cgat,
      label: getTranslation('userSelection.group'),
    },
    {
      testID: 'UserSelection:groupTypeRadioGroup-POSITION',
      value: GroupType.Position,
      label: getTranslation('position'),
    },
    {
      testID: 'UserSelection:groupTypeRadioGroup-STATUS',
      value: GroupType.Status,
      label: getTranslation('status'),
    },
    {
      testID: 'UserSelection:groupTypeRadioGroup-ACADEMIC_YEAR',
      value: GroupType.AcademicYear,
      label: getTranslation('year', 1),
    },
    {
      testID: 'UserSelection:groupTypeRadioGroup-CONTACTS',
      value: GroupType.Contacts,
      label: getTranslation('contacts', 2),
    },
  ].filter((option) => groupTypes.has(option.value));

  // add ATHLETIC_YEAR group type to options list
  // in case it exists in groupTpes and ACADEMIC module is off
  // we still want to show users with athletic year in the YEAR tab
  if (groupTypes.has(GroupType.AthleticYear) && !groupTypes.has(GroupType.AcademicYear)) {
    options.push({
      testID: 'UserSelection:groupTypeRadioGroup-ACADEMIC_YEAR',
      value: GroupType.AthleticYear,
      label: getTranslation('year', 1),
    });
  }

  // If the group type is not in the list of options, set the default value to the first option
  if (!groupTypes.has(defaultValue) && options?.[0]?.value) {
    onChange({ target: { value: options[0].value } });
  }

  // Don't bother rendering the radio group if there is only one option
  if (options.length <= 1 && !options.some((opt) => opt.value === GroupType.Contacts)) {
    return null;
  }

  // we don't want to include the custom groups across teams in any radio group
  const filteredOptions = options.filter((option) => option.value !== GroupType.Cgat);

  return (
    <TWInputRadioGroup
      accessibilityLabel="Filter Selections"
      inputType="button"
      onChange={onChange}
      defaultValue={defaultValue}
      testID="UserSelection:groupTypeRadioGroup"
      size={ListItemSizeScalingEnum.SMALL}
      customStyles={{ marginBottom: 16 }}
      options={filteredOptions}
    />
  );
};

export default GroupFilter;
