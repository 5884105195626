import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect';

const maritalStatusesList = [
  { label: 'Married', value: 1 },
  { label: 'Divorced', value: 2 },
  { label: 'Single', value: 3 },
  { label: 'Separated', value: 4 },
  { label: 'Unknown', value: 5 },
];

interface TWInputSelectMaritalStatusProps extends Omit<TWInputSelectProps, 'onChange'> {
  onChange?: (value?: number) => void;
  isDisabled?: boolean;
  populateOnMount?: boolean;
  popupContainerId?: string;
  placeholder?: string;
  testID?: string;
  skip?: boolean;
}

const TWInputSelectMaritalStatus = (props: TWInputSelectMaritalStatusProps) => {
  const {
    popupContainerId = '',
    onBlur,
    placeholder,
    onChange,
    isDisabled = false,
    testID,
    allowClear = true,
    ...allUnrecognizedProps
  } = props;

  return (
    <TWInputSelect
      onBlur={onBlur}
      allowClear={allowClear}
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      disabled={isDisabled}
      itemList={maritalStatusesList}
      onChange={(selectedStatus: number) => {
        onChange?.(selectedStatus);
      }}
      popupContainerId={popupContainerId}
      testID={testID}
      {...allUnrecognizedProps}
    />
  );
};

export default TWInputSelectMaritalStatus;
