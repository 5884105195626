import * as React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { relayUtils } from '@tw/util';
import { useViewer } from '@tw/hooks';
import { GET_ATHLETE_OPTIONS } from '@tw/modules/Profiles/AddProfileWizard/@graphql';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect';

interface TWInputSelectAthletePositionProps extends TWInputSelectProps {
  useGlobalId?: boolean;
  selectedTeamId?: string;
}

const TWInputSelectAthletePositions: React.FC<TWInputSelectAthletePositionProps> = ({
  useGlobalId = true,
  selectedTeamId,
  ...rest
}) => {
  const { currentTeam } = useViewer();
  const { loading, data } = useQuery(GET_ATHLETE_OPTIONS, {
    variables: { teamId: selectedTeamId ?? currentTeam.id },
  });

  const positions = _.map(data?.sportPositions, (position) => ({
    ...position,
    value: useGlobalId ? position.value : Number(relayUtils.fromGlobalId(position.value)),
  }));

  return (
    <TWInputSelect
      isMultiSelect
      allowClear
      filterOption
      optionFilterProp="title"
      itemList={positions}
      minWidth="200px"
      loading={loading}
      {...rest}
    />
  );
};

export default TWInputSelectAthletePositions;
