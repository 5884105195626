import { ThemeProp } from '../styled';
import { CustomStyles } from '../../presentational/definitions';

import {
  themedFontSize,
  themedFontWeight,
  themedMargin,
  themedPadding,
  themedTextColors,
  ThemeFontSizeProps,
  ThemeFontWeightProps,
  ThemeMarginProps,
  ThemePaddingProps,
  ThemeTextColorProps,
  truncatedText,
  TruncateProps,
  wrappingText,
  WrappingProps,
} from './themedFunctions';

export type TypographyComponentProps = ThemeMarginProps &
  ThemePaddingProps &
  ThemeFontSizeProps &
  ThemeFontWeightProps &
  ThemeTextColorProps &
  TruncateProps &
  WrappingProps &
  CustomStyles;

export const sharedTypographyStyles = ({
  theme,
  customStyles,
}: ThemeProp & TypographyComponentProps) => [
  themedMargin,
  themedPadding,
  themedFontSize,
  themedFontWeight,
  themedTextColors,
  truncatedText,
  wrappingText,
  {
    fontFamily: theme.fontFamily.default,
  },
  customStyles,
];
