/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideosByFolderQueryVariables = Types.Exact<{
  folderId?: Types.InputMaybe<Types.Scalars['TWFilter_Int']>;
}>;

export type VideosByFolderQuery = {
  myVideos?: {
    edges: Array<{
      node?: {
        wistiaVideoUuid?: string | null;
        title: string;
        description?: string | null;
        folderId?: number | null;
        isPinned: boolean;
        playCount?: number | null;
        downloadCount?: number | null;
        wistiaThumbnailUrl?: string | null;
        wistiaOriginalFileUrl?: string | null;
        updatedDate?: any | null;
        hasActiveShareLink?: boolean | null;
        isArchived?: boolean | null;
        canDownload?: boolean | null;
        videoId?: string | null;
        creator?: {
          createdBy?: number | null;
          pictureDownloadUrl?: string | null;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export const VideosByFolderDocument = gql`
  query VideosByFolder($folderId: TWFilter_Int) {
    myVideos(folderId: $folderId) {
      edges {
        node {
          videoId: pk
          wistiaVideoUuid
          title
          description
          folderId
          isPinned
          playCount
          downloadCount
          wistiaThumbnailUrl
          wistiaOriginalFileUrl
          updatedDate
          hasActiveShareLink
          isArchived
          canDownload
          creator {
            createdBy
            pictureDownloadUrl
            preferredName
            lastName
            initials
          }
        }
      }
    }
  }
`;

/**
 * __useVideosByFolderQuery__
 *
 * To run a query within a React component, call `useVideosByFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosByFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosByFolderQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useVideosByFolderQuery(
  baseOptions?: Apollo.QueryHookOptions<VideosByFolderQuery, VideosByFolderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VideosByFolderQuery, VideosByFolderQueryVariables>(
    VideosByFolderDocument,
    options,
  );
}
export function useVideosByFolderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VideosByFolderQuery, VideosByFolderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VideosByFolderQuery, VideosByFolderQueryVariables>(
    VideosByFolderDocument,
    options,
  );
}
export type VideosByFolderQueryHookResult = ReturnType<typeof useVideosByFolderQuery>;
export type VideosByFolderLazyQueryHookResult = ReturnType<typeof useVideosByFolderLazyQuery>;
export type VideosByFolderQueryResult = Apollo.QueryResult<
  VideosByFolderQuery,
  VideosByFolderQueryVariables
>;
