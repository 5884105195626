/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { Layout as Container } from 'antd';
import { SiderProps } from 'antd/lib/layout';

const Sider = (props: Omit<SiderProps, 'width'>) => <Container.Sider width={250} {...props} />;

const Header = styled(Container.Header)(({ theme }) => ({
  alignItems: 'center',
  background: theme.colors.white,
  boxShadow: theme.shadows.border,
  display: 'flex',
  height: theme.baseUnit * 12,
  justifyContent: 'space-between',
  padding: `${theme.baseUnit * 3.5}px ${theme.baseUnit * 3}px`,
}));

const Content = styled(Container.Content)(({ theme }) => ({
  padding: theme.baseUnit * 3,
}));

export default {
  Container,
  ...Container,
  Content,
  Header,
  Sider,
};
