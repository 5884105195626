import TWIcon, { TWIconProps } from '../../TWIcon';
import { DrawerDetailIconWrapper, DrawerDetailItemRow } from './TWDrawerDetailItem.styles';

interface TWDrawerDetailItemProps {
  iconTitle: string;
  iconProps?: Omit<TWIconProps, 'ref'>;
  text: React.ReactNode;
  testID?: string;
  align?: 'top' | 'middle' | 'bottom' | 'stretch';
}

const TWDrawerDetailItem = ({
  iconTitle,
  iconProps,
  text,
  testID = '',
  align = 'top',
}: TWDrawerDetailItemProps) => (
  <DrawerDetailItemRow gutter={16} align={align}>
    <DrawerDetailIconWrapper data-testid={`${testID}Item:Icon`}>
      <TWIcon type={iconTitle} {...iconProps} />
    </DrawerDetailIconWrapper>
    <DrawerDetailIconWrapper span={21} data-testid={`${testID}Item:Value`}>
      {text}
    </DrawerDetailIconWrapper>
  </DrawerDetailItemRow>
);

export default TWDrawerDetailItem;
