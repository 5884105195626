import _ from 'lodash';
import fastMemoize from 'fast-memoize';

import { emptyArray, emptyObject } from '@tw/constants';

/**
 * This returns a new object that's just the original object without any null|undefined values.
 * It's sort of a weird hybrid between map and filter -- a recursive filtered map, basically.
 *
 * @param object
 */
const cleanNullValuesFromObject = <T extends object>(object: T): T => {
  const cleanedObject = _.transform(
    object,
    (result: T, value, key) => {
      let newValue;
      if (_.isPlainObject(value)) {
        // recurse!
        newValue = cleanNullValuesFromObject(value as object);
        if (_.isEmpty(newValue)) {
          // Whoops, there's nothing in there.
          // @TODO: This "purge empty nested objects" behavior has been here forever, but it might be
          //        unnecessary or undesirable. Might be worth researching post-GA.
          newValue = null;
        }
      } else {
        // Nothing to do!
        newValue = value;
      }
      if (newValue != null) {
        result[key] = newValue as T[keyof T];
      }
    },
    {},
  );

  // Purely as an optimization to avoid creating new objects, since they may end up in selectors,
  // if we end up with an empty object then we'll return the exact same reference every time.
  if (_.isEmpty(cleanedObject)) {
    return emptyObject as T;
  }
  return cleanedObject as T;
};

const trimStringValuesInObject = (object) =>
  _.transform(
    object,
    (result, value, key) => {
      let newValue;
      if (_.isPlainObject(value)) {
        // recurse!
        newValue = trimStringValuesInObject(value);
      } else {
        // trim string values, don't change other types of values
        newValue = _.isString(value) ? value.trim() : value;
      }
      result[key] = newValue; // eslint-disable-line no-param-reassign
    },
    {},
  );

const getValueOrDefault = (lookupMapping, key, defaultKey = 'default') => {
  if (typeof lookupMapping !== 'object') {
    console.error(`lookupMapping argument is not an object: ${lookupMapping}`);
  }
  if (!lookupMapping[defaultKey]) {
    console.error(
      `
      You forgot to set a default to the lookupMapping or
      provide a defaultKey that matches any key in the lookup mapping!
    `,
      { lookupMapping, key, defaultKey },
    );
  }
  return _.get(lookupMapping, key, lookupMapping[defaultKey]);
};

const makeArrayFromCode = fastMemoize((selectionCode) => {
  if (!selectionCode) {
    return emptyArray;
  }
  return [selectionCode];
});

const makeSortedKeyForArray = (array) => {
  if (!array || !_.isArray(array)) {
    console.warn('makeSortedKeyForArray received invalid input', array);
    return array;
  }

  const sortedArray = _.sortBy(array);

  return sortedArray.join(',');
};

const willCreateStaticObject = () => {
  let staticObject;
  return (newObject) => {
    if (!staticObject) {
      staticObject = newObject;
      return staticObject;
    }

    if (newObject === staticObject || _.isEqual(staticObject, newObject)) {
      return staticObject;
    }

    staticObject = newObject;
    return staticObject;
  };
};

export default {
  getValueOrDefault,
  makeArrayFromCode,
  makeSortedKeyForArray,
  cleanNullValuesFromObject,
  trimStringValuesInObject,
  willCreateStaticObject,
};
