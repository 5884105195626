import { QueryHookOptions, useQuery } from '@apollo/client';

import {
  StudentTermPaginatedRawData,
  StudentTermQueryVariables,
  StudentTermRawQueryVariables,
} from '@tw/types';
import {
  formatStudentTermPaginatedData,
  formatStudentTermVariables,
} from '@tw/services/academicService';

import { FETCH_STUDENT_TERMS } from './FetchStudentTerms.query';

export const useStudentTerms = (
  variables?: StudentTermQueryVariables,
  options?: QueryHookOptions<StudentTermPaginatedRawData, StudentTermRawQueryVariables>,
) => {
  const { data, ...rest } = useQuery<StudentTermPaginatedRawData, StudentTermRawQueryVariables>(
    FETCH_STUDENT_TERMS,
    {
      variables: formatStudentTermVariables(variables),
      ...options,
    },
  );
  return {
    data: formatStudentTermPaginatedData(data),
    ...rest,
  };
};
