const $twIcon = {
  color: '$colorHue.graySilver',
};

const $twIconInverse = {
  ...$twIcon,
  color: '$colorHue.white',
};

export { $twIcon, $twIconInverse };
