/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAllVideoFoldersForPersonQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchAllVideoFoldersForPersonQuery = {
  folders?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        folderId: string;
        parentFolderId?: number | null;
        folderType?: string | null;
        folderName?: string | null;
        sortOrder?: number | null;
        team?: { id: string; label: string; teamId: string; active: boolean } | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchAllVideoFoldersForPersonDocument = gql`
  query FetchAllVideoFoldersForPerson {
    folders(folderType: "video") {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          folderId
          parentFolderId
          folderType
          folderName
          sortOrder
          team {
            id
            label
            teamId
            active
          }
        }
      }
    }
  }
`;

/**
 * __useFetchAllVideoFoldersForPersonQuery__
 *
 * To run a query within a React component, call `useFetchAllVideoFoldersForPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllVideoFoldersForPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllVideoFoldersForPersonQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllVideoFoldersForPersonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllVideoFoldersForPersonQuery,
    FetchAllVideoFoldersForPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchAllVideoFoldersForPersonQuery,
    FetchAllVideoFoldersForPersonQueryVariables
  >(FetchAllVideoFoldersForPersonDocument, options);
}
export function useFetchAllVideoFoldersForPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllVideoFoldersForPersonQuery,
    FetchAllVideoFoldersForPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchAllVideoFoldersForPersonQuery,
    FetchAllVideoFoldersForPersonQueryVariables
  >(FetchAllVideoFoldersForPersonDocument, options);
}
export type FetchAllVideoFoldersForPersonQueryHookResult = ReturnType<
  typeof useFetchAllVideoFoldersForPersonQuery
>;
export type FetchAllVideoFoldersForPersonLazyQueryHookResult = ReturnType<
  typeof useFetchAllVideoFoldersForPersonLazyQuery
>;
export type FetchAllVideoFoldersForPersonQueryResult = Apollo.QueryResult<
  FetchAllVideoFoldersForPersonQuery,
  FetchAllVideoFoldersForPersonQueryVariables
>;
