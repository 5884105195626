/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilesFolderUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  folderName: Types.Scalars['String'];
}>;

export type FilesFolderUpdateMutation = {
  folderUpdate: {
    folder?: {
      orgId: number;
      teamId?: number | null;
      parentFolderId?: number | null;
      folderName?: string | null;
      folderType?: string | null;
      sortOrder?: number | null;
      folderId?: number | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const FilesFolderUpdateDocument = gql`
  mutation FilesFolderUpdate($id: ID!, $folderName: String!) {
    folderUpdate(id: $id, input: { folderName: $folderName }) {
      folder {
        orgId
        teamId
        folderId: pk
        parentFolderId
        folderName
        folderType
        sortOrder
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FilesFolderUpdateMutationFn = Apollo.MutationFunction<
  FilesFolderUpdateMutation,
  FilesFolderUpdateMutationVariables
>;

/**
 * __useFilesFolderUpdateMutation__
 *
 * To run a mutation, you first call `useFilesFolderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFilesFolderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [filesFolderUpdateMutation, { data, loading, error }] = useFilesFolderUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      folderName: // value for 'folderName'
 *   },
 * });
 */
export function useFilesFolderUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FilesFolderUpdateMutation,
    FilesFolderUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FilesFolderUpdateMutation, FilesFolderUpdateMutationVariables>(
    FilesFolderUpdateDocument,
    options,
  );
}
export type FilesFolderUpdateMutationHookResult = ReturnType<typeof useFilesFolderUpdateMutation>;
export type FilesFolderUpdateMutationResult = Apollo.MutationResult<FilesFolderUpdateMutation>;
export type FilesFolderUpdateMutationOptions = Apollo.BaseMutationOptions<
  FilesFolderUpdateMutation,
  FilesFolderUpdateMutationVariables
>;
