/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-nested-ternary */
import type { Row as RowType } from '@tanstack/react-table';
import { memo } from 'react';
import { Tr } from '../../DataTable.styles';
import { EditableTableCellComponent, Position } from '../../DataTable.definitions';
import Cell from '../Cell';

type RowProps<T> = {
  editableComponents?: Record<string, EditableTableCellComponent<T> | object>;
  editedCells?: string[];
  isEditing: boolean;
  isEditMode: boolean;
  isFrozenColumn?: boolean;
  isRowSelected?: boolean;
  pinnedColumns?: number;
  selectedCell: Position;
  row: RowType<T>;
  rowIsClickable?: boolean;
  testId?: string;
  withColumnOrdering: boolean;
  onCellClick: (row: number, column: number) => void;
  onCellUpdate: (row: number, columnId: string) => (value: unknown) => void;
  onRowClick?: () => void;
  handleHover: (rowId: string | number, isHover: boolean) => void;
  hoverClass: string;
};

const Row = <T extends object>({
  editableComponents,
  editedCells,
  isEditing,
  isEditMode,
  isFrozenColumn = false,
  isRowSelected = false,
  pinnedColumns = 0,
  row,
  rowIsClickable = false,
  selectedCell,
  testId = 'DataTable:Row',
  withColumnOrdering,
  onCellClick,
  onCellUpdate,
  onRowClick,
  handleHover,
  hoverClass,
}: RowProps<T>) => {
  const getRowDataSource = () => {
    if (isFrozenColumn) {
      return row.getLeftVisibleCells();
    }
    if (withColumnOrdering) {
      return row.getCenterVisibleCells();
    }
    return row.getVisibleCells();
  };

  const handleCellClick = (rowIndex: number, columnIndex: number) =>
    isEditMode
      ? () => onCellClick(rowIndex, isFrozenColumn ? columnIndex - 1 : columnIndex + pinnedColumns)
      : undefined;

  return (
    <Tr
      key={row.id}
      isClickable={rowIsClickable}
      isEditMode={isEditMode}
      onClick={onRowClick}
      data-testid={testId}
      onMouseEnter={() => handleHover(row.id, true)}
      onMouseLeave={() => handleHover(row.id, false)}
      className={hoverClass}
    >
      {getRowDataSource().map((cell, columnIndex) => {
        const isSelected = isFrozenColumn
          ? selectedCell?.row === cell.row.index && selectedCell?.column === columnIndex - 1
          : selectedCell?.row === cell.row.index &&
            selectedCell?.column === columnIndex + pinnedColumns;

        return (
          <Cell<T>
            key={cell.id}
            cell={cell}
            columnIndex={columnIndex}
            // @ts-ignore
            editableComponents={editableComponents}
            editedCells={editedCells}
            isRowSelected={isRowSelected}
            isSelected={isSelected}
            isEditing={isEditing}
            isEditMode={isEditMode}
            onClick={handleCellClick(cell.row.index, columnIndex)}
            onUpdate={isEditMode ? onCellUpdate(cell.row.index, cell.column.id) : undefined}
          />
        );
      })}
    </Tr>
  );
};

const MemoizedRow = memo(
  Row,
  (prev, next) =>
    next.isRowSelected === prev.isRowSelected &&
    next.selectedCell?.row === prev.row.index &&
    next.row.index === prev.selectedCell?.row &&
    next.selectedCell?.column === prev.selectedCell?.column &&
    next.isEditing === prev.isEditing &&
    next.isEditMode === prev.isEditMode,
) as typeof Row;

export default MemoizedRow;
