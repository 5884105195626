import _ from 'lodash';

import {
  ConfigContainerType,
  StudyHallExportFilters,
  StudyHallExportRawFilters,
  StudyHallExportTypes,
} from '@tw/types';
import { pythonApiBackend } from '@tw/backends';
import { graphqlUtils } from '@tw/util';

const formatArrayIncludesQuery = (toInclude: string[] | number[]): string => {
  const query = graphqlUtils.createArrayIncludesQuery(toInclude);
  return _.isNumber(query) ? String(query) : query;
};

const formatReportFilters = (filters: StudyHallExportFilters): StudyHallExportRawFilters => {
  const { advisorIds, dateRange, isAtRisk, studentIds, searchString, teamIds, termIds } = filters;

  return {
    advisorIds:
      advisorIds && !_.isEmpty(_.castArray(advisorIds))
        ? formatArrayIncludesQuery(advisorIds)
        : undefined,
    dateRange: graphqlUtils.createRangeColumnBetweenQuery(dateRange),
    isAtRisk: !_.isNil(isAtRisk) ? graphqlUtils.createBooleanQuery(isAtRisk) : undefined,
    studentIds:
      studentIds && !_.isEmpty(_.castArray(studentIds))
        ? formatArrayIncludesQuery(studentIds)
        : undefined,
    studentName: searchString ? graphqlUtils.createStringContainsQuery(searchString) : undefined,
    teamIds:
      teamIds && !_.isEmpty(_.castArray(teamIds)) ? formatArrayIncludesQuery(teamIds) : undefined,
    termIds:
      termIds && !_.isEmpty(_.castArray(termIds)) ? formatArrayIncludesQuery(termIds) : undefined,
  };
};

export const fetchStudyHallReport = async (
  type: StudyHallExportTypes,
  filters: StudyHallExportFilters,
  personId: number,
  teamId: number,
  configContainer: ConfigContainerType,
): Promise<{ responseJson?: string }> => {
  const body = {
    contentType: 'csv',
    filters: formatReportFilters(filters),
  };

  return pythonApiBackend.doFetch(
    {
      ctx: 'study_hall',
      model: type === StudyHallExportTypes.Aggregated ? 'reports/summary' : 'reports/detail',
      debugLabel:
        type === StudyHallExportTypes.Aggregated
          ? 'fetchStudyHallSummaryReport'
          : 'fetchStudyHallDetailReport',
      includeAuthenticationHeader: true,
      desiredFormat: 'text',
      personId,
      teamId,
    },
    {
      method: 'POST',
      body,
    },
    configContainer,
  );
};
