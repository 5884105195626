/* eslint-disable no-nested-ternary */
import {
  ColumnDef,
  ColumnOrderState,
  ColumnPinningState,
  ColumnSort,
  RowSelectionState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import moment, { Moment } from 'moment';
import {
  KeyboardEvent,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInterval } from 'react-use';

import { TWIcon, TWLoadingSpinner } from '@tw/components';
import { TranslationKey, getTranslation } from '@tw/i18n';
import { numberUtils } from '@tw/util';

import {
  CursorPagination as CursorPaginationType,
  EditableTableCellComponent,
  Position,
  TableActionButton,
  TableHeaderFilter,
} from './DataTable.definitions';
import {
  Container,
  Count,
  CountContainer,
  Grid,
  HeaderCell,
  LastUpdate,
  LoadingContainer,
  SelectedRowsStatus,
  SortIcon,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  UpdateCount,
  UpdatesSummary,
} from './DataTable.styles';
import {
  CursorPagination,
  EmptyState,
  IndeterminateCheckbox,
  Row,
  TableHeader,
} from './components';
import { useEscape } from './useEscape';
import { useSort } from './useSort';

const { clip } = numberUtils;

interface DataTableProps<T extends object> {
  actions?: TableActionButton<T>[];
  columns: ColumnDef<T>[];
  cursorPagination?: CursorPaginationType;
  data: T[];
  dataTableRef: RefObject<unknown>;
  defaultColumnPinning?: ColumnPinningState;
  defaultColumnOrder?: ColumnOrderState;
  defaultColumnSort?: ColumnSort[];
  defaultColumnVisibility?: Record<string, boolean>;
  editableComponents?: Record<string, EditableTableCellComponent<T>>;
  headerFilters?: TableHeaderFilter[];
  headerLabel?: string;
  keepSelection?: boolean;
  loading?: boolean;
  noDataComponent?: JSX.Element;
  rowLabel?: string;
  sortableColumns?: string[];
  testId?: string;
  withColumnOrdering?: boolean;
  withInlineEditing?: boolean;
  withSelectableRows?: boolean;
  withSingleSort?: boolean;
  onRowClick?: (row: T) => void;
  onSelectedRowsChange?: (selectedRows: T[]) => void;
  onSortChange?: (sort: ColumnSort[]) => void;
  renderLeftActions?: () => ReactNode;
  renderRightActions?: () => ReactNode;
  editModeChange?: (editMode: boolean) => void;
}

const DataTable = <T extends object>({
  actions = [],
  data,
  columns,
  cursorPagination,
  dataTableRef,
  defaultColumnOrder,
  defaultColumnPinning,
  defaultColumnVisibility,
  defaultColumnSort = [],
  editableComponents,
  headerLabel,
  headerFilters,
  keepSelection = false,
  loading = false,
  noDataComponent,
  rowLabel,
  sortableColumns = [],
  testId = 'DataTable',
  withColumnOrdering = false,
  withInlineEditing = false,
  withSelectableRows = false,
  withSingleSort = false,
  onSelectedRowsChange,
  onRowClick,
  onSortChange,
  renderLeftActions,
  renderRightActions,
  editModeChange,
}: DataTableProps<T>) => {
  /* Internal Data */
  const [internalData, setInternalData] = useState<T[]>(data);

  /* Row Selection */
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  /* Column Visibility */
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean> | undefined>(
    defaultColumnVisibility,
  );
  useEffect(() => setColumnVisibility(defaultColumnVisibility), [defaultColumnVisibility]);

  /* Column Ordering */
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState | undefined>(defaultColumnOrder);
  useEffect(() => setColumnOrder(defaultColumnOrder), [defaultColumnOrder]);

  /* Column Pinning */
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>(
    defaultColumnPinning ?? {},
  );
  useEffect(() => {
    if (defaultColumnPinning) {
      setColumnPinning(defaultColumnPinning);
    }
  }, [defaultColumnPinning]);

  useEffect(() => {
    setInternalData(data);
    if (!keepSelection) {
      setRowSelection({});
    }
  }, [data, keepSelection]);

  useImperativeHandle(dataTableRef, () => ({
    resetSelection: () => {
      setRowSelection({});
    },
  }));

  // the key is the row index, the value is an array of edited column ids
  const [editedCells, setEditedCells] = useState<Record<number, string[]>>({});
  const editedCellsCount = Object.values(editedCells).reduce((acc, val) => acc + val.length, 0);
  const [lastUpdated, setLastUpdated] = useState<Moment>();
  const [lastUpdatedText, setLastUpdatedText] = useState<string>('');

  const { isSortable, isSorted, toggleSort } = useSort({
    defaultColumnSort,
    sortableColumns,
    withSingleSort,
    onSortChange,
  });

  const internalColumns = useMemo(
    () => (withSelectableRows ? getRowSelectionColumn<T>().concat(columns) : columns),
    [columns, withSelectableRows],
  );

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const table = useReactTable({
    data: internalData,
    columns: internalColumns,
    state: {
      columnVisibility,
      columnOrder,
      columnPinning,
      rowSelection,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      // These are not part of the standard API, but are accessible via table.options.meta
      editableComponents,
      updateData: (rowIndex, columnId, value) => {
        const tableColumns = table.getAllLeafColumns();
        const column = tableColumns.find((col) => col.id === columnId);

        const accessorKey =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (column?.columnDef?.accessorKey as string) || column?.columnDef?.meta?.dataAccessor;

        if (!accessorKey) {
          console.error('Tried to update a table column without an meta.dataAccessor');
          return;
        }

        setInternalData((previousData) =>
          previousData.map((row, index) => {
            if (index === rowIndex) {
              if (accessorKey.includes('.') && !(accessorKey in row)) {
                const [objectKey, nestedKey] = accessorKey.split('.');
                return {
                  ...row,
                  [objectKey]: {
                    ...row[objectKey as keyof T],
                    [nestedKey]: value,
                  },
                };
              }
              return {
                ...row,
                [accessorKey]: value,
              };
            }
            return row;
          }),
        );

        setEditedCells((previousEditedCells) => ({
          ...previousEditedCells,
          [rowIndex]:
            rowIndex in previousEditedCells
              ? previousEditedCells[rowIndex].concat(columnId)
              : [columnId],
        }));
        setLastUpdated(moment());
        setLastUpdatedText(moment().fromNow());
      },
    },
  });

  /* Row Selection computed */
  const selectedRows = withSelectableRows
    ? table.getSelectedRowModel().flatRows.map((row) => row.original)
    : [];

  useEffect(() => {
    if (typeof onSelectedRowsChange === 'function') {
      onSelectedRowsChange(selectedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  const [editMode, setEditMode] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCell, setSelectedCell] = useState<Position>(null);

  useEffect(() => {
    if (editModeChange) {
      editModeChange(editMode);
    }
  }, [editMode, editModeChange]);

  const focusOnSelectedCell = useCallback(() => {
    if (selectedCell == null) return;
    const cell = tableContainerRef.current?.querySelector(
      `[data-row="${selectedCell.row}"][data-column="${selectedCell.column}"]`,
    ) as HTMLDivElement;
    if (cell) cell.focus();
  }, [selectedCell, tableContainerRef]);

  useEscape(() => {
    setIsEditing(false);
    focusOnSelectedCell();
  });

  useInterval(
    () => {
      if (lastUpdated) {
        setLastUpdatedText(lastUpdated?.fromNow());
      }
    },
    !!lastUpdated && editMode ? 10000 : null,
  );

  const [hoverRowId, setHoverRowId] = useState<string | number | null>(null);

  const handleHover = (rowId: string | number, isHover: boolean) => {
    setHoverRowId(isHover && !editMode ? rowId : null);
  };

  const onSelectedCellChange = useCallback(
    (position: Position) => {
      if (
        selectedCell == null ||
        position == null ||
        selectedCell.row !== position?.row ||
        selectedCell.column !== position.column
      )
        setSelectedCell(position);
    },
    [selectedCell],
  );

  const isColumnEditable = useCallback(
    (selectedColumn: number) => {
      if (!withInlineEditing) return false;

      const tableColumns = [
        ...table.getLeftVisibleLeafColumns(),
        ...table.getCenterVisibleLeafColumns(),
      ];

      const column = tableColumns[withSelectableRows ? selectedColumn + 1 : selectedColumn];
      if (!column) return false;

      const columnId = column.columnDef.id;
      return columnId && editableComponents && columnId in editableComponents;
    },
    [table, editableComponents, withInlineEditing, withSelectableRows],
  );

  const onCellClick = useCallback(
    (row: number, column: number) => {
      // ignore row select checkbox column
      if (
        selectedCell?.row === row &&
        selectedCell?.column === column &&
        isColumnEditable(column)
      ) {
        setIsEditing(true);
        return;
      }
      // ignore row select checkbox column
      if (column === -1) return;
      setIsEditing(false);
      onSelectedCellChange({ row, column });
    },
    [selectedCell, isColumnEditable, onSelectedCellChange],
  );

  const onCellEditUpdate = useCallback(
    (rowIndex: number, columnId: string) => (value: unknown) =>
      table.options.meta?.updateData
        ? table.options.meta?.updateData(rowIndex, columnId, value)
        : undefined,
    [table],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (!selectedCell) return;

      const { code, shiftKey } = event;

      const commandCodes: {
        [key: string]: [number, number];
      } = {
        Tab: [0, 1],
        Enter: [1, 0],
      };

      const navigationCodes: {
        [key: string]: [number, number];
      } = {
        ArrowRight: [0, 1],
        ArrowLeft: [0, -1],
        ArrowDown: [1, 0],
        ArrowUp: [-1, 0],
      };

      const lastRow = table.getRowModel().rows.length - 1;
      const lastColumn = table.getVisibleLeafColumns().length - 1 - (withSelectableRows ? 1 : 0);

      const navigate = (delta: [number, number], tabWrap = false): [number, number] => {
        const x0 = selectedCell?.column || 0;
        const y0 = selectedCell?.row || 0;

        let x1 = x0 + delta[1];
        let y1 = y0 + delta[0];

        if (tabWrap) {
          if (delta[1] > 0) {
            // wrap to the next row if we're on the last column
            if (x1 > lastColumn) {
              x1 = 0;
              y1 += 1;
            }
            // don't wrap to the next row if we're on the last row
            if (y1 > lastRow) {
              x1 = x0;
              y1 = y0;
            }
          } else {
            // reverse tab wrap
            if (x1 < 0) {
              x1 = lastColumn;
              y1 -= 1;
            }

            if (y1 < 0) {
              x1 = x0;
              y1 = y0;
            }
          }
        } else {
          x1 = clip(x1, 0, lastColumn);
        }

        y1 = clip(y1, 0, lastRow);

        return [x1, y1];
      };

      if (code in commandCodes) {
        event.preventDefault();

        if (!isEditing && code === 'Enter' && !shiftKey && isColumnEditable(selectedCell.column)) {
          setIsEditing(true);
          return;
        }

        let direction = commandCodes[code];
        if (shiftKey) direction = [-direction[0], -direction[1]];
        const [x1, y1] = navigate(direction, code === 'Tab');
        setSelectedCell({
          row: y1,
          column: x1,
        });
        if (isEditing) {
          setIsEditing(false);
        }
      } else if (code in navigationCodes) {
        // arrow key navigation should't work if we're editing
        if (isEditing) return;
        event.preventDefault();
        const [x1, y1] = navigate(navigationCodes[code], code === 'Tab');
        setIsEditing(false);
        setSelectedCell({
          row: y1,
          column: x1,
        });
        // any other key (besides shift) activates editing
        // if the column is editable and a cell is selected
      } else if (
        !['ShiftLeft', 'ShiftRight'].includes(code) &&
        !isEditing &&
        selectedCell &&
        isColumnEditable(selectedCell.column)
      ) {
        setIsEditing(true);
      }
    },
    [isColumnEditable, isEditing, selectedCell, setSelectedCell, table, withSelectableRows],
  );

  // reset the selected cell when the table data changes
  useEffect(() => {
    setSelectedCell(null);
    setEditedCells({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editMode,
    columnVisibility,
    columnOrder,
    cursorPagination?.nextCursor,
    cursorPagination?.rowsPerPage,
  ]);

  const { rows } = table.getRowModel();
  const rowsAreClickable = typeof onRowClick === 'function';

  /* Visible Header Rows */
  const visibleHeaderGroups = table
    .getHeaderGroups()
    .reduce<number[]>((acc, headerGroup, index) => {
      const isHeaderGroupVisible = headerGroup.headers.some((header) => !header.isPlaceholder);

      if (isHeaderGroupVisible) {
        acc.push(index);
      }

      return acc;
    }, []);

  const generateRows = (isFrozenColumn?: boolean) => (
    <>
      {rows.map((row, index) => (
        <Row
          key={row.id}
          row={row}
          testId={`${testId}:Table:Row_${index}`}
          editableComponents={editableComponents}
          editedCells={editedCells[row.index]}
          isEditing={isEditing}
          isEditMode={editMode}
          isFrozenColumn={isFrozenColumn}
          isRowSelected={row.index in rowSelection && !!rowSelection[row.index]}
          pinnedColumns={
            columnPinning?.left ? columnPinning.left?.length - (withSelectableRows ? 1 : 0) : 0
          }
          selectedCell={selectedCell}
          withColumnOrdering={withColumnOrdering}
          onCellClick={onCellClick}
          onCellUpdate={onCellEditUpdate}
          onRowClick={rowsAreClickable ? () => onRowClick(row.original) : undefined}
          handleHover={handleHover}
          hoverClass={row.id === hoverRowId ? 'data-table-row-hover' : ''}
        />
      ))}
    </>
  );
  return (
    <Container>
      {cursorPagination && headerLabel && (
        <CountContainer isEditMode={editMode}>
          <Count data-testid={`${testId}:HeaderCount`}>
            {cursorPagination.count > 0 ? `${cursorPagination.count} ${headerLabel}` : ''}
          </Count>
          {editMode && (
            <UpdatesSummary>
              {lastUpdatedText && (
                <LastUpdate>
                  {getTranslation('lastUpdatedDate', {
                    date: lastUpdatedText,
                  })}
                </LastUpdate>
              )}
              {selectedRows.length > 0 && (
                <UpdateCount>
                  <strong>
                    <TWIcon type="material-person" />
                    <span>{selectedRows.length}</span>
                  </strong>
                  <p>{getTranslation('usersSelected', selectedRows.length)}</p>
                </UpdateCount>
              )}
              <UpdateCount>
                <strong>
                  <TWIcon type="material-track_changes" />
                  <span>{editedCellsCount}</span>
                </strong>
                <p>{getTranslation('updatesMade', editedCellsCount)}</p>
              </UpdateCount>
            </UpdatesSummary>
          )}
        </CountContainer>
      )}
      {(actions.length > 0 ||
        headerFilters ||
        renderLeftActions ||
        renderRightActions ||
        withInlineEditing) && (
        <TableHeader
          actions={actions}
          editMode={editMode}
          headerFilters={headerFilters}
          renderLeftActions={renderLeftActions}
          renderRightActions={renderRightActions}
          selectedRows={selectedRows}
          setEditMode={setEditMode}
          testId={testId}
          withInlineEditing={withInlineEditing}
        />
      )}
      {withSelectableRows && selectedRows.length > 0 && (
        <SelectedRowsStatus>
          {selectedRows.length === rows.length
            ? getTranslation('table.allRowsSelected', {
                smart_count: selectedRows.length,
                item: getTranslation((rowLabel ?? 'table.row') as TranslationKey, {
                  smart_count: selectedRows.length,
                }).toLocaleLowerCase(),
              })
            : getTranslation('table.multipleRowsSelected', {
                smart_count: selectedRows.length,
                item: getTranslation((rowLabel ?? 'table.row') as TranslationKey, {
                  smart_count: selectedRows.length,
                }).toLocaleLowerCase(),
              })}{' '}
          {getTranslation('table.rowsSelected', {
            item: getTranslation((rowLabel ?? 'table.row') as TranslationKey, {
              smart_count: 2,
            }).toLocaleLowerCase(),
          })}
        </SelectedRowsStatus>
      )}
      <TableContainer
        data-testid={`${testId}:Table`}
        ref={tableContainerRef}
        onKeyDown={editMode ? onKeyDown : undefined}
      >
        {loading && (
          <LoadingContainer>
            <TWLoadingSpinner />
          </LoadingContainer>
        )}
        <Grid withColumnOrdering={withColumnOrdering}>
          {withColumnOrdering && (
            <Table
              data-testid="Profiles:PeopleTable:Columns:pinnedColumns"
              isPinned
              className="pinnedColumns"
            >
              <Thead>
                {!loading &&
                  table.getLeftHeaderGroups().map((headerGroup, headerGroupIndex) => {
                    const isHeaderGroupVisible = visibleHeaderGroups.includes(headerGroupIndex);

                    return (
                      isHeaderGroupVisible && (
                        <Tr key={headerGroup.id} isHeader>
                          {headerGroup.headers.map((header) => {
                            const { column } = header;
                            const sorted = isSorted(header.column.id);
                            const sortable = isSortable(header.column.id);

                            let groupedClass = '';
                            if (
                              column.columns.length > 0 ||
                              (column.parent && column.parent.columns.length > 0)
                            ) {
                              groupedClass = 'data-table-group-parent';
                            }

                            return (
                              <Th
                                key={header.id}
                                colSpan={header.colSpan}
                                sortable={sortable}
                                className={groupedClass}
                                onClick={sortable ? () => toggleSort(header.column.id) : undefined}
                              >
                                {!header.isPlaceholder && (
                                  <HeaderCell>
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext(),
                                    )}

                                    {sorted ? (
                                      sorted === -1 ? (
                                        <SortIcon
                                          title={getTranslation('sort.ascending')}
                                          type="material-arrow_drop_down"
                                        />
                                      ) : (
                                        <SortIcon
                                          title={getTranslation('sort.descending')}
                                          type="material-arrow_drop_up"
                                        />
                                      )
                                    ) : null}
                                  </HeaderCell>
                                )}
                              </Th>
                            );
                          })}
                        </Tr>
                      )
                    );
                  })}
              </Thead>
              <Tbody>{generateRows(true)}</Tbody>
            </Table>
          )}

          <Table data-testid="Profiles:PeopleTable:Columns:attributeColumns">
            <Thead>
              {!loading &&
                (withColumnOrdering ? table.getCenterHeaderGroups() : table.getHeaderGroups()).map(
                  (headerGroup, headerGroupIndex) => {
                    const isHeaderGroupVisible = visibleHeaderGroups.includes(headerGroupIndex);
                    return (
                      isHeaderGroupVisible && (
                        <Tr key={headerGroup.id} isHeader>
                          {headerGroup.headers.map((header) => {
                            const { column } = header;
                            const sorted = isSorted(column.id);
                            const sortable = isSortable(column.id);

                            let groupedClass = '';
                            if (
                              column.columns.length > 0 ||
                              (column.parent && column.parent.columns.length > 0)
                            ) {
                              groupedClass = 'data-table-group-parent';
                            }

                            return (
                              <Th
                                key={header.id}
                                data-testid={`${testId}:Header:${header.id.replace(/\s+/g, '')}`}
                                colSpan={header.colSpan}
                                w={header.getSize()}
                                sortable={sortable}
                                className={groupedClass}
                                onClick={sortable ? () => toggleSort(column.id) : undefined}
                              >
                                {!header.isPlaceholder && (
                                  <HeaderCell>
                                    {flexRender(column.columnDef.header, header.getContext())}
                                    {sorted ? (
                                      sorted === -1 ? (
                                        <SortIcon
                                          title={getTranslation('sort.descending')}
                                          type="material-arrow_drop_down"
                                        />
                                      ) : (
                                        <SortIcon
                                          title={getTranslation('sort.ascending')}
                                          type="material-arrow_drop_up"
                                        />
                                      )
                                    ) : null}
                                  </HeaderCell>
                                )}
                              </Th>
                            );
                          })}
                        </Tr>
                      )
                    );
                  },
                )}
            </Thead>
            <Tbody>{generateRows()}</Tbody>
          </Table>
        </Grid>
        {data.length === 0 && !loading && (
          <LoadingContainer>{noDataComponent || <EmptyState testId={testId} />}</LoadingContainer>
        )}
      </TableContainer>
      {cursorPagination && (
        <CursorPagination
          pagination={cursorPagination}
          setRowSelection={setRowSelection}
          testId={testId}
        />
      )}
    </Container>
  );
};

function getRowSelectionColumn<T>(): ColumnDef<T>[] {
  return [
    {
      id: 'Select',
      size: 40,
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
            'data-testid': 'SelectAll',
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
  ];
}

export default DataTable;
