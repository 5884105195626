import { ConfigContainerType } from '@tw/types';

// DANGER! This is a HORRIBLE thing to do:
// we should NEVER EVER EVER EVER EVER keep any reference to data from
// redux, but that's exactly what this is.
let currentConfigContainerInstance;

const setConfigContainerInstance = (configContainer: ConfigContainerType) => {
  currentConfigContainerInstance = configContainer;
};

const makePartialWithConfigContainer =
  (fn) =>
  (...args) =>
    fn(currentConfigContainerInstance, ...args);

export default {
  setConfigContainerInstance,
  makePartialWithConfigContainer,
};
