import styled from '@emotion/styled';
import { sharedTypographyStyles, TypographyComponentProps } from '../../utils';

export const TWHero = styled.h1<TypographyComponentProps>(sharedTypographyStyles, {
  lineHeight: 1.33,
  letterSpacing: -0.4,
});
TWHero.defaultProps = {
  twFontSize: 8,
  twFontWeight: 'medium',
};

export const TWHeading1 = styled.h1<TypographyComponentProps>(sharedTypographyStyles, {
  lineHeight: 1.33,
  letterSpacing: -0.3,
});
TWHeading1.defaultProps = {
  twFontSize: 7,
  twFontWeight: 'regular',
};

export const TWHeading2 = styled.h2<TypographyComponentProps>(sharedTypographyStyles, {
  lineHeight: 1.39,
  letterSpacing: -0.2,
});
TWHeading2.defaultProps = {
  twFontSize: 6,
  twFontWeight: 'regular',
};

export const TWHeading3 = styled.h3<TypographyComponentProps>(sharedTypographyStyles, {
  lineHeight: 1.45,
  letterSpacing: -0.2,
});
TWHeading3.defaultProps = {
  twFontSize: 5,
  twFontWeight: 'medium',
};

export const TWHeading4 = styled.h4<TypographyComponentProps>(sharedTypographyStyles, {
  lineHeight: 1.45,
});
TWHeading4.defaultProps = {
  twFontSize: 4.5,
  twFontWeight: 'medium',
};

export const TWHeading5 = styled.h5<TypographyComponentProps>(sharedTypographyStyles, {
  lineHeight: 1.45,
});
TWHeading5.defaultProps = {
  twFontSize: 4,
  twFontWeight: 'medium',
};

export const TWHeading6 = styled.h6<TypographyComponentProps>(sharedTypographyStyles, {
  lineHeight: 1.45,
});
TWHeading6.defaultProps = {
  twFontSize: 3.5,
  twFontWeight: 'semibold',
};

export const TWTextSmall = styled.span<TypographyComponentProps>(sharedTypographyStyles, {
  lineHeight: 1.45,
});
TWTextSmall.defaultProps = {
  twFontSize: 3,
};

export const TWTextDefault = styled.span<TypographyComponentProps>(sharedTypographyStyles, {
  lineHeight: 1.45,
});
TWTextDefault.defaultProps = {
  twFontSize: 3.5,
};

export const TWTextLarge = styled.span<TypographyComponentProps>(sharedTypographyStyles, {
  letterSpacing: -0.2,
  lineHeight: 1.45,
});
TWTextLarge.defaultProps = {
  twFontSize: 4,
};
