import { datadogRum } from '@datadog/browser-rum';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Engine } from 'json-rules-engine';
import { FormSubmitData } from '../modules/Forms/QuickForm/QuickForm.definitions';
import {
  datePickerDefaultFormat,
  QuestionType,
  SchemaModel,
  SchemaType,
  TemplateStorageProps,
} from '../modules/Forms/Forms.definitions';
import {
  RulesMatches,
  RulesActions,
} from '../modules/Forms/QuickFormBuilder/components/DrawerTabs/RulesTab/RulesTab.definitions';

const removeDuplicateAnswers = (template: TemplateStorageProps) => {
  // HACK: some users use empty multiple-choice questions as section headers
  // this makes sure that multiple-choice or checkbox questions that have no options
  // are treated as if they have an option by the jsonschema validator, but the fake
  // empty question is not rendered by our components (since those don't render if `isEmptyQuestion` is true).
  const hydratedSchemaProperties = _.mapValues(template?.schema?.properties, (question) => {
    const { enum: questionEnum, items, type, uniqueItems } = question;

    if (type === SchemaType.String && questionEnum && _.isEmpty(questionEnum)) {
      return {
        ...question,
        enum: [''],
        isEmptyQuestion: true,
      };
    }

    if (type === SchemaType.Array) {
      const booleanUniqueItems = uniqueItems === 'true' ? true : uniqueItems;

      const emptyQuestionProps =
        items?.enum && _.isEmpty(items?.enum)
          ? {
              items: {
                ...question.items,
                enum: [''],
              },
              isEmptyQuestion: true,
            }
          : {};

      return {
        ...question,
        ...emptyQuestionProps,
        uniqueItems: booleanUniqueItems,
      };
    }

    return question;
  });

  const newSchemaProperties = {};

  _.forEach(hydratedSchemaProperties, (questionDetails, questionKey) => {
    const questionsEnum = _.get(questionDetails, 'enum');

    // remove duplicates for question types that have an enum attribute (checkbox and radio)
    if (questionsEnum) {
      newSchemaProperties[questionKey] = {
        ...questionDetails,
        enum: _.uniq(questionsEnum),
      };
    } else {
      // dropdown nests them in items.enum, so remove duplicates there as well
      const dropdownEnum = _.get(questionDetails, 'items.enum');

      if (dropdownEnum) {
        newSchemaProperties[questionKey] = {
          ...questionDetails,
          items: {
            ...questionDetails.items,
            enum: _.uniq(dropdownEnum),
          },
        };
      } else {
        // for questions with no enum, nothing to do
        newSchemaProperties[questionKey] = questionDetails;
      }
    }
  });

  return {
    ...template,
    schema: {
      ...template.schema,
      properties: newSchemaProperties,
    },
  };
};

const getDefaultValuesForRangeFields = (quickFormItem) => {
  const formData = {};
  const { schema, uiSchema } = quickFormItem;
  const fields = uiSchema['ui:order'];

  _.forEach(fields, (fieldName) => {
    const widget = _.get(uiSchema, [fieldName, 'ui:widget'], '');
    if (widget === QuestionType.Range) {
      formData[fieldName] = schema.properties[fieldName].minimum;
    }
  });

  return formData;
};

const RuleOperator = {
  Between: 'between',
  Contains: 'contains',
  DoesNotContain: 'doesNotContain',
  Equal: 'equal',
  GreaterThan: 'greaterThan',
  GreaterThanInclusive: 'greaterThanInclusive',
  LessThan: 'lessThan',
  LessThanInclusive: 'lessThanInclusive',
  NotBetween: 'notBetween',
  NotEqual: 'notEqual',
};

const getRuleOperator = (
  ruleOperator: RulesMatches | undefined,
  fieldType: SchemaModel['type'],
  fact: string[] | string | number | boolean | undefined,
) => {
  if (
    fieldType === SchemaType.Array &&
    fact !== undefined &&
    Array.isArray(fact) &&
    fact.length !== 0
  ) {
    switch (ruleOperator) {
      case RulesMatches.DoesNotMatch:
      case RulesMatches.IsFilled: // If isFilled then it will notEqual undefined
        return RuleOperator.DoesNotContain;
      case RulesMatches.IsEmpty: // If isEmpty then it will equal undefined
      case RulesMatches.Matches:
      default:
        return RuleOperator.Contains;
    }
  } else {
    switch (ruleOperator) {
      case RulesMatches.DoesNotMatch:
      case RulesMatches.IsFilled: // If isFilled then it will notEqual undefined
      case RulesMatches.IsNotOn:
      case RulesMatches.NotEqual:
        return RuleOperator.NotEqual;
      case RulesMatches.GreaterThan:
      case RulesMatches.IsAfter:
        return RuleOperator.GreaterThan;
      case RulesMatches.IsAfterOrOn:
        return RuleOperator.GreaterThanInclusive;
      case RulesMatches.LessThan:
      case RulesMatches.IsBefore:
        return RuleOperator.LessThan;
      case RulesMatches.IsBeforeOrOn:
        return RuleOperator.LessThanInclusive;
      case RulesMatches.IsBetween:
        return RuleOperator.Between;
      case RulesMatches.IsNotBetween:
        return RuleOperator.NotBetween;
      case RulesMatches.IsEmpty: // If isEmpty then it will equal undefined
      case RulesMatches.Matches:
      case RulesMatches.IsOn:
      default:
        return RuleOperator.Equal;
    }
  }
};

const normalizeDateValues = (selectedValue: string, factValue: string, dateFormat: string) => {
  const startString = selectedValue.split('-')[0];
  const endString = selectedValue.split('-')[1];

  const sVals = startString.split('/');
  const sY = sVals[2];
  const sM = dateFormat === datePickerDefaultFormat ? sVals[0] : sVals[1];
  const sD = dateFormat === datePickerDefaultFormat ? sVals[1] : sVals[0];

  const eVals = endString.split('/');
  const eY = eVals[2];
  const eM = dateFormat === datePickerDefaultFormat ? eVals[0] : eVals[1];
  const eD = dateFormat === datePickerDefaultFormat ? eVals[1] : eVals[0];

  const factStartString = factValue.split('-')[0];
  const factEndString = factValue.split('-')[1];

  const fsVals = factStartString.split('/');
  const fsY = fsVals[2];
  const fsM = dateFormat === datePickerDefaultFormat ? fsVals[0] : fsVals[1];
  const fsD = dateFormat === datePickerDefaultFormat ? fsVals[1] : fsVals[0];

  const feVals = factEndString.split('/');
  const feY = feVals[2];
  const feM = dateFormat === datePickerDefaultFormat ? feVals[0] : feVals[1];
  const feD = dateFormat === datePickerDefaultFormat ? feVals[1] : feVals[0];

  // Date returns NaN if the date is in MM/DD/YYYY or DD/MM/YYYY format
  const selectedStart = dayjs(`${sY}-${sM}-${sD}`).utc().toDate().getTime();
  const selectedEnd = dayjs(`${eY}-${eM}-${eD}`).utc().toDate().getTime();
  const factStart = dayjs(`${fsY}-${fsM}-${fsD}`).utc().toDate().getTime();
  const factEnd = dayjs(`${feY}-${feM}-${feD}`).utc().toDate().getTime();

  return { selectedStart, selectedEnd, factStart, factEnd };
};

export const getNumericValue = (inputValue: string | number | undefined) =>
  inputValue !== undefined ? parseFloat(`${inputValue}`) : NaN;

const isValidNumber = (inputValue: number) => !Number.isNaN(inputValue);

export const isNumericValueEqual = (
  inputValue: string | number | undefined,
  optionValue: number,
) => {
  const inputNumber = getNumericValue(inputValue);
  return isValidNumber(inputNumber) && isValidNumber(optionValue) && inputNumber === optionValue;
};

export const isNumericValueLessThan = (
  inputValue: string | number | undefined,
  optionValue: number,
) => {
  const inputNumber = getNumericValue(inputValue);
  return isValidNumber(inputNumber) && isValidNumber(optionValue) && inputNumber < optionValue;
};

export const isNumericValueGreaterThan = (
  inputValue: string | number | undefined,
  optionValue: number,
) => {
  const inputNumber = getNumericValue(inputValue);
  return isValidNumber(inputNumber) && isValidNumber(optionValue) && inputNumber > optionValue;
};

const isDateBetween = (
  selectedValue: string | undefined,
  factValue: string,
  dateFormat: string,
) => {
  if (selectedValue && factValue) {
    const { selectedStart, selectedEnd, factStart, factEnd } = normalizeDateValues(
      selectedValue,
      factValue,
      dateFormat,
    );
    // check if selected start and selected end is between fact start and fact end
    if (selectedStart && selectedEnd && factStart && factEnd) {
      if (selectedStart >= factStart && selectedEnd <= factEnd) {
        return true;
      }
    }
  }
  return false;
};

const isDateNotBetween = (
  selectedValue: string | undefined,
  factValue: string,
  dateFormat: string,
) => {
  if (selectedValue && factValue) {
    const { selectedStart, selectedEnd, factStart, factEnd } = normalizeDateValues(
      selectedValue,
      factValue,
      dateFormat,
    );
    // check if selected start and selected end is not between fact start and fact end
    if (selectedStart && selectedEnd && factStart && factEnd) {
      if (selectedEnd < factStart || selectedStart > factEnd) {
        return true;
      }
    }
  }
  return false;
};

const addDataToEngine = (
  engine: Engine,
  quickFormTemplate: TemplateStorageProps,
  rawFacts: Record<string, string | string[] | undefined>,
) => {
  const questionArray = Object.keys(quickFormTemplate.schema.properties).map((key) => ({
    ...quickFormTemplate.schema.properties[key],
    key,
  }));
  questionArray?.map((Question) => {
    const { rules, key, dateFormat, asNumeric, type } = Question;
    const isNumericType = type === SchemaType.String && asNumeric;

    if (rawFacts) {
      if (
        (type !== SchemaType.Array && rawFacts[key] && rawFacts[key] !== '') ||
        (type === SchemaType.Array && Array.isArray(rawFacts[key]) && rawFacts[key]?.length !== 0)
      ) {
        engine.addFact(key, rawFacts[key]);
      } else {
        engine.addFact(key, undefined);
      }
    }
    if (rules && rules.length > 0) {
      rules.map((rule) => {
        const fact = rawFacts[key];
        const ruleOperator = getRuleOperator(rule.ifAnswer, type, fact);

        let ruleValue: string | number | undefined = rule.option || undefined;
        if (type === SchemaType.Integer) {
          ruleValue = Number(rule.option);
        } else if (isNumericType && rule.option !== undefined) {
          ruleValue = getNumericValue(rule.option);
        }

        // Since we're storing a complex number as string, let's compare them as numbers
        if (isNumericType && ruleOperator === RuleOperator.Equal) {
          engine.addOperator(
            RuleOperator.Equal,
            () =>
              !Array.isArray(fact) &&
              ruleValue !== undefined &&
              typeof ruleValue === 'number' &&
              isNumericValueEqual(fact, ruleValue),
          );
        }
        if (isNumericType && ruleOperator === RuleOperator.NotEqual) {
          engine.addOperator(
            RuleOperator.NotEqual,
            () =>
              Array.isArray(fact) ||
              ruleValue === undefined ||
              typeof ruleValue !== 'number' ||
              !isNumericValueEqual(fact, ruleValue),
          );
        }
        if (isNumericType && ruleOperator === RuleOperator.LessThan) {
          engine.addOperator(
            RuleOperator.LessThan,
            () =>
              !Array.isArray(fact) &&
              ruleValue !== undefined &&
              typeof ruleValue === 'number' &&
              isNumericValueLessThan(fact, ruleValue),
          );
        }
        if (isNumericType && ruleOperator === RuleOperator.GreaterThan) {
          engine.addOperator(
            RuleOperator.GreaterThan,
            () =>
              !Array.isArray(fact) &&
              ruleValue !== undefined &&
              typeof ruleValue === 'number' &&
              isNumericValueGreaterThan(fact, ruleValue),
          );
        }

        if (ruleOperator === RuleOperator.Between) {
          engine.addOperator(
            RuleOperator.Between,
            () =>
              !Array.isArray(fact) &&
              typeof ruleValue === 'string' &&
              !!dateFormat &&
              isDateBetween(fact, ruleValue, dateFormat),
          );
        }
        if (ruleOperator === RuleOperator.NotBetween) {
          engine.addOperator(
            RuleOperator.NotBetween,
            () =>
              !Array.isArray(fact) &&
              typeof ruleValue === 'string' &&
              !!dateFormat &&
              isDateNotBetween(fact, ruleValue, dateFormat),
          );
        }
        engine.addRule({
          conditions: {
            any: [
              {
                fact: key,
                operator: ruleOperator,
                value: ruleValue,
              },
            ],
          },
          onSuccess() {
            // We only want to set a param of visible/hidden. We'll modify schema after all rules get processed.
            if (rule.action === RulesActions.Show) {
              rule.fields?.map((field) => {
                if (!quickFormTemplate.schema.properties[field.key]['ui:visible']) {
                  quickFormTemplate.schema.properties[field.key]['ui:visible'] = true;
                }
                return quickFormTemplate;
              });
            } else if (rule.action === RulesActions.Hide) {
              rule.fields?.map((field) => {
                quickFormTemplate.schema.properties[field.key]['ui:visible'] = false;
                if (
                  quickFormTemplate.schema.properties[field.key].rules &&
                  quickFormTemplate.schema.properties[field.key].rules.length > 0
                ) {
                  quickFormTemplate.schema.properties[field.key].rules.map((subRule) => {
                    if (subRule.action === RulesActions.Show) {
                      subRule.fields?.map((subField) => {
                        quickFormTemplate.schema.properties[subField.key]['ui:visible'] = false;
                        return quickFormTemplate;
                      });
                    }
                    return quickFormTemplate;
                  });
                }
                return quickFormTemplate;
              });
            }
          },
          onFailure() {
            if (rule.action === RulesActions.Show) {
              rule.fields?.map((field) => {
                if (quickFormTemplate.schema.properties[field.key]['ui:visible'] !== true) {
                  quickFormTemplate.schema.properties[field.key]['ui:visible'] = false;
                }
                if (
                  quickFormTemplate.schema.properties[field.key].rules &&
                  quickFormTemplate.schema.properties[field.key].rules.length !== 0
                ) {
                  quickFormTemplate.schema.properties[field.key].rules.map((subRule) => {
                    if (subRule.action === RulesActions.Show) {
                      subRule.fields?.map((subField) => {
                        if (
                          quickFormTemplate.schema.properties[subField.key]['ui:visible'] !== true
                        ) {
                          quickFormTemplate.schema.properties[subField.key]['ui:visible'] = false;
                        }
                        return quickFormTemplate;
                      });
                    }
                    return quickFormTemplate;
                  });
                }
                return quickFormTemplate;
              });
            } else if (rule.action === RulesActions.Hide) {
              rule.fields?.map((field) => {
                if (
                  !quickFormTemplate.schema.properties[field.key]['ui:visible'] &&
                  quickFormTemplate.schema.properties[field.key]['ui:visible'] !== false
                ) {
                  quickFormTemplate.schema.properties[field.key]['ui:visible'] = true;
                }
                return quickFormTemplate;
              });
            }
          },
          event: {
            type: 'message',
            params: {
              schema: { ...quickFormTemplate.schema },
              uiSchema: { ...quickFormTemplate.uiSchema }, // this is not working
            },
          },
        });
        return rule;
      });
    }
    return Question;
  });
  return engine;
};

const processQuickFormRules = async (
  formData: FormSubmitData | {},
  quickFormTemplate: TemplateStorageProps,
) => {
  try {
    const cleanTemplate = removeDuplicateAnswers(quickFormTemplate);
    const engine: Engine = new Engine();
    await addDataToEngine(engine, cleanTemplate, formData).run();

    Object.keys(cleanTemplate.schema.properties).forEach((key) => {
      if (cleanTemplate.schema.properties[key]['ui:visible'] === false) {
        delete cleanTemplate.uiSchema[key];
        delete cleanTemplate.schema.properties[key];

        if (cleanTemplate.uiSchema['ui:order'] && cleanTemplate.uiSchema['ui:order'].length !== 0) {
          cleanTemplate.uiSchema['ui:order'] = cleanTemplate.uiSchema['ui:order'].filter(
            (item) => item !== key,
          );
        }

        if (cleanTemplate.schema.required && cleanTemplate.schema.required.length > 0) {
          cleanTemplate.schema.required = cleanTemplate.schema.required.filter(
            (item) => item !== key,
          );
        }
      }
    });

    cleanTemplate.isProcessed = true;
    return cleanTemplate;
  } catch (error) {
    console.error('err is', error);
    datadogRum.addError(error, { moduleName: 'FORMS' });
    throw error;
  }
};

const cleanFormData = (formData: FormSubmitData, quickFormTemplate: TemplateStorageProps) => {
  const cleanedFormData = {};
  Object.keys(quickFormTemplate.schema.properties).forEach((key) => {
    cleanedFormData[key] = formData[key] || undefined;
  });
  return cleanedFormData;
};

export default {
  getDefaultValuesForRangeFields,
  removeDuplicateAnswers,
  processQuickFormRules,
  cleanFormData,
};
