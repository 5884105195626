import { getTranslation } from '@tw/i18n';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect';

export interface TWInputSelectTeamForUserProps
  extends Pick<
    TWInputSelectProps,
    | 'id'
    | 'accessibilityLabel'
    | 'placeholder'
    | 'popupContainerId'
    | 'defaultValue'
    | 'onChange'
    | 'isMultiSelect'
    | 'testID'
  > {
  teamsList?: TWInputSelectProps['itemList'];
}

const TWInputSelectTeamForUser = ({
  placeholder = getTranslation('allTeams'),
  teamsList = [],
  ...otherProps
}: TWInputSelectTeamForUserProps) => {
  const parseTeamId = (teamId: string) => {
    // id format TeamNode:1180
    const idParts = atob(teamId).split(':');
    return idParts[1];
  };

  const keyExtractor = (team: (typeof teamsList)[0]) => team.id;
  const labelExtractor = (team: (typeof teamsList)[0]) => team.label;
  const valueExtractor = (team: (typeof teamsList)[0]) => Number(parseTeamId(team.id));

  return (
    <TWInputSelect
      allowClear
      filterOption
      optionFilterProp="title"
      keyExtractor={keyExtractor}
      labelExtractor={labelExtractor}
      valueExtractor={valueExtractor}
      itemList={teamsList}
      minWidth="200px"
      placeholder={placeholder}
      {...otherProps}
    />
  );
};

export default TWInputSelectTeamForUser;
