import type { Cell as CellType } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { memo, useState } from 'react';
import { stringUtils } from '@tw/util';
import { EditableTableCellComponent, CellValidationType } from '../../DataTable.definitions';
import { Td, CellContent } from '../../DataTable.styles';
import { useMovingCellRef } from '../../useRovingCellRef';

type CellProps<T> = {
  cell: CellType<T, unknown>;
  columnIndex: number;
  editableComponents?: Record<string, EditableTableCellComponent<T>>;
  editedCells?: string[];
  isRowSelected: boolean;
  isSelected: boolean;
  isEditing: boolean;
  isEditMode: boolean;
  onClick?: () => void;
  onUpdate?: (value: unknown, isValid?: boolean) => void;
  onValidate?: (validateType: CellValidationType, value: unknown) => boolean;
};

const Cell = <T extends object>({
  cell,
  columnIndex,
  editableComponents,
  editedCells,
  isSelected,
  isEditing,
  isEditMode,
  onClick,
  onUpdate,
}: CellProps<T>) => {
  const { ref, tabIndex, onFocus } = useMovingCellRef(isSelected);
  const columnId = cell.column.columnDef.id;
  const [hasError, setHasError] = useState(false);

  const hasEditableTableCellComponent =
    columnId !== undefined && editableComponents && columnId in editableComponents;

  const wasEdited = !!editedCells && !!columnId && editedCells.includes(columnId);

  const editableCell = hasEditableTableCellComponent
    ? editableComponents[columnId]({
        columnId,
        value: cell.renderValue(),
        row: cell.row.original,
        onUpdate: onUpdate
          ? (value, isValid = true) => {
              setHasError(!isValid);
              onUpdate(value);
            }
          : () => {
              console.error('failed to pass an onUpdate function to the popover');
            },
        onError: () => {
          setHasError(true);
        },
        onValidate: (validateType, value: unknown) => {
          setHasError(false);
          let isValid = true;
          if (!value || value === '') {
            return isValid;
          }

          if (validateType === CellValidationType.email) {
            isValid = stringUtils.isValidEmailAddress(value.toString());
          } else if (validateType === CellValidationType.phone) {
            isValid = stringUtils.isValidPhoneNumber(value.toString());
          } else if (validateType === CellValidationType.number) {
            isValid = typeof value === 'string' && !Number.isNaN(Number(value));
          }

          setHasError(!isValid);
          return isValid;
        },
      })
    : null;

  return (
    <Td
      ref={ref}
      data-row={cell.row.index}
      data-column={columnIndex}
      tabIndex={tabIndex}
      canEdit={isEditMode && hasEditableTableCellComponent}
      isEditMode={isEditMode}
      isSelected={isEditMode && isSelected}
      wasEdited={isEditMode && wasEdited}
      hasError={isEditing && isSelected && hasError}
      onClick={onClick}
      onFocus={onFocus}
      // value is back to original if there is a validation error
      onBlur={() => setHasError(false)}
    >
      <CellContent ref={ref}>
        {isSelected && hasEditableTableCellComponent && isEditing
          ? editableCell
          : flexRender(cell.column.columnDef.cell, cell.getContext())}
      </CellContent>
    </Td>
  );
};

const MemoizedCell = memo(
  Cell,
  (prev, next) =>
    next.isRowSelected === prev.isRowSelected &&
    next.isSelected === prev.isSelected &&
    next.isEditing === prev.isEditing &&
    next.isEditMode === prev.isEditMode &&
    next.cell.getValue() === prev.cell.getValue() &&
    next.cell.getContext() === prev.cell.getContext(),
);

export default MemoizedCell as typeof Cell;
