import styled from '@emotion/styled';
import { DialogContent } from '@teamworksdev/react';

export const SessionTimeoutDialogContent = styled(DialogContent)({
  // hide the close button
  '& > button': {
    height: 1,
    width: 1,
    left: -9999,
    right: -9999,
  },
});
