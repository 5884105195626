import styled from '@emotion/styled';
import { TWButton, TWTextDefault } from '@tw/components/presentational';
import { Col, Dropdown, DropdownProps, Row } from 'antd';

import { TWColumnFlex } from '../../layout';
import TWIcon from '../../TWIcon';

export const Container = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.border}`,
  borderRadius: theme.baseUnit * 0.5,
  '.ant-table-selection-column': {
    width: '1%',
  },
  button: {
    marginLeft: theme.baseUnit,
  },
  '.rowsPerPageColumn > div': {
    display: 'flex',
  },
  td: {
    flex: '0 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const BulkActionColumn = styled(TWColumnFlex)(({ theme, showSeparator }) => ({
  display: 'flex',
  flex: 1,
  marginLeft: theme.baseUnit,
  borderLeft: `1px solid ${showSeparator ? theme.colors.border : 'transparent'}`,
  color: theme.colors.text,
  button: {
    marginLeft: theme.baseUnit,
  },
}));

export const HeaderColumn = styled(Col)({ alignSelf: 'center' });

export const HeaderRow = styled(Row)(({ theme }) => ({
  backgroundColor: theme.colors.grayBackground,
  padding: `${theme.baseUnit * 1.5}px ${theme.baseUnit * 2}px`,
  color: theme.colors.text,
  alignItems: 'center',
}));

export const BulkDropdownButton = styled(TWButton)({
  width: '200px',
  maxWidth: 'none',
});

export const BulkDropdownIcon = styled(TWIcon)({
  height: '25px',
  width: '25px',
});

export const BulkDropdownTitleText = styled(TWTextDefault)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: '12px',
  color: theme.colors.secondary,
}));

export const BulkDropdown = (props: DropdownProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown overlayStyle={{ width: '200px', minWidth: 'unset' }} {...props} />
);
