/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileDeleteMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type FileDeleteMutation = {
  sharedFileDelete: {
    sharedFile?: { id: string; pk?: number | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const FileDeleteDocument = gql`
  mutation FileDelete($id: ID) {
    sharedFileDelete(id: $id) {
      sharedFile {
        id
        pk
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FileDeleteMutationFn = Apollo.MutationFunction<
  FileDeleteMutation,
  FileDeleteMutationVariables
>;

/**
 * __useFileDeleteMutation__
 *
 * To run a mutation, you first call `useFileDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileDeleteMutation, { data, loading, error }] = useFileDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<FileDeleteMutation, FileDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FileDeleteMutation, FileDeleteMutationVariables>(
    FileDeleteDocument,
    options,
  );
}
export type FileDeleteMutationHookResult = ReturnType<typeof useFileDeleteMutation>;
export type FileDeleteMutationResult = Apollo.MutationResult<FileDeleteMutation>;
export type FileDeleteMutationOptions = Apollo.BaseMutationOptions<
  FileDeleteMutation,
  FileDeleteMutationVariables
>;
