import { graphqlUtils } from '@tw/util';

import { studentBasicNode } from '../student/studentQueries';

const tutorSessionAttendanceBasicNode = {
  student: studentBasicNode,
  status: true,
  meta: true,
};

const tutorSessionAttendanceExportSchema = graphqlUtils.createEdgesObject({
  nodeSchema: tutorSessionAttendanceBasicNode,
});

export { tutorSessionAttendanceExportSchema };
