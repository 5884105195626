import { reduxConfigUtils } from '@tw/util';

import baseDefault from './baseDefault';
import deviceConfigStoreConfig from './deviceConfigStoreConfig';
import {
  APPLY_DEBUG_OVERRIDE,
  CLEAR_DEBUG_OVERRIDE,
  UPDATE_DEVICE_CONFIG,
} from './deviceConfigActions';

// Note: Everything is flat here (instead of nesting/grouping the attributes and values
// separately) because that makes the store manipulations easier -- we'd need 3-4 spread
// operators for each one instead of 1-2.
const initialState = {
  // In priority order, from lowest to highest
  layerNames: deviceConfigStoreConfig.layerNames,

  // Available attributes:
  //    isEnabled     {bool}
  //    @TODO: For future growth, if/when we need it:
  //      * something to enable/disable debug logging for the layer,
  //      * tags to allow complex queries from outside (to answer questions like
  //        "did this value come from a layer tagged as 'override'?", for debugging only)
  attributes_baseDefault: { isEnabled: true },
  attributes_device: { isEnabled: false },
  attributes_debugOverride: { isEnabled: false },

  values_baseDefault: baseDefault,
  values_device: {},
  values_debugOverride: {},
};

reduxConfigUtils.warnIfInitialStateLooksIncomplete(initialState);

export default function deviceConfigReducer(state = initialState, action) {
  switch (action.type) {
    // This updates the values at the layer named "device". (The name is a bit confusing: it does not
    // update deviceConfig.)
    case UPDATE_DEVICE_CONFIG: {
      const { deviceConfigValues } = action;

      return {
        ...state,
        attributes_device: {
          isEnabled: true,
        },
        values_device: reduxConfigUtils.mergeConfigLayerValues(
          state.values_device,
          deviceConfigValues,
        ),
      };
    }
    /**
     * This *merges in* a set of debugOverride values
     */
    case APPLY_DEBUG_OVERRIDE:
      return {
        ...state,
        attributes_debugOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_debugOverride,
          {
            isEnabled: true,
          },
        ),
        values_debugOverride: reduxConfigUtils.mergeConfigLayerValues(
          state.values_debugOverride,
          action.debugOverrideValues,
        ),
      };

    /**
     * This wipes (but does not disable) any debugOverride values
     */
    case CLEAR_DEBUG_OVERRIDE:
      return {
        ...state,
        attributes_debugOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_debugOverride,
          {
            isEnabled: false,
          },
        ),
        values_debugOverride: {},
      };

    default:
      return state;
  }
}
