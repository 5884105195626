/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchFileDownloadQueryVariables = Types.Exact<{
  sharedFileId?: Types.InputMaybe<Types.Scalars['TWFilter_ID']>;
}>;

export type FetchFileDownloadQuery = {
  allSharedFiles?: {
    edges: Array<{
      node?: {
        id: string;
        pk?: number | null;
        sharedFileId?: number | null;
        fileName: string;
        prettyDownloadUrl?: string | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchFileDownloadDocument = gql`
  query FetchFileDownload($sharedFileId: TWFilter_ID) {
    allSharedFiles(sharedFileId: $sharedFileId) {
      edges {
        node {
          id
          pk
          sharedFileId
          fileName
          prettyDownloadUrl
        }
      }
    }
  }
`;

/**
 * __useFetchFileDownloadQuery__
 *
 * To run a query within a React component, call `useFetchFileDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFileDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFileDownloadQuery({
 *   variables: {
 *      sharedFileId: // value for 'sharedFileId'
 *   },
 * });
 */
export function useFetchFileDownloadQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchFileDownloadQuery, FetchFileDownloadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchFileDownloadQuery, FetchFileDownloadQueryVariables>(
    FetchFileDownloadDocument,
    options,
  );
}
export function useFetchFileDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchFileDownloadQuery,
    FetchFileDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchFileDownloadQuery, FetchFileDownloadQueryVariables>(
    FetchFileDownloadDocument,
    options,
  );
}
export type FetchFileDownloadQueryHookResult = ReturnType<typeof useFetchFileDownloadQuery>;
export type FetchFileDownloadLazyQueryHookResult = ReturnType<typeof useFetchFileDownloadLazyQuery>;
export type FetchFileDownloadQueryResult = Apollo.QueryResult<
  FetchFileDownloadQuery,
  FetchFileDownloadQueryVariables
>;
