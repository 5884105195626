import ALL_TERMS_QUERY from './allTermsQuery.graphql';

export default [
  {
    request: {
      query: ALL_TERMS_QUERY,
      variables: {},
    },
    result: {
      data: {
        terms: {
          totalCount: 4,
          edges: [
            {
              node: {
                id: 'lkjahsdfk',
                termId: '2',
                label: 'Fall Semester 2018',
                startDate: '2019-12-20',
                endDate: '2020-05-20',
                __typename: 'TermNode',
              },
              __typename: 'TermEdge',
            },
            {
              node: {
                id: 'oiuqero',
                termId: '3',
                label: 'Spring Semester 2019',
                startDate: '2020-06-20',
                endDate: '2020-09-20',
                __typename: 'TermNode',
              },
              __typename: 'TermEdge',
            },
            {
              node: {
                id: 'mnzxc',
                termId: '4',
                label: 'Summer Sessions 2019',
                startDate: '2020-09-20',
                endDate: '2020-12-20',
                __typename: 'TermNode',
              },
              __typename: 'TermEdge',
            },
            {
              node: {
                id: 'mknbygjm',
                termId: '5',
                label: 'Fall Semester 2019',
                startDate: '2020-12-20',
                endDate: '2021-04-20',
                __typename: 'TermNode',
              },
              __typename: 'TermEdge',
            },
          ],
          __typename: 'TermConnection',
        },
      },
    },
  },
];
