import * as React from 'react';
import { Select } from 'antd';

import { getTranslation } from '@tw/i18n';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect/TWInputSelect';

type TWInputSelectProfessorSurveyStatusProps = TWInputSelectProps;

export const TWInputSelectProfessorSurveyStatus: React.FC<
  TWInputSelectProfessorSurveyStatusProps
> = (props) => {
  const {
    popupContainerId,
    placeholder = getTranslation('status'),
    onChange,
    minWidth = '200px',
    showSearch = false,
    ...allUnrecognizedProps
  } = props;

  return (
    <TWInputSelect
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      loading={false}
      onChange={onChange}
      popupContainerId={popupContainerId}
      minWidth={minWidth}
      showSearch={showSearch}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allUnrecognizedProps}
    >
      <Select.Option value="true" title={getTranslation('coursesTab.surveys.closed')}>
        {getTranslation('coursesTab.surveys.closed')}
      </Select.Option>
      <Select.Option value="false" title={getTranslation('coursesTab.surveys.opened')}>
        {getTranslation('coursesTab.surveys.opened')}
      </Select.Option>
    </TWInputSelect>
  );
};
