import { Checkbox, MenuProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { LabeledValue } from 'antd/lib/select';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import partial from 'lodash/partial';
import { MouseEvent, useState } from 'react';

import { TWButton, TWFlexContainer, TWInputCheckboxText, TWTextDefault } from '@tw/components';
import { noopFn } from '@tw/constants';
import { getTranslation } from '@tw/i18n';
import { DOMUtils } from '@tw/util';

import { ItemListProps, TWInputMultiSelectProps } from './TWInputMultiSelect.definitions';
import {
  ClearSelectionsButton,
  ClearSelectionsButtonContainer,
  InputIcon,
  LoadingIcon,
  MultiSelectionsHeader,
  Placeholder,
  StyledDropdown,
  SubHeadTextContainer,
} from './TWInputMultiSelect.styles';

const isLabeledValue = (value: string[] | number[] | LabeledValue[]): value is LabeledValue[] =>
  !!value[0] && typeof value[0] !== 'string' && typeof value[0] !== 'number';

const TWInputMultiSelect = ({
  accessibilityLabel,
  allSelectedLabel = '',
  containerId,
  itemList = [],
  placeholder,
  value = [],
  onChange = noopFn,
  keyExtractor,
  valueExtractor,
  labelExtractor,
  selectionLabel = '',
  testID,
  selectAllCheckedByDefault = false,
  selectAllItems = false,
  allItems = [],
  selectAllText = undefined,
  hasOptGroup = false,
  labelInValue = false,
  loading,
  disabled = false,
  clearSelectionText = null,
  subHeadText = null,
  getPopupContainer,
  onSelectAll,
}: TWInputMultiSelectProps) => {
  const [visible, setVisibility] = useState(false);
  const [allItemsChecked, setAllItemsChecked] = useState<boolean>(!selectAllCheckedByDefault);

  const handleMenuClick = () => {
    setVisibility(true);
  };

  const handleVisibleChange = (isVisible: boolean) => {
    setVisibility(isVisible);
  };

  const clearSelections = () => onChange([]);

  const setAllSelections = () => {
    onChange(allItemsChecked ? allItems : []);
  };

  const selectAllClick = () => {
    if (selectAllItems) {
      setAllItemsChecked(!allItemsChecked);
      if (onSelectAll) {
        onSelectAll(); // workaround for performance issues COMP-1263
      } else {
        setAllSelections();
      }
    }
  };

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setVisibility(false);
  };

  const handleSelectAllCheckbox = (actualLength: number) => {
    const checkAllItems: boolean = actualLength !== allItems.length;
    if (selectAllItems) {
      setAllItemsChecked(checkAllItems);
    }
  };

  const handleChange = (e: CheckboxChangeEvent, changedValue: string | LabeledValue) => {
    let actualLength = value.length;
    if (e.target.checked) {
      onChange([...value, changedValue]);
      actualLength += 1;
    } else {
      const newValues = filter(value, (val) => !isEqual(val, changedValue));
      actualLength -= 1;
      onChange(newValues);
    }
    if (selectAllItems) {
      handleSelectAllCheckbox(actualLength);
    }
  };

  const menuItem = (itemObject: ItemListProps): ItemType => {
    const itemKey =
      typeof keyExtractor === 'function'
        ? keyExtractor(itemObject)
        : itemObject.key || itemObject.value;

    const itemLabel =
      typeof labelExtractor === 'function'
        ? labelExtractor(itemObject)
        : String((itemObject.label || itemObject.value) ?? '');

    const itemValue =
      typeof valueExtractor === 'function' ? valueExtractor(itemObject) : itemObject.value;
    const allValues =
      labelInValue && isLabeledValue(value)
        ? value.map((el) => (typeof valueExtractor === 'function' ? valueExtractor(el) : el.value))
        : value;
    const isChecked = allValues.includes(itemValue);

    return {
      key: itemValue,
      label: (
        <Checkbox
          data-testid={`MultiSelect:Option:${itemLabel}`}
          key={itemKey}
          value={itemValue}
          onChange={(e) => handleChange(e, labelInValue ? itemObject : itemValue)}
          checked={isChecked}
        >
          {itemLabel}
        </Checkbox>
      ),
    };
  };

  const menuItemGroup = (groupItem: ItemListProps): ItemType => ({
    type: 'group',
    className: 'groupItem',
    label: groupItem.groupLabel,
    children: groupItem?.options?.map((option) => menuItem(option)),
  });

  const renderMultiSelectionLabel = () => {
    const flattenItemList = hasOptGroup ? itemList.flatMap((item) => item.options) : itemList;

    if (isEmpty(value)) {
      return <Placeholder disabled={disabled}>{placeholder}</Placeholder>;
    }

    if (
      Object.keys(value).length === Object.keys(flattenItemList).length &&
      Object.keys(flattenItemList).length !== 1
    ) {
      return (
        <TWTextDefault>
          {allSelectedLabel || getTranslation('allNouns', { noun: selectionLabel })}
        </TWTextDefault>
      );
    }

    if (Object.keys(value).length === 1) {
      const selectedItem = flattenItemList.find((item) => {
        const itemValue = typeof valueExtractor === 'function' ? valueExtractor(item) : item?.value;
        const [head] = value;
        return isEqual(labelInValue ? item : itemValue, head);
      });

      const itemLabel =
        typeof labelExtractor === 'function'
          ? labelExtractor(selectedItem)
          : selectedItem && String((selectedItem?.label || selectedItem?.value) ?? '');
      return <TWTextDefault>{itemLabel}</TWTextDefault>;
    }

    if (selectionLabel) {
      return (
        <TWTextDefault>
          {getTranslation('numItemSelected', {
            number: Object.keys(value).length,
            items: selectionLabel,
          })}
        </TWTextDefault>
      );
    }
    return (
      <TWTextDefault>{getTranslation('numSelected', Object.keys(value).length)}</TWTextDefault>
    );
  };

  const menuItems = (): MenuProps['items'] => {
    const items: MenuProps['items'] = [];
    if (selectAllItems) {
      items.push({
        key: 'selectAll',
        className: 'selectAll',
        label: (
          <TWInputCheckboxText
            className="selectAllCheckbox"
            data-testid="MultiSelect:SelectAll"
            onChange={selectAllClick}
            checked={!allItemsChecked}
            label={selectAllText}
          />
        ),
      });
      items.push({ type: 'divider' });
    } else {
      items.push({
        key: 'clearSelections',
        className: 'clearSelections',
        label: (
          <TWFlexContainer row>
            {subHeadText ? <SubHeadTextContainer>{subHeadText}</SubHeadTextContainer> : ''}
            <ClearSelectionsButtonContainer>
              <ClearSelectionsButton
                onClick={clearSelections}
                data-testid="MultiSelect:ClearSelections"
              >
                {clearSelectionText || getTranslation('clearSelection', { smart_count: 2 })}
              </ClearSelectionsButton>
            </ClearSelectionsButtonContainer>
          </TWFlexContainer>
        ),
      });
    }

    itemList.forEach((item) => {
      items.push(hasOptGroup ? menuItemGroup(item) : menuItem(item));
    });

    if (selectAllItems) {
      items.push(
        { type: 'divider' },
        {
          key: 'closeButton',
          className: 'closeButton',
          label: (
            <TWButton type="primary" data-testid="MultiSelect:CloseButton" onClick={closeMenu}>
              {getTranslation('close')}
            </TWButton>
          ),
        },
      );
    }

    return items;
  };

  return (
    <StyledDropdown
      getPopupContainer={getPopupContainer ?? partial(DOMUtils.getContainer, containerId)}
      onOpenChange={handleVisibleChange}
      disabled={disabled || loading}
      open={visible}
      trigger={['click']}
      menu={{
        onClick: handleMenuClick,
        items: menuItems(),
        className: 'input-multiselect-styled-menu',
      }}
    >
      <MultiSelectionsHeader
        aria-label={accessibilityLabel}
        disabled={disabled}
        className="ant-select"
        data-testid={testID}
      >
        {renderMultiSelectionLabel()}
        {loading ? (
          <LoadingIcon type="material-refresh" color="inherit" />
        ) : (
          <InputIcon type="material-expand_more" />
        )}
      </MultiSelectionsHeader>
    </StyledDropdown>
  );
};

export default TWInputMultiSelect;
