import _ from 'lodash';
import { cloneElement, memo, ReactNode, useEffect, useState } from 'react';

import { getTranslation } from '@tw/i18n';
import TWIcon from '../../components.web/TWIcon';
import { TWModal } from '../../components.web/TWModal';
import { TWButton } from '../buttons';
import TWText from '../TWText';
import { ReadComponent, TWIconDiv, WriteContainer } from './TWInlineEditField.styles';

const pendingTimeouts: NodeJS.Timeout[] = [];

export interface TWInlineEditFieldProps {
  canEdit?: boolean;
  containerId?: string;
  editByDefault?: boolean;
  keyDownPropName?: string;
  onCancel?: Function;
  onChange: Function;
  onChangePropName?: string;
  onKeyDown?: Function;
  onSave: Function;
  readComponent: ReactNode;
  writeComponent: ReactNode;
  style?: Object;
  hideButtons?: boolean;
  iconEdit?: string;
  iconSave?: string;
  iconCancel?: string;
}

const TWInlineEditField = ({
  canEdit = true,
  containerId = null,
  editByDefault = false,
  hideButtons = false,
  keyDownPropName = 'onInputKeyDown',
  onCancel = _.noop,
  onChange,
  onChangePropName = 'onChange',
  onKeyDown = null,
  onSave,
  readComponent,
  writeComponent,
  iconEdit = 'material-edit',
  iconSave = 'material-done',
  iconCancel = 'material-close',
}: TWInlineEditFieldProps) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isEditing, setEditing] = useState(editByDefault);
  const [isDirty, setDirty] = useState(false);
  useEffect(() => () => pendingTimeouts.forEach((id) => clearTimeout(id)), []);

  function handleOk() {
    setModalVisible(false);
    setEditing(false);
    setDirty(false);
    onCancel();
  }

  function handleCancel(event) {
    if (isDirty) {
      setModalVisible(true);
      // Prevent onBlur
      event.preventDefault();
      return;
    }

    setEditing(false);
    onCancel();
  }

  function handleSave(event) {
    if (isDirty) {
      setDirty(false);
      setEditing(false);
      onSave(event);
      return;
    }

    handleCancel(event);
  }

  function handleClick() {
    setEditing(true);
  }

  function handleChange(event) {
    setDirty(true);
    onChange(event);
  }

  function handleBlur(event) {
    const { currentTarget } = event;
    if (isModalVisible) {
      event.preventDefault();
      return;
    }

    // This timeout is necessary because the document.activeElement won't be updated until the next
    // tick because the focus event for that hasn't happened yet.
    const timeoutId = setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        handleSave(event);
      }
    });
    pendingTimeouts.push(timeoutId);
  }

  function handleKeyDown(event) {
    if (onKeyDown) {
      onKeyDown(event, handleSave, handleCancel);
      event.stopPropagation();
      return;
    }

    if (event.key === 'Escape') {
      handleCancel(event);
      event.stopPropagation();
    }

    if (event.key === 'Enter') {
      handleSave(event);
      event.stopPropagation();
    }
  }

  const fieldContainerId = `${containerId}-blurWrapper`;
  const writeComponentProps = {
    [onChangePropName]: handleChange,
    [keyDownPropName]: handleKeyDown,
  };

  if (_.has(writeComponent.props, 'popupContainerId')) {
    writeComponentProps.popupContainerId = fieldContainerId;
  } else if (_.has(writeComponent.props, 'containerId')) {
    writeComponentProps.containerId = fieldContainerId;
  }

  if (isEditing) {
    return (
      <WriteContainer>
        <TWModal
          containerId={containerId}
          testID="confirmationModal"
          open={isModalVisible}
          title={<TWText dictionaryId="cancelConfirmation" />}
          onOk={handleOk}
          onCancel={() => setModalVisible(false)}
          okText={<TWText dictionaryId="yes" />}
          cancelText={<TWText dictionaryId="no" />}
        >
          <TWText dictionaryId="lostChangesWarning" />
        </TWModal>
        <div
          id={fieldContainerId}
          aria-hidden
          className="blurWrapper"
          data-testid="blurWrapper"
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          tabIndex={-1}
        >
          {cloneElement(writeComponent, writeComponentProps)}
          {!hideButtons && (
            <div className="actionButtons">
              <TWButton
                accessibilityLabel={getTranslation('save')}
                aria-hidden
                testID="ActionButtons:Save"
                onClick={handleSave}
                tabIndex={-1}
              >
                <TWIcon type={iconSave} />
              </TWButton>
              <TWButton
                accessibilityLabel={getTranslation('cancel')}
                aria-hidden
                testID="ActionButtons:Cancel"
                onClick={handleCancel}
                tabIndex={-1}
              >
                <TWIcon type={iconCancel} />
              </TWButton>
            </div>
          )}
        </div>
      </WriteContainer>
    );
  }

  if (!canEdit) {
    return readComponent;
  }

  return (
    <ReadComponent>
      <button data-testid="editButton" type="button" onClick={handleClick}>
        <div className="flex-wrapper">
          <div className="stretch">{readComponent}</div>
          <TWIconDiv>
            <TWIcon type={iconEdit} className="editIcon" />
          </TWIconDiv>
        </div>
      </button>
    </ReadComponent>
  );
};

export default memo(TWInlineEditField);
