import { Dayjs } from 'dayjs';
import { forwardRef } from 'react';
import { PickerTimeProps } from 'antd/lib/date-picker/generatePicker';
import { Omit } from 'antd/lib/_util/type';
import DatePicker from '../TWInputDatePickerDayjs/DatePicker';

export type TimePickerProps = Omit<PickerTimeProps<Dayjs>, 'picker'>;

const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

TimePicker.displayName = 'TimePicker';

export default TimePicker;
