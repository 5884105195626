import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Fallback } from '@tw/components';
import { ContentWrapper } from './TWRouterContent.styles';
import { LayoutContent } from './GroupSideNav.styles';
import { SideNavRoute } from './SideNavTypes';

/** Builds Router for the corresponding component <GroupedSideNav />. Recommended you map through the links of the SideNavGroup array of objects and then flatten the Array of Arrays with .flat().
 * @param: containerId - containerId of the parent page the component will live in.
 * @param: links - list of objects route details
 * @param: style? - renders icon inline with header element (name)
 */

type GroupedSideNavRoutesProps = {
  links: SideNavRoute[];
  containerId: string;
};

const GroupdedSideNavRoutes = ({ links, containerId }: GroupedSideNavRoutesProps) => (
  <Suspense fallback={<Fallback />}>
    <ContentWrapper>
      <LayoutContent>
        <Switch>
          {links
            .filter(({ enabled }) => !!enabled)
            .map(({ key, to, component, exact }) => (
              <Route key={key} path={to} exact={exact}>
                {component({ containerId })}
              </Route>
            ))}
        </Switch>
      </LayoutContent>
    </ContentWrapper>
  </Suspense>
);

export default GroupdedSideNavRoutes;
