import { useCallback, useReducer } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BaseState = { [key: keyof any]: any };

type Action<T> =
  | { type: 'set'; payload: { key: keyof T; value: unknown | ((prev: unknown) => unknown) } }
  | { type: 'update'; payload: Partial<T> | ((prev: T) => T) }
  | { type: 'reset'; payload: T };

const createReducer =
  <T>() =>
  (state: T, { type, payload }: Action<T>): T => {
    switch (type) {
      case 'set': {
        const value =
          typeof payload.value === 'function' ? payload.value(state[payload.key]) : payload.value;
        return {
          ...state,
          [payload.key]: value,
        };
      }
      case 'update': {
        const updates: Partial<T> = typeof payload === 'function' ? payload(state) : payload;
        return { ...state, ...updates };
      }
      case 'reset':
        return payload;
      default:
        return state;
    }
  };

/**
 * A hook for managing complex state with multiple properties typed with a reducer
 */
const useComplexState = <T extends BaseState>({ initialState }: { initialState: T }) => {
  const [state, dispatch] = useReducer(createReducer<T>(), initialState);

  const set = useCallback(<U extends keyof T>(key: U, value: T[U] | ((prev: T[U]) => T[U])) => {
    dispatch({ type: 'set', payload: { key, value } });
  }, []);

  const update = useCallback((updates: Partial<T> | ((prev: T) => T)) => {
    dispatch({ type: 'update', payload: updates });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: 'reset', payload: initialState });
  }, [initialState]);

  return {
    state,
    set,
    update,
    reset,
  };
};

export default useComplexState;
