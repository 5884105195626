import { memo, useState, useEffect, CSSProperties } from 'react';

import { eligibilityFilterOptions } from '@tw/constants';
import { TWInputSelectEligibility } from '../../components.web/inputs';
import { TWStudentStatus } from '../..';

import TWInlineEditField from '../../presentational/TWInlineEditField';

interface TWEditableEligibilityProps {
  containerId: string;
  isAtRisk: boolean;
  onChange: (eligibilityValue: boolean) => void;
  width?: string;
  style?: CSSProperties;
}

const TWEditableEligibility = (props: TWEditableEligibilityProps) => {
  const { containerId, isAtRisk, onChange, width = '100%', style: externalStyles = null } = props;

  const [eligibilityValue, setEligibilityValue] = useState(isAtRisk);
  const [eligibilityOption, setEligibilityOption] = useState(
    isAtRisk ? eligibilityFilterOptions.AT_RISK : eligibilityFilterOptions.ON_TRACK,
  );

  useEffect(() => {
    setEligibilityValue(eligibilityOption === eligibilityFilterOptions.AT_RISK);
  }, [eligibilityOption]);

  function handleChange(newEligibilityOption) {
    setEligibilityOption(newEligibilityOption);
  }

  function handleCancel() {
    setEligibilityValue(isAtRisk);
  }

  function handleSave() {
    onChange(eligibilityValue);
  }

  return (
    <TWInlineEditField
      containerId={containerId}
      onCancel={handleCancel}
      onChange={handleChange}
      onSave={handleSave}
      style={externalStyles}
      readComponent={<TWStudentStatus isAtRisk={eligibilityValue} />}
      writeComponent={
        <TWInputSelectEligibility
          popupContainerId={containerId}
          open
          value={eligibilityOption}
          onChange={handleChange}
          width={width}
          minWidth="180px"
        />
      }
    />
  );
};

export default memo(TWEditableEligibility);
