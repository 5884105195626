/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupSlotsQueryVariables = Types.Exact<{
  signupId?: Types.InputMaybe<Types.Scalars['UUID']>;
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  mySignupsOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type SignupSlotsQuery = {
  signups?: {
    edges: Array<{
      node?: {
        timezone?: string | null;
        slots?: Array<{
          slotId?: any | null;
          capacity?: number | null;
          numReservations?: number | null;
          isOpen?: boolean | null;
          bookedPersons?: Array<{
            initials?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            personId?: number | null;
            pictureUrl?: string | null;
            selectionCode?: string | null;
            id?: string | null;
            label?: string | null;
          } | null> | null;
          timespan?: { startDatetime?: any | null; endDatetime?: any | null } | null;
        } | null> | null;
        resourceCalendarConflictingAppointments?: Array<{
          title?: string | null;
          location?: string | null;
          appointmentOccurrences?: Array<{
            endDateTime?: any | null;
            startDateTime?: any | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const SignupSlotsDocument = gql`
  query SignupSlots(
    $signupId: UUID
    $startDate: DateTime
    $endDate: DateTime
    $mySignupsOnly: Boolean
  ) {
    signups(signupId: $signupId, mySignupsOnly: $mySignupsOnly) {
      edges {
        node {
          slots(startDatetime: $startDate, endDatetime: $endDate) {
            slotId
            bookedPersons {
              id: pk
              initials
              label: displayName
              firstName
              lastName
              personId
              pictureUrl
              selectionCode
            }
            capacity
            numReservations
            isOpen
            timespan {
              startDatetime
              endDatetime
            }
          }
          resourceCalendarConflictingAppointments {
            appointmentOccurrences {
              endDateTime
              startDateTime
            }
            title
            location
          }
          timezone
        }
      }
    }
  }
`;

/**
 * __useSignupSlotsQuery__
 *
 * To run a query within a React component, call `useSignupSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupSlotsQuery({
 *   variables: {
 *      signupId: // value for 'signupId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      mySignupsOnly: // value for 'mySignupsOnly'
 *   },
 * });
 */
export function useSignupSlotsQuery(
  baseOptions?: Apollo.QueryHookOptions<SignupSlotsQuery, SignupSlotsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignupSlotsQuery, SignupSlotsQueryVariables>(SignupSlotsDocument, options);
}
export function useSignupSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SignupSlotsQuery, SignupSlotsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignupSlotsQuery, SignupSlotsQueryVariables>(
    SignupSlotsDocument,
    options,
  );
}
export type SignupSlotsQueryHookResult = ReturnType<typeof useSignupSlotsQuery>;
export type SignupSlotsLazyQueryHookResult = ReturnType<typeof useSignupSlotsLazyQuery>;
export type SignupSlotsQueryResult = Apollo.QueryResult<
  SignupSlotsQuery,
  SignupSlotsQueryVariables
>;
