import styled from '@emotion/styled';

export const MessageWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.background,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: theme.baseUnit * 2,
  textAlign: 'center',
}));
