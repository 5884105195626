import { GraphQLPageInfo, GraphQLPaginatedResponse } from '../graphQL';
import { Course } from './course';

export interface Subject {
  id: string; // relay id
  subjectId: string;
  courses?: Course[];
  customerKey: string;
  description: string;
  label: string;
  shortCode: string;
  // cursor may be added by graphqlUtils.convertEdgesToArray, but generally should not be used
  cursor?: string;
  __typename: 'SubjectNode';
}

export enum SubjectSortEnum {
  /* eslint-disable camelcase */
  ac2_subject_id_asc = 'ac2_subject_id_asc',
  ac2_subject_id_desc = 'ac2_subject_id_desc',
  created_by_asc = 'created_by_asc',
  created_by_desc = 'created_by_desc',
  created_date_asc = 'created_date_asc',
  created_date_desc = 'created_date_desc',
  description_xlt_asc = 'description_xlt_asc',
  description_xlt_des = 'description_xlt_des',
  label_xlt_asc = 'label_xlt_asc',
  label_xlt_desc = 'label_xlt_desc',
  short_code_asc = 'short_code_asc',
  short_code_desc = 'short_code_desc',
  updated_by_asc = 'updated_by_asc',
  updated_by_desc = 'updated_by_desc',
  updated_date_asc = 'updated_date_asc',
  updated_date_desc = 'updated_date_desc',
  /* eslint-enable camelcase */
}

export interface SubjectQueryVariables {
  subjectId?: string | number;
  includeGeneral?: boolean;
  sort?: SubjectSortEnum[];
  studentTermIds?: number[];
}

export interface SubjectRawQueryVariables {
  subjectId?: string;
  includeGeneral?: boolean;
  sort?: SubjectSortEnum[];
  studentTermIds?: number[];
}

export interface SubjectPaginatedRawData {
  subjects: GraphQLPaginatedResponse<Subject>;
}

export interface SubjectPaginatedData {
  subjects: Subject[];
  pageInfo: GraphQLPageInfo;
  totalCount: number;
}

export interface SubjectCreateInput {
  input: {
    description: string;
    shortCode: string;
  };
}

export interface SubjectCreateData {
  subjectCreate: {
    subject: {
      id: string;
    };
    userErrors: { field: string[]; message: string }[];
  };
}

export interface SubjectUpdateInput {
  id: string;
  input: {
    description?: string;
    shortCode?: string;
  };
}

export interface SubjectUpdateData {
  subjectUpdate: {
    subject: {
      id: string;
    };
    userErrors: { field: string[]; message: string }[];
  };
}
