/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnarchiveApptTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.AppointmentTypeInput>;
}>;

export type UnarchiveApptTypeMutation = {
  appointmentTypeUpdate: { appointmentType?: { pk?: string | null; label: string } | null };
};

export const UnarchiveApptTypeDocument = gql`
  mutation UnarchiveApptType($id: ID!, $input: AppointmentTypeInput) {
    appointmentTypeUpdate(id: $id, input: $input) {
      appointmentType {
        pk
        label
      }
    }
  }
`;
export type UnarchiveApptTypeMutationFn = Apollo.MutationFunction<
  UnarchiveApptTypeMutation,
  UnarchiveApptTypeMutationVariables
>;

/**
 * __useUnarchiveApptTypeMutation__
 *
 * To run a mutation, you first call `useUnarchiveApptTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveApptTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveApptTypeMutation, { data, loading, error }] = useUnarchiveApptTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveApptTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveApptTypeMutation,
    UnarchiveApptTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveApptTypeMutation, UnarchiveApptTypeMutationVariables>(
    UnarchiveApptTypeDocument,
    options,
  );
}
export type UnarchiveApptTypeMutationHookResult = ReturnType<typeof useUnarchiveApptTypeMutation>;
export type UnarchiveApptTypeMutationResult = Apollo.MutationResult<UnarchiveApptTypeMutation>;
export type UnarchiveApptTypeMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveApptTypeMutation,
  UnarchiveApptTypeMutationVariables
>;
