import _ from 'lodash';
import { graphqlUtils } from '@tw/util';
import {
  ProfessorSurveyAssignment,
  GraphQLPaginatedResponse,
  PaginatedProfessorSurveyAssignmentData,
  RawProfessorSurveyAssignment,
} from '@tw/types';

import { normalizeRawCourseSectionData } from '../courseSection/courseSectionModel';
import { transformRawPaginatedProfessorSurveyReportData } from '../professorSurveyReport/professorSurveyReportModel';

const transformRawProfessorSurveyAssignmentData = (
  rawAssignment: RawProfessorSurveyAssignment,
): ProfessorSurveyAssignment => {
  if (!rawAssignment) {
    return undefined;
  }

  const { courseSection, professorSurveyReports: rawReports, ...rest } = rawAssignment;
  const { professorSurveyReports } = transformRawPaginatedProfessorSurveyReportData(rawReports);
  return {
    courseSection: normalizeRawCourseSectionData(courseSection),
    professorSurveyReports,
    ...rest,
  };
};

const transformRawPaginatedProfessorSurveyAssignmentData = (
  rawData: GraphQLPaginatedResponse<RawProfessorSurveyAssignment> | null,
): PaginatedProfessorSurveyAssignmentData => {
  if (!rawData) {
    return undefined;
  }

  const { edges, ...rest } = rawData;
  const rawAssignments = graphqlUtils.convertEdgesToArray<RawProfessorSurveyAssignment>(edges);
  return {
    professorSurveyAssignments: _.map(rawAssignments, transformRawProfessorSurveyAssignmentData),
    ...rest,
  };
};

export default {
  transformRawProfessorSurveyAssignmentData,
  transformRawPaginatedProfessorSurveyAssignmentData,
};
