import _ from 'lodash';

import {
  ConfigContainerType,
  CustomAjaxError,
  FetchOptions,
  FetchUrlOptions,
  MetaData,
} from '@tw/types';
import { OFFLINE_MESSAGE } from '@tw/constants';
import { backendDebugUtils, backendUtils } from '@tw/util';

import ast from './ast';

const pyApiBackend = {
  /**
   * This is where the magic happens. It's basically fetch() except you pass an options object
   * instead of a string URL, and it'll handle any global-settings-based stuff we need.
   *
   * @param {object} urlOptions
   * @param {object} fetchOptions
   * @param {object} configContainer
   * @param {object} params
   * @return {promise}
   */
  getMetaDataForRequest: (
    urlOptions: FetchUrlOptions,
    fetchOptions: FetchOptions,
    configContainer: ConfigContainerType,
  ): MetaData => {
    // This is purely a convenience helper, since we want error messages to spew *everywhere*.
    function failWithSanityCheck(message: string) {
      console.error(`Cannot doFetch: ${message}`, urlOptions, fetchOptions);
      throw new Error(message);
    }

    // First we do some sanity checks on our arguments
    if (!configContainer) {
      failWithSanityCheck('You forgot to pass configContainer to doFetch()');
    }
    const {
      backendConfig: { defaultFetchOptions },
    } = configContainer;

    // @FIXME: Instead of spreading in the keys/values from urlOptions and fetchOptions
    // (*especially* fetchOptions!) we should pass params as appropriate.
    const baseApiCall = ast.apiCallFactory({ ...urlOptions, ...fetchOptions });
    const apiCall = baseApiCall;

    // Next we merge everything together into a unified fetch config
    const combinedFetchOptions = {
      ...defaultFetchOptions,
      ...fetchOptions,
      // We want to merge headers, not overwrite it
      headers: {
        ...defaultFetchOptions.headers,
        ...fetchOptions.headers,
      },
    };

    if (urlOptions.includeAuthenticationHeader) {
      if (combinedFetchOptions.headers.Authorization) {
        failWithSanityCheck(
          'Cannot includeAuthenticationHeader because Authorization header is already set',
        );
      }

      combinedFetchOptions.headers = {
        ...combinedFetchOptions.headers,
        ...apiCall.getHeaders(),
      };
    }

    const fullUrl = apiCall.getUrl();
    const postData = apiCall.getData();
    if (!_.isNull(postData)) {
      combinedFetchOptions.body = _.assign(
        {},
        !_.isNull(combinedFetchOptions.body) ? combinedFetchOptions.body : {},
        postData,
      );
    }
    if (!_.isNull(apiCall.files)) {
      combinedFetchOptions.files = apiCall.files;
    }
    // Now we're all set up and ready to go
    const metaData = backendUtils.prepareMetaDataForFetch({
      urlOptions,
      fullUrl,
      fetchOptions: combinedFetchOptions,
    });

    if (_.isObject(combinedFetchOptions.body)) {
      // We're in charge of encoding a PUT or POST body properly
      if (!combinedFetchOptions.headers['Content-Type']) {
        combinedFetchOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
        combinedFetchOptions.body = backendDebugUtils.shouldDebugRequest(metaData)
          ? JSON.stringify(fetchOptions.body, null, '  ')
          : JSON.stringify(fetchOptions.body);
      }
    }

    return metaData;
  },
  /**
   * This is generally called from doFetch, above, but it's also available as a standalone piece
   * because there are non-fetch() requests like file uploads which use device APIs to hit the
   * coldfusion backend.
   *
   * Regardless of who started the request, this function should be the one to handle the result
   * because there are a lot of wonky response conditions from Coldfusion
   * (like receiving a truthy value for "success" when there was actually a failure.)
   */
  processResultOfPromise: (
    ajaxPromise: Promise<unknown>,
    metaData: MetaData,
    { resolve, reject } = { resolve: undefined, reject: undefined },
    configContainer: ConfigContainerType,
  ) =>
    ajaxPromise
      .catch((immediateError) => {
        // If it fails instead of giving a response, then the network request wasn't even allowed --
        // probably because wi-fi is asleep or offline (and hopefully being turned on shortly)
        backendUtils.markEndOfResponse(metaData);
        metaData.isNetworkError = true; // eslint-disable-line no-param-reassign
        throw immediateError;
      })
      .then((response) => {
        if (metaData.urlOptions.receiveResponse) {
          return metaData.urlOptions.receiveResponse(response, metaData);
        }
        return backendUtils.receiveResponse(response, metaData, metaData.urlOptions.desiredFormat);
      })
      // eslint-disable-next-line consistent-return
      .then((responseJson) => {
        // @TODO: Right now we do these responseJson checks even if it's a non-json
        // response. They pass through okay, but it's still a bit sloppy.
        if (
          metaData.responseStatus &&
          metaData.responseStatus >= 200 &&
          metaData.responseStatus < 300 &&
          !responseJson.apiError &&
          responseJson.SUCCESS !== false &&
          responseJson.SUCCESS !== 'false' &&
          responseJson.success !== false &&
          responseJson.success !== 'false' &&
          // graphql uses an "errors" attribute but always sends 200 responses
          !responseJson.errors
        ) {
          // If we're given callbacks we'll use those; otherwise this promise will work directly.
          //
          // Note that we don't support using *both* external callbacks *and* chaining directly,
          // however, because that will result in two promises that React looks for
          // "possible unhandled promise rejection" for -- and the consumer should only be
          // watching one promise anyway. That results in a useless error being logged for the
          // correctly-uncaught one, so we avoid the whole situation by making the two cases
          // mutually exclusive.
          const successResponse = pyApiBackend.makeAjaxSuccessFromResponseJson(
            responseJson,
            metaData,
          );
          if (_.isFunction(resolve)) {
            resolve(successResponse);
          } else {
            return successResponse;
          }
        } else {
          // Whoops! Not successful after all
          throw pyApiBackend.makeAjaxErrorFromResponseJson(responseJson, metaData);
        }
      })
      .catch((error) => {
        const errorResponse = pyApiBackend.makeAjaxErrorFromErrorObject(
          error,
          metaData,
          configContainer,
        );
        if (_.isFunction(reject)) {
          reject(errorResponse);
        } else {
          throw errorResponse;
        }
      }),

  /**
   * This is the glue where everything really happens. It's basically fetch() except you pass
   * an options object instead of a string URL, and it'll handle any global-settings-based
   * stuff we need.
   */
  doFetch<T>(
    urlOptions: FetchUrlOptions,
    fetchOptions: FetchOptions = {},
    configContainer: ConfigContainerType,
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const metaData = pyApiBackend.getMetaDataForRequest(
        urlOptions,
        fetchOptions,
        configContainer,
      );

      const ajaxPromise = fetch(metaData.fullUrl, metaData.fetchOptions);

      // The 'timeout' option does not seem to be honored at the moment -- so we'll do it ourselves.
      setTimeout(() => {
        reject(
          pyApiBackend.makeAjaxErrorFromErrorObject(
            new Error(OFFLINE_MESSAGE),
            metaData,
            configContainer,
          ),
        );
      }, configContainer.backendConfig.timing.ajaxTimeout);

      return pyApiBackend.processResultOfPromise(
        ajaxPromise,
        metaData,
        { resolve, reject },
        configContainer,
      );
    });
  },

  doFetchGraphQL(
    urlOptions: FetchUrlOptions,
    fetchOptions: FetchOptions = {},
    configContainer: ConfigContainerType,
  ) {
    // GraphQL must use the 'graphql' model. We append debug to help developers look at this in the networking tab.
    // We were using debugLabel before, for this
    const operation = `?operation=${urlOptions.debugLabel || urlOptions.operationName}`;
    const model = `graphql${operation}`;

    _.set(urlOptions, 'model', model);
    // GraphQL requests must be POSTs
    _.set(fetchOptions, 'method', 'POST');

    return this.doFetch(urlOptions, fetchOptions, configContainer);
  },
  /**
   * This transforms a successful, parsed response into ... a successful parsed response.
   * All we really do is add the metaData to it, to help with debugging.
   * It's here primarily for parity with the makeAjaxError*() functions.
   * @TODO: Can this go into backendUtils? Is it different between different backends?
   *
   * @param {object} responseJson
   * @param metaData
   * @returns {object}
   */
  makeAjaxSuccessFromResponseJson(responseJson: unknown, metaData: MetaData) {
    return {
      // Note that we cannot use the spread operator over responseJson here
      // because sometimes *arrays* come back as the top level of the response (!?!?)
      // So, for now, we just tack the metaData onto the original object.
      // @FIXME: Either the backend should always give us an object at the top level (preferable)
      //         or we should come up with some other format that will allow for pagination
      //         and other backend-delivered metadata.
      responseJson,
      metaData,
    };
  },

  /**
   * @TODO: Can this go into backendUtils? Is it different between different backends?
   *
   * @param {object} responseJson
   * @param metaData
   * @returns CustomAjaxError
   */
  makeAjaxErrorFromResponseJson(responseJson: unknown, metaData: MetaData): CustomAjaxError {
    // @TODO: What do error responses actually look like?
    // @TODO: I18N
    const error: CustomAjaxError = new Error(
      responseJson.message ||
        responseJson.error_description ||
        responseJson.error ||
        // graphql returns an "errors" array of objects. We use the first as the error.
        _.get(responseJson, 'errors[0].message') ||
        'An unknown error occurred',
    );
    // in case there are other errors from graphql, keep them as errorsList
    if (responseJson.errors) {
      error.errorsList = responseJson.errors;
    }
    error.responseJson = responseJson;

    // @FIXME: We need a better way to detect this
    if (
      error.message.includes('token has expired') ||
      error.message.includes('wrong credentials') ||
      error.message.includes('401 Unauthorized')
    ) {
      error.isAuthenticationError = true;
    }

    return this.makeAjaxErrorFromErrorObject(error, metaData);
  },

  makeAjaxErrorFromErrorObject(
    error: CustomAjaxError,
    metaData: MetaData,
    configContainer: ConfigContainerType,
  ): CustomAjaxError {
    const newError = {
      ...error,
      metaData,
      message:
        !error.message || backendUtils.isJsonParseError(error.message)
          ? OFFLINE_MESSAGE
          : error.message,
    };

    if (
      // determine if the request timeout is due to a network connectivity issue
      // below 10 MS is to determine if the ping fails immediately
      configContainer &&
      (metaData.duration >= configContainer.backendConfig.timing.ajaxTimeout ||
        metaData.duration < 10) &&
      !newError.isAuthenticationError
    ) {
      newError.metaData.isNetworkError = true;
    } else if (newError.isAuthenticationError) {
      // Putting this on the metaData too just makes for easier debugging
      newError.metaData.isAuthenticationError = true;
    }

    return error;
  },
};

export default pyApiBackend;
