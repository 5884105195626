import styled from '@emotion/styled';
import _ from 'lodash';

import { useFlags } from '@tw/hooks';
import { getTranslation, isTranslationKey } from '@tw/i18n';
import { styleShimUtils } from '@tw/util';

import TWLayout from '../layout/TWLayout';
import { DISABLED_ROUTE_PREFIX } from './TWRoute.constants';
import TWRouterContent from './TWRouterContent';
import TWRouterHeader from './TWRouterHeader';
import { TWRouterPageProps } from './propTypes';

import TWUnauthorizedAcccess from './errorPages/TWUnauthorizedAccess';

const TWRouterPage = ({
  currentUserData,
  initialData,
  routerPageConfig: { pageKey, routeMap, pageTitle, pageTitleKey = '', containerId, styleContent },
}: TWRouterPageProps) => {
  const flags = useFlags();

  if (_.get(flags, `${DISABLED_ROUTE_PREFIX}${pageKey}`)) {
    return <TWUnauthorizedAcccess />;
  }

  const safeTitle = isTranslationKey(pageTitleKey) ? getTranslation(pageTitleKey) : pageTitle;
  const pageContainerId = containerId || `TWRouterPage__${_.kebabCase(safeTitle)}`;

  return (
    <Wrapper>
      <TWLayout.Container id={pageContainerId}>
        <TWRouterHeader
          tabHeader={safeTitle}
          routeMap={routeMap}
          initialData={initialData}
          currentUserData={currentUserData}
          containerId={pageContainerId}
        />
        <TWRouterContent
          routeMap={routeMap}
          initialData={initialData}
          currentUserData={currentUserData}
          containerId={pageContainerId}
          styleContent={styleContent}
        />
      </TWLayout.Container>
    </Wrapper>
  );
};

const Wrapper = styled.div(styleShimUtils.getPageStyleShims());

export default TWRouterPage;
