import styled from '@emotion/styled';

import { DialogBody, DropdownMenuContent, IconButton } from '@teamworksdev/react';
import { colors } from '@teamworksdev/style';

const DialogButton = styled(IconButton)<{ dialogOpen: boolean; disabled: boolean }>(
  ({ dialogOpen, disabled, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.baseUnit - 2,
    cursor: disabled ? 'not-allowed' : 'pointer',
    pointerEvents: 'auto',
    height: 44,
    width: 44,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    fontSize: theme.baseUnit * 3,
    backgroundColor: dialogOpen ? colors.light[300] : colors.light[50],
    '&:focus': {
      outline: `2px solid ${colors.info[200]}`,
      outlineOffset: 0,
    },
  }),
);

const DialogSelectedItems = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `${theme.baseUnit / 4}px ${theme.baseUnit / 2}px`,
  whiteSpace: 'nowrap',
}));

const EmptyStateContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
});

const EmptyStateDesc = styled.div(({ theme }) => ({
  whiteSpace: 'normal',
  color: theme.colors.black60,
  fontSize: `${theme.fontUnit * 3 + 2}px`,
  lineHeight: `${theme.fontUnit * 4 + 2}px`,
  fontWeight: theme.fontWeight.regular,
  fontFamily: theme.fontFamily.default,
  marginBottom: `${theme.baseUnit * 2}px`,
}));

const EmptyStateIcon = styled.div(({ theme }) => ({
  display: 'flex',
  width: `${theme.baseUnit * 7}px`,
  height: `${theme.baseUnit * 7}px`,
  borderRadius: '50%',
  backgroundColor: colors.black,
  color: colors.white,
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  marginBottom: `${theme.baseUnit * 2}px`,

  svg: {
    width: `${theme.baseUnit * 4}px`,
    height: `${theme.baseUnit * 4}px`,
    color: colors.white,
  },
}));

const EmptyStateTitle = styled.div(({ theme }) => ({
  color: `${colors.black}DE`,
  fontSize: `${theme.fontUnit * 4 + 2}px`,
  lineHeight: `${theme.fontUnit * 6}px`,
  fontWeight: theme.fontWeight.semibold,
  fontFamily: theme.fontFamily.default,
  marginBottom: theme.fontUnit,
}));

const GridContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gap: theme.baseUnit * 2,
  padding: theme.baseUnit / 2,
}));

const FlexContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.baseUnit * 2,
}));

const ItemLabel = styled.div(({ theme }) => ({
  fontSize: 14,
  fontWeight: theme.fontWeight.regular,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flexGrow: 1,
  width: 0,
}));

const FilterLabel = styled.div(({ theme }) => ({
  fontSize: theme.fontUnit * 3,
  fontWeight: theme.fontWeight.semibold,
  color: 'rgba(0, 0, 0, 0.6)',
  paddingBottom: theme.baseUnit / 2,
}));

const FilterWrapper = styled.div({
  width: '50%',
});

const FiltersContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.fontUnit * 3,
  width: '48.5%',
}));

const InputClear = styled(IconButton)({
  borderRadius: '100%',
});

const InputContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${colors.light[300]}`,
  borderRadius: theme.baseUnit - 2,
  justifyContent: 'space-between',
  flexGrow: 1,
}));

const Panel = styled.div<{ isEmptyState?: boolean }>(({ isEmptyState, theme }) => ({
  border: `1px solid ${colors.light[300]}`,
  borderRadius: theme.baseUnit / 2,
  padding: `${theme.baseUnit * 1.5}px 0px ${theme.baseUnit * 1.5}px ${theme.baseUnit * 1.5}px`,
  height: 376,
  maxWidth: 409,
  width: '100%',
  overflowY: 'auto',
  display: isEmptyState ? 'flex' : undefined,
  justifyContent: isEmptyState ? 'center' : undefined,
  alignItems: isEmptyState ? 'center' : undefined,
}));

const PanelsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  columnGap: theme.baseUnit * 3,
}));

const PillContainer = styled.div({
  position: 'absolute',
  bottom: 90,
  right: 30,
});

const PopoverContainer = styled.div<{ maxHeight?: number; maxWidth?: number }>(
  ({ theme, maxHeight, maxWidth }) => ({
    maxHeight,
    overflowY: 'auto',
    padding: theme.baseUnit * 1.5,
    marginTop: theme.baseUnit / 2,
    maxWidth: maxWidth ?? 'auto',
    backgroundColor: colors.white,
    border: `1px solid ${colors.light[200]}`,
    borderRadius: theme.baseUnit - 2,
    boxShadow: '0px 10px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  }),
);

const PopoverSelectedItems = styled.div({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
});

const SelectionsContainer = styled.div(({ theme }) => ({
  maxHeight: 300,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.baseUnit * 2}px ${theme.baseUnit}px`,
  border: `1px solid ${colors.light[300]}`,
  borderRadius: theme.baseUnit / 2,
}));

const SelectionDetails = styled.div({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SelectionTreeGroup = styled.div(({ theme }) => ({
  borderRadius: theme.baseUnit - 2,
  '&:focus': {
    outline: `2px solid ${colors.info[200]}`,
    outlineOffset: -2,
  },
}));

const SelectionTreeItem = styled.li<{ disabled: boolean }>(({ disabled, theme }) => ({
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  padding: `${theme.baseUnit - 2}px ${theme.baseUnit}px`,
  borderRadius: theme.baseUnit - 2,
  '&:hover': {
    backgroundColor: colors.light[200],
  },
  '&:focus': {
    backgroundColor: colors.light[200],
    outline: `2px solid ${colors.info[200]}`,
    outlineOffset: -2,
  },
}));

const SelectionTreeList = styled.ul<{ dialogOpen: boolean }>(({ dialogOpen }) => ({
  listStyle: 'none',
  paddingLeft: dialogOpen ? 20 : 8,
}));

const TeamSelect = styled.div(({ theme }) => ({
  height: 36,
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  border: `1px solid ${colors.light[200]}`,
  borderRadius: theme.baseUnit - 2,
  backgroundColor: colors.white,
  cursor: 'pointer',
  paddingTop: theme.baseUnit,
  paddingBottom: theme.baseUnit,
  paddingLeft: theme.baseUnit * 1.5,
  paddingRight: theme.baseUnit * 1.5,
  fontSize: 14,
  '&:focus': {
    outline: `2px solid ${colors.info[200]}`,
    outlineOffset: 2,
  },
}));

const TeamSelectContent = styled(DropdownMenuContent)({
  height: 'auto',
  maxHeight: 300,
});

const TeamSelectFlexContainer = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const TreeGroupLabel = styled.span(({ theme }) => ({
  fontSize: 14,
  fontWeight: theme.fontWeight.semibold,
}));

const TreeGroupLabelContainer = styled.div<{ menuOpen: boolean }>(({ menuOpen, theme }) => ({
  display: 'flex',
  justifyContent: menuOpen ? 'space-between' : 'flex-start',
  alignItems: 'center',
  padding: `${theme.baseUnit - 2}px ${theme.baseUnit}px`,
  cursor: 'pointer',
}));

const UserDialogBody = styled(DialogBody)(({ theme }) => ({
  maxHeight: `${theme.baseUnit * 83}px`,
  overflowY: 'hidden',
}));

export {
  DialogButton,
  DialogSelectedItems,
  EmptyStateContainer,
  EmptyStateDesc,
  EmptyStateIcon,
  EmptyStateTitle,
  GridContainer,
  FilterLabel,
  FilterWrapper,
  FiltersContainer,
  FlexContainer,
  ItemLabel,
  InputClear,
  InputContainer,
  Panel,
  PanelsWrapper,
  PillContainer,
  PopoverContainer,
  PopoverSelectedItems,
  SelectionsContainer,
  SelectionDetails,
  SelectionTreeGroup,
  SelectionTreeItem,
  SelectionTreeList,
  TeamSelect,
  TeamSelectContent,
  TeamSelectFlexContainer,
  TreeGroupLabel,
  TreeGroupLabelContainer,
  UserDialogBody,
};
