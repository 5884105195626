/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalsyncAccountCreateMutationVariables = Types.Exact<{
  baseUrl: Types.Scalars['String'];
  code?: Types.InputMaybe<Types.Scalars['String']>;
  input?: Types.InputMaybe<Types.CalsyncAccountInput>;
}>;

export type CalsyncAccountCreateMutation = {
  calsyncAccountCreate: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    calsyncAccount?: {
      id: string;
      calsyncAccountId: string;
      providerId: string;
      createdBy?: number | null;
      personId: number;
    } | null;
  };
};

export const CalsyncAccountCreateDocument = gql`
  mutation CalsyncAccountCreate($baseUrl: String!, $code: String, $input: CalsyncAccountInput) {
    calsyncAccountCreate(baseUrl: $baseUrl, code: $code, input: $input) {
      userErrors {
        field
        message
      }
      calsyncAccount {
        id
        calsyncAccountId
        providerId
        createdBy
        personId
      }
    }
  }
`;
export type CalsyncAccountCreateMutationFn = Apollo.MutationFunction<
  CalsyncAccountCreateMutation,
  CalsyncAccountCreateMutationVariables
>;

/**
 * __useCalsyncAccountCreateMutation__
 *
 * To run a mutation, you first call `useCalsyncAccountCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalsyncAccountCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calsyncAccountCreateMutation, { data, loading, error }] = useCalsyncAccountCreateMutation({
 *   variables: {
 *      baseUrl: // value for 'baseUrl'
 *      code: // value for 'code'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalsyncAccountCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CalsyncAccountCreateMutation,
    CalsyncAccountCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CalsyncAccountCreateMutation, CalsyncAccountCreateMutationVariables>(
    CalsyncAccountCreateDocument,
    options,
  );
}
export type CalsyncAccountCreateMutationHookResult = ReturnType<
  typeof useCalsyncAccountCreateMutation
>;
export type CalsyncAccountCreateMutationResult =
  Apollo.MutationResult<CalsyncAccountCreateMutation>;
export type CalsyncAccountCreateMutationOptions = Apollo.BaseMutationOptions<
  CalsyncAccountCreateMutation,
  CalsyncAccountCreateMutationVariables
>;
