/**
 * This file provides static values about how the deviceConfig interacts with and exists
 * within the Redux store.
 */

const storeKey = 'i18nConfig';

export default {
  storeKey,
  rootSelector: (store) => store[storeKey],

  fallbackLanguage: 'en',
  supportedLanguages: {
    en: 'English',
    es: 'Español',
  },

  layerNames: ['baseDefault', 'devicePreference', 'debugOverride'],
};
