import styled from '@emotion/styled';

import { sharedSpacingStyles, SpacingComponentProps } from '../../../utils';

interface ContainerProps {
  isHighlighted?: boolean;
}

const Container = styled.div<SpacingComponentProps & ContainerProps>(({ theme, isHighlighted }) => [
  sharedSpacingStyles,
  {
    display: 'flex',
    cursor: 'pointer',
    flex: 1,
    flexFlow: 'row',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    minWidth: 0,
    transition: theme.cssValues.transition,
    '*:not(:last-child)': {
      marginRight: theme.baseUnit,
    },
    outline: isHighlighted ? `1px solid ${theme.colors.border}` : 'inherit',
    ':focus': {
      outline: 0,
      border: 'none',
      background: theme.colors.border,
    },
    ':hover': {
      backgroundColor: theme.colors.highlightBold,
    },
  },
]);

Container.defaultProps = {
  twPadding: [0.5, 1],
};
interface IconContainerProps {
  state: string;
}

const IconContainer = styled.div<IconContainerProps>(({ state }) => {
  const animationStates = {
    entering: { transform: `rotate(0deg)` },
    entered: { transform: `rotate(90deg)` },
    exiting: { transform: `rotate(90deg)` },
    exited: { transform: `rotate(0deg)` },
  };

  return {
    transition: `transform 100ms ease-in-out`,
    transform: 0,
    display: 'flex',
    alignItems: 'center',
    ...animationStates[state],
  };
});

interface ExpandedLeafsContainerProps {
  state: string;
}

const ExpandedLeafsContainer = styled.div<ExpandedLeafsContainerProps>(({ state }) => {
  const animationStates = {
    entering: { opacity: 0, height: 0, display: 'none' },
    entered: { opacity: 1, height: '100%', display: 'inline' },
    exiting: { opacity: 1, height: '100%', display: 'inline' },
    exited: { opacity: 0, height: 0, display: 'none' },
  };

  return {
    transition: `height 100ms ease-in-out, opacity 100ms ease-in-out`,
    transform: 0,
    opacity: 0,
    ...animationStates[state],
  };
});

export { Container, ExpandedLeafsContainer, IconContainer };
