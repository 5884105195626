import blackTiny from './logo_black_30px.png';
import blackSmall from './logo_black_105px.png';
import transparentTiny from './logo_transparent_18px.png';
import transparentSmall from './logo_transparent_180px.png';

export const giphyLogos = {
  black: {
    tiny: blackTiny,
    default: blackSmall,
  },
  transparent: {
    tiny: transparentTiny,
    default: transparentSmall,
  },
};
