/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileCreateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.SharedFileInput>;
}>;

export type FileCreateMutation = {
  sharedFileCreate: {
    sharedFile?: {
      id: string;
      pk?: number | null;
      sharedFileId?: number | null;
      folderId?: number | null;
      fileName: string;
      fileNotes?: string | null;
      isPinned?: boolean | null;
      updatedDate?: any | null;
      canEditFile?: boolean | null;
      creator?: {
        createdBy?: number | null;
        pictureDownloadUrl?: string | null;
        preferredName?: string | null;
        lastName?: string | null;
        initials?: string | null;
      } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const FileCreateDocument = gql`
  mutation FileCreate($input: SharedFileInput) {
    sharedFileCreate(input: $input) {
      sharedFile {
        id
        pk
        sharedFileId
        folderId
        fileName
        fileNotes
        isPinned
        updatedDate
        canEditFile
        creator {
          createdBy
          pictureDownloadUrl
          preferredName
          lastName
          initials
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FileCreateMutationFn = Apollo.MutationFunction<
  FileCreateMutation,
  FileCreateMutationVariables
>;

/**
 * __useFileCreateMutation__
 *
 * To run a mutation, you first call `useFileCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileCreateMutation, { data, loading, error }] = useFileCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFileCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<FileCreateMutation, FileCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FileCreateMutation, FileCreateMutationVariables>(
    FileCreateDocument,
    options,
  );
}
export type FileCreateMutationHookResult = ReturnType<typeof useFileCreateMutation>;
export type FileCreateMutationResult = Apollo.MutationResult<FileCreateMutation>;
export type FileCreateMutationOptions = Apollo.BaseMutationOptions<
  FileCreateMutation,
  FileCreateMutationVariables
>;
