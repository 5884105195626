/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTicketingEventsQueryVariables = Types.Exact<{
  team?: Types.InputMaybe<Types.Scalars['ID']>;
  isEventUpcoming?: Types.InputMaybe<Types.Scalars['Boolean']>;
  eventStartDateTime?: Types.InputMaybe<Types.Scalars['DateTime']>;
  eventEndDateTime?: Types.InputMaybe<Types.Scalars['DateTime']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  isArchived?: Types.InputMaybe<Types.Scalars['TWFilter_Boolean']>;
  sort?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.TicketingEventSortEnum>>
    | Types.InputMaybe<Types.TicketingEventSortEnum>
  >;
}>;

export type FetchTicketingEventsQuery = {
  ticketingEvents?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        eventLabel?: string | null;
        eventDateTime?: any | null;
        hasTicketAllocations?: boolean | null;
        totalAllocated?: number | null;
        totalReserved?: number | null;
        windowOpenDateTime?: any | null;
        windowCloseDateTime?: any | null;
        isArchived: boolean;
        eventTimeZone?: string | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchTicketingEventsDocument = gql`
  query FetchTicketingEvents(
    $team: ID
    $isEventUpcoming: Boolean
    $eventStartDateTime: DateTime
    $eventEndDateTime: DateTime
    $after: String
    $before: String
    $first: Int
    $last: Int
    $isArchived: TWFilter_Boolean
    $sort: [TicketingEventSortEnum]
  ) {
    ticketingEvents(
      team: $team
      isEventUpcoming: $isEventUpcoming
      eventStartDateTime: $eventStartDateTime
      eventEndDateTime: $eventEndDateTime
      after: $after
      before: $before
      first: $first
      last: $last
      isArchived: $isArchived
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          eventLabel
          eventDateTime
          hasTicketAllocations
          totalAllocated
          totalReserved
          windowOpenDateTime
          windowCloseDateTime
          isArchived
          eventTimeZone
          hasTicketAllocations
        }
      }
    }
  }
`;

/**
 * __useFetchTicketingEventsQuery__
 *
 * To run a query within a React component, call `useFetchTicketingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTicketingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTicketingEventsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      isEventUpcoming: // value for 'isEventUpcoming'
 *      eventStartDateTime: // value for 'eventStartDateTime'
 *      eventEndDateTime: // value for 'eventEndDateTime'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      isArchived: // value for 'isArchived'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchTicketingEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTicketingEventsQuery,
    FetchTicketingEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTicketingEventsQuery, FetchTicketingEventsQueryVariables>(
    FetchTicketingEventsDocument,
    options,
  );
}
export function useFetchTicketingEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTicketingEventsQuery,
    FetchTicketingEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTicketingEventsQuery, FetchTicketingEventsQueryVariables>(
    FetchTicketingEventsDocument,
    options,
  );
}
export type FetchTicketingEventsQueryHookResult = ReturnType<typeof useFetchTicketingEventsQuery>;
export type FetchTicketingEventsLazyQueryHookResult = ReturnType<
  typeof useFetchTicketingEventsLazyQuery
>;
export type FetchTicketingEventsQueryResult = Apollo.QueryResult<
  FetchTicketingEventsQuery,
  FetchTicketingEventsQueryVariables
>;
