import styled from '@emotion/styled';
import {
  VerticalNav,
  VerticalNavGroup,
  VerticalNavItem,
  VerticalNavLink,
} from '@teamworksdev/react';
import { useLocation } from 'react-router-dom';

import { SideNavGroup } from './SideNavTypes';

/** Builds sections of a header and nested links to components. Takes in a list of objects with the type of SideNavGroup ([{enabled: true, name: ""}...]). Components linked to path are rendered by <GroupedSideNavRoutes />
 * @param: enabled? - route is enabled to view (i.e isOrgSuperUser)
 * @param: name - header element of the grouped nav links
 * @param: links - list of Routes wrapped around passed components
 * @param: icon? - renders icon inline with header element (name)
 * @param: defaultSelectedKey? - option used to highlight routes on initial navigation
 */
const GroupedSideNav = ({ groups }: { groups: SideNavGroup[]; defaultSelectedKey?: string }) => {
  const location = useLocation();
  const lastPathSegment: string = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1,
  );

  return (
    <VerticalNavContainer>
      {groups
        .filter(({ enabled }) => !!enabled)
        .map(({ name, links }) => (
          <VerticalNavGroup key={name} title={name}>
            {links
              .filter(({ enabled }) => !!enabled)
              .map((link) => (
                <VerticalNavItem key={link.key}>
                  <VerticalNavLink
                    data-testid={link.dataTestId}
                    to={link.to}
                    active={link.key === lastPathSegment}
                  >
                    {link.name}
                  </VerticalNavLink>
                </VerticalNavItem>
              ))}
          </VerticalNavGroup>
        ))}
    </VerticalNavContainer>
  );
};

const VerticalNavContainer = styled(VerticalNav)({
  minWidth: '200px',
  maxWidth: '250px',
  padding: '24px 16px',
  height: '100%',
  '& ul': { paddingLeft: '0', listStyle: 'none' },
});

export default GroupedSideNav;
