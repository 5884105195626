/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OverlayEventsDetailsQueryVariables = Types.Exact<{
  requestId: Types.Scalars['String'];
  eventIds:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type OverlayEventsDetailsQuery = {
  getOverlayEvents?: {
    requestId: string;
    events: Array<{
      allDay?: boolean | null;
      creator?: string | null;
      endDatetime: any;
      isPrivate?: boolean | null;
      location?: string | null;
      nonHubAttendees: Array<string | null>;
      notes?: string | null;
      overlayEventId: string;
      owningApp: string;
      peopleCount: number;
      startDatetime: any;
      title: string;
      timeZone: string;
      appointmentType: { color: string; id: string; name: string };
      selections?: {
        persons?: Array<{
          active: boolean;
          id: string;
          initials?: string | null;
          pictureUrl?: string | null;
          preferredName?: string | null;
          lastName?: string | null;
          selectionCode?: string | null;
        } | null> | null;
      } | null;
      userMetadata?: { canViewDetails: boolean } | null;
    } | null>;
  } | null;
};

export const OverlayEventsDetailsDocument = gql`
  query OverlayEventsDetails($requestId: String!, $eventIds: [String]!) {
    getOverlayEvents(requestId: $requestId, eventIds: $eventIds) {
      requestId
      events {
        allDay
        appointmentType {
          color
          id
          name
        }
        creator
        endDatetime
        isPrivate
        location
        nonHubAttendees
        notes
        overlayEventId
        owningApp
        peopleCount
        selections {
          persons {
            active
            id
            initials
            pictureUrl
            preferredName
            lastName
            selectionCode
          }
        }
        startDatetime
        timeZone: timezone
        title
        userMetadata {
          canViewDetails
        }
      }
    }
  }
`;

/**
 * __useOverlayEventsDetailsQuery__
 *
 * To run a query within a React component, call `useOverlayEventsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverlayEventsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverlayEventsDetailsQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      eventIds: // value for 'eventIds'
 *   },
 * });
 */
export function useOverlayEventsDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OverlayEventsDetailsQuery,
    OverlayEventsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OverlayEventsDetailsQuery, OverlayEventsDetailsQueryVariables>(
    OverlayEventsDetailsDocument,
    options,
  );
}
export function useOverlayEventsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OverlayEventsDetailsQuery,
    OverlayEventsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OverlayEventsDetailsQuery, OverlayEventsDetailsQueryVariables>(
    OverlayEventsDetailsDocument,
    options,
  );
}
export type OverlayEventsDetailsQueryHookResult = ReturnType<typeof useOverlayEventsDetailsQuery>;
export type OverlayEventsDetailsLazyQueryHookResult = ReturnType<
  typeof useOverlayEventsDetailsLazyQuery
>;
export type OverlayEventsDetailsQueryResult = Apollo.QueryResult<
  OverlayEventsDetailsQuery,
  OverlayEventsDetailsQueryVariables
>;
