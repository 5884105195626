/* eslint-disable no-underscore-dangle */
import { datadogRum } from '@datadog/browser-rum';
import { IdxTransactionMeta } from '@okta/okta-auth-js';
import axios from 'axios';
import { jwtDecode, type JwtPayload } from 'jwt-decode';
import localStorage from '@tw/services/localStorage';
import { backendUtils } from '@tw/util';
import { LocalStorageKeys } from '../localStorage/localStorage.definitions';
import { makeBearerToken } from './makeBearerToken';
import { oktaAuth } from './okta';

const INACTIVITY_WARNING_THRESHOLD = 5 * 60 * 1000; // 5 minutes before inactivity timeout
const RENEW_TOKEN_THRESHOLD = 3 * 60 * 1000; // 3 minutes
const TOKEN_CHECKER_INTERVAL = 15 * 1000; //  every 15 sec
const ACCESS_TOKEN_LIFESPAN = 1 * 30 * 60 * 1000; // 30 minutes
const REFRESH_TOKEN_LIFESPAN = 30 * 24 * 60 * 60 * 1000; // 30 days

let sessionCheckerTimerId: ReturnType<typeof setInterval> | null = null;

const session = {
  _isRenewing: false,

  async loginWithOkta() {
    // then use that to get a new access token
    const { host } = window.location;
    const [subdomain] = host.split('.');

    if (host === 'localhost' || subdomain === 'www' || !subdomain) {
      oktaAuth.signInWithRedirect();
      return;
    }

    try {
      const {
        links: [
          {
            properties: { 'okta:idp:id': idp },
          },
        ],
      } = (await oktaAuth.webfinger({ resource: `acct:@${subdomain}` })) as unknown as {
        links: { properties: { 'okta:idp:id': string } }[];
      };

      oktaAuth.signInWithRedirect({ idp });
    } catch (err) {
      datadogRum.addAction('loginWithOkta error', err);
      oktaAuth.signInWithRedirect();
    }
  },

  async loginWithOktaUsernamePassword(username: string, password: string) {
    return new Promise((resolve, reject) => {
      oktaAuth
        .signInWithCredentials({ username, password })
        .then(({ sessionToken }) => {
          oktaAuth.token.getWithoutPrompt({ sessionToken }).then(({ tokens }) => {
            const accessToken = tokens?.accessToken?.accessToken;
            if (!accessToken) reject(new Error('Failed to get access token'));
            session.setIsRenewing(false);
            session.exchangeOktaTokenForHubToken(accessToken!).then(resolve).catch(reject);
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async logout(redirectUrl?: string) {
    try {
      await oktaAuth.signOut({ postLogoutRedirectUri: redirectUrl });
    } finally {
      session.clear();
      window.location.href = redirectUrl ?? '/';
    }
  },

  async logoutWithoutRedirect() {
    return oktaAuth.closeSession();
  },

  async exchangeCodeForAccessToken(code: string, state: string) {
    const meta = oktaAuth.transactionManager.load() as IdxTransactionMeta;
    if (!meta || !meta?.codeVerifier) {
      session.logout();
      datadogRum.addAction('Meta or codeVerifier not found', { meta });
      return null;
    }

    try {
      const response = await oktaAuth.token.exchangeCodeForTokens({
        authorizationCode: code,
        state,
        codeVerifier: meta?.codeVerifier,
      });

      const oktaToken = response.tokens?.accessToken?.accessToken;
      if (!oktaToken) {
        datadogRum.addAction('Response from okta did not include an access token', {
          response,
        });
      }

      return oktaToken;
    } catch (err) {
      datadogRum.addAction('Request to okta for tokens failed', { error: err });
      throw err;
    }
  },

  async exchangeOktaTokenForHubToken(oktaToken: string): Promise<boolean> {
    try {
      const region = this.getRegionFromOktaToken(oktaToken);
      switch (region) {
        // if we are not on the correct domain, redirect to the correct one
        // and return false to stop the login process
        case 'AP':
          if (!(process.env.AWS_REGION === 'ap-southeast-2')) {
            window.location.replace('https://teamworksapp.co.nz/home/overview');
            return false;
          }
          break;
        case 'EU':
          if (!(process.env.AWS_REGION === 'eu-central-1')) {
            window.location.replace('https://teamworksapp.eu/home/overview');
            return false;
          }
          break;
        case 'US':
        default:
          if (!(process.env.AWS_REGION === 'us-east-1')) {
            window.location.replace('https://teamworksapp.com/home/overview');
            return false;
          }
          break;
      }

      const response = await axios.post(`${backendUtils.API_BASE_URL}/api/v3/auth/hub/token`, {
        token_type: 'okta',
        token: oktaToken,
      });

      if (!response.data || response.status !== 200) {
        datadogRum.addAction('Response from backend was not successful', { response });
        throw new Error('Failed to exchange tokens');
      }

      const token = makeBearerToken(response.data);

      localStorage.setBearerToken(token);
      return true;
    } catch (err) {
      datadogRum.addError(err);
      throw err;
    }
  },

  async exchangeAxleTokenForHubToken(axleToken: string) {
    const response = await axios.post(`${backendUtils.API_BASE_URL}/api/v3/auth/hub/token`, {
      token_type: 'axle',
      token: axleToken,
    });

    if (!response.data || response.status !== 200) throw new Error('Failed to exchange tokens');
    const token = makeBearerToken(response.data);

    localStorage.setBearerToken(token);
  },

  async getAxleToken(): Promise<string> {
    const currentUser = localStorage.getCurrentUser();

    const headers: HeadersInit = {
      'User-Agent': 'TW-Web',
      Authorization: `Bearer ${localStorage.getAccessToken()}`,
      'X-TW-PersonId': String(currentUser?.personId),
      'X-TW-TeamId': String(currentUser?.teamId),
    };

    // When an account hasn't been selected yet, we are authenticated but we
    // still don't have these ids
    if (!currentUser?.personId && !currentUser?.teamId) {
      delete headers['X-TW-PersonId'];
      delete headers['X-TW-TeamId'];
    }
    const response = await axios.post(
      `${backendUtils.API_BASE_URL}/api/v3/auth/hub/axle`,
      {},
      {
        headers,
      },
    );

    if (response.status !== 200)
      throw new Error(`Failed to get axle token. Received status code: ${response.status}`);
    if (!response.data) throw new Error('Failed to get axle token');
    if (!response.data.token) throw new Error('Invalid response from axle');

    return response.data.token.toString();
  },

  getRegionFromOktaToken(oktaToken: string) {
    const payload = jwtDecode<JwtPayload & { region?: string }>(oktaToken);

    return 'region' in payload && typeof payload.region === 'string'
      ? payload.region.toUpperCase()
      : 'US';
  },

  expireBearerToken() {
    const token = localStorage.getBearerToken();
    if (token) {
      localStorage.setBearerToken({
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
        expiresAt: 0,
      });
    }
  },

  clear() {
    this.stopTokenChecker();
    localStorage.unsubscribe(receiveMessage);
    localStorage.clear();
  },

  // ensureSession verifies that token is valid and starts
  // periodically checking and refreshing the token.
  ensureSession() {
    this.stopTokenChecker();
    this._ensureLocalStorageSubscription();

    if (this._shouldRenewToken()) {
      this.renewToken()
        .then(() => {
          this.startTokenChecker();
        })
        .catch(this.logout.bind(this));
    } else {
      this.startTokenChecker();
    }
  },

  isValid() {
    return this.timeLeft() > 0;
  },

  isRefreshTokenValid() {
    const token = this._getBearerToken();

    if (token === null || !token.expiresAt) {
      return false;
    }

    const tokenCreatedAt = token.expiresAt * 1000 - ACCESS_TOKEN_LIFESPAN;
    const tokenExpiresAt = tokenCreatedAt + REFRESH_TOKEN_LIFESPAN;

    const delta = tokenExpiresAt - new Date().getTime();

    return delta > 0;
  },

  isWithinInactivityTimeout() {
    const lastActive = localStorage.getLastActive();
    const now = new Date().getTime();
    const inactivityTimeout = localStorage.getSessionInactivityTimeout();

    return lastActive + inactivityTimeout > now;
  },

  getInactivityTimeout(minutes?: number) {
    return minutes ? minutes * 60 * 1000 : 0;
  },

  getInactivityWarningThreshold() {
    return INACTIVITY_WARNING_THRESHOLD;
  },

  _getBearerToken() {
    let token = null;
    try {
      token = localStorage.getBearerToken();
    } catch (err) {
      console.error('Cannot find bearer token', err);
    }

    return token;
  },

  _shouldRenewToken() {
    if (this._getIsRenewing()) {
      return false;
    }

    // Renew session if token expiry time is less than 3 minutes.
    // Browsers have js timer throttling behavior in inactive tabs that can go
    // up to 100s between timer calls from testing. 3 minutes seems to be a safe number
    // with extra padding.
    return this.timeLeft() < RENEW_TOKEN_THRESHOLD;
  },

  async renewToken() {
    this._setAndBroadcastIsRenewing(true);
    const refreshToken = localStorage.getRefreshToken();

    if (!refreshToken) {
      throw new Error('Invalid refresh token');
    }

    try {
      const { data } = await axios.post(
        `${backendUtils.API_BASE_URL}/api/v3/auth/hub/token/refresh`,
        {
          refresh_token: refreshToken,
        },
      );

      if (!data) throw new Error('Invalid refresh token');

      const token = makeBearerToken(data);
      localStorage.setBearerToken(token);

      this._setAndBroadcastIsRenewing(false);
    } catch {
      this.logout();
    }
  },

  _setAndBroadcastIsRenewing(value: boolean) {
    this.setIsRenewing(value);
    localStorage.broadcast(LocalStorageKeys.TOKEN_RENEW, `${value}`);
  },

  setIsRenewing(value: boolean) {
    this._isRenewing = value;
  },

  _getIsRenewing() {
    return !!this._isRenewing;
  },

  timeLeft() {
    const token = this._getBearerToken();

    if (!token) {
      return 0;
    }
    const { expiresAt } = token;

    if (!expiresAt) {
      return 0;
    }

    const delta = expiresAt * 1000 - new Date().getTime();
    return delta;
  },

  _shouldCheckStatus() {
    if (this._getIsRenewing()) {
      return false;
    }

    /*
     * double the threshold value for slow connections to avoid
     * access-denied response due to concurrent renew token request
     * made from other tab
     */
    return this.timeLeft() > TOKEN_CHECKER_INTERVAL * 2;
  },

  // subsribes to localStorage changes (triggered from other browser tabs)
  _ensureLocalStorageSubscription() {
    localStorage.subscribe(receiveMessage);
  },

  startTokenChecker() {
    this.stopTokenChecker();

    sessionCheckerTimerId = setInterval(() => {
      // calling ensureSession() will again invoke startTokenChecker
      this.ensureSession();
    }, TOKEN_CHECKER_INTERVAL);
  },

  stopTokenChecker() {
    if (sessionCheckerTimerId) clearInterval(sessionCheckerTimerId);
    sessionCheckerTimerId = null;
  },
};

function receiveMessage(event: StorageEvent) {
  const { key, newValue } = event;

  // check if logout was triggered from other tabs
  if (localStorage.getBearerToken() === null) {
    session.logout();
  }

  // check if token is being renewed from another tab
  if (key === LocalStorageKeys.TOKEN_RENEW && !!newValue) {
    session.setIsRenewing(JSON.parse(newValue));
  }
}

export default session;
