import { SharedFile } from '../forms';
import { Team } from '../teams';

export enum ConflictType {
  all = 'all',
  any = 'any',
  adequateNotice = 'adequateNotice',
  dayOffWeekly = 'dayOffWeekly',
  dayOffAdditional = 'dayOffAdditional',
  noConflict = 'noConflict',
}

export type ReviewStatus = 'reviewed' | 'dismissed' | 'none';

export enum ReviewStatusFilter {
  all = 'all',
  notDismissed = 'notDismissed',
  unreviewed = 'unreviewed',
  reviewed = 'reviewed',
  dismissed = 'dismissed',
}

interface AttendeeDiff {
  same: string[];
  removed: string[];
  added: string[];
}

interface IntDiff {
  new: number;
  old: number;
}

interface TimeDiff {
  new: string;
  old: string;
}

interface StringDiff {
  new: string;
  old: string;
}

export interface ComplianceAuditApptSnapshot {
  title: string;
  startDate: string;
  endDate: string;
  timeZone: string;
  appointmentType: string;
  complianceType: string;
  countableMinutes: number;
  action: string;
  location: string;
  recurRule: string;
  attendeeSelectionCodes: string[];
  conflictDates: string[];
  changeType: string;
}

export interface ComplianceAuditApptChanges {
  startDate: TimeDiff;
  endDate: TimeDiff;
  startTimeZone: StringDiff;
  countableMinutes: IntDiff;
  location: StringDiff;
  appointmentType: StringDiff;
  complianceType: StringDiff;
  recurRule: StringDiff;
  attendees: AttendeeDiff;
  title: StringDiff;
}

export interface ComplianceAuditDiff {
  changes: ComplianceAuditApptChanges;
  original: ComplianceAuditApptSnapshot;
}

export interface AuditConflictReport {
  label: string;
  bylaw: string;
}

export interface ComplianceAuditComment {
  note: string;
  createdDateTime: string;
  creator: { firstName: string; lastName: string; pictureUrl: string; initials: string };
  attachments: SharedFile[];
}

export interface ComplianceAudit {
  pk: string;
  appointmentComplianceAuditId: string;
  changeTime: string;
  reviewStatus: string;
  diff: ComplianceAuditDiff;
  teams: Team[];
  conflictsList: AuditConflictReport[];
  creator: {
    firstName: string;
    lastName: string;
    pictureUrl: string;
    initials: string;
  };
  createdDateTime: string;
  comments: ComplianceAuditComment[];
  conflictReasonCode: string;
  conflictReasonText: string;
}

export interface ComplianceAuditsChangeReviewStatusInput {
  auditIds: string[];
  reviewStatus: ReviewStatus;
}

export interface ComplianceAuditAddCommentInput {
  commentNote: string;
}
