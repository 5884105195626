import { Dayjs } from 'dayjs';

import { GraphQLPaginatedResponse, GraphQLPageInfo } from '../graphQL';

import { Student } from './student';
import { Tutor, RawTutor } from './tutor';
import {
  SessionAttendance,
  RawSessionAttendance,
  SessionAttendanceStatuses,
} from './sessionAttendance';
import { Course } from './course';

export interface UpcomingSessionStudentSchema {
  pk: number;
  fullName: string;
  personId: number;
  pictureUrl: string;
  teamIds: number[];
}

export interface UpcomingSession {
  appointmentOccId: number;
  course: string;
  courseId: number;
  endDateTime: string;
  location: string;
  startDateTime: string;
  studentIds: number[];
  students: UpcomingSessionStudentSchema[];
  teams: string;
  termId: number;
  tutor?: string;
  tutorId: number;
  tutorPersonId: number;
}

export interface SessionToReport extends Omit<UpcomingSession, 'tutor'> {
  tutor: { subjectLabels: Array<string>; fullName: string };
}

export interface Session {
  sessionId: string;
  id: string;
  appointmentOccurrence?: {
    id: string;
  };
  appointmentOccurrenceId: number;
  course: Course;
  courseId: number;
  courseSectionId?: number;
  creator: {
    fullName: string;
    lastName: string;
    personId: string;
    preferredName: string;
  };
  endDateTime: string;
  isCanceled: boolean;
  location: string;
  reportAnswers: {
    notes?: string;
    rules_checked?: boolean; // eslint-disable-line camelcase
  };
  reportStatus: SessionReportStatus;
  scheduledOn: string;
  sessionAttendances: SessionAttendance[];
  startDateTime: string;
  studentIds: number[];
  students: Student[];
  subjectId?: number;
  tutor: Tutor;
  termId: number;
}

export interface RawSession
  extends Omit<
    Session,
    'students' | 'sessionAttendances' | 'tutor' | 'isCanceled' | 'reportAnswers'
  > {
  isCanceled: 'true' | 'false';
  reportAnswers: string;
  students: GraphQLPaginatedResponse<Student>;
  sessionAttendances: GraphQLPaginatedResponse<RawSessionAttendance>;
  tutor: RawTutor;
}

export interface PaginatedSessionData {
  sessions: Session[];
  pageInfo: GraphQLPageInfo;
  totalCount: number;
}

export enum SessionReportStatuses {
  /* eslint-disable camelcase */
  report_complete = 'report_complete',
  needs_report = 'needs_report',
  session_canceled = 'session_canceled',
  /* eslint-enable camelcase */
}

export type SessionReportStatus = keyof typeof SessionReportStatuses;

export interface SessionFilters {
  first?: number;
  sessionId?: number;
  searchSessions?: string;
  advisorIds?: number[];
  teamIds?: number[];
  courseIds?: number[];
  isAtRisk?: boolean;
  occurrenceStartDateTime?: Dayjs;
  termId?: number[];
  reportStatus?: SessionReportStatus[];
  sort?: string[];
  before?: string;
  after?: string;
  last?: number;
  dateRange?: [string, string];
  isDeleted?: boolean;
}

export interface SessionRawQueryVariables
  extends Omit<
    SessionFilters,
    'reportStatus' | 'termId' | 'occurrenceStartDateTime' | 'dateRange' | 'isDeleted'
  > {
  isDeleted?: string;
  occurrenceStartDateTime?: string;
  reportStatus?: string;
  termId?: number;
}

export interface SessionAttendanceSubmitValue {
  attendanceStartDateTime: Dayjs;
  attendanceEndDateTime: Dayjs;
  status: SessionAttendanceStatuses;
  studentId: string;
  prepared: string;
  notes: string;
}

export interface SessionReportSubmitValues {
  sessionId: string;
  rulesChecked: string;
  sessionAttendances: SessionAttendanceSubmitValue[];
}

/* eslint-disable camelcase */
export interface SessionAttendanceRawSubmitValue {
  student_id: string;
  attendance_start_date_time: string;
  attendance_end_date_time: string;
  status: SessionAttendanceStatuses;
  prepared: string;
  notes: string;
}

export interface SessionReportRawSubmitValues {
  notes: string;
  rules_checked: string;
  session_attendances: SessionAttendanceRawSubmitValue[];
}
