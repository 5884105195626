import _ from 'lodash';

const UI_SETTINGS_CONFIG_ACTION_CONSTANTS = {
  /**
   * This *merges in* a set of values
   */
  APPLY_USER_STATE: 'UI_SETTINGS_CONFIG__APPLY_USER_STATE',
  APPLY_DEBUG_OVERRIDE: 'UI_SETTINGS_CONFIG__APPLY_DEBUG_OVERRIDE',

  /**
   * This wipes (but does not disable) a set of values
   */
  CLEAR_USER_STATE: 'UI_SETTINGS_CONFIG__CLEAR_USER_STATE',
  CLEAR_DEBUG_OVERRIDE: 'UI_SETTINGS_CONFIG__CLEAR_DEBUG_OVERRIDE',
};

const UI_SETTINGS_CONFIG_ACTION_LIST = _.values(UI_SETTINGS_CONFIG_ACTION_CONSTANTS);

function applyUserStateAction(userStateValues) {
  return {
    type: UI_SETTINGS_CONFIG_ACTION_CONSTANTS.APPLY_USER_STATE,
    userStateValues,
  };
}

function clearUserStateAction() {
  return {
    type: UI_SETTINGS_CONFIG_ACTION_CONSTANTS.CLEAR_USER_STATE,
  };
}

/**
 * By contract, each service has an applyDebugOverrideAction, for use in Secret Settings ONLY.
 * See superSecretConfigData if you want to go down that rabbit hole.
 */
function applyDebugOverrideAction(debugOverrideValues) {
  return {
    type: UI_SETTINGS_CONFIG_ACTION_CONSTANTS.APPLY_DEBUG_OVERRIDE,
    debugOverrideValues,
  };
}

function clearDebugOverrideAction() {
  return {
    type: UI_SETTINGS_CONFIG_ACTION_CONSTANTS.CLEAR_DEBUG_OVERRIDE,
  };
}

// Action creators below this point are just syntactic sugar: they exist so that screens can
// user nicer-looking and more-exactly-named functions.

function setUserPreferenceAction(userPreferenceName, newValue) {
  return applyUserStateAction({
    userPreferences: {
      [userPreferenceName]: newValue,
    },
  });
}

// We're going to export the raw values under their corresponding
// (non-"UI_SETTINGS_CONFIG__"-prefixed) names
export const { APPLY_USER_STATE, CLEAR_USER_STATE, APPLY_DEBUG_OVERRIDE, CLEAR_DEBUG_OVERRIDE } =
  UI_SETTINGS_CONFIG_ACTION_CONSTANTS;

export {
  // DANGER: this should only be used by Mediator-level Watchers that need to respond to changes
  // in the config. Exporting all action constants as a unit makes things much easier for watchers,
  // but would be a severe anti-pattern if used anywhere else.
  UI_SETTINGS_CONFIG_ACTION_LIST,
  // And we're also going to export a constructor for each of the actions
  applyUserStateAction,
  clearUserStateAction,
  applyDebugOverrideAction,
  clearDebugOverrideAction,
  setUserPreferenceAction,
};
