import { CSSProperties, ReactNode } from 'react';
import { TWIcon } from '@tw/components';
import {
  ChipSizeScalingEnum,
  Theme,
  theme as defaultTheme,
  ChipSizeScalingType,
  CustomChipSizeType,
} from '@tw/components/utils';

import TWAvatar from '../TWAvatar';
import { TWHeading4, TWTextDefault, TWTextLarge, TWTextSmall } from '../typography';
import { ChipContainer, TextAndSubTextColumn } from './TWChip.styles';

const sizeScaling = (theme: Theme, size) => {
  const sizeValues = {
    xsmall: {
      iconSize: theme.iconSize.xsmall,
      avatarFontSize: theme.baseUnit * 1.5,
      TextComponent: TWTextSmall,
      SubTextComponent: TWTextSmall,
      textPaddingLeft: 0.5,
    },
    small: {
      iconSize: theme.iconSize.small,
      avatarFontSize: theme.baseUnit * 1.5,
      TextComponent: TWTextSmall,
      SubTextComponent: TWTextSmall,
      textPaddingLeft: 1,
    },
    default: {
      iconSize: theme.iconSize.medium,
      avatarFontSize: theme.baseUnit * 2,
      TextComponent: TWTextDefault,
      SubTextComponent: TWTextSmall,
      textPaddingLeft: 1.5,
    },
    large: {
      iconSize: theme.iconSize.large,
      avatarFontSize: theme.baseUnit * 2.5,
      TextComponent: TWTextLarge,
      SubTextComponent: TWTextDefault,
      textPaddingLeft: 2,
    },
    xlarge: {
      iconSize: theme.iconSize.xlarge,
      avatarFontSize: theme.baseUnit * 2.5,
      TextComponent: TWHeading4,
      SubTextComponent: TWHeading4,
      textPaddingLeft: 2,
    },
  };
  return sizeValues[size];
};

interface TWChipProps {
  label: string | JSX.Element;
  labelStyles?: object;
  labelColor?: string;
  subtext?: string | JSX.Element;
  subtext2?: string;
  avatarText?: string;
  avatarImage?: string;
  avatarIcon?: ReactNode;
  size?: ChipSizeScalingType | CustomChipSizeType;
  isSelected?: boolean;
  showAvatar?: boolean;
  subtextStyles?: CSSProperties;
  subtext2Styles?: CSSProperties;
  subtextTitle?: string;
  subtext2Title?: string;
  labelAndSubtextStyles?: CSSProperties;
}

const TWChip: React.FC<TWChipProps> = ({
  label,
  labelStyles = {},
  labelColor = '',
  subtext = '',
  subtext2 = '',
  subtextTitle = '',
  subtext2Title = '',
  avatarImage = null,
  avatarIcon = <TWIcon type="material-person" />,
  avatarText = '',
  size = ChipSizeScalingEnum.DEFAULT,
  isSelected = false,
  showAvatar = true,
  subtextStyles = {},
  subtext2Styles = {},
  labelAndSubtextStyles = {},
}) => {
  const { textPaddingLeft, TextComponent, SubTextComponent, iconSize, avatarFontSize } =
    typeof size === 'object' ? size : sizeScaling(defaultTheme, size);

  return (
    <ChipContainer iconSize={iconSize} fontSize={avatarFontSize} data-testid="Avatar:Container">
      {showAvatar && (
        <TWAvatar
          icon={!avatarText && avatarIcon}
          alt={`Avatar for ${label}`}
          src={avatarImage}
          shape="circle"
          size={iconSize}
        >
          {avatarText}
        </TWAvatar>
      )}
      <TextAndSubTextColumn
        className="twchip-text-and-subtext"
        twPaddingLeft={showAvatar ? textPaddingLeft : 0}
        customStyles={labelAndSubtextStyles}
      >
        <TextComponent
          data-testid="Avatar:FullName"
          twFontWeight={isSelected ? 'bold' : 'medium'}
          truncate
          twColor={labelColor}
          customStyles={labelStyles}
        >
          {label}
        </TextComponent>
        {subtext && (
          <SubTextComponent
            data-testid="Avatar:Team"
            twColor="secondary"
            title={subtextTitle || subtext}
            truncate
            customStyles={subtextStyles}
          >
            {subtext}
          </SubTextComponent>
        )}
        {subtext2 && (
          <SubTextComponent
            data-testid="Avatar:Team"
            twColor="secondary"
            title={subtext2Title || subtext2}
            truncate
            customStyles={subtext2Styles}
          >
            {subtext2}
          </SubTextComponent>
        )}
      </TextAndSubTextColumn>
    </ChipContainer>
  );
};

export default TWChip;
