/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentBulkUpdateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.AppointmentBulkUpdateInput>>
    | Types.InputMaybe<Types.AppointmentBulkUpdateInput>
  >;
}>;

export type AppointmentBulkUpdateMutation = {
  appointmentBulkUpdate: {
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ message: string; field: Array<string> } | null> | null;
    }>;
  };
};

export const AppointmentBulkUpdateDocument = gql`
  mutation AppointmentBulkUpdate($input: [AppointmentBulkUpdateInput]) {
    appointmentBulkUpdate(input: $input) {
      bulkUserErrors {
        objectKey
        errorList {
          message
          field
        }
      }
    }
  }
`;
export type AppointmentBulkUpdateMutationFn = Apollo.MutationFunction<
  AppointmentBulkUpdateMutation,
  AppointmentBulkUpdateMutationVariables
>;

/**
 * __useAppointmentBulkUpdateMutation__
 *
 * To run a mutation, you first call `useAppointmentBulkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentBulkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentBulkUpdateMutation, { data, loading, error }] = useAppointmentBulkUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentBulkUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppointmentBulkUpdateMutation,
    AppointmentBulkUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AppointmentBulkUpdateMutation, AppointmentBulkUpdateMutationVariables>(
    AppointmentBulkUpdateDocument,
    options,
  );
}
export type AppointmentBulkUpdateMutationHookResult = ReturnType<
  typeof useAppointmentBulkUpdateMutation
>;
export type AppointmentBulkUpdateMutationResult =
  Apollo.MutationResult<AppointmentBulkUpdateMutation>;
export type AppointmentBulkUpdateMutationOptions = Apollo.BaseMutationOptions<
  AppointmentBulkUpdateMutation,
  AppointmentBulkUpdateMutationVariables
>;
