import fastMemoize from 'fast-memoize';
import { CSSProperties } from 'react';

import { PersonNode } from '@tw/generated';
import { TWChip } from '../../../presentational';
import TWIcon from '../../TWIcon';

type Person = Pick<PersonNode, 'picture' | 'initials'> & {
  fullName: string;
  iconType?: string;
};

interface StylesType {
  labelAndSubtextStyles?: CSSProperties;
}

const renderProfileItem = fastMemoize((person?: Person, subtext?: string, styles?: StylesType) => {
  if (!person) {
    return null;
  }

  const { picture, initials, fullName, iconType } = person;

  return (
    <TWChip
      label={fullName}
      avatarImage={picture ?? undefined}
      avatarText={initials ?? undefined}
      subtext={subtext}
      labelAndSubtextStyles={styles?.labelAndSubtextStyles}
      avatarIcon={iconType ? <TWIcon type={iconType} /> : undefined}
    />
  );
});

export default renderProfileItem;
