import { useEffect } from 'react';
import * as React from 'react';

import { getTranslation } from '@tw/i18n';
import { StudyHallStatuses } from '@tw/types';

import TWInputSelect from '../TWInputSelect';

interface TWInputSelectCheckinLocationStatusProps {
  popupContainerId: string;
  placeholder?: string;
  onChange: (status: StudyHallStatuses) => void;
  minWidth?: string;
  testID: string;
  defaultValue?: StudyHallStatuses;
  popupClassName: string;
}

const TWInputSelectCheckinLocationStatus: React.FC<TWInputSelectCheckinLocationStatusProps> = ({
  popupContainerId,
  placeholder = 'Status',
  onChange,
  minWidth = '200px',
  testID,
  defaultValue = StudyHallStatuses.Active,
  popupClassName,
}) => {
  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  }, []);

  const activeText = getTranslation('active');
  const inactiveText = getTranslation('inactive');
  return (
    <TWInputSelect
      showSearch={false}
      optionFilterProp="title"
      placeholder={placeholder}
      loading={false}
      onChange={onChange}
      popupContainerId={popupContainerId}
      minWidth={minWidth}
      testID={testID}
      popupClassName={popupClassName}
      defaultValue={defaultValue}
    >
      <TWInputSelect.Option value={StudyHallStatuses.Active} title={activeText}>
        {activeText}
      </TWInputSelect.Option>
      <TWInputSelect.Option value={StudyHallStatuses.Inactive} title={inactiveText}>
        {inactiveText}
      </TWInputSelect.Option>
    </TWInputSelect>
  );
};

export default TWInputSelectCheckinLocationStatus;
