import { useState } from 'react';
import _ from 'lodash';
import { Col, Form, Row, Button } from 'antd';

import { dateUtils, dayjs, stringUtils } from '@tw/util';
import { useConfigContainer, useGrades } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { ProfessorSurveyReportSubmitSchema } from '@tw/types';

import {
  columnRenderFunctions,
  TWDrawer,
  TWDrawerBody,
  TWDrawerDetailItem,
  TWFlexContainer,
  TWForm,
  TWChip,
  TWHeading3,
  TWHeading4,
  TWMessage,
  TWLoadingMask,
  TWSpacingContainer,
  TWTextDefault,
} from '@tw/components';

import { professorSurveyAssignmentOperations } from '@tw/services/academicService';

import { ProfessorAvatarColumn } from './StudentFeedbackDrawer.styles';

import {
  StudentFeedbackDrawerProps,
  ReportValues,
  studentFeedbackFormName,
} from './StudentFeedbackDrawer.definitions';

import StudentReportTable from '../StudentReportTable';

const fieldKeyRegex = /professor_survey_reports\[(\d+)\]\.(\w+)/;

export const StudentFeedbackDrawer: React.FC<StudentFeedbackDrawerProps> = (props) => {
  const { uuid, onSubmit, containerId, selectedSurvey = null, onClose, open = false } = props;

  const { courseSection, professorSurveyAssignmentId, professorSurveyReports } =
    selectedSurvey || {};

  const { term, professor, courseSectionSchedules } = courseSection || {};

  const [form] = Form.useForm();

  const { grades } = useGrades();

  const configContainer = useConfigContainer();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const parseFieldKey = (key: string): [string, string] => {
    const match = fieldKeyRegex.exec(key);
    return [match[1], match[2]];
  };

  const parseSurveyValues = (formValues: Record<string, string>): Record<string, ReportValues> =>
    _.reduce(
      _.keys(formValues),
      (compiledValues, key) => {
        const fieldValue = formValues[key];

        const [rowIndex, field] = parseFieldKey(key);

        const safeCompiledValues = compiledValues || {};
        const compiledRowValue = safeCompiledValues[rowIndex] || {};

        return {
          ...safeCompiledValues,
          [rowIndex]: { ...compiledRowValue, [field]: fieldValue },
        };
      },
      {},
    );

  const handleSubmitError = (message: string) => {
    TWMessage.error(`Error submitting survey report form. ${message || ''}`);
  };

  /* eslint-disable camelcase */
  const normalizeFormValues = (formValues: ReportValues): ProfessorSurveyReportSubmitSchema => {
    const { professor_survey_report_id, grade: gradeValue, absence_count, ...rest } = formValues;
    return {
      ...rest,
      professor_survey_report_id: Number(professor_survey_report_id),
      grade_id: gradeValue
        ? Number(_.find(grades, (grade) => grade.label.toLocaleUpperCase() === gradeValue).gradeId)
        : undefined,
      absence_count:
        absence_count !== undefined && absence_count !== null ? Number(absence_count) : null,
    };
  };
  /* eslint-enable camelcase */

  const submit = async (formValues: Record<string, string>, status: string) => {
    const reports = _.isEmpty(formValues)
      ? { professor_survey_reports: [] }
      : {
          professor_survey_reports: _.map(
            _.values(parseSurveyValues(formValues)),
            normalizeFormValues,
          ),
        };

    const updatedValues = {
      uuid,
      status,
      ...reports,
    };

    setIsSubmitting(true);

    try {
      await professorSurveyAssignmentOperations.updateProfessorSurveyAssignment(
        professorSurveyAssignmentId,
        updatedValues,
        configContainer,
      );
      onSubmit(status);
    } catch (err) {
      handleSubmitError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSaveForLater = async () => {
    submit(form.getFieldsValue(), 'in_progress');
  };

  const handleSubmit = async (values: Record<string, string>) => {
    submit(values, 'completed');
  };

  const createScheduleInfo = () => ({
    courseSectionSchedules,
    hasMultipleTimes:
      _.uniqBy(courseSectionSchedules, 'startTime').length > 1 ||
      _.uniqBy(courseSectionSchedules, 'endTime').length > 1,
    startTime: courseSectionSchedules?.[0]?.startTime,
    endTime: courseSectionSchedules?.[0]?.endTime,
  });

  if (!selectedSurvey) {
    return null;
  }

  const courseSectionLabel = `${courseSection?.course.label}-${courseSection?.shortCode}`;
  const professorName = professor ? `${professor.firstName} ${professor.lastName}` : null;
  const professorInitials = professorName
    ? stringUtils.getInitialsFromFullName(professorName)
    : null;

  const sortedCourseDays = dateUtils
    .sortWeekdays(
      _.uniq(_.map(courseSectionSchedules || [], (schedule) => _.capitalize(schedule?.day) || '')),
    )
    .join(', ');

  return (
    <div>
      <TWDrawer
        containerId={containerId}
        placement="right"
        testID="TWRouterPage__StudentFeedback"
        closable={false}
        destroyOnClose
        onClose={onClose}
        open={open}
        width="100%"
      >
        <TWDrawerBody>
          {isSubmitting || (_.isEmpty(grades) && <TWLoadingMask />)}
          <TWFlexContainer twMarginBottom={1.5} align="baseline" flex="0 0 auto">
            <TWHeading3>{`${courseSectionLabel}: ${courseSection?.course.description}`}</TWHeading3>
            <TWTextDefault>{term.label}</TWTextDefault>
          </TWFlexContainer>
          <TWSpacingContainer twPaddingTop={2} twPaddingBottom={2}>
            <TWFlexContainer twMarginBottom={1.5}>
              <Row>
                <Col span={12}>
                  <TWHeading4>{getTranslation('summary')}</TWHeading4>
                  <Row gutter={8} align="middle" justify="start">
                    <Col span={12}>
                      <TWDrawerDetailItem
                        iconTitle="material-import_contacts"
                        text={courseSectionLabel}
                      />
                      <TWDrawerDetailItem iconTitle="tw-date" text={sortedCourseDays} />
                    </Col>
                    <Col span={12}>
                      <TWDrawerDetailItem
                        iconTitle="tw-location"
                        text={_.get(courseSectionSchedules?.[0], 'location') || '-'}
                      />
                      <TWDrawerDetailItem
                        iconTitle="tw-time"
                        text={columnRenderFunctions.renderCourseSectionTimes(
                          createScheduleInfo(),
                          dayjs.tz.guess(),
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <ProfessorAvatarColumn span={12}>
                  <TWHeading4>{getTranslation('instructor', 1)}</TWHeading4>
                  <TWChip size="large" avatarText={professorInitials} label={professorName} />
                </ProfessorAvatarColumn>
              </Row>
            </TWFlexContainer>
          </TWSpacingContainer>
          {!_.isEmpty(grades) && (
            <TWForm
              id={`${containerId}_StudentFeedbackForm`}
              form={form}
              name={studentFeedbackFormName}
              onFinish={handleSubmit}
            >
              <Row>
                <Col span={24}>
                  <StudentReportTable
                    containerId={`${containerId}_StudentFeedbackForm`}
                    professorSurveyReports={professorSurveyReports}
                  />
                </Col>
              </Row>
              <TWFlexContainer twMarginTop={1.5}>
                <Row>
                  <Button type="default" htmlType="button" onClick={onClose}>
                    {getTranslation('back')}
                  </Button>
                  <TWFlexContainer justify="flex-end" twMarginLeft={1}>
                    <Button
                      type="primary"
                      disabled={isSubmitting}
                      htmlType="submit"
                      style={{ float: 'right' }}
                    >
                      {getTranslation('submit')}
                    </Button>
                  </TWFlexContainer>
                  <TWFlexContainer justify="flex-end" twMarginLeft={1}>
                    <Button
                      type="default"
                      disabled={isSubmitting}
                      htmlType="button"
                      onClick={handleSaveForLater}
                      style={{ float: 'right' }}
                    >
                      {getTranslation('instructorSurvey.studentFeedbackForm.saveForLater')}
                    </Button>
                  </TWFlexContainer>
                </Row>
              </TWFlexContainer>
            </TWForm>
          )}
        </TWDrawerBody>
      </TWDrawer>
    </div>
  );
};
