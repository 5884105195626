/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBulletinsSettingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetBulletinsSettingsQuery = {
  bulletinsSettings?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      birthday?: {
        pk?: string | null;
        bulletinsSettingsId: string;
        isBirthdaysEnabled: boolean;
        birthdaysAnnouncementCodes?: Array<string | null> | null;
        birthdaysVisibilityCodes?: Array<string | null> | null;
        label?: string | null;
      } | null;
    } | null>;
  } | null;
};

export const GetBulletinsSettingsDocument = gql`
  query GetBulletinsSettings {
    bulletinsSettings(sort: label_asc) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        birthday: node {
          pk
          bulletinsSettingsId
          isBirthdaysEnabled
          birthdaysAnnouncementCodes
          birthdaysVisibilityCodes
          label
        }
      }
    }
  }
`;

/**
 * __useGetBulletinsSettingsQuery__
 *
 * To run a query within a React component, call `useGetBulletinsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulletinsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulletinsSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBulletinsSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBulletinsSettingsQuery,
    GetBulletinsSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBulletinsSettingsQuery, GetBulletinsSettingsQueryVariables>(
    GetBulletinsSettingsDocument,
    options,
  );
}
export function useGetBulletinsSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBulletinsSettingsQuery,
    GetBulletinsSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBulletinsSettingsQuery, GetBulletinsSettingsQueryVariables>(
    GetBulletinsSettingsDocument,
    options,
  );
}
export type GetBulletinsSettingsQueryHookResult = ReturnType<typeof useGetBulletinsSettingsQuery>;
export type GetBulletinsSettingsLazyQueryHookResult = ReturnType<
  typeof useGetBulletinsSettingsLazyQuery
>;
export type GetBulletinsSettingsQueryResult = Apollo.QueryResult<
  GetBulletinsSettingsQuery,
  GetBulletinsSettingsQueryVariables
>;
