import { useTheme } from '@emotion/react';
import { ModalProps } from 'antd/lib/modal';
import { isValidElement, ReactNode } from 'react';

import { TWIcon } from '@tw/components';
import { DOMUtils } from '@tw/util';

import { TWHeading1 } from '../../presentational';
import { TWModalContainer } from './TWModal.styles';

export interface TWModalProps extends ModalProps {
  children: React.ReactNode;
  className?: string;
  containerId?: string;
  open?: boolean;
  testID?: string;
  id?: string;
  contentHeight?: string | number;
  overflowY?: React.CSSProperties['overflowY'];
}

export const TWModal = ({
  children,
  className = '',
  containerId,
  open = false,
  title = null,
  bodyStyle = {},
  contentHeight = 'unset',
  overflowY = 'inherit',
  testID = '',
  centered = true,
  ...restOfProps
}: TWModalProps) => {
  const theme = useTheme();
  const isTitleReactEl = isValidElement(title);

  let titleContent: ReactNode = null;
  if (title !== null) {
    titleContent = isTitleReactEl ? (
      title
    ) : (
      <TWHeading1 data-testid={`${testID}Modal:Title`}>{title}</TWHeading1>
    );
  }

  return (
    <TWModalContainer
      mask
      closeIcon={
        <TWIcon
          data-testid={`${testID}Modal:Close`}
          height={24}
          width={24}
          type="material-close"
          style={{ verticalAlign: 'middle' }}
        />
      }
      className={className}
      open={open}
      centered={centered}
      getContainer={() => DOMUtils.getContainer(containerId)}
      title={titleContent}
      bodyStyle={{
        backgroundColor: theme.colors.background,
        height: contentHeight || 'unset',
        overflowY,
        ...bodyStyle,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restOfProps}
    >
      {children}
    </TWModalContainer>
  );
};
