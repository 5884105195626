import { Input } from 'antd';

const { TextArea } = Input;

interface TWQuickFormTextAreaProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

const TWQuickFormTextArea = ({ value, onChange, disabled }: TWQuickFormTextAreaProps) => {
  const handleOnChange = (event: { target: { value: string } }) => {
    onChange(event.target.value);
  };

  return <TextArea disabled={disabled} rows={4} onChange={handleOnChange} value={value} />;
};

export default TWQuickFormTextArea;
