/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAppointmentDetailsQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  appointmentId?: Types.InputMaybe<Types.Scalars['TWFilter_ID']>;
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type FetchAppointmentDetailsQuery = {
  allAppointments?: {
    edges: Array<{
      node?: {
        appointmentId: string;
        complianceType?: string | null;
        countableEndHour?: number | null;
        countableEndMinute?: number | null;
        countableMinutes?: number | null;
        countableStartHour?: number | null;
        countableStartMinute?: number | null;
        createdDate?: any | null;
        enableCheckins?: boolean | null;
        enableRsvps?: boolean | null;
        endDate?: any | null;
        isAllDay: boolean;
        isClass?: boolean | null;
        isPrivate?: boolean | null;
        isMandatory?: boolean | null;
        isRecurring?: boolean | null;
        location: string;
        meta?: any | null;
        notes?: string | null;
        orgId: number;
        title?: string | null;
        rrule?: string | null;
        sendRsvpAlerts?: string | null;
        startDate?: any | null;
        startTimeZone?: string | null;
        appointmentCheckin?: {
          checkoutEnabled?: boolean | null;
          hasCheckinActions?: boolean | null;
          rotateQrCode?: boolean | null;
          checkinAdminSelectables?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | {
                active: boolean;
                id: string;
                initials?: string | null;
                lastName?: string | null;
                pictureUrl?: string | null;
                preferredName?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              }
            | {
                groupType?: Types.GroupType | null;
                pluralLabel?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  active: boolean;
                  id: string;
                  initials?: string | null;
                  label?: string | null;
                  lastName?: string | null;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  selectionCode?: string | null;
                  teamMembership?: { isAthlete?: boolean | null } | null;
                } | null> | null;
              }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
        } | null;
        appointmentType?: {
          appointmentTypeId?: number | null;
          color: string;
          label: string;
          isDayOff?: boolean | null;
        } | null;
        attachments?: Array<{
          id: string;
          fileName: string;
          contentType?: string | null;
          downloadUrl?: string | null;
          fileSize?: number | null;
        } | null> | null;
        attendees?: Array<
          | { label?: string | null; peopleCount?: number | null; selectionCode?: string | null }
          | {
              active: boolean;
              id: string;
              initials?: string | null;
              lastName?: string | null;
              personId: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              label?: string | null;
              peopleCount?: number | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: Types.GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              peopleCount?: number | null;
              selectionCode?: string | null;
              people?: Array<{
                active: boolean;
                id: string;
                initials?: string | null;
                label?: string | null;
                lastName?: string | null;
                personId: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; peopleCount?: number | null; selectionCode?: string | null }
          | null
        > | null;
        creator?: {
          active: boolean;
          id: string;
          initials?: string | null;
          lastName?: string | null;
          label?: string | null;
          pictureUrl?: string | null;
          preferredName?: string | null;
          selectionCode?: string | null;
        } | null;
        occurrences?: Array<{
          appointmentId?: number | null;
          appointmentOccurrenceId?: number | null;
          endDateTime?: any | null;
          rsvpStatus?: Types.AppointmentRsvpStatusEnum | null;
          startDateTime?: any | null;
          appointmentCheckinOccurrence?: { appointmentCheckinOccurrenceId?: any | null } | null;
          occurrenceRsvpData?: Array<{
            personId?: number | null;
            status?: string | null;
          } | null> | null;
          rsvpCounts?: {
            AWAITING?: number | null;
            MAYBE?: number | null;
            NO?: number | null;
            YES?: number | null;
          } | null;
        } | null> | null;
        reminders?: Array<{
          reminderType?: string | null;
          offsetMinutes?: number | null;
        } | null> | null;
        userMetadata?: {
          canDelete?: boolean | null;
          canEdit?: boolean | null;
          canViewDetails?: boolean | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchAppointmentDetailsDocument = gql`
  query FetchAppointmentDetails(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $appointmentId: TWFilter_ID
    $selections: [String]!
  ) {
    allAppointments(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      appointmentId: $appointmentId
      selections: $selections
    ) {
      edges {
        node {
          appointmentId
          complianceType
          countableEndHour
          countableEndMinute
          countableMinutes
          countableStartHour
          countableStartMinute
          createdDate
          enableCheckins
          enableRsvps
          endDate
          isAllDay
          isClass
          isPrivate
          isMandatory
          isRecurring
          location
          meta
          notes
          orgId
          title
          rrule
          sendRsvpAlerts
          startDate
          startTimeZone
          appointmentCheckin {
            checkoutEnabled
            hasCheckinActions
            rotateQrCode
            checkinAdminSelectables {
              label
              selectionCode
              ... on PersonNode {
                active
                id
                initials
                lastName
                pictureUrl
                preferredName
                teamMembership {
                  isAthlete
                }
              }
              ... on SelectionGroup {
                groupType
                pluralLabel
                people {
                  active
                  id
                  initials
                  label
                  lastName
                  pictureUrl
                  preferredName
                  selectionCode
                  teamMembership {
                    isAthlete
                  }
                }
              }
            }
          }
          appointmentType {
            appointmentTypeId
            color
            label
            isDayOff
          }
          attachments {
            id
            fileName
            contentType
            downloadUrl
            fileSize
          }
          attendees {
            label
            peopleCount
            selectionCode
            ... on PersonNode {
              active
              id
              initials
              lastName
              personId
              pictureUrl
              preferredName
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                active
                id
                initials
                label
                lastName
                personId
                pictureUrl
                preferredName
                selectionCode
                teamMembership {
                  isAthlete
                }
              }
            }
          }
          creator {
            active
            id
            initials
            lastName
            label
            pictureUrl
            preferredName
            selectionCode
          }
          occurrences {
            appointmentId
            appointmentOccurrenceId
            endDateTime
            rsvpStatus
            startDateTime
            appointmentCheckinOccurrence {
              appointmentCheckinOccurrenceId
            }
            occurrenceRsvpData {
              personId
              status
            }
            rsvpCounts {
              AWAITING
              MAYBE
              NO
              YES
            }
          }
          reminders {
            reminderType
            offsetMinutes
          }
          userMetadata {
            canDelete
            canEdit
            canViewDetails
          }
        }
      }
    }
  }
`;

/**
 * __useFetchAppointmentDetailsQuery__
 *
 * To run a query within a React component, call `useFetchAppointmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAppointmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAppointmentDetailsQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      appointmentId: // value for 'appointmentId'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useFetchAppointmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchAppointmentDetailsQuery,
    FetchAppointmentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAppointmentDetailsQuery, FetchAppointmentDetailsQueryVariables>(
    FetchAppointmentDetailsDocument,
    options,
  );
}
export function useFetchAppointmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAppointmentDetailsQuery,
    FetchAppointmentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAppointmentDetailsQuery, FetchAppointmentDetailsQueryVariables>(
    FetchAppointmentDetailsDocument,
    options,
  );
}
export type FetchAppointmentDetailsQueryHookResult = ReturnType<
  typeof useFetchAppointmentDetailsQuery
>;
export type FetchAppointmentDetailsLazyQueryHookResult = ReturnType<
  typeof useFetchAppointmentDetailsLazyQuery
>;
export type FetchAppointmentDetailsQueryResult = Apollo.QueryResult<
  FetchAppointmentDetailsQuery,
  FetchAppointmentDetailsQueryVariables
>;
