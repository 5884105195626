import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';

import timeZoneData from './timeZoneData';

export interface TimezoneGroup {
  groupLabel: string;
  options: TimezoneOptions[];
}
export interface TimezoneOptions {
  code: string;
  label: string;
  key: string;
}

const timeZoneListByCode = keyBy(
  timeZoneData.reduce((acc, country) => [...acc, ...country.timeZones], []),
  'code',
);

const timeZonesSectionsList = timeZoneData.map((timeZoneItem) => ({
  data: timeZoneItem.timeZones,
  title: timeZoneItem.label,
}));

// this formats the timezone data to be used in an antd select component with optGroups
const timeZoneArrayForOptGroupSelect: TimezoneGroup[] = sortBy(
  timeZoneData.map(
    (country) => ({
      groupLabel: country.label,
      options: sortBy(
        country.timeZones.map((timeZone) => ({
          code: timeZone.code,
          label: timeZone.label,
          key: `${country.label} - ${timeZone.code}`,
        })),
        ['label'],
      ),
    }),
    'groupLabel',
  ),
);

// for (US) user convenience, we put US first in the list, even though it's not first alphabetically
const countryList = timeZoneData.reduce(
  (result, timezone) => {
    if (timezone.id !== 'US') {
      result.push({
        label: timezone.label,
        value: timezone.id,
      });
    }
    return result;
  },
  [
    {
      label: 'United States',
      value: 'US',
    },
  ],
);

export { countryList, timeZonesSectionsList, timeZoneListByCode, timeZoneArrayForOptGroupSelect };
