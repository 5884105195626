import { ThemeProvider, CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { PropsWithChildren, createContext, useMemo, useState } from 'react';
import { updatedTheme as initialTheme, Theme } from '@tw/components/utils';
import createExtraScopePlugin from '@tw/util/emotionUtils';

const ThemeContext = createContext({
  theme: initialTheme,
  updateTheme: (theme: Partial<Theme>) => {
    if (theme === undefined) {
      console.error('No theme was provided');
    }
  },
});

const EmotionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [theme, setTheme] = useState(initialTheme);

  const cache = useMemo(
    () =>
      createCache({
        key: 'tw',
        stylisPlugins: [createExtraScopePlugin('.react-root')],
      }),
    [],
  );

  return (
    <ThemeContext.Provider
      value={{
        theme,
        updateTheme: (newTheme) =>
          setTheme({
            ...initialTheme,
            ...newTheme,
          }),
      }}
    >
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </CacheProvider>
    </ThemeContext.Provider>
  );
};

export default EmotionProvider;
