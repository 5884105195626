import { Col, Row } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { noopFn } from '@tw/constants';
import { useConfigContainer, useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { studentOperations, studentTermOperations } from '@tw/services/academicService';
import { StudentTerm } from '@tw/types';
import { statusAlert } from '@tw/util';

import { TWAttributeStacked, TWIconWithContent } from '../..';
import TWEditableEligibility from '../TWEditableEligibility';
import TWEditableInputNumber from '../TWEditableInputNumber';
import { AttributeGridWrapper, iconWithContentStyle } from './TWStudentSummary.styles';

const GPA_MIN = 0;
const GPA_MAX = 9.999;

interface TWStudentSummaryProps {
  containerId: string;
  studentTermItem: StudentTerm;
  style: {};
  onUpdate: () => void;
  studentId: number;
  studentTermFilters: {};
}

export const TWStudentSummary = (props: TWStudentSummaryProps) => {
  const {
    containerId,
    studentTermItem = null,
    style: externalStyles = null,
    onUpdate = noopFn,
    studentId,
    studentTermFilters,
  } = props;

  const configContainer = useConfigContainer();
  const { personId, teamId } = useViewer();

  const cumulativeGpa = studentTermItem?.student.cumulativeGpa;
  const eligibility = studentTermItem?.student.isAtRisk;
  const cumulativeHours = studentTermItem?.creditsCompleted;

  const styles = _.defaultsDeep({}, externalStyles);

  const [isGpaLoading, setIsGpaLoading] = useState(null);
  const [isEligibilityLoading, setIsEligibilityLoading] = useState(null);
  const [isCumulativeHoursLoading, setIsCumulativeHoursLoading] = useState(null);

  useEffect(() => {
    setIsGpaLoading(!cumulativeGpa);
    setIsCumulativeHoursLoading(!cumulativeHours);
  }, [cumulativeGpa, cumulativeHours]);

  const updateStudent = (
    updatedValues: { cumulativeGpa?: number; isAtRisk?: boolean },
    loadingCallback: (loading: boolean) => void,
  ) => {
    loadingCallback(true);

    studentOperations
      .updateStudent(personId, teamId, studentId, updatedValues, configContainer)
      .then(() => {
        loadingCallback(false);
        onUpdate();
      })
      .catch(() => {
        loadingCallback(false);

        statusAlert.error(
          containerId,
          `${getTranslation('warnings.unableUpdateStudents')} ${getTranslation(
            'warnings.contactAdministrators',
          )}`,
        );
      });
  };

  const updateStudentTerm = (
    updatedValues: { creditsCompleted?: number },
    loadingCallback: (loading: boolean) => void,
  ) => {
    loadingCallback(true);

    studentTermOperations
      .updateStudentTerm(
        personId,
        teamId,
        studentTermItem?.studentTermId,
        updatedValues,
        studentTermFilters,
        configContainer,
      )
      .then(() => {
        loadingCallback(false);
        onUpdate();
      })
      .catch(() => {
        loadingCallback(false);

        statusAlert.error(
          containerId,
          `${getTranslation('warnings.unableUpdateStudents')} ${getTranslation(
            'warnings.contactAdministrators',
          )}`,
        );
      });
  };

  const handleGpaChange = (value: number) => {
    // validation
    if (value < GPA_MIN || value > GPA_MAX) {
      statusAlert.error(
        containerId,
        `${getTranslation('warnings.unableUpdateStudents')} ${getTranslation('warnings.gpaRange')}`,
      );
    } else {
      updateStudent({ cumulativeGpa: value }, setIsGpaLoading);
    }
  };

  const handleEligibilityChange = (value: boolean) => {
    updateStudent({ isAtRisk: value }, setIsEligibilityLoading);
  };

  const handleCumulativeHoursChange = (value: number) => {
    updateStudentTerm({ creditsCompleted: value }, setIsCumulativeHoursLoading);
  };

  if (!studentTermItem) {
    return null;
  }

  return (
    <AttributeGridWrapper>
      <Row gutter={8}>
        <Col span={12}>
          <TWAttributeStacked
            testID="Students:Profiles:StudentDrawer:Attributes:GPA"
            style={styles.attributeStacked}
            label={getTranslation('studentsTab.gpa')}
            value={
              <TWEditableInputNumber
                containerId={containerId}
                isLoading={isGpaLoading}
                onChange={handleGpaChange}
                value={cumulativeGpa}
                min={GPA_MIN}
                max={GPA_MAX}
                precision={3}
              />
            }
          />
        </Col>
        <Col span={12}>
          <TWAttributeStacked
            testID="Students:Profiles:StudentDrawer:Attributes:Eligibility"
            style={styles.attributeStacked}
            label={getTranslation('studentsTab.eligibility')}
            value={
              <TWEditableEligibility
                containerId={containerId}
                isLoading={isEligibilityLoading}
                onChange={handleEligibilityChange}
                isAtRisk={eligibility}
                style={styles.eligibility}
              />
            }
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <TWAttributeStacked
            testID="Students:Profiles:StudentDrawer:Attributes:CurrentHours"
            style={styles.attributeStacked}
            label={getTranslation('studentsTab.currentHours')}
            value={
              <TWIconWithContent
                style={iconWithContentStyle}
                iconName="material-schedule"
                content={studentTermItem?.creditsAttempted}
              />
            }
          />
        </Col>
        <Col span={12}>
          <TWAttributeStacked
            testID="Students:Profiles:StudentDrawer:Attributes:CumulativeHours"
            style={styles.attributeStacked}
            label={getTranslation('studentsTab.cumulativeHours', 1)}
            value={
              <TWIconWithContent
                style={iconWithContentStyle}
                iconName="material-schedule"
                content={
                  <TWEditableInputNumber
                    containerId={containerId}
                    isLoading={isCumulativeHoursLoading}
                    onChange={handleCumulativeHoursChange}
                    value={_.isFinite(Number(cumulativeHours)) ? cumulativeHours : '-'}
                  />
                }
              />
            }
          />
        </Col>
      </Row>
    </AttributeGridWrapper>
  );
};
