import { MutationHookOptions, useMutation } from '@apollo/client';

import DELETE_SESSION from './DeleteSession.graphql';

export interface DeleteSessionInput {
  id: string;
}

export interface DeleteSessionData {
  sessionDelete: {
    session: {
      id: string;
    };
    userErrors: Array<{ field: string[]; message: string }>;
  };
}

export const useDeleteSession = (
  options?: MutationHookOptions<DeleteSessionData, DeleteSessionInput>,
) => useMutation<DeleteSessionData, DeleteSessionInput>(DELETE_SESSION, options);
