/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketingEventPersonDefaultAllocationBulkUpdateMutationVariables = Types.Exact<{
  input: Types.TicketingEventPersonDefaultAllocationBulkUpdateInput;
}>;

export type TicketingEventPersonDefaultAllocationBulkUpdateMutation = {
  ticketingEventPersonDefaultAllocationBulkUpdate: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    bulkUserErrors: Array<{ errorList?: Array<{ message: string } | null> | null }>;
  };
};

export const TicketingEventPersonDefaultAllocationBulkUpdateDocument = gql`
  mutation TicketingEventPersonDefaultAllocationBulkUpdate(
    $input: TicketingEventPersonDefaultAllocationBulkUpdateInput!
  ) {
    ticketingEventPersonDefaultAllocationBulkUpdate(input: $input) {
      userErrors {
        field
        message
      }
      bulkUserErrors {
        errorList {
          message
        }
      }
    }
  }
`;
export type TicketingEventPersonDefaultAllocationBulkUpdateMutationFn = Apollo.MutationFunction<
  TicketingEventPersonDefaultAllocationBulkUpdateMutation,
  TicketingEventPersonDefaultAllocationBulkUpdateMutationVariables
>;

/**
 * __useTicketingEventPersonDefaultAllocationBulkUpdateMutation__
 *
 * To run a mutation, you first call `useTicketingEventPersonDefaultAllocationBulkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingEventPersonDefaultAllocationBulkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingEventPersonDefaultAllocationBulkUpdateMutation, { data, loading, error }] = useTicketingEventPersonDefaultAllocationBulkUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketingEventPersonDefaultAllocationBulkUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TicketingEventPersonDefaultAllocationBulkUpdateMutation,
    TicketingEventPersonDefaultAllocationBulkUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TicketingEventPersonDefaultAllocationBulkUpdateMutation,
    TicketingEventPersonDefaultAllocationBulkUpdateMutationVariables
  >(TicketingEventPersonDefaultAllocationBulkUpdateDocument, options);
}
export type TicketingEventPersonDefaultAllocationBulkUpdateMutationHookResult = ReturnType<
  typeof useTicketingEventPersonDefaultAllocationBulkUpdateMutation
>;
export type TicketingEventPersonDefaultAllocationBulkUpdateMutationResult =
  Apollo.MutationResult<TicketingEventPersonDefaultAllocationBulkUpdateMutation>;
export type TicketingEventPersonDefaultAllocationBulkUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    TicketingEventPersonDefaultAllocationBulkUpdateMutation,
    TicketingEventPersonDefaultAllocationBulkUpdateMutationVariables
  >;
