import { forwardRef, PropsWithChildren } from 'react';
import { Transition } from 'react-transition-group';

import { TWIcon } from '@tw/components';

import { listItemParentSizeScaling } from '../utils';
import { SizeScalingType } from '../../../utils';
import { TWFlexContainer } from '../..';
import { Container, ExpandedLeafsContainer, IconContainer } from './TWListItemParent.styles';

interface TWListItemParentProps extends PropsWithChildren {
  testID: string;
  label: string;
  isExpanded?: boolean;
  isHighlighted?: boolean;
  disableExpanded?: boolean;
  size?: SizeScalingType;
  leftNode?: React.ReactNode;
  rightNode?: React.ReactNode;
  onClick?: () => void;
}

const TWListItemParent = forwardRef<HTMLDivElement, TWListItemParentProps>(
  (
    {
      disableExpanded,
      isExpanded,
      size,
      leftNode,
      rightNode,
      onClick,
      children,
      isHighlighted,
      testID,
      label,
    },
    ref,
  ) => {
    const { padding, TextComponent, leftIconSize } = listItemParentSizeScaling[size];

    const renderChevronIcon = () => {
      if (!disableExpanded) {
        return (
          <Transition in={isExpanded} timeout={0}>
            {(state) => (
              <>
                <IconContainer state={state}>
                  <TWIcon
                    type="material-chevron_right"
                    width={leftIconSize}
                    height={leftIconSize}
                  />
                </IconContainer>
                {leftNode}
              </>
            )}
          </Transition>
        );
      }
      return null;
    };

    return (
      <>
        <Container
          isHighlighted={isHighlighted}
          twPadding={padding}
          role="button"
          data-testid={testID}
          onClick={onClick}
          ref={ref}
        >
          {renderChevronIcon()}
          <TextComponent twColor="buttonText" truncate>
            {label}
          </TextComponent>
          {rightNode && <TWFlexContainer flex={1}>{rightNode}</TWFlexContainer>}
        </Container>

        <Transition in={isExpanded || disableExpanded} timeout={0}>
          {(state) => <ExpandedLeafsContainer state={state}>{children}</ExpandedLeafsContainer>}
        </Transition>
      </>
    );
  },
);

TWListItemParent.defaultProps = {
  disableExpanded: false,
  isHighlighted: false,
  isExpanded: false,
  size: 'default',
  leftNode: null,
  rightNode: null,
  onClick: () => null,
};

export default TWListItemParent;
