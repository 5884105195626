/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserVideoActionCreateMutationVariables = Types.Exact<{
  input: Types.UserFileVideoActionInput;
}>;

export type UserVideoActionCreateMutation = {
  userFileVideoActionCreate: { userFileVideoAction?: { userFileVideoActionId: string } | null };
};

export const UserVideoActionCreateDocument = gql`
  mutation UserVideoActionCreate($input: UserFileVideoActionInput!) {
    userFileVideoActionCreate(input: $input) {
      userFileVideoAction {
        userFileVideoActionId
      }
    }
  }
`;
export type UserVideoActionCreateMutationFn = Apollo.MutationFunction<
  UserVideoActionCreateMutation,
  UserVideoActionCreateMutationVariables
>;

/**
 * __useUserVideoActionCreateMutation__
 *
 * To run a mutation, you first call `useUserVideoActionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserVideoActionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userVideoActionCreateMutation, { data, loading, error }] = useUserVideoActionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserVideoActionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserVideoActionCreateMutation,
    UserVideoActionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserVideoActionCreateMutation, UserVideoActionCreateMutationVariables>(
    UserVideoActionCreateDocument,
    options,
  );
}
export type UserVideoActionCreateMutationHookResult = ReturnType<
  typeof useUserVideoActionCreateMutation
>;
export type UserVideoActionCreateMutationResult =
  Apollo.MutationResult<UserVideoActionCreateMutation>;
export type UserVideoActionCreateMutationOptions = Apollo.BaseMutationOptions<
  UserVideoActionCreateMutation,
  UserVideoActionCreateMutationVariables
>;
