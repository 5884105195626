import { makeConstantsObject } from '../constants';

const DEVICE_TYPES = makeConstantsObject(['IPHONE', 'IPHONEX', 'ANDROID', 'TABLET']);

const DEVICE_ORIENTATIONS = makeConstantsObject(['LANDSCAPE', 'PORTRAIT']);

const DISTANCE_FROM_TOP = makeConstantsObject(['APP_BAR', 'STATUS_BAR', 'ZERO', 'LIGHTBOX']);

// These are used to detect whether we're running in Chrome ("Debug JS Remotely") or in the device's
// own JS engine, and it should ONLY be used for debugging items that *cannot* be enabled except
// when running with a remote console attached.
// (This is different from __DEV__, which will be true for all dev builds -- including end-to-end tests)
//
// This is a cleaned-up, eslint-fixed version of a snippet from
// https://stackoverflow.com/questions/38903329/is-there-a-way-to-programmatically-detect-when-the-app-is-running-in-chrome-debu
const isNode =
  typeof global !== 'undefined' && Object.prototype.toString.call(global) === '[object global]';
const isWebWorker =
  !isNode &&
  typeof WorkerGlobalScope !== 'undefined' &&
  typeof importScripts === 'function' &&
  // eslint-disable-next-line no-undef
  typeof navigator !== 'undefined' &&
  typeof WorkerNavigator !== 'undefined' &&
  navigator instanceof WorkerNavigator;

const HAS_REMOTE_DEBUGGING_CONSOLE = isWebWorker;

export { DEVICE_TYPES, DEVICE_ORIENTATIONS, DISTANCE_FROM_TOP, HAS_REMOTE_DEBUGGING_CONSOLE };
