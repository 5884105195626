import { ApolloError } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { ReactNode } from 'react';

import { TWIcon } from '@tw/components';
import { IconInterface } from '@tw/components/presentational/listItems/utils';
import { GroupType, PersonNode, SelectionGroup, UserSelectionOptionsQuery } from '@tw/generated';
import { getTranslation } from '@tw/i18n';
import { formatUtils } from '@tw/util';

import { TWListItemAvatar } from '../../../presentational';
import { UserSelectionFullBase } from '../@components';
import {
  Container,
  SelectedContainer,
  StyledTWErrorAlertDeprecated,
} from './TWUserSelectionFullMulti.styles';
import { MultiSelectManagementInterface } from './useMultiSelect';

interface TWUserSelectionFullMultiProps {
  setTeamFilter: (teamId: string) => void;
  teamFilter?: string | number;
  loading?: boolean;
  data?: UserSelectionOptionsQuery;
  alwaysSelectedCodes?: string[];
  hideAlwaysSelected?: boolean;
  userTypesOnly?: boolean;
  userTypeGroupsOnly?: boolean;
  individualsOnly: boolean;
  includesAllTeams?: boolean;
  canSwitchTeams?: boolean;
  disableSwitchTeams?: boolean;
  disabledGroupTypes?: GroupType[];
  includeCustomGroups?: boolean;
  error?: ApolloError;
  disabledSelections?: string[];
  hideDisabled?: boolean;
  viewCanModifyUsersTeamOnly?: boolean;
}
// =================================================

const TWUserSelectionFullMulti = ({
  handleOnDeselect,
  handleOnExplode,
  canSwitchTeams = true,
  disableSwitchTeams = false,
  includeCustomGroups = true,
  selectionItemsByCode,
  alwaysSelectedCodes,
  hideAlwaysSelected,
  userTypesOnly = false,
  disabledSelections = [],
  hideDisabled = false,
  error,
  viewCanModifyUsersTeamOnly,
  ...restOfProps
}: MultiSelectManagementInterface & TWUserSelectionFullMultiProps) => {
  const { colors } = useTheme();

  const renderSelectedList = (): ReactNode[] =>
    Object.values(selectionItemsByCode)?.map((selectedListItem) => {
      const isAlwaysSelected = alwaysSelectedCodes?.includes(selectedListItem.selectionCode);

      if (isAlwaysSelected && hideAlwaysSelected) {
        return null;
      }

      const id = `UserSelection:ModalSelectedItem-${selectedListItem.selectionCode}`;

      const isGroup = ('groupType' in selectedListItem && selectedListItem.groupType) ?? false;

      const icons: IconInterface[] = [];

      // Only allow deselect if not in "alwaysSelectedCodes"
      if (!isAlwaysSelected) {
        icons.unshift({
          type: 'material-close',
          tooltip: getTranslation('remove'),
          iconOnClick: (): void => handleOnDeselect(selectedListItem.selectionCode),
        });
      }

      if (
        !userTypesOnly &&
        'groupType' in selectedListItem &&
        selectedListItem.groupType !== GroupType.Everyone
      ) {
        icons.unshift({
          type: 'tw-expand-list',
          tooltip: getTranslation('userSelection.explode'),
          iconOnClick: (): void => handleOnExplode(selectedListItem.selectionCode),
        });
      }

      // if there is no key 'active' we set true value by default
      const isActive = 'active' in selectedListItem ? selectedListItem.active ?? true : true;

      if (!isActive) {
        icons.unshift({
          type: 'material-error',
          tooltip: getTranslation('inactiveUser'),
          style: { color: colors.negative },
        });
      }

      const { pictureUrl, initials, label } = selectedListItem as PersonNode;
      const { pluralLabel } = selectedListItem as SelectionGroup;

      return (
        <TWListItemAvatar
          key={id}
          avatarIcon={<TWIcon type={isGroup ? 'tw-group' : 'tw-user'} />}
          avatarImage={pictureUrl ?? undefined}
          avatarText={initials ?? undefined}
          testID={id}
          label={
            (isGroup
              ? formatUtils.getSelectionGroupLabel(
                  selectedListItem.groupType as GroupType,
                  pluralLabel ?? '',
                )
              : label) ?? <span />
          }
          size="default"
          rightIcons={icons}
          padding="8px 12px 8px 8px"
        />
      );
    });

  // =================================================

  return (
    <>
      <StyledTWErrorAlertDeprecated apolloError={error} data-testid="UserSelection:ErrorMessage" />
      <Container id="TWUserSelectionFullMulti">
        <UserSelectionFullBase
          selectionItemsByCode={selectionItemsByCode}
          userTypesOnly={userTypesOnly}
          canSwitchTeams={canSwitchTeams}
          disableSwitchTeams={disableSwitchTeams}
          includesCustomGroups={includeCustomGroups}
          alwaysSelectedCodes={alwaysSelectedCodes}
          hideAlwaysSelected={hideAlwaysSelected}
          disabledSelections={disabledSelections}
          hideDisabled={hideDisabled}
          viewCanModifyUsersTeamOnly={viewCanModifyUsersTeamOnly}
          {...restOfProps}
        />
        {!userTypesOnly && <SelectedContainer>{renderSelectedList()}</SelectedContainer>}
      </Container>
    </>
  );
};

export default TWUserSelectionFullMulti;
