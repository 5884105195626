import styled from '@emotion/styled';
import { sharedSpacingStyles, SpacingComponentProps } from '@tw/components/utils';

interface Props {
  hidden?: boolean;
}

const TWSpacingContainer = styled.div<SpacingComponentProps & Props>(({ hidden = false }) => [
  sharedSpacingStyles,
  { display: hidden ? 'none' : null },
]);

export default TWSpacingContainer;
