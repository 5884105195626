/**
 * This file provides static values about how the deviceConfig interacts with and exists
 * within the Redux store.
 */

const storeKey = 'deviceConfig';

export default {
  storeKey,
  rootSelector: (store) => store[storeKey],

  layerNames: ['baseDefault', 'device', 'debugOverride'],
};
