import _ from 'lodash';

import { pythonApiBackend } from '@tw/backends';
import { graphqlUtils } from '@tw/util';
import { ConfigContainerType, SessionReportSubmitValues } from '@tw/types';

import academicServiceStoreConfig from '../academicServiceStoreConfig';

import { tutorSessionExportQuery } from './tutorSessionQueries';
import tutorSessionModel from './tutorSessionModel';

const tutorSessionOperations = {
  createTutorSessionReport(
    personId: number,
    teamId: number,
    sessionId: number | string,
    fieldValues: SessionReportSubmitValues,
    configContainer: ConfigContainerType,
  ) {
    const body = tutorSessionModel.convertDataForTutorSessionReport(fieldValues);

    // This loosely mirrors the fetch() API
    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: `tutoring/session/${sessionId}::form`,
        debugLabel: 'createTutorSessionReport',
        includeAuthenticationHeader: true,
        personId,
        teamId,
      },
      {
        // fetch options
        method: 'POST',
        body,
      },
      configContainer,
    );
  },

  fetchTutorSessionExport(personId, teamId, filters, configContainer) {
    const variables = tutorSessionModel.normalizeSessionVariables(filters);

    const body = {
      query: graphqlUtils.createExecutableQueryObject(tutorSessionExportQuery),
      variables: _.omit(variables, ['first', 'after', 'before']),
    };

    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: 'graphql/reports',
        debugLabel: 'fetchTutorSessionExport',
        includeAuthenticationHeader: true,
        desiredFormat: 'text',
        personId,
        teamId,
      },
      {
        // fetch body
        method: 'POST',
        body,
      },
      configContainer,
    );
  },
  async fetchTutorSessionIdByAppointmentOccurrenceId(
    personId,
    teamId,
    appointmentOccurrenceId,
    configContainer,
  ) {
    const body = { appointmentOccurrenceId };

    // This loosely mirrors the fetch() API
    const response = await pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: 'tutoring/session/get_or_create',
        debugLabel: 'fetchTutorSessionIdByAppointmentOccurrenceId',
        includeAuthenticationHeader: true,
        personId,
        teamId,
      },
      {
        // fetch options
        method: 'POST',
        body,
      },
      configContainer,
    );
    return response;
  },
};

export default tutorSessionOperations;
