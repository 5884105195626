const baseDefault = {
  auth: {
    coldfusionToken: null,
  },

  defaultFetchOptions: {
    headers: {
      Accept: 'application/json',
    },
    mode: 'cors',
  },

  // These are all in ms
  timing: {
    // When first loading a screen that needs some data, it will re-fetch if the data is more than 30s stale,
    // unless explicitly told otherwise (e.g., shorter time for conversation messages, longer for profiles).
    dataFreshnessTimeout: 30 * 1000,
    // When returning to an already-loaded screen that needs some data, it will re-fetch if the data is more
    // than 2 minutes stale, unless told otherwise.
    dataStalenessTimeout: 120 * 1000,
    // Data that's kept in offline storage should be swept away if it's more than 7 days stale.
    // (This is not currently an automatic process.)
    dataPurgeTimeout: 7 * 86400 * 1000,

    // While offline, there's a 10s gap between the end of one network check and the beginning of the next.
    delayBetweenPings: 10 * 1000,
    // When prefetching data or performing other cyclic fetches, there's a minimum 5s gap between the end
    // of one fetch and the beginning of the next.
    delayBetweenBackgroundFetches: 5 * 1000,

    ajaxTimeout: 20000,
    // @TODO: If the first fetch attempt failed, use a longer timeout on the second try
    // ajaxTimeoutForRetry: 30000,

    // Today auth sessions last 24 hours, but we'll give ourselves a 2-minute buffer in case
    // it takes a few retries (if the user is active at refresh time).
    sessionDuration: 86400000 - 2 * 60 * 1000,
  },

  googleAnalytics: {
    token: 'UA-29449919-5',
  },

  // We want everything in the "mocking" group to be off except during development.
  mocking: {
    // To help test laggy connections, this will delay processing of *ALL* ajax requests
    artificialAjaxDelay: 0, // in ms
    qrCodeScannerData: null,

    artificialAuthTokenServiceAjaxDelay: 0,
    artificialAcademicServiceAjaxDelay: 0,
    artificialCalendarServiceAjaxDelay: 0,
    artificialFormServiceAjaxDelay: 0,
    artificialSelectionCodeServiceAjaxDelay: 0,
    artificialTeamServiceAjaxDelay: 0,
    artificialUserServiceAjaxDelay: 0,
    artificialUserTeamsPermissionsServiceAjaxDelay: 0,

    // This will enable all or some of the 'fake-{whatever}-backend' services.
    // 'useFakeBackends' overrides ALL the service-specific flags.
    useFakeBackends: false,

    useFakeActivityFeedOldBackend: false,
    useFakeActivityNoticeBackend: false,
    useFakeCalendarBackend: false,
    useFakeCaraBackend: false,
    useFakeFileBackend: false,
    useFakeFormBackend: false,
    useFakeMessagingBackend: false,
    useFakeSelectionCodeBackend: false,
    useFakeWebLinkBackend: false,
  },
};

export default baseDefault;
