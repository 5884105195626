import _ from 'lodash';

const sometimesNull = (generator: () => any, chanceForNull = 0.15) =>
  Math.random() >= chanceForNull ? generator() : null;

const flattenObject = (obj: object = {}) => {
  const result = {};

  const flatten = (collection: object, prefix = '', suffix = '') => {
    _.forEach(collection, (value: any, key: string) => {
      const path = `${prefix}${key}${suffix}`;

      if (_.isArray(value)) {
        flatten(value, `${path}[`, ']');
      } else if (_.isPlainObject(value)) {
        flatten(value, `${path}.`);
      } else {
        result[path] = value;
      }
    });
  };

  flatten(obj);

  return result;
};

const nodeHasString = (node: object, searchString: string) =>
  !searchString ||
  (searchString &&
    _.some(
      flattenObject(node),
      (value: any) =>
        _.isString(value) && _.includes(_.lowerCase(value), _.lowerCase(searchString)),
    ));

export default {
  nodeHasString,
  sometimesNull,
};
