import { useEffect, useState } from 'react';
import { PaginatedFilters } from '@tw/components';
import { CursorPagination } from '@tw/components/components.web/DataTable/DataTable.definitions';
import { usePrevious } from '@tw/hooks';
import { PageInfo } from '@tw/generated';
import { paginationUtils } from '@tw/util';
import { noopFn } from '@tw/constants';

type UsePaginationOptions = {
  rowsPerPageOptions?: number[];
};

type UsePaginationProps = {
  count: number;
  filters: PaginatedFilters;
  pageInfo?: PageInfo;
  onFilterChange: (filters: PaginatedFilters) => void;
  onPageChange: (cursor?: string | null) => void;
  options?: UsePaginationOptions;
};

export const ROWS_PER_PAGE_STORAGE_KEY = 'TWDataTable__rowsPerPage';
const defaultPagination: CursorPagination = {
  count: 0,
  currentPageString: '',
  hasNextPage: false,
  hasPreviousPage: false,
  loadNextPage: noopFn,
  loadPreviousPage: noopFn,
  updateRowsPerPage: noopFn,
};

const useCursorPagination = ({
  count,
  filters,
  pageInfo,
  onFilterChange,
  onPageChange,
  options,
}: UsePaginationProps): CursorPagination => {
  const lastCount = usePrevious(count);
  const [cursorHistory, setCursorHistory] = useState<(string | null)[]>([]);
  const [internalPageInfo, setPageInfo] = useState<PageInfo | undefined>(pageInfo);
  const [rowsPerPage, setRowsPerPage] = useState<number | undefined>(filters.first ?? 25);

  useEffect(() => {
    if (pageInfo) setPageInfo(pageInfo);
  }, [pageInfo]);

  useEffect(() => {
    if (!filters.after) {
      setCursorHistory([]);
    }
  }, [filters.after]);

  useEffect(() => {
    if (filters.first !== rowsPerPage) {
      onFilterChange({
        ...filters,
        first: rowsPerPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!internalPageInfo) return defaultPagination;

  const loadNextPage = () => {
    const currentCursor = filters?.after ?? null;

    if (internalPageInfo.hasNextPage && cursorHistory.indexOf(currentCursor) === -1) {
      cursorHistory.push(currentCursor);
      onPageChange(internalPageInfo.endCursor);
    }
  };

  const loadPreviousPage = () => {
    const previousCursor = cursorHistory.pop();
    onPageChange(previousCursor);
  };

  const updateRowsPerPage = (newValue: number) => {
    localStorage.setItem(ROWS_PER_PAGE_STORAGE_KEY, newValue.toString());
    setRowsPerPage(newValue);
    setCursorHistory([]);

    onFilterChange({
      ...filters,
      after: null,
      first: newValue,
    });
  };

  const currentPage = cursorHistory.length;
  const hasPreviousPage = currentPage > 0;

  // This is in place so while the screen is loading, it is clearer to the user that we are
  // loading a subsequent page. While it loads, totalCount becomes null so keeping a copy of
  // it so we can achieve this desired clarity.
  const safeLastTotalCount = Number.isInteger(count) ? count : Number(lastCount);

  const rangeString = rowsPerPage
    ? paginationUtils.getRangeOfRowsOnPage(currentPage, rowsPerPage, safeLastTotalCount)
    : '...';

  const currentPageString = safeLastTotalCount ? `${rangeString} of ${safeLastTotalCount}` : '';

  return {
    count,
    currentPageString,
    hasNextPage: internalPageInfo.hasNextPage,
    hasPreviousPage,
    loadNextPage,
    loadPreviousPage,
    rowsPerPage: rowsPerPage ?? 0,
    rowsPerPageOptions: options?.rowsPerPageOptions,
    updateRowsPerPage,
  };
};

export default useCursorPagination;
