import { Popover } from 'antd';
import sortBy from 'lodash/sortBy';
import { useState } from 'react';

import {
  TWIcon,
  TWListItemAvatar,
  TWLoadingSpinner,
  TWMessage,
  TWSpacingContainer,
} from '@tw/components';
import { PersonNode, SelectablesDataQuery, useSelectablesDataLazyQuery } from '@tw/generated';
import { SelectableInterface, isGroup, isPerson } from '@tw/hooks/useSelectables/useSelectables';
import { getTranslation } from '@tw/i18n';
import { filterNils } from '@tw/util';

import { UserPopoverProps } from './UserPopover.definitions';
import {
  PopoverBody,
  PopoverContainer,
  PopoverHeader,
  PopoverSpinnerContainer,
  UsersBtn,
  UsersContainer,
  UsersText,
} from './UserPopover.styles';

const UserPopover = ({
  codes,
  containerId,
  placement = 'topLeft',
  text,
  title,
}: UserPopoverProps) => {
  const [users, setUsers] = useState<SelectablesDataQuery['selectables']>([]);
  const [toggledGroups, setToggledGroups] = useState<Record<string, boolean>>({});

  const [fetchSelectables, { loading: loadingEventUsers }] = useSelectablesDataLazyQuery({
    onCompleted: (data) => {
      setUsers(data?.selectables ?? []);
    },
    onError: (error) => TWMessage.errorHandler(error),
  });

  const getUsers = () => {
    fetchSelectables({
      variables: { codes },
    });
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      getUsers();
    }
  };

  const toggleUserGroup = (userGroup: string) => {
    setToggledGroups((prevState) => ({
      ...prevState,
      [userGroup]: !prevState[userGroup],
    }));
  };

  const renderIndividualUser = ({
    personId,
    preferredName,
    lastName,
    pictureUrl,
    initials,
  }: PersonNode) => (
    <TWListItemAvatar
      avatarImage={pictureUrl ?? undefined}
      avatarText={initials ?? undefined}
      key={personId}
      label={`${preferredName} ${lastName}`}
      selectable={false}
      size="small"
    />
  );

  const renderUserGroup = (pluralLabel: string, people: PersonNode[]) => {
    const sortedPeople = sortBy(people || [], 'label');
    const isExpanded = toggledGroups[pluralLabel] ?? true;

    return (
      <div key={pluralLabel}>
        <TWListItemAvatar
          aria-label={getTranslation(isExpanded ? 'collapse' : 'expand')}
          key={pluralLabel}
          label={pluralLabel}
          labelStyles={{ fontSize: 12, fontWeight: 600 }}
          onClick={() => toggleUserGroup(pluralLabel)}
          rightNode={
            <TWIcon
              type={isExpanded ? 'material-expand_more' : 'material-chevron_right'}
              width={16}
              height={16}
            />
          }
          showAvatar={false}
          size="small"
        />
        {isExpanded && (
          <TWSpacingContainer>{sortedPeople.map(renderIndividualUser)}</TWSpacingContainer>
        )}
      </div>
    );
  };

  const renderUser = (user: SelectableInterface) => {
    if (isGroup(user)) {
      return renderUserGroup(user.pluralLabel ?? '', filterNils(user.people ?? []));
    }
    if (isPerson(user)) {
      return renderIndividualUser(user);
    }
    return null;
  };

  const renderUserPopover = () => (
    <PopoverContainer>
      <PopoverHeader>{title}</PopoverHeader>
      {loadingEventUsers ? (
        <PopoverSpinnerContainer align="middle" justify="center">
          <TWLoadingSpinner />
        </PopoverSpinnerContainer>
      ) : (
        users && users?.length > 0 && <PopoverBody>{users?.map(renderUser)}</PopoverBody>
      )}
    </PopoverContainer>
  );

  return (
    <Popover
      placement={placement}
      trigger="click"
      content={renderUserPopover()}
      onOpenChange={onOpenChange}
      getPopupContainer={() => document.getElementById(containerId) as HTMLElement}
    >
      <UsersContainer row>
        <UsersBtn type="borderless">
          <TWIcon type="tw-group" width={24} height={24} />
          <UsersText>{text}</UsersText>
        </UsersBtn>
      </UsersContainer>
    </Popover>
  );
};

export default UserPopover;
