import styled from '@emotion/styled';
import { Alert } from 'antd';

const StyledAlert = styled(Alert)(({ theme, type }) => ({
  '.ant-alert-message': {
    color: theme.colors.text,
  },
  'svg, span.material-icons-outlined': {
    top: theme.baseUnit,
    left: theme.baseUnit,
    color: {
      error: theme.colors.negativeIcon,
      info: theme.colors.infoIcon,
      success: theme.colors.positiveIcon,
      warning: theme.colors.warningIcon,
    }[type as string],
  },
}));

export { StyledAlert };
