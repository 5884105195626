/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTicketingOrgSettingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchTicketingOrgSettingsQuery = {
  ticketingOrgSetting?: {
    orgId: number;
    id: string;
    settings?: {
      guestApprovals?: Array<string | null> | null;
      notifications?: Array<number | null> | null;
      ticketedTeams?: Array<number | null> | null;
      attributes?: Array<{
        attributeId?: string | null;
        label?: string | null;
        attributeType?: string | null;
        attributeOptions?: Array<string | null> | null;
        isRequired?: boolean | null;
        isNameAttribute?: boolean | null;
        conditionalAttributes?: Array<{
          attributeId?: string | null;
          label?: string | null;
          attributeType?: string | null;
          attributeOptions?: Array<string | null> | null;
          isRequired?: boolean | null;
          matchedStrValues?: Array<string | null> | null;
          matchedBoolValue?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export const FetchTicketingOrgSettingsDocument = gql`
  query FetchTicketingOrgSettings {
    ticketingOrgSetting {
      orgId
      id
      settings {
        guestApprovals
        attributes {
          attributeId
          label
          attributeType
          attributeOptions
          isRequired
          isNameAttribute
          conditionalAttributes {
            attributeId
            label
            attributeType
            attributeOptions
            isRequired
            matchedStrValues
            matchedBoolValue
          }
        }
        notifications
        ticketedTeams
      }
    }
  }
`;

/**
 * __useFetchTicketingOrgSettingsQuery__
 *
 * To run a query within a React component, call `useFetchTicketingOrgSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTicketingOrgSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTicketingOrgSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTicketingOrgSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTicketingOrgSettingsQuery,
    FetchTicketingOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTicketingOrgSettingsQuery, FetchTicketingOrgSettingsQueryVariables>(
    FetchTicketingOrgSettingsDocument,
    options,
  );
}
export function useFetchTicketingOrgSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTicketingOrgSettingsQuery,
    FetchTicketingOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchTicketingOrgSettingsQuery,
    FetchTicketingOrgSettingsQueryVariables
  >(FetchTicketingOrgSettingsDocument, options);
}
export type FetchTicketingOrgSettingsQueryHookResult = ReturnType<
  typeof useFetchTicketingOrgSettingsQuery
>;
export type FetchTicketingOrgSettingsLazyQueryHookResult = ReturnType<
  typeof useFetchTicketingOrgSettingsLazyQuery
>;
export type FetchTicketingOrgSettingsQueryResult = Apollo.QueryResult<
  FetchTicketingOrgSettingsQuery,
  FetchTicketingOrgSettingsQueryVariables
>;
