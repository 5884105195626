// Note that $twInput uses several of its own colors, instead of $colors, because we
// *don't* invert it when the darkDefaultTheme is enabled.

const $twInput = {
  backgroundColor: '$colorHue.grayExtremelyLight',
  borderColor: '$colorHue.grayMid',
  borderRadius: 4,
  borderWidth: '$borderWidth.medium',
  inputHeight: 37,
  verticalGutter: 12,
  horizontalPadding: '$twInput.iconSize * 0.5',
  iconColor: '$twInput.labelColor',
  iconSize: 16,
  labelColor: '$colorHue.grayBlue',
  labelFontSize: '$fontSize.small',
  labelFontWeight: 'bold',
  placeholderColor: '$colorHue.grayBlue',
  smallLabelFontSize: 11,
  smallLabelLineHeight: 15,
  totalHeight: 50,
  validationIconSize: 20,
  valueColor: '$colorHue.grayDark',
  valueFontSize: 16,
  valueFontWeight: 'normal',
  valueLineHeight: 21,
};

const $twInputInverse = {
  ...$twInput,
  backgroundColor: '$colorHue.grayVeryDark',
  borderColor: 'transparent',
  valueColor: '$colorHue.grayBlue',
};

export { $twInput, $twInputInverse };
