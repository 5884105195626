/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSignupReservationsMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.ReservationInput>> | Types.InputMaybe<Types.ReservationInput>
  >;
  sendAlerts?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type CreateSignupReservationsMutation = {
  createSignupReservations: {
    slots?: Array<{
      slotId?: any | null;
      numReservations?: number | null;
      bookedPersons?: Array<{
        initials?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        personId?: number | null;
        pictureUrl?: string | null;
        selectionCode?: string | null;
        id?: string | null;
        label?: string | null;
      } | null> | null;
    } | null> | null;
  };
};

export const CreateSignupReservationsDocument = gql`
  mutation CreateSignupReservations($input: [ReservationInput], $sendAlerts: String) {
    createSignupReservations(input: $input, sendAlerts: $sendAlerts) {
      slots {
        slotId
        bookedPersons {
          id: pk
          initials
          label: displayName
          firstName
          lastName
          personId
          pictureUrl
          selectionCode
        }
        numReservations
      }
    }
  }
`;
export type CreateSignupReservationsMutationFn = Apollo.MutationFunction<
  CreateSignupReservationsMutation,
  CreateSignupReservationsMutationVariables
>;

/**
 * __useCreateSignupReservationsMutation__
 *
 * To run a mutation, you first call `useCreateSignupReservationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignupReservationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignupReservationsMutation, { data, loading, error }] = useCreateSignupReservationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sendAlerts: // value for 'sendAlerts'
 *   },
 * });
 */
export function useCreateSignupReservationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSignupReservationsMutation,
    CreateSignupReservationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSignupReservationsMutation,
    CreateSignupReservationsMutationVariables
  >(CreateSignupReservationsDocument, options);
}
export type CreateSignupReservationsMutationHookResult = ReturnType<
  typeof useCreateSignupReservationsMutation
>;
export type CreateSignupReservationsMutationResult =
  Apollo.MutationResult<CreateSignupReservationsMutation>;
export type CreateSignupReservationsMutationOptions = Apollo.BaseMutationOptions<
  CreateSignupReservationsMutation,
  CreateSignupReservationsMutationVariables
>;
