/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulletinClickCreateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.BulletinClickInput>;
}>;

export type BulletinClickCreateMutation = {
  bulletinClickCreate: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const BulletinClickCreateDocument = gql`
  mutation BulletinClickCreate($input: BulletinClickInput) {
    bulletinClickCreate(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type BulletinClickCreateMutationFn = Apollo.MutationFunction<
  BulletinClickCreateMutation,
  BulletinClickCreateMutationVariables
>;

/**
 * __useBulletinClickCreateMutation__
 *
 * To run a mutation, you first call `useBulletinClickCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulletinClickCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulletinClickCreateMutation, { data, loading, error }] = useBulletinClickCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulletinClickCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulletinClickCreateMutation,
    BulletinClickCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulletinClickCreateMutation, BulletinClickCreateMutationVariables>(
    BulletinClickCreateDocument,
    options,
  );
}
export type BulletinClickCreateMutationHookResult = ReturnType<
  typeof useBulletinClickCreateMutation
>;
export type BulletinClickCreateMutationResult = Apollo.MutationResult<BulletinClickCreateMutation>;
export type BulletinClickCreateMutationOptions = Apollo.BaseMutationOptions<
  BulletinClickCreateMutation,
  BulletinClickCreateMutationVariables
>;
