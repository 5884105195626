/*
  Extracts only the non-null or non-undefined type in an array type. Helpful in reading types from codegen generated data arrays.
  S = (T | null | undefined)[]
  T = ArrayItemNonNullType<S>
*/
export type ArrayItemNonNullType<ArrType extends readonly unknown[]> =
  ArrType extends readonly (infer ElementType)[] ? NonNullable<ElementType> : never;

// Only return the type in the T union that extends (or complies) with the I interface
export type Complies<T, I> = T extends I ? T : never;

/*
  Constructs a type by picking the set of properties who's value extends the FilterType.
  S = {a: string, b: number, c: string | number, d: number}
  T = FilterTypeProps<S, number> = {b: number, d: number}
 */
export type FilterTypeProps<Type, FilterType> = {
  [Property in keyof Type as Type[Property] extends FilterType
    ? Property
    : Exclude<Property, Property>]: Type[Property];
};

/*
  Returns a union type of all the namespace keys within an object
  Source: https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3
*/
export type NestedKeysOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeysOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

// Type predicate to filter out null and undefined
export const isNonNullable = <T extends NonNullable<unknown> = object>(
  item: T | null | undefined,
): item is NonNullable<T> => item !== null && item !== undefined;
