import { TranslationKey } from 'twI18n';

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  NonBinary = 'NONBINARY',
}

export type GenderDataType = 'M' | 'F' | 'N';

export type GenderOption = {
  labelNamespace: TranslationKey;
  value: Gender;
  shortValue: GenderDataType;
};

export type TimezoneOption = {
  'aria-label'?: string;
  children?: string;
  creator?: string;
  'data-testid'?: string;
  disabled?: boolean;
  key?: string;
  label?: string;
  options?: TimezoneOption[];
  title?: string;
  value?: string;
};
