/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ComplianceConflictQueryVariables = Types.Exact<{
  startDatetime?: Types.InputMaybe<Types.Scalars['GraphenePendulumDateTime']>;
  endDatetime?: Types.InputMaybe<Types.Scalars['GraphenePendulumDateTime']>;
  appointmentId?: Types.InputMaybe<Types.Scalars['ID']>;
  appointmentOccurrenceId?: Types.InputMaybe<Types.Scalars['ID']>;
  appointmentTypeId?: Types.InputMaybe<Types.Scalars['ID']>;
  isCountable?: Types.InputMaybe<Types.Scalars['Boolean']>;
  visibility?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>
  >;
}>;

export type ComplianceConflictQuery = {
  tmpConflicts?: Array<{
    limitValue?: number | null;
    conflictValue?: number | null;
    explanation?: string | null;
    rule?: { bylaw?: string | null; label?: string | null; tmpRuleId?: number | null } | null;
    actions?: { notifyCompliance?: boolean | null; commentRequired?: boolean | null } | null;
  } | null> | null;
};

export const ComplianceConflictDocument = gql`
  query ComplianceConflict(
    $startDatetime: GraphenePendulumDateTime
    $endDatetime: GraphenePendulumDateTime
    $appointmentId: ID
    $appointmentOccurrenceId: ID
    $appointmentTypeId: ID
    $isCountable: Boolean
    $visibility: [String]
  ) {
    tmpConflicts(
      startDatetime: $startDatetime
      endDatetime: $endDatetime
      appointmentId: $appointmentId
      appointmentOccurrenceId: $appointmentOccurrenceId
      appointmentTypeId: $appointmentTypeId
      isCountable: $isCountable
      visibility: $visibility
    ) {
      rule {
        bylaw
        label
        tmpRuleId
      }
      actions {
        notifyCompliance
        commentRequired
      }
      limitValue
      conflictValue
      explanation
    }
  }
`;

/**
 * __useComplianceConflictQuery__
 *
 * To run a query within a React component, call `useComplianceConflictQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceConflictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceConflictQuery({
 *   variables: {
 *      startDatetime: // value for 'startDatetime'
 *      endDatetime: // value for 'endDatetime'
 *      appointmentId: // value for 'appointmentId'
 *      appointmentOccurrenceId: // value for 'appointmentOccurrenceId'
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *      isCountable: // value for 'isCountable'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useComplianceConflictQuery(
  baseOptions?: Apollo.QueryHookOptions<ComplianceConflictQuery, ComplianceConflictQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ComplianceConflictQuery, ComplianceConflictQueryVariables>(
    ComplianceConflictDocument,
    options,
  );
}
export function useComplianceConflictLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplianceConflictQuery,
    ComplianceConflictQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ComplianceConflictQuery, ComplianceConflictQueryVariables>(
    ComplianceConflictDocument,
    options,
  );
}
export type ComplianceConflictQueryHookResult = ReturnType<typeof useComplianceConflictQuery>;
export type ComplianceConflictLazyQueryHookResult = ReturnType<
  typeof useComplianceConflictLazyQuery
>;
export type ComplianceConflictQueryResult = Apollo.QueryResult<
  ComplianceConflictQuery,
  ComplianceConflictQueryVariables
>;
