/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { CSSProperties } from 'react';
import { BaseEditor, Editor, Transforms, Element, Node, BaseElement } from 'slate';
import { ReactEditor } from 'slate-react';
import { theme } from '@tw/components/utils';
// eslint-disable-next-line import/named
import { FontFamily, FontSize, ValidFormat } from '../RichTextEditor.definitions';

const alignment: ValidFormat[] = ['alignLeft', 'alignRight', 'alignCenter'];
const listTypes: ValidFormat[] = ['orderedList', 'unorderedList'];

/* Knonwn issue:
 1. Node/BaseElement does not have property type: https://github.com/ianstormtaylor/slate/issues/4915
*/

const defaultMarkData: CSSProperties = {
  color: theme.colors.text,
  backgroundColor: theme.colors.text,
  fontSize: 'normal',
  fontFamily: 'sans',
};

export const sizeMap: Record<FontSize, string> = {
  small: '0.75em',
  normal: '1em',
  medium: '1.75em',
  huge: '2.5em',
};

export const fontFamilyMap: Record<FontFamily, string> = {
  sans: 'sans-serif',
  serif: 'serif',
  monospace: 'monospace',
};

export const isMarkActive = (editor: BaseEditor, format: ValidFormat) => {
  const marks = Editor.marks(editor);

  return marks ? marks[format] === true : false;
};

export const isBlockActive = (editor: BaseEditor, format: ValidFormat) => {
  try {
    const [match] = Editor.nodes(editor, {
      match: (n: Node) => !Editor.isEditor(n) && Element.isElement(n) && (n as any).type === format,
    });

    return !!match;
  } catch {
    return false;
  }
};

const clearFormatting = (editor: BaseEditor) => {
  const marks = Editor.marks(editor);
  if (marks) {
    Object.keys(marks).forEach((format) => {
      Editor.removeMark(editor, format);
    });
  }

  Transforms.setNodes(
    editor,
    {
      ...defaultMarkData,
    } as BaseElement,
    { match: (n) => Element.isElement(n) },
  );
};

export const toggleBlock = (editor: BaseEditor, format: ValidFormat) => {
  const isClear = format === 'clear';
  if (isClear) {
    clearFormatting(editor);
    return; // early exit;
  }

  const isActive = isBlockActive(editor, format);
  const isList = listTypes.includes(format);
  const isIndent = alignment.includes(format);
  const isAligned = alignment.some((alignmentType) => isBlockActive(editor, alignmentType));

  /* If the node is already aligned and change in indent is called we should unwrap it first and split the node to prevent
  messy, nested DOM structure and bugs due to that. */
  if (isAligned && isIndent) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        alignment.includes(!Editor.isEditor(n) && Element.isElement(n) && (n as any).type),
      split: true,
    });
  }

  /* Wraping the nodes for alignment, to allow it to co-exist with other block level operations */
  if (isIndent) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    } as BaseElement);
    return;
  }
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      listTypes.includes(!Editor.isEditor(n) && Element.isElement(n) && (n as any).type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  } as Partial<Node>);

  if (isList && !isActive) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    } as BaseElement);
  }
};
export const addMarkData = (editor: ReactEditor, { format, value }) => {
  Editor.addMark(editor, format, value);
  ReactEditor.focus(editor);
};

export const toggleMark = (editor: BaseEditor, format: ValidFormat) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
  ReactEditor.focus(editor as ReactEditor);
};

export const activeMark = (editor: BaseEditor, format: ValidFormat) => {
  try {
    const marks = Editor.marks(editor);
    const defaultValue = defaultMarkData[format];
    return marks?.[format] ?? defaultValue;
  } catch {
    return false;
  }
};
