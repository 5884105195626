import styled from '@emotion/styled';
import { colors } from '@teamworksdev/style';
import { ReactElement } from 'react';

interface TableErrorProps {
  isError?: boolean;
}

interface TableMessageProps {
  icon: ReactElement;
  title: string;
  description: string;
  button?: ReactElement;
  isError?: boolean;
}
const TableMessage = ({ icon, title, description, button, isError }: TableMessageProps) => (
  <TableMessageContainer>
    <TableMessageStateIcon isError={isError}>{icon}</TableMessageStateIcon>
    <TableMessageTitle isError={isError}>{title}</TableMessageTitle>
    <TableMessageStateDesc>{description}</TableMessageStateDesc>
    {button && <div>{button}</div>}
  </TableMessageContainer>
);

const TableMessageContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: `${theme.baseUnit * 49}px`,
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
}));

const TableMessageTitle = styled.div<TableErrorProps>(({ isError, theme }) => ({
  color: isError ? colors.error[700] : `${colors.black}DE`,
  fontSize: `${theme.fontUnit * 4 + 2}px`,
  lineHeight: `${theme.fontUnit * 6}px`,
  fontWeight: theme.fontWeight.semibold,
  fontFamily: theme.fontFamily.default,
  marginBottom: theme.fontUnit,
}));

const TableMessageStateIcon = styled.div<TableErrorProps>(({ isError, theme }) => ({
  display: 'flex',
  width: `${theme.baseUnit * 7}px`,
  height: `${theme.baseUnit * 7}px`,
  borderRadius: '50%',
  backgroundColor: isError ? colors.error[700] : colors.black,
  color: colors.white,
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  marginBottom: `${theme.baseUnit * 2}px`,

  svg: {
    width: `${theme.baseUnit * 4}px`,
    height: `${theme.baseUnit * 4}px`,
    color: colors.white,
  },
}));

const TableMessageStateDesc = styled.div(({ theme }) => ({
  width: '100%',
  maxWidth: `${theme.baseUnit * 49}px`,
  whiteSpace: 'normal',
  color: theme.colors.black60,
  fontSize: `${theme.fontUnit * 3 + 2}px`,
  lineHeight: `${theme.fontUnit * 4 + 2}px`,
  fontWeight: theme.fontWeight.regular,
  fontFamily: theme.fontFamily.default,
  marginBottom: `${theme.baseUnit * 2}px`,
}));

export default TableMessage;
