/* eslint-disable no-console */
import _ from 'lodash';

const maybeUnwrapArray = (moreInfo) => {
  if (_.isArray(moreInfo) && moreInfo.length === 1) {
    return moreInfo[0];
  }
  return moreInfo;
};

// To make it easy to share debugLogger instances across the app, it's possible (but NOT recommended)
// to register a particular `debugLoggerId`. If two different areas request the same `debugLoggerId`
// then they'll receive the same instance.
const debugLoggerRegistry = {};

/**
 * This is based on something that worked well in a past project, as a log wrapper that worked well
 * for more Frontend-oriented cases. It's a *persistent* (not-immutable) object that wraps console.logs
 * and a log history, then we can connect it up to a debugFlag to easily enable/disable console vomit
 * during dev, or collect log and/or error history from users who are having issues.
 *
 * Some notes:
 *  - We separate "log to the console" and "save in an internal log" because the former is nicer for
 *    devs while the latter is better for sending logs from end users.
 *  - We track both "logs" and "errors" in one debugLogger, but in separate lists. This keeps everything
 *    centralized into one history and narrows all debug/info/warn/error/etc options down into just
 *    "really bad" and "not really bad"
 */
export default class DebugLogger {
  options = {
    debugLoggerId: null,
    debugEnabled: false,
    debugHistoryEnabled: false,
    debugLabel: '(you should set a debugLabel)',
    debugLevel: 'log',
  };

  logCount = 0;

  logHistory = [];

  errorCount = 0;

  errorHistory = [];

  static getInstanceFromRegistry(debugLoggerId) {
    // Pull it from the registry, creating if necessary
    if (!_.has(debugLoggerRegistry, debugLoggerId)) {
      debugLoggerRegistry[debugLoggerId] = new DebugLogger();
    }
    return debugLoggerRegistry[debugLoggerId];
  }

  constructor(options = {}) {
    // Syntactic sugar: ask for a debugLogger by string and that'll become both the label and the ID
    if (_.isString(options)) {
      /* eslint-disable no-constructor-return */
      return new DebugLogger({
        debugLoggerId: options,
        debugLabel: options,
      });
    }

    if (options.debugLoggerId) {
      const debugLogger = DebugLogger.getInstanceFromRegistry(options.debugLoggerId);
      debugLogger.setOptions(options);
      return debugLogger;
    }
    this.setOptions(options);
    /* eslint-enable no-constructor-return */
  }

  getOptions() {
    return this.options;
  }

  setOptions(newOptions = {}) {
    this.log('DebugLogger.setOptions', newOptions);
    // (debugLoggerId cannot be changed after initialization)
    _.assign(
      this.options,
      _.pick(newOptions, ['debugEnabled', 'debugHistoryEnabled', 'debugLabel', 'debugLevel']),
    );
    _.assign(
      this,
      _.pick(newOptions, ['debugEnabled', 'debugHistoryEnabled', 'debugLabel', 'debugLevel']),
    );
  }

  getLogHistory() {
    return this.logHistory;
  }

  getErrorHistory() {
    return this.errorHistory;
  }

  isEnabled() {
    return this.options.debugEnabled || this.options.debugHistoryEnabled;
  }

  clearHistory() {
    this.logHistory = [];
    this.errorHistory = [];
  }

  log(message, ...moreInfo) {
    if (this.options.debugEnabled) {
      if (!_.isFunction(console[this.options.debugLevel])) {
        console.warn('Hey developer, you set an invalid debugLevel for a DebugLogger!', this);
      } else {
        console[this.options.debugLevel](
          `${this.options.debugLabel} (${this.logCount}) : ${message}`,
          ...moreInfo,
        );
      }
    }
    if (this.options.debugHistoryEnabled) {
      this.logHistory.push({ message, moreInfo: maybeUnwrapArray(moreInfo) });
    }

    this.logCount += 1;
    return this.getLogHistory();
  }

  error(message, ...moreInfo) {
    // We *always* warn about "errors", even if disabled. The external API uses "errors" to discourage
    // people from using it for mere warnings, though.
    console.warn(`${this.options.debugLabel} (${this.errorCount}) : ${message}`, ...moreInfo);
    if (this.options.debugHistoryEnabled) {
      this.errorHistory.push({ message, moreInfo: maybeUnwrapArray(moreInfo) });
      // To help with tracing down bugs, we also add errors to the 'normal' log
      this.logHistory.push({
        message: `(ERROR) : ${message}`,
        moreInfo: maybeUnwrapArray(moreInfo),
      });
      this.logCount += 1;
    }

    this.errorCount += 1;
    return this.getErrorHistory();
  }
}
