import { combineReducers } from 'redux';

// Configs
import backendConfigReducer from '@tw/config/backendConfig/backendConfigReducer';
import backendConfigStoreConfig from '@tw/config/backendConfig/backendConfigStoreConfig';
import formatConfigReducer from '@tw/config/formatConfig/formatConfigReducer';
import formatConfigStoreConfig from '@tw/config/formatConfig/formatConfigStoreConfig';
import themeConfigReducer from '@tw/config/themeConfig/themeConfigReducer';
import themeConfigStoreConfig from '@tw/config/themeConfig/themeConfigStoreConfig';
import uiSettingsConfigReducer from '@tw/config/uiSettingsConfig/uiSettingsConfigReducer';
import uiSettingsConfigStoreConfig from '@tw/config/uiSettingsConfig/uiSettingsConfigStoreConfig';
import deviceConfigReducer from '@tw/config/deviceConfig/deviceConfigReducer';
import deviceConfigStoreConfig from '@tw/config/deviceConfig/deviceConfigStoreConfig';
import i18nConfigStoreConfig from '@tw/config/i18nConfig/i18nConfigStoreConfig';
import i18nConfigReducer from '@tw/config/i18nConfig/i18nConfigReducer';

const rootReducer = combineReducers({
  // Configs
  [backendConfigStoreConfig.storeKey]: backendConfigReducer,
  [formatConfigStoreConfig.storeKey]: formatConfigReducer,
  [themeConfigStoreConfig.storeKey]: themeConfigReducer,
  [uiSettingsConfigStoreConfig.storeKey]: uiSettingsConfigReducer,
  [deviceConfigStoreConfig.storeKey]: deviceConfigReducer,
  [i18nConfigStoreConfig.storeKey]: i18nConfigReducer,
});

export default rootReducer;
