import { forwardRef, SVGProps } from 'react';

import { DefaultStyledSVG } from './TWIcon.styles';

interface TWIconCustomProps extends SVGProps<SVGSVGElement> {
  type: string;
  testID?: string;
  title?: string;
}

const TWIconCustom = forwardRef<SVGSVGElement, TWIconCustomProps>(
  ({ type, testID, title, ...svgProps }, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DefaultStyledSVG data-testid={testID} {...svgProps} ref={ref}>
      {title && <title>{title}</title>}
      <use href={`#icon-${type}`} />
    </DefaultStyledSVG>
  ),
);

export default TWIconCustom;
