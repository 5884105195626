import { useEffect, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { stringUtils } from '@tw/util';
import 'react-phone-input-2/lib/style.css';
import { getTranslation } from '@tw/i18n';

interface TWInputPhoneNumberProps {
  id?: string;
  onChange?: Function;
  testID: string;
  accessibilityLabel?: string;
  value?: string;
  placeholder?: string;
  onlyCountries?: string[];
  preferredCountries?: string[];
  excludeCountries?: string[];
  disabled?: boolean;
  height?: string;
  isPhoneValid?: (value: boolean) => void;
}

// based off of https://stackoverflow.com/questions/2113908/what-regular-expression-will-match-valid-international-phone-numbers
const internationalPhoneRegex =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1).+$/gi;

const TWInputPhoneNumber = ({
  testID,
  accessibilityLabel = undefined,
  onChange,
  value = '',
  placeholder,
  onlyCountries = [],
  preferredCountries = [],
  excludeCountries = [],
  disabled,
  isPhoneValid,
  height = undefined,
  id,
  ...restOfProps
}: TWInputPhoneNumberProps) => {
  const [inputValue, setInputValue] = useState('');

  // for legacy values not internationally formatted ( e.g. (555) 494-2323 )
  useEffect(() => {
    if (value) {
      const match = value.match(internationalPhoneRegex);
      // regex matches if country code is found. if no country code then US is assigned
      if (value.length > 3 && !match) {
        const onlyNumbers: string = value
          .split('')
          .filter((char) => !!Number(char) || char === '0')
          .join('');
        setInputValue(`+1${onlyNumbers}`);
        return;
      }
      setInputValue(value);
    }
  }, [value]);

  const onValueChange = (phoneNumber: string, data: CountryData) => {
    if (onChange) {
      if (!phoneNumber.slice(data.dialCode.length).length) {
        onChange(null);
      } else {
        onChange(`+${phoneNumber}`);
      }
    }
    setInputValue(`+${phoneNumber}`);
  };

  return (
    <PhoneInput
      disabled={disabled}
      inputStyle={{ width: 'inherit', height, color: disabled ? 'silver' : 'inherit' }}
      placeholder={placeholder}
      onlyCountries={onlyCountries}
      preferredCountries={preferredCountries}
      excludeCountries={excludeCountries}
      country="us"
      value={inputValue}
      onChange={onValueChange}
      inputProps={{
        'data-testid': testID,
        id,
        'aria-label': accessibilityLabel,
        disabled,
        ...restOfProps,
      }}
      containerStyle={{
        height,
      }}
      isValid={(number: string, country: CountryData) => {
        // if number is less than the country code length, skip validation we should treat it like an empty field
        if (number.length <= country.countryCode.length) {
          if (isPhoneValid) {
            isPhoneValid(true);
          }
          return true;
        }
        // if the country code is US or CA, we need to validate more strictly by checking the length of the number
        if (country?.dialCode === '1') {
          // number length is 11 because of the +1, it's including the country code
          if (!stringUtils.isValidPhoneNumber(number) || number.length !== 11) {
            // if the number is not valid, disable the submit button
            if (isPhoneValid) {
              isPhoneValid(false);
            }
            return getTranslation('invalidPhoneNumber');
          }
        } else if (!stringUtils.isValidPhoneNumber(number)) {
          // if the number is not valid, disable the submit button
          if (isPhoneValid) {
            isPhoneValid(false);
          }
          return getTranslation('invalidPhoneNumber');
        }

        if (isPhoneValid) {
          isPhoneValid(true);
        }
        return true;
      }}
    />
  );
};

export { TWInputPhoneNumber };
