import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const errorWhitelist: RegExp[] = [
  /ResizeObserver/,
  /Uncaught "Script error\."/,
  /AbortError/,
  /ApolloError: cancelled/,
  /ApolloError: canceled/,
];

// The datadogRum SDK with tracing enabled doesn't play well with Cypress. It ends
// up injecting duplicate headers as an array which cannot be parsed properly.
if (process.env.DD_RUM_ENABLED && !window.Cypress) {
  datadogRum.init({
    service: process.env.DD_RUM_APP_NAME,
    applicationId: process.env.DD_RUM_APP_ID ?? '',
    clientToken: process.env.DD_RUM_CLIENT_TOKEN ?? '',
    site: process.env.DD_RUM_SITE,
    env: process.env.DD_RUM_ENV,
    version: process.env.CI_COMMIT_SHORT_SHA,
    sampleRate: Number(process.env.DD_RUM_SAMPLE_RATE),
    trackInteractions: true,
    allowedTracingOrigins: [/https:\/\/.*\.teamworksapp\.com/],
    beforeSend: (event) => {
      if (
        event.type === 'error' &&
        errorWhitelist.some((item) => event.error?.message.match(item))
      ) {
        return false;
      }

      if (
        event.type === 'resource' &&
        event.resource.status_code &&
        event.resource.status_code >= 500
      ) {
        datadogRum.addError(
          `${event.resource.method} ${event.resource.url} ${event.resource.status_code}`,
        );
      }

      return true;
    },
    enableExperimentalFeatures: ['feature_flags'],
  });

  datadogLogs.init({
    service: process.env.DD_RUM_APP_NAME,
    clientToken: process.env.DD_RUM_CLIENT_TOKEN ?? '',
    site: process.env.DD_RUM_SITE,
    env: process.env.DD_RUM_ENV,
    forwardErrorsToLogs: true,
    sampleRate: Number(process.env.DD_RUM_SAMPLE_RATE),
    beforeSend: (log) => {
      // Note that the `message` on log is a full error stringified and differs from
      // `error.message` on a RUM error event. For this reason we're checking with a
      // regex instead of a string.
      if (errorWhitelist.some((item) => log.message.match(item))) {
        return false;
      }

      return true;
    },
  });
}
