import styled from '@emotion/styled';
import { has } from 'lodash';

import { TWFlexContainer } from '@tw/components';
import { getTranslation } from '@tw/i18n';
import { PropertiesModelKey } from '@tw/modules/Forms/Forms.definitions';

import { useFlags } from '@tw/hooks';
import TWFormInputDeprecated from './TWFormInputDeprecated';
import TWIcon from '../TWIcon';

interface TWFormBuilderFieldOptionRefactorProps {
  fieldName: string;
  fieldSchema: PropertiesModelKey;
  uiWidget: string;
  onInputFocus?: (inputId: string) => void;
  onQuestionOptionAdd: (fieldName: string, index?: number) => void;
  onQuestionOptionDelete: (fieldName: string, optionName: string) => void;
  onQuestionOptionToggleAlertFlag: (fieldName: string, optionName: string) => void;
  onQuestionOptionChange: (fieldName: string, newValue: string, index: number) => void;
}

const TWFormBuilderFieldOptionRefactor = ({
  fieldName,
  onQuestionOptionAdd,
  onQuestionOptionDelete,
  onQuestionOptionToggleAlertFlag,
  onQuestionOptionChange,
  fieldSchema,
  uiWidget,
  onInputFocus,
}: TWFormBuilderFieldOptionRefactorProps) => {
  const { disableMessaging } = useFlags();

  const blurHandler = (newValue: string, index: number) => {
    onQuestionOptionChange(fieldName, newValue || '', index);
  };

  const handleKeyDown = (
    event: { keyCode: number; preventDefault: () => void; target: { value: string } },
    index: number,
  ) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onQuestionOptionChange(fieldName, event.target.value || '', index);
    }
  };

  const enumNames = has(fieldSchema, 'items') ? fieldSchema.items?.enum : fieldSchema.enum;

  const getIconType = () => {
    switch (uiWidget) {
      case 'radio':
        return 'radio_button_unchecked';
      case 'checkboxes':
        return 'check_box_outline_blank';
      case 'dropdown':
        return 'chevron_right';
      default:
        return '';
    }
  };

  const iconType = getIconType();

  return (
    <ul>
      {enumNames?.map((optionName, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`${index}-${optionName}`}>
          <TWFlexContainer row justify="space-between">
            <TWFlexContainer row>
              <TWIcon
                type={`material-${iconType}`}
                className={iconType === 'chevron_right' ? 'chevron-icon' : 'option-icon'}
              />
              <TWFormInputDeprecated
                index={index}
                value={optionName}
                onBlur={blurHandler}
                onFocus={onInputFocus}
                onKeyDown={handleKeyDown}
                fieldSchema={fieldSchema}
              />
            </TWFlexContainer>
            <TWFlexContainer row>
              {!disableMessaging && (
                <TWIcon
                  type="material-flag"
                  title={getTranslation('forms.formBuilder.flagForAlerts')}
                  role="button"
                  tabIndex={-1}
                  className={`material-icons alert-option ${
                    fieldSchema.flaggedOptions?.includes(optionName) ? 'flagged' : ''
                  }`}
                  onClick={() => onQuestionOptionToggleAlertFlag(fieldName, optionName)}
                  onKeyPress={() => onQuestionOptionToggleAlertFlag(fieldName, optionName)}
                  data-testid="FormTemplateBuilder:FlagOption"
                />
              )}
              <TWIcon
                type="material-clear"
                role="button"
                tabIndex={-1}
                className="delete-option"
                onClick={() => onQuestionOptionDelete(fieldName, optionName)}
                onKeyPress={() => onQuestionOptionDelete(fieldName, optionName)}
                data-testid="FormTemplateBuilder:DeleteOption"
              />
            </TWFlexContainer>
          </TWFlexContainer>
        </li>
      ))}
      <li>
        <AddOptionContainer
          role="button"
          tabIndex={0}
          onClick={() => onQuestionOptionAdd(fieldName)}
          onKeyPress={() => onQuestionOptionAdd(fieldName)}
          data-testid="FormTemplateBuilder:AddOption"
        >
          <TWIcon type="material-add_circle" className="add-option-icon" />
          <AddOption>{getTranslation('forms.formBuilder.option')}</AddOption>
        </AddOptionContainer>
      </li>
    </ul>
  );
};

const AddOption = styled.span({
  color: 'rgba(4, 93, 255, 1)',
});

const AddOptionContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export default TWFormBuilderFieldOptionRefactor;
