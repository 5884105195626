import { DOMUtils } from '@tw/util';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import isFunction from 'lodash/isFunction';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { createRef, PureComponent } from 'react';

const { RangePicker } = DatePicker;

// Date range picker does not apply size style from unknown reason
// heights are 40px and 24px respectively, while default size is 32px
const defaultStyle = {
  minWidth: 330,
  height: 32,
};
interface Props {
  containerId: string;
  onChange?: (range: RangeValue<Moment>) => void;
  style?: {};
}

type TWInputDateRangeProps = Props & RangePickerProps<Moment>;

// Intentionally a class because antd tries to assign a ref to it and produces an error:
// Warning: Stateless function components cannot be given refs. Attempts to access this ref will fail.
class TWInputDateRange extends PureComponent<TWInputDateRangeProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private elementRef = createRef<any>();

  getContainer = () => {
    const { containerId } = this.props;
    return DOMUtils.getContainer(containerId);
  };

  /**
   * By default, Ant's Range Picker assigns a datetime with a 'time' of current time of day
   * to the start and end date. This creates a problem for our date filters for records created
   * before or after the respective start/end dates. This explicitly sets the filter to search
   * for records created between the beginning of the start date to the end of the end date.
   */
  getFullDateRange: RangePickerProps<Moment>['onChange'] = (momentDateRange) => {
    const { onChange } = this.props;

    if (isFunction(onChange)) {
      const formattedDates: RangeValue<Moment> =
        momentDateRange && momentDateRange?.length > 0
          ? [momentDateRange[0]?.startOf('day') ?? null, momentDateRange[1]?.endOf('day') ?? null]
          : null;

      onChange(formattedDates);
    }
  };

  blurOnClose = (open: boolean) => {
    if (!open) {
      // this.elementRef.current.blur();
    }
  };

  render() {
    const { style = {}, ...allOtherProps } = this.props;
    return (
      <RangePicker
        format="LL"
        ref={this.elementRef}
        onChange={this.getFullDateRange}
        onOpenChange={this.blurOnClose}
        getPopupContainer={this.getContainer}
        style={{
          ...defaultStyle,
          ...style,
        }}
        {...allOtherProps}
      />
    );
  }
}

export default TWInputDateRange;
