/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchVideoShareLinkQueryVariables = Types.Exact<{
  videoId?: Types.InputMaybe<Types.Scalars['TWFilter_ID']>;
}>;

export type FetchVideoShareLinkQuery = {
  myVideos?: {
    edges: Array<{ video?: { videoId: string; activeShareLink?: string | null } | null } | null>;
  } | null;
};

export const FetchVideoShareLinkDocument = gql`
  query FetchVideoShareLink($videoId: TWFilter_ID) {
    myVideos(videoId: $videoId) {
      edges {
        video: node {
          videoId
          activeShareLink
        }
      }
    }
  }
`;

/**
 * __useFetchVideoShareLinkQuery__
 *
 * To run a query within a React component, call `useFetchVideoShareLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchVideoShareLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchVideoShareLinkQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useFetchVideoShareLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchVideoShareLinkQuery,
    FetchVideoShareLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchVideoShareLinkQuery, FetchVideoShareLinkQueryVariables>(
    FetchVideoShareLinkDocument,
    options,
  );
}
export function useFetchVideoShareLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchVideoShareLinkQuery,
    FetchVideoShareLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchVideoShareLinkQuery, FetchVideoShareLinkQueryVariables>(
    FetchVideoShareLinkDocument,
    options,
  );
}
export type FetchVideoShareLinkQueryHookResult = ReturnType<typeof useFetchVideoShareLinkQuery>;
export type FetchVideoShareLinkLazyQueryHookResult = ReturnType<
  typeof useFetchVideoShareLinkLazyQuery
>;
export type FetchVideoShareLinkQueryResult = Apollo.QueryResult<
  FetchVideoShareLinkQuery,
  FetchVideoShareLinkQueryVariables
>;
