const $twAvatarImage = {
  smallSize: 24,
  mediumSize: '$twListItem.contentHeight',
  largeSize: '$twListItem.totalHeight',
  largerSize: 75,
  xlargeSize: 90,

  defaultTextBackground: '$colorHue.grayMid',
  defaultTextColor: '$colorHue.white',

  defaultIconBackground: '$colorHue.gray',
  defaultIconColor: '$colorHue.grayBlue',

  defaultBorderColor: '$colorHue.white',
  defaultBorderRadius: 4,
  defaultBorderWidth: 1,
};

const $twAvatarImagePlaceholder = {
  backgroundColor: '#EDEEF2',
  border: '1px solid #FFFFFF',
};

export { $twAvatarImage, $twAvatarImagePlaceholder };
