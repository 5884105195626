import { TableProps } from 'antd';
import { ComponentType, FC, ForwardRefExoticComponent, Key, ReactHTML, ReactNode } from 'react';
import { DropTargetHookSpec } from 'react-dnd';

import { ArrayItemNonNullType, Complies, FilterTypeProps } from '@tw/util';

type Component<P> = ComponentType<P> | ForwardRefExoticComponent<P> | FC<P> | keyof ReactHTML;
type CustomizeComponent = Component<{}>;

interface BodyType {
  wrapper?: CustomizeComponent;
  row?: CustomizeComponent;
}

// construct the type for the components prop
export type TableComponentsType<T> = Exclude<TableProps<T>['components'], undefined>;
type OriginalBody<T> = TableComponentsType<T>['body'];
type Body<T> = Complies<OriginalBody<T>, BodyType>;

type OriginalOnRowType<T> = Exclude<TableProps<T>['onRow'], undefined>;
type OnRowParams<T> = Parameters<OriginalOnRowType<T>>;
export type OnRowType<T> = (
  data: OnRowParams<T>[0],
  index?: OnRowParams<T>[1],
) => ReturnType<OriginalOnRowType<T>> & {
  index?: OnRowParams<T>[1];
  moveRow: (dragIndex: number, hoverIndex: number) => void;
};

export enum SortableDragKeys {
  dragHandle = 'dragHandle',
}

// Column types
type ColumnsType<T> = Omit<Exclude<TableProps<T>['columns'], undefined>, 'key'> & {
  key?: Key | SortableDragKeys | keyof typeof SortableDragKeys;
};
export type ColumnType<T> = ArrayItemNonNullType<ColumnsType<T>>;

// Body component type
export type DraggableRowType<T> = Body<T>['row'];

// Datasource type
export type TableDataSource<T> = TableProps<T>['dataSource'];

export type DragSourceType = string | symbol;
export type DropSourceType = DropTargetHookSpec<{}, {}, {}>['accept'];

// data types sent to the renderer as props
export type SortableDragTableRenderProps<T> = {
  components: TableComponentsType<T>;
  sortedDataSource: TableDataSource<T>;
  onRow: OnRowType<T>;
};

export type DataType = {};

export type StringKeys<T> = keyof FilterTypeProps<T, string>;
export type NumberKeys<T> = keyof FilterTypeProps<T, number>;

export enum SortBy {
  asc = 'asc',
  desc = 'desc',
}

export type SortScanAccumulator<T extends DataType> = {
  nextSortOrder: number;
  changed: T[];
};

export type LocalDataState<T extends DataType> = {
  dataSource?: T[];
  changedList?: T[];
  movedItem?: T;
};

// component props
export type SortableDragTableWrapperProps<T extends DataType> = Pick<
  TableProps<T>,
  'dataSource'
> & {
  children: ({ components, sortedDataSource, onRow }: SortableDragTableRenderProps<T>) => ReactNode;
  onSort?: (
    newOrder: TableDataSource<T>,
    oldOrder: TableDataSource<T>,
    changed?: TableDataSource<T>,
    movedItem?: T,
  ) => void;
  dragSource: DragSourceType;
  dropSource?: DropSourceType;
  dragPadding?: number;
  rowKey: StringKeys<T>;
  sortKey?: NumberKeys<T>;
  sortBy?: SortBy | keyof typeof SortBy;
};

export type SortableDragTableProps<T extends DataType> = Omit<TableProps<T>, 'rowKey' | 'onRow'> & {
  onSort?: (
    newOrder: TableDataSource<T>,
    oldOrder: TableDataSource<T>,
    changed?: TableDataSource<T>,
  ) => void;
  // can only be a key of T that returns a string
  rowKey: StringKeys<T>;
  // if set to false, will fallback to a traditional table
  sortEnabled?: boolean;
  dragSource: DragSourceType;
  dragPadding?: number;
  // will default to same value as dragSource
  dropSource?: DropSourceType;
  onRow?: OnRowType<T>;
  // can only be a key of T that returns a number. This enables the internal sorting
  sortKey?: NumberKeys<T>;
  // defaults to 'asc'
  sortBy?: SortBy | keyof typeof SortBy;
  sortFailed?: boolean;
};
