import styled from '@emotion/styled';
import { TWLoadingSpinner } from '@tw/components';

const FallbackWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20vh;
`;

const Fallback = () => (
  <FallbackWrapper>
    <TWLoadingSpinner size="large" />
  </FallbackWrapper>
);

export default Fallback;
