import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { createRef, PureComponent } from 'react';

import { DOMUtils } from '@tw/util';
import DatePicker from './DatePicker';

const { RangePicker } = DatePicker;

export const extendRangeTime = (dateRange: [Dayjs, Dayjs], timeZone: string | undefined) => {
  const startDate = dateRange?.[0]
    ? dayjs(dateRange[0]).tz(timeZone, true).startOf('day')
    : undefined;
  const endDate = dateRange?.[1] ? dayjs(dateRange[1]).tz(timeZone, true).endOf('day') : undefined;

  return [startDate, endDate];
};

// Date range picker does not apply size style from unknown reason
// heights are 40px and 24px respectively, while default size is 32px
const defaultStyle = {
  minWidth: 330,
  height: 32,
};
interface Props {
  containerId: string;
  onChange?: (arg: [Dayjs, Dayjs] | []) => void;
  style?: {};
  testID?: string;
}

type TWInputDateRangeProps = Props & RangePickerProps<Dayjs>;

// Intentionally a class because antd tries to assign a ref to it and produces an error:
// Warning: Stateless function components cannot be given refs. Attempts to access this ref will fail.
class TWInputDateRangeDayjs extends PureComponent<TWInputDateRangeProps> {
  // eslint-disable-next-line
  private elementRef = createRef<any>();

  private pickerId: string;

  constructor(props: TWInputDateRangeProps) {
    super(props);

    this.pickerId =
      Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
  }

  componentDidMount() {
    const inputs = document
      .querySelector(`[data-rangepicker-id="${this.pickerId}"]`)
      ?.getElementsByTagName('input');

    if (inputs && inputs.length > 0) {
      _.forEach(inputs, (input) => {
        input.setAttribute('aria-label', input.getAttribute('placeholder') || 'Select date');
      });
    }
  }

  getContainer = () => {
    const { containerId } = this.props;
    return DOMUtils.getContainer(containerId);
  };

  /**
   * By default, Ant's Range Picker assigns a datetime with a 'time' of current time of day
   * to the start and end date. This creates a problem for our date filters for records created
   * before or after the respective start/end dates. This explicitly sets the filter to search
   * for records created between the beginning of the start date to the end of the end date.
   */
  getFullDateRange = (momentDateRange: [Dayjs, Dayjs]) => {
    const { onChange } = this.props;

    if (_.isFunction(onChange)) {
      const formattedDates: [Dayjs, Dayjs] | [] =
        momentDateRange?.length > 0
          ? [momentDateRange[0].startOf('day'), momentDateRange[1].endOf('day')]
          : [];

      onChange(formattedDates);
    }
  };

  blurOnClose = (open: boolean) => {
    if (!open) {
      // this.elementRef.current.blur();
    }
  };

  render() {
    const { style = {}, testID, ...allOtherProps } = this.props;
    return (
      <RangePicker
        format="LL"
        ref={this.elementRef}
        onChange={this.getFullDateRange}
        onOpenChange={this.blurOnClose}
        getPopupContainer={this.getContainer}
        style={{
          ...defaultStyle,
          ...style,
        }}
        data-testid={testID}
        data-rangepicker-id={this.pickerId}
        {...allOtherProps}
      />
    );
  }
}

export default TWInputDateRangeDayjs;
