/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AthleteInfoCalendarQueryVariables = Types.Exact<{
  codes:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type AthleteInfoCalendarQuery = {
  selectables?: Array<
    | { selectionCode?: string | null }
    | { selectionCode?: string | null; teamMembership?: { isAthlete?: boolean | null } | null }
    | {
        selectionCode?: string | null;
        people?: Array<{ teamMembership?: { isAthlete?: boolean | null } | null } | null> | null;
      }
    | { selectionCode?: string | null }
    | null
  > | null;
};

export const AthleteInfoCalendarDocument = gql`
  query AthleteInfoCalendar($codes: [String]!) {
    selectables(codes: $codes) {
      selectionCode
      ... on SelectionGroup {
        people {
          teamMembership {
            isAthlete
          }
        }
      }
      ... on PersonNode {
        teamMembership {
          isAthlete
        }
      }
    }
  }
`;

/**
 * __useAthleteInfoCalendarQuery__
 *
 * To run a query within a React component, call `useAthleteInfoCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteInfoCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteInfoCalendarQuery({
 *   variables: {
 *      codes: // value for 'codes'
 *   },
 * });
 */
export function useAthleteInfoCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<AthleteInfoCalendarQuery, AthleteInfoCalendarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AthleteInfoCalendarQuery, AthleteInfoCalendarQueryVariables>(
    AthleteInfoCalendarDocument,
    options,
  );
}
export function useAthleteInfoCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AthleteInfoCalendarQuery,
    AthleteInfoCalendarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AthleteInfoCalendarQuery, AthleteInfoCalendarQueryVariables>(
    AthleteInfoCalendarDocument,
    options,
  );
}
export type AthleteInfoCalendarQueryHookResult = ReturnType<typeof useAthleteInfoCalendarQuery>;
export type AthleteInfoCalendarLazyQueryHookResult = ReturnType<
  typeof useAthleteInfoCalendarLazyQuery
>;
export type AthleteInfoCalendarQueryResult = Apollo.QueryResult<
  AthleteInfoCalendarQuery,
  AthleteInfoCalendarQueryVariables
>;
