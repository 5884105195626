import { PureComponent } from 'react';

import { TWInputSelectTerm } from '../TWInputSelectTerm';
import { TWInputSelectTermProps } from '../TWInputSelectTerm/TWInputSelectTerm.definitions';

interface TWInputSelectTermFormProps extends TWInputSelectTermProps {
  initialValue?: number[];
}

class TWInputSelectTermForm extends PureComponent<TWInputSelectTermFormProps> {
  render() {
    const { initialValue, ...otherProps } = this.props;

    return <TWInputSelectTerm defaultValue={initialValue} {...otherProps} />;
  }
}

export default TWInputSelectTermForm;
