import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import { TWInputMultiSelect } from '@tw/components';
import { useDefaultUserTypePermissionsInputQuery } from '@tw/generated';
import { getTranslation } from '@tw/i18n';
import { relayUtils } from '@tw/util';

type PermissionGroupItem = {
  key: string;
  value: number;
  label: string;
  groupLabel: string;
};

interface TWInputSelectPermissionProps {
  onChange?: Function;
  popupContainerId?: string;
  testID?: string;
  disabled?: boolean;
}

const TWInputSelectPermission = ({
  onChange,
  popupContainerId = '',
  testID = '',
  disabled = false,
  ...rest
}: TWInputSelectPermissionProps) => {
  const { loading, data } = useDefaultUserTypePermissionsInputQuery();

  const permissions = useMemo(() => {
    const items = data?.permissions?.edges.reduce<PermissionGroupItem[]>((permList, perm) => {
      if (perm?.node) {
        permList.push({
          key: perm.node.id,
          value: Number(relayUtils.fromGlobalId(perm.node.id)),
          label: perm.node.displayName,
          groupLabel: perm.node.category,
        });
      }
      return permList;
    }, []);

    const sortedList = orderBy(items, ['groupLabel', 'label'], ['asc', 'asc']);
    const groupedList = groupBy(sortedList, 'groupLabel');

    return Object.keys(groupedList).map((key) => ({
      groupLabel: key,
      options: groupedList[key],
    }));
  }, [data]);

  if (loading) return null;
  return (
    <TWInputMultiSelect
      loading={loading}
      valueExtractor={(permission: PermissionGroupItem) => permission.key}
      labelInValue
      hasOptGroup
      testID={testID}
      containerId={popupContainerId}
      itemList={permissions}
      placeholder={getTranslation('profiles.permissionsPlaceholder')}
      selectionLabel={getTranslation('permissions', 2)}
      disabled={disabled}
      onChange={onChange}
      {...rest}
    />
  );
};

export default TWInputSelectPermission;
