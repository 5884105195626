import { createBrowserHistory } from 'history';

import TWRouterPage from './TWRouterPage';
import TWRouterPageWithSideNavigation from './TWRouterPageWithSideNavigation';
import { TWRouteProps } from './propTypes';

interface PageConfigProps {
  pageRenderer?: typeof TWRouterPage | typeof TWRouterPageWithSideNavigation;
  containerId?: string;
  routeMap?: TWRouteProps[];
  pageTitle?: string;
}

const history = createBrowserHistory();

const defaultProps: PageConfigProps = {
  containerId: '',
  routeMap: [],
  pageTitle: '',
};

/* eslint-disable react/jsx-props-no-spreading */
const makePageFromConfig = (routeConfig: PageConfigProps) => (props) =>
  <routeConfig.pageRenderer routerPageConfig={routeConfig} history={history} {...props} />;

makePageFromConfig.defaultProps = defaultProps;

export default makePageFromConfig;
