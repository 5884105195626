import { debounce, words } from 'lodash';
import type { AriaAttributes, KeyboardEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useOutsideClick } from '@teamworksdev/react';
import { getTranslation } from '@tw/i18n';
import { useViewer } from '@tw/hooks';

import type {
  IndividualOrGroup,
  OptionGroup,
  SelectionItemsById,
  TreeNode,
  UserGroup,
} from '../types';
import type { UserSelectProps } from '../UserSelect';

const defaultProps = {
  accessibilityLabel: getTranslation('selectUsers', 2),
  alwaysSelected: [],
  canSwitchTeams: false,
  disabled: false,
  hideSelections: false,
  isMulti: false,
  placeholder: getTranslation('selectUsers', 2),
  queryFilters: {},
  readOnly: false,
  resetAfterSelection: false,
  selectionsMaxHeight: 250,
  showAvatars: false,
  testID: 'user-select',
  usersOnly: false,
};

export default function useUserSelect(props: UserSelectProps) {
  /* Viewer */
  const {
    availableTeams,
    currentOrg: { allTeamsTeamId },
  } = useViewer();
  /* Inner Props */
  const innerProps = useMemo(
    () => ({
      ...defaultProps,
      ...props,
    }),
    [props],
  );

  /* Refs */
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dialogInputRef = useRef<HTMLInputElement>(null);
  const focusableNodes = useRef<Record<string, TreeNode>>({});
  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const teamSelectRef = useRef<HTMLDivElement>(null);
  const instanceId = 'user-select';

  /* Input */
  const [controlledValue, setControlledValue] = useState('');

  /* Menu */
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  /* Focus */
  const [focusedId, setFocusedId] = useState<string | null>(null);

  /* User Selections */
  const [selectionItemsById, setSelectionItemsById] = useState<SelectionItemsById>(
    innerProps.selections && innerProps.selections.length > 0
      ? makeSelectionItemsById(innerProps.selections, innerProps.isMulti)
      : {},
  );

  /* Dialog */
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogSelections, setDialogSelections] = useState<SelectionItemsById>(
    innerProps.selections && innerProps.selections.length > 0
      ? makeSelectionItemsById(innerProps.selections, innerProps.isMulti)
      : {},
  );

  /* Team Selection */
  const [currentTeam, setCurrentTeam] = useState<{ label: string; teamId: string }>(
    innerProps.currentTeam ?? { label: '', teamId: '' },
  );

  /* Accessibility */
  const inputProps = useMemo<AriaAttributes>(
    () => ({
      role: 'combobox',
      'aria-expanded': menuOpen,
      'aria-controls': menuOpen ? instanceId : undefined,
      'aria-haspopup': 'tree',
      'aria-autocomplete': 'list',
      autoComplete: 'off',
      autoCorrect: 'off',
    }),
    [menuOpen, instanceId],
  );
  const listBoxProps = useMemo<AriaAttributes & { id: string }>(
    () => ({
      id: instanceId,
      role: 'tree',
      tabIndex: -1,
    }),
    [instanceId],
  );
  const popoverProps = useMemo<AriaAttributes>(() => ({}), []);

  useOutsideClick({
    ref: popoverRef,
    handler: () => setMenuOpen(false),
  });

  const makeGroupItems = useCallback(
    (group: UserGroup, groupId: string): IndividualOrGroup[] => {
      const result: IndividualOrGroup[] = [];

      // Include the user type selection only when usersOnly is false
      if (!innerProps.usersOnly) {
        result.push({
          ...group.data,
          uid: getOptionId(groupId, group.data.id),
          label: group.data.label || '',
          children: group.children,
          isGroupOption: true,
          removable: true,
        });
      }

      // Always include the users
      const users = group.children.map((user) => ({
        ...user.data,
        uid: getOptionId(groupId, user.data.id),
        removable: user.data.removable ?? false,
      }));

      result.push(...users);

      return result;
    },
    [innerProps.usersOnly],
  );

  const optionGroups = useMemo<OptionGroup[]>(() => {
    // Process groups
    const groups = !innerProps.data
      ? []
      : innerProps.data.reduce<OptionGroup[]>((acc, group) => {
          const uid = getGroupId(instanceId, group.data.id);
          const groupName = group.data.label || '';
          const isCurrentTeam = group.data.teamId === currentTeam.teamId;
          const isAllTeams = group.data.teamId === allTeamsTeamId;
          const groupTeamLabel = isAllTeams
            ? `(${getTranslation('allTeams')})`
            : `(${currentTeam.label})`;

          // If the user can switch teams
          if (innerProps.canSwitchTeams) {
            // If the dialog is open, only show the groups for the current team
            // If the dialog is closed, show the groups for the current team and All Teams
            if ((dialogOpen && isCurrentTeam) || (!dialogOpen && (isCurrentTeam || isAllTeams))) {
              acc.push({
                uid,
                expanded: false,
                items: makeGroupItems(group, uid),
                name: `${groupName}${!dialogOpen ? ` ${groupTeamLabel}` : ''}`,
              });
            }
          } else {
            const currentGroupTeamLabel =
              `(${availableTeams.find((team) => team.teamId === group.data.teamId)?.label})` ?? '';
            // If the user can't switch teams, include all groups
            acc.push({
              uid,
              expanded: false,
              items: makeGroupItems(group, uid),
              name: `${groupName} ${currentGroupTeamLabel}`,
            });
          }
          return acc;
        }, []);

    return groups;
  }, [
    allTeamsTeamId,
    availableTeams,
    currentTeam.label,
    currentTeam.teamId,
    dialogOpen,
    innerProps.canSwitchTeams,
    innerProps.data,
    makeGroupItems,
  ]);

  /* Output */
  const [filteredOptionGroups, setFilteredOptionGroups] = useState<OptionGroup[]>(optionGroups);

  const preserveCheckedState = (item: IndividualOrGroup) => ({
    ...item,
    isChecked: dialogOpen
      ? dialogSelections[item.id]?.isChecked
      : selectionItemsById[item.id]?.isChecked,
  });

  const makeFilteredOptionGroups = (query?: string): OptionGroup[] =>
    optionGroups.reduce((acc: OptionGroup[], group) => {
      let itemsToInclude: IndividualOrGroup[];

      if (query) {
        itemsToInclude = group.items
          .filter((item) =>
            stringContainsTerm(
              item.label.toLocaleLowerCase().trim(),
              query.toLocaleLowerCase().trim(),
            ),
          )
          .map(preserveCheckedState);
      } else {
        itemsToInclude = group.items.map(preserveCheckedState);
      }

      if (!itemsToInclude.length) {
        return acc; // Skip groups with no matching items
      }

      return acc.concat({
        ...group,
        expanded: true,
        items: itemsToInclude,
      });
    }, []);

  const onInputClick = () => {
    setMenuOpen(true);
  };

  const debouncedInputChange = debounce((search: string) => {
    setFilteredOptionGroups(makeFilteredOptionGroups(search));
  }, 240);

  const onChange = (selections: IndividualOrGroup[]) => {
    if (innerProps.onChange && typeof innerProps.onChange === 'function') {
      innerProps.onChange(selections);
    }
  };

  const resetPopover = () => {
    setMenuOpen(false);
    setFocusedId(null);
  };

  const clearGroupsAndInput = () => {
    setControlledValue('');
    setFilteredOptionGroups(makeFilteredOptionGroups());
  };

  const setGroupsAndInput = () => setFilteredOptionGroups(makeFilteredOptionGroups(inputValue));

  // Update filteredOptionGroups when the optionGroups change
  // optionGroups is only updated if the team changes
  useEffect(() => {
    if (innerProps.canSwitchTeams) {
      setGroupsAndInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerProps.canSwitchTeams, optionGroups]);

  const focusInput = () =>
    dialogOpen ? dialogInputRef?.current?.focus() : inputRef?.current?.focus();

  const resetFocus = () => {
    setFocusedId(null);
    focusInput();
    if (listBoxRef) {
      listBoxRef.current?.scrollTo(0, 0);
    }
  };

  const scrollTo = (elementId: string, delay: boolean) => {
    const element = document.getElementById(elementId);
    const block = 'nearest';
    if (element) {
      if (delay) {
        setTimeout(() => {
          element.scrollIntoView({ block });
        }, 80);
      } else {
        element.scrollIntoView({ block });
      }
    }
  };

  const getFirstNode = () =>
    Object.values(focusableNodes.current).find(
      (node) => node !== undefined && node.previousId === undefined,
    );

  const getLastNode = () =>
    Object.values(focusableNodes.current).find(
      (node) => node !== undefined && node.nextId === undefined,
    );

  const getNextNode = (currentId: string | null) => {
    if (currentId === null) return getFirstNode();

    const nextId = focusableNodes.current[currentId]?.nextId;
    if (nextId) {
      return focusableNodes.current[nextId];
    }
    resetFocus();
    return null;
  };

  const getPreviousNode = (currentId: string | null) => {
    if (currentId === null) return getLastNode();

    const previousId = focusableNodes.current[currentId]?.previousId;
    if (previousId) {
      return focusableNodes.current[previousId];
    }
    resetFocus();
    return null;
  };

  const isExpanded = (uid: string) => filteredOptionGroups.some((g) => g.uid === uid && g.expanded);

  const hasParent = (id: string) => {
    const parentId = focusableNodes.current[id]?.parentId;
    return parentId !== undefined;
  };
  const hasChildren = (id: string) => focusableNodes.current[id]?.expandable ?? false;

  const allOptionGroups = useMemo<OptionGroup[]>(() => {
    // Return all groups which is needed for the explode functionality
    const groups = !innerProps.data
      ? []
      : innerProps.data.reduce<OptionGroup[]>((acc, group) => {
          const uid = getGroupId(instanceId, group.data.id);
          const groupName = group.data.label || '';

          acc.push({
            uid,
            expanded: false,
            items: makeGroupItems(group, uid),
            name: groupName,
          });

          return acc;
        }, []);

    return groups;
  }, [innerProps.data, makeGroupItems]);

  const onExplode = (uid?: string) => {
    if (!uid) return;
    // Find the group with the specified groupId
    const group = allOptionGroups.find((optionGroup) => uid.includes(optionGroup.uid));
    // Iterate over each item in the group
    group?.items.forEach((item) => {
      // Check if the item is not selected and is not a group option
      if (
        !item.isGroupOption &&
        !(item.id in (dialogOpen ? dialogSelections : selectionItemsById)) &&
        item.removable
      ) {
        // If not selected and not a group option, select the item
        onSelect(item);
      }
      if (item.isGroupOption) {
        onDeselect(item);
      }
    });
  };

  const getGroupCount = (uid: string) =>
    innerProps.data.find((d) => uid.includes(d.data.id))?.children.length ?? undefined;

  const getPillCount = () => {
    let itemsCount = 0;
    const groupOptions = Object.values(dialogSelections).filter((item) => item.isGroupOption);
    Object.values(dialogSelections).forEach((selection) => {
      if (!selection.isGroupOption) {
        if (groupOptions.find((group) => selection.uid?.includes(group.id))) {
          return;
        }
        itemsCount += 1;
      } else {
        itemsCount += selection.uid ? getGroupCount(selection.uid) ?? 0 : 0;
      }
    });
    return itemsCount;
  };

  const setFocus = (command: string) => {
    let nextFocusedId = focusedId;
    let scrollDelay = false;
    switch (command) {
      case 'first':
        nextFocusedId = getFirstNode()?.uid ?? null;
        break;
      case 'last':
        nextFocusedId = getLastNode()?.uid ?? null;
        break;
      case 'previous':
        nextFocusedId = getPreviousNode(focusedId)?.uid ?? null;
        break;
      case 'next':
        nextFocusedId = getNextNode(focusedId)?.uid ?? null;
        break;
      default:
        nextFocusedId = command;
        scrollDelay = true;
    }

    setFocusedId(nextFocusedId);

    if (nextFocusedId) {
      const element = document.getElementById(nextFocusedId);
      if (element) element.focus();
      scrollTo(nextFocusedId, scrollDelay);
    }
  };

  const onMouseOver = () => {
    setFocusedId(null);
  };

  /* Keyboard actions */
  const handleArrowLeft = () => {
    if (!focusedId) return;
    if (hasParent(focusedId)) {
      const { parentId } = focusableNodes.current[focusedId];
      onGroupCollapse(parentId!);
      setFocus(parentId!);
    } else {
      onGroupCollapse(focusedId);
    }
  };

  const handleArrowRight = () => {
    if (!focusedId || !hasChildren(focusedId)) return;
    if (isExpanded(focusedId)) {
      setFocus('next');
    } else {
      onGroupExpand(focusedId);
    }
  };

  const handleTab = () => {
    if (dialogOpen) {
      // If you can switch teams, focus the team select
      if (dialogInputRef.current === document.activeElement) {
        teamSelectRef.current?.focus();
        return;
      }
      // Keep the team select separate from tree select focus
      // so you can arrow up and arrow down through the team select
      if (!(teamSelectRef.current === document.activeElement)) {
        if (focusedId && !hasParent(focusedId)) {
          if (isExpanded(focusedId)) {
            setFocus('last');
          } else {
            onGroupExpand(focusedId);
          }
          return;
        }
        setFocus('first');
        return;
      }
    }
    setGroupsAndInput();
    if (menuOpen && !dialogOpen) {
      resetPopover();
      focusInput();
    }
  };

  const handleEnterOrSpace = () => {
    if (!focusedId || !hasParent(focusedId)) return;
    setGroupsAndInput();
    if (!dialogOpen) {
      resetPopover();
    }
  };

  const handleEscape = () => (menuOpen ? resetPopover() : setGroupsAndInput());

  const handleArrowDown = () => {
    if (menuOpen || dialogOpen) {
      setFocus('next');
    }
    if (!dialogOpen) {
      setMenuOpen(true);
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (innerProps.disabled) {
      return;
    }
    switch (event.key) {
      case 'ArrowLeft':
        handleArrowLeft();
        break;
      case 'ArrowRight':
        handleArrowRight();
        break;
      case 'Tab':
        if (event.key === 'Tab' && event.shiftKey) {
          resetFocus();
          return;
        }
        handleTab();
        break;
      case 'Enter':
      case ' ': // space
        handleEnterOrSpace();
        break;
      case 'Escape':
        if (!dialogOpen) {
          if (menuOpen) {
            handleEscape();
            event.preventDefault();
          }
          return;
        }
        break;
      case 'ArrowUp':
        if (!(getLastNode()?.uid === focusedId)) {
          setFocus('last');
        }
        setFocus('previous');
        break;
      case 'ArrowDown':
        handleArrowDown();
        break;
      case 'Home':
        setFocus('first');
        break;
      case 'End':
        setFocus('last');
        break;
      default:
        resetFocus();
        return;
    }
    if (event.key !== 'Tab' && event.key !== 'Escape') event.preventDefault();
  };

  const updateSelections = (id: string, selectionItem: IndividualOrGroup, remove = false) => {
    const updateFn = (prevSelections: SelectionItemsById) => {
      if (remove && !(id in prevSelections)) return prevSelections;

      const newSelections = { ...prevSelections };
      if (remove) {
        delete newSelections[id];
      } else {
        newSelections[id] = checked(selectionItem);
      }
      onChange(Object.values(newSelections));
      return newSelections;
    };

    if (dialogOpen) {
      setDialogSelections(updateFn);
    } else {
      setSelectionItemsById(updateFn);
      setDialogSelections(updateFn);
    }
  };

  const updateFilteredGroups = (id: string, isChecked: boolean) => {
    setFilteredOptionGroups((prevGroups) =>
      prevGroups.map((group) => ({
        ...group,
        items: group.items.map((item) => (item.id === id ? { ...item, isChecked } : item)),
      })),
    );
  };

  const onSelect = (selectionItem: IndividualOrGroup | undefined) => {
    if (!selectionItem) return;

    updateSelections(selectionItem.id, selectionItem);
    updateFilteredGroups(selectionItem.id, true);
  };

  const onDeselect = (selectionItem: IndividualOrGroup | undefined) => {
    if (!selectionItem) return;

    updateSelections(selectionItem.id, selectionItem, true);
    updateFilteredGroups(selectionItem.id, false);
  };

  const setCheckedSelections = (selections: SelectionItemsById) => {
    setFilteredOptionGroups((previousGroups) =>
      previousGroups.map((group) => ({
        ...group,
        items: group.items.map((item) => ({
          ...item,
          isChecked: item.id in selections,
        })),
      })),
    );
  };

  const handleSubmit = () => {
    setSelectionItemsById(dialogSelections);
    setCheckedSelections(dialogSelections);
    setDialogOpen(false);
    // Reset input and groups on close
    clearGroupsAndInput();
  };

  const handleCancel = () => {
    setDialogSelections(selectionItemsById);
    setCheckedSelections(selectionItemsById);
    setDialogOpen(false);
    // Reset input and groups on close
    clearGroupsAndInput();
    // Reset team select to the current team
    if (innerProps.canSwitchTeams)
      setCurrentTeam(innerProps.currentTeam ?? { label: '', teamId: '' });
  };

  const openDialog = () => {
    setDialogOpen(!dialogOpen);
    // Close the menu when opening the dialog so it doesn't steal focus
    setMenuOpen(false);
  };

  const onGroupExpand = (uid: string) =>
    setFilteredOptionGroups((previousGroups) =>
      previousGroups.map((group) => (group.uid === uid ? { ...group, expanded: true } : group)),
    );

  const onGroupCollapse = (uid: string) =>
    setFilteredOptionGroups((previousGroups) =>
      previousGroups.map((group) => (group.uid === uid ? { ...group, expanded: false } : group)),
    );

  const removeSelections = () => {
    Object.values(selectionItemsById).forEach((item) => {
      onDeselect(item);
    });
  };

  const onInputChange = (inputValue: string): void => {
    setControlledValue(inputValue);
    debouncedInputChange(inputValue);

    if (inputValue.length > 0 && !dialogOpen) {
      setMenuOpen(true);
    } else if (!innerProps.isMulti) {
      removeSelections();
    }
  };

  const inputValue =
    innerProps.isMulti || controlledValue
      ? controlledValue
      : Object.values(selectionItemsById)?.[0]?.label ?? '';

  return {
    aria: { inputProps, listBoxProps, popoverProps },
    groups: filteredOptionGroups,
    selectionItemsById,
    // focus
    focusedId,
    setFocusedId,
    resetFocus,
    setFocus,
    // props
    innerProps,
    refs: {
      buttonRef,
      containerRef,
      dialogInputRef,
      focusableNodes,
      inputRef,
      listBoxRef,
      popoverRef,
      teamSelectRef,
    },
    // popover styling
    selectionsMaxHeight: innerProps.selectionsMaxHeight,
    selectionsMaxWidth: innerProps.selectionsMaxWidth,
    // filters
    inputValue,
    // menu
    menuOpen,
    setMenuOpen,
    // dialog
    dialogOpen,
    dialogSelections,
    // team
    currentTeam,
    setCurrentTeam,
    canSwitchTeams: innerProps.canSwitchTeams,
    // accordion handlers
    onGroupExpand,
    onGroupCollapse,
    // input handlers
    onInputClick,
    onInputChange,
    // selection handlers
    onSelect,
    onDeselect,
    onExplode,
    // dialog handlers
    openDialog,
    handleSubmit,
    handleCancel,
    // tree select handlers
    onMouseOver,
    onKeyDown,
    clearGroupsAndInput,
    getGroupCount,
    getPillCount,
  };
}

const checked = (item: IndividualOrGroup): IndividualOrGroup => ({
  ...item,
  isChecked: true,
});

const getOptionId = (groupId: string, optionId: string) => `${groupId}_${optionId}_option`;

const getGroupId = (instanceId: string, groupId: string) => `${instanceId}_${groupId}_group`;

const makeSelectionItemsById = (
  input: IndividualOrGroup[],
  isMulti: boolean,
): SelectionItemsById => {
  const result: SelectionItemsById = {};
  input.forEach((item) => {
    if (!(item.id in result)) {
      result[item.id] = checked(item);
      // early exit for single user select
      if (!isMulti) return result;
    }
    return result;
  });
  return result;
};

const stringContainsTerm = (input: string, filter: string) => {
  if (input.startsWith(filter)) {
    return true;
  }

  const filterTokens = words(filter);
  const inputTokens = words(input);
  return filterTokens.every((fToken) => inputTokens.some((iToken) => iToken.startsWith(fToken)));
};
