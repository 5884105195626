import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

import { noopFn } from '@tw/constants';
import { useConfigContainer, useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { statusAlert } from '@tw/util';
import { studentOperations } from '@tw/services/academicService';
import { StudentTerm } from '@tw/types';

import { TWEditableAdvisorList, TWAttribute } from '../..';

const attributeStyles = {
  '.attribute-item': {
    display: 'flex',
    alignItems: 'center',
  },
};

interface TWStudentAttributesProps {
  containerId: string;
  studentTermItem?: StudentTerm;
  studentId: number;
  onUpdate?: () => void;
}

export const TWStudentAttributes: React.FC<TWStudentAttributesProps> = ({
  studentTermItem = null,
  containerId,
  studentId,
  onUpdate = noopFn,
}) => {
  const configContainer = useConfigContainer();
  const { personId, teamId } = useViewer();

  const [isAdvisorsLoading, setIsAdvisorsLoading] = useState(false);
  const [advisors, setAdvisors] = useState(null);

  const updateStudent = useCallback(
    (updatedValues) => {
      setIsAdvisorsLoading(true);

      studentOperations
        .updateStudent(personId, teamId, studentId, updatedValues, configContainer)
        .then(() => onUpdate())
        .catch(() => {
          statusAlert.error(
            containerId,
            `${getTranslation('warnings.unableUpdateStudents')} ${getTranslation(
              'warnings.contactAdministrators',
            )}`,
          );
        })
        .finally(() => setIsAdvisorsLoading(false));
    },
    [personId, teamId, studentId, configContainer, onUpdate, containerId],
  );

  useEffect(() => {
    if (_.isArray(advisors)) {
      updateStudent({ advisorIds: advisors });
    }
  }, [advisors, updateStudent]);

  const renderAdvisors = () => {
    const advisorsContainerId = 'TWRouterPage__students_drawer_advisors';

    return (
      <div id={advisorsContainerId}>
        <TWEditableAdvisorList
          containerId={advisorsContainerId}
          advisorList={studentTermItem?.student.advisors}
          onChange={setAdvisors}
          isLoading={isAdvisorsLoading}
          width="100%"
        />
      </div>
    );
  };

  if (!studentTermItem) {
    return null;
  }

  return (
    <>
      <TWAttribute
        label={getTranslation('studentsTab.studentId')}
        value={studentTermItem?.student.person.schoolIdentifier}
        style={attributeStyles}
      />
      <TWAttribute
        label={getTranslation('year', 1)}
        value={_.join(studentTermItem?.student.eligibilityYears, ', ')}
        style={attributeStyles}
        testID="Students:Profiles:StudentDrawer:Attributes:Year"
      />
      <TWAttribute
        label={getTranslation('studentsTab.college')}
        value={studentTermItem?.student.metaData?.college}
        style={attributeStyles}
      />
      <TWAttribute
        label={getTranslation('major')}
        value={studentTermItem?.student.metaData?.major}
        style={attributeStyles}
      />
      <TWAttribute
        label={getTranslation('studentsTab.minor')}
        value={studentTermItem?.student.metaData?.minor}
        style={attributeStyles}
      />
      <TWAttribute
        label={getTranslation('advisors', 2)}
        value={renderAdvisors()}
        style={attributeStyles}
        testID="Students:Profiles:StudentDrawer:Attributes:Advisors"
      />
    </>
  );
};
