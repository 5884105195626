import { Children, cloneElement, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DragSourceType, DropSourceType, SortableDragKeys } from '../SortableDragTable.definitions';
import {
  DragObjectWithTypeIndex,
  SortableDragTableRowProps,
  SortableDragTableRowPropsWrapped,
} from './SortableDragTableRow.definitions';
import { StyledTr } from './SortableDragTableRow.styles';

const SortableDragTableRow = ({
  index,
  moveRow,
  className,
  children,
  dragSource,
  dropSource,
  dragPadding,
  ...restProps
}: SortableDragTableRowPropsWrapped) => {
  const ref = useRef<HTMLTableRowElement | null>(null);

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: dropSource,
    drop: (item: DragObjectWithTypeIndex) => {
      if (moveRow) {
        moveRow(item.index, index);
      }
    },
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      let newDropClassName = '';
      if (!className?.includes('ant-table-expanded-row')) {
        if (dragIndex === index) {
          newDropClassName = 'hover-on';
        } else if (dragIndex < index) {
          newDropClassName = 'hover-downward';
        } else {
          newDropClassName = 'hover-upward';
        }
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: newDropClassName,
      };
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: dragSource,
    item: { index, type: dragSource },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Display whole row when dragging and make the whole row a drop zone
  preview(drop(ref));

  const dragIndex = useMemo(() => {
    const arrayChildren = Children.toArray(children);
    const idx = arrayChildren.findIndex(
      (child) =>
        child &&
        typeof child === 'object' &&
        'props' in child &&
        'key' in child &&
        child.key === `.$${SortableDragKeys.dragHandle}`,
    );
    return idx !== -1 ? idx : null;
  }, [children]);

  const styles = [className, isDragging ? 'dragging' : '', isOver ? dropClassName : ''].join(' ');

  return (
    <StyledTr
      ref={ref}
      className={styles}
      dragPadding={dragPadding}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      {Children.map(children, (child, childIndex) => {
        // Clone first child to bind ref that makes it the drag handle
        if (childIndex === dragIndex && child && typeof child === 'object' && 'props' in child) {
          return cloneElement(child, { ref: drag });
        }
        return child;
      })}
    </StyledTr>
  );
};

export const SortableDragTableRowGenerator =
  (dragSource: DragSourceType, dropSource: DropSourceType, dragPadding?: number) =>
  (props: SortableDragTableRowProps) =>
    (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <SortableDragTableRow
        {...props}
        dragSource={dragSource}
        dropSource={dropSource}
        dragPadding={dragPadding}
      />
    );

export default SortableDragTableRow;
