/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupAttachmentBulkCreateMutationVariables = Types.Exact<{
  files:
    | Array<Types.InputMaybe<Types.FileAttachmentFileData>>
    | Types.InputMaybe<Types.FileAttachmentFileData>;
  parentId: Types.Scalars['ID'];
}>;

export type SignupAttachmentBulkCreateMutation = {
  signupAttachmentBulkCreate: { fileAttachment?: Array<{ id: string } | null> | null };
};

export const SignupAttachmentBulkCreateDocument = gql`
  mutation SignupAttachmentBulkCreate($files: [FileAttachmentFileData]!, $parentId: ID!) {
    signupAttachmentBulkCreate(files: $files, parentId: $parentId) {
      fileAttachment {
        id
      }
    }
  }
`;
export type SignupAttachmentBulkCreateMutationFn = Apollo.MutationFunction<
  SignupAttachmentBulkCreateMutation,
  SignupAttachmentBulkCreateMutationVariables
>;

/**
 * __useSignupAttachmentBulkCreateMutation__
 *
 * To run a mutation, you first call `useSignupAttachmentBulkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupAttachmentBulkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupAttachmentBulkCreateMutation, { data, loading, error }] = useSignupAttachmentBulkCreateMutation({
 *   variables: {
 *      files: // value for 'files'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useSignupAttachmentBulkCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupAttachmentBulkCreateMutation,
    SignupAttachmentBulkCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignupAttachmentBulkCreateMutation,
    SignupAttachmentBulkCreateMutationVariables
  >(SignupAttachmentBulkCreateDocument, options);
}
export type SignupAttachmentBulkCreateMutationHookResult = ReturnType<
  typeof useSignupAttachmentBulkCreateMutation
>;
export type SignupAttachmentBulkCreateMutationResult =
  Apollo.MutationResult<SignupAttachmentBulkCreateMutation>;
export type SignupAttachmentBulkCreateMutationOptions = Apollo.BaseMutationOptions<
  SignupAttachmentBulkCreateMutation,
  SignupAttachmentBulkCreateMutationVariables
>;
