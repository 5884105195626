import styled from '@emotion/styled';
import { addColorProperties, BoxComponentProps } from '@tw/components/utils';

export const NavbarContainer = styled.div<
  BoxComponentProps & { sticky?: boolean; borderLeft?: boolean }
>(({ theme, sticky = false, borderLeft = true }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  boxShadow: `inset 0px -2px 0px -1px ${theme.colors.border}`,
  height: theme.baseUnit * 7,
  padding: `0 ${theme.baseUnit}px`,
  position: sticky ? 'sticky' : null,
  top: sticky ? 0 : null,
  borderLeft: borderLeft && sticky ? `1px solid ${theme.colors.border}` : null,
  zIndex: sticky ? 2 : null,
}));

export const NavButtonsContainer = styled.nav(({ theme }) => {
  const { colors, brandColors } = theme;

  const pseudoStyles = {
    color: colors.text,
    textDecoration: 'none',
  };

  return {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'stretch',
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    a: {
      display: 'flex',
      whiteSpace: 'nowrap',
      color: colors.secondary,
      textDecoration: 'none',
      ':visited': pseudoStyles,
      ':active': pseudoStyles,
      ':focus': pseudoStyles,
      ':hover': {
        ...pseudoStyles,
        backgroundColor: addColorProperties(colors.primaryButtonBackground, 0.1),
      },
      transition: theme.cssValues.transition,
      alignSelf: 'stretch',
      alignItems: 'center',
      fontFamily: theme.fontFamily.default,
      fontStyle: 'normal',
      fontWeight: theme.fontWeight.regular,
      padding: theme.baseUnit * 2,
    },
    'a.active': {
      color: colors.text,
      fontWeight: theme.fontWeight.semibold,
      borderBottom: `2px solid ${brandColors.primaryColor}`,
    },
  };
});
