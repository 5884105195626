import styled from '@emotion/styled';

import { theme } from '@tw/components/utils';

export const AttributeWrapper = styled.div({
  '.attribute-label': {
    fontSize: theme.fontUnit * 3,
    paddingTop: 2,
  },
});
