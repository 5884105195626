/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentAttachmentSignedUrlMutationVariables = Types.Exact<{
  fileNames: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type AppointmentAttachmentSignedUrlMutation = {
  appointmentAttachmentSignedUrl: Array<{ url?: string | null; fileName?: string | null } | null>;
};

export const AppointmentAttachmentSignedUrlDocument = gql`
  mutation AppointmentAttachmentSignedUrl($fileNames: [String!]!) {
    appointmentAttachmentSignedUrl(fileNames: $fileNames) {
      url
      fileName
    }
  }
`;
export type AppointmentAttachmentSignedUrlMutationFn = Apollo.MutationFunction<
  AppointmentAttachmentSignedUrlMutation,
  AppointmentAttachmentSignedUrlMutationVariables
>;

/**
 * __useAppointmentAttachmentSignedUrlMutation__
 *
 * To run a mutation, you first call `useAppointmentAttachmentSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentAttachmentSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentAttachmentSignedUrlMutation, { data, loading, error }] = useAppointmentAttachmentSignedUrlMutation({
 *   variables: {
 *      fileNames: // value for 'fileNames'
 *   },
 * });
 */
export function useAppointmentAttachmentSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppointmentAttachmentSignedUrlMutation,
    AppointmentAttachmentSignedUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AppointmentAttachmentSignedUrlMutation,
    AppointmentAttachmentSignedUrlMutationVariables
  >(AppointmentAttachmentSignedUrlDocument, options);
}
export type AppointmentAttachmentSignedUrlMutationHookResult = ReturnType<
  typeof useAppointmentAttachmentSignedUrlMutation
>;
export type AppointmentAttachmentSignedUrlMutationResult =
  Apollo.MutationResult<AppointmentAttachmentSignedUrlMutation>;
export type AppointmentAttachmentSignedUrlMutationOptions = Apollo.BaseMutationOptions<
  AppointmentAttachmentSignedUrlMutation,
  AppointmentAttachmentSignedUrlMutationVariables
>;
