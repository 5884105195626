/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentRsvpSaveMutationVariables = Types.Exact<{
  input: Types.AppointmentRsvpInput;
}>;

export type AppointmentRsvpSaveMutation = {
  appointmentRsvpSave: {
    appointmentRSVP?: Array<{
      appointmentOccurrenceId: number;
      personId: number;
      status: Types.Appointmentrsvpstatusenum;
    } | null> | null;
  };
};

export const AppointmentRsvpSaveDocument = gql`
  mutation AppointmentRSVPSave($input: AppointmentRSVPInput!) {
    appointmentRsvpSave(input: $input) {
      appointmentRSVP {
        appointmentOccurrenceId
        personId
        status
      }
    }
  }
`;
export type AppointmentRsvpSaveMutationFn = Apollo.MutationFunction<
  AppointmentRsvpSaveMutation,
  AppointmentRsvpSaveMutationVariables
>;

/**
 * __useAppointmentRsvpSaveMutation__
 *
 * To run a mutation, you first call `useAppointmentRsvpSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentRsvpSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentRsvpSaveMutation, { data, loading, error }] = useAppointmentRsvpSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentRsvpSaveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppointmentRsvpSaveMutation,
    AppointmentRsvpSaveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AppointmentRsvpSaveMutation, AppointmentRsvpSaveMutationVariables>(
    AppointmentRsvpSaveDocument,
    options,
  );
}
export type AppointmentRsvpSaveMutationHookResult = ReturnType<
  typeof useAppointmentRsvpSaveMutation
>;
export type AppointmentRsvpSaveMutationResult = Apollo.MutationResult<AppointmentRsvpSaveMutation>;
export type AppointmentRsvpSaveMutationOptions = Apollo.BaseMutationOptions<
  AppointmentRsvpSaveMutation,
  AppointmentRsvpSaveMutationVariables
>;
