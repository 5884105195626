import { useRef } from 'react';
import * as React from 'react';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { Dayjs } from 'dayjs';

import { noopFn } from '@tw/constants';

import DatePicker from './DatePicker';

interface Props {
  containerId: string;
  testID?: string;
}

const TWInputDatePickerDayjs: React.FC<Props & PickerProps<Dayjs>> = ({
  containerId,
  testID,
  onChange = noopFn,
  ...rest
}) => {
  const inputElement = useRef(null);

  // this allows us to update the value even if the user doesn't click on the "ok" button in the dropdown
  const onSelect = (e) => {
    onChange(e, e.format());
  };

  const onOpenChange = (isOpen) => {
    if (!isOpen) {
      // inputElement.current.blur();
    }
  };

  return (
    <DatePicker
      onSelect={onSelect}
      onChange={onChange}
      onOpenChange={onOpenChange}
      data-testid={testID}
      getPopupContainer={() => document.getElementById(containerId)}
      ref={inputElement}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default TWInputDatePickerDayjs;
