// This must be the first line in src/index.tsx, according to this:
// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import 'react-app-polyfill/ie11';

// This is needed for Cypress to run properly, or any other environments that
// don't have promise.finally()
import 'promise.prototype.finally/auto';
import 'broadcastchannel-polyfill';
import './util/shims';

// This needs to initialize before any application JS to ensure tracing works correctly
import './datadog';

import { datadogRum } from '@datadog/browser-rum';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from './components/components.web/ErrorBoundary';
import { FeatureFlagsProvider } from './contexts/featureFlags';
import Hub from './modules/Hub';

(async () => {
  const [subdomain] = window.location.host.split('.'); // eslint-disable-line

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.FEATURE_FLAG_CLIENT_ID ?? '',
    user: { anonymous: true, custom: { subdomain } },
    options: {
      fetchGoals: false,
      sendEvents: false,
      sendLDHeaders: false,
      streaming: true,
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });

  // For Global Admin we don't need to mount the React app at all. When logged
  // in as a global admin, the `react-root` node is not rendered. Instead we
  // will make the page visible and do nothing else.
  const container = document.getElementById('react-root');

  if (!container) {
    document.body.style.opacity = '1';
    return;
  }

  const root = createRoot(container);

  root.render(
    <ErrorBoundary>
      <LDProvider>
        <FeatureFlagsProvider>
          <Hub />
        </FeatureFlagsProvider>
      </LDProvider>
    </ErrorBoundary>,
  );
})();
