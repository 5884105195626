import * as React from 'react';
import _ from 'lodash';

import { Container, LiOdd, LiEven, List, Title } from './TWTextAnswerList.styles';

interface TWTextAnswerListProps {
  fieldAnswerList: React.ReactNode[];
  fieldName: React.ReactNode;
}

const TWTextAnswerList = ({ fieldAnswerList, fieldName }: TWTextAnswerListProps) => (
  <Container>
    <Title>{fieldName}</Title>
    <List>
      {_.map(fieldAnswerList, (answer, index: number) => {
        const LiComponent = index % 2 ? LiOdd : LiEven;
        return (
          <LiComponent key={`${index}-${answer}`} role="listitem">
            {answer}
          </LiComponent>
        );
      })}
    </List>
  </Container>
);

export default TWTextAnswerList;
