import { gql } from '@apollo/client';

export const selectablesQuery = gql`
  query SelectablesData($codes: [String]!, $withPeople: Boolean = true) {
    selectables(codes: $codes) {
      label
      selectionCode
      ... on SelectionGroup {
        groupType
        pluralLabel
        team {
          id
          orgId
        }
        people @include(if: $withPeople) {
          id
          personId
          firstName
          lastName
          preferredName
          displayName
          label
          selectionCode
          pictureUrl
          active
          streamUserId
          teams {
            teamId
          }
          teamMembership {
            id
            isAthlete
          }
        }
      }
      ... on PersonNode {
        personId
        orgId
        firstName
        lastName
        emailAddress
        preferredName
        displayName
        initials
        pictureUrl
        active
        streamUserId
        teams {
          teamId
        }
        teamMembership {
          id
          isAthlete
        }
      }
    }
  }
`;
