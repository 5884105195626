import { theme } from '../../../utils';

export const inputSizeScaling = {
  small: {
    height: theme.baseUnit * 3,
  },
  default: {
    height: theme.baseUnit * 4,
  },
  large: {
    height: theme.baseUnit * 5,
  },
};
