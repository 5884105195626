/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.AppointmentUpdateInput;
}>;

export type AppointmentUpdateMutation = {
  appointmentUpdate: {
    appointment?: {
      appointmentId: string;
      attendees?: Array<
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | null
      > | null;
    } | null;
  };
};

export const AppointmentUpdateDocument = gql`
  mutation AppointmentUpdate($id: ID!, $input: AppointmentUpdateInput!) {
    appointmentUpdate(id: $id, input: $input) {
      appointment {
        appointmentId
        attendees {
          label
          selectionCode
        }
      }
    }
  }
`;
export type AppointmentUpdateMutationFn = Apollo.MutationFunction<
  AppointmentUpdateMutation,
  AppointmentUpdateMutationVariables
>;

/**
 * __useAppointmentUpdateMutation__
 *
 * To run a mutation, you first call `useAppointmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentUpdateMutation, { data, loading, error }] = useAppointmentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppointmentUpdateMutation,
    AppointmentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AppointmentUpdateMutation, AppointmentUpdateMutationVariables>(
    AppointmentUpdateDocument,
    options,
  );
}
export type AppointmentUpdateMutationHookResult = ReturnType<typeof useAppointmentUpdateMutation>;
export type AppointmentUpdateMutationResult = Apollo.MutationResult<AppointmentUpdateMutation>;
export type AppointmentUpdateMutationOptions = Apollo.BaseMutationOptions<
  AppointmentUpdateMutation,
  AppointmentUpdateMutationVariables
>;
