import { useEffect, useState } from 'react';
import _ from 'lodash';
import { ApolloError } from '@apollo/client';

/**
 * This is connected to issue with Apollo hooks whose doesn't provide any way to reset state. This cause problems e.g. with modals,
 * because error previously displayed (e.g. after unsuccessful mutation on modal submit) will still be there on modal reopen.
 * There are some initiatives to patch upstream hooks to provide a reset functions, see e.g. https://github.com/apollographql/apollo-feature-requests/issues/157
 *
 * Instead of patching Apollo hooks, here is this workaround. Simply put an array containing Apollo errors (used e.g. on your modal) as input. Then
 * you will get filtered array of passed Apollo errors and clear() function as output.
 *
 * Passed apolloErrors are tracked for changes since last clear() call. When an error change, change flag for the error is set.
 * Calling clear(), clears all change flags.
 *
 * @param apolloErrors array of errors you want to track
 * @return filtered input array and clear() function
 */
const useClearableApolloErrors = (apolloErrors: ApolloError[]): [ApolloError[], () => void] => {
  const [prevErrors, setPrevErrors] = useState<ApolloError[]>(
    new Array<ApolloError>(apolloErrors.length),
  );
  const [resultErrors, setResultErrors] = useState<ApolloError[]>(
    new Array<ApolloError>(apolloErrors.length),
  );
  const [errorsChangeFlags, setErrorsChangeFlags] = useState<boolean[]>(
    new Array<boolean>(apolloErrors.length),
  );

  useEffect(() => {
    const newChangeFlags = _.map(
      apolloErrors,
      (error, idx) => errorsChangeFlags[idx] || error !== prevErrors[idx],
    );
    setErrorsChangeFlags(newChangeFlags);

    const newResult = _.map(
      apolloErrors,
      (error, idx) => (newChangeFlags[idx] && error) || undefined,
    );
    setResultErrors(newResult);

    setPrevErrors(apolloErrors);
  }, [...apolloErrors]);

  const clear = () => {
    // Only clear if any flag is set, to prevent infinite rerender loop.
    if (_.find(errorsChangeFlags, (flag) => flag)) {
      setErrorsChangeFlags(new Array<boolean>(errorsChangeFlags.length));
      setResultErrors(new Array<ApolloError>(apolloErrors.length));
    }
  };

  return [resultErrors, clear];
};

export default useClearableApolloErrors;
