import { CSSProperties } from 'react';
import * as React from 'react';

import TWLoadingSpinner from '../TWLoadingSpinner';

// this can later be broken out into a function if we need to extend it support multiple styles based on props, etc.
const styleSheet: CSSProperties = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  opacity: 0.7,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f0f0f0',
};

interface TWLoadingMaskProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  fixed?: boolean;
  marginTop?: string | number;
  zIndex?: number;
}

const TWLoadingMask = ({
  marginTop = 0,
  fixed = false,
  zIndex = 3,
  ...allUnrecognizedProps
}: TWLoadingMaskProps) => {
  // Need it to cover the whole page
  if (fixed) {
    styleSheet.position = 'fixed';
  }

  const style = {
    ...styleSheet,
    marginTop,
    zIndex,
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div style={style} {...allUnrecognizedProps}>
      <TWLoadingSpinner />
    </div>
  );
};

export default TWLoadingMask;
