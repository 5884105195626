import { reduxConfigUtils } from '@tw/util';

import backendConfigStoreConfig from './backendConfigStoreConfig';

const { layerNames, rootSelector } = backendConfigStoreConfig;

// All the actual work to create selectors and chain them together is in reduxConfigUtils.js;
// go there to see what's actually happening here.
const { topmostSelector, generatedSelectors } = reduxConfigUtils.generateSelectorsForConfigLayers(
  layerNames,
  rootSelector,
);

export { topmostSelector as backendConfigSelector };

// You generally DON'T want these -- they're for debugging and peeking at the internal and
// intermediate values within the layers.
export const {
  debugOverrideValuesSelector,
  mockDataDemoAttributesSelector,
  // Secret Settings and superSecretConfigData need access to all the layers'
  // attributes and values as one block
  allLayerAttributesSelector: allBackendConfigLayerAttributesSelector,
  allLayerValuesSelector: allBackendConfigLayerValuesSelector,
} = generatedSelectors;
