import { Col } from 'antd';

import { TWHeading2, TWLayout } from '@tw/components';
import { getTranslation } from '@tw/i18n';

import { StudentFeedbackList } from './StudentFeedbackList';

interface ProfessorSurveyReportProps {
  uuid?: string;
}

const containerId = 'professor-survey-report-container-id';

const ProfessorSurveyReport = ({ uuid }: ProfessorSurveyReportProps) => (
  <div id={containerId}>
    <TWLayout.Header>
      <Col span={8}>
        <TWHeading2 data-testid="PageHeader">
          {getTranslation('instructorSurvey.studentFeedback.studentAthleteFeedback')}
        </TWHeading2>
      </Col>
    </TWLayout.Header>
    <TWLayout.Content>
      <StudentFeedbackList containerId={containerId} uuid={uuid} />
    </TWLayout.Content>
  </div>
);

export default ProfessorSurveyReport;
