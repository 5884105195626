import _ from 'lodash';

import { pythonApiBackend } from '@tw/backends';
import { filterSpecUtils, graphqlUtils } from '@tw/util';
import { ConfigContainerType, ProfessorSurveyExportFilters } from '@tw/types';

import academicServiceStoreConfig from '../academicServiceStoreConfig';

const normalizeExportVariables = ({
  professorSurveyId,
  advisorIds,
  courseIds,
  eligibilityYearIds,
  isAtRisk,
  searchSurveyResults,
  status,
  teamIds,
}: ProfessorSurveyExportFilters) => ({
  professor_survey_id: professorSurveyId,
  advisor_ids: !_.isEmpty(advisorIds)
    ? filterSpecUtils.createArrayIncludesFilter(advisorIds)
    : undefined,
  course_ids: !_.isEmpty(courseIds)
    ? filterSpecUtils.createArrayIncludesFilter(courseIds)
    : undefined,
  eligibility_year_ids: !_.isEmpty(eligibilityYearIds)
    ? JSON.stringify(eligibilityYearIds)
    : undefined,
  is_at_risk: !_.isNil(isAtRisk) ? graphqlUtils.createBooleanQuery(isAtRisk) : undefined,
  search_string: searchSurveyResults || undefined,
  // TODO: ACA-2242 both the filterSpecUtil & graphqlUtil versions of the array includes filter have bugs.
  // Specifically, they don't correctly work with string arrays where the values are not numbers
  status: !_.isEmpty(status) ? `in:${JSON.stringify(status)}` : undefined,
  team_ids: !_.isEmpty(teamIds) ? filterSpecUtils.createArrayIncludesFilter(teamIds) : undefined,
});

const professorSurveyOperations = {
  fetchProfessorSurveyExport(
    personId: number,
    teamId: number,
    filters: ProfessorSurveyExportFilters,
    configContainer: ConfigContainerType,
  ) {
    const body = {
      contentType: 'csv',
      filters: normalizeExportVariables(filters),
    };

    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: 'professor_surveys/report',
        debugLabel: 'fetchInstructorSurveyExport',
        includeAuthenticationHeader: true,
        desiredFormat: 'text',
        personId,
        teamId,
      },
      {
        // fetch body
        method: 'POST',
        body,
      },
      configContainer,
    );
  },
  sendProfessorSurveyReminder(
    personId: number,
    teamId: number,
    professorSurveyId: string,
    configContainer: ConfigContainerType,
  ) {
    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: `professor_surveys/${professorSurveyId}::reminder`,
        debugLabel: 'sendInstructorSurveyReminder',
        includeAuthenticationHeader: true,
        personId,
        teamId,
      },
      {
        method: 'POST',
      },
      configContainer,
    );
  },
};

export default professorSurveyOperations;
