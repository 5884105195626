import fastMemoize from 'fast-memoize';
import isEqual from 'lodash/isEqual';
import words from 'lodash/words';

/**
 * a function that will take a function as an argument and use fastMemoize variadic strategy
 * to memoize spread arguments.
 * @param {Function} fn
 */
const memoizeVariadicFn = (fn) => fastMemoize(fn, { strategy: fastMemoize.strategies.variadic });

/**
 * a function that will take a function as an argument and memoize the ref instance of it's first argument
 * @param {Function} fn
 */
const memoizeFnByFirstArgRef = (fn) => {
  let lastInputRef;
  let lastOutputRef;

  return (arg1, ...args) => {
    const arg1CacheEquality = arg1 === lastInputRef;

    if (arg1CacheEquality) {
      return lastOutputRef;
    }

    lastInputRef = arg1;
    lastOutputRef = fn(arg1, ...args);
    return lastOutputRef;
  };
};

type FilterSearchFn<T> = (listToSearch: T[], searchQuery: string) => T[];

/**
 * a function that will take a function as an argument and memoize the first argument (array instance) and search term results to search term lookup
 * it will keep a stack of the five (arbitrary number) most recent search terms and use the cached search term results to be filtered by the a substring of the most
 * recent search term.  if the recent search term substring matches any cache key then filter those cache results with the complete search term.
 * @param {FilterSearchFn} fn
 */
const memoizeFilterWithSearchTermFn = <T>(fn: FilterSearchFn<T>) => {
  let resultsCacheBySearchTermFragment: Record<string, T[]> = {};
  let searchTermStack: string[] = [];
  let lastArrayInstance: T[] | undefined;

  return (arrayInstance: T[], searchTerm: string) => {
    if (!searchTerm) {
      return arrayInstance;
    }

    const arrayInstanceCacheEquality = isEqual(arrayInstance, lastArrayInstance);

    if (!arrayInstanceCacheEquality) {
      resultsCacheBySearchTermFragment = {};
      searchTermStack = [];
      lastArrayInstance = arrayInstance;
    }

    if (!resultsCacheBySearchTermFragment[searchTerm]) {
      let previousSuperSetResults = arrayInstance;
      let searchTermTempCacheLookup = searchTerm.substring(0, searchTerm.length - 1);

      while (searchTermTempCacheLookup.length) {
        if (resultsCacheBySearchTermFragment[searchTermTempCacheLookup]) {
          previousSuperSetResults = resultsCacheBySearchTermFragment[searchTermTempCacheLookup];
          break;
        }
        searchTermTempCacheLookup = searchTermTempCacheLookup.substring(
          0,
          searchTermTempCacheLookup.length - 1,
        );
      }

      resultsCacheBySearchTermFragment[searchTerm] = fn(previousSuperSetResults, searchTerm);

      searchTermStack.push(searchTerm);

      if (searchTermStack.length > 5) {
        const oldestTerm = searchTermStack.shift();
        if (oldestTerm) {
          delete resultsCacheBySearchTermFragment[oldestTerm];
        }
      }
    }

    return resultsCacheBySearchTermFragment[searchTerm];
  };
};

// Needle in a haystack search algorithm. mostly used in the callback for Array.filter for lists
const searchTermStartsWordInString = (string: string, searchTerm: string) => {
  const stringLowerCase = string.toLocaleLowerCase().trim();
  const searchTermLowerCase = searchTerm.toLocaleLowerCase().trim();
  if (stringLowerCase.startsWith(searchTermLowerCase)) {
    return true;
  }
  const searchTermWords = words(searchTermLowerCase);
  const stringWords = words(stringLowerCase);
  return searchTermWords.every((searchTermWord) =>
    stringWords.some((stringWord) => stringWord.startsWith(searchTermWord)),
  );
};

export default {
  memoizeVariadicFn,
  memoizeFnByFirstArgRef,
  memoizeFilterWithSearchTermFn,
  searchTermStartsWordInString,
};
