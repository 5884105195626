/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentCheckinOccurrenceQueryVariables = Types.Exact<{
  apptCheckinOccurrenceId: Types.Scalars['UUID'];
}>;

export type AppointmentCheckinOccurrenceQuery = {
  appointmentCheckinOccurrence?: {
    totalCheckinCount?: number | null;
    totalCheckoutCount?: number | null;
    appointmentCheckinActions?: Array<{
      checkinTimes?: Array<any | null> | null;
      checkoutTimes?: Array<any | null> | null;
      notes?: string | null;
      person?: {
        displayName?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        personId: string;
        pictureUrl?: string | null;
        selectionCode?: string | null;
        teamMembership?: { userTypes?: Array<{ id: string } | null> | null } | null;
      } | null;
    } | null> | null;
  } | null;
};

export const AppointmentCheckinOccurrenceDocument = gql`
  query AppointmentCheckinOccurrence($apptCheckinOccurrenceId: UUID!) {
    appointmentCheckinOccurrence(apptCheckinOccurrenceId: $apptCheckinOccurrenceId) {
      totalCheckinCount
      totalCheckoutCount
      appointmentCheckinActions {
        checkinTimes
        checkoutTimes
        notes
        person {
          displayName
          firstName
          id
          lastName
          personId
          pictureUrl
          selectionCode
          teamMembership {
            userTypes {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAppointmentCheckinOccurrenceQuery__
 *
 * To run a query within a React component, call `useAppointmentCheckinOccurrenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCheckinOccurrenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentCheckinOccurrenceQuery({
 *   variables: {
 *      apptCheckinOccurrenceId: // value for 'apptCheckinOccurrenceId'
 *   },
 * });
 */
export function useAppointmentCheckinOccurrenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentCheckinOccurrenceQuery,
    AppointmentCheckinOccurrenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppointmentCheckinOccurrenceQuery,
    AppointmentCheckinOccurrenceQueryVariables
  >(AppointmentCheckinOccurrenceDocument, options);
}
export function useAppointmentCheckinOccurrenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentCheckinOccurrenceQuery,
    AppointmentCheckinOccurrenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppointmentCheckinOccurrenceQuery,
    AppointmentCheckinOccurrenceQueryVariables
  >(AppointmentCheckinOccurrenceDocument, options);
}
export type AppointmentCheckinOccurrenceQueryHookResult = ReturnType<
  typeof useAppointmentCheckinOccurrenceQuery
>;
export type AppointmentCheckinOccurrenceLazyQueryHookResult = ReturnType<
  typeof useAppointmentCheckinOccurrenceLazyQuery
>;
export type AppointmentCheckinOccurrenceQueryResult = Apollo.QueryResult<
  AppointmentCheckinOccurrenceQuery,
  AppointmentCheckinOccurrenceQueryVariables
>;
