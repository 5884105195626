// This file provides styling-related variable overrides that get consumed by
// the web UI
import * as TWButtonTheming from './TWButtonTheming';

const categoriesTheming = {
  ...TWButtonTheming,
};

const $fontFamily = {
  body: 'Roboto,Montserrat,Helvetica,Arial,sans-serif',
};

// color hues object to provide the base colors exposed to the application.
// Think of this as the palette from which all "module" styling sources from.
const $colorHue = {
  purple: '#6D60AA',
};

const $color = {
  checked: '$colorHue.purple',
};

const allSourceValues = {
  $fontFamily,
  $colorHue,
  $color,
};

export default {
  ...allSourceValues,
  ...categoriesTheming,
};
