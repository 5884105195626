import {
  GroupType,
  TeamNode,
  PersonNode as GeneratedPersonNode,
  SelectionGroup as GeneratedSelectionGroup,
} from '@tw/generated';

export interface PersonNode {
  id?: string;
  label: string;
  selectionCode: string;
  initials: string;
  orgId: number;
  displayName: string;
  personId: string;
  firstName: string;
  lastName: string;
  emailAddress?: string;
  active: boolean;
  pictureUrl?: string;
  teamLabels?: string;
  streamUserId?: string | null;
  teamMembership?: {
    id: string;
    isAthlete: boolean;
    userTypes?: { namePlural: string }[];
  } | null;
  __typename: 'PersonNode';
}

export interface SelectionGroup {
  id: string;
  label: string;
  selectionCode: string;
  people: PersonNode[];
  pluralLabel: string;
  groupType: GroupType;
  active?: boolean;
  team?: TeamNode;
  __typename?: 'SelectionGroup';
}

export interface SelectionGroupNoExtends {
  label: string;
  selectionCode: string;
  pluralLabel: string;
  team: TeamNode;
}

export type Selectable = PersonNode | SelectionGroup | SelectionGroupNoExtends;
export type Selectables = Selectable[];

export const isSelectionGroup = (selection: Selectable): selection is SelectionGroup =>
  'people' in selection;

export const isPersonNode = (selection: Selectable): selection is PersonNode =>
  'personId' in selection;

// The above PersonNode and SelectionGroup is leveraged in several spots
// This is a temporary solution to avoid causing regressions in the codebase
type GeneratedSelectable = GeneratedPersonNode | GeneratedSelectionGroup;

export const isGeneratedSelectionGroup = (
  selection: GeneratedSelectable,
): selection is GeneratedSelectionGroup => 'people' in selection;

export const isGeneratedPersonNode = (
  selection: GeneratedSelectable,
): selection is GeneratedPersonNode => 'personId' in selection;
