import styled from '@emotion/styled';
import { sharedSpacingStyles, SpacingComponentProps } from '@tw/components/utils';
import { TWFlexContainer } from '@tw/components';

export const ChooseAccountContainer = styled.div<SpacingComponentProps>(({ theme }) => [
  sharedSpacingStyles,
  {
    width: '50vw',
    minWidth: 300,
    maxWidth: 600,
    maxHeight: 300,
    backgroundColor: theme.colors.white,
    overflow: 'auto',
    border: `${theme.borderWidth.small}px solid ${theme.colors.border}`,
    borderRadius: 6,
  },
]);

export const Container = styled.div({
  height: '100vh',
  '.ant-popover-arrow': {
    display: 'none',
  },
  '.ant-popover-inner-content': {
    padding: 0,
  },
});

export const TWLogoImage = styled.img({
  height: 80,
});

export const CenterContent = styled(TWFlexContainer)({
  height: '100vh',
});
