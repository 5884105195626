/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTicketingOrgSettingsMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  input: Types.TicketingOrgSettingInput;
}>;

export type UpdateTicketingOrgSettingsMutation = {
  ticketingOrgSettingUpdate: {
    ticketingOrgSetting?: {
      settings?: {
        guestApprovals?: Array<string | null> | null;
        notifications?: Array<number | null> | null;
        ticketedTeams?: Array<number | null> | null;
        attributes?: Array<{
          attributeId?: string | null;
          label?: string | null;
          attributeType?: string | null;
          attributeOptions?: Array<string | null> | null;
          isRequired?: boolean | null;
          conditionalAttributes?: Array<{
            attributeId?: string | null;
            label?: string | null;
            attributeType?: string | null;
            attributeOptions?: Array<string | null> | null;
            isRequired?: boolean | null;
            matchedStrValues?: Array<string | null> | null;
            matchedBoolValue?: boolean | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const UpdateTicketingOrgSettingsDocument = gql`
  mutation UpdateTicketingOrgSettings($id: ID, $input: TicketingOrgSettingInput!) {
    ticketingOrgSettingUpdate(id: $id, input: $input) {
      ticketingOrgSetting {
        settings {
          guestApprovals
          attributes {
            attributeId
            label
            attributeType
            attributeOptions
            isRequired
            conditionalAttributes {
              attributeId
              label
              attributeType
              attributeOptions
              isRequired
              matchedStrValues
              matchedBoolValue
            }
          }
          notifications
          ticketedTeams
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateTicketingOrgSettingsMutationFn = Apollo.MutationFunction<
  UpdateTicketingOrgSettingsMutation,
  UpdateTicketingOrgSettingsMutationVariables
>;

/**
 * __useUpdateTicketingOrgSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTicketingOrgSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketingOrgSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketingOrgSettingsMutation, { data, loading, error }] = useUpdateTicketingOrgSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTicketingOrgSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTicketingOrgSettingsMutation,
    UpdateTicketingOrgSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTicketingOrgSettingsMutation,
    UpdateTicketingOrgSettingsMutationVariables
  >(UpdateTicketingOrgSettingsDocument, options);
}
export type UpdateTicketingOrgSettingsMutationHookResult = ReturnType<
  typeof useUpdateTicketingOrgSettingsMutation
>;
export type UpdateTicketingOrgSettingsMutationResult =
  Apollo.MutationResult<UpdateTicketingOrgSettingsMutation>;
export type UpdateTicketingOrgSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTicketingOrgSettingsMutation,
  UpdateTicketingOrgSettingsMutationVariables
>;
