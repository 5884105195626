import { PDFDocument } from 'pdf-lib';
import { TWMessage } from '@tw/components';
import { datadogRum } from '@datadog/browser-rum';
import { getTranslation } from '@tw/i18n';
import { useDownloader } from '../useDownloader';

export const useFormPDF = () => {
  const { downloadFile } = useDownloader();

  const mergePDFs = async (pdfUrlList: string[]) => {
    const mergedPdf = await PDFDocument.create();

    // Load each pdf from s3 url and copy the pages to the main PDF
    const documentPromises = pdfUrlList.map((pdfUrl) =>
      fetch(pdfUrl)
        .then(async (res) => {
          const arrayBuffer = await res.arrayBuffer();
          const document = await PDFDocument.load(arrayBuffer);
          const copiedPages = await mergedPdf.copyPages(document, document.getPageIndices());
          copiedPages.forEach((page) => mergedPdf.addPage(page));
        })
        .catch((pdfError) => {
          datadogRum.addError(pdfError);
          TWMessage.error(getTranslation('forms.formResultsPDF.downloadFailed'));
          throw new Error(pdfError);
        }),
    );
    await Promise.all(documentPromises);

    return mergedPdf.save();
  };

  const downloadPDFs = (pdfUrls: string[], fileName: string) =>
    mergePDFs(pdfUrls)
      .then((mergedResult: Uint8Array) => {
        downloadFile(new Blob([mergedResult], { type: 'application/pdf' }), fileName);
      })
      .catch((pdfError) => {
        datadogRum.addError(pdfError);
        throw new Error(pdfError);
      });

  return { downloadPDFs };
};
