import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { Theme } from '@tw/components/utils';

const getColors = (type: string | undefined, newDesign: boolean, theme: Theme) => {
  switch (type) {
    case 'attention':
      return {
        backgroundColor: '#FFDCFE',
        color: '#70006D',
      };
    case 'primary':
      return {
        backgroundColor: '#E3F2FF',
        color: '#004890',
      };
    case 'canary':
      return {
        backgroundColor: '#FDFFCC',
        color: '#656706',
      };
    case 'success':
      if (newDesign) {
        return {
          backgroundColor: theme.colors.success25,
          color: theme.colors.success700,
        };
      }
      return {
        backgroundColor: theme.colors.pillPositiveBackground,
        color: theme.colors.pillPositiveText,
      };
    case 'warning':
      if (newDesign) {
        return {
          backgroundColor: theme.colors.warning25,
          color: theme.colors.orangeDark600,
        };
      }
      return {
        backgroundColor: '#FFE6D5',
        color: theme.colors.pillWarningText,
      };
    case 'danger':
      if (newDesign) {
        return {
          backgroundColor: theme.colors.error25,
          color: theme.colors.error700,
        };
      }
      return {
        backgroundColor: theme.colors.pillNegativeBackground,
        color: theme.colors.pillNegativeText,
      };
    case 'pending':
      return {
        backgroundColor: theme.colors.warningBackground,
        color: theme.colors.warning,
      };
    // profiles importer pill
    case 'exception':
      if (newDesign) {
        return {
          backgroundColor: theme.colors.warning700,
          color: theme.colors.textHightEmphasis,
        };
      }
      return {
        backgroundColor: theme.colors.yellowIcon,
        color: theme.colors.text,
      };
    // Quick form pills
    case 'requiredQuestion':
      return {
        backgroundColor: theme.colors.warning25,
        color: theme.colors.warning700,
      };
    case 'ruleAdded':
      return {
        backgroundColor: theme.colors.success25,
        color: theme.colors.success700,
      };
    case 'ruleDependent':
      return {
        backgroundColor: '#F5F8FF',
        color: '#004EEB',
      };
    case 'ruleError':
      return {
        backgroundColor: theme.colors.error25,
        color: theme.colors.error700,
      };
    case 'default':
    default:
      return {
        backgroundColor: theme.colors.pillGrayBackground,
        color: theme.colors.pillGrayText,
      };
  }
};

export const TWPillStyledComponent = styled.div<{ type?: string; newDesign?: boolean }>(
  ({ type, newDesign = false, theme }) => ({
    padding: '2px 5px',
    borderRadius: '1em',
    fontSize: theme.fontUnit * 3,
    lineHeight: '17/12',
    whiteSpace: 'nowrap',
    height: newDesign ? undefined : 22,
    color: getColors(type, newDesign, theme).color,
    backgroundColor: getColors(type, newDesign, theme).backgroundColor,
    width: 'fit-content',
  }),
);

export const PillTextWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const PillText = styled.span<{ iconPrefix: ReactNode; newDesign?: boolean }>(
  ({ iconPrefix, newDesign, theme }) => ({
    paddingLeft: iconPrefix ? '8px' : '0px',
    fontSize: newDesign ? '14px' : undefined,
    fontWeight: newDesign ? theme.fontWeight.semibold : theme.fontWeight.regular,
  }),
);
