import { CSSProperties } from 'react';
import { theme } from '@tw/components/utils';

export const iconAdjustStyle: CSSProperties = {
  height: theme.fontUnit * 8,
};

export const courseSelectStyle: CSSProperties = {
  width: '100%',
};
