import _ from 'lodash';

import { graphqlUtils } from '@tw/util';
import {
  ProfessorSurvey,
  ProfessorSurveyPaginatedData,
  ProfessorSurveyPaginatedRawData,
  ProfessorSurveyQueryVariables,
  ProfessorSurveyRawQueryVariables,
  RawProfessorSurvey,
} from '@tw/types';
import professorSurveyAssignmentModel from '../professorSurveyAssignment/professorSurveyAssignmentModel';

const normalizeRawProfessorSurvey = (rawSurvey: RawProfessorSurvey): ProfessorSurvey => {
  if (_.isEmpty(rawSurvey)) {
    return null;
  }

  const {
    countCompletedAssignments,
    countNotCompletedAssignments,
    countStudents,
    isClosed,
    isExpired,
    professorSurveyAssignments,
    ...rest
  } = rawSurvey;
  return {
    countCompletedAssignments: countCompletedAssignments
      ? Number(countCompletedAssignments)
      : undefined,
    countNotCompletedAssignments: countNotCompletedAssignments
      ? Number(countNotCompletedAssignments)
      : undefined,
    countStudents: countStudents ? Number(countStudents) : undefined,
    isClosed: isClosed ? isClosed === 'true' : undefined,
    isExpired: isExpired ? isExpired === 'true' : undefined,
    professorSurveyAssignments: professorSurveyAssignments
      ? professorSurveyAssignmentModel.transformRawPaginatedProfessorSurveyAssignmentData(
          professorSurveyAssignments,
        )
      : undefined,
    ...rest,
  };
};

const normalizePaginatedProfessorSurveyData = (
  data: ProfessorSurveyPaginatedRawData,
): ProfessorSurveyPaginatedData => {
  if (_.isEmpty(data)) {
    return null;
  }

  const { professorSurveys } = data;
  const { edges, pageInfo, totalCount } = professorSurveys;

  return {
    professorSurveys: _.map(graphqlUtils.convertEdgesToArray(edges), normalizeRawProfessorSurvey),
    pageInfo,
    totalCount,
  };
};

const normalizeProfessorSurveyVariables = (
  variables: ProfessorSurveyQueryVariables,
): ProfessorSurveyRawQueryVariables => {
  if (_.isEmpty(variables)) {
    return null;
  }

  const { professorSurveyId, isClosed, isDeleted, ...rest } = variables;
  return {
    professorSurveyId: !_.isEmpty(professorSurveyId)
      ? String(graphqlUtils.createArrayIncludesQuery(professorSurveyId))
      : undefined,
    isClosed: !_.isNil(isClosed) ? graphqlUtils.createBooleanQuery(isClosed) : undefined,
    isDeleted: !_.isNil(isDeleted) ? graphqlUtils.createBooleanQuery(isDeleted) : undefined,
    ...rest,
  };
};

export default {
  normalizePaginatedProfessorSurveyData,
  normalizeProfessorSurveyVariables,
  normalizeRawProfessorSurvey,
};
