const timeZoneData = [
  {
    label: 'Andorra',
    id: 'AD',
    timeZones: [
      {
        timeZoneId: 249,
        region: 'Europe',
        code: 'Europe/Andorra',
        offsetMinutes: 249,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Andorra',
        countryCode: 249,
      },
    ],
  },
  {
    label: 'United Arab Emirates',
    id: 'AE',
    timeZones: [
      {
        timeZoneId: 347,
        region: 'Asia',
        code: 'Asia/Dubai',
        offsetMinutes: 347,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'GST',
        label: 'Dubai',
        countryCode: 347,
      },
    ],
  },
  {
    label: 'Afghanistan',
    id: 'AF',
    timeZones: [
      {
        timeZoneId: 355,
        region: 'Asia',
        code: 'Asia/Kabul',
        offsetMinutes: 355,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'AFT',
        label: 'Kabul',
        countryCode: 355,
      },
    ],
  },
  {
    label: 'Antigua & Barbuda',
    id: 'AG',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Anguilla',
    id: 'AI',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Albania',
    id: 'AL',
    timeZones: [
      {
        timeZoneId: 271,
        region: 'Europe',
        code: 'Europe/Tirane',
        offsetMinutes: 271,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Tirane',
        countryCode: 271,
      },
    ],
  },
  {
    label: 'Armenia',
    id: 'AM',
    timeZones: [
      {
        timeZoneId: 350,
        region: 'Asia',
        code: 'Asia/Yerevan',
        offsetMinutes: 350,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'AMT',
        label: 'Yerevan',
        countryCode: 350,
      },
    ],
  },
  {
    label: 'Angola',
    id: 'AO',
    timeZones: [
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'Antarctica',
    id: 'AQ',
    timeZones: [
      {
        timeZoneId: 389,
        region: 'Antarctica',
        code: 'Antarctica/Davis',
        offsetMinutes: 389,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'DAVT',
        label: 'Davis',
        countryCode: 389,
      },
      {
        timeZoneId: 439,
        region: 'Antarctica',
        code: 'Antarctica/DumontDUrville',
        offsetMinutes: 439,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'DDUT',
        label: 'DumontDUrville',
        countryCode: 439,
      },
      {
        timeZoneId: 356,
        region: 'Antarctica',
        code: 'Antarctica/Mawson',
        offsetMinutes: 356,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'MAWT',
        label: 'Mawson',
        countryCode: 356,
      },
      {
        timeZoneId: 493,
        region: 'Antarctica',
        code: 'Antarctica/Palmer',
        offsetMinutes: 493,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'CLST',
        label: 'Palmer',
        countryCode: 493,
      },
      {
        timeZoneId: 191,
        region: 'Antarctica',
        code: 'Antarctica/Rothera',
        offsetMinutes: 191,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ROTT',
        label: 'Rothera',
        countryCode: 191,
      },
      {
        timeZoneId: 326,
        region: 'Antarctica',
        code: 'Antarctica/Syowa',
        offsetMinutes: 326,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'SYOT',
        label: 'Syowa',
        countryCode: 326,
      },
      {
        timeZoneId: 217,
        region: 'Antarctica',
        code: 'Antarctica/Troll',
        offsetMinutes: 217,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'CEST',
        label: 'Troll',
        countryCode: 217,
      },
      {
        timeZoneId: 374,
        region: 'Antarctica',
        code: 'Antarctica/Vostok',
        offsetMinutes: 374,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'VOST',
        label: 'Vostok',
        countryCode: 374,
      },
      {
        timeZoneId: 478,
        region: 'Pacific',
        code: 'Pacific/Auckland',
        offsetMinutes: 478,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'NZST',
        label: 'Auckland',
        countryCode: 478,
      },
    ],
  },
  {
    label: 'Argentina',
    id: 'AR',
    timeZones: [
      {
        timeZoneId: 160,
        region: 'America',
        code: 'America/Argentina/Buenos_Aires',
        offsetMinutes: 160,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'Buenos Aires',
        countryCode: 160,
      },
      {
        timeZoneId: 163,
        region: 'America',
        code: 'America/Argentina/Cordoba',
        offsetMinutes: 163,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'Cordoba',
        countryCode: 163,
      },
      {
        timeZoneId: 168,
        region: 'America',
        code: 'America/Argentina/Salta',
        offsetMinutes: 168,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'Salta',
        countryCode: 168,
      },
      {
        timeZoneId: 164,
        region: 'America',
        code: 'America/Argentina/Jujuy',
        offsetMinutes: 164,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'Jujuy',
        countryCode: 164,
      },
      {
        timeZoneId: 171,
        region: 'America',
        code: 'America/Argentina/Tucuman',
        offsetMinutes: 171,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'Tucuman',
        countryCode: 171,
      },
      {
        timeZoneId: 161,
        region: 'America',
        code: 'America/Argentina/Catamarca',
        offsetMinutes: 161,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'Catamarca',
        countryCode: 161,
      },
      {
        timeZoneId: 165,
        region: 'America',
        code: 'America/Argentina/La_Rioja',
        offsetMinutes: 165,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'La Rioja',
        countryCode: 165,
      },
      {
        timeZoneId: 169,
        region: 'America',
        code: 'America/Argentina/San_Juan',
        offsetMinutes: 169,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'San Juan',
        countryCode: 169,
      },
      {
        timeZoneId: 166,
        region: 'America',
        code: 'America/Argentina/Mendoza',
        offsetMinutes: 166,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'Mendoza',
        countryCode: 166,
      },
      {
        timeZoneId: 170,
        region: 'America',
        code: 'America/Argentina/San_Luis',
        offsetMinutes: 170,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'San Luis',
        countryCode: 170,
      },
      {
        timeZoneId: 167,
        region: 'America',
        code: 'America/Argentina/Rio_Gallegos',
        offsetMinutes: 167,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'Rio Gallegos',
        countryCode: 167,
      },
      {
        timeZoneId: 172,
        region: 'America',
        code: 'America/Argentina/Ushuaia',
        offsetMinutes: 172,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'ART',
        label: 'Ushuaia',
        countryCode: 172,
      },
    ],
  },
  {
    label: 'Samoa (American)',
    id: 'AS',
    timeZones: [
      {
        timeZoneId: 14,
        region: 'Pacific',
        code: 'Pacific/Pago_Pago',
        offsetMinutes: 14,
        offsetHoursFormatted: '-11:00',
        abbreviation: 'SST',
        label: 'Pago Pago',
        countryCode: 14,
      },
    ],
  },
  {
    label: 'Austria',
    id: 'AT',
    timeZones: [
      {
        timeZoneId: 274,
        region: 'Europe',
        code: 'Europe/Vienna',
        offsetMinutes: 274,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Vienna',
        countryCode: 274,
      },
    ],
  },
  {
    label: 'Australia',
    id: 'AU',
    timeZones: [
      {
        timeZoneId: 463,
        region: 'Australia',
        code: 'Australia/Lord_Howe',
        offsetMinutes: 463,
        offsetHoursFormatted: '+11:00',
        abbreviation: 'LHST',
        label: 'Lord Howe',
        countryCode: 463,
      },
      {
        timeZoneId: 464,
        region: 'Antarctica',
        code: 'Antarctica/Macquarie',
        offsetMinutes: 464,
        offsetHoursFormatted: '+11:00',
        abbreviation: 'MIST',
        label: 'Macquarie',
        countryCode: 464,
      },
      {
        timeZoneId: 448,
        region: 'Australia',
        code: 'Australia/Hobart',
        offsetMinutes: 448,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'AEST',
        label: 'Hobart',
        countryCode: 448,
      },
      {
        timeZoneId: 447,
        region: 'Australia',
        code: 'Australia/Currie',
        offsetMinutes: 447,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'AEST',
        label: 'Currie',
        countryCode: 447,
      },
      {
        timeZoneId: 450,
        region: 'Australia',
        code: 'Australia/Melbourne',
        offsetMinutes: 450,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'AEST',
        label: 'Melbourne',
        countryCode: 450,
      },
      {
        timeZoneId: 453,
        region: 'Australia',
        code: 'Australia/Sydney',
        offsetMinutes: 453,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'AEST',
        label: 'Sydney',
        countryCode: 453,
      },
      {
        timeZoneId: 434,
        region: 'Australia',
        code: 'Australia/Broken_Hill',
        offsetMinutes: 434,
        offsetHoursFormatted: '+010:00',
        abbreviation: 'ACST',
        label: 'Broken Hill',
        countryCode: 434,
      },
      {
        timeZoneId: 445,
        region: 'Australia',
        code: 'Australia/Brisbane',
        offsetMinutes: 445,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'AEST',
        label: 'Brisbane',
        countryCode: 445,
      },
      {
        timeZoneId: 449,
        region: 'Australia',
        code: 'Australia/Lindeman',
        offsetMinutes: 449,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'AEST',
        label: 'Lindeman',
        countryCode: 449,
      },
      {
        timeZoneId: 433,
        region: 'Australia',
        code: 'Australia/Adelaide',
        offsetMinutes: 433,
        offsetHoursFormatted: '+010:00',
        abbreviation: 'ACST',
        label: 'Adelaide',
        countryCode: 433,
      },
      {
        timeZoneId: 435,
        region: 'Australia',
        code: 'Australia/Darwin',
        offsetMinutes: 435,
        offsetHoursFormatted: '+010:00',
        abbreviation: 'ACST',
        label: 'Darwin',
        countryCode: 435,
      },
      {
        timeZoneId: 422,
        region: 'Australia',
        code: 'Australia/Perth',
        offsetMinutes: 422,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'AWST',
        label: 'Perth',
        countryCode: 422,
      },
      {
        timeZoneId: 424,
        region: 'Australia',
        code: 'Australia/Eucla',
        offsetMinutes: 424,
        offsetHoursFormatted: '+09:00',
        abbreviation: 'ACWST',
        label: 'Eucla',
        countryCode: 424,
      },
    ],
  },
  {
    label: 'Aruba',
    id: 'AW',
    timeZones: [
      {
        timeZoneId: 126,
        region: 'America',
        code: 'America/Curacao',
        offsetMinutes: 126,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Curacao',
        countryCode: 126,
      },
    ],
  },
  {
    label: 'Åland Islands',
    id: 'AX',
    timeZones: [
      {
        timeZoneId: 303,
        region: 'Europe',
        code: 'Europe/Helsinki',
        offsetMinutes: 303,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Helsinki',
        countryCode: 303,
      },
    ],
  },
  {
    label: 'Azerbaijan',
    id: 'AZ',
    timeZones: [
      {
        timeZoneId: 346,
        region: 'Asia',
        code: 'Asia/Baku',
        offsetMinutes: 346,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'AZT',
        label: 'Baku',
        countryCode: 346,
      },
    ],
  },
  {
    label: 'Bosnia & Herzegovina',
    id: 'BA',
    timeZones: [
      {
        timeZoneId: 250,
        region: 'Europe',
        code: 'Europe/Belgrade',
        offsetMinutes: 250,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Belgrade',
        countryCode: 250,
      },
    ],
  },
  {
    label: 'Barbados',
    id: 'BB',
    timeZones: [
      {
        timeZoneId: 121,
        region: 'America',
        code: 'America/Barbados',
        offsetMinutes: 121,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Barbados',
        countryCode: 121,
      },
    ],
  },
  {
    label: 'Bangladesh',
    id: 'BD',
    timeZones: [
      {
        timeZoneId: 378,
        region: 'Asia',
        code: 'Asia/Dhaka',
        offsetMinutes: 378,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'BDT',
        label: 'Dhaka',
        countryCode: 378,
      },
    ],
  },
  {
    label: 'Belgium',
    id: 'BE',
    timeZones: [
      {
        timeZoneId: 253,
        region: 'Europe',
        code: 'Europe/Brussels',
        offsetMinutes: 253,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Brussels',
        countryCode: 253,
      },
    ],
  },
  {
    label: 'Burkina Faso',
    id: 'BF',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'Bulgaria',
    id: 'BG',
    timeZones: [
      {
        timeZoneId: 310,
        region: 'Europe',
        code: 'Europe/Sofia',
        offsetMinutes: 310,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Sofia',
        countryCode: 310,
      },
    ],
  },
  {
    label: 'Bahrain',
    id: 'BH',
    timeZones: [
      {
        timeZoneId: 9,
        region: 'Arabia Standard Time',
        code: 'Asia/Qatar',
        offsetMinutes: 9,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'AST',
        label: 'Qatar',
        countryCode: 9,
      },
    ],
  },
  {
    label: 'Burundi',
    id: 'BI',
    timeZones: [
      {
        timeZoneId: 287,
        region: 'Africa',
        code: 'Africa/Maputo',
        offsetMinutes: 287,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'CAT',
        label: 'Maputo',
        countryCode: 287,
      },
    ],
  },
  {
    label: 'Benin',
    id: 'BJ',
    timeZones: [
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'St Barthelemy',
    id: 'BL',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Bermuda',
    id: 'BM',
    timeZones: [
      {
        timeZoneId: 154,
        region: 'Atlantic',
        code: 'Atlantic/Bermuda',
        offsetMinutes: 154,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'ADT',
        label: 'Bermuda',
        countryCode: 154,
      },
    ],
  },
  {
    label: 'Brunei',
    id: 'BN',
    timeZones: [
      {
        timeZoneId: 402,
        region: 'Asia',
        code: 'Asia/Brunei',
        offsetMinutes: 402,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'BNT',
        label: 'Brunei',
        countryCode: 402,
      },
    ],
  },
  {
    label: 'Bolivia',
    id: 'BO',
    timeZones: [
      {
        timeZoneId: 135,
        region: 'America',
        code: 'America/La_Paz',
        offsetMinutes: 135,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'BOT',
        label: 'La Paz',
        countryCode: 135,
      },
    ],
  },
  {
    label: 'Caribbean NL',
    id: 'BQ',
    timeZones: [
      {
        timeZoneId: 126,
        region: 'America',
        code: 'America/Curacao',
        offsetMinutes: 126,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Curacao',
        countryCode: 126,
      },
    ],
  },
  {
    label: 'Brazil',
    id: 'BR',
    timeZones: [
      {
        timeZoneId: 194,
        region: 'America',
        code: 'America/Noronha',
        offsetMinutes: 194,
        offsetHoursFormatted: '-02:00',
        abbreviation: 'FNT',
        label: 'Noronha',
        countryCode: 194,
      },
      {
        timeZoneId: 174,
        region: 'America',
        code: 'America/Belem',
        offsetMinutes: 174,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'BRT',
        label: 'Belem',
        countryCode: 174,
      },
      {
        timeZoneId: 179,
        region: 'America',
        code: 'America/Fortaleza',
        offsetMinutes: 179,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'BRT',
        label: 'Fortaleza',
        countryCode: 179,
      },
      {
        timeZoneId: 187,
        region: 'America',
        code: 'America/Recife',
        offsetMinutes: 187,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'BRT',
        label: 'Recife',
        countryCode: 187,
      },
      {
        timeZoneId: 159,
        region: 'America',
        code: 'America/Araguaina',
        offsetMinutes: 159,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'BRT',
        label: 'Araguaina',
        countryCode: 159,
      },
      {
        timeZoneId: 182,
        region: 'America',
        code: 'America/Maceio',
        offsetMinutes: 182,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'BRT',
        label: 'Maceio',
        countryCode: 182,
      },
      {
        timeZoneId: 173,
        region: 'America',
        code: 'America/Bahia',
        offsetMinutes: 173,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'BRT',
        label: 'Bahia',
        countryCode: 173,
      },
      {
        timeZoneId: 190,
        region: 'America',
        code: 'America/Sao_Paulo',
        offsetMinutes: 190,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'BRT',
        label: 'Sao Paulo',
        countryCode: 190,
      },
      {
        timeZoneId: 124,
        region: 'America',
        code: 'America/Campo_Grande',
        offsetMinutes: 124,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AMT',
        label: 'Campo Grande',
        countryCode: 124,
      },
      {
        timeZoneId: 125,
        region: 'America',
        code: 'America/Cuiaba',
        offsetMinutes: 125,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AMT',
        label: 'Cuiaba',
        countryCode: 125,
      },
      {
        timeZoneId: 189,
        region: 'America',
        code: 'America/Santarem',
        offsetMinutes: 189,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'BRT',
        label: 'Santarem',
        countryCode: 189,
      },
      {
        timeZoneId: 143,
        region: 'America',
        code: 'America/Porto_Velho',
        offsetMinutes: 143,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AMT',
        label: 'Porto Velho',
        countryCode: 143,
      },
      {
        timeZoneId: 123,
        region: 'America',
        code: 'America/Boa_Vista',
        offsetMinutes: 123,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AMT',
        label: 'Boa Vista',
        countryCode: 123,
      },
      {
        timeZoneId: 137,
        region: 'America',
        code: 'America/Manaus',
        offsetMinutes: 137,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AMT',
        label: 'Manaus',
        countryCode: 137,
      },
      {
        timeZoneId: 87,
        region: 'America',
        code: 'America/Eirunepe',
        offsetMinutes: 87,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'ACT',
        label: 'Eirunepe',
        countryCode: 87,
      },
      {
        timeZoneId: 111,
        region: 'America',
        code: 'America/Rio_Branco',
        offsetMinutes: 111,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'ACT',
        label: 'Rio Branco',
        countryCode: 111,
      },
    ],
  },
  {
    label: 'Bahamas',
    id: 'BS',
    timeZones: [
      {
        timeZoneId: 105,
        region: 'America',
        code: 'America/Nassau',
        offsetMinutes: 105,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Nassau',
        countryCode: 105,
      },
    ],
  },
  {
    label: 'Bhutan',
    id: 'BT',
    timeZones: [
      {
        timeZoneId: 384,
        region: 'Asia',
        code: 'Asia/Thimphu',
        offsetMinutes: 384,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'BTT',
        label: 'Thimphu',
        countryCode: 384,
      },
    ],
  },
  {
    label: 'Botswana',
    id: 'BW',
    timeZones: [
      {
        timeZoneId: 287,
        region: 'Africa',
        code: 'Africa/Maputo',
        offsetMinutes: 287,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'CAT',
        label: 'Maputo',
        countryCode: 287,
      },
    ],
  },
  {
    label: 'Belarus',
    id: 'BY',
    timeZones: [
      {
        timeZoneId: 332,
        region: 'Europe',
        code: 'Europe/Minsk',
        offsetMinutes: 332,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'MSK',
        label: 'Minsk',
        countryCode: 332,
      },
    ],
  },
  {
    label: 'Belize',
    id: 'BZ',
    timeZones: [
      {
        timeZoneId: 53,
        region: 'America',
        code: 'America/Belize',
        offsetMinutes: 53,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CST',
        label: 'Belize',
        countryCode: 53,
      },
    ],
  },
  {
    label: 'Canada',
    id: 'CA',
    timeZones: [
      {
        timeZoneId: 157,
        region: 'America',
        code: 'America/St_Johns',
        offsetMinutes: 157,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'NDT',
        label: 'St Johns',
        countryCode: 157,
      },
      {
        timeZoneId: 133,
        region: 'America',
        code: 'America/Halifax',
        offsetMinutes: 133,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'ADT',
        label: 'Halifax',
        countryCode: 133,
      },
      {
        timeZoneId: 128,
        region: 'America',
        code: 'America/Glace_Bay',
        offsetMinutes: 128,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'ADT',
        label: 'Glace Bay',
        countryCode: 128,
      },
      {
        timeZoneId: 140,
        region: 'America',
        code: 'America/Moncton',
        offsetMinutes: 140,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'ADT',
        label: 'Moncton',
        countryCode: 140,
      },
      {
        timeZoneId: 129,
        region: 'America',
        code: 'America/Goose_Bay',
        offsetMinutes: 129,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'ADT',
        label: 'Goose Bay',
        countryCode: 129,
      },
      {
        timeZoneId: 122,
        region: 'America',
        code: 'America/Blanc-Sablon',
        offsetMinutes: 122,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Blanc-Sablon',
        countryCode: 122,
      },
      {
        timeZoneId: 113,
        region: 'America',
        code: 'America/Toronto',
        offsetMinutes: 113,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Toronto',
        countryCode: 113,
      },
      {
        timeZoneId: 106,
        region: 'America',
        code: 'America/Nipigon',
        offsetMinutes: 106,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Nipigon',
        countryCode: 106,
      },
      {
        timeZoneId: 112,
        region: 'America',
        code: 'America/Thunder_Bay',
        offsetMinutes: 112,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Thunder Bay',
        countryCode: 112,
      },
      {
        timeZoneId: 98,
        region: 'America',
        code: 'America/Iqaluit',
        offsetMinutes: 98,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Iqaluit',
        countryCode: 98,
      },
      {
        timeZoneId: 108,
        region: 'America',
        code: 'America/Pangnirtung',
        offsetMinutes: 108,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Pangnirtung',
        countryCode: 108,
      },
      {
        timeZoneId: 81,
        region: 'America',
        code: 'America/Atikokan',
        offsetMinutes: 81,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EST',
        label: 'Atikokan',
        countryCode: 81,
      },
      {
        timeZoneId: 75,
        region: 'America',
        code: 'America/Winnipeg',
        offsetMinutes: 75,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Winnipeg',
        countryCode: 75,
      },
      {
        timeZoneId: 69,
        region: 'America',
        code: 'America/Rainy_River',
        offsetMinutes: 69,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Rainy River',
        countryCode: 69,
      },
      {
        timeZoneId: 72,
        region: 'America',
        code: 'America/Resolute',
        offsetMinutes: 72,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Resolute',
        countryCode: 72,
      },
      {
        timeZoneId: 70,
        region: 'America',
        code: 'America/Rankin_Inlet',
        offsetMinutes: 70,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Rankin Inlet',
        countryCode: 70,
      },
      {
        timeZoneId: 71,
        region: 'America',
        code: 'America/Regina',
        offsetMinutes: 71,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CST',
        label: 'Regina',
        countryCode: 71,
      },
      {
        timeZoneId: 73,
        region: 'America',
        code: 'America/Swift_Current',
        offsetMinutes: 73,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CST',
        label: 'Swift Current',
        countryCode: 73,
      },
      {
        timeZoneId: 43,
        region: 'America',
        code: 'America/Edmonton',
        offsetMinutes: 43,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MDT',
        label: 'Edmonton',
        countryCode: 43,
      },
      {
        timeZoneId: 39,
        region: 'America',
        code: 'America/Cambridge_Bay',
        offsetMinutes: 39,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MDT',
        label: 'Cambridge Bay',
        countryCode: 39,
      },
      {
        timeZoneId: 49,
        region: 'America',
        code: 'America/Yellowknife',
        offsetMinutes: 49,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MDT',
        label: 'Yellowknife',
        countryCode: 49,
      },
      {
        timeZoneId: 45,
        region: 'America',
        code: 'America/Inuvik',
        offsetMinutes: 45,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MDT',
        label: 'Inuvik',
        countryCode: 45,
      },
      {
        timeZoneId: 41,
        region: 'America',
        code: 'America/Creston',
        offsetMinutes: 41,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MST',
        label: 'Creston',
        countryCode: 41,
      },
      {
        timeZoneId: 42,
        region: 'America',
        code: 'America/Dawson_Creek',
        offsetMinutes: 42,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MST',
        label: 'Dawson Creek',
        countryCode: 42,
      },
      {
        timeZoneId: 32,
        region: 'America',
        code: 'America/Vancouver',
        offsetMinutes: 32,
        offsetHoursFormatted: '-08:00',
        abbreviation: 'PDT',
        label: 'Vancouver',
        countryCode: 32,
      },
      {
        timeZoneId: 33,
        region: 'America',
        code: 'America/Whitehorse',
        offsetMinutes: 33,
        offsetHoursFormatted: '-08:00',
        abbreviation: 'PDT',
        label: 'Whitehorse',
        countryCode: 33,
      },
      {
        timeZoneId: 27,
        region: 'America',
        code: 'America/Dawson',
        offsetMinutes: 27,
        offsetHoursFormatted: '-08:00',
        abbreviation: 'PDT',
        label: 'Dawson',
        countryCode: 27,
      },
    ],
  },
  {
    label: 'Cocos (Keeling) Islands',
    id: 'CC',
    timeZones: [
      {
        timeZoneId: 388,
        region: 'Indian',
        code: 'Indian/Cocos',
        offsetMinutes: 388,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'CCT',
        label: 'Cocos',
        countryCode: 388,
      },
    ],
  },
  {
    label: 'Congo (Dem. Rep.)',
    id: 'CD',
    timeZones: [
      {
        timeZoneId: 287,
        region: 'Africa',
        code: 'Africa/Maputo',
        offsetMinutes: 287,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'CAT',
        label: 'Maputo',
        countryCode: 287,
      },
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'Central African Rep.',
    id: 'CF',
    timeZones: [
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'Congo (Rep.)',
    id: 'CG',
    timeZones: [
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'Switzerland',
    id: 'CH',
    timeZones: [
      {
        timeZoneId: 277,
        region: 'Europe',
        code: 'Europe/Zurich',
        offsetMinutes: 277,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Zurich',
        countryCode: 277,
      },
    ],
  },
  {
    label: "Côte d'Ivoire",
    id: 'CI',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'Cook Islands',
    id: 'CK',
    timeZones: [
      {
        timeZoneId: 19,
        region: 'Pacific',
        code: 'Pacific/Rarotonga',
        offsetMinutes: 19,
        offsetHoursFormatted: '-10:00',
        abbreviation: 'CKT',
        label: 'Rarotonga',
        countryCode: 19,
      },
    ],
  },
  {
    label: 'Chile',
    id: 'CL',
    timeZones: [
      {
        timeZoneId: 495,
        region: 'America',
        code: 'America/Santiago',
        offsetMinutes: 495,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'CLST',
        label: 'Santiago',
        countryCode: 495,
      },
      {
        timeZoneId: 496,
        region: 'Pacific',
        code: 'Pacific/Easter',
        offsetMinutes: 496,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EASST',
        label: 'Easter',
        countryCode: 496,
      },
    ],
  },
  {
    label: 'Cameroon',
    id: 'CM',
    timeZones: [
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'China',
    id: 'CN',
    timeZones: [
      {
        timeZoneId: 416,
        region: 'Asia',
        code: 'Asia/Shanghai',
        offsetMinutes: 416,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'CST',
        label: 'Shanghai',
        countryCode: 416,
      },
      {
        timeZoneId: 385,
        region: 'Asia',
        code: 'Asia/Urumqi',
        offsetMinutes: 385,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'XJT',
        label: 'Urumqi',
        countryCode: 385,
      },
    ],
  },
  {
    label: 'Colombia',
    id: 'CO',
    timeZones: [
      {
        timeZoneId: 82,
        region: 'America',
        code: 'America/Bogota',
        offsetMinutes: 82,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'COT',
        label: 'Bogota',
        countryCode: 82,
      },
    ],
  },
  {
    label: 'Costa Rica',
    id: 'CR',
    timeZones: [
      {
        timeZoneId: 54,
        region: 'America',
        code: 'America/Costa_Rica',
        offsetMinutes: 54,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CST',
        label: 'Costa Rica',
        countryCode: 54,
      },
    ],
  },
  {
    label: 'Cuba',
    id: 'CU',
    timeZones: [
      {
        timeZoneId: 90,
        region: 'America',
        code: 'America/Havana',
        offsetMinutes: 90,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'CDT',
        label: 'Havana',
        countryCode: 90,
      },
    ],
  },
  {
    label: 'Cape Verde',
    id: 'CV',
    timeZones: [
      {
        timeZoneId: 199,
        region: 'Atlantic',
        code: 'Atlantic/Cape_Verde',
        offsetMinutes: 199,
        offsetHoursFormatted: '-01:00',
        abbreviation: 'CVT',
        label: 'Cape Verde',
        countryCode: 199,
      },
    ],
  },
  {
    label: 'Curaçao',
    id: 'CW',
    timeZones: [
      {
        timeZoneId: 126,
        region: 'America',
        code: 'America/Curacao',
        offsetMinutes: 126,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Curacao',
        countryCode: 126,
      },
    ],
  },
  {
    label: 'Christmas Island',
    id: 'CX',
    timeZones: [
      {
        timeZoneId: 400,
        region: 'Indian',
        code: 'Indian/Christmas',
        offsetMinutes: 400,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'CXT',
        label: 'Christmas',
        countryCode: 400,
      },
    ],
  },
  {
    label: 'Cyprus',
    id: 'CY',
    timeZones: [
      {
        timeZoneId: 298,
        region: 'Asia',
        code: 'Asia/Nicosia',
        offsetMinutes: 298,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Nicosia',
        countryCode: 298,
      },
    ],
  },
  {
    label: 'Czech Republic',
    id: 'CZ',
    timeZones: [
      {
        timeZoneId: 265,
        region: 'Europe',
        code: 'Europe/Prague',
        offsetMinutes: 265,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Prague',
        countryCode: 265,
      },
    ],
  },
  {
    label: 'Germany',
    id: 'DE',
    timeZones: [
      {
        timeZoneId: 277,
        region: 'Europe',
        code: 'Europe/Zurich',
        offsetMinutes: 277,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Zurich',
        countryCode: 277,
      },
      {
        timeZoneId: 251,
        region: 'Europe',
        code: 'Europe/Berlin',
        offsetMinutes: 251,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Berlin',
        countryCode: 251,
      },
    ],
  },
  {
    label: 'Djibouti',
    id: 'DJ',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'Denmark',
    id: 'DK',
    timeZones: [
      {
        timeZoneId: 10,
        region: 'Europe - Copenhagen',
        code: 'Europe/Copenhagen',
        offsetMinutes: 10,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Copenhagen',
        countryCode: 10,
      },
    ],
  },
  {
    label: 'Dominica',
    id: 'DM',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Dominican Republic',
    id: 'DO',
    timeZones: [
      {
        timeZoneId: 145,
        region: 'America',
        code: 'America/Santo_Domingo',
        offsetMinutes: 145,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Santo Domingo',
        countryCode: 145,
      },
    ],
  },
  {
    label: 'Algeria',
    id: 'DZ',
    timeZones: [
      {
        timeZoneId: 231,
        region: 'Africa',
        code: 'Africa/Algiers',
        offsetMinutes: 231,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CET',
        label: 'Algiers',
        countryCode: 231,
      },
    ],
  },
  {
    label: 'Ecuador',
    id: 'EC',
    timeZones: [
      {
        timeZoneId: 89,
        region: 'America',
        code: 'America/Guayaquil',
        offsetMinutes: 89,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'ECT',
        label: 'Guayaquil',
        countryCode: 89,
      },
      {
        timeZoneId: 80,
        region: 'Pacific',
        code: 'Pacific/Galapagos',
        offsetMinutes: 80,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'GALT',
        label: 'Galapagos',
        countryCode: 80,
      },
    ],
  },
  {
    label: 'Estonia',
    id: 'EE',
    timeZones: [
      {
        timeZoneId: 311,
        region: 'Europe',
        code: 'Europe/Tallinn',
        offsetMinutes: 311,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Tallinn',
        countryCode: 311,
      },
    ],
  },
  {
    label: 'Egypt',
    id: 'EG',
    timeZones: [
      {
        timeZoneId: 280,
        region: 'Africa',
        code: 'Africa/Cairo',
        offsetMinutes: 280,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EET',
        label: 'Cairo',
        countryCode: 280,
      },
    ],
  },
  {
    label: 'Western Sahara',
    id: 'EH',
    timeZones: [
      {
        timeZoneId: 208,
        region: 'Africa',
        code: 'Africa/El_Aaiun',
        offsetMinutes: 208,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'WEST',
        label: 'El Aaiun',
        countryCode: 208,
      },
    ],
  },
  {
    label: 'Eritrea',
    id: 'ER',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'Spain',
    id: 'ES',
    timeZones: [
      {
        timeZoneId: 259,
        region: 'Europe',
        code: 'Europe/Madrid',
        offsetMinutes: 259,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Madrid',
        countryCode: 259,
      },
      {
        timeZoneId: 234,
        region: 'Africa',
        code: 'Africa/Ceuta',
        offsetMinutes: 234,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Ceuta',
        countryCode: 234,
      },
      {
        timeZoneId: 218,
        region: 'Atlantic',
        code: 'Atlantic/Canary',
        offsetMinutes: 218,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'WEST',
        label: 'Canary',
        countryCode: 218,
      },
    ],
  },
  {
    label: 'Ethiopia',
    id: 'ET',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'Finland',
    id: 'FI',
    timeZones: [
      {
        timeZoneId: 303,
        region: 'Europe',
        code: 'Europe/Helsinki',
        offsetMinutes: 303,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Helsinki',
        countryCode: 303,
      },
    ],
  },
  {
    label: 'Fiji',
    id: 'FJ',
    timeZones: [
      {
        timeZoneId: 479,
        region: 'Pacific',
        code: 'Pacific/Fiji',
        offsetMinutes: 479,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'FJT',
        label: 'Fiji',
        countryCode: 479,
      },
    ],
  },
  {
    label: 'Falkland Islands',
    id: 'FK',
    timeZones: [
      {
        timeZoneId: 192,
        region: 'Atlantic',
        code: 'Atlantic/Stanley',
        offsetMinutes: 192,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'FKST',
        label: 'Stanley',
        countryCode: 192,
      },
    ],
  },
  {
    label: 'Micronesia',
    id: 'FM',
    timeZones: [
      {
        timeZoneId: 456,
        region: 'Pacific',
        code: 'Pacific/Chuuk',
        offsetMinutes: 456,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'CHUT',
        label: 'Chuuk',
        countryCode: 456,
      },
      {
        timeZoneId: 471,
        region: 'Pacific',
        code: 'Pacific/Pohnpei',
        offsetMinutes: 471,
        offsetHoursFormatted: '+11:00',
        abbreviation: 'PONT',
        label: 'Pohnpei',
        countryCode: 471,
      },
      {
        timeZoneId: 469,
        region: 'Pacific',
        code: 'Pacific/Kosrae',
        offsetMinutes: 469,
        offsetHoursFormatted: '+11:00',
        abbreviation: 'KOST',
        label: 'Kosrae',
        countryCode: 469,
      },
    ],
  },
  {
    label: 'Faroe Islands',
    id: 'FO',
    timeZones: [
      {
        timeZoneId: 220,
        region: 'Atlantic',
        code: 'Atlantic/Faroe',
        offsetMinutes: 220,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'WEST',
        label: 'Faroe',
        countryCode: 220,
      },
    ],
  },
  {
    label: 'France',
    id: 'FR',
    timeZones: [
      {
        timeZoneId: 263,
        region: 'Europe',
        code: 'Europe/Paris',
        offsetMinutes: 263,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Paris',
        countryCode: 263,
      },
    ],
  },
  {
    label: 'Gabon',
    id: 'GA',
    timeZones: [
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'Britain (UK)',
    id: 'GB',
    timeZones: [
      {
        timeZoneId: 11,
        region: 'Europe - London',
        code: 'Europe/London',
        offsetMinutes: 11,
        offsetHoursFormatted: '0:00',
        abbreviation: 'BST',
        label: 'London',
        countryCode: 11,
      },
    ],
  },
  {
    label: 'Grenada',
    id: 'GD',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Georgia',
    id: 'GE',
    timeZones: [
      {
        timeZoneId: 349,
        region: 'Asia',
        code: 'Asia/Tbilisi',
        offsetMinutes: 349,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'GET',
        label: 'Tbilisi',
        countryCode: 349,
      },
    ],
  },
  {
    label: 'French Guiana',
    id: 'GF',
    timeZones: [
      {
        timeZoneId: 177,
        region: 'America',
        code: 'America/Cayenne',
        offsetMinutes: 177,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'GFT',
        label: 'Cayenne',
        countryCode: 177,
      },
    ],
  },
  {
    label: 'Guernsey',
    id: 'GG',
    timeZones: [
      {
        timeZoneId: 11,
        region: 'Europe - London',
        code: 'Europe/London',
        offsetMinutes: 11,
        offsetHoursFormatted: '0:00',
        abbreviation: 'BST',
        label: 'London',
        countryCode: 11,
      },
    ],
  },
  {
    label: 'Ghana',
    id: 'GH',
    timeZones: [
      {
        timeZoneId: 201,
        region: 'Africa',
        code: 'Africa/Accra',
        offsetMinutes: 201,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Accra',
        countryCode: 201,
      },
    ],
  },
  {
    label: 'Gibraltar',
    id: 'GI',
    timeZones: [
      {
        timeZoneId: 256,
        region: 'Europe',
        code: 'Europe/Gibraltar',
        offsetMinutes: 256,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Gibraltar',
        countryCode: 256,
      },
    ],
  },
  {
    label: 'Greenland',
    id: 'GL',
    timeZones: [
      {
        timeZoneId: 180,
        region: 'America',
        code: 'America/Godthab',
        offsetMinutes: 180,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'WGST',
        label: 'Godthab',
        countryCode: 180,
      },
      {
        timeZoneId: 216,
        region: 'America',
        code: 'America/Danmarkshavn',
        offsetMinutes: 216,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Danmarkshavn',
        countryCode: 216,
      },
      {
        timeZoneId: 197,
        region: 'America',
        code: 'America/Scoresbysund',
        offsetMinutes: 197,
        offsetHoursFormatted: '-01:00',
        abbreviation: 'EGST',
        label: 'Scoresbysund',
        countryCode: 197,
      },
      {
        timeZoneId: 151,
        region: 'America',
        code: 'America/Thule',
        offsetMinutes: 151,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'ADT',
        label: 'Thule',
        countryCode: 151,
      },
    ],
  },
  {
    label: 'Gambia',
    id: 'GM',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'Guinea',
    id: 'GN',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'Guadeloupe',
    id: 'GP',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Equatorial Guinea',
    id: 'GQ',
    timeZones: [
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'Greece',
    id: 'GR',
    timeZones: [
      {
        timeZoneId: 300,
        region: 'Europe',
        code: 'Europe/Athens',
        offsetMinutes: 300,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Athens',
        countryCode: 300,
      },
    ],
  },
  {
    label: 'South Georgia & the South Sandwich Islands',
    id: 'GS',
    timeZones: [
      {
        timeZoneId: 195,
        region: 'Atlantic',
        code: 'Atlantic/South_Georgia',
        offsetMinutes: 195,
        offsetHoursFormatted: '-02:00',
        abbreviation: 'GST',
        label: 'South Georgia',
        countryCode: 195,
      },
    ],
  },
  {
    label: 'Guatemala',
    id: 'GT',
    timeZones: [
      {
        timeZoneId: 56,
        region: 'America',
        code: 'America/Guatemala',
        offsetMinutes: 56,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CST',
        label: 'Guatemala',
        countryCode: 56,
      },
    ],
  },
  {
    label: 'Guam',
    id: 'GU',
    timeZones: [
      {
        timeZoneId: 457,
        region: 'Pacific',
        code: 'Pacific/Guam',
        offsetMinutes: 457,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'ChST',
        label: 'Guam',
        countryCode: 457,
      },
    ],
  },
  {
    label: 'Guinea-Bissau',
    id: 'GW',
    timeZones: [
      {
        timeZoneId: 204,
        region: 'Africa',
        code: 'Africa/Bissau',
        offsetMinutes: 204,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Bissau',
        countryCode: 204,
      },
    ],
  },
  {
    label: 'Guyana',
    id: 'GY',
    timeZones: [
      {
        timeZoneId: 132,
        region: 'America',
        code: 'America/Guyana',
        offsetMinutes: 132,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'GYT',
        label: 'Guyana',
        countryCode: 132,
      },
    ],
  },
  {
    label: 'Hong Kong',
    id: 'HK',
    timeZones: [
      {
        timeZoneId: 408,
        region: 'Asia',
        code: 'Asia/Hong_Kong',
        offsetMinutes: 408,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'HKT',
        label: 'Hong Kong',
        countryCode: 408,
      },
    ],
  },
  {
    label: 'Honduras',
    id: 'HN',
    timeZones: [
      {
        timeZoneId: 74,
        region: 'America',
        code: 'America/Tegucigalpa',
        offsetMinutes: 74,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CST',
        label: 'Tegucigalpa',
        countryCode: 74,
      },
    ],
  },
  {
    label: 'Croatia',
    id: 'HR',
    timeZones: [
      {
        timeZoneId: 250,
        region: 'Europe',
        code: 'Europe/Belgrade',
        offsetMinutes: 250,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Belgrade',
        countryCode: 250,
      },
    ],
  },
  {
    label: 'Haiti',
    id: 'HT',
    timeZones: [
      {
        timeZoneId: 109,
        region: 'America',
        code: 'America/Port-au-Prince',
        offsetMinutes: 109,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EST',
        label: 'Port-au-Prince',
        countryCode: 109,
      },
    ],
  },
  {
    label: 'Hungary',
    id: 'HU',
    timeZones: [
      {
        timeZoneId: 254,
        region: 'Europe',
        code: 'Europe/Budapest',
        offsetMinutes: 254,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Budapest',
        countryCode: 254,
      },
    ],
  },
  {
    label: 'Indonesia',
    id: 'ID',
    timeZones: [
      {
        timeZoneId: 393,
        region: 'Asia',
        code: 'Asia/Jakarta',
        offsetMinutes: 393,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'WIB',
        label: 'Jakarta',
        countryCode: 393,
      },
      {
        timeZoneId: 397,
        region: 'Asia',
        code: 'Asia/Pontianak',
        offsetMinutes: 397,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'WIB',
        label: 'Pontianak',
        countryCode: 397,
      },
      {
        timeZoneId: 414,
        region: 'Asia',
        code: 'Asia/Makassar',
        offsetMinutes: 414,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'WITA',
        label: 'Makassar',
        countryCode: 414,
      },
      {
        timeZoneId: 426,
        region: 'Asia',
        code: 'Asia/Jayapura',
        offsetMinutes: 426,
        offsetHoursFormatted: '+09:00',
        abbreviation: 'WIT',
        label: 'Jayapura',
        countryCode: 426,
      },
    ],
  },
  {
    label: 'Ireland',
    id: 'IE',
    timeZones: [
      {
        timeZoneId: 225,
        region: 'Europe',
        code: 'Europe/Dublin',
        offsetMinutes: 225,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'IST',
        label: 'Dublin',
        countryCode: 225,
      },
    ],
  },
  {
    label: 'Israel',
    id: 'IL',
    timeZones: [
      {
        timeZoneId: 297,
        region: 'Asia',
        code: 'Asia/Jerusalem',
        offsetMinutes: 297,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'IDT',
        label: 'Jerusalem',
        countryCode: 297,
      },
    ],
  },
  {
    label: 'Isle of Man',
    id: 'IM',
    timeZones: [
      {
        timeZoneId: 11,
        region: 'Europe - London',
        code: 'Europe/London',
        offsetMinutes: 11,
        offsetHoursFormatted: '0:00',
        abbreviation: 'BST',
        label: 'London',
        countryCode: 11,
      },
    ],
  },
  {
    label: 'India',
    id: 'IN',
    timeZones: [
      {
        timeZoneId: 371,
        region: 'Asia',
        code: 'Asia/Kolkata',
        offsetMinutes: 371,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'IST',
        label: 'Kolkata',
        countryCode: 371,
      },
    ],
  },
  {
    label: 'British Indian Ocean Territory',
    id: 'IO',
    timeZones: [
      {
        timeZoneId: 386,
        region: 'Indian',
        code: 'Indian/Chagos',
        offsetMinutes: 386,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'IOT',
        label: 'Chagos',
        countryCode: 386,
      },
    ],
  },
  {
    label: 'Iraq',
    id: 'IQ',
    timeZones: [
      {
        timeZoneId: 328,
        region: 'Asia',
        code: 'Asia/Baghdad',
        offsetMinutes: 328,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'AST',
        label: 'Baghdad',
        countryCode: 328,
      },
    ],
  },
  {
    label: 'Iran',
    id: 'IR',
    timeZones: [
      {
        timeZoneId: 345,
        region: 'Asia',
        code: 'Asia/Tehran',
        offsetMinutes: 345,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'IRDT',
        label: 'Tehran',
        countryCode: 345,
      },
    ],
  },
  {
    label: 'Iceland',
    id: 'IS',
    timeZones: [
      {
        timeZoneId: 222,
        region: 'Atlantic',
        code: 'Atlantic/Reykjavik',
        offsetMinutes: 222,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Reykjavik',
        countryCode: 222,
      },
    ],
  },
  {
    label: 'Italy',
    id: 'IT',
    timeZones: [
      {
        timeZoneId: 266,
        region: 'Europe',
        code: 'Europe/Rome',
        offsetMinutes: 266,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Rome',
        countryCode: 266,
      },
    ],
  },
  {
    label: 'Jersey',
    id: 'JE',
    timeZones: [
      {
        timeZoneId: 11,
        region: 'Europe - London',
        code: 'Europe/London',
        offsetMinutes: 11,
        offsetHoursFormatted: '0:00',
        abbreviation: 'BST',
        label: 'London',
        countryCode: 11,
      },
    ],
  },
  {
    label: 'Jamaica',
    id: 'JM',
    timeZones: [
      {
        timeZoneId: 99,
        region: 'America',
        code: 'America/Jamaica',
        offsetMinutes: 99,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EST',
        label: 'Jamaica',
        countryCode: 99,
      },
    ],
  },
  {
    label: 'Jordan',
    id: 'JO',
    timeZones: [
      {
        timeZoneId: 291,
        region: 'Asia',
        code: 'Asia/Amman',
        offsetMinutes: 291,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Amman',
        countryCode: 291,
      },
    ],
  },
  {
    label: 'Japan',
    id: 'JP',
    timeZones: [
      {
        timeZoneId: 430,
        region: 'Asia',
        code: 'Asia/Tokyo',
        offsetMinutes: 430,
        offsetHoursFormatted: '+09:00',
        abbreviation: 'JST',
        label: 'Tokyo',
        countryCode: 430,
      },
    ],
  },
  {
    label: 'Kenya',
    id: 'KE',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'Kyrgyzstan',
    id: 'KG',
    timeZones: [
      {
        timeZoneId: 376,
        region: 'Asia',
        code: 'Asia/Bishkek',
        offsetMinutes: 376,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'KGT',
        label: 'Bishkek',
        countryCode: 376,
      },
    ],
  },
  {
    label: 'Cambodia',
    id: 'KH',
    timeZones: [
      {
        timeZoneId: 390,
        region: 'Asia',
        code: 'Asia/Bangkok',
        offsetMinutes: 390,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'ICT',
        label: 'Bangkok',
        countryCode: 390,
      },
    ],
  },
  {
    label: 'Kiribati',
    id: 'KI',
    timeZones: [
      {
        timeZoneId: 484,
        region: 'Pacific',
        code: 'Pacific/Tarawa',
        offsetMinutes: 484,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'GILT',
        label: 'Tarawa',
        countryCode: 484,
      },
      {
        timeZoneId: 489,
        region: 'Pacific',
        code: 'Pacific/Enderbury',
        offsetMinutes: 489,
        offsetHoursFormatted: '+13:00',
        abbreviation: 'PHOT',
        label: 'Enderbury',
        countryCode: 489,
      },
      {
        timeZoneId: 492,
        region: 'Pacific',
        code: 'Pacific/Kiritimati',
        offsetMinutes: 492,
        offsetHoursFormatted: '+14:00',
        abbreviation: 'LINT',
        label: 'Kiritimati',
        countryCode: 492,
      },
    ],
  },
  {
    label: 'Comoros',
    id: 'KM',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'St Kitts & Nevis',
    id: 'KN',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Korea (North)',
    id: 'KP',
    timeZones: [
      {
        timeZoneId: 428,
        region: 'Asia',
        code: 'Asia/Pyongyang',
        offsetMinutes: 428,
        offsetHoursFormatted: '+09:00',
        abbreviation: 'KST',
        label: 'Pyongyang',
        countryCode: 428,
      },
    ],
  },
  {
    label: 'Korea (South)',
    id: 'KR',
    timeZones: [
      {
        timeZoneId: 429,
        region: 'Asia',
        code: 'Asia/Seoul',
        offsetMinutes: 429,
        offsetHoursFormatted: '+09:00',
        abbreviation: 'KST',
        label: 'Seoul',
        countryCode: 429,
      },
    ],
  },
  {
    label: 'Kuwait',
    id: 'KW',
    timeZones: [
      {
        timeZoneId: 331,
        region: 'Asia',
        code: 'Asia/Riyadh',
        offsetMinutes: 331,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'AST',
        label: 'Riyadh',
        countryCode: 331,
      },
    ],
  },
  {
    label: 'Cayman Islands',
    id: 'KY',
    timeZones: [
      {
        timeZoneId: 107,
        region: 'America',
        code: 'America/Panama',
        offsetMinutes: 107,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EST',
        label: 'Panama',
        countryCode: 107,
      },
    ],
  },
  {
    label: 'Kazakhstan',
    id: 'KZ',
    timeZones: [
      {
        timeZoneId: 375,
        region: 'Asia',
        code: 'Asia/Almaty',
        offsetMinutes: 375,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'ALMT',
        label: 'Almaty',
        countryCode: 375,
      },
      {
        timeZoneId: 382,
        region: 'Asia',
        code: 'Asia/Qyzylorda',
        offsetMinutes: 382,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'QYZT',
        label: 'Qyzylorda',
        countryCode: 382,
      },
      {
        timeZoneId: 358,
        region: 'Asia',
        code: 'Asia/Aqtobe',
        offsetMinutes: 358,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'AQTT',
        label: 'Aqtobe',
        countryCode: 358,
      },
      {
        timeZoneId: 357,
        region: 'Asia',
        code: 'Asia/Aqtau',
        offsetMinutes: 357,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'AQTT',
        label: 'Aqtau',
        countryCode: 357,
      },
      {
        timeZoneId: 363,
        region: 'Asia',
        code: 'Asia/Oral',
        offsetMinutes: 363,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'ORAT',
        label: 'Oral',
        countryCode: 363,
      },
    ],
  },
  {
    label: 'Laos',
    id: 'LA',
    timeZones: [
      {
        timeZoneId: 390,
        region: 'Asia',
        code: 'Asia/Bangkok',
        offsetMinutes: 390,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'ICT',
        label: 'Bangkok',
        countryCode: 390,
      },
    ],
  },
  {
    label: 'Lebanon',
    id: 'LB',
    timeZones: [
      {
        timeZoneId: 292,
        region: 'Asia',
        code: 'Asia/Beirut',
        offsetMinutes: 292,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Beirut',
        countryCode: 292,
      },
    ],
  },
  {
    label: 'St Lucia',
    id: 'LC',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Liechtenstein',
    id: 'LI',
    timeZones: [
      {
        timeZoneId: 277,
        region: 'Europe',
        code: 'Europe/Zurich',
        offsetMinutes: 277,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Zurich',
        countryCode: 277,
      },
    ],
  },
  {
    label: 'Sri Lanka',
    id: 'LK',
    timeZones: [
      {
        timeZoneId: 370,
        region: 'Asia',
        code: 'Asia/Colombo',
        offsetMinutes: 370,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'IST',
        label: 'Colombo',
        countryCode: 370,
      },
    ],
  },
  {
    label: 'Liberia',
    id: 'LR',
    timeZones: [
      {
        timeZoneId: 211,
        region: 'Africa',
        code: 'Africa/Monrovia',
        offsetMinutes: 211,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Monrovia',
        countryCode: 211,
      },
    ],
  },
  {
    label: 'Lesotho',
    id: 'LS',
    timeZones: [
      {
        timeZoneId: 283,
        region: 'Africa',
        code: 'Africa/Johannesburg',
        offsetMinutes: 283,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'SAST',
        label: 'Johannesburg',
        countryCode: 283,
      },
    ],
  },
  {
    label: 'Lithuania',
    id: 'LT',
    timeZones: [
      {
        timeZoneId: 314,
        region: 'Europe',
        code: 'Europe/Vilnius',
        offsetMinutes: 314,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Vilnius',
        countryCode: 314,
      },
    ],
  },
  {
    label: 'Luxembourg',
    id: 'LU',
    timeZones: [
      {
        timeZoneId: 258,
        region: 'Europe',
        code: 'Europe/Luxembourg',
        offsetMinutes: 258,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Luxembourg',
        countryCode: 258,
      },
    ],
  },
  {
    label: 'Latvia',
    id: 'LV',
    timeZones: [
      {
        timeZoneId: 309,
        region: 'Europe',
        code: 'Europe/Riga',
        offsetMinutes: 309,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Riga',
        countryCode: 309,
      },
    ],
  },
  {
    label: 'Libya',
    id: 'LY',
    timeZones: [
      {
        timeZoneId: 290,
        region: 'Africa',
        code: 'Africa/Tripoli',
        offsetMinutes: 290,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EET',
        label: 'Tripoli',
        countryCode: 290,
      },
    ],
  },
  {
    label: 'Morocco',
    id: 'MA',
    timeZones: [
      {
        timeZoneId: 205,
        region: 'Africa',
        code: 'Africa/Casablanca',
        offsetMinutes: 205,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'WEST',
        label: 'Casablanca',
        countryCode: 205,
      },
    ],
  },
  {
    label: 'Monaco',
    id: 'MC',
    timeZones: [
      {
        timeZoneId: 261,
        region: 'Europe',
        code: 'Europe/Monaco',
        offsetMinutes: 261,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Monaco',
        countryCode: 261,
      },
    ],
  },
  {
    label: 'Moldova',
    id: 'MD',
    timeZones: [
      {
        timeZoneId: 302,
        region: 'Europe',
        code: 'Europe/Chisinau',
        offsetMinutes: 302,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Chisinau',
        countryCode: 302,
      },
    ],
  },
  {
    label: 'Montenegro',
    id: 'ME',
    timeZones: [
      {
        timeZoneId: 250,
        region: 'Europe',
        code: 'Europe/Belgrade',
        offsetMinutes: 250,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Belgrade',
        countryCode: 250,
      },
    ],
  },
  {
    label: 'St Martin (French)',
    id: 'MF',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Madagascar',
    id: 'MG',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'Marshall Islands',
    id: 'MH',
    timeZones: [
      {
        timeZoneId: 482,
        region: 'Pacific',
        code: 'Pacific/Majuro',
        offsetMinutes: 482,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'MHT',
        label: 'Majuro',
        countryCode: 482,
      },
      {
        timeZoneId: 481,
        region: 'Pacific',
        code: 'Pacific/Kwajalein',
        offsetMinutes: 481,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'MHT',
        label: 'Kwajalein',
        countryCode: 481,
      },
    ],
  },
  {
    label: 'Macedonia',
    id: 'MK',
    timeZones: [
      {
        timeZoneId: 250,
        region: 'Europe',
        code: 'Europe/Belgrade',
        offsetMinutes: 250,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Belgrade',
        countryCode: 250,
      },
    ],
  },
  {
    label: 'Mali',
    id: 'ML',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'Myanmar (Burma)',
    id: 'MM',
    timeZones: [],
  },
  {
    label: 'Mongolia',
    id: 'MN',
    timeZones: [
      {
        timeZoneId: 420,
        region: 'Asia',
        code: 'Asia/Ulaanbaatar',
        offsetMinutes: 420,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'ULAST',
        label: 'Ulaanbaatar',
        countryCode: 420,
      },
      {
        timeZoneId: 392,
        region: 'Asia',
        code: 'Asia/Hovd',
        offsetMinutes: 392,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'HOVST',
        label: 'Hovd',
        countryCode: 392,
      },
      {
        timeZoneId: 404,
        region: 'Asia',
        code: 'Asia/Choibalsan',
        offsetMinutes: 404,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'CHOST',
        label: 'Choibalsan',
        countryCode: 404,
      },
    ],
  },
  {
    label: 'Macau',
    id: 'MO',
    timeZones: [
      {
        timeZoneId: 413,
        region: 'Asia',
        code: 'Asia/Macau',
        offsetMinutes: 413,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'CST',
        label: 'Macau',
        countryCode: 413,
      },
    ],
  },
  {
    label: 'Northern Mariana Islands',
    id: 'MP',
    timeZones: [
      {
        timeZoneId: 457,
        region: 'Pacific',
        code: 'Pacific/Guam',
        offsetMinutes: 457,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'ChST',
        label: 'Guam',
        countryCode: 457,
      },
    ],
  },
  {
    label: 'Martinique',
    id: 'MQ',
    timeZones: [
      {
        timeZoneId: 139,
        region: 'America',
        code: 'America/Martinique',
        offsetMinutes: 139,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Martinique',
        countryCode: 139,
      },
    ],
  },
  {
    label: 'Mauritania',
    id: 'MR',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'Montserrat',
    id: 'MS',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Malta',
    id: 'MT',
    timeZones: [
      {
        timeZoneId: 260,
        region: 'Europe',
        code: 'Europe/Malta',
        offsetMinutes: 260,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Malta',
        countryCode: 260,
      },
    ],
  },
  {
    label: 'Mauritius',
    id: 'MU',
    timeZones: [
      {
        timeZoneId: 353,
        region: 'Indian',
        code: 'Indian/Mauritius',
        offsetMinutes: 353,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'MUT',
        label: 'Mauritius',
        countryCode: 353,
      },
    ],
  },
  {
    label: 'Maldives',
    id: 'MV',
    timeZones: [
      {
        timeZoneId: 368,
        region: 'Indian',
        code: 'Indian/Maldives',
        offsetMinutes: 368,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'MVT',
        label: 'Maldives',
        countryCode: 368,
      },
    ],
  },
  {
    label: 'Malawi',
    id: 'MW',
    timeZones: [
      {
        timeZoneId: 287,
        region: 'Africa',
        code: 'Africa/Maputo',
        offsetMinutes: 287,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'CAT',
        label: 'Maputo',
        countryCode: 287,
      },
    ],
  },
  {
    label: 'Mexico',
    id: 'MX',
    timeZones: [
      {
        timeZoneId: 64,
        region: 'America',
        code: 'America/Mexico_City',
        offsetMinutes: 64,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Mexico City',
        countryCode: 64,
      },
      {
        timeZoneId: 83,
        region: 'America',
        code: 'America/Cancun',
        offsetMinutes: 83,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EST',
        label: 'Cancun',
        countryCode: 83,
      },
      {
        timeZoneId: 63,
        region: 'America',
        code: 'America/Merida',
        offsetMinutes: 63,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Merida',
        countryCode: 63,
      },
      {
        timeZoneId: 65,
        region: 'America',
        code: 'America/Monterrey',
        offsetMinutes: 65,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Monterrey',
        countryCode: 65,
      },
      {
        timeZoneId: 61,
        region: 'America',
        code: 'America/Matamoros',
        offsetMinutes: 61,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Matamoros',
        countryCode: 61,
      },
      {
        timeZoneId: 46,
        region: 'America',
        code: 'America/Mazatlan',
        offsetMinutes: 46,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MDT',
        label: 'Mazatlan',
        countryCode: 46,
      },
      {
        timeZoneId: 40,
        region: 'America',
        code: 'America/Chihuahua',
        offsetMinutes: 40,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MDT',
        label: 'Chihuahua',
        countryCode: 40,
      },
      {
        timeZoneId: 47,
        region: 'America',
        code: 'America/Ojinaga',
        offsetMinutes: 47,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MDT',
        label: 'Ojinaga',
        countryCode: 47,
      },
      {
        timeZoneId: 44,
        region: 'America',
        code: 'America/Hermosillo',
        offsetMinutes: 44,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MST',
        label: 'Hermosillo',
        countryCode: 44,
      },
      {
        timeZoneId: 31,
        region: 'America',
        code: 'America/Tijuana',
        offsetMinutes: 31,
        offsetHoursFormatted: '-08:00',
        abbreviation: 'PDT',
        label: 'Tijuana',
        countryCode: 31,
      },
      {
        timeZoneId: 52,
        region: 'America',
        code: 'America/Bahia_Banderas',
        offsetMinutes: 52,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Bahia Banderas',
        countryCode: 52,
      },
    ],
  },
  {
    label: 'Malaysia',
    id: 'MY',
    timeZones: [
      {
        timeZoneId: 410,
        region: 'Asia',
        code: 'Asia/Kuala_Lumpur',
        offsetMinutes: 410,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'MYT',
        label: 'Kuala Lumpur',
        countryCode: 410,
      },
      {
        timeZoneId: 411,
        region: 'Asia',
        code: 'Asia/Kuching',
        offsetMinutes: 411,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'MYT',
        label: 'Kuching',
        countryCode: 411,
      },
    ],
  },
  {
    label: 'Mozambique',
    id: 'MZ',
    timeZones: [
      {
        timeZoneId: 287,
        region: 'Africa',
        code: 'Africa/Maputo',
        offsetMinutes: 287,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'CAT',
        label: 'Maputo',
        countryCode: 287,
      },
    ],
  },
  {
    label: 'Namibia',
    id: 'NA',
    timeZones: [
      {
        timeZoneId: 245,
        region: 'Africa',
        code: 'Africa/Windhoek',
        offsetMinutes: 245,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Windhoek',
        countryCode: 245,
      },
    ],
  },
  {
    label: 'New Caledonia',
    id: 'NC',
    timeZones: [
      {
        timeZoneId: 470,
        region: 'Pacific',
        code: 'Pacific/Noumea',
        offsetMinutes: 470,
        offsetHoursFormatted: '+11:00',
        abbreviation: 'NCT',
        label: 'Noumea',
        countryCode: 470,
      },
    ],
  },
  {
    label: 'Niger',
    id: 'NE',
    timeZones: [
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'Norfolk Island',
    id: 'NF',
    timeZones: [
      {
        timeZoneId: 473,
        region: 'Pacific',
        code: 'Pacific/Norfolk',
        offsetMinutes: 473,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'NFT',
        label: 'Norfolk',
        countryCode: 473,
      },
    ],
  },
  {
    label: 'Nigeria',
    id: 'NG',
    timeZones: [
      {
        timeZoneId: 237,
        region: 'Africa',
        code: 'Africa/Lagos',
        offsetMinutes: 237,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Lagos',
        countryCode: 237,
      },
    ],
  },
  {
    label: 'Nicaragua',
    id: 'NI',
    timeZones: [
      {
        timeZoneId: 60,
        region: 'America',
        code: 'America/Managua',
        offsetMinutes: 60,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CST',
        label: 'Managua',
        countryCode: 60,
      },
    ],
  },
  {
    label: 'Netherlands',
    id: 'NL',
    timeZones: [
      {
        timeZoneId: 248,
        region: 'Europe',
        code: 'Europe/Amsterdam',
        offsetMinutes: 248,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Amsterdam',
        countryCode: 248,
      },
    ],
  },
  {
    label: 'Norway',
    id: 'NO',
    timeZones: [
      {
        timeZoneId: 262,
        region: 'Europe',
        code: 'Europe/Oslo',
        offsetMinutes: 262,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Oslo',
        countryCode: 262,
      },
    ],
  },
  {
    label: 'Nepal',
    id: 'NP',
    timeZones: [
      {
        timeZoneId: 372,
        region: 'Asia',
        code: 'Asia/Kathmandu',
        offsetMinutes: 372,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'NPT',
        label: 'Kathmandu',
        countryCode: 372,
      },
    ],
  },
  {
    label: 'Nauru',
    id: 'NR',
    timeZones: [
      {
        timeZoneId: 483,
        region: 'Pacific',
        code: 'Pacific/Nauru',
        offsetMinutes: 483,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'NRT',
        label: 'Nauru',
        countryCode: 483,
      },
    ],
  },
  {
    label: 'Niue',
    id: 'NU',
    timeZones: [
      {
        timeZoneId: 13,
        region: 'Pacific',
        code: 'Pacific/Niue',
        offsetMinutes: 13,
        offsetHoursFormatted: '-11:00',
        abbreviation: 'NUT',
        label: 'Niue',
        countryCode: 13,
      },
    ],
  },
  {
    label: 'New Zealand',
    id: 'NZ',
    timeZones: [
      {
        timeZoneId: 478,
        region: 'Pacific',
        code: 'Pacific/Auckland',
        offsetMinutes: 478,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'NZST',
        label: 'Auckland',
        countryCode: 478,
      },
      {
        timeZoneId: 487,
        region: 'Pacific',
        code: 'Pacific/Chatham',
        offsetMinutes: 487,
        offsetHoursFormatted: '+13:00',
        abbreviation: 'CHAST',
        label: 'Chatham',
        countryCode: 487,
      },
    ],
  },
  {
    label: 'Oman',
    id: 'OM',
    timeZones: [
      {
        timeZoneId: 347,
        region: 'Asia',
        code: 'Asia/Dubai',
        offsetMinutes: 347,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'GST',
        label: 'Dubai',
        countryCode: 347,
      },
    ],
  },
  {
    label: 'Panama',
    id: 'PA',
    timeZones: [
      {
        timeZoneId: 107,
        region: 'America',
        code: 'America/Panama',
        offsetMinutes: 107,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EST',
        label: 'Panama',
        countryCode: 107,
      },
    ],
  },
  {
    label: 'Peru',
    id: 'PE',
    timeZones: [
      {
        timeZoneId: 102,
        region: 'America',
        code: 'America/Lima',
        offsetMinutes: 102,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'PET',
        label: 'Lima',
        countryCode: 102,
      },
    ],
  },
  {
    label: 'French Polynesia',
    id: 'PF',
    timeZones: [
      {
        timeZoneId: 20,
        region: 'Pacific',
        code: 'Pacific/Tahiti',
        offsetMinutes: 20,
        offsetHoursFormatted: '-10:00',
        abbreviation: 'TAHT',
        label: 'Tahiti',
        countryCode: 20,
      },
      {
        timeZoneId: 21,
        region: 'Pacific',
        code: 'Pacific/Marquesas',
        offsetMinutes: 21,
        offsetHoursFormatted: '-010:00',
        abbreviation: 'MART',
        label: 'Marquesas',
        countryCode: 21,
      },
      {
        timeZoneId: 26,
        region: 'Pacific',
        code: 'Pacific/Gambier',
        offsetMinutes: 26,
        offsetHoursFormatted: '-09:00',
        abbreviation: 'GAMT',
        label: 'Gambier',
        countryCode: 26,
      },
    ],
  },
  {
    label: 'Papua New Guinea',
    id: 'PG',
    timeZones: [
      {
        timeZoneId: 458,
        region: 'Pacific',
        code: 'Pacific/Port_Moresby',
        offsetMinutes: 458,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'PGT',
        label: 'Port Moresby',
        countryCode: 458,
      },
      {
        timeZoneId: 466,
        region: 'Pacific',
        code: 'Pacific/Bougainville',
        offsetMinutes: 466,
        offsetHoursFormatted: '+11:00',
        abbreviation: 'BST',
        label: 'Bougainville',
        countryCode: 466,
      },
    ],
  },
  {
    label: 'Philippines',
    id: 'PH',
    timeZones: [
      {
        timeZoneId: 415,
        region: 'Asia',
        code: 'Asia/Manila',
        offsetMinutes: 415,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'PHT',
        label: 'Manila',
        countryCode: 415,
      },
    ],
  },
  {
    label: 'Pakistan',
    id: 'PK',
    timeZones: [
      {
        timeZoneId: 362,
        region: 'Asia',
        code: 'Asia/Karachi',
        offsetMinutes: 362,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'PKT',
        label: 'Karachi',
        countryCode: 362,
      },
    ],
  },
  {
    label: 'Poland',
    id: 'PL',
    timeZones: [
      {
        timeZoneId: 275,
        region: 'Europe',
        code: 'Europe/Warsaw',
        offsetMinutes: 275,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Warsaw',
        countryCode: 275,
      },
    ],
  },
  {
    label: 'St Pierre & Miquelon',
    id: 'PM',
    timeZones: [
      {
        timeZoneId: 184,
        region: 'America',
        code: 'America/Miquelon',
        offsetMinutes: 184,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'PMDT',
        label: 'Miquelon',
        countryCode: 184,
      },
    ],
  },
  {
    label: 'Pitcairn',
    id: 'PN',
    timeZones: [
      {
        timeZoneId: 37,
        region: 'Pacific',
        code: 'Pacific/Pitcairn',
        offsetMinutes: 37,
        offsetHoursFormatted: '-08:00',
        abbreviation: 'PST',
        label: 'Pitcairn',
        countryCode: 37,
      },
    ],
  },
  {
    label: 'Puerto Rico',
    id: 'PR',
    timeZones: [
      {
        timeZoneId: 144,
        region: 'Atlantic',
        code: 'America/Puerto_Rico',
        offsetMinutes: 144,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Puerto Rico',
        countryCode: 144,
      },
    ],
  },
  {
    label: 'Palestine',
    id: 'PS',
    timeZones: [
      {
        timeZoneId: 294,
        region: 'Asia',
        code: 'Asia/Gaza',
        offsetMinutes: 294,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Gaza',
        countryCode: 294,
      },
      {
        timeZoneId: 295,
        region: 'Asia',
        code: 'Asia/Hebron',
        offsetMinutes: 295,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Hebron',
        countryCode: 295,
      },
    ],
  },
  {
    label: 'Portugal',
    id: 'PT',
    timeZones: [
      {
        timeZoneId: 229,
        region: 'Europe',
        code: 'Europe/Lisbon',
        offsetMinutes: 229,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'WEST',
        label: 'Lisbon',
        countryCode: 229,
      },
      {
        timeZoneId: 221,
        region: 'Atlantic',
        code: 'Atlantic/Madeira',
        offsetMinutes: 221,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'WEST',
        label: 'Madeira',
        countryCode: 221,
      },
      {
        timeZoneId: 198,
        region: 'Atlantic',
        code: 'Atlantic/Azores',
        offsetMinutes: 198,
        offsetHoursFormatted: '-01:00',
        abbreviation: 'AZOST',
        label: 'Azores',
        countryCode: 198,
      },
    ],
  },
  {
    label: 'Palau',
    id: 'PW',
    timeZones: [
      {
        timeZoneId: 432,
        region: 'Pacific',
        code: 'Pacific/Palau',
        offsetMinutes: 432,
        offsetHoursFormatted: '+09:00',
        abbreviation: 'PWT',
        label: 'Palau',
        countryCode: 432,
      },
    ],
  },
  {
    label: 'Paraguay',
    id: 'PY',
    timeZones: [
      {
        timeZoneId: 120,
        region: 'America',
        code: 'America/Asuncion',
        offsetMinutes: 120,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'PYT',
        label: 'Asuncion',
        countryCode: 120,
      },
    ],
  },
  {
    label: 'Qatar',
    id: 'QA',
    timeZones: [
      {
        timeZoneId: 9,
        region: 'Arabia Standard Time',
        code: 'Asia/Qatar',
        offsetMinutes: 9,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'AST',
        label: 'Qatar',
        countryCode: 9,
      },
    ],
  },
  {
    label: 'Réunion',
    id: 'RE',
    timeZones: [
      {
        timeZoneId: 354,
        region: 'Indian',
        code: 'Indian/Reunion',
        offsetMinutes: 354,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'RET',
        label: 'Reunion',
        countryCode: 354,
      },
    ],
  },
  {
    label: 'Romania',
    id: 'RO',
    timeZones: [
      {
        timeZoneId: 301,
        region: 'Europe',
        code: 'Europe/Bucharest',
        offsetMinutes: 301,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Bucharest',
        countryCode: 301,
      },
    ],
  },
  {
    label: 'Serbia',
    id: 'RS',
    timeZones: [
      {
        timeZoneId: 250,
        region: 'Europe',
        code: 'Europe/Belgrade',
        offsetMinutes: 250,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Belgrade',
        countryCode: 250,
      },
    ],
  },
  {
    label: 'Russia',
    id: 'RU',
    timeZones: [
      {
        timeZoneId: 305,
        region: 'Europe',
        code: 'Europe/Kaliningrad',
        offsetMinutes: 305,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EET',
        label: 'Kaliningrad',
        countryCode: 305,
      },
      {
        timeZoneId: 333,
        region: 'Europe',
        code: 'Europe/Moscow',
        offsetMinutes: 333,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'MSK',
        label: 'Moscow',
        countryCode: 333,
      },
      {
        timeZoneId: 334,
        region: 'Europe',
        code: 'Europe/Simferopol',
        offsetMinutes: 334,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'MSK',
        label: 'Simferopol',
        countryCode: 334,
      },
      {
        timeZoneId: 335,
        region: 'Europe',
        code: 'Europe/Volgograd',
        offsetMinutes: 335,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'MSK',
        label: 'Volgograd',
        countryCode: 335,
      },
      {
        timeZoneId: 351,
        region: 'Europe',
        code: 'Europe/Samara',
        offsetMinutes: 351,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'SAMT',
        label: 'Samara',
        countryCode: 351,
      },
      {
        timeZoneId: 366,
        region: 'Asia',
        code: 'Asia/Yekaterinburg',
        offsetMinutes: 366,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'YEKT',
        label: 'Yekaterinburg',
        countryCode: 366,
      },
      {
        timeZoneId: 381,
        region: 'Asia',
        code: 'Asia/Omsk',
        offsetMinutes: 381,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'OMST',
        label: 'Omsk',
        countryCode: 381,
      },
      {
        timeZoneId: 380,
        region: 'Asia',
        code: 'Asia/Novosibirsk',
        offsetMinutes: 380,
        offsetHoursFormatted: '+06:00',
        abbreviation: 'NOVT',
        label: 'Novosibirsk',
        countryCode: 380,
      },
      {
        timeZoneId: 395,
        region: 'Asia',
        code: 'Asia/Novokuznetsk',
        offsetMinutes: 395,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'KRAT',
        label: 'Novokuznetsk',
        countryCode: 395,
      },
      {
        timeZoneId: 394,
        region: 'Asia',
        code: 'Asia/Krasnoyarsk',
        offsetMinutes: 394,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'KRAT',
        label: 'Krasnoyarsk',
        countryCode: 394,
      },
      {
        timeZoneId: 409,
        region: 'Asia',
        code: 'Asia/Irkutsk',
        offsetMinutes: 409,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'IRKT',
        label: 'Irkutsk',
        countryCode: 409,
      },
      {
        timeZoneId: 403,
        region: 'Asia',
        code: 'Asia/Chita',
        offsetMinutes: 403,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'YAKT',
        label: 'Chita',
        countryCode: 403,
      },
      {
        timeZoneId: 431,
        region: 'Asia',
        code: 'Asia/Yakutsk',
        offsetMinutes: 431,
        offsetHoursFormatted: '+09:00',
        abbreviation: 'YAKT',
        label: 'Yakutsk',
        countryCode: 431,
      },
      {
        timeZoneId: 427,
        region: 'Asia',
        code: 'Asia/Khandyga',
        offsetMinutes: 427,
        offsetHoursFormatted: '+09:00',
        abbreviation: 'YAKT',
        label: 'Khandyga',
        countryCode: 427,
      },
      {
        timeZoneId: 443,
        region: 'Asia',
        code: 'Asia/Vladivostok',
        offsetMinutes: 443,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'VLAT',
        label: 'Vladivostok',
        countryCode: 443,
      },
      {
        timeZoneId: 442,
        region: 'Asia',
        code: 'Asia/Ust-Nera',
        offsetMinutes: 442,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'VLAT',
        label: 'Ust-Nera',
        countryCode: 442,
      },
      {
        timeZoneId: 440,
        region: 'Asia',
        code: 'Asia/Magadan',
        offsetMinutes: 440,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'MAGT',
        label: 'Magadan',
        countryCode: 440,
      },
      {
        timeZoneId: 441,
        region: 'Asia',
        code: 'Asia/Sakhalin',
        offsetMinutes: 441,
        offsetHoursFormatted: '+10:00',
        abbreviation: 'SAKT',
        label: 'Sakhalin',
        countryCode: 441,
      },
      {
        timeZoneId: 465,
        region: 'Asia',
        code: 'Asia/Srednekolymsk',
        offsetMinutes: 465,
        offsetHoursFormatted: '+11:00',
        abbreviation: 'SRET',
        label: 'Srednekolymsk',
        countryCode: 465,
      },
      {
        timeZoneId: 477,
        region: 'Asia',
        code: 'Asia/Kamchatka',
        offsetMinutes: 477,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'PETT',
        label: 'Kamchatka',
        countryCode: 477,
      },
      {
        timeZoneId: 476,
        region: 'Asia',
        code: 'Asia/Anadyr',
        offsetMinutes: 476,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'ANAT',
        label: 'Anadyr',
        countryCode: 476,
      },
    ],
  },
  {
    label: 'Rwanda',
    id: 'RW',
    timeZones: [
      {
        timeZoneId: 287,
        region: 'Africa',
        code: 'Africa/Maputo',
        offsetMinutes: 287,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'CAT',
        label: 'Maputo',
        countryCode: 287,
      },
    ],
  },
  {
    label: 'Saudi Arabia',
    id: 'SA',
    timeZones: [
      {
        timeZoneId: 331,
        region: 'Asia',
        code: 'Asia/Riyadh',
        offsetMinutes: 331,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'AST',
        label: 'Riyadh',
        countryCode: 331,
      },
    ],
  },
  {
    label: 'Solomon Islands',
    id: 'SB',
    timeZones: [
      {
        timeZoneId: 468,
        region: 'Pacific',
        code: 'Pacific/Guadalcanal',
        offsetMinutes: 468,
        offsetHoursFormatted: '+11:00',
        abbreviation: 'SBT',
        label: 'Guadalcanal',
        countryCode: 468,
      },
    ],
  },
  {
    label: 'Seychelles',
    id: 'SC',
    timeZones: [
      {
        timeZoneId: 352,
        region: 'Indian',
        code: 'Indian/Mahe',
        offsetMinutes: 352,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'SCT',
        label: 'Mahe',
        countryCode: 352,
      },
    ],
  },
  {
    label: 'Sudan',
    id: 'SD',
    timeZones: [
      {
        timeZoneId: 323,
        region: 'Africa',
        code: 'Africa/Khartoum',
        offsetMinutes: 323,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Khartoum',
        countryCode: 323,
      },
    ],
  },
  {
    label: 'Sweden',
    id: 'SE',
    timeZones: [
      {
        timeZoneId: 270,
        region: 'Europe',
        code: 'Europe/Stockholm',
        offsetMinutes: 270,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Stockholm',
        countryCode: 270,
      },
    ],
  },
  {
    label: 'Singapore',
    id: 'SG',
    timeZones: [
      {
        timeZoneId: 417,
        region: 'Asia',
        code: 'Asia/Singapore',
        offsetMinutes: 417,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'SGT',
        label: 'Singapore',
        countryCode: 417,
      },
    ],
  },
  {
    label: 'St Helena',
    id: 'SH',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'Slovenia',
    id: 'SI',
    timeZones: [
      {
        timeZoneId: 250,
        region: 'Europe',
        code: 'Europe/Belgrade',
        offsetMinutes: 250,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Belgrade',
        countryCode: 250,
      },
    ],
  },
  {
    label: 'Svalbard & Jan Mayen',
    id: 'SJ',
    timeZones: [
      {
        timeZoneId: 262,
        region: 'Europe',
        code: 'Europe/Oslo',
        offsetMinutes: 262,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Oslo',
        countryCode: 262,
      },
    ],
  },
  {
    label: 'Slovakia',
    id: 'SK',
    timeZones: [
      {
        timeZoneId: 265,
        region: 'Europe',
        code: 'Europe/Prague',
        offsetMinutes: 265,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Prague',
        countryCode: 265,
      },
    ],
  },
  {
    label: 'Sierra Leone',
    id: 'SL',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'San Marino',
    id: 'SM',
    timeZones: [
      {
        timeZoneId: 266,
        region: 'Europe',
        code: 'Europe/Rome',
        offsetMinutes: 266,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Rome',
        countryCode: 266,
      },
    ],
  },
  {
    label: 'Senegal',
    id: 'SN',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'Somalia',
    id: 'SO',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'Suriname',
    id: 'SR',
    timeZones: [
      {
        timeZoneId: 186,
        region: 'America',
        code: 'America/Paramaribo',
        offsetMinutes: 186,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'SRT',
        label: 'Paramaribo',
        countryCode: 186,
      },
    ],
  },
  {
    label: 'South Sudan',
    id: 'SS',
    timeZones: [
      {
        timeZoneId: 321,
        region: 'Africa',
        code: 'Africa/Juba',
        offsetMinutes: 321,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Juba',
        countryCode: 321,
      },
    ],
  },
  {
    label: 'Sao Tome & Principe',
    id: 'ST',
    timeZones: [
      {
        timeZoneId: 214,
        region: 'Africa',
        code: 'Africa/Sao_Tome',
        offsetMinutes: 214,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Sao Tome',
        countryCode: 214,
      },
    ],
  },
  {
    label: 'El Salvador',
    id: 'SV',
    timeZones: [
      {
        timeZoneId: 55,
        region: 'America',
        code: 'America/El_Salvador',
        offsetMinutes: 55,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CST',
        label: 'El Salvador',
        countryCode: 55,
      },
    ],
  },
  {
    label: 'St Maarten (Dutch)',
    id: 'SX',
    timeZones: [
      {
        timeZoneId: 126,
        region: 'America',
        code: 'America/Curacao',
        offsetMinutes: 126,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Curacao',
        countryCode: 126,
      },
    ],
  },
  {
    label: 'Syria',
    id: 'SY',
    timeZones: [
      {
        timeZoneId: 293,
        region: 'Asia',
        code: 'Asia/Damascus',
        offsetMinutes: 293,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Damascus',
        countryCode: 293,
      },
    ],
  },
  {
    label: 'Swaziland',
    id: 'SZ',
    timeZones: [
      {
        timeZoneId: 283,
        region: 'Africa',
        code: 'Africa/Johannesburg',
        offsetMinutes: 283,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'SAST',
        label: 'Johannesburg',
        countryCode: 283,
      },
    ],
  },
  {
    label: 'Turks & Caicos Is',
    id: 'TC',
    timeZones: [
      {
        timeZoneId: 494,
        region: 'America',
        code: 'America/Grand_Turk',
        offsetMinutes: 494,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'AST',
        label: 'Grand Turk',
        countryCode: 494,
      },
    ],
  },
  {
    label: 'Chad',
    id: 'TD',
    timeZones: [
      {
        timeZoneId: 241,
        region: 'Africa',
        code: 'Africa/Ndjamena',
        offsetMinutes: 241,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'WAT',
        label: 'Ndjamena',
        countryCode: 241,
      },
    ],
  },
  {
    label: 'French Southern & Antarctic Lands',
    id: 'TF',
    timeZones: [
      {
        timeZoneId: 354,
        region: 'Indian',
        code: 'Indian/Reunion',
        offsetMinutes: 354,
        offsetHoursFormatted: '+04:00',
        abbreviation: 'RET',
        label: 'Reunion',
        countryCode: 354,
      },
      {
        timeZoneId: 367,
        region: 'Indian',
        code: 'Indian/Kerguelen',
        offsetMinutes: 367,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'TFT',
        label: 'Kerguelen',
        countryCode: 367,
      },
    ],
  },
  {
    label: 'Togo',
    id: 'TG',
    timeZones: [
      {
        timeZoneId: 200,
        region: 'Africa',
        code: 'Africa/Abidjan',
        offsetMinutes: 200,
        offsetHoursFormatted: '+00:00',
        abbreviation: 'GMT',
        label: 'Abidjan',
        countryCode: 200,
      },
    ],
  },
  {
    label: 'Thailand',
    id: 'TH',
    timeZones: [
      {
        timeZoneId: 390,
        region: 'Asia',
        code: 'Asia/Bangkok',
        offsetMinutes: 390,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'ICT',
        label: 'Bangkok',
        countryCode: 390,
      },
    ],
  },
  {
    label: 'Tajikistan',
    id: 'TJ',
    timeZones: [
      {
        timeZoneId: 361,
        region: 'Asia',
        code: 'Asia/Dushanbe',
        offsetMinutes: 361,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'TJT',
        label: 'Dushanbe',
        countryCode: 361,
      },
    ],
  },
  {
    label: 'Tokelau',
    id: 'TK',
    timeZones: [
      {
        timeZoneId: 490,
        region: 'Pacific',
        code: 'Pacific/Fakaofo',
        offsetMinutes: 490,
        offsetHoursFormatted: '+13:00',
        abbreviation: 'TKT',
        label: 'Fakaofo',
        countryCode: 490,
      },
    ],
  },
  {
    label: 'East Timor',
    id: 'TL',
    timeZones: [
      {
        timeZoneId: 425,
        region: 'Asia',
        code: 'Asia/Dili',
        offsetMinutes: 425,
        offsetHoursFormatted: '+09:00',
        abbreviation: 'TLT',
        label: 'Dili',
        countryCode: 425,
      },
    ],
  },
  {
    label: 'Turkmenistan',
    id: 'TM',
    timeZones: [
      {
        timeZoneId: 359,
        region: 'Asia',
        code: 'Asia/Ashgabat',
        offsetMinutes: 359,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'TMT',
        label: 'Ashgabat',
        countryCode: 359,
      },
    ],
  },
  {
    label: 'Tunisia',
    id: 'TN',
    timeZones: [
      {
        timeZoneId: 244,
        region: 'Africa',
        code: 'Africa/Tunis',
        offsetMinutes: 244,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CET',
        label: 'Tunis',
        countryCode: 244,
      },
    ],
  },
  {
    label: 'Tonga',
    id: 'TO',
    timeZones: [
      {
        timeZoneId: 491,
        region: 'Pacific',
        code: 'Pacific/Tongatapu',
        offsetMinutes: 491,
        offsetHoursFormatted: '+13:00',
        abbreviation: 'TOT',
        label: 'Tongatapu',
        countryCode: 491,
      },
    ],
  },
  {
    label: 'Turkey',
    id: 'TR',
    timeZones: [
      {
        timeZoneId: 304,
        region: 'Europe',
        code: 'Europe/Istanbul',
        offsetMinutes: 304,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Istanbul',
        countryCode: 304,
      },
    ],
  },
  {
    label: 'Trinidad & Tobago',
    id: 'TT',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Tuvalu',
    id: 'TV',
    timeZones: [
      {
        timeZoneId: 480,
        region: 'Pacific',
        code: 'Pacific/Funafuti',
        offsetMinutes: 480,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'TVT',
        label: 'Funafuti',
        countryCode: 480,
      },
    ],
  },
  {
    label: 'Taiwan',
    id: 'TW',
    timeZones: [
      {
        timeZoneId: 418,
        region: 'Asia',
        code: 'Asia/Taipei',
        offsetMinutes: 418,
        offsetHoursFormatted: '+08:00',
        abbreviation: 'CST',
        label: 'Taipei',
        countryCode: 418,
      },
    ],
  },
  {
    label: 'Tanzania',
    id: 'TZ',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'Ukraine',
    id: 'UA',
    timeZones: [
      {
        timeZoneId: 306,
        region: 'Europe',
        code: 'Europe/Kiev',
        offsetMinutes: 306,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Kiev',
        countryCode: 306,
      },
      {
        timeZoneId: 313,
        region: 'Europe',
        code: 'Europe/Uzhgorod',
        offsetMinutes: 313,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Uzhgorod',
        countryCode: 313,
      },
      {
        timeZoneId: 315,
        region: 'Europe',
        code: 'Europe/Zaporozhye',
        offsetMinutes: 315,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'EEST',
        label: 'Zaporozhye',
        countryCode: 315,
      },
    ],
  },
  {
    label: 'Uganda',
    id: 'UG',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'US minor outlying islands',
    id: 'UM',
    timeZones: [
      {
        timeZoneId: 14,
        region: 'Pacific',
        code: 'Pacific/Pago_Pago',
        offsetMinutes: 14,
        offsetHoursFormatted: '-11:00',
        abbreviation: 'SST',
        label: 'Pago Pago',
        countryCode: 14,
      },
      {
        timeZoneId: 485,
        region: 'Pacific',
        code: 'Pacific/Wake',
        offsetMinutes: 485,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'WAKT',
        label: 'Wake',
        countryCode: 485,
      },
      {
        timeZoneId: 8,
        region: 'Hawaii',
        code: 'Pacific/Honolulu',
        offsetMinutes: 8,
        offsetHoursFormatted: '-10:00',
        abbreviation: 'HAT',
        label: 'Hawaii',
        countryCode: 8,
      },
    ],
  },
  {
    label: 'United States',
    id: 'US',
    timeZones: [
      {
        timeZoneId: 1,
        region: 'Eastern',
        code: 'America/New_York',
        offsetMinutes: 1,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'ET',
        label: 'Eastern',
        countryCode: 1,
      },
      {
        timeZoneId: 86,
        region: 'America',
        code: 'America/Detroit',
        offsetMinutes: 86,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Detroit',
        countryCode: 86,
      },
      {
        timeZoneId: 100,
        region: 'America',
        code: 'America/Kentucky/Louisville',
        offsetMinutes: 100,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Louisville',
        countryCode: 100,
      },
      {
        timeZoneId: 101,
        region: 'America',
        code: 'America/Kentucky/Monticello',
        offsetMinutes: 101,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Monticello',
        countryCode: 101,
      },
      {
        timeZoneId: 91,
        region: 'America',
        code: 'America/Indiana/Indianapolis',
        offsetMinutes: 91,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Indianapolis',
        countryCode: 91,
      },
      {
        timeZoneId: 95,
        region: 'America',
        code: 'America/Indiana/Vincennes',
        offsetMinutes: 95,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Vincennes',
        countryCode: 95,
      },
      {
        timeZoneId: 96,
        region: 'America',
        code: 'America/Indiana/Winamac',
        offsetMinutes: 96,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Winamac',
        countryCode: 96,
      },
      {
        timeZoneId: 92,
        region: 'America',
        code: 'America/Indiana/Marengo',
        offsetMinutes: 92,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Marengo',
        countryCode: 92,
      },
      {
        timeZoneId: 93,
        region: 'America',
        code: 'America/Indiana/Petersburg',
        offsetMinutes: 93,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Petersburg',
        countryCode: 93,
      },
      {
        timeZoneId: 94,
        region: 'America',
        code: 'America/Indiana/Vevay',
        offsetMinutes: 94,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'EDT',
        label: 'Vevay',
        countryCode: 94,
      },
      {
        timeZoneId: 2,
        region: 'Central',
        code: 'America/Chicago',
        offsetMinutes: 2,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CT',
        label: 'Central',
        countryCode: 2,
      },
      {
        timeZoneId: 58,
        region: 'America',
        code: 'America/Indiana/Tell_City',
        offsetMinutes: 58,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Tell City',
        countryCode: 58,
      },
      {
        timeZoneId: 57,
        region: 'America',
        code: 'America/Indiana/Knox',
        offsetMinutes: 57,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Knox',
        countryCode: 57,
      },
      {
        timeZoneId: 62,
        region: 'America',
        code: 'America/Menominee',
        offsetMinutes: 62,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Menominee',
        countryCode: 62,
      },
      {
        timeZoneId: 67,
        region: 'America',
        code: 'America/North_Dakota/Center',
        offsetMinutes: 67,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Center',
        countryCode: 67,
      },
      {
        timeZoneId: 68,
        region: 'America',
        code: 'America/North_Dakota/New_Salem',
        offsetMinutes: 68,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'New Salem',
        countryCode: 68,
      },
      {
        timeZoneId: 66,
        region: 'America',
        code: 'America/North_Dakota/Beulah',
        offsetMinutes: 66,
        offsetHoursFormatted: '-06:00',
        abbreviation: 'CDT',
        label: 'Beulah',
        countryCode: 66,
      },
      {
        timeZoneId: 4,
        region: 'Mountain',
        code: 'America/Denver',
        offsetMinutes: 4,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MT',
        label: 'Mountain',
        countryCode: 4,
      },
      {
        timeZoneId: 38,
        region: 'America',
        code: 'America/Boise',
        offsetMinutes: 38,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MDT',
        label: 'Boise',
        countryCode: 38,
      },
      {
        timeZoneId: 5,
        region: 'Mountain - Arizona',
        code: 'America/Phoenix',
        offsetMinutes: 5,
        offsetHoursFormatted: '-07:00',
        abbreviation: 'MT-AZ',
        label: 'Mountain - Arizona',
        countryCode: 5,
      },
      {
        timeZoneId: 3,
        region: 'Pacific',
        code: 'America/Los_Angeles',
        offsetMinutes: 3,
        offsetHoursFormatted: '-08:00',
        abbreviation: 'PT',
        label: 'Pacific',
        countryCode: 3,
      },
      {
        timeZoneId: 6,
        region: 'Alaska',
        code: 'America/Anchorage',
        offsetMinutes: 6,
        offsetHoursFormatted: '-09:00',
        abbreviation: 'AKT',
        label: 'Alaska',
        countryCode: 6,
      },
      {
        timeZoneId: 22,
        region: 'America',
        code: 'America/Juneau',
        offsetMinutes: 22,
        offsetHoursFormatted: '-09:00',
        abbreviation: 'AKDT',
        label: 'Juneau',
        countryCode: 22,
      },
      {
        timeZoneId: 24,
        region: 'America',
        code: 'America/Sitka',
        offsetMinutes: 24,
        offsetHoursFormatted: '-09:00',
        abbreviation: 'AKDT',
        label: 'Sitka',
        countryCode: 24,
      },
      {
        timeZoneId: 29,
        region: 'America',
        code: 'America/Metlakatla',
        offsetMinutes: 29,
        offsetHoursFormatted: '-08:00',
        abbreviation: 'AKDT',
        label: 'Metlakatla',
        countryCode: 29,
      },
      {
        timeZoneId: 25,
        region: 'America',
        code: 'America/Yakutat',
        offsetMinutes: 25,
        offsetHoursFormatted: '-09:00',
        abbreviation: 'AKDT',
        label: 'Yakutat',
        countryCode: 25,
      },
      {
        timeZoneId: 23,
        region: 'America',
        code: 'America/Nome',
        offsetMinutes: 23,
        offsetHoursFormatted: '-09:00',
        abbreviation: 'AKDT',
        label: 'Nome',
        countryCode: 23,
      },
      {
        timeZoneId: 16,
        region: 'America',
        code: 'America/Adak',
        offsetMinutes: 16,
        offsetHoursFormatted: '-10:00',
        abbreviation: 'HDT',
        label: 'Adak',
        countryCode: 16,
      },
      {
        timeZoneId: 8,
        region: 'Hawaii',
        code: 'Pacific/Honolulu',
        offsetMinutes: 8,
        offsetHoursFormatted: '-10:00',
        abbreviation: 'HAT',
        label: 'Hawaii',
        countryCode: 8,
      },
    ],
  },
  {
    label: 'Uruguay',
    id: 'UY',
    timeZones: [
      {
        timeZoneId: 185,
        region: 'America',
        code: 'America/Montevideo',
        offsetMinutes: 185,
        offsetHoursFormatted: '-03:00',
        abbreviation: 'UYT',
        label: 'Montevideo',
        countryCode: 185,
      },
    ],
  },
  {
    label: 'Uzbekistan',
    id: 'UZ',
    timeZones: [
      {
        timeZoneId: 364,
        region: 'Asia',
        code: 'Asia/Samarkand',
        offsetMinutes: 364,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'UZT',
        label: 'Samarkand',
        countryCode: 364,
      },
      {
        timeZoneId: 365,
        region: 'Asia',
        code: 'Asia/Tashkent',
        offsetMinutes: 365,
        offsetHoursFormatted: '+05:00',
        abbreviation: 'UZT',
        label: 'Tashkent',
        countryCode: 365,
      },
    ],
  },
  {
    label: 'Vatican City',
    id: 'VA',
    timeZones: [
      {
        timeZoneId: 266,
        region: 'Europe',
        code: 'Europe/Rome',
        offsetMinutes: 266,
        offsetHoursFormatted: '+01:00',
        abbreviation: 'CEST',
        label: 'Rome',
        countryCode: 266,
      },
    ],
  },
  {
    label: 'St Vincent',
    id: 'VC',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Venezuela',
    id: 'VE',
    timeZones: [
      {
        timeZoneId: 116,
        region: 'America',
        code: 'America/Caracas',
        offsetMinutes: 116,
        offsetHoursFormatted: '-05:00',
        abbreviation: 'VET',
        label: 'Caracas',
        countryCode: 116,
      },
    ],
  },
  {
    label: 'Virgin Islands (UK)',
    id: 'VG',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Virgin Islands (US)',
    id: 'VI',
    timeZones: [
      {
        timeZoneId: 142,
        region: 'America',
        code: 'America/Port_of_Spain',
        offsetMinutes: 142,
        offsetHoursFormatted: '-04:00',
        abbreviation: 'AST',
        label: 'Port of Spain',
        countryCode: 142,
      },
    ],
  },
  {
    label: 'Vietnam',
    id: 'VN',
    timeZones: [
      {
        timeZoneId: 390,
        region: 'Asia',
        code: 'Asia/Bangkok',
        offsetMinutes: 390,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'ICT',
        label: 'Bangkok',
        countryCode: 390,
      },
      {
        timeZoneId: 391,
        region: 'Asia',
        code: 'Asia/Ho_Chi_Minh',
        offsetMinutes: 391,
        offsetHoursFormatted: '+07:00',
        abbreviation: 'ICT',
        label: 'Ho Chi Minh',
        countryCode: 391,
      },
    ],
  },
  {
    label: 'Vanuatu',
    id: 'VU',
    timeZones: [
      {
        timeZoneId: 467,
        region: 'Pacific',
        code: 'Pacific/Efate',
        offsetMinutes: 467,
        offsetHoursFormatted: '+11:00',
        abbreviation: 'VUT',
        label: 'Efate',
        countryCode: 467,
      },
    ],
  },
  {
    label: 'Wallis & Futuna',
    id: 'WF',
    timeZones: [
      {
        timeZoneId: 486,
        region: 'Pacific',
        code: 'Pacific/Wallis',
        offsetMinutes: 486,
        offsetHoursFormatted: '+12:00',
        abbreviation: 'WFT',
        label: 'Wallis',
        countryCode: 486,
      },
    ],
  },
  {
    label: 'Samoa (western)',
    id: 'WS',
    timeZones: [
      {
        timeZoneId: 488,
        region: 'Pacific',
        code: 'Pacific/Apia',
        offsetMinutes: 488,
        offsetHoursFormatted: '+13:00',
        abbreviation: 'WSST',
        label: 'Apia',
        countryCode: 488,
      },
    ],
  },
  {
    label: 'Yemen',
    id: 'YE',
    timeZones: [
      {
        timeZoneId: 331,
        region: 'Asia',
        code: 'Asia/Riyadh',
        offsetMinutes: 331,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'AST',
        label: 'Riyadh',
        countryCode: 331,
      },
    ],
  },
  {
    label: 'Mayotte',
    id: 'YT',
    timeZones: [
      {
        timeZoneId: 325,
        region: 'Africa',
        code: 'Africa/Nairobi',
        offsetMinutes: 325,
        offsetHoursFormatted: '+03:00',
        abbreviation: 'EAT',
        label: 'Nairobi',
        countryCode: 325,
      },
    ],
  },
  {
    label: 'South Africa',
    id: 'ZA',
    timeZones: [
      {
        timeZoneId: 283,
        region: 'Africa',
        code: 'Africa/Johannesburg',
        offsetMinutes: 283,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'SAST',
        label: 'Johannesburg',
        countryCode: 283,
      },
    ],
  },
  {
    label: 'Zambia',
    id: 'ZM',
    timeZones: [
      {
        timeZoneId: 287,
        region: 'Africa',
        code: 'Africa/Maputo',
        offsetMinutes: 287,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'CAT',
        label: 'Maputo',
        countryCode: 287,
      },
    ],
  },
  {
    label: 'Zimbabwe',
    id: 'ZW',
    timeZones: [
      {
        timeZoneId: 287,
        region: 'Africa',
        code: 'Africa/Maputo',
        offsetMinutes: 287,
        offsetHoursFormatted: '+02:00',
        abbreviation: 'CAT',
        label: 'Maputo',
        countryCode: 287,
      },
    ],
  },
];

export default timeZoneData;
