import { Row } from 'antd';
import styled from '@emotion/styled';
import { colors } from '@teamworksdev/style';

import {
  TWFlexContainer,
  TWHeading2,
  TWIcon,
  TWTextDefault,
  TWTextSmall,
  TWDataTablePaginated,
} from '@tw/components';
import { theme } from '@tw/components/utils';

export const Header = styled(TWHeading2)({
  paddingLeft: theme.baseUnit,
  paddingRight: theme.baseUnit,
  paddingBottom: theme.baseUnit / 2,
});

export const SubHeader = styled(TWTextDefault)({
  paddingLeft: theme.baseUnit,
  paddingRight: theme.baseUnit,
  color: theme.colors.text,
});

export const HeaderRight = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const FiltersRow = styled(Row)({
  paddingTop: theme.baseUnit * 2,
  paddingLeft: theme.baseUnit,
});

export const NoWrapContainer = styled.span({
  whiteSpace: 'nowrap',
});

export const ErrorWrapper = styled.div({
  marginBottom: theme.baseUnit * 2,
});

export const Container = styled.div({
  padding: `0px ${theme.baseUnit * 2}px ${theme.baseUnit * 2}px`,
  flex: 1,
});

export const NotesIcon = styled(TWIcon)({
  color: theme.colors.yellowIcon,
});

export const MessageLine1 = styled.div({
  marginBottom: theme.baseUnit,
});

export const ActionText = styled.span({
  textAlign: 'left',
  color: `${theme.colors.text} !important`,
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.fontUnit * 3.5,
});

export const ActionTipText = styled.span({
  textAlign: 'left',
  color: `${theme.colors.text} !important`,
  fontSize: theme.fontUnit * 3,
});

export const InvisibleLabel = styled.label({
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: 1,
  height: 1,
  overflow: 'hidden',
});

export const FilterHeaderText = styled(TWTextSmall)({
  color: theme.colors.text,
});

export const ResetFiltersText = styled(TWTextDefault)({
  color: `${theme.colors.link} !important`,
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const FlexContainer = styled.div({
  flex: 1,
});

export const PreviewContainer = styled.div({
  pointerEvents: 'none',
  '.form-group': {
    display: 'flex',
    flexDirection: 'column',
    '.control-label': {
      textAlign: 'left',
      width: 'auto',
    },
  },
});

export const NoDataContainer = styled(TWFlexContainer)({
  paddingTop: '148px',
  paddingBottom: '148px',
});

export const CheckboxTable = styled(TWDataTablePaginated)({
  '.ant-table-middle  .ant-table-content tbody > tr > td:first-of-type': {
    padding: `${theme.baseUnit * 2.5}px ${theme.baseUnit * 2}px`,
  },
});

export const NonCheckboxTable = styled(TWDataTablePaginated)({
  '.ant-table-thead': {
    '.ant-table-cell': {
      padding: `${theme.baseUnit - 2}px ${theme.baseUnit * 2}px !important`,
    },
  },
  '.ant-table-middle  .ant-table-content tbody > tr > td': {
    padding: `${theme.baseUnit * 2.25}px ${theme.baseUnit * 2}px`,
  },
});

export const ErrorIcon = styled(TWIcon)({
  color: theme.colors.secondary,
  backgroundColor: '#F2F4F7',
  padding: '10px',
  borderRadius: '20px',
});

export const ErrorText = styled(TWTextDefault)({
  color: theme.colors.secondary,
  fontWeight: theme.fontWeight.bold,
});

export const QuickformStyleWrapper = styled.div<{ isExternal?: boolean }>(({ isExternal }) => ({
  fontFamily: '"Inter", Helvetica, sans-serif',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: colors.light[100],
  textarea: {
    width: '100%',
  },
  select: {
    marginTop: theme.baseUnit,
  },
  '.field-description': {
    fontFamily: 'unset', // overrides an !important somewhere I think
    overflowWrap: 'anywhere',
  },
  'input + span': {
    paddingLeft: isExternal ? theme.baseUnit : 0,
  },
  '.field-title': {
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.fontUnit * 6,
  },

  '.form-control': {
    width: '100%',
    boxShadow: 'none !important',
  },

  /*
    This just unsets some style overrides for Quickforms from assets/css/external.css,
    so that we don't see a major style change when we move away from coldfusion
  */

  /* Need to override some of styles.css in order to get Bootstrap horizontal form working correctly */
  '.form-group [class^="col-"]': {
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
  '.form-group .control-label': {
    float: 'unset',
    width: 'unset',
    marginRight: 'unset',
    paddingRight: 'unset',
  },
  '.form-horizontal .control-label': {
    textAlign: 'unset',
    marginBottom: 'unset',
    paddingTop: 'unset',
  },

  '@media only screen and (max-width: 1199px)': {
    '.form-group [class^="col-"]': {
      paddingLeft: 'unset',
      paddingRight: 'unset',
    },
    '.form-group .control-label': {
      float: 'unset',
      width: 'unset',
      marginRight: 'unset',
    },
    '.form-horizontal .control-label': {
      textAlign: 'unset',
      marginBottom: 'unset',
      paddingTop: 'unset',
    },
  },
}));

export const QuickformContainer = styled.div({
  width: 600,
});
