/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactEditor } from 'slate-react';

const withLinks = (editor: ReactEditor) => {
  const { isInline } = editor;
  editor.isInline = (element) => ((element as any).type === 'link' ? true : isInline(element));
  return editor;
};

export default withLinks;
