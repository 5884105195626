import { forwardRef } from 'react';

interface TWIconMaterialProps {
  type: string;
  testID?: string;
  title?: string;
  className?: string;
  width?: number | string;
  height?: number | string;
  color?: string;
  style?: React.CSSProperties;
}

const TWIconMaterial = forwardRef<HTMLSpanElement, TWIconMaterialProps>(
  (
    { type = 'question_mark', title, testID, className, width, height, color, style, ...iconProps },
    ref,
  ) => (
    <>
      <span
        ref={ref}
        className={`material-icons-outlined ${className}`}
        data-testid={testID}
        title={title}
        aria-hidden="true"
        style={{
          ...style,
          fontSize: width || height,
          color: color ?? style?.color,
        }}
        {...iconProps}
      >
        {type}
      </span>
    </>
  ),
);

export default TWIconMaterial;
