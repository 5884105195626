import { ApolloProvider } from '@apollo/client';
import { Toaster as ToasterProvider } from '@teamworksdev/react';
import { PropsWithChildren, Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { Fallback, GlobalModalProvider, TWBindToConfigContainer } from '@tw/components';
import { TranslatorProvider, i18nLocaleTranslator } from '@tw/i18n';
import { configureApollo } from '@tw/store/configureApollo';
import configureStore from '@tw/store/configureStore';

import EmotionProvider from './EmotionProvider';

const store = configureStore();
const client = configureApollo();

const AppProviders = ({ children }: PropsWithChildren<{}>) => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <EmotionProvider>
        <TranslatorProvider translator={i18nLocaleTranslator}>
          <TWBindToConfigContainer />
          <DndProvider backend={HTML5Backend}>
            <GlobalModalProvider>
              <Suspense fallback={<Fallback />}>
                <Router>{children}</Router>
              </Suspense>
            </GlobalModalProvider>
          </DndProvider>
          <ToasterProvider />
        </TranslatorProvider>
      </EmotionProvider>
    </ApolloProvider>
  </Provider>
);

export default AppProviders;
