import { Divider } from 'antd';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { noopFn } from '@tw/constants';
import { useSubjectsLazy, useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { Subject, SubjectSortEnum } from '@tw/types';

import { TWButtonIconFlat, TWFlexContainer } from '../../../presentational';
import TWIcon from '../../TWIcon';
import TWInputSelect from '../TWInputSelect';

import { SubjectCreateModal } from './SubjectCreateModal';
import { TWInputSelectSubjectProps } from './TWInputSelectSubject.definitions';
import { AddSubjectButton } from './TWInputSelectSubject.styles';

export const TWInputSelectSubject = ({
  filters = {},
  isMultiSelect,
  onChange = noopFn,
  onUpdate = noopFn,
  placeholder = getTranslation('subjects', 2),
  populateOnMount = false,
  popupContainerId,
  showAdd = false,
  showEdit = false,
  value,
  ...allUnrecognizedProps
}: TWInputSelectSubjectProps) => {
  const {
    currentTeam: { teamId: currentTeamId },
    permissions: { isAcademicSuperuser, teamsCanManageAcademics },
  } = useViewer();

  const canManageAcademics =
    isAcademicSuperuser || teamsCanManageAcademics.includes(+currentTeamId);

  const defaultFilters = { sort: [SubjectSortEnum.label_xlt_asc] };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [subjectToUpdate, setSubjectToUpdate] = useState<Subject | null>(null);

  const { loading, data, fetchSubjects, refetch } = useSubjectsLazy({
    ...defaultFilters,
    ...filters,
  });
  const { subjects } = data || {};

  useEffectOnce(() => {
    if (populateOnMount) {
      // if the parent component has an initialValue, we need to perform a fetch when this component mounts
      // otherwise this will display the selector's value rather than its label
      fetchSubjects();
    }
  });

  const keyExtractor = (subject: Subject) => subject && subject.subjectId;

  const labelExtractor = (subject: Subject) => subject && subject.label;

  const valueExtractor = (subject: Subject) => subject && subject.subjectId;

  const onModalSubmit = (id: string) => {
    refetch?.();
    onChange(id);
    onUpdate();
    setIsModalVisible(false);
    setSubjectToUpdate(null);
  };

  const onModalClose = () => {
    setIsModalVisible(false);
    setSubjectToUpdate(null);
  };

  const onAddClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setIsModalVisible(true);
  };

  return (
    <TWFlexContainer row>
      <TWInputSelect
        onFocus={() => fetchSubjects()}
        allowClear
        filterOption
        onChange={onChange}
        isMultiSelect={isMultiSelect}
        optionFilterProp="title"
        placeholder={placeholder}
        keyExtractor={keyExtractor}
        labelExtractor={labelExtractor}
        valueExtractor={valueExtractor}
        itemList={subjects}
        loading={loading}
        minWidth="200px"
        value={value}
        popupContainerId={popupContainerId}
        dropdownRender={(menu: React.ReactNode) =>
          showAdd && !loading && canManageAcademics ? (
            <div>
              {menu}
              <Divider style={{ margin: '2px' }} />
              <TWFlexContainer>
                <AddSubjectButton
                  aria-label={getTranslation('coursesTab.subjectCreationModal.newSubject')}
                  onClick={onAddClick}
                >
                  {getTranslation('coursesTab.subjectCreationModal.newSubject')}
                </AddSubjectButton>
              </TWFlexContainer>
            </div>
          ) : (
            <>{menu}</>
          )
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...allUnrecognizedProps}
      />
      {showEdit && !isMultiSelect && canManageAcademics && (
        <TWButtonIconFlat
          onClick={() => {
            setSubjectToUpdate(subjects.find((subject) => value === subject.id) ?? null);
            setIsModalVisible(true);
          }}
          disabled={!value}
        >
          <TWIcon type="tw-pencil" />
        </TWButtonIconFlat>
      )}
      {(showAdd || showEdit) && canManageAcademics && (
        <SubjectCreateModal
          containerId={popupContainerId}
          onSubmit={onModalSubmit}
          onClose={onModalClose}
          subject={subjectToUpdate ?? undefined}
          open={isModalVisible}
        />
      )}
    </TWFlexContainer>
  );
};
