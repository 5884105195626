import _ from 'lodash';

// locale data aren't loaded by default. It may cause an error "No default locale has been configured"
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';

import { PolyglotOptions } from 'node-polyglot';
import { TranslationKey, TWPolyglot, TWInterpolationOptions } from '@tw/i18n';

/**
 * Takes the instance of Polyglot, the dictionary lookup key, and an optional object
 * with substrings to interpolate, and returns the localized string.
 *
 * @param {Object} translator - Polyglot instance
 * @param {string} key - The key in the locale dictionary
 * @param {Object=} interpolations - Substrings to interpolate into the locale string
 * @returns {(string|undefined)}
 */

interface TWTranslatorOptions extends PolyglotOptions {
  interpolations?: TWInterpolationOptions;
  returnUndefinedOnMissingKey: boolean;
}

const getI18nString = (
  translator: TWPolyglot,
  key: TranslationKey,
  options = {} as TWTranslatorOptions,
) => {
  const { interpolations = {}, returnUndefinedOnMissingKey = false } = options;
  if (!translator || (returnUndefinedOnMissingKey && !translator.has(key))) {
    return undefined;
  }
  return translator.t(key, interpolations);
};

const arrayToFormalSentence = (
  translator: TWPolyglot,
  array: string[],
  options = {
    useOxfordComma: false,
  },
) => {
  const arrayClone = _.clone(array); // Don't mutate incoming array...

  if (_.includes(['en', 'es'], translator.locale())) {
    let andPart: string;
    if (arrayClone.length > 1) {
      andPart = `${getI18nString(translator, 'and')} ${arrayClone.pop()}`;
      const firstPart = arrayClone.join(', ');

      // Only add oxford comma if requested, and only if after popping we still have more than 1 element in the array
      if (options.useOxfordComma && arrayClone.length > 1) {
        return `${firstPart}, ${andPart}`;
      }
      return `${firstPart} ${andPart}`;
    }
  }
  return arrayClone.join(', ');
};

const arrayToFormalSentenceNew = (translator: TWPolyglot, listToFormat: Array<string>) => {
  if (!listToFormat) {
    return listToFormat;
  }

  const listFormatter = new Intl.ListFormat(translator.locale() || 'en', {
    localeMatcher: 'best fit', // other values: "lookup"
    type: 'conjunction', // "conjunction", "disjunction" or "unit"
    style: 'long', // other values: "short" or "narrow"
  });

  return listFormatter.format(listToFormat);
};

export default {
  getI18nString,
  arrayToFormalSentence,
  arrayToFormalSentenceNew,
};
