import { reduxConfigUtils } from '@tw/util';

import themeConfigStoreConfig from './themeConfigStoreConfig';

const { layerNames, rootSelector } = themeConfigStoreConfig;

// All the actual work to create selectors and chain them together is in reduxConfigUtils.js;
// go there to see what's actually happening here.
const { topmostSelector, generatedSelectors } = reduxConfigUtils.generateSelectorsForConfigLayers(
  layerNames,
  rootSelector,
);

// We're going to do one final round of post-processing (in a selector) once the values
// pop out of the topmostSelector. This is where variables like "$color.brandHighlight" get
// replaced with the final hex value that resides in that spot after overrides are applies.
const dereferencedValuesSelector =
  reduxConfigUtils.dereferencedValuesSelectorFactory(topmostSelector);

export { dereferencedValuesSelector as themeConfigSelector };

// You generally DON'T want these -- they're for debugging and peeking at the internal and
// intermediate values within the layers.
export const {
  darkDefaultAttributesSelector,
  // Secret Settings and superSecretConfigData need access to all the layers'
  // attributes and values as one block
  allLayerAttributesSelector: allThemeConfigLayerAttributesSelector,
  allLayerValuesSelector: allThemeConfigLayerValuesSelector,
} = generatedSelectors;
