import { reduxConfigUtils } from '@tw/util';

import baseDefault from './baseDefault';
import darkScheme from './darkScheme';
import platformScheme from './platformScheme';

import themeConfigStoreConfig from './themeConfigStoreConfig';
import {
  SET_VALUES_IN_THEME_CONFIG_LAYER,
  TOGGLE_DARK_SCHEME,
  SET_CUSTOMER_OVERRIDE,
  CLEAR_CUSTOMER_OVERRIDE,
  APPLY_DEBUG_OVERRIDE,
  CLEAR_DEBUG_OVERRIDE,
} from './themeConfigActions';

// Note: Everything is flat here (instead of nesting/grouping the attributes and values
// separately) because that makes the store manipulations easier -- we'd need 3-4 spread
// operators for each one instead of 1-2.
const initialState = {
  // In priority order, from lowest to highest
  layerNames: themeConfigStoreConfig.layerNames,

  // Available attributes:
  //    isEnabled     {bool}
  //    @TODO: For future growth, if/when we need it:
  //      * something to enable/disable debug logging for the layer,
  //      * tags to allow complex queries from outside (to answer questions like
  //        "did this value come from a layer tagged as 'override'?", for debugging only)
  attributes_baseDefault: { isEnabled: true },
  attributes_platformScheme: { isEnabled: true },
  attributes_darkScheme: { isEnabled: false },
  attributes_customerOverride: { isEnabled: false },
  attributes_debugOverride: { isEnabled: true },

  values_baseDefault: baseDefault,
  values_platformScheme: platformScheme,
  values_darkScheme: darkScheme,
  values_customerOverride: {},
  values_debugOverride: {},
};

reduxConfigUtils.warnIfInitialStateLooksIncomplete(initialState);

export default function themeConfigReducer(state = initialState, action) {
  switch (action.type) {
    /**
     *
     */
    case TOGGLE_DARK_SCHEME:
      return {
        ...state,
        attributes_darkScheme: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_darkScheme,
          {
            isEnabled: !state.attributes_darkScheme.isEnabled,
          },
        ),
      };

    /**
     * This *replaces* any customerOverride themeConfig with a new set of values
     */
    case SET_CUSTOMER_OVERRIDE:
      return {
        ...state,
        values_customerOverride: action.customerOverrideThemeConfig,
        attributes_customerOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_customerOverride,
          {
            isEnabled: true,
          },
        ),
      };

    /**
     * This *disables and wipes* any customerOverride themeConfig
     */
    case CLEAR_CUSTOMER_OVERRIDE:
      return {
        ...state,
        values_customerOverride: {},
        attributes_customerOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_customerOverride,
          {
            isEnabled: false,
          },
        ),
      };

    /**
     * This *merges in* a set of debugOverride values
     */
    case APPLY_DEBUG_OVERRIDE:
      return {
        ...state,
        values_debugOverride: reduxConfigUtils.mergeConfigLayerValues(
          state.values_debugOverride,
          action.debugOverrideThemeConfig,
        ),
      };

    /**
     * This wipes (but does not disable) any debugOverride values
     */
    case CLEAR_DEBUG_OVERRIDE:
      return {
        ...state,
        values_debugOverride: {},
      };

    case SET_VALUES_IN_THEME_CONFIG_LAYER: {
      const { layerName, values } = action;

      if (!themeConfigStoreConfig.layerNames.includes(layerName)) {
        throw new Error(`Invalid layerName for setting themeConfig values: "${layerName}"`);
      }
      const attributeKey = `attributes_${layerName}`;
      const valueKey = `values_${layerName}`;
      return {
        ...state,
        [attributeKey]: reduxConfigUtils.mergeConfigLayerAttributes(state[attributeKey], {
          isEnabled: true,
        }),
        [valueKey]: reduxConfigUtils.mergeConfigLayerValues(state[valueKey], values),
      };
    }

    default:
      return state;
  }
}
