/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentCreateMutationVariables = Types.Exact<{
  input: Types.AppointmentCreateInput;
}>;

export type AppointmentCreateMutation = {
  appointmentCreate: { appointment?: { appointmentId: string } | null };
};

export const AppointmentCreateDocument = gql`
  mutation AppointmentCreate($input: AppointmentCreateInput!) {
    appointmentCreate(input: $input) {
      appointment {
        appointmentId
      }
    }
  }
`;
export type AppointmentCreateMutationFn = Apollo.MutationFunction<
  AppointmentCreateMutation,
  AppointmentCreateMutationVariables
>;

/**
 * __useAppointmentCreateMutation__
 *
 * To run a mutation, you first call `useAppointmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentCreateMutation, { data, loading, error }] = useAppointmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppointmentCreateMutation,
    AppointmentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AppointmentCreateMutation, AppointmentCreateMutationVariables>(
    AppointmentCreateDocument,
    options,
  );
}
export type AppointmentCreateMutationHookResult = ReturnType<typeof useAppointmentCreateMutation>;
export type AppointmentCreateMutationResult = Apollo.MutationResult<AppointmentCreateMutation>;
export type AppointmentCreateMutationOptions = Apollo.BaseMutationOptions<
  AppointmentCreateMutation,
  AppointmentCreateMutationVariables
>;
