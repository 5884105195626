import { useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import PhoneInput from 'react-phone-input-2';

import { getTranslation } from '@tw/i18n';
import {
  TWForm,
  TWMessage,
  TWFormItem,
  TWTextDefault,
  TWSpacingContainer,
  TWFlexContainer,
} from '@tw/components';
import { useSendUsernameReminderMutation } from '@tw/generated';
import { composeFormRules } from '@tw/util';
import { Button } from '@teamworksdev/react';

import { AuthTemplate, ButtonContainer, FooterLink } from '../@components';

type FormValues = {
  email?: string;
  phone?: string;
};

const ForgotUsername = () => {
  const [submitComplete, setSubmitComplete] = useState(false);
  const [sendUsernameReminder, { loading }] = useSendUsernameReminderMutation({
    onCompleted: ({ usernameReminderSend }) => {
      if (usernameReminderSend.ok) {
        setSubmitComplete(true);
      } else {
        TWMessage.completedHandler(usernameReminderSend);
      }
    },
    onError: (error) => TWMessage.errorHandler(error),
  });

  const [form] = Form.useForm<FormValues>();

  const onFormFinish = (values: FormValues) => {
    sendUsernameReminder({ variables: { email: values.email ?? '', phone: values.phone ?? '' } });
  };

  const ForgotUsernameLinks = (
    <FooterLink backLink={getTranslation('navigation.backToSignInOptions')} contactSupport />
  );

  return !submitComplete ? (
    <AuthTemplate
      heading={getTranslation('authentication.forgotUsername')}
      helperText={
        <TWTextDefault>{getTranslation('authentication.forgotUsernameHelper')}</TWTextDefault>
      }
      footerLinks={ForgotUsernameLinks}
    >
      <TWForm form={form} name="ForgotUsernameForm" size="large" onFinish={onFormFinish}>
        <Row>
          <Col span={24}>
            <TWSpacingContainer twMarginTop={3}>
              <TWFormItem
                name="email"
                label={getTranslation('email')}
                rules={composeFormRules('email')}
              >
                <Input
                  type="email"
                  autoFocus
                  placeholder={getTranslation('authentication.enterEmailAddress')}
                  data-testid="Authentication:ForgotUsernamePage:email"
                  aria-label={getTranslation('email')}
                />
              </TWFormItem>
            </TWSpacingContainer>
            <TWSpacingContainer twMargin={[2, 0, 1, 0]}>
              <TWFlexContainer row justify="space-between" align="center">
                <div className="separation-line" />
                <div>{getTranslation('or')}</div>
                <div className="separation-line" />
              </TWFlexContainer>
            </TWSpacingContainer>
            <TWSpacingContainer twMarginBottom={2}>
              <TWFormItem
                name="phone"
                label={getTranslation('phoneNumber')}
                rules={composeFormRules('phone')}
              >
                <PhoneInput
                  inputStyle={{ width: '100%' }}
                  country="us"
                  data-testid="Authentication:ForgotUsernamePage:phone"
                />
              </TWFormItem>
            </TWSpacingContainer>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ButtonContainer>
              <TWFormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const email = getFieldValue('email');
                  const phone = getFieldValue('phone');
                  return (
                    <Button
                      data-testid="Authentication:ForgotUsernamePage:forgotUsernameActionButton"
                      style={{ maxWidth: '100%' }}
                      isLoading={loading}
                      disabled={
                        (isEmpty(email) && isEmpty(phone)) || (!isEmpty(email) && !isEmpty(phone))
                      }
                      type="submit"
                    >
                      {getTranslation('authentication.sendUsernameReminder')}
                    </Button>
                  );
                }}
              </TWFormItem>
            </ButtonContainer>
          </Col>
        </Row>
      </TWForm>
    </AuthTemplate>
  ) : (
    <AuthTemplate
      heading={getTranslation('authentication.userNameSent')}
      helperText={
        <>
          <TWSpacingContainer twMarginBottom={2}>
            {getTranslation('authentication.usernameReminderSentHelper')}
          </TWSpacingContainer>
          <TWSpacingContainer twMarginBottom={2}>
            {getTranslation('authentication.accountRecoveryHelpText')}
            <ul style={{ lineHeight: 2 }}>
              <li>{getTranslation('authentication.accountRecoveryHelpTextSSO')}</li>
              <li>{getTranslation('authentication.accountRecoveryHelpTextUsername')}</li>
            </ul>
          </TWSpacingContainer>
        </>
      }
      footerLinks={ForgotUsernameLinks}
    />
  );
};

export default ForgotUsername;
