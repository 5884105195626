import fastMemoize from 'fast-memoize';
import parsePhoneNumber from 'libphonenumber-js';
import _ from 'lodash';

import { getTranslation } from '@tw/i18n';
import { SelectionType, Team } from '@tw/types';

/**
 * Hopefully this name is self-explanatory.
 */
const isValidEmailAddress = (value: string) =>
  // eslint-disable-next-line max-len
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
    value,
  );

// https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
// this uses the 2nd option which also validates international numbers in many configurations
const isValidPhoneNumber = (value: string) =>
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{2,})(?: *x(\d+))?\s*$/.test(value);

const isValidInternationalPhoneNumber = (value: string) => {
  const startWithPlus = /^\+\d+$/.test(value);
  if (startWithPlus) {
    const phoneNumber = parsePhoneNumber(value);
    return phoneNumber?.isPossible() ?? false;
  }
  return false;
};

const convertTextToInternationalPhoneNumberFormat = (value: string) => {
  const phoneNumber = parsePhoneNumber(value, {
    defaultCountry: 'US',
  });

  return phoneNumber?.number ?? value;
};

const hexRegularExp = /^#*([A-Fa-f0-9]{3,6})$/;

const getHexCode = (colorHex: string) => {
  const matchObj = colorHex && colorHex.match(hexRegularExp);
  // if there's nothing similar to a hexColor we return light gray
  if (!matchObj) {
    return '#f4f4f4';
  }
  // otherwise, we can destructure the regex match and return a hex value based on the input
  let hexValue = matchObj[1];
  if (hexValue.length > 3 && hexValue.length < 6) {
    // dumb hack: repeat the last letter if we're short:
    // This is a terrible thing to do, but sometimes the backend gives us malformed hex code
    // that only have 5 non-# characters. :-(
    hexValue = _.padEnd(hexValue, 6, hexValue[hexValue.length - 1]);
  }
  return `#${hexValue}`;
};

const isValidUrl = (possibleUrl: string) => {
  const matcher = new RegExp(
    '^' +
      // protocol identifier (optional)
      // short syntax // still required
      '(?:(?:(?:https?|ftp):)?\\/\\/)' +
      // user:pass BasicAuth (optional)
      '(?:\\S+(?::\\S*)?@)?' +
      '(?:' +
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      '(?:' +
      '(?:' +
      '[a-z0-9\\u00a1-\\uffff]' +
      '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
      ')?' +
      '[a-z0-9\\u00a1-\\uffff]\\.' +
      ')+' +
      // TLD identifier name, may end with dot
      '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
      ')' +
      // port number (optional)
      '(?::\\d{2,5})?' +
      // resource path (optional)
      '(?:[/?#]\\S*)?' +
      '$',
    'i',
  );
  return _.isString(possibleUrl) && matcher.test(possibleUrl);
};

/**
 * If given a string that looks like a regex, this will turn it into a regex.
 * @TODO: This does not support flags
 *
 * @param {String} stringValue
 * @return {String|RegExp}
 */
const maybeConvertToRegExp = (stringValue: string) => {
  if (stringValue[0] === '/' && stringValue[stringValue.length - 1] === '/') {
    return new RegExp(stringValue.substring(1, stringValue.length - 1));
  }
  return stringValue;
};

/**
 * Converts a set of keys/values into an encoded value usable as a querystring or POST body.
 */
const objectToQueryString = (values: Record<string, string | number | boolean>) =>
  _.map(values, (value, key) => `${key}=${encodeURIComponent(value)}`).join('&');

const cleanupUri = (value: string) => value && value.replace('://://', '://');

const getAddressText = fastMemoize((addressItem) => {
  // if not passed anything, return an empty text
  if (!addressItem) {
    return '';
  }

  // sometimes this is street, sometimes street 1 (former for profile addresses, latter for trip vendors)
  const street1 = addressItem.street1 || addressItem.street;
  return _.compact([
    street1 || '',
    addressItem.street2 || '',
    // We're using lodash's trim here because we want to remove stray commas
    _.trim(`${addressItem.city || ''}, ${addressItem.state || ''} ${addressItem.zip || ''}`, ', '),
    addressItem.country || '',
  ]).join('\n');
});

const toPhrase = (str: string) => _.capitalize(_.replace(str, /[-_]/, ' '));

const replaceNewLineWithBreak = (str: string) => _.replace(str, /\n/g, '<br />');

const sortByKeyFunction = (key: string) => (a: object, b: object) =>
  _.get(a, key, '').trim().localeCompare(_.get(b, key, '').trim(), 'en', {
    sensitivity: 'base',
  });

export const getFullName = ({ preferredName = '', lastName = '' } = {}) =>
  preferredName || lastName
    ? _.trim(`${preferredName} ${lastName}`)
    : `${getTranslation('unknown')}`;

export const getNormalizedFullName = (incomingName: string, handleCommas = true) => {
  let name = incomingName || '';
  if (handleCommas && _.includes(incomingName, ',')) {
    const splitName = _.split(name, ',');
    name = `${_.trim(_.nth(splitName, 1))} ${_.trim(_.nth(splitName, 0))}`;
  }
  return name;
};

export const getInitialsFromFullName = (
  incomingName: string | undefined | null,
  handleCommas = false,
) => {
  if (!incomingName) return '';
  let name = incomingName;
  if (handleCommas && incomingName.includes(',')) {
    const splitName = incomingName.split(',');
    name = `${splitName[1].trim()} ${splitName[0].trim()}`;
  }

  let initials = name[0] ?? '';

  const nameParts = name.split(' ');
  if (nameParts.length > 1) {
    const firstInitial = nameParts[0][0] ?? '';
    const lastInitial = nameParts[nameParts.length - 1][0] ?? '';
    initials = firstInitial + lastInitial;
  }

  return initials;
};

const createSelectionCode = (
  type: SelectionType,
  teamId: string | number,
  selectionId: string | number,
) => `${type}_${teamId}_${selectionId}`;

const generateTeamList = (teamIds: number[], teams: Pick<Team, 'teamId' | 'label'>[]): string => {
  if (_.size(teamIds) === 0 || _.size(teams) === 0) {
    return '';
  }

  return _.reduce(
    teamIds,
    (acc, currTeamId) => {
      const teamLabel = _.find(teams, (team) => Number(team.teamId) === currTeamId)?.label;
      const prefix = acc ? `${acc}, ` : acc;
      return teamLabel ? `${prefix}${teamLabel}` : acc;
    },
    '',
  );
};

const getBytesString = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const copyToClipboard = (textToCopy: string): Promise<unknown> =>
  navigator.clipboard.writeText(textToCopy).catch((err) => {
    // eslint-disable-next-line no-alert
    window.prompt(getTranslation('errorHandler.copyToClipboardFailed'), err);
  });

const formatPhoneNumber = (value: string) => {
  const phoneNumber = parsePhoneNumber(value);
  if (!phoneNumber) return value;

  if (phoneNumber?.isPossible()) {
    if (phoneNumber.countryCallingCode === '1' && phoneNumber.nationalNumber?.match(/^\d+$/)) {
      return `+1 ${phoneNumber.formatNational()}`;
    }
    return phoneNumber.formatInternational();
  }
  return phoneNumber?.format('E.164') ?? value;
};

export default {
  cleanupUri,
  convertTextToInternationalPhoneNumberFormat,
  copyToClipboard,
  createSelectionCode,
  formatPhoneNumber,
  isValidEmailAddress,
  isValidPhoneNumber,
  isValidInternationalPhoneNumber,
  isValidUrl,
  generateTeamList,
  getAddressText,
  getBytesString,
  getHexCode,
  getFullName,
  getInitialsFromFullName,
  getNormalizedFullName,
  maybeConvertToRegExp,
  objectToQueryString,
  replaceNewLineWithBreak,
  sortByKeyFunction,
  toPhrase,
};
