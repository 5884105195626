import { Divider } from 'antd';
import { MouseEventHandler, ReactNode, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { noopFn } from '@tw/constants';
import { useProfessorsLazy, useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { Professor, ProfessorSortEnum } from '@tw/types';

import { TWButtonIconFlat, TWFlexContainer } from '../../../presentational';
import TWIcon from '../../TWIcon';
import TWInputSelect from '../TWInputSelect';
import { ProfessorCreateModal } from './ProfessorCreateModal';
import { TWInputSelectProfessorProps } from './TWInputSelectProfessor.definitions';
import { AddProfessorButton } from './TWInputSelectProfessor.styles';

export const TWInputSelectProfessor = (props: TWInputSelectProfessorProps) => {
  const {
    filters = {},
    isMultiSelect,
    onChange,
    onUpdate = noopFn,
    placeholder = getTranslation('instructor', 2),
    populateOnMount = false,
    popupContainerId,
    showAdd = false,
    showEdit = false,
    value,
    ...allUnrecognizedProps
  } = props;

  const {
    currentTeam: { teamId: currentTeamId },
    permissions: { isAcademicSuperuser, teamsCanManageAcademics },
  } = useViewer();

  const canManageAcademics =
    isAcademicSuperuser || teamsCanManageAcademics.includes(Number(currentTeamId));

  const defaultFilters = {
    // DO NOT pass an isActive filter until COL-1845 is resolved
    // isActive: true,
    sort: [ProfessorSortEnum.last_name_asc, ProfessorSortEnum.first_name_asc],
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [professorToUpdate, setProfessorToUpdate] = useState<Professor | null>(null);

  const { loading, data, fetchProfessors, refetch } = useProfessorsLazy({
    ...defaultFilters,
    ...filters,
  });
  const { professors } = data || {};

  useEffectOnce(() => {
    if (populateOnMount) {
      // if the parent component has an initialValue, we need to perform a fetch when this component mounts
      // otherwise this will display the selector's value rather than its label
      fetchProfessors();
    }
  });

  const keyExtractor = (professor: Professor) => professor && professor.id;

  const labelExtractor = (professor: Professor) => professor && professor.fullName;

  const valueExtractor = (professor: Professor) => professor && professor.id;

  const onModalSubmit = (id: string & string[]) => {
    refetch?.();
    onChange?.(id);
    onUpdate();
    setProfessorToUpdate(null);
    setIsModalVisible(false);
  };

  const onModalClose = () => {
    setProfessorToUpdate(null);
    setIsModalVisible(false);
  };

  const onAddClick: MouseEventHandler = (event) => {
    event.preventDefault();
    setIsModalVisible(true);
  };

  return (
    <TWFlexContainer row>
      <TWInputSelect
        onFocus={() => fetchProfessors()}
        allowClear
        filterOption
        optionFilterProp="title"
        placeholder={placeholder}
        isMultiSelect={isMultiSelect}
        keyExtractor={keyExtractor}
        labelExtractor={labelExtractor}
        valueExtractor={valueExtractor}
        itemList={professors}
        loading={loading}
        minWidth="200px"
        onChange={(profId) => onChange?.(profId)}
        popupContainerId={popupContainerId}
        value={value}
        dropdownRender={(menu: ReactNode) => (
          <>
            {menu}
            {showAdd && !loading && canManageAcademics && (
              <>
                <Divider style={{ margin: '2px' }} />
                <TWFlexContainer>
                  <AddProfessorButton
                    aria-label={getTranslation('coursesTab.instructorCreationModal.newInstructor')}
                    onClick={onAddClick}
                  >
                    {getTranslation('coursesTab.instructorCreationModal.newInstructor')}
                  </AddProfessorButton>
                </TWFlexContainer>
              </>
            )}
          </>
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...allUnrecognizedProps}
      />
      {showEdit && !isMultiSelect && canManageAcademics && (
        <TWButtonIconFlat
          onClick={() => {
            setProfessorToUpdate(professors.find((professor) => value === professor.id) ?? null);
            setIsModalVisible(true);
          }}
          disabled={!value}
        >
          <TWIcon type="tw-pencil" />
        </TWButtonIconFlat>
      )}
      {(showAdd || showEdit) && canManageAcademics && (
        <ProfessorCreateModal
          containerId={popupContainerId}
          professor={professorToUpdate ?? undefined}
          onSubmit={onModalSubmit}
          onClose={onModalClose}
          open={isModalVisible}
        />
      )}
    </TWFlexContainer>
  );
};
