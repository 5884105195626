/* eslint-disable global-require, import/newline-after-import */

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';

export default function configureStore(initialState = {}) {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();

  const allMiddlewares = applyMiddleware(sagaMiddleware);

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle, max-len

  const storeEnhancers = composeEnhancers(allMiddlewares);

  const store = createStore(rootReducer, initialState, storeEnhancers);

  if (module.hot) {
    module.hot.accept(() => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextRootReducer = require('./rootReducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
