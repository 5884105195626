import styled from '@emotion/styled';
import { Button } from 'antd';
import { Editable } from 'slate-react';
import { theme as staticTheme } from '@tw/components/utils';

const commonButtonProps = {
  backgroundColor: staticTheme.colors.white,
  border: 'none',
  cursor: 'pointer',
  height: 40,
  marginLeft: '0 !important',
};

type ColorButtonProps = {
  color: string;
};

export const ColorButton = styled.button<ColorButtonProps>(({ color }) => ({
  ...commonButtonProps,
  color,
  width: 30,
}));

export const DropdownButton = styled(Button)({
  ...commonButtonProps,
  alignItems: 'center',
  display: 'flex',
  fontSize: 14,
  fontWeight: 'bold',
  justifyContent: 'space-between',
  lineHeight: '32px',
  opacity: 1,
  padding: '4px',
});

export const EditableArea = styled(Editable)(({ theme }) => ({
  padding: 10,
  minHeight: '135px!important',
  overflowX: 'hidden',

  'a, a span': {
    color: `${theme.colors.link} !important`,
    textDecoration: 'underline',
  },
}));

export const Group = styled.span({
  display: 'flex',
  margin: '0 3px',

  '&>*': {
    cursor: 'pointer',
    margin: '0',
  },
});

export const RichTextWrapper = styled.div(({ theme }) => ({
  minHeight: 200,
  border: '1px solid',
  borderColor: theme.colors.buttonBorder,
  borderRadius: '4px',
}));

type ToggleButtonProps = {
  active?: boolean;
};

export const ToggleButton = styled.button<ToggleButtonProps>(({ active = false }) => ({
  ...commonButtonProps,
  opacity: active ? 1.0 : 0.5,
  width: 30,

  '&:hover': {
    opacity: active ? 1.0 : 0.7,
  },
}));

export const ToolbarIcon = styled.span<{ size?: number }>(({ size = 20, theme }) => ({
  color: 'inherit',
  fontSize: size,
  padding: theme.baseUnit * 0.25,
  verticalAlign: 'middle',
  width: '100%',
}));

export const ToolbarWrapper = styled.div(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.buttonBorder}`,
  display: 'flex',
  flexWrap: 'wrap',
  padding: 10,
}));
