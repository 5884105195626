import { datadogRum } from '@datadog/browser-rum';
import {
  Dispatch,
  RefForwardingComponent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
} from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';

import { TWButtonIconFlat, TWButton, TWIcon, TWMessage, TWModal } from '@tw/components';
import { getTranslation } from '@tw/i18n';

import {
  CanvasContainer,
  CropperContainer,
  Overlay,
  OverlayContainer,
  OverlayIconButtonWrapper,
  OverlayUploadButtonWrapper,
} from './TWInputImageUpload.styles';

interface TWInputImageUploadValues {
  imageFile: File;
  imageBase64String: string;
}
interface TWInputImageUploadProps {
  alt: string;
  accept?: string;
  accessibilityLabel?: string;
  aspectRatio?: number;
  canBeDeleted?: boolean;
  containerId: string;
  cropShape?: 'rect' | 'round';
  defaultValue?: string;
  height?: string | number;
  imageStyleOverrides?: object;
  showCrop?: boolean;
  style?: object;
  overlayStyleOverrides?: object;
  uploadLabel?: React.ReactNode;
  value?: string | TWInputImageUploadValues;
  width?: string | number;
  wrapperStyleOverrides?: object;
  handleClickDelete?: () => void;
  onChange: (arg0: TWInputImageUploadValues | null) => void | Dispatch<string>;
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

// This function was taken from examples in the ReadMe of https://github.com/ricardo-ch/react-easy-crop
const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number },
) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  const ctx = canvas.getContext('2d');

  if (ctx) {
    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx?.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
    );
  }

  return canvas;
};

const CropModal = ({
  imageFile,
  handleCancel,
  containerId,
  showCropModal,
  aspectRatio,
  cropShape,
  handleOk,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPx) => {
    setCroppedAreaPixels(croppedAreaPx);
  }, []);

  const setCroppedImage = async () => {
    try {
      const canvas = await getCroppedImg(imageFile, croppedAreaPixels);
      handleOk(canvas);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <TWModal
      title={getTranslation('editPicture')}
      onOk={setCroppedImage}
      onCancel={handleCancel}
      containerId={containerId}
      open={showCropModal}
      testID="imageCropModal"
    >
      <CropperContainer>
        <CanvasContainer>
          <Cropper
            image={imageFile}
            crop={crop}
            cropShape={cropShape}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </CanvasContainer>
      </CropperContainer>
    </TWModal>
  );
};

const TWInputImageUpload: RefForwardingComponent<HTMLInputElement, TWInputImageUploadProps> =
  forwardRef(
    (
      {
        alt,
        accept,
        accessibilityLabel = getTranslation('uploadImage'),
        aspectRatio = 1,
        canBeDeleted = true,
        containerId,
        cropShape = 'rect',
        defaultValue,
        height = 200,
        imageStyleOverrides,
        overlayStyleOverrides,
        showCrop = false,
        style,
        uploadLabel = getTranslation('uploadImage'),
        value,
        width = 300,
        wrapperStyleOverrides,
        handleClickDelete,
        onChange,
      },
      ref,
    ) => {
      const fileInputRef = useRef<HTMLInputElement>();
      const [imageSource, setImageSource] = useState<string | undefined>(defaultValue);
      const [overlayVisible, setOverlayVisible] = useState(false);
      const [showCropModal, setShowCropModal] = useState(false);

      useImperativeHandle(ref, () => ({
        clear() {
          setImageSource(undefined);
          onChange(null);
        },
      }));

      useEffect(() => {
        setImageSource(defaultValue);
      }, [defaultValue]);

      useEffect(() => {
        const newValue = value?.imageBase64String || value;

        // When we don't have a newValue, that means we need to explicitly set it to null as a value...
        // This will happen typically when the delete button is clicked
        if (!newValue) {
          setImageSource(defaultValue);
          onChange(null);
        } else {
          setImageSource(newValue);
        }
        // Disabling to prevent an infinite OnChange loop bug from calling
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [value]);

      const handleCancel = () => {
        setShowCropModal(false);
      };

      const handleOkAndCloseModal = (canvas: HTMLCanvasElement) => {
        const croppedB64Image = canvas.toDataURL('image/jpeg');
        canvas.toBlob((blob) => {
          if (blob) {
            const imageFile = new File([blob], 'image.jpeg', { type: 'image/jpeg' });
            const fileReader = new FileReader();
            fileReader.onload = () => {
              onChange({
                imageFile,
                imageBase64String: croppedB64Image,
              });
              setImageSource(croppedB64Image);
              setShowCropModal(false);
            };
            fileReader.readAsDataURL(imageFile);
          }
        });
      };

      const handleFileInputChange = async () => {
        try {
          convertImageFileToBase64();
          setShowCropModal(true);
        } catch (err) {
          TWMessage.error(getTranslation('files.modal.upload.error', { fileName: file.name }));
          datadogRum.addError(err);
        }
      };

      const convertImageFileToBase64 = () => {
        const { current: currentUploaderRef } = fileInputRef;
        // get the (temporary) URL of the file which has been assigned to the 'file-input' element
        const filesSelected = currentUploaderRef?.files;
        if (filesSelected && filesSelected.length > 0) {
          // load the file from the user's local environment
          const imageFile = filesSelected[0] as File;
          const fileReader = new FileReader();
          fileReader.onload = (fileLoadedEvent) => {
            // convert the image to a base64 string and call the onChange handler with the result
            const backgroundImageBase64String = fileLoadedEvent?.target?.result;
            const imageBase64String = backgroundImageBase64String?.toString() ?? '';
            onChange({ imageFile, imageBase64String });
            setImageSource(imageBase64String);
          };
          fileReader.readAsDataURL(imageFile);
          if (currentUploaderRef?.value) {
            currentUploaderRef.value = '';
          }
        }
      };

      const renderUploadButton = () => (
        <TWButton
          accessibilityLabel={accessibilityLabel}
          onClick={() => fileInputRef?.current?.click()}
          testID={`TWInputImageUpload:uploadButton:${imageSource ? 'withImage' : 'empty'}`}
        >
          {uploadLabel}
        </TWButton>
      );

      return (
        <div style={style}>
          <input
            type="file"
            id="file-input"
            ref={fileInputRef}
            accept={accept ?? 'image/*'}
            onChange={() => {
              if (showCrop) {
                handleFileInputChange();
              } else {
                convertImageFileToBase64();
              }
            }}
            style={{
              display: 'none',
            }}
          />
          {imageSource ? ( // This is done so we don't HAVE to have a default image set
            <OverlayContainer
              style={{
                height,
                width,
                ...wrapperStyleOverrides,
              }}
              onMouseEnter={() => setOverlayVisible(true)}
              onMouseLeave={() => setOverlayVisible(false)}
            >
              {overlayVisible && (
                <Overlay style={{ ...overlayStyleOverrides }}>
                  <OverlayUploadButtonWrapper>{renderUploadButton()}</OverlayUploadButtonWrapper>
                  {canBeDeleted && (
                    <OverlayIconButtonWrapper>
                      <TWButtonIconFlat
                        onClick={handleClickDelete}
                        accessibilityLabel={getTranslation('delete')}
                      >
                        <TWIcon type="material-delete" />
                      </TWButtonIconFlat>
                    </OverlayIconButtonWrapper>
                  )}
                </Overlay>
              )}
              <img
                src={imageSource}
                alt={alt}
                width={width}
                height={height}
                style={{ objectFit: 'contain', ...imageStyleOverrides }}
              />
            </OverlayContainer>
          ) : (
            renderUploadButton()
          )}
          <CropModal
            imageFile={imageSource}
            handleOk={handleOkAndCloseModal}
            containerId={containerId}
            handleCancel={handleCancel}
            showCropModal={showCropModal}
            aspectRatio={aspectRatio}
            cropShape={cropShape}
          />
        </div>
      );
    },
  );

export default TWInputImageUpload;
