import _ from 'lodash';
import { createSelector } from 'reselect';

import { ConfigContainerType } from '@tw/types';

import { backendConfigSelector } from './backendConfig/backendConfigSelectors';
import { formatConfigSelector } from './formatConfig/formatConfigSelectors';
import { themeConfigSelector } from './themeConfig/themeConfigSelectors';
import { uiSettingsConfigSelector } from './uiSettingsConfig/uiSettingsConfigSelectors';
import { deviceConfigSelector } from './deviceConfig/deviceConfigSelectors';
import { i18nConfigSelector } from './i18nConfig/i18nConfigSelectors';

// This file's main duty is to collate the config selectors for easier/nicer injection elsewhere,
// and it also adds a PropType for these configs.

/**
 * Because modules often need to pass multiple config sets into actions or down to their
 * children, the configContainer is a convenient "bucket of buckets" that contains all
 * the major (top level) config values. This lets each module receive and pass along a
 * single prop to its children, and the children can access whichever items they need
 * (instead of needing to enumerate and pass each of the items that the child component needs.)
 *
 * This is not a great pattern -- it sort of obfuscates what config values the child
 * component needs/uses -- but it makes life much simpler when we don't have to micro-manage
 * the individual config dependencies of each and every child.
 * It also means we can add new configs without having to update every place where we pass
 * props to a child in the app.
 *
 * This does come at a performance cost, since this selector will re-calculate every time
 * *any* config changes -- but the individual configs are all cached so hopefully it's low.
 * The performance hit is certainly dwarfed by the dev-time savings.
 */
const configContainerSelector = createSelector<unknown, unknown, ConfigContainerType>(
  [
    backendConfigSelector,
    formatConfigSelector,
    themeConfigSelector,
    uiSettingsConfigSelector,
    deviceConfigSelector,
    i18nConfigSelector,
  ],
  (backendConfig, formatConfig, themeConfig, uiSettingsConfig, deviceConfig, i18nConfig) => ({
    backendConfig,
    formatConfig,
    themeConfig,
    uiSettingsConfig,
    deviceConfig,
    i18nConfig,
  }),
);

/**
 * Used for prop validation via component.propTypes
 *
 * @param props
 * @param propName
 * @param componentName
 * @return {Error}
 */
const configContainerPropType = (props, propName, componentName) => {
  // This is basically unimplemented, for now.
  // For now, we'll just make sure it's an object.
  if (!_.isObjectLike(props[propName])) {
    return new Error(
      `Invalid prop "${propName}" supplied to "${componentName}": must be a config object`,
    );
  }

  return null;
};

// The linter expects all required props to have `isRequired` on their propType, so we're going
// to add another reference to configContainerPropType at that point.
configContainerPropType.isRequired = configContainerPropType;

export {
  configContainerSelector,
  backendConfigSelector,
  themeConfigSelector,
  uiSettingsConfigSelector,
  deviceConfigSelector,
  i18nConfigSelector,
  configContainerPropType,
};

export * from './superSecretConfigData';
