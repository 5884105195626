/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchMyBulletinsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchMyBulletinsQuery = {
  myBulletins?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      bulletin?: {
        bulletinId: string;
        bulletinType: Types.BulletinType;
        label: string;
        externalUrl?: string | null;
        isPinned: boolean;
        createdDate?: any | null;
        content: string;
        expirationDateTime?: any | null;
        headerImageFull?: string | null;
        headerImageThumb?: string | null;
        meta?: any | null;
        buttonText?: string | null;
        key?: string | null;
        visibilityCodes?: Array<string | null> | null;
        creator?: {
          pk?: string | null;
          initials?: string | null;
          fullName?: string | null;
          picture?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchMyBulletinsDocument = gql`
  query FetchMyBulletins {
    myBulletins {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        bulletin: node {
          key: pk
          bulletinId
          bulletinType
          label
          externalUrl
          isPinned
          createdDate
          creator {
            pk
            fullName: fullNameNormalOrder
            initials
            picture: pictureUrl
          }
          content
          expirationDateTime
          headerImageFull
          headerImageThumb
          visibilityCodes: visibility
          meta
          buttonText
        }
      }
    }
  }
`;

/**
 * __useFetchMyBulletinsQuery__
 *
 * To run a query within a React component, call `useFetchMyBulletinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyBulletinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyBulletinsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMyBulletinsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMyBulletinsQuery, FetchMyBulletinsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMyBulletinsQuery, FetchMyBulletinsQueryVariables>(
    FetchMyBulletinsDocument,
    options,
  );
}
export function useFetchMyBulletinsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMyBulletinsQuery, FetchMyBulletinsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMyBulletinsQuery, FetchMyBulletinsQueryVariables>(
    FetchMyBulletinsDocument,
    options,
  );
}
export type FetchMyBulletinsQueryHookResult = ReturnType<typeof useFetchMyBulletinsQuery>;
export type FetchMyBulletinsLazyQueryHookResult = ReturnType<typeof useFetchMyBulletinsLazyQuery>;
export type FetchMyBulletinsQueryResult = Apollo.QueryResult<
  FetchMyBulletinsQuery,
  FetchMyBulletinsQueryVariables
>;
