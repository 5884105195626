/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseEditor, Transforms } from 'slate';
import { urlUtils } from '@tw/util';

const IMAGE_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

export const insertImage = (editor: BaseEditor, url: string | ArrayBuffer) => {
  const text = { text: '' };
  const image = { type: 'image', url, children: [text] };
  Transforms.insertNodes(editor, image, { select: true });
  Transforms.insertNodes(
    editor,
    {
      type: 'paragraph',
      children: [{ text: '' }],
    } as any,
    { mode: 'highest' },
  );
};

export const isImageUrl = (url: string) => {
  if (!url) return false;
  if (!urlUtils.isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return IMAGE_FILE_TYPES.includes(ext);
};
