import styled from '@emotion/styled';
import TWErrorAlertDeprecated from '../../../presentational/TWErrorAlertDeprecated';

const Container = styled.div(({ theme }) => ({
  height: '100%',
  maxHeight: 462,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  flexDirection: 'row',
  backgroundColor: theme.colors.background,
  '> div:first-of-type': {
    flex: 1,
    maxWidth: 450,
  },
}));

const StyledTWErrorAlertDeprecated = styled(TWErrorAlertDeprecated)(({ theme }) => ({
  marginBottom: theme.baseUnit * 2,
}));

export { Container, StyledTWErrorAlertDeprecated };
