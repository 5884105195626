/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExternalVideoIncrementPlayCountMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ExternalVideoInput>;
}>;

export type ExternalVideoIncrementPlayCountMutation = {
  externalVideoIncrementPlayCount: {
    video?: { title: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const ExternalVideoIncrementPlayCountDocument = gql`
  mutation ExternalVideoIncrementPlayCount($input: ExternalVideoInput) {
    externalVideoIncrementPlayCount(input: $input) {
      video {
        title
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type ExternalVideoIncrementPlayCountMutationFn = Apollo.MutationFunction<
  ExternalVideoIncrementPlayCountMutation,
  ExternalVideoIncrementPlayCountMutationVariables
>;

/**
 * __useExternalVideoIncrementPlayCountMutation__
 *
 * To run a mutation, you first call `useExternalVideoIncrementPlayCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalVideoIncrementPlayCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalVideoIncrementPlayCountMutation, { data, loading, error }] = useExternalVideoIncrementPlayCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalVideoIncrementPlayCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalVideoIncrementPlayCountMutation,
    ExternalVideoIncrementPlayCountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalVideoIncrementPlayCountMutation,
    ExternalVideoIncrementPlayCountMutationVariables
  >(ExternalVideoIncrementPlayCountDocument, options);
}
export type ExternalVideoIncrementPlayCountMutationHookResult = ReturnType<
  typeof useExternalVideoIncrementPlayCountMutation
>;
export type ExternalVideoIncrementPlayCountMutationResult =
  Apollo.MutationResult<ExternalVideoIncrementPlayCountMutation>;
export type ExternalVideoIncrementPlayCountMutationOptions = Apollo.BaseMutationOptions<
  ExternalVideoIncrementPlayCountMutation,
  ExternalVideoIncrementPlayCountMutationVariables
>;
