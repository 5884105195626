import styled from '@emotion/styled';

// ant-form-item-label styles for the TWInputCheckboxGroup component label
// this component doesn't exist in antd but we need it to match our designs
// so we need to emulate the ant-d form field dimensions for the input

const CheckboxesRow = styled.div<{ column?: boolean }>(({ column }) => ({
  alignItems: column ? 'flex-start' : 'center',
  display: 'flex',
  flexDirection: column ? 'column' : 'row',
}));

export { CheckboxesRow };
