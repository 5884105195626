import styled from '@emotion/styled';
import { Row } from 'antd';
import { CSSProperties } from 'react';

import TWIcon from '../../TWIcon';

const HEADER_HEIGHT = '96PX';
const FOOTER_HEIGHT = '80px';

export const FullscreenDrawerBody = styled.div(({ theme }) => ({
  marginBottom: FOOTER_HEIGHT,
  marginTop: FOOTER_HEIGHT,
  padding: theme.baseUnit * 3,
}));

export const FullscreenDrawerHeader = styled(Row)(({ theme }) => ({
  height: HEADER_HEIGHT,
  backgroundColor: theme.colors.white,
  borderBottom: `1px solid ${theme.colors.border}`,
  padding: theme.baseUnit * 3,
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  // some antd z-indices go up to 2
  zIndex: 3,
}));

export const FullscreenDrawerFooter = styled(Row)<{ position: CSSProperties['position'] }>(
  ({ theme, position }) => ({
    height: FOOTER_HEIGHT,
    backgroundColor: theme.colors.white,
    borderTop: `1px solid ${theme.colors.border}`,
    padding: theme.baseUnit * 3,
    boxShadow: `0px 0px 8px ${theme.colors.boxShadow}`,

    // we should eventually drop this ternary override in favor of position: 'fixed' once we can fully test. This will resolve typescript issue for position prop.
    position: position || 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 3,
  }),
);

export const CloseIcon = styled(TWIcon)(({ theme }) => ({
  cursor: 'pointer',
  marginRight: theme.baseUnit,
  verticalAlign: 'top',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const CloseText = styled.h1(({ theme }) => ({
  marginBottom: 'unset',
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: theme.fontFamily.default,
  fontSize: '20px',
  fontWeight: theme.fontWeight.semibold,
  lineHeight: '28px',
}));
