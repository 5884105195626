import { Form, Input } from 'antd';
import { useEffect } from 'react';

import { useProfessorCreate, useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { useProfessorUpdate } from '@tw/modules/Academics2/hooks';

import {
  TWButton,
  TWFlexContainer,
  TWForm,
  TWFormItem,
  TWInputPhoneNumber,
} from '../../../../presentational';
import { TWMessage } from '../../../TWMessage';
import { TWModal } from '../../../TWModal';

import {
  ProfessorCreateFormValues,
  ProfessorCreateModalProps,
} from './ProfessorCreateModal.definitions';

export const ProfessorCreateModal = ({
  professor,
  onSubmit,
  onClose,
  testID,
  ...rest
}: ProfessorCreateModalProps) => {
  const { personId, orgId } = useViewer();

  const [form] = Form.useForm<ProfessorCreateFormValues>();

  const handleComplete = (
    aC2Professor: { id: string },
    userErrors: {
      field: string[];
      message: string;
    }[],
  ) => {
    if (userErrors.length > 0) {
      TWMessage.error(
        userErrors[0].message.includes(
          'violates unique constraint "uix_ac2_professor_org_id_customer_key"',
        )
          ? getTranslation('coursesTab.instructorCreationModal.emailUsed')
          : userErrors[0].message,
      );
    } else {
      form.resetFields();
      onSubmit(aC2Professor.id);
    }
  };

  const [createProfessor, { loading: createLoading }] = useProfessorCreate({
    onCompleted: ({ professorCreate: { aC2Professor, userErrors } }) =>
      handleComplete(aC2Professor, userErrors),
    onError: (error) => TWMessage.handleErrorWithContext({ error, context: { personId, orgId } }),
  });
  const [updateProfessor, { loading: updateLoading }] = useProfessorUpdate({
    onCompleted: ({ professorUpdate: { aC2Professor, userErrors } }) =>
      handleComplete(aC2Professor, userErrors),
    onError: (error) =>
      TWMessage.handleErrorWithContext({
        error,
        context: { personId, orgId, professorId: professor?.id },
      }),
  });

  const loading = createLoading || updateLoading;

  useEffect(() => {
    const { fullName = '', id, emailAddress, officeLocation, phoneNumber } = professor || {};
    const [firstName, lastName] = fullName.split(' ');
    form.setFieldsValue({ firstName, lastName, id, emailAddress, officeLocation, phoneNumber });
  }, [professor, form]);

  const onOk = async () => {
    await form.validateFields();

    const values: ProfessorCreateFormValues = form.getFieldsValue();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...input } = values;

    if (!professor) {
      await createProfessor({ variables: { input } });
    } else {
      await updateProfessor({ variables: { id: professor.id, input } });
    }
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <TWForm form={form} name="create-professor-form" requiredMark>
      <TWModal
        onCancel={onCancel}
        title={
          professor
            ? getTranslation('coursesTab.instructorCreationModal.updateInstructor')
            : getTranslation('coursesTab.instructorCreationModal.newInstructor')
        }
        footer={
          <TWFlexContainer row twMarginLeft={1} justify="flex-end">
            <TWButton
              accessibilityLabel={getTranslation('cancel')}
              onClick={onCancel}
              type="default"
            >
              {getTranslation('cancel')}
            </TWButton>
            <TWButton
              accessibilityLabel={getTranslation('submit')}
              loading={loading}
              onClick={onOk}
              style={{ marginLeft: '8px' }}
              type="primary"
            >
              {getTranslation('submit')}
            </TWButton>
          </TWFlexContainer>
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {professor && (
          <TWFormItem hidden name="id" required>
            <Input />
          </TWFormItem>
        )}
        <TWFlexContainer row>
          <TWFormItem
            name="firstName"
            label={getTranslation('firstName')}
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: getTranslation('coursesTab.instructorCreationModal.firstNameRequired'),
              },
            ]}
          >
            <Input placeholder={getTranslation('firstName')} data-testid={`${testID}:FirstName`} />
          </TWFormItem>
          <TWFormItem
            label={getTranslation('lastName')}
            name="lastName"
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: getTranslation('coursesTab.instructorCreationModal.lastNameRequired'),
              },
            ]}
            style={{ marginLeft: '16px' }}
          >
            <Input placeholder={getTranslation('lastName')} data-testid={`${testID}:LastName`} />
          </TWFormItem>
        </TWFlexContainer>
        <TWFormItem
          name="emailAddress"
          label={getTranslation('emailAddress')}
          rules={[
            {
              required: true,
              type: 'email',
              message: getTranslation('coursesTab.instructorCreationModal.invalidEmail'),
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input
            placeholder={getTranslation('emailAddress')}
            data-testid={`${testID}:EmailAddress`}
          />
        </TWFormItem>
        <TWFormItem name="phoneNumber" label={getTranslation('phoneNumber')}>
          <TWInputPhoneNumber
            accessibilityLabel={getTranslation('phoneNumber')}
            testID={`${testID}:PhoneNumber`}
            aria-label={getTranslation('phoneNumber')}
          />
        </TWFormItem>
        <TWFormItem name="officeLocation" label={getTranslation('officeLocation')}>
          <Input
            placeholder={getTranslation('officeLocation')}
            data-testid={`${testID}:OfficeLocation`}
          />
        </TWFormItem>
      </TWModal>
    </TWForm>
  );
};
