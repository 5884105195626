import {
  monthDayYearLongFormat,
  twelveHourTimeFormat,
  twelveHourTimeFormatWithSeconds,
  twentyFourHourTimeFormat,
} from '@tw/constants';
import { useViewer } from '@tw/hooks';

type Props = {
  inputDateFormat?: string;
};

export function useDateTimeFormat(options: Props = {}) {
  const {
    userPreferences: { uses24HourTime: userUses24HourTime },
    person: { timeZone: personTimeZone },
    currentOrg: { timeZoneCode: orgTimeZone, uses24HourTime: orgUses24HourTime },
  } = useViewer();
  // use the user's preference if it exists, otherwise use the org's preference
  const uses24HourTime = userUses24HourTime ?? orgUses24HourTime ?? false;

  const dateFormat = options.inputDateFormat || monthDayYearLongFormat;
  const timeZone = personTimeZone || orgTimeZone || 'America/New_York'; // default to EST if no timezone is set. Should hopefully never happen
  const timeFormat = uses24HourTime ? twentyFourHourTimeFormat : twelveHourTimeFormat;

  // timeFormatWithSeconds needs to be different between 12/24 time or else you end up with 12:00pm:00 and 12:00:00 formats;
  let timeFormatWithSeconds;
  if (uses24HourTime) {
    timeFormatWithSeconds = `${timeFormat}:ss`;
  } else {
    timeFormatWithSeconds = twelveHourTimeFormatWithSeconds;
  }

  const timeFormatWithZone = `${timeFormat} z`;

  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  const dateTimeFormatWithZone = `${dateFormat} ${timeFormatWithZone}`;
  const dateTimeFormatWithSeconds = `${dateFormat} ${timeFormatWithSeconds}`;

  return {
    dateFormat,
    userUses24HourTime,
    dateTimeFormat,
    dateTimeFormatWithZone,
    dateTimeFormatWithSeconds,
    timeFormat,
    timeFormatWithZone,
    timeFormatWithSeconds,
    timeZone,
    uses24HourTime,
  };
}
