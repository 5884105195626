import { Slider } from 'antd';

import { TWFlexContainer } from '@tw/components';

interface TWInputRangeProps {
  value: number;
  schema: {
    maximum?: number;
    minimum?: number;
    title?: string;
  };
  options: {
    maxLabel?: string;
    minLabel?: string;
  };
  onChange: (value: number) => void;
}

const TWInputRange = ({ value, schema, options, onChange }: TWInputRangeProps) => {
  const handleOnChange = (newValue: number) => {
    onChange(newValue);
  };

  const { maximum, minimum } = schema;

  return (
    <div>
      <Slider onChange={handleOnChange} value={value} max={maximum} min={minimum} />
      <TWFlexContainer row justify="space-between">
        <span>{options?.minLabel}</span>
        <span>{options?.maxLabel}</span>
      </TWFlexContainer>
    </div>
  );
};

export default TWInputRange;
