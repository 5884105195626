/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTicketingEventPersonMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  input: Types.TicketingEventPersonInput;
}>;

export type UpdateTicketingEventPersonMutation = {
  ticketingEventPersonUpdate: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const UpdateTicketingEventPersonDocument = gql`
  mutation UpdateTicketingEventPerson($id: ID, $input: TicketingEventPersonInput!) {
    ticketingEventPersonUpdate(id: $id, input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateTicketingEventPersonMutationFn = Apollo.MutationFunction<
  UpdateTicketingEventPersonMutation,
  UpdateTicketingEventPersonMutationVariables
>;

/**
 * __useUpdateTicketingEventPersonMutation__
 *
 * To run a mutation, you first call `useUpdateTicketingEventPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketingEventPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketingEventPersonMutation, { data, loading, error }] = useUpdateTicketingEventPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTicketingEventPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTicketingEventPersonMutation,
    UpdateTicketingEventPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTicketingEventPersonMutation,
    UpdateTicketingEventPersonMutationVariables
  >(UpdateTicketingEventPersonDocument, options);
}
export type UpdateTicketingEventPersonMutationHookResult = ReturnType<
  typeof useUpdateTicketingEventPersonMutation
>;
export type UpdateTicketingEventPersonMutationResult =
  Apollo.MutationResult<UpdateTicketingEventPersonMutation>;
export type UpdateTicketingEventPersonMutationOptions = Apollo.BaseMutationOptions<
  UpdateTicketingEventPersonMutation,
  UpdateTicketingEventPersonMutationVariables
>;
