import { useTheme } from '@emotion/react';
import { TWButton, TWFlexContainer, TWSpacingContainer } from '@tw/components/presentational';
import {
  InfoMessageType,
  TWInfoMessageProps,
  TWInfoMessageTypes,
} from './TWInfoMessage.definitions';
import {
  TWInfoMessageContainer,
  TWInfoMessageIcon,
  TWInfoMessageHeaderText,
  getColors,
} from './TWInfoMessage.styles';
import TWIcon from '../TWIcon/TWIcon';

const TWInfoMessage = ({
  type,
  testID,
  headerText,
  description,
  showCloseIcon = false,
  fitContent = false,
  onClose = () => null,
}: TWInfoMessageProps) => {
  const theme = useTheme();
  const getIcon = (messageType: TWInfoMessageTypes): string => {
    switch (messageType) {
      case InfoMessageType.WARNING:
        return 'material-warning';
      case InfoMessageType.INFO:
      default:
        return 'material-info';
    }
  };

  return (
    <TWInfoMessageContainer messageType={type} fitContent={fitContent} row>
      <TWFlexContainer row align="flex-start">
        <TWInfoMessageIcon
          testID={`${testID}:Icon`}
          type={getIcon(type)}
          color={getColors(type, theme).iconColor}
          width={20}
          height={20}
        />
        <TWFlexContainer>
          {headerText && (
            <TWFlexContainer data-testid={`${testID}:Header`}>
              <TWInfoMessageHeaderText messageType={type} twFontWeight="bold">
                {headerText}
              </TWInfoMessageHeaderText>
            </TWFlexContainer>
          )}
          {description && headerText && <TWSpacingContainer twMarginTop={0.5} />}
          {description && (
            <TWFlexContainer data-testid={`${testID}:Description`}>{description}</TWFlexContainer>
          )}
        </TWFlexContainer>
      </TWFlexContainer>
      {showCloseIcon && (
        <TWButton
          accessibilityLabel="close-info-message"
          onClick={onClose}
          type="borderless"
          shape="circle"
        >
          <TWIcon
            testID={`${testID}:CloseIcon`}
            type="material-close"
            color={getColors(type, theme).iconColor}
            width={20}
            height={20}
          />
        </TWButton>
      )}
    </TWInfoMessageContainer>
  );
};

export default TWInfoMessage;
