import styled from '@emotion/styled';
import { sharedSpacingStyles, SpacingComponentProps } from '@tw/components/utils';

interface ContainerProps {
  isStaged: boolean;
  isDisabled: boolean;
}

const Container = styled.div<SpacingComponentProps & ContainerProps>(
  ({ theme, isStaged, isDisabled }) => {
    let backgroundColor = 'inherit';
    if (isStaged) {
      backgroundColor = theme.colors.stagedBackground;
    }
    if (isDisabled) {
      backgroundColor = theme.colors.disabledBackground;
    }
    return [
      sharedSpacingStyles,
      {
        backgroundColor,
        '.twchip-text-and-subtext': {
          span: {
            color: isDisabled ? theme.colors.secondary : 'inherit',
          },
        },
      },
    ];
  },
);

export { Container };
