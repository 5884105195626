import styled from '@emotion/styled';

import { theme } from '@tw/components/utils';

export const BackButtonWrapper = styled.div({
  '.back-button': {
    position: 'absolute',
    color: theme.colors.secondary,
    fontSize: theme.fontUnit * 6,
    padding: theme.fontUnit * 3,
    width: 50,
    height: 50,
    top: 0,
    left: 0,
    '&:hover, &:focus, &:active': {
      borderColor: 'transparent',
    },
  },
});
