/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTicketingGuestMutationVariables = Types.Exact<{
  input: Types.TicketingGuestInput;
  id: Types.Scalars['ID'];
}>;

export type UpdateTicketingGuestMutation = {
  ticketingGuestUpdate: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    ticketingGuest?: { status: Types.TicketingGuestStatus } | null;
  };
};

export const UpdateTicketingGuestDocument = gql`
  mutation UpdateTicketingGuest($input: TicketingGuestInput!, $id: ID!) {
    ticketingGuestUpdate(input: $input, id: $id) {
      userErrors {
        field
        message
      }
      ticketingGuest {
        status
      }
    }
  }
`;
export type UpdateTicketingGuestMutationFn = Apollo.MutationFunction<
  UpdateTicketingGuestMutation,
  UpdateTicketingGuestMutationVariables
>;

/**
 * __useUpdateTicketingGuestMutation__
 *
 * To run a mutation, you first call `useUpdateTicketingGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketingGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketingGuestMutation, { data, loading, error }] = useUpdateTicketingGuestMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTicketingGuestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTicketingGuestMutation,
    UpdateTicketingGuestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTicketingGuestMutation, UpdateTicketingGuestMutationVariables>(
    UpdateTicketingGuestDocument,
    options,
  );
}
export type UpdateTicketingGuestMutationHookResult = ReturnType<
  typeof useUpdateTicketingGuestMutation
>;
export type UpdateTicketingGuestMutationResult =
  Apollo.MutationResult<UpdateTicketingGuestMutation>;
export type UpdateTicketingGuestMutationOptions = Apollo.BaseMutationOptions<
  UpdateTicketingGuestMutation,
  UpdateTicketingGuestMutationVariables
>;
