import { SUPPORTED_EXT } from '@tw/constants';

export const getBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result?.toString() ?? '');
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsDataURL(file);
  });

const KILOBYTE = 1024;
export const MEGABYTE = 1024 * 1024;

export const toKilobytes = (bytes: number) => bytes / KILOBYTE;
export const toMegabytes = (bytes: number) => bytes / MEGABYTE;

export const isSupportedFile = (file: File): boolean => {
  // Validate if file extension is actually supported by Teamworks. Sometimes the OS will still
  // allow the user to select a file with an unsupported extension, so we need to validate manually
  let isSupported = false;
  let fileExt = '';

  if (file.name.includes('.')) {
    fileExt = file.name.split('.').pop()?.toLocaleLowerCase() ?? '';
    fileExt = fileExt ? `.${fileExt}` : '';
    isSupported = SUPPORTED_EXT.includes(fileExt);
  }

  return isSupported;
};
