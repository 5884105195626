import { sanitize } from 'dompurify';

interface Props {
  textToRender: string;
}

const TWPurifiedHtml = ({ textToRender }: Props) => {
  const sanitizedHtml = {
    __html: `<span data-testid="Common:PurifiedHtml">${sanitize(textToRender, {
      WHOLE_DOCUMENT: true,
    })}</span>`,
  };

  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={sanitizedHtml} />;
};

export default TWPurifiedHtml;
