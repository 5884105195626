import styled from '@emotion/styled';
import { Button, ButtonProps } from 'antd';

import { TWIcon } from '@tw/components';

const DrawerButton = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: theme.fontUnit * 4,
  height: theme.baseUnit * 5,
  marginBottom: theme.baseUnit * 2,
  textAlign: 'left',
  width: '100%',
}));

interface TWDrawerButtonProps extends Omit<ButtonProps, 'icon'> {
  accessibilityLabel: string;
  testID?: string;
  label: React.ReactNode;
  icon?: string;
}

const TWDrawerButton = (props: TWDrawerButtonProps) => {
  const { testID, accessibilityLabel, label, onClick, icon, ...allUnrecognizedProps } = props;

  const iconEl = icon ? <TWIcon type={icon} style={{ marginRight: 8 }} /> : null;

  return (
    <DrawerButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allUnrecognizedProps}
      icon={iconEl}
      data-testid={testID}
      aria-label={accessibilityLabel}
      onClick={onClick}
    >
      {label}
    </DrawerButton>
  );
};

export default TWDrawerButton;
