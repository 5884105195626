/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupsMessageUsersMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.SignupsMessageUsersInput>;
}>;

export type SignupsMessageUsersMutation = { signupsMessageUsers: { messageId?: string | null } };

export const SignupsMessageUsersDocument = gql`
  mutation SignupsMessageUsers($input: SignupsMessageUsersInput) {
    signupsMessageUsers(input: $input) {
      messageId
    }
  }
`;
export type SignupsMessageUsersMutationFn = Apollo.MutationFunction<
  SignupsMessageUsersMutation,
  SignupsMessageUsersMutationVariables
>;

/**
 * __useSignupsMessageUsersMutation__
 *
 * To run a mutation, you first call `useSignupsMessageUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupsMessageUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupsMessageUsersMutation, { data, loading, error }] = useSignupsMessageUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupsMessageUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupsMessageUsersMutation,
    SignupsMessageUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupsMessageUsersMutation, SignupsMessageUsersMutationVariables>(
    SignupsMessageUsersDocument,
    options,
  );
}
export type SignupsMessageUsersMutationHookResult = ReturnType<
  typeof useSignupsMessageUsersMutation
>;
export type SignupsMessageUsersMutationResult = Apollo.MutationResult<SignupsMessageUsersMutation>;
export type SignupsMessageUsersMutationOptions = Apollo.BaseMutationOptions<
  SignupsMessageUsersMutation,
  SignupsMessageUsersMutationVariables
>;
