/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideoDeleteMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type VideoDeleteMutation = {
  videoDelete: {
    video?: { videoId: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const VideoDeleteDocument = gql`
  mutation VideoDelete($id: ID) {
    videoDelete(id: $id) {
      video {
        videoId
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type VideoDeleteMutationFn = Apollo.MutationFunction<
  VideoDeleteMutation,
  VideoDeleteMutationVariables
>;

/**
 * __useVideoDeleteMutation__
 *
 * To run a mutation, you first call `useVideoDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoDeleteMutation, { data, loading, error }] = useVideoDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<VideoDeleteMutation, VideoDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VideoDeleteMutation, VideoDeleteMutationVariables>(
    VideoDeleteDocument,
    options,
  );
}
export type VideoDeleteMutationHookResult = ReturnType<typeof useVideoDeleteMutation>;
export type VideoDeleteMutationResult = Apollo.MutationResult<VideoDeleteMutation>;
export type VideoDeleteMutationOptions = Apollo.BaseMutationOptions<
  VideoDeleteMutation,
  VideoDeleteMutationVariables
>;
