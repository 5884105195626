import styled from '@emotion/styled';
import TWIcon from '../TWIcon';

export const InvisibleLabel = styled.div({
  display: 'none',
});

export const OptionError = styled.div(({ theme }) => ({
  color: theme.colors.negative,
  fontSize: theme.fontUnit * 3,
  fontWeight: theme.fontWeight.regular,
  marginTop: '5px',
  display: 'flex',
  justifyContent: 'flex-start',
}));

export const ErrorIcon = styled(TWIcon)(({ theme }) => ({
  fontSize: theme.fontUnit * 4,
  marginRight: '5px',
}));
