import { gql } from '@apollo/client';

import { formsQueries, userMocks, peopleQueries, peopleMutations } from './mocks';

const typeDefs = gql`
  ${userMocks.graphqlDefs}
  ${formsQueries.graphqlDefs}
  ${peopleQueries.graphqlDefs}
  ${peopleMutations.graphqlDefs}
`;
const resolvers = {
  Query: {
    viewer: userMocks.resolver,
    ...formsQueries.resolvers,
    ...peopleQueries.resolvers,
  },
  Mutation: {
    ...peopleMutations.resolvers,
  },
};

export { typeDefs, resolvers };
