import styled from '@emotion/styled';
import { withRouter, RouteChildrenProps } from 'react-router-dom';
import { TWRouterContentProps } from './propTypes';
import TWRouterRendererSwitch from './TWRouterRendererSwitch';
import { DEFAULT_RENDER_KEY } from './TWRoute.constants';

const TWRouterContent: React.FC<TWRouterContentProps & RouteChildrenProps> = ({
  renderKey = DEFAULT_RENDER_KEY,
  routeMap,
  currentUserData,
  ...allUnrecognizedProps
}) => (
  <Content>
    <TWRouterRendererSwitch
      routeMap={routeMap}
      currentUserData={currentUserData}
      renderKey={renderKey}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allUnrecognizedProps}
    />
  </Content>
);

export default withRouter(TWRouterContent);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
`;
