const $twSecondaryTabs = {
  backgroundColor: '$colorHue.white',
  borderColor: '$colorHue.grayMid',
  borderWidth: '$borderWidth.medium',
  labelColorActive: '$colorHue.grayDark',
  labelColorInactive: '$colorHue.grayBlue',
  labelFontSize: 16,
  labelFontWeight: '500',
  indicatorHeight: 3,
  paddingVertical: '$whitespaceOld.medium',
  indicatorColor: '$brandColor.primaryColor',
};

export { $twSecondaryTabs };
