import styled from '@emotion/styled';
import { Checkbox } from 'antd';
import { SizeScalingType } from '../../../utils';
import { inputSizeScaling } from '../utils';

interface CheckboxContainerProps {
  size: SizeScalingType;
}

const CheckboxContainer = styled.div<CheckboxContainerProps>(({ theme, size }) => {
  const { height } = inputSizeScaling[size];

  return {
    display: 'flex',
    alignItems: 'center',
    height,
    span: {
      color: theme.colors.secondary,
      fontWeight: theme.fontWeight.regular,
    },
  };
});

const HideLabelCheckbox = styled(Checkbox)({
  '& > span:nth-of-type(2)': {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
});

export { CheckboxContainer, HideLabelCheckbox };
