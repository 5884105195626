import { reduxConfigUtils } from '@tw/util';

import formatConfigStoreConfig from './formatConfigStoreConfig';

const { layerNames, rootSelector } = formatConfigStoreConfig;

// All the actual work to create selectors and chain them together is in reduxConfigUtils.js;
// go there to see what's actually happening here.
const { topmostSelector, generatedSelectors } = reduxConfigUtils.generateSelectorsForConfigLayers(
  layerNames,
  rootSelector,
);

// Note that we explicitly list the names/selectors that we want to export
// (partially for clarity and greppability, and partially because we can't do dynamic
//  names with es6 modules anyway.)

// 99% of the time, consumers just want the topmost selector -- we'll rename it for
// clarity and readability.
export { topmostSelector as formatConfigSelector };

// You generally DON'T want these -- they're for debugging and peeking at the internal and
// intermediate values within the layers.
export const {
  debugOverrideValuesSelector,
  // Secret Settings and superSecretConfigData need access to all the layers'
  // attributes and values as one block
  allLayerAttributesSelector: allFormatConfigLayerAttributesSelector,
  allLayerValuesSelector: allFormatConfigLayerValuesSelector,
} = generatedSelectors;
