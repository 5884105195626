import { useTheme } from '@emotion/react';
import noop from 'lodash/noop';
import { ReactEventHandler, ReactNode, useEffect, useState } from 'react';

import { IconInterface } from '@tw/components/presentational/listItems/utils/listItem.definitions';
import { SelectionItemInterface, SelectionItemsByCode } from '@tw/components/utils';
import { GroupType } from '@tw/generated';
import { useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import {
  UserSelectionQueryFilters,
  useUserSelection,
} from '@tw/hooks/useUserSelection/useUserSelection';
import { formatUtils } from '@tw/util';

import { TWIcon, TWInputCheckboxColor } from '../../../../../components.web';
import { TWListItemAvatar } from '../../../../../presentational';
import { SelectInputBase } from '../../../@components';
import TWUserSelectionFullMultiModal from '../../TWUserSelectionFullMultiModal';
import useMultiSelect from '../../useMultiSelect';
import {
  Container,
  SelectionListWrapper,
  SelectionsContainer,
} from './TWUserSelectionInputMultiList.styles';

/**
 * Codes specified are only "selected" visually. You must implement the logic to
 * handle these selections in your `onChange` callback.
 */
export interface TWUserSelectionInputMultiListProps {
  accessibilityLabel?: string;
  alwaysSelectedCodes?: string[];
  canSwitchTeams?: boolean;
  checkedSelections?: boolean;
  disabled?: boolean;
  defaultGroupTypeFilter?: GroupType;
  hideAlwaysSelected?: boolean;
  hideSelections?: boolean;
  id?: string;
  ignoreCustomGroupsForAllTeamsTeam?: boolean;
  includesAllTeams?: boolean;
  individualsOnly?: boolean;
  isModalOpen?: boolean;
  modalOnly?: boolean;
  placeholder?: string;
  queryFilters?: UserSelectionQueryFilters;
  readOnly?: boolean;
  selectedTeamId?: string;
  selections: SelectionItemInterface[];
  selectionsContainerStyle?: React.CSSProperties;
  selectionsMaxHeight?: string | number;
  showAvatars?: boolean;
  testID: string;
  useGroups?: boolean;
  userTypeGroupsOnly?: boolean;
  userTypesOnly?: boolean;
  value?: SelectionItemInterface[]; // Will be set when used as a controlled input
  viewCanModifyUsersTeamOnly?: boolean;
  viewOnly?: boolean;
  width?: number | string;
  withTeamMembership?: boolean;
  innerInputRender?: () => ReactNode;
  renderInput?: (onClick: ReactEventHandler<HTMLButtonElement>) => ReactNode;
  onCancel?: () => void;
  onChange?: (selectionsList: SelectionItemInterface[]) => void;
  onCheckedSelectionsChange?: (checkedSelectionsList: SelectionItemInterface[]) => void;
}

// wrapped in forwardRef because to be used as a form input a ref is passed through
const TWUserSelectionInputMultiList = ({
  accessibilityLabel,
  alwaysSelectedCodes,
  canSwitchTeams = true,
  checkedSelections = false,
  defaultGroupTypeFilter = GroupType.Type,
  disabled = false,
  hideAlwaysSelected,
  hideSelections = false,
  id = 'TWUserSelectionInputMultiList',
  ignoreCustomGroupsForAllTeamsTeam = false,
  includesAllTeams = true,
  individualsOnly = false,
  isModalOpen = false,
  modalOnly = false,
  placeholder = '',
  queryFilters = {},
  readOnly = false,
  // pre-selected teamId, if it is not specified, the current team is used instead
  selectedTeamId,
  selections,
  selectionsContainerStyle = {},
  selectionsMaxHeight = 'auto',
  testID,
  showAvatars = false,
  useGroups = false,
  userTypeGroupsOnly = false,
  userTypesOnly = false,
  viewCanModifyUsersTeamOnly,
  viewOnly = false,
  width = 265,
  withTeamMembership = false,
  innerInputRender,
  renderInput,
  onChange = noop,
  onCheckedSelectionsChange = noop,
  onCancel = noop,
}: TWUserSelectionInputMultiListProps) => {
  const { colors } = useTheme();
  const viewer = useViewer();
  const [selectionTreeVisible, setSelectionTreeVisible] = useState(false);
  const [internalIsModalOpen, setInternalIsModalOpen] = useState(isModalOpen);

  useEffect(() => {
    setInternalIsModalOpen(isModalOpen);
  }, [isModalOpen]);

  const { loading, error, data } = useUserSelection({
    options: {
      ...queryFilters,
      teamFilter: selectedTeamId || viewer.currentTeam.id,
      withTeamMembership: !userTypesOnly && withTeamMembership,
      userTypesOnly,
    },
    fetchPolicy: 'cache-and-network',
  });

  const multiSelectManagementProps = useMultiSelect({
    defaultGroupTypeFilter,
    onChange,
    onCheckedSelectionsChange,
    selections,
    queryFilters,
  });

  const {
    handleOnItemClick,
    handleOnDeselect,
    handleOnExplode,
    setSelectionItemsByCode,
    selectionItemsByCode,
  } = multiSelectManagementProps;

  const selectionsList = Object.values(selectionItemsByCode);

  useEffect(() => {
    setSelectionTreeVisible(false);
  }, [selectionItemsByCode]);

  const handleModalClose = (): void => {
    setInternalIsModalOpen(false);
    onCancel();
  };

  const handleSubmit = (incomingSelectionItemsByCode: SelectionItemsByCode): void => {
    const incomingSelectionItemsList = Object.values(incomingSelectionItemsByCode);
    setSelectionItemsByCode(incomingSelectionItemsByCode);
    onCheckedSelectionsChange(incomingSelectionItemsList);
    onChange(incomingSelectionItemsList);
    handleModalClose();
  };

  const handleMoreButtonClick = (): void => {
    setInternalIsModalOpen(true);
    setSelectionTreeVisible(false);
  };

  const onSelectionCheckClicked = (clickedSelectionItem: SelectionItemInterface) => {
    setSelectionItemsByCode((prevSelectionItemsByCode) => {
      const newSelectionItemsByCode = Object.entries(
        prevSelectionItemsByCode,
      ).reduce<SelectionItemsByCode>((obj, [key, selectionItem]) => {
        if (selectionItem.selectionCode === clickedSelectionItem.selectionCode) {
          obj[key] = {
            ...clickedSelectionItem,
            isChecked: !clickedSelectionItem.isChecked,
          };
        } else {
          obj[key] = selectionItem;
        }

        return obj;
      }, {});
      onCheckedSelectionsChange(Object.values(newSelectionItemsByCode));
      return newSelectionItemsByCode;
    });
  };

  const renderSelectionsList = () =>
    selectionsList.map((selectionItem: SelectionItemInterface & { leftNode?: React.ReactNode }) => {
      if (hideAlwaysSelected && alwaysSelectedCodes?.includes(selectionItem.selectionCode)) {
        return null;
      }

      const isGroup = 'groupType' in selectionItem && selectionItem.groupType;
      const isPerson = 'pictureUrl' in selectionItem && 'initials' in selectionItem;

      const icons: IconInterface[] = [];
      // if there is no key 'active' we set true value by default
      let isActive = true;

      if ('active' in selectionItem) {
        isActive = selectionItem.active ?? true;
      }

      // Only allow deselect self if not "excludeSelf" & allow deselect other user if
      // not in "alwaysSelectedCodes"
      const isSelf = isPerson && +selectionItem.personId === viewer.personId;
      const canDeselect = queryFilters.excludeSelf
        ? !isSelf
        : !alwaysSelectedCodes?.includes(selectionItem.selectionCode);

      if (!readOnly && !viewOnly && canDeselect) {
        icons.push({
          type: 'material-close',
          tooltip: getTranslation('remove'),
          iconOnClick: (): void => handleOnDeselect(selectionItem.selectionCode),
        });
      }

      // The explode selections action should be unavailable for calendar filters
      if (
        !userTypesOnly &&
        !checkedSelections &&
        'groupType' in selectionItem &&
        selectionItem.groupType &&
        selectionItem.groupType !== 'EVERYONE'
      ) {
        icons.unshift({
          type: 'tw-expand-list',
          tooltip: getTranslation('userSelection.explode'),
          iconOnClick: (): void => handleOnExplode(selectionItem.selectionCode),
        });
      }

      if (!isActive) {
        icons.unshift({
          type: 'material-error',
          tooltip: getTranslation('inactiveUser'),
          style: { color: colors.negative },
        });
      }

      return (
        <TWListItemAvatar
          key={selectionItem.selectionCode}
          showAvatar={showAvatars}
          onClick={() => onSelectionCheckClicked(selectionItem)}
          leftNode={
            selectionItem?.leftNode
              ? selectionItem.leftNode
              : (checkedSelections && (
                  <TWInputCheckboxColor
                    labelPassed={false}
                    testID={`UserSelection:SelectedItem-${selectionItem.selectionCode}:checkbox`}
                    id={`UserSelection:SelectedItem-${selectionItem.selectionCode}:checkbox`}
                    isChecked={
                      !!selectionsList.find(
                        ({ selectionCode }) => selectionCode === selectionItem.selectionCode,
                      )?.isChecked
                    }
                  />
                )) ||
                null
          }
          avatarIcon={<TWIcon type={'groupType' in selectionItem ? 'tw-group' : 'tw-user'} />}
          avatarImage={isPerson ? selectionItem.pictureUrl : undefined}
          avatarText={isPerson ? selectionItem.initials : undefined}
          label={
            isGroup
              ? formatUtils.getSelectionGroupLabel(
                  selectionItem.groupType as GroupType,
                  selectionItem.pluralLabel,
                )
              : selectionItem.label
          }
          testID={`UserSelection:SelectedItem-${selectionItem.selectionCode}`}
          size="default"
          rightIcons={!selectionItem.isPersistent ? icons : undefined}
        />
      );
    });

  return (
    <Container id={id} inputWidth={width}>
      {!modalOnly && (
        <>
          {!readOnly && (
            <SelectInputBase
              accessibilityLabel={accessibilityLabel}
              disabled={disabled || viewOnly}
              testID={testID}
              placeholder={placeholder}
              isModalOpen={internalIsModalOpen}
              setSelectionTreeVisible={setSelectionTreeVisible}
              selectionTreeVisible={selectionTreeVisible}
              handleMoreButtonClick={handleMoreButtonClick}
              itemOnSelect={handleOnItemClick}
              alwaysSelectedCodes={alwaysSelectedCodes}
              hideAlwaysSelected={hideAlwaysSelected}
              resetAfterSelection
              data={data}
              loading={loading}
              error={error}
              width={width}
              multi
              individualsOnly={individualsOnly}
              userTypesOnly={userTypesOnly}
              userTypeGroupsOnly={userTypeGroupsOnly}
              useGroups={useGroups}
              innerInputRender={innerInputRender}
              {...multiSelectManagementProps}
            />
          )}
          {!hideSelections && selectionsList && (
            <SelectionsContainer twMarginTop={readOnly ? -1 : 1} style={selectionsContainerStyle}>
              <SelectionListWrapper selectionsMaxHeight={selectionsMaxHeight}>
                {renderSelectionsList()}
              </SelectionListWrapper>
            </SelectionsContainer>
          )}
        </>
      )}
      {renderInput ? renderInput(() => setInternalIsModalOpen(!internalIsModalOpen)) : null}
      {internalIsModalOpen && (
        <TWUserSelectionFullMultiModal
          containerId={id}
          visible={internalIsModalOpen}
          testID={`UserSelectionSelectMulti:dialog:${testID}`}
          onOk={handleSubmit}
          onCancel={handleModalClose}
          queryFilters={queryFilters}
          defaultGroupTypeFilter={GroupType.Type}
          selectedTeamId={selectedTeamId}
          selections={selectionsList}
          selectionItemsByCode={selectionItemsByCode}
          alwaysSelectedCodes={alwaysSelectedCodes}
          hideAlwaysSelected={hideAlwaysSelected}
          individualsOnly={individualsOnly}
          userTypesOnly={userTypesOnly}
          userTypeGroupsOnly={userTypeGroupsOnly}
          canSwitchTeams={canSwitchTeams}
          withTeamMembership={withTeamMembership}
          ignoreCustomGroupsForAllTeamsTeam={ignoreCustomGroupsForAllTeamsTeam}
          includesAllTeams={includesAllTeams}
          viewCanModifyUsersTeamOnly={viewCanModifyUsersTeamOnly}
        />
      )}
    </Container>
  );
};

export default TWUserSelectionInputMultiList;
