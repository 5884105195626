import {
  MdAirplanemodeActive,
  MdCalendarMonth,
  MdFactCheck,
  MdFolderCopy,
  MdForum,
  MdHome,
  MdListAlt,
  MdPersonSearch,
  MdSchool,
  MdVideoFile,
  TimeManagementIcon,
} from '@teamworksdev/icons';
import { ViewerData } from '@tw/hooks';
import { TranslationKey, getTranslation } from '@tw/i18n';

const icons: Record<string, JSX.Element> = {
  '/home': <MdHome />,
  '/files': <MdFolderCopy />,
  '/videos': <MdVideoFile />,
  '/calendar': <MdCalendarMonth />,
  '/profiles': <MdPersonSearch />,
  '/profiles/me': <MdPersonSearch />,
  '/messaging': <MdForum />,
  '/academics': <MdSchool />,
  '/travel': <MdAirplanemodeActive />,
  '/timeManagement': <TimeManagementIcon />,
  '/forms': <MdListAlt />,
  '/tasks': <MdFactCheck />,
};

export default function useSidebarLinks(viewer: ViewerData) {
  let links = viewer?.availableTabs?.map((tab) => ({
    translationKey: tab.key,
    overrideLabel: tab.overrideLabel,
    href: tab.path,
    label: tab.label,
  }));

  const isCollegiate = viewer?.currentOrg?.isCollegiate;

  if (links.find((link) => link.href === '/timeManagement')) {
    links = links.map((link) => {
      if (link.href !== '/timeManagement') return link;
      return {
        ...link,
        label: isCollegiate ? 'Compliance' : 'Admin',
        translationKey: isCollegiate ? 'compliance' : 'admin',
      };
    });
  }

  if (
    links.find((link) => link.href === '/profiles/me') &&
    viewer.availableTeams.some((team) => team.canViewOtherUsers)
  ) {
    links = links.map((link) => {
      if (link.href !== '/profiles/me') {
        return link;
      }
      return {
        href: '/profiles',
        label: 'Profiles',
        overrideLabel: null,
        translationKey: 'profiles',
      };
    });
  }

  return links.map((link) => ({
    to: link.href,
    name:
      link.overrideLabel ??
      getTranslation(`navigation.tabs.${link.translationKey}` as TranslationKey),
    icon: icons[link.href],
  }));
}
