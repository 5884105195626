import { createContext, useContext } from 'react';
import * as React from 'react';
import { TWPolyglot } from './@dictionary';

const i18nContext = createContext<TWPolyglot>(null);

interface TranslatorProviderProps {
  translator: TWPolyglot;
  children: React.ReactNode;
}

const TranslatorProvider: React.FC<TranslatorProviderProps> = ({ translator, children }) => (
  <i18nContext.Provider value={translator}>{children}</i18nContext.Provider>
);

const TranslatorConsumer = i18nContext.Consumer;
const useTranslator = () => useContext<TWPolyglot>(i18nContext);

export { TranslatorProvider, TranslatorConsumer, useTranslator };
