const $twPrimaryTabs = {
  backgroundColor: '$colorHue.white',
  iconSize: 26,
  iconColorActive: '$brandColor.primaryColor',
  iconColorInactive: '$colorHue.grayBlue',
  labelColorActive: '$brandColor.primaryColor',
  // Note that there is no labelColorInactive, since we don't show inactive labels
  labelFontSize: 11,
  labelFontWeight: 'bold',
  paddingTop: 3,
};

export { $twPrimaryTabs };
