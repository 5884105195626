import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { SelectionItemInterface } from '@tw/components/utils';
import { GroupType } from '@tw/generated';
import { useDebouncedCallback } from '@tw/hooks';

interface UserSingleSelectProps {
  initialValue?: SelectionItemInterface;
  defaultGroupTypeFilter?: GroupType;
  onChange: (selectionItem: SelectionItemInterface) => void;
}

export interface SingleSelectManagementInterface {
  // filters
  groupTypeFilter: GroupType;
  searchFilter: string;
  // event handlers
  handleSearchFilterChange: ({ target: { value } }: { target: { value: string } }) => void;
  handleSetGroupTypeFilter: ({ target: { value } }: { target: { value: GroupType } }) => void;
  handleOnItemClick: (selectionItem: SelectionItemInterface) => void;
  // selection state
  selectedItem: SelectionItemInterface;
  setSelectedItem: (selectionItem: SelectionItemInterface) => void;
}

const useSingleSelect = ({
  defaultGroupTypeFilter,
  onChange,
  initialValue = null,
}: UserSingleSelectProps): SingleSelectManagementInterface => {
  const [groupTypeFilter, setGroupTypeFilter] = useState(defaultGroupTypeFilter);
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedItem, setSelectedItem] = useState(initialValue);
  const debouncedSetSearchFilter = useDebouncedCallback(setSearchFilter, 350);

  // Make sure the input value is set if an initial value is provided
  useEffectOnce(() => {
    if (selectedItem) {
      onChange(selectedItem);
    }
  });

  // =================================================
  // EVENT HANDLERS

  const handleSetGroupTypeFilter = ({ target: { value } }): void => {
    setGroupTypeFilter(value);
  };

  const handleSearchFilterChange = ({ target: { value } }): void => {
    debouncedSetSearchFilter(value);
  };

  const handleOnItemClick = (selectionItem: SelectionItemInterface): void => {
    setSelectedItem(selectionItem);
    onChange(selectionItem);
  };

  return {
    // filters
    groupTypeFilter,
    searchFilter,
    // event handlers
    handleSearchFilterChange,
    handleSetGroupTypeFilter,
    handleOnItemClick,
    // selection state
    setSelectedItem,
    selectedItem,
  };
};

export default useSingleSelect;
