/* eslint-disable react/jsx-props-no-spreading */
import { ApolloError, ApolloQueryResult, useApolloClient } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';

import { SelectionItemInterface } from '@tw/components/utils';
import { GroupType, UserSelectionOptionsDocument, UserSelectionOptionsQuery } from '@tw/generated';
import { useViewer } from '@tw/hooks';
import {
  UserSelectionQueryFilters,
  useUserSelection,
} from '@tw/hooks/useUserSelection/useUserSelection';

import TWUserSelectionFullMulti from '../../TWUserSelectionFullMulti';
import useMultiSelect from '../../useMultiSelect';

interface TWUserSelectionInputFullMultiProps {
  testID: string;
  canSwitchTeams?: boolean;
  disableSwitchTeams?: boolean;
  individualsOnly?: boolean;
  defaultGroupTypeFilter?: GroupType;
  onChange?: (selections: SelectionItemInterface[]) => void;
  onCheckedSelectionsChange?: (checkedSelectionsList: SelectionItemInterface[]) => void;
  queryFilters?: UserSelectionQueryFilters;
  error?: ApolloError;
  visible?: boolean;
  value?: SelectionItemInterface[];
  alwaysSelectedCodes?: string[];
  hideAlwaysSelected?: boolean;
  disabledGroupTypes?: GroupType[];
  withTeamMembership?: boolean;
  includesAllTeams?: boolean;
  teamId: string | null | undefined;
  teamFilter: string;
  setCommonTeams: (teams: string[]) => void;
  setTeamId: (teamId: string | number | null | undefined) => void;
  setTeamFilter: (teamId: string) => void;
}

const TWUserSelectionInputFullMulti = ({
  canSwitchTeams = false,
  defaultGroupTypeFilter = GroupType.Type,
  disableSwitchTeams = false,
  disabledGroupTypes = [],
  individualsOnly = false,
  onChange,
  queryFilters = {},
  setCommonTeams,
  setTeamId,
  setTeamFilter,
  teamId,
  teamFilter,
  withTeamMembership = false,
  includesAllTeams = true,
  value = [],
  alwaysSelectedCodes,
  hideAlwaysSelected,
}: TWUserSelectionInputFullMultiProps) => {
  const viewer = useViewer();
  // map of selection codes to team ids
  const [teamsBySelectionCodes, setTeamsBySelectionCodes] = useState<Record<string, Set<string>>>(
    {},
  );

  // used for fetching user selection options in parallel when aggressively prefetching team ids
  const client = useApolloClient();
  const [hasPrefetched, setHasPrefetched] = useState(false);

  const { loading, error, data } = useUserSelection({
    options: {
      teamFilter,
      withTeamMembership,
      ...queryFilters,
    },
  });

  const modalMultiSelectManagementProps = useMultiSelect({
    defaultGroupTypeFilter,
    onChange,
    selections: value,
    queryFilters,
  });

  const includesCustomGroups =
    'includeCustomGroups' in queryFilters ? queryFilters.includeCustomGroups : true;

  // maps groups (and their selection codes) to teams and stores them in state
  const mapTeamsByCode = (groups: UserSelectionOptionsQuery['groups']) => {
    setTeamsBySelectionCodes((current) => {
      groups?.forEach((group) => {
        if (group?.selectionCode) {
          const team = group.selectionCode.split('_')[1];
          if (group.selectionCode in current) {
            current[group.selectionCode].add(team);
          } else {
            current[group.selectionCode] = new Set([team]);
          }

          if (group.people) {
            group.people.forEach((person) => {
              if (person?.selectionCode) {
                if (person.selectionCode in current) {
                  current[person.selectionCode].add(team);
                } else {
                  current[person.selectionCode] = new Set([team]);
                }
              }
            });
          }
        }
      });
      return { ...current };
    });
  };

  // lazy load teams by selection code when creating a new group
  useEffect(() => {
    if (data?.groups && !hasPrefetched) {
      mapTeamsByCode(data.groups);
    }
  }, [data, hasPrefetched]);

  // aggressively load teams by selection code when editing an across-teams group
  useEffect(() => {
    // fetch selection codes for each team in parallel
    const prefetchSelectionCodesFromTeams = async () => {
      // we return a list of promises to be resolved in parallel
      const requests: Promise<ApolloQueryResult<UserSelectionOptionsQuery>>[] =
        viewer.availableTeams.map((team) =>
          client.query({
            query: UserSelectionOptionsDocument,
            variables: {
              filters: {
                includeCustomGroups: false,
                selectedTeam: team.id,
              },
            },
            // this is an expensive request that doesn't change often
            fetchPolicy: 'cache-first',
          }),
        );

      try {
        // wait for all the requests to finish
        const responses = await Promise.all(requests);
        // then flatten the groups into a single list
        const groups = responses.reduce<NonNullable<UserSelectionOptionsQuery['groups']>>(
          (acc, response) => [...acc, ...(response.data?.groups || [])],
          [],
        );
        // finally, build our stateful map of selection codes to teams
        mapTeamsByCode(groups);
        setHasPrefetched(true);
      } catch (err) {
        datadogRum.addError(err);
      }
    };

    const shouldPrefetch = teamId === null || teamFilter === viewer.currentOrg.allTeamsTeamId;

    if (shouldPrefetch && !hasPrefetched) {
      prefetchSelectionCodesFromTeams();
    }
  }, [client, hasPrefetched, teamId, teamFilter, viewer]);

  // when the selection codes or selection codes to teams map changes, we need to
  // update our assessment of the common teams for the selected items
  useEffect(() => {
    const selectionCodes: string[] = Object.keys(
      modalMultiSelectManagementProps.selectionItemsByCode,
    );

    const teamsByCode = selectionCodes.map((selectionCode) => teamsBySelectionCodes[selectionCode]);

    const userTeamsAreLoaded = Object.values(teamsByCode).every((teams) => teams !== undefined);
    if (userTeamsAreLoaded) {
      if (selectionCodes.length === 0) {
        setCommonTeams([]);
      } else {
        // get the intersection of the current commonTeams set and the new set
        const allTeamsId = viewer.currentOrg.allTeamsTeamId.toString();
        const commonTeams = teamsByCode.reduce<Set<string>>(
          (acc, x) => new Set(Array.from(acc).filter((i) => x.has(i))),
          teamsByCode[0],
        );

        if (commonTeams.has(allTeamsId)) {
          commonTeams.delete(allTeamsId);
        }

        setCommonTeams(Array.from(commonTeams));
      }
    }

    if (selectionCodes.length === 0 && teamId !== null) {
      setTeamId(undefined);
    } else if (teamId === viewer.currentOrg?.allTeamsTeamId) {
      setTeamId(null);
    } else if (teamId !== null) {
      setTeamId(teamFilter);
    }
  }, [
    modalMultiSelectManagementProps.selectionItemsByCode,
    teamsBySelectionCodes,
    setCommonTeams,
    setTeamId,
    teamId,
    teamFilter,
    viewer,
  ]);

  return (
    <TWUserSelectionFullMulti
      setTeamFilter={setTeamFilter}
      teamFilter={teamFilter}
      loading={loading}
      data={data}
      error={error}
      individualsOnly={individualsOnly}
      canSwitchTeams={canSwitchTeams}
      disableSwitchTeams={disableSwitchTeams}
      disabledGroupTypes={disabledGroupTypes}
      includeCustomGroups={includesCustomGroups}
      includesAllTeams={includesAllTeams}
      alwaysSelectedCodes={alwaysSelectedCodes}
      hideAlwaysSelected={hideAlwaysSelected}
      {...modalMultiSelectManagementProps}
    />
  );
};

export default TWUserSelectionInputFullMulti;
