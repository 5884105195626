import { shim as hasOwnShim } from 'object.hasown';
import { shim as arrayAtShim } from 'array.prototype.at';

// Polyfill for older Safari browsers
if (!Object.hasOwn) {
  hasOwnShim();
}
if (!Array.prototype.at) {
  arrayAtShim();
}
