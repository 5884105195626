import {
  Button,
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@teamworksdev/react';
import { getTranslation } from '@tw/i18n';
import { DOMUtils } from '@tw/util';
import { SessionTimeoutDialogContent } from './SessionTimeout.styles';

type SessionTimeoutLoginModalProps = {
  onAcknowledged: () => void;
  visible: boolean;
};

const SessionTimeoutLoginModal = ({ visible, onAcknowledged }: SessionTimeoutLoginModalProps) => {
  const container = DOMUtils.getContainer('react-root');
  return (
    <Dialog open={visible}>
      <SessionTimeoutDialogContent container={container}>
        <DialogHeader>
          <DialogTitle>{getTranslation('authentication.sessionExpiredTitle')}</DialogTitle>
          <DialogDescription>
            {getTranslation('authentication.sessionExpiredBody')}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button data-testid="Auth:SessionTimeoutKeepAliveButton" onClick={onAcknowledged}>
            {getTranslation('authentication.confirmStillActive')}
          </Button>
        </DialogFooter>
      </SessionTimeoutDialogContent>
    </Dialog>
  );
};

export default SessionTimeoutLoginModal;
