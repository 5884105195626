import { datadogRum } from '@datadog/browser-rum';
import _ from 'lodash';
import fastMemoize from 'fast-memoize';

import DebugLogger from '../DebugLogger';

// Set this to global.__DEV__ for debugging
const debugLogger = new DebugLogger({
  debugEnabled: false,
  debugHistoryEnabled: false,
  debugLabel: 'modelConverter memoization',
});

/**
 * This is the main entry point: specify the ID field (or fields) to use as the model's primary key,
 * and provide a convert function, and the convert function will only re-run if the model gets updated.
 * This lets us avoid creating new objects in redux (and, subsequently, new props)
 *
 * @param {String|Array} idField
 * @param {Function} convertFn
 * @return {Function}
 */
const memoizeById = (idField, convertFn) => {
  if (convertFn.length > 1) {
    debugLogger.error(
      "It looks like you're trying to memoizeById when memoizeByIdAndArgs would work better",
    );
  }

  if (_.isString(idField)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return memoizeByIdOnly(idField, convertFn);
  }

  if (_.isArray(idField)) {
    // Syntactic sugar: we'll just treat all other idFields as though they're custom args to the convertFn
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const variadicMemoizedConvertFn = memoizeByIdAndArgs(idField[0], convertFn);
    const additionalIdFields = idField.slice(1);
    return (modelData) => {
      const additionalIdValues = additionalIdFields.map((fieldName) => modelData[fieldName]);
      return variadicMemoizedConvertFn(modelData, ...additionalIdValues);
    };
  }
  // Else: for backwards compatibility, if we aren't given a valid idField then we'll default to
  // fastMemoize, which works fine but just consumes more memory as models change.
  debugLogger.error(`Invalid idField: "${idField}"`);
  datadogRum.addError(
    new Error(`Invalid fieldId for modelConverterUtils.memoizeById: "${idField}"`),
  );
  return fastMemoize(convertFn);
};

// Optimization: if we have a timestamp to tell us when things are different, use that.
// Else we'll just use json.stringify like fastMemoize does.
const getHashForModelData = (modelData) => {
  const hashString = modelData.updatedDate || modelData.updated_date || JSON.stringify(modelData);
  if (debugLogger.isEnabled() && !_.isString(hashString)) {
    debugLogger.error('modelData has a non-string updated date: ', modelData);
    return JSON.stringify(modelData);
  }
  return hashString;
};

/**
 * This works sort of like a two-cache version of fastMemoize's serialization and cache setup:
 * we only care about the model ID as a cache key, but when the model's contents change we need
 * to generate a new object for it.
 *
 * The main goal here is to not create new object instances when already-seen data arrives:
 * if the 'new' object is the same as the old we'll just reuse the old.
 *
 * @param {String|Array} idField
 * @param {Function} convertFn
 * @return {Function}
 */
const memoizeByIdOnly = (idField, convertFn) => {
  // Simple case: all lookups are just direct access. This is the common case so it's optimized for,
  // even though there's some duplication between the id-and-args case and this.
  const hashCache = Object.create(null);
  const modelCache = Object.create(null);

  return (modelData) => {
    if (modelData == null) {
      debugLogger.error(`modelData is blank, when we expected "${idField}": `, modelData);
      return modelData;
    }

    const modelId = _.get(modelData, idField);
    if (modelId == null) {
      debugLogger.error(`modelData doesn't include "${idField}": `, modelData);
      return convertFn(modelData);
    }

    const existingHash = hashCache[modelId];
    const newHash = getHashForModelData(modelData);

    if (existingHash === newHash) {
      return modelCache[modelId];
    }
    // else: cache miss!

    // This snippet is useful for debugging: this should only happen when the model's data changes.
    if (debugLogger.isEnabled() && existingHash) {
      debugLogger.log(`Cache rejected for ${idField}=${modelId}!`, {
        oldHash: existingHash,
        newHash,
        cachedModel: modelCache[modelId],
        incomingData: modelData,
      });
    }

    const convertedModelData = convertFn(modelData);
    hashCache[modelId] = newHash;
    modelCache[modelId] = convertedModelData;
    return convertedModelData;
  };
};

/**
 * This works the same as memoizeByIdOnly, except we'll also treat the convert function's second arg
 * (and third, etc) as keys to memoize on. The use case for this is calendar events, where we
 * need to trigger a change when timeZone changes.
 *
 * Note that ONLY PRIMITIVES are supported for the additional args.
 *
 * @param {String} idField
 * @param {Function} convertFn
 * @return {Function}
 */
const memoizeByIdAndArgs = (idField, convertFn) => {
  const hashCache = Object.create(null);
  const modelCache = Object.create(null);

  return (modelData, ...additionalArgs) => {
    const modelId = _.get(modelData, idField);
    if (modelId == null) {
      debugLogger.error(`modelData doesn't include "${idField}": `, modelData);
      return convertFn(modelData);
    }

    // walk the chain to find the existing hash, if any. It'll be N steps deep into hashCache.
    let stepNumber = 0;
    let hashCacheForStep = hashCache[modelId];
    // To avoid repeating the drilldown in modelCache, we'll create a single-level key for it
    while (hashCacheForStep && stepNumber < additionalArgs.length) {
      const additionalArgValue = additionalArgs[stepNumber];
      if (additionalArgValue == null) {
        debugLogger.error('Given an empty additionalArg: ', modelData, additionalArgs);
        return convertFn(modelData);
      }
      hashCacheForStep = hashCacheForStep[additionalArgValue];
      stepNumber += 1;
    }

    // We *should* have a hash in hashCacheForStep now -- but if we're still in the cache itself
    // then it means not enough additionalArgs were provided.
    if (hashCacheForStep && _.isPlainObject(hashCacheForStep)) {
      debugLogger.error('Not given all the additionalArgs expected: ', modelData, additionalArgs);
      return convertFn(modelData);
    }

    const existingHash = hashCacheForStep;
    const newHash = getHashForModelData(modelData);

    // To avoid a second set of drill-downs, once we're certain that the keys in additionalArgs are
    // good we'll collapse them into a single key, so that modelCache can be single-layer.
    // DANGER: This can get key collisions if the additionalArgs include "@~@".
    const modelCacheKey = `${modelId}@~@${additionalArgs.join('@~@')}`;

    if (existingHash === newHash) {
      return modelCache[modelCacheKey];
    }
    // else cache miss!

    // This snippet is useful for debugging: this should only happen when the model's data changes.
    if (debugLogger.isEnabled() && existingHash) {
      debugLogger.log(
        `Cache rejected for ${idField}=${modelId} and ${additionalArgs.join(', ')}!`,
        {
          oldHash: existingHash,
          newHash,
          cachedModel: modelCache[modelCacheKey],
          incomingData: modelData,

          modelCache,
          modelCacheKey,
          hashCache,
        },
      );
    }

    const convertedModelData = convertFn(modelData, ...additionalArgs);
    hashCache[modelId] = _.set(hashCache[modelId] || {}, additionalArgs, newHash);
    modelCache[modelCacheKey] = convertedModelData;
    return convertedModelData;
  };
};

export default {
  memoizeById,
  memoizeByIdOnly,
  memoizeByIdAndArgs,
};
