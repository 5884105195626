/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveAppointmentCheckinActionNoteMutationVariables = Types.Exact<{
  input: Types.AppointmentCheckinActionNoteInput;
}>;

export type SaveAppointmentCheckinActionNoteMutation = {
  saveAppointmentCheckinActionNote: { appointmentCheckinActionId?: any | null };
};

export const SaveAppointmentCheckinActionNoteDocument = gql`
  mutation SaveAppointmentCheckinActionNote($input: AppointmentCheckinActionNoteInput!) {
    saveAppointmentCheckinActionNote(input: $input) {
      appointmentCheckinActionId
    }
  }
`;
export type SaveAppointmentCheckinActionNoteMutationFn = Apollo.MutationFunction<
  SaveAppointmentCheckinActionNoteMutation,
  SaveAppointmentCheckinActionNoteMutationVariables
>;

/**
 * __useSaveAppointmentCheckinActionNoteMutation__
 *
 * To run a mutation, you first call `useSaveAppointmentCheckinActionNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAppointmentCheckinActionNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAppointmentCheckinActionNoteMutation, { data, loading, error }] = useSaveAppointmentCheckinActionNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAppointmentCheckinActionNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAppointmentCheckinActionNoteMutation,
    SaveAppointmentCheckinActionNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveAppointmentCheckinActionNoteMutation,
    SaveAppointmentCheckinActionNoteMutationVariables
  >(SaveAppointmentCheckinActionNoteDocument, options);
}
export type SaveAppointmentCheckinActionNoteMutationHookResult = ReturnType<
  typeof useSaveAppointmentCheckinActionNoteMutation
>;
export type SaveAppointmentCheckinActionNoteMutationResult =
  Apollo.MutationResult<SaveAppointmentCheckinActionNoteMutation>;
export type SaveAppointmentCheckinActionNoteMutationOptions = Apollo.BaseMutationOptions<
  SaveAppointmentCheckinActionNoteMutation,
  SaveAppointmentCheckinActionNoteMutationVariables
>;
