import { Form, Input } from 'antd';
import { useState } from 'react';
import { useViewer } from '@tw/hooks';
import session from '@tw/services/session';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogFooter,
} from '@teamworksdev/react';
import { getTranslation } from '@tw/i18n';
import { TWMessage } from '@tw/components';
import { DOMUtils } from '@tw/util';
import { SessionTimeoutDialogContent } from './SessionTimeout.styles';

type FormValues = {
  password: string;
};

type SessionTimeoutLoginModalProps = {
  onAuthenticated: () => void;
  visible: boolean;
};

const SessionTimeoutLoginModal = ({ visible, onAuthenticated }: SessionTimeoutLoginModalProps) => {
  const { username } = useViewer();
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values: FormValues) => {
    setIsLoading(true);
    const { password } = values;
    if (password) {
      session
        .loginWithOktaUsernamePassword(username, password)
        .then(() => {
          form.setFieldValue('password', '');
          onAuthenticated();
        })
        .catch((e) => {
          TWMessage.errorHandler(e, getTranslation('authentication.invalidCredentials'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const container = DOMUtils.getContainer('react-root');

  return (
    <Dialog open={visible}>
      <SessionTimeoutDialogContent container={container}>
        <DialogHeader>
          <DialogTitle>{getTranslation('authentication.loggedOutInactivityHeading')}</DialogTitle>
          <DialogDescription>
            {getTranslation('authentication.loggedOutInactivityBody')}
          </DialogDescription>
        </DialogHeader>
        <DialogBody>
          <Form
            form={form}
            autoComplete="off"
            initialValues={{
              username,
              person: '',
            }}
            onFinish={onSubmit}
          >
            <Form.Item
              name="username"
              label={getTranslation('username')}
              rules={[{ required: true, message: getTranslation('requiredField') }]}
            >
              <Input disabled value={username} />
            </Form.Item>
            <Form.Item
              name="password"
              label={getTranslation('password')}
              rules={[{ required: true, message: getTranslation('requiredField') }]}
            >
              <Input
                autoComplete="off"
                autoFocus
                // This doesn't seem like the right test id -- but it's what is currently there
                data-testid="People:AddProfile:AddProfileStartPage:emailAddress"
                type="password"
              />
            </Form.Item>
          </Form>
        </DialogBody>
        <DialogFooter>
          <Button
            data-testid="Auth:SessionTimeoutLoginButton"
            isLoading={isLoading}
            isDisabled={isLoading}
            onClick={form.submit}
            type="submit"
          >
            {getTranslation('login')}
          </Button>
        </DialogFooter>
      </SessionTimeoutDialogContent>
    </Dialog>
  );
};

export default SessionTimeoutLoginModal;
