import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { TWButtonIconFlat, TWFlexContainer } from '@tw/components/presentational';

export const UsersBtn = styled(TWButtonIconFlat)({
  padding: 0,
});

export const UsersContainer = styled(TWFlexContainer)(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  padding: 0,
  textAlign: 'right',
  svg: {
    backgroundColor: theme.colors.iconBackground,
    borderRadius: '14px',
    marginRight: '6px',
    padding: '4px',
    verticalAlign: 'middle',
  },
}));

export const UsersText = styled.span(({ theme }) => ({
  color: `${theme.colors.link} !important`,
  textDecoration: 'underline',
  textUnderlineOffset: '2px',
}));

export const PopoverBody = styled.div({
  maxHeight: 165,
  overflowY: 'auto',
});

export const PopoverContainer = styled(Col)({
  height: '200px',
  width: '200px',
});

export const PopoverHeader = styled(Row)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.borderGray}`,
  padding: '4 8px 4px',
  marginBottom: '8px',
  fontSize: '14px',
  fontWeight: 600,
}));

export const PopoverSpinnerContainer = styled(Row)({
  height: '165px',
});
