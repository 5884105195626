/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupsAdminQueryVariables = Types.Exact<{
  signupId?: Types.InputMaybe<Types.Scalars['UUID']>;
  mySignupsOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type SignupsAdminQuery = {
  signups?: {
    edges: Array<{
      node?: {
        signupId: string;
        title: string;
        canEdit?: boolean | null;
        isPrivate?: boolean | null;
        location?: string | null;
        mandatory?: boolean | null;
        timezone?: string | null;
        recurrence?: string | null;
        slotCapacity?: number | null;
        slotDuration?: string | null;
        slotInterval?: string | null;
        firstStartDatetime?: any | null;
        lastEndDatetime?: any | null;
        firstStartTime?: any | null;
        lastEndTime?: any | null;
        firstDate?: any | null;
        lastDate?: any | null;
        creator?: { displayName?: string | null } | null;
        slotDates?: Array<{
          date?: any | null;
          isFull?: boolean | null;
          isEmpty?: boolean | null;
        } | null> | null;
        reservingPersonGroups?: Array<{
          isOpen?: boolean | null;
          visibility?: Array<string | null> | null;
          persons?: Array<{
            initials?: string | null;
            personId?: number | null;
            pictureUrl?: string | null;
            selectionCode?: string | null;
            id?: string | null;
            label?: string | null;
          } | null> | null;
        } | null> | null;
        reservablePersons?: Array<{
          selectionCode?: string | null;
          id?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const SignupsAdminDocument = gql`
  query SignupsAdmin($signupId: UUID, $mySignupsOnly: Boolean) {
    signups(signupId: $signupId, mySignupsOnly: $mySignupsOnly) {
      edges {
        node {
          signupId
          title
          canEdit
          isPrivate
          location
          mandatory
          timezone
          recurrence
          slotCapacity
          slotDuration
          slotInterval
          firstStartDatetime
          lastEndDatetime
          firstStartTime
          lastEndTime
          firstDate
          lastDate
          creator {
            displayName
          }
          slotDates {
            date
            isFull
            isEmpty
          }
          reservingPersonGroups {
            isOpen
            visibility
            persons {
              id: pk
              initials
              label: displayName
              personId
              pictureUrl
              selectionCode
            }
          }
          reservablePersons {
            id: pk
            selectionCode
          }
        }
      }
    }
  }
`;

/**
 * __useSignupsAdminQuery__
 *
 * To run a query within a React component, call `useSignupsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupsAdminQuery({
 *   variables: {
 *      signupId: // value for 'signupId'
 *      mySignupsOnly: // value for 'mySignupsOnly'
 *   },
 * });
 */
export function useSignupsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<SignupsAdminQuery, SignupsAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignupsAdminQuery, SignupsAdminQueryVariables>(
    SignupsAdminDocument,
    options,
  );
}
export function useSignupsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SignupsAdminQuery, SignupsAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignupsAdminQuery, SignupsAdminQueryVariables>(
    SignupsAdminDocument,
    options,
  );
}
export type SignupsAdminQueryHookResult = ReturnType<typeof useSignupsAdminQuery>;
export type SignupsAdminLazyQueryHookResult = ReturnType<typeof useSignupsAdminLazyQuery>;
export type SignupsAdminQueryResult = Apollo.QueryResult<
  SignupsAdminQuery,
  SignupsAdminQueryVariables
>;
