/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttendeeNamesQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  appointmentId?: Types.InputMaybe<Types.Scalars['TWFilter_ID']>;
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type AttendeeNamesQuery = {
  allAppointments?: {
    edges: Array<{
      node?: {
        appointmentId: string;
        reminders?: Array<{
          offsetMinutes?: number | null;
          reminderType?: string | null;
        } | null> | null;
        attendees?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              preferredName?: string | null;
              lastName?: string | null;
              label?: string | null;
              selectionCode?: string | null;
            }
          | {
              groupType?: Types.GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                preferredName?: string | null;
                lastName?: string | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export const AttendeeNamesDocument = gql`
  query AttendeeNames(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $appointmentId: TWFilter_ID
    $selections: [String]!
  ) {
    allAppointments(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      appointmentId: $appointmentId
      selections: $selections
    ) {
      edges {
        node {
          appointmentId
          reminders {
            offsetMinutes
            reminderType
          }
          attendees {
            label
            selectionCode
            ... on PersonNode {
              preferredName
              lastName
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                preferredName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAttendeeNamesQuery__
 *
 * To run a query within a React component, call `useAttendeeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeNamesQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      appointmentId: // value for 'appointmentId'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useAttendeeNamesQuery(
  baseOptions: Apollo.QueryHookOptions<AttendeeNamesQuery, AttendeeNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendeeNamesQuery, AttendeeNamesQueryVariables>(
    AttendeeNamesDocument,
    options,
  );
}
export function useAttendeeNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendeeNamesQuery, AttendeeNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendeeNamesQuery, AttendeeNamesQueryVariables>(
    AttendeeNamesDocument,
    options,
  );
}
export type AttendeeNamesQueryHookResult = ReturnType<typeof useAttendeeNamesQuery>;
export type AttendeeNamesLazyQueryHookResult = ReturnType<typeof useAttendeeNamesLazyQuery>;
export type AttendeeNamesQueryResult = Apollo.QueryResult<
  AttendeeNamesQuery,
  AttendeeNamesQueryVariables
>;
