import { ForwardedRef, forwardRef, SVGProps } from 'react';

import TWIconCustom from './TWIconCustom';
import TWIconMaterial from './TWIconMaterial';

export interface TWIconProps extends SVGProps<SVGSVGElement> {
  type: string;
  testID?: string;
  title?: string;
  onClick?: () => void;
}

const TWIcon = forwardRef<SVGSVGElement | HTMLSpanElement, TWIconProps>((props, ref) => {
  const { type: prefixedType, testID, ...allUnrecognizedProps } = props;

  const match = /^(tw-|material-)(.+)/.exec(prefixedType);
  const prefix = match ? match[1] : '';
  const type = match ? match[2] : prefixedType;

  const iconProps = {
    type,
    testID,
    'aria-label': `icon: ${type}`,
    ...allUnrecognizedProps, // Allows unrecognized props to override aria label
  };

  const iconTwProps = {
    viewBox: '0 0 24 24',
    ...iconProps,
  };

  let icon;
  if (prefix === 'tw-') {
    // eslint-disable-next-line react/jsx-props-no-spreading
    const svgRef = ref as ForwardedRef<SVGSVGElement>;
    icon = <TWIconCustom {...iconTwProps} ref={svgRef} />;
  } else if (prefix === 'material-') {
    const spanRef = ref as ForwardedRef<HTMLSpanElement>;
    icon = <TWIconMaterial {...iconProps} ref={spanRef} />;
  } else {
    const spanRef = ref as ForwardedRef<HTMLSpanElement>;
    console.warn('You are using an unrecognized icon! Please check your icon props: ', iconProps);
    icon = <TWIconMaterial type="question_mark" ref={spanRef} />;
  }
  return icon;
});

export default TWIcon;
