import styled from '@emotion/styled';
import ReactPlayer from 'react-player';
import { useEffect, useState } from 'react';
import { Spinner } from '@teamworksdev/react';
import { SerializedStyles, css, useTheme } from '@emotion/react';

import { getTranslation } from '@tw/i18n';

import { MediaStatus, VideoPlayerProps } from '../Videos.definitions';
import { getWistiaMediaData } from '../util/wistia';

const VideoPlayer = ({
  video,
  onVideoReady,
  onVideoPlay,
  height,
  width,
  autoplay = false,
  responsive = true,
}: VideoPlayerProps) => {
  const [mediaStatus, setMediaStatus] = useState<MediaStatus | null>(null);
  const [progress, setProgress] = useState(1);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  const theme = useTheme();

  const isReady = mediaStatus === 'ready';

  // Wistia media may still be processing. Check status until ready.
  useEffect(() => {
    let itvl: NodeJS.Timeout;

    if (video.wistiaVideoUuid && !isReady) {
      const trackWistiaMediaStatus = () =>
        new Promise<void>((resolve) => {
          itvl = setInterval(async () => {
            if (video.wistiaVideoUuid) {
              const res = await getWistiaMediaData(video.wistiaVideoUuid);

              if (res.status === 'ready') {
                setMediaStatus('ready');
                setProgress(1);
                onVideoReady?.(res);
                resolve();
                clearInterval(itvl);
              } else {
                setMediaStatus(res.status);
                setProgress(res.progress);
              }
            }
          }, 3000); // Check every 3 seconds
        });

      trackWistiaMediaStatus();
    }

    return () => {
      clearInterval(itvl);
    };
  }, [isReady, onVideoReady, video.wistiaVideoUuid]);

  const isProcessing = mediaStatus === 'processing' && progress < 1;

  let statusText = !isReady && mediaStatus ? getTranslation(mediaStatus) : null;

  if (isProcessing) {
    statusText += ` ${Math.round(progress * 100)}%`;
  }

  return (
    <Wrapper
      style={{ width: isReady ? width : '100%', height }}
      responsive={responsive}
      aspectRatio={width / height}
    >
      {isReady && (
        <ReactPlayer
          className="react-player"
          style={{ opacity: isPlayerReady ? 1 : 0 }}
          url={`https://fast.wistia.com/medias/${video.wistiaVideoUuid}`}
          playing={autoplay}
          controls
          onPlay={() => {
            if ('videoId' in video && video.videoId) {
              onVideoPlay?.(video.videoId);
            }
          }}
          onReady={() => {
            setIsPlayerReady(true);
          }}
          width={responsive ? '100%' : width}
          height={responsive ? '100%' : height}
          config={{
            wistia: {
              options: {
                playerColor: theme.colors.slateBackground,
              },
            },
          }}
        />
      )}

      {!isReady && <p>{mediaStatus ? `${statusText}...` : <Spinner />}</p>}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ aspectRatio?: number; responsive?: boolean }>(
  ({ aspectRatio, responsive }) => {
    let responsiveStyles: SerializedStyles | undefined;

    if (responsive && aspectRatio) {
      responsiveStyles = css`
        padding-top: ${100 / aspectRatio}%;
        width: 100%;
        height: 0;

        .react-player {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      `;
    }

    return css`
      position: relative;
      overflow: hidden;

      ${responsiveStyles}

      .react-player {
        transition: opacity 150ms cubic-bezier(0, 0, 0.58, 1) 600ms; /* ease-out */
      }

      p {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        font-size: 2rem;
        transform: translate3d(0, -50%, 0);
        text-align: center;
        color: #fff;
      }
    `;
  },
);

export default VideoPlayer;
