import _ from 'lodash';
import { createSelector } from 'reselect';

import objectUtils from './objectUtils';
import serviceReducerUtils from './services/serviceReducerUtils';

/**
 * This does some basic sanity-checking to warn us if we haven't set up the initialState
 * for a 'layered' config properly. If you find yourself here it *probably* means you
 * added a new layer to the config without updating the reducer.
 *
 * @param {object} initialState
 */
function warnIfInitialStateLooksIncomplete(initialState) {
  // @TODO:
  // Every layer should have an 'attributes' and a 'values' key
  // There should not be any extra keys other than 'layerNames', the attributes, and the values.

  if (!_.isObject(initialState)) {
    console.warn('InitialStateLooksIncomplete!', initialState);
  }
}

/**
 * This returns a selector that will dereference all string theme variables
 * into raw values that can be used by both web and mobile platforms.
 *
 * @param {function} topmostSelector
 * @return {function} dereferencedValuesSelector
 */
const dereferencedValuesSelectorFactory = (topmostSelector) =>
  createSelector([topmostSelector], (topmostValues) => {
    const cachedLookup = {};

    const dereferenceOneValue = (valueItem) => {
      if (typeof valueItem === 'string' && valueItem.startsWith('$')) {
        if (!cachedLookup[valueItem]) {
          if (/[-+/*]/g.test(valueItem)) {
            cachedLookup[valueItem] = operationDereference(valueItem);
          } else {
            cachedLookup[valueItem] = _.get(topmostValues, valueItem);
          }
        }
        if (typeof cachedLookup[valueItem] === 'string' && valueItem.startsWith('$')) {
          // It dereferenced to another variable: keep going
          cachedLookup[valueItem] = dereferenceOneValue(cachedLookup[valueItem]);
        } else if (cachedLookup[valueItem] == null) {
          console.warn(`Value does not exist for dereference: ${valueItem}`);
          return null;
        }
        return cachedLookup[valueItem];
      }
      return valueItem;
    };

    const operationDereference = (valueItem: string) => {
      const operation = /^([^\s]+)\s*([-+/*])\s*([^\s]+)$/g.exec(valueItem);
      const leftHandValue = dereferenceOneValue(operation[1]);
      const operand = operation[2];
      const rightHandValue = dereferenceOneValue(operation[3]);
      switch (operand) {
        case '+':
          return Number(leftHandValue + rightHandValue);
        case '-':
          return Number(leftHandValue - rightHandValue);
        case '/':
          return Number(leftHandValue / rightHandValue);
        case '*':
          return Number(leftHandValue * rightHandValue);
        default:
          return valueItem;
      }
    };

    const recursiveDereference = (valueObject) =>
      _.mapValues(valueObject, (valueItem) => {
        if (_.isObjectLike(valueItem)) {
          return recursiveDereference(valueItem);
        }
        return dereferenceOneValue(valueItem);
      });

    return recursiveDereference(topmostValues);
  });

/**
 * This automatically creates all the generators we could ever need for accessing values from
 * a 'layered config'.
 * @TODO: A full docs/ document to explain all of this, with a link from here.
 *
 * @TODO: This belongs in util
 *
 * @param {array}   layerNames
 * @param {function}  storeRootSelector
 * @return {object} {topmostSelector, generatedSelectors}
 */
function generateSelectorsForConfigLayers(layerNames, storeRootSelector) {
  const generatedSelectors = {};

  // We're gonna use a reducer to track the last 'layer' that we made a selector for:
  // that makes the code a little simpler (since we just have `previousLayerSelector`
  // available as a normal var, without having to reassign it ourselves each time)
  const topmostSelector = layerNames.reduce(
    (previousLayerSelector, layerName) => {
      // Each layer has three selectors:
      // (1) attributes selector, which just returns the current layer's attributes
      const attributesSelector = createSelector(
        [storeRootSelector],
        (storeRoot) => storeRoot[`attributes_${layerName}`],
      );

      // (2) a values selector, which returns the current layer's values after piping them
      // through a sanitizer.
      // Note that we technically create two selectors for this, because we only want to run
      // cleanNullValuesFromObject if the source has actually changed. (We just don't save or
      // use rawValuesSelector anywhere other than here.)
      const rawValuesSelector = createSelector(
        [storeRootSelector],
        (storeRoot) => storeRoot[`values_${layerName}`],
      );

      // cleanNullValuesFromObject will remove nested empty objects, but we want to keep all the
      // top-level groups around -- so we'll only cleanNullValues from the groups instead of
      // from the entire set of values itself.
      const valuesSelector = createSelector([rawValuesSelector], (rawValues) => {
        if (rawValues && !_.isEmpty(rawValues)) {
          return _.mapValues(rawValues, (groupOfValues) => {
            if (_.isPlainObject(groupOfValues)) {
              return objectUtils.cleanNullValuesFromObject(groupOfValues);
            }
            // @TODO: All values in the config should live 2 layers deep, but today there are a few
            // that have values at the top level. We should standardize those spots up post-GA.
            // console.warn('Found a config value where we expect a group:', groupOfValues, rawValues);
            return groupOfValues;
          });
        }
        return rawValues;
      });

      // (3) an actual output selector where the magic happens:
      // this is where we use 'our' values to override the previous layer's values (where
      // appropriate), resulting in a selector that combines all the layers we've touched
      // so far. This becomes the next previousLayerSelector.
      const outputSelector = createSelector(
        [previousLayerSelector, attributesSelector, valuesSelector],
        (previousLayer, attributes, values) => {
          if (attributes.isEnabled && !_.isEmpty(values)) {
            return _.defaultsDeep({}, values, previousLayer);
          }
          // Else we look like the underlying layer
          return previousLayer;
        },
      );

      // Now we save everything.
      // Note that the caller will have to list the exact names it wants to export
      // (since you can't do dynamic export names for es6 modules)
      generatedSelectors[`${layerName}AttributesSelector`] = attributesSelector;
      generatedSelectors[`${layerName}ValuesSelector`] = valuesSelector;
      generatedSelectors[`${layerName}Selector`] = outputSelector;

      // and now this layer becomes the previous layer
      return outputSelector;
    },
    // This last param is the initial previousLayerSelector we pass to the reducer.
    // It *must* be a function (because it's a selector) but we want it to always return
    // the exact same value -- because otherwise Reselect will see a difference and it'll
    // re-run the next-to-bottom selector. And then, because we use _.defaultsDeep to get
    // a *new* object, that next-to-bottom selector will cause the *next* layer to be
    // re-calculated as well -- and the whole thing cascades in a way which would result
    // in invalidating ALL cached styles EVERYWHERE. This was not a fun issue to debug.
    () => null,
  );

  // Now, to make debugging easier, we're going to make a selector which combines all the
  // layers' attributes selectors together, and another one which combines all the
  // layers' value selectors together.
  generatedSelectors.allLayerAttributesSelector = createSelector(
    layerNames.map((layerName) => generatedSelectors[`${layerName}AttributesSelector`]),
    (...allAttributesForAllLayers) => {
      const allLayerAttributes = {};
      layerNames.forEach((layerName, index) => {
        allLayerAttributes[layerName] = allAttributesForAllLayers[index];
      });
      return allLayerAttributes;
    },
  );
  generatedSelectors.allLayerValuesSelector = createSelector(
    layerNames.map((layerName) => generatedSelectors[`${layerName}ValuesSelector`]),
    (...allValuesForAllLayers) => {
      const allLayerValues = {};
      layerNames.forEach((layerName, index) => {
        allLayerValues[layerName] = allValuesForAllLayers[index];
      });
      return allLayerValues;
    },
  );

  return { topmostSelector, generatedSelectors };
}

/**
 * It's a tiny bit awkward, but the mergeModelData function already does everything we need: we're just repurposing it.
 */
const mergeConfigLayerAttributes = (existingLayerValues, incomingLayerValues) =>
  serviceReducerUtils.mergeModelData(
    // (The arguments are backwards! There's a @FIXME about this in mergeModelData)
    incomingLayerValues,
    existingLayerValues,
  );

/**
 * This is like the merge helpers in serviceReducerUtils, except it operates deeply. All config values live at
 * the second level of the hierarchy, for consistency and sanity.
 */
const mergeConfigLayerValues = (existingLayerValues, incomingLayerValues) => {
  if (!existingLayerValues) {
    // Nothing to do: it's all new
    return incomingLayerValues;
  }
  if (_.isMatch(existingLayerValues, incomingLayerValues)) {
    // Nothing to do: there aren't any changes
    // We use isMatch instead of isEqual because it's fine for incoming values to not overwrite everything.
    return existingLayerValues;
  }
  return _.defaultsDeep({}, incomingLayerValues, existingLayerValues);
};

export default {
  warnIfInitialStateLooksIncomplete,
  generateSelectorsForConfigLayers,
  dereferencedValuesSelectorFactory,
  mergeConfigLayerAttributes,
  mergeConfigLayerValues,
};
