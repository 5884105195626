interface Pendo {
  initialize(options?: any): void;
}

declare global {
  interface Window {
    pendo: Pendo;
  }
}

// TODO: This isn't a secret but probably should be set with an env variable
const pendoKey = 'f1d00988-a88f-4750-6049-0857c4db97b7';

// We use the same api key for multiple environments in Pendo. To prevent
// aggregating data from multiple environments this will prefix non-prod visitor
// and account ids with the environment.
const maybePrefix = (id) =>
  process.env.DD_RUM_ENV === 'production' ? id : `${process.env.DD_RUM_ENV}:${id}`;

const loadPendo = () => {
  if (window.pendo) return;

  /* eslint-disable */
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = `https://cdn.pendo.io/agent/static/${pendoKey}/pendo.js`;
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
  /* eslint-enable */
};

const setMetadata = (viewerData) => {
  if (!window.pendo) return;

  const {
    person,
    currentTeam,
    currentOrg,
    currentMembership,
    isOrgSuperuser,
    isTeamSuperuser,
    personId,
    teamId,
    orgId,
  } = viewerData;

  window.pendo.initialize({
    visitor: {
      id: maybePrefix(personId),
      name: person.displayName,
      email: person.emailAddress,
      teamId,
      orgId,
      team: currentTeam.label,
      userTypes: currentMembership.userTypes.map(({ name }) => name),
      userTypeIds: currentMembership.userTypes.map(({ pk }) => Number(pk)),
      athleteStatus: currentMembership.athleteStatus?.label ?? '',
      isActiveAthlete: currentMembership.athleteStatus?.isActiveStatus ?? false,
      isOrgSuperuser,
      isTeamSuperuser,
    },

    account: {
      id: maybePrefix(teamId),
      name: `${currentOrg.label} (${currentTeam.label})`,
      sportId: currentTeam.sportId,
      teamId,
      orgId,
    },

    parentAccount: {
      id: maybePrefix(orgId),
      name: currentOrg.label,
      teamId: currentOrg.allTeamsTeamId,
      orgId,
      timeZone: currentOrg.timeZoneCode,
    },
  });
};

export default { loadPendo, setMetadata };
