import styled from '@emotion/styled';
import { Row, Col } from 'antd';

const Container = styled(Row)(({ theme }) => ({
  height: '100vh',
  background: theme.colors.background,
  fontFamily: 'Inter',
  input: {
    borderRadius: '6px',
  },
  button: {
    borderRadius: '6px',
  },
  '.ant-form-large .ant-form-item-label > label': {
    height: 20,
    fontSize: theme.fontUnit * 3,
    color: theme.colors.secondary,
  },
  '.separation-line': {
    borderTop: `1px solid ${theme.colors.border}`,
    width: '44%',
  },
  '.auth-footer-link': {
    fontWeight: theme.fontWeight.bold,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  '.auth-footer-back': {
    fontWeight: theme.fontWeight.bold,
    cursor: 'pointer',
    '&::before': {
      content: "'← '",
    },
  },
  '#marketing-panel': {
    height: '100%',
  },
  '#marketing-panel > video': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '6px',
  },
  '.ant-form-item-label>label': {
    fontWeight: theme.fontWeight.bold,
    color: theme.colors.black,
  },
}));

const ColumnContainer = styled(Col)({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const ButtonContainer = styled.div({
  button: {
    width: '100%',
    span: {
      width: '100%',
    },
  },
});

const TWLogoImage = styled.img(({ theme }) => ({
  height: theme.baseUnit * 6,
}));

const LinksSpace = styled.div({
  height: 100,
});

const FormContainer = styled.div({
  position: 'absolute',
  top: '20%',
});

export { TWLogoImage, Container, ColumnContainer, ButtonContainer, LinksSpace, FormContainer };
