import * as React from 'react';
import { Select } from 'antd';
import { getTranslation } from '@tw/i18n';

import { ProfessorSurveyAssignmentStatus } from '@tw/types';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect/TWInputSelect';

interface TWInputSelectSurveyReportStatusProps extends TWInputSelectProps {
  onChange: (reportStatus: ProfessorSurveyAssignmentStatus) => void;
}

const TWInputSelectSurveyReportStatus: React.FC<TWInputSelectSurveyReportStatusProps> = (props) => {
  const {
    popupContainerId = '',
    placeholder = getTranslation('forms.allReportStatuses'),
    onChange,
    defaultValue,
    testID,
    isMultiSelect,
  } = props;

  return (
    <TWInputSelect
      allowClear
      isMultiSelect={isMultiSelect}
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      loading={false}
      onChange={onChange}
      popupContainerId={popupContainerId}
      minWidth="200px"
      defaultValue={defaultValue}
      testID={testID}
    >
      <Select.Option
        value={ProfessorSurveyAssignmentStatus.completed}
        title={getTranslation('completed')}
      >
        {getTranslation('completed')}
      </Select.Option>
      <Select.Option
        value={ProfessorSurveyAssignmentStatus.inProgress}
        title={getTranslation('forms.formStatus.inProgress')}
      >
        {getTranslation('forms.formStatus.inProgress')}
      </Select.Option>
      <Select.Option
        value={ProfessorSurveyAssignmentStatus.pending}
        title={getTranslation('tutorsTab.attendanceStatus.pending')}
      >
        {getTranslation('tutorsTab.attendanceStatus.pending')}
      </Select.Option>
    </TWInputSelect>
  );
};

export default TWInputSelectSurveyReportStatus;
