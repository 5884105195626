import _ from 'lodash';
import { FetchResult, MutationFunctionOptions } from '@apollo/client';

import { useScript } from '@tw/hooks';

function useReCaptchaMutation<TData, TVariables>(
  mutation: (options?: MutationFunctionOptions<TData, TVariables>) => Promise<FetchResult<TData>>,
  action: string,
) {
  const recaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY;
  useScript(`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`);

  if (!recaptchaSiteKey) {
    console.warn('Using recaptcha hook but no RECAPTCHA_SITE_KEY is configured');
    return mutation;
  }

  const withRecaptcha = (mutationArgs: MutationFunctionOptions<TData, TVariables>) => {
    // eslint-disable-next-line consistent-return
    window.grecaptcha.ready(() => {
      try {
        window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
          const args = _.cloneDeep(mutationArgs);
          args.context = args.context || {};
          args.context.reCaptchaToken = token;
          return mutation(args);
        });
      } catch {
        return mutation(mutationArgs);
      }
    });
  };

  return withRecaptcha;
}

export default useReCaptchaMutation;
