import styled from '@emotion/styled';

import { TWButton } from '../../../presentational/buttons/TWButton';
import { TWInputTimePickerDayjs } from '../../../presentational/inputs/TWInputTimePickerDayjs';

export const AddTimesButton = styled(TWButton)(({ theme }) => ({
  marginTop: theme.baseUnit,
}));

export const TimePicker = styled(TWInputTimePickerDayjs)(({ theme }) => ({
  marginRight: theme.baseUnit,
  input: {
    width: 'auto',
  },
}));
