import styled from '@emotion/styled';
import { sharedSpacingStyles, SpacingComponentProps } from '../../../utils';

interface ContainerProps {
  isHighlighted: boolean;
}

const Container = styled.div<SpacingComponentProps & ContainerProps>(({ theme, isHighlighted }) => [
  sharedSpacingStyles,
  {
    display: 'flex',
    flex: 1,
    minWidth: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    userSelect: 'none',
    outline: isHighlighted ? `1px solid ${theme.colors.border}` : 'inherit',
    cursor: 'pointer',
    transition: theme.cssValues.transition,
    ':focus': {
      outline: 0,
      border: 'none',
      background: theme.colors.border,
    },
    ':hover': {
      backgroundColor: theme.colors.highlightBold,
    },
  },
]);

Container.defaultProps = {
  twPadding: [0.5, 1],
};

const IconRow = styled.div(({ theme }) => ({
  display: 'flex',
  '*:not(:last-child)': {
    marginRight: theme.baseUnit,
  },
}));

const ContentRow = styled.div(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexFlow: 'row',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  minWidth: 0,
  '*:not(:last-child)': {
    marginRight: theme.baseUnit * 2,
  },
}));

export { Container, IconRow, ContentRow };
