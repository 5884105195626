import styled from '@emotion/styled';
import { colors } from '@teamworksdev/style';

export const StyledWrapper = styled.div<{ displayLabel?: boolean }>(({ displayLabel }) => ({
  margin: displayLabel ? '0px 0px 24px' : '',
  padding: 25,
  color: '#555',
  backgroundColor: displayLabel ? colors.white : undefined,
  border: displayLabel ? `1px solid ${colors.light[300]}` : '',
  borderRadius: 8,
}));

export const StyledLabel = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeight.semibold,
  fontStyle: 'normal',
  textTransform: 'none',
  fontSize: '20px !important',
  marginBottom: '8px',
  lineHeight: '28px',
  letterSpacing: '-0.4px',
  textAlign: 'left',
  overflowWrap: 'anywhere',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const StyledDescription = styled.div(({ theme }) => ({
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: theme.fontWeight.regular,
  lineHeight: '18px',
  marginBottom: 12,
  letterSpacing: '-0.3px',
  textAlign: 'left',
  color: 'rgba(0, 0, 0, 0.6) !important',
}));
