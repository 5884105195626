import { useMemo } from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import {
  GraphQLPaginatedResponse,
  RawSession,
  SessionFilters,
  SessionRawQueryVariables,
} from '@tw/types';
import { sessionModel } from '@tw/services/academicService';

import { SESSION_DETAIL_QUERY } from '@tw/modules/Academics2/@graphql';

export const useSessions = (
  variables?: SessionFilters,
  additionalArgs?: Omit<QueryHookOptions, 'variables'>,
) => {
  const response = useQuery<
    { sessions: GraphQLPaginatedResponse<RawSession> },
    SessionRawQueryVariables
  >(SESSION_DETAIL_QUERY, {
    variables: sessionModel.normalizeSessionVariables(variables),
    ...additionalArgs,
  });

  const transformedData = useMemo(
    () => sessionModel.transformRawSessionPaginatedData(response.data?.sessions),
    [response.data],
  );

  return {
    ...response,
    data: transformedData,
  };
};
