import styled from '@emotion/styled';

export const CropperContainer = styled.div`
  height: 300px;
`;

export const CanvasContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
`;

export const AvatarContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const AvatarIconContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  span {
    font-size: 20px;
    background-color: ${({ theme }) => theme.colors.background};
    padding: 5px;
    border-radius: 3px;
  }
`;
