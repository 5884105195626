/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExternalUserFileVideoActionCreateMutationVariables = Types.Exact<{
  input: Types.UserFileVideoActionInput;
}>;

export type ExternalUserFileVideoActionCreateMutation = {
  externalUserFileVideoActionCreate: {
    __typename: 'ExternalUserFileVideoActionCreatePayload';
    userFileVideoAction?: { __typename: 'UserFileVideoActionNode' } | null;
  };
};

export const ExternalUserFileVideoActionCreateDocument = gql`
  mutation ExternalUserFileVideoActionCreate($input: UserFileVideoActionInput!) {
    externalUserFileVideoActionCreate(input: $input) {
      userFileVideoAction {
        __typename
      }
      __typename
    }
  }
`;
export type ExternalUserFileVideoActionCreateMutationFn = Apollo.MutationFunction<
  ExternalUserFileVideoActionCreateMutation,
  ExternalUserFileVideoActionCreateMutationVariables
>;

/**
 * __useExternalUserFileVideoActionCreateMutation__
 *
 * To run a mutation, you first call `useExternalUserFileVideoActionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalUserFileVideoActionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalUserFileVideoActionCreateMutation, { data, loading, error }] = useExternalUserFileVideoActionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalUserFileVideoActionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalUserFileVideoActionCreateMutation,
    ExternalUserFileVideoActionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalUserFileVideoActionCreateMutation,
    ExternalUserFileVideoActionCreateMutationVariables
  >(ExternalUserFileVideoActionCreateDocument, options);
}
export type ExternalUserFileVideoActionCreateMutationHookResult = ReturnType<
  typeof useExternalUserFileVideoActionCreateMutation
>;
export type ExternalUserFileVideoActionCreateMutationResult =
  Apollo.MutationResult<ExternalUserFileVideoActionCreateMutation>;
export type ExternalUserFileVideoActionCreateMutationOptions = Apollo.BaseMutationOptions<
  ExternalUserFileVideoActionCreateMutation,
  ExternalUserFileVideoActionCreateMutationVariables
>;
