import styled from '@emotion/styled';
import { Button } from 'antd';

export const AddCourseButton = styled(Button)(({ theme }) => ({
  color: theme.colors.link,
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  boxShadow: 'none',
  paddingLeft: theme.baseUnit * 1.5,
}));
