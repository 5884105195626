import { useFeatureFlags } from '@tw/contexts/featureFlags';
import { NestedKeysOf } from '@tw/util';

export interface LDFlagsType {
  // alphabetical order
  accessibilityIssueModal: boolean;
  adminBulkCreateAddresses: boolean;
  adminBulkCreateRoommates: boolean;
  adminBulkImporter: boolean;
  adminUploadContacts: boolean;
  adminUploadPersons: boolean;
  appAssignmentManager: boolean;
  athleteStatusChangeDetails: boolean;
  birthdays: boolean;
  checkInsCheckoutEnabledWeb: boolean;
  checkInsNotesWeb: boolean;
  checkInsWeb: boolean;
  complianceYearlyAttributes: boolean;
  customTasks: boolean;
  disableMessaging: boolean;
  formNumericInput: boolean;
  messagingHistoryLimit10: boolean;
  messagingHistoryLimit25: boolean;
  messagingHistoryLimit50: boolean;
  messagingFileAttachmentConversation: boolean;
  messagingUnreadConversationFilter: boolean;
  messagingDeletionNotification: boolean;
  mobileAlwaysSetActivityViewed: boolean;
  multiTeamTrip: boolean;
  oktaPasswordResetLink: boolean;
  overlayEventsWeb: boolean;
  overlayEventsConflictCheckerWeb: boolean;
  profileSharedView: boolean;
  profilesAttributeReminders: boolean;
  reactCanEditSeatplan: boolean;
  renameCoreAttributesOnWeb: boolean;
  restrictFileDownload: boolean;
  restrictVideoDownload: boolean;
  rsvpWeb: boolean;
  salesforceLiveChatWidget: boolean;
  sendProgressReportsFromStudentsTable: boolean;
  sharedNavAppSwitcher: boolean;
  teamRosterPage: boolean;
  tmpSegmentStartDay: boolean;
  useGraphQlForMobileSelectionCodeService: boolean;
  wcagComplianceWebV21: boolean;
  wcagSlateRichTextEditor: boolean;
}

export type LDFlagsKey = NestedKeysOf<LDFlagsType>;

const useFlagsWithTypes = (): LDFlagsType => {
  const { localFlags, setLocalFlag, ...flags } = useFeatureFlags();

  return flags;
};

export default useFlagsWithTypes;
