/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilesByFolderQueryVariables = Types.Exact<{
  folderId?: Types.InputMaybe<Types.Scalars['TWFilter_Int']>;
}>;

export type FilesByFolderQuery = {
  allSharedFiles?: {
    edges: Array<{
      node?: {
        id: string;
        pk?: number | null;
        sharedFileId?: number | null;
        folderId?: number | null;
        fileName: string;
        fileNotes?: string | null;
        isPinned?: boolean | null;
        updatedDate?: any | null;
        canEditFile?: boolean | null;
        canDownload?: boolean | null;
        creator?: {
          createdBy?: number | null;
          pictureDownloadUrl?: string | null;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export const FilesByFolderDocument = gql`
  query FilesByFolder($folderId: TWFilter_Int) {
    allSharedFiles(folderId: $folderId) {
      edges {
        node {
          id
          pk
          sharedFileId
          folderId
          fileName
          fileNotes
          isPinned
          updatedDate
          canEditFile
          canDownload
          creator {
            createdBy
            pictureDownloadUrl
            preferredName
            lastName
            initials
          }
        }
      }
    }
  }
`;

/**
 * __useFilesByFolderQuery__
 *
 * To run a query within a React component, call `useFilesByFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesByFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesByFolderQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useFilesByFolderQuery(
  baseOptions?: Apollo.QueryHookOptions<FilesByFolderQuery, FilesByFolderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilesByFolderQuery, FilesByFolderQueryVariables>(
    FilesByFolderDocument,
    options,
  );
}
export function useFilesByFolderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilesByFolderQuery, FilesByFolderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilesByFolderQuery, FilesByFolderQueryVariables>(
    FilesByFolderDocument,
    options,
  );
}
export type FilesByFolderQueryHookResult = ReturnType<typeof useFilesByFolderQuery>;
export type FilesByFolderLazyQueryHookResult = ReturnType<typeof useFilesByFolderLazyQuery>;
export type FilesByFolderQueryResult = Apollo.QueryResult<
  FilesByFolderQuery,
  FilesByFolderQueryVariables
>;
