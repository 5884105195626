import { useState, useEffect } from 'react';
import * as React from 'react';
import _ from 'lodash';

import { getTranslation } from '@tw/i18n';
import { Advisor } from '@tw/types';
import { noopFn } from '@tw/constants';

import {
  TWInputSelectAdvisor,
  TWInputSelectAdvisorProps,
} from '../../components.web/inputs/TWInputSelectAdvisor';
import TWChip from '../../presentational/TWChip';
import TWLoadingMask from '../../presentational/TWLoadingMask';
import TWInlineEditField from '../../presentational/TWInlineEditField';
import TWSpacingContainer from '../../presentational/layout/TWSpacingContainer';

interface TWEditableAdvisorListProps extends TWInputSelectAdvisorProps {
  containerId: string;
  advisorList?: Advisor[];
  width?: string;
  isLoading?: boolean;
}

export const TWEditableAdvisorList: React.FC<TWEditableAdvisorListProps> = ({
  advisorList = [],
  containerId,
  isLoading = false,
  onChange = noopFn,
  width,
}) => {
  const [initialAdvisorIdList, setInitialAdvisorIds] = useState(null);
  const [advisorIdList, setAdvisorIds] = useState(null);
  const [advisorProfileList, setAdvisorProfileList] = useState(null);

  useEffect(() => {
    const idList = [];
    const advisorsList = [];

    _.forEach(advisorList, (advisor) => {
      idList.push(Number(advisor.advisorId));
      advisorsList.push({
        personId: advisor.personId,
        picture: advisor.pictureDownloadUrl,
        fullName: `${advisor.preferredName} ${advisor.lastName}`,
        initials: `${advisor.preferredName.slice(0, 1)}${advisor.lastName.slice(0, 1)}`,
        size: 'small',
      });
    });

    setInitialAdvisorIds(idList);
    setAdvisorIds(idList);
    setAdvisorProfileList(advisorsList);
  }, [advisorList]);

  function handleCancel() {
    setAdvisorIds(initialAdvisorIdList);
  }

  function handleSave() {
    onChange(advisorIdList, null);
  }

  return (
    <TWInlineEditField
      containerId={containerId}
      onCancel={handleCancel}
      onChange={setAdvisorIds}
      onSave={handleSave}
      hideButtons
      readComponent={
        <>
          {isLoading || !advisorProfileList ? (
            <TWLoadingMask />
          ) : (
            <>
              {advisorProfileList.length > 0 ? (
                _.map(advisorProfileList, ({ fullName, picture, intitials, personId }, index) => (
                  <TWSpacingContainer
                    twMarginBottom={index + 1 === advisorProfileList.length ? 0 : 1}
                    key={personId}
                  >
                    <TWChip label={fullName} avatarImage={picture} avatarText={intitials} />
                  </TWSpacingContainer>
                ))
              ) : (
                <>{getTranslation('components.noAdvisor')}</>
              )}
            </>
          )}
        </>
      }
      writeComponent={
        <TWInputSelectAdvisor
          testID="Students:Profiles:StudentDrawer:Attributes:AdvisorsItem"
          isMultiSelect
          popupContainerId={containerId}
          placeholder={getTranslation('components.selectAdvisors')}
          autoFocus
          value={advisorIdList}
          width={width}
          maxHeight="500px"
          open
        />
      }
    />
  );
};
