import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import session from '@tw/services/session';
import localStorage from '@tw/services/localStorage';
import { datadogRum } from '@datadog/browser-rum';

const AxleTokenExchange = () => {
  const history = useHistory();
  const location = useLocation();

  const [tokenExchangeErrorMsg, setTokenExchangeErrorMsg] = useState<string | null>(null);

  const searchParams = new URLSearchParams(location.search);

  const token = searchParams.get('token') as string;
  const personId = searchParams.get('person_id') ?? undefined;
  const teamId = searchParams.get('team_id') ?? undefined;
  const orgId = searchParams.get('org_id') ?? undefined;

  useEffect(() => {
    if (!token) {
      throw Error('Token is missing from redirect url');
    }
  }, [token]);

  useEffect(() => {
    if (tokenExchangeErrorMsg) {
      throw Error(tokenExchangeErrorMsg);
    }
  }, [tokenExchangeErrorMsg]);

  if (personId && teamId && orgId) {
    localStorage.setCurrentUser({ personId, teamId, orgId });
  }

  const exchangeTokens = useCallback(async () => {
    try {
      await session.exchangeAxleTokenForHubToken(token);
      history.push('/home/overview');
    } catch (err) {
      datadogRum.addError(err);
      setTokenExchangeErrorMsg(err.response.data.message);
    }
  }, [token, history]);

  useEffect(() => {
    exchangeTokens();
  }, [exchangeTokens]);

  return null;
};

export default AxleTokenExchange;
