/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalsyncAccountsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CalsyncAccountsQuery = {
  calsyncAccounts?: Array<{
    id: string;
    status?: string | null;
    providerId: string;
    calsyncCalendars?: Array<{
      id: string;
      status?: string | null;
      providerId: string;
      thirdPartyName?: string | null;
      relinkUrl?: string | null;
    } | null> | null;
  } | null> | null;
};

export const CalsyncAccountsDocument = gql`
  query CalsyncAccounts {
    calsyncAccounts {
      id
      status
      providerId
      calsyncCalendars {
        id
        status
        providerId
        thirdPartyName
        relinkUrl
      }
    }
  }
`;

/**
 * __useCalsyncAccountsQuery__
 *
 * To run a query within a React component, call `useCalsyncAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalsyncAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalsyncAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalsyncAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<CalsyncAccountsQuery, CalsyncAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalsyncAccountsQuery, CalsyncAccountsQueryVariables>(
    CalsyncAccountsDocument,
    options,
  );
}
export function useCalsyncAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalsyncAccountsQuery, CalsyncAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalsyncAccountsQuery, CalsyncAccountsQueryVariables>(
    CalsyncAccountsDocument,
    options,
  );
}
export type CalsyncAccountsQueryHookResult = ReturnType<typeof useCalsyncAccountsQuery>;
export type CalsyncAccountsLazyQueryHookResult = ReturnType<typeof useCalsyncAccountsLazyQuery>;
export type CalsyncAccountsQueryResult = Apollo.QueryResult<
  CalsyncAccountsQuery,
  CalsyncAccountsQueryVariables
>;
