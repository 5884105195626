import * as React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { relayUtils } from '@tw/util';
import GET_ELIGIBILITY_YEARS from './@graphql/getEligibilityYears.graphql';
import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect';

const TWInputSelectYear: React.FC<TWInputSelectProps> = ({
  onChange,
  popupContainerId = '',
  placeholder = 'All Years',
  testID = '',
  ...rest
}) => {
  const { loading, data } = useQuery(GET_ELIGIBILITY_YEARS);

  let academicYears = [];
  academicYears = _.map(data?.eligibilityYears, (eligibilityYear) => ({
    key: eligibilityYear.id,
    value: Number(relayUtils.fromGlobalId(eligibilityYear.id)), // TODO: replace references to eligibility year pk on the backend so global ids can be used instead
    label: eligibilityYear.label,
  }));

  if (loading) return null;
  return (
    <TWInputSelect
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      itemList={academicYears}
      loading={loading}
      onChange={onChange}
      popupContainerId={popupContainerId}
      minWidth="200px"
      testID={testID}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default TWInputSelectYear;
