import { useQuery, QueryHookOptions } from '@apollo/client';
import _ from 'lodash';
import { useMemo } from 'react';

import {
  GraphQLPaginatedResponse,
  Team,
  TeamQueryVariables,
  TeamRawQueryVariables,
  TeamSortEnum,
} from '@tw/types';
import { graphqlUtils } from '@tw/util';

import teamsQuery from './teamsQuery.graphql';

interface TeamQueryResponse {
  teams: GraphQLPaginatedResponse<Team>;
}

interface UseTeamsOptions extends QueryHookOptions<TeamQueryResponse, TeamQueryVariables> {
  includeAllTeamsTeam?: boolean;
}

const defaultVariables = { sort: [TeamSortEnum.label_asc] };

const transformTeamsVariables = (variables: TeamQueryVariables): TeamRawQueryVariables => {
  if (!variables) {
    return defaultVariables;
  }

  const { teamId, ...rest } = variables;

  return {
    ...defaultVariables,
    ...rest,
    teamId: !_.isEmpty(teamId) ? graphqlUtils.createArrayIncludesQuery(teamId) : undefined,
  };
};

export const useTeams = (options?: UseTeamsOptions) => {
  const { includeAllTeamsTeam = false, variables, ...otherOptions } = options || {};

  const { data, ...rest } = useQuery<TeamQueryResponse, TeamRawQueryVariables>(teamsQuery, {
    variables: transformTeamsVariables(variables),
    ...otherOptions,
  });

  const teamData = useMemo(() => {
    if (!data?.teams) {
      return null;
    }

    const { teams } = data;
    const { edges, ...other } = teams;

    const teamList = edges ? graphqlUtils.convertEdgesToArray(edges) : null;

    return {
      ...other,
      teams:
        _.isEmpty(teamList) || includeAllTeamsTeam
          ? teamList
          : _.filter(teamList, (team) => team.label !== 'All Teams'),
    };
  }, [data, includeAllTeamsTeam]);

  return {
    ...rest,
    data: teamData,
  };
};
