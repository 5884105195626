import styled from '@emotion/styled';
import { CSSProperties, PropsWithChildren, ReactNode } from 'react';

const Container = styled.div(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.baseUnit * 3,
}));

interface TWDrawerSectionProps extends PropsWithChildren {
  className?: string;
  label?: ReactNode;
  style?: CSSProperties;
  testID?: string;
}

const TWDrawerSection = (props: TWDrawerSectionProps) => {
  const { children, className, label = '', style, testID } = props;

  return (
    <Container className={className} style={style} data-testid={testID}>
      {label && <h4>{label}</h4>}
      {children}
    </Container>
  );
};

export default TWDrawerSection;
