import { Dayjs } from 'dayjs';
import { useEffectOnce } from 'react-use';

import { TWIcon } from '@tw/components';
import { getTranslation } from '@tw/i18n';
import { Day, DayTime } from '@tw/types';
import { dayjs } from '@tw/util';

import { TWButtonIconFlat, TWFlexContainer } from '../../../presentational';
import { TWInputSelectDay } from '../TWInputSelectDay';
import {
  DayTimeRowProps,
  TWInputSelectMultiDayTimeProps,
} from './TWInputSelectMultiDayTime.definitions';
import { AddTimesButton, TimePicker } from './TWInputSelectMultiDayTime.styles';

const shortTime = 'hh:mm a';

const DayTimeRow: React.FC<DayTimeRowProps> = ({
  allowDelete,
  disabled,
  dayTime,
  onChange,
  onDelete,
  testID,
}) => {
  const rowContainerId = `${testID}_row_${dayTime.index}`;

  const onStartTimeChange = (startTime: Dayjs) => onChange({ ...dayTime, startTime });

  const onEndTimeChange = (endTime: Dayjs) => onChange({ ...dayTime, endTime });

  const onDayChange = (days: Day[]) => onChange({ ...dayTime, days });

  return (
    <TWFlexContainer id={rowContainerId} row twMarginBottom={1}>
      <TimePicker
        allowClear={false}
        disabled={disabled}
        format={shortTime}
        onChange={onStartTimeChange}
        popupContainerId={rowContainerId}
        use12Hours
        value={dayjs(dayTime.startTime)}
      />
      <TimePicker
        allowClear={false}
        disabled={disabled}
        format={shortTime}
        onChange={onEndTimeChange}
        popupContainerId={rowContainerId}
        use12Hours
        value={dayjs(dayTime.endTime)}
      />

      <TWInputSelectDay
        disabled={disabled}
        isMultiSelect
        popupContainerId={rowContainerId}
        onChange={onDayChange}
        value={dayTime.days}
      />
      {allowDelete && (
        <TWButtonIconFlat onClick={() => onDelete(dayTime)} disabled={disabled}>
          <TWIcon type="material-close" />
        </TWButtonIconFlat>
      )}
    </TWFlexContainer>
  );
};

export const TWInputSelectMultiDayTime = ({
  value,
  isMultiSelect = true,
  onChange,
  testID,
  disabled = false,
}: TWInputSelectMultiDayTimeProps) => {
  const safeValue =
    value?.map((item) => ({
      ...item,
      startTime: item.startTime || dayjs(),
      endTime: item.endTime || dayjs(),
    })) ?? [];

  useEffectOnce(() => {
    if (safeValue.length === 0) {
      const now = dayjs().startOf('minute');
      onChange?.([{ index: 0, startTime: now.clone(), endTime: now.clone() }]);
    }
  });

  const onAddDayTime = () => {
    const now = dayjs().startOf('minute');
    onChange?.([
      ...safeValue,
      { index: safeValue ? safeValue.length : 0, startTime: now.clone(), endTime: now.clone() },
    ]);
  };

  const onDayTimeRowChange = (dayTime: DayTime) =>
    onChange?.(safeValue.map((v) => (v.index === dayTime.index ? dayTime : v)));

  const onRemoveDayTime = (dayTime: DayTime) =>
    onChange?.(safeValue.filter((v) => v.index !== dayTime.index));

  if (safeValue.length === 0) {
    return null;
  }

  return (
    <TWFlexContainer data-testid={testID}>
      <TWFlexContainer>
        {safeValue.map((dayTime) => (
          <DayTimeRow
            key={dayTime.index}
            disabled={disabled}
            allowDelete={safeValue.length > 1}
            dayTime={dayTime}
            onChange={onDayTimeRowChange}
            onDelete={onRemoveDayTime}
            testID={testID}
          />
        ))}
      </TWFlexContainer>
      {isMultiSelect && (
        <AddTimesButton onClick={onAddDayTime} disabled={disabled}>
          {getTranslation('addDays')}
        </AddTimesButton>
      )}
    </TWFlexContainer>
  );
};
