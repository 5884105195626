import { MutationHookOptions, useMutation } from '@apollo/client';

import { default as CREATE_APPOINTMENT_FROM_SESSION } from './CreateAppointmentFromSession.graphql'; // eslint-disable-line import/no-named-default

export interface CreateAppointmentFromSessionInput {
  id: string;
}

export interface CreateAppointmentFromSessionData {
  createAppointmentFromSession: {
    session: {
      id: string;
      appointmentOccurrenceId: number;
    };
    userErrors: Array<{ field: string[]; message: string }>;
  };
}

export const useCreateAppointmentFromSession = (
  options?: MutationHookOptions<
    CreateAppointmentFromSessionData,
    CreateAppointmentFromSessionInput
  >,
) =>
  useMutation<CreateAppointmentFromSessionData, CreateAppointmentFromSessionInput>(
    CREATE_APPOINTMENT_FROM_SESSION,
    options,
  );
