export const getLocaleDictionary = (dictionary: { [key: string]: object }) => {
  if (window.navigator.languages) {
    // languages is an array in order of user preference
    const locale =
      window.navigator.languages.find((lang) => {
        const langShort = lang.slice(0, 2);
        return dictionary[lang] || dictionary[langShort];
      }) || 'en';

    const shortLocale = locale.slice(0, 2);
    return dictionary[locale] || dictionary[shortLocale];
  }

  const locale = window?.navigator?.language || window?.navigator?.userLanguage;
  if (locale) {
    const shortLocale = locale.slice(0, 2);
    return dictionary[locale] || dictionary[shortLocale] || dictionary.en;
  }

  return dictionary.en;
};
