import { datadogRum } from '@datadog/browser-rum';
import { defer, noop } from 'lodash';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';

import { TokenResponse, useStreamTokenLazyQuery } from '@tw/generated';
import { useFlags, useViewer } from '@tw/hooks';
import { StreamChatGenerics } from '@tw/modules/Messaging/screens/Conversations/contexts';

import { getStreamRequestInfo, logStreamRequest } from './logging';

type StreamClientContextType = {
  chatClient: StreamChat<StreamChatGenerics> | null;
  error?: Error | null;
  disconnect: () => void;
};

const StreamClientContext = createContext<StreamClientContextType | null>(null);

const hasStreamToken = (
  tokenResponse?: TokenResponse | null,
): tokenResponse is { streamUserId: string; token: string } =>
  !!tokenResponse?.streamUserId && !!tokenResponse?.token;

export const StreamClientProvider = ({ children }: PropsWithChildren<{}>) => {
  const [chatClient, setChatClient] = useState<StreamChat<StreamChatGenerics> | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const { person: viewerPerson } = useViewer();
  const { disableMessaging } = useFlags();

  const [fetchStreamTokenData] = useStreamTokenLazyQuery();

  // Reset state and refetch Stream token data when viewer changes
  useEffect(() => {
    setError(null);
    setChatClient(null);

    if (disableMessaging) return;

    fetchStreamTokenData().then(({ data }) => {
      const { person, streamTokenResponse } = data?.viewer || {};

      if (!(hasStreamToken(streamTokenResponse) && person)) {
        const missingTokenResponseError = new Error('No stream token response');
        setError(missingTokenResponseError);
        datadogRum.addError(missingTokenResponseError);

        return noop;
      }

      const { streamUserId, token } = streamTokenResponse;
      const client = new StreamChat<StreamChatGenerics>(
        process.env.REACT_APP_STREAM_API_KEY ?? '',
        { timeout: 6000 },
      );

      // Under some circumstances, a "connectUser" operation might be interrupted
      // (fast user switching, react strict-mode in dev). With this flag, we control
      // whether a "disconnectUser" operation has been requested before we
      // provide a new StreamChat instance to the consumers of this hook.
      let didUserConnectInterrupt = false;

      const connection = client
        .connectUser(
          {
            id: streamUserId ?? '',
            name: person.displayName ?? '',
            image: person.pictureUrl ?? undefined,
          },
          token,
        )
        .catch((err) => {
          console.error(err);
          datadogRum.addError(err);
          setError(err);
        })
        .then(() => {
          logStreamRequest('connect', {
            userId: client.user?.id,
          });

          if (!didUserConnectInterrupt) {
            setChatClient(client);
          }
        });

      return () => {
        didUserConnectInterrupt = true;
        setChatClient(null);

        // there might be a pending "connectUser" operation, wait for it to finish
        // before executing the "disconnectUser" in order to prevent race-conditions.
        connection.then(() => {
          client.disconnectUser().catch((err) => {
            console.error(err);
            datadogRum.addError(err, { type: 'auto' });
          });
        });
      };
    });
  }, [fetchStreamTokenData, viewerPerson, disableMessaging]);

  // Configure Stream client axios instance
  useEffect(() => {
    // Set up interceptor for request logging
    const reqInterceptorId = chatClient?.axiosInstance.interceptors.request.use((req) => {
      defer(() => {
        const [key, data] = getStreamRequestInfo(req) ?? [];

        if (key) {
          logStreamRequest(key, {
            url: req.url,
            method: req.method,
            userId: chatClient?.user?.id ?? req.params?.user_id,
            ...data,
          });
        }
      });

      return req;
    });

    return () => {
      // Eject old interceptor
      if (chatClient && reqInterceptorId) {
        chatClient.axiosInstance.interceptors.request.eject(reqInterceptorId);
      }
    };
  }, [chatClient]);

  return (
    <StreamClientContext.Provider
      value={{
        chatClient,
        error,
        disconnect: () => {
          chatClient?.disconnectUser().catch((err) => {
            console.error(err);
            datadogRum.addError(err, { type: 'manual' });
          });
          setChatClient(null);
        },
      }}
    >
      {children}
    </StreamClientContext.Provider>
  );
};

export const useStreamClient = (): StreamClientContextType => {
  const context = useContext(StreamClientContext);

  if (!context) {
    throw new Error('useStreamClient must be used within a StreamClientProvider');
  }

  return context;
};
