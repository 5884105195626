import styled from '@emotion/styled';

import { theme } from '@tw/components/utils';

export const ActionBarWrapper = styled.div({
  '.action-bar': {
    position: 'absolute',
    right: theme.fontUnit * 6,
    top: theme.fontUnit * 6,
    fontSize: theme.fontUnit * 6,
    '.ant-btn': {
      fontSize: theme.fontUnit * 6,
      lineHeight: 1.5,
      '&:not(:hover)': {
        color: theme.colors.secondary,
      },
    },
  },
});
