import { Tooltip } from 'antd';
import { PillText, PillTextWrapper, TWPillStyledComponent } from './TWPill.styles';

interface TWPillProps {
  text: string | number;
  type?: string;
  ariaLabel?: string;
  testID?: string;
  tooltip?: boolean;
  tooltipText?: string;
  iconPrefix?: React.ReactNode;
  newDesign?: boolean;
}

const TWPill = ({
  text,
  type,
  ariaLabel,
  testID,
  tooltip,
  tooltipText,
  iconPrefix,
  newDesign,
}: TWPillProps) => (
  <TWPillStyledComponent
    type={type}
    data-type={type}
    aria-label={ariaLabel || text.toString()}
    data-testid={testID}
    newDesign={newDesign}
  >
    {tooltip ? (
      <Tooltip placement="bottom" title={tooltipText}>
        {text}
      </Tooltip>
    ) : (
      <PillTextWrapper>
        {iconPrefix}
        <PillText iconPrefix={iconPrefix} newDesign={newDesign}>
          {text}
        </PillText>
      </PillTextWrapper>
    )}
  </TWPillStyledComponent>
);

TWPill.defaultProps = {
  type: 'default',
  ariaLabel: null,
  newDesign: false,
};

export default TWPill;
