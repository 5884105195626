import _ from 'lodash';
import dataUtils from '../dataUtils';

// All of the dev-checks will *function* okay, but they're an indication that you're likely using
// the wrong util -- so they'll warn but otherwise do nothing.
const enableDevChecks = global.__DEV__; // eslint-disable-line no-underscore-dangle

/**
 * Sometimes when we merge new data over the old, we have to do some special logic or
 * take care not to overwrite 'real' data with null/missing data -- but most of the time
 * we don't.
 *
 * This helper handles the most common case for merging stuff inside a reducer:
 * when we just want to apply the incoming data over top of whatever's there already.
 *
 * @FIXME: The order of arguments here is backwards from elsewhere.
 *
 * @param {Object} incomingModelData
 * @param {Object} existingModelData
 */
const mergeModelData = (incomingModelData, existingModelData) => {
  if (!existingModelData || incomingModelData === existingModelData) {
    // Nothing to do!
    return incomingModelData;
  }
  if (_.isMatch(existingModelData, incomingModelData)) {
    // We use isMatch instead of isEqual in case incomingModelData is incomplete yet still has nothing new
    return existingModelData;
  }

  return _.defaults({}, incomingModelData, existingModelData);
};

/**
 * Most model data is stored indexed by ID (since that lets us accumulate it from multiple sources)
 * so when new data arrives we merge whatever's incoming into whatever was there before.
 * With background refreshes, though, the incoming data is often not actually anything new --
 * so, to avoid creating a new object (and avoid re-running selectors that use the model data)
 * this will check to see whether it's *actually* something new.
 *
 * Note that this will only work if the model construction is memoized as well:
 * modelConverterUtils.memoizeById() handles that.
 *
 * @param {Object} existingState
 * @param {Object} incomingData
 * @return {Object}
 */
const mergeDataById = (existingState, incomingData) => {
  if (_.isEmpty(incomingData)) {
    return existingState;
  }
  if (_.isEmpty(existingState)) {
    return incomingData;
  }
  if (enableDevChecks && !_.isPlainObject(incomingData)) {
    console.warn(
      "serviceReducerUtils.mergeDataById was given something that doesn't look like incomingData:",
      incomingData,
    );
  }

  // Are any of the models actually new/different?
  // This will get called a LOT, so it's all manual loops for performance.
  let hasNewData = false;
  _.every(incomingData, (incomingItemInData, indexId: number) => {
    const existingItemInData = existingState[indexId];

    if (enableDevChecks) {
      if (
        existingItemInData &&
        dataUtils.isPrimitive(existingItemInData) !== dataUtils.isPrimitive(incomingItemInData)
      ) {
        console.warn(
          'serviceReducerUtils.mergeDataById is overriding non-primitive type with primitive type (or viceversa)',
        );
      }

      if (
        incomingItemInData !== null &&
        !dataUtils.isPrimitive(incomingItemInData) &&
        !_.isPlainObject(incomingItemInData) &&
        !_.isArray(incomingItemInData)
      ) {
        console.warn(
          "serviceReducerUtils.mergeDataById was given something that doesn't look like a model:",
          incomingItemInData,
        );
      }
    }
    if (existingState[indexId] !== incomingData[indexId]) {
      hasNewData = true;
      return false;
    }

    return true;
  });

  if (hasNewData) {
    return {
      ...existingState,
      ...incomingData,
    };
  }
  return existingState;
};

/**
 * To accompany the above data-by-ID optimizer, this one handles lists of IDs.
 *
 * @param {Object} existingState
 * @param {Object} incomingIdLists
 * @return {Object}
 */
const mergeIdLists = (existingState, incomingIdLists) => {
  if (_.isEmpty(existingState)) {
    return incomingIdLists;
  }
  if (enableDevChecks && !_.isPlainObject(incomingIdLists)) {
    console.warn(
      "serviceReducerUtils.mergeIdLists was given something that doesn't look like incomingIdLists:",
      incomingIdLists,
    );
  }

  // Is the list of IDs actually new/different?
  let hasNewData = false;
  _.every(incomingIdLists, (incomingIdList, indexId: number) => {
    // eslint-disable-line no-restricted-syntax

    if (enableDevChecks && incomingIdList != null && !_.isArray(incomingIdList)) {
      console.warn(
        "serviceReducerUtils.mergeIdLists was given something that doesn't look like a list:",
        incomingIdList,
      );
    }

    // Do the arrays line up exactly?
    const existingIdList = existingState[indexId];
    if (!existingIdList || !incomingIdList || existingIdList.length !== incomingIdList.length) {
      hasNewData = true;
      return false;
    }
    // More manual looping for performance. Sorry for the ugly-to-read code.
    for (let i = 0; i < incomingIdList.length; i += 1) {
      const incomingId = incomingIdList[i];
      if (enableDevChecks && !_.isNumber(incomingId) && !_.isString(incomingId)) {
        console.warn(
          "serviceReducerUtils.mergeIdLists was given something that doesn't look like an id:",
          incomingId,
        );
      }

      if (existingIdList[i] !== incomingId) {
        hasNewData = true;
        return false;
      }
    }

    return true;
  });

  if (hasNewData) {
    return {
      ...existingState,
      ...incomingIdLists,
    };
  }
  return existingState;
};

export default {
  mergeModelData,
  mergeDataById,
  mergeIdLists,
};
