import { graphqlUtils } from '@tw/util';

import { tutorSessionAttendanceExportSchema } from '../tutorSessionAttendance/tutorSessionAttendanceQueries';

const tutorSessionExportSchema = graphqlUtils.createEdgesObject({
  nodeSchema: {
    occurrenceStartDateTime: true,
    occurrenceEndDateTime: true,
    reportStatus: true,
    reportAnswers: true,
    tutor: {
      preferredName: true,
      lastName: true,
    },
    subject: {
      label: true,
    },
    course: {
      label: true,
    },
    sessionAttendances: tutorSessionAttendanceExportSchema,
  },
});

const tutorSessionExportQuery = graphqlUtils.buildQuery({
  endpoint: 'sessions',
  schema: tutorSessionExportSchema,
  supportedFilters: {
    sessionId: 'TWFilter_ID',
    sort: '[SessionSortEnum]',
    tutorId: 'TWFilter_Int',
    teamIds: '[Int]',
    termId: 'TWFilter_Int',
    advisorIds: '[Int]',
    searchSessions: 'String',
    reportStatus: 'TWFilter_String',
    occurrenceStartDateTime: 'TWFilter_DateTime',
    isDeleted: 'TWFilter_Boolean',
  },
});

export { tutorSessionExportQuery };
