/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSignupsMutationVariables = Types.Exact<{
  input:
    | Array<Types.InputMaybe<Types.DeleteSignupInput>>
    | Types.InputMaybe<Types.DeleteSignupInput>;
  sendAlerts?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type DeleteSignupsMutation = { deleteSignups: { deletedIds?: Array<any | null> | null } };

export const DeleteSignupsDocument = gql`
  mutation DeleteSignups($input: [DeleteSignupInput]!, $sendAlerts: String) {
    deleteSignups(input: $input, sendAlerts: $sendAlerts) {
      deletedIds
    }
  }
`;
export type DeleteSignupsMutationFn = Apollo.MutationFunction<
  DeleteSignupsMutation,
  DeleteSignupsMutationVariables
>;

/**
 * __useDeleteSignupsMutation__
 *
 * To run a mutation, you first call `useDeleteSignupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignupsMutation, { data, loading, error }] = useDeleteSignupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sendAlerts: // value for 'sendAlerts'
 *   },
 * });
 */
export function useDeleteSignupsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSignupsMutation, DeleteSignupsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSignupsMutation, DeleteSignupsMutationVariables>(
    DeleteSignupsDocument,
    options,
  );
}
export type DeleteSignupsMutationHookResult = ReturnType<typeof useDeleteSignupsMutation>;
export type DeleteSignupsMutationResult = Apollo.MutationResult<DeleteSignupsMutation>;
export type DeleteSignupsMutationOptions = Apollo.BaseMutationOptions<
  DeleteSignupsMutation,
  DeleteSignupsMutationVariables
>;
