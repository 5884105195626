import _ from 'lodash';

import { reduxConfigUtils } from '@tw/util';

import baseDefault from './baseDefault';
import internationalTime from './internationalTime';
import esDateFormatForLocale from './dateFormatForLocale.es';
import formatConfigStoreConfig from './formatConfigStoreConfig';
import {
  APPLY_DEBUG_OVERRIDE,
  CLEAR_DEBUG_OVERRIDE,
  TOGGLE_OVERRIDE_LAYER,
} from './formatConfigActions';

// Note: Everything is flat here (instead of nesting/grouping the attributes and values
// separately) because that makes the store manipulations easier -- we'd need 3-4 spread
// operators for each one instead of 1-2.
const initialState = {
  // In priority order, from lowest to highest
  layerNames: formatConfigStoreConfig.layerNames,

  // Available attributes:
  //    isEnabled     {bool}
  //    @TODO: For future growth, if/when we need it:
  //      * something to enable/disable debug logging for the layer,
  //      * tags to allow complex queries from outside (to answer questions like
  //        "did this value come from a layer tagged as 'override'?", for debugging only)
  attributes_baseDefault: { isEnabled: true },
  attributes_dateFormatsForLocale_es: { isEnabled: false },
  attributes_internationalTime: { isEnabled: false },
  attributes_devicePreference: { isEnabled: false },
  attributes_teamPreference: { isEnabled: false },
  attributes_debugOverride: { isEnabled: false },

  values_baseDefault: baseDefault,
  values_internationalTime: internationalTime,
  values_dateFormatsForLocale_es: esDateFormatForLocale,
  values_devicePreference: {},
  values_teamPreference: {},
  values_debugOverride: {},
};

reduxConfigUtils.warnIfInitialStateLooksIncomplete(initialState);

export default function formatConfigReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * This *merges in* a set of debugOverride values
     */
    case APPLY_DEBUG_OVERRIDE:
      return {
        ...state,
        attributes_debugOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_debugOverride,
          {
            isEnabled: true,
          },
        ),
        values_debugOverride: reduxConfigUtils.mergeConfigLayerValues(
          state.values_debugOverride,
          action.debugOverrideValues,
        ),
      };

    /**
     * This wipes (but does not disable) any debugOverride values
     */
    case CLEAR_DEBUG_OVERRIDE:
      return {
        ...state,
        attributes_debugOverride: reduxConfigUtils.mergeConfigLayerAttributes(
          state.attributes_debugOverride,
          {
            isEnabled: false,
          },
        ),
        values_debugOverride: {},
      };

    case TOGGLE_OVERRIDE_LAYER: {
      if (!action.layerName || !_.get(state, `attributes_${action.layerName}`)) {
        console.warn('received action with invalid layerName!', action);
        return state;
      }

      return {
        ...state,
        [`attributes_${action.layerName}`]: {
          ...state[`attributes_${action.layerName}`],
          isEnabled: action.isEnabled,
        },
      };
    }

    default:
      return state;
  }
}
