import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import {
  handleAddOrRemoveSelection,
  SelectionItemInterface,
  SelectionItemsByCode,
} from '@tw/components/utils';
import { GroupType } from '@tw/generated';
import { useDebouncedCallback } from '@tw/hooks';
import { relayUtils } from '@tw/util';
import { UserSelectionQueryFilters } from '@tw/hooks/useUserSelection/useUserSelection';

interface UserMultiSelectProps {
  defaultGroupTypeFilter?: GroupType;
  onChange?: (selectionsByCode: SelectionItemInterface[]) => void;
  onCheckedSelectionsChange?: (selectionsByCode: SelectionItemInterface[]) => void;
  selections: SelectionItemInterface[];
  queryFilters?: UserSelectionQueryFilters;
  disabledSelections?: string[];
}

export interface MultiSelectManagementInterface {
  // filters
  groupTypeFilter: GroupType;
  searchFilter: string;
  // event handlers
  handleSearchFilterChange: ({ target: { value } }: { target: { value: string } }) => void;
  handleSetGroupTypeFilter: ({ target: { value } }: { target: { value: GroupType } }) => void;
  handleOnDeselect: (selectionCode: string) => void;
  handleOnExplode: (selectionCode: string) => void;
  handleOnItemClick: (selectionItem: SelectionItemInterface) => void;
  selectionItemsByCode: SelectionItemsByCode;
  setSelectionItemsByCode: React.Dispatch<React.SetStateAction<SelectionItemsByCode>>;
}

const useMultiSelect = ({
  defaultGroupTypeFilter = GroupType.Type,
  onChange,
  onCheckedSelectionsChange,
  selections,
  queryFilters,
  disabledSelections = [],
}: UserMultiSelectProps): MultiSelectManagementInterface => {
  const [groupTypeFilter, setGroupTypeFilter] = useState(defaultGroupTypeFilter);
  const [searchFilter, setSearchFilter] = useState('');
  const [selectionItemsByCode, setSelectionItemsByCode] = useState<SelectionItemsByCode>({});
  const debouncedSetSearchFilter = useDebouncedCallback(setSearchFilter, 350);
  const previousSelections = usePrevious(selections);

  const handleSelectionByCodeChange = (newSelectionItemsByCode: SelectionItemsByCode) => {
    const newSelectionsList = Object.values(newSelectionItemsByCode);
    if (onCheckedSelectionsChange) {
      onCheckedSelectionsChange(newSelectionsList);
    }
    onChange?.(newSelectionsList);
  };

  const handleSetGroupTypeFilter = ({ target: { value } }): void => {
    setGroupTypeFilter(value);
  };

  const handleSearchFilterChange = ({ target: { value } }): void => {
    debouncedSetSearchFilter(value);
  };

  const handleOnItemClick = (selectionItem: SelectionItemInterface): void => {
    const { selectionAdded } = handleAddOrRemoveSelection(selectionItemsByCode, {
      ...selectionItem,
      isChecked: true,
    });
    setSelectionItemsByCode((prevSelectionItemsByCode) => {
      let newSelectionItemsByCode = {
        ...prevSelectionItemsByCode,
      };

      if (selectionItem) {
        if (selectionAdded) {
          newSelectionItemsByCode = {
            ...newSelectionItemsByCode,
            [selectionItem.selectionCode]: {
              ...selectionItem,
              isChecked: true,
            },
          };
        } else {
          newSelectionItemsByCode = omit(newSelectionItemsByCode, [selectionItem.selectionCode]);
        }
      }

      handleSelectionByCodeChange(newSelectionItemsByCode);
      return newSelectionItemsByCode;
    });
  };

  // function for clicking X icon
  const handleOnDeselect = (selectionCode: string): void => {
    const newSelectionItemsByCode = omit(selectionItemsByCode, [selectionCode]);
    handleSelectionByCodeChange(newSelectionItemsByCode);
    setSelectionItemsByCode(newSelectionItemsByCode);
  };

  const handleOnExplode = (selectionCode: string): void => {
    const newSelectionItemsByCode = omit(selectionItemsByCode, [selectionCode]);
    const selectionItem = selectionItemsByCode[selectionCode];
    const people = ('people' in selectionItem && selectionItem.people) || [];
    const groupMembers = isEmpty(queryFilters?.allowedPeople)
      ? people
      : people.filter(
          (groupMember) =>
            groupMember.id &&
            queryFilters?.allowedPeople?.includes(Number(relayUtils?.fromGlobalId(groupMember.id))),
        );
    const groupMembersWithoutDisabled = groupMembers.filter(
      (groupMember) => !disabledSelections.includes(groupMember.selectionCode),
    );
    groupMembersWithoutDisabled.forEach((member) => {
      newSelectionItemsByCode[member.selectionCode] = { ...member, isChecked: true };
    });
    handleSelectionByCodeChange(newSelectionItemsByCode);
    setSelectionItemsByCode(newSelectionItemsByCode);
  };

  // set the state of multi selection based on incoming data and initial selection items
  useEffect(() => {
    if (!isEmpty(selections)) {
      let newSelectionsByCode = {};
      selections?.forEach((selectionItem) => {
        newSelectionsByCode = {
          ...newSelectionsByCode,
          [selectionItem.selectionCode]: {
            isChecked: true,
            ...selectionItem, // If isChecked is set in the selectionItem then honor that value
          },
        };
      });
      setSelectionItemsByCode(() => newSelectionsByCode);
      // handle removing all users with "selections" prop (controlled by component not in form)
    } else if (isEmpty(selections) && previousSelections && previousSelections.length) {
      setSelectionItemsByCode({});
    }
    // This hook runs if the selections passed in from the consumer of the hook
    // change. This allows the user selections to be controlled externally.
  }, [previousSelections, selections]);

  return {
    // filters
    groupTypeFilter,
    searchFilter,
    // event handlers
    handleSearchFilterChange,
    handleSetGroupTypeFilter,
    handleOnDeselect,
    handleOnExplode,
    handleOnItemClick,
    // selection state
    selectionItemsByCode,
    setSelectionItemsByCode,
  };
};

export default useMultiSelect;
