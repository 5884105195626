import { Text } from 'slate';
import { jsx } from 'slate-hyperscript';
import { fontFamilyMap, sizeMap } from './slate';

function escapeHtml(str) {
  const matchHtmlRegExp = /["'&<>]/;
  const match = matchHtmlRegExp.exec(str);

  if (!match) {
    return str;
  }

  let escape;
  let html = '';
  let index = 0;
  let lastIndex = 0;

  for (index = match.index; index < str.length; index += 1) {
    switch (str.charCodeAt(index)) {
      case 34: // "
        escape = '&quot;';
        break;
      case 38: // &
        escape = '&amp;';
        break;
      case 39: // '
        escape = '&#39;';
        break;
      case 60: // <
        escape = '&lt;';
        break;
      case 62: // >
        escape = '&gt;';
        break;
      default:
        // eslint-disable-next-line no-continue
        continue;
    }

    if (lastIndex !== index) {
      html += str.substring(lastIndex, index);
    }

    lastIndex = index + 1;
    html += escape;
  }

  return lastIndex !== index ? html + str.substring(lastIndex, index) : html;
}

export const serializeHTML = (node) => {
  // TODO: this function works but the typescript is not happy.
  // Need to fix this.

  if (Text.isText(node)) {
    let htmlFriendlyString = escapeHtml(node.text);
    if ('bold' in node) {
      htmlFriendlyString = `<strong>${htmlFriendlyString}</strong>`;
    }
    if ('italic' in node) {
      htmlFriendlyString = `<em>${htmlFriendlyString}</em>`;
    }
    if ('underline' in node) {
      htmlFriendlyString = `<u>${htmlFriendlyString}</u>`;
    }
    if ('strikethrough' in node) {
      htmlFriendlyString = `<s>${htmlFriendlyString}</s>`;
    }
    if ('superscript' in node) {
      htmlFriendlyString = `<sup>${htmlFriendlyString}</sup>`;
    }
    if ('subscript' in node) {
      htmlFriendlyString = `<sub>${htmlFriendlyString}</sub>`;
    }
    if ('fontFamily' in node) {
      const family = fontFamilyMap[node.fontFamily];
      htmlFriendlyString = `<span style='font-family: ${family}'>${htmlFriendlyString}</span>`;
    }
    if ('fontSize' in node) {
      const size = sizeMap[node.fontSize];
      htmlFriendlyString = `<span style='font-size: ${size}'>${htmlFriendlyString}</span>`;
    }
    if ('alignLeft' in node) {
      htmlFriendlyString = `<div style='text-align: left'>${htmlFriendlyString}</div>`;
    }
    if ('alignCenter' in node) {
      htmlFriendlyString = `<div style='text-align: center'>${htmlFriendlyString}</div>`;
    }
    if ('alignRight' in node) {
      htmlFriendlyString = `<div style='text-align: right'>${htmlFriendlyString}</div>`;
    }
    if ('color' in node) {
      htmlFriendlyString = `<span style='color: ${node.color}'>${htmlFriendlyString}</span>`;
    }
    if ('backgroundColor' in node) {
      htmlFriendlyString = `<span style='background-color: ${node.backgroundColor}'>${htmlFriendlyString}</span>`;
    }
    if ('link' in node) {
      htmlFriendlyString = `<a href=${node.link} rel='noopener noreferrer' target='_blank'>${htmlFriendlyString}</a>`;
    }

    if ('image' in node) {
      htmlFriendlyString = `<img src=${node.image} style="max-width: 100%"/>`;
    }

    return htmlFriendlyString;
  }

  const children = node.children.map((childNode) => serializeHTML(childNode)).join('');
  if ('type' in node) {
    switch (node.type) {
      case 'paragraph':
        return `<p>${children}</p>`;
      case 'orderedList':
        return `<ol>${children}</ol>`;
      case 'unorderedList':
        return `<ul>${children}</ul>`;
      case 'list-item':
        return `<li>${children}</li>`;
      case 'alignLeft':
        return `<div style='text-align: left; list-style-position: inside'>${children}</div>`;
      case 'alignCenter':
        return `<div style='text-align: center; list-style-position: inside'>${children}</div>`;
      case 'alignRight':
        return `<div style='text-align: right; list-style-position: inside'>${children}</div>`;
      case 'link':
        return `<a href=${node.href} rel='noopener noreferrer' target='_blank'>${children}</a>`;
      case 'image':
        return `<img src=${node.url} style="max-width: 100%"/>`;
      default:
        return children;
    }
  } else {
    return children;
  }
};

function findKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === 3) {
    return jsx('text', markAttributes, el.textContent);
  }
  if (el.nodeType !== 1) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
      break;
    case 'SUB':
      nodeAttributes.subscript = true;
      break;
    case 'SUP':
      nodeAttributes.superscript = true;
      break;
    case 'EM':
      nodeAttributes.italic = true;
      break;
    case 'S':
      nodeAttributes.strikethrough = true;
      break;
    // H tags aren't used in this version but attempt to provide backwards compatibility
    case 'H1':
      nodeAttributes.fontSize = sizeMap.huge;
      break;
    case 'H2':
    case 'H3':
      nodeAttributes.fontSize = sizeMap.large;
      break;
    case 'H4':
    case 'H5':
      nodeAttributes.fontSize = sizeMap.normal;
      break;
    case 'H6':
      nodeAttributes.fontSize = sizeMap.small;
      break;
    default:
      break;
  }

  if (el.style.fontFamily) {
    nodeAttributes.fontFamily = findKeyByValue(fontFamilyMap, el.style.fontFamily);
  }

  if (el.style.fontSize) {
    nodeAttributes.fontSize = findKeyByValue(sizeMap, el.style.fontSize);
  }

  if (el.style.color) {
    nodeAttributes.color = el.style.color;
  }

  if (el.style.backgroundColor) {
    nodeAttributes.backgroundColor = el.style.backgroundColor;
  }

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'P': // <p> and <div> tags should return the same
    case 'DIV':
      switch (el.style.textAlign) {
        case 'left':
          return jsx('element', { type: 'alignLeft' }, children);
        case 'center':
          return jsx('element', { type: 'alignCenter' }, children);
        case 'right':
          return jsx('element', { type: 'alignRight' }, children);
        default:
          return jsx('element', { type: 'paragraph' }, children);
      }
    case 'OL':
      return jsx('element', { type: 'orderedList' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    case 'UL':
      return jsx('element', { type: 'unorderedList' }, children);
    case 'A':
      return jsx('element', { type: 'link', href: el.href }, children);
    case 'IMG':
      return jsx('element', { type: 'image', url: el.src }, children);
    default:
      return children;
  }
};

// Used because deserialize is recursive
export const deserializeText = (html) => {
  const document = new DOMParser().parseFromString(html, 'text/html');
  const value = deserialize(document.body);
  return value;
};
