import { useEffect } from 'react';
import * as React from 'react';

import { Grade } from '@tw/types';
import { useGradesLazy } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';

import TWInputSelect from '../TWInputSelect';
import { TWInputSelectProps } from '../TWInputSelect/TWInputSelect';

interface TWInputSelectGradeProps extends TWInputSelectProps {
  populateOnMount?: boolean;
}

const TWInputSelectGrade: React.FC<TWInputSelectGradeProps> = (props) => {
  const {
    placeholder = getTranslation('allGrades'),
    populateOnMount,
    ...allUnrecognizedProps
  } = props;

  const { fetchGrades, grades, loading } = useGradesLazy();

  useEffect(() => {
    if (populateOnMount) {
      fetchGrades();
    }
  }, []);

  const keyExtractor = (grade: Grade) => grade?.gradeId;

  const labelExtractor = (grade: Grade) => grade?.label?.toLocaleUpperCase();

  const valueExtractor = (grade: Grade) => grade?.label?.toLocaleUpperCase();

  return (
    <TWInputSelect
      onFocus={() => fetchGrades()}
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      keyExtractor={keyExtractor}
      labelExtractor={labelExtractor}
      valueExtractor={valueExtractor}
      itemList={grades}
      loading={loading}
      minWidth="100px"
      {...allUnrecognizedProps}
    />
  );
};

export default TWInputSelectGrade;
