import { QuestionType } from '@tw/modules/Forms/Forms.definitions';

export const fieldsToHide: string[] = [
  QuestionType.Date,
  QuestionType.DateRange,
  QuestionType.TextArea,
];

export enum RulesActions {
  Show = 'show',
  Hide = 'hide',
}

export enum RulesMatches {
  Matches = 'matches',
  DoesNotMatch = 'doesNotMatch',
  Equal = 'equal',
  GreaterThan = 'greaterThan',
  IsFilled = 'isFilled',
  IsEmpty = 'isEmpty',
  IsOn = 'isOn',
  IsNotOn = 'isNotOn',
  IsBefore = 'isBefore',
  IsAfter = 'isAfter',
  IsBeforeOrOn = 'isBeforeOrOn',
  IsAfterOrOn = 'isAfterOrOn',
  IsBetween = 'isBetween',
  IsNotBetween = 'isNotBetween',
  LessThan = 'lessThan',
  NotEqual = 'notEqual',
}

export interface WithConflict {
  conflict?: boolean;
}
