import { TimePicker } from 'antd';
import { TimePickerProps } from 'antd/lib/time-picker';
import { useRef } from 'react';
import * as React from 'react';
import { Moment } from 'moment';

import { noopFn } from '@tw/constants';
import { DOMUtils } from '@tw/util';

interface Props {
  popupContainerId: string;
  testID?: string;
}

const TWInputTimePicker: React.FC<Props & TimePickerProps> = ({
  popupContainerId,
  testID,
  use12Hours,
  onChange = noopFn,
  ...rest
}) => {
  const inputElement = useRef(null);

  const onOpenChange = (isOpen) => {
    if (!isOpen) {
      // inputElement.current.blur();
    }
  };

  // this allows us to update the value even if the user doesn't click on the "ok" button in the dropdown
  const onSelect = (e: Moment) => {
    onChange(e, e.format());
  };

  return (
    <TimePicker
      onSelect={onSelect}
      onChange={onChange}
      onOpenChange={onOpenChange}
      data-testid={testID}
      getPopupContainer={(e) => DOMUtils.getParentContainer(e, popupContainerId)}
      use12Hours={use12Hours}
      ref={inputElement}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default TWInputTimePicker;
