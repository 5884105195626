const $twButton = {
  primaryBackgroundColor: '$brandColor.primaryColor',
  primaryTextColor: '$colorHue.white',
  secondaryBackgroundColor: '$brandColor.primaryLighterColor',
  secondaryTextColor: '$colorHue.white',
  cancelBackgroundColor: '$colorHue.grayPale',
  cancelTextColor: '$brandColor.primaryColor',
  dangerBackgroundColor: '$colorHue.redDark',
  dangerTextColor: '$colorHue.white',

  disabledOpacity: 0.4,
  borderWidth: '$borderWidth.large',
  borderColor: '$colorHue.transparent',
  borderRadius: 6,
  iconSpacing: 4,

  fontFamily: '$fontFamily.body',
  fontSize: '$fontSize.large',
  fontWeight: 'bold',
  iconSize: '$lineHeight.large',
  minHeight: 60,
  fontSizeSmall: '$fontSize.medium',
  fontWeightSmall: '500',
  iconSizeSmall: '$lineHeight.large',
  minHeightSmall: 44,
  paddingVertical: 10,
};

export { $twButton };
