import { Input, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import { BaseEditor } from 'slate';
import { getTranslation } from '@tw/i18n';
import { Button, Icon } from '../components';
import { insertLink } from '../utils/link';
import { isBlockActive } from '../utils/slate';

export const LinkButton = ({ editor }: { editor: BaseEditor }) => {
  const active = isBlockActive(editor, 'link');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [href, setHref] = useState('');

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleInsertLink = () => {
    if (href) {
      const url =
        href.startsWith('http://') || href.startsWith('https://') ? href : `https://${href}`;
      insertLink(editor, url);
      closeModal();
      setHref('');
    }
  };

  return (
    <>
      <Tooltip title={getTranslation('link')}>
        <Button
          accessibilityLabel={getTranslation('link')}
          active={active}
          format="link"
          onClick={openModal}
        >
          <Icon icon="link" title={getTranslation('link')} />
        </Button>
      </Tooltip>
      <Modal
        centered
        closable={false}
        open={isModalVisible}
        onOk={handleInsertLink}
        onCancel={closeModal}
      >
        <Input
          addonBefore="https://"
          defaultValue="teamworks.com"
          value={href}
          onChange={(e) => setHref(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleInsertLink();
            }
          }}
        />
      </Modal>
    </>
  );
};
