import { RowSelectionState } from '@tanstack/react-table';
import { TWButtonGroupPagination, TWInputSelectRowsPerPage } from '@tw/components';
import { useTranslator } from '@tw/i18n';
import { Col, Row } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { CursorPagination as CursorPaginationType } from '../../DataTable.definitions';
import { Pagination } from '../../DataTable.styles';

type CursorPaginationProps = {
  testId: string;
  loading?: boolean;
  pagination: CursorPaginationType;
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
};

const CursorPagination = ({
  testId,
  loading = false,
  pagination,
  setRowSelection,
}: CursorPaginationProps) => {
  const translator = useTranslator();
  const {
    currentPageString,
    hasNextPage,
    hasPreviousPage,
    loadNextPage,
    loadPreviousPage,
    rowsPerPage,
    rowsPerPageOptions,
    updateRowsPerPage,
  } = pagination;

  const onUpdateRowsPerPage = (value: number) => {
    updateRowsPerPage(value);
    setRowSelection({});
  };

  const onNextClick = () => {
    loadNextPage();
    setRowSelection({});
  };

  const onPreviousClick = () => {
    loadPreviousPage();
    setRowSelection({});
  };

  return (
    <Pagination data-testid={`${testId}:CursorPagination`}>
      <Col className="rowsPerPageColumn">
        {rowsPerPage && (
          <TWInputSelectRowsPerPage
            id="DataTablePaginated:RowsPerPage"
            accessibilityLabel={translator.t('rowsPerPage')}
            value={rowsPerPage}
            options={rowsPerPageOptions}
            onChange={onUpdateRowsPerPage}
          />
        )}
      </Col>
      <Col>
        <Row align="middle" justify="end">
          {currentPageString}
          <TWButtonGroupPagination
            loading={loading}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            onNextPage={onNextClick}
            onPreviousPage={onPreviousClick}
          />
        </Row>
      </Col>
    </Pagination>
  );
};

export default CursorPagination;
