/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyEventsMutationVariables = Types.Exact<{
  input:
    | Array<Types.InputMaybe<Types.AppointmentBulkCopyDateInput>>
    | Types.InputMaybe<Types.AppointmentBulkCopyDateInput>;
}>;

export type CopyEventsMutation = {
  appointmentBulkCopy: {
    appointment?: Array<{ appointmentId: string } | null> | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export const CopyEventsDocument = gql`
  mutation CopyEvents($input: [AppointmentBulkCopyDateInput]!) {
    appointmentBulkCopy(input: $input) {
      appointment {
        appointmentId
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type CopyEventsMutationFn = Apollo.MutationFunction<
  CopyEventsMutation,
  CopyEventsMutationVariables
>;

/**
 * __useCopyEventsMutation__
 *
 * To run a mutation, you first call `useCopyEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyEventsMutation, { data, loading, error }] = useCopyEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyEventsMutation, CopyEventsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CopyEventsMutation, CopyEventsMutationVariables>(
    CopyEventsDocument,
    options,
  );
}
export type CopyEventsMutationHookResult = ReturnType<typeof useCopyEventsMutation>;
export type CopyEventsMutationResult = Apollo.MutationResult<CopyEventsMutation>;
export type CopyEventsMutationOptions = Apollo.BaseMutationOptions<
  CopyEventsMutation,
  CopyEventsMutationVariables
>;
