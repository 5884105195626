/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilesFolderCreateMutationVariables = Types.Exact<{
  folderName: Types.Scalars['String'];
  folderType: Types.FolderTypeGraphene;
  parentFolderId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type FilesFolderCreateMutation = {
  folderCreate: {
    folder?: {
      orgId: number;
      teamId?: number | null;
      folderName?: string | null;
      folderType?: string | null;
      sortOrder?: number | null;
      folderId?: number | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const FilesFolderCreateDocument = gql`
  mutation FilesFolderCreate(
    $folderName: String!
    $folderType: FolderTypeGraphene!
    $parentFolderId: Int
  ) {
    folderCreate(
      input: { folderName: $folderName, folderType: $folderType, parentFolderId: $parentFolderId }
    ) {
      folder {
        orgId
        teamId
        folderId: pk
        folderName
        folderType
        sortOrder
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FilesFolderCreateMutationFn = Apollo.MutationFunction<
  FilesFolderCreateMutation,
  FilesFolderCreateMutationVariables
>;

/**
 * __useFilesFolderCreateMutation__
 *
 * To run a mutation, you first call `useFilesFolderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFilesFolderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [filesFolderCreateMutation, { data, loading, error }] = useFilesFolderCreateMutation({
 *   variables: {
 *      folderName: // value for 'folderName'
 *      folderType: // value for 'folderType'
 *      parentFolderId: // value for 'parentFolderId'
 *   },
 * });
 */
export function useFilesFolderCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FilesFolderCreateMutation,
    FilesFolderCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FilesFolderCreateMutation, FilesFolderCreateMutationVariables>(
    FilesFolderCreateDocument,
    options,
  );
}
export type FilesFolderCreateMutationHookResult = ReturnType<typeof useFilesFolderCreateMutation>;
export type FilesFolderCreateMutationResult = Apollo.MutationResult<FilesFolderCreateMutation>;
export type FilesFolderCreateMutationOptions = Apollo.BaseMutationOptions<
  FilesFolderCreateMutation,
  FilesFolderCreateMutationVariables
>;
