import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import type { LDFlagsKey, LDFlagsType } from '@tw/hooks';

const isDevelopment = process.env.NODE_ENV === 'development';

type FeatureFlagsContextType = LDFlagsType & {
  localFlags: Partial<LDFlagsType>;
  setLocalFlag: (flag: LDFlagsKey, value: boolean | undefined) => void;
};

const featureFlagsContext = createContext<FeatureFlagsContextType | null>(null);

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  // local overrides
  const [localFlags, setLocalFlags] = useState<Partial<LDFlagsType>>({});
  const flagsFromLD = useFlags() as LDFlagsType;

  const [flags, setFlags] = useState(flagsFromLD);

  useEffect(() => {
    setFlags({ ...flagsFromLD, ...localFlags });
  }, [flagsFromLD, localFlags]);

  const setLocalFlag = useCallback((flag: LDFlagsKey, value: boolean | undefined) => {
    setLocalFlags(({ ...prev }) => {
      if (value === undefined) {
        // remove local flag override
        const { [flag]: _, ...otherFlags } = prev;
        return otherFlags;
      }
      return { ...prev, [flag]: value };
    });
  }, []);

  useEffect(() => {
    // Update server-side FFs with LaunchDarkly on mount if in development
    if (isDevelopment) {
      axios.post('http://localhost/ldflags');
    }
  }, []);

  return (
    <featureFlagsContext.Provider value={{ ...flags, localFlags, setLocalFlag }}>
      {children}
    </featureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const ctx = useContext(featureFlagsContext);

  if (!ctx) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }

  return ctx;
};
