import { useMutation, MutationHookOptions } from '@apollo/client';

import { ProfessorSurveyCreateData, ProfessorSurveyCreateInput } from '@tw/types';

import { PROFESSOR_SURVEY_CREATE } from '../../@graphql';

export const useProfessorSurveyCreate = (
  options?: MutationHookOptions<ProfessorSurveyCreateData, ProfessorSurveyCreateInput>,
) =>
  useMutation<ProfessorSurveyCreateData, ProfessorSurveyCreateInput>(
    PROFESSOR_SURVEY_CREATE,
    options,
  );
