import styled from '@emotion/styled';

export const EmptyTableText = styled.div(({ theme }) => ({
  padding: theme.baseUnit * 5,
  textAlign: 'center',
  color: theme.colors.secondary,
}));

export const TableFooterWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.baseUnit,
  fontSize: theme.fontUnit * 4,
}));
