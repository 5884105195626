import styled from '@emotion/styled';
import { Col, Row } from 'antd';

export const DrawerDetailItemRow = styled(Row)(({ theme }) => ({
  flexWrap: 'nowrap',
  marginBottom: theme.fontUnit,
}));

export const DrawerDetailIconWrapper = styled(Col)(({ theme }) => ({
  fontSize: theme.fontUnit * 3.5,
  color: theme.colors.lightIcon,
  display: 'flex',
}));
