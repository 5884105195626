import { useCallback, useMemo } from 'react';
import {
  LazyQueryHookOptions,
  QueryHookOptions,
  QueryLazyOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';

import { RawTutorFilters, TutorFilters, TutorPaginatedRawData } from '@tw/types';
import { tutorModel } from '@tw/services/academicService';

import { FetchTutorsQuery } from './FetchTutors.query';

export const useTutors = (
  variables?: TutorFilters,
  additionalArgs?: QueryHookOptions<TutorPaginatedRawData, RawTutorFilters>,
) => {
  const { data: rawData, ...rest } = useQuery<TutorPaginatedRawData, RawTutorFilters>(
    FetchTutorsQuery,
    {
      variables: tutorModel.transformTutorFilters(variables),
      ...additionalArgs,
    },
  );

  const data = useMemo(() => tutorModel.normalizeTutorRawPaginatedData(rawData?.tutors), [rawData]);

  return {
    ...rest,
    data,
  };
};

export const useTutorsLazy = (
  options?: LazyQueryHookOptions<TutorPaginatedRawData, TutorFilters>,
) => {
  const { variables = {}, ...additionalOptions } = options || {};
  const [fetchTutorsRaw, { data: rawData, ...rest }] = useLazyQuery<
    TutorPaginatedRawData,
    RawTutorFilters
  >(FetchTutorsQuery, {
    variables: tutorModel.transformTutorFilters(variables),
    ...additionalOptions,
  });

  const fetchTutors = useCallback(
    (opts?: QueryLazyOptions<TutorFilters>) => {
      const { variables: v, ...otherOptions } = opts || {};
      fetchTutorsRaw({ ...otherOptions, variables: tutorModel.transformTutorFilters(v) });
    },
    [fetchTutorsRaw],
  );

  const data = useMemo(() => tutorModel.normalizeTutorRawPaginatedData(rawData?.tutors), [rawData]);

  return {
    ...rest,
    fetchTutors,
    data,
  };
};
