import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { TWFlexContainer, TWHeading3 } from '@tw/components/presentational';
import TWIcon from '../TWIcon';

export const Container = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.border}`,
  borderRadius: theme.baseUnit,
  display: 'block',
  maxWidth: '100%',
}));

export const CountContainer = styled.div<{ isEditMode?: boolean }>(({ isEditMode, theme }) => ({
  alignItems: 'center',
  backgroundColor: isEditMode ? theme.brandColors.headerBgColor : theme.colors.grayBackground,
  color: isEditMode ? theme.colors.white : theme.colors.text,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: 68,
  padding: '0.75rem',
  borderRadius: '0.5rem 0.5rem 0 0',
}));

export const Count = styled(TWHeading3)({
  color: 'inherit',
});

export const HeaderCell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4px;
`;

export const LastUpdate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  padding-bottom: 15px;
`;

export const LoadingContainer = styled(TWFlexContainer)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderBottom: `1px solid ${theme.colors.border}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50vh',
}));

export const Grid = styled.div<{ withColumnOrdering?: boolean }>(({ withColumnOrdering }) => ({
  display: 'grid',
  gridTemplateColumns: withColumnOrdering ? 'auto 1fr' : '1fr',
}));

export const TableContainer = styled.div({
  display: 'block',
  whiteSpace: 'nowrap',
  WebkitOverflowScrolling: 'touch',
  overflowX: 'auto',
  overflowY: 'hidden',
  maxWidth: '100%',
});

export const Table = styled.table<{ isPinned?: boolean }>(({ isPinned, theme }) => ({
  borderCollapse: 'collapse',
  borderRight: isPinned ? `2px solid ${theme.colors.border}` : undefined,
  fontVariantNumeric: 'lining-nums tabular-nums',
  width: '100%',
  position: isPinned ? 'sticky' : undefined,
  left: isPinned ? 0 : undefined,
  '.data-table-group-parent': {
    borderLeft: `1px solid ${theme.colors.border}`,
    borderRight: `1px solid ${theme.colors.border}`,
  },
  '.data-table-group-child-start': {
    borderLeft: `1px solid ${theme.colors.border}`,
  },
  '.data-table-group-child-end': {
    borderRight: `1px solid ${theme.colors.border}`,
  },
  '.data-table-row-hover': {
    backgroundColor: theme.colors.hoverBackground,
  },
}));

export const Thead = styled.thead<{}>();

export const Tbody = styled.tbody<{}>();

export const Tr = styled.tr<{ isHeader?: boolean; isClickable?: boolean; isEditMode?: boolean }>(
  ({ isHeader = false, isClickable = false, isEditMode = false, theme }) => css`
    background-color: ${isHeader ? theme.colors.background : theme.colors.white};
    height: ${isHeader ? '50px' : undefined};
    &:hover {
      cursor: ${isClickable ? 'pointer' : 'default'};
      background-color: ${isHeader || isEditMode ? 'transparent' : theme.colors.hoverBackground};
    }
  `,
);

export const Th = styled.th<{ w?: number; sortable: boolean }>(({ w, sortable, theme }) => ({
  borderBottom: `1px solid ${theme.colors.border}`,
  color: theme.colors.secondary,
  backgroundColor: theme.colors.background,
  cursor: sortable ? 'pointer' : 'default',
  fontSize: 12,
  fontWeight: theme.fontWeight.bold,
  lineHeight: '1.25rem',
  padding: '0.75rem',
  textAlign: 'start',
  whiteSpace: 'nowrap',
  width: w,
}));

const getTdBackground = (
  theme: Theme,
  wasEdited?: boolean,
  isEditMode?: boolean,
  canEdit?: boolean,
) => {
  if (wasEdited) {
    return theme.colors.warningBackground;
  }
  if (isEditMode && !canEdit) {
    return theme.colors.disabledBackground;
  }
  return undefined;
};

export const Td = styled.td<{
  canEdit?: boolean;
  isEditMode?: boolean;
  isSelected?: boolean;
  wasEdited?: boolean;
  hasError?: boolean;
}>(({ canEdit, isEditMode, isSelected, wasEdited, hasError, theme }) => ({
  background: getTdBackground(theme, wasEdited, isEditMode, canEdit),
  borderBottom: `1px solid ${theme.colors.border}`,
  borderRightColor: theme.colors.border,
  borderRightWidth: isEditMode ? 1 : 0,
  borderRightStyle: isEditMode ? 'solid' : undefined,
  boxShadow:
    isSelected && !hasError
      ? `inset 0 0 0 3px ${theme.colors.focus}, 0 0 0 4px ${theme.colors.white}`
      : undefined,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  maxWidth: '50vw',
  padding: '1rem 0.75rem',
  outline:
    isSelected || hasError
      ? `2px solid ${hasError ? theme.colors.attention : theme.colors.focus}`
      : undefined,
  outlineOffset: '-2px',
  textAlign: 'start',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '.profile-link div:hover': {
    backgroundColor: theme.colors.hoverBackground,
    cursor: 'pointer',
  },
}));

export const Pagination = styled.div<{}>({
  padding: '1rem 0.75rem',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const SelectedRowsStatus = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.infoBackground,
  borderBottom: `1px solid ${theme.colors.border}`,
  color: theme.colors.text,
  fontSize: 14,
  fontWeight: theme.fontWeight.bold,
  padding: '0.75rem 0',
  textAlign: 'center',
}));

export const SortIcon = styled(TWIcon)({
  width: 16,
  height: 16,
  fontSize: 16,
  fontWeight: 800,
});

export const UpdateCount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  & > strong {
    display: flex;
    flex-direction: row;
    align-items: start;
    column-gap: 8px;
    font-size: 18px;
    font-weight: 500;
    padding-top: 10px;

    & > .material-icons-outlined {
      padding-top: 4px;
      font-size: 18px;
    }
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const UpdatesSummary = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.baseUnit * 2,
}));

export const CellContent = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`;
