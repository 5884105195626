import { Checkbox } from 'antd';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';

interface TWQuickFormCheckBoxesProps {
  schema: {
    title?: string;
    isEmptyQuestion?: boolean;
  };
  id: string;
  options: {
    enumOptions?: {
      label: string;
      value: string;
    }[];
  };
  value: string[];
  required: boolean;
  readonly: boolean;
  disabled: boolean;
  autofocus: boolean;
  onChange: (value: string[]) => void;
}

const TWQuickFormCheckBoxes = (props: TWQuickFormCheckBoxesProps) => {
  const { id, disabled, options, value, autofocus = false, readonly, onChange, schema } = props;
  const { enumOptions } = options;

  // HACK: don't show empty options that we generate to make jsonschema validation happy with
  // empty checkbox questions (used as section headers)
  if (schema?.isEmptyQuestion) {
    return null;
  }

  const selectValue = (newValue: string, selected: string[]) => {
    if (enumOptions) {
      const all = enumOptions.map((checkbox) => checkbox.value);
      const at = all.indexOf(newValue);
      const updated = selected.slice(0, at).concat(newValue, selected.slice(at));
      // As inserting values at predefined index positions doesn't work with empty
      // arrays, we need to reorder the updated selection to match the initial order
      return updated.sort((a, b) => all.indexOf(a) - all.indexOf(b));
    }
    return selected;
  };

  const deselectValue = (newValue: string, selected: string[]) =>
    selected.filter((v) => v !== newValue);

  return (
    <div className="checkboxes" id={id}>
      {enumOptions?.map((option, index) => {
        const validatedValue = Array.isArray(value) || typeof value === 'string' ? value : [];
        const checked = !!(validatedValue && validatedValue.indexOf(option.value) !== -1);

        return (
          <Container key={option.value}>
            <Checkbox
              disabled={disabled || readonly}
              checked={checked}
              id={`${id}_${index}`}
              autoFocus={autofocus && index === 0}
              onChange={(event) => {
                if (event.target.checked) {
                  onChange(selectValue(option.value, value));
                } else {
                  const newValues = deselectValue(option.value, value);
                  onChange(!isEmpty(newValues) ? newValues : []);
                }
              }}
            >
              {option.label}
            </Checkbox>
          </Container>
        );
      })}
    </div>
  );
};

const Container = styled.span(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row',
  margin: `${theme.baseUnit}px`,
}));

export default TWQuickFormCheckBoxes;
