import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useEventListener } from '@tw/hooks';
import { SelectionItemInterface, SelectionItemsByCode } from '@tw/components/utils';
import { KEY_CODES } from '@tw/constants';
import { functionUtils } from '@tw/util';

const arrowKeyDirections = {
  UP: 'UP',
  DOWN: 'DOWN',
};

interface SelectionItemProps {
  label: string;
}

// outside of component because of memoization / search results cache
// need to decouple from re-renders
const userSelectionFilterBySearchQuery = functionUtils.memoizeFilterWithSearchTermFn(
  (listToSearch: object[], searchQuery: string) =>
    _.filter(listToSearch, (selectionItem: SelectionItemProps) =>
      functionUtils.searchTermStartsWordInString(selectionItem.label, searchQuery),
    ),
);

interface BuildSelectionTreeByCursorPositionProps {
  groups: object[];
  expandedBranches: string[];
  searchFilter: string;
}

const buildSelectionTreeByCursorPosition = ({
  groups,
  expandedBranches,
  searchFilter,
}: BuildSelectionTreeByCursorPositionProps) => {
  const selectionTreeByCursorPosition = [];

  const assignPositionToItem = (item) => {
    if (_.has(item, 'people')) {
      const branchId = `${item.selectionCode}-${item.label}`;

      // Don't add branchId as a selectable if we have a search term...
      // the branches aren't expandable when searching, and shouldn't be selectable
      selectionTreeByCursorPosition.push({
        ...item,
        clientId: _.isEmpty(searchFilter) ? branchId : item.selectionCode,
      });

      if (_.includes(expandedBranches, branchId) && !searchFilter) {
        selectionTreeByCursorPosition.push({
          ...item,
          clientId: item.selectionCode,
        });

        _.forEach(item.people, assignPositionToItem);
      }

      if (searchFilter) {
        _.forEach(item.people, assignPositionToItem);
      }

      return true;
    }

    selectionTreeByCursorPosition.push({
      ...item,
      clientId: item.selectionCode,
    });

    return true;
  };

  _.forEach(groups, assignPositionToItem);

  return selectionTreeByCursorPosition;
};

interface UseKeyboardNavigationProps {
  groups: object[];
  TreeEl: HTMLElement;
  highlightedElRef: HTMLElement;
  itemOnSelect: (selectionItem: SelectionItemInterface) => void;
  selections: SelectionItemsByCode;
  resetAfterSelection?: boolean;
  active?: boolean;
  searchFilter?: string;
  groupTypeFilter?: string;
  visible?: boolean;
}

const useKeyboardNavigation = ({
  groups,
  TreeEl,
  highlightedElRef,
  itemOnSelect,
  selections,
  searchFilter = '',
  groupTypeFilter = '',
  resetAfterSelection = true,
  visible = false,
}: UseKeyboardNavigationProps) => {
  const [usingMouse, setUsingMouse] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [arrowKeyDirection, setArrowKeyDirection] = useState(null);
  const [expandedBranches, setExpandedBranches] = useState([]);
  const [filteredSelectionItems, setFilteredSelectionItems] = useState([]);
  const [scrollHeightMargin, setScrollHeightMargin] = useState(0);

  const resetPositionToTop = () => {
    setScrollHeightMargin(0);
    setArrowKeyDirection(null);
    setCursorPosition(null);
  };

  const handleExpandBranch = ({ selectionCode, label }) => {
    const branchId = `${selectionCode}-${label}`;
    if (!_.includes(expandedBranches, branchId)) {
      setExpandedBranches([...expandedBranches, branchId]);
      const groupCursorPosition = _.findIndex(filteredSelectionItems, ['clientId', branchId]);
      if (!_.isNull(cursorPosition)) {
        setCursorPosition(groupCursorPosition);
      }
    }
    if (_.includes(expandedBranches, branchId)) {
      const filteredBranches = _.filter(expandedBranches, (n) => n !== branchId);
      setExpandedBranches(filteredBranches);
      const groupCursorPosition = _.findIndex(filteredSelectionItems, ['clientId', branchId]);
      if (!_.isNull(cursorPosition)) {
        setCursorPosition(groupCursorPosition);
      }
    }
  };

  const handleMouseEvent = () => {
    setUsingMouse(true);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (visible) {
      setUsingMouse(false);
      const listLength = _.values(filteredSelectionItems).length;
      const selectionItem = filteredSelectionItems[cursorPosition];

      if (e.code === KEY_CODES.ENTER) {
        e.preventDefault();
        if (!_.isEmpty(selectionItem)) {
          // Covers pressing enter without initial arrowing down into tree
          const { clientId, selectionCode, label } = selectionItem;
          const branchId = `${selectionCode}-${label}`;

          if (clientId === branchId) {
            handleExpandBranch(selectionItem);
            return;
          }

          itemOnSelect(selectionItem);
        }
      }

      if (e.code === KEY_CODES.ARROW_UP && cursorPosition > 0) {
        e.preventDefault();
        setArrowKeyDirection(arrowKeyDirections.UP);
        setCursorPosition(cursorPosition - 1);
      } else if (e.code === KEY_CODES.ARROW_DOWN && cursorPosition < listLength - 1) {
        e.preventDefault();
        setArrowKeyDirection(arrowKeyDirections.DOWN);
        setCursorPosition(cursorPosition + 1);
      }

      // don't highlight element on initial render.  wait until
      // an up/down arrow key is pressed to set the cursor position to 0
      if (
        (e.code === KEY_CODES.ARROW_DOWN || e.code === KEY_CODES.ARROW_UP) &&
        _.isNull(cursorPosition)
      ) {
        e.preventDefault();
        setCursorPosition(0);
      }
    }
  };

  useEventListener('click', handleMouseEvent, TreeEl);

  useEventListener('keydown', handleKeyDown);

  // Programatically scroll based on position of highlighted element in relation to the scroll container
  useEffect(() => {
    if (highlightedElRef) {
      let selectionItemHeight = 0;
      if (highlightedElRef.offsetHeight <= 36) {
        selectionItemHeight = highlightedElRef.offsetHeight;
      }
      // height of tree container
      const treeTop = TreeEl.offsetHeight;
      // pixels away from top of tree container
      const highlightedElRefTop = highlightedElRef.offsetTop;
      // remaining pixels between bottom of tree container and highlighted element
      const remainderMargin = treeTop - (highlightedElRef.offsetTop - TreeEl.scrollTop);

      // threshold of pixels away from top of tree container past which a scroll action needs to occur
      if (
        arrowKeyDirection === arrowKeyDirections.DOWN &&
        // if highlighted element's pixels away from top of tree container is greater than
        // the threshold then set new scroll position and increase scrollHeightMargin
        treeTop + scrollHeightMargin - (highlightedElRefTop + selectionItemHeight) < 0
      ) {
        TreeEl.scrollTop = TreeEl.scrollTop + selectionItemHeight - remainderMargin;
        setScrollHeightMargin(scrollHeightMargin + selectionItemHeight);
      }

      if (
        arrowKeyDirection === arrowKeyDirections.UP &&
        highlightedElRefTop - scrollHeightMargin < 0
      ) {
        highlightedElRef.scrollIntoView();
        if (scrollHeightMargin > 0) {
          setScrollHeightMargin(scrollHeightMargin - selectionItemHeight);
        }
      }
    }
  }, [TreeEl, arrowKeyDirection, highlightedElRef, scrollHeightMargin]);

  // build selection tree by cursor position and filter appropriately
  useEffect(() => {
    let filteredSelectionItemsList = [];

    filteredSelectionItemsList = buildSelectionTreeByCursorPosition({
      groups,
      expandedBranches,
      searchFilter,
    });

    if (searchFilter) {
      filteredSelectionItemsList = userSelectionFilterBySearchQuery(
        filteredSelectionItemsList,
        searchFilter,
      );
    }

    if (groupTypeFilter) {
      filteredSelectionItemsList = _.filter(
        filteredSelectionItemsList,
        (item) => !_.has(item, 'people') || item.groupType === groupTypeFilter,
      );
    }

    setFilteredSelectionItems(filteredSelectionItemsList);
  }, [groups, searchFilter, groupTypeFilter, expandedBranches]);

  useEffect(() => {
    if (!usingMouse) {
      resetPositionToTop();
    }
  }, [usingMouse]);

  useEffect(() => {
    resetPositionToTop();
  }, [searchFilter, groupTypeFilter]);

  useEffect(() => {
    if (resetAfterSelection) {
      setExpandedBranches([]);
      setFilteredSelectionItems([]);
      resetPositionToTop();
    }
  }, [resetAfterSelection, selections, visible]);

  return { cursorPosition, handleExpandBranch, expandedBranches, filteredSelectionItems };
};

export default useKeyboardNavigation;
