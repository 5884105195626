import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row } from 'antd';
import _ from 'lodash';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';

import { getTranslation } from '@tw/i18n';
import { TWButton, TWLoadingMask, TWMessage, TWFlexContainer } from '@tw/components';
import { FormAssignmentNode } from '@tw/generated';

import { ISubmitEvent } from 'react-jsonschema-form';
import { COMPLETE_FORM } from '../graphql';
import { TemplateStorageProps, routeMap, LocationWithState } from '../../Forms.definitions';
import { QuickformStyleWrapper, QuickformContainer } from '../../Forms.styles';
import QuickFormNew from '../../QuickForm/QuickFormNew';
import { FormHeading } from '../../FormHeading';
import { FormSubmitData } from '../../QuickForm/QuickForm.definitions';

const containerId = 'Complete_Quickform_Screen';

interface CompleteQuickformGQLProps {
  completionRoleId: string;
  externalEmail?: string;
  externalName?: string;
  isExternalRequest?: boolean;
  quickformTemplate: TemplateStorageProps;
  status?: string;
  uuid?: string;
  signedDownloadUrl?: string;
  assignment: FormAssignmentNode;
}

const CompleteQuickformGQL = ({
  completionRoleId,
  externalName,
  isExternalRequest,
  quickformTemplate,
  status,
  uuid,
  signedDownloadUrl,
  assignment,
}: CompleteQuickformGQLProps) => {
  const history = useHistory();
  const location = useLocation<LocationWithState>();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [completeForm, { loading: completeFormMutationLoading }] = useMutation(COMPLETE_FORM, {
    refetchQueries: ['GetTasksCount', 'GetTasks'],
    onCompleted: () => {
      if (isExternalRequest) {
        setSubmitSuccess(true);
        history.go(0);
        return;
      }

      const matchingRoute = Object.keys(routeMap).find((key) => location?.state?.[key]);
      const { pathname, state } = matchingRoute
        ? routeMap[matchingRoute]
        : routeMap.fromAssignedToMe;

      history.replace(pathname, state);
      TWMessage.success(getTranslation('forms.formSubmitted'));
    },
    onError: (error) => TWMessage.errorHandler(error),
  });

  const handleSubmitQuickForm = (formSubmitData: ISubmitEvent<FormSubmitData>) => {
    const {
      schema: { properties },
      uiSchema,
    } = quickformTemplate;
    const { formData } = formSubmitData;

    Object.keys(properties)?.forEach((question) => {
      if (!(question in formData)) {
        // @ts-ignore
        formData[question] = undefined;
      }
    });

    // add enum options to formData/sAnswers
    // add answer order to formData as well
    // include the 'ui:order' array from the uiSchema so the questions/answers are in the proper order on the PDF
    const answerData = _.map(formData, (answerValue, name) => {
      const answerType = uiSchema[name]['ui:widget'];
      const visibleQuestions = formSubmitData.schema.properties;
      let options = [];
      let answer = answerValue;

      if (!answer || !visibleQuestions[name]) {
        answer = '';
      }
      switch (answerType) {
        case 'radio':
        case 'dropdown':
          options = properties[name].enum;
          break;
        case 'checkboxes':
          options = properties[name].items.enum;
          break;
        case 'range':
          options = _.range(properties[name].minimum, properties[name].maximum + 1);
          break;
        default: {
          break;
        }
      }

      return {
        name,
        answer,
        answerType: answerType ?? '',
        order: _.indexOf(uiSchema['ui:order'], name),
        quickformDataStr: JSON.stringify({
          options,
        }),
      };
    });

    // Defensive code to fix corrupt questions with no answerType (FORM-1348)
    const cleanAnswerData = answerData.filter((answer) => answer.answerType);

    const sortedAnswerData = _.sortBy(cleanAnswerData, ['order']);
    const variables = {
      input: {
        completionRole: completionRoleId,
        answerData: sortedAnswerData,
      },
    };
    if (uuid) {
      _.set(variables.input, 'externalLinkUuid', uuid);
    }

    completeForm({ variables });
  };

  return completeFormMutationLoading ? (
    <TWLoadingMask />
  ) : (
    <>
      <TWFlexContainer>
        <QuickformHeadingContainer>
          <FormHeading assignment={assignment} />
        </QuickformHeadingContainer>
        <QuickformStyleWrapper isExternal={isExternalRequest} id={containerId}>
          <QuickformContainer>
            <QuickFormNew
              quickFormTemplate={quickformTemplate}
              onSubmit={handleSubmitQuickForm}
              status={status}
              submitSuccess={submitSuccess}
              isExternalRequest={isExternalRequest}
              signedDownloadUrl={signedDownloadUrl}
              externalName={externalName}
              assignment={assignment}
            >
              <QuickformRow justify="end">
                <TWButton testID="QuickForm:Submit" type="primary" htmlType="submit">
                  {getTranslation('submit')}
                </TWButton>
              </QuickformRow>
            </QuickFormNew>
          </QuickformContainer>
        </QuickformStyleWrapper>
      </TWFlexContainer>
    </>
  );
};

const QuickformHeadingContainer = styled.div(({ theme }) => ({
  padding: '16px',
  borderBottom: `2px solid ${theme.colors.border}`,
  backgroundColor: theme.colors.white,
}));

const QuickformRow = styled(Row)({
  padding: '0px 25px 25px 0px',
});

export default CompleteQuickformGQL;
