import * as TWInputTheming from './TWInputTheming';
import * as TWIconTheming from './TWIconTheming';
import * as TWLightboxTheming from './TWLightboxTheming';
import * as TWListItemTheming from './TWListItemTheming';
import * as TWMessagingTheming from './TWMessagingTheming';
import * as TWPageContainerTheming from './TWPageContainerTheming';
import * as TWPrimaryTabsTheming from './TWPrimaryTabsTheming';
import * as TWSecondaryTabsTheming from './TWSecondaryTabsTheming';
import * as TWSectionTheming from './TWSectionTheming';
import * as TWTopbarTheming from './TWTopbarTheming';

const categoriesTheming = {
  ...TWIconTheming,
  ...TWInputTheming,
  ...TWLightboxTheming,
  ...TWListItemTheming,
  ...TWMessagingTheming,
  ...TWPageContainerTheming,
  ...TWPrimaryTabsTheming,
  ...TWSecondaryTabsTheming,
  ...TWSectionTheming,
  ...TWTopbarTheming,
};

// These are just random guesswork, for now.
const $color = {
  brandHighlight: '$colorHue.grayBlue',
  brandFieldPrimary: '$colorHue.grayVeryDark',
  brandFieldSecondary: '$colorHue.grayBlue',

  background: '$colorHue.grayDark',
  backgroundStrong: '$colorHue.grayVeryDark',
  backgroundInverse: '$colorHue.grayVeryDark',
  backgroundInverseStrong: '$colorHue.black',

  foreground: '#43484d',
  border: '$colorHue.grayBlue',

  text: '$colorHue.gray',
  textStrong: '#E1E8EE',
  textWeak: '$colorHue.graySilver',
  textInverse: '$colorHue.grayVeryDark',
  // no textInverseStrong or textInverseWeak are needed/used anywhere, yet
};

const allSourceValues = {
  $color,
};

export default {
  ...allSourceValues,
  ...categoriesTheming,
};
