import * as React from 'react';
import { TWSpacingContainer } from '..';

import { TWFormItemGroupingProps } from './TWFormItemGrouping.definitions';

const TWFormItemGrouping: React.FC<TWFormItemGroupingProps> = ({
  twMargin = [1.5, 0],
  children,
}) => <TWSpacingContainer twMargin={twMargin}>{children}</TWSpacingContainer>;

export default TWFormItemGrouping;
