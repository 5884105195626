/**
 * Builder combinators for creating valid API calls.
 *
 * This offers a way to build up API Calls as "partials" over the full set of options. This way
 * you can have, say, one constant, "orgsApi" that is defined for the orgs context, and then call
 * setModel to "persons", "orgs", "announcements", etc.
 *
 * This is a bit different from the rest of the code, intentionally. This code will become part of
 * a javascript client library and will also have SDK-equivalent libraries in Java and Python that
 * will allow people to write compliant API calls without thinking about it. Therefore its scope
 * and intent are somewhat aligned, but not parallel.
 */
import _ from 'lodash';

import { backendUtils, stringUtils } from '@tw/util';
import localStorage from '@tw/services/localStorage';

/*
  @FIXME: EXEMPT_ENDPOINTS can be replaced in favor of making the context
  optional. (TW-15974)
*/
const EXEMPT_ENDPOINTS = {
  auth: ['token'],
};

interface ASTOptions {
  ctx?: string;
  model?: string;
  objectId?: string;
  filterSpec?: { render: () => string };
  method?: 'GET' | 'DELETE' | 'POST' | 'PATCH';
  instanceMethodName?: string;
  classMethodName?: string;
  teamMemberId?: number;
  personId?: string | number;
  teamId?: string | number;
  data?: { _dataType?: unknown; render: () => unknown };
  files?: unknown;
  queryStringParams?: Record<string, unknown>;
  paginate?: boolean;
  oauthToken?: unknown;
}

// @FIXME: This is recreating the entire library each time it's run, with new instances
//         of all functions and everything.
const apiCallFactory = ({
  ctx,
  model,
  objectId,
  filterSpec,
  method,
  instanceMethodName,
  classMethodName,
  paginate,
  teamMemberId,
  personId,
  teamId,
  oauthToken,
  data,
  files,
  // @FIXME: Not sure this param is being treated right.
  queryStringParams,
}: ASTOptions) => ({
  /**
   * This class is used to build a validated URL up over one or more calls.
   *
   * Typical usage would be to start with a backendConfig, a context, and a model and then build calls
   * from there by calling set*() with the feature you want to set.  Before calling, check .isCallable
   * to make sure from a data validation standpoint that your URL is valid.
   *
   */
  baseUrl: `${backendUtils.API_BASE_URL}/api/v1`,
  method: method || 'GET',
  ctx: ctx || null,
  model: model || '',
  objectId: objectId || null,
  filterSpec: filterSpec || null,
  instanceMethodName: instanceMethodName || null,
  classMethodName: classMethodName || null,
  teamMemberId: teamMemberId || null,
  personId: personId || null,
  teamId: teamId || null,
  oauthToken: oauthToken || null,
  // paginate should default to true, so we check if it's not false, since `paginate || true` would always return true
  paginate: paginate !== false,
  data: data || {},
  files: files || null,
  queryStringParams,

  // checks to see if the current URL state is consistent and will result in a valid URL.
  // This doesn't guarantee that it's a real URL, but guarantees it fits the pattern.
  isCallable() {
    return (
      _.has(EXEMPT_ENDPOINTS, this.ctx) ||
      (!(this.ctx || this.model || !this.baseUrl) && // must have all of these
        !(_.isNull(this.personId) && _.isNull(this.teamMemberId)) && // must have credentials, but ids can be 0
        (!_.isNull(this.objectId) || this.instanceMethodName) && // cannot call an instance method without an object ref
        (_.isNull(this.objectId) || !this.classMethodName) && // cannot call a class method on an individual object
        (this.instanceMethodName || // cannot filter a method or a single object.
          this.classMethodName ||
          _.isNull(this.objectId) ||
          !this.filterSpec))
    );
  },

  // checks to see if this is either an instance or class method call.
  isMethodCall() {
    return (
      this.isCallable() && !(_.isNull(this.instanceMethodName) || _.isNull(this.classMethodName))
    );
  },

  getUrl() {
    let url: string = this.baseUrl;
    url += this.ctx ? `/${this.ctx}/${this.model}` : `/${this.model}`;

    if (!_.isNull(this.objectId)) {
      url += `/${this.objectId}`;
    }

    if (this.isMethodCall()) {
      if (!_.isNull(this.instanceMethodName)) {
        url += `/${this.instanceMethodName}`;
      } else {
        url += `/${this.classMethodName}`;
      }
    }

    if (!_.isNull(this.filterSpec)) {
      url += filterSpec?.render() ?? '';
    }

    if (this.method === 'GET') {
      // then use data as the query string, not post data.
      url += _.reduce(
        _.mapValues(this.data, encodeURIComponent), // encode the values in data as uri components
        (acc, v, k) => `${acc}&${k}=${v}`, // append the value to the url query string
        '?', // url query string begins with ?
      );
    }
    // @FIXME: This is probably the wrong place for this. Also, 'queryStringParams' isn't listed
    // or documented anywhere here, but it's standard elsewhere.
    if (this.queryStringParams) {
      const questionMarkIfNeeded = url.indexOf('?') === -1 ? '?' : '';
      url += `${questionMarkIfNeeded}${stringUtils.objectToQueryString(this.queryStringParams)}`;
    }

    return url;
  },

  getHeaders() {
    const token = localStorage.getAccessToken();
    const headers = _.isNull(this.teamId)
      ? {
          Authorization: `Bearer ${token}`,
          'X-TW-PersonId': String(this.personId),
        }
      : {
          Authorization: `Bearer ${token}`,
          'X-TW-PersonId': String(this.personId),
          'X-TW-TeamId': String(this.teamId),
        };
    return headers;
  },

  getData() {
    // eslint-disable-next-line no-underscore-dangle
    if (this.data?._dataType === 'update') {
      return this.data.render();
    }

    if (this.method !== 'GET') {
      return this.data;
    }
    return {};
  },
});

export default {
  apiCallFactory,
};
