import { updatedTheme as theme } from '@tw/components/utils';
import { CSSObject } from '@emotion/react';

// Represents style shims to be applied to every page. Used by the TWRouterPage components to set page-level styles.
// This is generally used to override component library styles in a more cascading way.

// THIS FILE HAS BEEN UPDATED TO USE THE UPDATEDTHEME FILE RATHER THAN THE ORIGINAL THEME FILE
const getPageStyleShims = (): CSSObject => ({
  a: {
    '&:focus': {
      textDecoration: 'none',
    },
    '&:hover': {
      '.trigger-indicator': {
        textDecoration: 'underline',
      },
    },
  },

  label: {
    marginBottom: 'unset',
    fontWeight: 'unset',
  },

  button: {
    marginLeft: theme.fontUnit * 2,
  },

  '.tw-input-search button': {
    marginLeft: 'unset',
  },

  // Barf emoji for these
  '.ant-drawer-open': { transform: 'none!important' },

  '.ant-drawer-body': {
    padding: 'unset',
    overflowX: 'hidden',
  },

  '.ant-form-vertical .ant-form-item-label, .ant-form-item-label': {
    lineHeight: 'unset',
    paddingBottom: theme.fontUnit * 1,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    '> label': {
      color: theme.colors.secondary,
      fontSize: theme.fontUnit * 3,
    },
  },
  '.ant-layout-sider': {
    '.ant-menu': {
      '.ant-menu-item': {
        height: theme.fontUnit * 14,
        lineHeight: `${theme.fontUnit * 14}px`,
        margin: 0,
      },
    },
  },
  '.ant-drawer-wrapper-body': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.ant-drawer-close': {
    left: 0,
    right: 'initial',
    '&:focus:active': {
      backgroundColor: theme.colors.background,
    },
  },
  '.ant-drawer-title': {
    color: theme.colors.secondary,
    fontSize: theme.fontUnit * 6,
    letterSpacing: -0.2,
    fontWeight: 'normal',
  },
  '.discard-changes-prompt': {
    '.anticon': {
      backgroundColor: 'transparent',
    },
    'svg, span.material-icons-outlined': {
      color: theme.colors.warningIcon,
    },
  },
  '.animatedPage': {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '10',
    transform: 'translate3d(100%, 0, 0)',
  },
  '.ant-layout-has-sider > div': {
    width: '100% !important',
  },
  '.animatedPage.ant-drawer-enter.ant-drawer-enter-active, .animatedPage.ant-scrolling-effect': {
    transform: 'translate3d(0, 0, 0)',
    transition: 'all 300ms ease-in-out',
  },
  '.animatedPage.ant-drawer-exit.ant-drawer-exit-active': {
    transform: 'translate3d(100%, 0, 0)',
    transition: 'all 300ms ease-in-out',
  },
});

export default {
  getPageStyleShims,
};
