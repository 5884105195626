import _ from 'lodash';

import { graphqlUtils } from '@tw/util';
import {
  SubjectPaginatedData,
  SubjectPaginatedRawData,
  SubjectQueryVariables,
  SubjectRawQueryVariables,
} from '@tw/types';

const normalizeSubjectVariables = (variables: SubjectQueryVariables): SubjectRawQueryVariables => {
  if (_.isEmpty(variables)) {
    return null;
  }

  const { subjectId, ...rest } = variables;
  return {
    subjectId: String(subjectId),
    ...rest,
  };
};

const normalizePaginatedSubjectData = (data: SubjectPaginatedRawData): SubjectPaginatedData => {
  if (_.isEmpty(data)) {
    return null;
  }

  const { subjects } = data;
  const { edges, pageInfo, totalCount } = subjects;

  return {
    subjects: graphqlUtils.convertEdgesToArray(edges),
    pageInfo,
    totalCount,
  };
};

export default {
  normalizeSubjectVariables,
  normalizePaginatedSubjectData,
};
