import { cloneDeep, isEmpty, set } from 'lodash';

import {
  CourseSortEnum,
  TreeSelectCourseBySubjectQueryQueryResult,
  useTreeSelectCourseBySubjectQueryQuery,
} from '@tw/generated';
import { getTranslation } from '@tw/i18n';
import { Course, Subject } from '@tw/types';
import { extractNodes } from '@tw/util/graphql';

import TWInputTreeSelect from '../TWInputTreeSelect';
import { TreeConfig } from '../TWInputTreeSelect/TWInputTreeSelect';

interface SubjectWithCourses extends Subject {
  courses: Course[];
}

const treeConfig: TreeConfig = {
  traversePath: ['subjects', 'courses'],
  config: {
    subjects: {
      labelExtractor: (item: Subject) => item && item.label,
      keyExtractor: (item: Subject) => item && `subject-${item.subjectId}`,
      valueExtractor: (item: Subject) => item && `subject-${item.subjectId}`,
    },
    courses: {
      labelExtractor: (item: Course) => item && item.label,
      keyExtractor: (item: Course) => item && `course-${item.courseId}`,
      valueExtractor: (item: Course) => item && `course-${item.courseId}`,
    },
  },
};

const groupCoursesBySubject = (
  courseData: TreeSelectCourseBySubjectQueryQueryResult['data'],
): SubjectWithCourses[] => {
  const courseList: Course[] = extractNodes(courseData?.courses);

  const coursesBySubjectId = courseList.reduce<Record<string, SubjectWithCourses>>(
    (subjects, course) => {
      const { subject } = course;
      const { subjectId } = subject;

      if (!subjects[subjectId]) {
        subjects[subjectId] = {
          ...subject,
          courses: [],
        };
      }

      subjects[subjectId].courses.push(course);

      return subjects;
    },
    {},
  );

  return Object.values(coursesBySubjectId);
};

interface TWInputTreeSelectCourseBySubjectProps {
  allowClear?: boolean;
  configOverrides?: object;
  defaultValue?: string[];
  filters: { sort?: CourseSortEnum | CourseSortEnum[] };
  isDisabled?: boolean;
  onChange?: (selection: string) => void;
  placeholder?: string;
  populateOnMount?: boolean;
  popupContainerId: string;
  showSearch?: boolean;
  testID?: string;
}

export const TWInputTreeSelectCourseBySubject = ({
  configOverrides = {},
  isDisabled,
  popupContainerId,
  placeholder,
  onChange,
  defaultValue,
  filters = {},
  // props we don't need to pass to TWInputTreeSelect
  ...allUnrecognizedProps
}: TWInputTreeSelectCourseBySubjectProps) => {
  // Note we're using customer_key here to sort. We should be careful if there's some ticket in the future that
  // says we're not sorting correctly, as we're relying on customer_key to be a composition of the course name
  // and section. If this is not the case moving forward, backend should try to expose a sorting for the +1
  // field course.label, and shortCode...
  const { loading, data } = useTreeSelectCourseBySubjectQueryQuery({
    variables: { ...filters, sort: filters.sort || CourseSortEnum.CustomerKeyAsc },
  });

  let courseListBySubject: SubjectWithCourses[] = [];

  if (data) {
    courseListBySubject = groupCoursesBySubject(data);
  }

  const getTreeConfig = () => {
    if (isEmpty(configOverrides)) {
      return treeConfig;
    }

    const newConfig = cloneDeep(treeConfig);
    Object.entries(configOverrides).forEach((value, path) => {
      set(newConfig.config, path, value);
    });

    return newConfig;
  };

  return (
    <TWInputTreeSelect
      allowClear
      placeholder={placeholder || getTranslation('courses', 1)}
      treeConfig={getTreeConfig()}
      disabled={isDisabled}
      treeData={courseListBySubject}
      loading={loading}
      onChange={(selection) => onChange?.(selection)}
      popupContainerId={popupContainerId}
      popupClassName="course-select-by-subject"
      defaultValue={defaultValue}
      {...allUnrecognizedProps}
    />
  );
};
