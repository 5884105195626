const getContainer = (containerId?: string) => {
  if (!containerId) {
    return document.body;
  }
  return document.getElementById(containerId) ?? document.body;
};

const getParentContainer = (element: HTMLElement, containerId?: string) => {
  if (!containerId) {
    return element.parentElement ?? document.body;
  }

  return document.getElementById(containerId) ?? document.body;
};

export default { getContainer, getParentContainer };
