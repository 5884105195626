import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@teamworksdev/react';

import { getTranslation } from '@tw/i18n';

interface TWInputSelectRowsPerPageProps {
  accessibilityLabel?: string;
  id?: string;
  options?: number[];
  value?: number;
  onChange?: (value: number) => void;
}

const TWInputSelectRowsPerPage = ({
  accessibilityLabel,
  id,
  onChange,
  options = [10, 25, 50, 100],
  value = 25,
}: TWInputSelectRowsPerPageProps) => (
  <Select value={value.toString()} onValueChange={(v) => onChange?.(+v)}>
    <SelectTrigger id={id} aria-label={accessibilityLabel}>
      <SelectValue placeholder={getTranslation('rowsPerPage')} />
    </SelectTrigger>
    <SelectContent>
      {options.map((option) => (
        <SelectItem key={option.toString()} value={option.toString()}>
          {getTranslation('nRowsPerPage', { smart_count: option })}
        </SelectItem>
      ))}
    </SelectContent>
  </Select>
);

export default TWInputSelectRowsPerPage;
