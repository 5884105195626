/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentBulkDeleteMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.AppointmentBulkDeleteInput>>
    | Types.InputMaybe<Types.AppointmentBulkDeleteInput>
  >;
}>;

export type AppointmentBulkDeleteMutation = {
  appointmentBulkDelete: {
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ message: string; field: Array<string> } | null> | null;
    }>;
  };
};

export const AppointmentBulkDeleteDocument = gql`
  mutation AppointmentBulkDelete($input: [AppointmentBulkDeleteInput]) {
    appointmentBulkDelete(input: $input) {
      bulkUserErrors {
        objectKey
        errorList {
          message
          field
        }
      }
    }
  }
`;
export type AppointmentBulkDeleteMutationFn = Apollo.MutationFunction<
  AppointmentBulkDeleteMutation,
  AppointmentBulkDeleteMutationVariables
>;

/**
 * __useAppointmentBulkDeleteMutation__
 *
 * To run a mutation, you first call `useAppointmentBulkDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentBulkDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentBulkDeleteMutation, { data, loading, error }] = useAppointmentBulkDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentBulkDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppointmentBulkDeleteMutation,
    AppointmentBulkDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AppointmentBulkDeleteMutation, AppointmentBulkDeleteMutationVariables>(
    AppointmentBulkDeleteDocument,
    options,
  );
}
export type AppointmentBulkDeleteMutationHookResult = ReturnType<
  typeof useAppointmentBulkDeleteMutation
>;
export type AppointmentBulkDeleteMutationResult =
  Apollo.MutationResult<AppointmentBulkDeleteMutation>;
export type AppointmentBulkDeleteMutationOptions = Apollo.BaseMutationOptions<
  AppointmentBulkDeleteMutation,
  AppointmentBulkDeleteMutationVariables
>;
