import { gql } from '@apollo/client';

export const FetchTutorsQuery = gql`
  query FetchTutors(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTutors: String
    $courseIds: [Int]
    $subjectIds: [Int]
    $isActive: TWFilter_Boolean
    $tutorId: TWFilter_ID
    $personId: TWFilter_Int
  ) {
    tutors(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTutors: $searchTutors
      courseIds: $courseIds
      subjectIds: $subjectIds
      isActive: $isActive
      tutorId: $tutorId
      personId: $personId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          tutorId: pk
          preferredName
          lastName
          personId
          fullName
          maximumNumberOfHours
          subjects {
            totalCount
            edges {
              cursor
              node {
                id
                subjectId: pk
                label
                description
                shortCode
                customerKey
              }
            }
          }
          person {
            id
            emailAddress
            teams {
              id
              teamId
              label
            }
            title
          }
          pictureDownloadUrl
          isActive
          courses {
            totalCount
            edges {
              cursor
              node {
                id
                courseId: pk
                credits
                label
                subject {
                  id
                  subjectId: pk
                  label
                  description
                  shortCode
                  customerKey
                }
                description
              }
            }
          }
        }
      }
    }
  }
`;
