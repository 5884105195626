import { Col, Dropdown, MenuProps, Row } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import omit from 'lodash/omit';
import size from 'lodash/size';
import some from 'lodash/some';
import union from 'lodash/union';
import { MouseEvent, cloneElement, useCallback, useEffect, useState } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';

import { getTranslation, useTranslator } from '@tw/i18n';
import { DOMUtils, paginationUtils } from '@tw/util';

import { TWButton, TWFlexContainer, TWHeading4, TWTextDefault } from '../../../presentational';
import TWIcon from '../../TWIcon';
import { TWButtonGroupPagination } from '../../buttons';
import { TWInputSelectRowsPerPage } from '../../inputs';
import { TWColumnFlex } from '../../layout';
import { ROWS_PER_PAGE_STORAGE_KEY } from '../@config';
import { TWDataTable } from '../TWDataTable';
import {
  PAGINATION_FILTERS,
  RollbackGenericSelectionQuery,
  RollbackSelectionState,
  TWDataTableRollbackProps,
} from './TWDataTableRollback.definitions';
import {
  BulkActionColumn,
  BulkActionDropdownColumn,
  BulkDropdownButton,
  BulkDropdownIcon,
  BulkDropdownTitleText,
  Container,
  HeaderColumn,
  HeaderRow,
} from './TWDataTableRollback.styles';
import { hasSelectIgnore, hasSelectionPrimaryKey } from './TWDataTableRollback.utils';

export function TWDataTableRollback<T extends object>(props: TWDataTableRollbackProps<T>) {
  const {
    children,
    containerId,
    totalCount,
    hasNextPage = false,
    defaultSelections = [],
    filters,
    headerLabel,
    testID = null,
    filters: { first: rowsPerPage },
    bulkActions = [],
    selectionPrimaryKey,
    onSelectionChanged,
    dataSource,
    loading = false,
    renderHeaderExtra,
    resetSelectionsOnFilterChange = true,
    onFilterChange,
    nextCursor,
    onPageChange,
    bulkActionsForCurrentPage = false,
    rowsPerPageOptions,
    clearSelection = false,
    bulkActionModal = false,
    bulkActionDropdown = false,
    bulkActionDropdownTitle = null,
    ...allUnrecognizedProps
  } = props;

  const prevFilters = usePrevious(filters);
  const lastTotalCount = usePrevious(totalCount);
  const translator = useTranslator();

  const [selections, setSelections] = useState<RollbackSelectionState<T>>({
    included: true,
    keys: defaultSelections || [],
    records: [],
  });

  const setSelectionState = useCallback(
    (update: Partial<RollbackSelectionState<T>>) => setSelections({ ...selections, ...update }),
    [selections],
  );

  const [cursorHistory, setCursorHistory] = useState<(string | null)[]>([]);

  useEffectOnce(() => {
    const storageRowsPerPage = Number(localStorage.getItem(ROWS_PER_PAGE_STORAGE_KEY));
    if (storageRowsPerPage) {
      onFilterChange({
        ...filters,
        first: storageRowsPerPage,
      });
    }
  });

  useEffect(() => {
    if (!filters.after) {
      setCursorHistory([]);
    }
  }, [filters.after]);

  useEffect(() => {
    if (resetSelectionsOnFilterChange && prevFilters) {
      // if prevFilters is falsy, this is the first render and we should skip
      const filtersNoPagination = omit(filters, PAGINATION_FILTERS);
      const prevFiltersNoPagination = omit(prevFilters, PAGINATION_FILTERS);
      if (!isEqual(filtersNoPagination, prevFiltersNoPagination)) {
        // This is safe because it is only called when non-paginated filters change.
        // Will make more sense when we can use useEffect instead
        // setSelectionIsWhiteListed(true);
        // setSelectionKeys([]);
        setSelections({ included: true, keys: [], records: [] });
      }
    }
  }, [resetSelectionsOnFilterChange, filters, prevFilters]);

  useEffect(() => {
    if (clearSelection) {
      setSelectionState({ keys: [], records: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSelection]);

  const handleSelectAll = (selectAll: boolean) => {
    if (!bulkActionsForCurrentPage) {
      setSelections({ included: !selectAll, keys: [], records: [] });
      return;
    }

    const dataIdsOnPage = map(
      filter(dataSource, (item) => !hasSelectIgnore(item) || !item.selectIgnore),
      selectionPrimaryKey as string,
    );
    const dataOnPage = filter(dataSource, (item) => !hasSelectIgnore(item) || !item.selectIgnore);

    if (selectAll) {
      setSelections((prevState) => ({
        included: true,
        keys: union(prevState.keys, dataIdsOnPage),
        records: dataOnPage,
      }));
    } else {
      const newSelection = selections.keys.filter((item) => !dataIdsOnPage.includes(item));
      const recordsSelection = dataSource?.filter((record) =>
        newSelection.includes(record[selectionPrimaryKey] as string | number),
      );

      setSelections({
        included: true,
        keys: newSelection,
        records: recordsSelection ?? [],
      });
    }
  };

  const handleOnSelect = (record: T, selected: boolean) => {
    if (!hasSelectionPrimaryKey(record, selectionPrimaryKey as string)) {
      return;
    }

    let updateRecord: T[] = [...selections.records, record];
    let update: (string | number)[] = [...selections.keys, record[selectionPrimaryKey]];

    // only selected or only included
    if ((selected && !selections.included) || (selections.included && !selected)) {
      update = filter(selections.keys, (key) => key !== record[selectionPrimaryKey]);
      updateRecord = selections.records.filter(
        (selectionRecord) => selectionRecord[selectionPrimaryKey] !== record[selectionPrimaryKey],
      );
    }

    setSelectionState({ keys: update, records: updateRecord });
  };

  const handleSelectionChanged = useCallback(() => {
    if (!isFunction(onSelectionChanged)) {
      return;
    }

    const selectionQueryConfig = {
      [selectionPrimaryKey.toString()]: {
        isWhiteListed: selections.included,
        selectionKeys: selections.keys,
        records: selections.records,
        totalCount: totalCount ?? 0,
      },
    };

    onSelectionChanged(selectionQueryConfig);
  }, [onSelectionChanged, selections, selectionPrimaryKey, totalCount]);

  useEffect(() => {
    handleSelectionChanged();
  }, [handleSelectionChanged]);

  const getHeaderText = () => {
    const totalRowCount = totalCount || 0;
    const ignoreRowCount = size(
      filter(dataSource, (item) => hasSelectIgnore(item) && item.selectIgnore),
    ); // rows which cannot be selected

    let countText = `${totalRowCount} ${headerLabel}`;

    if (size(bulkActions) > 0) {
      let selectedCount: number;
      // If only particular rows are selected we show their number
      if (selections.included) {
        selectedCount = size(selections.keys);
      } else if (ignoreRowCount) {
        // We show only the number of rows that can be selected, if a bulk checkbox is checked
        selectedCount = size(dataSource) - ignoreRowCount - size(selections.keys);
      } else {
        // Otherwise, we show the total number of rows without counting unselected single rows
        selectedCount = totalRowCount - size(selections.keys);
      }

      if (selectedCount > 0) {
        countText =
          selectedCount === totalRowCount
            ? getTranslation('numAllSelected', selectedCount)
            : getTranslation('numSelected', selectedCount);
      }
    }

    return <TWHeading4 data-testid="table-header-text">{countText}</TWHeading4>;
  };

  const menuItems = (): MenuProps['items'] => {
    const items = bulkActions.map<MenuItemType>((action, index) => {
      const { onClick } = action;
      const onClickFn: MenuItemType['onClick'] = (info) => {
        const selectionQueryConfig: RollbackGenericSelectionQuery = {
          [selectionPrimaryKey]: {
            isWhiteListed: selections.included,
            selectionKeys: selections.keys,
            records: selections.records,
            totalCount: totalCount ?? 0,
          },
        };

        onClick(selectionQueryConfig, info.domEvent);
        if (bulkActionsForCurrentPage && !bulkActionModal) {
          setSelections({ keys: [], included: true, records: [] });
        }
      };

      let text: string | undefined;
      let iconName: string | undefined;
      let disabled = false;

      if ('text' in action) {
        text = action.text;
        iconName = action.iconName;
        disabled = action.disabled ?? false;
      }

      return {
        key: text ?? index.toString(),
        onClick: onClickFn,
        'data-testid': `ActionMenu:Item:${iconName}`,
        disabled,
        label: (
          <TWFlexContainer row>
            {iconName ? <BulkDropdownIcon type={iconName} /> : null}
            <TWTextDefault>{text}</TWTextDefault>
          </TWFlexContainer>
        ),
      };
    });

    return bulkActionDropdownTitle
      ? [
          {
            type: 'group',
            label: <BulkDropdownTitleText>{bulkActionDropdownTitle}</BulkDropdownTitleText>,
            children: items,
          },
        ]
      : items;
  };

  const getBulkActions = () => {
    // If the bulkActions array is empty the nothing is rendered
    if (size(bulkActions) === 0) {
      return null;
    }

    const noRowsSelected = selections.included
      ? size(selections.keys) === 0
      : size(selections.keys) === size(dataSource);

    if (bulkActionDropdown) {
      return (
        <Dropdown
          overlayStyle={{ width: '200px', minWidth: 'unset' }}
          disabled={noRowsSelected}
          menu={{ items: menuItems() }}
          trigger={['click']}
          placement="bottomRight"
          getPopupContainer={() => DOMUtils.getContainer(containerId)}
        >
          <BulkDropdownButton
            maxWidth="none"
            accessibilityLabel="action-menu-open"
            testID="ActionMenu:Open"
          >
            <TWFlexContainer
              row
              justify="space-between"
              align="center"
              style={{ width: 'inherit' }}
            >
              <div>{translator.t('editSelected')}</div>
              <TWIcon type="material-keyboard_arrow_down" />
            </TWFlexContainer>
          </BulkDropdownButton>
        </Dropdown>
      );
    }

    return map(bulkActions, (action, key) => {
      const { onClick } = action;
      const onClickFn = (event?: MouseEvent<HTMLElement>) => {
        const selectionQueryConfig: RollbackGenericSelectionQuery<T> = {
          [selectionPrimaryKey.toString()]: {
            isWhiteListed: selections.included,
            selectionKeys: selections.keys,
            records: selections.records,
            totalCount: totalCount ?? 0,
          },
        };

        onClick(selectionQueryConfig, event);
        if (bulkActionsForCurrentPage && !bulkActionModal) {
          setSelections({ keys: [], included: true, records: [] });
        }
      };

      if ('customControl' in action) {
        const { customControl, ignoreSelections = false } = action;

        return cloneElement(customControl, {
          onClick: onClickFn,
          key,
          disabled: !ignoreSelections && noRowsSelected,
        });
      }

      const { text, iconName, disabled = false, loading: actionLoading = false } = action;

      return (
        <TWButton
          testID={`TimeManagement:Ticketing:GuestApproval:${action.text.replace(/\s+/g, '')}`}
          accessibilityLabel={text}
          type="default"
          disabled={noRowsSelected || disabled}
          key={text}
          onClick={onClickFn}
          loading={actionLoading}
          maxWidth={300}
        >
          <TWIcon type={iconName} />
          <TWTextDefault>{text}</TWTextDefault>
        </TWButton>
      );
    });
  };

  const getSelectedRowKeys = () => {
    // filtered list by rows which cannot be selected
    const newDataSource = filter(
      dataSource,
      (item) => !(hasSelectIgnore(item) && item.selectIgnore),
    );
    return newDataSource
      .map((item) => item[selectionPrimaryKey] as string | number)
      .filter((itemKey) =>
        selections.included
          ? includes(selections.keys, itemKey)
          : !includes(selections.keys, itemKey),
      );
  };

  const loadNextPage = () => {
    const { after: currentCursor } = filters;

    if (hasNextPage && cursorHistory.indexOf(currentCursor ?? null) === -1) {
      setCursorHistory((prev) => [...prev, currentCursor ?? null]);

      if (nextCursor) {
        onPageChange(nextCursor);
      }
    }
  };

  const loadPreviousPage = () => {
    const previousCursor = cursorHistory[cursorHistory.length - 1];
    setCursorHistory((prev) => prev.slice(0, -1));

    onPageChange(previousCursor ?? null);
  };

  const updateRowsPerPage = (newValue: number) => {
    const prevRowsPerPage = Number(localStorage.getItem(ROWS_PER_PAGE_STORAGE_KEY));
    // Don't write to storage if it hasn't changed.
    if (prevRowsPerPage !== newValue) {
      localStorage.setItem(ROWS_PER_PAGE_STORAGE_KEY, String(newValue));
    }

    setCursorHistory([]);

    onFilterChange({
      ...filters,
      first: newValue,
    });
  };

  const currentPage = size(cursorHistory);
  const hasPreviousPage = currentPage > 0;

  // This is in place so while the screen is loading, it is clearer to the user that we are
  // loading a subsequent page. While it loads, totalCount becomes null so keeping a copy of
  // it so we can achieve this desired clarity.
  const safeLastTotalCount = isNumber(totalCount) ? totalCount : Number(lastTotalCount);

  const rangeString = paginationUtils.getRangeOfRowsOnPage(
    currentPage,
    rowsPerPage ?? 0,
    safeLastTotalCount,
  );
  const currentPageString = safeLastTotalCount
    ? translator.t('xOfY', {
        x: rangeString,
        y: safeLastTotalCount,
      })
    : '';

  const showRowSelection = some(
    bulkActions,
    (action) => !('ignoreSelections' in action) || !action.ignoreSelections,
  );
  let rowSelection: TableRowSelection<T> = {};
  if (showRowSelection) {
    rowSelection = {
      type: 'checkbox',
      onSelectAll: (selected) => {
        handleSelectAll(selected);
      },
      selectedRowKeys: getSelectedRowKeys(),
      onSelect: handleOnSelect,
      getCheckboxProps: (record: T) => ({
        disabled: hasSelectIgnore(record) && record.selectIgnore, // Row configuration not to be checked
        'aria-label': translator.t('select'),
      }),
    };
  }

  return (
    <div aria-label={headerLabel}>
      <Container>
        <HeaderRow align="bottom" justify="space-between">
          {totalCount != null && totalCount > 0 && (
            <>
              <HeaderColumn>{getHeaderText()}</HeaderColumn>
              {!!renderHeaderExtra && <HeaderColumn>{renderHeaderExtra()}</HeaderColumn>}
              {!bulkActionDropdown && (
                <BulkActionColumn showSeparator={totalCount != null && totalCount > 0}>
                  {getBulkActions()}
                </BulkActionColumn>
              )}
            </>
          )}
          <TWColumnFlex>
            <Row align="middle" justify="end">
              {bulkActionDropdown && (
                <BulkActionDropdownColumn showSeparator={totalCount != null && totalCount > 0}>
                  {getBulkActions()}
                </BulkActionDropdownColumn>
              )}
              {currentPageString}
              <TWButtonGroupPagination
                loading={loading}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                onNextPage={loadNextPage}
                onPreviousPage={loadPreviousPage}
              />
            </Row>
          </TWColumnFlex>
        </HeaderRow>
        <TWDataTable
          size="middle"
          testID={testID ?? undefined}
          dataSource={dataSource}
          rowSelection={rowSelection}
          // selectionPrimaryKey is used as the rowKey to match the
          // rowSelection.selectedRowKeys array
          rowKey={selectionPrimaryKey.toString()}
          loading={loading}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...allUnrecognizedProps}
        >
          {children}
        </TWDataTable>
        <HeaderRow align="middle" justify="space-between">
          <Col className="rowsPerPageColumn">
            <TWInputSelectRowsPerPage
              value={rowsPerPage ?? undefined}
              options={rowsPerPageOptions}
              onChange={updateRowsPerPage}
              accessibilityLabel={translator.t('rowsPerPage')}
            />
          </Col>
          <Col>
            <Row align="middle" justify="end">
              {currentPageString}
              <TWButtonGroupPagination
                loading={loading}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                onNextPage={loadNextPage}
                onPreviousPage={loadPreviousPage}
              />
            </Row>
          </Col>
        </HeaderRow>
      </Container>
    </div>
  );
}
