import { Button } from 'antd';
import { forwardRef } from 'react';

import { ButtonContainer, ButtonContainerFlat } from './TWButton.styles';

import { TWButtonProps } from './TWButton.defintions';

const TWButton = forwardRef<HTMLButtonElement, TWButtonProps>(
  (
    {
      'aria-hidden': ariaHidden,
      className,
      accessibilityLabel,
      children,
      padding,
      maxWidth,
      style,
      tabIndex,
      testID,
      type = 'default',
      size = 'default',
      customStyles = {},
      disabled,
      ...restOfProps
    },
    ref,
  ) => (
    <ButtonContainer
      maxWidth={maxWidth}
      padding={padding}
      type={type}
      size={size}
      customStyles={customStyles}
      disabled={disabled}
    >
      <Button
        role="button"
        aria-label={accessibilityLabel}
        className={className}
        data-testid={testID}
        ref={ref}
        aria-hidden={ariaHidden}
        style={style}
        tabIndex={tabIndex}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restOfProps}
      >
        {children}
      </Button>
    </ButtonContainer>
  ),
);

const TWButtonIcon: React.FC<TWButtonProps> = ({
  children,
  accessibilityLabel,
  testID,
  ...allUnrecognizedProps
}) => (
  <TWButton
    testID={testID}
    accessibilityLabel={accessibilityLabel}
    padding={[0, 1]}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...allUnrecognizedProps}
  >
    {children}
  </TWButton>
);

const TWButtonIconFlat: React.FC<TWButtonProps> = ({
  children,
  disabled = false,
  ...allUnrecognizedProps
}) => (
  <ButtonContainerFlat>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <TWButton padding={0} shape="circle" disabled={disabled} {...allUnrecognizedProps}>
      {children}
    </TWButton>
  </ButtonContainerFlat>
);

export { TWButton, TWButtonIcon, TWButtonIconFlat };
