import _, { Cancelable, DebounceSettings } from 'lodash';

import { useCallback, useEffect, useState, useRef } from 'react';

export function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  delay = 0,
  options?: DebounceSettings,
): T & Cancelable {
  return useCallback(_.debounce(callback, delay, options), [callback, delay, options]);
}

export function useDebounce<T>(value: T, delay = 0, options?: DebounceSettings): T {
  const previousValue = useRef(value);
  const [current, setCurrent] = useState(value);
  const debouncedCallback = useDebouncedCallback((val: T) => setCurrent(val), delay, options);
  useEffect(() => {
    // does trigger the debounce timer initially
    if (value !== previousValue.current) {
      debouncedCallback(value);
      previousValue.current = value;
      // cancel the debounced callback on clean up
      return debouncedCallback.cancel;
    }

    return null;
  }, [value]);

  return current;
}
