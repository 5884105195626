import * as React from 'react';
import _ from 'lodash';
import { Route, Redirect } from 'react-router-dom';

import { useFlags } from '@tw/hooks';
import { HAS_REMOTE_DEBUGGING_CONSOLE } from '@tw/constants';
import { DebugLogger } from '@tw/util';

import { TWRouteMapProps, TWRouteProps } from './propTypes';
import { DEFAULT_RENDER_KEY, DISABLED_ROUTE_PREFIX } from './TWRoute.constants';
import TWUnauthorizedAccess from './errorPages/TWUnauthorizedAccess';

const debugLogger = new DebugLogger({
  debugEnabled: HAS_REMOTE_DEBUGGING_CONSOLE,
  debugHistoryEnabled: false,
  debugLabel: 'TWRouterRendererSwitch',
});

const TWRouteMap: React.FC<{
  routeMap: TWRouteMapProps;
  renderKey: string;
  isRequired: boolean;
}> = ({ routeMap, renderKey = DEFAULT_RENDER_KEY, isRequired, ...allUnrecognizedProps }) => {
  const flags = useFlags();
  const enabledRoutes = _.filter(routeMap, 'enabled');

  const isDefaultRenderKey = renderKey === DEFAULT_RENDER_KEY;
  const routeKeyComponentRequired = isRequired || isDefaultRenderKey;

  const routesToRender = _.map(
    enabledRoutes,
    ({ path: originalPath, spaPath, componentMap, routeMap: childRouteMap, exact, routeKey }) => {
      const disabledByLD = _.get(flags, `${DISABLED_ROUTE_PREFIX}${routeKey}`);
      const path = spaPath || originalPath;

      const ComponentToRender =
        routeKeyComponentRequired && disabledByLD
          ? TWUnauthorizedAccess
          : _.get(componentMap, renderKey);

      if (!ComponentToRender) {
        // Only print a warning if the renderKey passed is explicitly stated to be required, or if
        // the renderKey is 'main'. This lets us make optional entries in our route component maps.
        if (routeKeyComponentRequired) {
          debugLogger.error(
            'TWRouterRendererSwitch called with a route that has no component to render',
            `Looking for componentMap.${renderKey} in route for '${path}' with componentMap:`,
            componentMap,
          );
        }
        return null;
      }

      return (
        <React.Fragment key={path}>
          <Route
            exact={exact}
            path={path}
            render={(routeParams) => (
              <>
                <ComponentToRender
                  // Pass all props of the component to be rendered...
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...allUnrecognizedProps}
                  // Need to pass route params, react-router's render method doesn't forward to the component directly...
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...routeParams}
                />
                {childRouteMap && (
                  // Enable child routes to be used if so desired...
                  <TWRouteMap routeMap={childRouteMap} />
                )}
              </>
            )}
          />
        </React.Fragment>
      );
    },
  );

  const isRouteEnabled = (route: TWRouteProps) =>
    route && !_.get(flags, `${DISABLED_ROUTE_PREFIX}${route.routeKey}`);

  const defaultRoute = _.find(routeMap, 'isDefault');
  const isDefaultRouteEnabled = isRouteEnabled(defaultRoute);

  const defaultPath: string = isDefaultRouteEnabled
    ? defaultRoute.path
    : _.get(_.find(routeMap, isRouteEnabled), 'path');

  if (isDefaultRenderKey) {
    routesToRender.push(
      <Route
        key="redirect"
        exact
        path="/"
        render={() => (
          <Redirect
            to={{
              pathname: defaultPath,
            }}
          />
        )}
      />,
    );
  }

  return routesToRender;
};

export default TWRouteMap;
