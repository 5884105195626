/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupsDetailsQueryVariables = Types.Exact<{
  signupId?: Types.InputMaybe<Types.Scalars['UUID']>;
  mySignupsOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type SignupsDetailsQuery = {
  signups?: {
    edges: Array<{
      node?: {
        signupId: string;
        title: string;
        location?: string | null;
        notes?: string | null;
        isPrivate?: boolean | null;
        adminsReservingAlerts?: string | null;
        appointmentTypeIcon?: string | null;
        mandatory?: boolean | null;
        timezone?: string | null;
        recurrence?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        firstStartDatetime?: any | null;
        lastStartDatetime?: any | null;
        lastEndDatetime?: any | null;
        firstStartTime?: any | null;
        lastStartTime?: any | null;
        lastEndTime?: any | null;
        firstDate?: any | null;
        lastDate?: any | null;
        totalReservations?: number | null;
        totalCapacity?: number | null;
        slotDuration?: string | null;
        slotCapacity?: number | null;
        slotInterval?: string | null;
        individualReservationLimitCount?: number | null;
        individualReservationLimitPer?: Types.Setperiod | null;
        conflictHandler?: Types.Conflicthandler | null;
        canEdit?: boolean | null;
        appointmentType?: {
          appointmentTypeId?: number | null;
          color?: string | null;
          label?: string | null;
        } | null;
        creator?: { displayName?: string | null } | null;
        extraApptDetails?: {
          attachments?: string | null;
          reminders?: Array<{ reminderType: string; offsetMinutes: number } | null> | null;
        } | null;
        slotBreaks?: Array<{ startTime: any; endTime: any } | null> | null;
        slotDates?: Array<{
          date?: any | null;
          isFull?: boolean | null;
          isEmpty?: boolean | null;
        } | null> | null;
        reservingPersonGroups?: Array<{
          manuallyOpen?: boolean | null;
          openAt?: any | null;
          isOpen?: boolean | null;
          visibility?: Array<string | null> | null;
          persons?: Array<{
            initials?: string | null;
            personId?: number | null;
            pictureUrl?: string | null;
            selectionCode?: string | null;
            id?: string | null;
            label?: string | null;
          } | null> | null;
        } | null> | null;
        reservablePersons?: Array<{
          initials?: string | null;
          personId?: number | null;
          pictureUrl?: string | null;
          selectionCode?: string | null;
          id?: string | null;
          label?: string | null;
        } | null> | null;
        unreservedPersons?: Array<{
          initials?: string | null;
          personId?: number | null;
          pictureUrl?: string | null;
          selectionCode?: string | null;
          id?: string | null;
          label?: string | null;
        } | null> | null;
        reservationDeadline?: {
          days?: number | null;
          hours?: number | null;
          minutes?: number | null;
          seconds?: number | null;
        } | null;
        cancellationDeadline?: {
          days?: number | null;
          hours?: number | null;
          minutes?: number | null;
          seconds?: number | null;
        } | null;
        attachments?: Array<{
          id: string;
          downloadUrl?: string | null;
          fileName: string;
          fileNameServer?: string | null;
          fileSize?: number | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const SignupsDetailsDocument = gql`
  query SignupsDetails($signupId: UUID, $mySignupsOnly: Boolean) {
    signups(signupId: $signupId, mySignupsOnly: $mySignupsOnly) {
      edges {
        node {
          signupId
          title
          location
          notes
          isPrivate
          adminsReservingAlerts
          appointmentType {
            appointmentTypeId
            color
            label
          }
          appointmentTypeIcon
          creator {
            displayName
          }
          extraApptDetails {
            reminders {
              reminderType
              offsetMinutes
            }
            attachments
          }
          mandatory
          timezone
          recurrence
          startDate
          endDate
          firstStartDatetime
          lastStartDatetime
          lastEndDatetime
          firstStartTime
          lastStartTime
          lastEndTime
          firstDate
          lastDate
          totalReservations
          totalCapacity
          slotDuration
          slotCapacity
          slotInterval
          slotBreaks {
            startTime
            endTime
          }
          slotDates {
            date
            isFull
            isEmpty
          }
          reservingPersonGroups {
            manuallyOpen
            openAt
            isOpen
            visibility
            persons {
              id: pk
              initials
              label: displayName
              personId
              pictureUrl
              selectionCode
            }
          }
          reservablePersons {
            id: pk
            initials
            label: displayName
            personId
            pictureUrl
            selectionCode
          }
          unreservedPersons {
            id: pk
            initials
            label: displayName
            personId
            pictureUrl
            selectionCode
          }
          reservationDeadline {
            days
            hours
            minutes
            seconds
          }
          cancellationDeadline {
            days
            hours
            minutes
            seconds
          }
          individualReservationLimitCount
          individualReservationLimitPer
          conflictHandler
          canEdit
          attachments {
            id
            downloadUrl
            fileName
            fileNameServer
            fileSize
          }
        }
      }
    }
  }
`;

/**
 * __useSignupsDetailsQuery__
 *
 * To run a query within a React component, call `useSignupsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupsDetailsQuery({
 *   variables: {
 *      signupId: // value for 'signupId'
 *      mySignupsOnly: // value for 'mySignupsOnly'
 *   },
 * });
 */
export function useSignupsDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<SignupsDetailsQuery, SignupsDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignupsDetailsQuery, SignupsDetailsQueryVariables>(
    SignupsDetailsDocument,
    options,
  );
}
export function useSignupsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SignupsDetailsQuery, SignupsDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignupsDetailsQuery, SignupsDetailsQueryVariables>(
    SignupsDetailsDocument,
    options,
  );
}
export type SignupsDetailsQueryHookResult = ReturnType<typeof useSignupsDetailsQuery>;
export type SignupsDetailsLazyQueryHookResult = ReturnType<typeof useSignupsDetailsLazyQuery>;
export type SignupsDetailsQueryResult = Apollo.QueryResult<
  SignupsDetailsQuery,
  SignupsDetailsQueryVariables
>;
