import { useEffect } from 'react';
import { connect } from 'react-redux';

import { configContainerSelector } from '@tw/config';
import { ConfigContainerType } from '@tw/types';
import { configContainerUtils } from '@tw/util';

// @FIXME: This should be reunited with its equivalent in mobile: today the code is duplicated.
// This is covered by TW-15345

interface TWBindToConfigContainerProps {
  configContainer: ConfigContainerType;
}

const TWBindToConfigContainer = ({ configContainer }: TWBindToConfigContainerProps) => {
  useEffect(() => {
    configContainerUtils.setConfigContainerInstance(configContainer);
  }, [configContainer]);

  return null;
};

const mapStateToProps = (state: unknown) => ({
  configContainer: configContainerSelector(state),
});

export default connect(mapStateToProps)(TWBindToConfigContainer);
