/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilesFolderDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type FilesFolderDeleteMutation = {
  folderDelete: {
    folder?: { folderId?: number | null } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export const FilesFolderDeleteDocument = gql`
  mutation FilesFolderDelete($id: ID!) {
    folderDelete(id: $id) {
      folder {
        folderId: pk
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type FilesFolderDeleteMutationFn = Apollo.MutationFunction<
  FilesFolderDeleteMutation,
  FilesFolderDeleteMutationVariables
>;

/**
 * __useFilesFolderDeleteMutation__
 *
 * To run a mutation, you first call `useFilesFolderDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFilesFolderDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [filesFolderDeleteMutation, { data, loading, error }] = useFilesFolderDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFilesFolderDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FilesFolderDeleteMutation,
    FilesFolderDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FilesFolderDeleteMutation, FilesFolderDeleteMutationVariables>(
    FilesFolderDeleteDocument,
    options,
  );
}
export type FilesFolderDeleteMutationHookResult = ReturnType<typeof useFilesFolderDeleteMutation>;
export type FilesFolderDeleteMutationResult = Apollo.MutationResult<FilesFolderDeleteMutation>;
export type FilesFolderDeleteMutationOptions = Apollo.BaseMutationOptions<
  FilesFolderDeleteMutation,
  FilesFolderDeleteMutationVariables
>;
