import axios from 'axios';
import { useRef, useState } from 'react';
import { getTranslation } from '@tw/i18n';
// @ts-ignore
const endpoint = process.env.LINK_PREVIEW_ENDPOINT;
if (!endpoint && process.env.NODE_ENV !== 'test')
  throw new Error('LINK_PREVIEW_ENDPOINT is not defined');

// type copied from link_unfurler repo
export type UnfurledResponse = {
  title: string;
  description: string;
  image: {
    original?: string;
    hash?: string;
  };
};

export const useLinkPreview = (): [
  (url: string) => Promise<UnfurledResponse>,
  { loading: boolean; error: string | null },
] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const cache = useRef(new Map<string, UnfurledResponse>());

  const fetchLinkPreview = async (url: string): Promise<UnfurledResponse> => {
    if (!endpoint) return getDefaultResponse(url);
    if (cache.current && cache.current.has(url)) {
      return cache.current.get(url) as UnfurledResponse;
    }

    setLoading(true);

    try {
      const response = await axios.get<UnfurledResponse>(endpoint, {
        params: { url },
      });
      cache.current.set(url, response.data);
      return response.data;
    } catch (err) {
      setError(getTranslation('bulletins.errors.unableToLoadLinkPreview'));
      return getDefaultResponse(url);
    } finally {
      setLoading(false);
    }
  };

  return [
    fetchLinkPreview,
    {
      loading,
      error,
    },
  ];
};

function getDefaultResponse(url: string): UnfurledResponse {
  return {
    title: url,
    description: '',
    image: {},
  };
}
