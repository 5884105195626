import * as React from 'react';

import { useViewer } from '@tw/hooks';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect';

const TWInputSelectAthleteStatus: React.FC<TWInputSelectProps> = (props) => {
  const {
    currentOrg: { athleteStatuses },
  } = useViewer();

  return (
    <TWInputSelect
      filterOption
      optionFilterProp="title"
      itemList={athleteStatuses}
      minWidth="200px"
      {...props}
    />
  );
};

export default TWInputSelectAthleteStatus;
