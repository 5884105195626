/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketingReservationReportQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TicketingReservationReportQuery = {
  ticketingReservationReport?: {
    edges: Array<{
      node?: {
        createdDate?: any | null;
        downloadableFileId: string;
        errorMessage?: string | null;
        kind: string;
        status: string;
        title?: string | null;
        url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export const TicketingReservationReportDocument = gql`
  query TicketingReservationReport {
    ticketingReservationReport {
      edges {
        node {
          createdDate
          downloadableFileId
          errorMessage
          kind
          status
          title
          url
        }
      }
    }
  }
`;

/**
 * __useTicketingReservationReportQuery__
 *
 * To run a query within a React component, call `useTicketingReservationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketingReservationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketingReservationReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketingReservationReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TicketingReservationReportQuery,
    TicketingReservationReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketingReservationReportQuery, TicketingReservationReportQueryVariables>(
    TicketingReservationReportDocument,
    options,
  );
}
export function useTicketingReservationReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TicketingReservationReportQuery,
    TicketingReservationReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TicketingReservationReportQuery,
    TicketingReservationReportQueryVariables
  >(TicketingReservationReportDocument, options);
}
export type TicketingReservationReportQueryHookResult = ReturnType<
  typeof useTicketingReservationReportQuery
>;
export type TicketingReservationReportLazyQueryHookResult = ReturnType<
  typeof useTicketingReservationReportLazyQuery
>;
export type TicketingReservationReportQueryResult = Apollo.QueryResult<
  TicketingReservationReportQuery,
  TicketingReservationReportQueryVariables
>;
