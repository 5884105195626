/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTicketingGuestApprovalRequestsCountQueryVariables = Types.Exact<{
  status?: Types.InputMaybe<Types.Scalars['TWFilter_TicketingGuestStatus']>;
}>;

export type FetchTicketingGuestApprovalRequestsCountQuery = {
  ticketingGuests?: { totalCount?: number | null } | null;
};

export const FetchTicketingGuestApprovalRequestsCountDocument = gql`
  query FetchTicketingGuestApprovalRequestsCount($status: TWFilter_TicketingGuestStatus) {
    ticketingGuests(status: $status) {
      totalCount
    }
  }
`;

/**
 * __useFetchTicketingGuestApprovalRequestsCountQuery__
 *
 * To run a query within a React component, call `useFetchTicketingGuestApprovalRequestsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTicketingGuestApprovalRequestsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTicketingGuestApprovalRequestsCountQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useFetchTicketingGuestApprovalRequestsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTicketingGuestApprovalRequestsCountQuery,
    FetchTicketingGuestApprovalRequestsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchTicketingGuestApprovalRequestsCountQuery,
    FetchTicketingGuestApprovalRequestsCountQueryVariables
  >(FetchTicketingGuestApprovalRequestsCountDocument, options);
}
export function useFetchTicketingGuestApprovalRequestsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTicketingGuestApprovalRequestsCountQuery,
    FetchTicketingGuestApprovalRequestsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchTicketingGuestApprovalRequestsCountQuery,
    FetchTicketingGuestApprovalRequestsCountQueryVariables
  >(FetchTicketingGuestApprovalRequestsCountDocument, options);
}
export type FetchTicketingGuestApprovalRequestsCountQueryHookResult = ReturnType<
  typeof useFetchTicketingGuestApprovalRequestsCountQuery
>;
export type FetchTicketingGuestApprovalRequestsCountLazyQueryHookResult = ReturnType<
  typeof useFetchTicketingGuestApprovalRequestsCountLazyQuery
>;
export type FetchTicketingGuestApprovalRequestsCountQueryResult = Apollo.QueryResult<
  FetchTicketingGuestApprovalRequestsCountQuery,
  FetchTicketingGuestApprovalRequestsCountQueryVariables
>;
