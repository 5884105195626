import { useMemo } from 'react';
import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import { graphqlUtils } from '@tw/util';
import {
  Course,
  CourseQueryFilters,
  CoursesRawPaginatedData,
  CourseRawQueryFilters,
} from '@tw/types';
import { normalizeCourseVariables } from '@tw/services/academicService';

import { CourseQuery } from './CourseQuery.query';

export const useCourses = (
  variables: CourseQueryFilters,
  additionalArgs: Omit<
    QueryHookOptions<CoursesRawPaginatedData, CourseRawQueryFilters>,
    'variables'
  >,
) => {
  const { data, ...rest } = useQuery<CoursesRawPaginatedData, CourseRawQueryFilters>(CourseQuery, {
    variables: normalizeCourseVariables(variables),
    ...additionalArgs,
  });

  const normalizedData = useMemo(
    () => graphqlUtils.convertEdgesToArray<Course>(data?.courses.edges),
    [data],
  );

  return {
    data: normalizedData,
    ...rest,
  };
};

export const useCoursesLazy = (
  variables?: CourseQueryFilters,
  additionalArgs?: Omit<
    LazyQueryHookOptions<CoursesRawPaginatedData, CourseRawQueryFilters>,
    'variables'
  >,
) => {
  const [fetchCourses, { data, ...rest }] = useLazyQuery<
    CoursesRawPaginatedData,
    CourseRawQueryFilters
  >(CourseQuery, { variables: normalizeCourseVariables(variables), ...additionalArgs });

  const normalizedData = useMemo(
    () => graphqlUtils.convertEdgesToArray<Course>(data?.courses.edges),
    [data],
  );

  return {
    fetchCourses,
    data: normalizedData,
    ...rest,
  };
};
