/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuestInformationExportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.TicketingGuestInformationExportInput>;
}>;

export type GuestInformationExportQuery = {
  ticketingGuestInformationExport?: {
    exportColumns?: Array<string | null> | null;
    metadataColumns?: Array<string | null> | null;
    sortOrder?: string | null;
    guestInformation?: Array<{
      guestName?: string | null;
      requesterName?: string | null;
      team?: string | null;
      requestedDate?: string | null;
      blockedDate?: string | null;
      status?: string | null;
      guestAttributes?: Array<{
        attributeId?: string | null;
        attributeValue?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export const GuestInformationExportDocument = gql`
  query GuestInformationExport($filters: TicketingGuestInformationExportInput) {
    ticketingGuestInformationExport(filters: $filters) {
      exportColumns
      metadataColumns
      guestInformation {
        guestName
        requesterName
        team
        requestedDate
        blockedDate
        status
        guestAttributes {
          attributeId
          attributeValue
        }
      }
      sortOrder
    }
  }
`;

/**
 * __useGuestInformationExportQuery__
 *
 * To run a query within a React component, call `useGuestInformationExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestInformationExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestInformationExportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGuestInformationExportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GuestInformationExportQuery,
    GuestInformationExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GuestInformationExportQuery, GuestInformationExportQueryVariables>(
    GuestInformationExportDocument,
    options,
  );
}
export function useGuestInformationExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GuestInformationExportQuery,
    GuestInformationExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GuestInformationExportQuery, GuestInformationExportQueryVariables>(
    GuestInformationExportDocument,
    options,
  );
}
export type GuestInformationExportQueryHookResult = ReturnType<
  typeof useGuestInformationExportQuery
>;
export type GuestInformationExportLazyQueryHookResult = ReturnType<
  typeof useGuestInformationExportLazyQuery
>;
export type GuestInformationExportQueryResult = Apollo.QueryResult<
  GuestInformationExportQuery,
  GuestInformationExportQueryVariables
>;
