import { QueryHookOptions, useQuery } from '@apollo/client';

import { GraphQLPaginatedResponse, ProfessorSurveyAssignmentStatus } from '@tw/types';

import { PROFESSOR_SURVEY_ASSIGNMENTS_BY_COURSE_SECTION_QUERY } from '../../@graphql';

export interface CourseSectionWithProfessorSurveyAssignments {
  id: string;
  shortCode: string;
  course: {
    id: string;
    label: string;
    description: string;
  };
  courseSectionSchedules: GraphQLPaginatedResponse<{
    id: string;
    startTime: string;
    endTime: string;
    day: string;
    location: string;
  }>;
  professor: {
    id: string;
    fullName: string;
  };
  enrollments: GraphQLPaginatedResponse<{
    id: string;
    studentTerm: {
      id: string;
      student: {
        id: string;
        eligibilityYears: string[];
        fullName: string;
        teamIds: number[];
        advisors: GraphQLPaginatedResponse<{ id: string }>;
        person: {
          id: string;
          teamLabels: string;
        };
      };
    };
    professorSurveyReports: GraphQLPaginatedResponse<{
      id: string;
      absenceCount: number;
      comment: string;
      isAtRisk: boolean;
      grade: {
        id: string;
        label: string;
      };
      professorSurveyAssignment: {
        id: string;
        professorSurveyId: number;
        status: ProfessorSurveyAssignmentStatus;
      };
    }>;
  }>;
}

interface ProfessorSurveyAssignmentsByCourseSectionData {
  courseSections: GraphQLPaginatedResponse<CourseSectionWithProfessorSurveyAssignments>;
}

interface ProfessorSurveyAssignmentsByCourseSectionVariables {
  professorSurveyId: string;
  advisorIds?: string[];
  courseIds?: string;
  eligibilityYearIds?: number[];
  isAtRiskReport?: boolean;
  searchCourseSections?: string;
  professorSurveyAssignmentStatus?: string[];
  teamIds?: string[];
  after: string;
  first: number;
}

export const useProfessorSurveyAssignmentsByCourseSection = (
  options: QueryHookOptions<
    ProfessorSurveyAssignmentsByCourseSectionData,
    ProfessorSurveyAssignmentsByCourseSectionVariables
  >,
) => useQuery(PROFESSOR_SURVEY_ASSIGNMENTS_BY_COURSE_SECTION_QUERY, options);
