import styled from '@emotion/styled';
import { sharedSpacingStyles, SpacingComponentProps } from '../../utils';

interface ChipContainerProps {
  fontSize: number;
  iconSize: number;
}

const ChipContainer = styled.div(({ fontSize, iconSize }: ChipContainerProps) => ({
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  // This is odd, Ant-d is applying position relative to the span that contains the Avatar text
  // but that is breaking it's positioning so only have of the letters are visible.
  // we are targeting the second span and unsetting the position to fix it.  Watch out for Ant-d
  // updates regarding this
  'span span.ant-avatar-string': {
    position: 'absolute',
    fontSize,
  },
  '.ant-avatar': {
    minWidth: iconSize,
    margin: 0,
  },
}));

const TextAndSubTextColumn = styled.div<SpacingComponentProps>(() => [
  sharedSpacingStyles,
  {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },
]);

TextAndSubTextColumn.defaultProps = {
  twPaddingLeft: 1,
};

export { ChipContainer, TextAndSubTextColumn };
