/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketingEventAllocationBulkCopyMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.TicketingEventAllocationCopyInput>;
}>;

export type TicketingEventAllocationBulkCopyMutation = {
  ticketingEventAllocationBulkCopy: {
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const TicketingEventAllocationBulkCopyDocument = gql`
  mutation TicketingEventAllocationBulkCopy($input: TicketingEventAllocationCopyInput) {
    ticketingEventAllocationBulkCopy(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type TicketingEventAllocationBulkCopyMutationFn = Apollo.MutationFunction<
  TicketingEventAllocationBulkCopyMutation,
  TicketingEventAllocationBulkCopyMutationVariables
>;

/**
 * __useTicketingEventAllocationBulkCopyMutation__
 *
 * To run a mutation, you first call `useTicketingEventAllocationBulkCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingEventAllocationBulkCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingEventAllocationBulkCopyMutation, { data, loading, error }] = useTicketingEventAllocationBulkCopyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketingEventAllocationBulkCopyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TicketingEventAllocationBulkCopyMutation,
    TicketingEventAllocationBulkCopyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TicketingEventAllocationBulkCopyMutation,
    TicketingEventAllocationBulkCopyMutationVariables
  >(TicketingEventAllocationBulkCopyDocument, options);
}
export type TicketingEventAllocationBulkCopyMutationHookResult = ReturnType<
  typeof useTicketingEventAllocationBulkCopyMutation
>;
export type TicketingEventAllocationBulkCopyMutationResult =
  Apollo.MutationResult<TicketingEventAllocationBulkCopyMutation>;
export type TicketingEventAllocationBulkCopyMutationOptions = Apollo.BaseMutationOptions<
  TicketingEventAllocationBulkCopyMutation,
  TicketingEventAllocationBulkCopyMutationVariables
>;
