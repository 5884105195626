import { Drawer, DrawerProps, Row } from 'antd';
import { CSSProperties, ReactNode } from 'react';

import { noopFn } from '@tw/constants';
import { DOMUtils } from '@tw/util';

import { TWFlexContainer } from '../../../presentational';
import {
  CloseIcon,
  CloseText,
  FullscreenDrawerBody,
  FullscreenDrawerFooter,
  FullscreenDrawerHeader,
} from './TWDrawerFullscreen.styles';

interface TWDrawerFullscreenProps extends DrawerProps {
  children?: ReactNode;
  containerId: string;
  footerLeft?: ReactNode;
  footerRight?: ReactNode;
  footerPosition?: CSSProperties['position'];
  headerLeft?: ReactNode;
  headerRight?: ReactNode;
  id?: string;
  testID?: string;
  title?: string;
  onClose?: () => void;
  open?: boolean;
}
const TWDrawerFullscreen = ({
  bodyStyle,
  children,
  closable = false,
  containerId,
  footerRight,
  footerLeft,
  footerPosition,
  headerRight,
  headerLeft,
  height,
  id,
  onClose = noopFn,
  placement = 'right',
  testID,
  title = '',
  open = false,
}: TWDrawerFullscreenProps) => {
  const titleRemovedSpaces = title.split(' ').join('');

  return (
    <Drawer
      closable={closable}
      data-testid={testID}
      destroyOnClose
      height={height}
      placement={placement}
      onClose={onClose}
      open={open}
      getContainer={DOMUtils.getContainer(containerId)}
      width="100%"
      bodyStyle={bodyStyle}
    >
      <FullscreenDrawerHeader justify="space-between" align="middle">
        <Row align="middle">
          <TWFlexContainer
            row
            align="center"
            data-testid={`FullscreenDrawer:${titleRemovedSpaces}:Header`}
          >
            <CloseIcon
              type="material-arrow_back"
              onClick={onClose}
              data-testid={`FullscreenDrawer:${titleRemovedSpaces}:CloseDrawer`}
            />
            <CloseText>{title}</CloseText>
          </TWFlexContainer>
          {headerLeft}
        </Row>
        <div>{headerRight}</div>
      </FullscreenDrawerHeader>
      <FullscreenDrawerBody id={id}>{children}</FullscreenDrawerBody>
      {(footerRight || footerLeft) && (
        <FullscreenDrawerFooter justify="space-between" position={footerPosition}>
          <TWFlexContainer row justify="flex-start">
            {footerLeft}
          </TWFlexContainer>
          <TWFlexContainer row justify="flex-end">
            {footerRight}
          </TWFlexContainer>
        </FullscreenDrawerFooter>
      )}
    </Drawer>
  );
};

export default TWDrawerFullscreen;
