import { useTheme } from '@emotion/react';
import { TWDropdown } from '@tw/components/components.web';
import { useTranslator } from '@tw/i18n';
import { DropdownProps, RichTextDropdownOption } from '../RichTextEditor.definitions';
import { activeMark, addMarkData } from '../utils/slate';
import { DropdownButton } from '../RichTextEditor.styles';
import { Icon } from '../components';

function getLabelByValue(options: RichTextDropdownOption[], value: string) {
  const option = options.find((o) => o.value === value);
  return option ? option.text : '';
}

export const Dropdown = ({ editor, format, options }: DropdownProps) => {
  const { colors } = useTheme();
  const translator = useTranslator();
  const activeLabel = getLabelByValue(options, activeMark(editor, format));
  return (
    <TWDropdown
      menu={{
        items: options.map((item) => ({
          key: item.value,
          icon: item.icon,
          onClick: () => {
            addMarkData(editor, { format, value: item.value });
          },
          style: {
            backgroundColor: item.text === activeLabel ? colors.gray : undefined,
            fontSize: item.fontSize,
            fontFamily: item.fontFamily,
            lineHeight: item.fontSize ? 'normal' : undefined,
          },
          label: item.text,
        })),
      }}
    >
      <DropdownButton aria-label={activeLabel}>
        {activeLabel}
        <Icon icon="arrowDropDown" title={translator.t('expand')} />
      </DropdownButton>
    </TWDropdown>
  );
};
