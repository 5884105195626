import styled from '@emotion/styled';
import { Modal } from 'antd';

const TWModalContainer = styled(Modal)(({ theme }) => ({
  '.ant-modal-content button:active,.ant-modal-content button:active:focus,.ant-modal-content button:active:hover':
    {
      backgroundColor: 'unset',
      border: 'unset',
    },

  '.ant-modal-footer button + button': {
    marginLeft: theme.baseUnit * 2,
  },
}));

export { TWModalContainer };
