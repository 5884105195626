import _ from 'lodash';
import { graphqlUtils, modelConverterUtils } from '@tw/util';

const groupCourses = (courseList) => {
  const courseSectionGroups = _.groupBy(courseList, 'courseSectionId');

  return _.map(courseSectionGroups, (group) => ({
    key: `${group[0].courseLabel}_${group[0].courseShortCode}`,
    courseLabel: group[0].courseLabel,
    courseShortCode: group[0].courseShortCode,
    professorEmail: group[0].professorEmail,
    professorFullName: group[0].professorFullName,
    professorLastName: group[0].professorLastName,
    courseSectionId: group[0].courseSectionId,
    studentNames: _.map(group, 'studentFullName'),
  }));
};

const normalizeRawItem = modelConverterUtils.memoizeById(
  'enrollmentAttendanceId',
  (rawCourseItem) => {
    const enrollment = _.get(rawCourseItem, ['enrollment']);
    const courseSection = _.get(enrollment, ['courseSection']);

    return {
      enrollmentAttendanceId: _.get(rawCourseItem, ['enrollmentAttendanceId']),
      occurrenceStartDateTime: _.get(rawCourseItem, ['occurrenceStartDateTime']),
      occurrenceEndDateTime: _.get(rawCourseItem, ['occurrenceEndDateTime']),
      studentTermId: _.get(enrollment, ['studentTermId']),
      studentId: _.get(enrollment, ['studentTerm', 'student', 'studentId']),
      studentFullName: _.get(enrollment, ['studentTerm', 'student', 'fullName']),
      courseLabel: _.get(courseSection, ['course', 'label']),
      courseShortCode: _.get(courseSection, ['shortCode']),
      courseSectionId: _.get(courseSection, ['courseSectionId']),
      professorFullName: _.get(courseSection, ['professor', 'fullName']),
      professorLastName: _.get(courseSection, ['professor', 'lastName']),
      professorEmail: _.get(courseSection, ['professor', 'emailAddress']),
    };
  },
);

const normalizeMultipleFetchCourses = (responses) => {
  const courseEdgeList = _.flatten(
    _.map(responses, (rawCourseList) =>
      graphqlUtils.convertEdgesToArray(
        _.get(rawCourseList, ['responseJson', 'data', 'enrollmentAttendances', 'edges']),
      ),
    ),
  );

  const normalizedCourseList = _.map(courseEdgeList, normalizeRawItem);

  // Dedupe the results, should only have one record per course section per
  // student. Duplicates would exist if the student missing the same class
  // multiple times for one travel letter.
  const dedupedCourseList = _.uniqBy(
    normalizedCourseList,
    ({ courseSectionId, studentId }) => `${courseSectionId}_${studentId}`,
  );

  // Group the list by courseSectionId, combining the students
  const groupedCourseList = groupCourses(dedupedCourseList);

  return _.orderBy(groupedCourseList, 'professorFullName');
};

export default {
  normalizeMultipleFetchCourses,
};
