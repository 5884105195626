import * as React from 'react';
import _ from 'lodash';

import { theme } from '@tw/components/utils';
import { useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { CourseSection } from '@tw/types';
import { formatUtils, numberUtils } from '@tw/util';
import { noopFn } from '@tw/constants';

import { TWDataTable, TWDataTableColumn, columnRenderFunctions } from '../..';

const styles = {
  border: `solid 1px ${theme.colors.border}`,
  borderRadius: 4,
};

interface TWStudentCourseGridProps {
  courseSectionList: CourseSection[];
  onCourseSectionClick?: (courseData: CourseSection) => void;
  studentTermId: number;
  testID: string;
}

const TWStudentCourseGrid: React.FC<TWStudentCourseGridProps> = ({
  courseSectionList = [],
  onCourseSectionClick = noopFn,
  studentTermId,
  testID,
}) => {
  const {
    currentOrg: { timeZoneCode: orgTimeZone },
  } = useViewer();

  const renderLabel = (courseSection: CourseSection) =>
    courseSection?.course ? `${courseSection.course.label}-${courseSection.shortCode}` : '-';

  const renderDays = ({ courseSectionSchedules }: CourseSection) =>
    columnRenderFunctions.renderDayAbbreviations(null, {
      courseSectionSchedules: _.map(
        formatUtils.sortByDayOfWeek(courseSectionSchedules, 'day'),
        (schedule) => ({
          dayAbbreviation: formatUtils.getDayOfWeekAbbreviation(schedule.day),
        }),
      ),
    });

  const renderCourseSectionTimes = ({ courseSectionSchedules }: CourseSection) =>
    columnRenderFunctions.renderCourseSectionTimes(
      {
        courseSectionSchedules,
        hasMultipleTimes:
          _.uniqBy(courseSectionSchedules, 'startTime').length > 1 ||
          _.uniqBy(courseSectionSchedules, 'endTime').length > 1,
        startTime: courseSectionSchedules[0]?.startTime || '',
        endTime: courseSectionSchedules[0]?.endTime || '',
      },
      orgTimeZone,
    );

  const renderCreditHours = (courseSection: CourseSection) => {
    if (!courseSection?.enrollments) return '-';

    const selectedEnrollment = _.find(
      courseSection.enrollments.edges,
      (edge) => edge.studentTermId === studentTermId,
    );

    const creditsAttempted = Number(
      selectedEnrollment?.creditsAttempted || courseSection?.course.credits || 0,
    );
    return numberUtils.parseNumberToSingleDecimalFloat(creditsAttempted);
  };

  return (
    <TWDataTable
      dataSource={courseSectionList}
      onRowClick={onCourseSectionClick}
      style={styles}
      testID={testID}
      rowKey="id"
    >
      <TWDataTableColumn
        title={
          <b>
            {getTranslation('courses', 1)}/{getTranslation('sections', 1)}
          </b>
        }
        key="courseSectionLabel"
        render={renderLabel}
      />
      <TWDataTableColumn title={<b>{getTranslation('day', 2)}</b>} key="days" render={renderDays} />
      <TWDataTableColumn
        title={<b>{getTranslation('time')}</b>}
        key="time"
        render={renderCourseSectionTimes}
        className="no-wrap"
      />
      <TWDataTableColumn
        title={<b>{getTranslation('hour', 2)}</b>}
        key="credits"
        render={renderCreditHours}
      />
    </TWDataTable>
  );
};

export default TWStudentCourseGrid;
