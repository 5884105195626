import { theme } from '@tw/components/utils';

export const toastContainerStyle = {
  lineHeight: 1.571, // to override .antd line height changes
  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
  left: 0,
  width: '100%',
};

export const toastStyle = {
  padding: '10px',
  success: {
    backgroundColor: theme.colors.positiveBackground,
    border: `1px solid ${theme.colors.positiveBorder}`,
  },
  warning: {
    backgroundColor: theme.colors.warningBackground,
    border: `1px solid ${theme.colors.warningBorder}`,
  },
};
