import { useMutation } from '@apollo/client';

import { default as DELETE_STUDY_HALL } from './DeleteStudyHall.graphql'; // eslint-disable-line import/no-named-default

export interface DeleteStudyHallInput {
  id: string;
}

export interface DeleteStudyHallData {
  studyHallDelete: {
    studyHall: {
      id: string;
      isExpired: boolean;
    };
    userErrors: { field: string[]; message: string }[];
  };
}

export const useDeleteStudyHallMutation = () =>
  useMutation<DeleteStudyHallData, DeleteStudyHallInput>(DELETE_STUDY_HALL);
