import { Dayjs } from 'dayjs';
import fastMemoize from 'fast-memoize';
import { EnumType, jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import _ from 'lodash';
import moment, { Moment } from 'moment';

import { emptyObject } from '@tw/constants';
import { Maybe } from '@tw/generated';
import { GraphQLEdge } from '@tw/types';
import { dayjs } from '@tw/util';

// Leverage helpers from jsonToGraphQLQuery
const createVariableAttribute = fastMemoize((variableName) => new VariableType(variableName));
const createEnumAttribute = fastMemoize((enumName) => new EnumType(enumName));

// Declare Constants
const IS_NULL = 'eq:null';
const IS_NOT_NULL = `~${IS_NULL}`;

const DEFAULT_PAGINATION_INFO_OBJECT = {
  hasNextPage: true,
  hasPreviousPage: true,
  startCursor: true,
  endCursor: true,
};

const DATE_TIME_OBJECT = {
  dateTime: true,
  timeZone: true,
};

const buildVariablesAndArgs = (fields) => {
  const args = {};
  const variables = {};

  _.forOwn(fields, (variableType, variableName) => {
    args[variableName] = createVariableAttribute(variableName);
    variables[variableName] = variableType;
  });

  return {
    args,
    variables,
  };
};

const paginationArgsAndVariables = buildVariablesAndArgs({
  after: 'String',
  before: 'String',
  first: 'Int',
  last: 'Int',
});

const PAGINATION_VARIABLES = paginationArgsAndVariables.variables;
const PAGINATION_ARGUMENTS = paginationArgsAndVariables.args;

// Helpers
function createFilteredDateString(dateTimeObject: Moment | Dayjs): string {
  return dateTimeObject.utc().format('YYYY-MM-DD HH:mm:ss');
}

// datetime queries for date format TWFilter_DateTime, datetime in brackets e.g. "lt:(2019-11-04 10:00:00+00:00)"
const getBeforeNowQuery = (now: string | Dayjs) =>
  `lt:(${createFilteredDateString(dayjs(now).utc())})`;

const getAfterNowQuery = (now: Moment | string) =>
  `gt:(${createFilteredDateString(moment(now).utc())})`;

const getBeforeDateQuery = (now: Dayjs | string) => `lt:(${dayjs(now).utc().format('YYYY-MM-DD')})`;

const getAfterDateQuery = (now: Dayjs | string) => `gt:(${dayjs(now).utc().format('YYYY-MM-DD')})`;

// datetime queries for date format TWFilter_String, format without brackets e.g. "lt:2019-11-04 10:00:00+00:00"
const getBeforeNowStringQuery = (now: Moment | string) =>
  `lt:${createFilteredDateString(moment(now).utc())}`;

const getAfterNowStringQuery = (now: Moment | string): string =>
  `gt:${createFilteredDateString(moment(now).utc())}`;

const createNotEqualQuery = (notEqual) => {
  if (_.isEmpty(notEqual)) {
    return null;
  }

  return `~eq:'${notEqual}'`;
};

function createArrayIncludesQuery(toInclude: string[]): string;
function createArrayIncludesQuery(toInclude: number | number[]): number | string;
function createArrayIncludesQuery(toInclude: any): any {
  if (_.isNumber(toInclude)) {
    return toInclude;
  }
  if (_.isEmpty(toInclude)) {
    return null;
  }
  // TODO: There is a bug in the backend where array data containing a single value doesn't work to use as filters.
  // So, we need to, in that case, send a single value and not an 'in:<array>'. Remove ternary once that works.
  return _.size(toInclude) === 1
    ? _.head<number | string>(toInclude)
    : `in:${JSON.stringify(_.flatten<number | string>(toInclude))}`;
}

const createArrayExcludesQuery = (toExclude) => {
  if (_.isNumber(toExclude)) {
    return toExclude;
  }
  if (_.isEmpty(toExclude)) {
    return null;
  }

  return `~in:${JSON.stringify(_.flatten(toExclude))}`;
};

const getQueryFilterFromWhiteListSelection = ({ isWhiteListed, selectionKeys }) =>
  isWhiteListed ? createArrayIncludesQuery(selectionKeys) : createArrayExcludesQuery(selectionKeys);

const createRangeQuery = (
  start: string | Date | Moment | Dayjs,
  end: string | Date | Moment | Dayjs,
) => {
  if (!start || !end) {
    return null;
  }

  const startMoment = moment.isMoment(start) ? moment(start) : dayjs(start);
  const endMoment = moment.isMoment(end) ? moment(end) : dayjs(end);

  const startFormat = createFilteredDateString(startMoment);
  const endFormat = createFilteredDateString(endMoment);

  return `btw:[(${startFormat}),(${endFormat})]`;
};

// Takes a boolean (or null) and returns a filterspec string to use in a query.
const createBooleanQuery = (bool: boolean | null, allowNull?: boolean): string => {
  if (_.isNil(bool)) {
    return allowNull ? IS_NULL : bool;
  }
  return `eq${allowNull ? '0' : ''}:${bool ? 'true' : 'false'}`;
};

const createRangeColumnBetweenQuery = (dateRange: [string | Moment, string | Moment]) => {
  if (_.size(dateRange) !== 2) {
    return null;
  }

  const start = createFilteredDateString(moment(dateRange[0]));
  const end = createFilteredDateString(moment(dateRange[1]));

  return `rngbtw:[(${start}),(${end})]`;
};

const createStringContainsQuery = (toContain: string): string => {
  const escaped = _.replace(toContain, "'", "\\'");
  return `icontains:'${escaped}'`;
};

const createAliasFor = fastMemoize((realName) => ({
  __aliasFor: realName,
}));

const createArgsObject = ({ args, usePagination }) => ({
  __args: _.pickBy(
    {
      ...(usePagination ? PAGINATION_ARGUMENTS : emptyObject),
      ...args,
    },
    _.identity,
  ),
});

const createVariablesObject = ({ variables, usePagination }) => ({
  __variables: {
    ...(usePagination ? PAGINATION_VARIABLES : emptyObject),
    ...variables,
  },
});

const createPaginationInfoObject = (pageInfoObject) => ({
  pageInfo: pageInfoObject || DEFAULT_PAGINATION_INFO_OBJECT,
});

const createEdgesObject = ({
  nodeSchema,
  includeCursor,
  includeCount,
}: {
  nodeSchema;
  includeCursor?: boolean;
  includeCount?: boolean;
}) => ({
  totalCount: includeCount,
  edges: {
    cursor: includeCursor,
    node: nodeSchema,
  },
});

const createQueryObject = (queryObject) => ({
  query: queryObject,
});

const createExecutableQueryObject = (query, optionOverrides?) =>
  jsonToGraphQLQuery(query, optionOverrides);

export const convertEdgesToArray = <T>(edgeList?: GraphQLEdge<T>[]): T[] => {
  if (!edgeList || edgeList.length === 0) {
    // This also handles edgeList being null
    return [];
  }

  return edgeList.map((edgeItem) => ({
    cursor: edgeItem.cursor,
    ...edgeItem.node,
  }));
};

type Edge = {
  node?: any;
  [key: string]: any;
};
type EdgeNode<T extends Maybe<Edge>> = Exclude<T, null>['node'];

type ExtractNodesData<T extends Maybe<Edge>> = { edges: T[]; [key: string]: any };

type ExtractNodesOptions<T extends Maybe<Edge>, U extends EdgeNode<T>> = {
  filter?: (node: EdgeNode<T>) => boolean;
  transform?: (node: EdgeNode<T>) => U;
};

export const extractNodes = <T extends Maybe<Edge>, U = Exclude<EdgeNode<T>, null | undefined>>(
  data?: Maybe<ExtractNodesData<T>>,
  options?: ExtractNodesOptions<T, U>,
): U[] => {
  const edges = data?.edges ?? [];
  return edges.reduce<U[]>((acc, edge) => {
    const { filter, transform } = options ?? {};
    if (edge?.node) {
      if (typeof filter === 'function') {
        if (!filter(edge.node)) {
          return acc;
        }
      }

      if (typeof transform === 'function') {
        return [...acc, transform(edge.node)];
      }
      return [...acc, edge.node];
    }

    return acc;
  }, []);
};

interface BuildQueryProps {
  endpoint: string;
  args?;
  variables?;
  supportedFilters?;
  paginationInfo?;
  schema;
  usePagination?: boolean;
  useEdges?: boolean;
}

const buildQuery = ({
  endpoint,
  args: externalArgs,
  variables: externalVariables,
  supportedFilters,
  paginationInfo,
  schema,
  usePagination,
  useEdges,
}: BuildQueryProps) => {
  // If we aren't requesting a single item, assume the backend will need to return us a pagination object.
  // We don't rely on the logic in createPaginationObject, because it will always return *some* pagination object for
  // list types, which we don't really want. But we do want it to return pagination for any lists we request
  const paginationObject = usePagination ? createPaginationInfoObject(paginationInfo) : emptyObject;
  const querySchemaObject = useEdges
    ? createEdgesObject({ nodeSchema: schema, includeCursor: !!usePagination })
    : schema;

  let args = externalArgs;
  let variables = externalVariables;
  if (supportedFilters) {
    const varsAndArgs = buildVariablesAndArgs(supportedFilters);
    ({ args, variables } = varsAndArgs);
  }

  const variablesObject = variables
    ? createVariablesObject({ variables, usePagination })
    : emptyObject;
  const argsObject = args ? createArgsObject({ args, usePagination }) : emptyObject;

  return createQueryObject({
    ...variablesObject,
    [endpoint]: {
      ...argsObject,
      ...paginationObject,
      ...querySchemaObject,
    },
  });
};

export default {
  DATE_TIME_OBJECT,
  DEFAULT_PAGINATION_INFO_OBJECT,
  PAGINATION_ARGUMENTS,
  PAGINATION_VARIABLES,
  IS_NULL,
  IS_NOT_NULL,
  buildQuery,
  buildVariablesAndArgs,
  convertEdgesToArray,
  createAliasFor,
  createArrayIncludesQuery,
  createArrayExcludesQuery,
  createBooleanQuery,
  createNotEqualQuery,
  createRangeQuery,
  createEdgesObject,
  createEnumAttribute,
  createExecutableQueryObject,
  createQueryObject,
  createRangeColumnBetweenQuery,
  createStringContainsQuery,
  createVariableAttribute,
  getAfterNowQuery,
  getBeforeNowQuery,
  getQueryFilterFromWhiteListSelection,
  getBeforeDateQuery,
  getAfterDateQuery,
  getBeforeNowStringQuery,
  getAfterNowStringQuery,
};
