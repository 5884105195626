import throttle from 'lodash/throttle';
import { useCallback, useEffect } from 'react';
import { useBroadcastState, useViewer } from '@tw/hooks';
import localStorage, { LocalStorageKeys } from '@tw/services/localStorage';
import session from '@tw/services/session';
import { useLogoutUserMutation } from '@tw/generated';
import SessionTimeoutLoginModal from './SessionTimeoutLoginModal';
import SessionTimeoutWarningModal from './SessionTimeoutWarningModal';

const ACTIVITY_CHECKER_INTERVAL_MS = 30 * 1000; // 30 seconds
const ACTIVITY_EVENT_DELAY_MS = 15 * 1000; // 15 seconds
const ACTIVITY_EVENTS = ['keydown', 'pointermove', 'pointerdown'];

enum SessionTimeoutState {
  NORMAL,
  WARNING,
  LOGOUT,
}

const SessionTimeoutManager = () => {
  const [state, setState] = useBroadcastState<SessionTimeoutState>(
    LocalStorageKeys.SESSION_INACTIVE_STATE,
    SessionTimeoutState.NORMAL,
  );

  const viewer = useViewer();

  const inactivityTtl = session.getInactivityTimeout(viewer?.sharedNavbar?.inactivityTimeout ?? 0);
  const inactivityWarningThreshold = session.getInactivityWarningThreshold();

  useEffect(() => {
    localStorage.setSessionInactivityTimeout(inactivityTtl);
  }, [inactivityTtl]);

  const [logout] = useLogoutUserMutation();

  const { person } = useViewer();
  const isPersonSSO = person?.usesExternalAuthentication ?? false;

  useEffect(() => {
    switch (state) {
      case SessionTimeoutState.WARNING:
      case SessionTimeoutState.LOGOUT:
        session.stopTokenChecker();
        break;
      case SessionTimeoutState.NORMAL:
      default:
        session.startTokenChecker();
        break;
    }
  }, [state]);

  useEffect(() => {
    session.ensureSession();

    // If inactivityTtl is 0, then we won't check for inactivity.
    if (inactivityTtl === 0) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return startActivityChecker(inactivityTtl, inactivityWarningThreshold);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactivityTtl]);

  const startActivityChecker = useCallback(
    (ttl = 0, warningThreshold = 0) => {
      // adjustedTtl slightly improves accuracy of inactivity time.
      // this will at most cause user to log out ACTIVITY_CHECKER_INTERVAL_MS early.
      // NOTE: because of browser js throttling on inactive tabs, expiry timeout may
      // still be extended up to over a minute.
      const adjustedTtl = ttl - ACTIVITY_CHECKER_INTERVAL_MS;
      const warningThresholdTtl = ttl - warningThreshold;

      // initialize or renew the storage before starting interval.
      localStorage.setLastActive(Date.now());

      const intervalId = setInterval(() => {
        const shouldLogout = session.isValid() && isInactive(adjustedTtl);
        const shouldWarn = isInactive(warningThresholdTtl);

        if (shouldLogout) {
          stop();
          if (isPersonSSO) {
            // revoke the refresh token and clear the cookies
            logout({ variables: { refreshToken: localStorage.getRefreshToken() } }).finally(() => {
              // hard logout with redirect to login.teamworksapp.com
              session.logout(process.env.OKTA_HOST ?? '/');
            });
          } else {
            setState(SessionTimeoutState.LOGOUT);
            // we want to logout if it fails or successed
            session.logoutWithoutRedirect().finally(() => {
              logout({ variables: { refreshToken: localStorage.getRefreshToken() } }).finally(
                () => {
                  session.expireBearerToken();
                },
              );
            });
          }
        } else if (shouldWarn) {
          setState(SessionTimeoutState.WARNING);
        } else {
          setState(SessionTimeoutState.NORMAL);
        }
      }, ACTIVITY_CHECKER_INTERVAL_MS);

      const throttled = throttle(() => {
        if (!isInactive(warningThresholdTtl)) {
          localStorage.setLastActive(Date.now());
        }
      }, ACTIVITY_EVENT_DELAY_MS);

      ACTIVITY_EVENTS.forEach((event) => window.addEventListener(event, throttled));

      function stop() {
        throttled.cancel();
        clearInterval(intervalId);
        ACTIVITY_EVENTS.forEach((event) => window.removeEventListener(event, throttled));
      }

      // eslint-disable-next-line consistent-return
      return stop;
    },
    [isPersonSSO, logout, setState],
  );

  const onAuthenticated = () => {
    setState(SessionTimeoutState.NORMAL);
    localStorage.setLastActive(Date.now());
    startActivityChecker(inactivityTtl, inactivityWarningThreshold);
  };

  const onAcknowledged = () => {
    setState(SessionTimeoutState.NORMAL);
    localStorage.setLastActive(Date.now());
  };

  return (
    <>
      <SessionTimeoutLoginModal
        visible={state === SessionTimeoutState.LOGOUT}
        onAuthenticated={onAuthenticated}
      />
      <SessionTimeoutWarningModal
        visible={state === SessionTimeoutState.WARNING}
        onAcknowledged={onAcknowledged}
      />
    </>
  );
};

function isInactive(ttl = 0) {
  const lastActive = localStorage.getLastActive();
  return lastActive > 0 && Date.now() - lastActive > ttl;
}

export default SessionTimeoutManager;
