/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchVideoVisibilityQueryVariables = Types.Exact<{
  videoId?: Types.InputMaybe<Types.Scalars['TWFilter_ID']>;
}>;

export type FetchVideoVisibilityQuery = {
  myVideos?: {
    edges: Array<{
      video?: { videoId: string; visibility?: Array<string | null> | null } | null;
    } | null>;
  } | null;
};

export const FetchVideoVisibilityDocument = gql`
  query FetchVideoVisibility($videoId: TWFilter_ID) {
    myVideos(videoId: $videoId) {
      edges {
        video: node {
          videoId
          visibility
        }
      }
    }
  }
`;

/**
 * __useFetchVideoVisibilityQuery__
 *
 * To run a query within a React component, call `useFetchVideoVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchVideoVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchVideoVisibilityQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useFetchVideoVisibilityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchVideoVisibilityQuery,
    FetchVideoVisibilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchVideoVisibilityQuery, FetchVideoVisibilityQueryVariables>(
    FetchVideoVisibilityDocument,
    options,
  );
}
export function useFetchVideoVisibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchVideoVisibilityQuery,
    FetchVideoVisibilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchVideoVisibilityQuery, FetchVideoVisibilityQueryVariables>(
    FetchVideoVisibilityDocument,
    options,
  );
}
export type FetchVideoVisibilityQueryHookResult = ReturnType<typeof useFetchVideoVisibilityQuery>;
export type FetchVideoVisibilityLazyQueryHookResult = ReturnType<
  typeof useFetchVideoVisibilityLazyQuery
>;
export type FetchVideoVisibilityQueryResult = Apollo.QueryResult<
  FetchVideoVisibilityQuery,
  FetchVideoVisibilityQueryVariables
>;
