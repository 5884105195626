import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Spin } from 'antd';
import { SizeScalingType } from '../../utils';

interface TWLoadingSpinnerProps {
  className?: string;
  color?: string;
  size?: SizeScalingType | number;
}

const loadingSpinnerSizeScaling = {
  small: 20,
  default: 40,
  large: 60,
};

const TWLoadingSpinner = ({ className, color, size = 'default' }: TWLoadingSpinnerProps) => {
  const theme = useTheme();
  const spinColor = color ?? theme.brandColors.primaryColor;
  const spinSize = loadingSpinnerSizeScaling[size] || size || loadingSpinnerSizeScaling.default;

  return (
    <StyledSvg
      className={className}
      spinColor={spinColor}
      spinSize={spinSize}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="loading-spinner"
      data-testid="LoadingSpinner"
    >
      <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
          <stop stopColor={spinColor} stopOpacity="0" offset="0%" />
          <stop stopColor={spinColor} stopOpacity=".631" offset="63.146%" />
          <stop stopColor={spinColor} offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke={spinColor} strokeWidth="2">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
          <circle fill="#fff" cx="36" cy="18" r={1}>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ spinColor: string; spinSize: number }>(
  ({ spinColor, spinSize }) => ({
    color: spinColor,
    fontSize: spinSize,
    width: '1em',
    height: '1em',
    '.ant-spin &': {
      fontSize: loadingSpinnerSizeScaling.default,
    },
    '.ant-spin.ant-spin-sm &': {
      fontSize: loadingSpinnerSizeScaling.small,
    },
    '.ant-spin.ant-spin-lg &': {
      fontSize: loadingSpinnerSizeScaling.large,
    },
    '.ant-spin-nested-loading > div > .ant-spin &.ant-spin-dot': {
      margin: -loadingSpinnerSizeScaling.default / 2,
    },
    '.ant-spin-nested-loading > div > .ant-spin.ant-spin-sm &.ant-spin-dot': {
      margin: -loadingSpinnerSizeScaling.small / 2,
    },
    '.ant-spin-nested-loading > div > .ant-spin.ant-spin-lg &.ant-spin-dot': {
      margin: -loadingSpinnerSizeScaling.large / 2,
    },
  }),
);

Spin.setDefaultIndicator(<TWLoadingSpinner />);

export default TWLoadingSpinner;
