import { isFinite, toNumber } from 'lodash';

const clip = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

/**
 *  This method supplements lodash's isNumber function which returns false for stringified numbers '123'
 *  This method is explicitly defined to accept any so we can determine whether value is numeric or not
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNumeric = (value: any): boolean => {
  if (
    value === null ||
    typeof value === 'boolean' ||
    (typeof value !== 'number' &&
      (value === '' || Number.isNaN(toNumber(value)) || Array.isArray(value)))
  ) {
    return false;
  }
  return isFinite(toNumber(value));
};

const parseNumberToSingleDecimalFloat = (num: string | number): string =>
  Number.parseFloat(String(num)).toFixed(1);

export default { clip, isNumeric, parseNumberToSingleDecimalFloat };
