/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useCallback, useState } from 'react';
import { Checkbox, Tooltip } from 'antd';

import { TWTextDefault } from '../../typography';

import { TWInputCheckboxTextProps } from './TWInputCheckboxText.definitions';
import { CheckboxContainer, HideLabelCheckbox } from './TWInputCheckboxText.styles';

const TWInputCheckboxText = forwardRef<HTMLInputElement, TWInputCheckboxTextProps>(
  (
    {
      label,
      containerStyleOverrides,
      accessibilityLabel = null,
      testID,
      size = 'default',
      onChange,
      value: checked,
      hasTooltip,
      tooltipProps,
      hideLabel = false,
      ...restOfProps
    },
    ref,
  ) => {
    // We have this block so we can control when a tooltip is shown. Not doing this will make it
    // so we can only see it when hovering the text, but not the checkbox.
    //
    // Why not wrap the entire container in the tooltip? because the tooltip will anchor on the outer
    // bounds of the component, so it will not necessarily line up with the text, which would make the
    // UX unfriendly
    const [showTooltip, setShowTooltip] = useState(false);
    const onCheckboxMouseEnter = useCallback(() => {
      if (hasTooltip) {
        setShowTooltip(true);
      }
    }, [hasTooltip]);
    const onCheckboxMouseExit = useCallback(() => {
      if (hasTooltip) {
        setShowTooltip(false);
      }
    }, [hasTooltip]);

    return (
      <CheckboxContainer
        size={size}
        onMouseEnter={onCheckboxMouseEnter}
        onMouseLeave={onCheckboxMouseExit}
        style={containerStyleOverrides}
      >
        {hideLabel ? (
          <HideLabelCheckbox
            ref={ref}
            onChange={({ target: { checked: newValue } }) => onChange(newValue)}
            aria-label={accessibilityLabel || label}
            data-testid={testID}
            checked={checked}
            {...restOfProps}
          >
            {hasTooltip ? (
              <Tooltip {...tooltipProps} visible={showTooltip}>
                <TWTextDefault>{label}</TWTextDefault>
              </Tooltip>
            ) : (
              <TWTextDefault>{label}</TWTextDefault>
            )}
          </HideLabelCheckbox>
        ) : (
          <Checkbox
            ref={ref}
            onChange={({ target: { checked: newValue } }) => onChange(newValue)}
            aria-label={accessibilityLabel || label}
            data-testid={testID}
            checked={checked}
            {...restOfProps}
          >
            {hasTooltip ? (
              <Tooltip {...tooltipProps} visible={showTooltip}>
                <TWTextDefault>{label}</TWTextDefault>
              </Tooltip>
            ) : (
              <TWTextDefault>{label}</TWTextDefault>
            )}
          </Checkbox>
        )}
      </CheckboxContainer>
    );
  },
);

export { TWInputCheckboxText };
