import { Dayjs, OpUnitType } from 'dayjs';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';

import { dayjs } from '@tw/util';

const combineDateAndTimeMoments = (dateMoment: Moment, timeMoment: Moment): Moment | null => {
  if (!dateMoment || !timeMoment || !dateMoment.isValid() || !timeMoment.isValid()) {
    return null;
  }
  const combinedMoment = moment(dateMoment);
  combinedMoment.hour(timeMoment.hour());
  combinedMoment.minute(timeMoment.minute());
  combinedMoment.second(timeMoment.second());
  combinedMoment.millisecond(timeMoment.millisecond());
  return combinedMoment;
};

const combineDateAndTimeDayjs = (dateMoment: Dayjs, timeMoment: Dayjs): Dayjs | null => {
  if (!dateMoment || !timeMoment || !dateMoment.isValid() || !timeMoment.isValid()) {
    return null;
  }
  const dateString = dateMoment.format('YYYY-MM-DD');
  const timeString = timeMoment.format('HH:mm:ss.SSS');
  const timeZone = dateMoment.format('Z');

  return dayjs(`${dateString}T${timeString}${timeZone}`);
};

const displayDurationFromMinutes = (givenMinutes: number): string => {
  if (!_.isNumber(givenMinutes)) {
    return '';
  }
  let hours = 0;
  let minutes = givenMinutes;
  while (minutes >= 60) {
    hours += 1;
    minutes -= 60;
  }
  if (hours && minutes) {
    return `${hours}h ${minutes}m`;
  }
  return hours ? `${hours}h` : `${minutes}m`;
};

const displayDurationFromSeconds = (givenSeconds: number): string => {
  if (!_.isNumber(givenSeconds)) return '';

  let hours = 0;
  let minutes = 0;
  let seconds = givenSeconds;
  while (seconds >= 60) {
    minutes += 1;
    seconds -= 60;
    if (minutes >= 60) {
      hours += 1;
      minutes -= 60;
    }
  }
  const hoursDisplay = hours ? `${hours}h` : '';
  const minutesDisplay = minutes ? `${minutes}m` : '';
  const secondsDisplay = seconds ? `${seconds}s` : '';
  return `${hoursDisplay} ${minutesDisplay} ${secondsDisplay}`.replace(/\s+/g, ' ').trim() || '0s';
};

// Returns the last Sunday before the given day.
// If the given day is Sunday, returns that day.
const getPreviousSunday = (date: Moment): Moment => {
  // Using ISO week dates, so this doesn't rely on locale.
  // 1 = Monday, 7 = Sunday
  // https://en.wikipedia.org/wiki/ISO_week_date
  if (date.isoWeekday() === 7) {
    return date.clone().startOf('day');
  }

  return date.clone().subtract(date.isoWeekday(), 'day').startOf('day');
};

const utcDatetimeToLocalTime = (utcDatetime: string, timeZone: string, format = 'h:mma') =>
  moment.utc(utcDatetime).tz(timeZone).format(format);

const WEEKDAYS_ORDER: Record<string, number> = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};
const sortWeekdays = (daysArray: Array<string>) =>
  _.sortBy(daysArray, (day) => WEEKDAYS_ORDER[_.lowerCase(day)]);

const isDateTimeContainedInRange = (
  a: Dayjs,
  b: [Dayjs, Dayjs],
  unit: OpUnitType = 'day',
): boolean => a.isSameOrAfter(b[0], unit) && a.isSameOrBefore(b[1], unit);

// 6 different cases to consider.
// 1: A is completely before B
// 2: A is completely after B
// 3: A starts before B, ends during B
// 4: A starts during B, ends after B
// 5: A contains B
// 6: A is contained in B
// Start and End date inclusive
const areDateRangesOverlapping = (a: [Dayjs, Dayjs], b: [Dayjs, Dayjs]): boolean =>
  isDateTimeContainedInRange(a[0], b) ||
  isDateTimeContainedInRange(a[1], b) ||
  isDateTimeContainedInRange(b[0], a) ||
  isDateTimeContainedInRange(b[1], a);

const isWithinHours = (date: Date, offsetHours: number) => {
  const currentDate = moment();
  const targetDate = moment(date).toDate();
  return (
    Math.abs(currentDate.diff(targetDate, 'hours')) <= 24 &&
    Math.abs(currentDate.diff(moment(targetDate).add(offsetHours, 'hours').toDate(), 'hours')) <= 24
  );
};

export default {
  combineDateAndTimeMoments,
  combineDateAndTimeDayjs,
  displayDurationFromMinutes,
  displayDurationFromSeconds,
  getPreviousSunday,
  sortWeekdays,
  utcDatetimeToLocalTime,
  isDateTimeContainedInRange,
  areDateRangesOverlapping,
  isWithinHours,
};
