import * as React from 'react';
import { Select } from 'antd';

import { getTranslation } from '@tw/i18n';
import { StudentEligibilityStatus, StudentEligibilityStatuses } from '@tw/types';

import TWInputSelect from '../TWInputSelect';
import { TWInputSelectProps } from '../TWInputSelect/TWInputSelect';

interface TWInputSelectEligibilityProps extends TWInputSelectProps {
  popupContainerId: string;
  placeholder?: string;
  onChange: (value: StudentEligibilityStatus) => void;
  minWidth?: string;
  showSearch?: boolean;
}

const TWInputSelectEligibility: React.FC<TWInputSelectEligibilityProps> = (props) => {
  const {
    popupContainerId,
    placeholder = 'Eligibility',
    onChange,
    minWidth = '200px',
    showSearch = false,
    ...allUnrecognizedProps
  } = props;

  return (
    <TWInputSelect
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      loading={false}
      onChange={onChange}
      popupContainerId={popupContainerId}
      minWidth={minWidth}
      showSearch={showSearch}
      {...allUnrecognizedProps}
    >
      <Select.Option
        value={StudentEligibilityStatuses.ON_TRACK}
        title={getTranslation('components.onTrack')}
      >
        {getTranslation('components.onTrack')}
      </Select.Option>
      <Select.Option
        value={StudentEligibilityStatuses.AT_RISK}
        title={getTranslation('components.atRisk')}
      >
        {getTranslation('components.atRisk')}
      </Select.Option>
    </TWInputSelect>
  );
};

export default TWInputSelectEligibility;
