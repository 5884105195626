/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileUpdateMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  input?: Types.InputMaybe<Types.SharedFileInput>;
  hasFolder?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type FileUpdateMutation = {
  sharedFileUpdate: {
    sharedFile?: {
      id: string;
      pk?: number | null;
      sharedFileId?: number | null;
      folderId?: number | null;
      fileName: string;
      fileNotes?: string | null;
      isPinned?: boolean | null;
      updatedDate?: any | null;
      canDownload?: boolean | null;
      folder?: { id: string; folderName?: string | null; folderId: string; orgId: number } | null;
      creator?: {
        createdBy?: number | null;
        pictureDownloadUrl?: string | null;
        preferredName?: string | null;
        lastName?: string | null;
        initials?: string | null;
      } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const FileUpdateDocument = gql`
  mutation FileUpdate($id: ID, $input: SharedFileInput, $hasFolder: Boolean = false) {
    sharedFileUpdate(id: $id, input: $input) {
      sharedFile {
        id
        pk
        sharedFileId
        folderId
        fileName
        fileNotes
        isPinned
        updatedDate
        canDownload
        folder @include(if: $hasFolder) {
          id
          folderName
          folderId
          orgId
        }
        creator {
          createdBy
          pictureDownloadUrl
          preferredName
          lastName
          initials
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FileUpdateMutationFn = Apollo.MutationFunction<
  FileUpdateMutation,
  FileUpdateMutationVariables
>;

/**
 * __useFileUpdateMutation__
 *
 * To run a mutation, you first call `useFileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUpdateMutation, { data, loading, error }] = useFileUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      hasFolder: // value for 'hasFolder'
 *   },
 * });
 */
export function useFileUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<FileUpdateMutation, FileUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FileUpdateMutation, FileUpdateMutationVariables>(
    FileUpdateDocument,
    options,
  );
}
export type FileUpdateMutationHookResult = ReturnType<typeof useFileUpdateMutation>;
export type FileUpdateMutationResult = Apollo.MutationResult<FileUpdateMutation>;
export type FileUpdateMutationOptions = Apollo.BaseMutationOptions<
  FileUpdateMutation,
  FileUpdateMutationVariables
>;
