import { InputNumber } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { FocusEvent, useState } from 'react';

import { TWIcon, TWSpacingContainer } from '@tw/components';
import { getTranslation } from '@tw/i18n';

import {
  getDefaultReminder,
  transformDataToReminders,
  transformRemindersToMinutes,
} from './TWInputCustomReminder.utils';

import {
  BeforeText,
  ReminderRow,
  RemoveButton,
  RemoveButtonIcon,
} from './TWInputCustomReminder.styles';

import { TWButton } from '../../../presentational/buttons';
import { TWTextDefault } from '../../../presentational/typography';
import TWInputSelect from '../TWInputSelect';
import {
  FormReminder,
  ReminderType,
  ReminderUnits,
  TWInputCustomReminderProps,
  offsetTypes,
  reminderTypes,
} from './TWInputCustomReminder.definitions';

const TWInputCustomReminder = ({
  isDisabled = false,
  maxReminders = 3,
  popupContainerId,
  testID = 'TWInputCustomReminders',
  value = [],
  onChange,
}: TWInputCustomReminderProps) => {
  const [reminders, setReminders] = useState(() => transformDataToReminders(value));

  const triggerChange = (changedValue: FormReminder[]) => {
    const newValue = cloneDeep(changedValue);

    if (onChange) {
      onChange(transformRemindersToMinutes(newValue));
    }
  };

  const addNewReminder = () => {
    const newReminders = [...reminders, getDefaultReminder()];

    setReminders(newReminders);
    triggerChange(newReminders);
  };

  const handleReminderChange = (
    key: 'unit' | 'time' | 'type',
    inputPosition: number,
    newValue: ReminderType | number | ReminderUnits | null,
  ) => {
    const newReminderValues: FormReminder[] = [...reminders];

    set(newReminderValues[inputPosition], [key], newValue);

    setReminders(newReminderValues);
    triggerChange(newReminderValues);
  };

  const handleReminderUnitChange = (offsetType: ReminderUnits, indexReminder: number) =>
    handleReminderChange('unit', indexReminder, offsetType);

  const handleReminderTimeChange = (offset: number | null, indexReminder: number) => {
    handleReminderChange('time', indexReminder, offset);
  };

  const removeReminderNode = (inputPosition: number) => {
    const newReminderValues = [...reminders];
    newReminderValues.splice(inputPosition, 1);
    setReminders(newReminderValues);
    triggerChange(newReminderValues);
  };

  const renderReminderRow = (rowValue: FormReminder, index: number) => (
    <ReminderRow key={index} data-testid="TWInputCustomReminder:Row">
      <TWInputSelect
        accessibilityLabel={getTranslation('components.reminderType')}
        disabled={isDisabled}
        itemList={reminderTypes}
        maxWidth={150}
        placeholder={getTranslation('types', 1)}
        popupContainerId={popupContainerId}
        showSearch={false}
        testID={`TWInputCustomReminder:Type:${index}`}
        value={rowValue.type}
        onChange={(e: ReminderType) => handleReminderChange('type', index, e)}
      />
      <TWSpacingContainer twMargin={[0, 1]} />
      <InputNumber
        aria-label={getTranslation('components.reminderAmount')}
        data-testid={`TWInputCustomReminder:Time:Value:${index}`}
        max={999}
        min={0}
        type="number"
        value={rowValue.time}
        onChange={(offset) => handleReminderTimeChange(offset, index)}
        onBlur={(e: FocusEvent<HTMLInputElement, Element>) => {
          // set the 0 number if a user removed the time value
          if (e.target.value === '') {
            handleReminderTimeChange(0, index);
          }
        }}
        style={{ maxWidth: 150 }}
      />
      <TWSpacingContainer twMargin={[0, 1]} />
      <TWInputSelect
        accessibilityLabel={getTranslation('components.reminderUnit')}
        disabled={isDisabled}
        itemList={offsetTypes}
        maxWidth={100}
        minWidth={100}
        placeholder={getTranslation('types', 1)}
        popupContainerId={popupContainerId}
        showSearch={false}
        testID={`TWInputCustomReminder:Time:Type:${index}`}
        value={rowValue.unit}
        onChange={(offsetType: ReminderUnits) => handleReminderUnitChange(offsetType, index)}
      />
      <TWSpacingContainer twMargin={[0, 1]} />
      <BeforeText>{getTranslation('before')}</BeforeText>
      <RemoveButton
        accessibilityLabel={getTranslation('components.removeReminder')}
        data-testid={`TWInputCustomReminder:Remove:${index}`}
        onClick={() => removeReminderNode(index)}
      >
        <RemoveButtonIcon type="material-remove_circle_outline" />
      </RemoveButton>
    </ReminderRow>
  );

  const canShowAddReminderButton = reminders.length < maxReminders;

  return (
    <div data-testid={testID}>
      {reminders.map((reminder, index) => renderReminderRow(reminder, index))}
      <TWButton
        accessibilityLabel={getTranslation('components.addReminder')}
        disabled={!canShowAddReminderButton}
        testID="TWInputCustomReminder:Add"
        onClick={addNewReminder}
      >
        <TWIcon type="material-control_point" />
        <TWTextDefault>{getTranslation('components.addReminder')}</TWTextDefault>
      </TWButton>
    </div>
  );
};

export default TWInputCustomReminder;
