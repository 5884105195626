/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBulletinMutationVariables = Types.Exact<{
  input: Types.BulletinInput;
}>;

export type CreateBulletinMutation = {
  bulletinCreate: {
    bulletin?: {
      bulletinId: string;
      bulletinType: Types.BulletinType;
      label: string;
      externalUrl?: string | null;
      isPinned: boolean;
      createdDate?: any | null;
      content: string;
      expirationDateTime?: any | null;
      headerImageFull?: string | null;
      headerImageThumb?: string | null;
      key?: string | null;
      visibilityCodes?: Array<string | null> | null;
      creator?: {
        pk?: string | null;
        initials?: string | null;
        pictureDownloadUrl?: string | null;
        fullName?: string | null;
      } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const CreateBulletinDocument = gql`
  mutation CreateBulletin($input: BulletinInput!) {
    bulletinCreate(input: $input) {
      bulletin {
        key: pk
        bulletinId
        bulletinType
        label
        externalUrl
        isPinned
        createdDate
        creator {
          pk
          fullName: fullNameNormalOrder
          initials
          pictureDownloadUrl
        }
        content
        expirationDateTime
        headerImageFull
        headerImageThumb
        visibilityCodes: visibility
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CreateBulletinMutationFn = Apollo.MutationFunction<
  CreateBulletinMutation,
  CreateBulletinMutationVariables
>;

/**
 * __useCreateBulletinMutation__
 *
 * To run a mutation, you first call `useCreateBulletinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulletinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulletinMutation, { data, loading, error }] = useCreateBulletinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBulletinMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBulletinMutation, CreateBulletinMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBulletinMutation, CreateBulletinMutationVariables>(
    CreateBulletinDocument,
    options,
  );
}
export type CreateBulletinMutationHookResult = ReturnType<typeof useCreateBulletinMutation>;
export type CreateBulletinMutationResult = Apollo.MutationResult<CreateBulletinMutation>;
export type CreateBulletinMutationOptions = Apollo.BaseMutationOptions<
  CreateBulletinMutation,
  CreateBulletinMutationVariables
>;
