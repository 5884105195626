import { datadogRum } from '@datadog/browser-rum';
import Polyglot from 'node-polyglot';
import dictionary, {
  i18nLocaleTranslator,
  TranslationKey,
  TWInterpolationOptions,
  TWPolyglot,
} from './@dictionary';
import en from './@dictionary/en_US.json';

import { TranslatorConsumer, TranslatorProvider, useTranslator } from './translatorContext';
import withTranslatorFactory from './withTranslator';

const translator = i18nLocaleTranslator;

const getTranslation = (key: TranslationKey, options?: TWInterpolationOptions) => {
  if (translator.has(key)) {
    return translator.t(key, options);
  }

  // Dictionary doesn't have key, try the English dictionary
  const englishDictionary = new Polyglot({ phrases: en });
  if (englishDictionary.has(key)) {
    console.warn(`Cannot find translated dictionary key ${key}, falling back to English value`);
    return englishDictionary.t(key, options);
  }

  // Even the English Dictionary doesn't have key.
  // Polyglot will return the key as the displayed string and log an error
  datadogRum.addError(new Error('Missing English dictionary key'), { key });
  return englishDictionary.t(key, options);
};

const withTranslator = withTranslatorFactory(TranslatorConsumer);

// Type predicate for translation keys
export const isTranslationKey = (key: string | TranslationKey): key is TranslationKey =>
  translator.has(key);

export { default as translatorPropType } from './translatorPropType';
export { dictionary, getTranslation, i18nLocaleTranslator };
export { TranslatorProvider, TranslatorConsumer, withTranslator, useTranslator };
export type { TWInterpolationOptions, TranslationKey, TWPolyglot };
