import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import session from '@tw/services/session';
import { datadogRum } from '@datadog/browser-rum';

const LoginCallback = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const code = searchParams.get('code') as string;
  const state = searchParams.get('state') as string;
  const error = searchParams.get('error') as string;
  const errorDescription = searchParams.get('error_description') as string;

  if (error) {
    session.logoutWithoutRedirect();
    throw new Error(`401: ${errorDescription || 'Access Denied'}`);
  }

  if (!code || !state) {
    session.logout();
    throw new Error('Invalid code or state');
  }

  const exchangeCodeForToken = useCallback(async () => {
    if (code && state && !error) {
      try {
        const accessToken = await session.exchangeCodeForAccessToken(code, state);
        if (!accessToken) throw Error('Failed to exchange code for access token');

        const success = await session.exchangeOktaTokenForHubToken(accessToken);
        if (success) {
          history.push('/home/overview');
        }
      } catch (err) {
        datadogRum.addAction('exchangeCodeForToken error', err);
        setAuthError('Failed to login');
      }
    }
  }, [code, state, error, history]);

  useEffect(() => {
    exchangeCodeForToken();
  }, [exchangeCodeForToken]);

  const [authError, setAuthError] = useState('');
  useEffect(() => {
    // Bubble up to sad footballer
    if (authError) throw new Error(authError);
  }, [authError]);

  return null;
};

export default LoginCallback;
