import { Button } from 'antd';
import { PropsWithChildren } from 'react';

import { getTranslation } from '@tw/i18n';

import { TWModal } from '../../../../components.web';
import { FooterInfo } from './UserSelectionModalBase.styles';

interface UserSelectionModalBaseProps extends PropsWithChildren {
  title: React.ReactNode;
  width: number;
  containerId: string;
  testID: string;
  open?: boolean;
  footerMessage?: string;
  onOk: () => void;
  onCancel: () => void;
  loading?: boolean;
}

/*
 * This is just common base for other modals. Do not use it directly.
 */
const UserSelectionModalBase = ({
  children,
  footerMessage,
  onOk,
  onCancel,
  loading,
  ...allUnrecognizedProps
}: UserSelectionModalBaseProps) => {
  const footer = [
    <Button key="back" data-testid="UserSelection:Modal:CloseButton" onClick={onCancel}>
      {getTranslation('close')}
    </Button>,
    <Button
      key="submit"
      type="primary"
      data-testid="UserSelection:Modal:OKButton"
      onClick={onOk}
      tabIndex={0}
      loading={loading}
    >
      {getTranslation('save')}
    </Button>,
  ];
  if (footerMessage) {
    footer.unshift(<FooterInfo key="message">{footerMessage}</FooterInfo>);
  }

  return (
    <TWModal
      maskClosable
      destroyOnClose
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      {...allUnrecognizedProps}
      bodyStyle={{ minHeight: 464, padding: 24 }}
    >
      {children || (
        <strong>
          Do not use this modal directly. It is common base for other User Selection modals.
        </strong>
      )}
    </TWModal>
  );
};

UserSelectionModalBase.defaultProps = {
  footerMessage: '',
  open: false,
};

export default UserSelectionModalBase;
