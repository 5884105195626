/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideosFolderUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  folderName: Types.Scalars['String'];
}>;

export type VideosFolderUpdateMutation = {
  folderUpdate: {
    folder?: {
      id: string;
      orgId: number;
      teamId?: number | null;
      folderId: string;
      folderName?: string | null;
      folderType?: string | null;
      sortOrder?: number | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const VideosFolderUpdateDocument = gql`
  mutation VideosFolderUpdate($id: ID!, $folderName: String!) {
    folderUpdate(id: $id, input: { folderName: $folderName }) {
      folder {
        id
        orgId
        teamId
        folderId
        folderName
        folderType
        sortOrder
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type VideosFolderUpdateMutationFn = Apollo.MutationFunction<
  VideosFolderUpdateMutation,
  VideosFolderUpdateMutationVariables
>;

/**
 * __useVideosFolderUpdateMutation__
 *
 * To run a mutation, you first call `useVideosFolderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideosFolderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videosFolderUpdateMutation, { data, loading, error }] = useVideosFolderUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      folderName: // value for 'folderName'
 *   },
 * });
 */
export function useVideosFolderUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideosFolderUpdateMutation,
    VideosFolderUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VideosFolderUpdateMutation, VideosFolderUpdateMutationVariables>(
    VideosFolderUpdateDocument,
    options,
  );
}
export type VideosFolderUpdateMutationHookResult = ReturnType<typeof useVideosFolderUpdateMutation>;
export type VideosFolderUpdateMutationResult = Apollo.MutationResult<VideosFolderUpdateMutation>;
export type VideosFolderUpdateMutationOptions = Apollo.BaseMutationOptions<
  VideosFolderUpdateMutation,
  VideosFolderUpdateMutationVariables
>;
