import { gql } from '@apollo/client';

export const FETCH_STUDENT_TERMS = gql`
  query FetchStudentTerms(
    $after: String
    $first: Int
    $searchStudents: String
    $teamIds: [Int]
    $termId: TWFilter_Int
    $studentId: TWFilter_Int
    $studentTermId: TWFilter_ID
    $advisorIds: [Int]
    $courseIds: [Int]
    $courseSectionIds: [Int]
    $isAtRisk: Boolean
    $studentYearId: TWFilter_Int
    $eligibilityYearIds: [Int]
    $checkinStartDay: String
    $checkinEndDay: String
  ) {
    studentTerms(
      after: $after
      first: $first
      searchStudents: $searchStudents
      teamIds: $teamIds
      termId: $termId
      studentId: $studentId
      studentTermId: $studentTermId
      advisorIds: $advisorIds
      courseIds: $courseIds
      courseSectionIds: $courseSectionIds
      isAtRisk: $isAtRisk
      studentYearId: $studentYearId
      eligibilityYearIds: $eligibilityYearIds
      checkinStartDay: $checkinStartDay
      checkinEndDay: $checkinEndDay
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          studentTermId: pk
          creditsAttempted
          creditsCompleted
          finalGpa
          goalGpa
          midtermGpa
          studentId
          studentYear {
            id
            label
            studentYearId
          }
          student {
            id
            studentId: pk
            preferredName
            lastName
            fullName
            teamIds
            personId
            isAtRisk
            eligibilityYears
            isDeleted
            cumulativeGpa
            academicStatus
            advisors {
              totalCount
              edges {
                cursor
                node {
                  id
                  advisorId: pk
                  personId
                  preferredName
                  lastName
                  pictureDownloadUrl
                }
              }
            }
            pictureDownloadUrl
            metaData: meta
            person {
              id
              emailAddress
              phoneCell: mobilePhone
              phoneHome
              phoneOffice
            }
            studyHallDetails {
              isCurrentlyCheckedIn
              weeklyAccumulatedMinutes
              weeklyHoursRequired
            }
          }
          termId
          term {
            id
          }
          enrollments {
            edges {
              node {
                id
                courseSection {
                  id
                  courseId
                  course {
                    id
                    credits
                  }
                }
                creditsAttempted
                droppedDate
              }
            }
          }
        }
      }
    }
  }
`;
