/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAvailabilitySlotDetailQueryVariables = Types.Exact<{
  appointmentId: Types.Scalars['TWFilter_ID'];
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
  courseId?: Types.InputMaybe<Types.Scalars['TWFilter_ID']>;
  includeGeneral?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetAvailabilitySlotDetailQuery = {
  findTutorAppointments?: {
    edges: Array<{
      node?: {
        appointmentId: string;
        startTimeZone?: string | null;
        location: string;
        rrule?: string | null;
        appointmentType?: {
          appointmentTypeId?: number | null;
          color: string;
          label: string;
        } | null;
        occurrences?: Array<{
          appointmentId?: number | null;
          appointmentOccurrenceId?: number | null;
          endDateTime?: any | null;
          startDateTime?: any | null;
        } | null> | null;
        attendees?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              personId: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              label?: string | null;
              selectionCode?: string | null;
            }
          | {
              groupType?: Types.GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
  courses?: {
    totalCount?: number | null;
    edges: Array<{
      cursor: string;
      node?: { courseId: string; label?: string | null } | null;
    } | null>;
  } | null;
};

export const GetAvailabilitySlotDetailDocument = gql`
  query GetAvailabilitySlotDetail(
    $appointmentId: TWFilter_ID!
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $selections: [String]!
    $courseId: TWFilter_ID
    $includeGeneral: Boolean
  ) {
    findTutorAppointments(
      appointmentId: $appointmentId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      selections: $selections
    ) {
      edges {
        node {
          appointmentId
          appointmentType {
            appointmentTypeId
            color
            label
          }
          occurrences {
            appointmentId
            appointmentOccurrenceId
            endDateTime
            startDateTime
          }
          startTimeZone
          location
          rrule
          attendees {
            label
            selectionCode
            ... on PersonNode {
              id
              personId
              pictureUrl
              preferredName
              lastName
              initials
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
              }
            }
          }
        }
      }
    }
    courses(courseId: $courseId, includeGeneral: $includeGeneral) {
      totalCount
      edges {
        cursor
        node {
          courseId
          label
        }
      }
    }
  }
`;

/**
 * __useGetAvailabilitySlotDetailQuery__
 *
 * To run a query within a React component, call `useGetAvailabilitySlotDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilitySlotDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilitySlotDetailQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      selections: // value for 'selections'
 *      courseId: // value for 'courseId'
 *      includeGeneral: // value for 'includeGeneral'
 *   },
 * });
 */
export function useGetAvailabilitySlotDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailabilitySlotDetailQuery,
    GetAvailabilitySlotDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAvailabilitySlotDetailQuery, GetAvailabilitySlotDetailQueryVariables>(
    GetAvailabilitySlotDetailDocument,
    options,
  );
}
export function useGetAvailabilitySlotDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailabilitySlotDetailQuery,
    GetAvailabilitySlotDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailabilitySlotDetailQuery,
    GetAvailabilitySlotDetailQueryVariables
  >(GetAvailabilitySlotDetailDocument, options);
}
export type GetAvailabilitySlotDetailQueryHookResult = ReturnType<
  typeof useGetAvailabilitySlotDetailQuery
>;
export type GetAvailabilitySlotDetailLazyQueryHookResult = ReturnType<
  typeof useGetAvailabilitySlotDetailLazyQuery
>;
export type GetAvailabilitySlotDetailQueryResult = Apollo.QueryResult<
  GetAvailabilitySlotDetailQuery,
  GetAvailabilitySlotDetailQueryVariables
>;
