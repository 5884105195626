import styled from '@emotion/styled';

const TWDataTableFilters = styled.div(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'flex-start',
  '.ant-select, .ant-picker-range, .ant-input': {
    marginBottom: theme.baseUnit * 3,
    marginRight: theme.baseUnit * 2,
  },
  '.ant-input-search-button': {
    marginBottom: theme.baseUnit * 3,
    marginRight: theme.baseUnit * 2,
  },
  // fix search input which has a clear button
  '.ant-input-affix-wrapper': {
    marginBottom: theme.baseUnit * 3,
    marginRight: theme.baseUnit * 2,
  },
  '.ant-input-affix-wrapper .ant-input': {
    marginBottom: '0px',
    marginRight: '0px',
  },
}));

export default TWDataTableFilters;
