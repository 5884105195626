import _ from 'lodash';

import { graphqlUtils } from '@tw/util';
import {
  ProfessorPaginatedData,
  ProfessorPaginatedRawData,
  ProfessorQueryVariables,
  ProfessorRawQueryVariables,
} from '@tw/types';

const normalizePaginatedProfessorData = (
  data: ProfessorPaginatedRawData,
): ProfessorPaginatedData => {
  if (_.isEmpty(data)) {
    return null;
  }

  const { professors } = data;
  const { edges, pageInfo, totalCount } = professors;

  return {
    professors: graphqlUtils.convertEdgesToArray(edges),
    pageInfo,
    totalCount,
  };
};

const normalizeProfessorVariables = (
  variables: ProfessorQueryVariables,
): ProfessorRawQueryVariables => {
  if (_.isEmpty(variables)) {
    return null;
  }

  const { professorId, sort } = variables;
  return {
    professorId: !_.isEmpty(professorId)
      ? graphqlUtils.createArrayIncludesQuery(_.map(professorId, (id) => String(id)))
      : undefined,
    // DO NOT pass an isActive filter until COL-1845 is resolved
    // isActive: _.isBoolean(isActive) ? graphqlUtils.createBooleanQuery(isActive) : undefined,
    sort,
  };
};

export default { normalizePaginatedProfessorData, normalizeProfessorVariables };
