import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

import { GraphQLPaginatedResponse, Student, StudentTerm, Term } from '@tw/types';

import { STUDENT_TERM_BULK_SELECTION_QUERY } from '@tw/modules/Academics2/@graphql';

export type StudentTermBulkSelection = Pick<StudentTerm, 'id' | 'studentId'> & {
  student: Pick<Student, 'id' | 'fullName' | 'personId' | 'teamIds'>;
  term: Pick<Term, 'startDate' | 'endDate'>;
};

export interface StudentTermBulkSelectionVariables {
  searchStudents?: string;
  teamIds?: number[];
  termId?: number | string;
  studentId?: number | string;
  studentTermId?: number | string;
  advisorIds?: number[];
  courseIds?: number[];
  courseSectionIds?: number[];
  isAtRisk?: boolean;
  studentYearId?: number | string;
  eligibilityYearIds?: number[];
}

interface StudentTermBulkSelectionData {
  studentTerms: Pick<GraphQLPaginatedResponse<StudentTermBulkSelection>, 'edges'>;
}

export const useStudentTermBulkSelectionLazyQuery = (
  options?: LazyQueryHookOptions<StudentTermBulkSelectionData, StudentTermBulkSelectionVariables>,
) =>
  useLazyQuery<StudentTermBulkSelectionData, StudentTermBulkSelectionVariables>(
    STUDENT_TERM_BULK_SELECTION_QUERY,
    options,
  );
