import styled from '@emotion/styled';
import { TWIcon } from '@tw/components';

export const AspectRatio = styled.div<{ ratio: number; width: number }>(
  ({ ratio, width, theme }) => ({
    height: 'auto',
    width: '100%',
    cursor: 'pointer',
    maxWidth: width,
    position: 'relative',
    color: theme.colors.gray,
    background: theme.colors.slateBackground,
    '&:before': {
      height: 0,
      content: `""`,
      display: 'block',
      paddingBottom: `${(1 / ratio) * 100}%`,
    },
    '& > *:not(style)': {
      overflow: 'hidden',
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      zIndex: 999,
    },
    '& > img, & > video': {
      objectFit: 'cover',
    },
  }),
);

export const PlayButton = styled(TWIcon)(() => ({
  background: 'rgba(0,0,0.4)',
  padding: '0.5rem',
  borderRadius: '100%',
  fontSize: 78,
}));

export const ResponsiveContainer = styled.div<{ mobile: boolean }>(({ mobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 20,
  padding: mobile ? '40px 20px' : '40px 80px',
}));

export const SmallLogo = styled.img(() => ({
  height: 31,
  width: 123,
}));

export const VideoContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 20,
}));
