import styled from '@emotion/styled';
import { Checkbox, CheckboxProps } from 'antd';

const HideLabelCheckbox = styled(Checkbox)({
  color: 'green',
  '& > span:last-child': {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
});

type IndeterminateCheckboxProps = {
  checked: boolean;
  indeterminate: boolean;
  'aria-label'?: string;
  'aria-labelledby'?: string;
} & CheckboxProps;

const IndeterminateCheckbox = ({
  indeterminate,
  checked,
  ...props
}: IndeterminateCheckboxProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <HideLabelCheckbox checked={checked} indeterminate={indeterminate} {...props}>
    Select
  </HideLabelCheckbox>
);

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export default IndeterminateCheckbox;
