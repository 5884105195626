import { isApolloError } from '@apollo/client';
import { useState } from 'react';

import { useSwitchTeams, useTranslator, useViewer } from '@tw/hooks';
import { relayUtils, urlUtils } from '@tw/util';

import { TWButtonIconFlat } from '../../../presentational/buttons';
import TWIcon from '../../TWIcon';
import { TWMessage } from '../../TWMessage';
import { TWModal } from '../../TWModal';

interface TWDrawerButtonProfileProps {
  containerId: string;
  globalPersonId: string;
  teamId?: number;
  testID: string;
}

export const TWDrawerButtonProfile = ({
  globalPersonId,
  teamId,
  containerId,
  testID,
}: TWDrawerButtonProfileProps) => {
  const { teamId: currentUserTeamId, personId: currentUserPersonId } = useViewer();
  const translator = useTranslator();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // check for null or undefined
  const disabled = Boolean(teamId) === false;

  const profilePath = urlUtils.getProfileUrl(globalPersonId);

  const [switchTeams] = useSwitchTeams({ redirectPath: profilePath });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await switchTeams({ variables: { team: relayUtils.toGlobalId('TeamNode', String(teamId)) } });
      setIsModalOpen(false);
    } catch (error) {
      if (isApolloError(error)) {
        TWMessage.handleErrorWithContext({
          error,
          context: {
            currentUserPersonId,
            currentUserTeamId,
            targetPersonId: globalPersonId,
            targetTeamId: teamId,
          },
        });
      } else {
        TWMessage.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (currentUserTeamId !== teamId) {
      setIsModalOpen(true);
    } else {
      handleSubmit();
    }
  };

  const handleClose = () => setIsModalOpen(false);

  return (
    <>
      <TWModal
        containerId={containerId}
        title={translator.t('confirmProfileSwitchTeamsTitle')}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleClose}
        okButtonProps={{ loading }}
      >
        {translator.t('confirmProfileSwitchTeams')}
      </TWModal>
      <TWButtonIconFlat
        accessibilityLabel={translator.t('studentsTab.actions.viewProfile')}
        disabled={disabled}
        label={translator.t('studentsTab.actions.viewProfile')}
        data-testid={testID}
        rel="noopener noreferrer"
        onClick={onClick}
        title={translator.t('studentsTab.actions.viewProfile')}
      >
        <TWIcon type="tw-expand-profile" />
      </TWButtonIconFlat>
    </>
  );
};
