import { gql } from '@apollo/client';

export const FetchProfessors = gql`
  query FetchProfessors(
    $professorId: TWFilter_ID
    $isActive: TWFilter_Boolean
    $sort: [AC2ProfessorSortEnum]
  ) {
    professors(professorId: $professorId, isActive: $isActive, sort: $sort) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          professorId
          fullName
          emailAddress
          phoneNumber
          officeLocation
        }
      }
    }
  }
`;
