import * as React from 'react';
import { Col } from 'antd';

import { TWInputCheckboxText } from '../TWInputCheckboxText';
import { CheckboxesRow } from './TWInputCheckboxGroup.styles';
import { TWInputCheckboxGroupProps } from './TWInputCheckboxGroup.definitions';

const TWInputCheckboxGroup: React.FC<TWInputCheckboxGroupProps> = ({
  checkboxOptions,
  onChange,
  size = 'default',
  value = {},
  column = false,
  ...restOfProps
}) => {
  const handleOnCheckboxClicked = (name: string, newValue: boolean) => {
    const newCheckboxState = {
      ...value,
      [name]: newValue,
    };
    onChange(newCheckboxState);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CheckboxesRow column={column} {...restOfProps}>
      {checkboxOptions.map(({ name, testID: checkboxTestID, col, ...restCheckboxOfProps }) => (
        <Col span={col} key={checkboxTestID}>
          <TWInputCheckboxText
            size={size}
            checked={value[name]}
            testID={checkboxTestID}
            onChange={() => handleOnCheckboxClicked(name, !value[name])}
            {...restCheckboxOfProps}
          />
        </Col>
      ))}
    </CheckboxesRow>
  );
};

export { TWInputCheckboxGroup };
