import { useMemo } from 'react';

import {
  EligibilityYear,
  PersonNode,
  SelectionGroup,
  SportPosition,
  useSelectablesDataQuery,
} from '@tw/generated';
import { useViewer } from '@tw/hooks';

// If the BE ever adds a new type of selectable object, ADD IT TO HERE!!! Or better yet, get
// the BE to combine all of these into one type in the GQL schema and just use that here.
export type SelectableInterface = (
  | PersonNode
  | SelectionGroup
  | EligibilityYear
  | SportPosition
) & { __typename?: string };

export const isGroup = (selectable?: SelectableInterface): selectable is SelectionGroup =>
  // eslint-disable-next-line no-underscore-dangle
  !!selectable && '__typename' in selectable && selectable.__typename === 'SelectionGroup';
export const isPerson = (selectable?: SelectableInterface): selectable is PersonNode =>
  // eslint-disable-next-line no-underscore-dangle
  !!selectable && '__typename' in selectable && selectable.__typename === 'PersonNode';
export const isEligibilityYear = (
  selectable?: SelectableInterface,
): selectable is EligibilityYear =>
  // eslint-disable-next-line no-underscore-dangle
  !!selectable && '__typename' in selectable && selectable.__typename === 'EligibilityYear';

type UseSelectablesResult = {
  selectables?: SelectableInterface[] | null;
  loading: boolean;
};

const useSelectables = (selectionCodes: string[], options = {}): UseSelectablesResult => {
  const viewer = useViewer();
  const { loading, data, error } = useSelectablesDataQuery({
    skip: selectionCodes.length === 0,
    variables: {
      codes: selectionCodes,
    },
    ...options,
  });

  // Filter out selectables not in current user org
  const selectables = useMemo(
    () =>
      data?.selectables?.reduce<SelectableInterface[]>((acc, selectable) => {
        if (selectable) {
          if ('groupType' in selectable && selectable.team?.orgId !== viewer.orgId) {
            return acc;
          }

          if ('personId' in selectable && selectable.orgId !== viewer.orgId) {
            return acc;
          }

          return [...acc, selectable];
        }

        return acc;
      }, []),

    [data, viewer.orgId],
  );

  if (error) {
    console.error(error);
    return { selectables: null, loading };
  }

  if (loading) {
    return { selectables: null, loading };
  }

  return { selectables, loading };
};

export default useSelectables;

// Temporary function to filter out inactive users from a selectables array.
// This will eventually be replaced by backend filtering of inactive users.
// NOTE: This is an imperfect solution since a person could be on only an
// inactive team and still have their active flag be true.
export const filterInactiveUsersFromSelection = (
  users: SelectableInterface[],
): SelectableInterface[] =>
  users?.filter((attendee) => {
    if (!attendee) return false;
    if (isGroup(attendee)) {
      const activePeople = attendee.people?.filter((person) => person?.active) ?? [];
      return activePeople.length > 0;
    }

    return isPerson(attendee) && attendee?.active;
  });
