import _ from 'lodash';

import { DayTime } from '@tw/types';

export const validateDayTimeCompletion = (dayTimes: DayTime[]): string => {
  if (_.isEmpty(dayTimes)) {
    return 'No days/times selected';
  }

  let reason = '';
  _.forEach(dayTimes, (dayTime) => {
    if (_.isEmpty(dayTime.days)) {
      reason = 'No days selected';
      return false;
    }
    if (!dayTime.startTime) {
      reason = 'No start time selected';
      return false;
    }

    if (!dayTime.endTime) {
      reason = 'No end time selected';
      return false;
    }

    if (!dayTime.startTime.isValid() || !dayTime.endTime.isValid()) {
      reason = 'Time selections are corrupted, please close and reopen this form';
      return false;
    }

    if (dayTime.startTime.isAfter(dayTime.endTime)) {
      reason = 'Start time must be after end time';
      return false;
    }

    return true;
  });

  return reason;
};
