import { Fallback } from '@tw/components';
import { FfController } from '@tw/contexts/featureFlags/components';
import { useViewer } from '@tw/hooks';
import { PrimaryNav } from '@tw/modules/Navigation';
import localStorage from '@tw/services/localStorage';
import isEmpty from 'lodash/isEmpty';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { Content, Layout, Main } from './Hub.styles';

const Academics = lazy(() => import(/* webpackChunkName: "Academics" */ '@tw/modules/Academics2'));
const AccountSettings = lazy(
  () => import(/* webpackChunkName: "AccountSettings" */ '@tw/modules/AccountSettings'),
);
const ChooseAccount = lazy(
  () => import(/* webpackChunkName: "ChooseAccount" */ '@tw/modules/Authentication/ChooseAccount'),
);
const HomeTab = lazy(() => import(/* webpackChunkName: "HomeTab" */ '@tw/modules/Home/HomeTab'));
const Messaging = lazy(() => import(/* webpackChunkName: "Messaging" */ './Messaging'));
const Profiles = lazy(() => import(/* webpackChunkName: "Profiles" */ '@tw/modules/Profiles'));
const Tasks = lazy(() => import(/* webpackChunkName: "Tasks" */ '@tw/modules/Tasks'));
const TimeManagement = lazy(
  () => import(/* webpackChunkName: "TimeManagement" */ '@tw/modules/TimeManagement'),
);
const Travel = lazy(() => import(/* webpackChunkName: "Travel" */ '@tw/modules/Travel'));
const TutorSessionReport = lazy(
  () => import(/* webpackChunkName: "Profiles" */ '@tw/modules/Academics2/TutorSessionReport'),
);
const Calendar = lazy(
  () => import(/* webpackChunkName: "Calendar" */ '@tw/modules/Calendar/CalendarTabs'),
);
const FilesAndVideos = lazy(
  () => import(/* webpackChunkName: "FilesAndVideos" */ '@tw/modules/Files/FilesAndVideosTabs'),
);
const Forms = lazy(() => import(/* webpackChunkName: "Forms" */ '@tw/modules/Forms/Forms'));

const navigationBlockList = ['/chooseAccount', '/login'];

const AuthenticatedApp = () => {
  const viewer = useViewer();
  const history = useHistory();
  const location = useLocation();

  useEffectOnce(() => {
    const currentUser = localStorage.getCurrentUser();
    if (isEmpty(currentUser)) {
      history.replace('/chooseAccount');
    }
  });

  const hideScroll = location.pathname.includes('/forms/quickFormBuilder');
  const hasNavigation = !navigationBlockList.includes(location.pathname);

  return (
    <Layout roundedLayout={hasNavigation} topColor={viewer?.sharedNavbar?.topColor ?? '#000000'}>
      {!!viewer?.personId && hasNavigation && <PrimaryNav />}
      <Content roundedLayout={hasNavigation} hideScroll={hideScroll} id="content">
        <Main>
          <Suspense fallback={<Fallback />}>
            <Switch>
              <Route path="/chooseAccount" component={ChooseAccount} />
              <Route path="/home" component={HomeTab} />
              <Route path="/academics/tutorSessionReport" component={TutorSessionReport} />
              <Route path="/academics" component={Academics} />
              <Route path="/tasks" component={Tasks} />
              <Route path="/calendar" component={Calendar} />
              <Route path="/messaging" component={Messaging} />
              <Route path="/profiles" component={Profiles} />
              <Route path="/timeManagement" component={TimeManagement} />
              <Route path="/travel" component={Travel} />
              <Route path="/accountSettings" component={AccountSettings} />
              <Route path="/forms" component={Forms} />
              <Route path="/files" component={FilesAndVideos} />
              <Redirect from="/ext/formcallback" to="/home/forms" />
              <Redirect from="/home/tasks" to="/tasks" />
              <Redirect from="/home/tasks/manager" to="/tasks/manager" />
              <Redirect to="/home/overview" />
            </Switch>
          </Suspense>
        </Main>
      </Content>
      <FfController flags={['rsvpWeb', 'disableMessaging']} position="bottom-right" />
    </Layout>
  );
};

export default AuthenticatedApp;
