import * as React from 'react';
import { Tabs } from 'antd';

import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';

import { useTranslator } from '@tw/i18n';
import { TabsContainer, TabContent, TabTitle, TabWithLeftDivider } from './TabFilters.styles';
import { TabFiltersProps } from './TabFilters.definitions';

const { TabPane } = Tabs;

const TabFilters: React.FC<TabFiltersProps> = ({
  animated,
  defaultActiveKey,
  tabs,
  destroyInactiveTabPane = false,
  withHistoryReplace = false,
}) => {
  const history = useHistory();
  const translator = useTranslator();
  const location = useLocation();

  const urlParams = queryString.parse(location.search) || {};
  const { tab: currentTabName } = urlParams;

  const handleTabChange = (newTabKey: String) =>
    withHistoryReplace
      ? history.replace({
          ...location,
          search: queryString.stringify({
            ...urlParams,
            tab: newTabKey,
          }),
        })
      : history.push({
          ...location,
          search: queryString.stringify({
            ...urlParams,
            tab: newTabKey,
          }),
        });

  return (
    <TabsContainer>
      <Tabs
        animated={animated}
        onChange={handleTabChange}
        activeKey={(currentTabName as string) || defaultActiveKey}
        destroyInactiveTabPane={destroyInactiveTabPane}
      >
        {_.map(tabs, ({ content, divider, routeName, titleKey }) => (
          <TabPane
            key={routeName}
            tab={
              divider ? (
                <TabWithLeftDivider>{translator.t(titleKey)}</TabWithLeftDivider>
              ) : (
                <TabTitle>{translator.t(titleKey)}</TabTitle>
              )
            }
          >
            <TabContent>{content}</TabContent>
          </TabPane>
        ))}
      </Tabs>
    </TabsContainer>
  );
};

export default TabFilters;
