/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkRevokeTicketingEventPersonMutationVariables = Types.Exact<{
  input: Types.TicketingEventPersonBulkRevokeInput;
}>;

export type BulkRevokeTicketingEventPersonMutation = {
  ticketingEventPersonBulkRevoke: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const BulkRevokeTicketingEventPersonDocument = gql`
  mutation BulkRevokeTicketingEventPerson($input: TicketingEventPersonBulkRevokeInput!) {
    ticketingEventPersonBulkRevoke(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type BulkRevokeTicketingEventPersonMutationFn = Apollo.MutationFunction<
  BulkRevokeTicketingEventPersonMutation,
  BulkRevokeTicketingEventPersonMutationVariables
>;

/**
 * __useBulkRevokeTicketingEventPersonMutation__
 *
 * To run a mutation, you first call `useBulkRevokeTicketingEventPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkRevokeTicketingEventPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkRevokeTicketingEventPersonMutation, { data, loading, error }] = useBulkRevokeTicketingEventPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkRevokeTicketingEventPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkRevokeTicketingEventPersonMutation,
    BulkRevokeTicketingEventPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkRevokeTicketingEventPersonMutation,
    BulkRevokeTicketingEventPersonMutationVariables
  >(BulkRevokeTicketingEventPersonDocument, options);
}
export type BulkRevokeTicketingEventPersonMutationHookResult = ReturnType<
  typeof useBulkRevokeTicketingEventPersonMutation
>;
export type BulkRevokeTicketingEventPersonMutationResult =
  Apollo.MutationResult<BulkRevokeTicketingEventPersonMutation>;
export type BulkRevokeTicketingEventPersonMutationOptions = Apollo.BaseMutationOptions<
  BulkRevokeTicketingEventPersonMutation,
  BulkRevokeTicketingEventPersonMutationVariables
>;
