/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTicketingBlockedGuestMutationVariables = Types.Exact<{
  input: Types.TicketingBlockedGuestInput;
}>;

export type CreateTicketingBlockedGuestMutation = {
  ticketingBlockedGuestCreate: {
    ticketingBlockedGuest?: {
      id: string;
      notes?: string | null;
      attributes?: Array<{
        attributeId?: string | null;
        attributeValue?: string | null;
      } | null> | null;
    } | null;
  };
};

export const CreateTicketingBlockedGuestDocument = gql`
  mutation CreateTicketingBlockedGuest($input: TicketingBlockedGuestInput!) {
    ticketingBlockedGuestCreate(input: $input) {
      ticketingBlockedGuest {
        id
        attributes {
          attributeId
          attributeValue
        }
        notes
      }
    }
  }
`;
export type CreateTicketingBlockedGuestMutationFn = Apollo.MutationFunction<
  CreateTicketingBlockedGuestMutation,
  CreateTicketingBlockedGuestMutationVariables
>;

/**
 * __useCreateTicketingBlockedGuestMutation__
 *
 * To run a mutation, you first call `useCreateTicketingBlockedGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketingBlockedGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketingBlockedGuestMutation, { data, loading, error }] = useCreateTicketingBlockedGuestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTicketingBlockedGuestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTicketingBlockedGuestMutation,
    CreateTicketingBlockedGuestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTicketingBlockedGuestMutation,
    CreateTicketingBlockedGuestMutationVariables
  >(CreateTicketingBlockedGuestDocument, options);
}
export type CreateTicketingBlockedGuestMutationHookResult = ReturnType<
  typeof useCreateTicketingBlockedGuestMutation
>;
export type CreateTicketingBlockedGuestMutationResult =
  Apollo.MutationResult<CreateTicketingBlockedGuestMutation>;
export type CreateTicketingBlockedGuestMutationOptions = Apollo.BaseMutationOptions<
  CreateTicketingBlockedGuestMutation,
  CreateTicketingBlockedGuestMutationVariables
>;
