import { getTranslation } from '@tw/i18n';
import { TWHeading2, TWHeading3 } from '@tw/components';

import { MessageWrapper } from './TWUnauthorizedAccess.styles';

const TWUnauthorizedAccess = ({ path }: { path?: string }) => (
  <MessageWrapper>
    <TWHeading2>
      {path && path?.length > 0
        ? getTranslation('errors.unauthorizedAccessToPageWithName', { path })
        : getTranslation('errors.unauthorizedAccessToPage')}
    </TWHeading2>
    <br />
    <TWHeading3>{getTranslation('errors.contactAdministrators')}</TWHeading3>
  </MessageWrapper>
);

export default TWUnauthorizedAccess;
