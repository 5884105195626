import { castArray } from 'lodash';

import { Term } from '@tw/types';
import { dayjs } from '@tw/util';

const today = dayjs();

// Assumes that terms are in start date asc order
export const findFirstActiveTerm = (terms: Term[]) =>
  terms.find(
    (term) =>
      (term.startDate === null || today.isSameOrAfter(term.startDate)) &&
      (term.endDate === null || today.isSameOrBefore(term.endDate)),
  );

// Assumes that terms are in start date asc order
export const findFirstFutureTerm = (terms: Term[]) =>
  terms.find((term) => today.isBefore(term.startDate));

// Assumes that terms are in start date asc order, and that there are no current / future terms
export const findLatestPastTerm = (terms: Term[]) =>
  terms.reduce<Term | null>(
    (latestTerm, curr) =>
      !latestTerm || dayjs(latestTerm.endDate).isBefore(curr.endDate) ? curr : latestTerm,
    null,
  );

// Assumes that terms are fetched in start date asc order
// i.e. the first term in the list started first, and the last term started last
export const selectDefaultTerm = (terms: Term[], useGlobalId?: boolean) => {
  if (terms.length === 0) {
    return [];
  }

  const activeTerm = findFirstActiveTerm(terms);

  // if we have any active terms, return the active term that's been in session the longest
  if (activeTerm) {
    return castArray(useGlobalId ? activeTerm.id : Number(activeTerm.termId));
  }

  const futureTerm = findFirstFutureTerm(terms);

  // if we have any future terms, return the term that will start next
  if (futureTerm) {
    return castArray(useGlobalId ? futureTerm.id : Number(futureTerm.termId));
  }

  const latestTerm = findLatestPastTerm(terms);

  return castArray(useGlobalId ? latestTerm?.id : Number(latestTerm?.termId));
};
