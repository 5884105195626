import { ColProps } from 'antd/lib/col';
import _ from 'lodash';

import { Col } from 'antd';

const TWColumnFlex = (props: ColProps & { showSeparator?: boolean }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { style = null, showSeparator, ...allUnrecognizedProps } = props;

  const flexStyle = _.defaultsDeep({}, style, { flex: 1 });
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Col style={flexStyle} {...allUnrecognizedProps} />;
};

export default TWColumnFlex;
