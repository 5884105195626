import { useCallback } from 'react';
import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import {
  SubjectPaginatedRawData,
  SubjectQueryVariables,
  SubjectRawQueryVariables,
} from '@tw/types';
import { subjectModel } from '@tw/services/academicService';

import FETCH_SUBJECTS from './FetchSubjects.graphql';

export const useSubjects = (
  variables?: SubjectQueryVariables,
  options?: QueryHookOptions<SubjectPaginatedRawData, SubjectRawQueryVariables>,
) => {
  const { data, ...rest } = useQuery<SubjectPaginatedRawData, SubjectRawQueryVariables>(
    FETCH_SUBJECTS,
    {
      variables: subjectModel.normalizeSubjectVariables(variables),
      ...options,
    },
  );

  return {
    data: subjectModel.normalizePaginatedSubjectData(data),
    ...rest,
  };
};

export const useSubjectsLazy = (
  variables?: SubjectQueryVariables,
  options?: LazyQueryHookOptions,
) => {
  const [fetchSubjectsRaw, { data, ...rest }] = useLazyQuery(FETCH_SUBJECTS, {
    variables,
    ...options,
  });

  const fetchSubjects = useCallback(
    (
      fetchVariables?: SubjectQueryVariables,
      fetchOptions?: LazyQueryHookOptions<SubjectPaginatedRawData, SubjectRawQueryVariables>,
    ) =>
      fetchSubjectsRaw({
        ...fetchOptions,
        variables: subjectModel.normalizeSubjectVariables(fetchVariables),
      }),
    [fetchSubjectsRaw],
  );

  return {
    ...rest,
    fetchSubjects,
    data: subjectModel.normalizePaginatedSubjectData(data),
  };
};
