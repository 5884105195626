/* eslint-disable react/jsx-props-no-spreading */
import { PropsWithChildren } from 'react';
import { ToggleButton } from '../RichTextEditor.styles';

type ButtonProps = {
  active: boolean;
  accessibilityLabel: string;
  [key: string]: unknown;
};

const Button = ({
  active,
  accessibilityLabel,
  children,
  ...rest
}: PropsWithChildren<ButtonProps>) => (
  <ToggleButton active={active} aria-label={accessibilityLabel} type="button" {...rest}>
    {children}
  </ToggleButton>
);

export default Button;
