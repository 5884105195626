import { PersonNode } from '@tw/generated';

import { TWAvatar, TWFlexContainer, TWSpacingContainer } from '../../../presentational';
import TWIcon from '../../TWIcon';
import renderProfileItem from './renderProfileItem';
import renderStringList from './renderStringList';

const renderProfileList = (_value: unknown, record: { studentList: PersonNode[] }) => {
  const { pictureUrl, fullName, initials } = record.studentList[0] ?? {};

  if (record.studentList.length === 0) {
    return null;
  }

  if (record.studentList.length === 1) {
    return renderProfileItem({
      picture: pictureUrl ?? undefined,
      fullName: fullName ?? '',
      initials: initials ?? '',
    });
  }

  const studentListString = record.studentList
    .map((studentItem) => studentItem.fullName)
    .join(', ');

  return (
    <TWFlexContainer row align="center" data-testid="Avatar:Container">
      <TWSpacingContainer twMarginRight={1.5}>
        <TWAvatar icon={<TWIcon type="tw-group" />} />
      </TWSpacingContainer>
      {renderStringList('TWRouterPage__tutoring')(studentListString)}
    </TWFlexContainer>
  );
};

export default renderProfileList;
