/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useRef, useState } from 'react';
import isHotkey from 'is-hotkey';
import { createEditor, Descendant } from 'slate';
import { ReactEditor, Slate, withReact } from 'slate-react';

import { TWSpacingContainer } from '@tw/components';
import { useTranslator } from '@tw/i18n';

import { Image, Link } from './components';
import { EditorProps, ElementProps, LeafProps } from './RichTextEditor.definitions';
import Toolbar from './Toolbar';
import { withImages, withLinks } from './plugins';
import { deserializeText, serializeHTML } from './utils/html';
import { HOTKEYS } from './utils/hotkeys';
import { sizeMap, fontFamilyMap, toggleMark } from './utils/slate';

import { EditableArea, RichTextWrapper } from './RichTextEditor.styles';

const Element = (props: ElementProps) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'alignLeft':
      return (
        <div style={{ textAlign: 'left', listStylePosition: 'inside' }} {...attributes}>
          {children}
        </div>
      );
    case 'alignCenter':
      return (
        <div style={{ textAlign: 'center', listStylePosition: 'inside' }} {...attributes}>
          {children}
        </div>
      );
    case 'alignRight':
      return (
        <div style={{ textAlign: 'right', listStylePosition: 'inside' }} {...attributes}>
          {children}
        </div>
      );
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'orderedList':
      return (
        <ol type="1" {...attributes}>
          {children}
        </ol>
      );
    case 'unorderedList':
      return <ul {...attributes}>{children}</ul>;
    case 'link':
      return <Link {...props} />;
    case 'image':
      return <Image {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: LeafProps) => {
  let result = children;
  if (leaf.bold) {
    result = <strong>{result}</strong>;
  }

  if (leaf.italic) {
    result = <em>{result}</em>;
  }
  if (leaf.strikethrough) {
    result = <span style={{ textDecoration: 'line-through' }}>{result}</span>;
  }
  if (leaf.underline) {
    result = <u>{result}</u>;
  }
  if (leaf.superscript) {
    result = <sup>{result}</sup>;
  }
  if (leaf.subscript) {
    result = <sub>{result}</sub>;
  }
  if (leaf.color) {
    result = <span style={{ color: leaf.color }}>{result}</span>;
  }
  if (leaf.backgroundColor) {
    result = <span style={{ backgroundColor: leaf.backgroundColor }}>{result}</span>;
  }
  if (leaf.fontSize) {
    const size = sizeMap[leaf.fontSize];
    result = <span style={{ fontSize: size }}>{result}</span>;
  }
  if (leaf.fontFamily) {
    const family = fontFamilyMap[leaf.fontFamily];
    result = <span style={{ fontFamily: family }}>{result}</span>;
  }
  return <span {...attributes}>{result}</span>;
};

const RichTextEditor = ({ onChange, value }: EditorProps) => {
  const [editor] = useState<ReactEditor>(() =>
    withImages(withLinks(withReact(createEditor() as ReactEditor))),
  );
  const translator = useTranslator();
  const initialValueWasLoaded = useRef(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [deserializedValue, setDeserializedValue] = useState<Descendant[]>(
    value
      ? deserializeText(value)
      : [
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ],
  );

  useEffect(() => {
    if (value && !initialValueWasLoaded.current && !hasChanges) {
      const newValue = deserializeText(value);
      setDeserializedValue(newValue);
      editor.children = newValue;
      editor.onChange();
      initialValueWasLoaded.current = true;
    }
  }, [editor, hasChanges, initialValueWasLoaded, value]);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const handleChange = useCallback(
    (newValue) => {
      setHasChanges(true);
      setDeserializedValue(newValue);
      const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
      if (isAstChange) {
        onChange(serializeHTML(editor));
      }
    },
    [editor, onChange],
  );

  const handleKeyDown = useCallback(
    (e) => {
      Object.keys(HOTKEYS).forEach((hotkey) => {
        if (isHotkey(hotkey, e)) {
          e.preventDefault();
          const mark = HOTKEYS[hotkey];
          toggleMark(editor, mark);
        }
      });
    },
    [editor],
  );

  return (
    <RichTextWrapper>
      <TWSpacingContainer>
        <Slate editor={editor} value={deserializedValue} onChange={handleChange}>
          <Toolbar />
          <EditableArea
            aria-label={translator.t('textEditor.textEditor')}
            placeholder="..."
            data-testid="RichTextEditor:EditableArea"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            onKeyDown={handleKeyDown}
          />
        </Slate>
      </TWSpacingContainer>
    </RichTextWrapper>
  );
};

export default RichTextEditor;
