import {
  ApolloQueryResult,
  LazyQueryExecFunction,
  OperationVariables,
  useLazyQuery,
  useQuery,
} from '@apollo/client';

import { graphqlUtils } from '@tw/util';
import { GraphQLPaginatedResponse, Grade } from '@tw/types';

// eslint-disable-next-line import/no-named-default
import { default as FETCH_GRADES } from './FetchGrades.graphql';

export interface GradesQueryData {
  gradesDefault: GraphQLPaginatedResponse<Grade>;
}

interface GradesQueryResult extends Omit<ApolloQueryResult<GradesQueryData>, 'data'> {
  grades: Grade[];
}

interface GradesLazyQueryResult extends GradesQueryResult {
  fetchGrades: LazyQueryExecFunction<GradesQueryData, OperationVariables>;
}

export const useGrades = (): GradesQueryResult => {
  const result = useQuery<GradesQueryData>(FETCH_GRADES);
  const { data, ...rest } = result;
  return {
    grades: data ? graphqlUtils.convertEdgesToArray(data?.gradesDefault.edges) : [],
    ...rest,
  };
};

export const useGradesLazy = (): GradesLazyQueryResult => {
  const [fetchGrades, result] = useLazyQuery<GradesQueryData>(FETCH_GRADES);
  const { data, ...rest } = result;
  return {
    fetchGrades,
    grades: data ? graphqlUtils.convertEdgesToArray(data?.gradesDefault.edges) : [],
    ...rest,
  };
};
