/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateApptTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.AppointmentTypeInput>;
}>;

export type UpdateApptTypeMutation = {
  appointmentTypeUpdate: { appointmentType?: { pk?: string | null } | null };
};

export const UpdateApptTypeDocument = gql`
  mutation UpdateApptType($id: ID!, $input: AppointmentTypeInput) {
    appointmentTypeUpdate(id: $id, input: $input) {
      appointmentType {
        pk
      }
    }
  }
`;
export type UpdateApptTypeMutationFn = Apollo.MutationFunction<
  UpdateApptTypeMutation,
  UpdateApptTypeMutationVariables
>;

/**
 * __useUpdateApptTypeMutation__
 *
 * To run a mutation, you first call `useUpdateApptTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApptTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApptTypeMutation, { data, loading, error }] = useUpdateApptTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApptTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateApptTypeMutation, UpdateApptTypeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateApptTypeMutation, UpdateApptTypeMutationVariables>(
    UpdateApptTypeDocument,
    options,
  );
}
export type UpdateApptTypeMutationHookResult = ReturnType<typeof useUpdateApptTypeMutation>;
export type UpdateApptTypeMutationResult = Apollo.MutationResult<UpdateApptTypeMutation>;
export type UpdateApptTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateApptTypeMutation,
  UpdateApptTypeMutationVariables
>;
