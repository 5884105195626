import { useMutation, ApolloError } from '@apollo/client';
import { Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';

import { getTranslation } from '@tw/i18n';
import { composeFormRules } from '@tw/util';
import { useReCaptchaMutation } from '@tw/hooks';
import {
  TWFlexContainer,
  TWSpacingContainer,
  TWTextDefault,
  TWButton,
  TWFormItem,
  TWForm,
  TWMessage,
  TWLoadingSpinner,
} from '@tw/components';
import { FormAssignmentNode } from 'twGenerated';

import { FormHeading } from '../../FormHeading';
import { EXTERNAL_FORM_COMPLETION_CREATE } from '../graphql';

export const ShareableLinkForm = ({ formAssignment }: { formAssignment: FormAssignmentNode }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [createExternalFormCompletion, { loading }] = useMutation(EXTERNAL_FORM_COMPLETION_CREATE, {
    onCompleted: (data) => {
      const completionUUID = data.externalCompletionCreate.link;
      if (completionUUID) {
        // TODO: We should setup the <FormCompletion> component to optionally
        // take a completion role and avoid redirecing here
        history.replace(`${completionUUID}`);
      } else {
        const error = new ApolloError({});
        TWMessage.errorHandler(error);
      }
    },
    onError: (error) => {
      TWMessage.errorHandler(error);
    },
  });

  const createExternalFormCompletionWithRecapcha = useReCaptchaMutation(
    createExternalFormCompletion,
    'shareableLinkExternalUserSubmit',
  );

  const uuid = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

  const handleCompleteForm = (values: { name: string; email: string }) => {
    createExternalFormCompletionWithRecapcha({ variables: { input: { uuid, ...values } } });
  };

  const containerId = 'shareableLinkForm';
  return (
    <TWFlexContainer id={containerId}>
      <TWSpacingContainer twPadding={3} customStyles={{ borderBottom: '1px solid gray' }}>
        <TWFlexContainer row>
          <FormHeading assignment={formAssignment} />
        </TWFlexContainer>
      </TWSpacingContainer>

      <TWSpacingContainer twPadding={3}>
        <TWFlexContainer row twPaddingBottom={3}>
          <TWTextDefault>{getTranslation('forms.shareableLink.prompt')}</TWTextDefault>
        </TWFlexContainer>
        <TWForm
          id="AddEditFilterForm"
          name="AddEditFilterForm"
          form={form}
          preserve={false}
          onFinish={handleCompleteForm}
        >
          <TWFlexContainer row>
            <TWFormItem
              name="name"
              validateTrigger="onBlur"
              label={getTranslation('name')}
              rules={composeFormRules('required')}
              testID="Forms:ShareableLinkForm:Name"
            >
              <Input autoFocus width={200} aria-label={getTranslation('name')} />
            </TWFormItem>
          </TWFlexContainer>

          <TWFlexContainer row twPaddingBottom={3}>
            <TWFormItem
              name="email"
              validateTrigger="onBlur"
              label={getTranslation('email')}
              rules={composeFormRules('email', 'required')}
              testID="Forms:ShareableLinkForm:Email"
            >
              <Input width={200} aria-label={getTranslation('name')} />
            </TWFormItem>
          </TWFlexContainer>

          <TWFlexContainer row>
            {!loading ? (
              <TWButton
                type="primary"
                onClick={() => form.submit()}
                testID="Forms:ShareableLinkForm:Submit"
              >
                {getTranslation('continue')}
              </TWButton>
            ) : (
              <TWLoadingSpinner size="small" />
            )}
          </TWFlexContainer>
        </TWForm>
      </TWSpacingContainer>
    </TWFlexContainer>
  );
};
