export const getAttachmentType = (fileName: string) => {
  const attachmentExt = fileName.split('.').pop();
  switch (attachmentExt?.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return 'image';
    case 'pdf':
      return 'pdf';
    case 'doc':
    case 'docx':
      return 'word';
    case 'ppt':
    case 'pptx':
      return 'powerpoint';
    case 'xls':
    case 'xlsx':
    case 'csv':
      return 'spreadsheet';
    case 'mp3':
      return 'music';
    case 'mp4':
    case 'mov':
      return 'video';
    default:
      return 'file';
  }
};

export const SUPPORTED_FILES =
  '.pdf,.mp4,.jpg,.jpeg,.png,.gif,.mpeg,.bmp,.csv,.oga,.ogv,.ogx,.3gp,.weba,.webm,.tiff,.rtf,.ics,.mp3,.vcf,.xls,.xlsx,.xlsm,.xlsb,.xltx,.xml,.doc,.docx,.dot,.htm,.html,.txt,.rtf,.mov';

export const SUPPORTED_EXT = SUPPORTED_FILES.split(',');
