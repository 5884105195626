/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveApptTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.AppointmentTypeInput>;
}>;

export type ArchiveApptTypeMutation = {
  appointmentTypeUpdate: { appointmentType?: { pk?: string | null; label: string } | null };
};

export const ArchiveApptTypeDocument = gql`
  mutation ArchiveApptType($id: ID!, $input: AppointmentTypeInput) {
    appointmentTypeUpdate(id: $id, input: $input) {
      appointmentType {
        pk
        label
      }
    }
  }
`;
export type ArchiveApptTypeMutationFn = Apollo.MutationFunction<
  ArchiveApptTypeMutation,
  ArchiveApptTypeMutationVariables
>;

/**
 * __useArchiveApptTypeMutation__
 *
 * To run a mutation, you first call `useArchiveApptTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveApptTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveApptTypeMutation, { data, loading, error }] = useArchiveApptTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveApptTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveApptTypeMutation,
    ArchiveApptTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveApptTypeMutation, ArchiveApptTypeMutationVariables>(
    ArchiveApptTypeDocument,
    options,
  );
}
export type ArchiveApptTypeMutationHookResult = ReturnType<typeof useArchiveApptTypeMutation>;
export type ArchiveApptTypeMutationResult = Apollo.MutationResult<ArchiveApptTypeMutation>;
export type ArchiveApptTypeMutationOptions = Apollo.BaseMutationOptions<
  ArchiveApptTypeMutation,
  ArchiveApptTypeMutationVariables
>;
