import * as React from 'react';
import { ApolloError } from '@apollo/client';

import { UserSelectionOptionsQuery } from '@tw/generated';
import { Container, StyledTWErrorAlertDeprecated } from './TWUserSelectionFullSingle.styles';
import { UserSelectionFullBase } from '../@components';
import { SingleSelectManagementInterface } from './useSingleSelect';

interface TWUserSelectionFullSingleProps {
  setTeamFilter: (teamId: number) => void;
  teamFilter: number;
  loading: boolean;
  individualsOnly: boolean;
  data?: UserSelectionOptionsQuery;
  error?: ApolloError;
  canSwitchTeams?: boolean;
}

const TWUserSelectionFullSingle: React.FC<
  TWUserSelectionFullSingleProps & SingleSelectManagementInterface
> = ({ selectedItem, error, canSwitchTeams, ...restOfProps }) => (
  <>
    <StyledTWErrorAlertDeprecated apolloError={error} data-testid="UserSelection:ErrorMessage" />
    <Container id="TWUserSelectionFullSingle">
      <UserSelectionFullBase
        selectionItemsByCode={
          selectedItem
            ? {
                [selectedItem.selectionCode]: selectedItem,
              }
            : {}
        }
        canSwitchTeams={canSwitchTeams}
        {...restOfProps}
      />
    </Container>
  </>
);

export default TWUserSelectionFullSingle;
