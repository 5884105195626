import localStorage from '@tw/services/localStorage';
import { backendUtils } from '@tw/util';

export const useDownloader = () => {
  const currentUser = localStorage.getCurrentUser();

  const streamFile = (url: string, body?: object, headers?: object) => {
    let combinedHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Cache-Control': 'max-age=3', // cache for up to 3 seconds
      'X-TW-PersonId': String(currentUser?.personId),
      'X-TW-TeamId': String(currentUser?.teamId),
      Authorization: `Bearer ${localStorage.getAccessToken()}`,
    };

    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    const requestInit: RequestInit = {
      mode: 'cors' as RequestMode,
      headers: combinedHeaders,
    };

    if (body) {
      requestInit.body = JSON.stringify(body);
      requestInit.method = 'POST';
    } else {
      requestInit.method = 'GET';
    }

    return fetch(`${backendUtils.API_BASE_URL}${url}`, requestInit).then(async (response) => {
      if (response.ok) {
        const reader = response.body?.getReader();
        const chunks = [];

        // eslint-disable-next-line no-constant-condition
        while (true) {
          // eslint-disable-next-line no-await-in-loop
          const result = await reader?.read();
          if (!result || result?.done) {
            break;
          }
          chunks.push(result.value);
        }
        return new Blob(chunks);
      }
      throw new Error('Error during fetching data.');
    });
  };

  const downloadFile = (
    data: string | ArrayBuffer | ArrayBufferView | Blob,
    filename: string,
    mime?: string,
    bom?: string | Uint8Array,
  ) => {
    const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  };

  return { streamFile, downloadFile };
};
