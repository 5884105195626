import { useContext } from 'react';

import { ViewerContext } from './viewer';
import { ViewerData } from './viewerData.definitions';
import { ViewContextType } from './viewer.definitions';

export type UseViewerType = NonNullable<ViewContextType<ViewerData>>;
export const useViewer = (): UseViewerType => {
  const ctx = useContext(ViewerContext);

  if (!ctx) {
    throw Error('useViewer() must be used inside of a ViewerProvider');
  }
  return ctx as unknown as UseViewerType;
};

export default useViewer;
