import { gql } from '@apollo/client';

export const FetchCourseSections = gql`
  query FetchCourseSections(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $courseId: TWFilter_Int
    $courseSectionId: TWFilter_ID
    $courseSectionSchedulesDay: TWFilter_DaysOfWeekEnum
    $courseSubjectId: TWFilter_Int
    $enableNameSort: Boolean
    $endDate: TWFilter_String
    $searchCourseSections: String
    $startDate: TWFilter_String
    $studentIds: [Int]
    $teamIds: [Int]
    $termId: TWFilter_Int
    $sort: [CourseSectionSortEnum]
  ) {
    courseSections(
      after: $after
      before: $before
      first: $first
      last: $last
      courseId: $courseId
      courseSectionId: $courseSectionId
      courseSectionSchedulesDay: $courseSectionSchedulesDay
      courseSubjectId: $courseSubjectId
      enableNameSort: $enableNameSort
      endDate: $endDate
      searchCourseSections: $searchCourseSections
      startDate: $startDate
      studentIds: $studentIds
      teamIds: $teamIds
      termId: $termId
      sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          courseSectionId: pk
          courseId
          term {
            id
            termId: pk
            startDate
            endDate
            label
          }
          enrollments {
            totalCount
            edges {
              node {
                id
                droppedDate
                creditsAttempted
                studentTermId
                studentTerm {
                  id
                  studentTermId
                  studentId
                  student {
                    id
                    personId
                    isDeleted
                    fullName
                    lastName
                    preferredName
                    teamIds
                  }
                }
              }
            }
          }
          courseSectionSchedules {
            edges {
              node {
                id
                courseSectionScheduleId
                startTime
                endTime
                day
                location
              }
            }
          }
          professor {
            id
            professorId
            firstName
            lastName
            fullName
            emailAddress
            phoneNumber
          }
          course {
            courseId: pk
            id
            credits
            label
            subject {
              id
              subjectId: pk
              label
              description
              shortCode
              customerKey
            }
            description
          }
          customerKey
          isOnline
          isDeleted
          shortCode
          startDate
          endDate
        }
      }
    }
  }
`;
