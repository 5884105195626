import { Dropdown } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { ReactNode } from 'react';

import { TWButton, TWFlexContainer } from '@tw/components';
import { DOMUtils } from '@tw/util';

import { TWButtonIconFlat } from '../../../presentational/buttons';
import TWIcon from '../../TWIcon';
import { DropdownWrapper, Icon } from './renderActionMenu.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionItem<T = any> = {
  label: string;
  action: (record: T, index: number) => void;
  shouldDisplay?: ((record: T, index: number) => boolean) | boolean;
  icon?: string | null;
  showAsButton?: boolean;
  danger?: boolean;
  testID?: string;
};

const willDisplayItem = <T,>(actionItem: ActionItem, record: T, index: number) =>
  actionItem &&
  (actionItem.shouldDisplay === undefined ||
    actionItem.shouldDisplay === null ||
    (typeof actionItem.shouldDisplay === 'boolean' && actionItem.shouldDisplay) ||
    (typeof actionItem.shouldDisplay === 'function' && actionItem.shouldDisplay(record, index)));

const renderActionMenu =
  <T,>(actionList: ActionItem[], containerId: string, button?: ReactNode) =>
  (_value: unknown, record: T, index: number) => {
    const displayableActions = actionList.filter((item) => willDisplayItem(item, record, index));

    // None of the action items should be displayed so don't render the dropdown menu
    if (displayableActions.length === 0) {
      return null;
    }

    const menuItems = () => {
      const items = displayableActions.map<MenuItemType>((actionItem: ActionItem) => {
        const isMaterialIcon = actionItem.icon?.startsWith('material-');
        const icon = isMaterialIcon ? actionItem.icon?.replace('material-', '') : actionItem.icon;

        return {
          key: actionItem.label,
          'data-testid': `ActionMenu:Item:${actionItem.testID ?? actionItem.icon}`,
          onClick: ({ domEvent: event }) => {
            actionItem.action(record, index);
            event.stopPropagation();
          },
          danger: actionItem.danger,
          label: (
            <TWFlexContainer twMargin={[0.5, 0]} row align="center">
              {isMaterialIcon && icon && (
                <Icon
                  className="material-icons"
                  data-testid={`ActionMenu:Item:${actionItem.testID ?? icon}:Icon`}
                >
                  {icon}
                </Icon>
              )}
              {!isMaterialIcon && icon && (
                <TWIcon
                  testID={`ActionMenu:Item:${actionItem.testID ?? actionItem.icon}:Icon`}
                  type={icon}
                  width={20}
                  height={20}
                />
              )}

              <div data-testid={`ActionMenu:Item:${actionItem.testID ?? icon}:Text`}>
                {actionItem.label}
              </div>
            </TWFlexContainer>
          ),
        };
      });
      return items;
    };

    if (displayableActions.length === 1 && displayableActions[0].showAsButton) {
      const { label, action, icon } = displayableActions[0];
      return (
        <DropdownWrapper>
          <TWButton
            onClick={() => {
              action(record, index);
            }}
            testID="ActionMenu:Button"
          >
            {icon && <TWIcon type={icon} />}
            {label}
          </TWButton>
        </DropdownWrapper>
      );
    }

    return (
      <DropdownWrapper>
        <Dropdown
          menu={{ items: menuItems() }}
          trigger={['click']}
          placement="bottomRight"
          getPopupContainer={() => DOMUtils.getContainer(containerId)}
        >
          <div
            // Need to ensure onClick actions don't propagate to the parent container so onRowClick actions are prevented
            // This way it just opens its drop down as expected, but does *only* that
            role="presentation"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {button || (
              <TWButtonIconFlat accessibilityLabel="action-menu-open" testID="ActionMenu:Open">
                <TWIcon type="material-more_vert" />
              </TWButtonIconFlat>
            )}
          </div>
        </Dropdown>
      </DropdownWrapper>
    );
  };

export default renderActionMenu;
