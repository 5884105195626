/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateMessageSendCodesQueryVariables = Types.Exact<{
  selectionCodes?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>
  >;
}>;

export type ValidateMessageSendCodesQuery = {
  validatedCodes?: {
    validCodes?: Array<
      | { selectionCode?: string | null }
      | { selectionCode?: string | null }
      | { selectionCode?: string | null }
      | { selectionCode?: string | null }
      | null
    > | null;
    invalidCodes?: Array<
      | { selectionCode?: string | null }
      | { selectionCode?: string | null }
      | { selectionCode?: string | null }
      | { selectionCode?: string | null }
      | null
    > | null;
  } | null;
};

export const ValidateMessageSendCodesDocument = gql`
  query ValidateMessageSendCodes($selectionCodes: [String]) {
    validatedCodes(selectionCodes: $selectionCodes, permissionCode: MESSAGING_SEND) {
      validCodes {
        selectionCode
      }
      invalidCodes {
        selectionCode
      }
    }
  }
`;

/**
 * __useValidateMessageSendCodesQuery__
 *
 * To run a query within a React component, call `useValidateMessageSendCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateMessageSendCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateMessageSendCodesQuery({
 *   variables: {
 *      selectionCodes: // value for 'selectionCodes'
 *   },
 * });
 */
export function useValidateMessageSendCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ValidateMessageSendCodesQuery,
    ValidateMessageSendCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateMessageSendCodesQuery, ValidateMessageSendCodesQueryVariables>(
    ValidateMessageSendCodesDocument,
    options,
  );
}
export function useValidateMessageSendCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateMessageSendCodesQuery,
    ValidateMessageSendCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateMessageSendCodesQuery, ValidateMessageSendCodesQueryVariables>(
    ValidateMessageSendCodesDocument,
    options,
  );
}
export type ValidateMessageSendCodesQueryHookResult = ReturnType<
  typeof useValidateMessageSendCodesQuery
>;
export type ValidateMessageSendCodesLazyQueryHookResult = ReturnType<
  typeof useValidateMessageSendCodesLazyQuery
>;
export type ValidateMessageSendCodesQueryResult = Apollo.QueryResult<
  ValidateMessageSendCodesQuery,
  ValidateMessageSendCodesQueryVariables
>;
