import { useTeams } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { Team } from '@tw/types';

import TWInputSelect from '../TWInputSelect';
import { TWInputSelectTeamProps } from './TWInputSelectTeam.definitions';

export const TWInputSelectTeam = (props: TWInputSelectTeamProps) => {
  const {
    placeholder = getTranslation('allTeams'),
    popupContainerId = '',
    filters = {},
    ...allUnrecognizedProps
  } = props;

  const { data, loading, error } = useTeams({
    variables: filters,
  });
  const { teams } = data || {};

  const keyExtractor = (team: Team) => team.teamId;

  const labelExtractor = (team: Team) => team.label;

  const valueExtractor = (team: Team) => Number(team.teamId);

  if (error) {
    return null;
  }

  return (
    <TWInputSelect
      allowClear
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      keyExtractor={keyExtractor}
      labelExtractor={labelExtractor}
      valueExtractor={valueExtractor}
      itemList={teams ?? undefined}
      popupContainerId={popupContainerId}
      minWidth="200px"
      loading={loading}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allUnrecognizedProps}
    />
  );
};
