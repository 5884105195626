/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSessionRequestNotesQueryVariables = Types.Exact<{
  sessionRequestId?: Types.InputMaybe<Types.Scalars['TWFilter_ID']>;
}>;

export type FetchSessionRequestNotesQuery = {
  sessionRequests?: {
    edges: Array<{
      node?: { id: string; sessionRequestId: string; notes?: string | null } | null;
    } | null>;
  } | null;
};

export const FetchSessionRequestNotesDocument = gql`
  query FetchSessionRequestNotes($sessionRequestId: TWFilter_ID) {
    sessionRequests(sessionRequestId: $sessionRequestId) {
      edges {
        node {
          id
          sessionRequestId
          notes
        }
      }
    }
  }
`;

/**
 * __useFetchSessionRequestNotesQuery__
 *
 * To run a query within a React component, call `useFetchSessionRequestNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSessionRequestNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSessionRequestNotesQuery({
 *   variables: {
 *      sessionRequestId: // value for 'sessionRequestId'
 *   },
 * });
 */
export function useFetchSessionRequestNotesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchSessionRequestNotesQuery,
    FetchSessionRequestNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSessionRequestNotesQuery, FetchSessionRequestNotesQueryVariables>(
    FetchSessionRequestNotesDocument,
    options,
  );
}
export function useFetchSessionRequestNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSessionRequestNotesQuery,
    FetchSessionRequestNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSessionRequestNotesQuery, FetchSessionRequestNotesQueryVariables>(
    FetchSessionRequestNotesDocument,
    options,
  );
}
export type FetchSessionRequestNotesQueryHookResult = ReturnType<
  typeof useFetchSessionRequestNotesQuery
>;
export type FetchSessionRequestNotesLazyQueryHookResult = ReturnType<
  typeof useFetchSessionRequestNotesLazyQuery
>;
export type FetchSessionRequestNotesQueryResult = Apollo.QueryResult<
  FetchSessionRequestNotesQuery,
  FetchSessionRequestNotesQueryVariables
>;
