import _ from 'lodash';
import { useQuery, useMutation, ApolloError, DocumentNode } from '@apollo/client';
import { TWPolyglot } from '@tw/i18n';

const getUserErrorMessage = (error: ApolloError, translator: TWPolyglot): string | null => {
  if (!error) return null;

  if (error.networkError) return translator.t('apollo.networkError');
  if (error.graphQLErrors) return translator.t('apollo.graphQLError');

  // This should never happen...
  console.error('getUserErrorMessage: unexpected error occurred.', error);
  return translator.t('apollo.unexpectedError');
};

const useMakeQuery = <T>(
  query: DocumentNode,
  filters: object,
  additionalArgs?: object,
  converterFn?: (arg0: object) => T,
) => {
  const { data, loading, error, refetch } = useQuery(query, {
    variables: { ...filters },
    ...additionalArgs,
  });

  return {
    data: converterFn ? converterFn(data) : data,
    loading,
    error,
    refetch,
  };
};

const useMakeMutation = (query: DocumentNode, variables: object, additionalOptions?: object) => {
  const [mutation, { loading, error }] = useMutation(query, {
    variables,
    errorPolicy: 'all',
    ...additionalOptions,
  });

  return {
    mutation,
    loading,
    error,
  };
};

// Cast undefined to null (for fields that were cleared)
const normalizeInput = (input) =>
  _.transform(
    input,
    (result, value, key: string) => {
      // Remove any typing fields supplied by Apollo
      if (key === '__typename') {
        return result;
      }

      let newValue;
      if (_.isPlainObject(value)) {
        newValue = normalizeInput(value);
      } else if (_.isArray(value) && _.isPlainObject(_.first(value))) {
        newValue = value.map(normalizeInput);
      } else if (value === undefined) {
        newValue = null;
      } else {
        newValue = value;
      }

      // eslint-disable-next-line no-param-reassign
      result[key] = newValue;

      return result;
    },
    {},
  );

export default { getUserErrorMessage, useMakeQuery, useMakeMutation, normalizeInput };
