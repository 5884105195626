const $twButton = {
  minWidth: 125,
  borderRadius: 4,
  paddingTop: 8,
  paddingBottom: 8,
  paddingRight: 25,
  paddingLeft: 25,
  marginBottom: 15,
  marginLeft: 15,
  float: 'right',
  fontSize: 14,
};

export { $twButton };
