import styled from '@emotion/styled';

const Container = styled.div<{ withPadding?: boolean; disableRowClick?: boolean }>(
  ({ theme, withPadding, disableRowClick }) => {
    const paddingStyling = withPadding
      ? {
          // padding for the first and last columns
          '.ant-table-middle  .ant-table-content tbody > tr > td:first-of-type': {
            paddingLeft: '24px',
          },

          '.ant-table-middle  .ant-table-content thead > tr > th:first-of-type': {
            paddingLeft: '24px',
            '> .ant-table-column-sorters': {
              padding: '12px 7px',
              '> .ant-table-column-sorter': {
                marginLeft: '0px',
              },
            },
          },

          '.ant-table-middle  .ant-table-content tbody > tr > td:last-of-type': {
            paddingRight: '24px',
          },

          '.ant-table-middle  .ant-table-content thead > tr > th:last-of-type': {
            paddingRight: '24px',
            '> .ant-table-column-sorters': {
              padding: '12px 7px',
              '> .ant-table-column-sorter': {
                marginLeft: '0px',
              },
            },
          },
        }
      : {};

    const disabledRowClick = disableRowClick && {
      '& .ant-table-row:hover': {
        cursor: 'default',
        '& > .ant-table-cell': {
          background: theme.colors.white,
        },
      },
    };

    return {
      fontSize: theme.fontUnit * 3,
      backgroundColor: theme.colors.white,
      width: '100%',
      '& .twDataTable__row:hover': {
        cursor: 'pointer',
      },
      '& .twDataTable__disabled__row': {
        backgroundColor: theme.colors.gray,
      },
      '& .twDataTable__disabled__row:hover': {
        backgroundColor: theme.colors.gray,
      },
      '& .twDataTable__disabled__row td.ant-table-column-sort': {
        background: theme.colors.gray,
      },
      '.ant-table': {
        fontSize: theme.fontUnit * 3.5,
      },
      '& .ant-table-content': {
        overflowX: 'auto',
      },
      '.ant-table-middle > .ant-table-content > .ant-table-body': {
        '> table': {
          '> .ant-table-thead > tr > th, > .ant-table-tbody > tr > td': {
            '&:first-of-type': {
              paddingLeft: theme.baseUnit * 2,
            },
            '&:last-of-type': {
              paddingRight: theme.baseUnit * 2,
            },
          },
          '> .ant-table-thead > tr > th': {
            whiteSpace: 'nowrap',
            color: theme.colors.secondary,
            padding: theme.baseUnit,
            lineHeight: 1,
          },
        },
      },

      // remove highlighting for sortable rows
      '.ant-table thead th.ant-table-column-sort': {
        background: '#FAFBFC',
      },
      'td.ant-table-column-sort': {
        background: '#FFF',
      },
      ...paddingStyling,
      ...disabledRowClick,
    };
  },
);

export { Container };
