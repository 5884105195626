import {
  CheckIcon,
  MdCancel,
  MdClose,
  MdFormatLineSpacing,
  MdMoreVert,
  MdKeyboardArrowDown,
  MdPerson,
  MdSearch,
} from '@teamworksdev/icons';
import {
  Avatar,
  Button,
  Command,
  CommandInput,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  IconButton,
  Pill,
  TextField,
  TextFieldElementLeft,
  TextFieldElementRight,
  TextFieldGroup,
} from '@teamworksdev/react';
import { TWSpacingContainer } from '@tw/components';
import { getTranslation } from '@tw/i18n';

import type { IndividualOrGroup, UserGroup, UserSelectionQueryFilters } from './types';
import useUserSelect from './hooks/useUserSelect';
import {
  DialogButton,
  DialogSelectedItems,
  EmptyStateContainer,
  EmptyStateDesc,
  EmptyStateIcon,
  EmptyStateTitle,
  GridContainer,
  FilterLabel,
  FiltersContainer,
  FilterWrapper,
  FlexContainer,
  InputClear,
  InputContainer,
  ItemLabel,
  Panel,
  PanelsWrapper,
  PillContainer,
  PopoverContainer,
  PopoverSelectedItems,
  SelectionsContainer,
  TeamSelect,
  TeamSelectContent,
  TeamSelectFlexContainer,
  UserDialogBody,
} from './UserSelect.styles';

import UserPopover from './components/UserPopover';
import UserTreeSelect from './components/UserTreeSelect';
import { UserSelectContext } from './UserSelectContext';

export interface UserSelectProps {
  teams?: { label: string; teamId: string }[];
  data: UserGroup[];
  selections: IndividualOrGroup[];
  currentTeam?: { label: string; teamId: string };
  setCurrentTeam?: (team: { label: string; teamId: string }) => void;
  onChange: (value: IndividualOrGroup[]) => void;
  containerNode: HTMLElement;
  selectionsMaxHeight?: number;
  selectionsMaxWidth?: number;
  isMulti?: boolean;
  accessibilityLabel?: string;
  alwaysSelected?: string[];
  disabled?: boolean;
  showAvatars?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  hideSelections?: boolean;
  queryFilters?: Record<string, UserSelectionQueryFilters>;
  testID?: string;
  resetAfterSelection?: boolean;
  usersOnly?: boolean;
  canSwitchTeams?: boolean;
}

const UserSelect = (props: UserSelectProps) => {
  const { containerNode } = props;
  const state = useUserSelect(props);
  const {
    aria: { inputProps },
    innerProps: { disabled, placeholder, teams },
    refs: { buttonRef, containerRef, dialogInputRef, inputRef, popoverRef, teamSelectRef },
    dialogOpen,
    dialogSelections,
    openDialog: setDialogOpen,
    handleSubmit,
    handleCancel,
    menuOpen,
    groups,
    selectionItemsById,
    onExplode,
    getPillCount,
    onDeselect,
    inputValue,
    onInputChange,
    onInputClick,
    clearGroupsAndInput,
    setFocus,
    currentTeam,
    setCurrentTeam,
    canSwitchTeams,
    onKeyDown,
    selectionsMaxHeight,
    selectionsMaxWidth,
  } = state;

  return (
    <UserSelectContext.Provider value={state}>
      <div ref={containerRef}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div onKeyDown={onKeyDown}>
          <Command>
            <InputContainer>
              <CommandInput
                {...inputProps}
                placeholder={getTranslation('selectUsers', 2)}
                ref={inputRef}
                value={inputValue}
                onValueChange={onInputChange}
                onClick={onInputClick}
                disabled={disabled}
              />
              <DialogButton
                ref={buttonRef}
                size="md"
                variant="ghost"
                data-testid="user-select-dialog-button"
                aria-label={getTranslation('openSelectUsersDialog')}
                icon={<MdMoreVert />}
                onClick={setDialogOpen}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') setDialogOpen();
                }}
                disabled={disabled}
                dialogOpen={dialogOpen}
              />
            </InputContainer>
          </Command>
          {menuOpen && (
            <PopoverContainer
              maxHeight={selectionsMaxHeight}
              maxWidth={selectionsMaxWidth}
              ref={popoverRef}
            >
              {groups.length > 0 ? (
                <UserPopover>
                  <UserTreeSelect />
                </UserPopover>
              ) : (
                <EmptyStateContainer>
                  <EmptyStateIcon>
                    <MdSearch size={32} />
                  </EmptyStateIcon>
                  <EmptyStateTitle>{getTranslation('noResults')}</EmptyStateTitle>
                  <EmptyStateDesc>{getTranslation('noResultsDesc')}</EmptyStateDesc>
                </EmptyStateContainer>
              )}
            </PopoverContainer>
          )}
        </div>
        {Object.values(selectionItemsById).length > 0 && (
          <>
            <TWSpacingContainer twMarginTop={1} />
            <SelectionsContainer>
              {Object.values(selectionItemsById)
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((selection, index) => (
                  <>
                    <PopoverSelectedItems key={selection.id}>
                      <Avatar size="md" name={selection.label} src={selection.pictureUrl ?? ''} />
                      <TWSpacingContainer twMarginRight={1} />
                      <ItemLabel>
                        {selection.isGroupOption
                          ? `${selection.label} (${selection.teamLabel})`
                          : selection.label}
                      </ItemLabel>
                      {selection.rightNode}
                      {selection.isGroupOption && (
                        <IconButton
                          icon={<MdFormatLineSpacing size={20} />}
                          aria-label={getTranslation('userSelection.explode')}
                          onClick={() => onExplode(selection?.uid)}
                          variant="ghost"
                        />
                      )}
                      {(selection.removable ?? !disabled) && (
                        <IconButton
                          icon={<MdCancel size={20} />}
                          aria-label={getTranslation('clearSelection', 1)}
                          onClick={() => onDeselect(selection)}
                          variant="ghost"
                        />
                      )}
                    </PopoverSelectedItems>
                    {index !== Object.values(selectionItemsById).length - 1 && (
                      <TWSpacingContainer twMarginBottom={1} />
                    )}
                  </>
                ))}
            </SelectionsContainer>
          </>
        )}
        <Dialog open={dialogOpen} onOpenChange={handleCancel}>
          <DialogContent size="md" container={containerNode}>
            <DialogHeader>
              <DialogTitle>{placeholder}</DialogTitle>
            </DialogHeader>
            <UserDialogBody>
              <GridContainer>
                <FlexContainer>
                  <FiltersContainer>
                    <FilterWrapper>
                      <FilterLabel>{getTranslation('search')}</FilterLabel>
                      <TextFieldGroup size="md">
                        <TextFieldElementLeft>
                          <MdSearch />
                        </TextFieldElementLeft>
                        <TextField
                          placeholder={getTranslation('search')}
                          value={inputValue}
                          onChange={(e) => onInputChange(e.target.value)}
                          onKeyDown={onKeyDown}
                          ref={dialogInputRef}
                        />
                        <TextFieldElementRight>
                          {inputValue && (
                            <InputClear
                              aria-label={getTranslation('clear')}
                              variant="ghost"
                              size="sm"
                              icon={<MdClose size={16} />}
                              onClick={clearGroupsAndInput}
                            />
                          )}
                        </TextFieldElementRight>
                      </TextFieldGroup>
                    </FilterWrapper>
                    {canSwitchTeams && teams && (
                      <FilterWrapper>
                        <FilterLabel>{getTranslation('teams', 1)}</FilterLabel>
                        <DropdownMenu>
                          <DropdownMenuTrigger
                            asChild
                            onKeyDown={(e) => {
                              if (e.key === 'Tab' && e.shiftKey) {
                                inputRef.current?.focus();
                                return;
                              }
                              if (e.key === 'Tab') {
                                setFocus('last');
                              }
                            }}
                          >
                            <TeamSelect tabIndex={0}>
                              <TeamSelectFlexContainer>
                                {currentTeam ? currentTeam.label : getTranslation('selectTeam')}
                                <MdKeyboardArrowDown size={16} />
                              </TeamSelectFlexContainer>
                            </TeamSelect>
                          </DropdownMenuTrigger>
                          <TeamSelectContent
                            align="start"
                            ref={teamSelectRef}
                            container={containerNode}
                          >
                            {teams.map((team) => (
                              <DropdownMenuItem
                                key={team.teamId}
                                onClick={() => setCurrentTeam(team)}
                              >
                                <TeamSelectFlexContainer>
                                  {team.label}
                                  {currentTeam.teamId === team.teamId && <CheckIcon />}
                                </TeamSelectFlexContainer>
                              </DropdownMenuItem>
                            ))}
                          </TeamSelectContent>
                        </DropdownMenu>
                      </FilterWrapper>
                    )}
                  </FiltersContainer>
                  <PanelsWrapper>
                    <Panel isEmptyState={groups.length === 0}>
                      <UserPopover>
                        <UserTreeSelect />
                      </UserPopover>
                    </Panel>
                    <Panel>
                      {Object.values(dialogSelections)
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((selection, index) => (
                          <>
                            <DialogSelectedItems key={selection.id}>
                              <Avatar
                                size="sm"
                                name={selection.label}
                                src={selection.pictureUrl ?? ''}
                              />
                              <TWSpacingContainer twMarginRight={1} />
                              <ItemLabel>
                                {selection.isGroupOption
                                  ? `${selection.label} (${selection.teamLabel})`
                                  : selection.label}
                              </ItemLabel>
                              {selection.rightNode}
                              {selection.isGroupOption && (
                                <IconButton
                                  icon={<MdFormatLineSpacing size={20} />}
                                  aria-label={getTranslation('userSelection.explode')}
                                  onClick={() => onExplode(selection?.uid)}
                                  variant="ghost"
                                />
                              )}
                              {(selection.removable ?? !disabled) && (
                                <IconButton
                                  icon={<MdCancel size={20} />}
                                  data-testid={`user-select-dialog-clear-selection-${selection.id}`}
                                  aria-label={getTranslation('clearSelection', 1)}
                                  onClick={() => onDeselect(selection)}
                                  variant="ghost"
                                />
                              )}
                            </DialogSelectedItems>
                            {index !== Object.values(dialogSelections).length - 1 && (
                              <TWSpacingContainer twMarginBottom={1} />
                            )}
                          </>
                        ))}
                      {getPillCount() > 0 && (
                        <PillContainer>
                          <Pill type="info" leftIcon={<MdPerson />} size="sm">
                            {getTranslation('xSelected', {
                              x: getPillCount(),
                              smart_count: getPillCount(),
                            })}
                          </Pill>
                        </PillContainer>
                      )}
                    </Panel>
                  </PanelsWrapper>
                </FlexContainer>
              </GridContainer>
            </UserDialogBody>
            <DialogFooter>
              <Button variant="secondary" onClick={handleCancel}>
                {getTranslation('cancel')}
              </Button>
              <Button onClick={handleSubmit} type="submit" data-testid="user-select-submit-button">
                {getTranslation('select')}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </UserSelectContext.Provider>
  );
};

export default UserSelect;
