import { Button } from '@teamworksdev/react';
import { useState } from 'react';
import _ from 'lodash';
import { Form, Input, Result } from 'antd';

import {
  TWForm,
  TWFormItem,
  TWHeading2,
  TWLoadingMask,
  TWMessage,
  TWPurifiedHtml,
  TWSpacingContainer,
  TWTextDefault,
  TWTextSmall,
} from '@tw/components';
import { useUserRegisterMutation, useUserRegistrationQuery } from '@tw/generated';
import localStorage from '@tw/services/localStorage';
import { getTranslation } from '@tw/i18n';
import styled from '@emotion/styled';
import { AuthTemplate } from '../@components';

type FormValues = {
  person: string;
  username: string;
  password: string;
  confirmPassword: string;
};

export const UserRegistration = ({ uuid }: { uuid: string }) => {
  const [form] = Form.useForm<FormValues>();

  const [isLinkingAccounts, setIsLinkingAccounts] = useState(false);
  const [accountCreationPending, setAccountCreationPending] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState<
    | {
        field: string[];
        message: string;
      }[]
    | null
  >(null);

  const { data, loading } = useUserRegistrationQuery({ variables: { uuid } });
  const username = data?.userRegistrationPerson?.username;

  const [registerUser] = useUserRegisterMutation({
    onCompleted: ({ userRegister }) => {
      const { accessToken, refreshToken, expiresAt, userErrors } = userRegister;
      if (userErrors) {
        setValidationErrorMessages(userErrors);
      } else {
        TWMessage.completedHandler(userRegister);
      }

      if (!accessToken) throw new Error('No access token returned from userRegister mutation');
      if (!refreshToken) throw new Error('No refresh token returned from userRegister mutation');
      if (!expiresAt) throw new Error('No expires at returned from userRegister mutation');

      const token = {
        accessToken,
        refreshToken,
        expiresAt,
      };

      localStorage.setBearerToken(token);
      window.location.href = '/home/overview';
    },
    onError: (args) => {
      setAccountCreationPending(false);
      TWMessage.errorHandler(args);
    },
  });

  const getFieldErrorMessage = (fieldname: string) =>
    _.get(
      _.find(validationErrorMessages, (e) => e.field.includes(fieldname)),
      'message',
    );

  const usernameErrorMessage = getFieldErrorMessage('username');

  const passwordErrorMessage = getFieldErrorMessage('password');

  if (loading) {
    return <TWLoadingMask />;
  }

  if (username) {
    return (
      <Result
        title={getTranslation('authentication.userRegistration.accountAlreadyExists', { username })}
        extra={
          <TWTextDefault>
            {' '}
            If you need help, please{' '}
            <a href="https://www.teamworks.com/support/" target="_blank" rel="noreferrer">
              contact Teamworks support.
            </a>
          </TWTextDefault>
        }
      />
    );
  }

  const person = data?.userRegistrationPerson;

  const fieldsToValidate = isLinkingAccounts
    ? ['username', 'password']
    : ['username', 'password', 'confirmPassword'];

  const handleFinish = async (values: FormValues) => {
    if (!accountCreationPending) {
      localStorage.setCurrentUser({});
      setAccountCreationPending(true);
      const result = await registerUser({ variables: { input: values } });
      if (result?.data?.userRegister?.userErrors.length) {
        setAccountCreationPending(false);
      }
    }
  };

  return (
    <AuthTemplate>
      <TWSpacingContainer twMargin={[3, 0]}>
        <TWHeading2>
          <TWPurifiedHtml
            textToRender={getTranslation('authentication.userRegistration.welcome', {
              name: person?.preferredName,
              org: person?.org?.orgName,
            })}
          />
        </TWHeading2>
      </TWSpacingContainer>

      <TWForm
        name="UserRegistrationForm"
        form={form}
        initialValues={{ person: person?.id, username: person?.emailAddress }}
        onFinish={handleFinish}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
      >
        <TWFormItem hidden noStyle name="person">
          <Input type="hidden" />
        </TWFormItem>

        <TWFormItem
          name="username"
          label={getTranslation('username')}
          initialValue={person?.emailAddress}
          validateTrigger={['onBlur', 'onChange']}
          validateStatus={usernameErrorMessage && 'error'}
          help={usernameErrorMessage}
          rules={[
            { validateTrigger: 'onChange', required: true, whitespace: true },
            { validateTrigger: 'onBlur', min: 4 },
          ]}
        >
          <Input autoFocus autoComplete="username" />
        </TWFormItem>

        {!isLinkingAccounts && (
          <TWTextSmall twColor="secondary">
            {getTranslation('authentication.userRegistration.usernameHint')}
          </TWTextSmall>
        )}

        <TWFormItem
          name="password"
          label={getTranslation('password')}
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            { validateTrigger: 'onChange', required: true, whitespace: true },
            { validateTrigger: 'onBlur', min: 8 },
          ]}
          validateStatus={passwordErrorMessage && 'error'}
          help={passwordErrorMessage}
        >
          <Input.Password autoComplete="new-password" />
        </TWFormItem>

        {!isLinkingAccounts && (
          <TWTextSmall twColor="secondary">
            {getTranslation('authentication.userRegistration.passwordHint')}
          </TWTextSmall>
        )}

        {!isLinkingAccounts && (
          <TWFormItem
            name="confirmPassword"
            label={getTranslation('authentication.confirmPassword')}
            validateTrigger={['onBlur', 'onChange']}
            rules={[
              { validateTrigger: 'onChange', required: true, whitespace: true },
              { validateTrigger: 'onBlur', min: 8 },
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </TWFormItem>
        )}

        <TWSpacingContainer twMargin={[3, 0]}>
          <TWFormItem noStyle shouldUpdate>
            {({ submit, getFieldsError, getFieldsValue }) => (
              <Button
                isLoading={accountCreationPending}
                style={{ width: '100%' }}
                onClick={submit}
                isDisabled={
                  !_.every(Object.values(getFieldsValue(fieldsToValidate))) ||
                  Boolean(getFieldsError().filter(({ errors }) => errors.length).length)
                }
              >
                {isLinkingAccounts
                  ? getTranslation('authentication.signIn')
                  : getTranslation('authentication.userRegistration.createAccount')}
              </Button>
            )}
          </TWFormItem>
        </TWSpacingContainer>
      </TWForm>
      <HrWithText>or</HrWithText>
      {!isLinkingAccounts ? (
        <TWSpacingContainer twMarginBottom={3} style={{ textAlign: 'center' }}>
          <Button
            variant="secondary"
            style={{ width: '100%' }}
            onClick={(e) => {
              e.preventDefault();
              setIsLinkingAccounts(true);
              form.setFieldsValue({ username: undefined });
            }}
          >
            {getTranslation('authentication.signIn')}
          </Button>
        </TWSpacingContainer>
      ) : (
        <TWSpacingContainer twMarginBottom={3} style={{ textAlign: 'center' }}>
          <Button
            variant="secondary"
            style={{ width: '100%' }}
            onClick={(e) => {
              e.preventDefault();
              setIsLinkingAccounts(false);
              form.setFieldsValue({ username: person?.emailAddress ?? undefined });
            }}
          >
            {getTranslation('authentication.userRegistration.createAccount')}
          </Button>
        </TWSpacingContainer>
      )}

      <TWTextDefault>
        <TWPurifiedHtml textToRender={getTranslation('authentication.userRegistration.support')} />
      </TWTextDefault>
    </AuthTemplate>
  );
};

const HrWithText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  margin-bottom: 10px;

  &::before,
  &::after {
    content: '';
    width: calc(50% - 18px);
    height: 1px;
    background-color: #ccc;
  }

  &::before {
    margin-right: 9px;
  }

  &::after {
    margin-left: 9px;
  }
`;
