import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { getTranslation } from '@tw/i18n';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect';

const dayList = _.map(moment.weekdays(), (weekday, index) => ({
  dayIndex: index,
  key: _.lowerCase(weekday),
  value: _.lowerCase(weekday),
  // This label can be overwritten by whoever is rendering this for i18n reasons.
  label: moment.weekdaysShort(index),
}));

interface TWInputSelectDayProps extends TWInputSelectProps {
  includeOnline?: boolean;
}

export const TWInputSelectDay: React.FC<TWInputSelectDayProps> = (props) => {
  const {
    includeOnline = false,
    placeholder = getTranslation('day', 2),
    ...allUnrecognizedProps
  } = props;

  const itemList = includeOnline ? [...dayList, 'online'] : dayList;

  return (
    <TWInputSelect
      allowClear
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      itemList={itemList}
      {...allUnrecognizedProps}
    />
  );
};
