/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveAppointmentCheckinActionMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.AppointmentCheckinActionInput>;
}>;

export type SaveAppointmentCheckinActionMutation = {
  saveAppointmentCheckinAction: {
    appointmentCheckinActionId?: any | null;
    checkinTimes?: Array<any | null> | null;
    checkoutTimes?: Array<any | null> | null;
    notes?: string | null;
  };
};

export const SaveAppointmentCheckinActionDocument = gql`
  mutation SaveAppointmentCheckinAction($input: AppointmentCheckinActionInput) {
    saveAppointmentCheckinAction(input: $input) {
      appointmentCheckinActionId
      checkinTimes
      checkoutTimes
      notes
    }
  }
`;
export type SaveAppointmentCheckinActionMutationFn = Apollo.MutationFunction<
  SaveAppointmentCheckinActionMutation,
  SaveAppointmentCheckinActionMutationVariables
>;

/**
 * __useSaveAppointmentCheckinActionMutation__
 *
 * To run a mutation, you first call `useSaveAppointmentCheckinActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAppointmentCheckinActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAppointmentCheckinActionMutation, { data, loading, error }] = useSaveAppointmentCheckinActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAppointmentCheckinActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAppointmentCheckinActionMutation,
    SaveAppointmentCheckinActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveAppointmentCheckinActionMutation,
    SaveAppointmentCheckinActionMutationVariables
  >(SaveAppointmentCheckinActionDocument, options);
}
export type SaveAppointmentCheckinActionMutationHookResult = ReturnType<
  typeof useSaveAppointmentCheckinActionMutation
>;
export type SaveAppointmentCheckinActionMutationResult =
  Apollo.MutationResult<SaveAppointmentCheckinActionMutation>;
export type SaveAppointmentCheckinActionMutationOptions = Apollo.BaseMutationOptions<
  SaveAppointmentCheckinActionMutation,
  SaveAppointmentCheckinActionMutationVariables
>;
