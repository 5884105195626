export const graphqlDefs = `
extend type Mutation {
  personCreate(person: PersonInput!): PersonCreatePayload
}

enum Gender {
  MALE
  FEMALE
  NONBINARY
}

type InvitationMedium {
  Link: Boolean
  Email: Boolean
  SMS: Boolean
}

input PersonInput {
  emailAddress: String
  mobilePhone: String
  firstName: String
  middleName: String
  lastName: String
  userType: ID
  profilePicture: Upload
  gender: Gender
  nickname: String
  invitationMedium: InvitationMedium
  useNickname: String
  dateOfBirth: String
}

type PersonCreatePayload {
  userErrors: [UserError!]!
  person: PersonNode
}

type UserError {
  message: String!
  field: [String!]
}
`;

export const resolvers = {
  personCreate: (_, { input }) => {
    const userErrors = [];
    if (input.emailAddress === 'existing@example.com') {
      userErrors.push({
        field: ['emailAddress'],
        message: 'This email address is already in use for this organization.',
      });
    }

    if (input.mobilePhone === '1234567890') {
      userErrors.push({
        field: ['mobilePhone'],
        message: 'This mobile phone number is already in use for this organization.',
      });
    }

    return {
      __typename: 'PersonCreatePayload',
      person: {
        __typename: 'PersonNode',
        firstName: input.firstName,
        lastName: input.lastName,
      },
      userErrors,
    };
  },
};
