import Linkify from 'linkify-react';
import { FieldTemplateProps } from 'react-jsonschema-form';

import { TWPill, TWSpacingContainer } from '@tw/components';
import { getTranslation } from '@tw/i18n';

import { StyledDescription, StyledLabel, StyledWrapper } from './TWQuickFormFieldTemplate.styles';
import { RulePillContainer } from '../TWFormBuilderField/TWFormBuilderField.styles';

const TWQuickFormFieldTemplate = ({
  classNames,
  label,
  children,
  errors,
  help,
  description,
  rawDescription,
  required,
  displayLabel,
}: FieldTemplateProps) => (
  <Linkify options={{ target: { url: '_blank' } }}>
    <StyledWrapper
      className={classNames}
      data-testid={displayLabel ? 'QuickForm:Question' : 'QuickForm'}
      displayLabel={displayLabel}
    >
      {displayLabel && (
        <>
          <StyledLabel data-testid="QuickForm:Question:Label" className="control-label">
            {label}
          </StyledLabel>
          {displayLabel && description ? (
            <StyledDescription
              data-testid="QuickForm:Question:Description"
              className="field-description"
            >
              {rawDescription || ''}
            </StyledDescription>
          ) : null}
          {required ? (
            <>
              <RulePillContainer>
                <TWPill type="requiredQuestion" text={getTranslation('required')} />
              </RulePillContainer>
              <TWSpacingContainer twMarginBottom={1.5} />
            </>
          ) : null}
        </>
      )}
      {children}
      {errors}
      {help}
    </StyledWrapper>
  </Linkify>
);

export default TWQuickFormFieldTemplate;
