import { useState, useEffect, useCallback, useRef } from 'react';
import * as React from 'react';

const doubleClickThreshold = 200;

// Hook from Medium article:
// https://medium.com/@anilchaudhary453/double-click-using-react-hooks-4fea2292d3a4
const useDoubleClick = (dbClickCb: () => void, clickCb: () => void, isDisabled: boolean) => {
  const [elem, setElem] = useState(null);
  const countRef = useRef(0);
  const timerRef = useRef(null);
  const inputDbClickRef = useRef(null);
  const inputClickRef = useRef(null);
  const clickableElemRef = useCallback((node) => {
    setElem(node);
    clickableElemRef.current = node;
  }, []);

  useEffect(() => {
    inputDbClickRef.current = dbClickCb;
    inputClickRef.current = clickCb;
  });

  React.useEffect(() => {
    function handler() {
      const isDoubleClick = countRef.current + 1 === 2;
      const timerIsPresent = timerRef.current;
      if (timerIsPresent && isDoubleClick) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        countRef.current = 0;
        if (inputDbClickRef.current) {
          inputDbClickRef.current();
        }
      } else if (inputClickRef.current) {
        inputClickRef.current();
      }
      if (!timerIsPresent) {
        countRef.current += 1;
        const timer = setTimeout(() => {
          clearTimeout(timerRef.current);
          timerRef.current = null;
          countRef.current = 0;
        }, doubleClickThreshold);
        timerRef.current = timer;
      }
    }
    if (!isDisabled && elem) {
      elem.addEventListener('click', handler);
    }
    return () => {
      if (elem) {
        elem.removeEventListener('click', handler);
      }
    };
  }, [elem]);

  return [clickableElemRef, elem];
};

export default useDoubleClick;
