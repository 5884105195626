/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpenSignupSlotsMutationVariables = Types.Exact<{
  slotIds?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['UUID']>> | Types.InputMaybe<Types.Scalars['UUID']>
  >;
}>;

export type OpenSignupSlotsMutation = {
  openSignupSlots: {
    slots?: Array<{ slotId?: any | null; isOpen?: boolean | null } | null> | null;
  };
};

export const OpenSignupSlotsDocument = gql`
  mutation OpenSignupSlots($slotIds: [UUID]) {
    openSignupSlots(slotIds: $slotIds) {
      slots {
        slotId
        isOpen
      }
    }
  }
`;
export type OpenSignupSlotsMutationFn = Apollo.MutationFunction<
  OpenSignupSlotsMutation,
  OpenSignupSlotsMutationVariables
>;

/**
 * __useOpenSignupSlotsMutation__
 *
 * To run a mutation, you first call `useOpenSignupSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenSignupSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openSignupSlotsMutation, { data, loading, error }] = useOpenSignupSlotsMutation({
 *   variables: {
 *      slotIds: // value for 'slotIds'
 *   },
 * });
 */
export function useOpenSignupSlotsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpenSignupSlotsMutation,
    OpenSignupSlotsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OpenSignupSlotsMutation, OpenSignupSlotsMutationVariables>(
    OpenSignupSlotsDocument,
    options,
  );
}
export type OpenSignupSlotsMutationHookResult = ReturnType<typeof useOpenSignupSlotsMutation>;
export type OpenSignupSlotsMutationResult = Apollo.MutationResult<OpenSignupSlotsMutation>;
export type OpenSignupSlotsMutationOptions = Apollo.BaseMutationOptions<
  OpenSignupSlotsMutation,
  OpenSignupSlotsMutationVariables
>;
