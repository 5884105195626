import { useCallback } from 'react';
import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import {
  StudentPaginatedRawData,
  StudentQueryVariables,
  StudentRawQueryVariables,
} from '@tw/types';
import { studentModel } from '@tw/services/academicService';

import { FETCH_STUDENTS } from './FetchStudents.query';

export const useStudents = (
  variables?: StudentQueryVariables,
  options?: QueryHookOptions<StudentPaginatedRawData, StudentRawQueryVariables>,
) => {
  const { data, ...rest } = useQuery<StudentPaginatedRawData, StudentRawQueryVariables>(
    FETCH_STUDENTS,
    {
      variables: studentModel.normalizeStudentVariables(variables),
      ...options,
    },
  );

  return {
    data: studentModel.normalizePaginatedStudentData(data),
    ...rest,
  };
};

export const useStudentsLazy = (
  variables?: StudentQueryVariables,
  options?: LazyQueryHookOptions<StudentPaginatedRawData, StudentRawQueryVariables>,
) => {
  const [fetchStudentsRaw, { data, ...rest }] = useLazyQuery<
    StudentPaginatedRawData,
    StudentRawQueryVariables
  >(FETCH_STUDENTS, {
    variables: studentModel.normalizeStudentVariables(variables),
    ...options,
  });

  const fetchStudents = useCallback(
    (
      fetchVariables?: StudentQueryVariables,
      fetchOptions?: LazyQueryHookOptions<StudentPaginatedRawData, StudentRawQueryVariables>,
    ) =>
      fetchStudentsRaw({
        ...fetchOptions,
        variables: studentModel.normalizeStudentVariables(fetchVariables),
      }),
    [fetchStudentsRaw],
  );

  return {
    ...rest,
    fetchStudents,
    data: studentModel.normalizePaginatedStudentData(data),
  };
};
