import _ from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { getTranslation, TranslationKey } from '@tw/i18n';
import { ReminderType } from '@tw/modules/Calendar/Calendar.definitions';
import { dayjs } from '@tw/util';

import { TWIcon } from '@tw/components';
import {
  BeforeText,
  MarginRight2,
  ReminderRow,
  RemoveButton,
  RemoveButtonIcon,
} from './TWInputCompositeReminders.styles';

import { TWButton } from '../../../presentational/buttons';
import { TWTextDefault } from '../../../presentational/typography';
import TWInputSelect from '../TWInputSelect';

const DEFAULT_TIME_SELECTION = -15;
const DEFAULT_TYPE_SELECTION = 'sms';

interface Props {
  isDisabled?: boolean;
  maxReminders?: number;
  onChange?: (newValue: ReminderType[]) => void;
  popupContainerId?: string;
  testID?: string;
  value?: ReminderType[];
}

const TWInputCompositeReminders: React.FC<Props> = ({
  isDisabled = false,
  maxReminders = 3,
  onChange,
  popupContainerId,
  testID = 'TWInputCompositeReminders',
  value = null,
}) => {
  const [reminders, setReminders] = useState<ReminderType[]>([]);

  useEffect(() => {
    if (value) setReminders(value);
  }, [value]);

  const triggerChange = (changedValue: ReminderType[]) => {
    const newValue = [...changedValue];

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleReminderChange = (
    key: 'type' | 'time',
    inputPosition: number,
    newValue: string | number,
  ) => {
    const newReminderValues = reminders ? [...reminders] : [];
    _.set(newReminderValues, [inputPosition, key], newValue);

    // When setting time, nothing will happen here, as the line above sets this.
    // When setting type, we will only set time if time is not set already
    if (newValue && !_.has(newReminderValues, [inputPosition, 'time'])) {
      _.set(newReminderValues, [inputPosition, 'time'], DEFAULT_TIME_SELECTION);
    }

    triggerChange(newReminderValues);
  };

  const willHandleReminderTypeChange = _.memoize((index: number) =>
    _.partial(handleReminderChange, 'type', index),
  );

  const willHandleReminderTimeChange = _.memoize((index: number) =>
    _.partial(handleReminderChange, 'time', index),
  );

  const removeReminderNode = (inputPosition: number) => {
    const newReminderValues = [...reminders];
    newReminderValues.splice(inputPosition, 1);

    triggerChange(newReminderValues);
  };

  const willRemoveReminderNode = _.memoize((index: number) => _.partial(removeReminderNode, index));

  const getReminderTypes = () => {
    const reminderTypes = ['email', 'sms'];

    return _.map(reminderTypes, (reminderType) => ({
      value: reminderType,
      label: getTranslation(`components.reminderTypes.${reminderType}` as TranslationKey),
    }));
  };

  const getReminderTimeframes = () => {
    const nowMoment = dayjs();
    const valuesInMinutes = [-5, -10, -15, -30, -60, -120, -180, -360, -720, -1440, -2880, -10080];
    const result = [{ value: 0, label: getTranslation('xMinutes', 0) }];

    _.forEach(valuesInMinutes, (minuteValue) => {
      const timeOffset = dayjs().add(minuteValue, 'minutes');
      const label = nowMoment.from(timeOffset, true);
      result.push({ value: minuteValue, label });
    });
    // cannot use forEach approach because it returns label in days
    result.push({ label: getTranslation('xWeeks', 2), value: -20160 });
    result.push({ label: getTranslation('xWeeks', 3), value: -30240 });

    return result;
  };

  const addReminderNode = () => {
    const nextIndex = _.size(reminders);

    if (nextIndex < maxReminders) {
      willHandleReminderTypeChange(nextIndex)(DEFAULT_TYPE_SELECTION);
    }
  };

  const renderReminderRow = (rowValue: ReminderType, index: number) => (
    <ReminderRow key={`${index}_${rowValue.type}_${rowValue.time}`} data-testid="ReminderContainer">
      <MarginRight2>
        <TWInputSelect
          defaultValue={rowValue.type}
          showSearch={false}
          disabled={isDisabled}
          placeholder={getTranslation('types', 1)}
          popupContainerId={popupContainerId}
          itemList={getReminderTypes()}
          onChange={willHandleReminderTypeChange(index)}
          testID={`TWInputCompositeReminders:SelectReminderType:${index}`}
          width={200}
        />
      </MarginRight2>
      {rowValue.type && (
        <>
          <MarginRight2>
            <TWInputSelect
              disabled={isDisabled}
              showSearch={false}
              placeholder={getTranslation('reminders', 1)}
              popupContainerId={popupContainerId}
              itemList={getReminderTimeframes()}
              onChange={willHandleReminderTimeChange(index)}
              value={rowValue.time}
              testID={`TWInputCompositeReminders:SelectReminderTime:${index}`}
            />
          </MarginRight2>
          <BeforeText>{getTranslation('before')}</BeforeText>
          <RemoveButton
            onClick={willRemoveReminderNode(index)}
            data-testid={`TWInputCompositeReminders:RemoveReminderEntry:${index}`}
            accessibilityLabel={getTranslation('components.removeReminder')}
          >
            <RemoveButtonIcon type="material-remove_circle_outline" />
          </RemoveButton>
        </>
      )}
    </ReminderRow>
  );

  const canShowAddReminderButton = _.size(reminders) < maxReminders;
  return (
    <div data-testid={testID}>
      {_.map(reminders, (reminderValue, index) => renderReminderRow(reminderValue, index))}
      {canShowAddReminderButton && (
        <TWButton
          testID="TWInputCompositeReminders:AddReminderEntry"
          accessibilityLabel={getTranslation('components.addReminder')}
          onClick={addReminderNode}
        >
          <TWIcon type="material-control_point" />
          <TWTextDefault>{getTranslation('components.addReminder')}</TWTextDefault>
        </TWButton>
      )}
    </div>
  );
};

export default TWInputCompositeReminders;
