import { PropsWithChildren } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import UnhandledErrorView from '@tw/modules/UnhandledErrorView';

const ErrorBoundary = ({ children }: PropsWithChildren) => (
  <ReactErrorBoundary
    FallbackComponent={UnhandledErrorView}
    onError={(error, info) => datadogRum.addError(error, info)}
  >
    {children}
  </ReactErrorBoundary>
);

export default ErrorBoundary;
