import _ from 'lodash';

const translatorPropType = (props, propName, componentName) => {
  const thisProp = _.get(props, [propName]);

  // Simple duck-type for Polyglot objects since instanceof is problematic in tests
  if (!_.isNil(thisProp) && _.isUndefined(thisProp.phrases)) {
    return new Error(
      `Invalid prop "${propName}" supplied to "${componentName}": must be a PolyGlot object`,
    );
  }
  return null;
};

translatorPropType.isRequired = translatorPropType;

export default translatorPropType;
