import _ from 'lodash';

import { ConfigContainerType } from '@tw/types';
import { pythonApiBackend } from '@tw/backends';

import academicServiceStoreConfig from '../academicServiceStoreConfig';

const updateStudentTermPaths = {
  studentYearId: '/student_year_id',
  creditsCompleted: '/credits_completed',
};

const studentTermOperations = {
  updateStudentTerm(
    personId: string,
    teamId: string,
    studentTermId: string,
    updatedValues,
    filters,
    configContainer: ConfigContainerType,
  ) {
    const body = _.map(updatedValues, (value, key) => ({
      op: 'replace',
      path: updateStudentTermPaths[key],
      value,
    }));

    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: `shared/student_terms/${studentTermId}`,
        debugLabel: 'updateStudent',
        includeAuthenticationHeader: true,
        personId,
        teamId,
        studentTermId,
        filters,
      },
      {
        // fetch body
        method: 'PATCH',
        body,
      },
      configContainer,
    );
  },
};

export default studentTermOperations;
