import { Dropdown, MenuProps } from 'antd';
import { useMemo } from 'react';

import { TWButton, TWFlexContainer, TWIcon } from '@tw/components';
import { DOMUtils } from '@tw/util';
import { getTranslation } from '@tw/i18n';

import {
  TableActionButton,
  TableActionGroup,
  TableHeaderFilter,
} from '../../DataTable.definitions';
import { Actions, Filters, Wrapper, RightActionsDivider } from './TableHeader.styles';

type TableHeaderProps<T> = {
  actions: TableActionButton<T>[];
  editMode?: boolean;
  headerFilters?: TableHeaderFilter[];
  selectedRows: T[];
  testId: string;
  withInlineEditing?: boolean;
  renderRightActions?: () => React.ReactNode;
  renderLeftActions?: () => React.ReactNode;
  setEditMode?: (editMode: boolean) => void;
};

const TableHeader = <T extends object>({
  testId,
  actions,
  editMode = false,
  headerFilters,
  selectedRows,
  withInlineEditing = false,
  renderRightActions,
  renderLeftActions,
  setEditMode,
}: TableHeaderProps<T>) => {
  const dataTableId = useMemo(() => `${testId}-${Math.random().toString(32).slice(2)}`, [testId]);
  const makeActionDropdownOverlay = (tableActionsGroups: TableActionGroup<T>[]) => {
    const actionMenuItems = tableActionsGroups.reduce<MenuProps['items']>(
      (items, group, groupIndex) => {
        if (group?.options && group.options.length > 0) {
          items?.push({
            type: 'group',
            label: group.label,
            children: group.options
              .filter((action) => action.permission !== false)
              .map((action) => ({
                key: action.label,
                danger: action.danger,
                disabled: action.disabled,
                onClick: () => action.onClick(selectedRows),
                label: (
                  <TWFlexContainer row align="center">
                    {action.icon && <TWIcon type={action.icon} width={16} height={16} />}
                    {action.label}
                  </TWFlexContainer>
                ),
              })),
          });
          if (tableActionsGroups.length - 1 !== groupIndex) {
            items?.push({ type: 'divider' });
          }
        }
        return items;
      },
      [],
    );
    return actionMenuItems;
  };
  return (
    <Wrapper
      id={dataTableId}
      data-testid={`${testId}:HeaderActions`}
      justifyContent="space-between"
    >
      {!!renderLeftActions && renderLeftActions()}
      {actions.length > 0 && (
        <Actions data-testid={`${testId}:HeaderActions:OtherActions`}>
          {actions.map((button, buttonIndex) => (
            <Dropdown
              // eslint-disable-next-line react/no-array-index-key
              key={`${button.label}-${buttonIndex}`}
              trigger={['click']}
              disabled={!button.alwaysEnabled && selectedRows.length === 0}
              placement="bottomLeft"
              getPopupContainer={() => DOMUtils.getContainer(dataTableId)}
              menu={{ items: makeActionDropdownOverlay(button.groups) }}
            >
              <TWButton
                data-testid={`${testId}:HeaderActions:OtherActions:${button.label.replace(
                  ' ',
                  '',
                )}`}
              >
                {button.label}
                <TWIcon type="material-keyboard_arrow_down" />
              </TWButton>
            </Dropdown>
          ))}
        </Actions>
      )}

      <TWFlexContainer row>
        {(withInlineEditing || (headerFilters && headerFilters.length)) && (
          <Filters>
            {withInlineEditing && (
              <TWButton
                type={editMode ? 'primary' : 'borderless'}
                onClick={() => setEditMode?.(!editMode)}
                testID={`${testId}:TableHeader:EditAction`}
              >
                <TWIcon type={editMode ? 'material-task_alt' : 'material-edit_note'} />
                {getTranslation(editMode ? 'table.editMode.cancel' : 'table.editMode.edit')}
              </TWButton>
            )}
            {headerFilters?.map((filter) => (
              <TWButton
                key={filter.label?.toString()}
                testID={filter.testID}
                accessibilityLabel={filter.accessibilityLabel || filter.label?.toString()}
                type="borderless"
                loading={filter.loading}
                onClick={filter.onClick}
              >
                {filter.icon && <TWIcon type={filter.icon} />}
                {filter.label}
              </TWButton>
            ))}
          </Filters>
        )}
        {!!renderRightActions && (
          <>
            <RightActionsDivider />
            {renderRightActions()}
          </>
        )}
      </TWFlexContainer>
    </Wrapper>
  );
};

export default TableHeader;
