import _ from 'lodash';

import { pythonApiBackend } from '@tw/backends';
import { filterSpecUtils } from '@tw/util';
import {
  ConfigContainerType,
  CourseSectionReportFilters,
  CourseSectionRawReportFilters,
} from '@tw/types';

import academicServiceStoreConfig from '../academicServiceStoreConfig';

const createCourseSectionReportFilters = (
  filters: CourseSectionReportFilters,
): CourseSectionRawReportFilters => ({
  termIds: filters.termId ? String(_.head(filters.termId)) : undefined,
  teamIds: filters.teamIds ? filterSpecUtils.createArrayIncludesFilter(filters.teamIds) : undefined,
  courseIds: filters.courseId
    ? filterSpecUtils.createArrayIncludesFilter(filters.courseId)
    : undefined,
  // graphqlUtils.createArrayIncludesQuery handles an array of length 1 differently than the BE wants.
  // Once that behavior is consistent, we should use the util for courseSectionSchedulesDay
  courseSectionSchedulesDay: !_.isEmpty(filters.courseSectionSchedulesDay)
    ? `in:${JSON.stringify(_.flatten(_.castArray(filters.courseSectionSchedulesDay)))}`
    : undefined,
  studentIds: !_.isEmpty(filters.studentIds) ? JSON.stringify(filters.studentIds) : undefined,
});

const courseSectionOperations = {
  createCourseSectionReport(
    filters: CourseSectionReportFilters,
    personId: number,
    teamId: number,
    configContainer: ConfigContainerType,
  ) {
    const body = {
      contentType: 'csv',
      filters: createCourseSectionReportFilters(filters),
      searchString: filters.searchCourseSections ? filters.searchCourseSections : null,
    };

    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: 'enrollments/export',
        debugLabel: 'courseExport',
        includeAuthenticationHeader: true,
        desiredFormat: 'text',
        personId,
        teamId,
      },
      {
        // fetch body
        method: 'POST',
        body,
      },
      configContainer,
    );
  },
};

export default courseSectionOperations;
