import styled from '@emotion/styled';

export const PopoverOverflow = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const PopoverCountDiv = styled.div(({ theme }) => ({
  marginLeft: theme.baseUnit / 2,
  color: theme.colors.link,
}));
