import { useState } from 'react';
import _ from 'lodash';

function usePaginationFilters<T>(
  initialFilters: T,
): [T & { after: string | null }, (nextFilters: T) => void, (cursor: string) => void] {
  const [filters, setFilters] = useState({
    filters: initialFilters,
    combinedFilters: { ...initialFilters, after: null },
  });

  const handleFilterChange = (nextFilters: T) => {
    if (!_.isEqual(filters.filters, nextFilters)) {
      setFilters({
        filters: nextFilters,
        combinedFilters: { ...nextFilters, after: null },
      });
    }
  };

  const handlePageChange = (cursor: string) => {
    setFilters({
      filters: filters.filters,
      combinedFilters: {
        ...filters.combinedFilters,
        after: cursor,
      },
    });
  };
  return [filters.combinedFilters, handleFilterChange, handlePageChange];
}

export default usePaginationFilters;
