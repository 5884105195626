/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAppointmentConflictsQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type FetchAppointmentConflictsQuery = {
  allAppointments?: {
    edges: Array<{
      node?: {
        appointmentId: string;
        isAllDay: boolean;
        isBookedSlot?: string | null;
        location: string;
        startTimeZone?: string | null;
        title?: string | null;
        appointmentType?: { appointmentTypeId?: number | null } | null;
        attendees?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              pictureUrl?: string | null;
              initials?: string | null;
              label?: string | null;
              selectionCode?: string | null;
            }
          | {
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                initials?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                selectionCode?: string | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        occurrences?: Array<{
          appointmentId?: number | null;
          appointmentOccurrenceId?: number | null;
          endDateTime?: any | null;
          startDateTime?: any | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchAppointmentConflictsDocument = gql`
  query FetchAppointmentConflicts(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $selections: [String]!
  ) {
    allAppointments(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      selections: $selections
    ) {
      edges {
        node {
          appointmentId
          appointmentType {
            appointmentTypeId
          }
          attendees {
            label
            selectionCode
            ... on PersonNode {
              pictureUrl
              initials
            }
            ... on SelectionGroup {
              people {
                id
                pictureUrl
                initials
                firstName
                lastName
                selectionCode
              }
            }
          }
          isAllDay
          isBookedSlot
          location
          occurrences {
            appointmentId
            appointmentOccurrenceId
            endDateTime
            startDateTime
          }
          startTimeZone
          title
        }
      }
    }
  }
`;

/**
 * __useFetchAppointmentConflictsQuery__
 *
 * To run a query within a React component, call `useFetchAppointmentConflictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAppointmentConflictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAppointmentConflictsQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useFetchAppointmentConflictsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchAppointmentConflictsQuery,
    FetchAppointmentConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAppointmentConflictsQuery, FetchAppointmentConflictsQueryVariables>(
    FetchAppointmentConflictsDocument,
    options,
  );
}
export function useFetchAppointmentConflictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAppointmentConflictsQuery,
    FetchAppointmentConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchAppointmentConflictsQuery,
    FetchAppointmentConflictsQueryVariables
  >(FetchAppointmentConflictsDocument, options);
}
export type FetchAppointmentConflictsQueryHookResult = ReturnType<
  typeof useFetchAppointmentConflictsQuery
>;
export type FetchAppointmentConflictsLazyQueryHookResult = ReturnType<
  typeof useFetchAppointmentConflictsLazyQuery
>;
export type FetchAppointmentConflictsQueryResult = Apollo.QueryResult<
  FetchAppointmentConflictsQuery,
  FetchAppointmentConflictsQueryVariables
>;
