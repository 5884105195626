import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { TWLoadingMask, TWMessage } from '@tw/components';
import {
  FormAssignmentCompletionRoleNode,
  PersonNode,
  useExternalFormCompletionQuery,
  useInternalFormCompletionQuery,
} from '@tw/generated';
import { useViewer } from '@tw/hooks';
import { numberUtils, relayUtils } from '@tw/util';

import { FlexContainer } from '../Forms.styles';
import CompleteQuickformGQL from './components/CompleteQuickformGQL';
import { DocuSignForm } from '../DocuSign';
import AssignNextUserForm from './components/AssignNextUserForm';
import { ShareableLinkForm } from './components/ShareableLinkForm';

// For external users completing a perpetual assignment, prompt for name and email
// 1. Name input (required, maxlength 200)
// 2. Email input (maxlength 200)
// 3. Captcha (hard-coded disabled for AZ Cardinals)
//
// Form submission creates completion records for the assignment.

// If completion role id is available, we can render the form to complete
export const ExternalFormCompletion = ({ uuid }: { uuid: string }) => {
  const { data, loading } = useExternalFormCompletionQuery({ variables: { uuid } });
  if (loading) {
    return <TWLoadingMask />;
  }

  const externalFormAssignment = data?.externalFormAssignment;
  const completionRole = data?.externalCompletionRole as FormAssignmentCompletionRoleNode;
  const assignment = completionRole?.completion?.assignment;
  const statusCode = completionRole?.status;
  const signedDownloadUrl = completionRole?.completion?.signedDownloadUrl;

  const statusParts = _.split(statusCode, '.');
  const status = _.isEmpty(_.last(statusParts)) ? statusCode : _.last(statusParts);
  const externalName = completionRole?.externalName;
  const externalEmail = completionRole?.externalEmail;

  const assignmentCompletionRoleId = completionRole?.id;

  if (externalFormAssignment) {
    return (
      <ShareableLinkForm formAssignment={{ ...externalFormAssignment, quickformTemplate: null }} />
    );
  }

  if (!completionRole || !assignment) {
    return null;
  }

  if (assignment.isDocusignForm) {
    if (!_.every(completionRole?.completionRolesAssignedByCompleter, 'hasRoleBeenChosen')) {
      return <AssignNextUserForm completionRole={completionRole} uuid={uuid} external />;
    }
    return <DocuSignForm completionRole={completionRole} uuid={uuid} />;
  }

  const quickformTemplate =
    assignment?.quickformTemplate && JSON.parse(assignment.quickformTemplate);

  return (
    <CompleteQuickformGQL
      completionRoleId={assignmentCompletionRoleId || ''}
      externalEmail={externalEmail || undefined}
      externalName={externalName || undefined}
      isExternalRequest
      quickformTemplate={quickformTemplate}
      status={status || undefined}
      uuid={uuid}
      signedDownloadUrl={signedDownloadUrl || undefined}
      assignment={{
        ...assignment,
        assignedBy: {
          id: '',
          displayName: '',
          active: true,
          activeMemberships: [],
          addresses: [],
          // Add other required properties here
        } as unknown as PersonNode,
      }}
    />
  );
};

export const InternalFormCompletion = () => {
  const viewer = useViewer();
  const { assignmentCompletionRoleId } = useParams<{ assignmentCompletionRoleId: string }>();

  const { data, loading } = useInternalFormCompletionQuery({
    variables: {
      // not using Number.isNaN() since string will be returned as false
      // not using global isNaN() since TS will flag it as a type error when passing string
      id: numberUtils.isNumeric(assignmentCompletionRoleId)
        ? relayUtils.toGlobalId('FormAssignmentCompletionRoleNode', assignmentCompletionRoleId)
        : assignmentCompletionRoleId,
    },
    onError: (error) => TWMessage.errorHandler(error),
  });
  const completionRole = data?.completionRole as FormAssignmentCompletionRoleNode;
  const assignment = completionRole?.completion?.assignment;
  const quickformTemplate =
    assignment?.quickformTemplate && JSON.parse(assignment.quickformTemplate);

  if (!completionRole || !assignment) {
    return null;
  }

  if (loading) {
    return <TWLoadingMask />;
  }

  if (assignment.isDocusignForm) {
    // Check whether current viewer has already assigned users to the roles they are supposed to choose
    if (!_.every(completionRole?.completionRolesAssignedByCompleter, 'hasRoleBeenChosen')) {
      return <AssignNextUserForm completionRole={completionRole} viewer={viewer} />;
    }
    return (
      <FlexContainer>
        <DocuSignForm completionRole={completionRole} internal />
      </FlexContainer>
    );
  }
  return (
    <FlexContainer>
      <CompleteQuickformGQL
        completionRoleId={assignmentCompletionRoleId}
        quickformTemplate={quickformTemplate}
        assignment={assignment}
      />
    </FlexContainer>
  );
};
