/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalsyncCalendarCreateMutationVariables = Types.Exact<{
  input: Types.CalsyncCalendarInput;
}>;

export type CalsyncCalendarCreateMutation = {
  calsyncCalendarCreate: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    calsyncCalendar?: {
      id: string;
      providerId: string;
      createdBy?: number | null;
      personId: number;
      thirdPartyName?: string | null;
      status?: string | null;
      relinkUrl?: string | null;
      calsyncAccount?: { id: string; status?: string | null; providerId: string } | null;
    } | null;
  };
};

export const CalsyncCalendarCreateDocument = gql`
  mutation CalsyncCalendarCreate($input: CalsyncCalendarInput!) {
    calsyncCalendarCreate(input: $input) {
      userErrors {
        field
        message
      }
      calsyncCalendar {
        id
        providerId
        createdBy
        personId
        thirdPartyName
        status
        relinkUrl
        calsyncAccount {
          id
          status
          providerId
        }
      }
    }
  }
`;
export type CalsyncCalendarCreateMutationFn = Apollo.MutationFunction<
  CalsyncCalendarCreateMutation,
  CalsyncCalendarCreateMutationVariables
>;

/**
 * __useCalsyncCalendarCreateMutation__
 *
 * To run a mutation, you first call `useCalsyncCalendarCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalsyncCalendarCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calsyncCalendarCreateMutation, { data, loading, error }] = useCalsyncCalendarCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalsyncCalendarCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CalsyncCalendarCreateMutation,
    CalsyncCalendarCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CalsyncCalendarCreateMutation, CalsyncCalendarCreateMutationVariables>(
    CalsyncCalendarCreateDocument,
    options,
  );
}
export type CalsyncCalendarCreateMutationHookResult = ReturnType<
  typeof useCalsyncCalendarCreateMutation
>;
export type CalsyncCalendarCreateMutationResult =
  Apollo.MutationResult<CalsyncCalendarCreateMutation>;
export type CalsyncCalendarCreateMutationOptions = Apollo.BaseMutationOptions<
  CalsyncCalendarCreateMutation,
  CalsyncCalendarCreateMutationVariables
>;
