/**
 * This toggles the 'darkDefault' layer in the config
 */
export const TOGGLE_DARK_SCHEME = 'THEME_CONFIG__TOGGLE_DARK_SCHEME';

/**
 * This *replaces* any customerOverride themeConfig with a new set of config
 */
export const SET_CUSTOMER_OVERRIDE = 'THEME_CONFIG__SET_CUSTOMER_OVERRIDE';

/**
 * This *disables and wipes* any customerOverride themeConfig
 */
export const CLEAR_CUSTOMER_OVERRIDE = 'THEME_CONFIG__CLEAR_CUSTOMER_OVERRIDE';

/**
 * This *merges in* a set of debugOverride themeConfig
 */
export const APPLY_DEBUG_OVERRIDE = 'THEME_CONFIG__APPLY_DEBUG_OVERRIDE';

/**
 * This wipes (but does not disable) any debugOverride themeConfig
 */
export const CLEAR_DEBUG_OVERRIDE = 'THEME_CONFIG__CLEAR_DEBUG_OVERRIDE';

/**
 * Low-level generic action for assigning raw values to a specific layer.
 *
 * This should not be used directly: use the action constructors instead.
 */
export const SET_VALUES_IN_THEME_CONFIG_LAYER = 'THEME_CONFIG__SET_VALUES_IN_LAYER';

function setScreenDimensionsAction(screenDimensions) {
  return {
    type: SET_VALUES_IN_THEME_CONFIG_LAYER,
    layerName: 'platformScheme',
    values: {
      $screenDimensions: screenDimensions,
    },
  };
}

function toggleDarkSchemeAction() {
  return {
    type: TOGGLE_DARK_SCHEME,
  };
}

function setCustomerOverrideAction(customerOverrideThemeConfig) {
  return {
    type: SET_CUSTOMER_OVERRIDE,
    customerOverrideThemeConfig,
  };
}

function clearCustomerOverrideAction() {
  return {
    type: CLEAR_CUSTOMER_OVERRIDE,
  };
}

/**
 * By contract, each service has an applyDebugOverrideAction, for use in Secret Settings ONLY.
 * See superSecretConfigData if you want to go down that rabbit hole.
 */
function applyDebugOverrideAction(debugOverrideThemeConfig) {
  return {
    type: APPLY_DEBUG_OVERRIDE,
    debugOverrideThemeConfig,
  };
}

function clearDebugOverrideAction() {
  return {
    type: CLEAR_DEBUG_OVERRIDE,
  };
}

export {
  // New semantic actions
  setScreenDimensionsAction,
  // Old rusty layer-specific actions
  toggleDarkSchemeAction,
  setCustomerOverrideAction,
  clearCustomerOverrideAction,
  applyDebugOverrideAction,
  clearDebugOverrideAction,
};
