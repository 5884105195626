import { useHistory } from 'react-router-dom';

import { TWTextDefault, TWSpacingContainer, TWFlexContainer } from '@tw/components';
import { getTranslation } from '@tw/i18n';

interface FooterLinkProps {
  links?: {
    linkText: string;
    urlPath: string;
  }[];
  backLink?: string;
  contactSupport?: boolean;
}

export const FooterLink = ({ links = [], backLink, contactSupport }: FooterLinkProps) => {
  const history = useHistory();
  const updateHistoryLink = (link: string) => {
    history.push(link);
  };

  return backLink || contactSupport || links.length > 0 ? (
    <TWSpacingContainer>
      <TWFlexContainer row justify="space-around" className="auth-footer">
        {backLink && (
          <TWTextDefault onClick={() => updateHistoryLink('/')} className="auth-footer-back">
            {backLink}
          </TWTextDefault>
        )}
        {links.map((link) => (
          <TWTextDefault
            onClick={() => updateHistoryLink(link.urlPath)}
            className="auth-footer-link"
          >
            {link.linkText}
          </TWTextDefault>
        ))}
        {contactSupport && (
          <a
            data-testid="Authentication:LoginPage:supportEmailLink"
            href="mailto:support@teamworks.com"
          >
            <TWTextDefault className="auth-footer-link">
              {getTranslation('navigation.contactSupport')}
            </TWTextDefault>
          </a>
        )}
      </TWFlexContainer>
    </TWSpacingContainer>
  ) : null;
};
