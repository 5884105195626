import _ from 'lodash';
import moment, { Moment } from 'moment';

// Declare Constants
const IS_NULL = 'eq:null';
const IS_NOT_NULL = `~${IS_NULL}`;

function createFilteredDateString(momentObject: Moment) {
  return momentObject.format().replace('T', ' ').replace('Z', '');
}

const getBeforeNowFilter = (now: Moment | string) =>
  `lt:(${createFilteredDateString(moment(now).utc())})`;

const createNotEqualFilter = (notEqual: unknown[]) => {
  if (_.isEmpty(notEqual)) {
    return null;
  }

  return `~eq:'${notEqual}'`;
};

const createArrayIncludesFilter = (toInclude: unknown[]) => {
  if (_.isEmpty(toInclude)) {
    return undefined;
  }

  return `in:[${toInclude}]`;
};

const createArrayOverlapsFilter = (toOverlap: unknown[]) => {
  if (_.isEmpty(toOverlap)) {
    return undefined;
  }

  return `overlaps:[${toOverlap}]`;
};

const createArrayExcludesFilter = (toExclude: number | unknown[]) => {
  if (_.isNumber(toExclude)) {
    return toExclude;
  }
  if (_.isEmpty(toExclude)) {
    return null;
  }

  return `~in:${JSON.stringify(_.flatten(toExclude))}`;
};

const getFilterFromWhiteListSelection = (selectionKeys: unknown[], isWhiteListed: boolean) =>
  isWhiteListed
    ? createArrayIncludesFilter(selectionKeys)
    : createArrayExcludesFilter(selectionKeys);

const createRangeFilter = ([startMoment, endMoment]: [Moment, Moment]) => {
  if (!startMoment || !endMoment) {
    return undefined;
  }

  return `btw:[(${createFilteredDateString(startMoment)}),(${createFilteredDateString(
    endMoment,
  )})]`;
};

export default {
  IS_NULL,
  IS_NOT_NULL,
  createArrayIncludesFilter,
  createArrayOverlapsFilter,
  createArrayExcludesFilter,
  createNotEqualFilter,
  createRangeFilter,
  getBeforeNowFilter,
  getFilterFromWhiteListSelection,
};
