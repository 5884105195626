import { gql } from '@apollo/client';

export const FETCH_STUDENTS = gql`
  query FetchStudents(
    $isDeleted: TWFilter_Boolean
    $sort: [StudentSortEnum]
    $teamIds: TWFilter_IntArray
  ) {
    students(isDeleted: $isDeleted, sort: $sort, teamIds: $teamIds) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          studentId
          personId
          fullName
        }
      }
    }
  }
`;
