import styled from '@emotion/styled';
import { Button } from 'antd';
import { TWLogoWhite, TWSadFootballPlayer } from '@tw/assets';

const ErrorPage = styled.div(() => ({
  backgroundColor: '#393b3f',
  backgroundImage: `linear-gradient(180deg, rgba(57, 59, 63, .54), rgba(57, 59, 63, .54)), url(${TWSadFootballPlayer})`,
  backgroundPosition: '0px 0px, 0px 0px',
  backgroundSize: 'auto, cover',
  height: 'calc(100vh)',
  fontFamily: '"Inter", sans-serif',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: '#fff',
}));

const ErrorItem = styled.div(() => ({
  paddingLeft: 50,
  paddingBottom: 20,
}));

// Inline to override ant'd styles
const errorHeadingStyles = {
  fontWeight: 300,
  fontSize: 38,
  color: '#fff',
  marginTop: 20,
};

const Link = styled.a`
  color: #a1c738 !important;
`;

const ErrorText = styled.span(() => ({
  fontWeight: 800,
}));

const loginStyles = {
  paddingBottom: '30px',
};

export default ({ error }) => {
  const message = error?.message;

  const is401Error = !!message && message.includes('401');

  return (
    <ErrorPage>
      <ErrorItem>
        <img style={{ width: 350 }} src={TWLogoWhite} alt="Teamworks logo" />
      </ErrorItem>
      <ErrorItem>
        <h1 style={errorHeadingStyles}>We encountered an error</h1>
      </ErrorItem>
      {message && (
        <ErrorItem>
          <ErrorText>{message}</ErrorText>
        </ErrorItem>
      )}
      {is401Error ? (
        <ErrorItem>
          <p>There may be an issue with your current session. Click here to try re-logging in. </p>
          <Button
            type="primary"
            htmlType="button"
            style={loginStyles}
            onClick={() => {
              window.localStorage.clear();
              window.location.href = '/';
            }}
          >
            Login
          </Button>
        </ErrorItem>
      ) : (
        <ErrorItem>
          <p>We were unable to process your request, please try refreshing the page.</p>
          <Button type="primary" htmlType="button" onClick={() => window.location.reload()}>
            Refresh Page
          </Button>
        </ErrorItem>
      )}
      <ErrorItem>
        <p>
          If the issue persists, contact{' '}
          <Link href="mailto:support@teamworks.com">Teamworks Support</Link>.
        </p>
      </ErrorItem>
    </ErrorPage>
  );
};
