import { Col, MenuProps, Row, Tooltip } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { TableRowSelection } from 'antd/lib/table/interface';
import _ from 'lodash';
import { MouseEvent, cloneElement, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce, usePrevious } from 'react-use';

import { getTranslation, useTranslator } from '@tw/i18n';
import { DOMUtils, paginationUtils } from '@tw/util';

import { TWButton, TWFlexContainer, TWHeading4, TWTextDefault } from '../../../presentational';
import TWIcon from '../../TWIcon';
import { TWButtonGroupPagination } from '../../buttons';
import { TWInputSelectRowsPerPage } from '../../inputs';
import { TWColumnFlex } from '../../layout';
import { ROWS_PER_PAGE_STORAGE_KEY } from '../@config';
import { TWDataTable } from '../TWDataTable';
import {
  PaginatedGenericSelectionQuery,
  SelectionState,
  TWDataTablePaginatedProps,
} from './TWDataTablePaginated.definitions';
import {
  BulkActionColumn,
  BulkDropdown,
  BulkDropdownButton,
  BulkDropdownIcon,
  BulkDropdownTitleText,
  Container,
  HeaderColumn,
  HeaderRow,
} from './TWDataTablePaginated.styles';
import {
  hasSelectIgnore,
  hasSelectIsActive,
  hasSelectionPrimaryKey,
} from './TWDataTablePaginated.utils';

const paginationFilters = ['after', 'before', 'first', 'last'];

export function TWDataTablePaginated<T extends object>(props: TWDataTablePaginatedProps<T>) {
  const {
    children,
    className = '',
    containerId,
    totalCount,
    hasNextPage = false,
    defaultSelections = [],
    filters,
    headerLabel,
    testID,
    filters: { first: rowsPerPage },
    bulkActions = [],
    selectionPrimaryKey = 'key',
    onSelectionChanged,
    dataSource,
    loading = false,
    renderHeaderExtra,
    resetSelectionsOnFilterChange = true,
    onFilterChange,
    nextCursor,
    onPageChange,
    bulkActionsForCurrentPage = false,
    rowsPerPageOptions,
    clearSelection = false,
    bulkActionModal = false,
    bulkActionDropdown = false,
    bulkActionDropdownTitle,
    disabledCheckbox = false,
    rowClassName,
    setCursorHistoryData,
    ...allUnrecognizedProps
  } = props;

  const translator = useTranslator();

  const prevFilters = usePrevious(filters);
  const lastTotalCount = usePrevious(totalCount);
  const location = useLocation();

  const [selections, setSelections] = useState<SelectionState>({
    included: true,
    keys: defaultSelections || [],
  });

  const setSelectionState = useCallback(
    (update: Partial<SelectionState>) => setSelections({ ...selections, ...update }),
    [selections],
  );

  const [cursorHistory, setCursorHistory] = useState<(string | null)[]>([]);

  useEffectOnce(() => {
    const storageRowsPerPage = Number(localStorage.getItem(ROWS_PER_PAGE_STORAGE_KEY));
    if (storageRowsPerPage) {
      onFilterChange({
        ...filters,
        first: storageRowsPerPage,
      });
    }
  });

  useEffect(() => {
    if (!filters.after) {
      setCursorHistory([]);
    }
  }, [filters.after]);

  useEffect(() => {
    const cachedCursorHistoryData = localStorage.getItem('cursorHistoryData');
    if (
      location.state !== undefined &&
      cachedCursorHistoryData &&
      JSON.parse(cachedCursorHistoryData)
    ) {
      setCursorHistory(JSON.parse(cachedCursorHistoryData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetSelectionsOnFilterChange && prevFilters) {
      // if prevFilters is falsy, this is the first render and we should skip
      const filtersNoPagination = _.omit(filters, paginationFilters);
      const prevFiltersNoPagination = _.omit(prevFilters, paginationFilters);
      if (!_.isEqual(filtersNoPagination, prevFiltersNoPagination)) {
        // This is safe because it is only called when non-paginated filters change.
        // Will make more sense when we can use useEffect instead
        // setSelectionIsWhiteListed(true);
        // setSelectionKeys([]);
        setSelections({ included: true, keys: [] });
      }
    }
  }, [resetSelectionsOnFilterChange, filters, prevFilters]);

  useEffect(() => {
    if (clearSelection) {
      setSelectionState({ keys: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSelection]);

  const handleSelectAll = (selectAll: boolean) => {
    if (!bulkActionsForCurrentPage) {
      setSelections({ included: !selectAll, keys: [] });
    } else {
      const dataOnPage = _.map(
        _.filter(dataSource, (item) => !hasSelectIgnore(item) || !item.selectIgnore),
        selectionPrimaryKey,
      );
      if (selectAll) {
        setSelections((prevState) => ({
          included: true,
          keys: _.union(prevState.keys, dataOnPage),
        }));
      } else {
        const newSelection = selections.keys.filter((item) => !dataOnPage.includes(item));
        setSelections({
          included: true,
          keys: newSelection,
        });
      }
    }
  };

  const handleOnSelect = (record: T, selected: boolean) => {
    let update: (string | number)[];

    if (hasSelectionPrimaryKey(record, selectionPrimaryKey)) {
      if (selections.included) {
        if (selected) {
          update = [...selections.keys, record[selectionPrimaryKey]];
        } else {
          update = _.filter(selections.keys, (key) => key !== record[selectionPrimaryKey]);
        }
      } else if (selected) {
        update = _.filter(selections.keys, (key) => key !== record[selectionPrimaryKey]);
      } else {
        update = [...selections.keys, record[selectionPrimaryKey]];
      }
      setSelectionState({ keys: update });
    }
  };

  const handleSelectionChanged = useCallback(() => {
    if (_.isFunction(onSelectionChanged)) {
      const selectionQueryConfig: PaginatedGenericSelectionQuery = {
        [selectionPrimaryKey]: {
          isWhiteListed: selections.included,
          selectionKeys: selections.keys,
          totalCount: totalCount ?? 0,
        },
      };

      onSelectionChanged(selectionQueryConfig);
    }
  }, [onSelectionChanged, selectionPrimaryKey, selections, totalCount]);

  useEffect(() => {
    handleSelectionChanged();
  }, [handleSelectionChanged]);

  const getHeaderText = () => {
    const totalRowCount = totalCount || 0;
    const ignoreRowCount = _.size(
      _.filter(dataSource, (item) => hasSelectIgnore(item) && item.selectIgnore),
    ); // rows which cannot be selected

    let countText = `${totalRowCount} ${headerLabel}`;

    if (_.size(bulkActions) > 0) {
      let selectedCount: number;
      // If only particular rows are selected we show their number
      if (selections.included) {
        selectedCount = _.size(selections.keys);
      } else if (ignoreRowCount) {
        // We show only the number of rows that can be selected, if a bulk checkbox is checked
        selectedCount = _.size(dataSource) - ignoreRowCount - _.size(selections.keys);
      } else {
        // Otherwise, we show the total number of rows without counting unselected single rows
        selectedCount = totalRowCount - _.size(selections.keys);
      }

      if (selectedCount > 0) {
        countText =
          selectedCount === totalRowCount
            ? getTranslation('numAllSelected', selectedCount)
            : getTranslation('numSelected', selectedCount);
      }
    }

    return <TWHeading4 data-testid="table-header-text">{countText}</TWHeading4>;
  };

  const menuItems = (): MenuProps['items'] => {
    const items = bulkActions.map<MenuItemType>((action, index) => {
      const { onClick } = action;
      const onClickFn: MenuItemType['onClick'] = (info) => {
        const selectionQueryConfig: PaginatedGenericSelectionQuery = {
          [selectionPrimaryKey]: {
            isWhiteListed: selections.included,
            selectionKeys: selections.keys,
            totalCount: totalCount ?? 0,
          },
        };

        onClick(selectionQueryConfig, info.domEvent);
        if (bulkActionsForCurrentPage && !bulkActionModal) {
          setSelections({ keys: [], included: true });
        }
      };

      let text: string | undefined;
      let iconName: string | undefined;
      let disabled = false;

      if ('text' in action) {
        text = action.text;
        iconName = action.iconName;
        disabled = action.disabled ?? false;
      }

      return {
        key: text ?? index.toString(),
        onClick: onClickFn,
        'data-testid': `ActionMenu:Item:${iconName}`,
        disabled,
        label: (
          <TWFlexContainer row>
            {iconName ? <BulkDropdownIcon type={iconName} /> : null}
            <TWTextDefault>{text}</TWTextDefault>
          </TWFlexContainer>
        ),
      };
    });

    return bulkActionDropdownTitle
      ? [
          {
            type: 'group',
            label: <BulkDropdownTitleText>{bulkActionDropdownTitle}</BulkDropdownTitleText>,
            children: items,
          },
        ]
      : items;
  };

  const getBulkActions = () => {
    // If the bulkActions array is empty the nothing is rendered
    if (_.size(bulkActions) === 0) {
      return null;
    }

    const noRowsSelected = selections.included
      ? _.size(selections.keys) === 0
      : _.size(selections.keys) === _.size(dataSource);

    if (bulkActionDropdown) {
      return (
        <BulkDropdown
          disabled={noRowsSelected}
          menu={{ items: menuItems() }}
          trigger={['click']}
          placement="bottomRight"
          getPopupContainer={() => DOMUtils.getContainer(containerId)}
        >
          <BulkDropdownButton
            maxWidth="none"
            accessibilityLabel="action-menu-open"
            testID="ActionMenu:Open"
          >
            <TWFlexContainer
              row
              justify="space-between"
              align="center"
              style={{ width: 'inherit' }}
            >
              <div>{translator.t('editSelected')}</div>
              <TWIcon type="material-keyboard_arrow_down" width={18} height={18} />
            </TWFlexContainer>
          </BulkDropdownButton>
        </BulkDropdown>
      );
    }

    return _.map(bulkActions, (action, key) => {
      const { onClick } = action;
      const onClickFn = (event?: MouseEvent<HTMLElement>) => {
        const selectionQueryConfig: PaginatedGenericSelectionQuery = {
          [selectionPrimaryKey]: {
            isWhiteListed: selections.included,
            selectionKeys: selections.keys,
            totalCount: totalCount ?? 0,
          },
        };

        onClick(selectionQueryConfig, event);
        if (bulkActionsForCurrentPage && !bulkActionModal) {
          setSelections({ keys: [], included: true });
        }
      };

      if ('customControl' in action) {
        const { customControl, ignoreSelections = false } = action;

        return cloneElement(customControl, {
          onClick: onClickFn,
          key,
          disabled: !ignoreSelections && noRowsSelected,
        });
      }

      const {
        text,
        iconName,
        disabled = false,
        loading: actionLoading = false,
        testID: actionTestId,
      } = action;

      return (
        <TWButton
          accessibilityLabel={text}
          type="default"
          disabled={noRowsSelected || disabled}
          key={text}
          onClick={onClickFn}
          loading={actionLoading}
          maxWidth={300}
          testID={actionTestId}
        >
          <TWIcon type={iconName.startsWith('tw-') ? iconName : `material-${iconName}`} />
          <TWTextDefault>{text}</TWTextDefault>
        </TWButton>
      );
    });
  };

  const getSelectedRowKeys = () => {
    // filtered list by rows which cannot be selected
    const newDataSource = _.filter(
      dataSource,
      (item) => !(hasSelectIgnore(item) && item.selectIgnore),
    );
    return _(newDataSource)
      .map(selectionPrimaryKey)
      .filter((itemKey) =>
        selections.included
          ? _.includes(selections.keys, itemKey)
          : !_.includes(selections.keys, itemKey),
      )
      .value();
  };

  const loadNextPage = () => {
    const { after: currentCursor } = filters;

    if (hasNextPage && cursorHistory.indexOf(currentCursor ?? null) === -1) {
      setCursorHistory((prev) => [...prev, currentCursor ?? null]);

      if (nextCursor) {
        onPageChange(nextCursor);
      }
    }
  };

  const loadPreviousPage = () => {
    const previousCursor = cursorHistory[cursorHistory.length - 1];
    setCursorHistory((prev) => prev.slice(0, -1));

    onPageChange(previousCursor ?? null);
  };

  const updateRowsPerPage = (newValue: number) => {
    const prevRowsPerPage = Number(localStorage.getItem(ROWS_PER_PAGE_STORAGE_KEY));
    // Don't write to storage if it hasn't changed.
    if (prevRowsPerPage !== newValue) {
      localStorage.setItem(ROWS_PER_PAGE_STORAGE_KEY, String(newValue));
    }

    setCursorHistory([]);

    onFilterChange({
      ...filters,
      first: newValue,
    });
  };

  useEffect(() => {
    if (setCursorHistoryData) {
      setCursorHistoryData(cursorHistory);
    }
  }, [cursorHistory, setCursorHistoryData]);

  const currentPage = _.size(cursorHistory);
  const hasPreviousPage = currentPage > 0;

  // This is in place so while the screen is loading, it is clearer to the user that we are
  // loading a subsequent page. While it loads, totalCount becomes null so keeping a copy of
  // it so we can achieve this desired clarity.
  const safeLastTotalCount = _.isNumber(totalCount) ? totalCount : Number(lastTotalCount);

  const rangeString = paginationUtils.getRangeOfRowsOnPage(
    currentPage,
    rowsPerPage ?? 0,
    safeLastTotalCount,
  );
  const currentPageString = safeLastTotalCount ? `${rangeString} of ${safeLastTotalCount}` : '';

  const showRowSelection = _.some(
    bulkActions,
    (action) => !('ignoreSelections' in action) || !action.ignoreSelections,
  );
  let rowSelection: TableRowSelection<T> | undefined;
  if (showRowSelection) {
    rowSelection = {
      type: 'checkbox',
      renderCell: (_value, record, _index, originNode) => ({
        children:
          disabledCheckbox &&
          hasSelectIgnore(record) &&
          record.selectIgnore &&
          hasSelectIsActive(record) &&
          record.isActive ? (
            <Tooltip
              overlayStyle={{ width: 150 }}
              placement="bottomRight"
              title={getTranslation('forms.manageFormsActions.editAssignmentPermission')}
            >
              {originNode}
            </Tooltip>
          ) : (
            originNode
          ),
      }),
      selectedRowKeys: getSelectedRowKeys(),
      onSelectAll: (selected) => {
        handleSelectAll(selected);
      },
      onSelect: handleOnSelect,
      getCheckboxProps: (record: T) => ({
        disabled: hasSelectIgnore(record) && record.selectIgnore, // Row configuration not to be checked
        'aria-label': translator.t('select'),
      }),
    };
  }

  return (
    <div aria-label={headerLabel}>
      <Container className={className}>
        <HeaderRow align="bottom" justify="space-between">
          {!!totalCount && (
            <>
              <HeaderColumn>{getHeaderText()}</HeaderColumn>
              {!!renderHeaderExtra && <HeaderColumn>{renderHeaderExtra()}</HeaderColumn>}
              {!bulkActionDropdown && (
                <BulkActionColumn showSeparator={totalCount != null && totalCount > 0}>
                  {getBulkActions()}
                </BulkActionColumn>
              )}
            </>
          )}
          <TWColumnFlex>
            <Row align="middle" justify="end">
              {bulkActionDropdown && (
                <BulkActionColumn
                  showSeparator={totalCount != null && totalCount > 0}
                  style={{ justifyContent: 'end', paddingRight: '20px' }}
                >
                  {getBulkActions()}
                </BulkActionColumn>
              )}
              {currentPageString}
              <TWButtonGroupPagination
                loading={loading}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                onNextPage={loadNextPage}
                onPreviousPage={loadPreviousPage}
              />
            </Row>
          </TWColumnFlex>
        </HeaderRow>
        <TWDataTable
          size="middle"
          testID={testID}
          dataSource={dataSource}
          rowSelection={rowSelection}
          rowClassName={rowClassName}
          // selectionPrimaryKey is used as the rowKey to match the
          // rowSelection.selectedRowKeys array
          rowKey={selectionPrimaryKey}
          loading={loading}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...allUnrecognizedProps}
        >
          {children}
        </TWDataTable>
        <HeaderRow align="middle" justify="space-between">
          <Col className="rowsPerPageColumn">
            <TWInputSelectRowsPerPage
              id="DataTablePaginated:RowsPerPage"
              accessibilityLabel={translator.t('rowsPerPage')}
              value={rowsPerPage ?? undefined}
              options={rowsPerPageOptions}
              onChange={updateRowsPerPage}
            />
          </Col>
          <Col>
            <Row align="middle" justify="end">
              {currentPageString}
              <TWButtonGroupPagination
                loading={loading}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                onNextPage={loadNextPage}
                onPreviousPage={loadPreviousPage}
              />
            </Row>
          </Col>
        </HeaderRow>
      </Container>
    </div>
  );
}
