import { createSelector } from 'reselect';

import backendConfigStoreConfig from '@tw/config/backendConfig/backendConfigStoreConfig';
import formatConfigStoreConfig from '@tw/config/formatConfig/formatConfigStoreConfig';
import themeConfigStoreConfig from '@tw/config/themeConfig/themeConfigStoreConfig';
import uiSettingsConfigStoreConfig from '@tw/config/uiSettingsConfig/uiSettingsConfigStoreConfig';

// @TODO: We should probably do the renames on export instead of import, once this is working
// and if/when we decide to roll out TWSecretSettingsTextInput to all Secret Settings screens.
import { applyDebugOverrideAction as applyBackendConfigDebugOverrideAction } from '@tw/config/backendConfig/backendConfigActions';
import { applyDebugOverrideAction as applyFormatConfigDebugOverrideAction } from '@tw/config/formatConfig/formatConfigActions';
import { applyDebugOverrideAction as applyThemeConfigDebugOverrideAction } from '@tw/config/themeConfig/themeConfigActions';
import { applyDebugOverrideAction as applyUiSettingsConfigDebugOverrideAction } from '@tw/config/uiSettingsConfig/uiSettingsConfigActions';

import {
  allBackendConfigLayerAttributesSelector,
  allBackendConfigLayerValuesSelector,
} from '@tw/config/backendConfig/backendConfigSelectors';
import {
  allFormatConfigLayerAttributesSelector,
  allFormatConfigLayerValuesSelector,
} from '@tw/config/formatConfig/formatConfigSelectors';
import {
  allThemeConfigLayerAttributesSelector,
  allThemeConfigLayerValuesSelector,
} from '@tw/config/themeConfig/themeConfigSelectors';
import {
  allUiSettingsConfigLayerAttributesSelector,
  allUiSettingsConfigLayerValuesSelector,
} from '@tw/config/uiSettingsConfig/uiSettingsConfigSelectors';

/*
 * This is a pretty evil hack and anti-pattern that makes life in Secret Settings much easier --
 * but you MUST NOT use this anywhere outside of Secret Settings.
 *
 * The end goal here is that we want a rather generic TWSecretSettingsTextInput for viewing
 * and editing values in configContainer. For this the component needs to be able to peek
 * under the hood at the raw layers in Redux (normally a huge no-no) -- it needs to know
 * config names, paths, layer names, layer values -- and it also needs a way to dispatch
 * changes to update the correct parts of the config.
 *
 * Normally much of this logic would be handled in the screen/module, but in Secret Settings
 * the overall logic and needs are the same across all screens. So, to make things easier
 * for them, this file provides a selector and action that have unprecedented access to
 * the data in the layered configs.
 *
 * This is effectively a tight coupling between the internal Redux state and that
 * TWSecretSettingsTextInput component, and this whole situation would be pretty awful
 * if applied to any other component or repeated in any other area of the app, but for
 * this one particular situation it makes it very easy to quickly set up screens.
 */

const superSecretConfigDataSelector = createSelector(
  [
    allBackendConfigLayerAttributesSelector,
    allBackendConfigLayerValuesSelector,
    allFormatConfigLayerAttributesSelector,
    allFormatConfigLayerValuesSelector,
    allThemeConfigLayerAttributesSelector,
    allThemeConfigLayerValuesSelector,
    allUiSettingsConfigLayerAttributesSelector,
    allUiSettingsConfigLayerValuesSelector,
  ],
  (
    allBackendConfigLayerAttributes,
    allBackendConfigLayerValues,
    allFormatConfigLayerAttributes,
    allFormatConfigLayerValues,
    allThemeConfigLayerAttributes,
    allThemeConfigLayerValues,
    allUiSettingsConfigLayerAttributes,
    allUiSettingsConfigLayerValues,
  ) => ({
    // We're really just smushing everything back together into a structure that looks
    // suspiciously similar to the original shape in Redux, but with the StoreConfig
    // added for good measure.
    backendConfig: {
      layerNames: backendConfigStoreConfig.layerNames,
      attributes: allBackendConfigLayerAttributes,
      values: allBackendConfigLayerValues,
    },
    formatConfig: {
      layerNames: formatConfigStoreConfig.layerNames,
      attributes: allFormatConfigLayerAttributes,
      values: allFormatConfigLayerValues,
    },
    themeConfig: {
      layerNames: themeConfigStoreConfig.layerNames,
      attributes: allThemeConfigLayerAttributes,
      values: allThemeConfigLayerValues,
    },
    uiSettingsConfig: {
      layerNames: uiSettingsConfigStoreConfig.layerNames,
      attributes: allUiSettingsConfigLayerAttributes,
      values: allUiSettingsConfigLayerValues,
    },
  }),
);

/**
 * This will route to one of the config-specific applyDebugOverrideAction constructors:
 * this is just a handy router so that Secret Settings screens can all use the same one.
 */
const debugOverrideActionsByConfigGroup = {
  backendConfig: applyBackendConfigDebugOverrideAction,
  formatConfig: applyFormatConfigDebugOverrideAction,
  themeConfig: applyThemeConfigDebugOverrideAction,
  uiSettingsConfig: applyUiSettingsConfigDebugOverrideAction,
};
const superSecretConfigDebugOverrideAction = (configGroup, debugOverrideValues) => {
  const debugOverrideAction = debugOverrideActionsByConfigGroup[configGroup];
  if (debugOverrideAction) {
    return debugOverrideAction(debugOverrideValues);
  }
  console.warn('Invalid configGroup for debugOverrideAction: ', configGroup);
  return null;
};

export { superSecretConfigDataSelector, superSecretConfigDebugOverrideAction };
