import { pythonApiBackend } from '@tw/backends';
import { ConfigContainerType, SessionRequestCreateValues } from '@tw/types';

import academicServiceStoreConfig from '../academicServiceStoreConfig';
import tutorSessionRequestModel from './tutorSessionRequestModel';

const tutorSessionRequestOperations = {
  createTutorSessionRequest(
    personId: number,
    teamId: number,
    fieldValues: SessionRequestCreateValues,
    configContainer: ConfigContainerType,
  ) {
    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: 'tutoring/session_requests',
        debugLabel: 'createTutorSessionRequest',
        includeAuthenticationHeader: true,
        personId,
        teamId,
      },
      {
        // fetch options
        method: 'POST',
        body: tutorSessionRequestModel.convertDataForTutorSessionRequest(fieldValues),
      },
      configContainer,
    );
  },
  updateTutorSessionRequest(
    orgPersonId: React.ReactText,
    teamId: React.ReactText,
    tutorSessionRequestId: React.ReactText,
    value,
    configContainer: ConfigContainerType,
  ) {
    const body = [
      {
        op: 'replace',
        path: '/status',
        value,
      },
    ];

    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: `tutoring/session_requests/${tutorSessionRequestId}`,
        debugLabel: 'updateTutorSessionRequest',
        includeAuthenticationHeader: true,
        personId: orgPersonId,
        teamId,
      },
      {
        // fetch body
        method: 'PATCH',
        body,
      },
      configContainer,
    );
  },
};

export default tutorSessionRequestOperations;
