export const APPLY_DEVICE_CONFIG = 'I18N_CONFIG__APPLY_DEVICE_I18N_CONFIG';
export const APPLY_DEBUG_OVERRIDE = 'I18N_CONFIG__APPLY_DEBUG_OVERRIDE';
export const CLEAR_DEBUG_OVERRIDE = 'I18N_CONFIG__CLEAR_DEBUG_OVERRIDE';

function applyDeviceI18NConfigAction(i18nConfigValues) {
  return {
    type: APPLY_DEVICE_CONFIG,
    i18nConfigValues,
  };
}

/**
 * By contract, each service has an applyDebugOverrideAction, for use in Secret Settings ONLY.
 * See superSecretConfigData if you want to go down that rabbit hole.
 */
function applyDebugOverrideAction(debugOverrideValues) {
  return {
    type: APPLY_DEBUG_OVERRIDE,
    debugOverrideValues,
  };
}

function clearDebugOverrideAction() {
  return {
    type: CLEAR_DEBUG_OVERRIDE,
  };
}

// And we're also going to export a constructor for each of the actions
export { applyDeviceI18NConfigAction, applyDebugOverrideAction, clearDebugOverrideAction };
