import { useRef } from 'react';
import * as React from 'react';
import { Dayjs } from 'dayjs';

import { noopFn } from '@tw/constants';

import TimePicker, { TimePickerProps } from './TimePicker';

interface Props {
  popupContainerId: string;
  testID?: string;
}

const TWInputTimePickerDayjs: React.FC<Props & TimePickerProps> = ({
  popupContainerId,
  testID,
  use12Hours,
  onChange = noopFn,
  ...rest
}) => {
  const inputElement = useRef(null);

  const onOpenChange = (isOpen) => {
    if (!isOpen) {
      // inputElement.current.blur();
    }
  };

  // this allows us to update the value even if the user doesn't click on the "ok" button in the dropdown
  const onSelect = (e: Dayjs) => {
    onChange(e, e.format());
  };

  return (
    <TimePicker
      onSelect={onSelect}
      onChange={onChange}
      onOpenChange={onOpenChange}
      data-testid={testID}
      getPopupContainer={() => document.getElementById(popupContainerId)}
      use12Hours={use12Hours}
      ref={inputElement}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default TWInputTimePickerDayjs;
