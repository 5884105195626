import _ from 'lodash';
import { StyleType } from '../../presentational/definitions';
import { ThemeProp } from '../styled';
import { ColorKeys, TextColorsKeys } from '../theme.definitions';

// Receives a number or array of numbers and returns an object with the margin
// property. The margin values are mapped over the baseUnit from the theme. Can
// also receive a string for values like "auto"
export interface ThemeMarginProps {
  twMargin?: (number | string) | (number | string)[];
  twMarginTop?: number | string;
  twMarginRight?: number | string;
  twMarginBottom?: number | string;
  twMarginLeft?: number | string;
}
export const themedMargin = ({
  theme,
  twMargin,
  twMarginTop = 0,
  twMarginRight = 0,
  twMarginBottom = 0,
  twMarginLeft = 0,
}: ThemeProp & ThemeMarginProps): { margin: string } => {
  const margins = _.isUndefined(twMargin)
    ? [twMarginTop, twMarginRight, twMarginBottom, twMarginLeft]
    : _.castArray(twMargin);

  const convertToCss = (amount) =>
    typeof amount === 'string' ? amount : `${amount * theme.baseUnit}px`;

  const marginCss = _.map(margins, convertToCss).join(' ');
  return { margin: marginCss };
};

// Receives a number or array of numbers and returns an object with the padding
// property. The padding values are mapped over the baseUnit from the theme. Can
// also receive a string for values like "auto"
export interface ThemePaddingProps {
  twPadding?: (number | string) | (number | string)[];
  twPaddingTop?: number | string;
  twPaddingRight?: number | string;
  twPaddingBottom?: number | string;
  twPaddingLeft?: number | string;
}
export const themedPadding = ({
  theme,
  twPadding,
  twPaddingTop = 0,
  twPaddingRight = 0,
  twPaddingBottom = 0,
  twPaddingLeft = 0,
}: ThemeProp & ThemePaddingProps): { padding: string } => {
  const paddings = _.isUndefined(twPadding)
    ? [twPaddingTop, twPaddingRight, twPaddingBottom, twPaddingLeft]
    : _.castArray(twPadding);

  const convertToCss = (amount) =>
    typeof amount === 'string' ? amount : `${amount * theme.baseUnit}px`;

  const paddingCss = _.map(paddings, convertToCss).join(' ');
  return { padding: paddingCss };
};

// Returns a dynamic font size based on the the theme fontUnit
export interface ThemeFontSizeProps {
  twFontSize?: number | string;
}
export const themedFontSize = ({
  theme,
  twFontSize = 1,
}: ThemeProp & ThemeFontSizeProps): { fontSize: string } => ({
  fontSize: typeof twFontSize === 'string' ? twFontSize : `${theme.fontUnit * twFontSize}px`,
});

// Returns a font weight based on a named font weight. Font names must match the
// theme.fontWeight object
export interface ThemeFontWeightProps {
  twFontWeight?: 'regular' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black';
}
export const themedFontWeight = ({
  theme,
  twFontWeight = 'regular',
}: ThemeProp & ThemeFontWeightProps): { fontWeight: number } => ({
  fontWeight: theme.fontWeight[twFontWeight],
});

// Returns a twTheme color
export interface ThemeColorProps {
  twColor?: ColorKeys;
}
export const themedColors = ({
  theme,
  twColor = 'text',
}: ThemeProp & ThemeColorProps): { color: string } => ({
  color: theme.colors[twColor],
});

// Returns a twTheme color from the text color keys
export interface ThemeTextColorProps {
  twColor?: TextColorsKeys;
}
export const themedTextColors = ({
  theme,
  twColor = 'text',
}: ThemeProp & ThemeTextColorProps): { color: string } => ({
  color: theme.colors[twColor],
});

// Returns styles that truncates the text
export interface TruncateProps {
  truncate?: boolean;
}
export const truncatedText = ({ truncate = false }: ThemeProp & TruncateProps): StyleType =>
  truncate && {
    flex: '0 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 0,
  };

export interface WrappingProps {
  wrapping?: boolean;
}

export const wrappingText = ({ wrapping = false }: WrappingProps): StyleType =>
  wrapping && {
    display: 'block',
    wordWrap: 'break-word',
  };
