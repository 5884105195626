import { useState, useEffect } from 'react';
import { useFetchTmpDateTypesQuery, useFetchTmpDatesByTypeLazyQuery } from '@tw/generated';
import { TWMessage } from '@tw/components';

interface DatesByTmpYearReturnType {
  loading: boolean;
  tmpYearDatesByYearId: Record<string, [string, string]>;
}

export function useDatesByTmpYear(): DatesByTmpYearReturnType {
  const [academicYearDateTypeId, setAcademicYearDateTypeId] = useState<string | null>(null);
  const [tmpYearDatesByYearId, setTmpYearDatesByYearId] = useState<
    Record<string, [string, string]>
  >({});

  const { data: tmpDates, loading: loadingTmpDates } = useFetchTmpDateTypesQuery({
    variables: {},
    onError: (error) => TWMessage.errorHandler(error),
  });

  const [fetchTmpDatesByType, { data: datesByType, loading: loadingTmpDatesByType }] =
    useFetchTmpDatesByTypeLazyQuery({ onError: (error) => TWMessage.errorHandler(error) });

  useEffect(() => {
    if (tmpDates) {
      const academicYearType = tmpDates?.tmpDateTypes?.edges?.find(
        (tmpDateType) => tmpDateType?.node?.code === 'academicYear',
      )?.node;
      if (academicYearType) {
        setAcademicYearDateTypeId(academicYearType.tmpDateTypeId);
      }
    }
  }, [tmpDates]);

  useEffect(() => {
    if (academicYearDateTypeId) {
      fetchTmpDatesByType({
        variables: {
          tmpDateTypeId: academicYearDateTypeId,
        },
      });
    }
  }, [academicYearDateTypeId, fetchTmpDatesByType]);

  useEffect(() => {
    if (datesByType) {
      const tmpYearDatesByYear = datesByType.tmpDates?.edges.reduce((acc, tmpDate) => {
        if (tmpDate?.node?.tmpYearId && tmpDate.node?.startDate && tmpDate.node.endDate) {
          return {
            ...acc,
            [String(tmpDate.node?.tmpYearId)]: [tmpDate.node?.startDate, tmpDate.node.endDate],
          };
        }
        return acc;
      }, {} as Record<string, [string, string]>) as Record<string, [string, string]>;
      setTmpYearDatesByYearId(tmpYearDatesByYear);
    }
  }, [datesByType]);

  return { loading: loadingTmpDates || loadingTmpDatesByType, tmpYearDatesByYearId };
}
