// This file provides styling-related variables that get consumed by all the components
// throughout the UI.
//
// There are two conceptual groups of values:
//  * "source" values are semantic and global-oriented, used throughout many different components.
//    They generally represent branding colors and overall themes for how things look.
//    They are consumed ONLY by the "component" values.
//
//  * "component" values actually show up on the screen, and they tend to be component- or
//    componentType-specific. In general they pull in the "source" values (but not always).
//
// The overall idea is that you can do 90% of theming just by changing the "source" values
// (since each source value can be pulled into many different component values), and for any
// extra-special customizations you can go a step further and override the component values.

// @TODO: https://www.npmjs.com/package/color for color manipulation -- e.g., to lighten/darken
// some of the sourceValues so that we can get reasonable defaults without having to specify
// exactly values for everything.

import { GOLDEN_RATIO, GOLDEN_RATIO_INVERSE } from '@tw/constants';

import * as TWInputTheming from './TWInputTheming';
import * as TWAvatarImageTheming from './TWAvatarImageTheming';
import * as TWButtonTheming from './TWButtonTheming';
import * as TWIconTheming from './TWIconTheming';
import * as TWLightboxTheming from './TWLightboxTheming';
import * as TWListItemTheming from './TWListItemTheming';
import * as TWMessagingTheming from './TWMessagingTheming';
import * as TWPageContainerTheming from './TWPageContainerTheming';
import * as TWPrimaryTabsTheming from './TWPrimaryTabsTheming';
import * as TWSecondaryTabsTheming from './TWSecondaryTabsTheming';
import * as TWSectionTheming from './TWSectionTheming';
import * as TWTopbarTheming from './TWTopbarTheming';
import * as TWSideMenu from './TWSideMenuTheming';

const categoriesTheming = {
  ...TWAvatarImageTheming,
  ...TWButtonTheming,
  ...TWIconTheming,
  ...TWInputTheming,
  ...TWLightboxTheming,
  ...TWListItemTheming,
  ...TWMessagingTheming,
  ...TWPageContainerTheming,
  ...TWPrimaryTabsTheming,
  ...TWSecondaryTabsTheming,
  ...TWSectionTheming,
  ...TWTopbarTheming,
  ...TWSideMenu,
};

/*
 * Source Values
 */

// colors recieved from the backend
const $brandColor = {
  headerBGColor: '#343841',
  headerLinkColor: '#A1C738',
  headerTextColor: '#ffffff',
  primaryColor: '#A1C738',
  primaryLighterColor: '#86939e',
  primaryLightestColor: '#e2e2e2',
};

const $updatedColors = {
  /*
  Design colors from Figma
  https://www.figma.com/file/G0fiSVc5zSAS6lFtQRyqS3jV/Web?node-id=66%3A0
  These should be the only colors used in React components. This should
  eventually replace $colorHue and $color.
  */
  white: '#FFFFFF',
  background: '#FAFBFC',
  highlight: '#F5F9FF',
  border: 'rgba(66, 81, 130, 0.2)',

  text: '#31363B',
  secondary: '#6E7983',
  heading: '#181A1C',
  icon: 'rgba(66, 75, 105, 0.7)',
  lightIcon: 'rgba(66, 75, 105, 0.45)',
  iconHover: '#474D54',

  link: 'rgba(15, 50, 169, 0.9)',
  linkHover: '#0B257C',
  linkActive: '#091E63',
  linkIcon: 'rgba(15, 50, 169, 0.7)',

  buttonBorder: 'rgba(66, 81, 130, 0.35)',
  buttonText: 'rgba(66, 75, 105, 0.9)',
  primaryButtonBackground: '#6C7182',
  primaryButtonText: '#FFFFFF',
  focus: '#0035EA',

  positive: '#21A700',
  positiveIcon: 'rgba(33, 167, 0, 0.8)',
  negative: '#DC1721',
  negativeIcon: 'rgba(220, 23, 33, 0.8)',
  warning: '#DB7600',
  warningIcon: 'rgba(219, 118, 0, 0.8)',
  yellowIcon: '#FFB802',

  positiveBackground: '#F6FFED',
  positiveBorder: '#B7EB8F',
  negativeBackground: '#FFF1F0',
  negativeBorder: '#FFA39E',
  warningBackground: '#FFFBE6',
  warningBorder: '#FFE58F',
  infoBackground: '#E6F7FF',
  infoBorder: '#91D5FF',

  brand: '#649900',
  brand80: 'rgba(100, 153, 0, 0.8)',
  brandHighlight: 'rgba(100, 153, 0, 0.05)',

  attention: '#BB3E45',
  compliance: '#9122d5',
  slateBackground: '#343840',
  selectedBackground: '#e9edf7',
  hoverBackground: '#8EA6CB26',
};

// Pill Colors - these are specific to pills and match the provided designs
const $pillColors = {
  default: {
    backgroundColor: '#E4E9EE',
    color: '#354658',
  },
  attention: {
    backgroundColor: '#FFDCFE',
    color: '#70006D',
  },
  primary: {
    backgroundColor: '#E3F2FF',
    color: '#004890',
  },
  canary: {
    backgroundColor: '#FDFFCC',
    color: '#656706',
  },
  success: {
    backgroundColor: '#D9FFC2',
    color: '#166D00',
  },
  warning: {
    backgroundColor: '#FFE6D5',
    color: '#854800',
  },
  danger: {
    backgroundColor: '#FFE5E5',
    color: '#980000',
  },
};

// NOTE: DO NOT USE $colorHue ON WEB! use the colors in updatedColors above.
// if you see a color in a design that's not in updatedColors, ask John L to pick or edit a color
// from his list of swatches, then add it to updatedColors.
const $colorHue = {
  gray: '#e2e2e2',
  grayDark: '#444444',
  grayDarkTranslucent: '#444444AA',
  grayVeryDark: '#363636',
  grayExtremelyDark: '#282828',
  grayLight: '#f1f1f1',
  grayMid: '#d3dce2',
  grayVeryLight: '#f5f8fa',
  grayExtremelyLight: '#fbfbfb',
  grayBlue: '#86939e',
  grayPale: '#eff1f4',
  graySilver: '#bdc6cf',
  blueLight: '#39ADD1',
  red: '#f63300',
  redDark: '#be2a3c',
  redVeryDark: '#CC3447',
  greenBooger: '#A1C738',
  gold: '#f7db2a',
  black: '#000000',
  white: '#ffffff',
  whiteTranslucent: '#ffffffAA',
  transparent: 'transparent',
};

const $color = {
  boxShadow: 'rgba(0, 0, 0, 0.2)',
  brandHighlight: '$brandColor.primaryColor', // official Teamworks green, by default used for icons
  brandFieldPrimary: '$brandColor.headerBGColor', // Nick's dark gray, by default used for the topbar
  brandFieldSecondary: '$brandColor.primaryLightestColor', // official Teamworks gray, by default used for the tabs

  background: '$updatedColors.background', // Nick's pale gray, by default used for all pages' backgrounds
  backgroundStrong: '$colorHue.grayVeryLight', // by default used for backgrounds of headers
  backgroundInverse: '$colorHue.grayDark', // official Teamworks secondary color, by default used for the sidebar
  backgroundInverseStrong: '$colorHue.grayVeryDark', // by default used for header backgrounds
  backgroundTranslucent: '$colorHue.grayDarkTranslucent', // used for the overlay backgrounds, like for the lightbox
  borderStrong: '$colorHue.graySilver', // grey for the borders in some lists
  foreground: '$colorHue.white', // fill color for blocks that cover the background
  border: '$colorHue.grayMid', // from Nick's mockups

  text: '$colorHue.grayDark', // official Teamworks secondary color (again)
  textStrong: '$colorHue.grayDark', // official Teamworks secondary color (again)
  textWeak: '$colorHue.grayBlue', // Nick's blush-gray color
  textVeryWeak: '$colorHue.graySilver', // Nick's silver color
  textInverse: '$colorHue.white',
  link: 'rgba(15, 50, 169, 0.9)',
  linkHover: 'rgba(15, 50, 169, 0.05)',
  // no textInverseStrong or textInverseWeak are needed/used anywhere, yet

  danger: '$colorHue.redDark',
  validationPass: '$colorHue.greenBooger',
  validationFail: '$colorHue.redVeryDark',
  dueAlert: '$colorHue.red',

  drawerHeaderBackground: '$updatedColors.white',
  drawerHeaderText: '$updatedColors.secondary',
  drawerPersonName: '$updatedColors.text',
  attributeLabel: '$updatedColors.secondary',
  attributeIcon: '$updatedColors.lightIcon',
  tableHeader: '$updatedColors.secondary',

  // status colors
  atRisk: '$updatedColors.negative',
  onTrack: '$updatedColors.positive',

  // workflow state colors
  success: '$colorHue.greenBooger',
  failed: '$colorHue.red',

  icon: 'rgba(66, 75, 105, 0.7)',
};

const $borderWidth = {
  large: 2,
  medium: 1,
  small: 1,
};

const $fontFamily = {
  header: 'Roboto',
  body: 'Roboto',
};

const $fontSize = {
  xlarge: 24,
  large: 18,
  medium: 14,
  small: 13,
  xsmall: 12,

  listItem: 17,
};

const $headings = {
  hero: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 32,
    lineHeight: '43px',
    letterSpacing: -0.4,
  },
  h1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '37px',
    letterSpacing: -0.3,
  },
  h2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '33px',
    letterSpacing: -0.2,
  },
  h3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '29px',
    letterSpacing: -0.2,
  },
};

$headings.native = {
  hero: {
    ...$headings.hero,
    fontFamily: 'Roboto',
    lineHeight: 43,
    fontWeight: String($headings.hero.fontWeight),
  },
  h1: {
    ...$headings.h1,
    fontFamily: 'Roboto',
    lineHeight: 37,
    fontWeight: String($headings.h1.fontWeight),
  },
  h2: {
    ...$headings.h2,
    fontFamily: 'Roboto',
    lineHeight: 33,
    fontWeight: String($headings.h2.fontWeight),
  },
  h3: {
    ...$headings.h3,
    fontFamily: 'Roboto',
    lineHeight: 29,
    fontWeight: String($headings.h3.fontWeight),
  },
};

const $text = {
  default: {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
  },
};

$text.native = {
  default: {
    ...$text.default,
    fontFamily: 'Roboto',
    lineHeight: 20,
  },
};

const $secondaryText = {
  default: {
    ...$text.default,
    color: $updatedColors.secondary,
  },
};

$secondaryText.native = {
  default: {
    ...$text.native.default,
    color: $updatedColors.secondary,
  },
};

const $fontSizeNew = {
  size1: 4,
  size2: '$fontSizeNew.size1 * 2',
  size3: '$fontSizeNew.size1 * 3',
  size4: '$fontSizeNew.size1 * 4',
  size5: '$fontSizeNew.size1 * 5',
  size6: '$fontSizeNew.size1 * 6',
  size7: '$fontSizeNew.size1 * 7',
  size8: '$fontSizeNew.size1 * 8',
};

const $fontWeight = {
  thin: '100',
  extraLight: '200',
  light: '300',
  normal: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
};

const $iconSize = {
  xlarge: 36,
  large: 30,
  medium: 24,
  small: 20,
  navIconSize: 26,
};

const $lineHeight = {
  xlarge: 36,
  large: 28,
  medium: 22,
  small: 20,
  xsmall: 18,
};

// Whitespace between elements
const $whitespaceOld = {
  xlarge: 28,
  large: 17,
  medium: 9,
  small: 5,
  xsmall: 2,
};

// value-set for z-indexes
const $overlay = {
  low: 5,
  high: 10,
  veryHigh: 15,
};

// These must be set manually as part of app init, and on orientation change or window resize
const $screenDimensions = {
  appBarHeight: 0,
  statusBarHeight: 0,
  width: 0,
  height: 0,
};

const initialSpace = 4;
const $whitespace = {
  space1: initialSpace,
  space2: initialSpace * 2,
  space3: initialSpace * 3,
  space4: initialSpace * 4,
  space5: initialSpace * 5,
  space6: initialSpace * 6,
  space7: initialSpace * 7,
  space8: initialSpace * 8,
  space9: initialSpace * 9,
  space10: initialSpace * 10,
  space14: initialSpace * 14,
  space16: initialSpace * 16,
  space25: initialSpace * 25,
  space50: initialSpace * 50,
};

const allSourceValues = {
  $borderWidth,
  $brandColor,
  $color,
  $colorHue,
  $fontFamily,
  $fontSize,
  $fontSizeNew,
  $fontWeight,
  $headings,
  $text,
  $secondaryText,
  $lineHeight,
  $whitespaceOld,
  $whitespace,
  $iconSize,
  $overlay,
  $screenDimensions,
  $updatedColors,
  $pillColors,
};

// The intermediate 'allSourceValues' and 'allComponentValues' are just there to make this export
// look cleaner (and because they're occasionally useful to just dump to console)
export default {
  // Some handy global vars for calculations
  $goldenRatio: GOLDEN_RATIO,
  $goldenRatioInverse: GOLDEN_RATIO_INVERSE,

  $touchableEffectColor: '$brandColor.primaryColor',

  // To enable react-native-extended-stylesheet's outlines (for debugging), you can
  // uncomment this line:
  // $outline: 1,
  ...allSourceValues,
  ...categoriesTheming,
};
