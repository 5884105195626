import { datadogRum } from '@datadog/browser-rum';
import { GroupType } from '@tw/generated';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
// NOTE: This will be fixed in future localization work. For now, ensure en-gb is imported after es to avoid spanish being set as the default locale
import 'moment/locale/es';
import 'moment/locale/en-gb';

import { GenderDataType, GenderOption } from '@tw/@types/definitions';
import { GENDERS, compoundFormatNames, dateAndTimeFormatNames } from '@tw/constants';
import { getTranslation } from '@tw/i18n';
import { ConfigContainerType } from '@tw/types';

/**
 * If possible, returns a MomentJS instance for incomingDate.
 * Note that it is NOT guaranteed to be valid: the caller should check moment.isValid
 * before trying to use it.
 *
 * Note that this is one of the few places where configContainer is optional
 */
const convertValueToMoment = (
  incomingDate: Moment | Date | string,
  configContainer: ConfigContainerType,
) => {
  if (!incomingDate) {
    return null;
  }

  if (moment.isMoment(incomingDate)) {
    return incomingDate;
  }

  if (incomingDate instanceof Date) {
    return moment(incomingDate);
  }

  if (_.isString(incomingDate) && configContainer) {
    // We'll try to parse it using one of our known formats, if we have them;
    // otherwise it'll be parsed just like anything else.
    const dateAndTimeConfig = configContainer.formatConfig.dateAndTime;
    return moment(incomingDate, [
      dateAndTimeConfig.backendDate,
      dateAndTimeConfig.backendDateTime,
      dateAndTimeConfig.shortDate,
      dateAndTimeConfig.shortTime,
    ]);
  }

  // If all else fails, let's just hand it to MomentJS and hope for the best
  return moment(incomingDate);
};

const getDayOfWeek = (dayIndex: number, configContainer: ConfigContainerType) => {
  moment.locale(_.get(configContainer, 'i18nConfig.activeLanguage'));
  return moment.weekdays(dayIndex);
};

export type DayOfWeekAbbreviation = 'U' | 'M' | 'T' | 'W' | 'R' | 'F' | 'S' | 'Online';

const getDayOfWeekAbbreviation = (dayString: string): DayOfWeekAbbreviation => {
  const days = {
    sunday: 'U',
    monday: 'M',
    tuesday: 'T',
    wednesday: 'W',
    thursday: 'R',
    friday: 'F',
    saturday: 'S',
    online: 'Online',
  };

  return days[dayString];
};

/*
* We use this for "compound" date/time values that contain dates and times
* the user's locale determines date format, and the time format preference determines the time values.
* So we need to keep those separate in the configValues (so each layer can set what it needs),
* and then join the values from configContainer here

* This function takes a the list of parts of dateAndTimeConfig (i.e. keys) and returns a formatName made from
* joining the values for those keys by commas.
*/
const combineFormatParts = (formatPartsList: string[], dateAndTimeConfig) =>
  formatPartsList.map((formatName) => _.get(dateAndTimeConfig, formatName)).join(', ');

const formatMomentAsString = (
  incomingDate: Moment | Date | string,
  formatName: string,
  configContainer: ConfigContainerType,
) => {
  if (!configContainer || !configContainer.formatConfig) {
    console.error('Hey, you forgot to pass configContainer to formatMomentAsString!', {
      incomingDate,
      formatName,
      configContainer,
    });
    return 'error';
  }
  const dateAndTimeConfig = configContainer.formatConfig.dateAndTime;

  let formatType;

  const formatParts = compoundFormatNames[formatName];

  if (formatParts) {
    formatType = combineFormatParts(formatParts, dateAndTimeConfig);
  } else {
    formatType = _.get(dateAndTimeConfig, formatName);
  }

  if (!formatType) {
    console.error('Invalid formatName for formatMomentAsString!', {
      incomingDate,
      formatName,
      configContainer,
    });
    return 'error';
  }

  const momentFromDate = convertValueToMoment(incomingDate, configContainer);

  if (!momentFromDate) {
    return dateAndTimeConfig.whenEmpty;
  }
  if (!momentFromDate.isValid()) {
    return dateAndTimeConfig.whenInvalid;
  }

  // Woot! All good
  momentFromDate.locale(_.get(configContainer, 'i18nConfig.activeLanguage'));
  return momentFromDate.format(formatType);
};

// This is really just creating a bunch of partial functions (where formatName is pre-set for each)
const formatFunctions = {};
_.forEach(dateAndTimeFormatNames, (formatName) => {
  formatFunctions[formatName] = (
    date: Moment | Date | string,
    configContainer: ConfigContainerType,
  ) => formatMomentAsString(date, formatName, configContainer);
});

const daySorter = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  online: 7,
};

function sortByDayOfWeek<T>(array: T[], prop: string): T[] {
  return array.sort((a, b) => {
    const day1 = a[prop].toLowerCase();
    const day2 = b[prop].toLowerCase();
    return daySorter[day1] - daySorter[day2];
  });
}

const humanizeMinutes = (minutes: number, defaultText = '') => {
  // defaultText is shown if minutes is 0 or falsy, so we can chose what to render in that case
  // in different locations. E.g. '0h' for cara signoff total row (see TW-16161)
  if (!minutes || minutes === 0) {
    return defaultText;
  }
  // transforms a total # of minutes into '4h 22m' format
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  let output = '';
  if (hours) output += `${hours}h`;
  if (hours && remainingMinutes) output += ' ';
  if (remainingMinutes) output += `${remainingMinutes}m`;
  return output;
};

/**
 * Finds gender option constant based on short value 'M', 'F', 'N', etc. Utilize to get translation namespace.
 * @param  {GenderDataType|string} shortGenderValue - Gender short value 'M', 'F', 'N' saved in database.
 * @returns {GenderOption|undefined}
 */
const getGenderOption = (shortGenderValue: GenderDataType | string): GenderOption | undefined => {
  const gender = GENDERS.find(
    ({ shortValue, value }) =>
      shortValue === shortGenderValue.toUpperCase() || value === shortGenderValue.toUpperCase(),
  );
  if (!gender && shortGenderValue.trim() !== '') {
    const error = `Invalid shortGenderValue of "${shortGenderValue}" passed into formatUtils.getGenderOption!`;
    datadogRum.addError(new Error(error));
  }
  return gender;
};

const getSelectionGroupLabel = (groupType?: GroupType, label = '') => {
  if (label.startsWith('Academic') || label.startsWith('Athletic')) {
    return label;
  }
  switch (groupType) {
    case GroupType.AcademicYear:
      return `${getTranslation('academics', 1)} ${label}`;
    case GroupType.AthleticYear:
      return `${getTranslation('athletic')} ${label}`;
    default:
      return label;
  }
};

const formatDateWithTimezone = (date: string, dateTimeFormat: string, timezone?: string) => {
  if (!date) {
    return '';
  }

  const messageDateTime = moment.tz(moment.utc(date), timezone ?? moment.tz.guess());

  return messageDateTime.format(dateTimeFormat);
};

export default {
  formatDateWithTimezone,
  convertValueToMoment,
  daySorter,
  getDayOfWeek,
  getDayOfWeekAbbreviation,
  sortByDayOfWeek,
  humanizeMinutes,
  getGenderOption,
  getSelectionGroupLabel,
  ...formatFunctions,
};
