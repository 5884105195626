import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Term, GraphQLPaginatedResponse, TermQueryFilters } from '@tw/types';
import { graphqlUtils } from '@tw/util';

import allTermsQuery from './allTermsQuery.graphql';

interface TermsGraphQLResponse {
  terms: GraphQLPaginatedResponse<Term>;
}

const useAllTerms = (filters?: TermQueryFilters, skip?: boolean) => {
  const { loading, error, data } = useQuery<TermsGraphQLResponse>(allTermsQuery, {
    variables: filters,
    skip: skip || false,
  });

  const termData = useMemo(
    () => (data ? graphqlUtils.convertEdgesToArray(data.terms.edges) : null),
    [data],
  );

  return {
    loading,
    error,
    data: termData,
  };
};

export default useAllTerms;
