/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTicketingEventsDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type FetchTicketingEventsDetailQuery = {
  ticketingEvent?:
    | {
        id: string;
        eventLabel?: string | null;
        eventDateTime?: any | null;
        eventTimeZone?: string | null;
        windowCloseDateTime?: any | null;
        windowOpenDateTime?: any | null;
        teamId: number;
        totalTransferred?: number | null;
        totalAllocated?: number | null;
        totalReserved?: number | null;
        eventTotals?: {
          athleteAllocated?: number | null;
          athleteReserved?: number | null;
          nonAthleteReserved?: number | null;
          nonAthleteAllocated?: number | null;
        } | null;
        allocations?: Array<{
          defaultAllocatedAmount?: number | null;
          poolCapAmount?: number | null;
          preventTransfers?: boolean | null;
          selectable?:
            | {
                groupType?: Types.GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
                pluralLabel?: string | null;
                team?: { label: string } | null;
              }
            | {
                groupType?: Types.GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
                pluralLabel?: string | null;
                team?: { label: string } | null;
              }
            | {
                groupType?: Types.GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
                pluralLabel?: string | null;
                team?: { label: string } | null;
              }
            | {
                groupType?: Types.GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
                pluralLabel?: string | null;
                team?: { label: string } | null;
              }
            | null;
        } | null> | null;
      }
    | {}
    | null;
};

export const FetchTicketingEventsDetailDocument = gql`
  query FetchTicketingEventsDetail($id: ID!) {
    ticketingEvent: node(id: $id) {
      ... on TicketingEvent {
        id
        eventLabel
        eventDateTime
        eventTimeZone
        windowCloseDateTime
        windowOpenDateTime
        teamId
        totalTransferred
        totalAllocated
        totalReserved
        eventTotals {
          athleteAllocated
          athleteReserved
          nonAthleteReserved
          nonAthleteAllocated
        }
        allocations {
          selectable {
            groupType
            label
            selectionCode
            pluralLabel
            team {
              label
            }
          }
          defaultAllocatedAmount
          poolCapAmount
          preventTransfers
        }
      }
    }
  }
`;

/**
 * __useFetchTicketingEventsDetailQuery__
 *
 * To run a query within a React component, call `useFetchTicketingEventsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTicketingEventsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTicketingEventsDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchTicketingEventsDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchTicketingEventsDetailQuery,
    FetchTicketingEventsDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTicketingEventsDetailQuery, FetchTicketingEventsDetailQueryVariables>(
    FetchTicketingEventsDetailDocument,
    options,
  );
}
export function useFetchTicketingEventsDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTicketingEventsDetailQuery,
    FetchTicketingEventsDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchTicketingEventsDetailQuery,
    FetchTicketingEventsDetailQueryVariables
  >(FetchTicketingEventsDetailDocument, options);
}
export type FetchTicketingEventsDetailQueryHookResult = ReturnType<
  typeof useFetchTicketingEventsDetailQuery
>;
export type FetchTicketingEventsDetailLazyQueryHookResult = ReturnType<
  typeof useFetchTicketingEventsDetailLazyQuery
>;
export type FetchTicketingEventsDetailQueryResult = Apollo.QueryResult<
  FetchTicketingEventsDetailQuery,
  FetchTicketingEventsDetailQueryVariables
>;
