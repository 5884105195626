/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTicketingEventsWithAllocationsQueryVariables = Types.Exact<{
  team?: Types.InputMaybe<Types.Scalars['ID']>;
  eventStartDateTime?: Types.InputMaybe<Types.Scalars['DateTime']>;
  eventEndDateTime?: Types.InputMaybe<Types.Scalars['DateTime']>;
  isArchived?: Types.InputMaybe<Types.Scalars['TWFilter_Boolean']>;
  sort?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.TicketingEventSortEnum>>
    | Types.InputMaybe<Types.TicketingEventSortEnum>
  >;
  withAllocations?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type FetchTicketingEventsWithAllocationsQuery = {
  ticketingEvents?: {
    edges: Array<{
      node?: {
        id: string;
        eventLabel?: string | null;
        eventDateTime?: any | null;
        hasTicketAllocations?: boolean | null;
        allocations?: Array<{
          selectable?:
            | { label?: string | null }
            | { label?: string | null }
            | { label?: string | null }
            | { label?: string | null }
            | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchTicketingEventsWithAllocationsDocument = gql`
  query FetchTicketingEventsWithAllocations(
    $team: ID
    $eventStartDateTime: DateTime
    $eventEndDateTime: DateTime
    $isArchived: TWFilter_Boolean
    $sort: [TicketingEventSortEnum]
    $withAllocations: Boolean = true
  ) {
    ticketingEvents(
      team: $team
      eventStartDateTime: $eventStartDateTime
      eventEndDateTime: $eventEndDateTime
      isArchived: $isArchived
      sort: $sort
    ) {
      edges {
        node {
          id
          eventLabel
          eventDateTime
          allocations @include(if: $withAllocations) {
            selectable {
              label
            }
          }
          hasTicketAllocations
        }
      }
    }
  }
`;

/**
 * __useFetchTicketingEventsWithAllocationsQuery__
 *
 * To run a query within a React component, call `useFetchTicketingEventsWithAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTicketingEventsWithAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTicketingEventsWithAllocationsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      eventStartDateTime: // value for 'eventStartDateTime'
 *      eventEndDateTime: // value for 'eventEndDateTime'
 *      isArchived: // value for 'isArchived'
 *      sort: // value for 'sort'
 *      withAllocations: // value for 'withAllocations'
 *   },
 * });
 */
export function useFetchTicketingEventsWithAllocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTicketingEventsWithAllocationsQuery,
    FetchTicketingEventsWithAllocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchTicketingEventsWithAllocationsQuery,
    FetchTicketingEventsWithAllocationsQueryVariables
  >(FetchTicketingEventsWithAllocationsDocument, options);
}
export function useFetchTicketingEventsWithAllocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTicketingEventsWithAllocationsQuery,
    FetchTicketingEventsWithAllocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchTicketingEventsWithAllocationsQuery,
    FetchTicketingEventsWithAllocationsQueryVariables
  >(FetchTicketingEventsWithAllocationsDocument, options);
}
export type FetchTicketingEventsWithAllocationsQueryHookResult = ReturnType<
  typeof useFetchTicketingEventsWithAllocationsQuery
>;
export type FetchTicketingEventsWithAllocationsLazyQueryHookResult = ReturnType<
  typeof useFetchTicketingEventsWithAllocationsLazyQuery
>;
export type FetchTicketingEventsWithAllocationsQueryResult = Apollo.QueryResult<
  FetchTicketingEventsWithAllocationsQuery,
  FetchTicketingEventsWithAllocationsQueryVariables
>;
