import styled from '@emotion/styled';

import { TWFlexContainer } from '../../../presentational';

export const CheckboxContainer = styled(TWFlexContainer)<{ isEmpty?: boolean }>(
  ({ theme, isEmpty }) => ({
    height: 160,
    background: theme.colors.white,
    borderWidth: theme.borderWidth.large,
    borderColor: theme.colors.border,
    borderStyle: 'solid',
    overflowY: 'auto',
    position: 'relative',
    display: isEmpty ? 'flex' : 'inherit',
  }),
);

export const CheckboxActionsContainer = styled(TWFlexContainer)({
  overflowY: 'auto',
  position: 'relative',
});
