/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CountryCode: any;
  Date: any;
  DateTime: any;
  GenericScalar: any;
  GraphenePendulumDateTime: any;
  JSONString: any;
  TWFilter_AcademicStatus: any;
  TWFilter_AlertNotificationType: any;
  TWFilter_AthleteStatusChangeReason: any;
  TWFilter_Boolean: any;
  TWFilter_BulletinType: any;
  TWFilter_ChangeType: any;
  TWFilter_CustomTaskAssignmentStatus: any;
  TWFilter_CustomTaskStatus: any;
  TWFilter_CustomTaskType: any;
  TWFilter_DateTime: any;
  TWFilter_DaysOfWeekEnum: any;
  TWFilter_EnrollmentAttendanceStatus: any;
  TWFilter_FeatureFlagType: any;
  TWFilter_Float: any;
  TWFilter_FormAssignmentType: any;
  TWFilter_ID: any;
  TWFilter_Int: any;
  TWFilter_IntArray: any;
  TWFilter_JSONString: any;
  TWFilter_ProfessorSurveyAssignmentStatus: any;
  TWFilter_SessionAttendanceStatus: any;
  TWFilter_SessionRequestStatus: any;
  TWFilter_String: any;
  TWFilter_StringArray: any;
  TWFilter_TicketingGuestStatus: any;
  Time: any;
  UUID: any;
  Upload: any;
};

/** An enumeration. */
export enum Ac2ProfessorSortEnum {
  Ac2ProfessorIdAsc = 'ac2_professor_id_asc',
  Ac2ProfessorIdDesc = 'ac2_professor_id_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  EmailAddressAsc = 'email_address_asc',
  EmailAddressDesc = 'email_address_desc',
  FirstNameAsc = 'first_name_asc',
  FirstNameDesc = 'first_name_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  LastNameAsc = 'last_name_asc',
  LastNameDesc = 'last_name_desc',
  OfficeLocationAsc = 'office_location_asc',
  OfficeLocationDesc = 'office_location_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PhoneNumberAsc = 'phone_number_asc',
  PhoneNumberDesc = 'phone_number_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type AbridgedAppointmentNode = {
  countableMinutes?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  tmpActivityLogAppointmentId?: Maybe<Scalars['Int']>;
};

/** Represents whether the student is eligible for athletic activities. */
export enum AcademicStatus {
  Eligible = 'eligible',
  Ineligible = 'ineligible',
  Probation = 'probation',
}

export type ActiveCoreCategoryNode = {
  coreCode?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type ActivityLogSignoff = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  status?: Maybe<Scalars['String']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ActivityLogSignoffInput = {
  approval: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
};

export type ActivityLogSignoffResponseConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivityLogSignoffResponseEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ActivityLogSignoffResponse` and its cursor. */
export type ActivityLogSignoffResponseEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ActivityLogSignoffResponseNode>;
};

export type ActivityLogSignoffResponseNode = {
  /** True when the person has signed off.False when they have refused sign off. Null when pending. */
  approval?: Maybe<Scalars['Boolean']>;
  /** Deprecated (Automatically generated. For the purpose of generating a link that a form with notes can be posted to, signing off (or not) on the log) */
  approvalGuid?: Maybe<Scalars['String']>;
  athleteSummary?: Maybe<AthleteSummaryNode>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  externalAccessLink?: Maybe<ExternalAccessLinkNode>;
  /** Deprecated (Athletes get a link in their email that take them to a signoff page.) */
  externalAccessLinkId?: Maybe<Scalars['Int']>;
  /** When the stage is over, this can no longer be edited. This also cannot be edited until the log is on this stage */
  locked?: Maybe<Scalars['Boolean']>;
  log?: Maybe<TmpActivityLogListNode>;
  /** Any notes that the responder wants to leave as a reason for their response. */
  notes?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The person who has been contacted to sign off on this log. */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  reminderMessage?: Maybe<Message>;
  /** The reminder message associated with this response */
  reminderMessageId?: Maybe<Scalars['Int']>;
  /** The reminder stream message metadata associated with this response */
  reminderStreamMessageMeta?: Maybe<Scalars['JSONString']>;
  stage?: Maybe<TmpActivityLogStage>;
  /** The log this response is associated with */
  tmpActivityLogId: Scalars['Int'];
  tmpActivityLogSignoffResponseId: Scalars['ID'];
  /** The stage associated with this response, so we know how to render it (e.g. if it is editable by the responder). */
  tmpActivityLogStageId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type ActivityNode = Node & {
  action?: Maybe<Scalars['String']>;
  activityDate?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns activityDate from the json data for greater precision (date + time).
   *
   *         For some reason Activity.activity_date is stored as a date in the database instead of a timestamp.
   *
   */
  activityDatetime?: Maybe<Scalars['String']>;
  activityId: Scalars['ID'];
  activityPersons?: Maybe<ActivityPersonNodeConnection>;
  activityType?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isViewed?: Maybe<Scalars['Boolean']>;
  jsonData?: Maybe<Scalars['GenericScalar']>;
  objectId?: Maybe<Scalars['Int']>;
  objectType?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  person?: Maybe<PersonNode>;
  personId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['Int']>;
};

export type ActivityNodeActivityPersonsArgs = {
  activityId?: InputMaybe<Scalars['TWFilter_Int']>;
  activityPersonId?: InputMaybe<Scalars['TWFilter_ID']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isViewed?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<ActivityPersonSortEnum>>>;
};

export type ActivityNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivityNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ActivityNode` and its cursor. */
export type ActivityNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ActivityNode>;
};

/** Exposes ActivityPerson objects */
export type ActivityPersonNode = Node & {
  activity?: Maybe<ActivityNode>;
  activityId?: Maybe<Scalars['Int']>;
  activityPersonId: Scalars['ID'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isViewed: Scalars['Boolean'];
  person?: Maybe<PersonNode>;
  personId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
};

export type ActivityPersonNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivityPersonNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ActivityPersonNode` and its cursor. */
export type ActivityPersonNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ActivityPersonNode>;
};

/** An enumeration. */
export enum ActivityPersonSortEnum {
  ActivityIdAsc = 'activity_id_asc',
  ActivityIdDesc = 'activity_id_desc',
  ActivityPersonIdAsc = 'activity_person_id_asc',
  ActivityPersonIdDesc = 'activity_person_id_desc',
  IsViewedAsc = 'is_viewed_asc',
  IsViewedDesc = 'is_viewed_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
}

export type AddTravelVendorToGroupPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelVendorGroupVendorLink?: Maybe<TravelVendorGroupVendorLinkNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AdditionalResultsVisibilityInput = {
  completersToView?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewResultsInEmail?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewResultsInTeamworks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AdditionalRoleArgsNode = {
  chooserAllowExternal?: Maybe<Scalars['Boolean']>;
  chooserAllowUser?: Maybe<Scalars['Boolean']>;
  chooserRestrictedToPersonTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  chooserRoleOrder?: Maybe<Scalars['Int']>;
  editCompleted?: Maybe<Scalars['Boolean']>;
  externalAssignees?: Maybe<Array<Maybe<ExternalAssignee>>>;
  name?: Maybe<Scalars['String']>;
  personSelection?: Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>;
  roleOrder?: Maybe<Scalars['Int']>;
  roleType?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  countryIso?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  isMailing?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type AddressNode = {
  addressId: Scalars['ID'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryNode>;
  countryIso?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  customStateProvince?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  state?: Maybe<StateNode>;
  stateId?: Maybe<Scalars['Int']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AdminActionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdminActionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AdminAction` and its cursor. */
export type AdminActionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AdminActionNode>;
};

export type AdminActionNode = Node & {
  action: Scalars['String'];
  adminActionId: Scalars['ID'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isAsync?: Maybe<Scalars['Boolean']>;
  log?: Maybe<Scalars['JSONString']>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['JSONString']>;
  pctCompleted?: Maybe<Scalars['Float']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum AdminActionSortEnum {
  ActionAsc = 'action_asc',
  ActionDesc = 'action_desc',
  AdminActionIdAsc = 'admin_action_id_asc',
  AdminActionIdDesc = 'admin_action_id_desc',
  CompletedAtAsc = 'completed_at_asc',
  CompletedAtDesc = 'completed_at_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsAsyncAsc = 'is_async_asc',
  IsAsyncDesc = 'is_async_desc',
  LogAsc = 'log_asc',
  LogDesc = 'log_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ParametersAsc = 'parameters_asc',
  ParametersDesc = 'parameters_desc',
  PctCompletedAsc = 'pct_completed_asc',
  PctCompletedDesc = 'pct_completed_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type AdminBulkImportContactsPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<Array<Maybe<PersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Input model for AdminBulkImportContacts mutation */
export type AdminPersonContactInput = {
  city?: InputMaybe<Scalars['String']>;
  countryIso?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  isGuardian?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  maritalStatusId?: InputMaybe<Scalars['String']>;
  phoneCell?: InputMaybe<Scalars['String']>;
  phoneHome?: InputMaybe<Scalars['String']>;
  phoneOffice?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  relationship: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  twUserEmail: Scalars['String'];
  zip?: InputMaybe<Scalars['String']>;
};

export type AdvisorConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdvisorEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Advisor` and its cursor. */
export type AdvisorEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AdvisorNode>;
};

export type AdvisorNode = Node & {
  advisorId: Scalars['ID'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The customer's record id for an advisor, for reconciling */
  customerKey: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /** The Teamworks Person record for the advisor's user */
  personId: Scalars['Int'];
  /**
   *
   *         Returns a link to the person's profile picture
   *
   */
  pictureDownloadUrl?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum AdvisorSortEnum {
  Ac2AdvisorIdAsc = 'ac2_advisor_id_asc',
  Ac2AdvisorIdDesc = 'ac2_advisor_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  LastNameAsc = 'last_name_asc',
  LastNameDesc = 'last_name_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  PreferredNameAsc = 'preferred_name_asc',
  PreferredNameDesc = 'preferred_name_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Whether and how to notify new assignees of the custom task that they've been assigned one. */
export enum AlertNotificationType {
  Both = 'both',
  Email = 'email',
  None = 'none',
  Sms = 'sms',
}

/** Graphql Wrapper for Appointment Type on the viewer query */
export type AllowedAppointmentType = {
  appointmentTypeId: Scalars['Int'];
  canUserAdd: Scalars['Boolean'];
  color: Scalars['String'];
  complianceLabel?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isDayOff: Scalars['Boolean'];
  isTutoring: Scalars['Boolean'];
  label: Scalars['String'];
  travelOnly: Scalars['Boolean'];
};

export type AnswerInput = {
  answer: Scalars['String'];
  answerType: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  quickformDataStr: Scalars['String'];
};

export type AppAssignmentUpdate = {
  allowedUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  installationId: Scalars['String'];
  revokedUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AppAssignmentsUpdate = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ok?: Maybe<Scalars['Boolean']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppAssignmentsUpdateInput = {
  updates?: InputMaybe<Array<InputMaybe<AppAssignmentUpdate>>>;
};

export type AppTargetUrl = {
  app?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Application = {
  id?: Maybe<Scalars['ID']>;
  installationId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Appointment = Node & {
  appointmentCheckin?: Maybe<AppointmentCheckinNode>;
  appointmentComplianceTypeId?: Maybe<Scalars['Int']>;
  appointmentId: Scalars['ID'];
  appointmentOccurrences?: Maybe<AppointmentOccurrenceNodeConnection>;
  appointmentType?: Maybe<AppointmentType>;
  appointmentTypeId?: Maybe<Scalars['Int']>;
  apptDate?: Maybe<Scalars['DateTime']>;
  attachments?: Maybe<Array<Maybe<FileAttachment>>>;
  attendeeCount?: Maybe<Scalars['Int']>;
  attendees?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  bookedSlotAppointments?: Maybe<Array<Maybe<BaseAppointmentNode>>>;
  bookedSlotParent?: Maybe<BaseAppointmentNode>;
  complianceType?: Maybe<Scalars['String']>;
  countableEndHour?: Maybe<Scalars['Int']>;
  countableEndMinute?: Maybe<Scalars['Int']>;
  countableMinutes?: Maybe<Scalars['Int']>;
  countableStartHour?: Maybe<Scalars['Int']>;
  countableStartMinute?: Maybe<Scalars['Int']>;
  countableTypeId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  daysFromBase?: Maybe<Scalars['Int']>;
  enableCheckins?: Maybe<Scalars['Boolean']>;
  enableRsvps?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  /** Appointment's end date as a pendulum object aware to the appointment's timezone */
  endDatetimeLocal?: Maybe<Scalars['String']>;
  /** Appointment's end date as a pendulum object localized but naive */
  endDatetimeNaive?: Maybe<Scalars['String']>;
  /** Appointment's end date as a pendulum object set timezone aware and then set to UTC */
  endDatetimeUtc?: Maybe<Scalars['String']>;
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  endTimeZone?: Maybe<Scalars['String']>;
  externalAppointmentUid?: Maybe<Scalars['String']>;
  externalCalendarAccountId?: Maybe<Scalars['Int']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityName?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['ID']>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isAllDay: Scalars['Boolean'];
  isAvailability?: Maybe<Scalars['Boolean']>;
  isBookedSlot?: Maybe<Scalars['String']>;
  isClass?: Maybe<Scalars['Boolean']>;
  isCountable?: Maybe<Scalars['Boolean']>;
  isExternal?: Maybe<Scalars['Boolean']>;
  isExternalAppointment?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  isSlot?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  /** Metadata JSONB object */
  meta?: Maybe<Scalars['JSONString']>;
  notes?: Maybe<Scalars['String']>;
  occurrenceCalculationEnd?: Maybe<Scalars['DateTime']>;
  occurrences?: Maybe<Array<Maybe<AppointmentOccurrenceNode>>>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  parentAppointmentId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  recurEnd?: Maybe<Scalars['DateTime']>;
  recurRule?: Maybe<Scalars['String']>;
  recurStart?: Maybe<Scalars['DateTime']>;
  recurType?: Maybe<Scalars['Int']>;
  reminders?: Maybe<Array<Maybe<AppointmentReminder>>>;
  rrule?: Maybe<Scalars['String']>;
  sendRsvpAlerts?: Maybe<Scalars['String']>;
  signupSlot?: Maybe<SlotNode>;
  slotAppointments?: Maybe<Array<Maybe<TutorAppointmentNode>>>;
  slotParentAppointment?: Maybe<TutorAppointmentNode>;
  /** Parent appointment of a child slot appointment */
  slotParentAppointmentId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  /** Appointment's start date as a pendulum object aware to the appointment's timezone */
  startDatetimeLocal?: Maybe<Scalars['String']>;
  /** Appointment's start date as a pendulum object localized but naive */
  startDatetimeNaive?: Maybe<Scalars['String']>;
  /** Appointment's start date as a pendulum object set timezone aware and then set to UTC */
  startDatetimeUtc?: Maybe<Scalars['String']>;
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  startTimeZone?: Maybe<Scalars['String']>;
  /** Alias for the one true timezone to end all other timezone properties */
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  userMetadata?: Maybe<AppointmentUserMetadataNode>;
  var1?: Maybe<Scalars['Int']>;
  var2?: Maybe<Scalars['String']>;
  var3?: Maybe<Scalars['Int']>;
};

export type AppointmentAppointmentOccurrencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentOccurrenceId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  period?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentOccurrenceSortEnum>>>;
  startDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type AppointmentAttachmentBulkCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  fileAttachment?: Maybe<Array<Maybe<FileAttachment>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppointmentAttachmentBulkDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  fileAttachment?: Maybe<FileAttachment>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppointmentAttachmentUploadPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  fileAttachment?: Maybe<Array<Maybe<FileAttachment>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppointmentBulkCopyDateInput = {
  appointmentIds: Array<InputMaybe<Scalars['ID']>>;
  copyToDates: Array<InputMaybe<Scalars['Date']>>;
};

export type AppointmentBulkCopyPayload = {
  appointment?: Maybe<Array<Maybe<TutorAppointmentNode>>>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppointmentBulkDeleteInput = {
  appointmentDeletes: Array<InputMaybe<AppointmentDeleteInput>>;
};

export type AppointmentBulkDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  unauthorizedAppointments?: Maybe<Array<Maybe<TutorAppointmentNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppointmentBulkUpdateInput = {
  appointmentUpdates: Array<InputMaybe<AppointmentUpdateInput>>;
};

export type AppointmentBulkUpdatePayload = {
  appointment?: Maybe<TutorAppointmentNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppointmentCheckinActionInput = {
  actionDatetime: Scalars['DateTime'];
  adminActionType?: InputMaybe<AppointmentCheckinActionType>;
  adminReverseAction?: InputMaybe<Scalars['Boolean']>;
  appointmentCheckinOccurrenceId: Scalars['UUID'];
  notes?: InputMaybe<Scalars['String']>;
  oneTimePassword?: InputMaybe<Scalars['String']>;
  personId: Scalars['Int'];
};

export type AppointmentCheckinActionNode = {
  appointmentCheckinActionId?: Maybe<Scalars['UUID']>;
  appointmentCheckinOccurrenceId?: Maybe<Scalars['UUID']>;
  checkinTimes?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  checkoutTimes?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notes?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId?: Maybe<Scalars['ID']>;
};

export type AppointmentCheckinActionNoteInput = {
  appointmentCheckinOccurrenceId?: InputMaybe<Scalars['UUID']>;
  notes?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['ID']>;
};

/** Used as a flag for specifying if a user is checking in or checking out */
export enum AppointmentCheckinActionType {
  Checkin = 'CHECKIN',
  Checkout = 'CHECKOUT',
}

export type AppointmentCheckinInput = {
  checkinAdminSelectionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  checkoutEnabled?: InputMaybe<Scalars['Boolean']>;
  rotateQrCode?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentCheckinNode = {
  appointmentCheckinId?: Maybe<Scalars['UUID']>;
  checkinAdminSelectables?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  checkinAdminSelectionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkinInterval?: Maybe<TimeDeltaNode>;
  checkoutEnabled?: Maybe<Scalars['Boolean']>;
  checkoutInterval?: Maybe<TimeDeltaNode>;
  hasCheckinActions?: Maybe<Scalars['Boolean']>;
  isCheckinAdmin?: Maybe<Scalars['Boolean']>;
  orgId?: Maybe<Scalars['Int']>;
  qrCodeLink?: Maybe<Scalars['String']>;
  rotateDuration?: Maybe<TimeDeltaNode>;
  rotateQrCode?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCheckinOccurrenceNode = {
  appointmentCheckinActions?: Maybe<Array<Maybe<AppointmentCheckinActionNode>>>;
  appointmentCheckinOccurrenceId?: Maybe<Scalars['UUID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  totalCheckinCount?: Maybe<Scalars['Int']>;
  totalCheckoutCount?: Maybe<Scalars['Int']>;
  userCheckinActionStatus?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum AppointmentComplianceAuditSortEnum {
  AppointmentComplianceAuditIdAsc = 'appointment_compliance_audit_id_asc',
  AppointmentComplianceAuditIdDesc = 'appointment_compliance_audit_id_desc',
  ConflictReasonCodeAsc = 'conflict_reason_code_asc',
  ConflictReasonCodeDesc = 'conflict_reason_code_desc',
  ConflictReasonTextAsc = 'conflict_reason_text_asc',
  ConflictReasonTextDesc = 'conflict_reason_text_desc',
  ConflictsAsc = 'conflicts_asc',
  ConflictsDesc = 'conflicts_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DiffJsonAsc = 'diff_json_asc',
  DiffJsonDesc = 'diff_json_desc',
  ReviewDateAsc = 'review_date_asc',
  ReviewDateDesc = 'review_date_desc',
  ReviewStatusAsc = 'review_status_asc',
  ReviewStatusDesc = 'review_status_desc',
  ReviewedByAsc = 'reviewed_by_asc',
  ReviewedByDesc = 'reviewed_by_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  TeamIdsAsc = 'team_ids_asc',
  TeamIdsDesc = 'team_ids_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export enum AppointmentComplianceType {
  Countable = 'countable',
  None = 'none',
  Required = 'required',
  SkillInstruction = 'skill_instruction',
  Voluntary = 'voluntary',
}

export type AppointmentConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppointmentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** Input fields for AppointmentCreate mutation */
export type AppointmentCreateInput = {
  appointmentTypeId: Scalars['ID'];
  apptCheckinArgs?: InputMaybe<AppointmentCheckinInput>;
  complianceAuditCode?: InputMaybe<Scalars['String']>;
  complianceAuditReason?: InputMaybe<Scalars['String']>;
  countableEndDatetime?: InputMaybe<Scalars['DateTime']>;
  countableStartDatetime?: InputMaybe<Scalars['DateTime']>;
  enableCheckins?: InputMaybe<Scalars['Boolean']>;
  enableRsvps?: InputMaybe<Scalars['Boolean']>;
  endDatetime: Scalars['DateTime'];
  isAllDay: Scalars['Boolean'];
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  isPrivate: Scalars['Boolean'];
  location?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<AppointmentMeta>;
  notes?: InputMaybe<Scalars['String']>;
  recurRule?: InputMaybe<Scalars['String']>;
  reminders?: InputMaybe<Array<InputMaybe<AppointmentReminders>>>;
  sendAlerts?: InputMaybe<Scalars['String']>;
  sendRsvpAlerts?: InputMaybe<Scalars['String']>;
  startDatetime: Scalars['DateTime'];
  timeZone: Scalars['String'];
  title: Scalars['String'];
  visibility: Array<InputMaybe<Scalars['String']>>;
};

export type AppointmentCreatePayload = {
  appointment?: Maybe<TutorAppointmentNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppointmentDeleteInput = {
  appointmentOccurrenceId?: InputMaybe<Scalars['ID']>;
  complianceAuditCode?: InputMaybe<Scalars['String']>;
  complianceAuditReason?: InputMaybe<Scalars['String']>;
  deleteType?: InputMaybe<Scalars['String']>;
  sendAlerts?: InputMaybe<Scalars['String']>;
  sendAlertsRecurrenceString?: InputMaybe<Scalars['String']>;
};

export type AppointmentDeletePayload = {
  appointment?: Maybe<TutorAppointmentNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `Appointment` and its cursor. */
export type AppointmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Appointment>;
};

export type AppointmentHighlightInput = {
  enabled: Scalars['Boolean'];
  settings?: InputMaybe<Scalars['JSONString']>;
};

export type AppointmentMeta = {
  highlight?: InputMaybe<AppointmentHighlightInput>;
  slotDuration?: InputMaybe<Scalars['Int']>;
  slotParentAppointmentId?: InputMaybe<Scalars['Int']>;
  slotType?: InputMaybe<Scalars['String']>;
  tutorSession?: InputMaybe<TutorSessionInput>;
};

export type AppointmentOccurrence = Node & {
  appointment?: Maybe<TutorAppointmentNode>;
  appointmentId?: Maybe<Scalars['Int']>;
  appointmentOccurrenceId: Scalars['ID'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type AppointmentOccurrenceNode = {
  appointmentCheckinOccurrence?: Maybe<AppointmentCheckinOccurrenceNode>;
  appointmentId?: Maybe<Scalars['Int']>;
  appointmentOccurrenceId?: Maybe<Scalars['Int']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  occurrenceRsvpData?: Maybe<Array<Maybe<AppointmentRsvpNode>>>;
  rsvpCounts?: Maybe<AppointmentRsvpCounts>;
  rsvpStatus?: Maybe<AppointmentRsvpStatusEnum>;
  sessions?: Maybe<Array<Maybe<SessionNode>>>;
  startDateTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentOccurrenceNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppointmentOccurrenceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AppointmentOccurrenceNode` and its cursor. */
export type AppointmentOccurrenceNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AppointmentOccurrence>;
};

/** An enumeration. */
export enum AppointmentOccurrenceSortEnum {
  AppointmentIdAsc = 'appointment_id_asc',
  AppointmentIdDesc = 'appointment_id_desc',
  AppointmentOccurrenceIdAsc = 'appointment_occurrence_id_asc',
  AppointmentOccurrenceIdDesc = 'appointment_occurrence_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EndDateTimeAsc = 'end_date_time_asc',
  EndDateTimeDesc = 'end_date_time_desc',
  StartDateTimeAsc = 'start_date_time_asc',
  StartDateTimeDesc = 'start_date_time_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type AppointmentRsvp = Node & {
  appointmentOccurrence?: Maybe<AppointmentOccurrence>;
  appointmentOccurrenceId: Scalars['Int'];
  appointmentRsvpId: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  status: Appointmentrsvpstatusenum;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type AppointmentRsvpCounts = {
  AWAITING?: Maybe<Scalars['Int']>;
  MAYBE?: Maybe<Scalars['Int']>;
  NO?: Maybe<Scalars['Int']>;
  YES?: Maybe<Scalars['Int']>;
};

export type AppointmentRsvpInput = {
  appointmentOccurrenceId: Scalars['ID'];
  personId: Scalars['ID'];
  status: AppointmentRsvpStatusEnum;
  updateType: Scalars['String'];
};

export type AppointmentRsvpNode = {
  appointmentOccurrenceId?: Maybe<Scalars['Int']>;
  appointmentRsvpId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type AppointmentRsvpSavePayload = {
  appointmentRSVP?: Maybe<Array<Maybe<AppointmentRsvp>>>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/**
 *
 *     Represents possible AppointmentRSVP responses
 *
 */
export enum AppointmentRsvpStatusEnum {
  Awaiting = 'AWAITING',
  Maybe = 'MAYBE',
  No = 'NO',
  Yes = 'YES',
}

export type AppointmentReminder = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  offsetMinutes?: Maybe<Scalars['Int']>;
  pk?: Maybe<Scalars['Int']>;
  reminderType?: Maybe<Scalars['String']>;
};

export type AppointmentReminderInput = {
  offsetMinutes: Scalars['Int'];
  reminderType: Scalars['String'];
};

export type AppointmentReminderNode = {
  offsetMinutes: Scalars['Int'];
  reminderType: Scalars['String'];
};

export type AppointmentReminders = {
  offsetMinutes?: InputMaybe<Scalars['Int']>;
  reminderType: Scalars['String'];
};

export type AppointmentSelectionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppointmentSelectionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AppointmentSelection` and its cursor. */
export type AppointmentSelectionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AppointmentSelectionNode>;
};

export type AppointmentSelectionNode = {
  appointmentCheckin?: Maybe<AppointmentCheckinNode>;
  appointmentComplianceTypeId?: Maybe<Scalars['Int']>;
  appointmentId: Scalars['ID'];
  appointmentOccurrences?: Maybe<AppointmentOccurrenceNodeConnection>;
  appointmentType?: Maybe<AppointmentType>;
  appointmentTypeId?: Maybe<Scalars['Int']>;
  apptDate?: Maybe<Scalars['DateTime']>;
  attachments?: Maybe<Array<Maybe<FileAttachment>>>;
  attendeeCount?: Maybe<Scalars['Int']>;
  attendees?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  bookedSlotAppointments?: Maybe<Array<Maybe<BaseAppointmentNode>>>;
  bookedSlotParent?: Maybe<BaseAppointmentNode>;
  complianceType?: Maybe<Scalars['String']>;
  countableEndHour?: Maybe<Scalars['Int']>;
  countableEndMinute?: Maybe<Scalars['Int']>;
  countableMinutes?: Maybe<Scalars['Int']>;
  countableStartHour?: Maybe<Scalars['Int']>;
  countableStartMinute?: Maybe<Scalars['Int']>;
  countableTypeId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  daysFromBase?: Maybe<Scalars['Int']>;
  enableCheckins?: Maybe<Scalars['Boolean']>;
  enableRsvps?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  /** Appointment's end date as a pendulum object aware to the appointment's timezone */
  endDatetimeLocal?: Maybe<Scalars['String']>;
  /** Appointment's end date as a pendulum object localized but naive */
  endDatetimeNaive?: Maybe<Scalars['String']>;
  /** Appointment's end date as a pendulum object set timezone aware and then set to UTC */
  endDatetimeUtc?: Maybe<Scalars['String']>;
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  endTimeZone?: Maybe<Scalars['String']>;
  externalAppointmentUid?: Maybe<Scalars['String']>;
  externalCalendarAccountId?: Maybe<Scalars['Int']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityName?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['ID']>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
  isAllDay: Scalars['Boolean'];
  isAvailability?: Maybe<Scalars['Boolean']>;
  isBookedSlot?: Maybe<Scalars['String']>;
  isClass?: Maybe<Scalars['Boolean']>;
  isCountable?: Maybe<Scalars['Boolean']>;
  isExternal?: Maybe<Scalars['Boolean']>;
  isExternalAppointment?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  isSlot?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  /** Metadata JSONB object */
  meta?: Maybe<Scalars['JSONString']>;
  notes?: Maybe<Scalars['String']>;
  occurrenceCalculationEnd?: Maybe<Scalars['DateTime']>;
  occurrences?: Maybe<Array<Maybe<AppointmentOccurrenceNode>>>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  parentAppointmentId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  recurEnd?: Maybe<Scalars['DateTime']>;
  recurRule?: Maybe<Scalars['String']>;
  recurStart?: Maybe<Scalars['DateTime']>;
  recurType?: Maybe<Scalars['Int']>;
  reminders?: Maybe<Array<Maybe<AppointmentReminder>>>;
  rrule?: Maybe<Scalars['String']>;
  selections?: Maybe<Array<Maybe<SelectionNode>>>;
  sendRsvpAlerts?: Maybe<Scalars['String']>;
  signupSlot?: Maybe<SlotNode>;
  slotAppointments?: Maybe<Array<Maybe<TutorAppointmentNode>>>;
  slotParentAppointment?: Maybe<TutorAppointmentNode>;
  /** Parent appointment of a child slot appointment */
  slotParentAppointmentId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  /** Appointment's start date as a pendulum object aware to the appointment's timezone */
  startDatetimeLocal?: Maybe<Scalars['String']>;
  /** Appointment's start date as a pendulum object localized but naive */
  startDatetimeNaive?: Maybe<Scalars['String']>;
  /** Appointment's start date as a pendulum object set timezone aware and then set to UTC */
  startDatetimeUtc?: Maybe<Scalars['String']>;
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  startTimeZone?: Maybe<Scalars['String']>;
  /** Alias for the one true timezone to end all other timezone properties */
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  userMetadata?: Maybe<AppointmentUserMetadataNode>;
  var1?: Maybe<Scalars['Int']>;
  var2?: Maybe<Scalars['String']>;
  var3?: Maybe<Scalars['Int']>;
};

export type AppointmentSelectionNodeAppointmentOccurrencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentOccurrenceId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  period?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentOccurrenceSortEnum>>>;
  startDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum AppointmentSortEnum {
  AppointmentComplianceTypeIdAsc = 'appointment_compliance_type_id_asc',
  AppointmentComplianceTypeIdDesc = 'appointment_compliance_type_id_desc',
  AppointmentIdAsc = 'appointment_id_asc',
  AppointmentIdDesc = 'appointment_id_desc',
  AppointmentTypeIdAsc = 'appointment_type_id_asc',
  AppointmentTypeIdDesc = 'appointment_type_id_desc',
  ApptDateAsc = 'appt_date_asc',
  ApptDateDesc = 'appt_date_desc',
  CountableEndHourAsc = 'countable_end_hour_asc',
  CountableEndHourDesc = 'countable_end_hour_desc',
  CountableEndMinuteAsc = 'countable_end_minute_asc',
  CountableEndMinuteDesc = 'countable_end_minute_desc',
  CountableMinutesAsc = 'countable_minutes_asc',
  CountableMinutesDesc = 'countable_minutes_desc',
  CountableStartHourAsc = 'countable_start_hour_asc',
  CountableStartHourDesc = 'countable_start_hour_desc',
  CountableStartMinuteAsc = 'countable_start_minute_asc',
  CountableStartMinuteDesc = 'countable_start_minute_desc',
  CountableTypeIdAsc = 'countable_type_id_asc',
  CountableTypeIdDesc = 'countable_type_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DaysFromBaseAsc = 'days_from_base_asc',
  DaysFromBaseDesc = 'days_from_base_desc',
  EnableCheckinsAsc = 'enable_checkins_asc',
  EnableCheckinsDesc = 'enable_checkins_desc',
  EnableRsvpsAsc = 'enable_rsvps_asc',
  EnableRsvpsDesc = 'enable_rsvps_desc',
  EndDateAsc = 'end_date_asc',
  EndDateDesc = 'end_date_desc',
  EndHourAsc = 'end_hour_asc',
  EndHourDesc = 'end_hour_desc',
  EndMinuteAsc = 'end_minute_asc',
  EndMinuteDesc = 'end_minute_desc',
  EndTimeZoneAsc = 'end_time_zone_asc',
  EndTimeZoneDesc = 'end_time_zone_desc',
  ExternalAppointmentUidAsc = 'external_appointment_uid_asc',
  ExternalAppointmentUidDesc = 'external_appointment_uid_desc',
  ExternalCalendarAccountIdAsc = 'external_calendar_account_id_asc',
  ExternalCalendarAccountIdDesc = 'external_calendar_account_id_desc',
  FacilityIdAsc = 'facility_id_asc',
  FacilityIdDesc = 'facility_id_desc',
  FacilityNameAsc = 'facility_name_asc',
  FacilityNameDesc = 'facility_name_desc',
  IsAllDayAsc = 'is_all_day_asc',
  IsAllDayDesc = 'is_all_day_desc',
  IsAvailabilityAsc = 'is_availability_asc',
  IsAvailabilityDesc = 'is_availability_desc',
  IsExternalAppointmentAsc = 'is_external_appointment_asc',
  IsExternalAppointmentDesc = 'is_external_appointment_desc',
  IsMandatoryAsc = 'is_mandatory_asc',
  IsMandatoryDesc = 'is_mandatory_desc',
  IsPrivateAsc = 'is_private_asc',
  IsPrivateDesc = 'is_private_desc',
  LocationAsc = 'location_asc',
  LocationDesc = 'location_desc',
  MetaAsc = 'meta_asc',
  MetaDesc = 'meta_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  OccurrenceCalculationEndAsc = 'occurrence_calculation_end_asc',
  OccurrenceCalculationEndDesc = 'occurrence_calculation_end_desc',
  ParentAppointmentIdAsc = 'parent_appointment_id_asc',
  ParentAppointmentIdDesc = 'parent_appointment_id_desc',
  RecurEndAsc = 'recur_end_asc',
  RecurEndDesc = 'recur_end_desc',
  RecurRuleAsc = 'recur_rule_asc',
  RecurRuleDesc = 'recur_rule_desc',
  RecurStartAsc = 'recur_start_asc',
  RecurStartDesc = 'recur_start_desc',
  RecurTypeAsc = 'recur_type_asc',
  RecurTypeDesc = 'recur_type_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SendRsvpAlertsAsc = 'send_rsvp_alerts_asc',
  SendRsvpAlertsDesc = 'send_rsvp_alerts_desc',
  SlotParentAppointmentIdAsc = 'slot_parent_appointment_id_asc',
  SlotParentAppointmentIdDesc = 'slot_parent_appointment_id_desc',
  StartDateAsc = 'start_date_asc',
  StartDateDesc = 'start_date_desc',
  StartHourAsc = 'start_hour_asc',
  StartHourDesc = 'start_hour_desc',
  StartMinuteAsc = 'start_minute_asc',
  StartMinuteDesc = 'start_minute_desc',
  StartTimeZoneAsc = 'start_time_zone_asc',
  StartTimeZoneDesc = 'start_time_zone_desc',
  TitleAsc = 'title_asc',
  TitleDesc = 'title_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  Var1Asc = 'var1_asc',
  Var1Desc = 'var1_desc',
  Var2Asc = 'var2_asc',
  Var2Desc = 'var2_desc',
  Var3Asc = 'var3_asc',
  Var3Desc = 'var3_desc',
}

export type AppointmentType = Node & {
  appointmentComplianceType?: Maybe<Scalars['String']>;
  appointmentComplianceTypeId?: Maybe<Scalars['Int']>;
  appointmentTypeId?: Maybe<Scalars['Int']>;
  /** The color of the appointment type */
  color: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDayOff?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isSelectable: Scalars['Boolean'];
  isTravel?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  /** Metadata JSONB object */
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
};

export type AppointmentTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppointmentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeCreatePayload = {
  appointmentType?: Maybe<AppointmentType>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `AppointmentType` and its cursor. */
export type AppointmentTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AppointmentType>;
};

export type AppointmentTypeInput = {
  appointmentComplianceType?: InputMaybe<AppointmentComplianceType>;
  color: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  isTravel?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  visibility?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** An enumeration. */
export enum AppointmentTypeSortEnum {
  AppointmentComplianceTypeIdAsc = 'appointment_compliance_type_id_asc',
  AppointmentComplianceTypeIdDesc = 'appointment_compliance_type_id_desc',
  AppointmentTypeIdAsc = 'appointment_type_id_asc',
  AppointmentTypeIdDesc = 'appointment_type_id_desc',
  ColorAsc = 'color_asc',
  ColorDesc = 'color_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsEditableAsc = 'is_editable_asc',
  IsEditableDesc = 'is_editable_desc',
  IsSelectableAsc = 'is_selectable_asc',
  IsSelectableDesc = 'is_selectable_desc',
  IsTravelAsc = 'is_travel_asc',
  IsTravelDesc = 'is_travel_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  MetaAsc = 'meta_asc',
  MetaDesc = 'meta_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type AppointmentTypeUpdatePayload = {
  appointmentType?: Maybe<AppointmentType>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppointmentTypesByDayNode = {
  date?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Array<Maybe<CountableSummaryNode>>>;
  totalCountableMinutes?: Maybe<Scalars['Int']>;
};

/** Input fields for AppointmentUpdate mutation (similar to Create inputs, but less strict) */
export type AppointmentUpdateInput = {
  appointmentOccurrenceId?: InputMaybe<Scalars['ID']>;
  appointmentTypeId?: InputMaybe<Scalars['ID']>;
  apptCheckinArgs?: InputMaybe<AppointmentCheckinInput>;
  complianceAuditCode?: InputMaybe<Scalars['String']>;
  complianceAuditReason?: InputMaybe<Scalars['String']>;
  countableEndDatetime?: InputMaybe<Scalars['DateTime']>;
  countableStartDatetime?: InputMaybe<Scalars['DateTime']>;
  enableCheckins?: InputMaybe<Scalars['Boolean']>;
  enableRsvps?: InputMaybe<Scalars['Boolean']>;
  endDatetime?: InputMaybe<Scalars['DateTime']>;
  isAllDay?: InputMaybe<Scalars['Boolean']>;
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<AppointmentMeta>;
  notes?: InputMaybe<Scalars['String']>;
  recurRule?: InputMaybe<Scalars['String']>;
  reminders?: InputMaybe<Array<InputMaybe<AppointmentReminders>>>;
  sendAlerts?: InputMaybe<Scalars['String']>;
  sendRsvpAlerts?: InputMaybe<Scalars['String']>;
  startDatetime?: InputMaybe<Scalars['DateTime']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updateType?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AppointmentUpdatePayload = {
  appointment?: Maybe<TutorAppointmentNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AppointmentUserMetadataNode = {
  canDelete?: Maybe<Scalars['Boolean']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  canViewDetails?: Maybe<Scalars['Boolean']>;
};

/** Whether or not to delete or keep appointments when the designated signup is modified or deleted */
export enum ApptBehaviorOnModify {
  CascadeAll = 'CASCADE_ALL',
  CascadeFuture = 'CASCADE_FUTURE',
  Keep = 'KEEP',
}

export type ApptCheckinQrKioskDetails = {
  appointmentEndDate?: Maybe<Scalars['Date']>;
  appointmentEndTime?: Maybe<Scalars['Time']>;
  appointmentLabel?: Maybe<Scalars['String']>;
  appointmentOccurrenceId?: Maybe<Scalars['Int']>;
  appointmentStartDate?: Maybe<Scalars['Date']>;
  appointmentStartTime?: Maybe<Scalars['Time']>;
  qrCodeLink?: Maybe<Scalars['String']>;
  rotateDuration?: Maybe<TimeDeltaNode>;
  rotateQrCode?: Maybe<Scalars['Boolean']>;
  teamLogoUrl?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  totpSecret?: Maybe<Scalars['String']>;
  uses24HourTime?: Maybe<Scalars['Boolean']>;
};

export type AssignmentArgsInput = {
  additionalResultsTo?: InputMaybe<Array<InputMaybe<AdditionalResultsVisibilityInput>>>;
  additionalRoleAssignments?: InputMaybe<Array<InputMaybe<SubsequentRoleInput>>>;
  assignmentTimezone?: InputMaybe<Scalars['String']>;
  assignmentType: FormAssignmentType;
  captchaEnabled?: InputMaybe<Scalars['Boolean']>;
  createLink?: InputMaybe<Scalars['Boolean']>;
  dateAvailable?: InputMaybe<Scalars['DateTime']>;
  deactivationDateTime?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dueDate?: InputMaybe<Scalars['DateTime']>;
  flaggedFormAlerts?: InputMaybe<FlaggingFormInput>;
  folder?: InputMaybe<Scalars['String']>;
  form: Scalars['String'];
  formSchedule?: InputMaybe<Scalars['String']>;
  initialRoleAssignment: InitialRoleInput;
  isActive?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  notifyAssignees?: InputMaybe<NotifyAssignees>;
  resultsTo: ResultsVisibilityInput;
  smartabaseFormName?: InputMaybe<Scalars['String']>;
};

export type AssignmentObjectType = {
  customLabel?: Maybe<Scalars['String']>;
  legLabel?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Input model for a number of Attribute mutations */
export type AthleteAttributeInput = {
  academicYear?: InputMaybe<Scalars['ID']>;
  athleteStatus?: InputMaybe<Scalars['ID']>;
  athleticYear?: InputMaybe<Scalars['ID']>;
  /** When change is deemed to take effect */
  effectiveDate?: InputMaybe<Scalars['Date']>;
  hasScholarship?: InputMaybe<Scalars['Boolean']>;
  jerseyNumber?: InputMaybe<Scalars['String']>;
  major?: InputMaybe<Scalars['String']>;
  /** Notes */
  notes?: InputMaybe<Scalars['String']>;
  positions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Reason why athlete status changed */
  reason?: InputMaybe<AthleteStatusChangeReason>;
  scholarshipAmount?: InputMaybe<Scalars['String']>;
};

export type AthleteBreakdownDailyViolationsNode = {
  hasCaraOnDayOff?: Maybe<Scalars['Boolean']>;
  hasCaraOnVacation?: Maybe<Scalars['Boolean']>;
  hasDailyOverage?: Maybe<Scalars['Boolean']>;
  hasSkillInstructionOverage?: Maybe<Scalars['Boolean']>;
};

export type AthleteBreakdownNode = {
  dailyViolations?: Maybe<AthleteBreakdownDailyViolationsNode>;
  date?: Maybe<Scalars['String']>;
  logAppointments?: Maybe<Array<Maybe<TmpActivityLogAppointmentBreakdownNode>>>;
  totalCountableMinutes?: Maybe<Scalars['Int']>;
};

/** Input model for a number of AthleteBulkUpdate mutation */
export type AthleteBulkUpdateField = {
  academicYearGid?: InputMaybe<Scalars['ID']>;
  athleteStatusGid?: InputMaybe<Scalars['ID']>;
  athleticYearGid?: InputMaybe<Scalars['ID']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  hasLoginAccess?: InputMaybe<Scalars['Boolean']>;
  jerseyNumber?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  personGid?: InputMaybe<Scalars['ID']>;
  positionGids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  schoolIdentifier?: InputMaybe<Scalars['String']>;
};

export type AthleteBulkUpdateInput = {
  athletes: Array<InputMaybe<AthleteBulkUpdateField>>;
};

export type AthleteBulkUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AthleteInput = {
  athleteStatus?: InputMaybe<Scalars['ID']>;
  athleticYear?: InputMaybe<Scalars['ID']>;
  eligibilityYear?: InputMaybe<Scalars['ID']>;
  jerseyNumber?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['ID']>;
  teamRosters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type AthleteRoommateNode = Node & {
  athletePerson?: Maybe<PersonNode>;
  athletePersonId: Scalars['Int'];
  athleteRoommateId: Scalars['ID'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  customEmail?: Maybe<Scalars['String']>;
  customName?: Maybe<Scalars['String']>;
  customPhone?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  roommatePerson?: Maybe<PersonNode>;
  roommatePersonId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** Represents reasons for status */
export enum AthleteStatusChangeReason {
  CutDismissed = 'CUT_DISMISSED',
  ExhaustedEligibility = 'EXHAUSTED_ELIGIBILITY',
  Graduated = 'GRADUATED',
  MedicalExceptionInjury = 'MEDICAL_EXCEPTION_INJURY',
  Other = 'OTHER',
  QuitTeam = 'QUIT_TEAM',
  Withdrew = 'WITHDREW',
}

export type AthleteSummaryNode = {
  appointmentTypesByDay?: Maybe<Array<Maybe<AppointmentTypesByDayNode>>>;
  summary?: Maybe<Array<Maybe<CountableSummaryNode>>>;
  tmpActivityLogId?: Maybe<Scalars['Int']>;
};

export type AttachmentObjectType = {
  downloadUrl?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileNameServer?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['Int']>;
};

export type AttachmentResponseStructure = {
  /** File Name */
  fileName?: Maybe<Scalars['String']>;
  /** S3 Url */
  s3Url?: Maybe<Scalars['String']>;
  /** Server Name */
  serverName?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum AttachmentVehicle {
  Email = 'email',
  Text = 'text',
}

export type AttendeeDiff = {
  added?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw?: Maybe<Scalars['String']>;
  removed?: Maybe<Array<Maybe<Scalars['String']>>>;
  same?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AttributeBulkImportPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AttributeBulkUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<Array<Maybe<PersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AttributeCategoryConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AttributeCategoryEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AttributeCategory` and its cursor. */
export type AttributeCategoryEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AttributeCategoryNode>;
};

export type AttributeCategoryInput = {
  isActive: Scalars['Boolean'];
};

/** GraphQL node for a profile attribute category */
export type AttributeCategoryNode = Node & {
  /** The list of attributes for the category. */
  attributes?: Maybe<Array<Maybe<AttributeNode>>>;
  coreCode?: Maybe<Scalars['String']>;
  editability?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCustom: Scalars['Boolean'];
  label: Scalars['String'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Active attributes for configuration in the admin section. */
  profileAttributes?: Maybe<Array<Maybe<AttributeNode>>>;
  profileAttributesForAthlete?: Maybe<Array<Maybe<AttributeNode>>>;
  sortOrder?: Maybe<Scalars['Int']>;
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['ID']>;
  visibility?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
};

/** An enumeration. */
export enum AttributeCategorySortEnum {
  AttributeCategoryIdAsc = 'attribute_category_id_asc',
  AttributeCategoryIdDesc = 'attribute_category_id_desc',
  CoreCodeAsc = 'core_code_asc',
  CoreCodeDesc = 'core_code_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsCustomAsc = 'is_custom_asc',
  IsCustomDesc = 'is_custom_desc',
  IsViewableProfileAsc = 'is_viewable_profile_asc',
  IsViewableProfileDesc = 'is_viewable_profile_desc',
  IsViewableReportAsc = 'is_viewable_report_asc',
  IsViewableReportDesc = 'is_viewable_report_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SelfManageUsersAsc = 'self_manage_users_asc',
  SelfManageUsersDesc = 'self_manage_users_desc',
  SortOrderAsc = 'sort_order_asc',
  SortOrderDesc = 'sort_order_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type AttributeCategoryUpdatePayload = {
  attributeCategory?: Maybe<AttributeCategoryNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum AttributeDataTypeSortEnum {
  AttributeDataTypeIdAsc = 'attribute_data_type_id_asc',
  AttributeDataTypeIdDesc = 'attribute_data_type_id_desc',
  CodeAsc = 'code_asc',
  CodeDesc = 'code_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DataTypeAsc = 'data_type_asc',
  DataTypeDesc = 'data_type_desc',
  FormControlAsc = 'form_control_asc',
  FormControlDesc = 'form_control_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  TextFieldMaxAsc = 'text_field_max_asc',
  TextFieldMaxDesc = 'text_field_max_desc',
  TextFieldSizeAsc = 'text_field_size_asc',
  TextFieldSizeDesc = 'text_field_size_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type AttributeInput = {
  athlete?: InputMaybe<AthleteAttributeInput>;
  automobile?: InputMaybe<AutomobileAttributeInput>;
  customAttributes?: InputMaybe<Array<InputMaybe<CustomAttributeInput>>>;
  internationalTravelDocument?: InputMaybe<InternationalTravelDocumentInput>;
  person?: InputMaybe<PersonAttributeInput>;
  yearlyAttributes?: InputMaybe<Array<InputMaybe<YearlyAttributeInput>>>;
};

/** GraphQL node for a profile attribute */
export type AttributeNode = Node & {
  appliesToUserTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attributeCategoryId?: Maybe<Scalars['Int']>;
  attributeReminders?: Maybe<Array<Maybe<AttributeReminderNode>>>;
  canEdit: Scalars['Boolean'];
  categoryCoreCode?: Maybe<Scalars['String']>;
  categoryLabel?: Maybe<Scalars['String']>;
  coreCode?: Maybe<Scalars['String']>;
  dataType?: Maybe<Scalars['String']>;
  enumOptions?: Maybe<Scalars['String']>;
  extendedOptionsList?: Maybe<Array<Maybe<AttributeOptionNode>>>;
  hasUserTypeRestriction: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCustom?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  optionsList?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  selfManageUsers?: Maybe<Scalars['String']>;
  selfManagedUserTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sortOrder: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  valueLabel?: Maybe<Scalars['String']>;
};

export type AttributeOptionNode = {
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AttributeReminderInput = {
  reminderOffset?: InputMaybe<AttributeReminderOffsetInput>;
  reminderType?: InputMaybe<AttributeReminderType>;
  teamId?: InputMaybe<Scalars['Int']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** GraphQL node for attribute reminder associated with Date type attributes */
export type AttributeReminderNode = Node & {
  active: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  reminderOffset?: Maybe<ReminderOffsetNode>;
  reminderType?: Maybe<AttributeReminderType>;
  teamId: Scalars['Int'];
  visibility?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
};

export type AttributeReminderOffsetInput = {
  days?: InputMaybe<Scalars['Int']>;
  months?: InputMaybe<Scalars['Int']>;
  weeks?: InputMaybe<Scalars['Int']>;
  years?: InputMaybe<Scalars['Int']>;
};

/**
 *
 *     Possible Reminder Types
 *
 */
export enum AttributeReminderType {
  Email = 'EMAIL',
  Text = 'TEXT',
}

/** An enumeration. */
export enum AttributeSortEnum {
  AttributeCategoryIdAsc = 'attribute_category_id_asc',
  AttributeCategoryIdDesc = 'attribute_category_id_desc',
  AttributeDataTypeIdAsc = 'attribute_data_type_id_asc',
  AttributeDataTypeIdDesc = 'attribute_data_type_id_desc',
  AttributeIdAsc = 'attribute_id_asc',
  AttributeIdDesc = 'attribute_id_desc',
  ColumnDataSqlAsc = 'column_data_sql_asc',
  ColumnDataSqlDesc = 'column_data_sql_desc',
  ColumnDisplaySqlAsc = 'column_display_sql_asc',
  ColumnDisplaySqlDesc = 'column_display_sql_desc',
  CoreCodeAsc = 'core_code_asc',
  CoreCodeDesc = 'core_code_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DataRangeAsc = 'data_range_asc',
  DataRangeDesc = 'data_range_desc',
  EnumOptionsAsc = 'enum_options_asc',
  EnumOptionsDesc = 'enum_options_desc',
  EnumSetCodeAsc = 'enum_set_code_asc',
  EnumSetCodeDesc = 'enum_set_code_desc',
  HasUserTypeRestrictionAsc = 'has_user_type_restriction_asc',
  HasUserTypeRestrictionDesc = 'has_user_type_restriction_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsCustomAsc = 'is_custom_asc',
  IsCustomDesc = 'is_custom_desc',
  IsSelfManageAvailableAsc = 'is_self_manage_available_asc',
  IsSelfManageAvailableDesc = 'is_self_manage_available_desc',
  IsTeamSpecificAsc = 'is_team_specific_asc',
  IsTeamSpecificDesc = 'is_team_specific_desc',
  IsViewableProfileAsc = 'is_viewable_profile_asc',
  IsViewableProfileDesc = 'is_viewable_profile_desc',
  IsViewableReportAsc = 'is_viewable_report_asc',
  IsViewableReportDesc = 'is_viewable_report_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  ReportFilterScopeRestrictionAsc = 'report_filter_scope_restriction_asc',
  ReportFilterScopeRestrictionDesc = 'report_filter_scope_restriction_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SelfManageUsersAsc = 'self_manage_users_asc',
  SelfManageUsersDesc = 'self_manage_users_desc',
  SortOrderAsc = 'sort_order_asc',
  SortOrderDesc = 'sort_order_desc',
  TableDataAliasAsc = 'table_data_alias_asc',
  TableDataAliasDesc = 'table_data_alias_desc',
  TableDisplayAliasAsc = 'table_display_alias_asc',
  TableDisplayAliasDesc = 'table_display_alias_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type AttributeUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type AttributesUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Simplified Conflict object for Compliance Audits, since we work with a reduced data set on the object */
export type AuditConflictReport = {
  bylaw?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type AutomobileAttributeInput = {
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  plateNumber?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type AutomobileNode = {
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  stateCode: Scalars['String'];
  year?: Maybe<Scalars['String']>;
};

export type BaseAppointmentNode = Node & {
  appointmentCheckin?: Maybe<AppointmentCheckinNode>;
  appointmentId: Scalars['ID'];
  appointmentType?: Maybe<AppointmentType>;
  attachments?: Maybe<Array<Maybe<FileAttachment>>>;
  attendeeCount?: Maybe<Scalars['Int']>;
  attendees?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  bookedSlotAppointments?: Maybe<Array<Maybe<BaseAppointmentNode>>>;
  bookedSlotParent?: Maybe<BaseAppointmentNode>;
  complianceType?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  enableCheckins?: Maybe<Scalars['Boolean']>;
  enableRsvps?: Maybe<Scalars['Boolean']>;
  endTimeZone?: Maybe<Scalars['String']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityName?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['ID']>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isAllDay: Scalars['Boolean'];
  isClass?: Maybe<Scalars['Boolean']>;
  isCountable?: Maybe<Scalars['Boolean']>;
  isExternal?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  location: Scalars['String'];
  /** Metadata JSONB object */
  meta?: Maybe<Scalars['JSONString']>;
  notes?: Maybe<Scalars['String']>;
  occurrences?: Maybe<Array<Maybe<AppointmentOccurrenceNode>>>;
  orgId: Scalars['Int'];
  recurRule?: Maybe<Scalars['String']>;
  reminders?: Maybe<Array<Maybe<AppointmentReminder>>>;
  rrule?: Maybe<Scalars['String']>;
  signupSlot?: Maybe<SlotNode>;
  /** Parent appointment of a child slot appointment */
  slotParentAppointmentId?: Maybe<Scalars['Int']>;
  startTimeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userMetadata?: Maybe<AppointmentUserMetadataNode>;
};

export type BatchUpdateTeamMembershipsInput = {
  updates?: InputMaybe<Array<InputMaybe<UpdateTeamMembershipInput>>>;
};

export type BulkAddressInput = {
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
  fileName?: InputMaybe<Scalars['String']>;
  serverFileName: Scalars['String'];
};

export type BulkAppointmentConflict = {
  appointmentOccurrenceId?: Maybe<Scalars['ID']>;
  conflicts?: Maybe<Array<Maybe<Conflict>>>;
};

export type BulkAppointmentConflictInput = {
  appointmentOccurrenceId: Scalars['ID'];
  appointmentTypeId?: InputMaybe<Scalars['ID']>;
  countableMinutes?: InputMaybe<Scalars['Int']>;
  endDatetime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  isCountable?: InputMaybe<Scalars['Boolean']>;
  startDatetime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkAssignProfilePicturePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<Array<Maybe<PersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type BulkAttributeImportInput = {
  attribute: Scalars['ID'];
  emailAddress: Scalars['String'];
  value: Scalars['String'];
};

export type BulkAttributeInput = {
  attributeValue?: InputMaybe<AttributeInput>;
  personIds: Array<InputMaybe<Scalars['ID']>>;
};

export type BulkDeactivateAssignmentArgsInput = {
  assignmentsToDeactivate?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type BulkDeactivateAssignmentsPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<Array<Maybe<FormAssignmentNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type BulkGrantAccessInput = {
  invitationMedium?: InputMaybe<InvitationMedium>;
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type BulkPersonContactInput = {
  contacts?: InputMaybe<Array<InputMaybe<AdminPersonContactInput>>>;
  fileName?: InputMaybe<Scalars['String']>;
  serverFileName: Scalars['String'];
};

export type BulkPersonDeactivateInput = {
  personIds: Array<InputMaybe<Scalars['ID']>>;
};

export type BulkPersonPictureUploadInput = {
  personPictures: Array<InputMaybe<PersonPictureUploadInput>>;
};

export type BulkPersonReactivateInput = {
  personIds: Array<InputMaybe<Scalars['ID']>>;
};

export type BulkRoommateInput = {
  anchorPersonEmail?: InputMaybe<Scalars['String']>;
  anchorSchoolIdentifier?: InputMaybe<Scalars['String']>;
  customEmail?: InputMaybe<Scalars['String']>;
  customName?: InputMaybe<Scalars['String']>;
  customPhone?: InputMaybe<Scalars['String']>;
};

export type BulkTeamMemberUpdatePermissionsPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamMember?: Maybe<Array<Maybe<TeamMemberNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type BulkUpdatePermissionsInput = {
  permissions?: InputMaybe<Array<InputMaybe<PermissionInput>>>;
  resetExistingPermissions?: InputMaybe<Scalars['Boolean']>;
  teamMembers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** Represents a bulk error caused by the viewing user, typically field-level validation errors. */
export type BulkUserError = {
  /** List of errors associated to the object_key */
  errorList?: Maybe<Array<Maybe<UserError>>>;
  /** The ID for the associated object */
  objectKey: Scalars['String'];
};

export type BulletinClickConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BulletinClickEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type BulletinClickCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  bulletinClick?: Maybe<BulletinClickNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `BulletinClick` and its cursor. */
export type BulletinClickEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BulletinClickNode>;
};

export type BulletinClickInput = {
  bulletin: Scalars['ID'];
  sessionId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type BulletinClickNode = Node & {
  bulletin?: Maybe<BulletinNode>;
  /** bulletin_click_id */
  bulletinClickId: Scalars['String'];
  bulletinId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** person_id */
  personId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** An id that identifies a login session */
  sessionId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type BulletinClickNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BulletinClickNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BulletinClickNode` and its cursor. */
export type BulletinClickNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BulletinClickNode>;
};

/** An enumeration. */
export enum BulletinClickSortEnum {
  BulletinClickIdAsc = 'bulletin_click_id_asc',
  BulletinClickIdDesc = 'bulletin_click_id_desc',
  BulletinIdAsc = 'bulletin_id_asc',
  BulletinIdDesc = 'bulletin_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  SessionIdAsc = 'session_id_asc',
  SessionIdDesc = 'session_id_desc',
  SourceAsc = 'source_asc',
  SourceDesc = 'source_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export enum BulletinClickStatusEnum {
  All = 'ALL',
  HasClicked = 'HAS_CLICKED',
  NotClicked = 'NOT_CLICKED',
}

export type BulletinCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  bulletin?: Maybe<BulletinNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A enum class that lets us define a custom sorting behavior outside of passing an array of columns with asc/desc */
export enum BulletinCustomSortEnum {
  ActivePinnedRecent = 'ACTIVE_PINNED_RECENT',
}

export type BulletinDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  bulletin?: Maybe<BulletinNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type BulletinInput = {
  bulletinType?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  headerImage?: InputMaybe<Scalars['Upload']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  linkPreviewHash?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['JSONString']>;
  scheduledDatetime?: InputMaybe<Scalars['DateTime']>;
  sendAlert?: InputMaybe<Scalars['Boolean']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulletinNode = Node & {
  bulletinClick?: Maybe<BulletinClickNodeConnection>;
  bulletinId: Scalars['ID'];
  bulletinType: BulletinType;
  buttonText?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The app that created this bulletin */
  creatorApp?: Maybe<Scalars['String']>;
  /** The datetime for when this post is no longer visible; timestamp with tz field */
  expirationDateTime?: Maybe<Scalars['DateTime']>;
  externalUrl?: Maybe<Scalars['String']>;
  headerImageFull?: Maybe<Scalars['String']>;
  headerImageThumb?: Maybe<Scalars['String']>;
  headerImageUuid?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isPinned: Scalars['Boolean'];
  label: Scalars['String'];
  lastClickedDate?: Maybe<Scalars['DateTime']>;
  linkPreviewHash?: Maybe<Scalars['String']>;
  message?: Maybe<Message>;
  /** The message that this is associated with a scheduled news post */
  messageId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<Scalars['String']>;
  /** Whether or not this bulletin is accessible to the whole org, without declaring visibility */
  orgAccessible?: Maybe<Scalars['Boolean']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personIdsNotClicked?: Maybe<Array<Maybe<Scalars['Int']>>>;
  personIdsWhoClicked?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** The datetime for when this post becomes visible; timestamp with tz field */
  scheduledDatetime?: Maybe<Scalars['DateTime']>;
  /** Stream message_id associated with a scheduled news post */
  streamMessageId?: Maybe<Scalars['Int']>;
  totalClicks?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BulletinNodeBulletinClickArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulletinClickId?: InputMaybe<Scalars['TWFilter_String']>;
  bulletinId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sessionId?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<BulletinClickSortEnum>>>;
  source?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** A class that inherits all the fields for a person, and includes a flag whether person has clicked a bulletin */
export type BulletinPersonNode = {
  firstName?: Maybe<Scalars['String']>;
  hasClicked?: Maybe<Scalars['Boolean']>;
  lastClickedDate?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
};

export type BulletinSendReminderInput = {
  bulletin: Scalars['ID'];
  /** ID of specific person to send bulletin reminder to, null means to send to everyone who has not clicked the bulletin */
  person?: InputMaybe<Scalars['ID']>;
};

export type BulletinSendReminderPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  bulletinClick?: Maybe<BulletinClickNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum BulletinSortEnum {
  BulletinIdAsc = 'bulletin_id_asc',
  BulletinIdDesc = 'bulletin_id_desc',
  BulletinTypeAsc = 'bulletin_type_asc',
  BulletinTypeDesc = 'bulletin_type_desc',
  ButtonTextAsc = 'button_text_asc',
  ButtonTextDesc = 'button_text_desc',
  ContentAsc = 'content_asc',
  ContentDesc = 'content_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CreatorAppAsc = 'creator_app_asc',
  CreatorAppDesc = 'creator_app_desc',
  ExpirationDateTimeAsc = 'expiration_date_time_asc',
  ExpirationDateTimeDesc = 'expiration_date_time_desc',
  ExternalUrlAsc = 'external_url_asc',
  ExternalUrlDesc = 'external_url_desc',
  HeaderImageUuidAsc = 'header_image_uuid_asc',
  HeaderImageUuidDesc = 'header_image_uuid_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsPinnedAsc = 'is_pinned_asc',
  IsPinnedDesc = 'is_pinned_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  LinkPreviewHashAsc = 'link_preview_hash_asc',
  LinkPreviewHashDesc = 'link_preview_hash_desc',
  MessageIdAsc = 'message_id_asc',
  MessageIdDesc = 'message_id_desc',
  MetaAsc = 'meta_asc',
  MetaDesc = 'meta_desc',
  OrgAccessibleAsc = 'org_accessible_asc',
  OrgAccessibleDesc = 'org_accessible_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ScheduledDatetimeAsc = 'scheduled_datetime_asc',
  ScheduledDatetimeDesc = 'scheduled_datetime_desc',
  StreamMessageIdAsc = 'stream_message_id_asc',
  StreamMessageIdDesc = 'stream_message_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** An enumeration. */
export enum BulletinType {
  Announcement = 'announcement',
  Birthday = 'birthday',
  Form = 'form',
  Link = 'link',
  Tip = 'tip',
}

export type BulletinUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  bulletin?: Maybe<BulletinNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type BulletinViewersConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BulletinViewersEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `BulletinViewers` and its cursor. */
export type BulletinViewersEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BulletinPersonNode>;
};

export type BulletinsConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BulletinsEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Bulletins` and its cursor. */
export type BulletinsEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BulletinNode>;
};

export type BulletinsSettingsConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BulletinsSettingsEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type BulletinsSettingsCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  bulletinsSettings?: Maybe<BulletinsSettingsNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type BulletinsSettingsDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  bulletinsSettings?: Maybe<BulletinsSettingsNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `BulletinsSettings` and its cursor. */
export type BulletinsSettingsEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BulletinsSettingsNode>;
};

export type BulletinsSettingsInput = {
  birthdaysAnnouncementCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  birthdaysVisibilityCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isBirthdaysEnabled?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
};

export type BulletinsSettingsNode = Node & {
  birthdaysAnnouncementCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  birthdaysVisibilityCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  bulletinsSettingsId: Scalars['ID'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Is Birthdays Enabled for Org */
  isBirthdaysEnabled: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum BulletinsSettingsSortEnum {
  BirthdaysAnnouncementCodesAsc = 'birthdays_announcement_codes_asc',
  BirthdaysAnnouncementCodesDesc = 'birthdays_announcement_codes_desc',
  BirthdaysVisibilityCodesAsc = 'birthdays_visibility_codes_asc',
  BirthdaysVisibilityCodesDesc = 'birthdays_visibility_codes_desc',
  BulletinsSettingsIdAsc = 'bulletins_settings_id_asc',
  BulletinsSettingsIdDesc = 'bulletins_settings_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsBirthdaysEnabledAsc = 'is_birthdays_enabled_asc',
  IsBirthdaysEnabledDesc = 'is_birthdays_enabled_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type BulletinsSettingsUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  bulletinsSettings?: Maybe<BulletinsSettingsNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CaraInfo = {
  /** Minumim hours required for appointment changes */
  adequateChangeNoticeHours?: Maybe<Scalars['Int']>;
  /** Enabled if CARA settings come from Axle */
  isCaraEnabled?: Maybe<Scalars['Boolean']>;
};

export type CfapiTokenRefresh = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  token?: Maybe<Scalars['String']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export enum CalendarAlertType {
  Both = 'BOTH',
  Email = 'EMAIL',
  None = 'NONE',
  Sms = 'SMS',
}

export type CalendarReportConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CalendarReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CalendarReportCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  calendarReport?: Maybe<CalendarReportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `CalendarReport` and its cursor. */
export type CalendarReportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CalendarReportNode>;
};

export type CalendarReportInput = {
  appointmentTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDatetime?: InputMaybe<Scalars['DateTime']>;
  forCompliance?: InputMaybe<Scalars['String']>;
  metacalTypeIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reportName?: InputMaybe<CalendarReportTypes>;
  selections: Array<InputMaybe<Scalars['String']>>;
  startDatetime?: InputMaybe<Scalars['DateTime']>;
};

export type CalendarReportNode = Node & {
  calendarReportId: Scalars['ID'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  downloadableFileId: Scalars['ID'];
  errorMessage?: Maybe<Scalars['String']>;
  fileNameServer?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** What kind of downloaded file this is, e.g. 'report'. */
  kind: Scalars['String'];
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<OrgNode>;
  /** The Teamworks Org Id that this is associated with this */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Teamworks Person Id that this is associated with this */
  personId: Scalars['Int'];
  pk?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
  /** The Teamworks Team Id that this is associated with this */
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CalendarReportTypes {
  AvailabilityReport = 'availability_report',
  EventsReport = 'events_report',
  HoursReport = 'hours_report',
}

export type CalsyncAccountCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  calsyncAccount?: Maybe<CalsyncAccountNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CalsyncAccountDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  calsyncAccount?: Maybe<CalsyncAccountNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CalsyncAccountInput = {
  /** The token received from the provider */
  accessToken?: InputMaybe<Scalars['String']>;
  calsyncAccountId?: InputMaybe<Scalars['ID']>;
  calsyncCalendars?: InputMaybe<Array<InputMaybe<CalsyncCalendarInput>>>;
  /** Which engine to be used in Calsync 3.0+, e.g. 'cronofy' for Cronofy. */
  engineName?: InputMaybe<Scalars['String']>;
  /** PersonId of the person syncing the account */
  personId?: InputMaybe<Scalars['Int']>;
  /** The provider's id to sync to. */
  providerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type CalsyncAccountNode = Node & {
  calsyncAccountId: Scalars['ID'];
  calsyncCalendars?: Maybe<Array<Maybe<CalsyncCalendarNode>>>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  engineName: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** The authentication token received from the provider for this user */
  providerAccessToken?: Maybe<Scalars['String']>;
  /** Arbitrary provider data for forward compatibility. */
  providerData?: Maybe<Scalars['JSONString']>;
  /** The external account id for this user and calendar. */
  providerId: Scalars['String'];
  /** One of CalsyncAccount.STATUS_* */
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type CalsyncAccountNodeCalsyncCalendarsArgs = {
  providerId?: InputMaybe<Scalars['String']>;
};

export type CalsyncAccountNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CalsyncAccountNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CalsyncAccountNode` and its cursor. */
export type CalsyncAccountNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CalsyncAccountNode>;
};

/** An enumeration. */
export enum CalsyncAccountSortEnum {
  CalsyncAccountIdAsc = 'calsync_account_id_asc',
  CalsyncAccountIdDesc = 'calsync_account_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EngineNameAsc = 'engine_name_asc',
  EngineNameDesc = 'engine_name_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  ProviderAccessTokenAsc = 'provider_access_token_asc',
  ProviderAccessTokenDesc = 'provider_access_token_desc',
  ProviderDataAsc = 'provider_data_asc',
  ProviderDataDesc = 'provider_data_desc',
  ProviderIdAsc = 'provider_id_asc',
  ProviderIdDesc = 'provider_id_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type CalsyncAccountUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  calsyncAccount?: Maybe<CalsyncAccountNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CalsyncCalendarCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  calsyncCalendar?: Maybe<CalsyncCalendarNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CalsyncCalendarDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  calsyncCalendar?: Maybe<CalsyncCalendarNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CalsyncCalendarInput = {
  /** If user wants the external appointments to be seen as private */
  appointmentsArePrivate?: InputMaybe<Scalars['Boolean']>;
  calsyncAccountId?: InputMaybe<Scalars['ID']>;
  /** The provider's id to sync to. */
  providerId?: InputMaybe<Scalars['String']>;
  /** One of: [unsyncing, syncing] */
  status?: InputMaybe<Scalars['String']>;
  /** The calendar provider name, e.g. Google, Outlook, etc. */
  thirdPartyName?: InputMaybe<Scalars['String']>;
};

export type CalsyncCalendarNode = Node & {
  /** If user wants the external appointments to be seen as private */
  appointmentsArePrivate?: Maybe<Scalars['Boolean']>;
  calsyncAccount?: Maybe<CalsyncAccountNode>;
  calsyncAccountId: Scalars['Int'];
  calsyncCalendarId: Scalars['ID'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  engineName: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The last date that was manually synced, usually limited by the provider's event query window. */
  lastSyncCursor?: Maybe<Scalars['DateTime']>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Arbitrary provider data for forward compatibility. */
  providerData?: Maybe<Scalars['JSONString']>;
  /** The third-party calendar ID. */
  providerId: Scalars['String'];
  relinkUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** Name of the calendar provider connected to by the engine provider. */
  thirdPartyName?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type CalsyncCalendarUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  calsyncCalendar?: Maybe<CalsyncCalendarNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CalsyncCalendarUpdateStatusPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  calsyncCalendar?: Maybe<CalsyncCalendarNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CalsyncProviderCalendarNode = {
  calendarId?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  thirdPartyName?: Maybe<Scalars['String']>;
};

export type CancelSignupReservations = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  slots?: Maybe<Array<Maybe<SlotNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum ChangeType {
  AddToRoster = 'ADD_TO_ROSTER',
  AddToTeam = 'ADD_TO_TEAM',
  AddToTeamworks = 'ADD_TO_TEAMWORKS',
  PersonDeactivated = 'PERSON_DEACTIVATED',
  PersonReactivated = 'PERSON_REACTIVATED',
  ProfileAttributeChange = 'PROFILE_ATTRIBUTE_CHANGE',
  RemoveFromRoster = 'REMOVE_FROM_ROSTER',
  RemoveFromTeam = 'REMOVE_FROM_TEAM',
  StatusChange = 'STATUS_CHANGE',
  UserTypeChange = 'USER_TYPE_CHANGE',
}

export type CheckinActionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CheckinActionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CheckinActionDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  checkinAction?: Maybe<CheckinActionNode>;
  id?: Maybe<Scalars['ID']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `CheckinAction` and its cursor. */
export type CheckinActionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CheckinActionNode>;
};

export type CheckinActionNode = Node & {
  accumulatedMinutes?: Maybe<Scalars['String']>;
  accumulatedSeconds?: Maybe<Scalars['String']>;
  checkin?: Maybe<CheckinNode>;
  checkinActionId: Scalars['ID'];
  checkinTime: Scalars['String'];
  checkinTimeRange?: Maybe<CheckinTimeRangeNode>;
  checkoutTime?: Maybe<Scalars['String']>;
  chkCheckinId: Scalars['Int'];
  chkCheckinTimeRangeId: Scalars['Int'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** notes for checkin, checkout and edit */
  notes?: Maybe<ParentNoteType>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type CheckinActionNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CheckinActionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CheckinActionNode` and its cursor. */
export type CheckinActionNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CheckinActionNode>;
};

/** An enumeration. */
export enum CheckinActionSortEnum {
  CheckinTimeAsc = 'checkin_time_asc',
  CheckinTimeDesc = 'checkin_time_desc',
  CheckoutTimeAsc = 'checkout_time_asc',
  CheckoutTimeDesc = 'checkout_time_desc',
  ChkCheckinActionIdAsc = 'chk_checkin_action_id_asc',
  ChkCheckinActionIdDesc = 'chk_checkin_action_id_desc',
  ChkCheckinIdAsc = 'chk_checkin_id_asc',
  ChkCheckinIdDesc = 'chk_checkin_id_desc',
  ChkCheckinTimeRangeIdAsc = 'chk_checkin_time_range_id_asc',
  ChkCheckinTimeRangeIdDesc = 'chk_checkin_time_range_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type CheckinConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CheckinEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CheckinDataConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CheckinDataEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CheckinData` and its cursor. */
export type CheckinDataEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CheckinDataNode>;
};

export type CheckinDataNode = {
  /** Number of minutes after checkin time that a user can checkin */
  checkinAfter?: Maybe<Scalars['Int']>;
  /** Selection code of users to checkin */
  checkinAttendeeCodes: Scalars['String'];
  /** Number of minutes before checkin time that a user is allowed to checkin */
  checkinBefore?: Maybe<Scalars['Int']>;
  checkinDataId: Scalars['ID'];
  checkinDateTime: Scalars['DateTime'];
  checkins?: Maybe<Scalars['JSONString']>;
  /** Number of minutes after checkout time that a user can checkout */
  checkoutAfter?: Maybe<Scalars['Int']>;
  /** Number of minutes before checkout time that a user is allowed to checkout */
  checkoutBefore?: Maybe<Scalars['Int']>;
  checkoutDateTime?: Maybe<Scalars['DateTime']>;
  /** Selection code for users who can checkin or checkout on behalf of attendees */
  checkoutOnBehalfCodes?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  isCheckinRequired: Scalars['Boolean'];
  isCheckoutRequired: Scalars['Boolean'];
  isCurrent?: Maybe<Scalars['String']>;
  /** Soft delete for the checkin */
  isDeleted: Scalars['Boolean'];
  /** Checkin expires after checkout_after time */
  isExpired: Scalars['Boolean'];
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of labelXlt */
  label?: Maybe<Scalars['String']>;
  /** Deprecated */
  labelXlt?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CheckinDataSortEnum {
  CheckinAfterAsc = 'checkin_after_asc',
  CheckinAfterDesc = 'checkin_after_desc',
  CheckinAttendeeCodesAsc = 'checkin_attendee_codes_asc',
  CheckinAttendeeCodesDesc = 'checkin_attendee_codes_desc',
  CheckinBeforeAsc = 'checkin_before_asc',
  CheckinBeforeDesc = 'checkin_before_desc',
  CheckinDateTimeAsc = 'checkin_date_time_asc',
  CheckinDateTimeDesc = 'checkin_date_time_desc',
  CheckinsAsc = 'checkins_asc',
  CheckinsDesc = 'checkins_desc',
  CheckoutAfterAsc = 'checkout_after_asc',
  CheckoutAfterDesc = 'checkout_after_desc',
  CheckoutBeforeAsc = 'checkout_before_asc',
  CheckoutBeforeDesc = 'checkout_before_desc',
  CheckoutDateTimeAsc = 'checkout_date_time_asc',
  CheckoutDateTimeDesc = 'checkout_date_time_desc',
  CheckoutOnBehalfCodesAsc = 'checkout_on_behalf_codes_asc',
  CheckoutOnBehalfCodesDesc = 'checkout_on_behalf_codes_desc',
  ChkCheckinDataIdAsc = 'chk_checkin_data_id_asc',
  ChkCheckinDataIdDesc = 'chk_checkin_data_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsCheckinRequiredAsc = 'is_checkin_required_asc',
  IsCheckinRequiredDesc = 'is_checkin_required_desc',
  IsCheckoutRequiredAsc = 'is_checkout_required_asc',
  IsCheckoutRequiredDesc = 'is_checkout_required_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  IsExpiredAsc = 'is_expired_asc',
  IsExpiredDesc = 'is_expired_desc',
  LabelXltAsc = 'label_xlt_asc',
  LabelXltDesc = 'label_xlt_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** A Relay edge containing a `Checkin` and its cursor. */
export type CheckinEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CheckinNode>;
};

export type CheckinNode = {
  checkinActions?: Maybe<CheckinActionNodeConnection>;
  checkinId: Scalars['ID'];
  checkinTimeRange?: Maybe<CheckinTimeRangeConnection>;
  chkCheckinTypeId: Scalars['Int'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  isCheckinRequired: Scalars['Boolean'];
  isCheckoutRequired: Scalars['Boolean'];
  /** Soft delete for the checkin */
  isDeleted: Scalars['Boolean'];
  /** Checkin expires after checkout_after time */
  isExpired: Scalars['Boolean'];
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of labelXlt */
  label?: Maybe<Scalars['String']>;
  /** Deprecated */
  labelXlt?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type CheckinNodeCheckinActionsArgs = {
  accumulatedMinutes?: InputMaybe<Scalars['TWFilter_String']>;
  accumulatedSeconds?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  checkinActionId?: InputMaybe<Scalars['TWFilter_ID']>;
  checkinTime?: InputMaybe<Scalars['TWFilter_String']>;
  checkoutTime?: InputMaybe<Scalars['TWFilter_String']>;
  chkCheckinId?: InputMaybe<Scalars['TWFilter_Int']>;
  chkCheckinTimeRangeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['TWFilter_JSONString']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CheckinActionSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type CheckinNodeCheckinTimeRangeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  checkinTimeRangeId?: InputMaybe<Scalars['TWFilter_ID']>;
  chkCheckinId?: InputMaybe<Scalars['TWFilter_Int']>;
  closeTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  day?: InputMaybe<Scalars['TWFilter_DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  openTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CheckinTimeRangeSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type CheckinTimeRangeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CheckinTimeRangeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CheckinTimeRange` and its cursor. */
export type CheckinTimeRangeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CheckinTimeRangeNode>;
};

export type CheckinTimeRangeNode = {
  checkin?: Maybe<CheckinNode>;
  checkinTimeRangeId: Scalars['ID'];
  chkCheckinId: Scalars['Int'];
  closeTime: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  day: Scalars['DateTime'];
  openTime: Scalars['DateTime'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CheckinTimeRangeSortEnum {
  ChkCheckinIdAsc = 'chk_checkin_id_asc',
  ChkCheckinIdDesc = 'chk_checkin_id_desc',
  ChkCheckinTimeRangeIdAsc = 'chk_checkin_time_range_id_asc',
  ChkCheckinTimeRangeIdDesc = 'chk_checkin_time_range_id_desc',
  CloseTimeAsc = 'close_time_asc',
  CloseTimeDesc = 'close_time_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DayAsc = 'day_asc',
  DayDesc = 'day_desc',
  OpenTimeAsc = 'open_time_asc',
  OpenTimeDesc = 'open_time_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Updates the active user's session metadata and returns the redirect url to the frontend */
export type ChooseAccount = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  currentUserData?: Maybe<CurrentUserData>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ChooseRoleInput = {
  completionRole: Scalars['String'];
  externalAssignee?: InputMaybe<ExternalAssigneeInput>;
  internalAssignee?: InputMaybe<Scalars['String']>;
};

export type ChooseRolesInput = {
  externalLinkUuid?: InputMaybe<Scalars['String']>;
  roleChoices: Array<InputMaybe<ChooseRoleInput>>;
};

export type CloseSignupSlots = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  slots?: Maybe<Array<Maybe<SlotNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum CommonCommentSortEnum {
  CommentContextAsc = 'comment_context_asc',
  CommentContextDesc = 'comment_context_desc',
  CommentObjectIdAsc = 'comment_object_id_asc',
  CommentObjectIdDesc = 'comment_object_id_desc',
  CommentParentIdAsc = 'comment_parent_id_asc',
  CommentParentIdDesc = 'comment_parent_id_desc',
  CommonCommentIdAsc = 'common_comment_id_asc',
  CommonCommentIdDesc = 'common_comment_id_desc',
  ContentAsc = 'content_asc',
  ContentDesc = 'content_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsPlaintextAsc = 'is_plaintext_asc',
  IsPlaintextDesc = 'is_plaintext_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** GraphQL node for a travel competition */
export type CompetitionNode = Node & {
  awayInstitution?: Maybe<InstitutionNode>;
  awayInstitutionId?: Maybe<Scalars['Int']>;
  awayLogoId?: Maybe<Scalars['Int']>;
  bulkCreated: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  competitionId: Scalars['ID'];
  competitionState?: Maybe<StateNode>;
  countryIso?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  customOpponent?: Maybe<Scalars['String']>;
  customOpponentLogo?: Maybe<Scalars['String']>;
  exportsLeftMargin?: Maybe<Scalars['String']>;
  hasCustomOpponent: Scalars['Boolean'];
  homeInstitution?: Maybe<InstitutionNode>;
  homeInstitutionId?: Maybe<Scalars['Int']>;
  homeLogoId?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isHost: Scalars['Boolean'];
  isTimeDetermined?: Maybe<Scalars['Boolean']>;
  isVisibleMobile: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  opponentInstitution?: Maybe<InstitutionNode>;
  opponentInstitutionId?: Maybe<Scalars['Int']>;
  opponentLogoId?: Maybe<Scalars['Int']>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  pregameVisibilities?: Maybe<Array<Maybe<Scalars['String']>>>;
  season?: Maybe<SeasonObjectType>;
  seasonId: Scalars['Int'];
  slideTimeInterval?: Maybe<TimeDeltaNode>;
  startDate?: Maybe<Scalars['DateTime']>;
  /** Competition's start date as a pendulum object set timezone aware and then set to UTC */
  startDateTime?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['Int']>;
  timeZoneId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  travelCredentialAssignments?: Maybe<TravelCredentialAssignmentNodeConnection>;
  travelEvent?: Maybe<TravelEventNodeConnection>;
  /** Deprecated. Use competition_id on travel_event instead */
  travelEventId?: Maybe<Scalars['Int']>;
  travelPregameItems?: Maybe<TravelPregameItemNodeConnection>;
  trip?: Maybe<Trip>;
  tripId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** GraphQL node for a travel competition */
export type CompetitionNodeTravelCredentialAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  quantity?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<TravelCredentialAssignmentSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelCredentialAssignmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  travelCredentialId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** GraphQL node for a travel competition */
export type CompetitionNodeTravelEventArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  before?: InputMaybe<Scalars['String']>;
  caraRequired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TravelEventSortEnum>>>;
  timeZoneId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelEventId?: InputMaybe<Scalars['TWFilter_ID']>;
  travelEventTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tripId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** GraphQL node for a travel competition */
export type CompetitionNodeTravelPregameItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  minutesBeforeStart?: InputMaybe<Scalars['TWFilter_Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  secondsBeforeStart?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<TravelPregameItemSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  timeBeforeStartSeconds?: InputMaybe<Scalars['TWFilter_Int']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  travelPregameItemId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type CompetitionPregameVisibilityInput = {
  pregameVisibilities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CompetitionSlideTimeIntervalInput = {
  slideTimeInterval: TimeDeltaInput;
};

export type CompleteFormInput = {
  answerData?: InputMaybe<Array<InputMaybe<AnswerInput>>>;
  completionRole: Scalars['String'];
  externalLinkUuid?: InputMaybe<Scalars['String']>;
};

export type CompleteFormPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignmentCompletionRole?: Maybe<FormAssignmentCompletionRoleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CompletionRoleSendReminderInput = {
  /** Type of reminder */
  reminderType: FormReminderTypeGraphene;
};

export type ComplianceAudit = Node & {
  appointmentComplianceAuditId: Scalars['ID'];
  changeTime?: Maybe<Scalars['GraphenePendulumDateTime']>;
  comments?: Maybe<Array<Maybe<ComplianceAuditComment>>>;
  conflictReasonCode?: Maybe<Scalars['String']>;
  conflictReasonText?: Maybe<Scalars['String']>;
  conflicts?: Maybe<Scalars['JSONString']>;
  conflictsList?: Maybe<Array<Maybe<AuditConflictReport>>>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  diff?: Maybe<ComplianceAuditDiff>;
  diffJson: Scalars['JSONString'];
  /** The ID of the object. */
  id: Scalars['ID'];
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  reviewDate?: Maybe<Scalars['DateTime']>;
  reviewStatus?: Maybe<Scalars['String']>;
  reviewedBy?: Maybe<Scalars['Int']>;
  teamIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  teams?: Maybe<Array<Maybe<TeamNode>>>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type ComplianceAuditAddCommentInput = {
  commentNote: Scalars['String'];
};

export type ComplianceAuditAddCommentPayload = {
  appointmentComplianceAudit?: Maybe<ComplianceAudit>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ComplianceAuditApptChanges = {
  appointmentType?: Maybe<StringDiff>;
  attendees?: Maybe<AttendeeDiff>;
  complianceType?: Maybe<StringDiff>;
  countableMinutes?: Maybe<IntDiff>;
  endDate?: Maybe<TimeDiff>;
  location?: Maybe<StringDiff>;
  recurRule?: Maybe<StringDiff>;
  startDate?: Maybe<TimeDiff>;
  startTimeZone?: Maybe<StringDiff>;
  title?: Maybe<StringDiff>;
};

export type ComplianceAuditApptSnapshot = {
  action?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<Scalars['String']>;
  athleteSelectionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  attendeeSelectionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changeType?: Maybe<Scalars['String']>;
  complianceType?: Maybe<Scalars['String']>;
  conflictDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  countableMinutes?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['GraphenePendulumDateTime']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  recurRule?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['GraphenePendulumDateTime']>;
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ComplianceAuditComment = Node & {
  appointmentComplianceAudit?: Maybe<ComplianceAudit>;
  appointmentComplianceAuditId?: Maybe<Scalars['Int']>;
  appointmentComplianceAuditReviewId: Scalars['ID'];
  attachments?: Maybe<Array<Maybe<SharedFileNode>>>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type ComplianceAuditConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ComplianceAuditEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ComplianceAuditDiff = {
  changes?: Maybe<ComplianceAuditApptChanges>;
  original?: Maybe<ComplianceAuditApptSnapshot>;
};

/** A Relay edge containing a `ComplianceAudit` and its cursor. */
export type ComplianceAuditEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ComplianceAudit>;
};

export type ComplianceAuditsChangeReviewStatusInput = {
  auditIds: Array<InputMaybe<Scalars['ID']>>;
  reviewStatus: ReviewStatus;
};

export type ComplianceAuditsChangeReviewStatusPayload = {
  appointmentComplianceAudit?: Maybe<Array<Maybe<ComplianceAudit>>>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type Conflict = {
  actions?: Maybe<TmpYearRule>;
  conflictValue?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
  limitValue?: Maybe<Scalars['Int']>;
  rule?: Maybe<TmpRule>;
  users?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export enum ConflictType {
  AdequateNotice = 'adequateNotice',
  All = 'all',
  Any = 'any',
  DayOffAdditional = 'dayOffAdditional',
  DayOffWeekly = 'dayOffWeekly',
  NoConflict = 'noConflict',
}

export type ContactCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ContactDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ContactUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type Conversation = Node & {
  /** Background color of the conversation window */
  color?: Maybe<Scalars['String']>;
  conversationPersons?: Maybe<Array<Maybe<ConversationPersonObjectType>>>;
  conversationType?: Maybe<ConversationType>;
  /** Conversation Type */
  conversationTypeId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Whether or not the conversation should be visible or archived */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Title of the conversation */
  label?: Maybe<Scalars['String']>;
  /** Last Message's date_time it was added to the conversation in UTC */
  lastMessageDateTime?: Maybe<Scalars['String']>;
  /** The id of the most recent message */
  lastMessageId?: Maybe<Scalars['Int']>;
  lastMessageRecord?: Maybe<Message>;
  /** The text of the most recent message */
  lastMessageText?: Maybe<Scalars['String']>;
  messages?: Maybe<MessageConnection>;
  /** The organizational owner of this conversation */
  orgId?: Maybe<Scalars['Int']>;
  /** URL of a pictoral header to this conversation */
  picture?: Maybe<Scalars['String']>;
  /** Returns a link to the conversation's picture */
  pictureDownloadUrl?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type ConversationMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<MessageSortEnum>>>;
};

export type ConversationAvatarSignedUrl = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  url?: Maybe<Scalars['String']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ConversationConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConversationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Conversation` and its cursor. */
export type ConversationEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Conversation>;
};

export type ConversationPersonObjectType = {
  /** conversation_id */
  conversationId?: Maybe<Scalars['Int']>;
  /** conversation_person_id */
  conversationPersonId: Scalars['ID'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** If this person is currently a member of the conversation */
  isActive?: Maybe<Scalars['Boolean']>;
  lastReadMessage?: Maybe<Message>;
  /** The last message tha this person has read */
  lastReadMessageId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** person_id */
  personId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Num messages this person has not yet read in this conversation */
  unreadCount?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ConversationSortEnum {
  ColorAsc = 'color_asc',
  ColorDesc = 'color_desc',
  ConversationIdAsc = 'conversation_id_asc',
  ConversationIdDesc = 'conversation_id_desc',
  ConversationTypeIdAsc = 'conversation_type_id_asc',
  ConversationTypeIdDesc = 'conversation_type_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  LastMessageAsc = 'last_message_asc',
  LastMessageDateAsc = 'last_message_date_asc',
  LastMessageDateDesc = 'last_message_date_desc',
  LastMessageDesc = 'last_message_desc',
  LastMessageIdAsc = 'last_message_id_asc',
  LastMessageIdDesc = 'last_message_id_desc',
  MembersArrayAsc = 'members_array_asc',
  MembersArrayDesc = 'members_array_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PictureAsc = 'picture_asc',
  PictureDesc = 'picture_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type ConversationType = {
  /** Label */
  label?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
};

export type ConversationTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConversationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ConversationType` and its cursor. */
export type ConversationTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ConversationType>;
};

/** An enumeration. */
export enum ConversationTypeSortEnum {
  ConversationTypeIdAsc = 'conversation_type_id_asc',
  ConversationTypeIdDesc = 'conversation_type_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type CountableEventNode = {
  countableHours?: Maybe<Scalars['String']>;
  dateString?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  event?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type CountableSummaryNode = {
  appointmentType?: Maybe<Scalars['String']>;
  countableMinutes?: Maybe<Scalars['Int']>;
};

export type CountryNode = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  iso: Scalars['ID'];
  iso3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numcode?: Maybe<Scalars['String']>;
  printableName?: Maybe<Scalars['String']>;
};

export type CountryNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CountryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CountryNode` and its cursor. */
export type CountryNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CountryNode>;
};

export type CourseConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Course input for creating Courses. */
export type CourseCreateInput = {
  /** Amount of credit hours this course is. */
  credits?: InputMaybe<Scalars['Float']>;
  /** Description for the course.  Ex: 'Algebra I'. */
  description: Scalars['String'];
  /** String field showing which course number this is.  Ex: 101 */
  label: Scalars['String'];
  /** Global ID for the Subject this course is in. */
  subjectId: Scalars['String'];
};

export type CourseCreatePayload = {
  Output?: Maybe<CourseNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  course?: Maybe<CourseNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `Course` and its cursor. */
export type CourseEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CourseNode>;
};

export type CourseNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  courseId: Scalars['ID'];
  courseSections?: Maybe<CourseSectionConnection>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  credits?: Maybe<Scalars['String']>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of descriptionXlt */
  description?: Maybe<Scalars['String']>;
  /** Deprecated */
  descriptionXlt?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  /** Flags whether or not a course will be available to all students or require enrollments */
  isGeneral: Scalars['Boolean'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of labelXlt */
  label?: Maybe<Scalars['String']>;
  /** Deprecated */
  labelXlt?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  subject?: Maybe<SubjectNode>;
  subjectId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type CourseNodeCourseSectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isOnline?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorId?: InputMaybe<Scalars['TWFilter_Int']>;
  shortCode?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CourseSectionSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type CourseSectionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseSectionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** CourseSection Input for creating CourseSections and their related CourseSectionSchedules. */
export type CourseSectionCreateInput = {
  /** Global ID for the course this CourseSection is for. Required. */
  courseId: Scalars['String'];
  /** List of CourseSectionSchedules for the CourseSection. */
  courseSectionSchedules: Array<InputMaybe<CourseSectionScheduleCreateSubInput>>;
  /** Date the CourseSection ends, separate from the term end date. */
  endDate: Scalars['Date'];
  /** Optional list of Enrollments for the CourseSection. */
  enrollments?: InputMaybe<Array<InputMaybe<EnrollmentCreateSubInput>>>;
  /** Signifies whether or not the course is online. */
  isOnline?: InputMaybe<Scalars['Boolean']>;
  /** Global ID for the professor for the CourseSection. */
  professorId?: InputMaybe<Scalars['String']>;
  /** The short code for the CourseSection. Example: 002. Required. */
  shortCode: Scalars['String'];
  /** Date the CourseSection starts, separate from the term start date. */
  startDate: Scalars['Date'];
  /** Global ID for the term that this CourseSection is part of. Required. */
  termId: Scalars['String'];
};

export type CourseSectionCreatePayload = {
  Output?: Maybe<CourseSectionNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  courseSection?: Maybe<CourseSectionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `CourseSection` and its cursor. */
export type CourseSectionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CourseSectionNode>;
};

export type CourseSectionNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  course?: Maybe<CourseNode>;
  /** The associated course */
  courseId: Scalars['Int'];
  courseSectionId: Scalars['ID'];
  courseSectionSchedules?: Maybe<CourseSectionScheduleConnection>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  /** Date the class ends, separate from term start and end date */
  endDate?: Maybe<Scalars['String']>;
  enrollments?: Maybe<EnrollmentConnection>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  /** Set if the course is online-only */
  isOnline: Scalars['Boolean'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  professor?: Maybe<ProfessorNode>;
  /** Professor teaching the course */
  professorId?: Maybe<Scalars['Int']>;
  /** The short code for the course section */
  shortCode: Scalars['String'];
  /** Date the class starts, separate from term start and end date */
  startDate?: Maybe<Scalars['String']>;
  term?: Maybe<TermNode>;
  /** The associated term */
  termId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type CourseSectionNodeCourseSectionSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseSectionScheduleId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  day?: InputMaybe<Scalars['TWFilter_DaysOfWeekEnum']>;
  endTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CourseSectionScheduleSortEnum>>>;
  startTime?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type CourseSectionNodeEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  creditsAttempted?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  droppedDate?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['TWFilter_Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  score?: InputMaybe<Scalars['TWFilter_Float']>;
  sort?: InputMaybe<Array<InputMaybe<EnrollmentSortEnum>>>;
  studentTermId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type CourseSectionScheduleConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseSectionScheduleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** CourseSectionSchedule Input for creating CourseSectionSchedules on CourseSectionSchedules. */
export type CourseSectionScheduleCreateSubInput = {
  /** String denoting what day of the week this CourseSectionSchedule is for. */
  day: Scalars['String'];
  /** End time of the CourseSectionSchedule. */
  endTime?: InputMaybe<Scalars['Time']>;
  /** Location for this CourseSectionSchedule. */
  location?: InputMaybe<Scalars['String']>;
  /** Start time of the CourseSectionSchedule. */
  startTime?: InputMaybe<Scalars['Time']>;
};

/** A Relay edge containing a `CourseSectionSchedule` and its cursor. */
export type CourseSectionScheduleEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CourseSectionScheduleNode>;
};

export type CourseSectionScheduleNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  courseSection?: Maybe<CourseSectionNode>;
  courseSectionId: Scalars['Int'];
  courseSectionScheduleId: Scalars['ID'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  /** Enum representing a day of the week */
  day: DaysOfWeekEnum;
  /** The time the class ends */
  endTime?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  /** The location that the class is held in */
  location?: Maybe<Scalars['String']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** The time the class meets */
  startTime?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CourseSectionScheduleSortEnum {
  Ac2CourseSectionScheduleIdAsc = 'ac2_course_section_schedule_id_asc',
  Ac2CourseSectionScheduleIdDesc = 'ac2_course_section_schedule_id_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CourseSectionIdAsc = 'course_section_id_asc',
  CourseSectionIdDesc = 'course_section_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  DayAsc = 'day_asc',
  DayDesc = 'day_desc',
  EndTimeAsc = 'end_time_asc',
  EndTimeDesc = 'end_time_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  LocationAsc = 'location_asc',
  LocationDesc = 'location_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  StartTimeAsc = 'start_time_asc',
  StartTimeDesc = 'start_time_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** An enumeration. */
export enum CourseSectionSortEnum {
  Ac2CourseSectionIdAsc = 'ac2_course_section_id_asc',
  Ac2CourseSectionIdDesc = 'ac2_course_section_id_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CourseIdAsc = 'course_id_asc',
  CourseIdDesc = 'course_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  EndDateAsc = 'end_date_asc',
  EndDateDesc = 'end_date_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  IsOnlineAsc = 'is_online_asc',
  IsOnlineDesc = 'is_online_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ProfessorIdAsc = 'professor_id_asc',
  ProfessorIdDesc = 'professor_id_desc',
  ShortCodeAsc = 'short_code_asc',
  ShortCodeDesc = 'short_code_desc',
  StartDateAsc = 'start_date_asc',
  StartDateDesc = 'start_date_desc',
  TermIdAsc = 'term_id_asc',
  TermIdDesc = 'term_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** CourseSection Input for updating a CourseSection and it's related CourseSectionSchedules. */
export type CourseSectionUpdateInput = {
  /** Optional list of Student Global IDs to add enrollments for the CourseSection. */
  addEnrollments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** List of CourseSectionSchedules for the CourseSection. */
  courseSectionSchedules?: InputMaybe<Array<InputMaybe<CourseSectionScheduleCreateSubInput>>>;
  /** Optional list of Student Global IDs to delete enrollments for the CourseSection. */
  deleteEnrollments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Date the CourseSection ends, separate from the term end date. */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Signifies whether or not the course is online. */
  isOnline?: InputMaybe<Scalars['Boolean']>;
  /** Global ID for the professor for the CourseSection. */
  professorId?: InputMaybe<Scalars['String']>;
  /** Date the CourseSection starts, separate from the term start date. */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Global ID for the term that this CourseSection is part of. */
  termId?: InputMaybe<Scalars['String']>;
};

export type CourseSectionUpdatePayload = {
  Output?: Maybe<CourseSectionNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  courseSection?: Maybe<CourseSectionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum CourseSortEnum {
  Ac2CourseIdAsc = 'ac2_course_id_asc',
  Ac2CourseIdDesc = 'ac2_course_id_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CreditsAsc = 'credits_asc',
  CreditsDesc = 'credits_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  DescriptionXltAsc = 'description_xlt_asc',
  DescriptionXltDesc = 'description_xlt_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  IsGeneralAsc = 'is_general_asc',
  IsGeneralDesc = 'is_general_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  LabelXltAsc = 'label_xlt_asc',
  LabelXltDesc = 'label_xlt_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  SubjectIdAsc = 'subject_id_asc',
  SubjectIdDesc = 'subject_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Course input for updating Courses. */
export type CourseUpdateInput = {
  /** Amount of credit hours this course is. */
  credits?: InputMaybe<Scalars['Float']>;
  /** Description for the course.  Ex: 'Algebra I'. */
  description?: InputMaybe<Scalars['String']>;
  /** String field showing which course number this is.  Ex: 101 */
  label?: InputMaybe<Scalars['String']>;
};

export type CourseUpdatePayload = {
  Output?: Maybe<CourseNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  course?: Maybe<CourseNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CreateAppointmentFromSessionPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  session?: Maybe<SessionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CreateMessageFromRetainPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  message?: Maybe<Message>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CreateSignupReservations = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  slots?: Maybe<Array<Maybe<SlotNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** The current user data used by F.E app */
export type CurrentUserData = {
  orgId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
};

export type CustomAttributeInput = {
  attribute?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CustomGroupCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customGroup?: Maybe<CustomGroupNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CustomGroupDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customGroup?: Maybe<CustomGroupNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CustomGroupInput = {
  name?: InputMaybe<Scalars['String']>;
  selectionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  team?: InputMaybe<Scalars['ID']>;
};

/** GraphQL node for a custom group */
export type CustomGroupNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  /** Person who created this custom group */
  creator?: Maybe<PersonNode>;
  customGroupId: Scalars['ID'];
  groupIsPublic?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Number of members of this custom group */
  memberCount?: Maybe<Scalars['Int']>;
  /** Members of this custom group */
  members?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  name: Scalars['String'];
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  selections?: Maybe<Scalars['JSONString']>;
  /** Team this custom group belongs to */
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type CustomGroupNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomGroupNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomGroupNode` and its cursor. */
export type CustomGroupNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomGroupNode>;
};

/** An enumeration. */
export enum CustomGroupSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomGroupIdAsc = 'custom_group_id_asc',
  CustomGroupIdDesc = 'custom_group_id_desc',
  GroupIsPublicAsc = 'group_is_public_asc',
  GroupIsPublicDesc = 'group_is_public_desc',
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SelectionsAsc = 'selections_asc',
  SelectionsDesc = 'selections_desc',
  SportSchoolIdAsc = 'sport_school_id_asc',
  SportSchoolIdDesc = 'sport_school_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type CustomGroupUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customGroup?: Maybe<CustomGroupNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CustomGroupsConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomGroupsEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CustomGroups` and its cursor. */
export type CustomGroupsEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomGroupNode>;
};

export type CustomModule = {
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type CustomTaskAssignerType = {
  assignerPrettyValue?: Maybe<Scalars['String']>;
  assignerRawValues?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** custom task assignment bulk actions */
export enum CustomTaskAssignmentBulkActions {
  ManuallyComplete = 'manually_complete',
  Unassign = 'unassign',
}

export type CustomTaskAssignmentBulkActionsInput = {
  bulkOperation: CustomTaskAssignmentBulkActions;
  customTaskAssignmentIds: Array<InputMaybe<Scalars['String']>>;
  message?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['Int']>;
};

export type CustomTaskAssignmentBulkActionsPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customTaskAssignment?: Maybe<Array<Maybe<CustomTaskAssignmentNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CustomTaskAssignmentCompletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customTaskAssignment?: Maybe<CustomTaskAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A GraphQL connection for custom task assignments. */
export type CustomTaskAssignmentConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomTaskAssignmentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CustomTaskAssignment` and its cursor. */
export type CustomTaskAssignmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomTaskAssignmentNode>;
};

/** Represents a custom task assignment node in GraphQL */
export type CustomTaskAssignmentNode = Node & {
  /** The person assigned to this custom task */
  assignee?: Maybe<PersonNode>;
  completedDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  customTask?: Maybe<CustomTaskNode>;
  /** custom_task_assignment_id */
  customTaskAssignmentId: Scalars['String'];
  customTaskId?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isCompleted?: Maybe<Scalars['String']>;
  manuallyCompletedBy?: Maybe<Scalars['Int']>;
  manuallyCompletedDate?: Maybe<Scalars['DateTime']>;
  manuallyCompletedNote?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  status?: Maybe<CustomTaskAssignmentStatus>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CustomTaskAssignmentSortEnum {
  CompletedDateAsc = 'completed_date_asc',
  CompletedDateDesc = 'completed_date_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomTaskAssignmentIdAsc = 'custom_task_assignment_id_asc',
  CustomTaskAssignmentIdDesc = 'custom_task_assignment_id_desc',
  CustomTaskIdAsc = 'custom_task_id_asc',
  CustomTaskIdDesc = 'custom_task_id_desc',
  LastNameAsc = 'last_name_asc',
  LastNameDesc = 'last_name_desc',
  ManuallyCompletedByAsc = 'manually_completed_by_asc',
  ManuallyCompletedByDesc = 'manually_completed_by_desc',
  ManuallyCompletedDateAsc = 'manually_completed_date_asc',
  ManuallyCompletedDateDesc = 'manually_completed_date_desc',
  ManuallyCompletedNoteAsc = 'manually_completed_note_asc',
  ManuallyCompletedNoteDesc = 'manually_completed_note_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** custom task assignment statuses */
export enum CustomTaskAssignmentStatus {
  Complete = 'complete',
  Incomplete = 'incomplete',
  ManuallyCompleted = 'manually_completed',
  Unassigned = 'unassigned',
}

/** Represents a custom task assignment status count node in GraphQL */
export type CustomTaskAssignmentStatusCountNode = {
  /** The count of custom task assignments with this status */
  count?: Maybe<Scalars['Int']>;
  /** The status of the custom task assignment */
  status?: Maybe<Scalars['String']>;
};

export type CustomTaskAssignmentsExportConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomTaskAssignmentsExportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CustomTaskAssignmentsExportCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customTaskAssignmentsExport?: Maybe<CustomTaskAssignmentsExportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `CustomTaskAssignmentsExport` and its cursor. */
export type CustomTaskAssignmentsExportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomTaskAssignmentsExportNode>;
};

export type CustomTaskAssignmentsExportInput = {
  /** CustomTask Global ID */
  customTask?: InputMaybe<Scalars['ID']>;
  /** Export type - excel or pdf */
  exportType: CustomTaskAssignmentsExportType;
  /** Search by name */
  searchName?: InputMaybe<Scalars['String']>;
  /** Filters tasks that matches any status from list */
  statuses?: InputMaybe<Array<InputMaybe<CustomTaskAssignmentStatus>>>;
};

export type CustomTaskAssignmentsExportNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  customTaskAssignmentsExportId: Scalars['ID'];
  downloadableFileId: Scalars['ID'];
  errorMessage?: Maybe<Scalars['String']>;
  fileNameServer?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** What kind of downloaded file this is, e.g. 'report'. */
  kind: Scalars['String'];
  /** Forward-compatible metadata column. */
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<OrgNode>;
  /** The Teamworks Org Id that this is associated with this */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Teamworks Person Id that this is associated with this */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Status of the file, i.e. one of `&.STATUS_*`. */
  status: Scalars['String'];
  team?: Maybe<TeamNode>;
  /** The Teamworks Team Id that this is associated with this */
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** Cached copy of the public URL. */
  url?: Maybe<Scalars['String']>;
};

/** Represents file type for expprt */
export enum CustomTaskAssignmentsExportType {
  Excel = 'excel',
  Pdf = 'pdf',
}

/** Defines the input arguments for creating a custom task */
export type CustomTaskBulkDeactivateInput = {
  /** List of custom task ids for the custom tasks to be deactivated */
  customTaskIds: Array<Scalars['ID']>;
};

export type CustomTaskBulkDeactivatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customTask?: Maybe<Array<Maybe<CustomTaskNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CustomTaskCompletionType = {
  assignedDate?: Maybe<Scalars['DateTime']>;
  assignerPrettyValue?: Maybe<Scalars['String']>;
  completedDate?: Maybe<Scalars['DateTime']>;
  customTaskAssignmentId?: Maybe<Scalars['String']>;
  customTaskDescription?: Maybe<Scalars['String']>;
  customTaskId?: Maybe<Scalars['String']>;
  customTaskName?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  manuallyCompletedDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
};

/** Connection for custom tasks */
export type CustomTaskConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomTaskEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Defines the input arguments for creating a custom task */
export type CustomTaskCreateInput = {
  alertNotificationType?: InputMaybe<Scalars['String']>;
  assignees?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  customTaskLabels?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customTaskType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CustomTaskCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customTask?: Maybe<CustomTaskNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `CustomTask` and its cursor. */
export type CustomTaskEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomTaskNode>;
};

/** Connection for custom task labels */
export type CustomTaskLabelConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomTaskLabelEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CustomTaskLabelCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customTaskLabel?: Maybe<CustomTaskLabelNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type CustomTaskLabelDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customTaskLabel?: Maybe<CustomTaskLabelNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `CustomTaskLabel` and its cursor. */
export type CustomTaskLabelEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomTaskLabelNode>;
};

/** Defines the input arguments for creating or updating a custom task label */
export type CustomTaskLabelInput = {
  label?: InputMaybe<Scalars['String']>;
};

/** Represents a custom task label node in GraphQL */
export type CustomTaskLabelNode = Node & {
  /** The number of tasks associated with this label */
  associatedTaskCount?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** custom_task_label_id */
  customTaskLabelId: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CustomTaskLabelSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomTaskLabelIdAsc = 'custom_task_label_id_asc',
  CustomTaskLabelIdDesc = 'custom_task_label_id_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type CustomTaskLabelUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customTaskLabel?: Maybe<CustomTaskLabelNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Represents a custom task node in GraphQL */
export type CustomTaskNode = Node & {
  alertNotificationType?: Maybe<AlertNotificationType>;
  /** The count of tasks by status */
  assignmentCountByStatus?: Maybe<Array<Maybe<CustomTaskAssignmentStatusCountNode>>>;
  /** The custom task assignments for this custom task. This is only available when querying a single custom task. */
  assignments?: Maybe<CustomTaskAssignmentConnection>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** custom_task_id */
  customTaskId: Scalars['ID'];
  customTaskType?: Maybe<CustomTaskType>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  /** The labels for this custom task */
  labels?: Maybe<Array<Maybe<CustomTaskLabelNode>>>;
  name?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  status?: Maybe<CustomTaskStatus>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Represents a custom task node in GraphQL */
export type CustomTaskNodeAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  completedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customTaskAssignmentId?: InputMaybe<Scalars['TWFilter_String']>;
  customTaskId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  isCompleted?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  manuallyCompletedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  manuallyCompletedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  manuallyCompletedNote?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchName?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<CustomTaskAssignmentSortEnum>>>;
  status?: InputMaybe<Scalars['TWFilter_CustomTaskAssignmentStatus']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum CustomTaskSortEnum {
  AlertNotificationTypeAsc = 'alert_notification_type_asc',
  AlertNotificationTypeDesc = 'alert_notification_type_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomTaskIdAsc = 'custom_task_id_asc',
  CustomTaskIdDesc = 'custom_task_id_desc',
  CustomTaskTypeAsc = 'custom_task_type_asc',
  CustomTaskTypeDesc = 'custom_task_type_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  DueDateAsc = 'due_date_asc',
  DueDateDesc = 'due_date_desc',
  ExpirationDateAsc = 'expiration_date_asc',
  ExpirationDateDesc = 'expiration_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** custom task statuses */
export enum CustomTaskStatus {
  Complete = 'complete',
  Deactivated = 'deactivated',
  Incomplete = 'incomplete',
}

/** Types of custom tasks */
export enum CustomTaskType {
  General = 'general',
  Task = 'task',
}

/** Defines the input arguments for creating a custom task */
export type CustomTaskUpdateInput = {
  alertNotificationType?: InputMaybe<Scalars['String']>;
  assignees?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**
   * List of custom task label ids to assign to the task.
   *         If None, labels will be unchanged. If an empty list, all labels will be removed.
   */
  customTaskLabels?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customTaskType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CustomTaskUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  customTask?: Maybe<CustomTaskNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A node for customer branding data. */
export type CustomerBrandingNode = {
  /** has_custom_ui_colors */
  hasCustomUiColors?: Maybe<Scalars['Boolean']>;
  /** header_bg_color */
  headerBgColor?: Maybe<Scalars['String']>;
  /** header_link_color */
  headerLinkColor?: Maybe<Scalars['String']>;
  /** header_text_color */
  headerTextColor?: Maybe<Scalars['String']>;
  /** primary_color */
  primaryColor?: Maybe<Scalars['String']>;
  /** primary_lighter_color */
  primaryLighterColor?: Maybe<Scalars['String']>;
  /** primary_lightest_color */
  primaryLightestColor?: Maybe<Scalars['String']>;
};

export type DateRangeInput = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type DateRangeObjectType = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

/** for postgres TSTZRANGE */
export type DateTimePeriodNode = {
  endDatetime?: Maybe<Scalars['DateTime']>;
  startDatetime?: Maybe<Scalars['DateTime']>;
};

export type DateToLabelNode = {
  date?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum DaysOfWeekEnum {
  Friday = 'friday',
  Monday = 'monday',
  Online = 'online',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
}

export type DeactivateApptCheckinOccurrenceInput = {
  appointmentCheckinOccurrenceId?: InputMaybe<Scalars['UUID']>;
};

export type DeactivateAssignmentArgsInput = {
  assignment: Scalars['String'];
};

export type DeactivateAssignmentPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type DeclineFormInput = {
  completionRole: Scalars['String'];
};

export type DeclineFormPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignmentCompletionRole?: Maybe<FormAssignmentCompletionRoleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type DeletePartyPersonPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type DeleteSignupInput = {
  cascadeBehavior?: InputMaybe<Scalars['String']>;
  signupId?: InputMaybe<Scalars['UUID']>;
};

export type DeleteSignups = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  deletedIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type DeleteTravelPartyPersonInput = {
  person: Scalars['ID'];
  removeExclusion?: InputMaybe<Scalars['Boolean']>;
};

export type DeleteUnassignedProfilePictureInput = {
  fileUuid: Scalars['String'];
  hasExpiration?: InputMaybe<Scalars['Boolean']>;
};

export type DeleteUnassignedProfilePictureOutput = {
  fileUuid?: Maybe<Scalars['String']>;
};

export type DeleteWebViewTask = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type DeleteWebViewTaskInput = {
  complete?: InputMaybe<Scalars['Boolean']>;
  orgId: Scalars['Int'];
  ownerApp: Scalars['String'];
  ownerId: Scalars['String'];
  ownerType: Scalars['String'];
  profileId: Scalars['String'];
};

export type DeliveryResponse = {
  /** Delivery Date */
  deliveryDate?: Maybe<Scalars['DateTime']>;
  /** Delivery Id */
  deliveryId?: Maybe<Scalars['Int']>;
  deliveryStatus?: Maybe<DeliveryStatus>;
  emailAddress?: Maybe<Scalars['String']>;
  /** External Id */
  externalId?: Maybe<Scalars['String']>;
  /** Fail After */
  failAfter?: Maybe<Scalars['DateTime']>;
  /** Message Id */
  messageId?: Maybe<Scalars['Int']>;
  messagePath?: Maybe<MessagePath>;
  person?: Maybe<PersonNode>;
  phoneMobile?: Maybe<Scalars['String']>;
  /** Read Date */
  readDate?: Maybe<Scalars['DateTime']>;
  /** Reason Of Failure */
  reasonOfFailure?: Maybe<Scalars['String']>;
  /** Scheduled Send Date */
  scheduledSendDate?: Maybe<Scalars['DateTime']>;
  /** Sent Date */
  sentDate?: Maybe<Scalars['DateTime']>;
  /** Status Date */
  statusDate?: Maybe<Scalars['DateTime']>;
  /** Tenant Id */
  tenantId?: Maybe<Scalars['String']>;
};

export type DeliveryResponseConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeliveryResponseEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DeliveryResponse` and its cursor. */
export type DeliveryResponseEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DeliveryResponse>;
};

/** An enumeration. */
export enum DeliveryStatus {
  BadNumber = 'bad_number',
  Bounced = 'bounced',
  Delivered = 'delivered',
  Dropped = 'dropped',
  Failed = 'failed',
  NoEmailAddressProvided = 'no_email_address_provided',
  Pending = 'pending',
  Read = 'read',
  Retrying = 'retrying',
  RetryingWithoutAttachments = 'retrying_without_attachments',
  Sent = 'sent',
  Unsendable = 'unsendable',
}

/** Connection class for DigitalDisplay nodes */
export type DigitalDisplayConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DigitalDisplayEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** DigitalDisplayCreateInput used in both create and update */
export type DigitalDisplayCreateInput = {
  displayPageSettings: DigitalDisplayPageSettingsInput;
  /** The label of the digital display */
  label: Scalars['String'];
  settings: DigitalDisplaySettingsInput;
};

export type DigitalDisplayCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  digitalDisplay?: Maybe<DigitalDisplayNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type DigitalDisplayDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  digitalDisplay?: Maybe<DigitalDisplayNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `DigitalDisplay` and its cursor. */
export type DigitalDisplayEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DigitalDisplayNode>;
};

/** Exposes DigitalDisplay objects */
export type DigitalDisplayNode = Node & {
  Settings: Scalars['JSONString'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  digitalDisplayId: Scalars['ID'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  label: Scalars['String'];
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  pages?: Maybe<Array<Maybe<DigitalDisplayPageNode>>>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  settings?: Maybe<DigitalDisplaySettingsOutput>;
  teamId: Scalars['Int'];
  token?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

/** Exposes DigitalDisplayPage node */
export type DigitalDisplayPageNode = {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  digitalDisplay?: Maybe<DigitalDisplayNode>;
  digitalDisplayId?: Maybe<Scalars['Int']>;
  digitalDisplayPageId: Scalars['ID'];
  label: Scalars['String'];
  multipleCalendarsEnabled?: Maybe<Scalars['Boolean']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  selectionsLabel?: Maybe<Scalars['String']>;
  settings?: Maybe<DigitalDisplayPageSettingsOutput>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** Input object for creating the various Digital Display Page settings */
export type DigitalDisplayPageSettingsInput = {
  /** The background image of the digital display page */
  backgroundImage?: InputMaybe<Scalars['String']>;
  /** The custom CSS for the digital display page */
  customCss?: InputMaybe<Scalars['JSONString']>;
  /** The logo which should be shown on the display page */
  logoOptions?: InputMaybe<LogoOptionsEnum>;
  /** The selections for multi-calendar display, if enabled */
  multicalSelections?: InputMaybe<Array<InputMaybe<DigitalDisplayPageSettingsSelection>>>;
  /** The orientation of the digital display page */
  orientation?: InputMaybe<OrientationEnum>;
  /** The list of selection codes */
  selectionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The selection codes (deprecated) */
  selections?: InputMaybe<Array<InputMaybe<DigitalDisplayPageSettingsSelection>>>;
  /** How long it takes for multi-calendar display to switch to the next calendar (in seconds) */
  transitionSpeed?: InputMaybe<Scalars['Int']>;
  /** Settings for widgets that should be displayed on the digital display page */
  widgetSettings?: InputMaybe<Array<InputMaybe<DigitalDisplayWidgetSettingInput>>>;
};

/** DigitalDisplayPageSettingsOutput used for defining the output of the query. */
export type DigitalDisplayPageSettingsOutput = {
  /** The background image of the digital display page */
  backgroundImage?: Maybe<Scalars['String']>;
  /** The custom CSS for the digital display page */
  customCss?: Maybe<Scalars['JSONString']>;
  /** The logo which should be shown on the digital display page */
  logoOptions?: Maybe<Scalars['String']>;
  /** The selections for multi-calendar display, if enabled */
  multicalSelections?: Maybe<Array<Maybe<DigitalDisplayPageSettingsSelectionOutput>>>;
  /** The orientation of the digital display page */
  orientation?: Maybe<Scalars['String']>;
  /** The list of selection codes */
  selectionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** How long it takes for multi-calendar display to switch to the next calendar (in seconds) */
  transitionSpeed?: Maybe<Scalars['Int']>;
  /** Page widget settings */
  widgetSettings?: Maybe<Array<Maybe<DigitalDisplayWidgetSettingsOutput>>>;
};

export type DigitalDisplayPageSettingsSelection = {
  /** The selection code */
  code: Scalars['String'];
  /** The selection code label */
  label?: InputMaybe<Scalars['String']>;
};

export type DigitalDisplayPageSettingsSelectionOutput = {
  /** The selection code */
  code?: Maybe<Scalars['String']>;
  /** The selection query key */
  key?: Maybe<Scalars['Int']>;
  /** The selection label */
  label?: Maybe<Scalars['String']>;
};

/** Input object for updating the various Digital Display Page settings */
export type DigitalDisplayPageSettingsUpdateInput = {
  /** The background image of the digital display page */
  backgroundImage?: InputMaybe<Scalars['String']>;
  /** The custom CSS for the digital display page */
  customCss?: InputMaybe<Scalars['JSONString']>;
  /** The logo which should be shown on the display page */
  logoOptions?: InputMaybe<LogoOptionsEnum>;
  /** The selections for multi-calendar display, if enabled */
  multicalSelections?: InputMaybe<Array<InputMaybe<DigitalDisplayPageSettingsSelection>>>;
  /** The orientation of the digital display page */
  orientation?: InputMaybe<OrientationEnum>;
  /** The list of selection codes */
  selectionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The selection codes (deprecated) */
  selections?: InputMaybe<Array<InputMaybe<DigitalDisplayPageSettingsSelection>>>;
  /** How long it takes for multi-calendar display to switch to the next calendar (in seconds) */
  transitionSpeed?: InputMaybe<Scalars['Int']>;
  /** Settings for widgets that should be displayed on the digital display page */
  widgetSettings?: InputMaybe<Array<InputMaybe<DigitalDisplayWidgetSettingInput>>>;
};

export type DigitalDisplaySettingsInput = {
  /** The country of the digital display */
  country?: InputMaybe<Scalars['String']>;
  /** Whether to show the label on the display */
  showLabelOnDisplay?: InputMaybe<Scalars['Boolean']>;
  /** The time format of the digital display */
  timeFormat?: Scalars['String'];
  /** The time zone of the digital display */
  timeZone: Scalars['String'];
};

/** DigitalDisplaySettingsOutput used for defining the output of the query. */
export type DigitalDisplaySettingsOutput = {
  /** The country of the digital display */
  country?: Maybe<Scalars['String']>;
  /** Whether to show the label on the display */
  showLabelOnDisplay?: Maybe<Scalars['Boolean']>;
  /** The time format of the digital display */
  timeFormat?: Maybe<Scalars['String']>;
  /** The time zone of the digital display */
  timeZone?: Maybe<Scalars['String']>;
};

export type DigitalDisplaySettingsUpdateInput = {
  /** The country of the digital display */
  country?: InputMaybe<Scalars['String']>;
  /** Whether to show the label on the display */
  showLabelOnDisplay?: InputMaybe<Scalars['Boolean']>;
  /** The time format of the digital display */
  timeFormat?: InputMaybe<Scalars['String']>;
  /** The time zone of the digital display */
  timeZone?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum DigitalDisplaySortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DdDisplayIdAsc = 'dd_display_id_asc',
  DdDisplayIdDesc = 'dd_display_id_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  SettingsAsc = 'settings_asc',
  SettingsDesc = 'settings_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TokenAsc = 'token_asc',
  TokenDesc = 'token_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  UuidAsc = 'uuid_asc',
  UuidDesc = 'uuid_desc',
}

/** DigitalDisplayUpdateInput used in update */
export type DigitalDisplayUpdateInput = {
  displayPageSettings?: InputMaybe<DigitalDisplayPageSettingsUpdateInput>;
  /** The label of the digital display */
  label?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<DigitalDisplaySettingsUpdateInput>;
};

export type DigitalDisplayUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  digitalDisplay?: Maybe<DigitalDisplayNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Input class for widget (e.g. announcements) settings */
export type DigitalDisplayWidgetSettingInput = {
  /** Text displayed on the widget */
  details?: InputMaybe<Scalars['String']>;
  /** Date when the widget becomes expired */
  endDatetime?: InputMaybe<Scalars['DateTime']>;
  /** The image shown as part of the widget */
  image?: InputMaybe<Scalars['String']>;
  /** Numeric identifier of the position where the widget should be rendered (1 or 2) */
  position?: InputMaybe<Scalars['Int']>;
  /** Date when the widget becomes active */
  startDatetime?: InputMaybe<Scalars['DateTime']>;
  /** Title of the widget shown as the heading */
  title?: InputMaybe<Scalars['String']>;
  /** Type of the widget the settings apply to, e.g. announcement */
  widgetType?: InputMaybe<WidgetTypeEnum>;
};

/** Output class used for defining output representation of widget (such as announcements) settings. */
export type DigitalDisplayWidgetSettingsOutput = {
  /** Text displayed on the widget */
  details?: Maybe<Scalars['String']>;
  /** Date when the widget becomes expired */
  endDatetime?: Maybe<Scalars['DateTime']>;
  /** The image shown as part of the widget */
  image?: Maybe<Scalars['String']>;
  /** Numeric identifier of the position where the widget should be rendered (1 or 2) */
  position?: Maybe<Scalars['Int']>;
  /** Date when the widget becomes active */
  startDatetime?: Maybe<Scalars['DateTime']>;
  /** Title of the widget shown as the heading */
  title?: Maybe<Scalars['String']>;
  /** Type of the widget the settings apply to, e.g. announcement */
  widgetType?: Maybe<Scalars['String']>;
};

export type DromoS3UploadOutput = {
  fileNameServer?: Maybe<Scalars['String']>;
};

export type DromoUploadS3FileParams = {
  inputData: Scalars['JSONString'];
};

/** The current eligibility year status for an Athlete. */
export type EligibilityYear = Node &
  Selectable & {
    academicYear?: Maybe<Scalars['Int']>;
    /** The type of group */
    groupType?: Maybe<GroupType>;
    /** The ID of the object. */
    id: Scalars['ID'];
    isCollegiate?: Maybe<Scalars['Boolean']>;
    /** The user-displayed name of the selectable. */
    label?: Maybe<Scalars['String']>;
    peopleCount?: Maybe<Scalars['Int']>;
    /** The label including the team name. Applies only to group selections. */
    pluralLabel?: Maybe<Scalars['String']>;
    /** The unique identifier used to select members of the selectable. */
    selectionCode?: Maybe<Scalars['String']>;
    team?: Maybe<TeamNode>;
  };

export type EmailInput = {
  password: Scalars['String'];
  primaryEmail: Scalars['String'];
};

export type EnrollmentAttendanceConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EnrollmentAttendanceEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `EnrollmentAttendance` and its cursor. */
export type EnrollmentAttendanceEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<EnrollmentAttendanceNode>;
};

export type EnrollmentAttendanceNode = Node & {
  /** TW Appointment Id that represents the appointment tied to appointment_occurrence_id */
  appointmentId?: Maybe<Scalars['Int']>;
  /** TW Appointment Occurrence ID that represents the specific class session that was/wasn't attended */
  appointmentOccurrenceId?: Maybe<Scalars['Int']>;
  /** Whether or not the student attended class */
  attendanceStatus: EnrollmentAttendanceStatus;
  /** If this is a locate-verified appointment, then the checkin result id will be set here */
  checkinResultId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  enrollment?: Maybe<EnrollmentNode>;
  enrollmentAttendanceId: Scalars['ID'];
  enrollmentId: Scalars['Int'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** End date time of the class in UTC */
  occurrenceEndDateTime?: Maybe<Scalars['DateTime']>;
  /** Start date time of the class in UTC */
  occurrenceStartDateTime?: Maybe<Scalars['DateTime']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** If the student missed class for a no-fault reason, give that here */
  reason?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type EnrollmentAttendanceNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EnrollmentAttendanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `EnrollmentAttendanceNode` and its cursor. */
export type EnrollmentAttendanceNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<EnrollmentAttendanceNode>;
};

/** An enumeration. */
export enum EnrollmentAttendanceSortEnum {
  Ac2EnrollmentAttendanceIdAsc = 'ac2_enrollment_attendance_id_asc',
  Ac2EnrollmentAttendanceIdDesc = 'ac2_enrollment_attendance_id_desc',
  AppointmentIdAsc = 'appointment_id_asc',
  AppointmentIdDesc = 'appointment_id_desc',
  AppointmentOccurrenceIdAsc = 'appointment_occurrence_id_asc',
  AppointmentOccurrenceIdDesc = 'appointment_occurrence_id_desc',
  AttendanceStatusAsc = 'attendance_status_asc',
  AttendanceStatusDesc = 'attendance_status_desc',
  CheckinResultIdAsc = 'checkin_result_id_asc',
  CheckinResultIdDesc = 'checkin_result_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EnrollmentIdAsc = 'enrollment_id_asc',
  EnrollmentIdDesc = 'enrollment_id_desc',
  OccurrenceEndDateTimeAsc = 'occurrence_end_date_time_asc',
  OccurrenceEndDateTimeDesc = 'occurrence_end_date_time_desc',
  OccurrenceStartDateTimeAsc = 'occurrence_start_date_time_asc',
  OccurrenceStartDateTimeDesc = 'occurrence_start_date_time_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ReasonAsc = 'reason_asc',
  ReasonDesc = 'reason_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Represents whether a student attended a tutoring appointment or class. */
export enum EnrollmentAttendanceStatus {
  Attended = 'attended',
  Medical = 'medical',
  NoFaultAbsence = 'no_fault_absence',
  NonAttendance = 'non_attendance',
  PartialSession = 'partial_session',
}

export type EnrollmentConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EnrollmentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Enrollment input for creating Enrollments on their own. */
export type EnrollmentCreateInput = {
  /** The global ID for the course section this enrollment is for. */
  courseSectionId: Scalars['String'];
  /** Credit hours the CourseSection is worth for the Student. */
  creditsAttempted?: InputMaybe<Scalars['Float']>;
  /** Global ID value of the Student the Enrollment is for. */
  studentId: Scalars['String'];
};

export type EnrollmentCreatePayload = {
  Output?: Maybe<EnrollmentNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  enrollment?: Maybe<EnrollmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Enrollment input for creating Enrollments on CourseSections. */
export type EnrollmentCreateSubInput = {
  /** Credit hours the CourseSection is worth for the Student. */
  creditsAttempted?: InputMaybe<Scalars['Float']>;
  /** Global ID value of the Student the Enrollment is for. */
  studentId: Scalars['String'];
};

/** A Relay edge containing a `Enrollment` and its cursor. */
export type EnrollmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<EnrollmentNode>;
};

export type EnrollmentNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  courseSection?: Maybe<CourseSectionNode>;
  courseSectionId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** Number of credits the student attempted this term. */
  creditsAttempted?: Maybe<Scalars['String']>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  /** Date the enrollment might be dropped */
  droppedDate?: Maybe<Scalars['String']>;
  enrollmentAttendances?: Maybe<EnrollmentAttendanceNodeConnection>;
  enrollmentId: Scalars['ID'];
  grade?: Maybe<GradeNode>;
  gradeId?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  professorSurveyReports?: Maybe<ProfessorSurveyReportNodeConnection>;
  score?: Maybe<Scalars['Float']>;
  studentTerm?: Maybe<StudentTermNode>;
  studentTermId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type EnrollmentNodeEnrollmentAttendancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentOccurrenceId?: InputMaybe<Scalars['TWFilter_Int']>;
  attendanceStatus?: InputMaybe<Scalars['TWFilter_EnrollmentAttendanceStatus']>;
  before?: InputMaybe<Scalars['String']>;
  checkinResultId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentAttendanceId?: InputMaybe<Scalars['TWFilter_ID']>;
  enrollmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  occurrenceEndDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  occurrenceStartDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reason?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<EnrollmentAttendanceSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type EnrollmentNodeProfessorSurveyReportsArgs = {
  absenceCount?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['TWFilter_Int']>;
  isAtRisk?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorSurveyAssignmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  professorSurveyReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<Array<InputMaybe<ProfessorSurveyReportSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum EnrollmentSortEnum {
  Ac2EnrollmentIdAsc = 'ac2_enrollment_id_asc',
  Ac2EnrollmentIdDesc = 'ac2_enrollment_id_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CourseSectionIdAsc = 'course_section_id_asc',
  CourseSectionIdDesc = 'course_section_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CreditsAttemptedAsc = 'credits_attempted_asc',
  CreditsAttemptedDesc = 'credits_attempted_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  DroppedDateAsc = 'dropped_date_asc',
  DroppedDateDesc = 'dropped_date_desc',
  GradeIdAsc = 'grade_id_asc',
  GradeIdDesc = 'grade_id_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ScoreAsc = 'score_asc',
  ScoreDesc = 'score_desc',
  StudentTermIdAsc = 'student_term_id_asc',
  StudentTermIdDesc = 'student_term_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Enrollment input for updating Enrollments on their own. */
export type EnrollmentUpdateInput = {
  /** Credit hours the Enrollment is worth for the Student. */
  creditsAttempted?: InputMaybe<Scalars['Float']>;
};

export type EnrollmentUpdatePayload = {
  Output?: Maybe<EnrollmentNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  enrollment?: Maybe<EnrollmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum EventAlertTypeEnum {
  Both = 'both',
  Email = 'email',
  None = 'none',
  Sms = 'sms',
}

/** Represents actions allowed by external access link */
export enum ExternalAccessLinkAction {
  AcademicsProfessorSurveyFormRw = 'ACADEMICS_PROFESSOR_SURVEY_FORM_RW',
  FormAssignmentComplete = 'FORM_ASSIGNMENT_COMPLETE',
  NewUser = 'NEW_USER',
  ResetPassword = 'RESET_PASSWORD',
  VideoExternalPlay = 'VIDEO_EXTERNAL_PLAY',
}

/** Node returned for external access links */
export type ExternalAccessLinkNode = Node & {
  action?: Maybe<ExternalAccessLinkAction>;
  /** The ID of the object. */
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
};

export type ExternalAccessLinkNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExternalAccessLinkNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ExternalAccessLinkNode` and its cursor. */
export type ExternalAccessLinkNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ExternalAccessLinkNode>;
};

/** Duplicate of ExternalAccessLinkNode but with an additional field for warnings */
export type ExternalAccessLinkNodeWithWarnings = {
  node?: Maybe<ExternalAccessLinkNode>;
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum ExternalAccessLinkSortEnum {
  ActionAsc = 'action_asc',
  ActionDesc = 'action_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  ExpirationDateAsc = 'expiration_date_asc',
  ExpirationDateDesc = 'expiration_date_desc',
  ExternalAccessLinkIdAsc = 'external_access_link_id_asc',
  ExternalAccessLinkIdDesc = 'external_access_link_id_desc',
  IsExpiredAsc = 'is_expired_asc',
  IsExpiredDesc = 'is_expired_desc',
  PageAsc = 'page_asc',
  PageDesc = 'page_desc',
  ParamsAsc = 'params_asc',
  ParamsDesc = 'params_desc',
  ParamsJsonAsc = 'params_json_asc',
  ParamsJsonDesc = 'params_json_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  UuidAsc = 'uuid_asc',
  UuidDesc = 'uuid_desc',
  VideoIdAsc = 'video_id_asc',
  VideoIdDesc = 'video_id_desc',
}

export type ExternalAssignee = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ExternalAssigneeInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type ExternalCompletionCreate = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formCompletionRole?: Maybe<FormAssignmentCompletionRoleNode>;
  link?: Maybe<Scalars['String']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ExternalCompletionCreateInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type ExternalModule = {
  icon?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type ExternalModules = {
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  modules?: Maybe<Array<Maybe<ExternalModule>>>;
  position?: Maybe<Scalars['Int']>;
};

export type ExternalStudyHallActionCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  studyHallAction?: Maybe<StudyHallActionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ExternalStudyHallActionInput = {
  /** The token for an external checkins app */
  checkinAppToken: Scalars['String'];
  /** Optional text field for any details that need to accompany the check in/out */
  note?: InputMaybe<Scalars['String']>;
  /** School identifier value of the person checking in/out */
  schoolIdentifier: Scalars['String'];
};

export type ExternalUserFileVideoActionCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  userFileVideoAction?: Maybe<UserFileVideoActionNode>;
};

export type ExternalVideoIncrementDownloadCountPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  video?: Maybe<ExternalVideoNode>;
};

export type ExternalVideoIncrementPlayCountPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  video?: Maybe<ExternalVideoNode>;
};

export type ExternalVideoInput = {
  uuid?: InputMaybe<Scalars['String']>;
};

/** Exposes External video object */
export type ExternalVideoNode = Node & {
  description?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isPinned: Scalars['Boolean'];
  title: Scalars['String'];
  wistiaOriginalFileUrl?: Maybe<Scalars['String']>;
  wistiaThumbnailUrl?: Maybe<Scalars['String']>;
  wistiaVideoUuid?: Maybe<Scalars['String']>;
};

export type ExtraApptDetailsInput = {
  attachments?: InputMaybe<Scalars['String']>;
  reminders?: InputMaybe<Array<InputMaybe<AppointmentReminderInput>>>;
};

export type ExtraApptDetailsNode = {
  attachments?: Maybe<Scalars['String']>;
  reminders?: Maybe<Array<Maybe<AppointmentReminderNode>>>;
};

export type FeatureFlagConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeatureFlagEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `FeatureFlag` and its cursor. */
export type FeatureFlagEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FeatureFlagNode>;
};

export type FeatureFlagNode = {
  /** If this is True, then this flag is on for everyone. If it is False, then this flag is off for everyone. If it is None, then this flag is on for the assigned group  */
  blanketOnOffNull?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** Include some documentation with your flag. */
  doc?: Maybe<Scalars['String']>;
  featureFlagId: Scalars['ID'];
  /** This field should contain filters for the feature flag applicability */
  filters?: Maybe<Scalars['JSONString']>;
  /** Specifies whether the feature flag is FE or BE related */
  flagType?: Maybe<FeatureFlagType>;
  label?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** If this flag relates to a JIRA ticket, then associate the ticket ID. */
  ticketId?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** If this flag is still present in the code after this point, log a warning. */
  warnAfter?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum FeatureFlagSortEnum {
  BlanketOnOffNullAsc = 'blanket_on_off_null_asc',
  BlanketOnOffNullDesc = 'blanket_on_off_null_desc',
  CodeAsc = 'code_asc',
  CodeDesc = 'code_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DocAsc = 'doc_asc',
  DocDesc = 'doc_desc',
  FeatureFlagIdAsc = 'feature_flag_id_asc',
  FeatureFlagIdDesc = 'feature_flag_id_desc',
  FiltersAsc = 'filters_asc',
  FiltersDesc = 'filters_desc',
  FlagTypeAsc = 'flag_type_asc',
  FlagTypeDesc = 'flag_type_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  TicketIdAsc = 'ticket_id_asc',
  TicketIdDesc = 'ticket_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  UsageInfoAsc = 'usage_info_asc',
  UsageInfoDesc = 'usage_info_desc',
  WarnAfterAsc = 'warn_after_asc',
  WarnAfterDesc = 'warn_after_desc',
}

/**
 *
 *     Represents the feature flag type enum
 *         value: enum value used for FeatureFlag.flag_type
 *
 */
export enum FeatureFlagType {
  Backend = 'backend',
  Frontend = 'frontend',
}

export type FileAttachment = Node & {
  ParentObjectId?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** Fetches a signed download URL from S3, note that this field issues a server request and should not be used for high object count queries, like allAppointments over a week */
  downloadUrl?: Maybe<Scalars['String']>;
  fileAttachmentId: Scalars['ID'];
  fileName: Scalars['String'];
  fileNameServer?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  /** Storage bucket used for this file category (ex: file.attachment) */
  fileType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  parentAttachmentId?: Maybe<Scalars['Int']>;
  parentObjectClassName: Scalars['String'];
  parentObjectId?: Maybe<Scalars['String']>;
  /** identifier for uuid pks */
  parentObjectUuid?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type FileAttachmentFileData = {
  /** original file name of a file uploaded to s3 */
  fileName: Scalars['String'];
  /** size of the file in bytes */
  fileSize: Scalars['Int'];
  /** server-side file name of a file uploaded to s3 */
  serverFileName: Scalars['String'];
};

export type FileAttachmentSignedUrlOutput = {
  fileName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FlaggingForm = {
  answerAlertSelections?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  flaggedAnswerAlertType?: Maybe<Scalars['String']>;
  flaggedNextStepsMsg?: Maybe<Scalars['String']>;
  nextStepsMsgType?: Maybe<Scalars['String']>;
  nonflaggedNextStepsMsg?: Maybe<Scalars['String']>;
};

export type FlaggingFormInput = {
  answerAlertSelections?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  flaggedAnswerAlertType?: InputMaybe<Scalars['String']>;
  flaggedNextStepsMsg?: InputMaybe<Scalars['String']>;
  nextStepsMsgType?: InputMaybe<Scalars['String']>;
  nonflaggedNextStepsMsg?: InputMaybe<Scalars['String']>;
};

export type FolderConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FolderEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type FolderCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  folder?: Maybe<FolderNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FolderDeleteInput = {
  /** Whether to move or delete contents of folder */
  moveOrDeleteContents?: InputMaybe<Scalars['String']>;
  /** Where we're moving the contents of the deleted folder */
  moveToFolderId?: InputMaybe<Scalars['Int']>;
};

export type FolderDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  folder?: Maybe<FolderNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `Folder` and its cursor. */
export type FolderEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FolderNode>;
};

/**
 * INFO: This is used for FolderTypeGraphene.form_assignment so far. Please feel free
 * to add anything you need for other types but remember to update Create/Update logic to reflect that
 */
export type FolderInput = {
  /** The title of the Folder */
  folderName: Scalars['String'];
  /** Type of folder */
  folderType: FolderTypeGraphene;
  isEditableByUser?: InputMaybe<Scalars['Boolean']>;
  /** Parent folder of this folder - for grouping. For files */
  parentFolderId?: InputMaybe<Scalars['Int']>;
  /** Priority of folder */
  sortOrder?: InputMaybe<Scalars['Int']>;
  /** Team associated with folder. For videos */
  teamId?: InputMaybe<Scalars['Int']>;
};

/** Exposes Folder objects */
export type FolderNode = Node & {
  children?: Maybe<FolderNodeConnection>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** folder_id */
  folderId: Scalars['ID'];
  /** folder_name */
  folderName?: Maybe<Scalars['String']>;
  /** folder_type */
  folderType?: Maybe<Scalars['String']>;
  forms?: Maybe<FormAssignmentNodeConnection>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** is_editable_by_user */
  isEditableByUser?: Maybe<Scalars['Boolean']>;
  org?: Maybe<OrgNode>;
  /** school_id */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  parent?: Maybe<FolderNode>;
  /** parent_folder_id */
  parentFolderId?: Maybe<Scalars['Int']>;
  /** Primary Key */
  pk?: Maybe<Scalars['Int']>;
  sharedFiles?: Maybe<Array<Maybe<SharedFileNode>>>;
  /** sort_order */
  sortOrder?: Maybe<Scalars['Int']>;
  team?: Maybe<TeamNode>;
  /** team_id */
  teamId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** video_folder_id */
  videoFolderId?: Maybe<Scalars['Int']>;
  videos?: Maybe<Array<Maybe<VideoNode>>>;
};

/** Exposes Folder objects */
export type FolderNodeChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['TWFilter_ID']>;
  folderName?: InputMaybe<Scalars['TWFilter_String']>;
  folderType?: InputMaybe<Scalars['TWFilter_String']>;
  isEditableByUser?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentFolderId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<FolderSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  videoFolderId?: InputMaybe<Scalars['TWFilter_Int']>;
};

/** Exposes Folder objects */
export type FolderNodeFormsArgs = {
  DeprecatedCanSaveForm?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  assignedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  assignmentTimezone?: InputMaybe<Scalars['TWFilter_String']>;
  assignmentType?: InputMaybe<Scalars['TWFilter_FormAssignmentType']>;
  availableDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  before?: InputMaybe<Scalars['String']>;
  captchaEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  compiledPdfFileName?: InputMaybe<Scalars['TWFilter_String']>;
  completedFormReceiptEmail?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  deactivationDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  dueDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  externalAccessLinkId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  flaggedNextStepsMsg?: InputMaybe<Scalars['TWFilter_String']>;
  folderId?: InputMaybe<Scalars['TWFilter_Int']>;
  formAssignmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  formId?: InputMaybe<Scalars['TWFilter_Int']>;
  formQuestions?: InputMaybe<Scalars['TWFilter_String']>;
  formScheduleId?: InputMaybe<Scalars['TWFilter_Int']>;
  hasConditionalLogic?: InputMaybe<Scalars['TWFilter_String']>;
  hasFlaggedOptions?: InputMaybe<Scalars['TWFilter_String']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isEditable?: InputMaybe<Scalars['TWFilter_String']>;
  isScheduled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  nextStepsMsgType?: InputMaybe<Scalars['TWFilter_String']>;
  nonFlaggedNextStepsMsg?: InputMaybe<Scalars['TWFilter_String']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  quickformTemplate?: InputMaybe<Scalars['TWFilter_JSONString']>;
  smartabaseFormName?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<FormAssignmentSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type FolderNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FolderNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FolderNode` and its cursor. */
export type FolderNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FolderNode>;
};

/** An enumeration. */
export enum FolderSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  FolderIdAsc = 'folder_id_asc',
  FolderIdDesc = 'folder_id_desc',
  FolderNameAsc = 'folder_name_asc',
  FolderNameDesc = 'folder_name_desc',
  FolderTypeAsc = 'folder_type_asc',
  FolderTypeDesc = 'folder_type_desc',
  IsEditableByUserAsc = 'is_editable_by_user_asc',
  IsEditableByUserDesc = 'is_editable_by_user_desc',
  ParentFolderIdAsc = 'parent_folder_id_asc',
  ParentFolderIdDesc = 'parent_folder_id_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SortOrderAsc = 'sort_order_asc',
  SortOrderDesc = 'sort_order_desc',
  SportSchoolIdAsc = 'sport_school_id_asc',
  SportSchoolIdDesc = 'sport_school_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  VideoFolderIdAsc = 'video_folder_id_asc',
  VideoFolderIdDesc = 'video_folder_id_desc',
}

export enum FolderTypeGraphene {
  FormAssignment = 'form_assignment',
  Team = 'team',
  Video = 'video',
}

export type FolderUpdateInput = {
  /** The title of the Folder */
  folderName: Scalars['String'];
  /** Priority of folder */
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export type FolderUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  folder?: Maybe<FolderNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAnswerUnansweredQuestionsNode = {
  fieldAnswerList?: Maybe<Array<Maybe<Scalars['String']>>>;
  fieldName: Scalars['String'];
  fieldOrder: Scalars['Int'];
};

export type FormAssignmentChooseRolePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAssignmentChooseRolesPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAssignmentCompletionAnswerConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FormAssignmentCompletionAnswerEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
  unansweredQuestions?: Maybe<Array<Maybe<FormAnswerUnansweredQuestionsNode>>>;
};

/** A Relay edge containing a `FormAssignmentCompletionAnswer` and its cursor. */
export type FormAssignmentCompletionAnswerEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormAssignmentCompletionAnswerNode>;
};

/** Exposes FormTemplate objects. */
export type FormAssignmentCompletionAnswerNode = Node & {
  fieldAnswerList?: Maybe<Array<Maybe<Scalars['String']>>>;
  fieldName?: Maybe<Scalars['String']>;
  fieldOrder?: Maybe<Scalars['Int']>;
  fieldType?: Maybe<Scalars['String']>;
  formAssignmentCompletionRoleId?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum FormAssignmentCompletionAnswerSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  FieldAnswerAsc = 'field_answer_asc',
  FieldAnswerDesc = 'field_answer_desc',
  FieldNameAsc = 'field_name_asc',
  FieldNameDesc = 'field_name_desc',
  FieldOrderAsc = 'field_order_asc',
  FieldOrderDesc = 'field_order_desc',
  FieldTypeAsc = 'field_type_asc',
  FieldTypeDesc = 'field_type_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  QuickformDataAsc = 'quickform_data_asc',
  QuickformDataDesc = 'quickform_data_desc',
  SubmittableFormAssignmentCompletionAnswerIdAsc = 'submittable_form_assignment_completion_answer_id_asc',
  SubmittableFormAssignmentCompletionAnswerIdDesc = 'submittable_form_assignment_completion_answer_id_desc',
  SubmittableFormAssignmentCompletionIdAsc = 'submittable_form_assignment_completion_id_asc',
  SubmittableFormAssignmentCompletionIdDesc = 'submittable_form_assignment_completion_id_desc',
  SubmittableFormAssignmentCompletionRoleIdAsc = 'submittable_form_assignment_completion_role_id_asc',
  SubmittableFormAssignmentCompletionRoleIdDesc = 'submittable_form_assignment_completion_role_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type FormAssignmentCompletionBulkManualCompleteInput = {
  /** List of global ids for the FormAssignmenCompletions to be manually completed */
  completions: Array<Scalars['ID']>;
  /** Explanation why the form was manually completed */
  manuallyCompletedExplanation: Scalars['String'];
};

export type FormAssignmentCompletionBulkManualCompletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignmentCompletion?: Maybe<Array<Maybe<FormAssignmentCompletionNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAssignmentCompletionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FormAssignmentCompletionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type FormAssignmentCompletionDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignmentCompletion?: Maybe<FormAssignmentCompletionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `FormAssignmentCompletion` and its cursor. */
export type FormAssignmentCompletionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormAssignmentCompletionNode>;
};

export type FormAssignmentCompletionFetchPdfInput = {
  /** Force PDF generation even if it already exists */
  force?: InputMaybe<Scalars['Boolean']>;
};

export type FormAssignmentCompletionFetchPdfPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignmentCompletion?: Maybe<FormAssignmentCompletionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAssignmentCompletionManualCompleteInput = {
  /** Explanation why the form was manually completed */
  manuallyCompletedExplanation: Scalars['String'];
};

export type FormAssignmentCompletionManualCompletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignmentCompletion?: Maybe<FormAssignmentCompletionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAssignmentCompletionNode = Node & {
  allCompleted?: Maybe<Scalars['Boolean']>;
  assignment?: Maybe<FormAssignmentNode>;
  completedAnswers?: Maybe<Array<Maybe<FormAssignmentCompletionAnswerNode>>>;
  completedDateTime?: Maybe<Scalars['DateTime']>;
  completedPdfFileName?: Maybe<Scalars['String']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  docusignEnvelopeId?: Maybe<Scalars['String']>;
  formAssignmentId: Scalars['Int'];
  hasBeenReassigned?: Maybe<Scalars['Boolean']>;
  /** Indicates whether this completion contains answers which were flagged for alerts */
  hasFlaggedAnswers: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  /** flag indicating if this completion was completed using form link */
  isLinkCompletion?: Maybe<Scalars['Boolean']>;
  isMostRecent?: Maybe<Scalars['Boolean']>;
  manuallyCompleted?: Maybe<Scalars['Boolean']>;
  manuallyCompletedBy?: Maybe<PersonNode>;
  manuallyCompletedExplanation?: Maybe<Scalars['String']>;
  parentCompletionId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  reassignNotes?: Maybe<Scalars['String']>;
  reassignedBy?: Maybe<FormAssignmentCompletionReassignedByType>;
  signedDownloadUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  viewerAssignmentCompletionRole?: Maybe<FormAssignmentCompletionRoleNode>;
  workflow?: Maybe<Array<Maybe<FormAssignmentCompletionRoleNode>>>;
};

export type FormAssignmentCompletionReassignInput = {
  /** Whether this request comes from reassign or edit flows */
  isEdit?: InputMaybe<Scalars['Boolean']>;
  /** Why is form reassigned */
  notes?: InputMaybe<Scalars['String']>;
};

export type FormAssignmentCompletionReassignPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignmentCompletion?: Maybe<FormAssignmentCompletionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Represents who reassigned (assigner, signer) */
export enum FormAssignmentCompletionReassignedByType {
  Assigner = 'assigner',
  Signer = 'signer',
}

export type FormAssignmentCompletionRoleNode = Node & {
  assignedDateTime?: Maybe<Scalars['DateTime']>;
  assignmentRole?: Maybe<FormAssignmentRoleNode>;
  canDeleteCompletion?: Maybe<Scalars['Boolean']>;
  canEditResponse?: Maybe<Scalars['Boolean']>;
  completedDateTime?: Maybe<Scalars['DateTime']>;
  completion?: Maybe<FormAssignmentCompletionNode>;
  completionRolesAssignedByCompleter?: Maybe<Array<Maybe<FormAssignmentCompletionRoleNode>>>;
  createdBy?: Maybe<Scalars['Int']>;
  externalAccessLink?: Maybe<ExternalAccessLinkNode>;
  /** if external and role chooses, this will be different from the assignment role value */
  externalEmail?: Maybe<Scalars['String']>;
  externalLinkUrl?: Maybe<Scalars['String']>;
  /** if external and role chooses, this will be different from the assignment role value */
  externalName?: Maybe<Scalars['String']>;
  formAssignmentCompletionId: Scalars['Int'];
  /** flag indicating if role has been chosen (only applies if chooser = role) */
  hasRoleBeenChosen?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  initiateAsyncRecipientTokenUrlFetch?: Maybe<Scalars['String']>;
  isExternalRole?: Maybe<Scalars['Boolean']>;
  lastCallbackEvent?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  pk?: Maybe<Scalars['Int']>;
  recipientTokenUrl?: Maybe<Scalars['String']>;
  retrieveAsyncRecipientTokenUrl?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  roleOrder?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<Array<Maybe<FormAssignmentCompletionRoleNode>>>;
};

export type FormAssignmentCompletionRoleSendReminderPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignmentCompletionRole?: Maybe<FormAssignmentCompletionRoleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum FormAssignmentCompletionRoleSortEnum {
  ApiEventCallbackAsc = 'api_event_callback_asc',
  ApiEventCallbackDesc = 'api_event_callback_desc',
  AssignedDateTimeAsc = 'assigned_date_time_asc',
  AssignedDateTimeDesc = 'assigned_date_time_desc',
  AssignedRoleDateTimeAsc = 'assigned_role_date_time_asc',
  AssignedRoleDateTimeDesc = 'assigned_role_date_time_desc',
  ChooserSubmittableFormAssignmentCompletionRoleIdAsc = 'chooser_submittable_form_assignment_completion_role_id_asc',
  ChooserSubmittableFormAssignmentCompletionRoleIdDesc = 'chooser_submittable_form_assignment_completion_role_id_desc',
  CompletedDateAsc = 'completed_date_asc',
  CompletedDateDesc = 'completed_date_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DueDateAsc = 'due_date_asc',
  DueDateDesc = 'due_date_desc',
  ExternalAccessLinkIdAsc = 'external_access_link_id_asc',
  ExternalAccessLinkIdDesc = 'external_access_link_id_desc',
  ExternalEmailAsc = 'external_email_asc',
  ExternalEmailDesc = 'external_email_desc',
  ExternalNameAsc = 'external_name_asc',
  ExternalNameDesc = 'external_name_desc',
  FormTitleAsc = 'form_title_asc',
  FormTitleDesc = 'form_title_desc',
  HasRoleBeenChosenAsc = 'has_role_been_chosen_asc',
  HasRoleBeenChosenDesc = 'has_role_been_chosen_desc',
  ManuallyCompletedAsc = 'manually_completed_asc',
  ManuallyCompletedDesc = 'manually_completed_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  RoleNameAsc = 'role_name_asc',
  RoleNameDesc = 'role_name_desc',
  RoleOrderAsc = 'role_order_asc',
  RoleOrderDesc = 'role_order_desc',
  RoleTypeAsc = 'role_type_asc',
  RoleTypeDesc = 'role_type_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  SubmittableFormAssignmentCompletionIdAsc = 'submittable_form_assignment_completion_id_asc',
  SubmittableFormAssignmentCompletionIdDesc = 'submittable_form_assignment_completion_id_desc',
  SubmittableFormAssignmentCompletionRoleIdAsc = 'submittable_form_assignment_completion_role_id_asc',
  SubmittableFormAssignmentCompletionRoleIdDesc = 'submittable_form_assignment_completion_role_id_desc',
  SubmittableFormAssignmentRoleIdAsc = 'submittable_form_assignment_role_id_asc',
  SubmittableFormAssignmentRoleIdDesc = 'submittable_form_assignment_role_id_desc',
  SubmitterIpAddressAsc = 'submitter_ip_address_asc',
  SubmitterIpAddressDesc = 'submitter_ip_address_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export enum FormAssignmentCompletionRoleStatusGraphene {
  Assigned = 'assigned',
  Completed = 'completed',
  Declined = 'declined',
  Expired = 'expired',
  Queued = 'queued',
  Saved = 'saved',
}

/** An enumeration. */
export enum FormAssignmentCompletionSortEnum {
  AllCompletedAsc = 'all_completed_asc',
  AllCompletedDesc = 'all_completed_desc',
  CompletedDateAsc = 'completed_date_asc',
  CompletedDateDesc = 'completed_date_desc',
  CompletedPdfFileNameAsc = 'completed_pdf_file_name_asc',
  CompletedPdfFileNameDesc = 'completed_pdf_file_name_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DocusignEnvelopeCreatedDateAsc = 'docusign_envelope_created_date_asc',
  DocusignEnvelopeCreatedDateDesc = 'docusign_envelope_created_date_desc',
  DocusignEnvelopeIdAsc = 'docusign_envelope_id_asc',
  DocusignEnvelopeIdDesc = 'docusign_envelope_id_desc',
  FirstRoleLastNameAsc = 'first_role_last_name_asc',
  FirstRoleLastNameDesc = 'first_role_last_name_desc',
  HasAccessibleAnswersAsc = 'has_accessible_answers_asc',
  HasAccessibleAnswersDesc = 'has_accessible_answers_desc',
  HasFieldDataBeenRetreivedAsc = 'has_field_data_been_retreived_asc',
  HasFieldDataBeenRetreivedDesc = 'has_field_data_been_retreived_desc',
  HasFlaggedAnswersAsc = 'has_flagged_answers_asc',
  HasFlaggedAnswersDesc = 'has_flagged_answers_desc',
  IsCompletedAsc = 'is_completed_asc',
  IsCompletedDesc = 'is_completed_desc',
  IsLinkCompletionAsc = 'is_link_completion_asc',
  IsLinkCompletionDesc = 'is_link_completion_desc',
  ManuallyCompletedAsc = 'manually_completed_asc',
  ManuallyCompletedByAsc = 'manually_completed_by_asc',
  ManuallyCompletedByDesc = 'manually_completed_by_desc',
  ManuallyCompletedDesc = 'manually_completed_desc',
  ManuallyCompletedExplanationAsc = 'manually_completed_explanation_asc',
  ManuallyCompletedExplanationDesc = 'manually_completed_explanation_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ParentCompletionIdAsc = 'parent_completion_id_asc',
  ParentCompletionIdDesc = 'parent_completion_id_desc',
  ReassignNotesAsc = 'reassign_notes_asc',
  ReassignNotesDesc = 'reassign_notes_desc',
  ReassignedByAsc = 'reassigned_by_asc',
  ReassignedByDesc = 'reassigned_by_desc',
  SubmittableFormAssignmentCompletionIdAsc = 'submittable_form_assignment_completion_id_asc',
  SubmittableFormAssignmentCompletionIdDesc = 'submittable_form_assignment_completion_id_desc',
  SubmittableFormAssignmentIdAsc = 'submittable_form_assignment_id_asc',
  SubmittableFormAssignmentIdDesc = 'submittable_form_assignment_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type FormAssignmentCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAssignmentCreateSharedLinkPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAssignmentDeleteSharedLinkPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAssignmentFetchPdfInput = {
  completionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  statusType?: InputMaybe<Scalars['String']>;
};

export type FormAssignmentFetchPdfPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormAssignmentNode = Node & {
  additionalResultsTo?: Maybe<Array<Maybe<VisibilityGroup>>>;
  additionalRoleArgs?: Maybe<Array<Maybe<AdditionalRoleArgsNode>>>;
  assignedBy?: Maybe<PersonNode>;
  assignedDateTime?: Maybe<Scalars['String']>;
  /** Only available when returning a single assignment (see FormAssignmentAssigneeLoader) */
  assignees?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  assignerEmail?: Maybe<Scalars['String']>;
  assignmentRoles?: Maybe<Array<Maybe<FormAssignmentRoleNode>>>;
  assignmentTimezone?: Maybe<Scalars['String']>;
  assignmentType?: Maybe<FormAssignmentType>;
  /** email address to send copy of PDF when form is completed */
  completedFormReceiptEmail?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<PersonNode>;
  deactivationDateTime?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  externalAccessLink?: Maybe<ExternalAccessLinkNode>;
  externalLinkUrl?: Maybe<Scalars['String']>;
  flaggedFormAlerts?: Maybe<FlaggingForm>;
  folder?: Maybe<FolderNode>;
  form?: Maybe<FormTemplateNode>;
  formLabel?: Maybe<Scalars['String']>;
  hasExternalAssignees?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  initialRoleArgs?: Maybe<InitialRoleArgsNode>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDocusignForm?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isScheduled?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  /** any notes or directions the assigning user wants shown to user when filling out */
  notes?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  quickformTemplate: Scalars['JSONString'];
  resultsTo?: Maybe<VisibilityGroup>;
  signedDownloadUrl?: Maybe<Scalars['String']>;
  /** Identifier needed to link a Hub form to a Smartabase form, but overridden at assignment level */
  smartabaseFormName?: Maybe<Scalars['String']>;
};

export type FormAssignmentNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FormAssignmentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FormAssignmentNode` and its cursor. */
export type FormAssignmentNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormAssignmentNode>;
};

/** Assignment roles basic info */
export type FormAssignmentRoleNode = Node & {
  assignedDateTime?: Maybe<Scalars['DateTime']>;
  assignment?: Maybe<FormAssignmentNode>;
  /** if roleChooser = role, let them specify external users */
  chooserAllowExternal?: Maybe<Scalars['Boolean']>;
  chooserAllowUser?: Maybe<Scalars['Boolean']>;
  chooserRestrictedToPersonTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  chooserRoleOrder?: Maybe<Scalars['Int']>;
  editCompleted?: Maybe<Scalars['Boolean']>;
  externalAssignees?: Maybe<Array<Maybe<ExternalAssignee>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  internalAssignees?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  name?: Maybe<Scalars['String']>;
  personSelection?: Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  roleOrder: Scalars['Int'];
  /** type of role; user|external */
  roleType: FormAssignmentRoleType;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum FormAssignmentRoleSortEnum {
  AssignedDateTimeAsc = 'assigned_date_time_asc',
  AssignedDateTimeDesc = 'assigned_date_time_desc',
  ChooserAllowExternalAsc = 'chooser_allow_external_asc',
  ChooserAllowExternalDesc = 'chooser_allow_external_desc',
  ChooserRestrictPersonTypesAsc = 'chooser_restrict_person_types_asc',
  ChooserRestrictPersonTypesDesc = 'chooser_restrict_person_types_desc',
  ChooserSubmittableFormAssignmentRoleIdAsc = 'chooser_submittable_form_assignment_role_id_asc',
  ChooserSubmittableFormAssignmentRoleIdDesc = 'chooser_submittable_form_assignment_role_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DueDateAsc = 'due_date_asc',
  DueDateDesc = 'due_date_desc',
  ExternalEmailAsc = 'external_email_asc',
  ExternalEmailDesc = 'external_email_desc',
  ExternalNameAsc = 'external_name_asc',
  ExternalNameDesc = 'external_name_desc',
  IsAbleToEditAsc = 'is_able_to_edit_asc',
  IsAbleToEditDesc = 'is_able_to_edit_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  RoleChooserAsc = 'role_chooser_asc',
  RoleChooserDesc = 'role_chooser_desc',
  RoleNameAsc = 'role_name_asc',
  RoleNameDesc = 'role_name_desc',
  RoleOrderAsc = 'role_order_asc',
  RoleOrderDesc = 'role_order_desc',
  RoleTypeAsc = 'role_type_asc',
  RoleTypeDesc = 'role_type_desc',
  SubmittableFormAssignmentIdAsc = 'submittable_form_assignment_id_asc',
  SubmittableFormAssignmentIdDesc = 'submittable_form_assignment_id_desc',
  SubmittableFormAssignmentRoleIdAsc = 'submittable_form_assignment_role_id_asc',
  SubmittableFormAssignmentRoleIdDesc = 'submittable_form_assignment_role_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Represents type of FormAssignmentRoleType */
export enum FormAssignmentRoleType {
  External = 'external',
  Link = 'link',
  User = 'user',
}

export type FormAssignmentSendRemindersInput = {
  /** Type of reminder */
  reminderType: FormReminderTypeGraphene;
};

export type FormAssignmentSendRemindersPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum FormAssignmentSortEnum {
  AssignmentTimezoneAsc = 'assignment_timezone_asc',
  AssignmentTimezoneDesc = 'assignment_timezone_desc',
  AssignmentTypeAsc = 'assignment_type_asc',
  AssignmentTypeDesc = 'assignment_type_desc',
  CanSaveFormAsc = 'can_save_form_asc',
  CanSaveFormDesc = 'can_save_form_desc',
  CaptchaEnabledAsc = 'captcha_enabled_asc',
  CaptchaEnabledDesc = 'captcha_enabled_desc',
  CompiledPdfFileNameAsc = 'compiled_pdf_file_name_asc',
  CompiledPdfFileNameDesc = 'compiled_pdf_file_name_desc',
  CompletedFormReceiptEmailAsc = 'completed_form_receipt_email_asc',
  CompletedFormReceiptEmailDesc = 'completed_form_receipt_email_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DateAvailableAsc = 'date_available_asc',
  DateAvailableDesc = 'date_available_desc',
  DeactivationDateTimeAsc = 'deactivation_date_time_asc',
  DeactivationDateTimeDesc = 'deactivation_date_time_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  DueDateAsc = 'due_date_asc',
  DueDateDesc = 'due_date_desc',
  ExternalAccessLinkIdAsc = 'external_access_link_id_asc',
  ExternalAccessLinkIdDesc = 'external_access_link_id_desc',
  FlaggedNextStepsMsgAsc = 'flagged_next_steps_msg_asc',
  FlaggedNextStepsMsgDesc = 'flagged_next_steps_msg_desc',
  FolderAsc = 'folder_asc',
  FolderDesc = 'folder_desc',
  FolderIdAsc = 'folder_id_asc',
  FolderIdDesc = 'folder_id_desc',
  FormScheduleIdAsc = 'form_schedule_id_asc',
  FormScheduleIdDesc = 'form_schedule_id_desc',
  FormatAsc = 'format_asc',
  FormatDesc = 'format_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsScheduledAsc = 'is_scheduled_asc',
  IsScheduledDesc = 'is_scheduled_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  NextStepsMsgTypeAsc = 'next_steps_msg_type_asc',
  NextStepsMsgTypeDesc = 'next_steps_msg_type_desc',
  NonFlaggedNextStepsMsgAsc = 'non_flagged_next_steps_msg_asc',
  NonFlaggedNextStepsMsgDesc = 'non_flagged_next_steps_msg_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  QuickformTemplateAsc = 'quickform_template_asc',
  QuickformTemplateDesc = 'quickform_template_desc',
  SmartabaseFormNameAsc = 'smartabase_form_name_asc',
  SmartabaseFormNameDesc = 'smartabase_form_name_desc',
  SubmittableFormAssignmentIdAsc = 'submittable_form_assignment_id_asc',
  SubmittableFormAssignmentIdDesc = 'submittable_form_assignment_id_desc',
  SubmittableFormIdAsc = 'submittable_form_id_asc',
  SubmittableFormIdDesc = 'submittable_form_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Represents type of assignment */
export enum FormAssignmentType {
  Onetime = 'onetime',
  Perpetual = 'perpetual',
}

export type FormAssignmentUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormCallbackInput = {
  completionRoleKey: Scalars['String'];
  event?: InputMaybe<Scalars['String']>;
};

export enum FormReminderTypeGraphene {
  Both = 'both',
  Email = 'email',
  None = 'none',
  Sms = 'sms',
}

export type FormResultsReportConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FormResultsReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type FormResultsReportCreateInput = {
  /** Completion IDs to include in the report */
  completionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate?: InputMaybe<Scalars['String']>;
  /** Form Assignment ID, keeping it as a string of an integer for now for backwards compatibility */
  formAssignmentId: Scalars['String'];
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  manuallyCompleted?: InputMaybe<Scalars['Boolean']>;
  /** File type of the exported report */
  reportFileType?: InputMaybe<FormResultsReportTypeGrapheneEnum>;
  searchAnswers?: InputMaybe<Scalars['String']>;
  searchString?: InputMaybe<Scalars['String']>;
  selectedTeamId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
  /** Status type of the form assignment. e.g complete, incomplete, manually_completed */
  statusType?: InputMaybe<Scalars['String']>;
};

export type FormResultsReportCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formResultsReport?: Maybe<FormResultsReportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `FormResultsReport` and its cursor. */
export type FormResultsReportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormResultsReportNode>;
};

export type FormResultsReportNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  downloadableFileId: Scalars['ID'];
  errorMessage?: Maybe<Scalars['String']>;
  fileNameServer?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  formResultsReportId: Scalars['ID'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** What kind of downloaded file this is, e.g. 'report'. */
  kind: Scalars['String'];
  /** Forward-compatible metadata column. */
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<OrgNode>;
  /** The Teamworks Org Id that this is associated with this */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Teamworks Person Id that this is associated with this */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Status of the file, i.e. one of `&.STATUS_*`. */
  status: Scalars['String'];
  team?: Maybe<TeamNode>;
  /** The Teamworks Team Id that this is associated with this */
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** Cached copy of the public URL. */
  url?: Maybe<Scalars['String']>;
};

export enum FormResultsReportTypeGrapheneEnum {
  Excel = 'excel',
  Pdf = 'pdf',
}

export type FormScheduleDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formSchedule?: Maybe<FormScheduleNode>;
  id?: Maybe<Scalars['ID']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type FormScheduleNode = Node & {
  additionalResultsTo?: Maybe<Array<Maybe<VisibilityGroup>>>;
  additionalRoleArgs?: Maybe<Array<Maybe<AdditionalRoleArgsNode>>>;
  assignmentTimezone?: Maybe<Scalars['String']>;
  assignmentType?: Maybe<Scalars['String']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<PersonNode>;
  flaggedFormAlerts?: Maybe<FlaggingForm>;
  folder?: Maybe<FolderNode>;
  form?: Maybe<FormTemplateNode>;
  formLabel?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  initialRoleArgs?: Maybe<InitialRoleArgsNode>;
  isActive: Scalars['Boolean'];
  isDocusignForm?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notifyAssignees?: Maybe<NotifyAssignees>;
  parameters?: Maybe<Scalars['JSONString']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  recurRule: Scalars['String'];
  recurRuleText: Scalars['String'];
  relativeDeactivationDateTimeNumber?: Maybe<Scalars['Int']>;
  relativeDeactivationDateTimeTime?: Maybe<Scalars['String']>;
  relativeDeactivationDateTimeType?: Maybe<RelativeDateType>;
  relativeDueDateNumber?: Maybe<Scalars['Int']>;
  relativeDueDateTime?: Maybe<Scalars['String']>;
  relativeDueDateType?: Maybe<RelativeDateType>;
  resultsTo?: Maybe<VisibilityGroup>;
  scheduleStart: Scalars['DateTime'];
};

/** An enumeration. */
export enum FormScheduleSortEnum {
  AssignmentLabelAsc = 'assignment_label_asc',
  AssignmentLabelDesc = 'assignment_label_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  FormScheduleIdAsc = 'form_schedule_id_asc',
  FormScheduleIdDesc = 'form_schedule_id_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  ParametersAsc = 'parameters_asc',
  ParametersDesc = 'parameters_desc',
  RecurRuleAsc = 'recur_rule_asc',
  RecurRuleDesc = 'recur_rule_desc',
  RecurRuleTextAsc = 'recur_rule_text_asc',
  RecurRuleTextDesc = 'recur_rule_text_desc',
  ScheduleStartAsc = 'schedule_start_asc',
  ScheduleStartDesc = 'schedule_start_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SubmittableFormIdAsc = 'submittable_form_id_asc',
  SubmittableFormIdDesc = 'submittable_form_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** An enumeration. */
export enum FormSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DocusignTemplateIdAsc = 'docusign_template_id_asc',
  DocusignTemplateIdDesc = 'docusign_template_id_desc',
  FormTitleAsc = 'form_title_asc',
  FormTitleDesc = 'form_title_desc',
  FormTypeAsc = 'form_type_asc',
  FormTypeDesc = 'form_type_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsDocusignFormAsc = 'is_docusign_form_asc',
  IsDocusignFormDesc = 'is_docusign_form_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  QuickformTemplateAsc = 'quickform_template_asc',
  QuickformTemplateDesc = 'quickform_template_desc',
  RolesAsc = 'roles_asc',
  RolesDesc = 'roles_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  ServerFileNameAsc = 'server_file_name_asc',
  ServerFileNameDesc = 'server_file_name_desc',
  SmartabaseFormNameAsc = 'smartabase_form_name_asc',
  SmartabaseFormNameDesc = 'smartabase_form_name_desc',
  SubmittableFormIdAsc = 'submittable_form_id_asc',
  SubmittableFormIdDesc = 'submittable_form_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type FormTemplateConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FormTemplateEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `FormTemplate` and its cursor. */
export type FormTemplateEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormTemplateNode>;
};

/** Exposes FormTemplate objects */
export type FormTemplateNode = Node & {
  canDelete?: Maybe<Scalars['Boolean']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<PersonNode>;
  docusignTemplateId?: Maybe<Scalars['String']>;
  hasFlaggedOptions?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isDocusignForm?: Maybe<Scalars['Boolean']>;
  /** Old form_title */
  label?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  quickformTemplate: Scalars['JSONString'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  serverFileName?: Maybe<Scalars['String']>;
  /** Identifier needed to link a Hub form to a Smartabase form, set at QuickForm template level */
  smartabaseFormName?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

/** The form type. */
export enum FormTypeEnum {
  Docusign = 'DOCUSIGN',
  Quickform = 'QUICKFORM',
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Nonbinary = 'NONBINARY',
}

export type GradeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GradeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Grade` and its cursor. */
export type GradeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GradeNode>;
};

export type GradeNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  gradeId: Scalars['ID'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of labelXlt */
  label?: Maybe<Scalars['String']>;
  /** Deprecated */
  labelXlt?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** The order on the list the grade should appear. Lower numbers appear higher up. */
  sortOrder: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** The multiplier for this grade. E.g. "A"=4.00, "C"=2.00, etc. Leave null if the grade does not factor into the student's GPA (such as a withdrawal-pass or pass) */
  weight?: Maybe<Scalars['Float']>;
};

/** An enumeration. */
export enum GradeSortEnum {
  Ac2GradeIdAsc = 'ac2_grade_id_asc',
  Ac2GradeIdDesc = 'ac2_grade_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  LabelXltAsc = 'label_xlt_asc',
  LabelXltDesc = 'label_xlt_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  SortOrderAsc = 'sort_order_asc',
  SortOrderDesc = 'sort_order_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  WeightAsc = 'weight_asc',
  WeightDesc = 'weight_desc',
}

/** The type of selection group. */
export enum GroupType {
  /** Members having the same academic year on a team */
  AcademicYear = 'ACADEMIC_YEAR',
  /** Members having the same athletic year on a team */
  AthleticYear = 'ATHLETIC_YEAR',
  /** Custom groups are composed of other groups and individual persons. */
  Cgat = 'CGAT',
  /** Contacts for a member on a team. */
  Contacts = 'CONTACTS',
  /** Custom groups are composed of other groups and individual persons. */
  Custom = 'CUSTOM',
  /** All members of a team or all members of an organization for an all-teams team. */
  Everyone = 'EVERYONE',
  /** Guardians for a member on a team. */
  Guardians = 'GUARDIANS',
  /** Members having the same athletic position on a team. */
  Position = 'POSITION',
  /** Members having the same athlete status on a team. */
  Status = 'STATUS',
  /** Members having the same person type (Role). */
  Type = 'TYPE',
  /** Contacts for members having the same person type. */
  TypeContacts = 'TYPE_CONTACTS',
  /** Guardians for members having the same person type. */
  TypeGuardians = 'TYPE_GUARDIANS',
}

export type HandleFormCallback = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  completionRole?: Maybe<FormAssignmentCompletionRoleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type IndividualNoteType = {
  note?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId?: Maybe<Scalars['Int']>;
};

export type InitialRoleArgsNode = {
  editCompleted?: Maybe<Scalars['Boolean']>;
  externalAssignees?: Maybe<Array<Maybe<ExternalAssignee>>>;
  internalAssignees?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  name?: Maybe<Scalars['String']>;
  roleOrder?: Maybe<Scalars['Int']>;
  roleType?: Maybe<Scalars['String']>;
};

export type InitialRoleInput = {
  editCompleted?: InputMaybe<Scalars['Boolean']>;
  externalAssignees?: InputMaybe<Array<InputMaybe<ExternalAssigneeInput>>>;
  internalAssignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  roleType?: InputMaybe<FormAssignmentRoleType>;
  sharedLinkOnly?: InputMaybe<Scalars['Boolean']>;
};

export type InstitutionNode = Node & {
  city?: Maybe<Scalars['String']>;
  conference?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  division?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  institutionId: Scalars['ID'];
  leagueId?: Maybe<Scalars['Int']>;
  nickname?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNodeConnection>;
  orgName?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  stadiumName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  timeZoneId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type InstitutionNodeOrgArgs = {
  IsTactical?: InputMaybe<Scalars['TWFilter_Boolean']>;
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  activePersonStatusTypes?: InputMaybe<Scalars['TWFilter_String']>;
  activeTeams?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  allTeamsTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  athletePersonTypeId?: InputMaybe<Scalars['TWFilter_String']>;
  before?: InputMaybe<Scalars['String']>;
  branding?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  defaultAppointmentSelection?: InputMaybe<Scalars['TWFilter_String']>;
  disableMessageEditDelete?: InputMaybe<Scalars['TWFilter_Boolean']>;
  doShowTwBranding?: InputMaybe<Scalars['TWFilter_Boolean']>;
  docusignAccountEmail?: InputMaybe<Scalars['TWFilter_String']>;
  enableGuardianshipFeatures?: InputMaybe<Scalars['TWFilter_Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  hasAcademics2?: InputMaybe<Scalars['TWFilter_String']>;
  hasCustomVoiceGreeting?: InputMaybe<Scalars['TWFilter_Boolean']>;
  identityFieldLabel?: InputMaybe<Scalars['TWFilter_String']>;
  identityProviderId?: InputMaybe<Scalars['TWFilter_Int']>;
  institutionId?: InputMaybe<Scalars['TWFilter_Int']>;
  isAdminOrg?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCollegiate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isMobileValidationEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isNonAthleteOrg?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isTactical?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  leagueId?: InputMaybe<Scalars['TWFilter_Int']>;
  logoImage?: InputMaybe<Scalars['TWFilter_String']>;
  messagingServiceSid?: InputMaybe<Scalars['TWFilter_String']>;
  oktaGroupId?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_ID']>;
  orgName?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sendSmsReminder?: InputMaybe<Scalars['TWFilter_Boolean']>;
  shortName?: InputMaybe<Scalars['TWFilter_String']>;
  smsProviderId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<OrgSortEnum>>>;
  timeZoneId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  uses24HourTime?: InputMaybe<Scalars['TWFilter_Boolean']>;
  usesExpandedProfessorSurveyReports?: InputMaybe<Scalars['TWFilter_Boolean']>;
  usesSsoAsDefault?: InputMaybe<Scalars['TWFilter_Boolean']>;
};

export type InstitutionNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InstitutionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InstitutionNode` and its cursor. */
export type InstitutionNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<InstitutionNode>;
};

export type IntDiff = {
  new?: Maybe<Scalars['Int']>;
  old?: Maybe<Scalars['Int']>;
  raw?: Maybe<Scalars['JSONString']>;
};

export type InternationalTravelDocumentInput = {
  birthCountry?: InputMaybe<Scalars['CountryCode']>;
  documentCountry?: InputMaybe<Scalars['CountryCode']>;
  documentExpDate?: InputMaybe<Scalars['String']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['CountryCode']>;
  residency?: InputMaybe<Scalars['CountryCode']>;
};

export type InternationalTravelDocumentNode = {
  birthCountry?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  documentCountry?: Maybe<Scalars['String']>;
  documentExpDate?: Maybe<Scalars['String']>;
  documentNumber: Scalars['String'];
  documentType: Scalars['String'];
  internationalTravelDocumentId: Scalars['ID'];
  nationality?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  residency?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type InvitationMedium = {
  email?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['Boolean']>;
  sms?: InputMaybe<Scalars['Boolean']>;
};

export type ListMessageResponse = {
  deliveryTypes?: Maybe<Array<Maybe<MessagePath>>>;
  /** Email Attachments */
  emailAttachments?: Maybe<Array<Maybe<AttachmentResponseStructure>>>;
  /** Email Subject */
  emailSubject?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
  externalEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalSms?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Has Been Processed */
  hasBeenProcessed?: Maybe<Scalars['Boolean']>;
  /** Message Id */
  messageId?: Maybe<Scalars['Int']>;
  messageType?: Maybe<MessageTypeNode>;
  /** Mms Attachments */
  mmsAttachments?: Maybe<Array<Maybe<AttachmentResponseStructure>>>;
  person?: Maybe<PersonNode>;
  /** Person Id */
  personId?: Maybe<Scalars['Int']>;
  /** Raw Email Body */
  rawEmailBody?: Maybe<Scalars['String']>;
  recipientSelections?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  /** Scheduled Date Time */
  scheduledDateTime?: Maybe<Scalars['DateTime']>;
  /** Sent Date Time */
  sentDateTime?: Maybe<Scalars['DateTime']>;
  /** Sms Body */
  smsBody?: Maybe<Scalars['String']>;
  /** Sms Text */
  smsText?: Maybe<Scalars['String']>;
  /** Target Selection Codes */
  targetSelectionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  timezone?: Maybe<TimezoneObjectType>;
  /** Timezone Code */
  timezoneCode?: Maybe<Scalars['String']>;
};

export type ListMessageResponseConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ListMessageResponseEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ListMessageResponse` and its cursor. */
export type ListMessageResponseEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ListMessageResponse>;
};

export enum LocationTypeEnum {
  Away = 'away',
  Home = 'home',
  NeutralSite = 'neutral_site',
}

export type LogAthletesSummaryNode = {
  actualSkillInstructionMinutes?: Maybe<Scalars['Int']>;
  appointmentTypesByDay?: Maybe<Array<Maybe<AppointmentTypesByDayNode>>>;
  entries?: Maybe<Array<Maybe<AthleteBreakdownNode>>>;
  expectedSkillInstructionMinutes?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  hasWeeklyOverage?: Maybe<Scalars['Boolean']>;
  isWeeklyDaysOffInViolation?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  multiSportAdjustment?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['Int']>;
  summary?: Maybe<Array<Maybe<AthleteSummaryNode>>>;
  tmpActivityLogId?: Maybe<Scalars['Int']>;
  tmpActivityLogSignoffResponseId?: Maybe<Scalars['Int']>;
  weeklyDaysOff?: Maybe<Scalars['Int']>;
};

export enum LoginAccess {
  Disabled = 'DISABLED',
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

export enum LogoOptionsEnum {
  ExistingLogo = 'existing_logo',
  NoLogo = 'no_logo',
}

/** Logs out the active user and returns the redirect url to the frontend */
export type LogoutUser = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  redirectUrl?: Maybe<Scalars['String']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ManageFormAssignmentsConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ManageFormAssignmentsEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ManageFormAssignments` and its cursor. */
export type ManageFormAssignmentsEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormAssignmentNode>;
};

export type ManageFormScheduleAssignmentsConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ManageFormScheduleAssignmentsEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ManageFormScheduleAssignments` and its cursor. */
export type ManageFormScheduleAssignmentsEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormScheduleNode>;
};

/** Individual message object type housing all the metadata about a single TW message */
export type Message = Node & {
  conversationId?: Maybe<Scalars['Int']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  /** A list of delivery methods associated with the message */
  deliveryTypes?: Maybe<Array<Maybe<MessageDeliveryType>>>;
  displayEmailText?: Maybe<Scalars['String']>;
  emailAttachments?: Maybe<Array<Maybe<MessageAttachmentObjectType>>>;
  emailSubject?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
  hasBeenProcessed?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isRichText?: Maybe<Scalars['String']>;
  messageRecipientDeliveryStatuses?: Maybe<MessageRecipientDeliveryStatusConnection>;
  messageType?: Maybe<MessageTypeObjectType>;
  mmsAttachments?: Maybe<Array<Maybe<MessageAttachmentObjectType>>>;
  numEmailMessages?: Maybe<Scalars['Int']>;
  numPushMessages?: Maybe<Scalars['Int']>;
  numSmsMessages?: Maybe<Scalars['Int']>;
  numVoiceMessages?: Maybe<Scalars['Int']>;
  numVoiceRecorded?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['Int']>;
  person?: Maybe<PersonNode>;
  /** The message sender's person_id */
  personId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  pushText?: Maybe<Scalars['String']>;
  recipients?: Maybe<Array<Maybe<RecipientPersonObjectType>>>;
  /**
   * Turn legacy database time into UTC time object. If object is already explicitly UTC, then assumes
   *         it was set to TZ to get ORM to save it as server time, so converts it back to server time but keeps in UTC
   */
  scheduledDateTime?: Maybe<Scalars['String']>;
  /** This function returns the time zone code. */
  scheduledDateTimeZone?: Maybe<Scalars['String']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendPersonalSms?: Maybe<Scalars['Boolean']>;
  sendPush?: Maybe<Scalars['Boolean']>;
  sendSms?: Maybe<Scalars['Boolean']>;
  sendVoice?: Maybe<Scalars['Boolean']>;
  sendVoiceDeliveryReportEmail?: Maybe<Scalars['Boolean']>;
  /** This function returns the name of the sender as first_name last_name. */
  senderName?: Maybe<Scalars['String']>;
  /**
   * Turn legacy database time into UTC time object. If object is already explicitly UTC, then assumes
   *         it was set to TZ to get ORM to save it as server time, so converts it back to server time but keeps in UTC
   */
  sentDateTime?: Maybe<Scalars['String']>;
  smsPages?: Maybe<Scalars['Int']>;
  smsText?: Maybe<Scalars['String']>;
  smsTextFormatted?: Maybe<Scalars['String']>;
  targetSelectionCodes?: Maybe<Array<Maybe<TargetSelectionCodeObjectType>>>;
  teamId?: Maybe<Scalars['Int']>;
  timeZone?: Maybe<TimeZoneObjectType>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  voiceDuration?: Maybe<Scalars['Int']>;
  voiceUrl?: Maybe<Scalars['String']>;
};

/** Individual message object type housing all the metadata about a single TW message */
export type MessageMessageRecipientDeliveryStatusesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Attachment object for any given message housing additional metadata */
export type MessageAttachmentObjectType = {
  /** Name of the bucket on s3 */
  bucketKey?: Maybe<Scalars['String']>;
  /** User defined file name */
  fileName?: Maybe<Scalars['String']>;
  /** Server file ContentType */
  fileType?: Maybe<Scalars['String']>;
  /** S3 url */
  s3Url?: Maybe<Scalars['String']>;
  /** Server file name */
  serverName?: Maybe<Scalars['String']>;
};

export type MessageBlastAttachmentS3UploadUrlInput = {
  /** What the attachment will be attached to. */
  attachmentVehicle: AttachmentVehicle;
  /** Content type of file being attached */
  contentType: Scalars['String'];
  /** The extension of file being attached */
  fileExtension: Scalars['String'];
};

export type MessageBlastAttachmentS3UploadUrlOutput = {
  url?: Maybe<Scalars['String']>;
};

export type MessageConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MessageEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type MessageCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  message?: Maybe<Message>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type MessageDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  message?: Maybe<Message>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Represents the possible message delivery types */
export enum MessageDeliveryType {
  Email = 'email',
  Sms = 'sms',
  Text = 'text',
  Voice = 'voice',
}

/** A Relay edge containing a `Message` and its cursor. */
export type MessageEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Message>;
};

export type MessageFromRetainInput = {
  personIds: Array<InputMaybe<Scalars['Int']>>;
  retainMessageType?: InputMaybe<Scalars['String']>;
  senderPersonId?: InputMaybe<Scalars['Int']>;
  smsText: Scalars['String'];
};

export type MessageInput = {
  appointmentOccurrence?: InputMaybe<Scalars['ID']>;
  emailAttachments?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  emailText?: InputMaybe<Scalars['String']>;
  includeGuardians?: InputMaybe<Scalars['Boolean']>;
  messageType?: InputMaybe<MessageTypes>;
  mmsAttachments?: InputMaybe<Scalars['String']>;
  scheduledSendDate?: InputMaybe<Scalars['DateTime']>;
  selectionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated, use send_text instead */
  sendSms?: InputMaybe<Scalars['Boolean']>;
  sendText?: InputMaybe<Scalars['Boolean']>;
  smsText?: InputMaybe<Scalars['String']>;
  timeZoneCode?: InputMaybe<Scalars['String']>;
  tripId?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum MessagePath {
  Email = 'email',
  ForwardedInsys = 'forwarded_insys',
  Insys = 'insys',
  Push = 'push',
  Sms = 'sms',
  Text = 'text',
}

/** Delivery audit records for each individual that received a message */
export type MessageRecipientDeliveryStatus = {
  conversationId?: Maybe<Scalars['Int']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  emailDeliveryDate?: Maybe<Scalars['DateTime']>;
  /** Turn UTC with no time_zone from database time into UTC time object */
  emailDeliveryDateTime?: Maybe<Scalars['String']>;
  emailDeliveryStatus?: Maybe<Scalars['String']>;
  emailFirstOpenedDate?: Maybe<Scalars['DateTime']>;
  /** Turn UTC with no time_zone from database time into UTC time object */
  emailFirstOpenedDateTime?: Maybe<Scalars['String']>;
  isPushMessage?: Maybe<Scalars['Boolean']>;
  messageId?: Maybe<Scalars['Int']>;
  person?: Maybe<PersonNode>;
  personId?: Maybe<Scalars['Int']>;
  phoneMobile?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  rawEmailDeliveryStatus?: Maybe<Scalars['String']>;
  rawTextDeliveryStatus?: Maybe<Scalars['String']>;
  rawVoiceDeliveryStatus?: Maybe<Scalars['String']>;
  smsFirstReadDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database time into UTC time object */
  smsFirstReadDateTime?: Maybe<Scalars['String']>;
  textDeliveryStatus?: Maybe<Scalars['String']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  voiceDeliveryStatus?: Maybe<Scalars['String']>;
  voiceMessageAcknowledged?: Maybe<Scalars['Boolean']>;
  voiceMessageCallDuration?: Maybe<Scalars['Int']>;
  voiceMessageCallsid?: Maybe<Scalars['String']>;
};

export type MessageRecipientDeliveryStatusConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MessageRecipientDeliveryStatusEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  pushCount?: Maybe<Scalars['Int']>;
  smsCount?: Maybe<Scalars['Int']>;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MessageRecipientDeliveryStatus` and its cursor. */
export type MessageRecipientDeliveryStatusEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<MessageRecipientDeliveryStatus>;
};

export type MessageSendersResponse = {
  person?: Maybe<PersonNode>;
};

export type MessageSendersResponseConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MessageSendersResponseEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MessageSendersResponse` and its cursor. */
export type MessageSendersResponseEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<MessageSendersResponse>;
};

/** An enumeration. */
export enum MessageSortEnum {
  AppointmentIdAsc = 'appointment_id_asc',
  AppointmentIdDesc = 'appointment_id_desc',
  ConversationIdAsc = 'conversation_id_asc',
  ConversationIdDesc = 'conversation_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EmailAttachmentsAsc = 'email_attachments_asc',
  EmailAttachmentsDesc = 'email_attachments_desc',
  EmailSubjectAsc = 'email_subject_asc',
  EmailSubjectDesc = 'email_subject_desc',
  EmailTextAsc = 'email_text_asc',
  EmailTextDesc = 'email_text_desc',
  ExternalAccessLinkIdAsc = 'external_access_link_id_asc',
  ExternalAccessLinkIdDesc = 'external_access_link_id_desc',
  ExternalReferenceIdAsc = 'external_reference_id_asc',
  ExternalReferenceIdDesc = 'external_reference_id_desc',
  HasBeenProcessedAsc = 'has_been_processed_asc',
  HasBeenProcessedDesc = 'has_been_processed_desc',
  IsRecurringReminderAsc = 'is_recurring_reminder_asc',
  IsRecurringReminderDesc = 'is_recurring_reminder_desc',
  MessageIdAsc = 'message_id_asc',
  MessageIdDesc = 'message_id_desc',
  MessageTypeAsc = 'message_type_asc',
  MessageTypeDesc = 'message_type_desc',
  MmsAttachmentsAsc = 'mms_attachments_asc',
  MmsAttachmentsDesc = 'mms_attachments_desc',
  NumEmailMessagesAsc = 'num_email_messages_asc',
  NumEmailMessagesDesc = 'num_email_messages_desc',
  NumPushMessagesAsc = 'num_push_messages_asc',
  NumPushMessagesDesc = 'num_push_messages_desc',
  NumRecipientsAsc = 'num_recipients_asc',
  NumRecipientsDesc = 'num_recipients_desc',
  NumSmsMessagesAsc = 'num_sms_messages_asc',
  NumSmsMessagesDesc = 'num_sms_messages_desc',
  NumVoiceMessagesAsc = 'num_voice_messages_asc',
  NumVoiceMessagesDesc = 'num_voice_messages_desc',
  NumVoiceRecordedAsc = 'num_voice_recorded_asc',
  NumVoiceRecordedDesc = 'num_voice_recorded_desc',
  ParamsSerializedAsc = 'params_serialized_asc',
  ParamsSerializedDesc = 'params_serialized_desc',
  ParentMessageIdAsc = 'parent_message_id_asc',
  ParentMessageIdDesc = 'parent_message_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  PushTextAsc = 'push_text_asc',
  PushTextDesc = 'push_text_desc',
  ReminderOffsetMinutesAsc = 'reminder_offset_minutes_asc',
  ReminderOffsetMinutesDesc = 'reminder_offset_minutes_desc',
  ScheduledSendDateAsc = 'scheduled_send_date_asc',
  ScheduledSendDateDesc = 'scheduled_send_date_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SendEmailAsc = 'send_email_asc',
  SendEmailDesc = 'send_email_desc',
  SendPersonalSmsAsc = 'send_personal_sms_asc',
  SendPersonalSmsDesc = 'send_personal_sms_desc',
  SendPushAsc = 'send_push_asc',
  SendPushDesc = 'send_push_desc',
  SendSmsAsc = 'send_sms_asc',
  SendSmsDesc = 'send_sms_desc',
  SendVoiceAsc = 'send_voice_asc',
  SendVoiceDeliveryReportEmailAsc = 'send_voice_delivery_report_email_asc',
  SendVoiceDeliveryReportEmailDesc = 'send_voice_delivery_report_email_desc',
  SendVoiceDesc = 'send_voice_desc',
  SenderPhoneNumberAsc = 'sender_phone_number_asc',
  SenderPhoneNumberDesc = 'sender_phone_number_desc',
  SenderVoiceNumberAsc = 'sender_voice_number_asc',
  SenderVoiceNumberDesc = 'sender_voice_number_desc',
  SentDatetimeAsc = 'sent_datetime_asc',
  SentDatetimeDesc = 'sent_datetime_desc',
  SmsPagesAsc = 'sms_pages_asc',
  SmsPagesDesc = 'sms_pages_desc',
  SmsTextAsc = 'sms_text_asc',
  SmsTextDesc = 'sms_text_desc',
  SmsTextFormattedAsc = 'sms_text_formatted_asc',
  SmsTextFormattedDesc = 'sms_text_formatted_desc',
  SportSchoolIdAsc = 'sport_school_id_asc',
  SportSchoolIdDesc = 'sport_school_id_desc',
  TimeZoneIdAsc = 'time_zone_id_asc',
  TimeZoneIdDesc = 'time_zone_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  VersionAsc = 'version_asc',
  VersionDesc = 'version_desc',
  VoiceDurationAsc = 'voice_duration_asc',
  VoiceDurationDesc = 'voice_duration_desc',
  VoiceUrlAsc = 'voice_url_asc',
  VoiceUrlDesc = 'voice_url_desc',
}

export type MessageTypeNode = {
  code?: Maybe<Scalars['String']>;
  integratorLabel?: Maybe<Scalars['String']>;
  integratorMessageType?: Maybe<Scalars['String']>;
};

export type MessageTypeObjectType = {
  code?: Maybe<Scalars['String']>;
  integratorLabel?: Maybe<Scalars['String']>;
  integratorMessageType?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum MessageTypes {
  Appointment = 'appointment',
  Messaging = 'messaging',
}

export type MessageUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  message?: Maybe<Message>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type MessagingLoginInput = {
  deviceModel: Scalars['String'];
  devicePushToken: Scalars['String'];
  notificationsEnabled: Scalars['Boolean'];
};

export type MessagingLoginOutput = {
  authToken?: Maybe<Scalars['String']>;
};

export type ModifyAllTeamAccess = {
  addToTeams?: InputMaybe<Array<InputMaybe<ModifyAllTeamAccessInput>>>;
};

export type ModifyAllTeamAccessInput = {
  athleteDetails?: InputMaybe<AthleteInput>;
  id?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['ID']>;
  userType?: InputMaybe<Scalars['ID']>;
};

export type ModifyTravelPartyInput = {
  exclusionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Global ID for Trip you're copying travel party from */
  fromTrip?: InputMaybe<Scalars['ID']>;
  selectionCodes: Array<InputMaybe<Scalars['String']>>;
};

export type ModifyTravelPartyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** The Modules node. */
export type ModuleNode = Node & {
  active?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  defaultAction?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  moduleId: Scalars['ID'];
  moduleName: Scalars['String'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type ModuleNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ModuleNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ModuleNode` and its cursor. */
export type ModuleNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ModuleNode>;
};

export type ModuleSettings = {
  /** A Boolean stating if the setting is enabled */
  enabled: Scalars['Boolean'];
  /** Name of the travel feature */
  label: Scalars['String'];
};

/** An enumeration. */
export enum ModuleSortEnum {
  ActiveAsc = 'active_asc',
  ActiveDesc = 'active_desc',
  CodeAsc = 'code_asc',
  CodeDesc = 'code_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DefaultActionAsc = 'default_action_asc',
  DefaultActionDesc = 'default_action_desc',
  ModuleIdAsc = 'module_id_asc',
  ModuleIdDesc = 'module_id_desc',
  ModuleNameAsc = 'module_name_asc',
  ModuleNameDesc = 'module_name_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** An enumeration. */
export enum Modules {
  Travel = 'travel',
}

export type Mutation = {
  activityLogSignoff: ActivityLogSignoff;
  addAuditComment: ComplianceAuditAddCommentPayload;
  addTravelVendorToGroup: AddTravelVendorToGroupPayload;
  adminBulkImportContacts: AdminBulkImportContactsPayload;
  appAssignmentsUpdate: AppAssignmentsUpdate;
  appointmentAttachmentBulkCreate: AppointmentAttachmentBulkCreatePayload;
  appointmentAttachmentBulkDelete: AppointmentAttachmentBulkDeletePayload;
  appointmentAttachmentSignedUrl: Array<Maybe<FileAttachmentSignedUrlOutput>>;
  appointmentAttachmentUpload: AppointmentAttachmentUploadPayload;
  appointmentBulkCopy: AppointmentBulkCopyPayload;
  appointmentBulkDelete: AppointmentBulkDeletePayload;
  appointmentBulkUpdate: AppointmentBulkUpdatePayload;
  appointmentCreate: AppointmentCreatePayload;
  appointmentDelete: AppointmentDeletePayload;
  appointmentRsvpSave: AppointmentRsvpSavePayload;
  appointmentTypeCreate: AppointmentTypeCreatePayload;
  appointmentTypeUpdate: AppointmentTypeUpdatePayload;
  appointmentUpdate: AppointmentUpdatePayload;
  assign: FormAssignmentCreatePayload;
  athleteBulkUpdate: AthleteBulkUpdatePayload;
  attributeBulkImport: AttributeBulkImportPayload;
  attributeBulkUpdate: AttributeBulkUpdatePayload;
  attributeCategoryUpdate: AttributeCategoryUpdatePayload;
  /** Mutation used for All Profiles table edit mode */
  attributeUpdate: AttributeUpdatePayload;
  attributesUpdate: AttributesUpdatePayload;
  bulkAssignProfilePicture: BulkAssignProfilePicturePayload;
  bulkStudentUpdate: Array<Maybe<StudentNode>>;
  bulletinClickCreate: BulletinClickCreatePayload;
  bulletinCreate: BulletinCreatePayload;
  bulletinDelete: BulletinDeletePayload;
  /** Sends reminders to all persons who have not clicked a bulletin */
  bulletinSendReminder: BulletinSendReminderPayload;
  bulletinUpdate: BulletinUpdatePayload;
  bulletinsSettingsCreate: BulletinsSettingsCreatePayload;
  bulletinsSettingsDelete: BulletinsSettingsDeletePayload;
  bulletinsSettingsUpdate: BulletinsSettingsUpdatePayload;
  /**
   * Mutation to create a downloadable file record for calendar reports
   * and spawn a generator task to create the report itself
   */
  calendarReportCreate: CalendarReportCreatePayload;
  calsyncAccountCreate: CalsyncAccountCreatePayload;
  calsyncAccountDelete: CalsyncAccountDeletePayload;
  calsyncAccountUpdate: CalsyncAccountUpdatePayload;
  calsyncCalendarCreate: CalsyncCalendarCreatePayload;
  calsyncCalendarDelete: CalsyncCalendarDeletePayload;
  calsyncCalendarUpdate: CalsyncCalendarUpdatePayload;
  calsyncCalendarUpdateStatus: CalsyncCalendarUpdateStatusPayload;
  cancelSignupReservations: CancelSignupReservations;
  cfApiTokenRefresh: CfapiTokenRefresh;
  changeAuditReview: ComplianceAuditsChangeReviewStatusPayload;
  checkinActionDelete: CheckinActionDeletePayload;
  /** Updates the active user's session metadata and returns the redirect url to the frontend */
  chooseAccount: ChooseAccount;
  closeSignupSlots: CloseSignupSlots;
  /** Used to have an individual sign a form, given their role. */
  completeForm: CompleteFormPayload;
  contactCreate: ContactCreatePayload;
  contactDelete: ContactDeletePayload;
  contactUpdate: ContactUpdatePayload;
  conversationAvatarSignedUrl: ConversationAvatarSignedUrl;
  courseCreate: CourseCreatePayload;
  courseSectionCreate: CourseSectionCreatePayload;
  courseSectionUpdate: CourseSectionUpdatePayload;
  courseUpdate: CourseUpdatePayload;
  createAppointmentFromSession: CreateAppointmentFromSessionPayload;
  createSignupReservations: CreateSignupReservations;
  customGroupCreate: CustomGroupCreatePayload;
  customGroupDelete: CustomGroupDeletePayload;
  customGroupUpdate: CustomGroupUpdatePayload;
  /** Defines the mutation for bulk actions against custom task assignments */
  customTaskAssignmentBulkActions: CustomTaskAssignmentBulkActionsPayload;
  /** Defines the mutation for completing a custom task */
  customTaskAssignmentComplete: CustomTaskAssignmentCompletePayload;
  /**
   * Mutation class for creating a custom task assignments export.
   *
   * This mutation allows users to create a custom task assignments export by providing the necessary input parameters.
   * It validates the input, checks the user's access permissions, and performs the export operation.
   *
   * Args:
   *     input (CustomTaskAssignmentsExportInput): The input object containing the parameters for creating the export.
   *
   * Returns:
   *     CustomTaskAssignmentsExport: The created custom task assignments export object.
   *
   * Raises:
   *     BadRequest: If the input is invalid or missing required fields.
   *     Forbidden: If the user does not have access to the custom task.
   */
  customTaskAssignmentsExportCreate: CustomTaskAssignmentsExportCreatePayload;
  /** Defines the mutation for deactivating a custom task */
  customTaskBulkDeactivate: CustomTaskBulkDeactivatePayload;
  /** Defines the mutation for creating a custom task */
  customTaskCreate: CustomTaskCreatePayload;
  /** Defines the mutation for creating a custom task label */
  customTaskLabelCreate: CustomTaskLabelCreatePayload;
  /** Defines the mutation for deleting a custom task label */
  customTaskLabelDelete: CustomTaskLabelDeletePayload;
  /** Defines the mutation for updating a custom task label */
  customTaskLabelUpdate: CustomTaskLabelUpdatePayload;
  /** Defines the mutation for updating a custom task */
  customTaskUpdate: CustomTaskUpdatePayload;
  deactivateAppointmentCheckinOccurrence: Array<Maybe<AppointmentCheckinOccurrenceNode>>;
  /** Used to declare that a signer has declined their role in signing a form. */
  declineForm: DeclineFormPayload;
  deleteSignups: DeleteSignups;
  /**
   * Really just here as a hack for use in a HTTP client like insomnia. Not used on the FE.
   * Again, named correctly but horribly mismatched with `travelPartyPersons` as it appears in other GraphQL request strings.
   */
  deleteTravelPartyMembers: TripDeleteTravelPartyMembersPayload;
  deleteTravelPartyPerson: DeletePartyPersonPayload;
  deleteUnassignedProfilePicture: DeleteUnassignedProfilePictureOutput;
  deleteWebViewTask: DeleteWebViewTask;
  /** Mutation that allows the creation of Digital Displays (and DD Pages by extension) */
  digitalDisplayCreate: DigitalDisplayCreatePayload;
  /** Mutation that allows the removal of Digital Displays (and DD Pages by extension) */
  digitalDisplayDelete: DigitalDisplayDeletePayload;
  /** Mutation that allows the updating of Digital Displays (and DD Pages by extension) */
  digitalDisplayUpdate: DigitalDisplayUpdatePayload;
  dromoS3Upload: DromoS3UploadOutput;
  enrollmentCreate: EnrollmentCreatePayload;
  enrollmentUpdate: EnrollmentUpdatePayload;
  externalCompletionCreate: ExternalCompletionCreate;
  /** Version of StudyHallActionCreate that is accessible by external checkin apps */
  externalStudyHallActionCreate: ExternalStudyHallActionCreatePayload;
  externalUserFileVideoActionCreate: ExternalUserFileVideoActionCreatePayload;
  externalVideoIncrementDownloadCount: ExternalVideoIncrementDownloadCountPayload;
  externalVideoIncrementPlayCount: ExternalVideoIncrementPlayCountPayload;
  folderCreate: FolderCreatePayload;
  folderDelete: FolderDeletePayload;
  folderUpdate: FolderUpdatePayload;
  formAssignmentBulkDeactivate: BulkDeactivateAssignmentsPayload;
  formAssignmentChooseRole: FormAssignmentChooseRolePayload;
  formAssignmentChooseRoles: FormAssignmentChooseRolesPayload;
  formAssignmentCompletionBulkManualComplete: FormAssignmentCompletionBulkManualCompletePayload;
  formAssignmentCompletionDelete: FormAssignmentCompletionDeletePayload;
  formAssignmentCompletionFetchPdf: FormAssignmentCompletionFetchPdfPayload;
  formAssignmentCompletionManualComplete: FormAssignmentCompletionManualCompletePayload;
  formAssignmentCompletionReassign: FormAssignmentCompletionReassignPayload;
  formAssignmentCompletionRoleSendReminder: FormAssignmentCompletionRoleSendReminderPayload;
  formAssignmentCreateSharedLink: FormAssignmentCreateSharedLinkPayload;
  formAssignmentDeactivate: DeactivateAssignmentPayload;
  formAssignmentDeleteSharedLink: FormAssignmentDeleteSharedLinkPayload;
  formAssignmentFetchPdf: FormAssignmentFetchPdfPayload;
  /** Sends form reminders to all persons that current user has visibility */
  formAssignmentSendReminders: FormAssignmentSendRemindersPayload;
  formAssignmentUpdate: FormAssignmentUpdatePayload;
  formCallback: HandleFormCallback;
  formResultsReportCreate: FormResultsReportCreatePayload;
  formScheduleDelete: FormScheduleDeletePayload;
  formScheduleSave: SaveFormSchedulePayload;
  /** Logs out the active user and returns the redirect url to the frontend */
  logoutUser: LogoutUser;
  messageBlastAttachmentS3UploadUrl: MessageBlastAttachmentS3UploadUrlOutput;
  messageCreate: MessageCreatePayload;
  messageCreateFromRetain: CreateMessageFromRetainPayload;
  messageDelete: MessageDeletePayload;
  messageUpdate: MessageUpdatePayload;
  messagingLogin: MessagingLoginOutput;
  modifyTravelParty: ModifyTravelPartyPayload;
  noteCreate: NoteCreatePayload;
  noteDelete: NoteDeletePayload;
  noteUpdate: NoteUpdatePayload;
  notificationSettingBulkSave: NotificationSettingBulkSavePayload;
  notificationSettingDelete: NotificationSettingDeletePayload;
  openSignupSlots: OpenSignupSlots;
  personAccountPreferencesUpdate: PersonAccountPreferencesUpdatePayload;
  personAddAddress: PersonAddAddressPayload;
  personAddTeamMembership: PersonAddTeamMembershipPayload;
  personBatchUpdateTeamMemberships: PersonBatchUpdateTeamMembershipsPayload;
  personBulkDeactivate: PersonBulkDeactivatePayload;
  personBulkGrantAccess: PersonBulkGrantAccessPayload;
  personBulkReactivate: PersonBulkReactivatePayload;
  personBulkRemoveTeamMemberships: PersonBulkRemoveTeamMembershipsPayload;
  personBulkUpdateLogin: PersonBulkUpdateLoginPayload;
  personBulkUpdateTeamMemberships: PersonBulkEditTeamMembershipsPayload;
  personCreate: PersonCreatePayload;
  personDeactivate: PersonDeactivatePayload;
  personGrantAllTeamsAccess: PersonGrantAllTeamsAccessPayload;
  /**
   * PersonModifyAllTeamsAccess will take a user from their current All Teams membership
   * to one or more newly provide valid memberships.
   */
  personModifyAllTeamsAccess: PersonModifyAllTeamsAccessPayload;
  personReactivate: PersonReactivatePayload;
  personRemoveAddress: PersonRemoveAddressPayload;
  personRemoveTeamMembership: PersonRemoveTeamMembershipPayload;
  /**
   * Admin reset password for user.
   *
   * Sends a password reset email to the user and puts them in password
   * reset mode (locks account until password reset).
   */
  personResetPassword: PersonResetPasswordPayload;
  personRevokeAllTeamsAccess: PersonRevokeAllTeamsAccessPayload;
  personSendInvitation: PersonSendInvitationPayload;
  personTypeCreate: PersonTypeCreatePayload;
  personTypeUpdate: PersonTypeUpdatePayload;
  personUpdate: PersonUpdatePayload;
  personUpdateAddress: PersonUpdateAddressPayload;
  prepareForSignature: PrepareForSignature;
  professorCreate: ProfessorCreatePayload;
  professorSurveyCreate: ProfessorSurveyCreatePayload;
  professorUpdate: ProfessorUpdatePayload;
  profileAttributeCategoryCreate: ProfileAttributeCategoryCreatePayload;
  profileAttributeCategoryDelete: ProfileAttributeCategoryDeletePayload;
  profileAttributeCategoryReorder: ProfileAttributeCategoryReorderPayload;
  profileAttributeCategoryUpdate: ProfileAttributeCategoryUpdatePayload;
  profileAttributeCreate: ProfileAttributeCreatePayload;
  profileAttributeDelete: ProfileAttributeDeletePayload;
  profileAttributeReorder: ProfileAttributeReorderPayload;
  profileAttributeUpdate: ProfileAttributeUpdatePayload;
  profileBulkImport: ProfileBulkImportPayload;
  profileBulkImportAddress: ProfileBulkImportAddressPayload;
  profileBulkImportRoommates: ProfileBulkImportRoommatesPayload;
  profileReportCreate: ProfileReportCreatePayload;
  quickFormAssignmentTemplateUpdate: QuickFormAssignmentTemplateUpdatePayload;
  quickFormTemplateCreate: QuickFormTemplateCreatePayload;
  quickFormTemplateDelete: QuickFormTemplateDeletePayload;
  quickFormTemplateDuplicate: QuickFormTemplateDuplicatePayload;
  quickFormTemplateUpdate: QuickFormTemplateUpdatePayload;
  relationshipTypeCreate: PersonRelationshipTypeCreatePayload;
  relationshipTypeDelete: PersonRelationshipTypeDeletePayload;
  relationshipTypeUpdate: PersonRelationshipTypeUpdatePayload;
  removeTravelVendorFromGroup: RemoveTravelVendorFromGroupPayload;
  /** Sends a password reset email to the given username */
  requestPasswordReset: RequestPasswordReset;
  rescheduleSignupReservations: RescheduleSignupReservations;
  roommateCreate: RoommateCreatePayload;
  roommateDelete: RoommateDeletePayload;
  roommateUpdate: RoommateUpdatePayload;
  saveAppointmentCheckinAction: AppointmentCheckinActionNode;
  saveAppointmentCheckinActionNote: AppointmentCheckinActionNode;
  /** Used to declare that a signer is not finished, but partial answers have been added. */
  saveFormProgress: SaveFormPayload;
  saveSignup: SignupNode;
  savedReportCreate: SavedReportCreatePayload;
  savedReportDelete: SavedReportDeletePayload;
  savedReportUpdate: SavedReportUpdatePayload;
  seasonCreate: SeasonCreatePayload;
  seasonDelete: SeasonDeletePayload;
  seasonUpdate: SeasonUpdatePayload;
  sendTravelPartyAssignments: SendTravelPartyAssignmentsPayload;
  sessionBulkCancel: SessionBulkCancelPayload;
  sessionDelete: SessionDeletePayload;
  sharedFileCreate: SharedFileCreatePayload;
  sharedFileDelete: SharedFileDeletePayload;
  sharedFileUpdate: SharedFileUpdatePayload;
  signedUrl: SignedUrl;
  signupAttachmentBulkCreate: SignupAttachmentBulkCreatePayload;
  signupsMessageUsers: SignupsMessageUsers;
  /** Mutation to store the mobile app version */
  storeVersionData: StoreVersionData;
  studyHallActionBulkCreate: StudyHallActionBulkCreatePayload;
  studyHallActionCreate: StudyHallActionCreatePayload;
  studyHallActionDelete: StudyHallActionDeletePayload;
  studyHallActionUpdate: StudyHallActionUpdatePayload;
  studyHallCreate: StudyHallCreatePayload;
  studyHallDelete: StudyHallDeletePayload;
  studyHallRequirementBulkCreateOrUpdate: StudyHallRequirementBulkCreateOrUpdatePayload;
  studyHallUpdate: StudyHallUpdatePayload;
  subjectCreate: SubjectCreatePayload;
  subjectUpdate: SubjectUpdatePayload;
  /** Updates active users current team */
  switchUsersCurrentTeam: SwitchUsersCurrentTeam;
  teamMemberBulkUpdatePermissions: BulkTeamMemberUpdatePermissionsPayload;
  teamMemberReactivate: TeamMemberReactivatePayload;
  teamMemberUpdatePermissions: TeamMemberUpdatePermissionsPayload;
  teamMemberUpdateUserTypes: TeamMemberUpdateUserTypesPayload;
  teamModuleUpdate: TeamModuleUpdatePayload;
  /** Bulk create Team Roster records for a given Team for all TMP Years */
  teamRosterBulkCreate: TeamRosterBulkCreatePayload;
  teamRosterCreate: TeamRosterCreatePayload;
  teamRosterDelete: TeamRosterDeletePayload;
  /**
   * Bulk Create Team Roster Person records for the following cases:
   *     - 1 Person across multiple Team Rosters
   *     - Multiple Persons for 1 Team Roster
   */
  teamRosterPersonBulkCreate: TeamRosterPersonBulkCreatePayload;
  /** Bulk Delete Team Roster Person records from only one Roster/Team. */
  teamRosterPersonBulkDelete: TeamRosterPersonBulkDeletePayload;
  /** Create a Team Roster Person record for a Roster/Team */
  teamRosterPersonCreate: TeamRosterPersonCreatePayload;
  /** Delete a Team Roster Person record */
  teamRosterPersonDelete: TeamRosterPersonDeletePayload;
  /** Rollover Team Roster Person records from one Roster to another or update a team member's athlete status */
  teamRosterPersonRollover: TeamRosterPersonRolloverPayload;
  /**
   * When persons are rollover over into another Roster year, we do the following:
   *     - Bulk Create Team Roster Person records for the target roster year:
   *     - Copy the yearly attribute values from source roster year to target roster year (TMP Year)
   */
  teamRosterPersonYearlyAttributeBulkRollover: TeamRosterPersonRolloverYearlyAttributePayload;
  teamRosterReportCreate: TeamRosterReportCreatePayload;
  teamUpdate: TeamUpdatePayload;
  ticketingBlockedGuestBulkCreate: TicketingBlockedGuestBulkCreatePayload;
  ticketingBlockedGuestBulkDelete: TicketingBlockedGuestBulkDeletePayload;
  ticketingBlockedGuestCreate: TicketingBlockedGuestCreatePayload;
  ticketingBlockedGuestImportCsv: TicketingBlockedGuestImportCsvPayload;
  /** Responsible for enabling bulk copy allocations from one event to multiple events */
  ticketingEventAllocationBulkCopy: TicketingEventAllocationBulkCopyPayload;
  ticketingEventBulkUpdate: TicketingEventBulkUpdatePayload;
  ticketingEventPersonBulkRevoke: TicketingEventPersonBulkRevokePayload;
  ticketingEventPersonCancelReservation: TicketingEventPersonCancelReservationPayload;
  ticketingEventPersonCopy: TicketingEventPersonCopyPayload;
  ticketingEventPersonDefaultAllocationBulkUpdate: TicketingEventPersonDefaultAllocationBulkUpdatePayload;
  ticketingEventPersonReserve: TicketingEventPersonReservePayload;
  ticketingEventPersonRevoke: TicketingEventPersonRevokePayload;
  ticketingEventPersonTransfer: TicketingEventPersonTransferPayload;
  ticketingEventPersonUpdate: TicketingEventPersonUpdatePayload;
  ticketingEventUpdate: TicketingEventUpdatePayload;
  ticketingGuestBulkUpdate: TicketingGuestBulkUpdatePayload;
  ticketingGuestCreate: TicketingGuestCreatePayload;
  ticketingGuestUpdate: TicketingGuestUpdatePayload;
  ticketingOrgSettingCreate: TicketingOrgSettingCreatePayload;
  ticketingOrgSettingUpdate: TicketingOrgSettingUpdatePayload;
  ticketingReservationReportCreate: TicketingReservationReportCreatePayload;
  tmpDateCreate: TmpDateCreatePayload;
  /** Super simple and unrestrictive delete... Let's hope this doesn't bite us in the butt :-) */
  tmpDateDelete: TmpDateDeletePayload;
  tmpDateUpdate: TmpDateUpdatePayload;
  tmpSettingsUpdate: TmpTeamSettingsUpdatePayload;
  tmpTeamCreate: TmpTeamCreatePayload;
  tmpTeamSegmentCreate: TmpTeamSegmentCreatePayload;
  tmpTeamSegmentDelete: TmpTeamSegmentDeletePayload;
  tmpTeamSegmentUpdate: TmpTeamSegmentUpdatePayload;
  tmpYearRuleCreate: TmpYearRuleCreatePayload;
  tmpYearRuleUpdate: TmpYearRuleUpdatePayload;
  tmpYearUpdate: TmpYearUpdatePayload;
  /** Serves as an endpoint to ping that will refresh access token and refresh the CF session */
  tokenRefresh: TokenRefresh;
  travelAirSeatingChartCreate: TravelAirSeatingChartCreatePayload;
  travelAirSeatingChartDelete: TravelAirSeatingChartDeletePayload;
  travelAirSeatingChartUpdate: TravelAirSeatingChartUpdatePayload;
  travelContactCreate: TravelContactCreatePayload;
  travelContactDelete: TravelContactDeletePayload;
  travelContactUpdate: TravelContactUpdatePayload;
  travelCredentialAssignmentBulkUpdate: TravelCredentialAssignmentBulkUpdatePayload;
  travelCredentialAssignmentCopy: TravelCredentialAssignmentCopyPayload;
  travelCredentialCreate: TravelCredentialCreatePayload;
  travelCredentialDelete: TravelCredentialDeletePayload;
  travelCredentialUpdate: TravelCredentialUpdatePayload;
  travelDocumentConfigUpsert: TravelDocumentConfigUpsertPayload;
  travelDressCreate: TravelDressCreatePayload;
  travelDressDelete: TravelDressDeletePayload;
  travelDressUpdate: TravelDressUpdatePayload;
  travelEventBulkDelete: TravelEventBulkDeletePayload;
  travelEventBulkUpdate: TravelEventBulkUpdatePayload;
  travelEventCopy: TravelEventCopyPayload;
  travelEventCreate: TravelEventCreatePayload;
  travelEventDelete: TravelEventDeletePayload;
  travelEventUpdate: TravelEventUpdatePayload;
  travelParticipationBulkUpsert: TravelParticipationBulkUpsertPayload;
  travelParticipationReportCreate: TravelParticipationReportCreatePayload;
  travelPregameItemCopy: TravelPregameItemCopyPayload;
  travelPregameItemCreate: TravelPregameItemCreatePayload;
  travelPregameItemDelete: TravelPregameItemDeletePayload;
  travelPregameItemUpdate: TravelPregameItemUpdatePayload;
  travelSeatplanCopy: TravelSeatplanCopyPayload;
  travelSeatplanCopyOverride: TravelSeatplanCopyOverridePayload;
  travelSeatplanCreate: TravelSeatplanCreatePayload;
  travelSeatplanDelete: TravelSeatplanDeletePayload;
  travelSeatplanUpdate: TravelSeatplanUpdatePayload;
  travelTaskBulkUpdate: TravelTaskBulkUpdatePayload;
  travelTaskCopy: TravelTaskCopyPayload;
  travelTaskCreate: TravelTaskCreatePayload;
  travelTaskDelete: TravelTaskDeletePayload;
  travelTaskUpdate: TravelTaskUpdatePayload;
  travelVendorCreate: TravelVendorCreatePayload;
  travelVendorDelete: TravelVendorDeletePayload;
  travelVendorGroupCreate: TravelVendorGroupCreatePayload;
  travelVendorGroupDelete: TravelVendorGroupDeletePayload;
  travelVendorGroupUpdate: TravelVendorGroupUpdatePayload;
  travelVendorUpdate: TravelVendorUpdatePayload;
  tripBulkDeleteTravelParty: TripBulkDeleteTravelPartyPayload;
  tripBulkUpdateTravelParty: TripBulkUpdateTravelPartyPayload;
  /** Responsible for creating both trips, and associated competitions for a given trip, with one mutation. */
  tripCompetitionsBulkCreate: TripCompetitionsBulkCreate;
  tripCreate: TripCreatePayload;
  tripDelete: TripDeletePayload;
  tripUpdate: TripUpdatePayload;
  updateActivityIsViewed: UpdateActivityIsViewed;
  updateCompetitionPregameVisibilities: UpdateCompetitionPregameVisibilitiesPayload;
  updateCompetitionSlideTimeInterval: UpdateCompetitionSlideTimeIntervalPayload;
  updateLoginData: UpdateLoginDataOutput;
  uploadAttachment: UploadAttachmentOutput;
  uploadProfilePicture: UploadProfilePictureOutput;
  /** Mutation for user to link their account to another. */
  userAccountsMerge: UserAccountsMerge;
  /** Mutation for user to update their own email. */
  userEmailUpdate: UserEmailUpdate;
  userFileVideoActionCreate: UserFileVideoActionCreatePayload;
  /** Mutation for user to update their own password. */
  userPasswordUpdate: UserPasswordUpdate;
  /** Registers a new user and returns access and refresh tokens to the frontend */
  userRegister: UserRegister;
  /** Mutation for user to update their own username. */
  userUsernameUpdate: UserUsernameUpdate;
  /** Sends a username reminder to the given email address or phone number */
  usernameReminderSend: UsernameReminderSend;
  videoCreate: VideoCreatePayload;
  videoDelete: VideoDeletePayload;
  videoIncrementDownloadCount: VideoIncrementDownloadCountPayload;
  videoIncrementPlayCount: VideoIncrementPlayCountPayload;
  videoUpdate: VideoUpdatePayload;
  weblinkCreate: WebLinkCreatePayload;
  weblinkDelete: WebLinkDeletePayload;
  weblinkUpdate: WebLinkUpdatePayload;
  /** Mutation to create yearly attribute category */
  yearlyAttributeCategoryCreate: YearlyAttributeCategoryCreatePayload;
  /** Mutation to delete an existing yearly attribute category */
  yearlyAttributeCategoryDelete: YearlyAttributeCategoryDeletePayload;
  yearlyAttributeCategoryReorder: YearlyAttributeCategoryReorderPayload;
  /** Mutation to update an existing yearly attribute category */
  yearlyAttributeCategoryUpdate: YearlyAttributeCategoryUpdatePayload;
  yearlyAttributeReportCreate: YearlyAttributeReportCreatePayload;
  /** Mutation that creates yearly attribute under a category */
  yearlyAttributeSettingCreate: YearlyAttributeSettingCreatePayload;
  /** Mutation to delete an existing yearly attribute */
  yearlyAttributeSettingDelete: YearlyAttributeSettingDeletePayload;
  /** Defines the operations we need to re-order yearly attributes within a category */
  yearlyAttributeSettingReorder: YearlyAttributeSettingReorderPayload;
  /** Mutation that updates existing yearly attribute */
  yearlyAttributeSettingUpdate: YearlyAttributeSettingUpdatePayload;
  /** Mutation that creates or updates a yearly attribute value in a TMP Year for a user */
  yearlyAttributeUserUpdate: YearlyAttributeUserUpdatePayload;
};

export type MutationActivityLogSignoffArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ActivityLogSignoffInput>;
};

export type MutationAddAuditCommentArgs = {
  fileData?: InputMaybe<Scalars['Upload']>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ComplianceAuditAddCommentInput>;
};

export type MutationAddTravelVendorToGroupArgs = {
  input?: InputMaybe<TravelVendorGroupVendorLinkInput>;
};

export type MutationAdminBulkImportContactsArgs = {
  input?: InputMaybe<BulkPersonContactInput>;
};

export type MutationAppAssignmentsUpdateArgs = {
  input?: InputMaybe<AppAssignmentsUpdateInput>;
};

export type MutationAppointmentAttachmentBulkCreateArgs = {
  files: Array<InputMaybe<FileAttachmentFileData>>;
  parentId: Scalars['ID'];
};

export type MutationAppointmentAttachmentBulkDeleteArgs = {
  fileIds: Array<Scalars['ID']>;
};

export type MutationAppointmentAttachmentSignedUrlArgs = {
  fileNames: Array<Scalars['String']>;
};

export type MutationAppointmentAttachmentUploadArgs = {
  appointmentId: Scalars['ID'];
};

export type MutationAppointmentBulkCopyArgs = {
  input?: InputMaybe<Array<InputMaybe<AppointmentBulkCopyDateInput>>>;
};

export type MutationAppointmentBulkDeleteArgs = {
  input?: InputMaybe<Array<InputMaybe<AppointmentBulkDeleteInput>>>;
};

export type MutationAppointmentBulkUpdateArgs = {
  input?: InputMaybe<Array<InputMaybe<AppointmentBulkUpdateInput>>>;
};

export type MutationAppointmentCreateArgs = {
  input?: InputMaybe<AppointmentCreateInput>;
};

export type MutationAppointmentDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<AppointmentDeleteInput>;
};

export type MutationAppointmentRsvpSaveArgs = {
  input?: InputMaybe<AppointmentRsvpInput>;
};

export type MutationAppointmentTypeCreateArgs = {
  input?: InputMaybe<AppointmentTypeInput>;
};

export type MutationAppointmentTypeUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<AppointmentTypeInput>;
};

export type MutationAppointmentUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<AppointmentUpdateInput>;
};

export type MutationAssignArgs = {
  input?: InputMaybe<AssignmentArgsInput>;
};

export type MutationAthleteBulkUpdateArgs = {
  input?: InputMaybe<AthleteBulkUpdateInput>;
};

export type MutationAttributeBulkImportArgs = {
  input?: InputMaybe<ProfileAttributeBulkImportInput>;
};

export type MutationAttributeBulkUpdateArgs = {
  input?: InputMaybe<BulkAttributeInput>;
};

export type MutationAttributeCategoryUpdateArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<AttributeCategoryInput>;
};

export type MutationAttributeUpdateArgs = {
  input?: InputMaybe<AttributeInput>;
  personId?: InputMaybe<Scalars['ID']>;
};

export type MutationAttributesUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<AttributeInput>;
};

export type MutationBulkAssignProfilePictureArgs = {
  input?: InputMaybe<BulkPersonPictureUploadInput>;
};

export type MutationBulkStudentUpdateArgs = {
  input?: InputMaybe<StudentInput>;
};

export type MutationBulletinClickCreateArgs = {
  input?: InputMaybe<BulletinClickInput>;
};

export type MutationBulletinCreateArgs = {
  input?: InputMaybe<BulletinInput>;
};

export type MutationBulletinDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationBulletinSendReminderArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<BulletinSendReminderInput>;
};

export type MutationBulletinUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<BulletinInput>;
};

export type MutationBulletinsSettingsCreateArgs = {
  input?: InputMaybe<BulletinsSettingsInput>;
};

export type MutationBulletinsSettingsDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationBulletinsSettingsUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<BulletinsSettingsInput>;
};

export type MutationCalendarReportCreateArgs = {
  input?: InputMaybe<CalendarReportInput>;
};

export type MutationCalsyncAccountCreateArgs = {
  baseUrl: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<CalsyncAccountInput>;
};

export type MutationCalsyncAccountDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationCalsyncAccountUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<CalsyncAccountInput>;
};

export type MutationCalsyncCalendarCreateArgs = {
  input?: InputMaybe<CalsyncCalendarInput>;
};

export type MutationCalsyncCalendarDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationCalsyncCalendarUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<CalsyncCalendarInput>;
};

export type MutationCalsyncCalendarUpdateStatusArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationCancelSignupReservationsArgs = {
  input?: InputMaybe<Array<InputMaybe<ReservationInput>>>;
  sendAlerts?: InputMaybe<Scalars['String']>;
};

export type MutationChangeAuditReviewArgs = {
  input?: InputMaybe<ComplianceAuditsChangeReviewStatusInput>;
};

export type MutationCheckinActionDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationChooseAccountArgs = {
  person: Scalars['ID'];
};

export type MutationCloseSignupSlotsArgs = {
  sendAlerts?: InputMaybe<Scalars['String']>;
  slotIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type MutationCompleteFormArgs = {
  input?: InputMaybe<CompleteFormInput>;
};

export type MutationContactCreateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<PersonContactInput>;
};

export type MutationContactDeleteArgs = {
  contactId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationContactUpdateArgs = {
  contactId: Scalars['ID'];
  id: Scalars['ID'];
  input?: InputMaybe<PersonContactUpdateInput>;
};

export type MutationConversationAvatarSignedUrlArgs = {
  input?: InputMaybe<SignedUrlInput>;
};

export type MutationCourseCreateArgs = {
  input?: InputMaybe<CourseCreateInput>;
};

export type MutationCourseSectionCreateArgs = {
  input?: InputMaybe<CourseSectionCreateInput>;
};

export type MutationCourseSectionUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<CourseSectionUpdateInput>;
};

export type MutationCourseUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<CourseUpdateInput>;
};

export type MutationCreateAppointmentFromSessionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationCreateSignupReservationsArgs = {
  input?: InputMaybe<Array<InputMaybe<ReservationInput>>>;
  sendAlerts?: InputMaybe<Scalars['String']>;
};

export type MutationCustomGroupCreateArgs = {
  input?: InputMaybe<CustomGroupInput>;
};

export type MutationCustomGroupDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationCustomGroupUpdateArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<CustomGroupInput>;
};

export type MutationCustomTaskAssignmentBulkActionsArgs = {
  input?: InputMaybe<CustomTaskAssignmentBulkActionsInput>;
};

export type MutationCustomTaskAssignmentCompleteArgs = {
  id: Scalars['ID'];
};

export type MutationCustomTaskAssignmentsExportCreateArgs = {
  input?: InputMaybe<CustomTaskAssignmentsExportInput>;
};

export type MutationCustomTaskBulkDeactivateArgs = {
  input?: InputMaybe<CustomTaskBulkDeactivateInput>;
};

export type MutationCustomTaskCreateArgs = {
  input?: InputMaybe<CustomTaskCreateInput>;
};

export type MutationCustomTaskLabelCreateArgs = {
  input?: InputMaybe<CustomTaskLabelInput>;
};

export type MutationCustomTaskLabelDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationCustomTaskLabelUpdateArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<CustomTaskLabelInput>;
};

export type MutationCustomTaskUpdateArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<CustomTaskUpdateInput>;
};

export type MutationDeactivateAppointmentCheckinOccurrenceArgs = {
  input?: InputMaybe<Array<InputMaybe<DeactivateApptCheckinOccurrenceInput>>>;
};

export type MutationDeclineFormArgs = {
  input?: InputMaybe<DeclineFormInput>;
};

export type MutationDeleteSignupsArgs = {
  input?: InputMaybe<Array<InputMaybe<DeleteSignupInput>>>;
  sendAlerts?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteTravelPartyMembersArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationDeleteTravelPartyPersonArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<DeleteTravelPartyPersonInput>;
};

export type MutationDeleteUnassignedProfilePictureArgs = {
  input?: InputMaybe<DeleteUnassignedProfilePictureInput>;
};

export type MutationDeleteWebViewTaskArgs = {
  taskData: DeleteWebViewTaskInput;
};

export type MutationDigitalDisplayCreateArgs = {
  input?: InputMaybe<DigitalDisplayCreateInput>;
};

export type MutationDigitalDisplayDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationDigitalDisplayUpdateArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<DigitalDisplayUpdateInput>;
};

export type MutationDromoS3UploadArgs = {
  input?: InputMaybe<DromoUploadS3FileParams>;
};

export type MutationEnrollmentCreateArgs = {
  input?: InputMaybe<EnrollmentCreateInput>;
};

export type MutationEnrollmentUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<EnrollmentUpdateInput>;
};

export type MutationExternalCompletionCreateArgs = {
  input?: InputMaybe<ExternalCompletionCreateInput>;
};

export type MutationExternalStudyHallActionCreateArgs = {
  input?: InputMaybe<ExternalStudyHallActionInput>;
};

export type MutationExternalUserFileVideoActionCreateArgs = {
  input?: InputMaybe<UserFileVideoActionInput>;
};

export type MutationExternalVideoIncrementDownloadCountArgs = {
  input?: InputMaybe<ExternalVideoInput>;
};

export type MutationExternalVideoIncrementPlayCountArgs = {
  input?: InputMaybe<ExternalVideoInput>;
};

export type MutationFolderCreateArgs = {
  input?: InputMaybe<FolderInput>;
};

export type MutationFolderDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<FolderDeleteInput>;
};

export type MutationFolderUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<FolderUpdateInput>;
};

export type MutationFormAssignmentBulkDeactivateArgs = {
  input?: InputMaybe<BulkDeactivateAssignmentArgsInput>;
};

export type MutationFormAssignmentChooseRoleArgs = {
  input?: InputMaybe<ChooseRoleInput>;
};

export type MutationFormAssignmentChooseRolesArgs = {
  input?: InputMaybe<ChooseRolesInput>;
};

export type MutationFormAssignmentCompletionBulkManualCompleteArgs = {
  input?: InputMaybe<FormAssignmentCompletionBulkManualCompleteInput>;
};

export type MutationFormAssignmentCompletionDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationFormAssignmentCompletionFetchPdfArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<FormAssignmentCompletionFetchPdfInput>;
};

export type MutationFormAssignmentCompletionManualCompleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<FormAssignmentCompletionManualCompleteInput>;
};

export type MutationFormAssignmentCompletionReassignArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<FormAssignmentCompletionReassignInput>;
};

export type MutationFormAssignmentCompletionRoleSendReminderArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<CompletionRoleSendReminderInput>;
};

export type MutationFormAssignmentCreateSharedLinkArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationFormAssignmentDeactivateArgs = {
  input?: InputMaybe<DeactivateAssignmentArgsInput>;
};

export type MutationFormAssignmentDeleteSharedLinkArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationFormAssignmentFetchPdfArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<FormAssignmentFetchPdfInput>;
};

export type MutationFormAssignmentSendRemindersArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<FormAssignmentSendRemindersInput>;
};

export type MutationFormAssignmentUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<UpdateAssignmentArgsInput>;
};

export type MutationFormCallbackArgs = {
  input?: InputMaybe<FormCallbackInput>;
};

export type MutationFormResultsReportCreateArgs = {
  input?: InputMaybe<FormResultsReportCreateInput>;
};

export type MutationFormScheduleDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationFormScheduleSaveArgs = {
  input?: InputMaybe<SaveFormScheduleArgsInput>;
};

export type MutationLogoutUserArgs = {
  devicePushToken?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type MutationMessageBlastAttachmentS3UploadUrlArgs = {
  input?: InputMaybe<MessageBlastAttachmentS3UploadUrlInput>;
};

export type MutationMessageCreateArgs = {
  input?: InputMaybe<MessageInput>;
};

export type MutationMessageCreateFromRetainArgs = {
  input?: InputMaybe<MessageFromRetainInput>;
};

export type MutationMessageDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationMessageUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<MessageInput>;
};

export type MutationMessagingLoginArgs = {
  input?: InputMaybe<MessagingLoginInput>;
};

export type MutationModifyTravelPartyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ModifyTravelPartyInput>;
};

export type MutationNoteCreateArgs = {
  input?: InputMaybe<NoteInput>;
};

export type MutationNoteDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationNoteUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<NoteInput>;
};

export type MutationNotificationSettingBulkSaveArgs = {
  input?: InputMaybe<NotificationSettingBulkSaveInput>;
};

export type MutationNotificationSettingDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationOpenSignupSlotsArgs = {
  slotIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type MutationPersonAccountPreferencesUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<PersonPreferencesInput>;
};

export type MutationPersonAddAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<AddressInput>;
};

export type MutationPersonAddTeamMembershipArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<PersonInput>;
  teamId?: InputMaybe<Scalars['ID']>;
};

export type MutationPersonBatchUpdateTeamMembershipsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<BatchUpdateTeamMembershipsInput>;
};

export type MutationPersonBulkDeactivateArgs = {
  input?: InputMaybe<BulkPersonDeactivateInput>;
};

export type MutationPersonBulkGrantAccessArgs = {
  input?: InputMaybe<BulkGrantAccessInput>;
};

export type MutationPersonBulkReactivateArgs = {
  input?: InputMaybe<BulkPersonReactivateInput>;
};

export type MutationPersonBulkRemoveTeamMembershipsArgs = {
  input?: InputMaybe<PersonBulkRemoveTeamMembersInput>;
};

export type MutationPersonBulkUpdateLoginArgs = {
  input?: InputMaybe<PersonBulkUpdateLoginInput>;
};

export type MutationPersonBulkUpdateTeamMembershipsArgs = {
  input?: InputMaybe<PersonBulkEditTeamMembersInput>;
};

export type MutationPersonCreateArgs = {
  input?: InputMaybe<PersonInput>;
};

export type MutationPersonDeactivateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationPersonGrantAllTeamsAccessArgs = {
  id?: InputMaybe<Scalars['ID']>;
  sourceTeamId?: InputMaybe<Scalars['ID']>;
};

export type MutationPersonModifyAllTeamsAccessArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ModifyAllTeamAccess>;
};

export type MutationPersonReactivateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationPersonRemoveAddressArgs = {
  addressId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationPersonRemoveTeamMembershipArgs = {
  id?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

export type MutationPersonResetPasswordArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<PersonSendInvitationInput>;
};

export type MutationPersonRevokeAllTeamsAccessArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationPersonSendInvitationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<PersonSendInvitationInput>;
};

export type MutationPersonTypeCreateArgs = {
  input?: InputMaybe<PersonTypeInput>;
};

export type MutationPersonTypeUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<PersonTypeInput>;
};

export type MutationPersonUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<PersonInput>;
};

export type MutationPersonUpdateAddressArgs = {
  addressId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<AddressInput>;
};

export type MutationPrepareForSignatureArgs = {
  input?: InputMaybe<PrepareForSignatureArgsInput>;
};

export type MutationProfessorCreateArgs = {
  input?: InputMaybe<ProfessorCreateInput>;
};

export type MutationProfessorSurveyCreateArgs = {
  input?: InputMaybe<ProfessorSurveyCreateInput>;
};

export type MutationProfessorUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ProfessorUpdateInput>;
};

export type MutationProfileAttributeCategoryCreateArgs = {
  input?: InputMaybe<ProfileAttributeCategoryInput>;
};

export type MutationProfileAttributeCategoryDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationProfileAttributeCategoryReorderArgs = {
  input?: InputMaybe<ProfileAttributeCategoryReorderInput>;
};

export type MutationProfileAttributeCategoryUpdateArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<ProfileAttributeCategoryInput>;
};

export type MutationProfileAttributeCreateArgs = {
  input?: InputMaybe<ProfileAttributeInput>;
};

export type MutationProfileAttributeDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationProfileAttributeReorderArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ProfileAttributeReorderInput>;
};

export type MutationProfileAttributeUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ProfileAttributeInput>;
};

export type MutationProfileBulkImportArgs = {
  input?: InputMaybe<ProfileBulkInput>;
};

export type MutationProfileBulkImportAddressArgs = {
  input?: InputMaybe<BulkAddressInput>;
};

export type MutationProfileBulkImportRoommatesArgs = {
  input?: InputMaybe<ProfileBulkRoommateInput>;
};

export type MutationProfileReportCreateArgs = {
  input?: InputMaybe<ProfileReportInput>;
  teamId?: InputMaybe<Scalars['ID']>;
};

export type MutationQuickFormAssignmentTemplateUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<QuickFormTemplateInput>;
};

export type MutationQuickFormTemplateCreateArgs = {
  input?: InputMaybe<QuickFormTemplateInput>;
};

export type MutationQuickFormTemplateDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationQuickFormTemplateDuplicateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationQuickFormTemplateUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<QuickFormTemplateInput>;
};

export type MutationRelationshipTypeCreateArgs = {
  input?: InputMaybe<PersonRelationshipTypeInput>;
};

export type MutationRelationshipTypeDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationRelationshipTypeUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<PersonRelationshipTypeInput>;
};

export type MutationRemoveTravelVendorFromGroupArgs = {
  input?: InputMaybe<TravelVendorGroupVendorLinkInput>;
};

export type MutationRequestPasswordResetArgs = {
  username: Scalars['String'];
};

export type MutationRescheduleSignupReservationsArgs = {
  input: Array<InputMaybe<RescheduleReservationInput>>;
  sendAlerts?: InputMaybe<Scalars['String']>;
};

export type MutationRoommateCreateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<RoommateInput>;
};

export type MutationRoommateDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  roommateId?: InputMaybe<Scalars['ID']>;
};

export type MutationRoommateUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<RoommateInput>;
  roommateId?: InputMaybe<Scalars['ID']>;
};

export type MutationSaveAppointmentCheckinActionArgs = {
  input?: InputMaybe<AppointmentCheckinActionInput>;
};

export type MutationSaveAppointmentCheckinActionNoteArgs = {
  input?: InputMaybe<AppointmentCheckinActionNoteInput>;
};

export type MutationSaveFormProgressArgs = {
  input?: InputMaybe<SaveFormInput>;
};

export type MutationSaveSignupArgs = {
  input?: InputMaybe<SignupInput>;
  sendAlerts?: InputMaybe<Scalars['String']>;
  signupId?: InputMaybe<Scalars['String']>;
};

export type MutationSavedReportCreateArgs = {
  input?: InputMaybe<SavedReportInput>;
};

export type MutationSavedReportDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationSavedReportUpdateArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<SavedReportUpdateInput>;
};

export type MutationSeasonCreateArgs = {
  input?: InputMaybe<SeasonInput>;
};

export type MutationSeasonDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationSeasonUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<SeasonInput>;
};

export type MutationSendTravelPartyAssignmentsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<SendTravelPartyAssignmentInput>;
};

export type MutationSessionBulkCancelArgs = {
  input?: InputMaybe<SessionBulkCancelInput>;
};

export type MutationSessionDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationSharedFileCreateArgs = {
  fileData?: InputMaybe<Scalars['Upload']>;
  input?: InputMaybe<SharedFileInput>;
};

export type MutationSharedFileDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationSharedFileUpdateArgs = {
  fileData?: InputMaybe<Scalars['Upload']>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<SharedFileInput>;
};

export type MutationSignedUrlArgs = {
  input?: InputMaybe<SignedUrlInput>;
};

export type MutationSignupAttachmentBulkCreateArgs = {
  files: Array<InputMaybe<FileAttachmentFileData>>;
  parentId: Scalars['ID'];
};

export type MutationSignupsMessageUsersArgs = {
  input?: InputMaybe<SignupsMessageUsersInput>;
};

export type MutationStoreVersionDataArgs = {
  input?: InputMaybe<VersionInput>;
};

export type MutationStudyHallActionBulkCreateArgs = {
  input?: InputMaybe<StudyHallActionBulkCreateInput>;
};

export type MutationStudyHallActionCreateArgs = {
  input?: InputMaybe<StudyHallActionInput>;
};

export type MutationStudyHallActionDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationStudyHallActionUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<StudyHallActionUpdateInput>;
};

export type MutationStudyHallCreateArgs = {
  input?: InputMaybe<StudyHallInput>;
};

export type MutationStudyHallDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationStudyHallRequirementBulkCreateOrUpdateArgs = {
  input?: InputMaybe<StudyHallRequirementBulkCreateOrUpdateInput>;
};

export type MutationStudyHallUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<StudyHallInput>;
};

export type MutationSubjectCreateArgs = {
  input?: InputMaybe<SubjectCreateInput>;
};

export type MutationSubjectUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<SubjectUpdateInput>;
};

export type MutationSwitchUsersCurrentTeamArgs = {
  team: Scalars['ID'];
};

export type MutationTeamMemberBulkUpdatePermissionsArgs = {
  input?: InputMaybe<BulkUpdatePermissionsInput>;
};

export type MutationTeamMemberReactivateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTeamMemberUpdatePermissionsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<UpdatePermissionsInput>;
};

export type MutationTeamMemberUpdateUserTypesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<UpdateUserTypesInput>;
};

export type MutationTeamModuleUpdateArgs = {
  input?: InputMaybe<TeamModuleUpdateInput>;
};

export type MutationTeamRosterBulkCreateArgs = {
  input?: InputMaybe<TeamRosterBulkCreateInput>;
};

export type MutationTeamRosterCreateArgs = {
  input?: InputMaybe<TeamRosterInput>;
};

export type MutationTeamRosterDeleteArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<TeamRosterDeleteInput>;
};

export type MutationTeamRosterPersonBulkCreateArgs = {
  input?: InputMaybe<TeamRosterPersonBulkCreateInput>;
};

export type MutationTeamRosterPersonBulkDeleteArgs = {
  input?: InputMaybe<TeamRosterPersonBulkInput>;
};

export type MutationTeamRosterPersonCreateArgs = {
  input?: InputMaybe<TeamRosterPersonInput>;
};

export type MutationTeamRosterPersonDeleteArgs = {
  input?: InputMaybe<TeamRosterPersonInput>;
};

export type MutationTeamRosterPersonRolloverArgs = {
  input?: InputMaybe<TeamRosterPersonRolloverInput>;
};

export type MutationTeamRosterPersonYearlyAttributeBulkRolloverArgs = {
  input?: InputMaybe<TeamRosterPersonRolloverYearlyAttributeInput>;
};

export type MutationTeamRosterReportCreateArgs = {
  input?: InputMaybe<TeamRosterReportInput>;
};

export type MutationTeamUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TeamUpdateInput>;
};

export type MutationTicketingBlockedGuestBulkCreateArgs = {
  input?: InputMaybe<TicketingBlockedGuestBulkCreateInput>;
};

export type MutationTicketingBlockedGuestBulkDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TicketingBlockedGuestBulkDeleteInput>;
};

export type MutationTicketingBlockedGuestCreateArgs = {
  input?: InputMaybe<TicketingBlockedGuestInput>;
};

export type MutationTicketingBlockedGuestImportCsvArgs = {
  csv: Scalars['Upload'];
};

export type MutationTicketingEventAllocationBulkCopyArgs = {
  input?: InputMaybe<TicketingEventAllocationCopyInput>;
};

export type MutationTicketingEventBulkUpdateArgs = {
  input?: InputMaybe<TicketingEventBulkInput>;
};

export type MutationTicketingEventPersonBulkRevokeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TicketingEventPersonBulkRevokeInput>;
};

export type MutationTicketingEventPersonCancelReservationArgs = {
  input?: InputMaybe<TicketingCancelReservationInput>;
};

export type MutationTicketingEventPersonCopyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TicketingEventPersonCopyInput>;
};

export type MutationTicketingEventPersonDefaultAllocationBulkUpdateArgs = {
  input?: InputMaybe<TicketingEventPersonDefaultAllocationBulkUpdateInput>;
};

export type MutationTicketingEventPersonReserveArgs = {
  input?: InputMaybe<TicketingReserveInput>;
};

export type MutationTicketingEventPersonRevokeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTicketingEventPersonTransferArgs = {
  input?: InputMaybe<TicketingTransferInput>;
};

export type MutationTicketingEventPersonUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TicketingEventPersonInput>;
};

export type MutationTicketingEventUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TicketingEventInput>;
};

export type MutationTicketingGuestBulkUpdateArgs = {
  input?: InputMaybe<TicketingGuestBulkUpdateInput>;
};

export type MutationTicketingGuestCreateArgs = {
  input?: InputMaybe<TicketingGuestInput>;
};

export type MutationTicketingGuestUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TicketingGuestInput>;
};

export type MutationTicketingOrgSettingCreateArgs = {
  input?: InputMaybe<TicketingOrgSettingInput>;
};

export type MutationTicketingOrgSettingUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TicketingOrgSettingInput>;
};

export type MutationTicketingReservationReportCreateArgs = {
  input?: InputMaybe<TicketingReservationReportCreateInput>;
};

export type MutationTmpDateCreateArgs = {
  input?: InputMaybe<TmpDateCreateInput>;
};

export type MutationTmpDateDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTmpDateUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TmpDateUpdateInput>;
};

export type MutationTmpSettingsUpdateArgs = {
  input?: InputMaybe<TmpTeamSettingsInput>;
};

export type MutationTmpTeamCreateArgs = {
  input?: InputMaybe<TmpTeamInput>;
};

export type MutationTmpTeamSegmentCreateArgs = {
  input?: InputMaybe<TmpTeamSegmentInput>;
};

export type MutationTmpTeamSegmentDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTmpTeamSegmentUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TmpTeamSegmentInput>;
};

export type MutationTmpYearRuleCreateArgs = {
  input?: InputMaybe<TmpYearRuleInput>;
};

export type MutationTmpYearRuleUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TmpYearRuleInput>;
};

export type MutationTmpYearUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TmpYearUpdateInput>;
};

export type MutationTravelAirSeatingChartCreateArgs = {
  input?: InputMaybe<TravelAirSeatingChartCreateInput>;
};

export type MutationTravelAirSeatingChartDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelAirSeatingChartUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelAirSeatingChartUpdateInput>;
};

export type MutationTravelContactCreateArgs = {
  input?: InputMaybe<TravelContactCreateInput>;
};

export type MutationTravelContactDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelContactUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelContactUpdateInput>;
};

export type MutationTravelCredentialAssignmentBulkUpdateArgs = {
  input?: InputMaybe<TravelCredentialAssignmentBulkInput>;
};

export type MutationTravelCredentialAssignmentCopyArgs = {
  input?: InputMaybe<TravelCredentialAssignmentCopyInput>;
};

export type MutationTravelCredentialCreateArgs = {
  input?: InputMaybe<TravelCredentialInput>;
};

export type MutationTravelCredentialDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelCredentialUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelCredentialInput>;
};

export type MutationTravelDocumentConfigUpsertArgs = {
  input?: InputMaybe<TravelDocumentConfigInput>;
};

export type MutationTravelDressCreateArgs = {
  input?: InputMaybe<TravelDressInput>;
};

export type MutationTravelDressDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelDressUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelDressInput>;
};

export type MutationTravelEventBulkDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelEventBulkDeleteInput>;
};

export type MutationTravelEventBulkUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelEventBulkUpdateInput>;
};

export type MutationTravelEventCopyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelEventCopyInput>;
};

export type MutationTravelEventCreateArgs = {
  input?: InputMaybe<TravelEventInput>;
};

export type MutationTravelEventDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelEventUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelEventInput>;
};

export type MutationTravelParticipationBulkUpsertArgs = {
  input?: InputMaybe<TravelParticipationBulkUpsertInput>;
};

export type MutationTravelParticipationReportCreateArgs = {
  input?: InputMaybe<TravelParticipationReportCreateInput>;
};

export type MutationTravelPregameItemCopyArgs = {
  input?: InputMaybe<TravelPregameItemCopyInput>;
};

export type MutationTravelPregameItemCreateArgs = {
  input?: InputMaybe<TravelPregameItemCreateInput>;
};

export type MutationTravelPregameItemDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelPregameItemUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelPregameItemUpdateInput>;
};

export type MutationTravelSeatplanCopyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelSeatplanCopyInput>;
};

export type MutationTravelSeatplanCopyOverrideArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelSeatplanCopyOverrideInput>;
};

export type MutationTravelSeatplanCreateArgs = {
  input?: InputMaybe<TravelSeatplanCreateInput>;
};

export type MutationTravelSeatplanDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelSeatplanUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelSeatplanUpdateInput>;
};

export type MutationTravelTaskBulkUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelTaskBulkUpdateInput>;
};

export type MutationTravelTaskCopyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelTaskCopyInput>;
};

export type MutationTravelTaskCreateArgs = {
  input?: InputMaybe<TravelTaskCreateInput>;
};

export type MutationTravelTaskDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelTaskUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelTaskUpdateInput>;
};

export type MutationTravelVendorCreateArgs = {
  input?: InputMaybe<TravelVendorCreateInput>;
};

export type MutationTravelVendorDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelVendorGroupCreateArgs = {
  input?: InputMaybe<TravelVendorGroupInput>;
};

export type MutationTravelVendorGroupDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTravelVendorGroupUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelVendorGroupInput>;
};

export type MutationTravelVendorUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelVendorUpdateInput>;
};

export type MutationTripBulkDeleteTravelPartyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TripBulkDeleteTravelPartyInput>;
};

export type MutationTripBulkUpdateTravelPartyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TripBulkUpdateTravelPartyInput>;
};

export type MutationTripCompetitionsBulkCreateArgs = {
  input?: InputMaybe<TripCompetitionsBulkCreateInput>;
};

export type MutationTripCreateArgs = {
  input?: InputMaybe<TripCreateInput>;
};

export type MutationTripDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationTripUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TripUpdateInput>;
};

export type MutationUpdateActivityIsViewedArgs = {
  activityId?: InputMaybe<Scalars['Int']>;
  isViewed?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateCompetitionPregameVisibilitiesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<CompetitionPregameVisibilityInput>;
};

export type MutationUpdateCompetitionSlideTimeIntervalArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<CompetitionSlideTimeIntervalInput>;
};

export type MutationUploadAttachmentArgs = {
  input?: InputMaybe<UploadAttachmentInput>;
};

export type MutationUploadProfilePictureArgs = {
  input?: InputMaybe<UploadProfilePictureInput>;
};

export type MutationUserAccountsMergeArgs = {
  input?: InputMaybe<UserAccountsMergeInput>;
};

export type MutationUserEmailUpdateArgs = {
  input?: InputMaybe<EmailInput>;
};

export type MutationUserFileVideoActionCreateArgs = {
  input?: InputMaybe<UserFileVideoActionInput>;
};

export type MutationUserPasswordUpdateArgs = {
  input?: InputMaybe<PasswordInput>;
};

export type MutationUserRegisterArgs = {
  input?: InputMaybe<UserRegisterInput>;
};

export type MutationUserUsernameUpdateArgs = {
  input?: InputMaybe<UsernameInput>;
};

export type MutationUsernameReminderSendArgs = {
  email: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type MutationVideoCreateArgs = {
  input?: InputMaybe<VideoInput>;
};

export type MutationVideoDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationVideoIncrementDownloadCountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationVideoIncrementPlayCountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationVideoUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<VideoInput>;
};

export type MutationWeblinkCreateArgs = {
  input?: InputMaybe<WebLinkInput>;
};

export type MutationWeblinkDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationWeblinkUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<WebLinkInput>;
};

export type MutationYearlyAttributeCategoryCreateArgs = {
  input?: InputMaybe<YearlyAttributeCategoryInput>;
};

export type MutationYearlyAttributeCategoryDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationYearlyAttributeCategoryReorderArgs = {
  input?: InputMaybe<YearlyAttributeCategoryReorderInput>;
};

export type MutationYearlyAttributeCategoryUpdateArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<YearlyAttributeCategoryInput>;
};

export type MutationYearlyAttributeReportCreateArgs = {
  input?: InputMaybe<YearlyAttributeReportCreateInput>;
};

export type MutationYearlyAttributeSettingCreateArgs = {
  input?: InputMaybe<YearlyAttributeSettingInput>;
};

export type MutationYearlyAttributeSettingDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationYearlyAttributeSettingReorderArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<YearlyAttributeSettingReorderInput>;
};

export type MutationYearlyAttributeSettingUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<YearlyAttributeSettingInput>;
};

export type MutationYearlyAttributeUserUpdateArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<AttributeInput>;
  tmpYear: Scalars['ID'];
};

export type MyFormAssignmentsConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MyFormAssignmentsEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MyFormAssignments` and its cursor. */
export type MyFormAssignmentsEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormAssignmentCompletionRoleNode>;
};

export type MyPerpetualFormConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MyPerpetualFormEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MyPerpetualForm` and its cursor. */
export type MyPerpetualFormEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormAssignmentRoleNode>;
};

/** Gives details of the calling user's reservations */
export type MyReservationNode = {
  appointmentId?: Maybe<Scalars['ID']>;
  appointmentOccurrenceId?: Maybe<Scalars['ID']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  slotId?: Maybe<Scalars['ID']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum MyTaskSortEnum {
  AssignerAsc = 'assigner_asc',
  AssignerDesc = 'assigner_desc',
  DueDateAsc = 'due_date_asc',
  DueDateDesc = 'due_date_desc',
  OwnerAppAsc = 'owner_app_asc',
  OwnerAppDesc = 'owner_app_desc',
  OwnerTypeAsc = 'owner_type_asc',
  OwnerTypeDesc = 'owner_type_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  TitleAsc = 'title_asc',
  TitleDesc = 'title_desc',
}

/** A GraphQL connection for tasks. */
export type MyTasksConnection = {
  /** List of all assigners who have active tasks for person */
  allAssigners?: Maybe<Array<Maybe<TaskAssignerType>>>;
  /** All unique owner-apps (product-lines) that have tasks for to this person */
  allOwnerApps?: Maybe<Array<Maybe<TaskOwnerApp>>>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MyTasksEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MyTasks` and its cursor. */
export type MyTasksEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TaskObjectType>;
};

export type MyTravelSeatplanObjectType = {
  flightAssignments?: Maybe<Array<Maybe<AssignmentObjectType>>>;
  groundAssignments?: Maybe<Array<Maybe<AssignmentObjectType>>>;
  hotelAssignments?: Maybe<Array<Maybe<AssignmentObjectType>>>;
};

export type NavigationTab = {
  /** The CF action associated with the tab. */
  action: Scalars['String'];
  /** The i18n translation key for the tab for the label */
  key: Scalars['String'];
  /** The organization-configured or default label of the tab. */
  label: Scalars['String'];
  /** The label to override the i18n translation key */
  overrideLabel?: Maybe<Scalars['String']>;
  /** The path that the tab routes to. */
  path: Scalars['String'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export enum NoteAlertEnum {
  Email = 'EMAIL',
  EmailAndText = 'EMAIL_AND_TEXT',
  Text = 'TEXT',
}

export type NoteCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  note?: Maybe<NoteNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type NoteDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  note?: Maybe<NoteNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type NoteInput = {
  alertType?: InputMaybe<NoteAlertEnum>;
  content: Scalars['String'];
  person?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  travelVendor?: InputMaybe<Scalars['ID']>;
  type: NoteTypeEnum;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** GraphQL node for a profile note */
export type NoteNode = Node & {
  author: PersonNode;
  canEdit?: Maybe<Scalars['Boolean']>;
  content: Scalars['String'];
  createdDate: Scalars['DateTime'];
  createdDateTime: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedDateTime?: Maybe<Scalars['DateTime']>;
  visibility?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
};

export enum NoteTypeEnum {
  Person = 'PERSON',
  Vendor = 'VENDOR',
}

export type NoteUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  note?: Maybe<NoteNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type NotificationSettingBulkSaveInput = {
  bulkInput?: InputMaybe<Array<InputMaybe<NotificationSettingField>>>;
};

export type NotificationSettingBulkSavePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  notificationSetting?: Maybe<NotificationSettingNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type NotificationSettingConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationSettingEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type NotificationSettingDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  notificationSetting?: Maybe<NotificationSettingNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `NotificationSetting` and its cursor. */
export type NotificationSettingEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationSettingNode>;
};

export type NotificationSettingField = {
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  label: Scalars['String'];
  notifSettingType: NotificationSettingTypesEnum;
  selectionCodesToSendTo: Array<InputMaybe<Scalars['String']>>;
  selectionCodesToWatch: Array<InputMaybe<Scalars['String']>>;
};

export type NotificationSettingNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  notifSettingType?: Maybe<NotificationSettingTypesEnum>;
  notificationSettingId?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  peopleToSendToCount?: Maybe<Scalars['Int']>;
  personStatusTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  selectionCodesToWatch: Array<Maybe<Scalars['String']>>;
  selectionsToSendTo?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  selectionsToWatch?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  selectionsToWatchCount?: Maybe<Scalars['Int']>;
  teams?: Maybe<Array<Maybe<Scalars['ID']>>>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum NotificationSettingSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EnabledAsc = 'enabled_asc',
  EnabledDesc = 'enabled_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  NotifSettingTypeAsc = 'notif_setting_type_asc',
  NotifSettingTypeDesc = 'notif_setting_type_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  RmNotificationSettingIdAsc = 'rm_notification_setting_id_asc',
  RmNotificationSettingIdDesc = 'rm_notification_setting_id_desc',
  SelectionCodesToWatchAsc = 'selection_codes_to_watch_asc',
  SelectionCodesToWatchDesc = 'selection_codes_to_watch_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** An enumeration. */
export enum NotificationSettingTypesEnum {
  AthleteStatus = 'athlete_status',
  UserType = 'user_type',
}

/** Whether and how to notify new assignees of the form that they've been assigned one. */
export enum NotifyAssignees {
  Both = 'both',
  Email = 'email',
  None = 'none',
  Sms = 'sms',
}

export type OpenSignupSlots = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  slots?: Maybe<Array<Maybe<SlotNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** GraphQL node for information about the org */
export type OrgNode = Node & {
  IsTactical?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  activeCoreCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  activeCoreCategoriesWithLabels?: Maybe<Array<Maybe<ActiveCoreCategoryNode>>>;
  activePersonStatusTypes?: Maybe<Scalars['String']>;
  activeTeams?: Maybe<Scalars['String']>;
  allTeamsTeam?: Maybe<TeamNode>;
  allTeamsTeamId?: Maybe<Scalars['Int']>;
  /** Returns the person_type_id of athletes for this school. */
  athletePersonTypeId?: Maybe<Scalars['String']>;
  athleteStatuses?: Maybe<Array<Maybe<PersonStatusTypeNode>>>;
  athleteUserType?: Maybe<PersonTypeNode>;
  branding?: Maybe<CustomerBrandingNode>;
  calsyncAccounts?: Maybe<CalsyncAccountNodeConnection>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  customerBranding?: Maybe<CustomerBrandingNode>;
  defaultAppointmentSelection?: Maybe<Scalars['String']>;
  disableMessageEditDelete?: Maybe<Scalars['Boolean']>;
  doShowTwBranding: Scalars['Boolean'];
  docusignAccountEmail?: Maybe<Scalars['String']>;
  enableGuardianshipFeatures?: Maybe<Scalars['Boolean']>;
  folders?: Maybe<FolderNodeConnection>;
  hasAcademics2?: Maybe<Scalars['String']>;
  hasCustomVoiceGreeting: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  identityFieldLabel?: Maybe<Scalars['String']>;
  identityProviderId?: Maybe<Scalars['Int']>;
  idpMatchAttributeType?: Maybe<Scalars['String']>;
  installedApps?: Maybe<Array<Application>>;
  institution?: Maybe<InstitutionNode>;
  institutionId?: Maybe<Scalars['Int']>;
  isAdminOrg: Scalars['Boolean'];
  isCollegiate: Scalars['Boolean'];
  /** Whether the org has Messaging 3 enabled */
  isMessaging3Org?: Maybe<Scalars['Boolean']>;
  isMobileValidationEnabled?: Maybe<Scalars['Boolean']>;
  isNonAthleteOrg: Scalars['Boolean'];
  isTactical?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  leagueId?: Maybe<Scalars['Int']>;
  logoImage?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  /** @deprecated Use OrgNode.logo_url instead */
  logoUrlFullSize?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  oktaGroupId?: Maybe<Scalars['String']>;
  orgId: Scalars['ID'];
  orgName: Scalars['String'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personRelationshipTypes?: Maybe<PersonRelationshipTypeNodeConnection>;
  /** @deprecated Use OrgNode.athlete_statuses instead. */
  personStatusTypes?: Maybe<Array<Maybe<PersonStatusTypeNode>>>;
  /** @deprecated Use OrgNode.user_types instead. */
  personTypes?: Maybe<Array<Maybe<PersonTypeNode>>>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  relationshipTypes?: Maybe<Array<Maybe<PersonRelationshipTypeNode>>>;
  sendSmsReminder?: Maybe<Scalars['Boolean']>;
  shortName: Scalars['String'];
  smsProviderId?: Maybe<Scalars['Int']>;
  teams?: Maybe<TeamNodeConnection>;
  timeZoneCode?: Maybe<Scalars['String']>;
  timeZoneId?: Maybe<Scalars['Int']>;
  tmpYears?: Maybe<TmpYearNodeConnection>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  userTypes?: Maybe<Array<Maybe<PersonTypeNode>>>;
  uses24HourTime?: Maybe<Scalars['Boolean']>;
  usesExpandedProfessorSurveyReports?: Maybe<Scalars['Boolean']>;
  /** Whether the org has SSO enabled. */
  usesExternalAuthentication: Scalars['Boolean'];
  usesSsoAsDefault: Scalars['Boolean'];
};

/** GraphQL node for information about the org */
export type OrgNodeCalsyncAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  calsyncAccountId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  engineName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  providerAccessToken?: InputMaybe<Scalars['TWFilter_String']>;
  providerData?: InputMaybe<Scalars['TWFilter_JSONString']>;
  providerId?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CalsyncAccountSortEnum>>>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** GraphQL node for information about the org */
export type OrgNodeFoldersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['TWFilter_ID']>;
  folderName?: InputMaybe<Scalars['TWFilter_String']>;
  folderType?: InputMaybe<Scalars['TWFilter_String']>;
  isEditableByUser?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentFolderId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<FolderSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  videoFolderId?: InputMaybe<Scalars['TWFilter_Int']>;
};

/** GraphQL node for information about the org */
export type OrgNodeLogoUrlArgs = {
  subset?: InputMaybe<Scalars['String']>;
};

/** GraphQL node for information about the org */
export type OrgNodePersonRelationshipTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isParent?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personRelationshipTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<PersonRelationshipTypeSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** GraphQL node for information about the org */
export type OrgNodeTeamsArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  branding?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  institution?: InputMaybe<Scalars['TWFilter_String']>;
  institutionId?: InputMaybe<Scalars['TWFilter_Int']>;
  isUserChangeAlertEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  logoImage?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  settings?: InputMaybe<Scalars['TWFilter_JSONString']>;
  shortLabel?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamSortEnum>>>;
  sportId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  userChangeAlertEmail?: InputMaybe<Scalars['TWFilter_String']>;
  voiceGreeting?: InputMaybe<Scalars['TWFilter_String']>;
};

/** GraphQL node for information about the org */
export type OrgNodeTmpYearsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chancellorPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  chancellorTitle?: InputMaybe<Scalars['TWFilter_String']>;
  complianceDirectorPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  complianceDirectorTitle?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  directorAthleticsPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  directorAthleticsTitle?: InputMaybe<Scalars['TWFilter_String']>;
  facultyAthleticPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  facultyAthleticTitle?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isCurrent?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpYearSortEnum>>>;
  termCount?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type OrgNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrgNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrgNode` and its cursor. */
export type OrgNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<OrgNode>;
};

/** An enumeration. */
export enum OrgSortEnum {
  ActiveAsc = 'active_asc',
  ActiveDesc = 'active_desc',
  AllTeamsTeamIdAsc = 'all_teams_team_id_asc',
  AllTeamsTeamIdDesc = 'all_teams_team_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DefaultAppointmentSelectionAsc = 'default_appointment_selection_asc',
  DefaultAppointmentSelectionDesc = 'default_appointment_selection_desc',
  DisableMessageEditDeleteAsc = 'disable_message_edit_delete_asc',
  DisableMessageEditDeleteDesc = 'disable_message_edit_delete_desc',
  DoShowTwBrandingAsc = 'do_show_tw_branding_asc',
  DoShowTwBrandingDesc = 'do_show_tw_branding_desc',
  DocusignAccountEmailAsc = 'docusign_account_email_asc',
  DocusignAccountEmailDesc = 'docusign_account_email_desc',
  EnableGuardianshipFeaturesAsc = 'enable_guardianship_features_asc',
  EnableGuardianshipFeaturesDesc = 'enable_guardianship_features_desc',
  HasCustomVoiceGreetingAsc = 'has_custom_voice_greeting_asc',
  HasCustomVoiceGreetingDesc = 'has_custom_voice_greeting_desc',
  IdentityFieldLabelAsc = 'identity_field_label_asc',
  IdentityFieldLabelDesc = 'identity_field_label_desc',
  IdentityProviderIdAsc = 'identity_provider_id_asc',
  IdentityProviderIdDesc = 'identity_provider_id_desc',
  InstitutionIdAsc = 'institution_id_asc',
  InstitutionIdDesc = 'institution_id_desc',
  IsAdminOrgAsc = 'is_admin_org_asc',
  IsAdminOrgDesc = 'is_admin_org_desc',
  IsCollegiateAsc = 'is_collegiate_asc',
  IsCollegiateDesc = 'is_collegiate_desc',
  IsMobileValidationEnabledAsc = 'is_mobile_validation_enabled_asc',
  IsMobileValidationEnabledDesc = 'is_mobile_validation_enabled_desc',
  IsNonAthleteOrgAsc = 'is_non_athlete_org_asc',
  IsNonAthleteOrgDesc = 'is_non_athlete_org_desc',
  IsTacticalAsc = 'is_tactical_asc',
  IsTacticalDesc = 'is_tactical_desc',
  LeagueIdAsc = 'league_id_asc',
  LeagueIdDesc = 'league_id_desc',
  LogoImageAsc = 'logo_image_asc',
  LogoImageDesc = 'logo_image_desc',
  MessagingServiceSidAsc = 'messaging_service_sid_asc',
  MessagingServiceSidDesc = 'messaging_service_sid_desc',
  OktaGroupIdAsc = 'okta_group_id_asc',
  OktaGroupIdDesc = 'okta_group_id_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SchoolNameAsc = 'school_name_asc',
  SchoolNameDesc = 'school_name_desc',
  SendSmsReminderAsc = 'send_sms_reminder_asc',
  SendSmsReminderDesc = 'send_sms_reminder_desc',
  ShortNameAsc = 'short_name_asc',
  ShortNameDesc = 'short_name_desc',
  SmsProviderIdAsc = 'sms_provider_id_asc',
  SmsProviderIdDesc = 'sms_provider_id_desc',
  TimeZoneIdAsc = 'time_zone_id_asc',
  TimeZoneIdDesc = 'time_zone_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  Uses_24HourTimeAsc = 'uses_24_hour_time_asc',
  Uses_24HourTimeDesc = 'uses_24_hour_time_desc',
  UsesExpandedProfessorSurveyReportsAsc = 'uses_expanded_professor_survey_reports_asc',
  UsesExpandedProfessorSurveyReportsDesc = 'uses_expanded_professor_survey_reports_desc',
  UsesSsoAsDefaultAsc = 'uses_sso_as_default_asc',
  UsesSsoAsDefaultDesc = 'uses_sso_as_default_desc',
}

/** All sport positions for an org grouped by team. */
export type OrgSportPositionNode = {
  /** The sport position. */
  sportPosition?: Maybe<SportPosition>;
  /** The team which the sport position is for. */
  team?: Maybe<TeamNode>;
};

export enum OrientationEnum {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

/**
 * Represents an overlay event application metadata node.
 *
 * Attributes:
 *     code (str): The code of the application.
 *     name (str): The name of the application.
 *     logo (str): The logo of the application.
 */
export type OverlayEventApplicationMetadataNode = {
  code: Scalars['String'];
  logo: Scalars['String'];
  name: Scalars['String'];
};

/**
 * Represents an overlay event attendee node. This node does not provide Selectables for attendees with profiles,
 * but instead provides a list of their selection code strings.
 *
 * Attributes:
 *     hub_selection_codes (List[str]): The selection codes associated with hub attendees.
 *     non_hub_attendees (List[str]): The non-hub attendees.
 *     people_count (int): The count of people associated with the event.
 */
export type OverlayEventAttendeeNode = {
  hubSelectionCodes: Array<Maybe<Scalars['String']>>;
  nonHubAttendees?: Maybe<Array<Maybe<Scalars['String']>>>;
  peopleCount: Scalars['Int'];
};

export type OverlayEventDataNode = {
  applications: Array<Maybe<OverlayEventApplicationMetadataNode>>;
  events: Array<Maybe<OverlayEventNode>>;
  requestId: Scalars['String'];
};

/**
 * Represents an overlay event deep link node.
 *
 * Attributes:
 *     url (str): The URL of the deep link.
 */
export type OverlayEventDeepLinkNode = {
  url: Scalars['String'];
};

/**
 * Node representing a non HUB event on another application's calendar.
 *
 * Attributes:
 *     overlay_event_id (str): The ID of the overlay event.
 *     owning_app (str): The owning application of the event.
 *     title (str): The title or name of the event.
 *     location (str, optional): The location of the event.
 *     notes (str, optional): Additional notes or details about the event.
 *     appointment_type (OverlayEventTypeNode): The type of the appointment.
 *     is_private (bool, optional): Indicates if the event is private.
 *     all_day (bool, optional): Indicates if the event is an all-day event.
 *     timezone (str): The timezone of the event.
 *     start_datetime (datetime): The start date and time of the event.
 *     end_datetime (datetime): The end date and time of the event.
 *     attendees (OverlayEventAttendeeNode): A Node encapsulating a list of hub attendees with profile_id selection codes,
 *                                       a list of non_hub_attendee strings, and the total people count (hub_attendees+non_hub+attendees)
 *                                       - hub_selection_codes (List[str]): The selection codes associated with hub attendees.
 *                                       - non_hub_attendees (List[str]): The non-hub attendees.
 *                                       - people_count (int): The count of people associated with the event.
 *     non_hub_attendees (List[str]): The non-hub attendees of the event.
 *                                    This is resolved in the attendees OverlayEventAttendeeNode, and is only used as input.
 *     deep_link (OverlayEventDeepLinkNode, optional): The deep link associated with the event.
 *     user_metadata (OverlayEventUserMetadataNode, optional): The user metadata of the event.
 *     creator (str, optional): The creator of the event.
 *     people_count (int): The count of people associated with the event.
 *                         This is resolved in the attendees OverlayEventAttendeeNode, and is only used as input.
 *     is_class (bool, optional): Indicates if the event is a class event.
 *     selections (OverlaySelectionNode): The selections associated with the event.
 */
export type OverlayEventNode = {
  allDay?: Maybe<Scalars['Boolean']>;
  appointmentType: OverlayEventTypeNode;
  attendees: OverlayEventAttendeeNode;
  creator?: Maybe<Scalars['String']>;
  deepLink?: Maybe<OverlayEventDeepLinkNode>;
  endDatetime: Scalars['DateTime'];
  isClass?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  nonHubAttendees: Array<Maybe<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  overlayEventId: Scalars['String'];
  owningApp: Scalars['String'];
  peopleCount: Scalars['Int'];
  selections?: Maybe<OverlaySelectionNode>;
  startDatetime: Scalars['DateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  userMetadata?: Maybe<OverlayEventUserMetadataNode>;
};

/**
 * Represents an overlay event attendee node. This node provides Selectables for attendees with profiles.
 *
 * Attributes:
 *     hub_attendees (List[Selectable]): The Selectable objects for hub attendees with profile_ids.
 *     non_hub_attendees (List[str]): The non-hub attendees.
 *     people_count (int): The count of people associated with the event.
 */
export type OverlayEventSelectableAttendeesNode = {
  hubAttendees: Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>;
  nonHubAttendees?: Maybe<Array<Maybe<Scalars['String']>>>;
  peopleCount: Scalars['Int'];
};

/**
 * Represents an overlay event type node.
 *
 * Attributes:
 *     id (str): The ID of the event type.
 *     name (str): The name of the event type.
 *     color (str): The color associated with the event type.
 */
export type OverlayEventTypeNode = {
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

/**
 * Represents an overlay event user metadata node.
 *
 * Attributes:
 *     can_view_details (bool): Indicates if the user can view event details.
 */
export type OverlayEventUserMetadataNode = {
  canViewDetails: Scalars['Boolean'];
};

/**
 * Represents an overlay event node with additional selectable properties.
 * Attributes:
 *     request_id (str): The request ID associated with the event.
 *     attendees (OverlayEventSelectableAttendeesNode): A Node encapsulating a selectable list of hub attendees,
 *                                                      a list of non_hub_attendee strings, and the total people count (hub_attendees+non_hub+attendees)
 *                                                      - hub_attendees (List[Selectable]): The Selectable objects for hub attendees with profile_ids.
 *                                                      - non_hub_attendees (List[str]): The non-hub attendees.
 *                                                      - people_count (int): The count of people associated with the event.
 *     application_metadata (OverlayEventApplicationMetadataNode): The application metadata of the event.
 */
export type OverlayEventWithSelectablesNode = {
  allDay?: Maybe<Scalars['Boolean']>;
  applicationMetadata: OverlayEventApplicationMetadataNode;
  appointmentType: OverlayEventTypeNode;
  attendees: OverlayEventSelectableAttendeesNode;
  creator?: Maybe<Scalars['String']>;
  deepLink?: Maybe<OverlayEventDeepLinkNode>;
  endDatetime: Scalars['DateTime'];
  isClass?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  nonHubAttendees: Array<Maybe<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  overlayEventId: Scalars['String'];
  owningApp: Scalars['String'];
  peopleCount: Scalars['Int'];
  requestId: Scalars['String'];
  selections?: Maybe<OverlaySelectionNode>;
  startDatetime: Scalars['DateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  userMetadata?: Maybe<OverlayEventUserMetadataNode>;
};

/**
 * Node that provides a list of Selectable PersonNodes given a list of selection codes.
 *
 * Attributes:
 *     persons (List[PersonNode]): The list of persons associated with the selection.
 *     selection_codes (List[str]): The selection codes associated with the selection.
 */
export type OverlaySelectionNode = {
  persons?: Maybe<Array<Maybe<PersonNode>>>;
  selectionCodes: Array<Maybe<Scalars['String']>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type ParentNoteType = {
  checkinNote?: Maybe<IndividualNoteType>;
  checkoutNote?: Maybe<IndividualNoteType>;
  editNote?: Maybe<Array<Maybe<IndividualNoteType>>>;
};

export type PasswordInput = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type PeopleConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PeopleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `People` and its cursor. */
export type PeopleEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PersonNode>;
};

/** The Permission connection. */
export type PermissionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PermissionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export enum PermissionContextEnum {
  Current = 'current',
  None = 'none',
  Usertype = 'usertype',
}

/** A Relay edge containing a `Permission` and its cursor. */
export type PermissionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PermissionNode>;
};

export type PermissionInput = {
  id?: InputMaybe<Scalars['ID']>;
  restrictions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** The Permission node. */
export type PermissionNode = Node & {
  Dependencies?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  codeName: Scalars['String'];
  dependencies?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description: Scalars['String'];
  displayName: Scalars['String'];
  grantPermission?: Maybe<PermissionNode>;
  grantPermissionId?: Maybe<Scalars['Int']>;
  grantedBy?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** If this is true, permission restrictions refer to org */
  hasOrgRestriction: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** <graphene.types.argument.Argument object at 0x29ffb8100> */
  inheritedPermissions?: Maybe<PermissionNodeConnection>;
  module?: Maybe<ModuleNode>;
  moduleId?: Maybe<Scalars['Int']>;
  permissionId: Scalars['ID'];
  /** If this is true, permission restrictions refer to persontype */
  personTypeFilter: Scalars['Boolean'];
  sortOrder: Scalars['Int'];
  tooltip?: Maybe<Scalars['String']>;
};

/** The Permission node. */
export type PermissionNodeInheritedPermissionsArgs = {
  Dependencies?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['TWFilter_String']>;
  codeName?: InputMaybe<Scalars['TWFilter_String']>;
  dependencies?: InputMaybe<Scalars['TWFilter_String']>;
  displayName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  grantPermissionId?: InputMaybe<Scalars['TWFilter_Int']>;
  hasOrgRestriction?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  moduleId?: InputMaybe<Scalars['TWFilter_Int']>;
  permissionId?: InputMaybe<Scalars['TWFilter_ID']>;
  personTypeFilter?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<PermissionSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type PermissionNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PermissionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PermissionNode` and its cursor. */
export type PermissionNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PermissionNode>;
};

/** An enumeration. */
export enum PermissionSortEnum {
  CategoryAsc = 'category_asc',
  CategoryDesc = 'category_desc',
  CodeNameAsc = 'code_name_asc',
  CodeNameDesc = 'code_name_desc',
  DependenciesAsc = 'dependencies_asc',
  DependenciesDesc = 'dependencies_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  DisplayNameAsc = 'display_name_asc',
  DisplayNameDesc = 'display_name_desc',
  GrantPermissionIdAsc = 'grant_permission_id_asc',
  GrantPermissionIdDesc = 'grant_permission_id_desc',
  HasOrgRestrictionAsc = 'has_org_restriction_asc',
  HasOrgRestrictionDesc = 'has_org_restriction_desc',
  ModuleIdAsc = 'module_id_asc',
  ModuleIdDesc = 'module_id_desc',
  PermissionIdAsc = 'permission_id_asc',
  PermissionIdDesc = 'permission_id_desc',
  PersontypeFilterAsc = 'persontype_filter_asc',
  PersontypeFilterDesc = 'persontype_filter_desc',
  SortOrderAsc = 'sort_order_asc',
  SortOrderDesc = 'sort_order_desc',
}

export type PersonAccountPreferencesUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonAddAddressPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonAddTeamMembershipPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** GraphQL node for an address associated with a person */
export type PersonAddressNode = Node & {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryNode>;
  countryIso?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  customStateProvince?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isMailing?: Maybe<Scalars['String']>;
  isMailingYesNo?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personAddressId: Scalars['ID'];
  personId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  typeLabel?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type PersonAddressNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PersonAddressNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PersonAddressNode` and its cursor. */
export type PersonAddressNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PersonAddressNode>;
};

/** An enumeration. */
export enum PersonAddressSortEnum {
  CityAsc = 'city_asc',
  CityDesc = 'city_desc',
  CountryIsoAsc = 'country_iso_asc',
  CountryIsoDesc = 'country_iso_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomStateProvinceAsc = 'custom_state_province_asc',
  CustomStateProvinceDesc = 'custom_state_province_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  IsmailingAsc = 'ismailing_asc',
  IsmailingDesc = 'ismailing_desc',
  PersonAddressIdAsc = 'person_address_id_asc',
  PersonAddressIdDesc = 'person_address_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  StateAsc = 'state_asc',
  StateDesc = 'state_desc',
  Street2Asc = 'street2_asc',
  Street2Desc = 'street2_desc',
  StreetAsc = 'street_asc',
  StreetDesc = 'street_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  ZipAsc = 'zip_asc',
  ZipDesc = 'zip_desc',
}

/** Input model for a number of Attribute mutations */
export type PersonAttributeInput = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  driversLicenseExpirationDate?: InputMaybe<Scalars['String']>;
  driversLicenseNumber?: InputMaybe<Scalars['String']>;
  driversLicenseState?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  emailAddressPersonal?: InputMaybe<Scalars['String']>;
  facebookAccount?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phoneCell?: InputMaybe<Scalars['String']>;
  phoneFax?: InputMaybe<Scalars['String']>;
  phoneHome?: InputMaybe<Scalars['String']>;
  phoneOffice?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  schoolIdentifier?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  travelWeight?: InputMaybe<Scalars['Int']>;
  twitterAccount?: InputMaybe<Scalars['String']>;
};

export type PersonBatchUpdateTeamMembershipsPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonBulkDeactivatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<Array<Maybe<PersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonBulkEditTeamMembersInput = {
  addTeams: Array<InputMaybe<Scalars['ID']>>;
  permissionContext: PermissionContextEnum;
  removeTeams: Array<InputMaybe<Scalars['ID']>>;
  teamMembers: Array<InputMaybe<Scalars['ID']>>;
};

export type PersonBulkEditTeamMembershipsPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<Array<Maybe<PersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonBulkGrantAccessPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<Array<Maybe<PersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonBulkReactivatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<Array<Maybe<PersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonBulkRemoveTeamMembersInput = {
  teamMembers: Array<InputMaybe<Scalars['ID']>>;
};

export type PersonBulkRemoveTeamMembershipsPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<Array<Maybe<PersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonBulkUpdateLoginInput = {
  hasLoginAccess?: InputMaybe<Scalars['Boolean']>;
  /** List of all modified persons */
  personIds: Array<InputMaybe<Scalars['ID']>>;
  usesExternalAuthentication?: InputMaybe<Scalars['Boolean']>;
};

export type PersonBulkUpdateLoginPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Input model for ContactCreate mutation */
export type PersonContactInput = {
  address?: InputMaybe<AddressInput>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  isGuardian?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  maritalStatusId?: InputMaybe<Scalars['Int']>;
  middleName?: InputMaybe<Scalars['String']>;
  personSelectionCode?: InputMaybe<Scalars['String']>;
  phoneCell?: InputMaybe<Scalars['String']>;
  phoneHome?: InputMaybe<Scalars['String']>;
  phoneOffice?: InputMaybe<Scalars['String']>;
  relationship: Scalars['ID'];
  travelWeight?: InputMaybe<Scalars['Int']>;
};

/** Input model for ContactUpdate mutation */
export type PersonContactUpdateInput = {
  address?: InputMaybe<AddressInput>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  isGuardian?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  maritalStatusId?: InputMaybe<Scalars['Int']>;
  middleName?: InputMaybe<Scalars['String']>;
  phoneCell?: InputMaybe<Scalars['String']>;
  phoneHome?: InputMaybe<Scalars['String']>;
  phoneOffice?: InputMaybe<Scalars['String']>;
  relationship: Scalars['ID'];
  travelWeight?: InputMaybe<Scalars['Int']>;
};

export type PersonCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonDeactivatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonGrantAllTeamsAccessPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonInfo = {
  /** List of apps currently available to this person */
  appAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** First name of the person */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of the person */
  lastName?: Maybe<Scalars['String']>;
  /** List of apps the current person has mobile access to */
  mobileAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** List of apps the current person has web access to */
  webAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Input model for a number of Person mutations */
export type PersonInput = {
  athleteDetails?: InputMaybe<AthleteInput>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  hasLoginAccess?: InputMaybe<Scalars['Boolean']>;
  invitationMedium?: InputMaybe<InvitationMedium>;
  lastName?: InputMaybe<Scalars['String']>;
  loginAccess?: InputMaybe<LoginAccess>;
  middleName?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  profileImageFile?: InputMaybe<Scalars['Upload']>;
  schoolIdentifier?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tutorDetails?: InputMaybe<TutorDetails>;
  userType?: InputMaybe<Scalars['ID']>;
  usesExternalAuthentication?: InputMaybe<Scalars['Boolean']>;
};

export type PersonModifyAllTeamsAccessPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A selectable person. */
export type PersonNode = Node &
  Recipient &
  Selectable & {
    active: Scalars['Boolean'];
    activeMemberships?: Maybe<Array<Maybe<TeamMemberNode>>>;
    /** The registered addresses for the person. */
    addresses?: Maybe<Array<Maybe<PersonAddressNode>>>;
    assignedApps?: Maybe<Array<Maybe<Application>>>;
    /** The list of attributes grouped by category for the person. */
    attributeCategories?: Maybe<Array<Maybe<AttributeCategoryNode>>>;
    /** Persons Automobile */
    automobile?: Maybe<AutomobileNode>;
    automobileId?: Maybe<Scalars['Int']>;
    calendarAlertDefault?: Maybe<Scalars['String']>;
    calsyncAccount?: Maybe<CalsyncAccountNodeConnection>;
    canModifyNotes: Scalars['Boolean'];
    canModifyProfileAddresses: Scalars['Boolean'];
    canModifyProfileCustomContacts: Scalars['Boolean'];
    canModifyProfileFiles: Scalars['Boolean'];
    canModifyProfileRoommates: Scalars['Boolean'];
    canModifyProfileTwContacts: Scalars['Boolean'];
    canViewAcademicDetails: Scalars['Boolean'];
    canViewAddressesDetails: Scalars['Boolean'];
    canViewAllPersonalDetails: Scalars['Boolean'];
    canViewAthleteDetails: Scalars['Boolean'];
    canViewContactDetails: Scalars['Boolean'];
    canViewPersonalDetails: Scalars['Boolean'];
    canViewProfile: Scalars['Boolean'];
    canViewProfileCalendar: Scalars['Boolean'];
    canViewProfileFiles: Scalars['Boolean'];
    canViewProfileForms: Scalars['Boolean'];
    canViewProfileNotes: Scalars['Boolean'];
    canViewYearlyAttributes: Scalars['Boolean'];
    cfAccessToken?: Maybe<Scalars['String']>;
    /** The persons associated with this contact (if the person is a contact). */
    contactAssociations?: Maybe<Array<Maybe<PersonNode>>>;
    /** The list of contacts associated with the person. */
    contacts?: Maybe<Array<Maybe<ProfileContact>>>;
    createdBy?: Maybe<Scalars['Int']>;
    createdDate?: Maybe<Scalars['DateTime']>;
    customAttributeValues?: Maybe<Scalars['JSONString']>;
    /** The person's date of birth in YYYY-MM-DD format */
    dateOfBirth?: Maybe<Scalars['String']>;
    directReportPerson?: Maybe<PersonNode>;
    directReportPersonId?: Maybe<Scalars['Int']>;
    /** Name as it should appear to the end user on the front end. */
    displayName?: Maybe<Scalars['String']>;
    driversLicenseExpirationDate?: Maybe<Scalars['DateTime']>;
    driversLicenseNumber?: Maybe<Scalars['String']>;
    driversLicenseState?: Maybe<Scalars['String']>;
    driversLicenseStateId?: Maybe<Scalars['Int']>;
    emailAddress?: Maybe<Scalars['String']>;
    emailAddressPersonal?: Maybe<Scalars['String']>;
    facebookAccount?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    /** Returns full name */
    fullName?: Maybe<Scalars['String']>;
    /** Returns concatenated full name (last name, first[0]) */
    fullNameFirstInitial?: Maybe<Scalars['String']>;
    /** Returns concatenated full name (last name, preferred[0]) */
    fullNameFirstInitialPreferred?: Maybe<Scalars['String']>;
    /** Returns concatenated full name (first name "nick" last) */
    fullNameNormalOrder?: Maybe<Scalars['String']>;
    /** Returns concatenated full name (last name, first "nick" middle) */
    fullNameWithMiddle?: Maybe<Scalars['String']>;
    /** Returns concatenated full name (first name "nick" middle last) */
    fullNameWithMiddleNormalOrder?: Maybe<Scalars['String']>;
    /** Returns concatenated full name (last name, preferred name or first name), with spaces around each trimmed */
    fullPreferredName?: Maybe<Scalars['String']>;
    gender?: Maybe<Scalars['String']>;
    /** The type of group */
    groupType?: Maybe<GroupType>;
    /** Whether this person has a linked SSO account */
    hasLinkedSsoAccount?: Maybe<Scalars['Boolean']>;
    /** This person can login */
    hasLoginAccess?: Maybe<Scalars['Boolean']>;
    hasMobileBeenValidated?: Maybe<Scalars['Boolean']>;
    /** Whether this person has a mobile token. */
    hasMobileToken?: Maybe<Scalars['Boolean']>;
    /** Whether this person has linked user account */
    hasUserAccount?: Maybe<Scalars['Boolean']>;
    /** The ID of the object. */
    id: Scalars['ID'];
    inactiveMemberships?: Maybe<TeamMemberNodeConnection>;
    /** The first and last initials of the person or null if no name is provided. */
    initials?: Maybe<Scalars['String']>;
    internationalTravelDocument?: Maybe<InternationalTravelDocumentNode>;
    /** A URL to an expiring page for registering a user or resetting a password. */
    inviteUrl?: Maybe<Scalars['String']>;
    isContact: Scalars['Boolean'];
    /** Whether this person is all teams */
    isOrgLevelUser?: Maybe<Scalars['Boolean']>;
    label?: Maybe<Scalars['String']>;
    /** Returns concatenated last and first name (last name, first) */
    lastAndFirstName?: Maybe<Scalars['String']>;
    /** The last date that the user logged in on this account */
    lastLoginDate?: Maybe<Scalars['DateTime']>;
    lastName?: Maybe<Scalars['String']>;
    lastSelectedTeamId?: Maybe<Scalars['String']>;
    /** The number of logins this person has made */
    loginCount?: Maybe<Scalars['Int']>;
    maritalStatusId?: Maybe<Scalars['Int']>;
    middleName?: Maybe<Scalars['String']>;
    /** A formatted mobile phone number */
    mobilePhone?: Maybe<Scalars['String']>;
    /**
     * Name formatted as <preferred name> <last name>.
     * @deprecated Use PersonNode.displayName instead
     */
    name?: Maybe<Scalars['String']>;
    nickname?: Maybe<Scalars['String']>;
    /** Returns either a nickname with quotes around it, or ""  */
    nicknameFormatted?: Maybe<Scalars['String']>;
    /** The profile notes associated with the person. */
    notes?: Maybe<Array<Maybe<NoteNode>>>;
    org?: Maybe<OrgNode>;
    orgId?: Maybe<Scalars['Int']>;
    peopleCount?: Maybe<Scalars['Int']>;
    /** <graphene.types.argument.Argument object at 0x2a01118e0> */
    personAddresses?: Maybe<PersonAddressNodeConnection>;
    personId: Scalars['ID'];
    /** @deprecated Use PersonNode.mobilePhone instead */
    phoneCell?: Maybe<Scalars['String']>;
    phoneFax?: Maybe<Scalars['String']>;
    phoneHome?: Maybe<Scalars['String']>;
    phoneOffice?: Maybe<Scalars['String']>;
    phoneTeamworks?: Maybe<Scalars['String']>;
    /** @deprecated Use PersonNode.pictureUrl instead */
    picture?: Maybe<Scalars['String']>;
    /** @deprecated Use PersonNode.pictureUrl instead */
    pictureDownloadUrl?: Maybe<Scalars['String']>;
    /** @deprecated Use PersonNode.pictureUrl instead */
    pictureImage?: Maybe<Scalars['String']>;
    /** An avatar URL for the person or null if not provided. */
    pictureUrl?: Maybe<Scalars['String']>;
    pk?: Maybe<Scalars['String']>;
    /** The label including the team name. Applies only to group selections. */
    pluralLabel?: Maybe<Scalars['String']>;
    preferredName?: Maybe<Scalars['String']>;
    /** A persons roommate information. */
    roommates?: Maybe<Array<Maybe<ProfileRoommateType>>>;
    schoolIdentifier?: Maybe<Scalars['String']>;
    /** The unique identifier used to select members of the selectable. */
    selectionCode?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    streamUserId?: Maybe<Scalars['String']>;
    /** A persons related ac2 student profile. */
    student?: Maybe<StudentNode>;
    /** Name suffix of the person */
    suffix?: Maybe<Scalars['String']>;
    team?: Maybe<TeamNode>;
    /** Labels of teams the user is an active member of */
    teamLabels?: Maybe<Scalars['String']>;
    teamMembers?: Maybe<TeamMemberNodeConnection>;
    /** A specific team membership of the person. */
    teamMembership?: Maybe<TeamMemberNode>;
    /** The teams the person is a member of. */
    teams?: Maybe<Array<Maybe<TeamNode>>>;
    timeZone?: Maybe<Scalars['String']>;
    /** Title of the person */
    title?: Maybe<Scalars['String']>;
    travelDocument?: Maybe<InternationalTravelDocumentNode>;
    travelWeight?: Maybe<Scalars['Int']>;
    twitterAccount?: Maybe<Scalars['String']>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedDate?: Maybe<Scalars['DateTime']>;
    userTypesByTeam?: Maybe<Scalars['JSONString']>;
    username?: Maybe<Scalars['String']>;
    usesExternalAuthentication: Scalars['Boolean'];
    yearlyAttributeValues?: Maybe<Scalars['JSONString']>;
  };

/** A selectable person. */
export type PersonNodeAttributeCategoriesArgs = {
  includeAddresses?: InputMaybe<Scalars['Boolean']>;
};

/** A selectable person. */
export type PersonNodeCalsyncAccountArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  calsyncAccountId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  engineName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  providerAccessToken?: InputMaybe<Scalars['TWFilter_String']>;
  providerData?: InputMaybe<Scalars['TWFilter_JSONString']>;
  providerId?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CalsyncAccountSortEnum>>>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** A selectable person. */
export type PersonNodeInactiveMembershipsArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAcademicsUser?: InputMaybe<Scalars['TWFilter_String']>;
  isOrgSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  isTeamSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamMemberSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamMemberId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** A selectable person. */
export type PersonNodePersonAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['TWFilter_String']>;
  countryIso?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customStateProvince?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isMailing?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personAddressId?: InputMaybe<Scalars['TWFilter_ID']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<PersonAddressSortEnum>>>;
  state?: InputMaybe<Scalars['TWFilter_String']>;
  street?: InputMaybe<Scalars['TWFilter_String']>;
  street2?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  zip?: InputMaybe<Scalars['TWFilter_String']>;
};

/** A selectable person. */
export type PersonNodeTeamMembersArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAcademicsUser?: InputMaybe<Scalars['TWFilter_String']>;
  isOrgSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  isTeamSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamMemberSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamMemberId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** A selectable person. */
export type PersonNodeYearlyAttributeValuesArgs = {
  tmpYearId?: InputMaybe<Scalars['ID']>;
};

export type PersonNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PersonNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PersonNode` and its cursor. */
export type PersonNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PersonNode>;
};

export type PersonPictureUploadInput = {
  fileUuid: Scalars['String'];
  hasExpiration?: InputMaybe<Scalars['Boolean']>;
  persons: Array<InputMaybe<Scalars['ID']>>;
};

export type PersonPreferencesInput = {
  alertType?: InputMaybe<CalendarAlertType>;
  timeZone?: InputMaybe<Scalars['String']>;
  /** The user's preference for 12 or 24 hour time. */
  uses24HourTime?: InputMaybe<Uses24HourTime>;
};

export type PersonReactivatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonRelationshipTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PersonRelationshipTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PersonRelationshipTypeCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  personRelationshipType?: Maybe<PersonRelationshipTypeNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonRelationshipTypeDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  personRelationshipType?: Maybe<PersonRelationshipTypeNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `PersonRelationshipType` and its cursor. */
export type PersonRelationshipTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PersonRelationshipTypeNode>;
};

export type PersonRelationshipTypeInput = {
  label: Scalars['String'];
};

export type PersonRelationshipTypeNode = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  /** label */
  label: Scalars['String'];
  usedCount?: Maybe<Scalars['Int']>;
};

export type PersonRelationshipTypeNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PersonRelationshipTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PersonRelationshipTypeNode` and its cursor. */
export type PersonRelationshipTypeNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PersonRelationshipTypeNode>;
};

/** An enumeration. */
export enum PersonRelationshipTypeSortEnum {
  CodeAsc = 'code_asc',
  CodeDesc = 'code_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsParentAsc = 'is_parent_asc',
  IsParentDesc = 'is_parent_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonRelationshipTypeIdAsc = 'person_relationship_type_id_asc',
  PersonRelationshipTypeIdDesc = 'person_relationship_type_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type PersonRelationshipTypeUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  personRelationshipType?: Maybe<PersonRelationshipTypeNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonRemoveAddressPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonRemoveTeamMembershipPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonResetPasswordPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  passwordResetLink?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendSms?: Maybe<Scalars['Boolean']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonRevokeAllTeamsAccessPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonSendInvitationInput = {
  invitationMedium?: InputMaybe<InvitationMedium>;
};

export type PersonSendInvitationPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum PersonSortEnum {
  ActiveAsc = 'active_asc',
  ActiveDesc = 'active_desc',
  AutomobileIdAsc = 'automobile_id_asc',
  AutomobileIdDesc = 'automobile_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DateOfBirthAsc = 'date_of_birth_asc',
  DateOfBirthDesc = 'date_of_birth_desc',
  DirectReportPersonIdAsc = 'direct_report_person_id_asc',
  DirectReportPersonIdDesc = 'direct_report_person_id_desc',
  DriversLicenseExpirationDateAsc = 'drivers_license_expiration_date_asc',
  DriversLicenseExpirationDateDesc = 'drivers_license_expiration_date_desc',
  DriversLicenseNumberAsc = 'drivers_license_number_asc',
  DriversLicenseNumberDesc = 'drivers_license_number_desc',
  DriversLicenseStateAsc = 'drivers_license_state_asc',
  DriversLicenseStateDesc = 'drivers_license_state_desc',
  DriversLicenseStateIdAsc = 'drivers_license_state_id_asc',
  DriversLicenseStateIdDesc = 'drivers_license_state_id_desc',
  EmailAddressAsc = 'email_address_asc',
  EmailAddressDesc = 'email_address_desc',
  EmailAddressPersonalAsc = 'email_address_personal_asc',
  EmailAddressPersonalDesc = 'email_address_personal_desc',
  FacebookAccountAsc = 'facebook_account_asc',
  FacebookAccountDesc = 'facebook_account_desc',
  FirstNameAsc = 'first_name_asc',
  FirstNameDesc = 'first_name_desc',
  GenderAsc = 'gender_asc',
  GenderDesc = 'gender_desc',
  HasLoginAccessAsc = 'has_login_access_asc',
  HasLoginAccessDesc = 'has_login_access_desc',
  HasMobileBeenValidatedAsc = 'has_mobile_been_validated_asc',
  HasMobileBeenValidatedDesc = 'has_mobile_been_validated_desc',
  IsContactAsc = 'is_contact_asc',
  IsContactDesc = 'is_contact_desc',
  LastLoginDateFromNowAsc = 'last_login_date__from_now_asc',
  LastLoginDateFromNowDesc = 'last_login_date__from_now_desc',
  LastLoginDateAsc = 'last_login_date_asc',
  LastLoginDateDesc = 'last_login_date_desc',
  LastNameAsc = 'last_name_asc',
  LastNameDesc = 'last_name_desc',
  LoginCountAsc = 'login_count_asc',
  LoginCountDesc = 'login_count_desc',
  MaritalStatusIdAsc = 'marital_status_id_asc',
  MaritalStatusIdDesc = 'marital_status_id_desc',
  MiddleNameAsc = 'middle_name_asc',
  MiddleNameDesc = 'middle_name_desc',
  NicknameAsc = 'nickname_asc',
  NicknameDesc = 'nickname_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  PhoneCellAsc = 'phone_cell_asc',
  PhoneCellDesc = 'phone_cell_desc',
  PhoneFaxAsc = 'phone_fax_asc',
  PhoneFaxDesc = 'phone_fax_desc',
  PhoneHomeAsc = 'phone_home_asc',
  PhoneHomeDesc = 'phone_home_desc',
  PhoneOfficeAsc = 'phone_office_asc',
  PhoneOfficeDesc = 'phone_office_desc',
  PhoneTeamworksAsc = 'phone_teamworks_asc',
  PhoneTeamworksDesc = 'phone_teamworks_desc',
  PictureImageAsc = 'picture_image_asc',
  PictureImageDesc = 'picture_image_desc',
  PreferredNameAsc = 'preferred_name_asc',
  PreferredNameDesc = 'preferred_name_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SchoolIdentifierAsc = 'school_identifier_asc',
  SchoolIdentifierDesc = 'school_identifier_desc',
  StartDateAsc = 'start_date_asc',
  StartDateDesc = 'start_date_desc',
  SuffixAsc = 'suffix_asc',
  SuffixDesc = 'suffix_desc',
  TimeZoneAsc = 'time_zone_asc',
  TimeZoneDesc = 'time_zone_desc',
  TitleAsc = 'title_asc',
  TitleDesc = 'title_desc',
  TravelWeightAsc = 'travel_weight_asc',
  TravelWeightDesc = 'travel_weight_desc',
  TwitterAccountAsc = 'twitter_account_asc',
  TwitterAccountDesc = 'twitter_account_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  UserTypesByTeamAsc = 'user_types_by_team_asc',
  UserTypesByTeamDesc = 'user_types_by_team_desc',
  UsesExternalAuthenticationAsc = 'uses_external_authentication_asc',
  UsesExternalAuthenticationDesc = 'uses_external_authentication_desc',
}

export type PersonStatusTypeNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActiveStatus?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  labelPlural?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personStatusTypeId: Scalars['ID'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type PersonToUnreadCountObjectType = {
  personId?: Maybe<Scalars['Int']>;
  unreadCount?: Maybe<Scalars['Int']>;
};

export type PersonTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PersonTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PersonTypeCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  personType?: Maybe<PersonTypeNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `PersonType` and its cursor. */
export type PersonTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PersonTypeNode>;
};

export type PersonTypeInput = {
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  namePlural: Scalars['String'];
  permissions?: InputMaybe<Array<InputMaybe<PersonTypePermissionInput>>>;
};

export type PersonTypeNode = Node & {
  color?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  defaultUserTypePermissions?: Maybe<Array<Maybe<ProfilePermissionNode>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  namePlural: Scalars['String'];
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personTypeId: Scalars['ID'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  teamMembers?: Maybe<TeamMemberNodeConnection>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type PersonTypeNodeTeamMembersArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAcademicsUser?: InputMaybe<Scalars['TWFilter_String']>;
  isOrgSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  isTeamSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamMemberSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamMemberId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type PersonTypeNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PersonTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PersonTypeNode` and its cursor. */
export type PersonTypeNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PersonTypeNode>;
};

export type PersonTypePermissionInput = {
  id?: InputMaybe<Scalars['ID']>;
  restrictions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** An enumeration. */
export enum PersonTypeSortEnum {
  ColorAsc = 'color_asc',
  ColorDesc = 'color_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  NamePluralAsc = 'name_plural_asc',
  NamePluralDesc = 'name_plural_desc',
  PersonTypeIdAsc = 'person_type_id_asc',
  PersonTypeIdDesc = 'person_type_id_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type PersonTypeUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  personType?: Maybe<PersonTypeNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonUpdateAddressPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PersonUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum PositionSortEnum {
  PositionIdAsc = 'position_id_asc',
  PositionIdDesc = 'position_id_desc',
  PositionLongAsc = 'position_long_asc',
  PositionLongDesc = 'position_long_desc',
  PositionPluralAsc = 'position_plural_asc',
  PositionPluralDesc = 'position_plural_desc',
  PositionShortAsc = 'position_short_asc',
  PositionShortDesc = 'position_short_desc',
  SportIdAsc = 'sport_id_asc',
  SportIdDesc = 'sport_id_desc',
}

export type PrepareForSignature = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  completion?: Maybe<FormAssignmentCompletionNode>;
  link?: Maybe<Scalars['String']>;
  myCompletionRole?: Maybe<FormAssignmentCompletionRoleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type PrepareForSignatureArgsInput = {
  assignmentId?: InputMaybe<Scalars['Int']>;
  completionRoleId?: InputMaybe<Scalars['Int']>;
};

export type ProfessorConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfessorEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Professor input for creating new Professors. */
export type ProfessorCreateInput = {
  /** Email address for the professor. Required. */
  emailAddress: Scalars['String'];
  /** First name for the professor. Required. */
  firstName: Scalars['String'];
  /** Last name for the professor. Required. */
  lastName: Scalars['String'];
  /** Office location for the professor. Optional. */
  officeLocation?: InputMaybe<Scalars['String']>;
  /** Phone number for the professor. Optional. */
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type ProfessorCreatePayload = {
  Output?: Maybe<ProfessorNode>;
  aC2Professor?: Maybe<ProfessorNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `Professor` and its cursor. */
export type ProfessorEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProfessorNode>;
};

export type ProfessorNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  /** The professor's email address */
  emailAddress?: Maybe<Scalars['String']>;
  /** The professor's first name */
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Whether or not professor is active */
  isActive: Scalars['Boolean'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  /** The professor's last name */
  lastName?: Maybe<Scalars['String']>;
  /** The professor's office location. */
  officeLocation?: Maybe<Scalars['String']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /** The professor's phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  professorId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type ProfessorSurveyAssignmentConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfessorSurveyAssignmentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ProfessorSurveyAssignment` and its cursor. */
export type ProfessorSurveyAssignmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProfessorSurveyAssignmentNode>;
};

export type ProfessorSurveyAssignmentNode = Node & {
  /** Time when survey was marked as done */
  completedDateTime?: Maybe<Scalars['DateTime']>;
  courseSection?: Maybe<CourseSectionNode>;
  courseSectionId: Scalars['Int'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  externalAccessLink?: Maybe<ExternalAccessLinkNode>;
  /** FK to external_access_link table */
  externalAccessLinkId: Scalars['Int'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  professorSurvey?: Maybe<ProfessorSurveyNode>;
  professorSurveyAssignmentId: Scalars['ID'];
  professorSurveyId: Scalars['Int'];
  professorSurveyReports?: Maybe<ProfessorSurveyReportConnection>;
  /** Current assignment status. */
  status: ProfessorSurveyAssignmentStatus;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type ProfessorSurveyAssignmentNodeProfessorSurveyReportsArgs = {
  absenceCount?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['TWFilter_Int']>;
  isAtRisk?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorSurveyAssignmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  professorSurveyReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<Array<InputMaybe<ProfessorSurveyReportSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum ProfessorSurveyAssignmentSortEnum {
  Ac2ProfessorSurveyAssignmentIdAsc = 'ac2_professor_survey_assignment_id_asc',
  Ac2ProfessorSurveyAssignmentIdDesc = 'ac2_professor_survey_assignment_id_desc',
  CompletedDateTimeAsc = 'completed_date_time_asc',
  CompletedDateTimeDesc = 'completed_date_time_desc',
  CourseSectionIdAsc = 'course_section_id_asc',
  CourseSectionIdDesc = 'course_section_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  ExternalAccessLinkIdAsc = 'external_access_link_id_asc',
  ExternalAccessLinkIdDesc = 'external_access_link_id_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ProfessorSurveyIdAsc = 'professor_survey_id_asc',
  ProfessorSurveyIdDesc = 'professor_survey_id_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Represents a Professor */
export enum ProfessorSurveyAssignmentStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  Pending = 'pending',
}

export type ProfessorSurveyConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfessorSurveyEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProfessorSurveyCreateInput = {
  /** List of course sections to create a survey for */
  courseSectionIds: Array<InputMaybe<Scalars['String']>>;
  description: Scalars['String'];
  dueDateTime: Scalars['Date'];
  label: Scalars['String'];
  /** text for the body of the message */
  messageBody?: InputMaybe<Scalars['String']>;
  /** List of global student ids to send surveys for */
  studentIds: Array<InputMaybe<Scalars['String']>>;
  termId: Scalars['String'];
};

export type ProfessorSurveyCreatePayload = {
  Output?: Maybe<ProfessorSurveyNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  professorSurvey?: Maybe<ProfessorSurveyNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `ProfessorSurvey` and its cursor. */
export type ProfessorSurveyEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProfessorSurveyNode>;
};

export type ProfessorSurveyNode = Node & {
  countCompletedAssignments?: Maybe<Scalars['String']>;
  countNotCompletedAssignments?: Maybe<Scalars['String']>;
  countStudents?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of descriptionXlt */
  description?: Maybe<Scalars['String']>;
  /** Deprecated */
  descriptionXlt?: Maybe<Scalars['GenericScalar']>;
  /** When survey should be done */
  dueDateTime: Scalars['DateTime'];
  hasOnlyCompletedAssignments?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isClosed?: Maybe<Scalars['String']>;
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  isExpired?: Maybe<Scalars['String']>;
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of labelXlt */
  label?: Maybe<Scalars['String']>;
  /** Deprecated */
  labelXlt?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  professorSurveyAssignments?: Maybe<ProfessorSurveyAssignmentConnection>;
  professorSurveyId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type ProfessorSurveyNodeProfessorSurveyAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  completedDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  externalAccessLinkId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorSurveyAssignmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  professorSurveyId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<ProfessorSurveyAssignmentSortEnum>>>;
  status?: InputMaybe<Scalars['TWFilter_ProfessorSurveyAssignmentStatus']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type ProfessorSurveyReportConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfessorSurveyReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ProfessorSurveyReport` and its cursor. */
export type ProfessorSurveyReportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProfessorSurveyReportNode>;
};

export type ProfessorSurveyReportNode = Node & {
  /** Count of unattended lessons */
  absenceCount?: Maybe<Scalars['Int']>;
  /** Custom comment */
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  enrollment?: Maybe<EnrollmentNode>;
  enrollmentId: Scalars['Int'];
  grade?: Maybe<GradeNode>;
  gradeId?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Flag student is at risk */
  isAtRisk?: Maybe<Scalars['Boolean']>;
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  professorSurveyAssignment?: Maybe<ProfessorSurveyAssignmentNode>;
  professorSurveyAssignmentId: Scalars['Int'];
  professorSurveyReportId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type ProfessorSurveyReportNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfessorSurveyReportNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfessorSurveyReportNode` and its cursor. */
export type ProfessorSurveyReportNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProfessorSurveyReportNode>;
};

/** An enumeration. */
export enum ProfessorSurveyReportSortEnum {
  AbsenceCountAsc = 'absence_count_asc',
  AbsenceCountDesc = 'absence_count_desc',
  Ac2ProfessorSurveyReportIdAsc = 'ac2_professor_survey_report_id_asc',
  Ac2ProfessorSurveyReportIdDesc = 'ac2_professor_survey_report_id_desc',
  CommentAsc = 'comment_asc',
  CommentDesc = 'comment_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EnrollmentIdAsc = 'enrollment_id_asc',
  EnrollmentIdDesc = 'enrollment_id_desc',
  GradeIdAsc = 'grade_id_asc',
  GradeIdDesc = 'grade_id_desc',
  IsAtRiskAsc = 'is_at_risk_asc',
  IsAtRiskDesc = 'is_at_risk_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ProfessorSurveyAssignmentIdAsc = 'professor_survey_assignment_id_asc',
  ProfessorSurveyAssignmentIdDesc = 'professor_survey_assignment_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** An enumeration. */
export enum ProfessorSurveySortEnum {
  Ac2ProfessorSurveyIdAsc = 'ac2_professor_survey_id_asc',
  Ac2ProfessorSurveyIdDesc = 'ac2_professor_survey_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DescriptionXltAsc = 'description_xlt_asc',
  DescriptionXltDesc = 'description_xlt_desc',
  DueDateTimeAsc = 'due_date_time_asc',
  DueDateTimeDesc = 'due_date_time_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  LabelXltAsc = 'label_xlt_asc',
  LabelXltDesc = 'label_xlt_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Professor input for udating Professors. */
export type ProfessorUpdateInput = {
  /** Email address for the professor. */
  emailAddress?: InputMaybe<Scalars['String']>;
  /** First name for the professor. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last name for the professor. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Office location for the professor. */
  officeLocation?: InputMaybe<Scalars['String']>;
  /** Phone number for the professor. */
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type ProfessorUpdatePayload = {
  Output?: Maybe<ProfessorNode>;
  aC2Professor?: Maybe<ProfessorNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileAttributeBulkImportInput = {
  attributes: Array<InputMaybe<BulkAttributeImportInput>>;
  fileName: Scalars['String'];
  serverFileName: Scalars['String'];
  teamId: Scalars['Int'];
};

export type ProfileAttributeCategoryCreatePayload = {
  attributeCategory?: Maybe<AttributeCategoryNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileAttributeCategoryDeletePayload = {
  attributeCategory?: Maybe<AttributeCategoryNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileAttributeCategoryInput = {
  editabilityCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isTeamLevel?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  visibilityCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProfileAttributeCategoryOrderInput = {
  /** Global ID for the Profile Attribute Category to update */
  attributeCategoryId: Scalars['ID'];
  /** Number 0 - x determining the sort order */
  sortOrder: Scalars['Int'];
};

export type ProfileAttributeCategoryReorderInput = {
  attributeCategories: Array<InputMaybe<ProfileAttributeCategoryOrderInput>>;
};

export type ProfileAttributeCategoryReorderPayload = {
  attributeCategory?: Maybe<AttributeCategoryNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileAttributeCategoryUpdatePayload = {
  attributeCategory?: Maybe<AttributeCategoryNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileAttributeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfileAttributeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProfileAttributeCreatePayload = {
  attribute?: Maybe<AttributeNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileAttributeDatatypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfileAttributeDatatypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ProfileAttributeDatatype` and its cursor. */
export type ProfileAttributeDatatypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProfileAttributeDatatypeObjectNode>;
};

export type ProfileAttributeDatatypeObjectNode = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  label: Scalars['String'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
};

export type ProfileAttributeDeletePayload = {
  attribute?: Maybe<AttributeNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `ProfileAttribute` and its cursor. */
export type ProfileAttributeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AttributeNode>;
};

/** Input model for a number of ProfileAttribute mutations */
export type ProfileAttributeInput = {
  attributeCategory?: InputMaybe<Scalars['ID']>;
  attributeDataType?: InputMaybe<Scalars['ID']>;
  attributeReminders?: InputMaybe<Array<InputMaybe<AttributeReminderInput>>>;
  /** Array of strings of attribute options */
  enumOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** True if any restriction of who this applies to */
  hasUserTypeRestriction?: InputMaybe<Scalars['Boolean']>;
  /** Minimum one character string name for attribute. No duplicates allowed per category */
  label?: InputMaybe<Scalars['String']>;
  /** If hasUsertypeRestriction, then must pass user type IDs */
  restrictedUserTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** If selfManageUsers is 'custom', then user type IDs here */
  selfManageUserTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Must be 'all', 'custom', or 'none'. Determines who can manage attribute */
  selfManageUsers?: InputMaybe<Scalars['String']>;
  /** Number 1 - x determining the sort order. On create, defaults to last of attributes */
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export type ProfileAttributeOrderInput = {
  /** Global ID for the Profile Attribute to update */
  attributeId: Scalars['ID'];
  /** Number 1 - x determining the sort order */
  sortOrder: Scalars['Int'];
};

export type ProfileAttributeReorderInput = {
  attributes: Array<InputMaybe<ProfileAttributeOrderInput>>;
};

export type ProfileAttributeReorderPayload = {
  attributeCategory?: Maybe<AttributeCategoryNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileAttributeUpdatePayload = {
  attribute?: Maybe<AttributeNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileBulkImportAddressPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  personAddress?: Maybe<Array<Maybe<PersonAddressNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileBulkImportPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<Array<Maybe<PersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileBulkImportRoommatesPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type ProfileBulkInput = {
  fileName?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<Array<InputMaybe<ProfileInput>>>;
  serverFileName: Scalars['String'];
};

export type ProfileBulkRoommateInput = {
  fileName: Scalars['String'];
  roommates: Array<InputMaybe<BulkRoommateInput>>;
  serverFileName: Scalars['String'];
};

/** GraphQL node for a profile contact */
export type ProfileContact = {
  addresses?: Maybe<Array<Maybe<PersonAddressNode>>>;
  dateOfBirth?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  emailAddressPersonal?: Maybe<Scalars['String']>;
  facebookAccount?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  initials?: Maybe<Scalars['String']>;
  isGuardian?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  maritalStatusId?: Maybe<Scalars['Int']>;
  maritalStatusLabel?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  phoneCell?: Maybe<Scalars['String']>;
  phoneFax?: Maybe<Scalars['String']>;
  phoneHome?: Maybe<Scalars['String']>;
  phoneOffice?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  relationship?: Maybe<PersonRelationshipTypeNode>;
  selectionCode?: Maybe<Scalars['String']>;
  travelWeight?: Maybe<Scalars['Int']>;
  twitterAccount?: Maybe<Scalars['String']>;
};

/** Input model for ProfileBulkImport mutation */
export type ProfileInput = {
  academicYear?: InputMaybe<Scalars['ID']>;
  athletePosition?: InputMaybe<Scalars['ID']>;
  athleteStatus?: InputMaybe<Scalars['ID']>;
  athleticYear?: InputMaybe<Scalars['ID']>;
  autoMake?: InputMaybe<Scalars['String']>;
  autoModel?: InputMaybe<Scalars['String']>;
  autoPlateNumber?: InputMaybe<Scalars['String']>;
  autoState?: InputMaybe<Scalars['String']>;
  autoYear?: InputMaybe<Scalars['String']>;
  birthCountry?: InputMaybe<Scalars['String']>;
  customAttributes?: InputMaybe<Array<InputMaybe<CustomAttributeInput>>>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  documentCountry?: InputMaybe<Scalars['String']>;
  documentExpDate?: InputMaybe<Scalars['String']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  driversLicenseExpirationDate?: InputMaybe<Scalars['String']>;
  driversLicenseNumber?: InputMaybe<Scalars['String']>;
  driversLicenseState?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  emailAddressPersonal?: InputMaybe<Scalars['String']>;
  facebookAccount?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  hasScholarship?: InputMaybe<Scalars['Boolean']>;
  jerseyNumber?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  major?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phoneCell?: InputMaybe<Scalars['String']>;
  phoneFax?: InputMaybe<Scalars['String']>;
  phoneHome?: InputMaybe<Scalars['String']>;
  phoneOffice?: InputMaybe<Scalars['String']>;
  residency?: InputMaybe<Scalars['String']>;
  scholarshipAmount?: InputMaybe<Scalars['String']>;
  schoolIdentifier?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  travelWeight?: InputMaybe<Scalars['Int']>;
  twitterAccount?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['ID']>;
  usesExternalAuthentication?: InputMaybe<Scalars['Boolean']>;
};

export type ProfilePermissionNode = {
  category?: Maybe<Scalars['String']>;
  codeName?: Maybe<Scalars['String']>;
  dependencies?: Maybe<Array<Maybe<Scalars['ID']>>>;
  granted?: Maybe<Scalars['Boolean']>;
  grantedBy?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id?: Maybe<Scalars['ID']>;
  isRestrictedType?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  restrictions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tooltip?: Maybe<Scalars['String']>;
};

export type ProfileReportConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfileReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProfileReportCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  profileReport?: Maybe<ProfileReportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `ProfileReport` and its cursor. */
export type ProfileReportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProfileReportNode>;
};

/** Input model for ProfileReportCreate mutation */
export type ProfileReportInput = {
  activeStatus?: InputMaybe<Scalars['Boolean']>;
  advanced?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  athleteStatus?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customGroup?: InputMaybe<Scalars['String']>;
  defaultColumns: Array<InputMaybe<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortType?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  withAddresses?: InputMaybe<Scalars['Boolean']>;
  withAutomobile?: InputMaybe<Scalars['Boolean']>;
  withContacts?: InputMaybe<Scalars['Boolean']>;
  withIsOrgSuperuser?: InputMaybe<Scalars['Boolean']>;
  withRoommates?: InputMaybe<Scalars['Boolean']>;
  withTravelDocument?: InputMaybe<Scalars['Boolean']>;
};

export type ProfileReportNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  downloadableFileId: Scalars['ID'];
  errorMessage?: Maybe<Scalars['String']>;
  fileNameServer?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** What kind of downloaded file this is, e.g. 'report'. */
  kind: Scalars['String'];
  /** Forward-compatible metadata column. */
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<OrgNode>;
  /** The Teamworks Org Id that this is associated with this */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Teamworks Person Id that this is associated with this */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  profileReportId: Scalars['ID'];
  /** Status of the file, i.e. one of `&.STATUS_*`. */
  status: Scalars['String'];
  team?: Maybe<TeamNode>;
  /** The Teamworks Team Id that this is associated with this */
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** Cached copy of the public URL. */
  url?: Maybe<Scalars['String']>;
};

export type ProfileRoommateType = {
  displayName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  initials?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
  roommate?: Maybe<AthleteRoommateNode>;
  selectionCode?: Maybe<Scalars['String']>;
  teamMembers?: Maybe<Array<Maybe<TeamMemberNode>>>;
};

export type Query = {
  /** All activities for the current user. */
  activities?: Maybe<ActivityNodeConnection>;
  /** Organizational admin actions taken. Includes both in-app and Global Admin (madden) actions */
  adminActions?: Maybe<AdminActionConnection>;
  /** All advisors in the org. */
  advisors?: Maybe<AdvisorConnection>;
  /** All appointments for the provided selections and date range that are visible to the current user. */
  allAppointments?: Maybe<AppointmentConnection>;
  /** All active bulletins for the org */
  allBulletins?: Maybe<BulletinsConnection>;
  /** <graphene.types.argument.Argument object at 0x29f487df0> */
  allCustomTasks?: Maybe<CustomTaskConnection>;
  /** Organizational person info; intended for Admin use. */
  allPersons?: Maybe<PersonNodeConnection>;
  /** All files uploaded by or shared with the current user. */
  allSharedFiles?: Maybe<SharedFileConnection>;
  /** Returns a list of the current_user's permissions */
  allTeamMemberPermissions?: Maybe<TeamMemberPermissionConnection>;
  /** All Team-Scoped Attribute Categories */
  allTeamProfileAttributeCategories?: Maybe<AttributeCategoryConnection>;
  /**
   * Organizational team info
   * @deprecated Use 'Query.teams' instead. It returns only active teams by default.
   */
  allTeams?: Maybe<TeamConnection>;
  /** User File Video Actions */
  allUserFileVideoActions?: Maybe<UserFileVideoConnection>;
  /** Pull info on a specific Appointment Checkin */
  appointmentCheckin?: Maybe<AppointmentCheckinNode>;
  /** Pulling AppointmentCheckinOccurrence by UUID */
  appointmentCheckinOccurrence?: Maybe<AppointmentCheckinOccurrenceNode>;
  /** Initial call from FE to load appointment data and QR code metadata */
  appointmentCheckinQrCode?: Maybe<ApptCheckinQrKioskDetails>;
  /** A single appointment with the ability to include selection objects containing code/label data. */
  appointmentSelections?: Maybe<AppointmentSelectionConnection>;
  /** A list of all the valid appointment types */
  appointmentTypes?: Maybe<AppointmentTypeConnection>;
  /** The list of defined athletic years. */
  athleticYears?: Maybe<Array<Maybe<EligibilityYear>>>;
  attributeCategories?: Maybe<Array<Maybe<AttributeCategoryNode>>>;
  /** Conflicts for TMP/CARA enabled rules for a set of appointment occurrences */
  bulkTmpConflicts?: Maybe<Array<Maybe<BulkAppointmentConflict>>>;
  bulletinClicks?: Maybe<BulletinClickConnection>;
  /** Bulletins settings for org */
  bulletinsSettings?: Maybe<BulletinsSettingsConnection>;
  /** a list of calendar reports for this user */
  calendarReports?: Maybe<CalendarReportConnection>;
  calsyncAccounts?: Maybe<Array<Maybe<CalsyncAccountNode>>>;
  calsyncProviderCalendars?: Maybe<Array<Maybe<CalsyncProviderCalendarNode>>>;
  /** All checkins in the org. */
  checkin?: Maybe<CheckinConnection>;
  /** All checkin actions for a student id. */
  checkinAction?: Maybe<CheckinActionConnection>;
  /** All checkin data in the org. */
  checkins?: Maybe<CheckinDataConnection>;
  /** A list of all the valid conversation types */
  conversationTypes?: Maybe<ConversationTypeConnection>;
  /** All conversations that the current user has access to. */
  conversations?: Maybe<ConversationConnection>;
  /** All countries */
  countries?: Maybe<CountryNodeConnection>;
  /** All course sections the current user has access to. */
  courseSections?: Maybe<CourseSectionConnection>;
  /** All courses in the org. */
  courses?: Maybe<CourseConnection>;
  customGroups?: Maybe<CustomGroupsConnection>;
  /** All assigners who have custom tasks user can manage */
  customTaskAssigners?: Maybe<Array<Maybe<CustomTaskAssignerType>>>;
  /** Get a list of CustomTaskAssignmentsExports */
  customTaskAssignmentsExport?: Maybe<CustomTaskAssignmentsExportConnection>;
  /** All custom tasks completed by a person */
  customTaskCompletions?: Maybe<Array<Maybe<CustomTaskCompletionType>>>;
  customTaskLabels?: Maybe<CustomTaskLabelConnection>;
  /** The microservice message recipient delivery status records represent one message per recipient */
  deliveries?: Maybe<DeliveryResponseConnection>;
  /** Digital Displays available to requester on org */
  digitalDisplays?: Maybe<DigitalDisplayConnection>;
  /** The list of defined eligibility years. */
  eligibilityYears?: Maybe<Array<Maybe<EligibilityYear>>>;
  /** All EnrollmentAttendances in the org. */
  enrollmentAttendances?: Maybe<EnrollmentAttendanceConnection>;
  externalAccessLink?: Maybe<ExternalAccessLinkNodeWithWarnings>;
  externalCompletionRole?: Maybe<FormAssignmentCompletionRoleNode>;
  externalFormAssignment?: Maybe<FormAssignmentNode>;
  /** The current given professor survey assignment */
  externalProfessorSurveyAssignments?: Maybe<ProfessorSurveyAssignmentConnection>;
  /** An individual Study Hall accessible by an external checkins app */
  externalStudyHall?: Maybe<StudyHallConnection>;
  externalVideo?: Maybe<ExternalVideoNode>;
  /** Feature flags enabled for the user. */
  featureFlags?: Maybe<FeatureFlagConnection>;
  fetchOverlayEvents?: Maybe<OverlayEventDataNode>;
  /** Get full S3 server file url to download the file which can be saved as fileName param */
  fileDownloadUrl?: Maybe<AttachmentObjectType>;
  /** Filter form completions for a given form assignment given a search query and search fields. */
  filterFormCompletions?: Maybe<FormAssignmentCompletionConnection>;
  /** All parent and booked slot tutoring appointments for the provided selections and date range that are visible to the current user. */
  findTutorAppointments?: Maybe<TutoringAppointmentConnection>;
  /** Folders user has access to */
  folders?: Maybe<FolderConnection>;
  formAssignmentCompletionAnswers?: Maybe<FormAssignmentCompletionAnswerConnection>;
  /** All form completions for a given form assignment (if user can manage that assignment) */
  formAssignmentCompletions?: Maybe<FormAssignmentCompletionConnection>;
  /** All persons who have assigned either a Form Assignment or Form Schedule */
  formAssignmentsAssigners?: Maybe<Array<Maybe<PersonNode>>>;
  /** a list of form results reports for this user */
  formResultsReport?: Maybe<FormResultsReportConnection>;
  /** unless filtered by inManagableAssignments, all form templates from user's organization */
  formTemplates?: Maybe<FormTemplateConnection>;
  getOverlayEvent?: Maybe<OverlayEventWithSelectablesNode>;
  getOverlayEventHubAttendeeSelections?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  getOverlayEvents?: Maybe<OverlayEventDataNode>;
  /** All grades in the org. */
  grades?: Maybe<GradeConnection>;
  /** Default grades. */
  gradesDefault?: Maybe<GradeConnection>;
  /** Returns the set of available selection groups. */
  groups?: Maybe<Array<Maybe<SelectionGroup>>>;
  /** All institutions. */
  institutions?: Maybe<InstitutionNodeConnection>;
  /** All the sender persons for messages that the user can list */
  listMessageSenders?: Maybe<MessageSendersResponseConnection>;
  /** The microservice message records */
  listMessages?: Maybe<ListMessageResponseConnection>;
  /** All form assignments that user can manage */
  manageFormAssignments?: Maybe<ManageFormAssignmentsConnection>;
  /** All form assignments that user can manage */
  manageFormScheduleAssignments?: Maybe<ManageFormScheduleAssignmentsConnection>;
  /** The message recipient delivery status records represent one message per recipient */
  messageRecipientDeliveryStatuses?: Maybe<MessageRecipientDeliveryStatusConnection>;
  /** All persons who've sent a message that you'd have access to */
  messageSenders?: Maybe<Array<Maybe<PersonNode>>>;
  /** All the valid message types */
  messageTypes?: Maybe<Array<Maybe<MessageTypeObjectType>>>;
  /** All messages the current user has access to. If user has access to more than just their messages, then they'll be returned as well. Use filters to get just the user's messages if desired. */
  messages?: Maybe<MessageConnection>;
  /** All appointments for the current user within the provided date range. */
  myAppointments?: Maybe<AppointmentConnection>;
  /** All active bulletins visibile to user */
  myBulletins?: Maybe<BulletinsConnection>;
  /** <graphene.types.argument.Argument object at 0x29f497490> */
  myCustomTasks?: Maybe<CustomTaskConnection>;
  /** All forms user is assigned to except perpetual that belong to (My) Form Library */
  myFormAssignments?: Maybe<MyFormAssignmentsConnection>;
  /** All mobile visible trips that a user has access to. */
  myMobileTrips?: Maybe<TripConnection>;
  /** All perpetual forms user is assigned to */
  myPerpetualFormAssignments?: Maybe<MyPerpetualFormConnection>;
  /** All saved views (unfortunately tabled as saved_report) that the current user has. */
  mySavedReports?: Maybe<SavedReportNodeConnection>;
  /** All saved views (unfortunately tabled as saved_report) that have been shared with the current user. */
  mySharedSavedReports?: Maybe<SavedReportNodeConnection>;
  /** All team roster saved views (unfortunately tabled as saved_report) that have been shared with the current user. */
  mySharedTeamRosterSavedViews?: Maybe<SavedReportNodeConnection>;
  /** All assigners (persons, entities, etc) who have assigned either a task to this person */
  myTaskAssigners?: Maybe<Array<Maybe<TaskAssignerType>>>;
  /** All tasks for a given person. */
  myTasks?: Maybe<MyTasksConnection>;
  /** All team roster saved views (unfortunately tabled as saved_report) that the current user has. */
  myTeamRosterSavedViews?: Maybe<SavedReportNodeConnection>;
  /** Only my seatplans for a given trip */
  myTripSeatplans?: Maybe<MyTravelSeatplanObjectType>;
  /** <graphene.types.argument.Argument object at 0x29a745610> */
  myVideos?: Maybe<VideoConnection>;
  /** All web links shared with the current user */
  myWebLinks?: Maybe<WebLinkConnection>;
  node?: Maybe<
    | ActivityNode
    | ActivityPersonNode
    | AdminActionNode
    | AdvisorNode
    | Appointment
    | AppointmentOccurrence
    | AppointmentRsvp
    | AppointmentReminder
    | AppointmentType
    | AthleteRoommateNode
    | AttributeCategoryNode
    | AttributeNode
    | AttributeReminderNode
    | BaseAppointmentNode
    | BulletinClickNode
    | BulletinNode
    | BulletinsSettingsNode
    | CalendarReportNode
    | CalsyncAccountNode
    | CalsyncCalendarNode
    | CheckinActionNode
    | CompetitionNode
    | ComplianceAudit
    | ComplianceAuditComment
    | Conversation
    | CountryNode
    | CourseNode
    | CourseSectionNode
    | CourseSectionScheduleNode
    | CustomGroupNode
    | CustomTaskAssignmentNode
    | CustomTaskAssignmentsExportNode
    | CustomTaskLabelNode
    | CustomTaskNode
    | DigitalDisplayNode
    | EligibilityYear
    | EnrollmentAttendanceNode
    | EnrollmentNode
    | ExternalAccessLinkNode
    | ExternalVideoNode
    | FileAttachment
    | FolderNode
    | FormAssignmentCompletionAnswerNode
    | FormAssignmentCompletionNode
    | FormAssignmentCompletionRoleNode
    | FormAssignmentNode
    | FormAssignmentRoleNode
    | FormResultsReportNode
    | FormScheduleNode
    | FormTemplateNode
    | GradeNode
    | InstitutionNode
    | Message
    | ModuleNode
    | NoteNode
    | NotificationSettingNode
    | OrgNode
    | PermissionNode
    | PersonAddressNode
    | PersonNode
    | PersonRelationshipTypeNode
    | PersonStatusTypeNode
    | PersonTypeNode
    | ProfessorNode
    | ProfessorSurveyAssignmentNode
    | ProfessorSurveyNode
    | ProfessorSurveyReportNode
    | ProfileAttributeDatatypeObjectNode
    | ProfileReportNode
    | SavedReportNode
    | SavedReportPersonNode
    | SeasonObjectType
    | SessionAttendanceNode
    | SessionNode
    | SessionRequestNode
    | SharedFileConnection
    | SharedFileNode
    | SharingRecordNode
    | SignupNode
    | SportPosition
    | StateNode
    | StudentNode
    | StudentTermNode
    | StudentYearNode
    | StudyHallActionNode
    | StudyHallNode
    | StudyHallRequirementNode
    | SubjectNode
    | TeamMemberNode
    | TeamMemberPermissionNode
    | TeamModuleNode
    | TeamNode
    | TeamRosterNode
    | TeamRosterPersonNode
    | TeamRosterReportNode
    | TermNode
    | TicketingBlockedGuest
    | TicketingEvent
    | TicketingEventPerson
    | TicketingGuest
    | TicketingOrgSettingObjectType
    | TicketingReservationReportNode
    | TmpActivityCommentsNode
    | TmpActivityLogDatesListNode
    | TmpActivityLogListConnection
    | TmpDateNode
    | TmpDateTypeNode
    | TmpRule
    | TmpRuleNode
    | TmpTeamNode
    | TmpTeamSegmentConflictNode
    | TmpTeamSegmentNode
    | TmpTeamSettingsNode
    | TmpYearNode
    | TmpYearRule
    | TmpYearRuleNode
    | TravelAirSeatingChart
    | TravelContactNode
    | TravelCredential
    | TravelCredentialAssignment
    | TravelDocumentConfigNode
    | TravelDressNode
    | TravelEventNode
    | TravelEventTypeNode
    | TravelParticipation
    | TravelParticipationReportNode
    | TravelPartyMemberNode
    | TravelPregameItem
    | TravelSeatplan
    | TravelTaskNode
    | TravelVendor
    | TravelVendorGroup
    | TravelVendorTypeNode
    | Trip
    | TutorNode
    | TutorTermNode
    | UserFileVideoActionNode
    | UserHistoryChangelogNode
    | UserPreferencesNode
    | VideoConnection
    | VideoNode
    | WebLink
    | YearlyAttributeCategoryNode
    | YearlyAttributeNode
    | YearlyAttributeReminderNode
    | YearlyAttributeReportNode
    | YearlyAttributeValueNode
  >;
  /** All Notification Settings for an org. */
  notificationSettings?: Maybe<NotificationSettingConnection>;
  /** Org-Scoped Attribute Categories */
  orgProfileAttributeCategories?: Maybe<AttributeCategoryConnection>;
  /** All org attributes the current user has access to. */
  orgProfileAttributes?: Maybe<ProfileAttributeConnection>;
  /** The list of defined sport positions. */
  orgSportPositions?: Maybe<Array<Maybe<OrgSportPositionNode>>>;
  /** Org-Scoped YearlyAttribute Categories */
  orgYearlyAttributeCategories?: Maybe<YearlyAttributeCategoryConnection>;
  /** Organizational entries scoped to the current user */
  orgs?: Maybe<OrgNodeConnection>;
  people?: Maybe<PeopleConnection>;
  /** <graphene.types.argument.Argument object at 0x2973b5c40> */
  permissions?: Maybe<PermissionConnection>;
  /** All user types usable on this org. */
  personTypes?: Maybe<PersonTypeConnection>;
  /** Persons who can view bulletin */
  personsWhoCanViewBulletin?: Maybe<BulletinViewersConnection>;
  /** All pregame items for the sent competition. */
  pregameItems?: Maybe<TravelPregameItemConnection>;
  professorSurveyAssignments?: Maybe<ProfessorSurveyAssignmentConnection>;
  /** All professor surveys in org */
  professorSurveys?: Maybe<ProfessorSurveyConnection>;
  /** All terms in the org. */
  professors?: Maybe<ProfessorConnection>;
  /** All data types for profile attributes. */
  profileAttributeDatatypes?: Maybe<ProfileAttributeDatatypeConnection>;
  /** a list of advanced reports for this user */
  profileReport?: Maybe<ProfileReportConnection>;
  /** Files uploaded by or shared with the current user, sorted by shared date (most recent first). */
  recentlySharedFiles?: Maybe<SharedFileConnection>;
  /** All relationship types usable on this org. */
  relationshipTypes?: Maybe<PersonRelationshipTypeConnection>;
  /** All seasons for the team. */
  seasons?: Maybe<SeasonConnection>;
  /** Returns the set of selectable objects based on the input selection code strings. */
  selectables?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  /** All session requests in the org. */
  sessionRequests?: Maybe<SessionRequestConnection>;
  /** All sessions in the org. */
  sessions?: Maybe<SessionConnection>;
  /** All sharing records the current user has access to. */
  sharingRecords?: Maybe<SharingRecordNodeConnection>;
  /** All not approved signoff responses for a given person */
  signoffResponse?: Maybe<ActivityLogSignoffResponseConnection>;
  /** An Athlete's log summary for a given signoff response */
  signoffResponseDetails?: Maybe<ActivityLogSignoffResponseConnection>;
  /** The root-level signups field. */
  signups?: Maybe<SignupConnection>;
  signupsMobileDashboard?: Maybe<SignupMobileDashboardNode>;
  /** All child slot appointments for the provided selections and date range that are visible to the current user. */
  slotAppointments?: Maybe<SlotAppointmentConnection>;
  /** The list of defined sport positions. */
  sportPositions?: Maybe<Array<Maybe<SportPosition>>>;
  /** All states */
  states?: Maybe<StateNodeConnection>;
  streamUsers?: Maybe<StreamUserResponseConnection>;
  /** All Study Halls Actions (e.g. checkins) for an student */
  studentStudyHallActions?: Maybe<StudyHallActionConnection>;
  /** All student terms in the org. */
  studentTerms?: Maybe<StudentTermConnection>;
  /** All student years in the org. */
  studentYears?: Maybe<StudentYearConnection>;
  /** All students in the org. */
  students?: Maybe<StudentConnection>;
  /** All Study Halls Actions (e.g. checkins) for an organization */
  studyHallActions?: Maybe<StudyHallActionConnection>;
  /** All Study Halls for an organization */
  studyHalls?: Maybe<StudyHallConnection>;
  /** All subjects in the org. */
  subjects?: Maybe<SubjectConnection>;
  /** Lookup a team by id. */
  team?: Maybe<TeamNode>;
  teamMembers?: Maybe<TeamMemberConnection>;
  /** The modules a team has access to. */
  teamModules?: Maybe<TeamModuleConnection>;
  /** Team-Scoped Attribute Categories */
  teamProfileAttributeCategories?: Maybe<AttributeCategoryConnection>;
  /** All team attributes the current user has access to. */
  teamProfileAttributes?: Maybe<ProfileAttributeConnection>;
  /** a list of team roster reports for this user */
  teamRosterReport?: Maybe<TeamRosterReportConnection>;
  /** All Team Rosters for an org */
  teamRosters?: Maybe<TeamRosterConnection>;
  /** Team-Scoped YearlyAttribute Categories */
  teamYearlyAttributeCategories?: Maybe<YearlyAttributeCategoryConnection>;
  /** All teams on the org */
  teams?: Maybe<TeamConnection>;
  /** All terms in the org. */
  terms?: Maybe<TermConnection>;
  /** All ticketing blocked guests in the org */
  ticketingBlockedGuests?: Maybe<TicketingBlockedGuestConnection>;
  ticketingEventMobileDashboard?: Maybe<TicketingEventMobileDashboardArmsNode>;
  /** All ticketing event persons in this event */
  ticketingEventPersons?: Maybe<TicketingEventPersonConnection>;
  /** All ticketing events this person has access to */
  ticketingEvents?: Maybe<TicketingEventConnection>;
  /** Export guest information */
  ticketingGuestInformationExport?: Maybe<TicketingGuestInformationExportObjectType>;
  /** All ticketing guests for this person */
  ticketingGuests?: Maybe<TicketingGuestConnection>;
  /** Returns the Ticketing Org Setting for the passed in Org. */
  ticketingOrgSetting?: Maybe<TicketingOrgSettingObjectType>;
  /** Export of current reservations for a ticketing event */
  ticketingReservationExport?: Maybe<TicketingReservationsExportObjectType>;
  /** A list of ticketing reservation reports for this user */
  ticketingReservationReport?: Maybe<TicketingReservationReportConnection>;
  /** All tmp activity log config dates. */
  tmpActivityLogDatesList?: Maybe<TmpActivityLogDatesListConnection>;
  /** Returns the TMP Calendar Overview for the selected athlete. */
  tmpCalendarOverviewAthlete?: Maybe<Array<Maybe<TmpOverviewApptNode>>>;
  /** Returns the TMP Calendar Overview for the selected athlete. */
  tmpCalendarOverviewDeclarations?: Maybe<Array<Maybe<TmpDeclarationsOverviewNode>>>;
  /** Returns the Legend info for the TMP Calendar Overview. */
  tmpCalendarOverviewLegend?: Maybe<Array<Maybe<TmpOverviewLegendNode>>>;
  /** Appointment compliance audit records for an org. */
  tmpChangeHistory?: Maybe<ComplianceAuditConnection>;
  /** Conflicts for TMP/CARA enabled rules for a given appointment */
  tmpConflicts?: Maybe<Array<Maybe<Conflict>>>;
  /** All TMP date types. */
  tmpDateTypes?: Maybe<TmpDateTypeConnection>;
  /** All TMP dates for a given tmp year. */
  tmpDates?: Maybe<TmpDateConnection>;
  /** All tmp logs and it's data for a given year, team and date range. */
  tmpLogList?: Maybe<TmpActivityLogListConnection>;
  /** Returns the Time Management Plan for selected segments. */
  tmpTeamPlan?: Maybe<TmpTeamPlanNode>;
  /** All tmp team segments for an org. */
  tmpTeamSegment?: Maybe<TmpTeamSegmentConnection>;
  /** All tmp team segment info for a given segment. */
  tmpTeamSegmentConflict?: Maybe<TmpTeamSegmentConflictConnection>;
  /** All tmp teams settings for an org for a year and team. */
  tmpTeamSettings?: Maybe<TmpTeamSettingsConnection>;
  /** All tmp teams for an org. */
  tmpTeams?: Maybe<TmpTeamConnection>;
  /** All rules for a given tmp year. */
  tmpYearRules?: Maybe<TmpYearRuleConnection>;
  /** All Tmp years and teams for an org. */
  tmpYears?: Maybe<TmpYearConnection>;
  travelCredentialAssignments?: Maybe<TravelCredentialAssignmentConnection>;
  travelCredentialOptions?: Maybe<TravelCredentialConnection>;
  /** Configurations for travel document formats per team */
  travelDocumentConfigs?: Maybe<TravelDocumentConfigNodeConnection>;
  travelDressOptions?: Maybe<TravelDressConnection>;
  /** All travel event types. */
  travelEventTypes?: Maybe<TravelEventTypeNodeConnection>;
  /** All events the current user has access to. */
  travelEvents?: Maybe<TravelEventNodeConnection>;
  travelParticipation?: Maybe<TravelParticipationConnection>;
  /** A list of travel participation reports for this user */
  travelParticipationReport?: Maybe<TravelParticipationReportConnection>;
  /** All travel tasks for a trip. */
  travelTask?: Maybe<TravelTaskNodeConnection>;
  /** All seatplans for a given trip */
  tripSeatplans?: Maybe<TravelSeatplanConnection>;
  /** All trips for the current team, provided the user has viewer or manage travel permission */
  trips?: Maybe<TripConnection>;
  /** All booked tutoring appointments for the provided selections in any of the provided datetime ranges */
  tutoringAppointmentRanges?: Maybe<TutoringAppointmentConnection>;
  /** All tutoring appointments for the provided selections and date range that are visible to the current user. */
  tutoringAppointments?: Maybe<TutoringAppointmentConnection>;
  /** All tutors in the org. */
  tutors?: Maybe<TutorConnection>;
  /** User File Video Actions */
  uniqueFileVideoNameList?: Maybe<UserFileVideoActionNodeConnection>;
  /** Stream unread counts by Person ID for a list of Stream User IDs */
  unreadCounts?: Maybe<UnreadCountResponseConnection>;
  /** Count of unread conversation messages current_user has across all active person records */
  unreadMessageCount?: Maybe<Array<Maybe<PersonToUnreadCountObjectType>>>;
  /** Returns upcoming tutoring sessions for the next 7 days */
  upcomingSessions?: Maybe<Array<Maybe<UpcomingSessionsSchema>>>;
  userAccounts: Array<UserAccount>;
  userFormStats?: Maybe<UserFormStatsNode>;
  /** Org-Scoped user history changelog */
  userHistoryChangelog?: Maybe<UserHistoryChangelogConnection>;
  /** Org-Scoped user history teams */
  userHistoryTeams?: Maybe<UserHistoryChangelogConnection>;
  userRegistrationPerson?: Maybe<PersonNode>;
  userSsoUrl?: Maybe<Scalars['String']>;
  validatedCodes?: Maybe<ValidatedCodesOutput>;
  /** All vendor groups the current user has access to. */
  vendorGroups?: Maybe<TravelVendorGroupConnection>;
  /** All vendor types. */
  vendorTypes?: Maybe<TravelVendorTypeNodeConnection>;
  /** All vendors the current user has access to. */
  vendors?: Maybe<VendorConnection>;
  /** An individual Study Hall accessible by an external checkins app with verified Time-based OTP if required */
  verifiedExternalStudyHall?: Maybe<StudyHallConnection>;
  /** The viewer represents the currently authenticated user */
  viewer?: Maybe<Viewer>;
  /** A list of yearly attribute reports requested by this user */
  yearlyAttributeReport?: Maybe<YearlyAttributeReportConnection>;
  /** Attribute values of a person for a given year */
  yearlyAttributeValuesForYear?: Maybe<YearlyAttributeValueConnection>;
};

export type QueryActivitiesArgs = {
  action?: InputMaybe<Scalars['TWFilter_String']>;
  activityDate?: InputMaybe<Scalars['TWFilter_String']>;
  activityDatetime?: InputMaybe<Scalars['TWFilter_String']>;
  activityId?: InputMaybe<Scalars['TWFilter_ID']>;
  activityType?: InputMaybe<Scalars['TWFilter_String']>;
  activityTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isViewed?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  objectId?: InputMaybe<Scalars['TWFilter_Int']>;
  objectType?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  subject?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryAdminActionsArgs = {
  action?: InputMaybe<Scalars['TWFilter_String']>;
  adminActionId?: InputMaybe<Scalars['TWFilter_ID']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAsync?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  log?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parameters?: InputMaybe<Scalars['TWFilter_JSONString']>;
  pctCompleted?: InputMaybe<Scalars['TWFilter_Float']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AdminActionSortEnum>>>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryAdvisorsArgs = {
  advisorId?: InputMaybe<Scalars['TWFilter_ID']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  preferredName?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AdvisorSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryAllAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentComplianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  appointmentOccurrenceId?: InputMaybe<Scalars['Int']>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  apptDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  before?: InputMaybe<Scalars['String']>;
  countableEndHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableEndMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableMinutes?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  daysFromBase?: InputMaybe<Scalars['TWFilter_Int']>;
  enableCheckins?: InputMaybe<Scalars['TWFilter_Boolean']>;
  enableRsvps?: InputMaybe<Scalars['TWFilter_Boolean']>;
  endDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endDateTime: Scalars['DateTime'];
  endDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  endHour?: InputMaybe<Scalars['TWFilter_Int']>;
  endMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  endTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  externalAppointmentUid?: InputMaybe<Scalars['TWFilter_String']>;
  externalCalendarAccountId?: InputMaybe<Scalars['TWFilter_Int']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  facilityId?: InputMaybe<Scalars['TWFilter_String']>;
  facilityName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAllDay?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isAvailability?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isBookedSlot?: InputMaybe<Scalars['TWFilter_String']>;
  isExternalAppointment?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isMandatory?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrivate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSlot?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  occurrenceCalculationEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  recurEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurRule?: InputMaybe<Scalars['TWFilter_String']>;
  recurStart?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurType?: InputMaybe<Scalars['TWFilter_Int']>;
  selections?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendRsvpAlerts?: InputMaybe<Scalars['TWFilter_String']>;
  slotParentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  startDateTime: Scalars['DateTime'];
  startDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  startHour?: InputMaybe<Scalars['TWFilter_Int']>;
  startMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  startTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  timeZone?: InputMaybe<Scalars['TWFilter_String']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  var1?: InputMaybe<Scalars['TWFilter_Int']>;
  var2?: InputMaybe<Scalars['TWFilter_String']>;
  var3?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryAllBulletinsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulletinId?: InputMaybe<Scalars['TWFilter_ID']>;
  bulletinType?: InputMaybe<Scalars['TWFilter_BulletinType']>;
  buttonText?: InputMaybe<Scalars['TWFilter_String']>;
  content?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  creatorApp?: InputMaybe<Scalars['TWFilter_String']>;
  customSort?: InputMaybe<BulletinCustomSortEnum>;
  expirationDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  externalUrl?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  headerImageUuid?: InputMaybe<Scalars['TWFilter_String']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPinned?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  linkPreviewHash?: InputMaybe<Scalars['TWFilter_String']>;
  messageId?: InputMaybe<Scalars['TWFilter_Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  org?: InputMaybe<Scalars['TWFilter_String']>;
  orgAccessible?: InputMaybe<Scalars['TWFilter_Boolean']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  scheduledDatetime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  sort?: InputMaybe<Array<InputMaybe<BulletinSortEnum>>>;
  streamMessageId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryAllCustomTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  alertNotificationType?: InputMaybe<Scalars['TWFilter_AlertNotificationType']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customTaskId?: InputMaybe<Scalars['TWFilter_ID']>;
  customTaskType?: InputMaybe<Scalars['TWFilter_CustomTaskType']>;
  dueDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  expirationDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<CustomTaskSortEnum>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['TWFilter_CustomTaskStatus']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryAllPersonsArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  automobileId?: InputMaybe<Scalars['TWFilter_Int']>;
  before?: InputMaybe<Scalars['String']>;
  calendarAlertDefault?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['TWFilter_String']>;
  directReportPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  displayName?: InputMaybe<Scalars['TWFilter_String']>;
  driversLicenseExpirationDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  driversLicenseNumber?: InputMaybe<Scalars['TWFilter_String']>;
  driversLicenseState?: InputMaybe<Scalars['TWFilter_String']>;
  driversLicenseStateId?: InputMaybe<Scalars['TWFilter_Int']>;
  emailAddress?: InputMaybe<Scalars['TWFilter_String']>;
  emailAddressPersonal?: InputMaybe<Scalars['TWFilter_String']>;
  excludeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
  facebookAccount?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['TWFilter_String']>;
  fullName?: InputMaybe<Scalars['TWFilter_String']>;
  fullNameFirstInitial?: InputMaybe<Scalars['TWFilter_String']>;
  fullNameFirstInitialPreferred?: InputMaybe<Scalars['TWFilter_String']>;
  fullNameNormalOrder?: InputMaybe<Scalars['TWFilter_String']>;
  fullNameWithMiddle?: InputMaybe<Scalars['TWFilter_String']>;
  fullNameWithMiddleNormalOrder?: InputMaybe<Scalars['TWFilter_String']>;
  fullPreferredName?: InputMaybe<Scalars['TWFilter_String']>;
  gender?: InputMaybe<Scalars['TWFilter_String']>;
  hasLoginAccess?: InputMaybe<Scalars['TWFilter_Boolean']>;
  hasMobileBeenValidated?: InputMaybe<Scalars['TWFilter_Boolean']>;
  hasMobileToken?: InputMaybe<Scalars['TWFilter_String']>;
  isContact?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isOrgLevelUser?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastAndFirstName?: InputMaybe<Scalars['TWFilter_String']>;
  lastLoginDate?: InputMaybe<Scalars['TWFilter_String']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  lastSelectedTeamId?: InputMaybe<Scalars['TWFilter_String']>;
  loginCount?: InputMaybe<Scalars['TWFilter_String']>;
  maritalStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  middleName?: InputMaybe<Scalars['TWFilter_String']>;
  nickname?: InputMaybe<Scalars['TWFilter_String']>;
  nicknameFormatted?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  personId?: InputMaybe<Scalars['TWFilter_ID']>;
  phoneCell?: InputMaybe<Scalars['TWFilter_String']>;
  phoneFax?: InputMaybe<Scalars['TWFilter_String']>;
  phoneHome?: InputMaybe<Scalars['TWFilter_String']>;
  phoneOffice?: InputMaybe<Scalars['TWFilter_String']>;
  phoneTeamworks?: InputMaybe<Scalars['TWFilter_String']>;
  picture?: InputMaybe<Scalars['TWFilter_String']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pictureImage?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  preferredName?: InputMaybe<Scalars['TWFilter_String']>;
  schoolIdentifier?: InputMaybe<Scalars['TWFilter_String']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  suffix?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['Int']>;
  teamLabels?: InputMaybe<Scalars['TWFilter_String']>;
  timeZone?: InputMaybe<Scalars['TWFilter_String']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  travelWeight?: InputMaybe<Scalars['TWFilter_Int']>;
  twitterAccount?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  userTypesByTeam?: InputMaybe<Scalars['TWFilter_JSONString']>;
  usesExternalAuthentication?: InputMaybe<Scalars['TWFilter_Boolean']>;
};

export type QueryAllSharedFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  announcementId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentComplianceAuditReviewId?: InputMaybe<Scalars['TWFilter_Int']>;
  before?: InputMaybe<Scalars['String']>;
  canDownload?: InputMaybe<Scalars['TWFilter_Boolean']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  fileName?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileNotes?: InputMaybe<Scalars['TWFilter_String']>;
  fileSize?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['TWFilter_Int']>;
  isPinned?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  officiatingSchoolId?: InputMaybe<Scalars['TWFilter_Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sharedFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<Array<InputMaybe<SharedFileSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelVendorId?: InputMaybe<Scalars['TWFilter_Int']>;
  tripId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  videoId?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryAllTeamMemberPermissionsArgs = {
  PermissionRestrictions?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  permissionId?: InputMaybe<Scalars['TWFilter_Int']>;
  permissionRestrictions?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamMemberPermissionSortEnum>>>;
  teamMemberId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamMemberPermissionId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryAllTeamProfileAttributeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  attributeCategoryId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  coreCode?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCustom?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableProfile?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableReport?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  selfManageUsers?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AttributeCategorySortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryAllTeamsArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  branding?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  institution?: InputMaybe<Scalars['TWFilter_String']>;
  institutionId?: InputMaybe<Scalars['TWFilter_Int']>;
  isUserChangeAlertEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  logoImage?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  settings?: InputMaybe<Scalars['TWFilter_JSONString']>;
  shortLabel?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamSortEnum>>>;
  sportId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  userChangeAlertEmail?: InputMaybe<Scalars['TWFilter_String']>;
  voiceGreeting?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryAllUserFileVideoActionsArgs = {
  actionDatetime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  actionType?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  assetType?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  platform?: InputMaybe<Scalars['TWFilter_String']>;
  sharedFileId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<UserFileVideoActionSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  userFileVideoActionId?: InputMaybe<Scalars['TWFilter_ID']>;
  videoId?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryAppointmentCheckinArgs = {
  appointmentCheckinId?: InputMaybe<Scalars['UUID']>;
};

export type QueryAppointmentCheckinOccurrenceArgs = {
  apptCheckinOccurrenceId?: InputMaybe<Scalars['UUID']>;
};

export type QueryAppointmentCheckinQrCodeArgs = {
  apptCheckinOccurrenceId?: InputMaybe<Scalars['UUID']>;
};

export type QueryAppointmentSelectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentComplianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  appointmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  apptDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  before?: InputMaybe<Scalars['String']>;
  countableEndHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableEndMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableMinutes?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  daysFromBase?: InputMaybe<Scalars['TWFilter_Int']>;
  enableCheckins?: InputMaybe<Scalars['TWFilter_Boolean']>;
  enableRsvps?: InputMaybe<Scalars['TWFilter_Boolean']>;
  endDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  endHour?: InputMaybe<Scalars['TWFilter_Int']>;
  endMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  endTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  externalAppointmentUid?: InputMaybe<Scalars['TWFilter_String']>;
  externalCalendarAccountId?: InputMaybe<Scalars['TWFilter_Int']>;
  facilityId?: InputMaybe<Scalars['TWFilter_String']>;
  facilityName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAllDay?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isAvailability?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isBookedSlot?: InputMaybe<Scalars['TWFilter_String']>;
  isExternalAppointment?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isMandatory?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrivate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSlot?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  occurrenceCalculationEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  recurEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurRule?: InputMaybe<Scalars['TWFilter_String']>;
  recurStart?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurType?: InputMaybe<Scalars['TWFilter_Int']>;
  sendRsvpAlerts?: InputMaybe<Scalars['TWFilter_String']>;
  slotParentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  startDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  startHour?: InputMaybe<Scalars['TWFilter_Int']>;
  startMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  startTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  timeZone?: InputMaybe<Scalars['TWFilter_String']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  var1?: InputMaybe<Scalars['TWFilter_Int']>;
  var2?: InputMaybe<Scalars['TWFilter_String']>;
  var3?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryAppointmentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentComplianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isEditable?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSelectable?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isTravel?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentTypeSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryBulkTmpConflictsArgs = {
  occurrenceChecks: Array<InputMaybe<BulkAppointmentConflictInput>>;
};

export type QueryBulletinClicksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulletinClickId?: InputMaybe<Scalars['TWFilter_String']>;
  bulletinId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sessionId?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<BulletinClickSortEnum>>>;
  source?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryBulletinsSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  birthdaysAnnouncementCodes?: InputMaybe<Scalars['TWFilter_StringArray']>;
  birthdaysVisibilityCodes?: InputMaybe<Scalars['TWFilter_StringArray']>;
  bulletinsSettingsId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isBirthdaysEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  org?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<BulletinsSettingsSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCalendarReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  calendarReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  downloadableFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  errorMessage?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileType?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  url?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCalsyncAccountsArgs = {
  providerId?: InputMaybe<Scalars['String']>;
};

export type QueryCheckinArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  checkinId?: InputMaybe<Scalars['TWFilter_ID']>;
  chkCheckinTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isCheckinRequired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCheckoutRequired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isExpired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchString?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCheckinActionArgs = {
  accumulatedMinutes?: InputMaybe<Scalars['TWFilter_String']>;
  accumulatedSeconds?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  checkinActionId?: InputMaybe<Scalars['TWFilter_ID']>;
  checkinTime?: InputMaybe<Scalars['TWFilter_String']>;
  checkoutTime?: InputMaybe<Scalars['TWFilter_String']>;
  chkCheckinId?: InputMaybe<Scalars['TWFilter_Int']>;
  chkCheckinTimeRangeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['TWFilter_JSONString']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CheckinActionSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCheckinsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  checkinAfter?: InputMaybe<Scalars['TWFilter_Int']>;
  checkinAttendeeCodes?: InputMaybe<Scalars['TWFilter_String']>;
  checkinBefore?: InputMaybe<Scalars['TWFilter_Int']>;
  checkinDataId?: InputMaybe<Scalars['TWFilter_ID']>;
  checkinDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  checkins?: InputMaybe<Scalars['TWFilter_JSONString']>;
  checkoutAfter?: InputMaybe<Scalars['TWFilter_Int']>;
  checkoutBefore?: InputMaybe<Scalars['TWFilter_Int']>;
  checkoutDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  checkoutOnBehalfCodes?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isCheckinRequired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCheckoutRequired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCurrent?: InputMaybe<Scalars['TWFilter_String']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isExpired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchString?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<CheckinDataSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryConversationTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<ConversationTypeSortEnum>>>;
};

export type QueryConversationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['TWFilter_String']>;
  conversationTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastMessageDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  lastMessageId?: InputMaybe<Scalars['TWFilter_Int']>;
  lastMessageText?: InputMaybe<Scalars['TWFilter_String']>;
  onlyUnreadConversations?: InputMaybe<Scalars['Boolean']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  picture?: InputMaybe<Scalars['TWFilter_String']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchConversations?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<ConversationSortEnum>>>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  iso?: InputMaybe<Scalars['TWFilter_ID']>;
  iso3?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  numcode?: InputMaybe<Scalars['TWFilter_String']>;
  printableName?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCourseSectionsArgs = {
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseBulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  courseBulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseChecksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseCourseId?: InputMaybe<Scalars['TWFilter_ID']>;
  courseCreatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  courseCreatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  courseCreatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  courseCredits?: InputMaybe<Scalars['TWFilter_String']>;
  courseCustomerKey?: InputMaybe<Scalars['TWFilter_String']>;
  courseDescriptionXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  courseId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseIsDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  courseIsGeneral?: InputMaybe<Scalars['TWFilter_Boolean']>;
  courseIsPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  courseLabelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  courseOrgId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseOwner?: InputMaybe<Scalars['TWFilter_String']>;
  courseOwnerId?: InputMaybe<Scalars['TWFilter_String']>;
  coursePk?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_ID']>;
  courseSectionSchedulesBulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesBulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseSectionSchedulesChecksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesCourseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseSectionSchedulesCourseSectionScheduleId?: InputMaybe<Scalars['TWFilter_ID']>;
  courseSectionSchedulesCreatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  courseSectionSchedulesCreatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  courseSectionSchedulesCreatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesCustomerKey?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesDay?: InputMaybe<Scalars['TWFilter_DaysOfWeekEnum']>;
  courseSectionSchedulesEndTime?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesIsPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  courseSectionSchedulesLocation?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesOrgId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseSectionSchedulesOwner?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesOwnerId?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesPk?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesStartTime?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionSchedulesUpdatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  courseSectionSchedulesUpdatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  courseSectionSchedulesUpdatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  courseSubjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseUpdatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  courseUpdatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  courseUpdatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  eligibilityYearIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  enableNameSort?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsBulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsBulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  enrollmentsChecksum?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsCourseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  enrollmentsCreatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  enrollmentsCreatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  enrollmentsCreatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsCreditsAttempted?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsCustomerKey?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsDroppedDate?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsEnrollmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  enrollmentsGradeId?: InputMaybe<Scalars['TWFilter_Int']>;
  enrollmentsIsDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  enrollmentsIsPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  enrollmentsOrgId?: InputMaybe<Scalars['TWFilter_Int']>;
  enrollmentsOwner?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsOwnerId?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsPk?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentsScore?: InputMaybe<Scalars['TWFilter_Float']>;
  enrollmentsStudentTermId?: InputMaybe<Scalars['TWFilter_Int']>;
  enrollmentsUpdatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  enrollmentsUpdatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  enrollmentsUpdatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAtRiskReport?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isOnline?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorBulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  professorBulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  professorChecksum?: InputMaybe<Scalars['TWFilter_String']>;
  professorCreatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  professorCreatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  professorCreatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  professorCustomerKey?: InputMaybe<Scalars['TWFilter_String']>;
  professorEmailAddress?: InputMaybe<Scalars['TWFilter_String']>;
  professorFirstName?: InputMaybe<Scalars['TWFilter_String']>;
  professorFullName?: InputMaybe<Scalars['TWFilter_String']>;
  professorId?: InputMaybe<Scalars['TWFilter_Int']>;
  professorIsActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  professorIsPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  professorLastName?: InputMaybe<Scalars['TWFilter_String']>;
  professorOfficeLocation?: InputMaybe<Scalars['TWFilter_String']>;
  professorOrgId?: InputMaybe<Scalars['TWFilter_Int']>;
  professorOwner?: InputMaybe<Scalars['TWFilter_String']>;
  professorOwnerId?: InputMaybe<Scalars['TWFilter_String']>;
  professorPhoneNumber?: InputMaybe<Scalars['TWFilter_String']>;
  professorPk?: InputMaybe<Scalars['TWFilter_String']>;
  professorProfessorId?: InputMaybe<Scalars['TWFilter_ID']>;
  professorSurveyAssignmentStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  professorSurveyId?: InputMaybe<Scalars['ID']>;
  professorSurveyReportTeamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  professorUpdatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  professorUpdatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  professorUpdatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  searchCourseSections?: InputMaybe<Scalars['String']>;
  shortCode?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CourseSectionSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  studentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  termBulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  termBulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  termChecksum?: InputMaybe<Scalars['TWFilter_String']>;
  termCreatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  termCreatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  termCreatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  termCustomerKey?: InputMaybe<Scalars['TWFilter_String']>;
  termEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  termIsPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  termLabelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  termOrgId?: InputMaybe<Scalars['TWFilter_Int']>;
  termOwner?: InputMaybe<Scalars['TWFilter_String']>;
  termOwnerId?: InputMaybe<Scalars['TWFilter_String']>;
  termPk?: InputMaybe<Scalars['TWFilter_String']>;
  termStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  termTermId?: InputMaybe<Scalars['TWFilter_ID']>;
  termUpdatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  termUpdatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  termUpdatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  credits?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  descriptionXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  includeGeneral?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isGeneral?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CourseSortEnum>>>;
  studentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  subjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  termIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCustomGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  creator?: InputMaybe<Scalars['String']>;
  customGroupId?: InputMaybe<Scalars['TWFilter_ID']>;
  first?: InputMaybe<Scalars['Int']>;
  groupIsPublic?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  selections?: InputMaybe<Scalars['TWFilter_JSONString']>;
  sort?: InputMaybe<Array<InputMaybe<CustomGroupSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCustomTaskAssignmentsExportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customTaskAssignmentsExportId?: InputMaybe<Scalars['TWFilter_ID']>;
  downloadableFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  errorMessage?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileType?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  url?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryCustomTaskLabelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customTaskLabelId?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CustomTaskLabelSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryDeliveriesArgs = {
  after?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids: Array<InputMaybe<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryDigitalDisplaysArgs = {
  Settings?: InputMaybe<Scalars['TWFilter_JSONString']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  digitalDisplayId?: InputMaybe<Scalars['TWFilter_ID']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  settings?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<DigitalDisplaySortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  token?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  uuid?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryEligibilityYearsArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEnrollmentAttendancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentOccurrenceId?: InputMaybe<Scalars['TWFilter_Int']>;
  attendanceStatus?: InputMaybe<Scalars['TWFilter_EnrollmentAttendanceStatus']>;
  before?: InputMaybe<Scalars['String']>;
  checkinResultId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentAttendanceId?: InputMaybe<Scalars['TWFilter_ID']>;
  enrollmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  occurrenceEndDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  occurrenceStartDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reason?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<EnrollmentAttendanceSortEnum>>>;
  studentTermIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryExternalAccessLinkArgs = {
  uuid: Scalars['String'];
};

export type QueryExternalCompletionRoleArgs = {
  uuid: Scalars['String'];
};

export type QueryExternalFormAssignmentArgs = {
  uuid: Scalars['String'];
};

export type QueryExternalProfessorSurveyAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  completedDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  externalAccessLinkId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorSurveyAssignmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  professorSurveyId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<ProfessorSurveyAssignmentSortEnum>>>;
  status?: InputMaybe<Scalars['TWFilter_ProfessorSurveyAssignmentStatus']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryExternalStudyHallArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  canEditDatetimes?: InputMaybe<Scalars['TWFilter_Boolean']>;
  checkinAppToken?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  externalCheckinAppToken: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isExpired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  periodRecurrence?: InputMaybe<Scalars['TWFilter_JSONString']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  rotateQrCode?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<StudyHallSortEnum>>>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  studyHallId?: InputMaybe<Scalars['TWFilter_ID']>;
  totp?: InputMaybe<Scalars['TWFilter_String']>;
  totpSecret?: InputMaybe<Scalars['TWFilter_String']>;
  totpTimeToLive?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryExternalVideoArgs = {
  uuid: Scalars['String'];
};

export type QueryFeatureFlagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  blanketOnOffNull?: InputMaybe<Scalars['TWFilter_Boolean']>;
  code?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  doc?: InputMaybe<Scalars['TWFilter_String']>;
  featureFlagId?: InputMaybe<Scalars['TWFilter_ID']>;
  filters?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  flagType?: InputMaybe<Scalars['TWFilter_FeatureFlagType']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<FeatureFlagSortEnum>>>;
  ticketId?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  warnAfter?: InputMaybe<Scalars['TWFilter_DateTime']>;
};

export type QueryFetchOverlayEventsArgs = {
  endDate: Scalars['DateTime'];
  selectionCodes: Array<InputMaybe<Scalars['String']>>;
  startDate: Scalars['DateTime'];
};

export type QueryFileDownloadUrlArgs = {
  fileName: Scalars['String'];
  fileNameServer: Scalars['String'];
  orgId: Scalars['Int'];
};

export type QueryFilterFormCompletionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  formAssignmentId: Scalars['ID'];
  generatePdfs?: InputMaybe<Scalars['Boolean']>;
  hasAccessibleAnswers?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastUpdatedDateEnd?: InputMaybe<Scalars['Date']>;
  lastUpdatedDateStart?: InputMaybe<Scalars['Date']>;
  manuallyCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchAssignees?: InputMaybe<Scalars['String']>;
  searchInAnswers?: InputMaybe<Scalars['String']>;
  searchOptions?: InputMaybe<Array<InputMaybe<SearchOptionsEnum>>>;
  sort?: InputMaybe<Array<InputMaybe<FormAssignmentCompletionSortEnum>>>;
  teamId?: InputMaybe<Scalars['Int']>;
};

export type QueryFindTutorAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentComplianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  apptDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  before?: InputMaybe<Scalars['String']>;
  countableEndHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableEndMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableMinutes?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  daysFromBase?: InputMaybe<Scalars['TWFilter_Int']>;
  enableCheckins?: InputMaybe<Scalars['TWFilter_Boolean']>;
  enableRsvps?: InputMaybe<Scalars['TWFilter_Boolean']>;
  endDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endDateTime: Scalars['DateTime'];
  endDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  endHour?: InputMaybe<Scalars['TWFilter_Int']>;
  endMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  endTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  externalAppointmentUid?: InputMaybe<Scalars['TWFilter_String']>;
  externalCalendarAccountId?: InputMaybe<Scalars['TWFilter_Int']>;
  facilityId?: InputMaybe<Scalars['TWFilter_String']>;
  facilityName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAllDay?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isAvailability?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isBookedSlot?: InputMaybe<Scalars['TWFilter_String']>;
  isExternalAppointment?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isMandatory?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrivate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSlot?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  occurrenceCalculationEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  recurEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurRule?: InputMaybe<Scalars['TWFilter_String']>;
  recurStart?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurType?: InputMaybe<Scalars['TWFilter_Int']>;
  selections: Array<InputMaybe<Scalars['String']>>;
  sendRsvpAlerts?: InputMaybe<Scalars['TWFilter_String']>;
  slotParentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  startDateTime: Scalars['DateTime'];
  startDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  startHour?: InputMaybe<Scalars['TWFilter_Int']>;
  startMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  startTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  timeZone?: InputMaybe<Scalars['TWFilter_String']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  var1?: InputMaybe<Scalars['TWFilter_Int']>;
  var2?: InputMaybe<Scalars['TWFilter_String']>;
  var3?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryFoldersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['TWFilter_ID']>;
  folderName?: InputMaybe<Scalars['TWFilter_String']>;
  folderType?: InputMaybe<Scalars['TWFilter_String']>;
  isEditableByUser?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentFolderId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<FolderSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  videoFolderId?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryFormAssignmentCompletionAnswersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  formAssignmentCompletionId: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<FormAssignmentCompletionAnswerSortEnum>>>;
};

export type QueryFormAssignmentCompletionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  formAssignmentId: Scalars['ID'];
  hasAccessibleAnswers?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastUpdatedDateEnd?: InputMaybe<Scalars['Date']>;
  lastUpdatedDateStart?: InputMaybe<Scalars['Date']>;
  manuallyCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchInAnswers?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<FormAssignmentCompletionSortEnum>>>;
  teamId?: InputMaybe<Scalars['Int']>;
};

export type QueryFormAssignmentsAssignersArgs = {
  assignmentType?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isMyForms?: InputMaybe<Scalars['Boolean']>;
  isScheduled?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFormResultsReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  downloadableFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  errorMessage?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileType?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  formResultsReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  kind?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  url?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryFormTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  formType?: InputMaybe<FormTypeEnum>;
  inManagableAssignments?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<FormSortEnum>>>;
};

export type QueryGetOverlayEventArgs = {
  eventId: Scalars['String'];
  requestId: Scalars['String'];
};

export type QueryGetOverlayEventHubAttendeeSelectionsArgs = {
  selectionCodes: Array<InputMaybe<Scalars['String']>>;
};

export type QueryGetOverlayEventsArgs = {
  eventIds: Array<InputMaybe<Scalars['String']>>;
  requestId: Scalars['String'];
};

export type QueryGradesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['TWFilter_ID']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<GradeSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  weight?: InputMaybe<Scalars['TWFilter_Float']>;
};

export type QueryGradesDefaultArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['TWFilter_ID']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<GradeSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  weight?: InputMaybe<Scalars['TWFilter_Float']>;
};

export type QueryGroupsArgs = {
  filters?: InputMaybe<SelectionGroupFiltersInput>;
};

export type QueryInstitutionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['TWFilter_String']>;
  conference?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  division?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  institutionId?: InputMaybe<Scalars['TWFilter_ID']>;
  institutionSearch?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  leagueId?: InputMaybe<Scalars['TWFilter_Int']>;
  nickname?: InputMaybe<Scalars['TWFilter_String']>;
  orgName?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  stadiumName?: InputMaybe<Scalars['TWFilter_String']>;
  state?: InputMaybe<Scalars['TWFilter_String']>;
  subdivision?: InputMaybe<Scalars['TWFilter_String']>;
  timeZoneId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryListMessageSendersArgs = {
  after?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  returnSentMessages?: InputMaybe<Scalars['Boolean']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
};

export type QueryListMessagesArgs = {
  after?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  filterByDeliveryPath?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterBySender?: InputMaybe<Scalars['Int']>;
  filterByTeams?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  filterByUtcDateRange?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  last?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  returnSentMessages?: InputMaybe<Scalars['Boolean']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
};

export type QueryManageFormAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['TWFilter_Int']>;
  formAssignmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  formId?: InputMaybe<Scalars['TWFilter_Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isScheduled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<FormAssignmentSortEnum>>>;
};

export type QueryManageFormScheduleAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['Int']>;
  formId?: InputMaybe<Scalars['TWFilter_Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<FormScheduleSortEnum>>>;
};

export type QueryMessageRecipientDeliveryStatusesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  broadStatusSearch?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  conversationId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  emailAddress?: InputMaybe<Scalars['TWFilter_String']>;
  emailDeliveryDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  emailDeliveryDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  emailDeliveryStatus?: InputMaybe<Scalars['TWFilter_String']>;
  emailFirstOpenedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  emailFirstOpenedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isPushMessage?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  messageId?: InputMaybe<Scalars['TWFilter_Int']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  phoneMobile?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  rawEmailDeliveryStatus?: InputMaybe<Scalars['TWFilter_String']>;
  rawTextDeliveryStatus?: InputMaybe<Scalars['TWFilter_String']>;
  rawVoiceDeliveryStatus?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  smsFirstReadDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  smsFirstReadDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  textDeliveryStatus?: InputMaybe<Scalars['TWFilter_String']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  voiceDeliveryStatus?: InputMaybe<Scalars['TWFilter_String']>;
  voiceMessageAcknowledged?: InputMaybe<Scalars['TWFilter_Boolean']>;
  voiceMessageCallDuration?: InputMaybe<Scalars['TWFilter_Int']>;
  voiceMessageCallsid?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryMessageSendersArgs = {
  hasBeenProcessed?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  deliveryTypes?: InputMaybe<Scalars['TWFilter_String']>;
  deliveryTypesFilt?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  emailSubject?: InputMaybe<Scalars['TWFilter_String']>;
  emailText?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasBeenProcessed?: InputMaybe<Scalars['TWFilter_Boolean']>;
  includeConversationMessages?: InputMaybe<Scalars['Boolean']>;
  isRichText?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  messageType?: InputMaybe<Scalars['TWFilter_String']>;
  mmsAttachments?: InputMaybe<Scalars['TWFilter_String']>;
  numEmailMessages?: InputMaybe<Scalars['TWFilter_Int']>;
  numPushMessages?: InputMaybe<Scalars['TWFilter_Int']>;
  numSmsMessages?: InputMaybe<Scalars['TWFilter_Int']>;
  numVoiceMessages?: InputMaybe<Scalars['TWFilter_Int']>;
  numVoiceRecorded?: InputMaybe<Scalars['TWFilter_Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  pushText?: InputMaybe<Scalars['TWFilter_String']>;
  scheduledDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  scheduledDateTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  sendEmail?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sendPersonalSms?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sendPush?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sendSms?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sendVoice?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sendVoiceDeliveryReportEmail?: InputMaybe<Scalars['TWFilter_Boolean']>;
  senderName?: InputMaybe<Scalars['TWFilter_String']>;
  sentDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  smsPages?: InputMaybe<Scalars['TWFilter_Int']>;
  smsText?: InputMaybe<Scalars['TWFilter_String']>;
  smsTextFormatted?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<MessageSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  timeZone?: InputMaybe<Scalars['TWFilter_String']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  voiceDuration?: InputMaybe<Scalars['TWFilter_Int']>;
  voiceUrl?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryMyAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentComplianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  apptDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  before?: InputMaybe<Scalars['String']>;
  countableEndHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableEndMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableMinutes?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  daysFromBase?: InputMaybe<Scalars['TWFilter_Int']>;
  enableCheckins?: InputMaybe<Scalars['TWFilter_Boolean']>;
  enableRsvps?: InputMaybe<Scalars['TWFilter_Boolean']>;
  endDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endDateTime: Scalars['DateTime'];
  endDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  endHour?: InputMaybe<Scalars['TWFilter_Int']>;
  endMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  endTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  externalAppointmentUid?: InputMaybe<Scalars['TWFilter_String']>;
  externalCalendarAccountId?: InputMaybe<Scalars['TWFilter_Int']>;
  facilityId?: InputMaybe<Scalars['TWFilter_String']>;
  facilityName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAllDay?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isAvailability?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isBookedSlot?: InputMaybe<Scalars['TWFilter_String']>;
  isExternalAppointment?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isMandatory?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrivate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSlot?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  occurrenceCalculationEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  recurEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurRule?: InputMaybe<Scalars['TWFilter_String']>;
  recurStart?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurType?: InputMaybe<Scalars['TWFilter_Int']>;
  sendRsvpAlerts?: InputMaybe<Scalars['TWFilter_String']>;
  slotParentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  startDateTime: Scalars['DateTime'];
  startDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  startHour?: InputMaybe<Scalars['TWFilter_Int']>;
  startMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  startTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  timeZone?: InputMaybe<Scalars['TWFilter_String']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  var1?: InputMaybe<Scalars['TWFilter_Int']>;
  var2?: InputMaybe<Scalars['TWFilter_String']>;
  var3?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryMyBulletinsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulletinId?: InputMaybe<Scalars['TWFilter_ID']>;
  bulletinType?: InputMaybe<Scalars['TWFilter_BulletinType']>;
  buttonText?: InputMaybe<Scalars['TWFilter_String']>;
  content?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  creatorApp?: InputMaybe<Scalars['TWFilter_String']>;
  expirationDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  externalUrl?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  headerImageUuid?: InputMaybe<Scalars['TWFilter_String']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPinned?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  linkPreviewHash?: InputMaybe<Scalars['TWFilter_String']>;
  messageId?: InputMaybe<Scalars['TWFilter_Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  org?: InputMaybe<Scalars['TWFilter_String']>;
  orgAccessible?: InputMaybe<Scalars['TWFilter_Boolean']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  scheduledDatetime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  sort?: InputMaybe<Array<InputMaybe<BulletinSortEnum>>>;
  streamMessageId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  visibilityContext?: InputMaybe<Scalars['String']>;
  visibleTo?: InputMaybe<Scalars['String']>;
};

export type QueryMyCustomTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  alertNotificationType?: InputMaybe<Scalars['TWFilter_AlertNotificationType']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customTaskId?: InputMaybe<Scalars['TWFilter_ID']>;
  customTaskType?: InputMaybe<Scalars['TWFilter_CustomTaskType']>;
  dueDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  expirationDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<CustomTaskSortEnum>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['TWFilter_CustomTaskStatus']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  visibilityContext?: InputMaybe<Scalars['String']>;
  visibleTo?: InputMaybe<Scalars['String']>;
};

export type QueryMyFormAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedDateTimeEnd?: InputMaybe<Scalars['DateTime']>;
  assignedDateTimeStart?: InputMaybe<Scalars['DateTime']>;
  assignerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  dueDateStatus?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  personId?: InputMaybe<Scalars['ID']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  showConditional?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<FormAssignmentCompletionRoleSortEnum>>>;
  status?: InputMaybe<Array<InputMaybe<FormAssignmentCompletionRoleStatusGraphene>>>;
};

export type QueryMyMobileTripsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkCreated?: InputMaybe<Scalars['TWFilter_Boolean']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  endDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  endTimeZoneId?: InputMaybe<Scalars['TWFilter_Int']>;
  exportsLeftMargin?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isInPast?: InputMaybe<Scalars['Boolean']>;
  isVisibleMobile?: InputMaybe<Scalars['TWFilter_Boolean']>;
  itineraryCalendarVisibility?: InputMaybe<Scalars['TWFilter_String']>;
  itineraryDateTimeRange?: InputMaybe<Scalars['TWFilter_String']>;
  itineraryDateTimeRangeWithoutTripDates?: InputMaybe<Scalars['TWFilter_String']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  logoFileName?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  seasonId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<TripSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  startDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  startTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  startTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  startTimeZoneId?: InputMaybe<Scalars['TWFilter_Int']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelPartyMemberPersons?: InputMaybe<Scalars['TWFilter_String']>;
  travelPartyMembersCount?: InputMaybe<Scalars['TWFilter_String']>;
  travelPartySmartPersons?: InputMaybe<Scalars['TWFilter_String']>;
  tripId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryMyPerpetualFormAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  showConditional?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<FormAssignmentRoleSortEnum>>>;
};

export type QueryMySavedReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  filters?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDefault?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  savedReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  viewTarget?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryMySharedSavedReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  filters?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDefault?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  savedReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  viewTarget?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryMySharedTeamRosterSavedViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  filters?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDefault?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  savedReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  viewTarget?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryMyTaskAssignersArgs = {
  hasArms?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMyTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  assigners?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  before?: InputMaybe<Scalars['String']>;
  dashboardEligible?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  hasArms?: InputMaybe<Scalars['Boolean']>;
  includeAcademicTasks?: InputMaybe<Scalars['Boolean']>;
  isAcademicTask?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  ownerApp?: InputMaybe<Array<InputMaybe<TwOwnerAppFilterEnum>>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<MyTaskSortEnum>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<InputMaybe<TaskStatusFilterEnum>>>;
  withStatus?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMyTeamRosterSavedViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  filters?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDefault?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  savedReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  viewTarget?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryMyTripSeatplansArgs = {
  tripIdFilter: Scalars['ID'];
};

export type QueryMyVideosArgs = {
  Discriminator?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  canDownload?: InputMaybe<Scalars['TWFilter_Boolean']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  downloadCount?: InputMaybe<Scalars['TWFilter_Int']>;
  embedHtml?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['TWFilter_Int']>;
  isArchived?: InputMaybe<Scalars['TWFilter_String']>;
  isPinned?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  officiatingEvaluationId?: InputMaybe<Scalars['TWFilter_Int']>;
  org?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  playCount?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<VideoSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  videoId?: InputMaybe<Scalars['TWFilter_ID']>;
  visibilityContext?: InputMaybe<Scalars['String']>;
  visibleTo?: InputMaybe<Scalars['String']>;
  vzaarVideoId?: InputMaybe<Scalars['TWFilter_Int']>;
  wistiaOriginalFileUrl?: InputMaybe<Scalars['TWFilter_String']>;
  wistiaProjectUuid?: InputMaybe<Scalars['TWFilter_String']>;
  wistiaThumbnailUrl?: InputMaybe<Scalars['TWFilter_String']>;
  wistiaVideoUrl?: InputMaybe<Scalars['TWFilter_String']>;
  wistiaVideoUuid?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryMyWebLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isMobileLink?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  linkTransformationType?: InputMaybe<Scalars['TWFilter_String']>;
  mobileLinkCode?: InputMaybe<Scalars['TWFilter_String']>;
  mobilePlatform?: InputMaybe<Scalars['TWFilter_String']>;
  officiatingSchoolId?: InputMaybe<Scalars['TWFilter_Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<WebLinkSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  url?: InputMaybe<Scalars['TWFilter_String']>;
  visibilityContext?: InputMaybe<Scalars['String']>;
  visibleTo?: InputMaybe<Scalars['String']>;
  webLinkId?: InputMaybe<Scalars['TWFilter_ID']>;
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryNotificationSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  enabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  notifSettingType?: InputMaybe<Scalars['TWFilter_Int']>;
  notificationSettingId?: InputMaybe<Scalars['TWFilter_ID']>;
  onlyAthStatusFilter?: InputMaybe<Scalars['Boolean']>;
  onlyEnabledFilter?: InputMaybe<Scalars['Boolean']>;
  onlyUserTypeFilter?: InputMaybe<Scalars['Boolean']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<NotificationSettingSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryOrgProfileAttributeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  attributeCategoryId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  coreCode?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  excludedCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCustom?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableProfile?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableReport?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  rosterManagementCategories?: InputMaybe<Scalars['Boolean']>;
  selfManageUsers?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AttributeCategorySortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryOrgProfileAttributesArgs = {
  ColumnDataSql?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  attributeCategoryId?: InputMaybe<Scalars['TWFilter_Int']>;
  attributeDataTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  attributeId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  columnDisplaySql?: InputMaybe<Scalars['TWFilter_String']>;
  coreCode?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  dataRange?: InputMaybe<Scalars['TWFilter_String']>;
  enumOptions?: InputMaybe<Scalars['TWFilter_String']>;
  enumSetCode?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasUserTypeRestriction?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCustom?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSelfManageAvailable?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isTeamSpecific?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableProfile?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableReport?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reportFilterScopeRestriction?: InputMaybe<Scalars['TWFilter_String']>;
  selfManageUsers?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AttributeSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  tableDataAlias?: InputMaybe<Scalars['TWFilter_String']>;
  tableDisplayAlias?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryOrgSportPositionsArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
};

export type QueryOrgYearlyAttributeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCustom?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personBeingViewed?: InputMaybe<Scalars['ID']>;
  personTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  personTypesBeingViewed?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<YearlyAttributeCategorySortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  yearlyAttributeCategoryId?: InputMaybe<Scalars['TWFilter_ID']>;
};

export type QueryOrgsArgs = {
  IsTactical?: InputMaybe<Scalars['TWFilter_Boolean']>;
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  activePersonStatusTypes?: InputMaybe<Scalars['TWFilter_String']>;
  activeTeams?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  allTeamsTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  athletePersonTypeId?: InputMaybe<Scalars['TWFilter_String']>;
  before?: InputMaybe<Scalars['String']>;
  branding?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  defaultAppointmentSelection?: InputMaybe<Scalars['TWFilter_String']>;
  disableMessageEditDelete?: InputMaybe<Scalars['TWFilter_Boolean']>;
  doShowTwBranding?: InputMaybe<Scalars['TWFilter_Boolean']>;
  docusignAccountEmail?: InputMaybe<Scalars['TWFilter_String']>;
  enableGuardianshipFeatures?: InputMaybe<Scalars['TWFilter_Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  hasAcademics2?: InputMaybe<Scalars['TWFilter_String']>;
  hasCustomVoiceGreeting?: InputMaybe<Scalars['TWFilter_Boolean']>;
  identityFieldLabel?: InputMaybe<Scalars['TWFilter_String']>;
  identityProviderId?: InputMaybe<Scalars['TWFilter_Int']>;
  institutionId?: InputMaybe<Scalars['TWFilter_Int']>;
  isAdminOrg?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCollegiate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isMobileValidationEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isNonAthleteOrg?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isTactical?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  leagueId?: InputMaybe<Scalars['TWFilter_Int']>;
  logoImage?: InputMaybe<Scalars['TWFilter_String']>;
  messagingServiceSid?: InputMaybe<Scalars['TWFilter_String']>;
  oktaGroupId?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_ID']>;
  orgName?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sendSmsReminder?: InputMaybe<Scalars['TWFilter_Boolean']>;
  shortName?: InputMaybe<Scalars['TWFilter_String']>;
  smsProviderId?: InputMaybe<Scalars['TWFilter_Int']>;
  timeZoneId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  uses24HourTime?: InputMaybe<Scalars['TWFilter_Boolean']>;
  usesExpandedProfessorSurveyReports?: InputMaybe<Scalars['TWFilter_Boolean']>;
  usesSsoAsDefault?: InputMaybe<Scalars['TWFilter_Boolean']>;
};

export type QueryPeopleArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  advanced?: InputMaybe<Scalars['JSONString']>;
  after?: InputMaybe<Scalars['String']>;
  athleteStatus?: InputMaybe<Scalars['ID']>;
  athleteStatuses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  before?: InputMaybe<Scalars['String']>;
  columns?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customGroup?: InputMaybe<Scalars['ID']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasLoginAccess?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  messageableOnly?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  personIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<PersonSortEnum>>>;
  team?: InputMaybe<Scalars['ID']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  trip?: InputMaybe<Scalars['ID']>;
  userType?: InputMaybe<Scalars['ID']>;
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  viaSavedView?: InputMaybe<Scalars['Boolean']>;
  withAcademicYear?: InputMaybe<Scalars['Boolean']>;
  withAddresses?: InputMaybe<Scalars['Boolean']>;
  withAlternateEmail?: InputMaybe<Scalars['Boolean']>;
  withAthletePositions?: InputMaybe<Scalars['Boolean']>;
  withAthleteStatus?: InputMaybe<Scalars['Boolean']>;
  withAthleticYear?: InputMaybe<Scalars['Boolean']>;
  withAutomobile?: InputMaybe<Scalars['Boolean']>;
  withBirthdate?: InputMaybe<Scalars['Boolean']>;
  withContacts?: InputMaybe<Scalars['Boolean']>;
  withCustomAttributes?: InputMaybe<Scalars['Boolean']>;
  withDriversLicenseExpDate?: InputMaybe<Scalars['Boolean']>;
  withDriversLicenseNumber?: InputMaybe<Scalars['Boolean']>;
  withDriversLicenseState?: InputMaybe<Scalars['Boolean']>;
  withEmail?: InputMaybe<Scalars['Boolean']>;
  withFacebookAccount?: InputMaybe<Scalars['Boolean']>;
  withFaxNumber?: InputMaybe<Scalars['Boolean']>;
  withFirstName?: InputMaybe<Scalars['Boolean']>;
  withGender?: InputMaybe<Scalars['Boolean']>;
  withHasScholarship?: InputMaybe<Scalars['Boolean']>;
  withHomePhone?: InputMaybe<Scalars['Boolean']>;
  withJerseyNumber?: InputMaybe<Scalars['Boolean']>;
  withLastLogin?: InputMaybe<Scalars['Boolean']>;
  withLastName?: InputMaybe<Scalars['Boolean']>;
  withLoginCount?: InputMaybe<Scalars['Boolean']>;
  withMajor?: InputMaybe<Scalars['Boolean']>;
  withMiddleName?: InputMaybe<Scalars['Boolean']>;
  withMobilePhone?: InputMaybe<Scalars['Boolean']>;
  withNickName?: InputMaybe<Scalars['Boolean']>;
  withNickname?: InputMaybe<Scalars['Boolean']>;
  withOfficePhone?: InputMaybe<Scalars['Boolean']>;
  withOrgId?: InputMaybe<Scalars['Boolean']>;
  withRoommates?: InputMaybe<Scalars['Boolean']>;
  withScholarshipAmount?: InputMaybe<Scalars['Boolean']>;
  withStartDate?: InputMaybe<Scalars['Boolean']>;
  withTeams?: InputMaybe<Scalars['Boolean']>;
  withTitle?: InputMaybe<Scalars['Boolean']>;
  withTravelDocument?: InputMaybe<Scalars['Boolean']>;
  withTwitterAccount?: InputMaybe<Scalars['Boolean']>;
  withWeight?: InputMaybe<Scalars['Boolean']>;
  withYearlyAttributes?: InputMaybe<Scalars['Boolean']>;
  yavTmpYearId?: InputMaybe<Scalars['ID']>;
};

export type QueryPermissionsArgs = {
  Dependencies?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['TWFilter_String']>;
  codeName?: InputMaybe<Scalars['TWFilter_String']>;
  dependencies?: InputMaybe<Scalars['TWFilter_String']>;
  displayName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  grantPermissionId?: InputMaybe<Scalars['TWFilter_Int']>;
  hasOrgRestriction?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  moduleId?: InputMaybe<Scalars['TWFilter_Int']>;
  onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  permissionId?: InputMaybe<Scalars['TWFilter_ID']>;
  personTypeFilter?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<PermissionSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryPersonTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  namePlural?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<PersonTypeSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryPersonsWhoCanViewBulletinArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulletinClickStatus?: InputMaybe<BulletinClickStatusEnum>;
  bulletinId: Scalars['Int'];
  customSort?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchPerson?: InputMaybe<Scalars['String']>;
};

export type QueryPregameItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  competitionIdFilter: Scalars['ID'];
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  minutesBeforeStart?: InputMaybe<Scalars['TWFilter_Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  secondsBeforeStart?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<TravelPregameItemSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  timeBeforeStartSeconds?: InputMaybe<Scalars['TWFilter_Int']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  travelPregameItemId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryProfessorSurveyAssignmentsArgs = {
  advisorId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  completedDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  eligibilityYearId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  externalAccessLinkId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorSurveyAssignmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  professorSurveyId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<ProfessorSurveyAssignmentSortEnum>>>;
  status?: InputMaybe<Scalars['TWFilter_ProfessorSurveyAssignmentStatus']>;
  teamId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryProfessorSurveysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  countCompletedAssignments?: InputMaybe<Scalars['TWFilter_String']>;
  countNotCompletedAssignments?: InputMaybe<Scalars['TWFilter_String']>;
  countStudents?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  descriptionXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  dueDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  hasOnlyCompletedAssignments?: InputMaybe<Scalars['TWFilter_String']>;
  isClosed?: InputMaybe<Scalars['TWFilter_String']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isExpired?: InputMaybe<Scalars['TWFilter_String']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorSurveyId?: InputMaybe<Scalars['TWFilter_ID']>;
  searchProfessorSurveys?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<ProfessorSurveySortEnum>>>;
  termIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryProfessorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  emailAddress?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['TWFilter_String']>;
  fullName?: InputMaybe<Scalars['TWFilter_String']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  officeLocation?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  phoneNumber?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<Array<InputMaybe<Ac2ProfessorSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryProfileAttributeDatatypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  attributeDataTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  dataType?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  formControl?: InputMaybe<Scalars['TWFilter_String']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AttributeDataTypeSortEnum>>>;
  textFieldMax?: InputMaybe<Scalars['TWFilter_Int']>;
  textFieldSize?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryProfileReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  downloadableFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  errorMessage?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileType?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  profileReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  url?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryRecentlySharedFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  announcementId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentComplianceAuditReviewId?: InputMaybe<Scalars['TWFilter_Int']>;
  before?: InputMaybe<Scalars['String']>;
  canDownload?: InputMaybe<Scalars['TWFilter_Boolean']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  fileName?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileNotes?: InputMaybe<Scalars['TWFilter_String']>;
  fileSize?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['TWFilter_Int']>;
  isPinned?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  officiatingSchoolId?: InputMaybe<Scalars['TWFilter_Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sharedFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<SharedFileSortEnum_Shared_Date_Updated_Date>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelVendorId?: InputMaybe<Scalars['TWFilter_Int']>;
  tripId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  videoId?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryRelationshipTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isParent?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personRelationshipTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<PersonRelationshipTypeSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QuerySeasonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  includeAllTeams?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  seasonId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<Array<InputMaybe<SeasonSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QuerySelectablesArgs = {
  allowedPeople?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  codes: Array<InputMaybe<Scalars['String']>>;
};

export type QuerySessionRequestsArgs = {
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchSessionRequests?: InputMaybe<Scalars['String']>;
  sessionRequestId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<Array<InputMaybe<SessionRequestSortEnum>>>;
  status?: InputMaybe<Scalars['TWFilter_SessionRequestStatus']>;
  studentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  students?: InputMaybe<Scalars['TWFilter_String']>;
  subjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QuerySessionsArgs = {
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  after?: InputMaybe<Scalars['String']>;
  appointmentOccurrenceId?: InputMaybe<Scalars['TWFilter_Int']>;
  before?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasFilledForm?: InputMaybe<Scalars['TWFilter_String']>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  isCanceled?: InputMaybe<Scalars['TWFilter_String']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  occurrenceEndDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  occurrenceLocation?: InputMaybe<Scalars['TWFilter_String']>;
  occurrenceStartDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reportAnswers?: InputMaybe<Scalars['TWFilter_JSONString']>;
  reportStatus?: InputMaybe<Scalars['TWFilter_String']>;
  searchSessions?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['TWFilter_ID']>;
  sessionRequestId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<SessionSortEnum>>>;
  students?: InputMaybe<Scalars['TWFilter_String']>;
  subjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QuerySharingRecordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  asSharedFileVisibilityId?: InputMaybe<Scalars['TWFilter_ID']>;
  assignSelectTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  assigneeId?: InputMaybe<Scalars['TWFilter_Int']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  fullAssigneeCode?: InputMaybe<Scalars['TWFilter_String']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sharedFileId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QuerySignoffResponseArgs = {
  after?: InputMaybe<Scalars['String']>;
  approvalGuid?: InputMaybe<Scalars['TWFilter_String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  externalAccessLinkId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['TWFilter_Boolean']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId: Scalars['String'];
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reminderMessageId?: InputMaybe<Scalars['TWFilter_Int']>;
  reminderStreamMessageMeta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  sort?: InputMaybe<Array<InputMaybe<TmpActivityLogSignoffResponseSortEnum>>>;
  tmpActivityLogId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpActivityLogSignoffResponseId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpActivityLogStageId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QuerySignoffResponseDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  approvalGuid?: InputMaybe<Scalars['TWFilter_String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  externalAccessLinkId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['TWFilter_Boolean']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reminderMessageId?: InputMaybe<Scalars['TWFilter_Int']>;
  reminderStreamMessageMeta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  sort?: InputMaybe<Array<InputMaybe<TmpActivityLogSignoffResponseSortEnum>>>;
  tmpActivityLogId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpActivityLogSignoffResponseId: Scalars['String'];
  tmpActivityLogStageId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QuerySignupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentTypeId?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  creator?: InputMaybe<Scalars['ID']>;
  endDatetime?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mandatory?: InputMaybe<Scalars['Boolean']>;
  myReservationsOnly?: InputMaybe<Scalars['Boolean']>;
  mySignupsOnly?: InputMaybe<Scalars['Boolean']>;
  reservationsOnly?: InputMaybe<Scalars['Boolean']>;
  signupId?: InputMaybe<Scalars['UUID']>;
  startDatetime?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type QuerySignupsMobileDashboardArgs = {
  endDatetime?: InputMaybe<Scalars['DateTime']>;
  startDatetime?: InputMaybe<Scalars['DateTime']>;
};

export type QuerySlotAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentComplianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  apptDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  before?: InputMaybe<Scalars['String']>;
  countableEndHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableEndMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableMinutes?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  daysFromBase?: InputMaybe<Scalars['TWFilter_Int']>;
  enableCheckins?: InputMaybe<Scalars['TWFilter_Boolean']>;
  enableRsvps?: InputMaybe<Scalars['TWFilter_Boolean']>;
  endDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endDateTime: Scalars['DateTime'];
  endDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  endHour?: InputMaybe<Scalars['TWFilter_Int']>;
  endMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  endTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  externalAppointmentUid?: InputMaybe<Scalars['TWFilter_String']>;
  externalCalendarAccountId?: InputMaybe<Scalars['TWFilter_Int']>;
  facilityId?: InputMaybe<Scalars['TWFilter_String']>;
  facilityName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAllDay?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isAvailability?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isBookedSlot?: InputMaybe<Scalars['TWFilter_String']>;
  isExternalAppointment?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isMandatory?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrivate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSlot?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  occurrenceCalculationEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  recurEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurRule?: InputMaybe<Scalars['TWFilter_String']>;
  recurStart?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurType?: InputMaybe<Scalars['TWFilter_Int']>;
  selections: Array<InputMaybe<Scalars['String']>>;
  sendRsvpAlerts?: InputMaybe<Scalars['TWFilter_String']>;
  slotParentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  startDateTime: Scalars['DateTime'];
  startDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  startHour?: InputMaybe<Scalars['TWFilter_Int']>;
  startMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  startTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  timeZone?: InputMaybe<Scalars['TWFilter_String']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  var1?: InputMaybe<Scalars['TWFilter_Int']>;
  var2?: InputMaybe<Scalars['TWFilter_String']>;
  var3?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QuerySportPositionsArgs = {
  teamId?: InputMaybe<Scalars['ID']>;
};

export type QueryStatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  stateId?: InputMaybe<Scalars['TWFilter_ID']>;
};

export type QueryStreamUsersArgs = {
  after?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
};

export type QueryStudentStudyHallActionsArgs = {
  accumulatedMinutes?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  autoCheckedOut?: InputMaybe<Scalars['TWFilter_Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  checkinDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  checkoutDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  hoursRequired?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<StudyHallActionSortEnum>>>;
  studyHallActionId?: InputMaybe<Scalars['TWFilter_ID']>;
  studyHallId?: InputMaybe<Scalars['TWFilter_Int']>;
  studyHallPeriodId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryStudentTermsArgs = {
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checkinEndDay?: InputMaybe<Scalars['String']>;
  checkinStartDay?: InputMaybe<Scalars['String']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  courseSectionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  creditsAttempted?: InputMaybe<Scalars['TWFilter_String']>;
  creditsCompleted?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  eligibilityYearIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  finalGpa?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  goalGpa?: InputMaybe<Scalars['TWFilter_String']>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  isAtRiskResult?: InputMaybe<Scalars['Boolean']>;
  isComplete?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  midtermGpa?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  professorSurveyAssignmentStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  professorSurveyId?: InputMaybe<Scalars['ID']>;
  searchStudents?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['TWFilter_Int']>;
  studentTermId?: InputMaybe<Scalars['TWFilter_ID']>;
  studentYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryStudentYearsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<StudentYearSortEnum>>>;
  studentYearId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryStudentsArgs = {
  academicStatus?: InputMaybe<Scalars['TWFilter_AcademicStatus']>;
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  cumulativeGpa?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  eligibilityYear?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['TWFilter_String']>;
  isAtRisk?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  preferredName?: InputMaybe<Scalars['TWFilter_String']>;
  searchStudents?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<StudentSortEnum>>>;
  studentId?: InputMaybe<Scalars['TWFilter_ID']>;
  studentYearIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  teamIds?: InputMaybe<Scalars['TWFilter_IntArray']>;
  termIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryStudyHallActionsArgs = {
  accumulatedMinutes?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  autoCheckedOut?: InputMaybe<Scalars['TWFilter_Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  checkinDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  checkoutDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  hoursRequired?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<StudyHallActionSortEnum>>>;
  studyHallActionId?: InputMaybe<Scalars['TWFilter_ID']>;
  studyHallId?: InputMaybe<Scalars['TWFilter_Int']>;
  studyHallPeriodId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryStudyHallsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  canEditDatetimes?: InputMaybe<Scalars['TWFilter_Boolean']>;
  checkinAppToken?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isExpired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  periodRecurrence?: InputMaybe<Scalars['TWFilter_JSONString']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  rotateQrCode?: InputMaybe<Scalars['TWFilter_Boolean']>;
  searchStudyHalls?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<StudyHallSortEnum>>>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  studyHallId?: InputMaybe<Scalars['TWFilter_ID']>;
  totp?: InputMaybe<Scalars['TWFilter_String']>;
  totpSecret?: InputMaybe<Scalars['TWFilter_String']>;
  totpTimeToLive?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QuerySubjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  descriptionXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  includeGeneral?: InputMaybe<Scalars['Boolean']>;
  isGeneral?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  shortCode?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<SubjectSortEnum>>>;
  studentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  studentTermIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  subjectId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTeamArgs = {
  id: Scalars['Int'];
};

export type QueryTeamMembersArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAcademicsUser?: InputMaybe<Scalars['TWFilter_String']>;
  isOrgSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  isTeamSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamMemberSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamMemberId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTeamModulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  moduleCode?: InputMaybe<Scalars['TWFilter_String']>;
  moduleId?: InputMaybe<Scalars['TWFilter_Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  settings?: InputMaybe<Scalars['TWFilter_JSONString']>;
  sort?: InputMaybe<Array<InputMaybe<TeamModuleSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamModuleId?: InputMaybe<Scalars['TWFilter_ID']>;
};

export type QueryTeamProfileAttributeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  attributeCategoryId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  coreCode?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCustom?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableProfile?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableReport?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  rosterManagementCategories?: InputMaybe<Scalars['Boolean']>;
  selfManageUsers?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AttributeCategorySortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTeamProfileAttributesArgs = {
  ColumnDataSql?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  attributeCategoryId?: InputMaybe<Scalars['TWFilter_Int']>;
  attributeDataTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  attributeId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  columnDisplaySql?: InputMaybe<Scalars['TWFilter_String']>;
  coreCode?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  dataRange?: InputMaybe<Scalars['TWFilter_String']>;
  enumOptions?: InputMaybe<Scalars['TWFilter_String']>;
  enumSetCode?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasUserTypeRestriction?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCustom?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSelfManageAvailable?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isTeamSpecific?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableProfile?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isViewableReport?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reportFilterScopeRestriction?: InputMaybe<Scalars['TWFilter_String']>;
  selfManageUsers?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AttributeSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  tableDataAlias?: InputMaybe<Scalars['TWFilter_String']>;
  tableDisplayAlias?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTeamRosterReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  downloadableFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  errorMessage?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileType?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamRosterReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  url?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTeamRostersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamRosterSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamRosterId?: InputMaybe<Scalars['TWFilter_String']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTeamYearlyAttributeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCustom?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personBeingViewed?: InputMaybe<Scalars['ID']>;
  personTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  personTypesBeingViewed?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<YearlyAttributeCategorySortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamBeingViewed?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  yearlyAttributeCategoryId?: InputMaybe<Scalars['TWFilter_ID']>;
};

export type QueryTeamsArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  branding?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeAllTeamsTeam?: InputMaybe<Scalars['Boolean']>;
  institution?: InputMaybe<Scalars['TWFilter_String']>;
  institutionId?: InputMaybe<Scalars['TWFilter_Int']>;
  isUserChangeAlertEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  logoImage?: InputMaybe<Scalars['TWFilter_String']>;
  managedTeams?: InputMaybe<Scalars['Boolean']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  permission?: InputMaybe<Scalars['String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  settings?: InputMaybe<Scalars['TWFilter_JSONString']>;
  shortLabel?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamSortEnum>>>;
  sportId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  userChangeAlertEmail?: InputMaybe<Scalars['TWFilter_String']>;
  voiceGreeting?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TermSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  termId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTicketingBlockedGuestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['TWFilter_JSONString']>;
  before?: InputMaybe<Scalars['String']>;
  blockedGuestId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<TicketingBlockedGuestSortEnum>>>;
  sortAttributes?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTicketingEventPersonsArgs = {
  actions?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  eventId?: InputMaybe<Scalars['TWFilter_Int']>;
  eventPersonId?: InputMaybe<Scalars['TWFilter_ID']>;
  first?: InputMaybe<Scalars['Int']>;
  hasAllocation?: InputMaybe<Scalars['Boolean']>;
  isManuallyEdited?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  person?: InputMaybe<Scalars['ID']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  personTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchPersons?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['TWFilter_JSONString']>;
  sort?: InputMaybe<Array<InputMaybe<TicketingEventPersonSortEnum>>>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  ticketingEvent: Scalars['ID'];
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTicketingEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  eventEndDateTime?: InputMaybe<Scalars['DateTime']>;
  eventId?: InputMaybe<Scalars['TWFilter_ID']>;
  eventStartDateTime?: InputMaybe<Scalars['DateTime']>;
  eventTitleSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasAvailableTicketsForUser?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isEventUpcoming?: InputMaybe<Scalars['Boolean']>;
  isWindowOpen?: InputMaybe<Scalars['Boolean']>;
  isWindowSet?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  selections?: InputMaybe<Scalars['TWFilter_JSONString']>;
  settings?: InputMaybe<Scalars['TWFilter_JSONString']>;
  sort?: InputMaybe<Array<InputMaybe<TicketingEventSortEnum>>>;
  team?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTicketingGuestInformationExportArgs = {
  filters?: InputMaybe<TicketingGuestInformationExportInput>;
};

export type QueryTicketingGuestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['TWFilter_JSONString']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  guestId?: InputMaybe<Scalars['TWFilter_ID']>;
  isArchived?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  search?: InputMaybe<Scalars['String']>;
  searchOnlyGuestFields?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<TicketingGuestSortEnum>>>;
  sortAttributes?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
  status?: InputMaybe<Scalars['TWFilter_TicketingGuestStatus']>;
  statusUpdatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTicketingReservationExportArgs = {
  filters?: InputMaybe<TicketingReservationsExportInput>;
};

export type QueryTicketingReservationReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  downloadableFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  errorMessage?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileType?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  ticketingReservationReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  url?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpActivityLogDatesListArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  complianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDateArg: Scalars['Date'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  logConfigIdArg: Scalars['Int'];
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpActivityLogConfigSortEnum>>>;
  startDateArg: Scalars['Date'];
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpActivityLogConfigId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpCalendarOverviewAthleteArgs = {
  filters?: InputMaybe<TmpCalendarOverviewInput>;
};

export type QueryTmpCalendarOverviewDeclarationsArgs = {
  tmpTeamIdArg: Scalars['ID'];
};

export type QueryTmpChangeHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  afterDate?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  appointmentComplianceAuditId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  beforeDate?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  conflictReasonCode?: InputMaybe<Scalars['TWFilter_String']>;
  conflictReasonText?: InputMaybe<Scalars['TWFilter_String']>;
  conflictType?: InputMaybe<ConflictType>;
  conflicts?: InputMaybe<Scalars['TWFilter_JSONString']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  diffJson?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reviewDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  reviewStatus?: InputMaybe<Scalars['TWFilter_String']>;
  reviewStatusFilter?: InputMaybe<ReviewStatusFilter>;
  reviewedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentComplianceAuditSortEnum>>>;
  teamId?: InputMaybe<Scalars['Int']>;
  teamIds?: InputMaybe<Scalars['TWFilter_IntArray']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpConflictsArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  appointmentOccurrenceId?: InputMaybe<Scalars['ID']>;
  appointmentTypeId?: InputMaybe<Scalars['ID']>;
  countableMinutes?: InputMaybe<Scalars['Int']>;
  endDatetime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  isCountable?: InputMaybe<Scalars['Boolean']>;
  startDatetime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTmpDateTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentType?: InputMaybe<Scalars['TWFilter_String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['TWFilter_String']>;
  countMax?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasAppointment?: InputMaybe<Scalars['TWFilter_Boolean']>;
  hasSeparateRangeAppointments?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isCaraAllowed?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isRange?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isRaraAllowed?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  rangeDays?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<TmpDateTypeSortEnum>>>;
  tmpDateTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpLevel?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpDatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  endDateInclusive?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpDateSortEnum>>>;
  startAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  tmpDateId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpDateTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamAthleteGroupId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearIdArg?: InputMaybe<Scalars['Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpLogListArgs = {
  adminComments?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  approved?: InputMaybe<Scalars['TWFilter_Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDateArg?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isDraft?: InputMaybe<Scalars['TWFilter_String']>;
  isDraftArg?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  logEndDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  logPendingStageSeqsArg?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  logStartDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pendingStageDueDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  pendingStageSeq?: InputMaybe<Scalars['TWFilter_Int']>;
  pendingStages?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpActivityLogSortEnum>>>;
  startDateArg?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpActivityLogConfigId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpActivityLogId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamIdsArg?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearIdArg?: InputMaybe<Scalars['Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpTeamPlanArgs = {
  filters?: InputMaybe<TmpTeamPlanFiltersInput>;
};

export type QueryTmpTeamSegmentArgs = {
  EndDate?: InputMaybe<Scalars['TWFilter_String']>;
  StartDate?: InputMaybe<Scalars['TWFilter_String']>;
  acclimatizationPeriodEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  acclimatizationPeriodStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  competitionCount?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  dayCount?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  firstCaraDate?: InputMaybe<Scalars['TWFilter_String']>;
  firstCompetitionDate?: InputMaybe<Scalars['TWFilter_String']>;
  inSeason?: InputMaybe<Scalars['TWFilter_String']>;
  isChampionship?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isFinalized?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  nineWeekPeriodEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  nineWeekPeriodStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  nineWeekPeriodTmpDate?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  orientationDate?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  postSeasonDaysoffEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  postSeasonDaysoffStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  postSeasonEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  postSeasonStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  preSeasonEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  preSeasonStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  seasonType?: InputMaybe<Scalars['TWFilter_String']>;
  segmentEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  segmentOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  segmentStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  segmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<TmpTeamSegmentSortEnum>>>;
  springDiscretionaryPeriods?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  summerDiscretionaryPeriods?: InputMaybe<Scalars['TWFilter_String']>;
  tmpSegmentType?: InputMaybe<Scalars['TWFilter_String']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamIdArg?: InputMaybe<Scalars['Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  totalWeeks?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  weekStart?: InputMaybe<Scalars['TWFilter_Int']>;
  weekStartDay?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpTeamSegmentConflictArgs = {
  EndDate?: InputMaybe<Scalars['TWFilter_String']>;
  StartDate?: InputMaybe<Scalars['TWFilter_String']>;
  acclimatizationPeriodEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  acclimatizationPeriodStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  competitionCount?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  dayCount?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
  firstCaraDate?: InputMaybe<Scalars['TWFilter_String']>;
  firstCompetitionDate?: InputMaybe<Scalars['TWFilter_String']>;
  inSeason?: InputMaybe<Scalars['TWFilter_String']>;
  isChampionship?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isFinalized?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  nineWeekPeriodEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  nineWeekPeriodStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  nineWeekPeriodTmpDate?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  orientationDate?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  postSeasonDaysoffEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  postSeasonDaysoffStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  postSeasonEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  postSeasonStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  preSeasonEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  preSeasonStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  seasonType?: InputMaybe<Scalars['TWFilter_String']>;
  segmentEndDate?: InputMaybe<Scalars['TWFilter_String']>;
  segmentOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  segmentStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  segmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<TmpTeamSegmentSortEnum>>>;
  springDiscretionaryPeriods?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  summerDiscretionaryPeriods?: InputMaybe<Scalars['TWFilter_String']>;
  tmpSegmentType?: InputMaybe<Scalars['TWFilter_String']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  totalWeeks?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  weekStart?: InputMaybe<Scalars['TWFilter_Int']>;
  weekStartDay?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpTeamSettingsArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  complianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpActivityLogConfigSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpActivityLogConfigId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamIdArg?: InputMaybe<Scalars['Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearIdArg?: InputMaybe<Scalars['Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpTeamsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  compliancePersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstCaraDate?: InputMaybe<Scalars['TWFilter_String']>;
  hasAthleteGroups?: InputMaybe<Scalars['TWFilter_Boolean']>;
  headCoachPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  includeCalendarVisibilityDaysOff?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpTeamSortEnum>>>;
  sportAdminPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  studentAthleteRepPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpYearRulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  commentRequired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  modApptNotificationHours?: InputMaybe<Scalars['TWFilter_Int']>;
  newApptNotificationHours?: InputMaybe<Scalars['TWFilter_Int']>;
  notifyCompliance?: InputMaybe<Scalars['TWFilter_Boolean']>;
  org?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpYearRuleSortEnum>>>;
  tmpRuleId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearRuleId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTmpYearsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chancellorPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  chancellorTitle?: InputMaybe<Scalars['TWFilter_String']>;
  complianceDirectorPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  complianceDirectorTitle?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  directorAthleticsPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  directorAthleticsTitle?: InputMaybe<Scalars['TWFilter_String']>;
  facultyAthleticPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  facultyAthleticTitle?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isCurrent?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpYearSortEnum>>>;
  termCount?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTravelCredentialAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  competitionIdFilter: Scalars['ID'];
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  quantity?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<TravelCredentialAssignmentSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelCredentialAssignmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  travelCredentialId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTravelCredentialOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TravelCredentialSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelCredentialId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTravelDocumentConfigsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['TWFilter_JSONString']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelDocumentConfigId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTravelDressOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDefault?: InputMaybe<Scalars['TWFilter_Boolean']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  orgIsDefault?: InputMaybe<Scalars['TWFilter_Boolean']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TravelDressSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelDressId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTravelEventTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  travelEventTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTravelEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  before?: InputMaybe<Scalars['String']>;
  caraRequired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  onlyCompetitionsFilter?: InputMaybe<Scalars['Boolean']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  pregameCompetitionVisibilityFilter?: InputMaybe<Scalars['Boolean']>;
  timeZoneId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelEventId?: InputMaybe<Scalars['TWFilter_ID']>;
  travelEventIdFilter?: InputMaybe<Scalars['ID']>;
  travelEventTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tripId?: InputMaybe<Scalars['TWFilter_Int']>;
  tripIdFilter: Scalars['ID'];
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTravelParticipationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  competitionIdFilter: Scalars['ID'];
  competitionParticipationId?: InputMaybe<Scalars['TWFilter_Int']>;
  competitionPersonParticipationId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  didParticipate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TravelParticipationSortEnum>>>;
  travelPartyMemberId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTravelParticipationReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  downloadableFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  errorMessage?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileType?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  travelParticipationReportId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  url?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTravelTaskArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  competitionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  taskName?: InputMaybe<Scalars['TWFilter_String']>;
  taskOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelTaskId?: InputMaybe<Scalars['TWFilter_ID']>;
  tripId?: InputMaybe<Scalars['TWFilter_Int']>;
  tripIdFilter: Scalars['ID'];
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTripSeatplansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  seatplanConfigurationJson?: InputMaybe<Scalars['TWFilter_String']>;
  seatplanJson?: InputMaybe<Scalars['TWFilter_JSONString']>;
  sort?: InputMaybe<Array<InputMaybe<TravelSeatplanSortEnum>>>;
  travelSeatplanId?: InputMaybe<Scalars['TWFilter_ID']>;
  travelVendorTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelVendorTypeIdFilter?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tripId?: InputMaybe<Scalars['TWFilter_Int']>;
  tripIdFilter: Scalars['ID'];
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryTripsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  endTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isInPast?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  season?: InputMaybe<Scalars['ID']>;
  seasons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sort?: InputMaybe<Array<InputMaybe<TripSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  startTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Array<InputMaybe<TripStatusModelEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryTutoringAppointmentRangesArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentComplianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  apptDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  before?: InputMaybe<Scalars['String']>;
  countableEndHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableEndMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableMinutes?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  datetimeRangeList: Array<InputMaybe<TutoringAppointmentRangeNode>>;
  daysFromBase?: InputMaybe<Scalars['TWFilter_Int']>;
  enableCheckins?: InputMaybe<Scalars['TWFilter_Boolean']>;
  enableRsvps?: InputMaybe<Scalars['TWFilter_Boolean']>;
  endDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  endHour?: InputMaybe<Scalars['TWFilter_Int']>;
  endMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  endTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  externalAppointmentUid?: InputMaybe<Scalars['TWFilter_String']>;
  externalCalendarAccountId?: InputMaybe<Scalars['TWFilter_Int']>;
  facilityId?: InputMaybe<Scalars['TWFilter_String']>;
  facilityName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAllDay?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isAvailability?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isBookedSlot?: InputMaybe<Scalars['TWFilter_String']>;
  isExternalAppointment?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isMandatory?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrivate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSlot?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  occurrenceCalculationEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  recurEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurRule?: InputMaybe<Scalars['TWFilter_String']>;
  recurStart?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurType?: InputMaybe<Scalars['TWFilter_Int']>;
  selections: Array<InputMaybe<Scalars['String']>>;
  sendRsvpAlerts?: InputMaybe<Scalars['TWFilter_String']>;
  slotParentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  startDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  startHour?: InputMaybe<Scalars['TWFilter_Int']>;
  startMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  startTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  timeZone?: InputMaybe<Scalars['TWFilter_String']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  var1?: InputMaybe<Scalars['TWFilter_Int']>;
  var2?: InputMaybe<Scalars['TWFilter_String']>;
  var3?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryTutoringAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentComplianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  appointmentTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  apptDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  before?: InputMaybe<Scalars['String']>;
  countableEndHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableEndMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableMinutes?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartHour?: InputMaybe<Scalars['TWFilter_Int']>;
  countableStartMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  countableTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  daysFromBase?: InputMaybe<Scalars['TWFilter_Int']>;
  enableCheckins?: InputMaybe<Scalars['TWFilter_Boolean']>;
  enableRsvps?: InputMaybe<Scalars['TWFilter_Boolean']>;
  endDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  endDateTime: Scalars['DateTime'];
  endDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  endHour?: InputMaybe<Scalars['TWFilter_Int']>;
  endMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  endTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  externalAppointmentUid?: InputMaybe<Scalars['TWFilter_String']>;
  externalCalendarAccountId?: InputMaybe<Scalars['TWFilter_Int']>;
  facilityId?: InputMaybe<Scalars['TWFilter_String']>;
  facilityName?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAllDay?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isAvailability?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isBookedSlot?: InputMaybe<Scalars['TWFilter_String']>;
  isExternalAppointment?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isMandatory?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrivate?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isSlot?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  occurrenceCalculationEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  recurEnd?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurRule?: InputMaybe<Scalars['TWFilter_String']>;
  recurStart?: InputMaybe<Scalars['TWFilter_DateTime']>;
  recurType?: InputMaybe<Scalars['TWFilter_Int']>;
  selections: Array<InputMaybe<Scalars['String']>>;
  sendRsvpAlerts?: InputMaybe<Scalars['TWFilter_String']>;
  slotParentAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentSortEnum>>>;
  startDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  startDateTime: Scalars['DateTime'];
  startDatetimeLocal?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeNaive?: InputMaybe<Scalars['TWFilter_String']>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  startHour?: InputMaybe<Scalars['TWFilter_Int']>;
  startMinute?: InputMaybe<Scalars['TWFilter_Int']>;
  startTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  timeZone?: InputMaybe<Scalars['TWFilter_String']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  var1?: InputMaybe<Scalars['TWFilter_Int']>;
  var2?: InputMaybe<Scalars['TWFilter_String']>;
  var3?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryTutorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  canTutorAtRiskStudents?: InputMaybe<Scalars['TWFilter_Boolean']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  combinedSubjectIds?: InputMaybe<Scalars['TWFilter_String']>;
  combinedSubjects?: InputMaybe<Scalars['TWFilter_String']>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  courses?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['TWFilter_String']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  maximumNumberOfHours?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  personAutomobileId?: InputMaybe<Scalars['TWFilter_Int']>;
  personCalendarAlertDefault?: InputMaybe<Scalars['TWFilter_String']>;
  personCreatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  personCreatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  personDateOfBirth?: InputMaybe<Scalars['TWFilter_String']>;
  personDirectReportPersonId?: InputMaybe<Scalars['TWFilter_Int']>;
  personDisplayName?: InputMaybe<Scalars['TWFilter_String']>;
  personDriversLicenseExpirationDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  personDriversLicenseNumber?: InputMaybe<Scalars['TWFilter_String']>;
  personDriversLicenseState?: InputMaybe<Scalars['TWFilter_String']>;
  personDriversLicenseStateId?: InputMaybe<Scalars['TWFilter_Int']>;
  personEmailAddress?: InputMaybe<Scalars['TWFilter_String']>;
  personEmailAddressPersonal?: InputMaybe<Scalars['TWFilter_String']>;
  personFacebookAccount?: InputMaybe<Scalars['TWFilter_String']>;
  personFirstName?: InputMaybe<Scalars['TWFilter_String']>;
  personFullName?: InputMaybe<Scalars['TWFilter_String']>;
  personFullNameFirstInitial?: InputMaybe<Scalars['TWFilter_String']>;
  personFullNameFirstInitialPreferred?: InputMaybe<Scalars['TWFilter_String']>;
  personFullNameNormalOrder?: InputMaybe<Scalars['TWFilter_String']>;
  personFullNameWithMiddle?: InputMaybe<Scalars['TWFilter_String']>;
  personFullNameWithMiddleNormalOrder?: InputMaybe<Scalars['TWFilter_String']>;
  personFullPreferredName?: InputMaybe<Scalars['TWFilter_String']>;
  personGender?: InputMaybe<Scalars['TWFilter_String']>;
  personHasLoginAccess?: InputMaybe<Scalars['TWFilter_Boolean']>;
  personHasMobileBeenValidated?: InputMaybe<Scalars['TWFilter_Boolean']>;
  personHasMobileToken?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  personIsContact?: InputMaybe<Scalars['TWFilter_Boolean']>;
  personIsOrgLevelUser?: InputMaybe<Scalars['TWFilter_String']>;
  personLastAndFirstName?: InputMaybe<Scalars['TWFilter_String']>;
  personLastLoginDate?: InputMaybe<Scalars['TWFilter_String']>;
  personLastName?: InputMaybe<Scalars['TWFilter_String']>;
  personLastSelectedTeamId?: InputMaybe<Scalars['TWFilter_String']>;
  personLoginCount?: InputMaybe<Scalars['TWFilter_String']>;
  personMaritalStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  personMiddleName?: InputMaybe<Scalars['TWFilter_String']>;
  personNickname?: InputMaybe<Scalars['TWFilter_String']>;
  personNicknameFormatted?: InputMaybe<Scalars['TWFilter_String']>;
  personOrgId?: InputMaybe<Scalars['TWFilter_Int']>;
  personPersonId?: InputMaybe<Scalars['TWFilter_ID']>;
  personPhoneCell?: InputMaybe<Scalars['TWFilter_String']>;
  personPhoneFax?: InputMaybe<Scalars['TWFilter_String']>;
  personPhoneHome?: InputMaybe<Scalars['TWFilter_String']>;
  personPhoneOffice?: InputMaybe<Scalars['TWFilter_String']>;
  personPhoneTeamworks?: InputMaybe<Scalars['TWFilter_String']>;
  personPicture?: InputMaybe<Scalars['TWFilter_String']>;
  personPictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  personPictureImage?: InputMaybe<Scalars['TWFilter_String']>;
  personPk?: InputMaybe<Scalars['TWFilter_String']>;
  personPreferredName?: InputMaybe<Scalars['TWFilter_String']>;
  personSchoolIdentifier?: InputMaybe<Scalars['TWFilter_String']>;
  personStartDate?: InputMaybe<Scalars['TWFilter_String']>;
  personSuffix?: InputMaybe<Scalars['TWFilter_String']>;
  personTeamLabels?: InputMaybe<Scalars['TWFilter_String']>;
  personTimeZone?: InputMaybe<Scalars['TWFilter_String']>;
  personTitle?: InputMaybe<Scalars['TWFilter_String']>;
  personTravelWeight?: InputMaybe<Scalars['TWFilter_Int']>;
  personTwitterAccount?: InputMaybe<Scalars['TWFilter_String']>;
  personUpdatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  personUpdatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  personUserTypesByTeam?: InputMaybe<Scalars['TWFilter_JSONString']>;
  personUsesExternalAuthentication?: InputMaybe<Scalars['TWFilter_Boolean']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  preferredName?: InputMaybe<Scalars['TWFilter_String']>;
  searchTutors?: InputMaybe<Scalars['String']>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  subjects?: InputMaybe<Scalars['TWFilter_String']>;
  tutorId?: InputMaybe<Scalars['TWFilter_ID']>;
  tutorTermsBulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  tutorTermsBulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorTermsChecksum?: InputMaybe<Scalars['TWFilter_String']>;
  tutorTermsCreatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorTermsCreatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  tutorTermsCreatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  tutorTermsCustomerKey?: InputMaybe<Scalars['TWFilter_String']>;
  tutorTermsIsDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  tutorTermsIsPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  tutorTermsOrgId?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorTermsOwner?: InputMaybe<Scalars['TWFilter_String']>;
  tutorTermsOwnerId?: InputMaybe<Scalars['TWFilter_String']>;
  tutorTermsPk?: InputMaybe<Scalars['TWFilter_String']>;
  tutorTermsTermId?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorTermsTutorId?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorTermsTutorTermId?: InputMaybe<Scalars['TWFilter_ID']>;
  tutorTermsUpdatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorTermsUpdatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  tutorTermsUpdatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryUniqueFileVideoNameListArgs = {
  actionDatetime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  actionType?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  assetType?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  platform?: InputMaybe<Scalars['TWFilter_String']>;
  sharedFileId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  userFileVideoActionId?: InputMaybe<Scalars['TWFilter_ID']>;
  videoId?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type QueryUnreadCountsArgs = {
  after?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids: Array<InputMaybe<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUpcomingSessionsArgs = {
  filters?: InputMaybe<UpcomingSessionsFiltersInput>;
};

export type QueryUserHistoryChangelogArgs = {
  actionDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  actorId?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  changeType?: InputMaybe<Scalars['TWFilter_ChangeType']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  effectiveDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  extra?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  newValue?: InputMaybe<Scalars['TWFilter_StringArray']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  oldValue?: InputMaybe<Scalars['TWFilter_StringArray']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personBeingViewed: Scalars['ID'];
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reason?: InputMaybe<Scalars['TWFilter_AthleteStatusChangeReason']>;
  sort?: InputMaybe<Array<InputMaybe<UserHistoryChangelogSortEnum>>>;
  sourcePk?: InputMaybe<Scalars['TWFilter_String']>;
  sourceTable?: InputMaybe<Scalars['TWFilter_String']>;
  teamBeingViewed: Scalars['ID'];
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  userHistoryChangelogId?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryUserHistoryTeamsArgs = {
  actionDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  actorId?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  changeType?: InputMaybe<Scalars['TWFilter_ChangeType']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  effectiveDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  extra?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  newValue?: InputMaybe<Scalars['TWFilter_StringArray']>;
  notes?: InputMaybe<Scalars['TWFilter_String']>;
  oldValue?: InputMaybe<Scalars['TWFilter_StringArray']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personBeingViewed: Scalars['ID'];
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reason?: InputMaybe<Scalars['TWFilter_AthleteStatusChangeReason']>;
  sort?: InputMaybe<Array<InputMaybe<UserHistoryChangelogSortEnum>>>;
  sourcePk?: InputMaybe<Scalars['TWFilter_String']>;
  sourceTable?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  userHistoryChangelogId?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryUserRegistrationPersonArgs = {
  uuid: Scalars['String'];
};

export type QueryUserSsoUrlArgs = {
  username: Scalars['String'];
};

export type QueryValidatedCodesArgs = {
  permissionCode: SelectionRestrictingPermission;
  selectionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryVendorGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<TravelVendorGroupSortEnum>>>;
  tripIdFilter: Scalars['ID'];
};

export type QueryVendorTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  travelVendorTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryVendorsArgs = {
  addressId?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  phoneFax?: InputMaybe<Scalars['TWFilter_String']>;
  phoneMain?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TravelVendorSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  travelVendorId?: InputMaybe<Scalars['TWFilter_ID']>;
  travelVendorIdFilter?: InputMaybe<Scalars['ID']>;
  travelVendorTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tripIdFilter?: InputMaybe<Scalars['ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  vendorInformation?: InputMaybe<Scalars['String']>;
  vendorSearch?: InputMaybe<Scalars['String']>;
  vendorTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryVerifiedExternalStudyHallArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  canEditDatetimes?: InputMaybe<Scalars['TWFilter_Boolean']>;
  checkinAppToken?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  externalCheckinAppToken: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isExpired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  periodRecurrence?: InputMaybe<Scalars['TWFilter_JSONString']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  rotateQrCode?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<StudyHallSortEnum>>>;
  startDatetimeUtc?: InputMaybe<Scalars['TWFilter_String']>;
  studyHallId?: InputMaybe<Scalars['TWFilter_ID']>;
  timeBasedOneTimePassword?: InputMaybe<Scalars['String']>;
  totp?: InputMaybe<Scalars['TWFilter_String']>;
  totpSecret?: InputMaybe<Scalars['TWFilter_String']>;
  totpTimeToLive?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type QueryYearlyAttributeReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  downloadableFileId?: InputMaybe<Scalars['TWFilter_ID']>;
  errorMessage?: InputMaybe<Scalars['TWFilter_String']>;
  fileNameServer?: InputMaybe<Scalars['TWFilter_String']>;
  fileType?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<Scalars['TWFilter_String']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  title?: InputMaybe<Scalars['TWFilter_String']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  url?: InputMaybe<Scalars['TWFilter_String']>;
  yearlyAttributeReportId?: InputMaybe<Scalars['TWFilter_ID']>;
};

export type QueryYearlyAttributeValuesForYearArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personBeingViewed: Scalars['ID'];
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<YearlyAttributeValueSortEnum>>>;
  tmpYear: Scalars['ID'];
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  valueDate?: InputMaybe<Scalars['TWFilter_String']>;
  valueNumeric?: InputMaybe<Scalars['TWFilter_Float']>;
  valueText?: InputMaybe<Scalars['TWFilter_String']>;
  yearlyAttributeId?: InputMaybe<Scalars['TWFilter_Int']>;
  yearlyAttributeValueId?: InputMaybe<Scalars['TWFilter_ID']>;
};

export type QuickFormAssignmentTemplateUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignment?: Maybe<FormAssignmentNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type QuickFormTemplateCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formTemplate?: Maybe<FormTemplateNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type QuickFormTemplateDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formTemplate?: Maybe<FormTemplateNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type QuickFormTemplateDuplicatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formTemplate?: Maybe<FormTemplateNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type QuickFormTemplateInput = {
  /** Will be rendered as actual form on frontend */
  quickformTemplate?: InputMaybe<Scalars['JSONString']>;
  /** Identifier needed to link a Hub form to a Smartabase form, set at QuickForm template level */
  smartabaseFormName?: InputMaybe<Scalars['String']>;
};

export type QuickFormTemplateUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formTemplate?: Maybe<FormTemplateNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A polymorphous entity that returns a PersonNode or TicketingGuestObjectType */
export type Recipient = {
  label?: Maybe<Scalars['String']>;
};

/** Person object for one of the recipients of the message */
export type RecipientPersonObjectType = {
  initials?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['Int']>;
  pictureDownloadUrl?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum RelativeDateType {
  Days = 'days',
  Weeks = 'weeks',
}

export type ReminderOffsetNode = {
  days?: Maybe<Scalars['Int']>;
  months?: Maybe<Scalars['Int']>;
  weeks?: Maybe<Scalars['Int']>;
  years?: Maybe<Scalars['Int']>;
};

export type RemoveTravelVendorFromGroupPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelVendorGroupVendorLink?: Maybe<TravelVendorGroupVendorLinkNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export enum ReportTypeGrapheneEnum {
  Excel = 'excel',
  Pdf = 'pdf',
}

/** Sends a password reset email to the given username */
export type RequestPasswordReset = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ok?: Maybe<Scalars['Boolean']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type RescheduleReservationInput = {
  newSlotId?: InputMaybe<Scalars['UUID']>;
  oldSlotId?: InputMaybe<Scalars['UUID']>;
  personId?: InputMaybe<Scalars['ID']>;
};

export type RescheduleSignupReservations = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  changed?: Maybe<Array<Maybe<RescheduleSlotNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type RescheduleSlotNode = {
  newSlot?: Maybe<SlotNode>;
  oldSlot?: Maybe<SlotNode>;
};

export type ReservationInput = {
  personId?: InputMaybe<Scalars['ID']>;
  slotId?: InputMaybe<Scalars['UUID']>;
};

export type ReservedPersonNode = {
  active?: Maybe<Scalars['Boolean']>;
  activeMemberships?: Maybe<Array<Maybe<TeamMemberNode>>>;
  /** Persons Automobile */
  automobile?: Maybe<AutomobileNode>;
  canModifyNotes: Scalars['Boolean'];
  canModifyProfileAddresses: Scalars['Boolean'];
  canModifyProfileCustomContacts: Scalars['Boolean'];
  canModifyProfileFiles: Scalars['Boolean'];
  canModifyProfileRoommates: Scalars['Boolean'];
  canModifyProfileTwContacts: Scalars['Boolean'];
  canViewAllPersonalDetails: Scalars['Boolean'];
  canViewPersonalDetails: Scalars['Boolean'];
  canViewProfile: Scalars['Boolean'];
  canViewProfileCalendar: Scalars['Boolean'];
  canViewProfileFiles: Scalars['Boolean'];
  canViewProfileForms: Scalars['Boolean'];
  canViewProfileNotes: Scalars['Boolean'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  customAttributeValues?: Maybe<Scalars['JSONString']>;
  /** The person's date of birth in YYYY-MM-DD format */
  dateOfBirth?: Maybe<Scalars['String']>;
  /** Name as it should appear to the end user on the front end. */
  displayName?: Maybe<Scalars['String']>;
  driversLicenseExpirationDate?: Maybe<Scalars['DateTime']>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  driversLicenseState?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  emailAddressPersonal?: Maybe<Scalars['String']>;
  facebookAccount?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasLoginAccess?: Maybe<Scalars['Boolean']>;
  hasMobileBeenValidated?: Maybe<Scalars['Boolean']>;
  /** Whether this person has a mobile token. */
  hasMobileToken?: Maybe<Scalars['Boolean']>;
  /** Whether this person has linked user account */
  hasUserAccount?: Maybe<Scalars['Boolean']>;
  /** The first and last initials of the person or null if no name is provided. */
  initials?: Maybe<Scalars['String']>;
  /** A URL to an expiring page for registering a user or resetting a password. */
  inviteUrl?: Maybe<Scalars['String']>;
  isContact?: Maybe<Scalars['Boolean']>;
  /** Whether this person is all teams */
  isOrgLevelUser?: Maybe<Scalars['Boolean']>;
  /** The last date that the user logged in on this account */
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  /** The number of logins this person has made */
  loginCount?: Maybe<Scalars['Int']>;
  middleName?: Maybe<Scalars['String']>;
  /** A formatted mobile phone number */
  mobilePhone?: Maybe<Scalars['String']>;
  /**
   * Name formatted as <preferred name> <last name>.
   * @deprecated Use SignupsPersonNode.displayName instead
   */
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  person?: Maybe<SignupsPersonNode>;
  personId?: Maybe<Scalars['Int']>;
  /** @deprecated Use SignupsPersonNode.mobilePhone instead */
  phoneCell?: Maybe<Scalars['String']>;
  phoneFax?: Maybe<Scalars['String']>;
  phoneHome?: Maybe<Scalars['String']>;
  phoneOffice?: Maybe<Scalars['String']>;
  phoneTeamworks?: Maybe<Scalars['String']>;
  /** @deprecated Use SignupsPersonNode.pictureUrl instead */
  picture?: Maybe<Scalars['String']>;
  /** @deprecated Use SignupsPersonNode.pictureUrl instead */
  pictureDownloadUrl?: Maybe<Scalars['String']>;
  /** @deprecated Use SignupsPersonNode.pictureUrl instead */
  pictureImage?: Maybe<Scalars['String']>;
  /** An avatar URL for the person or null if not provided. */
  pictureUrl?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['ID']>;
  preferredName?: Maybe<Scalars['String']>;
  schoolIdentifier?: Maybe<Scalars['String']>;
  selectionCode?: Maybe<Scalars['String']>;
  slotIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  startDate?: Maybe<Scalars['Date']>;
  /** A persons related ac2 student profile. */
  student?: Maybe<StudentNode>;
  suffix?: Maybe<Scalars['String']>;
  /** Labels of teams the user is an active member of */
  teamLabels?: Maybe<Scalars['String']>;
  /** A specific team membership of the person. */
  teamMembership?: Maybe<TeamMemberNode>;
  /** The teams the person is a member of. */
  teams?: Maybe<Array<Maybe<TeamNode>>>;
  timeZone?: Maybe<Scalars['String']>;
  /** Title of the person */
  title?: Maybe<Scalars['String']>;
  travelWeight?: Maybe<Scalars['Int']>;
  twitterAccount?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  usesExternalAuthentication?: Maybe<Scalars['Boolean']>;
};

export type ReservingPersonGroupInput = {
  manuallyOpen?: InputMaybe<Scalars['Boolean']>;
  openAt?: InputMaybe<Scalars['DateTime']>;
  users: Array<InputMaybe<Scalars['String']>>;
};

/** Node for a reserving person group, which corresponds to selections that are able to book slots on a signup */
export type ReservingPersonGroupNode = {
  isOpen?: Maybe<Scalars['Boolean']>;
  manuallyOpen?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  personIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  persons?: Maybe<Array<Maybe<SignupsPersonNode>>>;
  users: Array<Maybe<Scalars['String']>>;
  visibility?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResultsVisibilityInput = {
  viewResultsInEmail?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewResultsInTeamworks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum ReviewStatus {
  Dismissed = 'dismissed',
  None = 'none',
  Reviewed = 'reviewed',
}

export enum ReviewStatusFilter {
  All = 'all',
  Dismissed = 'dismissed',
  NotDismissed = 'notDismissed',
  Reviewed = 'reviewed',
  Unreviewed = 'unreviewed',
}

/** An enumeration. */
export enum RolloverType {
  CarryOver = 'CARRY_OVER',
  IncrementByOne = 'INCREMENT_BY_ONE',
  ResetToBlank = 'RESET_TO_BLANK',
}

export type RoommateCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type RoommateDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type RoommateInput = {
  customEmail?: InputMaybe<Scalars['String']>;
  customName?: InputMaybe<Scalars['String']>;
  customPhone?: InputMaybe<Scalars['String']>;
  roommatePerson?: InputMaybe<Scalars['ID']>;
};

export type RoommateUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type RosterInfoDetails = {
  teamRosterId?: Maybe<Scalars['String']>;
  tmpYearLabel?: Maybe<Scalars['String']>;
};

export type RosterInfoNode = {
  activeRosters?: Maybe<Array<Maybe<RosterInfoDetails>>>;
  missingRosters?: Maybe<Array<Maybe<RosterInfoDetails>>>;
};

export type S3FileObject = {
  /** name of shared file for this s3 file */
  name?: InputMaybe<Scalars['String']>;
  /** size of s3 file */
  size?: InputMaybe<Scalars['Int']>;
  /** url to file on s3 - includes server file name */
  url?: InputMaybe<Scalars['String']>;
};

export type SaveFormInput = {
  completionRole: Scalars['String'];
};

export type SaveFormPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formAssignmentCompletionRole?: Maybe<FormAssignmentCompletionRoleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SaveFormScheduleArgsInput = {
  additionalResultsTo?: InputMaybe<Array<InputMaybe<AdditionalResultsVisibilityInput>>>;
  additionalRoleAssignments?: InputMaybe<Array<InputMaybe<SubsequentRoleInput>>>;
  assignmentTimezone?: InputMaybe<Scalars['String']>;
  captchaEnabled?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['String'];
  flaggedFormAlerts?: InputMaybe<FlaggingFormInput>;
  folder?: InputMaybe<Scalars['String']>;
  form: Scalars['String'];
  formSchedule?: InputMaybe<Scalars['String']>;
  initialRoleAssignment: InitialRoleInput;
  notes?: InputMaybe<Scalars['String']>;
  notifyAssignees: NotifyAssignees;
  recurRule: Scalars['String'];
  recurRuleText: Scalars['String'];
  relativeDeactivationDateTimeNumber?: InputMaybe<Scalars['Int']>;
  relativeDeactivationDateTimeTime?: InputMaybe<Scalars['Time']>;
  relativeDeactivationDateTimeType?: InputMaybe<RelativeDateType>;
  relativeDueDateNumber?: InputMaybe<Scalars['Int']>;
  relativeDueDateTime?: InputMaybe<Scalars['Time']>;
  relativeDueDateType?: InputMaybe<RelativeDateType>;
  resultsTo?: InputMaybe<ResultsVisibilityInput>;
  start: Scalars['DateTime'];
};

export type SaveFormSchedulePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  formSchedule?: Maybe<FormScheduleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SavedReportCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  savedReport?: Maybe<SavedReportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SavedReportDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  savedReport?: Maybe<SavedReportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SavedReportInput = {
  filters: Scalars['JSONString'];
  label: Scalars['String'];
  sharedWithPersonIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  viewTarget: Scalars['String'];
};

export type SavedReportNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  filters: Scalars['JSONString'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  label: Scalars['String'];
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['Int']>;
  persons?: Maybe<SavedReportPersonNodeConnection>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  savedReportId: Scalars['ID'];
  savedViewPersons?: Maybe<Array<Maybe<SavedReportPersonNode>>>;
  team?: Maybe<TeamNode>;
  teamId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  viewTarget: Scalars['String'];
};

export type SavedReportNodePersonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  savedReportId?: InputMaybe<Scalars['TWFilter_Int']>;
  savedReportPersonId?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<SavedReportPersonSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type SavedReportNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SavedReportNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SavedReportNode` and its cursor. */
export type SavedReportNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SavedReportNode>;
};

/** A person associated with a Saved Report */
export type SavedReportPersonNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** person_id */
  personId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  savedReport?: Maybe<SavedReportNode>;
  /** saved_report_id */
  savedReportId?: Maybe<Scalars['Int']>;
  savedReportPersonId: Scalars['String'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type SavedReportPersonNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SavedReportPersonNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SavedReportPersonNode` and its cursor. */
export type SavedReportPersonNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SavedReportPersonNode>;
};

/** An enumeration. */
export enum SavedReportPersonSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  SavedReportIdAsc = 'saved_report_id_asc',
  SavedReportIdDesc = 'saved_report_id_desc',
  SavedReportPersonIdAsc = 'saved_report_person_id_asc',
  SavedReportPersonIdDesc = 'saved_report_person_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type SavedReportUpdateInput = {
  label: Scalars['String'];
  sharedWithPersonIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  unsharedWithPersonIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type SavedReportUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  savedReport?: Maybe<SavedReportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum SearchOptionsEnum {
  EmailAddress = 'email_address',
  ExternalEmail = 'external_email',
  ExternalName = 'external_name',
  FirstName = 'first_name',
  LastName = 'last_name',
  PreferredName = 'preferred_name',
}

export type SeasonConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SeasonEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type SeasonCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  season?: Maybe<SeasonObjectType>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SeasonDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  season?: Maybe<SeasonObjectType>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `Season` and its cursor. */
export type SeasonEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SeasonObjectType>;
};

export type SeasonInput = {
  label: Scalars['String'];
};

export type SeasonObjectType = Node & {
  createdDate?: Maybe<Scalars['DateTime']>;
  hasTrips?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
  tripCount?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum SeasonSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EndDateAsc = 'end_date_asc',
  EndDateDesc = 'end_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SeasonIdAsc = 'season_id_asc',
  SeasonIdDesc = 'season_id_desc',
  SportSchoolIdAsc = 'sport_school_id_asc',
  SportSchoolIdDesc = 'sport_school_id_desc',
  StartDateAsc = 'start_date_asc',
  StartDateDesc = 'start_date_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type SeasonUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  season?: Maybe<SeasonObjectType>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An entity that can be selected via a selection code. */
export type Selectable = {
  /** The type of group */
  groupType?: Maybe<GroupType>;
  /** The user-displayed name of the selectable. */
  label?: Maybe<Scalars['String']>;
  peopleCount?: Maybe<Scalars['Int']>;
  /** The label including the team name. Applies only to group selections. */
  pluralLabel?: Maybe<Scalars['String']>;
  /** The unique identifier used to select members of the selectable. */
  selectionCode?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
};

/** A selection group. It can be selected and contains members that can be individually selected. */
export type SelectionGroup = Selectable & {
  /** The type of group */
  groupType?: Maybe<GroupType>;
  /** The user-displayed name of the selectable. */
  label?: Maybe<Scalars['String']>;
  /** A list of people that are members of this group. */
  people?: Maybe<Array<Maybe<PersonNode>>>;
  peopleCount?: Maybe<Scalars['Int']>;
  /** The label including the team name. Applies only to group selections. */
  pluralLabel?: Maybe<Scalars['String']>;
  /** The unique identifier used to select members of the selectable. */
  selectionCode?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
};

export type SelectionGroupFiltersInput = {
  /** Return only the specified people */
  allowedPeople?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Exclude athletes from the response */
  excludeAthletes?: InputMaybe<Scalars['Boolean']>;
  /** Exclude current person from the response */
  excludeSelf?: InputMaybe<Scalars['Boolean']>;
  /** Include contact groups in the response */
  includeContacts?: InputMaybe<Scalars['Boolean']>;
  /** Include custom groups in the response */
  includeCustomGroups?: InputMaybe<Scalars['Boolean']>;
  /** Include group containing every team member */
  includeEveryoneGroups?: InputMaybe<Scalars['Boolean']>;
  /** Return only the specified person type groups */
  onlyPersonTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter results to include only options for the selected team */
  selectedTeam?: InputMaybe<Scalars['ID']>;
  /** Return only the specified selection options */
  selectionOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A selection-restricting permission */
  selectionRestrictingPermission?: InputMaybe<SelectionRestrictingPermission>;
};

export type SelectionNode = {
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  persons?: Maybe<Array<Maybe<PersonNode>>>;
};

/**
 * The type of selection-restricting permission.
 *
 * When these permissions are granted for a user on a team, a set of user types must also be selected. These user types
 * are then used to limit the result set for user selection options when designated as a filter.
 */
export enum SelectionRestrictingPermission {
  /** Ability to add and modify appointments to the calendars of other users. */
  CalendarAddApptOthers = 'CALENDAR_ADD_APPT_OTHERS',
  /** Ability to view the calendars and appointments of other users. */
  CalendarViewOthers = 'CALENDAR_VIEW_OTHERS',
  /** Ability to send messages to users on team. */
  MessagingSend = 'MESSAGING_SEND',
  /** Ability to send messages to family contacts. */
  MessagingSendContacts = 'MESSAGING_SEND_CONTACTS',
  /** Ability to view list of staff or athletes profile information. */
  ProfileView = 'PROFILE_VIEW',
  /** Ability to view the staff personal and contact information (phone numbers, email, addresses, including Core Attributes). */
  StaffContactDetailsView = 'STAFF_CONTACT_DETAILS_VIEW',
  /** Ability to view list of team staff and their profile information. */
  StaffProfileView = 'STAFF_PROFILE_VIEW',
  /** Ability to assign electronic forms to those within and outside of the department. */
  SubmittableFormAssign = 'SUBMITTABLE_FORM_ASSIGN',
}

export type SendTravelPartyAssignmentInput = {
  customSelection?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludeEmpty?: InputMaybe<Scalars['Boolean']>;
  includeBus?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  includeFlight?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  includeRoom?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  includeRoommate?: InputMaybe<Scalars['Boolean']>;
  selectionType: TripCalendarVisibilityEnum;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  sendText?: InputMaybe<Scalars['Boolean']>;
};

export type SendTravelPartyAssignmentsPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SessionAttendanceConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SessionAttendanceEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SessionAttendance` and its cursor. */
export type SessionAttendanceEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SessionAttendanceNode>;
};

export type SessionAttendanceNode = Node & {
  /** If there is a Locate checkin result associated with this, this is its id */
  checkinResultId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** meta data about this model */
  meta?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  session?: Maybe<SessionNode>;
  sessionAttendanceId: Scalars['ID'];
  sessionId: Scalars['Int'];
  /** Student's attendance status for the session */
  status: SessionAttendanceStatus;
  student?: Maybe<StudentNode>;
  studentId: Scalars['Int'];
  students?: Maybe<StudentConnection>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type SessionAttendanceNodeStudentsArgs = {
  academicStatus?: InputMaybe<Scalars['TWFilter_AcademicStatus']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  cumulativeGpa?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  eligibilityYear?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['TWFilter_String']>;
  isAtRisk?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  preferredName?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<StudentSortEnum>>>;
  studentId?: InputMaybe<Scalars['TWFilter_ID']>;
  teamIds?: InputMaybe<Scalars['TWFilter_IntArray']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum SessionAttendanceSortEnum {
  Ac2SessionAttendanceIdAsc = 'ac2_session_attendance_id_asc',
  Ac2SessionAttendanceIdDesc = 'ac2_session_attendance_id_desc',
  CheckinResultIdAsc = 'checkin_result_id_asc',
  CheckinResultIdDesc = 'checkin_result_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  MetaAsc = 'meta_asc',
  MetaDesc = 'meta_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  SessionIdAsc = 'session_id_asc',
  SessionIdDesc = 'session_id_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  StudentIdAsc = 'student_id_asc',
  StudentIdDesc = 'student_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Represents whether a student attended a tutoring session. */
export enum SessionAttendanceStatus {
  Attended = 'attended',
  Completed = 'completed',
  Facility = 'facility',
  NoFaultAbsence = 'no_fault_absence',
  Other = 'other',
  OtherCanceled = 'other_canceled',
  PartialSession = 'partial_session',
  Pending = 'pending',
  StudentAthleteHealthWellness = 'student_athlete_health_wellness',
  StudentCanceled = 'student_canceled',
  StudentNoShow = 'student_no_show',
  TeamScheduleChange = 'team_schedule_change',
  TravelDelay = 'travel_delay',
  TutorCanceled = 'tutor_canceled',
  Weather = 'weather',
}

export type SessionBulkCancelInput = {
  appointmentOccurances: Array<InputMaybe<Scalars['ID']>>;
  cancellationReason: SessionCancelledReason;
  notes?: InputMaybe<Scalars['String']>;
  sendAlerts?: InputMaybe<Scalars['String']>;
  students: Array<InputMaybe<Scalars['ID']>>;
};

export type SessionBulkCancelPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  session?: Maybe<Array<Maybe<SessionNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum SessionCancelledReason {
  Facility = 'facility',
  Other = 'other',
  StudentAthleteHealthWellness = 'student_athlete_health_wellness',
  TeamScheduleChange = 'team_schedule_change',
  TravelDelay = 'travel_delay',
  Weather = 'weather',
}

export type SessionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SessionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type SessionDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  session?: Maybe<SessionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `Session` and its cursor. */
export type SessionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SessionNode>;
};

export type SessionNode = Node & {
  appointmentOccurrence?: Maybe<AppointmentOccurrence>;
  /** Specific appointment occurrence (Teamworks appointment occurrence) that this attendance covers */
  appointmentOccurrenceId?: Maybe<Scalars['Int']>;
  course?: Maybe<CourseNode>;
  courseId?: Maybe<Scalars['Int']>;
  courseSection?: Maybe<CourseSectionNode>;
  courseSectionId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** If tutor filled form he must have fill rules_checked report_answers */
  hasFilledForm?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /**
   * Returns true if all attendance statuses are in canceled statuses
   *         This is currently only way to detect that session was cancelled and doesn't need report
   *         Helper for  @report_status.expression
   *
   */
  isCanceled?: Maybe<Scalars['String']>;
  /** Is session soft deleted */
  isDeleted: Scalars['Boolean'];
  /** End date time of the session in UTC */
  occurrenceEndDateTime?: Maybe<Scalars['DateTime']>;
  /** Location of the session. */
  occurrenceLocation?: Maybe<Scalars['String']>;
  /** Start date time of the session in UTC */
  occurrenceStartDateTime?: Maybe<Scalars['DateTime']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Report answers in JSONB object */
  reportAnswers: Scalars['JSONString'];
  /**
   *
   *         returns
   *         if all child session_attendances is cancelled
   *             return SessionReportStatus.session_canceled
   *         if report_answers is filled
   *             return SessionReportStatus.report_complete
   *         if report_answers is not filled
   *             return SessionReportStatus.needs_report
   *
   */
  reportStatus?: Maybe<Scalars['String']>;
  sessionAttendances?: Maybe<SessionAttendanceConnection>;
  sessionId: Scalars['ID'];
  sessionRequest?: Maybe<SessionRequestNode>;
  sessionRequestId?: Maybe<Scalars['Int']>;
  /** StudentIds for each session */
  studentIds: Array<Maybe<Scalars['Int']>>;
  students?: Maybe<StudentConnection>;
  subject?: Maybe<SubjectNode>;
  subjectId?: Maybe<Scalars['Int']>;
  term?: Maybe<TermNode>;
  termId: Scalars['Int'];
  tutor?: Maybe<TutorNode>;
  tutorId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type SessionNodeSessionAttendancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  checkinResultId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sessionAttendanceId?: InputMaybe<Scalars['TWFilter_ID']>;
  sessionId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<SessionAttendanceSortEnum>>>;
  status?: InputMaybe<Scalars['TWFilter_SessionAttendanceStatus']>;
  studentId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type SessionNodeStudentsArgs = {
  academicStatus?: InputMaybe<Scalars['TWFilter_AcademicStatus']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  cumulativeGpa?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  eligibilityYear?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['TWFilter_String']>;
  isAtRisk?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  preferredName?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<StudentSortEnum>>>;
  studentId?: InputMaybe<Scalars['TWFilter_ID']>;
  teamIds?: InputMaybe<Scalars['TWFilter_IntArray']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type SessionRequestConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SessionRequestEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SessionRequest` and its cursor. */
export type SessionRequestEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SessionRequestNode>;
};

export type SessionRequestNode = Node & {
  course?: Maybe<CourseNode>;
  courseId?: Maybe<Scalars['Int']>;
  courseSection?: Maybe<CourseSectionNode>;
  courseSectionId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Requester's notes (rich text allowed) for the session. */
  notes?: Maybe<Scalars['String']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  sessionRequestId: Scalars['ID'];
  sessions?: Maybe<SessionConnection>;
  /** Current state of session. */
  status: SessionRequestStatus;
  studentIds: Array<Maybe<Scalars['Int']>>;
  students?: Maybe<StudentConnection>;
  subject?: Maybe<SubjectNode>;
  subjectId?: Maybe<Scalars['Int']>;
  term?: Maybe<TermNode>;
  termId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type SessionRequestNodeSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentOccurrenceId?: InputMaybe<Scalars['TWFilter_Int']>;
  before?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasFilledForm?: InputMaybe<Scalars['TWFilter_String']>;
  isCanceled?: InputMaybe<Scalars['TWFilter_String']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  occurrenceEndDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  occurrenceLocation?: InputMaybe<Scalars['TWFilter_String']>;
  occurrenceStartDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reportAnswers?: InputMaybe<Scalars['TWFilter_JSONString']>;
  reportStatus?: InputMaybe<Scalars['TWFilter_String']>;
  sessionId?: InputMaybe<Scalars['TWFilter_ID']>;
  sessionRequestId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<SessionSortEnum>>>;
  students?: InputMaybe<Scalars['TWFilter_String']>;
  subjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type SessionRequestNodeStudentsArgs = {
  academicStatus?: InputMaybe<Scalars['TWFilter_AcademicStatus']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  cumulativeGpa?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  eligibilityYear?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['TWFilter_String']>;
  isAtRisk?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  preferredName?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<StudentSortEnum>>>;
  studentId?: InputMaybe<Scalars['TWFilter_ID']>;
  teamIds?: InputMaybe<Scalars['TWFilter_IntArray']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum SessionRequestSortEnum {
  Ac2SessionRequestIdAsc = 'ac2_session_request_id_asc',
  Ac2SessionRequestIdDesc = 'ac2_session_request_id_desc',
  CourseIdAsc = 'course_id_asc',
  CourseIdDesc = 'course_id_desc',
  CourseSectionIdAsc = 'course_section_id_asc',
  CourseSectionIdDesc = 'course_section_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  StudentIdsAsc = 'student_ids_asc',
  StudentIdsDesc = 'student_ids_desc',
  SubjectIdAsc = 'subject_id_asc',
  SubjectIdDesc = 'subject_id_desc',
  TermIdAsc = 'term_id_asc',
  TermIdDesc = 'term_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Represents a tutoring session request's status. */
export enum SessionRequestStatus {
  Closed = 'closed',
  New = 'new',
  Scheduled = 'scheduled',
}

/** An enumeration. */
export enum SessionSortEnum {
  Ac2SessionIdAsc = 'ac2_session_id_asc',
  Ac2SessionIdDesc = 'ac2_session_id_desc',
  AppointmentOccurrenceIdAsc = 'appointment_occurrence_id_asc',
  AppointmentOccurrenceIdDesc = 'appointment_occurrence_id_desc',
  CourseIdAsc = 'course_id_asc',
  CourseIdDesc = 'course_id_desc',
  CourseSectionIdAsc = 'course_section_id_asc',
  CourseSectionIdDesc = 'course_section_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  OccurrenceEndDateTimeAsc = 'occurrence_end_date_time_asc',
  OccurrenceEndDateTimeDesc = 'occurrence_end_date_time_desc',
  OccurrenceLocationAsc = 'occurrence_location_asc',
  OccurrenceLocationDesc = 'occurrence_location_desc',
  OccurrenceStartDateTimeAsc = 'occurrence_start_date_time_asc',
  OccurrenceStartDateTimeDesc = 'occurrence_start_date_time_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ReportAnswersAsc = 'report_answers_asc',
  ReportAnswersDesc = 'report_answers_desc',
  SessionRequestIdAsc = 'session_request_id_asc',
  SessionRequestIdDesc = 'session_request_id_desc',
  StudentIdsAsc = 'student_ids_asc',
  StudentIdsDesc = 'student_ids_desc',
  SubjectIdAsc = 'subject_id_asc',
  SubjectIdDesc = 'subject_id_desc',
  TermIdAsc = 'term_id_asc',
  TermIdDesc = 'term_id_desc',
  TutorIdAsc = 'tutor_id_asc',
  TutorIdDesc = 'tutor_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type SessionsStudentSchema = {
  fullName?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['Int']>;
  pictureUrl?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  teamIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** Connection class for shared file nodes, with result count */
export type SharedFileConnection = Node & {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SharedFileEdge>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type SharedFileCreatePayload = {
  Output?: Maybe<SharedFileNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  sharedFile?: Maybe<SharedFileNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SharedFileDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  sharedFile?: Maybe<SharedFileNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `SharedFile` and its cursor. */
export type SharedFileEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SharedFileNode>;
};

export type SharedFileInput = {
  canDownload?: InputMaybe<Scalars['Boolean']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileNotes?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<Scalars['String']>;
  folderId?: InputMaybe<Scalars['Int']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** The profile this file is/will be attached to */
  personId?: InputMaybe<Scalars['Int']>;
  s3Files?: InputMaybe<Array<InputMaybe<S3FileObject>>>;
  sendFileAlert?: InputMaybe<Scalars['String']>;
  /** The trip this file is attached to */
  trip?: InputMaybe<Scalars['ID']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Exposes SharedFile objects */
export type SharedFileNode = Node & {
  announcementId?: Maybe<Scalars['Int']>;
  appointmentComplianceAuditReviewId?: Maybe<Scalars['Int']>;
  canDownload?: Maybe<Scalars['Boolean']>;
  /** Whether person can edit (edit & delete) this folder */
  canEditFile?: Maybe<Scalars['Boolean']>;
  competitionId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  /** Person who created the file */
  creator?: Maybe<PersonNode>;
  /** Link to file; mobile uses this. Mobile previewer does not work with pretty url */
  downloadUrl?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileNameServer?: Maybe<Scalars['String']>;
  fileNotes?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  /** Folder in which the file is saved */
  folder?: Maybe<FolderNode>;
  folderId?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isPinned?: Maybe<Scalars['Boolean']>;
  officiatingSchoolId?: Maybe<Scalars['Int']>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /** The profile this file is attached to */
  personId?: Maybe<Scalars['Int']>;
  pk?: Maybe<Scalars['Int']>;
  /** Link to file using client provided filename (not s3 server filename) */
  prettyDownloadUrl?: Maybe<Scalars['String']>;
  /** Date when the file was first shared with the calling user  */
  sharedDate?: Maybe<Scalars['String']>;
  sharedFileId?: Maybe<Scalars['Int']>;
  /** Person who first shared the file with the calling user */
  sharer?: Maybe<PersonNode>;
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['Int']>;
  travelVendorId?: Maybe<Scalars['Int']>;
  trip?: Maybe<Trip>;
  /** The trip this file is attached to */
  tripId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['Int']>;
  visibility?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum SharedFileSortEnum {
  AnnouncementIdAsc = 'announcement_id_asc',
  AnnouncementIdDesc = 'announcement_id_desc',
  AppointmentComplianceAuditReviewIdAsc = 'appointment_compliance_audit_review_id_asc',
  AppointmentComplianceAuditReviewIdDesc = 'appointment_compliance_audit_review_id_desc',
  CanDownloadAsc = 'can_download_asc',
  CanDownloadDesc = 'can_download_desc',
  CompetitionIdAsc = 'competition_id_asc',
  CompetitionIdDesc = 'competition_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  FileNameAsc = 'file_name_asc',
  FileNameDesc = 'file_name_desc',
  FileNameServerAsc = 'file_name_server_asc',
  FileNameServerDesc = 'file_name_server_desc',
  FileNotesAsc = 'file_notes_asc',
  FileNotesDesc = 'file_notes_desc',
  FileSizeAsc = 'file_size_asc',
  FileSizeDesc = 'file_size_desc',
  FolderIdAsc = 'folder_id_asc',
  FolderIdDesc = 'folder_id_desc',
  IsPinnedAsc = 'is_pinned_asc',
  IsPinnedDesc = 'is_pinned_desc',
  OfficiatingSchoolIdAsc = 'officiating_school_id_asc',
  OfficiatingSchoolIdDesc = 'officiating_school_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SharedFileIdAsc = 'shared_file_id_asc',
  SharedFileIdDesc = 'shared_file_id_desc',
  SportSchoolIdAsc = 'sport_school_id_asc',
  SportSchoolIdDesc = 'sport_school_id_desc',
  TravelVendorIdAsc = 'travel_vendor_id_asc',
  TravelVendorIdDesc = 'travel_vendor_id_desc',
  TripIdAsc = 'trip_id_asc',
  TripIdDesc = 'trip_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  VideoIdAsc = 'video_id_asc',
  VideoIdDesc = 'video_id_desc',
}

/** An enumeration. */
export enum SharedFileSortEnum_Shared_Date_Updated_Date {
  SharedDateAsc = 'shared_date_asc',
  SharedDateDesc = 'shared_date_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type SharedFileUpdatePayload = {
  Output?: Maybe<SharedFileNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  sharedFile?: Maybe<SharedFileNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SharedNavbar = {
  /** List of apps currently available to switch to in shared navbar */
  appAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Shared navbar bottom color */
  bottomColor?: Maybe<Scalars['String']>;
  /** Shared navbar icon/logo image URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** Inactivity timeout in minutes */
  inactivityTimeout?: Maybe<Scalars['Int']>;
  /** List of target URLs for apps in the shared navbar app switcher */
  targetUrls?: Maybe<Array<Maybe<AppTargetUrl>>>;
  /** Custom branding for all teams in the org */
  teamLogos?: Maybe<Array<Maybe<TeamLogo>>>;
  /** Shared navbar top color */
  topColor?: Maybe<Scalars['String']>;
};

/** Exposes SharedFileVisibility objects */
export type SharingRecordNode = Node & {
  asSharedFileVisibilityId: Scalars['ID'];
  assignSelectTypeId: Scalars['Int'];
  assigneeId: Scalars['Int'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  fullAssigneeCode?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  sharedFile?: Maybe<SharedFileNode>;
  sharedFileId: Scalars['Int'];
  team?: Maybe<TeamNode>;
  teamId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type SharingRecordNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SharingRecordNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SharingRecordNode` and its cursor. */
export type SharingRecordNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SharingRecordNode>;
};

export type SignedUrl = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  url?: Maybe<Scalars['String']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SignedUrlInput = {
  /** content type of file we want to upload to s3 */
  contentType?: InputMaybe<Scalars['String']>;
  /** extension of file we want to upload to s3 */
  fileExtension?: InputMaybe<Scalars['String']>;
  /** file name we want to upload to s3 to make request unique */
  fileName?: InputMaybe<Scalars['String']>;
};

export type SignupAttachmentBulkCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  fileAttachment?: Maybe<Array<Maybe<FileAttachment>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SignupConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SignupEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Signup` and its cursor. */
export type SignupEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SignupNode>;
};

export type SignupInput = {
  adminsReservingAlerts?: InputMaybe<Scalars['String']>;
  appointmentTypeIcon?: InputMaybe<Scalars['String']>;
  appointmentTypeId: Scalars['Int'];
  apptsRelatedToExistingReservations?: InputMaybe<ApptBehaviorOnModify>;
  cancellationDeadline?: InputMaybe<TimeDeltaInput>;
  conflictHandler?: InputMaybe<Scalars['String']>;
  extraApptDetails?: InputMaybe<ExtraApptDetailsInput>;
  individualReservationLimitCount?: InputMaybe<Scalars['Int']>;
  individualReservationLimitPer?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  mandatory?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  recurrence: Scalars['String'];
  reservableResources: Array<InputMaybe<Scalars['String']>>;
  reservationDeadline?: InputMaybe<TimeDeltaInput>;
  reservingPersonGroups: Array<InputMaybe<ReservingPersonGroupInput>>;
  signupId?: InputMaybe<Scalars['UUID']>;
  slotBreaks?: InputMaybe<Array<InputMaybe<TimePeriodInput>>>;
  slotCapacity?: InputMaybe<Scalars['Int']>;
  slotDuration: TimeDeltaInput;
  slotInterval: TimeDeltaInput;
  timezone: Scalars['String'];
  title: Scalars['String'];
  usersReservingAlerts?: InputMaybe<Scalars['String']>;
};

export type SignupMobileDashboardNode = {
  mandatoryCount?: Maybe<Scalars['Int']>;
  newCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** GQL node representing a signup block on the calendar */
export type SignupNode = Node & {
  /** Regular breaks in time for lunch, etc. These are repeated for each day in the reccurence. */
  SlotBreaks?: Maybe<Scalars['JSONString']>;
  adminsReservingAlerts?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<SignupsAppointmentTypeNode>;
  /** The CSS class or LUT name for an icon for this event type. */
  appointmentTypeIcon?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['ID']>;
  /** FK to the appointment monitor that covers slot appointments. */
  apptMonitorId?: Maybe<Scalars['Int']>;
  attachments?: Maybe<Array<Maybe<FileAttachment>>>;
  /** FK to the calendar monitor that covers resource calendars */
  calMonitorId?: Maybe<Scalars['Int']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  cancellationDeadline?: Maybe<TimeDeltaNode>;
  /** Keep or close slots which otherwise have calendar conflicts. */
  conflictHandler?: Maybe<Conflicthandler>;
  conflictingAppointments?: Maybe<Array<Maybe<SignupsAppointmentNode>>>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<SignupsPersonNode>;
  endDate?: Maybe<Scalars['Date']>;
  endTime?: Maybe<Scalars['Time']>;
  extraApptDetails?: Maybe<ExtraApptDetailsNode>;
  firstDate?: Maybe<Scalars['Date']>;
  firstStartDatetime?: Maybe<Scalars['DateTime']>;
  firstStartTime?: Maybe<Scalars['Time']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The maximum number of reservations a single individual can make. NULL here means unlimited. Otherwise should be positive */
  individualReservationLimitCount?: Maybe<Scalars['Int']>;
  /** The period over which the reservation limit renews. E.g. once/day, once/year, once, etc. */
  individualReservationLimitPer?: Maybe<Setperiod>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  lastDate?: Maybe<Scalars['Date']>;
  lastEndDatetime?: Maybe<Scalars['DateTime']>;
  lastEndTime?: Maybe<Scalars['Time']>;
  lastStartDatetime?: Maybe<Scalars['DateTime']>;
  lastStartTime?: Maybe<Scalars['Time']>;
  location?: Maybe<Scalars['String']>;
  mandatory?: Maybe<Scalars['Boolean']>;
  myReservations?: Maybe<Array<Maybe<MyReservationNode>>>;
  notes?: Maybe<Scalars['String']>;
  numReservations?: Maybe<Scalars['Int']>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** The recurrence rule (RRULE) for the start time of the first slot on each day. MUST have a fixed beginning and end */
  recurrence?: Maybe<Scalars['String']>;
  reservablePersons?: Maybe<Array<Maybe<SignupsPersonNode>>>;
  reservationDeadline?: Maybe<TimeDeltaNode>;
  reservedPersons?: Maybe<Array<Maybe<ReservedPersonNode>>>;
  reservingPersonGroups?: Maybe<Array<Maybe<ReservingPersonGroupNode>>>;
  resourceCalendarConflictingAppointments?: Maybe<Array<Maybe<SignupsAppointmentNode>>>;
  signupId: Scalars['String'];
  slotBreaks?: Maybe<Array<Maybe<TimePeriodNode>>>;
  /** The number of people who can book the same slot. */
  slotCapacity?: Maybe<Scalars['Int']>;
  slotDates?: Maybe<Array<Maybe<SlotDateNode>>>;
  /** The duration of a slot */
  slotDuration?: Maybe<Scalars['String']>;
  /** The duration of a break between slots */
  slotInterval?: Maybe<Scalars['String']>;
  slots?: Maybe<Array<Maybe<SlotNode>>>;
  startDate?: Maybe<Scalars['Date']>;
  startTime?: Maybe<Scalars['Time']>;
  /** The timezone in the event timespan. */
  timezone?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalCapacity?: Maybe<Scalars['Int']>;
  totalReservations?: Maybe<Scalars['Int']>;
  unreservedPersons?: Maybe<Array<Maybe<SignupsPersonNode>>>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  usersReservingAlerts?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** GQL node representing a signup block on the calendar */
export type SignupNodeConflictingAppointmentsArgs = {
  selectionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** GQL node representing a signup block on the calendar */
export type SignupNodeSlotDatesArgs = {
  filterFullSlotDates?: InputMaybe<Scalars['Boolean']>;
};

/** GQL node representing a signup block on the calendar */
export type SignupNodeSlotsArgs = {
  endDatetime?: InputMaybe<Scalars['DateTime']>;
  myReservationsOnly?: InputMaybe<Scalars['Boolean']>;
  reservationsOnly?: InputMaybe<Scalars['Boolean']>;
  slotId?: InputMaybe<Scalars['UUID']>;
  startDatetime?: InputMaybe<Scalars['DateTime']>;
};

export type SignupsAppointmentNode = {
  appointmentOccurrences?: Maybe<Array<Maybe<SignupsAppointmentOccurrenceNode>>>;
  attendeeCount?: Maybe<Scalars['Int']>;
  complianceType?: Maybe<Scalars['String']>;
  creator?: Maybe<SignupsPersonNode>;
  globalId?: Maybe<Scalars['ID']>;
  isClass?: Maybe<Scalars['Boolean']>;
  isCountable?: Maybe<Scalars['Boolean']>;
  isExternal?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  rrule?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<SignupsSelectionNode>>>;
};

export type SignupsAppointmentOccurrenceNode = {
  appointmentId?: Maybe<Scalars['Int']>;
  appointmentOccurrenceId?: Maybe<Scalars['Int']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
};

export type SignupsAppointmentTypeNode = {
  appointmentTypeId?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<SignupsSelectionNode>>>;
};

export type SignupsMessageUsers = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  messageId?: Maybe<Scalars['ID']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SignupsMessageUsersInput = {
  alertType?: InputMaybe<Scalars['String']>;
  messageBody?: InputMaybe<Scalars['String']>;
  selectionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  signupId?: InputMaybe<Scalars['UUID']>;
};

export type SignupsPersonNode = {
  active?: Maybe<Scalars['Boolean']>;
  activeMemberships?: Maybe<Array<Maybe<TeamMemberNode>>>;
  /** Persons Automobile */
  automobile?: Maybe<AutomobileNode>;
  canModifyNotes: Scalars['Boolean'];
  canModifyProfileAddresses: Scalars['Boolean'];
  canModifyProfileCustomContacts: Scalars['Boolean'];
  canModifyProfileFiles: Scalars['Boolean'];
  canModifyProfileRoommates: Scalars['Boolean'];
  canModifyProfileTwContacts: Scalars['Boolean'];
  canViewAllPersonalDetails: Scalars['Boolean'];
  canViewPersonalDetails: Scalars['Boolean'];
  canViewProfile: Scalars['Boolean'];
  canViewProfileCalendar: Scalars['Boolean'];
  canViewProfileFiles: Scalars['Boolean'];
  canViewProfileForms: Scalars['Boolean'];
  canViewProfileNotes: Scalars['Boolean'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  customAttributeValues?: Maybe<Scalars['JSONString']>;
  /** The person's date of birth in YYYY-MM-DD format */
  dateOfBirth?: Maybe<Scalars['String']>;
  /** Name as it should appear to the end user on the front end. */
  displayName?: Maybe<Scalars['String']>;
  driversLicenseExpirationDate?: Maybe<Scalars['DateTime']>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  driversLicenseState?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  emailAddressPersonal?: Maybe<Scalars['String']>;
  facebookAccount?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasLoginAccess?: Maybe<Scalars['Boolean']>;
  hasMobileBeenValidated?: Maybe<Scalars['Boolean']>;
  /** Whether this person has a mobile token. */
  hasMobileToken?: Maybe<Scalars['Boolean']>;
  /** Whether this person has linked user account */
  hasUserAccount?: Maybe<Scalars['Boolean']>;
  /** The first and last initials of the person or null if no name is provided. */
  initials?: Maybe<Scalars['String']>;
  /** A URL to an expiring page for registering a user or resetting a password. */
  inviteUrl?: Maybe<Scalars['String']>;
  isContact?: Maybe<Scalars['Boolean']>;
  /** Whether this person is all teams */
  isOrgLevelUser?: Maybe<Scalars['Boolean']>;
  /** The last date that the user logged in on this account */
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  /** The number of logins this person has made */
  loginCount?: Maybe<Scalars['Int']>;
  middleName?: Maybe<Scalars['String']>;
  /** A formatted mobile phone number */
  mobilePhone?: Maybe<Scalars['String']>;
  /**
   * Name formatted as <preferred name> <last name>.
   * @deprecated Use SignupsPersonNode.displayName instead
   */
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['Int']>;
  /** @deprecated Use SignupsPersonNode.mobilePhone instead */
  phoneCell?: Maybe<Scalars['String']>;
  phoneFax?: Maybe<Scalars['String']>;
  phoneHome?: Maybe<Scalars['String']>;
  phoneOffice?: Maybe<Scalars['String']>;
  phoneTeamworks?: Maybe<Scalars['String']>;
  /** @deprecated Use SignupsPersonNode.pictureUrl instead */
  picture?: Maybe<Scalars['String']>;
  /** @deprecated Use SignupsPersonNode.pictureUrl instead */
  pictureDownloadUrl?: Maybe<Scalars['String']>;
  /** @deprecated Use SignupsPersonNode.pictureUrl instead */
  pictureImage?: Maybe<Scalars['String']>;
  /** An avatar URL for the person or null if not provided. */
  pictureUrl?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['ID']>;
  preferredName?: Maybe<Scalars['String']>;
  schoolIdentifier?: Maybe<Scalars['String']>;
  selectionCode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  /** A persons related ac2 student profile. */
  student?: Maybe<StudentNode>;
  suffix?: Maybe<Scalars['String']>;
  /** Labels of teams the user is an active member of */
  teamLabels?: Maybe<Scalars['String']>;
  /** A specific team membership of the person. */
  teamMembership?: Maybe<TeamMemberNode>;
  /** The teams the person is a member of. */
  teams?: Maybe<Array<Maybe<TeamNode>>>;
  timeZone?: Maybe<Scalars['String']>;
  /** Title of the person */
  title?: Maybe<Scalars['String']>;
  travelWeight?: Maybe<Scalars['Int']>;
  twitterAccount?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  usesExternalAuthentication?: Maybe<Scalars['Boolean']>;
};

export type SignupsSelectionNode = {
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type SlotAppointmentConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SlotAppointmentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SlotAppointment` and its cursor. */
export type SlotAppointmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BaseAppointmentNode>;
};

export type SlotDateNode = {
  capacity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  endDatetime?: Maybe<Scalars['DateTime']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isEmpty?: Maybe<Scalars['Boolean']>;
  isFull?: Maybe<Scalars['Boolean']>;
  isPastDeadline?: Maybe<Scalars['Boolean']>;
  isSetPeriodLimitExceeded?: Maybe<Scalars['Boolean']>;
  numReservations?: Maybe<Scalars['Int']>;
  startDatetime?: Maybe<Scalars['DateTime']>;
};

/** GQL node representing a single bookable slot on the signup */
export type SlotNode = {
  appointment?: Maybe<SignupsAppointmentNode>;
  /** The list of person_ids who booked the appointment. Most will just have one, but high capacity appointments can have several */
  bookedPersonIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  bookedPersons?: Maybe<Array<Maybe<SignupsPersonNode>>>;
  capacity?: Maybe<Scalars['Int']>;
  isFull?: Maybe<Scalars['Boolean']>;
  /** Whether or not the slot was manually set to opened or closed. Setting this to false cancels all reservations in the slot. */
  isOpen?: Maybe<Scalars['Boolean']>;
  numReservations?: Maybe<Scalars['Int']>;
  signup?: Maybe<SignupNode>;
  slotId?: Maybe<Scalars['UUID']>;
  timespan?: Maybe<DateTimePeriodNode>;
};

/** An athletic position on a team sport. */
export type SportPosition = Node &
  Selectable & {
    /** The type of group */
    groupType?: Maybe<GroupType>;
    /** The ID of the object. */
    id: Scalars['ID'];
    /** The user-displayed name of the selectable. */
    label?: Maybe<Scalars['String']>;
    peopleCount?: Maybe<Scalars['Int']>;
    /** The label including the team name. Applies only to group selections. */
    pluralLabel?: Maybe<Scalars['String']>;
    /** The unique identifier used to select members of the selectable. */
    selectionCode?: Maybe<Scalars['String']>;
    team?: Maybe<TeamNode>;
  };

export type SportPositionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SportPositionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SportPosition` and its cursor. */
export type SportPositionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SportPosition>;
};

export type StateNode = Node & {
  code?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stateId: Scalars['ID'];
};

export type StateNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StateNode` and its cursor. */
export type StateNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StateNode>;
};

export type StatusUpdatesInput = {
  personStatusType: Scalars['ID'];
  /** List of persons to update the status for */
  persons: Array<InputMaybe<Scalars['ID']>>;
};

/** Mutation to store the mobile app version */
export type StoreVersionData = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type StreamUserResponse = {
  /** Person Id */
  personId?: Maybe<Scalars['Int']>;
  /** Stream User Id */
  streamUserId?: Maybe<Scalars['String']>;
};

export type StreamUserResponseConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StreamUserResponseEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StreamUserResponse` and its cursor. */
export type StreamUserResponseEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StreamUserResponse>;
};

export type StringDiff = {
  new?: Maybe<Scalars['String']>;
  old?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSONString']>;
};

export type StudentConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Student` and its cursor. */
export type StudentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudentNode>;
};

/** Student mutation input for updating advisors */
export type StudentInput = {
  /** List of advisors to add to student records */
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Status to set all students eligibility to */
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  /** If true, replace previous assignments with current ones */
  overwrite?: InputMaybe<Scalars['Boolean']>;
  /** List of student ids to update */
  studentIds: Array<InputMaybe<Scalars['Int']>>;
};

export type StudentNode = Node & {
  /** The student's academic eligibility for athletics */
  academicStatus: AcademicStatus;
  advisors?: Maybe<AdvisorConnection>;
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The student's cumulative GPA */
  cumulativeGpa?: Maybe<Scalars['String']>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  /** The current eligibility year for the student. */
  eligibilityYear?: Maybe<Scalars['String']>;
  /** All current eligibility years for the student */
  eligibilityYears?: Maybe<Array<Maybe<Scalars['String']>>>;
  fullName?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Student's at-risk status */
  isAtRisk: Scalars['Boolean'];
  isCheckedInStudyhall: Scalars['Boolean'];
  /** Is student's soft deleted */
  isDeleted: Scalars['Boolean'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  lastName: Scalars['String'];
  /** meta data about this model */
  meta?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** TW Person ID that this student record refers to */
  personId: Scalars['Int'];
  /**
   *
   *         Returns a link to the person's profile picture
   *
   */
  pictureDownloadUrl?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  studentId: Scalars['ID'];
  studentTerms?: Maybe<StudentTermConnection>;
  studyHallDetails?: Maybe<StudyHallStudentNode>;
  /** The team ids the student person is a part of */
  teamIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalMinutesCheckedIn?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type StudentNodeAdvisorsArgs = {
  advisorId?: InputMaybe<Scalars['TWFilter_ID']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  preferredName?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AdvisorSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type StudentNodeStudentTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  creditsAttempted?: InputMaybe<Scalars['TWFilter_String']>;
  creditsCompleted?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  finalGpa?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  goalGpa?: InputMaybe<Scalars['TWFilter_String']>;
  isComplete?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  midtermGpa?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<StudentTermSortEnum>>>;
  studentId?: InputMaybe<Scalars['TWFilter_Int']>;
  studentTermId?: InputMaybe<Scalars['TWFilter_ID']>;
  studentYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum StudentSortEnum {
  Ac2StudentIdAsc = 'ac2_student_id_asc',
  Ac2StudentIdDesc = 'ac2_student_id_desc',
  AcademicStatusAsc = 'academic_status_asc',
  AcademicStatusDesc = 'academic_status_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CumulativeGpaAsc = 'cumulative_gpa_asc',
  CumulativeGpaDesc = 'cumulative_gpa_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  EligibilityYearAsc = 'eligibility_year_asc',
  EligibilityYearDesc = 'eligibility_year_desc',
  IsAtRiskAsc = 'is_at_risk_asc',
  IsAtRiskDesc = 'is_at_risk_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  LastNameAsc = 'last_name_asc',
  LastNameDesc = 'last_name_desc',
  MetaAsc = 'meta_asc',
  MetaDesc = 'meta_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  PreferredNameAsc = 'preferred_name_asc',
  PreferredNameDesc = 'preferred_name_desc',
  TeamIdsAsc = 'team_ids_asc',
  TeamIdsDesc = 'team_ids_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type StudentTermConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentTermEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `StudentTerm` and its cursor. */
export type StudentTermEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudentTermNode>;
};

export type StudentTermNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** Number of credits the student attempted this term. */
  creditsAttempted?: Maybe<Scalars['String']>;
  /** Cumulative number of credits the student has completed through this term. */
  creditsCompleted?: Maybe<Scalars['String']>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  enrollments?: Maybe<EnrollmentConnection>;
  /** Student's final GPA */
  finalGpa?: Maybe<Scalars['String']>;
  /** Student's goal GPA */
  goalGpa?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Whether or not the student has completed all enrolled courses. */
  isComplete?: Maybe<Scalars['Boolean']>;
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  /** meta data about this model */
  meta?: Maybe<Scalars['GenericScalar']>;
  /** Student's midterm GPA */
  midtermGpa?: Maybe<Scalars['String']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  student?: Maybe<StudentNode>;
  studentId: Scalars['Int'];
  studentTermId: Scalars['ID'];
  studentYear?: Maybe<StudentYearNode>;
  studentYearId?: Maybe<Scalars['Int']>;
  term?: Maybe<TermNode>;
  termId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type StudentTermNodeEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  creditsAttempted?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  droppedDate?: InputMaybe<Scalars['TWFilter_String']>;
  enrollmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['TWFilter_Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  score?: InputMaybe<Scalars['TWFilter_Float']>;
  sort?: InputMaybe<Array<InputMaybe<EnrollmentSortEnum>>>;
  studentTermId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum StudentTermSortEnum {
  Ac2StudentTermIdAsc = 'ac2_student_term_id_asc',
  Ac2StudentTermIdDesc = 'ac2_student_term_id_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CreditsAttemptedAsc = 'credits_attempted_asc',
  CreditsAttemptedDesc = 'credits_attempted_desc',
  CreditsCompletedAsc = 'credits_completed_asc',
  CreditsCompletedDesc = 'credits_completed_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  FinalGpaAsc = 'final_gpa_asc',
  FinalGpaDesc = 'final_gpa_desc',
  GoalGpaAsc = 'goal_gpa_asc',
  GoalGpaDesc = 'goal_gpa_desc',
  IsCompleteAsc = 'is_complete_asc',
  IsCompleteDesc = 'is_complete_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  MetaAsc = 'meta_asc',
  MetaDesc = 'meta_desc',
  MidtermGpaAsc = 'midterm_gpa_asc',
  MidtermGpaDesc = 'midterm_gpa_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  StudentIdAsc = 'student_id_asc',
  StudentIdDesc = 'student_id_desc',
  StudentYearIdAsc = 'student_year_id_asc',
  StudentYearIdDesc = 'student_year_id_desc',
  TermIdAsc = 'term_id_asc',
  TermIdDesc = 'term_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type StudentYearConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentYearEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `StudentYear` and its cursor. */
export type StudentYearEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudentYearNode>;
};

export type StudentYearNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of labelXlt */
  label?: Maybe<Scalars['String']>;
  /** Deprecated */
  labelXlt?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  studentYearId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum StudentYearSortEnum {
  Ac2StudentYearIdAsc = 'ac2_student_year_id_asc',
  Ac2StudentYearIdDesc = 'ac2_student_year_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  LabelXltAsc = 'label_xlt_asc',
  LabelXltDesc = 'label_xlt_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type StudyHallActionBulkCreateInput = {
  /** The first date to create requirements for */
  checkinDateTime: Scalars['GraphenePendulumDateTime'];
  /** The last date to create requirements for */
  checkoutDateTime: Scalars['GraphenePendulumDateTime'];
  /** Optional text field for any details that need to accompany the check in/out */
  note?: InputMaybe<Scalars['String']>;
  /** Student IDs of the people */
  studentIds: Array<InputMaybe<Scalars['String']>>;
};

export type StudyHallActionBulkCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  studyHallAction?: Maybe<StudyHallActionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type StudyHallActionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudyHallActionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type StudyHallActionCreatePayload = {
  Output?: Maybe<StudyHallActionNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  studyHallAction?: Maybe<StudyHallActionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type StudyHallActionDeletePayload = {
  Output?: Maybe<StudyHallActionNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  studyHallAction?: Maybe<StudyHallActionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `StudyHallAction` and its cursor. */
export type StudyHallActionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudyHallActionNode>;
};

export type StudyHallActionInput = {
  /** Optional text field for any details that need to accompany the check in/out */
  note?: InputMaybe<Scalars['String']>;
  /** School identifier value of the person checking in/out */
  schoolIdentifier?: InputMaybe<Scalars['String']>;
  /** ID of the Study Hall the person is entering/exiting */
  studyHall: Scalars['ID'];
};

export type StudyHallActionNode = Node & {
  accumulatedMinutes: Scalars['Int'];
  /** Person was auto-checked out when the study hall closed */
  autoCheckedOut?: Maybe<Scalars['Boolean']>;
  /** UTC timestamp of when the person checked in */
  checkinDateTime?: Maybe<Scalars['DateTime']>;
  /** UTC timestamp of when the person checked out */
  checkoutDateTime?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** Required hours for the week of this action */
  hoursRequired?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Field containing checkin/checkout/edit notes */
  notes?: Maybe<StudyHallActionNotesNode>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /** The Teamworks Person Id that this is associated with */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  studyHall?: Maybe<StudyHallNode>;
  studyHallActionId: Scalars['ID'];
  studyHallId: Scalars['Int'];
  studyHallPeriodId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type StudyHallActionNoteDataNode = {
  /** The actual string text of the note */
  note?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The pk of the Person record who made the note */
  personId?: Maybe<Scalars['Int']>;
  /** Date/time of when the note was created */
  timestamp?: Maybe<Scalars['String']>;
};

export type StudyHallActionNotesNode = {
  checkinNote?: Maybe<StudyHallActionNoteDataNode>;
  checkoutNote?: Maybe<StudyHallActionNoteDataNode>;
  editNotes?: Maybe<Array<Maybe<StudyHallActionNoteDataNode>>>;
};

/** An enumeration. */
export enum StudyHallActionSortEnum {
  Ac2StudyHallActionIdAsc = 'ac2_study_hall_action_id_asc',
  Ac2StudyHallActionIdDesc = 'ac2_study_hall_action_id_desc',
  Ac2StudyHallIdAsc = 'ac2_study_hall_id_asc',
  Ac2StudyHallIdDesc = 'ac2_study_hall_id_desc',
  Ac2StudyHallPeriodIdAsc = 'ac2_study_hall_period_id_asc',
  Ac2StudyHallPeriodIdDesc = 'ac2_study_hall_period_id_desc',
  AccumulatedMinutesAsc = 'accumulated_minutes_asc',
  AccumulatedMinutesDesc = 'accumulated_minutes_desc',
  AutoCheckedOutAsc = 'auto_checked_out_asc',
  AutoCheckedOutDesc = 'auto_checked_out_desc',
  CheckinDateTimeAsc = 'checkin_date_time_asc',
  CheckinDateTimeDesc = 'checkin_date_time_desc',
  CheckoutDateTimeAsc = 'checkout_date_time_asc',
  CheckoutDateTimeDesc = 'checkout_date_time_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type StudyHallActionUpdateInput = {
  /** The new checkin datetime for the study hall action */
  checkinDateTime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  /** The new checkout datetime for the study hall action */
  checkoutDateTime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  /** Optional text field for any details that need to accompany the edit */
  note?: InputMaybe<Scalars['String']>;
};

export type StudyHallActionUpdatePayload = {
  Output?: Maybe<StudyHallActionNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  studyHallAction?: Maybe<StudyHallActionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type StudyHallConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudyHallEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type StudyHallCreatePayload = {
  Output?: Maybe<StudyHallNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  studyHall?: Maybe<StudyHallNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type StudyHallDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  studyHall?: Maybe<StudyHallNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `StudyHall` and its cursor. */
export type StudyHallEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudyHallNode>;
};

export type StudyHallInput = {
  /** The length of each study hall period occurrence (in minutes) */
  duration?: InputMaybe<Scalars['Int']>;
  /** An internationalized label JSON string with language codes as keys */
  labelXlt?: InputMaybe<Scalars['JSONString']>;
  /** Whether or not to rotate the QR code with a Time-based OTP */
  rotateQrCode?: InputMaybe<Scalars['Boolean']>;
  /** A valid RRULE string representing the study hall period recurrence series */
  rrule?: InputMaybe<Scalars['String']>;
};

export type StudyHallNode = Node & {
  canEditDatetimes?: Maybe<Scalars['Boolean']>;
  /**
   *
   *         Creates an obfuscated and non-iterable token for the external checkin app using the primary key combined with
   *         the epoch timestamp from the the study hall's created_date.
   *
   */
  checkinAppToken?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<PersonNode>;
  endDatetimeUtc?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isExpired?: Maybe<Scalars['Boolean']>;
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of labelXlt */
  label?: Maybe<Scalars['String']>;
  /** Deprecated */
  labelXlt?: Maybe<Scalars['GenericScalar']>;
  numOpenCheckins?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Whether or not to rotate the QR code periodically */
  rotateQrCode: Scalars['Boolean'];
  startDatetimeUtc?: Maybe<Scalars['String']>;
  studyHallId: Scalars['ID'];
  /** Creates a temporary token for checkins to avoid people sharing the QR code. */
  totp?: Maybe<Scalars['String']>;
  /** The time left to live for the current totp */
  totpTimeToLive?: Maybe<Scalars['String']>;
};

export type StudyHallRequirementBulkCreateOrUpdateInput = {
  /** The last date to create requirements for */
  endDate: Scalars['Date'];
  /** The number of hours required for each week */
  hoursRequired: Scalars['Int'];
  /** The first date to create requirements for */
  startDate: Scalars['Date'];
  /** Student IDs of the people */
  studentIds: Array<InputMaybe<Scalars['String']>>;
};

export type StudyHallRequirementBulkCreateOrUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  studyHallRequirement?: Maybe<StudyHallRequirementNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type StudyHallRequirementNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The number of hours of study halls required for this week */
  hoursRequired: Scalars['Int'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Person ID that this is associated with */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  studyHallRequirementId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** The first day of the week this requirement is for (not timezone aware) */
  weekStart: Scalars['String'];
};

/** An enumeration. */
export enum StudyHallSortEnum {
  Ac2StudyHallIdAsc = 'ac2_study_hall_id_asc',
  Ac2StudyHallIdDesc = 'ac2_study_hall_id_desc',
  CanEditDatetimesAsc = 'can_edit_datetimes_asc',
  CanEditDatetimesDesc = 'can_edit_datetimes_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsExpiredAsc = 'is_expired_asc',
  IsExpiredDesc = 'is_expired_desc',
  LabelXltAsc = 'label_xlt_asc',
  LabelXltDesc = 'label_xlt_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PeriodRecurrenceAsc = 'period_recurrence_asc',
  PeriodRecurrenceDesc = 'period_recurrence_desc',
  RotateQrCodeAsc = 'rotate_qr_code_asc',
  RotateQrCodeDesc = 'rotate_qr_code_desc',
  TotpSecretAsc = 'totp_secret_asc',
  TotpSecretDesc = 'totp_secret_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type StudyHallStudentNode = {
  /** Whether the student has any open checkins (study hall actions) */
  isCurrentlyCheckedIn?: Maybe<Scalars['Boolean']>;
  /** The number of study hall minutes */
  weeklyAccumulatedMinutes?: Maybe<Scalars['Int']>;
  /** The number of required study hall hours */
  weeklyHoursRequired?: Maybe<Scalars['Int']>;
};

export type StudyHallUpdatePayload = {
  Output?: Maybe<StudyHallNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  studyHall?: Maybe<StudyHallNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SubjectConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SubjectEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Subject input for creating Subjects. */
export type SubjectCreateInput = {
  /** The description for this subject, example: 'Mathematics'. */
  description: Scalars['String'];
  /** The shortcode used by the registrar for courses with this subject. */
  shortCode: Scalars['String'];
};

export type SubjectCreatePayload = {
  Output?: Maybe<SubjectNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  subject?: Maybe<SubjectNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `Subject` and its cursor. */
export type SubjectEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SubjectNode>;
};

export type SubjectNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  courses?: Maybe<CourseConnection>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of descriptionXlt */
  description?: Maybe<Scalars['String']>;
  /** Deprecated */
  descriptionXlt?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Flags whether or not a subject will be available to all students or require enrollments */
  isGeneral: Scalars['Boolean'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of labelXlt */
  label?: Maybe<Scalars['String']>;
  /** Deprecated */
  labelXlt?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** The shortcode used by the registrar for courses with this subject */
  shortCode: Scalars['String'];
  subjectId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type SubjectNodeCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  credits?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  descriptionXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isGeneral?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CourseSortEnum>>>;
  subjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum SubjectSortEnum {
  Ac2SubjectIdAsc = 'ac2_subject_id_asc',
  Ac2SubjectIdDesc = 'ac2_subject_id_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  DescriptionXltAsc = 'description_xlt_asc',
  DescriptionXltDesc = 'description_xlt_desc',
  IsGeneralAsc = 'is_general_asc',
  IsGeneralDesc = 'is_general_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  LabelXltAsc = 'label_xlt_asc',
  LabelXltDesc = 'label_xlt_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ShortCodeAsc = 'short_code_asc',
  ShortCodeDesc = 'short_code_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Subject input for updating Subjects. */
export type SubjectUpdateInput = {
  /** The description for this subject, example: 'Mathematics'. */
  description?: InputMaybe<Scalars['String']>;
  /** The shortcode used by the registrar for courses with this subject. */
  shortCode?: InputMaybe<Scalars['String']>;
};

export type SubjectUpdatePayload = {
  Output?: Maybe<SubjectNode>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  subject?: Maybe<SubjectNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type SubmittedNamesNode = {
  personId?: Maybe<Scalars['Int']>;
  submittedName?: Maybe<Scalars['String']>;
};

export type SubsequentRoleInput = {
  chooserAllowExternal?: InputMaybe<Scalars['Boolean']>;
  chooserAllowUser?: InputMaybe<Scalars['Boolean']>;
  chooserRestrictedToPersonTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chooserRoleOrder?: InputMaybe<Scalars['Int']>;
  editCompleted?: InputMaybe<Scalars['Boolean']>;
  externalAssignees?: InputMaybe<Array<InputMaybe<ExternalAssigneeInput>>>;
  internalAssignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['Int']>;
  personSelection?: InputMaybe<Scalars['String']>;
  roleOrder: Scalars['Int'];
  roleType?: InputMaybe<FormAssignmentRoleType>;
};

/** Updates active users current team */
export type SwitchUsersCurrentTeam = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  currentUserData?: Maybe<CurrentUserData>;
  redirectUrl?: Maybe<Scalars['String']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum TwOwnerAppFilterEnum {
  Arms = 'arms',
  Communities = 'communities',
  Hub = 'hub',
  Inflcr = 'inflcr',
  Notemeal = 'notemeal',
  Pathways = 'pathways',
  Pulse = 'pulse',
  Retain = 'retain',
  Smartabase = 'smartabase',
  Whistle = 'whistle',
}

/** Code + metadata for each selection code group that received a message */
export type TargetSelectionCodeObjectType = {
  /** Teamworks entity Selection Code */
  code?: Maybe<Scalars['String']>;
  /** True if the SelectionCode is a group. False if it's an individual */
  isGroup?: Maybe<Scalars['Boolean']>;
  /** If the SelectionCode is a group, this is the display name for it. */
  label?: Maybe<Scalars['String']>;
};

export type TaskAssignerType = {
  assignerPrettyValue?: Maybe<Scalars['String']>;
  assignerRawValues?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TaskExtraType = {
  /** Specific to Retain's academic tasks: The category the task is for */
  category?: Maybe<Scalars['String']>;
  /** Specific to Retain's academic tasks: The course the task is for */
  course?: Maybe<Scalars['String']>;
};

export type TaskObjectType = {
  assigner?: Maybe<Scalars['String']>;
  /** Re-formatted assigner's first and last name for consistency */
  assignerPrettyValue?: Maybe<Scalars['String']>;
  callbackUrl?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Whether or not the task is eligible to be shown on the dashboard */
  dashboardEligible?: Maybe<Scalars['Boolean']>;
  dateAvailable?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** Extra fields for the task */
  extra?: Maybe<TaskExtraType>;
  /** Whether or not the task is an academic task */
  isAcademicTask?: Maybe<Scalars['Boolean']>;
  /** Whether or not the task is past due for the dashboard */
  isPastDueForDashboard?: Maybe<Scalars['Boolean']>;
  orgId: Scalars['Int'];
  ownerApp: Scalars['String'];
  ownerId: Scalars['String'];
  ownerType: Scalars['String'];
  pastDueDurationDays?: Maybe<Scalars['Int']>;
  profileId: Scalars['Int'];
  showDueDate?: Maybe<Scalars['Boolean']>;
  /** Status of the task based off TaskStatusFilterEnum */
  status?: Maybe<TaskStatusFilterEnum>;
  taskUrl: Scalars['String'];
  taskUrlType?: Maybe<TaskUrlType>;
  taskUrlWeb?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TaskOwnerApp = {
  ownerApp?: Maybe<Scalars['String']>;
  taskCount?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum TaskStatusFilterEnum {
  DueLater = 'DUE_LATER',
  DueSoon = 'DUE_SOON',
  New = 'NEW',
  NoDueDate = 'NO_DUE_DATE',
  PastDue = 'PAST_DUE',
  ShowAll = 'SHOW_ALL',
}

/** The type of URL for the task.  'normal' is a normal URL, 'webview' is a URL that will be opened in a webview */
export enum TaskUrlType {
  Normal = 'NORMAL',
  Webview = 'WEBVIEW',
}

export type TeamConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Team` and its cursor. */
export type TeamEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeamNode>;
};

export type TeamLogo = {
  imageUrl?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['ID']>;
};

export type TeamMemberAthleteNode = {
  academicYear?: Maybe<EligibilityYear>;
  academicYearId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['String']>;
  athleticYear?: Maybe<EligibilityYear>;
  athleticYearId?: Maybe<Scalars['Int']>;
  automobile?: Maybe<AutomobileNode>;
  automobileId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  hasScholarship?: Maybe<Scalars['Boolean']>;
  jerseyNumber?: Maybe<Scalars['String']>;
  major?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personStatusType?: Maybe<PersonStatusTypeNode>;
  personStatusTypeId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  positions?: Maybe<Scalars['String']>;
  scholarshipAmount?: Maybe<Scalars['String']>;
  teamMember?: Maybe<TeamMemberNode>;
  teamMemberAthleteId: Scalars['ID'];
  teamMemberId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TeamMemberConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamMemberEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TeamMember` and its cursor. */
export type TeamMemberEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeamMemberNode>;
};

export type TeamMemberNode = Node & {
  academicYear?: Maybe<Scalars['Int']>;
  academicYearLabel?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  athlete?: Maybe<TeamMemberAthleteNode>;
  athleteStatus?: Maybe<PersonStatusTypeNode>;
  athleticYear?: Maybe<Scalars['Int']>;
  athleticYearLabel?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  hasScholarship?: Maybe<Scalars['Boolean']>;
  hasScholarshipYesNo?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isAcademicsUser?: Maybe<Scalars['String']>;
  isAthlete?: Maybe<Scalars['Boolean']>;
  /**
   *
   *         Return true if this team member is an org superuser
   *
   */
  isOrgSuperuser?: Maybe<Scalars['String']>;
  /**
   *
   *         Return true if this team member is a team superuser
   *
   */
  isTeamSuperuser?: Maybe<Scalars['String']>;
  jerseyNumber?: Maybe<Scalars['String']>;
  major?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  permissions?: Maybe<TeamMemberPermissionNodeConnection>;
  person?: Maybe<PersonNode>;
  personId: Scalars['Int'];
  personTypes?: Maybe<PersonTypeConnection>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  positionLabels?: Maybe<Scalars['String']>;
  positionLabelsShort?: Maybe<Scalars['String']>;
  positions?: Maybe<SportPositionConnection>;
  profilePermissions?: Maybe<Array<Maybe<ProfilePermissionNode>>>;
  profilePermissionsNameList?: Maybe<Scalars['String']>;
  rosterInfo?: Maybe<RosterInfoNode>;
  scholarshipAmount?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['Int']>;
  teamMemberId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  userTypeLabels?: Maybe<Scalars['String']>;
  userTypeLabelsWithStatuses?: Maybe<Scalars['String']>;
  userTypes?: Maybe<Array<Maybe<PersonTypeNode>>>;
};

export type TeamMemberNodePermissionsArgs = {
  PermissionRestrictions?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  permissionId?: InputMaybe<Scalars['TWFilter_Int']>;
  permissionRestrictions?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamMemberPermissionSortEnum>>>;
  teamMemberId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamMemberPermissionId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TeamMemberNodePersonTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PersonTypeSortEnum>>>;
};

export type TeamMemberNodePositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  positionId?: InputMaybe<Scalars['TWFilter_ID']>;
  positionLong?: InputMaybe<Scalars['TWFilter_String']>;
  positionPlural?: InputMaybe<Scalars['TWFilter_String']>;
  positionShort?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<PositionSortEnum>>>;
  sportId?: InputMaybe<Scalars['TWFilter_Int']>;
};

export type TeamMemberNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamMemberNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TeamMemberNode` and its cursor. */
export type TeamMemberNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeamMemberNode>;
};

/** The Team Member Permission connection. */
export type TeamMemberPermissionConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamMemberPermissionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TeamMemberPermission` and its cursor. */
export type TeamMemberPermissionEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeamMemberPermissionNode>;
};

/** The Team Member Permission node. */
export type TeamMemberPermissionNode = Node & {
  PermissionRestrictions?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  permission?: Maybe<PermissionNode>;
  permissionId: Scalars['Int'];
  /**
   *
   *         Get the list of allowed person types for this team member permissions.
   *
   *         Returns: A list of PersonTypes
   *
   *
   */
  permissionRestrictions?: Maybe<Scalars['String']>;
  /** Permissions that belong to the current team member. */
  permissions?: Maybe<Array<Maybe<PermissionNode>>>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  teamMember?: Maybe<TeamMemberNode>;
  teamMemberId: Scalars['Int'];
  teamMemberPermissionId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TeamMemberPermissionNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamMemberPermissionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TeamMemberPermissionNode` and its cursor. */
export type TeamMemberPermissionNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeamMemberPermissionNode>;
};

/** An enumeration. */
export enum TeamMemberPermissionSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  PermissionIdAsc = 'permission_id_asc',
  PermissionIdDesc = 'permission_id_desc',
  PermissionRestrictionsAsc = 'permission_restrictions_asc',
  PermissionRestrictionsDesc = 'permission_restrictions_desc',
  SportSchoolPersonIdAsc = 'sport_school_person_id_asc',
  SportSchoolPersonIdDesc = 'sport_school_person_id_desc',
  TeamPersonPermissionIdAsc = 'team_person_permission_id_asc',
  TeamPersonPermissionIdDesc = 'team_person_permission_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TeamMemberReactivatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamMember?: Maybe<TeamMemberNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum TeamMemberSortEnum {
  ActiveAsc = 'active_asc',
  ActiveDesc = 'active_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  SportSchoolIdAsc = 'sport_school_id_asc',
  SportSchoolIdDesc = 'sport_school_id_desc',
  SportSchoolPersonIdAsc = 'sport_school_person_id_asc',
  SportSchoolPersonIdDesc = 'sport_school_person_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TeamMemberUpdatePermissionsPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamMember?: Maybe<TeamMemberNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TeamMemberUpdateUserTypesPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamMember?: Maybe<TeamMemberNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** The Team Modules connection. */
export type TeamModuleConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamModuleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TeamModule` and its cursor. */
export type TeamModuleEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeamModuleNode>;
};

/** The Team Modules node. */
export type TeamModuleNode = Node & {
  createdDate: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  module?: Maybe<ModuleNode>;
  moduleId: Scalars['Int'];
  /** The modules that belong to the current team */
  modules?: Maybe<Array<Maybe<ModuleNode>>>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  settings: Scalars['JSONString'];
  team?: Maybe<TeamNode>;
  teamId: Scalars['Int'];
  teamModuleId: Scalars['ID'];
};

export type TeamModuleSettingsField = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum TeamModuleSortEnum {
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  ModuleIdAsc = 'module_id_asc',
  ModuleIdDesc = 'module_id_desc',
  SettingsAsc = 'settings_asc',
  SettingsDesc = 'settings_desc',
  SportSchoolIdAsc = 'sport_school_id_asc',
  SportSchoolIdDesc = 'sport_school_id_desc',
  SportSchoolModuleIdAsc = 'sport_school_module_id_asc',
  SportSchoolModuleIdDesc = 'sport_school_module_id_desc',
}

export type TeamModuleUpdateInput = {
  moduleCode: Scalars['String'];
  settings?: InputMaybe<Array<InputMaybe<TeamModuleSettingsField>>>;
};

export type TeamModuleUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamModule?: Maybe<TeamModuleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Teamworks team within an organization. */
export type TeamNode = Node & {
  active: Scalars['Boolean'];
  branding?: Maybe<CustomerBrandingNode>;
  /** Whether the viewer is authorized to add a user to this team. */
  canAddToTeam?: Maybe<Scalars['Boolean']>;
  /** Whether the viewer is authorized to remove a user from this team. */
  canRemoveFromTeam?: Maybe<Scalars['Boolean']>;
  /** Whether the viewer is authorized to view other users in this team. */
  canViewOtherUsers?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  customGroups?: Maybe<CustomGroupNodeConnection>;
  /** List of modules with custom labels */
  customModules?: Maybe<Array<Maybe<CustomModule>>>;
  customerBranding?: Maybe<CustomerBrandingNode>;
  folders?: Maybe<FolderNodeConnection>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The label of the field used to uniquely identify a user. */
  identityFieldLabel?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  institution?: Maybe<InstitutionNode>;
  institutionId?: Maybe<Scalars['Int']>;
  isUserChangeAlertEnabled: Scalars['Boolean'];
  label: Scalars['String'];
  logoImage?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoUrlFullSize?: Maybe<Scalars['String']>;
  members?: Maybe<TeamMemberNodeConnection>;
  moduleCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  modules?: Maybe<ModuleNodeConnection>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  settings: Scalars['JSONString'];
  shortLabel: Scalars['String'];
  sportId?: Maybe<Scalars['Int']>;
  teamId: Scalars['ID'];
  teamInstitution?: Maybe<InstitutionNode>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  userChangeAlertEmail?: Maybe<Scalars['String']>;
  voiceGreeting?: Maybe<Scalars['String']>;
};

/** A Teamworks team within an organization. */
export type TeamNodeCustomGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customGroupId?: InputMaybe<Scalars['TWFilter_ID']>;
  first?: InputMaybe<Scalars['Int']>;
  groupIsPublic?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  selections?: InputMaybe<Scalars['TWFilter_JSONString']>;
  sort?: InputMaybe<Array<InputMaybe<CustomGroupSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** A Teamworks team within an organization. */
export type TeamNodeFoldersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['TWFilter_ID']>;
  folderName?: InputMaybe<Scalars['TWFilter_String']>;
  folderType?: InputMaybe<Scalars['TWFilter_String']>;
  isEditableByUser?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  parentFolderId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<FolderSortEnum>>>;
  sortOrder?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  videoFolderId?: InputMaybe<Scalars['TWFilter_Int']>;
};

/** A Teamworks team within an organization. */
export type TeamNodeMembersArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAcademicsUser?: InputMaybe<Scalars['TWFilter_String']>;
  isOrgSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  isTeamSuperuser?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TeamMemberSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamMemberId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** A Teamworks team within an organization. */
export type TeamNodeModulesArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  defaultAction?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  moduleId?: InputMaybe<Scalars['TWFilter_ID']>;
  moduleName?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<ModuleSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TeamNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TeamNode` and its cursor. */
export type TeamNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeamNode>;
};

export type TeamRosterBulkCreateInput = {
  team: Scalars['ID'];
};

export type TeamRosterBulkCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRoster?: Maybe<Array<Maybe<TeamRosterNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TeamRosterConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamRosterEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TeamRosterCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRoster?: Maybe<TeamRosterNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TeamRosterDeleteInput = {
  deleteTeamRosterPersons?: InputMaybe<Scalars['Boolean']>;
};

export type TeamRosterDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRoster?: Maybe<TeamRosterNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TeamRoster` and its cursor. */
export type TeamRosterEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeamRosterNode>;
};

export type TeamRosterInput = {
  team: Scalars['ID'];
  tmpYear: Scalars['ID'];
};

/** A Team Roster for a Teamworks team based on a TMP Year */
export type TeamRosterNode = Node & {
  /**
   * List of persons that are active athletes but not on the associated Team Roster.
   *         Note: This should only be used with tmpYearId and teamId filters
   */
  activeAthletesNotOnRoster?: Maybe<Array<Maybe<PersonNode>>>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** List of persons_ids that have TeamRosterPerson records */
  rosterPersonsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  team?: Maybe<TeamNode>;
  teamId: Scalars['Int'];
  teamRosterId: Scalars['String'];
  /** List of TeamRosterPerson records */
  teamRosterPersons?: Maybe<Array<Maybe<TeamRosterPersonNode>>>;
  tmpYear?: Maybe<TmpYearNode>;
  tmpYearId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TeamRosterPersonBulkCreateInput = {
  persons: Array<InputMaybe<Scalars['ID']>>;
  teamRosters: Array<InputMaybe<Scalars['ID']>>;
};

export type TeamRosterPersonBulkCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRosterPerson?: Maybe<Array<Maybe<TeamRosterPersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TeamRosterPersonBulkDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRosterPerson?: Maybe<Array<Maybe<TeamRosterPersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TeamRosterPersonBulkInput = {
  persons: Array<InputMaybe<Scalars['ID']>>;
  teamRoster: Scalars['ID'];
};

export type TeamRosterPersonCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRosterPerson?: Maybe<TeamRosterPersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TeamRosterPersonDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRosterPerson?: Maybe<TeamRosterPersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TeamRosterPersonInput = {
  person: Scalars['ID'];
  teamRoster: Scalars['ID'];
};

/** A person associated with a Team Roster */
export type TeamRosterPersonNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  teamRoster?: Maybe<TeamRosterNode>;
  teamRosterId: Scalars['String'];
  teamRosterPersonId: Scalars['String'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TeamRosterPersonRolloverInput = {
  /** List of persons to add to the roster */
  addToRoster?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  statusUpdates?: InputMaybe<Array<InputMaybe<StatusUpdatesInput>>>;
  teamRoster: Scalars['ID'];
};

export type TeamRosterPersonRolloverPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRosterPerson?: Maybe<Array<Maybe<TeamRosterPersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TeamRosterPersonRolloverYearlyAttributeInput = {
  /** Team Roster ID to copy persons and yearly attribute values from */
  copyFromTeamRoster: Scalars['ID'];
  /** Team Roster ID to copy persons and yearly attribute values to */
  copyToTeamRoster: Scalars['ID'];
  /** List of person IDs of athletes */
  persons: Array<InputMaybe<Scalars['ID']>>;
};

export type TeamRosterPersonRolloverYearlyAttributePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRosterPerson?: Maybe<Array<Maybe<TeamRosterPersonNode>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TeamRosterReportConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamRosterReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TeamRosterReportCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  teamRosterReport?: Maybe<TeamRosterReportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TeamRosterReport` and its cursor. */
export type TeamRosterReportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeamRosterReportNode>;
};

/**
 * Input model for TeamRosterReportCreate mutation
 *
 * This logic is generally similar to a report downloaded from the All Profiles table
 */
export type TeamRosterReportInput = {
  activeStatus?: InputMaybe<Scalars['Boolean']>;
  advanced?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  athleteStatus?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  rosterId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortType?: InputMaybe<Scalars['String']>;
  withAddresses?: InputMaybe<Scalars['Boolean']>;
  withAutomobile?: InputMaybe<Scalars['Boolean']>;
  withContacts?: InputMaybe<Scalars['Boolean']>;
  withRoommates?: InputMaybe<Scalars['Boolean']>;
  withTravelDocument?: InputMaybe<Scalars['Boolean']>;
  yavColumns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TeamRosterReportNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  downloadableFileId: Scalars['ID'];
  errorMessage?: Maybe<Scalars['String']>;
  fileNameServer?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** What kind of downloaded file this is, e.g. 'report'. */
  kind: Scalars['String'];
  /** Forward-compatible metadata column. */
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<OrgNode>;
  /** The Teamworks Org Id that this is associated with this */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Teamworks Person Id that this is associated with this */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Status of the file, i.e. one of `&.STATUS_*`. */
  status: Scalars['String'];
  team?: Maybe<TeamNode>;
  /** The Teamworks Team Id that this is associated with this */
  teamId: Scalars['Int'];
  teamRosterReportId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** Cached copy of the public URL. */
  url?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum TeamRosterSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TeamRosterIdAsc = 'team_roster_id_asc',
  TeamRosterIdDesc = 'team_roster_id_desc',
  TmpYearIdAsc = 'tmp_year_id_asc',
  TmpYearIdDesc = 'tmp_year_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TeamSettingsField = {
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  module: Modules;
};

/** An enumeration. */
export enum TeamSortEnum {
  ActiveAsc = 'active_asc',
  ActiveDesc = 'active_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  InstitutionIdAsc = 'institution_id_asc',
  InstitutionIdDesc = 'institution_id_desc',
  IsUserChangeAlertEnabledAsc = 'is_user_change_alert_enabled_asc',
  IsUserChangeAlertEnabledDesc = 'is_user_change_alert_enabled_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  LogoImageAsc = 'logo_image_asc',
  LogoImageDesc = 'logo_image_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SettingsAsc = 'settings_asc',
  SettingsDesc = 'settings_desc',
  ShortLabelAsc = 'short_label_asc',
  ShortLabelDesc = 'short_label_desc',
  SportIdAsc = 'sport_id_asc',
  SportIdDesc = 'sport_id_desc',
  SportSchoolIdAsc = 'sport_school_id_asc',
  SportSchoolIdDesc = 'sport_school_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  UserChangeAlertEmailAsc = 'user_change_alert_email_asc',
  UserChangeAlertEmailDesc = 'user_change_alert_email_desc',
  VoiceGreetingAsc = 'voice_greeting_asc',
  VoiceGreetingDesc = 'voice_greeting_desc',
}

export type TeamUpdateInput = {
  settings?: InputMaybe<Array<InputMaybe<TeamSettingsField>>>;
};

export type TeamUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  team?: Maybe<TeamNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TermConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TermEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Term` and its cursor. */
export type TermEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TermNode>;
};

export type TermNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  /** The end date (not timezone aware) */
  endDate?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  /** Determines which translation based on passed X-TW-ISO-Code header. Use this instead of labelXlt */
  label?: Maybe<Scalars['String']>;
  /** Deprecated */
  labelXlt?: Maybe<Scalars['GenericScalar']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** The start date (not timezone aware) */
  startDate?: Maybe<Scalars['String']>;
  termId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum TermSortEnum {
  Ac2TermIdAsc = 'ac2_term_id_asc',
  Ac2TermIdDesc = 'ac2_term_id_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  EndDateAsc = 'end_date_asc',
  EndDateDesc = 'end_date_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  LabelXltAsc = 'label_xlt_asc',
  LabelXltDesc = 'label_xlt_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  StartDateAsc = 'start_date_asc',
  StartDateDesc = 'start_date_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TicketingAttributeInput = {
  attributeId?: InputMaybe<Scalars['String']>;
  attributeOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attributeType?: InputMaybe<Scalars['String']>;
  conditionalAttributes?: InputMaybe<Array<InputMaybe<TicketingConditionalAttributeInput>>>;
  isNameAttribute?: InputMaybe<Scalars['Boolean']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
};

export type TicketingBlockedGuest = Node & {
  attributes?: Maybe<Array<Maybe<TicketingGuestAttribute>>>;
  blockedBy?: Maybe<PersonNode>;
  blockedGuestId: Scalars['ID'];
  blockedOn?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TicketingBlockedGuestBulkCreateInput = {
  ticketingGuests?: InputMaybe<Array<InputMaybe<TicketingBlockedGuestInput>>>;
};

export type TicketingBlockedGuestBulkCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingBlockedGuest?: Maybe<Array<Maybe<TicketingBlockedGuest>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingBlockedGuestBulkDeleteInput = {
  blockedGuestIds: Array<InputMaybe<Scalars['ID']>>;
};

export type TicketingBlockedGuestBulkDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingBlockedGuest?: Maybe<TicketingBlockedGuest>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingBlockedGuestConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketingBlockedGuestEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TicketingBlockedGuestCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingBlockedGuest?: Maybe<TicketingBlockedGuest>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TicketingBlockedGuest` and its cursor. */
export type TicketingBlockedGuestEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TicketingBlockedGuest>;
};

export type TicketingBlockedGuestImportCsvPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingBlockedGuest?: Maybe<TicketingBlockedGuest>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingBlockedGuestInput = {
  notes?: InputMaybe<Scalars['String']>;
  ticketingGuest: Scalars['ID'];
};

/** An enumeration. */
export enum TicketingBlockedGuestSortEnum {
  AttributesAsc = 'attributes_asc',
  AttributesDesc = 'attributes_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  TktBlockedGuestIdAsc = 'tkt_blocked_guest_id_asc',
  TktBlockedGuestIdDesc = 'tkt_blocked_guest_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TicketingCancelReservationInput = {
  event: Scalars['ID'];
  recipientContext: Scalars['String'];
  recipientId: Scalars['Int'];
};

export type TicketingConditionalAttributeInput = {
  attributeId?: InputMaybe<Scalars['String']>;
  attributeOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attributeType?: InputMaybe<Scalars['String']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  matchedBoolValue?: InputMaybe<Scalars['Boolean']>;
  matchedStrValues?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TicketingEvent = Node & {
  allocations?: Maybe<Array<Maybe<TicketingEventAllocation>>>;
  competition?: Maybe<CompetitionNode>;
  competitionId: Scalars['Int'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  eventDateTime?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['ID'];
  eventIsHome?: Maybe<Scalars['Boolean']>;
  eventLabel?: Maybe<Scalars['String']>;
  eventLocation?: Maybe<Scalars['String']>;
  eventPersons?: Maybe<TicketingEventPersonObjectTypeConnection>;
  eventTimeZone?: Maybe<Scalars['String']>;
  eventTotals?: Maybe<TicketingEventTotals>;
  hasTicketAllocations?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  isSoldOutForUser?: Maybe<Scalars['Boolean']>;
  myAvailableTicketCount?: Maybe<Scalars['Int']>;
  myOriginallyAllocatedTicketCount?: Maybe<Scalars['Int']>;
  myReservedTicketCount?: Maybe<Scalars['Int']>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  selections: Scalars['JSONString'];
  settings: Scalars['JSONString'];
  team?: Maybe<TeamNode>;
  teamId: Scalars['Int'];
  totalAllocated?: Maybe<Scalars['Int']>;
  totalReserved?: Maybe<Scalars['Int']>;
  totalTransferred?: Maybe<Scalars['Int']>;
  /** Should only be used when filtering by event. A list of Persons that are eligible to receive a transfer from the viewing person */
  transferrablePersons?: Maybe<Array<Maybe<PersonNode>>>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  windowCloseDateTime?: Maybe<Scalars['DateTime']>;
  windowOpenDateTime?: Maybe<Scalars['DateTime']>;
};

export type TicketingEventEventPersonsArgs = {
  actions?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  eventId?: InputMaybe<Scalars['TWFilter_Int']>;
  eventPersonId?: InputMaybe<Scalars['TWFilter_ID']>;
  first?: InputMaybe<Scalars['Int']>;
  isManuallyEdited?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  settings?: InputMaybe<Scalars['TWFilter_JSONString']>;
  sort?: InputMaybe<Array<InputMaybe<TicketingEventPersonSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TicketingEventAllocation = {
  defaultAllocatedAmount?: Maybe<Scalars['Int']>;
  poolCapAmount?: Maybe<Scalars['Int']>;
  preventTransfers?: Maybe<Scalars['Boolean']>;
  selectable?: Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>;
};

export type TicketingEventAllocationBulkCopyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEventPerson?: Maybe<TicketingEventPerson>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingEventAllocationCopyInput = {
  /** Global ID for event you are copying allocations from */
  fromEvent: Scalars['ID'];
  /** List of Global IDs for events you are copying allocations to */
  toEvents: Array<InputMaybe<Scalars['ID']>>;
};

export type TicketingEventBulkInput = {
  eventIds: Array<InputMaybe<Scalars['ID']>>;
  windowCloseHours?: InputMaybe<Scalars['Int']>;
  windowOpenDatetime?: InputMaybe<Scalars['DateTime']>;
  windowOpenHours?: InputMaybe<Scalars['Int']>;
};

export type TicketingEventBulkUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEvent?: Maybe<TicketingEvent>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingEventConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketingEventEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TicketingEvent` and its cursor. */
export type TicketingEventEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TicketingEvent>;
};

export type TicketingEventInput = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  selections?: InputMaybe<Array<InputMaybe<TicketingEventSelectionInput>>>;
  windowCloseDateTime?: InputMaybe<Scalars['DateTime']>;
  windowOpenDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type TicketingEventMobileDashboardArmsNode = {
  closingSoonCount?: Maybe<Scalars['Int']>;
  targetUrl?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TicketingEventPerson = Node & {
  Actions: Array<Maybe<Scalars['JSONString']>>;
  actions?: Maybe<Array<Maybe<TicketingEventPersonAction>>>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  defaultAllocatedAmount?: Maybe<Scalars['Int']>;
  event?: Maybe<TicketingEvent>;
  eventId: Scalars['Int'];
  eventPersonId: Scalars['ID'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isManuallyEdited: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  preventTransfers?: Maybe<Scalars['Boolean']>;
  reservationRecipients?: Maybe<Array<Maybe<PersonNode | TicketingGuest>>>;
  reservedAmount?: Maybe<Scalars['Int']>;
  settings: Scalars['JSONString'];
  transferredInAmount?: Maybe<Scalars['Int']>;
  transferredOutAmount?: Maybe<Scalars['Int']>;
  transferredRecipientPersons?: Maybe<Array<Maybe<TicketingTransferredRecipient>>>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  userDetails?: Maybe<Scalars['String']>;
};

export type TicketingEventPersonAction = {
  actionDateTime: Scalars['String'];
  actionType: Scalars['String'];
  actorPerson?: Maybe<PersonNode>;
  actorPersonId?: Maybe<Scalars['Int']>;
  person?: Maybe<PersonNode>;
  personId?: Maybe<Scalars['Int']>;
  recipient?: Maybe<PersonNode | TicketingGuest>;
  ticketCount: Scalars['Int'];
};

export type TicketingEventPersonBulkRevokeInput = {
  eventPersonIds: Array<InputMaybe<Scalars['ID']>>;
};

export type TicketingEventPersonBulkRevokePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEventPerson?: Maybe<TicketingEventPerson>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingEventPersonCancelReservationPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEventPerson?: Maybe<TicketingEventPerson>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingEventPersonConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketingEventPersonEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TicketingEventPersonCopyInput = {
  /** Global ID for Event you copying allocations from */
  fromEvent: Scalars['ID'];
};

export type TicketingEventPersonCopyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEventPerson?: Maybe<TicketingEventPerson>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingEventPersonDefaultAllocationBulkUpdateInput = {
  defaultAllocatedAmount: Scalars['Int'];
  eventPersonIds: Array<InputMaybe<Scalars['ID']>>;
};

export type TicketingEventPersonDefaultAllocationBulkUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEventPerson?: Maybe<TicketingEventPerson>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TicketingEventPerson` and its cursor. */
export type TicketingEventPersonEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TicketingEventPerson>;
};

export type TicketingEventPersonInput = {
  defaultAllocatedAmount?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type TicketingEventPersonObjectTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketingEventPersonObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TicketingEventPersonObjectType` and its cursor. */
export type TicketingEventPersonObjectTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TicketingEventPerson>;
};

export type TicketingEventPersonReservePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEventPerson?: Maybe<TicketingEventPerson>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingEventPersonRevokePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEventPerson?: Maybe<TicketingEventPerson>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum TicketingEventPersonSortEnum {
  ActionsAsc = 'actions_asc',
  ActionsDesc = 'actions_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EventIdAsc = 'event_id_asc',
  EventIdDesc = 'event_id_desc',
  IsManuallyEditedAsc = 'is_manually_edited_asc',
  IsManuallyEditedDesc = 'is_manually_edited_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonFirstNameAsc = 'person_first_name_asc',
  PersonFirstNameDesc = 'person_first_name_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  PersonLastNameAsc = 'person_last_name_asc',
  PersonLastNameDesc = 'person_last_name_desc',
  SettingsAsc = 'settings_asc',
  SettingsDesc = 'settings_desc',
  TktEventPersonIdAsc = 'tkt_event_person_id_asc',
  TktEventPersonIdDesc = 'tkt_event_person_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TicketingEventPersonTransferPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEventPerson?: Maybe<TicketingEventPerson>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingEventPersonUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEventPerson?: Maybe<TicketingEventPerson>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingEventSelectionInput = {
  defaultAllocatedAmount: Scalars['Int'];
  matchedSelection: Scalars['String'];
  poolCapAmount?: InputMaybe<Scalars['Int']>;
  preventTransfers: Scalars['Boolean'];
};

/** An enumeration. */
export enum TicketingEventSortEnum {
  CompetitionIdAsc = 'competition_id_asc',
  CompetitionIdDesc = 'competition_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EventStartDateTimeAsc = 'event_start_date_time_asc',
  EventStartDateTimeDesc = 'event_start_date_time_desc',
  IsArchivedAsc = 'is_archived_asc',
  IsArchivedDesc = 'is_archived_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  SelectionsAsc = 'selections_asc',
  SelectionsDesc = 'selections_desc',
  SettingsAsc = 'settings_asc',
  SettingsDesc = 'settings_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TktEventIdAsc = 'tkt_event_id_asc',
  TktEventIdDesc = 'tkt_event_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  WindowRangeAsc = 'window_range_asc',
  WindowRangeDesc = 'window_range_desc',
}

export type TicketingEventTotals = {
  athleteAllocated?: Maybe<Scalars['Int']>;
  athleteReserved?: Maybe<Scalars['Int']>;
  nonAthleteAllocated?: Maybe<Scalars['Int']>;
  nonAthleteReserved?: Maybe<Scalars['Int']>;
};

export type TicketingEventUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingEvent?: Maybe<TicketingEvent>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingGuest = Node &
  Recipient & {
    attributes?: Maybe<Array<Maybe<TicketingGuestAttribute>>>;
    createdBy?: Maybe<Scalars['Int']>;
    createdDate?: Maybe<Scalars['DateTime']>;
    /** Turn legacy database date time into UTC date time object */
    createdDateTime?: Maybe<Scalars['String']>;
    creator?: Maybe<PersonNode>;
    guestId: Scalars['ID'];
    /** The ID of the object. */
    id: Scalars['ID'];
    isArchived: Scalars['Boolean'];
    label?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    nearestBlockedGuest?: Maybe<TicketingBlockedGuest>;
    org?: Maybe<OrgNode>;
    orgId: Scalars['Int'];
    /**
     *
     *         Returns the owner for the purpose of determining editing permissions.
     *         Override this if it should be something other than creator.
     *
     */
    owner?: Maybe<Scalars['String']>;
    /**
     *
     *         Returns the person_id of the owner, for the purpose of determining permissions.
     *         Override this property if it should be something other than the creator's person_id (created_by)
     *
     */
    ownerId?: Maybe<Scalars['String']>;
    person?: Maybe<PersonNode>;
    personId: Scalars['Int'];
    /**
     *
     *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
     *         the primary key value
     *
     */
    pk?: Maybe<Scalars['String']>;
    status: TicketingGuestStatus;
    statusUpdatedBy?: Maybe<Scalars['Int']>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedDate?: Maybe<Scalars['DateTime']>;
    /** Turn legacy database date time into UTC date time object */
    updatedDateTime?: Maybe<Scalars['String']>;
  };

export type TicketingGuestAttribute = {
  attributeId?: Maybe<Scalars['String']>;
  attributeValue?: Maybe<Scalars['String']>;
};

export type TicketingGuestAttributeInputObjectType = {
  attributeId: Scalars['String'];
  attributeValue: Scalars['String'];
};

export type TicketingGuestBulkUpdateInput = {
  guests: Array<InputMaybe<Scalars['ID']>>;
  status: Scalars['String'];
};

export type TicketingGuestBulkUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingGuest?: Maybe<TicketingGuest>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingGuestConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketingGuestEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TicketingGuestCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingGuest?: Maybe<TicketingGuest>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TicketingGuest` and its cursor. */
export type TicketingGuestEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TicketingGuest>;
};

export type TicketingGuestInformationExportColumnsObjectType = {
  /** Date when guest was blocked, displayed in MMM d, YYYY format */
  blockedDate?: Maybe<Scalars['String']>;
  guestAttributes?: Maybe<Array<Maybe<TicketingGuestAttribute>>>;
  guestName?: Maybe<Scalars['String']>;
  /** Date when guest was created by host, displayed in MMM d, YYYY format */
  requestedDate?: Maybe<Scalars['String']>;
  /** Name of host/inviter */
  requesterName?: Maybe<Scalars['String']>;
  /** Indicates guest is approved, pending, denied or blocked */
  status?: Maybe<Scalars['String']>;
  /** Team of the host who invited this guest */
  team?: Maybe<Scalars['String']>;
};

/** An object containing the fields that will be included in the guest information export */
export type TicketingGuestInformationExportColumnsToInclude = {
  /** Whether to include when guest was blocked */
  blockedDate?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include guest attributes / metadata */
  guestAttributes: Array<InputMaybe<Scalars['String']>>;
  /** Whether to include guests' names */
  guestName: Scalars['Boolean'];
  /** Whether to include when guest was added */
  requestedDate?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include who the host/inviter is */
  requesterName?: InputMaybe<Scalars['Boolean']>;
  /** Whether to show whether guest is approved, pending, denied or blocked */
  status?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include the sport this guest is invited to */
  team?: InputMaybe<Scalars['Boolean']>;
};

export type TicketingGuestInformationExportInput = {
  /** Indicates which columns to show in export (e.g. guest name) */
  exportColumns: TicketingGuestInformationExportColumnsToInclude;
  /** Indicates which guests to include in filter: approved, pending, denied or blocked */
  options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Indicates which attribute to use for sorting output */
  sortOrder: TicketingGuestInformationExportSortInput;
  /** Indicates which teams to use for filtering exports */
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Indicates which user types to use for filtering exports */
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type TicketingGuestInformationExportObjectType = {
  exportColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  guestInformation?: Maybe<Array<Maybe<TicketingGuestInformationExportColumnsObjectType>>>;
  metadataColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type TicketingGuestInformationExportSortInput = {
  /** Sort by guest's last name, followed by first name */
  guestNameAsc?: InputMaybe<Scalars['Boolean']>;
  /** Sort by requester's last name, followed by first name */
  requesterNameAsc?: InputMaybe<Scalars['Boolean']>;
};

export type TicketingGuestInput = {
  attributes?: InputMaybe<Array<InputMaybe<TicketingGuestAttributeInputObjectType>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum TicketingGuestSortEnum {
  AttributesAsc = 'attributes_asc',
  AttributesDesc = 'attributes_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsArchivedAsc = 'is_archived_asc',
  IsArchivedDesc = 'is_archived_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  StatusUpdatedByAsc = 'status_updated_by_asc',
  StatusUpdatedByDesc = 'status_updated_by_desc',
  TktGuestIdAsc = 'tkt_guest_id_asc',
  TktGuestIdDesc = 'tkt_guest_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** An enumeration. */
export enum TicketingGuestStatus {
  Approved = 'approved',
  Blocked = 'blocked',
  Denied = 'denied',
  Pending = 'pending',
}

export type TicketingGuestUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingGuest?: Maybe<TicketingGuest>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingOrgSettingCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingOrgSetting?: Maybe<TicketingOrgSettingObjectType>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingOrgSettingInput = {
  settings?: InputMaybe<TicketingSettingsInput>;
};

export type TicketingOrgSettingObjectType = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  orgSettingId: Scalars['ID'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  settings?: Maybe<TicketingSettingsObjectType>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TicketingOrgSettingUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingOrgSetting?: Maybe<TicketingOrgSettingObjectType>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TicketingReservationReportConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketingReservationReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Defines the inputs in the mutation payload */
export type TicketingReservationReportCreateInput = {
  /** Event ID */
  event: Scalars['String'];
  /** Indicates which columns to show in export (e.g. guest name) */
  exportColumns: TicketingReservationsExportColumnsToInclude;
  /** File type of the exported report */
  exportFileType?: InputMaybe<ReportTypeGrapheneEnum>;
  /** Indicates which attribute to use for sorting output */
  sortOrder: TicketingReservationsExportSortInput;
  /** Indicates which teams to use for filtering exports */
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Indicates which user types to use for filtering exports */
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type TicketingReservationReportCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ticketingReservationReport?: Maybe<TicketingReservationReportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TicketingReservationReport` and its cursor. */
export type TicketingReservationReportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TicketingReservationReportNode>;
};

export type TicketingReservationReportNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  downloadableFileId: Scalars['ID'];
  errorMessage?: Maybe<Scalars['String']>;
  fileNameServer?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** What kind of downloaded file this is, e.g. 'report'. */
  kind: Scalars['String'];
  /** Forward-compatible metadata column. */
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<OrgNode>;
  /** The Teamworks Org Id that this is associated with this */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Teamworks Person Id that this is associated with this */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Status of the file, i.e. one of `&.STATUS_*`. */
  status: Scalars['String'];
  team?: Maybe<TeamNode>;
  /** The Teamworks Team Id that this is associated with this */
  teamId: Scalars['Int'];
  ticketingReservationReportId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** Cached copy of the public URL. */
  url?: Maybe<Scalars['String']>;
};

export type TicketingReservationsExportColumnsObjectType = {
  /** Academic year of the current owner of ticket */
  academicYear?: Maybe<Scalars['String']>;
  guestAttributes?: Maybe<Array<Maybe<TicketingGuestAttribute>>>;
  guestName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  transferInfo?: Maybe<Scalars['String']>;
  /** Email for the current owner of ticket */
  userEmail?: Maybe<Scalars['String']>;
  /** Current owner of ticket */
  userName?: Maybe<Scalars['String']>;
  /** Student ID for current owner of ticket */
  userSchoolIdentifier?: Maybe<Scalars['String']>;
  /** User type and team of the current owner of ticket */
  userTypeAndTeam?: Maybe<Scalars['String']>;
};

/** An object containing the fields that will be included in the reservations export */
export type TicketingReservationsExportColumnsToInclude = {
  /** Whether to show academic year of the current owner of the ticket */
  academicYear?: InputMaybe<Scalars['Boolean']>;
  guestAttributes: Array<InputMaybe<Scalars['String']>>;
  guestName: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['Boolean']>;
  signatureBox?: InputMaybe<Scalars['Boolean']>;
  transferInfo?: InputMaybe<Scalars['Boolean']>;
  /** Email for the current owner of ticket */
  userEmail?: InputMaybe<Scalars['Boolean']>;
  /** Current owner of ticket */
  userName?: InputMaybe<Scalars['Boolean']>;
  /** Student ID for current owner of ticket */
  userSchoolIdentifier?: InputMaybe<Scalars['Boolean']>;
  /** User type and team of the current owner of ticket */
  userTypeAndTeam?: InputMaybe<Scalars['Boolean']>;
};

export type TicketingReservationsExportInput = {
  event: Scalars['ID'];
  exportColumns: TicketingReservationsExportColumnsToInclude;
  /** Actual event ID pk passed in from async task to GraphQL */
  resolvedEventId?: InputMaybe<Scalars['ID']>;
  sortOrder: TicketingReservationsExportSortInput;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type TicketingReservationsExportObjectType = {
  event?: Maybe<TicketingEvent>;
  exportColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  guestColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  reservations?: Maybe<Array<Maybe<TicketingReservationsExportColumnsObjectType>>>;
  signature?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type TicketingReservationsExportSortInput = {
  guestNameAsc?: InputMaybe<Scalars['Boolean']>;
  userNameAsc?: InputMaybe<Scalars['Boolean']>;
};

export type TicketingReserveInput = {
  event: Scalars['ID'];
  /** Global ID for the TicketingGuest the reservation is for.If this is not passed in, it is assumed the person is reserving for themselves.  */
  guest?: InputMaybe<Scalars['ID']>;
};

export type TicketingSettingAttributeObjectType = {
  attributeId?: Maybe<Scalars['String']>;
  attributeOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  attributeType?: Maybe<Scalars['String']>;
  conditionalAttributes?: Maybe<Array<Maybe<TicketingSettingConditionalAttributeObjectType>>>;
  isNameAttribute?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
};

export type TicketingSettingConditionalAttributeObjectType = {
  attributeId?: Maybe<Scalars['String']>;
  attributeOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  attributeType?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  matchedBoolValue?: Maybe<Scalars['Boolean']>;
  matchedStrValues?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketingSettingsInput = {
  attributes?: InputMaybe<Array<InputMaybe<TicketingAttributeInput>>>;
  guestApprovals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notifications?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  ticketedTeams?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type TicketingSettingsObjectType = {
  attributes?: Maybe<Array<Maybe<TicketingSettingAttributeObjectType>>>;
  guestApprovals?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifications?: Maybe<Array<Maybe<Scalars['Int']>>>;
  ticketedTeams?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TicketingTransferInput = {
  event: Scalars['ID'];
  toPersonsInfo: Array<InputMaybe<TicketingTransferToPersonInput>>;
};

export type TicketingTransferToPersonInput = {
  ticketCount: Scalars['Int'];
  toPerson: Scalars['ID'];
};

export type TicketingTransferredRecipient = {
  person?: Maybe<PersonNode>;
  ticketCount?: Maybe<Scalars['Int']>;
};

export type TimeDeltaInput = {
  days?: InputMaybe<Scalars['Int']>;
  hours?: InputMaybe<Scalars['Int']>;
  minutes?: InputMaybe<Scalars['Int']>;
  seconds?: InputMaybe<Scalars['Int']>;
};

export type TimeDeltaNode = {
  days?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Int']>;
  minutes?: Maybe<Scalars['Int']>;
  seconds?: Maybe<Scalars['Int']>;
};

export type TimeDiff = {
  new?: Maybe<Scalars['GraphenePendulumDateTime']>;
  newTimeZone?: Maybe<Scalars['String']>;
  old?: Maybe<Scalars['GraphenePendulumDateTime']>;
  oldTimeZone?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSONString']>;
};

export type TimePeriodInput = {
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type TimePeriodNode = {
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type TimeZoneObjectType = {
  abbreviation?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  offsetHoursFormated?: Maybe<Scalars['String']>;
};

export type TimezoneObjectType = {
  abbreviation?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  offsetHoursFormated?: Maybe<Scalars['String']>;
};

export type TmpActivityCommentsNode = Node & {
  children?: Maybe<TmpActivityCommentsNodeConnection>;
  /** A context is a string that refers to a model and the context that the comment is associated with (in case there are different views of the same model that should contain a different set of comments). */
  commentContext: Scalars['String'];
  /** The foreign key referring to the object that is being commented on. */
  commentObjectId: Scalars['Int'];
  /** Null if this comment is a comment on an object directly, or the id of another comment that is this comment's parent */
  commentParentId?: Maybe<Scalars['Int']>;
  /** Primary Key */
  commonCommentId: Scalars['ID'];
  /** The text content of the comment. Either this is HTML and is_plaintext is False (input is sanitized), orthis is plaintext. If you mark the content as "not plaintext" after the fact, the act of marking it asnot- plaintext causes the backend to re-sanitize the information contained in this field. */
  content: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** True if this content should be rendered as plaintext, or false if this content contains HTML tags. */
  isPlaintext: Scalars['Boolean'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  parent?: Maybe<TmpActivityCommentsNode>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TmpActivityCommentsNodeChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  commentContext?: InputMaybe<Scalars['TWFilter_String']>;
  commentObjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  commentParentId?: InputMaybe<Scalars['TWFilter_Int']>;
  commonCommentId?: InputMaybe<Scalars['TWFilter_ID']>;
  content?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isPlaintext?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CommonCommentSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TmpActivityCommentsNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpActivityCommentsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TmpActivityCommentsNode` and its cursor. */
export type TmpActivityCommentsNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpActivityCommentsNode>;
};

export type TmpActivityLogAppointmentBreakdownNode = {
  appointmentCreatedDate?: Maybe<Scalars['String']>;
  appointmentOccurrenceId?: Maybe<Scalars['Int']>;
  appointmentTitle?: Maybe<Scalars['String']>;
  appointmentTypeColor?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['Int']>;
  appointmentTypeLabel?: Maybe<Scalars['String']>;
  countableMinutes?: Maybe<Scalars['Int']>;
  editedCountableEndDate?: Maybe<Scalars['String']>;
  editedCountableStartDate?: Maybe<Scalars['String']>;
  occEndDateTime?: Maybe<Scalars['String']>;
  occStartDateTime?: Maybe<Scalars['String']>;
  tmpActivityLogAppointmentId?: Maybe<Scalars['Int']>;
};

export type TmpActivityLogConfigLogDatesNode = {
  existingLogId?: Maybe<Scalars['Int']>;
  logEndDate?: Maybe<Scalars['DateTime']>;
  logStartDate?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum TmpActivityLogConfigSortEnum {
  ActiveAsc = 'active_asc',
  ActiveDesc = 'active_desc',
  ComplianceTypeIdAsc = 'compliance_type_id_asc',
  ComplianceTypeIdDesc = 'compliance_type_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TmpActivityLogConfigIdAsc = 'tmp_activity_log_config_id_asc',
  TmpActivityLogConfigIdDesc = 'tmp_activity_log_config_id_desc',
  TmpTeamIdAsc = 'tmp_team_id_asc',
  TmpTeamIdDesc = 'tmp_team_id_desc',
  TmpYearIdAsc = 'tmp_year_id_asc',
  TmpYearIdDesc = 'tmp_year_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TmpActivityLogDatesListConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpActivityLogDatesListEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TmpActivityLogDatesList` and its cursor. */
export type TmpActivityLogDatesListEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpActivityLogDatesListNode>;
};

export type TmpActivityLogDatesListNode = Node & {
  /** If this config is currently active in the system. This is true only when the date is between logging begin and logging end, and the organization and time management plan are also active. */
  active?: Maybe<Scalars['Boolean']>;
  /** Any appointment with this compliance type id will be given a log entry */
  complianceTypeId: Scalars['Int'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isUserComplianceUser?: Maybe<Scalars['Boolean']>;
  isUserLogAdmin?: Maybe<Scalars['Boolean']>;
  isUserViewer?: Maybe<Scalars['Boolean']>;
  logDates?: Maybe<Array<Maybe<TmpActivityLogConfigLogDatesNode>>>;
  logs?: Maybe<Array<Maybe<TmpActivityLogListNode>>>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  stages?: Maybe<Array<Maybe<TmpActivityLogStage>>>;
  team?: Maybe<TeamNode>;
  /** The team id, stored here for faster lookups. */
  teamId: Scalars['Int'];
  tmpActivityLogConfigId: Scalars['ID'];
  tmpTeam?: Maybe<TmpTeamNode>;
  /** The team's TMP record, which is the direct parent of this config. */
  tmpTeamId: Scalars['Int'];
  tmpYearId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  year?: Maybe<TmpYearNode>;
};

export type TmpActivityLogListConnection = Node & {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpActivityLogListEdge>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TmpActivityLogList` and its cursor. */
export type TmpActivityLogListEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpActivityLogListNode>;
};

export type TmpActivityLogListNode = {
  adminComments?: Maybe<Scalars['String']>;
  /** When true, all respondants for all phases have signed off or abstained on the log. When null, it is still pending. */
  approved?: Maybe<Scalars['Boolean']>;
  athleteCount?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<Maybe<TmpActivityCommentsNode>>>;
  config?: Maybe<TmpTeamSettingsNode>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  isUserComplianceUser?: Maybe<Scalars['Boolean']>;
  isUserLogAdmin?: Maybe<Scalars['Boolean']>;
  isUserViewer?: Maybe<Scalars['Boolean']>;
  logAppointments?: Maybe<Array<Maybe<AbridgedAppointmentNode>>>;
  logAthletesSummary?: Maybe<Array<Maybe<LogAthletesSummaryNode>>>;
  /** The date (inclusive) up to which all appointments are associated with this log. */
  logEndDate: Scalars['DateTime'];
  /** The date (inclusive) from which all appointments are associated with this log. */
  logStartDate: Scalars['DateTime'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /** The date the current signoff stage will end */
  pendingStageDueDate?: Maybe<Scalars['DateTime']>;
  pendingStageLabel?: Maybe<Scalars['String']>;
  /** The signoff stage that the log is currently waiting on. */
  pendingStageSeq?: Maybe<Scalars['Int']>;
  pendingStages?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  responses?: Maybe<Array<Maybe<ActivityLogSignoffResponseNode>>>;
  seasonType?: Maybe<Scalars['String']>;
  segmentTypePerDay?: Maybe<Array<Maybe<DateToLabelNode>>>;
  showSkillInstruction?: Maybe<Scalars['Boolean']>;
  submittedDate?: Maybe<Scalars['String']>;
  submittedNames?: Maybe<Array<Maybe<SubmittedNamesNode>>>;
  /** This log must be related back to a configured logging period. */
  tmpActivityLogConfigId: Scalars['Int'];
  tmpActivityLogId: Scalars['ID'];
  tmpTeamSegment?: Maybe<TmpTeamSegmentConflictNode>;
  tmpTeamSegmentId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Array<Maybe<DateToLabelNode>>>;
};

/** An enumeration. */
export enum TmpActivityLogSignoffResponseSortEnum {
  ApprovalAsc = 'approval_asc',
  ApprovalDesc = 'approval_desc',
  ApprovalGuidAsc = 'approval_guid_asc',
  ApprovalGuidDesc = 'approval_guid_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  ExternalAccessLinkIdAsc = 'external_access_link_id_asc',
  ExternalAccessLinkIdDesc = 'external_access_link_id_desc',
  LockedAsc = 'locked_asc',
  LockedDesc = 'locked_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  ReminderMessageIdAsc = 'reminder_message_id_asc',
  ReminderMessageIdDesc = 'reminder_message_id_desc',
  ReminderStreamMessageMetaAsc = 'reminder_stream_message_meta_asc',
  ReminderStreamMessageMetaDesc = 'reminder_stream_message_meta_desc',
  TmpActivityLogIdAsc = 'tmp_activity_log_id_asc',
  TmpActivityLogIdDesc = 'tmp_activity_log_id_desc',
  TmpActivityLogSignoffResponseIdAsc = 'tmp_activity_log_signoff_response_id_asc',
  TmpActivityLogSignoffResponseIdDesc = 'tmp_activity_log_signoff_response_id_desc',
  TmpActivityLogStageIdAsc = 'tmp_activity_log_stage_id_asc',
  TmpActivityLogStageIdDesc = 'tmp_activity_log_stage_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** An enumeration. */
export enum TmpActivityLogSortEnum {
  ApprovedAsc = 'approved_asc',
  ApprovedDesc = 'approved_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  LogEndDateAsc = 'log_end_date_asc',
  LogEndDateDesc = 'log_end_date_desc',
  LogStartDateAsc = 'log_start_date_asc',
  LogStartDateDesc = 'log_start_date_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PendingStageDueDateAsc = 'pending_stage_due_date_asc',
  PendingStageDueDateDesc = 'pending_stage_due_date_desc',
  PendingStageSeqAsc = 'pending_stage_seq_asc',
  PendingStageSeqDesc = 'pending_stage_seq_desc',
  TmpActivityLogConfigIdAsc = 'tmp_activity_log_config_id_asc',
  TmpActivityLogConfigIdDesc = 'tmp_activity_log_config_id_desc',
  TmpActivityLogIdAsc = 'tmp_activity_log_id_asc',
  TmpActivityLogIdDesc = 'tmp_activity_log_id_desc',
  TmpTeamSegmentIdAsc = 'tmp_team_segment_id_asc',
  TmpTeamSegmentIdDesc = 'tmp_team_segment_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TmpActivityLogStage = {
  /** Whether or not the members of this phase can adjust appointment times. Generally coaches should be able to, but not others. Only the first phase can have this set to True. */
  canEditAppointments: Scalars['Boolean'];
  config?: Maybe<TmpTeamSettingsNode>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The name this stage is given, e.g. 'head coach', 'athletes', 'compliance' */
  label: Scalars['String'];
  org?: Maybe<OrgNode>;
  /** The organization this activity log is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** See the signoff type enum. Should be set to 'web' or 'form'. Will default to web. */
  signoffType?: Maybe<Scalars['String']>;
  /** The sequence number this stage will follow. Multiple stages may have the same sequence, in which case reminder messages are sent at the same time */
  stageSequence: Scalars['Int'];
  /** If this is true, a random subset of the selected people will be selected as responders on this stage. */
  subsetN?: Maybe<Scalars['Int']>;
  /** If this is true, a random subset of the selected people will be selected as responders on this stage. */
  subsetPct?: Maybe<Scalars['Int']>;
  tmpActivityLogConfigId: Scalars['Int'];
  tmpActivityLogStageId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TmpAdditionalConflictInfoNode = {
  academicYearStartDate?: Maybe<Scalars['Date']>;
  athletes?: Maybe<Array<Maybe<TmpAthleteConflictInfoNode>>>;
  tmpTeamSegmentId?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TmpAthleteConflictInfoNode = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  numAppts?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  selectionCode?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
};

export type TmpCalendarOverviewInput = {
  /** Selected athlete to view their calendar */
  athlete: Scalars['String'];
  /** Global ID for selected TMP Team */
  tmpTeamId: Scalars['ID'];
};

export type TmpConflictInfoNode = {
  additionalConflicts?: Maybe<Array<Maybe<TmpAdditionalConflictInfoNode>>>;
  hasConflicts?: Maybe<Scalars['Boolean']>;
  weeklyConflicts?: Maybe<Array<Maybe<TmpWeeklyConflictInfoNode>>>;
};

export type TmpDateConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpDateEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** TmpDateInput for only create */
export type TmpDateCreateInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  label?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  tmpDateTypeId: Scalars['Int'];
  tmpTeamId?: InputMaybe<Scalars['Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['Int']>;
  tmpYearId: Scalars['Int'];
};

export type TmpDateCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpDate?: Maybe<TmpDateNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TmpDateDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpDate?: Maybe<TmpDateNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TmpDate` and its cursor. */
export type TmpDateEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpDateNode>;
};

export type TmpDateNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  dateType?: Maybe<TmpDateTypeNode>;
  endAppointment?: Maybe<TutorAppointmentNode>;
  endAppointmentId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  endDateInclusive?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  segment?: Maybe<TmpTeamSegmentConflictNode>;
  startAppointment?: Maybe<TutorAppointmentNode>;
  startAppointmentId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  team?: Maybe<TmpTeamNode>;
  tmpDateId: Scalars['ID'];
  tmpDateTypeId: Scalars['Int'];
  tmpSegments?: Maybe<TmpTeamSegmentConflictNode>;
  tmpTeamAthleteGroupId?: Maybe<Scalars['Int']>;
  tmpTeamId?: Maybe<Scalars['Int']>;
  tmpTeamSegmentId?: Maybe<Scalars['Int']>;
  tmpYearId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  year?: Maybe<TmpYearNode>;
};

export type TmpDateNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpDateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TmpDateNode` and its cursor. */
export type TmpDateNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpDateNode>;
};

/** An enumeration. */
export enum TmpDateSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EndAppointmentIdAsc = 'end_appointment_id_asc',
  EndAppointmentIdDesc = 'end_appointment_id_desc',
  EndDateAsc = 'end_date_asc',
  EndDateDesc = 'end_date_desc',
  EndDateInclusiveAsc = 'end_date_inclusive_asc',
  EndDateInclusiveDesc = 'end_date_inclusive_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  StartAppointmentIdAsc = 'start_appointment_id_asc',
  StartAppointmentIdDesc = 'start_appointment_id_desc',
  StartDateAsc = 'start_date_asc',
  StartDateDesc = 'start_date_desc',
  TmpDateIdAsc = 'tmp_date_id_asc',
  TmpDateIdDesc = 'tmp_date_id_desc',
  TmpDateTypeIdAsc = 'tmp_date_type_id_asc',
  TmpDateTypeIdDesc = 'tmp_date_type_id_desc',
  TmpTeamAthleteGroupIdAsc = 'tmp_team_athlete_group_id_asc',
  TmpTeamAthleteGroupIdDesc = 'tmp_team_athlete_group_id_desc',
  TmpTeamIdAsc = 'tmp_team_id_asc',
  TmpTeamIdDesc = 'tmp_team_id_desc',
  TmpTeamSegmentIdAsc = 'tmp_team_segment_id_asc',
  TmpTeamSegmentIdDesc = 'tmp_team_segment_id_desc',
  TmpYearIdAsc = 'tmp_year_id_asc',
  TmpYearIdDesc = 'tmp_year_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TmpDateTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpDateTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TmpDateType` and its cursor. */
export type TmpDateTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpDateTypeNode>;
};

export type TmpDateTypeNode = Node & {
  appointmentType?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  countMax?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  dates?: Maybe<TmpDateNodeConnection>;
  hasAppointment?: Maybe<Scalars['Boolean']>;
  hasSeparateRangeAppointments?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isCaraAllowed?: Maybe<Scalars['Boolean']>;
  isRange?: Maybe<Scalars['Boolean']>;
  isRaraAllowed?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  rangeDays?: Maybe<Scalars['Int']>;
  tmpDateTypeId: Scalars['ID'];
  tmpLevel?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TmpDateTypeNodeDatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  endDateInclusive?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpDateSortEnum>>>;
  startAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  tmpDateId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpDateTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamAthleteGroupId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum TmpDateTypeSortEnum {
  AppointmentTypeAsc = 'appointment_type_asc',
  AppointmentTypeDesc = 'appointment_type_desc',
  CodeAsc = 'code_asc',
  CodeDesc = 'code_desc',
  CountMaxAsc = 'count_max_asc',
  CountMaxDesc = 'count_max_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  HasAppointmentAsc = 'has_appointment_asc',
  HasAppointmentDesc = 'has_appointment_desc',
  HasSeparateRangeAppointmentsAsc = 'has_separate_range_appointments_asc',
  HasSeparateRangeAppointmentsDesc = 'has_separate_range_appointments_desc',
  IsCaraAllowedAsc = 'is_cara_allowed_asc',
  IsCaraAllowedDesc = 'is_cara_allowed_desc',
  IsRangeAsc = 'is_range_asc',
  IsRangeDesc = 'is_range_desc',
  IsRaraAllowedAsc = 'is_rara_allowed_asc',
  IsRaraAllowedDesc = 'is_rara_allowed_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  RangeDaysAsc = 'range_days_asc',
  RangeDaysDesc = 'range_days_desc',
  TmpDateTypeIdAsc = 'tmp_date_type_id_asc',
  TmpDateTypeIdDesc = 'tmp_date_type_id_desc',
  TmpLevelAsc = 'tmp_level_asc',
  TmpLevelDesc = 'tmp_level_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** TmpDateInput for only update */
export type TmpDateUpdateInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  label?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type TmpDateUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpDate?: Maybe<TmpDateNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TmpDeclarationsOverviewNode = {
  endAppointment?: Maybe<TmpOverviewApptNode>;
  isInterval?: Maybe<Scalars['Boolean']>;
  startAppointment?: Maybe<TmpOverviewApptNode>;
  tmpDateId?: Maybe<Scalars['Int']>;
  tmpDateType?: Maybe<Scalars['String']>;
};

export type TmpOverviewApptNode = {
  appointmentOccurrenceId?: Maybe<Scalars['Int']>;
  appointmentTitle?: Maybe<Scalars['String']>;
  appointmentTypeLabel?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  countableApptType?: Maybe<Scalars['String']>;
  countableMinutes?: Maybe<Scalars['Int']>;
  dayOffType?: Maybe<Scalars['String']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  isAllDay?: Maybe<Scalars['Boolean']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
};

export type TmpOverviewLegendNode = {
  colorCode?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayType?: Maybe<Scalars['String']>;
};

export type TmpPlanReviewersInput = {
  coach?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  compliance?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sportsAdministrator?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  studentAthletes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TmpPlanReviewersNode = {
  coach?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  compliance?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  sportsAdministrator?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  studentAthletes?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
};

export type TmpRule = Node & {
  bylaw?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  tmpRuleId?: Maybe<Scalars['Int']>;
};

export type TmpRuleNode = Node & {
  bylaw?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  description: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  tmpRuleId: Scalars['ID'];
  tmpYearRules?: Maybe<TmpYearRuleNodeConnection>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TmpRuleNodeTmpYearRulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  commentRequired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  modApptNotificationHours?: InputMaybe<Scalars['TWFilter_Int']>;
  newApptNotificationHours?: InputMaybe<Scalars['TWFilter_Int']>;
  notifyCompliance?: InputMaybe<Scalars['TWFilter_Boolean']>;
  org?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpYearRuleSortEnum>>>;
  tmpRuleId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearRuleId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TmpSummaryNode = {
  reviewers?: Maybe<TmpPlanReviewersNode>;
  tmpSegment?: Maybe<Array<Maybe<TmpTeamSegmentNode>>>;
  totalAthletes?: Maybe<Scalars['Int']>;
};

export type TmpTeamConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpTeamEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TmpTeamCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpTeam?: Maybe<TmpTeamNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TmpTeamDetailsNode = {
  label?: Maybe<Scalars['String']>;
  segments?: Maybe<Array<Maybe<TmpTeamDetailsSegmentNode>>>;
  teamId?: Maybe<Scalars['Int']>;
  tmpActivityLogConfigId?: Maybe<Scalars['Int']>;
  tmpTeamId?: Maybe<Scalars['Int']>;
};

export type TmpTeamDetailsSegmentNode = {
  segmentEndDate?: Maybe<Scalars['DateTime']>;
  segmentStartDate?: Maybe<Scalars['DateTime']>;
};

/** A Relay edge containing a `TmpTeam` and its cursor. */
export type TmpTeamEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpTeamNode>;
};

/** TmpTeamInput used in creating tmp teams */
export type TmpTeamInput = {
  copyFromTmpYearId?: InputMaybe<Scalars['Int']>;
  teamId: Scalars['Int'];
  tmpYearId: Scalars['Int'];
};

export type TmpTeamNode = Node & {
  compliancePerson?: Maybe<PersonNode>;
  compliancePersonId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  firstCaraDate?: Maybe<Scalars['String']>;
  hasAthleteGroups?: Maybe<Scalars['Boolean']>;
  hasEditDeclarationPerm?: Maybe<Scalars['Boolean']>;
  headCoach?: Maybe<PersonNode>;
  headCoachPersonId?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  includeCalendarVisibilityDaysOff?: Maybe<Scalars['Boolean']>;
  org?: Maybe<OrgNode>;
  /** The organization this tmp team is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  segments?: Maybe<Array<Maybe<TmpTeamSegmentNode>>>;
  sportAdmin?: Maybe<PersonNode>;
  sportAdminPersonId?: Maybe<Scalars['Int']>;
  studentAthleteRep?: Maybe<PersonNode>;
  studentAthleteRepPersonId?: Maybe<Scalars['Int']>;
  team?: Maybe<TeamNode>;
  teamId: Scalars['Int'];
  tmpActivityLogConfigs?: Maybe<TmpTeamSettingsNodeConnection>;
  tmpTeamId: Scalars['ID'];
  tmpYearId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  year?: Maybe<TmpYearNode>;
};

export type TmpTeamNodeTmpActivityLogConfigsArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  complianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpActivityLogConfigSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpActivityLogConfigId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TmpTeamPlanFiltersInput = {
  /** Must be used with start_date and as a replacement to tmp_team_segment_ids */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Pass in reviewers to be included within Time Management Plan. */
  planReviewers?: InputMaybe<TmpPlanReviewersInput>;
  /** Must be used with end_date and as a replacement to tmp_team_segment_ids */
  startDate?: InputMaybe<Scalars['Date']>;
  /** TMP Team ID */
  tmpTeamId?: InputMaybe<Scalars['Int']>;
  /** Filter to only return plan for given TMP Team Segments. */
  tmpTeamSegmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TmpTeamPlanNode = {
  competitions?: Maybe<Array<Maybe<CountableEventNode>>>;
  countableEvents?: Maybe<Array<Maybe<CountableEventNode>>>;
  daysOff?: Maybe<Array<Maybe<CountableEventNode>>>;
  reviewers?: Maybe<TmpPlanReviewersNode>;
  summary?: Maybe<TmpSummaryNode>;
  tmpTeam?: Maybe<TmpTeamNode>;
  tmpYear?: Maybe<TmpYearNode>;
};

export type TmpTeamSegmentConflictConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpTeamSegmentConflictEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TmpTeamSegmentConflict` and its cursor. */
export type TmpTeamSegmentConflictEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpTeamSegmentConflictNode>;
};

export type TmpTeamSegmentConflictNode = Node & {
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  acclimatizationPeriodEndDate?: Maybe<Scalars['String']>;
  acclimatizationPeriodStartDate?: Maybe<Scalars['String']>;
  allTmpConflicts?: Maybe<Array<Maybe<TmpConflictInfoNode>>>;
  competitionCount?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  dates?: Maybe<TmpDateNodeConnection>;
  dayCount?: Maybe<Scalars['Int']>;
  firstCaraDate?: Maybe<Scalars['String']>;
  firstCompetitionDate?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  inSeason?: Maybe<Scalars['String']>;
  isChampionship?: Maybe<Scalars['Boolean']>;
  isFinalized?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  /** meta data about this model */
  meta?: Maybe<Scalars['GenericScalar']>;
  metaData?: Maybe<TmpTeamSegmentDefaultMetaObjectType>;
  nineWeekPeriodEndDate?: Maybe<Scalars['String']>;
  nineWeekPeriodStartDate?: Maybe<Scalars['String']>;
  nineWeekPeriodTmpDate?: Maybe<Scalars['String']>;
  orientationDate?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  postSeasonDaysoffEndDate?: Maybe<Scalars['String']>;
  postSeasonDaysoffStartDate?: Maybe<Scalars['String']>;
  postSeasonEndDate?: Maybe<Scalars['String']>;
  postSeasonStartDate?: Maybe<Scalars['String']>;
  preSeasonEndDate?: Maybe<Scalars['String']>;
  preSeasonStartDate?: Maybe<Scalars['String']>;
  seasonType: Scalars['String'];
  segmentEndDate?: Maybe<Scalars['String']>;
  segmentOrder?: Maybe<Scalars['Int']>;
  segmentStartDate?: Maybe<Scalars['String']>;
  segmentTypeId?: Maybe<Scalars['Int']>;
  springDiscretionaryPeriods?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  summerDiscretionaryPeriods?: Maybe<Scalars['String']>;
  team?: Maybe<TmpTeamNode>;
  tmpDates?: Maybe<TmpDateNodeConnection>;
  tmpSegmentType?: Maybe<Scalars['String']>;
  tmpTeamId: Scalars['Int'];
  tmpTeamSegmentId: Scalars['ID'];
  totalWeeks?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** The weekday that the week starts on for Time Management Purposes, starting with Sunday=1 to Saturday=7 */
  weekStart?: Maybe<Scalars['Int']>;
  weekStartDay?: Maybe<Scalars['String']>;
};

export type TmpTeamSegmentConflictNodeDatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  endDateInclusive?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpDateSortEnum>>>;
  startAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  tmpDateId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpDateTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamAthleteGroupId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TmpTeamSegmentConflictNodeTmpDatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  endDateInclusive?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpDateSortEnum>>>;
  startAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  tmpDateId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpDateTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamAthleteGroupId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TmpTeamSegmentConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpTeamSegmentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TmpTeamSegmentCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpTeamSegment?: Maybe<TmpTeamSegmentConflictNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TmpTeamSegmentDefaultMetaObjectType = {
  daysOff?: Maybe<Scalars['String']>;
  selectedNonCountableDays?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TmpTeamSegmentDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpTeamSegment?: Maybe<TmpTeamSegmentConflictNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TmpTeamSegment` and its cursor. */
export type TmpTeamSegmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpTeamSegmentNode>;
};

/** TmpTeamSegmentInput used in adding and updating tmp team segment settings */
export type TmpTeamSegmentInput = {
  acclimatizationPeriodEndDate?: InputMaybe<Scalars['Date']>;
  acclimatizationPeriodStartDate?: InputMaybe<Scalars['Date']>;
  dayCount?: InputMaybe<Scalars['Int']>;
  firstCaraDate?: InputMaybe<Scalars['Date']>;
  inSeason?: InputMaybe<Scalars['Boolean']>;
  isFinalized?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<TmpTeamSegmentMetaInputObjectType>;
  nineWeekPeriodEndDate?: InputMaybe<Scalars['Date']>;
  nineWeekPeriodStartDate?: InputMaybe<Scalars['Date']>;
  orientationDate?: InputMaybe<Scalars['Date']>;
  postSeasonDaysoffEndDate?: InputMaybe<Scalars['Date']>;
  postSeasonDaysoffStartDate?: InputMaybe<Scalars['Date']>;
  postSeasonEndDate?: InputMaybe<Scalars['Date']>;
  postSeasonStartDate?: InputMaybe<Scalars['Date']>;
  preSeasonEndDate?: InputMaybe<Scalars['Date']>;
  preSeasonStartDate?: InputMaybe<Scalars['Date']>;
  segmentEndDate?: InputMaybe<Scalars['Date']>;
  segmentStartDate?: InputMaybe<Scalars['Date']>;
  springDiscretionaryPeriods?: InputMaybe<Array<InputMaybe<DateRangeObjectType>>>;
  status?: InputMaybe<Scalars['String']>;
  summerDiscretionaryPeriods?: InputMaybe<Array<InputMaybe<DateRangeObjectType>>>;
  tmpSegmentType?: InputMaybe<Scalars['String']>;
  tmpTeamId?: InputMaybe<Scalars['Int']>;
  weekStart?: InputMaybe<Scalars['Int']>;
};

export type TmpTeamSegmentMetaInputObjectType = {
  daysOff?: InputMaybe<Scalars['String']>;
  selectedNonCountableDays?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TmpTeamSegmentNode = Node & {
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  acclimatizationPeriodEndDate?: Maybe<Scalars['String']>;
  acclimatizationPeriodStartDate?: Maybe<Scalars['String']>;
  competitionCount?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  dates?: Maybe<TmpDateNodeConnection>;
  dayCount?: Maybe<Scalars['Int']>;
  firstCaraDate?: Maybe<Scalars['String']>;
  firstCompetitionDate?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  inSeason?: Maybe<Scalars['String']>;
  isChampionship?: Maybe<Scalars['Boolean']>;
  isFinalized?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  /** meta data about this model */
  meta?: Maybe<Scalars['GenericScalar']>;
  metaData?: Maybe<TmpTeamSegmentDefaultMetaObjectType>;
  nineWeekPeriodEndDate?: Maybe<Scalars['String']>;
  nineWeekPeriodStartDate?: Maybe<Scalars['String']>;
  nineWeekPeriodTmpDate?: Maybe<Scalars['String']>;
  orientationDate?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  postSeasonDaysoffEndDate?: Maybe<Scalars['String']>;
  postSeasonDaysoffStartDate?: Maybe<Scalars['String']>;
  postSeasonEndDate?: Maybe<Scalars['String']>;
  postSeasonStartDate?: Maybe<Scalars['String']>;
  preSeasonEndDate?: Maybe<Scalars['String']>;
  preSeasonStartDate?: Maybe<Scalars['String']>;
  seasonType: Scalars['String'];
  segmentEndDate?: Maybe<Scalars['String']>;
  segmentHasConflict?: Maybe<Scalars['Boolean']>;
  segmentOrder?: Maybe<Scalars['Int']>;
  segmentStartDate?: Maybe<Scalars['String']>;
  segmentTypeId?: Maybe<Scalars['Int']>;
  springDiscretionaryPeriods?: Maybe<Array<Maybe<TmpDateNode>>>;
  status?: Maybe<Scalars['String']>;
  summerDiscretionaryPeriods?: Maybe<Array<Maybe<TmpDateNode>>>;
  team?: Maybe<TmpTeamNode>;
  tmpDates?: Maybe<TmpDateNodeConnection>;
  tmpSegmentType?: Maybe<Scalars['String']>;
  tmpTeamId: Scalars['Int'];
  tmpTeamSegmentId: Scalars['ID'];
  totalWeeks?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** The weekday that the week starts on for Time Management Purposes, starting with Sunday=1 to Saturday=7 */
  weekStart?: Maybe<Scalars['Int']>;
  weekStartDay?: Maybe<Scalars['String']>;
};

export type TmpTeamSegmentNodeDatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  endDateInclusive?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpDateSortEnum>>>;
  startAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  tmpDateId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpDateTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamAthleteGroupId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TmpTeamSegmentNodeTmpDatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  endDateInclusive?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpDateSortEnum>>>;
  startAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  tmpDateId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpDateTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamAthleteGroupId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum TmpTeamSegmentSortEnum {
  CompetitionCountAsc = 'competition_count_asc',
  CompetitionCountDesc = 'competition_count_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DayCountAsc = 'day_count_asc',
  DayCountDesc = 'day_count_desc',
  EndDateAsc = 'end_date_asc',
  EndDateDesc = 'end_date_desc',
  FirstCompetitionDateAsc = 'first_competition_date_asc',
  FirstCompetitionDateDesc = 'first_competition_date_desc',
  IsChampionshipAsc = 'is_championship_asc',
  IsChampionshipDesc = 'is_championship_desc',
  IsFinalizedAsc = 'is_finalized_asc',
  IsFinalizedDesc = 'is_finalized_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  MetaAsc = 'meta_asc',
  MetaDesc = 'meta_desc',
  SeasonTypeAsc = 'season_type_asc',
  SeasonTypeDesc = 'season_type_desc',
  SegmentOrderAsc = 'segment_order_asc',
  SegmentOrderDesc = 'segment_order_desc',
  SegmentTypeIdAsc = 'segment_type_id_asc',
  SegmentTypeIdDesc = 'segment_type_id_desc',
  StartDateAsc = 'start_date_asc',
  StartDateDesc = 'start_date_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  TmpSegmentTypeAsc = 'tmp_segment_type_asc',
  TmpSegmentTypeDesc = 'tmp_segment_type_desc',
  TmpTeamIdAsc = 'tmp_team_id_asc',
  TmpTeamIdDesc = 'tmp_team_id_desc',
  TmpTeamSegmentIdAsc = 'tmp_team_segment_id_asc',
  TmpTeamSegmentIdDesc = 'tmp_team_segment_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  WeekStartAsc = 'week_start_asc',
  WeekStartDesc = 'week_start_desc',
}

export type TmpTeamSegmentUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpTeamSegment?: Maybe<TmpTeamSegmentConflictNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TmpTeamSettingsConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpTeamSettingsEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TmpTeamSettingsDataNode = {
  autoGenerateLogs?: Maybe<Scalars['Boolean']>;
  calendarVisibilityUsers?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  conflictNotificationsVisibilityUsers?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  daysOffVisibilityAthleteAndDeclaration?: Maybe<Scalars['Boolean']>;
  daysOffVisibilityAthleteOnly?: Maybe<Scalars['Boolean']>;
  editDeclarationsVisibilityUsers?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  logAdminUsers?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  logAthleteUsers?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  logComplianceUsers?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  logViewerUsers?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  percentAthleteSigners?: Maybe<Scalars['Int']>;
  tmpConfigId?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TmpTeamSettings` and its cursor. */
export type TmpTeamSettingsEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpTeamSettingsNode>;
};

/** TmpTeamSettingsInput used in updating tmp team settings */
export type TmpTeamSettingsInput = {
  autoGenerateLogs?: InputMaybe<Scalars['Boolean']>;
  calendarVisibilityUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  conflictNotificationsVisibilityUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  daysOffVisibilityAthleteAndDeclaration?: InputMaybe<Scalars['Boolean']>;
  daysOffVisibilityAthleteOnly?: InputMaybe<Scalars['Boolean']>;
  editDeclarationsVisibilityUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  logAdminUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  logAthleteUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  logComplianceUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  logViewerUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  percentAthleteSigners?: InputMaybe<Scalars['Int']>;
  tmpTeamId: Scalars['Int'];
  tmpYearId: Scalars['Int'];
};

export type TmpTeamSettingsNode = Node & {
  /** If this config is currently active in the system. This is true only when the date is between logging begin and logging end, and the organization and time management plan are also active. */
  active?: Maybe<Scalars['Boolean']>;
  /** Any appointment with this compliance type id will be given a log entry */
  complianceTypeId: Scalars['Int'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  logs?: Maybe<Array<Maybe<TmpActivityLogListNode>>>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  stages?: Maybe<Array<Maybe<TmpActivityLogStage>>>;
  team?: Maybe<TeamNode>;
  /** The team id, stored here for faster lookups. */
  teamId: Scalars['Int'];
  tmpActivityLogConfigId: Scalars['ID'];
  tmpTeam?: Maybe<TmpTeamNode>;
  /** The team's TMP record, which is the direct parent of this config. */
  tmpTeamId: Scalars['Int'];
  tmpTeamSettingsData?: Maybe<Array<Maybe<TmpTeamSettingsDataNode>>>;
  tmpYearId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  year?: Maybe<TmpYearNode>;
};

export type TmpTeamSettingsNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpTeamSettingsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TmpTeamSettingsNode` and its cursor. */
export type TmpTeamSettingsNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpTeamSettingsNode>;
};

export type TmpTeamSettingsUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpActivityLogConfig?: Maybe<TmpTeamSettingsNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum TmpTeamSortEnum {
  CompliancePersonIdAsc = 'compliance_person_id_asc',
  CompliancePersonIdDesc = 'compliance_person_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  HasAthleteGroupsAsc = 'has_athlete_groups_asc',
  HasAthleteGroupsDesc = 'has_athlete_groups_desc',
  HeadCoachPersonIdAsc = 'head_coach_person_id_asc',
  HeadCoachPersonIdDesc = 'head_coach_person_id_desc',
  IncludeCalendarVisibilityDaysOffAsc = 'include_calendar_visibility_days_off_asc',
  IncludeCalendarVisibilityDaysOffDesc = 'include_calendar_visibility_days_off_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  SportAdminPersonIdAsc = 'sport_admin_person_id_asc',
  SportAdminPersonIdDesc = 'sport_admin_person_id_desc',
  StudentAthleteRepPersonIdAsc = 'student_athlete_rep_person_id_asc',
  StudentAthleteRepPersonIdDesc = 'student_athlete_rep_person_id_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TmpTeamIdAsc = 'tmp_team_id_asc',
  TmpTeamIdDesc = 'tmp_team_id_desc',
  TmpYearIdAsc = 'tmp_year_id_asc',
  TmpYearIdDesc = 'tmp_year_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TmpWeeklyConflictInfoNode = {
  athletes?: Maybe<Array<Maybe<TmpAthleteConflictInfoNode>>>;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tmpTeamSegmentId?: Maybe<Scalars['Int']>;
  weekLabel?: Maybe<Scalars['String']>;
};

export type TmpYearConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpYearEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TmpYear` and its cursor. */
export type TmpYearEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpYearNode>;
};

export type TmpYearNode = Node & {
  chancellor?: Maybe<PersonNode>;
  chancellorPersonId?: Maybe<Scalars['Int']>;
  chancellorTitle?: Maybe<Scalars['String']>;
  complianceDirector?: Maybe<PersonNode>;
  complianceDirectorPersonId?: Maybe<Scalars['Int']>;
  complianceDirectorTitle?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  dates?: Maybe<TmpDateNodeConnection>;
  directorAthletics?: Maybe<PersonNode>;
  directorAthleticsPersonId?: Maybe<Scalars['Int']>;
  directorAthleticsTitle?: Maybe<Scalars['String']>;
  facultyAthleticPerson?: Maybe<PersonNode>;
  facultyAthleticPersonId?: Maybe<Scalars['Int']>;
  facultyAthleticTitle?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  label: Scalars['String'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  termCount: Scalars['Int'];
  tmpActivityLogConfigs?: Maybe<TmpTeamSettingsNodeConnection>;
  tmpTeamDetails?: Maybe<Array<Maybe<TmpTeamDetailsNode>>>;
  tmpTeams?: Maybe<Array<Maybe<TmpTeamNode>>>;
  tmpYearId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  yearRules?: Maybe<TmpYearRuleNodeConnection>;
};

export type TmpYearNodeDatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  endDateInclusive?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['TWFilter_String']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpDateSortEnum>>>;
  startAppointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  tmpDateId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpDateTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamAthleteGroupId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpTeamSegmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TmpYearNodeTmpActivityLogConfigsArgs = {
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  complianceTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpActivityLogConfigSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpActivityLogConfigId?: InputMaybe<Scalars['TWFilter_ID']>;
  tmpTeamId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TmpYearNodeYearRulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  commentRequired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isEnabled?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  modApptNotificationHours?: InputMaybe<Scalars['TWFilter_Int']>;
  newApptNotificationHours?: InputMaybe<Scalars['TWFilter_Int']>;
  notifyCompliance?: InputMaybe<Scalars['TWFilter_Boolean']>;
  org?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_String']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TmpYearRuleSortEnum>>>;
  tmpRuleId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  tmpYearRuleId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TmpYearNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpYearNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TmpYearNode` and its cursor. */
export type TmpYearNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpYearNode>;
};

export type TmpYearRule = Node & {
  commentRequired?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  notifyCompliance?: Maybe<Scalars['Boolean']>;
  tmpYearRuleId?: Maybe<Scalars['Int']>;
};

export type TmpYearRuleConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpYearRuleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TmpYearRuleCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpYearRule?: Maybe<TmpYearRuleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TmpYearRule` and its cursor. */
export type TmpYearRuleEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpYearRuleNode>;
};

/** TmpYearRuleInput used in both create and update */
export type TmpYearRuleInput = {
  commentRequired?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  modApptNotificationHours?: InputMaybe<Scalars['Int']>;
  newApptNotificationHours?: InputMaybe<Scalars['Int']>;
  notifyCompliance?: InputMaybe<Scalars['Boolean']>;
  skipNotificationsValue?: InputMaybe<Scalars['Boolean']>;
  tmpYear?: InputMaybe<Scalars['ID']>;
};

export type TmpYearRuleNode = Node & {
  commentRequired?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  modApptNotificationHours?: Maybe<Scalars['Int']>;
  newApptNotificationHours?: Maybe<Scalars['Int']>;
  notifyCompliance?: Maybe<Scalars['Boolean']>;
  org?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  rule?: Maybe<Array<Maybe<TmpRuleNode>>>;
  tmpRuleId?: Maybe<Scalars['Int']>;
  tmpYearId?: Maybe<Scalars['Int']>;
  tmpYearRuleId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  year?: Maybe<TmpYearNode>;
};

export type TmpYearRuleNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TmpYearRuleNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TmpYearRuleNode` and its cursor. */
export type TmpYearRuleNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TmpYearRuleNode>;
};

/** An enumeration. */
export enum TmpYearRuleSortEnum {
  CommentRequiredAsc = 'comment_required_asc',
  CommentRequiredDesc = 'comment_required_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsEnabledAsc = 'is_enabled_asc',
  IsEnabledDesc = 'is_enabled_desc',
  ModApptNotificationHoursAsc = 'mod_appt_notification_hours_asc',
  ModApptNotificationHoursDesc = 'mod_appt_notification_hours_desc',
  NewApptNotificationHoursAsc = 'new_appt_notification_hours_asc',
  NewApptNotificationHoursDesc = 'new_appt_notification_hours_desc',
  NotifyComplianceAsc = 'notify_compliance_asc',
  NotifyComplianceDesc = 'notify_compliance_desc',
  TmpRuleIdAsc = 'tmp_rule_id_asc',
  TmpRuleIdDesc = 'tmp_rule_id_desc',
  TmpYearIdAsc = 'tmp_year_id_asc',
  TmpYearIdDesc = 'tmp_year_id_desc',
  TmpYearRuleIdAsc = 'tmp_year_rule_id_asc',
  TmpYearRuleIdDesc = 'tmp_year_rule_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TmpYearRuleUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpYearRule?: Maybe<TmpYearRuleNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum TmpYearSortEnum {
  ChancellorPersonIdAsc = 'chancellor_person_id_asc',
  ChancellorPersonIdDesc = 'chancellor_person_id_desc',
  ChancellorTitleAsc = 'chancellor_title_asc',
  ChancellorTitleDesc = 'chancellor_title_desc',
  ComplianceDirectorPersonIdAsc = 'compliance_director_person_id_asc',
  ComplianceDirectorPersonIdDesc = 'compliance_director_person_id_desc',
  ComplianceDirectorTitleAsc = 'compliance_director_title_asc',
  ComplianceDirectorTitleDesc = 'compliance_director_title_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DirectorAthleticsPersonIdAsc = 'director_athletics_person_id_asc',
  DirectorAthleticsPersonIdDesc = 'director_athletics_person_id_desc',
  DirectorAthleticsTitleAsc = 'director_athletics_title_asc',
  DirectorAthleticsTitleDesc = 'director_athletics_title_desc',
  FacultyAthleticPersonIdAsc = 'faculty_athletic_person_id_asc',
  FacultyAthleticPersonIdDesc = 'faculty_athletic_person_id_desc',
  FacultyAthleticTitleAsc = 'faculty_athletic_title_asc',
  FacultyAthleticTitleDesc = 'faculty_athletic_title_desc',
  IsCurrentAsc = 'is_current_asc',
  IsCurrentDesc = 'is_current_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  TermCountAsc = 'term_count_asc',
  TermCountDesc = 'term_count_desc',
  TmpYearIdAsc = 'tmp_year_id_asc',
  TmpYearIdDesc = 'tmp_year_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TmpYearUpdateInput = {
  isCurrent?: InputMaybe<Scalars['Boolean']>;
};

export type TmpYearUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  tmpYear?: Maybe<TmpYearNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Serves as an endpoint to ping that will refresh access token and refresh the CF session */
export type TokenRefresh = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ok?: Maybe<Scalars['Boolean']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TokenResponse = {
  streamUserId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type TravelAirSeatingChart = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** label */
  label: Scalars['String'];
  numberOfSeats?: Maybe<Scalars['Int']>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** seatplan_configuration_json */
  seatplanConfigurationJson: Scalars['String'];
  team?: Maybe<TeamNode>;
  /** team_id */
  teamId?: Maybe<Scalars['Int']>;
  /** travel_equipment_id */
  travelAirSeatingChartId: Scalars['ID'];
  travelVendor?: Maybe<TravelVendor>;
  /** travel_vendor_id */
  travelVendorId: Scalars['Int'];
  travelVendorType?: Maybe<TravelVendorTypeNode>;
  /** travel_vendor_type_id */
  travelVendorTypeId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelAirSeatingChartCreateInput = {
  label: Scalars['String'];
  seatplanConfigurationJson: Scalars['String'];
  travelVendor: Scalars['ID'];
};

export type TravelAirSeatingChartCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelAirSeatingChart?: Maybe<TravelAirSeatingChart>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelAirSeatingChartDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelAirSeatingChart?: Maybe<TravelAirSeatingChart>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelAirSeatingChartUpdateInput = {
  label?: InputMaybe<Scalars['String']>;
  seatplanConfigurationJson?: InputMaybe<Scalars['String']>;
};

export type TravelAirSeatingChartUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelAirSeatingChart?: Maybe<TravelAirSeatingChart>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelContactCreateInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  isPrimaryContact?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  phoneFax?: InputMaybe<Scalars['String']>;
  phoneMobile?: InputMaybe<Scalars['String']>;
  phoneOffice?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  travelVendor: Scalars['ID'];
};

export type TravelContactCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelContact?: Maybe<TravelContactNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelContactDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelContact?: Maybe<TravelContactNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelContactInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneFax?: InputMaybe<Scalars['String']>;
  phoneMobile?: InputMaybe<Scalars['String']>;
  phoneOffice?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TravelContactNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** email_address */
  emailAddress?: Maybe<Scalars['String']>;
  /** first_name */
  firstName?: Maybe<Scalars['String']>;
  /** Returns full name */
  fullName?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  institution?: Maybe<InstitutionNode>;
  /** institution_id */
  institutionId?: Maybe<Scalars['Int']>;
  /** is_primary_contact */
  isPrimaryContact: Scalars['Boolean'];
  /** last_name */
  lastName?: Maybe<Scalars['String']>;
  /** notes */
  notes?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /** phone_fax */
  phoneFax?: Maybe<Scalars['String']>;
  /** phone_mobile */
  phoneMobile?: Maybe<Scalars['String']>;
  /** phone_office */
  phoneOffice?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
  /** team_id */
  teamId?: Maybe<Scalars['Int']>;
  /** title */
  title?: Maybe<Scalars['String']>;
  /** travel_contact_id */
  travelContactId: Scalars['ID'];
  travelVendor?: Maybe<TravelVendor>;
  /** travel_vendor_id */
  travelVendorId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelContactUpdateInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isPrimaryContact?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneFax?: InputMaybe<Scalars['String']>;
  phoneMobile?: InputMaybe<Scalars['String']>;
  phoneOffice?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TravelContactUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelContact?: Maybe<TravelContactNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelCredential = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** is_active */
  isActive?: Maybe<Scalars['Boolean']>;
  /** label */
  label?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  /** school_id */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
  /** team_id */
  teamId: Scalars['Int'];
  /** travel_credential_id */
  travelCredentialId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelCredentialAssignment = Node & {
  competition?: Maybe<CompetitionNode>;
  competitionId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** person_id of the traveler with the assigned credential */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  team?: Maybe<TeamNode>;
  teamId: Scalars['Int'];
  travelCredential?: Maybe<TravelCredential>;
  travelCredentialAssignmentId: Scalars['ID'];
  travelCredentialId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelCredentialAssignmentBulkInput = {
  /** Global ID for the competition where the credentials are being assigned */
  competition: Scalars['ID'];
  credentialAssignments: Array<InputMaybe<TravelCredentialAssignmentInput>>;
};

export type TravelCredentialAssignmentBulkUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelCredentialAssignment?: Maybe<Array<Maybe<TravelCredentialAssignment>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelCredentialAssignmentConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelCredentialAssignmentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TravelCredentialAssignmentCopyInput = {
  /** Global ID for the competition where the credentials are being copied to */
  competition: Scalars['ID'];
  /** List of person gids for whom we want to copy the credential assignments */
  persons: Array<InputMaybe<Scalars['ID']>>;
  /** Global ID for the competition where the credentials are being copied from */
  sourceCompetition: Scalars['ID'];
};

export type TravelCredentialAssignmentCopyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelCredentialAssignment?: Maybe<Array<Maybe<TravelCredentialAssignment>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TravelCredentialAssignment` and its cursor. */
export type TravelCredentialAssignmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelCredentialAssignment>;
};

export type TravelCredentialAssignmentInput = {
  notes?: InputMaybe<Scalars['String']>;
  /** Global ID for the person that has the credential assigned */
  person: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
  /** Global ID for the credential that is being assigned */
  travelCredential: Scalars['ID'];
};

export type TravelCredentialAssignmentNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelCredentialAssignmentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TravelCredentialAssignmentNode` and its cursor. */
export type TravelCredentialAssignmentNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelCredentialAssignment>;
};

/** An enumeration. */
export enum TravelCredentialAssignmentSortEnum {
  CompetitionIdAsc = 'competition_id_asc',
  CompetitionIdDesc = 'competition_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  QuantityAsc = 'quantity_asc',
  QuantityDesc = 'quantity_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TravelCredentialAssignmentIdAsc = 'travel_credential_assignment_id_asc',
  TravelCredentialAssignmentIdDesc = 'travel_credential_assignment_id_desc',
  TravelCredentialIdAsc = 'travel_credential_id_asc',
  TravelCredentialIdDesc = 'travel_credential_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TravelCredentialConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelCredentialEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TravelCredentialCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelCredential?: Maybe<TravelCredential>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelCredentialDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelCredential?: Maybe<TravelCredential>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TravelCredential` and its cursor. */
export type TravelCredentialEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelCredential>;
};

export type TravelCredentialInput = {
  label: Scalars['String'];
};

/** An enumeration. */
export enum TravelCredentialSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TravelCredentialIdAsc = 'travel_credential_id_asc',
  TravelCredentialIdDesc = 'travel_credential_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TravelCredentialUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelCredential?: Maybe<TravelCredential>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelDocumentConfigInput = {
  config: Scalars['JSONString'];
};

export type TravelDocumentConfigNode = Node & {
  config?: Maybe<Scalars['JSONString']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
  teamId: Scalars['Int'];
  travelDocumentConfigId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelDocumentConfigNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelDocumentConfigNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TravelDocumentConfigNode` and its cursor. */
export type TravelDocumentConfigNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelDocumentConfigNode>;
};

export type TravelDocumentConfigUpsertPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelDocumentConfig?: Maybe<TravelDocumentConfigNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelDressConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelDressEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TravelDressCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelDress?: Maybe<TravelDressNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelDressDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelDress?: Maybe<TravelDressNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TravelDress` and its cursor. */
export type TravelDressEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelDressNode>;
};

export type TravelDressInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
};

export type TravelDressNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** is_active */
  isActive?: Maybe<Scalars['Boolean']>;
  /** This field will be used for the team wide check on is_default */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** label */
  label?: Maybe<Scalars['String']>;
  org?: Maybe<OrgNode>;
  /** school_id */
  orgId?: Maybe<Scalars['Int']>;
  /** This field will be use for the org wide check on is_default. This is introduced with multiple teams trips */
  orgIsDefault?: Maybe<Scalars['Boolean']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
  /** team_id */
  teamId?: Maybe<Scalars['Int']>;
  /** travel_dress_id */
  travelDressId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum TravelDressSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsDefaultAsc = 'is_default_asc',
  IsDefaultDesc = 'is_default_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  OrgIsDefaultAsc = 'org_is_default_asc',
  OrgIsDefaultDesc = 'org_is_default_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TravelDressIdAsc = 'travel_dress_id_asc',
  TravelDressIdDesc = 'travel_dress_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TravelDressUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelDress?: Maybe<TravelDressNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelEventAppointmentMeta = {
  highlight?: InputMaybe<AppointmentHighlightInput>;
};

export type TravelEventBulkDeleteInput = {
  eventIds: Array<InputMaybe<Scalars['ID']>>;
};

export type TravelEventBulkDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelEventBulkField = {
  appointmentType?: InputMaybe<Scalars['ID']>;
  endDatetime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  startDatetime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  timeZoneCode?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  travelEventId: Scalars['ID'];
  travelEventType?: InputMaybe<Scalars['ID']>;
};

export type TravelEventBulkUpdateInput = {
  events: Array<InputMaybe<TravelEventBulkField>>;
};

export type TravelEventBulkUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelEventCopyField = {
  startDate: Scalars['GraphenePendulumDateTime'];
  travelEventId: Scalars['ID'];
};

export type TravelEventCopyInput = {
  events: Array<InputMaybe<TravelEventCopyField>>;
};

export type TravelEventCopyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelEventCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelEvent?: Maybe<TravelEventNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelEventDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelEvent?: Maybe<TravelEventNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelEventInput = {
  appointmentMeta?: InputMaybe<TravelEventAppointmentMeta>;
  appointmentType?: InputMaybe<Scalars['ID']>;
  apptCheckinArgs?: InputMaybe<AppointmentCheckinInput>;
  awayInstitution?: InputMaybe<Scalars['ID']>;
  customOpponent?: InputMaybe<Scalars['String']>;
  customSelection?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  enableCheckins?: InputMaybe<Scalars['Boolean']>;
  endDatetime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  hasCustomOpponent?: InputMaybe<Scalars['Boolean']>;
  homeInstitution?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationTypeEnum>;
  notes?: InputMaybe<Scalars['String']>;
  opponentInstitution?: InputMaybe<Scalars['ID']>;
  pregameVisibilities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reminders?: InputMaybe<Array<InputMaybe<AppointmentReminders>>>;
  selectionType?: InputMaybe<TripCalendarVisibilityEnum>;
  sendAlerts?: InputMaybe<EventAlertTypeEnum>;
  slideTimeInterval?: InputMaybe<TimeDeltaInput>;
  startDatetime?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  timeZoneCode?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  travelEventType?: InputMaybe<Scalars['ID']>;
  trip?: InputMaybe<Scalars['ID']>;
};

export type TravelEventNode = Node & {
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['Int']>;
  appointmentType?: Maybe<AppointmentType>;
  appointmentTypeId?: Maybe<Scalars['Int']>;
  attendees?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  caraRequired?: Maybe<Scalars['Boolean']>;
  competition?: Maybe<CompetitionNode>;
  competitionId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  hasTicketAllocations?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  timeZoneId?: Maybe<Scalars['Int']>;
  travelEventId: Scalars['ID'];
  travelEventType?: Maybe<TravelEventTypeNode>;
  travelEventTypeId?: Maybe<Scalars['Int']>;
  trip?: Maybe<Trip>;
  tripId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelEventNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelEventNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TravelEventNode` and its cursor. */
export type TravelEventNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelEventNode>;
};

/** An enumeration. */
export enum TravelEventSortEnum {
  AppointmentIdAsc = 'appointment_id_asc',
  AppointmentIdDesc = 'appointment_id_desc',
  AppointmentTypeIdAsc = 'appointment_type_id_asc',
  AppointmentTypeIdDesc = 'appointment_type_id_desc',
  CaraRequiredAsc = 'cara_required_asc',
  CaraRequiredDesc = 'cara_required_desc',
  CompetitionIdAsc = 'competition_id_asc',
  CompetitionIdDesc = 'competition_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  TimeZoneIdAsc = 'time_zone_id_asc',
  TimeZoneIdDesc = 'time_zone_id_desc',
  TravelEventIdAsc = 'travel_event_id_asc',
  TravelEventIdDesc = 'travel_event_id_desc',
  TravelEventTypeIdAsc = 'travel_event_type_id_asc',
  TravelEventTypeIdDesc = 'travel_event_type_id_desc',
  TripIdAsc = 'trip_id_asc',
  TripIdDesc = 'trip_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TravelEventTypeNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** label */
  label: Scalars['String'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** travel_event_type_id */
  travelEventTypeId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelEventTypeNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelEventTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TravelEventTypeNode` and its cursor. */
export type TravelEventTypeNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelEventTypeNode>;
};

export type TravelEventUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelEvent?: Maybe<TravelEventNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Duplicated here from Profiles module to avoid (unbelievably annoying) circular import. */
export enum TravelGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Nonbinary = 'NONBINARY',
}

export type TravelParticipation = Node & {
  competition?: Maybe<CompetitionNode>;
  competitionId: Scalars['Int'];
  competitionParticipationId: Scalars['Int'];
  competitionPersonParticipationId: Scalars['ID'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  didParticipate?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  travelPartyMemberId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelParticipationBulkUpsertInput = {
  /** Global ID for the competition for participation */
  competition: Scalars['ID'];
  participants: Array<InputMaybe<TravelParticipationInput>>;
};

export type TravelParticipationBulkUpsertPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelParticipation?: Maybe<Array<Maybe<TravelParticipation>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelParticipationConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelParticipationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TravelParticipation` and its cursor. */
export type TravelParticipationEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelParticipation>;
};

export type TravelParticipationInput = {
  /** Whether the person has participated in the competition */
  didParticipate: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  /** Global ID for the person that has participation assigned */
  person: Scalars['ID'];
};

export type TravelParticipationReportConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelParticipationReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TravelParticipationReportCreateInput = {
  tmpYear: Scalars['ID'];
};

export type TravelParticipationReportCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelParticipationReport?: Maybe<TravelParticipationReportNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TravelParticipationReport` and its cursor. */
export type TravelParticipationReportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelParticipationReportNode>;
};

export type TravelParticipationReportNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  downloadableFileId: Scalars['ID'];
  errorMessage?: Maybe<Scalars['String']>;
  fileNameServer?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** What kind of downloaded file this is, e.g. 'report'. */
  kind: Scalars['String'];
  /** Forward-compatible metadata column. */
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<OrgNode>;
  /** The Teamworks Org Id that this is associated with this */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Teamworks Person Id that this is associated with this */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Status of the file, i.e. one of `&.STATUS_*`. */
  status: Scalars['String'];
  team?: Maybe<TeamNode>;
  /** The Teamworks Team Id that this is associated with this */
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  travelParticipationReportId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** Cached copy of the public URL. */
  url?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum TravelParticipationSortEnum {
  CompetitionIdAsc = 'competition_id_asc',
  CompetitionIdDesc = 'competition_id_desc',
  CompetitionParticipationIdAsc = 'competition_participation_id_asc',
  CompetitionParticipationIdDesc = 'competition_participation_id_desc',
  CompetitionPersonParticipationIdAsc = 'competition_person_participation_id_asc',
  CompetitionPersonParticipationIdDesc = 'competition_person_participation_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DidParticipateAsc = 'did_participate_asc',
  DidParticipateDesc = 'did_participate_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  TravelPartyMemberIdAsc = 'travel_party_member_id_asc',
  TravelPartyMemberIdDesc = 'travel_party_member_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** Duplicated from Profiles|PersonAttributeInput to avoid (unbelievably annoying) circular import. */
export type TravelPartyMemberInput = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<TravelGender>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  perDiem?: InputMaybe<Scalars['Float']>;
  personId?: InputMaybe<Scalars['ID']>;
  travelDressId?: InputMaybe<Scalars['ID']>;
  travelWeight?: InputMaybe<Scalars['Int']>;
};

export type TravelPartyMemberNode = Node & {
  competition?: Maybe<CompetitionNode>;
  /** competition_id */
  competitionId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** notes */
  notes?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /** per_diem */
  perDiem?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** person_id */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  travelDress?: Maybe<Scalars['String']>;
  /** travel_dress_id */
  travelDressId?: Maybe<Scalars['Int']>;
  /** travel_duty_id */
  travelDutyId?: Maybe<Scalars['Int']>;
  /** travel_party_member_id */
  travelPartyMemberId: Scalars['ID'];
  trip?: Maybe<Trip>;
  /** trip_id */
  tripId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelPregameItem = Node & {
  competition?: Maybe<CompetitionNode>;
  /** competition_id */
  competitionId: Scalars['Int'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** minutes_before_start */
  minutesBeforeStart: Scalars['Int'];
  org?: Maybe<OrgNode>;
  /** school_id */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** seconds_before_start */
  secondsBeforeStart: Scalars['Int'];
  team?: Maybe<TeamNode>;
  /** team_id */
  teamId: Scalars['Int'];
  timeBeforeStartSeconds?: Maybe<Scalars['Int']>;
  /** title */
  title: Scalars['String'];
  /** travel_pregame_item_id */
  travelPregameItemId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelPregameItemConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelPregameItemEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TravelPregameItemCopyInput = {
  competition: Scalars['ID'];
  travelPregameItems: Array<InputMaybe<Scalars['ID']>>;
};

export type TravelPregameItemCopyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelPregameItem?: Maybe<Array<Maybe<TravelPregameItem>>>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelPregameItemCreateInput = {
  competition: Scalars['ID'];
  minutesBeforeStart: Scalars['Int'];
  secondsBeforeStart: Scalars['Int'];
  title: Scalars['String'];
};

export type TravelPregameItemCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelPregameItem?: Maybe<TravelPregameItem>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelPregameItemDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelPregameItem?: Maybe<TravelPregameItem>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TravelPregameItem` and its cursor. */
export type TravelPregameItemEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelPregameItem>;
};

export type TravelPregameItemNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelPregameItemNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TravelPregameItemNode` and its cursor. */
export type TravelPregameItemNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelPregameItem>;
};

/** An enumeration. */
export enum TravelPregameItemSortEnum {
  CompetitionIdAsc = 'competition_id_asc',
  CompetitionIdDesc = 'competition_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  MinutesBeforeStartAsc = 'minutes_before_start_asc',
  MinutesBeforeStartDesc = 'minutes_before_start_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SecondsBeforeStartAsc = 'seconds_before_start_asc',
  SecondsBeforeStartDesc = 'seconds_before_start_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TimeBeforeStartSecondsAsc = 'time_before_start_seconds_asc',
  TimeBeforeStartSecondsDesc = 'time_before_start_seconds_desc',
  TitleAsc = 'title_asc',
  TitleDesc = 'title_desc',
  TravelPregameItemIdAsc = 'travel_pregame_item_id_asc',
  TravelPregameItemIdDesc = 'travel_pregame_item_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TravelPregameItemUpdateInput = {
  minutesBeforeStart?: InputMaybe<Scalars['Int']>;
  secondsBeforeStart?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TravelPregameItemUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelPregameItem?: Maybe<TravelPregameItem>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelSeatplan = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  label: Scalars['String'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Deprecated, seatplan_json should be used instead */
  seatplanConfigurationJson: Scalars['String'];
  seatplanJson: Scalars['JSONString'];
  travelSeatplanId: Scalars['ID'];
  travelVendorType?: Maybe<TravelVendorTypeNode>;
  travelVendorTypeId?: Maybe<Scalars['Int']>;
  trip?: Maybe<Trip>;
  tripId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelSeatplanConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelSeatplanEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TravelSeatplanCopyInput = {
  /** The travel seatplan to copy */
  travelSeatplan?: InputMaybe<Scalars['ID']>;
  /** The trip we're copying the seatplan to */
  trip: Scalars['ID'];
};

export type TravelSeatplanCopyOverrideInput = {
  /** The travel seatplan we wish to copy from */
  copyFromSeatplan: Scalars['ID'];
  /** The travel seatplan we wish to copy into */
  copyToSeatplan: Scalars['ID'];
};

export type TravelSeatplanCopyOverridePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelSeatplan?: Maybe<TravelSeatplan>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelSeatplanCopyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelSeatplan?: Maybe<TravelSeatplan>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelSeatplanCreateInput = {
  label: Scalars['String'];
  travelVendorType: Scalars['ID'];
  trip: Scalars['ID'];
};

export type TravelSeatplanCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelSeatplan?: Maybe<TravelSeatplan>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelSeatplanDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelSeatplan?: Maybe<TravelSeatplan>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TravelSeatplan` and its cursor. */
export type TravelSeatplanEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelSeatplan>;
};

/** An enumeration. */
export enum TravelSeatplanSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SeatplanConfigurationJsonAsc = 'seatplan_configuration_json_asc',
  SeatplanConfigurationJsonDesc = 'seatplan_configuration_json_desc',
  SeatplanJsonAsc = 'seatplan_json_asc',
  SeatplanJsonDesc = 'seatplan_json_desc',
  TravelSeatplanIdAsc = 'travel_seatplan_id_asc',
  TravelSeatplanIdDesc = 'travel_seatplan_id_desc',
  TravelVendorTypeIdAsc = 'travel_vendor_type_id_asc',
  TravelVendorTypeIdDesc = 'travel_vendor_type_id_desc',
  TripIdAsc = 'trip_id_asc',
  TripIdDesc = 'trip_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TravelSeatplanUpdateInput = {
  label?: InputMaybe<Scalars['String']>;
  seatplanJson?: InputMaybe<Scalars['JSONString']>;
};

export type TravelSeatplanUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelSeatplan?: Maybe<TravelSeatplan>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelTaskBaseUpdateInput = {
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  taskName?: InputMaybe<Scalars['String']>;
  taskOrder?: InputMaybe<Scalars['Int']>;
  /** Global ID for the Travel Task you are updating */
  travelTaskId: Scalars['ID'];
};

export type TravelTaskBulkUpdateInput = {
  travelTasks: Array<InputMaybe<TravelTaskBaseUpdateInput>>;
};

export type TravelTaskBulkUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelTaskCopyInput = {
  /** Global ID for Trip you copying tasks from */
  fromTrip?: InputMaybe<Scalars['ID']>;
  /** Global IDs for the specific Travel Tasks you are copying */
  taskList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type TravelTaskCopyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelTaskCreateInput = {
  isCompleted: Scalars['Boolean'];
  taskName: Scalars['String'];
  taskOrder: Scalars['Int'];
  /** Global ID for the Trip you are creating the Travel Task for */
  trip: Scalars['ID'];
};

export type TravelTaskCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelTask?: Maybe<TravelTaskNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelTaskDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelTask?: Maybe<TravelTaskNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelTaskNode = Node & {
  /** competition_id */
  competitionId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** is_completed */
  isCompleted: Scalars['Boolean'];
  /** school_id */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** task_name */
  taskName: Scalars['String'];
  /** task_order */
  taskOrder: Scalars['Int'];
  /** team_id */
  teamId: Scalars['Int'];
  /** travel_task_id */
  travelTaskId: Scalars['ID'];
  trip?: Maybe<Trip>;
  /** trip_id */
  tripId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelTaskNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelTaskNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TravelTaskNode` and its cursor. */
export type TravelTaskNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelTaskNode>;
};

export type TravelTaskUpdateInput = {
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  taskName?: InputMaybe<Scalars['String']>;
  taskOrder?: InputMaybe<Scalars['Int']>;
};

export type TravelTaskUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelTask?: Maybe<TravelTaskNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelVendor = Node & {
  address?: Maybe<AddressNode>;
  /** address_id */
  addressId?: Maybe<Scalars['Int']>;
  contacts?: Maybe<Array<Maybe<TravelContactNode>>>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  groups?: Maybe<Array<Maybe<TravelVendorGroup>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** is_active */
  isActive?: Maybe<Scalars['Boolean']>;
  /** vendor_name */
  name: Scalars['String'];
  notes?: Maybe<Array<Maybe<NoteNode>>>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /** phone_fax (Deprecated, create a contact instead) */
  phoneFax?: Maybe<Scalars['String']>;
  /** phone_main (Deprecated, create a contact instead) */
  phoneMain?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  seatingCharts?: Maybe<Array<Maybe<TravelAirSeatingChart>>>;
  team?: Maybe<TeamNode>;
  /** team_id */
  teamId?: Maybe<Scalars['Int']>;
  teams?: Maybe<Array<Maybe<TeamNode>>>;
  /** travel_vendor_id */
  travelVendorId: Scalars['ID'];
  travelVendorType?: Maybe<TravelVendorTypeNode>;
  /** travel_vendor_type_id */
  travelVendorTypeId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelVendorAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryIso?: InputMaybe<Scalars['String']>;
  customStateProvince?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type TravelVendorCreateInput = {
  address?: InputMaybe<TravelVendorAddress>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  name: Scalars['String'];
  primaryContact?: InputMaybe<TravelContactInput>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  travelVendorType: Scalars['ID'];
};

export type TravelVendorCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelVendor?: Maybe<TravelVendor>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelVendorDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelVendor?: Maybe<TravelVendor>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelVendorGroup = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  /** label */
  label?: Maybe<Scalars['String']>;
  /** travel_vendor_group_id */
  travelVendorGroupId: Scalars['ID'];
  travelVendors?: Maybe<VendorNodeConnection>;
  /** trip_id */
  tripId: Scalars['Int'];
};

export type TravelVendorGroupTravelVendorsArgs = {
  addressId?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  phoneFax?: InputMaybe<Scalars['TWFilter_String']>;
  phoneMain?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TravelVendorSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  travelVendorId?: InputMaybe<Scalars['TWFilter_ID']>;
  travelVendorTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TravelVendorGroupConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelVendorGroupEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TravelVendorGroupCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelVendorGroup?: Maybe<TravelVendorGroup>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelVendorGroupDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelVendorGroup?: Maybe<TravelVendorGroup>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `TravelVendorGroup` and its cursor. */
export type TravelVendorGroupEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelVendorGroup>;
};

export type TravelVendorGroupInput = {
  label?: InputMaybe<Scalars['String']>;
  trip?: InputMaybe<Scalars['ID']>;
};

/** An enumeration. */
export enum TravelVendorGroupSortEnum {
  CompetitionIdAsc = 'competition_id_asc',
  CompetitionIdDesc = 'competition_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  TravelVendorGroupIdAsc = 'travel_vendor_group_id_asc',
  TravelVendorGroupIdDesc = 'travel_vendor_group_id_desc',
  TripIdAsc = 'trip_id_asc',
  TripIdDesc = 'trip_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TravelVendorGroupUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelVendorGroup?: Maybe<TravelVendorGroup>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TravelVendorGroupVendorLinkInput = {
  travelVendor: Scalars['ID'];
  travelVendorGroup: Scalars['ID'];
};

export type TravelVendorGroupVendorLinkNode = {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  travelVendor?: Maybe<TravelVendor>;
  travelVendorGroup?: Maybe<TravelVendorGroup>;
  /** travel_vendor_group_id */
  travelVendorGroupId: Scalars['Int'];
  /** travel_vendor_group_vendor_link_id */
  travelVendorGroupVendorLinkId: Scalars['ID'];
  /** travel_vendor_id */
  travelVendorId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum TravelVendorSortEnum {
  AddressIdAsc = 'address_id_asc',
  AddressIdDesc = 'address_id_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  PhoneFaxAsc = 'phone_fax_asc',
  PhoneFaxDesc = 'phone_fax_desc',
  PhoneMainAsc = 'phone_main_asc',
  PhoneMainDesc = 'phone_main_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TravelVendorIdAsc = 'travel_vendor_id_asc',
  TravelVendorIdDesc = 'travel_vendor_id_desc',
  TravelVendorTypeIdAsc = 'travel_vendor_type_id_asc',
  TravelVendorTypeIdDesc = 'travel_vendor_type_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  VendorNameAsc = 'vendor_name_asc',
  VendorNameDesc = 'vendor_name_desc',
}

export type TravelVendorTypeNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** label */
  label: Scalars['String'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** travel_vendor_type_id */
  travelVendorTypeId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TravelVendorTypeNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TravelVendorTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TravelVendorTypeNode` and its cursor. */
export type TravelVendorTypeNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelVendorTypeNode>;
};

export type TravelVendorUpdateInput = {
  address?: InputMaybe<TravelVendorAddress>;
  name?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  travelVendorType?: InputMaybe<Scalars['ID']>;
};

export type TravelVendorUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  travelVendor?: Maybe<TravelVendor>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type Trip = Node & {
  /** Aggregates end date and end time into one object and returns dateTime */
  endDateTime?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Maybe<TravelEventNode>>>;
  files?: Maybe<Array<Maybe<SharedFileNode>>>;
  hasTicketAllocations?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isInPast?: Maybe<Scalars['Boolean']>;
  /** is_visible_mobile */
  isVisibleMobile: Scalars['Boolean'];
  itineraryCalendarVisibility?: Maybe<TripCalendarVisibilityEnum>;
  /** label */
  label?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  season?: Maybe<SeasonObjectType>;
  seasons?: Maybe<Array<Maybe<SeasonObjectType>>>;
  seatplans?: Maybe<Array<Maybe<TravelSeatplan>>>;
  /** Aggregates start date and start time into one object and returns dateTime */
  startDateTime?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the status of the trip based on the current date
   *
   */
  status?: Maybe<Scalars['String']>;
  tasks?: Maybe<Array<Maybe<TravelTaskNode>>>;
  timeZone?: Maybe<Scalars['String']>;
  travelPartyExclusions?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  travelPartyMembers?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  travelPartyPersons?: Maybe<Array<Maybe<TravelPartyMemberNode>>>;
  travelPartySelections?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  visibility?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
};

export type TripBulkDeleteTravelPartyInput = {
  id?: InputMaybe<Scalars['ID']>;
  removeExclusions?: InputMaybe<Scalars['Boolean']>;
  travelPartyPersons?: InputMaybe<Array<InputMaybe<TravelPartyMemberInput>>>;
};

export type TripBulkDeleteTravelPartyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TripBulkUpdateTravelPartyInput = {
  id?: InputMaybe<Scalars['ID']>;
  travelPartyPersons?: InputMaybe<Array<InputMaybe<TravelPartyMemberInput>>>;
};

export type TripBulkUpdateTravelPartyPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export enum TripCalendarVisibilityEnum {
  AllTravelers = 'all_travelers',
  NoTravelers = 'no_travelers',
  SpecificTravelers = 'specific_travelers',
}

/** Responsible for creating both trips, and associated competitions for a given trip, with one mutation. */
export type TripCompetitionsBulkCreate = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TripCompetitionsBulkCreateField = {
  competitions: Array<InputMaybe<TravelEventInput>>;
  trip: TripCreateInput;
};

export type TripCompetitionsBulkCreateInput = {
  travelParty: ModifyTravelPartyInput;
  tripCompetitionsBulkCreate: Array<InputMaybe<TripCompetitionsBulkCreateField>>;
};

export type TripConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TripEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TripCreateInput = {
  /** Date the trip ends in passed TZ */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Time the trip ends in passed TZ */
  endTime?: InputMaybe<Scalars['Time']>;
  isVisibleMobile?: InputMaybe<Scalars['Boolean']>;
  itineraryCalendarVisibility: TripCalendarVisibilityEnum;
  label: Scalars['String'];
  /** Legacy singular team season that is participating with the trip */
  season: Scalars['ID'];
  /** List of team seasons that are participating with the trip */
  seasons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Date the trip starts in passed TZ */
  startDate: Scalars['Date'];
  /** Time the trip starts in passed TZ */
  startTime: Scalars['Time'];
  /** TimeZone of which to display the trip time in */
  timeZone: Scalars['String'];
  tripPicture?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TripCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TripDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TripDeleteTravelPartyMembersPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** A Relay edge containing a `Trip` and its cursor. */
export type TripEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Trip>;
};

/** An enumeration. */
export enum TripSortEnum {
  BulkCreatedAsc = 'bulk_created_asc',
  BulkCreatedDesc = 'bulk_created_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EndDateAsc = 'end_date_asc',
  EndDateDesc = 'end_date_desc',
  EndTimeAsc = 'end_time_asc',
  EndTimeDesc = 'end_time_desc',
  EndTimeZoneIdAsc = 'end_time_zone_id_asc',
  EndTimeZoneIdDesc = 'end_time_zone_id_desc',
  ExportsLeftMarginAsc = 'exports_left_margin_asc',
  ExportsLeftMarginDesc = 'exports_left_margin_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsVisibleMobileAsc = 'is_visible_mobile_asc',
  IsVisibleMobileDesc = 'is_visible_mobile_desc',
  ItineraryCalendarVisibilityAsc = 'itinerary_calendar_visibility_asc',
  ItineraryCalendarVisibilityDesc = 'itinerary_calendar_visibility_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  LogoFileNameAsc = 'logo_file_name_asc',
  LogoFileNameDesc = 'logo_file_name_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  SeasonIdAsc = 'season_id_asc',
  SeasonIdDesc = 'season_id_desc',
  StartDateAsc = 'start_date_asc',
  StartDateDesc = 'start_date_desc',
  StartTimeAsc = 'start_time_asc',
  StartTimeDesc = 'start_time_desc',
  StartTimeZoneIdAsc = 'start_time_zone_id_asc',
  StartTimeZoneIdDesc = 'start_time_zone_id_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  TravelPartyAsc = 'travel_party_asc',
  TravelPartyDesc = 'travel_party_desc',
  TripIdAsc = 'trip_id_asc',
  TripIdDesc = 'trip_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

/** An enumeration. */
export enum TripStatusModelEnum {
  Completed = 'completed',
  InProgress = 'in_progress',
  Upcoming = 'upcoming',
}

export type TripUpdateInput = {
  /** Date the trip ends in passed TZ */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Time the trip ends in passed TZ */
  endTime?: InputMaybe<Scalars['Time']>;
  isVisibleMobile?: InputMaybe<Scalars['Boolean']>;
  itineraryCalendarVisibility?: InputMaybe<TripCalendarVisibilityEnum>;
  label?: InputMaybe<Scalars['String']>;
  /** Legacy singular team season that is participating with the trip */
  season?: InputMaybe<Scalars['ID']>;
  /** List of team seasons that are participating with the trip */
  seasons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Date the trip starts in passed TZ */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Time the trip starts in passed TZ */
  startTime?: InputMaybe<Scalars['Time']>;
  /** TimeZone of which to display the trip time in */
  timeZone?: InputMaybe<Scalars['String']>;
  tripPicture?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TripUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  trip?: Maybe<Trip>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type TutorAppointmentNode = {
  appointmentCheckin?: Maybe<AppointmentCheckinNode>;
  appointmentComplianceTypeId?: Maybe<Scalars['Int']>;
  appointmentId: Scalars['ID'];
  appointmentOccurrences?: Maybe<AppointmentOccurrenceNodeConnection>;
  appointmentType?: Maybe<AppointmentType>;
  appointmentTypeId?: Maybe<Scalars['Int']>;
  apptDate?: Maybe<Scalars['DateTime']>;
  attachments?: Maybe<Array<Maybe<FileAttachment>>>;
  attendeeCount?: Maybe<Scalars['Int']>;
  attendees?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  bookedSlotAppointments?: Maybe<Array<Maybe<BaseAppointmentNode>>>;
  bookedSlotParent?: Maybe<BaseAppointmentNode>;
  complianceType?: Maybe<Scalars['String']>;
  countableEndHour?: Maybe<Scalars['Int']>;
  countableEndMinute?: Maybe<Scalars['Int']>;
  countableMinutes?: Maybe<Scalars['Int']>;
  countableStartHour?: Maybe<Scalars['Int']>;
  countableStartMinute?: Maybe<Scalars['Int']>;
  countableTypeId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  daysFromBase?: Maybe<Scalars['Int']>;
  enableCheckins?: Maybe<Scalars['Boolean']>;
  enableRsvps?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  /** Appointment's end date as a pendulum object aware to the appointment's timezone */
  endDatetimeLocal?: Maybe<Scalars['String']>;
  /** Appointment's end date as a pendulum object localized but naive */
  endDatetimeNaive?: Maybe<Scalars['String']>;
  /** Appointment's end date as a pendulum object set timezone aware and then set to UTC */
  endDatetimeUtc?: Maybe<Scalars['String']>;
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  endTimeZone?: Maybe<Scalars['String']>;
  externalAppointmentUid?: Maybe<Scalars['String']>;
  externalCalendarAccountId?: Maybe<Scalars['Int']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityName?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['ID']>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
  isAllDay: Scalars['Boolean'];
  isAvailability?: Maybe<Scalars['Boolean']>;
  isBooked?: Maybe<Scalars['Boolean']>;
  isBookedSlot?: Maybe<Scalars['String']>;
  isClass?: Maybe<Scalars['Boolean']>;
  isCountable?: Maybe<Scalars['Boolean']>;
  isExternal?: Maybe<Scalars['Boolean']>;
  isExternalAppointment?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  isSlot?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  /** Metadata JSONB object */
  meta?: Maybe<Scalars['JSONString']>;
  notes?: Maybe<Scalars['String']>;
  occurrenceCalculationEnd?: Maybe<Scalars['DateTime']>;
  occurrences?: Maybe<Array<Maybe<AppointmentOccurrenceNode>>>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  parentAppointmentId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  recurEnd?: Maybe<Scalars['DateTime']>;
  recurRule?: Maybe<Scalars['String']>;
  recurStart?: Maybe<Scalars['DateTime']>;
  recurType?: Maybe<Scalars['Int']>;
  reminders?: Maybe<Array<Maybe<AppointmentReminder>>>;
  rrule?: Maybe<Scalars['String']>;
  selections?: Maybe<Array<Maybe<SelectionNode>>>;
  sendRsvpAlerts?: Maybe<Scalars['String']>;
  signupSlot?: Maybe<SlotNode>;
  slotAppointments?: Maybe<Array<Maybe<TutorAppointmentNode>>>;
  slotParentAppointment?: Maybe<TutorAppointmentNode>;
  /** Parent appointment of a child slot appointment */
  slotParentAppointmentId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  /** Appointment's start date as a pendulum object aware to the appointment's timezone */
  startDatetimeLocal?: Maybe<Scalars['String']>;
  /** Appointment's start date as a pendulum object localized but naive */
  startDatetimeNaive?: Maybe<Scalars['String']>;
  /** Appointment's start date as a pendulum object set timezone aware and then set to UTC */
  startDatetimeUtc?: Maybe<Scalars['String']>;
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  startTimeZone?: Maybe<Scalars['String']>;
  /** Alias for the one true timezone to end all other timezone properties */
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  userMetadata?: Maybe<AppointmentUserMetadataNode>;
  var1?: Maybe<Scalars['Int']>;
  var2?: Maybe<Scalars['String']>;
  var3?: Maybe<Scalars['Int']>;
};

export type TutorAppointmentNodeAppointmentOccurrencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['TWFilter_Int']>;
  appointmentOccurrenceId?: InputMaybe<Scalars['TWFilter_ID']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  endDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  period?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<AppointmentOccurrenceSortEnum>>>;
  startDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TutorConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TutorEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type TutorDetails = {
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  maxNumberOfHours?: InputMaybe<Scalars['Int']>;
};

/** A Relay edge containing a `Tutor` and its cursor. */
export type TutorEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TutorNode>;
};

export type TutorNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** Whether or not a tutor can tutor at risk students */
  canTutorAtRiskStudents?: Maybe<Scalars['Boolean']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  combinedSubjectIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  combinedSubjects?: Maybe<SubjectConnection>;
  /** The courses the tutor is certified for */
  courseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  courses?: Maybe<CourseConnection>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Whether or not a tutor is active */
  isActive: Scalars['Boolean'];
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  lastName: Scalars['String'];
  /** Max number of hours a tutor can tutor in term */
  maximumNumberOfHours: Scalars['String'];
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Teamworks Person record for the tutor's user */
  personId: Scalars['Int'];
  /**
   *
   *         Returns a link to the person's profile picture
   *
   */
  pictureDownloadUrl?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  /** The academic subject ids this tutor is certified for */
  subjectIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  subjects?: Maybe<SubjectConnection>;
  tutorId: Scalars['ID'];
  tutorTerms?: Maybe<TutorTermConnection>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TutorNodeCombinedSubjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  descriptionXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  isGeneral?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  shortCode?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<SubjectSortEnum>>>;
  subjectId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TutorNodeCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  courseId?: InputMaybe<Scalars['TWFilter_ID']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  credits?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  descriptionXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isGeneral?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<CourseSortEnum>>>;
  subjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TutorNodeSubjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  descriptionXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  first?: InputMaybe<Scalars['Int']>;
  isGeneral?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  labelXlt?: InputMaybe<Scalars['TWFilter_JSONString']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  shortCode?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<SubjectSortEnum>>>;
  subjectId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TutorNodeTutorTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<TutorTermSortEnum>>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorId?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorTermId?: InputMaybe<Scalars['TWFilter_ID']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

export type TutorSessionInput = {
  courseId?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  termId?: InputMaybe<Scalars['Int']>;
  tutorPersonId?: InputMaybe<Scalars['Int']>;
  tutorSessionRequestId?: InputMaybe<Scalars['Int']>;
};

export type TutorTermConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TutorTermEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TutorTerm` and its cursor. */
export type TutorTermEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TutorTermNode>;
};

export type TutorTermNode = Node & {
  /** If the row was ever associated with a bulk load, it is considered "managed" by that load source. Therefore if that source is updated, this record will be updated. */
  bulkLoadSrc?: Maybe<Scalars['String']>;
  /** A reference back to the latest bulk_upload_status record to create/update the row. */
  bulkUploadStatusId?: Maybe<Scalars['Int']>;
  /** A checksum of all the importable fields in the table */
  checksum?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The customer's record id for this record, for reconciling */
  customerKey: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Flag for soft delete */
  isDeleted: Scalars['Boolean'];
  /** Whether or not this record is considered primary when resolving diffs */
  isPrimary: Scalars['Boolean'];
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  students?: Maybe<StudentConnection>;
  term?: Maybe<TermNode>;
  termId: Scalars['Int'];
  tutor?: Maybe<TutorNode>;
  tutorId: Scalars['Int'];
  tutorTermId: Scalars['ID'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
};

export type TutorTermNodeStudentsArgs = {
  academicStatus?: InputMaybe<Scalars['TWFilter_AcademicStatus']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bulkLoadSrc?: InputMaybe<Scalars['TWFilter_String']>;
  bulkUploadStatusId?: InputMaybe<Scalars['TWFilter_Int']>;
  checksum?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  cumulativeGpa?: InputMaybe<Scalars['TWFilter_String']>;
  customerKey?: InputMaybe<Scalars['TWFilter_String']>;
  eligibilityYear?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['TWFilter_String']>;
  isAtRisk?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  isPrimary?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['TWFilter_String']>;
  meta?: InputMaybe<Scalars['TWFilter_JSONString']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  pictureDownloadUrl?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  preferredName?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<StudentSortEnum>>>;
  studentId?: InputMaybe<Scalars['TWFilter_ID']>;
  teamIds?: InputMaybe<Scalars['TWFilter_IntArray']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** An enumeration. */
export enum TutorTermSortEnum {
  Ac2TutorTermIdAsc = 'ac2_tutor_term_id_asc',
  Ac2TutorTermIdDesc = 'ac2_tutor_term_id_desc',
  BulkLoadSrcAsc = 'bulk_load_src_asc',
  BulkLoadSrcDesc = 'bulk_load_src_desc',
  BulkUploadStatusIdAsc = 'bulk_upload_status_id_asc',
  BulkUploadStatusIdDesc = 'bulk_upload_status_id_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  CustomerKeyAsc = 'customer_key_asc',
  CustomerKeyDesc = 'customer_key_desc',
  IsDeletedAsc = 'is_deleted_asc',
  IsDeletedDesc = 'is_deleted_desc',
  IsPrimaryAsc = 'is_primary_asc',
  IsPrimaryDesc = 'is_primary_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  TermIdAsc = 'term_id_asc',
  TermIdDesc = 'term_id_desc',
  TutorIdAsc = 'tutor_id_asc',
  TutorIdDesc = 'tutor_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
}

export type TutoringAppointmentConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TutoringAppointmentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TutoringAppointment` and its cursor. */
export type TutoringAppointmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TutorAppointmentNode>;
};

export type TutoringAppointmentRangeNode = {
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type UnreadCountResponse = {
  /** Person Id */
  personId?: Maybe<Scalars['Int']>;
  /** Unread Count */
  unreadCount?: Maybe<Scalars['Int']>;
};

export type UnreadCountResponseConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UnreadCountResponseEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UnreadCountResponse` and its cursor. */
export type UnreadCountResponseEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UnreadCountResponse>;
};

export type UpcomingSessionsFiltersInput = {
  /** List of Advisor pks */
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** List of Course pks */
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** DateTime for when to end filtering appointment occurrence dates */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** Boolean for identifying if any student associated with a session is at risk */
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  /** Wild card search across columns: Tutor Full Name, Student Full Name and Course Label */
  searchSessions?: InputMaybe<Scalars['String']>;
  /** DateTime for when to begin filtering appointment occurrence dates */
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** List of Team pks */
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Term pk */
  termId?: InputMaybe<Scalars['Int']>;
};

export type UpcomingSessionsSchema = {
  appointmentOccId?: Maybe<Scalars['Int']>;
  course?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  studentIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  studentNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  students?: Maybe<Array<Maybe<SessionsStudentSchema>>>;
  termId?: Maybe<Scalars['Int']>;
  tutor?: Maybe<Scalars['String']>;
  tutorId?: Maybe<Scalars['Int']>;
  tutorPersonId?: Maybe<Scalars['Int']>;
};

export type UpdateActivityIsViewed = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type UpdateAssignmentArgsInput = {
  additionalResultsTo?: InputMaybe<Array<InputMaybe<AdditionalResultsVisibilityInput>>>;
  additionalRoleAssignments?: InputMaybe<Array<InputMaybe<SubsequentRoleInput>>>;
  assignmentTimezone?: InputMaybe<Scalars['String']>;
  completedFormReceiptEmail?: InputMaybe<Scalars['String']>;
  confirmRoleChanges?: InputMaybe<Scalars['Boolean']>;
  createLink?: InputMaybe<Scalars['Boolean']>;
  dateAvailable?: InputMaybe<Scalars['DateTime']>;
  deactivationDateTime?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  disableFormFlagging?: InputMaybe<Scalars['Boolean']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  flaggedFormAlerts?: InputMaybe<FlaggingFormInput>;
  folder?: InputMaybe<Scalars['String']>;
  form: Scalars['String'];
  initialRoleAssignment?: InputMaybe<InitialRoleInput>;
  isActive: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  notifyAssignees?: InputMaybe<NotifyAssignees>;
  resultsTo: ResultsVisibilityInput;
  smartabaseFormName?: InputMaybe<Scalars['String']>;
};

export type UpdateCompetitionPregameVisibilitiesPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  competition?: Maybe<CompetitionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type UpdateCompetitionSlideTimeIntervalPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  competition?: Maybe<CompetitionNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type UpdateLoginDataOutput = {
  status?: Maybe<Scalars['String']>;
};

export type UpdatePermissionsInput = {
  permissions?: InputMaybe<Array<InputMaybe<PermissionInput>>>;
};

export type UpdateTeamMembershipInput = {
  personStatusTypeId?: InputMaybe<Scalars['ID']>;
  personTypeIds: Array<InputMaybe<Scalars['ID']>>;
  teamId: Scalars['ID'];
};

export type UpdateUserTypesInput = {
  athleteStatus?: InputMaybe<Scalars['ID']>;
  /** When change is deemed to take effect */
  effectiveDate?: InputMaybe<Scalars['Date']>;
  /** Notes */
  notes?: InputMaybe<Scalars['String']>;
  /** Reason why athlete status changed */
  reason?: InputMaybe<AthleteStatusChangeReason>;
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UploadAttachmentInput = {
  attachment: Scalars['Upload'];
  /** The extension of file name being attached */
  fileNameExt?: InputMaybe<Scalars['String']>;
  /** Indicate attachment type, True if it's picture/image, False otherwise */
  isImage?: InputMaybe<Scalars['Boolean']>;
  /** What the attachment will be attached to. Either 'text' or 'email' */
  messageSendType: Scalars['String'];
};

export type UploadAttachmentOutput = {
  fileUrl?: Maybe<Scalars['String']>;
  serverName?: Maybe<Scalars['String']>;
};

export type UploadProfilePictureInput = {
  fileName?: InputMaybe<Scalars['String']>;
  imageFile: Scalars['Upload'];
};

export type UploadProfilePictureOutput = {
  fileName?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  fileUuid?: Maybe<Scalars['String']>;
};

export type UserAccount = {
  /** The organization the user has an account with. */
  org: OrgNode;
  /** The person associated with the user. */
  person: PersonNode;
};

/** Mutation for user to link their account to another. */
export type UserAccountsMerge = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  success?: Maybe<Scalars['Boolean']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type UserAccountsMergeInput = {
  keepCurrentUserAccount: Scalars['Boolean'];
  password: Scalars['String'];
  username: Scalars['String'];
};

/** Mutation for user to update their own email. */
export type UserEmailUpdate = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  primaryEmail?: Maybe<Scalars['String']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** Represents an error caused by the viewing user, typically field-level validation errors. */
export type UserError = {
  /** An internal error code to use to lookup translations */
  code?: Maybe<Scalars['String']>;
  /** The path to the input field associated for the error. */
  field: Array<Scalars['String']>;
  /** A displayable error message associated with the field */
  message: Scalars['String'];
};

export type UserFileVideoActionCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  userFileVideoAction?: Maybe<UserFileVideoActionNode>;
};

export type UserFileVideoActionInput = {
  actionType?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  sharedFileId?: InputMaybe<Scalars['Int']>;
  uuid?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['Int']>;
};

export type UserFileVideoActionNode = Node & {
  /** The date and time that this action occurred. */
  actionDatetime?: Maybe<Scalars['DateTime']>;
  /** type: download, play, view */
  actionType: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  /** The org the person was on when the action was performed. */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** platform that the object action was performed on (web, mobile) */
  platform?: Maybe<Scalars['String']>;
  sharedFile?: Maybe<SharedFileNode>;
  /** The file this action was performed on if we're dealing with a file. */
  sharedFileId?: Maybe<Scalars['Int']>;
  team?: Maybe<TeamNode>;
  /** The team the person was on when the action was performed. */
  teamId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** user_file_video_action_id */
  userFileVideoActionId: Scalars['ID'];
  video?: Maybe<ExternalVideoNode>;
  /** The video this action was performed on if we're dealing with a video. */
  videoId?: Maybe<Scalars['Int']>;
};

export type UserFileVideoActionNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserFileVideoActionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserFileVideoActionNode` and its cursor. */
export type UserFileVideoActionNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserFileVideoActionNode>;
};

/** An enumeration. */
export enum UserFileVideoActionSortEnum {
  ActionDatetimeAsc = 'action_datetime_asc',
  ActionDatetimeDesc = 'action_datetime_desc',
  ActionTypeAsc = 'action_type_asc',
  ActionTypeDesc = 'action_type_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PlatformAsc = 'platform_asc',
  PlatformDesc = 'platform_desc',
  SharedFileIdAsc = 'shared_file_id_asc',
  SharedFileIdDesc = 'shared_file_id_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  UserFileVideoActionIdAsc = 'user_file_video_action_id_asc',
  UserFileVideoActionIdDesc = 'user_file_video_action_id_desc',
  VideoIdAsc = 'video_id_asc',
  VideoIdDesc = 'video_id_desc',
}

export type UserFileVideoConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserFileVideoEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UserFileVideo` and its cursor. */
export type UserFileVideoEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserFileVideoActionNode>;
};

export type UserFormStatsNode = {
  allActiveForms: Scalars['Int'];
  formsCreatedRecently: Scalars['Int'];
  formsExpiringSoon: Scalars['Int'];
  overdueForms: Scalars['Int'];
};

export type UserHistoryChangelogConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserHistoryChangelogEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UserHistoryChangelog` and its cursor. */
export type UserHistoryChangelogEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserHistoryChangelogNode>;
};

export type UserHistoryChangelogNode = Node & {
  actionDate: Scalars['DateTime'];
  /** Returns person who performed action */
  actor?: Maybe<PersonNode>;
  actorId?: Maybe<Scalars['Int']>;
  changeType: ChangeType;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  extra?: Maybe<Scalars['JSONString']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  newValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Readable label for reason */
  reason?: Maybe<Scalars['String']>;
  /** Roster label if change_type is ADD_TO_ROSTER or REMOVE_FROM_ROSTER */
  rosterLabel?: Maybe<Scalars['String']>;
  sourcePk?: Maybe<Scalars['String']>;
  sourceTable?: Maybe<Scalars['String']>;
  /** Team name */
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['Int']>;
  /** TMP Year label used by org */
  tmpYear?: Maybe<TmpYearNode>;
  tmpYearId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  userHistoryChangelogId: Scalars['String'];
};

/** An enumeration. */
export enum UserHistoryChangelogSortEnum {
  ActionDateAsc = 'action_date_asc',
  ActionDateDesc = 'action_date_desc',
  ActorIdAsc = 'actor_id_asc',
  ActorIdDesc = 'actor_id_desc',
  ChangeTypeAsc = 'change_type_asc',
  ChangeTypeDesc = 'change_type_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  EffectiveDateAsc = 'effective_date_asc',
  EffectiveDateDesc = 'effective_date_desc',
  ExtraAsc = 'extra_asc',
  ExtraDesc = 'extra_desc',
  NewValueAsc = 'new_value_asc',
  NewValueDesc = 'new_value_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  OldValueAsc = 'old_value_asc',
  OldValueDesc = 'old_value_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  ReasonAsc = 'reason_asc',
  ReasonDesc = 'reason_desc',
  SourcePkAsc = 'source_pk_asc',
  SourcePkDesc = 'source_pk_desc',
  SourceTableAsc = 'source_table_asc',
  SourceTableDesc = 'source_table_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  TmpYearIdAsc = 'tmp_year_id_asc',
  TmpYearIdDesc = 'tmp_year_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  UserHistoryChangelogIdAsc = 'user_history_changelog_id_asc',
  UserHistoryChangelogIdDesc = 'user_history_changelog_id_desc',
}

/** Mutation for user to update their own password. */
export type UserPasswordUpdate = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** GraphQL node for user preferences, actually tied to user.id */
export type UserPreferencesNode = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  uses24HourTime?: Maybe<Scalars['Boolean']>;
};

/** Registers a new user and returns access and refresh tokens to the frontend */
export type UserRegister = {
  accessToken?: Maybe<Scalars['String']>;
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  expiresAt?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type UserRegisterInput = {
  /** When not set, assume the user is linking to an existing account. */
  confirmPassword?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  person: Scalars['ID'];
  username: Scalars['String'];
};

/** GraphQL node for 'user settings'. These are actually tied to the person.id not the user */
export type UserSettingsNode = {
  calendarAlertDefault?: Maybe<Scalars['String']>;
};

/** Mutation for user to update their own username. */
export type UserUsernameUpdate = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  username?: Maybe<Scalars['String']>;
};

export type UsernameInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

/** Sends a username reminder to the given email address or phone number */
export type UsernameReminderSend = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  ok?: Maybe<Scalars['Boolean']>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

/** An enumeration. */
export enum Uses24HourTime {
  Default = 'DEFAULT',
  Twelve = 'TWELVE',
  TwentyFour = 'TWENTY_FOUR',
}

export type ValidatedCodesOutput = {
  invalidCodes?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  validCodes?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
};

export type VendorConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Vendor` and its cursor. */
export type VendorEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelVendor>;
};

export type VendorNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VendorNode` and its cursor. */
export type VendorNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TravelVendor>;
};

export type VersionInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
};

export type VideoConnection = Node & {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoEdge>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type VideoCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  video?: Maybe<VideoNode>;
};

export type VideoDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  video?: Maybe<VideoNode>;
};

/** A Relay edge containing a `Video` and its cursor. */
export type VideoEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VideoNode>;
};

export type VideoIncrementDownloadCountPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  video?: Maybe<VideoNode>;
};

export type VideoIncrementPlayCountPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  video?: Maybe<VideoNode>;
};

export type VideoInput = {
  canDownload?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  enableSharedUrl?: InputMaybe<Scalars['Boolean']>;
  folderId?: InputMaybe<Scalars['Int']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  playCount?: InputMaybe<Scalars['Int']>;
  sendVideoAlert?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wistiaOriginalFileUrl?: InputMaybe<Scalars['String']>;
  wistiaProjectUuid?: InputMaybe<Scalars['String']>;
  wistiaThumbnailUrl?: InputMaybe<Scalars['String']>;
  wistiaVideoUrl?: InputMaybe<Scalars['String']>;
  wistiaVideoUuid?: InputMaybe<Scalars['String']>;
};

/** Exposes Video objects */
export type VideoNode = Node & {
  /** Deprecated. Only used in video.reactivate task */
  Discriminator?: Maybe<Scalars['String']>;
  activeShareLink?: Maybe<Scalars['String']>;
  canDownload?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  description?: Maybe<Scalars['String']>;
  downloadCount?: Maybe<Scalars['Int']>;
  embedHtml?: Maybe<Scalars['String']>;
  folder?: Maybe<FolderNode>;
  folderId?: Maybe<Scalars['Int']>;
  hasActiveShareLink?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  officiatingEvaluationId?: Maybe<Scalars['Int']>;
  org?: Maybe<Scalars['String']>;
  /** The Teamworks Org Id that this is associated with */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  playCount?: Maybe<Scalars['Int']>;
  sharedLink?: Maybe<ExternalAccessLinkNodeConnection>;
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  videoId: Scalars['ID'];
  visibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  vzaarVideoId?: Maybe<Scalars['Int']>;
  wistiaOriginalFileUrl?: Maybe<Scalars['String']>;
  wistiaProjectUuid?: Maybe<Scalars['String']>;
  wistiaThumbnailUrl?: Maybe<Scalars['String']>;
  wistiaVideoUrl?: Maybe<Scalars['String']>;
  wistiaVideoUuid?: Maybe<Scalars['String']>;
};

/** Exposes Video objects */
export type VideoNodeSharedLinkArgs = {
  action?: InputMaybe<Scalars['TWFilter_String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  expirationDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  externalAccessLinkId?: InputMaybe<Scalars['TWFilter_ID']>;
  first?: InputMaybe<Scalars['Int']>;
  isExpired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  page?: InputMaybe<Scalars['TWFilter_String']>;
  params?: InputMaybe<Scalars['TWFilter_String']>;
  paramsJson?: InputMaybe<Scalars['TWFilter_JSONString']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<ExternalAccessLinkSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  uuid?: InputMaybe<Scalars['TWFilter_String']>;
  videoId?: InputMaybe<Scalars['TWFilter_Int']>;
};

/** An enumeration. */
export enum VideoSortEnum {
  CanDownloadAsc = 'can_download_asc',
  CanDownloadDesc = 'can_download_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  DiscriminatorAsc = 'discriminator_asc',
  DiscriminatorDesc = 'discriminator_desc',
  DownloadCountAsc = 'download_count_asc',
  DownloadCountDesc = 'download_count_desc',
  EmbedHtmlAsc = 'embed_html_asc',
  EmbedHtmlDesc = 'embed_html_desc',
  FolderIdAsc = 'folder_id_asc',
  FolderIdDesc = 'folder_id_desc',
  IsPinnedAsc = 'is_pinned_asc',
  IsPinnedDesc = 'is_pinned_desc',
  OfficiatingEvaluationIdAsc = 'officiating_evaluation_id_asc',
  OfficiatingEvaluationIdDesc = 'officiating_evaluation_id_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PlayCountAsc = 'play_count_asc',
  PlayCountDesc = 'play_count_desc',
  SportSchoolIdAsc = 'sport_school_id_asc',
  SportSchoolIdDesc = 'sport_school_id_desc',
  TitleAsc = 'title_asc',
  TitleDesc = 'title_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  VideoIdAsc = 'video_id_asc',
  VideoIdDesc = 'video_id_desc',
  VzaarVideoIdAsc = 'vzaar_video_id_asc',
  VzaarVideoIdDesc = 'vzaar_video_id_desc',
  WistiaOriginalFileUrlAsc = 'wistia_original_file_url_asc',
  WistiaOriginalFileUrlDesc = 'wistia_original_file_url_desc',
  WistiaProjectUuidAsc = 'wistia_project_uuid_asc',
  WistiaProjectUuidDesc = 'wistia_project_uuid_desc',
  WistiaThumbnailUrlAsc = 'wistia_thumbnail_url_asc',
  WistiaThumbnailUrlDesc = 'wistia_thumbnail_url_desc',
  WistiaVideoUrlAsc = 'wistia_video_url_asc',
  WistiaVideoUrlDesc = 'wistia_video_url_desc',
  WistiaVideoUuidAsc = 'wistia_video_uuid_asc',
  WistiaVideoUuidDesc = 'wistia_video_uuid_desc',
}

export type VideoUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  video?: Maybe<VideoNode>;
};

/** The main GraphQL node containing information about the currently logged in user */
export type Viewer = {
  allowedAppointmentTypes?: Maybe<Array<Maybe<AllowedAppointmentType>>>;
  availableTabs: Array<NavigationTab>;
  availableTeams: Array<TeamNode>;
  caraInfo?: Maybe<CaraInfo>;
  currentMembership?: Maybe<TeamMemberNode>;
  currentOrg: OrgNode;
  currentTeam: TeamNode;
  enabledApps?: Maybe<Array<Maybe<Application>>>;
  externalModules?: Maybe<Array<Maybe<ExternalModules>>>;
  hasMultipleAccounts: Scalars['Boolean'];
  /** @deprecated Check for permissions instead of roles. */
  isOrgSuperuser?: Maybe<Scalars['Boolean']>;
  /** @deprecated Check for permissions instead of roles. */
  isTeamSuperuser?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use Viewer.currentOrg.id instead. */
  orgId?: Maybe<Scalars['Int']>;
  permissions: ViewerPermissionsNode;
  /** The viewing person. */
  person: PersonNode;
  /** @deprecated Use Viewer.person.id instead. */
  personId?: Maybe<Scalars['Int']>;
  personInfo?: Maybe<PersonInfo>;
  sharedNavbar?: Maybe<SharedNavbar>;
  streamTokenResponse?: Maybe<TokenResponse>;
  /** @deprecated Use Viewer.currentTeam.id instead. */
  teamId?: Maybe<Scalars['Int']>;
  /** Teams that the current user is a team superuser for. */
  teamSuperuserTeams?: Maybe<Array<Maybe<TeamNode>>>;
  travelEventTypes?: Maybe<Array<Maybe<ViewerTravelEventTypeNode>>>;
  travelSettings?: Maybe<Array<Maybe<ModuleSettings>>>;
  userPreferences?: Maybe<UserPreferencesNode>;
  userSettings?: Maybe<UserSettingsNode>;
  username?: Maybe<Scalars['String']>;
  visibleUserTypes?: Maybe<Array<Maybe<PersonTypeNode>>>;
};

/** Permissions associated with the viewer */
export type ViewerPermissionsNode = {
  academics2ModuleEnabled?: Maybe<Scalars['Boolean']>;
  calendarModuleEnabled?: Maybe<Scalars['Boolean']>;
  canAddAvailability?: Maybe<Scalars['Boolean']>;
  canAddComplianceApptType?: Maybe<Scalars['Boolean']>;
  canAddOtherUserAppointments?: Maybe<Scalars['Boolean']>;
  canAddTravelApptType?: Maybe<Scalars['Boolean']>;
  canAddTravelContacts?: Maybe<Scalars['Boolean']>;
  canAdminCalendar?: Maybe<Scalars['Boolean']>;
  canAdminLoginAccess?: Maybe<Scalars['Boolean']>;
  canAdminTicketing?: Maybe<Scalars['Boolean']>;
  canAdminTmp?: Maybe<Scalars['Boolean']>;
  canAssignForms?: Maybe<Scalars['Boolean']>;
  canCopyAppointments?: Maybe<Scalars['Boolean']>;
  canDeactivateUsers?: Maybe<Scalars['Boolean']>;
  canEditCaraTeamDeclarations?: Maybe<Scalars['Boolean']>;
  canFindTutors?: Maybe<Scalars['Boolean']>;
  canManageAllTeamsAccess?: Maybe<Scalars['Boolean']>;
  canManageAppAssignments?: Maybe<Scalars['Boolean']>;
  canManageDigitalDisplays?: Maybe<Scalars['Boolean']>;
  canManageForms?: Maybe<Scalars['Boolean']>;
  canManageNews?: Maybe<Scalars['Boolean']>;
  canManagePermissions?: Maybe<Scalars['Boolean']>;
  canManageQuickForms?: Maybe<Scalars['Boolean']>;
  canManageTasks?: Maybe<Scalars['Boolean']>;
  canManageTravel?: Maybe<Scalars['Boolean']>;
  canManageWeblinks?: Maybe<Scalars['Boolean']>;
  canModifyAthleteNotes?: Maybe<Scalars['Boolean']>;
  canModifyAthleteProfiles?: Maybe<Scalars['Boolean']>;
  canModifyStaffProfiles?: Maybe<Scalars['Boolean']>;
  canModifyUsers?: Maybe<Scalars['Boolean']>;
  canSendMessages?: Maybe<Scalars['Boolean']>;
  canSendMessagesToContacts?: Maybe<Scalars['Boolean']>;
  canSendTravelLetter?: Maybe<Scalars['Boolean']>;
  canSubmitForms?: Maybe<Scalars['Boolean']>;
  canUseFirestoreConversations?: Maybe<Scalars['Boolean']>;
  canViewAcademicDetails?: Maybe<Scalars['Boolean']>;
  canViewAcademics2?: Maybe<Scalars['Boolean']>;
  canViewAddressesDetails?: Maybe<Scalars['Boolean']>;
  canViewAthleteDetails?: Maybe<Scalars['Boolean']>;
  canViewAthleteList?: Maybe<Scalars['Boolean']>;
  canViewAthleteNotes?: Maybe<Scalars['Boolean']>;
  canViewCalendarActivityLogs?: Maybe<Scalars['Boolean']>;
  canViewCaraLogs?: Maybe<Scalars['Boolean']>;
  canViewContactDetails?: Maybe<Scalars['Boolean']>;
  canViewConversations?: Maybe<Scalars['Boolean']>;
  canViewMessageHistory?: Maybe<Scalars['Boolean']>;
  canViewNews?: Maybe<Scalars['Boolean']>;
  canViewOtherTrips?: Maybe<Scalars['Boolean']>;
  canViewOtherUserAppointments?: Maybe<Scalars['Boolean']>;
  canViewOthersMessageHistory?: Maybe<Scalars['Boolean']>;
  canViewPersonalDetails?: Maybe<Scalars['Boolean']>;
  canViewStaffContactDetails?: Maybe<Scalars['Boolean']>;
  canViewStaffProfile?: Maybe<Scalars['Boolean']>;
  canViewTicketing?: Maybe<Scalars['Boolean']>;
  canViewTmp?: Maybe<Scalars['Boolean']>;
  canViewTravel?: Maybe<Scalars['Boolean']>;
  canViewVideo?: Maybe<Scalars['Boolean']>;
  canViewWeblinks?: Maybe<Scalars['Boolean']>;
  disableMessageEditDelete?: Maybe<Scalars['Boolean']>;
  enableGuardianshipFeatures?: Maybe<Scalars['Boolean']>;
  isAcademicSuperuser?: Maybe<Scalars['Boolean']>;
  isLegacyApiUser?: Maybe<Scalars['Boolean']>;
  messagingModuleEnabled?: Maybe<Scalars['Boolean']>;
  /** List of teams user can add files */
  teamsCanAddFiles?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** List of teams user can manage file folders */
  teamsCanAdminFileFolders?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** List of teams user can admin videos */
  teamsCanAdminVideos?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** List of teams user can manage academics on */
  teamsCanManageAcademics?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** List of teams user can manage travel on */
  teamsCanManageTravel?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** List of teams user can view academic classes */
  teamsCanViewAcademicsClass?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tmpModuleEnabled?: Maybe<Scalars['Boolean']>;
  travelModuleEnabled?: Maybe<Scalars['Boolean']>;
  /** List of attribute category labels user can view */
  visibleAttributeCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * This is a duplicate of TravelEventTypeNode that doesn't have dependencies so as to avoid
 * circular import problems. "Viewer" code should really be imported after the modules if it is going
 * to reference them, or be moved somewhere else that is imported after the modules.
 */
export type ViewerTravelEventTypeNode = {
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type VisibilityGroup = {
  completersToView?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
  viewResultsInEmail?: Maybe<Array<Maybe<Scalars['String']>>>;
  viewResultsInTeamworks?: Maybe<
    Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>
  >;
};

export type WebLink = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isMobileLink?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  linkTransformationType?: Maybe<Scalars['String']>;
  mobileLinkCode?: Maybe<Scalars['String']>;
  mobilePlatform?: Maybe<Scalars['String']>;
  officiatingSchoolId?: Maybe<Scalars['Int']>;
  org?: Maybe<OrgNode>;
  orgId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  webLinkId: Scalars['ID'];
};

export type WebLinkConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WebLinkEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type WebLinkCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  webLink?: Maybe<WebLink>;
};

export type WebLinkDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  webLink?: Maybe<WebLink>;
};

/** A Relay edge containing a `WebLink` and its cursor. */
export type WebLinkEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<WebLink>;
};

export type WebLinkInput = {
  label?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum WebLinkSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsMobileLinkAsc = 'is_mobile_link_asc',
  IsMobileLinkDesc = 'is_mobile_link_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  LinkTransformationTypeAsc = 'link_transformation_type_asc',
  LinkTransformationTypeDesc = 'link_transformation_type_desc',
  MobileLinkCodeAsc = 'mobile_link_code_asc',
  MobileLinkCodeDesc = 'mobile_link_code_desc',
  MobilePlatformAsc = 'mobile_platform_asc',
  MobilePlatformDesc = 'mobile_platform_desc',
  OfficiatingSchoolIdAsc = 'officiating_school_id_asc',
  OfficiatingSchoolIdDesc = 'officiating_school_id_desc',
  SchoolIdAsc = 'school_id_asc',
  SchoolIdDesc = 'school_id_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  UrlAsc = 'url_asc',
  UrlDesc = 'url_desc',
  WebLinkIdAsc = 'web_link_id_asc',
  WebLinkIdDesc = 'web_link_id_desc',
}

export type WebLinkUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  webLink?: Maybe<WebLink>;
};

export enum WidgetTypeEnum {
  Announcement = 'announcement',
}

export type YearlyAttributeCategoryConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<YearlyAttributeCategoryEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type YearlyAttributeCategoryCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  yearlyAttributeCategory?: Maybe<YearlyAttributeCategoryNode>;
};

export type YearlyAttributeCategoryDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  yearlyAttributeCategory?: Maybe<YearlyAttributeCategoryNode>;
};

/** A Relay edge containing a `YearlyAttributeCategory` and its cursor. */
export type YearlyAttributeCategoryEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<YearlyAttributeCategoryNode>;
};

/** Defines variables supported when creating or updating a yearly attribute category */
export type YearlyAttributeCategoryInput = {
  /** Selection codes of persons and persontypes who have edit permissions of other persons' yearly attribute in this category */
  editabilityCodes: Array<InputMaybe<Scalars['String']>>;
  /** Assigns category to a team_id if set to True, otherwise null */
  isTeamLevel?: InputMaybe<Scalars['Boolean']>;
  /** At least 2, max 100-character name for yearly attribute category. No duplicates allowed across entire org */
  label: Scalars['String'];
  /** Selection codes of persons and persontypes who have view permissions of other persons' yearly attribute values under this category */
  visibilityCodes: Array<InputMaybe<Scalars['String']>>;
};

/** Defines the fields that can be included in yearlyAttributeCategory selection in GraphQL schema */
export type YearlyAttributeCategoryNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  editability?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCustom: Scalars['Boolean'];
  label: Scalars['String'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personType?: Maybe<PersonTypeNode>;
  personTypeId?: Maybe<Scalars['Int']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  yearlyAttributeCategoryId: Scalars['ID'];
  /** The list of all yearly attributes for the category. */
  yearlyAttributes?: Maybe<Array<Maybe<YearlyAttributeNode>>>;
  /** The list of active yearly attributes for the category. */
  yearlyAttributesActive?: Maybe<Array<Maybe<YearlyAttributeNode>>>;
};

/** Defines variables supported when re-ordering yearly attribute categories */
export type YearlyAttributeCategoryOrderInput = {
  /** Number 0 - x determining the sort order */
  sortOrder: Scalars['Int'];
  /** Global ID for the Yearly Attribute Category to update */
  yearlyAttributeCategoryId: Scalars['ID'];
};

/** Defines list of yearly attributes categories being re-ordered */
export type YearlyAttributeCategoryReorderInput = {
  yearlyAttributeCategories: Array<InputMaybe<YearlyAttributeCategoryOrderInput>>;
};

export type YearlyAttributeCategoryReorderPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  yearlyAttributeCategory?: Maybe<YearlyAttributeCategoryNode>;
};

/** An enumeration. */
export enum YearlyAttributeCategorySortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  IsActiveAsc = 'is_active_asc',
  IsActiveDesc = 'is_active_desc',
  IsCustomAsc = 'is_custom_asc',
  IsCustomDesc = 'is_custom_desc',
  LabelAsc = 'label_asc',
  LabelDesc = 'label_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonTypeIdAsc = 'person_type_id_asc',
  PersonTypeIdDesc = 'person_type_id_desc',
  SortOrderAsc = 'sort_order_asc',
  SortOrderDesc = 'sort_order_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  YearlyAttributeCategoryIdAsc = 'yearly_attribute_category_id_asc',
  YearlyAttributeCategoryIdDesc = 'yearly_attribute_category_id_desc',
}

export type YearlyAttributeCategoryUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  yearlyAttributeCategory?: Maybe<YearlyAttributeCategoryNode>;
};

/**
 * Values that are stored in YearlyAttributeValue model
 * TMP Year appears as a shared input arg in bulk update
 */
export type YearlyAttributeInput = {
  value?: InputMaybe<Scalars['String']>;
  yearlyAttribute: Scalars['ID'];
};

/** Defines the fields that can be included in yearlyAttribute selection in GraphQL schema */
export type YearlyAttributeNode = Node & {
  DataType?: Maybe<ProfileAttributeDatatypeObjectNode>;
  appliesToUserTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attributeDataTypeId?: Maybe<Scalars['Int']>;
  attributeReminders?: Maybe<Array<Maybe<YearlyAttributeReminderNode>>>;
  canEdit: Scalars['Boolean'];
  category?: Maybe<YearlyAttributeCategoryNode>;
  coreCode?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  dataType?: Maybe<Scalars['String']>;
  enumOptions?: Maybe<Scalars['String']>;
  hasUserTypeRestriction: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCustom?: Maybe<Scalars['Boolean']>;
  isSelfManageAvailable: Scalars['Boolean'];
  isTeamSpecific: Scalars['Boolean'];
  label: Scalars['String'];
  optionsList?: Maybe<Array<Maybe<Scalars['String']>>>;
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  personTypes?: Maybe<PersonTypeNodeConnection>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  rolloverType: RolloverType;
  selfManageUsers?: Maybe<Scalars['String']>;
  selfManagedUserTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sortOrder: Scalars['Int'];
  team?: Maybe<TeamNode>;
  teamId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueLabel?: Maybe<Scalars['String']>;
  yearlyAttributeCategoryId?: Maybe<Scalars['Int']>;
  yearlyAttributeId: Scalars['ID'];
  yearlyAttributeReminders?: Maybe<YearlyAttributeReminderNodeConnection>;
};

/** Defines the fields that can be included in yearlyAttribute selection in GraphQL schema */
export type YearlyAttributeNodePersonTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['TWFilter_String']>;
  namePlural?: InputMaybe<Scalars['TWFilter_String']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  personTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<PersonTypeSortEnum>>>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
};

/** Defines the fields that can be included in yearlyAttribute selection in GraphQL schema */
export type YearlyAttributeNodeYearlyAttributeRemindersArgs = {
  ReminderOffset?: InputMaybe<Scalars['TWFilter_JSONString']>;
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  owner?: InputMaybe<Scalars['TWFilter_String']>;
  ownerId?: InputMaybe<Scalars['TWFilter_String']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  reminderType?: InputMaybe<Scalars['TWFilter_String']>;
  sort?: InputMaybe<Array<InputMaybe<YearlyAttributeReminderSortEnum>>>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedBy?: InputMaybe<Scalars['TWFilter_Int']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  yearlyAttributeId?: InputMaybe<Scalars['TWFilter_Int']>;
  yearlyAttributeReminderId?: InputMaybe<Scalars['TWFilter_ID']>;
};

/** Defines the fields that can be included in yearlyAttributeReminder selection in GraphQL schema */
export type YearlyAttributeReminderNode = Node & {
  ReminderOffset?: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  reminderOffset?: Maybe<ReminderOffsetNode>;
  reminderType?: Maybe<AttributeReminderType>;
  team?: Maybe<TeamNode>;
  teamId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<EligibilityYear | PersonNode | SelectionGroup | SportPosition>>>;
  yearlyAttribute?: Maybe<YearlyAttributeNode>;
  yearlyAttributeId: Scalars['Int'];
  yearlyAttributeReminderId: Scalars['ID'];
};

export type YearlyAttributeReminderNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<YearlyAttributeReminderNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `YearlyAttributeReminderNode` and its cursor. */
export type YearlyAttributeReminderNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<YearlyAttributeReminderNode>;
};

/** An enumeration. */
export enum YearlyAttributeReminderSortEnum {
  ActiveAsc = 'active_asc',
  ActiveDesc = 'active_desc',
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  ReminderOffsetAsc = 'reminder_offset_asc',
  ReminderOffsetDesc = 'reminder_offset_desc',
  ReminderTypeAsc = 'reminder_type_asc',
  ReminderTypeDesc = 'reminder_type_desc',
  TeamIdAsc = 'team_id_asc',
  TeamIdDesc = 'team_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  YearlyAttributeIdAsc = 'yearly_attribute_id_asc',
  YearlyAttributeIdDesc = 'yearly_attribute_id_desc',
  YearlyAttributeReminderIdAsc = 'yearly_attribute_reminder_id_asc',
  YearlyAttributeReminderIdDesc = 'yearly_attribute_reminder_id_desc',
}

export type YearlyAttributeReportConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<YearlyAttributeReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Defines the inputs in the mutation payload */
export type YearlyAttributeReportCreateInput = {
  /** ID of the person whose yearly attribute values are being exported */
  personBeingViewed: Scalars['ID'];
  /** ID of the team where person belongs to */
  teamBeingViewed: Scalars['ID'];
  /** ID corresponding to the TMP Year of the values we need */
  tmpYearId: Scalars['ID'];
};

export type YearlyAttributeReportCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  yearlyAttributeReport?: Maybe<YearlyAttributeReportNode>;
};

/** A Relay edge containing a `YearlyAttributeReport` and its cursor. */
export type YearlyAttributeReportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<YearlyAttributeReportNode>;
};

export type YearlyAttributeReportNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  downloadableFileId: Scalars['ID'];
  errorMessage?: Maybe<Scalars['String']>;
  fileNameServer?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** What kind of downloaded file this is, e.g. 'report'. */
  kind: Scalars['String'];
  /** Forward-compatible metadata column. */
  meta?: Maybe<Scalars['JSONString']>;
  org?: Maybe<OrgNode>;
  /** The Teamworks Org Id that this is associated with this */
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  /** The Teamworks Person Id that this is associated with this */
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  /** Status of the file, i.e. one of `&.STATUS_*`. */
  status: Scalars['String'];
  team?: Maybe<TeamNode>;
  /** The Teamworks Team Id that this is associated with this */
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  /** Cached copy of the public URL. */
  url?: Maybe<Scalars['String']>;
  yearlyAttributeReportId: Scalars['ID'];
};

export type YearlyAttributeSettingCreatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  yearlyAttribute?: Maybe<YearlyAttributeNode>;
};

export type YearlyAttributeSettingDeletePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  yearlyAttribute?: Maybe<YearlyAttributeNode>;
};

/** Defines variables supported when creating or updating a yearly attribute */
export type YearlyAttributeSettingInput = {
  /** ID for yearly attribute's data type */
  attributeDataType?: InputMaybe<Scalars['ID']>;
  attributeReminders?: InputMaybe<Array<InputMaybe<AttributeReminderInput>>>;
  /** Array of strings of attribute options if type is list */
  enumOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** True if any restriction of who this applies to */
  hasUserTypeRestriction?: InputMaybe<Scalars['Boolean']>;
  /** At least 2, max 100-character name for yearly attribute. No duplicates allowed per category */
  label?: InputMaybe<Scalars['String']>;
  /** If hasUsertypeRestriction, then must pass user type IDs */
  restrictedUserTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  rolloverType?: InputMaybe<RolloverType>;
  /** If selfManageUsers is 'custom', then set user type IDs */
  selfManageUserTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Must be 'all', 'custom', or 'none'. Determines who can manage attribute */
  selfManageUsers?: InputMaybe<Scalars['String']>;
  /** Number 1 - x determining the sort order. On create, defaults to last of attributes */
  sortOrder?: InputMaybe<Scalars['Int']>;
  /** ID for yearly attribute's parent category */
  yearlyAttributeCategory?: InputMaybe<Scalars['ID']>;
};

/** Defines variables supported when re-ordering yearly attributes in a category */
export type YearlyAttributeSettingOrderInput = {
  /** Number 1 - x determining the sort order */
  sortOrder: Scalars['Int'];
  /** Global ID for the Yearly Attribute to update */
  yearlyAttributeId: Scalars['ID'];
};

/** Defines list of yearly attributes being re-ordered in a category */
export type YearlyAttributeSettingReorderInput = {
  yearlyAttributes: Array<InputMaybe<YearlyAttributeSettingOrderInput>>;
};

export type YearlyAttributeSettingReorderPayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  yearlyAttributeCategory?: Maybe<YearlyAttributeCategoryNode>;
};

export type YearlyAttributeSettingUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
  yearlyAttribute?: Maybe<YearlyAttributeNode>;
};

export type YearlyAttributeUserUpdatePayload = {
  /** List of bulk input errors that occurred during mutation. */
  bulkUserErrors: Array<BulkUserError>;
  person?: Maybe<PersonNode>;
  /** List of input errors that occurred during mutation. */
  userErrors: Array<UserError>;
};

export type YearlyAttributeValueConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<YearlyAttributeValueEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total result count */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `YearlyAttributeValue` and its cursor. */
export type YearlyAttributeValueEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<YearlyAttributeValueNode>;
};

export type YearlyAttributeValueNode = Node & {
  createdBy?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  createdDateTime?: Maybe<Scalars['String']>;
  creator?: Maybe<PersonNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  org?: Maybe<OrgNode>;
  orgId: Scalars['Int'];
  /**
   *
   *         Returns the owner for the purpose of determining editing permissions.
   *         Override this if it should be something other than creator.
   *
   */
  owner?: Maybe<Scalars['String']>;
  /**
   *
   *         Returns the person_id of the owner, for the purpose of determining permissions.
   *         Override this property if it should be something other than the creator's person_id (created_by)
   *
   */
  ownerId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonNode>;
  personId: Scalars['Int'];
  /**
   *
   *         Can be used in queries to match on primary key of this model. Can also be used on the object to retrieve
   *         the primary key value
   *
   */
  pk?: Maybe<Scalars['String']>;
  tmpYear?: Maybe<TmpYearNode>;
  tmpYearId: Scalars['Int'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  /** Turn legacy database date time into UTC date time object */
  updatedDateTime?: Maybe<Scalars['String']>;
  valueDate?: Maybe<Scalars['String']>;
  valueNumeric?: Maybe<Scalars['Float']>;
  valueText?: Maybe<Scalars['String']>;
  yearlyAttribute?: Maybe<YearlyAttributeNode>;
  yearlyAttributeId: Scalars['Int'];
  yearlyAttributeValueId: Scalars['ID'];
};

/** An enumeration. */
export enum YearlyAttributeValueSortEnum {
  CreatedByAsc = 'created_by_asc',
  CreatedByDesc = 'created_by_desc',
  CreatedDateAsc = 'created_date_asc',
  CreatedDateDesc = 'created_date_desc',
  OrgIdAsc = 'org_id_asc',
  OrgIdDesc = 'org_id_desc',
  PersonIdAsc = 'person_id_asc',
  PersonIdDesc = 'person_id_desc',
  TmpYearIdAsc = 'tmp_year_id_asc',
  TmpYearIdDesc = 'tmp_year_id_desc',
  UpdatedByAsc = 'updated_by_asc',
  UpdatedByDesc = 'updated_by_desc',
  UpdatedDateAsc = 'updated_date_asc',
  UpdatedDateDesc = 'updated_date_desc',
  ValueDateAsc = 'value_date_asc',
  ValueDateDesc = 'value_date_desc',
  ValueNumericAsc = 'value_numeric_asc',
  ValueNumericDesc = 'value_numeric_desc',
  ValueTextAsc = 'value_text_asc',
  ValueTextDesc = 'value_text_desc',
  YearlyAttributeIdAsc = 'yearly_attribute_id_asc',
  YearlyAttributeIdDesc = 'yearly_attribute_id_desc',
  YearlyAttributeValueIdAsc = 'yearly_attribute_value_id_asc',
  YearlyAttributeValueIdDesc = 'yearly_attribute_value_id_desc',
}

/** An enumeration. */
export enum Appointmentrsvpstatusenum {
  Awaiting = 'AWAITING',
  Maybe = 'MAYBE',
  No = 'NO',
  Yes = 'YES',
}

/** An enumeration. */
export enum Conflicthandler {
  Close = 'CLOSE',
  Keep = 'KEEP',
}

/** An enumeration. */
export enum Setperiod {
  Day = 'DAY',
  Entire = 'ENTIRE',
  Fortnight = 'FORTNIGHT',
  HalfYear = 'HALF_YEAR',
  Month = 'MONTH',
  Once = 'ONCE',
  Quarter = 'QUARTER',
  Unlimited = 'UNLIMITED',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type FetchAllAdvisorsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAllAdvisorsQuery = {
  advisors?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        advisorId: string;
        personId: number;
        preferredName: string;
        lastName: string;
        pictureDownloadUrl?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type TwInputSelectMessageSendersQueryVariables = Exact<{
  hasBeenProcessed?: InputMaybe<Scalars['Boolean']>;
}>;

export type TwInputSelectMessageSendersQuery = {
  messageSenders?: Array<{
    personId: string;
    preferredName?: string | null;
    lastName?: string | null;
  } | null> | null;
};

export type MessageTypeOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type MessageTypeOptionsQuery = {
  messageTypes?: Array<{
    code?: string | null;
    integratorLabel?: string | null;
    integratorMessageType?: string | null;
  } | null> | null;
};

export type DefaultUserTypePermissionsInputQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultUserTypePermissionsInputQuery = {
  permissions?: {
    edges: Array<{ node?: { id: string; category: string; displayName: string } | null } | null>;
  } | null;
};

export type GetEligibilityYearsQueryVariables = Exact<{
  includeAll?: InputMaybe<Scalars['Boolean']>;
  includeIsCollegiate?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetEligibilityYearsQuery = {
  eligibilityYears?: Array<{
    id: string;
    label?: string | null;
    isCollegiate?: boolean | null;
  } | null> | null;
};

export type TreeSelectCourseBySubjectQueryQueryVariables = Exact<{
  sort?: InputMaybe<Array<InputMaybe<CourseSortEnum>> | InputMaybe<CourseSortEnum>>;
  studentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  includeGeneral?: InputMaybe<Scalars['Boolean']>;
}>;

export type TreeSelectCourseBySubjectQueryQuery = {
  courses?: {
    edges: Array<{
      node?: {
        label?: string | null;
        description?: string | null;
        courseId?: string | null;
        subject?: {
          label?: string | null;
          description?: string | null;
          subjectId?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type UserSelectionOptionsQueryVariables = Exact<{
  filters?: InputMaybe<SelectionGroupFiltersInput>;
  withPeople?: InputMaybe<Scalars['Boolean']>;
  withTeamMembership?: InputMaybe<Scalars['Boolean']>;
}>;

export type UserSelectionOptionsQuery = {
  groups?: Array<{
    label?: string | null;
    pluralLabel?: string | null;
    groupType?: GroupType | null;
    selectionCode?: string | null;
    team?: { label: string; teamId: string } | null;
    people?: Array<{
      displayName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      initials?: string | null;
      pictureUrl?: string | null;
      selectionCode?: string | null;
      id: string;
      personId: string;
      streamUserId?: string | null;
      label?: string | null;
      teamMembership?: {
        id: string;
        isOrgSuperuser?: string | null;
        isAthlete?: boolean | null;
        userTypes?: Array<{ name: string; namePlural: string } | null> | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type UserSelectionQueryVariables = Exact<{
  filters?: InputMaybe<SelectionGroupFiltersInput>;
  withPeople?: InputMaybe<Scalars['Boolean']>;
}>;

export type UserSelectionQuery = {
  groups?: Array<{
    label?: string | null;
    selectionCode?: string | null;
    people?: Array<{
      displayName?: string | null;
      pictureUrl?: string | null;
      selectionCode?: string | null;
      id: string;
      personId: string;
      userTypesByTeam?: any | null;
    } | null> | null;
  } | null> | null;
};

export type StreamTokenQueryVariables = Exact<{ [key: string]: never }>;

export type StreamTokenQuery = {
  viewer?: {
    person: { displayName?: string | null; pictureUrl?: string | null };
    streamTokenResponse?: { streamUserId?: string | null; token?: string | null } | null;
  } | null;
};

export type AxleQueryVariables = Exact<{ [key: string]: never }>;

export type AxleQuery = {
  viewer?: {
    personInfo?: {
      webAccess?: Array<string | null> | null;
      appAccess?: Array<string | null> | null;
    } | null;
    sharedNavbar?: {
      topColor?: string | null;
      bottomColor?: string | null;
      imageUrl?: string | null;
      inactivityTimeout?: number | null;
      targetUrls?: Array<{ app?: string | null; url?: string | null } | null> | null;
      teamLogos?: Array<{ teamId?: string | null; imageUrl?: string | null } | null> | null;
    } | null;
    caraInfo?: { isCaraEnabled?: boolean | null; adequateChangeNoticeHours?: number | null } | null;
  } | null;
};

export type ViewerDataQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerDataQuery = {
  viewer?: {
    orgId?: number | null;
    teamId?: number | null;
    personId?: number | null;
    isOrgSuperuser?: boolean | null;
    isTeamSuperuser?: boolean | null;
    username?: string | null;
    hasMultipleAccounts: boolean;
    enabledApps?: Array<{
      id?: string | null;
      name?: string | null;
      logo?: string | null;
      link?: string | null;
    } | null> | null;
    person: {
      id: string;
      personId: string;
      emailAddress?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      fullNameNormalOrder?: string | null;
      displayName?: string | null;
      preferredName?: string | null;
      title?: string | null;
      initials?: string | null;
      isOrgLevelUser?: boolean | null;
      orgId?: number | null;
      pictureUrl?: string | null;
      selectionCode?: string | null;
      label?: string | null;
      timeZone?: string | null;
      hasLinkedSsoAccount?: boolean | null;
      usesExternalAuthentication: boolean;
    };
    userSettings?: { calendarAlertDefault?: string | null } | null;
    userPreferences?: { uses24HourTime?: boolean | null } | null;
    permissions: {
      canAddAvailability?: boolean | null;
      canAddOtherUserAppointments?: boolean | null;
      canAddTravelApptType?: boolean | null;
      canAddComplianceApptType?: boolean | null;
      canAdminLoginAccess?: boolean | null;
      canAdminTicketing?: boolean | null;
      canAssignForms?: boolean | null;
      canCopyAppointments?: boolean | null;
      canDeactivateUsers?: boolean | null;
      canFindTutors?: boolean | null;
      canManageAllTeamsAccess?: boolean | null;
      canManageAppAssignments?: boolean | null;
      canManageDigitalDisplays?: boolean | null;
      canManageForms?: boolean | null;
      canManageNews?: boolean | null;
      canManagePermissions?: boolean | null;
      canManageQuickForms?: boolean | null;
      canManageTasks?: boolean | null;
      canManageTravel?: boolean | null;
      canModifyStaffProfiles?: boolean | null;
      canModifyUsers?: boolean | null;
      canManageWeblinks?: boolean | null;
      canSendMessages?: boolean | null;
      canSendTravelLetter?: boolean | null;
      canSubmitForms?: boolean | null;
      canViewAcademics2?: boolean | null;
      canViewAthleteList?: boolean | null;
      canViewConversations?: boolean | null;
      canViewCalendarActivityLogs?: boolean | null;
      canViewMessageHistory?: boolean | null;
      canViewOthersMessageHistory?: boolean | null;
      canViewOtherUserAppointments?: boolean | null;
      canViewContactDetails?: boolean | null;
      canViewStaffProfile?: boolean | null;
      canViewTicketing?: boolean | null;
      canViewTravel?: boolean | null;
      canViewOtherTrips?: boolean | null;
      canViewVideo?: boolean | null;
      canViewWeblinks?: boolean | null;
      canViewNews?: boolean | null;
      canViewTmp?: boolean | null;
      canViewCaraLogs?: boolean | null;
      canEditCaraTeamDeclarations?: boolean | null;
      canAdminTmp?: boolean | null;
      isAcademicSuperuser?: boolean | null;
      teamsCanAdminVideos?: Array<number | null> | null;
      teamsCanAddFiles?: Array<number | null> | null;
      teamsCanAdminFileFolders?: Array<number | null> | null;
      teamsCanManageAcademics?: Array<number | null> | null;
      teamsCanViewAcademicsClass?: Array<number | null> | null;
      isLegacyApiUser?: boolean | null;
      canAddTravelContacts?: boolean | null;
      academics2ModuleEnabled?: boolean | null;
      calendarModuleEnabled?: boolean | null;
      messagingModuleEnabled?: boolean | null;
      tmpModuleEnabled?: boolean | null;
      travelModuleEnabled?: boolean | null;
      disableMessageEditDelete?: boolean | null;
      enableGuardianshipFeatures?: boolean | null;
    };
    availableTabs: Array<{
      key: string;
      overrideLabel?: string | null;
      label: string;
      path: string;
    }>;
    currentOrg: {
      id: string;
      label?: string | null;
      logoUrl?: string | null;
      allTeamsTeamId?: number | null;
      usesExternalAuthentication: boolean;
      usesSsoAsDefault: boolean;
      idpMatchAttributeType?: string | null;
      isCollegiate: boolean;
      isNonAthleteOrg: boolean;
      timeZoneCode?: string | null;
      uses24HourTime?: boolean | null;
      institutionId?: number | null;
      athleteUserType?: { value: string; label: string } | null;
      athleteStatuses?: Array<{
        value: string;
        label?: string | null;
        isActive?: boolean | null;
      } | null> | null;
      branding?: {
        headerBgColor?: string | null;
        headerTextColor?: string | null;
        headerLinkColor?: string | null;
        primaryColor?: string | null;
        primaryLighterColor?: string | null;
        primaryLightestColor?: string | null;
      } | null;
      userTypes?: Array<{
        id: string;
        name: string;
        namePlural: string;
        personTypeId: string;
      } | null> | null;
    };
    currentTeam: {
      id: string;
      label: string;
      initials?: string | null;
      logoUrl?: string | null;
      identityFieldLabel?: string | null;
      sportId?: number | null;
      teamId: string;
      branding?: {
        headerBgColor?: string | null;
        headerTextColor?: string | null;
        headerLinkColor?: string | null;
        primaryColor?: string | null;
        primaryLighterColor?: string | null;
        primaryLightestColor?: string | null;
      } | null;
      institution?: {
        city?: string | null;
        state?: string | null;
        leagueId?: number | null;
        id: string;
      } | null;
    };
    currentMembership?: {
      id: string;
      athleteStatus?: { id: string; isActiveStatus?: boolean | null; label?: string | null } | null;
      userTypes?: Array<{
        id: string;
        pk?: string | null;
        name: string;
        namePlural: string;
      } | null> | null;
    } | null;
    availableTeams: Array<{
      id: string;
      teamId: string;
      label: string;
      shortLabel: string;
      initials?: string | null;
      logoUrl?: string | null;
      canAddToTeam?: boolean | null;
      canRemoveFromTeam?: boolean | null;
      canViewOtherUsers?: boolean | null;
      moduleCodes?: Array<string | null> | null;
    }>;
    teamSuperuserTeams?: Array<{ id: string; teamId: string; label: string } | null> | null;
    travelSettings?: Array<{ label: string; enabled: boolean } | null> | null;
    visibleUserTypes?: Array<{ value: string; label: string } | null> | null;
    allAppointmentTypes?: Array<{
      id: string;
      appointmentTypeId: number;
      color: string;
      label: string;
      canUserAdd: boolean;
      isActive: boolean;
      isTutoring: boolean;
      isDayOff: boolean;
      travelOnly: boolean;
      complianceLabel?: string | null;
    } | null> | null;
  } | null;
};

export type CreateStudyHallMutationVariables = Exact<{
  input: StudyHallInput;
}>;

export type CreateStudyHallMutation = {
  studyHallCreate: {
    studyHall?: { id: string; label?: string | null; rotateQrCode: boolean } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type DeleteStudyHallMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteStudyHallMutation = {
  studyHallDelete: {
    studyHall?: { id: string; isExpired?: boolean | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type FetchStudyHallsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<StudyHallSortEnum>> | InputMaybe<StudyHallSortEnum>>;
  isExpired?: InputMaybe<Scalars['TWFilter_Boolean']>;
  searchStudyHalls?: InputMaybe<Scalars['String']>;
}>;

export type FetchStudyHallsQuery = {
  studyHalls?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        studyHallId: string;
        isExpired?: boolean | null;
        createdDate?: any | null;
        checkinAppToken?: string | null;
        label?: string | null;
        rotateQrCode: boolean;
        startDatetimeUtc?: string | null;
        endDatetimeUtc?: string | null;
        canEditDatetimes?: boolean | null;
        creator?: { fullNameNormalOrder?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type UpdateStudyHallMutationVariables = Exact<{
  input: StudyHallInput;
  id: Scalars['ID'];
}>;

export type UpdateStudyHallMutation = {
  studyHallUpdate: {
    studyHall?: { id: string; label?: string | null; rotateQrCode: boolean } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type CreateAppointmentFromTutorSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateAppointmentFromTutorSessionMutation = {
  createAppointmentFromSession: {
    session?: { id: string; appointmentOccurrenceId?: number | null } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type DeleteTutoringSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTutoringSessionMutation = {
  sessionDelete: {
    session?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type FetchTutorsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTutors?: InputMaybe<Scalars['String']>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  tutorId?: InputMaybe<Scalars['TWFilter_ID']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type FetchTutorsQuery = {
  tutors?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        preferredName: string;
        lastName: string;
        personId: number;
        fullName?: string | null;
        maximumNumberOfHours: string;
        pictureDownloadUrl?: string | null;
        isActive: boolean;
        tutorId?: string | null;
        subjects?: {
          totalCount?: number | null;
          edges: Array<{
            cursor: string;
            node?: {
              id: string;
              label?: string | null;
              description?: string | null;
              shortCode: string;
              customerKey: string;
              subjectId?: string | null;
            } | null;
          } | null>;
        } | null;
        person?: {
          id: string;
          emailAddress?: string | null;
          title?: string | null;
          teams?: Array<{ id: string; teamId: string; label: string } | null> | null;
        } | null;
        courses?: {
          totalCount?: number | null;
          edges: Array<{
            cursor: string;
            node?: {
              id: string;
              credits?: string | null;
              label?: string | null;
              description?: string | null;
              courseId?: string | null;
              subject?: {
                id: string;
                label?: string | null;
                description?: string | null;
                shortCode: string;
                customerKey: string;
                subjectId?: string | null;
              } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type CourseCreateMutationVariables = Exact<{
  input?: InputMaybe<CourseCreateInput>;
}>;

export type CourseCreateMutation = {
  courseCreate: {
    course?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type CourseSectionCreateMutationVariables = Exact<{
  input?: InputMaybe<CourseSectionCreateInput>;
}>;

export type CourseSectionCreateMutation = {
  courseSectionCreate: {
    courseSection?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type FetchCourseSectionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['TWFilter_Int']>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_ID']>;
  courseSectionSchedulesDay?: InputMaybe<Scalars['TWFilter_DaysOfWeekEnum']>;
  courseSubjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  enableNameSort?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
  searchCourseSections?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  studentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<Array<InputMaybe<CourseSectionSortEnum>> | InputMaybe<CourseSectionSortEnum>>;
}>;

export type FetchCourseSectionsQuery = {
  courseSections?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        courseId: number;
        customerKey: string;
        isOnline: boolean;
        isDeleted: boolean;
        shortCode: string;
        startDate?: string | null;
        endDate?: string | null;
        courseSectionId?: string | null;
        term?: {
          id: string;
          startDate?: string | null;
          endDate?: string | null;
          label?: string | null;
          termId?: string | null;
        } | null;
        enrollments?: {
          totalCount?: number | null;
          edges: Array<{
            node?: {
              id: string;
              droppedDate?: string | null;
              creditsAttempted?: string | null;
              studentTermId?: number | null;
              studentTerm?: {
                id: string;
                studentTermId: string;
                studentId: number;
                student?: {
                  id: string;
                  personId: number;
                  isDeleted: boolean;
                  fullName?: string | null;
                  lastName: string;
                  preferredName: string;
                  teamIds?: Array<number | null> | null;
                } | null;
              } | null;
            } | null;
          } | null>;
        } | null;
        courseSectionSchedules?: {
          edges: Array<{
            node?: {
              id: string;
              courseSectionScheduleId: string;
              startTime?: string | null;
              endTime?: string | null;
              day: DaysOfWeekEnum;
              location?: string | null;
            } | null;
          } | null>;
        } | null;
        professor?: {
          id: string;
          professorId: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName?: string | null;
          emailAddress?: string | null;
          phoneNumber?: string | null;
        } | null;
        course?: {
          id: string;
          credits?: string | null;
          label?: string | null;
          description?: string | null;
          courseId?: string | null;
          subject?: {
            id: string;
            label?: string | null;
            description?: string | null;
            shortCode: string;
            customerKey: string;
            subjectId?: string | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type CourseSelectorQueryQueryVariables = Exact<{
  courseId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<Array<InputMaybe<CourseSortEnum>> | InputMaybe<CourseSortEnum>>;
  studentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  subjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  termIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  includeGeneral?: InputMaybe<Scalars['Boolean']>;
}>;

export type CourseSelectorQueryQuery = {
  courses?: {
    totalCount?: number | null;
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        courseId: string;
        label?: string | null;
        description?: string | null;
        subject?: { id: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchGradesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchGradesQuery = {
  gradesDefault?: {
    totalCount?: number | null;
    edges: Array<{
      cursor: string;
      node?: { id: string; label?: string | null; gradeId?: string | null } | null;
    } | null>;
  } | null;
};

export type ProfessorCreateMutationVariables = Exact<{
  input?: InputMaybe<ProfessorCreateInput>;
}>;

export type ProfessorCreateMutation = {
  professorCreate: {
    aC2Professor?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type FetchExternalProfessorSurveyAssignmentsQueryVariables = Exact<{
  uuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['TWFilter_ProfessorSurveyAssignmentStatus']>;
}>;

export type FetchExternalProfessorSurveyAssignmentsQuery = {
  externalProfessorSurveyAssignments?: {
    totalCount?: number | null;
    edges: Array<{
      cursor: string;
      node?: {
        status: ProfessorSurveyAssignmentStatus;
        externalAccessLinkId: number;
        courseSectionId: number;
        professorSurveyAssignmentId?: string | null;
        courseSection?: {
          shortCode: string;
          startDate?: string | null;
          endDate?: string | null;
          term?: {
            termId: string;
            startDate?: string | null;
            endDate?: string | null;
            label?: string | null;
          } | null;
          enrollments?: { totalCount?: number | null } | null;
          courseSectionSchedules?: {
            edges: Array<{
              node?: {
                startTime?: string | null;
                endTime?: string | null;
                day: DaysOfWeekEnum;
                location?: string | null;
              } | null;
            } | null>;
          } | null;
          professor?: {
            firstName?: string | null;
            lastName?: string | null;
            emailAddress?: string | null;
            phoneNumber?: string | null;
          } | null;
          course?: {
            label?: string | null;
            description?: string | null;
            subject?: {
              label?: string | null;
              description?: string | null;
              shortCode: string;
            } | null;
          } | null;
        } | null;
        professorSurveyReports?: {
          totalCount?: number | null;
          edges: Array<{
            cursor: string;
            node?: {
              gradeId?: number | null;
              isAtRisk?: boolean | null;
              comment?: string | null;
              absenceCount?: number | null;
              professorSurveyReportId?: string | null;
              grade?: { label?: string | null } | null;
              enrollment?: {
                studentTerm?: {
                  student?: {
                    lastName: string;
                    preferredName: string;
                    pictureDownloadUrl?: string | null;
                    personId: number;
                    fullName?: string | null;
                    person?: { firstName?: string | null; teamLabels?: string | null } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchProfessorSurveysQueryVariables = Exact<{
  professorSurveyId?: InputMaybe<Scalars['TWFilter_ID']>;
  isClosed?: InputMaybe<Scalars['TWFilter_String']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  searchProfessorSurveys?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<
    Array<InputMaybe<ProfessorSurveySortEnum>> | InputMaybe<ProfessorSurveySortEnum>
  >;
  termIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type FetchProfessorSurveysQuery = {
  professorSurveys?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        professorSurveyId: string;
        isDeleted: boolean;
        dueDateTime: any;
        label?: string | null;
        description?: string | null;
        hasOnlyCompletedAssignments?: string | null;
        countCompletedAssignments?: string | null;
        isExpired?: string | null;
        isClosed?: string | null;
        countStudents?: string | null;
        countNotCompletedAssignments?: string | null;
        createdBy?: number | null;
        createdDate?: any | null;
        professorSurveyAssignments?: { totalCount?: number | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchProfessorsQueryVariables = Exact<{
  professorId?: InputMaybe<Scalars['TWFilter_ID']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<Ac2ProfessorSortEnum>> | InputMaybe<Ac2ProfessorSortEnum>>;
}>;

export type FetchProfessorsQuery = {
  professors?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        professorId: string;
        fullName?: string | null;
        emailAddress?: string | null;
        phoneNumber?: string | null;
        officeLocation?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchStudentTermsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  searchStudents?: InputMaybe<Scalars['String']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  studentId?: InputMaybe<Scalars['TWFilter_Int']>;
  studentTermId?: InputMaybe<Scalars['TWFilter_ID']>;
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseSectionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  studentYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  eligibilityYearIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  checkinStartDay?: InputMaybe<Scalars['String']>;
  checkinEndDay?: InputMaybe<Scalars['String']>;
}>;

export type FetchStudentTermsQuery = {
  studentTerms?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        creditsAttempted?: string | null;
        creditsCompleted?: string | null;
        finalGpa?: string | null;
        goalGpa?: string | null;
        midtermGpa?: string | null;
        studentId: number;
        termId: number;
        studentTermId?: string | null;
        studentYear?: { id: string; label?: string | null; studentYearId: string } | null;
        student?: {
          id: string;
          preferredName: string;
          lastName: string;
          fullName?: string | null;
          teamIds?: Array<number | null> | null;
          personId: number;
          isAtRisk: boolean;
          eligibilityYears?: Array<string | null> | null;
          isDeleted: boolean;
          cumulativeGpa?: string | null;
          academicStatus: AcademicStatus;
          pictureDownloadUrl?: string | null;
          studentId?: string | null;
          metaData?: any | null;
          advisors?: {
            totalCount?: number | null;
            edges: Array<{
              cursor: string;
              node?: {
                id: string;
                personId: number;
                preferredName: string;
                lastName: string;
                pictureDownloadUrl?: string | null;
                advisorId?: string | null;
              } | null;
            } | null>;
          } | null;
          person?: {
            id: string;
            emailAddress?: string | null;
            phoneHome?: string | null;
            phoneOffice?: string | null;
            phoneCell?: string | null;
          } | null;
          studyHallDetails?: {
            isCurrentlyCheckedIn?: boolean | null;
            weeklyAccumulatedMinutes?: number | null;
            weeklyHoursRequired?: number | null;
          } | null;
        } | null;
        term?: { id: string } | null;
        enrollments?: {
          edges: Array<{
            node?: {
              id: string;
              creditsAttempted?: string | null;
              droppedDate?: string | null;
              courseSection?: {
                id: string;
                courseId: number;
                course?: { id: string; credits?: string | null } | null;
              } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchStudentsQueryVariables = Exact<{
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<StudentSortEnum>> | InputMaybe<StudentSortEnum>>;
  teamIds?: InputMaybe<Scalars['TWFilter_IntArray']>;
}>;

export type FetchStudentsQuery = {
  students?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: { id: string; studentId: string; personId: number; fullName?: string | null } | null;
    } | null>;
  } | null;
};

export type SubjectCreateMutationVariables = Exact<{
  input?: InputMaybe<SubjectCreateInput>;
}>;

export type SubjectCreateMutation = {
  subjectCreate: {
    subject?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type FetchSubjectsQueryVariables = Exact<{
  subjectId?: InputMaybe<Scalars['TWFilter_ID']>;
  includeGeneral?: InputMaybe<Scalars['Boolean']>;
  studentTermIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  sort?: InputMaybe<Array<InputMaybe<SubjectSortEnum>> | InputMaybe<SubjectSortEnum>>;
}>;

export type FetchSubjectsQuery = {
  subjects?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        subjectId: string;
        isGeneral: boolean;
        description?: string | null;
        label?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type AllTermsQueryVariables = Exact<{
  termId?: InputMaybe<Scalars['TWFilter_ID']>;
  startDate?: InputMaybe<Scalars['TWFilter_String']>;
  endDate?: InputMaybe<Scalars['TWFilter_String']>;
}>;

export type AllTermsQuery = {
  terms?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        id: string;
        termId: string;
        label?: string | null;
        startDate?: string | null;
        endDate?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type LogoutUserMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']>;
}>;

export type LogoutUserMutation = { logoutUser: { redirectUrl?: string | null } };

export type SelectablesDataQueryVariables = Exact<{
  codes: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  withPeople?: InputMaybe<Scalars['Boolean']>;
}>;

export type SelectablesDataQuery = {
  selectables?: Array<
    | { label?: string | null; selectionCode?: string | null }
    | {
        personId: string;
        orgId?: number | null;
        firstName?: string | null;
        lastName?: string | null;
        emailAddress?: string | null;
        preferredName?: string | null;
        displayName?: string | null;
        initials?: string | null;
        pictureUrl?: string | null;
        active: boolean;
        streamUserId?: string | null;
        label?: string | null;
        selectionCode?: string | null;
        teams?: Array<{ teamId: string } | null> | null;
        teamMembership?: { id: string; isAthlete?: boolean | null } | null;
      }
    | {
        groupType?: GroupType | null;
        pluralLabel?: string | null;
        label?: string | null;
        selectionCode?: string | null;
        team?: { id: string; orgId?: number | null } | null;
        people?: Array<{
          id: string;
          personId: string;
          firstName?: string | null;
          lastName?: string | null;
          preferredName?: string | null;
          displayName?: string | null;
          label?: string | null;
          selectionCode?: string | null;
          pictureUrl?: string | null;
          active: boolean;
          streamUserId?: string | null;
          teams?: Array<{ teamId: string } | null> | null;
          teamMembership?: { id: string; isAthlete?: boolean | null } | null;
        } | null> | null;
      }
    | { label?: string | null; selectionCode?: string | null }
    | null
  > | null;
};

export type SwitchUsersCurrentTeamMutationVariables = Exact<{
  team: Scalars['ID'];
}>;

export type SwitchUsersCurrentTeamMutation = {
  switchUsersCurrentTeam: {
    redirectUrl?: string | null;
    currentUserData?: {
      personId?: string | null;
      teamId?: string | null;
      orgId?: string | null;
    } | null;
  };
};

export type FetchTeamsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<Array<InputMaybe<TeamSortEnum>> | InputMaybe<TeamSortEnum>>;
}>;

export type FetchTeamsQuery = {
  teams?: {
    totalCount?: number | null;
    edges: Array<{
      node?: { id: string; label: string; shortLabel: string; teamId?: string | null } | null;
    } | null>;
  } | null;
};

export type ProfessorSurveyAssignmentsByCourseSectionQueryQueryVariables = Exact<{
  professorSurveyId: Scalars['ID'];
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  courseIds?: InputMaybe<Scalars['TWFilter_Int']>;
  eligibilityYearIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  isAtRiskReport?: InputMaybe<Scalars['Boolean']>;
  searchCourseSections?: InputMaybe<Scalars['String']>;
  professorSurveyAssignmentStatus?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type ProfessorSurveyAssignmentsByCourseSectionQueryQuery = {
  courseSections?: {
    totalCount?: number | null;
    pageInfo: { hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      node?: {
        id: string;
        shortCode: string;
        course?: { id: string; label?: string | null; description?: string | null } | null;
        courseSectionSchedules?: {
          edges: Array<{
            node?: {
              id: string;
              startTime?: string | null;
              endTime?: string | null;
              day: DaysOfWeekEnum;
              location?: string | null;
            } | null;
          } | null>;
        } | null;
        professor?: { id: string; fullName?: string | null } | null;
        enrollments?: {
          edges: Array<{
            node?: {
              id: string;
              studentTerm?: {
                id: string;
                student?: {
                  id: string;
                  eligibilityYears?: Array<string | null> | null;
                  fullName?: string | null;
                  teamIds?: Array<number | null> | null;
                  advisors?: { edges: Array<{ node?: { id: string } | null } | null> } | null;
                  person?: { id: string; teamLabels?: string | null } | null;
                } | null;
              } | null;
              professorSurveyReports?: {
                edges: Array<{
                  node?: {
                    id: string;
                    absenceCount?: number | null;
                    comment?: string | null;
                    isAtRisk?: boolean | null;
                    grade?: { id: string; label?: string | null } | null;
                    professorSurveyAssignment?: {
                      id: string;
                      professorSurveyId: number;
                      status: ProfessorSurveyAssignmentStatus;
                      completedDateTime?: any | null;
                    } | null;
                  } | null;
                } | null>;
              } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ProfessorSurveyAssignmentsByStudentTermQueryQueryVariables = Exact<{
  professorSurveyId: Scalars['ID'];
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  eligibilityYearIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  isAtRiskResults?: InputMaybe<Scalars['Boolean']>;
  searchStudents?: InputMaybe<Scalars['String']>;
  professorSurveyAssignmentStatus?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termId: Scalars['TWFilter_Int'];
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
}>;

export type ProfessorSurveyAssignmentsByStudentTermQueryQuery = {
  studentTerms?: {
    totalCount?: number | null;
    pageInfo: { hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      node?: {
        id: string;
        student?: {
          id: string;
          eligibilityYears?: Array<string | null> | null;
          fullName?: string | null;
          person?: { id: string; teamLabels?: string | null } | null;
        } | null;
        enrollments?: {
          edges: Array<{
            node?: {
              id: string;
              professorSurveyReports?: {
                edges: Array<{
                  node?: {
                    id: string;
                    absenceCount?: number | null;
                    comment?: string | null;
                    isAtRisk?: boolean | null;
                    grade?: { id: string; label?: string | null } | null;
                    professorSurveyAssignment?: {
                      id: string;
                      professorSurveyId: number;
                      status: ProfessorSurveyAssignmentStatus;
                    } | null;
                  } | null;
                } | null>;
              } | null;
              courseSection?: {
                id: string;
                courseId: number;
                shortCode: string;
                course?: { id: string; label?: string | null; description?: string | null } | null;
                professor?: { id: string; fullName?: string | null } | null;
              } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchProfessorSurveyAssignmentsQueryVariables = Exact<{
  professorSurveyAssignmentId?: InputMaybe<Scalars['TWFilter_ID']>;
  advisorId?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  completedDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  courseId?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  eligibilityYearId?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  orgId?: InputMaybe<Scalars['TWFilter_Int']>;
  professorSurveyId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<
    | Array<InputMaybe<ProfessorSurveyAssignmentSortEnum>>
    | InputMaybe<ProfessorSurveyAssignmentSortEnum>
  >;
  status?: InputMaybe<Scalars['TWFilter_ProfessorSurveyAssignmentStatus']>;
  teamId?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  createdDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  updatedDate?: InputMaybe<Scalars['TWFilter_DateTime']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type FetchProfessorSurveyAssignmentsQuery = {
  professorSurveyAssignments?: {
    totalCount?: number | null;
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        status: ProfessorSurveyAssignmentStatus;
        externalAccessLinkId: number;
        courseSectionId: number;
        professorSurveyAssignmentId?: string | null;
        courseSection?: {
          id: string;
          shortCode: string;
          startDate?: string | null;
          endDate?: string | null;
          term?: {
            id: string;
            termId: string;
            startDate?: string | null;
            endDate?: string | null;
            label?: string | null;
          } | null;
          enrollments?: { totalCount?: number | null } | null;
          courseSectionSchedules?: {
            edges: Array<{
              node?: {
                id: string;
                startTime?: string | null;
                endTime?: string | null;
                day: DaysOfWeekEnum;
                location?: string | null;
              } | null;
            } | null>;
          } | null;
          professor?: {
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            emailAddress?: string | null;
            phoneNumber?: string | null;
          } | null;
          course?: {
            id: string;
            courseId: string;
            label?: string | null;
            description?: string | null;
            subject?: {
              id: string;
              label?: string | null;
              description?: string | null;
              shortCode: string;
            } | null;
          } | null;
        } | null;
        professorSurveyReports?: {
          totalCount?: number | null;
          edges: Array<{
            cursor: string;
            node?: {
              id: string;
              gradeId?: number | null;
              isAtRisk?: boolean | null;
              comment?: string | null;
              absenceCount?: number | null;
              professorSurveyReportId?: string | null;
              grade?: { id: string; label?: string | null } | null;
              enrollment?: {
                id: string;
                studentTerm?: {
                  id: string;
                  student?: {
                    id: string;
                    lastName: string;
                    preferredName: string;
                    pictureDownloadUrl?: string | null;
                    eligibilityYear?: string | null;
                    personId: number;
                    fullName?: string | null;
                    teamIds?: Array<number | null> | null;
                    person?: {
                      id: string;
                      firstName?: string | null;
                      teamLabels?: string | null;
                    } | null;
                    advisors?: {
                      edges: Array<{ node?: { id: string; advisorId: string } | null } | null>;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ProfessorSurveyCreateMutationVariables = Exact<{
  input: ProfessorSurveyCreateInput;
}>;

export type ProfessorSurveyCreateMutation = {
  professorSurveyCreate: {
    professorSurvey?: { id: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type FetchTutorSessionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  occurrenceStartDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  reportStatus?: InputMaybe<Scalars['TWFilter_String']>;
  searchSessions?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['TWFilter_ID']>;
  sort?: InputMaybe<Array<InputMaybe<SessionSortEnum>> | InputMaybe<SessionSortEnum>>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  tutorId?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type FetchTutorSessionsQuery = {
  sessions?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        sessionId: string;
        id: string;
        reportAnswers: any;
        studentIds: Array<number | null>;
        appointmentOccurrenceId?: number | null;
        courseId?: number | null;
        courseSectionId?: number | null;
        subjectId?: number | null;
        isCanceled?: string | null;
        reportStatus?: string | null;
        termId: number;
        startDateTime?: any | null;
        endDateTime?: any | null;
        location?: string | null;
        scheduledOn?: any | null;
        appointmentOccurrence?: { id: string } | null;
        course?: { id: string; label?: string | null } | null;
        students?: {
          totalCount?: number | null;
          edges: Array<{
            cursor: string;
            node?: {
              id: string;
              preferredName: string;
              lastName: string;
              fullName?: string | null;
              teamIds?: Array<number | null> | null;
              personId: number;
              isAtRisk: boolean;
              isDeleted: boolean;
              cumulativeGpa?: string | null;
              academicStatus: AcademicStatus;
              pictureDownloadUrl?: string | null;
              eligibilityYears?: Array<string | null> | null;
              studentId?: string | null;
              metaData?: any | null;
              advisors?: {
                totalCount?: number | null;
                edges: Array<{
                  cursor: string;
                  node?: {
                    id: string;
                    personId: number;
                    preferredName: string;
                    lastName: string;
                    pictureDownloadUrl?: string | null;
                    advisorId?: string | null;
                  } | null;
                } | null>;
              } | null;
            } | null;
          } | null>;
        } | null;
        tutor?: {
          id: string;
          preferredName: string;
          lastName: string;
          fullName?: string | null;
          personId: number;
          maximumNumberOfHours: string;
          pictureDownloadUrl?: string | null;
          isActive: boolean;
          tutorId?: string | null;
          subjects?: {
            totalCount?: number | null;
            edges: Array<{
              cursor: string;
              node?: {
                id: string;
                label?: string | null;
                description?: string | null;
                shortCode: string;
                customerKey: string;
                subjectId?: string | null;
              } | null;
            } | null>;
          } | null;
          person?: { id: string; teams?: Array<{ teamId?: string | null } | null> | null } | null;
        } | null;
        sessionAttendances?: {
          totalCount?: number | null;
          edges: Array<{
            cursor: string;
            node?: {
              id: string;
              sessionAttendanceId: string;
              sessionId: number;
              studentId: number;
              status: SessionAttendanceStatus;
              meta?: any | null;
              student?: {
                id: string;
                preferredName: string;
                lastName: string;
                teamIds?: Array<number | null> | null;
                studentId: string;
                fullName?: string | null;
                personId: number;
                pictureDownloadUrl?: string | null;
              } | null;
            } | null;
          } | null>;
        } | null;
        creator?: {
          personId: string;
          preferredName?: string | null;
          lastName?: string | null;
          fullName?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type StudentTermBulkSelectEnrollmentsQueryVariables = Exact<{
  searchStudents?: InputMaybe<Scalars['String']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  studentId?: InputMaybe<Scalars['TWFilter_Int']>;
  studentTermId?: InputMaybe<Scalars['TWFilter_ID']>;
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseSectionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  studentYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  eligibilityYearIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type StudentTermBulkSelectEnrollmentsQuery = {
  studentTerms?: {
    edges: Array<{
      node?: {
        id: string;
        enrollments?: {
          edges: Array<{
            node?: { id: string; courseSection?: { id: string; courseId: number } | null } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type StudentTermBulkSelectionQueryVariables = Exact<{
  searchStudents?: InputMaybe<Scalars['String']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
  studentId?: InputMaybe<Scalars['TWFilter_Int']>;
  studentTermId?: InputMaybe<Scalars['TWFilter_ID']>;
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseSectionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  studentYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  eligibilityYearIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type StudentTermBulkSelectionQuery = {
  studentTerms?: {
    edges: Array<{
      node?: {
        id: string;
        studentId: number;
        student?: {
          id: string;
          fullName?: string | null;
          personId: number;
          teamIds?: Array<number | null> | null;
        } | null;
        term?: { startDate?: string | null; endDate?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type BulkStudentUpdateMutationVariables = Exact<{
  input: StudentInput;
}>;

export type BulkStudentUpdateMutation = {
  bulkStudentUpdate: Array<{ id: string; studentId: string } | null>;
};

export type StudyHallActionDeleteMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type StudyHallActionDeleteMutation = {
  studyHallActionDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type UpdateStudyHallActionMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<StudyHallActionUpdateInput>;
}>;

export type UpdateStudyHallActionMutation = {
  studyHallActionUpdate: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type FetchStudyHallActionsQueryVariables = Exact<{
  studyHallActionId?: InputMaybe<Scalars['TWFilter_ID']>;
  checkinDateTime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  sort?: InputMaybe<
    Array<InputMaybe<StudyHallActionSortEnum>> | InputMaybe<StudyHallActionSortEnum>
  >;
}>;

export type FetchStudyHallActionsQuery = {
  studyHallActions?: {
    totalCount?: number | null;
    pageInfo: { startCursor?: string | null; endCursor?: string | null };
    edges: Array<{
      node?: {
        studyHallActionId: string;
        id: string;
        checkinDateTime?: any | null;
        checkoutDateTime?: any | null;
        accumulatedMinutes: number;
        autoCheckedOut?: boolean | null;
        studyHall?: {
          id: string;
          studyHallId: string;
          label?: string | null;
          endDatetimeUtc?: string | null;
        } | null;
        notes?: {
          checkinNote?: {
            note?: string | null;
            timestamp?: string | null;
            personId?: number | null;
            person?: {
              id: string;
              fullNameNormalOrder?: string | null;
              initials?: string | null;
              pictureUrl?: string | null;
            } | null;
          } | null;
          checkoutNote?: {
            note?: string | null;
            timestamp?: string | null;
            personId?: number | null;
            person?: {
              id: string;
              fullNameNormalOrder?: string | null;
              initials?: string | null;
              pictureUrl?: string | null;
            } | null;
          } | null;
          editNotes?: Array<{
            note?: string | null;
            timestamp?: string | null;
            personId?: number | null;
            person?: {
              id: string;
              fullNameNormalOrder?: string | null;
              initials?: string | null;
              pictureUrl?: string | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type StudyHallActionBulkCreateMutationVariables = Exact<{
  input: StudyHallActionBulkCreateInput;
}>;

export type StudyHallActionBulkCreateMutation = {
  studyHallActionBulkCreate: { studyHallAction?: { id: string } | null };
};

export type StudyHallRequirementBulkCreateOrUpdateMutationVariables = Exact<{
  input: StudyHallRequirementBulkCreateOrUpdateInput;
}>;

export type StudyHallRequirementBulkCreateOrUpdateMutation = {
  studyHallRequirementBulkCreateOrUpdate: { studyHallRequirement?: { id: string } | null };
};

export type FetchStudyHallDetailQueryVariables = Exact<{
  studyHallId: Scalars['TWFilter_ID'];
}>;

export type FetchStudyHallDetailQuery = {
  studyHalls?: {
    edges: Array<{
      cursor: string;
      node?: { id: string; studyHallId: string; numOpenCheckins?: number | null } | null;
    } | null>;
  } | null;
};

export type SessionBulkCancelMutationVariables = Exact<{
  input?: InputMaybe<SessionBulkCancelInput>;
}>;

export type SessionBulkCancelMutation = {
  sessionBulkCancel: {
    session?: Array<{
      sessionId: string;
      reportStatus?: string | null;
      sessionAttendances?: {
        edges: Array<{
          node?: { studentId: number; status: SessionAttendanceStatus; meta?: any | null } | null;
        } | null>;
      } | null;
    } | null> | null;
  };
};

export type UpcomingSessionsQueryVariables = Exact<{
  searchSessions?: InputMaybe<Scalars['String']>;
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
}>;

export type UpcomingSessionsQuery = {
  upcomingSessions?: Array<{
    appointmentOccId?: number | null;
    course?: string | null;
    courseId?: number | null;
    location?: string | null;
    startDateTime?: any | null;
    studentIds?: Array<number | null> | null;
    termId?: number | null;
    tutor?: string | null;
    tutorId?: number | null;
    tutorPersonId?: number | null;
    students?: Array<{
      pk?: number | null;
      fullName?: string | null;
      personId?: number | null;
      pictureUrl?: string | null;
      teamIds?: Array<number | null> | null;
    } | null> | null;
  } | null> | null;
};

export type SessionRequestListQueryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  advisorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  courseSectionId?: InputMaybe<Scalars['TWFilter_Int']>;
  createdDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  isAtRisk?: InputMaybe<Scalars['Boolean']>;
  searchSessionRequests?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SessionRequestSortEnum>> | InputMaybe<SessionRequestSortEnum>>;
  status?: InputMaybe<Scalars['TWFilter_SessionRequestStatus']>;
  subjectId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  termId?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type SessionRequestListQueryQuery = {
  sessionRequests?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        courseId?: number | null;
        courseSectionId?: number | null;
        termId: number;
        notes?: string | null;
        status: SessionRequestStatus;
        createdBy?: number | null;
        createdDateTime?: string | null;
        tutorSessionRequestId?: string | null;
        students?: {
          edges: Array<{
            node?: {
              id: string;
              studentId: string;
              personId: number;
              fullName?: string | null;
              teamIds?: Array<number | null> | null;
              picture?: string | null;
            } | null;
          } | null>;
        } | null;
        term?: {
          id: string;
          termId: string;
          startDate?: string | null;
          endDate?: string | null;
        } | null;
        course?: { id: string; label?: string | null; courseId?: string | null } | null;
        creator?: {
          personId: string;
          fullName?: string | null;
          initials?: string | null;
          picture?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type CourseSectionUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CourseSectionUpdateInput;
}>;

export type CourseSectionUpdateMutation = {
  courseSectionUpdate: {
    courseSection?: { id: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type CourseUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CourseUpdateInput;
}>;

export type CourseUpdateMutation = {
  courseUpdate: {
    course?: { id: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type ProfessorUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ProfessorUpdateInput;
}>;

export type ProfessorUpdateMutation = {
  professorUpdate: {
    aC2Professor?: { id: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type SubjectUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: SubjectUpdateInput;
}>;

export type SubjectUpdateMutation = {
  subjectUpdate: {
    subject?: { id: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type UpdatePersonAccountPreferencesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PersonPreferencesInput;
}>;

export type UpdatePersonAccountPreferencesMutation = {
  personAccountPreferencesUpdate: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type AccountSettingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AccountSettingsQuery = {
  person?:
    | { id: string; calendarAlertDefault?: string | null; timeZone?: string | null }
    | {}
    | null;
};

export type GetCfapiTokenQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCfapiTokenQuery = {
  person?: { __typename: 'PersonNode'; id: string; cfAccessToken?: string | null } | {} | null;
};

export type MergeUserAccountsMutationVariables = Exact<{
  input: UserAccountsMergeInput;
}>;

export type MergeUserAccountsMutation = {
  userAccountsMerge: {
    success?: boolean | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type RefreshCfapiTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshCfapiTokenMutation = {
  cfApiTokenRefresh: {
    token?: string | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type UpdateUserPasswordMutationVariables = Exact<{
  input: PasswordInput;
}>;

export type UpdateUserPasswordMutation = {
  userPasswordUpdate: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type UpdateUserEmailMutationVariables = Exact<{
  input: EmailInput;
}>;

export type UpdateUserEmailMutation = {
  userEmailUpdate: {
    primaryEmail?: string | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type UpdateUserUsernameMutationVariables = Exact<{
  input: UsernameInput;
}>;

export type UpdateUserUsernameMutation = {
  userUsernameUpdate: {
    username?: string | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type ProfileBulkImportAddressMutationVariables = Exact<{
  input: BulkAddressInput;
}>;

export type ProfileBulkImportAddressMutation = {
  profileBulkImportAddress: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type AdminBulkImportContactsMutationVariables = Exact<{
  input: BulkPersonContactInput;
}>;

export type AdminBulkImportContactsMutation = {
  adminBulkImportContacts: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type ProfileBulkImportMutationVariables = Exact<{
  input: ProfileBulkInput;
}>;

export type ProfileBulkImportMutation = {
  profileBulkImport: {
    __typename: 'ProfileBulkImportPayload';
    userErrors: Array<{ __typename: 'UserError'; field: Array<string>; message: string }>;
  };
};

export type AdminActionDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AdminActionDetailQuery = {
  adminAction?:
    | {
        id: string;
        status: string;
        parameters?: any | null;
        log?: any | null;
        action: string;
        creator?: {
          fullNameNormalOrder?: string | null;
          pictureUrl?: string | null;
          initials?: string | null;
          teamMembership?: { userTypeLabels?: string | null } | null;
        } | null;
      }
    | {}
    | null;
};

export type ProfileAttributeBulkImportMutationVariables = Exact<{
  input: ProfileAttributeBulkImportInput;
}>;

export type ProfileAttributeBulkImportMutation = {
  attributeBulkImport: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type AdminOrgProfileAttributesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type AdminOrgProfileAttributesQuery = {
  orgProfileAttributes?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        label: string;
        coreCode?: string | null;
        isCustom?: boolean | null;
        categoryLabel?: string | null;
        categoryCoreCode?: string | null;
        dataType?: string | null;
        optionsList?: Array<string | null> | null;
        appliesToUserTypes?: Array<string | null> | null;
        hasUserTypeRestriction: boolean;
      } | null;
    } | null>;
  } | null;
};

export type AdminTeamProfileAttributesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type AdminTeamProfileAttributesQuery = {
  teamProfileAttributes?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        label: string;
        coreCode?: string | null;
        isCustom?: boolean | null;
        categoryLabel?: string | null;
        dataType?: string | null;
        optionsList?: Array<string | null> | null;
        appliesToUserTypes?: Array<string | null> | null;
        hasUserTypeRestriction: boolean;
      } | null;
    } | null>;
  } | null;
};

export type AllOrgProfileAttributesQueryVariables = Exact<{ [key: string]: never }>;

export type AllOrgProfileAttributesQuery = {
  orgProfileAttributes?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        enumOptions?: string | null;
        dataType?: string | null;
        isCustom?: boolean | null;
        coreCode?: string | null;
        optionsList?: Array<string | null> | null;
        categoryLabel?: string | null;
        categoryCoreCode?: string | null;
        appliesToUserTypes?: Array<string | null> | null;
        hasUserTypeRestriction: boolean;
        extendedOptionsList?: Array<{
          label?: string | null;
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type AllTeamProfileAttributesQueryVariables = Exact<{ [key: string]: never }>;

export type AllTeamProfileAttributesQuery = {
  teamProfileAttributes?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        enumOptions?: string | null;
        dataType?: string | null;
        isCustom?: boolean | null;
        coreCode?: string | null;
        categoryLabel?: string | null;
        optionsList?: Array<string | null> | null;
        appliesToUserTypes?: Array<string | null> | null;
        hasUserTypeRestriction: boolean;
        extendedOptionsList?: Array<{
          label?: string | null;
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type DromoS3UploadMutationVariables = Exact<{
  input: DromoUploadS3FileParams;
}>;

export type DromoS3UploadMutation = { dromoS3Upload: { fileNameServer?: string | null } };

export type AdminActionsQueryVariables = Exact<{
  action?: InputMaybe<Scalars['TWFilter_String']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<AdminActionSortEnum>> | InputMaybe<AdminActionSortEnum>>;
}>;

export type AdminActionsQuery = {
  adminActions?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        createdDateTime?: string | null;
        status: string;
        parameters?: any | null;
        action: string;
        creator?: { fullNameNormalOrder?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllAthletesEmailsQueryVariables = Exact<{
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;

export type GetAllAthletesEmailsQuery = {
  people?: {
    totalCount?: number | null;
    edges: Array<{
      person?: { emailAddress?: string | null; schoolIdentifier?: string | null } | null;
    } | null>;
  } | null;
};

export type GetAllPersonsAdminQueryVariables = Exact<{
  isContact?: InputMaybe<Scalars['TWFilter_Boolean']>;
  teamId?: InputMaybe<Scalars['Int']>;
  withTypes?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetAllPersonsAdminQuery = {
  allPersons?: {
    edges: Array<{
      node?: { emailAddress?: string | null; userTypesByTeam?: any | null } | null;
    } | null>;
  } | null;
};

export type GetAllStatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllStatesQuery = {
  states?: {
    edges: Array<{ node?: { name?: string | null; code?: string | null } | null } | null>;
  } | null;
};

export type GetOrgPositionsForAthletesQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['ID']>;
}>;

export type GetOrgPositionsForAthletesQuery = {
  orgSportPositions?: Array<{
    sportPosition?: { id: string; label?: string | null } | null;
    team?: { teamId: string; label: string; shortLabel: string } | null;
  } | null> | null;
};

export type GetOrgTeamsQueryVariables = Exact<{
  active?: InputMaybe<Scalars['TWFilter_Boolean']>;
}>;

export type GetOrgTeamsQuery = {
  teams?: {
    edges: Array<{
      node?: { teamId: string; label: string; shortLabel: string; active: boolean } | null;
    } | null>;
  } | null;
};

export type ProfileBulkImportRoommatesMutationVariables = Exact<{
  input: ProfileBulkRoommateInput;
}>;

export type ProfileBulkImportRoommatesMutation = {
  profileBulkImportRoommates: {
    __typename: 'ProfileBulkImportRoommatesPayload';
    userErrors: Array<{ __typename: 'UserError'; field: Array<string>; message: string }>;
  };
};

export type ChooseAccountMutationVariables = Exact<{
  person: Scalars['ID'];
}>;

export type ChooseAccountMutation = {
  chooseAccount: {
    currentUserData?: {
      personId?: string | null;
      teamId?: string | null;
      orgId?: string | null;
      person?: { id: string } | null;
    } | null;
  };
};

export type UserAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type UserAccountsQuery = {
  userAccounts: Array<{
    person: {
      id: string;
      pictureUrl?: string | null;
      initials?: string | null;
      preferredName?: string | null;
      nickname?: string | null;
      fullName?: string | null;
      lastAndFirstName?: string | null;
      fullNameNormalOrder?: string | null;
      emailAddress?: string | null;
    };
    org: { id: string; label?: string | null; logoUrl?: string | null; isAdminOrg: boolean };
  }>;
};

export type ExternalAccessPageQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ExternalAccessPageQuery = {
  externalAccessLink?: {
    warnings?: Array<string | null> | null;
    node?: { id: string; action?: ExternalAccessLinkAction | null } | null;
  } | null;
};

export type HandleFormCallbackMutationVariables = Exact<{
  input: FormCallbackInput;
}>;

export type HandleFormCallbackMutation = {
  formCallback: {
    completionRole?: {
      id: string;
      status?: string | null;
      isExternalRole?: boolean | null;
      lastCallbackEvent?: string | null;
      externalLinkUrl?: string | null;
      completion?: {
        id: string;
        isCompleted?: boolean | null;
        isLinkCompletion?: boolean | null;
      } | null;
      workflow?: Array<{
        assignedDateTime?: any | null;
        completedDateTime?: any | null;
        roleOrder?: number | null;
        roleName?: string | null;
        pk?: number | null;
        externalName?: string | null;
        externalEmail?: string | null;
        id: string;
        status?: string | null;
        person?: { id: string; displayName?: string | null } | null;
      } | null> | null;
    } | null;
  };
};

export type RequestPasswordResetMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type RequestPasswordResetMutation = { requestPasswordReset: { ok?: boolean | null } };

export type SendUsernameReminderMutationVariables = Exact<{
  email: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
}>;

export type SendUsernameReminderMutation = {
  usernameReminderSend: {
    ok?: boolean | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type UserRegisterMutationVariables = Exact<{
  input: UserRegisterInput;
}>;

export type UserRegisterMutation = {
  userRegister: {
    accessToken?: string | null;
    refreshToken?: string | null;
    expiresAt?: number | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type UserRegistrationQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type UserRegistrationQuery = {
  userRegistrationPerson?: {
    id: string;
    preferredName?: string | null;
    emailAddress?: string | null;
    username?: string | null;
    org?: { id: string; orgName: string } | null;
  } | null;
};

export type DigitalDisplayQueryVariables = Exact<{
  digitalDisplayId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type DigitalDisplayQuery = {
  digitalDisplays?: {
    edges: Array<{
      node?: {
        createdDate?: any | null;
        digitalDisplayId: string;
        label: string;
        token?: string | null;
        creator?: {
          pictureUrl?: string | null;
          fullNameNormalOrder?: string | null;
          initials?: string | null;
        } | null;
        settings?: {
          showLabelOnDisplay?: boolean | null;
          timeFormat?: string | null;
          timeZone?: string | null;
        } | null;
        pages?: Array<{
          multipleCalendarsEnabled?: boolean | null;
          selectionsLabel?: string | null;
          settings?: {
            backgroundImage?: string | null;
            logoOptions?: string | null;
            orientation?: string | null;
            selectionCodes?: Array<string | null> | null;
            transitionSpeed?: number | null;
            multicalSelections?: Array<{
              code?: string | null;
              key?: number | null;
              label?: string | null;
            } | null> | null;
            widgetSettings?: Array<{
              details?: string | null;
              endDatetime?: any | null;
              image?: string | null;
              position?: number | null;
              startDatetime?: any | null;
              title?: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateDigitalDisplayMutationVariables = Exact<{
  backgroundImage?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customCss?: InputMaybe<Scalars['JSONString']>;
  label: Scalars['String'];
  logoOptions?: InputMaybe<LogoOptionsEnum>;
  multicalSelections?: InputMaybe<
    | Array<InputMaybe<DigitalDisplayPageSettingsSelection>>
    | InputMaybe<DigitalDisplayPageSettingsSelection>
  >;
  orientation?: InputMaybe<OrientationEnum>;
  showLabelOnDisplay?: InputMaybe<Scalars['Boolean']>;
  timeFormat?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  transitionSpeed?: InputMaybe<Scalars['Int']>;
  widgetSettings?: InputMaybe<
    | Array<InputMaybe<DigitalDisplayWidgetSettingInput>>
    | InputMaybe<DigitalDisplayWidgetSettingInput>
  >;
}>;

export type CreateDigitalDisplayMutation = {
  digitalDisplayCreate: { digitalDisplay?: { token?: string | null } | null };
};

export type DeleteDigitalDisplayMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteDigitalDisplayMutation = {
  digitalDisplayDelete: { digitalDisplay?: { token?: string | null } | null };
};

export type UpdateDigitalDisplayMutationVariables = Exact<{
  id: Scalars['ID'];
  backgroundImage?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customCss?: InputMaybe<Scalars['JSONString']>;
  label?: InputMaybe<Scalars['String']>;
  logoOptions?: InputMaybe<LogoOptionsEnum>;
  multicalSelections?: InputMaybe<
    | Array<InputMaybe<DigitalDisplayPageSettingsSelection>>
    | InputMaybe<DigitalDisplayPageSettingsSelection>
  >;
  orientation?: InputMaybe<OrientationEnum>;
  showLabelOnDisplay?: InputMaybe<Scalars['Boolean']>;
  timeFormat?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  transitionSpeed?: InputMaybe<Scalars['Int']>;
  widgetSettings?: InputMaybe<
    | Array<InputMaybe<DigitalDisplayWidgetSettingInput>>
    | InputMaybe<DigitalDisplayWidgetSettingInput>
  >;
}>;

export type UpdateDigitalDisplayMutation = {
  digitalDisplayUpdate: { digitalDisplay?: { token?: string | null } | null };
};

export type DigitalDisplaysListQueryVariables = Exact<{ [key: string]: never }>;

export type DigitalDisplaysListQuery = {
  digitalDisplays?: {
    edges: Array<{
      node?: {
        digitalDisplayId: string;
        createdDate?: any | null;
        label: string;
        token?: string | null;
        creator?: {
          fullNameNormalOrder?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
        } | null;
        pages?: Array<{ selectionsLabel?: string | null } | null> | null;
        settings?: { showLabelOnDisplay?: boolean | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type AssignFormDrawerQueryVariables = Exact<{ [key: string]: never }>;

export type AssignFormDrawerQuery = {
  formTemplates?: {
    edges: Array<{
      formTemplate?: {
        id: string;
        label?: string | null;
        quickformTemplate: any;
        isDocusignForm?: boolean | null;
        hasFlaggedOptions?: boolean | null;
        serverFileName?: string | null;
        roles?: Array<string | null> | null;
        createdDate?: any | null;
        creator?: { displayName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
  folders?: {
    edges: Array<{
      folder?: { id: string; isEditableByUser?: boolean | null; label?: string | null } | null;
    } | null>;
  } | null;
};

export type EditAssignFormDrawerQueryVariables = Exact<{
  editingAssignmentId: Scalars['ID'];
}>;

export type EditAssignFormDrawerQuery = {
  formTemplates?: {
    edges: Array<{
      formTemplate?: {
        id: string;
        label?: string | null;
        quickformTemplate: any;
        isDocusignForm?: boolean | null;
        hasFlaggedOptions?: boolean | null;
        serverFileName?: string | null;
        roles?: Array<string | null> | null;
      } | null;
    } | null>;
  } | null;
  folders?: {
    edges: Array<{
      folder?: {
        id: string;
        isEditableByUser?: boolean | null;
        folderId: string;
        label?: string | null;
      } | null;
    } | null>;
  } | null;
  editingAssignment?:
    | {
        __typename: 'FormAssignmentNode';
        id: string;
        label?: string | null;
        assignedDateTime?: string | null;
        assignmentType?: FormAssignmentType | null;
        assignmentTimezone?: string | null;
        isActive?: boolean | null;
        isScheduled?: boolean | null;
        dueDate?: any | null;
        deactivationDateTime?: any | null;
        notes?: string | null;
        completedFormReceiptEmail?: string | null;
        externalLinkUrl?: string | null;
        quickformTemplate: any;
        assignedBy?: { personId: string; displayName?: string | null } | null;
        form?: {
          id: string;
          label?: string | null;
          roles?: Array<string | null> | null;
          hasFlaggedOptions?: boolean | null;
          isDocusignForm?: boolean | null;
        } | null;
        folder?: { id: string } | null;
        initialRoleArgs?: {
          name?: string | null;
          roleType?: string | null;
          editCompleted?: boolean | null;
          internalAssignees?: Array<
            | {
                label?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                selectionCode?: string | null;
              }
            | {
                label?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                selectionCode?: string | null;
              }
            | {
                label?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  lastName?: string | null;
                  initials?: string | null;
                  pictureUrl?: string | null;
                  selectionCode?: string | null;
                  id: string;
                  label?: string | null;
                } | null> | null;
              }
            | {
                label?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                selectionCode?: string | null;
              }
            | null
          > | null;
          externalAssignees?: Array<{ name?: string | null; email?: string | null } | null> | null;
        } | null;
        additionalRoleArgs?: Array<{
          name?: string | null;
          roleType?: string | null;
          roleOrder?: number | null;
          editCompleted?: boolean | null;
          chooserRoleOrder?: number | null;
          chooserAllowUser?: boolean | null;
          chooserAllowExternal?: boolean | null;
          chooserRestrictedToPersonTypes?: Array<string | null> | null;
          externalAssignees?: Array<{ name?: string | null; email?: string | null } | null> | null;
          personSelection?:
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | null;
        } | null> | null;
        resultsTo?: {
          viewResultsInEmail?: Array<string | null> | null;
          viewResultsInTeamworks?: Array<
            | {
                label?: string | null;
                selectionCode?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                people?: Array<{
                  lastName?: string | null;
                  initials?: string | null;
                  pictureUrl?: string | null;
                  selectionCode?: string | null;
                  id: string;
                  label?: string | null;
                } | null> | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
              }
            | null
          > | null;
        } | null;
        additionalResultsTo?: Array<{
          viewResultsInEmail?: Array<string | null> | null;
          completersToView?: Array<
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | null
          > | null;
          viewResultsInTeamworks?: Array<
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | null
          > | null;
        } | null> | null;
        flaggedFormAlerts?: {
          nextStepsMsgType?: string | null;
          flaggedNextStepsMsg?: string | null;
          nonflaggedNextStepsMsg?: string | null;
          flaggedAnswerAlertType?: string | null;
          answerAlertSelections?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
        } | null;
      }
    | {
        __typename: 'FormScheduleNode';
        id: string;
        label?: string | null;
        notes?: string | null;
        notifyAssignees?: NotifyAssignees | null;
        scheduleStart: any;
        recurRule: string;
        recurRuleText: string;
        assignmentTimezone?: string | null;
        relativeDueDateNumber?: number | null;
        relativeDueDateType?: RelativeDateType | null;
        relativeDueDateTime?: string | null;
        relativeDeactivationDateTimeNumber?: number | null;
        relativeDeactivationDateTimeType?: RelativeDateType | null;
        relativeDeactivationDateTimeTime?: string | null;
        parameters?: any | null;
        formScheduleType?: string | null;
        creator?: { personId: string; displayName?: string | null } | null;
        form?: {
          id: string;
          label?: string | null;
          roles?: Array<string | null> | null;
          hasFlaggedOptions?: boolean | null;
          isDocusignForm?: boolean | null;
        } | null;
        folder?: { id: string } | null;
        initialRoleArgs?: {
          name?: string | null;
          roleType?: string | null;
          editCompleted?: boolean | null;
          internalAssignees?: Array<
            | {
                label?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                selectionCode?: string | null;
              }
            | {
                label?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                selectionCode?: string | null;
              }
            | {
                label?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  lastName?: string | null;
                  initials?: string | null;
                  pictureUrl?: string | null;
                  selectionCode?: string | null;
                  id: string;
                  label?: string | null;
                } | null> | null;
              }
            | {
                label?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                selectionCode?: string | null;
              }
            | null
          > | null;
          externalAssignees?: Array<{ name?: string | null; email?: string | null } | null> | null;
        } | null;
        additionalRoleArgs?: Array<{
          name?: string | null;
          roleType?: string | null;
          roleOrder?: number | null;
          editCompleted?: boolean | null;
          chooserRoleOrder?: number | null;
          chooserAllowUser?: boolean | null;
          chooserAllowExternal?: boolean | null;
          chooserRestrictedToPersonTypes?: Array<string | null> | null;
          externalAssignees?: Array<{ name?: string | null; email?: string | null } | null> | null;
          personSelection?:
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | null;
        } | null> | null;
        resultsTo?: {
          viewResultsInEmail?: Array<string | null> | null;
          viewResultsInTeamworks?: Array<
            | {
                label?: string | null;
                selectionCode?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                people?: Array<{
                  lastName?: string | null;
                  initials?: string | null;
                  pictureUrl?: string | null;
                  selectionCode?: string | null;
                  id: string;
                  label?: string | null;
                } | null> | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                groupType?: GroupType | null;
                pluralLabel?: string | null;
              }
            | null
          > | null;
        } | null;
        additionalResultsTo?: Array<{
          viewResultsInEmail?: Array<string | null> | null;
          completersToView?: Array<
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | null
          > | null;
          viewResultsInTeamworks?: Array<
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                pluralLabel?: string | null;
                groupType?: GroupType | null;
                label?: string | null;
                selectionCode?: string | null;
              }
            | null
          > | null;
        } | null> | null;
        flaggedFormAlerts?: {
          nextStepsMsgType?: string | null;
          flaggedNextStepsMsg?: string | null;
          nonflaggedNextStepsMsg?: string | null;
          flaggedAnswerAlertType?: string | null;
          answerAlertSelections?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
        } | null;
      }
    | {}
    | null;
};

export type FormAssignMutationVariables = Exact<{
  input?: InputMaybe<AssignmentArgsInput>;
}>;

export type FormAssignMutation = {
  assign: {
    formAssignment?: { id: string; label?: string | null; externalLinkUrl?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type CreateFormLinkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateFormLinkMutation = {
  formAssignmentCreateSharedLink: {
    formAssignment?: { id: string; externalLinkUrl?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type DeleteFormLinkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFormLinkMutation = {
  formAssignmentDeleteSharedLink: {
    formAssignment?: { id: string; label?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type UpdateFormAssignmentMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateAssignmentArgsInput;
}>;

export type UpdateFormAssignmentMutation = {
  formAssignmentUpdate: {
    formAssignment?: { id: string; label?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type FormScheduleSaveMutationVariables = Exact<{
  input?: InputMaybe<SaveFormScheduleArgsInput>;
}>;

export type FormScheduleSaveMutation = {
  formScheduleSave: {
    formSchedule?: { id: string; label?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type ExternalFetchDocusignRecipientUrlQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ExternalFetchDocusignRecipientUrlQuery = {
  externalCompletionRole?: {
    __typename: 'FormAssignmentCompletionRoleNode';
    retrieveAsyncRecipientTokenUrl?: string | null;
  } | null;
};

export type InternalFetchDocusignRecipientUrlQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InternalFetchDocusignRecipientUrlQuery = {
  completionRole?:
    | {
        __typename: 'FormAssignmentCompletionRoleNode';
        retrieveAsyncRecipientTokenUrl?: string | null;
      }
    | {}
    | null;
};

export type AssignFormMutationVariables = Exact<{
  input: ChooseRolesInput;
}>;

export type AssignFormMutation = {
  formAssignmentChooseRoles: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type ExternalCompletionCreateMutationVariables = Exact<{
  input?: InputMaybe<ExternalCompletionCreateInput>;
}>;

export type ExternalCompletionCreateMutation = {
  externalCompletionCreate: {
    link?: string | null;
    formCompletionRole?: {
      id: string;
      completion?: {
        assignment?: {
          id: string;
          label?: string | null;
          dueDate?: any | null;
          notes?: string | null;
          isDocusignForm?: boolean | null;
          quickformTemplate: any;
          assignerEmail?: string | null;
          assignedBy?: { id: string; displayName?: string | null } | null;
        } | null;
      } | null;
      workflow?: Array<{
        id: string;
        status?: string | null;
        updatedDate?: any | null;
        externalName?: string | null;
        person?: { id: string; displayName?: string | null } | null;
      } | null> | null;
    } | null;
  };
};

export type ExternalFormCompletionQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ExternalFormCompletionQuery = {
  externalFormAssignment?: { id: string; label?: string | null; dueDate?: any | null } | null;
  externalCompletionRole?: {
    id: string;
    status?: string | null;
    externalName?: string | null;
    externalEmail?: string | null;
    initiateAsyncRecipientTokenUrlFetch?: string | null;
    completion?: {
      signedDownloadUrl?: string | null;
      reassignmentNotes?: string | null;
      assignment?: {
        id: string;
        pk?: string | null;
        label?: string | null;
        dueDate?: any | null;
        assignedDateTime?: string | null;
        notes?: string | null;
        isDocusignForm?: boolean | null;
        quickformTemplate: any;
        assignerEmail?: string | null;
        assignedBy?: { id: string; displayName?: string | null } | null;
      } | null;
    } | null;
    completionRolesAssignedByCompleter?: Array<{
      id: string;
      hasRoleBeenChosen?: boolean | null;
      assignmentRole?: {
        id: string;
        chooserRestrictedToPersonTypes?: Array<string | null> | null;
        chooserAllowExternal?: boolean | null;
        roleName?: string | null;
        roleOrder: number;
      } | null;
    } | null> | null;
    workflow?: Array<{
      id: string;
      status?: string | null;
      updatedDate?: any | null;
      externalName?: string | null;
      person?: { id: string; displayName?: string | null } | null;
    } | null> | null;
  } | null;
};

export type InternalFormCompletionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InternalFormCompletionQuery = {
  completionRole?:
    | {
        __typename: 'FormAssignmentCompletionRoleNode';
        id: string;
        status?: string | null;
        initiateAsyncRecipientTokenUrlFetch?: string | null;
        completion?: {
          reassignmentNotes?: string | null;
          assignment?: {
            id: string;
            label?: string | null;
            dueDate?: any | null;
            assignedDateTime?: string | null;
            notes?: string | null;
            isDocusignForm?: boolean | null;
            quickformTemplate: any;
            assignerEmail?: string | null;
            assignedBy?: { id: string; displayName?: string | null } | null;
          } | null;
        } | null;
        completionRolesAssignedByCompleter?: Array<{
          id: string;
          hasRoleBeenChosen?: boolean | null;
          assignmentRole?: {
            id: string;
            chooserRestrictedToPersonTypes?: Array<string | null> | null;
            chooserAllowExternal?: boolean | null;
            roleName?: string | null;
            roleOrder: number;
          } | null;
        } | null> | null;
        workflow?: Array<{
          id: string;
          status?: string | null;
          updatedDate?: any | null;
          externalName?: string | null;
          person?: { id: string; displayName?: string | null } | null;
        } | null> | null;
      }
    | {}
    | null;
};

export type CompleteFormMutationVariables = Exact<{
  input: CompleteFormInput;
}>;

export type CompleteFormMutation = {
  completeForm: { formAssignmentCompletionRole?: { completedDateTime?: any | null } | null };
};

export type FilterFormCompletionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  isCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sort?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionSortEnum>>
    | InputMaybe<FormAssignmentCompletionSortEnum>
  >;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchAssignees?: InputMaybe<Scalars['String']>;
  searchInAnswers?: InputMaybe<Scalars['String']>;
  searchOptions?: InputMaybe<Array<InputMaybe<SearchOptionsEnum>> | InputMaybe<SearchOptionsEnum>>;
  lastUpdatedDateStart?: InputMaybe<Scalars['Date']>;
  lastUpdatedDateEnd?: InputMaybe<Scalars['Date']>;
  submittableFormAssignmentId: Scalars['ID'];
  teamId?: InputMaybe<Scalars['Int']>;
  manuallyCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  generatePdfs?: InputMaybe<Scalars['Boolean']>;
}>;

export type FilterFormCompletionsQuery = {
  filterFormCompletions?: {
    __typename: 'FormAssignmentCompletionConnection';
    totalCount?: number | null;
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        signedDownloadUrl?: string | null;
        pk?: string | null;
        docusignEnvelopeId?: string | null;
        completedDateTime?: any | null;
        status?: string | null;
        hasFlaggedAnswers: boolean;
        isCompleted?: boolean | null;
        isMostRecent?: boolean | null;
        isLinkCompletion?: boolean | null;
        hasBeenReassigned?: boolean | null;
        manuallyCompleted?: boolean | null;
        manuallyCompletedExplanation?: string | null;
        key: string;
        assignment?: {
          assignmentType?: FormAssignmentType | null;
          assignedBy?: { pk?: string | null } | null;
          assignees?: Array<
            | {
                label?: string | null;
                selectionCode?: string | null;
                peopleCount?: number | null;
                pluralLabel?: string | null;
                groupType?: GroupType | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                peopleCount?: number | null;
                pluralLabel?: string | null;
                groupType?: GroupType | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                peopleCount?: number | null;
                pluralLabel?: string | null;
                groupType?: GroupType | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                peopleCount?: number | null;
                pluralLabel?: string | null;
                groupType?: GroupType | null;
              }
            | null
          > | null;
        } | null;
        workflow?: Array<{
          id: string;
          pk?: number | null;
          status?: string | null;
          assignedDateTime?: any | null;
          completedDateTime?: any | null;
          updatedDate?: any | null;
          externalName?: string | null;
          externalEmail?: string | null;
          roleOrder?: number | null;
          roleName?: string | null;
          person?: {
            pk?: string | null;
            displayName?: string | null;
            emailAddress?: string | null;
            initials?: string | null;
            picture?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type AllFormAssignmentCompletionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  isCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sort?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionSortEnum>>
    | InputMaybe<FormAssignmentCompletionSortEnum>
  >;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchInAnswers?: InputMaybe<Scalars['String']>;
  lastUpdatedDateStart?: InputMaybe<Scalars['Date']>;
  lastUpdatedDateEnd?: InputMaybe<Scalars['Date']>;
  submittableFormAssignmentId: Scalars['ID'];
  teamId?: InputMaybe<Scalars['Int']>;
  hasAccessibleAnswers?: InputMaybe<Scalars['TWFilter_Boolean']>;
}>;

export type AllFormAssignmentCompletionsQuery = {
  formAssignmentCompletions?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        completedDateTime?: any | null;
        status?: string | null;
        hasFlaggedAnswers: boolean;
        isCompleted?: boolean | null;
        isLinkCompletion?: boolean | null;
        hasBeenReassigned?: boolean | null;
        manuallyCompleted?: boolean | null;
        manuallyCompletedExplanation?: string | null;
        signedDownloadUrl?: string | null;
        key: string;
        completionId?: string | null;
        completedAnswers?: Array<{
          id: string;
          fieldOrder?: number | null;
          fieldName?: string | null;
          fieldAnswerList?: Array<string | null> | null;
          fieldType?: string | null;
        } | null> | null;
        workflow?: Array<{
          pk?: number | null;
          status?: string | null;
          assignedDateTime?: any | null;
          completedDateTime?: any | null;
          externalName?: string | null;
          externalEmail?: string | null;
          roleOrder?: number | null;
          roleName?: string | null;
          person?: {
            pk?: string | null;
            displayName?: string | null;
            picture?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FormAssignmentCompletionBulkManuallyCompleteMutationVariables = Exact<{
  input: FormAssignmentCompletionBulkManualCompleteInput;
}>;

export type FormAssignmentCompletionBulkManuallyCompleteMutation = {
  formAssignmentCompletionBulkManualComplete: {
    formAssignmentCompletion?: Array<{
      id: string;
      isCompleted?: boolean | null;
      manuallyCompleted?: boolean | null;
      manuallyCompletedExplanation?: string | null;
      status?: string | null;
    } | null> | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type FetchCompletedFormResultsQueryVariables = Exact<{
  isCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  submittableFormAssignmentId: Scalars['ID'];
  teamId?: InputMaybe<Scalars['Int']>;
  searchInAnswers?: InputMaybe<Scalars['String']>;
  lastUpdatedDateStart?: InputMaybe<Scalars['Date']>;
  lastUpdatedDateEnd?: InputMaybe<Scalars['Date']>;
}>;

export type FetchCompletedFormResultsQuery = {
  formAssignmentCompletions?: {
    edges: Array<{
      node?: {
        completedDateTime?: any | null;
        manuallyCompleted?: boolean | null;
        parentCompletionId?: number | null;
        pk?: string | null;
        signedDownloadUrl?: string | null;
        key: string;
        workflow?: Array<{
          completedDateTime?: any | null;
          externalName?: string | null;
          externalEmail?: string | null;
          status?: string | null;
          person?: { firstName?: string | null; lastName?: string | null } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchFormAnswersQueryVariables = Exact<{
  formAssignmentCompletionId: Scalars['ID'];
  sort?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionAnswerSortEnum>>
    | InputMaybe<FormAssignmentCompletionAnswerSortEnum>
  >;
}>;

export type FetchFormAnswersQuery = {
  formAssignmentCompletionAnswers?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        pk?: string | null;
        id: string;
        fieldOrder?: number | null;
        fieldName?: string | null;
        fieldAnswerList?: Array<string | null> | null;
        fieldType?: string | null;
      } | null;
    } | null>;
    unansweredQuestions?: Array<{
      fieldOrder: number;
      fieldName: string;
      fieldAnswerList?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type FetchFormAssigneesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  submittableFormAssignmentId: Scalars['ID'];
}>;

export type FetchFormAssigneesQuery = {
  formAssignmentCompletions?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        isCompleted?: boolean | null;
        key: string;
        completionId?: string | null;
        workflow?: Array<{
          pk?: number | null;
          externalName?: string | null;
          externalEmail?: string | null;
          roleOrder?: number | null;
          roleName?: string | null;
          person?: {
            pk?: string | null;
            displayName?: string | null;
            picture?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FormAssignmentCompletionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  isCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
  sort?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionSortEnum>>
    | InputMaybe<FormAssignmentCompletionSortEnum>
  >;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  searchInAnswers?: InputMaybe<Scalars['String']>;
  lastUpdatedDateStart?: InputMaybe<Scalars['Date']>;
  lastUpdatedDateEnd?: InputMaybe<Scalars['Date']>;
  submittableFormAssignmentId: Scalars['ID'];
  teamId?: InputMaybe<Scalars['Int']>;
  manuallyCompleted?: InputMaybe<Scalars['TWFilter_Boolean']>;
}>;

export type FormAssignmentCompletionsQuery = {
  formAssignmentCompletions?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        signedDownloadUrl?: string | null;
        pk?: string | null;
        completedDateTime?: any | null;
        status?: string | null;
        hasFlaggedAnswers: boolean;
        isCompleted?: boolean | null;
        isMostRecent?: boolean | null;
        isLinkCompletion?: boolean | null;
        hasBeenReassigned?: boolean | null;
        manuallyCompleted?: boolean | null;
        manuallyCompletedExplanation?: string | null;
        key: string;
        assignment?: {
          assignmentType?: FormAssignmentType | null;
          assignedBy?: { pk?: string | null } | null;
          assignees?: Array<
            | {
                label?: string | null;
                selectionCode?: string | null;
                peopleCount?: number | null;
                pluralLabel?: string | null;
                groupType?: GroupType | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                peopleCount?: number | null;
                pluralLabel?: string | null;
                groupType?: GroupType | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                peopleCount?: number | null;
                pluralLabel?: string | null;
                groupType?: GroupType | null;
              }
            | {
                label?: string | null;
                selectionCode?: string | null;
                peopleCount?: number | null;
                pluralLabel?: string | null;
                groupType?: GroupType | null;
              }
            | null
          > | null;
        } | null;
        workflow?: Array<{
          id: string;
          pk?: number | null;
          status?: string | null;
          assignedDateTime?: any | null;
          completedDateTime?: any | null;
          updatedDate?: any | null;
          externalName?: string | null;
          externalEmail?: string | null;
          roleOrder?: number | null;
          roleName?: string | null;
          person?: {
            pk?: string | null;
            displayName?: string | null;
            emailAddress?: string | null;
            initials?: string | null;
            picture?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchResultsDetailsQueryVariables = Exact<{
  pk?: InputMaybe<Scalars['TWFilter_String']>;
}>;

export type FetchResultsDetailsQuery = {
  manageFormAssignments?: {
    edges: Array<{
      node?: {
        id: string;
        assignedDateTime?: string | null;
        deactivationDateTime?: any | null;
        dueDate?: any | null;
        label?: string | null;
        formLabel?: string | null;
        isDocusignForm?: boolean | null;
        notes?: string | null;
        assignmentType?: FormAssignmentType | null;
        isActive?: boolean | null;
        isScheduled?: boolean | null;
        hasExternalAssignees?: boolean | null;
        key?: string | null;
        formId?: string | null;
        form?: { pk?: string | null; docusignTemplateId?: string | null } | null;
        assignedBy?: {
          id: string;
          displayName?: string | null;
          initials?: string | null;
          picture?: string | null;
        } | null;
        assignmentRoles?: Array<{
          pk?: string | null;
          roleName?: string | null;
          roleOrder: number;
        } | null> | null;
        assignees?: Array<
          | {
              label?: string | null;
              selectionCode?: string | null;
              peopleCount?: number | null;
              pluralLabel?: string | null;
              groupType?: GroupType | null;
            }
          | {
              label?: string | null;
              selectionCode?: string | null;
              peopleCount?: number | null;
              pluralLabel?: string | null;
              groupType?: GroupType | null;
            }
          | {
              label?: string | null;
              selectionCode?: string | null;
              peopleCount?: number | null;
              pluralLabel?: string | null;
              groupType?: GroupType | null;
            }
          | {
              label?: string | null;
              selectionCode?: string | null;
              peopleCount?: number | null;
              pluralLabel?: string | null;
              groupType?: GroupType | null;
            }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type FormResultsReportQueryVariables = Exact<{ [key: string]: never }>;

export type FormResultsReportQuery = {
  formResultsReport?: {
    edges: Array<{
      node?: {
        createdDate?: any | null;
        downloadableFileId: string;
        errorMessage?: string | null;
        kind: string;
        status: string;
        title?: string | null;
        url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type FormResultsReportCreateMutationVariables = Exact<{
  input?: InputMaybe<FormResultsReportCreateInput>;
}>;

export type FormResultsReportCreateMutation = {
  formResultsReportCreate: {
    formResultsReport?: {
      createdDate?: any | null;
      downloadableFileId: string;
      status: string;
      title?: string | null;
      url?: string | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type FormAssignmentCompletionManualCompleteMutationVariables = Exact<{
  id: Scalars['ID'];
  explanation: Scalars['String'];
}>;

export type FormAssignmentCompletionManualCompleteMutation = {
  formAssignmentCompletionManualComplete: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    formAssignmentCompletion?: {
      isCompleted?: boolean | null;
      manuallyCompleted?: boolean | null;
    } | null;
  };
};

export type FetchOrgTeamsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<TeamSortEnum>> | InputMaybe<TeamSortEnum>>;
}>;

export type FetchOrgTeamsQuery = {
  teams?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: { label: string; teamId: string; sportId?: number | null; key: string } | null;
    } | null>;
  } | null;
};

export type FormAssignmentReassignMutationVariables = Exact<{
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
}>;

export type FormAssignmentReassignMutation = {
  formAssignmentCompletionReassign: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    formAssignmentCompletion?: { pk?: string | null } | null;
  };
};

export type SendIndividualReminderMutationVariables = Exact<{
  id: Scalars['ID'];
  reminderType: FormReminderTypeGraphene;
}>;

export type SendIndividualReminderMutation = {
  formAssignmentCompletionRoleSendReminder: {
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type FormAssignmentSendRemindersAllMutationVariables = Exact<{
  id: Scalars['ID'];
  reminderType: FormReminderTypeGraphene;
}>;

export type FormAssignmentSendRemindersAllMutation = {
  formAssignmentSendReminders: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type FormsCreateFolderMutationVariables = Exact<{
  folderName: Scalars['String'];
  folderType: FolderTypeGraphene;
}>;

export type FormsCreateFolderMutation = {
  folderCreate: {
    folder?: {
      orgId: number;
      folderName?: string | null;
      folderType?: string | null;
      sortOrder?: number | null;
    } | null;
  };
};

export type BulkDeactivateAssignmentsMutationVariables = Exact<{
  input: BulkDeactivateAssignmentArgsInput;
}>;

export type BulkDeactivateAssignmentsMutation = {
  formAssignmentBulkDeactivate: {
    formAssignment?: Array<{ id: string; isActive?: boolean | null } | null> | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type FolderDeleteByIdMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FolderDeleteByIdMutation = {
  folderDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type FormScheduleDeleteMutationVariables = Exact<{
  formScheduleId: Scalars['ID'];
}>;

export type FormScheduleDeleteMutation = {
  formScheduleDelete: {
    formSchedule?: { isActive: boolean; pk?: string | null } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type FormAssignmentsAssignersQueryVariables = Exact<{
  isActive?: InputMaybe<Scalars['Boolean']>;
  isScheduled?: InputMaybe<Scalars['Boolean']>;
  isMyForms?: InputMaybe<Scalars['Boolean']>;
  assignmentType?: InputMaybe<Scalars['String']>;
}>;

export type FormAssignmentsAssignersQuery = {
  formAssignmentsAssigners?: Array<{ value?: string | null; label?: string | null } | null> | null;
};

export type FetchFormFoldersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type FetchFormFoldersQuery = {
  folders?: {
    edges: Array<{
      node?: {
        folderId: string;
        folderName?: string | null;
        isEditableByUser?: boolean | null;
      } | null;
    } | null>;
  } | null;
};

export type FormTemplatesCountQueryVariables = Exact<{ [key: string]: never }>;

export type FormTemplatesCountQuery = { formTemplates?: { totalCount?: number | null } | null };

export type FetchMyAssignedFormsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<FormAssignmentSortEnum>> | InputMaybe<FormAssignmentSortEnum>>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  folderId?: InputMaybe<Scalars['TWFilter_Int']>;
  templateId?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type FetchMyAssignedFormsQuery = {
  manageFormAssignments?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        quickformTemplate: any;
        assignedDateTime?: string | null;
        createdDate?: any | null;
        dueDate?: any | null;
        label?: string | null;
        formLabel?: string | null;
        isActive?: boolean | null;
        isEditable?: boolean | null;
        isDocusignForm?: boolean | null;
        notes?: string | null;
        assignmentType?: FormAssignmentType | null;
        isScheduled?: boolean | null;
        externalLinkUrl?: string | null;
        key?: string | null;
        formId?: string | null;
        assignedBy?: {
          id: string;
          displayName?: string | null;
          initials?: string | null;
          personId: string;
          picture?: string | null;
        } | null;
        resultsTo?: {
          viewResultsInTeamworks?: Array<
            | { personId: string }
            | { people?: Array<{ personId: string } | null> | null }
            | {}
            | null
          > | null;
        } | null;
        additionalResultsTo?: Array<{
          viewResultsInTeamworks?: Array<
            | { personId: string }
            | { people?: Array<{ personId: string } | null> | null }
            | {}
            | null
          > | null;
        } | null> | null;
        folder?: { folderId: string; folderName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchMyAssignedFormTemplatesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type FetchMyAssignedFormTemplatesQuery = {
  formTemplates?: {
    totalCount?: number | null;
    edges: Array<{ node?: { label?: string | null; templateId?: string | null } | null } | null>;
  } | null;
};

export type ManageFormScheduleAssignmentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<FormScheduleSortEnum>> | InputMaybe<FormScheduleSortEnum>>;
  templateId?: InputMaybe<Scalars['TWFilter_Int']>;
  isActive?: InputMaybe<Scalars['TWFilter_Boolean']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  folderId?: InputMaybe<Scalars['Int']>;
}>;

export type ManageFormScheduleAssignmentsQuery = {
  manageFormScheduleAssignments?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        label?: string | null;
        recurRule: string;
        recurRuleText: string;
        scheduleStart: any;
        createdDate?: any | null;
        canEdit?: boolean | null;
        isDocusignForm?: boolean | null;
        parameters?: any | null;
        isActive: boolean;
        key: string;
        formScheduleId?: string | null;
        templateLabel?: string | null;
        folder?: { folderId: string; folderName?: string | null } | null;
        creator?: {
          displayName?: string | null;
          initials?: string | null;
          preferredName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName?: string | null;
          picture?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type UpdateeFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  folderName: Scalars['String'];
}>;

export type UpdateeFolderMutation = {
  folderUpdate: {
    folder?: {
      orgId: number;
      folderName?: string | null;
      folderType?: string | null;
      sortOrder?: number | null;
    } | null;
  };
};

export type FetchFormCompletionByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchFormCompletionByIdQuery = {
  completionRole?:
    | {
        __typename: 'FormAssignmentCompletionRoleNode';
        id: string;
        status?: string | null;
        externalName?: string | null;
        externalEmail?: string | null;
        isExternalRole?: boolean | null;
        roleOrder?: number | null;
        roleName?: string | null;
        person?: {
          id: string;
          displayName?: string | null;
          selectionCode?: string | null;
          label?: string | null;
        } | null;
        assignmentRole?: {
          id: string;
          chooserRestrictedToPersonTypes?: Array<string | null> | null;
          chooserAllowExternal?: boolean | null;
          chooserAllowUser?: boolean | null;
          roleName?: string | null;
          roleOrder: number;
          roleType: FormAssignmentRoleType;
        } | null;
        completion?: {
          reassignmentNotes?: string | null;
          assignment?: {
            id: string;
            label?: string | null;
            dueDate?: any | null;
            notes?: string | null;
            isDocusignForm?: boolean | null;
            quickformTemplate: any;
            assignerEmail?: string | null;
            assignedBy?: { id: string; displayName?: string | null } | null;
          } | null;
        } | null;
        completionRolesAssignedByCompleter?: Array<{
          id: string;
          assignmentRole?: {
            id: string;
            chooserRestrictedToPersonTypes?: Array<string | null> | null;
            chooserAllowExternal?: boolean | null;
            chooserAllowUser?: boolean | null;
            roleName?: string | null;
            roleOrder: number;
          } | null;
        } | null> | null;
        workflow?: Array<{
          id: string;
          status?: string | null;
          roleName?: string | null;
          roleOrder?: number | null;
          updatedDate?: any | null;
          externalName?: string | null;
          externalEmail?: string | null;
          person?: {
            id: string;
            displayName?: string | null;
            selectionCode?: string | null;
          } | null;
        } | null> | null;
      }
    | {}
    | null;
};

export type CreatePerpetualFormCompletionMutationVariables = Exact<{
  input?: InputMaybe<PrepareForSignatureArgsInput>;
}>;

export type CreatePerpetualFormCompletionMutation = {
  prepareForSignature: {
    link?: string | null;
    myCompletionRole?: { id: string; status?: string | null; pk?: number | null } | null;
    completion?: {
      completedDateTime?: any | null;
      formAssignmentId: number;
      manuallyCompleted?: boolean | null;
      isCompleted?: boolean | null;
      pk?: string | null;
      assignment?: {
        label?: string | null;
        dueDate?: any | null;
        isActive?: boolean | null;
        notes?: string | null;
        assignmentType?: FormAssignmentType | null;
        isEditable?: boolean | null;
        isDocusignForm?: boolean | null;
        quickformTemplate: any;
        assignedBy?: { fullName?: string | null } | null;
      } | null;
      workflow?: Array<{
        externalName?: string | null;
        person?: { fullName?: string | null } | null;
      } | null> | null;
    } | null;
  };
};

export type FormAssignmentCompletionReassignMutationVariables = Exact<{
  id: Scalars['ID'];
  input: FormAssignmentCompletionReassignInput;
}>;

export type FormAssignmentCompletionReassignMutation = {
  formAssignmentCompletionReassign: {
    formAssignmentCompletion?: {
      id: string;
      viewerAssignmentCompletionRole?: { id: string } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type GetFormDownloadPdfMutationVariables = Exact<{
  id: Scalars['ID'];
  input: FormAssignmentCompletionFetchPdfInput;
}>;

export type GetFormDownloadPdfMutation = {
  formAssignmentCompletionFetchPdf: {
    formAssignmentCompletion?: { id: string; signedDownloadUrl?: string | null } | null;
  };
};

export type DeleteFormMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type DeleteFormMutation = {
  formAssignmentCompletionDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type FetchMyFormsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
  status?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionRoleStatusGraphene>>
    | InputMaybe<FormAssignmentCompletionRoleStatusGraphene>
  >;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionRoleSortEnum>>
    | InputMaybe<FormAssignmentCompletionRoleSortEnum>
  >;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  dueDateStatus?: InputMaybe<Scalars['String']>;
  assignerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  showConditional?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchMyFormsQuery = {
  myFormAssignments?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      formAssignmentCompletionRole?: {
        assignedDateTime?: any | null;
        completedDateTime?: any | null;
        updatedDate?: any | null;
        createdBy?: number | null;
        key: string;
        formId?: number | null;
        formStatus?: string | null;
        assignmentRole?: { roleLabel?: string | null } | null;
        completion?: {
          hasBeenReassigned?: boolean | null;
          signedDownloadUrl?: string | null;
          allCompleted?: boolean | null;
          reassignedBy?: FormAssignmentCompletionReassignedByType | null;
          completedDateTime?: any | null;
          createdDateTime?: string | null;
          manuallyCompleted?: boolean | null;
          status?: string | null;
          formAssignmentCompletionId?: string | null;
          reassignmentNotes?: string | null;
          assignment?: {
            id: string;
            notes?: string | null;
            isScheduled?: boolean | null;
            isDocusignForm?: boolean | null;
            quickformTemplate: any;
            assignmentType?: FormAssignmentType | null;
            assignerEmail?: string | null;
            assignmentId?: string | null;
            formTitle?: string | null;
            dueDateTime?: any | null;
            form?: { formLabel?: string | null } | null;
            assignedBy?: {
              id: string;
              pk?: string | null;
              displayName?: string | null;
              initials?: string | null;
              picture?: string | null;
            } | null;
          } | null;
          manuallyCompletedBy?: {
            pk?: string | null;
            displayName?: string | null;
            initials?: string | null;
            picture?: string | null;
          } | null;
          creator?: {
            pk?: string | null;
            displayName?: string | null;
            initials?: string | null;
            picture?: string | null;
          } | null;
          workflow?: Array<{
            completedDateTime?: any | null;
            status?: string | null;
            roleName?: string | null;
            roleOrder?: number | null;
            person?: { pk?: string | null } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchMyFormsCountQueryVariables = Exact<{
  status?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionRoleStatusGraphene>>
    | InputMaybe<FormAssignmentCompletionRoleStatusGraphene>
  >;
  showConditional?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchMyFormsCountQuery = { myFormAssignments?: { totalCount?: number | null } | null };

export type FetchMyFormDetailsQueryVariables = Exact<{
  pk: Scalars['TWFilter_String'];
  showConditional: Scalars['Boolean'];
}>;

export type FetchMyFormDetailsQuery = {
  myFormAssignments?: {
    edges: Array<{
      formAssignmentCompletionRole?: {
        id: string;
        canEditResponse?: boolean | null;
        canDeleteCompletion?: boolean | null;
        status?: string | null;
        completion?: {
          isMostRecent?: boolean | null;
          id: string;
          signedDownloadUrl?: string | null;
          allCompleted?: boolean | null;
          manuallyCompleted?: boolean | null;
          formAssignmentCompletionId?: string | null;
          reassignmentNotes?: string | null;
          assignment?: {
            id: string;
            isDocusignForm?: boolean | null;
            assignmentType?: FormAssignmentType | null;
            formDescription?: string | null;
            formTitle?: string | null;
            assignmentId?: string | null;
            form?: { formLabel?: string | null } | null;
            assignedBy?: {
              id: string;
              pk?: string | null;
              displayName?: string | null;
              initials?: string | null;
              picture?: string | null;
            } | null;
          } | null;
          manuallyCompletedBy?: {
            pk?: string | null;
            displayName?: string | null;
            initials?: string | null;
            id: string;
            picture?: string | null;
          } | null;
          viewerAssignmentCompletionRole?: {
            id: string;
            assignmentRole?: {
              id: string;
              chooserRestrictedToPersonTypes?: Array<string | null> | null;
              chooserAllowExternal?: boolean | null;
              roleName?: string | null;
              roleOrder: number;
            } | null;
          } | null;
        } | null;
        workflow?: Array<{
          assignedDateTime?: any | null;
          completedDateTime?: any | null;
          updatedDate?: any | null;
          roleOrder?: number | null;
          roleName?: string | null;
          pk?: number | null;
          externalName?: string | null;
          externalEmail?: string | null;
          id: string;
          status?: string | null;
          person?: {
            id: string;
            pk?: string | null;
            displayName?: string | null;
            initials?: string | null;
            picture?: string | null;
          } | null;
        } | null> | null;
        completionRolesAssignedByCompleter?: Array<{
          id: string;
          assignmentRole?: {
            id: string;
            chooserAllowExternal?: boolean | null;
            chooserAllowUser?: boolean | null;
            chooserRestrictedToPersonTypes?: Array<string | null> | null;
            chooserRoleOrder?: number | null;
            roleName?: string | null;
            roleOrder: number;
            roleType: FormAssignmentRoleType;
            internalAssignees?: Array<
              | {
                  label?: string | null;
                  selectionCode?: string | null;
                  peopleCount?: number | null;
                  pluralLabel?: string | null;
                  groupType?: GroupType | null;
                }
              | {
                  label?: string | null;
                  selectionCode?: string | null;
                  peopleCount?: number | null;
                  pluralLabel?: string | null;
                  groupType?: GroupType | null;
                }
              | {
                  label?: string | null;
                  selectionCode?: string | null;
                  peopleCount?: number | null;
                  pluralLabel?: string | null;
                  groupType?: GroupType | null;
                }
              | {
                  label?: string | null;
                  selectionCode?: string | null;
                  peopleCount?: number | null;
                  pluralLabel?: string | null;
                  groupType?: GroupType | null;
                }
              | null
            > | null;
            externalAssignees?: Array<{
              name?: string | null;
              email?: string | null;
            } | null> | null;
            assignment?: {
              assignedBy?: {
                id: string;
                pk?: string | null;
                displayName?: string | null;
                initials?: string | null;
                picture?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchMyPerpetualFormsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<
    Array<InputMaybe<FormAssignmentRoleSortEnum>> | InputMaybe<FormAssignmentRoleSortEnum>
  >;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  showConditional?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchMyPerpetualFormsQuery = {
  myPerpetualFormAssignments?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      formAssignmentRole?: {
        assignedDateTime?: any | null;
        key: string;
        assignmentRoleId?: string | null;
        roleLabel?: string | null;
        assignment?: {
          assignmentType?: FormAssignmentType | null;
          isScheduled?: boolean | null;
          formAssignmentId?: string | null;
          formTitle?: string | null;
          formDescription?: string | null;
          dueDateTime?: any | null;
          assignedBy?: {
            pk?: string | null;
            displayName?: string | null;
            initials?: string | null;
            picture?: string | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type UserFormStatsQueryVariables = Exact<{ [key: string]: never }>;

export type UserFormStatsQuery = {
  userFormStats?: {
    formsExpiringSoon: number;
    formsCreatedRecently: number;
    allActiveForms: number;
    overdueForms: number;
  } | null;
};

export type QuickFormAssignmentTemplateUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: QuickFormTemplateInput;
}>;

export type QuickFormAssignmentTemplateUpdateMutation = {
  quickFormAssignmentTemplateUpdate: {
    formAssignment?: { id: string; label?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type QuickFormTemplateCreateMutationVariables = Exact<{
  input: QuickFormTemplateInput;
}>;

export type QuickFormTemplateCreateMutation = {
  quickFormTemplateCreate: {
    formTemplate?: { id: string; label?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type QuickFormTemplateDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type QuickFormTemplateDeleteMutation = {
  quickFormTemplateDelete: {
    formTemplate?: { id: string; label?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type QuickFormTemplateDuplicateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type QuickFormTemplateDuplicateMutation = {
  quickFormTemplateDuplicate: {
    formTemplate?: { id: string; label?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type GetQuickFormTemplateEditQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  pk?: InputMaybe<Scalars['TWFilter_String']>;
}>;

export type GetQuickFormTemplateEditQuery = {
  formTemplates?: {
    totalCount?: number | null;
    edges: Array<{
      formTemplate?: {
        id: string;
        pk?: string | null;
        label?: string | null;
        quickformTemplate: any;
        canDelete?: boolean | null;
        canEdit?: boolean | null;
        createdDate?: any | null;
        creator?: {
          personId: string;
          displayName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
          hasLoginAccess?: boolean | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type QuickFormTemplateUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: QuickFormTemplateInput;
}>;

export type QuickFormTemplateUpdateMutation = {
  quickFormTemplateUpdate: {
    formTemplate?: { id: string; label?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type GetTemplatesQueryVariables = Exact<{
  sort?: InputMaybe<Array<InputMaybe<FormSortEnum>> | InputMaybe<FormSortEnum>>;
  search?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  formType?: InputMaybe<FormTypeEnum>;
}>;

export type GetTemplatesQuery = {
  formTemplates?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      formTemplate?: {
        id: string;
        label?: string | null;
        quickformTemplate: any;
        isDocusignForm?: boolean | null;
        serverFileName?: string | null;
        canDelete?: boolean | null;
        canEdit?: boolean | null;
        createdDate?: any | null;
        pk?: string | null;
        creator?: {
          personId: string;
          displayName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
          hasLoginAccess?: boolean | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type QuickFormTemplateCreatorsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type QuickFormTemplateCreatorsQuery = {
  formTemplates?: {
    edges: Array<{
      formTemplate?: { creator?: { personId: string; displayName?: string | null } | null } | null;
    } | null>;
  } | null;
};

export type FetchDashboardAllFilesQueryVariables = Exact<{
  sort?: InputMaybe<Array<InputMaybe<SharedFileSortEnum>> | InputMaybe<SharedFileSortEnum>>;
  isPinned?: InputMaybe<Scalars['TWFilter_Boolean']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  tripId?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type FetchDashboardAllFilesQuery = {
  allSharedFiles?: {
    edges: Array<{
      node?: {
        id: string;
        fileName: string;
        createdDateTime?: string | null;
        sharedFileId?: number | null;
        canDownload?: boolean | null;
        canEditFile?: boolean | null;
        personId?: number | null;
        tripId?: number | null;
        creator?: { displayName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchDashboardMyVideosQueryVariables = Exact<{
  sort?: InputMaybe<Array<InputMaybe<VideoSortEnum>> | InputMaybe<VideoSortEnum>>;
  isPinned?: InputMaybe<Scalars['TWFilter_Boolean']>;
}>;

export type FetchDashboardMyVideosQuery = {
  myVideos?: {
    edges: Array<{
      node?: {
        id: string;
        videoId: string;
        title: string;
        createdDateTime?: string | null;
        wistiaVideoUuid?: string | null;
        wistiaThumbnailUrl?: string | null;
        wistiaOriginalFileUrl?: string | null;
        creator?: { displayName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchDashboardFormsQueryVariables = Exact<{
  sort?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionRoleSortEnum>>
    | InputMaybe<FormAssignmentCompletionRoleSortEnum>
  >;
  status?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionRoleStatusGraphene>>
    | InputMaybe<FormAssignmentCompletionRoleStatusGraphene>
  >;
  first?: InputMaybe<Scalars['Int']>;
  showConditional?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchDashboardFormsQuery = {
  myFormAssignments?: {
    edges: Array<{
      node?: {
        id: string;
        pk?: number | null;
        assignedDateTime?: any | null;
        completion?: {
          pk?: string | null;
          assignment?: {
            label?: string | null;
            dueDate?: any | null;
            form?: { formLabel?: string | null } | null;
            assignedBy?: { displayName?: string | null } | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchDashboardBulletinsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<BulletinSortEnum>> | InputMaybe<BulletinSortEnum>>;
}>;

export type FetchDashboardBulletinsQuery = {
  myBulletins?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      bulletin?: {
        id: string;
        bulletinType: BulletinType;
        label: string;
        externalUrl?: string | null;
        isPinned: boolean;
        createdDateTime?: string | null;
        content: string;
        expirationDateTime?: any | null;
        headerImageFull?: string | null;
        headerImageThumb?: string | null;
        meta?: any | null;
        buttonText?: string | null;
        creator?: {
          fullNameNormalOrder?: string | null;
          pictureUrl?: string | null;
          initials?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateWeblinkMutationVariables = Exact<{
  input?: InputMaybe<WebLinkInput>;
}>;

export type CreateWeblinkMutation = {
  weblinkCreate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    webLink?: {
      webLinkId: string;
      label?: string | null;
      url?: string | null;
      creator?: {
        personId: string;
        preferredName?: string | null;
        lastName?: string | null;
        pictureUrl?: string | null;
      } | null;
    } | null;
  };
};

export type DeleteWeblinkMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type DeleteWeblinkMutation = {
  weblinkDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type UpdateWeblinkMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<WebLinkInput>;
}>;

export type UpdateWeblinkMutation = {
  weblinkUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    webLink?: {
      id: string;
      label?: string | null;
      url?: string | null;
      creator?: {
        personId: string;
        preferredName?: string | null;
        lastName?: string | null;
        pictureUrl?: string | null;
      } | null;
    } | null;
  };
};

export type WebLinksQueryVariables = Exact<{ [key: string]: never }>;

export type WebLinksQuery = {
  myWebLinks?: {
    edges: Array<{
      node?: {
        id: string;
        label?: string | null;
        url?: string | null;
        createdDateTime?: string | null;
        updatedDateTime?: string | null;
        creator?: {
          personId: string;
          lastName?: string | null;
          preferredName?: string | null;
          pictureUrl?: string | null;
        } | null;
        visibility?: Array<
          | {
              selectionCode?: string | null;
              label?: string | null;
              groupType?: GroupType | null;
              pluralLabel?: string | null;
            }
          | {
              selectionCode?: string | null;
              label?: string | null;
              groupType?: GroupType | null;
              pluralLabel?: string | null;
            }
          | {
              selectionCode?: string | null;
              label?: string | null;
              groupType?: GroupType | null;
              pluralLabel?: string | null;
            }
          | {
              selectionCode?: string | null;
              label?: string | null;
              groupType?: GroupType | null;
              pluralLabel?: string | null;
            }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type BlastMessageQueryVariables = Exact<{
  pk?: InputMaybe<Scalars['TWFilter_String']>;
}>;

export type BlastMessageQuery = {
  messages?: {
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        smsText?: string | null;
        emailSubject?: string | null;
        emailText?: string | null;
        deliveryTypes?: Array<MessageDeliveryType | null> | null;
        scheduledDateTime?: string | null;
        pk?: string | null;
        emailAttachments?: Array<{
          fileName?: string | null;
          serverName?: string | null;
          s3Url?: string | null;
        } | null> | null;
        mmsAttachments?: Array<{
          fileName?: string | null;
          serverName?: string | null;
          s3Url?: string | null;
        } | null> | null;
        targetSelectionCodes?: Array<{
          code?: string | null;
          isGroup?: boolean | null;
          label?: string | null;
        } | null> | null;
        timeZone?: { code?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type ConversationAvatarSignedUrlMutationVariables = Exact<{
  input?: InputMaybe<SignedUrlInput>;
}>;

export type ConversationAvatarSignedUrlMutation = {
  conversationAvatarSignedUrl: { url?: string | null };
};

export type CreateBlastMessageMutationVariables = Exact<{
  input?: InputMaybe<MessageInput>;
}>;

export type CreateBlastMessageMutation = {
  messageCreate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    message?: { pk?: string | null } | null;
  };
};

export type DeleteScheduledMessageMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type DeleteScheduledMessageMutation = {
  messageDelete: { message?: { pk?: string | null } | null };
};

export type ListMessageSendersQueryVariables = Exact<{
  returnSentMessages?: InputMaybe<Scalars['Boolean']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type ListMessageSendersQuery = {
  listMessageSenders?: {
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        person?: {
          personId: string;
          preferredName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ListMessagesQueryVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  filterDate?: InputMaybe<DateRangeInput>;
  returnSentMessages?: InputMaybe<Scalars['Boolean']>;
  filterByTeams?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  filterBySender?: InputMaybe<Scalars['Int']>;
  filterByDeliveryPath?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  after?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
}>;

export type ListMessagesQuery = {
  listMessages?: {
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        messageId?: number | null;
        hasBeenProcessed?: boolean | null;
        personId?: number | null;
        smsText?: string | null;
        emailSubject?: string | null;
        rawEmailBody?: string | null;
        emailText?: string | null;
        deliveryTypes?: Array<MessagePath | null> | null;
        scheduledDateTime?: any | null;
        sentDateTime?: any | null;
        externalEmails?: Array<string | null> | null;
        externalSms?: Array<string | null> | null;
        messageType?: {
          integratorLabel?: string | null;
          integratorMessageType?: string | null;
          code?: string | null;
        } | null;
        person?: {
          id: string;
          personId: string;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
          emailAddress?: string | null;
        } | null;
        emailAttachments?: Array<{
          fileName?: string | null;
          serverName?: string | null;
          s3Url?: string | null;
        } | null> | null;
        timezone?: { abbreviation?: string | null; code?: string | null } | null;
        mmsAttachments?: Array<{
          fileName?: string | null;
          serverName?: string | null;
          s3Url?: string | null;
        } | null> | null;
        recipientSelections?: Array<
          | { label?: string | null; selectionCode?: string | null; peopleCount?: number | null }
          | {
              id: string;
              emailAddress?: string | null;
              personId: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              label?: string | null;
              selectionCode?: string | null;
              peopleCount?: number | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              peopleCount?: number | null;
              people?: Array<{
                id: string;
                emailAddress?: string | null;
                personId: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null; peopleCount?: number | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type MessageBlastAttachmentS3UploadUrlMutationVariables = Exact<{
  input: MessageBlastAttachmentS3UploadUrlInput;
}>;

export type MessageBlastAttachmentS3UploadUrlMutation = {
  messageBlastAttachmentS3UploadUrl: { url?: string | null };
};

export type MessageHistoryListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  messageType?: InputMaybe<Scalars['TWFilter_String']>;
  deliveryTypes?: InputMaybe<Scalars['TWFilter_String']>;
  conversationId?: InputMaybe<Scalars['TWFilter_Int']>;
  sentDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  scheduledDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  deliveryTypesFilt?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type MessageHistoryListQuery = {
  messages?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        pk?: string | null;
        id: string;
        personId?: number | null;
        smsText?: string | null;
        pushText?: string | null;
        emailSubject?: string | null;
        emailText?: string | null;
        deliveryTypes?: Array<MessageDeliveryType | null> | null;
        scheduledDateTime?: string | null;
        sentDateTime?: string | null;
        displayEmailText?: string | null;
        hasBeenProcessed?: boolean | null;
        messageType?: {
          integratorLabel?: string | null;
          integratorMessageType?: string | null;
          code?: string | null;
        } | null;
        person?: {
          personId: string;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
          emailAddress?: string | null;
        } | null;
        emailAttachments?: Array<{
          fileName?: string | null;
          serverName?: string | null;
          s3Url?: string | null;
        } | null> | null;
        mmsAttachments?: Array<{
          fileName?: string | null;
          serverName?: string | null;
          s3Url?: string | null;
        } | null> | null;
        messageRecipientDeliveryStatuses?: {
          totalCount?: number | null;
          edges: Array<{
            node?: {
              pk?: string | null;
              emailAddress?: string | null;
              person?: {
                personId: string;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                pictureUrl?: string | null;
                emailAddress?: string | null;
              } | null;
            } | null;
          } | null>;
        } | null;
        targetSelectionCodes?: Array<{
          code?: string | null;
          isGroup?: boolean | null;
          label?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type MessageRecipientDeliveriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  ids: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type MessageRecipientDeliveriesQuery = {
  deliveries?: {
    edges: Array<{
      node?: {
        deliveryDate?: any | null;
        deliveryId?: number | null;
        deliveryStatus?: DeliveryStatus | null;
        externalId?: string | null;
        failAfter?: any | null;
        messageId?: number | null;
        messagePath?: MessagePath | null;
        readDate?: any | null;
        reasonOfFailure?: string | null;
        scheduledSendDate?: any | null;
        sentDate?: any | null;
        statusDate?: any | null;
        tenantId?: string | null;
        emailAddress?: string | null;
        phoneMobile?: string | null;
        person?: {
          id: string;
          personId: string;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
          active: boolean;
        } | null;
      } | null;
    } | null>;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
  } | null;
};

export type MessageRecipientDeliveryStatusesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  messageId: Scalars['TWFilter_Int'];
  search?: InputMaybe<Scalars['String']>;
  broadStatusSearch?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type MessageRecipientDeliveryStatusesQuery = {
  messageRecipientDeliveryStatuses?: {
    totalCount?: number | null;
    smsCount?: number | null;
    pushCount?: number | null;
    edges: Array<{
      node?: {
        pk?: string | null;
        personId?: number | null;
        messageId?: number | null;
        rawTextDeliveryStatus?: string | null;
        rawEmailDeliveryStatus?: string | null;
        textDeliveryStatus?: string | null;
        emailDeliveryStatus?: string | null;
        smsFirstReadDateTime?: string | null;
        emailDeliveryDateTime?: string | null;
        emailFirstOpenedDateTime?: string | null;
        createdDateTime?: string | null;
        phoneMobile?: string | null;
        emailAddress?: string | null;
        isPushMessage?: boolean | null;
        person?: {
          id: string;
          personId: string;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
          active: boolean;
        } | null;
      } | null;
    } | null>;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
  } | null;
};

export type ScheduledMessagesListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  deliveryTypes?: InputMaybe<Scalars['TWFilter_String']>;
  conversationId?: InputMaybe<Scalars['TWFilter_Int']>;
  sentDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  scheduledDateTime?: InputMaybe<Scalars['TWFilter_String']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  deliveryTypesFilt?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  messageId?: InputMaybe<Scalars['TWFilter_String']>;
}>;

export type ScheduledMessagesListQuery = {
  messages?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        pk?: string | null;
        id: string;
        personId?: number | null;
        smsText?: string | null;
        pushText?: string | null;
        emailSubject?: string | null;
        emailText?: string | null;
        deliveryTypes?: Array<MessageDeliveryType | null> | null;
        scheduledDateTime?: string | null;
        hasBeenProcessed?: boolean | null;
        messageType?: {
          integratorLabel?: string | null;
          integratorMessageType?: string | null;
          code?: string | null;
        } | null;
        person?: {
          id: string;
          personId: string;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
          pictureDownloadUrl?: string | null;
          emailAddress?: string | null;
        } | null;
        emailAttachments?: Array<{
          fileName?: string | null;
          serverName?: string | null;
          s3Url?: string | null;
        } | null> | null;
        mmsAttachments?: Array<{
          fileName?: string | null;
          serverName?: string | null;
          s3Url?: string | null;
        } | null> | null;
        targetSelectionCodes?: Array<{
          code?: string | null;
          isGroup?: boolean | null;
          label?: string | null;
        } | null> | null;
        recipients?: Array<{
          personId?: number | null;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
          pictureDownloadUrl?: string | null;
        } | null> | null;
        timeZone?: { code?: string | null; abbreviation?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type UpdateScheduledMessageMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<MessageInput>;
}>;

export type UpdateScheduledMessageMutation = {
  messageUpdate: { message?: { pk?: string | null } | null };
};

export type UploadAttachmentMessageBlastMutationVariables = Exact<{
  input?: InputMaybe<UploadAttachmentInput>;
}>;

export type UploadAttachmentMessageBlastMutation = {
  uploadAttachment: { serverName?: string | null };
};

export type MySavedReportsQueryVariables = Exact<{ [key: string]: never }>;

export type MySavedReportsQuery = {
  mySavedReports?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        filters: any;
        savedViewPersons?: Array<{
          person?: {
            id: string;
            label?: string | null;
            selectionCode?: string | null;
            initials?: string | null;
            orgId?: number | null;
            displayName?: string | null;
            personId: string;
            firstName?: string | null;
            lastName?: string | null;
            active: boolean;
            pictureUrl?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type MySharedSavedReportsQueryVariables = Exact<{ [key: string]: never }>;

export type MySharedSavedReportsQuery = {
  mySharedSavedReports?: {
    edges: Array<{ node?: { id: string; label: string; filters: any } | null } | null>;
  } | null;
};

export type MySharedTeamRosterSavedViewsQueryVariables = Exact<{ [key: string]: never }>;

export type MySharedTeamRosterSavedViewsQuery = {
  mySharedTeamRosterSavedViews?: {
    edges: Array<{ node?: { id: string; label: string; filters: any } | null } | null>;
  } | null;
};

export type MyTeamRosterSavedViewsQueryVariables = Exact<{ [key: string]: never }>;

export type MyTeamRosterSavedViewsQuery = {
  myTeamRosterSavedViews?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        filters: any;
        savedViewPersons?: Array<{
          person?: {
            id: string;
            label?: string | null;
            selectionCode?: string | null;
            initials?: string | null;
            orgId?: number | null;
            displayName?: string | null;
            personId: string;
            firstName?: string | null;
            lastName?: string | null;
            active: boolean;
            pictureUrl?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type BulkCreateTeamRosterPersonMutationVariables = Exact<{
  input: TeamRosterPersonBulkCreateInput;
}>;

export type BulkCreateTeamRosterPersonMutation = {
  teamRosterPersonBulkCreate: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type AdvFetchPeopleQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PersonSortEnum>> | InputMaybe<PersonSortEnum>>;
  search?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  trip?: InputMaybe<Scalars['ID']>;
  athleteStatus?: InputMaybe<Scalars['ID']>;
  customGroup?: InputMaybe<Scalars['ID']>;
  activeStatus?: InputMaybe<Scalars['Boolean']>;
  advanced?: InputMaybe<Scalars['JSONString']>;
  columns?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  viaSavedView?: InputMaybe<Scalars['Boolean']>;
  withAddresses?: InputMaybe<Scalars['Boolean']>;
  withAutomobile?: InputMaybe<Scalars['Boolean']>;
  withContacts?: InputMaybe<Scalars['Boolean']>;
  withRoommates?: InputMaybe<Scalars['Boolean']>;
  withTravelDocument?: InputMaybe<Scalars['Boolean']>;
  withIsOrgSuperuser?: InputMaybe<Scalars['Boolean']>;
  withFirstName?: InputMaybe<Scalars['Boolean']>;
  withLastName?: InputMaybe<Scalars['Boolean']>;
  withMiddleName?: InputMaybe<Scalars['Boolean']>;
  withNickName?: InputMaybe<Scalars['Boolean']>;
  withGender?: InputMaybe<Scalars['Boolean']>;
  withBirthdate?: InputMaybe<Scalars['Boolean']>;
  withOrgId?: InputMaybe<Scalars['Boolean']>;
  withStartDate?: InputMaybe<Scalars['Boolean']>;
  withWeight?: InputMaybe<Scalars['Boolean']>;
  withNickname?: InputMaybe<Scalars['Boolean']>;
  withTitle?: InputMaybe<Scalars['Boolean']>;
  withEmail?: InputMaybe<Scalars['Boolean']>;
  withAlternateEmail?: InputMaybe<Scalars['Boolean']>;
  withCustomAttributes?: InputMaybe<Scalars['Boolean']>;
  withYearlyAttributes?: InputMaybe<Scalars['Boolean']>;
  withHomePhone?: InputMaybe<Scalars['Boolean']>;
  withMobilePhone?: InputMaybe<Scalars['Boolean']>;
  withOfficePhone?: InputMaybe<Scalars['Boolean']>;
  withFaxNumber?: InputMaybe<Scalars['Boolean']>;
  withFacebookAccount?: InputMaybe<Scalars['Boolean']>;
  withTwitterAccount?: InputMaybe<Scalars['Boolean']>;
  withLastLogin?: InputMaybe<Scalars['Boolean']>;
  withLoginCount?: InputMaybe<Scalars['Boolean']>;
  withDriversLicenseNumber?: InputMaybe<Scalars['Boolean']>;
  withDriversLicenseState?: InputMaybe<Scalars['Boolean']>;
  withDriversLicenseExpDate?: InputMaybe<Scalars['Boolean']>;
  withJerseyNumber?: InputMaybe<Scalars['Boolean']>;
  withAthletePositions?: InputMaybe<Scalars['Boolean']>;
  withAcademicYear?: InputMaybe<Scalars['Boolean']>;
  withAthleticYear?: InputMaybe<Scalars['Boolean']>;
  withAthleteStatus?: InputMaybe<Scalars['Boolean']>;
  withHasScholarship?: InputMaybe<Scalars['Boolean']>;
  withScholarshipAmount?: InputMaybe<Scalars['Boolean']>;
  withMajor?: InputMaybe<Scalars['Boolean']>;
  withTeams?: InputMaybe<Scalars['Boolean']>;
  tmpYearId?: InputMaybe<Scalars['ID']>;
  yavTmpYearId?: InputMaybe<Scalars['ID']>;
  personIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type AdvFetchPeopleQuery = {
  people?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      person?: {
        id: string;
        displayName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
        nickname?: string | null;
        gender?: string | null;
        title?: string | null;
        dateOfBirth?: string | null;
        orgId?: number | null;
        startDate?: string | null;
        travelWeight?: number | null;
        preferredName?: string | null;
        active: boolean;
        initials?: string | null;
        pictureUrl?: string | null;
        emailAddress?: string | null;
        emailAddressPersonal?: string | null;
        customAttributeValues?: any | null;
        yearlyAttributeValues?: any | null;
        phoneHome?: string | null;
        mobilePhone?: string | null;
        phoneOffice?: string | null;
        phoneFax?: string | null;
        suffix?: string | null;
        schoolIdentifier?: string | null;
        selectionCode?: string | null;
        facebookAccount?: string | null;
        twitterAccount?: string | null;
        lastLoginDate?: any | null;
        loginCount?: number | null;
        usesExternalAuthentication: boolean;
        hasLoginAccess?: boolean | null;
        isOrgLevelUser?: boolean | null;
        hasUserAccount?: boolean | null;
        driversLicenseNumber?: string | null;
        driversLicenseState?: string | null;
        driversLicenseExpirationDate?: any | null;
        canViewAcademicDetails: boolean;
        canViewAddressesDetails: boolean;
        canViewAthleteDetails: boolean;
        canViewContactDetails: boolean;
        teamLabels?: string | null;
        key: string;
        travelDocument?: {
          documentType: string;
          documentNumber: string;
          documentCountry?: string | null;
          documentExpDate?: string | null;
          nationality?: string | null;
          birthCountry?: string | null;
          residency?: string | null;
        } | null;
        automobile?: {
          make?: string | null;
          model?: string | null;
          stateCode: string;
          year?: string | null;
          plateNumber?: string | null;
        } | null;
        activeMemberships?: Array<{
          id: string;
          jerseyNumber?: string | null;
          positionLabels?: string | null;
          academicYearLabel?: string | null;
          athleticYearLabel?: string | null;
          hasScholarshipYesNo?: string | null;
          scholarshipAmount?: string | null;
          major?: string | null;
          userTypeLabels?: string | null;
          isTeamSuperuser?: string | null;
          isOrgSuperuser?: string | null;
          profilePermissionsNameList?: string | null;
          athleteStatus?: { id: string; label?: string | null } | null;
          team?: { id: string; label: string } | null;
        } | null> | null;
        roommates?: Array<{
          id?: string | null;
          displayName?: string | null;
          emailAddress?: string | null;
          mobilePhone?: string | null;
        } | null> | null;
        addresses?: Array<{
          id: string;
          description?: string | null;
          street?: string | null;
          street2?: string | null;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          countryIso?: string | null;
          isMailing?: string | null;
        } | null> | null;
        contacts?: Array<{
          id?: string | null;
          displayName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          emailAddress?: string | null;
          dateOfBirth?: string | null;
          phoneHome?: string | null;
          phoneCell?: string | null;
          phoneOffice?: string | null;
          maritalStatusLabel?: string | null;
          relationship?: { id: string; label: string } | null;
          addresses?: Array<{
            id: string;
            street?: string | null;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type AttributeBulkUpdateMutationVariables = Exact<{
  input: BulkAttributeInput;
}>;

export type AttributeBulkUpdateMutation = {
  attributeBulkUpdate: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type AttributeUpdateMutationVariables = Exact<{
  personId: Scalars['ID'];
  input: AttributeInput;
}>;

export type AttributeUpdateMutation = {
  attributeUpdate: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type AdvancedFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type AdvancedFiltersQuery = {
  attributeCategories?: Array<{
    __typename: 'AttributeCategoryNode';
    label: string;
    coreCode?: string | null;
    attributes?: Array<{
      __typename: 'AttributeNode';
      id: string;
      label: string;
      dataType?: string | null;
      optionsList?: Array<string | null> | null;
      coreCode?: string | null;
      isCustom?: boolean | null;
      extendedOptionsList?: Array<{
        __typename: 'AttributeOptionNode';
        label?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type FetchAppAssignmentsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<PersonSortEnum>> | InputMaybe<PersonSortEnum>>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  athleteStatus?: InputMaybe<Scalars['ID']>;
  active?: InputMaybe<Scalars['Boolean']>;
  hasLoginAccess?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchAppAssignmentsQuery = {
  viewer?: {
    currentOrg: {
      id: string;
      installedApps?: Array<{
        id?: string | null;
        installationId?: string | null;
        name?: string | null;
      }> | null;
    };
  } | null;
  people?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      person?: {
        id: string;
        displayName?: string | null;
        emailAddress?: string | null;
        schoolIdentifier?: string | null;
        key?: string | null;
        activeMemberships?: Array<{
          id: string;
          userTypeLabels?: string | null;
          team?: { id: string; label: string } | null;
        } | null> | null;
        assignedApps?: Array<{ id?: string | null; name?: string | null } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type UserPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type UserPermissionsQuery = {
  permissions?: {
    edges: Array<{
      node?: {
        id: string;
        category: string;
        codeName: string;
        displayName: string;
        Dependencies?: string | null;
        grantedBy?: Array<string | null> | null;
        personTypeFilter: boolean;
        tooltip?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchPeopleQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PersonSortEnum>> | InputMaybe<PersonSortEnum>>;
  search?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  athleteStatus?: InputMaybe<Scalars['ID']>;
  activeStatus?: InputMaybe<Scalars['Boolean']>;
  advanced?: InputMaybe<Scalars['JSONString']>;
}>;

export type FetchPeopleQuery = {
  people?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      person?: {
        id: string;
        displayName?: string | null;
        preferredName?: string | null;
        active: boolean;
        initials?: string | null;
        pictureUrl?: string | null;
        title?: string | null;
        emailAddress?: string | null;
        mobilePhone?: string | null;
        lastLoginDate?: any | null;
        loginCount?: number | null;
        usesExternalAuthentication: boolean;
        hasLoginAccess?: boolean | null;
        isOrgLevelUser?: boolean | null;
        hasUserAccount?: boolean | null;
        key: string;
        activeMemberships?: Array<{
          id: string;
          team?: { id: string; label: string } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type PeopleSelectionCodesQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PersonSortEnum>> | InputMaybe<PersonSortEnum>>;
  search?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  personIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  userTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  athleteStatus?: InputMaybe<Scalars['ID']>;
  activeStatus?: InputMaybe<Scalars['Boolean']>;
  advanced?: InputMaybe<Scalars['JSONString']>;
  viaSavedView?: InputMaybe<Scalars['Boolean']>;
}>;

export type PeopleSelectionCodesQuery = {
  people?: {
    edges: Array<{
      cursor: string;
      person?: { id: string; selectionCode?: string | null } | null;
    } | null>;
  } | null;
};

export type ResetPasswordForPersonMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PersonSendInvitationInput;
}>;

export type ResetPasswordForPersonMutation = {
  personResetPassword: {
    passwordResetLink?: string | null;
    sendEmail?: boolean | null;
    sendSms?: boolean | null;
    person?: { id: string; displayName?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string; code?: string | null }>;
  };
};

export type SavedReportsQueryVariables = Exact<{ [key: string]: never }>;

export type SavedReportsQuery = {
  mySavedReports?: {
    edges: Array<{ node?: { id: string; label: string; filters: any } | null } | null>;
  } | null;
};

export type SavedReportCreateMutationVariables = Exact<{
  input?: InputMaybe<SavedReportInput>;
}>;

export type SavedReportCreateMutation = {
  savedReportCreate: { savedReport?: { id: string; label: string } | null };
};

export type SavedReportDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SavedReportDeleteMutation = {
  savedReportDelete: { savedReport?: { id: string; label: string } | null };
};

export type SavedReportUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<SavedReportUpdateInput>;
}>;

export type SavedReportUpdateMutation = {
  savedReportUpdate: { savedReport?: { id: string; label: string } | null };
};

export type UpdateAppAssignmentsMutationVariables = Exact<{
  input?: InputMaybe<AppAssignmentsUpdateInput>;
}>;

export type UpdateAppAssignmentsMutation = {
  appAssignmentsUpdate: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type CreatePersonMutationVariables = Exact<{
  input: PersonInput;
}>;

export type CreatePersonMutation = {
  personCreate: {
    person?: {
      id: string;
      displayName?: string | null;
      preferredName?: string | null;
      initials?: string | null;
      pictureUrl?: string | null;
      title?: string | null;
      gender?: string | null;
      inviteUrl?: string | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type GetOptionsForAthletesQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']>;
  team?: InputMaybe<Scalars['TWFilter_Int']>;
  withRoster?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetOptionsForAthletesQuery = {
  eligibilityYears?: Array<{ label?: string | null; value: string } | null> | null;
  sportPositions?: Array<{ label?: string | null; value: string } | null> | null;
  athleticYears?: Array<{ label?: string | null; value: string } | null> | null;
  teamRosters?: {
    edges: Array<{ node?: { id: string; tmpYear?: { label: string } | null } | null } | null>;
  } | null;
};

export type GetPersonByEmailOrPhoneQueryVariables = Exact<{
  emailAddress?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
}>;

export type GetPersonByEmailOrPhoneQuery = {
  people?: {
    totalCount?: number | null;
    edges: Array<{
      person?: {
        displayName?: string | null;
        preferredName?: string | null;
        initials?: string | null;
        pictureUrl?: string | null;
        title?: string | null;
        emailAddress?: string | null;
        mobilePhone?: string | null;
        key: string;
        teams?: Array<{
          label: string;
          canAddToTeam?: boolean | null;
          canRemoveFromTeam?: boolean | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type PersonAddTeamMembershipMutationVariables = Exact<{
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  input: PersonInput;
}>;

export type PersonAddTeamMembershipMutation = {
  personAddTeamMembership: {
    person?: {
      id: string;
      displayName?: string | null;
      preferredName?: string | null;
      initials?: string | null;
      pictureUrl?: string | null;
      title?: string | null;
      gender?: string | null;
      teamMembers?: {
        edges: Array<{ node?: { id: string; active?: boolean | null } | null } | null>;
      } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type CustomGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CustomGroupQuery = {
  customGroup?:
    | {
        id: string;
        name: string;
        memberCount?: number | null;
        members?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              lastName?: string | null;
              displayName?: string | null;
              initials?: string | null;
              pictureUrl?: string | null;
              label?: string | null;
              selectionCode?: string | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                lastName?: string | null;
                initials?: string | null;
                pictureUrl?: string | null;
                selectionCode?: string | null;
                id: string;
                label?: string | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        team?: { id: string; label: string } | null;
      }
    | {}
    | null;
};

export type CustomGroupCreateMutationVariables = Exact<{
  input: CustomGroupInput;
}>;

export type CustomGroupCreateMutation = {
  customGroupCreate: {
    customGroup?: { name: string; team?: { id: string; label: string } | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type CustomGroupDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CustomGroupDeleteMutation = {
  customGroupDelete: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type CustomGroupUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CustomGroupInput;
}>;

export type CustomGroupUpdateMutation = {
  customGroupUpdate: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    customGroup?: {
      id: string;
      name: string;
      members?: Array<
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | null
      > | null;
    } | null;
  };
};

export type CustomGroupsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<CustomGroupSortEnum>> | InputMaybe<CustomGroupSortEnum>>;
  creator?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;

export type CustomGroupsQuery = {
  customGroups?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      customGroup?: {
        id: string;
        name: string;
        memberCount?: number | null;
        team?: { id: string; label: string } | null;
        creator?: { displayName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type AthleteBulkUpdateMutationVariables = Exact<{
  input: AthleteBulkUpdateInput;
}>;

export type AthleteBulkUpdateMutation = {
  athleteBulkUpdate: {
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ field: Array<string>; message: string } | null> | null;
    }>;
  };
};

export type GetAthleteOptionsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']>;
}>;

export type GetAthleteOptionsQuery = {
  eligibilityYears?: Array<{ label?: string | null; value: string } | null> | null;
  sportPositions?: Array<{ label?: string | null; value: string } | null> | null;
};

export type FetchAthletesQueryVariables = Exact<{
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  userType?: InputMaybe<Scalars['ID']>;
  athleteStatuses?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type FetchAthletesQuery = {
  people?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      person?: {
        id: string;
        displayName?: string | null;
        active: boolean;
        pictureUrl?: string | null;
        initials?: string | null;
        emailAddress?: string | null;
        mobilePhone?: string | null;
        hasLoginAccess?: boolean | null;
        schoolIdentifier?: string | null;
        activeMemberships?: Array<{
          id: string;
          jerseyNumber?: string | null;
          positionLabels?: string | null;
          academicYearLabel?: string | null;
          athleticYearLabel?: string | null;
          teamId?: number | null;
          userTypeLabels?: string | null;
          athleteStatus?: { id: string; label?: string | null } | null;
          positions?: { edges: Array<{ node?: { id: string } | null } | null> } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchAthletesAcademicYearQueryVariables = Exact<{
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  userType?: InputMaybe<Scalars['ID']>;
  athleteStatus?: InputMaybe<Scalars['ID']>;
}>;

export type FetchAthletesAcademicYearQuery = {
  people?: {
    edges: Array<{
      cursor: string;
      person?: {
        id: string;
        activeMemberships?: Array<{
          academicYearLabel?: string | null;
          athleticYearLabel?: string | null;
          teamId?: number | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type TeamMemberBulkUpdatePermissionsMutationVariables = Exact<{
  input: BulkUpdatePermissionsInput;
}>;

export type TeamMemberBulkUpdatePermissionsMutation = {
  teamMemberBulkUpdatePermissions: { teamMember?: Array<{ id: string } | null> | null };
};

export type BulkAssignProfilePhotosMutationVariables = Exact<{
  input: BulkPersonPictureUploadInput;
}>;

export type BulkAssignProfilePhotosMutation = {
  bulkAssignProfilePicture: { person?: Array<{ lastName?: string | null } | null> | null };
};

export type FetchUsersInfoQueryVariables = Exact<{
  codes: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type FetchUsersInfoQuery = {
  selectables?: Array<
    | { label?: string | null; selectionCode?: string | null }
    | {
        id: string;
        personId: string;
        firstName?: string | null;
        lastName?: string | null;
        preferredName?: string | null;
        initials?: string | null;
        pictureUrl?: string | null;
        label?: string | null;
        selectionCode?: string | null;
        teamMembership?: { userTypes?: Array<{ namePlural: string } | null> | null } | null;
      }
    | {
        groupType?: GroupType | null;
        pluralLabel?: string | null;
        label?: string | null;
        selectionCode?: string | null;
        people?: Array<{
          id: string;
          personId: string;
          firstName?: string | null;
          lastName?: string | null;
          preferredName?: string | null;
          label?: string | null;
          selectionCode?: string | null;
          pictureUrl?: string | null;
          teamMembership?: { userTypes?: Array<{ namePlural: string } | null> | null } | null;
        } | null> | null;
      }
    | { label?: string | null; selectionCode?: string | null }
    | null
  > | null;
};

export type RemovePictureMutationVariables = Exact<{
  input: DeleteUnassignedProfilePictureInput;
}>;

export type RemovePictureMutation = {
  deleteUnassignedProfilePicture: { fileUuid?: string | null };
};

export type UploadPictureMutationVariables = Exact<{
  input: UploadProfilePictureInput;
}>;

export type UploadPictureMutation = {
  uploadProfilePicture: {
    fileUuid?: string | null;
    fileUrl?: string | null;
    fileName?: string | null;
  };
};

export type YearlyAttributeReportQueryVariables = Exact<{ [key: string]: never }>;

export type YearlyAttributeReportQuery = {
  yearlyAttributeReport?: {
    edges: Array<{
      node?: {
        createdDate?: any | null;
        downloadableFileId: string;
        errorMessage?: string | null;
        kind: string;
        status: string;
        title?: string | null;
        url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type YearlyAttributeReportCreateMutationVariables = Exact<{
  input?: InputMaybe<YearlyAttributeReportCreateInput>;
}>;

export type YearlyAttributeReportCreateMutation = {
  yearlyAttributeReportCreate: {
    yearlyAttributeReport?: { status: string; title?: string | null; url?: string | null } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type AddRoommateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: RoommateInput;
}>;

export type AddRoommateMutation = {
  roommateCreate: {
    person?: {
      id: string;
      roommates?: Array<{ displayName?: string | null } | null> | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type CreateContactMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PersonContactInput;
}>;

export type CreateContactMutation = {
  contactCreate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    person?: {
      id: string;
      contacts?: Array<{
        firstName?: string | null;
        lastName?: string | null;
        selectionCode?: string | null;
        id?: string | null;
        initials?: string | null;
        pictureUrl?: string | null;
        displayName?: string | null;
        mobilePhone?: string | null;
        dateOfBirth?: string | null;
        relationship?: { id: string; label: string } | null;
      } | null> | null;
    } | null;
  };
};

export type ContactDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
  contactId: Scalars['ID'];
}>;

export type ContactDeleteMutation = {
  contactDelete: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type RoommateDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
  roommateId: Scalars['ID'];
}>;

export type RoommateDeleteMutation = {
  roommateDelete: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type FilesByPersonQueryVariables = Exact<{
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type FilesByPersonQuery = {
  allSharedFiles?: {
    edges: Array<{
      node?: {
        id: string;
        pk?: number | null;
        sharedFileId?: number | null;
        fileName: string;
        fileNotes?: string | null;
        isPinned?: boolean | null;
        updatedDate?: any | null;
        canEditFile?: boolean | null;
        canDownload?: boolean | null;
        creator?: {
          createdBy?: number | null;
          pictureDownloadUrl?: string | null;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetFormsForPersonQueryVariables = Exact<{
  personId: Scalars['ID'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionRoleStatusGraphene>>
    | InputMaybe<FormAssignmentCompletionRoleStatusGraphene>
  >;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<
    | Array<InputMaybe<FormAssignmentCompletionRoleSortEnum>>
    | InputMaybe<FormAssignmentCompletionRoleSortEnum>
  >;
  showConditional?: InputMaybe<Scalars['Boolean']>;
  assignedDateTimeStart?: InputMaybe<Scalars['DateTime']>;
  assignedDateTimeEnd?: InputMaybe<Scalars['DateTime']>;
  assignerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type GetFormsForPersonQuery = {
  myFormAssignments?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        status?: string | null;
        pk?: number | null;
        assignedDateTime?: any | null;
        completedDateTime?: any | null;
        key: string;
        completion?: {
          allCompleted?: boolean | null;
          status?: string | null;
          signedDownloadUrl?: string | null;
          manuallyCompleted?: boolean | null;
          formAssignmentCompletionId?: string | null;
          assignmentCompletionCompletedDateTime?: any | null;
          assignment?: {
            isDocusignForm?: boolean | null;
            assignmentType?: FormAssignmentType | null;
            label?: string | null;
            form?: { formLabel?: string | null } | null;
            assignedBy?: {
              id: string;
              fullName?: string | null;
              initials?: string | null;
              pictureUrl?: string | null;
              displayName?: string | null;
            } | null;
          } | null;
        } | null;
        workflow?: Array<{
          assignedDateTime?: any | null;
          updatedDate?: any | null;
          roleName?: string | null;
          roleOrder?: number | null;
          completedDateTime?: any | null;
          pk?: number | null;
          externalName?: string | null;
          externalEmail?: string | null;
          status?: string | null;
          person?: {
            initials?: string | null;
            displayName?: string | null;
            personId: string;
            picture?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchPeopleNamesQueryVariables = Exact<{
  team: Scalars['ID'];
  activeStatus?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchPeopleNamesQuery = {
  people?: {
    edges: Array<{
      person?: {
        id: string;
        key: string;
        value: string;
        label?: string | null;
        teamMembership?: {
          id: string;
          userTypeLabels?: string | null;
          athlete?: { personStatusType?: { labelPlural?: string | null } | null } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetTeamMemberProfilePermissionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTeamMemberProfilePermissionsQuery = {
  person?:
    | {
        id: string;
        teamMembership?: {
          id: string;
          teamMemberId: string;
          profilePermissions?: Array<{
            id?: string | null;
            label?: string | null;
            dependencies?: Array<string | null> | null;
            category?: string | null;
            granted?: boolean | null;
            grantedBy?: Array<string | null> | null;
            restrictions?: Array<string | null> | null;
            codeName?: string | null;
            isRestrictedType?: boolean | null;
            tooltip?: string | null;
          } | null> | null;
        } | null;
      }
    | {}
    | null;
};

export type GetProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProfileQuery = {
  person?:
    | {
        __typename: 'PersonNode';
        id: string;
        active: boolean;
        personId: string;
        displayName?: string | null;
        title?: string | null;
        selectionCode?: string | null;
        pictureUrl?: string | null;
        preferredName?: string | null;
        nickname?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        emailAddress?: string | null;
        phoneHome?: string | null;
        mobilePhone?: string | null;
        dateOfBirth?: string | null;
        schoolIdentifier?: string | null;
        facebookAccount?: string | null;
        twitterAccount?: string | null;
        canViewProfile: boolean;
        canViewProfileCalendar: boolean;
        canViewProfileForms: boolean;
        canViewProfileNotes: boolean;
        canViewProfileFiles: boolean;
        canViewPersonalDetails: boolean;
        canViewYearlyAttributes: boolean;
        canViewAllPersonalDetails: boolean;
        canViewAcademicDetails: boolean;
        canViewAddressesDetails: boolean;
        canViewAthleteDetails: boolean;
        canViewContactDetails: boolean;
        canModifyProfileAddresses: boolean;
        canModifyProfileCustomContacts: boolean;
        canModifyProfileTwContacts: boolean;
        canModifyProfileRoommates: boolean;
        canModifyProfileFiles: boolean;
        hasLoginAccess?: boolean | null;
        hasUserAccount?: boolean | null;
        isOrgLevelUser?: boolean | null;
        usesExternalAuthentication: boolean;
        suffix?: string | null;
        addresses?: Array<{
          id: string;
          description?: string | null;
          street?: string | null;
          street2?: string | null;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          isMailing?: string | null;
          countryIso?: string | null;
        } | null> | null;
        teamMembership?: {
          createdDate?: any | null;
          id: string;
          teamMemberId: string;
          positionLabels?: string | null;
          userTypeLabels?: string | null;
          major?: string | null;
          hasScholarship?: boolean | null;
          scholarshipAmount?: string | null;
          jerseyNumber?: string | null;
          academicYearLabel?: string | null;
          athleticYearLabel?: string | null;
          isAthlete?: boolean | null;
          team?: { id: string; canAddToTeam?: boolean | null; label: string } | null;
          userTypes?: Array<{ id: string; name: string } | null> | null;
          athleteStatus?: { id: string; label?: string | null } | null;
        } | null;
        teamMembers?: {
          edges: Array<{
            node?: {
              id: string;
              isAthlete?: boolean | null;
              academicYearLabel?: string | null;
              athleticYearLabel?: string | null;
              active?: boolean | null;
              teamId?: number | null;
              teamMemberId: string;
              positionLabels?: string | null;
              userTypeLabels?: string | null;
              userTypeLabelsWithStatuses?: string | null;
              jerseyNumber?: string | null;
              major?: string | null;
              hasScholarship?: boolean | null;
              scholarshipAmount?: string | null;
              person?: { id: string; displayName?: string | null; personId: string } | null;
              team?: {
                canAddToTeam?: boolean | null;
                canRemoveFromTeam?: boolean | null;
                id: string;
                label: string;
              } | null;
              rosterInfo?: {
                activeRosters?: Array<{ tmpYearLabel?: string | null } | null> | null;
                missingRosters?: Array<{
                  teamRosterId?: string | null;
                  tmpYearLabel?: string | null;
                } | null> | null;
              } | null;
            } | null;
          } | null>;
        } | null;
        teams?: Array<{ id: string; label: string } | null> | null;
        student?: {
          studentId: string;
          advisors?: {
            edges: Array<{
              node?: {
                advisorId: string;
                preferredName: string;
                lastName: string;
                pictureUrl?: string | null;
              } | null;
            } | null>;
          } | null;
        } | null;
        contacts?: Array<{
          id?: string | null;
          emailAddress?: string | null;
          firstName?: string | null;
          middleName?: string | null;
          maritalStatusId?: number | null;
          phoneCell?: string | null;
          phoneHome?: string | null;
          phoneOffice?: string | null;
          lastName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
          displayName?: string | null;
          dateOfBirth?: string | null;
          mobilePhone?: string | null;
          selectionCode?: string | null;
          relationship?: { id: string; label: string } | null;
          addresses?: Array<{
            isMailing?: string | null;
            description?: string | null;
            street?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
            countryIso?: string | null;
          } | null> | null;
        } | null> | null;
        roommates?: Array<{
          id?: string | null;
          initials?: string | null;
          displayName?: string | null;
          mobilePhone?: string | null;
          pictureUrl?: string | null;
          profileId?: string | null;
          emailAddress?: string | null;
          selectionCode?: string | null;
          teamMembers?: Array<{
            teamMemberId: string;
            jerseyNumber?: string | null;
            positionLabels?: string | null;
          } | null> | null;
        } | null> | null;
        org?: {
          activeCoreCategories?: Array<string | null> | null;
          activeCoreCategoriesWithLabels?: Array<{
            coreCode?: string | null;
            label?: string | null;
          } | null> | null;
          relationshipTypes?: Array<{ id: string; label: string } | null> | null;
          userTypes?: Array<{
            id: string;
            personTypeId: string;
            name: string;
            namePlural: string;
          } | null> | null;
        } | null;
      }
    | {}
    | null;
};

export type GetProfileAttributesQueryVariables = Exact<{
  id: Scalars['ID'];
  includeAddresses?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetProfileAttributesQuery = {
  person?:
    | {
        __typename: 'PersonNode';
        id: string;
        attributeCategories?: Array<{
          label: string;
          isActive: boolean;
          coreCode?: string | null;
          sortOrder?: number | null;
          teamId?: string | null;
          attributes?: Array<{
            id: string;
            isActive: boolean;
            isCustom?: boolean | null;
            label: string;
            coreCode?: string | null;
            value?: string | null;
            valueLabel?: string | null;
            dataType?: string | null;
            canEdit: boolean;
            optionsList?: Array<string | null> | null;
          } | null> | null;
        } | null> | null;
        org?: {
          activeCoreCategories?: Array<string | null> | null;
          activeCoreCategoriesWithLabels?: Array<{
            coreCode?: string | null;
            label?: string | null;
          } | null> | null;
        } | null;
      }
    | {}
    | null;
};

export type GetProfileCoursesQueryVariables = Exact<{
  studentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type GetProfileCoursesQuery = {
  courseSections?: {
    edges: Array<{
      node?: {
        shortCode: string;
        startDate?: string | null;
        endDate?: string | null;
        courseSectionId?: string | null;
        term?: {
          startDate?: string | null;
          endDate?: string | null;
          label?: string | null;
          termId?: string | null;
        } | null;
        courseSectionSchedules?: {
          edges: Array<{
            node?: {
              startTime?: string | null;
              endTime?: string | null;
              day: DaysOfWeekEnum;
            } | null;
          } | null>;
        } | null;
        course?: {
          credits?: string | null;
          label?: string | null;
          description?: string | null;
          courseId?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetProfileViewPermissionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProfileViewPermissionsQuery = {
  person?:
    | {
        __typename: 'PersonNode';
        id: string;
        personId: string;
        canViewProfile: boolean;
        canViewProfileCalendar: boolean;
        canViewProfileForms: boolean;
        canViewProfileNotes: boolean;
        canViewProfileFiles: boolean;
        canViewPersonalDetails: boolean;
        canViewYearlyAttributes: boolean;
        canViewAllPersonalDetails: boolean;
        canViewAcademicDetails: boolean;
        canViewAddressesDetails: boolean;
        canViewAthleteDetails: boolean;
        canViewContactDetails: boolean;
        canModifyProfileAddresses: boolean;
        canModifyProfileCustomContacts: boolean;
        canModifyProfileTwContacts: boolean;
        canModifyProfileRoommates: boolean;
        canModifyProfileFiles: boolean;
        teamMembership?: { isAthlete?: boolean | null } | null;
      }
    | {}
    | null;
};

export type PersonAddAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AddressInput;
}>;

export type PersonAddAddressMutation = {
  personAddAddress: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type PersonGrantAllTeamsAccessMutationVariables = Exact<{
  id: Scalars['ID'];
  sourceTeamId: Scalars['ID'];
}>;

export type PersonGrantAllTeamsAccessMutation = {
  personGrantAllTeamsAccess: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type PersonModifyAllTeamsAccessMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ModifyAllTeamAccess;
}>;

export type PersonModifyAllTeamsAccessMutation = {
  personModifyAllTeamsAccess: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    person?: {
      displayName?: string | null;
      teamMembers?: {
        edges: Array<{ node?: { id: string; active?: boolean | null } | null } | null>;
      } | null;
    } | null;
  };
};

export type PersonRemoveAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  addressId: Scalars['ID'];
}>;

export type PersonRemoveAddressMutation = {
  personRemoveAddress: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type PersonRevokeAllTeamsAccessMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PersonRevokeAllTeamsAccessMutation = {
  personRevokeAllTeamsAccess: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    person?: {
      teamMembers?: {
        edges: Array<{ node?: { id: string; active?: boolean | null } | null } | null>;
      } | null;
    } | null;
  };
};

export type PersonUpdateAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  addressId: Scalars['ID'];
  input: AddressInput;
}>;

export type PersonUpdateAddressMutation = {
  personUpdateAddress: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type TeamMemberUpdateUserTypesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateUserTypesInput;
}>;

export type TeamMemberUpdateUserTypesMutation = {
  teamMemberUpdateUserTypes: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type UpdateAttributesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AttributeInput;
}>;

export type UpdateAttributesMutation = {
  attributesUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    person?: {
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      gender?: string | null;
      nickname?: string | null;
      dateOfBirth?: string | null;
      orgId?: number | null;
      title?: string | null;
      startDate?: string | null;
      travelWeight?: number | null;
      driversLicenseNumber?: string | null;
      driversLicenseExpirationDate?: any | null;
      driversLicenseState?: string | null;
      emailAddress?: string | null;
      emailAddressPersonal?: string | null;
      phoneHome?: string | null;
      phoneCell?: string | null;
      phoneOffice?: string | null;
      phoneFax?: string | null;
      facebookAccount?: string | null;
      twitterAccount?: string | null;
      automobile?: {
        make?: string | null;
        model?: string | null;
        stateCode: string;
        year?: string | null;
        plateNumber?: string | null;
      } | null;
    } | null;
  };
};

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID'];
  contactId: Scalars['ID'];
  input: PersonContactUpdateInput;
}>;

export type UpdateContactMutation = {
  contactUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    person?: {
      id: string;
      contacts?: Array<{
        id?: string | null;
        initials?: string | null;
        pictureUrl?: string | null;
        displayName?: string | null;
        dateOfBirth?: string | null;
        mobilePhone?: string | null;
        selectionCode?: string | null;
        relationship?: { id: string; label: string } | null;
      } | null> | null;
    } | null;
  };
};

export type UpdatePermissionMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdatePermissionsInput;
}>;

export type UpdatePermissionMutation = {
  teamMemberUpdatePermissions: {
    teamMember?: {
      profilePermissions?: Array<{
        id?: string | null;
        label?: string | null;
        dependencies?: Array<string | null> | null;
        category?: string | null;
        granted?: boolean | null;
        grantedBy?: Array<string | null> | null;
        restrictions?: Array<string | null> | null;
        codeName?: string | null;
        isRestrictedType?: boolean | null;
        tooltip?: string | null;
      } | null> | null;
    } | null;
  };
};

export type UpdateProfilePictureMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PersonInput;
}>;

export type UpdateProfilePictureMutation = {
  personUpdate: { person?: { id: string; pictureUrl?: string | null } | null };
};

export type UpdateRoommateMutationVariables = Exact<{
  id: Scalars['ID'];
  roommateId: Scalars['ID'];
  input: RoommateInput;
}>;

export type UpdateRoommateMutation = {
  roommateUpdate: {
    person?: {
      id: string;
      roommates?: Array<{ displayName?: string | null } | null> | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type YearlyAttributeValuesQueryVariables = Exact<{
  personBeingViewed: Scalars['ID'];
  tmpYear: Scalars['ID'];
}>;

export type YearlyAttributeValuesQuery = {
  yearlyAttributeValuesForYear?: {
    edges: Array<{
      node?: {
        id: string;
        yearlyAttributeId: number;
        valueText?: string | null;
        valueNumeric?: number | null;
        valueDate?: string | null;
        tmpYearId: number;
      } | null;
    } | null>;
  } | null;
};

export type YearlyAttributeProfileQueryVariables = Exact<{
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
  personBeingViewed?: InputMaybe<Scalars['ID']>;
  teamBeingViewed?: InputMaybe<Scalars['ID']>;
}>;

export type YearlyAttributeProfileQuery = {
  orgYearlyAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        isCustom: boolean;
        isActive: boolean;
        sortOrder: number;
        yearlyAttributesActive?: Array<{
          id: string;
          label: string;
          sortOrder: number;
          selfManageUsers?: string | null;
          dataType?: string | null;
          canEdit: boolean;
          isCustom?: boolean | null;
          isActive: boolean;
          optionsList?: Array<string | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
  teamYearlyAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        isCustom: boolean;
        isActive: boolean;
        yearlyAttributesActive?: Array<{
          id: string;
          label: string;
          sortOrder: number;
          selfManageUsers?: string | null;
          dataType?: string | null;
          canEdit: boolean;
          isCustom?: boolean | null;
          isActive: boolean;
          optionsList?: Array<string | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type YearlyAttributeProfileUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  tmpYear: Scalars['ID'];
  input: AttributeInput;
}>;

export type YearlyAttributeProfileUpdateMutation = {
  yearlyAttributeUserUpdate: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type CreateNoteMutationVariables = Exact<{
  input: NoteInput;
}>;

export type CreateNoteMutation = {
  noteCreate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    note?: { title: string; content: string; author: { personId: string } } | null;
  };
};

export type UpdateNoteMutationVariables = Exact<{
  input: NoteInput;
  id: Scalars['ID'];
}>;

export type UpdateNoteMutation = {
  noteUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    note?: { title: string; content: string; author: { personId: string } } | null;
  };
};

export type GetProfileNotesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProfileNotesQuery = {
  person?:
    | {
        __typename: 'PersonNode';
        id: string;
        canModifyNotes: boolean;
        notes?: Array<{
          id: string;
          title: string;
          content: string;
          canEdit?: boolean | null;
          createdDate: any;
          visibility?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
          author: {
            displayName?: string | null;
            pictureUrl?: string | null;
            title?: string | null;
            initials?: string | null;
          };
        } | null> | null;
      }
    | {}
    | null;
};

export type UserHistoryChangelogQueryVariables = Exact<{
  personBeingViewed: Scalars['ID'];
  teamBeingViewed: Scalars['ID'];
}>;

export type UserHistoryChangelogQuery = {
  userHistoryChangelog?: {
    edges: Array<{
      node?: {
        id: string;
        userHistoryChangelogId: string;
        tmpYearId?: number | null;
        changeType: ChangeType;
        oldValue?: Array<string | null> | null;
        newValue?: Array<string | null> | null;
        reason?: string | null;
        notes?: string | null;
        effectiveDate?: any | null;
        actionDate: any;
        team?: { label: string } | null;
        tmpYear?: { label: string } | null;
        actor?: {
          displayName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type UserHistoryTeamsQueryVariables = Exact<{
  personBeingViewed: Scalars['ID'];
}>;

export type UserHistoryTeamsQuery = {
  userHistoryTeams?: {
    edges: Array<{
      node?: { team?: { id: string; teamId: string; label: string } | null } | null;
    } | null>;
  } | null;
};

export type PersonBulkDeactivateMutationVariables = Exact<{
  input?: InputMaybe<BulkPersonDeactivateInput>;
}>;

export type PersonBulkDeactivateMutation = {
  personBulkDeactivate: {
    person?: Array<{ personId: string; active: boolean } | null> | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type PersonBulkGrantAccessMutationVariables = Exact<{
  input: BulkGrantAccessInput;
}>;

export type PersonBulkGrantAccessMutation = {
  personBulkGrantAccess: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type PersonBulkReactivateMutationVariables = Exact<{
  input?: InputMaybe<BulkPersonReactivateInput>;
}>;

export type PersonBulkReactivateMutation = {
  personBulkReactivate: {
    person?: Array<{ personId: string; active: boolean } | null> | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type PersonBulkRemoveTeamMembershipsMutationVariables = Exact<{
  input: PersonBulkRemoveTeamMembersInput;
}>;

export type PersonBulkRemoveTeamMembershipsMutation = {
  personBulkRemoveTeamMemberships: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type PersonBulkUpdateLoginMutationVariables = Exact<{
  input: PersonBulkUpdateLoginInput;
}>;

export type PersonBulkUpdateLoginMutation = {
  personBulkUpdateLogin: {
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ field: Array<string>; message: string } | null> | null;
    }>;
  };
};

export type DeactivatePersonMutationVariables = Exact<{
  input: Scalars['ID'];
}>;

export type DeactivatePersonMutation = {
  personDeactivate: {
    person?: { id: string; displayName?: string | null; active: boolean } | null;
  };
};

export type ProfileReportCreateMutationVariables = Exact<{
  teamId: Scalars['ID'];
  input: ProfileReportInput;
}>;

export type ProfileReportCreateMutation = {
  profileReportCreate: {
    profileReport?: {
      createdDate?: any | null;
      downloadableFileId: string;
      status: string;
      title?: string | null;
      url?: string | null;
    } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type ProfileReportQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileReportQuery = {
  profileReport?: {
    edges: Array<{
      node?: {
        createdDate?: any | null;
        downloadableFileId: string;
        errorMessage?: string | null;
        kind: string;
        status: string;
        title?: string | null;
        url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ReactivatePersonMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ReactivatePersonMutation = {
  personReactivate: {
    person?: { id: string; active: boolean; displayName?: string | null } | null;
    userErrors: Array<{ message: string }>;
  };
};

export type RemovePersonFromTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  teamId: Scalars['ID'];
}>;

export type RemovePersonFromTeamMutation = {
  personRemoveTeamMembership: {
    person?: {
      id: string;
      displayName?: string | null;
      active: boolean;
      teamMembers?: {
        edges: Array<{
          node?: { id: string; teamMemberId: string; active?: boolean | null } | null;
        } | null>;
      } | null;
    } | null;
    userErrors: Array<{ message: string }>;
  };
};

export type TeamRosterReportQueryVariables = Exact<{ [key: string]: never }>;

export type TeamRosterReportQuery = {
  teamRosterReport?: {
    edges: Array<{
      node?: {
        createdDate?: any | null;
        downloadableFileId: string;
        errorMessage?: string | null;
        kind: string;
        status: string;
        title?: string | null;
        url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type TeamRosterReportCreateMutationVariables = Exact<{
  input: TeamRosterReportInput;
}>;

export type TeamRosterReportCreateMutation = {
  teamRosterReportCreate: {
    teamRosterReport?: {
      createdDate?: any | null;
      downloadableFileId: string;
      status: string;
      title?: string | null;
      url?: string | null;
    } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PersonInput;
}>;

export type UpdatePersonMutation = {
  personUpdate: {
    person?: {
      id: string;
      displayName?: string | null;
      hasLoginAccess?: boolean | null;
      usesExternalAuthentication: boolean;
    } | null;
    userErrors: Array<{ message: string }>;
  };
};

export type PersonSendInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PersonSendInvitationInput;
}>;

export type PersonSendInvitationMutation = {
  personSendInvitation: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type CreateUserTypesMutationVariables = Exact<{
  input?: InputMaybe<PersonTypeInput>;
}>;

export type CreateUserTypesMutation = {
  personTypeCreate: {
    personType?: {
      pk?: string | null;
      id: string;
      name: string;
      namePlural: string;
      color?: string | null;
    } | null;
  };
};

export type GetUserTypePermissionsQueryVariables = Exact<{
  personTypeId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type GetUserTypePermissionsQuery = {
  personTypes?: {
    edges: Array<{
      node?: {
        defaultUserTypePermissions?: Array<{
          id?: string | null;
          label?: string | null;
          category?: string | null;
          codeName?: string | null;
          dependencies?: Array<string | null> | null;
          granted?: boolean | null;
          grantedBy?: Array<string | null> | null;
          isRestrictedType?: boolean | null;
          restrictions?: Array<string | null> | null;
          tooltip?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type GetUserTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserTypesQuery = {
  personTypes?: {
    edges: Array<{
      node?: {
        id: string;
        personTypeId: string;
        name: string;
        namePlural: string;
        color?: string | null;
        org?: { label?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type ManageTeamsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type ManageTeamsQuery = {
  teams?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        teamId: string;
        label: string;
        active: boolean;
        members?: {
          edges: Array<{
            node?: {
              teamMemberId: string;
              active?: boolean | null;
              userTypes?: Array<{ name: string; namePlural: string } | null> | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type BulkTeamMemberEditMutationVariables = Exact<{
  input?: InputMaybe<PersonBulkEditTeamMembersInput>;
}>;

export type BulkTeamMemberEditMutation = {
  personBulkUpdateTeamMemberships: { person?: Array<{ pk?: string | null } | null> | null };
};

export type TeamMemberUsersInfoQueryVariables = Exact<{
  codes: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type TeamMemberUsersInfoQuery = {
  selectables?: Array<
    | {
        id: string;
        firstName?: string | null;
        preferredName?: string | null;
        selectionCode?: string | null;
        isOrgLevelUser?: boolean | null;
        teams?: Array<{ id: string; label: string } | null> | null;
      }
    | {
        selectionCode?: string | null;
        people?: Array<{
          id: string;
          firstName?: string | null;
          preferredName?: string | null;
          selectionCode?: string | null;
          isOrgLevelUser?: boolean | null;
          teams?: Array<{ id: string; label: string } | null> | null;
        } | null> | null;
      }
    | {}
    | null
  > | null;
};

export type OrgProfileCategoryDetailQueryVariables = Exact<{
  attributeCategoryId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type OrgProfileCategoryDetailQuery = {
  orgProfileAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        isCustom: boolean;
        isActive: boolean;
        coreCode?: string | null;
        sortOrder?: number | null;
        visibility?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              label?: string | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        editability?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              label?: string | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type OrgYearlyAttributeCategoriesQueryVariables = Exact<{
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
}>;

export type OrgYearlyAttributeCategoriesQuery = {
  orgYearlyAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        isCustom: boolean;
        isActive: boolean;
        sortOrder: number;
        yearlyAttributesActive?: Array<{
          yearlyAttributeCategoryId?: number | null;
          label: string;
          sortOrder: number;
          enumOptions?: string | null;
          hasUserTypeRestriction: boolean;
          selfManageUsers?: string | null;
          id: string;
          dataType?: string | null;
          canEdit: boolean;
          isCustom?: boolean | null;
          optionsList?: Array<string | null> | null;
          rolloverType: RolloverType;
          appliesToUserTypes?: Array<string | null> | null;
          selfManagedUserTypes?: Array<string | null> | null;
          attributeReminders?: Array<{
            id: string;
            reminderType?: AttributeReminderType | null;
            active: boolean;
            visibility?: Array<
              | { label?: string | null }
              | { label?: string | null }
              | { label?: string | null }
              | { label?: string | null }
              | null
            > | null;
            reminderOffset?: {
              weeks?: number | null;
              months?: number | null;
              years?: number | null;
              days?: number | null;
            } | null;
          } | null> | null;
        } | null> | null;
        visibility?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        editability?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type OrgYearlyCategoryDetailQueryVariables = Exact<{
  yearlyAttributeCategoryId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type OrgYearlyCategoryDetailQuery = {
  orgYearlyAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        isCustom: boolean;
        isActive: boolean;
        sortOrder: number;
        visibility?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              label?: string | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        editability?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              label?: string | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type ProfileAttributeCategoryCreateMutationVariables = Exact<{
  input: ProfileAttributeCategoryInput;
}>;

export type ProfileAttributeCategoryCreateMutation = {
  profileAttributeCategoryCreate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    attributeCategory?: { id: string } | null;
  };
};

export type ProfileAttributeCategoryDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProfileAttributeCategoryDeleteMutation = {
  profileAttributeCategoryDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type ProfileAttributeCategoryUpdateMutationVariables = Exact<{
  input: ProfileAttributeCategoryInput;
  id: Scalars['ID'];
}>;

export type ProfileAttributeCategoryUpdateMutation = {
  profileAttributeCategoryUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    attributeCategory?: { id: string } | null;
  };
};

export type ReorderProfileAttributeCategoriesMutationVariables = Exact<{
  input: ProfileAttributeCategoryReorderInput;
}>;

export type ReorderProfileAttributeCategoriesMutation = {
  profileAttributeCategoryReorder: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type AttributeCategoryUpdateMutationVariables = Exact<{
  input: AttributeCategoryInput;
  id: Scalars['ID'];
}>;

export type AttributeCategoryUpdateMutation = {
  attributeCategoryUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    attributeCategory?: { id: string; isActive: boolean } | null;
  };
};

export type ProfileAttributeDatatypesQueryVariables = Exact<{
  sort?: InputMaybe<
    Array<InputMaybe<AttributeDataTypeSortEnum>> | InputMaybe<AttributeDataTypeSortEnum>
  >;
}>;

export type ProfileAttributeDatatypesQuery = {
  profileAttributeDatatypes?: {
    edges: Array<{ node?: { pk?: string | null; id: string; label: string } | null } | null>;
  } | null;
};

export type ProfileAttributeCreateMutationVariables = Exact<{
  input: ProfileAttributeInput;
}>;

export type ProfileAttributeCreateMutation = {
  profileAttributeCreate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    attribute?: { id: string } | null;
  };
};

export type ProfileAttributeDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProfileAttributeDeleteMutation = {
  profileAttributeDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type ProfileAttributeReorderMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  input: ProfileAttributeReorderInput;
}>;

export type ProfileAttributeReorderMutation = {
  profileAttributeReorder: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    attributeCategory?: { id: string } | null;
  };
};

export type ProfileAttributeUpdateMutationVariables = Exact<{
  input: ProfileAttributeInput;
  id: Scalars['ID'];
}>;

export type ProfileAttributeUpdateMutation = {
  profileAttributeUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    attribute?: { id: string } | null;
  };
};

export type OrgProfileAttributeCategoriesQueryVariables = Exact<{
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
}>;

export type OrgProfileAttributeCategoriesQuery = {
  orgProfileAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        coreCode?: string | null;
        isCustom: boolean;
        isActive: boolean;
        sortOrder?: number | null;
        visibility?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        editability?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        profileAttributes?: Array<{
          attributeCategoryId?: number | null;
          categoryLabel?: string | null;
          categoryCoreCode?: string | null;
          label: string;
          coreCode?: string | null;
          sortOrder: number;
          enumOptions?: string | null;
          hasUserTypeRestriction: boolean;
          selfManageUsers?: string | null;
          id: string;
          dataType?: string | null;
          canEdit: boolean;
          isCustom?: boolean | null;
          optionsList?: Array<string | null> | null;
          appliesToUserTypes?: Array<string | null> | null;
          selfManagedUserTypes?: Array<string | null> | null;
          extendedOptionsList?: Array<{
            label?: string | null;
            key?: string | null;
            value?: string | null;
          } | null> | null;
          attributeReminders?: Array<{
            teamId: number;
            reminderType?: AttributeReminderType | null;
            active: boolean;
            pk?: string | null;
            id: string;
            visibility?: Array<
              | { label?: string | null; selectionCode?: string | null }
              | {
                  id: string;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  lastName?: string | null;
                  initials?: string | null;
                  active: boolean;
                  label?: string | null;
                  selectionCode?: string | null;
                  teamMembership?: { isAthlete?: boolean | null } | null;
                }
              | {
                  groupType?: GroupType | null;
                  pluralLabel?: string | null;
                  label?: string | null;
                  selectionCode?: string | null;
                  people?: Array<{
                    id: string;
                    pictureUrl?: string | null;
                    preferredName?: string | null;
                    lastName?: string | null;
                    initials?: string | null;
                    active: boolean;
                    label?: string | null;
                    selectionCode?: string | null;
                    teamMembership?: { isAthlete?: boolean | null } | null;
                  } | null> | null;
                }
              | { label?: string | null; selectionCode?: string | null }
              | null
            > | null;
            reminderOffset?: {
              weeks?: number | null;
              months?: number | null;
              years?: number | null;
              days?: number | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type TeamProfileAttributeCategoriesQueryVariables = Exact<{
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
}>;

export type TeamProfileAttributeCategoriesQuery = {
  teamProfileAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        isCustom: boolean;
        isActive: boolean;
        sortOrder?: number | null;
        team?: { teamId: string; id: string } | null;
        visibility?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        editability?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        profileAttributes?: Array<{
          attributeCategoryId?: number | null;
          categoryLabel?: string | null;
          label: string;
          sortOrder: number;
          enumOptions?: string | null;
          hasUserTypeRestriction: boolean;
          selfManageUsers?: string | null;
          id: string;
          dataType?: string | null;
          canEdit: boolean;
          isCustom?: boolean | null;
          optionsList?: Array<string | null> | null;
          coreCode?: string | null;
          appliesToUserTypes?: Array<string | null> | null;
          selfManagedUserTypes?: Array<string | null> | null;
          extendedOptionsList?: Array<{
            label?: string | null;
            key?: string | null;
            value?: string | null;
          } | null> | null;
          attributeReminders?: Array<{
            teamId: number;
            reminderType?: AttributeReminderType | null;
            active: boolean;
            pk?: string | null;
            id: string;
            visibility?: Array<
              | { label?: string | null; selectionCode?: string | null }
              | {
                  id: string;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  lastName?: string | null;
                  initials?: string | null;
                  active: boolean;
                  label?: string | null;
                  selectionCode?: string | null;
                  teamMembership?: { isAthlete?: boolean | null } | null;
                }
              | {
                  groupType?: GroupType | null;
                  pluralLabel?: string | null;
                  label?: string | null;
                  selectionCode?: string | null;
                  people?: Array<{
                    id: string;
                    pictureUrl?: string | null;
                    preferredName?: string | null;
                    lastName?: string | null;
                    initials?: string | null;
                    active: boolean;
                    label?: string | null;
                    selectionCode?: string | null;
                    teamMembership?: { isAthlete?: boolean | null } | null;
                  } | null> | null;
                }
              | { label?: string | null; selectionCode?: string | null }
              | null
            > | null;
            reminderOffset?: {
              weeks?: number | null;
              months?: number | null;
              years?: number | null;
              days?: number | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type RelationshipTypesCreateMutationVariables = Exact<{
  input?: InputMaybe<PersonRelationshipTypeInput>;
}>;

export type RelationshipTypesCreateMutation = {
  relationshipTypeCreate: {
    personRelationshipType?: { id: string; label: string; usedCount?: number | null } | null;
  };
};

export type RelationshipTypesDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RelationshipTypesDeleteMutation = {
  relationshipTypeDelete: {
    personRelationshipType?: { id: string; label: string; usedCount?: number | null } | null;
  };
};

export type RelationshipTypesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type RelationshipTypesQuery = {
  relationshipTypes?: {
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{ node?: { id: string; label: string; usedCount?: number | null } | null } | null>;
  } | null;
};

export type RelationshipTypesUpdateMutationVariables = Exact<{
  input?: InputMaybe<PersonRelationshipTypeInput>;
  id: Scalars['ID'];
}>;

export type RelationshipTypesUpdateMutation = {
  relationshipTypeUpdate: {
    personRelationshipType?: { id: string; label: string; usedCount?: number | null } | null;
  };
};

export type TeamProfileCategoryDetailQueryVariables = Exact<{
  attributeCategoryId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type TeamProfileCategoryDetailQuery = {
  teamProfileAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        isCustom: boolean;
        isActive: boolean;
        sortOrder?: number | null;
        visibility?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              label?: string | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        editability?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              label?: string | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type TeamYearlyCategoryDetailQueryVariables = Exact<{
  yearlyAttributeCategoryId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type TeamYearlyCategoryDetailQuery = {
  teamYearlyAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        isCustom: boolean;
        isActive: boolean;
        sortOrder: number;
        visibility?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              label?: string | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        editability?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              label?: string | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type TeamYearlyAttributeCategoriesQueryVariables = Exact<{
  includeInactiveRecords?: InputMaybe<Scalars['Boolean']>;
}>;

export type TeamYearlyAttributeCategoriesQuery = {
  teamYearlyAttributeCategories?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        isCustom: boolean;
        isActive: boolean;
        sortOrder: number;
        team?: { label: string } | null;
        yearlyAttributesActive?: Array<{
          yearlyAttributeCategoryId?: number | null;
          label: string;
          sortOrder: number;
          enumOptions?: string | null;
          hasUserTypeRestriction: boolean;
          selfManageUsers?: string | null;
          id: string;
          dataType?: string | null;
          canEdit: boolean;
          isCustom?: boolean | null;
          optionsList?: Array<string | null> | null;
          rolloverType: RolloverType;
          appliesToUserTypes?: Array<string | null> | null;
          selfManagedUserTypes?: Array<string | null> | null;
          attributeReminders?: Array<{
            id: string;
            reminderType?: AttributeReminderType | null;
            active: boolean;
            visibility?: Array<
              | { label?: string | null }
              | { label?: string | null }
              | { label?: string | null }
              | { label?: string | null }
              | null
            > | null;
            reminderOffset?: {
              weeks?: number | null;
              months?: number | null;
              years?: number | null;
              days?: number | null;
            } | null;
          } | null> | null;
        } | null> | null;
        visibility?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        editability?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type UpdateUserTypesMutationVariables = Exact<{
  input?: InputMaybe<PersonTypeInput>;
  id: Scalars['ID'];
}>;

export type UpdateUserTypesMutation = {
  personTypeUpdate: {
    personType?: {
      pk?: string | null;
      id: string;
      name: string;
      namePlural: string;
      color?: string | null;
    } | null;
  };
};

export type YearlyAttributeCategoryCreateMutationVariables = Exact<{
  input: YearlyAttributeCategoryInput;
}>;

export type YearlyAttributeCategoryCreateMutation = {
  yearlyAttributeCategoryCreate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    yearlyAttributeCategory?: {
      id: string;
      label: string;
      team?: { label: string } | null;
      visibility?: Array<
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | null
      > | null;
      editability?: Array<
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | null
      > | null;
    } | null;
  };
};

export type YearlyAttributeCategoryDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type YearlyAttributeCategoryDeleteMutation = {
  yearlyAttributeCategoryDelete: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    yearlyAttributeCategory?: {
      id: string;
      yearlyAttributeCategoryId: string;
      label: string;
      team?: { label: string } | null;
    } | null;
  };
};

export type YearlyAttributeCategoryReorderMutationVariables = Exact<{
  input: YearlyAttributeCategoryReorderInput;
}>;

export type YearlyAttributeCategoryReorderMutation = {
  yearlyAttributeCategoryReorder: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type YearlyAttributeCategoryUpdateMutationVariables = Exact<{
  input: YearlyAttributeCategoryInput;
  id: Scalars['ID'];
}>;

export type YearlyAttributeCategoryUpdateMutation = {
  yearlyAttributeCategoryUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    yearlyAttributeCategory?: {
      id: string;
      yearlyAttributeCategoryId: string;
      label: string;
      visibility?: Array<
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | null
      > | null;
      editability?: Array<
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | { label?: string | null; selectionCode?: string | null }
        | null
      > | null;
      team?: { label: string } | null;
    } | null;
  };
};

export type YearlyAttributeSettingCreateMutationVariables = Exact<{
  input: YearlyAttributeSettingInput;
}>;

export type YearlyAttributeSettingCreateMutation = {
  yearlyAttributeSettingCreate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    yearlyAttribute?: {
      label: string;
      category?: { team?: { label: string } | null } | null;
    } | null;
  };
};

export type YearlyAttributeSettingDeleteMutationVariables = Exact<{
  yearlyAttributeGid: Scalars['ID'];
}>;

export type YearlyAttributeSettingDeleteMutation = {
  yearlyAttributeSettingDelete: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    yearlyAttribute?: {
      label: string;
      category?: { team?: { label: string } | null } | null;
    } | null;
  };
};

export type YearlyAttributeSettingReorderMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: YearlyAttributeSettingReorderInput;
}>;

export type YearlyAttributeSettingReorderMutation = {
  yearlyAttributeSettingReorder: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type YearlyAttributeSettingUpdateMutationVariables = Exact<{
  input: YearlyAttributeSettingInput;
  id: Scalars['ID'];
}>;

export type YearlyAttributeSettingUpdateMutation = {
  yearlyAttributeSettingUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    yearlyAttribute?: {
      label: string;
      category?: { team?: { label: string } | null } | null;
    } | null;
  };
};

export type FetchNotificationSettingQueryVariables = Exact<{
  onlyAthStatusFilter?: InputMaybe<Scalars['Boolean']>;
  onlyUserTypeFilter?: InputMaybe<Scalars['Boolean']>;
  onlyEnabledFilter?: InputMaybe<Scalars['Boolean']>;
  notificationSettingId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type FetchNotificationSettingQuery = {
  notificationSettings?: {
    edges: Array<{
      node?: {
        notificationSettingId?: number | null;
        orgId?: number | null;
        enabled?: boolean | null;
        notifSettingType?: NotificationSettingTypesEnum | null;
        teams?: Array<string | null> | null;
        personStatusTypes?: Array<string | null> | null;
        selectionsToWatch?: Array<
          | { selectionCode?: string | null }
          | {
              personId: string;
              firstName?: string | null;
              lastName?: string | null;
              preferredName?: string | null;
              initials?: string | null;
              pictureUrl?: string | null;
              selectionCode?: string | null;
              teamMembership?: {
                isAthlete?: boolean | null;
                team?: { label: string } | null;
              } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                personId: string;
                firstName?: string | null;
                lastName?: string | null;
                preferredName?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                pictureUrl?: string | null;
                teamMembership?: {
                  isAthlete?: boolean | null;
                  team?: { label: string } | null;
                } | null;
              } | null> | null;
            }
          | { selectionCode?: string | null }
          | null
        > | null;
        selectionsToSendTo?: Array<
          | { selectionCode?: string | null }
          | {
              label?: string | null;
              personId: string;
              firstName?: string | null;
              lastName?: string | null;
              preferredName?: string | null;
              initials?: string | null;
              pictureUrl?: string | null;
              selectionCode?: string | null;
              teamMembership?: {
                isAthlete?: boolean | null;
                team?: { label: string } | null;
              } | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                personId: string;
                firstName?: string | null;
                lastName?: string | null;
                preferredName?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                pictureUrl?: string | null;
                teamMembership?: {
                  isAthlete?: boolean | null;
                  team?: { label: string } | null;
                } | null;
              } | null> | null;
            }
          | { selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchNotificationSettingsQueryVariables = Exact<{
  onlyAthStatusFilter?: InputMaybe<Scalars['Boolean']>;
  onlyUserTypeFilter?: InputMaybe<Scalars['Boolean']>;
  onlyEnabledFilter?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchNotificationSettingsQuery = {
  notificationSettings?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        label?: string | null;
        notificationSettingId?: number | null;
        orgId?: number | null;
        enabled?: boolean | null;
        notifSettingType?: NotificationSettingTypesEnum | null;
        selectionsToWatchCount?: number | null;
        peopleToSendToCount?: number | null;
        teams?: Array<string | null> | null;
        personStatusTypes?: Array<string | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type NotificationSettingBulkSaveMutationVariables = Exact<{
  input?: InputMaybe<NotificationSettingBulkSaveInput>;
}>;

export type NotificationSettingBulkSaveMutation = {
  notificationSettingBulkSave: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type NotificationSettingDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type NotificationSettingDeleteMutation = {
  notificationSettingDelete: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type ProfilesAdvancedFiltersQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type ProfilesAdvancedFiltersQuery = {
  orgProfileAttributeCategories?: {
    edges: Array<{
      node?: {
        label: string;
        coreCode?: string | null;
        profileAttributesForAthlete?: Array<{
          id: string;
          label: string;
          dataType?: string | null;
          optionsList?: Array<string | null> | null;
          isCustom?: boolean | null;
          coreCode?: string | null;
          extendedOptionsList?: Array<{
            label?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
  teamProfileAttributeCategories?: {
    edges: Array<{
      node?: {
        label: string;
        profileAttributesForAthlete?: Array<{
          id: string;
          label: string;
          dataType?: string | null;
          optionsList?: Array<string | null> | null;
          isCustom?: boolean | null;
          coreCode?: string | null;
          extendedOptionsList?: Array<{
            label?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type BulkCreateTeamRosterMutationVariables = Exact<{
  input: TeamRosterBulkCreateInput;
}>;

export type BulkCreateTeamRosterMutation = {
  teamRosterBulkCreate: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type TeamRosterQueryVariables = Exact<{
  tmpYearId?: InputMaybe<Scalars['TWFilter_Int']>;
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type TeamRosterQuery = {
  teamRosters?: {
    edges: Array<{
      node?: {
        id: string;
        rosterPersonsList?: Array<number | null> | null;
        activeAthletesNotOnRoster?: Array<{
          id: string;
          personId: string;
          displayName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type TeamRosterPersonBulkDeleteMutationVariables = Exact<{
  input: TeamRosterPersonBulkInput;
}>;

export type TeamRosterPersonBulkDeleteMutation = {
  teamRosterPersonBulkDelete: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type TeamRosterPersonDeleteMutationVariables = Exact<{
  input: TeamRosterPersonInput;
}>;

export type TeamRosterPersonDeleteMutation = {
  teamRosterPersonDelete: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export type RolloverTeamRosterPersonMutationVariables = Exact<{
  input: TeamRosterPersonRolloverInput;
}>;

export type RolloverTeamRosterPersonMutation = {
  teamRosterPersonRollover: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    teamRosterPerson?: Array<{
      person?: { firstName?: string | null; lastName?: string | null } | null;
    } | null> | null;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ message: string; field: Array<string> } | null> | null;
    }>;
  };
};

export type TeamRosterPersonRolloverYearlyAttributeMutationVariables = Exact<{
  input: TeamRosterPersonRolloverYearlyAttributeInput;
}>;

export type TeamRosterPersonRolloverYearlyAttributeMutation = {
  teamRosterPersonYearlyAttributeBulkRollover: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    teamRosterPerson?: Array<{
      person?: { firstName?: string | null; lastName?: string | null } | null;
    } | null> | null;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ message: string; field: Array<string> } | null> | null;
    }>;
  };
};

export type TeamRostersQueryVariables = Exact<{
  orgId: Scalars['TWFilter_Int'];
}>;

export type TeamRostersQuery = {
  teamRosters?: {
    edges: Array<{
      node?: {
        id: string;
        teamRosterId: string;
        tmpYear?: { label: string } | null;
        team?: { teamId: string; label: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type YearlyAdvancedFiltersQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  athleteType?: InputMaybe<Scalars['ID']>;
}>;

export type YearlyAdvancedFiltersQuery = {
  orgYearlyAttributeCategories?: {
    edges: Array<{
      node?: {
        label: string;
        yearlyAttributesActive?: Array<{
          id: string;
          label: string;
          dataType?: string | null;
          optionsList?: Array<string | null> | null;
          isCustom?: boolean | null;
          coreCode?: string | null;
          rolloverType: RolloverType;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
  teamYearlyAttributeCategories?: {
    edges: Array<{
      node?: {
        label: string;
        yearlyAttributesActive?: Array<{
          id: string;
          label: string;
          dataType?: string | null;
          optionsList?: Array<string | null> | null;
          isCustom?: boolean | null;
          coreCode?: string | null;
          rolloverType: RolloverType;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type UniqueFileVideoNameListQueryVariables = Exact<{
  nameFilter?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type UniqueFileVideoNameListQuery = {
  uniqueFileVideoNameList?: {
    edges: Array<{
      node?: {
        videoId?: number | null;
        sharedFileId?: number | null;
        video?: { title: string } | null;
        sharedFile?: { fileName: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type AllUserFileVideoActionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['TWFilter_Int']>;
  actionType?: InputMaybe<Scalars['TWFilter_String']>;
  assetType?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['TWFilter_Int']>;
  sharedFileId?: InputMaybe<Scalars['TWFilter_Int']>;
  actionDatetime?: InputMaybe<Scalars['TWFilter_DateTime']>;
  sort?: InputMaybe<
    Array<InputMaybe<UserFileVideoActionSortEnum>> | InputMaybe<UserFileVideoActionSortEnum>
  >;
}>;

export type AllUserFileVideoActionsQuery = {
  history?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        pk?: string | null;
        actionType: string;
        createdBy?: number | null;
        teamId?: number | null;
        actionDatetime?: any | null;
        videoId?: number | null;
        sharedFileId?: number | null;
        creator?: {
          personId: string;
          preferredName?: string | null;
          lastName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
        } | null;
        video?: { title: string; isPinned: boolean } | null;
        sharedFile?: { fileName: string; isPinned?: boolean | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type BulkDeactivateCustomTaskMutationVariables = Exact<{
  input: CustomTaskBulkDeactivateInput;
}>;

export type BulkDeactivateCustomTaskMutation = {
  customTaskBulkDeactivate: {
    customTask?: Array<{
      name?: string | null;
      description?: string | null;
      dueDate?: any | null;
      status?: CustomTaskStatus | null;
      alertNotificationType?: AlertNotificationType | null;
      customTaskType?: CustomTaskType | null;
      visibility?: Array<string | null> | null;
      isActive?: boolean | null;
      updatedBy?: number | null;
    } | null> | null;
  };
};

export type CreateCustomTaskMutationVariables = Exact<{
  input: CustomTaskCreateInput;
}>;

export type CreateCustomTaskMutation = {
  customTaskCreate: {
    customTask?: {
      name?: string | null;
      description?: string | null;
      dueDate?: any | null;
      status?: CustomTaskStatus | null;
      alertNotificationType?: AlertNotificationType | null;
      customTaskType?: CustomTaskType | null;
      visibility?: Array<string | null> | null;
    } | null;
  };
};

export type CustomTaskAssignmentCompleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CustomTaskAssignmentCompleteMutation = {
  customTaskAssignmentComplete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type CustomTaskAssignmentsBulkActionsMutationVariables = Exact<{
  input: CustomTaskAssignmentBulkActionsInput;
}>;

export type CustomTaskAssignmentsBulkActionsMutation = {
  customTaskAssignmentBulkActions: { customTaskAssignment?: Array<{ id: string } | null> | null };
};

export type CustomTaskAssignmentsExportCreateMutationVariables = Exact<{
  input: CustomTaskAssignmentsExportInput;
}>;

export type CustomTaskAssignmentsExportCreateMutation = {
  customTaskAssignmentsExportCreate: {
    customTaskAssignmentsExport?: {
      createdDate?: any | null;
      downloadableFileId: string;
      status: string;
      title?: string | null;
      url?: string | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type CustomTaskAssignmentsExportQueryVariables = Exact<{ [key: string]: never }>;

export type CustomTaskAssignmentsExportQuery = {
  customTaskAssignmentsExport?: {
    edges: Array<{
      node?: {
        createdDate?: any | null;
        downloadableFileId: string;
        errorMessage?: string | null;
        kind: string;
        status: string;
        title?: string | null;
        url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CustomTaskLabelCreateMutationVariables = Exact<{
  input?: InputMaybe<CustomTaskLabelInput>;
}>;

export type CustomTaskLabelCreateMutation = {
  customTaskLabelCreate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    customTaskLabel?: { label?: string | null; pk?: string | null } | null;
  };
};

export type CustomTaskLabelDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CustomTaskLabelDeleteMutation = {
  customTaskLabelDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type CustomTaskLabelUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<CustomTaskLabelInput>;
}>;

export type CustomTaskLabelUpdateMutation = {
  customTaskLabelUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    customTaskLabel?: { label?: string | null } | null;
  };
};

export type CustomTaskLabelsQueryVariables = Exact<{
  sort?: InputMaybe<
    Array<InputMaybe<CustomTaskLabelSortEnum>> | InputMaybe<CustomTaskLabelSortEnum>
  >;
}>;

export type CustomTaskLabelsQuery = {
  customTaskLabels?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        label?: string | null;
        pk?: string | null;
        id: string;
        associatedTaskCount?: number | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchAllCustomTasksQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['TWFilter_CustomTaskStatus']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<CustomTaskSortEnum>> | InputMaybe<CustomTaskSortEnum>>;
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type FetchAllCustomTasksQuery = {
  allCustomTasks?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        name?: string | null;
        description?: string | null;
        dueDate?: any | null;
        createdDate?: any | null;
        updatedDate?: any | null;
        id: string;
        status?: CustomTaskStatus | null;
        labels?: Array<{ label?: string | null; pk?: string | null } | null> | null;
        creator?: { displayName?: string | null; pictureUrl?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchVisibleCustomTasksQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['TWFilter_CustomTaskStatus']>;
  createdBy?: InputMaybe<Scalars['TWFilter_Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<CustomTaskSortEnum>> | InputMaybe<CustomTaskSortEnum>>;
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type FetchVisibleCustomTasksQuery = {
  myCustomTasks?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        name?: string | null;
        description?: string | null;
        dueDate?: any | null;
        createdDate?: any | null;
        updatedDate?: any | null;
        id: string;
        status?: CustomTaskStatus | null;
        labels?: Array<{ label?: string | null; pk?: string | null } | null> | null;
        creator?: { displayName?: string | null; pictureUrl?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetCustomTaskQueryVariables = Exact<{
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['TWFilter_CustomTaskAssignmentStatus']>;
  searchName?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<
    Array<InputMaybe<CustomTaskAssignmentSortEnum>> | InputMaybe<CustomTaskAssignmentSortEnum>
  >;
}>;

export type GetCustomTaskQuery = {
  customTask?:
    | {
        alertNotificationType?: AlertNotificationType | null;
        id: string;
        name?: string | null;
        description?: string | null;
        dueDate?: any | null;
        createdDate?: any | null;
        status?: CustomTaskStatus | null;
        updatedDate?: any | null;
        visibility?: Array<string | null> | null;
        labels?: Array<{ label?: string | null; pk?: string | null; id: string } | null> | null;
        creator?: {
          displayName?: string | null;
          fullNameNormalOrder?: string | null;
          pictureUrl?: string | null;
        } | null;
        assignmentCountByStatus?: Array<{
          status?: string | null;
          count?: number | null;
        } | null> | null;
        assignments?: {
          totalCount?: number | null;
          pageInfo: {
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string | null;
            endCursor?: string | null;
          };
          edges: Array<{
            node?: {
              id: string;
              createdDate?: any | null;
              customTaskAssignmentId: string;
              completedDate?: any | null;
              manuallyCompletedDate?: any | null;
              manuallyCompletedNote?: string | null;
              status?: CustomTaskAssignmentStatus | null;
              updatedDate?: any | null;
              updatedBy?: number | null;
              assignee?: {
                fullName?: string | null;
                selectionCode?: string | null;
                userTypesByTeam?: any | null;
                pk?: string | null;
                initials?: string | null;
                pictureUrl?: string | null;
                displayName?: string | null;
              } | null;
            } | null;
          } | null>;
        } | null;
      }
    | {}
    | null;
};

export type GetCustomTaskAssignersQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomTaskAssignersQuery = {
  customTaskAssigners?: Array<{
    assignerPrettyValue?: string | null;
    assignerRawValues?: Array<string | null> | null;
  } | null> | null;
};

export type GetCustomTaskCompletionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomTaskCompletionsQuery = {
  customTaskCompletions?: Array<{
    customTaskName?: string | null;
    customTaskDescription?: string | null;
    customTaskId?: string | null;
    status?: string | null;
    assignerPrettyValue?: string | null;
    assignedDate?: any | null;
    dueDate?: any | null;
    completedDate?: any | null;
    manuallyCompletedDate?: any | null;
    labels?: Array<string | null> | null;
  } | null> | null;
};

export type GetCustomTasksCountQueryVariables = Exact<{
  canManageTasks: Scalars['Boolean'];
}>;

export type GetCustomTasksCountQuery = {
  myCustomTasks?: { totalCount?: number | null } | null;
  allCustomTasks?: { totalCount?: number | null } | null;
};

export type GetTasksQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  assigners?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  before?: InputMaybe<Scalars['String']>;
  dashboardEligible?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  hasArms?: InputMaybe<Scalars['Boolean']>;
  includeAcademicTasks?: InputMaybe<Scalars['Boolean']>;
  isAcademicTask?: InputMaybe<Scalars['Boolean']>;
  ownerApp?: InputMaybe<Array<InputMaybe<TwOwnerAppFilterEnum>> | InputMaybe<TwOwnerAppFilterEnum>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<MyTaskSortEnum>> | InputMaybe<MyTaskSortEnum>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<InputMaybe<TaskStatusFilterEnum>> | InputMaybe<TaskStatusFilterEnum>>;
  withStatus?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetTasksQuery = {
  myTasks?: {
    totalCount?: number | null;
    allAssigners?: Array<{
      assignerPrettyValue?: string | null;
      assignerRawValues?: Array<string | null> | null;
    } | null> | null;
    allOwnerApps?: Array<{ ownerApp?: string | null; taskCount?: number | null } | null> | null;
    edges: Array<{
      node?: {
        assigner?: string | null;
        assignerPrettyValue?: string | null;
        createdAt?: any | null;
        description?: string | null;
        dueDate?: any | null;
        expirationDate?: any | null;
        ownerApp: string;
        ownerId: string;
        ownerType: string;
        showDueDate?: boolean | null;
        taskUrl: string;
        taskUrlWeb?: string | null;
        title: string;
        status?: TaskStatusFilterEnum | null;
        extra?: { category?: string | null; course?: string | null } | null;
      } | null;
    } | null>;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type GetMyTaskAssignersQueryVariables = Exact<{
  hasArms?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetMyTaskAssignersQuery = {
  myTaskAssigners?: Array<{
    assignerPrettyValue?: string | null;
    assignerRawValues?: Array<string | null> | null;
  } | null> | null;
};

export type GetTasksCountQueryVariables = Exact<{
  hasArms?: InputMaybe<Scalars['Boolean']>;
  includeAcademicTasks?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetTasksCountQuery = { myTasks?: { totalCount?: number | null } | null };

export type UpdateCustomTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CustomTaskUpdateInput;
}>;

export type UpdateCustomTaskMutation = {
  customTaskUpdate: {
    customTask?: {
      name?: string | null;
      description?: string | null;
      dueDate?: any | null;
      status?: CustomTaskStatus | null;
      alertNotificationType?: AlertNotificationType | null;
      customTaskType?: CustomTaskType | null;
    } | null;
  };
};

export type FetchCaraLogDatesQueryVariables = Exact<{
  startDateArg: Scalars['Date'];
  endDateArg: Scalars['Date'];
  logConfigIdArg: Scalars['Int'];
  teamId?: InputMaybe<Scalars['TWFilter_Int']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type FetchCaraLogDatesQuery = {
  tmpActivityLogDatesList?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        pk?: string | null;
        tmpYearId: number;
        isUserLogAdmin?: boolean | null;
        isUserComplianceUser?: boolean | null;
        isUserViewer?: boolean | null;
        logDates?: Array<{
          logStartDate?: any | null;
          logEndDate?: any | null;
          existingLogId?: number | null;
        } | null> | null;
        logs?: Array<{
          submittedDate?: string | null;
          isDraft?: boolean | null;
          tmpActivityLogId: string;
          logStartDate: any;
          logEndDate: any;
          pendingStageSeq?: number | null;
          submittedNames?: Array<{
            submittedName?: string | null;
            personId?: number | null;
          } | null> | null;
          config?: { team?: { label: string } | null } | null;
          responses?: Array<{
            notes?: string | null;
            approval?: boolean | null;
            person?: {
              firstName?: string | null;
              lastName?: string | null;
              personId: string;
            } | null;
            stage?: { label: string } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchCaraLogListQueryVariables = Exact<{
  tmpTeamIdsArg?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  tmpYearIdArg: Scalars['Int'];
  startDateArg?: InputMaybe<Scalars['String']>;
  endDateArg?: InputMaybe<Scalars['String']>;
  logPendingStageSeqsArg?: InputMaybe<
    Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>
  >;
  isDraftArg?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type FetchCaraLogListQuery = {
  tmpLogList?: {
    totalCount?: number | null;
    pageInfo: {
      hasPreviousPage: boolean;
      startCursor?: string | null;
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        isUserLogAdmin?: boolean | null;
        isUserComplianceUser?: boolean | null;
        isUserViewer?: boolean | null;
        submittedDate?: string | null;
        isDraft?: boolean | null;
        tmpActivityLogId: string;
        logStartDate: any;
        logEndDate: any;
        pendingStageSeq?: number | null;
        submittedNames?: Array<{
          submittedName?: string | null;
          personId?: number | null;
        } | null> | null;
        config?: { team?: { label: string } | null } | null;
        responses?: Array<{
          notes?: string | null;
          approval?: boolean | null;
          person?: { personId: string; firstName?: string | null; lastName?: string | null } | null;
          stage?: { label: string } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchCaraLogListIdsQueryVariables = Exact<{
  tmpTeamIdsArg?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  tmpYearIdArg: Scalars['Int'];
  startDateArg?: InputMaybe<Scalars['String']>;
  endDateArg?: InputMaybe<Scalars['String']>;
  logPendingStageSeqsArg?: InputMaybe<
    Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>
  >;
  isDraftArg?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchCaraLogListIdsQuery = {
  tmpLogList?: {
    totalCount?: number | null;
    pageInfo: {
      hasPreviousPage: boolean;
      startCursor?: string | null;
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges: Array<{ node?: { tmpActivityLogId: string } | null } | null>;
  } | null;
};

export type FetchCaraLogDetailsQueryVariables = Exact<{
  tmpYearIdArg?: InputMaybe<Scalars['Int']>;
  tmpActivityLogId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type FetchCaraLogDetailsQuery = {
  tmpLogList?: {
    totalCount?: number | null;
    pageInfo: { hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      node?: {
        tmpActivityLogId: string;
        isDraft?: boolean | null;
        pendingStageSeq?: number | null;
        isUserComplianceUser?: boolean | null;
        isUserLogAdmin?: boolean | null;
        isUserViewer?: boolean | null;
        showSkillInstruction?: boolean | null;
        comments?: Array<{
          content: string;
          updatedDateTime?: string | null;
          creator?: {
            firstName?: string | null;
            lastName?: string | null;
            pictureUrl?: string | null;
            personId: string;
          } | null;
        } | null> | null;
        vacationDays?: Array<{ date?: string | null; label?: string | null } | null> | null;
        segmentTypePerDay?: Array<{ date?: string | null; label?: string | null } | null> | null;
        tmpTeamSegment?: { weekStart?: number | null } | null;
        logAthletesSummary?: Array<{
          tmpActivityLogId?: number | null;
          tmpActivityLogSignoffResponseId?: number | null;
          personId?: number | null;
          firstName?: string | null;
          lastName?: string | null;
          hasWeeklyOverage?: boolean | null;
          weeklyDaysOff?: number | null;
          isWeeklyDaysOffInViolation?: boolean | null;
          multiSportAdjustment?: boolean | null;
          expectedSkillInstructionMinutes?: number | null;
          actualSkillInstructionMinutes?: number | null;
          entries?: Array<{
            totalCountableMinutes?: number | null;
            date?: string | null;
            dailyViolations?: {
              hasDailyOverage?: boolean | null;
              hasCaraOnVacation?: boolean | null;
              hasCaraOnDayOff?: boolean | null;
            } | null;
            logAppointments?: Array<{
              appointmentTitle?: string | null;
              appointmentTypeLabel?: string | null;
              appointmentTypeColor?: string | null;
              appointmentOccurrenceId?: number | null;
              countableMinutes?: number | null;
              editedCountableStartDate?: string | null;
              editedCountableEndDate?: string | null;
              occStartDateTime?: string | null;
              occEndDateTime?: string | null;
              tmpActivityLogAppointmentId?: number | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
        responses?: Array<{
          approval?: boolean | null;
          notes?: string | null;
          tmpActivityLogSignoffResponseId: string;
          updatedDateTime?: string | null;
          person?: {
            personId: string;
            firstName?: string | null;
            lastName?: string | null;
            pictureUrl?: string | null;
          } | null;
          stage?: { label: string } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchTmpDateTypesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTmpDateTypesQuery = {
  tmpDateTypes?: {
    edges: Array<{ node?: { tmpDateTypeId: string; code?: string | null } | null } | null>;
  } | null;
};

export type FetchTmpDatesByTypeQueryVariables = Exact<{
  tmpDateTypeId?: InputMaybe<Scalars['TWFilter_Int']>;
}>;

export type FetchTmpDatesByTypeQuery = {
  tmpDates?: {
    edges: Array<{
      node?: {
        id: string;
        tmpDateId: string;
        tmpDateTypeId: number;
        tmpYearId?: number | null;
        startDate?: string | null;
        endDate?: string | null;
        dateType?: { id: string; tmpDateTypeId: string; code?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchTmpYearsAndSegmentsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTmpYearsAndSegmentsQuery = {
  tmpYears?: {
    totalCount?: number | null;
    edges: Array<{
      cursor: string;
      node?: {
        isCurrent: boolean;
        tmpYearId: string;
        label: string;
        tmpTeamDetails?: Array<{
          tmpTeamId?: number | null;
          teamId?: number | null;
          label?: string | null;
          tmpActivityLogConfigId?: number | null;
          segments?: Array<{
            segmentStartDate?: any | null;
            segmentEndDate?: any | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type AddCommentMutationVariables = Exact<{
  fileData?: InputMaybe<Scalars['Upload']>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ComplianceAuditAddCommentInput>;
}>;

export type AddCommentMutation = {
  addAuditComment: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    appointmentComplianceAudit?: {
      pk?: string | null;
      createdDateTime?: string | null;
      creator?: {
        firstName?: string | null;
        lastName?: string | null;
        pictureUrl?: string | null;
        initials?: string | null;
      } | null;
    } | null;
  };
};

export type AthleteInfoQueryVariables = Exact<{
  codes: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type AthleteInfoQuery = {
  selectables?: Array<
    | { selectionCode?: string | null }
    | {
        firstName?: string | null;
        lastName?: string | null;
        pictureUrl?: string | null;
        initials?: string | null;
        selectionCode?: string | null;
      }
    | { pluralLabel?: string | null; selectionCode?: string | null }
    | { selectionCode?: string | null }
    | null
  > | null;
};

export type FetchCaraChangesQueryVariables = Exact<{
  afterDate?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  beforeDate?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  conflictType?: InputMaybe<ConflictType>;
  reviewStatusFilter?: InputMaybe<ReviewStatusFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['Int']>;
}>;

export type FetchCaraChangesQuery = {
  tmpChangeHistory?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        appointmentComplianceAuditId: string;
        changeTime?: any | null;
        reviewStatus?: string | null;
        diff?: {
          changes?: {
            startDate?: { new?: any | null } | null;
            endDate?: { new?: any | null } | null;
            startTimeZone?: { new?: string | null } | null;
            countableMinutes?: { new?: number | null } | null;
            location?: { new?: string | null } | null;
            appointmentType?: { new?: string | null } | null;
            complianceType?: { new?: string | null } | null;
            recurRule?: { new?: string | null } | null;
            attendees?: { same?: Array<string | null> | null } | null;
          } | null;
          original?: {
            title?: string | null;
            startDate?: any | null;
            appointmentType?: string | null;
            complianceType?: string | null;
            action?: string | null;
            changeType?: string | null;
            conflictDates?: Array<string | null> | null;
          } | null;
        } | null;
        teams?: Array<{ pk?: string | null; label: string } | null> | null;
        conflictsList?: Array<{ label?: string | null } | null> | null;
        creator?: { firstName?: string | null; lastName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchCaraChangesExportQueryVariables = Exact<{
  afterDate?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  beforeDate?: InputMaybe<Scalars['GraphenePendulumDateTime']>;
  conflictType?: InputMaybe<ConflictType>;
  reviewStatusFilter?: InputMaybe<ReviewStatusFilter>;
  teamId?: InputMaybe<Scalars['Int']>;
}>;

export type FetchCaraChangesExportQuery = {
  tmpChangeHistory?: {
    edges: Array<{
      node?: {
        changeTime?: any | null;
        reviewStatus?: string | null;
        conflictReasonCode?: string | null;
        conflictReasonText?: string | null;
        diff?: {
          changes?: {
            startDate?: { new?: any | null } | null;
            endDate?: { new?: any | null } | null;
            startTimeZone?: { new?: string | null } | null;
            countableMinutes?: { new?: number | null } | null;
            location?: { new?: string | null } | null;
            appointmentType?: { new?: string | null } | null;
            complianceType?: { new?: string | null } | null;
            recurRule?: { new?: string | null } | null;
            attendees?: { raw?: string | null } | null;
          } | null;
          original?: {
            title?: string | null;
            location?: string | null;
            startDate?: any | null;
            appointmentType?: string | null;
            attendeeSelectionCodes?: Array<string | null> | null;
            action?: string | null;
          } | null;
        } | null;
        teams?: Array<{ pk?: string | null; label: string } | null> | null;
        conflictsList?: Array<{ label?: string | null; bylaw?: string | null } | null> | null;
        creator?: { firstName?: string | null; lastName?: string | null } | null;
        comments?: Array<{
          note?: string | null;
          attachments?: Array<{ pk?: number | null } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchCaraChangesDetailQueryVariables = Exact<{
  appointmentComplianceAuditId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type FetchCaraChangesDetailQuery = {
  tmpChangeHistory?: {
    edges: Array<{
      node?: {
        changeTime?: any | null;
        reviewStatus?: string | null;
        conflictReasonCode?: string | null;
        conflictReasonText?: string | null;
        diff?: {
          changes?: {
            startDate?: { new?: any | null; old?: any | null } | null;
            endDate?: { new?: any | null; old?: any | null } | null;
            startTimeZone?: { new?: string | null; old?: string | null } | null;
            countableMinutes?: { new?: number | null; old?: number | null } | null;
            location?: { new?: string | null; old?: string | null } | null;
            appointmentType?: { new?: string | null; old?: string | null } | null;
            complianceType?: { new?: string | null; old?: string | null } | null;
            recurRule?: { new?: string | null; old?: string | null } | null;
            attendees?: {
              removed?: Array<string | null> | null;
              added?: Array<string | null> | null;
            } | null;
            title?: { new?: string | null; old?: string | null } | null;
          } | null;
          original?: {
            title?: string | null;
            location?: string | null;
            startDate?: any | null;
            endDate?: any | null;
            timeZone?: string | null;
            appointmentType?: string | null;
            complianceType?: string | null;
            countableMinutes?: number | null;
            attendeeSelectionCodes?: Array<string | null> | null;
            action?: string | null;
            recurRule?: string | null;
            changeType?: string | null;
            conflictDates?: Array<string | null> | null;
          } | null;
        } | null;
        teams?: Array<{ pk?: string | null; label: string } | null> | null;
        conflictsList?: Array<{ label?: string | null; bylaw?: string | null } | null> | null;
        creator?: { firstName?: string | null; lastName?: string | null } | null;
        comments?: Array<{
          note?: string | null;
          createdDateTime?: string | null;
          creator?: {
            firstName?: string | null;
            lastName?: string | null;
            pictureUrl?: string | null;
            initials?: string | null;
          } | null;
          attachments?: Array<{
            pk?: number | null;
            fileName: string;
            downloadUrl?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type UpdateChangeStatusMutationVariables = Exact<{
  input: ComplianceAuditsChangeReviewStatusInput;
}>;

export type UpdateChangeStatusMutation = {
  changeAuditReview: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    appointmentComplianceAudit?: Array<{
      pk?: string | null;
      reviewStatus?: string | null;
    } | null> | null;
  };
};

export type FetchTmpOverviewAthleteEventsQueryVariables = Exact<{
  filters?: InputMaybe<TmpCalendarOverviewInput>;
}>;

export type FetchTmpOverviewAthleteEventsQuery = {
  tmpCalendarOverviewAthlete?: Array<{
    appointmentTitle?: string | null;
    startDateTime?: any | null;
    endDateTime?: any | null;
    countableMinutes?: number | null;
    color?: string | null;
    dayOffType?: string | null;
    countableApptType?: string | null;
    isAllDay?: boolean | null;
  } | null> | null;
};

export type FetchTmpOverviewAthletesQueryVariables = Exact<{
  selectedTeam: Scalars['ID'];
  athleteType: Scalars['ID'];
}>;

export type FetchTmpOverviewAthletesQuery = {
  groups?: Array<{
    label?: string | null;
    groupType?: GroupType | null;
    people?: Array<{
      id: string;
      key: string;
      label?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
};

export type FetchTmpOverviewLegendQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTmpOverviewLegendQuery = {
  tmpCalendarOverviewLegend?: Array<{
    displayName?: string | null;
    colorCode?: string | null;
    displayType?: string | null;
  } | null> | null;
};

export type FetchTmpOverviewTmpEventsQueryVariables = Exact<{
  tmpTeamIdArg: Scalars['ID'];
}>;

export type FetchTmpOverviewTmpEventsQuery = {
  tmpCalendarOverviewDeclarations?: Array<{
    isInterval?: boolean | null;
    startAppointment?: {
      appointmentTypeLabel?: string | null;
      appointmentTitle?: string | null;
      startDateTime?: any | null;
      endDateTime?: any | null;
      color?: string | null;
    } | null;
    endAppointment?: {
      appointmentTypeLabel?: string | null;
      appointmentTitle?: string | null;
      startDateTime?: any | null;
      endDateTime?: any | null;
      color?: string | null;
    } | null;
  } | null> | null;
};

export type FetchTmpYearRulesQueryVariables = Exact<{
  tmpYearId: Scalars['TWFilter_Int'];
}>;

export type FetchTmpYearRulesQuery = {
  tmpYearRules?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        tmpYearRuleId: string;
        id: string;
        notifyCompliance?: boolean | null;
        commentRequired?: boolean | null;
        newApptNotificationHours?: number | null;
        modApptNotificationHours?: number | null;
        isEnabled?: boolean | null;
        rule?: Array<{
          bylaw?: string | null;
          label?: string | null;
          code: string;
          description: string;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateTmpDateMutationVariables = Exact<{
  input: TmpDateCreateInput;
}>;

export type CreateTmpDateMutation = {
  tmpDateCreate: {
    tmpDate?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ message: string; field: Array<string> } | null> | null;
    }>;
  };
};

export type DeleteTmpDateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTmpDateMutation = {
  tmpDateDelete: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ message: string; field: Array<string> } | null> | null;
    }>;
  };
};

export type FetchTmpDatesQueryVariables = Exact<{
  tmpYearIdArg?: InputMaybe<Scalars['Int']>;
}>;

export type FetchTmpDatesQuery = {
  tmpDates?: {
    edges: Array<{
      node?: {
        id: string;
        tmpDateId: string;
        tmpDateTypeId: number;
        startDate?: string | null;
        endDate?: string | null;
        label?: string | null;
        dateType?: { id: string; tmpDateTypeId: string; code?: string | null } | null;
      } | null;
    } | null>;
  } | null;
  tmpDateTypes?: {
    edges: Array<{ node?: { tmpDateTypeId: string; code?: string | null } | null } | null>;
  } | null;
};

export type UpdateTmpDateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TmpDateUpdateInput;
}>;

export type UpdateTmpDateMutation = {
  tmpDateUpdate: {
    tmpDate?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ message: string; field: Array<string> } | null> | null;
    }>;
  };
};

export type UpdateTmpYearMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TmpYearUpdateInput;
}>;

export type UpdateTmpYearMutation = {
  tmpYearUpdate: {
    userErrors: Array<{ message: string; field: Array<string> }>;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ message: string; field: Array<string> } | null> | null;
    }>;
    tmpYear?: { id: string; tmpYearId: string; label: string; isCurrent: boolean } | null;
  };
};

export type CreateTmpYearRulesMutationVariables = Exact<{
  input: TmpYearRuleInput;
}>;

export type CreateTmpYearRulesMutation = {
  tmpYearRuleCreate: {
    tmpYearRule?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type UpdateTmpYearRulesMutationVariables = Exact<{
  input: TmpYearRuleInput;
  id: Scalars['ID'];
}>;

export type UpdateTmpYearRulesMutation = {
  tmpYearRuleUpdate: {
    tmpYearRule?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type AddSegmentMutationVariables = Exact<{
  input: TmpTeamSegmentInput;
}>;

export type AddSegmentMutation = {
  tmpTeamSegmentCreate: {
    tmpTeamSegment?: {
      id: string;
      label: string;
      acclimatizationPeriodStartDate?: string | null;
      acclimatizationPeriodEndDate?: string | null;
      firstCaraDate?: string | null;
      isChampionship?: boolean | null;
      nineWeekPeriodStartDate?: string | null;
      orientationDate?: string | null;
      preSeasonStartDate?: string | null;
      preSeasonEndDate?: string | null;
      postSeasonStartDate?: string | null;
      postSeasonEndDate?: string | null;
      postSeasonDaysoffStartDate?: string | null;
      postSeasonDaysoffEndDate?: string | null;
      seasonType: string;
      segmentEndDate?: string | null;
      segmentStartDate?: string | null;
      tmpSegmentType?: string | null;
      weekStart?: number | null;
      metaData?: {
        daysOff?: string | null;
        selectedNonCountableDays?: Array<string | null> | null;
      } | null;
    } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type AddTmpTeamMutationVariables = Exact<{
  input: TmpTeamInput;
}>;

export type AddTmpTeamMutation = {
  tmpTeamCreate: {
    tmpTeam?: { id: string; tmpTeamId: string; tmpYearId: number } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type DeleteSegmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteSegmentMutation = {
  tmpTeamSegmentDelete: {
    tmpTeamSegment?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type FetchSegmentConflictsQueryVariables = Exact<{
  tmpTeamSegmentId: Scalars['TWFilter_ID'];
}>;

export type FetchSegmentConflictsQuery = {
  tmpTeamSegmentConflict?: {
    edges: Array<{
      node?: {
        tmpTeamSegmentId: string;
        id: string;
        allTmpConflicts?: Array<{
          additionalConflicts?: Array<{
            academicYearStartDate?: any | null;
            athletes?: Array<{
              numAppts?: number | null;
              selectionCode?: Array<
                | { selectionCode?: string | null }
                | {
                    pictureUrl?: string | null;
                    preferredName?: string | null;
                    lastName?: string | null;
                    initials?: string | null;
                    selectionCode?: string | null;
                  }
                | { selectionCode?: string | null }
                | { selectionCode?: string | null }
                | null
              > | null;
            } | null> | null;
          } | null> | null;
          weeklyConflicts?: Array<{
            tmpTeamSegmentId?: number | null;
            weekLabel?: string | null;
            startDate?: any | null;
            endDate?: any | null;
            athletes?: Array<{
              selectionCode?: Array<
                | { selectionCode?: string | null }
                | {
                    pictureUrl?: string | null;
                    preferredName?: string | null;
                    lastName?: string | null;
                    initials?: string | null;
                    selectionCode?: string | null;
                  }
                | { selectionCode?: string | null }
                | { selectionCode?: string | null }
                | null
              > | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchTmpSegmentsQueryVariables = Exact<{
  tmpTeamIdArg?: InputMaybe<Scalars['Int']>;
}>;

export type FetchTmpSegmentsQuery = {
  tmpTeamSegment?: {
    edges: Array<{
      node?: {
        tmpTeamSegmentId: string;
        id: string;
        label: string;
        segmentStartDate?: string | null;
        segmentEndDate?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchTmpTeamsQueryVariables = Exact<{
  orgId: Scalars['TWFilter_Int'];
}>;

export type FetchTmpTeamsQuery = {
  tmpTeams?: {
    edges: Array<{
      node?: { teamId: number; team?: { label: string; orgId?: number | null } | null } | null;
    } | null>;
  } | null;
};

export type FetchTmpYearsQueryVariables = Exact<{
  orgId: Scalars['TWFilter_Int'];
  withTmpTeams?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchTmpYearsQuery = {
  tmpYears?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        id: string;
        isCurrent: boolean;
        tmpYearId: string;
        label: string;
        orgId: number;
        tmpTeams?: Array<{
          hasEditDeclarationPerm?: boolean | null;
          tmpTeamId: string;
          teamId: number;
          team?: { label: string; orgId?: number | null } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchTeamSegmentsQueryVariables = Exact<{
  tmpYearId: Scalars['TWFilter_Int'];
  tmpTeamId: Scalars['TWFilter_ID'];
}>;

export type FetchTeamSegmentsQuery = {
  tmpTeams?: {
    edges: Array<{
      node?: {
        hasAthleteGroups?: boolean | null;
        team?: { label: string; teamId: string; canAddToTeam?: boolean | null } | null;
        segments?: Array<{
          tmpTeamSegmentId: string;
          id: string;
          tmpTeamId: number;
          status?: string | null;
          seasonType: string;
          tmpSegmentType?: string | null;
          label: string;
          orientationDate?: string | null;
          weekStart?: number | null;
          segmentOrder?: number | null;
          isChampionship?: boolean | null;
          firstCompetitionDate?: string | null;
          firstCaraDate?: string | null;
          nineWeekPeriodStartDate?: string | null;
          preSeasonStartDate?: string | null;
          preSeasonEndDate?: string | null;
          postSeasonStartDate?: string | null;
          postSeasonEndDate?: string | null;
          postSeasonDaysoffEndDate?: string | null;
          postSeasonDaysoffStartDate?: string | null;
          segmentStartDate?: string | null;
          segmentEndDate?: string | null;
          acclimatizationPeriodStartDate?: string | null;
          acclimatizationPeriodEndDate?: string | null;
          metaData?: {
            daysOff?: string | null;
            selectedNonCountableDays?: Array<string | null> | null;
          } | null;
          summerDiscretionaryPeriods?: Array<{
            startDate?: string | null;
            endDate?: string | null;
          } | null> | null;
          springDiscretionaryPeriods?: Array<{
            startDate?: string | null;
            endDate?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchTeamTmpSettingsQueryVariables = Exact<{
  tmpYearIdArg: Scalars['Int'];
  tmpTeamIdArg: Scalars['Int'];
}>;

export type FetchTeamTmpSettingsQuery = {
  tmpTeamSettings?: {
    edges: Array<{
      node?: {
        tmpActivityLogConfigId: string;
        tmpTeamSettingsData?: Array<{
          tmpConfigId?: number | null;
          autoGenerateLogs?: boolean | null;
          percentAthleteSigners?: number | null;
          calendarVisibilityUsers?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | {
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  id: string;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  lastName?: string | null;
                  initials?: string | null;
                  active: boolean;
                  label?: string | null;
                  selectionCode?: string | null;
                } | null> | null;
              }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
          conflictNotificationsVisibilityUsers?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | {
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  id: string;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  lastName?: string | null;
                  initials?: string | null;
                  active: boolean;
                  label?: string | null;
                  selectionCode?: string | null;
                } | null> | null;
              }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
          editDeclarationsVisibilityUsers?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | {
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  id: string;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  lastName?: string | null;
                  initials?: string | null;
                  active: boolean;
                  label?: string | null;
                  selectionCode?: string | null;
                } | null> | null;
              }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
          logAdminUsers?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | {
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  id: string;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  lastName?: string | null;
                  initials?: string | null;
                  active: boolean;
                  label?: string | null;
                  selectionCode?: string | null;
                } | null> | null;
              }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
          logViewerUsers?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | {
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  id: string;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  lastName?: string | null;
                  initials?: string | null;
                  active: boolean;
                  label?: string | null;
                  selectionCode?: string | null;
                } | null> | null;
              }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
          logAthleteUsers?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | {
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  id: string;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  lastName?: string | null;
                  initials?: string | null;
                  active: boolean;
                  label?: string | null;
                  selectionCode?: string | null;
                } | null> | null;
              }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
          logComplianceUsers?: Array<
            | { label?: string | null; selectionCode?: string | null }
            | {
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                label?: string | null;
                selectionCode?: string | null;
              }
            | {
                groupType?: GroupType | null;
                pluralLabel?: string | null;
                label?: string | null;
                selectionCode?: string | null;
                people?: Array<{
                  id: string;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  lastName?: string | null;
                  initials?: string | null;
                  active: boolean;
                  label?: string | null;
                  selectionCode?: string | null;
                } | null> | null;
              }
            | { label?: string | null; selectionCode?: string | null }
            | null
          > | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchTimeMgtPlanQueryVariables = Exact<{
  filters?: InputMaybe<TmpTeamPlanFiltersInput>;
}>;

export type FetchTimeMgtPlanQuery = {
  tmpTeamPlan?: {
    tmpYear?: { tmpYearId: string; label: string } | null;
    tmpTeam?: {
      tmpTeamId: string;
      team?: { label: string } | null;
      org?: { orgId: string; orgName: string; logoUrl?: string | null } | null;
    } | null;
    summary?: {
      totalAthletes?: number | null;
      tmpSegment?: Array<{
        label: string;
        seasonType: string;
        segmentEndDate?: string | null;
        segmentStartDate?: string | null;
        totalWeeks?: string | null;
      } | null> | null;
    } | null;
    reviewers?: {
      coach?: Array<
        | { label?: string | null }
        | { label?: string | null }
        | { label?: string | null }
        | { label?: string | null }
        | null
      > | null;
      sportsAdministrator?: Array<
        | { label?: string | null }
        | { label?: string | null }
        | { label?: string | null }
        | { label?: string | null }
        | null
      > | null;
      compliance?: Array<
        | { label?: string | null }
        | { label?: string | null }
        | { label?: string | null }
        | { label?: string | null }
        | null
      > | null;
      studentAthletes?: Array<
        | { label?: string | null }
        | { label?: string | null }
        | { label?: string | null }
        | { label?: string | null }
        | null
      > | null;
    } | null;
    competitions?: Array<{
      event?: string | null;
      dateString?: string | null;
      eventType?: string | null;
      countableHours?: string | null;
      location?: string | null;
    } | null> | null;
    countableEvents?: Array<{
      event?: string | null;
      dateString?: string | null;
      eventType?: string | null;
      countableHours?: string | null;
      location?: string | null;
    } | null> | null;
    daysOff?: Array<{
      event?: string | null;
      dateString?: string | null;
      eventType?: string | null;
      countableHours?: string | null;
      location?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateSegmentMutationVariables = Exact<{
  input: TmpTeamSegmentInput;
  id: Scalars['ID'];
}>;

export type UpdateSegmentMutation = {
  tmpTeamSegmentUpdate: {
    tmpTeamSegment?: {
      id: string;
      metaData?: {
        daysOff?: string | null;
        selectedNonCountableDays?: Array<string | null> | null;
      } | null;
    } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type UpdateTeamTmpSettingsMutationVariables = Exact<{
  input: TmpTeamSettingsInput;
}>;

export type UpdateTeamTmpSettingsMutation = {
  tmpSettingsUpdate: {
    tmpActivityLogConfig?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type TravelCredentialCreateMutationVariables = Exact<{
  input?: InputMaybe<TravelCredentialInput>;
}>;

export type TravelCredentialCreateMutation = {
  travelCredentialCreate: {
    travelCredential?: { label?: string | null; isActive?: boolean | null } | null;
  };
};

export type TravelCredentialDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TravelCredentialDeleteMutation = {
  travelCredentialDelete: {
    travelCredential?: { label?: string | null; isActive?: boolean | null } | null;
  };
};

export type TravelCredentialUpdateMutationVariables = Exact<{
  input?: InputMaybe<TravelCredentialInput>;
  id: Scalars['ID'];
}>;

export type TravelCredentialUpdateMutation = {
  travelCredentialUpdate: {
    travelCredential?: { label?: string | null; isActive?: boolean | null } | null;
  };
};

export type TravelCredentialOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type TravelCredentialOptionsQuery = {
  travelCredentialOptions?: {
    edges: Array<{
      node?: { id: string; label?: string | null; isActive?: boolean | null } | null;
    } | null>;
  } | null;
};

export type FetchMyDressOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchMyDressOptionsQuery = {
  travelDressOptions?: {
    edges: Array<{
      node?: {
        id: string;
        travelDressId: string;
        label?: string | null;
        isDefault?: boolean | null;
        isActive?: boolean | null;
      } | null;
    } | null>;
  } | null;
};

export type TravelDressCreateMutationVariables = Exact<{
  input?: InputMaybe<TravelDressInput>;
}>;

export type TravelDressCreateMutation = {
  travelDressCreate: {
    travelDress?: { id: string } | null;
    userErrors: Array<{ message: string }>;
  };
};

export type TravelDressDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TravelDressDeleteMutation = {
  travelDressDelete: {
    travelDress?: { id: string } | null;
    userErrors: Array<{ message: string }>;
  };
};

export type TravelDressEditMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<TravelDressInput>;
}>;

export type TravelDressEditMutation = {
  travelDressUpdate: {
    travelDress?: { id: string } | null;
    userErrors: Array<{ message: string }>;
  };
};

export type TravelSettingsFeaturesEditMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<TeamUpdateInput>;
}>;

export type TravelSettingsFeaturesEditMutation = {
  teamUpdate: { team?: { settings: any } | null };
};

export type AddTravelVendorToGroupMutationVariables = Exact<{
  input?: InputMaybe<TravelVendorGroupVendorLinkInput>;
}>;

export type AddTravelVendorToGroupMutation = {
  addTravelVendorToGroup: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type TripTravelPartyMemberBusAssignmentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TripTravelPartyMemberBusAssignmentsQuery = {
  trip?:
    | {
        logoUrl?: string | null;
        label?: string | null;
        seatplans?: Array<{
          seatplanJson: any;
          label: string;
          travelVendorType?: { label: string } | null;
        } | null> | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              firstName?: string | null;
              lastName?: string | null;
              preferredName?: string | null;
              suffix?: string | null;
              teamMembership?: {
                teamId?: number | null;
                isAthlete?: boolean | null;
                positionLabelsShort?: string | null;
                athleteStatus?: { label?: string | null } | null;
                userTypes?: Array<{ namePlural: string } | null> | null;
              } | null;
            }
          | {}
          | null
        > | null;
      }
    | {}
    | null;
};

export type TravelAllTripCredAssignmentsQueryVariables = Exact<{
  tripIdFilter: Scalars['ID'];
  onlyCompetitionsFilter?: InputMaybe<Scalars['Boolean']>;
}>;

export type TravelAllTripCredAssignmentsQuery = {
  travelEvents?: {
    edges: Array<{
      node?: {
        trip?: { logoUrl?: string | null } | null;
        org?: { logoUrl?: string | null } | null;
        appointment?: { title?: string | null } | null;
        competition?: {
          pk?: string | null;
          travelCredentialAssignments?: {
            edges: Array<{
              node?: {
                notes?: string | null;
                quantity?: number | null;
                person?: {
                  firstName?: string | null;
                  lastName?: string | null;
                  preferredName?: string | null;
                  fullPreferredName?: string | null;
                } | null;
                travelCredential?: { label?: string | null } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type UpdateTravelDocumentConfigMutationVariables = Exact<{
  input: TravelDocumentConfigInput;
}>;

export type UpdateTravelDocumentConfigMutation = {
  travelDocumentConfigUpsert: { travelDocumentConfig?: { config?: any | null } | null };
};

export type FetchTravelDocumentConfigsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTravelDocumentConfigsQuery = {
  travelDocumentConfigs?: { edges: Array<{ node?: { config?: any | null } | null } | null> } | null;
};

export type TripTravelPartyDressReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TripTravelPartyDressReportQuery = {
  trip?:
    | {
        label?: string | null;
        logoUrl?: string | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              firstName?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              middleName?: string | null;
              suffix?: string | null;
              dateOfBirth?: string | null;
              gender?: string | null;
              travelWeight?: number | null;
              mobilePhone?: string | null;
              teamMembership?: { userTypes?: Array<{ namePlural: string } | null> | null } | null;
            }
          | {}
          | null
        > | null;
        travelPartyPersons?: Array<{
          travelDress?: string | null;
          person?: {
            pk?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            teamMembership?: { userTypes?: Array<{ namePlural: string } | null> | null } | null;
          } | null;
        } | null> | null;
      }
    | {}
    | null;
};

export type FetchFiltersForTravelExportQueryVariables = Exact<{ [key: string]: never }>;

export type FetchFiltersForTravelExportQuery = {
  attributeCategories?: Array<{
    label: string;
    attributes?: Array<{
      id: string;
      label: string;
      dataType?: string | null;
      optionsList?: Array<string | null> | null;
      coreCode?: string | null;
    } | null> | null;
  } | null> | null;
};

export type FlightManifestExportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FlightManifestExportQuery = {
  trip?:
    | {
        logoUrl?: string | null;
        label?: string | null;
        seatplans?: Array<{
          id: string;
          seatplanJson: any;
          label: string;
          travelVendorType?: { label: string } | null;
        } | null> | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              pictureUrl?: string | null;
              active: boolean;
              firstName?: string | null;
              middleName?: string | null;
              lastName?: string | null;
              suffix?: string | null;
              preferredName?: string | null;
              initials?: string | null;
              selectionCode?: string | null;
              isContact: boolean;
              dateOfBirth?: string | null;
              gender?: string | null;
              travelWeight?: number | null;
              teamMembership?: {
                athleteStatus?: { label?: string | null } | null;
                userTypes?: Array<{ namePlural: string } | null> | null;
              } | null;
            }
          | {}
          | null
        > | null;
      }
    | {}
    | null;
};

export type FlightSeatDiagramExportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FlightSeatDiagramExportQuery = {
  trip?:
    | {
        id: string;
        logoUrl?: string | null;
        label?: string | null;
        seatplans?: Array<{
          id: string;
          seatplanJson: any;
          label: string;
          travelVendorType?: { label: string } | null;
        } | null> | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              firstName?: string | null;
              lastName?: string | null;
              preferredName?: string | null;
              suffix?: string | null;
              teamMembership?: {
                active?: boolean | null;
                userTypes?: Array<{ name: string; color?: string | null } | null> | null;
              } | null;
            }
          | {}
          | null
        > | null;
      }
    | {}
    | null;
};

export type TripHotelRoomAssignmentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TripHotelRoomAssignmentsQuery = {
  trip?:
    | {
        logoUrl?: string | null;
        label?: string | null;
        seatplans?: Array<{
          id: string;
          seatplanJson: any;
          label: string;
          travelVendorType?: { label: string } | null;
        } | null> | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              firstName?: string | null;
              lastName?: string | null;
              preferredName?: string | null;
              suffix?: string | null;
              teamMembership?: { active?: boolean | null } | null;
            }
          | {}
          | null
        > | null;
      }
    | {}
    | null;
};

export type HotelMasterExportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type HotelMasterExportQuery = {
  trip?:
    | {
        label?: string | null;
        logoUrl?: string | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              firstName?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              middleName?: string | null;
              suffix?: string | null;
              dateOfBirth?: string | null;
              gender?: string | null;
              travelWeight?: number | null;
              mobilePhone?: string | null;
              teamMembership?: { userTypes?: Array<{ namePlural: string } | null> | null } | null;
            }
          | {}
          | null
        > | null;
      }
    | {}
    | null;
};

export type TravelItineraryQueryVariables = Exact<{
  tripIdFilter: Scalars['ID'];
}>;

export type TravelItineraryQuery = {
  travelEvents?: {
    edges: Array<{
      travelEvent?: {
        travelEventId: string;
        timeZone?: string | null;
        appointment?: {
          title?: string | null;
          startDatetimeUtc?: string | null;
          endDatetimeUtc?: string | null;
          location: string;
          notes?: string | null;
        } | null;
        competition?: {
          hasCustomOpponent: boolean;
          customOpponent?: string | null;
          opponentInstitution?: {
            nickname?: string | null;
            city?: string | null;
            state?: string | null;
          } | null;
          awayInstitution?: {
            nickname?: string | null;
            city?: string | null;
            state?: string | null;
          } | null;
          homeInstitution?: {
            nickname?: string | null;
            city?: string | null;
            state?: string | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type TripTravelPartyPerDiemsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TripTravelPartyPerDiemsQuery = {
  trip?:
    | {
        label?: string | null;
        logoUrl?: string | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              firstName?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              middleName?: string | null;
              suffix?: string | null;
              dateOfBirth?: string | null;
              gender?: string | null;
              travelWeight?: number | null;
              mobilePhone?: string | null;
              teamMembership?: { userTypes?: Array<{ namePlural: string } | null> | null } | null;
            }
          | {}
          | null
        > | null;
        travelPartyPersons?: Array<{
          perDiem?: string | null;
          person?: {
            pk?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            teamMembership?: { userTypes?: Array<{ namePlural: string } | null> | null } | null;
          } | null;
        } | null> | null;
      }
    | {}
    | null;
};

export type TravelAllTripPregameItemsQueryVariables = Exact<{
  tripIdFilter: Scalars['ID'];
  onlyCompetitionsFilter?: InputMaybe<Scalars['Boolean']>;
}>;

export type TravelAllTripPregameItemsQuery = {
  travelEvents?: {
    edges: Array<{
      node?: {
        timeZone?: string | null;
        trip?: { logoUrl?: string | null } | null;
        org?: { logoUrl?: string | null } | null;
        appointment?: { title?: string | null; startDatetimeLocal?: string | null } | null;
        competition?: {
          travelPregameItems?: {
            edges: Array<{
              node?: {
                title: string;
                secondsBeforeStart: number;
                minutesBeforeStart: number;
                timeBeforeStartSeconds?: number | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type RemoveTravelVendorFromGroupMutationVariables = Exact<{
  input?: InputMaybe<TravelVendorGroupVendorLinkInput>;
}>;

export type RemoveTravelVendorFromGroupMutation = {
  removeTravelVendorFromGroup: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type TravelPartyExportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TravelPartyExportQuery = {
  trip?:
    | {
        label?: string | null;
        logoUrl?: string | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              firstName?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              middleName?: string | null;
              suffix?: string | null;
              dateOfBirth?: string | null;
              gender?: string | null;
              travelWeight?: number | null;
              mobilePhone?: string | null;
              teamMembership?: { userTypes?: Array<{ namePlural: string } | null> | null } | null;
            }
          | {}
          | null
        > | null;
      }
    | {}
    | null;
};

export type TravelerAssignmentsExportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TravelerAssignmentsExportQuery = {
  trip?:
    | {
        label?: string | null;
        logoUrl?: string | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              firstName?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              middleName?: string | null;
              suffix?: string | null;
              dateOfBirth?: string | null;
              gender?: string | null;
              travelWeight?: number | null;
              mobilePhone?: string | null;
              teamMembership?: { userTypes?: Array<{ namePlural: string } | null> | null } | null;
            }
          | {}
          | null
        > | null;
      }
    | {}
    | null;
};

export type FetchTripQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchTripQuery = {
  trip?:
    | {
        label?: string | null;
        seatplans?: Array<{
          id: string;
          seatplanJson: any;
          label: string;
          travelVendorType?: { id: string } | null;
        } | null> | null;
        events?: Array<{
          appointment?: {
            id: string;
            title?: string | null;
            location: string;
            startDate?: any | null;
          } | null;
          competition?: {
            travelPregameItems?: { edges: Array<{ node?: { id: string } | null } | null> } | null;
            travelCredentialAssignments?: {
              edges: Array<{ node?: { id: string } | null } | null>;
            } | null;
          } | null;
        } | null> | null;
        tasks?: Array<{ isCompleted: boolean; taskName: string; taskOrder: number } | null> | null;
        travelPartyMembers?: Array<{ id: string } | {} | null> | null;
      }
    | {}
    | null;
};

export type TripsListTrimmedQueryVariables = Exact<{
  season?: InputMaybe<Scalars['ID']>;
}>;

export type TripsListTrimmedQuery = {
  trips?: {
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        label?: string | null;
        startDateTime?: string | null;
        endDateTime?: string | null;
        logoUrl?: string | null;
        timeZone?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type TravelParticipationReportCreateMutationVariables = Exact<{
  input?: InputMaybe<TravelParticipationReportCreateInput>;
}>;

export type TravelParticipationReportCreateMutation = {
  travelParticipationReportCreate: {
    travelParticipationReport?: {
      createdDate?: any | null;
      downloadableFileId: string;
      status: string;
      title?: string | null;
      url?: string | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type TripTravelPartySelectionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TripTravelPartySelectionsQuery = {
  trip?:
    | {
        isInPast?: boolean | null;
        label?: string | null;
        travelPartySelections?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              selectionCode?: string | null;
              pictureUrl?: string | null;
              displayName?: string | null;
              initials?: string | null;
              isContact: boolean;
              teamMembership?: {
                active?: boolean | null;
                athleteStatus?: { label?: string | null } | null;
                userTypes?: Array<{ namePlural: string } | null> | null;
              } | null;
              contactAssociations?: Array<{
                firstName?: string | null;
                lastName?: string | null;
                id: string;
                selectionCode?: string | null;
                initials?: string | null;
                label?: string | null;
                pictureUrl?: string | null;
              } | null> | null;
            }
          | {
              selectionCode?: string | null;
              pluralLabel?: string | null;
              people?: Array<{
                id: string;
                pk?: string | null;
                active: boolean;
                pictureUrl?: string | null;
                displayName?: string | null;
                initials?: string | null;
                teamMembership?: {
                  active?: boolean | null;
                  athleteStatus?: { label?: string | null } | null;
                  userTypes?: Array<{ namePlural: string } | null> | null;
                } | null;
              } | null> | null;
            }
          | {}
          | null
        > | null;
        travelPartyExclusions?: Array<
          | {
              id: string;
              pk?: string | null;
              pictureUrl?: string | null;
              displayName?: string | null;
              initials?: string | null;
              selectionCode?: string | null;
            }
          | {}
          | null
        > | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              pictureUrl?: string | null;
              firstName?: string | null;
              middleName?: string | null;
              lastName?: string | null;
              displayName?: string | null;
              preferredName?: string | null;
              nickname?: string | null;
              suffix?: string | null;
              active: boolean;
              initials?: string | null;
              selectionCode?: string | null;
              isContact: boolean;
              dateOfBirth?: string | null;
              gender?: string | null;
              travelWeight?: number | null;
              teamMembership?: {
                active?: boolean | null;
                athleteStatus?: { label?: string | null } | null;
                userTypes?: Array<{ namePlural: string } | null> | null;
              } | null;
              contactAssociations?: Array<{
                firstName?: string | null;
                lastName?: string | null;
                id: string;
                selectionCode?: string | null;
                initials?: string | null;
                label?: string | null;
                pictureUrl?: string | null;
              } | null> | null;
            }
          | {}
          | null
        > | null;
        travelPartyPersons?: Array<{
          perDiem?: string | null;
          notes?: string | null;
          travelDress?: string | null;
          person?: {
            id: string;
            pk?: string | null;
            pictureUrl?: string | null;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            displayName?: string | null;
            preferredName?: string | null;
            nickname?: string | null;
            suffix?: string | null;
            active: boolean;
            initials?: string | null;
            selectionCode?: string | null;
            isContact: boolean;
            dateOfBirth?: string | null;
            gender?: string | null;
            travelWeight?: number | null;
            teamMembership?: {
              active?: boolean | null;
              athleteStatus?: { label?: string | null } | null;
              userTypes?: Array<{ namePlural: string } | null> | null;
            } | null;
            contactAssociations?: Array<{
              firstName?: string | null;
              lastName?: string | null;
              id: string;
              selectionCode?: string | null;
              initials?: string | null;
              label?: string | null;
              pictureUrl?: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
      }
    | {}
    | null;
};

export type TravelVendorGroupCreateMutationVariables = Exact<{
  input?: InputMaybe<TravelVendorGroupInput>;
}>;

export type TravelVendorGroupCreateMutation = {
  travelVendorGroupCreate: { travelVendorGroup?: { id: string } | null };
};

export type TravelVendorGroupDeleteMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type TravelVendorGroupDeleteMutation = {
  travelVendorGroupDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type TravelVendorGroupEditMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelVendorGroupInput>;
}>;

export type TravelVendorGroupEditMutation = {
  travelVendorGroupUpdate: { travelVendorGroup?: { id: string } | null };
};

export type TripVendorGroupListQueryVariables = Exact<{
  tripIdFilter: Scalars['ID'];
}>;

export type TripVendorGroupListQuery = {
  vendorGroups?: {
    edges: Array<{
      node?: {
        id: string;
        label?: string | null;
        travelVendors?: {
          edges: Array<{
            node?: {
              id: string;
              name: string;
              travelVendorId: string;
              phoneFax?: string | null;
              phoneMain?: string | null;
              travelVendorType?: { id: string; label: string } | null;
              address?: {
                street1?: string | null;
                street2?: string | null;
                city?: string | null;
                countryIso?: string | null;
                customStateProvince?: string | null;
                zipCode?: string | null;
                country?: {
                  name?: string | null;
                  printableName?: string | null;
                  numcode?: string | null;
                } | null;
                state?: { name?: string | null; code?: string | null } | null;
              } | null;
              teams?: Array<{ id: string } | null> | null;
              contacts?: Array<{
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                phoneOffice?: string | null;
                phoneMobile?: string | null;
                phoneFax?: string | null;
                emailAddress?: string | null;
                isPrimaryContact: boolean;
                title?: string | null;
              } | null> | null;
              notes?: Array<{
                id: string;
                title: string;
                content: string;
                createdDateTime: any;
                updatedDateTime?: any | null;
                author: {
                  displayName?: string | null;
                  pictureUrl?: string | null;
                  initials?: string | null;
                  title?: string | null;
                };
              } | null> | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type TripVendorsQueryVariables = Exact<{
  vendorSearch?: InputMaybe<Scalars['String']>;
  tripIdFilter?: InputMaybe<Scalars['ID']>;
}>;

export type TripVendorsQuery = {
  vendors?: {
    edges: Array<{
      node?: {
        id: string;
        name: string;
        travelVendorType?: { id: string; label: string } | null;
        seatingCharts?: Array<{
          id: string;
          label: string;
          numberOfSeats?: number | null;
          seatplanConfigurationJson: string;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type VendorGroupsQueryVariables = Exact<{
  tripIdFilter: Scalars['ID'];
}>;

export type VendorGroupsQuery = {
  vendorGroups?: {
    edges: Array<{
      vendorGroup?: {
        label?: string | null;
        travelVendors?: {
          edges: Array<{
            vendor?: {
              name: string;
              travelVendorType?: { label: string } | null;
              address?: {
                street1?: string | null;
                street2?: string | null;
                city?: string | null;
                customStateProvince?: string | null;
                zipCode?: string | null;
                country?: { printableName?: string | null } | null;
                state?: { name?: string | null } | null;
              } | null;
              contacts?: Array<{
                firstName?: string | null;
                lastName?: string | null;
                phoneOffice?: string | null;
                phoneMobile?: string | null;
                phoneFax?: string | null;
                emailAddress?: string | null;
                isPrimaryContact: boolean;
                title?: string | null;
              } | null> | null;
              notes?: Array<{
                title: string;
                content: string;
                createdDateTime: any;
                author: { displayName?: string | null };
              } | null> | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type TravelCredentialAssignmentQueryVariables = Exact<{
  competitionIdFilter: Scalars['ID'];
}>;

export type TravelCredentialAssignmentQuery = {
  travelCredentialAssignments?: {
    edges: Array<{
      node?: {
        id: string;
        quantity?: number | null;
        notes?: string | null;
        person?: { id: string; displayName?: string | null; pk?: string | null } | null;
        travelCredential?: { id: string; label?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type CredentialAssignmentBulkEditMutationVariables = Exact<{
  input?: InputMaybe<TravelCredentialAssignmentBulkInput>;
}>;

export type CredentialAssignmentBulkEditMutation = {
  travelCredentialAssignmentBulkUpdate: {
    travelCredentialAssignment?: Array<{
      quantity?: number | null;
      notes?: string | null;
      travelCredential?: { label?: string | null } | null;
    } | null> | null;
  };
};

export type CredentialsAssignCopyMutationVariables = Exact<{
  input?: InputMaybe<TravelCredentialAssignmentCopyInput>;
}>;

export type CredentialsAssignCopyMutation = {
  travelCredentialAssignmentCopy: {
    travelCredentialAssignment?: Array<{
      quantity?: number | null;
      notes?: string | null;
      travelCredential?: { label?: string | null } | null;
      competition?: { title?: string | null } | null;
    } | null> | null;
  };
};

export type TripTravelCredentialsOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type TripTravelCredentialsOptionsQuery = {
  travelCredentialOptions?: {
    edges: Array<{ node?: { id: string; label?: string | null } | null } | null>;
  } | null;
};

export type TripTravelPartySelectionsForCredentialsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TripTravelPartySelectionsForCredentialsQuery = {
  trip?:
    | {
        isInPast?: boolean | null;
        label?: string | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              pictureUrl?: string | null;
              firstName?: string | null;
              middleName?: string | null;
              lastName?: string | null;
              displayName?: string | null;
              preferredName?: string | null;
              active: boolean;
              initials?: string | null;
              isContact: boolean;
              teamMembership?: {
                active?: boolean | null;
                athleteStatus?: { label?: string | null } | null;
                userTypes?: Array<{ namePlural: string; id: string } | null> | null;
              } | null;
            }
          | {}
          | null
        > | null;
        travelPartySelections?: Array<
          | {
              id: string;
              pk?: string | null;
              active: boolean;
              selectionCode?: string | null;
              pictureUrl?: string | null;
              firstName?: string | null;
              middleName?: string | null;
              lastName?: string | null;
              displayName?: string | null;
              initials?: string | null;
              isContact: boolean;
            }
          | {}
          | null
        > | null;
      }
    | {}
    | null;
};

export type TripFilesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TripFilesQuery = {
  trip?:
    | {
        files?: Array<{
          id: string;
          pk?: number | null;
          sharedFileId?: number | null;
          fileName: string;
          fileNotes?: string | null;
          isPinned?: boolean | null;
          updatedDate?: any | null;
          canEditFile?: boolean | null;
          canDownload?: boolean | null;
          creator?: {
            createdBy?: number | null;
            pictureDownloadUrl?: string | null;
            preferredName?: string | null;
            lastName?: string | null;
            initials?: string | null;
          } | null;
        } | null> | null;
      }
    | {}
    | null;
};

export type TripTravelSelectionCodesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TripTravelSelectionCodesQuery = {
  trip?:
    | {
        travelPartySelections?: Array<
          | {
              __typename: 'PersonNode';
              id: string;
              pk?: string | null;
              selectionCode?: string | null;
            }
          | { __typename: 'SelectionGroup'; selectionCode?: string | null }
          | {}
          | null
        > | null;
      }
    | {}
    | null;
};

export type InstitutionsQueryVariables = Exact<{ [key: string]: never }>;

export type InstitutionsQuery = {
  institutions?: {
    edges: Array<{
      node?: {
        id: string;
        state?: string | null;
        city?: string | null;
        nickname?: string | null;
        orgName?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type EventBulkDeleteMutationVariables = Exact<{
  input: TravelEventBulkDeleteInput;
  id: Scalars['ID'];
}>;

export type EventBulkDeleteMutation = {
  travelEventBulkDelete: { trip?: { events?: Array<{ id: string } | null> | null } | null };
};

export type EventBulkUpdateMutationVariables = Exact<{
  input: TravelEventBulkUpdateInput;
  id: Scalars['ID'];
}>;

export type EventBulkUpdateMutation = {
  travelEventBulkUpdate: { trip?: { events?: Array<{ id: string } | null> | null } | null };
};

export type TravelEventCopyMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<TravelEventCopyInput>;
}>;

export type TravelEventCopyMutation = { travelEventCopy: { trip?: { id: string } | null } };

export type EventcreateMutationVariables = Exact<{
  input?: InputMaybe<TravelEventInput>;
}>;

export type EventcreateMutation = {
  travelEventCreate: {
    travelEvent?: {
      id: string;
      orgId?: number | null;
      trip?: { label?: string | null } | null;
      competition?: { title?: string | null } | null;
      appointment?: { title?: string | null } | null;
    } | null;
  };
};

export type TravelEventDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TravelEventDeleteMutation = {
  travelEventDelete: { travelEvent?: { id: string } | null };
};

export type TravelEventUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<TravelEventInput>;
}>;

export type TravelEventUpdateMutation = {
  travelEventUpdate: {
    travelEvent?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type TravelEventTypesQueryVariables = Exact<{ [key: string]: never }>;

export type TravelEventTypesQuery = {
  travelEventTypes?: {
    edges: Array<{ node?: { id: string; label: string } | null } | null>;
  } | null;
};

export type TravelEventsQueryVariables = Exact<{
  tripIdFilter: Scalars['ID'];
}>;

export type TravelEventsQuery = {
  travelEvents?: {
    edges: Array<{
      node?: {
        id: string;
        hasTicketAllocations?: boolean | null;
        pk?: string | null;
        timeZone?: string | null;
        appointment?: {
          enableCheckins?: boolean | null;
          endDatetimeLocal?: string | null;
          endTimeZone?: string | null;
          location: string;
          notes?: string | null;
          startDatetimeLocal?: string | null;
          startTimeZone?: string | null;
          title?: string | null;
          appointmentCheckin?: {
            checkoutEnabled?: boolean | null;
            hasCheckinActions?: boolean | null;
            rotateQrCode?: boolean | null;
            checkinAdminSelectables?: Array<
              | { label?: string | null; selectionCode?: string | null }
              | {
                  active: boolean;
                  id: string;
                  initials?: string | null;
                  lastName?: string | null;
                  pictureUrl?: string | null;
                  preferredName?: string | null;
                  label?: string | null;
                  selectionCode?: string | null;
                  teamMembership?: { isAthlete?: boolean | null } | null;
                }
              | {
                  groupType?: GroupType | null;
                  pluralLabel?: string | null;
                  label?: string | null;
                  selectionCode?: string | null;
                  people?: Array<{
                    active: boolean;
                    id: string;
                    initials?: string | null;
                    label?: string | null;
                    lastName?: string | null;
                    pictureUrl?: string | null;
                    preferredName?: string | null;
                    selectionCode?: string | null;
                    teamMembership?: { isAthlete?: boolean | null } | null;
                  } | null> | null;
                }
              | { label?: string | null; selectionCode?: string | null }
              | null
            > | null;
          } | null;
          reminders?: Array<{
            offsetMinutes?: number | null;
            reminderType?: string | null;
          } | null> | null;
        } | null;
        appointmentType?: { color: string; id: string; label: string } | null;
        attendees?: Array<
          | { label?: string | null; peopleCount?: number | null; selectionCode?: string | null }
          | { label?: string | null; peopleCount?: number | null; selectionCode?: string | null }
          | { label?: string | null; peopleCount?: number | null; selectionCode?: string | null }
          | { label?: string | null; peopleCount?: number | null; selectionCode?: string | null }
          | null
        > | null;
        competition?: {
          customOpponent?: string | null;
          hasCustomOpponent: boolean;
          location?: string | null;
          awayInstitution?: {
            city?: string | null;
            id: string;
            nickname?: string | null;
            state?: string | null;
          } | null;
          homeInstitution?: {
            city?: string | null;
            id: string;
            nickname?: string | null;
            state?: string | null;
          } | null;
          opponentInstitution?: {
            city?: string | null;
            id: string;
            nickname?: string | null;
            state?: string | null;
          } | null;
        } | null;
        creator?: {
          active: boolean;
          id: string;
          initials?: string | null;
          label?: string | null;
          pictureUrl?: string | null;
          preferredName?: string | null;
          selectionCode?: string | null;
        } | null;
        travelEventType?: { id: string; label: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type ItineraryTripsListQueryVariables = Exact<{
  season?: InputMaybe<Scalars['ID']>;
}>;

export type ItineraryTripsListQuery = {
  trips?: {
    totalCount?: number | null;
    edges: Array<{
      node?: { pk?: string | null; id: string; label?: string | null } | null;
    } | null>;
  } | null;
};

export type TravelParticipationBulkUpsertMutationVariables = Exact<{
  input?: InputMaybe<TravelParticipationBulkUpsertInput>;
}>;

export type TravelParticipationBulkUpsertMutation = {
  travelParticipationBulkUpsert: {
    travelParticipation?: Array<{
      didParticipate?: boolean | null;
      notes?: string | null;
    } | null> | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type TravelParticipationQueryVariables = Exact<{
  competitionIdFilter: Scalars['ID'];
}>;

export type TravelParticipationQuery = {
  travelParticipation?: {
    edges: Array<{
      node?: {
        id: string;
        notes?: string | null;
        didParticipate?: boolean | null;
        person?: {
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          initials?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type PregameCreateMutationVariables = Exact<{
  input?: InputMaybe<TravelPregameItemCreateInput>;
}>;

export type PregameCreateMutation = {
  travelPregameItemCreate: {
    travelPregameItem?: {
      id: string;
      title: string;
      minutesBeforeStart: number;
      secondsBeforeStart: number;
      timeBeforeStartSeconds?: number | null;
    } | null;
  };
};

export type PregameItemDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PregameItemDeleteMutation = {
  travelPregameItemDelete: { travelPregameItem?: { id: string } | null };
};

export type PregameItemUpdateMutationVariables = Exact<{
  input?: InputMaybe<TravelPregameItemUpdateInput>;
  id: Scalars['ID'];
}>;

export type PregameItemUpdateMutation = {
  travelPregameItemUpdate: {
    travelPregameItem?: {
      id: string;
      title: string;
      minutesBeforeStart: number;
      secondsBeforeStart: number;
      timeBeforeStartSeconds?: number | null;
    } | null;
  };
};

export type PregameItemsQueryVariables = Exact<{
  competitionIdFilter: Scalars['ID'];
}>;

export type PregameItemsQuery = {
  pregameItems?: {
    edges: Array<{
      node?: {
        id: string;
        title: string;
        minutesBeforeStart: number;
        secondsBeforeStart: number;
        timeBeforeStartSeconds?: number | null;
      } | null;
    } | null>;
  } | null;
};

export type PregameItemsCopyMutationVariables = Exact<{
  input?: InputMaybe<TravelPregameItemCopyInput>;
}>;

export type PregameItemsCopyMutation = {
  travelPregameItemCopy: { travelPregameItem?: Array<{ id: string } | null> | null };
};

export type PregameTravelPartyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PregameTravelPartyQuery = {
  trip?:
    | {
        travelPartyPersons?: Array<{
          person?: { id: string; pk?: string | null } | null;
        } | null> | null;
      }
    | {}
    | null;
};

export type TravelCompetitionEventsQueryVariables = Exact<{
  tripIdFilter: Scalars['ID'];
  onlyCompetitionsFilter?: InputMaybe<Scalars['Boolean']>;
}>;

export type TravelCompetitionEventsQuery = {
  travelEvents?: {
    edges: Array<{
      node?: {
        id: string;
        pk?: string | null;
        timeZone?: string | null;
        appointment?: {
          title?: string | null;
          location: string;
          startDatetimeLocal?: string | null;
          endDatetimeLocal?: string | null;
          startTimeZone?: string | null;
          endTimeZone?: string | null;
        } | null;
        competition?: {
          id: string;
          pregameVisibilities?: Array<string | null> | null;
          slideTimeInterval?: {
            hours?: number | null;
            minutes?: number | null;
            seconds?: number | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type TravelCompetitionSlideTimeMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<CompetitionSlideTimeIntervalInput>;
}>;

export type TravelCompetitionSlideTimeMutation = {
  updateCompetitionSlideTimeInterval: {
    competition?: {
      id: string;
      slideTimeInterval?: {
        hours?: number | null;
        minutes?: number | null;
        seconds?: number | null;
      } | null;
    } | null;
  };
};

export type PregameCopySeasonsListQueryVariables = Exact<{ [key: string]: never }>;

export type PregameCopySeasonsListQuery = {
  seasons?: {
    edges: Array<{ cursor: string; node?: { id: string; label: string } | null } | null>;
  } | null;
};

export type PregameCopyTripsListQueryVariables = Exact<{
  season?: InputMaybe<Scalars['ID']>;
}>;

export type PregameCopyTripsListQuery = {
  trips?: {
    totalCount?: number | null;
    edges: Array<{
      node?: { pk?: string | null; id: string; label?: string | null } | null;
    } | null>;
  } | null;
};

export type UpdateCompetitionPregameVisibilitiesMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<CompetitionPregameVisibilityInput>;
}>;

export type UpdateCompetitionPregameVisibilitiesMutation = {
  updateCompetitionPregameVisibilities: {
    competition?: { id: string; pregameVisibilities?: Array<string | null> | null } | null;
  };
};

export type TravelSeatplanCopyMutationVariables = Exact<{
  input: TravelSeatplanCopyInput;
}>;

export type TravelSeatplanCopyMutation = {
  travelSeatplanCopy: {
    travelSeatplan?: {
      id: string;
      label: string;
      travelVendorTypeId?: number | null;
      seatplanJson: any;
      trip?: { label?: string | null } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type TravelSeatplanCopyOverrideMutationVariables = Exact<{
  input: TravelSeatplanCopyOverrideInput;
}>;

export type TravelSeatplanCopyOverrideMutation = {
  travelSeatplanCopyOverride: {
    travelSeatplan?: {
      id: string;
      label: string;
      travelVendorTypeId?: number | null;
      seatplanJson: any;
      trip?: { label?: string | null } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type CreateTravelSeatplanMutationVariables = Exact<{
  input: TravelSeatplanCreateInput;
}>;

export type CreateTravelSeatplanMutation = {
  travelSeatplanCreate: {
    travelSeatplan?: { id: string; label: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type DeleteTravelSeatplanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTravelSeatplanMutation = {
  travelSeatplanDelete: {
    travelSeatplan?: { id: string; label: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type UpdateTravelSeatplanMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TravelSeatplanUpdateInput;
}>;

export type UpdateTravelSeatplanMutation = {
  travelSeatplanUpdate: {
    travelSeatplan?: { id: string; label: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type TripSeatplanListQueryVariables = Exact<{
  tripIdFilter: Scalars['ID'];
  travelVendorTypeIdFilter?: InputMaybe<
    Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
  >;
}>;

export type TripSeatplanListQuery = {
  tripSeatplans?: {
    edges: Array<{
      node?: {
        id: string;
        label: string;
        seatplanJson: any;
        travelSeatplanId: string;
        createdDate?: any | null;
        travelVendorType?: { label: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type BulkUpdateTasksMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TravelTaskBulkUpdateInput;
}>;

export type BulkUpdateTasksMutation = {
  travelTaskBulkUpdate: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ field: Array<string>; message: string } | null> | null;
    }>;
  };
};

export type CopyTasksMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TravelTaskCopyInput;
}>;

export type CopyTasksMutation = {
  travelTaskCopy: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ field: Array<string>; message: string } | null> | null;
    }>;
  };
};

export type CreateTaskMutationVariables = Exact<{
  input: TravelTaskCreateInput;
}>;

export type CreateTaskMutation = {
  travelTaskCreate: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ field: Array<string>; message: string } | null> | null;
    }>;
  };
};

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTaskMutation = {
  travelTaskDelete: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ field: Array<string>; message: string } | null> | null;
    }>;
  };
};

export type TasksQueryVariables = Exact<{
  tripId: Scalars['ID'];
}>;

export type TasksQuery = {
  travelTask?: {
    edges: Array<{
      cursor: string;
      node?: {
        pk?: string | null;
        isCompleted: boolean;
        travelTaskId: string;
        taskName: string;
        taskOrder: number;
      } | null;
    } | null>;
  } | null;
};

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TravelTaskUpdateInput;
}>;

export type UpdateTaskMutation = {
  travelTaskUpdate: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    bulkUserErrors: Array<{
      objectKey: string;
      errorList?: Array<{ field: Array<string>; message: string } | null> | null;
    }>;
  };
};

export type GetContactDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContactDetailsQuery = {
  person?:
    | {
        initials?: string | null;
        label?: string | null;
        pictureUrl?: string | null;
        contacts?: Array<{
          gender?: string | null;
          travelWeight?: number | null;
          id?: string | null;
          emailAddress?: string | null;
          firstName?: string | null;
          middleName?: string | null;
          maritalStatusId?: number | null;
          phoneCell?: string | null;
          phoneHome?: string | null;
          phoneOffice?: string | null;
          lastName?: string | null;
          initials?: string | null;
          pictureUrl?: string | null;
          displayName?: string | null;
          dateOfBirth?: string | null;
          mobilePhone?: string | null;
          selectionCode?: string | null;
          relationship?: { id: string; label: string } | null;
          addresses?: Array<{
            isMailing?: string | null;
            description?: string | null;
            street?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
            countryIso?: string | null;
          } | null> | null;
        } | null> | null;
      }
    | {}
    | null;
};

export type TravelPartyRelationshipTypesQueryVariables = Exact<{ [key: string]: never }>;

export type TravelPartyRelationshipTypesQuery = {
  relationshipTypes?: {
    edges: Array<{ node?: { id: string; label: string } | null } | null>;
  } | null;
};

export type SendTravelPartyAssignmentsMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<SendTravelPartyAssignmentInput>;
}>;

export type SendTravelPartyAssignmentsMutation = {
  sendTravelPartyAssignments: { trip?: { id: string } | null };
};

export type BulkDeleteTravelPartyMutationVariables = Exact<{
  input: TripBulkDeleteTravelPartyInput;
  id: Scalars['ID'];
}>;

export type BulkDeleteTravelPartyMutation = {
  tripBulkDeleteTravelParty: {
    trip?: {
      travelPartyPersons?: Array<{
        person?: {
          firstName?: string | null;
          lastName?: string | null;
          middleName?: string | null;
          gender?: string | null;
          travelWeight?: number | null;
        } | null;
      } | null> | null;
    } | null;
  };
};

export type BulkUpdateTravelPartyMutationVariables = Exact<{
  input: TripBulkUpdateTravelPartyInput;
  id: Scalars['ID'];
}>;

export type BulkUpdateTravelPartyMutation = {
  tripBulkUpdateTravelParty: {
    trip?: {
      travelPartyPersons?: Array<{
        person?: {
          firstName?: string | null;
          lastName?: string | null;
          middleName?: string | null;
          gender?: string | null;
          travelWeight?: number | null;
        } | null;
      } | null> | null;
    } | null;
  };
};

export type DeleteTravelPartyPersonMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<DeleteTravelPartyPersonInput>;
}>;

export type DeleteTravelPartyPersonMutation = {
  deleteTravelPartyPerson: { trip?: { id: string } | null };
};

export type TripTravelPartyEditMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<ModifyTravelPartyInput>;
}>;

export type TripTravelPartyEditMutation = { modifyTravelParty: { trip?: { id: string } | null } };

export type CreateSeasonMutationVariables = Exact<{
  input?: InputMaybe<SeasonInput>;
}>;

export type CreateSeasonMutation = { seasonCreate: { season?: { id: string } | null } };

export type DeleteSeasonMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type DeleteSeasonMutation = {
  seasonDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type UpdateSeasonMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<SeasonInput>;
}>;

export type UpdateSeasonMutation = { seasonUpdate: { season?: { id: string } | null } };

export type SeasonsListQueryVariables = Exact<{ [key: string]: never }>;

export type SeasonsListQuery = {
  seasons?: {
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        label: string;
        hasTrips?: boolean | null;
        createdDate?: any | null;
      } | null;
    } | null>;
  } | null;
};

export type FetchTmpYearsWithDatesQueryVariables = Exact<{
  orgId: Scalars['TWFilter_Int'];
}>;

export type FetchTmpYearsWithDatesQuery = {
  tmpYears?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        id: string;
        isCurrent: boolean;
        tmpYearId: string;
        label: string;
        orgId: number;
        dates?: {
          edges: Array<{ node?: { dateType?: { label?: string | null } | null } | null } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type TravelParticipationReportQueryVariables = Exact<{ [key: string]: never }>;

export type TravelParticipationReportQuery = {
  travelParticipationReport?: {
    edges: Array<{
      node?: {
        createdDate?: any | null;
        downloadableFileId: string;
        errorMessage?: string | null;
        kind: string;
        status: string;
        title?: string | null;
        url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateTripMutationVariables = Exact<{
  input?: InputMaybe<TripCreateInput>;
}>;

export type CreateTripMutation = {
  tripCreate: {
    trip?: { id: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type DeleteTripMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type DeleteTripMutation = {
  tripDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type UpdateTripMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<TripUpdateInput>;
}>;

export type UpdateTripMutation = {
  tripUpdate: {
    trip?: { id: string } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export type TripsListQueryVariables = Exact<{
  season?: InputMaybe<Scalars['ID']>;
  isInPast?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type TripsListQuery = {
  trips?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: {
        pk?: string | null;
        id: string;
        label?: string | null;
        startDateTime?: string | null;
        endDateTime?: string | null;
        logoUrl?: string | null;
        isVisibleMobile: boolean;
        itineraryCalendarVisibility?: TripCalendarVisibilityEnum | null;
        status?: string | null;
        timeZone?: string | null;
        hasTicketAllocations?: boolean | null;
        season?: { id: string; label: string } | null;
        seasons?: Array<{
          id: string;
          label: string;
          team?: { id: string; label: string } | null;
        } | null> | null;
        visibility?: Array<
          | { selectionCode?: string | null; label?: string | null }
          | {
              pictureUrl?: string | null;
              initials?: string | null;
              selectionCode?: string | null;
              label?: string | null;
            }
          | {
              groupType?: GroupType | null;
              pluralLabel?: string | null;
              selectionCode?: string | null;
              label?: string | null;
              people?: Array<{
                selectionCode?: string | null;
                label?: string | null;
                pictureUrl?: string | null;
                initials?: string | null;
              } | null> | null;
            }
          | { selectionCode?: string | null; label?: string | null }
          | null
        > | null;
        travelPartyMembers?: Array<
          | {
              id: string;
              pk?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              pictureUrl?: string | null;
              initials?: string | null;
            }
          | {}
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export type TripCompetitionsBulkCreateMutationVariables = Exact<{
  input: TripCompetitionsBulkCreateInput;
}>;

export type TripCompetitionsBulkCreateMutation = {
  tripCompetitionsBulkCreate: {
    userErrors: Array<{ field: Array<string>; message: string }>;
    bulkUserErrors: Array<{ errorList?: Array<{ message: string } | null> | null }>;
  };
};

export type TravelContactCreateMutationVariables = Exact<{
  input?: InputMaybe<TravelContactCreateInput>;
}>;

export type TravelContactCreateMutation = {
  travelContactCreate: {
    travelContact?: {
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      phoneOffice?: string | null;
      phoneMobile?: string | null;
      phoneFax?: string | null;
      emailAddress?: string | null;
      isPrimaryContact: boolean;
      title?: string | null;
    } | null;
  };
};

export type TravelContactDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TravelContactDeleteMutation = {
  travelContactDelete: { travelContact?: { id: string } | null };
};

export type TravelContactUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<TravelContactUpdateInput>;
}>;

export type TravelContactUpdateMutation = {
  travelContactUpdate: {
    travelContact?: {
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      emailAddress?: string | null;
      phoneMobile?: string | null;
      phoneOffice?: string | null;
      phoneFax?: string | null;
      isPrimaryContact: boolean;
    } | null;
  };
};

export type NoteCreateMutationVariables = Exact<{
  input?: InputMaybe<NoteInput>;
}>;

export type NoteCreateMutation = {
  noteCreate: {
    note?: {
      id: string;
      title: string;
      content: string;
      createdDateTime: any;
      updatedDateTime?: any | null;
      author: {
        displayName?: string | null;
        pictureUrl?: string | null;
        initials?: string | null;
        title?: string | null;
      };
    } | null;
  };
};

export type NoteDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type NoteDeleteMutation = {
  noteDelete: {
    note?: { id: string } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export type EditNoteMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<NoteInput>;
}>;

export type EditNoteMutation = {
  noteUpdate: {
    note?: {
      id: string;
      title: string;
      content: string;
      createdDateTime: any;
      updatedDateTime?: any | null;
      author: {
        displayName?: string | null;
        pictureUrl?: string | null;
        initials?: string | null;
        title?: string | null;
      };
    } | null;
  };
};

export type SeatplansQueryVariables = Exact<{
  travelVendorId?: InputMaybe<Scalars['TWFilter_ID']>;
}>;

export type SeatplansQuery = {
  vendors?: {
    edges: Array<{
      node?: {
        id: string;
        name: string;
        travelVendorId: string;
        seatingCharts?: Array<{
          id: string;
          seatplanConfigurationJson: string;
          label: string;
          travelAirSeatingChartId: string;
          numberOfSeats?: number | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type TeamsQueryVariables = Exact<{
  permission?: InputMaybe<Scalars['String']>;
}>;

export type TeamsQuery = {
  teams?: { edges: Array<{ node?: { id: string; label: string } | null } | null> } | null;
};

export type TravelVendorCreateMutationVariables = Exact<{
  input?: InputMaybe<TravelVendorCreateInput>;
}>;

export type TravelVendorCreateMutation = {
  travelVendorCreate: { travelVendor?: { id: string } | null };
};

export type DeleteVendorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteVendorMutation = {
  travelVendorDelete: { userErrors: Array<{ message: string; field: Array<string> }> };
};

export type EditVendorMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<TravelVendorUpdateInput>;
}>;

export type EditVendorMutation = { travelVendorUpdate: { travelVendor?: { id: string } | null } };

export type VendorTypesQueryVariables = Exact<{ [key: string]: never }>;

export type VendorTypesQuery = {
  vendorTypes?: { edges: Array<{ node?: { label: string; id: string } | null } | null> } | null;
};

export type VendorsQueryVariables = Exact<{ [key: string]: never }>;

export type VendorsQuery = {
  vendors?: {
    edges: Array<{
      node?: {
        id: string;
        name: string;
        travelVendorId: string;
        phoneFax?: string | null;
        phoneMain?: string | null;
        travelVendorType?: { id: string; label: string } | null;
        address?: {
          street1?: string | null;
          street2?: string | null;
          city?: string | null;
          countryIso?: string | null;
          customStateProvince?: string | null;
          zipCode?: string | null;
          country?: {
            name?: string | null;
            printableName?: string | null;
            numcode?: string | null;
          } | null;
          state?: { name?: string | null; code?: string | null } | null;
        } | null;
        teams?: Array<{ id: string; label: string } | null> | null;
        contacts?: Array<{
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          phoneOffice?: string | null;
          phoneMobile?: string | null;
          phoneFax?: string | null;
          emailAddress?: string | null;
          isPrimaryContact: boolean;
          title?: string | null;
        } | null> | null;
        notes?: Array<{
          id: string;
          title: string;
          content: string;
          createdDateTime: any;
          updatedDateTime?: any | null;
          author: {
            displayName?: string | null;
            pictureUrl?: string | null;
            initials?: string | null;
            title?: string | null;
          };
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateSeatplanMutationVariables = Exact<{
  input?: InputMaybe<TravelAirSeatingChartCreateInput>;
}>;

export type CreateSeatplanMutation = {
  travelAirSeatingChartCreate: { travelAirSeatingChart?: { label: string } | null };
};

export type DeleteSeatplanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteSeatplanMutation = {
  travelAirSeatingChartDelete: { travelAirSeatingChart?: { label: string } | null };
};

export type UpdateSeatplanMutationVariables = Exact<{
  input?: InputMaybe<TravelAirSeatingChartUpdateInput>;
  id: Scalars['ID'];
}>;

export type UpdateSeatplanMutation = {
  travelAirSeatingChartUpdate: { travelAirSeatingChart?: { label: string } | null };
};

export type TokenRefreshMutationVariables = Exact<{ [key: string]: never }>;

export type TokenRefreshMutation = { tokenRefresh: { ok?: boolean | null } };

export const FetchAllAdvisorsDocument = gql`
  query FetchAllAdvisors {
    advisors(isDeleted: "eq:false") {
      totalCount
      edges {
        node {
          advisorId
          personId
          preferredName
          lastName
          pictureDownloadUrl
        }
      }
    }
  }
`;

/**
 * __useFetchAllAdvisorsQuery__
 *
 * To run a query within a React component, call `useFetchAllAdvisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllAdvisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllAdvisorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllAdvisorsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllAdvisorsQuery, FetchAllAdvisorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllAdvisorsQuery, FetchAllAdvisorsQueryVariables>(
    FetchAllAdvisorsDocument,
    options,
  );
}
export function useFetchAllAdvisorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAllAdvisorsQuery, FetchAllAdvisorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllAdvisorsQuery, FetchAllAdvisorsQueryVariables>(
    FetchAllAdvisorsDocument,
    options,
  );
}
export type FetchAllAdvisorsQueryHookResult = ReturnType<typeof useFetchAllAdvisorsQuery>;
export type FetchAllAdvisorsLazyQueryHookResult = ReturnType<typeof useFetchAllAdvisorsLazyQuery>;
export type FetchAllAdvisorsQueryResult = Apollo.QueryResult<
  FetchAllAdvisorsQuery,
  FetchAllAdvisorsQueryVariables
>;
export function refetchFetchAllAdvisorsQuery(variables?: FetchAllAdvisorsQueryVariables) {
  return { query: FetchAllAdvisorsDocument, variables: variables };
}
export const TwInputSelectMessageSendersDocument = gql`
  query TWInputSelectMessageSenders($hasBeenProcessed: Boolean) {
    messageSenders(hasBeenProcessed: $hasBeenProcessed) {
      personId
      preferredName
      lastName
    }
  }
`;

/**
 * __useTwInputSelectMessageSendersQuery__
 *
 * To run a query within a React component, call `useTwInputSelectMessageSendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwInputSelectMessageSendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwInputSelectMessageSendersQuery({
 *   variables: {
 *      hasBeenProcessed: // value for 'hasBeenProcessed'
 *   },
 * });
 */
export function useTwInputSelectMessageSendersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TwInputSelectMessageSendersQuery,
    TwInputSelectMessageSendersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TwInputSelectMessageSendersQuery,
    TwInputSelectMessageSendersQueryVariables
  >(TwInputSelectMessageSendersDocument, options);
}
export function useTwInputSelectMessageSendersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TwInputSelectMessageSendersQuery,
    TwInputSelectMessageSendersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TwInputSelectMessageSendersQuery,
    TwInputSelectMessageSendersQueryVariables
  >(TwInputSelectMessageSendersDocument, options);
}
export type TwInputSelectMessageSendersQueryHookResult = ReturnType<
  typeof useTwInputSelectMessageSendersQuery
>;
export type TwInputSelectMessageSendersLazyQueryHookResult = ReturnType<
  typeof useTwInputSelectMessageSendersLazyQuery
>;
export type TwInputSelectMessageSendersQueryResult = Apollo.QueryResult<
  TwInputSelectMessageSendersQuery,
  TwInputSelectMessageSendersQueryVariables
>;
export function refetchTwInputSelectMessageSendersQuery(
  variables?: TwInputSelectMessageSendersQueryVariables,
) {
  return { query: TwInputSelectMessageSendersDocument, variables: variables };
}
export const MessageTypeOptionsDocument = gql`
  query MessageTypeOptions {
    messageTypes {
      code
      integratorLabel
      integratorMessageType
    }
  }
`;

/**
 * __useMessageTypeOptionsQuery__
 *
 * To run a query within a React component, call `useMessageTypeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageTypeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageTypeOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageTypeOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<MessageTypeOptionsQuery, MessageTypeOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessageTypeOptionsQuery, MessageTypeOptionsQueryVariables>(
    MessageTypeOptionsDocument,
    options,
  );
}
export function useMessageTypeOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessageTypeOptionsQuery,
    MessageTypeOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessageTypeOptionsQuery, MessageTypeOptionsQueryVariables>(
    MessageTypeOptionsDocument,
    options,
  );
}
export type MessageTypeOptionsQueryHookResult = ReturnType<typeof useMessageTypeOptionsQuery>;
export type MessageTypeOptionsLazyQueryHookResult = ReturnType<
  typeof useMessageTypeOptionsLazyQuery
>;
export type MessageTypeOptionsQueryResult = Apollo.QueryResult<
  MessageTypeOptionsQuery,
  MessageTypeOptionsQueryVariables
>;
export function refetchMessageTypeOptionsQuery(variables?: MessageTypeOptionsQueryVariables) {
  return { query: MessageTypeOptionsDocument, variables: variables };
}
export const DefaultUserTypePermissionsInputDocument = gql`
  query defaultUserTypePermissionsInput {
    permissions(sort: code_name_asc, onlyAvailable: true) {
      edges {
        node {
          id
          category
          displayName
        }
      }
    }
  }
`;

/**
 * __useDefaultUserTypePermissionsInputQuery__
 *
 * To run a query within a React component, call `useDefaultUserTypePermissionsInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultUserTypePermissionsInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultUserTypePermissionsInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultUserTypePermissionsInputQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DefaultUserTypePermissionsInputQuery,
    DefaultUserTypePermissionsInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DefaultUserTypePermissionsInputQuery,
    DefaultUserTypePermissionsInputQueryVariables
  >(DefaultUserTypePermissionsInputDocument, options);
}
export function useDefaultUserTypePermissionsInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DefaultUserTypePermissionsInputQuery,
    DefaultUserTypePermissionsInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DefaultUserTypePermissionsInputQuery,
    DefaultUserTypePermissionsInputQueryVariables
  >(DefaultUserTypePermissionsInputDocument, options);
}
export type DefaultUserTypePermissionsInputQueryHookResult = ReturnType<
  typeof useDefaultUserTypePermissionsInputQuery
>;
export type DefaultUserTypePermissionsInputLazyQueryHookResult = ReturnType<
  typeof useDefaultUserTypePermissionsInputLazyQuery
>;
export type DefaultUserTypePermissionsInputQueryResult = Apollo.QueryResult<
  DefaultUserTypePermissionsInputQuery,
  DefaultUserTypePermissionsInputQueryVariables
>;
export function refetchDefaultUserTypePermissionsInputQuery(
  variables?: DefaultUserTypePermissionsInputQueryVariables,
) {
  return { query: DefaultUserTypePermissionsInputDocument, variables: variables };
}
export const GetEligibilityYearsDocument = gql`
  query GetEligibilityYears($includeAll: Boolean, $includeIsCollegiate: Boolean = false) {
    eligibilityYears(includeAll: $includeAll) {
      id
      label
      isCollegiate @include(if: $includeIsCollegiate)
    }
  }
`;

/**
 * __useGetEligibilityYearsQuery__
 *
 * To run a query within a React component, call `useGetEligibilityYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilityYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilityYearsQuery({
 *   variables: {
 *      includeAll: // value for 'includeAll'
 *      includeIsCollegiate: // value for 'includeIsCollegiate'
 *   },
 * });
 */
export function useGetEligibilityYearsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEligibilityYearsQuery,
    GetEligibilityYearsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEligibilityYearsQuery, GetEligibilityYearsQueryVariables>(
    GetEligibilityYearsDocument,
    options,
  );
}
export function useGetEligibilityYearsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEligibilityYearsQuery,
    GetEligibilityYearsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEligibilityYearsQuery, GetEligibilityYearsQueryVariables>(
    GetEligibilityYearsDocument,
    options,
  );
}
export type GetEligibilityYearsQueryHookResult = ReturnType<typeof useGetEligibilityYearsQuery>;
export type GetEligibilityYearsLazyQueryHookResult = ReturnType<
  typeof useGetEligibilityYearsLazyQuery
>;
export type GetEligibilityYearsQueryResult = Apollo.QueryResult<
  GetEligibilityYearsQuery,
  GetEligibilityYearsQueryVariables
>;
export function refetchGetEligibilityYearsQuery(variables?: GetEligibilityYearsQueryVariables) {
  return { query: GetEligibilityYearsDocument, variables: variables };
}
export const TreeSelectCourseBySubjectQueryDocument = gql`
  query TreeSelectCourseBySubjectQuery(
    $sort: [CourseSortEnum]
    $studentIds: [Int]
    $termIds: [Int]
    $includeGeneral: Boolean
  ) {
    courses(
      sort: $sort
      studentIds: $studentIds
      termIds: $termIds
      includeGeneral: $includeGeneral
    ) {
      edges {
        node {
          courseId: pk
          label
          description
          subject {
            subjectId: pk
            label
            description
          }
        }
      }
    }
  }
`;

/**
 * __useTreeSelectCourseBySubjectQueryQuery__
 *
 * To run a query within a React component, call `useTreeSelectCourseBySubjectQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreeSelectCourseBySubjectQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreeSelectCourseBySubjectQueryQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      studentIds: // value for 'studentIds'
 *      termIds: // value for 'termIds'
 *      includeGeneral: // value for 'includeGeneral'
 *   },
 * });
 */
export function useTreeSelectCourseBySubjectQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TreeSelectCourseBySubjectQueryQuery,
    TreeSelectCourseBySubjectQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TreeSelectCourseBySubjectQueryQuery,
    TreeSelectCourseBySubjectQueryQueryVariables
  >(TreeSelectCourseBySubjectQueryDocument, options);
}
export function useTreeSelectCourseBySubjectQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TreeSelectCourseBySubjectQueryQuery,
    TreeSelectCourseBySubjectQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TreeSelectCourseBySubjectQueryQuery,
    TreeSelectCourseBySubjectQueryQueryVariables
  >(TreeSelectCourseBySubjectQueryDocument, options);
}
export type TreeSelectCourseBySubjectQueryQueryHookResult = ReturnType<
  typeof useTreeSelectCourseBySubjectQueryQuery
>;
export type TreeSelectCourseBySubjectQueryLazyQueryHookResult = ReturnType<
  typeof useTreeSelectCourseBySubjectQueryLazyQuery
>;
export type TreeSelectCourseBySubjectQueryQueryResult = Apollo.QueryResult<
  TreeSelectCourseBySubjectQueryQuery,
  TreeSelectCourseBySubjectQueryQueryVariables
>;
export function refetchTreeSelectCourseBySubjectQueryQuery(
  variables?: TreeSelectCourseBySubjectQueryQueryVariables,
) {
  return { query: TreeSelectCourseBySubjectQueryDocument, variables: variables };
}
export const UserSelectionOptionsDocument = gql`
  query UserSelectionOptions(
    $filters: SelectionGroupFiltersInput
    $withPeople: Boolean = true
    $withTeamMembership: Boolean = false
  ) {
    groups(filters: $filters) {
      label
      pluralLabel
      groupType
      selectionCode
      team {
        label
        teamId
      }
      people @include(if: $withPeople) {
        label: fullPreferredName
        displayName
        firstName
        lastName
        initials
        pictureUrl
        selectionCode
        id
        personId
        streamUserId
        teamMembership @include(if: $withTeamMembership) {
          id
          isOrgSuperuser
          isAthlete
          userTypes {
            name
            namePlural
          }
        }
      }
    }
  }
`;

/**
 * __useUserSelectionOptionsQuery__
 *
 * To run a query within a React component, call `useUserSelectionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectionOptionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      withPeople: // value for 'withPeople'
 *      withTeamMembership: // value for 'withTeamMembership'
 *   },
 * });
 */
export function useUserSelectionOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSelectionOptionsQuery,
    UserSelectionOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSelectionOptionsQuery, UserSelectionOptionsQueryVariables>(
    UserSelectionOptionsDocument,
    options,
  );
}
export function useUserSelectionOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSelectionOptionsQuery,
    UserSelectionOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSelectionOptionsQuery, UserSelectionOptionsQueryVariables>(
    UserSelectionOptionsDocument,
    options,
  );
}
export type UserSelectionOptionsQueryHookResult = ReturnType<typeof useUserSelectionOptionsQuery>;
export type UserSelectionOptionsLazyQueryHookResult = ReturnType<
  typeof useUserSelectionOptionsLazyQuery
>;
export type UserSelectionOptionsQueryResult = Apollo.QueryResult<
  UserSelectionOptionsQuery,
  UserSelectionOptionsQueryVariables
>;
export function refetchUserSelectionOptionsQuery(variables?: UserSelectionOptionsQueryVariables) {
  return { query: UserSelectionOptionsDocument, variables: variables };
}
export const UserSelectionDocument = gql`
  query UserSelection($filters: SelectionGroupFiltersInput, $withPeople: Boolean = true) {
    groups(filters: $filters) {
      label
      selectionCode
      people @include(if: $withPeople) {
        displayName
        pictureUrl
        selectionCode
        id
        personId
        userTypesByTeam
      }
    }
  }
`;

/**
 * __useUserSelectionQuery__
 *
 * To run a query within a React component, call `useUserSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectionQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      withPeople: // value for 'withPeople'
 *   },
 * });
 */
export function useUserSelectionQuery(
  baseOptions?: Apollo.QueryHookOptions<UserSelectionQuery, UserSelectionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSelectionQuery, UserSelectionQueryVariables>(
    UserSelectionDocument,
    options,
  );
}
export function useUserSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserSelectionQuery, UserSelectionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSelectionQuery, UserSelectionQueryVariables>(
    UserSelectionDocument,
    options,
  );
}
export type UserSelectionQueryHookResult = ReturnType<typeof useUserSelectionQuery>;
export type UserSelectionLazyQueryHookResult = ReturnType<typeof useUserSelectionLazyQuery>;
export type UserSelectionQueryResult = Apollo.QueryResult<
  UserSelectionQuery,
  UserSelectionQueryVariables
>;
export function refetchUserSelectionQuery(variables?: UserSelectionQueryVariables) {
  return { query: UserSelectionDocument, variables: variables };
}
export const StreamTokenDocument = gql`
  query StreamToken {
    viewer {
      person {
        displayName
        pictureUrl
      }
      streamTokenResponse {
        streamUserId
        token
      }
    }
  }
`;

/**
 * __useStreamTokenQuery__
 *
 * To run a query within a React component, call `useStreamTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useStreamTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<StreamTokenQuery, StreamTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StreamTokenQuery, StreamTokenQueryVariables>(StreamTokenDocument, options);
}
export function useStreamTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StreamTokenQuery, StreamTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StreamTokenQuery, StreamTokenQueryVariables>(
    StreamTokenDocument,
    options,
  );
}
export type StreamTokenQueryHookResult = ReturnType<typeof useStreamTokenQuery>;
export type StreamTokenLazyQueryHookResult = ReturnType<typeof useStreamTokenLazyQuery>;
export type StreamTokenQueryResult = Apollo.QueryResult<
  StreamTokenQuery,
  StreamTokenQueryVariables
>;
export function refetchStreamTokenQuery(variables?: StreamTokenQueryVariables) {
  return { query: StreamTokenDocument, variables: variables };
}
export const AxleDocument = gql`
  query Axle {
    viewer {
      personInfo {
        webAccess
      }
      sharedNavbar {
        topColor
        bottomColor
        imageUrl
        targetUrls {
          app
          url
        }
        teamLogos {
          teamId
          imageUrl
        }
        inactivityTimeout
      }
      personInfo {
        appAccess
      }
      caraInfo {
        isCaraEnabled
        adequateChangeNoticeHours
      }
    }
  }
`;

/**
 * __useAxleQuery__
 *
 * To run a query within a React component, call `useAxleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAxleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAxleQuery({
 *   variables: {
 *   },
 * });
 */
export function useAxleQuery(baseOptions?: Apollo.QueryHookOptions<AxleQuery, AxleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AxleQuery, AxleQueryVariables>(AxleDocument, options);
}
export function useAxleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AxleQuery, AxleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AxleQuery, AxleQueryVariables>(AxleDocument, options);
}
export type AxleQueryHookResult = ReturnType<typeof useAxleQuery>;
export type AxleLazyQueryHookResult = ReturnType<typeof useAxleLazyQuery>;
export type AxleQueryResult = Apollo.QueryResult<AxleQuery, AxleQueryVariables>;
export function refetchAxleQuery(variables?: AxleQueryVariables) {
  return { query: AxleDocument, variables: variables };
}
export const ViewerDataDocument = gql`
  query ViewerData {
    viewer {
      orgId
      teamId
      personId
      isOrgSuperuser
      isTeamSuperuser
      username
      hasMultipleAccounts
      enabledApps {
        id
        name
        logo
        link
      }
      person {
        id
        personId
        emailAddress
        firstName
        lastName
        fullName
        fullNameNormalOrder
        displayName
        preferredName
        title
        initials
        isOrgLevelUser
        orgId
        pictureUrl
        selectionCode
        label
        timeZone
        hasLinkedSsoAccount
        usesExternalAuthentication
      }
      userSettings {
        calendarAlertDefault
      }
      userPreferences {
        uses24HourTime
      }
      permissions {
        canAddAvailability
        canAddOtherUserAppointments
        canAddTravelApptType
        canAddComplianceApptType
        canAdminLoginAccess
        canAdminTicketing
        canAssignForms
        canCopyAppointments
        canDeactivateUsers
        canFindTutors
        canManageAllTeamsAccess
        canManageAppAssignments
        canManageDigitalDisplays
        canManageForms
        canManageNews
        canManagePermissions
        canManageQuickForms
        canManageTasks
        canManageTravel
        canModifyStaffProfiles
        canModifyUsers
        canManageWeblinks
        canSendMessages
        canSendTravelLetter
        canSubmitForms
        canViewAcademics2
        canViewAthleteList
        canViewConversations
        canViewCalendarActivityLogs
        canViewMessageHistory
        canViewOthersMessageHistory
        canViewOtherUserAppointments
        canViewContactDetails
        canViewStaffProfile
        canViewTicketing
        canViewTravel
        canViewOtherTrips
        canViewVideo
        canViewWeblinks
        canViewNews
        canViewTmp
        canViewCaraLogs
        canEditCaraTeamDeclarations
        canAdminTmp
        isAcademicSuperuser
        teamsCanAdminVideos
        teamsCanAddFiles
        teamsCanAdminFileFolders
        teamsCanManageAcademics
        teamsCanViewAcademicsClass
        isLegacyApiUser
        canAddTravelContacts
        academics2ModuleEnabled
        calendarModuleEnabled
        messagingModuleEnabled
        tmpModuleEnabled
        travelModuleEnabled
        disableMessageEditDelete
        enableGuardianshipFeatures
      }
      availableTabs {
        key
        overrideLabel
        label
        path
      }
      currentOrg {
        id
        label
        logoUrl
        allTeamsTeamId
        usesExternalAuthentication
        usesSsoAsDefault
        idpMatchAttributeType
        isCollegiate
        isNonAthleteOrg
        timeZoneCode
        uses24HourTime
        institutionId
        athleteUserType {
          value: id
          label: name
        }
        athleteStatuses {
          value: id
          label: labelPlural
          isActive: isActiveStatus
        }
        branding {
          headerBgColor
          headerTextColor
          headerLinkColor
          primaryColor
          primaryLighterColor
          primaryLightestColor
        }
        userTypes {
          id
          name
          namePlural
          personTypeId
        }
      }
      currentTeam {
        id
        label
        initials
        logoUrl
        identityFieldLabel
        branding {
          headerBgColor
          headerTextColor
          headerLinkColor
          primaryColor
          primaryLighterColor
          primaryLightestColor
        }
        sportId
        teamId
        institution {
          city
          state
          leagueId
          id
        }
      }
      currentMembership {
        id
        athleteStatus {
          id
          isActiveStatus
          label
        }
        userTypes {
          id
          pk
          name
          namePlural
        }
      }
      availableTeams {
        id
        teamId
        label
        shortLabel
        initials
        logoUrl
        canAddToTeam
        canRemoveFromTeam
        canViewOtherUsers
        moduleCodes
      }
      teamSuperuserTeams {
        id
        teamId
        label
      }
      travelSettings {
        label
        enabled
      }
      visibleUserTypes {
        value: id
        label: name
      }
      allAppointmentTypes: allowedAppointmentTypes {
        id
        appointmentTypeId
        color
        label
        canUserAdd
        isActive
        isTutoring
        isDayOff
        travelOnly
        complianceLabel
      }
    }
  }
`;

/**
 * __useViewerDataQuery__
 *
 * To run a query within a React component, call `useViewerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerDataQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerDataQuery, ViewerDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewerDataQuery, ViewerDataQueryVariables>(ViewerDataDocument, options);
}
export function useViewerDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewerDataQuery, ViewerDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewerDataQuery, ViewerDataQueryVariables>(
    ViewerDataDocument,
    options,
  );
}
export type ViewerDataQueryHookResult = ReturnType<typeof useViewerDataQuery>;
export type ViewerDataLazyQueryHookResult = ReturnType<typeof useViewerDataLazyQuery>;
export type ViewerDataQueryResult = Apollo.QueryResult<ViewerDataQuery, ViewerDataQueryVariables>;
export function refetchViewerDataQuery(variables?: ViewerDataQueryVariables) {
  return { query: ViewerDataDocument, variables: variables };
}
export const CreateStudyHallDocument = gql`
  mutation CreateStudyHall($input: StudyHallInput!) {
    studyHallCreate(input: $input) {
      studyHall {
        id
        label
        rotateQrCode
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CreateStudyHallMutationFn = Apollo.MutationFunction<
  CreateStudyHallMutation,
  CreateStudyHallMutationVariables
>;

/**
 * __useCreateStudyHallMutation__
 *
 * To run a mutation, you first call `useCreateStudyHallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudyHallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudyHallMutation, { data, loading, error }] = useCreateStudyHallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudyHallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStudyHallMutation,
    CreateStudyHallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStudyHallMutation, CreateStudyHallMutationVariables>(
    CreateStudyHallDocument,
    options,
  );
}
export type CreateStudyHallMutationHookResult = ReturnType<typeof useCreateStudyHallMutation>;
export type CreateStudyHallMutationResult = Apollo.MutationResult<CreateStudyHallMutation>;
export type CreateStudyHallMutationOptions = Apollo.BaseMutationOptions<
  CreateStudyHallMutation,
  CreateStudyHallMutationVariables
>;
export const DeleteStudyHallDocument = gql`
  mutation DeleteStudyHall($id: ID!) {
    studyHallDelete(id: $id) {
      studyHall {
        id
        isExpired
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type DeleteStudyHallMutationFn = Apollo.MutationFunction<
  DeleteStudyHallMutation,
  DeleteStudyHallMutationVariables
>;

/**
 * __useDeleteStudyHallMutation__
 *
 * To run a mutation, you first call `useDeleteStudyHallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudyHallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudyHallMutation, { data, loading, error }] = useDeleteStudyHallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStudyHallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStudyHallMutation,
    DeleteStudyHallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteStudyHallMutation, DeleteStudyHallMutationVariables>(
    DeleteStudyHallDocument,
    options,
  );
}
export type DeleteStudyHallMutationHookResult = ReturnType<typeof useDeleteStudyHallMutation>;
export type DeleteStudyHallMutationResult = Apollo.MutationResult<DeleteStudyHallMutation>;
export type DeleteStudyHallMutationOptions = Apollo.BaseMutationOptions<
  DeleteStudyHallMutation,
  DeleteStudyHallMutationVariables
>;
export const FetchStudyHallsDocument = gql`
  query FetchStudyHalls(
    $first: Int
    $after: String
    $sort: [StudyHallSortEnum]
    $isExpired: TWFilter_Boolean
    $searchStudyHalls: String
  ) {
    studyHalls(
      first: $first
      after: $after
      sort: $sort
      isExpired: $isExpired
      searchStudyHalls: $searchStudyHalls
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          studyHallId
          isExpired
          createdDate
          checkinAppToken
          creator {
            fullNameNormalOrder
          }
          label
          rotateQrCode
          startDatetimeUtc
          endDatetimeUtc
          canEditDatetimes
        }
      }
    }
  }
`;

/**
 * __useFetchStudyHallsQuery__
 *
 * To run a query within a React component, call `useFetchStudyHallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStudyHallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStudyHallsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *      isExpired: // value for 'isExpired'
 *      searchStudyHalls: // value for 'searchStudyHalls'
 *   },
 * });
 */
export function useFetchStudyHallsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchStudyHallsQuery, FetchStudyHallsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchStudyHallsQuery, FetchStudyHallsQueryVariables>(
    FetchStudyHallsDocument,
    options,
  );
}
export function useFetchStudyHallsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchStudyHallsQuery, FetchStudyHallsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchStudyHallsQuery, FetchStudyHallsQueryVariables>(
    FetchStudyHallsDocument,
    options,
  );
}
export type FetchStudyHallsQueryHookResult = ReturnType<typeof useFetchStudyHallsQuery>;
export type FetchStudyHallsLazyQueryHookResult = ReturnType<typeof useFetchStudyHallsLazyQuery>;
export type FetchStudyHallsQueryResult = Apollo.QueryResult<
  FetchStudyHallsQuery,
  FetchStudyHallsQueryVariables
>;
export function refetchFetchStudyHallsQuery(variables?: FetchStudyHallsQueryVariables) {
  return { query: FetchStudyHallsDocument, variables: variables };
}
export const UpdateStudyHallDocument = gql`
  mutation UpdateStudyHall($input: StudyHallInput!, $id: ID!) {
    studyHallUpdate(id: $id, input: $input) {
      studyHall {
        id
        label
        rotateQrCode
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateStudyHallMutationFn = Apollo.MutationFunction<
  UpdateStudyHallMutation,
  UpdateStudyHallMutationVariables
>;

/**
 * __useUpdateStudyHallMutation__
 *
 * To run a mutation, you first call `useUpdateStudyHallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudyHallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudyHallMutation, { data, loading, error }] = useUpdateStudyHallMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateStudyHallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudyHallMutation,
    UpdateStudyHallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStudyHallMutation, UpdateStudyHallMutationVariables>(
    UpdateStudyHallDocument,
    options,
  );
}
export type UpdateStudyHallMutationHookResult = ReturnType<typeof useUpdateStudyHallMutation>;
export type UpdateStudyHallMutationResult = Apollo.MutationResult<UpdateStudyHallMutation>;
export type UpdateStudyHallMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudyHallMutation,
  UpdateStudyHallMutationVariables
>;
export const CreateAppointmentFromTutorSessionDocument = gql`
  mutation CreateAppointmentFromTutorSession($id: ID!) {
    createAppointmentFromSession(id: $id) {
      session {
        id
        appointmentOccurrenceId
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type CreateAppointmentFromTutorSessionMutationFn = Apollo.MutationFunction<
  CreateAppointmentFromTutorSessionMutation,
  CreateAppointmentFromTutorSessionMutationVariables
>;

/**
 * __useCreateAppointmentFromTutorSessionMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentFromTutorSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentFromTutorSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentFromTutorSessionMutation, { data, loading, error }] = useCreateAppointmentFromTutorSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateAppointmentFromTutorSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentFromTutorSessionMutation,
    CreateAppointmentFromTutorSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentFromTutorSessionMutation,
    CreateAppointmentFromTutorSessionMutationVariables
  >(CreateAppointmentFromTutorSessionDocument, options);
}
export type CreateAppointmentFromTutorSessionMutationHookResult = ReturnType<
  typeof useCreateAppointmentFromTutorSessionMutation
>;
export type CreateAppointmentFromTutorSessionMutationResult =
  Apollo.MutationResult<CreateAppointmentFromTutorSessionMutation>;
export type CreateAppointmentFromTutorSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentFromTutorSessionMutation,
  CreateAppointmentFromTutorSessionMutationVariables
>;
export const DeleteTutoringSessionDocument = gql`
  mutation DeleteTutoringSession($id: ID!) {
    sessionDelete(id: $id) {
      session {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type DeleteTutoringSessionMutationFn = Apollo.MutationFunction<
  DeleteTutoringSessionMutation,
  DeleteTutoringSessionMutationVariables
>;

/**
 * __useDeleteTutoringSessionMutation__
 *
 * To run a mutation, you first call `useDeleteTutoringSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTutoringSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTutoringSessionMutation, { data, loading, error }] = useDeleteTutoringSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTutoringSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTutoringSessionMutation,
    DeleteTutoringSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTutoringSessionMutation, DeleteTutoringSessionMutationVariables>(
    DeleteTutoringSessionDocument,
    options,
  );
}
export type DeleteTutoringSessionMutationHookResult = ReturnType<
  typeof useDeleteTutoringSessionMutation
>;
export type DeleteTutoringSessionMutationResult =
  Apollo.MutationResult<DeleteTutoringSessionMutation>;
export type DeleteTutoringSessionMutationOptions = Apollo.BaseMutationOptions<
  DeleteTutoringSessionMutation,
  DeleteTutoringSessionMutationVariables
>;
export const FetchTutorsDocument = gql`
  query FetchTutors(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTutors: String
    $courseIds: [Int]
    $subjectIds: [Int]
    $isActive: TWFilter_Boolean
    $tutorId: TWFilter_ID
    $personId: TWFilter_Int
  ) {
    tutors(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTutors: $searchTutors
      courseIds: $courseIds
      subjectIds: $subjectIds
      isActive: $isActive
      tutorId: $tutorId
      personId: $personId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          tutorId: pk
          preferredName
          lastName
          personId
          fullName
          maximumNumberOfHours
          subjects {
            totalCount
            edges {
              cursor
              node {
                id
                subjectId: pk
                label
                description
                shortCode
                customerKey
              }
            }
          }
          person {
            id
            emailAddress
            teams {
              id
              teamId
              label
            }
            title
          }
          pictureDownloadUrl
          isActive
          courses {
            totalCount
            edges {
              cursor
              node {
                id
                courseId: pk
                credits
                label
                subject {
                  id
                  subjectId: pk
                  label
                  description
                  shortCode
                  customerKey
                }
                description
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTutorsQuery__
 *
 * To run a query within a React component, call `useFetchTutorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTutorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTutorsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchTutors: // value for 'searchTutors'
 *      courseIds: // value for 'courseIds'
 *      subjectIds: // value for 'subjectIds'
 *      isActive: // value for 'isActive'
 *      tutorId: // value for 'tutorId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useFetchTutorsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchTutorsQuery, FetchTutorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTutorsQuery, FetchTutorsQueryVariables>(FetchTutorsDocument, options);
}
export function useFetchTutorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTutorsQuery, FetchTutorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTutorsQuery, FetchTutorsQueryVariables>(
    FetchTutorsDocument,
    options,
  );
}
export type FetchTutorsQueryHookResult = ReturnType<typeof useFetchTutorsQuery>;
export type FetchTutorsLazyQueryHookResult = ReturnType<typeof useFetchTutorsLazyQuery>;
export type FetchTutorsQueryResult = Apollo.QueryResult<
  FetchTutorsQuery,
  FetchTutorsQueryVariables
>;
export function refetchFetchTutorsQuery(variables?: FetchTutorsQueryVariables) {
  return { query: FetchTutorsDocument, variables: variables };
}
export const CourseCreateDocument = gql`
  mutation CourseCreate($input: CourseCreateInput) {
    courseCreate(input: $input) {
      course {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type CourseCreateMutationFn = Apollo.MutationFunction<
  CourseCreateMutation,
  CourseCreateMutationVariables
>;

/**
 * __useCourseCreateMutation__
 *
 * To run a mutation, you first call `useCourseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseCreateMutation, { data, loading, error }] = useCourseCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<CourseCreateMutation, CourseCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CourseCreateMutation, CourseCreateMutationVariables>(
    CourseCreateDocument,
    options,
  );
}
export type CourseCreateMutationHookResult = ReturnType<typeof useCourseCreateMutation>;
export type CourseCreateMutationResult = Apollo.MutationResult<CourseCreateMutation>;
export type CourseCreateMutationOptions = Apollo.BaseMutationOptions<
  CourseCreateMutation,
  CourseCreateMutationVariables
>;
export const CourseSectionCreateDocument = gql`
  mutation CourseSectionCreate($input: CourseSectionCreateInput) {
    courseSectionCreate(input: $input) {
      courseSection {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type CourseSectionCreateMutationFn = Apollo.MutationFunction<
  CourseSectionCreateMutation,
  CourseSectionCreateMutationVariables
>;

/**
 * __useCourseSectionCreateMutation__
 *
 * To run a mutation, you first call `useCourseSectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSectionCreateMutation, { data, loading, error }] = useCourseSectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseSectionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseSectionCreateMutation,
    CourseSectionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CourseSectionCreateMutation, CourseSectionCreateMutationVariables>(
    CourseSectionCreateDocument,
    options,
  );
}
export type CourseSectionCreateMutationHookResult = ReturnType<
  typeof useCourseSectionCreateMutation
>;
export type CourseSectionCreateMutationResult = Apollo.MutationResult<CourseSectionCreateMutation>;
export type CourseSectionCreateMutationOptions = Apollo.BaseMutationOptions<
  CourseSectionCreateMutation,
  CourseSectionCreateMutationVariables
>;
export const FetchCourseSectionsDocument = gql`
  query FetchCourseSections(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $courseId: TWFilter_Int
    $courseSectionId: TWFilter_ID
    $courseSectionSchedulesDay: TWFilter_DaysOfWeekEnum
    $courseSubjectId: TWFilter_Int
    $enableNameSort: Boolean
    $endDate: TWFilter_String
    $searchCourseSections: String
    $startDate: TWFilter_String
    $studentIds: [Int]
    $teamIds: [Int]
    $termId: TWFilter_Int
    $sort: [CourseSectionSortEnum]
  ) {
    courseSections(
      after: $after
      before: $before
      first: $first
      last: $last
      courseId: $courseId
      courseSectionId: $courseSectionId
      courseSectionSchedulesDay: $courseSectionSchedulesDay
      courseSubjectId: $courseSubjectId
      enableNameSort: $enableNameSort
      endDate: $endDate
      searchCourseSections: $searchCourseSections
      startDate: $startDate
      studentIds: $studentIds
      teamIds: $teamIds
      termId: $termId
      sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          courseSectionId: pk
          courseId
          term {
            id
            termId: pk
            startDate
            endDate
            label
          }
          enrollments {
            totalCount
            edges {
              node {
                id
                droppedDate
                creditsAttempted
                studentTermId
                studentTerm {
                  id
                  studentTermId
                  studentId
                  student {
                    id
                    personId
                    isDeleted
                    fullName
                    lastName
                    preferredName
                    teamIds
                  }
                }
              }
            }
          }
          courseSectionSchedules {
            edges {
              node {
                id
                courseSectionScheduleId
                startTime
                endTime
                day
                location
              }
            }
          }
          professor {
            id
            professorId
            firstName
            lastName
            fullName
            emailAddress
            phoneNumber
          }
          course {
            courseId: pk
            id
            credits
            label
            subject {
              id
              subjectId: pk
              label
              description
              shortCode
              customerKey
            }
            description
          }
          customerKey
          isOnline
          isDeleted
          shortCode
          startDate
          endDate
        }
      }
    }
  }
`;

/**
 * __useFetchCourseSectionsQuery__
 *
 * To run a query within a React component, call `useFetchCourseSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCourseSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCourseSectionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      courseId: // value for 'courseId'
 *      courseSectionId: // value for 'courseSectionId'
 *      courseSectionSchedulesDay: // value for 'courseSectionSchedulesDay'
 *      courseSubjectId: // value for 'courseSubjectId'
 *      enableNameSort: // value for 'enableNameSort'
 *      endDate: // value for 'endDate'
 *      searchCourseSections: // value for 'searchCourseSections'
 *      startDate: // value for 'startDate'
 *      studentIds: // value for 'studentIds'
 *      teamIds: // value for 'teamIds'
 *      termId: // value for 'termId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchCourseSectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchCourseSectionsQuery,
    FetchCourseSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCourseSectionsQuery, FetchCourseSectionsQueryVariables>(
    FetchCourseSectionsDocument,
    options,
  );
}
export function useFetchCourseSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCourseSectionsQuery,
    FetchCourseSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCourseSectionsQuery, FetchCourseSectionsQueryVariables>(
    FetchCourseSectionsDocument,
    options,
  );
}
export type FetchCourseSectionsQueryHookResult = ReturnType<typeof useFetchCourseSectionsQuery>;
export type FetchCourseSectionsLazyQueryHookResult = ReturnType<
  typeof useFetchCourseSectionsLazyQuery
>;
export type FetchCourseSectionsQueryResult = Apollo.QueryResult<
  FetchCourseSectionsQuery,
  FetchCourseSectionsQueryVariables
>;
export function refetchFetchCourseSectionsQuery(variables?: FetchCourseSectionsQueryVariables) {
  return { query: FetchCourseSectionsDocument, variables: variables };
}
export const CourseSelectorQueryDocument = gql`
  query CourseSelectorQuery(
    $courseId: TWFilter_ID
    $sort: [CourseSortEnum]
    $studentIds: [Int]
    $subjectId: TWFilter_Int
    $termIds: [Int]
    $includeGeneral: Boolean
  ) {
    courses(
      courseId: $courseId
      sort: $sort
      studentIds: $studentIds
      subjectId: $subjectId
      termIds: $termIds
      includeGeneral: $includeGeneral
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          courseId
          label
          description
          subject {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useCourseSelectorQueryQuery__
 *
 * To run a query within a React component, call `useCourseSelectorQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSelectorQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSelectorQueryQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      sort: // value for 'sort'
 *      studentIds: // value for 'studentIds'
 *      subjectId: // value for 'subjectId'
 *      termIds: // value for 'termIds'
 *      includeGeneral: // value for 'includeGeneral'
 *   },
 * });
 */
export function useCourseSelectorQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CourseSelectorQueryQuery,
    CourseSelectorQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseSelectorQueryQuery, CourseSelectorQueryQueryVariables>(
    CourseSelectorQueryDocument,
    options,
  );
}
export function useCourseSelectorQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseSelectorQueryQuery,
    CourseSelectorQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseSelectorQueryQuery, CourseSelectorQueryQueryVariables>(
    CourseSelectorQueryDocument,
    options,
  );
}
export type CourseSelectorQueryQueryHookResult = ReturnType<typeof useCourseSelectorQueryQuery>;
export type CourseSelectorQueryLazyQueryHookResult = ReturnType<
  typeof useCourseSelectorQueryLazyQuery
>;
export type CourseSelectorQueryQueryResult = Apollo.QueryResult<
  CourseSelectorQueryQuery,
  CourseSelectorQueryQueryVariables
>;
export function refetchCourseSelectorQueryQuery(variables?: CourseSelectorQueryQueryVariables) {
  return { query: CourseSelectorQueryDocument, variables: variables };
}
export const FetchGradesDocument = gql`
  query FetchGrades {
    gradesDefault {
      totalCount
      edges {
        cursor
        node {
          id
          gradeId: pk
          label
        }
      }
    }
  }
`;

/**
 * __useFetchGradesQuery__
 *
 * To run a query within a React component, call `useFetchGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGradesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchGradesQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchGradesQuery, FetchGradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchGradesQuery, FetchGradesQueryVariables>(FetchGradesDocument, options);
}
export function useFetchGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchGradesQuery, FetchGradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchGradesQuery, FetchGradesQueryVariables>(
    FetchGradesDocument,
    options,
  );
}
export type FetchGradesQueryHookResult = ReturnType<typeof useFetchGradesQuery>;
export type FetchGradesLazyQueryHookResult = ReturnType<typeof useFetchGradesLazyQuery>;
export type FetchGradesQueryResult = Apollo.QueryResult<
  FetchGradesQuery,
  FetchGradesQueryVariables
>;
export function refetchFetchGradesQuery(variables?: FetchGradesQueryVariables) {
  return { query: FetchGradesDocument, variables: variables };
}
export const ProfessorCreateDocument = gql`
  mutation ProfessorCreate($input: ProfessorCreateInput) {
    professorCreate(input: $input) {
      aC2Professor {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type ProfessorCreateMutationFn = Apollo.MutationFunction<
  ProfessorCreateMutation,
  ProfessorCreateMutationVariables
>;

/**
 * __useProfessorCreateMutation__
 *
 * To run a mutation, you first call `useProfessorCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfessorCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [professorCreateMutation, { data, loading, error }] = useProfessorCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfessorCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfessorCreateMutation,
    ProfessorCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProfessorCreateMutation, ProfessorCreateMutationVariables>(
    ProfessorCreateDocument,
    options,
  );
}
export type ProfessorCreateMutationHookResult = ReturnType<typeof useProfessorCreateMutation>;
export type ProfessorCreateMutationResult = Apollo.MutationResult<ProfessorCreateMutation>;
export type ProfessorCreateMutationOptions = Apollo.BaseMutationOptions<
  ProfessorCreateMutation,
  ProfessorCreateMutationVariables
>;
export const FetchExternalProfessorSurveyAssignmentsDocument = gql`
  query FetchExternalProfessorSurveyAssignments(
    $uuid: String
    $status: TWFilter_ProfessorSurveyAssignmentStatus
  ) {
    externalProfessorSurveyAssignments(uuid: $uuid, status: $status) {
      totalCount
      edges {
        cursor
        node {
          professorSurveyAssignmentId: pk
          status
          externalAccessLinkId
          courseSectionId
          courseSection {
            term {
              termId
              startDate
              endDate
              label
            }
            enrollments {
              totalCount
            }
            courseSectionSchedules {
              edges {
                node {
                  startTime
                  endTime
                  day
                  location
                }
              }
            }
            professor {
              firstName
              lastName
              emailAddress
              phoneNumber
            }
            course {
              label
              description
              subject {
                label
                description
                shortCode
              }
            }
            shortCode
            startDate
            endDate
          }
          professorSurveyReports {
            totalCount
            edges {
              cursor
              node {
                professorSurveyReportId: pk
                gradeId
                isAtRisk
                comment
                absenceCount
                grade {
                  label
                }
                enrollment {
                  studentTerm {
                    student {
                      lastName
                      preferredName
                      pictureDownloadUrl
                      personId
                      fullName
                      person {
                        firstName
                        teamLabels
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchExternalProfessorSurveyAssignmentsQuery__
 *
 * To run a query within a React component, call `useFetchExternalProfessorSurveyAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExternalProfessorSurveyAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExternalProfessorSurveyAssignmentsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useFetchExternalProfessorSurveyAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchExternalProfessorSurveyAssignmentsQuery,
    FetchExternalProfessorSurveyAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchExternalProfessorSurveyAssignmentsQuery,
    FetchExternalProfessorSurveyAssignmentsQueryVariables
  >(FetchExternalProfessorSurveyAssignmentsDocument, options);
}
export function useFetchExternalProfessorSurveyAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchExternalProfessorSurveyAssignmentsQuery,
    FetchExternalProfessorSurveyAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchExternalProfessorSurveyAssignmentsQuery,
    FetchExternalProfessorSurveyAssignmentsQueryVariables
  >(FetchExternalProfessorSurveyAssignmentsDocument, options);
}
export type FetchExternalProfessorSurveyAssignmentsQueryHookResult = ReturnType<
  typeof useFetchExternalProfessorSurveyAssignmentsQuery
>;
export type FetchExternalProfessorSurveyAssignmentsLazyQueryHookResult = ReturnType<
  typeof useFetchExternalProfessorSurveyAssignmentsLazyQuery
>;
export type FetchExternalProfessorSurveyAssignmentsQueryResult = Apollo.QueryResult<
  FetchExternalProfessorSurveyAssignmentsQuery,
  FetchExternalProfessorSurveyAssignmentsQueryVariables
>;
export function refetchFetchExternalProfessorSurveyAssignmentsQuery(
  variables?: FetchExternalProfessorSurveyAssignmentsQueryVariables,
) {
  return { query: FetchExternalProfessorSurveyAssignmentsDocument, variables: variables };
}
export const FetchProfessorSurveysDocument = gql`
  query FetchProfessorSurveys(
    $professorSurveyId: TWFilter_ID
    $isClosed: TWFilter_String
    $isDeleted: TWFilter_Boolean
    $searchProfessorSurveys: String
    $sort: [ProfessorSurveySortEnum]
    $termIds: [Int]
    $after: String
    $first: Int
  ) {
    professorSurveys(
      professorSurveyId: $professorSurveyId
      isClosed: $isClosed
      isDeleted: $isDeleted
      searchProfessorSurveys: $searchProfessorSurveys
      sort: $sort
      termIds: $termIds
      after: $after
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          professorSurveyId
          isDeleted
          dueDateTime
          label
          description
          hasOnlyCompletedAssignments
          countCompletedAssignments
          isExpired
          isClosed
          countStudents
          countNotCompletedAssignments
          createdBy
          createdDate
          professorSurveyAssignments {
            totalCount
          }
        }
      }
    }
  }
`;

/**
 * __useFetchProfessorSurveysQuery__
 *
 * To run a query within a React component, call `useFetchProfessorSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfessorSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfessorSurveysQuery({
 *   variables: {
 *      professorSurveyId: // value for 'professorSurveyId'
 *      isClosed: // value for 'isClosed'
 *      isDeleted: // value for 'isDeleted'
 *      searchProfessorSurveys: // value for 'searchProfessorSurveys'
 *      sort: // value for 'sort'
 *      termIds: // value for 'termIds'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFetchProfessorSurveysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchProfessorSurveysQuery,
    FetchProfessorSurveysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchProfessorSurveysQuery, FetchProfessorSurveysQueryVariables>(
    FetchProfessorSurveysDocument,
    options,
  );
}
export function useFetchProfessorSurveysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchProfessorSurveysQuery,
    FetchProfessorSurveysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchProfessorSurveysQuery, FetchProfessorSurveysQueryVariables>(
    FetchProfessorSurveysDocument,
    options,
  );
}
export type FetchProfessorSurveysQueryHookResult = ReturnType<typeof useFetchProfessorSurveysQuery>;
export type FetchProfessorSurveysLazyQueryHookResult = ReturnType<
  typeof useFetchProfessorSurveysLazyQuery
>;
export type FetchProfessorSurveysQueryResult = Apollo.QueryResult<
  FetchProfessorSurveysQuery,
  FetchProfessorSurveysQueryVariables
>;
export function refetchFetchProfessorSurveysQuery(variables?: FetchProfessorSurveysQueryVariables) {
  return { query: FetchProfessorSurveysDocument, variables: variables };
}
export const FetchProfessorsDocument = gql`
  query FetchProfessors(
    $professorId: TWFilter_ID
    $isActive: TWFilter_Boolean
    $sort: [AC2ProfessorSortEnum]
  ) {
    professors(professorId: $professorId, isActive: $isActive, sort: $sort) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          professorId
          fullName
          emailAddress
          phoneNumber
          officeLocation
        }
      }
    }
  }
`;

/**
 * __useFetchProfessorsQuery__
 *
 * To run a query within a React component, call `useFetchProfessorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfessorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfessorsQuery({
 *   variables: {
 *      professorId: // value for 'professorId'
 *      isActive: // value for 'isActive'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchProfessorsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchProfessorsQuery, FetchProfessorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchProfessorsQuery, FetchProfessorsQueryVariables>(
    FetchProfessorsDocument,
    options,
  );
}
export function useFetchProfessorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchProfessorsQuery, FetchProfessorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchProfessorsQuery, FetchProfessorsQueryVariables>(
    FetchProfessorsDocument,
    options,
  );
}
export type FetchProfessorsQueryHookResult = ReturnType<typeof useFetchProfessorsQuery>;
export type FetchProfessorsLazyQueryHookResult = ReturnType<typeof useFetchProfessorsLazyQuery>;
export type FetchProfessorsQueryResult = Apollo.QueryResult<
  FetchProfessorsQuery,
  FetchProfessorsQueryVariables
>;
export function refetchFetchProfessorsQuery(variables?: FetchProfessorsQueryVariables) {
  return { query: FetchProfessorsDocument, variables: variables };
}
export const FetchStudentTermsDocument = gql`
  query FetchStudentTerms(
    $after: String
    $first: Int
    $searchStudents: String
    $teamIds: [Int]
    $termId: TWFilter_Int
    $studentId: TWFilter_Int
    $studentTermId: TWFilter_ID
    $advisorIds: [Int]
    $courseIds: [Int]
    $courseSectionIds: [Int]
    $isAtRisk: Boolean
    $studentYearId: TWFilter_Int
    $eligibilityYearIds: [Int]
    $checkinStartDay: String
    $checkinEndDay: String
  ) {
    studentTerms(
      after: $after
      first: $first
      searchStudents: $searchStudents
      teamIds: $teamIds
      termId: $termId
      studentId: $studentId
      studentTermId: $studentTermId
      advisorIds: $advisorIds
      courseIds: $courseIds
      courseSectionIds: $courseSectionIds
      isAtRisk: $isAtRisk
      studentYearId: $studentYearId
      eligibilityYearIds: $eligibilityYearIds
      checkinStartDay: $checkinStartDay
      checkinEndDay: $checkinEndDay
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          studentTermId: pk
          creditsAttempted
          creditsCompleted
          finalGpa
          goalGpa
          midtermGpa
          studentId
          studentYear {
            id
            label
            studentYearId
          }
          student {
            id
            studentId: pk
            preferredName
            lastName
            fullName
            teamIds
            personId
            isAtRisk
            eligibilityYears
            isDeleted
            cumulativeGpa
            academicStatus
            advisors {
              totalCount
              edges {
                cursor
                node {
                  id
                  advisorId: pk
                  personId
                  preferredName
                  lastName
                  pictureDownloadUrl
                }
              }
            }
            pictureDownloadUrl
            metaData: meta
            person {
              id
              emailAddress
              phoneCell: mobilePhone
              phoneHome
              phoneOffice
            }
            studyHallDetails {
              isCurrentlyCheckedIn
              weeklyAccumulatedMinutes
              weeklyHoursRequired
            }
          }
          termId
          term {
            id
          }
          enrollments {
            edges {
              node {
                id
                courseSection {
                  id
                  courseId
                  course {
                    id
                    credits
                  }
                }
                creditsAttempted
                droppedDate
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchStudentTermsQuery__
 *
 * To run a query within a React component, call `useFetchStudentTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStudentTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStudentTermsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      searchStudents: // value for 'searchStudents'
 *      teamIds: // value for 'teamIds'
 *      termId: // value for 'termId'
 *      studentId: // value for 'studentId'
 *      studentTermId: // value for 'studentTermId'
 *      advisorIds: // value for 'advisorIds'
 *      courseIds: // value for 'courseIds'
 *      courseSectionIds: // value for 'courseSectionIds'
 *      isAtRisk: // value for 'isAtRisk'
 *      studentYearId: // value for 'studentYearId'
 *      eligibilityYearIds: // value for 'eligibilityYearIds'
 *      checkinStartDay: // value for 'checkinStartDay'
 *      checkinEndDay: // value for 'checkinEndDay'
 *   },
 * });
 */
export function useFetchStudentTermsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchStudentTermsQuery, FetchStudentTermsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchStudentTermsQuery, FetchStudentTermsQueryVariables>(
    FetchStudentTermsDocument,
    options,
  );
}
export function useFetchStudentTermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchStudentTermsQuery,
    FetchStudentTermsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchStudentTermsQuery, FetchStudentTermsQueryVariables>(
    FetchStudentTermsDocument,
    options,
  );
}
export type FetchStudentTermsQueryHookResult = ReturnType<typeof useFetchStudentTermsQuery>;
export type FetchStudentTermsLazyQueryHookResult = ReturnType<typeof useFetchStudentTermsLazyQuery>;
export type FetchStudentTermsQueryResult = Apollo.QueryResult<
  FetchStudentTermsQuery,
  FetchStudentTermsQueryVariables
>;
export function refetchFetchStudentTermsQuery(variables?: FetchStudentTermsQueryVariables) {
  return { query: FetchStudentTermsDocument, variables: variables };
}
export const FetchStudentsDocument = gql`
  query FetchStudents(
    $isDeleted: TWFilter_Boolean
    $sort: [StudentSortEnum]
    $teamIds: TWFilter_IntArray
  ) {
    students(isDeleted: $isDeleted, sort: $sort, teamIds: $teamIds) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          studentId
          personId
          fullName
        }
      }
    }
  }
`;

/**
 * __useFetchStudentsQuery__
 *
 * To run a query within a React component, call `useFetchStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStudentsQuery({
 *   variables: {
 *      isDeleted: // value for 'isDeleted'
 *      sort: // value for 'sort'
 *      teamIds: // value for 'teamIds'
 *   },
 * });
 */
export function useFetchStudentsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchStudentsQuery, FetchStudentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchStudentsQuery, FetchStudentsQueryVariables>(
    FetchStudentsDocument,
    options,
  );
}
export function useFetchStudentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchStudentsQuery, FetchStudentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchStudentsQuery, FetchStudentsQueryVariables>(
    FetchStudentsDocument,
    options,
  );
}
export type FetchStudentsQueryHookResult = ReturnType<typeof useFetchStudentsQuery>;
export type FetchStudentsLazyQueryHookResult = ReturnType<typeof useFetchStudentsLazyQuery>;
export type FetchStudentsQueryResult = Apollo.QueryResult<
  FetchStudentsQuery,
  FetchStudentsQueryVariables
>;
export function refetchFetchStudentsQuery(variables?: FetchStudentsQueryVariables) {
  return { query: FetchStudentsDocument, variables: variables };
}
export const SubjectCreateDocument = gql`
  mutation SubjectCreate($input: SubjectCreateInput) {
    subjectCreate(input: $input) {
      subject {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type SubjectCreateMutationFn = Apollo.MutationFunction<
  SubjectCreateMutation,
  SubjectCreateMutationVariables
>;

/**
 * __useSubjectCreateMutation__
 *
 * To run a mutation, you first call `useSubjectCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubjectCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subjectCreateMutation, { data, loading, error }] = useSubjectCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubjectCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<SubjectCreateMutation, SubjectCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubjectCreateMutation, SubjectCreateMutationVariables>(
    SubjectCreateDocument,
    options,
  );
}
export type SubjectCreateMutationHookResult = ReturnType<typeof useSubjectCreateMutation>;
export type SubjectCreateMutationResult = Apollo.MutationResult<SubjectCreateMutation>;
export type SubjectCreateMutationOptions = Apollo.BaseMutationOptions<
  SubjectCreateMutation,
  SubjectCreateMutationVariables
>;
export const FetchSubjectsDocument = gql`
  query FetchSubjects(
    $subjectId: TWFilter_ID
    $includeGeneral: Boolean
    $studentTermIds: [Int]
    $sort: [SubjectSortEnum]
  ) {
    subjects(
      subjectId: $subjectId
      includeGeneral: $includeGeneral
      studentTermIds: $studentTermIds
      sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          subjectId
          isGeneral
          description
          label
        }
      }
    }
  }
`;

/**
 * __useFetchSubjectsQuery__
 *
 * To run a query within a React component, call `useFetchSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSubjectsQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *      includeGeneral: // value for 'includeGeneral'
 *      studentTermIds: // value for 'studentTermIds'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchSubjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchSubjectsQuery, FetchSubjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSubjectsQuery, FetchSubjectsQueryVariables>(
    FetchSubjectsDocument,
    options,
  );
}
export function useFetchSubjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSubjectsQuery, FetchSubjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSubjectsQuery, FetchSubjectsQueryVariables>(
    FetchSubjectsDocument,
    options,
  );
}
export type FetchSubjectsQueryHookResult = ReturnType<typeof useFetchSubjectsQuery>;
export type FetchSubjectsLazyQueryHookResult = ReturnType<typeof useFetchSubjectsLazyQuery>;
export type FetchSubjectsQueryResult = Apollo.QueryResult<
  FetchSubjectsQuery,
  FetchSubjectsQueryVariables
>;
export function refetchFetchSubjectsQuery(variables?: FetchSubjectsQueryVariables) {
  return { query: FetchSubjectsDocument, variables: variables };
}
export const AllTermsDocument = gql`
  query AllTerms($termId: TWFilter_ID, $startDate: TWFilter_String, $endDate: TWFilter_String) {
    terms(termId: $termId, startDate: $startDate, endDate: $endDate, sort: start_date_asc) {
      totalCount
      edges {
        node {
          id
          termId
          label
          startDate
          endDate
        }
      }
    }
  }
`;

/**
 * __useAllTermsQuery__
 *
 * To run a query within a React component, call `useAllTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTermsQuery({
 *   variables: {
 *      termId: // value for 'termId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAllTermsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllTermsQuery, AllTermsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllTermsQuery, AllTermsQueryVariables>(AllTermsDocument, options);
}
export function useAllTermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllTermsQuery, AllTermsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllTermsQuery, AllTermsQueryVariables>(AllTermsDocument, options);
}
export type AllTermsQueryHookResult = ReturnType<typeof useAllTermsQuery>;
export type AllTermsLazyQueryHookResult = ReturnType<typeof useAllTermsLazyQuery>;
export type AllTermsQueryResult = Apollo.QueryResult<AllTermsQuery, AllTermsQueryVariables>;
export function refetchAllTermsQuery(variables?: AllTermsQueryVariables) {
  return { query: AllTermsDocument, variables: variables };
}
export const LogoutUserDocument = gql`
  mutation LogoutUser($refreshToken: String) {
    logoutUser(refreshToken: $refreshToken) {
      redirectUrl
    }
  }
`;
export type LogoutUserMutationFn = Apollo.MutationFunction<
  LogoutUserMutation,
  LogoutUserMutationVariables
>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useLogoutUserMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(
    LogoutUserDocument,
    options,
  );
}
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<
  LogoutUserMutation,
  LogoutUserMutationVariables
>;
export const SelectablesDataDocument = gql`
  query SelectablesData($codes: [String]!, $withPeople: Boolean = true) {
    selectables(codes: $codes) {
      label
      selectionCode
      ... on SelectionGroup {
        groupType
        pluralLabel
        team {
          id
          orgId
        }
        people @include(if: $withPeople) {
          id
          personId
          firstName
          lastName
          preferredName
          displayName
          label
          selectionCode
          pictureUrl
          active
          streamUserId
          teams {
            teamId
          }
          teamMembership {
            id
            isAthlete
          }
        }
      }
      ... on PersonNode {
        personId
        orgId
        firstName
        lastName
        emailAddress
        preferredName
        displayName
        initials
        pictureUrl
        active
        streamUserId
        teams {
          teamId
        }
        teamMembership {
          id
          isAthlete
        }
      }
    }
  }
`;

/**
 * __useSelectablesDataQuery__
 *
 * To run a query within a React component, call `useSelectablesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectablesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectablesDataQuery({
 *   variables: {
 *      codes: // value for 'codes'
 *      withPeople: // value for 'withPeople'
 *   },
 * });
 */
export function useSelectablesDataQuery(
  baseOptions: Apollo.QueryHookOptions<SelectablesDataQuery, SelectablesDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelectablesDataQuery, SelectablesDataQueryVariables>(
    SelectablesDataDocument,
    options,
  );
}
export function useSelectablesDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelectablesDataQuery, SelectablesDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelectablesDataQuery, SelectablesDataQueryVariables>(
    SelectablesDataDocument,
    options,
  );
}
export type SelectablesDataQueryHookResult = ReturnType<typeof useSelectablesDataQuery>;
export type SelectablesDataLazyQueryHookResult = ReturnType<typeof useSelectablesDataLazyQuery>;
export type SelectablesDataQueryResult = Apollo.QueryResult<
  SelectablesDataQuery,
  SelectablesDataQueryVariables
>;
export function refetchSelectablesDataQuery(variables: SelectablesDataQueryVariables) {
  return { query: SelectablesDataDocument, variables: variables };
}
export const SwitchUsersCurrentTeamDocument = gql`
  mutation SwitchUsersCurrentTeam($team: ID!) {
    switchUsersCurrentTeam(team: $team) {
      redirectUrl
      currentUserData {
        personId
        teamId
        orgId
      }
    }
  }
`;
export type SwitchUsersCurrentTeamMutationFn = Apollo.MutationFunction<
  SwitchUsersCurrentTeamMutation,
  SwitchUsersCurrentTeamMutationVariables
>;

/**
 * __useSwitchUsersCurrentTeamMutation__
 *
 * To run a mutation, you first call `useSwitchUsersCurrentTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchUsersCurrentTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchUsersCurrentTeamMutation, { data, loading, error }] = useSwitchUsersCurrentTeamMutation({
 *   variables: {
 *      team: // value for 'team'
 *   },
 * });
 */
export function useSwitchUsersCurrentTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchUsersCurrentTeamMutation,
    SwitchUsersCurrentTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SwitchUsersCurrentTeamMutation,
    SwitchUsersCurrentTeamMutationVariables
  >(SwitchUsersCurrentTeamDocument, options);
}
export type SwitchUsersCurrentTeamMutationHookResult = ReturnType<
  typeof useSwitchUsersCurrentTeamMutation
>;
export type SwitchUsersCurrentTeamMutationResult =
  Apollo.MutationResult<SwitchUsersCurrentTeamMutation>;
export type SwitchUsersCurrentTeamMutationOptions = Apollo.BaseMutationOptions<
  SwitchUsersCurrentTeamMutation,
  SwitchUsersCurrentTeamMutationVariables
>;
export const FetchTeamsDocument = gql`
  query FetchTeams($teamId: TWFilter_ID, $sort: [TeamSortEnum]) {
    teams(teamId: $teamId, sort: $sort) {
      totalCount
      edges {
        node {
          teamId: pk
          id
          label
          shortLabel
        }
      }
    }
  }
`;

/**
 * __useFetchTeamsQuery__
 *
 * To run a query within a React component, call `useFetchTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTeamsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchTeamsQuery, FetchTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTeamsQuery, FetchTeamsQueryVariables>(FetchTeamsDocument, options);
}
export function useFetchTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTeamsQuery, FetchTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTeamsQuery, FetchTeamsQueryVariables>(
    FetchTeamsDocument,
    options,
  );
}
export type FetchTeamsQueryHookResult = ReturnType<typeof useFetchTeamsQuery>;
export type FetchTeamsLazyQueryHookResult = ReturnType<typeof useFetchTeamsLazyQuery>;
export type FetchTeamsQueryResult = Apollo.QueryResult<FetchTeamsQuery, FetchTeamsQueryVariables>;
export function refetchFetchTeamsQuery(variables?: FetchTeamsQueryVariables) {
  return { query: FetchTeamsDocument, variables: variables };
}
export const ProfessorSurveyAssignmentsByCourseSectionQueryDocument = gql`
  query ProfessorSurveyAssignmentsByCourseSectionQuery(
    $professorSurveyId: ID!
    $advisorIds: [ID]
    $courseIds: TWFilter_Int
    $eligibilityYearIds: [Int]
    $isAtRiskReport: Boolean
    $searchCourseSections: String
    $professorSurveyAssignmentStatus: [String]
    $teamIds: [Int]
    $termId: TWFilter_Int
    $after: String
    $first: Int
  ) {
    courseSections(
      professorSurveyId: $professorSurveyId
      advisorIds: $advisorIds
      courseId: $courseIds
      eligibilityYearIds: $eligibilityYearIds
      enableNameSort: true
      isAtRiskReport: $isAtRiskReport
      professorSurveyAssignmentStatus: $professorSurveyAssignmentStatus
      searchCourseSections: $searchCourseSections
      professorSurveyReportTeamIds: $teamIds
      termId: $termId
      after: $after
      first: $first
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          shortCode
          course {
            id
            label
            description
          }
          courseSectionSchedules {
            edges {
              node {
                id
                startTime
                endTime
                day
                location
              }
            }
          }
          professor {
            id
            fullName
          }
          enrollments {
            edges {
              node {
                id
                studentTerm {
                  id
                  student {
                    id
                    eligibilityYears
                    fullName
                    teamIds
                    advisors {
                      edges {
                        node {
                          id
                        }
                      }
                    }
                    person {
                      id
                      teamLabels
                    }
                  }
                }
                professorSurveyReports {
                  edges {
                    node {
                      id
                      absenceCount
                      comment
                      isAtRisk
                      grade {
                        id
                        label
                      }
                      professorSurveyAssignment {
                        id
                        professorSurveyId
                        status
                        completedDateTime
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useProfessorSurveyAssignmentsByCourseSectionQueryQuery__
 *
 * To run a query within a React component, call `useProfessorSurveyAssignmentsByCourseSectionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfessorSurveyAssignmentsByCourseSectionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessorSurveyAssignmentsByCourseSectionQueryQuery({
 *   variables: {
 *      professorSurveyId: // value for 'professorSurveyId'
 *      advisorIds: // value for 'advisorIds'
 *      courseIds: // value for 'courseIds'
 *      eligibilityYearIds: // value for 'eligibilityYearIds'
 *      isAtRiskReport: // value for 'isAtRiskReport'
 *      searchCourseSections: // value for 'searchCourseSections'
 *      professorSurveyAssignmentStatus: // value for 'professorSurveyAssignmentStatus'
 *      teamIds: // value for 'teamIds'
 *      termId: // value for 'termId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProfessorSurveyAssignmentsByCourseSectionQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProfessorSurveyAssignmentsByCourseSectionQueryQuery,
    ProfessorSurveyAssignmentsByCourseSectionQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfessorSurveyAssignmentsByCourseSectionQueryQuery,
    ProfessorSurveyAssignmentsByCourseSectionQueryQueryVariables
  >(ProfessorSurveyAssignmentsByCourseSectionQueryDocument, options);
}
export function useProfessorSurveyAssignmentsByCourseSectionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfessorSurveyAssignmentsByCourseSectionQueryQuery,
    ProfessorSurveyAssignmentsByCourseSectionQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfessorSurveyAssignmentsByCourseSectionQueryQuery,
    ProfessorSurveyAssignmentsByCourseSectionQueryQueryVariables
  >(ProfessorSurveyAssignmentsByCourseSectionQueryDocument, options);
}
export type ProfessorSurveyAssignmentsByCourseSectionQueryQueryHookResult = ReturnType<
  typeof useProfessorSurveyAssignmentsByCourseSectionQueryQuery
>;
export type ProfessorSurveyAssignmentsByCourseSectionQueryLazyQueryHookResult = ReturnType<
  typeof useProfessorSurveyAssignmentsByCourseSectionQueryLazyQuery
>;
export type ProfessorSurveyAssignmentsByCourseSectionQueryQueryResult = Apollo.QueryResult<
  ProfessorSurveyAssignmentsByCourseSectionQueryQuery,
  ProfessorSurveyAssignmentsByCourseSectionQueryQueryVariables
>;
export function refetchProfessorSurveyAssignmentsByCourseSectionQueryQuery(
  variables: ProfessorSurveyAssignmentsByCourseSectionQueryQueryVariables,
) {
  return { query: ProfessorSurveyAssignmentsByCourseSectionQueryDocument, variables: variables };
}
export const ProfessorSurveyAssignmentsByStudentTermQueryDocument = gql`
  query ProfessorSurveyAssignmentsByStudentTermQuery(
    $professorSurveyId: ID!
    $advisorIds: [Int]
    $courseIds: [Int]
    $eligibilityYearIds: [Int]
    $isAtRiskResults: Boolean
    $searchStudents: String
    $professorSurveyAssignmentStatus: [String]
    $teamIds: [Int]
    $termId: TWFilter_Int!
    $after: String
    $first: Int!
  ) {
    studentTerms(
      professorSurveyId: $professorSurveyId
      advisorIds: $advisorIds
      courseIds: $courseIds
      eligibilityYearIds: $eligibilityYearIds
      isAtRiskResult: $isAtRiskResults
      professorSurveyAssignmentStatus: $professorSurveyAssignmentStatus
      searchStudents: $searchStudents
      teamIds: $teamIds
      termId: $termId
      after: $after
      first: $first
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          student {
            id
            eligibilityYears
            fullName
            person {
              id
              teamLabels
            }
          }
          enrollments {
            edges {
              node {
                id
                professorSurveyReports {
                  edges {
                    node {
                      id
                      absenceCount
                      comment
                      isAtRisk
                      grade {
                        id
                        label
                      }
                      professorSurveyAssignment {
                        id
                        professorSurveyId
                        status
                      }
                    }
                  }
                }
                courseSection {
                  id
                  courseId
                  shortCode
                  course {
                    id
                    label
                    description
                  }
                  professor {
                    id
                    fullName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useProfessorSurveyAssignmentsByStudentTermQueryQuery__
 *
 * To run a query within a React component, call `useProfessorSurveyAssignmentsByStudentTermQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfessorSurveyAssignmentsByStudentTermQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessorSurveyAssignmentsByStudentTermQueryQuery({
 *   variables: {
 *      professorSurveyId: // value for 'professorSurveyId'
 *      advisorIds: // value for 'advisorIds'
 *      courseIds: // value for 'courseIds'
 *      eligibilityYearIds: // value for 'eligibilityYearIds'
 *      isAtRiskResults: // value for 'isAtRiskResults'
 *      searchStudents: // value for 'searchStudents'
 *      professorSurveyAssignmentStatus: // value for 'professorSurveyAssignmentStatus'
 *      teamIds: // value for 'teamIds'
 *      termId: // value for 'termId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProfessorSurveyAssignmentsByStudentTermQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProfessorSurveyAssignmentsByStudentTermQueryQuery,
    ProfessorSurveyAssignmentsByStudentTermQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfessorSurveyAssignmentsByStudentTermQueryQuery,
    ProfessorSurveyAssignmentsByStudentTermQueryQueryVariables
  >(ProfessorSurveyAssignmentsByStudentTermQueryDocument, options);
}
export function useProfessorSurveyAssignmentsByStudentTermQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfessorSurveyAssignmentsByStudentTermQueryQuery,
    ProfessorSurveyAssignmentsByStudentTermQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfessorSurveyAssignmentsByStudentTermQueryQuery,
    ProfessorSurveyAssignmentsByStudentTermQueryQueryVariables
  >(ProfessorSurveyAssignmentsByStudentTermQueryDocument, options);
}
export type ProfessorSurveyAssignmentsByStudentTermQueryQueryHookResult = ReturnType<
  typeof useProfessorSurveyAssignmentsByStudentTermQueryQuery
>;
export type ProfessorSurveyAssignmentsByStudentTermQueryLazyQueryHookResult = ReturnType<
  typeof useProfessorSurveyAssignmentsByStudentTermQueryLazyQuery
>;
export type ProfessorSurveyAssignmentsByStudentTermQueryQueryResult = Apollo.QueryResult<
  ProfessorSurveyAssignmentsByStudentTermQueryQuery,
  ProfessorSurveyAssignmentsByStudentTermQueryQueryVariables
>;
export function refetchProfessorSurveyAssignmentsByStudentTermQueryQuery(
  variables: ProfessorSurveyAssignmentsByStudentTermQueryQueryVariables,
) {
  return { query: ProfessorSurveyAssignmentsByStudentTermQueryDocument, variables: variables };
}
export const FetchProfessorSurveyAssignmentsDocument = gql`
  query FetchProfessorSurveyAssignments(
    $professorSurveyAssignmentId: TWFilter_ID
    $advisorId: [String]
    $completedDateTime: TWFilter_DateTime
    $courseId: [ID]
    $courseSectionId: TWFilter_Int
    $eligibilityYearId: [String]
    $isAtRisk: Boolean
    $isDeleted: TWFilter_Boolean
    $orgId: TWFilter_Int
    $professorSurveyId: TWFilter_Int
    $sort: [ProfessorSurveyAssignmentSortEnum]
    $status: TWFilter_ProfessorSurveyAssignmentStatus
    $teamId: [String]
    $createdDate: TWFilter_DateTime
    $updatedDate: TWFilter_DateTime
    $after: String
    $first: Int
  ) {
    professorSurveyAssignments(
      professorSurveyAssignmentId: $professorSurveyAssignmentId
      advisorId: $advisorId
      completedDateTime: $completedDateTime
      courseSectionId: $courseSectionId
      eligibilityYearId: $eligibilityYearId
      isAtRisk: $isAtRisk
      isDeleted: $isDeleted
      orgId: $orgId
      professorSurveyId: $professorSurveyId
      sort: $sort
      status: $status
      teamId: $teamId
      createdDate: $createdDate
      updatedDate: $updatedDate
      after: $after
      first: $first
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          professorSurveyAssignmentId: pk
          status
          externalAccessLinkId
          courseSectionId
          courseSection {
            id
            term {
              id
              termId
              startDate
              endDate
              label
            }
            enrollments {
              totalCount
            }
            courseSectionSchedules {
              edges {
                node {
                  id
                  startTime
                  endTime
                  day
                  location
                }
              }
            }
            professor {
              id
              firstName
              lastName
              emailAddress
              phoneNumber
            }
            course {
              id
              courseId
              label
              description
              subject {
                id
                label
                description
                shortCode
              }
            }
            shortCode
            startDate
            endDate
          }
          professorSurveyReports {
            totalCount
            edges {
              cursor
              node {
                id
                professorSurveyReportId: pk
                gradeId
                isAtRisk
                comment
                absenceCount
                grade {
                  id
                  label
                }
                enrollment {
                  id
                  studentTerm {
                    id
                    student {
                      id
                      lastName
                      preferredName
                      pictureDownloadUrl
                      eligibilityYear
                      personId
                      fullName
                      teamIds
                      person {
                        id
                        firstName
                        teamLabels
                      }
                      advisors {
                        edges {
                          node {
                            id
                            advisorId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchProfessorSurveyAssignmentsQuery__
 *
 * To run a query within a React component, call `useFetchProfessorSurveyAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfessorSurveyAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfessorSurveyAssignmentsQuery({
 *   variables: {
 *      professorSurveyAssignmentId: // value for 'professorSurveyAssignmentId'
 *      advisorId: // value for 'advisorId'
 *      completedDateTime: // value for 'completedDateTime'
 *      courseId: // value for 'courseId'
 *      courseSectionId: // value for 'courseSectionId'
 *      eligibilityYearId: // value for 'eligibilityYearId'
 *      isAtRisk: // value for 'isAtRisk'
 *      isDeleted: // value for 'isDeleted'
 *      orgId: // value for 'orgId'
 *      professorSurveyId: // value for 'professorSurveyId'
 *      sort: // value for 'sort'
 *      status: // value for 'status'
 *      teamId: // value for 'teamId'
 *      createdDate: // value for 'createdDate'
 *      updatedDate: // value for 'updatedDate'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFetchProfessorSurveyAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchProfessorSurveyAssignmentsQuery,
    FetchProfessorSurveyAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchProfessorSurveyAssignmentsQuery,
    FetchProfessorSurveyAssignmentsQueryVariables
  >(FetchProfessorSurveyAssignmentsDocument, options);
}
export function useFetchProfessorSurveyAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchProfessorSurveyAssignmentsQuery,
    FetchProfessorSurveyAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchProfessorSurveyAssignmentsQuery,
    FetchProfessorSurveyAssignmentsQueryVariables
  >(FetchProfessorSurveyAssignmentsDocument, options);
}
export type FetchProfessorSurveyAssignmentsQueryHookResult = ReturnType<
  typeof useFetchProfessorSurveyAssignmentsQuery
>;
export type FetchProfessorSurveyAssignmentsLazyQueryHookResult = ReturnType<
  typeof useFetchProfessorSurveyAssignmentsLazyQuery
>;
export type FetchProfessorSurveyAssignmentsQueryResult = Apollo.QueryResult<
  FetchProfessorSurveyAssignmentsQuery,
  FetchProfessorSurveyAssignmentsQueryVariables
>;
export function refetchFetchProfessorSurveyAssignmentsQuery(
  variables?: FetchProfessorSurveyAssignmentsQueryVariables,
) {
  return { query: FetchProfessorSurveyAssignmentsDocument, variables: variables };
}
export const ProfessorSurveyCreateDocument = gql`
  mutation ProfessorSurveyCreate($input: ProfessorSurveyCreateInput!) {
    professorSurveyCreate(input: $input) {
      professorSurvey {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type ProfessorSurveyCreateMutationFn = Apollo.MutationFunction<
  ProfessorSurveyCreateMutation,
  ProfessorSurveyCreateMutationVariables
>;

/**
 * __useProfessorSurveyCreateMutation__
 *
 * To run a mutation, you first call `useProfessorSurveyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfessorSurveyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [professorSurveyCreateMutation, { data, loading, error }] = useProfessorSurveyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfessorSurveyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfessorSurveyCreateMutation,
    ProfessorSurveyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProfessorSurveyCreateMutation, ProfessorSurveyCreateMutationVariables>(
    ProfessorSurveyCreateDocument,
    options,
  );
}
export type ProfessorSurveyCreateMutationHookResult = ReturnType<
  typeof useProfessorSurveyCreateMutation
>;
export type ProfessorSurveyCreateMutationResult =
  Apollo.MutationResult<ProfessorSurveyCreateMutation>;
export type ProfessorSurveyCreateMutationOptions = Apollo.BaseMutationOptions<
  ProfessorSurveyCreateMutation,
  ProfessorSurveyCreateMutationVariables
>;
export const FetchTutorSessionsDocument = gql`
  query FetchTutorSessions(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $advisorIds: [Int]
    $courseIds: [Int]
    $isAtRisk: Boolean
    $isDeleted: TWFilter_Boolean
    $occurrenceStartDateTime: TWFilter_DateTime
    $reportStatus: TWFilter_String
    $searchSessions: String
    $sessionId: TWFilter_ID
    $sort: [SessionSortEnum]
    $teamIds: [Int]
    $termId: TWFilter_Int
    $tutorId: TWFilter_Int
  ) {
    sessions(
      after: $after
      before: $before
      first: $first
      last: $last
      advisorIds: $advisorIds
      courseIds: $courseIds
      isAtRisk: $isAtRisk
      isDeleted: $isDeleted
      occurrenceStartDateTime: $occurrenceStartDateTime
      reportStatus: $reportStatus
      searchSessions: $searchSessions
      sessionId: $sessionId
      sort: $sort
      teamIds: $teamIds
      termId: $termId
      tutorId: $tutorId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          sessionId
          id
          startDateTime: occurrenceStartDateTime
          endDateTime: occurrenceEndDateTime
          location: occurrenceLocation
          reportAnswers
          studentIds
          appointmentOccurrenceId
          appointmentOccurrence {
            id
          }
          courseId
          courseSectionId
          course {
            id
            label
          }
          subjectId
          students {
            totalCount
            edges {
              cursor
              node {
                id
                studentId: pk
                preferredName
                lastName
                fullName
                teamIds
                personId
                isAtRisk
                isDeleted
                cumulativeGpa
                academicStatus
                advisors {
                  totalCount
                  edges {
                    cursor
                    node {
                      id
                      advisorId: pk
                      personId
                      preferredName
                      lastName
                      pictureDownloadUrl
                    }
                  }
                }
                pictureDownloadUrl
                metaData: meta
                eligibilityYears
              }
            }
          }
          tutor {
            id
            tutorId: pk
            preferredName
            lastName
            fullName
            personId
            maximumNumberOfHours
            subjects {
              totalCount
              edges {
                cursor
                node {
                  id
                  subjectId: pk
                  label
                  description
                  shortCode
                  customerKey
                }
              }
            }
            pictureDownloadUrl
            isActive
            person {
              id
              teams {
                teamId: pk
              }
            }
          }
          sessionAttendances {
            totalCount
            edges {
              cursor
              node {
                id
                sessionAttendanceId
                sessionId
                studentId
                student {
                  id
                  preferredName
                  lastName
                  teamIds
                  studentId
                  fullName
                  personId
                  pictureDownloadUrl
                }
                status
                meta
              }
            }
          }
          creator {
            personId
            preferredName
            lastName
            fullName
          }
          scheduledOn: createdDate
          isCanceled
          reportStatus
          termId
        }
      }
    }
  }
`;

/**
 * __useFetchTutorSessionsQuery__
 *
 * To run a query within a React component, call `useFetchTutorSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTutorSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTutorSessionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      advisorIds: // value for 'advisorIds'
 *      courseIds: // value for 'courseIds'
 *      isAtRisk: // value for 'isAtRisk'
 *      isDeleted: // value for 'isDeleted'
 *      occurrenceStartDateTime: // value for 'occurrenceStartDateTime'
 *      reportStatus: // value for 'reportStatus'
 *      searchSessions: // value for 'searchSessions'
 *      sessionId: // value for 'sessionId'
 *      sort: // value for 'sort'
 *      teamIds: // value for 'teamIds'
 *      termId: // value for 'termId'
 *      tutorId: // value for 'tutorId'
 *   },
 * });
 */
export function useFetchTutorSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchTutorSessionsQuery, FetchTutorSessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTutorSessionsQuery, FetchTutorSessionsQueryVariables>(
    FetchTutorSessionsDocument,
    options,
  );
}
export function useFetchTutorSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTutorSessionsQuery,
    FetchTutorSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTutorSessionsQuery, FetchTutorSessionsQueryVariables>(
    FetchTutorSessionsDocument,
    options,
  );
}
export type FetchTutorSessionsQueryHookResult = ReturnType<typeof useFetchTutorSessionsQuery>;
export type FetchTutorSessionsLazyQueryHookResult = ReturnType<
  typeof useFetchTutorSessionsLazyQuery
>;
export type FetchTutorSessionsQueryResult = Apollo.QueryResult<
  FetchTutorSessionsQuery,
  FetchTutorSessionsQueryVariables
>;
export function refetchFetchTutorSessionsQuery(variables?: FetchTutorSessionsQueryVariables) {
  return { query: FetchTutorSessionsDocument, variables: variables };
}
export const StudentTermBulkSelectEnrollmentsDocument = gql`
  query StudentTermBulkSelectEnrollments(
    $searchStudents: String
    $teamIds: [Int]
    $termId: TWFilter_Int
    $studentId: TWFilter_Int
    $studentTermId: TWFilter_ID
    $advisorIds: [Int]
    $courseIds: [Int]
    $courseSectionIds: [Int]
    $isAtRisk: Boolean
    $studentYearId: TWFilter_Int
    $eligibilityYearIds: [Int]
  ) {
    studentTerms(
      searchStudents: $searchStudents
      teamIds: $teamIds
      termId: $termId
      studentId: $studentId
      studentTermId: $studentTermId
      advisorIds: $advisorIds
      courseIds: $courseIds
      courseSectionIds: $courseSectionIds
      isAtRisk: $isAtRisk
      studentYearId: $studentYearId
      eligibilityYearIds: $eligibilityYearIds
      isDeleted: "eq:false"
    ) {
      edges {
        node {
          id
          enrollments {
            edges {
              node {
                id
                courseSection {
                  id
                  courseId
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useStudentTermBulkSelectEnrollmentsQuery__
 *
 * To run a query within a React component, call `useStudentTermBulkSelectEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentTermBulkSelectEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentTermBulkSelectEnrollmentsQuery({
 *   variables: {
 *      searchStudents: // value for 'searchStudents'
 *      teamIds: // value for 'teamIds'
 *      termId: // value for 'termId'
 *      studentId: // value for 'studentId'
 *      studentTermId: // value for 'studentTermId'
 *      advisorIds: // value for 'advisorIds'
 *      courseIds: // value for 'courseIds'
 *      courseSectionIds: // value for 'courseSectionIds'
 *      isAtRisk: // value for 'isAtRisk'
 *      studentYearId: // value for 'studentYearId'
 *      eligibilityYearIds: // value for 'eligibilityYearIds'
 *   },
 * });
 */
export function useStudentTermBulkSelectEnrollmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StudentTermBulkSelectEnrollmentsQuery,
    StudentTermBulkSelectEnrollmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StudentTermBulkSelectEnrollmentsQuery,
    StudentTermBulkSelectEnrollmentsQueryVariables
  >(StudentTermBulkSelectEnrollmentsDocument, options);
}
export function useStudentTermBulkSelectEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudentTermBulkSelectEnrollmentsQuery,
    StudentTermBulkSelectEnrollmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StudentTermBulkSelectEnrollmentsQuery,
    StudentTermBulkSelectEnrollmentsQueryVariables
  >(StudentTermBulkSelectEnrollmentsDocument, options);
}
export type StudentTermBulkSelectEnrollmentsQueryHookResult = ReturnType<
  typeof useStudentTermBulkSelectEnrollmentsQuery
>;
export type StudentTermBulkSelectEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useStudentTermBulkSelectEnrollmentsLazyQuery
>;
export type StudentTermBulkSelectEnrollmentsQueryResult = Apollo.QueryResult<
  StudentTermBulkSelectEnrollmentsQuery,
  StudentTermBulkSelectEnrollmentsQueryVariables
>;
export function refetchStudentTermBulkSelectEnrollmentsQuery(
  variables?: StudentTermBulkSelectEnrollmentsQueryVariables,
) {
  return { query: StudentTermBulkSelectEnrollmentsDocument, variables: variables };
}
export const StudentTermBulkSelectionDocument = gql`
  query StudentTermBulkSelection(
    $searchStudents: String
    $teamIds: [Int]
    $termId: TWFilter_Int
    $studentId: TWFilter_Int
    $studentTermId: TWFilter_ID
    $advisorIds: [Int]
    $courseIds: [Int]
    $courseSectionIds: [Int]
    $isAtRisk: Boolean
    $studentYearId: TWFilter_Int
    $eligibilityYearIds: [Int]
  ) {
    studentTerms(
      searchStudents: $searchStudents
      teamIds: $teamIds
      termId: $termId
      studentId: $studentId
      studentTermId: $studentTermId
      advisorIds: $advisorIds
      courseIds: $courseIds
      courseSectionIds: $courseSectionIds
      isAtRisk: $isAtRisk
      studentYearId: $studentYearId
      eligibilityYearIds: $eligibilityYearIds
      isDeleted: "eq:false"
    ) {
      edges {
        node {
          id
          studentId
          student {
            id
            fullName
            personId
            teamIds
          }
          term {
            startDate
            endDate
          }
        }
      }
    }
  }
`;

/**
 * __useStudentTermBulkSelectionQuery__
 *
 * To run a query within a React component, call `useStudentTermBulkSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentTermBulkSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentTermBulkSelectionQuery({
 *   variables: {
 *      searchStudents: // value for 'searchStudents'
 *      teamIds: // value for 'teamIds'
 *      termId: // value for 'termId'
 *      studentId: // value for 'studentId'
 *      studentTermId: // value for 'studentTermId'
 *      advisorIds: // value for 'advisorIds'
 *      courseIds: // value for 'courseIds'
 *      courseSectionIds: // value for 'courseSectionIds'
 *      isAtRisk: // value for 'isAtRisk'
 *      studentYearId: // value for 'studentYearId'
 *      eligibilityYearIds: // value for 'eligibilityYearIds'
 *   },
 * });
 */
export function useStudentTermBulkSelectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StudentTermBulkSelectionQuery,
    StudentTermBulkSelectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StudentTermBulkSelectionQuery, StudentTermBulkSelectionQueryVariables>(
    StudentTermBulkSelectionDocument,
    options,
  );
}
export function useStudentTermBulkSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudentTermBulkSelectionQuery,
    StudentTermBulkSelectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StudentTermBulkSelectionQuery, StudentTermBulkSelectionQueryVariables>(
    StudentTermBulkSelectionDocument,
    options,
  );
}
export type StudentTermBulkSelectionQueryHookResult = ReturnType<
  typeof useStudentTermBulkSelectionQuery
>;
export type StudentTermBulkSelectionLazyQueryHookResult = ReturnType<
  typeof useStudentTermBulkSelectionLazyQuery
>;
export type StudentTermBulkSelectionQueryResult = Apollo.QueryResult<
  StudentTermBulkSelectionQuery,
  StudentTermBulkSelectionQueryVariables
>;
export function refetchStudentTermBulkSelectionQuery(
  variables?: StudentTermBulkSelectionQueryVariables,
) {
  return { query: StudentTermBulkSelectionDocument, variables: variables };
}
export const BulkStudentUpdateDocument = gql`
  mutation BulkStudentUpdate($input: StudentInput!) {
    bulkStudentUpdate(input: $input) {
      id
      studentId
    }
  }
`;
export type BulkStudentUpdateMutationFn = Apollo.MutationFunction<
  BulkStudentUpdateMutation,
  BulkStudentUpdateMutationVariables
>;

/**
 * __useBulkStudentUpdateMutation__
 *
 * To run a mutation, you first call `useBulkStudentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkStudentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkStudentUpdateMutation, { data, loading, error }] = useBulkStudentUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkStudentUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkStudentUpdateMutation,
    BulkStudentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkStudentUpdateMutation, BulkStudentUpdateMutationVariables>(
    BulkStudentUpdateDocument,
    options,
  );
}
export type BulkStudentUpdateMutationHookResult = ReturnType<typeof useBulkStudentUpdateMutation>;
export type BulkStudentUpdateMutationResult = Apollo.MutationResult<BulkStudentUpdateMutation>;
export type BulkStudentUpdateMutationOptions = Apollo.BaseMutationOptions<
  BulkStudentUpdateMutation,
  BulkStudentUpdateMutationVariables
>;
export const StudyHallActionDeleteDocument = gql`
  mutation StudyHallActionDelete($id: ID) {
    studyHallActionDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type StudyHallActionDeleteMutationFn = Apollo.MutationFunction<
  StudyHallActionDeleteMutation,
  StudyHallActionDeleteMutationVariables
>;

/**
 * __useStudyHallActionDeleteMutation__
 *
 * To run a mutation, you first call `useStudyHallActionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyHallActionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyHallActionDeleteMutation, { data, loading, error }] = useStudyHallActionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyHallActionDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StudyHallActionDeleteMutation,
    StudyHallActionDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StudyHallActionDeleteMutation, StudyHallActionDeleteMutationVariables>(
    StudyHallActionDeleteDocument,
    options,
  );
}
export type StudyHallActionDeleteMutationHookResult = ReturnType<
  typeof useStudyHallActionDeleteMutation
>;
export type StudyHallActionDeleteMutationResult =
  Apollo.MutationResult<StudyHallActionDeleteMutation>;
export type StudyHallActionDeleteMutationOptions = Apollo.BaseMutationOptions<
  StudyHallActionDeleteMutation,
  StudyHallActionDeleteMutationVariables
>;
export const UpdateStudyHallActionDocument = gql`
  mutation UpdateStudyHallAction($id: ID, $input: StudyHallActionUpdateInput) {
    studyHallActionUpdate(id: $id, input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type UpdateStudyHallActionMutationFn = Apollo.MutationFunction<
  UpdateStudyHallActionMutation,
  UpdateStudyHallActionMutationVariables
>;

/**
 * __useUpdateStudyHallActionMutation__
 *
 * To run a mutation, you first call `useUpdateStudyHallActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudyHallActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudyHallActionMutation, { data, loading, error }] = useUpdateStudyHallActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudyHallActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudyHallActionMutation,
    UpdateStudyHallActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStudyHallActionMutation, UpdateStudyHallActionMutationVariables>(
    UpdateStudyHallActionDocument,
    options,
  );
}
export type UpdateStudyHallActionMutationHookResult = ReturnType<
  typeof useUpdateStudyHallActionMutation
>;
export type UpdateStudyHallActionMutationResult =
  Apollo.MutationResult<UpdateStudyHallActionMutation>;
export type UpdateStudyHallActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudyHallActionMutation,
  UpdateStudyHallActionMutationVariables
>;
export const FetchStudyHallActionsDocument = gql`
  query FetchStudyHallActions(
    $studyHallActionId: TWFilter_ID
    $checkinDateTime: TWFilter_DateTime
    $personId: TWFilter_Int
    $sort: [StudyHallActionSortEnum]
  ) {
    studyHallActions(
      studyHallActionId: $studyHallActionId
      checkinDateTime: $checkinDateTime
      personId: $personId
      sort: $sort
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          studyHallActionId
          id
          studyHall {
            id
            studyHallId
            label
            endDatetimeUtc
          }
          checkinDateTime
          checkoutDateTime
          accumulatedMinutes
          autoCheckedOut
          notes {
            checkinNote {
              note
              timestamp
              personId
              person {
                id
                fullNameNormalOrder
                initials
                pictureUrl
              }
            }
            checkoutNote {
              note
              timestamp
              personId
              person {
                id
                fullNameNormalOrder
                initials
                pictureUrl
              }
            }
            editNotes {
              note
              timestamp
              personId
              person {
                id
                fullNameNormalOrder
                initials
                pictureUrl
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchStudyHallActionsQuery__
 *
 * To run a query within a React component, call `useFetchStudyHallActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStudyHallActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStudyHallActionsQuery({
 *   variables: {
 *      studyHallActionId: // value for 'studyHallActionId'
 *      checkinDateTime: // value for 'checkinDateTime'
 *      personId: // value for 'personId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchStudyHallActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchStudyHallActionsQuery,
    FetchStudyHallActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchStudyHallActionsQuery, FetchStudyHallActionsQueryVariables>(
    FetchStudyHallActionsDocument,
    options,
  );
}
export function useFetchStudyHallActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchStudyHallActionsQuery,
    FetchStudyHallActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchStudyHallActionsQuery, FetchStudyHallActionsQueryVariables>(
    FetchStudyHallActionsDocument,
    options,
  );
}
export type FetchStudyHallActionsQueryHookResult = ReturnType<typeof useFetchStudyHallActionsQuery>;
export type FetchStudyHallActionsLazyQueryHookResult = ReturnType<
  typeof useFetchStudyHallActionsLazyQuery
>;
export type FetchStudyHallActionsQueryResult = Apollo.QueryResult<
  FetchStudyHallActionsQuery,
  FetchStudyHallActionsQueryVariables
>;
export function refetchFetchStudyHallActionsQuery(variables?: FetchStudyHallActionsQueryVariables) {
  return { query: FetchStudyHallActionsDocument, variables: variables };
}
export const StudyHallActionBulkCreateDocument = gql`
  mutation StudyHallActionBulkCreate($input: StudyHallActionBulkCreateInput!) {
    studyHallActionBulkCreate(input: $input) {
      studyHallAction {
        id
      }
    }
  }
`;
export type StudyHallActionBulkCreateMutationFn = Apollo.MutationFunction<
  StudyHallActionBulkCreateMutation,
  StudyHallActionBulkCreateMutationVariables
>;

/**
 * __useStudyHallActionBulkCreateMutation__
 *
 * To run a mutation, you first call `useStudyHallActionBulkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyHallActionBulkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyHallActionBulkCreateMutation, { data, loading, error }] = useStudyHallActionBulkCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyHallActionBulkCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StudyHallActionBulkCreateMutation,
    StudyHallActionBulkCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StudyHallActionBulkCreateMutation,
    StudyHallActionBulkCreateMutationVariables
  >(StudyHallActionBulkCreateDocument, options);
}
export type StudyHallActionBulkCreateMutationHookResult = ReturnType<
  typeof useStudyHallActionBulkCreateMutation
>;
export type StudyHallActionBulkCreateMutationResult =
  Apollo.MutationResult<StudyHallActionBulkCreateMutation>;
export type StudyHallActionBulkCreateMutationOptions = Apollo.BaseMutationOptions<
  StudyHallActionBulkCreateMutation,
  StudyHallActionBulkCreateMutationVariables
>;
export const StudyHallRequirementBulkCreateOrUpdateDocument = gql`
  mutation StudyHallRequirementBulkCreateOrUpdate(
    $input: StudyHallRequirementBulkCreateOrUpdateInput!
  ) {
    studyHallRequirementBulkCreateOrUpdate(input: $input) {
      studyHallRequirement {
        id
      }
    }
  }
`;
export type StudyHallRequirementBulkCreateOrUpdateMutationFn = Apollo.MutationFunction<
  StudyHallRequirementBulkCreateOrUpdateMutation,
  StudyHallRequirementBulkCreateOrUpdateMutationVariables
>;

/**
 * __useStudyHallRequirementBulkCreateOrUpdateMutation__
 *
 * To run a mutation, you first call `useStudyHallRequirementBulkCreateOrUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyHallRequirementBulkCreateOrUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyHallRequirementBulkCreateOrUpdateMutation, { data, loading, error }] = useStudyHallRequirementBulkCreateOrUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyHallRequirementBulkCreateOrUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StudyHallRequirementBulkCreateOrUpdateMutation,
    StudyHallRequirementBulkCreateOrUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StudyHallRequirementBulkCreateOrUpdateMutation,
    StudyHallRequirementBulkCreateOrUpdateMutationVariables
  >(StudyHallRequirementBulkCreateOrUpdateDocument, options);
}
export type StudyHallRequirementBulkCreateOrUpdateMutationHookResult = ReturnType<
  typeof useStudyHallRequirementBulkCreateOrUpdateMutation
>;
export type StudyHallRequirementBulkCreateOrUpdateMutationResult =
  Apollo.MutationResult<StudyHallRequirementBulkCreateOrUpdateMutation>;
export type StudyHallRequirementBulkCreateOrUpdateMutationOptions = Apollo.BaseMutationOptions<
  StudyHallRequirementBulkCreateOrUpdateMutation,
  StudyHallRequirementBulkCreateOrUpdateMutationVariables
>;
export const FetchStudyHallDetailDocument = gql`
  query FetchStudyHallDetail($studyHallId: TWFilter_ID!) {
    studyHalls(studyHallId: $studyHallId) {
      edges {
        cursor
        node {
          id
          studyHallId
          numOpenCheckins
        }
      }
    }
  }
`;

/**
 * __useFetchStudyHallDetailQuery__
 *
 * To run a query within a React component, call `useFetchStudyHallDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStudyHallDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStudyHallDetailQuery({
 *   variables: {
 *      studyHallId: // value for 'studyHallId'
 *   },
 * });
 */
export function useFetchStudyHallDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchStudyHallDetailQuery,
    FetchStudyHallDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchStudyHallDetailQuery, FetchStudyHallDetailQueryVariables>(
    FetchStudyHallDetailDocument,
    options,
  );
}
export function useFetchStudyHallDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchStudyHallDetailQuery,
    FetchStudyHallDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchStudyHallDetailQuery, FetchStudyHallDetailQueryVariables>(
    FetchStudyHallDetailDocument,
    options,
  );
}
export type FetchStudyHallDetailQueryHookResult = ReturnType<typeof useFetchStudyHallDetailQuery>;
export type FetchStudyHallDetailLazyQueryHookResult = ReturnType<
  typeof useFetchStudyHallDetailLazyQuery
>;
export type FetchStudyHallDetailQueryResult = Apollo.QueryResult<
  FetchStudyHallDetailQuery,
  FetchStudyHallDetailQueryVariables
>;
export function refetchFetchStudyHallDetailQuery(variables: FetchStudyHallDetailQueryVariables) {
  return { query: FetchStudyHallDetailDocument, variables: variables };
}
export const SessionBulkCancelDocument = gql`
  mutation SessionBulkCancel($input: SessionBulkCancelInput) {
    sessionBulkCancel(input: $input) {
      session {
        sessionId
        reportStatus
        sessionAttendances {
          edges {
            node {
              studentId
              status
              meta
            }
          }
        }
      }
    }
  }
`;
export type SessionBulkCancelMutationFn = Apollo.MutationFunction<
  SessionBulkCancelMutation,
  SessionBulkCancelMutationVariables
>;

/**
 * __useSessionBulkCancelMutation__
 *
 * To run a mutation, you first call `useSessionBulkCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSessionBulkCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sessionBulkCancelMutation, { data, loading, error }] = useSessionBulkCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSessionBulkCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SessionBulkCancelMutation,
    SessionBulkCancelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SessionBulkCancelMutation, SessionBulkCancelMutationVariables>(
    SessionBulkCancelDocument,
    options,
  );
}
export type SessionBulkCancelMutationHookResult = ReturnType<typeof useSessionBulkCancelMutation>;
export type SessionBulkCancelMutationResult = Apollo.MutationResult<SessionBulkCancelMutation>;
export type SessionBulkCancelMutationOptions = Apollo.BaseMutationOptions<
  SessionBulkCancelMutation,
  SessionBulkCancelMutationVariables
>;
export const UpcomingSessionsDocument = gql`
  query UpcomingSessions(
    $searchSessions: String
    $advisorIds: [Int]
    $courseIds: [Int]
    $isAtRisk: Boolean
    $teamIds: [Int]
    $termId: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    upcomingSessions(
      filters: {
        searchSessions: $searchSessions
        advisorIds: $advisorIds
        courseIds: $courseIds
        isAtRisk: $isAtRisk
        teamIds: $teamIds
        termId: $termId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      appointmentOccId
      course
      courseId
      location
      startDateTime
      students {
        pk
        fullName
        personId
        pictureUrl
        teamIds
      }
      studentIds
      termId
      tutor
      tutorId
      tutorPersonId
    }
  }
`;

/**
 * __useUpcomingSessionsQuery__
 *
 * To run a query within a React component, call `useUpcomingSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingSessionsQuery({
 *   variables: {
 *      searchSessions: // value for 'searchSessions'
 *      advisorIds: // value for 'advisorIds'
 *      courseIds: // value for 'courseIds'
 *      isAtRisk: // value for 'isAtRisk'
 *      teamIds: // value for 'teamIds'
 *      termId: // value for 'termId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpcomingSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<UpcomingSessionsQuery, UpcomingSessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpcomingSessionsQuery, UpcomingSessionsQueryVariables>(
    UpcomingSessionsDocument,
    options,
  );
}
export function useUpcomingSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UpcomingSessionsQuery, UpcomingSessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpcomingSessionsQuery, UpcomingSessionsQueryVariables>(
    UpcomingSessionsDocument,
    options,
  );
}
export type UpcomingSessionsQueryHookResult = ReturnType<typeof useUpcomingSessionsQuery>;
export type UpcomingSessionsLazyQueryHookResult = ReturnType<typeof useUpcomingSessionsLazyQuery>;
export type UpcomingSessionsQueryResult = Apollo.QueryResult<
  UpcomingSessionsQuery,
  UpcomingSessionsQueryVariables
>;
export function refetchUpcomingSessionsQuery(variables?: UpcomingSessionsQueryVariables) {
  return { query: UpcomingSessionsDocument, variables: variables };
}
export const SessionRequestListQueryDocument = gql`
  query SessionRequestListQuery(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $pk: TWFilter_String
    $advisorIds: [Int]
    $courseIds: [Int]
    $courseSectionId: TWFilter_Int
    $createdDateTime: TWFilter_String
    $isAtRisk: Boolean
    $searchSessionRequests: String
    $sort: [SessionRequestSortEnum]
    $status: TWFilter_SessionRequestStatus
    $subjectId: TWFilter_Int
    $teamIds: [Int]
    $termId: TWFilter_Int
  ) {
    sessionRequests(
      after: $after
      before: $before
      first: $first
      last: $last
      pk: $pk
      advisorIds: $advisorIds
      courseIds: $courseIds
      courseSectionId: $courseSectionId
      createdDateTime: $createdDateTime
      isAtRisk: $isAtRisk
      searchSessionRequests: $searchSessionRequests
      sort: $sort
      status: $status
      subjectId: $subjectId
      teamIds: $teamIds
      termId: $termId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          tutorSessionRequestId: pk
          courseId
          courseSectionId
          students {
            edges {
              node {
                id
                studentId
                personId
                fullName
                teamIds
                picture: pictureDownloadUrl
              }
            }
          }
          termId
          term {
            id
            termId
            startDate
            endDate
          }
          course {
            id
            courseId: pk
            label
          }
          notes
          status
          createdBy
          createdDateTime
          creator {
            personId
            fullName
            initials
            picture: pictureUrl
          }
        }
      }
    }
  }
`;

/**
 * __useSessionRequestListQueryQuery__
 *
 * To run a query within a React component, call `useSessionRequestListQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionRequestListQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionRequestListQueryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      pk: // value for 'pk'
 *      advisorIds: // value for 'advisorIds'
 *      courseIds: // value for 'courseIds'
 *      courseSectionId: // value for 'courseSectionId'
 *      createdDateTime: // value for 'createdDateTime'
 *      isAtRisk: // value for 'isAtRisk'
 *      searchSessionRequests: // value for 'searchSessionRequests'
 *      sort: // value for 'sort'
 *      status: // value for 'status'
 *      subjectId: // value for 'subjectId'
 *      teamIds: // value for 'teamIds'
 *      termId: // value for 'termId'
 *   },
 * });
 */
export function useSessionRequestListQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SessionRequestListQueryQuery,
    SessionRequestListQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionRequestListQueryQuery, SessionRequestListQueryQueryVariables>(
    SessionRequestListQueryDocument,
    options,
  );
}
export function useSessionRequestListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionRequestListQueryQuery,
    SessionRequestListQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionRequestListQueryQuery, SessionRequestListQueryQueryVariables>(
    SessionRequestListQueryDocument,
    options,
  );
}
export type SessionRequestListQueryQueryHookResult = ReturnType<
  typeof useSessionRequestListQueryQuery
>;
export type SessionRequestListQueryLazyQueryHookResult = ReturnType<
  typeof useSessionRequestListQueryLazyQuery
>;
export type SessionRequestListQueryQueryResult = Apollo.QueryResult<
  SessionRequestListQueryQuery,
  SessionRequestListQueryQueryVariables
>;
export function refetchSessionRequestListQueryQuery(
  variables?: SessionRequestListQueryQueryVariables,
) {
  return { query: SessionRequestListQueryDocument, variables: variables };
}
export const CourseSectionUpdateDocument = gql`
  mutation CourseSectionUpdate($id: ID!, $input: CourseSectionUpdateInput!) {
    courseSectionUpdate(id: $id, input: $input) {
      courseSection {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CourseSectionUpdateMutationFn = Apollo.MutationFunction<
  CourseSectionUpdateMutation,
  CourseSectionUpdateMutationVariables
>;

/**
 * __useCourseSectionUpdateMutation__
 *
 * To run a mutation, you first call `useCourseSectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSectionUpdateMutation, { data, loading, error }] = useCourseSectionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseSectionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseSectionUpdateMutation,
    CourseSectionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CourseSectionUpdateMutation, CourseSectionUpdateMutationVariables>(
    CourseSectionUpdateDocument,
    options,
  );
}
export type CourseSectionUpdateMutationHookResult = ReturnType<
  typeof useCourseSectionUpdateMutation
>;
export type CourseSectionUpdateMutationResult = Apollo.MutationResult<CourseSectionUpdateMutation>;
export type CourseSectionUpdateMutationOptions = Apollo.BaseMutationOptions<
  CourseSectionUpdateMutation,
  CourseSectionUpdateMutationVariables
>;
export const CourseUpdateDocument = gql`
  mutation CourseUpdate($id: ID!, $input: CourseUpdateInput!) {
    courseUpdate(id: $id, input: $input) {
      course {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CourseUpdateMutationFn = Apollo.MutationFunction<
  CourseUpdateMutation,
  CourseUpdateMutationVariables
>;

/**
 * __useCourseUpdateMutation__
 *
 * To run a mutation, you first call `useCourseUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseUpdateMutation, { data, loading, error }] = useCourseUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<CourseUpdateMutation, CourseUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CourseUpdateMutation, CourseUpdateMutationVariables>(
    CourseUpdateDocument,
    options,
  );
}
export type CourseUpdateMutationHookResult = ReturnType<typeof useCourseUpdateMutation>;
export type CourseUpdateMutationResult = Apollo.MutationResult<CourseUpdateMutation>;
export type CourseUpdateMutationOptions = Apollo.BaseMutationOptions<
  CourseUpdateMutation,
  CourseUpdateMutationVariables
>;
export const ProfessorUpdateDocument = gql`
  mutation ProfessorUpdate($id: ID!, $input: ProfessorUpdateInput!) {
    professorUpdate(id: $id, input: $input) {
      aC2Professor {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type ProfessorUpdateMutationFn = Apollo.MutationFunction<
  ProfessorUpdateMutation,
  ProfessorUpdateMutationVariables
>;

/**
 * __useProfessorUpdateMutation__
 *
 * To run a mutation, you first call `useProfessorUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfessorUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [professorUpdateMutation, { data, loading, error }] = useProfessorUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfessorUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfessorUpdateMutation,
    ProfessorUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProfessorUpdateMutation, ProfessorUpdateMutationVariables>(
    ProfessorUpdateDocument,
    options,
  );
}
export type ProfessorUpdateMutationHookResult = ReturnType<typeof useProfessorUpdateMutation>;
export type ProfessorUpdateMutationResult = Apollo.MutationResult<ProfessorUpdateMutation>;
export type ProfessorUpdateMutationOptions = Apollo.BaseMutationOptions<
  ProfessorUpdateMutation,
  ProfessorUpdateMutationVariables
>;
export const SubjectUpdateDocument = gql`
  mutation SubjectUpdate($id: ID!, $input: SubjectUpdateInput!) {
    subjectUpdate(id: $id, input: $input) {
      subject {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type SubjectUpdateMutationFn = Apollo.MutationFunction<
  SubjectUpdateMutation,
  SubjectUpdateMutationVariables
>;

/**
 * __useSubjectUpdateMutation__
 *
 * To run a mutation, you first call `useSubjectUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubjectUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subjectUpdateMutation, { data, loading, error }] = useSubjectUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubjectUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<SubjectUpdateMutation, SubjectUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubjectUpdateMutation, SubjectUpdateMutationVariables>(
    SubjectUpdateDocument,
    options,
  );
}
export type SubjectUpdateMutationHookResult = ReturnType<typeof useSubjectUpdateMutation>;
export type SubjectUpdateMutationResult = Apollo.MutationResult<SubjectUpdateMutation>;
export type SubjectUpdateMutationOptions = Apollo.BaseMutationOptions<
  SubjectUpdateMutation,
  SubjectUpdateMutationVariables
>;
export const UpdatePersonAccountPreferencesDocument = gql`
  mutation UpdatePersonAccountPreferences($id: ID!, $input: PersonPreferencesInput!) {
    personAccountPreferencesUpdate(id: $id, input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type UpdatePersonAccountPreferencesMutationFn = Apollo.MutationFunction<
  UpdatePersonAccountPreferencesMutation,
  UpdatePersonAccountPreferencesMutationVariables
>;

/**
 * __useUpdatePersonAccountPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdatePersonAccountPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonAccountPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonAccountPreferencesMutation, { data, loading, error }] = useUpdatePersonAccountPreferencesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonAccountPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonAccountPreferencesMutation,
    UpdatePersonAccountPreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonAccountPreferencesMutation,
    UpdatePersonAccountPreferencesMutationVariables
  >(UpdatePersonAccountPreferencesDocument, options);
}
export type UpdatePersonAccountPreferencesMutationHookResult = ReturnType<
  typeof useUpdatePersonAccountPreferencesMutation
>;
export type UpdatePersonAccountPreferencesMutationResult =
  Apollo.MutationResult<UpdatePersonAccountPreferencesMutation>;
export type UpdatePersonAccountPreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonAccountPreferencesMutation,
  UpdatePersonAccountPreferencesMutationVariables
>;
export const AccountSettingsDocument = gql`
  query AccountSettings($id: ID!) {
    person: node(id: $id) {
      ... on PersonNode {
        id
        calendarAlertDefault
        timeZone
      }
    }
  }
`;

/**
 * __useAccountSettingsQuery__
 *
 * To run a query within a React component, call `useAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountSettingsQuery, AccountSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(
    AccountSettingsDocument,
    options,
  );
}
export function useAccountSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountSettingsQuery, AccountSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(
    AccountSettingsDocument,
    options,
  );
}
export type AccountSettingsQueryHookResult = ReturnType<typeof useAccountSettingsQuery>;
export type AccountSettingsLazyQueryHookResult = ReturnType<typeof useAccountSettingsLazyQuery>;
export type AccountSettingsQueryResult = Apollo.QueryResult<
  AccountSettingsQuery,
  AccountSettingsQueryVariables
>;
export function refetchAccountSettingsQuery(variables: AccountSettingsQueryVariables) {
  return { query: AccountSettingsDocument, variables: variables };
}
export const GetCfapiTokenDocument = gql`
  query GetCFAPIToken($id: ID!) {
    person: node(id: $id) {
      ... on PersonNode {
        __typename
        id
        cfAccessToken
      }
    }
  }
`;

/**
 * __useGetCfapiTokenQuery__
 *
 * To run a query within a React component, call `useGetCfapiTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCfapiTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCfapiTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCfapiTokenQuery(
  baseOptions: Apollo.QueryHookOptions<GetCfapiTokenQuery, GetCfapiTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCfapiTokenQuery, GetCfapiTokenQueryVariables>(
    GetCfapiTokenDocument,
    options,
  );
}
export function useGetCfapiTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCfapiTokenQuery, GetCfapiTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCfapiTokenQuery, GetCfapiTokenQueryVariables>(
    GetCfapiTokenDocument,
    options,
  );
}
export type GetCfapiTokenQueryHookResult = ReturnType<typeof useGetCfapiTokenQuery>;
export type GetCfapiTokenLazyQueryHookResult = ReturnType<typeof useGetCfapiTokenLazyQuery>;
export type GetCfapiTokenQueryResult = Apollo.QueryResult<
  GetCfapiTokenQuery,
  GetCfapiTokenQueryVariables
>;
export function refetchGetCfapiTokenQuery(variables: GetCfapiTokenQueryVariables) {
  return { query: GetCfapiTokenDocument, variables: variables };
}
export const MergeUserAccountsDocument = gql`
  mutation MergeUserAccounts($input: UserAccountsMergeInput!) {
    userAccountsMerge(input: $input) {
      success
      userErrors {
        message
        field
      }
    }
  }
`;
export type MergeUserAccountsMutationFn = Apollo.MutationFunction<
  MergeUserAccountsMutation,
  MergeUserAccountsMutationVariables
>;

/**
 * __useMergeUserAccountsMutation__
 *
 * To run a mutation, you first call `useMergeUserAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeUserAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeUserAccountsMutation, { data, loading, error }] = useMergeUserAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeUserAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergeUserAccountsMutation,
    MergeUserAccountsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MergeUserAccountsMutation, MergeUserAccountsMutationVariables>(
    MergeUserAccountsDocument,
    options,
  );
}
export type MergeUserAccountsMutationHookResult = ReturnType<typeof useMergeUserAccountsMutation>;
export type MergeUserAccountsMutationResult = Apollo.MutationResult<MergeUserAccountsMutation>;
export type MergeUserAccountsMutationOptions = Apollo.BaseMutationOptions<
  MergeUserAccountsMutation,
  MergeUserAccountsMutationVariables
>;
export const RefreshCfapiTokenDocument = gql`
  mutation RefreshCFAPIToken {
    cfApiTokenRefresh {
      token
      userErrors {
        message
        field
      }
    }
  }
`;
export type RefreshCfapiTokenMutationFn = Apollo.MutationFunction<
  RefreshCfapiTokenMutation,
  RefreshCfapiTokenMutationVariables
>;

/**
 * __useRefreshCfapiTokenMutation__
 *
 * To run a mutation, you first call `useRefreshCfapiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshCfapiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshCfapiTokenMutation, { data, loading, error }] = useRefreshCfapiTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshCfapiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshCfapiTokenMutation,
    RefreshCfapiTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshCfapiTokenMutation, RefreshCfapiTokenMutationVariables>(
    RefreshCfapiTokenDocument,
    options,
  );
}
export type RefreshCfapiTokenMutationHookResult = ReturnType<typeof useRefreshCfapiTokenMutation>;
export type RefreshCfapiTokenMutationResult = Apollo.MutationResult<RefreshCfapiTokenMutation>;
export type RefreshCfapiTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshCfapiTokenMutation,
  RefreshCfapiTokenMutationVariables
>;
export const UpdateUserPasswordDocument = gql`
  mutation UpdateUserPassword($input: PasswordInput!) {
    userPasswordUpdate(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(
    UpdateUserPasswordDocument,
    options,
  );
}
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export const UpdateUserEmailDocument = gql`
  mutation UpdateUserEmail($input: EmailInput!) {
    userEmailUpdate(input: $input) {
      primaryEmail
      userErrors {
        message
        field
      }
    }
  }
`;
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>(
    UpdateUserEmailDocument,
    options,
  );
}
export type UpdateUserEmailMutationHookResult = ReturnType<typeof useUpdateUserEmailMutation>;
export type UpdateUserEmailMutationResult = Apollo.MutationResult<UpdateUserEmailMutation>;
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;
export const UpdateUserUsernameDocument = gql`
  mutation UpdateUserUsername($input: UsernameInput!) {
    userUsernameUpdate(input: $input) {
      username
      userErrors {
        message
        field
      }
    }
  }
`;
export type UpdateUserUsernameMutationFn = Apollo.MutationFunction<
  UpdateUserUsernameMutation,
  UpdateUserUsernameMutationVariables
>;

/**
 * __useUpdateUserUsernameMutation__
 *
 * To run a mutation, you first call `useUpdateUserUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserUsernameMutation, { data, loading, error }] = useUpdateUserUsernameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserUsernameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserUsernameMutation,
    UpdateUserUsernameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserUsernameMutation, UpdateUserUsernameMutationVariables>(
    UpdateUserUsernameDocument,
    options,
  );
}
export type UpdateUserUsernameMutationHookResult = ReturnType<typeof useUpdateUserUsernameMutation>;
export type UpdateUserUsernameMutationResult = Apollo.MutationResult<UpdateUserUsernameMutation>;
export type UpdateUserUsernameMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserUsernameMutation,
  UpdateUserUsernameMutationVariables
>;
export const ProfileBulkImportAddressDocument = gql`
  mutation ProfileBulkImportAddress($input: BulkAddressInput!) {
    profileBulkImportAddress(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type ProfileBulkImportAddressMutationFn = Apollo.MutationFunction<
  ProfileBulkImportAddressMutation,
  ProfileBulkImportAddressMutationVariables
>;

/**
 * __useProfileBulkImportAddressMutation__
 *
 * To run a mutation, you first call `useProfileBulkImportAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileBulkImportAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileBulkImportAddressMutation, { data, loading, error }] = useProfileBulkImportAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileBulkImportAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileBulkImportAddressMutation,
    ProfileBulkImportAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileBulkImportAddressMutation,
    ProfileBulkImportAddressMutationVariables
  >(ProfileBulkImportAddressDocument, options);
}
export type ProfileBulkImportAddressMutationHookResult = ReturnType<
  typeof useProfileBulkImportAddressMutation
>;
export type ProfileBulkImportAddressMutationResult =
  Apollo.MutationResult<ProfileBulkImportAddressMutation>;
export type ProfileBulkImportAddressMutationOptions = Apollo.BaseMutationOptions<
  ProfileBulkImportAddressMutation,
  ProfileBulkImportAddressMutationVariables
>;
export const AdminBulkImportContactsDocument = gql`
  mutation AdminBulkImportContacts($input: BulkPersonContactInput!) {
    adminBulkImportContacts(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type AdminBulkImportContactsMutationFn = Apollo.MutationFunction<
  AdminBulkImportContactsMutation,
  AdminBulkImportContactsMutationVariables
>;

/**
 * __useAdminBulkImportContactsMutation__
 *
 * To run a mutation, you first call `useAdminBulkImportContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkImportContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkImportContactsMutation, { data, loading, error }] = useAdminBulkImportContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminBulkImportContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminBulkImportContactsMutation,
    AdminBulkImportContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminBulkImportContactsMutation,
    AdminBulkImportContactsMutationVariables
  >(AdminBulkImportContactsDocument, options);
}
export type AdminBulkImportContactsMutationHookResult = ReturnType<
  typeof useAdminBulkImportContactsMutation
>;
export type AdminBulkImportContactsMutationResult =
  Apollo.MutationResult<AdminBulkImportContactsMutation>;
export type AdminBulkImportContactsMutationOptions = Apollo.BaseMutationOptions<
  AdminBulkImportContactsMutation,
  AdminBulkImportContactsMutationVariables
>;
export const ProfileBulkImportDocument = gql`
  mutation ProfileBulkImport($input: ProfileBulkInput!) {
    profileBulkImport(input: $input) {
      userErrors {
        field
        message
        __typename
      }
      __typename
    }
  }
`;
export type ProfileBulkImportMutationFn = Apollo.MutationFunction<
  ProfileBulkImportMutation,
  ProfileBulkImportMutationVariables
>;

/**
 * __useProfileBulkImportMutation__
 *
 * To run a mutation, you first call `useProfileBulkImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileBulkImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileBulkImportMutation, { data, loading, error }] = useProfileBulkImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileBulkImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileBulkImportMutation,
    ProfileBulkImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProfileBulkImportMutation, ProfileBulkImportMutationVariables>(
    ProfileBulkImportDocument,
    options,
  );
}
export type ProfileBulkImportMutationHookResult = ReturnType<typeof useProfileBulkImportMutation>;
export type ProfileBulkImportMutationResult = Apollo.MutationResult<ProfileBulkImportMutation>;
export type ProfileBulkImportMutationOptions = Apollo.BaseMutationOptions<
  ProfileBulkImportMutation,
  ProfileBulkImportMutationVariables
>;
export const AdminActionDetailDocument = gql`
  query AdminActionDetail($id: ID!) {
    adminAction: node(id: $id) {
      ... on AdminActionNode {
        id
        creator {
          fullNameNormalOrder
          pictureUrl
          initials
          teamMembership {
            userTypeLabels
          }
        }
        status
        parameters
        log
        action
      }
    }
  }
`;

/**
 * __useAdminActionDetailQuery__
 *
 * To run a query within a React component, call `useAdminActionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminActionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminActionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminActionDetailQuery(
  baseOptions: Apollo.QueryHookOptions<AdminActionDetailQuery, AdminActionDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminActionDetailQuery, AdminActionDetailQueryVariables>(
    AdminActionDetailDocument,
    options,
  );
}
export function useAdminActionDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminActionDetailQuery,
    AdminActionDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminActionDetailQuery, AdminActionDetailQueryVariables>(
    AdminActionDetailDocument,
    options,
  );
}
export type AdminActionDetailQueryHookResult = ReturnType<typeof useAdminActionDetailQuery>;
export type AdminActionDetailLazyQueryHookResult = ReturnType<typeof useAdminActionDetailLazyQuery>;
export type AdminActionDetailQueryResult = Apollo.QueryResult<
  AdminActionDetailQuery,
  AdminActionDetailQueryVariables
>;
export function refetchAdminActionDetailQuery(variables: AdminActionDetailQueryVariables) {
  return { query: AdminActionDetailDocument, variables: variables };
}
export const ProfileAttributeBulkImportDocument = gql`
  mutation ProfileAttributeBulkImport($input: ProfileAttributeBulkImportInput!) {
    attributeBulkImport(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type ProfileAttributeBulkImportMutationFn = Apollo.MutationFunction<
  ProfileAttributeBulkImportMutation,
  ProfileAttributeBulkImportMutationVariables
>;

/**
 * __useProfileAttributeBulkImportMutation__
 *
 * To run a mutation, you first call `useProfileAttributeBulkImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAttributeBulkImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAttributeBulkImportMutation, { data, loading, error }] = useProfileAttributeBulkImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileAttributeBulkImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileAttributeBulkImportMutation,
    ProfileAttributeBulkImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileAttributeBulkImportMutation,
    ProfileAttributeBulkImportMutationVariables
  >(ProfileAttributeBulkImportDocument, options);
}
export type ProfileAttributeBulkImportMutationHookResult = ReturnType<
  typeof useProfileAttributeBulkImportMutation
>;
export type ProfileAttributeBulkImportMutationResult =
  Apollo.MutationResult<ProfileAttributeBulkImportMutation>;
export type ProfileAttributeBulkImportMutationOptions = Apollo.BaseMutationOptions<
  ProfileAttributeBulkImportMutation,
  ProfileAttributeBulkImportMutationVariables
>;
export const AdminOrgProfileAttributesDocument = gql`
  query AdminOrgProfileAttributes($after: String, $before: String, $first: Int, $last: Int) {
    orgProfileAttributes(after: $after, before: $before, first: $first, last: $last) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          label
          coreCode
          isCustom
          categoryLabel
          categoryCoreCode
          dataType
          optionsList
          appliesToUserTypes
          hasUserTypeRestriction
        }
      }
    }
  }
`;

/**
 * __useAdminOrgProfileAttributesQuery__
 *
 * To run a query within a React component, call `useAdminOrgProfileAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrgProfileAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrgProfileAttributesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAdminOrgProfileAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminOrgProfileAttributesQuery,
    AdminOrgProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminOrgProfileAttributesQuery, AdminOrgProfileAttributesQueryVariables>(
    AdminOrgProfileAttributesDocument,
    options,
  );
}
export function useAdminOrgProfileAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminOrgProfileAttributesQuery,
    AdminOrgProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminOrgProfileAttributesQuery,
    AdminOrgProfileAttributesQueryVariables
  >(AdminOrgProfileAttributesDocument, options);
}
export type AdminOrgProfileAttributesQueryHookResult = ReturnType<
  typeof useAdminOrgProfileAttributesQuery
>;
export type AdminOrgProfileAttributesLazyQueryHookResult = ReturnType<
  typeof useAdminOrgProfileAttributesLazyQuery
>;
export type AdminOrgProfileAttributesQueryResult = Apollo.QueryResult<
  AdminOrgProfileAttributesQuery,
  AdminOrgProfileAttributesQueryVariables
>;
export function refetchAdminOrgProfileAttributesQuery(
  variables?: AdminOrgProfileAttributesQueryVariables,
) {
  return { query: AdminOrgProfileAttributesDocument, variables: variables };
}
export const AdminTeamProfileAttributesDocument = gql`
  query AdminTeamProfileAttributes($after: String, $before: String, $first: Int, $last: Int) {
    teamProfileAttributes(after: $after, before: $before, first: $first, last: $last) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          label
          coreCode
          isCustom
          categoryLabel
          dataType
          optionsList
          appliesToUserTypes
          hasUserTypeRestriction
        }
      }
    }
  }
`;

/**
 * __useAdminTeamProfileAttributesQuery__
 *
 * To run a query within a React component, call `useAdminTeamProfileAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTeamProfileAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTeamProfileAttributesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAdminTeamProfileAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminTeamProfileAttributesQuery,
    AdminTeamProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminTeamProfileAttributesQuery, AdminTeamProfileAttributesQueryVariables>(
    AdminTeamProfileAttributesDocument,
    options,
  );
}
export function useAdminTeamProfileAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminTeamProfileAttributesQuery,
    AdminTeamProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminTeamProfileAttributesQuery,
    AdminTeamProfileAttributesQueryVariables
  >(AdminTeamProfileAttributesDocument, options);
}
export type AdminTeamProfileAttributesQueryHookResult = ReturnType<
  typeof useAdminTeamProfileAttributesQuery
>;
export type AdminTeamProfileAttributesLazyQueryHookResult = ReturnType<
  typeof useAdminTeamProfileAttributesLazyQuery
>;
export type AdminTeamProfileAttributesQueryResult = Apollo.QueryResult<
  AdminTeamProfileAttributesQuery,
  AdminTeamProfileAttributesQueryVariables
>;
export function refetchAdminTeamProfileAttributesQuery(
  variables?: AdminTeamProfileAttributesQueryVariables,
) {
  return { query: AdminTeamProfileAttributesDocument, variables: variables };
}
export const AllOrgProfileAttributesDocument = gql`
  query AllOrgProfileAttributes {
    orgProfileAttributes {
      edges {
        node {
          id
          label
          enumOptions
          dataType
          isCustom
          coreCode
          optionsList
          categoryLabel
          categoryCoreCode
          appliesToUserTypes
          hasUserTypeRestriction
          extendedOptionsList {
            label
            key
            value
          }
        }
      }
    }
  }
`;

/**
 * __useAllOrgProfileAttributesQuery__
 *
 * To run a query within a React component, call `useAllOrgProfileAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrgProfileAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrgProfileAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllOrgProfileAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllOrgProfileAttributesQuery,
    AllOrgProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllOrgProfileAttributesQuery, AllOrgProfileAttributesQueryVariables>(
    AllOrgProfileAttributesDocument,
    options,
  );
}
export function useAllOrgProfileAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllOrgProfileAttributesQuery,
    AllOrgProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllOrgProfileAttributesQuery, AllOrgProfileAttributesQueryVariables>(
    AllOrgProfileAttributesDocument,
    options,
  );
}
export type AllOrgProfileAttributesQueryHookResult = ReturnType<
  typeof useAllOrgProfileAttributesQuery
>;
export type AllOrgProfileAttributesLazyQueryHookResult = ReturnType<
  typeof useAllOrgProfileAttributesLazyQuery
>;
export type AllOrgProfileAttributesQueryResult = Apollo.QueryResult<
  AllOrgProfileAttributesQuery,
  AllOrgProfileAttributesQueryVariables
>;
export function refetchAllOrgProfileAttributesQuery(
  variables?: AllOrgProfileAttributesQueryVariables,
) {
  return { query: AllOrgProfileAttributesDocument, variables: variables };
}
export const AllTeamProfileAttributesDocument = gql`
  query AllTeamProfileAttributes {
    teamProfileAttributes {
      edges {
        node {
          id
          label
          enumOptions
          dataType
          isCustom
          coreCode
          categoryLabel
          optionsList
          appliesToUserTypes
          hasUserTypeRestriction
          extendedOptionsList {
            label
            key
            value
          }
        }
      }
    }
  }
`;

/**
 * __useAllTeamProfileAttributesQuery__
 *
 * To run a query within a React component, call `useAllTeamProfileAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamProfileAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamProfileAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTeamProfileAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllTeamProfileAttributesQuery,
    AllTeamProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllTeamProfileAttributesQuery, AllTeamProfileAttributesQueryVariables>(
    AllTeamProfileAttributesDocument,
    options,
  );
}
export function useAllTeamProfileAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllTeamProfileAttributesQuery,
    AllTeamProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllTeamProfileAttributesQuery, AllTeamProfileAttributesQueryVariables>(
    AllTeamProfileAttributesDocument,
    options,
  );
}
export type AllTeamProfileAttributesQueryHookResult = ReturnType<
  typeof useAllTeamProfileAttributesQuery
>;
export type AllTeamProfileAttributesLazyQueryHookResult = ReturnType<
  typeof useAllTeamProfileAttributesLazyQuery
>;
export type AllTeamProfileAttributesQueryResult = Apollo.QueryResult<
  AllTeamProfileAttributesQuery,
  AllTeamProfileAttributesQueryVariables
>;
export function refetchAllTeamProfileAttributesQuery(
  variables?: AllTeamProfileAttributesQueryVariables,
) {
  return { query: AllTeamProfileAttributesDocument, variables: variables };
}
export const DromoS3UploadDocument = gql`
  mutation DromoS3Upload($input: DromoUploadS3FileParams!) {
    dromoS3Upload(input: $input) {
      fileNameServer
    }
  }
`;
export type DromoS3UploadMutationFn = Apollo.MutationFunction<
  DromoS3UploadMutation,
  DromoS3UploadMutationVariables
>;

/**
 * __useDromoS3UploadMutation__
 *
 * To run a mutation, you first call `useDromoS3UploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDromoS3UploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dromoS3UploadMutation, { data, loading, error }] = useDromoS3UploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDromoS3UploadMutation(
  baseOptions?: Apollo.MutationHookOptions<DromoS3UploadMutation, DromoS3UploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DromoS3UploadMutation, DromoS3UploadMutationVariables>(
    DromoS3UploadDocument,
    options,
  );
}
export type DromoS3UploadMutationHookResult = ReturnType<typeof useDromoS3UploadMutation>;
export type DromoS3UploadMutationResult = Apollo.MutationResult<DromoS3UploadMutation>;
export type DromoS3UploadMutationOptions = Apollo.BaseMutationOptions<
  DromoS3UploadMutation,
  DromoS3UploadMutationVariables
>;
export const AdminActionsDocument = gql`
  query AdminActions(
    $action: TWFilter_String
    $first: Int
    $after: String
    $sort: [AdminActionSortEnum]
  ) {
    adminActions(action: $action, first: $first, after: $after, sort: $sort) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          createdDateTime
          creator {
            fullNameNormalOrder
          }
          status
          parameters
          action
        }
      }
    }
  }
`;

/**
 * __useAdminActionsQuery__
 *
 * To run a query within a React component, call `useAdminActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminActionsQuery({
 *   variables: {
 *      action: // value for 'action'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminActionsQuery, AdminActionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminActionsQuery, AdminActionsQueryVariables>(
    AdminActionsDocument,
    options,
  );
}
export function useAdminActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminActionsQuery, AdminActionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminActionsQuery, AdminActionsQueryVariables>(
    AdminActionsDocument,
    options,
  );
}
export type AdminActionsQueryHookResult = ReturnType<typeof useAdminActionsQuery>;
export type AdminActionsLazyQueryHookResult = ReturnType<typeof useAdminActionsLazyQuery>;
export type AdminActionsQueryResult = Apollo.QueryResult<
  AdminActionsQuery,
  AdminActionsQueryVariables
>;
export function refetchAdminActionsQuery(variables?: AdminActionsQueryVariables) {
  return { query: AdminActionsDocument, variables: variables };
}
export const GetAllAthletesEmailsDocument = gql`
  query GetAllAthletesEmails($userTypes: [ID]) {
    people(userTypes: $userTypes) {
      edges {
        person: node {
          emailAddress
          schoolIdentifier
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetAllAthletesEmailsQuery__
 *
 * To run a query within a React component, call `useGetAllAthletesEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAthletesEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAthletesEmailsQuery({
 *   variables: {
 *      userTypes: // value for 'userTypes'
 *   },
 * });
 */
export function useGetAllAthletesEmailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllAthletesEmailsQuery,
    GetAllAthletesEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAthletesEmailsQuery, GetAllAthletesEmailsQueryVariables>(
    GetAllAthletesEmailsDocument,
    options,
  );
}
export function useGetAllAthletesEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAthletesEmailsQuery,
    GetAllAthletesEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAthletesEmailsQuery, GetAllAthletesEmailsQueryVariables>(
    GetAllAthletesEmailsDocument,
    options,
  );
}
export type GetAllAthletesEmailsQueryHookResult = ReturnType<typeof useGetAllAthletesEmailsQuery>;
export type GetAllAthletesEmailsLazyQueryHookResult = ReturnType<
  typeof useGetAllAthletesEmailsLazyQuery
>;
export type GetAllAthletesEmailsQueryResult = Apollo.QueryResult<
  GetAllAthletesEmailsQuery,
  GetAllAthletesEmailsQueryVariables
>;
export function refetchGetAllAthletesEmailsQuery(variables?: GetAllAthletesEmailsQueryVariables) {
  return { query: GetAllAthletesEmailsDocument, variables: variables };
}
export const GetAllPersonsAdminDocument = gql`
  query GetAllPersonsAdmin(
    $isContact: TWFilter_Boolean
    $teamId: Int
    $withTypes: Boolean = false
  ) {
    allPersons(excludeInactiveRecords: true, isContact: $isContact, teamId: $teamId) {
      edges {
        node {
          emailAddress
          userTypesByTeam @include(if: $withTypes)
        }
      }
    }
  }
`;

/**
 * __useGetAllPersonsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllPersonsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPersonsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPersonsAdminQuery({
 *   variables: {
 *      isContact: // value for 'isContact'
 *      teamId: // value for 'teamId'
 *      withTypes: // value for 'withTypes'
 *   },
 * });
 */
export function useGetAllPersonsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPersonsAdminQuery, GetAllPersonsAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPersonsAdminQuery, GetAllPersonsAdminQueryVariables>(
    GetAllPersonsAdminDocument,
    options,
  );
}
export function useGetAllPersonsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPersonsAdminQuery,
    GetAllPersonsAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPersonsAdminQuery, GetAllPersonsAdminQueryVariables>(
    GetAllPersonsAdminDocument,
    options,
  );
}
export type GetAllPersonsAdminQueryHookResult = ReturnType<typeof useGetAllPersonsAdminQuery>;
export type GetAllPersonsAdminLazyQueryHookResult = ReturnType<
  typeof useGetAllPersonsAdminLazyQuery
>;
export type GetAllPersonsAdminQueryResult = Apollo.QueryResult<
  GetAllPersonsAdminQuery,
  GetAllPersonsAdminQueryVariables
>;
export function refetchGetAllPersonsAdminQuery(variables?: GetAllPersonsAdminQueryVariables) {
  return { query: GetAllPersonsAdminDocument, variables: variables };
}
export const GetAllStatesDocument = gql`
  query GetAllStates {
    states {
      edges {
        node {
          name
          code
        }
      }
    }
  }
`;

/**
 * __useGetAllStatesQuery__
 *
 * To run a query within a React component, call `useGetAllStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStatesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllStatesQuery, GetAllStatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllStatesQuery, GetAllStatesQueryVariables>(
    GetAllStatesDocument,
    options,
  );
}
export function useGetAllStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllStatesQuery, GetAllStatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllStatesQuery, GetAllStatesQueryVariables>(
    GetAllStatesDocument,
    options,
  );
}
export type GetAllStatesQueryHookResult = ReturnType<typeof useGetAllStatesQuery>;
export type GetAllStatesLazyQueryHookResult = ReturnType<typeof useGetAllStatesLazyQuery>;
export type GetAllStatesQueryResult = Apollo.QueryResult<
  GetAllStatesQuery,
  GetAllStatesQueryVariables
>;
export function refetchGetAllStatesQuery(variables?: GetAllStatesQueryVariables) {
  return { query: GetAllStatesDocument, variables: variables };
}
export const GetOrgPositionsForAthletesDocument = gql`
  query GetOrgPositionsForAthletes($orgId: ID) {
    orgSportPositions(orgId: $orgId) {
      sportPosition {
        id
        label
      }
      team {
        teamId
        label
        shortLabel
      }
    }
  }
`;

/**
 * __useGetOrgPositionsForAthletesQuery__
 *
 * To run a query within a React component, call `useGetOrgPositionsForAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgPositionsForAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgPositionsForAthletesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetOrgPositionsForAthletesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrgPositionsForAthletesQuery,
    GetOrgPositionsForAthletesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgPositionsForAthletesQuery, GetOrgPositionsForAthletesQueryVariables>(
    GetOrgPositionsForAthletesDocument,
    options,
  );
}
export function useGetOrgPositionsForAthletesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgPositionsForAthletesQuery,
    GetOrgPositionsForAthletesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgPositionsForAthletesQuery,
    GetOrgPositionsForAthletesQueryVariables
  >(GetOrgPositionsForAthletesDocument, options);
}
export type GetOrgPositionsForAthletesQueryHookResult = ReturnType<
  typeof useGetOrgPositionsForAthletesQuery
>;
export type GetOrgPositionsForAthletesLazyQueryHookResult = ReturnType<
  typeof useGetOrgPositionsForAthletesLazyQuery
>;
export type GetOrgPositionsForAthletesQueryResult = Apollo.QueryResult<
  GetOrgPositionsForAthletesQuery,
  GetOrgPositionsForAthletesQueryVariables
>;
export function refetchGetOrgPositionsForAthletesQuery(
  variables?: GetOrgPositionsForAthletesQueryVariables,
) {
  return { query: GetOrgPositionsForAthletesDocument, variables: variables };
}
export const GetOrgTeamsDocument = gql`
  query GetOrgTeams($active: TWFilter_Boolean) {
    teams(active: $active) {
      edges {
        node {
          teamId
          label
          shortLabel
          active
        }
      }
    }
  }
`;

/**
 * __useGetOrgTeamsQuery__
 *
 * To run a query within a React component, call `useGetOrgTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgTeamsQuery({
 *   variables: {
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetOrgTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrgTeamsQuery, GetOrgTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgTeamsQuery, GetOrgTeamsQueryVariables>(GetOrgTeamsDocument, options);
}
export function useGetOrgTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrgTeamsQuery, GetOrgTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrgTeamsQuery, GetOrgTeamsQueryVariables>(
    GetOrgTeamsDocument,
    options,
  );
}
export type GetOrgTeamsQueryHookResult = ReturnType<typeof useGetOrgTeamsQuery>;
export type GetOrgTeamsLazyQueryHookResult = ReturnType<typeof useGetOrgTeamsLazyQuery>;
export type GetOrgTeamsQueryResult = Apollo.QueryResult<
  GetOrgTeamsQuery,
  GetOrgTeamsQueryVariables
>;
export function refetchGetOrgTeamsQuery(variables?: GetOrgTeamsQueryVariables) {
  return { query: GetOrgTeamsDocument, variables: variables };
}
export const ProfileBulkImportRoommatesDocument = gql`
  mutation ProfileBulkImportRoommates($input: ProfileBulkRoommateInput!) {
    profileBulkImportRoommates(input: $input) {
      userErrors {
        field
        message
        __typename
      }
      __typename
    }
  }
`;
export type ProfileBulkImportRoommatesMutationFn = Apollo.MutationFunction<
  ProfileBulkImportRoommatesMutation,
  ProfileBulkImportRoommatesMutationVariables
>;

/**
 * __useProfileBulkImportRoommatesMutation__
 *
 * To run a mutation, you first call `useProfileBulkImportRoommatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileBulkImportRoommatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileBulkImportRoommatesMutation, { data, loading, error }] = useProfileBulkImportRoommatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileBulkImportRoommatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileBulkImportRoommatesMutation,
    ProfileBulkImportRoommatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileBulkImportRoommatesMutation,
    ProfileBulkImportRoommatesMutationVariables
  >(ProfileBulkImportRoommatesDocument, options);
}
export type ProfileBulkImportRoommatesMutationHookResult = ReturnType<
  typeof useProfileBulkImportRoommatesMutation
>;
export type ProfileBulkImportRoommatesMutationResult =
  Apollo.MutationResult<ProfileBulkImportRoommatesMutation>;
export type ProfileBulkImportRoommatesMutationOptions = Apollo.BaseMutationOptions<
  ProfileBulkImportRoommatesMutation,
  ProfileBulkImportRoommatesMutationVariables
>;
export const ChooseAccountDocument = gql`
  mutation ChooseAccount($person: ID!) {
    chooseAccount(person: $person) {
      currentUserData {
        personId
        teamId
        orgId
        person {
          id
        }
      }
    }
  }
`;
export type ChooseAccountMutationFn = Apollo.MutationFunction<
  ChooseAccountMutation,
  ChooseAccountMutationVariables
>;

/**
 * __useChooseAccountMutation__
 *
 * To run a mutation, you first call `useChooseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseAccountMutation, { data, loading, error }] = useChooseAccountMutation({
 *   variables: {
 *      person: // value for 'person'
 *   },
 * });
 */
export function useChooseAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<ChooseAccountMutation, ChooseAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChooseAccountMutation, ChooseAccountMutationVariables>(
    ChooseAccountDocument,
    options,
  );
}
export type ChooseAccountMutationHookResult = ReturnType<typeof useChooseAccountMutation>;
export type ChooseAccountMutationResult = Apollo.MutationResult<ChooseAccountMutation>;
export type ChooseAccountMutationOptions = Apollo.BaseMutationOptions<
  ChooseAccountMutation,
  ChooseAccountMutationVariables
>;
export const UserAccountsDocument = gql`
  query UserAccounts {
    userAccounts {
      person {
        id
        pictureUrl
        initials
        preferredName
        nickname
        fullName
        lastAndFirstName
        fullNameNormalOrder
        emailAddress
      }
      org {
        id
        label
        logoUrl
        isAdminOrg
      }
    }
  }
`;

/**
 * __useUserAccountsQuery__
 *
 * To run a query within a React component, call `useUserAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserAccountsQuery, UserAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAccountsQuery, UserAccountsQueryVariables>(
    UserAccountsDocument,
    options,
  );
}
export function useUserAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserAccountsQuery, UserAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserAccountsQuery, UserAccountsQueryVariables>(
    UserAccountsDocument,
    options,
  );
}
export type UserAccountsQueryHookResult = ReturnType<typeof useUserAccountsQuery>;
export type UserAccountsLazyQueryHookResult = ReturnType<typeof useUserAccountsLazyQuery>;
export type UserAccountsQueryResult = Apollo.QueryResult<
  UserAccountsQuery,
  UserAccountsQueryVariables
>;
export function refetchUserAccountsQuery(variables?: UserAccountsQueryVariables) {
  return { query: UserAccountsDocument, variables: variables };
}
export const ExternalAccessPageDocument = gql`
  query ExternalAccessPage($uuid: String!) {
    externalAccessLink(uuid: $uuid) {
      node {
        id
        action
      }
      warnings
    }
  }
`;

/**
 * __useExternalAccessPageQuery__
 *
 * To run a query within a React component, call `useExternalAccessPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalAccessPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalAccessPageQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useExternalAccessPageQuery(
  baseOptions: Apollo.QueryHookOptions<ExternalAccessPageQuery, ExternalAccessPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalAccessPageQuery, ExternalAccessPageQueryVariables>(
    ExternalAccessPageDocument,
    options,
  );
}
export function useExternalAccessPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalAccessPageQuery,
    ExternalAccessPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalAccessPageQuery, ExternalAccessPageQueryVariables>(
    ExternalAccessPageDocument,
    options,
  );
}
export type ExternalAccessPageQueryHookResult = ReturnType<typeof useExternalAccessPageQuery>;
export type ExternalAccessPageLazyQueryHookResult = ReturnType<
  typeof useExternalAccessPageLazyQuery
>;
export type ExternalAccessPageQueryResult = Apollo.QueryResult<
  ExternalAccessPageQuery,
  ExternalAccessPageQueryVariables
>;
export function refetchExternalAccessPageQuery(variables: ExternalAccessPageQueryVariables) {
  return { query: ExternalAccessPageDocument, variables: variables };
}
export const HandleFormCallbackDocument = gql`
  mutation HandleFormCallback($input: FormCallbackInput!) {
    formCallback(input: $input) {
      completionRole {
        id
        status
        isExternalRole
        lastCallbackEvent
        externalLinkUrl
        completion {
          id
          isCompleted
          isLinkCompletion
        }
        workflow {
          assignedDateTime
          completedDateTime
          roleOrder
          roleName
          pk
          person {
            id
            displayName
          }
          externalName
          externalEmail
          id
          status
        }
      }
    }
  }
`;
export type HandleFormCallbackMutationFn = Apollo.MutationFunction<
  HandleFormCallbackMutation,
  HandleFormCallbackMutationVariables
>;

/**
 * __useHandleFormCallbackMutation__
 *
 * To run a mutation, you first call `useHandleFormCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleFormCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleFormCallbackMutation, { data, loading, error }] = useHandleFormCallbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandleFormCallbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HandleFormCallbackMutation,
    HandleFormCallbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HandleFormCallbackMutation, HandleFormCallbackMutationVariables>(
    HandleFormCallbackDocument,
    options,
  );
}
export type HandleFormCallbackMutationHookResult = ReturnType<typeof useHandleFormCallbackMutation>;
export type HandleFormCallbackMutationResult = Apollo.MutationResult<HandleFormCallbackMutation>;
export type HandleFormCallbackMutationOptions = Apollo.BaseMutationOptions<
  HandleFormCallbackMutation,
  HandleFormCallbackMutationVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($username: String!) {
    requestPasswordReset(username: $username) {
      ok
    }
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
    RequestPasswordResetDocument,
    options,
  );
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>;
export type RequestPasswordResetMutationResult =
  Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const SendUsernameReminderDocument = gql`
  mutation SendUsernameReminder($email: String!, $phone: String) {
    usernameReminderSend(email: $email, phone: $phone) {
      ok
      userErrors {
        field
        message
      }
    }
  }
`;
export type SendUsernameReminderMutationFn = Apollo.MutationFunction<
  SendUsernameReminderMutation,
  SendUsernameReminderMutationVariables
>;

/**
 * __useSendUsernameReminderMutation__
 *
 * To run a mutation, you first call `useSendUsernameReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUsernameReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUsernameReminderMutation, { data, loading, error }] = useSendUsernameReminderMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useSendUsernameReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendUsernameReminderMutation,
    SendUsernameReminderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendUsernameReminderMutation, SendUsernameReminderMutationVariables>(
    SendUsernameReminderDocument,
    options,
  );
}
export type SendUsernameReminderMutationHookResult = ReturnType<
  typeof useSendUsernameReminderMutation
>;
export type SendUsernameReminderMutationResult =
  Apollo.MutationResult<SendUsernameReminderMutation>;
export type SendUsernameReminderMutationOptions = Apollo.BaseMutationOptions<
  SendUsernameReminderMutation,
  SendUsernameReminderMutationVariables
>;
export const UserRegisterDocument = gql`
  mutation UserRegister($input: UserRegisterInput!) {
    userRegister(input: $input) {
      accessToken
      refreshToken
      expiresAt
      userErrors {
        field
        message
      }
    }
  }
`;
export type UserRegisterMutationFn = Apollo.MutationFunction<
  UserRegisterMutation,
  UserRegisterMutationVariables
>;

/**
 * __useUserRegisterMutation__
 *
 * To run a mutation, you first call `useUserRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRegisterMutation, { data, loading, error }] = useUserRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<UserRegisterMutation, UserRegisterMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserRegisterMutation, UserRegisterMutationVariables>(
    UserRegisterDocument,
    options,
  );
}
export type UserRegisterMutationHookResult = ReturnType<typeof useUserRegisterMutation>;
export type UserRegisterMutationResult = Apollo.MutationResult<UserRegisterMutation>;
export type UserRegisterMutationOptions = Apollo.BaseMutationOptions<
  UserRegisterMutation,
  UserRegisterMutationVariables
>;
export const UserRegistrationDocument = gql`
  query UserRegistration($uuid: String!) {
    userRegistrationPerson(uuid: $uuid) {
      id
      preferredName
      emailAddress
      org {
        id
        orgName
      }
      username
    }
  }
`;

/**
 * __useUserRegistrationQuery__
 *
 * To run a query within a React component, call `useUserRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRegistrationQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUserRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<UserRegistrationQuery, UserRegistrationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserRegistrationQuery, UserRegistrationQueryVariables>(
    UserRegistrationDocument,
    options,
  );
}
export function useUserRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserRegistrationQuery, UserRegistrationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserRegistrationQuery, UserRegistrationQueryVariables>(
    UserRegistrationDocument,
    options,
  );
}
export type UserRegistrationQueryHookResult = ReturnType<typeof useUserRegistrationQuery>;
export type UserRegistrationLazyQueryHookResult = ReturnType<typeof useUserRegistrationLazyQuery>;
export type UserRegistrationQueryResult = Apollo.QueryResult<
  UserRegistrationQuery,
  UserRegistrationQueryVariables
>;
export function refetchUserRegistrationQuery(variables: UserRegistrationQueryVariables) {
  return { query: UserRegistrationDocument, variables: variables };
}
export const DigitalDisplayDocument = gql`
  query DigitalDisplay($digitalDisplayId: TWFilter_ID) {
    digitalDisplays(digitalDisplayId: $digitalDisplayId) {
      edges {
        node {
          creator {
            pictureUrl
            fullNameNormalOrder
            initials
          }
          createdDate
          digitalDisplayId
          label
          settings {
            showLabelOnDisplay
            timeFormat
            timeZone
          }
          token
          pages {
            multipleCalendarsEnabled
            selectionsLabel
            settings {
              backgroundImage
              logoOptions
              multicalSelections {
                code
                key
                label
              }
              orientation
              selectionCodes
              transitionSpeed
              widgetSettings {
                details
                endDatetime
                image
                position
                startDatetime
                title
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDigitalDisplayQuery__
 *
 * To run a query within a React component, call `useDigitalDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalDisplayQuery({
 *   variables: {
 *      digitalDisplayId: // value for 'digitalDisplayId'
 *   },
 * });
 */
export function useDigitalDisplayQuery(
  baseOptions?: Apollo.QueryHookOptions<DigitalDisplayQuery, DigitalDisplayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DigitalDisplayQuery, DigitalDisplayQueryVariables>(
    DigitalDisplayDocument,
    options,
  );
}
export function useDigitalDisplayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DigitalDisplayQuery, DigitalDisplayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DigitalDisplayQuery, DigitalDisplayQueryVariables>(
    DigitalDisplayDocument,
    options,
  );
}
export type DigitalDisplayQueryHookResult = ReturnType<typeof useDigitalDisplayQuery>;
export type DigitalDisplayLazyQueryHookResult = ReturnType<typeof useDigitalDisplayLazyQuery>;
export type DigitalDisplayQueryResult = Apollo.QueryResult<
  DigitalDisplayQuery,
  DigitalDisplayQueryVariables
>;
export function refetchDigitalDisplayQuery(variables?: DigitalDisplayQueryVariables) {
  return { query: DigitalDisplayDocument, variables: variables };
}
export const CreateDigitalDisplayDocument = gql`
  mutation CreateDigitalDisplay(
    $backgroundImage: String
    $country: String = "USA"
    $customCss: JSONString
    $label: String!
    $logoOptions: LogoOptionsEnum
    $multicalSelections: [DigitalDisplayPageSettingsSelection]
    $orientation: OrientationEnum = landscape
    $showLabelOnDisplay: Boolean = false
    $timeFormat: String = "12h"
    $timeZone: String = "America/New_York"
    $transitionSpeed: Int = 15
    $widgetSettings: [DigitalDisplayWidgetSettingInput]
  ) {
    digitalDisplayCreate(
      input: {
        label: $label
        settings: {
          country: $country
          showLabelOnDisplay: $showLabelOnDisplay
          timeFormat: $timeFormat
          timeZone: $timeZone
        }
        displayPageSettings: {
          backgroundImage: $backgroundImage
          customCss: $customCss
          logoOptions: $logoOptions
          multicalSelections: $multicalSelections
          orientation: $orientation
          transitionSpeed: $transitionSpeed
          widgetSettings: $widgetSettings
        }
      }
    ) {
      digitalDisplay {
        token
      }
    }
  }
`;
export type CreateDigitalDisplayMutationFn = Apollo.MutationFunction<
  CreateDigitalDisplayMutation,
  CreateDigitalDisplayMutationVariables
>;

/**
 * __useCreateDigitalDisplayMutation__
 *
 * To run a mutation, you first call `useCreateDigitalDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDigitalDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDigitalDisplayMutation, { data, loading, error }] = useCreateDigitalDisplayMutation({
 *   variables: {
 *      backgroundImage: // value for 'backgroundImage'
 *      country: // value for 'country'
 *      customCss: // value for 'customCss'
 *      label: // value for 'label'
 *      logoOptions: // value for 'logoOptions'
 *      multicalSelections: // value for 'multicalSelections'
 *      orientation: // value for 'orientation'
 *      showLabelOnDisplay: // value for 'showLabelOnDisplay'
 *      timeFormat: // value for 'timeFormat'
 *      timeZone: // value for 'timeZone'
 *      transitionSpeed: // value for 'transitionSpeed'
 *      widgetSettings: // value for 'widgetSettings'
 *   },
 * });
 */
export function useCreateDigitalDisplayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDigitalDisplayMutation,
    CreateDigitalDisplayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDigitalDisplayMutation, CreateDigitalDisplayMutationVariables>(
    CreateDigitalDisplayDocument,
    options,
  );
}
export type CreateDigitalDisplayMutationHookResult = ReturnType<
  typeof useCreateDigitalDisplayMutation
>;
export type CreateDigitalDisplayMutationResult =
  Apollo.MutationResult<CreateDigitalDisplayMutation>;
export type CreateDigitalDisplayMutationOptions = Apollo.BaseMutationOptions<
  CreateDigitalDisplayMutation,
  CreateDigitalDisplayMutationVariables
>;
export const DeleteDigitalDisplayDocument = gql`
  mutation DeleteDigitalDisplay($id: ID!) {
    digitalDisplayDelete(id: $id) {
      digitalDisplay {
        token
      }
    }
  }
`;
export type DeleteDigitalDisplayMutationFn = Apollo.MutationFunction<
  DeleteDigitalDisplayMutation,
  DeleteDigitalDisplayMutationVariables
>;

/**
 * __useDeleteDigitalDisplayMutation__
 *
 * To run a mutation, you first call `useDeleteDigitalDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDigitalDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDigitalDisplayMutation, { data, loading, error }] = useDeleteDigitalDisplayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDigitalDisplayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDigitalDisplayMutation,
    DeleteDigitalDisplayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDigitalDisplayMutation, DeleteDigitalDisplayMutationVariables>(
    DeleteDigitalDisplayDocument,
    options,
  );
}
export type DeleteDigitalDisplayMutationHookResult = ReturnType<
  typeof useDeleteDigitalDisplayMutation
>;
export type DeleteDigitalDisplayMutationResult =
  Apollo.MutationResult<DeleteDigitalDisplayMutation>;
export type DeleteDigitalDisplayMutationOptions = Apollo.BaseMutationOptions<
  DeleteDigitalDisplayMutation,
  DeleteDigitalDisplayMutationVariables
>;
export const UpdateDigitalDisplayDocument = gql`
  mutation UpdateDigitalDisplay(
    $id: ID!
    $backgroundImage: String
    $country: String
    $customCss: JSONString
    $label: String
    $logoOptions: LogoOptionsEnum
    $multicalSelections: [DigitalDisplayPageSettingsSelection]
    $orientation: OrientationEnum
    $showLabelOnDisplay: Boolean
    $timeFormat: String
    $timeZone: String
    $transitionSpeed: Int
    $widgetSettings: [DigitalDisplayWidgetSettingInput]
  ) {
    digitalDisplayUpdate(
      id: $id
      input: {
        label: $label
        settings: {
          timeZone: $timeZone
          timeFormat: $timeFormat
          showLabelOnDisplay: $showLabelOnDisplay
          country: $country
        }
        displayPageSettings: {
          backgroundImage: $backgroundImage
          customCss: $customCss
          logoOptions: $logoOptions
          multicalSelections: $multicalSelections
          orientation: $orientation
          transitionSpeed: $transitionSpeed
          widgetSettings: $widgetSettings
        }
      }
    ) {
      digitalDisplay {
        token
      }
    }
  }
`;
export type UpdateDigitalDisplayMutationFn = Apollo.MutationFunction<
  UpdateDigitalDisplayMutation,
  UpdateDigitalDisplayMutationVariables
>;

/**
 * __useUpdateDigitalDisplayMutation__
 *
 * To run a mutation, you first call `useUpdateDigitalDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDigitalDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDigitalDisplayMutation, { data, loading, error }] = useUpdateDigitalDisplayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      backgroundImage: // value for 'backgroundImage'
 *      country: // value for 'country'
 *      customCss: // value for 'customCss'
 *      label: // value for 'label'
 *      logoOptions: // value for 'logoOptions'
 *      multicalSelections: // value for 'multicalSelections'
 *      orientation: // value for 'orientation'
 *      showLabelOnDisplay: // value for 'showLabelOnDisplay'
 *      timeFormat: // value for 'timeFormat'
 *      timeZone: // value for 'timeZone'
 *      transitionSpeed: // value for 'transitionSpeed'
 *      widgetSettings: // value for 'widgetSettings'
 *   },
 * });
 */
export function useUpdateDigitalDisplayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDigitalDisplayMutation,
    UpdateDigitalDisplayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDigitalDisplayMutation, UpdateDigitalDisplayMutationVariables>(
    UpdateDigitalDisplayDocument,
    options,
  );
}
export type UpdateDigitalDisplayMutationHookResult = ReturnType<
  typeof useUpdateDigitalDisplayMutation
>;
export type UpdateDigitalDisplayMutationResult =
  Apollo.MutationResult<UpdateDigitalDisplayMutation>;
export type UpdateDigitalDisplayMutationOptions = Apollo.BaseMutationOptions<
  UpdateDigitalDisplayMutation,
  UpdateDigitalDisplayMutationVariables
>;
export const DigitalDisplaysListDocument = gql`
  query DigitalDisplaysList {
    digitalDisplays {
      edges {
        node {
          digitalDisplayId
          createdDate
          label
          token
          creator {
            fullNameNormalOrder
            initials
            pictureUrl
          }
          pages {
            selectionsLabel
          }
          settings {
            showLabelOnDisplay
          }
        }
      }
    }
  }
`;

/**
 * __useDigitalDisplaysListQuery__
 *
 * To run a query within a React component, call `useDigitalDisplaysListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalDisplaysListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalDisplaysListQuery({
 *   variables: {
 *   },
 * });
 */
export function useDigitalDisplaysListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DigitalDisplaysListQuery,
    DigitalDisplaysListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DigitalDisplaysListQuery, DigitalDisplaysListQueryVariables>(
    DigitalDisplaysListDocument,
    options,
  );
}
export function useDigitalDisplaysListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DigitalDisplaysListQuery,
    DigitalDisplaysListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DigitalDisplaysListQuery, DigitalDisplaysListQueryVariables>(
    DigitalDisplaysListDocument,
    options,
  );
}
export type DigitalDisplaysListQueryHookResult = ReturnType<typeof useDigitalDisplaysListQuery>;
export type DigitalDisplaysListLazyQueryHookResult = ReturnType<
  typeof useDigitalDisplaysListLazyQuery
>;
export type DigitalDisplaysListQueryResult = Apollo.QueryResult<
  DigitalDisplaysListQuery,
  DigitalDisplaysListQueryVariables
>;
export function refetchDigitalDisplaysListQuery(variables?: DigitalDisplaysListQueryVariables) {
  return { query: DigitalDisplaysListDocument, variables: variables };
}
export const AssignFormDrawerDocument = gql`
  query AssignFormDrawer {
    formTemplates(sort: [form_title_asc]) {
      edges {
        formTemplate: node {
          id
          label
          quickformTemplate
          isDocusignForm
          hasFlaggedOptions
          serverFileName
          roles
          creator {
            displayName
          }
          createdDate
        }
      }
    }
    folders(folderType: "submittableFormAssignment") {
      edges {
        folder: node {
          id
          label: folderName
          isEditableByUser
        }
      }
    }
  }
`;

/**
 * __useAssignFormDrawerQuery__
 *
 * To run a query within a React component, call `useAssignFormDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignFormDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignFormDrawerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssignFormDrawerQuery(
  baseOptions?: Apollo.QueryHookOptions<AssignFormDrawerQuery, AssignFormDrawerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssignFormDrawerQuery, AssignFormDrawerQueryVariables>(
    AssignFormDrawerDocument,
    options,
  );
}
export function useAssignFormDrawerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssignFormDrawerQuery, AssignFormDrawerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssignFormDrawerQuery, AssignFormDrawerQueryVariables>(
    AssignFormDrawerDocument,
    options,
  );
}
export type AssignFormDrawerQueryHookResult = ReturnType<typeof useAssignFormDrawerQuery>;
export type AssignFormDrawerLazyQueryHookResult = ReturnType<typeof useAssignFormDrawerLazyQuery>;
export type AssignFormDrawerQueryResult = Apollo.QueryResult<
  AssignFormDrawerQuery,
  AssignFormDrawerQueryVariables
>;
export function refetchAssignFormDrawerQuery(variables?: AssignFormDrawerQueryVariables) {
  return { query: AssignFormDrawerDocument, variables: variables };
}
export const EditAssignFormDrawerDocument = gql`
  query EditAssignFormDrawer($editingAssignmentId: ID!) {
    formTemplates(sort: [form_title_asc]) {
      edges {
        formTemplate: node {
          id
          label
          quickformTemplate
          isDocusignForm
          hasFlaggedOptions
          serverFileName
          roles
        }
      }
    }
    folders(folderType: "submittableFormAssignment") {
      edges {
        folder: node {
          id
          label: folderName
          isEditableByUser
          folderId
        }
      }
    }
    editingAssignment: node(id: $editingAssignmentId) {
      ... on FormScheduleNode {
        __typename
        id
        label
        notes
        notifyAssignees
        formScheduleType: assignmentType
        scheduleStart
        recurRule
        recurRuleText
        assignmentTimezone
        relativeDueDateNumber
        relativeDueDateType
        relativeDueDateTime
        relativeDeactivationDateTimeNumber
        relativeDeactivationDateTimeType
        relativeDeactivationDateTimeTime
        parameters
        creator {
          personId
          displayName
        }
        form {
          id
          label
          roles
          hasFlaggedOptions
          isDocusignForm
        }
        folder {
          id
        }
        initialRoleArgs {
          name
          roleType
          editCompleted
          internalAssignees {
            label
            groupType
            pluralLabel
            selectionCode
            ... on SelectionGroup {
              people {
                label: displayName
                lastName
                initials
                pictureUrl
                selectionCode
                id
              }
            }
          }
          externalAssignees {
            name
            email
          }
        }
        additionalRoleArgs {
          name
          roleType
          roleOrder
          editCompleted
          chooserRoleOrder
          chooserAllowUser
          chooserAllowExternal
          chooserRestrictedToPersonTypes
          externalAssignees {
            name
            email
          }
          personSelection {
            label
            selectionCode
          }
        }
        resultsTo {
          viewResultsInTeamworks {
            label
            selectionCode
            groupType
            pluralLabel
            ... on SelectionGroup {
              people {
                label: displayName
                lastName
                initials
                pictureUrl
                selectionCode
                id
              }
            }
          }
          viewResultsInEmail
        }
        additionalResultsTo {
          completersToView {
            pluralLabel
            groupType
            label
            selectionCode
          }
          viewResultsInTeamworks {
            pluralLabel
            groupType
            label
            selectionCode
          }
          viewResultsInEmail
        }
        flaggedFormAlerts {
          nextStepsMsgType
          flaggedNextStepsMsg
          nonflaggedNextStepsMsg
          flaggedAnswerAlertType
          answerAlertSelections {
            label
            selectionCode
          }
        }
      }
      ... on FormAssignmentNode {
        __typename
        id
        label
        assignedDateTime
        assignmentType
        assignmentTimezone
        assignedBy {
          personId
          displayName
        }
        isActive
        isScheduled
        dueDate
        deactivationDateTime
        notes
        completedFormReceiptEmail
        externalLinkUrl
        quickformTemplate
        form {
          id
          label
          roles
          hasFlaggedOptions
          isDocusignForm
        }
        folder {
          id
        }
        initialRoleArgs {
          name
          roleType
          editCompleted
          internalAssignees {
            label
            groupType
            pluralLabel
            selectionCode
            ... on SelectionGroup {
              people {
                label: displayName
                lastName
                initials
                pictureUrl
                selectionCode
                id
              }
            }
          }
          externalAssignees {
            name
            email
          }
        }
        additionalRoleArgs {
          name
          roleType
          roleOrder
          editCompleted
          chooserRoleOrder
          chooserAllowUser
          chooserAllowExternal
          chooserRestrictedToPersonTypes
          externalAssignees {
            name
            email
          }
          personSelection {
            label
            selectionCode
          }
        }
        resultsTo {
          viewResultsInTeamworks {
            label
            selectionCode
            groupType
            pluralLabel
            ... on SelectionGroup {
              people {
                label: displayName
                lastName
                initials
                pictureUrl
                selectionCode
                id
              }
            }
          }
          viewResultsInEmail
        }
        additionalResultsTo {
          completersToView {
            pluralLabel
            groupType
            label
            selectionCode
          }
          viewResultsInTeamworks {
            pluralLabel
            groupType
            label
            selectionCode
          }
          viewResultsInEmail
        }
        flaggedFormAlerts {
          nextStepsMsgType
          flaggedNextStepsMsg
          nonflaggedNextStepsMsg
          flaggedAnswerAlertType
          answerAlertSelections {
            label
            selectionCode
          }
        }
      }
    }
  }
`;

/**
 * __useEditAssignFormDrawerQuery__
 *
 * To run a query within a React component, call `useEditAssignFormDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditAssignFormDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditAssignFormDrawerQuery({
 *   variables: {
 *      editingAssignmentId: // value for 'editingAssignmentId'
 *   },
 * });
 */
export function useEditAssignFormDrawerQuery(
  baseOptions: Apollo.QueryHookOptions<
    EditAssignFormDrawerQuery,
    EditAssignFormDrawerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EditAssignFormDrawerQuery, EditAssignFormDrawerQueryVariables>(
    EditAssignFormDrawerDocument,
    options,
  );
}
export function useEditAssignFormDrawerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditAssignFormDrawerQuery,
    EditAssignFormDrawerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EditAssignFormDrawerQuery, EditAssignFormDrawerQueryVariables>(
    EditAssignFormDrawerDocument,
    options,
  );
}
export type EditAssignFormDrawerQueryHookResult = ReturnType<typeof useEditAssignFormDrawerQuery>;
export type EditAssignFormDrawerLazyQueryHookResult = ReturnType<
  typeof useEditAssignFormDrawerLazyQuery
>;
export type EditAssignFormDrawerQueryResult = Apollo.QueryResult<
  EditAssignFormDrawerQuery,
  EditAssignFormDrawerQueryVariables
>;
export function refetchEditAssignFormDrawerQuery(variables: EditAssignFormDrawerQueryVariables) {
  return { query: EditAssignFormDrawerDocument, variables: variables };
}
export const FormAssignDocument = gql`
  mutation FormAssign($input: AssignmentArgsInput) {
    assign(input: $input) {
      formAssignment {
        id
        label
        externalLinkUrl
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FormAssignMutationFn = Apollo.MutationFunction<
  FormAssignMutation,
  FormAssignMutationVariables
>;

/**
 * __useFormAssignMutation__
 *
 * To run a mutation, you first call `useFormAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formAssignMutation, { data, loading, error }] = useFormAssignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormAssignMutation(
  baseOptions?: Apollo.MutationHookOptions<FormAssignMutation, FormAssignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FormAssignMutation, FormAssignMutationVariables>(
    FormAssignDocument,
    options,
  );
}
export type FormAssignMutationHookResult = ReturnType<typeof useFormAssignMutation>;
export type FormAssignMutationResult = Apollo.MutationResult<FormAssignMutation>;
export type FormAssignMutationOptions = Apollo.BaseMutationOptions<
  FormAssignMutation,
  FormAssignMutationVariables
>;
export const CreateFormLinkDocument = gql`
  mutation CreateFormLink($id: ID!) {
    formAssignmentCreateSharedLink(id: $id) {
      formAssignment {
        id
        externalLinkUrl
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CreateFormLinkMutationFn = Apollo.MutationFunction<
  CreateFormLinkMutation,
  CreateFormLinkMutationVariables
>;

/**
 * __useCreateFormLinkMutation__
 *
 * To run a mutation, you first call `useCreateFormLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormLinkMutation, { data, loading, error }] = useCreateFormLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateFormLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFormLinkMutation, CreateFormLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFormLinkMutation, CreateFormLinkMutationVariables>(
    CreateFormLinkDocument,
    options,
  );
}
export type CreateFormLinkMutationHookResult = ReturnType<typeof useCreateFormLinkMutation>;
export type CreateFormLinkMutationResult = Apollo.MutationResult<CreateFormLinkMutation>;
export type CreateFormLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateFormLinkMutation,
  CreateFormLinkMutationVariables
>;
export const DeleteFormLinkDocument = gql`
  mutation DeleteFormLink($id: ID!) {
    formAssignmentDeleteSharedLink(id: $id) {
      formAssignment {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type DeleteFormLinkMutationFn = Apollo.MutationFunction<
  DeleteFormLinkMutation,
  DeleteFormLinkMutationVariables
>;

/**
 * __useDeleteFormLinkMutation__
 *
 * To run a mutation, you first call `useDeleteFormLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormLinkMutation, { data, loading, error }] = useDeleteFormLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFormLinkMutation, DeleteFormLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFormLinkMutation, DeleteFormLinkMutationVariables>(
    DeleteFormLinkDocument,
    options,
  );
}
export type DeleteFormLinkMutationHookResult = ReturnType<typeof useDeleteFormLinkMutation>;
export type DeleteFormLinkMutationResult = Apollo.MutationResult<DeleteFormLinkMutation>;
export type DeleteFormLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteFormLinkMutation,
  DeleteFormLinkMutationVariables
>;
export const UpdateFormAssignmentDocument = gql`
  mutation UpdateFormAssignment($id: ID!, $input: UpdateAssignmentArgsInput!) {
    formAssignmentUpdate(id: $id, input: $input) {
      formAssignment {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateFormAssignmentMutationFn = Apollo.MutationFunction<
  UpdateFormAssignmentMutation,
  UpdateFormAssignmentMutationVariables
>;

/**
 * __useUpdateFormAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateFormAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormAssignmentMutation, { data, loading, error }] = useUpdateFormAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormAssignmentMutation,
    UpdateFormAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFormAssignmentMutation, UpdateFormAssignmentMutationVariables>(
    UpdateFormAssignmentDocument,
    options,
  );
}
export type UpdateFormAssignmentMutationHookResult = ReturnType<
  typeof useUpdateFormAssignmentMutation
>;
export type UpdateFormAssignmentMutationResult =
  Apollo.MutationResult<UpdateFormAssignmentMutation>;
export type UpdateFormAssignmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormAssignmentMutation,
  UpdateFormAssignmentMutationVariables
>;
export const FormScheduleSaveDocument = gql`
  mutation FormScheduleSave($input: SaveFormScheduleArgsInput) {
    formScheduleSave(input: $input) {
      formSchedule {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FormScheduleSaveMutationFn = Apollo.MutationFunction<
  FormScheduleSaveMutation,
  FormScheduleSaveMutationVariables
>;

/**
 * __useFormScheduleSaveMutation__
 *
 * To run a mutation, you first call `useFormScheduleSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormScheduleSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formScheduleSaveMutation, { data, loading, error }] = useFormScheduleSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormScheduleSaveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FormScheduleSaveMutation,
    FormScheduleSaveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FormScheduleSaveMutation, FormScheduleSaveMutationVariables>(
    FormScheduleSaveDocument,
    options,
  );
}
export type FormScheduleSaveMutationHookResult = ReturnType<typeof useFormScheduleSaveMutation>;
export type FormScheduleSaveMutationResult = Apollo.MutationResult<FormScheduleSaveMutation>;
export type FormScheduleSaveMutationOptions = Apollo.BaseMutationOptions<
  FormScheduleSaveMutation,
  FormScheduleSaveMutationVariables
>;
export const ExternalFetchDocusignRecipientUrlDocument = gql`
  query ExternalFetchDocusignRecipientUrl($uuid: String!) {
    externalCompletionRole(uuid: $uuid) {
      ... on FormAssignmentCompletionRoleNode {
        __typename
        retrieveAsyncRecipientTokenUrl
      }
    }
  }
`;

/**
 * __useExternalFetchDocusignRecipientUrlQuery__
 *
 * To run a query within a React component, call `useExternalFetchDocusignRecipientUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalFetchDocusignRecipientUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalFetchDocusignRecipientUrlQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useExternalFetchDocusignRecipientUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalFetchDocusignRecipientUrlQuery,
    ExternalFetchDocusignRecipientUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalFetchDocusignRecipientUrlQuery,
    ExternalFetchDocusignRecipientUrlQueryVariables
  >(ExternalFetchDocusignRecipientUrlDocument, options);
}
export function useExternalFetchDocusignRecipientUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalFetchDocusignRecipientUrlQuery,
    ExternalFetchDocusignRecipientUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalFetchDocusignRecipientUrlQuery,
    ExternalFetchDocusignRecipientUrlQueryVariables
  >(ExternalFetchDocusignRecipientUrlDocument, options);
}
export type ExternalFetchDocusignRecipientUrlQueryHookResult = ReturnType<
  typeof useExternalFetchDocusignRecipientUrlQuery
>;
export type ExternalFetchDocusignRecipientUrlLazyQueryHookResult = ReturnType<
  typeof useExternalFetchDocusignRecipientUrlLazyQuery
>;
export type ExternalFetchDocusignRecipientUrlQueryResult = Apollo.QueryResult<
  ExternalFetchDocusignRecipientUrlQuery,
  ExternalFetchDocusignRecipientUrlQueryVariables
>;
export function refetchExternalFetchDocusignRecipientUrlQuery(
  variables: ExternalFetchDocusignRecipientUrlQueryVariables,
) {
  return { query: ExternalFetchDocusignRecipientUrlDocument, variables: variables };
}
export const InternalFetchDocusignRecipientUrlDocument = gql`
  query InternalFetchDocusignRecipientUrl($id: ID!) {
    completionRole: node(id: $id) {
      ... on FormAssignmentCompletionRoleNode {
        __typename
        retrieveAsyncRecipientTokenUrl
      }
    }
  }
`;

/**
 * __useInternalFetchDocusignRecipientUrlQuery__
 *
 * To run a query within a React component, call `useInternalFetchDocusignRecipientUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalFetchDocusignRecipientUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalFetchDocusignRecipientUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInternalFetchDocusignRecipientUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    InternalFetchDocusignRecipientUrlQuery,
    InternalFetchDocusignRecipientUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InternalFetchDocusignRecipientUrlQuery,
    InternalFetchDocusignRecipientUrlQueryVariables
  >(InternalFetchDocusignRecipientUrlDocument, options);
}
export function useInternalFetchDocusignRecipientUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InternalFetchDocusignRecipientUrlQuery,
    InternalFetchDocusignRecipientUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InternalFetchDocusignRecipientUrlQuery,
    InternalFetchDocusignRecipientUrlQueryVariables
  >(InternalFetchDocusignRecipientUrlDocument, options);
}
export type InternalFetchDocusignRecipientUrlQueryHookResult = ReturnType<
  typeof useInternalFetchDocusignRecipientUrlQuery
>;
export type InternalFetchDocusignRecipientUrlLazyQueryHookResult = ReturnType<
  typeof useInternalFetchDocusignRecipientUrlLazyQuery
>;
export type InternalFetchDocusignRecipientUrlQueryResult = Apollo.QueryResult<
  InternalFetchDocusignRecipientUrlQuery,
  InternalFetchDocusignRecipientUrlQueryVariables
>;
export function refetchInternalFetchDocusignRecipientUrlQuery(
  variables: InternalFetchDocusignRecipientUrlQueryVariables,
) {
  return { query: InternalFetchDocusignRecipientUrlDocument, variables: variables };
}
export const AssignFormDocument = gql`
  mutation AssignForm($input: ChooseRolesInput!) {
    formAssignmentChooseRoles(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type AssignFormMutationFn = Apollo.MutationFunction<
  AssignFormMutation,
  AssignFormMutationVariables
>;

/**
 * __useAssignFormMutation__
 *
 * To run a mutation, you first call `useAssignFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignFormMutation, { data, loading, error }] = useAssignFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignFormMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignFormMutation, AssignFormMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignFormMutation, AssignFormMutationVariables>(
    AssignFormDocument,
    options,
  );
}
export type AssignFormMutationHookResult = ReturnType<typeof useAssignFormMutation>;
export type AssignFormMutationResult = Apollo.MutationResult<AssignFormMutation>;
export type AssignFormMutationOptions = Apollo.BaseMutationOptions<
  AssignFormMutation,
  AssignFormMutationVariables
>;
export const ExternalCompletionCreateDocument = gql`
  mutation ExternalCompletionCreate($input: ExternalCompletionCreateInput) {
    externalCompletionCreate(input: $input) {
      link
      formCompletionRole {
        id
        completion {
          assignment {
            id
            label
            dueDate
            notes
            isDocusignForm
            quickformTemplate
            assignerEmail
            assignedBy {
              id
              displayName
            }
          }
        }
        workflow {
          id
          status
          updatedDate
          externalName
          person {
            id
            displayName
          }
        }
      }
    }
  }
`;
export type ExternalCompletionCreateMutationFn = Apollo.MutationFunction<
  ExternalCompletionCreateMutation,
  ExternalCompletionCreateMutationVariables
>;

/**
 * __useExternalCompletionCreateMutation__
 *
 * To run a mutation, you first call `useExternalCompletionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalCompletionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalCompletionCreateMutation, { data, loading, error }] = useExternalCompletionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalCompletionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalCompletionCreateMutation,
    ExternalCompletionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalCompletionCreateMutation,
    ExternalCompletionCreateMutationVariables
  >(ExternalCompletionCreateDocument, options);
}
export type ExternalCompletionCreateMutationHookResult = ReturnType<
  typeof useExternalCompletionCreateMutation
>;
export type ExternalCompletionCreateMutationResult =
  Apollo.MutationResult<ExternalCompletionCreateMutation>;
export type ExternalCompletionCreateMutationOptions = Apollo.BaseMutationOptions<
  ExternalCompletionCreateMutation,
  ExternalCompletionCreateMutationVariables
>;
export const ExternalFormCompletionDocument = gql`
  query ExternalFormCompletion($uuid: String!) {
    externalFormAssignment(uuid: $uuid) {
      id
      label
      dueDate
    }
    externalCompletionRole(uuid: $uuid) {
      id
      status
      externalName
      externalEmail
      initiateAsyncRecipientTokenUrlFetch
      completion {
        reassignmentNotes: reassignNotes
        signedDownloadUrl
        assignment {
          id
          pk
          label
          dueDate
          assignedDateTime
          notes
          isDocusignForm
          quickformTemplate
          assignerEmail
          assignedBy {
            id
            displayName
          }
        }
      }
      completionRolesAssignedByCompleter {
        id
        hasRoleBeenChosen
        assignmentRole {
          id
          chooserRestrictedToPersonTypes
          chooserAllowExternal
          roleName
          roleOrder
        }
      }
      workflow {
        id
        status
        updatedDate
        externalName
        person {
          id
          displayName
        }
      }
    }
  }
`;

/**
 * __useExternalFormCompletionQuery__
 *
 * To run a query within a React component, call `useExternalFormCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalFormCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalFormCompletionQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useExternalFormCompletionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalFormCompletionQuery,
    ExternalFormCompletionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalFormCompletionQuery, ExternalFormCompletionQueryVariables>(
    ExternalFormCompletionDocument,
    options,
  );
}
export function useExternalFormCompletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalFormCompletionQuery,
    ExternalFormCompletionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalFormCompletionQuery, ExternalFormCompletionQueryVariables>(
    ExternalFormCompletionDocument,
    options,
  );
}
export type ExternalFormCompletionQueryHookResult = ReturnType<
  typeof useExternalFormCompletionQuery
>;
export type ExternalFormCompletionLazyQueryHookResult = ReturnType<
  typeof useExternalFormCompletionLazyQuery
>;
export type ExternalFormCompletionQueryResult = Apollo.QueryResult<
  ExternalFormCompletionQuery,
  ExternalFormCompletionQueryVariables
>;
export function refetchExternalFormCompletionQuery(
  variables: ExternalFormCompletionQueryVariables,
) {
  return { query: ExternalFormCompletionDocument, variables: variables };
}
export const InternalFormCompletionDocument = gql`
  query InternalFormCompletion($id: ID!) {
    completionRole: node(id: $id) {
      ... on FormAssignmentCompletionRoleNode {
        __typename
        id
        status
        initiateAsyncRecipientTokenUrlFetch
        completion {
          reassignmentNotes: reassignNotes
          assignment {
            id
            label
            dueDate
            assignedDateTime
            notes
            isDocusignForm
            quickformTemplate
            assignerEmail
            assignedBy {
              id
              displayName
            }
          }
        }
        completionRolesAssignedByCompleter {
          id
          hasRoleBeenChosen
          assignmentRole {
            id
            chooserRestrictedToPersonTypes
            chooserAllowExternal
            roleName
            roleOrder
          }
        }
        workflow {
          id
          status
          updatedDate
          externalName
          person {
            id
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useInternalFormCompletionQuery__
 *
 * To run a query within a React component, call `useInternalFormCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalFormCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalFormCompletionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInternalFormCompletionQuery(
  baseOptions: Apollo.QueryHookOptions<
    InternalFormCompletionQuery,
    InternalFormCompletionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InternalFormCompletionQuery, InternalFormCompletionQueryVariables>(
    InternalFormCompletionDocument,
    options,
  );
}
export function useInternalFormCompletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InternalFormCompletionQuery,
    InternalFormCompletionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InternalFormCompletionQuery, InternalFormCompletionQueryVariables>(
    InternalFormCompletionDocument,
    options,
  );
}
export type InternalFormCompletionQueryHookResult = ReturnType<
  typeof useInternalFormCompletionQuery
>;
export type InternalFormCompletionLazyQueryHookResult = ReturnType<
  typeof useInternalFormCompletionLazyQuery
>;
export type InternalFormCompletionQueryResult = Apollo.QueryResult<
  InternalFormCompletionQuery,
  InternalFormCompletionQueryVariables
>;
export function refetchInternalFormCompletionQuery(
  variables: InternalFormCompletionQueryVariables,
) {
  return { query: InternalFormCompletionDocument, variables: variables };
}
export const CompleteFormDocument = gql`
  mutation CompleteForm($input: CompleteFormInput!) {
    completeForm(input: $input) {
      formAssignmentCompletionRole {
        completedDateTime
      }
    }
  }
`;
export type CompleteFormMutationFn = Apollo.MutationFunction<
  CompleteFormMutation,
  CompleteFormMutationVariables
>;

/**
 * __useCompleteFormMutation__
 *
 * To run a mutation, you first call `useCompleteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFormMutation, { data, loading, error }] = useCompleteFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteFormMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteFormMutation, CompleteFormMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteFormMutation, CompleteFormMutationVariables>(
    CompleteFormDocument,
    options,
  );
}
export type CompleteFormMutationHookResult = ReturnType<typeof useCompleteFormMutation>;
export type CompleteFormMutationResult = Apollo.MutationResult<CompleteFormMutation>;
export type CompleteFormMutationOptions = Apollo.BaseMutationOptions<
  CompleteFormMutation,
  CompleteFormMutationVariables
>;
export const FilterFormCompletionsDocument = gql`
  query FilterFormCompletions(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $isCompleted: TWFilter_Boolean
    $sort: [FormAssignmentCompletionSortEnum]
    $pk: TWFilter_String
    $searchAssignees: String
    $searchInAnswers: String
    $searchOptions: [SearchOptionsEnum]
    $lastUpdatedDateStart: Date
    $lastUpdatedDateEnd: Date
    $submittableFormAssignmentId: ID!
    $teamId: Int
    $manuallyCompleted: TWFilter_Boolean
    $generatePdfs: Boolean
  ) {
    filterFormCompletions(
      after: $after
      before: $before
      first: $first
      last: $last
      isCompleted: $isCompleted
      sort: $sort
      pk: $pk
      lastUpdatedDateStart: $lastUpdatedDateStart
      lastUpdatedDateEnd: $lastUpdatedDateEnd
      searchAssignees: $searchAssignees
      searchInAnswers: $searchInAnswers
      searchOptions: $searchOptions
      formAssignmentId: $submittableFormAssignmentId
      teamId: $teamId
      manuallyCompleted: $manuallyCompleted
      generatePdfs: $generatePdfs
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        __typename
      }
      totalCount
      edges {
        node {
          signedDownloadUrl
          key: id
          pk
          docusignEnvelopeId
          completedDateTime
          status
          hasFlaggedAnswers
          isCompleted
          isMostRecent
          isLinkCompletion
          hasBeenReassigned
          manuallyCompleted
          manuallyCompletedExplanation
          assignment {
            assignmentType
            assignedBy {
              pk
            }
            assignees {
              label
              selectionCode
              peopleCount
              pluralLabel
              groupType
            }
          }
          workflow {
            id
            pk
            status
            assignedDateTime
            completedDateTime
            updatedDate
            person {
              pk
              displayName
              emailAddress
              initials
              picture: pictureUrl
            }
            externalName
            externalEmail
            roleOrder
            roleName
          }
        }
      }
      __typename
    }
  }
`;

/**
 * __useFilterFormCompletionsQuery__
 *
 * To run a query within a React component, call `useFilterFormCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterFormCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterFormCompletionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      isCompleted: // value for 'isCompleted'
 *      sort: // value for 'sort'
 *      pk: // value for 'pk'
 *      searchAssignees: // value for 'searchAssignees'
 *      searchInAnswers: // value for 'searchInAnswers'
 *      searchOptions: // value for 'searchOptions'
 *      lastUpdatedDateStart: // value for 'lastUpdatedDateStart'
 *      lastUpdatedDateEnd: // value for 'lastUpdatedDateEnd'
 *      submittableFormAssignmentId: // value for 'submittableFormAssignmentId'
 *      teamId: // value for 'teamId'
 *      manuallyCompleted: // value for 'manuallyCompleted'
 *      generatePdfs: // value for 'generatePdfs'
 *   },
 * });
 */
export function useFilterFormCompletionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterFormCompletionsQuery,
    FilterFormCompletionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFormCompletionsQuery, FilterFormCompletionsQueryVariables>(
    FilterFormCompletionsDocument,
    options,
  );
}
export function useFilterFormCompletionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterFormCompletionsQuery,
    FilterFormCompletionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFormCompletionsQuery, FilterFormCompletionsQueryVariables>(
    FilterFormCompletionsDocument,
    options,
  );
}
export type FilterFormCompletionsQueryHookResult = ReturnType<typeof useFilterFormCompletionsQuery>;
export type FilterFormCompletionsLazyQueryHookResult = ReturnType<
  typeof useFilterFormCompletionsLazyQuery
>;
export type FilterFormCompletionsQueryResult = Apollo.QueryResult<
  FilterFormCompletionsQuery,
  FilterFormCompletionsQueryVariables
>;
export function refetchFilterFormCompletionsQuery(variables: FilterFormCompletionsQueryVariables) {
  return { query: FilterFormCompletionsDocument, variables: variables };
}
export const AllFormAssignmentCompletionsDocument = gql`
  query AllFormAssignmentCompletions(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $isCompleted: TWFilter_Boolean
    $sort: [FormAssignmentCompletionSortEnum]
    $pk: TWFilter_String
    $searchInAnswers: String
    $lastUpdatedDateStart: Date
    $lastUpdatedDateEnd: Date
    $submittableFormAssignmentId: ID!
    $teamId: Int
    $hasAccessibleAnswers: TWFilter_Boolean
  ) {
    formAssignmentCompletions(
      after: $after
      before: $before
      first: $first
      last: $last
      isCompleted: $isCompleted
      sort: $sort
      pk: $pk
      lastUpdatedDateStart: $lastUpdatedDateStart
      lastUpdatedDateEnd: $lastUpdatedDateEnd
      searchInAnswers: $searchInAnswers
      formAssignmentId: $submittableFormAssignmentId
      teamId: $teamId
      hasAccessibleAnswers: $hasAccessibleAnswers
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          key: id
          completionId: pk
          completedDateTime
          status
          hasFlaggedAnswers
          isCompleted
          isLinkCompletion
          hasBeenReassigned
          manuallyCompleted
          manuallyCompletedExplanation
          signedDownloadUrl
          completedAnswers {
            id
            fieldOrder
            fieldName
            fieldAnswerList
            fieldType
          }
          workflow {
            pk
            status
            assignedDateTime
            completedDateTime
            person {
              pk
              displayName
              picture: pictureUrl
            }
            externalName
            externalEmail
            roleOrder
            roleName
          }
        }
      }
    }
  }
`;

/**
 * __useAllFormAssignmentCompletionsQuery__
 *
 * To run a query within a React component, call `useAllFormAssignmentCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFormAssignmentCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFormAssignmentCompletionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      isCompleted: // value for 'isCompleted'
 *      sort: // value for 'sort'
 *      pk: // value for 'pk'
 *      searchInAnswers: // value for 'searchInAnswers'
 *      lastUpdatedDateStart: // value for 'lastUpdatedDateStart'
 *      lastUpdatedDateEnd: // value for 'lastUpdatedDateEnd'
 *      submittableFormAssignmentId: // value for 'submittableFormAssignmentId'
 *      teamId: // value for 'teamId'
 *      hasAccessibleAnswers: // value for 'hasAccessibleAnswers'
 *   },
 * });
 */
export function useAllFormAssignmentCompletionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllFormAssignmentCompletionsQuery,
    AllFormAssignmentCompletionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllFormAssignmentCompletionsQuery,
    AllFormAssignmentCompletionsQueryVariables
  >(AllFormAssignmentCompletionsDocument, options);
}
export function useAllFormAssignmentCompletionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllFormAssignmentCompletionsQuery,
    AllFormAssignmentCompletionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllFormAssignmentCompletionsQuery,
    AllFormAssignmentCompletionsQueryVariables
  >(AllFormAssignmentCompletionsDocument, options);
}
export type AllFormAssignmentCompletionsQueryHookResult = ReturnType<
  typeof useAllFormAssignmentCompletionsQuery
>;
export type AllFormAssignmentCompletionsLazyQueryHookResult = ReturnType<
  typeof useAllFormAssignmentCompletionsLazyQuery
>;
export type AllFormAssignmentCompletionsQueryResult = Apollo.QueryResult<
  AllFormAssignmentCompletionsQuery,
  AllFormAssignmentCompletionsQueryVariables
>;
export function refetchAllFormAssignmentCompletionsQuery(
  variables: AllFormAssignmentCompletionsQueryVariables,
) {
  return { query: AllFormAssignmentCompletionsDocument, variables: variables };
}
export const FormAssignmentCompletionBulkManuallyCompleteDocument = gql`
  mutation FormAssignmentCompletionBulkManuallyComplete(
    $input: FormAssignmentCompletionBulkManualCompleteInput!
  ) {
    formAssignmentCompletionBulkManualComplete(input: $input) {
      formAssignmentCompletion {
        id
        isCompleted
        manuallyCompleted
        manuallyCompletedExplanation
        status
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FormAssignmentCompletionBulkManuallyCompleteMutationFn = Apollo.MutationFunction<
  FormAssignmentCompletionBulkManuallyCompleteMutation,
  FormAssignmentCompletionBulkManuallyCompleteMutationVariables
>;

/**
 * __useFormAssignmentCompletionBulkManuallyCompleteMutation__
 *
 * To run a mutation, you first call `useFormAssignmentCompletionBulkManuallyCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormAssignmentCompletionBulkManuallyCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formAssignmentCompletionBulkManuallyCompleteMutation, { data, loading, error }] = useFormAssignmentCompletionBulkManuallyCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormAssignmentCompletionBulkManuallyCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FormAssignmentCompletionBulkManuallyCompleteMutation,
    FormAssignmentCompletionBulkManuallyCompleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FormAssignmentCompletionBulkManuallyCompleteMutation,
    FormAssignmentCompletionBulkManuallyCompleteMutationVariables
  >(FormAssignmentCompletionBulkManuallyCompleteDocument, options);
}
export type FormAssignmentCompletionBulkManuallyCompleteMutationHookResult = ReturnType<
  typeof useFormAssignmentCompletionBulkManuallyCompleteMutation
>;
export type FormAssignmentCompletionBulkManuallyCompleteMutationResult =
  Apollo.MutationResult<FormAssignmentCompletionBulkManuallyCompleteMutation>;
export type FormAssignmentCompletionBulkManuallyCompleteMutationOptions =
  Apollo.BaseMutationOptions<
    FormAssignmentCompletionBulkManuallyCompleteMutation,
    FormAssignmentCompletionBulkManuallyCompleteMutationVariables
  >;
export const FetchCompletedFormResultsDocument = gql`
  query FetchCompletedFormResults(
    $isCompleted: TWFilter_Boolean
    $submittableFormAssignmentId: ID!
    $teamId: Int
    $searchInAnswers: String
    $lastUpdatedDateStart: Date
    $lastUpdatedDateEnd: Date
  ) {
    formAssignmentCompletions(
      isCompleted: $isCompleted
      formAssignmentId: $submittableFormAssignmentId
      lastUpdatedDateStart: $lastUpdatedDateStart
      lastUpdatedDateEnd: $lastUpdatedDateEnd
      searchInAnswers: $searchInAnswers
      teamId: $teamId
    ) {
      edges {
        node {
          completedDateTime
          manuallyCompleted
          workflow {
            completedDateTime
            person {
              firstName
              lastName
            }
            externalName
            externalEmail
            status
          }
          parentCompletionId
          pk
          signedDownloadUrl
          key: id
        }
      }
    }
  }
`;

/**
 * __useFetchCompletedFormResultsQuery__
 *
 * To run a query within a React component, call `useFetchCompletedFormResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompletedFormResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompletedFormResultsQuery({
 *   variables: {
 *      isCompleted: // value for 'isCompleted'
 *      submittableFormAssignmentId: // value for 'submittableFormAssignmentId'
 *      teamId: // value for 'teamId'
 *      searchInAnswers: // value for 'searchInAnswers'
 *      lastUpdatedDateStart: // value for 'lastUpdatedDateStart'
 *      lastUpdatedDateEnd: // value for 'lastUpdatedDateEnd'
 *   },
 * });
 */
export function useFetchCompletedFormResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCompletedFormResultsQuery,
    FetchCompletedFormResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCompletedFormResultsQuery, FetchCompletedFormResultsQueryVariables>(
    FetchCompletedFormResultsDocument,
    options,
  );
}
export function useFetchCompletedFormResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCompletedFormResultsQuery,
    FetchCompletedFormResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchCompletedFormResultsQuery,
    FetchCompletedFormResultsQueryVariables
  >(FetchCompletedFormResultsDocument, options);
}
export type FetchCompletedFormResultsQueryHookResult = ReturnType<
  typeof useFetchCompletedFormResultsQuery
>;
export type FetchCompletedFormResultsLazyQueryHookResult = ReturnType<
  typeof useFetchCompletedFormResultsLazyQuery
>;
export type FetchCompletedFormResultsQueryResult = Apollo.QueryResult<
  FetchCompletedFormResultsQuery,
  FetchCompletedFormResultsQueryVariables
>;
export function refetchFetchCompletedFormResultsQuery(
  variables: FetchCompletedFormResultsQueryVariables,
) {
  return { query: FetchCompletedFormResultsDocument, variables: variables };
}
export const FetchFormAnswersDocument = gql`
  query FetchFormAnswers(
    $formAssignmentCompletionId: ID!
    $sort: [FormAssignmentCompletionAnswerSortEnum]
  ) {
    formAssignmentCompletionAnswers(
      formAssignmentCompletionId: $formAssignmentCompletionId
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          pk
          id
          fieldOrder
          fieldName
          fieldAnswerList
          fieldType
        }
      }
      unansweredQuestions {
        fieldOrder
        fieldName
        fieldAnswerList
      }
    }
  }
`;

/**
 * __useFetchFormAnswersQuery__
 *
 * To run a query within a React component, call `useFetchFormAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFormAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFormAnswersQuery({
 *   variables: {
 *      formAssignmentCompletionId: // value for 'formAssignmentCompletionId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchFormAnswersQuery(
  baseOptions: Apollo.QueryHookOptions<FetchFormAnswersQuery, FetchFormAnswersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchFormAnswersQuery, FetchFormAnswersQueryVariables>(
    FetchFormAnswersDocument,
    options,
  );
}
export function useFetchFormAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchFormAnswersQuery, FetchFormAnswersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchFormAnswersQuery, FetchFormAnswersQueryVariables>(
    FetchFormAnswersDocument,
    options,
  );
}
export type FetchFormAnswersQueryHookResult = ReturnType<typeof useFetchFormAnswersQuery>;
export type FetchFormAnswersLazyQueryHookResult = ReturnType<typeof useFetchFormAnswersLazyQuery>;
export type FetchFormAnswersQueryResult = Apollo.QueryResult<
  FetchFormAnswersQuery,
  FetchFormAnswersQueryVariables
>;
export function refetchFetchFormAnswersQuery(variables: FetchFormAnswersQueryVariables) {
  return { query: FetchFormAnswersDocument, variables: variables };
}
export const FetchFormAssigneesDocument = gql`
  query FetchFormAssignees(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $submittableFormAssignmentId: ID!
  ) {
    formAssignmentCompletions(
      after: $after
      before: $before
      first: $first
      last: $last
      formAssignmentId: $submittableFormAssignmentId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          key: id
          isCompleted
          completionId: pk
          workflow {
            pk
            person {
              pk
              displayName
              picture: pictureUrl
            }
            externalName
            externalEmail
            roleOrder
            roleName
          }
        }
      }
    }
  }
`;

/**
 * __useFetchFormAssigneesQuery__
 *
 * To run a query within a React component, call `useFetchFormAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFormAssigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFormAssigneesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      submittableFormAssignmentId: // value for 'submittableFormAssignmentId'
 *   },
 * });
 */
export function useFetchFormAssigneesQuery(
  baseOptions: Apollo.QueryHookOptions<FetchFormAssigneesQuery, FetchFormAssigneesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchFormAssigneesQuery, FetchFormAssigneesQueryVariables>(
    FetchFormAssigneesDocument,
    options,
  );
}
export function useFetchFormAssigneesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchFormAssigneesQuery,
    FetchFormAssigneesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchFormAssigneesQuery, FetchFormAssigneesQueryVariables>(
    FetchFormAssigneesDocument,
    options,
  );
}
export type FetchFormAssigneesQueryHookResult = ReturnType<typeof useFetchFormAssigneesQuery>;
export type FetchFormAssigneesLazyQueryHookResult = ReturnType<
  typeof useFetchFormAssigneesLazyQuery
>;
export type FetchFormAssigneesQueryResult = Apollo.QueryResult<
  FetchFormAssigneesQuery,
  FetchFormAssigneesQueryVariables
>;
export function refetchFetchFormAssigneesQuery(variables: FetchFormAssigneesQueryVariables) {
  return { query: FetchFormAssigneesDocument, variables: variables };
}
export const FormAssignmentCompletionsDocument = gql`
  query FormAssignmentCompletions(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $isCompleted: TWFilter_Boolean
    $sort: [FormAssignmentCompletionSortEnum]
    $pk: TWFilter_String
    $searchInAnswers: String
    $lastUpdatedDateStart: Date
    $lastUpdatedDateEnd: Date
    $submittableFormAssignmentId: ID!
    $teamId: Int
    $manuallyCompleted: TWFilter_Boolean
  ) {
    formAssignmentCompletions(
      after: $after
      before: $before
      first: $first
      last: $last
      isCompleted: $isCompleted
      sort: $sort
      pk: $pk
      lastUpdatedDateStart: $lastUpdatedDateStart
      lastUpdatedDateEnd: $lastUpdatedDateEnd
      searchInAnswers: $searchInAnswers
      formAssignmentId: $submittableFormAssignmentId
      teamId: $teamId
      manuallyCompleted: $manuallyCompleted
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          signedDownloadUrl
          key: id
          pk
          completedDateTime
          status
          hasFlaggedAnswers
          isCompleted
          isMostRecent
          isLinkCompletion
          hasBeenReassigned
          manuallyCompleted
          manuallyCompletedExplanation
          assignment {
            assignmentType
            assignedBy {
              pk
            }
            assignees {
              label
              selectionCode
              peopleCount
              pluralLabel
              groupType
            }
          }
          workflow {
            id
            pk
            status
            assignedDateTime
            completedDateTime
            updatedDate
            person {
              pk
              displayName
              emailAddress
              initials
              picture: pictureUrl
            }
            externalName
            externalEmail
            roleOrder
            roleName
          }
        }
      }
    }
  }
`;

/**
 * __useFormAssignmentCompletionsQuery__
 *
 * To run a query within a React component, call `useFormAssignmentCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormAssignmentCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormAssignmentCompletionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      isCompleted: // value for 'isCompleted'
 *      sort: // value for 'sort'
 *      pk: // value for 'pk'
 *      searchInAnswers: // value for 'searchInAnswers'
 *      lastUpdatedDateStart: // value for 'lastUpdatedDateStart'
 *      lastUpdatedDateEnd: // value for 'lastUpdatedDateEnd'
 *      submittableFormAssignmentId: // value for 'submittableFormAssignmentId'
 *      teamId: // value for 'teamId'
 *      manuallyCompleted: // value for 'manuallyCompleted'
 *   },
 * });
 */
export function useFormAssignmentCompletionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FormAssignmentCompletionsQuery,
    FormAssignmentCompletionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormAssignmentCompletionsQuery, FormAssignmentCompletionsQueryVariables>(
    FormAssignmentCompletionsDocument,
    options,
  );
}
export function useFormAssignmentCompletionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormAssignmentCompletionsQuery,
    FormAssignmentCompletionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FormAssignmentCompletionsQuery,
    FormAssignmentCompletionsQueryVariables
  >(FormAssignmentCompletionsDocument, options);
}
export type FormAssignmentCompletionsQueryHookResult = ReturnType<
  typeof useFormAssignmentCompletionsQuery
>;
export type FormAssignmentCompletionsLazyQueryHookResult = ReturnType<
  typeof useFormAssignmentCompletionsLazyQuery
>;
export type FormAssignmentCompletionsQueryResult = Apollo.QueryResult<
  FormAssignmentCompletionsQuery,
  FormAssignmentCompletionsQueryVariables
>;
export function refetchFormAssignmentCompletionsQuery(
  variables: FormAssignmentCompletionsQueryVariables,
) {
  return { query: FormAssignmentCompletionsDocument, variables: variables };
}
export const FetchResultsDetailsDocument = gql`
  query FetchResultsDetails($pk: TWFilter_String) {
    manageFormAssignments(pk: $pk) {
      edges {
        node {
          id
          key: pk
          formId: pk
          assignedDateTime
          deactivationDateTime
          dueDate
          label
          formLabel
          isDocusignForm
          notes
          assignmentType
          isActive
          isScheduled
          hasExternalAssignees
          form {
            pk
            docusignTemplateId
          }
          assignedBy {
            id
            displayName
            initials
            picture: pictureUrl
          }
          assignmentRoles {
            pk
            roleName
            roleOrder
          }
          assignees {
            label
            selectionCode
            peopleCount
            pluralLabel
            groupType
          }
        }
      }
    }
  }
`;

/**
 * __useFetchResultsDetailsQuery__
 *
 * To run a query within a React component, call `useFetchResultsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchResultsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchResultsDetailsQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useFetchResultsDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchResultsDetailsQuery,
    FetchResultsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchResultsDetailsQuery, FetchResultsDetailsQueryVariables>(
    FetchResultsDetailsDocument,
    options,
  );
}
export function useFetchResultsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchResultsDetailsQuery,
    FetchResultsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchResultsDetailsQuery, FetchResultsDetailsQueryVariables>(
    FetchResultsDetailsDocument,
    options,
  );
}
export type FetchResultsDetailsQueryHookResult = ReturnType<typeof useFetchResultsDetailsQuery>;
export type FetchResultsDetailsLazyQueryHookResult = ReturnType<
  typeof useFetchResultsDetailsLazyQuery
>;
export type FetchResultsDetailsQueryResult = Apollo.QueryResult<
  FetchResultsDetailsQuery,
  FetchResultsDetailsQueryVariables
>;
export function refetchFetchResultsDetailsQuery(variables?: FetchResultsDetailsQueryVariables) {
  return { query: FetchResultsDetailsDocument, variables: variables };
}
export const FormResultsReportDocument = gql`
  query FormResultsReport {
    formResultsReport {
      edges {
        node {
          createdDate
          downloadableFileId
          errorMessage
          kind
          status
          title
          url
        }
      }
    }
  }
`;

/**
 * __useFormResultsReportQuery__
 *
 * To run a query within a React component, call `useFormResultsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormResultsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormResultsReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormResultsReportQuery(
  baseOptions?: Apollo.QueryHookOptions<FormResultsReportQuery, FormResultsReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormResultsReportQuery, FormResultsReportQueryVariables>(
    FormResultsReportDocument,
    options,
  );
}
export function useFormResultsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormResultsReportQuery,
    FormResultsReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormResultsReportQuery, FormResultsReportQueryVariables>(
    FormResultsReportDocument,
    options,
  );
}
export type FormResultsReportQueryHookResult = ReturnType<typeof useFormResultsReportQuery>;
export type FormResultsReportLazyQueryHookResult = ReturnType<typeof useFormResultsReportLazyQuery>;
export type FormResultsReportQueryResult = Apollo.QueryResult<
  FormResultsReportQuery,
  FormResultsReportQueryVariables
>;
export function refetchFormResultsReportQuery(variables?: FormResultsReportQueryVariables) {
  return { query: FormResultsReportDocument, variables: variables };
}
export const FormResultsReportCreateDocument = gql`
  mutation FormResultsReportCreate($input: FormResultsReportCreateInput) {
    formResultsReportCreate(input: $input) {
      formResultsReport {
        createdDate
        downloadableFileId
        status
        title
        url
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FormResultsReportCreateMutationFn = Apollo.MutationFunction<
  FormResultsReportCreateMutation,
  FormResultsReportCreateMutationVariables
>;

/**
 * __useFormResultsReportCreateMutation__
 *
 * To run a mutation, you first call `useFormResultsReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormResultsReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formResultsReportCreateMutation, { data, loading, error }] = useFormResultsReportCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormResultsReportCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FormResultsReportCreateMutation,
    FormResultsReportCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FormResultsReportCreateMutation,
    FormResultsReportCreateMutationVariables
  >(FormResultsReportCreateDocument, options);
}
export type FormResultsReportCreateMutationHookResult = ReturnType<
  typeof useFormResultsReportCreateMutation
>;
export type FormResultsReportCreateMutationResult =
  Apollo.MutationResult<FormResultsReportCreateMutation>;
export type FormResultsReportCreateMutationOptions = Apollo.BaseMutationOptions<
  FormResultsReportCreateMutation,
  FormResultsReportCreateMutationVariables
>;
export const FormAssignmentCompletionManualCompleteDocument = gql`
  mutation FormAssignmentCompletionManualComplete($id: ID!, $explanation: String!) {
    formAssignmentCompletionManualComplete(
      id: $id
      input: { manuallyCompletedExplanation: $explanation }
    ) {
      userErrors {
        message
        field
      }
      formAssignmentCompletion {
        isCompleted
        manuallyCompleted
      }
    }
  }
`;
export type FormAssignmentCompletionManualCompleteMutationFn = Apollo.MutationFunction<
  FormAssignmentCompletionManualCompleteMutation,
  FormAssignmentCompletionManualCompleteMutationVariables
>;

/**
 * __useFormAssignmentCompletionManualCompleteMutation__
 *
 * To run a mutation, you first call `useFormAssignmentCompletionManualCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormAssignmentCompletionManualCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formAssignmentCompletionManualCompleteMutation, { data, loading, error }] = useFormAssignmentCompletionManualCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      explanation: // value for 'explanation'
 *   },
 * });
 */
export function useFormAssignmentCompletionManualCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FormAssignmentCompletionManualCompleteMutation,
    FormAssignmentCompletionManualCompleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FormAssignmentCompletionManualCompleteMutation,
    FormAssignmentCompletionManualCompleteMutationVariables
  >(FormAssignmentCompletionManualCompleteDocument, options);
}
export type FormAssignmentCompletionManualCompleteMutationHookResult = ReturnType<
  typeof useFormAssignmentCompletionManualCompleteMutation
>;
export type FormAssignmentCompletionManualCompleteMutationResult =
  Apollo.MutationResult<FormAssignmentCompletionManualCompleteMutation>;
export type FormAssignmentCompletionManualCompleteMutationOptions = Apollo.BaseMutationOptions<
  FormAssignmentCompletionManualCompleteMutation,
  FormAssignmentCompletionManualCompleteMutationVariables
>;
export const FetchOrgTeamsDocument = gql`
  query FetchOrgTeams(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [TeamSortEnum]
  ) {
    teams(after: $after, before: $before, first: $first, last: $last, sort: $sort) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          key: id
          label
          teamId
          sportId
        }
      }
    }
  }
`;

/**
 * __useFetchOrgTeamsQuery__
 *
 * To run a query within a React component, call `useFetchOrgTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrgTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrgTeamsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchOrgTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchOrgTeamsQuery, FetchOrgTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchOrgTeamsQuery, FetchOrgTeamsQueryVariables>(
    FetchOrgTeamsDocument,
    options,
  );
}
export function useFetchOrgTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchOrgTeamsQuery, FetchOrgTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchOrgTeamsQuery, FetchOrgTeamsQueryVariables>(
    FetchOrgTeamsDocument,
    options,
  );
}
export type FetchOrgTeamsQueryHookResult = ReturnType<typeof useFetchOrgTeamsQuery>;
export type FetchOrgTeamsLazyQueryHookResult = ReturnType<typeof useFetchOrgTeamsLazyQuery>;
export type FetchOrgTeamsQueryResult = Apollo.QueryResult<
  FetchOrgTeamsQuery,
  FetchOrgTeamsQueryVariables
>;
export function refetchFetchOrgTeamsQuery(variables?: FetchOrgTeamsQueryVariables) {
  return { query: FetchOrgTeamsDocument, variables: variables };
}
export const FormAssignmentReassignDocument = gql`
  mutation FormAssignmentReassign($id: ID!, $notes: String) {
    formAssignmentCompletionReassign(id: $id, input: { notes: $notes }) {
      userErrors {
        message
        field
      }
      formAssignmentCompletion {
        pk
      }
    }
  }
`;
export type FormAssignmentReassignMutationFn = Apollo.MutationFunction<
  FormAssignmentReassignMutation,
  FormAssignmentReassignMutationVariables
>;

/**
 * __useFormAssignmentReassignMutation__
 *
 * To run a mutation, you first call `useFormAssignmentReassignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormAssignmentReassignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formAssignmentReassignMutation, { data, loading, error }] = useFormAssignmentReassignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useFormAssignmentReassignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FormAssignmentReassignMutation,
    FormAssignmentReassignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FormAssignmentReassignMutation,
    FormAssignmentReassignMutationVariables
  >(FormAssignmentReassignDocument, options);
}
export type FormAssignmentReassignMutationHookResult = ReturnType<
  typeof useFormAssignmentReassignMutation
>;
export type FormAssignmentReassignMutationResult =
  Apollo.MutationResult<FormAssignmentReassignMutation>;
export type FormAssignmentReassignMutationOptions = Apollo.BaseMutationOptions<
  FormAssignmentReassignMutation,
  FormAssignmentReassignMutationVariables
>;
export const SendIndividualReminderDocument = gql`
  mutation sendIndividualReminder($id: ID!, $reminderType: FormReminderTypeGraphene!) {
    formAssignmentCompletionRoleSendReminder(id: $id, input: { reminderType: $reminderType }) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type SendIndividualReminderMutationFn = Apollo.MutationFunction<
  SendIndividualReminderMutation,
  SendIndividualReminderMutationVariables
>;

/**
 * __useSendIndividualReminderMutation__
 *
 * To run a mutation, you first call `useSendIndividualReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendIndividualReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendIndividualReminderMutation, { data, loading, error }] = useSendIndividualReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reminderType: // value for 'reminderType'
 *   },
 * });
 */
export function useSendIndividualReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendIndividualReminderMutation,
    SendIndividualReminderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendIndividualReminderMutation,
    SendIndividualReminderMutationVariables
  >(SendIndividualReminderDocument, options);
}
export type SendIndividualReminderMutationHookResult = ReturnType<
  typeof useSendIndividualReminderMutation
>;
export type SendIndividualReminderMutationResult =
  Apollo.MutationResult<SendIndividualReminderMutation>;
export type SendIndividualReminderMutationOptions = Apollo.BaseMutationOptions<
  SendIndividualReminderMutation,
  SendIndividualReminderMutationVariables
>;
export const FormAssignmentSendRemindersAllDocument = gql`
  mutation formAssignmentSendRemindersAll($id: ID!, $reminderType: FormReminderTypeGraphene!) {
    formAssignmentSendReminders(id: $id, input: { reminderType: $reminderType }) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type FormAssignmentSendRemindersAllMutationFn = Apollo.MutationFunction<
  FormAssignmentSendRemindersAllMutation,
  FormAssignmentSendRemindersAllMutationVariables
>;

/**
 * __useFormAssignmentSendRemindersAllMutation__
 *
 * To run a mutation, you first call `useFormAssignmentSendRemindersAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormAssignmentSendRemindersAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formAssignmentSendRemindersAllMutation, { data, loading, error }] = useFormAssignmentSendRemindersAllMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reminderType: // value for 'reminderType'
 *   },
 * });
 */
export function useFormAssignmentSendRemindersAllMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FormAssignmentSendRemindersAllMutation,
    FormAssignmentSendRemindersAllMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FormAssignmentSendRemindersAllMutation,
    FormAssignmentSendRemindersAllMutationVariables
  >(FormAssignmentSendRemindersAllDocument, options);
}
export type FormAssignmentSendRemindersAllMutationHookResult = ReturnType<
  typeof useFormAssignmentSendRemindersAllMutation
>;
export type FormAssignmentSendRemindersAllMutationResult =
  Apollo.MutationResult<FormAssignmentSendRemindersAllMutation>;
export type FormAssignmentSendRemindersAllMutationOptions = Apollo.BaseMutationOptions<
  FormAssignmentSendRemindersAllMutation,
  FormAssignmentSendRemindersAllMutationVariables
>;
export const FormsCreateFolderDocument = gql`
  mutation FormsCreateFolder($folderName: String!, $folderType: FolderTypeGraphene!) {
    folderCreate(input: { folderName: $folderName, folderType: $folderType }) {
      folder {
        orgId
        folderName
        folderType
        sortOrder
      }
    }
  }
`;
export type FormsCreateFolderMutationFn = Apollo.MutationFunction<
  FormsCreateFolderMutation,
  FormsCreateFolderMutationVariables
>;

/**
 * __useFormsCreateFolderMutation__
 *
 * To run a mutation, you first call `useFormsCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormsCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formsCreateFolderMutation, { data, loading, error }] = useFormsCreateFolderMutation({
 *   variables: {
 *      folderName: // value for 'folderName'
 *      folderType: // value for 'folderType'
 *   },
 * });
 */
export function useFormsCreateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FormsCreateFolderMutation,
    FormsCreateFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FormsCreateFolderMutation, FormsCreateFolderMutationVariables>(
    FormsCreateFolderDocument,
    options,
  );
}
export type FormsCreateFolderMutationHookResult = ReturnType<typeof useFormsCreateFolderMutation>;
export type FormsCreateFolderMutationResult = Apollo.MutationResult<FormsCreateFolderMutation>;
export type FormsCreateFolderMutationOptions = Apollo.BaseMutationOptions<
  FormsCreateFolderMutation,
  FormsCreateFolderMutationVariables
>;
export const BulkDeactivateAssignmentsDocument = gql`
  mutation BulkDeactivateAssignments($input: BulkDeactivateAssignmentArgsInput!) {
    formAssignmentBulkDeactivate(input: $input) {
      formAssignment {
        id
        isActive
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type BulkDeactivateAssignmentsMutationFn = Apollo.MutationFunction<
  BulkDeactivateAssignmentsMutation,
  BulkDeactivateAssignmentsMutationVariables
>;

/**
 * __useBulkDeactivateAssignmentsMutation__
 *
 * To run a mutation, you first call `useBulkDeactivateAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeactivateAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeactivateAssignmentsMutation, { data, loading, error }] = useBulkDeactivateAssignmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkDeactivateAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkDeactivateAssignmentsMutation,
    BulkDeactivateAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkDeactivateAssignmentsMutation,
    BulkDeactivateAssignmentsMutationVariables
  >(BulkDeactivateAssignmentsDocument, options);
}
export type BulkDeactivateAssignmentsMutationHookResult = ReturnType<
  typeof useBulkDeactivateAssignmentsMutation
>;
export type BulkDeactivateAssignmentsMutationResult =
  Apollo.MutationResult<BulkDeactivateAssignmentsMutation>;
export type BulkDeactivateAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  BulkDeactivateAssignmentsMutation,
  BulkDeactivateAssignmentsMutationVariables
>;
export const FolderDeleteByIdDocument = gql`
  mutation FolderDeleteById($id: ID!) {
    folderDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type FolderDeleteByIdMutationFn = Apollo.MutationFunction<
  FolderDeleteByIdMutation,
  FolderDeleteByIdMutationVariables
>;

/**
 * __useFolderDeleteByIdMutation__
 *
 * To run a mutation, you first call `useFolderDeleteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolderDeleteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folderDeleteByIdMutation, { data, loading, error }] = useFolderDeleteByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFolderDeleteByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FolderDeleteByIdMutation,
    FolderDeleteByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FolderDeleteByIdMutation, FolderDeleteByIdMutationVariables>(
    FolderDeleteByIdDocument,
    options,
  );
}
export type FolderDeleteByIdMutationHookResult = ReturnType<typeof useFolderDeleteByIdMutation>;
export type FolderDeleteByIdMutationResult = Apollo.MutationResult<FolderDeleteByIdMutation>;
export type FolderDeleteByIdMutationOptions = Apollo.BaseMutationOptions<
  FolderDeleteByIdMutation,
  FolderDeleteByIdMutationVariables
>;
export const FormScheduleDeleteDocument = gql`
  mutation formScheduleDelete($formScheduleId: ID!) {
    formScheduleDelete(id: $formScheduleId) {
      formSchedule {
        isActive
        pk
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type FormScheduleDeleteMutationFn = Apollo.MutationFunction<
  FormScheduleDeleteMutation,
  FormScheduleDeleteMutationVariables
>;

/**
 * __useFormScheduleDeleteMutation__
 *
 * To run a mutation, you first call `useFormScheduleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormScheduleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formScheduleDeleteMutation, { data, loading, error }] = useFormScheduleDeleteMutation({
 *   variables: {
 *      formScheduleId: // value for 'formScheduleId'
 *   },
 * });
 */
export function useFormScheduleDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FormScheduleDeleteMutation,
    FormScheduleDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FormScheduleDeleteMutation, FormScheduleDeleteMutationVariables>(
    FormScheduleDeleteDocument,
    options,
  );
}
export type FormScheduleDeleteMutationHookResult = ReturnType<typeof useFormScheduleDeleteMutation>;
export type FormScheduleDeleteMutationResult = Apollo.MutationResult<FormScheduleDeleteMutation>;
export type FormScheduleDeleteMutationOptions = Apollo.BaseMutationOptions<
  FormScheduleDeleteMutation,
  FormScheduleDeleteMutationVariables
>;
export const FormAssignmentsAssignersDocument = gql`
  query formAssignmentsAssigners(
    $isActive: Boolean
    $isScheduled: Boolean
    $isMyForms: Boolean
    $assignmentType: String
  ) {
    formAssignmentsAssigners(
      isActive: $isActive
      isScheduled: $isScheduled
      isMyForms: $isMyForms
      assignmentType: $assignmentType
    ) {
      value: pk
      label: fullName
    }
  }
`;

/**
 * __useFormAssignmentsAssignersQuery__
 *
 * To run a query within a React component, call `useFormAssignmentsAssignersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormAssignmentsAssignersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormAssignmentsAssignersQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      isScheduled: // value for 'isScheduled'
 *      isMyForms: // value for 'isMyForms'
 *      assignmentType: // value for 'assignmentType'
 *   },
 * });
 */
export function useFormAssignmentsAssignersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FormAssignmentsAssignersQuery,
    FormAssignmentsAssignersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormAssignmentsAssignersQuery, FormAssignmentsAssignersQueryVariables>(
    FormAssignmentsAssignersDocument,
    options,
  );
}
export function useFormAssignmentsAssignersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormAssignmentsAssignersQuery,
    FormAssignmentsAssignersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormAssignmentsAssignersQuery, FormAssignmentsAssignersQueryVariables>(
    FormAssignmentsAssignersDocument,
    options,
  );
}
export type FormAssignmentsAssignersQueryHookResult = ReturnType<
  typeof useFormAssignmentsAssignersQuery
>;
export type FormAssignmentsAssignersLazyQueryHookResult = ReturnType<
  typeof useFormAssignmentsAssignersLazyQuery
>;
export type FormAssignmentsAssignersQueryResult = Apollo.QueryResult<
  FormAssignmentsAssignersQuery,
  FormAssignmentsAssignersQueryVariables
>;
export function refetchFormAssignmentsAssignersQuery(
  variables?: FormAssignmentsAssignersQueryVariables,
) {
  return { query: FormAssignmentsAssignersDocument, variables: variables };
}
export const FetchFormFoldersDocument = gql`
  query FetchFormFolders($after: String, $before: String, $first: Int, $last: Int) {
    folders(
      after: $after
      before: $before
      first: $first
      last: $last
      folderType: "submittableFormAssignment"
      sort: [folder_name_asc]
    ) {
      edges {
        node {
          folderId
          folderName
          isEditableByUser
        }
      }
    }
  }
`;

/**
 * __useFetchFormFoldersQuery__
 *
 * To run a query within a React component, call `useFetchFormFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFormFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFormFoldersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useFetchFormFoldersQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchFormFoldersQuery, FetchFormFoldersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchFormFoldersQuery, FetchFormFoldersQueryVariables>(
    FetchFormFoldersDocument,
    options,
  );
}
export function useFetchFormFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchFormFoldersQuery, FetchFormFoldersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchFormFoldersQuery, FetchFormFoldersQueryVariables>(
    FetchFormFoldersDocument,
    options,
  );
}
export type FetchFormFoldersQueryHookResult = ReturnType<typeof useFetchFormFoldersQuery>;
export type FetchFormFoldersLazyQueryHookResult = ReturnType<typeof useFetchFormFoldersLazyQuery>;
export type FetchFormFoldersQueryResult = Apollo.QueryResult<
  FetchFormFoldersQuery,
  FetchFormFoldersQueryVariables
>;
export function refetchFetchFormFoldersQuery(variables?: FetchFormFoldersQueryVariables) {
  return { query: FetchFormFoldersDocument, variables: variables };
}
export const FormTemplatesCountDocument = gql`
  query FormTemplatesCount {
    formTemplates {
      totalCount
    }
  }
`;

/**
 * __useFormTemplatesCountQuery__
 *
 * To run a query within a React component, call `useFormTemplatesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTemplatesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTemplatesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormTemplatesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<FormTemplatesCountQuery, FormTemplatesCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormTemplatesCountQuery, FormTemplatesCountQueryVariables>(
    FormTemplatesCountDocument,
    options,
  );
}
export function useFormTemplatesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormTemplatesCountQuery,
    FormTemplatesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormTemplatesCountQuery, FormTemplatesCountQueryVariables>(
    FormTemplatesCountDocument,
    options,
  );
}
export type FormTemplatesCountQueryHookResult = ReturnType<typeof useFormTemplatesCountQuery>;
export type FormTemplatesCountLazyQueryHookResult = ReturnType<
  typeof useFormTemplatesCountLazyQuery
>;
export type FormTemplatesCountQueryResult = Apollo.QueryResult<
  FormTemplatesCountQuery,
  FormTemplatesCountQueryVariables
>;
export function refetchFormTemplatesCountQuery(variables?: FormTemplatesCountQueryVariables) {
  return { query: FormTemplatesCountDocument, variables: variables };
}
export const FetchMyAssignedFormsDocument = gql`
  query FetchMyAssignedForms(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $search: String
    $sort: [FormAssignmentSortEnum]
    $isActive: TWFilter_Boolean
    $folderId: TWFilter_Int
    $templateId: TWFilter_Int
    $pk: TWFilter_String
    $createdBy: TWFilter_Int
  ) {
    manageFormAssignments(
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
      search: $search
      isActive: $isActive
      formId: $templateId
      folderId: $folderId
      pk: $pk
      createdBy: $createdBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          key: pk
          formId: pk
          quickformTemplate
          assignedDateTime
          createdDate
          dueDate
          label
          formLabel
          isActive
          isEditable
          isDocusignForm
          notes
          assignmentType
          isScheduled
          assignedBy {
            id
            displayName
            initials
            picture: pictureUrl
            personId
          }
          resultsTo {
            viewResultsInTeamworks {
              ... on PersonNode {
                personId
              }
              ... on SelectionGroup {
                people {
                  personId
                }
              }
            }
          }
          additionalResultsTo {
            viewResultsInTeamworks {
              ... on PersonNode {
                personId
              }
              ... on SelectionGroup {
                people {
                  personId
                }
              }
            }
          }
          folder {
            folderId
            folderName
          }
          externalLinkUrl
        }
      }
    }
  }
`;

/**
 * __useFetchMyAssignedFormsQuery__
 *
 * To run a query within a React component, call `useFetchMyAssignedFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyAssignedFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyAssignedFormsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      isActive: // value for 'isActive'
 *      folderId: // value for 'folderId'
 *      templateId: // value for 'templateId'
 *      pk: // value for 'pk'
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useFetchMyAssignedFormsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMyAssignedFormsQuery,
    FetchMyAssignedFormsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMyAssignedFormsQuery, FetchMyAssignedFormsQueryVariables>(
    FetchMyAssignedFormsDocument,
    options,
  );
}
export function useFetchMyAssignedFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMyAssignedFormsQuery,
    FetchMyAssignedFormsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMyAssignedFormsQuery, FetchMyAssignedFormsQueryVariables>(
    FetchMyAssignedFormsDocument,
    options,
  );
}
export type FetchMyAssignedFormsQueryHookResult = ReturnType<typeof useFetchMyAssignedFormsQuery>;
export type FetchMyAssignedFormsLazyQueryHookResult = ReturnType<
  typeof useFetchMyAssignedFormsLazyQuery
>;
export type FetchMyAssignedFormsQueryResult = Apollo.QueryResult<
  FetchMyAssignedFormsQuery,
  FetchMyAssignedFormsQueryVariables
>;
export function refetchFetchMyAssignedFormsQuery(variables?: FetchMyAssignedFormsQueryVariables) {
  return { query: FetchMyAssignedFormsDocument, variables: variables };
}
export const FetchMyAssignedFormTemplatesDocument = gql`
  query FetchMyAssignedFormTemplates($after: String, $before: String, $first: Int, $last: Int) {
    formTemplates(
      after: $after
      before: $before
      first: $first
      last: $last
      inManagableAssignments: true
    ) {
      totalCount
      edges {
        node {
          templateId: pk
          label
        }
      }
    }
  }
`;

/**
 * __useFetchMyAssignedFormTemplatesQuery__
 *
 * To run a query within a React component, call `useFetchMyAssignedFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyAssignedFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyAssignedFormTemplatesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useFetchMyAssignedFormTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMyAssignedFormTemplatesQuery,
    FetchMyAssignedFormTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchMyAssignedFormTemplatesQuery,
    FetchMyAssignedFormTemplatesQueryVariables
  >(FetchMyAssignedFormTemplatesDocument, options);
}
export function useFetchMyAssignedFormTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMyAssignedFormTemplatesQuery,
    FetchMyAssignedFormTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchMyAssignedFormTemplatesQuery,
    FetchMyAssignedFormTemplatesQueryVariables
  >(FetchMyAssignedFormTemplatesDocument, options);
}
export type FetchMyAssignedFormTemplatesQueryHookResult = ReturnType<
  typeof useFetchMyAssignedFormTemplatesQuery
>;
export type FetchMyAssignedFormTemplatesLazyQueryHookResult = ReturnType<
  typeof useFetchMyAssignedFormTemplatesLazyQuery
>;
export type FetchMyAssignedFormTemplatesQueryResult = Apollo.QueryResult<
  FetchMyAssignedFormTemplatesQuery,
  FetchMyAssignedFormTemplatesQueryVariables
>;
export function refetchFetchMyAssignedFormTemplatesQuery(
  variables?: FetchMyAssignedFormTemplatesQueryVariables,
) {
  return { query: FetchMyAssignedFormTemplatesDocument, variables: variables };
}
export const ManageFormScheduleAssignmentsDocument = gql`
  query ManageFormScheduleAssignments(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $search: String
    $sort: [FormScheduleSortEnum]
    $templateId: TWFilter_Int
    $isActive: TWFilter_Boolean
    $createdBy: TWFilter_Int
    $folderId: Int
  ) {
    manageFormScheduleAssignments(
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
      search: $search
      formId: $templateId
      isActive: $isActive
      createdBy: $createdBy
      folderId: $folderId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          key: id
          formScheduleId: pk
          label
          templateLabel: formLabel
          folder {
            folderId
            folderName
          }
          recurRule
          recurRuleText
          scheduleStart
          creator {
            displayName
            initials
            picture: pictureUrl
            preferredName
            firstName
            lastName
            fullName
          }
          createdDate
          canEdit
          isDocusignForm
          parameters
          isActive
        }
      }
    }
  }
`;

/**
 * __useManageFormScheduleAssignmentsQuery__
 *
 * To run a query within a React component, call `useManageFormScheduleAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageFormScheduleAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageFormScheduleAssignmentsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      templateId: // value for 'templateId'
 *      isActive: // value for 'isActive'
 *      createdBy: // value for 'createdBy'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useManageFormScheduleAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManageFormScheduleAssignmentsQuery,
    ManageFormScheduleAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManageFormScheduleAssignmentsQuery,
    ManageFormScheduleAssignmentsQueryVariables
  >(ManageFormScheduleAssignmentsDocument, options);
}
export function useManageFormScheduleAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManageFormScheduleAssignmentsQuery,
    ManageFormScheduleAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManageFormScheduleAssignmentsQuery,
    ManageFormScheduleAssignmentsQueryVariables
  >(ManageFormScheduleAssignmentsDocument, options);
}
export type ManageFormScheduleAssignmentsQueryHookResult = ReturnType<
  typeof useManageFormScheduleAssignmentsQuery
>;
export type ManageFormScheduleAssignmentsLazyQueryHookResult = ReturnType<
  typeof useManageFormScheduleAssignmentsLazyQuery
>;
export type ManageFormScheduleAssignmentsQueryResult = Apollo.QueryResult<
  ManageFormScheduleAssignmentsQuery,
  ManageFormScheduleAssignmentsQueryVariables
>;
export function refetchManageFormScheduleAssignmentsQuery(
  variables?: ManageFormScheduleAssignmentsQueryVariables,
) {
  return { query: ManageFormScheduleAssignmentsDocument, variables: variables };
}
export const UpdateeFolderDocument = gql`
  mutation UpdateeFolder($id: ID!, $folderName: String!) {
    folderUpdate(id: $id, input: { folderName: $folderName }) {
      folder {
        orgId
        folderName
        folderType
        sortOrder
      }
    }
  }
`;
export type UpdateeFolderMutationFn = Apollo.MutationFunction<
  UpdateeFolderMutation,
  UpdateeFolderMutationVariables
>;

/**
 * __useUpdateeFolderMutation__
 *
 * To run a mutation, you first call `useUpdateeFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateeFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateeFolderMutation, { data, loading, error }] = useUpdateeFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      folderName: // value for 'folderName'
 *   },
 * });
 */
export function useUpdateeFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateeFolderMutation, UpdateeFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateeFolderMutation, UpdateeFolderMutationVariables>(
    UpdateeFolderDocument,
    options,
  );
}
export type UpdateeFolderMutationHookResult = ReturnType<typeof useUpdateeFolderMutation>;
export type UpdateeFolderMutationResult = Apollo.MutationResult<UpdateeFolderMutation>;
export type UpdateeFolderMutationOptions = Apollo.BaseMutationOptions<
  UpdateeFolderMutation,
  UpdateeFolderMutationVariables
>;
export const FetchFormCompletionByIdDocument = gql`
  query FetchFormCompletionById($id: ID!) {
    completionRole: node(id: $id) {
      ... on FormAssignmentCompletionRoleNode {
        __typename
        id
        status
        externalName
        externalEmail
        isExternalRole
        roleOrder
        roleName
        person {
          id
          displayName
          selectionCode
          label
        }
        assignmentRole {
          id
          chooserRestrictedToPersonTypes
          chooserAllowExternal
          chooserAllowUser
          roleName
          roleOrder
          roleType
        }
        completion {
          reassignmentNotes: reassignNotes
          assignment {
            id
            label
            dueDate
            notes
            isDocusignForm
            quickformTemplate
            assignerEmail
            assignedBy {
              id
              displayName
            }
          }
        }
        completionRolesAssignedByCompleter {
          id
          assignmentRole {
            id
            chooserRestrictedToPersonTypes
            chooserAllowExternal
            chooserAllowUser
            roleName
            roleOrder
          }
        }
        workflow {
          id
          status
          roleName
          roleOrder
          updatedDate
          externalName
          externalEmail
          person {
            id
            displayName
            selectionCode
          }
        }
      }
    }
  }
`;

/**
 * __useFetchFormCompletionByIdQuery__
 *
 * To run a query within a React component, call `useFetchFormCompletionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFormCompletionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFormCompletionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchFormCompletionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchFormCompletionByIdQuery,
    FetchFormCompletionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchFormCompletionByIdQuery, FetchFormCompletionByIdQueryVariables>(
    FetchFormCompletionByIdDocument,
    options,
  );
}
export function useFetchFormCompletionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchFormCompletionByIdQuery,
    FetchFormCompletionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchFormCompletionByIdQuery, FetchFormCompletionByIdQueryVariables>(
    FetchFormCompletionByIdDocument,
    options,
  );
}
export type FetchFormCompletionByIdQueryHookResult = ReturnType<
  typeof useFetchFormCompletionByIdQuery
>;
export type FetchFormCompletionByIdLazyQueryHookResult = ReturnType<
  typeof useFetchFormCompletionByIdLazyQuery
>;
export type FetchFormCompletionByIdQueryResult = Apollo.QueryResult<
  FetchFormCompletionByIdQuery,
  FetchFormCompletionByIdQueryVariables
>;
export function refetchFetchFormCompletionByIdQuery(
  variables: FetchFormCompletionByIdQueryVariables,
) {
  return { query: FetchFormCompletionByIdDocument, variables: variables };
}
export const CreatePerpetualFormCompletionDocument = gql`
  mutation CreatePerpetualFormCompletion($input: PrepareForSignatureArgsInput) {
    prepareForSignature(input: $input) {
      link
      myCompletionRole {
        id
        status
        pk
      }
      completion {
        assignment {
          label
          dueDate
          isActive
          notes
          assignmentType
          isEditable
          assignedBy {
            fullName
          }
          isDocusignForm
          quickformTemplate
        }
        completedDateTime
        formAssignmentId
        manuallyCompleted
        isCompleted
        pk
        workflow {
          externalName
          person {
            fullName
          }
        }
      }
    }
  }
`;
export type CreatePerpetualFormCompletionMutationFn = Apollo.MutationFunction<
  CreatePerpetualFormCompletionMutation,
  CreatePerpetualFormCompletionMutationVariables
>;

/**
 * __useCreatePerpetualFormCompletionMutation__
 *
 * To run a mutation, you first call `useCreatePerpetualFormCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerpetualFormCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerpetualFormCompletionMutation, { data, loading, error }] = useCreatePerpetualFormCompletionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePerpetualFormCompletionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePerpetualFormCompletionMutation,
    CreatePerpetualFormCompletionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePerpetualFormCompletionMutation,
    CreatePerpetualFormCompletionMutationVariables
  >(CreatePerpetualFormCompletionDocument, options);
}
export type CreatePerpetualFormCompletionMutationHookResult = ReturnType<
  typeof useCreatePerpetualFormCompletionMutation
>;
export type CreatePerpetualFormCompletionMutationResult =
  Apollo.MutationResult<CreatePerpetualFormCompletionMutation>;
export type CreatePerpetualFormCompletionMutationOptions = Apollo.BaseMutationOptions<
  CreatePerpetualFormCompletionMutation,
  CreatePerpetualFormCompletionMutationVariables
>;
export const FormAssignmentCompletionReassignDocument = gql`
  mutation FormAssignmentCompletionReassign(
    $id: ID!
    $input: FormAssignmentCompletionReassignInput!
  ) {
    formAssignmentCompletionReassign(id: $id, input: $input) {
      formAssignmentCompletion {
        id
        viewerAssignmentCompletionRole {
          id
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type FormAssignmentCompletionReassignMutationFn = Apollo.MutationFunction<
  FormAssignmentCompletionReassignMutation,
  FormAssignmentCompletionReassignMutationVariables
>;

/**
 * __useFormAssignmentCompletionReassignMutation__
 *
 * To run a mutation, you first call `useFormAssignmentCompletionReassignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormAssignmentCompletionReassignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formAssignmentCompletionReassignMutation, { data, loading, error }] = useFormAssignmentCompletionReassignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormAssignmentCompletionReassignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FormAssignmentCompletionReassignMutation,
    FormAssignmentCompletionReassignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FormAssignmentCompletionReassignMutation,
    FormAssignmentCompletionReassignMutationVariables
  >(FormAssignmentCompletionReassignDocument, options);
}
export type FormAssignmentCompletionReassignMutationHookResult = ReturnType<
  typeof useFormAssignmentCompletionReassignMutation
>;
export type FormAssignmentCompletionReassignMutationResult =
  Apollo.MutationResult<FormAssignmentCompletionReassignMutation>;
export type FormAssignmentCompletionReassignMutationOptions = Apollo.BaseMutationOptions<
  FormAssignmentCompletionReassignMutation,
  FormAssignmentCompletionReassignMutationVariables
>;
export const GetFormDownloadPdfDocument = gql`
  mutation GetFormDownloadPDF($id: ID!, $input: FormAssignmentCompletionFetchPdfInput!) {
    formAssignmentCompletionFetchPdf(id: $id, input: $input) {
      formAssignmentCompletion {
        id
        signedDownloadUrl
      }
    }
  }
`;
export type GetFormDownloadPdfMutationFn = Apollo.MutationFunction<
  GetFormDownloadPdfMutation,
  GetFormDownloadPdfMutationVariables
>;

/**
 * __useGetFormDownloadPdfMutation__
 *
 * To run a mutation, you first call `useGetFormDownloadPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetFormDownloadPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getFormDownloadPdfMutation, { data, loading, error }] = useGetFormDownloadPdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFormDownloadPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetFormDownloadPdfMutation,
    GetFormDownloadPdfMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetFormDownloadPdfMutation, GetFormDownloadPdfMutationVariables>(
    GetFormDownloadPdfDocument,
    options,
  );
}
export type GetFormDownloadPdfMutationHookResult = ReturnType<typeof useGetFormDownloadPdfMutation>;
export type GetFormDownloadPdfMutationResult = Apollo.MutationResult<GetFormDownloadPdfMutation>;
export type GetFormDownloadPdfMutationOptions = Apollo.BaseMutationOptions<
  GetFormDownloadPdfMutation,
  GetFormDownloadPdfMutationVariables
>;
export const DeleteFormDocument = gql`
  mutation DeleteForm($id: ID) {
    formAssignmentCompletionDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type DeleteFormMutationFn = Apollo.MutationFunction<
  DeleteFormMutation,
  DeleteFormMutationVariables
>;

/**
 * __useDeleteFormMutation__
 *
 * To run a mutation, you first call `useDeleteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormMutation, { data, loading, error }] = useDeleteFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFormMutation, DeleteFormMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFormMutation, DeleteFormMutationVariables>(
    DeleteFormDocument,
    options,
  );
}
export type DeleteFormMutationHookResult = ReturnType<typeof useDeleteFormMutation>;
export type DeleteFormMutationResult = Apollo.MutationResult<DeleteFormMutation>;
export type DeleteFormMutationOptions = Apollo.BaseMutationOptions<
  DeleteFormMutation,
  DeleteFormMutationVariables
>;
export const FetchMyFormsDocument = gql`
  query FetchMyForms(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $pk: TWFilter_String
    $status: [FormAssignmentCompletionRoleStatusGraphene]
    $search: String
    $sort: [FormAssignmentCompletionRoleSortEnum]
    $createdBy: TWFilter_Int
    $dueDateStatus: String
    $assignerIds: [Int]
    $showConditional: Boolean
  ) {
    myFormAssignments(
      after: $after
      before: $before
      first: $first
      pk: $pk
      last: $last
      status: $status
      search: $search
      sort: $sort
      createdBy: $createdBy
      dueDateStatus: $dueDateStatus
      assignerIds: $assignerIds
      showConditional: $showConditional
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        formAssignmentCompletionRole: node {
          key: id
          formId: pk
          formStatus: status
          assignedDateTime
          completedDateTime
          updatedDate
          createdBy
          assignmentRole {
            roleLabel: roleName
          }
          completion {
            hasBeenReassigned
            signedDownloadUrl
            formAssignmentCompletionId: pk
            assignment {
              form {
                formLabel: label
              }
              id
              assignmentId: pk
              formTitle: label
              dueDateTime: dueDate
              notes
              isScheduled
              isDocusignForm
              quickformTemplate
              assignmentType
              assignerEmail
              assignedBy {
                id
                pk
                displayName
                initials
                picture: pictureUrl
              }
            }
            allCompleted
            reassignmentNotes: reassignNotes
            reassignedBy
            completedDateTime
            createdDateTime
            manuallyCompleted
            manuallyCompletedBy {
              pk
              displayName
              initials
              picture: pictureUrl
            }
            creator {
              pk
              displayName
              initials
              picture: pictureUrl
            }
            workflow {
              completedDateTime
              status
              roleName
              roleOrder
              person {
                pk
              }
            }
            status
          }
        }
      }
    }
  }
`;

/**
 * __useFetchMyFormsQuery__
 *
 * To run a query within a React component, call `useFetchMyFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyFormsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      pk: // value for 'pk'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      createdBy: // value for 'createdBy'
 *      dueDateStatus: // value for 'dueDateStatus'
 *      assignerIds: // value for 'assignerIds'
 *      showConditional: // value for 'showConditional'
 *   },
 * });
 */
export function useFetchMyFormsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMyFormsQuery, FetchMyFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMyFormsQuery, FetchMyFormsQueryVariables>(
    FetchMyFormsDocument,
    options,
  );
}
export function useFetchMyFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMyFormsQuery, FetchMyFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMyFormsQuery, FetchMyFormsQueryVariables>(
    FetchMyFormsDocument,
    options,
  );
}
export type FetchMyFormsQueryHookResult = ReturnType<typeof useFetchMyFormsQuery>;
export type FetchMyFormsLazyQueryHookResult = ReturnType<typeof useFetchMyFormsLazyQuery>;
export type FetchMyFormsQueryResult = Apollo.QueryResult<
  FetchMyFormsQuery,
  FetchMyFormsQueryVariables
>;
export function refetchFetchMyFormsQuery(variables?: FetchMyFormsQueryVariables) {
  return { query: FetchMyFormsDocument, variables: variables };
}
export const FetchMyFormsCountDocument = gql`
  query FetchMyFormsCount(
    $status: [FormAssignmentCompletionRoleStatusGraphene]
    $showConditional: Boolean
  ) {
    myFormAssignments(status: $status, showConditional: $showConditional) {
      totalCount
    }
  }
`;

/**
 * __useFetchMyFormsCountQuery__
 *
 * To run a query within a React component, call `useFetchMyFormsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyFormsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyFormsCountQuery({
 *   variables: {
 *      status: // value for 'status'
 *      showConditional: // value for 'showConditional'
 *   },
 * });
 */
export function useFetchMyFormsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMyFormsCountQuery, FetchMyFormsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMyFormsCountQuery, FetchMyFormsCountQueryVariables>(
    FetchMyFormsCountDocument,
    options,
  );
}
export function useFetchMyFormsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMyFormsCountQuery,
    FetchMyFormsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMyFormsCountQuery, FetchMyFormsCountQueryVariables>(
    FetchMyFormsCountDocument,
    options,
  );
}
export type FetchMyFormsCountQueryHookResult = ReturnType<typeof useFetchMyFormsCountQuery>;
export type FetchMyFormsCountLazyQueryHookResult = ReturnType<typeof useFetchMyFormsCountLazyQuery>;
export type FetchMyFormsCountQueryResult = Apollo.QueryResult<
  FetchMyFormsCountQuery,
  FetchMyFormsCountQueryVariables
>;
export function refetchFetchMyFormsCountQuery(variables?: FetchMyFormsCountQueryVariables) {
  return { query: FetchMyFormsCountDocument, variables: variables };
}
export const FetchMyFormDetailsDocument = gql`
  query FetchMyFormDetails($pk: TWFilter_String!, $showConditional: Boolean!) {
    myFormAssignments(pk: $pk, showConditional: $showConditional) {
      edges {
        formAssignmentCompletionRole: node {
          id
          canEditResponse
          canDeleteCompletion
          status
          completion {
            isMostRecent
            id
            signedDownloadUrl
            formAssignmentCompletionId: pk
            assignment {
              form {
                formLabel: label
              }
              id
              formDescription: notes
              formTitle: label
              isDocusignForm
              assignmentType
              assignmentId: pk
              assignedBy {
                id
                pk
                displayName
                initials
                picture: pictureUrl
              }
            }
            allCompleted
            reassignmentNotes: reassignNotes
            manuallyCompleted
            manuallyCompletedBy {
              pk
              displayName
              initials
              picture: pictureUrl
              id
            }
            viewerAssignmentCompletionRole {
              id
              assignmentRole {
                id
                chooserRestrictedToPersonTypes
                chooserAllowExternal
                roleName
                roleOrder
              }
            }
          }
          workflow {
            assignedDateTime
            completedDateTime
            updatedDate
            roleOrder
            roleName
            pk
            person {
              id
              pk
              displayName
              initials
              picture: pictureUrl
            }
            externalName
            externalEmail
            id
            status
          }
          completionRolesAssignedByCompleter {
            id
            assignmentRole {
              id
              chooserAllowExternal
              chooserAllowUser
              chooserRestrictedToPersonTypes
              chooserRoleOrder
              roleName
              roleOrder
              roleType
              internalAssignees {
                label
                selectionCode
                peopleCount
                pluralLabel
                groupType
              }
              externalAssignees {
                name
                email
              }
              assignment {
                assignedBy {
                  id
                  pk
                  displayName
                  initials
                  picture: pictureUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchMyFormDetailsQuery__
 *
 * To run a query within a React component, call `useFetchMyFormDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyFormDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyFormDetailsQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *      showConditional: // value for 'showConditional'
 *   },
 * });
 */
export function useFetchMyFormDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchMyFormDetailsQuery, FetchMyFormDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMyFormDetailsQuery, FetchMyFormDetailsQueryVariables>(
    FetchMyFormDetailsDocument,
    options,
  );
}
export function useFetchMyFormDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMyFormDetailsQuery,
    FetchMyFormDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMyFormDetailsQuery, FetchMyFormDetailsQueryVariables>(
    FetchMyFormDetailsDocument,
    options,
  );
}
export type FetchMyFormDetailsQueryHookResult = ReturnType<typeof useFetchMyFormDetailsQuery>;
export type FetchMyFormDetailsLazyQueryHookResult = ReturnType<
  typeof useFetchMyFormDetailsLazyQuery
>;
export type FetchMyFormDetailsQueryResult = Apollo.QueryResult<
  FetchMyFormDetailsQuery,
  FetchMyFormDetailsQueryVariables
>;
export function refetchFetchMyFormDetailsQuery(variables: FetchMyFormDetailsQueryVariables) {
  return { query: FetchMyFormDetailsDocument, variables: variables };
}
export const FetchMyPerpetualFormsDocument = gql`
  query FetchMyPerpetualForms(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $search: String
    $sort: [FormAssignmentRoleSortEnum]
    $createdBy: TWFilter_Int
    $showConditional: Boolean
  ) {
    myPerpetualFormAssignments(
      after: $after
      before: $before
      first: $first
      last: $last
      search: $search
      sort: $sort
      createdBy: $createdBy
      showConditional: $showConditional
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        formAssignmentRole: node {
          key: id
          assignmentRoleId: pk
          roleLabel: roleName
          assignedDateTime
          assignment {
            formAssignmentId: pk
            formTitle: label
            formDescription: notes
            assignmentType
            isScheduled
            dueDateTime: dueDate
            assignedBy {
              pk
              displayName
              initials
              picture: pictureUrl
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchMyPerpetualFormsQuery__
 *
 * To run a query within a React component, call `useFetchMyPerpetualFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyPerpetualFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyPerpetualFormsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      createdBy: // value for 'createdBy'
 *      showConditional: // value for 'showConditional'
 *   },
 * });
 */
export function useFetchMyPerpetualFormsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMyPerpetualFormsQuery,
    FetchMyPerpetualFormsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMyPerpetualFormsQuery, FetchMyPerpetualFormsQueryVariables>(
    FetchMyPerpetualFormsDocument,
    options,
  );
}
export function useFetchMyPerpetualFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMyPerpetualFormsQuery,
    FetchMyPerpetualFormsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMyPerpetualFormsQuery, FetchMyPerpetualFormsQueryVariables>(
    FetchMyPerpetualFormsDocument,
    options,
  );
}
export type FetchMyPerpetualFormsQueryHookResult = ReturnType<typeof useFetchMyPerpetualFormsQuery>;
export type FetchMyPerpetualFormsLazyQueryHookResult = ReturnType<
  typeof useFetchMyPerpetualFormsLazyQuery
>;
export type FetchMyPerpetualFormsQueryResult = Apollo.QueryResult<
  FetchMyPerpetualFormsQuery,
  FetchMyPerpetualFormsQueryVariables
>;
export function refetchFetchMyPerpetualFormsQuery(variables?: FetchMyPerpetualFormsQueryVariables) {
  return { query: FetchMyPerpetualFormsDocument, variables: variables };
}
export const UserFormStatsDocument = gql`
  query UserFormStats {
    userFormStats {
      formsExpiringSoon
      formsCreatedRecently
      allActiveForms
      overdueForms
    }
  }
`;

/**
 * __useUserFormStatsQuery__
 *
 * To run a query within a React component, call `useUserFormStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFormStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFormStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFormStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserFormStatsQuery, UserFormStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserFormStatsQuery, UserFormStatsQueryVariables>(
    UserFormStatsDocument,
    options,
  );
}
export function useUserFormStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserFormStatsQuery, UserFormStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserFormStatsQuery, UserFormStatsQueryVariables>(
    UserFormStatsDocument,
    options,
  );
}
export type UserFormStatsQueryHookResult = ReturnType<typeof useUserFormStatsQuery>;
export type UserFormStatsLazyQueryHookResult = ReturnType<typeof useUserFormStatsLazyQuery>;
export type UserFormStatsQueryResult = Apollo.QueryResult<
  UserFormStatsQuery,
  UserFormStatsQueryVariables
>;
export function refetchUserFormStatsQuery(variables?: UserFormStatsQueryVariables) {
  return { query: UserFormStatsDocument, variables: variables };
}
export const QuickFormAssignmentTemplateUpdateDocument = gql`
  mutation QuickFormAssignmentTemplateUpdate($id: ID!, $input: QuickFormTemplateInput!) {
    quickFormAssignmentTemplateUpdate(id: $id, input: $input) {
      formAssignment {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type QuickFormAssignmentTemplateUpdateMutationFn = Apollo.MutationFunction<
  QuickFormAssignmentTemplateUpdateMutation,
  QuickFormAssignmentTemplateUpdateMutationVariables
>;

/**
 * __useQuickFormAssignmentTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useQuickFormAssignmentTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuickFormAssignmentTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quickFormAssignmentTemplateUpdateMutation, { data, loading, error }] = useQuickFormAssignmentTemplateUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuickFormAssignmentTemplateUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuickFormAssignmentTemplateUpdateMutation,
    QuickFormAssignmentTemplateUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuickFormAssignmentTemplateUpdateMutation,
    QuickFormAssignmentTemplateUpdateMutationVariables
  >(QuickFormAssignmentTemplateUpdateDocument, options);
}
export type QuickFormAssignmentTemplateUpdateMutationHookResult = ReturnType<
  typeof useQuickFormAssignmentTemplateUpdateMutation
>;
export type QuickFormAssignmentTemplateUpdateMutationResult =
  Apollo.MutationResult<QuickFormAssignmentTemplateUpdateMutation>;
export type QuickFormAssignmentTemplateUpdateMutationOptions = Apollo.BaseMutationOptions<
  QuickFormAssignmentTemplateUpdateMutation,
  QuickFormAssignmentTemplateUpdateMutationVariables
>;
export const QuickFormTemplateCreateDocument = gql`
  mutation QuickFormTemplateCreate($input: QuickFormTemplateInput!) {
    quickFormTemplateCreate(input: $input) {
      formTemplate {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type QuickFormTemplateCreateMutationFn = Apollo.MutationFunction<
  QuickFormTemplateCreateMutation,
  QuickFormTemplateCreateMutationVariables
>;

/**
 * __useQuickFormTemplateCreateMutation__
 *
 * To run a mutation, you first call `useQuickFormTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuickFormTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quickFormTemplateCreateMutation, { data, loading, error }] = useQuickFormTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuickFormTemplateCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuickFormTemplateCreateMutation,
    QuickFormTemplateCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuickFormTemplateCreateMutation,
    QuickFormTemplateCreateMutationVariables
  >(QuickFormTemplateCreateDocument, options);
}
export type QuickFormTemplateCreateMutationHookResult = ReturnType<
  typeof useQuickFormTemplateCreateMutation
>;
export type QuickFormTemplateCreateMutationResult =
  Apollo.MutationResult<QuickFormTemplateCreateMutation>;
export type QuickFormTemplateCreateMutationOptions = Apollo.BaseMutationOptions<
  QuickFormTemplateCreateMutation,
  QuickFormTemplateCreateMutationVariables
>;
export const QuickFormTemplateDeleteDocument = gql`
  mutation QuickFormTemplateDelete($id: ID!) {
    quickFormTemplateDelete(id: $id) {
      formTemplate {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type QuickFormTemplateDeleteMutationFn = Apollo.MutationFunction<
  QuickFormTemplateDeleteMutation,
  QuickFormTemplateDeleteMutationVariables
>;

/**
 * __useQuickFormTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useQuickFormTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuickFormTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quickFormTemplateDeleteMutation, { data, loading, error }] = useQuickFormTemplateDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuickFormTemplateDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuickFormTemplateDeleteMutation,
    QuickFormTemplateDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuickFormTemplateDeleteMutation,
    QuickFormTemplateDeleteMutationVariables
  >(QuickFormTemplateDeleteDocument, options);
}
export type QuickFormTemplateDeleteMutationHookResult = ReturnType<
  typeof useQuickFormTemplateDeleteMutation
>;
export type QuickFormTemplateDeleteMutationResult =
  Apollo.MutationResult<QuickFormTemplateDeleteMutation>;
export type QuickFormTemplateDeleteMutationOptions = Apollo.BaseMutationOptions<
  QuickFormTemplateDeleteMutation,
  QuickFormTemplateDeleteMutationVariables
>;
export const QuickFormTemplateDuplicateDocument = gql`
  mutation QuickFormTemplateDuplicate($id: ID!) {
    quickFormTemplateDuplicate(id: $id) {
      formTemplate {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type QuickFormTemplateDuplicateMutationFn = Apollo.MutationFunction<
  QuickFormTemplateDuplicateMutation,
  QuickFormTemplateDuplicateMutationVariables
>;

/**
 * __useQuickFormTemplateDuplicateMutation__
 *
 * To run a mutation, you first call `useQuickFormTemplateDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuickFormTemplateDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quickFormTemplateDuplicateMutation, { data, loading, error }] = useQuickFormTemplateDuplicateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuickFormTemplateDuplicateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuickFormTemplateDuplicateMutation,
    QuickFormTemplateDuplicateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuickFormTemplateDuplicateMutation,
    QuickFormTemplateDuplicateMutationVariables
  >(QuickFormTemplateDuplicateDocument, options);
}
export type QuickFormTemplateDuplicateMutationHookResult = ReturnType<
  typeof useQuickFormTemplateDuplicateMutation
>;
export type QuickFormTemplateDuplicateMutationResult =
  Apollo.MutationResult<QuickFormTemplateDuplicateMutation>;
export type QuickFormTemplateDuplicateMutationOptions = Apollo.BaseMutationOptions<
  QuickFormTemplateDuplicateMutation,
  QuickFormTemplateDuplicateMutationVariables
>;
export const GetQuickFormTemplateEditDocument = gql`
  query GetQuickFormTemplateEdit($search: String, $createdBy: TWFilter_Int, $pk: TWFilter_String) {
    formTemplates(formType: QUICKFORM, search: $search, createdBy: $createdBy, pk: $pk) {
      totalCount
      edges {
        formTemplate: node {
          id
          pk
          label
          quickformTemplate
          canDelete
          canEdit
          createdDate
          creator {
            personId
            displayName
            initials
            pictureUrl
            hasLoginAccess
          }
        }
      }
    }
  }
`;

/**
 * __useGetQuickFormTemplateEditQuery__
 *
 * To run a query within a React component, call `useGetQuickFormTemplateEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickFormTemplateEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickFormTemplateEditQuery({
 *   variables: {
 *      search: // value for 'search'
 *      createdBy: // value for 'createdBy'
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useGetQuickFormTemplateEditQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetQuickFormTemplateEditQuery,
    GetQuickFormTemplateEditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQuickFormTemplateEditQuery, GetQuickFormTemplateEditQueryVariables>(
    GetQuickFormTemplateEditDocument,
    options,
  );
}
export function useGetQuickFormTemplateEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuickFormTemplateEditQuery,
    GetQuickFormTemplateEditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQuickFormTemplateEditQuery, GetQuickFormTemplateEditQueryVariables>(
    GetQuickFormTemplateEditDocument,
    options,
  );
}
export type GetQuickFormTemplateEditQueryHookResult = ReturnType<
  typeof useGetQuickFormTemplateEditQuery
>;
export type GetQuickFormTemplateEditLazyQueryHookResult = ReturnType<
  typeof useGetQuickFormTemplateEditLazyQuery
>;
export type GetQuickFormTemplateEditQueryResult = Apollo.QueryResult<
  GetQuickFormTemplateEditQuery,
  GetQuickFormTemplateEditQueryVariables
>;
export function refetchGetQuickFormTemplateEditQuery(
  variables?: GetQuickFormTemplateEditQueryVariables,
) {
  return { query: GetQuickFormTemplateEditDocument, variables: variables };
}
export const QuickFormTemplateUpdateDocument = gql`
  mutation QuickFormTemplateUpdate($id: ID!, $input: QuickFormTemplateInput!) {
    quickFormTemplateUpdate(id: $id, input: $input) {
      formTemplate {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type QuickFormTemplateUpdateMutationFn = Apollo.MutationFunction<
  QuickFormTemplateUpdateMutation,
  QuickFormTemplateUpdateMutationVariables
>;

/**
 * __useQuickFormTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useQuickFormTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuickFormTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quickFormTemplateUpdateMutation, { data, loading, error }] = useQuickFormTemplateUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuickFormTemplateUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuickFormTemplateUpdateMutation,
    QuickFormTemplateUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuickFormTemplateUpdateMutation,
    QuickFormTemplateUpdateMutationVariables
  >(QuickFormTemplateUpdateDocument, options);
}
export type QuickFormTemplateUpdateMutationHookResult = ReturnType<
  typeof useQuickFormTemplateUpdateMutation
>;
export type QuickFormTemplateUpdateMutationResult =
  Apollo.MutationResult<QuickFormTemplateUpdateMutation>;
export type QuickFormTemplateUpdateMutationOptions = Apollo.BaseMutationOptions<
  QuickFormTemplateUpdateMutation,
  QuickFormTemplateUpdateMutationVariables
>;
export const GetTemplatesDocument = gql`
  query GetTemplates(
    $sort: [FormSortEnum]
    $search: String
    $createdBy: TWFilter_Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $formType: FormTypeEnum
  ) {
    formTemplates(
      formType: $formType
      before: $before
      after: $after
      first: $first
      last: $last
      sort: $sort
      search: $search
      createdBy: $createdBy
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        formTemplate: node {
          id
          label
          quickformTemplate
          isDocusignForm
          serverFileName
          canDelete
          canEdit
          createdDate
          creator {
            personId
            displayName
            initials
            pictureUrl
            hasLoginAccess
          }
          pk
        }
      }
    }
  }
`;

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      createdBy: // value for 'createdBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      formType: // value for 'formType'
 *   },
 * });
 */
export function useGetTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(
    GetTemplatesDocument,
    options,
  );
}
export function useGetTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(
    GetTemplatesDocument,
    options,
  );
}
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesQueryResult = Apollo.QueryResult<
  GetTemplatesQuery,
  GetTemplatesQueryVariables
>;
export function refetchGetTemplatesQuery(variables?: GetTemplatesQueryVariables) {
  return { query: GetTemplatesDocument, variables: variables };
}
export const QuickFormTemplateCreatorsDocument = gql`
  query QuickFormTemplateCreators($before: String, $after: String, $first: Int, $last: Int) {
    formTemplates(formType: QUICKFORM, before: $before, after: $after, first: $first, last: $last) {
      edges {
        formTemplate: node {
          creator {
            personId
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useQuickFormTemplateCreatorsQuery__
 *
 * To run a query within a React component, call `useQuickFormTemplateCreatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickFormTemplateCreatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickFormTemplateCreatorsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useQuickFormTemplateCreatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QuickFormTemplateCreatorsQuery,
    QuickFormTemplateCreatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuickFormTemplateCreatorsQuery, QuickFormTemplateCreatorsQueryVariables>(
    QuickFormTemplateCreatorsDocument,
    options,
  );
}
export function useQuickFormTemplateCreatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuickFormTemplateCreatorsQuery,
    QuickFormTemplateCreatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QuickFormTemplateCreatorsQuery,
    QuickFormTemplateCreatorsQueryVariables
  >(QuickFormTemplateCreatorsDocument, options);
}
export type QuickFormTemplateCreatorsQueryHookResult = ReturnType<
  typeof useQuickFormTemplateCreatorsQuery
>;
export type QuickFormTemplateCreatorsLazyQueryHookResult = ReturnType<
  typeof useQuickFormTemplateCreatorsLazyQuery
>;
export type QuickFormTemplateCreatorsQueryResult = Apollo.QueryResult<
  QuickFormTemplateCreatorsQuery,
  QuickFormTemplateCreatorsQueryVariables
>;
export function refetchQuickFormTemplateCreatorsQuery(
  variables?: QuickFormTemplateCreatorsQueryVariables,
) {
  return { query: QuickFormTemplateCreatorsDocument, variables: variables };
}
export const FetchDashboardAllFilesDocument = gql`
  query FetchDashboardAllFiles(
    $sort: [SharedFileSortEnum]
    $isPinned: TWFilter_Boolean
    $personId: TWFilter_Int
    $tripId: TWFilter_Int
  ) {
    allSharedFiles(sort: $sort, isPinned: $isPinned, personId: $personId, tripId: $tripId) {
      edges {
        node {
          id
          fileName
          createdDateTime
          creator {
            displayName
          }
          sharedFileId
          canDownload
          canEditFile
          personId
          tripId
        }
      }
    }
  }
`;

/**
 * __useFetchDashboardAllFilesQuery__
 *
 * To run a query within a React component, call `useFetchDashboardAllFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardAllFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardAllFilesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      isPinned: // value for 'isPinned'
 *      personId: // value for 'personId'
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useFetchDashboardAllFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchDashboardAllFilesQuery,
    FetchDashboardAllFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchDashboardAllFilesQuery, FetchDashboardAllFilesQueryVariables>(
    FetchDashboardAllFilesDocument,
    options,
  );
}
export function useFetchDashboardAllFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDashboardAllFilesQuery,
    FetchDashboardAllFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchDashboardAllFilesQuery, FetchDashboardAllFilesQueryVariables>(
    FetchDashboardAllFilesDocument,
    options,
  );
}
export type FetchDashboardAllFilesQueryHookResult = ReturnType<
  typeof useFetchDashboardAllFilesQuery
>;
export type FetchDashboardAllFilesLazyQueryHookResult = ReturnType<
  typeof useFetchDashboardAllFilesLazyQuery
>;
export type FetchDashboardAllFilesQueryResult = Apollo.QueryResult<
  FetchDashboardAllFilesQuery,
  FetchDashboardAllFilesQueryVariables
>;
export function refetchFetchDashboardAllFilesQuery(
  variables?: FetchDashboardAllFilesQueryVariables,
) {
  return { query: FetchDashboardAllFilesDocument, variables: variables };
}
export const FetchDashboardMyVideosDocument = gql`
  query FetchDashboardMyVideos($sort: [VideoSortEnum], $isPinned: TWFilter_Boolean) {
    myVideos(sort: $sort, isPinned: $isPinned) {
      edges {
        node {
          id
          videoId
          title
          createdDateTime
          wistiaVideoUuid
          wistiaThumbnailUrl
          wistiaOriginalFileUrl
          creator {
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useFetchDashboardMyVideosQuery__
 *
 * To run a query within a React component, call `useFetchDashboardMyVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardMyVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardMyVideosQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      isPinned: // value for 'isPinned'
 *   },
 * });
 */
export function useFetchDashboardMyVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchDashboardMyVideosQuery,
    FetchDashboardMyVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchDashboardMyVideosQuery, FetchDashboardMyVideosQueryVariables>(
    FetchDashboardMyVideosDocument,
    options,
  );
}
export function useFetchDashboardMyVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDashboardMyVideosQuery,
    FetchDashboardMyVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchDashboardMyVideosQuery, FetchDashboardMyVideosQueryVariables>(
    FetchDashboardMyVideosDocument,
    options,
  );
}
export type FetchDashboardMyVideosQueryHookResult = ReturnType<
  typeof useFetchDashboardMyVideosQuery
>;
export type FetchDashboardMyVideosLazyQueryHookResult = ReturnType<
  typeof useFetchDashboardMyVideosLazyQuery
>;
export type FetchDashboardMyVideosQueryResult = Apollo.QueryResult<
  FetchDashboardMyVideosQuery,
  FetchDashboardMyVideosQueryVariables
>;
export function refetchFetchDashboardMyVideosQuery(
  variables?: FetchDashboardMyVideosQueryVariables,
) {
  return { query: FetchDashboardMyVideosDocument, variables: variables };
}
export const FetchDashboardFormsDocument = gql`
  query FetchDashboardForms(
    $sort: [FormAssignmentCompletionRoleSortEnum]
    $status: [FormAssignmentCompletionRoleStatusGraphene]
    $first: Int
    $showConditional: Boolean
  ) {
    myFormAssignments(
      sort: $sort
      status: $status
      first: $first
      showConditional: $showConditional
    ) {
      edges {
        node {
          id
          pk
          assignedDateTime
          completion {
            pk
            assignment {
              label
              dueDate
              form {
                formLabel: label
              }
              assignedBy {
                displayName
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchDashboardFormsQuery__
 *
 * To run a query within a React component, call `useFetchDashboardFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardFormsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      status: // value for 'status'
 *      first: // value for 'first'
 *      showConditional: // value for 'showConditional'
 *   },
 * });
 */
export function useFetchDashboardFormsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchDashboardFormsQuery,
    FetchDashboardFormsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchDashboardFormsQuery, FetchDashboardFormsQueryVariables>(
    FetchDashboardFormsDocument,
    options,
  );
}
export function useFetchDashboardFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDashboardFormsQuery,
    FetchDashboardFormsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchDashboardFormsQuery, FetchDashboardFormsQueryVariables>(
    FetchDashboardFormsDocument,
    options,
  );
}
export type FetchDashboardFormsQueryHookResult = ReturnType<typeof useFetchDashboardFormsQuery>;
export type FetchDashboardFormsLazyQueryHookResult = ReturnType<
  typeof useFetchDashboardFormsLazyQuery
>;
export type FetchDashboardFormsQueryResult = Apollo.QueryResult<
  FetchDashboardFormsQuery,
  FetchDashboardFormsQueryVariables
>;
export function refetchFetchDashboardFormsQuery(variables?: FetchDashboardFormsQueryVariables) {
  return { query: FetchDashboardFormsDocument, variables: variables };
}
export const FetchDashboardBulletinsDocument = gql`
  query FetchDashboardBulletins(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [BulletinSortEnum]
  ) {
    myBulletins(after: $after, before: $before, first: $first, last: $last, sort: $sort) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        bulletin: node {
          id
          bulletinType
          label
          externalUrl
          isPinned
          createdDateTime
          content
          expirationDateTime
          headerImageFull
          headerImageThumb
          meta
          buttonText
          creator {
            fullNameNormalOrder
            pictureUrl
            initials
          }
        }
      }
    }
  }
`;

/**
 * __useFetchDashboardBulletinsQuery__
 *
 * To run a query within a React component, call `useFetchDashboardBulletinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardBulletinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardBulletinsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchDashboardBulletinsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchDashboardBulletinsQuery,
    FetchDashboardBulletinsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchDashboardBulletinsQuery, FetchDashboardBulletinsQueryVariables>(
    FetchDashboardBulletinsDocument,
    options,
  );
}
export function useFetchDashboardBulletinsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDashboardBulletinsQuery,
    FetchDashboardBulletinsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchDashboardBulletinsQuery, FetchDashboardBulletinsQueryVariables>(
    FetchDashboardBulletinsDocument,
    options,
  );
}
export type FetchDashboardBulletinsQueryHookResult = ReturnType<
  typeof useFetchDashboardBulletinsQuery
>;
export type FetchDashboardBulletinsLazyQueryHookResult = ReturnType<
  typeof useFetchDashboardBulletinsLazyQuery
>;
export type FetchDashboardBulletinsQueryResult = Apollo.QueryResult<
  FetchDashboardBulletinsQuery,
  FetchDashboardBulletinsQueryVariables
>;
export function refetchFetchDashboardBulletinsQuery(
  variables?: FetchDashboardBulletinsQueryVariables,
) {
  return { query: FetchDashboardBulletinsDocument, variables: variables };
}
export const CreateWeblinkDocument = gql`
  mutation CreateWeblink($input: WebLinkInput) {
    weblinkCreate(input: $input) {
      userErrors {
        message
        field
      }
      webLink {
        webLinkId
        label
        url
        creator {
          personId
          preferredName
          lastName
          pictureUrl
        }
      }
    }
  }
`;
export type CreateWeblinkMutationFn = Apollo.MutationFunction<
  CreateWeblinkMutation,
  CreateWeblinkMutationVariables
>;

/**
 * __useCreateWeblinkMutation__
 *
 * To run a mutation, you first call `useCreateWeblinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWeblinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWeblinkMutation, { data, loading, error }] = useCreateWeblinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWeblinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWeblinkMutation, CreateWeblinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWeblinkMutation, CreateWeblinkMutationVariables>(
    CreateWeblinkDocument,
    options,
  );
}
export type CreateWeblinkMutationHookResult = ReturnType<typeof useCreateWeblinkMutation>;
export type CreateWeblinkMutationResult = Apollo.MutationResult<CreateWeblinkMutation>;
export type CreateWeblinkMutationOptions = Apollo.BaseMutationOptions<
  CreateWeblinkMutation,
  CreateWeblinkMutationVariables
>;
export const DeleteWeblinkDocument = gql`
  mutation DeleteWeblink($id: ID) {
    weblinkDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type DeleteWeblinkMutationFn = Apollo.MutationFunction<
  DeleteWeblinkMutation,
  DeleteWeblinkMutationVariables
>;

/**
 * __useDeleteWeblinkMutation__
 *
 * To run a mutation, you first call `useDeleteWeblinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWeblinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWeblinkMutation, { data, loading, error }] = useDeleteWeblinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWeblinkMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteWeblinkMutation, DeleteWeblinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWeblinkMutation, DeleteWeblinkMutationVariables>(
    DeleteWeblinkDocument,
    options,
  );
}
export type DeleteWeblinkMutationHookResult = ReturnType<typeof useDeleteWeblinkMutation>;
export type DeleteWeblinkMutationResult = Apollo.MutationResult<DeleteWeblinkMutation>;
export type DeleteWeblinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteWeblinkMutation,
  DeleteWeblinkMutationVariables
>;
export const UpdateWeblinkDocument = gql`
  mutation UpdateWeblink($id: ID, $input: WebLinkInput) {
    weblinkUpdate(id: $id, input: $input) {
      userErrors {
        message
        field
      }
      webLink {
        id
        label
        url
        creator {
          personId
          preferredName
          lastName
          pictureUrl
        }
      }
    }
  }
`;
export type UpdateWeblinkMutationFn = Apollo.MutationFunction<
  UpdateWeblinkMutation,
  UpdateWeblinkMutationVariables
>;

/**
 * __useUpdateWeblinkMutation__
 *
 * To run a mutation, you first call `useUpdateWeblinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeblinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeblinkMutation, { data, loading, error }] = useUpdateWeblinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWeblinkMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWeblinkMutation, UpdateWeblinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWeblinkMutation, UpdateWeblinkMutationVariables>(
    UpdateWeblinkDocument,
    options,
  );
}
export type UpdateWeblinkMutationHookResult = ReturnType<typeof useUpdateWeblinkMutation>;
export type UpdateWeblinkMutationResult = Apollo.MutationResult<UpdateWeblinkMutation>;
export type UpdateWeblinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateWeblinkMutation,
  UpdateWeblinkMutationVariables
>;
export const WebLinksDocument = gql`
  query WebLinks {
    myWebLinks {
      edges {
        node {
          id
          label
          url
          creator {
            personId
            lastName
            preferredName
            pictureUrl
          }
          createdDateTime
          updatedDateTime
          visibility {
            selectionCode
            label
            groupType
            pluralLabel
          }
        }
      }
    }
  }
`;

/**
 * __useWebLinksQuery__
 *
 * To run a query within a React component, call `useWebLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useWebLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<WebLinksQuery, WebLinksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebLinksQuery, WebLinksQueryVariables>(WebLinksDocument, options);
}
export function useWebLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WebLinksQuery, WebLinksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebLinksQuery, WebLinksQueryVariables>(WebLinksDocument, options);
}
export type WebLinksQueryHookResult = ReturnType<typeof useWebLinksQuery>;
export type WebLinksLazyQueryHookResult = ReturnType<typeof useWebLinksLazyQuery>;
export type WebLinksQueryResult = Apollo.QueryResult<WebLinksQuery, WebLinksQueryVariables>;
export function refetchWebLinksQuery(variables?: WebLinksQueryVariables) {
  return { query: WebLinksDocument, variables: variables };
}
export const BlastMessageDocument = gql`
  query BlastMessage($pk: TWFilter_String) {
    messages(pk: $pk) {
      edges {
        cursor
        node {
          id
          pk: pk
          smsText
          emailSubject
          emailText
          emailAttachments {
            fileName
            serverName
            s3Url
          }
          deliveryTypes
          scheduledDateTime
          mmsAttachments {
            fileName
            serverName
            s3Url
          }
          targetSelectionCodes {
            code
            isGroup
            label
          }
          timeZone {
            code
          }
        }
      }
    }
  }
`;

/**
 * __useBlastMessageQuery__
 *
 * To run a query within a React component, call `useBlastMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlastMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlastMessageQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useBlastMessageQuery(
  baseOptions?: Apollo.QueryHookOptions<BlastMessageQuery, BlastMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlastMessageQuery, BlastMessageQueryVariables>(
    BlastMessageDocument,
    options,
  );
}
export function useBlastMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlastMessageQuery, BlastMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlastMessageQuery, BlastMessageQueryVariables>(
    BlastMessageDocument,
    options,
  );
}
export type BlastMessageQueryHookResult = ReturnType<typeof useBlastMessageQuery>;
export type BlastMessageLazyQueryHookResult = ReturnType<typeof useBlastMessageLazyQuery>;
export type BlastMessageQueryResult = Apollo.QueryResult<
  BlastMessageQuery,
  BlastMessageQueryVariables
>;
export function refetchBlastMessageQuery(variables?: BlastMessageQueryVariables) {
  return { query: BlastMessageDocument, variables: variables };
}
export const ConversationAvatarSignedUrlDocument = gql`
  mutation ConversationAvatarSignedUrl($input: SignedUrlInput) {
    conversationAvatarSignedUrl(input: $input) {
      url
    }
  }
`;
export type ConversationAvatarSignedUrlMutationFn = Apollo.MutationFunction<
  ConversationAvatarSignedUrlMutation,
  ConversationAvatarSignedUrlMutationVariables
>;

/**
 * __useConversationAvatarSignedUrlMutation__
 *
 * To run a mutation, you first call `useConversationAvatarSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConversationAvatarSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [conversationAvatarSignedUrlMutation, { data, loading, error }] = useConversationAvatarSignedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConversationAvatarSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConversationAvatarSignedUrlMutation,
    ConversationAvatarSignedUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConversationAvatarSignedUrlMutation,
    ConversationAvatarSignedUrlMutationVariables
  >(ConversationAvatarSignedUrlDocument, options);
}
export type ConversationAvatarSignedUrlMutationHookResult = ReturnType<
  typeof useConversationAvatarSignedUrlMutation
>;
export type ConversationAvatarSignedUrlMutationResult =
  Apollo.MutationResult<ConversationAvatarSignedUrlMutation>;
export type ConversationAvatarSignedUrlMutationOptions = Apollo.BaseMutationOptions<
  ConversationAvatarSignedUrlMutation,
  ConversationAvatarSignedUrlMutationVariables
>;
export const CreateBlastMessageDocument = gql`
  mutation CreateBlastMessage($input: MessageInput) {
    messageCreate(input: $input) {
      userErrors {
        message
        field
      }
      message {
        pk
      }
    }
  }
`;
export type CreateBlastMessageMutationFn = Apollo.MutationFunction<
  CreateBlastMessageMutation,
  CreateBlastMessageMutationVariables
>;

/**
 * __useCreateBlastMessageMutation__
 *
 * To run a mutation, you first call `useCreateBlastMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlastMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlastMessageMutation, { data, loading, error }] = useCreateBlastMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBlastMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBlastMessageMutation,
    CreateBlastMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBlastMessageMutation, CreateBlastMessageMutationVariables>(
    CreateBlastMessageDocument,
    options,
  );
}
export type CreateBlastMessageMutationHookResult = ReturnType<typeof useCreateBlastMessageMutation>;
export type CreateBlastMessageMutationResult = Apollo.MutationResult<CreateBlastMessageMutation>;
export type CreateBlastMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateBlastMessageMutation,
  CreateBlastMessageMutationVariables
>;
export const DeleteScheduledMessageDocument = gql`
  mutation DeleteScheduledMessage($id: ID) {
    messageDelete(id: $id) {
      message {
        pk
      }
    }
  }
`;
export type DeleteScheduledMessageMutationFn = Apollo.MutationFunction<
  DeleteScheduledMessageMutation,
  DeleteScheduledMessageMutationVariables
>;

/**
 * __useDeleteScheduledMessageMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledMessageMutation, { data, loading, error }] = useDeleteScheduledMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduledMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScheduledMessageMutation,
    DeleteScheduledMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScheduledMessageMutation,
    DeleteScheduledMessageMutationVariables
  >(DeleteScheduledMessageDocument, options);
}
export type DeleteScheduledMessageMutationHookResult = ReturnType<
  typeof useDeleteScheduledMessageMutation
>;
export type DeleteScheduledMessageMutationResult =
  Apollo.MutationResult<DeleteScheduledMessageMutation>;
export type DeleteScheduledMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteScheduledMessageMutation,
  DeleteScheduledMessageMutationVariables
>;
export const ListMessageSendersDocument = gql`
  query ListMessageSenders($returnSentMessages: Boolean, $pageSize: Int, $page: Int) {
    listMessageSenders(returnSentMessages: $returnSentMessages, pageSize: $pageSize, page: $page) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          person {
            personId
            preferredName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useListMessageSendersQuery__
 *
 * To run a query within a React component, call `useListMessageSendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMessageSendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMessageSendersQuery({
 *   variables: {
 *      returnSentMessages: // value for 'returnSentMessages'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListMessageSendersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListMessageSendersQuery, ListMessageSendersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMessageSendersQuery, ListMessageSendersQueryVariables>(
    ListMessageSendersDocument,
    options,
  );
}
export function useListMessageSendersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMessageSendersQuery,
    ListMessageSendersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMessageSendersQuery, ListMessageSendersQueryVariables>(
    ListMessageSendersDocument,
    options,
  );
}
export type ListMessageSendersQueryHookResult = ReturnType<typeof useListMessageSendersQuery>;
export type ListMessageSendersLazyQueryHookResult = ReturnType<
  typeof useListMessageSendersLazyQuery
>;
export type ListMessageSendersQueryResult = Apollo.QueryResult<
  ListMessageSendersQuery,
  ListMessageSendersQueryVariables
>;
export function refetchListMessageSendersQuery(variables?: ListMessageSendersQueryVariables) {
  return { query: ListMessageSendersDocument, variables: variables };
}
export const ListMessagesDocument = gql`
  query ListMessages(
    $ids: [Int]
    $filterDate: DateRangeInput
    $returnSentMessages: Boolean
    $filterByTeams: [Int]
    $filterBySender: Int
    $filterByDeliveryPath: [String]
    $after: Int
    $first: Int
    $reverse: Boolean = false
  ) {
    listMessages(
      ids: $ids
      filterByUtcDateRange: $filterDate
      returnSentMessages: $returnSentMessages
      filterByTeams: $filterByTeams
      filterBySender: $filterBySender
      filterByDeliveryPath: $filterByDeliveryPath
      after: $after
      first: $first
      reverse: $reverse
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          messageId
          hasBeenProcessed
          messageType {
            integratorLabel
            integratorMessageType
            code
          }
          personId
          person {
            id
            personId
            preferredName
            lastName
            initials
            pictureUrl
            emailAddress
          }
          smsText
          emailSubject
          rawEmailBody
          emailText
          emailAttachments {
            fileName
            serverName
            s3Url
          }
          deliveryTypes
          scheduledDateTime
          timezone {
            abbreviation
            code
          }
          sentDateTime
          mmsAttachments {
            fileName
            serverName
            s3Url
          }
          externalEmails
          externalSms
          recipientSelections {
            label
            selectionCode
            peopleCount
            ... on PersonNode {
              id
              emailAddress
              personId
              pictureUrl
              preferredName
              lastName
              initials
              active
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                emailAddress
                personId
                pictureUrl
                preferredName
                lastName
                initials
                active
                label
                selectionCode
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListMessagesQuery__
 *
 * To run a query within a React component, call `useListMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMessagesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      filterDate: // value for 'filterDate'
 *      returnSentMessages: // value for 'returnSentMessages'
 *      filterByTeams: // value for 'filterByTeams'
 *      filterBySender: // value for 'filterBySender'
 *      filterByDeliveryPath: // value for 'filterByDeliveryPath'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      reverse: // value for 'reverse'
 *   },
 * });
 */
export function useListMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListMessagesQuery, ListMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMessagesQuery, ListMessagesQueryVariables>(
    ListMessagesDocument,
    options,
  );
}
export function useListMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMessagesQuery, ListMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMessagesQuery, ListMessagesQueryVariables>(
    ListMessagesDocument,
    options,
  );
}
export type ListMessagesQueryHookResult = ReturnType<typeof useListMessagesQuery>;
export type ListMessagesLazyQueryHookResult = ReturnType<typeof useListMessagesLazyQuery>;
export type ListMessagesQueryResult = Apollo.QueryResult<
  ListMessagesQuery,
  ListMessagesQueryVariables
>;
export function refetchListMessagesQuery(variables?: ListMessagesQueryVariables) {
  return { query: ListMessagesDocument, variables: variables };
}
export const MessageBlastAttachmentS3UploadUrlDocument = gql`
  mutation MessageBlastAttachmentS3UploadUrl($input: MessageBlastAttachmentS3UploadUrlInput!) {
    messageBlastAttachmentS3UploadUrl(input: $input) {
      url
    }
  }
`;
export type MessageBlastAttachmentS3UploadUrlMutationFn = Apollo.MutationFunction<
  MessageBlastAttachmentS3UploadUrlMutation,
  MessageBlastAttachmentS3UploadUrlMutationVariables
>;

/**
 * __useMessageBlastAttachmentS3UploadUrlMutation__
 *
 * To run a mutation, you first call `useMessageBlastAttachmentS3UploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageBlastAttachmentS3UploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageBlastAttachmentS3UploadUrlMutation, { data, loading, error }] = useMessageBlastAttachmentS3UploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageBlastAttachmentS3UploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MessageBlastAttachmentS3UploadUrlMutation,
    MessageBlastAttachmentS3UploadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MessageBlastAttachmentS3UploadUrlMutation,
    MessageBlastAttachmentS3UploadUrlMutationVariables
  >(MessageBlastAttachmentS3UploadUrlDocument, options);
}
export type MessageBlastAttachmentS3UploadUrlMutationHookResult = ReturnType<
  typeof useMessageBlastAttachmentS3UploadUrlMutation
>;
export type MessageBlastAttachmentS3UploadUrlMutationResult =
  Apollo.MutationResult<MessageBlastAttachmentS3UploadUrlMutation>;
export type MessageBlastAttachmentS3UploadUrlMutationOptions = Apollo.BaseMutationOptions<
  MessageBlastAttachmentS3UploadUrlMutation,
  MessageBlastAttachmentS3UploadUrlMutationVariables
>;
export const MessageHistoryListDocument = gql`
  query MessageHistoryList(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $messageType: TWFilter_String
    $deliveryTypes: TWFilter_String
    $conversationId: TWFilter_Int
    $sentDateTime: TWFilter_String
    $scheduledDateTime: TWFilter_String
    $teamIds: [Int]
    $personId: TWFilter_Int
    $deliveryTypesFilt: [String]
  ) {
    messages(
      after: $after
      before: $before
      first: $first
      last: $last
      messageType: $messageType
      deliveryTypes: $deliveryTypes
      conversationId: $conversationId
      sentDateTime: $sentDateTime
      scheduledDateTime: $scheduledDateTime
      teamIds: $teamIds
      personId: $personId
      deliveryTypesFilt: $deliveryTypesFilt
      sort: scheduled_send_date_desc
      hasBeenProcessed: "true"
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          pk
          id
          messageType {
            integratorLabel
            integratorMessageType
            code
          }
          personId
          person {
            personId
            preferredName
            lastName
            initials
            pictureUrl
            emailAddress
          }
          smsText
          pushText
          emailSubject
          emailText
          emailAttachments {
            fileName
            serverName
            s3Url
          }
          deliveryTypes
          scheduledDateTime
          sentDateTime
          mmsAttachments {
            fileName
            serverName
            s3Url
          }
          displayEmailText
          messageRecipientDeliveryStatuses {
            totalCount
            edges {
              node {
                pk
                emailAddress
                person {
                  personId
                  preferredName
                  lastName
                  initials
                  pictureUrl
                  emailAddress
                }
              }
            }
          }
          targetSelectionCodes {
            code
            isGroup
            label
          }
          hasBeenProcessed
        }
      }
    }
  }
`;

/**
 * __useMessageHistoryListQuery__
 *
 * To run a query within a React component, call `useMessageHistoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageHistoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageHistoryListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      messageType: // value for 'messageType'
 *      deliveryTypes: // value for 'deliveryTypes'
 *      conversationId: // value for 'conversationId'
 *      sentDateTime: // value for 'sentDateTime'
 *      scheduledDateTime: // value for 'scheduledDateTime'
 *      teamIds: // value for 'teamIds'
 *      personId: // value for 'personId'
 *      deliveryTypesFilt: // value for 'deliveryTypesFilt'
 *   },
 * });
 */
export function useMessageHistoryListQuery(
  baseOptions?: Apollo.QueryHookOptions<MessageHistoryListQuery, MessageHistoryListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessageHistoryListQuery, MessageHistoryListQueryVariables>(
    MessageHistoryListDocument,
    options,
  );
}
export function useMessageHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessageHistoryListQuery,
    MessageHistoryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessageHistoryListQuery, MessageHistoryListQueryVariables>(
    MessageHistoryListDocument,
    options,
  );
}
export type MessageHistoryListQueryHookResult = ReturnType<typeof useMessageHistoryListQuery>;
export type MessageHistoryListLazyQueryHookResult = ReturnType<
  typeof useMessageHistoryListLazyQuery
>;
export type MessageHistoryListQueryResult = Apollo.QueryResult<
  MessageHistoryListQuery,
  MessageHistoryListQueryVariables
>;
export function refetchMessageHistoryListQuery(variables?: MessageHistoryListQueryVariables) {
  return { query: MessageHistoryListDocument, variables: variables };
}
export const MessageRecipientDeliveriesDocument = gql`
  query MessageRecipientDeliveries(
    $after: Int
    $before: String
    $first: Int
    $last: Int
    $ids: [Int]!
    $search: String
    $statuses: [String]
  ) {
    deliveries(
      after: $after
      before: $before
      first: $first
      last: $last
      ids: $ids
      search: $search
      statuses: $statuses
    ) {
      edges {
        node {
          deliveryDate
          deliveryId
          deliveryStatus
          externalId
          failAfter
          messageId
          messagePath
          readDate
          reasonOfFailure
          scheduledSendDate
          sentDate
          statusDate
          tenantId
          person {
            id
            personId
            preferredName
            lastName
            initials
            pictureUrl
            active
          }
          emailAddress
          phoneMobile
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

/**
 * __useMessageRecipientDeliveriesQuery__
 *
 * To run a query within a React component, call `useMessageRecipientDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageRecipientDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageRecipientDeliveriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      ids: // value for 'ids'
 *      search: // value for 'search'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useMessageRecipientDeliveriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MessageRecipientDeliveriesQuery,
    MessageRecipientDeliveriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessageRecipientDeliveriesQuery, MessageRecipientDeliveriesQueryVariables>(
    MessageRecipientDeliveriesDocument,
    options,
  );
}
export function useMessageRecipientDeliveriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessageRecipientDeliveriesQuery,
    MessageRecipientDeliveriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MessageRecipientDeliveriesQuery,
    MessageRecipientDeliveriesQueryVariables
  >(MessageRecipientDeliveriesDocument, options);
}
export type MessageRecipientDeliveriesQueryHookResult = ReturnType<
  typeof useMessageRecipientDeliveriesQuery
>;
export type MessageRecipientDeliveriesLazyQueryHookResult = ReturnType<
  typeof useMessageRecipientDeliveriesLazyQuery
>;
export type MessageRecipientDeliveriesQueryResult = Apollo.QueryResult<
  MessageRecipientDeliveriesQuery,
  MessageRecipientDeliveriesQueryVariables
>;
export function refetchMessageRecipientDeliveriesQuery(
  variables: MessageRecipientDeliveriesQueryVariables,
) {
  return { query: MessageRecipientDeliveriesDocument, variables: variables };
}
export const MessageRecipientDeliveryStatusesDocument = gql`
  query MessageRecipientDeliveryStatuses(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $messageId: TWFilter_Int!
    $search: String
    $broadStatusSearch: [String]
  ) {
    messageRecipientDeliveryStatuses(
      after: $after
      before: $before
      first: $first
      last: $last
      messageId: $messageId
      search: $search
      broadStatusSearch: $broadStatusSearch
    ) {
      totalCount
      smsCount
      pushCount
      edges {
        node {
          pk
          personId
          person {
            id
            personId
            preferredName
            lastName
            initials
            pictureUrl
            active
          }
          messageId
          rawTextDeliveryStatus
          rawEmailDeliveryStatus
          textDeliveryStatus
          emailDeliveryStatus
          smsFirstReadDateTime
          emailDeliveryDateTime
          emailFirstOpenedDateTime
          createdDateTime
          phoneMobile
          emailAddress
          isPushMessage
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

/**
 * __useMessageRecipientDeliveryStatusesQuery__
 *
 * To run a query within a React component, call `useMessageRecipientDeliveryStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageRecipientDeliveryStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageRecipientDeliveryStatusesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      messageId: // value for 'messageId'
 *      search: // value for 'search'
 *      broadStatusSearch: // value for 'broadStatusSearch'
 *   },
 * });
 */
export function useMessageRecipientDeliveryStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MessageRecipientDeliveryStatusesQuery,
    MessageRecipientDeliveryStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MessageRecipientDeliveryStatusesQuery,
    MessageRecipientDeliveryStatusesQueryVariables
  >(MessageRecipientDeliveryStatusesDocument, options);
}
export function useMessageRecipientDeliveryStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessageRecipientDeliveryStatusesQuery,
    MessageRecipientDeliveryStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MessageRecipientDeliveryStatusesQuery,
    MessageRecipientDeliveryStatusesQueryVariables
  >(MessageRecipientDeliveryStatusesDocument, options);
}
export type MessageRecipientDeliveryStatusesQueryHookResult = ReturnType<
  typeof useMessageRecipientDeliveryStatusesQuery
>;
export type MessageRecipientDeliveryStatusesLazyQueryHookResult = ReturnType<
  typeof useMessageRecipientDeliveryStatusesLazyQuery
>;
export type MessageRecipientDeliveryStatusesQueryResult = Apollo.QueryResult<
  MessageRecipientDeliveryStatusesQuery,
  MessageRecipientDeliveryStatusesQueryVariables
>;
export function refetchMessageRecipientDeliveryStatusesQuery(
  variables: MessageRecipientDeliveryStatusesQueryVariables,
) {
  return { query: MessageRecipientDeliveryStatusesDocument, variables: variables };
}
export const ScheduledMessagesListDocument = gql`
  query ScheduledMessagesList(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $deliveryTypes: TWFilter_String
    $conversationId: TWFilter_Int
    $sentDateTime: TWFilter_String
    $scheduledDateTime: TWFilter_String
    $teamIds: [Int]
    $personId: TWFilter_Int
    $deliveryTypesFilt: [String]
    $messageId: TWFilter_String
  ) {
    messages(
      after: $after
      before: $before
      first: $first
      last: $last
      pk: $messageId
      messageType: "messaging"
      deliveryTypes: $deliveryTypes
      conversationId: $conversationId
      sentDateTime: $sentDateTime
      scheduledDateTime: $scheduledDateTime
      teamIds: $teamIds
      personId: $personId
      deliveryTypesFilt: $deliveryTypesFilt
      sort: scheduled_send_date_asc
      hasBeenProcessed: "false"
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          pk
          id
          messageType {
            integratorLabel
            integratorMessageType
            code
          }
          personId
          person {
            id
            personId
            preferredName
            lastName
            initials
            pictureUrl
            pictureDownloadUrl
            emailAddress
          }
          smsText
          pushText
          emailSubject
          emailText
          emailAttachments {
            fileName
            serverName
            s3Url
          }
          mmsAttachments {
            fileName
            serverName
            s3Url
          }
          deliveryTypes
          scheduledDateTime
          targetSelectionCodes {
            code
            isGroup
            label
          }
          recipients {
            personId
            preferredName
            lastName
            initials
            pictureDownloadUrl
          }
          timeZone {
            code
            abbreviation
          }
          hasBeenProcessed
        }
      }
    }
  }
`;

/**
 * __useScheduledMessagesListQuery__
 *
 * To run a query within a React component, call `useScheduledMessagesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledMessagesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledMessagesListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      deliveryTypes: // value for 'deliveryTypes'
 *      conversationId: // value for 'conversationId'
 *      sentDateTime: // value for 'sentDateTime'
 *      scheduledDateTime: // value for 'scheduledDateTime'
 *      teamIds: // value for 'teamIds'
 *      personId: // value for 'personId'
 *      deliveryTypesFilt: // value for 'deliveryTypesFilt'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useScheduledMessagesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ScheduledMessagesListQuery,
    ScheduledMessagesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduledMessagesListQuery, ScheduledMessagesListQueryVariables>(
    ScheduledMessagesListDocument,
    options,
  );
}
export function useScheduledMessagesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduledMessagesListQuery,
    ScheduledMessagesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduledMessagesListQuery, ScheduledMessagesListQueryVariables>(
    ScheduledMessagesListDocument,
    options,
  );
}
export type ScheduledMessagesListQueryHookResult = ReturnType<typeof useScheduledMessagesListQuery>;
export type ScheduledMessagesListLazyQueryHookResult = ReturnType<
  typeof useScheduledMessagesListLazyQuery
>;
export type ScheduledMessagesListQueryResult = Apollo.QueryResult<
  ScheduledMessagesListQuery,
  ScheduledMessagesListQueryVariables
>;
export function refetchScheduledMessagesListQuery(variables?: ScheduledMessagesListQueryVariables) {
  return { query: ScheduledMessagesListDocument, variables: variables };
}
export const UpdateScheduledMessageDocument = gql`
  mutation UpdateScheduledMessage($id: ID, $input: MessageInput) {
    messageUpdate(id: $id, input: $input) {
      message {
        pk
      }
    }
  }
`;
export type UpdateScheduledMessageMutationFn = Apollo.MutationFunction<
  UpdateScheduledMessageMutation,
  UpdateScheduledMessageMutationVariables
>;

/**
 * __useUpdateScheduledMessageMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledMessageMutation, { data, loading, error }] = useUpdateScheduledMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScheduledMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduledMessageMutation,
    UpdateScheduledMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScheduledMessageMutation,
    UpdateScheduledMessageMutationVariables
  >(UpdateScheduledMessageDocument, options);
}
export type UpdateScheduledMessageMutationHookResult = ReturnType<
  typeof useUpdateScheduledMessageMutation
>;
export type UpdateScheduledMessageMutationResult =
  Apollo.MutationResult<UpdateScheduledMessageMutation>;
export type UpdateScheduledMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduledMessageMutation,
  UpdateScheduledMessageMutationVariables
>;
export const UploadAttachmentMessageBlastDocument = gql`
  mutation UploadAttachmentMessageBlast($input: UploadAttachmentInput) {
    uploadAttachment(input: $input) {
      serverName
    }
  }
`;
export type UploadAttachmentMessageBlastMutationFn = Apollo.MutationFunction<
  UploadAttachmentMessageBlastMutation,
  UploadAttachmentMessageBlastMutationVariables
>;

/**
 * __useUploadAttachmentMessageBlastMutation__
 *
 * To run a mutation, you first call `useUploadAttachmentMessageBlastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAttachmentMessageBlastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAttachmentMessageBlastMutation, { data, loading, error }] = useUploadAttachmentMessageBlastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadAttachmentMessageBlastMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadAttachmentMessageBlastMutation,
    UploadAttachmentMessageBlastMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadAttachmentMessageBlastMutation,
    UploadAttachmentMessageBlastMutationVariables
  >(UploadAttachmentMessageBlastDocument, options);
}
export type UploadAttachmentMessageBlastMutationHookResult = ReturnType<
  typeof useUploadAttachmentMessageBlastMutation
>;
export type UploadAttachmentMessageBlastMutationResult =
  Apollo.MutationResult<UploadAttachmentMessageBlastMutation>;
export type UploadAttachmentMessageBlastMutationOptions = Apollo.BaseMutationOptions<
  UploadAttachmentMessageBlastMutation,
  UploadAttachmentMessageBlastMutationVariables
>;
export const MySavedReportsDocument = gql`
  query MySavedReports {
    mySavedReports {
      edges {
        node {
          id
          label
          filters
          savedViewPersons {
            person {
              id
              label
              selectionCode
              initials
              orgId
              displayName
              personId
              firstName
              lastName
              active
              pictureUrl
            }
          }
        }
      }
    }
  }
`;

/**
 * __useMySavedReportsQuery__
 *
 * To run a query within a React component, call `useMySavedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySavedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySavedReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySavedReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<MySavedReportsQuery, MySavedReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MySavedReportsQuery, MySavedReportsQueryVariables>(
    MySavedReportsDocument,
    options,
  );
}
export function useMySavedReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MySavedReportsQuery, MySavedReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MySavedReportsQuery, MySavedReportsQueryVariables>(
    MySavedReportsDocument,
    options,
  );
}
export type MySavedReportsQueryHookResult = ReturnType<typeof useMySavedReportsQuery>;
export type MySavedReportsLazyQueryHookResult = ReturnType<typeof useMySavedReportsLazyQuery>;
export type MySavedReportsQueryResult = Apollo.QueryResult<
  MySavedReportsQuery,
  MySavedReportsQueryVariables
>;
export function refetchMySavedReportsQuery(variables?: MySavedReportsQueryVariables) {
  return { query: MySavedReportsDocument, variables: variables };
}
export const MySharedSavedReportsDocument = gql`
  query MySharedSavedReports {
    mySharedSavedReports {
      edges {
        node {
          id
          label
          filters
        }
      }
    }
  }
`;

/**
 * __useMySharedSavedReportsQuery__
 *
 * To run a query within a React component, call `useMySharedSavedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySharedSavedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySharedSavedReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySharedSavedReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MySharedSavedReportsQuery,
    MySharedSavedReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MySharedSavedReportsQuery, MySharedSavedReportsQueryVariables>(
    MySharedSavedReportsDocument,
    options,
  );
}
export function useMySharedSavedReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MySharedSavedReportsQuery,
    MySharedSavedReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MySharedSavedReportsQuery, MySharedSavedReportsQueryVariables>(
    MySharedSavedReportsDocument,
    options,
  );
}
export type MySharedSavedReportsQueryHookResult = ReturnType<typeof useMySharedSavedReportsQuery>;
export type MySharedSavedReportsLazyQueryHookResult = ReturnType<
  typeof useMySharedSavedReportsLazyQuery
>;
export type MySharedSavedReportsQueryResult = Apollo.QueryResult<
  MySharedSavedReportsQuery,
  MySharedSavedReportsQueryVariables
>;
export function refetchMySharedSavedReportsQuery(variables?: MySharedSavedReportsQueryVariables) {
  return { query: MySharedSavedReportsDocument, variables: variables };
}
export const MySharedTeamRosterSavedViewsDocument = gql`
  query MySharedTeamRosterSavedViews {
    mySharedTeamRosterSavedViews {
      edges {
        node {
          id
          label
          filters
        }
      }
    }
  }
`;

/**
 * __useMySharedTeamRosterSavedViewsQuery__
 *
 * To run a query within a React component, call `useMySharedTeamRosterSavedViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySharedTeamRosterSavedViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySharedTeamRosterSavedViewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySharedTeamRosterSavedViewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MySharedTeamRosterSavedViewsQuery,
    MySharedTeamRosterSavedViewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MySharedTeamRosterSavedViewsQuery,
    MySharedTeamRosterSavedViewsQueryVariables
  >(MySharedTeamRosterSavedViewsDocument, options);
}
export function useMySharedTeamRosterSavedViewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MySharedTeamRosterSavedViewsQuery,
    MySharedTeamRosterSavedViewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MySharedTeamRosterSavedViewsQuery,
    MySharedTeamRosterSavedViewsQueryVariables
  >(MySharedTeamRosterSavedViewsDocument, options);
}
export type MySharedTeamRosterSavedViewsQueryHookResult = ReturnType<
  typeof useMySharedTeamRosterSavedViewsQuery
>;
export type MySharedTeamRosterSavedViewsLazyQueryHookResult = ReturnType<
  typeof useMySharedTeamRosterSavedViewsLazyQuery
>;
export type MySharedTeamRosterSavedViewsQueryResult = Apollo.QueryResult<
  MySharedTeamRosterSavedViewsQuery,
  MySharedTeamRosterSavedViewsQueryVariables
>;
export function refetchMySharedTeamRosterSavedViewsQuery(
  variables?: MySharedTeamRosterSavedViewsQueryVariables,
) {
  return { query: MySharedTeamRosterSavedViewsDocument, variables: variables };
}
export const MyTeamRosterSavedViewsDocument = gql`
  query MyTeamRosterSavedViews {
    myTeamRosterSavedViews {
      edges {
        node {
          id
          label
          filters
          savedViewPersons {
            person {
              id
              label
              selectionCode
              initials
              orgId
              displayName
              personId
              firstName
              lastName
              active
              pictureUrl
            }
          }
        }
      }
    }
  }
`;

/**
 * __useMyTeamRosterSavedViewsQuery__
 *
 * To run a query within a React component, call `useMyTeamRosterSavedViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTeamRosterSavedViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTeamRosterSavedViewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTeamRosterSavedViewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyTeamRosterSavedViewsQuery,
    MyTeamRosterSavedViewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyTeamRosterSavedViewsQuery, MyTeamRosterSavedViewsQueryVariables>(
    MyTeamRosterSavedViewsDocument,
    options,
  );
}
export function useMyTeamRosterSavedViewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyTeamRosterSavedViewsQuery,
    MyTeamRosterSavedViewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyTeamRosterSavedViewsQuery, MyTeamRosterSavedViewsQueryVariables>(
    MyTeamRosterSavedViewsDocument,
    options,
  );
}
export type MyTeamRosterSavedViewsQueryHookResult = ReturnType<
  typeof useMyTeamRosterSavedViewsQuery
>;
export type MyTeamRosterSavedViewsLazyQueryHookResult = ReturnType<
  typeof useMyTeamRosterSavedViewsLazyQuery
>;
export type MyTeamRosterSavedViewsQueryResult = Apollo.QueryResult<
  MyTeamRosterSavedViewsQuery,
  MyTeamRosterSavedViewsQueryVariables
>;
export function refetchMyTeamRosterSavedViewsQuery(
  variables?: MyTeamRosterSavedViewsQueryVariables,
) {
  return { query: MyTeamRosterSavedViewsDocument, variables: variables };
}
export const BulkCreateTeamRosterPersonDocument = gql`
  mutation BulkCreateTeamRosterPerson($input: TeamRosterPersonBulkCreateInput!) {
    teamRosterPersonBulkCreate(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type BulkCreateTeamRosterPersonMutationFn = Apollo.MutationFunction<
  BulkCreateTeamRosterPersonMutation,
  BulkCreateTeamRosterPersonMutationVariables
>;

/**
 * __useBulkCreateTeamRosterPersonMutation__
 *
 * To run a mutation, you first call `useBulkCreateTeamRosterPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateTeamRosterPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateTeamRosterPersonMutation, { data, loading, error }] = useBulkCreateTeamRosterPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateTeamRosterPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkCreateTeamRosterPersonMutation,
    BulkCreateTeamRosterPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkCreateTeamRosterPersonMutation,
    BulkCreateTeamRosterPersonMutationVariables
  >(BulkCreateTeamRosterPersonDocument, options);
}
export type BulkCreateTeamRosterPersonMutationHookResult = ReturnType<
  typeof useBulkCreateTeamRosterPersonMutation
>;
export type BulkCreateTeamRosterPersonMutationResult =
  Apollo.MutationResult<BulkCreateTeamRosterPersonMutation>;
export type BulkCreateTeamRosterPersonMutationOptions = Apollo.BaseMutationOptions<
  BulkCreateTeamRosterPersonMutation,
  BulkCreateTeamRosterPersonMutationVariables
>;
export const AdvFetchPeopleDocument = gql`
  query AdvFetchPeople(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sort: [PersonSortEnum]
    $search: String
    $teams: [ID]
    $userTypes: [ID]
    $trip: ID
    $athleteStatus: ID
    $customGroup: ID
    $activeStatus: Boolean
    $advanced: JSONString
    $columns: [ID]
    $viaSavedView: Boolean = false
    $withAddresses: Boolean = false
    $withAutomobile: Boolean = false
    $withContacts: Boolean = false
    $withRoommates: Boolean = false
    $withTravelDocument: Boolean = false
    $withIsOrgSuperuser: Boolean = true
    $withFirstName: Boolean = true
    $withLastName: Boolean = true
    $withMiddleName: Boolean = true
    $withNickName: Boolean = true
    $withGender: Boolean = true
    $withBirthdate: Boolean = true
    $withOrgId: Boolean = true
    $withStartDate: Boolean = true
    $withWeight: Boolean = true
    $withNickname: Boolean = true
    $withTitle: Boolean = true
    $withEmail: Boolean = true
    $withAlternateEmail: Boolean = true
    $withCustomAttributes: Boolean = true
    $withYearlyAttributes: Boolean = false
    $withHomePhone: Boolean = true
    $withMobilePhone: Boolean = true
    $withOfficePhone: Boolean = true
    $withFaxNumber: Boolean = true
    $withFacebookAccount: Boolean = true
    $withTwitterAccount: Boolean = true
    $withLastLogin: Boolean = true
    $withLoginCount: Boolean = true
    $withDriversLicenseNumber: Boolean = true
    $withDriversLicenseState: Boolean = true
    $withDriversLicenseExpDate: Boolean = true
    $withJerseyNumber: Boolean = true
    $withAthletePositions: Boolean = true
    $withAcademicYear: Boolean = true
    $withAthleticYear: Boolean = true
    $withAthleteStatus: Boolean = true
    $withHasScholarship: Boolean = true
    $withScholarshipAmount: Boolean = true
    $withMajor: Boolean = true
    $withTeams: Boolean = true
    $tmpYearId: ID
    $yavTmpYearId: ID
    $personIds: [Int]
  ) {
    people(
      before: $before
      after: $after
      first: $first
      last: $last
      sort: $sort
      search: $search
      teams: $teams
      userTypes: $userTypes
      trip: $trip
      athleteStatus: $athleteStatus
      customGroup: $customGroup
      active: $activeStatus
      advanced: $advanced
      columns: $columns
      viaSavedView: $viaSavedView
      withAddresses: $withAddresses
      withAutomobile: $withAutomobile
      withContacts: $withContacts
      withRoommates: $withRoommates
      withTravelDocument: $withTravelDocument
      withFirstName: $withFirstName
      withLastName: $withLastName
      withMiddleName: $withMiddleName
      withNickName: $withNickName
      withGender: $withGender
      withTitle: $withTitle
      withBirthdate: $withBirthdate
      withStartDate: $withStartDate
      withWeight: $withWeight
      withNickname: $withNickname
      withEmail: $withEmail
      withAlternateEmail: $withAlternateEmail
      withCustomAttributes: $withCustomAttributes
      withYearlyAttributes: $withYearlyAttributes
      withHomePhone: $withHomePhone
      withMobilePhone: $withMobilePhone
      withOfficePhone: $withOfficePhone
      withOrgId: $withOrgId
      withFaxNumber: $withFaxNumber
      withFacebookAccount: $withFacebookAccount
      withTwitterAccount: $withTwitterAccount
      withLastLogin: $withLastLogin
      withLoginCount: $withLoginCount
      withDriversLicenseNumber: $withDriversLicenseNumber
      withDriversLicenseState: $withDriversLicenseState
      withDriversLicenseExpDate: $withDriversLicenseExpDate
      withJerseyNumber: $withJerseyNumber
      withAthletePositions: $withAthletePositions
      withAcademicYear: $withAcademicYear
      withAthleticYear: $withAthleticYear
      withAthleteStatus: $withAthleteStatus
      withHasScholarship: $withHasScholarship
      withScholarshipAmount: $withScholarshipAmount
      withMajor: $withMajor
      withTeams: $withTeams
      yavTmpYearId: $yavTmpYearId
      personIds: $personIds
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        person: node {
          id
          key: id
          displayName
          firstName @include(if: $withFirstName)
          lastName @include(if: $withLastName)
          middleName @include(if: $withMiddleName)
          nickname @include(if: $withNickName)
          gender @include(if: $withGender)
          title @include(if: $withTitle)
          dateOfBirth @include(if: $withBirthdate)
          orgId @include(if: $withOrgId)
          startDate @include(if: $withStartDate)
          travelWeight @include(if: $withWeight)
          preferredName @include(if: $withNickname)
          active
          initials
          pictureUrl
          emailAddress @include(if: $withEmail)
          emailAddressPersonal @include(if: $withAlternateEmail)
          customAttributeValues @include(if: $withCustomAttributes)
          yearlyAttributeValues(tmpYearId: $tmpYearId) @include(if: $withYearlyAttributes)
          phoneHome @include(if: $withHomePhone)
          mobilePhone @include(if: $withMobilePhone)
          phoneOffice @include(if: $withOfficePhone)
          phoneFax @include(if: $withFaxNumber)
          suffix
          schoolIdentifier
          selectionCode
          facebookAccount @include(if: $withFacebookAccount)
          twitterAccount @include(if: $withTwitterAccount)
          lastLoginDate @include(if: $withLastLogin)
          loginCount @include(if: $withLoginCount)
          usesExternalAuthentication
          hasLoginAccess
          isOrgLevelUser @include(if: $withIsOrgSuperuser)
          hasUserAccount
          driversLicenseNumber @include(if: $withDriversLicenseNumber)
          driversLicenseState @include(if: $withDriversLicenseState)
          driversLicenseExpirationDate @include(if: $withDriversLicenseExpDate)
          canViewAcademicDetails
          canViewAddressesDetails
          canViewAthleteDetails
          canViewContactDetails
          travelDocument @include(if: $withTravelDocument) {
            documentType
            documentNumber
            documentCountry
            documentExpDate
            nationality
            birthCountry
            residency
          }
          automobile @include(if: $withAutomobile) {
            make
            model
            stateCode
            year
            plateNumber
          }
          teamLabels @include(if: $withTeams)
          activeMemberships {
            id
            jerseyNumber @include(if: $withJerseyNumber)
            positionLabels @include(if: $withAthletePositions)
            academicYearLabel @include(if: $withAcademicYear)
            athleticYearLabel @include(if: $withAthleticYear)
            athleteStatus @include(if: $withAthleteStatus) {
              id
              label
            }
            hasScholarshipYesNo @include(if: $withHasScholarship)
            scholarshipAmount @include(if: $withScholarshipAmount)
            major @include(if: $withMajor)
            userTypeLabels
            isTeamSuperuser
            isOrgSuperuser
            profilePermissionsNameList @include(if: $withIsOrgSuperuser)
            team {
              id
              label
            }
          }
          roommates @include(if: $withRoommates) {
            id
            displayName
            emailAddress
            mobilePhone
          }
          addresses @include(if: $withAddresses) {
            id
            description
            street
            street2
            city
            state
            zip
            countryIso
            isMailing
          }
          contacts @include(if: $withContacts) {
            id
            displayName
            firstName
            lastName
            relationship {
              id
              label
            }
            emailAddress
            dateOfBirth
            phoneHome
            phoneCell
            phoneOffice
            maritalStatusLabel
            addresses {
              id
              street
              city
              state
              zip
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useAdvFetchPeopleQuery__
 *
 * To run a query within a React component, call `useAdvFetchPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvFetchPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvFetchPeopleQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      teams: // value for 'teams'
 *      userTypes: // value for 'userTypes'
 *      trip: // value for 'trip'
 *      athleteStatus: // value for 'athleteStatus'
 *      customGroup: // value for 'customGroup'
 *      activeStatus: // value for 'activeStatus'
 *      advanced: // value for 'advanced'
 *      columns: // value for 'columns'
 *      viaSavedView: // value for 'viaSavedView'
 *      withAddresses: // value for 'withAddresses'
 *      withAutomobile: // value for 'withAutomobile'
 *      withContacts: // value for 'withContacts'
 *      withRoommates: // value for 'withRoommates'
 *      withTravelDocument: // value for 'withTravelDocument'
 *      withIsOrgSuperuser: // value for 'withIsOrgSuperuser'
 *      withFirstName: // value for 'withFirstName'
 *      withLastName: // value for 'withLastName'
 *      withMiddleName: // value for 'withMiddleName'
 *      withNickName: // value for 'withNickName'
 *      withGender: // value for 'withGender'
 *      withBirthdate: // value for 'withBirthdate'
 *      withOrgId: // value for 'withOrgId'
 *      withStartDate: // value for 'withStartDate'
 *      withWeight: // value for 'withWeight'
 *      withNickname: // value for 'withNickname'
 *      withTitle: // value for 'withTitle'
 *      withEmail: // value for 'withEmail'
 *      withAlternateEmail: // value for 'withAlternateEmail'
 *      withCustomAttributes: // value for 'withCustomAttributes'
 *      withYearlyAttributes: // value for 'withYearlyAttributes'
 *      withHomePhone: // value for 'withHomePhone'
 *      withMobilePhone: // value for 'withMobilePhone'
 *      withOfficePhone: // value for 'withOfficePhone'
 *      withFaxNumber: // value for 'withFaxNumber'
 *      withFacebookAccount: // value for 'withFacebookAccount'
 *      withTwitterAccount: // value for 'withTwitterAccount'
 *      withLastLogin: // value for 'withLastLogin'
 *      withLoginCount: // value for 'withLoginCount'
 *      withDriversLicenseNumber: // value for 'withDriversLicenseNumber'
 *      withDriversLicenseState: // value for 'withDriversLicenseState'
 *      withDriversLicenseExpDate: // value for 'withDriversLicenseExpDate'
 *      withJerseyNumber: // value for 'withJerseyNumber'
 *      withAthletePositions: // value for 'withAthletePositions'
 *      withAcademicYear: // value for 'withAcademicYear'
 *      withAthleticYear: // value for 'withAthleticYear'
 *      withAthleteStatus: // value for 'withAthleteStatus'
 *      withHasScholarship: // value for 'withHasScholarship'
 *      withScholarshipAmount: // value for 'withScholarshipAmount'
 *      withMajor: // value for 'withMajor'
 *      withTeams: // value for 'withTeams'
 *      tmpYearId: // value for 'tmpYearId'
 *      yavTmpYearId: // value for 'yavTmpYearId'
 *      personIds: // value for 'personIds'
 *   },
 * });
 */
export function useAdvFetchPeopleQuery(
  baseOptions?: Apollo.QueryHookOptions<AdvFetchPeopleQuery, AdvFetchPeopleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdvFetchPeopleQuery, AdvFetchPeopleQueryVariables>(
    AdvFetchPeopleDocument,
    options,
  );
}
export function useAdvFetchPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdvFetchPeopleQuery, AdvFetchPeopleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdvFetchPeopleQuery, AdvFetchPeopleQueryVariables>(
    AdvFetchPeopleDocument,
    options,
  );
}
export type AdvFetchPeopleQueryHookResult = ReturnType<typeof useAdvFetchPeopleQuery>;
export type AdvFetchPeopleLazyQueryHookResult = ReturnType<typeof useAdvFetchPeopleLazyQuery>;
export type AdvFetchPeopleQueryResult = Apollo.QueryResult<
  AdvFetchPeopleQuery,
  AdvFetchPeopleQueryVariables
>;
export function refetchAdvFetchPeopleQuery(variables?: AdvFetchPeopleQueryVariables) {
  return { query: AdvFetchPeopleDocument, variables: variables };
}
export const AttributeBulkUpdateDocument = gql`
  mutation AttributeBulkUpdate($input: BulkAttributeInput!) {
    attributeBulkUpdate(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type AttributeBulkUpdateMutationFn = Apollo.MutationFunction<
  AttributeBulkUpdateMutation,
  AttributeBulkUpdateMutationVariables
>;

/**
 * __useAttributeBulkUpdateMutation__
 *
 * To run a mutation, you first call `useAttributeBulkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeBulkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeBulkUpdateMutation, { data, loading, error }] = useAttributeBulkUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttributeBulkUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttributeBulkUpdateMutation,
    AttributeBulkUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttributeBulkUpdateMutation, AttributeBulkUpdateMutationVariables>(
    AttributeBulkUpdateDocument,
    options,
  );
}
export type AttributeBulkUpdateMutationHookResult = ReturnType<
  typeof useAttributeBulkUpdateMutation
>;
export type AttributeBulkUpdateMutationResult = Apollo.MutationResult<AttributeBulkUpdateMutation>;
export type AttributeBulkUpdateMutationOptions = Apollo.BaseMutationOptions<
  AttributeBulkUpdateMutation,
  AttributeBulkUpdateMutationVariables
>;
export const AttributeUpdateDocument = gql`
  mutation AttributeUpdate($personId: ID!, $input: AttributeInput!) {
    attributeUpdate(personId: $personId, input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type AttributeUpdateMutationFn = Apollo.MutationFunction<
  AttributeUpdateMutation,
  AttributeUpdateMutationVariables
>;

/**
 * __useAttributeUpdateMutation__
 *
 * To run a mutation, you first call `useAttributeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeUpdateMutation, { data, loading, error }] = useAttributeUpdateMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttributeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttributeUpdateMutation,
    AttributeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttributeUpdateMutation, AttributeUpdateMutationVariables>(
    AttributeUpdateDocument,
    options,
  );
}
export type AttributeUpdateMutationHookResult = ReturnType<typeof useAttributeUpdateMutation>;
export type AttributeUpdateMutationResult = Apollo.MutationResult<AttributeUpdateMutation>;
export type AttributeUpdateMutationOptions = Apollo.BaseMutationOptions<
  AttributeUpdateMutation,
  AttributeUpdateMutationVariables
>;
export const AdvancedFiltersDocument = gql`
  query AdvancedFilters {
    attributeCategories {
      label
      coreCode
      attributes {
        id
        label
        dataType
        optionsList
        coreCode
        isCustom
        extendedOptionsList {
          label
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useAdvancedFiltersQuery__
 *
 * To run a query within a React component, call `useAdvancedFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancedFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancedFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdvancedFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<AdvancedFiltersQuery, AdvancedFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdvancedFiltersQuery, AdvancedFiltersQueryVariables>(
    AdvancedFiltersDocument,
    options,
  );
}
export function useAdvancedFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdvancedFiltersQuery, AdvancedFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdvancedFiltersQuery, AdvancedFiltersQueryVariables>(
    AdvancedFiltersDocument,
    options,
  );
}
export type AdvancedFiltersQueryHookResult = ReturnType<typeof useAdvancedFiltersQuery>;
export type AdvancedFiltersLazyQueryHookResult = ReturnType<typeof useAdvancedFiltersLazyQuery>;
export type AdvancedFiltersQueryResult = Apollo.QueryResult<
  AdvancedFiltersQuery,
  AdvancedFiltersQueryVariables
>;
export function refetchAdvancedFiltersQuery(variables?: AdvancedFiltersQueryVariables) {
  return { query: AdvancedFiltersDocument, variables: variables };
}
export const FetchAppAssignmentsDocument = gql`
  query FetchAppAssignments(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $search: String
    $sort: [PersonSortEnum]
    $teams: [ID]
    $userTypes: [ID]
    $athleteStatus: ID
    $active: Boolean = true
    $hasLoginAccess: Boolean = true
  ) {
    viewer {
      currentOrg {
        id
        installedApps {
          id
          installationId
          name
        }
      }
    }
    people(
      before: $before
      after: $after
      first: $first
      last: $last
      search: $search
      sort: $sort
      teams: $teams
      userTypes: $userTypes
      athleteStatus: $athleteStatus
      active: $active
      hasLoginAccess: $hasLoginAccess
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        person: node {
          id
          key: selectionCode
          displayName
          emailAddress
          schoolIdentifier
          activeMemberships {
            id
            userTypeLabels
            team {
              id
              label
            }
          }
          assignedApps {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useFetchAppAssignmentsQuery__
 *
 * To run a query within a React component, call `useFetchAppAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAppAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAppAssignmentsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      teams: // value for 'teams'
 *      userTypes: // value for 'userTypes'
 *      athleteStatus: // value for 'athleteStatus'
 *      active: // value for 'active'
 *      hasLoginAccess: // value for 'hasLoginAccess'
 *   },
 * });
 */
export function useFetchAppAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAppAssignmentsQuery,
    FetchAppAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAppAssignmentsQuery, FetchAppAssignmentsQueryVariables>(
    FetchAppAssignmentsDocument,
    options,
  );
}
export function useFetchAppAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAppAssignmentsQuery,
    FetchAppAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAppAssignmentsQuery, FetchAppAssignmentsQueryVariables>(
    FetchAppAssignmentsDocument,
    options,
  );
}
export type FetchAppAssignmentsQueryHookResult = ReturnType<typeof useFetchAppAssignmentsQuery>;
export type FetchAppAssignmentsLazyQueryHookResult = ReturnType<
  typeof useFetchAppAssignmentsLazyQuery
>;
export type FetchAppAssignmentsQueryResult = Apollo.QueryResult<
  FetchAppAssignmentsQuery,
  FetchAppAssignmentsQueryVariables
>;
export function refetchFetchAppAssignmentsQuery(variables?: FetchAppAssignmentsQueryVariables) {
  return { query: FetchAppAssignmentsDocument, variables: variables };
}
export const UserPermissionsDocument = gql`
  query UserPermissions {
    permissions(sort: code_name_asc, onlyAvailable: true) {
      edges {
        node {
          id
          category
          codeName
          displayName
          Dependencies
          grantedBy
          personTypeFilter
          tooltip
        }
      }
    }
  }
`;

/**
 * __useUserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
    UserPermissionsDocument,
    options,
  );
}
export function useUserPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
    UserPermissionsDocument,
    options,
  );
}
export type UserPermissionsQueryHookResult = ReturnType<typeof useUserPermissionsQuery>;
export type UserPermissionsLazyQueryHookResult = ReturnType<typeof useUserPermissionsLazyQuery>;
export type UserPermissionsQueryResult = Apollo.QueryResult<
  UserPermissionsQuery,
  UserPermissionsQueryVariables
>;
export function refetchUserPermissionsQuery(variables?: UserPermissionsQueryVariables) {
  return { query: UserPermissionsDocument, variables: variables };
}
export const FetchPeopleDocument = gql`
  query FetchPeople(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sort: [PersonSortEnum]
    $search: String
    $teams: [ID]
    $userTypes: [ID]
    $athleteStatus: ID
    $activeStatus: Boolean
    $advanced: JSONString
  ) {
    people(
      before: $before
      after: $after
      first: $first
      last: $last
      sort: $sort
      search: $search
      teams: $teams
      userTypes: $userTypes
      athleteStatus: $athleteStatus
      active: $activeStatus
      advanced: $advanced
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        person: node {
          id
          key: id
          displayName
          preferredName
          active
          initials
          pictureUrl
          title
          emailAddress
          mobilePhone
          lastLoginDate
          loginCount
          usesExternalAuthentication
          hasLoginAccess
          isOrgLevelUser
          hasUserAccount
          activeMemberships {
            id
            team {
              id
              label
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useFetchPeopleQuery__
 *
 * To run a query within a React component, call `useFetchPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPeopleQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      teams: // value for 'teams'
 *      userTypes: // value for 'userTypes'
 *      athleteStatus: // value for 'athleteStatus'
 *      activeStatus: // value for 'activeStatus'
 *      advanced: // value for 'advanced'
 *   },
 * });
 */
export function useFetchPeopleQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchPeopleQuery, FetchPeopleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchPeopleQuery, FetchPeopleQueryVariables>(FetchPeopleDocument, options);
}
export function useFetchPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPeopleQuery, FetchPeopleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchPeopleQuery, FetchPeopleQueryVariables>(
    FetchPeopleDocument,
    options,
  );
}
export type FetchPeopleQueryHookResult = ReturnType<typeof useFetchPeopleQuery>;
export type FetchPeopleLazyQueryHookResult = ReturnType<typeof useFetchPeopleLazyQuery>;
export type FetchPeopleQueryResult = Apollo.QueryResult<
  FetchPeopleQuery,
  FetchPeopleQueryVariables
>;
export function refetchFetchPeopleQuery(variables?: FetchPeopleQueryVariables) {
  return { query: FetchPeopleDocument, variables: variables };
}
export const PeopleSelectionCodesDocument = gql`
  query PeopleSelectionCodes(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sort: [PersonSortEnum]
    $search: String
    $teams: [ID]
    $personIds: [Int]
    $userTypes: [ID]
    $athleteStatus: ID
    $activeStatus: Boolean
    $advanced: JSONString
    $viaSavedView: Boolean
  ) {
    people(
      before: $before
      after: $after
      first: $first
      last: $last
      sort: $sort
      search: $search
      teams: $teams
      personIds: $personIds
      userTypes: $userTypes
      athleteStatus: $athleteStatus
      active: $activeStatus
      advanced: $advanced
      messageableOnly: true
      viaSavedView: $viaSavedView
    ) {
      edges {
        cursor
        person: node {
          id
          selectionCode
        }
      }
    }
  }
`;

/**
 * __usePeopleSelectionCodesQuery__
 *
 * To run a query within a React component, call `usePeopleSelectionCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleSelectionCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleSelectionCodesQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      teams: // value for 'teams'
 *      personIds: // value for 'personIds'
 *      userTypes: // value for 'userTypes'
 *      athleteStatus: // value for 'athleteStatus'
 *      activeStatus: // value for 'activeStatus'
 *      advanced: // value for 'advanced'
 *      viaSavedView: // value for 'viaSavedView'
 *   },
 * });
 */
export function usePeopleSelectionCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PeopleSelectionCodesQuery,
    PeopleSelectionCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PeopleSelectionCodesQuery, PeopleSelectionCodesQueryVariables>(
    PeopleSelectionCodesDocument,
    options,
  );
}
export function usePeopleSelectionCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeopleSelectionCodesQuery,
    PeopleSelectionCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PeopleSelectionCodesQuery, PeopleSelectionCodesQueryVariables>(
    PeopleSelectionCodesDocument,
    options,
  );
}
export type PeopleSelectionCodesQueryHookResult = ReturnType<typeof usePeopleSelectionCodesQuery>;
export type PeopleSelectionCodesLazyQueryHookResult = ReturnType<
  typeof usePeopleSelectionCodesLazyQuery
>;
export type PeopleSelectionCodesQueryResult = Apollo.QueryResult<
  PeopleSelectionCodesQuery,
  PeopleSelectionCodesQueryVariables
>;
export function refetchPeopleSelectionCodesQuery(variables?: PeopleSelectionCodesQueryVariables) {
  return { query: PeopleSelectionCodesDocument, variables: variables };
}
export const ResetPasswordForPersonDocument = gql`
  mutation ResetPasswordForPerson($id: ID!, $input: PersonSendInvitationInput!) {
    personResetPassword(id: $id, input: $input) {
      passwordResetLink
      person {
        id
        displayName
      }
      sendEmail
      sendSms
      userErrors {
        field
        message
        code
      }
    }
  }
`;
export type ResetPasswordForPersonMutationFn = Apollo.MutationFunction<
  ResetPasswordForPersonMutation,
  ResetPasswordForPersonMutationVariables
>;

/**
 * __useResetPasswordForPersonMutation__
 *
 * To run a mutation, you first call `useResetPasswordForPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordForPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordForPersonMutation, { data, loading, error }] = useResetPasswordForPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordForPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordForPersonMutation,
    ResetPasswordForPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordForPersonMutation,
    ResetPasswordForPersonMutationVariables
  >(ResetPasswordForPersonDocument, options);
}
export type ResetPasswordForPersonMutationHookResult = ReturnType<
  typeof useResetPasswordForPersonMutation
>;
export type ResetPasswordForPersonMutationResult =
  Apollo.MutationResult<ResetPasswordForPersonMutation>;
export type ResetPasswordForPersonMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordForPersonMutation,
  ResetPasswordForPersonMutationVariables
>;
export const SavedReportsDocument = gql`
  query SavedReports {
    mySavedReports {
      edges {
        node {
          id
          label
          filters
        }
      }
    }
  }
`;

/**
 * __useSavedReportsQuery__
 *
 * To run a query within a React component, call `useSavedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<SavedReportsQuery, SavedReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SavedReportsQuery, SavedReportsQueryVariables>(
    SavedReportsDocument,
    options,
  );
}
export function useSavedReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SavedReportsQuery, SavedReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SavedReportsQuery, SavedReportsQueryVariables>(
    SavedReportsDocument,
    options,
  );
}
export type SavedReportsQueryHookResult = ReturnType<typeof useSavedReportsQuery>;
export type SavedReportsLazyQueryHookResult = ReturnType<typeof useSavedReportsLazyQuery>;
export type SavedReportsQueryResult = Apollo.QueryResult<
  SavedReportsQuery,
  SavedReportsQueryVariables
>;
export function refetchSavedReportsQuery(variables?: SavedReportsQueryVariables) {
  return { query: SavedReportsDocument, variables: variables };
}
export const SavedReportCreateDocument = gql`
  mutation SavedReportCreate($input: SavedReportInput) {
    savedReportCreate(input: $input) {
      savedReport {
        id
        label
      }
    }
  }
`;
export type SavedReportCreateMutationFn = Apollo.MutationFunction<
  SavedReportCreateMutation,
  SavedReportCreateMutationVariables
>;

/**
 * __useSavedReportCreateMutation__
 *
 * To run a mutation, you first call `useSavedReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavedReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savedReportCreateMutation, { data, loading, error }] = useSavedReportCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavedReportCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavedReportCreateMutation,
    SavedReportCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SavedReportCreateMutation, SavedReportCreateMutationVariables>(
    SavedReportCreateDocument,
    options,
  );
}
export type SavedReportCreateMutationHookResult = ReturnType<typeof useSavedReportCreateMutation>;
export type SavedReportCreateMutationResult = Apollo.MutationResult<SavedReportCreateMutation>;
export type SavedReportCreateMutationOptions = Apollo.BaseMutationOptions<
  SavedReportCreateMutation,
  SavedReportCreateMutationVariables
>;
export const SavedReportDeleteDocument = gql`
  mutation SavedReportDelete($id: ID!) {
    savedReportDelete(id: $id) {
      savedReport {
        id
        label
      }
    }
  }
`;
export type SavedReportDeleteMutationFn = Apollo.MutationFunction<
  SavedReportDeleteMutation,
  SavedReportDeleteMutationVariables
>;

/**
 * __useSavedReportDeleteMutation__
 *
 * To run a mutation, you first call `useSavedReportDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavedReportDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savedReportDeleteMutation, { data, loading, error }] = useSavedReportDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSavedReportDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavedReportDeleteMutation,
    SavedReportDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SavedReportDeleteMutation, SavedReportDeleteMutationVariables>(
    SavedReportDeleteDocument,
    options,
  );
}
export type SavedReportDeleteMutationHookResult = ReturnType<typeof useSavedReportDeleteMutation>;
export type SavedReportDeleteMutationResult = Apollo.MutationResult<SavedReportDeleteMutation>;
export type SavedReportDeleteMutationOptions = Apollo.BaseMutationOptions<
  SavedReportDeleteMutation,
  SavedReportDeleteMutationVariables
>;
export const SavedReportUpdateDocument = gql`
  mutation SavedReportUpdate($id: ID!, $input: SavedReportUpdateInput) {
    savedReportUpdate(input: $input, id: $id) {
      savedReport {
        id
        label
      }
    }
  }
`;
export type SavedReportUpdateMutationFn = Apollo.MutationFunction<
  SavedReportUpdateMutation,
  SavedReportUpdateMutationVariables
>;

/**
 * __useSavedReportUpdateMutation__
 *
 * To run a mutation, you first call `useSavedReportUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavedReportUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savedReportUpdateMutation, { data, loading, error }] = useSavedReportUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavedReportUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavedReportUpdateMutation,
    SavedReportUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SavedReportUpdateMutation, SavedReportUpdateMutationVariables>(
    SavedReportUpdateDocument,
    options,
  );
}
export type SavedReportUpdateMutationHookResult = ReturnType<typeof useSavedReportUpdateMutation>;
export type SavedReportUpdateMutationResult = Apollo.MutationResult<SavedReportUpdateMutation>;
export type SavedReportUpdateMutationOptions = Apollo.BaseMutationOptions<
  SavedReportUpdateMutation,
  SavedReportUpdateMutationVariables
>;
export const UpdateAppAssignmentsDocument = gql`
  mutation UpdateAppAssignments($input: AppAssignmentsUpdateInput) {
    appAssignmentsUpdate(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateAppAssignmentsMutationFn = Apollo.MutationFunction<
  UpdateAppAssignmentsMutation,
  UpdateAppAssignmentsMutationVariables
>;

/**
 * __useUpdateAppAssignmentsMutation__
 *
 * To run a mutation, you first call `useUpdateAppAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppAssignmentsMutation, { data, loading, error }] = useUpdateAppAssignmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppAssignmentsMutation,
    UpdateAppAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppAssignmentsMutation, UpdateAppAssignmentsMutationVariables>(
    UpdateAppAssignmentsDocument,
    options,
  );
}
export type UpdateAppAssignmentsMutationHookResult = ReturnType<
  typeof useUpdateAppAssignmentsMutation
>;
export type UpdateAppAssignmentsMutationResult =
  Apollo.MutationResult<UpdateAppAssignmentsMutation>;
export type UpdateAppAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppAssignmentsMutation,
  UpdateAppAssignmentsMutationVariables
>;
export const CreatePersonDocument = gql`
  mutation CreatePerson($input: PersonInput!) {
    personCreate(input: $input) {
      person {
        id
        displayName
        preferredName
        initials
        pictureUrl
        title
        gender
        inviteUrl
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CreatePersonMutationFn = Apollo.MutationFunction<
  CreatePersonMutation,
  CreatePersonMutationVariables
>;

/**
 * __useCreatePersonMutation__
 *
 * To run a mutation, you first call `useCreatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonMutation, { data, loading, error }] = useCreatePersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePersonMutation, CreatePersonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePersonMutation, CreatePersonMutationVariables>(
    CreatePersonDocument,
    options,
  );
}
export type CreatePersonMutationHookResult = ReturnType<typeof useCreatePersonMutation>;
export type CreatePersonMutationResult = Apollo.MutationResult<CreatePersonMutation>;
export type CreatePersonMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonMutation,
  CreatePersonMutationVariables
>;
export const GetOptionsForAthletesDocument = gql`
  query GetOptionsForAthletes($teamId: ID, $team: TWFilter_Int, $withRoster: Boolean = false) {
    eligibilityYears {
      value: id
      label
    }
    sportPositions(teamId: $teamId) {
      value: id
      label
    }
    athleticYears {
      value: id
      label
    }
    teamRosters(teamId: $team) @include(if: $withRoster) {
      edges {
        node {
          id
          tmpYear {
            label
          }
        }
      }
    }
  }
`;

/**
 * __useGetOptionsForAthletesQuery__
 *
 * To run a query within a React component, call `useGetOptionsForAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionsForAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionsForAthletesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      team: // value for 'team'
 *      withRoster: // value for 'withRoster'
 *   },
 * });
 */
export function useGetOptionsForAthletesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOptionsForAthletesQuery,
    GetOptionsForAthletesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOptionsForAthletesQuery, GetOptionsForAthletesQueryVariables>(
    GetOptionsForAthletesDocument,
    options,
  );
}
export function useGetOptionsForAthletesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOptionsForAthletesQuery,
    GetOptionsForAthletesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOptionsForAthletesQuery, GetOptionsForAthletesQueryVariables>(
    GetOptionsForAthletesDocument,
    options,
  );
}
export type GetOptionsForAthletesQueryHookResult = ReturnType<typeof useGetOptionsForAthletesQuery>;
export type GetOptionsForAthletesLazyQueryHookResult = ReturnType<
  typeof useGetOptionsForAthletesLazyQuery
>;
export type GetOptionsForAthletesQueryResult = Apollo.QueryResult<
  GetOptionsForAthletesQuery,
  GetOptionsForAthletesQueryVariables
>;
export function refetchGetOptionsForAthletesQuery(variables?: GetOptionsForAthletesQueryVariables) {
  return { query: GetOptionsForAthletesDocument, variables: variables };
}
export const GetPersonByEmailOrPhoneDocument = gql`
  query GetPersonByEmailOrPhone($emailAddress: String, $mobilePhone: String) {
    people(emailAddress: $emailAddress, mobilePhone: $mobilePhone) {
      edges {
        person: node {
          key: id
          displayName
          preferredName
          initials
          pictureUrl
          title
          emailAddress
          mobilePhone
          teams {
            label
            canAddToTeam
            canRemoveFromTeam
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetPersonByEmailOrPhoneQuery__
 *
 * To run a query within a React component, call `useGetPersonByEmailOrPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonByEmailOrPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonByEmailOrPhoneQuery({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      mobilePhone: // value for 'mobilePhone'
 *   },
 * });
 */
export function useGetPersonByEmailOrPhoneQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPersonByEmailOrPhoneQuery,
    GetPersonByEmailOrPhoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonByEmailOrPhoneQuery, GetPersonByEmailOrPhoneQueryVariables>(
    GetPersonByEmailOrPhoneDocument,
    options,
  );
}
export function useGetPersonByEmailOrPhoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonByEmailOrPhoneQuery,
    GetPersonByEmailOrPhoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonByEmailOrPhoneQuery, GetPersonByEmailOrPhoneQueryVariables>(
    GetPersonByEmailOrPhoneDocument,
    options,
  );
}
export type GetPersonByEmailOrPhoneQueryHookResult = ReturnType<
  typeof useGetPersonByEmailOrPhoneQuery
>;
export type GetPersonByEmailOrPhoneLazyQueryHookResult = ReturnType<
  typeof useGetPersonByEmailOrPhoneLazyQuery
>;
export type GetPersonByEmailOrPhoneQueryResult = Apollo.QueryResult<
  GetPersonByEmailOrPhoneQuery,
  GetPersonByEmailOrPhoneQueryVariables
>;
export function refetchGetPersonByEmailOrPhoneQuery(
  variables?: GetPersonByEmailOrPhoneQueryVariables,
) {
  return { query: GetPersonByEmailOrPhoneDocument, variables: variables };
}
export const PersonAddTeamMembershipDocument = gql`
  mutation PersonAddTeamMembership($id: ID!, $teamId: ID!, $input: PersonInput!) {
    personAddTeamMembership(id: $id, teamId: $teamId, input: $input) {
      person {
        id
        displayName
        preferredName
        initials
        pictureUrl
        title
        gender
        teamMembers {
          edges {
            node {
              id
              active
            }
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type PersonAddTeamMembershipMutationFn = Apollo.MutationFunction<
  PersonAddTeamMembershipMutation,
  PersonAddTeamMembershipMutationVariables
>;

/**
 * __usePersonAddTeamMembershipMutation__
 *
 * To run a mutation, you first call `usePersonAddTeamMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonAddTeamMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personAddTeamMembershipMutation, { data, loading, error }] = usePersonAddTeamMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonAddTeamMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonAddTeamMembershipMutation,
    PersonAddTeamMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PersonAddTeamMembershipMutation,
    PersonAddTeamMembershipMutationVariables
  >(PersonAddTeamMembershipDocument, options);
}
export type PersonAddTeamMembershipMutationHookResult = ReturnType<
  typeof usePersonAddTeamMembershipMutation
>;
export type PersonAddTeamMembershipMutationResult =
  Apollo.MutationResult<PersonAddTeamMembershipMutation>;
export type PersonAddTeamMembershipMutationOptions = Apollo.BaseMutationOptions<
  PersonAddTeamMembershipMutation,
  PersonAddTeamMembershipMutationVariables
>;
export const CustomGroupDocument = gql`
  query CustomGroup($id: ID!) {
    customGroup: node(id: $id) {
      ... on CustomGroupNode {
        id
        name
        memberCount
        members {
          label
          selectionCode
          ... on SelectionGroup {
            groupType
            pluralLabel
            people {
              label: displayName
              lastName
              initials
              pictureUrl
              selectionCode
              id
            }
          }
          ... on PersonNode {
            lastName
            displayName
            initials
            pictureUrl
          }
        }
        team {
          id
          label
        }
      }
    }
  }
`;

/**
 * __useCustomGroupQuery__
 *
 * To run a query within a React component, call `useCustomGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomGroupQuery(
  baseOptions: Apollo.QueryHookOptions<CustomGroupQuery, CustomGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomGroupQuery, CustomGroupQueryVariables>(CustomGroupDocument, options);
}
export function useCustomGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomGroupQuery, CustomGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomGroupQuery, CustomGroupQueryVariables>(
    CustomGroupDocument,
    options,
  );
}
export type CustomGroupQueryHookResult = ReturnType<typeof useCustomGroupQuery>;
export type CustomGroupLazyQueryHookResult = ReturnType<typeof useCustomGroupLazyQuery>;
export type CustomGroupQueryResult = Apollo.QueryResult<
  CustomGroupQuery,
  CustomGroupQueryVariables
>;
export function refetchCustomGroupQuery(variables: CustomGroupQueryVariables) {
  return { query: CustomGroupDocument, variables: variables };
}
export const CustomGroupCreateDocument = gql`
  mutation CustomGroupCreate($input: CustomGroupInput!) {
    customGroupCreate(input: $input) {
      customGroup {
        name
        team {
          id
          label
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CustomGroupCreateMutationFn = Apollo.MutationFunction<
  CustomGroupCreateMutation,
  CustomGroupCreateMutationVariables
>;

/**
 * __useCustomGroupCreateMutation__
 *
 * To run a mutation, you first call `useCustomGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customGroupCreateMutation, { data, loading, error }] = useCustomGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomGroupCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomGroupCreateMutation,
    CustomGroupCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomGroupCreateMutation, CustomGroupCreateMutationVariables>(
    CustomGroupCreateDocument,
    options,
  );
}
export type CustomGroupCreateMutationHookResult = ReturnType<typeof useCustomGroupCreateMutation>;
export type CustomGroupCreateMutationResult = Apollo.MutationResult<CustomGroupCreateMutation>;
export type CustomGroupCreateMutationOptions = Apollo.BaseMutationOptions<
  CustomGroupCreateMutation,
  CustomGroupCreateMutationVariables
>;
export const CustomGroupDeleteDocument = gql`
  mutation CustomGroupDelete($id: ID!) {
    customGroupDelete(id: $id) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type CustomGroupDeleteMutationFn = Apollo.MutationFunction<
  CustomGroupDeleteMutation,
  CustomGroupDeleteMutationVariables
>;

/**
 * __useCustomGroupDeleteMutation__
 *
 * To run a mutation, you first call `useCustomGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customGroupDeleteMutation, { data, loading, error }] = useCustomGroupDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomGroupDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomGroupDeleteMutation,
    CustomGroupDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomGroupDeleteMutation, CustomGroupDeleteMutationVariables>(
    CustomGroupDeleteDocument,
    options,
  );
}
export type CustomGroupDeleteMutationHookResult = ReturnType<typeof useCustomGroupDeleteMutation>;
export type CustomGroupDeleteMutationResult = Apollo.MutationResult<CustomGroupDeleteMutation>;
export type CustomGroupDeleteMutationOptions = Apollo.BaseMutationOptions<
  CustomGroupDeleteMutation,
  CustomGroupDeleteMutationVariables
>;
export const CustomGroupUpdateDocument = gql`
  mutation CustomGroupUpdate($id: ID!, $input: CustomGroupInput!) {
    customGroupUpdate(id: $id, input: $input) {
      userErrors {
        field
        message
      }
      customGroup {
        id
        name
        members {
          label
          selectionCode
        }
      }
    }
  }
`;
export type CustomGroupUpdateMutationFn = Apollo.MutationFunction<
  CustomGroupUpdateMutation,
  CustomGroupUpdateMutationVariables
>;

/**
 * __useCustomGroupUpdateMutation__
 *
 * To run a mutation, you first call `useCustomGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customGroupUpdateMutation, { data, loading, error }] = useCustomGroupUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomGroupUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomGroupUpdateMutation,
    CustomGroupUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomGroupUpdateMutation, CustomGroupUpdateMutationVariables>(
    CustomGroupUpdateDocument,
    options,
  );
}
export type CustomGroupUpdateMutationHookResult = ReturnType<typeof useCustomGroupUpdateMutation>;
export type CustomGroupUpdateMutationResult = Apollo.MutationResult<CustomGroupUpdateMutation>;
export type CustomGroupUpdateMutationOptions = Apollo.BaseMutationOptions<
  CustomGroupUpdateMutation,
  CustomGroupUpdateMutationVariables
>;
export const CustomGroupsDocument = gql`
  query CustomGroups(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $search: String
    $sort: [CustomGroupSortEnum]
    $creator: String
    $teams: [ID]
  ) {
    customGroups(
      before: $before
      after: $after
      first: $first
      last: $last
      sort: $sort
      search: $search
      creator: $creator
      teams: $teams
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        customGroup: node {
          id
          name
          memberCount
          team {
            id
            label
          }
          creator {
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useCustomGroupsQuery__
 *
 * To run a query within a React component, call `useCustomGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomGroupsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      creator: // value for 'creator'
 *      teams: // value for 'teams'
 *   },
 * });
 */
export function useCustomGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomGroupsQuery, CustomGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomGroupsQuery, CustomGroupsQueryVariables>(
    CustomGroupsDocument,
    options,
  );
}
export function useCustomGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomGroupsQuery, CustomGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomGroupsQuery, CustomGroupsQueryVariables>(
    CustomGroupsDocument,
    options,
  );
}
export type CustomGroupsQueryHookResult = ReturnType<typeof useCustomGroupsQuery>;
export type CustomGroupsLazyQueryHookResult = ReturnType<typeof useCustomGroupsLazyQuery>;
export type CustomGroupsQueryResult = Apollo.QueryResult<
  CustomGroupsQuery,
  CustomGroupsQueryVariables
>;
export function refetchCustomGroupsQuery(variables?: CustomGroupsQueryVariables) {
  return { query: CustomGroupsDocument, variables: variables };
}
export const AthleteBulkUpdateDocument = gql`
  mutation AthleteBulkUpdate($input: AthleteBulkUpdateInput!) {
    athleteBulkUpdate(input: $input) {
      bulkUserErrors {
        objectKey
        errorList {
          field
          message
        }
      }
    }
  }
`;
export type AthleteBulkUpdateMutationFn = Apollo.MutationFunction<
  AthleteBulkUpdateMutation,
  AthleteBulkUpdateMutationVariables
>;

/**
 * __useAthleteBulkUpdateMutation__
 *
 * To run a mutation, you first call `useAthleteBulkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteBulkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteBulkUpdateMutation, { data, loading, error }] = useAthleteBulkUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAthleteBulkUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AthleteBulkUpdateMutation,
    AthleteBulkUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AthleteBulkUpdateMutation, AthleteBulkUpdateMutationVariables>(
    AthleteBulkUpdateDocument,
    options,
  );
}
export type AthleteBulkUpdateMutationHookResult = ReturnType<typeof useAthleteBulkUpdateMutation>;
export type AthleteBulkUpdateMutationResult = Apollo.MutationResult<AthleteBulkUpdateMutation>;
export type AthleteBulkUpdateMutationOptions = Apollo.BaseMutationOptions<
  AthleteBulkUpdateMutation,
  AthleteBulkUpdateMutationVariables
>;
export const GetAthleteOptionsDocument = gql`
  query GetAthleteOptions($teamId: ID) {
    eligibilityYears {
      value: id
      label
    }
    sportPositions(teamId: $teamId) {
      value: id
      label
    }
  }
`;

/**
 * __useGetAthleteOptionsQuery__
 *
 * To run a query within a React component, call `useGetAthleteOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAthleteOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAthleteOptionsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetAthleteOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAthleteOptionsQuery, GetAthleteOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAthleteOptionsQuery, GetAthleteOptionsQueryVariables>(
    GetAthleteOptionsDocument,
    options,
  );
}
export function useGetAthleteOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAthleteOptionsQuery,
    GetAthleteOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAthleteOptionsQuery, GetAthleteOptionsQueryVariables>(
    GetAthleteOptionsDocument,
    options,
  );
}
export type GetAthleteOptionsQueryHookResult = ReturnType<typeof useGetAthleteOptionsQuery>;
export type GetAthleteOptionsLazyQueryHookResult = ReturnType<typeof useGetAthleteOptionsLazyQuery>;
export type GetAthleteOptionsQueryResult = Apollo.QueryResult<
  GetAthleteOptionsQuery,
  GetAthleteOptionsQueryVariables
>;
export function refetchGetAthleteOptionsQuery(variables?: GetAthleteOptionsQueryVariables) {
  return { query: GetAthleteOptionsDocument, variables: variables };
}
export const FetchAthletesDocument = gql`
  query FetchAthletes(
    $teams: [ID]
    $userType: ID
    $athleteStatuses: [ID]
    $first: Int
    $after: String
  ) {
    people(
      sort: [last_name_asc]
      teams: $teams
      userType: $userType
      athleteStatuses: $athleteStatuses
      active: true
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        person: node {
          id
          displayName
          active
          pictureUrl
          initials
          emailAddress
          mobilePhone
          hasLoginAccess
          schoolIdentifier
          activeMemberships {
            id
            jerseyNumber
            positionLabels
            academicYearLabel
            athleticYearLabel
            teamId
            athleteStatus {
              id
              label
            }
            userTypeLabels
            positions {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchAthletesQuery__
 *
 * To run a query within a React component, call `useFetchAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAthletesQuery({
 *   variables: {
 *      teams: // value for 'teams'
 *      userType: // value for 'userType'
 *      athleteStatuses: // value for 'athleteStatuses'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchAthletesQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAthletesQuery, FetchAthletesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAthletesQuery, FetchAthletesQueryVariables>(
    FetchAthletesDocument,
    options,
  );
}
export function useFetchAthletesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAthletesQuery, FetchAthletesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAthletesQuery, FetchAthletesQueryVariables>(
    FetchAthletesDocument,
    options,
  );
}
export type FetchAthletesQueryHookResult = ReturnType<typeof useFetchAthletesQuery>;
export type FetchAthletesLazyQueryHookResult = ReturnType<typeof useFetchAthletesLazyQuery>;
export type FetchAthletesQueryResult = Apollo.QueryResult<
  FetchAthletesQuery,
  FetchAthletesQueryVariables
>;
export function refetchFetchAthletesQuery(variables?: FetchAthletesQueryVariables) {
  return { query: FetchAthletesDocument, variables: variables };
}
export const FetchAthletesAcademicYearDocument = gql`
  query FetchAthletesAcademicYear($teams: [ID], $userType: ID, $athleteStatus: ID) {
    people(
      sort: [last_name_asc]
      teams: $teams
      userType: $userType
      athleteStatus: $athleteStatus
      active: true
    ) {
      edges {
        cursor
        person: node {
          id
          activeMemberships {
            academicYearLabel
            athleticYearLabel
            teamId
          }
        }
      }
    }
  }
`;

/**
 * __useFetchAthletesAcademicYearQuery__
 *
 * To run a query within a React component, call `useFetchAthletesAcademicYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAthletesAcademicYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAthletesAcademicYearQuery({
 *   variables: {
 *      teams: // value for 'teams'
 *      userType: // value for 'userType'
 *      athleteStatus: // value for 'athleteStatus'
 *   },
 * });
 */
export function useFetchAthletesAcademicYearQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAthletesAcademicYearQuery,
    FetchAthletesAcademicYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAthletesAcademicYearQuery, FetchAthletesAcademicYearQueryVariables>(
    FetchAthletesAcademicYearDocument,
    options,
  );
}
export function useFetchAthletesAcademicYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAthletesAcademicYearQuery,
    FetchAthletesAcademicYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchAthletesAcademicYearQuery,
    FetchAthletesAcademicYearQueryVariables
  >(FetchAthletesAcademicYearDocument, options);
}
export type FetchAthletesAcademicYearQueryHookResult = ReturnType<
  typeof useFetchAthletesAcademicYearQuery
>;
export type FetchAthletesAcademicYearLazyQueryHookResult = ReturnType<
  typeof useFetchAthletesAcademicYearLazyQuery
>;
export type FetchAthletesAcademicYearQueryResult = Apollo.QueryResult<
  FetchAthletesAcademicYearQuery,
  FetchAthletesAcademicYearQueryVariables
>;
export function refetchFetchAthletesAcademicYearQuery(
  variables?: FetchAthletesAcademicYearQueryVariables,
) {
  return { query: FetchAthletesAcademicYearDocument, variables: variables };
}
export const TeamMemberBulkUpdatePermissionsDocument = gql`
  mutation TeamMemberBulkUpdatePermissions($input: BulkUpdatePermissionsInput!) {
    teamMemberBulkUpdatePermissions(input: $input) {
      teamMember {
        id
      }
    }
  }
`;
export type TeamMemberBulkUpdatePermissionsMutationFn = Apollo.MutationFunction<
  TeamMemberBulkUpdatePermissionsMutation,
  TeamMemberBulkUpdatePermissionsMutationVariables
>;

/**
 * __useTeamMemberBulkUpdatePermissionsMutation__
 *
 * To run a mutation, you first call `useTeamMemberBulkUpdatePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberBulkUpdatePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamMemberBulkUpdatePermissionsMutation, { data, loading, error }] = useTeamMemberBulkUpdatePermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamMemberBulkUpdatePermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeamMemberBulkUpdatePermissionsMutation,
    TeamMemberBulkUpdatePermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TeamMemberBulkUpdatePermissionsMutation,
    TeamMemberBulkUpdatePermissionsMutationVariables
  >(TeamMemberBulkUpdatePermissionsDocument, options);
}
export type TeamMemberBulkUpdatePermissionsMutationHookResult = ReturnType<
  typeof useTeamMemberBulkUpdatePermissionsMutation
>;
export type TeamMemberBulkUpdatePermissionsMutationResult =
  Apollo.MutationResult<TeamMemberBulkUpdatePermissionsMutation>;
export type TeamMemberBulkUpdatePermissionsMutationOptions = Apollo.BaseMutationOptions<
  TeamMemberBulkUpdatePermissionsMutation,
  TeamMemberBulkUpdatePermissionsMutationVariables
>;
export const BulkAssignProfilePhotosDocument = gql`
  mutation BulkAssignProfilePhotos($input: BulkPersonPictureUploadInput!) {
    bulkAssignProfilePicture(input: $input) {
      person {
        lastName
      }
    }
  }
`;
export type BulkAssignProfilePhotosMutationFn = Apollo.MutationFunction<
  BulkAssignProfilePhotosMutation,
  BulkAssignProfilePhotosMutationVariables
>;

/**
 * __useBulkAssignProfilePhotosMutation__
 *
 * To run a mutation, you first call `useBulkAssignProfilePhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAssignProfilePhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAssignProfilePhotosMutation, { data, loading, error }] = useBulkAssignProfilePhotosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkAssignProfilePhotosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkAssignProfilePhotosMutation,
    BulkAssignProfilePhotosMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkAssignProfilePhotosMutation,
    BulkAssignProfilePhotosMutationVariables
  >(BulkAssignProfilePhotosDocument, options);
}
export type BulkAssignProfilePhotosMutationHookResult = ReturnType<
  typeof useBulkAssignProfilePhotosMutation
>;
export type BulkAssignProfilePhotosMutationResult =
  Apollo.MutationResult<BulkAssignProfilePhotosMutation>;
export type BulkAssignProfilePhotosMutationOptions = Apollo.BaseMutationOptions<
  BulkAssignProfilePhotosMutation,
  BulkAssignProfilePhotosMutationVariables
>;
export const FetchUsersInfoDocument = gql`
  query FetchUsersInfo($codes: [String]!) {
    selectables(codes: $codes) {
      label
      selectionCode
      ... on SelectionGroup {
        groupType
        pluralLabel
        people {
          id
          personId
          firstName
          lastName
          preferredName
          label
          selectionCode
          pictureUrl
          teamMembership {
            userTypes {
              namePlural
            }
          }
        }
      }
      ... on PersonNode {
        id
        personId
        firstName
        lastName
        preferredName
        initials
        pictureUrl
        teamMembership {
          userTypes {
            namePlural
          }
        }
      }
    }
  }
`;

/**
 * __useFetchUsersInfoQuery__
 *
 * To run a query within a React component, call `useFetchUsersInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersInfoQuery({
 *   variables: {
 *      codes: // value for 'codes'
 *   },
 * });
 */
export function useFetchUsersInfoQuery(
  baseOptions: Apollo.QueryHookOptions<FetchUsersInfoQuery, FetchUsersInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchUsersInfoQuery, FetchUsersInfoQueryVariables>(
    FetchUsersInfoDocument,
    options,
  );
}
export function useFetchUsersInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchUsersInfoQuery, FetchUsersInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchUsersInfoQuery, FetchUsersInfoQueryVariables>(
    FetchUsersInfoDocument,
    options,
  );
}
export type FetchUsersInfoQueryHookResult = ReturnType<typeof useFetchUsersInfoQuery>;
export type FetchUsersInfoLazyQueryHookResult = ReturnType<typeof useFetchUsersInfoLazyQuery>;
export type FetchUsersInfoQueryResult = Apollo.QueryResult<
  FetchUsersInfoQuery,
  FetchUsersInfoQueryVariables
>;
export function refetchFetchUsersInfoQuery(variables: FetchUsersInfoQueryVariables) {
  return { query: FetchUsersInfoDocument, variables: variables };
}
export const RemovePictureDocument = gql`
  mutation RemovePicture($input: DeleteUnassignedProfilePictureInput!) {
    deleteUnassignedProfilePicture(input: $input) {
      fileUuid
    }
  }
`;
export type RemovePictureMutationFn = Apollo.MutationFunction<
  RemovePictureMutation,
  RemovePictureMutationVariables
>;

/**
 * __useRemovePictureMutation__
 *
 * To run a mutation, you first call `useRemovePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePictureMutation, { data, loading, error }] = useRemovePictureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<RemovePictureMutation, RemovePictureMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePictureMutation, RemovePictureMutationVariables>(
    RemovePictureDocument,
    options,
  );
}
export type RemovePictureMutationHookResult = ReturnType<typeof useRemovePictureMutation>;
export type RemovePictureMutationResult = Apollo.MutationResult<RemovePictureMutation>;
export type RemovePictureMutationOptions = Apollo.BaseMutationOptions<
  RemovePictureMutation,
  RemovePictureMutationVariables
>;
export const UploadPictureDocument = gql`
  mutation UploadPicture($input: UploadProfilePictureInput!) {
    uploadProfilePicture(input: $input) {
      fileUuid
      fileUrl
      fileName
    }
  }
`;
export type UploadPictureMutationFn = Apollo.MutationFunction<
  UploadPictureMutation,
  UploadPictureMutationVariables
>;

/**
 * __useUploadPictureMutation__
 *
 * To run a mutation, you first call `useUploadPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPictureMutation, { data, loading, error }] = useUploadPictureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPictureMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadPictureMutation, UploadPictureMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadPictureMutation, UploadPictureMutationVariables>(
    UploadPictureDocument,
    options,
  );
}
export type UploadPictureMutationHookResult = ReturnType<typeof useUploadPictureMutation>;
export type UploadPictureMutationResult = Apollo.MutationResult<UploadPictureMutation>;
export type UploadPictureMutationOptions = Apollo.BaseMutationOptions<
  UploadPictureMutation,
  UploadPictureMutationVariables
>;
export const YearlyAttributeReportDocument = gql`
  query YearlyAttributeReport {
    yearlyAttributeReport {
      edges {
        node {
          createdDate
          downloadableFileId
          errorMessage
          kind
          status
          title
          url
        }
      }
    }
  }
`;

/**
 * __useYearlyAttributeReportQuery__
 *
 * To run a query within a React component, call `useYearlyAttributeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearlyAttributeReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useYearlyAttributeReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    YearlyAttributeReportQuery,
    YearlyAttributeReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<YearlyAttributeReportQuery, YearlyAttributeReportQueryVariables>(
    YearlyAttributeReportDocument,
    options,
  );
}
export function useYearlyAttributeReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    YearlyAttributeReportQuery,
    YearlyAttributeReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<YearlyAttributeReportQuery, YearlyAttributeReportQueryVariables>(
    YearlyAttributeReportDocument,
    options,
  );
}
export type YearlyAttributeReportQueryHookResult = ReturnType<typeof useYearlyAttributeReportQuery>;
export type YearlyAttributeReportLazyQueryHookResult = ReturnType<
  typeof useYearlyAttributeReportLazyQuery
>;
export type YearlyAttributeReportQueryResult = Apollo.QueryResult<
  YearlyAttributeReportQuery,
  YearlyAttributeReportQueryVariables
>;
export function refetchYearlyAttributeReportQuery(variables?: YearlyAttributeReportQueryVariables) {
  return { query: YearlyAttributeReportDocument, variables: variables };
}
export const YearlyAttributeReportCreateDocument = gql`
  mutation YearlyAttributeReportCreate($input: YearlyAttributeReportCreateInput) {
    yearlyAttributeReportCreate(input: $input) {
      yearlyAttributeReport {
        status
        title
        url
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type YearlyAttributeReportCreateMutationFn = Apollo.MutationFunction<
  YearlyAttributeReportCreateMutation,
  YearlyAttributeReportCreateMutationVariables
>;

/**
 * __useYearlyAttributeReportCreateMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeReportCreateMutation, { data, loading, error }] = useYearlyAttributeReportCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useYearlyAttributeReportCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeReportCreateMutation,
    YearlyAttributeReportCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeReportCreateMutation,
    YearlyAttributeReportCreateMutationVariables
  >(YearlyAttributeReportCreateDocument, options);
}
export type YearlyAttributeReportCreateMutationHookResult = ReturnType<
  typeof useYearlyAttributeReportCreateMutation
>;
export type YearlyAttributeReportCreateMutationResult =
  Apollo.MutationResult<YearlyAttributeReportCreateMutation>;
export type YearlyAttributeReportCreateMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeReportCreateMutation,
  YearlyAttributeReportCreateMutationVariables
>;
export const AddRoommateDocument = gql`
  mutation AddRoommate($id: ID!, $input: RoommateInput!) {
    roommateCreate(id: $id, input: $input) {
      person {
        id
        roommates {
          displayName
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type AddRoommateMutationFn = Apollo.MutationFunction<
  AddRoommateMutation,
  AddRoommateMutationVariables
>;

/**
 * __useAddRoommateMutation__
 *
 * To run a mutation, you first call `useAddRoommateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoommateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoommateMutation, { data, loading, error }] = useAddRoommateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRoommateMutation(
  baseOptions?: Apollo.MutationHookOptions<AddRoommateMutation, AddRoommateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRoommateMutation, AddRoommateMutationVariables>(
    AddRoommateDocument,
    options,
  );
}
export type AddRoommateMutationHookResult = ReturnType<typeof useAddRoommateMutation>;
export type AddRoommateMutationResult = Apollo.MutationResult<AddRoommateMutation>;
export type AddRoommateMutationOptions = Apollo.BaseMutationOptions<
  AddRoommateMutation,
  AddRoommateMutationVariables
>;
export const CreateContactDocument = gql`
  mutation createContact($id: ID!, $input: PersonContactInput!) {
    contactCreate(id: $id, input: $input) {
      userErrors {
        message
        field
      }
      person {
        id
        contacts {
          firstName
          lastName
          selectionCode
          id
          initials
          pictureUrl
          displayName
          mobilePhone
          dateOfBirth
          relationship {
            id
            label
          }
        }
      }
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(
    CreateContactDocument,
    options,
  );
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const ContactDeleteDocument = gql`
  mutation ContactDelete($id: ID!, $contactId: ID!) {
    contactDelete(id: $id, contactId: $contactId) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type ContactDeleteMutationFn = Apollo.MutationFunction<
  ContactDeleteMutation,
  ContactDeleteMutationVariables
>;

/**
 * __useContactDeleteMutation__
 *
 * To run a mutation, you first call `useContactDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactDeleteMutation, { data, loading, error }] = useContactDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useContactDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<ContactDeleteMutation, ContactDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContactDeleteMutation, ContactDeleteMutationVariables>(
    ContactDeleteDocument,
    options,
  );
}
export type ContactDeleteMutationHookResult = ReturnType<typeof useContactDeleteMutation>;
export type ContactDeleteMutationResult = Apollo.MutationResult<ContactDeleteMutation>;
export type ContactDeleteMutationOptions = Apollo.BaseMutationOptions<
  ContactDeleteMutation,
  ContactDeleteMutationVariables
>;
export const RoommateDeleteDocument = gql`
  mutation RoommateDelete($id: ID!, $roommateId: ID!) {
    roommateDelete(id: $id, roommateId: $roommateId) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type RoommateDeleteMutationFn = Apollo.MutationFunction<
  RoommateDeleteMutation,
  RoommateDeleteMutationVariables
>;

/**
 * __useRoommateDeleteMutation__
 *
 * To run a mutation, you first call `useRoommateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoommateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roommateDeleteMutation, { data, loading, error }] = useRoommateDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roommateId: // value for 'roommateId'
 *   },
 * });
 */
export function useRoommateDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<RoommateDeleteMutation, RoommateDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RoommateDeleteMutation, RoommateDeleteMutationVariables>(
    RoommateDeleteDocument,
    options,
  );
}
export type RoommateDeleteMutationHookResult = ReturnType<typeof useRoommateDeleteMutation>;
export type RoommateDeleteMutationResult = Apollo.MutationResult<RoommateDeleteMutation>;
export type RoommateDeleteMutationOptions = Apollo.BaseMutationOptions<
  RoommateDeleteMutation,
  RoommateDeleteMutationVariables
>;
export const FilesByPersonDocument = gql`
  query filesByPerson($personId: TWFilter_Int) {
    allSharedFiles(personId: $personId) {
      edges {
        node {
          id
          pk
          sharedFileId
          fileName
          fileNotes
          isPinned
          updatedDate
          canEditFile
          canDownload
          creator {
            createdBy
            pictureDownloadUrl
            preferredName
            lastName
            initials
          }
        }
      }
    }
  }
`;

/**
 * __useFilesByPersonQuery__
 *
 * To run a query within a React component, call `useFilesByPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesByPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesByPersonQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useFilesByPersonQuery(
  baseOptions?: Apollo.QueryHookOptions<FilesByPersonQuery, FilesByPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilesByPersonQuery, FilesByPersonQueryVariables>(
    FilesByPersonDocument,
    options,
  );
}
export function useFilesByPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilesByPersonQuery, FilesByPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilesByPersonQuery, FilesByPersonQueryVariables>(
    FilesByPersonDocument,
    options,
  );
}
export type FilesByPersonQueryHookResult = ReturnType<typeof useFilesByPersonQuery>;
export type FilesByPersonLazyQueryHookResult = ReturnType<typeof useFilesByPersonLazyQuery>;
export type FilesByPersonQueryResult = Apollo.QueryResult<
  FilesByPersonQuery,
  FilesByPersonQueryVariables
>;
export function refetchFilesByPersonQuery(variables?: FilesByPersonQueryVariables) {
  return { query: FilesByPersonDocument, variables: variables };
}
export const GetFormsForPersonDocument = gql`
  query GetFormsForPerson(
    $personId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $status: [FormAssignmentCompletionRoleStatusGraphene]
    $search: String
    $sort: [FormAssignmentCompletionRoleSortEnum]
    $showConditional: Boolean
    $assignedDateTimeStart: DateTime
    $assignedDateTimeEnd: DateTime
    $assignerIds: [Int]
  ) {
    myFormAssignments(
      personId: $personId
      after: $after
      before: $before
      first: $first
      last: $last
      status: $status
      search: $search
      sort: $sort
      showConditional: $showConditional
      assignedDateTimeStart: $assignedDateTimeStart
      assignedDateTimeEnd: $assignedDateTimeEnd
      assignerIds: $assignerIds
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          key: id
          status
          pk
          completion {
            allCompleted
            status
            signedDownloadUrl
            formAssignmentCompletionId: pk
            manuallyCompleted
            assignmentCompletionCompletedDateTime: completedDateTime
            assignment {
              isDocusignForm
              assignmentType
              label
              form {
                formLabel: label
              }
              assignedBy {
                id
                fullName
                initials
                pictureUrl
                displayName
              }
            }
          }
          assignedDateTime
          completedDateTime
          workflow {
            assignedDateTime
            updatedDate
            roleName
            roleOrder
            completedDateTime
            person {
              picture: pictureUrl
              initials
              displayName
              personId
            }
            pk
            externalName
            externalEmail
            status
          }
        }
      }
    }
  }
`;

/**
 * __useGetFormsForPersonQuery__
 *
 * To run a query within a React component, call `useGetFormsForPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsForPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsForPersonQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      showConditional: // value for 'showConditional'
 *      assignedDateTimeStart: // value for 'assignedDateTimeStart'
 *      assignedDateTimeEnd: // value for 'assignedDateTimeEnd'
 *      assignerIds: // value for 'assignerIds'
 *   },
 * });
 */
export function useGetFormsForPersonQuery(
  baseOptions: Apollo.QueryHookOptions<GetFormsForPersonQuery, GetFormsForPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormsForPersonQuery, GetFormsForPersonQueryVariables>(
    GetFormsForPersonDocument,
    options,
  );
}
export function useGetFormsForPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormsForPersonQuery,
    GetFormsForPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormsForPersonQuery, GetFormsForPersonQueryVariables>(
    GetFormsForPersonDocument,
    options,
  );
}
export type GetFormsForPersonQueryHookResult = ReturnType<typeof useGetFormsForPersonQuery>;
export type GetFormsForPersonLazyQueryHookResult = ReturnType<typeof useGetFormsForPersonLazyQuery>;
export type GetFormsForPersonQueryResult = Apollo.QueryResult<
  GetFormsForPersonQuery,
  GetFormsForPersonQueryVariables
>;
export function refetchGetFormsForPersonQuery(variables: GetFormsForPersonQueryVariables) {
  return { query: GetFormsForPersonDocument, variables: variables };
}
export const FetchPeopleNamesDocument = gql`
  query FetchPeopleNames($team: ID!, $activeStatus: Boolean) {
    people(sort: [last_name_asc, first_name_asc], team: $team, active: $activeStatus) {
      edges {
        person: node {
          id
          key: id
          value: id
          label: displayName
          teamMembership {
            id
            userTypeLabels
            athlete {
              personStatusType {
                labelPlural
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchPeopleNamesQuery__
 *
 * To run a query within a React component, call `useFetchPeopleNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPeopleNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPeopleNamesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      activeStatus: // value for 'activeStatus'
 *   },
 * });
 */
export function useFetchPeopleNamesQuery(
  baseOptions: Apollo.QueryHookOptions<FetchPeopleNamesQuery, FetchPeopleNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchPeopleNamesQuery, FetchPeopleNamesQueryVariables>(
    FetchPeopleNamesDocument,
    options,
  );
}
export function useFetchPeopleNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPeopleNamesQuery, FetchPeopleNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchPeopleNamesQuery, FetchPeopleNamesQueryVariables>(
    FetchPeopleNamesDocument,
    options,
  );
}
export type FetchPeopleNamesQueryHookResult = ReturnType<typeof useFetchPeopleNamesQuery>;
export type FetchPeopleNamesLazyQueryHookResult = ReturnType<typeof useFetchPeopleNamesLazyQuery>;
export type FetchPeopleNamesQueryResult = Apollo.QueryResult<
  FetchPeopleNamesQuery,
  FetchPeopleNamesQueryVariables
>;
export function refetchFetchPeopleNamesQuery(variables: FetchPeopleNamesQueryVariables) {
  return { query: FetchPeopleNamesDocument, variables: variables };
}
export const GetTeamMemberProfilePermissionsDocument = gql`
  query GetTeamMemberProfilePermissions($id: ID!) {
    person: node(id: $id) {
      ... on PersonNode {
        id
        teamMembership {
          id
          teamMemberId
          profilePermissions {
            id
            label
            dependencies
            category
            granted
            grantedBy
            restrictions
            codeName
            isRestrictedType
            tooltip
          }
        }
      }
    }
  }
`;

/**
 * __useGetTeamMemberProfilePermissionsQuery__
 *
 * To run a query within a React component, call `useGetTeamMemberProfilePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMemberProfilePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMemberProfilePermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamMemberProfilePermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamMemberProfilePermissionsQuery,
    GetTeamMemberProfilePermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeamMemberProfilePermissionsQuery,
    GetTeamMemberProfilePermissionsQueryVariables
  >(GetTeamMemberProfilePermissionsDocument, options);
}
export function useGetTeamMemberProfilePermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamMemberProfilePermissionsQuery,
    GetTeamMemberProfilePermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeamMemberProfilePermissionsQuery,
    GetTeamMemberProfilePermissionsQueryVariables
  >(GetTeamMemberProfilePermissionsDocument, options);
}
export type GetTeamMemberProfilePermissionsQueryHookResult = ReturnType<
  typeof useGetTeamMemberProfilePermissionsQuery
>;
export type GetTeamMemberProfilePermissionsLazyQueryHookResult = ReturnType<
  typeof useGetTeamMemberProfilePermissionsLazyQuery
>;
export type GetTeamMemberProfilePermissionsQueryResult = Apollo.QueryResult<
  GetTeamMemberProfilePermissionsQuery,
  GetTeamMemberProfilePermissionsQueryVariables
>;
export function refetchGetTeamMemberProfilePermissionsQuery(
  variables: GetTeamMemberProfilePermissionsQueryVariables,
) {
  return { query: GetTeamMemberProfilePermissionsDocument, variables: variables };
}
export const GetProfileDocument = gql`
  query GetProfile($id: ID!) {
    person: node(id: $id) {
      ... on PersonNode {
        __typename
        id
        active
        personId
        displayName
        title
        selectionCode
        pictureUrl
        preferredName
        nickname
        firstName
        lastName
        emailAddress
        phoneHome
        mobilePhone
        dateOfBirth
        schoolIdentifier
        facebookAccount
        twitterAccount
        canViewProfile
        canViewProfileCalendar
        canViewProfileForms
        canViewProfileNotes
        canViewProfileFiles
        canViewPersonalDetails
        canViewYearlyAttributes
        canViewAllPersonalDetails
        canViewAcademicDetails
        canViewAddressesDetails
        canViewAthleteDetails
        canViewContactDetails
        canModifyProfileAddresses
        canModifyProfileCustomContacts
        canModifyProfileTwContacts
        canModifyProfileRoommates
        canModifyProfileFiles
        hasLoginAccess
        hasUserAccount
        isOrgLevelUser
        usesExternalAuthentication
        suffix
        addresses {
          id
          description
          street
          street2
          city
          state
          zip
          isMailing
          countryIso
        }
        teamMembership {
          createdDate
          id
          teamMemberId
          positionLabels
          userTypeLabels
          major
          hasScholarship
          scholarshipAmount
          jerseyNumber
          academicYearLabel
          athleticYearLabel
          isAthlete
          team {
            id
            canAddToTeam
            label
          }
          userTypes {
            id
            name
          }
          athleteStatus {
            id
            label
          }
        }
        teamMembers {
          edges {
            node {
              id
              isAthlete
              academicYearLabel
              athleticYearLabel
              active
              teamId
              teamMemberId
              positionLabels
              userTypeLabels
              userTypeLabelsWithStatuses
              jerseyNumber
              major
              hasScholarship
              scholarshipAmount
              person {
                id
                displayName
                personId
              }
              team {
                canAddToTeam
                canRemoveFromTeam
                id
                label
              }
              rosterInfo {
                activeRosters {
                  tmpYearLabel
                }
                missingRosters {
                  teamRosterId
                  tmpYearLabel
                }
              }
            }
          }
        }
        teams {
          id
          label
        }
        student {
          studentId
          advisors {
            edges {
              node {
                advisorId
                preferredName
                lastName
                pictureUrl: pictureDownloadUrl
              }
            }
          }
        }
        contacts {
          id
          emailAddress
          firstName
          middleName
          maritalStatusId
          phoneCell
          phoneHome
          phoneOffice
          lastName
          initials
          pictureUrl
          displayName
          dateOfBirth
          mobilePhone
          relationship {
            id
            label
          }
          selectionCode
          addresses {
            isMailing
            description
            street
            street2
            city
            state
            zip
            countryIso
          }
        }
        roommates {
          id
          initials
          displayName
          mobilePhone
          pictureUrl
          profileId
          emailAddress
          selectionCode
          teamMembers {
            teamMemberId
            jerseyNumber
            positionLabels
          }
        }
        org {
          activeCoreCategories
          activeCoreCategoriesWithLabels {
            coreCode
            label
          }
          relationshipTypes {
            id
            label
          }
          userTypes {
            id
            personTypeId
            name
            namePlural
          }
        }
      }
    }
  }
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options,
  );
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export function refetchGetProfileQuery(variables: GetProfileQueryVariables) {
  return { query: GetProfileDocument, variables: variables };
}
export const GetProfileAttributesDocument = gql`
  query GetProfileAttributes($id: ID!, $includeAddresses: Boolean) {
    person: node(id: $id) {
      ... on PersonNode {
        __typename
        id
        attributeCategories(includeAddresses: $includeAddresses) {
          label
          isActive
          coreCode
          attributes {
            id
            isActive
            isCustom
            label
            coreCode
            value
            valueLabel
            dataType
            canEdit
            optionsList
          }
          sortOrder
          teamId
        }
        org {
          activeCoreCategories
          activeCoreCategoriesWithLabels {
            coreCode
            label
          }
        }
      }
    }
  }
`;

/**
 * __useGetProfileAttributesQuery__
 *
 * To run a query within a React component, call `useGetProfileAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileAttributesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeAddresses: // value for 'includeAddresses'
 *   },
 * });
 */
export function useGetProfileAttributesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileAttributesQuery,
    GetProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileAttributesQuery, GetProfileAttributesQueryVariables>(
    GetProfileAttributesDocument,
    options,
  );
}
export function useGetProfileAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileAttributesQuery,
    GetProfileAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileAttributesQuery, GetProfileAttributesQueryVariables>(
    GetProfileAttributesDocument,
    options,
  );
}
export type GetProfileAttributesQueryHookResult = ReturnType<typeof useGetProfileAttributesQuery>;
export type GetProfileAttributesLazyQueryHookResult = ReturnType<
  typeof useGetProfileAttributesLazyQuery
>;
export type GetProfileAttributesQueryResult = Apollo.QueryResult<
  GetProfileAttributesQuery,
  GetProfileAttributesQueryVariables
>;
export function refetchGetProfileAttributesQuery(variables: GetProfileAttributesQueryVariables) {
  return { query: GetProfileAttributesDocument, variables: variables };
}
export const GetProfileCoursesDocument = gql`
  query GetProfileCourses($studentIds: [Int]) {
    courseSections(studentIds: $studentIds, startDate: "lte:(now)", endDate: "gte:(now)") {
      edges {
        node {
          courseSectionId: pk
          shortCode
          startDate
          endDate
          term {
            termId: pk
            startDate
            endDate
            label
          }
          courseSectionSchedules {
            edges {
              node {
                startTime
                endTime
                day
              }
            }
          }
          course {
            courseId: pk
            credits
            label
            description
          }
        }
      }
    }
  }
`;

/**
 * __useGetProfileCoursesQuery__
 *
 * To run a query within a React component, call `useGetProfileCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCoursesQuery({
 *   variables: {
 *      studentIds: // value for 'studentIds'
 *   },
 * });
 */
export function useGetProfileCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProfileCoursesQuery, GetProfileCoursesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileCoursesQuery, GetProfileCoursesQueryVariables>(
    GetProfileCoursesDocument,
    options,
  );
}
export function useGetProfileCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileCoursesQuery,
    GetProfileCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileCoursesQuery, GetProfileCoursesQueryVariables>(
    GetProfileCoursesDocument,
    options,
  );
}
export type GetProfileCoursesQueryHookResult = ReturnType<typeof useGetProfileCoursesQuery>;
export type GetProfileCoursesLazyQueryHookResult = ReturnType<typeof useGetProfileCoursesLazyQuery>;
export type GetProfileCoursesQueryResult = Apollo.QueryResult<
  GetProfileCoursesQuery,
  GetProfileCoursesQueryVariables
>;
export function refetchGetProfileCoursesQuery(variables?: GetProfileCoursesQueryVariables) {
  return { query: GetProfileCoursesDocument, variables: variables };
}
export const GetProfileViewPermissionsDocument = gql`
  query GetProfileViewPermissions($id: ID!) {
    person: node(id: $id) {
      ... on PersonNode {
        __typename
        id
        personId
        canViewProfile
        canViewProfileCalendar
        canViewProfileForms
        canViewProfileNotes
        canViewProfileFiles
        canViewPersonalDetails
        canViewYearlyAttributes
        canViewAllPersonalDetails
        canViewAcademicDetails
        canViewAddressesDetails
        canViewAthleteDetails
        canViewContactDetails
        canModifyProfileAddresses
        canModifyProfileCustomContacts
        canModifyProfileTwContacts
        canModifyProfileRoommates
        canModifyProfileFiles
        teamMembership {
          isAthlete
        }
      }
    }
  }
`;

/**
 * __useGetProfileViewPermissionsQuery__
 *
 * To run a query within a React component, call `useGetProfileViewPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileViewPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileViewPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileViewPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileViewPermissionsQuery,
    GetProfileViewPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileViewPermissionsQuery, GetProfileViewPermissionsQueryVariables>(
    GetProfileViewPermissionsDocument,
    options,
  );
}
export function useGetProfileViewPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileViewPermissionsQuery,
    GetProfileViewPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProfileViewPermissionsQuery,
    GetProfileViewPermissionsQueryVariables
  >(GetProfileViewPermissionsDocument, options);
}
export type GetProfileViewPermissionsQueryHookResult = ReturnType<
  typeof useGetProfileViewPermissionsQuery
>;
export type GetProfileViewPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetProfileViewPermissionsLazyQuery
>;
export type GetProfileViewPermissionsQueryResult = Apollo.QueryResult<
  GetProfileViewPermissionsQuery,
  GetProfileViewPermissionsQueryVariables
>;
export function refetchGetProfileViewPermissionsQuery(
  variables: GetProfileViewPermissionsQueryVariables,
) {
  return { query: GetProfileViewPermissionsDocument, variables: variables };
}
export const PersonAddAddressDocument = gql`
  mutation PersonAddAddress($id: ID!, $input: AddressInput!) {
    personAddAddress(id: $id, input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type PersonAddAddressMutationFn = Apollo.MutationFunction<
  PersonAddAddressMutation,
  PersonAddAddressMutationVariables
>;

/**
 * __usePersonAddAddressMutation__
 *
 * To run a mutation, you first call `usePersonAddAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonAddAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personAddAddressMutation, { data, loading, error }] = usePersonAddAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonAddAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonAddAddressMutation,
    PersonAddAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PersonAddAddressMutation, PersonAddAddressMutationVariables>(
    PersonAddAddressDocument,
    options,
  );
}
export type PersonAddAddressMutationHookResult = ReturnType<typeof usePersonAddAddressMutation>;
export type PersonAddAddressMutationResult = Apollo.MutationResult<PersonAddAddressMutation>;
export type PersonAddAddressMutationOptions = Apollo.BaseMutationOptions<
  PersonAddAddressMutation,
  PersonAddAddressMutationVariables
>;
export const PersonGrantAllTeamsAccessDocument = gql`
  mutation PersonGrantAllTeamsAccess($id: ID!, $sourceTeamId: ID!) {
    personGrantAllTeamsAccess(id: $id, sourceTeamId: $sourceTeamId) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type PersonGrantAllTeamsAccessMutationFn = Apollo.MutationFunction<
  PersonGrantAllTeamsAccessMutation,
  PersonGrantAllTeamsAccessMutationVariables
>;

/**
 * __usePersonGrantAllTeamsAccessMutation__
 *
 * To run a mutation, you first call `usePersonGrantAllTeamsAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonGrantAllTeamsAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personGrantAllTeamsAccessMutation, { data, loading, error }] = usePersonGrantAllTeamsAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sourceTeamId: // value for 'sourceTeamId'
 *   },
 * });
 */
export function usePersonGrantAllTeamsAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonGrantAllTeamsAccessMutation,
    PersonGrantAllTeamsAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PersonGrantAllTeamsAccessMutation,
    PersonGrantAllTeamsAccessMutationVariables
  >(PersonGrantAllTeamsAccessDocument, options);
}
export type PersonGrantAllTeamsAccessMutationHookResult = ReturnType<
  typeof usePersonGrantAllTeamsAccessMutation
>;
export type PersonGrantAllTeamsAccessMutationResult =
  Apollo.MutationResult<PersonGrantAllTeamsAccessMutation>;
export type PersonGrantAllTeamsAccessMutationOptions = Apollo.BaseMutationOptions<
  PersonGrantAllTeamsAccessMutation,
  PersonGrantAllTeamsAccessMutationVariables
>;
export const PersonModifyAllTeamsAccessDocument = gql`
  mutation PersonModifyAllTeamsAccess($id: ID!, $input: ModifyAllTeamAccess!) {
    personModifyAllTeamsAccess(id: $id, input: $input) {
      userErrors {
        field
        message
      }
      person {
        displayName
        teamMembers {
          edges {
            node {
              id
              active
            }
          }
        }
      }
    }
  }
`;
export type PersonModifyAllTeamsAccessMutationFn = Apollo.MutationFunction<
  PersonModifyAllTeamsAccessMutation,
  PersonModifyAllTeamsAccessMutationVariables
>;

/**
 * __usePersonModifyAllTeamsAccessMutation__
 *
 * To run a mutation, you first call `usePersonModifyAllTeamsAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonModifyAllTeamsAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personModifyAllTeamsAccessMutation, { data, loading, error }] = usePersonModifyAllTeamsAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonModifyAllTeamsAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonModifyAllTeamsAccessMutation,
    PersonModifyAllTeamsAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PersonModifyAllTeamsAccessMutation,
    PersonModifyAllTeamsAccessMutationVariables
  >(PersonModifyAllTeamsAccessDocument, options);
}
export type PersonModifyAllTeamsAccessMutationHookResult = ReturnType<
  typeof usePersonModifyAllTeamsAccessMutation
>;
export type PersonModifyAllTeamsAccessMutationResult =
  Apollo.MutationResult<PersonModifyAllTeamsAccessMutation>;
export type PersonModifyAllTeamsAccessMutationOptions = Apollo.BaseMutationOptions<
  PersonModifyAllTeamsAccessMutation,
  PersonModifyAllTeamsAccessMutationVariables
>;
export const PersonRemoveAddressDocument = gql`
  mutation PersonRemoveAddress($id: ID!, $addressId: ID!) {
    personRemoveAddress(id: $id, addressId: $addressId) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type PersonRemoveAddressMutationFn = Apollo.MutationFunction<
  PersonRemoveAddressMutation,
  PersonRemoveAddressMutationVariables
>;

/**
 * __usePersonRemoveAddressMutation__
 *
 * To run a mutation, you first call `usePersonRemoveAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonRemoveAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personRemoveAddressMutation, { data, loading, error }] = usePersonRemoveAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function usePersonRemoveAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonRemoveAddressMutation,
    PersonRemoveAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PersonRemoveAddressMutation, PersonRemoveAddressMutationVariables>(
    PersonRemoveAddressDocument,
    options,
  );
}
export type PersonRemoveAddressMutationHookResult = ReturnType<
  typeof usePersonRemoveAddressMutation
>;
export type PersonRemoveAddressMutationResult = Apollo.MutationResult<PersonRemoveAddressMutation>;
export type PersonRemoveAddressMutationOptions = Apollo.BaseMutationOptions<
  PersonRemoveAddressMutation,
  PersonRemoveAddressMutationVariables
>;
export const PersonRevokeAllTeamsAccessDocument = gql`
  mutation PersonRevokeAllTeamsAccess($id: ID!) {
    personRevokeAllTeamsAccess(id: $id) {
      userErrors {
        field
        message
      }
      person {
        teamMembers {
          edges {
            node {
              id
              active
            }
          }
        }
      }
    }
  }
`;
export type PersonRevokeAllTeamsAccessMutationFn = Apollo.MutationFunction<
  PersonRevokeAllTeamsAccessMutation,
  PersonRevokeAllTeamsAccessMutationVariables
>;

/**
 * __usePersonRevokeAllTeamsAccessMutation__
 *
 * To run a mutation, you first call `usePersonRevokeAllTeamsAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonRevokeAllTeamsAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personRevokeAllTeamsAccessMutation, { data, loading, error }] = usePersonRevokeAllTeamsAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonRevokeAllTeamsAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonRevokeAllTeamsAccessMutation,
    PersonRevokeAllTeamsAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PersonRevokeAllTeamsAccessMutation,
    PersonRevokeAllTeamsAccessMutationVariables
  >(PersonRevokeAllTeamsAccessDocument, options);
}
export type PersonRevokeAllTeamsAccessMutationHookResult = ReturnType<
  typeof usePersonRevokeAllTeamsAccessMutation
>;
export type PersonRevokeAllTeamsAccessMutationResult =
  Apollo.MutationResult<PersonRevokeAllTeamsAccessMutation>;
export type PersonRevokeAllTeamsAccessMutationOptions = Apollo.BaseMutationOptions<
  PersonRevokeAllTeamsAccessMutation,
  PersonRevokeAllTeamsAccessMutationVariables
>;
export const PersonUpdateAddressDocument = gql`
  mutation PersonUpdateAddress($id: ID!, $addressId: ID!, $input: AddressInput!) {
    personUpdateAddress(id: $id, addressId: $addressId, input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type PersonUpdateAddressMutationFn = Apollo.MutationFunction<
  PersonUpdateAddressMutation,
  PersonUpdateAddressMutationVariables
>;

/**
 * __usePersonUpdateAddressMutation__
 *
 * To run a mutation, you first call `usePersonUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personUpdateAddressMutation, { data, loading, error }] = usePersonUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      addressId: // value for 'addressId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonUpdateAddressMutation,
    PersonUpdateAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PersonUpdateAddressMutation, PersonUpdateAddressMutationVariables>(
    PersonUpdateAddressDocument,
    options,
  );
}
export type PersonUpdateAddressMutationHookResult = ReturnType<
  typeof usePersonUpdateAddressMutation
>;
export type PersonUpdateAddressMutationResult = Apollo.MutationResult<PersonUpdateAddressMutation>;
export type PersonUpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  PersonUpdateAddressMutation,
  PersonUpdateAddressMutationVariables
>;
export const TeamMemberUpdateUserTypesDocument = gql`
  mutation TeamMemberUpdateUserTypes($id: ID!, $input: UpdateUserTypesInput!) {
    teamMemberUpdateUserTypes(id: $id, input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type TeamMemberUpdateUserTypesMutationFn = Apollo.MutationFunction<
  TeamMemberUpdateUserTypesMutation,
  TeamMemberUpdateUserTypesMutationVariables
>;

/**
 * __useTeamMemberUpdateUserTypesMutation__
 *
 * To run a mutation, you first call `useTeamMemberUpdateUserTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberUpdateUserTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamMemberUpdateUserTypesMutation, { data, loading, error }] = useTeamMemberUpdateUserTypesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamMemberUpdateUserTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeamMemberUpdateUserTypesMutation,
    TeamMemberUpdateUserTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TeamMemberUpdateUserTypesMutation,
    TeamMemberUpdateUserTypesMutationVariables
  >(TeamMemberUpdateUserTypesDocument, options);
}
export type TeamMemberUpdateUserTypesMutationHookResult = ReturnType<
  typeof useTeamMemberUpdateUserTypesMutation
>;
export type TeamMemberUpdateUserTypesMutationResult =
  Apollo.MutationResult<TeamMemberUpdateUserTypesMutation>;
export type TeamMemberUpdateUserTypesMutationOptions = Apollo.BaseMutationOptions<
  TeamMemberUpdateUserTypesMutation,
  TeamMemberUpdateUserTypesMutationVariables
>;
export const UpdateAttributesDocument = gql`
  mutation updateAttributes($id: ID!, $input: AttributeInput!) {
    attributesUpdate(id: $id, input: $input) {
      userErrors {
        message
        field
      }
      person {
        id
        firstName
        lastName
        middleName
        gender
        nickname
        dateOfBirth
        orgId
        title
        startDate
        travelWeight
        driversLicenseNumber
        driversLicenseExpirationDate
        driversLicenseState
        emailAddress
        emailAddressPersonal
        phoneHome
        phoneCell
        phoneOffice
        phoneFax
        facebookAccount
        twitterAccount
        automobile {
          make
          model
          stateCode
          year
          plateNumber
        }
      }
    }
  }
`;
export type UpdateAttributesMutationFn = Apollo.MutationFunction<
  UpdateAttributesMutation,
  UpdateAttributesMutationVariables
>;

/**
 * __useUpdateAttributesMutation__
 *
 * To run a mutation, you first call `useUpdateAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttributesMutation, { data, loading, error }] = useUpdateAttributesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttributesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAttributesMutation,
    UpdateAttributesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAttributesMutation, UpdateAttributesMutationVariables>(
    UpdateAttributesDocument,
    options,
  );
}
export type UpdateAttributesMutationHookResult = ReturnType<typeof useUpdateAttributesMutation>;
export type UpdateAttributesMutationResult = Apollo.MutationResult<UpdateAttributesMutation>;
export type UpdateAttributesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAttributesMutation,
  UpdateAttributesMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($id: ID!, $contactId: ID!, $input: PersonContactUpdateInput!) {
    contactUpdate(id: $id, contactId: $contactId, input: $input) {
      userErrors {
        message
        field
      }
      person {
        id
        contacts {
          id
          initials
          pictureUrl
          displayName
          dateOfBirth
          mobilePhone
          relationship {
            id
            label
          }
          selectionCode
        }
      }
    }
  }
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(
    UpdateContactDocument,
    options,
  );
}
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const UpdatePermissionDocument = gql`
  mutation UpdatePermission($id: ID!, $input: UpdatePermissionsInput!) {
    teamMemberUpdatePermissions(id: $id, input: $input) {
      teamMember {
        profilePermissions {
          id
          label
          dependencies
          category
          granted
          grantedBy
          restrictions
          codeName
          isRestrictedType
          tooltip
        }
      }
    }
  }
`;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePermissionMutation,
    UpdatePermissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePermissionMutation, UpdatePermissionMutationVariables>(
    UpdatePermissionDocument,
    options,
  );
}
export type UpdatePermissionMutationHookResult = ReturnType<typeof useUpdatePermissionMutation>;
export type UpdatePermissionMutationResult = Apollo.MutationResult<UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;
export const UpdateProfilePictureDocument = gql`
  mutation UpdateProfilePicture($id: ID!, $input: PersonInput!) {
    personUpdate(id: $id, input: $input) {
      person {
        id
        pictureUrl
      }
    }
  }
`;
export type UpdateProfilePictureMutationFn = Apollo.MutationFunction<
  UpdateProfilePictureMutation,
  UpdateProfilePictureMutationVariables
>;

/**
 * __useUpdateProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePictureMutation, { data, loading, error }] = useUpdateProfilePictureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfilePictureMutation,
    UpdateProfilePictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>(
    UpdateProfilePictureDocument,
    options,
  );
}
export type UpdateProfilePictureMutationHookResult = ReturnType<
  typeof useUpdateProfilePictureMutation
>;
export type UpdateProfilePictureMutationResult =
  Apollo.MutationResult<UpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfilePictureMutation,
  UpdateProfilePictureMutationVariables
>;
export const UpdateRoommateDocument = gql`
  mutation UpdateRoommate($id: ID!, $roommateId: ID!, $input: RoommateInput!) {
    roommateUpdate(id: $id, roommateId: $roommateId, input: $input) {
      person {
        id
        roommates {
          displayName
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateRoommateMutationFn = Apollo.MutationFunction<
  UpdateRoommateMutation,
  UpdateRoommateMutationVariables
>;

/**
 * __useUpdateRoommateMutation__
 *
 * To run a mutation, you first call `useUpdateRoommateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoommateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoommateMutation, { data, loading, error }] = useUpdateRoommateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roommateId: // value for 'roommateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoommateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRoommateMutation, UpdateRoommateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoommateMutation, UpdateRoommateMutationVariables>(
    UpdateRoommateDocument,
    options,
  );
}
export type UpdateRoommateMutationHookResult = ReturnType<typeof useUpdateRoommateMutation>;
export type UpdateRoommateMutationResult = Apollo.MutationResult<UpdateRoommateMutation>;
export type UpdateRoommateMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoommateMutation,
  UpdateRoommateMutationVariables
>;
export const YearlyAttributeValuesDocument = gql`
  query YearlyAttributeValues($personBeingViewed: ID!, $tmpYear: ID!) {
    yearlyAttributeValuesForYear(personBeingViewed: $personBeingViewed, tmpYear: $tmpYear) {
      edges {
        node {
          id
          yearlyAttributeId
          valueText
          valueNumeric
          valueDate
          tmpYearId
        }
      }
    }
  }
`;

/**
 * __useYearlyAttributeValuesQuery__
 *
 * To run a query within a React component, call `useYearlyAttributeValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearlyAttributeValuesQuery({
 *   variables: {
 *      personBeingViewed: // value for 'personBeingViewed'
 *      tmpYear: // value for 'tmpYear'
 *   },
 * });
 */
export function useYearlyAttributeValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    YearlyAttributeValuesQuery,
    YearlyAttributeValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<YearlyAttributeValuesQuery, YearlyAttributeValuesQueryVariables>(
    YearlyAttributeValuesDocument,
    options,
  );
}
export function useYearlyAttributeValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    YearlyAttributeValuesQuery,
    YearlyAttributeValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<YearlyAttributeValuesQuery, YearlyAttributeValuesQueryVariables>(
    YearlyAttributeValuesDocument,
    options,
  );
}
export type YearlyAttributeValuesQueryHookResult = ReturnType<typeof useYearlyAttributeValuesQuery>;
export type YearlyAttributeValuesLazyQueryHookResult = ReturnType<
  typeof useYearlyAttributeValuesLazyQuery
>;
export type YearlyAttributeValuesQueryResult = Apollo.QueryResult<
  YearlyAttributeValuesQuery,
  YearlyAttributeValuesQueryVariables
>;
export function refetchYearlyAttributeValuesQuery(variables: YearlyAttributeValuesQueryVariables) {
  return { query: YearlyAttributeValuesDocument, variables: variables };
}
export const YearlyAttributeProfileDocument = gql`
  query YearlyAttributeProfile(
    $includeInactiveRecords: Boolean
    $personBeingViewed: ID
    $teamBeingViewed: ID
  ) {
    orgYearlyAttributeCategories: orgYearlyAttributeCategories(
      sort: [sort_order_asc, label_asc]
      includeInactiveRecords: $includeInactiveRecords
      personBeingViewed: $personBeingViewed
    ) {
      edges {
        node {
          id
          label
          isCustom
          isActive
          sortOrder
          yearlyAttributesActive {
            id
            label
            sortOrder
            selfManageUsers
            dataType
            canEdit
            isCustom
            isActive
            optionsList
          }
        }
      }
    }
    teamYearlyAttributeCategories: teamYearlyAttributeCategories(
      sort: [sort_order_asc, label_asc]
      includeInactiveRecords: $includeInactiveRecords
      personBeingViewed: $personBeingViewed
      teamBeingViewed: $teamBeingViewed
    ) {
      edges {
        node {
          id
          label
          isCustom
          isActive
          yearlyAttributesActive {
            id
            label
            sortOrder
            selfManageUsers
            dataType
            canEdit
            isCustom
            isActive
            optionsList
          }
        }
      }
    }
  }
`;

/**
 * __useYearlyAttributeProfileQuery__
 *
 * To run a query within a React component, call `useYearlyAttributeProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearlyAttributeProfileQuery({
 *   variables: {
 *      includeInactiveRecords: // value for 'includeInactiveRecords'
 *      personBeingViewed: // value for 'personBeingViewed'
 *      teamBeingViewed: // value for 'teamBeingViewed'
 *   },
 * });
 */
export function useYearlyAttributeProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    YearlyAttributeProfileQuery,
    YearlyAttributeProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<YearlyAttributeProfileQuery, YearlyAttributeProfileQueryVariables>(
    YearlyAttributeProfileDocument,
    options,
  );
}
export function useYearlyAttributeProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    YearlyAttributeProfileQuery,
    YearlyAttributeProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<YearlyAttributeProfileQuery, YearlyAttributeProfileQueryVariables>(
    YearlyAttributeProfileDocument,
    options,
  );
}
export type YearlyAttributeProfileQueryHookResult = ReturnType<
  typeof useYearlyAttributeProfileQuery
>;
export type YearlyAttributeProfileLazyQueryHookResult = ReturnType<
  typeof useYearlyAttributeProfileLazyQuery
>;
export type YearlyAttributeProfileQueryResult = Apollo.QueryResult<
  YearlyAttributeProfileQuery,
  YearlyAttributeProfileQueryVariables
>;
export function refetchYearlyAttributeProfileQuery(
  variables?: YearlyAttributeProfileQueryVariables,
) {
  return { query: YearlyAttributeProfileDocument, variables: variables };
}
export const YearlyAttributeProfileUpdateDocument = gql`
  mutation YearlyAttributeProfileUpdate($id: ID!, $tmpYear: ID!, $input: AttributeInput!) {
    yearlyAttributeUserUpdate(id: $id, tmpYear: $tmpYear, input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type YearlyAttributeProfileUpdateMutationFn = Apollo.MutationFunction<
  YearlyAttributeProfileUpdateMutation,
  YearlyAttributeProfileUpdateMutationVariables
>;

/**
 * __useYearlyAttributeProfileUpdateMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeProfileUpdateMutation, { data, loading, error }] = useYearlyAttributeProfileUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tmpYear: // value for 'tmpYear'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useYearlyAttributeProfileUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeProfileUpdateMutation,
    YearlyAttributeProfileUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeProfileUpdateMutation,
    YearlyAttributeProfileUpdateMutationVariables
  >(YearlyAttributeProfileUpdateDocument, options);
}
export type YearlyAttributeProfileUpdateMutationHookResult = ReturnType<
  typeof useYearlyAttributeProfileUpdateMutation
>;
export type YearlyAttributeProfileUpdateMutationResult =
  Apollo.MutationResult<YearlyAttributeProfileUpdateMutation>;
export type YearlyAttributeProfileUpdateMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeProfileUpdateMutation,
  YearlyAttributeProfileUpdateMutationVariables
>;
export const CreateNoteDocument = gql`
  mutation CreateNote($input: NoteInput!) {
    noteCreate(input: $input) {
      userErrors {
        message
        field
      }
      note {
        title
        content
        author {
          personId
        }
      }
    }
  }
`;
export type CreateNoteMutationFn = Apollo.MutationFunction<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
    options,
  );
}
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;
export const UpdateNoteDocument = gql`
  mutation UpdateNote($input: NoteInput!, $id: ID!) {
    noteUpdate(input: $input, id: $id) {
      userErrors {
        message
        field
      }
      note {
        title
        content
        author {
          personId
        }
      }
    }
  }
`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(
    UpdateNoteDocument,
    options,
  );
}
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;
export const GetProfileNotesDocument = gql`
  query GetProfileNotes($id: ID!) {
    person: node(id: $id) {
      ... on PersonNode {
        __typename
        id
        canModifyNotes
        notes {
          id
          title
          content
          canEdit
          visibility {
            label
            selectionCode
          }
          author {
            displayName
            pictureUrl
            title
            initials
          }
          createdDate
        }
      }
    }
  }
`;

/**
 * __useGetProfileNotesQuery__
 *
 * To run a query within a React component, call `useGetProfileNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileNotesQuery(
  baseOptions: Apollo.QueryHookOptions<GetProfileNotesQuery, GetProfileNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileNotesQuery, GetProfileNotesQueryVariables>(
    GetProfileNotesDocument,
    options,
  );
}
export function useGetProfileNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProfileNotesQuery, GetProfileNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileNotesQuery, GetProfileNotesQueryVariables>(
    GetProfileNotesDocument,
    options,
  );
}
export type GetProfileNotesQueryHookResult = ReturnType<typeof useGetProfileNotesQuery>;
export type GetProfileNotesLazyQueryHookResult = ReturnType<typeof useGetProfileNotesLazyQuery>;
export type GetProfileNotesQueryResult = Apollo.QueryResult<
  GetProfileNotesQuery,
  GetProfileNotesQueryVariables
>;
export function refetchGetProfileNotesQuery(variables: GetProfileNotesQueryVariables) {
  return { query: GetProfileNotesDocument, variables: variables };
}
export const UserHistoryChangelogDocument = gql`
  query UserHistoryChangelog($personBeingViewed: ID!, $teamBeingViewed: ID!) {
    userHistoryChangelog(personBeingViewed: $personBeingViewed, teamBeingViewed: $teamBeingViewed) {
      edges {
        node {
          id
          userHistoryChangelogId
          tmpYearId
          changeType
          oldValue
          newValue
          reason
          notes
          effectiveDate
          actionDate
          team {
            label
          }
          tmpYear {
            label
          }
          actor {
            displayName
            initials
            pictureUrl
          }
        }
      }
    }
  }
`;

/**
 * __useUserHistoryChangelogQuery__
 *
 * To run a query within a React component, call `useUserHistoryChangelogQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHistoryChangelogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHistoryChangelogQuery({
 *   variables: {
 *      personBeingViewed: // value for 'personBeingViewed'
 *      teamBeingViewed: // value for 'teamBeingViewed'
 *   },
 * });
 */
export function useUserHistoryChangelogQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserHistoryChangelogQuery,
    UserHistoryChangelogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserHistoryChangelogQuery, UserHistoryChangelogQueryVariables>(
    UserHistoryChangelogDocument,
    options,
  );
}
export function useUserHistoryChangelogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserHistoryChangelogQuery,
    UserHistoryChangelogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserHistoryChangelogQuery, UserHistoryChangelogQueryVariables>(
    UserHistoryChangelogDocument,
    options,
  );
}
export type UserHistoryChangelogQueryHookResult = ReturnType<typeof useUserHistoryChangelogQuery>;
export type UserHistoryChangelogLazyQueryHookResult = ReturnType<
  typeof useUserHistoryChangelogLazyQuery
>;
export type UserHistoryChangelogQueryResult = Apollo.QueryResult<
  UserHistoryChangelogQuery,
  UserHistoryChangelogQueryVariables
>;
export function refetchUserHistoryChangelogQuery(variables: UserHistoryChangelogQueryVariables) {
  return { query: UserHistoryChangelogDocument, variables: variables };
}
export const UserHistoryTeamsDocument = gql`
  query UserHistoryTeams($personBeingViewed: ID!) {
    userHistoryTeams(personBeingViewed: $personBeingViewed) {
      edges {
        node {
          team {
            id
            teamId
            label
          }
        }
      }
    }
  }
`;

/**
 * __useUserHistoryTeamsQuery__
 *
 * To run a query within a React component, call `useUserHistoryTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHistoryTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHistoryTeamsQuery({
 *   variables: {
 *      personBeingViewed: // value for 'personBeingViewed'
 *   },
 * });
 */
export function useUserHistoryTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<UserHistoryTeamsQuery, UserHistoryTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserHistoryTeamsQuery, UserHistoryTeamsQueryVariables>(
    UserHistoryTeamsDocument,
    options,
  );
}
export function useUserHistoryTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserHistoryTeamsQuery, UserHistoryTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserHistoryTeamsQuery, UserHistoryTeamsQueryVariables>(
    UserHistoryTeamsDocument,
    options,
  );
}
export type UserHistoryTeamsQueryHookResult = ReturnType<typeof useUserHistoryTeamsQuery>;
export type UserHistoryTeamsLazyQueryHookResult = ReturnType<typeof useUserHistoryTeamsLazyQuery>;
export type UserHistoryTeamsQueryResult = Apollo.QueryResult<
  UserHistoryTeamsQuery,
  UserHistoryTeamsQueryVariables
>;
export function refetchUserHistoryTeamsQuery(variables: UserHistoryTeamsQueryVariables) {
  return { query: UserHistoryTeamsDocument, variables: variables };
}
export const PersonBulkDeactivateDocument = gql`
  mutation PersonBulkDeactivate($input: BulkPersonDeactivateInput) {
    personBulkDeactivate(input: $input) {
      person {
        personId
        active
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type PersonBulkDeactivateMutationFn = Apollo.MutationFunction<
  PersonBulkDeactivateMutation,
  PersonBulkDeactivateMutationVariables
>;

/**
 * __usePersonBulkDeactivateMutation__
 *
 * To run a mutation, you first call `usePersonBulkDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonBulkDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personBulkDeactivateMutation, { data, loading, error }] = usePersonBulkDeactivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonBulkDeactivateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonBulkDeactivateMutation,
    PersonBulkDeactivateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PersonBulkDeactivateMutation, PersonBulkDeactivateMutationVariables>(
    PersonBulkDeactivateDocument,
    options,
  );
}
export type PersonBulkDeactivateMutationHookResult = ReturnType<
  typeof usePersonBulkDeactivateMutation
>;
export type PersonBulkDeactivateMutationResult =
  Apollo.MutationResult<PersonBulkDeactivateMutation>;
export type PersonBulkDeactivateMutationOptions = Apollo.BaseMutationOptions<
  PersonBulkDeactivateMutation,
  PersonBulkDeactivateMutationVariables
>;
export const PersonBulkGrantAccessDocument = gql`
  mutation PersonBulkGrantAccess($input: BulkGrantAccessInput!) {
    personBulkGrantAccess(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type PersonBulkGrantAccessMutationFn = Apollo.MutationFunction<
  PersonBulkGrantAccessMutation,
  PersonBulkGrantAccessMutationVariables
>;

/**
 * __usePersonBulkGrantAccessMutation__
 *
 * To run a mutation, you first call `usePersonBulkGrantAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonBulkGrantAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personBulkGrantAccessMutation, { data, loading, error }] = usePersonBulkGrantAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonBulkGrantAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonBulkGrantAccessMutation,
    PersonBulkGrantAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PersonBulkGrantAccessMutation, PersonBulkGrantAccessMutationVariables>(
    PersonBulkGrantAccessDocument,
    options,
  );
}
export type PersonBulkGrantAccessMutationHookResult = ReturnType<
  typeof usePersonBulkGrantAccessMutation
>;
export type PersonBulkGrantAccessMutationResult =
  Apollo.MutationResult<PersonBulkGrantAccessMutation>;
export type PersonBulkGrantAccessMutationOptions = Apollo.BaseMutationOptions<
  PersonBulkGrantAccessMutation,
  PersonBulkGrantAccessMutationVariables
>;
export const PersonBulkReactivateDocument = gql`
  mutation PersonBulkReactivate($input: BulkPersonReactivateInput) {
    personBulkReactivate(input: $input) {
      person {
        personId
        active
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type PersonBulkReactivateMutationFn = Apollo.MutationFunction<
  PersonBulkReactivateMutation,
  PersonBulkReactivateMutationVariables
>;

/**
 * __usePersonBulkReactivateMutation__
 *
 * To run a mutation, you first call `usePersonBulkReactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonBulkReactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personBulkReactivateMutation, { data, loading, error }] = usePersonBulkReactivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonBulkReactivateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonBulkReactivateMutation,
    PersonBulkReactivateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PersonBulkReactivateMutation, PersonBulkReactivateMutationVariables>(
    PersonBulkReactivateDocument,
    options,
  );
}
export type PersonBulkReactivateMutationHookResult = ReturnType<
  typeof usePersonBulkReactivateMutation
>;
export type PersonBulkReactivateMutationResult =
  Apollo.MutationResult<PersonBulkReactivateMutation>;
export type PersonBulkReactivateMutationOptions = Apollo.BaseMutationOptions<
  PersonBulkReactivateMutation,
  PersonBulkReactivateMutationVariables
>;
export const PersonBulkRemoveTeamMembershipsDocument = gql`
  mutation PersonBulkRemoveTeamMemberships($input: PersonBulkRemoveTeamMembersInput!) {
    personBulkRemoveTeamMemberships(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type PersonBulkRemoveTeamMembershipsMutationFn = Apollo.MutationFunction<
  PersonBulkRemoveTeamMembershipsMutation,
  PersonBulkRemoveTeamMembershipsMutationVariables
>;

/**
 * __usePersonBulkRemoveTeamMembershipsMutation__
 *
 * To run a mutation, you first call `usePersonBulkRemoveTeamMembershipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonBulkRemoveTeamMembershipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personBulkRemoveTeamMembershipsMutation, { data, loading, error }] = usePersonBulkRemoveTeamMembershipsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonBulkRemoveTeamMembershipsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonBulkRemoveTeamMembershipsMutation,
    PersonBulkRemoveTeamMembershipsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PersonBulkRemoveTeamMembershipsMutation,
    PersonBulkRemoveTeamMembershipsMutationVariables
  >(PersonBulkRemoveTeamMembershipsDocument, options);
}
export type PersonBulkRemoveTeamMembershipsMutationHookResult = ReturnType<
  typeof usePersonBulkRemoveTeamMembershipsMutation
>;
export type PersonBulkRemoveTeamMembershipsMutationResult =
  Apollo.MutationResult<PersonBulkRemoveTeamMembershipsMutation>;
export type PersonBulkRemoveTeamMembershipsMutationOptions = Apollo.BaseMutationOptions<
  PersonBulkRemoveTeamMembershipsMutation,
  PersonBulkRemoveTeamMembershipsMutationVariables
>;
export const PersonBulkUpdateLoginDocument = gql`
  mutation PersonBulkUpdateLogin($input: PersonBulkUpdateLoginInput!) {
    personBulkUpdateLogin(input: $input) {
      bulkUserErrors {
        objectKey
        errorList {
          field
          message
        }
      }
    }
  }
`;
export type PersonBulkUpdateLoginMutationFn = Apollo.MutationFunction<
  PersonBulkUpdateLoginMutation,
  PersonBulkUpdateLoginMutationVariables
>;

/**
 * __usePersonBulkUpdateLoginMutation__
 *
 * To run a mutation, you first call `usePersonBulkUpdateLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonBulkUpdateLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personBulkUpdateLoginMutation, { data, loading, error }] = usePersonBulkUpdateLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonBulkUpdateLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonBulkUpdateLoginMutation,
    PersonBulkUpdateLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PersonBulkUpdateLoginMutation, PersonBulkUpdateLoginMutationVariables>(
    PersonBulkUpdateLoginDocument,
    options,
  );
}
export type PersonBulkUpdateLoginMutationHookResult = ReturnType<
  typeof usePersonBulkUpdateLoginMutation
>;
export type PersonBulkUpdateLoginMutationResult =
  Apollo.MutationResult<PersonBulkUpdateLoginMutation>;
export type PersonBulkUpdateLoginMutationOptions = Apollo.BaseMutationOptions<
  PersonBulkUpdateLoginMutation,
  PersonBulkUpdateLoginMutationVariables
>;
export const DeactivatePersonDocument = gql`
  mutation DeactivatePerson($input: ID!) {
    personDeactivate(id: $input) {
      person {
        id
        displayName
        active
      }
    }
  }
`;
export type DeactivatePersonMutationFn = Apollo.MutationFunction<
  DeactivatePersonMutation,
  DeactivatePersonMutationVariables
>;

/**
 * __useDeactivatePersonMutation__
 *
 * To run a mutation, you first call `useDeactivatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivatePersonMutation, { data, loading, error }] = useDeactivatePersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivatePersonMutation,
    DeactivatePersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeactivatePersonMutation, DeactivatePersonMutationVariables>(
    DeactivatePersonDocument,
    options,
  );
}
export type DeactivatePersonMutationHookResult = ReturnType<typeof useDeactivatePersonMutation>;
export type DeactivatePersonMutationResult = Apollo.MutationResult<DeactivatePersonMutation>;
export type DeactivatePersonMutationOptions = Apollo.BaseMutationOptions<
  DeactivatePersonMutation,
  DeactivatePersonMutationVariables
>;
export const ProfileReportCreateDocument = gql`
  mutation ProfileReportCreate($teamId: ID!, $input: ProfileReportInput!) {
    profileReportCreate(teamId: $teamId, input: $input) {
      profileReport {
        createdDate
        downloadableFileId
        status
        title
        url
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type ProfileReportCreateMutationFn = Apollo.MutationFunction<
  ProfileReportCreateMutation,
  ProfileReportCreateMutationVariables
>;

/**
 * __useProfileReportCreateMutation__
 *
 * To run a mutation, you first call `useProfileReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileReportCreateMutation, { data, loading, error }] = useProfileReportCreateMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileReportCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileReportCreateMutation,
    ProfileReportCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProfileReportCreateMutation, ProfileReportCreateMutationVariables>(
    ProfileReportCreateDocument,
    options,
  );
}
export type ProfileReportCreateMutationHookResult = ReturnType<
  typeof useProfileReportCreateMutation
>;
export type ProfileReportCreateMutationResult = Apollo.MutationResult<ProfileReportCreateMutation>;
export type ProfileReportCreateMutationOptions = Apollo.BaseMutationOptions<
  ProfileReportCreateMutation,
  ProfileReportCreateMutationVariables
>;
export const ProfileReportDocument = gql`
  query ProfileReport {
    profileReport {
      edges {
        node {
          createdDate
          downloadableFileId
          errorMessage
          kind
          status
          title
          url
        }
      }
    }
  }
`;

/**
 * __useProfileReportQuery__
 *
 * To run a query within a React component, call `useProfileReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileReportQuery(
  baseOptions?: Apollo.QueryHookOptions<ProfileReportQuery, ProfileReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileReportQuery, ProfileReportQueryVariables>(
    ProfileReportDocument,
    options,
  );
}
export function useProfileReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProfileReportQuery, ProfileReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfileReportQuery, ProfileReportQueryVariables>(
    ProfileReportDocument,
    options,
  );
}
export type ProfileReportQueryHookResult = ReturnType<typeof useProfileReportQuery>;
export type ProfileReportLazyQueryHookResult = ReturnType<typeof useProfileReportLazyQuery>;
export type ProfileReportQueryResult = Apollo.QueryResult<
  ProfileReportQuery,
  ProfileReportQueryVariables
>;
export function refetchProfileReportQuery(variables?: ProfileReportQueryVariables) {
  return { query: ProfileReportDocument, variables: variables };
}
export const ReactivatePersonDocument = gql`
  mutation ReactivatePerson($id: ID!) {
    personReactivate(id: $id) {
      person {
        id
        active
        displayName
      }
      userErrors {
        message
      }
    }
  }
`;
export type ReactivatePersonMutationFn = Apollo.MutationFunction<
  ReactivatePersonMutation,
  ReactivatePersonMutationVariables
>;

/**
 * __useReactivatePersonMutation__
 *
 * To run a mutation, you first call `useReactivatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivatePersonMutation, { data, loading, error }] = useReactivatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReactivatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivatePersonMutation,
    ReactivatePersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReactivatePersonMutation, ReactivatePersonMutationVariables>(
    ReactivatePersonDocument,
    options,
  );
}
export type ReactivatePersonMutationHookResult = ReturnType<typeof useReactivatePersonMutation>;
export type ReactivatePersonMutationResult = Apollo.MutationResult<ReactivatePersonMutation>;
export type ReactivatePersonMutationOptions = Apollo.BaseMutationOptions<
  ReactivatePersonMutation,
  ReactivatePersonMutationVariables
>;
export const RemovePersonFromTeamDocument = gql`
  mutation RemovePersonFromTeam($id: ID!, $teamId: ID!) {
    personRemoveTeamMembership(id: $id, teamId: $teamId) {
      person {
        id
        displayName
        active
        teamMembers {
          edges {
            node {
              id
              teamMemberId
              active
            }
          }
        }
      }
      userErrors {
        message
      }
    }
  }
`;
export type RemovePersonFromTeamMutationFn = Apollo.MutationFunction<
  RemovePersonFromTeamMutation,
  RemovePersonFromTeamMutationVariables
>;

/**
 * __useRemovePersonFromTeamMutation__
 *
 * To run a mutation, you first call `useRemovePersonFromTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePersonFromTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePersonFromTeamMutation, { data, loading, error }] = useRemovePersonFromTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useRemovePersonFromTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePersonFromTeamMutation,
    RemovePersonFromTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePersonFromTeamMutation, RemovePersonFromTeamMutationVariables>(
    RemovePersonFromTeamDocument,
    options,
  );
}
export type RemovePersonFromTeamMutationHookResult = ReturnType<
  typeof useRemovePersonFromTeamMutation
>;
export type RemovePersonFromTeamMutationResult =
  Apollo.MutationResult<RemovePersonFromTeamMutation>;
export type RemovePersonFromTeamMutationOptions = Apollo.BaseMutationOptions<
  RemovePersonFromTeamMutation,
  RemovePersonFromTeamMutationVariables
>;
export const TeamRosterReportDocument = gql`
  query TeamRosterReport {
    teamRosterReport {
      edges {
        node {
          createdDate
          downloadableFileId
          errorMessage
          kind
          status
          title
          url
        }
      }
    }
  }
`;

/**
 * __useTeamRosterReportQuery__
 *
 * To run a query within a React component, call `useTeamRosterReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamRosterReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamRosterReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamRosterReportQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamRosterReportQuery, TeamRosterReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamRosterReportQuery, TeamRosterReportQueryVariables>(
    TeamRosterReportDocument,
    options,
  );
}
export function useTeamRosterReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamRosterReportQuery, TeamRosterReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamRosterReportQuery, TeamRosterReportQueryVariables>(
    TeamRosterReportDocument,
    options,
  );
}
export type TeamRosterReportQueryHookResult = ReturnType<typeof useTeamRosterReportQuery>;
export type TeamRosterReportLazyQueryHookResult = ReturnType<typeof useTeamRosterReportLazyQuery>;
export type TeamRosterReportQueryResult = Apollo.QueryResult<
  TeamRosterReportQuery,
  TeamRosterReportQueryVariables
>;
export function refetchTeamRosterReportQuery(variables?: TeamRosterReportQueryVariables) {
  return { query: TeamRosterReportDocument, variables: variables };
}
export const TeamRosterReportCreateDocument = gql`
  mutation TeamRosterReportCreate($input: TeamRosterReportInput!) {
    teamRosterReportCreate(input: $input) {
      teamRosterReport {
        createdDate
        downloadableFileId
        status
        title
        url
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type TeamRosterReportCreateMutationFn = Apollo.MutationFunction<
  TeamRosterReportCreateMutation,
  TeamRosterReportCreateMutationVariables
>;

/**
 * __useTeamRosterReportCreateMutation__
 *
 * To run a mutation, you first call `useTeamRosterReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamRosterReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamRosterReportCreateMutation, { data, loading, error }] = useTeamRosterReportCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamRosterReportCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeamRosterReportCreateMutation,
    TeamRosterReportCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TeamRosterReportCreateMutation,
    TeamRosterReportCreateMutationVariables
  >(TeamRosterReportCreateDocument, options);
}
export type TeamRosterReportCreateMutationHookResult = ReturnType<
  typeof useTeamRosterReportCreateMutation
>;
export type TeamRosterReportCreateMutationResult =
  Apollo.MutationResult<TeamRosterReportCreateMutation>;
export type TeamRosterReportCreateMutationOptions = Apollo.BaseMutationOptions<
  TeamRosterReportCreateMutation,
  TeamRosterReportCreateMutationVariables
>;
export const UpdatePersonDocument = gql`
  mutation UpdatePerson($id: ID!, $input: PersonInput!) {
    personUpdate(id: $id, input: $input) {
      person {
        id
        displayName
        hasLoginAccess
        usesExternalAuthentication
      }
      userErrors {
        message
      }
    }
  }
`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(
    UpdatePersonDocument,
    options,
  );
}
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;
export const PersonSendInvitationDocument = gql`
  mutation PersonSendInvitation($id: ID!, $input: PersonSendInvitationInput!) {
    personSendInvitation(id: $id, input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type PersonSendInvitationMutationFn = Apollo.MutationFunction<
  PersonSendInvitationMutation,
  PersonSendInvitationMutationVariables
>;

/**
 * __usePersonSendInvitationMutation__
 *
 * To run a mutation, you first call `usePersonSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personSendInvitationMutation, { data, loading, error }] = usePersonSendInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonSendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonSendInvitationMutation,
    PersonSendInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PersonSendInvitationMutation, PersonSendInvitationMutationVariables>(
    PersonSendInvitationDocument,
    options,
  );
}
export type PersonSendInvitationMutationHookResult = ReturnType<
  typeof usePersonSendInvitationMutation
>;
export type PersonSendInvitationMutationResult =
  Apollo.MutationResult<PersonSendInvitationMutation>;
export type PersonSendInvitationMutationOptions = Apollo.BaseMutationOptions<
  PersonSendInvitationMutation,
  PersonSendInvitationMutationVariables
>;
export const CreateUserTypesDocument = gql`
  mutation CreateUserTypes($input: PersonTypeInput) {
    personTypeCreate(input: $input) {
      personType {
        pk
        id
        name
        namePlural
        color
      }
    }
  }
`;
export type CreateUserTypesMutationFn = Apollo.MutationFunction<
  CreateUserTypesMutation,
  CreateUserTypesMutationVariables
>;

/**
 * __useCreateUserTypesMutation__
 *
 * To run a mutation, you first call `useCreateUserTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserTypesMutation, { data, loading, error }] = useCreateUserTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserTypesMutation,
    CreateUserTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserTypesMutation, CreateUserTypesMutationVariables>(
    CreateUserTypesDocument,
    options,
  );
}
export type CreateUserTypesMutationHookResult = ReturnType<typeof useCreateUserTypesMutation>;
export type CreateUserTypesMutationResult = Apollo.MutationResult<CreateUserTypesMutation>;
export type CreateUserTypesMutationOptions = Apollo.BaseMutationOptions<
  CreateUserTypesMutation,
  CreateUserTypesMutationVariables
>;
export const GetUserTypePermissionsDocument = gql`
  query GetUserTypePermissions($personTypeId: TWFilter_ID) {
    personTypes(personTypeId: $personTypeId) {
      edges {
        node {
          defaultUserTypePermissions {
            id
            label
            category
            codeName
            dependencies
            granted
            grantedBy
            isRestrictedType
            restrictions
            tooltip
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserTypePermissionsQuery__
 *
 * To run a query within a React component, call `useGetUserTypePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTypePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTypePermissionsQuery({
 *   variables: {
 *      personTypeId: // value for 'personTypeId'
 *   },
 * });
 */
export function useGetUserTypePermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserTypePermissionsQuery,
    GetUserTypePermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserTypePermissionsQuery, GetUserTypePermissionsQueryVariables>(
    GetUserTypePermissionsDocument,
    options,
  );
}
export function useGetUserTypePermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserTypePermissionsQuery,
    GetUserTypePermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserTypePermissionsQuery, GetUserTypePermissionsQueryVariables>(
    GetUserTypePermissionsDocument,
    options,
  );
}
export type GetUserTypePermissionsQueryHookResult = ReturnType<
  typeof useGetUserTypePermissionsQuery
>;
export type GetUserTypePermissionsLazyQueryHookResult = ReturnType<
  typeof useGetUserTypePermissionsLazyQuery
>;
export type GetUserTypePermissionsQueryResult = Apollo.QueryResult<
  GetUserTypePermissionsQuery,
  GetUserTypePermissionsQueryVariables
>;
export function refetchGetUserTypePermissionsQuery(
  variables?: GetUserTypePermissionsQueryVariables,
) {
  return { query: GetUserTypePermissionsDocument, variables: variables };
}
export const GetUserTypesDocument = gql`
  query GetUserTypes {
    personTypes(sort: [name_asc]) {
      edges {
        node {
          org {
            label
          }
          id
          personTypeId
          name
          namePlural
          color
        }
      }
    }
  }
`;

/**
 * __useGetUserTypesQuery__
 *
 * To run a query within a React component, call `useGetUserTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserTypesQuery, GetUserTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserTypesQuery, GetUserTypesQueryVariables>(
    GetUserTypesDocument,
    options,
  );
}
export function useGetUserTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserTypesQuery, GetUserTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserTypesQuery, GetUserTypesQueryVariables>(
    GetUserTypesDocument,
    options,
  );
}
export type GetUserTypesQueryHookResult = ReturnType<typeof useGetUserTypesQuery>;
export type GetUserTypesLazyQueryHookResult = ReturnType<typeof useGetUserTypesLazyQuery>;
export type GetUserTypesQueryResult = Apollo.QueryResult<
  GetUserTypesQuery,
  GetUserTypesQueryVariables
>;
export function refetchGetUserTypesQuery(variables?: GetUserTypesQueryVariables) {
  return { query: GetUserTypesDocument, variables: variables };
}
export const ManageTeamsDocument = gql`
  query ManageTeams($before: String, $after: String, $first: Int, $last: Int) {
    teams(
      before: $before
      after: $after
      first: $first
      last: $last
      active: "True"
      includeAllTeamsTeam: false
      sort: [label_asc]
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          teamId
          label
          active
          members {
            edges {
              node {
                teamMemberId
                active
                userTypes {
                  name
                  namePlural
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useManageTeamsQuery__
 *
 * To run a query within a React component, call `useManageTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageTeamsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useManageTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<ManageTeamsQuery, ManageTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageTeamsQuery, ManageTeamsQueryVariables>(ManageTeamsDocument, options);
}
export function useManageTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageTeamsQuery, ManageTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageTeamsQuery, ManageTeamsQueryVariables>(
    ManageTeamsDocument,
    options,
  );
}
export type ManageTeamsQueryHookResult = ReturnType<typeof useManageTeamsQuery>;
export type ManageTeamsLazyQueryHookResult = ReturnType<typeof useManageTeamsLazyQuery>;
export type ManageTeamsQueryResult = Apollo.QueryResult<
  ManageTeamsQuery,
  ManageTeamsQueryVariables
>;
export function refetchManageTeamsQuery(variables?: ManageTeamsQueryVariables) {
  return { query: ManageTeamsDocument, variables: variables };
}
export const BulkTeamMemberEditDocument = gql`
  mutation bulkTeamMemberEdit($input: PersonBulkEditTeamMembersInput) {
    personBulkUpdateTeamMemberships(input: $input) {
      person {
        pk
      }
    }
  }
`;
export type BulkTeamMemberEditMutationFn = Apollo.MutationFunction<
  BulkTeamMemberEditMutation,
  BulkTeamMemberEditMutationVariables
>;

/**
 * __useBulkTeamMemberEditMutation__
 *
 * To run a mutation, you first call `useBulkTeamMemberEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkTeamMemberEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkTeamMemberEditMutation, { data, loading, error }] = useBulkTeamMemberEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkTeamMemberEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkTeamMemberEditMutation,
    BulkTeamMemberEditMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkTeamMemberEditMutation, BulkTeamMemberEditMutationVariables>(
    BulkTeamMemberEditDocument,
    options,
  );
}
export type BulkTeamMemberEditMutationHookResult = ReturnType<typeof useBulkTeamMemberEditMutation>;
export type BulkTeamMemberEditMutationResult = Apollo.MutationResult<BulkTeamMemberEditMutation>;
export type BulkTeamMemberEditMutationOptions = Apollo.BaseMutationOptions<
  BulkTeamMemberEditMutation,
  BulkTeamMemberEditMutationVariables
>;
export const TeamMemberUsersInfoDocument = gql`
  query TeamMemberUsersInfo($codes: [String]!) {
    selectables(codes: $codes) {
      ... on SelectionGroup {
        selectionCode
        people {
          id
          firstName
          preferredName
          selectionCode
          isOrgLevelUser
          teams {
            id
            label
          }
        }
      }
      ... on PersonNode {
        id
        firstName
        preferredName
        selectionCode
        isOrgLevelUser
        teams {
          id
          label
        }
      }
    }
  }
`;

/**
 * __useTeamMemberUsersInfoQuery__
 *
 * To run a query within a React component, call `useTeamMemberUsersInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberUsersInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMemberUsersInfoQuery({
 *   variables: {
 *      codes: // value for 'codes'
 *   },
 * });
 */
export function useTeamMemberUsersInfoQuery(
  baseOptions: Apollo.QueryHookOptions<TeamMemberUsersInfoQuery, TeamMemberUsersInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamMemberUsersInfoQuery, TeamMemberUsersInfoQueryVariables>(
    TeamMemberUsersInfoDocument,
    options,
  );
}
export function useTeamMemberUsersInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamMemberUsersInfoQuery,
    TeamMemberUsersInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamMemberUsersInfoQuery, TeamMemberUsersInfoQueryVariables>(
    TeamMemberUsersInfoDocument,
    options,
  );
}
export type TeamMemberUsersInfoQueryHookResult = ReturnType<typeof useTeamMemberUsersInfoQuery>;
export type TeamMemberUsersInfoLazyQueryHookResult = ReturnType<
  typeof useTeamMemberUsersInfoLazyQuery
>;
export type TeamMemberUsersInfoQueryResult = Apollo.QueryResult<
  TeamMemberUsersInfoQuery,
  TeamMemberUsersInfoQueryVariables
>;
export function refetchTeamMemberUsersInfoQuery(variables: TeamMemberUsersInfoQueryVariables) {
  return { query: TeamMemberUsersInfoDocument, variables: variables };
}
export const OrgProfileCategoryDetailDocument = gql`
  query OrgProfileCategoryDetail($attributeCategoryId: TWFilter_ID) {
    orgProfileAttributeCategories(attributeCategoryId: $attributeCategoryId) {
      edges {
        node {
          id
          label
          isCustom
          isActive
          coreCode
          sortOrder
          visibility {
            label
            selectionCode
            ... on PersonNode {
              id
              pictureUrl
              preferredName
              lastName
              initials
              active
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
                label
                selectionCode
                teamMembership {
                  isAthlete
                }
              }
            }
          }
          editability {
            label
            selectionCode
            ... on PersonNode {
              id
              pictureUrl
              preferredName
              lastName
              initials
              active
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
                label
                selectionCode
                teamMembership {
                  isAthlete
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useOrgProfileCategoryDetailQuery__
 *
 * To run a query within a React component, call `useOrgProfileCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgProfileCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgProfileCategoryDetailQuery({
 *   variables: {
 *      attributeCategoryId: // value for 'attributeCategoryId'
 *   },
 * });
 */
export function useOrgProfileCategoryDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgProfileCategoryDetailQuery,
    OrgProfileCategoryDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgProfileCategoryDetailQuery, OrgProfileCategoryDetailQueryVariables>(
    OrgProfileCategoryDetailDocument,
    options,
  );
}
export function useOrgProfileCategoryDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgProfileCategoryDetailQuery,
    OrgProfileCategoryDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgProfileCategoryDetailQuery, OrgProfileCategoryDetailQueryVariables>(
    OrgProfileCategoryDetailDocument,
    options,
  );
}
export type OrgProfileCategoryDetailQueryHookResult = ReturnType<
  typeof useOrgProfileCategoryDetailQuery
>;
export type OrgProfileCategoryDetailLazyQueryHookResult = ReturnType<
  typeof useOrgProfileCategoryDetailLazyQuery
>;
export type OrgProfileCategoryDetailQueryResult = Apollo.QueryResult<
  OrgProfileCategoryDetailQuery,
  OrgProfileCategoryDetailQueryVariables
>;
export function refetchOrgProfileCategoryDetailQuery(
  variables?: OrgProfileCategoryDetailQueryVariables,
) {
  return { query: OrgProfileCategoryDetailDocument, variables: variables };
}
export const OrgYearlyAttributeCategoriesDocument = gql`
  query OrgYearlyAttributeCategories($includeInactiveRecords: Boolean) {
    orgYearlyAttributeCategories(
      sort: [sort_order_asc, label_asc]
      includeInactiveRecords: $includeInactiveRecords
    ) {
      edges {
        node {
          id
          label
          isCustom
          isActive
          sortOrder
          yearlyAttributesActive {
            yearlyAttributeCategoryId
            label
            sortOrder
            enumOptions
            hasUserTypeRestriction
            selfManageUsers
            id
            dataType
            canEdit
            isCustom
            optionsList
            attributeReminders {
              id
              reminderType
              visibility {
                label
              }
              reminderOffset {
                weeks
                months
                years
                days
              }
              active
            }
            rolloverType
            appliesToUserTypes
            selfManagedUserTypes
            label
          }
          visibility {
            label
            selectionCode
          }
          editability {
            label
            selectionCode
          }
        }
      }
    }
  }
`;

/**
 * __useOrgYearlyAttributeCategoriesQuery__
 *
 * To run a query within a React component, call `useOrgYearlyAttributeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgYearlyAttributeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgYearlyAttributeCategoriesQuery({
 *   variables: {
 *      includeInactiveRecords: // value for 'includeInactiveRecords'
 *   },
 * });
 */
export function useOrgYearlyAttributeCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgYearlyAttributeCategoriesQuery,
    OrgYearlyAttributeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgYearlyAttributeCategoriesQuery,
    OrgYearlyAttributeCategoriesQueryVariables
  >(OrgYearlyAttributeCategoriesDocument, options);
}
export function useOrgYearlyAttributeCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgYearlyAttributeCategoriesQuery,
    OrgYearlyAttributeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgYearlyAttributeCategoriesQuery,
    OrgYearlyAttributeCategoriesQueryVariables
  >(OrgYearlyAttributeCategoriesDocument, options);
}
export type OrgYearlyAttributeCategoriesQueryHookResult = ReturnType<
  typeof useOrgYearlyAttributeCategoriesQuery
>;
export type OrgYearlyAttributeCategoriesLazyQueryHookResult = ReturnType<
  typeof useOrgYearlyAttributeCategoriesLazyQuery
>;
export type OrgYearlyAttributeCategoriesQueryResult = Apollo.QueryResult<
  OrgYearlyAttributeCategoriesQuery,
  OrgYearlyAttributeCategoriesQueryVariables
>;
export function refetchOrgYearlyAttributeCategoriesQuery(
  variables?: OrgYearlyAttributeCategoriesQueryVariables,
) {
  return { query: OrgYearlyAttributeCategoriesDocument, variables: variables };
}
export const OrgYearlyCategoryDetailDocument = gql`
  query OrgYearlyCategoryDetail($yearlyAttributeCategoryId: TWFilter_ID) {
    orgYearlyAttributeCategories(yearlyAttributeCategoryId: $yearlyAttributeCategoryId) {
      edges {
        node {
          id
          label
          isCustom
          isActive
          sortOrder
          visibility {
            label
            selectionCode
            ... on PersonNode {
              id
              pictureUrl
              preferredName
              lastName
              initials
              active
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
                label
                selectionCode
                teamMembership {
                  isAthlete
                }
              }
            }
          }
          editability {
            label
            selectionCode
            ... on PersonNode {
              id
              pictureUrl
              preferredName
              lastName
              initials
              active
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
                label
                selectionCode
                teamMembership {
                  isAthlete
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useOrgYearlyCategoryDetailQuery__
 *
 * To run a query within a React component, call `useOrgYearlyCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgYearlyCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgYearlyCategoryDetailQuery({
 *   variables: {
 *      yearlyAttributeCategoryId: // value for 'yearlyAttributeCategoryId'
 *   },
 * });
 */
export function useOrgYearlyCategoryDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgYearlyCategoryDetailQuery,
    OrgYearlyCategoryDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgYearlyCategoryDetailQuery, OrgYearlyCategoryDetailQueryVariables>(
    OrgYearlyCategoryDetailDocument,
    options,
  );
}
export function useOrgYearlyCategoryDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgYearlyCategoryDetailQuery,
    OrgYearlyCategoryDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgYearlyCategoryDetailQuery, OrgYearlyCategoryDetailQueryVariables>(
    OrgYearlyCategoryDetailDocument,
    options,
  );
}
export type OrgYearlyCategoryDetailQueryHookResult = ReturnType<
  typeof useOrgYearlyCategoryDetailQuery
>;
export type OrgYearlyCategoryDetailLazyQueryHookResult = ReturnType<
  typeof useOrgYearlyCategoryDetailLazyQuery
>;
export type OrgYearlyCategoryDetailQueryResult = Apollo.QueryResult<
  OrgYearlyCategoryDetailQuery,
  OrgYearlyCategoryDetailQueryVariables
>;
export function refetchOrgYearlyCategoryDetailQuery(
  variables?: OrgYearlyCategoryDetailQueryVariables,
) {
  return { query: OrgYearlyCategoryDetailDocument, variables: variables };
}
export const ProfileAttributeCategoryCreateDocument = gql`
  mutation ProfileAttributeCategoryCreate($input: ProfileAttributeCategoryInput!) {
    profileAttributeCategoryCreate(input: $input) {
      userErrors {
        message
        field
      }
      attributeCategory {
        id
      }
    }
  }
`;
export type ProfileAttributeCategoryCreateMutationFn = Apollo.MutationFunction<
  ProfileAttributeCategoryCreateMutation,
  ProfileAttributeCategoryCreateMutationVariables
>;

/**
 * __useProfileAttributeCategoryCreateMutation__
 *
 * To run a mutation, you first call `useProfileAttributeCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAttributeCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAttributeCategoryCreateMutation, { data, loading, error }] = useProfileAttributeCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileAttributeCategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileAttributeCategoryCreateMutation,
    ProfileAttributeCategoryCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileAttributeCategoryCreateMutation,
    ProfileAttributeCategoryCreateMutationVariables
  >(ProfileAttributeCategoryCreateDocument, options);
}
export type ProfileAttributeCategoryCreateMutationHookResult = ReturnType<
  typeof useProfileAttributeCategoryCreateMutation
>;
export type ProfileAttributeCategoryCreateMutationResult =
  Apollo.MutationResult<ProfileAttributeCategoryCreateMutation>;
export type ProfileAttributeCategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  ProfileAttributeCategoryCreateMutation,
  ProfileAttributeCategoryCreateMutationVariables
>;
export const ProfileAttributeCategoryDeleteDocument = gql`
  mutation ProfileAttributeCategoryDelete($id: ID!) {
    profileAttributeCategoryDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type ProfileAttributeCategoryDeleteMutationFn = Apollo.MutationFunction<
  ProfileAttributeCategoryDeleteMutation,
  ProfileAttributeCategoryDeleteMutationVariables
>;

/**
 * __useProfileAttributeCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useProfileAttributeCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAttributeCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAttributeCategoryDeleteMutation, { data, loading, error }] = useProfileAttributeCategoryDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileAttributeCategoryDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileAttributeCategoryDeleteMutation,
    ProfileAttributeCategoryDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileAttributeCategoryDeleteMutation,
    ProfileAttributeCategoryDeleteMutationVariables
  >(ProfileAttributeCategoryDeleteDocument, options);
}
export type ProfileAttributeCategoryDeleteMutationHookResult = ReturnType<
  typeof useProfileAttributeCategoryDeleteMutation
>;
export type ProfileAttributeCategoryDeleteMutationResult =
  Apollo.MutationResult<ProfileAttributeCategoryDeleteMutation>;
export type ProfileAttributeCategoryDeleteMutationOptions = Apollo.BaseMutationOptions<
  ProfileAttributeCategoryDeleteMutation,
  ProfileAttributeCategoryDeleteMutationVariables
>;
export const ProfileAttributeCategoryUpdateDocument = gql`
  mutation ProfileAttributeCategoryUpdate($input: ProfileAttributeCategoryInput!, $id: ID!) {
    profileAttributeCategoryUpdate(input: $input, id: $id) {
      userErrors {
        message
        field
      }
      attributeCategory {
        id
      }
    }
  }
`;
export type ProfileAttributeCategoryUpdateMutationFn = Apollo.MutationFunction<
  ProfileAttributeCategoryUpdateMutation,
  ProfileAttributeCategoryUpdateMutationVariables
>;

/**
 * __useProfileAttributeCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useProfileAttributeCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAttributeCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAttributeCategoryUpdateMutation, { data, loading, error }] = useProfileAttributeCategoryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileAttributeCategoryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileAttributeCategoryUpdateMutation,
    ProfileAttributeCategoryUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileAttributeCategoryUpdateMutation,
    ProfileAttributeCategoryUpdateMutationVariables
  >(ProfileAttributeCategoryUpdateDocument, options);
}
export type ProfileAttributeCategoryUpdateMutationHookResult = ReturnType<
  typeof useProfileAttributeCategoryUpdateMutation
>;
export type ProfileAttributeCategoryUpdateMutationResult =
  Apollo.MutationResult<ProfileAttributeCategoryUpdateMutation>;
export type ProfileAttributeCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
  ProfileAttributeCategoryUpdateMutation,
  ProfileAttributeCategoryUpdateMutationVariables
>;
export const ReorderProfileAttributeCategoriesDocument = gql`
  mutation ReorderProfileAttributeCategories($input: ProfileAttributeCategoryReorderInput!) {
    profileAttributeCategoryReorder(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type ReorderProfileAttributeCategoriesMutationFn = Apollo.MutationFunction<
  ReorderProfileAttributeCategoriesMutation,
  ReorderProfileAttributeCategoriesMutationVariables
>;

/**
 * __useReorderProfileAttributeCategoriesMutation__
 *
 * To run a mutation, you first call `useReorderProfileAttributeCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderProfileAttributeCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderProfileAttributeCategoriesMutation, { data, loading, error }] = useReorderProfileAttributeCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderProfileAttributeCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderProfileAttributeCategoriesMutation,
    ReorderProfileAttributeCategoriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderProfileAttributeCategoriesMutation,
    ReorderProfileAttributeCategoriesMutationVariables
  >(ReorderProfileAttributeCategoriesDocument, options);
}
export type ReorderProfileAttributeCategoriesMutationHookResult = ReturnType<
  typeof useReorderProfileAttributeCategoriesMutation
>;
export type ReorderProfileAttributeCategoriesMutationResult =
  Apollo.MutationResult<ReorderProfileAttributeCategoriesMutation>;
export type ReorderProfileAttributeCategoriesMutationOptions = Apollo.BaseMutationOptions<
  ReorderProfileAttributeCategoriesMutation,
  ReorderProfileAttributeCategoriesMutationVariables
>;
export const AttributeCategoryUpdateDocument = gql`
  mutation AttributeCategoryUpdate($input: AttributeCategoryInput!, $id: ID!) {
    attributeCategoryUpdate(input: $input, id: $id) {
      userErrors {
        message
        field
      }
      attributeCategory {
        id
        isActive
      }
    }
  }
`;
export type AttributeCategoryUpdateMutationFn = Apollo.MutationFunction<
  AttributeCategoryUpdateMutation,
  AttributeCategoryUpdateMutationVariables
>;

/**
 * __useAttributeCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useAttributeCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeCategoryUpdateMutation, { data, loading, error }] = useAttributeCategoryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttributeCategoryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttributeCategoryUpdateMutation,
    AttributeCategoryUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AttributeCategoryUpdateMutation,
    AttributeCategoryUpdateMutationVariables
  >(AttributeCategoryUpdateDocument, options);
}
export type AttributeCategoryUpdateMutationHookResult = ReturnType<
  typeof useAttributeCategoryUpdateMutation
>;
export type AttributeCategoryUpdateMutationResult =
  Apollo.MutationResult<AttributeCategoryUpdateMutation>;
export type AttributeCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
  AttributeCategoryUpdateMutation,
  AttributeCategoryUpdateMutationVariables
>;
export const ProfileAttributeDatatypesDocument = gql`
  query profileAttributeDatatypes($sort: [AttributeDataTypeSortEnum]) {
    profileAttributeDatatypes(sort: $sort) {
      edges {
        node {
          pk
          id
          label
        }
      }
    }
  }
`;

/**
 * __useProfileAttributeDatatypesQuery__
 *
 * To run a query within a React component, call `useProfileAttributeDatatypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileAttributeDatatypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileAttributeDatatypesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProfileAttributeDatatypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfileAttributeDatatypesQuery,
    ProfileAttributeDatatypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileAttributeDatatypesQuery, ProfileAttributeDatatypesQueryVariables>(
    ProfileAttributeDatatypesDocument,
    options,
  );
}
export function useProfileAttributeDatatypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileAttributeDatatypesQuery,
    ProfileAttributeDatatypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfileAttributeDatatypesQuery,
    ProfileAttributeDatatypesQueryVariables
  >(ProfileAttributeDatatypesDocument, options);
}
export type ProfileAttributeDatatypesQueryHookResult = ReturnType<
  typeof useProfileAttributeDatatypesQuery
>;
export type ProfileAttributeDatatypesLazyQueryHookResult = ReturnType<
  typeof useProfileAttributeDatatypesLazyQuery
>;
export type ProfileAttributeDatatypesQueryResult = Apollo.QueryResult<
  ProfileAttributeDatatypesQuery,
  ProfileAttributeDatatypesQueryVariables
>;
export function refetchProfileAttributeDatatypesQuery(
  variables?: ProfileAttributeDatatypesQueryVariables,
) {
  return { query: ProfileAttributeDatatypesDocument, variables: variables };
}
export const ProfileAttributeCreateDocument = gql`
  mutation ProfileAttributeCreate($input: ProfileAttributeInput!) {
    profileAttributeCreate(input: $input) {
      userErrors {
        message
        field
      }
      attribute {
        id
      }
    }
  }
`;
export type ProfileAttributeCreateMutationFn = Apollo.MutationFunction<
  ProfileAttributeCreateMutation,
  ProfileAttributeCreateMutationVariables
>;

/**
 * __useProfileAttributeCreateMutation__
 *
 * To run a mutation, you first call `useProfileAttributeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAttributeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAttributeCreateMutation, { data, loading, error }] = useProfileAttributeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileAttributeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileAttributeCreateMutation,
    ProfileAttributeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileAttributeCreateMutation,
    ProfileAttributeCreateMutationVariables
  >(ProfileAttributeCreateDocument, options);
}
export type ProfileAttributeCreateMutationHookResult = ReturnType<
  typeof useProfileAttributeCreateMutation
>;
export type ProfileAttributeCreateMutationResult =
  Apollo.MutationResult<ProfileAttributeCreateMutation>;
export type ProfileAttributeCreateMutationOptions = Apollo.BaseMutationOptions<
  ProfileAttributeCreateMutation,
  ProfileAttributeCreateMutationVariables
>;
export const ProfileAttributeDeleteDocument = gql`
  mutation ProfileAttributeDelete($id: ID!) {
    profileAttributeDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type ProfileAttributeDeleteMutationFn = Apollo.MutationFunction<
  ProfileAttributeDeleteMutation,
  ProfileAttributeDeleteMutationVariables
>;

/**
 * __useProfileAttributeDeleteMutation__
 *
 * To run a mutation, you first call `useProfileAttributeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAttributeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAttributeDeleteMutation, { data, loading, error }] = useProfileAttributeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileAttributeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileAttributeDeleteMutation,
    ProfileAttributeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileAttributeDeleteMutation,
    ProfileAttributeDeleteMutationVariables
  >(ProfileAttributeDeleteDocument, options);
}
export type ProfileAttributeDeleteMutationHookResult = ReturnType<
  typeof useProfileAttributeDeleteMutation
>;
export type ProfileAttributeDeleteMutationResult =
  Apollo.MutationResult<ProfileAttributeDeleteMutation>;
export type ProfileAttributeDeleteMutationOptions = Apollo.BaseMutationOptions<
  ProfileAttributeDeleteMutation,
  ProfileAttributeDeleteMutationVariables
>;
export const ProfileAttributeReorderDocument = gql`
  mutation ProfileAttributeReorder($categoryId: ID!, $input: ProfileAttributeReorderInput!) {
    profileAttributeReorder(id: $categoryId, input: $input) {
      userErrors {
        message
        field
      }
      attributeCategory {
        id
      }
    }
  }
`;
export type ProfileAttributeReorderMutationFn = Apollo.MutationFunction<
  ProfileAttributeReorderMutation,
  ProfileAttributeReorderMutationVariables
>;

/**
 * __useProfileAttributeReorderMutation__
 *
 * To run a mutation, you first call `useProfileAttributeReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAttributeReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAttributeReorderMutation, { data, loading, error }] = useProfileAttributeReorderMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileAttributeReorderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileAttributeReorderMutation,
    ProfileAttributeReorderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileAttributeReorderMutation,
    ProfileAttributeReorderMutationVariables
  >(ProfileAttributeReorderDocument, options);
}
export type ProfileAttributeReorderMutationHookResult = ReturnType<
  typeof useProfileAttributeReorderMutation
>;
export type ProfileAttributeReorderMutationResult =
  Apollo.MutationResult<ProfileAttributeReorderMutation>;
export type ProfileAttributeReorderMutationOptions = Apollo.BaseMutationOptions<
  ProfileAttributeReorderMutation,
  ProfileAttributeReorderMutationVariables
>;
export const ProfileAttributeUpdateDocument = gql`
  mutation ProfileAttributeUpdate($input: ProfileAttributeInput!, $id: ID!) {
    profileAttributeUpdate(input: $input, id: $id) {
      userErrors {
        message
        field
      }
      attribute {
        id
      }
    }
  }
`;
export type ProfileAttributeUpdateMutationFn = Apollo.MutationFunction<
  ProfileAttributeUpdateMutation,
  ProfileAttributeUpdateMutationVariables
>;

/**
 * __useProfileAttributeUpdateMutation__
 *
 * To run a mutation, you first call `useProfileAttributeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAttributeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAttributeUpdateMutation, { data, loading, error }] = useProfileAttributeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileAttributeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProfileAttributeUpdateMutation,
    ProfileAttributeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProfileAttributeUpdateMutation,
    ProfileAttributeUpdateMutationVariables
  >(ProfileAttributeUpdateDocument, options);
}
export type ProfileAttributeUpdateMutationHookResult = ReturnType<
  typeof useProfileAttributeUpdateMutation
>;
export type ProfileAttributeUpdateMutationResult =
  Apollo.MutationResult<ProfileAttributeUpdateMutation>;
export type ProfileAttributeUpdateMutationOptions = Apollo.BaseMutationOptions<
  ProfileAttributeUpdateMutation,
  ProfileAttributeUpdateMutationVariables
>;
export const OrgProfileAttributeCategoriesDocument = gql`
  query OrgProfileAttributeCategories($includeInactiveRecords: Boolean) {
    orgProfileAttributeCategories(
      sort: [sort_order_asc, label_asc]
      includeInactiveRecords: $includeInactiveRecords
    ) {
      edges {
        node {
          id
          label
          coreCode
          isCustom
          isActive
          coreCode
          sortOrder
          visibility {
            label
            selectionCode
          }
          editability {
            label
            selectionCode
          }
          profileAttributes {
            attributeCategoryId
            categoryLabel
            categoryCoreCode
            label
            coreCode
            sortOrder
            enumOptions
            hasUserTypeRestriction
            selfManageUsers
            id
            dataType
            canEdit
            isCustom
            optionsList
            extendedOptionsList {
              label
              key
              value
            }
            appliesToUserTypes
            selfManagedUserTypes
            attributeReminders {
              teamId
              visibility {
                label
                selectionCode
                ... on PersonNode {
                  id
                  pictureUrl
                  preferredName
                  lastName
                  initials
                  active
                  teamMembership {
                    isAthlete
                  }
                }
                ... on SelectionGroup {
                  groupType
                  pluralLabel
                  people {
                    id
                    pictureUrl
                    preferredName
                    lastName
                    initials
                    active
                    label
                    selectionCode
                    teamMembership {
                      isAthlete
                    }
                  }
                }
              }
              reminderType
              reminderOffset {
                weeks
                months
                years
                days
              }
              active
              pk
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useOrgProfileAttributeCategoriesQuery__
 *
 * To run a query within a React component, call `useOrgProfileAttributeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgProfileAttributeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgProfileAttributeCategoriesQuery({
 *   variables: {
 *      includeInactiveRecords: // value for 'includeInactiveRecords'
 *   },
 * });
 */
export function useOrgProfileAttributeCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgProfileAttributeCategoriesQuery,
    OrgProfileAttributeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgProfileAttributeCategoriesQuery,
    OrgProfileAttributeCategoriesQueryVariables
  >(OrgProfileAttributeCategoriesDocument, options);
}
export function useOrgProfileAttributeCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgProfileAttributeCategoriesQuery,
    OrgProfileAttributeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgProfileAttributeCategoriesQuery,
    OrgProfileAttributeCategoriesQueryVariables
  >(OrgProfileAttributeCategoriesDocument, options);
}
export type OrgProfileAttributeCategoriesQueryHookResult = ReturnType<
  typeof useOrgProfileAttributeCategoriesQuery
>;
export type OrgProfileAttributeCategoriesLazyQueryHookResult = ReturnType<
  typeof useOrgProfileAttributeCategoriesLazyQuery
>;
export type OrgProfileAttributeCategoriesQueryResult = Apollo.QueryResult<
  OrgProfileAttributeCategoriesQuery,
  OrgProfileAttributeCategoriesQueryVariables
>;
export function refetchOrgProfileAttributeCategoriesQuery(
  variables?: OrgProfileAttributeCategoriesQueryVariables,
) {
  return { query: OrgProfileAttributeCategoriesDocument, variables: variables };
}
export const TeamProfileAttributeCategoriesDocument = gql`
  query TeamProfileAttributeCategories($includeInactiveRecords: Boolean) {
    teamProfileAttributeCategories(
      sort: [sort_order_asc, label_asc]
      includeInactiveRecords: $includeInactiveRecords
    ) {
      edges {
        node {
          id
          label
          isCustom
          isActive
          sortOrder
          team {
            teamId
            id
          }
          visibility {
            label
            selectionCode
          }
          editability {
            label
            selectionCode
          }
          profileAttributes {
            attributeCategoryId
            categoryLabel
            label
            sortOrder
            enumOptions
            hasUserTypeRestriction
            selfManageUsers
            id
            dataType
            canEdit
            isCustom
            optionsList
            coreCode
            extendedOptionsList {
              label
              key
              value
            }
            appliesToUserTypes
            selfManagedUserTypes
            attributeReminders {
              teamId
              visibility {
                label
                selectionCode
                ... on PersonNode {
                  id
                  pictureUrl
                  preferredName
                  lastName
                  initials
                  active
                  teamMembership {
                    isAthlete
                  }
                }
                ... on SelectionGroup {
                  groupType
                  pluralLabel
                  people {
                    id
                    pictureUrl
                    preferredName
                    lastName
                    initials
                    active
                    label
                    selectionCode
                    teamMembership {
                      isAthlete
                    }
                  }
                }
              }
              reminderType
              reminderOffset {
                weeks
                months
                years
                days
              }
              active
              pk
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTeamProfileAttributeCategoriesQuery__
 *
 * To run a query within a React component, call `useTeamProfileAttributeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamProfileAttributeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamProfileAttributeCategoriesQuery({
 *   variables: {
 *      includeInactiveRecords: // value for 'includeInactiveRecords'
 *   },
 * });
 */
export function useTeamProfileAttributeCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TeamProfileAttributeCategoriesQuery,
    TeamProfileAttributeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TeamProfileAttributeCategoriesQuery,
    TeamProfileAttributeCategoriesQueryVariables
  >(TeamProfileAttributeCategoriesDocument, options);
}
export function useTeamProfileAttributeCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamProfileAttributeCategoriesQuery,
    TeamProfileAttributeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TeamProfileAttributeCategoriesQuery,
    TeamProfileAttributeCategoriesQueryVariables
  >(TeamProfileAttributeCategoriesDocument, options);
}
export type TeamProfileAttributeCategoriesQueryHookResult = ReturnType<
  typeof useTeamProfileAttributeCategoriesQuery
>;
export type TeamProfileAttributeCategoriesLazyQueryHookResult = ReturnType<
  typeof useTeamProfileAttributeCategoriesLazyQuery
>;
export type TeamProfileAttributeCategoriesQueryResult = Apollo.QueryResult<
  TeamProfileAttributeCategoriesQuery,
  TeamProfileAttributeCategoriesQueryVariables
>;
export function refetchTeamProfileAttributeCategoriesQuery(
  variables?: TeamProfileAttributeCategoriesQueryVariables,
) {
  return { query: TeamProfileAttributeCategoriesDocument, variables: variables };
}
export const RelationshipTypesCreateDocument = gql`
  mutation RelationshipTypesCreate($input: PersonRelationshipTypeInput) {
    relationshipTypeCreate(input: $input) {
      personRelationshipType {
        id
        label
        usedCount
      }
    }
  }
`;
export type RelationshipTypesCreateMutationFn = Apollo.MutationFunction<
  RelationshipTypesCreateMutation,
  RelationshipTypesCreateMutationVariables
>;

/**
 * __useRelationshipTypesCreateMutation__
 *
 * To run a mutation, you first call `useRelationshipTypesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelationshipTypesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relationshipTypesCreateMutation, { data, loading, error }] = useRelationshipTypesCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelationshipTypesCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RelationshipTypesCreateMutation,
    RelationshipTypesCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RelationshipTypesCreateMutation,
    RelationshipTypesCreateMutationVariables
  >(RelationshipTypesCreateDocument, options);
}
export type RelationshipTypesCreateMutationHookResult = ReturnType<
  typeof useRelationshipTypesCreateMutation
>;
export type RelationshipTypesCreateMutationResult =
  Apollo.MutationResult<RelationshipTypesCreateMutation>;
export type RelationshipTypesCreateMutationOptions = Apollo.BaseMutationOptions<
  RelationshipTypesCreateMutation,
  RelationshipTypesCreateMutationVariables
>;
export const RelationshipTypesDeleteDocument = gql`
  mutation relationshipTypesDelete($id: ID!) {
    relationshipTypeDelete(id: $id) {
      personRelationshipType {
        id
        label
        usedCount
      }
    }
  }
`;
export type RelationshipTypesDeleteMutationFn = Apollo.MutationFunction<
  RelationshipTypesDeleteMutation,
  RelationshipTypesDeleteMutationVariables
>;

/**
 * __useRelationshipTypesDeleteMutation__
 *
 * To run a mutation, you first call `useRelationshipTypesDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelationshipTypesDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relationshipTypesDeleteMutation, { data, loading, error }] = useRelationshipTypesDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRelationshipTypesDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RelationshipTypesDeleteMutation,
    RelationshipTypesDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RelationshipTypesDeleteMutation,
    RelationshipTypesDeleteMutationVariables
  >(RelationshipTypesDeleteDocument, options);
}
export type RelationshipTypesDeleteMutationHookResult = ReturnType<
  typeof useRelationshipTypesDeleteMutation
>;
export type RelationshipTypesDeleteMutationResult =
  Apollo.MutationResult<RelationshipTypesDeleteMutation>;
export type RelationshipTypesDeleteMutationOptions = Apollo.BaseMutationOptions<
  RelationshipTypesDeleteMutation,
  RelationshipTypesDeleteMutationVariables
>;
export const RelationshipTypesDocument = gql`
  query RelationshipTypes($after: String, $first: Int) {
    relationshipTypes(after: $after, first: $first) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          label
          usedCount
        }
      }
    }
  }
`;

/**
 * __useRelationshipTypesQuery__
 *
 * To run a query within a React component, call `useRelationshipTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipTypesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRelationshipTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<RelationshipTypesQuery, RelationshipTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RelationshipTypesQuery, RelationshipTypesQueryVariables>(
    RelationshipTypesDocument,
    options,
  );
}
export function useRelationshipTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelationshipTypesQuery,
    RelationshipTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RelationshipTypesQuery, RelationshipTypesQueryVariables>(
    RelationshipTypesDocument,
    options,
  );
}
export type RelationshipTypesQueryHookResult = ReturnType<typeof useRelationshipTypesQuery>;
export type RelationshipTypesLazyQueryHookResult = ReturnType<typeof useRelationshipTypesLazyQuery>;
export type RelationshipTypesQueryResult = Apollo.QueryResult<
  RelationshipTypesQuery,
  RelationshipTypesQueryVariables
>;
export function refetchRelationshipTypesQuery(variables?: RelationshipTypesQueryVariables) {
  return { query: RelationshipTypesDocument, variables: variables };
}
export const RelationshipTypesUpdateDocument = gql`
  mutation relationshipTypesUpdate($input: PersonRelationshipTypeInput, $id: ID!) {
    relationshipTypeUpdate(input: $input, id: $id) {
      personRelationshipType {
        id
        label
        usedCount
      }
    }
  }
`;
export type RelationshipTypesUpdateMutationFn = Apollo.MutationFunction<
  RelationshipTypesUpdateMutation,
  RelationshipTypesUpdateMutationVariables
>;

/**
 * __useRelationshipTypesUpdateMutation__
 *
 * To run a mutation, you first call `useRelationshipTypesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelationshipTypesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relationshipTypesUpdateMutation, { data, loading, error }] = useRelationshipTypesUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRelationshipTypesUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RelationshipTypesUpdateMutation,
    RelationshipTypesUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RelationshipTypesUpdateMutation,
    RelationshipTypesUpdateMutationVariables
  >(RelationshipTypesUpdateDocument, options);
}
export type RelationshipTypesUpdateMutationHookResult = ReturnType<
  typeof useRelationshipTypesUpdateMutation
>;
export type RelationshipTypesUpdateMutationResult =
  Apollo.MutationResult<RelationshipTypesUpdateMutation>;
export type RelationshipTypesUpdateMutationOptions = Apollo.BaseMutationOptions<
  RelationshipTypesUpdateMutation,
  RelationshipTypesUpdateMutationVariables
>;
export const TeamProfileCategoryDetailDocument = gql`
  query TeamProfileCategoryDetail($attributeCategoryId: TWFilter_ID) {
    teamProfileAttributeCategories(attributeCategoryId: $attributeCategoryId) {
      edges {
        node {
          id
          label
          isCustom
          isActive
          sortOrder
          visibility {
            label
            selectionCode
            ... on PersonNode {
              id
              pictureUrl
              preferredName
              lastName
              initials
              active
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
                label
                selectionCode
                teamMembership {
                  isAthlete
                }
              }
            }
          }
          editability {
            label
            selectionCode
            ... on PersonNode {
              id
              pictureUrl
              preferredName
              lastName
              initials
              active
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
                label
                selectionCode
                teamMembership {
                  isAthlete
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTeamProfileCategoryDetailQuery__
 *
 * To run a query within a React component, call `useTeamProfileCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamProfileCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamProfileCategoryDetailQuery({
 *   variables: {
 *      attributeCategoryId: // value for 'attributeCategoryId'
 *   },
 * });
 */
export function useTeamProfileCategoryDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TeamProfileCategoryDetailQuery,
    TeamProfileCategoryDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamProfileCategoryDetailQuery, TeamProfileCategoryDetailQueryVariables>(
    TeamProfileCategoryDetailDocument,
    options,
  );
}
export function useTeamProfileCategoryDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamProfileCategoryDetailQuery,
    TeamProfileCategoryDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TeamProfileCategoryDetailQuery,
    TeamProfileCategoryDetailQueryVariables
  >(TeamProfileCategoryDetailDocument, options);
}
export type TeamProfileCategoryDetailQueryHookResult = ReturnType<
  typeof useTeamProfileCategoryDetailQuery
>;
export type TeamProfileCategoryDetailLazyQueryHookResult = ReturnType<
  typeof useTeamProfileCategoryDetailLazyQuery
>;
export type TeamProfileCategoryDetailQueryResult = Apollo.QueryResult<
  TeamProfileCategoryDetailQuery,
  TeamProfileCategoryDetailQueryVariables
>;
export function refetchTeamProfileCategoryDetailQuery(
  variables?: TeamProfileCategoryDetailQueryVariables,
) {
  return { query: TeamProfileCategoryDetailDocument, variables: variables };
}
export const TeamYearlyCategoryDetailDocument = gql`
  query TeamYearlyCategoryDetail($yearlyAttributeCategoryId: TWFilter_ID) {
    teamYearlyAttributeCategories(yearlyAttributeCategoryId: $yearlyAttributeCategoryId) {
      edges {
        node {
          id
          label
          isCustom
          isActive
          sortOrder
          visibility {
            label
            selectionCode
            ... on PersonNode {
              id
              pictureUrl
              preferredName
              lastName
              initials
              active
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
                label
                selectionCode
                teamMembership {
                  isAthlete
                }
              }
            }
          }
          editability {
            label
            selectionCode
            ... on PersonNode {
              id
              pictureUrl
              preferredName
              lastName
              initials
              active
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
                label
                selectionCode
                teamMembership {
                  isAthlete
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTeamYearlyCategoryDetailQuery__
 *
 * To run a query within a React component, call `useTeamYearlyCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamYearlyCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamYearlyCategoryDetailQuery({
 *   variables: {
 *      yearlyAttributeCategoryId: // value for 'yearlyAttributeCategoryId'
 *   },
 * });
 */
export function useTeamYearlyCategoryDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TeamYearlyCategoryDetailQuery,
    TeamYearlyCategoryDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamYearlyCategoryDetailQuery, TeamYearlyCategoryDetailQueryVariables>(
    TeamYearlyCategoryDetailDocument,
    options,
  );
}
export function useTeamYearlyCategoryDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamYearlyCategoryDetailQuery,
    TeamYearlyCategoryDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamYearlyCategoryDetailQuery, TeamYearlyCategoryDetailQueryVariables>(
    TeamYearlyCategoryDetailDocument,
    options,
  );
}
export type TeamYearlyCategoryDetailQueryHookResult = ReturnType<
  typeof useTeamYearlyCategoryDetailQuery
>;
export type TeamYearlyCategoryDetailLazyQueryHookResult = ReturnType<
  typeof useTeamYearlyCategoryDetailLazyQuery
>;
export type TeamYearlyCategoryDetailQueryResult = Apollo.QueryResult<
  TeamYearlyCategoryDetailQuery,
  TeamYearlyCategoryDetailQueryVariables
>;
export function refetchTeamYearlyCategoryDetailQuery(
  variables?: TeamYearlyCategoryDetailQueryVariables,
) {
  return { query: TeamYearlyCategoryDetailDocument, variables: variables };
}
export const TeamYearlyAttributeCategoriesDocument = gql`
  query TeamYearlyAttributeCategories($includeInactiveRecords: Boolean) {
    teamYearlyAttributeCategories(
      sort: [sort_order_asc, label_asc]
      includeInactiveRecords: $includeInactiveRecords
    ) {
      edges {
        node {
          id
          label
          isCustom
          isActive
          sortOrder
          team {
            label
          }
          yearlyAttributesActive {
            yearlyAttributeCategoryId
            label
            sortOrder
            enumOptions
            hasUserTypeRestriction
            selfManageUsers
            id
            dataType
            canEdit
            isCustom
            optionsList
            attributeReminders {
              id
              reminderType
              visibility {
                label
              }
              reminderOffset {
                weeks
                months
                years
                days
              }
              active
            }
            rolloverType
            appliesToUserTypes
            selfManagedUserTypes
            label
          }
          visibility {
            label
            selectionCode
          }
          editability {
            label
            selectionCode
          }
        }
      }
    }
  }
`;

/**
 * __useTeamYearlyAttributeCategoriesQuery__
 *
 * To run a query within a React component, call `useTeamYearlyAttributeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamYearlyAttributeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamYearlyAttributeCategoriesQuery({
 *   variables: {
 *      includeInactiveRecords: // value for 'includeInactiveRecords'
 *   },
 * });
 */
export function useTeamYearlyAttributeCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TeamYearlyAttributeCategoriesQuery,
    TeamYearlyAttributeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TeamYearlyAttributeCategoriesQuery,
    TeamYearlyAttributeCategoriesQueryVariables
  >(TeamYearlyAttributeCategoriesDocument, options);
}
export function useTeamYearlyAttributeCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamYearlyAttributeCategoriesQuery,
    TeamYearlyAttributeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TeamYearlyAttributeCategoriesQuery,
    TeamYearlyAttributeCategoriesQueryVariables
  >(TeamYearlyAttributeCategoriesDocument, options);
}
export type TeamYearlyAttributeCategoriesQueryHookResult = ReturnType<
  typeof useTeamYearlyAttributeCategoriesQuery
>;
export type TeamYearlyAttributeCategoriesLazyQueryHookResult = ReturnType<
  typeof useTeamYearlyAttributeCategoriesLazyQuery
>;
export type TeamYearlyAttributeCategoriesQueryResult = Apollo.QueryResult<
  TeamYearlyAttributeCategoriesQuery,
  TeamYearlyAttributeCategoriesQueryVariables
>;
export function refetchTeamYearlyAttributeCategoriesQuery(
  variables?: TeamYearlyAttributeCategoriesQueryVariables,
) {
  return { query: TeamYearlyAttributeCategoriesDocument, variables: variables };
}
export const UpdateUserTypesDocument = gql`
  mutation UpdateUserTypes($input: PersonTypeInput, $id: ID!) {
    personTypeUpdate(input: $input, id: $id) {
      personType {
        pk
        id
        name
        namePlural
        color
      }
    }
  }
`;
export type UpdateUserTypesMutationFn = Apollo.MutationFunction<
  UpdateUserTypesMutation,
  UpdateUserTypesMutationVariables
>;

/**
 * __useUpdateUserTypesMutation__
 *
 * To run a mutation, you first call `useUpdateUserTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTypesMutation, { data, loading, error }] = useUpdateUserTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserTypesMutation,
    UpdateUserTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserTypesMutation, UpdateUserTypesMutationVariables>(
    UpdateUserTypesDocument,
    options,
  );
}
export type UpdateUserTypesMutationHookResult = ReturnType<typeof useUpdateUserTypesMutation>;
export type UpdateUserTypesMutationResult = Apollo.MutationResult<UpdateUserTypesMutation>;
export type UpdateUserTypesMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserTypesMutation,
  UpdateUserTypesMutationVariables
>;
export const YearlyAttributeCategoryCreateDocument = gql`
  mutation YearlyAttributeCategoryCreate($input: YearlyAttributeCategoryInput!) {
    yearlyAttributeCategoryCreate(input: $input) {
      userErrors {
        message
        field
      }
      yearlyAttributeCategory {
        id
        label
        team {
          label
        }
        visibility {
          label
          selectionCode
        }
        editability {
          label
          selectionCode
        }
      }
    }
  }
`;
export type YearlyAttributeCategoryCreateMutationFn = Apollo.MutationFunction<
  YearlyAttributeCategoryCreateMutation,
  YearlyAttributeCategoryCreateMutationVariables
>;

/**
 * __useYearlyAttributeCategoryCreateMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeCategoryCreateMutation, { data, loading, error }] = useYearlyAttributeCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useYearlyAttributeCategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeCategoryCreateMutation,
    YearlyAttributeCategoryCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeCategoryCreateMutation,
    YearlyAttributeCategoryCreateMutationVariables
  >(YearlyAttributeCategoryCreateDocument, options);
}
export type YearlyAttributeCategoryCreateMutationHookResult = ReturnType<
  typeof useYearlyAttributeCategoryCreateMutation
>;
export type YearlyAttributeCategoryCreateMutationResult =
  Apollo.MutationResult<YearlyAttributeCategoryCreateMutation>;
export type YearlyAttributeCategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeCategoryCreateMutation,
  YearlyAttributeCategoryCreateMutationVariables
>;
export const YearlyAttributeCategoryDeleteDocument = gql`
  mutation YearlyAttributeCategoryDelete($id: ID!) {
    yearlyAttributeCategoryDelete(id: $id) {
      userErrors {
        message
        field
      }
      yearlyAttributeCategory {
        id
        yearlyAttributeCategoryId
        label
        team {
          label
        }
      }
    }
  }
`;
export type YearlyAttributeCategoryDeleteMutationFn = Apollo.MutationFunction<
  YearlyAttributeCategoryDeleteMutation,
  YearlyAttributeCategoryDeleteMutationVariables
>;

/**
 * __useYearlyAttributeCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeCategoryDeleteMutation, { data, loading, error }] = useYearlyAttributeCategoryDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useYearlyAttributeCategoryDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeCategoryDeleteMutation,
    YearlyAttributeCategoryDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeCategoryDeleteMutation,
    YearlyAttributeCategoryDeleteMutationVariables
  >(YearlyAttributeCategoryDeleteDocument, options);
}
export type YearlyAttributeCategoryDeleteMutationHookResult = ReturnType<
  typeof useYearlyAttributeCategoryDeleteMutation
>;
export type YearlyAttributeCategoryDeleteMutationResult =
  Apollo.MutationResult<YearlyAttributeCategoryDeleteMutation>;
export type YearlyAttributeCategoryDeleteMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeCategoryDeleteMutation,
  YearlyAttributeCategoryDeleteMutationVariables
>;
export const YearlyAttributeCategoryReorderDocument = gql`
  mutation yearlyAttributeCategoryReorder($input: YearlyAttributeCategoryReorderInput!) {
    yearlyAttributeCategoryReorder(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type YearlyAttributeCategoryReorderMutationFn = Apollo.MutationFunction<
  YearlyAttributeCategoryReorderMutation,
  YearlyAttributeCategoryReorderMutationVariables
>;

/**
 * __useYearlyAttributeCategoryReorderMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeCategoryReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeCategoryReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeCategoryReorderMutation, { data, loading, error }] = useYearlyAttributeCategoryReorderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useYearlyAttributeCategoryReorderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeCategoryReorderMutation,
    YearlyAttributeCategoryReorderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeCategoryReorderMutation,
    YearlyAttributeCategoryReorderMutationVariables
  >(YearlyAttributeCategoryReorderDocument, options);
}
export type YearlyAttributeCategoryReorderMutationHookResult = ReturnType<
  typeof useYearlyAttributeCategoryReorderMutation
>;
export type YearlyAttributeCategoryReorderMutationResult =
  Apollo.MutationResult<YearlyAttributeCategoryReorderMutation>;
export type YearlyAttributeCategoryReorderMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeCategoryReorderMutation,
  YearlyAttributeCategoryReorderMutationVariables
>;
export const YearlyAttributeCategoryUpdateDocument = gql`
  mutation YearlyAttributeCategoryUpdate($input: YearlyAttributeCategoryInput!, $id: ID!) {
    yearlyAttributeCategoryUpdate(input: $input, id: $id) {
      userErrors {
        message
        field
      }
      yearlyAttributeCategory {
        id
        yearlyAttributeCategoryId
        label
        visibility {
          label
          selectionCode
        }
        editability {
          label
          selectionCode
        }
        team {
          label
        }
      }
    }
  }
`;
export type YearlyAttributeCategoryUpdateMutationFn = Apollo.MutationFunction<
  YearlyAttributeCategoryUpdateMutation,
  YearlyAttributeCategoryUpdateMutationVariables
>;

/**
 * __useYearlyAttributeCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeCategoryUpdateMutation, { data, loading, error }] = useYearlyAttributeCategoryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useYearlyAttributeCategoryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeCategoryUpdateMutation,
    YearlyAttributeCategoryUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeCategoryUpdateMutation,
    YearlyAttributeCategoryUpdateMutationVariables
  >(YearlyAttributeCategoryUpdateDocument, options);
}
export type YearlyAttributeCategoryUpdateMutationHookResult = ReturnType<
  typeof useYearlyAttributeCategoryUpdateMutation
>;
export type YearlyAttributeCategoryUpdateMutationResult =
  Apollo.MutationResult<YearlyAttributeCategoryUpdateMutation>;
export type YearlyAttributeCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeCategoryUpdateMutation,
  YearlyAttributeCategoryUpdateMutationVariables
>;
export const YearlyAttributeSettingCreateDocument = gql`
  mutation YearlyAttributeSettingCreate($input: YearlyAttributeSettingInput!) {
    yearlyAttributeSettingCreate(input: $input) {
      userErrors {
        message
        field
      }
      yearlyAttribute {
        label
        category {
          team {
            label
          }
        }
      }
    }
  }
`;
export type YearlyAttributeSettingCreateMutationFn = Apollo.MutationFunction<
  YearlyAttributeSettingCreateMutation,
  YearlyAttributeSettingCreateMutationVariables
>;

/**
 * __useYearlyAttributeSettingCreateMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeSettingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeSettingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeSettingCreateMutation, { data, loading, error }] = useYearlyAttributeSettingCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useYearlyAttributeSettingCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeSettingCreateMutation,
    YearlyAttributeSettingCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeSettingCreateMutation,
    YearlyAttributeSettingCreateMutationVariables
  >(YearlyAttributeSettingCreateDocument, options);
}
export type YearlyAttributeSettingCreateMutationHookResult = ReturnType<
  typeof useYearlyAttributeSettingCreateMutation
>;
export type YearlyAttributeSettingCreateMutationResult =
  Apollo.MutationResult<YearlyAttributeSettingCreateMutation>;
export type YearlyAttributeSettingCreateMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeSettingCreateMutation,
  YearlyAttributeSettingCreateMutationVariables
>;
export const YearlyAttributeSettingDeleteDocument = gql`
  mutation YearlyAttributeSettingDelete($yearlyAttributeGid: ID!) {
    yearlyAttributeSettingDelete(id: $yearlyAttributeGid) {
      userErrors {
        message
        field
      }
      yearlyAttribute {
        label
        category {
          team {
            label
          }
        }
      }
    }
  }
`;
export type YearlyAttributeSettingDeleteMutationFn = Apollo.MutationFunction<
  YearlyAttributeSettingDeleteMutation,
  YearlyAttributeSettingDeleteMutationVariables
>;

/**
 * __useYearlyAttributeSettingDeleteMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeSettingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeSettingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeSettingDeleteMutation, { data, loading, error }] = useYearlyAttributeSettingDeleteMutation({
 *   variables: {
 *      yearlyAttributeGid: // value for 'yearlyAttributeGid'
 *   },
 * });
 */
export function useYearlyAttributeSettingDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeSettingDeleteMutation,
    YearlyAttributeSettingDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeSettingDeleteMutation,
    YearlyAttributeSettingDeleteMutationVariables
  >(YearlyAttributeSettingDeleteDocument, options);
}
export type YearlyAttributeSettingDeleteMutationHookResult = ReturnType<
  typeof useYearlyAttributeSettingDeleteMutation
>;
export type YearlyAttributeSettingDeleteMutationResult =
  Apollo.MutationResult<YearlyAttributeSettingDeleteMutation>;
export type YearlyAttributeSettingDeleteMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeSettingDeleteMutation,
  YearlyAttributeSettingDeleteMutationVariables
>;
export const YearlyAttributeSettingReorderDocument = gql`
  mutation YearlyAttributeSettingReorder($id: ID, $input: YearlyAttributeSettingReorderInput!) {
    yearlyAttributeSettingReorder(id: $id, input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type YearlyAttributeSettingReorderMutationFn = Apollo.MutationFunction<
  YearlyAttributeSettingReorderMutation,
  YearlyAttributeSettingReorderMutationVariables
>;

/**
 * __useYearlyAttributeSettingReorderMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeSettingReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeSettingReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeSettingReorderMutation, { data, loading, error }] = useYearlyAttributeSettingReorderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useYearlyAttributeSettingReorderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeSettingReorderMutation,
    YearlyAttributeSettingReorderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeSettingReorderMutation,
    YearlyAttributeSettingReorderMutationVariables
  >(YearlyAttributeSettingReorderDocument, options);
}
export type YearlyAttributeSettingReorderMutationHookResult = ReturnType<
  typeof useYearlyAttributeSettingReorderMutation
>;
export type YearlyAttributeSettingReorderMutationResult =
  Apollo.MutationResult<YearlyAttributeSettingReorderMutation>;
export type YearlyAttributeSettingReorderMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeSettingReorderMutation,
  YearlyAttributeSettingReorderMutationVariables
>;
export const YearlyAttributeSettingUpdateDocument = gql`
  mutation YearlyAttributeSettingUpdate($input: YearlyAttributeSettingInput!, $id: ID!) {
    yearlyAttributeSettingUpdate(input: $input, id: $id) {
      userErrors {
        message
        field
      }
      yearlyAttribute {
        label
        category {
          team {
            label
          }
        }
      }
    }
  }
`;
export type YearlyAttributeSettingUpdateMutationFn = Apollo.MutationFunction<
  YearlyAttributeSettingUpdateMutation,
  YearlyAttributeSettingUpdateMutationVariables
>;

/**
 * __useYearlyAttributeSettingUpdateMutation__
 *
 * To run a mutation, you first call `useYearlyAttributeSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYearlyAttributeSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yearlyAttributeSettingUpdateMutation, { data, loading, error }] = useYearlyAttributeSettingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useYearlyAttributeSettingUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    YearlyAttributeSettingUpdateMutation,
    YearlyAttributeSettingUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    YearlyAttributeSettingUpdateMutation,
    YearlyAttributeSettingUpdateMutationVariables
  >(YearlyAttributeSettingUpdateDocument, options);
}
export type YearlyAttributeSettingUpdateMutationHookResult = ReturnType<
  typeof useYearlyAttributeSettingUpdateMutation
>;
export type YearlyAttributeSettingUpdateMutationResult =
  Apollo.MutationResult<YearlyAttributeSettingUpdateMutation>;
export type YearlyAttributeSettingUpdateMutationOptions = Apollo.BaseMutationOptions<
  YearlyAttributeSettingUpdateMutation,
  YearlyAttributeSettingUpdateMutationVariables
>;
export const FetchNotificationSettingDocument = gql`
  query FetchNotificationSetting(
    $onlyAthStatusFilter: Boolean
    $onlyUserTypeFilter: Boolean
    $onlyEnabledFilter: Boolean
    $notificationSettingId: TWFilter_ID
  ) {
    notificationSettings(
      onlyAthStatusFilter: $onlyAthStatusFilter
      onlyUserTypeFilter: $onlyUserTypeFilter
      onlyEnabledFilter: $onlyEnabledFilter
      notificationSettingId: $notificationSettingId
    ) {
      edges {
        node {
          notificationSettingId
          orgId
          enabled
          notifSettingType
          selectionsToWatch {
            selectionCode
            ... on SelectionGroup {
              groupType
              pluralLabel
              label
              people {
                personId
                firstName
                lastName
                preferredName
                label
                selectionCode
                pictureUrl
                teamMembership {
                  isAthlete
                  team {
                    label
                  }
                }
              }
            }
            ... on PersonNode {
              personId
              firstName
              lastName
              preferredName
              initials
              pictureUrl
              teamMembership {
                isAthlete
                team {
                  label
                }
              }
            }
          }
          selectionsToSendTo {
            selectionCode
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                personId
                firstName
                lastName
                preferredName
                label
                selectionCode
                pictureUrl
                teamMembership {
                  isAthlete
                  team {
                    label
                  }
                }
              }
            }
            ... on PersonNode {
              label
              personId
              firstName
              lastName
              preferredName
              initials
              pictureUrl
              teamMembership {
                team {
                  label
                }
                isAthlete
              }
            }
          }
          teams
          personStatusTypes
        }
      }
    }
  }
`;

/**
 * __useFetchNotificationSettingQuery__
 *
 * To run a query within a React component, call `useFetchNotificationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchNotificationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchNotificationSettingQuery({
 *   variables: {
 *      onlyAthStatusFilter: // value for 'onlyAthStatusFilter'
 *      onlyUserTypeFilter: // value for 'onlyUserTypeFilter'
 *      onlyEnabledFilter: // value for 'onlyEnabledFilter'
 *      notificationSettingId: // value for 'notificationSettingId'
 *   },
 * });
 */
export function useFetchNotificationSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchNotificationSettingQuery,
    FetchNotificationSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchNotificationSettingQuery, FetchNotificationSettingQueryVariables>(
    FetchNotificationSettingDocument,
    options,
  );
}
export function useFetchNotificationSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchNotificationSettingQuery,
    FetchNotificationSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchNotificationSettingQuery, FetchNotificationSettingQueryVariables>(
    FetchNotificationSettingDocument,
    options,
  );
}
export type FetchNotificationSettingQueryHookResult = ReturnType<
  typeof useFetchNotificationSettingQuery
>;
export type FetchNotificationSettingLazyQueryHookResult = ReturnType<
  typeof useFetchNotificationSettingLazyQuery
>;
export type FetchNotificationSettingQueryResult = Apollo.QueryResult<
  FetchNotificationSettingQuery,
  FetchNotificationSettingQueryVariables
>;
export function refetchFetchNotificationSettingQuery(
  variables?: FetchNotificationSettingQueryVariables,
) {
  return { query: FetchNotificationSettingDocument, variables: variables };
}
export const FetchNotificationSettingsDocument = gql`
  query FetchNotificationSettings(
    $onlyAthStatusFilter: Boolean
    $onlyUserTypeFilter: Boolean
    $onlyEnabledFilter: Boolean
  ) {
    notificationSettings(
      onlyAthStatusFilter: $onlyAthStatusFilter
      onlyUserTypeFilter: $onlyUserTypeFilter
      onlyEnabledFilter: $onlyEnabledFilter
    ) {
      totalCount
      edges {
        node {
          label
          notificationSettingId
          orgId
          enabled
          notifSettingType
          selectionsToWatchCount
          peopleToSendToCount
          teams
          personStatusTypes
        }
      }
    }
  }
`;

/**
 * __useFetchNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useFetchNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchNotificationSettingsQuery({
 *   variables: {
 *      onlyAthStatusFilter: // value for 'onlyAthStatusFilter'
 *      onlyUserTypeFilter: // value for 'onlyUserTypeFilter'
 *      onlyEnabledFilter: // value for 'onlyEnabledFilter'
 *   },
 * });
 */
export function useFetchNotificationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchNotificationSettingsQuery,
    FetchNotificationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchNotificationSettingsQuery, FetchNotificationSettingsQueryVariables>(
    FetchNotificationSettingsDocument,
    options,
  );
}
export function useFetchNotificationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchNotificationSettingsQuery,
    FetchNotificationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchNotificationSettingsQuery,
    FetchNotificationSettingsQueryVariables
  >(FetchNotificationSettingsDocument, options);
}
export type FetchNotificationSettingsQueryHookResult = ReturnType<
  typeof useFetchNotificationSettingsQuery
>;
export type FetchNotificationSettingsLazyQueryHookResult = ReturnType<
  typeof useFetchNotificationSettingsLazyQuery
>;
export type FetchNotificationSettingsQueryResult = Apollo.QueryResult<
  FetchNotificationSettingsQuery,
  FetchNotificationSettingsQueryVariables
>;
export function refetchFetchNotificationSettingsQuery(
  variables?: FetchNotificationSettingsQueryVariables,
) {
  return { query: FetchNotificationSettingsDocument, variables: variables };
}
export const NotificationSettingBulkSaveDocument = gql`
  mutation notificationSettingBulkSave($input: NotificationSettingBulkSaveInput) {
    notificationSettingBulkSave(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type NotificationSettingBulkSaveMutationFn = Apollo.MutationFunction<
  NotificationSettingBulkSaveMutation,
  NotificationSettingBulkSaveMutationVariables
>;

/**
 * __useNotificationSettingBulkSaveMutation__
 *
 * To run a mutation, you first call `useNotificationSettingBulkSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingBulkSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSettingBulkSaveMutation, { data, loading, error }] = useNotificationSettingBulkSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationSettingBulkSaveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationSettingBulkSaveMutation,
    NotificationSettingBulkSaveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NotificationSettingBulkSaveMutation,
    NotificationSettingBulkSaveMutationVariables
  >(NotificationSettingBulkSaveDocument, options);
}
export type NotificationSettingBulkSaveMutationHookResult = ReturnType<
  typeof useNotificationSettingBulkSaveMutation
>;
export type NotificationSettingBulkSaveMutationResult =
  Apollo.MutationResult<NotificationSettingBulkSaveMutation>;
export type NotificationSettingBulkSaveMutationOptions = Apollo.BaseMutationOptions<
  NotificationSettingBulkSaveMutation,
  NotificationSettingBulkSaveMutationVariables
>;
export const NotificationSettingDeleteDocument = gql`
  mutation notificationSettingDelete($id: ID!) {
    notificationSettingDelete(id: $id) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type NotificationSettingDeleteMutationFn = Apollo.MutationFunction<
  NotificationSettingDeleteMutation,
  NotificationSettingDeleteMutationVariables
>;

/**
 * __useNotificationSettingDeleteMutation__
 *
 * To run a mutation, you first call `useNotificationSettingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSettingDeleteMutation, { data, loading, error }] = useNotificationSettingDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationSettingDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationSettingDeleteMutation,
    NotificationSettingDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NotificationSettingDeleteMutation,
    NotificationSettingDeleteMutationVariables
  >(NotificationSettingDeleteDocument, options);
}
export type NotificationSettingDeleteMutationHookResult = ReturnType<
  typeof useNotificationSettingDeleteMutation
>;
export type NotificationSettingDeleteMutationResult =
  Apollo.MutationResult<NotificationSettingDeleteMutation>;
export type NotificationSettingDeleteMutationOptions = Apollo.BaseMutationOptions<
  NotificationSettingDeleteMutation,
  NotificationSettingDeleteMutationVariables
>;
export const ProfilesAdvancedFiltersDocument = gql`
  query ProfilesAdvancedFilters($teamId: TWFilter_Int) {
    orgProfileAttributeCategories(
      sort: [sort_order_asc, label_asc]
      rosterManagementCategories: true
    ) {
      edges {
        node {
          label
          coreCode
          profileAttributesForAthlete {
            id
            label
            dataType
            optionsList
            isCustom
            coreCode
            extendedOptionsList {
              label
              value
            }
          }
        }
      }
    }
    teamProfileAttributeCategories(
      sort: [sort_order_asc, label_asc]
      rosterManagementCategories: true
      teamId: $teamId
    ) {
      edges {
        node {
          label
          profileAttributesForAthlete {
            id
            label
            dataType
            optionsList
            isCustom
            coreCode
            extendedOptionsList {
              label
              value
            }
          }
        }
      }
    }
  }
`;

/**
 * __useProfilesAdvancedFiltersQuery__
 *
 * To run a query within a React component, call `useProfilesAdvancedFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesAdvancedFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesAdvancedFiltersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useProfilesAdvancedFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfilesAdvancedFiltersQuery,
    ProfilesAdvancedFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfilesAdvancedFiltersQuery, ProfilesAdvancedFiltersQueryVariables>(
    ProfilesAdvancedFiltersDocument,
    options,
  );
}
export function useProfilesAdvancedFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfilesAdvancedFiltersQuery,
    ProfilesAdvancedFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfilesAdvancedFiltersQuery, ProfilesAdvancedFiltersQueryVariables>(
    ProfilesAdvancedFiltersDocument,
    options,
  );
}
export type ProfilesAdvancedFiltersQueryHookResult = ReturnType<
  typeof useProfilesAdvancedFiltersQuery
>;
export type ProfilesAdvancedFiltersLazyQueryHookResult = ReturnType<
  typeof useProfilesAdvancedFiltersLazyQuery
>;
export type ProfilesAdvancedFiltersQueryResult = Apollo.QueryResult<
  ProfilesAdvancedFiltersQuery,
  ProfilesAdvancedFiltersQueryVariables
>;
export function refetchProfilesAdvancedFiltersQuery(
  variables?: ProfilesAdvancedFiltersQueryVariables,
) {
  return { query: ProfilesAdvancedFiltersDocument, variables: variables };
}
export const BulkCreateTeamRosterDocument = gql`
  mutation BulkCreateTeamRoster($input: TeamRosterBulkCreateInput!) {
    teamRosterBulkCreate(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type BulkCreateTeamRosterMutationFn = Apollo.MutationFunction<
  BulkCreateTeamRosterMutation,
  BulkCreateTeamRosterMutationVariables
>;

/**
 * __useBulkCreateTeamRosterMutation__
 *
 * To run a mutation, you first call `useBulkCreateTeamRosterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateTeamRosterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateTeamRosterMutation, { data, loading, error }] = useBulkCreateTeamRosterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateTeamRosterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkCreateTeamRosterMutation,
    BulkCreateTeamRosterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkCreateTeamRosterMutation, BulkCreateTeamRosterMutationVariables>(
    BulkCreateTeamRosterDocument,
    options,
  );
}
export type BulkCreateTeamRosterMutationHookResult = ReturnType<
  typeof useBulkCreateTeamRosterMutation
>;
export type BulkCreateTeamRosterMutationResult =
  Apollo.MutationResult<BulkCreateTeamRosterMutation>;
export type BulkCreateTeamRosterMutationOptions = Apollo.BaseMutationOptions<
  BulkCreateTeamRosterMutation,
  BulkCreateTeamRosterMutationVariables
>;
export const TeamRosterDocument = gql`
  query TeamRoster($tmpYearId: TWFilter_Int, $teamId: TWFilter_Int) {
    teamRosters(tmpYearId: $tmpYearId, teamId: $teamId) {
      edges {
        node {
          id
          activeAthletesNotOnRoster {
            id
            personId
            displayName
            initials
            pictureUrl
          }
          rosterPersonsList
        }
      }
    }
  }
`;

/**
 * __useTeamRosterQuery__
 *
 * To run a query within a React component, call `useTeamRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamRosterQuery({
 *   variables: {
 *      tmpYearId: // value for 'tmpYearId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamRosterQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamRosterQuery, TeamRosterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamRosterQuery, TeamRosterQueryVariables>(TeamRosterDocument, options);
}
export function useTeamRosterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamRosterQuery, TeamRosterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamRosterQuery, TeamRosterQueryVariables>(
    TeamRosterDocument,
    options,
  );
}
export type TeamRosterQueryHookResult = ReturnType<typeof useTeamRosterQuery>;
export type TeamRosterLazyQueryHookResult = ReturnType<typeof useTeamRosterLazyQuery>;
export type TeamRosterQueryResult = Apollo.QueryResult<TeamRosterQuery, TeamRosterQueryVariables>;
export function refetchTeamRosterQuery(variables?: TeamRosterQueryVariables) {
  return { query: TeamRosterDocument, variables: variables };
}
export const TeamRosterPersonBulkDeleteDocument = gql`
  mutation TeamRosterPersonBulkDelete($input: TeamRosterPersonBulkInput!) {
    teamRosterPersonBulkDelete(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type TeamRosterPersonBulkDeleteMutationFn = Apollo.MutationFunction<
  TeamRosterPersonBulkDeleteMutation,
  TeamRosterPersonBulkDeleteMutationVariables
>;

/**
 * __useTeamRosterPersonBulkDeleteMutation__
 *
 * To run a mutation, you first call `useTeamRosterPersonBulkDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamRosterPersonBulkDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamRosterPersonBulkDeleteMutation, { data, loading, error }] = useTeamRosterPersonBulkDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamRosterPersonBulkDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeamRosterPersonBulkDeleteMutation,
    TeamRosterPersonBulkDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TeamRosterPersonBulkDeleteMutation,
    TeamRosterPersonBulkDeleteMutationVariables
  >(TeamRosterPersonBulkDeleteDocument, options);
}
export type TeamRosterPersonBulkDeleteMutationHookResult = ReturnType<
  typeof useTeamRosterPersonBulkDeleteMutation
>;
export type TeamRosterPersonBulkDeleteMutationResult =
  Apollo.MutationResult<TeamRosterPersonBulkDeleteMutation>;
export type TeamRosterPersonBulkDeleteMutationOptions = Apollo.BaseMutationOptions<
  TeamRosterPersonBulkDeleteMutation,
  TeamRosterPersonBulkDeleteMutationVariables
>;
export const TeamRosterPersonDeleteDocument = gql`
  mutation TeamRosterPersonDelete($input: TeamRosterPersonInput!) {
    teamRosterPersonDelete(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type TeamRosterPersonDeleteMutationFn = Apollo.MutationFunction<
  TeamRosterPersonDeleteMutation,
  TeamRosterPersonDeleteMutationVariables
>;

/**
 * __useTeamRosterPersonDeleteMutation__
 *
 * To run a mutation, you first call `useTeamRosterPersonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamRosterPersonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamRosterPersonDeleteMutation, { data, loading, error }] = useTeamRosterPersonDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamRosterPersonDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeamRosterPersonDeleteMutation,
    TeamRosterPersonDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TeamRosterPersonDeleteMutation,
    TeamRosterPersonDeleteMutationVariables
  >(TeamRosterPersonDeleteDocument, options);
}
export type TeamRosterPersonDeleteMutationHookResult = ReturnType<
  typeof useTeamRosterPersonDeleteMutation
>;
export type TeamRosterPersonDeleteMutationResult =
  Apollo.MutationResult<TeamRosterPersonDeleteMutation>;
export type TeamRosterPersonDeleteMutationOptions = Apollo.BaseMutationOptions<
  TeamRosterPersonDeleteMutation,
  TeamRosterPersonDeleteMutationVariables
>;
export const RolloverTeamRosterPersonDocument = gql`
  mutation RolloverTeamRosterPerson($input: TeamRosterPersonRolloverInput!) {
    teamRosterPersonRollover(input: $input) {
      userErrors {
        message
        field
      }
      teamRosterPerson {
        person {
          firstName
          lastName
        }
      }
      bulkUserErrors {
        objectKey
        errorList {
          message
          field
        }
      }
    }
  }
`;
export type RolloverTeamRosterPersonMutationFn = Apollo.MutationFunction<
  RolloverTeamRosterPersonMutation,
  RolloverTeamRosterPersonMutationVariables
>;

/**
 * __useRolloverTeamRosterPersonMutation__
 *
 * To run a mutation, you first call `useRolloverTeamRosterPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRolloverTeamRosterPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rolloverTeamRosterPersonMutation, { data, loading, error }] = useRolloverTeamRosterPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRolloverTeamRosterPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RolloverTeamRosterPersonMutation,
    RolloverTeamRosterPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RolloverTeamRosterPersonMutation,
    RolloverTeamRosterPersonMutationVariables
  >(RolloverTeamRosterPersonDocument, options);
}
export type RolloverTeamRosterPersonMutationHookResult = ReturnType<
  typeof useRolloverTeamRosterPersonMutation
>;
export type RolloverTeamRosterPersonMutationResult =
  Apollo.MutationResult<RolloverTeamRosterPersonMutation>;
export type RolloverTeamRosterPersonMutationOptions = Apollo.BaseMutationOptions<
  RolloverTeamRosterPersonMutation,
  RolloverTeamRosterPersonMutationVariables
>;
export const TeamRosterPersonRolloverYearlyAttributeDocument = gql`
  mutation TeamRosterPersonRolloverYearlyAttribute(
    $input: TeamRosterPersonRolloverYearlyAttributeInput!
  ) {
    teamRosterPersonYearlyAttributeBulkRollover(input: $input) {
      userErrors {
        message
        field
      }
      teamRosterPerson {
        person {
          firstName
          lastName
        }
      }
      bulkUserErrors {
        objectKey
        errorList {
          message
          field
        }
      }
    }
  }
`;
export type TeamRosterPersonRolloverYearlyAttributeMutationFn = Apollo.MutationFunction<
  TeamRosterPersonRolloverYearlyAttributeMutation,
  TeamRosterPersonRolloverYearlyAttributeMutationVariables
>;

/**
 * __useTeamRosterPersonRolloverYearlyAttributeMutation__
 *
 * To run a mutation, you first call `useTeamRosterPersonRolloverYearlyAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamRosterPersonRolloverYearlyAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamRosterPersonRolloverYearlyAttributeMutation, { data, loading, error }] = useTeamRosterPersonRolloverYearlyAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamRosterPersonRolloverYearlyAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeamRosterPersonRolloverYearlyAttributeMutation,
    TeamRosterPersonRolloverYearlyAttributeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TeamRosterPersonRolloverYearlyAttributeMutation,
    TeamRosterPersonRolloverYearlyAttributeMutationVariables
  >(TeamRosterPersonRolloverYearlyAttributeDocument, options);
}
export type TeamRosterPersonRolloverYearlyAttributeMutationHookResult = ReturnType<
  typeof useTeamRosterPersonRolloverYearlyAttributeMutation
>;
export type TeamRosterPersonRolloverYearlyAttributeMutationResult =
  Apollo.MutationResult<TeamRosterPersonRolloverYearlyAttributeMutation>;
export type TeamRosterPersonRolloverYearlyAttributeMutationOptions = Apollo.BaseMutationOptions<
  TeamRosterPersonRolloverYearlyAttributeMutation,
  TeamRosterPersonRolloverYearlyAttributeMutationVariables
>;
export const TeamRostersDocument = gql`
  query TeamRosters($orgId: TWFilter_Int!) {
    teamRosters(orgId: $orgId) {
      edges {
        node {
          id
          teamRosterId
          tmpYear {
            label
          }
          team {
            teamId
            label
          }
        }
      }
    }
  }
`;

/**
 * __useTeamRostersQuery__
 *
 * To run a query within a React component, call `useTeamRostersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamRostersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamRostersQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useTeamRostersQuery(
  baseOptions: Apollo.QueryHookOptions<TeamRostersQuery, TeamRostersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamRostersQuery, TeamRostersQueryVariables>(TeamRostersDocument, options);
}
export function useTeamRostersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamRostersQuery, TeamRostersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamRostersQuery, TeamRostersQueryVariables>(
    TeamRostersDocument,
    options,
  );
}
export type TeamRostersQueryHookResult = ReturnType<typeof useTeamRostersQuery>;
export type TeamRostersLazyQueryHookResult = ReturnType<typeof useTeamRostersLazyQuery>;
export type TeamRostersQueryResult = Apollo.QueryResult<
  TeamRostersQuery,
  TeamRostersQueryVariables
>;
export function refetchTeamRostersQuery(variables: TeamRostersQueryVariables) {
  return { query: TeamRostersDocument, variables: variables };
}
export const YearlyAdvancedFiltersDocument = gql`
  query YearlyAdvancedFilters($teamId: TWFilter_Int, $athleteType: ID) {
    orgYearlyAttributeCategories(
      sort: [sort_order_asc, label_asc]
      includeInactiveRecords: false
      personTypesBeingViewed: [$athleteType]
    ) {
      edges {
        node {
          label
          yearlyAttributesActive {
            id
            label
            dataType
            optionsList
            isCustom
            coreCode
            rolloverType
          }
        }
      }
    }
    teamYearlyAttributeCategories(
      sort: [sort_order_asc, label_asc]
      includeInactiveRecords: false
      personTypesBeingViewed: [$athleteType]
      teamId: $teamId
    ) {
      edges {
        node {
          label
          yearlyAttributesActive {
            id
            label
            dataType
            optionsList
            isCustom
            coreCode
            rolloverType
          }
        }
      }
    }
  }
`;

/**
 * __useYearlyAdvancedFiltersQuery__
 *
 * To run a query within a React component, call `useYearlyAdvancedFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearlyAdvancedFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearlyAdvancedFiltersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      athleteType: // value for 'athleteType'
 *   },
 * });
 */
export function useYearlyAdvancedFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    YearlyAdvancedFiltersQuery,
    YearlyAdvancedFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<YearlyAdvancedFiltersQuery, YearlyAdvancedFiltersQueryVariables>(
    YearlyAdvancedFiltersDocument,
    options,
  );
}
export function useYearlyAdvancedFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    YearlyAdvancedFiltersQuery,
    YearlyAdvancedFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<YearlyAdvancedFiltersQuery, YearlyAdvancedFiltersQueryVariables>(
    YearlyAdvancedFiltersDocument,
    options,
  );
}
export type YearlyAdvancedFiltersQueryHookResult = ReturnType<typeof useYearlyAdvancedFiltersQuery>;
export type YearlyAdvancedFiltersLazyQueryHookResult = ReturnType<
  typeof useYearlyAdvancedFiltersLazyQuery
>;
export type YearlyAdvancedFiltersQueryResult = Apollo.QueryResult<
  YearlyAdvancedFiltersQuery,
  YearlyAdvancedFiltersQueryVariables
>;
export function refetchYearlyAdvancedFiltersQuery(variables?: YearlyAdvancedFiltersQueryVariables) {
  return { query: YearlyAdvancedFiltersDocument, variables: variables };
}
export const UniqueFileVideoNameListDocument = gql`
  query UniqueFileVideoNameList($nameFilter: String, $personId: TWFilter_Int) {
    uniqueFileVideoNameList(nameFilter: $nameFilter, createdBy: $personId) {
      edges {
        node {
          videoId
          video {
            title
          }
          sharedFileId
          sharedFile {
            fileName
          }
        }
      }
    }
  }
`;

/**
 * __useUniqueFileVideoNameListQuery__
 *
 * To run a query within a React component, call `useUniqueFileVideoNameListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueFileVideoNameListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueFileVideoNameListQuery({
 *   variables: {
 *      nameFilter: // value for 'nameFilter'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useUniqueFileVideoNameListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UniqueFileVideoNameListQuery,
    UniqueFileVideoNameListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UniqueFileVideoNameListQuery, UniqueFileVideoNameListQueryVariables>(
    UniqueFileVideoNameListDocument,
    options,
  );
}
export function useUniqueFileVideoNameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UniqueFileVideoNameListQuery,
    UniqueFileVideoNameListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UniqueFileVideoNameListQuery, UniqueFileVideoNameListQueryVariables>(
    UniqueFileVideoNameListDocument,
    options,
  );
}
export type UniqueFileVideoNameListQueryHookResult = ReturnType<
  typeof useUniqueFileVideoNameListQuery
>;
export type UniqueFileVideoNameListLazyQueryHookResult = ReturnType<
  typeof useUniqueFileVideoNameListLazyQuery
>;
export type UniqueFileVideoNameListQueryResult = Apollo.QueryResult<
  UniqueFileVideoNameListQuery,
  UniqueFileVideoNameListQueryVariables
>;
export function refetchUniqueFileVideoNameListQuery(
  variables?: UniqueFileVideoNameListQueryVariables,
) {
  return { query: UniqueFileVideoNameListDocument, variables: variables };
}
export const AllUserFileVideoActionsDocument = gql`
  query AllUserFileVideoActions(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $personId: TWFilter_Int
    $actionType: TWFilter_String
    $assetType: String
    $nameFilter: String
    $videoId: TWFilter_Int
    $sharedFileId: TWFilter_Int
    $actionDatetime: TWFilter_DateTime
    $sort: [UserFileVideoActionSortEnum]
  ) {
    history: allUserFileVideoActions(
      after: $after
      before: $before
      first: $first
      last: $last
      createdBy: $personId
      actionType: $actionType
      assetType: $assetType
      nameFilter: $nameFilter
      videoId: $videoId
      sharedFileId: $sharedFileId
      actionDatetime: $actionDatetime
      sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          pk
          actionType
          createdBy
          creator {
            personId
            preferredName
            lastName
            initials
            pictureUrl
          }
          teamId
          actionDatetime
          videoId
          sharedFileId
          video {
            title
            isPinned
          }
          sharedFile {
            fileName
            isPinned
          }
        }
      }
    }
  }
`;

/**
 * __useAllUserFileVideoActionsQuery__
 *
 * To run a query within a React component, call `useAllUserFileVideoActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserFileVideoActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserFileVideoActionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      personId: // value for 'personId'
 *      actionType: // value for 'actionType'
 *      assetType: // value for 'assetType'
 *      nameFilter: // value for 'nameFilter'
 *      videoId: // value for 'videoId'
 *      sharedFileId: // value for 'sharedFileId'
 *      actionDatetime: // value for 'actionDatetime'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAllUserFileVideoActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllUserFileVideoActionsQuery,
    AllUserFileVideoActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllUserFileVideoActionsQuery, AllUserFileVideoActionsQueryVariables>(
    AllUserFileVideoActionsDocument,
    options,
  );
}
export function useAllUserFileVideoActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserFileVideoActionsQuery,
    AllUserFileVideoActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllUserFileVideoActionsQuery, AllUserFileVideoActionsQueryVariables>(
    AllUserFileVideoActionsDocument,
    options,
  );
}
export type AllUserFileVideoActionsQueryHookResult = ReturnType<
  typeof useAllUserFileVideoActionsQuery
>;
export type AllUserFileVideoActionsLazyQueryHookResult = ReturnType<
  typeof useAllUserFileVideoActionsLazyQuery
>;
export type AllUserFileVideoActionsQueryResult = Apollo.QueryResult<
  AllUserFileVideoActionsQuery,
  AllUserFileVideoActionsQueryVariables
>;
export function refetchAllUserFileVideoActionsQuery(
  variables?: AllUserFileVideoActionsQueryVariables,
) {
  return { query: AllUserFileVideoActionsDocument, variables: variables };
}
export const BulkDeactivateCustomTaskDocument = gql`
  mutation BulkDeactivateCustomTask($input: CustomTaskBulkDeactivateInput!) {
    customTaskBulkDeactivate(input: $input) {
      customTask {
        name
        description
        dueDate
        status
        alertNotificationType
        customTaskType
        visibility
        isActive
        updatedBy
      }
    }
  }
`;
export type BulkDeactivateCustomTaskMutationFn = Apollo.MutationFunction<
  BulkDeactivateCustomTaskMutation,
  BulkDeactivateCustomTaskMutationVariables
>;

/**
 * __useBulkDeactivateCustomTaskMutation__
 *
 * To run a mutation, you first call `useBulkDeactivateCustomTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeactivateCustomTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeactivateCustomTaskMutation, { data, loading, error }] = useBulkDeactivateCustomTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkDeactivateCustomTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkDeactivateCustomTaskMutation,
    BulkDeactivateCustomTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkDeactivateCustomTaskMutation,
    BulkDeactivateCustomTaskMutationVariables
  >(BulkDeactivateCustomTaskDocument, options);
}
export type BulkDeactivateCustomTaskMutationHookResult = ReturnType<
  typeof useBulkDeactivateCustomTaskMutation
>;
export type BulkDeactivateCustomTaskMutationResult =
  Apollo.MutationResult<BulkDeactivateCustomTaskMutation>;
export type BulkDeactivateCustomTaskMutationOptions = Apollo.BaseMutationOptions<
  BulkDeactivateCustomTaskMutation,
  BulkDeactivateCustomTaskMutationVariables
>;
export const CreateCustomTaskDocument = gql`
  mutation CreateCustomTask($input: CustomTaskCreateInput!) {
    customTaskCreate(input: $input) {
      customTask {
        name
        description
        dueDate
        status
        alertNotificationType
        customTaskType
        visibility
      }
    }
  }
`;
export type CreateCustomTaskMutationFn = Apollo.MutationFunction<
  CreateCustomTaskMutation,
  CreateCustomTaskMutationVariables
>;

/**
 * __useCreateCustomTaskMutation__
 *
 * To run a mutation, you first call `useCreateCustomTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomTaskMutation, { data, loading, error }] = useCreateCustomTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomTaskMutation,
    CreateCustomTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCustomTaskMutation, CreateCustomTaskMutationVariables>(
    CreateCustomTaskDocument,
    options,
  );
}
export type CreateCustomTaskMutationHookResult = ReturnType<typeof useCreateCustomTaskMutation>;
export type CreateCustomTaskMutationResult = Apollo.MutationResult<CreateCustomTaskMutation>;
export type CreateCustomTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomTaskMutation,
  CreateCustomTaskMutationVariables
>;
export const CustomTaskAssignmentCompleteDocument = gql`
  mutation CustomTaskAssignmentComplete($id: ID!) {
    customTaskAssignmentComplete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type CustomTaskAssignmentCompleteMutationFn = Apollo.MutationFunction<
  CustomTaskAssignmentCompleteMutation,
  CustomTaskAssignmentCompleteMutationVariables
>;

/**
 * __useCustomTaskAssignmentCompleteMutation__
 *
 * To run a mutation, you first call `useCustomTaskAssignmentCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomTaskAssignmentCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customTaskAssignmentCompleteMutation, { data, loading, error }] = useCustomTaskAssignmentCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomTaskAssignmentCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomTaskAssignmentCompleteMutation,
    CustomTaskAssignmentCompleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CustomTaskAssignmentCompleteMutation,
    CustomTaskAssignmentCompleteMutationVariables
  >(CustomTaskAssignmentCompleteDocument, options);
}
export type CustomTaskAssignmentCompleteMutationHookResult = ReturnType<
  typeof useCustomTaskAssignmentCompleteMutation
>;
export type CustomTaskAssignmentCompleteMutationResult =
  Apollo.MutationResult<CustomTaskAssignmentCompleteMutation>;
export type CustomTaskAssignmentCompleteMutationOptions = Apollo.BaseMutationOptions<
  CustomTaskAssignmentCompleteMutation,
  CustomTaskAssignmentCompleteMutationVariables
>;
export const CustomTaskAssignmentsBulkActionsDocument = gql`
  mutation CustomTaskAssignmentsBulkActions($input: CustomTaskAssignmentBulkActionsInput!) {
    customTaskAssignmentBulkActions(input: $input) {
      customTaskAssignment {
        id
      }
    }
  }
`;
export type CustomTaskAssignmentsBulkActionsMutationFn = Apollo.MutationFunction<
  CustomTaskAssignmentsBulkActionsMutation,
  CustomTaskAssignmentsBulkActionsMutationVariables
>;

/**
 * __useCustomTaskAssignmentsBulkActionsMutation__
 *
 * To run a mutation, you first call `useCustomTaskAssignmentsBulkActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomTaskAssignmentsBulkActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customTaskAssignmentsBulkActionsMutation, { data, loading, error }] = useCustomTaskAssignmentsBulkActionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomTaskAssignmentsBulkActionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomTaskAssignmentsBulkActionsMutation,
    CustomTaskAssignmentsBulkActionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CustomTaskAssignmentsBulkActionsMutation,
    CustomTaskAssignmentsBulkActionsMutationVariables
  >(CustomTaskAssignmentsBulkActionsDocument, options);
}
export type CustomTaskAssignmentsBulkActionsMutationHookResult = ReturnType<
  typeof useCustomTaskAssignmentsBulkActionsMutation
>;
export type CustomTaskAssignmentsBulkActionsMutationResult =
  Apollo.MutationResult<CustomTaskAssignmentsBulkActionsMutation>;
export type CustomTaskAssignmentsBulkActionsMutationOptions = Apollo.BaseMutationOptions<
  CustomTaskAssignmentsBulkActionsMutation,
  CustomTaskAssignmentsBulkActionsMutationVariables
>;
export const CustomTaskAssignmentsExportCreateDocument = gql`
  mutation CustomTaskAssignmentsExportCreate($input: CustomTaskAssignmentsExportInput!) {
    customTaskAssignmentsExportCreate(input: $input) {
      customTaskAssignmentsExport {
        createdDate
        downloadableFileId
        status
        title
        url
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CustomTaskAssignmentsExportCreateMutationFn = Apollo.MutationFunction<
  CustomTaskAssignmentsExportCreateMutation,
  CustomTaskAssignmentsExportCreateMutationVariables
>;

/**
 * __useCustomTaskAssignmentsExportCreateMutation__
 *
 * To run a mutation, you first call `useCustomTaskAssignmentsExportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomTaskAssignmentsExportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customTaskAssignmentsExportCreateMutation, { data, loading, error }] = useCustomTaskAssignmentsExportCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomTaskAssignmentsExportCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomTaskAssignmentsExportCreateMutation,
    CustomTaskAssignmentsExportCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CustomTaskAssignmentsExportCreateMutation,
    CustomTaskAssignmentsExportCreateMutationVariables
  >(CustomTaskAssignmentsExportCreateDocument, options);
}
export type CustomTaskAssignmentsExportCreateMutationHookResult = ReturnType<
  typeof useCustomTaskAssignmentsExportCreateMutation
>;
export type CustomTaskAssignmentsExportCreateMutationResult =
  Apollo.MutationResult<CustomTaskAssignmentsExportCreateMutation>;
export type CustomTaskAssignmentsExportCreateMutationOptions = Apollo.BaseMutationOptions<
  CustomTaskAssignmentsExportCreateMutation,
  CustomTaskAssignmentsExportCreateMutationVariables
>;
export const CustomTaskAssignmentsExportDocument = gql`
  query CustomTaskAssignmentsExport {
    customTaskAssignmentsExport {
      edges {
        node {
          createdDate
          downloadableFileId
          errorMessage
          kind
          status
          title
          url
        }
      }
    }
  }
`;

/**
 * __useCustomTaskAssignmentsExportQuery__
 *
 * To run a query within a React component, call `useCustomTaskAssignmentsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomTaskAssignmentsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomTaskAssignmentsExportQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomTaskAssignmentsExportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomTaskAssignmentsExportQuery,
    CustomTaskAssignmentsExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomTaskAssignmentsExportQuery,
    CustomTaskAssignmentsExportQueryVariables
  >(CustomTaskAssignmentsExportDocument, options);
}
export function useCustomTaskAssignmentsExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomTaskAssignmentsExportQuery,
    CustomTaskAssignmentsExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomTaskAssignmentsExportQuery,
    CustomTaskAssignmentsExportQueryVariables
  >(CustomTaskAssignmentsExportDocument, options);
}
export type CustomTaskAssignmentsExportQueryHookResult = ReturnType<
  typeof useCustomTaskAssignmentsExportQuery
>;
export type CustomTaskAssignmentsExportLazyQueryHookResult = ReturnType<
  typeof useCustomTaskAssignmentsExportLazyQuery
>;
export type CustomTaskAssignmentsExportQueryResult = Apollo.QueryResult<
  CustomTaskAssignmentsExportQuery,
  CustomTaskAssignmentsExportQueryVariables
>;
export function refetchCustomTaskAssignmentsExportQuery(
  variables?: CustomTaskAssignmentsExportQueryVariables,
) {
  return { query: CustomTaskAssignmentsExportDocument, variables: variables };
}
export const CustomTaskLabelCreateDocument = gql`
  mutation CustomTaskLabelCreate($input: CustomTaskLabelInput) {
    customTaskLabelCreate(input: $input) {
      userErrors {
        message
        field
      }
      customTaskLabel {
        label
        pk
      }
    }
  }
`;
export type CustomTaskLabelCreateMutationFn = Apollo.MutationFunction<
  CustomTaskLabelCreateMutation,
  CustomTaskLabelCreateMutationVariables
>;

/**
 * __useCustomTaskLabelCreateMutation__
 *
 * To run a mutation, you first call `useCustomTaskLabelCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomTaskLabelCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customTaskLabelCreateMutation, { data, loading, error }] = useCustomTaskLabelCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomTaskLabelCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomTaskLabelCreateMutation,
    CustomTaskLabelCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomTaskLabelCreateMutation, CustomTaskLabelCreateMutationVariables>(
    CustomTaskLabelCreateDocument,
    options,
  );
}
export type CustomTaskLabelCreateMutationHookResult = ReturnType<
  typeof useCustomTaskLabelCreateMutation
>;
export type CustomTaskLabelCreateMutationResult =
  Apollo.MutationResult<CustomTaskLabelCreateMutation>;
export type CustomTaskLabelCreateMutationOptions = Apollo.BaseMutationOptions<
  CustomTaskLabelCreateMutation,
  CustomTaskLabelCreateMutationVariables
>;
export const CustomTaskLabelDeleteDocument = gql`
  mutation CustomTaskLabelDelete($id: ID!) {
    customTaskLabelDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type CustomTaskLabelDeleteMutationFn = Apollo.MutationFunction<
  CustomTaskLabelDeleteMutation,
  CustomTaskLabelDeleteMutationVariables
>;

/**
 * __useCustomTaskLabelDeleteMutation__
 *
 * To run a mutation, you first call `useCustomTaskLabelDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomTaskLabelDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customTaskLabelDeleteMutation, { data, loading, error }] = useCustomTaskLabelDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomTaskLabelDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomTaskLabelDeleteMutation,
    CustomTaskLabelDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomTaskLabelDeleteMutation, CustomTaskLabelDeleteMutationVariables>(
    CustomTaskLabelDeleteDocument,
    options,
  );
}
export type CustomTaskLabelDeleteMutationHookResult = ReturnType<
  typeof useCustomTaskLabelDeleteMutation
>;
export type CustomTaskLabelDeleteMutationResult =
  Apollo.MutationResult<CustomTaskLabelDeleteMutation>;
export type CustomTaskLabelDeleteMutationOptions = Apollo.BaseMutationOptions<
  CustomTaskLabelDeleteMutation,
  CustomTaskLabelDeleteMutationVariables
>;
export const CustomTaskLabelUpdateDocument = gql`
  mutation CustomTaskLabelUpdate($id: ID!, $input: CustomTaskLabelInput) {
    customTaskLabelUpdate(id: $id, input: $input) {
      userErrors {
        message
        field
      }
      customTaskLabel {
        label
      }
    }
  }
`;
export type CustomTaskLabelUpdateMutationFn = Apollo.MutationFunction<
  CustomTaskLabelUpdateMutation,
  CustomTaskLabelUpdateMutationVariables
>;

/**
 * __useCustomTaskLabelUpdateMutation__
 *
 * To run a mutation, you first call `useCustomTaskLabelUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomTaskLabelUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customTaskLabelUpdateMutation, { data, loading, error }] = useCustomTaskLabelUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomTaskLabelUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomTaskLabelUpdateMutation,
    CustomTaskLabelUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomTaskLabelUpdateMutation, CustomTaskLabelUpdateMutationVariables>(
    CustomTaskLabelUpdateDocument,
    options,
  );
}
export type CustomTaskLabelUpdateMutationHookResult = ReturnType<
  typeof useCustomTaskLabelUpdateMutation
>;
export type CustomTaskLabelUpdateMutationResult =
  Apollo.MutationResult<CustomTaskLabelUpdateMutation>;
export type CustomTaskLabelUpdateMutationOptions = Apollo.BaseMutationOptions<
  CustomTaskLabelUpdateMutation,
  CustomTaskLabelUpdateMutationVariables
>;
export const CustomTaskLabelsDocument = gql`
  query CustomTaskLabels($sort: [CustomTaskLabelSortEnum]) {
    customTaskLabels(sort: $sort) {
      totalCount
      edges {
        node {
          label
          pk
          id
          associatedTaskCount
        }
      }
    }
  }
`;

/**
 * __useCustomTaskLabelsQuery__
 *
 * To run a query within a React component, call `useCustomTaskLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomTaskLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomTaskLabelsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCustomTaskLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomTaskLabelsQuery, CustomTaskLabelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomTaskLabelsQuery, CustomTaskLabelsQueryVariables>(
    CustomTaskLabelsDocument,
    options,
  );
}
export function useCustomTaskLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomTaskLabelsQuery, CustomTaskLabelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomTaskLabelsQuery, CustomTaskLabelsQueryVariables>(
    CustomTaskLabelsDocument,
    options,
  );
}
export type CustomTaskLabelsQueryHookResult = ReturnType<typeof useCustomTaskLabelsQuery>;
export type CustomTaskLabelsLazyQueryHookResult = ReturnType<typeof useCustomTaskLabelsLazyQuery>;
export type CustomTaskLabelsQueryResult = Apollo.QueryResult<
  CustomTaskLabelsQuery,
  CustomTaskLabelsQueryVariables
>;
export function refetchCustomTaskLabelsQuery(variables?: CustomTaskLabelsQueryVariables) {
  return { query: CustomTaskLabelsDocument, variables: variables };
}
export const FetchAllCustomTasksDocument = gql`
  query FetchAllCustomTasks(
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $status: TWFilter_CustomTaskStatus
    $createdBy: TWFilter_Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [CustomTaskSortEnum]
    $labelIds: [String]
  ) {
    allCustomTasks(
      search: $search
      startDate: $startDate
      endDate: $endDate
      status: $status
      createdBy: $createdBy
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
      labelIds: $labelIds
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          name
          description
          dueDate
          createdDate
          updatedDate
          id
          status
          labels {
            label
            pk
          }
          creator {
            displayName
            pictureUrl
          }
        }
      }
    }
  }
`;

/**
 * __useFetchAllCustomTasksQuery__
 *
 * To run a query within a React component, call `useFetchAllCustomTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllCustomTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllCustomTasksQuery({
 *   variables: {
 *      search: // value for 'search'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *      createdBy: // value for 'createdBy'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *      labelIds: // value for 'labelIds'
 *   },
 * });
 */
export function useFetchAllCustomTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllCustomTasksQuery,
    FetchAllCustomTasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllCustomTasksQuery, FetchAllCustomTasksQueryVariables>(
    FetchAllCustomTasksDocument,
    options,
  );
}
export function useFetchAllCustomTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllCustomTasksQuery,
    FetchAllCustomTasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllCustomTasksQuery, FetchAllCustomTasksQueryVariables>(
    FetchAllCustomTasksDocument,
    options,
  );
}
export type FetchAllCustomTasksQueryHookResult = ReturnType<typeof useFetchAllCustomTasksQuery>;
export type FetchAllCustomTasksLazyQueryHookResult = ReturnType<
  typeof useFetchAllCustomTasksLazyQuery
>;
export type FetchAllCustomTasksQueryResult = Apollo.QueryResult<
  FetchAllCustomTasksQuery,
  FetchAllCustomTasksQueryVariables
>;
export function refetchFetchAllCustomTasksQuery(variables?: FetchAllCustomTasksQueryVariables) {
  return { query: FetchAllCustomTasksDocument, variables: variables };
}
export const FetchVisibleCustomTasksDocument = gql`
  query FetchVisibleCustomTasks(
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $status: TWFilter_CustomTaskStatus
    $createdBy: TWFilter_Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [CustomTaskSortEnum]
    $labelIds: [String]
  ) {
    myCustomTasks(
      search: $search
      startDate: $startDate
      endDate: $endDate
      status: $status
      createdBy: $createdBy
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
      labelIds: $labelIds
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          name
          description
          dueDate
          createdDate
          updatedDate
          id
          status
          labels {
            label
            pk
          }
          creator {
            displayName
            pictureUrl
          }
        }
      }
    }
  }
`;

/**
 * __useFetchVisibleCustomTasksQuery__
 *
 * To run a query within a React component, call `useFetchVisibleCustomTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchVisibleCustomTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchVisibleCustomTasksQuery({
 *   variables: {
 *      search: // value for 'search'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *      createdBy: // value for 'createdBy'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *      labelIds: // value for 'labelIds'
 *   },
 * });
 */
export function useFetchVisibleCustomTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchVisibleCustomTasksQuery,
    FetchVisibleCustomTasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchVisibleCustomTasksQuery, FetchVisibleCustomTasksQueryVariables>(
    FetchVisibleCustomTasksDocument,
    options,
  );
}
export function useFetchVisibleCustomTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchVisibleCustomTasksQuery,
    FetchVisibleCustomTasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchVisibleCustomTasksQuery, FetchVisibleCustomTasksQueryVariables>(
    FetchVisibleCustomTasksDocument,
    options,
  );
}
export type FetchVisibleCustomTasksQueryHookResult = ReturnType<
  typeof useFetchVisibleCustomTasksQuery
>;
export type FetchVisibleCustomTasksLazyQueryHookResult = ReturnType<
  typeof useFetchVisibleCustomTasksLazyQuery
>;
export type FetchVisibleCustomTasksQueryResult = Apollo.QueryResult<
  FetchVisibleCustomTasksQuery,
  FetchVisibleCustomTasksQueryVariables
>;
export function refetchFetchVisibleCustomTasksQuery(
  variables?: FetchVisibleCustomTasksQueryVariables,
) {
  return { query: FetchVisibleCustomTasksDocument, variables: variables };
}
export const GetCustomTaskDocument = gql`
  query GetCustomTask(
    $id: ID!
    $status: TWFilter_CustomTaskAssignmentStatus
    $searchName: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [CustomTaskAssignmentSortEnum]
  ) {
    customTask: node(id: $id) {
      ... on CustomTaskNode {
        alertNotificationType
        id
        name
        description
        dueDate
        createdDate
        status
        labels {
          label
          pk
          id
        }
        creator {
          displayName
          fullNameNormalOrder
          pictureUrl
        }
        updatedDate
        visibility
        assignmentCountByStatus {
          status
          count
        }
        assignments(
          status: $status
          searchName: $searchName
          after: $after
          before: $before
          first: $first
          last: $last
          sort: $sort
        ) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdDate
              customTaskAssignmentId
              completedDate
              manuallyCompletedDate
              manuallyCompletedNote
              assignee {
                fullName
                selectionCode
                userTypesByTeam
                pk
                initials
                pictureUrl
                displayName
              }
              status
              updatedDate
              updatedBy
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCustomTaskQuery__
 *
 * To run a query within a React component, call `useGetCustomTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      searchName: // value for 'searchName'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCustomTaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomTaskQuery, GetCustomTaskQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomTaskQuery, GetCustomTaskQueryVariables>(
    GetCustomTaskDocument,
    options,
  );
}
export function useGetCustomTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomTaskQuery, GetCustomTaskQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomTaskQuery, GetCustomTaskQueryVariables>(
    GetCustomTaskDocument,
    options,
  );
}
export type GetCustomTaskQueryHookResult = ReturnType<typeof useGetCustomTaskQuery>;
export type GetCustomTaskLazyQueryHookResult = ReturnType<typeof useGetCustomTaskLazyQuery>;
export type GetCustomTaskQueryResult = Apollo.QueryResult<
  GetCustomTaskQuery,
  GetCustomTaskQueryVariables
>;
export function refetchGetCustomTaskQuery(variables: GetCustomTaskQueryVariables) {
  return { query: GetCustomTaskDocument, variables: variables };
}
export const GetCustomTaskAssignersDocument = gql`
  query GetCustomTaskAssigners {
    customTaskAssigners {
      assignerPrettyValue
      assignerRawValues
    }
  }
`;

/**
 * __useGetCustomTaskAssignersQuery__
 *
 * To run a query within a React component, call `useGetCustomTaskAssignersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTaskAssignersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTaskAssignersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomTaskAssignersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomTaskAssignersQuery,
    GetCustomTaskAssignersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomTaskAssignersQuery, GetCustomTaskAssignersQueryVariables>(
    GetCustomTaskAssignersDocument,
    options,
  );
}
export function useGetCustomTaskAssignersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomTaskAssignersQuery,
    GetCustomTaskAssignersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomTaskAssignersQuery, GetCustomTaskAssignersQueryVariables>(
    GetCustomTaskAssignersDocument,
    options,
  );
}
export type GetCustomTaskAssignersQueryHookResult = ReturnType<
  typeof useGetCustomTaskAssignersQuery
>;
export type GetCustomTaskAssignersLazyQueryHookResult = ReturnType<
  typeof useGetCustomTaskAssignersLazyQuery
>;
export type GetCustomTaskAssignersQueryResult = Apollo.QueryResult<
  GetCustomTaskAssignersQuery,
  GetCustomTaskAssignersQueryVariables
>;
export function refetchGetCustomTaskAssignersQuery(
  variables?: GetCustomTaskAssignersQueryVariables,
) {
  return { query: GetCustomTaskAssignersDocument, variables: variables };
}
export const GetCustomTaskCompletionsDocument = gql`
  query GetCustomTaskCompletions {
    customTaskCompletions {
      customTaskName
      customTaskDescription
      customTaskId
      status
      assignerPrettyValue
      assignedDate
      dueDate
      completedDate
      manuallyCompletedDate
      labels
    }
  }
`;

/**
 * __useGetCustomTaskCompletionsQuery__
 *
 * To run a query within a React component, call `useGetCustomTaskCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTaskCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTaskCompletionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomTaskCompletionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomTaskCompletionsQuery,
    GetCustomTaskCompletionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomTaskCompletionsQuery, GetCustomTaskCompletionsQueryVariables>(
    GetCustomTaskCompletionsDocument,
    options,
  );
}
export function useGetCustomTaskCompletionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomTaskCompletionsQuery,
    GetCustomTaskCompletionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomTaskCompletionsQuery, GetCustomTaskCompletionsQueryVariables>(
    GetCustomTaskCompletionsDocument,
    options,
  );
}
export type GetCustomTaskCompletionsQueryHookResult = ReturnType<
  typeof useGetCustomTaskCompletionsQuery
>;
export type GetCustomTaskCompletionsLazyQueryHookResult = ReturnType<
  typeof useGetCustomTaskCompletionsLazyQuery
>;
export type GetCustomTaskCompletionsQueryResult = Apollo.QueryResult<
  GetCustomTaskCompletionsQuery,
  GetCustomTaskCompletionsQueryVariables
>;
export function refetchGetCustomTaskCompletionsQuery(
  variables?: GetCustomTaskCompletionsQueryVariables,
) {
  return { query: GetCustomTaskCompletionsDocument, variables: variables };
}
export const GetCustomTasksCountDocument = gql`
  query GetCustomTasksCount($canManageTasks: Boolean!) {
    myCustomTasks @skip(if: $canManageTasks) {
      totalCount
    }
    allCustomTasks @include(if: $canManageTasks) {
      totalCount
    }
  }
`;

/**
 * __useGetCustomTasksCountQuery__
 *
 * To run a query within a React component, call `useGetCustomTasksCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTasksCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTasksCountQuery({
 *   variables: {
 *      canManageTasks: // value for 'canManageTasks'
 *   },
 * });
 */
export function useGetCustomTasksCountQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomTasksCountQuery, GetCustomTasksCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomTasksCountQuery, GetCustomTasksCountQueryVariables>(
    GetCustomTasksCountDocument,
    options,
  );
}
export function useGetCustomTasksCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomTasksCountQuery,
    GetCustomTasksCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomTasksCountQuery, GetCustomTasksCountQueryVariables>(
    GetCustomTasksCountDocument,
    options,
  );
}
export type GetCustomTasksCountQueryHookResult = ReturnType<typeof useGetCustomTasksCountQuery>;
export type GetCustomTasksCountLazyQueryHookResult = ReturnType<
  typeof useGetCustomTasksCountLazyQuery
>;
export type GetCustomTasksCountQueryResult = Apollo.QueryResult<
  GetCustomTasksCountQuery,
  GetCustomTasksCountQueryVariables
>;
export function refetchGetCustomTasksCountQuery(variables: GetCustomTasksCountQueryVariables) {
  return { query: GetCustomTasksCountDocument, variables: variables };
}
export const GetTasksDocument = gql`
  query GetTasks(
    $after: String
    $assigners: [String]
    $before: String
    $dashboardEligible: Boolean
    $endDate: DateTime
    $first: Int
    $hasArms: Boolean
    $includeAcademicTasks: Boolean
    $isAcademicTask: Boolean
    $ownerApp: [TWOwnerAppFilterEnum]
    $search: String
    $sort: [MyTaskSortEnum]
    $startDate: DateTime
    $statuses: [TaskStatusFilterEnum]
    $withStatus: Boolean = false
  ) {
    myTasks(
      after: $after
      assigners: $assigners
      before: $before
      dashboardEligible: $dashboardEligible
      endDate: $endDate
      first: $first
      hasArms: $hasArms
      includeAcademicTasks: $includeAcademicTasks
      isAcademicTask: $isAcademicTask
      ownerApp: $ownerApp
      search: $search
      sort: $sort
      startDate: $startDate
      statuses: $statuses
      withStatus: $withStatus
    ) {
      allAssigners {
        assignerPrettyValue
        assignerRawValues
      }
      allOwnerApps {
        ownerApp
        taskCount
      }
      edges {
        node {
          assigner
          assignerPrettyValue
          createdAt
          description
          dueDate
          expirationDate
          ownerApp
          ownerId
          ownerType
          showDueDate
          taskUrl
          taskUrlWeb
          title
          extra {
            category
            course
          }
          status @include(if: $withStatus)
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      after: // value for 'after'
 *      assigners: // value for 'assigners'
 *      before: // value for 'before'
 *      dashboardEligible: // value for 'dashboardEligible'
 *      endDate: // value for 'endDate'
 *      first: // value for 'first'
 *      hasArms: // value for 'hasArms'
 *      includeAcademicTasks: // value for 'includeAcademicTasks'
 *      isAcademicTask: // value for 'isAcademicTask'
 *      ownerApp: // value for 'ownerApp'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      startDate: // value for 'startDate'
 *      statuses: // value for 'statuses'
 *      withStatus: // value for 'withStatus'
 *   },
 * });
 */
export function useGetTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
}
export function useGetTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
}
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
export function refetchGetTasksQuery(variables?: GetTasksQueryVariables) {
  return { query: GetTasksDocument, variables: variables };
}
export const GetMyTaskAssignersDocument = gql`
  query GetMyTaskAssigners($hasArms: Boolean) {
    myTaskAssigners(hasArms: $hasArms) {
      assignerPrettyValue
      assignerRawValues
    }
  }
`;

/**
 * __useGetMyTaskAssignersQuery__
 *
 * To run a query within a React component, call `useGetMyTaskAssignersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTaskAssignersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTaskAssignersQuery({
 *   variables: {
 *      hasArms: // value for 'hasArms'
 *   },
 * });
 */
export function useGetMyTaskAssignersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyTaskAssignersQuery, GetMyTaskAssignersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyTaskAssignersQuery, GetMyTaskAssignersQueryVariables>(
    GetMyTaskAssignersDocument,
    options,
  );
}
export function useGetMyTaskAssignersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyTaskAssignersQuery,
    GetMyTaskAssignersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyTaskAssignersQuery, GetMyTaskAssignersQueryVariables>(
    GetMyTaskAssignersDocument,
    options,
  );
}
export type GetMyTaskAssignersQueryHookResult = ReturnType<typeof useGetMyTaskAssignersQuery>;
export type GetMyTaskAssignersLazyQueryHookResult = ReturnType<
  typeof useGetMyTaskAssignersLazyQuery
>;
export type GetMyTaskAssignersQueryResult = Apollo.QueryResult<
  GetMyTaskAssignersQuery,
  GetMyTaskAssignersQueryVariables
>;
export function refetchGetMyTaskAssignersQuery(variables?: GetMyTaskAssignersQueryVariables) {
  return { query: GetMyTaskAssignersDocument, variables: variables };
}
export const GetTasksCountDocument = gql`
  query GetTasksCount($hasArms: Boolean, $includeAcademicTasks: Boolean) {
    myTasks(hasArms: $hasArms, includeAcademicTasks: $includeAcademicTasks) {
      totalCount
    }
  }
`;

/**
 * __useGetTasksCountQuery__
 *
 * To run a query within a React component, call `useGetTasksCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksCountQuery({
 *   variables: {
 *      hasArms: // value for 'hasArms'
 *      includeAcademicTasks: // value for 'includeAcademicTasks'
 *   },
 * });
 */
export function useGetTasksCountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTasksCountQuery, GetTasksCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTasksCountQuery, GetTasksCountQueryVariables>(
    GetTasksCountDocument,
    options,
  );
}
export function useGetTasksCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTasksCountQuery, GetTasksCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTasksCountQuery, GetTasksCountQueryVariables>(
    GetTasksCountDocument,
    options,
  );
}
export type GetTasksCountQueryHookResult = ReturnType<typeof useGetTasksCountQuery>;
export type GetTasksCountLazyQueryHookResult = ReturnType<typeof useGetTasksCountLazyQuery>;
export type GetTasksCountQueryResult = Apollo.QueryResult<
  GetTasksCountQuery,
  GetTasksCountQueryVariables
>;
export function refetchGetTasksCountQuery(variables?: GetTasksCountQueryVariables) {
  return { query: GetTasksCountDocument, variables: variables };
}
export const UpdateCustomTaskDocument = gql`
  mutation UpdateCustomTask($id: ID!, $input: CustomTaskUpdateInput!) {
    customTaskUpdate(id: $id, input: $input) {
      customTask {
        name
        description
        dueDate
        status
        alertNotificationType
        customTaskType
      }
    }
  }
`;
export type UpdateCustomTaskMutationFn = Apollo.MutationFunction<
  UpdateCustomTaskMutation,
  UpdateCustomTaskMutationVariables
>;

/**
 * __useUpdateCustomTaskMutation__
 *
 * To run a mutation, you first call `useUpdateCustomTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomTaskMutation, { data, loading, error }] = useUpdateCustomTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomTaskMutation,
    UpdateCustomTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomTaskMutation, UpdateCustomTaskMutationVariables>(
    UpdateCustomTaskDocument,
    options,
  );
}
export type UpdateCustomTaskMutationHookResult = ReturnType<typeof useUpdateCustomTaskMutation>;
export type UpdateCustomTaskMutationResult = Apollo.MutationResult<UpdateCustomTaskMutation>;
export type UpdateCustomTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomTaskMutation,
  UpdateCustomTaskMutationVariables
>;
export const FetchCaraLogDatesDocument = gql`
  query FetchCARALogDates(
    $startDateArg: Date!
    $endDateArg: Date!
    $logConfigIdArg: Int!
    $teamId: TWFilter_Int
    $first: Int
  ) {
    tmpActivityLogDatesList(
      startDateArg: $startDateArg
      endDateArg: $endDateArg
      logConfigIdArg: $logConfigIdArg
      teamId: $teamId
      first: $first
    ) {
      totalCount
      edges {
        node {
          pk
          tmpYearId
          isUserLogAdmin
          isUserComplianceUser
          isUserViewer
          logDates {
            logStartDate
            logEndDate
            existingLogId
          }
          logs {
            submittedDate
            submittedNames {
              submittedName
              personId
            }
            isDraft
            tmpActivityLogId
            logStartDate
            logEndDate
            pendingStageSeq
            config {
              team {
                label
              }
            }
            responses {
              notes
              approval
              person {
                firstName
                lastName
                personId
              }
              stage {
                label
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchCaraLogDatesQuery__
 *
 * To run a query within a React component, call `useFetchCaraLogDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCaraLogDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCaraLogDatesQuery({
 *   variables: {
 *      startDateArg: // value for 'startDateArg'
 *      endDateArg: // value for 'endDateArg'
 *      logConfigIdArg: // value for 'logConfigIdArg'
 *      teamId: // value for 'teamId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFetchCaraLogDatesQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCaraLogDatesQuery, FetchCaraLogDatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCaraLogDatesQuery, FetchCaraLogDatesQueryVariables>(
    FetchCaraLogDatesDocument,
    options,
  );
}
export function useFetchCaraLogDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCaraLogDatesQuery,
    FetchCaraLogDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCaraLogDatesQuery, FetchCaraLogDatesQueryVariables>(
    FetchCaraLogDatesDocument,
    options,
  );
}
export type FetchCaraLogDatesQueryHookResult = ReturnType<typeof useFetchCaraLogDatesQuery>;
export type FetchCaraLogDatesLazyQueryHookResult = ReturnType<typeof useFetchCaraLogDatesLazyQuery>;
export type FetchCaraLogDatesQueryResult = Apollo.QueryResult<
  FetchCaraLogDatesQuery,
  FetchCaraLogDatesQueryVariables
>;
export function refetchFetchCaraLogDatesQuery(variables: FetchCaraLogDatesQueryVariables) {
  return { query: FetchCaraLogDatesDocument, variables: variables };
}
export const FetchCaraLogListDocument = gql`
  query FetchCARALogList(
    $tmpTeamIdsArg: [Int]
    $tmpYearIdArg: Int!
    $startDateArg: String
    $endDateArg: String
    $logPendingStageSeqsArg: [Int]
    $isDraftArg: Boolean
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    tmpLogList(
      tmpTeamIdsArg: $tmpTeamIdsArg
      tmpYearIdArg: $tmpYearIdArg
      startDateArg: $startDateArg
      endDateArg: $endDateArg
      logPendingStageSeqsArg: $logPendingStageSeqsArg
      isDraftArg: $isDraftArg
      first: $first
      last: $last
      before: $before
      after: $after
      sort: log_start_date_asc
    ) {
      totalCount
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          isUserLogAdmin
          isUserComplianceUser
          isUserViewer
          submittedDate
          submittedNames {
            submittedName
            personId
          }
          isDraft
          tmpActivityLogId
          logStartDate
          logEndDate
          pendingStageSeq
          config {
            team {
              label
            }
          }
          responses {
            notes
            approval
            person {
              personId
              firstName
              lastName
            }
            stage {
              label
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchCaraLogListQuery__
 *
 * To run a query within a React component, call `useFetchCaraLogListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCaraLogListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCaraLogListQuery({
 *   variables: {
 *      tmpTeamIdsArg: // value for 'tmpTeamIdsArg'
 *      tmpYearIdArg: // value for 'tmpYearIdArg'
 *      startDateArg: // value for 'startDateArg'
 *      endDateArg: // value for 'endDateArg'
 *      logPendingStageSeqsArg: // value for 'logPendingStageSeqsArg'
 *      isDraftArg: // value for 'isDraftArg'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchCaraLogListQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCaraLogListQuery, FetchCaraLogListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCaraLogListQuery, FetchCaraLogListQueryVariables>(
    FetchCaraLogListDocument,
    options,
  );
}
export function useFetchCaraLogListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCaraLogListQuery, FetchCaraLogListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCaraLogListQuery, FetchCaraLogListQueryVariables>(
    FetchCaraLogListDocument,
    options,
  );
}
export type FetchCaraLogListQueryHookResult = ReturnType<typeof useFetchCaraLogListQuery>;
export type FetchCaraLogListLazyQueryHookResult = ReturnType<typeof useFetchCaraLogListLazyQuery>;
export type FetchCaraLogListQueryResult = Apollo.QueryResult<
  FetchCaraLogListQuery,
  FetchCaraLogListQueryVariables
>;
export function refetchFetchCaraLogListQuery(variables: FetchCaraLogListQueryVariables) {
  return { query: FetchCaraLogListDocument, variables: variables };
}
export const FetchCaraLogListIdsDocument = gql`
  query FetchCARALogListIds(
    $tmpTeamIdsArg: [Int]
    $tmpYearIdArg: Int!
    $startDateArg: String
    $endDateArg: String
    $logPendingStageSeqsArg: [Int]
    $isDraftArg: Boolean
  ) {
    tmpLogList(
      tmpTeamIdsArg: $tmpTeamIdsArg
      tmpYearIdArg: $tmpYearIdArg
      startDateArg: $startDateArg
      endDateArg: $endDateArg
      logPendingStageSeqsArg: $logPendingStageSeqsArg
      isDraftArg: $isDraftArg
    ) {
      totalCount
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          tmpActivityLogId
        }
      }
    }
  }
`;

/**
 * __useFetchCaraLogListIdsQuery__
 *
 * To run a query within a React component, call `useFetchCaraLogListIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCaraLogListIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCaraLogListIdsQuery({
 *   variables: {
 *      tmpTeamIdsArg: // value for 'tmpTeamIdsArg'
 *      tmpYearIdArg: // value for 'tmpYearIdArg'
 *      startDateArg: // value for 'startDateArg'
 *      endDateArg: // value for 'endDateArg'
 *      logPendingStageSeqsArg: // value for 'logPendingStageSeqsArg'
 *      isDraftArg: // value for 'isDraftArg'
 *   },
 * });
 */
export function useFetchCaraLogListIdsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCaraLogListIdsQuery, FetchCaraLogListIdsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCaraLogListIdsQuery, FetchCaraLogListIdsQueryVariables>(
    FetchCaraLogListIdsDocument,
    options,
  );
}
export function useFetchCaraLogListIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCaraLogListIdsQuery,
    FetchCaraLogListIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCaraLogListIdsQuery, FetchCaraLogListIdsQueryVariables>(
    FetchCaraLogListIdsDocument,
    options,
  );
}
export type FetchCaraLogListIdsQueryHookResult = ReturnType<typeof useFetchCaraLogListIdsQuery>;
export type FetchCaraLogListIdsLazyQueryHookResult = ReturnType<
  typeof useFetchCaraLogListIdsLazyQuery
>;
export type FetchCaraLogListIdsQueryResult = Apollo.QueryResult<
  FetchCaraLogListIdsQuery,
  FetchCaraLogListIdsQueryVariables
>;
export function refetchFetchCaraLogListIdsQuery(variables: FetchCaraLogListIdsQueryVariables) {
  return { query: FetchCaraLogListIdsDocument, variables: variables };
}
export const FetchCaraLogDetailsDocument = gql`
  query FetchCARALogDetails($tmpYearIdArg: Int, $tmpActivityLogId: TWFilter_ID) {
    tmpLogList(tmpYearIdArg: $tmpYearIdArg, tmpActivityLogId: $tmpActivityLogId) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          comments {
            content
            updatedDateTime
            creator {
              firstName
              lastName
              pictureUrl
              personId
            }
          }
          vacationDays {
            date
            label
          }
          segmentTypePerDay {
            date
            label
          }
          tmpActivityLogId
          isDraft
          pendingStageSeq
          isUserComplianceUser
          isUserLogAdmin
          isUserViewer
          tmpTeamSegment {
            weekStart
          }
          showSkillInstruction
          logAthletesSummary {
            tmpActivityLogId
            tmpActivityLogSignoffResponseId
            personId
            firstName
            lastName
            hasWeeklyOverage
            weeklyDaysOff
            isWeeklyDaysOffInViolation
            multiSportAdjustment
            expectedSkillInstructionMinutes
            actualSkillInstructionMinutes
            entries {
              totalCountableMinutes
              date
              dailyViolations {
                hasDailyOverage
                hasCaraOnVacation
                hasCaraOnDayOff
              }
              logAppointments {
                appointmentTitle
                appointmentTypeLabel
                appointmentTypeColor
                appointmentOccurrenceId
                countableMinutes
                editedCountableStartDate
                editedCountableEndDate
                occStartDateTime
                occEndDateTime
                tmpActivityLogAppointmentId
              }
            }
          }
          responses {
            approval
            notes
            tmpActivityLogSignoffResponseId
            person {
              personId
              firstName
              lastName
              pictureUrl
            }
            updatedDateTime
            stage {
              label
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchCaraLogDetailsQuery__
 *
 * To run a query within a React component, call `useFetchCaraLogDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCaraLogDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCaraLogDetailsQuery({
 *   variables: {
 *      tmpYearIdArg: // value for 'tmpYearIdArg'
 *      tmpActivityLogId: // value for 'tmpActivityLogId'
 *   },
 * });
 */
export function useFetchCaraLogDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchCaraLogDetailsQuery,
    FetchCaraLogDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCaraLogDetailsQuery, FetchCaraLogDetailsQueryVariables>(
    FetchCaraLogDetailsDocument,
    options,
  );
}
export function useFetchCaraLogDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCaraLogDetailsQuery,
    FetchCaraLogDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCaraLogDetailsQuery, FetchCaraLogDetailsQueryVariables>(
    FetchCaraLogDetailsDocument,
    options,
  );
}
export type FetchCaraLogDetailsQueryHookResult = ReturnType<typeof useFetchCaraLogDetailsQuery>;
export type FetchCaraLogDetailsLazyQueryHookResult = ReturnType<
  typeof useFetchCaraLogDetailsLazyQuery
>;
export type FetchCaraLogDetailsQueryResult = Apollo.QueryResult<
  FetchCaraLogDetailsQuery,
  FetchCaraLogDetailsQueryVariables
>;
export function refetchFetchCaraLogDetailsQuery(variables?: FetchCaraLogDetailsQueryVariables) {
  return { query: FetchCaraLogDetailsDocument, variables: variables };
}
export const FetchTmpDateTypesDocument = gql`
  query FetchTMPDateTypes {
    tmpDateTypes {
      edges {
        node {
          tmpDateTypeId
          code
        }
      }
    }
  }
`;

/**
 * __useFetchTmpDateTypesQuery__
 *
 * To run a query within a React component, call `useFetchTmpDateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpDateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpDateTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTmpDateTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchTmpDateTypesQuery, FetchTmpDateTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpDateTypesQuery, FetchTmpDateTypesQueryVariables>(
    FetchTmpDateTypesDocument,
    options,
  );
}
export function useFetchTmpDateTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTmpDateTypesQuery,
    FetchTmpDateTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpDateTypesQuery, FetchTmpDateTypesQueryVariables>(
    FetchTmpDateTypesDocument,
    options,
  );
}
export type FetchTmpDateTypesQueryHookResult = ReturnType<typeof useFetchTmpDateTypesQuery>;
export type FetchTmpDateTypesLazyQueryHookResult = ReturnType<typeof useFetchTmpDateTypesLazyQuery>;
export type FetchTmpDateTypesQueryResult = Apollo.QueryResult<
  FetchTmpDateTypesQuery,
  FetchTmpDateTypesQueryVariables
>;
export function refetchFetchTmpDateTypesQuery(variables?: FetchTmpDateTypesQueryVariables) {
  return { query: FetchTmpDateTypesDocument, variables: variables };
}
export const FetchTmpDatesByTypeDocument = gql`
  query FetchTMPDatesByType($tmpDateTypeId: TWFilter_Int) {
    tmpDates(tmpDateTypeId: $tmpDateTypeId) {
      edges {
        node {
          id
          tmpDateId
          tmpDateTypeId
          tmpYearId
          startDate
          endDate
          dateType {
            id
            tmpDateTypeId
            code
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTmpDatesByTypeQuery__
 *
 * To run a query within a React component, call `useFetchTmpDatesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpDatesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpDatesByTypeQuery({
 *   variables: {
 *      tmpDateTypeId: // value for 'tmpDateTypeId'
 *   },
 * });
 */
export function useFetchTmpDatesByTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTmpDatesByTypeQuery,
    FetchTmpDatesByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpDatesByTypeQuery, FetchTmpDatesByTypeQueryVariables>(
    FetchTmpDatesByTypeDocument,
    options,
  );
}
export function useFetchTmpDatesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTmpDatesByTypeQuery,
    FetchTmpDatesByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpDatesByTypeQuery, FetchTmpDatesByTypeQueryVariables>(
    FetchTmpDatesByTypeDocument,
    options,
  );
}
export type FetchTmpDatesByTypeQueryHookResult = ReturnType<typeof useFetchTmpDatesByTypeQuery>;
export type FetchTmpDatesByTypeLazyQueryHookResult = ReturnType<
  typeof useFetchTmpDatesByTypeLazyQuery
>;
export type FetchTmpDatesByTypeQueryResult = Apollo.QueryResult<
  FetchTmpDatesByTypeQuery,
  FetchTmpDatesByTypeQueryVariables
>;
export function refetchFetchTmpDatesByTypeQuery(variables?: FetchTmpDatesByTypeQueryVariables) {
  return { query: FetchTmpDatesByTypeDocument, variables: variables };
}
export const FetchTmpYearsAndSegmentsDocument = gql`
  query FetchTMPYearsAndSegments {
    tmpYears {
      totalCount
      edges {
        cursor
        node {
          isCurrent
          tmpYearId
          label
          tmpTeamDetails {
            tmpTeamId
            teamId
            label
            segments {
              segmentStartDate
              segmentEndDate
            }
            tmpActivityLogConfigId
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTmpYearsAndSegmentsQuery__
 *
 * To run a query within a React component, call `useFetchTmpYearsAndSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpYearsAndSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpYearsAndSegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTmpYearsAndSegmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTmpYearsAndSegmentsQuery,
    FetchTmpYearsAndSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpYearsAndSegmentsQuery, FetchTmpYearsAndSegmentsQueryVariables>(
    FetchTmpYearsAndSegmentsDocument,
    options,
  );
}
export function useFetchTmpYearsAndSegmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTmpYearsAndSegmentsQuery,
    FetchTmpYearsAndSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpYearsAndSegmentsQuery, FetchTmpYearsAndSegmentsQueryVariables>(
    FetchTmpYearsAndSegmentsDocument,
    options,
  );
}
export type FetchTmpYearsAndSegmentsQueryHookResult = ReturnType<
  typeof useFetchTmpYearsAndSegmentsQuery
>;
export type FetchTmpYearsAndSegmentsLazyQueryHookResult = ReturnType<
  typeof useFetchTmpYearsAndSegmentsLazyQuery
>;
export type FetchTmpYearsAndSegmentsQueryResult = Apollo.QueryResult<
  FetchTmpYearsAndSegmentsQuery,
  FetchTmpYearsAndSegmentsQueryVariables
>;
export function refetchFetchTmpYearsAndSegmentsQuery(
  variables?: FetchTmpYearsAndSegmentsQueryVariables,
) {
  return { query: FetchTmpYearsAndSegmentsDocument, variables: variables };
}
export const AddCommentDocument = gql`
  mutation AddComment($fileData: Upload, $id: ID, $input: ComplianceAuditAddCommentInput) {
    addAuditComment(fileData: $fileData, id: $id, input: $input) {
      userErrors {
        message
        field
      }
      appointmentComplianceAudit {
        pk
        creator {
          firstName
          lastName
          pictureUrl
          initials
        }
        createdDateTime
      }
    }
  }
`;
export type AddCommentMutationFn = Apollo.MutationFunction<
  AddCommentMutation,
  AddCommentMutationVariables
>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      fileData: // value for 'fileData'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(
    AddCommentDocument,
    options,
  );
}
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<
  AddCommentMutation,
  AddCommentMutationVariables
>;
export const AthleteInfoDocument = gql`
  query AthleteInfo($codes: [String]!) {
    selectables(codes: $codes) {
      selectionCode
      ... on SelectionGroup {
        pluralLabel
      }
      ... on PersonNode {
        firstName
        lastName
        pictureUrl
        initials
      }
    }
  }
`;

/**
 * __useAthleteInfoQuery__
 *
 * To run a query within a React component, call `useAthleteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteInfoQuery({
 *   variables: {
 *      codes: // value for 'codes'
 *   },
 * });
 */
export function useAthleteInfoQuery(
  baseOptions: Apollo.QueryHookOptions<AthleteInfoQuery, AthleteInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AthleteInfoQuery, AthleteInfoQueryVariables>(AthleteInfoDocument, options);
}
export function useAthleteInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AthleteInfoQuery, AthleteInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AthleteInfoQuery, AthleteInfoQueryVariables>(
    AthleteInfoDocument,
    options,
  );
}
export type AthleteInfoQueryHookResult = ReturnType<typeof useAthleteInfoQuery>;
export type AthleteInfoLazyQueryHookResult = ReturnType<typeof useAthleteInfoLazyQuery>;
export type AthleteInfoQueryResult = Apollo.QueryResult<
  AthleteInfoQuery,
  AthleteInfoQueryVariables
>;
export function refetchAthleteInfoQuery(variables: AthleteInfoQueryVariables) {
  return { query: AthleteInfoDocument, variables: variables };
}
export const FetchCaraChangesDocument = gql`
  query FetchCARAChanges(
    $afterDate: GraphenePendulumDateTime
    $beforeDate: GraphenePendulumDateTime
    $conflictType: ConflictType
    $reviewStatusFilter: ReviewStatusFilter
    $after: String
    $before: String
    $first: Int
    $last: Int
    $teamId: Int
  ) {
    tmpChangeHistory(
      afterDate: $afterDate
      beforeDate: $beforeDate
      conflictType: $conflictType
      reviewStatusFilter: $reviewStatusFilter
      after: $after
      before: $before
      first: $first
      last: $last
      teamId: $teamId
      sort: created_date_desc
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          appointmentComplianceAuditId
          diff {
            changes {
              startDate {
                new
              }
              endDate {
                new
              }
              startTimeZone {
                new
              }
              countableMinutes {
                new
              }
              location {
                new
              }
              appointmentType {
                new
              }
              complianceType {
                new
              }
              recurRule {
                new
              }
              attendees {
                same
              }
            }
            original {
              title
              startDate
              appointmentType
              complianceType
              action
              changeType
              conflictDates
            }
          }
          teams {
            pk
            label
          }
          changeTime
          conflictsList {
            label
          }
          creator {
            firstName
            lastName
          }
          reviewStatus
        }
      }
    }
  }
`;

/**
 * __useFetchCaraChangesQuery__
 *
 * To run a query within a React component, call `useFetchCaraChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCaraChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCaraChangesQuery({
 *   variables: {
 *      afterDate: // value for 'afterDate'
 *      beforeDate: // value for 'beforeDate'
 *      conflictType: // value for 'conflictType'
 *      reviewStatusFilter: // value for 'reviewStatusFilter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useFetchCaraChangesQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchCaraChangesQuery, FetchCaraChangesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCaraChangesQuery, FetchCaraChangesQueryVariables>(
    FetchCaraChangesDocument,
    options,
  );
}
export function useFetchCaraChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCaraChangesQuery, FetchCaraChangesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCaraChangesQuery, FetchCaraChangesQueryVariables>(
    FetchCaraChangesDocument,
    options,
  );
}
export type FetchCaraChangesQueryHookResult = ReturnType<typeof useFetchCaraChangesQuery>;
export type FetchCaraChangesLazyQueryHookResult = ReturnType<typeof useFetchCaraChangesLazyQuery>;
export type FetchCaraChangesQueryResult = Apollo.QueryResult<
  FetchCaraChangesQuery,
  FetchCaraChangesQueryVariables
>;
export function refetchFetchCaraChangesQuery(variables?: FetchCaraChangesQueryVariables) {
  return { query: FetchCaraChangesDocument, variables: variables };
}
export const FetchCaraChangesExportDocument = gql`
  query FetchCARAChangesExport(
    $afterDate: GraphenePendulumDateTime
    $beforeDate: GraphenePendulumDateTime
    $conflictType: ConflictType
    $reviewStatusFilter: ReviewStatusFilter
    $teamId: Int
  ) {
    tmpChangeHistory(
      afterDate: $afterDate
      beforeDate: $beforeDate
      conflictType: $conflictType
      reviewStatusFilter: $reviewStatusFilter
      teamId: $teamId
    ) {
      edges {
        node {
          diff {
            changes {
              startDate {
                new
              }
              endDate {
                new
              }
              startTimeZone {
                new
              }
              countableMinutes {
                new
              }
              location {
                new
              }
              appointmentType {
                new
              }
              complianceType {
                new
              }
              recurRule {
                new
              }
              attendees {
                raw
              }
            }
            original {
              title
              location
              startDate
              appointmentType
              attendeeSelectionCodes
              action
            }
          }
          teams {
            pk
            label
          }
          changeTime
          conflictsList {
            label
            bylaw
          }
          creator {
            firstName
            lastName
          }
          reviewStatus
          comments {
            note
            attachments {
              pk
            }
          }
          conflictReasonCode
          conflictReasonText
        }
      }
    }
  }
`;

/**
 * __useFetchCaraChangesExportQuery__
 *
 * To run a query within a React component, call `useFetchCaraChangesExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCaraChangesExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCaraChangesExportQuery({
 *   variables: {
 *      afterDate: // value for 'afterDate'
 *      beforeDate: // value for 'beforeDate'
 *      conflictType: // value for 'conflictType'
 *      reviewStatusFilter: // value for 'reviewStatusFilter'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useFetchCaraChangesExportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchCaraChangesExportQuery,
    FetchCaraChangesExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCaraChangesExportQuery, FetchCaraChangesExportQueryVariables>(
    FetchCaraChangesExportDocument,
    options,
  );
}
export function useFetchCaraChangesExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCaraChangesExportQuery,
    FetchCaraChangesExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCaraChangesExportQuery, FetchCaraChangesExportQueryVariables>(
    FetchCaraChangesExportDocument,
    options,
  );
}
export type FetchCaraChangesExportQueryHookResult = ReturnType<
  typeof useFetchCaraChangesExportQuery
>;
export type FetchCaraChangesExportLazyQueryHookResult = ReturnType<
  typeof useFetchCaraChangesExportLazyQuery
>;
export type FetchCaraChangesExportQueryResult = Apollo.QueryResult<
  FetchCaraChangesExportQuery,
  FetchCaraChangesExportQueryVariables
>;
export function refetchFetchCaraChangesExportQuery(
  variables?: FetchCaraChangesExportQueryVariables,
) {
  return { query: FetchCaraChangesExportDocument, variables: variables };
}
export const FetchCaraChangesDetailDocument = gql`
  query FetchCARAChangesDetail($appointmentComplianceAuditId: TWFilter_ID) {
    tmpChangeHistory(appointmentComplianceAuditId: $appointmentComplianceAuditId) {
      edges {
        node {
          diff {
            changes {
              startDate {
                new
                old
              }
              endDate {
                new
                old
              }
              startTimeZone {
                new
                old
              }
              countableMinutes {
                new
                old
              }
              location {
                new
                old
              }
              appointmentType {
                new
                old
              }
              complianceType {
                new
                old
              }
              recurRule {
                new
                old
              }
              attendees {
                removed
                added
              }
              title {
                new
                old
              }
            }
            original {
              title
              location
              startDate
              endDate
              timeZone
              appointmentType
              complianceType
              countableMinutes
              attendeeSelectionCodes
              action
              recurRule
              changeType
              conflictDates
            }
          }
          teams {
            pk
            label
          }
          changeTime
          conflictsList {
            label
            bylaw
          }
          creator {
            firstName
            lastName
          }
          reviewStatus
          comments {
            note
            createdDateTime
            creator {
              firstName
              lastName
              pictureUrl
              initials
            }
            attachments {
              pk
              fileName
              downloadUrl
            }
          }
          conflictReasonCode
          conflictReasonText
        }
      }
    }
  }
`;

/**
 * __useFetchCaraChangesDetailQuery__
 *
 * To run a query within a React component, call `useFetchCaraChangesDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCaraChangesDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCaraChangesDetailQuery({
 *   variables: {
 *      appointmentComplianceAuditId: // value for 'appointmentComplianceAuditId'
 *   },
 * });
 */
export function useFetchCaraChangesDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchCaraChangesDetailQuery,
    FetchCaraChangesDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCaraChangesDetailQuery, FetchCaraChangesDetailQueryVariables>(
    FetchCaraChangesDetailDocument,
    options,
  );
}
export function useFetchCaraChangesDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCaraChangesDetailQuery,
    FetchCaraChangesDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCaraChangesDetailQuery, FetchCaraChangesDetailQueryVariables>(
    FetchCaraChangesDetailDocument,
    options,
  );
}
export type FetchCaraChangesDetailQueryHookResult = ReturnType<
  typeof useFetchCaraChangesDetailQuery
>;
export type FetchCaraChangesDetailLazyQueryHookResult = ReturnType<
  typeof useFetchCaraChangesDetailLazyQuery
>;
export type FetchCaraChangesDetailQueryResult = Apollo.QueryResult<
  FetchCaraChangesDetailQuery,
  FetchCaraChangesDetailQueryVariables
>;
export function refetchFetchCaraChangesDetailQuery(
  variables?: FetchCaraChangesDetailQueryVariables,
) {
  return { query: FetchCaraChangesDetailDocument, variables: variables };
}
export const UpdateChangeStatusDocument = gql`
  mutation UpdateChangeStatus($input: ComplianceAuditsChangeReviewStatusInput!) {
    changeAuditReview(input: $input) {
      userErrors {
        message
        field
      }
      appointmentComplianceAudit {
        pk
        reviewStatus
      }
    }
  }
`;
export type UpdateChangeStatusMutationFn = Apollo.MutationFunction<
  UpdateChangeStatusMutation,
  UpdateChangeStatusMutationVariables
>;

/**
 * __useUpdateChangeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeStatusMutation, { data, loading, error }] = useUpdateChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChangeStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChangeStatusMutation,
    UpdateChangeStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChangeStatusMutation, UpdateChangeStatusMutationVariables>(
    UpdateChangeStatusDocument,
    options,
  );
}
export type UpdateChangeStatusMutationHookResult = ReturnType<typeof useUpdateChangeStatusMutation>;
export type UpdateChangeStatusMutationResult = Apollo.MutationResult<UpdateChangeStatusMutation>;
export type UpdateChangeStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateChangeStatusMutation,
  UpdateChangeStatusMutationVariables
>;
export const FetchTmpOverviewAthleteEventsDocument = gql`
  query FetchTmpOverviewAthleteEvents($filters: TmpCalendarOverviewInput) {
    tmpCalendarOverviewAthlete(filters: $filters) {
      appointmentTitle
      startDateTime
      endDateTime
      countableMinutes
      color
      dayOffType
      countableApptType
      countableMinutes
      isAllDay
    }
  }
`;

/**
 * __useFetchTmpOverviewAthleteEventsQuery__
 *
 * To run a query within a React component, call `useFetchTmpOverviewAthleteEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpOverviewAthleteEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpOverviewAthleteEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFetchTmpOverviewAthleteEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTmpOverviewAthleteEventsQuery,
    FetchTmpOverviewAthleteEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchTmpOverviewAthleteEventsQuery,
    FetchTmpOverviewAthleteEventsQueryVariables
  >(FetchTmpOverviewAthleteEventsDocument, options);
}
export function useFetchTmpOverviewAthleteEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTmpOverviewAthleteEventsQuery,
    FetchTmpOverviewAthleteEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchTmpOverviewAthleteEventsQuery,
    FetchTmpOverviewAthleteEventsQueryVariables
  >(FetchTmpOverviewAthleteEventsDocument, options);
}
export type FetchTmpOverviewAthleteEventsQueryHookResult = ReturnType<
  typeof useFetchTmpOverviewAthleteEventsQuery
>;
export type FetchTmpOverviewAthleteEventsLazyQueryHookResult = ReturnType<
  typeof useFetchTmpOverviewAthleteEventsLazyQuery
>;
export type FetchTmpOverviewAthleteEventsQueryResult = Apollo.QueryResult<
  FetchTmpOverviewAthleteEventsQuery,
  FetchTmpOverviewAthleteEventsQueryVariables
>;
export function refetchFetchTmpOverviewAthleteEventsQuery(
  variables?: FetchTmpOverviewAthleteEventsQueryVariables,
) {
  return { query: FetchTmpOverviewAthleteEventsDocument, variables: variables };
}
export const FetchTmpOverviewAthletesDocument = gql`
  query FetchTmpOverviewAthletes($selectedTeam: ID!, $athleteType: ID!) {
    groups(filters: { selectedTeam: $selectedTeam, onlyPersonTypes: [$athleteType] }) {
      label
      groupType
      people {
        id
        key: id
        label: displayName
        value: selectionCode
      }
    }
  }
`;

/**
 * __useFetchTmpOverviewAthletesQuery__
 *
 * To run a query within a React component, call `useFetchTmpOverviewAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpOverviewAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpOverviewAthletesQuery({
 *   variables: {
 *      selectedTeam: // value for 'selectedTeam'
 *      athleteType: // value for 'athleteType'
 *   },
 * });
 */
export function useFetchTmpOverviewAthletesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchTmpOverviewAthletesQuery,
    FetchTmpOverviewAthletesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpOverviewAthletesQuery, FetchTmpOverviewAthletesQueryVariables>(
    FetchTmpOverviewAthletesDocument,
    options,
  );
}
export function useFetchTmpOverviewAthletesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTmpOverviewAthletesQuery,
    FetchTmpOverviewAthletesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpOverviewAthletesQuery, FetchTmpOverviewAthletesQueryVariables>(
    FetchTmpOverviewAthletesDocument,
    options,
  );
}
export type FetchTmpOverviewAthletesQueryHookResult = ReturnType<
  typeof useFetchTmpOverviewAthletesQuery
>;
export type FetchTmpOverviewAthletesLazyQueryHookResult = ReturnType<
  typeof useFetchTmpOverviewAthletesLazyQuery
>;
export type FetchTmpOverviewAthletesQueryResult = Apollo.QueryResult<
  FetchTmpOverviewAthletesQuery,
  FetchTmpOverviewAthletesQueryVariables
>;
export function refetchFetchTmpOverviewAthletesQuery(
  variables: FetchTmpOverviewAthletesQueryVariables,
) {
  return { query: FetchTmpOverviewAthletesDocument, variables: variables };
}
export const FetchTmpOverviewLegendDocument = gql`
  query FetchTmpOverviewLegend {
    tmpCalendarOverviewLegend {
      displayName
      colorCode
      displayType
    }
  }
`;

/**
 * __useFetchTmpOverviewLegendQuery__
 *
 * To run a query within a React component, call `useFetchTmpOverviewLegendQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpOverviewLegendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpOverviewLegendQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTmpOverviewLegendQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTmpOverviewLegendQuery,
    FetchTmpOverviewLegendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpOverviewLegendQuery, FetchTmpOverviewLegendQueryVariables>(
    FetchTmpOverviewLegendDocument,
    options,
  );
}
export function useFetchTmpOverviewLegendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTmpOverviewLegendQuery,
    FetchTmpOverviewLegendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpOverviewLegendQuery, FetchTmpOverviewLegendQueryVariables>(
    FetchTmpOverviewLegendDocument,
    options,
  );
}
export type FetchTmpOverviewLegendQueryHookResult = ReturnType<
  typeof useFetchTmpOverviewLegendQuery
>;
export type FetchTmpOverviewLegendLazyQueryHookResult = ReturnType<
  typeof useFetchTmpOverviewLegendLazyQuery
>;
export type FetchTmpOverviewLegendQueryResult = Apollo.QueryResult<
  FetchTmpOverviewLegendQuery,
  FetchTmpOverviewLegendQueryVariables
>;
export function refetchFetchTmpOverviewLegendQuery(
  variables?: FetchTmpOverviewLegendQueryVariables,
) {
  return { query: FetchTmpOverviewLegendDocument, variables: variables };
}
export const FetchTmpOverviewTmpEventsDocument = gql`
  query FetchTmpOverviewTmpEvents($tmpTeamIdArg: ID!) {
    tmpCalendarOverviewDeclarations(tmpTeamIdArg: $tmpTeamIdArg) {
      isInterval
      startAppointment {
        appointmentTypeLabel
        appointmentTitle
        startDateTime
        endDateTime
        color
      }
      endAppointment {
        appointmentTypeLabel
        appointmentTitle
        startDateTime
        endDateTime
        color
      }
    }
  }
`;

/**
 * __useFetchTmpOverviewTmpEventsQuery__
 *
 * To run a query within a React component, call `useFetchTmpOverviewTmpEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpOverviewTmpEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpOverviewTmpEventsQuery({
 *   variables: {
 *      tmpTeamIdArg: // value for 'tmpTeamIdArg'
 *   },
 * });
 */
export function useFetchTmpOverviewTmpEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchTmpOverviewTmpEventsQuery,
    FetchTmpOverviewTmpEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpOverviewTmpEventsQuery, FetchTmpOverviewTmpEventsQueryVariables>(
    FetchTmpOverviewTmpEventsDocument,
    options,
  );
}
export function useFetchTmpOverviewTmpEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTmpOverviewTmpEventsQuery,
    FetchTmpOverviewTmpEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchTmpOverviewTmpEventsQuery,
    FetchTmpOverviewTmpEventsQueryVariables
  >(FetchTmpOverviewTmpEventsDocument, options);
}
export type FetchTmpOverviewTmpEventsQueryHookResult = ReturnType<
  typeof useFetchTmpOverviewTmpEventsQuery
>;
export type FetchTmpOverviewTmpEventsLazyQueryHookResult = ReturnType<
  typeof useFetchTmpOverviewTmpEventsLazyQuery
>;
export type FetchTmpOverviewTmpEventsQueryResult = Apollo.QueryResult<
  FetchTmpOverviewTmpEventsQuery,
  FetchTmpOverviewTmpEventsQueryVariables
>;
export function refetchFetchTmpOverviewTmpEventsQuery(
  variables: FetchTmpOverviewTmpEventsQueryVariables,
) {
  return { query: FetchTmpOverviewTmpEventsDocument, variables: variables };
}
export const FetchTmpYearRulesDocument = gql`
  query FetchTMPYearRules($tmpYearId: TWFilter_Int!) {
    tmpYearRules(tmpYearId: $tmpYearId) {
      totalCount
      edges {
        node {
          tmpYearRuleId
          id
          notifyCompliance
          commentRequired
          newApptNotificationHours
          modApptNotificationHours
          isEnabled
          rule {
            bylaw
            label
            code
            description
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTmpYearRulesQuery__
 *
 * To run a query within a React component, call `useFetchTmpYearRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpYearRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpYearRulesQuery({
 *   variables: {
 *      tmpYearId: // value for 'tmpYearId'
 *   },
 * });
 */
export function useFetchTmpYearRulesQuery(
  baseOptions: Apollo.QueryHookOptions<FetchTmpYearRulesQuery, FetchTmpYearRulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpYearRulesQuery, FetchTmpYearRulesQueryVariables>(
    FetchTmpYearRulesDocument,
    options,
  );
}
export function useFetchTmpYearRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTmpYearRulesQuery,
    FetchTmpYearRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpYearRulesQuery, FetchTmpYearRulesQueryVariables>(
    FetchTmpYearRulesDocument,
    options,
  );
}
export type FetchTmpYearRulesQueryHookResult = ReturnType<typeof useFetchTmpYearRulesQuery>;
export type FetchTmpYearRulesLazyQueryHookResult = ReturnType<typeof useFetchTmpYearRulesLazyQuery>;
export type FetchTmpYearRulesQueryResult = Apollo.QueryResult<
  FetchTmpYearRulesQuery,
  FetchTmpYearRulesQueryVariables
>;
export function refetchFetchTmpYearRulesQuery(variables: FetchTmpYearRulesQueryVariables) {
  return { query: FetchTmpYearRulesDocument, variables: variables };
}
export const CreateTmpDateDocument = gql`
  mutation CreateTMPDate($input: TmpDateCreateInput!) {
    tmpDateCreate(input: $input) {
      tmpDate {
        id
      }
      userErrors {
        message
        field
      }
      bulkUserErrors {
        objectKey
        errorList {
          message
          field
        }
      }
    }
  }
`;
export type CreateTmpDateMutationFn = Apollo.MutationFunction<
  CreateTmpDateMutation,
  CreateTmpDateMutationVariables
>;

/**
 * __useCreateTmpDateMutation__
 *
 * To run a mutation, you first call `useCreateTmpDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTmpDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTmpDateMutation, { data, loading, error }] = useCreateTmpDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTmpDateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTmpDateMutation, CreateTmpDateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTmpDateMutation, CreateTmpDateMutationVariables>(
    CreateTmpDateDocument,
    options,
  );
}
export type CreateTmpDateMutationHookResult = ReturnType<typeof useCreateTmpDateMutation>;
export type CreateTmpDateMutationResult = Apollo.MutationResult<CreateTmpDateMutation>;
export type CreateTmpDateMutationOptions = Apollo.BaseMutationOptions<
  CreateTmpDateMutation,
  CreateTmpDateMutationVariables
>;
export const DeleteTmpDateDocument = gql`
  mutation DeleteTMPDate($id: ID!) {
    tmpDateDelete(id: $id) {
      userErrors {
        message
        field
      }
      bulkUserErrors {
        objectKey
        errorList {
          message
          field
        }
      }
    }
  }
`;
export type DeleteTmpDateMutationFn = Apollo.MutationFunction<
  DeleteTmpDateMutation,
  DeleteTmpDateMutationVariables
>;

/**
 * __useDeleteTmpDateMutation__
 *
 * To run a mutation, you first call `useDeleteTmpDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTmpDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTmpDateMutation, { data, loading, error }] = useDeleteTmpDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTmpDateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTmpDateMutation, DeleteTmpDateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTmpDateMutation, DeleteTmpDateMutationVariables>(
    DeleteTmpDateDocument,
    options,
  );
}
export type DeleteTmpDateMutationHookResult = ReturnType<typeof useDeleteTmpDateMutation>;
export type DeleteTmpDateMutationResult = Apollo.MutationResult<DeleteTmpDateMutation>;
export type DeleteTmpDateMutationOptions = Apollo.BaseMutationOptions<
  DeleteTmpDateMutation,
  DeleteTmpDateMutationVariables
>;
export const FetchTmpDatesDocument = gql`
  query FetchTMPDates($tmpYearIdArg: Int) {
    tmpDates(tmpYearIdArg: $tmpYearIdArg) {
      edges {
        node {
          id
          tmpDateId
          tmpDateTypeId
          startDate
          endDate
          dateType {
            id
            tmpDateTypeId
            code
          }
          label
        }
      }
    }
    tmpDateTypes {
      edges {
        node {
          tmpDateTypeId
          code
        }
      }
    }
  }
`;

/**
 * __useFetchTmpDatesQuery__
 *
 * To run a query within a React component, call `useFetchTmpDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpDatesQuery({
 *   variables: {
 *      tmpYearIdArg: // value for 'tmpYearIdArg'
 *   },
 * });
 */
export function useFetchTmpDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchTmpDatesQuery, FetchTmpDatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpDatesQuery, FetchTmpDatesQueryVariables>(
    FetchTmpDatesDocument,
    options,
  );
}
export function useFetchTmpDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTmpDatesQuery, FetchTmpDatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpDatesQuery, FetchTmpDatesQueryVariables>(
    FetchTmpDatesDocument,
    options,
  );
}
export type FetchTmpDatesQueryHookResult = ReturnType<typeof useFetchTmpDatesQuery>;
export type FetchTmpDatesLazyQueryHookResult = ReturnType<typeof useFetchTmpDatesLazyQuery>;
export type FetchTmpDatesQueryResult = Apollo.QueryResult<
  FetchTmpDatesQuery,
  FetchTmpDatesQueryVariables
>;
export function refetchFetchTmpDatesQuery(variables?: FetchTmpDatesQueryVariables) {
  return { query: FetchTmpDatesDocument, variables: variables };
}
export const UpdateTmpDateDocument = gql`
  mutation UpdateTMPDate($id: ID!, $input: TmpDateUpdateInput!) {
    tmpDateUpdate(id: $id, input: $input) {
      tmpDate {
        id
      }
      userErrors {
        message
        field
      }
      bulkUserErrors {
        objectKey
        errorList {
          message
          field
        }
      }
    }
  }
`;
export type UpdateTmpDateMutationFn = Apollo.MutationFunction<
  UpdateTmpDateMutation,
  UpdateTmpDateMutationVariables
>;

/**
 * __useUpdateTmpDateMutation__
 *
 * To run a mutation, you first call `useUpdateTmpDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTmpDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTmpDateMutation, { data, loading, error }] = useUpdateTmpDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTmpDateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTmpDateMutation, UpdateTmpDateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTmpDateMutation, UpdateTmpDateMutationVariables>(
    UpdateTmpDateDocument,
    options,
  );
}
export type UpdateTmpDateMutationHookResult = ReturnType<typeof useUpdateTmpDateMutation>;
export type UpdateTmpDateMutationResult = Apollo.MutationResult<UpdateTmpDateMutation>;
export type UpdateTmpDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateTmpDateMutation,
  UpdateTmpDateMutationVariables
>;
export const UpdateTmpYearDocument = gql`
  mutation UpdateTMPYear($id: ID!, $input: TmpYearUpdateInput!) {
    tmpYearUpdate(id: $id, input: $input) {
      userErrors {
        message
        field
      }
      bulkUserErrors {
        objectKey
        errorList {
          message
          field
        }
      }
      tmpYear {
        id
        tmpYearId
        label
        isCurrent
      }
    }
  }
`;
export type UpdateTmpYearMutationFn = Apollo.MutationFunction<
  UpdateTmpYearMutation,
  UpdateTmpYearMutationVariables
>;

/**
 * __useUpdateTmpYearMutation__
 *
 * To run a mutation, you first call `useUpdateTmpYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTmpYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTmpYearMutation, { data, loading, error }] = useUpdateTmpYearMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTmpYearMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTmpYearMutation, UpdateTmpYearMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTmpYearMutation, UpdateTmpYearMutationVariables>(
    UpdateTmpYearDocument,
    options,
  );
}
export type UpdateTmpYearMutationHookResult = ReturnType<typeof useUpdateTmpYearMutation>;
export type UpdateTmpYearMutationResult = Apollo.MutationResult<UpdateTmpYearMutation>;
export type UpdateTmpYearMutationOptions = Apollo.BaseMutationOptions<
  UpdateTmpYearMutation,
  UpdateTmpYearMutationVariables
>;
export const CreateTmpYearRulesDocument = gql`
  mutation CreateTMPYearRules($input: TmpYearRuleInput!) {
    tmpYearRuleCreate(input: $input) {
      tmpYearRule {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type CreateTmpYearRulesMutationFn = Apollo.MutationFunction<
  CreateTmpYearRulesMutation,
  CreateTmpYearRulesMutationVariables
>;

/**
 * __useCreateTmpYearRulesMutation__
 *
 * To run a mutation, you first call `useCreateTmpYearRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTmpYearRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTmpYearRulesMutation, { data, loading, error }] = useCreateTmpYearRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTmpYearRulesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTmpYearRulesMutation,
    CreateTmpYearRulesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTmpYearRulesMutation, CreateTmpYearRulesMutationVariables>(
    CreateTmpYearRulesDocument,
    options,
  );
}
export type CreateTmpYearRulesMutationHookResult = ReturnType<typeof useCreateTmpYearRulesMutation>;
export type CreateTmpYearRulesMutationResult = Apollo.MutationResult<CreateTmpYearRulesMutation>;
export type CreateTmpYearRulesMutationOptions = Apollo.BaseMutationOptions<
  CreateTmpYearRulesMutation,
  CreateTmpYearRulesMutationVariables
>;
export const UpdateTmpYearRulesDocument = gql`
  mutation UpdateTMPYearRules($input: TmpYearRuleInput!, $id: ID!) {
    tmpYearRuleUpdate(input: $input, id: $id) {
      tmpYearRule {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type UpdateTmpYearRulesMutationFn = Apollo.MutationFunction<
  UpdateTmpYearRulesMutation,
  UpdateTmpYearRulesMutationVariables
>;

/**
 * __useUpdateTmpYearRulesMutation__
 *
 * To run a mutation, you first call `useUpdateTmpYearRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTmpYearRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTmpYearRulesMutation, { data, loading, error }] = useUpdateTmpYearRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTmpYearRulesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTmpYearRulesMutation,
    UpdateTmpYearRulesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTmpYearRulesMutation, UpdateTmpYearRulesMutationVariables>(
    UpdateTmpYearRulesDocument,
    options,
  );
}
export type UpdateTmpYearRulesMutationHookResult = ReturnType<typeof useUpdateTmpYearRulesMutation>;
export type UpdateTmpYearRulesMutationResult = Apollo.MutationResult<UpdateTmpYearRulesMutation>;
export type UpdateTmpYearRulesMutationOptions = Apollo.BaseMutationOptions<
  UpdateTmpYearRulesMutation,
  UpdateTmpYearRulesMutationVariables
>;
export const AddSegmentDocument = gql`
  mutation AddSegment($input: TmpTeamSegmentInput!) {
    tmpTeamSegmentCreate(input: $input) {
      tmpTeamSegment {
        id
        metaData {
          daysOff
          selectedNonCountableDays
        }
        label
        acclimatizationPeriodStartDate
        acclimatizationPeriodEndDate
        firstCaraDate
        isChampionship
        nineWeekPeriodStartDate
        orientationDate
        preSeasonStartDate
        preSeasonEndDate
        postSeasonStartDate
        postSeasonEndDate
        postSeasonDaysoffStartDate
        postSeasonDaysoffEndDate
        seasonType
        segmentEndDate
        segmentStartDate
        tmpSegmentType
        weekStart
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type AddSegmentMutationFn = Apollo.MutationFunction<
  AddSegmentMutation,
  AddSegmentMutationVariables
>;

/**
 * __useAddSegmentMutation__
 *
 * To run a mutation, you first call `useAddSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSegmentMutation, { data, loading, error }] = useAddSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSegmentMutation, AddSegmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSegmentMutation, AddSegmentMutationVariables>(
    AddSegmentDocument,
    options,
  );
}
export type AddSegmentMutationHookResult = ReturnType<typeof useAddSegmentMutation>;
export type AddSegmentMutationResult = Apollo.MutationResult<AddSegmentMutation>;
export type AddSegmentMutationOptions = Apollo.BaseMutationOptions<
  AddSegmentMutation,
  AddSegmentMutationVariables
>;
export const AddTmpTeamDocument = gql`
  mutation AddTMPTeam($input: TmpTeamInput!) {
    tmpTeamCreate(input: $input) {
      tmpTeam {
        id
        tmpTeamId
        tmpYearId
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type AddTmpTeamMutationFn = Apollo.MutationFunction<
  AddTmpTeamMutation,
  AddTmpTeamMutationVariables
>;

/**
 * __useAddTmpTeamMutation__
 *
 * To run a mutation, you first call `useAddTmpTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTmpTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTmpTeamMutation, { data, loading, error }] = useAddTmpTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTmpTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTmpTeamMutation, AddTmpTeamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTmpTeamMutation, AddTmpTeamMutationVariables>(
    AddTmpTeamDocument,
    options,
  );
}
export type AddTmpTeamMutationHookResult = ReturnType<typeof useAddTmpTeamMutation>;
export type AddTmpTeamMutationResult = Apollo.MutationResult<AddTmpTeamMutation>;
export type AddTmpTeamMutationOptions = Apollo.BaseMutationOptions<
  AddTmpTeamMutation,
  AddTmpTeamMutationVariables
>;
export const DeleteSegmentDocument = gql`
  mutation DeleteSegment($id: ID!) {
    tmpTeamSegmentDelete(id: $id) {
      tmpTeamSegment {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type DeleteSegmentMutationFn = Apollo.MutationFunction<
  DeleteSegmentMutation,
  DeleteSegmentMutationVariables
>;

/**
 * __useDeleteSegmentMutation__
 *
 * To run a mutation, you first call `useDeleteSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSegmentMutation, { data, loading, error }] = useDeleteSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSegmentMutation, DeleteSegmentMutationVariables>(
    DeleteSegmentDocument,
    options,
  );
}
export type DeleteSegmentMutationHookResult = ReturnType<typeof useDeleteSegmentMutation>;
export type DeleteSegmentMutationResult = Apollo.MutationResult<DeleteSegmentMutation>;
export type DeleteSegmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteSegmentMutation,
  DeleteSegmentMutationVariables
>;
export const FetchSegmentConflictsDocument = gql`
  query FetchSegmentConflicts($tmpTeamSegmentId: TWFilter_ID!) {
    tmpTeamSegmentConflict(tmpTeamSegmentId: $tmpTeamSegmentId) {
      edges {
        node {
          tmpTeamSegmentId
          id
          allTmpConflicts {
            additionalConflicts {
              academicYearStartDate
              athletes {
                numAppts
                selectionCode {
                  selectionCode
                  ... on PersonNode {
                    pictureUrl
                    preferredName
                    lastName
                    initials
                  }
                }
              }
            }
            weeklyConflicts {
              tmpTeamSegmentId
              weekLabel
              startDate
              endDate
              athletes {
                selectionCode {
                  selectionCode
                  ... on PersonNode {
                    pictureUrl
                    preferredName
                    lastName
                    initials
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchSegmentConflictsQuery__
 *
 * To run a query within a React component, call `useFetchSegmentConflictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSegmentConflictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSegmentConflictsQuery({
 *   variables: {
 *      tmpTeamSegmentId: // value for 'tmpTeamSegmentId'
 *   },
 * });
 */
export function useFetchSegmentConflictsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSegmentConflictsQuery,
    FetchSegmentConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSegmentConflictsQuery, FetchSegmentConflictsQueryVariables>(
    FetchSegmentConflictsDocument,
    options,
  );
}
export function useFetchSegmentConflictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSegmentConflictsQuery,
    FetchSegmentConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSegmentConflictsQuery, FetchSegmentConflictsQueryVariables>(
    FetchSegmentConflictsDocument,
    options,
  );
}
export type FetchSegmentConflictsQueryHookResult = ReturnType<typeof useFetchSegmentConflictsQuery>;
export type FetchSegmentConflictsLazyQueryHookResult = ReturnType<
  typeof useFetchSegmentConflictsLazyQuery
>;
export type FetchSegmentConflictsQueryResult = Apollo.QueryResult<
  FetchSegmentConflictsQuery,
  FetchSegmentConflictsQueryVariables
>;
export function refetchFetchSegmentConflictsQuery(variables: FetchSegmentConflictsQueryVariables) {
  return { query: FetchSegmentConflictsDocument, variables: variables };
}
export const FetchTmpSegmentsDocument = gql`
  query FetchTMPSegments($tmpTeamIdArg: Int) {
    tmpTeamSegment(tmpTeamIdArg: $tmpTeamIdArg) {
      edges {
        node {
          tmpTeamSegmentId
          id
          label
          segmentStartDate
          segmentEndDate
        }
      }
    }
  }
`;

/**
 * __useFetchTmpSegmentsQuery__
 *
 * To run a query within a React component, call `useFetchTmpSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpSegmentsQuery({
 *   variables: {
 *      tmpTeamIdArg: // value for 'tmpTeamIdArg'
 *   },
 * });
 */
export function useFetchTmpSegmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchTmpSegmentsQuery, FetchTmpSegmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpSegmentsQuery, FetchTmpSegmentsQueryVariables>(
    FetchTmpSegmentsDocument,
    options,
  );
}
export function useFetchTmpSegmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTmpSegmentsQuery, FetchTmpSegmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpSegmentsQuery, FetchTmpSegmentsQueryVariables>(
    FetchTmpSegmentsDocument,
    options,
  );
}
export type FetchTmpSegmentsQueryHookResult = ReturnType<typeof useFetchTmpSegmentsQuery>;
export type FetchTmpSegmentsLazyQueryHookResult = ReturnType<typeof useFetchTmpSegmentsLazyQuery>;
export type FetchTmpSegmentsQueryResult = Apollo.QueryResult<
  FetchTmpSegmentsQuery,
  FetchTmpSegmentsQueryVariables
>;
export function refetchFetchTmpSegmentsQuery(variables?: FetchTmpSegmentsQueryVariables) {
  return { query: FetchTmpSegmentsDocument, variables: variables };
}
export const FetchTmpTeamsDocument = gql`
  query FetchTMPTeams($orgId: TWFilter_Int!) {
    tmpTeams(orgId: $orgId) {
      edges {
        node {
          teamId
          team {
            label
            orgId
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTmpTeamsQuery__
 *
 * To run a query within a React component, call `useFetchTmpTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpTeamsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useFetchTmpTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchTmpTeamsQuery, FetchTmpTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpTeamsQuery, FetchTmpTeamsQueryVariables>(
    FetchTmpTeamsDocument,
    options,
  );
}
export function useFetchTmpTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTmpTeamsQuery, FetchTmpTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpTeamsQuery, FetchTmpTeamsQueryVariables>(
    FetchTmpTeamsDocument,
    options,
  );
}
export type FetchTmpTeamsQueryHookResult = ReturnType<typeof useFetchTmpTeamsQuery>;
export type FetchTmpTeamsLazyQueryHookResult = ReturnType<typeof useFetchTmpTeamsLazyQuery>;
export type FetchTmpTeamsQueryResult = Apollo.QueryResult<
  FetchTmpTeamsQuery,
  FetchTmpTeamsQueryVariables
>;
export function refetchFetchTmpTeamsQuery(variables: FetchTmpTeamsQueryVariables) {
  return { query: FetchTmpTeamsDocument, variables: variables };
}
export const FetchTmpYearsDocument = gql`
  query FetchTMPYears($orgId: TWFilter_Int!, $withTmpTeams: Boolean = true) {
    tmpYears(orgId: $orgId) {
      totalCount
      edges {
        node {
          id
          isCurrent
          tmpYearId
          label
          orgId
          tmpTeams @include(if: $withTmpTeams) {
            hasEditDeclarationPerm
            tmpTeamId
            teamId
            team {
              label
              orgId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTmpYearsQuery__
 *
 * To run a query within a React component, call `useFetchTmpYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpYearsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      withTmpTeams: // value for 'withTmpTeams'
 *   },
 * });
 */
export function useFetchTmpYearsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchTmpYearsQuery, FetchTmpYearsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpYearsQuery, FetchTmpYearsQueryVariables>(
    FetchTmpYearsDocument,
    options,
  );
}
export function useFetchTmpYearsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTmpYearsQuery, FetchTmpYearsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpYearsQuery, FetchTmpYearsQueryVariables>(
    FetchTmpYearsDocument,
    options,
  );
}
export type FetchTmpYearsQueryHookResult = ReturnType<typeof useFetchTmpYearsQuery>;
export type FetchTmpYearsLazyQueryHookResult = ReturnType<typeof useFetchTmpYearsLazyQuery>;
export type FetchTmpYearsQueryResult = Apollo.QueryResult<
  FetchTmpYearsQuery,
  FetchTmpYearsQueryVariables
>;
export function refetchFetchTmpYearsQuery(variables: FetchTmpYearsQueryVariables) {
  return { query: FetchTmpYearsDocument, variables: variables };
}
export const FetchTeamSegmentsDocument = gql`
  query FetchTeamSegments($tmpYearId: TWFilter_Int!, $tmpTeamId: TWFilter_ID!) {
    tmpTeams(tmpYearId: $tmpYearId, tmpTeamId: $tmpTeamId) {
      edges {
        node {
          team {
            label
            teamId
            canAddToTeam
          }
          hasAthleteGroups
          segments {
            tmpTeamSegmentId
            id
            metaData {
              daysOff
              selectedNonCountableDays
            }
            tmpTeamId
            status
            seasonType
            tmpSegmentType
            label
            orientationDate
            weekStart
            segmentOrder
            isChampionship
            firstCompetitionDate
            firstCaraDate
            nineWeekPeriodStartDate
            preSeasonStartDate
            preSeasonEndDate
            postSeasonStartDate
            postSeasonEndDate
            postSeasonDaysoffEndDate
            postSeasonDaysoffStartDate
            segmentStartDate
            segmentEndDate
            acclimatizationPeriodStartDate
            acclimatizationPeriodEndDate
            summerDiscretionaryPeriods {
              startDate
              endDate
            }
            springDiscretionaryPeriods {
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTeamSegmentsQuery__
 *
 * To run a query within a React component, call `useFetchTeamSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTeamSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTeamSegmentsQuery({
 *   variables: {
 *      tmpYearId: // value for 'tmpYearId'
 *      tmpTeamId: // value for 'tmpTeamId'
 *   },
 * });
 */
export function useFetchTeamSegmentsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchTeamSegmentsQuery, FetchTeamSegmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTeamSegmentsQuery, FetchTeamSegmentsQueryVariables>(
    FetchTeamSegmentsDocument,
    options,
  );
}
export function useFetchTeamSegmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTeamSegmentsQuery,
    FetchTeamSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTeamSegmentsQuery, FetchTeamSegmentsQueryVariables>(
    FetchTeamSegmentsDocument,
    options,
  );
}
export type FetchTeamSegmentsQueryHookResult = ReturnType<typeof useFetchTeamSegmentsQuery>;
export type FetchTeamSegmentsLazyQueryHookResult = ReturnType<typeof useFetchTeamSegmentsLazyQuery>;
export type FetchTeamSegmentsQueryResult = Apollo.QueryResult<
  FetchTeamSegmentsQuery,
  FetchTeamSegmentsQueryVariables
>;
export function refetchFetchTeamSegmentsQuery(variables: FetchTeamSegmentsQueryVariables) {
  return { query: FetchTeamSegmentsDocument, variables: variables };
}
export const FetchTeamTmpSettingsDocument = gql`
  query FetchTeamTMPSettings($tmpYearIdArg: Int!, $tmpTeamIdArg: Int!) {
    tmpTeamSettings(tmpYearIdArg: $tmpYearIdArg, tmpTeamIdArg: $tmpTeamIdArg) {
      edges {
        node {
          tmpActivityLogConfigId
          tmpTeamSettingsData {
            tmpConfigId
            autoGenerateLogs
            percentAthleteSigners
            calendarVisibilityUsers {
              label
              selectionCode
              ... on PersonNode {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
              }
              ... on SelectionGroup {
                groupType
                pluralLabel
                people {
                  id
                  pictureUrl
                  preferredName
                  lastName
                  initials
                  active
                  label
                  selectionCode
                }
              }
            }
            conflictNotificationsVisibilityUsers {
              label
              selectionCode
              ... on PersonNode {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
              }
              ... on SelectionGroup {
                groupType
                pluralLabel
                people {
                  id
                  pictureUrl
                  preferredName
                  lastName
                  initials
                  active
                  label
                  selectionCode
                }
              }
            }
            editDeclarationsVisibilityUsers {
              label
              selectionCode
              ... on PersonNode {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
              }
              ... on SelectionGroup {
                groupType
                pluralLabel
                people {
                  id
                  pictureUrl
                  preferredName
                  lastName
                  initials
                  active
                  label
                  selectionCode
                }
              }
            }
            logAdminUsers {
              label
              selectionCode
              ... on PersonNode {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
              }
              ... on SelectionGroup {
                groupType
                pluralLabel
                people {
                  id
                  pictureUrl
                  preferredName
                  lastName
                  initials
                  active
                  label
                  selectionCode
                }
              }
            }
            logViewerUsers {
              label
              selectionCode
              ... on PersonNode {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
              }
              ... on SelectionGroup {
                groupType
                pluralLabel
                people {
                  id
                  pictureUrl
                  preferredName
                  lastName
                  initials
                  active
                  label
                  selectionCode
                }
              }
            }
            logAthleteUsers {
              label
              selectionCode
              ... on PersonNode {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
              }
              ... on SelectionGroup {
                groupType
                pluralLabel
                people {
                  id
                  pictureUrl
                  preferredName
                  lastName
                  initials
                  active
                  label
                  selectionCode
                }
              }
            }
            logComplianceUsers {
              label
              selectionCode
              ... on PersonNode {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
              }
              ... on SelectionGroup {
                groupType
                pluralLabel
                people {
                  id
                  pictureUrl
                  preferredName
                  lastName
                  initials
                  active
                  label
                  selectionCode
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTeamTmpSettingsQuery__
 *
 * To run a query within a React component, call `useFetchTeamTmpSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTeamTmpSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTeamTmpSettingsQuery({
 *   variables: {
 *      tmpYearIdArg: // value for 'tmpYearIdArg'
 *      tmpTeamIdArg: // value for 'tmpTeamIdArg'
 *   },
 * });
 */
export function useFetchTeamTmpSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchTeamTmpSettingsQuery,
    FetchTeamTmpSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTeamTmpSettingsQuery, FetchTeamTmpSettingsQueryVariables>(
    FetchTeamTmpSettingsDocument,
    options,
  );
}
export function useFetchTeamTmpSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTeamTmpSettingsQuery,
    FetchTeamTmpSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTeamTmpSettingsQuery, FetchTeamTmpSettingsQueryVariables>(
    FetchTeamTmpSettingsDocument,
    options,
  );
}
export type FetchTeamTmpSettingsQueryHookResult = ReturnType<typeof useFetchTeamTmpSettingsQuery>;
export type FetchTeamTmpSettingsLazyQueryHookResult = ReturnType<
  typeof useFetchTeamTmpSettingsLazyQuery
>;
export type FetchTeamTmpSettingsQueryResult = Apollo.QueryResult<
  FetchTeamTmpSettingsQuery,
  FetchTeamTmpSettingsQueryVariables
>;
export function refetchFetchTeamTmpSettingsQuery(variables: FetchTeamTmpSettingsQueryVariables) {
  return { query: FetchTeamTmpSettingsDocument, variables: variables };
}
export const FetchTimeMgtPlanDocument = gql`
  query FetchTimeMgtPlan($filters: TmpTeamPlanFiltersInput) {
    tmpTeamPlan(filters: $filters) {
      tmpYear {
        tmpYearId
        label
      }
      tmpTeam {
        tmpTeamId
        team {
          label
        }
        org {
          orgId
          orgName
          logoUrl
        }
      }
      summary {
        tmpSegment {
          label
          seasonType
          segmentEndDate
          segmentStartDate
          totalWeeks
        }
        totalAthletes
      }
      reviewers {
        coach {
          label
        }
        sportsAdministrator {
          label
        }
        compliance {
          label
        }
        studentAthletes {
          label
        }
      }
      competitions {
        event
        dateString
        eventType
        countableHours
        location
      }
      countableEvents {
        event
        dateString
        eventType
        countableHours
        location
      }
      daysOff {
        event
        dateString
        eventType
        countableHours
        location
      }
    }
  }
`;

/**
 * __useFetchTimeMgtPlanQuery__
 *
 * To run a query within a React component, call `useFetchTimeMgtPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTimeMgtPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTimeMgtPlanQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFetchTimeMgtPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchTimeMgtPlanQuery, FetchTimeMgtPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTimeMgtPlanQuery, FetchTimeMgtPlanQueryVariables>(
    FetchTimeMgtPlanDocument,
    options,
  );
}
export function useFetchTimeMgtPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTimeMgtPlanQuery, FetchTimeMgtPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTimeMgtPlanQuery, FetchTimeMgtPlanQueryVariables>(
    FetchTimeMgtPlanDocument,
    options,
  );
}
export type FetchTimeMgtPlanQueryHookResult = ReturnType<typeof useFetchTimeMgtPlanQuery>;
export type FetchTimeMgtPlanLazyQueryHookResult = ReturnType<typeof useFetchTimeMgtPlanLazyQuery>;
export type FetchTimeMgtPlanQueryResult = Apollo.QueryResult<
  FetchTimeMgtPlanQuery,
  FetchTimeMgtPlanQueryVariables
>;
export function refetchFetchTimeMgtPlanQuery(variables?: FetchTimeMgtPlanQueryVariables) {
  return { query: FetchTimeMgtPlanDocument, variables: variables };
}
export const UpdateSegmentDocument = gql`
  mutation UpdateSegment($input: TmpTeamSegmentInput!, $id: ID!) {
    tmpTeamSegmentUpdate(input: $input, id: $id) {
      tmpTeamSegment {
        id
        metaData {
          daysOff
          selectedNonCountableDays
        }
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type UpdateSegmentMutationFn = Apollo.MutationFunction<
  UpdateSegmentMutation,
  UpdateSegmentMutationVariables
>;

/**
 * __useUpdateSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSegmentMutation, { data, loading, error }] = useUpdateSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSegmentMutation, UpdateSegmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(
    UpdateSegmentDocument,
    options,
  );
}
export type UpdateSegmentMutationHookResult = ReturnType<typeof useUpdateSegmentMutation>;
export type UpdateSegmentMutationResult = Apollo.MutationResult<UpdateSegmentMutation>;
export type UpdateSegmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateSegmentMutation,
  UpdateSegmentMutationVariables
>;
export const UpdateTeamTmpSettingsDocument = gql`
  mutation UpdateTeamTMPSettings($input: TmpTeamSettingsInput!) {
    tmpSettingsUpdate(input: $input) {
      tmpActivityLogConfig {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type UpdateTeamTmpSettingsMutationFn = Apollo.MutationFunction<
  UpdateTeamTmpSettingsMutation,
  UpdateTeamTmpSettingsMutationVariables
>;

/**
 * __useUpdateTeamTmpSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTeamTmpSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamTmpSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamTmpSettingsMutation, { data, loading, error }] = useUpdateTeamTmpSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamTmpSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamTmpSettingsMutation,
    UpdateTeamTmpSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamTmpSettingsMutation, UpdateTeamTmpSettingsMutationVariables>(
    UpdateTeamTmpSettingsDocument,
    options,
  );
}
export type UpdateTeamTmpSettingsMutationHookResult = ReturnType<
  typeof useUpdateTeamTmpSettingsMutation
>;
export type UpdateTeamTmpSettingsMutationResult =
  Apollo.MutationResult<UpdateTeamTmpSettingsMutation>;
export type UpdateTeamTmpSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamTmpSettingsMutation,
  UpdateTeamTmpSettingsMutationVariables
>;
export const TravelCredentialCreateDocument = gql`
  mutation TravelCredentialCreate($input: TravelCredentialInput) {
    travelCredentialCreate(input: $input) {
      travelCredential {
        label
        isActive
      }
    }
  }
`;
export type TravelCredentialCreateMutationFn = Apollo.MutationFunction<
  TravelCredentialCreateMutation,
  TravelCredentialCreateMutationVariables
>;

/**
 * __useTravelCredentialCreateMutation__
 *
 * To run a mutation, you first call `useTravelCredentialCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelCredentialCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelCredentialCreateMutation, { data, loading, error }] = useTravelCredentialCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelCredentialCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelCredentialCreateMutation,
    TravelCredentialCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelCredentialCreateMutation,
    TravelCredentialCreateMutationVariables
  >(TravelCredentialCreateDocument, options);
}
export type TravelCredentialCreateMutationHookResult = ReturnType<
  typeof useTravelCredentialCreateMutation
>;
export type TravelCredentialCreateMutationResult =
  Apollo.MutationResult<TravelCredentialCreateMutation>;
export type TravelCredentialCreateMutationOptions = Apollo.BaseMutationOptions<
  TravelCredentialCreateMutation,
  TravelCredentialCreateMutationVariables
>;
export const TravelCredentialDeleteDocument = gql`
  mutation TravelCredentialDelete($id: ID!) {
    travelCredentialDelete(id: $id) {
      travelCredential {
        label
        isActive
      }
    }
  }
`;
export type TravelCredentialDeleteMutationFn = Apollo.MutationFunction<
  TravelCredentialDeleteMutation,
  TravelCredentialDeleteMutationVariables
>;

/**
 * __useTravelCredentialDeleteMutation__
 *
 * To run a mutation, you first call `useTravelCredentialDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelCredentialDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelCredentialDeleteMutation, { data, loading, error }] = useTravelCredentialDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTravelCredentialDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelCredentialDeleteMutation,
    TravelCredentialDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelCredentialDeleteMutation,
    TravelCredentialDeleteMutationVariables
  >(TravelCredentialDeleteDocument, options);
}
export type TravelCredentialDeleteMutationHookResult = ReturnType<
  typeof useTravelCredentialDeleteMutation
>;
export type TravelCredentialDeleteMutationResult =
  Apollo.MutationResult<TravelCredentialDeleteMutation>;
export type TravelCredentialDeleteMutationOptions = Apollo.BaseMutationOptions<
  TravelCredentialDeleteMutation,
  TravelCredentialDeleteMutationVariables
>;
export const TravelCredentialUpdateDocument = gql`
  mutation TravelCredentialUpdate($input: TravelCredentialInput, $id: ID!) {
    travelCredentialUpdate(input: $input, id: $id) {
      travelCredential {
        label
        isActive
      }
    }
  }
`;
export type TravelCredentialUpdateMutationFn = Apollo.MutationFunction<
  TravelCredentialUpdateMutation,
  TravelCredentialUpdateMutationVariables
>;

/**
 * __useTravelCredentialUpdateMutation__
 *
 * To run a mutation, you first call `useTravelCredentialUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelCredentialUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelCredentialUpdateMutation, { data, loading, error }] = useTravelCredentialUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTravelCredentialUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelCredentialUpdateMutation,
    TravelCredentialUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelCredentialUpdateMutation,
    TravelCredentialUpdateMutationVariables
  >(TravelCredentialUpdateDocument, options);
}
export type TravelCredentialUpdateMutationHookResult = ReturnType<
  typeof useTravelCredentialUpdateMutation
>;
export type TravelCredentialUpdateMutationResult =
  Apollo.MutationResult<TravelCredentialUpdateMutation>;
export type TravelCredentialUpdateMutationOptions = Apollo.BaseMutationOptions<
  TravelCredentialUpdateMutation,
  TravelCredentialUpdateMutationVariables
>;
export const TravelCredentialOptionsDocument = gql`
  query TravelCredentialOptions {
    travelCredentialOptions(sort: label_asc) {
      edges {
        node {
          id
          label
          isActive
        }
      }
    }
  }
`;

/**
 * __useTravelCredentialOptionsQuery__
 *
 * To run a query within a React component, call `useTravelCredentialOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelCredentialOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelCredentialOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTravelCredentialOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TravelCredentialOptionsQuery,
    TravelCredentialOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelCredentialOptionsQuery, TravelCredentialOptionsQueryVariables>(
    TravelCredentialOptionsDocument,
    options,
  );
}
export function useTravelCredentialOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelCredentialOptionsQuery,
    TravelCredentialOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TravelCredentialOptionsQuery, TravelCredentialOptionsQueryVariables>(
    TravelCredentialOptionsDocument,
    options,
  );
}
export type TravelCredentialOptionsQueryHookResult = ReturnType<
  typeof useTravelCredentialOptionsQuery
>;
export type TravelCredentialOptionsLazyQueryHookResult = ReturnType<
  typeof useTravelCredentialOptionsLazyQuery
>;
export type TravelCredentialOptionsQueryResult = Apollo.QueryResult<
  TravelCredentialOptionsQuery,
  TravelCredentialOptionsQueryVariables
>;
export function refetchTravelCredentialOptionsQuery(
  variables?: TravelCredentialOptionsQueryVariables,
) {
  return { query: TravelCredentialOptionsDocument, variables: variables };
}
export const FetchMyDressOptionsDocument = gql`
  query FetchMyDressOptions {
    travelDressOptions(sort: label_asc) {
      edges {
        node {
          id
          travelDressId
          label
          isDefault
          isActive
        }
      }
    }
  }
`;

/**
 * __useFetchMyDressOptionsQuery__
 *
 * To run a query within a React component, call `useFetchMyDressOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyDressOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyDressOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMyDressOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMyDressOptionsQuery,
    FetchMyDressOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMyDressOptionsQuery, FetchMyDressOptionsQueryVariables>(
    FetchMyDressOptionsDocument,
    options,
  );
}
export function useFetchMyDressOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMyDressOptionsQuery,
    FetchMyDressOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMyDressOptionsQuery, FetchMyDressOptionsQueryVariables>(
    FetchMyDressOptionsDocument,
    options,
  );
}
export type FetchMyDressOptionsQueryHookResult = ReturnType<typeof useFetchMyDressOptionsQuery>;
export type FetchMyDressOptionsLazyQueryHookResult = ReturnType<
  typeof useFetchMyDressOptionsLazyQuery
>;
export type FetchMyDressOptionsQueryResult = Apollo.QueryResult<
  FetchMyDressOptionsQuery,
  FetchMyDressOptionsQueryVariables
>;
export function refetchFetchMyDressOptionsQuery(variables?: FetchMyDressOptionsQueryVariables) {
  return { query: FetchMyDressOptionsDocument, variables: variables };
}
export const TravelDressCreateDocument = gql`
  mutation TravelDressCreate($input: TravelDressInput) {
    travelDressCreate(input: $input) {
      travelDress {
        id
      }
      userErrors {
        message
      }
    }
  }
`;
export type TravelDressCreateMutationFn = Apollo.MutationFunction<
  TravelDressCreateMutation,
  TravelDressCreateMutationVariables
>;

/**
 * __useTravelDressCreateMutation__
 *
 * To run a mutation, you first call `useTravelDressCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelDressCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelDressCreateMutation, { data, loading, error }] = useTravelDressCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelDressCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelDressCreateMutation,
    TravelDressCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelDressCreateMutation, TravelDressCreateMutationVariables>(
    TravelDressCreateDocument,
    options,
  );
}
export type TravelDressCreateMutationHookResult = ReturnType<typeof useTravelDressCreateMutation>;
export type TravelDressCreateMutationResult = Apollo.MutationResult<TravelDressCreateMutation>;
export type TravelDressCreateMutationOptions = Apollo.BaseMutationOptions<
  TravelDressCreateMutation,
  TravelDressCreateMutationVariables
>;
export const TravelDressDeleteDocument = gql`
  mutation TravelDressDelete($id: ID!) {
    travelDressDelete(id: $id) {
      travelDress {
        id
      }
      userErrors {
        message
      }
    }
  }
`;
export type TravelDressDeleteMutationFn = Apollo.MutationFunction<
  TravelDressDeleteMutation,
  TravelDressDeleteMutationVariables
>;

/**
 * __useTravelDressDeleteMutation__
 *
 * To run a mutation, you first call `useTravelDressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelDressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelDressDeleteMutation, { data, loading, error }] = useTravelDressDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTravelDressDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelDressDeleteMutation,
    TravelDressDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelDressDeleteMutation, TravelDressDeleteMutationVariables>(
    TravelDressDeleteDocument,
    options,
  );
}
export type TravelDressDeleteMutationHookResult = ReturnType<typeof useTravelDressDeleteMutation>;
export type TravelDressDeleteMutationResult = Apollo.MutationResult<TravelDressDeleteMutation>;
export type TravelDressDeleteMutationOptions = Apollo.BaseMutationOptions<
  TravelDressDeleteMutation,
  TravelDressDeleteMutationVariables
>;
export const TravelDressEditDocument = gql`
  mutation TravelDressEdit($id: ID!, $input: TravelDressInput) {
    travelDressUpdate(id: $id, input: $input) {
      travelDress {
        id
      }
      userErrors {
        message
      }
    }
  }
`;
export type TravelDressEditMutationFn = Apollo.MutationFunction<
  TravelDressEditMutation,
  TravelDressEditMutationVariables
>;

/**
 * __useTravelDressEditMutation__
 *
 * To run a mutation, you first call `useTravelDressEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelDressEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelDressEditMutation, { data, loading, error }] = useTravelDressEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelDressEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelDressEditMutation,
    TravelDressEditMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelDressEditMutation, TravelDressEditMutationVariables>(
    TravelDressEditDocument,
    options,
  );
}
export type TravelDressEditMutationHookResult = ReturnType<typeof useTravelDressEditMutation>;
export type TravelDressEditMutationResult = Apollo.MutationResult<TravelDressEditMutation>;
export type TravelDressEditMutationOptions = Apollo.BaseMutationOptions<
  TravelDressEditMutation,
  TravelDressEditMutationVariables
>;
export const TravelSettingsFeaturesEditDocument = gql`
  mutation TravelSettingsFeaturesEdit($id: ID!, $input: TeamUpdateInput) {
    teamUpdate(id: $id, input: $input) {
      team {
        settings
      }
    }
  }
`;
export type TravelSettingsFeaturesEditMutationFn = Apollo.MutationFunction<
  TravelSettingsFeaturesEditMutation,
  TravelSettingsFeaturesEditMutationVariables
>;

/**
 * __useTravelSettingsFeaturesEditMutation__
 *
 * To run a mutation, you first call `useTravelSettingsFeaturesEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelSettingsFeaturesEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelSettingsFeaturesEditMutation, { data, loading, error }] = useTravelSettingsFeaturesEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelSettingsFeaturesEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelSettingsFeaturesEditMutation,
    TravelSettingsFeaturesEditMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelSettingsFeaturesEditMutation,
    TravelSettingsFeaturesEditMutationVariables
  >(TravelSettingsFeaturesEditDocument, options);
}
export type TravelSettingsFeaturesEditMutationHookResult = ReturnType<
  typeof useTravelSettingsFeaturesEditMutation
>;
export type TravelSettingsFeaturesEditMutationResult =
  Apollo.MutationResult<TravelSettingsFeaturesEditMutation>;
export type TravelSettingsFeaturesEditMutationOptions = Apollo.BaseMutationOptions<
  TravelSettingsFeaturesEditMutation,
  TravelSettingsFeaturesEditMutationVariables
>;
export const AddTravelVendorToGroupDocument = gql`
  mutation AddTravelVendorToGroup($input: TravelVendorGroupVendorLinkInput) {
    addTravelVendorToGroup(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type AddTravelVendorToGroupMutationFn = Apollo.MutationFunction<
  AddTravelVendorToGroupMutation,
  AddTravelVendorToGroupMutationVariables
>;

/**
 * __useAddTravelVendorToGroupMutation__
 *
 * To run a mutation, you first call `useAddTravelVendorToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTravelVendorToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTravelVendorToGroupMutation, { data, loading, error }] = useAddTravelVendorToGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTravelVendorToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTravelVendorToGroupMutation,
    AddTravelVendorToGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTravelVendorToGroupMutation,
    AddTravelVendorToGroupMutationVariables
  >(AddTravelVendorToGroupDocument, options);
}
export type AddTravelVendorToGroupMutationHookResult = ReturnType<
  typeof useAddTravelVendorToGroupMutation
>;
export type AddTravelVendorToGroupMutationResult =
  Apollo.MutationResult<AddTravelVendorToGroupMutation>;
export type AddTravelVendorToGroupMutationOptions = Apollo.BaseMutationOptions<
  AddTravelVendorToGroupMutation,
  AddTravelVendorToGroupMutationVariables
>;
export const TripTravelPartyMemberBusAssignmentsDocument = gql`
  query TripTravelPartyMemberBusAssignments($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        logoUrl
        label
        seatplans {
          seatplanJson
          label
          travelVendorType {
            label
          }
        }
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            active
            firstName
            lastName
            preferredName
            suffix
            teamMembership {
              teamId
              isAthlete
              athleteStatus {
                label
              }
              positionLabelsShort
              userTypes {
                namePlural
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTripTravelPartyMemberBusAssignmentsQuery__
 *
 * To run a query within a React component, call `useTripTravelPartyMemberBusAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripTravelPartyMemberBusAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripTravelPartyMemberBusAssignmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTripTravelPartyMemberBusAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TripTravelPartyMemberBusAssignmentsQuery,
    TripTravelPartyMemberBusAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TripTravelPartyMemberBusAssignmentsQuery,
    TripTravelPartyMemberBusAssignmentsQueryVariables
  >(TripTravelPartyMemberBusAssignmentsDocument, options);
}
export function useTripTravelPartyMemberBusAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripTravelPartyMemberBusAssignmentsQuery,
    TripTravelPartyMemberBusAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TripTravelPartyMemberBusAssignmentsQuery,
    TripTravelPartyMemberBusAssignmentsQueryVariables
  >(TripTravelPartyMemberBusAssignmentsDocument, options);
}
export type TripTravelPartyMemberBusAssignmentsQueryHookResult = ReturnType<
  typeof useTripTravelPartyMemberBusAssignmentsQuery
>;
export type TripTravelPartyMemberBusAssignmentsLazyQueryHookResult = ReturnType<
  typeof useTripTravelPartyMemberBusAssignmentsLazyQuery
>;
export type TripTravelPartyMemberBusAssignmentsQueryResult = Apollo.QueryResult<
  TripTravelPartyMemberBusAssignmentsQuery,
  TripTravelPartyMemberBusAssignmentsQueryVariables
>;
export function refetchTripTravelPartyMemberBusAssignmentsQuery(
  variables: TripTravelPartyMemberBusAssignmentsQueryVariables,
) {
  return { query: TripTravelPartyMemberBusAssignmentsDocument, variables: variables };
}
export const TravelAllTripCredAssignmentsDocument = gql`
  query TravelAllTripCredAssignments($tripIdFilter: ID!, $onlyCompetitionsFilter: Boolean) {
    travelEvents(tripIdFilter: $tripIdFilter, onlyCompetitionsFilter: $onlyCompetitionsFilter) {
      edges {
        node {
          trip {
            logoUrl
          }
          org {
            logoUrl
          }
          appointment {
            title
          }
          competition {
            pk
            travelCredentialAssignments {
              edges {
                node {
                  person {
                    firstName
                    lastName
                    preferredName
                    fullPreferredName
                  }
                  travelCredential {
                    label
                  }
                  notes
                  quantity
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTravelAllTripCredAssignmentsQuery__
 *
 * To run a query within a React component, call `useTravelAllTripCredAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAllTripCredAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAllTripCredAssignmentsQuery({
 *   variables: {
 *      tripIdFilter: // value for 'tripIdFilter'
 *      onlyCompetitionsFilter: // value for 'onlyCompetitionsFilter'
 *   },
 * });
 */
export function useTravelAllTripCredAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TravelAllTripCredAssignmentsQuery,
    TravelAllTripCredAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TravelAllTripCredAssignmentsQuery,
    TravelAllTripCredAssignmentsQueryVariables
  >(TravelAllTripCredAssignmentsDocument, options);
}
export function useTravelAllTripCredAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelAllTripCredAssignmentsQuery,
    TravelAllTripCredAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TravelAllTripCredAssignmentsQuery,
    TravelAllTripCredAssignmentsQueryVariables
  >(TravelAllTripCredAssignmentsDocument, options);
}
export type TravelAllTripCredAssignmentsQueryHookResult = ReturnType<
  typeof useTravelAllTripCredAssignmentsQuery
>;
export type TravelAllTripCredAssignmentsLazyQueryHookResult = ReturnType<
  typeof useTravelAllTripCredAssignmentsLazyQuery
>;
export type TravelAllTripCredAssignmentsQueryResult = Apollo.QueryResult<
  TravelAllTripCredAssignmentsQuery,
  TravelAllTripCredAssignmentsQueryVariables
>;
export function refetchTravelAllTripCredAssignmentsQuery(
  variables: TravelAllTripCredAssignmentsQueryVariables,
) {
  return { query: TravelAllTripCredAssignmentsDocument, variables: variables };
}
export const UpdateTravelDocumentConfigDocument = gql`
  mutation updateTravelDocumentConfig($input: TravelDocumentConfigInput!) {
    travelDocumentConfigUpsert(input: $input) {
      travelDocumentConfig {
        config
      }
    }
  }
`;
export type UpdateTravelDocumentConfigMutationFn = Apollo.MutationFunction<
  UpdateTravelDocumentConfigMutation,
  UpdateTravelDocumentConfigMutationVariables
>;

/**
 * __useUpdateTravelDocumentConfigMutation__
 *
 * To run a mutation, you first call `useUpdateTravelDocumentConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTravelDocumentConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTravelDocumentConfigMutation, { data, loading, error }] = useUpdateTravelDocumentConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTravelDocumentConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTravelDocumentConfigMutation,
    UpdateTravelDocumentConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTravelDocumentConfigMutation,
    UpdateTravelDocumentConfigMutationVariables
  >(UpdateTravelDocumentConfigDocument, options);
}
export type UpdateTravelDocumentConfigMutationHookResult = ReturnType<
  typeof useUpdateTravelDocumentConfigMutation
>;
export type UpdateTravelDocumentConfigMutationResult =
  Apollo.MutationResult<UpdateTravelDocumentConfigMutation>;
export type UpdateTravelDocumentConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateTravelDocumentConfigMutation,
  UpdateTravelDocumentConfigMutationVariables
>;
export const FetchTravelDocumentConfigsDocument = gql`
  query FetchTravelDocumentConfigs {
    travelDocumentConfigs {
      edges {
        node {
          config
        }
      }
    }
  }
`;

/**
 * __useFetchTravelDocumentConfigsQuery__
 *
 * To run a query within a React component, call `useFetchTravelDocumentConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTravelDocumentConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTravelDocumentConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTravelDocumentConfigsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchTravelDocumentConfigsQuery,
    FetchTravelDocumentConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTravelDocumentConfigsQuery, FetchTravelDocumentConfigsQueryVariables>(
    FetchTravelDocumentConfigsDocument,
    options,
  );
}
export function useFetchTravelDocumentConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTravelDocumentConfigsQuery,
    FetchTravelDocumentConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchTravelDocumentConfigsQuery,
    FetchTravelDocumentConfigsQueryVariables
  >(FetchTravelDocumentConfigsDocument, options);
}
export type FetchTravelDocumentConfigsQueryHookResult = ReturnType<
  typeof useFetchTravelDocumentConfigsQuery
>;
export type FetchTravelDocumentConfigsLazyQueryHookResult = ReturnType<
  typeof useFetchTravelDocumentConfigsLazyQuery
>;
export type FetchTravelDocumentConfigsQueryResult = Apollo.QueryResult<
  FetchTravelDocumentConfigsQuery,
  FetchTravelDocumentConfigsQueryVariables
>;
export function refetchFetchTravelDocumentConfigsQuery(
  variables?: FetchTravelDocumentConfigsQueryVariables,
) {
  return { query: FetchTravelDocumentConfigsDocument, variables: variables };
}
export const TripTravelPartyDressReportDocument = gql`
  query TripTravelPartyDressReport($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        label
        logoUrl
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            active
            firstName
            preferredName
            lastName
            middleName
            suffix
            dateOfBirth
            gender
            travelWeight
            mobilePhone
            teamMembership {
              userTypes {
                namePlural
              }
            }
          }
        }
        travelPartyPersons {
          travelDress
          person {
            ... on PersonNode {
              pk
              firstName
              lastName
              teamMembership {
                userTypes {
                  namePlural
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTripTravelPartyDressReportQuery__
 *
 * To run a query within a React component, call `useTripTravelPartyDressReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripTravelPartyDressReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripTravelPartyDressReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTripTravelPartyDressReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    TripTravelPartyDressReportQuery,
    TripTravelPartyDressReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripTravelPartyDressReportQuery, TripTravelPartyDressReportQueryVariables>(
    TripTravelPartyDressReportDocument,
    options,
  );
}
export function useTripTravelPartyDressReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripTravelPartyDressReportQuery,
    TripTravelPartyDressReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TripTravelPartyDressReportQuery,
    TripTravelPartyDressReportQueryVariables
  >(TripTravelPartyDressReportDocument, options);
}
export type TripTravelPartyDressReportQueryHookResult = ReturnType<
  typeof useTripTravelPartyDressReportQuery
>;
export type TripTravelPartyDressReportLazyQueryHookResult = ReturnType<
  typeof useTripTravelPartyDressReportLazyQuery
>;
export type TripTravelPartyDressReportQueryResult = Apollo.QueryResult<
  TripTravelPartyDressReportQuery,
  TripTravelPartyDressReportQueryVariables
>;
export function refetchTripTravelPartyDressReportQuery(
  variables: TripTravelPartyDressReportQueryVariables,
) {
  return { query: TripTravelPartyDressReportDocument, variables: variables };
}
export const FetchFiltersForTravelExportDocument = gql`
  query FetchFiltersForTravelExport {
    attributeCategories {
      label
      attributes {
        id
        label
        dataType
        optionsList
        coreCode
      }
    }
  }
`;

/**
 * __useFetchFiltersForTravelExportQuery__
 *
 * To run a query within a React component, call `useFetchFiltersForTravelExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFiltersForTravelExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFiltersForTravelExportQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchFiltersForTravelExportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchFiltersForTravelExportQuery,
    FetchFiltersForTravelExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchFiltersForTravelExportQuery,
    FetchFiltersForTravelExportQueryVariables
  >(FetchFiltersForTravelExportDocument, options);
}
export function useFetchFiltersForTravelExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchFiltersForTravelExportQuery,
    FetchFiltersForTravelExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchFiltersForTravelExportQuery,
    FetchFiltersForTravelExportQueryVariables
  >(FetchFiltersForTravelExportDocument, options);
}
export type FetchFiltersForTravelExportQueryHookResult = ReturnType<
  typeof useFetchFiltersForTravelExportQuery
>;
export type FetchFiltersForTravelExportLazyQueryHookResult = ReturnType<
  typeof useFetchFiltersForTravelExportLazyQuery
>;
export type FetchFiltersForTravelExportQueryResult = Apollo.QueryResult<
  FetchFiltersForTravelExportQuery,
  FetchFiltersForTravelExportQueryVariables
>;
export function refetchFetchFiltersForTravelExportQuery(
  variables?: FetchFiltersForTravelExportQueryVariables,
) {
  return { query: FetchFiltersForTravelExportDocument, variables: variables };
}
export const FlightManifestExportDocument = gql`
  query FlightManifestExport($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        logoUrl
        label
        seatplans {
          id
          seatplanJson
          label
          travelVendorType {
            label
          }
        }
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            pictureUrl
            active
            firstName
            middleName
            lastName
            suffix
            preferredName
            initials
            selectionCode
            isContact
            dateOfBirth
            gender
            travelWeight
            teamMembership {
              athleteStatus {
                label
              }
              userTypes {
                namePlural
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFlightManifestExportQuery__
 *
 * To run a query within a React component, call `useFlightManifestExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlightManifestExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlightManifestExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFlightManifestExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    FlightManifestExportQuery,
    FlightManifestExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlightManifestExportQuery, FlightManifestExportQueryVariables>(
    FlightManifestExportDocument,
    options,
  );
}
export function useFlightManifestExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FlightManifestExportQuery,
    FlightManifestExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlightManifestExportQuery, FlightManifestExportQueryVariables>(
    FlightManifestExportDocument,
    options,
  );
}
export type FlightManifestExportQueryHookResult = ReturnType<typeof useFlightManifestExportQuery>;
export type FlightManifestExportLazyQueryHookResult = ReturnType<
  typeof useFlightManifestExportLazyQuery
>;
export type FlightManifestExportQueryResult = Apollo.QueryResult<
  FlightManifestExportQuery,
  FlightManifestExportQueryVariables
>;
export function refetchFlightManifestExportQuery(variables: FlightManifestExportQueryVariables) {
  return { query: FlightManifestExportDocument, variables: variables };
}
export const FlightSeatDiagramExportDocument = gql`
  query FlightSeatDiagramExport($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        id
        logoUrl
        label
        seatplans {
          id
          seatplanJson
          label
          travelVendorType {
            label
          }
        }
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            active
            firstName
            lastName
            preferredName
            suffix
            teamMembership {
              active
              userTypes {
                name
                color
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFlightSeatDiagramExportQuery__
 *
 * To run a query within a React component, call `useFlightSeatDiagramExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlightSeatDiagramExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlightSeatDiagramExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFlightSeatDiagramExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    FlightSeatDiagramExportQuery,
    FlightSeatDiagramExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlightSeatDiagramExportQuery, FlightSeatDiagramExportQueryVariables>(
    FlightSeatDiagramExportDocument,
    options,
  );
}
export function useFlightSeatDiagramExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FlightSeatDiagramExportQuery,
    FlightSeatDiagramExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlightSeatDiagramExportQuery, FlightSeatDiagramExportQueryVariables>(
    FlightSeatDiagramExportDocument,
    options,
  );
}
export type FlightSeatDiagramExportQueryHookResult = ReturnType<
  typeof useFlightSeatDiagramExportQuery
>;
export type FlightSeatDiagramExportLazyQueryHookResult = ReturnType<
  typeof useFlightSeatDiagramExportLazyQuery
>;
export type FlightSeatDiagramExportQueryResult = Apollo.QueryResult<
  FlightSeatDiagramExportQuery,
  FlightSeatDiagramExportQueryVariables
>;
export function refetchFlightSeatDiagramExportQuery(
  variables: FlightSeatDiagramExportQueryVariables,
) {
  return { query: FlightSeatDiagramExportDocument, variables: variables };
}
export const TripHotelRoomAssignmentsDocument = gql`
  query TripHotelRoomAssignments($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        logoUrl
        label
        seatplans {
          id
          seatplanJson
          label
          travelVendorType {
            label
          }
        }
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            active
            firstName
            lastName
            preferredName
            suffix
            teamMembership {
              active
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTripHotelRoomAssignmentsQuery__
 *
 * To run a query within a React component, call `useTripHotelRoomAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripHotelRoomAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripHotelRoomAssignmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTripHotelRoomAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TripHotelRoomAssignmentsQuery,
    TripHotelRoomAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripHotelRoomAssignmentsQuery, TripHotelRoomAssignmentsQueryVariables>(
    TripHotelRoomAssignmentsDocument,
    options,
  );
}
export function useTripHotelRoomAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripHotelRoomAssignmentsQuery,
    TripHotelRoomAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripHotelRoomAssignmentsQuery, TripHotelRoomAssignmentsQueryVariables>(
    TripHotelRoomAssignmentsDocument,
    options,
  );
}
export type TripHotelRoomAssignmentsQueryHookResult = ReturnType<
  typeof useTripHotelRoomAssignmentsQuery
>;
export type TripHotelRoomAssignmentsLazyQueryHookResult = ReturnType<
  typeof useTripHotelRoomAssignmentsLazyQuery
>;
export type TripHotelRoomAssignmentsQueryResult = Apollo.QueryResult<
  TripHotelRoomAssignmentsQuery,
  TripHotelRoomAssignmentsQueryVariables
>;
export function refetchTripHotelRoomAssignmentsQuery(
  variables: TripHotelRoomAssignmentsQueryVariables,
) {
  return { query: TripHotelRoomAssignmentsDocument, variables: variables };
}
export const HotelMasterExportDocument = gql`
  query HotelMasterExport($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        label
        logoUrl
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            active
            firstName
            preferredName
            lastName
            middleName
            suffix
            dateOfBirth
            gender
            travelWeight
            mobilePhone
            teamMembership {
              userTypes {
                namePlural
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useHotelMasterExportQuery__
 *
 * To run a query within a React component, call `useHotelMasterExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelMasterExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelMasterExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHotelMasterExportQuery(
  baseOptions: Apollo.QueryHookOptions<HotelMasterExportQuery, HotelMasterExportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HotelMasterExportQuery, HotelMasterExportQueryVariables>(
    HotelMasterExportDocument,
    options,
  );
}
export function useHotelMasterExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HotelMasterExportQuery,
    HotelMasterExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HotelMasterExportQuery, HotelMasterExportQueryVariables>(
    HotelMasterExportDocument,
    options,
  );
}
export type HotelMasterExportQueryHookResult = ReturnType<typeof useHotelMasterExportQuery>;
export type HotelMasterExportLazyQueryHookResult = ReturnType<typeof useHotelMasterExportLazyQuery>;
export type HotelMasterExportQueryResult = Apollo.QueryResult<
  HotelMasterExportQuery,
  HotelMasterExportQueryVariables
>;
export function refetchHotelMasterExportQuery(variables: HotelMasterExportQueryVariables) {
  return { query: HotelMasterExportDocument, variables: variables };
}
export const TravelItineraryDocument = gql`
  query TravelItinerary($tripIdFilter: ID!) {
    travelEvents(tripIdFilter: $tripIdFilter) {
      edges {
        travelEvent: node {
          travelEventId
          timeZone
          appointment {
            title
            startDatetimeUtc
            endDatetimeUtc
            location
            notes
          }
          competition {
            opponentInstitution {
              nickname
              city
              state
            }
            awayInstitution {
              nickname
              city
              state
            }
            homeInstitution {
              nickname
              city
              state
            }
            hasCustomOpponent
            customOpponent
          }
        }
      }
    }
  }
`;

/**
 * __useTravelItineraryQuery__
 *
 * To run a query within a React component, call `useTravelItineraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelItineraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelItineraryQuery({
 *   variables: {
 *      tripIdFilter: // value for 'tripIdFilter'
 *   },
 * });
 */
export function useTravelItineraryQuery(
  baseOptions: Apollo.QueryHookOptions<TravelItineraryQuery, TravelItineraryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelItineraryQuery, TravelItineraryQueryVariables>(
    TravelItineraryDocument,
    options,
  );
}
export function useTravelItineraryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TravelItineraryQuery, TravelItineraryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TravelItineraryQuery, TravelItineraryQueryVariables>(
    TravelItineraryDocument,
    options,
  );
}
export type TravelItineraryQueryHookResult = ReturnType<typeof useTravelItineraryQuery>;
export type TravelItineraryLazyQueryHookResult = ReturnType<typeof useTravelItineraryLazyQuery>;
export type TravelItineraryQueryResult = Apollo.QueryResult<
  TravelItineraryQuery,
  TravelItineraryQueryVariables
>;
export function refetchTravelItineraryQuery(variables: TravelItineraryQueryVariables) {
  return { query: TravelItineraryDocument, variables: variables };
}
export const TripTravelPartyPerDiemsDocument = gql`
  query TripTravelPartyPerDiems($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        label
        logoUrl
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            active
            firstName
            preferredName
            lastName
            middleName
            suffix
            dateOfBirth
            gender
            travelWeight
            mobilePhone
            teamMembership {
              userTypes {
                namePlural
              }
            }
          }
        }
        travelPartyPersons {
          perDiem
          person {
            ... on PersonNode {
              pk
              firstName
              lastName
              teamMembership {
                userTypes {
                  namePlural
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTripTravelPartyPerDiemsQuery__
 *
 * To run a query within a React component, call `useTripTravelPartyPerDiemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripTravelPartyPerDiemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripTravelPartyPerDiemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTripTravelPartyPerDiemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TripTravelPartyPerDiemsQuery,
    TripTravelPartyPerDiemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripTravelPartyPerDiemsQuery, TripTravelPartyPerDiemsQueryVariables>(
    TripTravelPartyPerDiemsDocument,
    options,
  );
}
export function useTripTravelPartyPerDiemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripTravelPartyPerDiemsQuery,
    TripTravelPartyPerDiemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripTravelPartyPerDiemsQuery, TripTravelPartyPerDiemsQueryVariables>(
    TripTravelPartyPerDiemsDocument,
    options,
  );
}
export type TripTravelPartyPerDiemsQueryHookResult = ReturnType<
  typeof useTripTravelPartyPerDiemsQuery
>;
export type TripTravelPartyPerDiemsLazyQueryHookResult = ReturnType<
  typeof useTripTravelPartyPerDiemsLazyQuery
>;
export type TripTravelPartyPerDiemsQueryResult = Apollo.QueryResult<
  TripTravelPartyPerDiemsQuery,
  TripTravelPartyPerDiemsQueryVariables
>;
export function refetchTripTravelPartyPerDiemsQuery(
  variables: TripTravelPartyPerDiemsQueryVariables,
) {
  return { query: TripTravelPartyPerDiemsDocument, variables: variables };
}
export const TravelAllTripPregameItemsDocument = gql`
  query TravelAllTripPregameItems($tripIdFilter: ID!, $onlyCompetitionsFilter: Boolean) {
    travelEvents(tripIdFilter: $tripIdFilter, onlyCompetitionsFilter: $onlyCompetitionsFilter) {
      edges {
        node {
          trip {
            logoUrl
          }
          org {
            logoUrl
          }
          timeZone
          appointment {
            title
            startDatetimeLocal
          }
          competition {
            travelPregameItems {
              edges {
                node {
                  title
                  secondsBeforeStart
                  minutesBeforeStart
                  timeBeforeStartSeconds
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTravelAllTripPregameItemsQuery__
 *
 * To run a query within a React component, call `useTravelAllTripPregameItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAllTripPregameItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAllTripPregameItemsQuery({
 *   variables: {
 *      tripIdFilter: // value for 'tripIdFilter'
 *      onlyCompetitionsFilter: // value for 'onlyCompetitionsFilter'
 *   },
 * });
 */
export function useTravelAllTripPregameItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TravelAllTripPregameItemsQuery,
    TravelAllTripPregameItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelAllTripPregameItemsQuery, TravelAllTripPregameItemsQueryVariables>(
    TravelAllTripPregameItemsDocument,
    options,
  );
}
export function useTravelAllTripPregameItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelAllTripPregameItemsQuery,
    TravelAllTripPregameItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TravelAllTripPregameItemsQuery,
    TravelAllTripPregameItemsQueryVariables
  >(TravelAllTripPregameItemsDocument, options);
}
export type TravelAllTripPregameItemsQueryHookResult = ReturnType<
  typeof useTravelAllTripPregameItemsQuery
>;
export type TravelAllTripPregameItemsLazyQueryHookResult = ReturnType<
  typeof useTravelAllTripPregameItemsLazyQuery
>;
export type TravelAllTripPregameItemsQueryResult = Apollo.QueryResult<
  TravelAllTripPregameItemsQuery,
  TravelAllTripPregameItemsQueryVariables
>;
export function refetchTravelAllTripPregameItemsQuery(
  variables: TravelAllTripPregameItemsQueryVariables,
) {
  return { query: TravelAllTripPregameItemsDocument, variables: variables };
}
export const RemoveTravelVendorFromGroupDocument = gql`
  mutation RemoveTravelVendorFromGroup($input: TravelVendorGroupVendorLinkInput) {
    removeTravelVendorFromGroup(input: $input) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type RemoveTravelVendorFromGroupMutationFn = Apollo.MutationFunction<
  RemoveTravelVendorFromGroupMutation,
  RemoveTravelVendorFromGroupMutationVariables
>;

/**
 * __useRemoveTravelVendorFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveTravelVendorFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTravelVendorFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTravelVendorFromGroupMutation, { data, loading, error }] = useRemoveTravelVendorFromGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTravelVendorFromGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTravelVendorFromGroupMutation,
    RemoveTravelVendorFromGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveTravelVendorFromGroupMutation,
    RemoveTravelVendorFromGroupMutationVariables
  >(RemoveTravelVendorFromGroupDocument, options);
}
export type RemoveTravelVendorFromGroupMutationHookResult = ReturnType<
  typeof useRemoveTravelVendorFromGroupMutation
>;
export type RemoveTravelVendorFromGroupMutationResult =
  Apollo.MutationResult<RemoveTravelVendorFromGroupMutation>;
export type RemoveTravelVendorFromGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveTravelVendorFromGroupMutation,
  RemoveTravelVendorFromGroupMutationVariables
>;
export const TravelPartyExportDocument = gql`
  query TravelPartyExport($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        label
        logoUrl
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            active
            firstName
            preferredName
            lastName
            middleName
            suffix
            dateOfBirth
            gender
            travelWeight
            mobilePhone
            teamMembership {
              userTypes {
                namePlural
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTravelPartyExportQuery__
 *
 * To run a query within a React component, call `useTravelPartyExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelPartyExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelPartyExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTravelPartyExportQuery(
  baseOptions: Apollo.QueryHookOptions<TravelPartyExportQuery, TravelPartyExportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelPartyExportQuery, TravelPartyExportQueryVariables>(
    TravelPartyExportDocument,
    options,
  );
}
export function useTravelPartyExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelPartyExportQuery,
    TravelPartyExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TravelPartyExportQuery, TravelPartyExportQueryVariables>(
    TravelPartyExportDocument,
    options,
  );
}
export type TravelPartyExportQueryHookResult = ReturnType<typeof useTravelPartyExportQuery>;
export type TravelPartyExportLazyQueryHookResult = ReturnType<typeof useTravelPartyExportLazyQuery>;
export type TravelPartyExportQueryResult = Apollo.QueryResult<
  TravelPartyExportQuery,
  TravelPartyExportQueryVariables
>;
export function refetchTravelPartyExportQuery(variables: TravelPartyExportQueryVariables) {
  return { query: TravelPartyExportDocument, variables: variables };
}
export const TravelerAssignmentsExportDocument = gql`
  query TravelerAssignmentsExport($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        label
        logoUrl
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            active
            firstName
            preferredName
            lastName
            middleName
            suffix
            dateOfBirth
            gender
            travelWeight
            mobilePhone
            teamMembership {
              userTypes {
                namePlural
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTravelerAssignmentsExportQuery__
 *
 * To run a query within a React component, call `useTravelerAssignmentsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelerAssignmentsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelerAssignmentsExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTravelerAssignmentsExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    TravelerAssignmentsExportQuery,
    TravelerAssignmentsExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelerAssignmentsExportQuery, TravelerAssignmentsExportQueryVariables>(
    TravelerAssignmentsExportDocument,
    options,
  );
}
export function useTravelerAssignmentsExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelerAssignmentsExportQuery,
    TravelerAssignmentsExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TravelerAssignmentsExportQuery,
    TravelerAssignmentsExportQueryVariables
  >(TravelerAssignmentsExportDocument, options);
}
export type TravelerAssignmentsExportQueryHookResult = ReturnType<
  typeof useTravelerAssignmentsExportQuery
>;
export type TravelerAssignmentsExportLazyQueryHookResult = ReturnType<
  typeof useTravelerAssignmentsExportLazyQuery
>;
export type TravelerAssignmentsExportQueryResult = Apollo.QueryResult<
  TravelerAssignmentsExportQuery,
  TravelerAssignmentsExportQueryVariables
>;
export function refetchTravelerAssignmentsExportQuery(
  variables: TravelerAssignmentsExportQueryVariables,
) {
  return { query: TravelerAssignmentsExportDocument, variables: variables };
}
export const FetchTripDocument = gql`
  query FetchTrip($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        label
        seatplans {
          id
          seatplanJson
          label
          travelVendorType {
            id
          }
        }
        events {
          appointment {
            id
            title
            location
            startDate
          }
          competition {
            travelPregameItems {
              edges {
                node {
                  id
                }
              }
            }
            travelCredentialAssignments {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
        tasks {
          isCompleted
          taskName
          taskOrder
        }
        travelPartyMembers {
          ... on PersonNode {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTripQuery__
 *
 * To run a query within a React component, call `useFetchTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTripQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTripQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchTripQuery(
  baseOptions: Apollo.QueryHookOptions<FetchTripQuery, FetchTripQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTripQuery, FetchTripQueryVariables>(FetchTripDocument, options);
}
export function useFetchTripLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTripQuery, FetchTripQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTripQuery, FetchTripQueryVariables>(FetchTripDocument, options);
}
export type FetchTripQueryHookResult = ReturnType<typeof useFetchTripQuery>;
export type FetchTripLazyQueryHookResult = ReturnType<typeof useFetchTripLazyQuery>;
export type FetchTripQueryResult = Apollo.QueryResult<FetchTripQuery, FetchTripQueryVariables>;
export function refetchFetchTripQuery(variables: FetchTripQueryVariables) {
  return { query: FetchTripDocument, variables: variables };
}
export const TripsListTrimmedDocument = gql`
  query TripsListTrimmed($season: ID) {
    trips(season: $season, sort: [start_date_asc, start_time_asc]) {
      edges {
        cursor
        node {
          id
          label
          startDateTime
          endDateTime
          logoUrl
          timeZone
        }
      }
    }
  }
`;

/**
 * __useTripsListTrimmedQuery__
 *
 * To run a query within a React component, call `useTripsListTrimmedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripsListTrimmedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripsListTrimmedQuery({
 *   variables: {
 *      season: // value for 'season'
 *   },
 * });
 */
export function useTripsListTrimmedQuery(
  baseOptions?: Apollo.QueryHookOptions<TripsListTrimmedQuery, TripsListTrimmedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripsListTrimmedQuery, TripsListTrimmedQueryVariables>(
    TripsListTrimmedDocument,
    options,
  );
}
export function useTripsListTrimmedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TripsListTrimmedQuery, TripsListTrimmedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripsListTrimmedQuery, TripsListTrimmedQueryVariables>(
    TripsListTrimmedDocument,
    options,
  );
}
export type TripsListTrimmedQueryHookResult = ReturnType<typeof useTripsListTrimmedQuery>;
export type TripsListTrimmedLazyQueryHookResult = ReturnType<typeof useTripsListTrimmedLazyQuery>;
export type TripsListTrimmedQueryResult = Apollo.QueryResult<
  TripsListTrimmedQuery,
  TripsListTrimmedQueryVariables
>;
export function refetchTripsListTrimmedQuery(variables?: TripsListTrimmedQueryVariables) {
  return { query: TripsListTrimmedDocument, variables: variables };
}
export const TravelParticipationReportCreateDocument = gql`
  mutation TravelParticipationReportCreate($input: TravelParticipationReportCreateInput) {
    travelParticipationReportCreate(input: $input) {
      travelParticipationReport {
        createdDate
        downloadableFileId
        status
        title
        url
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type TravelParticipationReportCreateMutationFn = Apollo.MutationFunction<
  TravelParticipationReportCreateMutation,
  TravelParticipationReportCreateMutationVariables
>;

/**
 * __useTravelParticipationReportCreateMutation__
 *
 * To run a mutation, you first call `useTravelParticipationReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelParticipationReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelParticipationReportCreateMutation, { data, loading, error }] = useTravelParticipationReportCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelParticipationReportCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelParticipationReportCreateMutation,
    TravelParticipationReportCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelParticipationReportCreateMutation,
    TravelParticipationReportCreateMutationVariables
  >(TravelParticipationReportCreateDocument, options);
}
export type TravelParticipationReportCreateMutationHookResult = ReturnType<
  typeof useTravelParticipationReportCreateMutation
>;
export type TravelParticipationReportCreateMutationResult =
  Apollo.MutationResult<TravelParticipationReportCreateMutation>;
export type TravelParticipationReportCreateMutationOptions = Apollo.BaseMutationOptions<
  TravelParticipationReportCreateMutation,
  TravelParticipationReportCreateMutationVariables
>;
export const TripTravelPartySelectionsDocument = gql`
  query TripTravelPartySelections($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        isInPast
        label
        travelPartySelections {
          ... on PersonNode {
            id
            pk
            active
            selectionCode
            pictureUrl
            displayName
            initials
            isContact
            teamMembership {
              active
              athleteStatus {
                label
              }
              userTypes {
                namePlural
              }
            }
            contactAssociations {
              firstName
              lastName
              id
              selectionCode
              initials
              label
              pictureUrl
            }
          }
          ... on SelectionGroup {
            selectionCode
            pluralLabel
            people {
              id
              pk
              active
              pictureUrl
              displayName
              initials
              teamMembership {
                active
                athleteStatus {
                  label
                }
                userTypes {
                  namePlural
                }
              }
            }
          }
        }
        travelPartyExclusions {
          ... on PersonNode {
            id
            pk
            pictureUrl
            displayName
            initials
            selectionCode
          }
        }
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            pictureUrl
            firstName
            middleName
            lastName
            displayName
            preferredName
            nickname
            suffix
            active
            initials
            selectionCode
            isContact
            dateOfBirth
            gender
            travelWeight
            teamMembership {
              active
              athleteStatus {
                label
              }
              userTypes {
                namePlural
              }
            }
            contactAssociations {
              firstName
              lastName
              id
              selectionCode
              initials
              label
              pictureUrl
            }
          }
        }
        travelPartyPersons {
          perDiem
          notes
          travelDress
          person {
            id
            pk
            pictureUrl
            firstName
            middleName
            lastName
            displayName
            preferredName
            nickname
            suffix
            active
            initials
            selectionCode
            isContact
            dateOfBirth
            gender
            travelWeight
            teamMembership {
              active
              athleteStatus {
                label
              }
              userTypes {
                namePlural
              }
            }
            contactAssociations {
              firstName
              lastName
              id
              selectionCode
              initials
              label
              pictureUrl
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTripTravelPartySelectionsQuery__
 *
 * To run a query within a React component, call `useTripTravelPartySelectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripTravelPartySelectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripTravelPartySelectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTripTravelPartySelectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TripTravelPartySelectionsQuery,
    TripTravelPartySelectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripTravelPartySelectionsQuery, TripTravelPartySelectionsQueryVariables>(
    TripTravelPartySelectionsDocument,
    options,
  );
}
export function useTripTravelPartySelectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripTravelPartySelectionsQuery,
    TripTravelPartySelectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TripTravelPartySelectionsQuery,
    TripTravelPartySelectionsQueryVariables
  >(TripTravelPartySelectionsDocument, options);
}
export type TripTravelPartySelectionsQueryHookResult = ReturnType<
  typeof useTripTravelPartySelectionsQuery
>;
export type TripTravelPartySelectionsLazyQueryHookResult = ReturnType<
  typeof useTripTravelPartySelectionsLazyQuery
>;
export type TripTravelPartySelectionsQueryResult = Apollo.QueryResult<
  TripTravelPartySelectionsQuery,
  TripTravelPartySelectionsQueryVariables
>;
export function refetchTripTravelPartySelectionsQuery(
  variables: TripTravelPartySelectionsQueryVariables,
) {
  return { query: TripTravelPartySelectionsDocument, variables: variables };
}
export const TravelVendorGroupCreateDocument = gql`
  mutation TravelVendorGroupCreate($input: TravelVendorGroupInput) {
    travelVendorGroupCreate(input: $input) {
      travelVendorGroup {
        id
      }
    }
  }
`;
export type TravelVendorGroupCreateMutationFn = Apollo.MutationFunction<
  TravelVendorGroupCreateMutation,
  TravelVendorGroupCreateMutationVariables
>;

/**
 * __useTravelVendorGroupCreateMutation__
 *
 * To run a mutation, you first call `useTravelVendorGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelVendorGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelVendorGroupCreateMutation, { data, loading, error }] = useTravelVendorGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelVendorGroupCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelVendorGroupCreateMutation,
    TravelVendorGroupCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelVendorGroupCreateMutation,
    TravelVendorGroupCreateMutationVariables
  >(TravelVendorGroupCreateDocument, options);
}
export type TravelVendorGroupCreateMutationHookResult = ReturnType<
  typeof useTravelVendorGroupCreateMutation
>;
export type TravelVendorGroupCreateMutationResult =
  Apollo.MutationResult<TravelVendorGroupCreateMutation>;
export type TravelVendorGroupCreateMutationOptions = Apollo.BaseMutationOptions<
  TravelVendorGroupCreateMutation,
  TravelVendorGroupCreateMutationVariables
>;
export const TravelVendorGroupDeleteDocument = gql`
  mutation TravelVendorGroupDelete($id: ID) {
    travelVendorGroupDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type TravelVendorGroupDeleteMutationFn = Apollo.MutationFunction<
  TravelVendorGroupDeleteMutation,
  TravelVendorGroupDeleteMutationVariables
>;

/**
 * __useTravelVendorGroupDeleteMutation__
 *
 * To run a mutation, you first call `useTravelVendorGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelVendorGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelVendorGroupDeleteMutation, { data, loading, error }] = useTravelVendorGroupDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTravelVendorGroupDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelVendorGroupDeleteMutation,
    TravelVendorGroupDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelVendorGroupDeleteMutation,
    TravelVendorGroupDeleteMutationVariables
  >(TravelVendorGroupDeleteDocument, options);
}
export type TravelVendorGroupDeleteMutationHookResult = ReturnType<
  typeof useTravelVendorGroupDeleteMutation
>;
export type TravelVendorGroupDeleteMutationResult =
  Apollo.MutationResult<TravelVendorGroupDeleteMutation>;
export type TravelVendorGroupDeleteMutationOptions = Apollo.BaseMutationOptions<
  TravelVendorGroupDeleteMutation,
  TravelVendorGroupDeleteMutationVariables
>;
export const TravelVendorGroupEditDocument = gql`
  mutation TravelVendorGroupEdit($id: ID, $input: TravelVendorGroupInput) {
    travelVendorGroupUpdate(id: $id, input: $input) {
      travelVendorGroup {
        id
      }
    }
  }
`;
export type TravelVendorGroupEditMutationFn = Apollo.MutationFunction<
  TravelVendorGroupEditMutation,
  TravelVendorGroupEditMutationVariables
>;

/**
 * __useTravelVendorGroupEditMutation__
 *
 * To run a mutation, you first call `useTravelVendorGroupEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelVendorGroupEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelVendorGroupEditMutation, { data, loading, error }] = useTravelVendorGroupEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelVendorGroupEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelVendorGroupEditMutation,
    TravelVendorGroupEditMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelVendorGroupEditMutation, TravelVendorGroupEditMutationVariables>(
    TravelVendorGroupEditDocument,
    options,
  );
}
export type TravelVendorGroupEditMutationHookResult = ReturnType<
  typeof useTravelVendorGroupEditMutation
>;
export type TravelVendorGroupEditMutationResult =
  Apollo.MutationResult<TravelVendorGroupEditMutation>;
export type TravelVendorGroupEditMutationOptions = Apollo.BaseMutationOptions<
  TravelVendorGroupEditMutation,
  TravelVendorGroupEditMutationVariables
>;
export const TripVendorGroupListDocument = gql`
  query TripVendorGroupList($tripIdFilter: ID!) {
    vendorGroups(tripIdFilter: $tripIdFilter, sort: created_date_asc) {
      edges {
        node {
          id
          label
          travelVendors {
            edges {
              node {
                id
                name
                travelVendorId
                travelVendorType {
                  id
                  label
                }
                phoneFax
                phoneMain
                address {
                  street1
                  street2
                  city
                  countryIso
                  customStateProvince
                  country {
                    name
                    printableName
                    numcode
                  }
                  state {
                    name
                    code
                  }
                  zipCode
                }
                teams {
                  id
                }
                contacts {
                  id
                  firstName
                  lastName
                  phoneOffice
                  phoneMobile
                  phoneFax
                  emailAddress
                  isPrimaryContact
                  title
                }
                notes {
                  id
                  title
                  content
                  createdDateTime
                  updatedDateTime
                  author {
                    displayName
                    pictureUrl
                    initials
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTripVendorGroupListQuery__
 *
 * To run a query within a React component, call `useTripVendorGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripVendorGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripVendorGroupListQuery({
 *   variables: {
 *      tripIdFilter: // value for 'tripIdFilter'
 *   },
 * });
 */
export function useTripVendorGroupListQuery(
  baseOptions: Apollo.QueryHookOptions<TripVendorGroupListQuery, TripVendorGroupListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripVendorGroupListQuery, TripVendorGroupListQueryVariables>(
    TripVendorGroupListDocument,
    options,
  );
}
export function useTripVendorGroupListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripVendorGroupListQuery,
    TripVendorGroupListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripVendorGroupListQuery, TripVendorGroupListQueryVariables>(
    TripVendorGroupListDocument,
    options,
  );
}
export type TripVendorGroupListQueryHookResult = ReturnType<typeof useTripVendorGroupListQuery>;
export type TripVendorGroupListLazyQueryHookResult = ReturnType<
  typeof useTripVendorGroupListLazyQuery
>;
export type TripVendorGroupListQueryResult = Apollo.QueryResult<
  TripVendorGroupListQuery,
  TripVendorGroupListQueryVariables
>;
export function refetchTripVendorGroupListQuery(variables: TripVendorGroupListQueryVariables) {
  return { query: TripVendorGroupListDocument, variables: variables };
}
export const TripVendorsDocument = gql`
  query TripVendors($vendorSearch: String, $tripIdFilter: ID) {
    vendors(vendorSearch: $vendorSearch, tripIdFilter: $tripIdFilter) {
      edges {
        node {
          id
          name
          travelVendorType {
            id
            label
          }
          seatingCharts {
            id
            label
            numberOfSeats
            seatplanConfigurationJson
          }
        }
      }
    }
  }
`;

/**
 * __useTripVendorsQuery__
 *
 * To run a query within a React component, call `useTripVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripVendorsQuery({
 *   variables: {
 *      vendorSearch: // value for 'vendorSearch'
 *      tripIdFilter: // value for 'tripIdFilter'
 *   },
 * });
 */
export function useTripVendorsQuery(
  baseOptions?: Apollo.QueryHookOptions<TripVendorsQuery, TripVendorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripVendorsQuery, TripVendorsQueryVariables>(TripVendorsDocument, options);
}
export function useTripVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TripVendorsQuery, TripVendorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripVendorsQuery, TripVendorsQueryVariables>(
    TripVendorsDocument,
    options,
  );
}
export type TripVendorsQueryHookResult = ReturnType<typeof useTripVendorsQuery>;
export type TripVendorsLazyQueryHookResult = ReturnType<typeof useTripVendorsLazyQuery>;
export type TripVendorsQueryResult = Apollo.QueryResult<
  TripVendorsQuery,
  TripVendorsQueryVariables
>;
export function refetchTripVendorsQuery(variables?: TripVendorsQueryVariables) {
  return { query: TripVendorsDocument, variables: variables };
}
export const VendorGroupsDocument = gql`
  query VendorGroups($tripIdFilter: ID!) {
    vendorGroups(tripIdFilter: $tripIdFilter) {
      edges {
        vendorGroup: node {
          label
          travelVendors {
            edges {
              vendor: node {
                travelVendorType {
                  label
                }
                name
                address {
                  street1
                  street2
                  city
                  customStateProvince
                  country {
                    printableName
                  }
                  state {
                    name
                  }
                  zipCode
                }
                contacts {
                  firstName
                  lastName
                  phoneOffice
                  phoneMobile
                  phoneFax
                  emailAddress
                  isPrimaryContact
                  title
                }
                notes {
                  title
                  content
                  createdDateTime
                  author {
                    displayName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useVendorGroupsQuery__
 *
 * To run a query within a React component, call `useVendorGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorGroupsQuery({
 *   variables: {
 *      tripIdFilter: // value for 'tripIdFilter'
 *   },
 * });
 */
export function useVendorGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<VendorGroupsQuery, VendorGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VendorGroupsQuery, VendorGroupsQueryVariables>(
    VendorGroupsDocument,
    options,
  );
}
export function useVendorGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VendorGroupsQuery, VendorGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VendorGroupsQuery, VendorGroupsQueryVariables>(
    VendorGroupsDocument,
    options,
  );
}
export type VendorGroupsQueryHookResult = ReturnType<typeof useVendorGroupsQuery>;
export type VendorGroupsLazyQueryHookResult = ReturnType<typeof useVendorGroupsLazyQuery>;
export type VendorGroupsQueryResult = Apollo.QueryResult<
  VendorGroupsQuery,
  VendorGroupsQueryVariables
>;
export function refetchVendorGroupsQuery(variables: VendorGroupsQueryVariables) {
  return { query: VendorGroupsDocument, variables: variables };
}
export const TravelCredentialAssignmentDocument = gql`
  query TravelCredentialAssignment($competitionIdFilter: ID!) {
    travelCredentialAssignments(competitionIdFilter: $competitionIdFilter) {
      edges {
        node {
          id
          quantity
          notes
          person {
            id
            displayName
            pk
          }
          travelCredential {
            id
            label
          }
        }
      }
    }
  }
`;

/**
 * __useTravelCredentialAssignmentQuery__
 *
 * To run a query within a React component, call `useTravelCredentialAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelCredentialAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelCredentialAssignmentQuery({
 *   variables: {
 *      competitionIdFilter: // value for 'competitionIdFilter'
 *   },
 * });
 */
export function useTravelCredentialAssignmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    TravelCredentialAssignmentQuery,
    TravelCredentialAssignmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelCredentialAssignmentQuery, TravelCredentialAssignmentQueryVariables>(
    TravelCredentialAssignmentDocument,
    options,
  );
}
export function useTravelCredentialAssignmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelCredentialAssignmentQuery,
    TravelCredentialAssignmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TravelCredentialAssignmentQuery,
    TravelCredentialAssignmentQueryVariables
  >(TravelCredentialAssignmentDocument, options);
}
export type TravelCredentialAssignmentQueryHookResult = ReturnType<
  typeof useTravelCredentialAssignmentQuery
>;
export type TravelCredentialAssignmentLazyQueryHookResult = ReturnType<
  typeof useTravelCredentialAssignmentLazyQuery
>;
export type TravelCredentialAssignmentQueryResult = Apollo.QueryResult<
  TravelCredentialAssignmentQuery,
  TravelCredentialAssignmentQueryVariables
>;
export function refetchTravelCredentialAssignmentQuery(
  variables: TravelCredentialAssignmentQueryVariables,
) {
  return { query: TravelCredentialAssignmentDocument, variables: variables };
}
export const CredentialAssignmentBulkEditDocument = gql`
  mutation CredentialAssignmentBulkEdit($input: TravelCredentialAssignmentBulkInput) {
    travelCredentialAssignmentBulkUpdate(input: $input) {
      travelCredentialAssignment {
        travelCredential {
          label
        }
        quantity
        notes
      }
    }
  }
`;
export type CredentialAssignmentBulkEditMutationFn = Apollo.MutationFunction<
  CredentialAssignmentBulkEditMutation,
  CredentialAssignmentBulkEditMutationVariables
>;

/**
 * __useCredentialAssignmentBulkEditMutation__
 *
 * To run a mutation, you first call `useCredentialAssignmentBulkEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCredentialAssignmentBulkEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [credentialAssignmentBulkEditMutation, { data, loading, error }] = useCredentialAssignmentBulkEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCredentialAssignmentBulkEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CredentialAssignmentBulkEditMutation,
    CredentialAssignmentBulkEditMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CredentialAssignmentBulkEditMutation,
    CredentialAssignmentBulkEditMutationVariables
  >(CredentialAssignmentBulkEditDocument, options);
}
export type CredentialAssignmentBulkEditMutationHookResult = ReturnType<
  typeof useCredentialAssignmentBulkEditMutation
>;
export type CredentialAssignmentBulkEditMutationResult =
  Apollo.MutationResult<CredentialAssignmentBulkEditMutation>;
export type CredentialAssignmentBulkEditMutationOptions = Apollo.BaseMutationOptions<
  CredentialAssignmentBulkEditMutation,
  CredentialAssignmentBulkEditMutationVariables
>;
export const CredentialsAssignCopyDocument = gql`
  mutation CredentialsAssignCopy($input: TravelCredentialAssignmentCopyInput) {
    travelCredentialAssignmentCopy(input: $input) {
      travelCredentialAssignment {
        travelCredential {
          label
        }
        competition {
          title
        }
        quantity
        notes
      }
    }
  }
`;
export type CredentialsAssignCopyMutationFn = Apollo.MutationFunction<
  CredentialsAssignCopyMutation,
  CredentialsAssignCopyMutationVariables
>;

/**
 * __useCredentialsAssignCopyMutation__
 *
 * To run a mutation, you first call `useCredentialsAssignCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCredentialsAssignCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [credentialsAssignCopyMutation, { data, loading, error }] = useCredentialsAssignCopyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCredentialsAssignCopyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CredentialsAssignCopyMutation,
    CredentialsAssignCopyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CredentialsAssignCopyMutation, CredentialsAssignCopyMutationVariables>(
    CredentialsAssignCopyDocument,
    options,
  );
}
export type CredentialsAssignCopyMutationHookResult = ReturnType<
  typeof useCredentialsAssignCopyMutation
>;
export type CredentialsAssignCopyMutationResult =
  Apollo.MutationResult<CredentialsAssignCopyMutation>;
export type CredentialsAssignCopyMutationOptions = Apollo.BaseMutationOptions<
  CredentialsAssignCopyMutation,
  CredentialsAssignCopyMutationVariables
>;
export const TripTravelCredentialsOptionsDocument = gql`
  query TripTravelCredentialsOptions {
    travelCredentialOptions(sort: label_asc) {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

/**
 * __useTripTravelCredentialsOptionsQuery__
 *
 * To run a query within a React component, call `useTripTravelCredentialsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripTravelCredentialsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripTravelCredentialsOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTripTravelCredentialsOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TripTravelCredentialsOptionsQuery,
    TripTravelCredentialsOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TripTravelCredentialsOptionsQuery,
    TripTravelCredentialsOptionsQueryVariables
  >(TripTravelCredentialsOptionsDocument, options);
}
export function useTripTravelCredentialsOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripTravelCredentialsOptionsQuery,
    TripTravelCredentialsOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TripTravelCredentialsOptionsQuery,
    TripTravelCredentialsOptionsQueryVariables
  >(TripTravelCredentialsOptionsDocument, options);
}
export type TripTravelCredentialsOptionsQueryHookResult = ReturnType<
  typeof useTripTravelCredentialsOptionsQuery
>;
export type TripTravelCredentialsOptionsLazyQueryHookResult = ReturnType<
  typeof useTripTravelCredentialsOptionsLazyQuery
>;
export type TripTravelCredentialsOptionsQueryResult = Apollo.QueryResult<
  TripTravelCredentialsOptionsQuery,
  TripTravelCredentialsOptionsQueryVariables
>;
export function refetchTripTravelCredentialsOptionsQuery(
  variables?: TripTravelCredentialsOptionsQueryVariables,
) {
  return { query: TripTravelCredentialsOptionsDocument, variables: variables };
}
export const TripTravelPartySelectionsForCredentialsDocument = gql`
  query TripTravelPartySelectionsForCredentials($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        isInPast
        label
        travelPartyMembers {
          ... on PersonNode {
            id
            pk
            pictureUrl
            firstName
            middleName
            lastName
            displayName
            preferredName
            active
            initials
            isContact
            teamMembership {
              active
              athleteStatus {
                label
              }
              userTypes {
                namePlural
                id
              }
            }
          }
        }
        travelPartySelections {
          ... on PersonNode {
            id
            pk
            active
            selectionCode
            pictureUrl
            firstName
            middleName
            lastName
            displayName
            initials
            isContact
          }
        }
      }
    }
  }
`;

/**
 * __useTripTravelPartySelectionsForCredentialsQuery__
 *
 * To run a query within a React component, call `useTripTravelPartySelectionsForCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripTravelPartySelectionsForCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripTravelPartySelectionsForCredentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTripTravelPartySelectionsForCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TripTravelPartySelectionsForCredentialsQuery,
    TripTravelPartySelectionsForCredentialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TripTravelPartySelectionsForCredentialsQuery,
    TripTravelPartySelectionsForCredentialsQueryVariables
  >(TripTravelPartySelectionsForCredentialsDocument, options);
}
export function useTripTravelPartySelectionsForCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripTravelPartySelectionsForCredentialsQuery,
    TripTravelPartySelectionsForCredentialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TripTravelPartySelectionsForCredentialsQuery,
    TripTravelPartySelectionsForCredentialsQueryVariables
  >(TripTravelPartySelectionsForCredentialsDocument, options);
}
export type TripTravelPartySelectionsForCredentialsQueryHookResult = ReturnType<
  typeof useTripTravelPartySelectionsForCredentialsQuery
>;
export type TripTravelPartySelectionsForCredentialsLazyQueryHookResult = ReturnType<
  typeof useTripTravelPartySelectionsForCredentialsLazyQuery
>;
export type TripTravelPartySelectionsForCredentialsQueryResult = Apollo.QueryResult<
  TripTravelPartySelectionsForCredentialsQuery,
  TripTravelPartySelectionsForCredentialsQueryVariables
>;
export function refetchTripTravelPartySelectionsForCredentialsQuery(
  variables: TripTravelPartySelectionsForCredentialsQueryVariables,
) {
  return { query: TripTravelPartySelectionsForCredentialsDocument, variables: variables };
}
export const TripFilesDocument = gql`
  query TripFiles($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        files {
          id
          pk
          sharedFileId
          fileName
          fileNotes
          isPinned
          updatedDate
          canEditFile
          canDownload
          creator {
            createdBy
            pictureDownloadUrl
            preferredName
            lastName
            initials
          }
        }
      }
    }
  }
`;

/**
 * __useTripFilesQuery__
 *
 * To run a query within a React component, call `useTripFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTripFilesQuery(
  baseOptions: Apollo.QueryHookOptions<TripFilesQuery, TripFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripFilesQuery, TripFilesQueryVariables>(TripFilesDocument, options);
}
export function useTripFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TripFilesQuery, TripFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripFilesQuery, TripFilesQueryVariables>(TripFilesDocument, options);
}
export type TripFilesQueryHookResult = ReturnType<typeof useTripFilesQuery>;
export type TripFilesLazyQueryHookResult = ReturnType<typeof useTripFilesLazyQuery>;
export type TripFilesQueryResult = Apollo.QueryResult<TripFilesQuery, TripFilesQueryVariables>;
export function refetchTripFilesQuery(variables: TripFilesQueryVariables) {
  return { query: TripFilesDocument, variables: variables };
}
export const TripTravelSelectionCodesDocument = gql`
  query TripTravelSelectionCodes($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        travelPartySelections {
          ... on SelectionGroup {
            selectionCode
            __typename
          }
          ... on PersonNode {
            id
            pk
            selectionCode
            __typename
          }
        }
      }
    }
  }
`;

/**
 * __useTripTravelSelectionCodesQuery__
 *
 * To run a query within a React component, call `useTripTravelSelectionCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripTravelSelectionCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripTravelSelectionCodesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTripTravelSelectionCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TripTravelSelectionCodesQuery,
    TripTravelSelectionCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripTravelSelectionCodesQuery, TripTravelSelectionCodesQueryVariables>(
    TripTravelSelectionCodesDocument,
    options,
  );
}
export function useTripTravelSelectionCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripTravelSelectionCodesQuery,
    TripTravelSelectionCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripTravelSelectionCodesQuery, TripTravelSelectionCodesQueryVariables>(
    TripTravelSelectionCodesDocument,
    options,
  );
}
export type TripTravelSelectionCodesQueryHookResult = ReturnType<
  typeof useTripTravelSelectionCodesQuery
>;
export type TripTravelSelectionCodesLazyQueryHookResult = ReturnType<
  typeof useTripTravelSelectionCodesLazyQuery
>;
export type TripTravelSelectionCodesQueryResult = Apollo.QueryResult<
  TripTravelSelectionCodesQuery,
  TripTravelSelectionCodesQueryVariables
>;
export function refetchTripTravelSelectionCodesQuery(
  variables: TripTravelSelectionCodesQueryVariables,
) {
  return { query: TripTravelSelectionCodesDocument, variables: variables };
}
export const InstitutionsDocument = gql`
  query Institutions {
    institutions {
      edges {
        node {
          id
          state
          city
          nickname
          orgName
        }
      }
    }
  }
`;

/**
 * __useInstitutionsQuery__
 *
 * To run a query within a React component, call `useInstitutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstitutionsQuery(
  baseOptions?: Apollo.QueryHookOptions<InstitutionsQuery, InstitutionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstitutionsQuery, InstitutionsQueryVariables>(
    InstitutionsDocument,
    options,
  );
}
export function useInstitutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstitutionsQuery, InstitutionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstitutionsQuery, InstitutionsQueryVariables>(
    InstitutionsDocument,
    options,
  );
}
export type InstitutionsQueryHookResult = ReturnType<typeof useInstitutionsQuery>;
export type InstitutionsLazyQueryHookResult = ReturnType<typeof useInstitutionsLazyQuery>;
export type InstitutionsQueryResult = Apollo.QueryResult<
  InstitutionsQuery,
  InstitutionsQueryVariables
>;
export function refetchInstitutionsQuery(variables?: InstitutionsQueryVariables) {
  return { query: InstitutionsDocument, variables: variables };
}
export const EventBulkDeleteDocument = gql`
  mutation EventBulkDelete($input: TravelEventBulkDeleteInput!, $id: ID!) {
    travelEventBulkDelete(input: $input, id: $id) {
      trip {
        events {
          id
        }
      }
    }
  }
`;
export type EventBulkDeleteMutationFn = Apollo.MutationFunction<
  EventBulkDeleteMutation,
  EventBulkDeleteMutationVariables
>;

/**
 * __useEventBulkDeleteMutation__
 *
 * To run a mutation, you first call `useEventBulkDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventBulkDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventBulkDeleteMutation, { data, loading, error }] = useEventBulkDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventBulkDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventBulkDeleteMutation,
    EventBulkDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EventBulkDeleteMutation, EventBulkDeleteMutationVariables>(
    EventBulkDeleteDocument,
    options,
  );
}
export type EventBulkDeleteMutationHookResult = ReturnType<typeof useEventBulkDeleteMutation>;
export type EventBulkDeleteMutationResult = Apollo.MutationResult<EventBulkDeleteMutation>;
export type EventBulkDeleteMutationOptions = Apollo.BaseMutationOptions<
  EventBulkDeleteMutation,
  EventBulkDeleteMutationVariables
>;
export const EventBulkUpdateDocument = gql`
  mutation EventBulkUpdate($input: TravelEventBulkUpdateInput!, $id: ID!) {
    travelEventBulkUpdate(input: $input, id: $id) {
      trip {
        events {
          id
        }
      }
    }
  }
`;
export type EventBulkUpdateMutationFn = Apollo.MutationFunction<
  EventBulkUpdateMutation,
  EventBulkUpdateMutationVariables
>;

/**
 * __useEventBulkUpdateMutation__
 *
 * To run a mutation, you first call `useEventBulkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventBulkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventBulkUpdateMutation, { data, loading, error }] = useEventBulkUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventBulkUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventBulkUpdateMutation,
    EventBulkUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EventBulkUpdateMutation, EventBulkUpdateMutationVariables>(
    EventBulkUpdateDocument,
    options,
  );
}
export type EventBulkUpdateMutationHookResult = ReturnType<typeof useEventBulkUpdateMutation>;
export type EventBulkUpdateMutationResult = Apollo.MutationResult<EventBulkUpdateMutation>;
export type EventBulkUpdateMutationOptions = Apollo.BaseMutationOptions<
  EventBulkUpdateMutation,
  EventBulkUpdateMutationVariables
>;
export const TravelEventCopyDocument = gql`
  mutation TravelEventCopy($id: ID!, $input: TravelEventCopyInput) {
    travelEventCopy(id: $id, input: $input) {
      trip {
        id
      }
    }
  }
`;
export type TravelEventCopyMutationFn = Apollo.MutationFunction<
  TravelEventCopyMutation,
  TravelEventCopyMutationVariables
>;

/**
 * __useTravelEventCopyMutation__
 *
 * To run a mutation, you first call `useTravelEventCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelEventCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelEventCopyMutation, { data, loading, error }] = useTravelEventCopyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelEventCopyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelEventCopyMutation,
    TravelEventCopyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelEventCopyMutation, TravelEventCopyMutationVariables>(
    TravelEventCopyDocument,
    options,
  );
}
export type TravelEventCopyMutationHookResult = ReturnType<typeof useTravelEventCopyMutation>;
export type TravelEventCopyMutationResult = Apollo.MutationResult<TravelEventCopyMutation>;
export type TravelEventCopyMutationOptions = Apollo.BaseMutationOptions<
  TravelEventCopyMutation,
  TravelEventCopyMutationVariables
>;
export const EventcreateDocument = gql`
  mutation eventcreate($input: TravelEventInput) {
    travelEventCreate(input: $input) {
      travelEvent {
        id
        trip {
          label
        }
        competition {
          title
        }
        appointment {
          title
        }
        orgId
      }
    }
  }
`;
export type EventcreateMutationFn = Apollo.MutationFunction<
  EventcreateMutation,
  EventcreateMutationVariables
>;

/**
 * __useEventcreateMutation__
 *
 * To run a mutation, you first call `useEventcreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventcreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventcreateMutation, { data, loading, error }] = useEventcreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventcreateMutation(
  baseOptions?: Apollo.MutationHookOptions<EventcreateMutation, EventcreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EventcreateMutation, EventcreateMutationVariables>(
    EventcreateDocument,
    options,
  );
}
export type EventcreateMutationHookResult = ReturnType<typeof useEventcreateMutation>;
export type EventcreateMutationResult = Apollo.MutationResult<EventcreateMutation>;
export type EventcreateMutationOptions = Apollo.BaseMutationOptions<
  EventcreateMutation,
  EventcreateMutationVariables
>;
export const TravelEventDeleteDocument = gql`
  mutation TravelEventDelete($id: ID!) {
    travelEventDelete(id: $id) {
      travelEvent {
        id
      }
    }
  }
`;
export type TravelEventDeleteMutationFn = Apollo.MutationFunction<
  TravelEventDeleteMutation,
  TravelEventDeleteMutationVariables
>;

/**
 * __useTravelEventDeleteMutation__
 *
 * To run a mutation, you first call `useTravelEventDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelEventDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelEventDeleteMutation, { data, loading, error }] = useTravelEventDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTravelEventDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelEventDeleteMutation,
    TravelEventDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelEventDeleteMutation, TravelEventDeleteMutationVariables>(
    TravelEventDeleteDocument,
    options,
  );
}
export type TravelEventDeleteMutationHookResult = ReturnType<typeof useTravelEventDeleteMutation>;
export type TravelEventDeleteMutationResult = Apollo.MutationResult<TravelEventDeleteMutation>;
export type TravelEventDeleteMutationOptions = Apollo.BaseMutationOptions<
  TravelEventDeleteMutation,
  TravelEventDeleteMutationVariables
>;
export const TravelEventUpdateDocument = gql`
  mutation TravelEventUpdate($id: ID!, $input: TravelEventInput) {
    travelEventUpdate(id: $id, input: $input) {
      travelEvent {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type TravelEventUpdateMutationFn = Apollo.MutationFunction<
  TravelEventUpdateMutation,
  TravelEventUpdateMutationVariables
>;

/**
 * __useTravelEventUpdateMutation__
 *
 * To run a mutation, you first call `useTravelEventUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelEventUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelEventUpdateMutation, { data, loading, error }] = useTravelEventUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelEventUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelEventUpdateMutation,
    TravelEventUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelEventUpdateMutation, TravelEventUpdateMutationVariables>(
    TravelEventUpdateDocument,
    options,
  );
}
export type TravelEventUpdateMutationHookResult = ReturnType<typeof useTravelEventUpdateMutation>;
export type TravelEventUpdateMutationResult = Apollo.MutationResult<TravelEventUpdateMutation>;
export type TravelEventUpdateMutationOptions = Apollo.BaseMutationOptions<
  TravelEventUpdateMutation,
  TravelEventUpdateMutationVariables
>;
export const TravelEventTypesDocument = gql`
  query TravelEventTypes {
    travelEventTypes {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

/**
 * __useTravelEventTypesQuery__
 *
 * To run a query within a React component, call `useTravelEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelEventTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTravelEventTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<TravelEventTypesQuery, TravelEventTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelEventTypesQuery, TravelEventTypesQueryVariables>(
    TravelEventTypesDocument,
    options,
  );
}
export function useTravelEventTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TravelEventTypesQuery, TravelEventTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TravelEventTypesQuery, TravelEventTypesQueryVariables>(
    TravelEventTypesDocument,
    options,
  );
}
export type TravelEventTypesQueryHookResult = ReturnType<typeof useTravelEventTypesQuery>;
export type TravelEventTypesLazyQueryHookResult = ReturnType<typeof useTravelEventTypesLazyQuery>;
export type TravelEventTypesQueryResult = Apollo.QueryResult<
  TravelEventTypesQuery,
  TravelEventTypesQueryVariables
>;
export function refetchTravelEventTypesQuery(variables?: TravelEventTypesQueryVariables) {
  return { query: TravelEventTypesDocument, variables: variables };
}
export const TravelEventsDocument = gql`
  query TravelEvents($tripIdFilter: ID!) {
    travelEvents(tripIdFilter: $tripIdFilter) {
      edges {
        node {
          id
          hasTicketAllocations
          pk
          timeZone
          appointment {
            enableCheckins
            endDatetimeLocal
            endTimeZone
            location
            notes
            startDatetimeLocal
            startTimeZone
            title
            appointmentCheckin {
              checkoutEnabled
              hasCheckinActions
              rotateQrCode
              checkinAdminSelectables {
                label
                selectionCode
                ... on PersonNode {
                  active
                  id
                  initials
                  lastName
                  pictureUrl
                  preferredName
                  teamMembership {
                    isAthlete
                  }
                }
                ... on SelectionGroup {
                  groupType
                  pluralLabel
                  people {
                    active
                    id
                    initials
                    label
                    lastName
                    pictureUrl
                    preferredName
                    selectionCode
                    teamMembership {
                      isAthlete
                    }
                  }
                }
              }
            }
            reminders {
              offsetMinutes
              reminderType
            }
          }
          appointmentType {
            color
            id
            label
          }
          attendees {
            label
            peopleCount
            selectionCode
          }
          competition {
            customOpponent
            hasCustomOpponent
            location
            awayInstitution {
              city
              id
              nickname
              state
            }
            homeInstitution {
              city
              id
              nickname
              state
            }
            opponentInstitution {
              city
              id
              nickname
              state
            }
          }
          creator {
            active
            id
            initials
            label
            pictureUrl
            preferredName
            selectionCode
          }
          travelEventType {
            id
            label
          }
        }
      }
    }
  }
`;

/**
 * __useTravelEventsQuery__
 *
 * To run a query within a React component, call `useTravelEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelEventsQuery({
 *   variables: {
 *      tripIdFilter: // value for 'tripIdFilter'
 *   },
 * });
 */
export function useTravelEventsQuery(
  baseOptions: Apollo.QueryHookOptions<TravelEventsQuery, TravelEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelEventsQuery, TravelEventsQueryVariables>(
    TravelEventsDocument,
    options,
  );
}
export function useTravelEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TravelEventsQuery, TravelEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TravelEventsQuery, TravelEventsQueryVariables>(
    TravelEventsDocument,
    options,
  );
}
export type TravelEventsQueryHookResult = ReturnType<typeof useTravelEventsQuery>;
export type TravelEventsLazyQueryHookResult = ReturnType<typeof useTravelEventsLazyQuery>;
export type TravelEventsQueryResult = Apollo.QueryResult<
  TravelEventsQuery,
  TravelEventsQueryVariables
>;
export function refetchTravelEventsQuery(variables: TravelEventsQueryVariables) {
  return { query: TravelEventsDocument, variables: variables };
}
export const ItineraryTripsListDocument = gql`
  query ItineraryTripsList($season: ID) {
    trips(season: $season) {
      totalCount
      edges {
        node {
          pk
          id
          label
        }
      }
    }
  }
`;

/**
 * __useItineraryTripsListQuery__
 *
 * To run a query within a React component, call `useItineraryTripsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useItineraryTripsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItineraryTripsListQuery({
 *   variables: {
 *      season: // value for 'season'
 *   },
 * });
 */
export function useItineraryTripsListQuery(
  baseOptions?: Apollo.QueryHookOptions<ItineraryTripsListQuery, ItineraryTripsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItineraryTripsListQuery, ItineraryTripsListQueryVariables>(
    ItineraryTripsListDocument,
    options,
  );
}
export function useItineraryTripsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ItineraryTripsListQuery,
    ItineraryTripsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItineraryTripsListQuery, ItineraryTripsListQueryVariables>(
    ItineraryTripsListDocument,
    options,
  );
}
export type ItineraryTripsListQueryHookResult = ReturnType<typeof useItineraryTripsListQuery>;
export type ItineraryTripsListLazyQueryHookResult = ReturnType<
  typeof useItineraryTripsListLazyQuery
>;
export type ItineraryTripsListQueryResult = Apollo.QueryResult<
  ItineraryTripsListQuery,
  ItineraryTripsListQueryVariables
>;
export function refetchItineraryTripsListQuery(variables?: ItineraryTripsListQueryVariables) {
  return { query: ItineraryTripsListDocument, variables: variables };
}
export const TravelParticipationBulkUpsertDocument = gql`
  mutation TravelParticipationBulkUpsert($input: TravelParticipationBulkUpsertInput) {
    travelParticipationBulkUpsert(input: $input) {
      travelParticipation {
        didParticipate
        notes
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type TravelParticipationBulkUpsertMutationFn = Apollo.MutationFunction<
  TravelParticipationBulkUpsertMutation,
  TravelParticipationBulkUpsertMutationVariables
>;

/**
 * __useTravelParticipationBulkUpsertMutation__
 *
 * To run a mutation, you first call `useTravelParticipationBulkUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelParticipationBulkUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelParticipationBulkUpsertMutation, { data, loading, error }] = useTravelParticipationBulkUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelParticipationBulkUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelParticipationBulkUpsertMutation,
    TravelParticipationBulkUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelParticipationBulkUpsertMutation,
    TravelParticipationBulkUpsertMutationVariables
  >(TravelParticipationBulkUpsertDocument, options);
}
export type TravelParticipationBulkUpsertMutationHookResult = ReturnType<
  typeof useTravelParticipationBulkUpsertMutation
>;
export type TravelParticipationBulkUpsertMutationResult =
  Apollo.MutationResult<TravelParticipationBulkUpsertMutation>;
export type TravelParticipationBulkUpsertMutationOptions = Apollo.BaseMutationOptions<
  TravelParticipationBulkUpsertMutation,
  TravelParticipationBulkUpsertMutationVariables
>;
export const TravelParticipationDocument = gql`
  query TravelParticipation($competitionIdFilter: ID!) {
    travelParticipation(competitionIdFilter: $competitionIdFilter) {
      edges {
        node {
          id
          notes
          didParticipate
          person {
            id
            firstName
            lastName
            displayName
            initials
          }
        }
      }
    }
  }
`;

/**
 * __useTravelParticipationQuery__
 *
 * To run a query within a React component, call `useTravelParticipationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelParticipationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelParticipationQuery({
 *   variables: {
 *      competitionIdFilter: // value for 'competitionIdFilter'
 *   },
 * });
 */
export function useTravelParticipationQuery(
  baseOptions: Apollo.QueryHookOptions<TravelParticipationQuery, TravelParticipationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelParticipationQuery, TravelParticipationQueryVariables>(
    TravelParticipationDocument,
    options,
  );
}
export function useTravelParticipationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelParticipationQuery,
    TravelParticipationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TravelParticipationQuery, TravelParticipationQueryVariables>(
    TravelParticipationDocument,
    options,
  );
}
export type TravelParticipationQueryHookResult = ReturnType<typeof useTravelParticipationQuery>;
export type TravelParticipationLazyQueryHookResult = ReturnType<
  typeof useTravelParticipationLazyQuery
>;
export type TravelParticipationQueryResult = Apollo.QueryResult<
  TravelParticipationQuery,
  TravelParticipationQueryVariables
>;
export function refetchTravelParticipationQuery(variables: TravelParticipationQueryVariables) {
  return { query: TravelParticipationDocument, variables: variables };
}
export const PregameCreateDocument = gql`
  mutation PregameCreate($input: TravelPregameItemCreateInput) {
    travelPregameItemCreate(input: $input) {
      travelPregameItem {
        id
        title
        minutesBeforeStart
        secondsBeforeStart
        timeBeforeStartSeconds
      }
    }
  }
`;
export type PregameCreateMutationFn = Apollo.MutationFunction<
  PregameCreateMutation,
  PregameCreateMutationVariables
>;

/**
 * __usePregameCreateMutation__
 *
 * To run a mutation, you first call `usePregameCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePregameCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pregameCreateMutation, { data, loading, error }] = usePregameCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePregameCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<PregameCreateMutation, PregameCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PregameCreateMutation, PregameCreateMutationVariables>(
    PregameCreateDocument,
    options,
  );
}
export type PregameCreateMutationHookResult = ReturnType<typeof usePregameCreateMutation>;
export type PregameCreateMutationResult = Apollo.MutationResult<PregameCreateMutation>;
export type PregameCreateMutationOptions = Apollo.BaseMutationOptions<
  PregameCreateMutation,
  PregameCreateMutationVariables
>;
export const PregameItemDeleteDocument = gql`
  mutation PregameItemDelete($id: ID!) {
    travelPregameItemDelete(id: $id) {
      travelPregameItem {
        id
      }
    }
  }
`;
export type PregameItemDeleteMutationFn = Apollo.MutationFunction<
  PregameItemDeleteMutation,
  PregameItemDeleteMutationVariables
>;

/**
 * __usePregameItemDeleteMutation__
 *
 * To run a mutation, you first call `usePregameItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePregameItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pregameItemDeleteMutation, { data, loading, error }] = usePregameItemDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePregameItemDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PregameItemDeleteMutation,
    PregameItemDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PregameItemDeleteMutation, PregameItemDeleteMutationVariables>(
    PregameItemDeleteDocument,
    options,
  );
}
export type PregameItemDeleteMutationHookResult = ReturnType<typeof usePregameItemDeleteMutation>;
export type PregameItemDeleteMutationResult = Apollo.MutationResult<PregameItemDeleteMutation>;
export type PregameItemDeleteMutationOptions = Apollo.BaseMutationOptions<
  PregameItemDeleteMutation,
  PregameItemDeleteMutationVariables
>;
export const PregameItemUpdateDocument = gql`
  mutation PregameItemUpdate($input: TravelPregameItemUpdateInput, $id: ID!) {
    travelPregameItemUpdate(input: $input, id: $id) {
      travelPregameItem {
        id
        title
        minutesBeforeStart
        secondsBeforeStart
        timeBeforeStartSeconds
      }
    }
  }
`;
export type PregameItemUpdateMutationFn = Apollo.MutationFunction<
  PregameItemUpdateMutation,
  PregameItemUpdateMutationVariables
>;

/**
 * __usePregameItemUpdateMutation__
 *
 * To run a mutation, you first call `usePregameItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePregameItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pregameItemUpdateMutation, { data, loading, error }] = usePregameItemUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePregameItemUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PregameItemUpdateMutation,
    PregameItemUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PregameItemUpdateMutation, PregameItemUpdateMutationVariables>(
    PregameItemUpdateDocument,
    options,
  );
}
export type PregameItemUpdateMutationHookResult = ReturnType<typeof usePregameItemUpdateMutation>;
export type PregameItemUpdateMutationResult = Apollo.MutationResult<PregameItemUpdateMutation>;
export type PregameItemUpdateMutationOptions = Apollo.BaseMutationOptions<
  PregameItemUpdateMutation,
  PregameItemUpdateMutationVariables
>;
export const PregameItemsDocument = gql`
  query PregameItems($competitionIdFilter: ID!) {
    pregameItems(competitionIdFilter: $competitionIdFilter, sort: time_before_start_seconds_desc) {
      edges {
        node {
          id
          title
          minutesBeforeStart
          secondsBeforeStart
          timeBeforeStartSeconds
        }
      }
    }
  }
`;

/**
 * __usePregameItemsQuery__
 *
 * To run a query within a React component, call `usePregameItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePregameItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePregameItemsQuery({
 *   variables: {
 *      competitionIdFilter: // value for 'competitionIdFilter'
 *   },
 * });
 */
export function usePregameItemsQuery(
  baseOptions: Apollo.QueryHookOptions<PregameItemsQuery, PregameItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PregameItemsQuery, PregameItemsQueryVariables>(
    PregameItemsDocument,
    options,
  );
}
export function usePregameItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PregameItemsQuery, PregameItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PregameItemsQuery, PregameItemsQueryVariables>(
    PregameItemsDocument,
    options,
  );
}
export type PregameItemsQueryHookResult = ReturnType<typeof usePregameItemsQuery>;
export type PregameItemsLazyQueryHookResult = ReturnType<typeof usePregameItemsLazyQuery>;
export type PregameItemsQueryResult = Apollo.QueryResult<
  PregameItemsQuery,
  PregameItemsQueryVariables
>;
export function refetchPregameItemsQuery(variables: PregameItemsQueryVariables) {
  return { query: PregameItemsDocument, variables: variables };
}
export const PregameItemsCopyDocument = gql`
  mutation PregameItemsCopy($input: TravelPregameItemCopyInput) {
    travelPregameItemCopy(input: $input) {
      travelPregameItem {
        id
      }
    }
  }
`;
export type PregameItemsCopyMutationFn = Apollo.MutationFunction<
  PregameItemsCopyMutation,
  PregameItemsCopyMutationVariables
>;

/**
 * __usePregameItemsCopyMutation__
 *
 * To run a mutation, you first call `usePregameItemsCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePregameItemsCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pregameItemsCopyMutation, { data, loading, error }] = usePregameItemsCopyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePregameItemsCopyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PregameItemsCopyMutation,
    PregameItemsCopyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PregameItemsCopyMutation, PregameItemsCopyMutationVariables>(
    PregameItemsCopyDocument,
    options,
  );
}
export type PregameItemsCopyMutationHookResult = ReturnType<typeof usePregameItemsCopyMutation>;
export type PregameItemsCopyMutationResult = Apollo.MutationResult<PregameItemsCopyMutation>;
export type PregameItemsCopyMutationOptions = Apollo.BaseMutationOptions<
  PregameItemsCopyMutation,
  PregameItemsCopyMutationVariables
>;
export const PregameTravelPartyDocument = gql`
  query PregameTravelParty($id: ID!) {
    trip: node(id: $id) {
      ... on Trip {
        travelPartyPersons {
          person {
            id
            pk
          }
        }
      }
    }
  }
`;

/**
 * __usePregameTravelPartyQuery__
 *
 * To run a query within a React component, call `usePregameTravelPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePregameTravelPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePregameTravelPartyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePregameTravelPartyQuery(
  baseOptions: Apollo.QueryHookOptions<PregameTravelPartyQuery, PregameTravelPartyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PregameTravelPartyQuery, PregameTravelPartyQueryVariables>(
    PregameTravelPartyDocument,
    options,
  );
}
export function usePregameTravelPartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PregameTravelPartyQuery,
    PregameTravelPartyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PregameTravelPartyQuery, PregameTravelPartyQueryVariables>(
    PregameTravelPartyDocument,
    options,
  );
}
export type PregameTravelPartyQueryHookResult = ReturnType<typeof usePregameTravelPartyQuery>;
export type PregameTravelPartyLazyQueryHookResult = ReturnType<
  typeof usePregameTravelPartyLazyQuery
>;
export type PregameTravelPartyQueryResult = Apollo.QueryResult<
  PregameTravelPartyQuery,
  PregameTravelPartyQueryVariables
>;
export function refetchPregameTravelPartyQuery(variables: PregameTravelPartyQueryVariables) {
  return { query: PregameTravelPartyDocument, variables: variables };
}
export const TravelCompetitionEventsDocument = gql`
  query TravelCompetitionEvents($tripIdFilter: ID!, $onlyCompetitionsFilter: Boolean) {
    travelEvents(tripIdFilter: $tripIdFilter, onlyCompetitionsFilter: $onlyCompetitionsFilter) {
      edges {
        node {
          id
          pk
          timeZone
          appointment {
            title
            location
            startDatetimeLocal
            endDatetimeLocal
            startTimeZone
            endTimeZone
          }
          competition {
            id
            pregameVisibilities
            slideTimeInterval {
              hours
              minutes
              seconds
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTravelCompetitionEventsQuery__
 *
 * To run a query within a React component, call `useTravelCompetitionEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelCompetitionEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelCompetitionEventsQuery({
 *   variables: {
 *      tripIdFilter: // value for 'tripIdFilter'
 *      onlyCompetitionsFilter: // value for 'onlyCompetitionsFilter'
 *   },
 * });
 */
export function useTravelCompetitionEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TravelCompetitionEventsQuery,
    TravelCompetitionEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelCompetitionEventsQuery, TravelCompetitionEventsQueryVariables>(
    TravelCompetitionEventsDocument,
    options,
  );
}
export function useTravelCompetitionEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelCompetitionEventsQuery,
    TravelCompetitionEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TravelCompetitionEventsQuery, TravelCompetitionEventsQueryVariables>(
    TravelCompetitionEventsDocument,
    options,
  );
}
export type TravelCompetitionEventsQueryHookResult = ReturnType<
  typeof useTravelCompetitionEventsQuery
>;
export type TravelCompetitionEventsLazyQueryHookResult = ReturnType<
  typeof useTravelCompetitionEventsLazyQuery
>;
export type TravelCompetitionEventsQueryResult = Apollo.QueryResult<
  TravelCompetitionEventsQuery,
  TravelCompetitionEventsQueryVariables
>;
export function refetchTravelCompetitionEventsQuery(
  variables: TravelCompetitionEventsQueryVariables,
) {
  return { query: TravelCompetitionEventsDocument, variables: variables };
}
export const TravelCompetitionSlideTimeDocument = gql`
  mutation TravelCompetitionSlideTime($id: ID!, $input: CompetitionSlideTimeIntervalInput) {
    updateCompetitionSlideTimeInterval(id: $id, input: $input) {
      competition {
        id
        slideTimeInterval {
          hours
          minutes
          seconds
        }
      }
    }
  }
`;
export type TravelCompetitionSlideTimeMutationFn = Apollo.MutationFunction<
  TravelCompetitionSlideTimeMutation,
  TravelCompetitionSlideTimeMutationVariables
>;

/**
 * __useTravelCompetitionSlideTimeMutation__
 *
 * To run a mutation, you first call `useTravelCompetitionSlideTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelCompetitionSlideTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelCompetitionSlideTimeMutation, { data, loading, error }] = useTravelCompetitionSlideTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelCompetitionSlideTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelCompetitionSlideTimeMutation,
    TravelCompetitionSlideTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelCompetitionSlideTimeMutation,
    TravelCompetitionSlideTimeMutationVariables
  >(TravelCompetitionSlideTimeDocument, options);
}
export type TravelCompetitionSlideTimeMutationHookResult = ReturnType<
  typeof useTravelCompetitionSlideTimeMutation
>;
export type TravelCompetitionSlideTimeMutationResult =
  Apollo.MutationResult<TravelCompetitionSlideTimeMutation>;
export type TravelCompetitionSlideTimeMutationOptions = Apollo.BaseMutationOptions<
  TravelCompetitionSlideTimeMutation,
  TravelCompetitionSlideTimeMutationVariables
>;
export const PregameCopySeasonsListDocument = gql`
  query PregameCopySeasonsList {
    seasons(sort: label_asc) {
      edges {
        cursor
        node {
          id
          label
        }
      }
    }
  }
`;

/**
 * __usePregameCopySeasonsListQuery__
 *
 * To run a query within a React component, call `usePregameCopySeasonsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePregameCopySeasonsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePregameCopySeasonsListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePregameCopySeasonsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PregameCopySeasonsListQuery,
    PregameCopySeasonsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PregameCopySeasonsListQuery, PregameCopySeasonsListQueryVariables>(
    PregameCopySeasonsListDocument,
    options,
  );
}
export function usePregameCopySeasonsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PregameCopySeasonsListQuery,
    PregameCopySeasonsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PregameCopySeasonsListQuery, PregameCopySeasonsListQueryVariables>(
    PregameCopySeasonsListDocument,
    options,
  );
}
export type PregameCopySeasonsListQueryHookResult = ReturnType<
  typeof usePregameCopySeasonsListQuery
>;
export type PregameCopySeasonsListLazyQueryHookResult = ReturnType<
  typeof usePregameCopySeasonsListLazyQuery
>;
export type PregameCopySeasonsListQueryResult = Apollo.QueryResult<
  PregameCopySeasonsListQuery,
  PregameCopySeasonsListQueryVariables
>;
export function refetchPregameCopySeasonsListQuery(
  variables?: PregameCopySeasonsListQueryVariables,
) {
  return { query: PregameCopySeasonsListDocument, variables: variables };
}
export const PregameCopyTripsListDocument = gql`
  query PregameCopyTripsList($season: ID) {
    trips(season: $season) {
      totalCount
      edges {
        node {
          pk
          id
          label
        }
      }
    }
  }
`;

/**
 * __usePregameCopyTripsListQuery__
 *
 * To run a query within a React component, call `usePregameCopyTripsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePregameCopyTripsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePregameCopyTripsListQuery({
 *   variables: {
 *      season: // value for 'season'
 *   },
 * });
 */
export function usePregameCopyTripsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PregameCopyTripsListQuery,
    PregameCopyTripsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PregameCopyTripsListQuery, PregameCopyTripsListQueryVariables>(
    PregameCopyTripsListDocument,
    options,
  );
}
export function usePregameCopyTripsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PregameCopyTripsListQuery,
    PregameCopyTripsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PregameCopyTripsListQuery, PregameCopyTripsListQueryVariables>(
    PregameCopyTripsListDocument,
    options,
  );
}
export type PregameCopyTripsListQueryHookResult = ReturnType<typeof usePregameCopyTripsListQuery>;
export type PregameCopyTripsListLazyQueryHookResult = ReturnType<
  typeof usePregameCopyTripsListLazyQuery
>;
export type PregameCopyTripsListQueryResult = Apollo.QueryResult<
  PregameCopyTripsListQuery,
  PregameCopyTripsListQueryVariables
>;
export function refetchPregameCopyTripsListQuery(variables?: PregameCopyTripsListQueryVariables) {
  return { query: PregameCopyTripsListDocument, variables: variables };
}
export const UpdateCompetitionPregameVisibilitiesDocument = gql`
  mutation UpdateCompetitionPregameVisibilities(
    $id: ID!
    $input: CompetitionPregameVisibilityInput
  ) {
    updateCompetitionPregameVisibilities(id: $id, input: $input) {
      competition {
        id
        pregameVisibilities
      }
    }
  }
`;
export type UpdateCompetitionPregameVisibilitiesMutationFn = Apollo.MutationFunction<
  UpdateCompetitionPregameVisibilitiesMutation,
  UpdateCompetitionPregameVisibilitiesMutationVariables
>;

/**
 * __useUpdateCompetitionPregameVisibilitiesMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionPregameVisibilitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionPregameVisibilitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionPregameVisibilitiesMutation, { data, loading, error }] = useUpdateCompetitionPregameVisibilitiesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompetitionPregameVisibilitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompetitionPregameVisibilitiesMutation,
    UpdateCompetitionPregameVisibilitiesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompetitionPregameVisibilitiesMutation,
    UpdateCompetitionPregameVisibilitiesMutationVariables
  >(UpdateCompetitionPregameVisibilitiesDocument, options);
}
export type UpdateCompetitionPregameVisibilitiesMutationHookResult = ReturnType<
  typeof useUpdateCompetitionPregameVisibilitiesMutation
>;
export type UpdateCompetitionPregameVisibilitiesMutationResult =
  Apollo.MutationResult<UpdateCompetitionPregameVisibilitiesMutation>;
export type UpdateCompetitionPregameVisibilitiesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompetitionPregameVisibilitiesMutation,
  UpdateCompetitionPregameVisibilitiesMutationVariables
>;
export const TravelSeatplanCopyDocument = gql`
  mutation TravelSeatplanCopy($input: TravelSeatplanCopyInput!) {
    travelSeatplanCopy(input: $input) {
      travelSeatplan {
        id
        label
        travelVendorTypeId
        seatplanJson
        trip {
          label
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type TravelSeatplanCopyMutationFn = Apollo.MutationFunction<
  TravelSeatplanCopyMutation,
  TravelSeatplanCopyMutationVariables
>;

/**
 * __useTravelSeatplanCopyMutation__
 *
 * To run a mutation, you first call `useTravelSeatplanCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelSeatplanCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelSeatplanCopyMutation, { data, loading, error }] = useTravelSeatplanCopyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelSeatplanCopyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelSeatplanCopyMutation,
    TravelSeatplanCopyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelSeatplanCopyMutation, TravelSeatplanCopyMutationVariables>(
    TravelSeatplanCopyDocument,
    options,
  );
}
export type TravelSeatplanCopyMutationHookResult = ReturnType<typeof useTravelSeatplanCopyMutation>;
export type TravelSeatplanCopyMutationResult = Apollo.MutationResult<TravelSeatplanCopyMutation>;
export type TravelSeatplanCopyMutationOptions = Apollo.BaseMutationOptions<
  TravelSeatplanCopyMutation,
  TravelSeatplanCopyMutationVariables
>;
export const TravelSeatplanCopyOverrideDocument = gql`
  mutation TravelSeatplanCopyOverride($input: TravelSeatplanCopyOverrideInput!) {
    travelSeatplanCopyOverride(input: $input) {
      travelSeatplan {
        id
        label
        travelVendorTypeId
        seatplanJson
        trip {
          label
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type TravelSeatplanCopyOverrideMutationFn = Apollo.MutationFunction<
  TravelSeatplanCopyOverrideMutation,
  TravelSeatplanCopyOverrideMutationVariables
>;

/**
 * __useTravelSeatplanCopyOverrideMutation__
 *
 * To run a mutation, you first call `useTravelSeatplanCopyOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelSeatplanCopyOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelSeatplanCopyOverrideMutation, { data, loading, error }] = useTravelSeatplanCopyOverrideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelSeatplanCopyOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelSeatplanCopyOverrideMutation,
    TravelSeatplanCopyOverrideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TravelSeatplanCopyOverrideMutation,
    TravelSeatplanCopyOverrideMutationVariables
  >(TravelSeatplanCopyOverrideDocument, options);
}
export type TravelSeatplanCopyOverrideMutationHookResult = ReturnType<
  typeof useTravelSeatplanCopyOverrideMutation
>;
export type TravelSeatplanCopyOverrideMutationResult =
  Apollo.MutationResult<TravelSeatplanCopyOverrideMutation>;
export type TravelSeatplanCopyOverrideMutationOptions = Apollo.BaseMutationOptions<
  TravelSeatplanCopyOverrideMutation,
  TravelSeatplanCopyOverrideMutationVariables
>;
export const CreateTravelSeatplanDocument = gql`
  mutation CreateTravelSeatplan($input: TravelSeatplanCreateInput!) {
    travelSeatplanCreate(input: $input) {
      travelSeatplan {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CreateTravelSeatplanMutationFn = Apollo.MutationFunction<
  CreateTravelSeatplanMutation,
  CreateTravelSeatplanMutationVariables
>;

/**
 * __useCreateTravelSeatplanMutation__
 *
 * To run a mutation, you first call `useCreateTravelSeatplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTravelSeatplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTravelSeatplanMutation, { data, loading, error }] = useCreateTravelSeatplanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTravelSeatplanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTravelSeatplanMutation,
    CreateTravelSeatplanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTravelSeatplanMutation, CreateTravelSeatplanMutationVariables>(
    CreateTravelSeatplanDocument,
    options,
  );
}
export type CreateTravelSeatplanMutationHookResult = ReturnType<
  typeof useCreateTravelSeatplanMutation
>;
export type CreateTravelSeatplanMutationResult =
  Apollo.MutationResult<CreateTravelSeatplanMutation>;
export type CreateTravelSeatplanMutationOptions = Apollo.BaseMutationOptions<
  CreateTravelSeatplanMutation,
  CreateTravelSeatplanMutationVariables
>;
export const DeleteTravelSeatplanDocument = gql`
  mutation DeleteTravelSeatplan($id: ID!) {
    travelSeatplanDelete(id: $id) {
      travelSeatplan {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type DeleteTravelSeatplanMutationFn = Apollo.MutationFunction<
  DeleteTravelSeatplanMutation,
  DeleteTravelSeatplanMutationVariables
>;

/**
 * __useDeleteTravelSeatplanMutation__
 *
 * To run a mutation, you first call `useDeleteTravelSeatplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTravelSeatplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTravelSeatplanMutation, { data, loading, error }] = useDeleteTravelSeatplanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTravelSeatplanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTravelSeatplanMutation,
    DeleteTravelSeatplanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTravelSeatplanMutation, DeleteTravelSeatplanMutationVariables>(
    DeleteTravelSeatplanDocument,
    options,
  );
}
export type DeleteTravelSeatplanMutationHookResult = ReturnType<
  typeof useDeleteTravelSeatplanMutation
>;
export type DeleteTravelSeatplanMutationResult =
  Apollo.MutationResult<DeleteTravelSeatplanMutation>;
export type DeleteTravelSeatplanMutationOptions = Apollo.BaseMutationOptions<
  DeleteTravelSeatplanMutation,
  DeleteTravelSeatplanMutationVariables
>;
export const UpdateTravelSeatplanDocument = gql`
  mutation UpdateTravelSeatplan($id: ID!, $input: TravelSeatplanUpdateInput!) {
    travelSeatplanUpdate(id: $id, input: $input) {
      travelSeatplan {
        id
        label
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateTravelSeatplanMutationFn = Apollo.MutationFunction<
  UpdateTravelSeatplanMutation,
  UpdateTravelSeatplanMutationVariables
>;

/**
 * __useUpdateTravelSeatplanMutation__
 *
 * To run a mutation, you first call `useUpdateTravelSeatplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTravelSeatplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTravelSeatplanMutation, { data, loading, error }] = useUpdateTravelSeatplanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTravelSeatplanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTravelSeatplanMutation,
    UpdateTravelSeatplanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTravelSeatplanMutation, UpdateTravelSeatplanMutationVariables>(
    UpdateTravelSeatplanDocument,
    options,
  );
}
export type UpdateTravelSeatplanMutationHookResult = ReturnType<
  typeof useUpdateTravelSeatplanMutation
>;
export type UpdateTravelSeatplanMutationResult =
  Apollo.MutationResult<UpdateTravelSeatplanMutation>;
export type UpdateTravelSeatplanMutationOptions = Apollo.BaseMutationOptions<
  UpdateTravelSeatplanMutation,
  UpdateTravelSeatplanMutationVariables
>;
export const TripSeatplanListDocument = gql`
  query TripSeatplanList($tripIdFilter: ID!, $travelVendorTypeIdFilter: [ID]) {
    tripSeatplans(
      tripIdFilter: $tripIdFilter
      travelVendorTypeIdFilter: $travelVendorTypeIdFilter
      sort: created_date_asc
    ) {
      edges {
        node {
          id
          label
          seatplanJson
          travelSeatplanId
          travelVendorType {
            label
          }
          createdDate
        }
      }
    }
  }
`;

/**
 * __useTripSeatplanListQuery__
 *
 * To run a query within a React component, call `useTripSeatplanListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripSeatplanListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripSeatplanListQuery({
 *   variables: {
 *      tripIdFilter: // value for 'tripIdFilter'
 *      travelVendorTypeIdFilter: // value for 'travelVendorTypeIdFilter'
 *   },
 * });
 */
export function useTripSeatplanListQuery(
  baseOptions: Apollo.QueryHookOptions<TripSeatplanListQuery, TripSeatplanListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripSeatplanListQuery, TripSeatplanListQueryVariables>(
    TripSeatplanListDocument,
    options,
  );
}
export function useTripSeatplanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TripSeatplanListQuery, TripSeatplanListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripSeatplanListQuery, TripSeatplanListQueryVariables>(
    TripSeatplanListDocument,
    options,
  );
}
export type TripSeatplanListQueryHookResult = ReturnType<typeof useTripSeatplanListQuery>;
export type TripSeatplanListLazyQueryHookResult = ReturnType<typeof useTripSeatplanListLazyQuery>;
export type TripSeatplanListQueryResult = Apollo.QueryResult<
  TripSeatplanListQuery,
  TripSeatplanListQueryVariables
>;
export function refetchTripSeatplanListQuery(variables: TripSeatplanListQueryVariables) {
  return { query: TripSeatplanListDocument, variables: variables };
}
export const BulkUpdateTasksDocument = gql`
  mutation BulkUpdateTasks($id: ID!, $input: TravelTaskBulkUpdateInput!) {
    travelTaskBulkUpdate(id: $id, input: $input) {
      userErrors {
        field
        message
      }
      bulkUserErrors {
        objectKey
        errorList {
          field
          message
        }
      }
    }
  }
`;
export type BulkUpdateTasksMutationFn = Apollo.MutationFunction<
  BulkUpdateTasksMutation,
  BulkUpdateTasksMutationVariables
>;

/**
 * __useBulkUpdateTasksMutation__
 *
 * To run a mutation, you first call `useBulkUpdateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateTasksMutation, { data, loading, error }] = useBulkUpdateTasksMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateTasksMutation,
    BulkUpdateTasksMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkUpdateTasksMutation, BulkUpdateTasksMutationVariables>(
    BulkUpdateTasksDocument,
    options,
  );
}
export type BulkUpdateTasksMutationHookResult = ReturnType<typeof useBulkUpdateTasksMutation>;
export type BulkUpdateTasksMutationResult = Apollo.MutationResult<BulkUpdateTasksMutation>;
export type BulkUpdateTasksMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateTasksMutation,
  BulkUpdateTasksMutationVariables
>;
export const CopyTasksDocument = gql`
  mutation CopyTasks($id: ID!, $input: TravelTaskCopyInput!) {
    travelTaskCopy(id: $id, input: $input) {
      userErrors {
        field
        message
      }
      bulkUserErrors {
        objectKey
        errorList {
          field
          message
        }
      }
    }
  }
`;
export type CopyTasksMutationFn = Apollo.MutationFunction<
  CopyTasksMutation,
  CopyTasksMutationVariables
>;

/**
 * __useCopyTasksMutation__
 *
 * To run a mutation, you first call `useCopyTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTasksMutation, { data, loading, error }] = useCopyTasksMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyTasksMutation, CopyTasksMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CopyTasksMutation, CopyTasksMutationVariables>(
    CopyTasksDocument,
    options,
  );
}
export type CopyTasksMutationHookResult = ReturnType<typeof useCopyTasksMutation>;
export type CopyTasksMutationResult = Apollo.MutationResult<CopyTasksMutation>;
export type CopyTasksMutationOptions = Apollo.BaseMutationOptions<
  CopyTasksMutation,
  CopyTasksMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation CreateTask($input: TravelTaskCreateInput!) {
    travelTaskCreate(input: $input) {
      userErrors {
        field
        message
      }
      bulkUserErrors {
        objectKey
        errorList {
          field
          message
        }
      }
    }
  }
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options,
  );
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation DeleteTask($id: ID!) {
    travelTaskDelete(id: $id) {
      userErrors {
        field
        message
      }
      bulkUserErrors {
        objectKey
        errorList {
          field
          message
        }
      }
    }
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options,
  );
}
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const TasksDocument = gql`
  query Tasks($tripId: ID!) {
    travelTask(tripIdFilter: $tripId) {
      edges {
        cursor
        node {
          pk
          isCompleted
          travelTaskId
          taskName
          taskOrder
        }
      }
    }
  }
`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useTasksQuery(
  baseOptions: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export function useTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export function refetchTasksQuery(variables: TasksQueryVariables) {
  return { query: TasksDocument, variables: variables };
}
export const UpdateTaskDocument = gql`
  mutation UpdateTask($id: ID!, $input: TravelTaskUpdateInput!) {
    travelTaskUpdate(id: $id, input: $input) {
      userErrors {
        field
        message
      }
      bulkUserErrors {
        objectKey
        errorList {
          field
          message
        }
      }
    }
  }
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options,
  );
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const GetContactDetailsDocument = gql`
  query getContactDetails($id: ID!) {
    person: node(id: $id) {
      ... on PersonNode {
        initials
        label
        pictureUrl
        contacts {
          gender
          travelWeight
          id
          emailAddress
          firstName
          middleName
          maritalStatusId
          phoneCell
          phoneHome
          phoneOffice
          lastName
          initials
          pictureUrl
          displayName
          dateOfBirth
          mobilePhone
          relationship {
            id
            label
          }
          selectionCode
          addresses {
            isMailing
            description
            street
            street2
            city
            state
            zip
            countryIso
          }
        }
      }
    }
  }
`;

/**
 * __useGetContactDetailsQuery__
 *
 * To run a query within a React component, call `useGetContactDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetContactDetailsQuery, GetContactDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactDetailsQuery, GetContactDetailsQueryVariables>(
    GetContactDetailsDocument,
    options,
  );
}
export function useGetContactDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactDetailsQuery,
    GetContactDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContactDetailsQuery, GetContactDetailsQueryVariables>(
    GetContactDetailsDocument,
    options,
  );
}
export type GetContactDetailsQueryHookResult = ReturnType<typeof useGetContactDetailsQuery>;
export type GetContactDetailsLazyQueryHookResult = ReturnType<typeof useGetContactDetailsLazyQuery>;
export type GetContactDetailsQueryResult = Apollo.QueryResult<
  GetContactDetailsQuery,
  GetContactDetailsQueryVariables
>;
export function refetchGetContactDetailsQuery(variables: GetContactDetailsQueryVariables) {
  return { query: GetContactDetailsDocument, variables: variables };
}
export const TravelPartyRelationshipTypesDocument = gql`
  query TravelPartyRelationshipTypes {
    relationshipTypes {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

/**
 * __useTravelPartyRelationshipTypesQuery__
 *
 * To run a query within a React component, call `useTravelPartyRelationshipTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelPartyRelationshipTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelPartyRelationshipTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTravelPartyRelationshipTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TravelPartyRelationshipTypesQuery,
    TravelPartyRelationshipTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TravelPartyRelationshipTypesQuery,
    TravelPartyRelationshipTypesQueryVariables
  >(TravelPartyRelationshipTypesDocument, options);
}
export function useTravelPartyRelationshipTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelPartyRelationshipTypesQuery,
    TravelPartyRelationshipTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TravelPartyRelationshipTypesQuery,
    TravelPartyRelationshipTypesQueryVariables
  >(TravelPartyRelationshipTypesDocument, options);
}
export type TravelPartyRelationshipTypesQueryHookResult = ReturnType<
  typeof useTravelPartyRelationshipTypesQuery
>;
export type TravelPartyRelationshipTypesLazyQueryHookResult = ReturnType<
  typeof useTravelPartyRelationshipTypesLazyQuery
>;
export type TravelPartyRelationshipTypesQueryResult = Apollo.QueryResult<
  TravelPartyRelationshipTypesQuery,
  TravelPartyRelationshipTypesQueryVariables
>;
export function refetchTravelPartyRelationshipTypesQuery(
  variables?: TravelPartyRelationshipTypesQueryVariables,
) {
  return { query: TravelPartyRelationshipTypesDocument, variables: variables };
}
export const SendTravelPartyAssignmentsDocument = gql`
  mutation SendTravelPartyAssignments($id: ID!, $input: SendTravelPartyAssignmentInput) {
    sendTravelPartyAssignments(id: $id, input: $input) {
      trip {
        id
      }
    }
  }
`;
export type SendTravelPartyAssignmentsMutationFn = Apollo.MutationFunction<
  SendTravelPartyAssignmentsMutation,
  SendTravelPartyAssignmentsMutationVariables
>;

/**
 * __useSendTravelPartyAssignmentsMutation__
 *
 * To run a mutation, you first call `useSendTravelPartyAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTravelPartyAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTravelPartyAssignmentsMutation, { data, loading, error }] = useSendTravelPartyAssignmentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTravelPartyAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendTravelPartyAssignmentsMutation,
    SendTravelPartyAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendTravelPartyAssignmentsMutation,
    SendTravelPartyAssignmentsMutationVariables
  >(SendTravelPartyAssignmentsDocument, options);
}
export type SendTravelPartyAssignmentsMutationHookResult = ReturnType<
  typeof useSendTravelPartyAssignmentsMutation
>;
export type SendTravelPartyAssignmentsMutationResult =
  Apollo.MutationResult<SendTravelPartyAssignmentsMutation>;
export type SendTravelPartyAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  SendTravelPartyAssignmentsMutation,
  SendTravelPartyAssignmentsMutationVariables
>;
export const BulkDeleteTravelPartyDocument = gql`
  mutation BulkDeleteTravelParty($input: TripBulkDeleteTravelPartyInput!, $id: ID!) {
    tripBulkDeleteTravelParty(input: $input, id: $id) {
      trip {
        travelPartyPersons {
          person {
            firstName
            lastName
            middleName
            gender
            travelWeight
          }
        }
      }
    }
  }
`;
export type BulkDeleteTravelPartyMutationFn = Apollo.MutationFunction<
  BulkDeleteTravelPartyMutation,
  BulkDeleteTravelPartyMutationVariables
>;

/**
 * __useBulkDeleteTravelPartyMutation__
 *
 * To run a mutation, you first call `useBulkDeleteTravelPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteTravelPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteTravelPartyMutation, { data, loading, error }] = useBulkDeleteTravelPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBulkDeleteTravelPartyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkDeleteTravelPartyMutation,
    BulkDeleteTravelPartyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkDeleteTravelPartyMutation, BulkDeleteTravelPartyMutationVariables>(
    BulkDeleteTravelPartyDocument,
    options,
  );
}
export type BulkDeleteTravelPartyMutationHookResult = ReturnType<
  typeof useBulkDeleteTravelPartyMutation
>;
export type BulkDeleteTravelPartyMutationResult =
  Apollo.MutationResult<BulkDeleteTravelPartyMutation>;
export type BulkDeleteTravelPartyMutationOptions = Apollo.BaseMutationOptions<
  BulkDeleteTravelPartyMutation,
  BulkDeleteTravelPartyMutationVariables
>;
export const BulkUpdateTravelPartyDocument = gql`
  mutation BulkUpdateTravelParty($input: TripBulkUpdateTravelPartyInput!, $id: ID!) {
    tripBulkUpdateTravelParty(input: $input, id: $id) {
      trip {
        travelPartyPersons {
          person {
            firstName
            lastName
            middleName
            gender
            travelWeight
          }
        }
      }
    }
  }
`;
export type BulkUpdateTravelPartyMutationFn = Apollo.MutationFunction<
  BulkUpdateTravelPartyMutation,
  BulkUpdateTravelPartyMutationVariables
>;

/**
 * __useBulkUpdateTravelPartyMutation__
 *
 * To run a mutation, you first call `useBulkUpdateTravelPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateTravelPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateTravelPartyMutation, { data, loading, error }] = useBulkUpdateTravelPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBulkUpdateTravelPartyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateTravelPartyMutation,
    BulkUpdateTravelPartyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkUpdateTravelPartyMutation, BulkUpdateTravelPartyMutationVariables>(
    BulkUpdateTravelPartyDocument,
    options,
  );
}
export type BulkUpdateTravelPartyMutationHookResult = ReturnType<
  typeof useBulkUpdateTravelPartyMutation
>;
export type BulkUpdateTravelPartyMutationResult =
  Apollo.MutationResult<BulkUpdateTravelPartyMutation>;
export type BulkUpdateTravelPartyMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateTravelPartyMutation,
  BulkUpdateTravelPartyMutationVariables
>;
export const DeleteTravelPartyPersonDocument = gql`
  mutation DeleteTravelPartyPerson($id: ID!, $input: DeleteTravelPartyPersonInput) {
    deleteTravelPartyPerson(id: $id, input: $input) {
      trip {
        id
      }
    }
  }
`;
export type DeleteTravelPartyPersonMutationFn = Apollo.MutationFunction<
  DeleteTravelPartyPersonMutation,
  DeleteTravelPartyPersonMutationVariables
>;

/**
 * __useDeleteTravelPartyPersonMutation__
 *
 * To run a mutation, you first call `useDeleteTravelPartyPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTravelPartyPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTravelPartyPersonMutation, { data, loading, error }] = useDeleteTravelPartyPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTravelPartyPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTravelPartyPersonMutation,
    DeleteTravelPartyPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTravelPartyPersonMutation,
    DeleteTravelPartyPersonMutationVariables
  >(DeleteTravelPartyPersonDocument, options);
}
export type DeleteTravelPartyPersonMutationHookResult = ReturnType<
  typeof useDeleteTravelPartyPersonMutation
>;
export type DeleteTravelPartyPersonMutationResult =
  Apollo.MutationResult<DeleteTravelPartyPersonMutation>;
export type DeleteTravelPartyPersonMutationOptions = Apollo.BaseMutationOptions<
  DeleteTravelPartyPersonMutation,
  DeleteTravelPartyPersonMutationVariables
>;
export const TripTravelPartyEditDocument = gql`
  mutation TripTravelPartyEdit($id: ID!, $input: ModifyTravelPartyInput) {
    modifyTravelParty(id: $id, input: $input) {
      trip {
        id
      }
    }
  }
`;
export type TripTravelPartyEditMutationFn = Apollo.MutationFunction<
  TripTravelPartyEditMutation,
  TripTravelPartyEditMutationVariables
>;

/**
 * __useTripTravelPartyEditMutation__
 *
 * To run a mutation, you first call `useTripTravelPartyEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTripTravelPartyEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tripTravelPartyEditMutation, { data, loading, error }] = useTripTravelPartyEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTripTravelPartyEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TripTravelPartyEditMutation,
    TripTravelPartyEditMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TripTravelPartyEditMutation, TripTravelPartyEditMutationVariables>(
    TripTravelPartyEditDocument,
    options,
  );
}
export type TripTravelPartyEditMutationHookResult = ReturnType<
  typeof useTripTravelPartyEditMutation
>;
export type TripTravelPartyEditMutationResult = Apollo.MutationResult<TripTravelPartyEditMutation>;
export type TripTravelPartyEditMutationOptions = Apollo.BaseMutationOptions<
  TripTravelPartyEditMutation,
  TripTravelPartyEditMutationVariables
>;
export const CreateSeasonDocument = gql`
  mutation CreateSeason($input: SeasonInput) {
    seasonCreate(input: $input) {
      season {
        id
      }
    }
  }
`;
export type CreateSeasonMutationFn = Apollo.MutationFunction<
  CreateSeasonMutation,
  CreateSeasonMutationVariables
>;

/**
 * __useCreateSeasonMutation__
 *
 * To run a mutation, you first call `useCreateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonMutation, { data, loading, error }] = useCreateSeasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSeasonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSeasonMutation, CreateSeasonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSeasonMutation, CreateSeasonMutationVariables>(
    CreateSeasonDocument,
    options,
  );
}
export type CreateSeasonMutationHookResult = ReturnType<typeof useCreateSeasonMutation>;
export type CreateSeasonMutationResult = Apollo.MutationResult<CreateSeasonMutation>;
export type CreateSeasonMutationOptions = Apollo.BaseMutationOptions<
  CreateSeasonMutation,
  CreateSeasonMutationVariables
>;
export const DeleteSeasonDocument = gql`
  mutation DeleteSeason($id: ID) {
    seasonDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type DeleteSeasonMutationFn = Apollo.MutationFunction<
  DeleteSeasonMutation,
  DeleteSeasonMutationVariables
>;

/**
 * __useDeleteSeasonMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonMutation, { data, loading, error }] = useDeleteSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSeasonMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSeasonMutation, DeleteSeasonMutationVariables>(
    DeleteSeasonDocument,
    options,
  );
}
export type DeleteSeasonMutationHookResult = ReturnType<typeof useDeleteSeasonMutation>;
export type DeleteSeasonMutationResult = Apollo.MutationResult<DeleteSeasonMutation>;
export type DeleteSeasonMutationOptions = Apollo.BaseMutationOptions<
  DeleteSeasonMutation,
  DeleteSeasonMutationVariables
>;
export const UpdateSeasonDocument = gql`
  mutation UpdateSeason($id: ID!, $input: SeasonInput) {
    seasonUpdate(id: $id, input: $input) {
      season {
        id
      }
    }
  }
`;
export type UpdateSeasonMutationFn = Apollo.MutationFunction<
  UpdateSeasonMutation,
  UpdateSeasonMutationVariables
>;

/**
 * __useUpdateSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonMutation, { data, loading, error }] = useUpdateSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSeasonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSeasonMutation, UpdateSeasonMutationVariables>(
    UpdateSeasonDocument,
    options,
  );
}
export type UpdateSeasonMutationHookResult = ReturnType<typeof useUpdateSeasonMutation>;
export type UpdateSeasonMutationResult = Apollo.MutationResult<UpdateSeasonMutation>;
export type UpdateSeasonMutationOptions = Apollo.BaseMutationOptions<
  UpdateSeasonMutation,
  UpdateSeasonMutationVariables
>;
export const SeasonsListDocument = gql`
  query SeasonsList {
    seasons(sort: label_asc) {
      edges {
        cursor
        node {
          id
          label
          hasTrips
          createdDate
        }
      }
    }
  }
`;

/**
 * __useSeasonsListQuery__
 *
 * To run a query within a React component, call `useSeasonsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeasonsListQuery(
  baseOptions?: Apollo.QueryHookOptions<SeasonsListQuery, SeasonsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeasonsListQuery, SeasonsListQueryVariables>(SeasonsListDocument, options);
}
export function useSeasonsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SeasonsListQuery, SeasonsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeasonsListQuery, SeasonsListQueryVariables>(
    SeasonsListDocument,
    options,
  );
}
export type SeasonsListQueryHookResult = ReturnType<typeof useSeasonsListQuery>;
export type SeasonsListLazyQueryHookResult = ReturnType<typeof useSeasonsListLazyQuery>;
export type SeasonsListQueryResult = Apollo.QueryResult<
  SeasonsListQuery,
  SeasonsListQueryVariables
>;
export function refetchSeasonsListQuery(variables?: SeasonsListQueryVariables) {
  return { query: SeasonsListDocument, variables: variables };
}
export const FetchTmpYearsWithDatesDocument = gql`
  query FetchTMPYearsWithDates($orgId: TWFilter_Int!) {
    tmpYears(orgId: $orgId) {
      totalCount
      edges {
        node {
          id
          isCurrent
          tmpYearId
          label
          orgId
          dates {
            edges {
              node {
                dateType {
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTmpYearsWithDatesQuery__
 *
 * To run a query within a React component, call `useFetchTmpYearsWithDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTmpYearsWithDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTmpYearsWithDatesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useFetchTmpYearsWithDatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchTmpYearsWithDatesQuery,
    FetchTmpYearsWithDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTmpYearsWithDatesQuery, FetchTmpYearsWithDatesQueryVariables>(
    FetchTmpYearsWithDatesDocument,
    options,
  );
}
export function useFetchTmpYearsWithDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTmpYearsWithDatesQuery,
    FetchTmpYearsWithDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTmpYearsWithDatesQuery, FetchTmpYearsWithDatesQueryVariables>(
    FetchTmpYearsWithDatesDocument,
    options,
  );
}
export type FetchTmpYearsWithDatesQueryHookResult = ReturnType<
  typeof useFetchTmpYearsWithDatesQuery
>;
export type FetchTmpYearsWithDatesLazyQueryHookResult = ReturnType<
  typeof useFetchTmpYearsWithDatesLazyQuery
>;
export type FetchTmpYearsWithDatesQueryResult = Apollo.QueryResult<
  FetchTmpYearsWithDatesQuery,
  FetchTmpYearsWithDatesQueryVariables
>;
export function refetchFetchTmpYearsWithDatesQuery(
  variables: FetchTmpYearsWithDatesQueryVariables,
) {
  return { query: FetchTmpYearsWithDatesDocument, variables: variables };
}
export const TravelParticipationReportDocument = gql`
  query TravelParticipationReport {
    travelParticipationReport {
      edges {
        node {
          createdDate
          downloadableFileId
          errorMessage
          kind
          status
          title
          url
        }
      }
    }
  }
`;

/**
 * __useTravelParticipationReportQuery__
 *
 * To run a query within a React component, call `useTravelParticipationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelParticipationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelParticipationReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useTravelParticipationReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TravelParticipationReportQuery,
    TravelParticipationReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TravelParticipationReportQuery, TravelParticipationReportQueryVariables>(
    TravelParticipationReportDocument,
    options,
  );
}
export function useTravelParticipationReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TravelParticipationReportQuery,
    TravelParticipationReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TravelParticipationReportQuery,
    TravelParticipationReportQueryVariables
  >(TravelParticipationReportDocument, options);
}
export type TravelParticipationReportQueryHookResult = ReturnType<
  typeof useTravelParticipationReportQuery
>;
export type TravelParticipationReportLazyQueryHookResult = ReturnType<
  typeof useTravelParticipationReportLazyQuery
>;
export type TravelParticipationReportQueryResult = Apollo.QueryResult<
  TravelParticipationReportQuery,
  TravelParticipationReportQueryVariables
>;
export function refetchTravelParticipationReportQuery(
  variables?: TravelParticipationReportQueryVariables,
) {
  return { query: TravelParticipationReportDocument, variables: variables };
}
export const CreateTripDocument = gql`
  mutation CreateTrip($input: TripCreateInput) {
    tripCreate(input: $input) {
      trip {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type CreateTripMutationFn = Apollo.MutationFunction<
  CreateTripMutation,
  CreateTripMutationVariables
>;

/**
 * __useCreateTripMutation__
 *
 * To run a mutation, you first call `useCreateTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTripMutation, { data, loading, error }] = useCreateTripMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTripMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTripMutation, CreateTripMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTripMutation, CreateTripMutationVariables>(
    CreateTripDocument,
    options,
  );
}
export type CreateTripMutationHookResult = ReturnType<typeof useCreateTripMutation>;
export type CreateTripMutationResult = Apollo.MutationResult<CreateTripMutation>;
export type CreateTripMutationOptions = Apollo.BaseMutationOptions<
  CreateTripMutation,
  CreateTripMutationVariables
>;
export const DeleteTripDocument = gql`
  mutation DeleteTrip($id: ID) {
    tripDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type DeleteTripMutationFn = Apollo.MutationFunction<
  DeleteTripMutation,
  DeleteTripMutationVariables
>;

/**
 * __useDeleteTripMutation__
 *
 * To run a mutation, you first call `useDeleteTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTripMutation, { data, loading, error }] = useDeleteTripMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTripMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTripMutation, DeleteTripMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTripMutation, DeleteTripMutationVariables>(
    DeleteTripDocument,
    options,
  );
}
export type DeleteTripMutationHookResult = ReturnType<typeof useDeleteTripMutation>;
export type DeleteTripMutationResult = Apollo.MutationResult<DeleteTripMutation>;
export type DeleteTripMutationOptions = Apollo.BaseMutationOptions<
  DeleteTripMutation,
  DeleteTripMutationVariables
>;
export const UpdateTripDocument = gql`
  mutation UpdateTrip($id: ID!, $input: TripUpdateInput) {
    tripUpdate(id: $id, input: $input) {
      trip {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateTripMutationFn = Apollo.MutationFunction<
  UpdateTripMutation,
  UpdateTripMutationVariables
>;

/**
 * __useUpdateTripMutation__
 *
 * To run a mutation, you first call `useUpdateTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripMutation, { data, loading, error }] = useUpdateTripMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTripMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTripMutation, UpdateTripMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTripMutation, UpdateTripMutationVariables>(
    UpdateTripDocument,
    options,
  );
}
export type UpdateTripMutationHookResult = ReturnType<typeof useUpdateTripMutation>;
export type UpdateTripMutationResult = Apollo.MutationResult<UpdateTripMutation>;
export type UpdateTripMutationOptions = Apollo.BaseMutationOptions<
  UpdateTripMutation,
  UpdateTripMutationVariables
>;
export const TripsListDocument = gql`
  query TripsList(
    $season: ID
    $isInPast: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    trips(
      isInPast: $isInPast
      season: $season
      after: $after
      before: $before
      first: $first
      last: $last
      sort: [start_date_asc, start_time_asc]
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          pk
          id
          label
          startDateTime
          endDateTime
          logoUrl
          isVisibleMobile
          itineraryCalendarVisibility
          season {
            id
            label
          }
          seasons {
            id
            label
            team {
              id
              label
            }
          }
          status
          timeZone
          hasTicketAllocations
          visibility {
            selectionCode
            label
            ... on PersonNode {
              pictureUrl
              initials
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                selectionCode
                label
                pictureUrl
                initials
              }
            }
          }
          travelPartyMembers {
            ... on PersonNode {
              id
              pk
              preferredName
              lastName
              pictureUrl
              initials
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTripsListQuery__
 *
 * To run a query within a React component, call `useTripsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripsListQuery({
 *   variables: {
 *      season: // value for 'season'
 *      isInPast: // value for 'isInPast'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useTripsListQuery(
  baseOptions?: Apollo.QueryHookOptions<TripsListQuery, TripsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripsListQuery, TripsListQueryVariables>(TripsListDocument, options);
}
export function useTripsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TripsListQuery, TripsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripsListQuery, TripsListQueryVariables>(TripsListDocument, options);
}
export type TripsListQueryHookResult = ReturnType<typeof useTripsListQuery>;
export type TripsListLazyQueryHookResult = ReturnType<typeof useTripsListLazyQuery>;
export type TripsListQueryResult = Apollo.QueryResult<TripsListQuery, TripsListQueryVariables>;
export function refetchTripsListQuery(variables?: TripsListQueryVariables) {
  return { query: TripsListDocument, variables: variables };
}
export const TripCompetitionsBulkCreateDocument = gql`
  mutation TripCompetitionsBulkCreate($input: TripCompetitionsBulkCreateInput!) {
    tripCompetitionsBulkCreate(input: $input) {
      userErrors {
        field
        message
      }
      bulkUserErrors {
        errorList {
          message
        }
      }
    }
  }
`;
export type TripCompetitionsBulkCreateMutationFn = Apollo.MutationFunction<
  TripCompetitionsBulkCreateMutation,
  TripCompetitionsBulkCreateMutationVariables
>;

/**
 * __useTripCompetitionsBulkCreateMutation__
 *
 * To run a mutation, you first call `useTripCompetitionsBulkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTripCompetitionsBulkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tripCompetitionsBulkCreateMutation, { data, loading, error }] = useTripCompetitionsBulkCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTripCompetitionsBulkCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TripCompetitionsBulkCreateMutation,
    TripCompetitionsBulkCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TripCompetitionsBulkCreateMutation,
    TripCompetitionsBulkCreateMutationVariables
  >(TripCompetitionsBulkCreateDocument, options);
}
export type TripCompetitionsBulkCreateMutationHookResult = ReturnType<
  typeof useTripCompetitionsBulkCreateMutation
>;
export type TripCompetitionsBulkCreateMutationResult =
  Apollo.MutationResult<TripCompetitionsBulkCreateMutation>;
export type TripCompetitionsBulkCreateMutationOptions = Apollo.BaseMutationOptions<
  TripCompetitionsBulkCreateMutation,
  TripCompetitionsBulkCreateMutationVariables
>;
export const TravelContactCreateDocument = gql`
  mutation TravelContactCreate($input: TravelContactCreateInput) {
    travelContactCreate(input: $input) {
      travelContact {
        id
        firstName
        lastName
        phoneOffice
        phoneMobile
        phoneFax
        emailAddress
        isPrimaryContact
        title
      }
    }
  }
`;
export type TravelContactCreateMutationFn = Apollo.MutationFunction<
  TravelContactCreateMutation,
  TravelContactCreateMutationVariables
>;

/**
 * __useTravelContactCreateMutation__
 *
 * To run a mutation, you first call `useTravelContactCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelContactCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelContactCreateMutation, { data, loading, error }] = useTravelContactCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelContactCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelContactCreateMutation,
    TravelContactCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelContactCreateMutation, TravelContactCreateMutationVariables>(
    TravelContactCreateDocument,
    options,
  );
}
export type TravelContactCreateMutationHookResult = ReturnType<
  typeof useTravelContactCreateMutation
>;
export type TravelContactCreateMutationResult = Apollo.MutationResult<TravelContactCreateMutation>;
export type TravelContactCreateMutationOptions = Apollo.BaseMutationOptions<
  TravelContactCreateMutation,
  TravelContactCreateMutationVariables
>;
export const TravelContactDeleteDocument = gql`
  mutation TravelContactDelete($id: ID!) {
    travelContactDelete(id: $id) {
      travelContact {
        id
      }
    }
  }
`;
export type TravelContactDeleteMutationFn = Apollo.MutationFunction<
  TravelContactDeleteMutation,
  TravelContactDeleteMutationVariables
>;

/**
 * __useTravelContactDeleteMutation__
 *
 * To run a mutation, you first call `useTravelContactDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelContactDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelContactDeleteMutation, { data, loading, error }] = useTravelContactDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTravelContactDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelContactDeleteMutation,
    TravelContactDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelContactDeleteMutation, TravelContactDeleteMutationVariables>(
    TravelContactDeleteDocument,
    options,
  );
}
export type TravelContactDeleteMutationHookResult = ReturnType<
  typeof useTravelContactDeleteMutation
>;
export type TravelContactDeleteMutationResult = Apollo.MutationResult<TravelContactDeleteMutation>;
export type TravelContactDeleteMutationOptions = Apollo.BaseMutationOptions<
  TravelContactDeleteMutation,
  TravelContactDeleteMutationVariables
>;
export const TravelContactUpdateDocument = gql`
  mutation TravelContactUpdate($id: ID!, $input: TravelContactUpdateInput) {
    travelContactUpdate(id: $id, input: $input) {
      travelContact {
        id
        firstName
        lastName
        title
        emailAddress
        phoneMobile
        phoneOffice
        phoneFax
        isPrimaryContact
      }
    }
  }
`;
export type TravelContactUpdateMutationFn = Apollo.MutationFunction<
  TravelContactUpdateMutation,
  TravelContactUpdateMutationVariables
>;

/**
 * __useTravelContactUpdateMutation__
 *
 * To run a mutation, you first call `useTravelContactUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelContactUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelContactUpdateMutation, { data, loading, error }] = useTravelContactUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelContactUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelContactUpdateMutation,
    TravelContactUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelContactUpdateMutation, TravelContactUpdateMutationVariables>(
    TravelContactUpdateDocument,
    options,
  );
}
export type TravelContactUpdateMutationHookResult = ReturnType<
  typeof useTravelContactUpdateMutation
>;
export type TravelContactUpdateMutationResult = Apollo.MutationResult<TravelContactUpdateMutation>;
export type TravelContactUpdateMutationOptions = Apollo.BaseMutationOptions<
  TravelContactUpdateMutation,
  TravelContactUpdateMutationVariables
>;
export const NoteCreateDocument = gql`
  mutation NoteCreate($input: NoteInput) {
    noteCreate(input: $input) {
      note {
        id
        title
        content
        createdDateTime
        updatedDateTime
        author {
          displayName
          pictureUrl
          initials
          title
        }
      }
    }
  }
`;
export type NoteCreateMutationFn = Apollo.MutationFunction<
  NoteCreateMutation,
  NoteCreateMutationVariables
>;

/**
 * __useNoteCreateMutation__
 *
 * To run a mutation, you first call `useNoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteCreateMutation, { data, loading, error }] = useNoteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<NoteCreateMutation, NoteCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NoteCreateMutation, NoteCreateMutationVariables>(
    NoteCreateDocument,
    options,
  );
}
export type NoteCreateMutationHookResult = ReturnType<typeof useNoteCreateMutation>;
export type NoteCreateMutationResult = Apollo.MutationResult<NoteCreateMutation>;
export type NoteCreateMutationOptions = Apollo.BaseMutationOptions<
  NoteCreateMutation,
  NoteCreateMutationVariables
>;
export const NoteDeleteDocument = gql`
  mutation noteDelete($id: ID!) {
    noteDelete(id: $id) {
      note {
        id
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type NoteDeleteMutationFn = Apollo.MutationFunction<
  NoteDeleteMutation,
  NoteDeleteMutationVariables
>;

/**
 * __useNoteDeleteMutation__
 *
 * To run a mutation, you first call `useNoteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteDeleteMutation, { data, loading, error }] = useNoteDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<NoteDeleteMutation, NoteDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NoteDeleteMutation, NoteDeleteMutationVariables>(
    NoteDeleteDocument,
    options,
  );
}
export type NoteDeleteMutationHookResult = ReturnType<typeof useNoteDeleteMutation>;
export type NoteDeleteMutationResult = Apollo.MutationResult<NoteDeleteMutation>;
export type NoteDeleteMutationOptions = Apollo.BaseMutationOptions<
  NoteDeleteMutation,
  NoteDeleteMutationVariables
>;
export const EditNoteDocument = gql`
  mutation EditNote($id: ID, $input: NoteInput) {
    noteUpdate(id: $id, input: $input) {
      note {
        id
        title
        content
        createdDateTime
        updatedDateTime
        author {
          displayName
          pictureUrl
          initials
          title
        }
      }
    }
  }
`;
export type EditNoteMutationFn = Apollo.MutationFunction<
  EditNoteMutation,
  EditNoteMutationVariables
>;

/**
 * __useEditNoteMutation__
 *
 * To run a mutation, you first call `useEditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteMutation, { data, loading, error }] = useEditNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<EditNoteMutation, EditNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditNoteMutation, EditNoteMutationVariables>(EditNoteDocument, options);
}
export type EditNoteMutationHookResult = ReturnType<typeof useEditNoteMutation>;
export type EditNoteMutationResult = Apollo.MutationResult<EditNoteMutation>;
export type EditNoteMutationOptions = Apollo.BaseMutationOptions<
  EditNoteMutation,
  EditNoteMutationVariables
>;
export const SeatplansDocument = gql`
  query Seatplans($travelVendorId: TWFilter_ID) {
    vendors(vendorSearch: "Flight", travelVendorId: $travelVendorId) {
      edges {
        node {
          id
          name
          travelVendorId
          seatingCharts {
            id
            seatplanConfigurationJson
            label
            travelAirSeatingChartId
            numberOfSeats
          }
        }
      }
    }
  }
`;

/**
 * __useSeatplansQuery__
 *
 * To run a query within a React component, call `useSeatplansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeatplansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeatplansQuery({
 *   variables: {
 *      travelVendorId: // value for 'travelVendorId'
 *   },
 * });
 */
export function useSeatplansQuery(
  baseOptions?: Apollo.QueryHookOptions<SeatplansQuery, SeatplansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeatplansQuery, SeatplansQueryVariables>(SeatplansDocument, options);
}
export function useSeatplansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SeatplansQuery, SeatplansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeatplansQuery, SeatplansQueryVariables>(SeatplansDocument, options);
}
export type SeatplansQueryHookResult = ReturnType<typeof useSeatplansQuery>;
export type SeatplansLazyQueryHookResult = ReturnType<typeof useSeatplansLazyQuery>;
export type SeatplansQueryResult = Apollo.QueryResult<SeatplansQuery, SeatplansQueryVariables>;
export function refetchSeatplansQuery(variables?: SeatplansQueryVariables) {
  return { query: SeatplansDocument, variables: variables };
}
export const TeamsDocument = gql`
  query Teams($permission: String) {
    teams(permission: $permission) {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
}
export function useTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
}
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsQueryResult = Apollo.QueryResult<TeamsQuery, TeamsQueryVariables>;
export function refetchTeamsQuery(variables?: TeamsQueryVariables) {
  return { query: TeamsDocument, variables: variables };
}
export const TravelVendorCreateDocument = gql`
  mutation TravelVendorCreate($input: TravelVendorCreateInput) {
    travelVendorCreate(input: $input) {
      travelVendor {
        id
      }
    }
  }
`;
export type TravelVendorCreateMutationFn = Apollo.MutationFunction<
  TravelVendorCreateMutation,
  TravelVendorCreateMutationVariables
>;

/**
 * __useTravelVendorCreateMutation__
 *
 * To run a mutation, you first call `useTravelVendorCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelVendorCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelVendorCreateMutation, { data, loading, error }] = useTravelVendorCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTravelVendorCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TravelVendorCreateMutation,
    TravelVendorCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TravelVendorCreateMutation, TravelVendorCreateMutationVariables>(
    TravelVendorCreateDocument,
    options,
  );
}
export type TravelVendorCreateMutationHookResult = ReturnType<typeof useTravelVendorCreateMutation>;
export type TravelVendorCreateMutationResult = Apollo.MutationResult<TravelVendorCreateMutation>;
export type TravelVendorCreateMutationOptions = Apollo.BaseMutationOptions<
  TravelVendorCreateMutation,
  TravelVendorCreateMutationVariables
>;
export const DeleteVendorDocument = gql`
  mutation DeleteVendor($id: ID!) {
    travelVendorDelete(id: $id) {
      userErrors {
        message
        field
      }
    }
  }
`;
export type DeleteVendorMutationFn = Apollo.MutationFunction<
  DeleteVendorMutation,
  DeleteVendorMutationVariables
>;

/**
 * __useDeleteVendorMutation__
 *
 * To run a mutation, you first call `useDeleteVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorMutation, { data, loading, error }] = useDeleteVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVendorMutation, DeleteVendorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVendorMutation, DeleteVendorMutationVariables>(
    DeleteVendorDocument,
    options,
  );
}
export type DeleteVendorMutationHookResult = ReturnType<typeof useDeleteVendorMutation>;
export type DeleteVendorMutationResult = Apollo.MutationResult<DeleteVendorMutation>;
export type DeleteVendorMutationOptions = Apollo.BaseMutationOptions<
  DeleteVendorMutation,
  DeleteVendorMutationVariables
>;
export const EditVendorDocument = gql`
  mutation EditVendor($id: ID, $input: TravelVendorUpdateInput) {
    travelVendorUpdate(id: $id, input: $input) {
      travelVendor {
        id
      }
    }
  }
`;
export type EditVendorMutationFn = Apollo.MutationFunction<
  EditVendorMutation,
  EditVendorMutationVariables
>;

/**
 * __useEditVendorMutation__
 *
 * To run a mutation, you first call `useEditVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVendorMutation, { data, loading, error }] = useEditVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<EditVendorMutation, EditVendorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditVendorMutation, EditVendorMutationVariables>(
    EditVendorDocument,
    options,
  );
}
export type EditVendorMutationHookResult = ReturnType<typeof useEditVendorMutation>;
export type EditVendorMutationResult = Apollo.MutationResult<EditVendorMutation>;
export type EditVendorMutationOptions = Apollo.BaseMutationOptions<
  EditVendorMutation,
  EditVendorMutationVariables
>;
export const VendorTypesDocument = gql`
  query VendorTypes {
    vendorTypes {
      edges {
        node {
          label
          id
        }
      }
    }
  }
`;

/**
 * __useVendorTypesQuery__
 *
 * To run a query within a React component, call `useVendorTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVendorTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<VendorTypesQuery, VendorTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VendorTypesQuery, VendorTypesQueryVariables>(VendorTypesDocument, options);
}
export function useVendorTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VendorTypesQuery, VendorTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VendorTypesQuery, VendorTypesQueryVariables>(
    VendorTypesDocument,
    options,
  );
}
export type VendorTypesQueryHookResult = ReturnType<typeof useVendorTypesQuery>;
export type VendorTypesLazyQueryHookResult = ReturnType<typeof useVendorTypesLazyQuery>;
export type VendorTypesQueryResult = Apollo.QueryResult<
  VendorTypesQuery,
  VendorTypesQueryVariables
>;
export function refetchVendorTypesQuery(variables?: VendorTypesQueryVariables) {
  return { query: VendorTypesDocument, variables: variables };
}
export const VendorsDocument = gql`
  query Vendors {
    vendors {
      edges {
        node {
          id
          name
          travelVendorId
          travelVendorType {
            id
            label
          }
          phoneFax
          phoneMain
          address {
            street1
            street2
            city
            countryIso
            customStateProvince
            country {
              name
              printableName
              numcode
            }
            state {
              name
              code
            }
            zipCode
          }
          teams {
            id
            label
          }
          contacts {
            id
            firstName
            lastName
            phoneOffice
            phoneMobile
            phoneFax
            emailAddress
            isPrimaryContact
            title
          }
          notes {
            id
            title
            content
            createdDateTime
            updatedDateTime
            author {
              displayName
              pictureUrl
              initials
              title
            }
          }
        }
      }
    }
  }
`;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVendorsQuery(
  baseOptions?: Apollo.QueryHookOptions<VendorsQuery, VendorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
}
export function useVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VendorsQuery, VendorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
}
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = Apollo.QueryResult<VendorsQuery, VendorsQueryVariables>;
export function refetchVendorsQuery(variables?: VendorsQueryVariables) {
  return { query: VendorsDocument, variables: variables };
}
export const CreateSeatplanDocument = gql`
  mutation CreateSeatplan($input: TravelAirSeatingChartCreateInput) {
    travelAirSeatingChartCreate(input: $input) {
      travelAirSeatingChart {
        label
      }
    }
  }
`;
export type CreateSeatplanMutationFn = Apollo.MutationFunction<
  CreateSeatplanMutation,
  CreateSeatplanMutationVariables
>;

/**
 * __useCreateSeatplanMutation__
 *
 * To run a mutation, you first call `useCreateSeatplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeatplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeatplanMutation, { data, loading, error }] = useCreateSeatplanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSeatplanMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSeatplanMutation, CreateSeatplanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSeatplanMutation, CreateSeatplanMutationVariables>(
    CreateSeatplanDocument,
    options,
  );
}
export type CreateSeatplanMutationHookResult = ReturnType<typeof useCreateSeatplanMutation>;
export type CreateSeatplanMutationResult = Apollo.MutationResult<CreateSeatplanMutation>;
export type CreateSeatplanMutationOptions = Apollo.BaseMutationOptions<
  CreateSeatplanMutation,
  CreateSeatplanMutationVariables
>;
export const DeleteSeatplanDocument = gql`
  mutation DeleteSeatplan($id: ID!) {
    travelAirSeatingChartDelete(id: $id) {
      travelAirSeatingChart {
        label
      }
    }
  }
`;
export type DeleteSeatplanMutationFn = Apollo.MutationFunction<
  DeleteSeatplanMutation,
  DeleteSeatplanMutationVariables
>;

/**
 * __useDeleteSeatplanMutation__
 *
 * To run a mutation, you first call `useDeleteSeatplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeatplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeatplanMutation, { data, loading, error }] = useDeleteSeatplanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSeatplanMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSeatplanMutation, DeleteSeatplanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSeatplanMutation, DeleteSeatplanMutationVariables>(
    DeleteSeatplanDocument,
    options,
  );
}
export type DeleteSeatplanMutationHookResult = ReturnType<typeof useDeleteSeatplanMutation>;
export type DeleteSeatplanMutationResult = Apollo.MutationResult<DeleteSeatplanMutation>;
export type DeleteSeatplanMutationOptions = Apollo.BaseMutationOptions<
  DeleteSeatplanMutation,
  DeleteSeatplanMutationVariables
>;
export const UpdateSeatplanDocument = gql`
  mutation UpdateSeatplan($input: TravelAirSeatingChartUpdateInput, $id: ID!) {
    travelAirSeatingChartUpdate(input: $input, id: $id) {
      travelAirSeatingChart {
        label
      }
    }
  }
`;
export type UpdateSeatplanMutationFn = Apollo.MutationFunction<
  UpdateSeatplanMutation,
  UpdateSeatplanMutationVariables
>;

/**
 * __useUpdateSeatplanMutation__
 *
 * To run a mutation, you first call `useUpdateSeatplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeatplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeatplanMutation, { data, loading, error }] = useUpdateSeatplanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSeatplanMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSeatplanMutation, UpdateSeatplanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSeatplanMutation, UpdateSeatplanMutationVariables>(
    UpdateSeatplanDocument,
    options,
  );
}
export type UpdateSeatplanMutationHookResult = ReturnType<typeof useUpdateSeatplanMutation>;
export type UpdateSeatplanMutationResult = Apollo.MutationResult<UpdateSeatplanMutation>;
export type UpdateSeatplanMutationOptions = Apollo.BaseMutationOptions<
  UpdateSeatplanMutation,
  UpdateSeatplanMutationVariables
>;
export const TokenRefreshDocument = gql`
  mutation TokenRefresh {
    tokenRefresh {
      ok
    }
  }
`;
export type TokenRefreshMutationFn = Apollo.MutationFunction<
  TokenRefreshMutation,
  TokenRefreshMutationVariables
>;

/**
 * __useTokenRefreshMutation__
 *
 * To run a mutation, you first call `useTokenRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenRefreshMutation, { data, loading, error }] = useTokenRefreshMutation({
 *   variables: {
 *   },
 * });
 */
export function useTokenRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<TokenRefreshMutation, TokenRefreshMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TokenRefreshMutation, TokenRefreshMutationVariables>(
    TokenRefreshDocument,
    options,
  );
}
export type TokenRefreshMutationHookResult = ReturnType<typeof useTokenRefreshMutation>;
export type TokenRefreshMutationResult = Apollo.MutationResult<TokenRefreshMutation>;
export type TokenRefreshMutationOptions = Apollo.BaseMutationOptions<
  TokenRefreshMutation,
  TokenRefreshMutationVariables
>;
