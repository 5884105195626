import styled from '@emotion/styled';
import { theme as staticTheme } from '@tw/components/utils';

export const TWIconDiv = styled.div({
  width: '1.5em',
});

export const WriteContainer = styled.div({
  width: '100%',
  minHeight: staticTheme.fontUnit * 8,
  position: 'relative',
  '.blurWrapper': {
    display: 'flex',
    position: 'relative',
  },
  '.actionButtons': {
    display: 'flex',
    alignContent: 'space-between',
    position: 'absolute',
    right: 0,
    top: '100%',
    marginTop: staticTheme.fontUnit,
    'button.ant-btn': {
      paddingTop: '2px',
    },
  },
  '.actionButtons > *': {
    zIndex: 10000,
    marginLeft: staticTheme.fontUnit,
  },
});

export const ReadComponent = styled.div({
  display: 'flex',
  width: '100%',
  minHeight: staticTheme.fontUnit * 8,
  cursor: 'pointer',
  position: 'relative',
  '.flex-wrapper': {
    display: 'flex',
  },
  '.stretch': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  '.editIcon': {
    opacity: 0,
    fontSize: staticTheme.fontUnit * 4,
    marginLeft: staticTheme.fontUnit,
    color: staticTheme.colors.icon,
  },
  'button:hover': {
    backgroundColor: 'rgba(15, 50, 169, 0.05)',
    borderRadius: '4px',
  },
  ':hover .editIcon': {
    opacity: 1,
  },
  'button:focus .editIcon': {
    opacity: 1,
  },
  button: {
    background: 'none',
    border: 'none',
    margin: 0,
    marginLeft: -staticTheme.fontUnit,
    padding: `0 ${staticTheme.fontUnit * 4}px 0 ${staticTheme.fontUnit}px`,
    cursor: 'pointer',
    width: '100%',
    minHeight: staticTheme.fontUnit * 8,
    textAlign: 'left',
  },
});
