import { GenderOption } from '@tw/@types/definitions';
import { GENDERS } from '@tw/constants';

/**
 * Returns gender options
 * @returns {GenderOption[]}
 */
const useGenderOptions = (): GenderOption[] => GENDERS;

export default useGenderOptions;
