import styled from '@emotion/styled';

import { ButtonType, SizeScalingType } from './TWButton.defintions';

interface ButtonContainerProps {
  padding?: number[] | number;
  maxWidth?: number | string;
  size: SizeScalingType;
  type: ButtonType;
  customStyles: object;
  disabled?: boolean;
}

const ButtonContainer = styled.div<ButtonContainerProps>(
  ({ theme, padding, maxWidth, size, type, customStyles, disabled }) => {
    const { backgroundColorHover, backgroundColor, textColor, border, borderHover, boxShadow } = {
      default: {
        backgroundColorHover: theme.colors.white,
        backgroundColor: theme.colors.white,

        textColor: disabled ? theme.colors.buttonTextDisabled : theme.colors.buttonText,
        border: `${theme.colors.buttonBorder} ${theme.borderWidth.small}px solid`,
        borderHover: `${theme.colors.buttonBorderHover} ${theme.borderWidth.small}px solid`,
      },
      primary: {
        backgroundColorHover: theme.colors.primaryButtonBackgroundHover,
        backgroundColor: !disabled
          ? theme.colors.primaryButtonBackground
          : `${theme.colors.primaryButtonBackgroundDisabled} !important`,
        textColor: theme.colors.primaryButtonText,
        border: `${theme.colors.buttonBorder} ${theme.borderWidth.small}px solid`,
        borderHover: `${theme.colors.buttonBorderHover} ${theme.borderWidth.small}px solid`,
      },
      borderless: {
        backgroundColorHover: 'transparent',
        backgroundColor: 'transparent',
        textColor: theme.colors.buttonText,
        border: `none`,
        borderHover: `none`,
        boxShadow: 'none',
      },
      attention: {
        backgroundColorHover: theme.colors.negative,
        backgroundColor: theme.colors.negative,
        textColor: theme.colors.white,
        border: `${theme.colors.negative} ${theme.borderWidth.small}px solid`,
        borderHover: `${theme.colors.negative} ${theme.borderWidth.small}px solid`,
      },
      delete: {
        backgroundColorHover: theme.colors.white,
        backgroundColor: theme.colors.white,

        textColor: disabled ? theme.colors.buttonTextDisabled : theme.colors.negative,
        border: `${theme.colors.negative}99 ${theme.borderWidth.small}px solid`,
        borderHover: `${theme.colors.negative} ${theme.borderWidth.small}px solid`,
      },
    }[type];

    const { height } = {
      small: {
        height: 24,
      },
      default: {
        height: 32,
      },
      large: {
        height: 40,
      },
    }[size];
    // The reason we are not using twPadding here from sharedSpacingStyles is that we can't wrap
    // antd Button with styled() as that breaks typescript typechecking and causes a lot of typescript
    // errors. So we're converting padding manually here
    let paddingValue: number | string | undefined;
    if (Array.isArray(padding)) {
      paddingValue = padding.map((value: number) => `${value * theme.baseUnit}px`).join(' ');
    } else {
      paddingValue = padding;
    }

    return {
      maxWidth,
      button: {
        boxShadow,
        height,
        display: 'flex',
        flex: 1,
        flexFlow: 'row',
        alignItems: 'center',
        padding: paddingValue,
        backgroundColor,
        color: textColor,
        border,
        span: {
          color: textColor,
          ':hover': {
            color: textColor,
          },
        },
        ':hover': {
          backgroundColor: backgroundColorHover,
          color: textColor,
          border: borderHover,
        },
        ':active': {
          backgroundColor,
          color: textColor,
          border,
        },
        ':focus': {
          backgroundColor,
          color: textColor,
          border,
        },
        ...customStyles,
      },
      '*:not(:last-child)': {
        marginRight: theme.baseUnit,
      },
    };
  },
);

ButtonContainer.defaultProps = {
  padding: [0, 1.5],
  maxWidth: 200,
};

interface ButtonFlatContainerProps {
  outline?: boolean;
}

const ButtonContainerFlat = styled.div<ButtonFlatContainerProps>(({ theme }) => {
  const centerIconStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return {
    'button:not(:hover), a:not(:hover), button.ant-btn[disabled], button.ant-btn[disabled]:hover': {
      background: 'transparent',
      boxShadow: 'none',
      borderColor: 'transparent',
      ...centerIconStyles,
    },
    'button:hover, a:hover': {
      background: 'transparent',
      boxShadow: 'none',
      borderColor: theme.colors.highlightBold,
      ...centerIconStyles,
    },
  };
});

export { ButtonContainer, ButtonContainerFlat };
