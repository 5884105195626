/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBulletinMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type DeleteBulletinMutation = {
  bulletinDelete: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const DeleteBulletinDocument = gql`
  mutation DeleteBulletin($id: ID) {
    bulletinDelete(id: $id) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type DeleteBulletinMutationFn = Apollo.MutationFunction<
  DeleteBulletinMutation,
  DeleteBulletinMutationVariables
>;

/**
 * __useDeleteBulletinMutation__
 *
 * To run a mutation, you first call `useDeleteBulletinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBulletinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBulletinMutation, { data, loading, error }] = useDeleteBulletinMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBulletinMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBulletinMutation, DeleteBulletinMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBulletinMutation, DeleteBulletinMutationVariables>(
    DeleteBulletinDocument,
    options,
  );
}
export type DeleteBulletinMutationHookResult = ReturnType<typeof useDeleteBulletinMutation>;
export type DeleteBulletinMutationResult = Apollo.MutationResult<DeleteBulletinMutation>;
export type DeleteBulletinMutationOptions = Apollo.BaseMutationOptions<
  DeleteBulletinMutation,
  DeleteBulletinMutationVariables
>;
