import styled from '@emotion/styled';

import 'antd/dist/antd.less';
import '@teamworksdev/react/dist/index.css';

// Global override styles for antd components or undoing global coldfusion styles.
// Only GLOBAL style overrides should live here. Otherwise, pair them with their respective component
// and override using Emotion.
export const RootStylesContainer = styled.div(({ theme }) => ({
  fontFamily: '"Inter", Helvetica, sans-serif',

  body: {
    margin: 0,
    padding: 0,
  },

  '.ant-input-group-addon': {
    background: 'transparent',
  },

  '.ant-legacy-form-explain': {
    color: theme.colors.negative,
  },

  '.ant-legacy-form-item-control-wrapper': {
    width: '100%',
  },

  '.antd ::-webkit-scrollbar': {
    display: 'none',
  },

  '.ant-input-affix-wrapper .ant-input-prefix + input': {
    paddingLeft: 5,
  },

  // TODO: move to dropdown component
  '.ant-dropdown-menu-item': {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.buttonText,
    i: {
      display: 'flex',
      marginRight: '4px',
    },
    'svg, span.material-icons, span.material-icons-outlined, span.material-symbols, span.material-symbols-outlined':
      {
        marginRight: '12px',
      },
  },

  '.ant-dropdown-menu-item.ant-dropdown-menu-item-danger': {
    color: theme.colors.negative,
  },

  '.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover': {
    color: '#fff',
    backgroundColor: theme.colors.negative,
  },

  '.ant-layout-sider': {
    '.ant-menu-item': {
      a: {
        display: 'flex',
        alignItems: 'center',
        'svg, span.material-icons-outlined': {
          marginRight: '4px',
        },
      },
    },
  },

  '.ant-popover': {
    maxWidth: '36em',
    p: {
      margin: '0px',
    },
  },

  // for some reason this works here and not in TWForm
  '.ant-row': {
    label: {
      fontSize: theme.fontUnit * 3.5,
      fontWeight: 'normal',
      marginBottom: 0,
    },
    '.ant-legacy-form-item': {
      display: 'block',
    },
  },

  '.ant-tooltip-inner': {
    textAlign: 'center',
    display: 'flex',
  },

  '.ant-tooltip': {
    maxWidth: 400,
  },

  '.ant-message-notice-content': {
    padding: 0,
    color: theme.colors.text,

    '.ant-message-custom-content': {
      display: 'inline-flex',
      alignItems: 'center',
      minHeight: theme.baseUnit * 4,
      padding: '10px 16px',
      'svg, span.material-icons-outlined': {
        marginRight: theme.baseUnit,
      },
    },

    '.ant-message-success': {
      backgroundColor: theme.colors.positiveBackground,
      border: `1px solid ${theme.colors.positiveBorder}`,
      'svg, span.material-icons-outlined': {
        color: theme.colors.positiveIcon,
      },
    },

    '.ant-message-error': {
      backgroundColor: theme.colors.negativeBackground,
      border: `1px solid ${theme.colors.negativeBorder}`,
      'svg, span.material-icons-outlined': {
        color: theme.colors.negativeIcon,
      },
    },

    '.ant-message-info': {
      backgroundColor: theme.colors.infoBackground,
      border: `1px solid ${theme.colors.infoBorder}`,
      'svg, span.material-icons-outlined': {
        color: theme.colors.infoIcon,
      },
    },

    '.ant-message-warning': {
      backgroundColor: theme.colors.warningBackground,
      border: `1px solid ${theme.colors.warningBorder}`,
      'svg, span.material-icons-outlined': {
        color: theme.colors.warningIcon,
      },
    },
  },
  // decrease animation duration to 100ms
  '.ant-btn': {
    transition: 'all .1s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  '.ant-select-dropdown, .ant-dropdown, .ant-picker-dropdown': {
    animationDuration: '.1s',
  },
  '.ant-drawer-header': {
    padding: 0,
  },
  '.ant-drawer-content h1': {
    fontSize: '28px',
  },
  '.ant-drawer-header-title': {
    padding: '16px 24px',
  },
  '.ant-form-item-hidden': {
    display: 'none',
  },
  '.input-multiselect-styled-menu': {
    padding: '8px 24px 16px 16px',
    '.clearSelections': {
      float: 'right',
      width: '100%',
      padding: 0,
      '&:hover': {
        backgroundColor: theme.colors.white,
      },
    },
    '.closeButton': {
      float: 'right',
      padding: 0,
      marginTop: theme.baseUnit,
    },
    '.selectAllCheckbox > span > span': {
      color: theme.colors.text,
    },
    '.groupItem': {
      color: theme.colors.secondary,
      float: 'left',
      fontSize: '12px',
      padding: 0,
      '&:hover': {
        backgroundColor: theme.colors.white,
        cursor: 'default',
      },
    },
    maxHeight: '350px',
    overflow: 'auto',
  },

  // opacity for disabled menu items
  '.ant-dropdown-menu-item-disabled': {
    opacity: 0.5,
    '&.ant-dropdown-menu-item-danger:hover': {
      backgroundColor: 'transparent',
      color: '#DC1721',
    },
  },
}));

export const Layout = styled.div<{ roundedLayout: boolean; topColor?: string }>(
  ({ roundedLayout, topColor = '#000000', theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    backgroundColor: topColor,
    borderTop: roundedLayout ? `${theme.baseUnit}px solid ${topColor}` : undefined,
  }),
);

export const Content = styled.div<{
  roundedLayout: boolean;
  hideScroll: boolean;
}>(({ roundedLayout, hideScroll, theme }) => ({
  display: 'flex',
  flex: 1,
  overflow: hideScroll ? 'hidden' : 'auto',
  borderTopLeftRadius: roundedLayout ? theme.baseUnit * 2 : undefined,
  background: theme.colors.white,
}));

export const Main = styled.div<{}>({
  display: 'block',
  width: '100%',
});
