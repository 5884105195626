import styled from '@emotion/styled';
import { Heading as H } from '@teamworksdev/react';
import { TWLayout } from '@tw/components';
import { Collapse } from 'antd';

export const Container = styled(TWLayout.Container)(({ theme }) => ({
  background: theme.colors.white,
}));

export const Content = styled(TWLayout.Content)(({ theme }) => ({
  padding: 0,
  '&&&': {
    overflowX: 'unset',
    borderLeft: `1px solid ${theme.colors.border}`,
  },
}));

export const CategoriesCollapse = styled(Collapse)(({ theme }) => ({
  background: theme.colors.white,
  '.ant-collapse-header': {
    fontSize: '18px',
    color: `${theme.colors.text}!important`,
    fontWeight: '400',
    paddingBottom: `${theme.baseUnit * 2.5}px!important`,
    paddingTop: `${theme.baseUnit * 2.5}px!important`,
  },
}));

export const Heading = styled(H)(() => ({
  color: 'rgba(0,0,0,0.6)', // TODO: replace with theme.colors.black60
  margin: 0,
}));
