/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllCalsyncAccountsQueryVariables = Types.Exact<{
  personId: Types.Scalars['Int'];
}>;

export type AllCalsyncAccountsQuery = {
  calsyncAccounts?: Array<{
    id: string;
    status?: string | null;
    providerId: string;
    calsyncCalendars?: Array<{
      status?: string | null;
      providerId: string;
      thirdPartyName?: string | null;
    } | null> | null;
  } | null> | null;
};

export const AllCalsyncAccountsDocument = gql`
  query AllCalsyncAccounts($personId: Int!) {
    calsyncAccounts {
      id
      status
      providerId
      calsyncCalendars {
        status
        providerId
        thirdPartyName
      }
    }
  }
`;

/**
 * __useAllCalsyncAccountsQuery__
 *
 * To run a query within a React component, call `useAllCalsyncAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCalsyncAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCalsyncAccountsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useAllCalsyncAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<AllCalsyncAccountsQuery, AllCalsyncAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllCalsyncAccountsQuery, AllCalsyncAccountsQueryVariables>(
    AllCalsyncAccountsDocument,
    options,
  );
}
export function useAllCalsyncAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCalsyncAccountsQuery,
    AllCalsyncAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllCalsyncAccountsQuery, AllCalsyncAccountsQueryVariables>(
    AllCalsyncAccountsDocument,
    options,
  );
}
export type AllCalsyncAccountsQueryHookResult = ReturnType<typeof useAllCalsyncAccountsQuery>;
export type AllCalsyncAccountsLazyQueryHookResult = ReturnType<
  typeof useAllCalsyncAccountsLazyQuery
>;
export type AllCalsyncAccountsQueryResult = Apollo.QueryResult<
  AllCalsyncAccountsQuery,
  AllCalsyncAccountsQueryVariables
>;
