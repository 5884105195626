import { LogAthletesSummaryNode } from '@tw/generated';
import { TMPTeamSettings } from './tmpTeamSettings';

export interface ActivityLogSignoffResponse {
  tmpActivityLogSignoffResponseId: string;
  approval?: boolean;
  updatedDateTime?: string;
  notes?: string;
  person?: {
    personId: string;
    firstName?: string;
    lastName?: string;
    pictureUrl?: string;
  };
  stage?: {
    label: string;
  };
}

export interface TMPActivityLogAppointmentBreakdown {
  tmpActivityLogAppointmentId: number;
  appointmentTitle: string;
  appointmentTypeLabel: string;
  appointmentTypeColor: string;
  appointmentOccurrenceId: number;
  countableMinutes: number;
  editedCountableStartDate: string;
  editedCountableEndDate: string;
  occStartDateTime: string;
  occEndDateTime: string;
}

export interface AthleteBreakdown {
  totalCountableMinutes: number;
  date: string | null;
  dailyViolations: {
    hasDailyOverage?: boolean | null;
    hasCaraOnVacation?: boolean | null;
    hasCaraOnDayOff?: boolean | null;
  };
  logAppointments: TMPActivityLogAppointmentBreakdown[];
}

interface DateToLabel {
  date: string;
  label: string;
}

export type LogAthletesSummary = LogAthletesSummaryNode;

interface TMPTeamSegmentConflict {
  weekStart: number;
}

export interface TMPActivityComments {
  commonCommentId?: number;
  content: string;
  updatedDateTime?: string;
  creator?: {
    firstName?: string;
    lastName?: string;
    pictureUrl?: string;
    personId: string | number;
  };
}

interface SubmittedNames {
  submittedName: string;
  personId: number;
}

export enum TMPActivityLogPendingStage {
  waitingOnCoach = 0,
  waitingOnCompliance = 1,
  waitingOnSA = 2,
  reviewCalendar = 3,
}

export enum TMPActivityLogStageLabel {
  waitingOnCoach = 'Head Coach',
  waitingOnCompliance = 'Compliance',
  waitingOnSA = 'Student Athletes',
}

export interface TMPActivityLogList {
  tmpActivityLogId: string;
  tmpActivityLogConfigId: number;
  logStartDate: string;
  logEndDate: string;
  isDraft: boolean;
  pendingStageSeq: TMPActivityLogPendingStage;
  comments: TMPActivityComments[];
  tmpTeamSegment: TMPTeamSegmentConflict;
  logAthletesSummary: LogAthletesSummary[];
  responses: ActivityLogSignoffResponse[];
  submittedDate: string;
  submittedNames: SubmittedNames[];
  config: TMPTeamSettings;
  isUserLogAdmin: boolean;
  isUserComplianceUser: boolean;
  isUserViewer: boolean;
  vacationDays: DateToLabel[];
  segmentTypePerDay: DateToLabel[];
  weeklyDaysOff: number;
  __typename: 'TmpActivityLogListNode';
}
