import { gql } from '@apollo/client';

export default gql`
  query ViewerData {
    viewer {
      # These exist to ease transition while refactoring old code - they should not be used for new code
      orgId
      teamId
      personId
      isOrgSuperuser
      isTeamSuperuser
      username
      hasMultipleAccounts
      enabledApps {
        id
        name
        logo
        link
      }
      person {
        id
        personId
        emailAddress
        firstName
        lastName
        fullName
        fullNameNormalOrder
        displayName
        preferredName
        title
        initials
        isOrgLevelUser
        orgId
        pictureUrl
        selectionCode
        label
        timeZone
        hasLinkedSsoAccount
        usesExternalAuthentication
      }
      userSettings {
        calendarAlertDefault
      }
      userPreferences {
        uses24HourTime
      }
      permissions {
        canAddAvailability
        canAddOtherUserAppointments
        canAddTravelApptType
        canAddComplianceApptType
        canAdminLoginAccess
        canAdminTicketing
        canAssignForms
        canCopyAppointments
        canDeactivateUsers
        canFindTutors
        canManageAllTeamsAccess
        canManageAppAssignments
        canManageDigitalDisplays
        canManageForms
        canManageNews
        canManagePermissions
        canManageQuickForms
        canManageTasks
        canManageTravel
        canModifyStaffProfiles
        canModifyUsers
        canManageWeblinks
        canSendMessages
        canSendTravelLetter
        canSubmitForms
        canViewAcademics2
        canViewAthleteList
        canViewConversations
        canViewCalendarActivityLogs
        canViewMessageHistory
        canViewOthersMessageHistory
        canViewOtherUserAppointments
        canViewContactDetails
        canViewStaffProfile
        canViewTicketing
        canViewTravel
        canViewOtherTrips
        canViewVideo
        canViewWeblinks
        canViewNews
        canViewTmp
        canViewCaraLogs
        canEditCaraTeamDeclarations
        canAdminTmp
        isAcademicSuperuser
        teamsCanAdminVideos
        teamsCanAddFiles
        teamsCanAdminFileFolders
        teamsCanManageAcademics
        teamsCanViewAcademicsClass
        isLegacyApiUser
        canAddTravelContacts
        academics2ModuleEnabled
        calendarModuleEnabled
        messagingModuleEnabled
        tmpModuleEnabled
        travelModuleEnabled
        disableMessageEditDelete
        enableGuardianshipFeatures
      }
      availableTabs {
        key
        overrideLabel
        label
        path
      }
      currentOrg {
        id
        label
        logoUrl
        allTeamsTeamId
        usesExternalAuthentication
        usesSsoAsDefault
        idpMatchAttributeType
        isCollegiate
        isNonAthleteOrg
        timeZoneCode
        uses24HourTime
        institutionId
        athleteUserType {
          value: id
          label: name
        }
        athleteStatuses {
          value: id
          label: labelPlural
          isActive: isActiveStatus
        }
        branding {
          headerBgColor
          headerTextColor
          headerLinkColor
          primaryColor
          primaryLighterColor
          primaryLightestColor
        }
        userTypes {
          id
          name
          namePlural
          personTypeId
        }
      }
      currentTeam {
        id
        label
        initials
        logoUrl
        identityFieldLabel
        branding {
          headerBgColor
          headerTextColor
          headerLinkColor
          primaryColor
          primaryLighterColor
          primaryLightestColor
        }
        sportId
        teamId
        institution {
          city
          state
          leagueId
          id
        }
      }
      currentMembership {
        id
        athleteStatus {
          id
          isActiveStatus
          label
        }
        userTypes {
          id
          pk
          name
          namePlural
        }
      }
      availableTeams {
        id
        teamId
        label
        shortLabel
        initials
        logoUrl
        canAddToTeam
        canRemoveFromTeam
        canViewOtherUsers
        moduleCodes
      }
      teamSuperuserTeams {
        id
        teamId
        label
      }
      travelSettings {
        label
        enabled
      }
      visibleUserTypes {
        value: id
        label: name
      }
      allAppointmentTypes: allowedAppointmentTypes {
        id
        appointmentTypeId
        color
        label
        canUserAdd
        isActive
        isTutoring
        isDayOff
        travelOnly
        complianceLabel
      }
    }
  }
`;
