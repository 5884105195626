const $twSecondaryTabs = {
  backgroundColor: '$colorHue.grayVeryDark',
  borderColor: '$colorHue.grayMid',
  borderWidth: '$borderWidth.medium',
  labelColorActive: '$colorHue.white',
  labelColorInactive: '$colorHue.grayBlue',
  indicatorColor: '$brandColor.primaryColor',
};

export { $twSecondaryTabs };
