import axios from 'axios';

import { Maybe } from '@tw/generated';

import { WistiaMediaData } from '../Videos.definitions';

export const { WISTIA_UPLOAD_KEY } = process.env;
export const { WISTIA_PROJECT_ID } = process.env;

export const getWistiaMediaData = async (hashedId: string) => {
  const { data } = await axios.get<WistiaMediaData>(
    `https://api.wistia.com/v1/medias/${hashedId}.json`,
    {
      headers: {
        Authorization: `Bearer ${WISTIA_UPLOAD_KEY}`,
      },
    },
  );

  return data;
};

interface GetWistiaThumbnailUrlProps {
  width: number;
  height: number;
  wistiaThumbnailUrl?: Maybe<string>;
  wistiaOriginalFileUrl?: Maybe<string>;
}

export const getWistiaThumbnailUrl = ({
  width,
  height,
  wistiaOriginalFileUrl,
  wistiaThumbnailUrl,
}: GetWistiaThumbnailUrlProps) => {
  let thumbnailUrl = wistiaThumbnailUrl ?? '';

  // If invalid thumbnail url or Wistia placeholder, then generate from wistiaOriginalFileUrl
  if (
    (!thumbnailUrl.match(/\.(png|jpg)/) || thumbnailUrl?.match(/dashed-thumbnail/)) &&
    wistiaOriginalFileUrl
  ) {
    thumbnailUrl = wistiaOriginalFileUrl
      .split('.')
      .slice(0, -1)
      .join('.')
      .concat(
        `.jpg?image_crop_resized=${width * 2}x${
          height * 2
        }&image_quality=100&ssl=true&video_still_time=1`,
      );
  } else {
    // Set the width and height of the thumbnail
    thumbnailUrl = thumbnailUrl.replace(
      /image_crop_resized=\d+x\d+/,
      `image_crop_resized=${width * 2}x${height * 2}`,
    );
  }

  return thumbnailUrl;
};
