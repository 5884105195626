import { useState, useEffect } from 'react';
import * as React from 'react';
import _ from 'lodash';

import { getTranslation } from '@tw/i18n';

import { TWInputTreeSelectCourseBySubject } from '../../components.web/inputs/TWInputTreeSelectCourseBySubject';
import TWItemLabelList from '../../presentational/TWItemLabelList';
import TWEditableSelect from '../../presentational/TWEditableSelect';

interface TWEditableCourseListProps {
  canEdit?: boolean;
  containerId: string;
  courseList?: { courseId: number | string }[];
  onChange: (courseIds: string[]) => void;
  width?: string;
}

export const TWEditableCourseList: React.FC<TWEditableCourseListProps> = (props) => {
  const {
    canEdit = true,
    containerId,
    courseList = [],
    onChange,
    width,
    ...allUnrecognizedProps
  } = props;
  const [initialCourseIdList, setInitialCourseIds] = useState<string[]>(null);
  const [courseIdList, setCourseIds] = useState<string[]>(null);

  useEffect(() => {
    if (!_.isEmpty(courseList)) {
      const idList = _.map(courseList, (course) => `course-${course.courseId}`);

      setInitialCourseIds(idList);
      setCourseIds(idList);
    }
  }, [courseList]);

  function handleChange(newCourseIds: string[]) {
    setCourseIds(newCourseIds);
  }

  function handleCancel() {
    setCourseIds(initialCourseIdList);
  }

  function handleSave() {
    onChange(courseIdList);
  }

  return (
    <TWEditableSelect
      canEdit={canEdit}
      containerId={containerId}
      onCancel={handleCancel}
      onChange={handleChange}
      onSave={handleSave}
      hideButtons
      readComponent={
        <TWItemLabelList
          containerId={containerId}
          itemList={courseList}
          emptyLabel={getTranslation('noCourses')}
        />
      }
      writeComponent={
        <TWInputTreeSelectCourseBySubject
          popupContainerId={containerId}
          placeholder={getTranslation('courses', 1)}
          autoFocus
          treeCheckable
          value={courseIdList}
          width={width}
          populateOnMount
          {...allUnrecognizedProps}
        />
      }
    />
  );
};
