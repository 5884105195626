import { Gender, GenderOption } from '@tw/@types/definitions';
import _ from 'lodash';

/**
 * This just transforms an array of strings into an object which has the same strings
 * for its keys & values -- purely for syntactic sugar.
 * (So, ['FOO'] becomes { FOO: 'FOO' }, and consumers can then reference CONSTANTS.FOO)
 *
 * This is soltely for brevity; doing straight objects works just as well, but is a little
 * more to type and it's more annoying if/when the values need to be altered.
 *
 * The extraOverrides lets you create aliases -- where CONSTANTS.FOO can give 'BAR'.
 * For that, we just end up with an object like: {
 *    BAR: 'BAR',   // new value
 *    FOO: 'BAR',   // legacy key gives us new value
 *  }
 */
const makeConstantsObject = (sourceValues: string[] = [], extraOverrides = {}) =>
  Object.freeze(
    // The keyBy create our keys-and-values object, then we manipulate it and freeze it.s
    _.assign(_.keyBy(sourceValues), extraOverrides),
  );

// statuses for authentication state when in offline mode
const OFFLINE_AUTH_STATUS = makeConstantsObject(['NEEDS_TO_REFRESH', 'NEEDS_TO_AUTHENTICATE']);

/**
 * In general this is used to note the status of the last fetch attempted for a backend operation.
 */
const FETCH_STATUS = Object.freeze({
  // Default: we haven't tried to fetch yet
  NULL: 'NULL',
  // Indicates that a request is in flight.
  PENDING: 'PENDING',
  // Indicates that the request completed and all is well
  SUCCESSFUL: 'SUCCESSFUL',
  // Indicates that something went wrong: the request didn't complete, or it completed with
  // an http, payload/parsing, or business logic error.
  // @TODO: Should we distinguish between "request completed by result failed" vs
  //        "request didn't complete due to timeout or network error"?
  FAILED: 'FAILED',
  // @TODO: Is it worth adding 'SKIPPED' or 'OFFLINE' for cases where we were asked to
  //        do a fetch but didn't even try?
});

/**
 * In general this is used to note the presence of data for a specific backend operation.
 */
const DATA_STATUS = Object.freeze({
  // Default: we haven't received anything
  ABSENT: 'ABSENT',
  // Result was received and we have data
  PRESENT: 'PRESENT',
  // @TODO: Is it worth tracking an OFFLINE-like state?
});

// track the reasoning behind each logout action
const LOGOUT_CAUSES = makeConstantsObject([
  // the user actually deliberately logged out via the button on other tab
  'USER_PRESSED_LOG_OUT',
  // whenever we see a LOGIN_STATUS of 'LOGGED_OUT', we'll logout from the tabsLayout
  'RECEIVED_LOGGED_OUT_STATUS',
  // similarly, if we don't set login destination, the login or sso page will log the user out
  'NO_LOGIN_DESTINATION',
  // if the sso login result doesn't send the necessary python tokens, we log out
  'SSO_MISSING_TOKEN',
  // if the logged in user has no available accounts in the auth saga, we log out
  'NO_AVAILABLE_ACCOUNTS',
]);

/**
 * This gets used by customFieldUtils to group and remap the sections that field categories
 * go into.
 */
const CUSTOM_FIELD_SECTIONS = makeConstantsObject(
  [
    // These are the sections defined in the legacy app (uppercased)
    'PERSONAL',
    'CONTACT',
    'ATHLETE',
    'OTHER',
  ],
  {
    _default: 'OTHER',
    ACADEMIC: 'ATHLETE',
  },
);

/**
 * When we process/massage incoming custom fields, we need to decide what type of data
 * they hold. These vaguely correspond to input types.
 *
 * The process for figuring out a field's type is rather convoluted, though:
 * The type is largely based on the field's `dataType` and `formControl` fields,
 * but also adjusted based on its label and value (e.g., a text field with the substring
 * 'email' in its label will be given "EMAIL". A text field with a value that's 10
 * digits log will be given "PHONE").
 */
const CUSTOM_FIELD_TYPES = makeConstantsObject([
  'UNKNOWN',
  'BOOLEAN',
  'DATE',
  'EMAIL',
  'LIST_MULTIPLE',
  'LIST_SINGLE',
  'NUMBER',
  'PHONE',
  'TEXT',
  'TEXT_NAME',
]);

/**
 * Most forms -- and specifically the buttons on forms -- look different depending on the
 * action type they're supposed to do. This object is just here so that we don't put
 * string literals everything.
 */
const FORM_ACTION_TYPES = makeConstantsObject(['CREATE', 'UPDATE', 'DELETE']);

const GOLDEN_RATIO = 1.61803398875;
const GOLDEN_RATIO_INVERSE = 0.61803398875;

// Long setTimeout values cause Android to stay awake -- and it also throws ugly warnings up
// about this during dev. This value should be low enough to not cause unnecessary battery
// drain, but long enough to cause unnecessary ajax requests.
const MAX_TIMEOUT_DURATION = 50 * 1000; // 50 seconds

const PORTRAIT_ONLY_SCREENS = makeConstantsObject([
  'CalendarAgenda',
  'TripDetail',
  'ProfileDetail',
]);

// @TODO: When we get to internationalization and multi-language, values like this will come from normal keyed
// dicts for the different languages/translations, with a layered config setting to set/overwrite the language.
// That's been regularly de-prioritized for several years, though, so this value is just a stopgap to avoid
// copy/pasting too often.
const OFFLINE_MESSAGE = 'We could not connect to Teamworks. Please try again in a moment.';

/**
 * The values below this point are shared values, rather than explicit constants objects,
 * so they have normal names instead of all-caps identifiers.
 */

const contactUsEmail = 'support@teamworks.com';

const labelsByFormType = {
  completed: 'Completed',
  perpetual: 'Perpetual',
  waitingOnMe: 'Waiting on me',
  waitingOnOthers: 'Waiting on others',
};

// This is just a handy callback to pass when you need to provide a function but don't
// want to do anything: a no-op.
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopFn = () => {};

// And these are some related ones for cases where you want a non-changing object
const emptyArray: unknown[] = [];
const emptyArrayFn = () => emptyArray;
const emptyObject = {};
const emptyObjectFn = () => emptyObject;

const listTypes = makeConstantsObject(['FLAT_LIST', 'SECTION_LIST']);

const stateList = [
  { value: 'AL', label: 'AL - Alabama' },
  { value: 'AK', label: 'AK - Alaska' },
  { value: 'AZ', label: 'AZ - Arizona' },
  { value: 'AR', label: 'AR - Arkansas' },
  { value: 'CA', label: 'CA - California' },
  { value: 'CO', label: 'CO - Colorado' },
  { value: 'CT', label: 'CT - Connecticut' },
  { value: 'DE', label: 'DE - Delaware' },
  { value: 'DC', label: 'DC - District Of Columbia' },
  { value: 'FL', label: 'FL - Florida' },
  { value: 'GA', label: 'GA - Georgia' },
  { value: 'HI', label: 'HI - Hawaii' },
  { value: 'ID', label: 'ID - Idaho' },
  { value: 'IL', label: 'IL - Illinois' },
  { value: 'IN', label: 'IN - Indiana' },
  { value: 'IA', label: 'IA - Iowa' },
  { value: 'KS', label: 'KS - Kansas' },
  { value: 'KY', label: 'KY - Kentucky' },
  { value: 'LA', label: 'LA - Louisiana' },
  { value: 'ME', label: 'ME - Maine' },
  { value: 'MD', label: 'MD - Maryland' },
  { value: 'MA', label: 'MA - Massachusetts' },
  { value: 'MI', label: 'MI - Michigan' },
  { value: 'MN', label: 'MN - Minnesota' },
  { value: 'MS', label: 'MS - Mississippi' },
  { value: 'MO', label: 'MO - Missouri' },
  { value: 'MT', label: 'MT - Montana' },
  { value: 'NE', label: 'NE - Nebraska' },
  { value: 'NV', label: 'NV - Nevada' },
  { value: 'NH', label: 'NH - New Hampshire' },
  { value: 'NJ', label: 'NJ - New Jersey' },
  { value: 'NM', label: 'NM - New Mexico' },
  { value: 'NY', label: 'NY - New York' },
  { value: 'NC', label: 'NC - North Carolina' },
  { value: 'ND', label: 'ND - North Dakota' },
  { value: 'OH', label: 'OH - Ohio' },
  { value: 'OK', label: 'OK - Oklahoma' },
  { value: 'OR', label: 'OR - Oregon' },
  { value: 'PA', label: 'PA - Pennsylvania' },
  { value: 'RI', label: 'RI - Rhode Island' },
  { value: 'SC', label: 'SC - South Carolina' },
  { value: 'SD', label: 'SD - South Dakota' },
  { value: 'TN', label: 'TN - Tennessee' },
  { value: 'TX', label: 'TX - Texas' },
  { value: 'UT', label: 'UT - Utah' },
  { value: 'VT', label: 'VT - Vermont' },
  { value: 'VA', label: 'VA - Virginia' },
  { value: 'WA', label: 'WA - Washington' },
  { value: 'WV', label: 'WV - West Virginia' },
  { value: 'WI', label: 'WI - Wisconsin' },
  { value: 'WY', label: 'WY - Wyoming' },
];

// these are the hardcoded alert options for creating/changing events
const alertOptionsList = [
  { label: 'None', value: 'none' },
  { label: 'Email', value: 'email' },
  { label: 'Text Message', value: 'sms' },
  { label: 'Both', value: 'both' },
];

const alertNoneValue = _.find(alertOptionsList, { value: 'none' })?.value;

const reminderOptionsList = [
  { label: '0 min', value: 0 },
  { label: '5 min before', value: -5 },
  { label: '10 min before', value: -10 },
  { label: '15 min before', value: -15 },
  { label: '30 min before', value: -30 },
  { label: '1 hr before', value: -60 },
  { label: '2 hrs before', value: -120 },
  { label: '3 hrs before', value: -180 },
  { label: '6 hrs before', value: -360 },
  { label: '12 hrs before', value: -720 },
  { label: '1 day before', value: -1440 },
  { label: '2 days before', value: -2880 },
  { label: '1 week before', value: -10080 },
  { label: '2 weeks before', value: -20160 },
  { label: '3 weeks before', value: -30240 },
];

const recurringOptionsList = [
  { label: 'One-time', value: 'none' },
  { label: 'Daily', value: 'daily', navLocation: 'RecurringDaily' },
  { label: 'Weekly', value: 'weekly', navLocation: 'RecurringWeekly' },
  { label: 'Monthly', value: 'monthly', navLocation: 'RecurringMonthly' },
  { label: 'Yearly', value: 'yearly', navLocation: 'RecurringYearly' },
];

const daysFrequencyList = [
  { value: 1, label: 'Every day' },
  { value: 2, label: 'Every 2 days' },
  { value: 3, label: 'Every 3 days' },
  { value: 4, label: 'Every 4 days' },
  { value: 5, label: 'Every 5 days' },
  { value: 6, label: 'Every 6 days' },
  { value: 7, label: 'Every 7 days' },
  { value: 8, label: 'Every 8 days' },
  { value: 9, label: 'Every 9 days' },
  { value: 10, label: 'Every 10 days' },
  { value: 11, label: 'Every 11 days' },
  { value: 12, label: 'Every 12 days' },
  { value: 13, label: 'Every 13 days' },
  { value: 14, label: 'Every 14 days' },
  { value: 15, label: 'Every 15 days' },
  { value: 16, label: 'Every 16 days' },
  { value: 17, label: 'Every 17 days' },
  { value: 18, label: 'Every 18 days' },
  { value: 19, label: 'Every 19 days' },
  { value: 20, label: 'Every 20 days' },
  { value: 21, label: 'Every 21 days' },
  { value: 22, label: 'Every 22 days' },
  { value: 23, label: 'Every 23 days' },
  { value: 24, label: 'Every 24 days' },
  { value: 25, label: 'Every 25 days' },
  { value: 26, label: 'Every 26 days' },
  { value: 27, label: 'Every 27 days' },
  { value: 28, label: 'Every 28 days' },
  { value: 29, label: 'Every 29 days' },
  { value: 30, label: 'Every 30 days' },
];

const weeksFrequencyList = [
  { value: 1, label: 'Every week' },
  { value: 2, label: 'Every 2 weeks' },
  { value: 3, label: 'Every 3 weeks' },
  { value: 4, label: 'Every 4 weeks' },
  { value: 5, label: 'Every 5 weeks' },
  { value: 6, label: 'Every 6 weeks' },
  { value: 7, label: 'Every 7 weeks' },
  { value: 8, label: 'Every 8 weeks' },
  { value: 9, label: 'Every 9 weeks' },
  { value: 10, label: 'Every 10 weeks' },
  { value: 11, label: 'Every 11 weeks' },
  { value: 12, label: 'Every 12 weeks' },
  { value: 13, label: 'Every 13 weeks' },
  { value: 14, label: 'Every 14 weeks' },
  { value: 15, label: 'Every 15 weeks' },
  { value: 16, label: 'Every 16 weeks' },
  { value: 17, label: 'Every 17 weeks' },
  { value: 18, label: 'Every 18 weeks' },
  { value: 19, label: 'Every 19 weeks' },
  { value: 20, label: 'Every 20 weeks' },
  { value: 21, label: 'Every 21 weeks' },
  { value: 22, label: 'Every 22 weeks' },
  { value: 23, label: 'Every 23 weeks' },
  { value: 24, label: 'Every 24 weeks' },
  { value: 25, label: 'Every 25 weeks' },
  { value: 26, label: 'Every 26 weeks' },
  { value: 27, label: 'Every 27 weeks' },
  { value: 28, label: 'Every 28 weeks' },
  { value: 29, label: 'Every 29 weeks' },
  { value: 30, label: 'Every 30 weeks' },
];

const monthsFrequencyList = [
  { value: 1, label: 'Every month' },
  { value: 2, label: 'Every 2 months' },
  { value: 3, label: 'Every 3 months' },
  { value: 4, label: 'Every 4 months' },
  { value: 5, label: 'Every 5 months' },
  { value: 6, label: 'Every 6 months' },
  { value: 7, label: 'Every 7 months' },
  { value: 8, label: 'Every 8 months' },
  { value: 9, label: 'Every 9 months' },
  { value: 10, label: 'Every 10 months' },
  { value: 11, label: 'Every 11 months' },
];

const weekDaysList = [
  { id: 1, label: 'Sunday' },
  { id: 2, label: 'Monday' },
  { id: 3, label: 'Tuesday' },
  { id: 4, label: 'Wednesday' },
  { id: 5, label: 'Thursday' },
  { id: 6, label: 'Friday' },
  { id: 7, label: 'Saturday' },
];

enum WeekDays {
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
}

const daysOfMonthList = [
  { label: '1st', value: 1 },
  { label: '2nd', value: 2 },
  { label: '3rd', value: 3 },
  { label: '4th', value: 4 },
  { label: '5th', value: 5 },
  { label: '6th', value: 6 },
  { label: '7th', value: 7 },
  { label: '8th', value: 8 },
  { label: '9th', value: 9 },
  { label: '10th', value: 10 },
  { label: '11th', value: 11 },
  { label: '12th', value: 12 },
  { label: '13th', value: 13 },
  { label: '14th', value: 14 },
  { label: '15th', value: 15 },
  { label: '16th', value: 16 },
  { label: '17th', value: 17 },
  { label: '18th', value: 18 },
  { label: '19th', value: 19 },
  { label: '20th', value: 20 },
  { label: '21st', value: 21 },
  { label: '22nd', value: 22 },
  { label: '23rd', value: 23 },
  { label: '24th', value: 24 },
  { label: '25th', value: 25 },
  { label: '26th', value: 26 },
  { label: '27th', value: 27 },
  { label: '28th', value: 28 },
  { label: '29th', value: 29 },
  { label: '30th', value: 30 },
  { label: '31st', value: 31 },
];

// 1-indexed indices of months with 30 days
const shortMonths = [4, 6, 9, 11];

const weekIndexList = [
  { label: 'First', value: 1 },
  { label: 'Second', value: 2 },
  { label: 'Third', value: 3 },
  { label: 'Fourth', value: 4 },
  { label: 'Last', value: 5 },
];

const monthNamesList = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

const recurringEventTypes = {
  NONE: 0,
  DAILY_BY_DAY: 1,
  DAILY_WEEKDAYS: 2,
  WEEKLY: 3,
  MONTHLY_BY_DAY_OF_MONTH: 4,
  MONTHLY_BY_DAY_OF_WEEK: 8,
  YEARLY_BY_DAY_OF_MONTH: 9,
  YEARLY_BY_DAY_OF_WEEK: 13,
};

const defaultUserSelectionTabs = [
  {
    label: 'User Type',
    type: 'personType',
  },
  {
    label: 'Position',
    type: 'pos',
  },
  {
    label: 'Year',
    type: 'year',
  },
  {
    label: 'Custom Groups',
    type: 'custom',
  },
];

const nonAcademicUserSelectionTabs = _.cloneDeep(defaultUserSelectionTabs);
const idx = _.findIndex(defaultUserSelectionTabs, { type: 'year' });
nonAcademicUserSelectionTabs[idx] = {
  label: 'Status',
  type: 'year',
};

const selectionCodesTabTypes = [
  'all',
  'selected',
  'personType',
  'pos',
  'year',
  'custom',
  'contacts',
  'status',
];

const MESSAGING_INPUT_TEXT_MAX_LENGTH = 1500;

const schedulingTypes = {
  IMMEDIATE: 'Immediately',
  SCHEDULED: 'Scheduled',
};

// @TODO Move to translation file
const messageBlastTemplates = Object.freeze({
  [schedulingTypes.IMMEDIATE]: {
    RECIPIENTS_ONLY: 'Your message was sent to <%= recipientCount %> recipient(s).',
    GROUPS_ONLY: 'Your message was sent to <%= groupCount %> group(s).',
    RECIPIENTS_AND_GROUPS:
      'Your message was sent to <%= recipientCount %> recipient(s) and <%= groupCount %> group(s).',
  },
  [schedulingTypes.SCHEDULED]: {
    RECIPIENTS_ONLY: 'Your message will be sent to <%= recipientCount %> recipient(s).',
    GROUPS_ONLY: 'Your message will be sent to <%= groupCount %> group(s).',
    RECIPIENTS_AND_GROUPS:
      'Your message will be sent to <%= recipientCount %> recipient(s) and <%= groupCount %> group(s).',
  },
});

// @TODO Move to translation file
const formAssignmentType = Object.freeze({
  onetime: 'One-time',
  perpetual: 'Perpetual',
  scheduled: 'Scheduled',
});

// @TODO Move to translation file
const FORM_ASSIGNMENTS_FILTER_OPTIONS = {
  STATUS: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
  },
  FOLDER: {
    ANY: 'Any Folder',
    NONE: 'No Folder',
  },
  FORM: {
    ANY: 'Any Form',
    UNTITLED: 'Untitled',
  },
};

// Values taken from web/www/views/messaging/room.cfm
const colorOptions = {
  red: {
    label: 'Red',
    value: '#fc4b38',
  },
  orange: {
    label: 'Orange',
    value: '#ff8c40',
  },
  yellow: {
    label: 'Yellow',
    value: '#fcd801',
  },
  green: {
    label: 'Green',
    value: '#2bca90',
  },
  lightBlue: {
    label: 'Light Blue',
    value: '#47dfda',
  },
  blue: {
    label: 'Blue',
    value: '#5ac5ed',
  },
  darkBlue: {
    label: 'Dark Blue',
    value: '#446cb2',
  },
  purple: {
    label: 'Purple',
    value: '#707dc3',
  },
  darkGray: {
    label: 'Dark Gray',
    value: '#959ca4',
  },
  gray: {
    label: 'Gray',
    value: '#c8cad8',
  },
};

const USER_FACING_MESSAGE_TYPES = {
  email: {
    label: 'Email',
  },
  text: {
    label: 'Text',
  },
  voice: {
    label: 'Voice',
  },
};

const DEFAULT_RECONNECT_INTERVAL = 5000; // time between retrying set to 5s

const smsMessageDeliveryStatus = makeConstantsObject(['FAILED', 'SUCCEEDED', 'PENDING']);

const twModelTypes = makeConstantsObject(['ACCOUNT', 'FILE', 'VIDEO']);

const accessibilityStates = {
  DISABLED: ['disabled'],
  SELECTED: ['selected'],
  SELECTED_AND_DISABLED: ['selected', 'disabled'],
  NONE: emptyArray,
};

// TODO: internationalization
const offlineWarningMessage = 'Offline Mode | No Connection Detected';

const timeFormatOptions = makeConstantsObject(['AM_PM', 'MILITARY_TIME']);

const appointmentSlotTypes = [
  {
    value: 'any',
    label: 'Any length',
  },
];

const personStatusFilterOptions = makeConstantsObject(['ALL', 'ACTIVE', 'INACTIVE']);

const eligibilityFilterOptions = makeConstantsObject(['ALL_ELIGIBILITY', 'AT_RISK', 'ON_TRACK']);

const deliveryTypeFilterOptions = makeConstantsObject(['TEXT', 'EMAIL', 'VOICE'], {
  TEXT: 'text',
  SMS: 'sms',
  EMAIL: 'email',
  VOICE: 'voice',
});

const avgCharWidthToFontSize: Record<string, number> = {
  default: 8,
  12: 8,
};

const fullCalendarViewNames = {
  day: 'timeGridDay',
  week: 'timeGridWeek',
  month: 'dayGridMonth',
  list: 'listWeek',
};

const CALENDAR_VIEW = 'CalendarView';

const FILE_ATTACHMENTS = {
  image: {
    name: 'image',
    color: '#EE9051',
    bgColor: '#FDF2E9',
  },
  pdf: {
    name: 'picture_as_pdf',
    color: '#CA0971',
    bgColor: '#FBEBED',
  },
  word: {
    name: 'description',
    color: '#0184FE',
    bgColor: '#DAEDFE',
  },
  powerpoint: {
    name: 'present_to_all',
    color: '#8C49BC',
    bgColor: '#F7EFFE',
  },
  spreadsheet: {
    name: 'window',
    color: '#1F744B',
    bgColor: '#E1F7EC',
  },
  video: {
    name: 'smart_display',
    color: '#F12B2C',
    bgColor: '#FDDFDF',
  },
  music: {
    name: 'music_note',
    color: '#70C400',
    bgColor: '#E5F8CC',
  },
  file: {
    name: 'file_present',
    color: '#7B61FF',
    bgColor: '#E5E0FF',
  },
};

const KEY_CODES = {
  ENTER: 'Enter',
  SHIFT: 'ShiftLeft',
  SPACE: 'Space',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
};

const TOAST_TIMEOUT = 6000;

const HOTEL_ROOM_TYPES = {
  single: 'Single',
  twin: 'Twin',
  double: 'Double',
  doubleWPullout: 'Double w/ Pullout',
  queen: 'Queen',
  king: 'King',
  kingWPullouts: 'King w/ Pullout',
  suite: 'Suite',
  deluxe: 'Deluxe',
  standard: 'Standard',
  accessible: 'Accessible',
};

const SERVER_TIME_ZONE = 'America/New_York';

const PROFILE_ATTRIBUTE_DATA_TYPES = {
  text: 'Text',
  numeric: 'Numeric',
  boolean: 'Yes/No',
  date: 'Date',
  list: 'List',
  listMulti: 'List',
};

const CANCEL_SESSION_REASON = makeConstantsObject([
  'weather',
  'team_schedule_change',
  'facility',
  'travel_delay',
  'student_athlete_health_wellness',
  'other',
]);

const TICKETING_EVENT_STATUS = makeConstantsObject(['upcoming', 'past', 'archived']);

enum CoreCategoryCode {
  ACADEMIC = 'academic',
  ADDRESSES = 'addresses',
  ATHLETE = 'athlete',
  AUTOMOBILE = 'automobile',
  CONTACT = 'contact',
  FAMILY_CONTACTS = 'family_contacts',
  INTERNATIONAL_TRAVEL = 'international_travel',
  PERSONAL = 'personal',
  ROOMMATES = 'roommates',
}

enum CoreAttributeCode {
  // Academic
  ACADEMIC_YEAR = 'academicYear',
  ACADEMIC_HAS_SCHOLARSHIP = 'hasScholarship',
  ACADEMIC_MAJOR = 'major',
  ACADEMIC_SCHOLARSHIP_AMOUNT = 'scholarshipAmount',
  // Addresses
  ADDRESS_CITY = 'addressCity',
  ADDRESS_DESCRIPTION = 'addressDescription',
  ADDRESS_IS_MAILING = 'addressIsMailing',
  ADDRESS_STATE = 'addressState',
  ADDRESS_STREET = 'addressStreet',
  ADDRESS_STREET2 = 'addressStreet2',
  ADDRESS_TYPES = 'addressTypes',
  ADDRESS_ZIP_CODE = 'addressZipCode',
  // Athlete
  ATHLETE_POSITIONS = 'athletePositions',
  ATHLETE_STATUS = 'athleteStatus',
  ATHLETE_ATHLETIC_YEAR = 'athleticYear',
  ATHLETE_JERSEY_NUMBER = 'jerseyNumber',
  // Automobile
  AUTOMOBILE_MAKE = 'automobile.make',
  AUTOMOBILE_MODEL = 'automobile.model',
  AUTOMOBILE_PLATE_NUMBER = 'automobile.plateNumber',
  AUTOMOBILE_PLATE_STATE = 'automobile.plateState',
  AUTOMOBILE_YEAR = 'automobile.year',
  AUTOMOBILE_DRIVERS_LICENCE_EXP_DATE = 'driversLicenseExpDate',
  AUTOMOBILE_DRIVERS_LICENCE_NUMBER = 'driversLicenseNumber',
  AUTOMOBILE_DRIVERS_LICENCE_STATE = 'driversLicenseState',
  // Contact
  CONTACT_EMAIL_ALTERNATIVE = 'emailAddressAlternate',
  CONTACT_EMAIL = 'emailAddress',
  CONTACT_FACEBOOK_ACCOUNT = 'facebookAccount',
  CONTACT_TWITTER_ACCOUNT = 'twitterAccount',
  CONTACT_PHONE_FAX = 'phoneFax',
  CONTACT_PHONE_HOME = 'phoneHome',
  CONTACT_PHONE_CELL = 'phoneCell',
  CONTACT_PHONE_OFFICE = 'phoneOffice',
  // Family Contacts
  FAMILY_CONTACTS_BIRTHDATE = 'contactBirthdate',
  FAMILY_CONTACTS_CITY = 'contactCity',
  FAMILY_CONTACTS_EMAIL = 'contactEmailAddress',
  FAMILY_CONTACTS_FIRST_NAME = 'contactFirstName',
  FAMILY_CONTACTS_LAST_NAME = 'contactLastName',
  FAMILY_CONTACTS_HOME_PHONE = 'contactHomePhone',
  FAMILY_CONTACTS_MOBILE_PHONE = 'contactMobilePhone',
  FAMILY_CONTACTS_WORK_PHONE = 'contactWorkPhone',
  FAMILY_CONTACTS_MARITIAL_STATUS = 'contactMaritalStatus',
  FAMILY_CONTACTS_RELATIONSHIP = 'contactRelationship',
  FAMILY_CONTACTS_IS_GUARDIAN = 'contactIsGuardian',
  FAMILY_CONTACTS_STATE = 'contactState',
  FAMILY_CONTACTS_STREET = 'contactStreet',
  FAMILY_CONTACTS__ZIP = 'contactZip',
  // International Travel
  INTERNATIONAL_TRAVEL_BIRTH_COUNTRY = 'birthCountry',
  INTERNATIONAL_TRAVEL_DOCUMENT_COUNTRY = 'documentCountry',
  INTERNATIONAL_TRAVEL_DOCUMENT_EMP_DATE = 'documentExpDate',
  INTERNATIONAL_TRAVEL_DOCUMENT_NUMBER = 'documentNumber',
  INTERNATIONAL_TRAVEL_DOCUMENT_TYPE = 'documentType',
  INTERNATIONAL_TRAVEL_NATIONALITY = 'nationality',
  INTERNATIONAL_TRAVEL_RESIDENCY = 'residency',
  // Personal
  PERSONAL_DATE_OF_BIRTH = 'dateOfBirth',
  PERSONAL_FIRST_NAME = 'firstName',
  PERSONAL_LAST_NAME = 'lastName',
  PERSONAL_MIDDLE_NAME = 'middleName',
  PERSONAL_NICKNAME = 'nickname',
  PERSONAL_GENDER = 'gender',
  PERSONAL_SCHOOL_ID = 'schoolIdentifier',
  PERSONAL_USER_PERMISSIONS = 'userPermissions',
  PERSONAL_PERSON_ID = 'personId',
  PERSONAL_START_DATE = 'startDate',
  PERSONAL_TEAMS = 'teams',
  PERSONAL_TITLE = 'title',
  PERSONAL_USER_TYPES = 'userTypes',
  PERSONAL_WEIGHT = 'weight',
  PERSONAL_SUFFIX = 'suffix',
  // Roommates
  ROOMMATES_EMAIL = 'roommateEmail',
  ROOMMATES_NAME = 'roommateName',
  ROOMMATES_PHONE = 'roommatePhone',
}

export {
  CALENDAR_VIEW,
  CANCEL_SESSION_REASON,
  CUSTOM_FIELD_SECTIONS,
  CUSTOM_FIELD_TYPES,
  CoreAttributeCode,
  CoreCategoryCode,
  DATA_STATUS,
  DEFAULT_RECONNECT_INTERVAL,
  FETCH_STATUS,
  FILE_ATTACHMENTS,
  FORM_ACTION_TYPES,
  FORM_ASSIGNMENTS_FILTER_OPTIONS,
  GOLDEN_RATIO,
  GOLDEN_RATIO_INVERSE,
  HOTEL_ROOM_TYPES,
  KEY_CODES,
  LOGOUT_CAUSES,
  MAX_TIMEOUT_DURATION,
  MESSAGING_INPUT_TEXT_MAX_LENGTH,
  OFFLINE_AUTH_STATUS,
  OFFLINE_MESSAGE,
  PORTRAIT_ONLY_SCREENS,
  PROFILE_ATTRIBUTE_DATA_TYPES,
  SERVER_TIME_ZONE,
  TICKETING_EVENT_STATUS,
  TOAST_TIMEOUT,
  USER_FACING_MESSAGE_TYPES,
  WeekDays,
  accessibilityStates,
  alertNoneValue,
  alertOptionsList,
  appointmentSlotTypes,
  avgCharWidthToFontSize,
  colorOptions,
  contactUsEmail,
  daysFrequencyList,
  daysOfMonthList,
  defaultUserSelectionTabs,
  deliveryTypeFilterOptions,
  eligibilityFilterOptions,
  emptyArray,
  emptyArrayFn,
  emptyObject,
  emptyObjectFn,
  formAssignmentType,
  fullCalendarViewNames,
  labelsByFormType,
  listTypes,
  makeConstantsObject,
  messageBlastTemplates,
  monthNamesList,
  monthsFrequencyList,
  nonAcademicUserSelectionTabs,
  noopFn,
  offlineWarningMessage,
  personStatusFilterOptions,
  recurringEventTypes,
  recurringOptionsList,
  reminderOptionsList,
  schedulingTypes,
  selectionCodesTabTypes,
  shortMonths,
  smsMessageDeliveryStatus,
  stateList,
  timeFormatOptions,
  twModelTypes,
  weekDaysList,
  weekIndexList,
  weeksFrequencyList,
};

export const GENDERS: GenderOption[] = [
  { labelNamespace: 'profiles.genders.male', value: Gender.Male, shortValue: 'M' },
  { labelNamespace: 'profiles.genders.female', value: Gender.Female, shortValue: 'F' },
  { labelNamespace: 'profiles.genders.nonbinary', value: Gender.NonBinary, shortValue: 'N' },
];
