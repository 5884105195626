import styled from '@emotion/styled';
import { colors } from '@teamworksdev/style';
import { TWFlexContainer } from '@tw/components';

export const Image = styled.img({
  maxHeight: '100%',
  maxWidth: '100%',
});

export const ImageDiv = styled.div(() => ({
  textAlign: 'center',
}));

export const QuestionBox = styled.div<{ opacity: number }>(({ opacity }) => ({
  opacity,
}));

export const PdfImgContainer = styled(TWFlexContainer)<{
  previewTemplate: boolean;
}>(({ previewTemplate }) => ({
  marginTop: previewTemplate ? '' : '20px',
}));

export const FooterContainer = styled(TWFlexContainer)({
  width: '100%',
});

export const ButtonContainer = styled(TWFlexContainer)({
  margin: '18px -12px 0px 0px',
  'span.material-icons-outlined': {
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
});

export const OpenInTabText = styled.div({
  color: 'rgba(0, 0, 0, 0.87)',
});

export const StyledImg = styled.img<{ previewTemplate: boolean }>(({ previewTemplate, theme }) => ({
  height: previewTemplate ? '100%' : '690px',
  width: previewTemplate ? '100%' : '495px',
  border: previewTemplate
    ? `${theme.baseUnit * 1.5}px solid #5D758C14`
    : `${theme.baseUnit * 3}px solid #5D758C14`,
  borderRadius: theme.fontUnit,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
}));

export const PageCount = styled.div(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.87)',
  background: colors.light[300],
  borderRadius: theme.baseUnit * 2,
  padding: '6px 12px 6px 12px',
  marginTop: theme.baseUnit * 2,
}));
