/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchOrgBulletinsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  bulletinId?: Types.InputMaybe<Types.Scalars['TWFilter_ID']>;
  pk?: Types.InputMaybe<Types.Scalars['TWFilter_String']>;
  sort?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.BulletinSortEnum>> | Types.InputMaybe<Types.BulletinSortEnum>
  >;
  createdBy?: Types.InputMaybe<Types.Scalars['TWFilter_Int']>;
  bulletinType?: Types.InputMaybe<Types.Scalars['TWFilter_BulletinType']>;
  label?: Types.InputMaybe<Types.Scalars['TWFilter_String']>;
  customSort?: Types.InputMaybe<Types.BulletinCustomSortEnum>;
}>;

export type FetchOrgBulletinsQuery = {
  allBulletins?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      bulletin?: {
        bulletinId: string;
        bulletinType: Types.BulletinType;
        label: string;
        externalUrl?: string | null;
        isPinned: boolean;
        createdDate?: any | null;
        isActive: boolean;
        totalClicks?: number | null;
        content: string;
        expirationDateTime?: any | null;
        scheduledDatetime?: any | null;
        messageId?: number | null;
        headerImageFull?: string | null;
        headerImageThumb?: string | null;
        meta?: any | null;
        personIdsWhoClicked?: Array<number | null> | null;
        buttonText?: string | null;
        key?: string | null;
        visibilityCodes?: Array<string | null> | null;
        creator?: {
          pk?: string | null;
          initials?: string | null;
          fullName?: string | null;
          picture?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchOrgBulletinsDocument = gql`
  query FetchOrgBulletins(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $bulletinId: TWFilter_ID
    $pk: TWFilter_String
    $sort: [BulletinSortEnum]
    $createdBy: TWFilter_Int
    $bulletinType: TWFilter_BulletinType
    $label: TWFilter_String
    $customSort: BulletinCustomSortEnum
  ) {
    allBulletins(
      after: $after
      before: $before
      first: $first
      last: $last
      bulletinId: $bulletinId
      pk: $pk
      sort: $sort
      createdBy: $createdBy
      bulletinType: $bulletinType
      label: $label
      customSort: $customSort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        bulletin: node {
          key: pk
          bulletinId
          bulletinType
          label
          externalUrl
          isPinned
          createdDate
          isActive
          totalClicks
          creator {
            pk
            fullName: fullNameNormalOrder
            initials
            picture: pictureUrl
          }
          content
          expirationDateTime
          scheduledDatetime
          messageId
          headerImageFull
          headerImageThumb
          meta
          personIdsWhoClicked
          buttonText
          visibilityCodes: visibility
        }
      }
    }
  }
`;

/**
 * __useFetchOrgBulletinsQuery__
 *
 * To run a query within a React component, call `useFetchOrgBulletinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrgBulletinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrgBulletinsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      bulletinId: // value for 'bulletinId'
 *      pk: // value for 'pk'
 *      sort: // value for 'sort'
 *      createdBy: // value for 'createdBy'
 *      bulletinType: // value for 'bulletinType'
 *      label: // value for 'label'
 *      customSort: // value for 'customSort'
 *   },
 * });
 */
export function useFetchOrgBulletinsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchOrgBulletinsQuery, FetchOrgBulletinsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchOrgBulletinsQuery, FetchOrgBulletinsQueryVariables>(
    FetchOrgBulletinsDocument,
    options,
  );
}
export function useFetchOrgBulletinsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchOrgBulletinsQuery,
    FetchOrgBulletinsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchOrgBulletinsQuery, FetchOrgBulletinsQueryVariables>(
    FetchOrgBulletinsDocument,
    options,
  );
}
export type FetchOrgBulletinsQueryHookResult = ReturnType<typeof useFetchOrgBulletinsQuery>;
export type FetchOrgBulletinsLazyQueryHookResult = ReturnType<typeof useFetchOrgBulletinsLazyQuery>;
export type FetchOrgBulletinsQueryResult = Apollo.QueryResult<
  FetchOrgBulletinsQuery,
  FetchOrgBulletinsQueryVariables
>;
