import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

import { CourseSection, GraphQLPaginatedResponse } from '@tw/types';

import { STUDENT_TERM_BULK_SELECT_ENROLLMENTS_QUERY } from '@tw/modules/Academics2/@graphql';

export type StudentTermBulkSelectEnrollment = {
  id: string;
  enrollments: GraphQLPaginatedResponse<{
    id: string;
    courseSection: Pick<CourseSection, 'id' | 'courseId'>;
  }>;
};

export interface StudentTermBulkSelectEnrollmentsVariables {
  searchStudents?: string;
  teamIds?: number[];
  termId?: number | string;
  studentId?: number | string;
  studentTermId?: number | string;
  advisorIds?: number[];
  courseIds?: number[];
  courseSectionIds?: number[];
  isAtRisk?: boolean;
  studentYearId?: number | string;
  eligibilityYearIds?: number[];
}

interface StudentTermBulkSelectEnrollmentsData {
  studentTerms: Pick<GraphQLPaginatedResponse<StudentTermBulkSelectEnrollment>, 'edges'>;
}

export const useStudentTermBulkSelectEnrollmentsLazyQuery = (
  options?: LazyQueryHookOptions<
    StudentTermBulkSelectEnrollmentsData,
    StudentTermBulkSelectEnrollmentsVariables
  >,
) =>
  useLazyQuery<StudentTermBulkSelectEnrollmentsData, StudentTermBulkSelectEnrollmentsVariables>(
    STUDENT_TERM_BULK_SELECT_ENROLLMENTS_QUERY,
    options,
  );
