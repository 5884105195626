import styled from '@emotion/styled';

import { sharedSpacingStyles, SpacingComponentProps } from '../../../../utils';

const inputHeight = 30;

const ErrorContainer = styled.div<{ inputWidth: number }>(({ inputWidth }) => ({
  maxWidth: inputWidth,
}));

const Container = styled.div<{ isDisabled: boolean; inputWidth: number }>(
  ({ isDisabled, theme, inputWidth }) => ({
    maxWidth: inputWidth,
    position: 'relative',
    borderRadius: 4,
    border: `${theme.borderWidth.small}px solid ${theme.colors.border}`,
    background: isDisabled ? theme.colors.disabledBackground : theme.colors.white,
    '.custom-group-divider': {
      borderTop: `1px solid ${theme.colors.borderGray}`,
    },
  }),
);

const InputRenderContainer = styled.div<{ inputWidth: number | string }>(({ inputWidth }) => ({
  width: typeof inputWidth === 'number' ? inputWidth - 35 : inputWidth,
  outline: 'none',
}));

const InputContainer = styled.div<SpacingComponentProps>(() => [
  sharedSpacingStyles,
  {
    outline: 'none',
  },
]);
InputContainer.defaultProps = {
  twPaddingLeft: 0.5,
};

const Input = styled.input<SpacingComponentProps & { disabled: boolean; inputWidth: number }>(
  ({ disabled, theme, inputWidth }) => [
    sharedSpacingStyles,
    {
      width: inputWidth,
      height: inputHeight,
      backgroundColor: disabled ? theme.colors.disabledBackground : theme.colors.white,
      border: `none`,
      outline: 'none !important',
      paddingLeft: theme.baseUnit * 0.5,
      textOverflow: 'ellipsis',
    },
  ],
);
Input.defaultProps = {
  twPaddingLeft: 0.5,
};

interface DropDownContainerProps {
  state: string;
  inputWidth: number;
}

const DropDownContainer = styled.div<SpacingComponentProps & DropDownContainerProps>(
  ({ theme, state, inputWidth }) => {
    const animationStates = {
      entering: { opacity: 0, height: 0 },
      entered: { opacity: 1, height: 300 },
      exiting: { opacity: 1, height: 300 },
      exited: { opacity: 0, height: 0 },
    };

    const baseUnitHalf = theme.baseUnit * 0.5;
    return [
      sharedSpacingStyles,
      {
        left: -1,
        cursor: 'default',
        position: 'absolute',
        transition: `height 100ms ease-in-out, opacity 100ms ease-in-out`,
        height: 300,
        width: inputWidth,
        top: inputHeight + baseUnitHalf,
        zIndex: 2,
        backgroundColor: theme.colors.white,
        overflow: 'auto',
        border: `${theme.borderWidth.small}px solid ${theme.colors.border}`,
        borderRadius: 5,
        boxShadow: `0px 5px 10px #B5B5B5`,
        ...animationStates[state],
      },
    ];
  },
);

export {
  Input,
  InputContainer,
  DropDownContainer,
  InputRenderContainer,
  ErrorContainer,
  Container,
};
