import * as React from 'react';
import { ApolloError } from '@apollo/client';
import _ from 'lodash';

import { apolloUtils } from '@tw/util';
import { GraphQLUserErrorsType } from '@tw/types';
import { useTranslator } from '@tw/i18n';

import TWAlertDeprecated, { TWAlertDeprecatedProps } from '../../components.web/TWAlertDeprecated';
import { Bullets } from './TWErrorAlertDeprecated.styles';

interface TWErrorAlertDeprecatedProps extends Omit<TWAlertDeprecatedProps, 'message'> {
  apolloError?: ApolloError | ApolloError[];
  graphQLUserErrors?: GraphQLUserErrorsType;
  message?: string;
}

/*

  TWErrorAlert has been deprecated for error handling.
  Use antd's form validation for validation errors (see AddEditForm.tsx for examples of custom validation)
  Use TWMessage.errorHandler for all other errors.
  See https://teamworks.atlassian.net/wiki/spaces/FEG/pages/793542727/Current+Coding+Patterns for details.

*/

/**
 * This can be used to process and display errors in generic way. Following options are handled:
 *
 * * apolloError -- error object produced by Apollo client or array of those objects.
 *   NOTE, use useClearableApolloErrors hook to prevent unwanted reappearing of old errors in modal (etc.) when they are reopened.
 * * graphQLUserErrors -- userErrors object producer by our GraphQL mutations
 * * message -- just a message string to display
 *
 * Error inputs are handled in above order and only the first non-empty is displayed.
 * If nothing is passed, nothing is rendered.
 *
 * @param props
 * @constructor
 */
const TWErrorAlertDeprecated: React.FC<TWErrorAlertDeprecatedProps> = (props) => {
  const { apolloError, graphQLUserErrors, message: passedMessage, ...otherProps } = props;

  const translator = useTranslator();

  const apolloErrors = _.isArray(apolloError) ? apolloError : [apolloError];
  const apolloErrorToDisplay = _.find(apolloErrors, (error) => !_.isEmpty(error));

  let resultMessage: React.ReactNode;
  if (apolloErrorToDisplay) {
    resultMessage = apolloUtils.getUserErrorMessage(apolloErrorToDisplay, translator);
  } else if (!_.isEmpty(graphQLUserErrors)) {
    resultMessage = (
      <Bullets>
        {_.map(graphQLUserErrors, ({ field, message }) => (
          <li key={field}>{message}</li>
        ))}
      </Bullets>
    );
  } else {
    resultMessage = passedMessage;
  }

  if (!resultMessage) return null;

  return (
    <TWAlertDeprecated
      message={resultMessage}
      type="error"
      showIcon
      data-testid="TWErrorAlertDeprecated"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
};

export default TWErrorAlertDeprecated;
