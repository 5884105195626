/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExternalVideoQueryVariables = Types.Exact<{
  uuid: Types.Scalars['String'];
}>;

export type ExternalVideoQuery = {
  externalVideo?: {
    id: string;
    title: string;
    description?: string | null;
    wistiaVideoUuid?: string | null;
    wistiaThumbnailUrl?: string | null;
    wistiaOriginalFileUrl?: string | null;
  } | null;
};

export const ExternalVideoDocument = gql`
  query ExternalVideo($uuid: String!) {
    externalVideo(uuid: $uuid) {
      id
      title
      description
      wistiaVideoUuid
      wistiaThumbnailUrl
      wistiaOriginalFileUrl
    }
  }
`;

/**
 * __useExternalVideoQuery__
 *
 * To run a query within a React component, call `useExternalVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalVideoQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useExternalVideoQuery(
  baseOptions: Apollo.QueryHookOptions<ExternalVideoQuery, ExternalVideoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalVideoQuery, ExternalVideoQueryVariables>(
    ExternalVideoDocument,
    options,
  );
}
export function useExternalVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExternalVideoQuery, ExternalVideoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalVideoQuery, ExternalVideoQueryVariables>(
    ExternalVideoDocument,
    options,
  );
}
export type ExternalVideoQueryHookResult = ReturnType<typeof useExternalVideoQuery>;
export type ExternalVideoLazyQueryHookResult = ReturnType<typeof useExternalVideoLazyQuery>;
export type ExternalVideoQueryResult = Apollo.QueryResult<
  ExternalVideoQuery,
  ExternalVideoQueryVariables
>;
