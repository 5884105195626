import { Tooltip } from 'antd';
import Linkify from 'linkify-react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import { TWFlexContainer, TWSpacingContainer } from '@tw/components';
import { FormAssignmentNode } from '@tw/generated';
import { getTranslation } from '@tw/i18n';
import { dayjs } from '@tw/util';
import {
  MAX_LENGTH,
  TWQuickFormDescriptionFieldProps,
} from './TWQuickFormDescriptionField.definitions';
import {
  Description,
  Details,
  Icon,
  IconLabel,
  UserLabel,
} from './TWQuickFormDescriptionField.styles';

const TWQuickFormDescriptionField = ({
  id,
  description,
  assignment,
  currentPerson,
  hideAssignedOn,
}: TWQuickFormDescriptionFieldProps) => {
  const location = useLocation();
  const isFormCompletion = location?.pathname?.includes('/forms/formComplete') ?? false;
  const isExternalFormCompletion = location?.pathname?.includes('/ext2') ?? false;
  const isQuickFormBuilder = location?.pathname?.includes('/forms/quickFormBuilder') ?? false;
  const isTemplates = location?.pathname?.includes('/forms/templates') ?? false;
  const isAssignForm = location?.pathname?.includes('/forms/assign') ?? false;
  const isDashboardAssignForm = location?.pathname?.includes('/home/overview') ?? false;
  const isTravelTripForm = location?.pathname?.includes('/travel/trips') ?? false;
  const isProfileForm = location?.pathname?.includes('/profiles') ?? false;
  const hideBox =
    !description &&
    (isTemplates ||
      isQuickFormBuilder ||
      isAssignForm ||
      isDashboardAssignForm ||
      isTravelTripForm ||
      isProfileForm);
  const showAssignedBy = isFormCompletion || isExternalFormCompletion;

  const getDateLabel = (curAssignment: FormAssignmentNode) => {
    if (curAssignment?.dueDate) {
      return getTranslation('forms.dueBy', { dueDate: '' });
    }
    if (curAssignment?.assignedDateTime) {
      return getTranslation('forms.assignedOn', { assignedDate: '' });
    }
    // returning empty string to make eslint happy
    return '';
  };

  const getIconType = (curAssignment: FormAssignmentNode) => {
    if (curAssignment?.dueDate) {
      return 'timer';
    }
    if (curAssignment?.assignedDateTime) {
      return 'calendar_month';
    }
    return '';
  };

  const renderChip = (name?: string) => {
    if (name && name.length > MAX_LENGTH) {
      return (
        <Tooltip placement="topLeft" title={name}>
          <TWFlexContainer row align="center">
            <Icon className="material-icons">person</Icon>
            <IconLabel>{getTranslation('assignedBy')}</IconLabel>
            <UserLabel>
              {_.truncate(name, {
                length: MAX_LENGTH,
              })}
            </UserLabel>
          </TWFlexContainer>
        </Tooltip>
      );
    }
    return (
      <TWFlexContainer row align="center">
        <Icon className="material-icons">person</Icon>
        <IconLabel>{getTranslation('assignedBy')}</IconLabel>
        <UserLabel>
          {_.truncate(name, {
            length: MAX_LENGTH,
          })}
        </UserLabel>
      </TWFlexContainer>
    );
  };

  return (
    <Linkify options={{ target: { url: '_blank' } }}>
      <>
        <Description
          id={id}
          data-testid="QuickForm:Description"
          className="field-description"
          hideBox={hideBox}
        >
          {description || ''}
          <TWFlexContainer row justify="space-between">
            {showAssignedBy && (
              <Details hasDesc={!description}>
                {renderChip(
                  assignment?.assignedBy?.displayName
                    ? assignment.assignedBy.displayName
                    : currentPerson,
                )}
              </Details>
            )}
            {!hideAssignedOn && (assignment?.dueDate || assignment?.assignedDateTime) ? (
              <Details hasDesc={!description}>
                <TWFlexContainer row align="center">
                  <Icon className="material-icons">{getIconType(assignment)}</Icon>
                  <IconLabel>{getDateLabel(assignment)}</IconLabel>
                  <UserLabel>
                    {assignment?.dueDate
                      ? dayjs(assignment.dueDate).format('MMM D, YYYY')
                      : dayjs(assignment.assignedDateTime).format('MMM D, YYYY')}
                  </UserLabel>
                </TWFlexContainer>
              </Details>
            ) : null}
            <TWSpacingContainer twMarginRight={1} />
          </TWFlexContainer>
        </Description>
      </>
    </Linkify>
  );
};

export default TWQuickFormDescriptionField;
