import { OktaAuth } from '@okta/okta-auth-js';

export const oktaAuth = new OktaAuth({
  devMode: Boolean(__DEV__),
  scopes: ['openid', 'email', 'profile', 'tw:read', 'tw:write', 'tw:admin', 'offline_access'],
  issuer: `${process.env.OKTA_HOST}oauth2/default`,
  clientId: process.env.OKTA_SPA_CLIENT_ID,
  redirectUri: `${window.location.origin}/auth/callback`,
  maxClockSkew: 600, // Default is 300 (5 minutes)
});
