import { datadogRum } from '@datadog/browser-rum';
import { useTheme } from '@emotion/react';
import { Button } from '@teamworksdev/react';
import { Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { useAsync } from 'react-use';

import { TWAvatar, TWIcon, TWMessage, TWModal } from '@tw/components';
import { useFlags } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { getBase64, toMegabytes } from '@tw/util/fileUtils';

import { TWInputImageUploadCropProps } from './TWInputImageUploadCrop.definitions';
import {
  AvatarContainer,
  AvatarIconContainer,
  CanvasContainer,
  CropperContainer,
} from './TWInputImageUploadCrop.styles';

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

// This function was taken from examples in the ReadMe of https://github.com/ricardo-ch/react-easy-crop
const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number },
) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  const ctx = canvas.getContext('2d');

  if (ctx) {
    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx?.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
    );
  }

  return canvas;
};

interface CropModalProps {
  imageFileBase64: string;
  handleCancel: () => void;
  containerId?: string;
  showCropModal?: boolean;
  aspectRatio?: number;
  handleOk: (canvas: HTMLCanvasElement) => void;
  asAvatar?: boolean;
}

const CropModal = ({
  imageFileBase64,
  handleCancel,
  containerId,
  showCropModal,
  aspectRatio = 1,
  handleOk,
  asAvatar,
}: CropModalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPx: Area) => {
    setCroppedAreaPixels(croppedAreaPx);
  }, []);

  const setCroppedImage = async () => {
    try {
      const canvas = await getCroppedImg(imageFileBase64, croppedAreaPixels);
      handleOk(canvas);
    } catch (err) {
      console.error(err);
      datadogRum.addError(err);
    }
  };

  return (
    <TWModal
      title={getTranslation('editPicture')}
      onOk={setCroppedImage}
      onCancel={handleCancel}
      containerId={containerId}
      open={showCropModal}
      testID="imageCropModal"
    >
      <CropperContainer>
        <CanvasContainer>
          <Cropper
            image={imageFileBase64}
            crop={crop}
            cropShape={asAvatar ? 'round' : 'rect'}
            showGrid={!asAvatar}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </CanvasContainer>
      </CropperContainer>
    </TWModal>
  );
};

export const TWInputImageUploadCrop = ({
  aspectRatio,
  accessibilityLabel,
  testID,
  label,
  onChange,
  containerId,
  uploadControlRef,
  asAvatar,
  avatarIcon = <TWIcon type="tw-user" />,
  avatarSize = 64,
  avatarSrc,
  maxFileSizeMb = 5,
  onlyImages = false,
}: TWInputImageUploadCropProps) => {
  const theme = useTheme();
  const [showCropModal, setShowCropModal] = useState(false);
  const [originalImageFile, setOriginalImageFile] = useState<RcFile | null>(null);
  const { value: originalImageB64 } = useAsync<string | null>(async () => {
    if (!originalImageFile) return null;
    return getBase64(originalImageFile);
  }, [originalImageFile]);
  const [croppedImageB64, setCroppedImageB64] = useState<string | null>(null);
  const { messagingFileAttachmentConversation } = useFlags();

  // Check that file is less than or equal to maxFileSizeMb
  const beforeUpload = (file: File) => {
    const fileTooBig = toMegabytes(file.size) > maxFileSizeMb;

    if (fileTooBig) {
      const errors = [
        getTranslation('files.modal.upload.error', { fileName: file.name }),
        getTranslation('files.modal.upload.errorSizeTooBig', { maxSize: maxFileSizeMb }),
      ];
      errors.forEach((error) => TWMessage.error(error));
    }

    return !fileTooBig;
  };

  const handleCancel = () => {
    setShowCropModal(false);
  };

  const handleOkAndCloseModal = (canvas: HTMLCanvasElement) => {
    const croppedB64 = canvas.toDataURL(originalImageFile?.type);
    canvas.toBlob((blob) => {
      if (blob && originalImageFile) {
        const croppedFile = new File([blob], originalImageFile.name, {
          lastModified: Date.now(),
          type: originalImageFile.type,
        });

        onChange?.({
          file: croppedFile,
          croppedB64Image: croppedB64,
        });
        setCroppedImageB64(croppedB64);
        setShowCropModal(false);
      }
    });
  };

  const handleFileInputChange = async ({ file }: UploadChangeParam) => {
    if ('originFileObj' in file && file.status === 'done') {
      try {
        setOriginalImageFile(file.originFileObj ?? null);
        setShowCropModal(true);
      } catch (err) {
        TWMessage.error(getTranslation('files.modal.upload.error', { fileName: file.name }));
        datadogRum.addError(err);
      }
    }
  };

  const dummyRequest = (options: UploadRequestOption) => {
    if ('onSuccess' in options && typeof options.onSuccess === 'function') {
      const { onSuccess } = options;
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    }
  };

  return (
    <>
      <Upload
        accept={onlyImages || !messagingFileAttachmentConversation ? 'image/*' : '*'}
        beforeUpload={beforeUpload}
        onChange={handleFileInputChange}
        customRequest={dummyRequest}
        showUploadList={false}
        data-testid={`${testID}Input`}
        aria-label={accessibilityLabel}
        ref={uploadControlRef}
      >
        {asAvatar ? (
          <AvatarContainer>
            <TWAvatar
              size={avatarSize}
              icon={avatarIcon}
              src={croppedImageB64 || avatarSrc}
              style={{
                backgroundColor: theme.colors.grayBackground,
              }}
            />
            <AvatarIconContainer>
              <span className="material-icons">photo_camera</span>
            </AvatarIconContainer>
          </AvatarContainer>
        ) : (
          <Button
            data-testid={testID}
            variant="secondary"
            style={{ display: asAvatar ? 'none' : 'flex' }}
          >
            {label}
          </Button>
        )}
      </Upload>
      <CropModal
        imageFileBase64={originalImageB64 ?? ''}
        handleOk={handleOkAndCloseModal}
        containerId={containerId}
        handleCancel={handleCancel}
        showCropModal={!!originalImageB64 && showCropModal}
        aspectRatio={aspectRatio}
        asAvatar={asAvatar}
      />
    </>
  );
};
