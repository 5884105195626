export enum InfoMessageType {
  INFO,
  WARNING,
}

export type TWInfoMessageTypes = InfoMessageType.INFO | InfoMessageType.WARNING;

export type WithMessageInfoProps = { messageType: TWInfoMessageTypes; fitContent?: boolean };

export interface TWInfoMessageProps {
  type: TWInfoMessageTypes;
  headerText?: string;
  description?: string | JSX.Element;
  testID?: string;
  showCloseIcon?: boolean;
  fitContent?: boolean;
  onClose?: () => void;
}
