import { CSSProperties, DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react';

import { theme } from '@tw/components/utils';

const defaultStyles: CSSProperties = {
  padding: `${theme.baseUnit * 3}px`,
  background: theme.colors.background,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
};

type TWDrawerBodyProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const TWDrawerBody = ({
  children,
  style: externalStyles = {},
  ...allUnrecognizedProps
}: PropsWithChildren<TWDrawerBodyProps>) => {
  const styles: CSSProperties = { ...defaultStyles, ...externalStyles };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div style={styles} {...allUnrecognizedProps}>
      {children}
    </div>
  );
};

export default TWDrawerBody;
