import { Dropdown, MenuProps } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import partial from 'lodash/partial';
import { MouseEventHandler } from 'react';

import { ArrayElement, DOMUtils } from '@tw/util';

type ExtMenuItemProps = {
  'aria-label'?: string;
  'data-testid'?: string;
};

export type ExtItemType = ArrayElement<MenuProps['items']> & ExtMenuItemProps;

interface ExtendedItems extends MenuProps {
  items: ExtItemType[];
}

interface TWDropdownProps extends Partial<DropDownProps> {
  children: JSX.Element;
  containerId?: string;
  disabled?: boolean;
  menu: ExtendedItems;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const TWDropdown = ({
  children,
  containerId,
  disabled = false,
  menu,
  trigger = ['click'],
  onClick,
  ...otherProps
}: TWDropdownProps) => (
  <div onClick={onClick} role="presentation">
    <Dropdown
      disabled={disabled}
      trigger={trigger}
      menu={menu}
      getPopupContainer={partial(DOMUtils.getContainer, containerId)}
      {...otherProps}
    >
      {children}
    </Dropdown>
  </div>
);

export default TWDropdown;
