import fastMemoize from 'fast-memoize';

import TWTextListOverflowPopover from '../../TWTextListOverflowPopover';

const renderStringList = (containerId: string) =>
  fastMemoize((csvString: string) => {
    const listOfStrings = csvString.split(', ');
    return (
      <TWTextListOverflowPopover
        fontSize={16}
        listOfStrings={listOfStrings}
        containerId={containerId}
      />
    );
  });

export default renderStringList;
