/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAllFileFoldersForPersonQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchAllFileFoldersForPersonQuery = {
  folders?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        id: string;
        orgId: number;
        parentFolderId?: number | null;
        folderName?: string | null;
        sortOrder?: number | null;
        folderId?: number | null;
        team?: { id: string; label: string; teamId: string; active: boolean } | null;
        sharedFiles?: Array<{ sharedFileId?: number | null } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchAllFileFoldersForPersonDocument = gql`
  query FetchAllFileFoldersForPerson {
    folders(folderType: "team") {
      totalCount
      edges {
        node {
          id
          orgId
          folderId: pk
          parentFolderId
          folderName
          sortOrder
          team {
            id
            label
            teamId
            active
          }
          sharedFiles {
            sharedFileId
          }
        }
      }
    }
  }
`;

/**
 * __useFetchAllFileFoldersForPersonQuery__
 *
 * To run a query within a React component, call `useFetchAllFileFoldersForPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllFileFoldersForPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllFileFoldersForPersonQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllFileFoldersForPersonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllFileFoldersForPersonQuery,
    FetchAllFileFoldersForPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchAllFileFoldersForPersonQuery,
    FetchAllFileFoldersForPersonQueryVariables
  >(FetchAllFileFoldersForPersonDocument, options);
}
export function useFetchAllFileFoldersForPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllFileFoldersForPersonQuery,
    FetchAllFileFoldersForPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchAllFileFoldersForPersonQuery,
    FetchAllFileFoldersForPersonQueryVariables
  >(FetchAllFileFoldersForPersonDocument, options);
}
export type FetchAllFileFoldersForPersonQueryHookResult = ReturnType<
  typeof useFetchAllFileFoldersForPersonQuery
>;
export type FetchAllFileFoldersForPersonLazyQueryHookResult = ReturnType<
  typeof useFetchAllFileFoldersForPersonLazyQuery
>;
export type FetchAllFileFoldersForPersonQueryResult = Apollo.QueryResult<
  FetchAllFileFoldersForPersonQuery,
  FetchAllFileFoldersForPersonQueryVariables
>;
