import { useHistory } from 'react-router-dom';
import { useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { useSwitchUsersCurrentTeamMutation } from '@tw/generated';
import { useGlobalModal } from '@tw/components';

type SwitchTeamsConfig = { redirectPath?: string } & Parameters<
  typeof useSwitchUsersCurrentTeamMutation
>[0];

type GeneratedReturnType = ReturnType<typeof useSwitchUsersCurrentTeamMutation>;
type SwitchTeamMutationReturnType =
  | ReturnType<GeneratedReturnType[0]>
  | Promise<{ isCanceled?: boolean }>
  | Promise<{}>;
type SwitchTeamsMutation = (
  params: Parameters<GeneratedReturnType[0]>[0],
) => SwitchTeamMutationReturnType;
type HookReturn = [SwitchTeamsMutation, GeneratedReturnType[1]];

const useSwitchTeams = (config?: SwitchTeamsConfig): HookReturn => {
  const { redirectPath, onCompleted, ...options } = config ?? {};
  const { setUser, getTabCount, currentTeam } = useViewer();
  const history = useHistory();
  const { warningConfirmGlobalModal } = useGlobalModal();

  const [mutation, others] = useSwitchUsersCurrentTeamMutation({
    onCompleted: (data) => {
      const {
        switchUsersCurrentTeam: { currentUserData },
      } = data;
      setUser({
        ...currentUserData,
        orgId: currentUserData?.orgId?.toString(),
      });
      if (redirectPath) {
        history.replace(redirectPath);
      } else if (history.location.pathname === '/profiles') {
        // clear all filters for All Profile page - USR-2308
        history.replace(history.location.pathname);
      }
      onCompleted?.(data);
    },
    ...options,
  });

  return [
    async (params: Parameters<typeof mutation>[0]) => {
      const tabCount = getTabCount();
      const isChange = currentTeam?.id !== params?.variables?.team;
      if (
        !isChange ||
        tabCount < 2 ||
        (await warningConfirmGlobalModal({
          title: getTranslation('areYouSure'),
          content: getTranslation('navigation.tabsRefreshConfirmation'),
          okText: getTranslation('ok'),
          cancelText: getTranslation('cancel'),
        }))
      ) {
        const result = await mutation(params);
        return result;
      }
      return {
        // Canceled by confirm alert
        isCanceled: true,
      };
    },
    others,
  ];
};

export { useSwitchTeams };
