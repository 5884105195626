import { useTheme } from '@emotion/react';
import _ from 'lodash';
import { CSSProperties, forwardRef } from 'react';
import * as React from 'react';
import { TWIcon } from '../../../components.web';
import { SizeScalingType } from '../../../utils';
import { defaultListItemSizeScaling, IconInterface } from '../utils';
import { Container, ContentRow, IconRow } from './TWListItemIcon.styles';

interface TWListItemIconProps {
  testID: string;
  label: string;
  leftIconType: string;
  leftIconsStyle?: CSSProperties;
  size?: SizeScalingType;
  rightIcons?: IconInterface[];
  onClick?: () => void;
  isHighlighted: boolean;
  isSelected?: boolean;
}

const TWListItemIcon: React.FC<TWListItemIconProps> = forwardRef<
  HTMLButtonElement,
  TWListItemIconProps
>(
  (
    {
      leftIconType,
      size,
      testID,
      onClick,
      isSelected,
      isHighlighted,
      rightIcons,
      label,
      leftIconsStyle,
    },
    ref,
  ) => {
    const { padding, TextComponent, rightIconSize, leftIconSize } =
      defaultListItemSizeScaling[size];

    const theme = useTheme();

    return (
      <Container
        isHighlighted={isHighlighted}
        twPadding={padding}
        role="button"
        data-testid={testID}
        onClick={onClick}
        ref={ref}
      >
        <ContentRow>
          <TWIcon
            color={theme.colors.buttonText}
            type={leftIconType}
            width={leftIconSize}
            height={leftIconSize}
            style={leftIconsStyle}
          />
          <TextComponent
            twColor="buttonText"
            twFontWeight={isSelected ? 'bold' : 'medium'}
            truncate
          >
            {label}
          </TextComponent>
        </ContentRow>
        <IconRow>
          {_.map(rightIcons, ({ type, iconOnClick }: IconInterface) => (
            <TWIcon
              type={type}
              onClick={iconOnClick}
              width={rightIconSize}
              height={rightIconSize}
            />
          ))}
        </IconRow>
      </Container>
    );
  },
);

TWListItemIcon.defaultProps = {
  leftIconType: '',
  rightIcons: [],
  isHighlighted: false,
  size: 'default',
  isSelected: false,
};

export default TWListItemIcon;
