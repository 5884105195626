import { DrawerProps } from 'antd';

import { TWIcon } from '@tw/components';
import { noopFn } from '@tw/constants';
import { DOMUtils } from '@tw/util';

import { bodyStyle, TWDrawerContainer } from './TWDrawer.styles';

interface TWDrawerProps extends DrawerProps {
  containerId: string;
  children: React.ReactNode;
  getContainerOverride?: string;
  testID?: string;
}

const TWDrawer = (props: TWDrawerProps) => {
  const {
    children,
    closable = true,
    containerId,
    onClose = noopFn,
    testID = null,
    title = '',
    open = false,
    width = 350,
    getContainerOverride,
    extra,
    ...allUnrecognizedProps
  } = props;

  return (
    <TWDrawerContainer
      closable={closable}
      closeIcon={
        <TWIcon height={24} width={24} type="material-close" style={{ verticalAlign: 'middle' }} />
      }
      destroyOnClose
      placement="right"
      title={title}
      onClose={onClose}
      open={open}
      // "GetContainerOverride" is a testing override function
      getContainer={getContainerOverride ?? DOMUtils.getContainer(containerId)}
      width={width}
      extra={extra}
      bodyStyle={bodyStyle}
      {...allUnrecognizedProps}
    >
      <div data-testid={testID} style={{ flexGrow: 1 }}>
        {children}
      </div>
    </TWDrawerContainer>
  );
};

export default TWDrawer;
