/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateTicketingEventMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.TicketingEventBulkInput>;
}>;

export type BulkUpdateTicketingEventMutation = {
  ticketingEventBulkUpdate: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const BulkUpdateTicketingEventDocument = gql`
  mutation BulkUpdateTicketingEvent($input: TicketingEventBulkInput) {
    ticketingEventBulkUpdate(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type BulkUpdateTicketingEventMutationFn = Apollo.MutationFunction<
  BulkUpdateTicketingEventMutation,
  BulkUpdateTicketingEventMutationVariables
>;

/**
 * __useBulkUpdateTicketingEventMutation__
 *
 * To run a mutation, you first call `useBulkUpdateTicketingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateTicketingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateTicketingEventMutation, { data, loading, error }] = useBulkUpdateTicketingEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateTicketingEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateTicketingEventMutation,
    BulkUpdateTicketingEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateTicketingEventMutation,
    BulkUpdateTicketingEventMutationVariables
  >(BulkUpdateTicketingEventDocument, options);
}
export type BulkUpdateTicketingEventMutationHookResult = ReturnType<
  typeof useBulkUpdateTicketingEventMutation
>;
export type BulkUpdateTicketingEventMutationResult =
  Apollo.MutationResult<BulkUpdateTicketingEventMutation>;
export type BulkUpdateTicketingEventMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateTicketingEventMutation,
  BulkUpdateTicketingEventMutationVariables
>;
