import { useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { AdvFetchPeopleQueryVariables, usePeopleSelectionCodesQuery } from '@tw/generated';
import { TWMessage } from '@tw/components';

interface PeopleSelectionsReturnType {
  loading: boolean;
  selections: string[];
  error: ApolloError | undefined;
}

function usePeopleSelections({
  context,
  queryVariables,
  skip,
}: {
  context?: { headers: { 'X-TW-TeamId': string } };
  queryVariables: Partial<AdvFetchPeopleQueryVariables>;
  skip: boolean;
}): PeopleSelectionsReturnType {
  const [selectionCodes, setSelectedCodes] = useState<string[]>([]);

  const {
    data,
    loading: selectionCodesLoading,
    error: selectionCodesError,
  } = usePeopleSelectionCodesQuery({
    context,
    variables: { ...queryVariables, first: null, after: null },
    fetchPolicy: 'cache-first',
    skip,
    onError: (error) => TWMessage.errorHandler(error),
  });

  useEffect(() => {
    const normalizeSelectionCodes =
      data?.people?.edges.reduce((acc: string[], edge) => {
        if (edge?.person?.selectionCode) {
          acc.push(edge.person.selectionCode);
        }
        return acc;
      }, []) || [];

    if (normalizeSelectionCodes?.length > 0) {
      setSelectedCodes(normalizeSelectionCodes);
    }
  }, [data]);

  return { loading: selectionCodesLoading, selections: selectionCodes, error: selectionCodesError };
}

export default usePeopleSelections;
