import { Dispatch, SetStateAction } from 'react';

export const cacheSearchValues = (
  search: string,
  searchValues: { value: string; label: string }[],
  setSearches: Dispatch<
    SetStateAction<
      {
        label: string;
        value: string;
      }[]
    >
  >,
) => {
  if (searchValues.length > 6) searchValues.pop();
  if (search && search.trim()) {
    if (searchValues.find((pastSearch) => pastSearch.value === search)) return;
    setSearches([{ label: search, value: search }, ...searchValues]);
  }
};

export const clearCachedSearches = (
  setSearches: Dispatch<
    SetStateAction<
      {
        label: string;
        value: string;
      }[]
    >
  >,
) => {
  setSearches([]);
};

export default {
  clearCachedSearches,
  cacheSearchValues,
};
