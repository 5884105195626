/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketingEventPersonCopyMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  input?: Types.InputMaybe<Types.TicketingEventPersonCopyInput>;
}>;

export type TicketingEventPersonCopyMutation = {
  ticketingEventPersonCopy: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const TicketingEventPersonCopyDocument = gql`
  mutation TicketingEventPersonCopy($id: ID, $input: TicketingEventPersonCopyInput) {
    ticketingEventPersonCopy(id: $id, input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type TicketingEventPersonCopyMutationFn = Apollo.MutationFunction<
  TicketingEventPersonCopyMutation,
  TicketingEventPersonCopyMutationVariables
>;

/**
 * __useTicketingEventPersonCopyMutation__
 *
 * To run a mutation, you first call `useTicketingEventPersonCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingEventPersonCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingEventPersonCopyMutation, { data, loading, error }] = useTicketingEventPersonCopyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketingEventPersonCopyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TicketingEventPersonCopyMutation,
    TicketingEventPersonCopyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TicketingEventPersonCopyMutation,
    TicketingEventPersonCopyMutationVariables
  >(TicketingEventPersonCopyDocument, options);
}
export type TicketingEventPersonCopyMutationHookResult = ReturnType<
  typeof useTicketingEventPersonCopyMutation
>;
export type TicketingEventPersonCopyMutationResult =
  Apollo.MutationResult<TicketingEventPersonCopyMutation>;
export type TicketingEventPersonCopyMutationOptions = Apollo.BaseMutationOptions<
  TicketingEventPersonCopyMutation,
  TicketingEventPersonCopyMutationVariables
>;
