export interface Team {
  id: string;
  pk: string;
  teamId: string;
  orgId: number;
  label: string;
  shortLabel?: string;
}

export enum TeamSortEnum {
  /* eslint-disable camelcase */
  sport_school_id_asc = 'sport_school_id_asc',
  sport_school_id_desc = 'sport_school_id_desc',
  label_asc = 'label_asc',
  label_desc = 'label_desc',
  short_label_asc = 'short_label_asc',
  short_label_desc = 'short_label_desc',
  created_date_asc = 'created_date_asc',
  created_date_desc = 'created_date_desc',
  updated_date_asc = 'updated_date_asc',
  updated_date_desc = 'updated_date_desc',
  /* eslint-enable camelcase */
}

export interface TeamRawQueryVariables {
  teamId?: string;
  sort?: TeamSortEnum[];
}

export interface TeamQueryVariables {
  teamId?: string[];
  sort?: TeamSortEnum[];
}
