import styled from '@emotion/styled';
import { SortableTrProps } from './SortableDragTableRow.definitions';

export const StyledTr = styled.tr<SortableTrProps>(({ theme, dragPadding = 5 }) => ({
  cursor: 'default',

  '& > td.ant-table-cell': {
    transition: `background,padding 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000)` /* easeOutQuint */,
  },

  '&.dragging': {
    opacity: 0.33,

    '&.hover-on': {
      opacity: 1,

      '& > td': {
        borderTop: `1px dashed ${theme.colors.border}`,
        borderBottom: `1px dashed ${theme.colors.border}`,

        paddingTop: `${theme.baseUnit * 1.25}px !important`,
        paddingBottom: `${theme.baseUnit * 1.25}px !important`,
      },
    },
  },

  '&.hover-downward > td': {
    paddingBottom: `${theme.baseUnit * dragPadding}px !important`,
    borderBottom: `2px dashed ${theme.colors.link}`,
  },

  '&.hover-upward > td': {
    borderTop: `2px dashed ${theme.colors.link}`,
    paddingTop: `${theme.baseUnit * dragPadding}px !important`,
  },
}));
