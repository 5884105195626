/**
 * Keep track of active tabs. Using the BroadcastChannel API and LocalStorage. When added to stage, this hook will take roll call for the other tabs, recording the count in the process.
 * The proctor takes attendance by sending a message out and the students respond with a message back to the proctor who records attendance.
 * This keeps the tab count in sync between reloads, unloads, and duplicated tabs.
 */
import { useCallback, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';

const TAB_ID = nanoid();
const STORAGE_KEY = 'tab-count';

type TabAttendance = {
  // Proctor tab (who's taking roll/attendance)
  initiator: string;
  // Student tab who responds
  responder: string;
  // Tab count during communication
  count: number;
};

const getData = () => {
  const data = localStorage.getItem(STORAGE_KEY);
  return data ? (JSON.parse(data) as TabAttendance) : undefined;
};

const getCount = () => {
  const data = getData();
  return Math.max(data?.count ?? 1, 1);
};

export const useActiveTabs = () => {
  // Proctor Channel
  const pChannel = useRef(new BroadcastChannel(`tw-${STORAGE_KEY}-proctor`));
  // Student Channel
  const sChannel = useRef(new BroadcastChannel(`tw-${STORAGE_KEY}-student`));

  const takeAttendance = useCallback(() => {
    const proctor: TabAttendance = {
      initiator: TAB_ID,
      responder: TAB_ID,
      count: 1,
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(proctor));
    // Teacher/Proctor calls roll
    pChannel.current?.postMessage(proctor);
  }, []);

  useEffect(() => {
    let mounted = true;
    const proctorChannel = pChannel.current;
    const studentChannel = sChannel.current;

    const onRollCall = (e: MessageEvent<TabAttendance>) => {
      const {
        data: { initiator, count },
      } = e;

      if (initiator !== TAB_ID) {
        // only non-initiators should respond (students)
        const student: TabAttendance = {
          initiator,
          responder: TAB_ID,
          count,
        };
        // student responds 'Here!'
        sChannel.current?.postMessage(student);
      }
    };

    const onStudentPresent = (e: MessageEvent<TabAttendance>) => {
      const {
        data: { initiator },
      } = e;
      const { count: dataCount = 0 } = getData() ?? {};

      if (initiator === TAB_ID) {
        // this tab is the proctor, record the incoming student message
        const proctorLogOfStudent: TabAttendance = {
          initiator,
          responder: TAB_ID,
          // increment
          count: dataCount + 1,
        };
        localStorage.setItem(STORAGE_KEY, JSON.stringify(proctorLogOfStudent));
      }
    };

    const onUnload = () => {
      if (mounted) {
        mounted = false;
        proctorChannel.removeEventListener('message', onRollCall);
        studentChannel.removeEventListener('message', onStudentPresent);
        window.removeEventListener('beforeunload', onUnload);

        const { count: dataCount = 1 } = getData() ?? {};
        // decrement
        const newCount = Math.max(dataCount - 1, 0);
        const proctor: TabAttendance = {
          initiator: TAB_ID,
          responder: TAB_ID,
          count: newCount,
        };
        // Record one less tab
        localStorage.setItem(STORAGE_KEY, JSON.stringify(proctor));
      }
    };

    proctorChannel.addEventListener('message', onRollCall);
    studentChannel.addEventListener('message', onStudentPresent);
    window.addEventListener('beforeunload', onUnload);
    takeAttendance();

    return onUnload;
  }, [takeAttendance]);

  return getCount;
};
