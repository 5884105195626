// Note that $twInput uses several of its own colors, instead of $colors, because we
// *don't* invert it when the darkDefaultTheme is enabled.

const $twInput = {
  backgroundColor: '$colorHue.grayVeryDark',
  borderColor: '$colorHue.transparent',
  iconColor: '$twInput.labelColor',
  labelColor: '$colorHue.grayBlue',
  placeholderColor: '$colorHue.grayExtremelyLight',
  valueColor: '$colorHue.grayExtremelyLight',
};

const $twInputInverse = {
  ...$twInput,
  backgroundColor: '$colorHue.grayExtremelyLight',
  borderColor: '$colorHue.grayMid',
  valueColor: '$colorHue.grayDark',
};

export { $twInput, $twInputInverse };
