import { AlertProps } from 'antd/lib/alert';

import { ThemeMarginProps } from '@tw/components/utils';

import { TWSpacingContainer } from '../presentational/layout';
import { StyledAlert } from './TWAlertDeprecated.styles';

export type TWAlertDeprecatedProps = AlertProps & ThemeMarginProps;

/*

  TWAlert has been deprecated for error handling.
  Use antd's form validation for validation errors (see AddEditForm.tsx for examples of custom validation)
  Use TWMessage.errorHandler for all other errors.
  See https://teamworks.atlassian.net/wiki/spaces/FEG/pages/793542727/Current+Coding+Patterns for details.

*/

const TWAlertDeprecated = ({
  twMargin,
  twMarginTop,
  twMarginRight,
  twMarginBottom,
  twMarginLeft,
  ...alertProps
}: TWAlertDeprecatedProps) => (
  <TWSpacingContainer
    twMargin={twMargin}
    twMarginTop={twMarginTop}
    twMarginRight={twMarginRight}
    twMarginBottom={twMarginBottom}
    twMarginLeft={twMarginLeft}
  >
    <StyledAlert {...alertProps} />
  </TWSpacingContainer>
);

export default TWAlertDeprecated;
