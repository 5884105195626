/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalendarEventsQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
  scheduleView?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type CalendarEventsQuery = {
  allAppointments?: {
    edges: Array<{
      node?: {
        appointmentId: string;
        countableEndHour?: number | null;
        countableEndMinute?: number | null;
        countableMinutes?: number | null;
        countableStartHour?: number | null;
        countableStartMinute?: number | null;
        enableRsvps?: boolean | null;
        endTimeZone?: string | null;
        isClass?: boolean | null;
        isBookedSlot?: string | null;
        isExternal?: boolean | null;
        isPrivate?: boolean | null;
        isRecurring?: boolean | null;
        location?: string;
        meta?: any | null;
        orgId: number;
        rrule?: string | null;
        startTimeZone?: string | null;
        title?: string | null;
        hasAttachments?: boolean | null;
        allDay: boolean;
        firstOccurrenceEndDateTime?: any | null;
        firstOccurrenceStartDateTime?: any | null;
        appointmentType?: {
          appointmentTypeId?: number | null;
          color: string;
          label: string;
        } | null;
        attachments?: Array<{
          downloadUrl?: string | null;
          fileName: string;
          fileSize?: number | null;
        } | null> | null;
        attendees?: Array<
          | { label?: string | null; peopleCount?: number | null; selectionCode?: string | null }
          | { label?: string | null; peopleCount?: number | null; selectionCode?: string | null }
          | {
              label?: string | null;
              peopleCount?: number | null;
              selectionCode?: string | null;
              people?: Array<{ selectionCode?: string | null } | null> | null;
            }
          | { label?: string | null; peopleCount?: number | null; selectionCode?: string | null }
          | null
        > | null;
        occurrences?: Array<{
          appointmentId?: number | null;
          appointmentOccurrenceId?: number | null;
          endDateTime?: any | null;
          rsvpStatus?: Types.AppointmentRsvpStatusEnum | null;
          startDateTime?: any | null;
          appointmentCheckinOccurrence?: { appointmentCheckinOccurrenceId?: any | null } | null;
        } | null> | null;
        userMetadata?: {
          canDelete?: boolean | null;
          canEdit?: boolean | null;
          canViewDetails?: boolean | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export const CalendarEventsDocument = gql`
  query CalendarEvents(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $selections: [String]!
    $scheduleView: Boolean = false
  ) {
    allAppointments(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      selections: $selections
    ) {
      edges {
        node {
          allDay: isAllDay
          appointmentId
          countableEndHour
          countableEndMinute
          countableMinutes
          countableStartHour
          countableStartMinute
          enableRsvps
          endTimeZone
          firstOccurrenceEndDateTime: endDate
          firstOccurrenceStartDateTime: startDate
          isClass
          isBookedSlot
          isExternal
          isPrivate
          isRecurring
          location @include(if: $scheduleView)
          meta
          orgId
          rrule
          startTimeZone
          title
          hasAttachments
          appointmentType {
            appointmentTypeId
            color
            label
          }
          attachments {
            downloadUrl
            fileName
            fileSize
          }
          attendees @include(if: $scheduleView) {
            label
            peopleCount
            selectionCode
            ... on SelectionGroup {
              people {
                selectionCode
              }
            }
          }
          occurrences {
            appointmentCheckinOccurrence {
              appointmentCheckinOccurrenceId
            }
            appointmentId
            appointmentOccurrenceId
            endDateTime
            rsvpStatus
            startDateTime
          }
          userMetadata {
            canDelete
            canEdit
            canViewDetails
          }
        }
      }
    }
  }
`;

/**
 * __useCalendarEventsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      selections: // value for 'selections'
 *      scheduleView: // value for 'scheduleView'
 *   },
 * });
 */
export function useCalendarEventsQuery(
  baseOptions: Apollo.QueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(
    CalendarEventsDocument,
    options,
  );
}
export function useCalendarEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(
    CalendarEventsDocument,
    options,
  );
}
export type CalendarEventsQueryHookResult = ReturnType<typeof useCalendarEventsQuery>;
export type CalendarEventsLazyQueryHookResult = ReturnType<typeof useCalendarEventsLazyQuery>;
export type CalendarEventsQueryResult = Apollo.QueryResult<
  CalendarEventsQuery,
  CalendarEventsQueryVariables
>;
