/**
 * Stylis Plugin to add a scope to all rules
 *
 * stylis-plugin-extra-scope is not compatible with stylis v4
 * and emotion uses stylis v4. so we had to create our own plugin
 *
 * @param {(string|string[])} scope - prefix or list of prefixes to add to each rule
 *
 * */

export default function createExtraScopePlugin(scopes: string | string[]) {
  const internalScopes = Array.isArray(scopes)
    ? scopes.map((scope) => `${scope.trim()} `)
    : [`${scopes.trim()} `];

  return (element) => {
    if (element.type !== 'rule') {
      return;
    }

    if (element.root?.type === '@keyframes') {
      return;
    }

    /* 

  The if condition below handles a breaking stylis 3 and stylis 4.

  Stylis v3 behavior was to interpret this:

  ```scss
  .scope {
    :hover {
      color: hotpink;
    }
  }
  ```
  as
  ```css
  .scope:hover {
    color: hotpink;
  }
  ```
  but this was not "correct" (according to the semantics coined by SCSS & co) because it should be:
  
  ```css
  .scope :hover {
    color: hotpink;
  }
  ```

  The `compat` plugin was introduced to solve this problem. The next line is 
  a workaround for the `compat` plugin. Without this logic, the extra scope 
  would be applied to nested selectors and pseudo-selectors before being joined 
  to parent selectors by `compat`, resulting in the "joined" selector including 
  repeated references to the extra scope.
*/
    if (
      !element.parent ||
      (element.props.length === 1 && element.value.charCodeAt(0) !== 58) ||
      !element.length
    ) {
      element.props = element.props
        .map((prop) => internalScopes.map((scope) => scope + prop))
        .reduce((scopesArray, scope) => scopesArray.concat(scope), []);
    }
  };
}
