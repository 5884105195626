import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { StreamClientProvider, ViewerProvider } from '@tw/hooks';
import { Authenticated, SessionTimeoutManager } from '@tw/modules/Authentication';
import AppProviders from './AppProviders';
import AuthenticatedApp from './AuthenticatedApp';
import { RootStylesContainer } from './Hub.styles';

const Hub = () => (
  <AppProviders>
    <RootStylesContainer className="root-styles-container">
      <Switch>
        {publicRoutes()}
        <Route path="/">
          <Authenticated>
            <ViewerProvider>
              <StreamClientProvider>
                <AuthenticatedApp />
                <SessionTimeoutManager />
              </StreamClientProvider>
            </ViewerProvider>
          </Authenticated>
        </Route>
      </Switch>
    </RootStylesContainer>
  </AppProviders>
);

const AxleTokenExchange = lazy(() => import('@tw/modules/Authentication/AxleTokenExchange'));
const LoginCallback = lazy(() => import('@tw/modules/Authentication/LoginCallback'));
const ExternalAccess = lazy(() => import('@tw/modules/Authentication/ExternalAccess'));
const ExternalFormCallback = lazy(() => import('@tw/modules/Authentication/ExternalFormCallback'));
const ForgotPassword = lazy(() => import('@tw/modules/Authentication/ForgotPassword'));
const ForgotUsername = lazy(() => import('@tw/modules/Authentication/ForgotUsername'));

function publicRoutes() {
  return [
    <Route key="callback" path="/auth/callback" component={LoginCallback} />,
    <Route key="form-callback" exact path="/ext2/formcallback" component={ExternalFormCallback} />,
    <Route key="ext2" path="/ext2" component={ExternalAccess} />,
    <Route key="ext" path="/ext" component={ExternalAccess} />,
    <Route key="password" path="/forgotPassword" component={ForgotPassword} />,
    <Route key="username" path="/forgotUsername" component={ForgotUsername} />,
    <Route key="axle" path="/switch" component={AxleTokenExchange} />,
  ];
}

export default Hub;
