import { useState } from 'react';
import * as React from 'react';
import { isApolloError } from '@apollo/client';

import { useSwitchTeams, useTranslator, useViewer } from '@tw/hooks';
import { relayUtils, urlUtils } from '@tw/util';

import { TWModal } from '../../TWModal';
import TWListItemAvatar from '../../../presentational/listItems/TWListItemAvatar';
import { TWMessage } from '../../TWMessage';

interface TWDrawerDetailProfileProps {
  containerId: string;
  teamId: number;
  globalPersonId: string;
  fullName: string;
  initials: string;
  picture: string;
  subtext: string;
  rightNode?: React.ReactNode;
  disableRedirect?: boolean;
  testID?: string;
}

const TWDrawerDetailProfile: React.FC<TWDrawerDetailProfileProps> = ({
  containerId,
  globalPersonId,
  teamId,
  picture,
  initials,
  fullName,
  subtext,
  rightNode,
  disableRedirect = false,
  testID,
}) => {
  const { teamId: currentUserTeamId, personId: currentUserPersonId } = useViewer();
  const translator = useTranslator();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const profileUrl = urlUtils.getProfileUrl(globalPersonId);

  const [switchTeams] = useSwitchTeams({
    redirectPath: profileUrl,
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      switchTeams({ variables: { team: relayUtils.toGlobalId('TeamNode', String(teamId)) } });
      setIsModalOpen(false);
    } catch (error) {
      if (isApolloError(error)) {
        TWMessage.handleErrorWithContext({
          error,
          context: {
            currentUserPersonId,
            currentUserTeamId,
            targetPersonId: globalPersonId,
            targetTeamId: teamId,
          },
        });
      } else {
        TWMessage.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleClick = (e: React.SyntheticEvent<Element, Event>) => {
    if (!disableRedirect) {
      if (currentUserTeamId !== teamId) {
        setIsModalOpen(true);
        e.preventDefault();
      } else {
        window.open(profileUrl, '_blank');
      }
    }
  };

  return (
    <>
      <TWModal
        containerId={containerId}
        title={translator.t('confirmProfileSwitchTeamsTitle')}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleClose}
        okButtonProps={{ loading }}
      >
        {translator.t('confirmProfileSwitchTeams')}
      </TWModal>
      <TWListItemAvatar
        onClick={handleClick}
        label={fullName}
        avatarImage={picture}
        avatarText={initials}
        subtext={subtext}
        rightNode={rightNode}
        testID={testID}
      />
    </>
  );
};

export default TWDrawerDetailProfile;
