import TWInputSelect from '../TWInputSelect';
import { Creator, TWSelectCreatorsProps } from './TWInputSelectQuickFormCreators.definitions';

const TWInputSelectQuickFormCreators = ({
  popupContainerId,
  creatorList,
  loading,
  testID,
  disabled,
  placeholder,
  defaultValue,
  onChange,
}: TWSelectCreatorsProps) => {
  const keyExtractor = ({ personId }: Creator) => Number(personId);
  const labelExtractor = ({ displayName }: Creator) => displayName;

  return (
    <TWInputSelect
      allowClear
      filterOption
      disabled={disabled}
      optionFilterProp="title"
      placeholder={placeholder}
      keyExtractor={keyExtractor}
      labelExtractor={labelExtractor}
      valueExtractor={keyExtractor}
      itemList={creatorList}
      loading={loading}
      onChange={onChange}
      popupContainerId={popupContainerId}
      minWidth="200px"
      defaultValue={defaultValue}
      testID={testID}
      id={testID}
    />
  );
};

export default TWInputSelectQuickFormCreators;
