import { useState } from 'react';
import _ from 'lodash';

import { TWSpacingContainer } from '@tw/components';
import { noopFn } from '@tw/constants';
import { useConfigContainer, useTutors, useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { Heading } from '@tw/modules/Profiles/ProfilePage/ProfilePage.styles';
import { tutorOperations } from '@tw/services/academicService';
import { statusAlert } from '@tw/util';

import TWDrawerDetailItem from '../../components.web/drawers/TWDrawerDetailItem';
import TWLoadingMask from '../../presentational/TWLoadingMask';

// These should be changed to match the ones above once the components/containers directories have been consolidated
import { TWEditableCourseList } from '../TWEditableCourseList';
import TWEditableInputNumber from '../TWEditableInputNumber';
import { courseSelectStyle, iconAdjustStyle } from './TWTutorDetail.style';

interface TWTutorDetailProps {
  canEdit?: boolean;
  containerId: string;
  onUpdate?: () => void;
  tutorId?: number;
  tutorPersonId?: number | number[];
}

export const TWTutorDetail = ({
  canEdit = false,
  containerId,
  onUpdate = noopFn,
  tutorId,
  tutorPersonId,
}: TWTutorDetailProps) => {
  const configContainer = useConfigContainer();
  const { personId: viewerPersonId, teamId } = useViewer();

  const { loading, data, refetch } = useTutors(
    { personId: tutorPersonId, tutorId: _.castArray(tutorId) },
    { skip: !(tutorId || tutorPersonId) },
  );
  const { tutors } = data || {};
  const tutor = _.head(tutors);
  const safeTutorId = _.get(tutor, 'tutorId', tutorId); // if we came in using tutorPersonId, we need tutorId from the tutor object

  const [isUpdating, setIsUpdating] = useState(true);

  function updateTutor(updatedValues) {
    setIsUpdating(true);

    const valuesToUpdate = {
      ...updatedValues,
    };

    if (_.has(updatedValues, 'courseIds')) {
      valuesToUpdate.courseIds = _.map(updatedValues.courseIds, (selection) =>
        Number(_.replace(selection, 'course-', '')),
      );
    }

    tutorOperations
      .updateTutor(viewerPersonId, teamId, safeTutorId, valuesToUpdate, configContainer)
      .then(() => {
        setIsUpdating(false);
        refetch();
        onUpdate();
      })
      .catch(() => {
        setIsUpdating(false);
        statusAlert.error(
          containerId,
          'Unable to update the tutor. Please try again or contact your Teamworks administrator',
        );
      });
  }

  function handleCourseChange(courseIds) {
    updateTutor({ courseIds });
  }

  function handleHoursChange(maximumNumberOfHours) {
    updateTutor({ maximumNumberOfHours });
  }

  const iconPropsForEditableItems = { style: iconAdjustStyle };

  return (
    <>
      {isUpdating || (loading && <TWLoadingMask />)}
      <Heading>{getTranslation('tutors', 2)}</Heading>

      <TWSpacingContainer twMarginTop={1}>
        <TWDrawerDetailItem
          iconTitle="material-import_contacts"
          iconProps={iconPropsForEditableItems}
          testID="MyProfile:Tutoring:Courses"
          text={
            tutor && (
              <TWEditableCourseList
                canEdit={canEdit}
                containerId={containerId}
                courseList={tutor.courses}
                onChange={handleCourseChange}
                style={courseSelectStyle}
                width="100%"
                filters={{ includeGeneral: true }}
              />
            )
          }
        />
        <TWDrawerDetailItem
          iconTitle="tw-time"
          iconProps={iconPropsForEditableItems}
          align="middle"
          testID="MyProfile:Tutoring:Hours"
          text={
            tutor && (
              <TWEditableInputNumber
                canEdit={canEdit}
                containerId={containerId}
                onChange={handleHoursChange}
                value={tutor.maximumNumberOfHours}
              />
            )
          }
        />
      </TWSpacingContainer>
    </>
  );
};
