import * as React from 'react';

import { useTranslator } from '@tw/hooks';
import { stringUtils } from '@tw/util';

import TWDrawerDetailItem from '../TWDrawerDetailItem';
import TWLink from '../../TWLink';

import {
  ContactContainer,
  ContactItem,
  ContactLabel,
  ContactValue,
} from './TWDrawerContactInfo.styles';

interface TWDrawerContactInfoProps {
  orgPersonItem: {
    emailAddress: string;
    phoneCell?: string;
    phoneHome?: string;
    phoneOffice?: string;
  };
  testID?: string;
}

const renderContactItem = (label: string, value: React.ReactNode) => (
  <ContactItem align="middle">
    <ContactLabel>{label}</ContactLabel>
    <ContactValue>{value}</ContactValue>
  </ContactItem>
);

export const TWDrawerContactInfo = (props: TWDrawerContactInfoProps) => {
  const {
    orgPersonItem: { emailAddress, phoneCell, phoneHome, phoneOffice },
    testID,
  } = props;

  const translator = useTranslator();

  return (
    <ContactContainer>
      {phoneCell && (
        <TWDrawerDetailItem
          testID={`${testID}:Mobile`}
          iconTitle="material-call"
          text={renderContactItem(
            `${translator.t('mobile')}:`,
            <TWLink
              href={phoneCell}
              type="phone"
              text={stringUtils.formatPhoneNumber(phoneCell)}
            />,
          )}
        />
      )}
      {phoneHome && (
        <TWDrawerDetailItem
          testID={`${testID}:HomePhone`}
          iconTitle="material-call"
          text={renderContactItem(
            `${translator.t('home')}:`,
            <TWLink
              href={phoneHome}
              type="phone"
              text={stringUtils.formatPhoneNumber(phoneHome)}
            />,
          )}
        />
      )}
      {phoneOffice && (
        <TWDrawerDetailItem
          testID={`${testID}:OfficePhone`}
          iconTitle="material-call"
          text={renderContactItem(
            `${translator.t('office')}:`,
            <TWLink
              href={phoneOffice}
              type="phone"
              text={stringUtils.formatPhoneNumber(phoneOffice)}
            />,
          )}
        />
      )}
      <TWDrawerDetailItem
        testID={`${testID}:Email`}
        iconTitle="material-mail"
        text={renderContactItem(
          `${translator.t('email')}:`,
          <TWLink href={emailAddress} type="email" />,
        )}
      />
    </ContactContainer>
  );
};
