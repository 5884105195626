/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OverlayEventsForConflictsQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type OverlayEventsForConflictsQuery = {
  fetchOverlayEvents?: {
    events: Array<{
      title: string;
      startDatetime: any;
      endDatetime: any;
      timezone: string;
      location?: string | null;
      isPrivate?: boolean | null;
      allDay?: boolean | null;
      overlayEventId: string;
      appointmentType: { id: string };
      attendees: { hubSelectionCodes: Array<string | null> };
    } | null>;
  } | null;
};

export const OverlayEventsForConflictsDocument = gql`
  query OverlayEventsForConflicts(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $selections: [String]!
  ) {
    fetchOverlayEvents(
      startDate: $startDateTime
      endDate: $endDateTime
      selectionCodes: $selections
    ) {
      events {
        title
        startDatetime
        endDatetime
        timezone
        location
        isPrivate
        allDay
        overlayEventId
        appointmentType {
          id
        }
        attendees {
          hubSelectionCodes
        }
      }
    }
  }
`;

/**
 * __useOverlayEventsForConflictsQuery__
 *
 * To run a query within a React component, call `useOverlayEventsForConflictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverlayEventsForConflictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverlayEventsForConflictsQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useOverlayEventsForConflictsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OverlayEventsForConflictsQuery,
    OverlayEventsForConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OverlayEventsForConflictsQuery, OverlayEventsForConflictsQueryVariables>(
    OverlayEventsForConflictsDocument,
    options,
  );
}
export function useOverlayEventsForConflictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OverlayEventsForConflictsQuery,
    OverlayEventsForConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OverlayEventsForConflictsQuery,
    OverlayEventsForConflictsQueryVariables
  >(OverlayEventsForConflictsDocument, options);
}
export type OverlayEventsForConflictsQueryHookResult = ReturnType<
  typeof useOverlayEventsForConflictsQuery
>;
export type OverlayEventsForConflictsLazyQueryHookResult = ReturnType<
  typeof useOverlayEventsForConflictsLazyQuery
>;
export type OverlayEventsForConflictsQueryResult = Apollo.QueryResult<
  OverlayEventsForConflictsQuery,
  OverlayEventsForConflictsQueryVariables
>;
