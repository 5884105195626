import _ from 'lodash';

import { dayjs, graphqlUtils } from '@tw/util';
import {
  Session,
  GraphQLPaginatedResponse,
  PaginatedSessionData,
  RawSession,
  SessionFilters,
  SessionRawQueryVariables,
  SessionReportRawSubmitValues,
  SessionReportSubmitValues,
  SessionAttendanceRawSubmitValue,
} from '@tw/types';

import tutorModel from '../tutor/tutorModel';
import { transformRawSessionAttendancePaginatedData } from '../tutorSessionAttendance/sessionAttendanceModel';

const normalizeSessionVariables = (variables: SessionFilters): SessionRawQueryVariables => {
  const { isDeleted, occurrenceStartDateTime, reportStatus, termId, dateRange, ...rest } =
    variables;

  let dateTimeFilter;
  if (!_.isEmpty(dateRange)) {
    dateTimeFilter = graphqlUtils.createRangeQuery(dateRange[0], dateRange[1]);
  } else if (occurrenceStartDateTime) {
    const safeOccStartDateTime = dayjs.isDayjs(occurrenceStartDateTime)
      ? dayjs(occurrenceStartDateTime.format())
      : occurrenceStartDateTime;
    dateTimeFilter = graphqlUtils.getBeforeNowQuery(safeOccStartDateTime);
  }

  return {
    ...rest,
    isDeleted: graphqlUtils.createBooleanQuery(isDeleted),
    occurrenceStartDateTime: dateTimeFilter,
    reportStatus: Array.isArray(reportStatus)
      ? graphqlUtils.createArrayIncludesQuery(reportStatus)
      : reportStatus ?? undefined,
    termId: termId ? _.head(termId) : undefined,
  };
};

const transformRawSessionData = (rawSession: RawSession): Session => ({
  ...rawSession,
  isCanceled: !_.isNil(rawSession.isCanceled) ? rawSession.isCanceled === 'true' : undefined,
  reportAnswers: rawSession.reportAnswers ? JSON.parse(rawSession.reportAnswers) : undefined,
  sessionAttendances: transformRawSessionAttendancePaginatedData(rawSession.sessionAttendances),
  students: rawSession.students ? graphqlUtils.convertEdgesToArray(rawSession.students.edges) : [],
  tutor: tutorModel.transformRawTutor(rawSession.tutor),
});

const transformRawSessionPaginatedData = (
  rawSessionData: GraphQLPaginatedResponse<RawSession>,
): PaginatedSessionData => {
  if (!rawSessionData) {
    return undefined;
  }

  const { edges, pageInfo, totalCount } = rawSessionData;

  const rawSessions = graphqlUtils.convertEdgesToArray(edges);
  return {
    sessions: _.map(rawSessions, transformRawSessionData),
    pageInfo,
    totalCount,
  };
};

const convertDataForTutorSessionReport = ({
  rulesChecked,
  sessionAttendances,
}: SessionReportSubmitValues): SessionReportRawSubmitValues => ({
  rules_checked: rulesChecked,
  // TODO: Remove notes from values being passed in, once backend knows to not require them
  // See TW-16005
  notes: '--deprecated--',
  session_attendances: _.map(
    sessionAttendances,
    (attendanceItem): SessionAttendanceRawSubmitValue => {
      const { attendanceStartDateTime, attendanceEndDateTime, studentId, ...rest } = attendanceItem;

      return {
        attendance_start_date_time: dayjs.isDayjs(attendanceStartDateTime)
          ? attendanceStartDateTime.format()
          : undefined,
        attendance_end_date_time: dayjs.isDayjs(attendanceEndDateTime)
          ? attendanceEndDateTime.format()
          : undefined,
        student_id: studentId,
        ...rest,
      };
    },
  ),
});

export default {
  normalizeSessionVariables,
  transformRawSessionData,
  transformRawSessionPaginatedData,
  convertDataForTutorSessionReport,
};
