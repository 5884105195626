import { useLocation, Link } from 'react-router-dom';
import styled, { CSSObject } from '@emotion/styled';
import kebabCase from 'lodash/kebabCase';

import { useFlags } from '@tw/hooks';
import { TranslationKey, getTranslation } from '@tw/i18n';
import { styleShimUtils } from '@tw/util';

import TWIcon from '../TWIcon';
import { DISABLED_ROUTE_PREFIX } from './TWRoute.constants';
import TWRouterContent from './TWRouterContent';
import { IconWrapper } from './TWRouterSideNavigation.styles';
import TWUnauthorizedAccess from './errorPages/TWUnauthorizedAccess';
import { TWRouteMapProps, TWRouterPageProps } from './propTypes';

const TWRouterPageWithSideNavigation = ({
  currentUserData,
  initialData,
  routerPageConfig: { pageKey, routeMap, pageTitle, containerId, styleContent },
}: TWRouterPageProps) => {
  const flags = useFlags();

  if (`${DISABLED_ROUTE_PREFIX}${pageKey}` in flags) {
    return <TWUnauthorizedAccess />;
  }

  const pageContainerId = containerId || `TWRouterPage__${kebabCase(pageTitle)}`;

  return (
    <Layout id={pageContainerId} styles={styleShimUtils.getPageStyleShims()}>
      <Sidebar>
        <SidebarNavigation routes={routeMap} />
      </Sidebar>
      <Content>
        <TWRouterContent
          routeMap={routeMap}
          initialData={initialData}
          currentUserData={currentUserData}
          containerId={pageContainerId}
          style={styleContent}
        />
      </Content>
    </Layout>
  );
};

export default TWRouterPageWithSideNavigation;

const Layout = styled.div<{ styles: CSSObject }>(({ theme, styles = {} }) => ({
  ...styles,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  padding: theme.baseUnit * 3,
  width: '100%',
}));

const Sidebar = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.baseUnit}px ${theme.baseUnit * 2}px 0 0`,
  width: 200,
  height: '100%',
  rowGap: 4,
}));

const SidebarLink = styled(Link)<{ isActive: boolean }>(({ isActive, theme }) => ({
  backgroundColor: isActive ? 'rgba(0,0,0,0.1)' : theme.colors.white,
  borderRadius: 6,
  color: '#000000',
  fontSize: '0.875rem',
  padding: theme.baseUnit,
  width: '100%',

  '&:hover': {
    backgroundColor: isActive ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0.05)',
  },
}));

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const SidebarNavigation = ({ routes }: { routes: TWRouteMapProps }) => {
  const location = useLocation();
  if (!routes || routes.length === 0) return null;

  return (
    <>
      {routes.map((route) => {
        const { path, exact, title, titleKey, visible = true } = route;
        const safeTitle = titleKey ? getTranslation(titleKey as TranslationKey) : title;
        const isActive = exact ? location.pathname === path : location.pathname.includes(path);

        if (!visible) {
          return null;
        }

        return (
          <SidebarLink key={path} to={path} isActive={isActive}>
            {route.icon && (
              <IconWrapper>
                <TWIcon type={route.icon} />
              </IconWrapper>
            )}
            {safeTitle}
          </SidebarLink>
        );
      })}
    </>
  );
};
