export const graphqlDefs = `
extend type OrgNode {
  athleteStatuses: [AthleteStatus!]!
}

type Viewer {
  org: OrgNode!
  visibleUserTypes: [UserType!]!
}

type UserType implements Node {
  id: ID!
  labelPlural: String!
}

type AthleteStatus implements Node {
  id: ID!
  labelPlural: String!
  isActive: Boolean!
}

extend type Query {
  viewer: Viewer!
}`;

export const resolver = () => ({
  __typename: 'Viewer',
  org: {
    __typename: 'OrgNode',
    athleteStatuses: [
      {
        __typename: 'AthleteStatus',
        value: `AthleteStatus1`,
        label: 'Current',
        isActive: true,
      },
      {
        __typename: 'AthleteStatus',
        value: `AthleteStatus2`,
        label: 'Retired',
        isActive: false,
      },
    ],
  },
  visibleUserTypes: [
    {
      __typename: 'UserType',
      value: `UserType1`,
      label: 'Athletes',
    },
    {
      __typename: 'UserType',
      value: `UserType2`,
      label: 'Coaches',
    },
    {
      __typename: 'UserType',
      value: `UserType3`,
      label: 'Teamworks Support',
    },
  ],
});
