import styled from '@emotion/styled';
import { Button, Row } from 'antd';

import { TWIcon } from '@tw/components';
import { TWButtonIcon } from '../../../presentational';

const { Group: ButtonGroup } = Button;

const Container = styled(Row)({
  float: 'right',
});

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginLeft: theme.baseUnit,
}));

interface TWButtonGroupPaginationProps {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
  loading?: boolean;
}

const TWButtonGroupPagination = (props: TWButtonGroupPaginationProps) => {
  const { hasNextPage, hasPreviousPage, onNextPage, onPreviousPage, loading = false } = props;

  return (
    <Container>
      <StyledButtonGroup>
        <TWButtonIcon
          accessibilityLabel="previous page"
          data-testid="pagination-button-left"
          size="small"
          disabled={!hasPreviousPage || loading}
          onClick={onPreviousPage}
        >
          <TWIcon type="material-chevron_left" />
        </TWButtonIcon>
        <TWButtonIcon
          accessibilityLabel="next page"
          data-testid="pagination-button-right"
          size="small"
          disabled={!hasNextPage || loading}
          onClick={onNextPage}
        >
          <TWIcon type="material-chevron_right" />
        </TWButtonIcon>
      </StyledButtonGroup>
    </Container>
  );
};

export default TWButtonGroupPagination;
