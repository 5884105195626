/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchPotentialTutorsQueryVariables = Types.Exact<{
  isTutorActive?: Types.InputMaybe<Types.Scalars['TWFilter_Boolean']>;
  courseIds?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>
  >;
  searchTutors?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type FetchPotentialTutorsQuery = {
  tutors?: {
    edges: Array<{
      node?: { id: string; tutorId: string; fullName?: string | null; personId: number } | null;
    } | null>;
  } | null;
};

export const FetchPotentialTutorsDocument = gql`
  query FetchPotentialTutors(
    $isTutorActive: TWFilter_Boolean
    $courseIds: [Int]
    $searchTutors: String
  ) {
    tutors(isActive: $isTutorActive, courseIds: $courseIds, searchTutors: $searchTutors) {
      edges {
        node {
          id
          tutorId
          fullName
          personId
        }
      }
    }
  }
`;

/**
 * __useFetchPotentialTutorsQuery__
 *
 * To run a query within a React component, call `useFetchPotentialTutorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPotentialTutorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPotentialTutorsQuery({
 *   variables: {
 *      isTutorActive: // value for 'isTutorActive'
 *      courseIds: // value for 'courseIds'
 *      searchTutors: // value for 'searchTutors'
 *   },
 * });
 */
export function useFetchPotentialTutorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPotentialTutorsQuery,
    FetchPotentialTutorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchPotentialTutorsQuery, FetchPotentialTutorsQueryVariables>(
    FetchPotentialTutorsDocument,
    options,
  );
}
export function useFetchPotentialTutorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPotentialTutorsQuery,
    FetchPotentialTutorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchPotentialTutorsQuery, FetchPotentialTutorsQueryVariables>(
    FetchPotentialTutorsDocument,
    options,
  );
}
export type FetchPotentialTutorsQueryHookResult = ReturnType<typeof useFetchPotentialTutorsQuery>;
export type FetchPotentialTutorsLazyQueryHookResult = ReturnType<
  typeof useFetchPotentialTutorsLazyQuery
>;
export type FetchPotentialTutorsQueryResult = Apollo.QueryResult<
  FetchPotentialTutorsQuery,
  FetchPotentialTutorsQueryVariables
>;
