import { gql } from '@apollo/client';

export const CourseUpdate = gql`
  mutation CourseUpdate($id: ID!, $input: CourseUpdateInput!) {
    courseUpdate(id: $id, input: $input) {
      course {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
