/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAppointmentTypesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  isActive?: Types.InputMaybe<Types.Scalars['TWFilter_Boolean']>;
}>;

export type FetchAppointmentTypesQuery = {
  appointmentTypes?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        label: string;
        color: string;
        isActive?: boolean | null;
        isEditable?: boolean | null;
        isTravel?: boolean | null;
        appointmentComplianceType?: string | null;
        visibility?: Array<
          { selectionCode?: string | null; label?: string | null } | {} | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchAppointmentTypesDocument = gql`
  query FetchAppointmentTypes($after: String, $first: Int, $isActive: TWFilter_Boolean) {
    appointmentTypes(after: $after, first: $first, sort: [label_asc], isActive: $isActive) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          label
          color
          isActive
          isEditable
          isTravel
          appointmentComplianceType
          visibility {
            ... on SelectionGroup {
              selectionCode
              label
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchAppointmentTypesQuery__
 *
 * To run a query within a React component, call `useFetchAppointmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAppointmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAppointmentTypesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useFetchAppointmentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAppointmentTypesQuery,
    FetchAppointmentTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAppointmentTypesQuery, FetchAppointmentTypesQueryVariables>(
    FetchAppointmentTypesDocument,
    options,
  );
}
export function useFetchAppointmentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAppointmentTypesQuery,
    FetchAppointmentTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAppointmentTypesQuery, FetchAppointmentTypesQueryVariables>(
    FetchAppointmentTypesDocument,
    options,
  );
}
export type FetchAppointmentTypesQueryHookResult = ReturnType<typeof useFetchAppointmentTypesQuery>;
export type FetchAppointmentTypesLazyQueryHookResult = ReturnType<
  typeof useFetchAppointmentTypesLazyQuery
>;
export type FetchAppointmentTypesQueryResult = Apollo.QueryResult<
  FetchAppointmentTypesQuery,
  FetchAppointmentTypesQueryVariables
>;
