import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import useUserSelectContext from '../UserSelectContext';

const UserPopover = ({ children }: PropsWithChildren) => {
  const {
    aria: { popoverProps },
    refs: { listBoxRef, focusableNodes },
    onKeyDown,
  } = useUserSelectContext();

  useEffect(() => {
    /* Build a triple linked-list (TreeNode[]) of focusable DOM Elements that are children 
    
    type TreeNode {
      id: string;
      expandable: boolean;
      previousId?: string;
      nextId?: string;
      parentId? string;
    } 
    
    */

    focusableNodes.current = {};

    /* First get the parents */
    const parents: Element[] = [];
    if (listBoxRef.current) {
      let node = listBoxRef.current.firstElementChild;
      const lastNode = listBoxRef.current.lastElementChild;

      while (node && node !== lastNode) {
        parents.push(node);
        node = node.nextElementSibling;
      }

      if (node) {
        parents.push(node);
      }
    }

    /* Then make a flat list with the children spliced in */
    const nodes: [string, string | undefined][] = [];

    parents.forEach((parent) => {
      nodes.push([parent.id, undefined]);
      const group = parent.getElementsByTagName('ul');
      if (group?.[0]) {
        let child = group[0].firstElementChild;
        const lastChild = group[0].lastElementChild;

        while (child && child !== lastChild) {
          nodes.push([child.id, parent.id]);
          child = child.nextElementSibling;
        }

        if (child) {
          nodes.push([child.id, parent.id]);
        }
      }
    });

    /* Finally, create the triple linked list */
    nodes.forEach(([node, parent], index) => {
      const previous = nodes[index - 1];
      const next = nodes[index + 1];

      focusableNodes.current[node] = {
        uid: node,
        expandable: parent === undefined,
        parentId: parent,
        previousId: previous?.[0] || undefined,
        nextId: next?.[0] || undefined,
      };
    });
  }, [children, focusableNodes, listBoxRef]);

  return (
    // onKeyDown is drilled down to the children
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div {...popoverProps} tabIndex={-1} onKeyDown={onKeyDown}>
      {children}
    </div>
  );
};

export default UserPopover;
