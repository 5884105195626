import _ from 'lodash';
import moment from 'moment';
import faker from 'faker/locale/en';

import { relayUtils } from '@tw/util';

import mockUtils from './utils';
import { personNodes } from './people.queries';

export const graphqlDefs = `
type FormsConnection {
  pageInfo: PageInfo!
  edges: [FormNodeEdge!]!
  totalCount: Int!
}

type MyAssignedFormsConnection {
  pageInfo: PageInfo!
  edges: [MyAssignedFormsEdge]!
  totalCount: Int!
}

type ScheduledFormsConnection {
  pageInfo: PageInfo!
  edges: [ScheduledFormsEdge]!
  totalCount: Int!
}

type MyAssignedFormsEdge {
  node: MyAssignedFormsNode
  cursor: String!
}

type ScheduledFormsEdge {
  node: ScheduledFormsNode
  cursor: String!
}

type MyAssignedFormsNode {
  completedDateTime: DateTime
  status: FormAssignmentCompletionRoleStatus!
  createdBy: Int
  pk: String
  assignedBy: PersonNode
  id: ID!
  isDocusignForm: Boolean
  dueDate: Date
  roleLabel: String
  assignmentLabel: String
  assignmentNotes: String
  assignmentType: String
  isScheduled: Boolean
  assignmentCompletionCompletedDateTime: DateTime
  allCompleted: Boolean
}

type ScheduledFormsNode {
  id: ID!
  pk: String
  startDate: DateTime
  label: String
  schedule: String
  isEditable: Boolean
  creator: PersonNode
}

type FormsCompletionAssigneeConnection {
  pageInfo: PageInfo!
  edges: [FormsCompletionAssigneeNodeEdge!]!
  totalCount: Int!
}

type FormsFiltersInput {
  assigneeCompletionStatus: String!
  searchString: String!
}

type FormAssignmentsFiltersInput {
  status: String!
  searchString: String!
}

type FormFoldersConnection {
  pageInfo: PageInfo!
  edges: [FormFoldersEdge]!
  totalCount: Int!
}

type FormFoldersEdge {
  node: FormFoldersNode
  cursor: String!
}

type FormFoldersNode {
  id: ID!
  pk: String
  label: String
  isEditable: Boolean
}

extend type Query {
  myQuickForms(
    before: String,
    after: String,
    first: Int,
    last: Int,
    filters: FormsFiltersInput
  ): FormsConnection!

  myAssignedForms(
    before: String,
    after: String,
    first: Int,
    last: Int,
    filters: FormAssignmentsFiltersInput
  ): MyAssignedFormsConnection!

  scheduledFormAssignments(
    before: String,
    after: String,
    first: Int,
    last: Int,
    filters: FormAssignmentsFiltersInput
  ): ScheduledFormsConnection!

  formFolders(
    before: String,
    after: String,
    first: Int,
    last: Int,
  ): FormFoldersConnection!
}`;

const formAssignmentStatuses = ['assigned', 'completed'];
const validAssigneeCompletionStatuses = ['new', 'partial', 'completed'];
const validFormCompletionStatuses = ['new', 'started', 'completed'];
const validAssignmentTypes = ['perpetual', 'scheduled', 'open'];
const nonScheduledAssignmentTypes = ['Perpetual', 'One-time', 'Scheduled'];
const momentPast = moment().subtract(3, 'days');
const momentNow = moment();
const momentFuture = moment().add(5, 'days');

const generateFormCompletionAssigneeNode = (index: number) => ({
  __typename: 'QuickFormCompletionAssigneeEdge',
  node: {
    __typename: 'QuickFormCompletionAssigneeNode',
    key: `QuickFormCompletionAssignee${index}`,
    pk: faker.random.number(),
    dueDateTime: faker.date.between(momentPast, momentFuture),
    status: faker.random.arrayElement(validAssigneeCompletionStatuses),
    createdBy: faker.random.arrayElement(personNodes),
    person: faker.random.arrayElement(personNodes),
    isExternal: faker.random.boolean(),
    qfRole: {
      __typename: 'QuickFormRoleNode',
      pk: faker.random.number(),
      label: _.capitalize(faker.lorem.sentence()),
    },
  },
});

const generateFormNode = (index: number) => {
  const numAssignees = faker.random.number({ min: 1, max: 3 });

  const assignees = _.times(numAssignees, generateFormCompletionAssigneeNode);
  const finalAssignees = _.orderBy(assignees, ['dueDateTime'], ['asc']);

  return {
    __typename: 'QuickFormNode',
    key: `Form${index}`,
    pk: faker.random.number(),
    dueDateTime: faker.date.between(momentPast, momentFuture),
    status: faker.random.arrayElement(validAssigneeCompletionStatuses),
    createdBy: faker.random.arrayElement(personNodes),
    qfRole: {
      __typename: 'QuickFormRoleNode',
      pk: faker.random.number(),
      label: _.capitalize(faker.lorem.sentence()),
    },
    qfCompletion: {
      __typename: 'QuickFormCompletionNode',
      pk: faker.random.number(),
      status: faker.random.arrayElement(validFormCompletionStatuses),
      qfAssignment: {
        __typename: 'QuickFormAssignmentNode',
        pk: faker.random.number(),
        label: _.capitalize(faker.company.catchPhraseDescriptor()),
        description: _.capitalize(mockUtils.sometimesNull(faker.company.bs, 0.35)),
        category: faker.lorem.word(),
        isFavorite: faker.random.boolean(),
        dueDateTime: faker.date.between(momentPast, momentFuture),
        assignmentType: faker.random.arrayElement(validAssignmentTypes),
        rolesSelectionCodes: {},
        visibilityCodes: {},
      },
      qfCompletionAssignees: {
        __typename: 'QuickFormCompletionAssigneesNode',
        totalCount: numAssignees,
        edges: finalAssignees,
      },
    },
  };
};

const generateFormAssignmentNode = (index: number) => {
  const formId = faker.random.number();
  const assignerId = faker.random.number();

  const status = faker.random.arrayElement(formAssignmentStatuses);

  const assignmentType = faker.random.arrayElement(nonScheduledAssignmentTypes);
  const assignedDateTime = faker.date.between(momentPast, momentNow);
  const assignerName = 'Sam Doyle';
  const completedDateTime =
    status === 'completed' ? faker.date.between(assignedDateTime, momentNow) : null;

  return {
    pk: formId,
    formId,
    formAssignmentCompletionId: faker.random.number(),
    formStatus: status,
    isActive: faker.random.boolean(),
    assignedDateTime,
    canEditResponse: false,
    completedDateTime,
    assignedBy: {
      pk: String(assignerId),
      fullName: assignerName,
      initials: 'SD',
      pictureDownloadUrl: '',
      __typename: 'SimplePersonNode',
    },
    createdBy: assignerId,
    formTitle: _.capitalize(faker.lorem.sentence()),
    formDescription: _.capitalize(faker.lorem.sentence()),
    assignmentType,
    roleLabel: 'Completer',
    isDocusignForm: false,
    isScheduled: false,
    assignmentCompletionCompletedDateTime:
      status === 'completed' ? faker.date.between(assignedDateTime, momentNow) : null,
    allCompleted: false,
    manuallyCompleted: false,
    manuallyCompletedBy: null,
    canDeleteCompletion: true,
    dueDateTime: faker.date.between(momentNow, momentFuture),
    workflow: [
      {
        assignedDateTime,
        completedDateTime,
        roleOrder: 1,
        roleName: 'Completer',
        pk: formId,
        person: {
          pk: String(assignerId),
          fullName: assignerName,
          initials: 'SD',
          pictureDownloadUrl: '',
          __typename: 'SimplePersonNode',
        },
        externalName: null,
        externalEmail: null,
        id: faker.random.number(),
        status: `FormAssignmentCompletionRoleStatus.${status}`,
        __typename: 'FormAssignmentCompletionRoleNode',
      },
    ],
    __typename: 'FormAssignmentNode',
    key: `Form${index}`,
  };
};

const validFrequencies = ['day', 'week', 'month', 'year'];

const generateScheduledFormNode = (index: number) => {
  const formId = faker.random.number();
  const assignerId = faker.random.number();
  const scheduleId = faker.random.number();
  const templateId = faker.random.number();

  const frequency = faker.random.arrayElement(validFrequencies);
  const interval = faker.random.number(15) || 1;
  let freqString: String;

  if (frequency === 'day') {
    freqString = interval === 1 ? 'day' : `${interval} days`;
  } else if (frequency === 'week') {
    freqString = faker.date.weekday();
  } else if (frequency === 'month') {
    const day = faker.random.number(30) || 1;
    const mod = day % 10;
    let suffix: String;
    if (mod === 1) {
      suffix = 'st';
    } else if (mod === 2) {
      suffix = 'nd';
    } else if (mod === 3) {
      suffix = 'rd';
    } else {
      suffix = 'th';
    }
    freqString = `${interval === 1 ? 'month' : `${interval} months`} on the ${day}${suffix}`;
  } else if (frequency === 'year') {
    freqString = 'year on April 12th';
  }

  const max = faker.random.number(100);

  return {
    pk: formId,
    formId,
    startDate: faker.date.between(momentPast, momentNow),
    isEditable: faker.random.boolean(),
    creator: {
      pk: String(assignerId),
      fullName: 'Sam Doyle',
      initials: 'SD',
      pictureDownloadUrl: '',
      __typename: 'SimplePersonNode',
    },
    label: _.capitalize(faker.lorem.sentence()),
    schedule: {
      label: `Every ${freqString} for ${max} times`,
      scheduleId,
      __typename: 'FormScheduleNode',
    },
    template: {
      templateId,
      templateLabel: faker.lorem.sentence(),
      __typename: 'FormTemplateNode',
    },
    __typename: 'ScheduledFormsNode',
    key: `Form${index}`,
  };
};

const generateFormFolderNode = (index: number) => ({
  pk: String(faker.random.number()),
  // folderId: faker.random.number(),
  isEditable: faker.random.boolean(),
  label: _.capitalize(faker.lorem.sentence()).slice(0, 15),
  __typename: 'FormFoldersNode',
  key: `Form${index}`,
});

const totalCount = 45;
const formNodeSet = _.times(totalCount, generateFormNode);

const formAssignmentNodeSet = _.times(100, generateFormAssignmentNode);
const scheduledFormsNodeSet = _.times(100, generateScheduledFormNode);
const formFoldersNodeSet = _.times(30, generateFormFolderNode);

declare interface QuickFormNode {
  status: string;
  qfCompletion: { status: string; qfAssignment: { assignmentType: string } };
}

declare interface QuickFormFilters {
  pk?: number;
  assignmentStatus?: string;
  assignmentType?: string;
  searchString?: string;
}

declare interface FormAssignmentNode {
  isActive: boolean;
  formTitle: string;
  pk: string;
}

declare interface FormScheduleNode {
  label: string;
  pk: string;
}

declare interface FormFolderNode {
  label: string;
  pk: string;
}

declare interface FormFilters {
  pk?: number;
  searchString?: string;
  status?: Array<string>;
}

const applyFilters = (nodeList: Array<QuickFormNode>, args: QuickFormFilters) => {
  const { pk, searchString, assignmentStatus, assignmentType } = args;
  if (pk) {
    return _.filter(nodeList, { pk });
  }
  return _.filter(nodeList, (node: QuickFormNode) => {
    const matchesSearchString = mockUtils.nodeHasString(node, searchString);
    const matchesAssignmentStatus = !assignmentStatus || node.status === assignmentStatus;
    const matchesAssignmentType =
      !assignmentType || node.qfCompletion.qfAssignment.assignmentType === assignmentType;

    return matchesSearchString && matchesAssignmentStatus && matchesAssignmentType;
  });
};

const applyFormAssignmentFilters = (nodeList: Array<FormAssignmentNode>, args: FormFilters) => {
  const { pk, searchString, status } = args;
  if (pk) {
    return _.filter(nodeList, { pk });
  }
  return _.filter(nodeList, (node: FormAssignmentNode) => {
    const matchesSearchString = mockUtils.nodeHasString(node, searchString);
    const matchesAssignmentStatus =
      _.isEmpty(status) ||
      (status.includes('active') && node.isActive) ||
      (status.includes('inactive') && !node.isActive);

    return matchesSearchString && matchesAssignmentStatus;
  });
};

const applyScheduledFormsFilters = (nodeList: Array<FormScheduleNode>, args: FormFilters) => {
  const { pk } = args;
  if (pk) {
    return _.filter(nodeList, { pk });
  }
  return nodeList;
};

const applyFormFoldersFilters = (nodeList: Array<FormFolderNode>, args: FormFilters) => {
  const { pk } = args;
  if (pk) {
    return _.filter(nodeList, { pk });
  }
  return nodeList;
};

export const resolvers = {
  myQuickForms: (obj: object, args = {}) => {
    const filteredNodes = applyFilters(formNodeSet, args);
    const nodes = _.orderBy(filteredNodes, ['dueDateTime'], ['asc']);

    return relayUtils.connectionFromArraySlice(nodes, args, {
      sliceStart: 0,
      arrayLength: totalCount,
      nodeAlias: 'myQuickForm',
      connectionType: 'FormsConnection',
      edgeType: 'FormsEdge',
    });
  },

  myAssignedForms: (obj: object, args = {}) => {
    const filteredNodes = applyFormAssignmentFilters(formAssignmentNodeSet, args);
    const nodes = _.orderBy(filteredNodes, ['assignedDateTime'], ['desc']);

    const result = relayUtils.connectionFromArraySlice(nodes, args, {
      sliceStart: 0,
      arrayLength: filteredNodes.length,
      nodeAlias: 'myAssignedForm',
      connectionType: 'MyAssignedFormsConnection',
      edgeType: 'MyAssignedFormsEdge',
    });

    return result;
  },

  scheduledFormAssignments: (obj: object, args = {}) => {
    const filteredNodes = applyScheduledFormsFilters(scheduledFormsNodeSet, args);
    const nodes = _.orderBy(filteredNodes, ['startDate'], ['desc']);

    const result = relayUtils.connectionFromArraySlice(nodes, args, {
      sliceStart: 0,
      arrayLength: filteredNodes.length,
      connectionType: 'ScheduledFormsConnection',
      edgeType: 'ScheduledFormsEdge',
    });

    return result;
  },

  formFolders: (obj: object, args = {}) => {
    const filteredNodes = applyFormFoldersFilters(formFoldersNodeSet, args);

    const result = relayUtils.connectionFromArraySlice(filteredNodes, args, {
      sliceStart: 0,
      arrayLength: filteredNodes.length,
      connectionType: 'FormFoldersConnection',
      edgeType: 'FormFoldersEdge',
    });

    return result;
  },
};
