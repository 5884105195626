/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTicketingEventAllocationsQueryVariables = Types.Exact<{
  searchPersons?: Types.InputMaybe<Types.Scalars['String']>;
  teams?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>
  >;
  personTypes?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>
  >;
  ticketingEvent: Types.Scalars['ID'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sort?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.TicketingEventPersonSortEnum>>
    | Types.InputMaybe<Types.TicketingEventPersonSortEnum>
  >;
}>;

export type FetchTicketingEventAllocationsQuery = {
  ticketingEventPersons?: {
    totalCount?: number | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      node?: {
        id: string;
        notes?: string | null;
        userDetails?: string | null;
        defaultAllocatedAmount?: number | null;
        transferredInAmount?: number | null;
        transferredOutAmount?: number | null;
        reservedAmount?: number | null;
        isManuallyEdited: boolean;
        person?: {
          id: string;
          displayName?: string | null;
          pictureUrl?: string | null;
          initials?: string | null;
        } | null;
        reservationRecipients?: Array<
          | {
              __typename: 'PersonNode';
              personId: string;
              fullNameNormalOrder?: string | null;
              label?: string | null;
            }
          | {
              __typename: 'TicketingGuest';
              name?: string | null;
              guestId: string;
              label?: string | null;
              attributes?: Array<{
                attributeId?: string | null;
                attributeValue?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        actions?: Array<{
          actionType: string;
          ticketCount: number;
          actionDateTime: string;
          actorPerson?: {
            displayName?: string | null;
            teamMembership?: { isOrgSuperuser?: string | null } | null;
          } | null;
          person?: { fullNameNormalOrder?: string | null } | null;
          recipient?:
            | { __typename: 'PersonNode'; fullNameNormalOrder?: string | null }
            | { __typename: 'TicketingGuest'; name?: string | null }
            | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const FetchTicketingEventAllocationsDocument = gql`
  query FetchTicketingEventAllocations(
    $searchPersons: String
    $teams: [Int]
    $personTypes: [ID]
    $ticketingEvent: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [TicketingEventPersonSortEnum]
  ) {
    ticketingEventPersons(
      searchPersons: $searchPersons
      teams: $teams
      personTypes: $personTypes
      ticketingEvent: $ticketingEvent
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          notes
          person {
            id
            displayName
            pictureUrl
            initials
          }
          userDetails
          defaultAllocatedAmount
          transferredInAmount
          transferredOutAmount
          reservedAmount
          isManuallyEdited
          reservationRecipients {
            label
            __typename
            ... on TicketingGuest {
              name
              guestId
              attributes {
                attributeId
                attributeValue
              }
            }
            ... on PersonNode {
              personId
              fullNameNormalOrder
            }
          }
          actions {
            actionType
            ticketCount
            actionDateTime
            actorPerson {
              displayName
              teamMembership {
                isOrgSuperuser
              }
            }
            person {
              fullNameNormalOrder
            }
            recipient {
              __typename
              ... on TicketingGuest {
                name
              }
              ... on PersonNode {
                fullNameNormalOrder
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchTicketingEventAllocationsQuery__
 *
 * To run a query within a React component, call `useFetchTicketingEventAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTicketingEventAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTicketingEventAllocationsQuery({
 *   variables: {
 *      searchPersons: // value for 'searchPersons'
 *      teams: // value for 'teams'
 *      personTypes: // value for 'personTypes'
 *      ticketingEvent: // value for 'ticketingEvent'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchTicketingEventAllocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchTicketingEventAllocationsQuery,
    FetchTicketingEventAllocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchTicketingEventAllocationsQuery,
    FetchTicketingEventAllocationsQueryVariables
  >(FetchTicketingEventAllocationsDocument, options);
}
export function useFetchTicketingEventAllocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTicketingEventAllocationsQuery,
    FetchTicketingEventAllocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchTicketingEventAllocationsQuery,
    FetchTicketingEventAllocationsQueryVariables
  >(FetchTicketingEventAllocationsDocument, options);
}
export type FetchTicketingEventAllocationsQueryHookResult = ReturnType<
  typeof useFetchTicketingEventAllocationsQuery
>;
export type FetchTicketingEventAllocationsLazyQueryHookResult = ReturnType<
  typeof useFetchTicketingEventAllocationsLazyQuery
>;
export type FetchTicketingEventAllocationsQueryResult = Apollo.QueryResult<
  FetchTicketingEventAllocationsQuery,
  FetchTicketingEventAllocationsQueryVariables
>;
