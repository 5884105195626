import { gql } from '@apollo/client';

export const CourseQuery = gql`
  query CourseSelectorQuery(
    $courseId: TWFilter_ID
    $sort: [CourseSortEnum]
    $studentIds: [Int]
    $subjectId: TWFilter_Int
    $termIds: [Int]
    $includeGeneral: Boolean
  ) {
    courses(
      courseId: $courseId
      sort: $sort
      studentIds: $studentIds
      subjectId: $subjectId
      termIds: $termIds
      includeGeneral: $includeGeneral
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          courseId
          label
          description
          subject {
            id
          }
        }
      }
    }
  }
`;
