import { useHistory, useLocation } from 'react-router-dom';

import { useTranslator } from '@tw/hooks';
import { urlUtils } from '@tw/util';

import { TWButtonIconFlat } from '../../../presentational/buttons';
import TWIcon from '../../TWIcon';

interface TWDrawerButtonMessageProps {
  name: string;
  personId: number;
  teamId?: number;
  testID: string;
}

export const TWDrawerButtonMessage = ({
  name,
  personId,
  teamId,
  testID,
}: TWDrawerButtonMessageProps) => {
  const translator = useTranslator();
  const history = useHistory();
  const location = useLocation();

  // check for null or undefined
  const disabled = Boolean(teamId) === false;

  const label = translator.t('messagePerson', { name });

  return (
    <TWButtonIconFlat
      accessibilityLabel={label}
      label={label}
      testID={testID}
      disabled={disabled}
      title={label}
      onClick={() =>
        history.push('/messaging/messageblast', {
          selectionCodes: urlUtils.generateSelectionCodes([{ personId, teamId: teamId ?? 0 }]),
          from: location,
        })
      }
    >
      <TWIcon type="tw-chat" />
    </TWButtonIconFlat>
  );
};
