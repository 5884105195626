import queryString from 'query-string';
import { useEffectOnce } from 'react-use';
import { useMutation } from '@apollo/client';

import { TWLoadingMask } from '@tw/components';

import { HANDLE_FORM_CALLBACK } from './@graphql';

// TODO: In CF there is a magic external access link with a uuid=formcallback
// that is used to handle docusign callbacks and redirect to the
// `form.handleevent` action.
//
// Instead of doing that we can post to an unauthenticated endpoint that
// implements that action here.
export default function ExternalFormCallback() {
  const queryParams = queryString.parse(window.location.search);
  const { cr: completionRoleKey, event } = queryParams;

  const [handleFormCallback] = useMutation(HANDLE_FORM_CALLBACK, {
    variables: {
      input: { completionRoleKey, event },
    },
    onCompleted: (data) => {
      window.top.postMessage(data, '*');
    },
  });

  useEffectOnce(() => {
    handleFormCallback();
  });

  return <TWLoadingMask />;
}
