/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalsyncProviderCalendarsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CalsyncProviderCalendarsQuery = {
  calsyncProviderCalendars?: Array<{
    calendarId?: string | null;
    label?: string | null;
    thirdPartyName?: string | null;
  } | null> | null;
};

export const CalsyncProviderCalendarsDocument = gql`
  query CalsyncProviderCalendars {
    calsyncProviderCalendars {
      calendarId
      label
      thirdPartyName
    }
  }
`;

/**
 * __useCalsyncProviderCalendarsQuery__
 *
 * To run a query within a React component, call `useCalsyncProviderCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalsyncProviderCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalsyncProviderCalendarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalsyncProviderCalendarsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CalsyncProviderCalendarsQuery,
    CalsyncProviderCalendarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalsyncProviderCalendarsQuery, CalsyncProviderCalendarsQueryVariables>(
    CalsyncProviderCalendarsDocument,
    options,
  );
}
export function useCalsyncProviderCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalsyncProviderCalendarsQuery,
    CalsyncProviderCalendarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalsyncProviderCalendarsQuery, CalsyncProviderCalendarsQueryVariables>(
    CalsyncProviderCalendarsDocument,
    options,
  );
}
export type CalsyncProviderCalendarsQueryHookResult = ReturnType<
  typeof useCalsyncProviderCalendarsQuery
>;
export type CalsyncProviderCalendarsLazyQueryHookResult = ReturnType<
  typeof useCalsyncProviderCalendarsLazyQuery
>;
export type CalsyncProviderCalendarsQueryResult = Apollo.QueryResult<
  CalsyncProviderCalendarsQuery,
  CalsyncProviderCalendarsQueryVariables
>;
