import _ from 'lodash';
import { i18nLocaleTranslator } from '@tw/i18n';

import functionUtils from './functionUtils';
import i18nUtils from './i18n';
import stringUtils from './stringUtils';

const asyncRules = {
  required: {
    required: true,
    message: i18nUtils.getI18nString(i18nLocaleTranslator, 'requiredField'),
  },
  whitespace: {
    required: true,
    whitespace: true,
    message: i18nUtils.getI18nString(i18nLocaleTranslator, 'requiredField'),
  },
  email: {
    validator: (rule, value) => {
      if (value && !stringUtils.isValidEmailAddress(value)) {
        return Promise.reject(
          i18nUtils.getI18nString(i18nLocaleTranslator, 'inputValidatorMessages.email'),
        );
      }

      return Promise.resolve();
    },
  },
  emailList: {
    validator: (rule, value) => {
      if (_.some(value, (val) => val && !stringUtils.isValidEmailAddress(val))) {
        return Promise.reject(
          new Error(
            i18nUtils.getI18nString(i18nLocaleTranslator, 'inputValidatorMessages.emailList'),
          ),
        );
      }

      return Promise.resolve();
    },
  },
  phone: {
    validator: (rule, value) => {
      if (value && !stringUtils.isValidPhoneNumber(value)) {
        return Promise.reject(
          i18nUtils.getI18nString(i18nLocaleTranslator, 'inputValidatorMessages.phone'),
        );
      }

      return Promise.resolve();
    },
  },
  url: {
    validator: (rule, value) => {
      if (value && !stringUtils.isValidUrl(value)) {
        return Promise.reject(
          new Error(i18nUtils.getI18nString(i18nLocaleTranslator, 'inputValidatorMessages.url')),
        );
      }
      return Promise.resolve();
    },
  },
  // "required" validator doesn't work well for checkboxes... must handle specifically
  // https://github.com/ant-design/ant-design/issues/8261
  mustBeChecked: {
    required: true,
    transform: (value) => value || undefined,
    type: 'boolean',
    message: i18nUtils.getI18nString(i18nLocaleTranslator, 'inputValidatorMessages.mustBeChecked'),
  },
  oneMustBeChecked: {
    required: true,
    type: 'object',
    validator: (rule, value) => {
      if (rule.required) {
        const oneValueTrue = _.some(_.values(value), (v) => v);
        if (!oneValueTrue) {
          return Promise.reject(
            i18nUtils.getI18nString(
              i18nLocaleTranslator,
              'inputValidatorMessages.oneMustBeChecked',
            ),
          );
        }
      }

      return Promise.resolve();
    },
  },
};

const composeFormRules = functionUtils.memoizeVariadicFn((...ruleNames) =>
  _.map(ruleNames, (ruleName) => asyncRules[ruleName]),
);

export { asyncRules, composeFormRules };
