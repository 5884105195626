import _ from 'lodash';

import { filterSpecUtils, graphqlUtils } from '@tw/util';

import {
  RawStudent,
  Student,
  StudentPaginatedData,
  StudentPaginatedRawData,
  StudentQueryVariables,
  StudentRawQueryVariables,
} from '@tw/types';

const formatRawStudent = (rawStudent: RawStudent): Student => ({
  ...rawStudent,
  advisors: rawStudent.advisors
    ? graphqlUtils.convertEdgesToArray(rawStudent.advisors.edges)
    : undefined,
});

const normalizePaginatedStudentData = (data: StudentPaginatedRawData): StudentPaginatedData => {
  if (_.isEmpty(data)) {
    return null;
  }

  const { students } = data;
  const { edges, pageInfo, totalCount } = students;

  return {
    students: graphqlUtils.convertEdgesToArray(edges),
    pageInfo,
    totalCount,
  };
};

const normalizeStudentVariables = (variables: StudentQueryVariables): StudentRawQueryVariables => {
  if (_.isEmpty(variables)) {
    return null;
  }

  const { studentId, isAtRisk, isDeleted, teamIds, ...rest } = variables;
  return {
    studentId: studentId ? String(studentId) : undefined,
    isAtRisk: _.isBoolean(isAtRisk) ? graphqlUtils.createBooleanQuery(isAtRisk) : undefined,
    isDeleted: _.isBoolean(isDeleted) ? graphqlUtils.createBooleanQuery(isDeleted) : undefined,
    teamIds: filterSpecUtils.createArrayOverlapsFilter(teamIds),
    ...rest,
  };
};

export default {
  normalizePaginatedStudentData,
  normalizeStudentVariables,
  formatRawStudent,
};
