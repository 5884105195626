import styled from '@emotion/styled';
import { Form } from 'antd';

const TWFormContainer = styled(Form)({
  '.ant-form-item': {
    marginBottom: 'unset', // This removes 24px of margin-bottom that antd adds to all form item components
  },
  '.ant-form-item-control': {
    lineHeight: 'unset', // This removes a hardcoded 40px line height
  },
});

export { TWFormContainer };
