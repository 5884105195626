export interface StudyHall {
  id: string;
  studyHallId: string;
  cursor?: string;
  canEditDatetimes: boolean;
  checkinAppToken: string;
  createdDate: string;
  creator: {
    fullNameNormalOrder: string;
  };
  endDatetimeUtc: string;
  isExpired: boolean;
  label: string;
  rotateQrCode: boolean;
  startDatetimeUtc: string;
  __typename: 'StudyHallNode';
}

export enum StudyHallSortTypes {
  studyHallIdAsc = 'ac2_study_hall_id_asc',
  studyHallIdDesc = 'ac2_study_hall_id_desc',
  createdDateAsc = 'created_date_asc',
  createdDateDesc = 'created_date_desc',
  updatedDateAsc = 'updated_date_asc',
  updatedDateDesc = 'updated_date_desc',
  labelAsc = 'label_xlt_asc',
  labelDesc = 'label_xlt_desc',
}

export interface StudyHallQueryFilters {
  first: number;
  after?: string;
  sort?: StudyHallSortTypes[];
  searchStudyHalls?: string;
  isExpired?: boolean;
}

export enum StudyHallStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum StudyHallExportTypes {
  Aggregated = 'AGGREGATED',
  Unique = 'UNIQUE',
}

export interface StudyHallExportFilters {
  teamIds?: number[];
  termIds?: number[];
  studentIds?: number[];
  searchString?: string;
  isAtRisk?: boolean;
  advisorIds?: number[];
  dateRange: [string, string];
}

export interface StudyHallExportRawFilters {
  teamIds?: number | string;
  termIds?: number | string;
  studentIds?: number | string;
  studentName?: string;
  isAtRisk?: string;
  advisorIds?: number | string;
  dateRange: string;
}
