import { useCallback } from 'react';
import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import {
  ProfessorPaginatedRawData,
  ProfessorQueryVariables,
  ProfessorRawQueryVariables,
} from '@tw/types';
import { professorModel } from '@tw/services/academicService';

import { FetchProfessors } from './FetchProfessors.query';

export const useProfessors = (
  variables?: ProfessorQueryVariables,
  options?: QueryHookOptions<ProfessorPaginatedRawData, ProfessorRawQueryVariables>,
) => {
  const { data, ...rest } = useQuery<ProfessorPaginatedRawData, ProfessorRawQueryVariables>(
    FetchProfessors,
    {
      variables: professorModel.normalizeProfessorVariables(variables),
      ...options,
    },
  );

  return {
    data: professorModel.normalizePaginatedProfessorData(data),
    ...rest,
  };
};

export const useProfessorsLazy = (
  variables?: ProfessorQueryVariables,
  options?: LazyQueryHookOptions<ProfessorPaginatedRawData, ProfessorRawQueryVariables>,
) => {
  const [fetchProfessorsRaw, { data, ...rest }] = useLazyQuery<
    ProfessorPaginatedRawData,
    ProfessorRawQueryVariables
  >(FetchProfessors, {
    variables: professorModel.normalizeProfessorVariables(variables),
    ...options,
  });

  const fetchProfessors = useCallback(
    (
      fetchVariables?: ProfessorQueryVariables,
      fetchOptions?: LazyQueryHookOptions<ProfessorPaginatedRawData, ProfessorRawQueryVariables>,
    ) =>
      fetchProfessorsRaw({
        ...fetchOptions,
        variables: professorModel.normalizeProfessorVariables(fetchVariables),
      }),
    [fetchProfessorsRaw],
  );

  return {
    ...rest,
    fetchProfessors,
    data: professorModel.normalizePaginatedProfessorData(data),
  };
};
