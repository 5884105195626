import styled from '@emotion/styled';

export const CropperContainer = styled.div`
  height: 300px;
`;

export const CanvasContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
`;

export const OverlayContainer = styled.div`
  position: relative;
`;

export const Overlay = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.transparentIconHover,
  color: theme.colors.white,
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
}));

export const OverlayIconButtonWrapper = styled.div(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  top: `calc(50% - 0.75rem)`,
  width: 'fit-content',

  '&:hover div button span': {
    color: theme.colors.negativeIcon,
  },
  'div button span': {
    fontSize: theme.iconSize.small,
    color: theme.colors.white,
  },
}));

export const OverlayUploadButtonWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: calc(50% - 0.75rem);
  width: fit-content;
`;
