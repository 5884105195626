import { getTranslation, TranslationKey } from '@tw/i18n';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect';

const alertTypes = ['none', 'email', 'sms', 'both'];

interface TWInputSelectAlertProps extends TWInputSelectProps {
  isDisabled?: boolean;
  placeholder?: string;
  testID?: string;
  optionFilterProp?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TWInputSelectAlert = (props: TWInputSelectAlertProps) => {
  const {
    isDisabled = false,
    placeholder = getTranslation('alerts', 1),
    testID = '',
    optionFilterProp = 'title',
    ...allUnrecognizedProps
  } = props;

  const getAlertTypes = () =>
    alertTypes.map((value) => ({
      value,
      label: getTranslation(`components.alertTypes.${value}` as TranslationKey),
    }));

  return (
    <TWInputSelect
      disabled={isDisabled}
      placeholder={placeholder}
      itemList={getAlertTypes()}
      testID={testID}
      optionFilterProp={optionFilterProp}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allUnrecognizedProps}
    />
  );
};

export default TWInputSelectAlert;
