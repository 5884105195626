import _ from 'lodash';

import { pythonApiBackend } from '@tw/backends';
import { filterSpecUtils, objectUtils } from '@tw/util';
import { ConfigContainerType } from '@tw/types';

import academicServiceStoreConfig from '../academicServiceStoreConfig';

const updateTutorPaths = {
  courseIds: '/course_ids',
  subjectIds: '/subject_ids',
  maximumNumberOfHours: '/maximum_number_of_hours',
};

function createReportFilters(filters) {
  return objectUtils.cleanNullValuesFromObject({
    // eslint-disable-next-line camelcase
    course_ids: filters.courseIds
      ? filterSpecUtils.createArrayOverlapsFilter(_.castArray(filters.courseIds))
      : null,
  });
}
const tutorOperations = {
  updateTutor(
    orgPersonId: number,
    teamId: number,
    tutorId: number | string,
    updatedValues,
    configContainer: ConfigContainerType,
  ) {
    const body = _.map(updatedValues, (value, key) => ({
      op: 'replace',
      path: updateTutorPaths[key],
      value,
    }));

    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: `tutoring/tutors/${tutorId}`,
        debugLabel: 'updateTutor',
        includeAuthenticationHeader: true,
        personId: orgPersonId,
        teamId,
      },
      {
        // fetch body
        method: 'PATCH',
        body,
      },
      configContainer,
    );
  },

  createTutorReport(
    filters,
    personId: number,
    teamId: number,
    configContainer: ConfigContainerType,
  ) {
    const body = {
      contentType: 'csv',
      filters: createReportFilters(filters),
    };

    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: 'tutoring/export',
        debugLabel: 'tutorExport',
        includeAuthenticationHeader: true,
        desiredFormat: 'text',
        personId,
        teamId,
      },
      {
        // fetch body
        method: 'POST',
        body,
      },
      configContainer,
    );
  },
};

export default tutorOperations;
