import * as React from 'react';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { useEffectOnce } from 'react-use';

import { Advisor, GraphQLPaginatedResponse } from '@tw/types';
import { getTranslation } from '@tw/i18n';
import { graphqlUtils } from '@tw/util';

import TWInputSelect from '../TWInputSelect';

import { TWInputSelectAdvisorProps } from './TWInputSelectAdvisor.definitions';
import FETCH_ALL_ADVISORS from './fetchAllAdvisorsQuery.graphql';

export const TWInputSelectAdvisor: React.FC<TWInputSelectAdvisorProps> = (props) => {
  const {
    placeholder = getTranslation('allAdvisors'),
    defaultValue,
    testID,
    ...allUnrecognizedProps
  } = props;

  const [fetchAdvisors, { data, loading, error }] = useLazyQuery<{
    advisors: GraphQLPaginatedResponse<Advisor>;
  }>(FETCH_ALL_ADVISORS);

  const advisors = data ? graphqlUtils.convertEdgesToArray(data?.advisors.edges) : [];

  useEffectOnce(() => {
    if (defaultValue) {
      fetchAdvisors();
    }
  });

  const keyExtractor = (advisor: Advisor) => advisor.advisorId;

  const labelExtractor = (advisor: Advisor) => `${advisor.preferredName} ${advisor.lastName}`;

  const valueExtractor = (advisor: Advisor) => Number(advisor.advisorId);

  const onFocus = () => {
    if (_.isEmpty(advisors)) {
      fetchAdvisors();
    }
  };

  if (error) {
    return null;
  }

  return (
    <TWInputSelect
      getPopupContainer={(trigger) => trigger.parentElement}
      allowClear
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      defaultValue={defaultValue}
      itemList={advisors}
      keyExtractor={keyExtractor}
      labelExtractor={labelExtractor}
      valueExtractor={valueExtractor}
      loading={loading}
      onFocus={onFocus}
      minWidth="220px"
      testID={testID}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allUnrecognizedProps}
    />
  );
};
