import { gql } from '@apollo/client';

export const FetchStudyHalls = gql`
  query FetchStudyHalls(
    $first: Int
    $after: String
    $sort: [StudyHallSortEnum]
    $isExpired: TWFilter_Boolean
    $searchStudyHalls: String
  ) {
    studyHalls(
      first: $first
      after: $after
      sort: $sort
      isExpired: $isExpired
      searchStudyHalls: $searchStudyHalls
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          studyHallId
          isExpired
          createdDate
          checkinAppToken
          creator {
            fullNameNormalOrder
          }
          label
          rotateQrCode
          startDatetimeUtc
          endDatetimeUtc
          canEditDatetimes
        }
      }
    }
  }
`;
