import { Switch, withRouter } from 'react-router-dom';

import TWRouteMap from './TWRouteMap';

const TWRouterRendererSwitch = (props) => (
  <Switch>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <TWRouteMap {...props} />
  </Switch>
);

export default withRouter(TWRouterRendererSwitch);
