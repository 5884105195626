import { InterpolationOptions } from 'node-polyglot';

import { TranslationKey, TranslatorConsumer } from '@tw/i18n';

interface TWTextProps {
  dictionaryId: TranslationKey;
  count?: number;
  variables?: InterpolationOptions;
}

const TWText = ({ dictionaryId, variables = {}, count = 1 }: TWTextProps) => (
  <TranslatorConsumer>
    {(i18nTranslator) => (
      <>
        {i18nTranslator.t(dictionaryId, {
          ...variables,
          smart_count: count,
        })}
      </>
    )}
  </TranslatorConsumer>
);

export default TWText;
