import { ColumnSort } from '@tanstack/react-table';
import { useState } from 'react';

type UseSortProps = {
  defaultColumnSort: ColumnSort[];
  sortableColumns: string[];
  withSingleSort: boolean;
  onSortChange?: (sort: ColumnSort[]) => void;
};

export function useSort({
  defaultColumnSort,
  sortableColumns,
  withSingleSort,
  onSortChange,
}: UseSortProps) {
  const [sort, setSort] = useState<ColumnSort[]>(
    defaultColumnSort.filter((s) => sortableColumns.includes(s.id)),
  );

  const isSorted = (columnId: string): 1 | -1 | undefined => {
    const index = sort.findIndex((s) => s.id === columnId);
    if (index === -1) {
      return undefined;
    }
    return sort[index].desc ? -1 : 1;
  };

  const isSortable = (columnId: string) => sortableColumns.includes(columnId);

  const toggleSort = (columnId: string) => {
    const sortDirection = isSorted(columnId);

    if (sortDirection === undefined) {
      const newSort = withSingleSort
        ? [{ id: columnId, desc: false }]
        : [...sort, { id: columnId, desc: false }];
      setSort(newSort);
      onSortChange?.(newSort);
    } else {
      const index = sort.findIndex((s) => s.id === columnId);
      const newSort = [...sort];
      newSort.splice(index, 1);
      if (sortDirection === 1) {
        newSort.push({ id: columnId, desc: true });
      }

      setSort(newSort);
      onSortChange?.(newSort);
    }
  };

  return {
    isSortable,
    isSorted,
    toggleSort,
  };
}
