import { CourseSection, RawCourseSection } from './courseSection';
import { ProfessorSurveyReport } from './professorSurveyReport';
import { GraphQLPageInfo, GraphQLPaginatedResponse } from '../graphQL';

export interface ProfessorSurveyAssignment {
  id: string;
  professorSurveyAssignmentId: string;
  courseSectionId: number;
  courseSection: CourseSection;
  professorSurveyReports: ProfessorSurveyReport[];
  status: ProfessorSurveyAssignmentStatus;
  // cursor may be added by graphqlUtils.convertEdgesToArray, but generally should not be used
  cursor?: string;
}

export interface RawProfessorSurveyAssignment
  extends Omit<ProfessorSurveyAssignment, 'professorSurveyReports' | 'courseSection'> {
  courseSection: RawCourseSection;
  professorSurveyReports: GraphQLPaginatedResponse<ProfessorSurveyReport>;
}

export enum ProfessorSurveyAssignmentSortEnum {
  professorSurveyAssignmentIdAsc = 'ac2_professor_survey_assignment_id_asc',
  professorSurveyAssignmentIdDesc = 'ac2_professor_survey_assignment_id_desc',
  completedDateTimeAsc = 'completed_date_time_asc',
  completedDateTimeDesc = 'completed_date_time_desc',
  createdDateAsc = 'created_date_asc',
  createdDateDesc = 'created_date_desc',
  updatedDateAsc = 'updated_date_asc',
  updatedDateDesc = 'updated_date_desc',
}

export enum ProfessorSurveyAssignmentStatus {
  pending = 'pending',
  inProgress = 'in_progress',
  completed = 'completed',
}

export interface ProfessorSurveyAssignmentQueryVariables {
  professorSurveyAssignmentId?: string[];
  advisorId?: string[];
  completedDateTime?: string;
  courseId?: string[];
  courseSectionId?: number[];
  eligibilityYearId?: string[];
  isAtRisk?: boolean;
  isDeleted?: boolean;
  orgId?: number;
  professorSurveyId?: string[];
  searchSurveyResults?: string;
  sort?: ProfessorSurveyAssignmentSortEnum[];
  status?: ProfessorSurveyAssignmentStatus;
  teamId?: string[];
  createdDate?: string;
  updatedDate?: string;
  first?: number;
  after?: string;
}

export interface ProfessorSurveyAssignmentRawQueryVariables {
  professorSurveyAssignmentId?: string;
  professorSurveyId?: string;
  status?: ProfessorSurveyAssignmentStatus;
  courseSectionId?: string | number;
  completedDateTime?: string;
  isDeleted?: boolean;
  orgId?: number;
  createdDate?: string;
  updatedDate?: string;
  sort?: ProfessorSurveyAssignmentSortEnum[];
  after?: string;
  first?: number;
  advisorId?: string[];
  eligibilityYearId?: string[];
  isAtRisk?: boolean;
  teamId?: string[];
}

export interface PaginatedProfessorSurveyAssignmentData {
  professorSurveyAssignments: ProfessorSurveyAssignment[];
  pageInfo: GraphQLPageInfo;
  totalCount: number;
}

export interface ProfessorSurveyReportSubmitSchema {
  /* eslint-disable camelcase */
  professor_survey_report_id: number;
  grade_id?: number;
  comment?: string;
  is_at_risk?: 'true' | 'false';
  absence_count?: number;
  /* eslint-enable camelcase */
}

export interface UpdateProfessorSurveyAssignmentSchema {
  uuid: string;
  status: string;
  // eslint-disable-next-line camelcase
  professor_survey_reports: ProfessorSurveyReportSubmitSchema[];
}
