/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTicketingBlockedGuestBulkMutationVariables = Types.Exact<{
  input: Types.TicketingBlockedGuestBulkCreateInput;
}>;

export type CreateTicketingBlockedGuestBulkMutation = {
  ticketingBlockedGuestBulkCreate: {
    ticketingBlockedGuest?: Array<{
      id: string;
      notes?: string | null;
      attributes?: Array<{
        attributeId?: string | null;
        attributeValue?: string | null;
      } | null> | null;
    } | null> | null;
  };
};

export const CreateTicketingBlockedGuestBulkDocument = gql`
  mutation CreateTicketingBlockedGuestBulk($input: TicketingBlockedGuestBulkCreateInput!) {
    ticketingBlockedGuestBulkCreate(input: $input) {
      ticketingBlockedGuest {
        id
        attributes {
          attributeId
          attributeValue
        }
        notes
      }
    }
  }
`;
export type CreateTicketingBlockedGuestBulkMutationFn = Apollo.MutationFunction<
  CreateTicketingBlockedGuestBulkMutation,
  CreateTicketingBlockedGuestBulkMutationVariables
>;

/**
 * __useCreateTicketingBlockedGuestBulkMutation__
 *
 * To run a mutation, you first call `useCreateTicketingBlockedGuestBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketingBlockedGuestBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketingBlockedGuestBulkMutation, { data, loading, error }] = useCreateTicketingBlockedGuestBulkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTicketingBlockedGuestBulkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTicketingBlockedGuestBulkMutation,
    CreateTicketingBlockedGuestBulkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTicketingBlockedGuestBulkMutation,
    CreateTicketingBlockedGuestBulkMutationVariables
  >(CreateTicketingBlockedGuestBulkDocument, options);
}
export type CreateTicketingBlockedGuestBulkMutationHookResult = ReturnType<
  typeof useCreateTicketingBlockedGuestBulkMutation
>;
export type CreateTicketingBlockedGuestBulkMutationResult =
  Apollo.MutationResult<CreateTicketingBlockedGuestBulkMutation>;
export type CreateTicketingBlockedGuestBulkMutationOptions = Apollo.BaseMutationOptions<
  CreateTicketingBlockedGuestBulkMutation,
  CreateTicketingBlockedGuestBulkMutationVariables
>;
