import styled from '@emotion/styled';
import { Dropdown } from 'antd';
import TWIcon from '../../TWIcon';

interface MultiSelectionsHeaderProps {
  disabled: boolean;
}

export const MultiSelectionsHeader = styled.div<MultiSelectionsHeaderProps>(
  ({ theme, disabled }) => {
    const shared = {
      alignItems: 'center',
      backgroundColor: theme.colors.white,
      borderRadius: '2px',
      border: `1px solid ${theme.colors.border}`,
      cursor: 'pointer',
      display: 'flex',
      height: '32px',
      justifyContent: 'space-between',
      minWidth: '150px',
      padding: `0px ${theme.baseUnit}px`,
    };
    if (disabled) {
      return {
        ...shared,
        backgroundColor: theme.colors.disabledBackground,
        color: theme.colors.buttonTextDisabled,
        cursor: 'not-allowed',
      };
    }
    return shared;
  },
);

export const StyledDropdown = styled(Dropdown)(({ theme, disabled }) => ({
  '&:hover': {
    borderColor: disabled ? '' : theme.colors.buttonBorderHover,
  },
}));

export const ClearSelectionsButton = styled.span(({ theme }) => ({
  fontsize: theme.baseUnit,
  color: theme.colors.link,
  marginBottom: '4px',
  '&:hover': {
    color: theme.colors.linkHover,
    cursor: 'pointer',
  },
}));
export const SubHeadTextContainer = styled.div(() => ({
  textAlign: 'left',
  marginBottom: '4px',
  width: '100%',
}));
export const ClearSelectionsButtonContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  width: '100%',
}));

interface PlaceholderProps {
  disabled: boolean;
}

export const Placeholder = styled.span<PlaceholderProps>(({ theme, disabled }) => ({
  color: disabled ? theme.colors.buttonTextDisabled : theme.colors.placeholder,
}));

export const InputIcon = styled(TWIcon)(({ theme }) => ({
  marginLeft: `${theme.baseUnit}px`,
  color: 'rgba(0, 0, 0, 0.25)',
}));

export const LoadingIcon = styled(TWIcon)(({ theme }) => ({
  marginLeft: `${theme.baseUnit}px`,
  fontSize: '18px!important',
  color: 'rgba(0, 0, 0, 0.25)',
}));
