import { gql } from '@apollo/client';

export const CourseSectionUpdate = gql`
  mutation CourseSectionUpdate($id: ID!, $input: CourseSectionUpdateInput!) {
    courseSectionUpdate(id: $id, input: $input) {
      courseSection {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
