import { UserSelectionOptionsDocument } from '@tw/generated';

const standardSelectionOptions = ['persontype', 'everyone', 'pos', 'year', 'status', 'contacts'];
const customSelectionOptions = ['custom'];

const getVariablesWithSelectionOptions = (selectionOptions: string[]) => [
  {
    variables: {
      filters: {
        selectedTeam: null,
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 'VGVhbU5vZGU6MTI1Mw==', // Academic Tutoring
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 'VGVhbU5vZGU6MTI1Mg==', // Baseball
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 'VGVhbU5vZGU6MTI1MQ==', // Basketball
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 'VGVhbU5vZGU6MTI0OQ==', // Football - current team
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 'VGVhbU5vZGU6MTI0OQ==', // Football - current team
        selectionOptions,
      },
      withTeamMembership: true,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectionRestrictingPermission: 'MESSAGING_SEND',
        selectedTeam: 'VGVhbU5vZGU6MTI0OQ==', // Football - current team
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 'VGVhbU5vZGU6MTI0OQ==', // Football - current team
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 'VGVhbU5vZGU6MTI0OQ==', // Football - current team
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 85, // All Teams team
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 'VGVhbU5vZGU6MTI1MA==', // Softball
        selectionOptions,
      },
      withTeamMembership: false,
      withPeople: true,
    },
  },
  {
    variables: {
      filters: {
        selectedTeam: 'VGVhbU5vZGU6MTI1MA==', // Softball
        selectionOptions,
      },
      withTeamMembership: true,
      withPeople: true,
    },
  },
  {
    variables: {
      withTeamMembership: false,
      withPeople: true,
      filters: {
        selectionRestrictingPermission: 'CALENDAR_VIEW_OTHERS',
        selectedTeam: 'VGVhbU5vZGU6MTI0OQ==',
        selectionOptions,
      },
    },
  },
  {
    variables: {
      withTeamMembership: false,
      withPeople: true,
      filters: {
        selectionRestrictingPermission: 'CALENDAR_ADD_APPT_OTHERS',
        selectedTeam: 'VGVhbU5vZGU6MTI0OQ==',
        selectionOptions,
      },
    },
  },
];

const standardMocks = getVariablesWithSelectionOptions(standardSelectionOptions).map(
  ({ variables }) => ({
    request: {
      query: UserSelectionOptionsDocument,
      variables,
    },
    result: {
      data: {
        groups: [
          {
            label: 'Athlete',
            pluralLabel: 'Athletes',
            groupType: 'TYPE',
            selectionCode: '9956e108-4dc2-4d1e-a665-b6fbc7ab66a3',
            team: {
              teamId: 'X',
              label: '',
            },
            people: [
              {
                id: '2',
                label: 'Wilhelmine Will',
                displayName: 'Will Wilhelmine',
                firstName: 'Will',
                lastName: 'Wilhelmine',
                initials: 'WW',
                pictureUrl: 'http://localhost:4200/avatar/?number=6',
                selectionCode: '7f244a60-52b4-436a-8e11-b03832ba3dc6',
                personId: '2',
                streamUserId: 'p2',
                userTypesByTeam: [],
                teamMembership: [],
              },
              {
                id: '3',
                label: 'Sister Eichmann',
                displayName: 'Sister Eichmann',
                firstName: 'Sister',
                lastName: 'Eichmann',
                initials: 'SE',
                pictureUrl: 'http://localhost:4200/avatar/?number=2',
                selectionCode: 'd3d4f59a-a1ce-453d-854b-c12e02500f30',
                personId: '3',
                streamUserId: 'p3',
                userTypesByTeam: [],
                teamMembership: [],
              },
            ],
          },
          {
            label: 'Catcher',
            pluralLabel: 'Catchers',
            groupType: 'POSITION',
            selectionCode: 'aa1957df-bac7-46be-9860-398d151c84f7',
            team: {
              teamId: '',
              label: '',
            },
            people: [
              {
                id: '5',
                label: 'Amelia Grimes',
                displayName: 'Amelia Grimes',
                firstName: 'Amelia',
                lastName: 'Grimes',
                initials: 'AG',
                pictureUrl: 'http://localhost:4200/avatar/?number=4',
                selectionCode: 'e98616ec-f068-4d29-83e0-335b3d072ea6',
                personId: '5',
                streamUserId: 'p5',
                userTypesByTeam: [],
                teamMembership: [],
              },
              {
                id: '6',
                label: 'Garnet Oberbrunner',
                displayName: 'Garnet Oberbrunner',
                firstName: 'Garnet',
                lastName: 'Oberbrunner',
                initials: 'GO',
                pictureUrl: 'http://localhost:4200/avatar/?number=2',
                selectionCode: '2b2223d5-94f1-46ea-a8ac-9fa84f3712df',
                personId: '6',
                streamUserId: 'p6',
                userTypesByTeam: [],
                teamMembership: [],
              },
            ],
          },
        ],
      },
    },
  }),
);

const customMocks = getVariablesWithSelectionOptions(customSelectionOptions).map(
  ({ variables }) => ({
    request: {
      query: UserSelectionOptionsDocument,
      variables,
    },
    result: {
      data: {
        groups: [
          {
            label: 'Custom',
            pluralLabel: 'Customs',
            groupType: 'TYPE',
            selectionCode: '956e108-4dc2-4d1e-a665-b6fbc7ab66a3',
            team: {
              teamId: 'X',
              label: '',
            },
            people: [
              {
                id: '2',
                label: 'Milhelmine Mill',
                displayName: 'Mill Milhelmine',
                firstName: 'Mill',
                lastName: 'Milhelmine',
                initials: 'MM',
                pictureUrl: 'http://localhost:4200/avatar/?number=6',
                selectionCode: 'Xf244a60-52b4-436a-8e11-b03832ba3dc6',
                personId: '2',
                streamUserId: 'p2',
                userTypesByTeam: [],
                teamMembership: [],
              },
              {
                id: '3',
                label: 'Mister Eichmann',
                displayName: 'Mister Eichmann',
                firstName: 'Mister',
                lastName: 'Eichmann',
                initials: 'ME',
                pictureUrl: 'http://localhost:4200/avatar/?number=2',
                selectionCode: 'X3d4f59a-a1ce-453d-854b-c12e02500f30',
                personId: '3',
                streamUserId: 'p3',
                userTypesByTeam: [],
                teamMembership: [],
              },
            ],
          },
        ],
      },
    },
  }),
);

const mocks = [...standardMocks, ...customMocks];

export default mocks;
