import { TWIcon } from '@tw/components';
import { useTranslator } from '@tw/i18n';

import { TWButtonIconFlat } from '../../../presentational/buttons';
import { BackButtonWrapper } from './TWDrawerBackButton.styles';

interface TWDrawerBackButtonProps {
  accessibilityLabel?: string;
  testID?: string;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const TWDrawerBackButton = (props: TWDrawerBackButtonProps) => {
  const { testID = null, accessibilityLabel = null, onClick } = props;

  const translator = useTranslator();

  return (
    <BackButtonWrapper>
      <TWButtonIconFlat
        data-testid={testID || 'Drawer:BackButton'}
        className="back-button"
        onClick={onClick}
        accessibilityLabel={accessibilityLabel || translator.t('goBack')}
      >
        <TWIcon type="material-arrow_back" />
      </TWButtonIconFlat>
    </BackButtonWrapper>
  );
};

export default TWDrawerBackButton;
