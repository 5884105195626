import { useEffect } from 'react';

// Sets a script element with given url
const useScript = (url: string) => {
  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = url;
    document.body.appendChild(scriptElement);

    // Cleanup
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [url]);
};

export default useScript;
