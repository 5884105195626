import React, { useEffect, useRef } from 'react';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import { Popover } from 'antd';
import { useHistory } from 'react-router-dom';
import { getTranslation } from '@tw/i18n';
import { useTranslator, useViewer, useLogout, useStreamClient } from '@tw/hooks';
import {
  TWListItemAvatar,
  TWSpacingContainer,
  TWButton,
  TWTextDefault,
  TWHeading2,
  TWFlexContainer,
  TWLoadingMask,
  useGlobalModal,
} from '@tw/components';
import { TWLogoBlack } from '@tw/assets';
import { useChooseAccountMutation, useUserAccountsLazyQuery } from '@tw/generated';
import { DOMUtils } from '@tw/util';
import localStorage from '@tw/services/localStorage';

import {
  ChooseAccountContainer,
  TWLogoImage,
  Container,
  CenterContent,
} from './ChooseAccount.styles';

const containerId = 'ChooseAccount';

const ChooseAccount: React.FC = () => {
  const translator = useTranslator();
  const history = useHistory();
  const { setUser, hasMultipleAccounts, person: personData, getTabCount } = useViewer();
  const { warningConfirmGlobalModal } = useGlobalModal();
  const { disconnect: disconnectStreamClient } = useStreamClient();

  // Track if we have actively changed users
  const hasChangedUsers = useRef(false);

  // Switching users takes time, so let's not navigate until the user has switched in the context --
  // avoids permission errors when returning to app
  useEffect(() => {
    if (hasChangedUsers.current) {
      history.replace('/home');
    }
  }, [history, personData]);

  const [chooseAccountMutation, { called: chooseAccountCalled, error }] = useChooseAccountMutation({
    onCompleted: ({ chooseAccount: { currentUserData } }) => {
      if (currentUserData) {
        const currentUserFromLocalStorage = localStorage.getCurrentUser();
        const { person, ...rest } = currentUserData;
        const hasChanged = isEmpty(currentUserFromLocalStorage) || person?.id !== personData.id;

        // Only update the viewer in the context if the selected user has changed
        if (hasChanged) {
          // Track that we've changed the user so that we can redirect after updated in the context
          hasChangedUsers.current = true;
          setUser(rest);
          return;
        }

        // If the user hasn't changed, then go ahead and redirect to the dashboard since
        history.replace('/home');
      }
    },
  });

  const chooseAccount = async (params: Parameters<typeof chooseAccountMutation>[0]) => {
    const tabCount = getTabCount();
    const isChange = params?.variables?.person !== personData.id;
    if (
      !isChange ||
      tabCount < 2 ||
      (await warningConfirmGlobalModal({
        title: getTranslation('areYouSure'),
        content: getTranslation('navigation.tabsRefreshConfirmation'),
        okText: getTranslation('ok'),
        cancelText: getTranslation('cancel'),
      }))
    ) {
      if (isChange) {
        disconnectStreamClient();
      }
      chooseAccountMutation(params);
    }
  };

  const [fetchUserAccounts, { data, loading }] = useUserAccountsLazyQuery({
    onCompleted: () => {
      if (!hasMultipleAccounts) {
        chooseAccountMutation({ variables: { person: personData.id } });
      }
    },
  });

  useEffect(() => {
    if (personData.id) {
      fetchUserAccounts();
    }
  }, [personData, fetchUserAccounts]);

  const [logoutUser] = useLogout();

  if (loading || (chooseAccountCalled && !error)) {
    return <TWLoadingMask />;
  }
  if (error) {
    throw new Error(getTranslation('authentication.noActiveAccountError'));
  }

  const groupedAccounts = groupBy(data?.userAccounts, (account) => account.org.id);

  const handleOnAccountSelect = (accountId: string) => {
    if (!chooseAccountCalled) {
      chooseAccount({ variables: { person: accountId } });
    }
  };

  const renderLoginsForAccount = (accountId: string) => {
    if (!accountId) {
      return null;
    }

    const loginsForAccount =
      data?.userAccounts.filter((account) => account?.org.id === accountId) ?? [];

    return (
      <div>
        {loginsForAccount.map(({ person }) => (
          <TWListItemAvatar
            key={person.id}
            testID={`ChooseAccount:loginpicker:login:${person.fullNameNormalOrder}`}
            label={person.fullNameNormalOrder ?? ''}
            avatarImage={person.pictureUrl ?? undefined}
            onClick={() => {
              handleOnAccountSelect(person.id);
            }}
            subtext={person.emailAddress ?? undefined}
          />
        ))}
      </div>
    );
  };

  return (
    <Container id={containerId}>
      <CenterContent align="center" justify="space-evenly">
        <TWFlexContainer align="center">
          <TWSpacingContainer twMarginBottom={8}>
            <TWLogoImage src={TWLogoBlack} alt="Teamworks logo" />
          </TWSpacingContainer>
          <TWSpacingContainer twMarginBottom={3}>
            <TWHeading2>Choose an Account</TWHeading2>
          </TWSpacingContainer>
          <TWSpacingContainer twMarginBottom={3}>
            <ChooseAccountContainer>
              {Object.values(groupedAccounts).map((accountLogins) => {
                const { org: account } = accountLogins[0];

                if (accountLogins.length === 1) {
                  return (
                    <TWListItemAvatar
                      key={account.id}
                      size="large"
                      testID={`ChooseAccount:accountpicker:account:${account.label}`}
                      label={account.label ?? ''}
                      avatarImage={account.logoUrl ?? undefined}
                      onClick={() => handleOnAccountSelect(accountLogins[0].person.id)}
                    />
                  );
                }

                return (
                  <Popover
                    key={account.id}
                    getPopupContainer={() => DOMUtils.getContainer(containerId)}
                    placement="rightTop"
                    trigger="hover"
                    content={() => renderLoginsForAccount(account.id)}
                    overlayStyle={{
                      paddingLeft: 0,
                    }}
                  >
                    <TWListItemAvatar
                      size="large"
                      key={account.id}
                      testID={`ChooseAccount:accountpicker:account:${account.label}`}
                      label={account.label ?? ''}
                      avatarImage={account.logoUrl ?? undefined}
                      onClick={() => handleOnAccountSelect(accountLogins[0].person.id)}
                      rightIcons={[
                        {
                          type: 'material-chevron_right',
                        },
                      ]}
                    />
                  </Popover>
                );
              })}
            </ChooseAccountContainer>
          </TWSpacingContainer>
          <TWSpacingContainer>
            <TWButton
              customStyles={{ width: 144, display: 'block', textAlign: 'center' }}
              type="primary"
              size="large"
              accessibilityLabel="menu-button"
              testID="TWButton"
              onClick={() => logoutUser()}
            >
              <TWTextDefault>{translator.t('navigation.signOut')}</TWTextDefault>
            </TWButton>
          </TWSpacingContainer>
        </TWFlexContainer>
      </CenterContent>
    </Container>
  );
};

export default ChooseAccount;
