/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateTicketingGuestMutationVariables = Types.Exact<{
  input: Types.TicketingGuestBulkUpdateInput;
}>;

export type BulkUpdateTicketingGuestMutation = {
  ticketingGuestBulkUpdate: { userErrors: Array<{ field: Array<string>; message: string }> };
};

export const BulkUpdateTicketingGuestDocument = gql`
  mutation BulkUpdateTicketingGuest($input: TicketingGuestBulkUpdateInput!) {
    ticketingGuestBulkUpdate(input: $input) {
      userErrors {
        field
        message
      }
    }
  }
`;
export type BulkUpdateTicketingGuestMutationFn = Apollo.MutationFunction<
  BulkUpdateTicketingGuestMutation,
  BulkUpdateTicketingGuestMutationVariables
>;

/**
 * __useBulkUpdateTicketingGuestMutation__
 *
 * To run a mutation, you first call `useBulkUpdateTicketingGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateTicketingGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateTicketingGuestMutation, { data, loading, error }] = useBulkUpdateTicketingGuestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateTicketingGuestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateTicketingGuestMutation,
    BulkUpdateTicketingGuestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateTicketingGuestMutation,
    BulkUpdateTicketingGuestMutationVariables
  >(BulkUpdateTicketingGuestDocument, options);
}
export type BulkUpdateTicketingGuestMutationHookResult = ReturnType<
  typeof useBulkUpdateTicketingGuestMutation
>;
export type BulkUpdateTicketingGuestMutationResult =
  Apollo.MutationResult<BulkUpdateTicketingGuestMutation>;
export type BulkUpdateTicketingGuestMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateTicketingGuestMutation,
  BulkUpdateTicketingGuestMutationVariables
>;
