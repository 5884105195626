/* eslint-disable no-param-reassign */

import _ from 'lodash';

// Debug logging is really useful for tracing down ajax errors: enable it by setting
// `debug: true` when making the request, in the service's Operations.js
const debugByDefault = false;
function shouldDebugRequest(metaData) {
  return metaData.urlOptions.debug == null ? debugByDefault : !!metaData.urlOptions.debug;
}

function showCopyPasteableRequestInfo(metaData) {
  // These are a few different places where status/headers/etc might live, depending on what
  // library sent the request.
  const responseHeaders =
    _.get(metaData, 'response.headers.map') || _.get(metaData, 'response.respInfo.headers');

  // We toss all strings into an object so that you can show/hide different bits by just
  // commenting out the parts you want/don't want.
  const responseBodyAbridged =
    _.isArray(metaData.responseJson) &&
    metaData.responseJson.length > 3 &&
    `// an array with ${metaData.responseJson.length} items`;

  const debugInfo = {
    requestHttp: `${metaData.fetchOptions.method} ${metaData.fullUrl}`,
    requestHeaders: `${_.map(
      metaData.fetchOptions.headers,
      (value, key) => `${key}: ${value}`,
    ).join('\n')}\n`,
    requestBody: metaData.fetchOptions.body,
    responseHttp:
      metaData.responseStatus &&
      `HTTP ${metaData.responseStatus}: ${metaData.response.statusText || ''}`,
    responseHeaders: `${_.map(responseHeaders, (value, key) => `${key}: ${value}`).join('\n')}\n`,
    responseBody:
      responseBodyAbridged ||
      JSON.stringify(metaData.responseJson, null, '  ') ||
      metaData.responseText ||
      '// no response data',
  };

  // This string is intended as something you can copy/paste into Mattermost
  const debugString = _.compact([
    '-----',
    '*Request*',
    '```',
    debugInfo.requestHttp,
    // debugInfo.requestHeaders,
    debugInfo.requestBody,
    '```\n',
    '*Response*',
    '```',
    debugInfo.responseHttp,
    // debugInfo.responseHeaders,
    debugInfo.responseBody,
    '```',
    '-----',
  ]).join('\n');
  console.log(debugString);

  if (metaData.responseJson) {
    console.log('responseJson: ', metaData.responseJson);
  }
  return debugString;
}

export default {
  shouldDebugRequest,
  showCopyPasteableRequestInfo,
};
