const ATTENDANCE_STATUS_TYPE_MAP: Record<string, string> = {
  canceled: 'default',
  otherCanceled: 'default',
  studentCanceled: 'default',
  tutorCanceled: 'default',
  partialSession: 'warning',
  pending: 'default',
  noFaultAbsence: 'success',
  attended: 'success',
  completed: 'warning',
  studentNoShow: 'danger',
};

const REPORT_STATUS_MAP: Record<string, { type: string; iconTitle: string }> = {
  needsReport: {
    type: 'danger',
    iconTitle: 'tw-document-x',
  },
  sessionCanceled: {
    type: 'primary',
    iconTitle: 'tw-document-question-mark',
  },
  reportComplete: {
    type: 'success',
    iconTitle: 'tw-document-check',
  },
};

enum StudentYearType {
  academic = 'Academic',
  athletic = 'Athletic',
}

export { ATTENDANCE_STATUS_TYPE_MAP, REPORT_STATUS_MAP, StudentYearType };
