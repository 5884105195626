import _ from 'lodash';

import { dayjs, graphqlUtils, filterSpecUtils, numberUtils } from '@tw/util';
import {
  Enrollment,
  StudentTerm,
  StudentTermQueryVariables,
  StudentTermPaginatedData,
  StudentTermPaginatedRawData,
  StudentTermRawQueryVariables,
  GraphQLEdge,
  RawStudentTerm,
} from '@tw/types';

import studentModel from '../student/studentModel';

export const formatStudentTermVariables = (
  variables: StudentTermQueryVariables,
): StudentTermRawQueryVariables => {
  if (!variables) {
    return {};
  }

  const {
    after,
    dateRange,
    isDeleted,
    isWhiteListed,
    searchString,
    studentId,
    studentTermId,
    studentYearId,
    termIds,
    ...rest
  } = variables;

  return {
    after: after || undefined,
    checkinStartDay: dateRange ? dayjs(dateRange[0]).format() : undefined,
    checkinEndDay: dateRange ? dayjs(dateRange[1]).format() : undefined,
    isDeleted: !_.isNil(isDeleted) ? graphqlUtils.createBooleanQuery(isDeleted) : undefined,
    searchStudents: searchString,
    studentId: studentId
      ? filterSpecUtils.getFilterFromWhiteListSelection(
          studentId,
          _.isNil(isWhiteListed) || isWhiteListed,
        )
      : undefined,
    studentTermId: studentTermId ? graphqlUtils.createArrayIncludesQuery(studentTermId) : undefined,
    studentYearId: studentYearId ? graphqlUtils.createArrayIncludesQuery(studentYearId) : undefined,
    termId: termIds ? graphqlUtils.createArrayIncludesQuery(termIds) : undefined,
    ...rest,
  };
};

const calculateCreditsAttempted = (enrollments: Enrollment[]) => {
  if (!enrollments) {
    return null;
  }

  let creditsAttempted = 0;
  _.forEach(enrollments, (enrollment) => {
    const safeCredits =
      enrollment?.creditsAttempted || enrollment?.courseSection?.course.credits || 0;
    creditsAttempted += Number(safeCredits);
  });
  return numberUtils.parseNumberToSingleDecimalFloat(creditsAttempted);
};

export const formatStudentTermEdges = (
  studentTermEdges: GraphQLEdge<RawStudentTerm>[],
): StudentTerm[] => {
  const rawStudentTerms = graphqlUtils.convertEdgesToArray(studentTermEdges);
  return _.map(rawStudentTerms, (rawStudentTerm) => {
    const enrollments = graphqlUtils.convertEdgesToArray(rawStudentTerm.enrollments.edges);
    const currentEnrollments = _.filter(enrollments, (enrollment) => !enrollment.droppedDate);
    return {
      ...rawStudentTerm,
      student: rawStudentTerm.student
        ? studentModel.formatRawStudent(rawStudentTerm.student)
        : undefined,
      enrollments: currentEnrollments,
      creditsAttempted: calculateCreditsAttempted(currentEnrollments),
      creditsCompleted: numberUtils.parseNumberToSingleDecimalFloat(
        rawStudentTerm.creditsCompleted,
      ),
    };
  });
};

export const formatStudentTermPaginatedData = (
  rawData: StudentTermPaginatedRawData,
): StudentTermPaginatedData => {
  if (!rawData) {
    return undefined;
  }

  const { edges, ...rest } = rawData.studentTerms;

  return {
    studentTerms: formatStudentTermEdges(edges),
    ...rest,
  };
};
