import { useEffect, useState } from 'react';
import type { CalendarDate } from '@internationalized/date';

import { DatePicker } from '@teamworksdev/react';
import { getTranslation } from '@tw/i18n';
import { datePickerDefaultFormat } from '@tw/modules/Forms/Forms.definitions';
import { DateWidgetSchema } from '@tw/modules/Forms/QuickForm/QuickForm.definitions';

import { Date, DateContainer } from './TWFormBuilderField/TWFormBuilderField.styles';

interface TWQuickFormDateProps {
  schema: DateWidgetSchema;
  onChange: (value: string) => void;
  value: string;
  formContext: { previewTemplate: boolean };
}

const TWQuickFormDate = ({ schema, onChange, value, formContext }: TWQuickFormDateProps) => {
  const { dateFormat } = schema;
  const { previewTemplate } = formContext;
  const [date, setDate] = useState<CalendarDate | null>(null);

  const handleOnChange = (event: CalendarDate | null) => {
    if (!event) {
      setDate(null);
      onChange('');
    } else {
      const day = event.day.toString().padStart(2, '0');
      const month = event.month.toString().padStart(2, '0');
      const year = event.year.toString();
      const combined =
        dateFormat === datePickerDefaultFormat
          ? `${month}/${day}/${year}`
          : `${day}/${month}/${year}`;
      setDate(event);
      onChange(combined);
    }
  };

  useEffect(() => {
    if (!value) setDate(null);
  }, [value]);

  return (
    <DateContainer isRange={false} previewTemplate={previewTemplate}>
      <Date>{getTranslation('date', 1)}</Date>
      <DatePicker
        value={date}
        onChange={(event) => handleOnChange(event)}
        closeOnSelect
        allowUserInput
        isDisabled={previewTemplate}
        dateFormat={{ month: 'numeric', day: 'numeric', year: 'numeric' }}
        locale={dateFormat === datePickerDefaultFormat ? 'en-US' : 'en-GB'}
      />
    </DateContainer>
  );
};

export default TWQuickFormDate;
