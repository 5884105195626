// NOTE: No available options with ant'd 3.x modals to get provided context
// Importing theme and using manually for the time being.
import styled from '@emotion/styled';
import { theme as staticTheme } from '@tw/components/utils';
import TWIcon from '../../../components.web/TWIcon';

interface SvgContainerProps {
  color?: string;
}

export const SvgBackgroundNeutral = styled.div<SvgContainerProps>(({ theme }) => ({
  backgroundColor: theme.colors.highlightBold,
  borderRadius: '50%',
}));

export const SvgBackgroundSuccess = styled.div<SvgContainerProps>(({ theme }) => ({
  backgroundColor: theme.colors.positive,
  borderRadius: '50%',
}));

export const IconNeutralColor = styled(TWIcon)(({ theme }) => ({
  color: theme.colors.secondary,
  padding: '.6rem',
}));

export const IconSuccessColor = styled(TWIcon)(({ theme }) => ({
  color: theme.colors.white,
  padding: '.6rem',
}));

type ModalContentStylesProps = {
  hasIcon: boolean;
};

export const ModalContentStyles = styled.span<ModalContentStylesProps>(({ hasIcon, theme }) => ({
  fontSize: theme.fontUnit * 3.5,
  color: theme.colors.secondary,
  whiteSpace: 'pre-wrap',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: hasIcon ? '35px' : undefined,

  '.ant-radio-inner::after': {
    display: 'inherit', // fix weird behavior with radio
  },
}));

export const ModalTitleStyles = styled.span(({ theme }) => ({
  fontSize: theme.fontUnit * 5.5,
  color: theme.colors.text,
}));

export const warningButtonStyles = { backgroundColor: staticTheme.colors.negative, border: 'none' };
export const cancelButtonStyles = { fontSize: '16px', color: staticTheme.colors.buttonText };
