import { TWInputSelect } from '@tw/components';
import { useTranslator } from '@tw/hooks';

interface TWQuickFormDropdownProps {
  disabled: boolean;
  value: string;
  options: {
    enumOptions?: {
      label: string;
      value: string;
    }[];
  };
  schema: {
    title?: string;
    isEmptyQuestion?: boolean;
  };
  onChange: (value: string) => void;
  formContext: { containerId?: string };
}

const TWQuickFormDropdown = ({
  value,
  disabled,
  options: { enumOptions },
  onChange,
  formContext,
}: TWQuickFormDropdownProps) => {
  const translator = useTranslator();
  const { containerId } = formContext;

  const handleOnChange = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <TWInputSelect
      testID="QuickForm:Question:Dropdown"
      placeholder={translator.t('forms.selectValue')}
      value={value}
      showSearch={false}
      disabled={disabled}
      itemList={enumOptions}
      onChange={handleOnChange}
      popupContainerId={containerId}
      width="100%"
    />
  );
};

export default TWQuickFormDropdown;
