import _ from 'lodash';
import { emptyObjectFn } from '@tw/constants';

const deviceUtils = {
  // Not implemented for web: only mobile has/needs device metadata for auth tokens
  getDeviceMetaData: emptyObjectFn,

  // We are treating web as 'always online' for now, until we fully implement offline mode for web
  // See TW-13271 (https://teamworks.atlassian.net/browse/TW-13271)
  mightBeOffline: () => false,

  isWeb: () => true,

  getOS: () => {
    const { userAgent } = window.navigator;
    const { platform } = window.navigator;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (_.includes(macosPlatforms, platform)) {
      os = 'Mac';
    } else if (_.includes(iosPlatforms, platform)) {
      os = 'iOS';
    } else if (_.includes(windowsPlatforms, platform)) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  },

  supportsNativeEmojis: () => {
    const os = deviceUtils.getOS();
    const consistentlySupportedPlatforms = ['iOS', 'Mac', 'Android'];

    return _.includes(consistentlySupportedPlatforms, os);
  },
};

export default deviceUtils;
