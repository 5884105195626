const travelLettersCoursesQuery = `
query(
  $studentTermIds: [Int]
  $occurrenceBetweenStart: TWFilter_DateTime
  $occurrenceBetweenEnd: TWFilter_DateTime
) {
  enrollmentAttendances(
    studentTermIds: $studentTermIds
    occurrenceStartDateTime: $occurrenceBetweenStart
    occurrenceEndDateTime: $occurrenceBetweenEnd
  ) {
    edges {
      node {
        enrollmentAttendanceId
        occurrenceStartDateTime
        occurrenceEndDateTime
        enrollment {
          studentTermId
          studentTerm {
            termId
            student {
              fullName
              studentId
            }
          }
          courseSection {
            courseSectionId
            shortCode
            course {
              label
            }
            professor {
              fullName
              lastName
              emailAddress
            }
          }
        }
      }
    }
  }
}
`;

export default { travelLettersCoursesQuery };
