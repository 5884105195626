import styled from '@emotion/styled';
import { DatePicker } from 'antd';
import TWIcon from '../../TWIcon';

export const DateTimeWrapper = styled.div({
  display: 'flex',
});

export const RightArrow = styled(TWIcon)(({ theme }) => ({
  marginLeft: theme.baseUnit * 0.5,
  marginRight: theme.baseUnit * 0.5,
  marginTop: theme.baseUnit,
  fill: theme.colors.secondary,
}));

export const WideDatePicker = styled(DatePicker)(({ theme }) => ({
  width: theme.baseUnit * 23,
}));
