import { Button } from 'antd';
import _ from 'lodash';
import { ReactText, useMemo } from 'react';

import { getTranslation } from '@tw/i18n';

import {
  TWFlexContainer,
  TWHeading4,
  TWHeading6,
  TWLoadingMask,
  TWSpacingContainer,
  TWTextDefault,
} from '../../../presentational';
import TWInputCheckboxColor from '../TWInputCheckboxColor';
import { CheckboxActionsContainer, CheckboxContainer } from './TWInputCheckboxContainer.styles';

interface Item {
  key?: string;
  personId: ReactText;
  fullName: string;
}

interface TWInputCheckboxContainerProps {
  isLoading?: boolean;
  onSelectionUpdate: (selections: ReactText[]) => void;
  selectedItemKeys: ReactText[];
  titleLabel?: string;
  subTitleLabel?: string;
  showCount?: boolean;
  noItemsLabel?: string;
  itemList: Item[];
  showCountFromTotal?: boolean;
  keyExtractor?: (item: Item) => string;
  testID?: string;
}

export const TWInputCheckboxContainer = ({
  titleLabel = '',
  subTitleLabel = '',
  noItemsLabel = '',
  showCountFromTotal = true,
  showCount = true,
  itemList,
  selectedItemKeys,
  onSelectionUpdate,
  isLoading = false,
  keyExtractor,
  testID,
}: TWInputCheckboxContainerProps) => {
  const allItemIds = useMemo(() => _.map(itemList, ({ personId }) => personId), [itemList]);
  const itemCount = _.size(itemList);
  const selectedCount = _.size(selectedItemKeys);

  const countKey = showCountFromTotal ? 'xOfYSelected' : 'xSelected';
  const countString = getTranslation(countKey, {
    x: selectedCount,
    y: itemCount,
    smart_count: selectedCount,
  });

  const selectAll = () => onSelectionUpdate(allItemIds);
  const deselectAll = () => onSelectionUpdate([]);

  return (
    <TWSpacingContainer twMarginTop={1}>
      {titleLabel && <TWHeading6 customStyles={{ fontWeight: 'bold' }}>{titleLabel}</TWHeading6>}
      {subTitleLabel && (
        <TWTextDefault twColor="secondary" twMarginTop={2} twMarginBottom={2}>
          {subTitleLabel}
        </TWTextDefault>
      )}
      <CheckboxContainer isEmpty={itemCount === 0} twPadding={1} justify="center">
        {isLoading && <TWLoadingMask />}
        {itemCount > 0 ? (
          itemList.map((item) => (
            <TWInputCheckboxColor
              id={_.isFunction(keyExtractor) ? keyExtractor(item) : item.key}
              testID={testID}
              key={_.isFunction(keyExtractor) ? keyExtractor(item) : item.key}
              label={item.fullName}
              isChecked={_.includes(selectedItemKeys, item.personId)}
              onClick={() => {
                let updatedSelectedKeys;

                if (_.includes(selectedItemKeys, item.personId)) {
                  updatedSelectedKeys = _.without(selectedItemKeys, item.personId);
                } else {
                  updatedSelectedKeys = [...selectedItemKeys, item.personId];
                }

                onSelectionUpdate(updatedSelectedKeys);
              }}
            />
          ))
        ) : (
          <TWFlexContainer alignSelf="stretch" align="center" justify="center">
            <TWHeading4 twColor="text">{!isLoading && noItemsLabel}</TWHeading4>
          </TWFlexContainer>
        )}
      </CheckboxContainer>
      <CheckboxActionsContainer row justify="space-between">
        {showCount && <TWTextDefault twColor="secondary">{countString}</TWTextDefault>}
        <CheckboxActionsContainer row justify="flex-end">
          <Button
            type="link"
            size="small"
            onClick={selectAll}
            disabled={itemCount === 0 || selectedCount === itemCount}
          >
            {getTranslation('selectAll')}
          </Button>
          <Button type="link" size="small" onClick={deselectAll} disabled={selectedCount === 0}>
            {getTranslation('clearSelection', { smart_count: 2 })}
          </Button>
        </CheckboxActionsContainer>
      </CheckboxActionsContainer>
    </TWSpacingContainer>
  );
};
