import { forwardRef } from 'react';
import * as React from 'react';

import { TWFlexContainer } from '../..';
import { SizeScalingType } from '../../../utils';
import { defaultListItemSizeScaling } from '../utils';
import { Container } from './TWListItem.styles';

interface TWListItemProps {
  testID?: string;
  size?: SizeScalingType;
  onClick?: () => void;
  isOutlined?: boolean;
  selectable?: boolean;
  isSelected?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  leftNode?: React.ReactNode;
  rightNode?: React.ReactNode;
}

const TWListItem: React.FC<TWListItemProps> = forwardRef<HTMLButtonElement, TWListItemProps>(
  (
    {
      size,
      testID,
      onClick,
      isSelected,
      selectable = true,
      isOutlined,
      leftNode = null,
      rightNode = null,
    },
    ref,
  ) => {
    const { padding } = defaultListItemSizeScaling[size];

    return (
      <Container
        twPadding={padding}
        isOutlined={isOutlined}
        isSelected={isSelected}
        role="button"
        data-testid={testID}
        onClick={onClick}
        selectable={selectable}
        ref={ref}
      >
        {leftNode && <TWFlexContainer flex={1}>{leftNode}</TWFlexContainer>}
        {rightNode && <TWFlexContainer flex={1}>{rightNode}</TWFlexContainer>}
      </Container>
    );
  },
);

TWListItem.defaultProps = {
  size: 'default',
  isOutlined: false,
  isSelected: false,
};

export default TWListItem;
