import { FormAssignmentNode } from '@tw/generated';

export const MAX_LENGTH = 22;

export interface TWQuickFormDescriptionFieldProps {
  id: string;
  description?: string;
  assignment?: FormAssignmentNode;
  currentPerson?: string;
  hideAssignedOn?: boolean;
}
