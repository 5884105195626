/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ComplianceBulkConflictQueryVariables = Types.Exact<{
  occurrenceChecks:
    | Array<Types.InputMaybe<Types.BulkAppointmentConflictInput>>
    | Types.InputMaybe<Types.BulkAppointmentConflictInput>;
}>;

export type ComplianceBulkConflictQuery = {
  bulkTmpConflicts?: Array<{
    appointmentOccurrenceId?: string | null;
    conflicts?: Array<{
      limitValue?: number | null;
      conflictValue?: number | null;
      explanation?: string | null;
      rule?: { bylaw?: string | null; label?: string | null; tmpRuleId?: number | null } | null;
      actions?: { notifyCompliance?: boolean | null; commentRequired?: boolean | null } | null;
    } | null> | null;
  } | null> | null;
};

export const ComplianceBulkConflictDocument = gql`
  query ComplianceBulkConflict($occurrenceChecks: [BulkAppointmentConflictInput]!) {
    bulkTmpConflicts(occurrenceChecks: $occurrenceChecks) {
      appointmentOccurrenceId
      conflicts {
        rule {
          bylaw
          label
          tmpRuleId
        }
        actions {
          notifyCompliance
          commentRequired
        }
        limitValue
        conflictValue
        explanation
      }
    }
  }
`;

/**
 * __useComplianceBulkConflictQuery__
 *
 * To run a query within a React component, call `useComplianceBulkConflictQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceBulkConflictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceBulkConflictQuery({
 *   variables: {
 *      occurrenceChecks: // value for 'occurrenceChecks'
 *   },
 * });
 */
export function useComplianceBulkConflictQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplianceBulkConflictQuery,
    ComplianceBulkConflictQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ComplianceBulkConflictQuery, ComplianceBulkConflictQueryVariables>(
    ComplianceBulkConflictDocument,
    options,
  );
}
export function useComplianceBulkConflictLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplianceBulkConflictQuery,
    ComplianceBulkConflictQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ComplianceBulkConflictQuery, ComplianceBulkConflictQueryVariables>(
    ComplianceBulkConflictDocument,
    options,
  );
}
export type ComplianceBulkConflictQueryHookResult = ReturnType<
  typeof useComplianceBulkConflictQuery
>;
export type ComplianceBulkConflictLazyQueryHookResult = ReturnType<
  typeof useComplianceBulkConflictLazyQuery
>;
export type ComplianceBulkConflictQueryResult = Apollo.QueryResult<
  ComplianceBulkConflictQuery,
  ComplianceBulkConflictQueryVariables
>;
