import styled from '@emotion/styled';

export const Container = styled.div(({ theme }) => ({
  width: 600,
  padding: theme.baseUnit / 2,
  backgroundColor: theme.colors.white,
}));

export const LiOdd = styled.div(({ theme }) => ({
  padding: theme.baseUnit / 2,
  backgroundColor: theme.colors.white,
}));

export const LiEven = styled.div(({ theme }) => ({
  padding: theme.baseUnit / 2,
  backgroundColor: theme.colors.grayBackground,
}));

export const Title = styled.div(({ theme }) => ({
  // custom style to match the chart-js charts
  fontSize: theme.fontUnit * 6,
  fontFamily: theme.fontFamily,
  fontWeight: theme.fontWeight.bold,
  color: '#666',
  width: '100%',
  textAlign: 'center',
}));

export const List = styled.div(({ theme }) => ({
  padding: theme.baseUnit / 2,
}));
