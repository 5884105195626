import _ from 'lodash';

import { CourseQueryFilters, CourseRawQueryFilters } from '@tw/types';
import { graphqlUtils } from '@tw/util';

export const normalizeCourseVariables = ({
  courseId,
  ...rest
}: CourseQueryFilters): CourseRawQueryFilters => ({
  courseId: !_.isEmpty(courseId) ? graphqlUtils.createArrayIncludesQuery(courseId) : undefined,
  ...rest,
});
