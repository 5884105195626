import { datadogRum } from '@datadog/browser-rum';
import { AxleQuery, useAxleLazyQuery, useViewerDataLazyQuery } from '@tw/generated';
import { useActiveTabs, useBroadcastState } from '@tw/hooks';
import localStorage from '@tw/services/localStorage';
import { gaUtils, pendoUtils } from '@tw/util';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { isEqual } from 'lodash';
import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useRef } from 'react';
import { CurrentUser, ViewContextType, ViewerType } from './viewer.definitions';

const ViewerContext = createContext<ViewContextType | null>(null);

const defaultAxleState: AxleQuery['viewer'] = {
  personInfo: {
    webAccess: [],
  },
  sharedNavbar: {
    topColor: '#000000',
    bottomColor: '#000000',
    imageUrl: null,
    targetUrls: [],
    teamLogos: [],
    inactivityTimeout: undefined,
  },
  caraInfo: {
    isCaraEnabled: false,
    adequateChangeNoticeHours: undefined,
  },
};

const ViewerProvider = ({
  children,
  onViewerUpdate,
}: PropsWithChildren<{
  onViewerUpdate?: () => void;
}>) => {
  const ldClient = useLDClient();

  const getTabCount = useActiveTabs();
  const isRecordingSession = useRef(false);

  const [userData, setBroadcastUser] = useBroadcastState<CurrentUser>(
    'tw-user-session',
    localStorage.getCurrentUser(),
  );
  const { personId, teamId, orgId } = userData ?? {};
  const previousUser = useRef<CurrentUser>();
  const setUser = useCallback(
    (value: CurrentUser) => {
      if (value) {
        // strip out GraphQL junk
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...user } = value;
        localStorage.setCurrentUser(user);
        onViewerUpdate?.();
        setBroadcastUser(user);
      } else {
        localStorage.removeCurrentUser();
        onViewerUpdate?.();
        setBroadcastUser(null);
      }
    },
    [setBroadcastUser, onViewerUpdate],
  );

  useEffect(() => {
    const changeLDUser = async () => {
      onViewerUpdate?.();
      if (ldClient && orgId && personId) {
        try {
          const orgIdInt = parseInt(orgId, 10);
          if (Number.isNaN(orgIdInt)) return;
          const ldUser = { key: personId, custom: { orgId: orgIdInt } };
          await ldClient.identify(ldUser);
        } catch (err) {
          console.error(err);
        }
      }
    };
    changeLDUser();
  }, [ldClient, onViewerUpdate, orgId, personId]);

  const [fetchViewer, { loading: viewerLoading, data, error }] = useViewerDataLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [fetchAxle, { data: axleData }] = useAxleLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  // Are we making an initial fetch?
  const isInitialFetch = useRef(!data);
  const loading = isInitialFetch.current || viewerLoading;

  useEffect(() => {
    const fetch = () => {
      isInitialFetch.current = false;
      previousUser.current = userData;
      onViewerUpdate?.();
      // if (userData) localStorage.setCurrentUser(userData);
      fetchViewer();
      fetchAxle();
    };

    if (isInitialFetch.current) {
      // initial fetch
      fetch();
    } else if (
      !isInitialFetch.current &&
      !isEqual(previousUser.current, userData) &&
      !!userData &&
      !loading
    ) {
      // user change
      fetch();
    }
  }, [userData, loading, fetchViewer, fetchAxle, onViewerUpdate]);

  const context = useMemo<ViewContextType>(() => {
    const viewerData = (data?.viewer ?? {}) as ViewerType;
    const axleViewer = axleData?.viewer ?? defaultAxleState;

    return {
      ...viewerData,
      ...axleViewer,
      setUser,
      getTabCount,
    };
  }, [axleData, data, setUser, getTabCount]);

  useEffect(() => {
    if (!loading && !error && data) {
      if (process.env.GOOGLE_ANALYTICS_ENABLED) {
        gaUtils.loadGa();
      }

      if (process.env.PENDO_ENABLED) {
        pendoUtils.loadPendo();
        pendoUtils.setMetadata(data?.viewer);
      }

      if (process.env.DD_RUM_ENABLED && personId) {
        datadogRum.setUser({
          id: personId,
          name: data?.viewer?.username ?? undefined,
          email: data?.viewer?.person?.emailAddress ?? undefined,
          teamId,
          orgId,
        });
        if (!isRecordingSession.current) {
          datadogRum.startSessionReplayRecording();
          isRecordingSession.current = true;
        }
      }
    }
  }, [loading, error, data, orgId, personId, teamId]);

  if (error) {
    // Propagate error, will hit error boundary and render error page
    throw error;
  }

  if (!data) {
    return null;
  }

  return <ViewerContext.Provider value={context}>{children}</ViewerContext.Provider>;
};

export { ViewerContext, ViewerProvider };
