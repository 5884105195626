import { gql } from '@apollo/client';

export const ProfessorUpdate = gql`
  mutation ProfessorUpdate($id: ID!, $input: ProfessorUpdateInput!) {
    professorUpdate(id: $id, input: $input) {
      aC2Professor {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
