import styled from '@emotion/styled';
import { Row } from 'antd';

export const TWDrawerHeaderProfileContainer = styled.div(({ theme }) => ({
  background: theme.brandColors.headerLinkColor,
  padding: `${theme.fontUnit * 8}px ${theme.fontUnit * 10}px`,
  textAlign: 'center',
  marginBottom: 0,
  // TODO: fix when https://teamworks.atlassian.net/browse/TW-15103 complete
  borderBottom: 'solid 1px rgba(66, 81, 130, 0.2)',
}));

export const TWDrawerHeaderProfileAvatar = styled(Row)(({ theme }) => ({
  marginBottom: theme.fontUnit * 4,
}));

export const TWDrawerHeaderProfileTitle = styled.div(({ theme }) => ({
  color: theme.brandColors.primaryLighterColor,
  overflow: 'hidden',
}));
