import { Col } from 'antd';
import { withRouter } from 'react-router-dom';

import { TWHeading2 } from '../../presentational/typography';
import TWLayout from '../layout/TWLayout';
import { TWRouteMapProps } from './propTypes';
import TWRouterRendererSwitch from './TWRouterRendererSwitch';

interface TWRouterHeaderProps {
  routeMap: TWRouteMapProps;
  tabHeader?: string;
}

const TWRouterHeader = ({
  tabHeader = '',
  routeMap,
  ...allUnrecognizedProps
}: TWRouterHeaderProps) => (
  <TWLayout.Header>
    <Col span={8}>
      <TWHeading2 data-testid="PageHeader">{tabHeader}</TWHeading2>
    </Col>
    <Col span={16}>
      <TWRouterRendererSwitch
        routeMap={routeMap}
        renderKey="actions"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...allUnrecognizedProps}
      />
    </Col>
  </TWLayout.Header>
);

export default withRouter(TWRouterHeader);
