/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignedUrlMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.SignedUrlInput>;
}>;

export type SignedUrlMutation = { signedUrl: { url?: string | null } };

export const SignedUrlDocument = gql`
  mutation SignedUrl($input: SignedUrlInput) {
    signedUrl(input: $input) {
      url
    }
  }
`;
export type SignedUrlMutationFn = Apollo.MutationFunction<
  SignedUrlMutation,
  SignedUrlMutationVariables
>;

/**
 * __useSignedUrlMutation__
 *
 * To run a mutation, you first call `useSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signedUrlMutation, { data, loading, error }] = useSignedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<SignedUrlMutation, SignedUrlMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignedUrlMutation, SignedUrlMutationVariables>(
    SignedUrlDocument,
    options,
  );
}
export type SignedUrlMutationHookResult = ReturnType<typeof useSignedUrlMutation>;
export type SignedUrlMutationResult = Apollo.MutationResult<SignedUrlMutation>;
export type SignedUrlMutationOptions = Apollo.BaseMutationOptions<
  SignedUrlMutation,
  SignedUrlMutationVariables
>;
