import styled from '@emotion/styled';
import { addColorProperties, BoxComponentProps } from '@tw/components/utils';

interface SecondaryNavContainerProps {
  sticky?: boolean;
}

export const SecondaryNavContainer = styled.div<BoxComponentProps & SecondaryNavContainerProps>(
  ({ theme, sticky = false }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    boxShadow: `inset 0px -2px 0px -1px ${theme.colors.border}`,
    height: theme.baseUnit * 7,
    padding: `0 ${theme.baseUnit}px`,
    position: sticky ? 'sticky' : undefined,
    top: sticky ? 0 : undefined,
    borderLeft: sticky ? `1px solid ${theme.colors.border}` : undefined,
    borderBottom: `1px solid ${theme.colors.border}`,
  }),
);

export const SecondaryNavButton = styled.nav(({ theme }) => {
  const { colors, brandColors } = theme;

  const pseudoStyles = {
    color: colors.text,
    textDecoration: 'none',
  };

  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'stretch',
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    'a, button': {
      border: 'none',
      backgroundColor: 'transparent',
      display: 'flex',
      whiteSpace: 'nowrap',
      color: colors.secondary,
      textDecoration: 'none',
      transition: theme.cssValues.transition,
      alignSelf: 'stretch',
      alignItems: 'center',
      fontFamily: theme.fontFamily.default,
      fontStyle: 'normal',
      fontWeight: theme.fontWeight.regular,
      padding: theme.baseUnit * 2,
      flexGrow: 1,
      justifyContent: 'center',
      ':visited': pseudoStyles,
      ':active': pseudoStyles,
      ':focus': pseudoStyles,
      ':hover': {
        ...pseudoStyles,
        backgroundColor: addColorProperties(colors.primaryButtonBackground ?? '', 0.1),
      },
    },
    'a.active, button.active': {
      color: colors.text,
      fontWeight: theme.fontWeight.semibold,
      borderBottom: `2px solid ${brandColors.primaryColor}`,
    },
    button: {
      cursor: 'pointer',
    },
  };
});
