/**
 * This file provides static values about how the uiSettingsConfig interacts with and exists
 * within the Redux store.
 */

const storeKey = 'uiSettingsConfig';

export default {
  storeKey,
  rootSelector: (store) => store[storeKey],

  layerNames: ['baseDefault', 'userState', 'debugOverride'],
};
