import styled from '@emotion/styled';
import { Col, Row } from 'antd';

export const ContactContainer = styled.div({
  overflow: 'hidden',
});

export const ContactItem = styled(Row)({
  flexWrap: 'nowrap',
  textOverflow: 'ellipsis',
});

export const ContactLabel = styled(Col)(({ theme }) => ({
  marginRight: theme.baseUnit * 0.5,
}));

export const ContactValue = styled(Col)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});
