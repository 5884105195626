import _ from 'lodash';

import { graphqlUtils, formatUtils } from '@tw/util';
import {
  Enrollment,
  CourseSectionQueryFilters,
  CourseSectionRawQueryFilters,
  CourseSectionsRawPaginatedData,
  RawCourseSection,
  CourseSection,
  CourseSectionsPaginatedData,
} from '@tw/types';

const normalizeVariables = (variables: CourseSectionQueryFilters): CourseSectionRawQueryFilters => {
  if (!variables) {
    return null;
  }

  const {
    after,
    first,
    courseId,
    courseSectionId,
    courseSectionSchedulesDay,
    courseSubjectId,
    studentIds,
    teamIds,
    termId,
    ...rest
  } = variables;

  return {
    ...rest,
    after: after || undefined,
    first: first || undefined,
    courseId: courseId ? graphqlUtils.createArrayIncludesQuery(courseId) : undefined,
    courseSectionId: courseSectionId
      ? graphqlUtils.createArrayIncludesQuery(courseSectionId)
      : undefined,
    courseSectionSchedulesDay: courseSectionSchedulesDay
      ? graphqlUtils.createArrayIncludesQuery(_.castArray(courseSectionSchedulesDay))
      : undefined,
    courseSubjectId: courseSubjectId
      ? graphqlUtils.createArrayIncludesQuery(courseSubjectId)
      : undefined,
    studentIds: !_.isEmpty(studentIds) ? studentIds : undefined,
    teamIds,
    termId: termId ? graphqlUtils.createArrayIncludesQuery(_.castArray(termId)) : undefined,
  };
};

const filterOutDroppedEnrollments = (enrollments: Enrollment[]): Enrollment[] =>
  _.filter(enrollments, (enrollment) => !enrollment.droppedDate);

export const normalizeRawCourseSectionData = (
  rawCourseSection: RawCourseSection,
): CourseSection => {
  if (!rawCourseSection) {
    return null;
  }
  const { courseSectionSchedules, enrollments, ...rest } = rawCourseSection;

  const enrollmentEdges = enrollments?.edges
    ? filterOutDroppedEnrollments(graphqlUtils.convertEdgesToArray(enrollments.edges))
    : undefined;

  return {
    ...rest,
    courseSectionSchedules: courseSectionSchedules
      ? graphqlUtils.convertEdgesToArray(courseSectionSchedules.edges)
      : undefined,
    enrollments: enrollments
      ? {
          edges: enrollmentEdges,
          pageInfo: enrollments.pageInfo,
          totalCount: enrollmentEdges ? _.size(enrollmentEdges) : enrollments.totalCount,
        }
      : undefined,
  };
};

const normalizeRawPaginatedCourseSectionData = (
  data: CourseSectionsRawPaginatedData,
): CourseSectionsPaginatedData => {
  if (!data) {
    return null;
  }

  const { courseSections } = data;
  const { edges, pageInfo, totalCount } = courseSections;
  return {
    courseSections: _.map(graphqlUtils.convertEdgesToArray(edges), normalizeRawCourseSectionData),
    pageInfo,
    totalCount,
  };
};

export const formatCourseSectionSchedules = (courseSectionSchedulesList) => {
  const sortedCourseSectionSchedules = formatUtils.sortByDayOfWeek(
    courseSectionSchedulesList,
    'day',
  );

  return _.map(sortedCourseSectionSchedules, (schedule, index) => ({
    ...schedule,
    dayAbbreviation: formatUtils.getDayOfWeekAbbreviation(schedule.day),
    key: `COURSE_SECTION_SCHEDULE_${index}`,
  }));
};

export default {
  normalizeVariables,
  normalizeRawCourseSectionData,
  normalizeRawPaginatedCourseSectionData,
};
