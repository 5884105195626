import { gql } from '@apollo/client';

// If you change this file, you MUST update the hash in EXTERNAL_QUERY_WHITELIST
export const FetchProfessorSurveyAssignmentsExternal = gql`
  query FetchExternalProfessorSurveyAssignments(
    $uuid: String
    $status: TWFilter_ProfessorSurveyAssignmentStatus
  ) {
    externalProfessorSurveyAssignments(uuid: $uuid, status: $status) {
      totalCount
      edges {
        cursor
        node {
          professorSurveyAssignmentId: pk
          status
          externalAccessLinkId
          courseSectionId
          courseSection {
            term {
              termId
              startDate
              endDate
              label
            }
            enrollments {
              totalCount
            }
            courseSectionSchedules {
              edges {
                node {
                  startTime
                  endTime
                  day
                  location
                }
              }
            }
            professor {
              firstName
              lastName
              emailAddress
              phoneNumber
            }
            course {
              label
              description
              subject {
                label
                description
                shortCode
              }
            }
            shortCode
            startDate
            endDate
          }
          professorSurveyReports {
            totalCount
            edges {
              cursor
              node {
                professorSurveyReportId: pk
                gradeId
                isAtRisk
                comment
                absenceCount
                grade {
                  label
                }
                enrollment {
                  studentTerm {
                    student {
                      lastName
                      preferredName
                      pictureDownloadUrl
                      personId
                      fullName
                      person {
                        firstName
                        teamLabels
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
