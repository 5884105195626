import { memo, useState } from 'react';
import { InputNumber } from 'antd';
import { TWEditableInputNumberProps } from './TWEditableInputNumber.definitions';
import TWInlineEditField from '../../presentational/TWInlineEditField';

const TWEditableInputNumber = ({
  containerId,
  onChange,
  value = 0,
  testID = '',
  ...twInlineEditFieldProps
}: TWEditableInputNumberProps) => {
  const [inputValue, setInputValue] = useState(value);

  function handleChange(eventValue) {
    setInputValue(eventValue);
  }

  function handleSave() {
    onChange(inputValue);
  }

  function handleCancel() {
    setInputValue(value);
  }

  return (
    <TWInlineEditField
      {...twInlineEditFieldProps}
      containerId={containerId}
      onCancel={handleCancel}
      onSave={handleSave}
      onChange={handleChange}
      hideButtons
      readComponent={<>{value || 0}</>}
      keyDownPropName="onKeyDown"
      writeComponent={
        <InputNumber
          autoFocus
          data-testid={testID}
          value={inputValue}
          {...twInlineEditFieldProps}
        />
      }
    />
  );
};

export default memo(TWEditableInputNumber);
