import styled from '@emotion/styled';
import { MdClose } from '@teamworksdev/icons';
import { IconButton } from '@teamworksdev/react';
import { colors } from '@teamworksdev/style';
import { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { useResizeObserver } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';

import VideoPlayer from '../VideoPlayer';
import { VideoPlayerModalProps, WistiaMediaData } from '../Videos.definitions';

const VideoPlayerModal = ({
  video,
  isVisible,
  onVideoPlay,
  closeVideoPlayModal,
}: VideoPlayerModalProps) => {
  const [viewWidth, setViewWidth] = useState(getViewWidth());
  const [viewHeight, setViewHeight] = useState(getViewHeight());
  const [aspectRatio, setAspectRatio] = useState(9 / 16);

  // On video ready, set the aspect ratio and size constraints
  const onVideoReady = useCallback((media: WistiaMediaData) => {
    const originalFile = media.assets?.find((asset) => asset.type === 'OriginalFile');

    if (originalFile) {
      const ratio = originalFile.height / originalFile.width;
      setAspectRatio(ratio);
    }
  }, []);

  const handleClose = useCallback(() => {
    closeVideoPlayModal();

    // Reset state
    setAspectRatio(9 / 16);
    setViewWidth(getViewWidth());
    setViewHeight(getViewHeight());
  }, [closeVideoPlayModal]);

  if (!video) {
    return null;
  }

  if (!isVisible) {
    return null;
  }

  // Set max player width / height to be smaller than viewport
  const maxPlayerWidth = viewWidth * 0.8;
  const maxPlayerHeight = viewHeight * 0.9;

  let playerHeight = maxPlayerHeight;
  let playerWidth = playerHeight / aspectRatio;

  if (playerWidth > maxPlayerWidth) {
    playerWidth = maxPlayerWidth;
    playerHeight = playerWidth * aspectRatio;
  }

  return (
    <Wrapper
      onBackdropClick={handleClose}
      onResize={(width, height) => {
        setViewWidth(width);
        setViewHeight(height);
      }}
    >
      <VideoContainer style={{ width: playerWidth, backgroundColor: colors.black }}>
        <VideoPlayer
          video={video}
          onVideoReady={onVideoReady}
          onVideoPlay={onVideoPlay}
          width={playerWidth}
          height={playerHeight}
          autoplay
        />
        <CloseButton
          aria-label={getTranslation('close')}
          icon={<MdClose />}
          onClick={handleClose}
        />
      </VideoContainer>
    </Wrapper>
  );
};

interface WrapperProps extends PropsWithChildren {
  onBackdropClick?: () => void;
  onResize?: (width: number, height: number) => void;
}

const Wrapper = ({ children, onBackdropClick, onResize }: WrapperProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useResizeObserver(containerRef, (entry) => onResize?.(entry.width, entry.height));

  return (
    <Container>
      <Backdrop id="videos-play-modal" onClick={onBackdropClick} ref={containerRef} />
      {children}
    </Container>
  );
};

// Get viewport sizes
const getViewHeight = () =>
  Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
const getViewWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 300ms cubic-bezier(0.23, 1, 0.32, 1); /* easeOutQuint */
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0px;
  right: -40px;
`;

export default VideoPlayerModal;
