/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBulletinMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  input: Types.BulletinInput;
}>;

export type UpdateBulletinMutation = {
  bulletinUpdate: {
    bulletin?: {
      bulletinId: string;
      bulletinType: Types.BulletinType;
      label: string;
      externalUrl?: string | null;
      isPinned: boolean;
      createdDate?: any | null;
      content: string;
      expirationDateTime?: any | null;
      headerImageFull?: string | null;
      headerImageThumb?: string | null;
      key?: string | null;
      visibilityCodes?: Array<string | null> | null;
      creator?: {
        pk?: string | null;
        initials?: string | null;
        pictureDownloadUrl?: string | null;
        fullName?: string | null;
      } | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const UpdateBulletinDocument = gql`
  mutation UpdateBulletin($id: ID, $input: BulletinInput!) {
    bulletinUpdate(id: $id, input: $input) {
      bulletin {
        key: pk
        bulletinId
        bulletinType
        label
        externalUrl
        isPinned
        createdDate
        creator {
          pk
          fullName: fullNameNormalOrder
          initials
          pictureDownloadUrl
        }
        content
        expirationDateTime
        headerImageFull
        headerImageThumb
        visibilityCodes: visibility
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type UpdateBulletinMutationFn = Apollo.MutationFunction<
  UpdateBulletinMutation,
  UpdateBulletinMutationVariables
>;

/**
 * __useUpdateBulletinMutation__
 *
 * To run a mutation, you first call `useUpdateBulletinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulletinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulletinMutation, { data, loading, error }] = useUpdateBulletinMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBulletinMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBulletinMutation, UpdateBulletinMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBulletinMutation, UpdateBulletinMutationVariables>(
    UpdateBulletinDocument,
    options,
  );
}
export type UpdateBulletinMutationHookResult = ReturnType<typeof useUpdateBulletinMutation>;
export type UpdateBulletinMutationResult = Apollo.MutationResult<UpdateBulletinMutation>;
export type UpdateBulletinMutationOptions = Apollo.BaseMutationOptions<
  UpdateBulletinMutation,
  UpdateBulletinMutationVariables
>;
