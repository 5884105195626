/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFlags } from '@tw/hooks';
import { useEffect } from 'react';

declare const zE: any;

export default function useZendesk() {
  const { salesforceLiveChatWidget } = useFlags();
  useEffect(() => {
    if (salesforceLiveChatWidget) {
      return;
    }

    if (!document.getElementById('ze-snippet')) {
      const script = document.createElement('script');

      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=f1690bda-a499-4667-93a7-1be093c07285';
      script.async = true;
      script.id = 'ze-snippet';

      script.onload = (): void => {
        // Need to hide the default web widget because we are using our own custom
        // help button #zendesk-help
        zE('messenger', 'hide');

        (window as any).zESettings = {
          messenger: {
            position: { horizontal: 'right', vertical: 'bottom' },
            offset: { horizontal: '40px', vertical: '-12px' },
            navigation: {
              // Disabling chat popout because it triggers the web widget to show
              // and currently we don't want the actual web widget's chat button
              // to show.
              popoutButton: {
                enabled: true,
              },
            },
          },
        };

        zE('messenger:on', 'close', () => {
          zE('messenger', 'hide');
        });
      };

      document.body.appendChild(script);
    }
  }, [salesforceLiveChatWidget]);

  const openZendeskPanel = () => {
    if (salesforceLiveChatWidget) return;

    zE?.('messenger', 'open');
    zE?.('messenger', 'show');
  };

  return {
    openZendeskPanel,
  };
}
