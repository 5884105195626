/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OverlayEventsQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type OverlayEventsQuery = {
  fetchOverlayEvents?: {
    requestId: string;
    applications: Array<{ code: string; name: string } | null>;
    events: Array<{
      title: string;
      startDatetime: any;
      endDatetime: any;
      timezone: string;
      location?: string | null;
      notes?: string | null;
      isClass?: boolean | null;
      isPrivate?: boolean | null;
      allDay?: boolean | null;
      creator?: string | null;
      peopleCount: number;
      overlayEventId: string;
      owningApp: string;
      appointmentType: { id: string; name: string; color: string };
      attendees: {
        hubSelectionCodes: Array<string | null>;
        nonHubAttendees?: Array<string | null> | null;
        peopleCount: number;
      };
      userMetadata?: { canViewDetails: boolean } | null;
      deepLink?: { url: string } | null;
    } | null>;
  } | null;
};

export const OverlayEventsDocument = gql`
  query OverlayEvents($startDateTime: DateTime!, $endDateTime: DateTime!, $selections: [String]!) {
    fetchOverlayEvents(
      startDate: $startDateTime
      endDate: $endDateTime
      selectionCodes: $selections
    ) {
      requestId
      applications {
        code
        name
      }
      events {
        title
        startDatetime
        endDatetime
        timezone
        location
        notes
        isClass
        isPrivate
        allDay
        creator
        peopleCount
        overlayEventId
        owningApp
        appointmentType {
          id
          name
          color
        }
        attendees {
          hubSelectionCodes
          nonHubAttendees
          peopleCount
        }
        userMetadata {
          canViewDetails
        }
        deepLink {
          url
        }
      }
    }
  }
`;

/**
 * __useOverlayEventsQuery__
 *
 * To run a query within a React component, call `useOverlayEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverlayEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverlayEventsQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useOverlayEventsQuery(
  baseOptions: Apollo.QueryHookOptions<OverlayEventsQuery, OverlayEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OverlayEventsQuery, OverlayEventsQueryVariables>(
    OverlayEventsDocument,
    options,
  );
}
export function useOverlayEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OverlayEventsQuery, OverlayEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OverlayEventsQuery, OverlayEventsQueryVariables>(
    OverlayEventsDocument,
    options,
  );
}
export type OverlayEventsQueryHookResult = ReturnType<typeof useOverlayEventsQuery>;
export type OverlayEventsLazyQueryHookResult = ReturnType<typeof useOverlayEventsLazyQuery>;
export type OverlayEventsQueryResult = Apollo.QueryResult<
  OverlayEventsQuery,
  OverlayEventsQueryVariables
>;
