import { SimplePersonProps } from '../people';

import { Course } from './course';
import { Student } from './student';
import { Term } from './term';

export enum SessionRequestStatuses {
  new = 'new',
  scheduled = 'scheduled',
  closed = 'closed',
}

export enum SessionRequestSortEnum {
  /* eslint-disable camelcase */
  ac2_session_request_id_asc = 'ac2_session_request_id_asc',
  ac2_session_request_id_desc = 'ac2_session_request_id_desc',
  course_section_id_asc = 'course_section_id_asc',
  course_section_id_desc = 'course_section_id_desc',
  notes_asc = 'notes_asc',
  notes_desc = 'notes_desc',
  status_asc = 'status_asc',
  status_desc = 'status_desc',
  student_ids_asc = 'student_ids_asc',
  student_ids_desc = 'student_ids_desc',
  subject_id_asc = 'subject_id_asc',
  subject_id_desc = 'subject_id_desc',
  term_id_asc = 'term_id_asc',
  term_id_desc = 'term_id_desc',
  course_id_asc = 'course_id_asc',
  course_id_desc = 'course_id_desc',
  org_id_asc = 'org_id_asc',
  created_date_asc = 'created_date_asc',
  created_date_desc = 'created_date_desc',
  updated_date_asc = 'updated_date_asc',
  updated_date_desc = 'updated_date_desc',
  created_by_asc = 'created_by_asc',
  created_by_desc = 'created_by_desc',
  updated_by_asc = 'updated_by_asc',
  updated_by_desc = 'updated_by_desc',
  /* eslint-enable camelcase */
}

export interface SessionRequest {
  id: string;
  tutorSessionRequestId: number;
  courseId: number;
  course: Course;
  courseSectionId: number;
  createdBy: number;
  createdDateTime: string;
  creator: SimplePersonProps;
  notes: string;
  status: SessionRequestStatuses;
  students: Student[];
  subjectId: number;
  term: Term;
  termId: number;
}

export interface SessionRequestCreateValues {
  courseId: string;
  notes: string;
  orgId: number;
  studentIds: number[];
  termId: number[];
}

export interface SessionRequestCreateRawValues {
  /* eslint-disable camelcase */
  course_id: string;
  notes: string;
  org_id: number;
  status: SessionRequestStatuses.new;
  student_ids: number[];
  term_id: number;
  /* eslint-enable camelcase */
}
