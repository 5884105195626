import type { TWIconWithContentProps } from './TWIconWithContent.definitions';
import {
  TWIconContentColumn,
  TWIconContentIcon,
  TWIconContentRow,
} from './TWIconWithContent.styles';

const TWIconWithContent = ({ iconName, content }: TWIconWithContentProps) => (
  <TWIconContentRow gutter={8} align="middle">
    <TWIconContentColumn>
      <TWIconContentIcon type={iconName} />
    </TWIconContentColumn>
    <TWIconContentColumn>{content}</TWIconContentColumn>
  </TWIconContentRow>
);

export default TWIconWithContent;
