import { datadogRum } from '@datadog/browser-rum';
import { Button, Result } from 'antd';
import { useEffect, useLayoutEffect, useState } from 'react';
import Form, { AjvError, IChangeEvent, Widget } from 'react-jsonschema-form';

import {
  TWButton,
  TWIcon,
  TWInputRange,
  TWLoadingMask,
  TWMessage,
  TWQuickFormCheckBoxes,
  TWQuickFormDate,
  TWQuickFormDateRange,
  TWQuickFormDescriptionField,
  TWQuickFormDropdown,
  TWQuickFormFieldTemplate,
  TWQuickFormRadioButton,
  TWQuickFormTextArea,
  QuickFormNumericInput,
} from '@tw/components';
import { useFormPDF } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { quickFormUtils } from '@tw/util';

import { TemplateStorageProps, PropertiesModelKey, QuestionType } from '../Forms.definitions';
import {
  FormSubmitData,
  ImageWidgetSchema,
  PDFWidgetSchema,
  QuickFormProps,
} from './QuickForm.definitions';

import {
  ButtonContainer,
  FooterContainer,
  Image,
  ImageDiv,
  OpenInTabText,
  PageCount,
  PdfImgContainer,
  StyledImg,
} from './QuickForm.styles';

const handleViewPreview = (source: string) => {
  if (source) {
    window.open(source, '_new')?.focus();
  } else {
    datadogRum.addError(new Error(`Error - open pdf in tab src: ${source}`));
    TWMessage.error(getTranslation('forms.formBuilder.addPdf.openInTabError'));
  }
};

const ImageWidget = ({ schema }: { schema: ImageWidgetSchema }) => (
  <ImageDiv>
    <Image alt={schema.title} src={schema.src} />
  </ImageDiv>
);

const PDFWidget = ({
  schema,
  formContext,
}: {
  schema: PDFWidgetSchema;
  formContext: { previewTemplate: boolean };
}) => {
  const { pdfLength, src, title, snapshot } = schema;
  const { previewTemplate } = formContext;
  return (
    <PdfImgContainer previewTemplate={previewTemplate} justify="center" align="center">
      <StyledImg alt={title} src={snapshot} previewTemplate={previewTemplate} />
      {!previewTemplate && (
        <FooterContainer row justify="space-between">
          <PageCount>{getTranslation('forms.formBuilder.addPdf.pages', pdfLength)}</PageCount>
          <ButtonContainer>
            <TWButton
              type="borderless"
              onClick={() => {
                if (src) handleViewPreview(src);
              }}
            >
              <TWIcon type="material-open_in_new" />
              <OpenInTabText>{getTranslation('forms.formBuilder.addPdf.openInTab')}</OpenInTabText>
            </TWButton>
          </ButtonContainer>
        </FooterContainer>
      )}
    </PdfImgContainer>
  );
};

const QuickFormNew = ({
  quickFormTemplate,
  submitSuccess = false,
  onSubmit,
  status,
  children,
  signedDownloadUrl,
  externalName,
  currentPersonName,
  assignment,
  previewTemplate = false,
  hideAssignedBy = false,
  hideAssignedOn = false,
  quickFormPreview = false,
  resetForm = false,
  setResetForm,
  containerId,
}: QuickFormProps) => {
  const quickFormTemplateCopy: TemplateStorageProps =
    quickFormTemplate && JSON.parse(JSON.stringify(quickFormTemplate));
  const initialFormData: FormSubmitData | {} =
    quickFormUtils.getDefaultValuesForRangeFields(quickFormTemplate);
  const cleanOriginalTemplate: TemplateStorageProps | undefined = quickFormTemplate && {
    ...quickFormUtils.removeDuplicateAnswers(quickFormTemplate),
    isProcessed: true,
  };

  const [formData, setFormData] = useState<FormSubmitData>(initialFormData);
  const [formSchema, setFormSchema] = useState<TemplateStorageProps | undefined>(
    cleanOriginalTemplate,
  );
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const { downloadPDFs } = useFormPDF();
  const formDataString = JSON.stringify(formData);

  // the default message for "required" based validation errors is simply:
  // "is a required property"
  // but here we can update that message (and do additional manipulations if we wanted)
  const transformErrors = (errors: AjvError[]) =>
    errors.map((error) => {
      if (error.name === 'required') {
        error.message = getTranslation('requiredField');
      } else if (error.name === 'type') {
        // Parse react-jsonschema-form property accessor notation to the data path of the field
        const propKey = error.property.replace(/(\[{1}'{1})|('\]{1}$)/gm, '');

        const widget = formSchema?.schema?.properties?.[propKey];
        if (widget?.asNumeric) {
          error.message = getTranslation('forms.formBuilder.questions.numberAnswer.errorMessage');
        }
      }
      return error;
    });

  const processConditionalQuestions = async () => {
    const newFormSchema = await quickFormUtils.processQuickFormRules(
      formData,
      quickFormTemplateCopy,
    );
    setFormSchema(newFormSchema);
  };

  const onChange = (newformSchema: IChangeEvent<FormSubmitData>) => {
    // Date range and check answers can be filled and then cleared, so we filter out those keys here
    // in order for react-jsonschema-form validation to fire correctly
    const filteredFormData = Object.keys(newformSchema.formData).reduce(
      (acc: FormSubmitData, key) => {
        const questionValue = newformSchema.formData[key];
        if (
          !(questionValue === '' || (Array.isArray(questionValue) && questionValue.length === 0))
        ) {
          acc[key] = questionValue;
        }
        return acc;
      },
      {} as FormSubmitData,
    );
    setFormData(filteredFormData);
  };

  useEffect(() => {
    if (resetForm && setResetForm) {
      const clearedFormData = Object.keys(formData).reduce<FormSubmitData>((acc, key) => {
        const questionValue = formData[key];
        if (typeof questionValue === 'number') {
          const minimum = formSchema?.schema.properties[key].minimum;
          if (minimum) acc[key] = minimum;
        }
        return acc;
      }, {});
      setFormData(clearedFormData);
      setResetForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm, setResetForm]);

  useLayoutEffect(() => {
    if (formSchema?.isProcessed) {
      processConditionalQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickFormTemplate]);

  useEffect(() => {
    if (formSchema?.isProcessed) {
      processConditionalQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataString]);

  if (status === 'completed' && signedDownloadUrl) {
    return (
      <Result
        status="success"
        title={getTranslation('forms.formSubmitted')}
        extra={[
          <Button
            onClick={() =>
              downloadPDFs(
                [signedDownloadUrl],
                `${externalName}_${getTranslation('forms.formResultsScreen.currentResults')}.pdf`,
              ).catch((pdfError) => {
                TWMessage.errorHandler(
                  pdfError,
                  getTranslation('forms.formResultsPDF.downloadFailed'),
                );
              })
            }
          >
            {getTranslation('forms.downloadForm')}
          </Button>,
        ]}
      />
    );
  }

  if (submitSuccess) {
    return <TWLoadingMask />;
  }

  if (formSchema) {
    const descriptionFieldProps = {
      id: 'root__description',
      description: formSchema.schema.description,
      assignment,
      currentPerson: hideAssignedBy ? '' : currentPersonName,
      hideAssignedOn,
    };

    const FormWidgets: Record<QuestionType, Widget & Partial<PropertiesModelKey>> = {
      textarea: TWQuickFormTextArea,
      numeric: QuickFormNumericInput,
      checkboxes: TWQuickFormCheckBoxes,
      radio: TWQuickFormRadioButton,
      date: TWQuickFormDate,
      daterange: TWQuickFormDateRange,
      image: ImageWidget,
      pdf: PDFWidget,
      dropdown: TWQuickFormDropdown,
      range: TWInputRange,
    };

    return (
      <Form
        tagName={previewTemplate ? 'div' : 'form'} // Neat prop to ignore nesting form errors
        schema={{
          ...formSchema.schema,
          properties: formSchema.schema.properties,
          title: '',
          description: 'placeholder', // Setting this as a placeholder so that DescriptionField always renders
        }}
        formData={formData}
        formContext={{ previewTemplate, containerId }} // Pass a custom prop to all widgets
        onChange={onChange}
        uiSchema={{ ...formSchema.uiSchema }}
        FieldTemplate={TWQuickFormFieldTemplate}
        widgets={FormWidgets}
        fields={{
          DescriptionField: () => TWQuickFormDescriptionField(descriptionFieldProps),
        }}
        noHtml5Validate
        showErrorList={false}
        onSubmit={onSubmit}
        transformErrors={transformErrors}
        onError={() => setHasSubmitted(true)}
        liveValidate={hasSubmitted}
      >
        {quickFormPreview ? <></> : children}
      </Form>
    );
  }

  return null;
};

export default QuickFormNew;
