import { Table, TableProps } from 'antd';
import _ from 'lodash';

import { TWDataTableProps } from './TWDataTable.definitions';
import { Container } from './TWDataTable.styles';

export function TWDataTable<T extends object>({
  dataSource,
  children,
  rowSelection,
  onRowClick, // Removes deprecation warning
  size = 'middle',
  testID,
  loading = false,
  withPadding = false,
  rowClassName = () => 'twDataTable__row',
  ...allUnrecognizedProps
}: TWDataTableProps<T>) {
  const onRow: TableProps<T>['onRow'] = (row) => ({
    onClick: _.isFunction(onRowClick)
      ? (e) => {
          if ((e.target as HTMLInputElement).type === 'checkbox') {
            return;
          }
          onRowClick(row);
        }
      : undefined,
  });

  return (
    <Container disableRowClick={!onRowClick} withPadding={withPadding}>
      <Table
        data-testid={testID}
        rowSelection={rowSelection}
        size={size}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        rowClassName={rowClassName}
        onRow={onRow}
        showSorterTooltip={false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...allUnrecognizedProps}
      >
        {children}
      </Table>
    </Container>
  );
}
