import styled from '@emotion/styled';
import * as React from 'react';
import { Col, Row } from 'antd';

import { TWChip } from '@tw/components';
import { Professor } from '@tw/types';
import { stringUtils } from '@tw/util';

const WrapperRow = styled(Row)(({ theme }) => ({
  margin: `0, 0, ${theme.baseUnit * 2}, 0`,
  '.ant-avatar-string': {
    color: theme.colors.text,
  },
}));

interface SurveyInfoProps {
  description: string;
  professor: Professor;
}

const SurveyInfo: React.FC<SurveyInfoProps> = (props) => {
  const { description, professor } = props;
  const fullName = professor ? `${professor.firstName} ${professor.lastName}` : null;
  const initials = fullName ? stringUtils.getInitialsFromFullName(fullName) : null;

  return (
    <WrapperRow gutter={16}>
      <Col span={12}>{description}</Col>
      <TWChip size="large" avatarText={initials} label={fullName} />
    </WrapperRow>
  );
};

export default SurveyInfo;
