// monthId and dayId are used as unique IDs for grouping and organizing events.
// These are not displayed to the end user
const monthIdDateFormat = 'YYYY-MM';
const dayIdDateFormat = 'YYYY-MM-DD';

const profileNoteDateFormat = 'MMM DD, YYYY, h:mma';

const uploadHistoryFormat = 'ddd, MMM D, YYYY h:mma';

const messageMomentFormat = 'YYYY-MM-DD HH:mm:ss';

const appointmentTableFormat = 'MMM D, h:mma';
const appointmentTimeFormat = 'ddd, MMM DD YYYY';

const inputDateFormatUSA = 'MM-DD-YYYY';

const BACKEND_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss+-HH:mm';
const NO_TIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const longWeekDayFormat = 'dddd';
const longMonthFormat = 'MMMM';
const longMonthDayFormat = 'MMM D';

const BACKEND_SHORT_DATE_FORMAT = 'MMM DD, YYYY';
const displayDateFormat = 'MMMM DD, YYYY';
const shortDateFormat = 'MMM DD';
const shortTimeFormat = 'h:mm a';
const shortWeekDayFormat = 'ddd';

const displayDateTimeFormat = `MMMM Do YYYY, ${shortTimeFormat}`;
const shortDateTimeFormat = `${shortDateFormat}, ${shortTimeFormat}`;

const verboseDateTimeFormat = 'dddd, MMMM DD, YYYY • h:mm a';

const shortDateMilitaryFormat = 'HH:mm';

// section below is for datetime localization work, WIP
// date formats
const monthDayYearLongFormat = 'MMM D, YYYY';
const monthDayYearFullFormat = 'MMM Do, YYYY';
const dayMonthYearLongFormat = 'D MMM, YYYY';
const monthDayShortFormat = 'MMM D,';
const weekDayMonthDayFormat = 'dddd, MMMM D';
const weekDayMonthDayYearFormat = 'dddd, MMMM D, YYYY';
const dayLongMonthLongDayYearFormat = 'ddd, MMM DD YYYY';
const downloadableFileModalDateFormat = 'YYYY/MM/DD';
const shortDateWithTwoDigitYearFormat = 'M/D/YY';

// time formats
const twelveHourTimeFormat = 'h:mma';
const twentyFourHourTimeFormat = 'HH:mm';
const twelveHourTimeFormatWithSeconds = 'h:mm:ssa';
const twentyFourHourTimeFormatWithSeconds = 'H:mm:ss';

const dateAndTimeFormatNames = [
  'longDate',
  'veryShortDate',
  'shorterDate',
  'shortDate',
  'noDayLongDate',
  'dayDateTime',
  'longDateTime',
  'shortDateTime',
  'longTime',
  'shortTime',
  'backendDate',
  'backendDateTime',
  'shortDayDate',
];

// These are "compound" formats, i.e. each key is a format made by combining the formats in it's array of values
// so formatUtils.shortDateTime is the same as `${formatUtils.shortDate}, ${formatUtils.shortTime}`
const compoundFormatNames = {
  shortDateTime: ['shortDate', 'shortTime'],
  dayDateTime: ['dayDate', 'shortTime'],
  longDateTime: ['longDate', 'longTime'],
};

export {
  BACKEND_DATE_FORMAT,
  BACKEND_SHORT_DATE_FORMAT,
  NO_TIMEZONE_FORMAT,
  appointmentTableFormat,
  appointmentTimeFormat,
  compoundFormatNames,
  dateAndTimeFormatNames,
  dayIdDateFormat,
  dayLongMonthLongDayYearFormat,
  dayMonthYearLongFormat,
  displayDateFormat,
  displayDateTimeFormat,
  downloadableFileModalDateFormat,
  inputDateFormatUSA,
  longMonthDayFormat,
  longMonthFormat,
  longWeekDayFormat,
  messageMomentFormat,
  monthDayShortFormat,
  monthDayYearFullFormat,
  monthDayYearLongFormat,
  monthIdDateFormat,
  profileNoteDateFormat,
  shortDateFormat,
  shortDateMilitaryFormat,
  shortDateTimeFormat,
  shortDateWithTwoDigitYearFormat,
  shortTimeFormat,
  shortWeekDayFormat,
  twelveHourTimeFormat,
  twelveHourTimeFormatWithSeconds,
  twentyFourHourTimeFormat,
  twentyFourHourTimeFormatWithSeconds,
  uploadHistoryFormat,
  verboseDateTimeFormat,
  weekDayMonthDayFormat,
  weekDayMonthDayYearFormat,
};
