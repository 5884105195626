import { ProfessorSurveyAssignment } from '@tw/types';

export interface ReportValues {
  /* eslint-disable camelcase */
  professor_survey_report_id: string;
  absence_count: number | string;
  comment: string;
  grade: string;
  is_at_risk: 'true' | 'false';
  /* eslint-enable camelcase */
}

export interface StudentFeedbackDrawerProps {
  containerId: string;
  uuid: string;
  selectedSurvey?: ProfessorSurveyAssignment;
  open?: boolean;
  onClose: () => void;
  onSubmit: (status: string) => void;
}

export const studentFeedbackFormName = 'studentFeedbackForm';
