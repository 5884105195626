import { getTranslation } from '@tw/i18n';

import TWInputSelect from '../../../components.web/inputs/TWInputSelect';

interface RecipientStatusProps {
  popupContainerId: string;
  placeholder: string;
  onChange: (deliveryStatus: string) => void;
  testID: string;
  accessibilityLabel?: string;
  version2?: boolean;
}

const TWInputSelectRecipientStatus = ({
  accessibilityLabel = getTranslation('deliveryStatus'),
  popupContainerId,
  placeholder,
  onChange,
  testID,
  version2 = false,
}: RecipientStatusProps) => (
  <TWInputSelect
    filterOption
    allowClear
    optionFilterProp="title"
    placeholder={placeholder}
    loading={false}
    onChange={onChange}
    popupContainerId={popupContainerId}
    minWidth={200}
    showSearch
    accessibilityLabel={accessibilityLabel}
    testID={testID}
  >
    <TWInputSelect.Option value="sent">{getTranslation('sent')}</TWInputSelect.Option>
    <TWInputSelect.Option value={version2 ? 'read,delivered,voicemail,listened' : 'read,delivered'}>
      {getTranslation('messaging.deliveryStatusSelect.received')}
    </TWInputSelect.Option>
    <TWInputSelect.Option
      value={
        version2
          ? 'bad_number,failed,retrying,bounced,busy,no_answer'
          : 'bad_number,failed,retrying,bounced'
      }
    >
      {getTranslation('failed')}
    </TWInputSelect.Option>
  </TWInputSelect>
);

export default TWInputSelectRecipientStatus;
