/* eslint-disable no-useless-escape */

import stringUtils from './stringUtils';

function getProfileUrl(personId: string) {
  return `/profiles/${personId}`;
}

const generateSelectionCodes = (
  persons: { personId: string | number; teamId: string | number }[],
) =>
  persons.map(({ personId, teamId }) =>
    stringUtils.createSelectionCode('person', teamId, personId),
  );

// This takes a full url...
// (i.e. https://www.espn.com/mlb/story/_/id/29043681/cracking-birthday-mystery-don-mattingly-1987-topps-baseball-card)
// and returns only the hostname...
// (i.e. www.espn.com)
const getHostName = (url = '') => {
  const match = url.match(/:\/\/([0-9]?\.)?(.[^/:]+)/i);
  if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2];
  }
  return null;
};

// This takes a text block and extracts an array of URLs...
// (i.e. Hello https://www.cnn.com YES http://abc.com)
// and returns only the URLs...
// (i.e. [https://www.cnn.com, http://abc.com])
const getUrlsFromText = (text = '') => {
  // Regex obtained from
  // https://stackoverflow.com/questions/42618872/regex-for-website-or-url-validation
  const expression =
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
  const linkMatches = text.match(expression);

  return linkMatches;
};

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

const isUrl = (url: string) => {
  if (typeof url !== 'string') {
    return false;
  }

  const match = url.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (
    localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)
  ) {
    return true;
  }

  return false;
};

export default {
  getHostName,
  generateSelectionCodes,
  getProfileUrl,
  getUrlsFromText,
  isUrl,
};
