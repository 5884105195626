import _ from 'lodash';

import { pythonApiBackend } from '@tw/backends';
import { ConfigContainerType } from '@tw/types';

import academicServiceStoreConfig from '../academicServiceStoreConfig';

const updateStudentPaths = {
  advisorIds: '/advisor_ids',
  cumulativeGpa: '/cumulative_gpa',
  isAtRisk: '/is_at_risk',
};

const studentOperations = {
  updateStudent(
    personId: string,
    teamId: string,
    studentId: string,
    updatedValues,
    configContainer: ConfigContainerType,
  ) {
    const body = _.map(updatedValues, (value, key) => ({
      op: 'replace',
      path: updateStudentPaths[key],
      value,
    }));

    return pythonApiBackend.doFetch(
      {
        // URL-related options
        ctx: academicServiceStoreConfig.operationContext,
        model: `shared/students/${studentId}`,
        debugLabel: 'updateStudent',
        includeAuthenticationHeader: true,
        personId,
        teamId,
      },
      {
        // fetch body
        method: 'PATCH',
        body,
      },
      configContainer,
    );
  },
};

export default studentOperations;
