import _ from 'lodash';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { graphqlUtils } from '@tw/util';
import { StudyHallQueryFilters, StudyHallSortTypes } from '@tw/types';

import { FetchStudyHalls } from './FetchStudyHalls.query';
import { StudyHallQueryData } from './useStudyHalls.definitions';

export const useStudyHalls = (
  options?: QueryHookOptions<StudyHallQueryData, StudyHallQueryFilters>,
) => {
  const { variables, ...otherOptions } = options;
  const cleanFilters = _.pickBy(variables, (value) => !_.isNil(value));

  const { data, ...rest } = useQuery<StudyHallQueryData, Partial<StudyHallQueryFilters>>(
    FetchStudyHalls,
    {
      variables: { sort: [StudyHallSortTypes.createdDateDesc], ...cleanFilters },
      ...otherOptions,
    },
  );

  const { edges, ...otherFields } = data?.studyHalls || {};

  return {
    ...rest,
    data: data?.studyHalls
      ? {
          studyHalls: graphqlUtils.convertEdgesToArray(edges),
          ...otherFields,
        }
      : undefined,
  };
};
