import styled from '@emotion/styled';

import { colors } from '@teamworksdev/style';
import { TWFlexContainer } from '@tw/components/presentational';
import TWIcon from '@tw/components/components.web/TWIcon/TWIcon';
import { NumericInput } from '../QuickFormNumericInput';

const getDateContainerWidth = (isRange: boolean, previewTemplate?: boolean) => {
  if (previewTemplate) {
    return undefined;
  }
  return isRange ? 317 : 280;
};

const ActionButtons = styled.div({
  backgroundColor: colors.white,
  marginRight: '-53px',
  border: `1px solid ${colors.light[300]}`,
  borderRadius: '6px',
  height: '132px',
});

const ShortAnswerBox = styled.textarea({
  borderRadius: '6px',
  border: `1px solid ${colors.light[300]}`,
  height: '130px',
});

const StyledNumericInput = styled(NumericInput)(({ theme }) => ({
  paddingLeft: `${theme.baseUnit * 2}px`,
  border: `1px solid ${colors.light[300]}`,
  margin: `${theme.baseUnit * 1.5}px ${theme.baseUnit}px 0`,

  '&:focus, &.ant-input-number-focused': {
    boxShadow: 'none',
  },
}));

const SelectScale = styled.div(({ theme }) => ({
  color: theme.colors.secondary,
  fontFamily: theme.fontFamily.default,
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: theme.fontWeight.regular,
  lineHeight: '20px',
  marginLeft: theme.baseUnit,
  marginTop: theme.baseUnit * 1.5,
}));

const Date = styled.div(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.6)',
  fontFamily: theme.fontFamily.default,
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: theme.fontWeight.semibold,
  lineHeight: '16px',
  marginBottom: theme.baseUnit / 2,
}));

const DateContainer = styled(TWFlexContainer)<{
  isRange: boolean;
  previewTemplate?: boolean;
}>(({ isRange, previewTemplate }) => ({
  marginTop: 12,
  marginLeft: 8,
  width: getDateContainerWidth(isRange, previewTemplate),
}));

const PillsWrapper = styled(TWFlexContainer)<{ pillExists?: boolean }>(({ pillExists }) => ({
  paddingTop: pillExists ? 16 : 0,
}));

const StyledImg = styled.img(({ theme }) => ({
  border: `${theme.baseUnit * 3}px solid #5D758C14`,
  borderRadius: theme.fontUnit,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
}));

const PdfImgContainer = styled.div(({ theme }) => ({
  margin: `0px ${theme.baseUnit * 3}px 0px ${theme.baseUnit * 3}px`,
}));

const PageCount = styled.div(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.87)',
  background: colors.light[300],
  borderRadius: theme.baseUnit * 2,
  padding: '6px 12px 6px 12px',
  margin: '16px 0px 1px 23px',
}));

const ButtonContainer = styled(TWFlexContainer)({
  margin: '18px 12px 0px 0px',
  'span.material-icons-outlined': {
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
});

const ActionButton = styled(TWFlexContainer)<{ firstButton?: boolean }>(({ firstButton }) => ({
  borderBottom: `1px solid ${colors.light[300]}`,
  width: 44,
  height: 44,
  color: 'rgba(0, 0, 0, 0.87)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderTopLeftRadius: firstButton ? 5 : undefined,
    borderTopRightRadius: firstButton ? 5 : undefined,
  },
}));

const DeleteButton = styled(TWFlexContainer)(({ theme }) => ({
  backgroundColor: 'rgba(216, 61, 50, 1)',
  width: 44,
  height: 44,
  borderRadius: '0px 0px 6px 6px',
  color: colors.white,
  '&:hover': {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), ${theme.colors.error700}`,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
}));

const RulePillContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: theme.baseUnit + 2,
  paddingTop: theme.fontUnit,
}));

const ActionIcon = styled(TWIcon)({
  padding: 12,
});

export {
  ActionButtons,
  ActionButton,
  ActionIcon,
  Date,
  DateContainer,
  DeleteButton,
  ShortAnswerBox,
  StyledNumericInput,
  SelectScale,
  PillsWrapper,
  StyledImg,
  PdfImgContainer,
  PageCount,
  ButtonContainer,
  RulePillContainer,
};
