import { useHistory } from 'react-router-dom';

type TWLinkProps = JSX.IntrinsicElements['a'] & {
  href: string;
  testId?: string;
  text?: string;
  to?: string; // If provided, will use React routing instead of full page loads
};

const TWLink = (props: TWLinkProps) => {
  const { href, to, text, type, testId, onClick, children, ...restAProps } = props;

  const history = useHistory();

  let finalHref: string;

  switch (type) {
    case 'email':
      finalHref = `mailto:${href}`;
      break;
    case 'phone':
      finalHref = `tel:${href}`;
      break;
    default:
      finalHref = href;
  }

  let finalOnClick = onClick;

  if (to) {
    finalHref = to;
    finalOnClick = (e) => {
      e.preventDefault();
      history.push(to);
    };
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a data-testid={testId} href={finalHref} onClick={finalOnClick} {...restAProps}>
      {children || text || href}
    </a>
  );
};

export default TWLink;
