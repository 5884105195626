import { ApolloError } from '@apollo/client';

import { SelectionItemInterface } from '@tw/components/utils';
import { GroupType, UserSelectionOptionsQuery } from '@tw/generated';
import { getTranslation } from '@tw/i18n';

import { UserSelectionModalBase } from '../@components';
import TWUserSelectionFullSingle from './TWUserSelectionFullSingle';

import useSingleSelect from './useSingleSelect';

interface TWUserSelectionFullSingleModalProps {
  containerId: string;
  testID: string;
  defaultGroupTypeFilter: GroupType;
  onOk: (selection: SelectionItemInterface) => void;
  onCancel: () => void;
  setTeamFilter: (teamId: number) => void;
  teamFilter: number;
  individualsOnly: boolean;
  canSwitchTeams?: boolean;

  visible?: boolean;
  selectedItem?: SelectionItemInterface;
  loading?: boolean;
  data?: UserSelectionOptionsQuery;
  error?: ApolloError;
  disabledSelections: string[];
}

const TWUserSelectionFullSingleModal = ({
  containerId,
  testID,
  visible,
  onOk,
  onCancel,
  teamFilter,
  setTeamFilter,
  defaultGroupTypeFilter,
  selectedItem,
  individualsOnly,
  canSwitchTeams,

  disabledSelections = [],
  loading = false,
  data,
  error,
}: TWUserSelectionFullSingleModalProps) => {
  const modalSingleSelectManagementProps = useSingleSelect({
    defaultGroupTypeFilter,
    onChange: () => null,
    initialValue: selectedItem,
  });

  const { setSelectedItem: setModalSelectedItem, selectedItem: modalSelectedItem } =
    modalSingleSelectManagementProps;

  const onOkHandler = (): void => {
    onOk(modalSelectedItem);
  };

  const onCancelHandler = (): void => {
    setModalSelectedItem(selectedItem);
    onCancel();
  };

  return (
    <UserSelectionModalBase
      open={visible}
      containerId={containerId}
      testID={testID}
      title={getTranslation('userSelection.selectPerson')}
      width={500}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <TWUserSelectionFullSingle
        setTeamFilter={setTeamFilter}
        teamFilter={teamFilter}
        canSwitchTeams={canSwitchTeams}
        loading={loading}
        data={data}
        error={error}
        individualsOnly={individualsOnly}
        disabledSelections={disabledSelections}
        {...modalSingleSelectManagementProps}
      />
    </UserSelectionModalBase>
  );
};

TWUserSelectionFullSingleModal.defaultProps = {
  visible: false,
};

export default TWUserSelectionFullSingleModal;
