import { Popover } from 'antd';
import _ from 'lodash';
import { memo, useMemo, useRef } from 'react';

import { avgCharWidthToFontSize } from '@tw/constants';
import { useResizeObserver } from '@tw/hooks';
import { DOMUtils } from '@tw/util';

import { TWTextDefault } from '../../presentational';
import { PopoverCountDiv, PopoverOverflow } from './TWTextListOverflowPopover.styles';

const renderStringListItem = (string: string, idx: number) => (
  <div key={`${string}${idx}`}>{string}</div>
);

interface TWTextListOverflowPopoverProps {
  listOfStrings: Array<string>;
  popoverListItemRender?: (arg0: unknown) => Element;
  containerId: string;
  fontSize?: number;
}

const TWTextListOverflowPopover = memo<TWTextListOverflowPopoverProps>(
  ({
    containerId,
    listOfStrings,
    popoverListItemRender = renderStringListItem,
    fontSize = 12,
    ...allUnrecognizedProps
  }) => {
    const ref = useRef(null);
    const { width } = useResizeObserver(ref);
    const letterWidth = avgCharWidthToFontSize[fontSize] || avgCharWidthToFontSize.default;
    const commaAndSpaceWidth = 2 * letterWidth;

    const visibleStringCount = useMemo<number>(() => {
      let substringWidth = 1;
      let visibleIndex = 1; // we want to show at least first item

      // calculate the width of each string item and divide the span
      // container width by that value until the value is less than 1
      // at that iteration the index (+1) will represent the number of strings
      // to preview from the list
      listOfStrings.forEach((string, idx) => {
        substringWidth += string.length * letterWidth + commaAndSpaceWidth;
        const visibleCount = idx + 1;
        // if there are other strings in list count with of plus element and some space
        const plusStringWidth =
          listOfStrings.length - visibleCount
            ? (`+${listOfStrings.length - visibleCount}`.length + 1) * letterWidth
            : 0;
        if ((Math.floor(width) - plusStringWidth) / substringWidth > 1) {
          visibleIndex = visibleCount;
        }
      });
      return visibleIndex;
    }, [width, listOfStrings, letterWidth, commaAndSpaceWidth]);

    const previewString = useMemo<string>(
      () => _.take(listOfStrings, visibleStringCount).join(', '),
      [visibleStringCount, listOfStrings],
    );
    const popoverList = useMemo<string[]>(
      () => _.slice(listOfStrings, visibleStringCount),
      [visibleStringCount, listOfStrings],
    );
    const popoverCount = popoverList.length || '';

    const popoverListContent = <div>{_.map(popoverList, popoverListItemRender)}</div>;

    return (
      <PopoverOverflow ref={ref} {...allUnrecognizedProps}>
        <TWTextDefault twFontWeight="medium">{previewString}</TWTextDefault>
        {popoverCount && (
          <Popover
            placement="bottomRight"
            content={popoverListContent}
            trigger="hover"
            getPopupContainer={() => DOMUtils.getContainer(containerId)}
          >
            <PopoverCountDiv>{`+${popoverCount}`}</PopoverCountDiv>
          </Popover>
        )}
      </PopoverOverflow>
    );
  },
);

export default TWTextListOverflowPopover;
