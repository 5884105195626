/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import _ from 'lodash';
import { FocusEvent, JSXElementConstructor, ReactElement, ReactNode, UIEvent } from 'react';

import { PersonNode } from '@tw/generated';
import { getTranslation } from '@tw/i18n';
import { DOMUtils, dayjs } from '@tw/util';

import { FormDropdownItemSubtext } from './TWInputSelect.styles';

export type TWInputDefaultOptionItemDataType = {
  key?: string;
  label?: string;
  disabled?: boolean;
  creator?: PersonNode;
  value: string;
  [key: string]: any;
};

export type TWInputOptionGroupItemDataType = {
  groupLabel: string;
  options: TWInputDefaultOptionItemDataType[];
};

const isRegularItemList = (
  items: TWInputOptionGroupItemDataType[] | TWInputDefaultOptionItemDataType[],
): items is TWInputDefaultOptionItemDataType[] =>
  !!items[0] && typeof items[0] === 'object' && !('groupLabel' in items[0]);
const isOptionGroupItemList = (
  items: TWInputOptionGroupItemDataType[] | TWInputDefaultOptionItemDataType[],
): items is TWInputOptionGroupItemDataType[] =>
  !!items[0] && typeof items[0] === 'object' && 'groupLabel' in items[0];

export interface TWInputSelectProps extends SelectProps {
  popupContainerId?: string;
  testID?: string;
  children?: ReactNode;
  disabled?: boolean;
  popupClassName?: string;
  dropdownMatchSelectWidth?: boolean | number;
  dropdownRender?: (
    menu: ReactElement<any, string | JSXElementConstructor<any>>,
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  styles?: object;
  loading?: boolean;
  isMultiSelect?: boolean;
  hasOptGroup?: boolean;
  itemList?: TWInputOptionGroupItemDataType[] | TWInputDefaultOptionItemDataType[] | any[];
  keyExtractor?: Function;
  labelExtractor?: Function;
  valueExtractor?: Function;
  maxHeight?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  width?: string | number;
  showSearch?: boolean;
  allowClear?: boolean;
  optionFilterProp?: string;
  optionLabelProp?: string;
  autoFocus?: boolean;
  showArrow?: boolean;
  onDropdownVisibleChange?: (open: boolean) => void;
  onPopupScroll?: (event: UIEvent<HTMLDivElement>) => void;
  onFocus?: (event: FocusEvent<HTMLDivElement>) => void;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
  onClear?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (value: string, option: any) => void;
  accessibilityLabel?: string;
  isFormDropdown?: boolean;
  placeholder?: ReactNode;
}

const TWInputSelect = ({
  accessibilityLabel,
  id,
  allowClear,
  autoFocus,
  showArrow,
  popupContainerId,
  children,
  disabled,
  popupClassName,
  dropdownMatchSelectWidth,
  dropdownRender,
  filterOption,
  hasOptGroup,
  itemList,
  loading,
  keyExtractor,
  labelExtractor,
  valueExtractor,
  listHeight,
  maxHeight,
  maxWidth,
  minWidth,
  onChange,
  onFocus,
  onBlur,
  onClear,
  onSelect,
  optionFilterProp,
  optionLabelProp,
  onPopupScroll,
  placeholder,
  style: externalStyles,
  testID,
  isMultiSelect,
  showSearch,
  suffixIcon,
  width,
  defaultValue,
  value,
  size,
  getPopupContainer,
  open,
  className,
  bordered,
  onDropdownVisibleChange,
  labelInValue,
  isFormDropdown,
  placement,
}: TWInputSelectProps) => {
  const style = {
    maxHeight,
    maxWidth,
    minWidth,
    width,
    ...externalStyles,
  };
  // Handle cases where we may pass in multiple default values, but it isn't a multi-select.
  // The Select implementation isn't smart about it, and actually selects both,even if it's
  // not a multi-select. So, we need to protect against this, and by default get first value.
  // Components passing values should prevent this from happening, so we'll throw a warning.
  let safeDefaultValue: any = defaultValue;
  if (!isMultiSelect && _.isArray(defaultValue) && defaultValue.length > 1) {
    console.warn('Multiple values passed as defaultValue for TWInputSelect');
    safeDefaultValue = _.at(defaultValue, 0);
  }

  // if the select is in a modal we need to set the containerId
  // to the same one as the modal so that the dropdown appears
  // above the modal
  const defaultGetPopupContainer = () => DOMUtils.getContainer(popupContainerId);

  const renderOptionItem = (itemObject: TWInputDefaultOptionItemDataType) => {
    const itemKey = _.isFunction(keyExtractor)
      ? keyExtractor(itemObject)
      : itemObject.key || itemObject.value;
    const itemLabel = _.isFunction(labelExtractor)
      ? labelExtractor(itemObject)
      : _.toString(itemObject.label || itemObject.value);
    const itemValue = _.isFunction(valueExtractor) ? valueExtractor(itemObject) : itemObject.value;
    const itemCreator: PersonNode | null = _.isObject(itemObject.creator)
      ? itemObject.creator
      : null;

    // title has to be text because it is used as a search value
    const itemTitle = _.isString(itemLabel)
      ? itemLabel
      : _.toString(itemObject.label || itemObject.value);
    const BULLET_POINT = '\u2022';

    return (
      <Select.Option
        key={itemKey}
        value={itemValue}
        title={itemTitle}
        creator={isFormDropdown && itemCreator}
        disabled={_.get(itemObject, 'disabled', false)}
        data-testid={`${testID}:Option`}
        aria-label={itemTitle}
      >
        {isFormDropdown ? (
          <>
            {itemLabel}
            <div>
              <FormDropdownItemSubtext twColor="secondary">
                {itemObject?.isDocusignForm
                  ? getTranslation('forms.formats.docusign')
                  : getTranslation('forms.formats.quickForm')}{' '}
                {BULLET_POINT} {itemCreator?.displayName} {BULLET_POINT}{' '}
                {dayjs(itemObject?.createdDate).format('MMM Do, YYYY')}{' '}
              </FormDropdownItemSubtext>
            </div>
          </>
        ) : (
          itemLabel
        )}
      </Select.Option>
    );
  };

  const renderOptionGroup = (optionGroup: TWInputOptionGroupItemDataType) => {
    const options = _.get(optionGroup, 'options');
    return (
      <Select.OptGroup
        key={_.get(optionGroup, 'groupLabel')}
        label={_.get(optionGroup, 'groupLabel')}
        data-testid={`${testID}:Group`}
        aria-label={_.get(optionGroup, 'groupLabel')}
      >
        {options.map(renderOptionItem)}
      </Select.OptGroup>
    );
  };

  const renderOptions = (items: TWInputDefaultOptionItemDataType[]) =>
    !_.isEmpty(items) && _.map(items, renderOptionItem);

  const renderOptionsWithOptGroup = (items: TWInputOptionGroupItemDataType[]) =>
    !_.isEmpty(items) && _.map(items, renderOptionGroup);

  return (
    <Select
      aria-label={accessibilityLabel}
      id={id}
      allowClear={allowClear}
      showArrow={showArrow}
      className={`TWInputSelect ${className}`}
      data-testid={testID}
      disabled={disabled}
      popupClassName={popupClassName}
      filterOption={filterOption}
      getPopupContainer={getPopupContainer ?? defaultGetPopupContainer}
      loading={loading}
      optionFilterProp={optionFilterProp}
      optionLabelProp={optionLabelProp}
      placeholder={placeholder}
      style={style}
      mode={isMultiSelect ? 'multiple' : undefined}
      onClear={onClear}
      onChange={onChange}
      onPopupScroll={onPopupScroll}
      onSelect={onSelect}
      onFocus={onFocus}
      onBlur={onBlur}
      showSearch={showSearch}
      defaultValue={safeDefaultValue}
      suffixIcon={suffixIcon}
      value={value}
      size={size}
      open={open}
      autoFocus={autoFocus}
      bordered={bordered}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownRender={dropdownRender}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      listHeight={listHeight}
      labelInValue={labelInValue}
      placement={placement}
    >
      {/* NOTE: prevent default value from displaying before component loads */}
      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
      {loading && defaultValue && <Select.Option value={defaultValue}> </Select.Option>}
      {/* eslint-disable-next-line no-nested-ternary */}
      {itemList
        ? hasOptGroup && isOptionGroupItemList(itemList)
          ? renderOptionsWithOptGroup(itemList)
          : isRegularItemList(itemList) && renderOptions(itemList)
        : children}
    </Select>
  );
};

TWInputSelect.Option = Select.Option;

TWInputSelect.defaultProps = {
  allowClear: false,
  labelInValue: false,
  dropdownMatchSelectWidth: true,
  loading: false,
  isMultiSelect: false,
  maxHeight: '120px',
  maxWidth: '100%',
  minWidth: 120,
  showSearch: true,
  style: {},
};

export default TWInputSelect;
