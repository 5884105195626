import { useEffect, useState } from 'react';
import { Radio, RadioChangeEvent, Tooltip } from 'antd';

import { ListItemSizeScalingEnum } from '../../../utils';

import { RadioGroupContainer } from './TWInputRadioGroup.styles';
import { TWInputRadioGroupProps, RadioOptionsType } from './TWInputRadioGroup.definitions';

const TWInputRadioGroup = ({
  onChange,
  options,
  vertical = false,
  size = ListItemSizeScalingEnum.DEFAULT,
  inputType = 'radio',
  defaultValue = '',
  testID,
  accessibilityLabel,
  children = null,
  customStyles = {},
  ...restOfProps
}: TWInputRadioGroupProps) => {
  const [radioGroupValue, setRadioGroupValue] = useState(defaultValue);

  const handleRadioOptionOnClick = (event: RadioChangeEvent) => {
    const {
      target: { value },
    } = event;
    if (onChange) {
      onChange(event);
    }
    setRadioGroupValue(value);
  };

  // Fix the issue when RadioGroup is not unmounted(to preserve modal animation)
  // and default value doesn't work in this case
  useEffect(() => {
    setRadioGroupValue(defaultValue);
  }, [defaultValue]);

  const renderInput = ({
    label,
    testID: radioOptionTestID,
    value,
    tooltip,
    ...allOtherProps
  }: RadioOptionsType) => {
    const RadioComponent = inputType === 'radio' ? Radio : Radio.Button;

    return (
      <Tooltip
        title={tooltip}
        trigger={tooltip ? 'hover' : ''}
        placement="bottom"
        key={radioOptionTestID ?? label}
      >
        <RadioComponent
          checked={value === radioGroupValue}
          data-testid={radioOptionTestID}
          key={radioOptionTestID ?? label}
          value={value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...allOtherProps}
        >
          {label}
        </RadioComponent>
      </Tooltip>
    );
  };

  return (
    <RadioGroupContainer
      size={size}
      inputType={inputType}
      vertical={vertical}
      customStyles={customStyles}
    >
      <Radio.Group
        data-testid={testID}
        size={size === ListItemSizeScalingEnum.DEFAULT ? 'middle' : size}
        onChange={handleRadioOptionOnClick}
        buttonStyle="solid"
        value={radioGroupValue}
        aria-label={accessibilityLabel}
        defaultValue={defaultValue}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restOfProps}
      >
        {children ?? options?.map(renderInput)}
      </Radio.Group>
    </RadioGroupContainer>
  );
};

export { TWInputRadioGroup };
