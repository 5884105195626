/* eslint-disable @typescript-eslint/no-explicit-any */
import { BearerToken } from './session.definitions';

export function makeBearerToken(payload: any): BearerToken {
  return {
    accessToken: payload?.access_token,
    refreshToken: payload?.refresh_token,
    expiresAt: payload?.expires_at,
  };
}
