import styled from '@emotion/styled';
import { TWFlexContainer, TWTextDefault } from '@tw/components/presentational';
import { Theme } from '@tw/components/utils';
import TWIcon from '../TWIcon';
import {
  InfoMessageType,
  TWInfoMessageTypes,
  WithMessageInfoProps,
} from './TWInfoMessage.definitions';

export const getColors = (type: TWInfoMessageTypes | undefined, theme: Theme) => {
  switch (type) {
    case InfoMessageType.WARNING:
      return {
        borderColor: theme.colors.warningBorder,
        backgroundColor: theme.colors.warningBackground,
        iconColor: theme.colors.warningIcon,
        textColor: theme.colors.warningIcon,
      };
    case InfoMessageType.INFO:
    default:
      return {
        borderColor: '#84CAFF',
        backgroundColor: '#F5FAFF',
        iconColor: '#1849A9',
        textColor: '#1849A9',
      };
  }
};

export const TWInfoMessageContainer = styled(TWFlexContainer)<WithMessageInfoProps>(
  ({ theme, messageType, fitContent = false }) => ({
    border: `1px solid ${getColors(messageType, theme).borderColor}`,
    backgroundColor: getColors(messageType, theme).backgroundColor,
    color: getColors(messageType, theme).textColor,
    padding: 2 * theme.baseUnit,
    borderRadius: theme.baseUnit,
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: fitContent ? 'fit-content' : 'auto',
  }),
);

export const TWInfoMessageIcon = styled(TWIcon)(({ theme }) => ({
  paddingRight: 1.5 * theme.baseUnit,
}));

export const TWInfoMessageHeaderText = styled(TWTextDefault)<WithMessageInfoProps>(
  ({ theme, messageType }) => ({
    color: getColors(messageType, theme).textColor,
  }),
);
