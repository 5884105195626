/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OverlayEventsWithSelectionsQueryVariables = Types.Exact<{
  requestId: Types.Scalars['String'];
  eventIds:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type OverlayEventsWithSelectionsQuery = {
  getOverlayEvents?: {
    requestId: string;
    events: Array<{
      overlayEventId: string;
      title: string;
      location?: string | null;
      attendees: { nonHubAttendees?: Array<string | null> | null };
      selections?: {
        persons?: Array<{
          id: string;
          initials?: string | null;
          pictureUrl?: string | null;
          preferredName?: string | null;
          lastName?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const OverlayEventsWithSelectionsDocument = gql`
  query OverlayEventsWithSelections($requestId: String!, $eventIds: [String]!) {
    getOverlayEvents(requestId: $requestId, eventIds: $eventIds) {
      requestId
      events {
        overlayEventId
        title
        location
        attendees {
          nonHubAttendees
        }
        selections {
          persons {
            id
            initials
            pictureUrl
            preferredName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useOverlayEventsWithSelectionsQuery__
 *
 * To run a query within a React component, call `useOverlayEventsWithSelectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverlayEventsWithSelectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverlayEventsWithSelectionsQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      eventIds: // value for 'eventIds'
 *   },
 * });
 */
export function useOverlayEventsWithSelectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OverlayEventsWithSelectionsQuery,
    OverlayEventsWithSelectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OverlayEventsWithSelectionsQuery,
    OverlayEventsWithSelectionsQueryVariables
  >(OverlayEventsWithSelectionsDocument, options);
}
export function useOverlayEventsWithSelectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OverlayEventsWithSelectionsQuery,
    OverlayEventsWithSelectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OverlayEventsWithSelectionsQuery,
    OverlayEventsWithSelectionsQueryVariables
  >(OverlayEventsWithSelectionsDocument, options);
}
export type OverlayEventsWithSelectionsQueryHookResult = ReturnType<
  typeof useOverlayEventsWithSelectionsQuery
>;
export type OverlayEventsWithSelectionsLazyQueryHookResult = ReturnType<
  typeof useOverlayEventsWithSelectionsLazyQuery
>;
export type OverlayEventsWithSelectionsQueryResult = Apollo.QueryResult<
  OverlayEventsWithSelectionsQuery,
  OverlayEventsWithSelectionsQueryVariables
>;
