import { ComponentType, useEffect, useState } from 'react';

import withFilters, { BaseFilters, WithFiltersProps } from './withFilters';

type BasePaginationFilters = BaseFilters & {
  after?: string | null;
};

export type WithPaginationProps<T extends {} = {}> = WithFiltersProps<T & BasePaginationFilters> & {
  onPageChange: (cursor: string | null) => void;
};

function withPagination<P, T extends {} = {}>(
  WrappedComponent: ComponentType<P & WithPaginationProps<T>>,
  defaultFilters?: Partial<T & BasePaginationFilters>,
): ComponentType<Omit<P, keyof WithPaginationProps<T>>> {
  const Pagination = (props: P & WithFiltersProps<T>) => {
    const { filters } = props;

    const [paginatedFilters, setPaginatedFilters] = useState<Partial<T & BasePaginationFilters>>({
      ...filters,
      after: null,
    });

    // Reset pagination when filters change
    useEffect(() => {
      setPaginatedFilters(() => ({
        ...filters,
        after: null,
      }));
    }, [filters]);

    const handlePageChange = (cursor: string) =>
      setPaginatedFilters((prev) => ({
        ...prev,
        after: cursor,
      }));

    return (
      <WrappedComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        filters={paginatedFilters}
        onPageChange={handlePageChange}
      />
    );
  };

  return withFilters<Omit<P, keyof WithPaginationProps<T>>>(Pagination, defaultFilters);
}

export default withPagination;
