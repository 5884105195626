import { message } from 'antd';

function error(containerId: string, text: string) {
  message.config({
    duration: 4,
    getContainer: () => {
      if (containerId) {
        return document.getElementById(containerId);
      }

      return document.body;
    },
  });

  message.error(text);
}

export default {
  error,
};
