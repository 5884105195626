import styled from '@emotion/styled';

import { TWDataTable, TWFormItem } from '@tw/components';

export const ReportTable = styled(TWDataTable)(({ theme }) => ({
  '.ant-table-thead': {
    '.ant-table-cell': {
      color: theme.colors.text,
    },
  },
  '.ant-avatar-string': {
    color: theme.colors.text,
  },
  '.twchip-text-and-subtext': {
    span: {
      color: theme.colors.text,
    },
  },
}));

export const FormItemHiddenLabel = styled(TWFormItem)({
  '.ant-form-item-label': {
    display: 'none',
  },
});
