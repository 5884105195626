import _ from 'lodash';

import { i18nLocaleTranslator as translator } from '@tw/i18n';
import { CourseSectionSchedule } from '@tw/types';
import { dayjs } from '@tw/util';

export interface CourseSectionDetails {
  hasMultipleTimes?: boolean;
  courseSectionSchedules?: CourseSectionSchedule[];
  startTime: string;
  endTime: string;
}

const renderCourseSectionTimes = (record: CourseSectionDetails, timeZone: string) => {
  const { hasMultipleTimes, courseSectionSchedules, startTime, endTime } = record;

  if (hasMultipleTimes) {
    return translator.t('multiple');
  }

  if (!_.isNil(courseSectionSchedules) && _.isEmpty(courseSectionSchedules)) {
    return '-';
  }

  return _.map([startTime, endTime], (time) =>
    time ? dayjs.tz(time, 'hh:mm:ss', timeZone).format('h:mm a') : '',
  ).join('-');
};

export default renderCourseSectionTimes;
