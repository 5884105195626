/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import styled from '@emotion/styled';
import { TreeNodeProps, TreeSelect, TreeSelectProps } from 'antd';
import { toString } from 'lodash';
import { Component } from 'react';

import { getTranslation } from '@tw/i18n';
import { ArrayElement, DOMUtils } from '@tw/util';

const { SHOW_PARENT, SHOW_ALL, SHOW_CHILD } = TreeSelect;

type OptionType = ArrayElement<TreeSelectProps['treeData']>;

type TreePathConfig = {
  labelExtractor?: Function;
  keyExtractor?: Function;
  valueExtractor?: Function;
  selectableExtractor?: Function;
};

export type TreeConfig = {
  traversePath: string[];
  config: Record<string, TreePathConfig>;
};

interface TWInputTreeSelectProps extends TreeSelectProps {
  allowClear?: boolean;
  disabled?: boolean;
  loading: boolean;
  onChange: (selection: string, label: unknown, extra: unknown) => void;
  placeholder?: string;
  popupContainerId?: string;
  testID?: string;
  treeConfig: TreeConfig;
  treeData: object[];
  useAllValues?: boolean;
  useParentValues?: boolean;
  treeCheckable?: boolean;
}

const dropdownStyle = {
  maxHeight: '300',
};
class TWInputTreeSelect extends Component<TWInputTreeSelectProps> {
  getPopupContainer = () => {
    // if the select is in a modal we need to set the containerId
    // to the same one as the modal so that the dropdown appears
    // above the modal
    const { popupContainerId } = this.props;
    return DOMUtils.getContainer(popupContainerId);
  };

  getOptionItem = (
    itemObject: TreeNodeProps,
    config: TreePathConfig,
    childKey: string,
    nextDepth: number,
  ): OptionType => {
    const { keyExtractor, labelExtractor, valueExtractor, selectableExtractor } = config;

    const key =
      typeof keyExtractor === 'function'
        ? keyExtractor(itemObject)
        : itemObject.key || itemObject.value;

    const title =
      typeof labelExtractor === 'function'
        ? labelExtractor(itemObject)
        : toString(itemObject.label || itemObject.value);

    const value =
      typeof valueExtractor === 'function'
        ? valueExtractor(itemObject)
        : toString(itemObject.value);

    // Why not pass TRUE? Well, grasshopper, it turns out that ANT is awesome and if you pass true, it treats
    // selecting nodes only through the onSelect function, treating it differently than just selecting the checkbox.
    // Why? Because. So, we pass undefined if it is selectable, which makes ANT make it selectable by default, but actually
    // allows the onChange function to take precedence and makes the checkbox selected when the item is selected.
    const isSelectable =
      !(typeof selectableExtractor === 'function') || selectableExtractor(itemObject);
    const selectable = isSelectable ? undefined : false;

    const childItem = itemObject[childKey];
    const optionItem: OptionType = {
      label: title,
      title,
      value,
      key,
      selectable,
    };

    if (childItem) {
      optionItem.children = this.getOptionTree(childItem, nextDepth);
    }
    return optionItem;
  };

  getOptionTree = (branchData: TreeNodeProps[], depth = 0): OptionType[] | undefined => {
    const { treeConfig } = this.props;

    const currentKey = treeConfig.traversePath[depth];
    const nextKey = treeConfig.traversePath[depth + 1];
    const branchConfig = treeConfig.config[currentKey];

    if (!branchConfig) {
      return undefined;
    }

    return branchData.map((itemObject) =>
      this.getOptionItem(itemObject, branchConfig, nextKey, depth + 1),
    );
  };

  getMoreString = (omittedValues: any[]) =>
    getTranslation('plusMore', { number: omittedValues.length });

  onChange: TreeSelectProps['onChange'] = (newValue, label, extra) => {
    const { onChange } = this.props;
    return onChange(newValue, label, extra);
  };

  render() {
    const {
      allowClear,
      disabled,
      treeData,
      treeCheckable,
      useAllValues,
      useParentValues,
      loading,
      onChange,
      placeholder,
      testID,
      treeConfig,
      popupContainerId,
      ...otherProps
    } = this.props;

    let checkedStrategy: TreeSelectProps['showCheckedStrategy'] = SHOW_CHILD;
    if (useAllValues) {
      checkedStrategy = SHOW_ALL;
    } else if (useParentValues) {
      checkedStrategy = SHOW_PARENT;
    }

    return (
      <Wrapper>
        <TreeSelect
          data-testid={testID}
          allowClear={allowClear}
          disabled={disabled || loading}
          getPopupContainer={this.getPopupContainer}
          showCheckedStrategy={checkedStrategy}
          treeData={this.getOptionTree(treeData)}
          treeCheckable={treeCheckable}
          treeNodeFilterProp="title"
          maxTagCount={5}
          maxTagPlaceholder={this.getMoreString}
          onChange={this.onChange}
          className="tw-input-treeselect"
          dropdownStyle={dropdownStyle}
          placeholder={placeholder}
          dropdownMatchSelectWidth={false}
          {...otherProps}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div({
  position: 'relative',
  '.tw-input-treeselect': {
    minWidth: 120,
  },
});

export default TWInputTreeSelect;
