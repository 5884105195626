import styled from '@emotion/styled';
import TWIcon from '../../TWIcon';
import { TWButtonIconFlat } from '../../../presentational/buttons';

export const ReminderRow = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  marginBottom: theme.baseUnit,
}));

export const BeforeText = styled.div(({ theme }) => ({
  marginTop: 5,
  marginRight: theme.baseUnit * 2,
}));

export const MarginRight2 = styled.div(({ theme }) => ({
  marginRight: theme.baseUnit * 2,
}));

export const RemoveButton = styled(TWButtonIconFlat)(({ theme }) => ({
  '.remove-button-icon': {
    verticalAlign: '-0.125em',
  },
  '&:not([disabled]):hover': {
    backgroundColor: theme.colors.negativeBackground,
    borderColor: theme.colors.negativeBorder,
  },
  '[aria-label="icon: remove_circle_outline"]:not([disabled])': {
    color: theme.colors.negativeIcon,
    ':hover': {
      color: theme.colors.text,
    },
  },
}));

export const RemoveButtonIcon = styled(TWIcon)(() => ({
  verticalAlign: '-0.125em',
}));
