/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllFilesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AllFilesQuery = {
  folders?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        folderName?: string | null;
        parentFolderId?: number | null;
        sortOrder?: number | null;
        folderId?: number | null;
        parent?: { folderName?: string | null } | null;
        sharedFiles?: Array<{
          id: string;
          pk?: number | null;
          sharedFileId?: number | null;
          folderId?: number | null;
          fileName: string;
          fileNotes?: string | null;
          isPinned?: boolean | null;
          updatedDate?: any | null;
          canEditFile?: boolean | null;
          canDownload?: boolean | null;
          creator?: {
            id: string;
            createdBy?: number | null;
            pictureDownloadUrl?: string | null;
            preferredName?: string | null;
            lastName?: string | null;
            initials?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const AllFilesDocument = gql`
  query AllFiles {
    folders(folderType: "team") {
      totalCount
      edges {
        node {
          folderId: pk
          folderName
          parentFolderId
          parent {
            folderName
          }
          folderName
          sortOrder
          sharedFiles {
            id
            pk
            sharedFileId
            folderId
            fileName
            fileNotes
            isPinned
            updatedDate
            canEditFile
            canDownload
            creator {
              id
              createdBy
              pictureDownloadUrl
              preferredName
              lastName
              initials
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAllFilesQuery__
 *
 * To run a query within a React component, call `useAllFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllFilesQuery, AllFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllFilesQuery, AllFilesQueryVariables>(AllFilesDocument, options);
}
export function useAllFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllFilesQuery, AllFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllFilesQuery, AllFilesQueryVariables>(AllFilesDocument, options);
}
export type AllFilesQueryHookResult = ReturnType<typeof useAllFilesQuery>;
export type AllFilesLazyQueryHookResult = ReturnType<typeof useAllFilesLazyQuery>;
export type AllFilesQueryResult = Apollo.QueryResult<AllFilesQuery, AllFilesQueryVariables>;
