import styled from '@emotion/styled';
import * as React from 'react';

import { getTranslation } from '@tw/i18n';

const Wrapper = styled.div(({ theme }) => ({
  margin: 'auto',
  width: '500px',
  padding: theme.baseUnit * 3,
  border: '1px solid #ddd',
}));

const AllSurveyCompleted: React.FC = () => (
  <Wrapper>
    <h1>{getTranslation('instructorSurvey.studentFeedback.thank')}</h1>
    <p>{getTranslation('instructorSurvey.studentFeedback.submittedMessage')}</p>
  </Wrapper>
);

export default AllSurveyCompleted;
