import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TWFlexContainer, TWTextDefault } from '@tw/components/presentational';
import TWIcon from '@tw/components/components.web/TWIcon';

interface StatProps {
  value: number;
  icon: string;
  description: string;
  testID?: string;
}

const TWStat = ({ value, icon, description, testID }: StatProps) => {
  const theme = useTheme();
  return (
    <StatContainer data-testid={testID}>
      <TWFlexContainer row align="center">
        <StatIcon type={icon} height="18px" width="18px" color={theme.colors.secondary} />
        <StatCountText>{value}</StatCountText>
      </TWFlexContainer>
      <StatTypeText>{description}</StatTypeText>
    </StatContainer>
  );
};

const StatContainer = styled(TWFlexContainer)(({ theme }) => ({
  marginRight: theme.baseUnit * 3,
}));

const StatIcon = styled(TWIcon)(({ theme }) => ({
  marginRight: theme.baseUnit,
}));

const StatCountText = styled(TWTextDefault)(() => ({
  fontSize: '18px',
}));

const StatTypeText = styled(TWTextDefault)(({ theme }) => ({
  fontSize: '14px',
  color: theme.colors.secondary,
}));

export default TWStat;
