/* eslint-disable no-param-reassign */
import _ from 'lodash';

import { MetaData } from '@tw/types';

import backendDebugUtils from './backendDebugUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || window.location.origin;

const prepareMetaDataForFetch = ({ urlOptions, fullUrl, fetchOptions }: MetaData): MetaData => {
  const metaData = {
    urlOptions,
    fullUrl,
    fetchOptions,
    startTime: Date.now(),
  };

  if (backendDebugUtils.shouldDebugRequest(metaData)) {
    console.info('About to perform fetch: ', metaData);
  }
  return metaData;
};

// Note that .text() and .json() *should* return promises, but in some libraries
// (like RNFetchBlob) they return the text/json response directly.
// We use this to ensure we're always operating on a promise.
const makePromise = (valueOrPromise) => {
  if (valueOrPromise && _.isFunction(valueOrPromise.then)) {
    return valueOrPromise;
  }
  return Promise.resolve(valueOrPromise);
};

const responseProcessingFormats = {
  json: (response, metaData) => {
    if (backendDebugUtils.shouldDebugRequest(metaData) && _.isFunction(response.text)) {
      // When we're debugging it's really handy to be able to see the raw response text,
      // so instead of processing the response via .json() we'll first just grab the
      // .text() (so that we can log it later) and then parse it ourselves.
      return makePromise(response.text()).then((responseText) => {
        metaData.responseText = responseText;
        metaData.responseJson = JSON.parse(responseText);
        return metaData.responseJson;
      });
    }
    return makePromise(response.json()).then((responseJson) => {
      metaData.responseJson = responseJson;
      return responseJson;
    });
  },
  text: (response, metaData) =>
    makePromise(response.text()).then((responseText) => {
      metaData.responseText = responseText;
      return responseText;
    }),
  binary: (response, metaData) =>
    makePromise(response.blob()).then((responseBody) => {
      metaData.responseBody = responseBody;
      return responseBody;
    }),
};

const markEndOfResponse = (metaData) => {
  if (!metaData || !metaData.startTime) {
    console.error('markEndOfResponse expected a valid metaData from the fetch');
    // This *should* never happen in real code, but if it somehow does then we'd rather
    // skip everything than spam the user with errors (and, instead, redscreen for devs),
    // since it's likely new-in-dev code.
    return;
  }

  metaData.endTime = Date.now();
  metaData.duration = metaData.endTime - metaData.startTime;
};

const receiveResponse = (
  response,
  metaData,
  desiredFormat: string | ((r, m) => string) = 'json',
) => {
  if (!metaData || !metaData.startTime) {
    console.error('receiveResponse expected a valid metaData from the fetch');
    // This *should* never happen in real code, but if it somehow does then we'd rather
    // skip everything than spam the user with errors (and, instead, redscreen for devs),
    // since it's likely new-in-dev code.
    return Promise.resolve(null);
  }

  markEndOfResponse(metaData);

  metaData.response = response;
  // These are a few different places where status/headers/etc might live, depending on what
  // library sent the request.
  metaData.responseStatus = _.get(response, 'status') || _.get(response, 'respInfo.status');

  // @TODO: Check Content-Type instead of blindly assuming json for the default case
  let responseDataPromise;
  if (!_.isFunction(desiredFormat) && responseProcessingFormats[desiredFormat]) {
    responseDataPromise = responseProcessingFormats[desiredFormat](response, metaData);
  } else if (_.isFunction(desiredFormat)) {
    responseDataPromise = Promise.resolve(desiredFormat(response, metaData));
  } else {
    console.error(`Invalid desiredFormat for receiveResponse: "${desiredFormat}"`);
    responseDataPromise = Promise.resolve(null);
  }

  return responseDataPromise
    .then((responseDataInDesiredFormat) => {
      if (backendDebugUtils.shouldDebugRequest(metaData)) {
        backendDebugUtils.showCopyPasteableRequestInfo(metaData);
      }
      return responseDataInDesiredFormat;
    })
    .catch((error) => {
      console.warn('Backend error! ', error, metaData);
      if (backendDebugUtils.shouldDebugRequest(metaData)) {
        metaData.error = _.cloneDeep(error);
        backendDebugUtils.showCopyPasteableRequestInfo(metaData);
      } else {
        metaData.error = error;
      }
      return error;
    });
};

/**
 * These parse error messages come through if the backend is down, because we get back a html 404 page.
 * Different devices give us different strings.
 */
const isJsonParseError = (errorMessage) => {
  if (_.isString(errorMessage)) {
    const lowerCaseErrorMessage = errorMessage.toLowerCase();
    return (
      lowerCaseErrorMessage.indexOf('unexpected end of json') !== -1 ||
      lowerCaseErrorMessage.indexOf('json parse error') !== -1 ||
      /unexpected token.* in json/.test(lowerCaseErrorMessage)
    );
  }
  return false;
};

export default {
  API_BASE_URL,
  prepareMetaDataForFetch,
  markEndOfResponse,
  receiveResponse,
  isJsonParseError,
};
