import { useState, useEffect, useRef, useCallback } from 'react';
import { isEqual } from 'lodash';

export const useBroadcastState = <T>(name: string, initialState: T): [T, (value: T) => void] => {
  const [state, innerSetState] = useState(initialState);
  const channel = useRef(new BroadcastChannel(name));

  useEffect(() => {
    const handler = (e: MessageEvent<T>) => {
      const { data: value } = e;
      // only set state if data coming in is different
      innerSetState((prev) => (isEqual(prev, value) ? prev : value));
    };
    const chan = channel.current;
    chan.addEventListener('message', handler);
    return () => {
      chan.removeEventListener('message', handler);
    };
  }, [innerSetState, state]);

  const setState = useCallback(
    (value: T) => {
      innerSetState((prev) => (isEqual(prev, value) ? prev : value));
      channel.current?.postMessage(value);
    },
    [innerSetState],
  );

  return [state, setState];
};
