import { useState } from 'react';
import * as React from 'react';

import TWInlineEditField, { TWInlineEditFieldProps } from '../TWInlineEditField/TWInlineEditField';

interface TWEditableSelectProps extends TWInlineEditFieldProps {
  containerId: string;
  hideButtons?: boolean;
  onCancel: Function;
  onChange: Function;
  onSave: Function;
  readComponent: React.ReactElement;
  writeComponent: React.ReactElement;
}

const TWEditableSelect: React.FC<TWEditableSelectProps> = ({
  containerId,
  hideButtons,
  onCancel,
  onChange,
  onSave,
  readComponent,
  writeComponent,
  ...twInlineEditFieldProps
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  function handleKeyDown(event, saveEdit, cancelEdit) {
    if (event.key === 'Enter' && !isDropdownVisible) {
      saveEdit(event);
      return;
    }

    if (event.key === 'Escape' && !isDropdownVisible) {
      cancelEdit(event);
    }
  }

  return (
    <TWInlineEditField
      {...twInlineEditFieldProps}
      hideButtons={hideButtons}
      containerId={containerId}
      onCancel={onCancel}
      onChange={onChange}
      onSave={onSave}
      onKeyDown={handleKeyDown}
      readComponent={readComponent}
      writeComponent={React.cloneElement(writeComponent, {
        onDropdownVisibleChange: setDropdownVisible,
        onSearch: setDropdownVisible,
      })}
    />
  );
};

export default React.memo(TWEditableSelect);
