/* eslint-disable camelcase */
import Polyglot, { InterpolationOptions } from 'node-polyglot';
import { NestedKeysOf } from '@tw/util';
import { getLocaleDictionary } from './dictionary.utils';
import de from './de_DE.json';
import en from './en_US.json';
import es from './es_419.json';
import fr from './fr_FR.json';
import it from './it_IT.json';
import nl from './nl_NL.json';
import pt_BR from './pt_BR.json';
import pt_PT from './pt_PT.json';

const dictionary = {
  de,
  en,
  es,
  fr,
  it,
  nl,
  pt: pt_PT,
  'pt-PT': pt_PT,
  'pt-BR': pt_BR,
};

export type Dictionary = typeof dictionary;
export type TranslationKey = NestedKeysOf<Dictionary['en']>;

export type TWInterpolationOptions = number | InterpolationOptions;

export interface TWPolyglot extends Polyglot {
  // enforces the typed key
  t: (key: TranslationKey, options?: TWInterpolationOptions) => string;
}

const phrases = getLocaleDictionary(dictionary);

export const i18nLocaleTranslator = new Polyglot({ phrases }) as TWPolyglot;

export default dictionary;
