/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalendarAvailabilityAppointmentsQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type CalendarAvailabilityAppointmentsQuery = {
  allAppointments?: {
    edges: Array<{
      node?: {
        startTimeZone?: string | null;
        title?: string | null;
        location: string;
        isAllDay: boolean;
        attendees?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              id: string;
              pictureUrl?: string | null;
              preferredName?: string | null;
              lastName?: string | null;
              initials?: string | null;
              active: boolean;
              teamLabels?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              teamMembership?: { isAthlete?: boolean | null } | null;
            }
          | {
              groupType?: Types.GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                id: string;
                pictureUrl?: string | null;
                preferredName?: string | null;
                lastName?: string | null;
                initials?: string | null;
                active: boolean;
                selectionCode?: string | null;
                teamLabels?: string | null;
                teamMembership?: { isAthlete?: boolean | null } | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
        occurrences?: Array<{
          appointmentId?: number | null;
          appointmentOccurrenceId?: number | null;
          endDateTime?: any | null;
          startDateTime?: any | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export const CalendarAvailabilityAppointmentsDocument = gql`
  query CalendarAvailabilityAppointments(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $selections: [String]!
  ) {
    allAppointments(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      selections: $selections
    ) {
      edges {
        node {
          attendees {
            label
            selectionCode
            ... on PersonNode {
              id
              pictureUrl
              preferredName
              lastName
              initials
              active
              teamLabels
              teamMembership {
                isAthlete
              }
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                id
                pictureUrl
                preferredName
                lastName
                initials
                active
                selectionCode
                teamLabels
                teamMembership {
                  isAthlete
                }
              }
            }
          }
          startTimeZone
          title
          location
          isAllDay
          occurrences {
            appointmentId
            appointmentOccurrenceId
            endDateTime
            startDateTime
          }
        }
      }
    }
  }
`;

/**
 * __useCalendarAvailabilityAppointmentsQuery__
 *
 * To run a query within a React component, call `useCalendarAvailabilityAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarAvailabilityAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarAvailabilityAppointmentsQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useCalendarAvailabilityAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarAvailabilityAppointmentsQuery,
    CalendarAvailabilityAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalendarAvailabilityAppointmentsQuery,
    CalendarAvailabilityAppointmentsQueryVariables
  >(CalendarAvailabilityAppointmentsDocument, options);
}
export function useCalendarAvailabilityAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarAvailabilityAppointmentsQuery,
    CalendarAvailabilityAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarAvailabilityAppointmentsQuery,
    CalendarAvailabilityAppointmentsQueryVariables
  >(CalendarAvailabilityAppointmentsDocument, options);
}
export type CalendarAvailabilityAppointmentsQueryHookResult = ReturnType<
  typeof useCalendarAvailabilityAppointmentsQuery
>;
export type CalendarAvailabilityAppointmentsLazyQueryHookResult = ReturnType<
  typeof useCalendarAvailabilityAppointmentsLazyQuery
>;
export type CalendarAvailabilityAppointmentsQueryResult = Apollo.QueryResult<
  CalendarAvailabilityAppointmentsQuery,
  CalendarAvailabilityAppointmentsQueryVariables
>;
