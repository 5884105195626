import moment from 'moment';

const now = Date.now();

const baseDefault = {
  // As a nuclear option to address issues related to having the app open for a long time -- such as performance
  // degradation -- we can force the app to cold-start when it would otherwise warm-start, to force a full reset.
  // (These should *only* be used on warm starts.)
  // In general these should be disabled/falsy or very large, but it's currently providing a "nuclear option"
  // for the logout issue, TW-15045.
  appBackgroundTiming: {
    consideredInactive: 2 * 60 * 1000, // 2 minutes
    relaunchAfterInactivity: 6 * 60 * 60 * 1000, // 6 hours (generally overnight)
    relaunchBeforeTokenExpiration: 6 * 60 * 60 * 1000, // 4 hours
  },

  // This info is used to send the user to the App Store / Play Store when they need to
  // download upgrades
  appStore: {
    appleAppStoreId: '1271258473',
    googlePlayStoreId: 'com.teamworks.twWeb.next',
    // If somebody's using a TestFlight build, we'll open TestFlight instead of sending them
    // to the App Store
    // @TODO: Whoops, we can't detect TestFlight builds yet. See PX-607
    testflightAppStoreId: '899247664',
  },

  // This group is named rather awkwardly: it actually holds times when the app was launched/foregrounded:
  // it's not related to network connectivity.
  onlineStatus: {
    appLaunchTime: now,
    appActivityTime: now,
  },

  /**
   * "User preferences" are functionally identical to feature flags, but conceptually and
   * philosophically they're supposed to represent permanent, persistent options that are up
   * to the user and have their own dedicated controls in the UI; whereas feature flags are
   * temporary, are under our control instead of the user's, and usually aren't accessible
   * outside of Secret Settings. User preferences are also more likely to be strings or
   * other non-boolean values.
   */
  userPreferences: {
    calendarLockedTimezone: moment.tz.guess(),
    isCalendarLockedTimezoneEnabled: false,
    useOrgDefaultTimeFormat: true,
    userInternationalTimeFormat: false,
  },
};

export default baseDefault;
