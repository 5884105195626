/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchStudentIdsQueryVariables = Types.Exact<{
  personIds?: Types.InputMaybe<Types.Scalars['TWFilter_Int']>;
}>;

export type FetchStudentIdsQuery = {
  students?: {
    edges: Array<{ node?: { id: string; studentId: string; personId: number } | null } | null>;
  } | null;
};

export const FetchStudentIdsDocument = gql`
  query FetchStudentIds($personIds: TWFilter_Int) {
    students(personId: $personIds) {
      edges {
        node {
          id
          studentId
          personId
        }
      }
    }
  }
`;

/**
 * __useFetchStudentIdsQuery__
 *
 * To run a query within a React component, call `useFetchStudentIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStudentIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStudentIdsQuery({
 *   variables: {
 *      personIds: // value for 'personIds'
 *   },
 * });
 */
export function useFetchStudentIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchStudentIdsQuery, FetchStudentIdsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchStudentIdsQuery, FetchStudentIdsQueryVariables>(
    FetchStudentIdsDocument,
    options,
  );
}
export function useFetchStudentIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchStudentIdsQuery, FetchStudentIdsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchStudentIdsQuery, FetchStudentIdsQueryVariables>(
    FetchStudentIdsDocument,
    options,
  );
}
export type FetchStudentIdsQueryHookResult = ReturnType<typeof useFetchStudentIdsQuery>;
export type FetchStudentIdsLazyQueryHookResult = ReturnType<typeof useFetchStudentIdsLazyQuery>;
export type FetchStudentIdsQueryResult = Apollo.QueryResult<
  FetchStudentIdsQuery,
  FetchStudentIdsQueryVariables
>;
