import styled from '@emotion/styled';
import { colors } from '@teamworksdev/style';

export const Description = styled.div<{ hideBox?: boolean }>(({ theme, hideBox }) => ({
  whiteSpace: hideBox ? undefined : 'pre-wrap',
  backgroundColor: hideBox ? undefined : theme.colors.white,
  borderRadius: hideBox ? undefined : '8px',
  border: hideBox ? undefined : `1px solid ${colors.light[300]}`,
  padding: hideBox ? undefined : '16px',
  color: hideBox ? undefined : 'rgba(0, 0, 0, 0.87)',
  fontFamily: hideBox ? undefined : theme.fontFamily.default,
  marginBottom: hideBox ? undefined : '24px',
}));

export const Details = styled.div<{ hasDesc?: boolean }>(({ theme, hasDesc }) => ({
  whiteSpace: 'pre-wrap',
  marginTop: hasDesc ? '8px' : undefined,
  padding: '8px',
  color: '#555',
  fontFamily: theme.fontFamily.default,
}));

export const Icon = styled.span(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.38)',
  fontSize: 16,
  marginRight: theme.baseUnit,
}));

export const IconLabel = styled.span(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.6)',
  marginRight: theme.baseUnit,
}));

export const UserLabel = styled.span(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.87)',
  lineHeight: '18px',
  fontWeight: theme.fontWeight.medium,
  fontStyle: 'normal',
  fontSize: '14px',
  fontFamily: theme.fontFamily.default,
}));
