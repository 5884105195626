/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloseSignupSlotsMutationVariables = Types.Exact<{
  slotIds?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['UUID']>> | Types.InputMaybe<Types.Scalars['UUID']>
  >;
  sendAlerts?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type CloseSignupSlotsMutation = {
  closeSignupSlots: {
    slots?: Array<{
      slotId?: any | null;
      capacity?: number | null;
      numReservations?: number | null;
      isOpen?: boolean | null;
    } | null> | null;
  };
};

export const CloseSignupSlotsDocument = gql`
  mutation CloseSignupSlots($slotIds: [UUID], $sendAlerts: String) {
    closeSignupSlots(slotIds: $slotIds, sendAlerts: $sendAlerts) {
      slots {
        slotId
        capacity
        numReservations
        isOpen
      }
    }
  }
`;
export type CloseSignupSlotsMutationFn = Apollo.MutationFunction<
  CloseSignupSlotsMutation,
  CloseSignupSlotsMutationVariables
>;

/**
 * __useCloseSignupSlotsMutation__
 *
 * To run a mutation, you first call `useCloseSignupSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseSignupSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeSignupSlotsMutation, { data, loading, error }] = useCloseSignupSlotsMutation({
 *   variables: {
 *      slotIds: // value for 'slotIds'
 *      sendAlerts: // value for 'sendAlerts'
 *   },
 * });
 */
export function useCloseSignupSlotsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseSignupSlotsMutation,
    CloseSignupSlotsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseSignupSlotsMutation, CloseSignupSlotsMutationVariables>(
    CloseSignupSlotsDocument,
    options,
  );
}
export type CloseSignupSlotsMutationHookResult = ReturnType<typeof useCloseSignupSlotsMutation>;
export type CloseSignupSlotsMutationResult = Apollo.MutationResult<CloseSignupSlotsMutation>;
export type CloseSignupSlotsMutationOptions = Apollo.BaseMutationOptions<
  CloseSignupSlotsMutation,
  CloseSignupSlotsMutationVariables
>;
