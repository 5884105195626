import { Moment } from 'moment';
import { useState } from 'react';

import TWInputDateRange from '../TWInputDateRange/TWInputDateRange';

interface TWDisabledInputDateRangeProps {
  containerId: string;
  dateRange: number;
  onChange?: (arg: [Moment | null, Moment | null]) => void;
  style?: {};
  value?: [Moment, Moment];
}

const TWDisabledInputDateRange = (props: TWDisabledInputDateRangeProps) => {
  const { containerId, dateRange, onChange, style, value, ...allOtherProps } = props;
  const [dates, setDates] = useState<[Moment | null, Moment | null]>([null, null]);
  const [currentValue, setCurrentValue] = useState<[Moment | null, Moment | null]>([null, null]);
  const [isOpen, handleIsOpen] = useState(false);

  const disabledDate = (current: Moment) => {
    if (dates[0] === null && dates[1] === null) {
      return false;
    }
    const tooLate = (dates[0] && current.diff(dates[0], 'days') >= dateRange) ?? false;
    const tooEarly = (dates[1] && dates[1].diff(current, 'days') >= dateRange) ?? false;

    return tooEarly || tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
      handleIsOpen(true);
    } else {
      handleIsOpen(false);
    }
  };

  const handleOnChange = (val: [Moment, Moment] | null) => {
    let formattedDates: [Moment | null, Moment | null] = [null, null];
    if (val !== null) {
      const currentDuration = val[1]?.diff(val[0], 'days');
      if (currentDuration <= dateRange) {
        formattedDates =
          val?.length > 0 ? [val[0].startOf('day'), val[1].endOf('day')] : [null, null];
        if (onChange) {
          onChange(formattedDates);
        }
        setCurrentValue(val);
      } else {
        // Reset one of dates if it is outside dateRange
        if (val[0] === currentValue[0]) {
          formattedDates = val?.length > 0 ? [null, val[1]?.endOf('day')] : [null, null];
        } else {
          formattedDates = val?.length > 0 ? [val[0]?.startOf('day'), null] : [null, null];
        }
        setDates(formattedDates);
        if (onChange) {
          onChange(formattedDates);
        }
        setCurrentValue(formattedDates);
      }
    } else {
      setCurrentValue(formattedDates);
    }
  };

  const handleOnCalendarChange = (val: [Moment, Moment] | null) => {
    // Resetting DateRange picker
    if (val === null) {
      setCurrentValue([null, null]);
      if (onChange) {
        onChange([null, null]);
      }
    } else {
      const duration = val[0] && val[1] ? val[1].diff(val[0], 'days') : 0;
      if (duration <= dateRange) {
        setDates(val);
      }
    }
  };

  // If one of dates is undefined when calendar picker is closed, we will show empty dates
  const isInvalidState =
    (currentValue[0] === null || currentValue[0] === undefined) !==
      (currentValue[1] === null || currentValue[1] === undefined) && !isOpen;

  return (
    <TWInputDateRange
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allOtherProps}
      containerId={containerId}
      value={isInvalidState ? [null, null] : value || currentValue}
      disabledDate={disabledDate}
      onCalendarChange={handleOnCalendarChange}
      onChange={handleOnChange}
      onOpenChange={onOpenChange}
      style={style}
    />
  );
};

export default TWDisabledInputDateRange;
