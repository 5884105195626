import { Col, Row } from 'antd';
import { capitalize } from 'lodash';
import { ReactNode } from 'react';

import { AttributeWrapper } from './TWAttribute.styles';

interface TWAttributeProps {
  label: string;
  value?: ReactNode;
  testID?: string;
  labelColumnSpan?: number;
  valueColumnSpan?: number;
  shouldCapitalizeLabel?: boolean;
}

const TWAttribute = ({
  label,
  labelColumnSpan = 6,
  valueColumnSpan = 18,
  value = null,
  testID = '',
  shouldCapitalizeLabel = false,
}: TWAttributeProps) => {
  const printLabel = () => {
    if (label && label.trim()?.length > 0) {
      return shouldCapitalizeLabel ? capitalize(label) : label;
    }
    return null;
  };

  return (
    <AttributeWrapper data-testid={testID}>
      <Row gutter={8} align="top" justify="start" className="attribute-item">
        <Col data-testid={`${testID}Item:Label`} span={labelColumnSpan} className="attribute-label">
          {printLabel()}
        </Col>
        <Col data-testid={`${testID}Item:Value`} span={valueColumnSpan} className="attribute-value">
          {value}
        </Col>
      </Row>
    </AttributeWrapper>
  );
};

export default TWAttribute;
