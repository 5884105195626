import { selectablesQuery } from './selectablesQuery.query';

export default [
  {
    request: {
      query: selectablesQuery,
      variables: {
        codes: ['person_1248_25139', 'persontype_1253_1825'],
      },
    },
    result: {
      data: {
        selectables: [
          {
            label: 'SuperUser, Org',
            selectionCode: 'person_1248_25139',
            personId: '25139',
            firstName: 'Org',
            lastName: 'SuperUser',
            preferredName: 'Org',
            __typename: 'PersonNode',
          },
          {
            label: 'Academic Advisor',
            selectionCode: 'persontype_1253_1825',
            groupType: 'TYPE',
            pluralLabel: 'Academic Advisor (Academic Tutoring)',
            people: [
              {
                personId: '25141',
                firstName: 'Amelia',
                lastName: 'Miller',
                preferredName: 'Amelia',
                __typename: 'PersonNode',
              },
              {
                personId: '25142',
                firstName: 'Isabella',
                lastName: 'Taylor',
                preferredName: 'Isabella',
                __typename: 'PersonNode',
              },
              {
                personId: '25140',
                firstName: 'Ava',
                lastName: 'Wilcox',
                preferredName: 'Ava',
                __typename: 'PersonNode',
              },
              {
                personId: '25144',
                firstName: 'Elijah',
                lastName: 'Williams',
                preferredName: 'Elijah',
                __typename: 'PersonNode',
              },
            ],
            __typename: 'SelectionGroup',
          },
        ],
      },
    },
  },
];
