import {
  BorderWidthType,
  ColorsType,
  CustomBrandColorsType,
  FontFamilyType,
  FontWeightType,
  IconSizeType,
  ShadowsType,
  ImageDimensionsType,
  Theme,
} from './theme.definitions';

const baseUnit = 8;
const fontUnit = 4;

/* Brand Colors */
const brandColors: CustomBrandColorsType = {
  headerBgColor: '#233342',
  headerLinkColor: '#FFFFFF',
  headerTextColor: '#f3f3f3',
  primaryColor: '#A1C738',
  primaryLighterColor: '#86939e',
  primaryLightestColor: '#e2e2e2',
};

// https://www.figma.com/file/4QJOzRVFAi9j9pId8Vui2x/Messages-2.0?node-id=1507%3A5286
const conversationPickerColors: string[] = [
  '#FC4B38',
  '#FCD801',
  '#2BCA90',
  '#47DFDA',
  '#5AC5ED',
  '#446CB2',
  '#707DC3',
  '#959CA4',
  '#EDEEF2',
];

// Figma colors should be the only colors used in the app
// https://www.figma.com/file/G0fiSVc5zSAS6lFtQRyqS3jV/Web?node-id=66%3A0
const colors: Partial<ColorsType> = {
  white: '#FFFFFF',
  gray: '#F0F4F4',
  white90: 'rgba(255,255,255,0.9)',
  black60: 'rgba(0,0,0,0.6)',
  black: '#000000',
  background: '#FAFBFC',
  highlight: 'rgba(142, 166, 203, 0.07)',
  highlightBold: 'rgba(142, 166, 203, 0.15)',
  border: 'rgba(66, 81, 130, 0.2)',
  borderGray: '#C7C7C7',
  placeholder: '#6C7182',
  buttonTextDisabled: '#C7C7C7',
  boxShadow: 'rgba(66, 81, 130, 0.25)',

  text: '#31363B',
  textHightEmphasis: 'rgba(0, 0, 0, 0.87)',
  textLowEmphasis: 'rgba(0, 0, 0, 0.38)',
  secondary: '#6C7182',
  bold: '#181A1C',
  icon: 'rgba(66, 75, 105, 0.7)',
  lightIcon: 'rgba(66, 75, 105, 0.45)',
  iconBackground: '#EDEEF2',
  iconHover: '#474D54',
  transparentIconHover: 'rgb(71, 77, 84, 0.7)',

  link: 'rgba(15, 50, 169, 0.9)',
  linkHover: '#0B257C',
  linkActive: '#091E63',
  linkIcon: 'rgba(15, 50, 169, 0.7)',

  buttonBorder: 'rgba(66, 81, 130, 0.35)',
  buttonBorderHover: '#86898f',
  buttonText: '#424B69',
  primaryButtonBackground: '#6C7182',
  primaryButtonText: '#FFFFFF',
  primaryButtonBackgroundDisabled: '#b5b8c0',
  primaryButtonBackgroundHover: '#5B6174',
  focus: '#0035EA',

  positive: '#21A700',
  positiveIcon: 'rgba(33, 167, 0, 0.8)',
  negative: '#DC1721',
  negativeIcon: 'rgba(220, 23, 33, 0.8)',
  warning: '#DB7600',
  warningIcon: 'rgba(219, 118, 0, 0.8)',
  yellowIcon: '#FFB802',

  pieChartYellow: '#FFC107',
  pieChartBlue: '#1E88E5',

  positiveBackground: '#F6FFED',
  positiveBorder: '#B7EB8F',
  negativeBackground: '#FFF1F0',
  negativeBorder: '#FFA39E',
  warningBackground: '#FFFBE6',
  warningBorder: '#FFE58F',
  infoBackground: '#E6F7FF',
  infoBorder: '#91D5FF',
  infoIcon: '#0070D7',

  linkPreviewBackground: '#E5E5E5',
  avatarBackground: '#999999',
  menuHighlightBackground: '#2646b1',
  menuItemHighlight: 'rgba(15,50,169,0.15)',

  brand: '#649900',
  brand80: 'rgba(100, 153, 0, 0.8)',
  brandHighlight: 'rgba(100, 153, 0, 0.05)',

  attention: '#BB3E45',
  compliance: '#9122d5',
  grayBackground: 'rgba(93,117,140,0.08)',
  slateBackground: '#343840',
  hoverBackground: '#f6f7f8',
  stagedBackground: '#e9edf7',
  disabledBackground: '#f5f5f5',

  pillGrayBackground: '#E4E9EE',
  pillGrayText: '#354658',
  pillPositiveBackground: '#D9FFC2',
  pillPositiveText: '#166D00',
  pillNegativeBackground: '#FFE5E5',
  pillNegativeText: '#980000',
  pillWarningBackground: '#FFE6D4',
  pillWarningText: '#854800',
  pillFullBackground: '#166D00',
  pillFullText: '#FFFFFF',

  calendarToday: '#EC5120',

  success25: '#E7F3EC',
  success700: '#0A893C',
  error25: '#FBECEB',
  error700: '#D83D32',
  warning25: '#FEF4E6',
  warning700: '#F79009',

  orangeDark600: '#E04F16',
  lightTheme200: '#EAECF0',
  lightTheme300: '#D0D5DD',
};

const borderWidth: BorderWidthType = {
  large: 2,
  medium: 1,
  small: 1,
};

// https://www.figma.com/file/G0fiSVc5zSAS6lFtQRyqS3jV/Web?node-id=246%3A0
const iconSize: IconSizeType = {
  xxsmall: 12,
  xsmall: 16,
  small: 24,
  medium: 32,
  large: 40,
  xlarge: 64,
  xxlarge: 96,
};

const imageDimensions: ImageDimensionsType = {
  videothumbnail: {
    width: 72,
    height: 42,
  },
};

// https://www.figma.com/file/G0fiSVc5zSAS6lFtQRyqS3jV/Web?node-id=139%3A0
const fontWeight: FontWeightType = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};

const fontFamily: FontFamilyType = {
  default: "'Inter', system-ui, sans-serif",
};

const shadows: ShadowsType = {
  menu: '0px 6px 16px rgba(66, 81, 130, 0.5), 0px 0px 1px rgba(66, 81, 130, 0.5)',
  border: `inset 0px -2px 0px -1px ${colors.border}`,
  overlay: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  sider: '1px 0px 0px rgba(66, 81, 130, 0.2)',
};

const TRANSITION_DURATION = 300;
const transition = `all ${TRANSITION_DURATION}ms ease`;

const defaultTheme: Theme = {
  baseUnit,
  fontUnit,
  brandColors,
  conversationPickerColors,
  colors,
  borderWidth,
  iconSize,
  fontWeight,
  fontFamily,
  shadows,
  imageDimensions,
  cssValues: {
    transition,
  },
};

export default defaultTheme;
