import { Input } from 'antd';
import { useRef, useState } from 'react';

import { TWFlexContainer, TWInvisibleLabel } from '@tw/components';
import { GroupType, UserSelectionOptionsQuery } from '@tw/generated';
import { getTranslation } from '@tw/i18n';

import { GroupFilter, TeamFilter, TreeSelect } from '..';
import {
  SearchAndTeamFilters,
  TreeContainer,
} from '../../TWUserSelectionMulti/TWUserSelectionFullMulti.styles';
import { MultiSelectManagementInterface } from '../../TWUserSelectionMulti/useMultiSelect';
import { filterCustomGroupsAcrossTeams } from '../../userSelectionUtils';

interface UserSelectionFullBaseProps {
  autoFocus?: boolean;
  loading?: boolean;
  data?: UserSelectionOptionsQuery;
  includesCustomGroups?: boolean;
  includesAllTeams?: boolean;
  individualsOnly?: boolean;
  alwaysSelectedCodes?: string[];
  hideAlwaysSelected?: boolean;
  canSwitchTeams?: boolean;
  disableSwitchTeams?: boolean;
  userTypesOnly?: boolean;
  userTypeGroupsOnly?: boolean;
  disabledSelections?: string[];
  hideDisabled?: boolean;
  teamFilter?: string | number;
  setTeamFilter;
  viewCanModifyUsersTeamOnly?: boolean;
}

type MultiSelectProps = Omit<
  MultiSelectManagementInterface,
  'handleOnDeselect' | 'handleOnExplode' | 'setSelectionItemsByCode'
>;

const UserSelectionFullBase = ({
  autoFocus = true,
  loading,
  data,
  includesCustomGroups = true,
  includesAllTeams = true,
  individualsOnly,
  canSwitchTeams,
  disableSwitchTeams = false,
  userTypesOnly,
  userTypeGroupsOnly,
  disabledSelections = [],
  hideDisabled = false,
  alwaysSelectedCodes,
  hideAlwaysSelected,

  // filters
  groupTypeFilter,
  searchFilter,
  teamFilter,
  setTeamFilter,

  // event handlers
  handleSearchFilterChange,
  handleSetGroupTypeFilter,

  // selection state
  selectionItemsByCode,
  viewCanModifyUsersTeamOnly,

  handleOnItemClick = () => null,
}: UserSelectionFullBaseProps & MultiSelectProps) => {
  const [isTreeVisible, setIsTreeVisible] = useState(true);
  const TreeEl = useRef(null);
  const isAcrossTeams = !teamFilter;
  const groups = isAcrossTeams ? data?.groups ?? [] : filterCustomGroupsAcrossTeams(data);
  const groupTypes = new Set(
    groups?.reduce<GroupType[]>((acc, group) => {
      if (group?.groupType) {
        return acc.concat(group.groupType);
      }
      return acc;
    }, []),
  );

  return (
    <TWFlexContainer>
      <SearchAndTeamFilters row twMarginBottom={2}>
        <Input
          autoFocus={autoFocus}
          allowClear
          data-testid="UserSelection:Search"
          onChange={handleSearchFilterChange}
          placeholder="Search"
          style={{ marginRight: 8 }}
          id="UserSelectionSearch"
        />
        <TWInvisibleLabel>
          <label htmlFor="UserSelectionSearch">{getTranslation('search')}</label>
        </TWInvisibleLabel>
        {canSwitchTeams && (
          <TeamFilter
            onDropdownVisibleChange={() => setIsTreeVisible(!isTreeVisible)}
            value={teamFilter}
            loading={loading}
            disabled={disableSwitchTeams}
            includesCustomGroups={includesCustomGroups}
            includesAllTeams={includesAllTeams}
            viewCanModifyUsersTeamOnly={viewCanModifyUsersTeamOnly}
            onChange={(teamId: string): void => setTeamFilter(teamId)}
          />
        )}
      </SearchAndTeamFilters>
      {!userTypesOnly && !searchFilter && (
        <GroupFilter
          groupTypes={groupTypes}
          defaultValue={groupTypeFilter}
          onChange={handleSetGroupTypeFilter}
        />
      )}
      <TreeContainer ref={TreeEl}>
        <TreeSelect
          visible={isTreeVisible}
          containerEl={TreeEl}
          loading={loading}
          groups={groups}
          searchFilter={searchFilter}
          groupTypeFilter={groupTypeFilter}
          itemOnSelect={handleOnItemClick}
          selectionItemsByCode={selectionItemsByCode}
          resetAfterSelection={false}
          individualsOnly={individualsOnly}
          userTypesOnly={userTypesOnly}
          userTypeGroupsOnly={userTypeGroupsOnly}
          disabledSelections={disabledSelections}
          hideDisabled={hideDisabled}
          alwaysSelectedCodes={alwaysSelectedCodes}
          hideAlwaysSelected={hideAlwaysSelected}
        />
      </TreeContainer>
    </TWFlexContainer>
  );
};

export default UserSelectionFullBase;
