import { ApolloError } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import { SelectionItemInterface, SelectionItemsByCode } from '@tw/components/utils';
import { UserSelectionOptionsQuery } from '@tw/generated';
import { useEventListener } from '@tw/hooks';

import { TWIcon } from '../../../../components.web';
import {
  TWAvatar,
  TWButtonIcon,
  TWErrorAlertDeprecated,
  TWFlexContainer,
  TWSpacingContainer,
} from '../../../../presentational';
import { TreeSelect } from '../Tree';

import { filterCustomGroupsAcrossTeams } from '../../userSelectionUtils';
import {
  Container,
  DropDownContainer,
  ErrorContainer,
  Input,
  InputContainer,
  InputRenderContainer,
} from './SelectInputBase.styles';

interface SelectInputBaseProps {
  disabled?: boolean;
  isModalOpen: boolean;
  selectionTreeVisible: boolean;
  placeholder: string;
  initialSelectionCode?: string;
  handleSearchFilterChange: ({ target: { value } }: { target: { value: string } }) => void;
  handleMoreButtonClick: () => void;
  setSelectionTreeVisible: (bool: boolean) => void;
  data?: UserSelectionOptionsQuery;
  error?: ApolloError;
  loading: boolean;
  selectionItemsByCode?: SelectionItemsByCode;
  testID: string;
  searchFilter: string;
  individualsOnly: boolean;
  userTypesOnly: boolean;
  userTypeGroupsOnly: boolean;
  useGroups: boolean;
  alwaysSelectedCodes?: string[];
  hideAlwaysSelected?: boolean;
  disabledSelections?: string[];
  itemOnStage?: (selection: SelectionItemInterface) => void;
  itemOnSelect?: (selection: SelectionItemInterface) => void;
  innerInputRender?: () => ReactNode;
  multi?: boolean;
  resetAfterSelection?: boolean;
  width?: number;
  accessibilityLabel?: string;
}

const SelectInputBase = ({
  disabled = false,
  isModalOpen,
  itemOnSelect,
  placeholder,
  selectionItemsByCode,
  innerInputRender,
  handleMoreButtonClick,
  selectionTreeVisible,
  setSelectionTreeVisible,
  resetAfterSelection,
  searchFilter,
  handleSearchFilterChange,
  testID,
  individualsOnly,
  alwaysSelectedCodes,
  hideAlwaysSelected,
  disabledSelections,
  useGroups,
  userTypesOnly,
  userTypeGroupsOnly,
  accessibilityLabel,

  data,
  error,
  loading,
  multi,
  width = 265,
}: SelectInputBaseProps) => {
  const inputEl = useRef<HTMLDivElement | null>(null);
  const TreeEl = useRef<HTMLDivElement | null>(null);
  const searchInputEl = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [prevSelectionItemsByCode, setPrevSelectionItemsByCode] = useState(selectionItemsByCode);

  const clearSearchInput = () => {
    setInputValue('');
    handleSearchFilterChange({ target: { value: '' } });
  };

  const focusSearchInput = () => {
    if (searchInputEl.current) {
      searchInputEl.current.focus();
    }
  };

  // =================================================
  // EVENT HANDLERS

  const handleMouseDownEvent = ({ target }): void => {
    if (
      inputEl.current &&
      TreeEl.current &&
      !inputEl.current.contains(target) &&
      !TreeEl.current.contains(target)
    ) {
      setSelectionTreeVisible(false);
    }
  };

  const handleClearSearchInput = () => {
    focusSearchInput();
    clearSearchInput();
  };

  const handleInputOnChange = (e) => {
    handleSearchFilterChange(e);
    setInputValue(e.target.value);
    // Make sure selection tree is open when user types
    if (e.target.value?.length > 0) {
      setSelectionTreeVisible(true);
    }
  };

  // =================================================

  useEffect(() => {
    // Clear and focus search input when new value added.
    if (inputValue.length > 0 && size(prevSelectionItemsByCode) < size(selectionItemsByCode)) {
      focusSearchInput();
      clearSearchInput();
    }
    setPrevSelectionItemsByCode(selectionItemsByCode);
  }, [selectionItemsByCode]);

  useEffect(() => {
    // Just clear search input, when modal opens.
    if (isModalOpen) {
      clearSearchInput();
    }
  }, [isModalOpen]);

  useEventListener('mousedown', handleMouseDownEvent, window);

  const theme = useTheme();

  let inputWidth = width;

  if (multi && inputValue) {
    inputWidth = width - 67;
  }

  if (!multi) {
    if (inputValue) {
      inputWidth = width - 91;
    } else {
      inputWidth = width - 65;
    }
  }

  return (
    <>
      {error && !isModalOpen && (
        <ErrorContainer inputWidth={width}>
          <TWErrorAlertDeprecated
            apolloError={error}
            data-testid={`UserSelectionInput:ErrorMessage:${testID}`}
          />
        </ErrorContainer>
      )}
      <Container id={testID} data-testid={testID} inputWidth={width} isDisabled={disabled}>
        <TWFlexContainer row align="center">
          <InputRenderContainer ref={inputEl} inputWidth={width}>
            {!isEmpty(selectionItemsByCode) && innerInputRender ? (
              innerInputRender()
            ) : (
              <InputContainer>
                <Row align="middle" justify="space-between">
                  {!multi && (
                    <TWAvatar
                      icon={<TWIcon type="tw-user" />}
                      alt="user-icon"
                      shape="circle"
                      size="small"
                    />
                  )}
                  <Input
                    aria-label={accessibilityLabel}
                    disabled={disabled}
                    ref={searchInputEl}
                    value={inputValue}
                    onClick={(): void => setSelectionTreeVisible(true)}
                    id={`UserSelectionInput:searchInput:${testID}`}
                    data-testid={`UserSelectionInput:searchInput:${testID}`}
                    autoComplete="off"
                    placeholder={placeholder}
                    onChange={handleInputOnChange}
                    inputWidth={inputWidth}
                  />
                  {inputValue && (
                    <TWSpacingContainer twMarginRight={0.5}>
                      <TWFlexContainer>
                        <TWIcon type="material-close" onClick={handleClearSearchInput} />
                      </TWFlexContainer>
                    </TWSpacingContainer>
                  )}
                </Row>
              </InputContainer>
            )}
          </InputRenderContainer>
          <TWButtonIcon
            testID={`UserSelectionInput:OpenFullDialogButton:${testID}`}
            accessibilityLabel="Open full user selection dialog button"
            onClick={handleMoreButtonClick}
            padding={[0.5]}
            disabled={disabled}
            customStyles={{
              borderTop: '0px',
              borderRight: '0px',
              borderBottom: '0px',
              borderLeft: '1px solid',
              borderRadius: `0 ${theme.baseUnit / 2}px ${theme.baseUnit / 2}px 0`,
              borderColor: 'rgba(66, 81, 130, 0.2)',
              outline: 'none',
              ':hover': { borderColor: theme.colors.border },
              marginLeft: '0px!important',
            }}
          >
            <TWIcon type="material-more_horiz" color={theme.colors.icon} />
          </TWButtonIcon>
        </TWFlexContainer>
        <Transition in={selectionTreeVisible} timeout={0}>
          {(state): ReactNode =>
            !isModalOpen &&
            state !== 'exited' && (
              <DropDownContainer ref={TreeEl} state={state} inputWidth={width}>
                <TreeSelect
                  visible={selectionTreeVisible}
                  containerEl={TreeEl}
                  loading={loading}
                  groups={filterCustomGroupsAcrossTeams(data)}
                  searchFilter={searchFilter}
                  itemOnSelect={itemOnSelect}
                  selectionItemsByCode={selectionItemsByCode}
                  resetAfterSelection={resetAfterSelection}
                  alwaysSelectedCodes={alwaysSelectedCodes}
                  hideAlwaysSelected={hideAlwaysSelected}
                  disabledSelections={disabledSelections}
                  individualsOnly={individualsOnly}
                  userTypesOnly={userTypesOnly}
                  userTypeGroupsOnly={userTypeGroupsOnly}
                  useGroups={useGroups}
                />
              </DropDownContainer>
            )
          }
        </Transition>
      </Container>
    </>
  );
};

SelectInputBase.defaultProps = {
  initialSelectionCode: '',
  searchFilter: '',
  innerInputRender: null,
  multi: false,
  resetAfterSelection: false,
};

export default SelectInputBase;
