import { Viewer } from '@tw/generated';

const TEAMWORKS_SUPPORT_TYPES = ['Teamworks Support', 'Implementation Specialist'];
const TEAMWORKS_EMAIL = '@teamworks.com';

export const checkForTWSupportAccount = (
  currentMembership: Viewer['currentMembership'],
  emailAddress: string,
) => {
  const isTeamworksSupport =
    !!currentMembership?.userTypes?.some(
      (userType) => userType && TEAMWORKS_SUPPORT_TYPES.includes(userType.name),
    ) &&
    (emailAddress?.endsWith(TEAMWORKS_EMAIL) ?? false);

  return isTeamworksSupport;
};
