import _ from 'lodash';
import * as React from 'react';

import TWTextListOverflowPopover from '../../components.web/TWTextListOverflowPopover/TWTextListOverflowPopover';

interface TWItemLabelListProps<T extends Record<string, string>> {
  containerId: string;
  emptyLabel: React.ReactNode;
  labelPropertyName?: string;
  itemList: T[];
}

function TWItemLabelList<T extends Record<string, string>>(props: TWItemLabelListProps<T>) {
  const { itemList, labelPropertyName = 'label', containerId, emptyLabel } = props;

  const nameList = _.map(itemList, (item) => item[labelPropertyName]);

  if (nameList.length > 0) {
    return <TWTextListOverflowPopover containerId={containerId} listOfStrings={nameList} />;
  }

  return <>{emptyLabel}</>;
}

export default TWItemLabelList;
