import { PersonNode, SelectionGroup, SelectionGroupNoExtends } from '@tw/types';
import _ from 'lodash';

interface SelectionOptions {
  isChecked?: boolean;
  // isPersistent=true will remove the x for that item on the checklist row, but
  // does not prevent removal from the item within the modal.
  isPersistent?: boolean;
  sortString?: string;
  groupType?: string;
}

type PersonNodeWithOptions = PersonNode & SelectionOptions;
type SelectionGroupWithOptions = SelectionGroup & SelectionOptions;
type SelectionGroupNoExtendsWithOptions = SelectionGroupNoExtends & SelectionOptions;

export type SelectionItemInterface =
  | PersonNodeWithOptions
  | SelectionGroupWithOptions
  | SelectionGroupNoExtendsWithOptions;

export interface SelectionItemsByCode {
  [key: string]: SelectionItemInterface;
}

const isSelectionGroupWithOptions = (
  item: SelectionItemInterface,
): item is SelectionGroupWithOptions => _.has(item, 'people');

const findSelectionByCode = (
  data: SelectionItemInterface[] | SelectionItemsByCode,
  selectionCode: string,
) => {
  let selectionItem = null;
  const findSelectionFn = (item: SelectionItemInterface) => {
    if (item.selectionCode === selectionCode) {
      selectionItem = item;
      return false;
    }
    if (isSelectionGroupWithOptions(item)) {
      _.forEach(item.people, findSelectionFn);
    }
    return true;
  };
  _.forEach(data, findSelectionFn);

  return selectionItem;
};

const handleAddOrRemoveSelection = (
  itemsByCode: SelectionItemsByCode,
  selectionItem: SelectionItemInterface,
): { selectionAdded: boolean; newSelectionItemsByCode: SelectionItemsByCode } => {
  if (!selectionItem) {
    return {
      selectionAdded: false,
      newSelectionItemsByCode: itemsByCode,
    };
  }

  if (_.has(itemsByCode, selectionItem.selectionCode)) {
    return {
      selectionAdded: false,
      newSelectionItemsByCode: _.omit(itemsByCode, [selectionItem.selectionCode]),
    };
  }

  return {
    selectionAdded: true,
    newSelectionItemsByCode: {
      ...itemsByCode,
      [selectionItem.selectionCode]: selectionItem,
    },
  };
};

const convertSelectionListToItemsByCode = (incomingSelectionItems: SelectionItemInterface[]) =>
  _.reduce(
    incomingSelectionItems,
    (selectionsByCode: object, selectionItem: SelectionItemInterface) => ({
      [selectionItem.selectionCode]: selectionItem,
      ...selectionsByCode,
    }),
    {},
  );

export { findSelectionByCode, handleAddOrRemoveSelection, convertSelectionListToItemsByCode };
