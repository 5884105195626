import styled from '@emotion/styled';
import { Drawer } from 'antd';
import { CSSProperties } from 'react';
import { theme } from '@tw/components/utils';

export const TWDrawerContainer = styled(Drawer)(({ title }) => ({
  // Centered drawer close icon
  '.ant-drawer-close': {
    padding: title !== '' ? '15px' : '20px',
    paddingRight: 0,
  },
}));

export const bodyStyle: CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  background: theme.colors.background,
};
