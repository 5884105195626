/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindTutorAppointmentsQueryVariables = Types.Exact<{
  selections:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
  startDateTime: Types.Scalars['DateTime'];
  endDateTime: Types.Scalars['DateTime'];
}>;

export type FindTutorAppointmentsQuery = {
  findTutorAppointments?: {
    edges: Array<{
      node?: {
        meta?: any | null;
        isAllDay: boolean;
        appointmentId: string;
        appointmentTypeId?: number | null;
        complianceType?: string | null;
        countableMinutes?: number | null;
        createdBy?: number | null;
        createdDateTime?: string | null;
        title?: string | null;
        orgId: number;
        startTimeZone?: string | null;
        endTimeZone?: string | null;
        isBooked?: boolean | null;
        isClass?: boolean | null;
        isMandatory?: boolean | null;
        isPrivate?: boolean | null;
        isRecurring?: boolean | null;
        slotParentAppointmentId?: number | null;
        recurEnd?: any | null;
        recurStart?: any | null;
        rrule?: string | null;
        notes?: string | null;
        location: string;
        startDateTime?: any | null;
        endDateTime?: any | null;
        userMetadata?: { canViewDetails?: boolean | null; canEdit?: boolean | null } | null;
        appointmentType?: {
          label: string;
          color: string;
          appointmentTypeId?: number | null;
        } | null;
        occurrences?: Array<{
          appointmentOccurrenceId?: number | null;
          appointmentId?: number | null;
          startDateTime?: any | null;
          endDateTime?: any | null;
        } | null> | null;
        creator?: { preferredName?: string | null; lastName?: string | null } | null;
        attendees?: Array<
          | { label?: string | null; selectionCode?: string | null }
          | {
              personId: string;
              firstName?: string | null;
              lastName?: string | null;
              pictureUrl?: string | null;
              label?: string | null;
              selectionCode?: string | null;
            }
          | {
              groupType?: Types.GroupType | null;
              pluralLabel?: string | null;
              label?: string | null;
              selectionCode?: string | null;
              people?: Array<{
                personId: string;
                firstName?: string | null;
                lastName?: string | null;
                pictureUrl?: string | null;
              } | null> | null;
            }
          | { label?: string | null; selectionCode?: string | null }
          | null
        > | null;
      } | null;
    } | null>;
  } | null;
};

export const FindTutorAppointmentsDocument = gql`
  query FindTutorAppointments(
    $selections: [String]!
    $startDateTime: DateTime!
    $endDateTime: DateTime!
  ) {
    findTutorAppointments(
      selections: $selections
      startDateTime: $startDateTime
      endDateTime: $endDateTime
    ) {
      edges {
        node {
          userMetadata {
            canViewDetails
            canEdit
          }
          meta
          isAllDay
          appointmentId
          appointmentType {
            label
            color
            appointmentTypeId
          }
          appointmentTypeId
          complianceType
          countableMinutes
          createdBy
          createdDateTime
          title
          occurrences {
            appointmentOccurrenceId
            appointmentId
            startDateTime
            endDateTime
          }
          orgId
          startDateTime: startDate
          startTimeZone
          endDateTime: endDate
          endTimeZone
          isBooked
          isClass
          isMandatory
          isPrivate
          isRecurring
          slotParentAppointmentId
          recurEnd
          recurStart
          rrule
          notes
          location
          creator {
            preferredName
            lastName
          }
          attendees {
            label
            selectionCode
            ... on PersonNode {
              personId
              firstName
              lastName
              pictureUrl
            }
            ... on SelectionGroup {
              groupType
              pluralLabel
              people {
                personId
                firstName
                lastName
                pictureUrl
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFindTutorAppointmentsQuery__
 *
 * To run a query within a React component, call `useFindTutorAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTutorAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTutorAppointmentsQuery({
 *   variables: {
 *      selections: // value for 'selections'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *   },
 * });
 */
export function useFindTutorAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindTutorAppointmentsQuery,
    FindTutorAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindTutorAppointmentsQuery, FindTutorAppointmentsQueryVariables>(
    FindTutorAppointmentsDocument,
    options,
  );
}
export function useFindTutorAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindTutorAppointmentsQuery,
    FindTutorAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindTutorAppointmentsQuery, FindTutorAppointmentsQueryVariables>(
    FindTutorAppointmentsDocument,
    options,
  );
}
export type FindTutorAppointmentsQueryHookResult = ReturnType<typeof useFindTutorAppointmentsQuery>;
export type FindTutorAppointmentsLazyQueryHookResult = ReturnType<
  typeof useFindTutorAppointmentsLazyQuery
>;
export type FindTutorAppointmentsQueryResult = Apollo.QueryResult<
  FindTutorAppointmentsQuery,
  FindTutorAppointmentsQueryVariables
>;
