const internationalTimeFormatConfig = {
  // All date/time format strings use MomentJS format
  dateAndTime: {
    // NOTE: this should NOT override date values, just the time values
    // These are used for outputting/formatting dates
    shortTime: 'H:mm',
    longTime: 'H:mm:ss',
  },
};

export default internationalTimeFormatConfig;
