import { useCallback, useMemo } from 'react';
import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import {
  ProfessorSurveyPaginatedRawData,
  ProfessorSurveyQueryVariables,
  ProfessorSurveyRawQueryVariables,
} from '@tw/types';
import { professorSurveyModel } from '@tw/services/academicService';

import FETCH_PROFESSOR_SURVEYS from './FetchProfessorSurveys.graphql';

export const useProfessorSurveys = (
  variables?: ProfessorSurveyQueryVariables,
  options?: QueryHookOptions<ProfessorSurveyPaginatedRawData, ProfessorSurveyRawQueryVariables>,
) => {
  const { data: rawData, ...rest } = useQuery<
    ProfessorSurveyPaginatedRawData,
    ProfessorSurveyRawQueryVariables
  >(FETCH_PROFESSOR_SURVEYS, {
    variables: professorSurveyModel.normalizeProfessorSurveyVariables(variables),
    ...options,
  });

  const data = useMemo(
    () => professorSurveyModel.normalizePaginatedProfessorSurveyData(rawData),
    [rawData],
  );

  return {
    data,
    ...rest,
  };
};

export const useProfessorSurveysLazy = (
  variables?: ProfessorSurveyQueryVariables,
  options?: LazyQueryHookOptions,
) => {
  const [fetchProfessorsRaw, { data: rawData, ...rest }] = useLazyQuery(FETCH_PROFESSOR_SURVEYS, {
    variables,
    ...options,
  });

  const fetchProfessors = useCallback(
    (
      fetchVariables?: ProfessorSurveyQueryVariables,
      fetchOptions?: LazyQueryHookOptions<
        ProfessorSurveyPaginatedRawData,
        ProfessorSurveyRawQueryVariables
      >,
    ) =>
      fetchProfessorsRaw({
        ...fetchOptions,
        variables: professorSurveyModel.normalizeProfessorSurveyVariables(fetchVariables),
      }),
    [fetchProfessorsRaw],
  );

  const data = useMemo(
    () => professorSurveyModel.normalizePaginatedProfessorSurveyData(rawData),
    [rawData],
  );

  return {
    ...rest,
    fetchProfessors,
    data,
  };
};
