import { Dictionary, isNil, pickBy } from 'lodash';
import queryString, { ParseOptions } from 'query-string';
import { ComponentType, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const queryStringOptions: ParseOptions = {
  arrayFormat: 'comma',
  parseNumbers: true,
  parseBooleans: true,
};

export type BaseFilters = {
  first?: number | null;
  [key: string]: any;
};

export type WithFiltersProps<T extends {} = {}> = {
  filters: Partial<T & BaseFilters>;
  updateFilters: (filters: Partial<T & BaseFilters>) => void;
};

function withFilters<P, T extends {} = {}>(
  WrappedComponent: ComponentType<P & WithFiltersProps<T>>,
  defaultFilters?: Partial<T & BaseFilters>,
) {
  const Filters = (props: P) => {
    const history = useHistory();
    const location = useLocation();

    const [filters, setFilters] = useState<Partial<T & BaseFilters>>(() => {
      const queryParams = queryString.parse(location.search, queryStringOptions);

      return {
        first: 10,
        ...defaultFilters,
        ...queryParams,
      } as Partial<T & BaseFilters>;
    });

    const handleFilterChange = useCallback(
      (updatedFilters: Partial<T & BaseFilters>) => {
        const newFilters = {
          ...filters,
          ...updatedFilters,
        } as Dictionary<Partial<T & BaseFilters>>;

        setFilters(
          pickBy<Partial<T & BaseFilters>>(newFilters, (filter) => !isNil(filter)) as Partial<
            T & BaseFilters
          >,
        );

        // Get clean updated filters for URL without first and null values
        const filtersForUrl = pickBy(
          newFilters as Dictionary<Partial<T & BaseFilters>>,
          (filter, key) => key !== 'first' && !isNil(filter),
        );

        // Create serialized query string
        const search = queryString.stringify(filtersForUrl, queryStringOptions);

        // Update the URL when filters have changed
        if (location.search !== `?${search}`) {
          history.replace({ search });
        }
      },
      [filters, history, location.search],
    );

    return (
      <WrappedComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        filters={filters}
        updateFilters={handleFilterChange}
      />
    );
  };

  return Filters;
}

export default withFilters;
