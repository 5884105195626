import { useViewer } from '@tw/hooks';
import { getTranslation } from '@tw/i18n';
import { useGlobalModal } from '@tw/components';
import session from '@tw/services/session';
import { useLogoutUserMutation } from '@tw/generated';
import localStorage from '@tw/services/localStorage';

const useLogout = () => {
  const { warningConfirmGlobalModal } = useGlobalModal();
  const { setUser, getTabCount, person } = useViewer();
  const isSSO = person?.usesExternalAuthentication ?? false;

  const [logout] = useLogoutUserMutation({
    onCompleted: async () => {
      setUser(null);
      await session.logout(isSSO ? process.env.OKTA_HOST : undefined);
    },
  });

  return [
    async () => {
      const tabCount = getTabCount();

      if (
        tabCount < 2 ||
        (await warningConfirmGlobalModal({
          title: getTranslation('areYouSure'),
          content: getTranslation('navigation.tabsRefreshConfirmation'),
          okText: getTranslation('ok'),
          cancelText: getTranslation('cancel'),
        }))
      ) {
        await logout({
          variables: {
            refreshToken: localStorage.getRefreshToken() ?? undefined,
          },
        });
      }
    },
  ];
};

export { useLogout };
