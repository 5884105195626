import { FormProps } from 'antd/lib/form';
import { PropsWithChildren } from 'react';

import { TWFormContainer } from './TWForm.styles';

const TWForm = ({
  children,
  layout = 'vertical',
  ...otherProps
}: FormProps & PropsWithChildren) => (
  <TWFormContainer layout={layout} {...otherProps}>
    {children}
  </TWFormContainer>
);

export { TWForm };
