import { Checkbox, MenuProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import partial from 'lodash/partial';
import { MouseEvent, useState } from 'react';

import { TWButton, TWFlexContainer, TWTextDefault, TWTextSmall } from '@tw/components';
import { TicketingGuestStatus } from '@tw/generated';
import { getTranslation } from '@tw/i18n';
import { DOMUtils } from '@tw/util';

import {
  ClearSelectionsButton,
  ClearSelectionsButtonContainer,
  InputIcon,
  LoadingIcon,
  MultiSelectionsHeader,
  Placeholder,
  StyledDropdown,
  SubHeadTextContainer,
} from '../TWInputMultiSelect/TWInputMultiSelect.styles';
import {
  OptionItemProps,
  TWInputMultiSelectGuestOptionsProps,
} from './TWInputMultiSelectGuestOptions.definitions';

function TWInputMultiSelectGuestOptions({
  containerId,
  itemList = [],
  placeholder,
  values = [],
  onChange,
  selectionLabel,
  testID,
  loading,
  disabled = false,
  clearSelectionText,
  subHeadText,
  getPopupContainer,
}: TWInputMultiSelectGuestOptionsProps) {
  const [visible, setVisibility] = useState<boolean>(false);

  const handleMenuClick = () => {
    setVisibility(true);
  };

  const handleVisibleChange = (isVisible: boolean) => {
    setVisibility(isVisible);
  };

  const clearSelections = () => onChange?.([]);

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setVisibility(false);
  };

  const handleChange = (event: CheckboxChangeEvent, changedValue: string) => {
    if (event.target.checked) {
      onChange?.([...values, changedValue]);
    } else {
      const newValues = values.filter((item: string) => item !== changedValue);
      onChange?.(newValues);
    }
  };

  const menuItem = (itemObject: OptionItemProps) => {
    const isChecked = values.includes(itemObject.key);
    // Per COMP-1024:
    // if user selects blocked guests, all other options are disabled
    // if user selects approved, denied, pending, then blocked option is disabled
    const isDisabled =
      values.length > 0 &&
      ((values.includes(TicketingGuestStatus.Blocked) &&
        itemObject.key !== TicketingGuestStatus.Blocked) ||
        (!values.includes(TicketingGuestStatus.Blocked) &&
          itemObject.key === TicketingGuestStatus.Blocked));

    return {
      key: itemObject.key,
      label: (
        <Checkbox
          data-testid={`MultiSelect:Option:${itemObject.label}`}
          key={itemObject.key}
          value={itemObject.key}
          onChange={(e) => handleChange(e, itemObject.key)}
          checked={isChecked}
          disabled={isDisabled}
        >
          {itemObject.label}
        </Checkbox>
      ),
    };
  };

  const selectMenuItems = () => {
    const items: MenuProps['items'] = [];
    items.push({
      key: 'clearSelections',
      className: 'clearSelections',
      label: (
        <TWFlexContainer row>
          {subHeadText ? <SubHeadTextContainer>{subHeadText}</SubHeadTextContainer> : ''}
          <ClearSelectionsButtonContainer>
            <ClearSelectionsButton
              onClick={clearSelections}
              data-testid="MultiSelect:ClearSelections"
            >
              {clearSelectionText || getTranslation('clearSelection', { smart_count: 2 })}
            </ClearSelectionsButton>
          </ClearSelectionsButtonContainer>
        </TWFlexContainer>
      ),
    });
    itemList.forEach((item) => items.push(menuItem(item)));
    items.push({ type: 'divider' });
    items.push({
      key: 'closeButton',
      className: 'closeButton',
      label: (
        <TWButton
          type="primary"
          data-testid="MultiSelect:CloseButton"
          onClick={closeMenu}
          accessibilityLabel={getTranslation('close')}
        >
          {getTranslation('close')}
        </TWButton>
      ),
    });

    return items;
  };

  const renderMultiSelectionLabel = () => {
    if (values.length === 0) {
      return <Placeholder disabled={disabled}>{placeholder}</Placeholder>;
    }
    if (values.length === itemList.length) {
      return <TWTextDefault>{`${getTranslation('all')} ${selectionLabel}`}</TWTextDefault>;
    }
    if (values.length === 1) {
      const selectedItem = itemList.find((item) => item.key === values[0]);
      return <TWTextDefault>{selectedItem?.label}</TWTextDefault>;
    }
    return (
      <TWTextDefault>
        {`${values.length} ${selectionLabel} ${getTranslation('selected')}`}
      </TWTextDefault>
    );
  };

  return (
    <>
      <StyledDropdown
        getPopupContainer={getPopupContainer ?? partial(DOMUtils.getContainer, containerId)}
        onOpenChange={handleVisibleChange}
        disabled={disabled || loading}
        open={visible}
        trigger={['click']}
        data-testid="MultiSelect:Dropdown"
        menu={{ onClick: handleMenuClick, items: selectMenuItems() }}
      >
        <MultiSelectionsHeader disabled={disabled} className="ant-select" data-testid={testID}>
          {renderMultiSelectionLabel()}
          {loading ? (
            <LoadingIcon type="material-refresh" />
          ) : (
            <InputIcon type="material-expand_more" />
          )}
        </MultiSelectionsHeader>
      </StyledDropdown>
      {values.length === 0 && (
        <TWTextSmall twColor="negative">{getTranslation('requiredField')}</TWTextSmall>
      )}
    </>
  );
}

export default TWInputMultiSelectGuestOptions;
