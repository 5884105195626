import * as React from 'react';
import { Select } from 'antd';

import { SessionReportStatus, SessionReportStatuses } from '@tw/types';

import TWInputSelect, { TWInputSelectProps } from '../TWInputSelect/TWInputSelect';

interface TWInputSelectReportStatusProps extends TWInputSelectProps {
  popupContainerId?: string;
  placeholder?: string;
  onChange: (reportStatus: SessionReportStatus) => void;
}

const TWInputSelectReportStatus: React.FC<TWInputSelectReportStatusProps> = (props) => {
  const {
    popupContainerId = '',
    placeholder = 'All Report Statuses',
    onChange,
    defaultValue,
    testID,
  } = props;

  return (
    <TWInputSelect
      allowClear
      filterOption
      optionFilterProp="title"
      placeholder={placeholder}
      loading={false}
      onChange={onChange}
      popupContainerId={popupContainerId}
      minWidth="200px"
      defaultValue={defaultValue}
      testID={testID}
    >
      <Select.Option value={SessionReportStatuses.report_complete} title="Completed">
        Completed
      </Select.Option>
      <Select.Option value={SessionReportStatuses.needs_report} title="Needed">
        Needed
      </Select.Option>
      <Select.Option value={SessionReportStatuses.session_canceled} title="Canceled">
        Canceled
      </Select.Option>
    </TWInputSelect>
  );
};

export default TWInputSelectReportStatus;
