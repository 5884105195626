import _ from 'lodash';
import { createSelector } from 'reselect';

import { storageSelectorDoesNotPersistAnything } from '@tw/constants';
import { reduxConfigUtils } from '@tw/util';

import uiSettingsConfigStoreConfig from './uiSettingsConfigStoreConfig';

const { layerNames, rootSelector } = uiSettingsConfigStoreConfig;

// All the actual work to create selectors and chain them together is in reduxConfigUtils.js;
// go there to see what's actually happening here.
const { topmostSelector, generatedSelectors } = reduxConfigUtils.generateSelectorsForConfigLayers(
  layerNames,
  rootSelector,
);

/*
 * These selectors pull and format the data we want persisted in localStorage from this config.
 *
 * *Some* (but generally not most) feature flags are persisted across app launches,
 * for all users.
 * Note that this doesn't necessary mean we'll *restore* them, though: that's up to the reducer.
 *
 * Also note that the storage interactions for configs are more complicated than for the services,
 * since we have to interact with the under-the-hood layers themselves.
 * E.g., feature flags are backed up and restored on the 'debugOverride' layer while
 * user preferences uses the 'userState' layer. Each includes neither inherited nor
 * overriding values.
 */
const generalUiSettingsStorageSelector = storageSelectorDoesNotPersistAnything;
const userUiSettingsStorageSelector = createSelector(
  [generatedSelectors.userStateValuesSelector],
  (userStateValues) => ({
    storageSchemaVersion: 1,
    userStateValues: {
      userPreferences: _.get(userStateValues, 'userPreferences'),
    },
  }),
);

// Note that we explicitly list the names/selectors that we want to export
// (partially for clarity and greppability, and partially because we can't do dynamic
//  names with es6 modules anyway.)

// 99% of the time, consumers just want the topmost selector -- we'll rename it for
// clarity and readability.
export { topmostSelector as uiSettingsConfigSelector };

export { generalUiSettingsStorageSelector, userUiSettingsStorageSelector };

// You generally DON'T want these -- they're for debugging and peeking at the internal and
// intermediate values within the layers.
export const {
  debugOverrideValuesSelector,
  // Secret Settings and superSecretConfigData need access to all the layers'
  // attributes and values as one block
  allLayerAttributesSelector: allUiSettingsConfigLayerAttributesSelector,
  allLayerValuesSelector: allUiSettingsConfigLayerValuesSelector,
} = generatedSelectors;
