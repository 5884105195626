/* eslint-disable react/jsx-props-no-spreading */
import { Button, Popover } from 'antd';
import { useState } from 'react';
import { useFocused, useSelected, useSlateStatic } from 'slate-react';
import { useTranslator } from '@tw/i18n';
import { Icon } from '.';
import { removeLink } from '../utils/link';
import { ElementProps } from '../RichTextEditor.definitions';

const Link = ({ attributes, element, children }: ElementProps) => {
  const translator = useTranslator();
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const onChange = (newOpen: boolean) => {
    setOpen(selected && focused && newOpen);
  };

  const onRemoveLink = () => {
    removeLink(editor);
    hide();
  };

  return element.href ? (
    <Popover
      title={null}
      open={open}
      trigger="click"
      onOpenChange={onChange}
      content={
        <>
          <a
            href={element.href}
            onClick={hide}
            rel="noopener noreferrer"
            target="_blank"
            style={{ marginRight: 10 }}
          >
            {element.href}
          </a>
          <Button
            icon={<Icon icon="unlink" title={translator.t('bulletins.editor.removeLink')} />}
            onClick={onRemoveLink}
          />
        </>
      }
    >
      <a {...attributes} href={element.href}>
        {children}
      </a>
    </Popover>
  ) : (
    <div {...attributes}>{children}</div>
  );
};

export default Link;
