const DEVICE_CONFIG_ACTION_CONSTANTS = {
  UPDATE_DEVICE_CONFIG: 'DEVICE_CONFIG__UPDATE_DEVICE_CONFIG',
  /**
   * This *merges in* a set of debugOverride values
   */
  APPLY_DEBUG_OVERRIDE: 'DEVICE_CONFIG__APPLY_DEBUG_OVERRIDE',

  /**
   * This wipes (but does not disable) any debugOverride values
   */
  CLEAR_DEBUG_OVERRIDE: 'DEVICE_CONFIG__CLEAR_DEBUG_OVERRIDE',
};

function updateDeviceConfigAction(deviceConfigValues) {
  return {
    type: DEVICE_CONFIG_ACTION_CONSTANTS.UPDATE_DEVICE_CONFIG,
    deviceConfigValues,
  };
}

/**
 * By contract, each service has an applyDebugOverrideAction, for use in Secret Settings ONLY.
 * See superSecretConfigData if you want to go down that rabbit hole.
 */
function applyDebugOverrideAction(debugOverrideValues) {
  return {
    type: DEVICE_CONFIG_ACTION_CONSTANTS.APPLY_DEBUG_OVERRIDE,
    debugOverrideValues,
  };
}

function clearDebugOverrideAction() {
  return {
    type: DEVICE_CONFIG_ACTION_CONSTANTS.CLEAR_DEBUG_OVERRIDE,
  };
}

// We're going to export the raw values under their corresponding
// (non-"DEVICE_CONFIG__"-prefixed) names
export const { APPLY_DEBUG_OVERRIDE, CLEAR_DEBUG_OVERRIDE, UPDATE_DEVICE_CONFIG } =
  DEVICE_CONFIG_ACTION_CONSTANTS;

// And we're also going to export a constructor for each of the actions
export { updateDeviceConfigAction, applyDebugOverrideAction, clearDebugOverrideAction };
