/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketingReservationReportCreateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.TicketingReservationReportCreateInput>;
}>;

export type TicketingReservationReportCreateMutation = {
  ticketingReservationReportCreate: {
    ticketingReservationReport?: {
      createdDate?: any | null;
      downloadableFileId: string;
      status: string;
      title?: string | null;
      url?: string | null;
    } | null;
    userErrors: Array<{ field: Array<string>; message: string }>;
  };
};

export const TicketingReservationReportCreateDocument = gql`
  mutation TicketingReservationReportCreate($input: TicketingReservationReportCreateInput) {
    ticketingReservationReportCreate(input: $input) {
      ticketingReservationReport {
        createdDate
        downloadableFileId
        status
        title
        url
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export type TicketingReservationReportCreateMutationFn = Apollo.MutationFunction<
  TicketingReservationReportCreateMutation,
  TicketingReservationReportCreateMutationVariables
>;

/**
 * __useTicketingReservationReportCreateMutation__
 *
 * To run a mutation, you first call `useTicketingReservationReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingReservationReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingReservationReportCreateMutation, { data, loading, error }] = useTicketingReservationReportCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketingReservationReportCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TicketingReservationReportCreateMutation,
    TicketingReservationReportCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TicketingReservationReportCreateMutation,
    TicketingReservationReportCreateMutationVariables
  >(TicketingReservationReportCreateDocument, options);
}
export type TicketingReservationReportCreateMutationHookResult = ReturnType<
  typeof useTicketingReservationReportCreateMutation
>;
export type TicketingReservationReportCreateMutationResult =
  Apollo.MutationResult<TicketingReservationReportCreateMutation>;
export type TicketingReservationReportCreateMutationOptions = Apollo.BaseMutationOptions<
  TicketingReservationReportCreateMutation,
  TicketingReservationReportCreateMutationVariables
>;
