import styled from '@emotion/styled';

const TWInvisibleLabel = styled.span(() => ({
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  border: '0px',
  clip: 'rect(0px, 0px, 0px, 0px)',
  margin: '-1px',
  padding: '0px',
  whiteSpace: 'nowrap',
}));

export default TWInvisibleLabel;
