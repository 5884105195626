import _ from 'lodash';
import {
  ReminderDataModel,
  ReminderUnits,
  FormReminder,
} from './TWInputCustomReminder.definitions';

const DEFAULT_TIME_SELECTION = 15;
const DEFAULT_TYPE_SELECTION = 'sms';
const DEFAULT_TIME_UNIT = 'minutes';

export const getDefaultReminder = (): FormReminder => ({
  type: DEFAULT_TYPE_SELECTION,
  time: DEFAULT_TIME_SELECTION,
  unit: DEFAULT_TIME_UNIT,
});

const getTimeValue = (time: number): { time: number; unit: ReminderUnits } => {
  // transform offsetMinutes into human value
  if (time === 0) return { time, unit: 'minutes' };

  if (time % 10080 === 0) {
    return { time: time / 10080, unit: 'weeks' };
  }
  if (time % 1440 === 0) {
    return { time: time / 1440, unit: 'days' };
  }
  if (time % 60 === 0) {
    return { time: time / 60, unit: 'hours' };
  }
  return { time, unit: 'minutes' };
};

export const transformDataToReminders = (data: ReminderDataModel[]): FormReminder[] => {
  const reminders = _.cloneDeep(data);
  return _.map(reminders, ({ type, time }) => {
    const calculatedTimeValue = getTimeValue(time);
    return {
      type,
      time: -calculatedTimeValue.time,
      unit: calculatedTimeValue.unit,
    };
  });
};

export const transformRemindersToMinutes = (reminders: FormReminder[]): ReminderDataModel[] =>
  _.map(reminders, (reminder) => {
    let { time } = reminder;
    if (reminder.unit === 'hours') time *= 60;
    if (reminder.unit === 'days') time *= 1440;
    if (reminder.unit === 'weeks') time *= 10080;
    return {
      type: reminder.type,
      time: -time,
    };
  });
