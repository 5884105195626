import {
  Avatar,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@teamworksdev/react';
import { TWSpacingContainer } from '@tw/components';
import { getTranslation } from '@tw/i18n';
import { MdCheck, MdExpandMore, MdExpandLess, MdSearch } from '@teamworksdev/icons';

import useUserSelectContext from '../UserSelectContext';
import type { IndividualOrGroup, OptionGroup } from '../types';
import {
  EmptyStateContainer,
  EmptyStateDesc,
  EmptyStateIcon,
  EmptyStateTitle,
  ItemLabel,
  SelectionDetails,
  SelectionTreeGroup,
  SelectionTreeItem,
  SelectionTreeList,
  TreeGroupLabel,
  TreeGroupLabelContainer,
} from '../UserSelect.styles';

type Options = {
  groupItem: IndividualOrGroup[];
  userItems: IndividualOrGroup[];
};

const UserTreeSelect = () => {
  const {
    aria: { listBoxProps },
    groups,
    innerProps: { isMulti },
    onMouseOver,
    refs: { listBoxRef },
  } = useUserSelectContext();

  return (
    <div
      {...listBoxProps}
      aria-multiselectable={isMulti}
      ref={listBoxRef}
      onMouseOver={onMouseOver}
      // Added onFocus to make eslint happy
      onFocus={() => {}}
    >
      {groups.length > 0 ? (
        groups
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((group) => <Group key={group.uid} group={group} />)
      ) : (
        <EmptyStateContainer>
          <EmptyStateIcon>
            <MdSearch size={32} />
          </EmptyStateIcon>
          <EmptyStateTitle>{getTranslation('noResults')}</EmptyStateTitle>
          <EmptyStateDesc>{getTranslation('noResultsDesc')}</EmptyStateDesc>
        </EmptyStateContainer>
      )}
    </div>
  );
};

const MoreIcon = ({ isExpanded, dialogOpen }: { isExpanded: boolean; dialogOpen?: boolean }) => (
  <>
    {isExpanded ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
    {dialogOpen && <div style={{ marginRight: 8 }} />}
  </>
);

const Group = ({ group }: { group: OptionGroup }) => {
  const {
    refs: { inputRef },
    clearGroupsAndInput,
    innerProps: { alwaysSelected },
    onGroupCollapse,
    onGroupExpand,
    focusedId,
    setFocusedId,
    onSelect,
    onDeselect,
    selectionItemsById,
    menuOpen,
    setMenuOpen,
    dialogOpen,
    dialogSelections,
    getGroupCount,
  } = useUserSelectContext();
  const isExpanded = group.expanded && group.items.length > 0;
  const groupCount = getGroupCount(group.uid);
  // This allows us to keep the group item at the top of the list
  const allOptions = group.items.reduce(
    (acc: Options, item) => {
      if (item.isGroupOption) {
        acc.groupItem.push(item);
      } else {
        acc.userItems.push(item);
      }
      return acc;
    },
    { groupItem: [], userItems: [] },
  );
  // Sort the user items alphabetically after the group item
  const userItems = allOptions.userItems.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <SelectionTreeGroup
      id={group.uid}
      data-testid={group.uid}
      role="treeitem"
      tabIndex={0}
      aria-expanded={isExpanded}
      // On initial focus, set the focusedId to the group's uid
      onFocus={() => {
        if (!focusedId) {
          setFocusedId(group.uid);
        }
      }}
      // This is needed to open the group
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          return group.expanded ? onGroupCollapse(group.uid) : onGroupExpand(group.uid);
        }
        return undefined;
      }}
    >
      <SelectionTreeGroup
        role="button"
        tabIndex={0}
        onClick={() => (group.expanded ? onGroupCollapse(group.uid) : onGroupExpand(group.uid))}
        // Added onKeyDown to make eslint happy
        onKeyDown={() => {}}
      >
        <TreeGroupLabelContainer menuOpen={!dialogOpen}>
          {dialogOpen && <MoreIcon isExpanded={isExpanded} dialogOpen={dialogOpen} />}
          <TreeGroupLabel>{group.name}</TreeGroupLabel>
          {!dialogOpen && <MoreIcon isExpanded={isExpanded} />}
        </TreeGroupLabelContainer>
      </SelectionTreeGroup>
      {isExpanded && (
        <SelectionTreeList role="group" dialogOpen={dialogOpen}>
          {[...allOptions.groupItem, ...userItems].map((item) => {
            const isDisabled = !item.removable;
            const isItemSelected = dialogOpen
              ? item.id in dialogSelections
              : item.id in selectionItemsById;
            const onKeyDown = (key: string) => {
              if (isDisabled) return;
              // When selecting from the popover, close the menu and focus the input
              if (key === 'Enter' || key === ' ') {
                if (isItemSelected) {
                  onDeselect(item);
                } else {
                  onSelect(item);
                }
                if (menuOpen) {
                  inputRef.current?.focus();
                  clearGroupsAndInput();
                  setMenuOpen(false);
                }
              }
            };
            const onClick = () => {
              if (!alwaysSelected?.includes(item.id)) {
                if (isItemSelected) {
                  onDeselect(item);
                } else {
                  onSelect(item);
                }
                // When selecting from the popover, close the menu
                if (menuOpen) setMenuOpen(false);
              }
            };

            return (
              <Option
                key={item.uid}
                groupCount={groupCount}
                id={item.uid}
                item={item}
                isDisabled={isDisabled}
                isSelected={isItemSelected}
                isDialogOpen={dialogOpen}
                onClick={isDisabled ? undefined : onClick}
                onKeyDown={onKeyDown}
              />
            );
          })}
        </SelectionTreeList>
      )}
    </SelectionTreeGroup>
  );
};

const Option = ({
  groupCount,
  id,
  item,
  isDialogOpen,
  isDisabled,
  isSelected,
  onClick,
  onKeyDown,
}: {
  groupCount?: number;
  id?: string;
  item: IndividualOrGroup;
  isDialogOpen: boolean;
  isDisabled: boolean;
  isSelected: boolean;
  onClick?: () => void;
  onKeyDown?: (key: string) => void;
}) => {
  const { isGroupOption } = item;
  const name = item.label;

  return (
    <SelectionTreeItem
      id={id}
      tabIndex={0}
      aria-selected={isSelected}
      aria-disabled={isDisabled}
      disabled={isDisabled}
      role="treeitem"
      onClick={onClick}
      onKeyDown={(e) => onKeyDown?.(e.key)}
    >
      <SelectionDetails>
        <Avatar size={isDialogOpen ? 'sm' : 'xxs'} name={name} src={item.pictureUrl ?? ''} />
        <TWSpacingContainer twMarginRight={1} />
        <TooltipProvider>
          <Tooltip delayDuration={500}>
            <TooltipTrigger asChild>
              <ItemLabel>
                {isGroupOption && groupCount ? `${name} (${groupCount})` : name}
              </ItemLabel>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>
                <TooltipArrow />
                {isGroupOption && groupCount ? `${name} (${groupCount})` : name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
        {isSelected && <MdCheck size={20} />}
      </SelectionDetails>
    </SelectionTreeItem>
  );
};

export default UserTreeSelect;
