/* This file was generated by GraphQL CodeGen! Do not edit */
/* eslint-disable */
import * as Types from '../sharedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalendarReportsCreateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CalendarReportInput>;
}>;

export type CalendarReportsCreateMutation = {
  calendarReportCreate: {
    calendarReport?: {
      createdDate?: any | null;
      downloadableFileId: string;
      status?: string | null;
      title?: string | null;
      url?: string | null;
    } | null;
    userErrors: Array<{ message: string; field: Array<string> }>;
  };
};

export const CalendarReportsCreateDocument = gql`
  mutation CalendarReportsCreate($input: CalendarReportInput) {
    calendarReportCreate(input: $input) {
      calendarReport {
        createdDate
        downloadableFileId
        status
        title
        url
      }
      userErrors {
        message
        field
      }
    }
  }
`;
export type CalendarReportsCreateMutationFn = Apollo.MutationFunction<
  CalendarReportsCreateMutation,
  CalendarReportsCreateMutationVariables
>;

/**
 * __useCalendarReportsCreateMutation__
 *
 * To run a mutation, you first call `useCalendarReportsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarReportsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarReportsCreateMutation, { data, loading, error }] = useCalendarReportsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalendarReportsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CalendarReportsCreateMutation,
    CalendarReportsCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CalendarReportsCreateMutation, CalendarReportsCreateMutationVariables>(
    CalendarReportsCreateDocument,
    options,
  );
}
export type CalendarReportsCreateMutationHookResult = ReturnType<
  typeof useCalendarReportsCreateMutation
>;
export type CalendarReportsCreateMutationResult =
  Apollo.MutationResult<CalendarReportsCreateMutation>;
export type CalendarReportsCreateMutationOptions = Apollo.BaseMutationOptions<
  CalendarReportsCreateMutation,
  CalendarReportsCreateMutationVariables
>;
