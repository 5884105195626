import { ApolloError } from '@apollo/client';
import styled from '@emotion/styled';
import { MdClose } from '@teamworksdev/icons';
import { message } from 'antd';
import { ArgsProps, MessageApi, MessageType } from 'antd/lib/message';
import 'antd/lib/message/style';

import { TWIcon } from '@tw/components';
import theme from '@tw/components/utils/theme';
import { noopFn } from '@tw/constants';
import { TranslationKey, getTranslation } from '@tw/i18n';
import { GraphQLUserErrorsType } from '@tw/types';

enum AlertMessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

message.config({
  getContainer: () =>
    Array.from(document.querySelectorAll('.root-styles-container')).pop() as HTMLElement,
  duration: 3,
});

interface ErrorHandlerWithContextProps {
  error: ApolloError;
  defaultMessage?: string;
  context?: Record<string, unknown>;
}

export type TWMessageArgumentsType = string | ArgsProps;

export interface TWMessageApi extends MessageApi {
  info(options: TWMessageArgumentsType): MessageType;
  success(options: TWMessageArgumentsType): MessageType;
  error(options: TWMessageArgumentsType): MessageType;
  warning(options: TWMessageArgumentsType): MessageType;
  completedHandler(
    data: { userErrors?: GraphQLUserErrorsType },
    defaultMessage?: string,
    onSuccess?: () => void,
  ): void;
  errorHandler(
    error: ApolloError,
    defaultMessage?: string,
    context?: Record<string, unknown>,
  ): void;
  userCompletedHandler(
    userErrors?: GraphQLUserErrorsType,
    defaultMessage?: string,
    onSuccess?: () => void,
  ): void;
  userErrorHandler(
    userErrors?: GraphQLUserErrorsType,
    defaultMessage?: string,
    context?: Record<string, unknown>,
  ): void;
  handleErrorWithContext(options: ErrorHandlerWithContextProps): void;
}

const errorHandler = (
  error: ApolloError,
  errorMessage = getTranslation('errors.genericSubmitError'),
  context?: Record<string, unknown>,
) => {
  // The API will include an exception code for errors that should be
  // displayed to the user. The standard HTTP status codes are the foundation
  // (i.e. Forbidden, NotFound, Unauthorized) and more can be added later.
  const errorCode = error.graphQLErrors?.[0]?.extensions?.code;

  // we want to give first priority to errors we pass -- and then
  // give default messages related to error codes
  let contentText = errorMessage;
  if (contentText === getTranslation('errors.genericSubmitError')) {
    if (errorCode)
      contentText = getTranslation(`errorCodes.${errorCode}` as TranslationKey, {
        _: errorMessage,
      });
  }

  message.error({
    duration: 10,
    icon: <TWIcon type="material-cancel" />,
    content: (
      <ClickHandler
        type="button"
        data-testid={`alert-message-${AlertMessageType.ERROR}`}
        onClick={() => {
          navigator.clipboard.writeText(JSON.stringify({ error, context }));
          message.success(getTranslation('errorHandler.copiedToClipboard'));
        }}
      >
        {contentText}
        {contentText === errorMessage ? ` ${getTranslation('errorHandler.copyToClipboard')}` : ''}
      </ClickHandler>
    ),
  });
};

const userErrorHandler = (
  userErrors?: GraphQLUserErrorsType,
  errorMessage = getTranslation('errors.genericSubmitError'),
  context?: Record<string, unknown>,
) => {
  // The API may return an error code through the user errors.
  // See if that code exists and translate it with a cleaner user message.
  let errorCode = null;
  if (userErrors && userErrors.length === 1) {
    errorCode = userErrors?.[0].code;
  }

  if (userErrors && !errorCode) {
    // We have userErrors, but not a translatable error code, so use the regular completion handler to show those errors
    TWMessage.userCompletedHandler(userErrors, errorMessage);
  }

  // We want to give first priority to errors we pass -- and then give default messages related to error codes
  let contentText = errorMessage;
  if (contentText === getTranslation('errors.genericSubmitError')) {
    if (errorCode) {
      contentText = getTranslation(`errorCodes.${errorCode}` as TranslationKey, {
        _: errorMessage,
      });
    }
  }

  message.error({
    duration: 10,
    icon: <TWIcon type="material-cancel" />,
    content: (
      <ClickHandler
        type="button"
        data-testid={`alert-message-${AlertMessageType.ERROR}`}
        onClick={() => {
          navigator.clipboard.writeText(JSON.stringify({ userErrors, context }));
          message.success(getTranslation('errorHandler.copiedToClipboard'));
        }}
      >
        {contentText}
        {contentText === errorMessage ? ` ${getTranslation('errorHandler.copyToClipboard')}` : ''}
      </ClickHandler>
    ),
  });
};

const renderContentWithClose = (
  content: React.ReactNode,
  key: string,
  messageType: AlertMessageType,
) => (
  <ContentBody data-testid={`alert-message-${messageType}`}>
    {content}
    <ButtonContainer>
      <XButton
        size={16}
        aria-label={getTranslation('close')}
        onClick={() => message.destroy(key)}
      />
    </ButtonContainer>
  </ContentBody>
);

export const TWMessage: TWMessageApi = {
  ...message,
  success: (options) => {
    const key = Date.now().toString();
    return message.success({
      key,
      icon: <TWIcon type="material-check_circle" />,
      ...(typeof options === 'string'
        ? { content: renderContentWithClose(options, key, AlertMessageType.SUCCESS) }
        : {
            ...options,
            content: renderContentWithClose(options.content, key, AlertMessageType.SUCCESS),
          }),
    });
  },
  error: (options) => {
    const key = Date.now().toString();
    return message.error({
      key,
      icon: <TWIcon type="material-cancel" />,
      ...(typeof options === 'string'
        ? { content: renderContentWithClose(options, key, AlertMessageType.ERROR) }
        : {
            ...options,
            content: renderContentWithClose(options.content, key, AlertMessageType.ERROR),
          }),
    });
  },
  info: (options) => {
    const key = Date.now().toString();
    return message.info({
      key,
      icon: <TWIcon type="material-info" />,
      ...(typeof options === 'string'
        ? { content: renderContentWithClose(options, key, AlertMessageType.INFO) }
        : {
            ...options,
            content: renderContentWithClose(options.content, key, AlertMessageType.INFO),
          }),
    });
  },
  warning: (options) => {
    const key = Date.now().toString();
    return message.warning({
      key,
      icon: <TWIcon type="material-warning" />,
      ...(typeof options === 'string'
        ? { content: renderContentWithClose(options, key, AlertMessageType.WARNING) }
        : {
            ...options,
            content: renderContentWithClose(options.content, key, AlertMessageType.WARNING),
          }),
    });
  },
  completedHandler: (
    data,
    defaultMessage = getTranslation('genericSuccess'),
    onSuccess = noopFn,
  ) => {
    TWMessage.userCompletedHandler(data.userErrors, defaultMessage, onSuccess);
  },
  errorHandler,
  userCompletedHandler: (
    userErrors?: GraphQLUserErrorsType,
    defaultMessage = getTranslation('genericSuccess'),
    onSuccess = noopFn,
  ) => {
    if (!userErrors || userErrors.length === 0) {
      message.success(defaultMessage);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } else {
      const errors = userErrors;
      const content =
        errors?.length === 1 ? (
          errors[0].message
        ) : (
          <Bullets>
            {errors.map(({ field, message: text }) => (
              <li key={field.join('_')}>{text}</li>
            ))}
          </Bullets>
        );

      const key = Date.now().toString();

      message.error({
        duration: 10,
        icon: <TWIcon type="material-cancel" />,
        key,
        content: renderContentWithClose(content, key, AlertMessageType.ERROR),
      });
    }
  },
  userErrorHandler,
  handleErrorWithContext: ({ error, defaultMessage, context }) =>
    errorHandler(error, defaultMessage, context),
};

const ClickHandler = styled.button({
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  ':active:focus:hover, :active, :focus, :hover': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: theme.colors.text,
  },
});

const ContentBody = styled.div({
  display: 'flex',
  ':hover': {
    'div svg': {
      display: 'inline',
    },
  },
});

const ButtonContainer = styled.div({
  width: 40,
});

const Bullets = styled.ul({
  '&&': {
    paddingLeft: 24,
    marginBottom: 0,
    listStyleType: 'disc',
    textAlign: 'left',
  },
});

const XButton = styled(MdClose)({
  background: 'none',
  border: 'none',
  color: `${theme.colors.buttonText} !important`,
  cursor: 'pointer',
  height: 16,
  margin: '0 0 3px 16px',
  opacity: 0.7,
  padding: 0,
  verticalAlign: 'middle',
  display: 'none',
});
