import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import isFunction from 'lodash/isFunction';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { createRef, PureComponent } from 'react';

import { TWInvisibleLabel } from '@tw/components';
import { getTranslation } from '@tw/i18n';
import { DOMUtils } from '@tw/util';

import { DateTimeWrapper, RightArrow, WideDatePicker } from './TWInputDateRangePicker.styles';

interface Props {
  containerId: string;
  testId: string;
  onChange?: (range: RangeValue<Moment>) => void;
  defaultValue?: [Moment, Moment];
}

type TWInputDateRangeProps = Props &
  Omit<
    RangePickerProps<Moment>,
    'disabledTime' | 'onPanelChange' | 'onOk' | 'dateRender' | 'picker' | 'mode'
  >;

type State = { startDate: Moment | null; endDate: Moment | null };

// Intentionally a class because antd tries to assign a ref to it and produces an error:
// Warning: Stateless function components cannot be given refs. Attempts to access this ref will fail.
// For proper usage, pass in props containerId, onChange, testId and disabledDate
class TWInputDateRangePicker extends PureComponent<TWInputDateRangeProps, State> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private elementRef = createRef<any>();

  constructor(props: TWInputDateRangeProps) {
    super(props);

    this.state = {
      startDate: props.defaultValue?.[0] ?? null,
      endDate: props.defaultValue?.[1] ?? null,
    };

    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
  }

  defaultRange = () => {
    const { disabledDate } = this.props;
    return disabledDate?.(moment().subtract(7, 'days'));
  };

  getContainer = () => {
    const { containerId } = this.props;
    return DOMUtils.getContainer(containerId);
  };

  getFullDateRange = (start: Moment, end: Moment) => {
    const { onChange } = this.props;
    const { startDate, endDate } = this.state;
    const Startdate = start || startDate;
    const Enddate = end || endDate;

    if (isFunction(onChange) && Startdate !== null && Enddate !== null) {
      const fullDateRange: RangeValue<Moment> = [Startdate.startOf('day'), Enddate.endOf('day')];
      onChange(fullDateRange);
    } else {
      const fullDateRange: RangeValue<Moment> = [null, null];
      onChange?.(fullDateRange);
    }
  };

  setStartDate = (start: Moment) => {
    const { endDate } = this.state;
    const newEndDate = endDate || (this.defaultRange() ? moment().add(1, 'M') : moment());
    this.setState({
      startDate: start,
      endDate: newEndDate,
    });
    this.getFullDateRange(start, newEndDate);
  };

  setEndDate = (end: Moment) => {
    const { startDate } = this.state;
    const newStartDate = startDate || (this.defaultRange() ? moment() : moment().subtract(1, 'M'));
    this.setState({
      startDate: newStartDate,
      endDate: end,
    });
    this.getFullDateRange(newStartDate, end);
  };

  endDateCap = (current: Moment) => {
    // Can not select days after today or before start date
    const { startDate } = this.state;
    const { disabledDate } = this.props;
    return disabledDate?.(current) || startDate?.isAfter(current) || false;
  };

  startDateCap = (current: Moment) => {
    // Can not select days after today
    const { endDate } = this.state;
    const { disabledDate } = this.props;
    return disabledDate?.(current) || endDate?.isBefore(current) || false;
  };

  blurOnClose = (open: boolean) => {
    if (!open) {
      // this.elementRef.current.blur();
    }
  };

  render() {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      defaultPickerValue,
      testId,
      value,
      disabled,
      ...allOtherProps
    } = this.props;
    const { startDate, endDate } = this.state;

    const [defaultPickerValueStart, defaultPickerValueEnd] = defaultPickerValue ?? [];
    const startDisabled = Array.isArray(disabled) ? disabled[0] : disabled;
    const endDisabled = Array.isArray(disabled) ? disabled[1] : disabled;

    return (
      <DateTimeWrapper>
        <TWInvisibleLabel>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="start_date">{getTranslation('startDate')}</label>
        </TWInvisibleLabel>
        <WideDatePicker
          {...allOtherProps}
          format="LL"
          id="start_date"
          placeholder={getTranslation('startDate')}
          ref={this.elementRef}
          onChange={this.setStartDate}
          onOpenChange={this.blurOnClose}
          disabledDate={this.startDateCap}
          getPopupContainer={this.getContainer}
          data-testid={`${testId}:InputDateRange:start_date`}
          defaultValue={startDate ?? undefined}
          value={value?.[0] ?? undefined}
          disabled={startDisabled}
          defaultPickerValue={defaultPickerValueStart}
        />
        <RightArrow type="tw-swap-right" width={16} height={16} />
        <TWInvisibleLabel>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="end_date">{getTranslation('endDate')}</label>
        </TWInvisibleLabel>
        <WideDatePicker
          {...allOtherProps}
          format="LL"
          id="end_date"
          placeholder={getTranslation('endDate')}
          ref={this.elementRef}
          onChange={this.setEndDate}
          onOpenChange={this.blurOnClose}
          getPopupContainer={this.getContainer}
          disabledDate={this.endDateCap}
          data-testid={`${testId}:InputDateRange:end_date`}
          value={value?.[1] ?? undefined}
          defaultValue={endDate ?? undefined}
          disabled={endDisabled}
          defaultPickerValue={defaultPickerValueEnd}
        />
      </DateTimeWrapper>
    );
  }
}

export default TWInputDateRangePicker;
