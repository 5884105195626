import _ from 'lodash';

import { graphqlUtils } from '@tw/util';
import { GraphQLPaginatedResponse, RawSessionAttendance, SessionAttendance } from '@tw/types';

export const transformRawSessionAttendance = (
  attendance: RawSessionAttendance,
): SessionAttendance => ({
  ...attendance,
  meta: attendance.meta
    ? {
        notes: attendance.meta.notes,
        prepared: attendance.meta.prepared,
        attendanceStartDateTime: attendance.meta.attendance_start_date_time,
        attendanceEndDateTime: attendance.meta.attendance_end_date_time,
      }
    : {},
});

export const transformRawSessionAttendancePaginatedData = (
  rawSessionAttendanceData: GraphQLPaginatedResponse<RawSessionAttendance>,
): SessionAttendance[] => {
  if (!rawSessionAttendanceData) {
    return undefined;
  }
  const rawSessionAttendances = graphqlUtils.convertEdgesToArray(rawSessionAttendanceData.edges);
  return _.map(rawSessionAttendances, transformRawSessionAttendance);
};
