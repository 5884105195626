/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { Button } from 'antd';
import { Transforms } from 'slate';
import { ReactEditor, useFocused, useSelected, useSlateStatic } from 'slate-react';
import { useTranslator } from '@tw/i18n';
import { ElementProps } from '../RichTextEditor.definitions';

const Image = ({ attributes, children, element }: ElementProps) => {
  const translator = useTranslator();
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, element);
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      {children}
      <Wrapper contentEditable={false}>
        <FormattedImage src={element.url} selected={selected && focused} />
        <DeleteButton
          visible={selected}
          type="primary"
          aria-label={translator.t('bulletins.editor.deleteImage')}
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          // TODO: replace with TWIcon after that's merged
          icon={
            <span className="material-icons-outlined" aria-hidden style={{ fontSize: 28 }}>
              delete
            </span>
          }
        />
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const FormattedImage = styled.img<{ selected: boolean }>(({ selected, theme }) => ({
  display: 'block',
  width: '100%',
  height: 'auto',
  boxShadow: selected ? `0 0 0 3px ${theme.colors.lightIcon}` : 'none',
}));

const DeleteButton = styled(Button)<{ visible: boolean }>(({ visible }) => ({
  display: visible ? 'inline' : 'none',
  position: 'absolute',
  top: 10,
  left: 10,
}));

export default Image;
