import React, { PropsWithChildren } from 'react';
import { useTheme } from '@emotion/react';
import { TWModal } from '../../components.web';

export interface ModalProps {
  containerId: string;
  title: React.ReactNode;
  footer: JSX.Element;
  open?: boolean;
  onCancel?: () => void;
  width?: number;
  className?: string;
}

const TWWizardBase: React.FunctionComponent<PropsWithChildren<ModalProps>> = ({
  containerId,
  open,
  title,
  onCancel,
  footer,
  children,
  width = 850,
  className,
}) => {
  const theme = useTheme();
  return (
    <TWModal
      containerId={containerId}
      title={title}
      open={open}
      maskClosable
      destroyOnClose
      onCancel={onCancel}
      footer={footer}
      width={width}
      bodyStyle={{ paddingTop: 0, backgroundColor: theme.colors.white90 }}
      className={className}
    >
      {children}
    </TWModal>
  );
};

export default TWWizardBase;
