import { ApolloError } from '@apollo/client';
import { useState } from 'react';

import { TWHeading1 } from '@tw/components';
import { SelectionItemInterface, SelectionItemsByCode } from '@tw/components/utils';
import { GroupType, UserSelectionOptionsQuery } from '@tw/generated';
import { useViewer } from '@tw/hooks';
import {
  UserSelectionQueryFilters,
  useUserSelection,
} from '@tw/hooks/useUserSelection/useUserSelection';
import { getTranslation } from '@tw/i18n';

import { UserSelectionModalBase } from '../@components';
import TWUserSelectionFullMulti from './TWUserSelectionFullMulti';
import useMultiSelect from './useMultiSelect';

interface TWUserSelectionMultiModalProps {
  containerId: string;
  testID: string;
  defaultGroupTypeFilter?: GroupType;
  onOk: (selections: SelectionItemsByCode) => void;
  onCancel: () => void;
  selectedTeamId?: string;
  individualsOnly: boolean;
  canSwitchTeams: boolean;
  userTypesOnly: boolean;
  userTypeGroupsOnly: boolean;
  error?: ApolloError;
  onChange?: (selections: SelectionItemInterface[]) => void;
  onCheckedSelectionsChange?: (selections: SelectionItemInterface[]) => void;
  visible?: boolean;
  selections?: SelectionItemInterface[];
  selectionItemsByCode?: SelectionItemsByCode;
  alwaysSelectedCodes?: string[];
  hideAlwaysSelected?: boolean;
  hideDisabled?: boolean;
  withTeamMembership?: boolean;
  ignoreCustomGroupsForAllTeamsTeam?: boolean;
  includesAllTeams?: boolean;
  queryFilters: UserSelectionQueryFilters;
  disabledSelections?: string[];
  submitting?: boolean;
  viewCanModifyUsersTeamOnly?: boolean;
}

const TWUserSelectionFullMultiModal = ({
  containerId,
  testID,
  visible,
  onChange,
  onOk,
  onCancel,
  selections = [],
  defaultGroupTypeFilter,
  selectionItemsByCode,
  alwaysSelectedCodes,
  hideAlwaysSelected,
  onCheckedSelectionsChange,
  queryFilters,
  selectedTeamId,
  individualsOnly,
  canSwitchTeams,
  userTypesOnly,
  userTypeGroupsOnly,
  withTeamMembership = false,
  includesAllTeams = true,
  ignoreCustomGroupsForAllTeamsTeam = false,
  disabledSelections = [],
  hideDisabled = false,
  submitting = false,
  viewCanModifyUsersTeamOnly,
}: TWUserSelectionMultiModalProps) => {
  const viewer = useViewer();
  const [teamFilter, setTeamFilter] = useState(selectedTeamId || viewer.currentTeam.id);

  const includeCustomGroups = !(
    ignoreCustomGroupsForAllTeamsTeam && teamFilter === viewer.currentOrg.allTeamsTeamId
  );

  const { loading, error, data } = useUserSelection({
    options: {
      teamFilter,
      userTypesOnly,
      withTeamMembership,
      includeCustomGroups,
      ...queryFilters,
    },
  });

  const modalMultiSelectManagementProps = useMultiSelect({
    defaultGroupTypeFilter,
    onChange,
    onCheckedSelectionsChange,
    selections,
    queryFilters,
    disabledSelections,
  });

  const {
    setSelectionItemsByCode: setModalSelectionItemsByCode,
    selectionItemsByCode: modalSelectionItemsByCode,
  } = modalMultiSelectManagementProps;

  const onOkHandler = (): void => {
    onOk(modalSelectionItemsByCode);
  };

  const onCancelHandler = (): void => {
    setModalSelectionItemsByCode(selectionItemsByCode ?? {});
    onCancel();
  };

  const filterDataByAllowedPeople = (inputData?: UserSelectionOptionsQuery) => {
    const includeContacts = queryFilters?.includeContacts ?? false;
    const allowedPeople = queryFilters?.allowedPeople ?? [];
    const groups = inputData?.groups ?? [];

    // filter contacts due to allowed people
    if (includeContacts && allowedPeople.length > 0) {
      return groups.map((filteredUser) => {
        if (
          filteredUser?.groupType === GroupType.Contacts ||
          filteredUser?.groupType === GroupType.TypeContacts
        ) {
          return {
            ...filteredUser,
            people: filteredUser?.people?.filter((eachContact) => {
              const personId = eachContact?.selectionCode?.split('_').pop();
              return personId && allowedPeople.includes(+personId);
            }),
          };
        }
        return filteredUser;
      });
    }

    return groups;
  };

  const getSelectedPeopleCount = (): number => {
    // Same person could be selected more than once -- e.g. individually and as member of a group.
    // So just need to collect unique person selection codes and return count
    const uniqueSelectionCodes = Object.values(modalSelectionItemsByCode).reduce(
      (codes, selectionItem) => {
        // If it's group, it has people key. Otherwise it's one person.
        if ('people' in selectionItem && selectionItem.people) {
          selectionItem.people.forEach(({ selectionCode }) => codes.add(selectionCode));
          return codes;
        }

        codes.add(selectionItem.selectionCode);
        return codes;
      },
      new Set(),
    );

    return uniqueSelectionCodes.size;
  };

  const footerMessage = getTranslation('userSelection.peopleSelected', getSelectedPeopleCount());

  return (
    <UserSelectionModalBase
      open={visible}
      containerId={containerId}
      testID={testID}
      title={
        <TWHeading1>
          {!userTypesOnly ? getTranslation('selectPeople') : getTranslation('selectUserType')}
        </TWHeading1>
      }
      width={!userTypesOnly ? 866 : 433}
      footerMessage={!userTypesOnly ? footerMessage : ''}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      loading={submitting}
    >
      <TWUserSelectionFullMulti
        setTeamFilter={setTeamFilter}
        teamFilter={teamFilter}
        loading={loading}
        data={{ groups: filterDataByAllowedPeople(data) }}
        alwaysSelectedCodes={alwaysSelectedCodes}
        hideAlwaysSelected={hideAlwaysSelected}
        error={error}
        individualsOnly={individualsOnly}
        userTypesOnly={userTypesOnly}
        userTypeGroupsOnly={userTypeGroupsOnly}
        includesAllTeams={includesAllTeams}
        canSwitchTeams={canSwitchTeams}
        disabledSelections={disabledSelections}
        hideDisabled={hideDisabled}
        viewCanModifyUsersTeamOnly={viewCanModifyUsersTeamOnly}
        {...modalMultiSelectManagementProps}
      />
    </UserSelectionModalBase>
  );
};

TWUserSelectionFullMultiModal.defaultProps = {
  containerId: '',
  testID: '',
  visible: false,
  defaultGroupTypeFilter: GroupType.Type,
  selections: [],
};

export default TWUserSelectionFullMultiModal;
