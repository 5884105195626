import { TWTextSmall, TWTextDefault, TWTextLarge } from '../../typography';
import { theme } from '../../../utils';

export const defaultListItemSizeScaling = {
  small: {
    padding: 0.5,
    TextComponent: TWTextSmall,
    leftIconSize: theme.iconSize.small,
    rightIconSize: theme.iconSize.xsmall,
    leftFontSize: 12,
  },
  default: {
    padding: [1, 1],
    TextComponent: TWTextDefault,
    leftIconSize: theme.iconSize.medium,
    rightIconSize: theme.iconSize.xsmall,
    leftFontSize: 16,
  },
  large: {
    padding: [1, 1.5],
    TextComponent: TWTextLarge,
    leftIconSize: theme.iconSize.large,
    rightIconSize: theme.iconSize.xsmall,
    leftFontSize: 20,
  },
  subfolder: {
    padding: [1.25, 1, 1.25, 5.5],
    TextComponent: TWTextSmall,
    leftIconSize: theme.iconSize.small,
    rightIconSize: theme.iconSize.xsmall,
    leftFontSize: 14,
  },
};

export const listItemParentSizeScaling = {
  small: {
    ...defaultListItemSizeScaling.small,
    leftIconSize: theme.iconSize.xsmall,
    padding: [0.5, 1],
  },
  default: {
    ...defaultListItemSizeScaling.default,
    leftIconSize: theme.iconSize.xsmall,
    padding: [1, 1],
  },
  large: {
    ...defaultListItemSizeScaling.large,
    leftIconSize: theme.iconSize.small,
    padding: [1.5, 1.5],
  },
  subfolder: {
    ...defaultListItemSizeScaling.default,
    leftIconSize: theme.iconSize.small,
    padding: [1.5, 1, 1.5, 1.5],
  },
};
